import { isEmpty } from "lodash";
import { notification } from "antd";

import { returnNewRebateFieldArray } from "utils/helpers.offer";
import { encodeZeroDownLease } from "utils/helpers.paymentEngine";

import {
  leaseRebateFieldKeys,
  conditionalRebateFieldKeys,
} from "shared/constants/assetBuilder";

import {
  RawOfferData,
  SingletonOfferKeys,
  RepeatableOfferKeys,
  ProcessedPaymentEngineData,
} from "shared/types/assetBuilder";
import {
  FeedOfferKeys,
  ParseableOfferData,
  GetPaymentEngineDealDataResult,
} from "shared/types/paymentEngine";
import { DSOfferType } from "../paymentEngineModal/types";
import { Dispatch, SetStateAction } from "react";

type ApplyPaymentEngineValuesArgs = {
  offerType: DSOfferType;
  offerData: ParseableOfferData;
  rawPaymentEngineData: GetPaymentEngineDealDataResult | null;
  processedPaymentEngineData: ProcessedPaymentEngineData | null;
  closeModal: () => void;
  setEditedVin?: Dispatch<SetStateAction<string | undefined>>;
  editField: <T extends SingletonOfferKeys | RepeatableOfferKeys>(
    key: string | T,
    value: RawOfferData[T],
  ) => void;
};

const useApplyPaymentEngineValues =
  ({
    offerData,
    offerType,
    rawPaymentEngineData,
    processedPaymentEngineData,
    editField,
    closeModal,
    setEditedVin,
  }: ApplyPaymentEngineValuesArgs) =>
  () => {
    if (isEmpty(processedPaymentEngineData) || !processedPaymentEngineData) {
      notification.warn({
        message: "Cannot apply edits to offer.",
        description:
          "No Payment Engine data was returned, so the offer could not be updated.",
        placement: "topRight",
      });
      return;
    }

    const {
      leaseRebate = [],
      leaseRebateName = [],
      leaseRebateDisclosure = [],
      conditionalRebate = [],
      conditionalRebateName = [],
      conditionalRebateDisclosure = [],
    } = offerData;
    let finalLeaseRebateArray = [...leaseRebate];
    let finalLeaseRebateNameArray = [...leaseRebateName];
    let finalLeaseRebateDiscArray = [...leaseRebateDisclosure];
    let finalConditionalRebateArray = [...conditionalRebate];
    let finalConditionalRebateNameArray = [...conditionalRebateName];
    let finalConditionalRebateDiscArray = [...conditionalRebateDisclosure];

    for (const key in processedPaymentEngineData) {
      const value = processedPaymentEngineData[key as FeedOfferKeys];
      if (!value) {
        continue;
      }

      if (
        leaseRebateFieldKeys.concat(conditionalRebateFieldKeys).includes(key)
      ) {
        const digitRegex = /\d{1}/g;
        if (!digitRegex.test(key)) {
          continue;
        }
        const splitKey = key.toLowerCase().split(/rebate\d{1}/g);
        const integerMatch = key.match(/\d{1}/g);
        const rebateType = splitKey[0];
        const rebateField = splitKey[1]?.trim();
        const index = parseInt(integerMatch?.[0] || "1") - 1;
        switch (rebateType + "_" + rebateField) {
          case "lease_disclosure":
            finalLeaseRebateDiscArray = returnNewRebateFieldArray(
              finalLeaseRebateDiscArray,
              value,
              index,
            );
            break;
          case "lease_name":
            finalLeaseRebateNameArray = returnNewRebateFieldArray(
              finalLeaseRebateNameArray,
              value,
              index,
            );
            break;
          case "lease_":
            finalLeaseRebateArray = returnNewRebateFieldArray(
              finalLeaseRebateArray,
              value as string,
              index,
            );
            break;
          case "conditional_disclosure":
            finalConditionalRebateDiscArray = returnNewRebateFieldArray(
              finalConditionalRebateDiscArray,
              value,
              index,
            );
            break;
          case "conditional_name":
            finalConditionalRebateNameArray = returnNewRebateFieldArray(
              finalConditionalRebateNameArray,
              value,
              index,
            );
            break;
          case "conditional_":
            finalConditionalRebateArray = returnNewRebateFieldArray(
              finalConditionalRebateArray,
              value as string,
              index,
            );
            break;

          default:
            break;
        }
      }
      editField(
        offerType === "ZERO DOWN LEASE" ? encodeZeroDownLease(key) : key,
        value,
      );
    }
    if (offerType === "FINANCE" && rawPaymentEngineData?.loan) {
      const { totalFinanced, apr } = rawPaymentEngineData.loan;
      editField("amntFinanced", totalFinanced || "");
      editField(
        "financeRate",
        (parseFloat(apr?.value || "0") * 100).toFixed(2),
      );
    }

    const lenderName = rawPaymentEngineData?.general?.lender?.name || "";

    switch (offerType) {
      case "ZERO DOWN LEASE":
        editField("zeroDownLeaseLender", lenderName);
        break;
      case "FINANCE":
        editField("financeLender", lenderName);
        editField("aprLender", lenderName);
        break;
      case "LEASE":
        editField("leaseLender", lenderName);
    }

    editField("leaseRebate", finalLeaseRebateArray);
    editField("leaseRebateName", finalLeaseRebateNameArray);
    editField("leaseRebateDisclosure", finalLeaseRebateDiscArray);
    editField("conditionalRebate", finalConditionalRebateArray);
    editField("conditionalRebateName", finalConditionalRebateNameArray);
    editField("conditionalRebateDisclosure", finalConditionalRebateDiscArray);

    notification.info({
      message: "Offer Edited",
      description:
        "The offer has been edited so that it matches the latest Payment Engine data.",
      placement: "bottomRight",
    });
    if (setEditedVin) setEditedVin(offerData.vin);
    closeModal();
  };

export default useApplyPaymentEngineValues;
