import { Tooltip, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import {
  compareStringBy,
  getFiltersFromData,
  onFilterBy,
} from "utils/helpers.table";
import { AdLoadProgressData } from "../AdLoadProgressTable";
import StatusIcon from "./StatusIcon";
import { terms } from "utils/terms";
import { startCase } from "lodash";
import AdNameCell from "screens/adLibrary/adLoadV2/shared/AdNameCell";
import StoreTag from "screens/adLibrary/adLoadV2/shared/StoreTag";

export const useColumns = (
  dataSource: AdLoadProgressData[],
): ColumnsType<AdLoadProgressData> => {
  const getFilters = getFiltersFromData(dataSource);

  return [
    {
      title: "",
      width: 30,
      dataIndex: "status",
      render: (_, record) => {
        return (
          <Tooltip placement="topLeft" title={record.errorMessage}>
            <div>
              <StatusIcon status={record.status} />
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "Ad name",
      width: 300,
      dataIndex: "adName",
      sorter: compareStringBy("adName"),
      render: (_, record) => (
        <AdNameCell thumbnail={record.thumbnail} name={record.adName} />
      ),
      filters: getFilters("adName"),
      onFilter: onFilterBy("adName"),
    },
    {
      title: "Format",
      width: 150,
      dataIndex: "format",
      sorter: compareStringBy("format"),
      filters: getFilters("format"),
      onFilter: onFilterBy("format"),
      render: startCase,
    },
    {
      title: terms.accountName,
      dataIndex: "storeName",
      width: 200,
      sorter: compareStringBy("storeName"),
      render: (_, record) => {
        return <StoreTag storeName={record.storeName} />;
      },
      filters: getFilters("storeName"),
      onFilter: onFilterBy("storeName"),
    },
    {
      title: "Campaign Name",
      dataIndex: "campaignName",
      width: 250,
      sorter: compareStringBy("campaignName"),
      render: (_, record) => {
        return <Tag>{record.campaignName}</Tag>;
      },
      filters: getFilters("campaignName"),
      onFilter: onFilterBy("campaignName"),
    },
    {
      title: "Ad Set Name",
      width: 250,
      dataIndex: "adsetName",
      sorter: compareStringBy("adsetName"),
      render: (_, record) => {
        return <Tag>{record.adsetName}</Tag>;
      },
      filters: getFilters("adsetName"),
      onFilter: onFilterBy("adsetName"),
    },
  ];
};
