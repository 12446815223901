import useDeepEffect from "shared/hooks/useDeepEffect";
import { IDimension, IExtendedFabricObject } from "shared/types/designStudio";
import { createGridGroup } from "utils/fabric/helpers.grid";
import * as fabricHelpers from "utils/helpers.fabric";

export default (args: {
  showGrid: boolean;
  canvasDimension: IDimension;
  canvasAreaMargin: { top: number; left: number };
  onComplete: (gridGroup?: IExtendedFabricObject) => void;
}) => {
  useDeepEffect(() => {
    const { showGrid, canvasDimension, canvasAreaMargin } = args;
    if (showGrid) {
      // insert
      const gridGroup = createGridGroup(
        canvasDimension.width,
        canvasDimension.height,
      );

      gridGroup.set({
        top: canvasAreaMargin.top,
        left: canvasAreaMargin.left,
      });

      const extendedGridGroup = fabricHelpers.extendFabricObject({
        object: gridGroup,
        objectType: "grid",
      });
      args.onComplete(extendedGridGroup);
    } else {
      // delete
      args.onComplete(undefined);
    }
  }, [args.showGrid]);
};
