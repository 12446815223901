import { Form, Input, Typography } from "antd";
import { useState } from "react";
import { useGetIsEverythingAdHtmlSlugAvailable } from "shared/hooks/everythingAdHtml/useEverythingAdsHtml";
import { useDebounce } from "shared/hooks/useDebounce";
import { EverythingAdHtml } from "shared/types/adLibrary";
import { eaBaseUrl } from "../../../GenerateUrlDrawer";
import styles from "./SlugInput.module.scss";

export const SlugInput = ({
  slug,
  everythingAd,
  updateEverythingAd,
}: {
  slug: string;
  everythingAd: EverythingAdHtml;
  updateEverythingAd: (everythingAd: EverythingAdHtml) => void;
}) => {
  const [newSlug, setNewSlug] = useState(slug);
  const [isEditing, setIsEditing] = useState(false);
  const debouncedSlug = useDebounce(newSlug, 300);

  const { data: validation } = useGetIsEverythingAdHtmlSlugAvailable({
    slug: debouncedSlug,
  });

  const isCreatedSlug = debouncedSlug === everythingAd.slug;

  const isUnavailableSlug = validation?.isAvailable === false && !isCreatedSlug;

  const getValidateStatus = () => {
    if (isUnavailableSlug) return "error";
    return undefined;
  };

  const updateSlug = () => {
    setIsEditing(false);
    if (!everythingAd || isCreatedSlug || isUnavailableSlug) {
      setNewSlug(slug);
      return;
    }
    const updatedEverythingAd = {
      ...everythingAd,
      oldSlug: everythingAd.slug,
      slug: debouncedSlug,
    };
    updateEverythingAd(updatedEverythingAd);
  };

  const onChangeSlug = (event: { target: { value: string } }) => {
    setNewSlug(event.target.value);
  };

  const onStartEditing = () => {
    setIsEditing(true);
  };

  const getHelpMessage = () => {
    if (isUnavailableSlug && isEditing)
      return "This URL slug is already in use";
    return undefined;
  };

  return isEditing ? (
    <Form initialValues={{ slug: newSlug }}>
      <Form.Item
        className={styles.slugInput}
        name={"slug"}
        rules={[
          {
            required: true,
            message: "Please enter a URL slug",
          },
          {
            pattern: /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
            message:
              "Invalid URL slug, it can only contain lowercase letters, numbers and dashes",
          },
        ]}
        validateStatus={getValidateStatus()}
        hasFeedback={isEditing}
        help={getHelpMessage()}
      >
        <Input
          onChange={onChangeSlug}
          value={newSlug}
          onPressEnter={updateSlug}
          onBlur={updateSlug}
        />
      </Form.Item>
    </Form>
  ) : (
    <Typography.Link
      editable={{
        onStart: onStartEditing,
      }}
      href={eaBaseUrl + everythingAd.slug}
      target="_blank"
    >
      {slug}
    </Typography.Link>
  );
};
