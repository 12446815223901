import React from "react";
import { Button, Divider, Typography } from "antd";
import styles from "./ItemCountToolbar.module.scss";

type Props = {
  totalCount: number;
  selectedCount: number;
};
const ItemCountToolbar = (props: Props) => {
  const { totalCount, selectedCount } = props;
  return (
    <div className={styles.itemCountToolbar}>
      <Typography.Text>{totalCount} items</Typography.Text>
      <Divider type="vertical" />
      <span className={styles.filterContainer}>
        <Typography.Text strong>Filters</Typography.Text>
        <Button size="small">{selectedCount} selected</Button>
      </span>
    </div>
  );
};

export default ItemCountToolbar;
