import { useQuery } from "react-query";
import API from "services";

import {
  IApiResponse,
  IGetVersionOfJsonFromS3QueryParams,
  IGetVersionOfJsonFromS3ResponseData,
} from "shared/types/shared";

interface IUseFetchVersionOfJsonFromS3Args {
  queryParams: IGetVersionOfJsonFromS3QueryParams;
  enabled?: boolean;
}

const getVersionOfObjectFromS3 =
  (queryParams: IGetVersionOfJsonFromS3QueryParams) => async () => {
    const { result, error } =
      await API.services.adLibrary.getVersionOfJsonFromS3(queryParams);

    if (error || !result) {
      throw Error(error?.message || "Something went wrong.");
    }

    return result;
  };

export const useFetchVersionOfJsonFromS3 = (
  args: IUseFetchVersionOfJsonFromS3Args,
) => {
  return useQuery<
    IGetVersionOfJsonFromS3ResponseData | undefined,
    IApiResponse<IGetVersionOfJsonFromS3ResponseData>
  >(
    ["versionOfJsonFromS3", args.queryParams.url, args.queryParams.version_id],
    getVersionOfObjectFromS3(args.queryParams),
    { enabled: args?.enabled },
  );
};
