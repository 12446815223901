import { useMutation, useQueryClient } from "react-query";
import Page, {
  fbPage,
  ICreateCanvasResponseData,
} from "screens/adLibrary/facebookUtils/page";
import {
  ICanvasParams,
  IFacebookErrorObject,
} from "screens/adLibrary/facebookUtils/types";

export const useMutateFbCanvas = (canvasId?: string) => {
  const queryClient = useQueryClient();

  return useMutation<
    ICreateCanvasResponseData,
    { result: null; error: IFacebookErrorObject },
    { params: ICanvasParams; fbPage?: Page }
  >(
    (args: { params: ICanvasParams; fbPage?: Page }) =>
      canvasId
        ? (args.fbPage || fbPage).updateCanvas(canvasId, args.params)
        : (args.fbPage || fbPage).createCanvas(args.params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("fbCanvas");
      },
    },
  );
};
