import { memo } from "react";
import { Badge } from "antd";
import { isNil } from "lodash";

interface IProps {
  count?: number;
  backgroundColor?: string;
  borderColor?: string;
  color?: string;
  showZero?: boolean;
  title?: string;
}

const BadgeCount = ({
  count,
  backgroundColor = "#f47920",
  borderColor = backgroundColor,
  color = "white",
  showZero = false,
  title,
}: IProps) => {
  if (isNil(count)) return null;

  return (
    <Badge
      count={count}
      showZero={showZero}
      title={title}
      style={{
        backgroundColor,
        borderColor,
        marginLeft: "5px",
        color,
      }}
    />
  );
};

export default memo(BadgeCount);
