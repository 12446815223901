import useGetTemplatePreviewUrl from "screens/assetExporter/feedConfigurationV2/shared/hooks/useGetTemplatePreviewUrl";
import { customFabricAttributes } from "shared/types/designStudio";
import { HtmlTemplatePreview } from "./HtmlTemplatePreview";
import { useShowPreview } from "./useShowPreview";
import Preview from "screens/designStudio/editor/canvasContainer/toolbar/Preview";
import { useDesignStudioContext } from "screens/designStudio/context/DesignStudioDataProvider";

export const TemplatePreview = () => {
  const { previewTemplate, setPreviewTemplate } = useDesignStudioContext();
  const { canvas } = useShowPreview(previewTemplate);
  const { data: previewUrl } = useGetTemplatePreviewUrl(
    previewTemplate ?? undefined,
  );

  const RegularPreview = () => (
    <Preview
      canvas={canvas}
      resource={previewTemplate ?? undefined}
      openPreview={!!previewTemplate}
      onPreviewClose={() => {
        setPreviewTemplate(null);
      }}
      isDarkBackground={false}
      customAttributes={customFabricAttributes}
      variableAlias={{}}
      disablePlayButton={true}
    />
  );

  const HtmlPreview = () => (
    <HtmlTemplatePreview
      previewUrl={previewUrl ?? ""}
      onPreviewClose={() => {
        setPreviewTemplate(null);
      }}
    />
  );

  return (
    <>
      {previewTemplate && canvas && previewTemplate.type !== "html" && (
        <RegularPreview />
      )}
      {previewTemplate && previewTemplate.type === "html" && previewUrl && (
        <HtmlPreview />
      )}
    </>
  );
};
