import { Alert } from "antd";
import { memo } from "react";
import { fbPage } from "screens/adLibrary/facebookUtils/page";
import { AdType } from "screens/adLibrary/facebookUtils/types";
import CustomAdPreview from "screens/campaignManagement/adReview/shared/components/CustomAdPreview";
import { IVisuals } from "shared/types/adLibrary";
import { isValidUrl } from "utils/validators";

interface IProps {
  adVisuals?: IVisuals;
  adType: AdType;
}

const getLinkCaption = (displayUrl?: string) => {
  if (!displayUrl) return "";

  return isValidUrl(displayUrl, { optionalProtocol: true })
    ? displayUrl
    : "example.com";
};

const AdLibraryCustomAdPreview = ({ adVisuals, adType }: IProps) => {
  const linkCaption = getLinkCaption(adVisuals?.displayUrl);

  const supportsCustomPreview = [AdType.Video, AdType.Still].includes(adType);

  return (
    <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
      {supportsCustomPreview ? (
        <CustomAdPreview
          videoUrl={adVisuals?.videoUrl}
          thumbnailUrl={adVisuals?.thumbnail}
          pageId={fbPage.pageId}
          cta={adVisuals?.ctaButtonText}
          message={adVisuals?.postCopy}
          linkCaption={linkCaption}
          title={adVisuals?.headline}
          description={adVisuals?.headlineDescription}
        />
      ) : (
        <Alert
          message={
            <div>
              &quot;Custom Ad Preview&quot; is only supported for <b>Still</b>{" "}
              and <b>Video</b> formats
            </div>
          }
          type="info"
          showIcon
        />
      )}
    </div>
  );
};

export default memo(AdLibraryCustomAdPreview);
