import { Steps } from "antd";
import { useAdEngineActions } from "redux/assetExporter/assetExporter.slice";
import { steps } from "shared/constants/assetExporter";
import { useAppSelector } from "shared/hooks/useAppSelector";

const { Step } = Steps;

export const StepsConfig = () => {
  const { uploadType, drawerMode, step, latestStep, selectedType } =
    useAppSelector(state => state.assetExporter.sourceFeed.feedDrawer);
  const actions = useAdEngineActions();
  const isCustom = uploadType === "custom";
  const isUpdate = drawerMode === "UPDATE";
  const isAssetbuilderFeed = selectedType === "assetBuilder";
  const createSteps = steps
    .filter(s => !isCustom || s !== "Scheduling")
    .filter(s => isAssetbuilderFeed || s !== "Configure");
  const updateSteps = steps
    .filter(s => s !== "Map Columns")
    .filter(s => !isCustom || s !== "Scheduling")
    .filter(s => isAssetbuilderFeed || s !== "Configure");
  const availableSteps = isUpdate ? updateSteps : createSteps;

  return (
    <Steps direction="vertical" size="small" current={step}>
      {availableSteps.map((currStep, idx) => {
        const isDisabled = latestStep < idx;
        return (
          <Step
            style={{ cursor: isDisabled ? "default" : "pointer" }}
            key={idx}
            title={currStep}
            onClick={() => !isDisabled && actions.setStep(idx)}
            disabled={isDisabled}
          />
        );
      })}
    </Steps>
  );
};
