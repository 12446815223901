import { PlayCircleFilled } from "@ant-design/icons";
import { memo } from "react";
import VisualMedia from "screens/adLibrary/shared/VisualMedia";
import {
  IVideoElement,
  InstantExperienceElementStyle,
} from "shared/types/adLibrary";
import { LIVE_PREVIEW_HEIGHT, LIVE_PREVIEW_WIDTH } from "../LivePreview";
import styles from "./MediaPreview.module.scss";

interface IProps {
  videoElement: IVideoElement;
}

const VideoPreview = ({ videoElement }: IProps) => {
  return (
    <VisualMedia
      mediaHeight={
        videoElement.style === InstantExperienceElementStyle.FIT_TO_HEIGHT
          ? LIVE_PREVIEW_HEIGHT
          : undefined
      }
      videoUrl={videoElement.url}
      thumbnailUrl={videoElement.thumbnail}
      placeholder={
        <div
          className={styles.mediaPlaceholder}
          style={{ width: LIVE_PREVIEW_WIDTH, height: LIVE_PREVIEW_WIDTH }}
        >
          <PlayCircleFilled style={{ color: "#AFB4BD" }} />
        </div>
      }
      displayVideoControls={false}
    />
  );
};

export default memo(VideoPreview);
