import { flatten } from "lodash";
import { useQuery } from "react-query";
import ProductCatalog from "screens/adLibrary/facebookUtils/productCatalog";
import {
  IFacebookErrorObject,
  IFacebookProductSet,
} from "screens/adLibrary/facebookUtils/types";

interface IGetProductSetArgs {
  productCatalogId: string;
}

const getProductSets = (args: IGetProductSetArgs) => async () => {
  const productCatalog = new ProductCatalog(args.productCatalogId);
  const { result, error } = await productCatalog.getProductSets();
  if (error) {
    throw Error(error.message);
  }
  return result?.data ?? [];
};

export const useFetchProductSets = (args: IGetProductSetArgs) => {
  return useQuery<
    IFacebookProductSet[] | undefined,
    { result: null; error: IFacebookErrorObject }
  >(["facebookProductSets", args.productCatalogId], getProductSets(args), {
    staleTime: 300000,
    enabled: !!args.productCatalogId,
    retry: false,
  });
};

interface IGetProductSetForMutlipleCatalogsArgs {
  productCatalogIds: string[];
}

const getProductSetsForMultipleCatalogs =
  (args: IGetProductSetForMutlipleCatalogsArgs) => async () => {
    const productSetData = await Promise.all(
      args.productCatalogIds.map(async productCatalogId => {
        const productCatalog = new ProductCatalog(productCatalogId);
        try {
          const { result, error } = await productCatalog.getProductSets();
          if (error) {
            return [];
          }
          return result?.data ?? [];
        } catch (error) {
          return [];
        }
      }),
    );
    return flatten(productSetData);
  };

export const useFetchProductSetsForMultipleCatalogs = (
  args: IGetProductSetForMutlipleCatalogsArgs,
) => {
  return useQuery<
    IFacebookProductSet[] | undefined,
    { result: null; error: IFacebookErrorObject }
  >(
    ["facebookProductSets", args.productCatalogIds.sort().join(",")],
    getProductSetsForMultipleCatalogs(args),
    {
      staleTime: 300000,
      enabled: !!args.productCatalogIds?.length,
      retry: false,
    },
  );
};
