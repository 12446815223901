import API from "services";
import { useQuery } from "react-query";
import { IDisclosure } from "shared/types/legalLingo";

const fetchDisclosures = async () => {
  const { result, error } = await API.services.legalLingoV2.getDisclosures();

  if (!result) {
    throw Error(
      error?.message || "Something went wrong while fetching disclosures.",
    );
  }

  return result;
};

export const useFetchDisclosures = () => {
  const result = useQuery<IDisclosure[], Error>(
    "disclosures",
    fetchDisclosures,
    {
      staleTime: 300000,
    },
  );

  return { ...result, disclosures: result?.data || [] };
};
