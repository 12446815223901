import { useCallback } from "react";
import { PlusOutlined } from "@ant-design/icons";
import ListContainer from "../shared/ListContainer";
import ListItem from "../shared/ListItem";
import * as utils from "../../../utils";
import {
  Element,
  isUrlData,
  URLData,
  useEverythingAdsContext,
} from "screens/everythingAds.hooks/useContextAPI";

import styles from "./DestinationUrlLabelList.module.scss";
import uuid from "uuid/v4";
import { useFetchLabels } from "shared/hooks/useFetchLabels";
import { useEffect } from "react";
import { Spin } from "antd";
import { useStoreActions } from "react-flow-renderer";

const DestinationUrlLabelList = () => {
  const {
    labels,
    setLabels,
    setSelectedFlowElement,
    elements,
    selectedFlowElement,
  } = useEverythingAdsContext() || {};
  const onDragStart = useCallback(
    (element: Element) => (e: React.DragEvent<HTMLDivElement>) => {
      if (!isUrlData(element)) return;

      const urlElement: URLData = {
        id: uuid(),
        ...element,
      };
      utils.onDragStart(e, urlElement);
    },
    [],
  );

  const { data, isLoading } = useFetchLabels();
  useEffect(() => {
    setLabels?.(data?.labels || []);
  }, [setLabels, data]);

  const onClick = useCallback(
    (element: Element) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();

      setSelectedFlowElement?.(
        elements?.find(
          ele => ele.data?.url?.value === (element as URLData).value,
        ),
      );
    },
    [elements, setSelectedFlowElement],
  );

  const setSelectedElements = useStoreActions(
    actions => actions.setSelectedElements,
  );
  useEffect(() => {
    if (!selectedFlowElement) return;
    setSelectedElements([selectedFlowElement]);
  }, [selectedFlowElement, setSelectedElements]);

  return (
    <ListContainer isLoading={false}>
      <ListItem
        element={{
          type: "label",
          value: "",
        }}
        isSelected={false}
        onDragStart={onDragStart}
      >
        <div className={styles["new-destination"]}>
          <PlusOutlined />

          <span>New Destination</span>
        </div>
      </ListItem>

      <Spin
        className={styles["loading-spinner"]}
        spinning={isLoading}
        tip="Loading..."
      >
        {labels?.map(label => {
          const urlElement: URLData = {
            value: label.name,
            type: "label",
            labelName: label.name,
          };
          return (
            <ListItem
              key={`label-list-item-${label.name}`}
              isSelected={
                label.name === selectedFlowElement?.data?.url?.labelName
              }
              onDragStart={onDragStart}
              element={urlElement}
              onClick={onClick}
            />
          );
        })}
      </Spin>
    </ListContainer>
  );
};

export default DestinationUrlLabelList;
