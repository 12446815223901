import API from "services";

import { useUser } from "../useUser";
import { InfiniteData, useMutation, useQueryClient } from "react-query";

import uuid from "uuid";

import { IComment } from "shared/types/shared";
import {
  IGetCommentsResult,
  IModifyCommentResult,
} from "shared/types/adLibrary";
import { User } from "redux/auth/auth.slice";

const modifyComment = (user?: User | null) => async (comment: IComment) => {
  const nameWords = user?.name?.split(" ");
  const firstName = nameWords?.[0] ?? "";
  const lastName = nameWords?.[1] ?? "";

  const commentData: IComment = {
    ...comment,
    id: comment?.id || uuid(),
    lastName: comment?.lastName ?? lastName,
    firstName: comment?.firstName ?? firstName,
    createdBy: comment?.createdBy ?? user?.email ?? "",
  };
  const operation = comment.id ? "update" : "create";
  const { result, error } = await API.services.adLibrary.modifyComment(
    commentData,
    operation,
  );

  if (error) {
    throw Error(error.message);
  }

  return result;
};

const useMutateComment = () => {
  const user = useUser();
  const queryClient = useQueryClient();
  return useMutation<
    IModifyCommentResult | null,
    Error,
    IComment,
    { previousComments?: InfiniteData<IGetCommentsResult> }
  >(modifyComment(user), {
    onError: (_err, newComment, context) => {
      queryClient.setQueryData(
        ["commentsPages", newComment.objectId],
        context?.previousComments,
      );
    },
    onSettled: res => {
      queryClient.invalidateQueries(["commentsPages", res?.comment.objectId]);
    },
  });
};

export default useMutateComment;
