import { FC, useCallback, useContext, useMemo, useState } from "react";

import { context } from "../../../Editor.context";
import useLayers, {
  TLayerItem,
  TLayerOrderAction,
} from "screens/designStudio/editor.hooks/useLayers";

import styles from "./ManageLayers.module.scss";

import { message } from "antd";
import { ExtendedObjectType } from "shared/types/designStudio";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import LayerItem from "./manageLayers/LayerItem";
import useDeepEffect from "shared/hooks/useDeepEffect";
import Empty from "antd/es/empty";
import { useIsHotkeyPressed } from "react-hotkeys-hook";

const ManageLayers: FC = () => {
  const editorContext = useContext(context);
  const canvasJson = useMemo(() => {
    const { historyIndex, history } = editorContext?.canvasHistory || {};
    let json = history?.[historyIndex || -1];
    if (!json) {
      // This indicates the canvas was loaded for the first time.
      // If this is true, use canvasJson instead.
      json = editorContext?.canvasJson;
    }

    return json;
  }, [editorContext?.canvasHistory, editorContext?.canvasJson]);

  const [layerOrderAction, setLayerOrderAction] = useState<TLayerOrderAction>();
  const [layers, setLayers] = useLayers({
    json: canvasJson,
    layerOrderAction,
    onOrderChanged: layerOrderAction => {
      editorContext?.setLayerAction({
        action: {
          type: "re-order",
          data: layerOrderAction,
        },
      });
    },
    onComplete: () => {
      // reset the layerOrderAction
      setLayerOrderAction(undefined);
    },
  });

  const [layerToHighlight, setLayerToHighlight] = useState<TLayerItem>();
  const [selectedLayers, setSelectedLayers] = useState<TLayerItem[]>();
  useDeepEffect(() => {
    const { canvasAction } = editorContext || {};

    if (!canvasAction) return;

    const {
      target,
      action: { keyPressed, type },
    } = canvasAction || {};

    switch (type) {
      case "objectSelected":
        const selectedLayer = layers.find(
          layer => layer.id === (target as fabric.Object).name,
        );
        if (!selectedLayer) return;
        if (keyPressed === "shift") {
          // multi-select
          setSelectedLayers(prev => {
            return [...(prev || []), selectedLayer];
          });
        } else {
          setSelectedLayers([selectedLayer]);
        }
        break;
      case "objectDeSelected":
        // Below will reset the following states
        //  1. selectable on a textbox after editing is done.
        //  ... more to come
        setLayers(prev =>
          prev.map(layer =>
            layer.id === canvasAction.target?.name
              ? {
                  ...layer,
                  original: canvasAction.target!,
                }
              : layer,
          ),
        );
        setSelectedLayers(undefined);
        break;

      case "mouseOver":
        const object = canvasAction.target as fabric.Object;
        const layer = layers.find(layer => layer.id === object.name);

        setLayerToHighlight(layer);
        break;
      case "mouseOut":
        setLayerToHighlight(undefined);
        break;
    }
  }, [editorContext?.canvasAction]);

  const numOfLayers = useMemo(() => layers.length, [layers]);

  const isPressed = useIsHotkeyPressed();

  const { setLayerAction, setCanvasAction } = editorContext || {};
  const updateSelectedLayer = useCallback(
    layer => {
      const isShiftKeyPressed = isPressed("shift");
      const updatedLayers = isShiftKeyPressed
        ? [...(selectedLayers || []), layer]
        : [layer];

      setSelectedLayers(updatedLayers);

      setCanvasAction!({
        target: layer.original,
        action: {
          type: "objectSelected",
          keyPressed: isPressed("shift") ? "shift" : undefined,
        },
      });

      setLayerAction?.({
        layer: layer,
        action: {
          type: "selected",
          data: updatedLayers,
        },
      });
    },
    [isPressed, selectedLayers, setCanvasAction, setLayerAction],
  );

  return (
    <DragDropContext
      onDragEnd={e => {
        const { source, destination } = e;
        if (!source || !destination) {
          message.error("There was system error.");
          return;
        }

        /**
         * //////// Constructing layerOrderAction
         * When we send layerOrderAction to Canvas.tsx, we have to becareful with the index.
         * The thing we have to understand before this process is that we are NOT DISPLAYING
         *  canvasArea (the area where user actually puts objects into) within the layer section.
         * This means that there will be -1 layer item displayed and when user deal with one less items when re-order layers.
         * So we have to add +1 to each index here.
         *
         * Also, the Background layer is not movable, meaning it will always stays on top of the layer list. If background was not set,
         *  this layer will simply be removed from the layer section.
         *
         * So two things to keep in mind.
         *  - Canvas area object is not in the layer section even tho this object is present within the canvas.
         *  - Background object is not movable.
         *
         * NOTE: indice are modified in onDrop event handler in the draggable component.
         */

        // stop if a dragged layer is trying to take the background's position
        const doesBackgroundLayerExist = layers.find(
          layer => layer.type === "canvas_bg",
        );

        const to = { index: destination.index };
        const from = { index: source.index };
        const copied = [...layers];

        if (doesBackgroundLayerExist && to.index === numOfLayers - 1) {
          message.error('You cannot re-order "Background".');

          return;
        }

        const fromObject = { ...copied[from.index] };

        // remove object from index
        copied.splice(from.index, 1);

        // add from object to index
        const reordered = [
          ...copied.slice(0, to.index),
          fromObject,
          ...copied.slice(to.index),
        ];

        setLayers(reordered);

        const fromIndex = numOfLayers - 1 - source.index;
        const toIndex = numOfLayers - 1 - destination.index;
        setLayerOrderAction({
          from: {
            index: fromIndex,
          },
          to: {
            index: toIndex,
          },
        });
      }}
    >
      <Droppable droppableId="droppable">
        {provided => {
          return (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={styles.ManageLayersContainer}
            >
              {layers.length === 0 && (
                <Empty
                  style={{ color: "white" }}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}

              {layers.map((layer, idx) => {
                const isDraggableLayer = !(
                  ["canvas_bg", "canvas_area"] as ExtendedObjectType[]
                ).includes(layer.type as ExtendedObjectType);

                const highlight =
                  layer.id === layerToHighlight?.id ||
                  !!selectedLayers?.find(tmpLayer => tmpLayer.id === layer.id);

                return (
                  <Draggable
                    key={layer.id}
                    draggableId={layer.id}
                    index={idx}
                    isDragDisabled={!isDraggableLayer}
                  >
                    {provided => {
                      return (
                        <LayerItem
                          layer={layer}
                          draggableProvided={provided}
                          highlight={highlight}
                          updateSelectedLayer={updateSelectedLayer}
                        />
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

export default ManageLayers;
