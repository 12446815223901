export const pricePerQuantity = [
  {
    quantity: {
      max: 49,
      min: 25,
    },
    simplex: 1.1,
    duplex: 1.21,
  },
  {
    quantity: {
      max: 99,
      min: 50,
    },
    simplex: 0.93,
    duplex: 1.01,
  },
  {
    quantity: {
      max: 249,
      min: 100,
    },
    simplex: 0.93,
    duplex: 1.01,
  },
  {
    quantity: {
      max: 499,
      min: 250,
    },
    simplex: 0.82,
    duplex: 0.89,
  },
  {
    quantity: {
      max: 999,
      min: 500,
    },
    simplex: 0.8,
    duplex: 0.87,
  },
];

export const taxRate = 0.04;
