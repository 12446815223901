import { Input, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import Highlighter from "react-highlight-words";

import { IOnNameRenderProps } from "./types";

const OnNameRender = ({
  value,
  record,
  searchValue,
  currentEditSessionId,
  onModifySession,
  onCurrentEditSessionIdChange,
}: IOnNameRenderProps) => {
  if (currentEditSessionId !== record.id) {
    return (
      <span
        onDoubleClick={() => {
          onCurrentEditSessionIdChange?.(record.id);
        }}
      >
        {searchValue && (
          <Highlighter
            autoEscape
            searchWords={[searchValue]}
            textToHighlight={value}
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          />
        )}
        {!searchValue && value}
      </span>
    );
  }
  return (
    <Input
      defaultValue={value}
      onKeyDown={event => {
        if (!["Escape", "Enter"].includes(event.key)) {
          return;
        }

        if (event.key === "Enter") {
          onModifySession?.(
            {
              ...record,
              name: event.currentTarget.value,
            },
            "update",
          );
        }
        onCurrentEditSessionIdChange?.("");
      }}
      suffix={
        <Button
          size="small"
          shape="circle"
          title="Cancel Session name edit"
          onClick={() => {
            onCurrentEditSessionIdChange?.("");
          }}
        >
          <CloseOutlined />
        </Button>
      }
    />
  );
};

export default OnNameRender;
