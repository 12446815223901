import { Button } from "antd";

interface IOfferImagePopUploadButton {
  disableUpload: boolean;
  uploadJBFunc: () => void;
}

const OfferImagePopoverUploadButton = ({
  disableUpload,
  uploadJBFunc,
}: IOfferImagePopUploadButton) => {
  return (
    <Button type="primary" disabled={disableUpload} onClick={uploadJBFunc}>
      Upload
    </Button>
  );
};

export default OfferImagePopoverUploadButton;
