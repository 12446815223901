import { EditTwoTone, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Popover, Select } from "antd";
import { FC, useEffect, useRef, useState } from "react";

import _ from "lodash";
import { IAssetBuildInstance } from "shared/types/assetBuilder";
import {
  FetchingDataStatus,
  ITemplate,
  ITemplateTag,
} from "shared/types/designStudio";
import "./TemplateSelectionList.scss";

interface TemplateSelectionListProps {
  templates: ITemplate[];
  instance?: IAssetBuildInstance;
  tags: ITemplateTag[];
  fetchingData: FetchingDataStatus;
  selectedFilterTags: string[] | null;
  searchBy: string;
}

interface TemplateSelectionListHandlers {
  onTemplateSelect: (template: ITemplate) => void;
  saveSelectTemplateSearch: (searcyBy: string) => void;
  filterTemplateTags: (tags: string[] | null) => void;
  fetchData: (dataType: FetchingDataStatus, query?: string) => void;
  setSearchBy: (searchBy: string) => void;
}

const TemplateSelectionList: FC<
  TemplateSelectionListProps & TemplateSelectionListHandlers
> = props => {
  const [tagFilterTemplates, setTagFilterTemplates] = useState<string[]>([]);

  const [copiedBool, setCopiedBool] = useState<boolean>(false);

  const focusedTemplate = useRef<HTMLImageElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (focusedTemplate && focusedTemplate.current) {
        focusedTemplate.current.scrollIntoView({
          block: "center",
          inline: "start",
          behavior: "smooth",
        });
      }
    }, 500);
  }, [props.instance]);

  const changeTagFilterTemplates = (
    value: string,
    selectOrDeselect: "select" | "deselect",
  ) => {
    const tagFilter = value;
    let tempArr = [...tagFilterTemplates];
    const index = tempArr.indexOf(tagFilter);

    if (index > -1) {
      tempArr.splice(index, 1);
    } else {
      if (selectOrDeselect === "select") {
        tempArr = [...tagFilterTemplates, tagFilter];
      }
    }

    setTagFilterTemplates(tempArr);

    props.filterTemplateTags(tempArr);
  };

  return (
    <>
      <Input
        placeholder="Search By Template Name"
        className="template-search-by-input"
        value={props.searchBy}
        prefix={<SearchOutlined />}
        allowClear={true}
        onChange={e => {
          props.setSearchBy(e.target.value);
          props.saveSelectTemplateSearch(e.target.value);
        }}
      />
      <div style={{ height: "10px" }}></div>
      <Select
        className="template-tags-select"
        mode="multiple"
        placeholder="Search By Tag"
        showSearch={true}
        onFocus={() => {
          !props.tags.length &&
            props.fetchingData === null &&
            props.fetchData("tags");
        }}
        loading={props.fetchingData === "tags"}
        value={props.selectedFilterTags || []}
        onSelect={(value: string) => {
          changeTagFilterTemplates(value, "select");
        }}
        onDeselect={(value: string) => {
          changeTagFilterTemplates(value, "deselect");
        }}
      >
        {props.tags.map(tag => {
          return (
            <Select.Option
              className="template-tag-item"
              value={tag.name}
              key={`${tag.name}-key`}
            >
              {tag.name}
            </Select.Option>
          );
        })}
      </Select>

      {props.templates.length === 0 ? (
        <div className="empty-message">No Templates</div>
      ) : (
        <ul className="template-list">
          {_.sortBy(props.templates, "name").map(template => {
            return (
              <li
                key={`template-${template.id}`}
                className="template-list-item"
              >
                <div
                  className={`template-thumbnail-container ${
                    props.instance?.template?.id === template.id
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => {
                    props.onTemplateSelect(template);
                  }}
                >
                  <img
                    className="template-thumbnail"
                    ref={
                      props.instance?.template?.id === template.id
                        ? focusedTemplate
                        : null
                    }
                    src={template.thumbnailUrl || undefined}
                    alt="template"
                  />
                  <Popover
                    content={copiedBool ? "Copied!" : "Copy To Clipboard"}
                  >
                    <Button
                      ghost
                      key="COPY"
                      icon={<EditTwoTone />}
                      style={{ float: "right", border: "none" }}
                      onClick={event => {
                        event.stopPropagation();
                        const domain = window.location.origin;
                        const url = `${domain}${"/design-studio/editor/templates/:targetId/editor-v2".replace(
                          ":targetId",
                          template.id ?? "",
                        )}`;
                        navigator?.clipboard?.writeText(url);
                        setCopiedBool(true);
                      }}
                    ></Button>
                  </Popover>
                </div>
                <h4 className="template-name">{template.name}</h4>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default TemplateSelectionList;
