import { useMemo, useRef, useState } from "react";
import { Badge, Select, Tooltip } from "antd";

import useOutsideClick from "shared/hooks/useOutsideClick";

import { titleCase } from "utils/helpers";
import { StatusOption } from "screens/adLibrary/utils";
import { QcStatus } from "shared/types/adLibrary";

interface IProps<T extends string> {
  status: T;
  statusOptions: StatusOption<T>[];
  tooltip?: string;
}
interface IHandlers<T extends string> {
  onStatusChange?: (status: T) => void;
  getStatusColor: (status: T) => string;
}

type IStatusSelectorProps<T extends string> = IProps<T> & IHandlers<T>;

const StatusSelector = <T extends string>({
  status,
  onStatusChange,
  statusOptions,
  getStatusColor,
  tooltip,
}: IStatusSelectorProps<T>) => {
  const timeoutIdRef = useRef<NodeJS.Timeout>();

  const [isHovering, setIsHovering] = useState(false);
  const [isSelecting, setIsSelecting] = useState(false);
  const color = getStatusColor(status);

  const targetRef = useOutsideClick(() => setIsHovering(false));

  const options = useMemo(() => statusOptions, [statusOptions]);
  return (
    <div
      onMouseEnter={event => {
        event.stopPropagation();
        setIsHovering(true);
      }}
      onMouseLeave={event => {
        event.stopPropagation();
        if (isSelecting) return;
        timeoutIdRef.current = setTimeout(() => {
          setIsHovering(false);
        }, 250);
      }}
    >
      <span ref={targetRef}>
        {!isHovering && (
          <Badge
            color={color}
            text={
              options.find(option => option.value === status)?.text ||
              titleCase(status)
            }
            style={{ fontSize: 20.5 }}
          />
        )}
        {isHovering && (
          <Tooltip title={tooltip} visible={!!tooltip}>
            <Select
              disabled={status === QcStatus.ERROR}
              value={status}
              onMouseEnter={() => {
                if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
              }}
              style={{ width: 230 }}
              onClick={() => setIsSelecting(!isSelecting)}
              onSelect={() => {
                setIsHovering(false);
              }}
              onChange={value => onStatusChange?.(value)}
            >
              {options.map(({ key, text, value }) => {
                const isOptionDisabled = value === QcStatus.ERROR;
                return (
                  <Select.Option
                    disabled={isOptionDisabled}
                    key={key}
                    value={value}
                  >
                    <Badge
                      color={getStatusColor(value)}
                      style={{ opacity: isOptionDisabled ? 0.5 : 1 }}
                      text={titleCase(text.replace("_", " "))}
                    />
                  </Select.Option>
                );
              })}
            </Select>
          </Tooltip>
        )}
      </span>
    </div>
  );
};

export default StatusSelector;
