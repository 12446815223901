import { Table } from "antd";
import { useColsList } from "./useColsList";
import { useMediaTable } from "./useMediaTable";

type Props = {
  isFetching: boolean;
  data?: Array<WDAsset | WDFolder>;
};

export const TableList = ({ data, isFetching }: Props) => {
  const { components, rowSelection } = useMediaTable();
  const columns = useColsList();

  return (
    <div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={data}
        loading={isFetching}
        rowSelection={rowSelection}
        components={components}
        pagination={false}
        scroll={{
          x: "max-content",
        }}
      />
    </div>
  );
};
