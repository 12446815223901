import { useState } from "react";
import { fabric } from "fabric";
import { useQuery } from "react-query";
import { fetchCanvasJson } from "shared/components/contextAPI/shared/RenderTemplate";
import { ITemplate } from "shared/types/designStudio";

export const useShowPreview = (template: ITemplate | null) => {
  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null);

  useQuery(
    ["templatePreview", template?.id, template?.canvasJsonUrl],
    () => {
      if (!template || !template.canvasJsonUrl) {
        return Promise.reject();
      }
      return fetchCanvasJson(template.canvasJsonUrl);
    },
    {
      enabled: !!template?.canvasJsonUrl,
      onSettled: (data, error) => {
        if (data && !error) {
          const newCanvas = new fabric.Canvas(document.createElement("canvas"));
          newCanvas.loadFromJSON(data, () => {
            setCanvas(newCanvas);
          });
        }
      },
    },
  );

  return {
    canvas,
  };
};
