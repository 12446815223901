/* eslint-disable react/display-name */
import { Modal, TableColumnProps } from "antd";

import FacebookAdStatusSwitch from "./uniqueColumns/FacebookAdStatusSwitch";
import AdLoadAdStatusBadge from "../shared/components/adsToLoadTable/AdLoadAdStatusBadge";

import {
  doesAdToLoadHaveQAErrorIssues,
  getAdLoadStatusBadgeDescription,
} from "../shared/utils";
import { compareString } from "utils/helpers";
import { reviewStepTableFilters } from "../shared/components/adsToLoadTable/constants";

import {
  IAdToLoadData,
  IAdLoadStatus,
  AdLoadAdStatusEnum,
} from "shared/types/adLibrary";
import { FacebookAdStatus } from "screens/adLibrary/facebookUtils/types";

type UniqueColumnsArgs = {
  adsToLoad: IAdToLoadData[];
  modifyAdsToLoad: (updatedAdsToLoad: IAdToLoadData[]) => void;
  statusSwitchDisplayConfirmation: boolean;
  setStatusSwitchDisplayConfirmation: (state: boolean) => void;
};

const returnAdToLoadAfterOnCheckedChange = (
  adToLoad: IAdToLoadData,
  checked: boolean,
) => ({
  ...adToLoad,
  facebookStatus: checked ? FacebookAdStatus.ACTIVE : FacebookAdStatus.PAUSED,
  adLoadStatus: {
    ...adToLoad.adLoadStatus,
    status: checked ? AdLoadAdStatusEnum.SUCCESS : AdLoadAdStatusEnum.DRAFT,
  },
});

const uniqueColumns = (
  args: UniqueColumnsArgs,
): Array<TableColumnProps<IAdToLoadData>> => {
  const onCount = args.adsToLoad.filter(
    adToLoad => adToLoad.facebookStatus === FacebookAdStatus.ACTIVE,
  ).length;
  const areAllOn = onCount === args.adsToLoad.length;

  const changeAdsStatus = (adsToLoad: IAdToLoadData[], checked: boolean) => {
    const onOk = () =>
      args.modifyAdsToLoad(
        adsToLoad.map(adToLoad =>
          returnAdToLoadAfterOnCheckedChange(adToLoad, checked),
        ),
      );

    if (checked && args.statusSwitchDisplayConfirmation) {
      args.setStatusSwitchDisplayConfirmation(false);
      return Modal.confirm({
        okText: "Yes",
        cancelText: "No",
        title: "Switch to On",
        content:
          "Switching ads to On will make them visible to the public. Do you want to continue?",
        onOk,
      });
    }

    onOk();
  };

  return [
    {
      width: 50,
      fixed: "left",
      align: "center",
      title: () => (
        <FacebookAdStatusSwitch
          size="small"
          checked={areAllOn}
          isForMultiple={true}
          indeterminate={!!onCount && !areAllOn}
          disabled={
            !!args.adsToLoad.filter(adToLoad =>
              doesAdToLoadHaveQAErrorIssues(adToLoad),
            ).length
          }
          onIndeterminateClick={() => {
            changeAdsStatus(args.adsToLoad, true);
          }}
          onCheckedChange={checked => {
            changeAdsStatus(args.adsToLoad, checked);
          }}
        />
      ),
      dataIndex: "facebookStatus",
      render: (status: FacebookAdStatus, record) => {
        return (
          <FacebookAdStatusSwitch
            size="small"
            disabled={doesAdToLoadHaveQAErrorIssues(record)}
            checked={status === FacebookAdStatus.ACTIVE}
            onCheckedChange={checked => {
              changeAdsStatus([record], checked);
            }}
          />
        );
      },
    },
    {
      width: 100,
      title: "Status",
      dataIndex: "adLoadStatus",
      ...reviewStepTableFilters.adLoadStatus,
      sorter: (a, b) =>
        compareString(a.adLoadStatus.status, b.adLoadStatus.status),
      render: (adLoadStatus: IAdLoadStatus, record) => (
        <AdLoadAdStatusBadge
          status={adLoadStatus.status}
          adLoadStatus={record.adLoadStatus}
          facebookStatus={record.facebookStatus}
          description={getAdLoadStatusBadgeDescription(record)}
        />
      ),
    },
  ];
};

export default uniqueColumns;
