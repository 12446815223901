import { FC, useCallback, useEffect, useRef, useState } from "react";
import { message, Button, Empty, Spin, Input } from "antd";
import { connect } from "react-redux";

import { ILifestyleImage, OfferData } from "shared/types/assetBuilder";
import { IConfigurationState, IConfig } from "shared/types/configuration";
import API from "services";

import "./ImageGalleryUploader.scss";
import GenericError from "shared/errors/GenericError";
import { AppState } from "redux/store";
import { User } from "redux/auth/auth.slice";

interface ImageGalleryUploaderProps {
  config?: IConfig;
  user?: User;
  offerData?: OfferData;
}

interface ImageGalleryUploaderHandlers {
  toggleUploader?: (show: boolean) => void;
  onImageUploadComplete?: (lifestyleImage: ILifestyleImage) => void;
}

const ImageGalleryUploader: FC<
  ImageGalleryUploaderProps & ImageGalleryUploaderHandlers
> = props => {
  const [uploading, setUploading] = useState<boolean>(false);
  const [image, setImage] = useState<{
    imageUri: string;
    imageType: string;
  }>();

  const { offerData } = props;
  const [lifestyleImage, setLifestyleImage] = useState<
    Partial<ILifestyleImage> | undefined
  >({
    year: offerData?.year ? parseInt(offerData.year) : undefined,
    make: offerData?.make,
    model: offerData?.model,
    trim: offerData?.trim,
  });

  const inputRef = useRef<HTMLInputElement>(null);

  const getInitialLifestyleImage = useCallback(
    (offerData?: OfferData): Partial<ILifestyleImage> => ({
      year: offerData?.year ? parseInt(offerData.year) : undefined,
      make: offerData?.make,
      model: offerData?.model,
      trim: offerData?.trim,
    }),
    [],
  );
  const resetUploader = useCallback(() => {
    if (!inputRef.current) return;

    inputRef.current.value = ""; // reset file input

    setImage(undefined);

    setUploading(false);

    setLifestyleImage(getInitialLifestyleImage(offerData));
  }, [getInitialLifestyleImage, offerData]);

  useEffect(() => {
    setLifestyleImage({
      year: offerData?.year ? parseInt(offerData.year) : undefined,
      make: offerData?.make,
      model: offerData?.model,
      trim: offerData?.trim,
    });
  }, [offerData]);

  return (
    <div className="image-selection-uploader">
      <div className="title">Upload Lifestyle Visual</div>
      <div className="info-fields">
        <div className="field year">
          <div className="label">Year</div>
          <Input
            value={lifestyleImage?.year}
            disabled={uploading}
            onChange={e => {
              e.preventDefault();

              const parsedYear = parseInt(e.target.value);

              if (!parsedYear) {
                message.warning("Invalid year.");

                return;
              }

              setLifestyleImage({
                ...(lifestyleImage || {}),
                year: parsedYear,
              });
            }}
          />
        </div>
        <div className="field make">
          <div className="label">Make</div>
          <Input
            value={lifestyleImage?.make}
            disabled={uploading}
            onChange={e => {
              e.preventDefault();

              setLifestyleImage({
                ...(lifestyleImage || {}),
                make: e.target.value,
              });
            }}
          />
        </div>

        <div className="field model">
          <div className="label">Model</div>
          <Input
            value={lifestyleImage?.model}
            disabled={uploading}
            onChange={e => {
              e.preventDefault();

              setLifestyleImage({
                ...(lifestyleImage || {}),
                model: e.target.value,
              });
            }}
          />
        </div>
        <div className="field trim">
          <div className="label">Trim (optional)</div>
          <Input
            value={lifestyleImage?.trim}
            disabled={uploading}
            onChange={e => {
              e.preventDefault();

              setLifestyleImage({
                ...(lifestyleImage || {}),
                trim: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <Spin spinning={uploading} tip="Uploading image...">
        <div className="input-file-button-container">
          <input
            ref={inputRef}
            type="file"
            accept="image/*"
            onChange={async e => {
              e.preventDefault();

              const { files } = e.target;
              if (!files || files.length !== 1) {
                message.error("There was an error while loading image.");

                return;
              }

              const newFile = files[0];

              if (!newFile) {
                message.error("There was error while loading image.");
                return;
              }

              const imageUri = await getImageUri(newFile);

              const imageType = newFile.type.split("/")[1];

              setImage({
                imageUri,
                imageType,
              });
            }}
          />
        </div>

        <div className="uploaded-image-container">
          {image?.imageUri && <img src={image.imageUri} alt="preview" />}

          {!image?.imageUri && <Empty description="No Image" />}
        </div>
      </Spin>
      <div className="button-container">
        <Button
          disabled={uploading}
          onClick={e => {
            e.preventDefault();

            resetUploader();

            props.toggleUploader?.(false);
          }}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          loading={uploading}
          onClick={e => {
            e.preventDefault();

            setUploading(true);

            setTimeout(async () => {
              if (!props.config) {
                message.error("There was system error.");

                resetUploader();

                return;
              }

              const { url, year, make, model, trim } = lifestyleImage || {};
              const validFields = !!year && !!make && !!model;
              if (!validFields) {
                message.warning("Not all fields are valid.");

                resetUploader();
                return;
              }

              if (!image?.imageUri) {
                message.warning("Image must be selected.");

                resetUploader();

                return;
              }

              const uploadedLifestyleImage = await uploadImage(
                {
                  url,
                  make,
                  year,
                  trim,
                  model,
                  createdBy: props.user?.email,
                } as ILifestyleImage,
                image.imageUri,
                image.imageType,
                props.config,
              );

              if (!uploadedLifestyleImage) return; // error message will be displayed within the uploadImage func

              props.onImageUploadComplete?.(uploadedLifestyleImage);

              resetUploader();
            }, 2000);
          }}
        >
          Upload
        </Button>
      </div>
    </div>
  );
};

const getImageUri = (file: File): Promise<string> => {
  return new Promise<string>(resolve => {
    const fileReader: FileReader = new FileReader();

    fileReader.onload = progressEvent => {
      if (!progressEvent.target) {
        // error
        message.error(
          "some technical issue has been raised! Please contact the IT support.",
        );
        return;
      }

      const dataUri = (progressEvent.target as FileReader).result as string;

      resolve(dataUri);
    };

    fileReader.readAsDataURL(file);
  });
};

const uploadImage = async (
  lifestyleImage: ILifestyleImage,
  imageUri: string,
  imageType: string,
  config: IConfig,
) => {
  const updatedBase64Image = imageUri.replace(
    /^data:image\/(jpg|jpeg|png);base64,/,
    "",
  );
  const request: RequestInfo = new Request(
    config.services.assetBuilder.uploadLifestyleImageUrl,
    {
      method: "POST",
      cache: "no-cache",
      body: JSON.stringify({
        lifestyleImage,
        imageBase64: updatedBase64Image,
        imageType,
      }),
    },
  );
  const { result, error } = await API.send<{
    result: { lifestyleImage: ILifestyleImage };
    error: GenericError;
  }>(request);

  if (error) {
    message.error(error.message);

    return;
  }

  const { lifestyleImage: createdLifestyleImage } = result;

  return createdLifestyleImage;
};

const mapStateToProps = (state: AppState) => {
  const {
    configuration: { config },
    auth: { user },
  } = state;

  return {
    config,
    user: user || undefined,
  };
};

export default connect(mapStateToProps, null)(ImageGalleryUploader);
