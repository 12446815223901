import React from "react";
import styles from "./SearchDateRange.module.scss";
import { Button, DatePicker } from "antd";
import { FilterDropdownProps } from "antd/lib/table/interface";

const SearchDateRange = (props: FilterDropdownProps) => {
  return (
    <div className={styles.dateFilter}>
      <DatePicker.RangePicker
        size="small"
        onChange={(dates, datesStr) => {
          const [start, end] = datesStr;
          props.setSelectedKeys([`${start},${end}`]);
          props.confirm({ closeDropdown: true });
        }}
      />
      <Button
        onClick={() => {
          props.clearFilters?.();
          props.setSelectedKeys([]);
        }}
        size="small"
        style={{ width: 90 }}
      >
        Reset
      </Button>
    </div>
  );
};

export default SearchDateRange;
