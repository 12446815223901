import { useCallback } from "react";
import { message } from "antd";

import { useQueryClient } from "react-query";

import { useLocation } from "react-router-dom";

import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { resetAdLoadChangedProp } from "redux/adLibrary/adLibrary.slice";
import { useAppShallowEqualSelector } from "shared/hooks/useAppSelector";

import { useModifySessionJson } from "shared/hooks/adLibrary/adHistory/useMutateSessionJson";

import { IAdHistorySession, IAdLoad } from "shared/types/adLibrary";

export const useUpdateSessionJson = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const queryClient = useQueryClient();

  const {
    adLoadReview,
    adLoadHistory,
    selectedAds = [],
    adLoadDestination,
    selectedAccounts = [],
  } = useAppShallowEqualSelector(({ adLibrary }) => ({
    adLoadReview: adLibrary.adLoad.review,
    selectedAds: adLibrary.adLoad.selectedAds,
    adLoadHistory: adLibrary.adLoad.adLoadHistory,
    adLoadDestination: adLibrary.adLoad.destination,
    selectedAccounts: adLibrary.selectedFacebookAccounts,
  }));

  const { sessionId } = adLoadHistory || {};

  const currentSessionId =
    sessionId || location.search.split("?session_id=")?.[1]?.split("&")?.[0];

  const currentSessionJson: IAdLoad | undefined = queryClient.getQueryData([
    "adHistorySessionJson",
    currentSessionId,
  ]);
  const currentSession: IAdHistorySession | undefined = (
    (queryClient.getQueryData(["adHistorySessions", currentSessionId]) ??
      []) as IAdHistorySession[]
  )?.[0];

  const { mutateAsync: mutateSessionJson } = useModifySessionJson({
    sessionId: currentSessionId,
    selectedAccounts,
  });

  return useCallback(() => {
    const sessionData: IAdLoad = currentSessionJson
      ? {
          ...currentSessionJson,
          selectedAds,
          adLoadHistory,
          review: adLoadReview,
          destination: adLoadDestination,
        }
      : {
          selectedAds,
          adLoadHistory,
          review: adLoadReview,
          destination: adLoadDestination,
          currentStep: null,
          adLoadParameters: null,
          facebookAdLoadDict: null,
          facebookDataIdsByAd: null,
          selectedAdForParameters: null,
          prevSelectedAdForParameters: null,
        };
    mutateSessionJson({
      sessionId: currentSessionId,
      sessionData,
    })
      .catch(() => {
        message.warning("Something went wrong while saving session data.");
      })
      .finally(() => {
        dispatch(resetAdLoadChangedProp());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedAds,
    selectedAccounts,
    adLoadDestination,
    adLoadHistory,
    adLoadReview,
    currentSessionJson,
    currentSession,
  ]);
};
