import {
  SyncOutlined,
  CheckCircleFilled,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { LoadStatus } from "screens/adLibrary/facebookUtils/adProto.types";

type Props = {
  status: LoadStatus;
  loadingSpin?: boolean;
};

const StatusIcon = ({ status, loadingSpin = true }: Props) => {
  switch (status) {
    case "loading":
      return (
        <SyncOutlined
          spin={loadingSpin}
          style={{
            color: "#1890FF",
          }}
        />
      );
    case "success":
      return (
        <CheckCircleFilled
          style={{
            color: "#52C41A",
          }}
        />
      );
    case "error":
      return (
        <ExclamationCircleFilled
          style={{
            color: "#FF4D4F",
          }}
        />
      );
    default:
      return null;
  }
};

export default StatusIcon;
