import { Tooltip, AutoComplete, TooltipProps } from "antd";

import { AccountUrl } from "shared/types/accountManagement";
import { dedupeBy } from "utils/helpers.array";

export interface IProps {
  width?: number;
  linkValue?: string;
  disabled?: boolean;
  urlLabels?: AccountUrl[];
  willCheckFullUrl?: boolean;
  forceHideTooltip?: boolean;
  tooltipProps?: Partial<TooltipProps>;
}

interface IHandlers {
  onChange?: (value: string, labelWasSelected?: boolean) => void;
  onSearch?: (value: string) => void;
  onBlur?: () => void;
}

export type LabelSelectWithAutoCompleteProps = IProps & IHandlers;

const LabelSelectWithAutoComplete = ({
  willCheckFullUrl = true,
  forceHideTooltip = false,
  disabled = false,
  linkValue,
  urlLabels,
  onChange,
  onSearch,
  onBlur,
  width,
  tooltipProps,
}: LabelSelectWithAutoCompleteProps) => {
  const uniqueUrlLabels = (urlLabels || []).filter(dedupeBy("name"));
  return (
    <>
      <Tooltip
        title={!forceHideTooltip ? linkValue || "" : ""}
        {...(tooltipProps ?? {})}
      >
        <AutoComplete
          allowClear={true}
          disabled={disabled}
          placeholder="Select a label or type free-form text"
          style={width ? { width: width } : { width: "100%" }}
          options={uniqueUrlLabels.map((label: { name: string }) => {
            return {
              key: label.name,
              value: label.name,
              className: "template-tag-item",
            };
          })}
          filterOption
          onChange={value => {
            onChange?.(value);
          }}
          onSelect={value => {
            onChange?.(value, true);
          }}
          onSearch={onSearch}
          onBlur={onBlur}
          value={linkValue}
          data-cy="select-label-input"
        />
      </Tooltip>
      {linkValue && willCheckFullUrl && !linkValue.startsWith("https://") && (
        <div style={{ color: "red" }}>
          <p style={{ lineHeight: "20px" }}>Link must start with https://</p>
        </div>
      )}
    </>
  );
};

export default LabelSelectWithAutoComplete;
