import { Select } from "antd";
import { SelectProps, useSelect } from "./useSelect";

export const SelectMeta = (props: SelectProps) => {
  const { mode, options, value, tagRender } = useSelect(props);

  return (
    <Select
      {...props}
      mode={mode}
      value={value}
      options={options}
      tagRender={tagRender}
      allowClear
    />
  );
};
