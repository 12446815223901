import { Collapse } from "antd";
import { memo } from "react";

interface IProps {
  children: React.ReactNode;
  header: string;
  padding?: string | number;
}

const ComponentWrapper = ({ children, header, padding }: IProps) => {
  return (
    <Collapse
      className="collapse-container design-studio-filter-section no-spacing"
      defaultActiveKey={"title"}
    >
      <Collapse.Panel
        key="title"
        header={header}
        collapsible={"disabled"}
        showArrow={false}
      >
        <div style={{ backgroundColor: "white", padding: padding ?? 15 }}>
          {children}
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};

export default memo(ComponentWrapper);
