import { FontMappingItemType } from "./manageText/FontProperty.types";

export const getPresetColors = (colors: string[], color: string) => {
  const colorFound = colors.some(existing => existing === color);

  if (colorFound) return colors;

  const presetColors = [...colors, color];
  if (presetColors.length > 15) presetColors.shift();

  return presetColors;
};

export const loadFontV2 = async (font: FontMappingItemType) => {
  const { fontName, url, fontWeight: weight, fontStyle: style } = font;
  if (!fontName || !url) return;

  try {
    const fontFace = new FontFace(fontName, `url(${url})`, { weight, style });
    await fontFace.load();
    return fontFace;
  } catch (err) {
    return undefined;
  }
};

export const loadFontFromBrand = async (fontName: string, fontURL: string) => {
  try {
    const fontFace = new FontFace(fontName, `url(${fontURL})`);
    const response = await fontFace.load();

    document.fonts.add(response);

    return fontFace;
  } catch (err) {
    return undefined;
  }
};
