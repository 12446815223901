import { ColumnsType } from "antd/lib/table";
import { useCallback } from "react";
import { AdShellEntry, AllEntryTypes } from "../AssignDestsTable.types";
import { terms } from "utils/terms";
import {
  compareStringBy,
  getFiltersFromData,
  onFilterBy,
} from "utils/helpers.table";
import { startCase } from "lodash";
import RecordUrlLabelSelect from "./columns/RecordUrlLabelSelect";
import RecordCtaButtonSelect from "./columns/RecordCtaButtonSelect";
import NestedItemNameCell from "screens/adLibrary/adLoadV2/shared/NestedItemNameCell";
import RecordProductCatalogSelect from "./columns/RecordProductCatalogSelect";
import RecordProductSetSelect from "./columns/RecordProductSetSelect";
import { TableType } from "../AssignDestsTable";
import StoreTag from "screens/adLibrary/adLoadV2/shared/StoreTag";

const columnsToHideByTableType = {
  assignProductSets: ["destinationUrl", "displayUrl", "ctaButton"],
  assignDestinations: ["productCatalogId", "productSetId"],
} satisfies Record<TableType, string[]>;

export const useColumns = (
  dataSource: AdShellEntry[],
  tableType: TableType,
): ColumnsType<AllEntryTypes> => {
  const getFilters = getFiltersFromData(dataSource);

  const renderName = useCallback((_, record: AllEntryTypes) => {
    const thumbnail = "thumbnail" in record ? record.thumbnail : undefined;
    const itemType = record.type === "EverythingAd" ? record.type : undefined;

    return (
      <NestedItemNameCell
        name={record.name}
        thumbnail={thumbnail}
        itemType={itemType}
        wrapperStyle={{
          marginLeft: "children" in record ? undefined : 24,
        }}
      />
    );
  }, []);

  const renderUrlRecord = useCallback(
    (field: "destinationUrl" | "displayUrl") =>
      function render(_: any, record: AllEntryTypes) {
        if (!(field in record)) return null;

        return <RecordUrlLabelSelect record={record} field={field} />;
      },
    [],
  );

  const getAdShellSorter =
    (key: keyof AllEntryTypes) => (a: AllEntryTypes, b: AllEntryTypes) => {
      if (a.type !== "AdShell" || b.type !== "AdShell") return 0;

      return compareStringBy(key)(a, b);
    };

  const columns = [
    {
      title: "Ad Name",
      dataIndex: "name",
      key: "name",
      render: renderName,
      sorter: getAdShellSorter("name"),
      onCell: () => ({
        style: {
          minWidth: 150,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          verticalAlign: "middle",
        },
      }),
      filters: getFilters("name"),
      onFilter: onFilterBy("name"),
    },
    {
      title: "Format",
      dataIndex: "format",
      key: "format",
      width: 150,
      className: "text-dimmed",
      render: (_, record) => {
        if (
          tableType === "assignDestinations" &&
          "convertToVideo" in record &&
          record.convertToVideo
        ) {
          return "Video";
        }
        return startCase(record.format);
      },
      sorter: getAdShellSorter("format"),
      filters: getFilters("format"),
      onFilter: onFilterBy("format"),
    },
    {
      title: terms.accountName,
      dataIndex: "storeName",
      key: "storeName",
      width: 200,
      render: (_, record) => {
        if (record.type !== "AdShell") return null;

        return <StoreTag storeName={record.storeName} />;
      },
      sorter: getAdShellSorter("storeName"),
      filters: getFilters("storeName"),
      onFilter: onFilterBy("storeName"),
    },
    {
      title: "Product Catalog",
      dataIndex: "productCatalogId",
      key: "productCatalogId",
      width: 250,
      render: (_, record) => {
        return <RecordProductCatalogSelect record={record} />;
      },
    },
    {
      title: "Product Set",
      dataIndex: "productSetId",
      key: "productSetId",
      render: (_, record) => {
        return <RecordProductSetSelect record={record} />;
      },
      onCell: () => ({
        style: {
          minWidth: 250,
        },
      }),
    },
    {
      title: "Destination URL",
      dataIndex: "destinationUrl",
      key: "destinationUrl",
      width: 250,
      render: renderUrlRecord("destinationUrl"),
    },
    {
      title: "Display URL",
      dataIndex: "displayUrl",
      key: "displayUrl",
      width: 250,
      render: renderUrlRecord("displayUrl"),
    },
    {
      title: "CTA Button",
      dataIndex: "ctaButton",
      key: "ctaButton",
      onCell: () => ({
        style: {
          minWidth: 150,
        },
      }),
      render: (_, record: AllEntryTypes) => {
        return <RecordCtaButtonSelect record={record} />;
      },
    },
  ] satisfies ColumnsType<AllEntryTypes>;

  return columns.filter(
    column => !columnsToHideByTableType[tableType].includes(column.dataIndex),
  );
};
