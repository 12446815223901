import { Fragment, memo, ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Checkbox, Radio, Input, Row, Col, message } from "antd";
import { SaveOutlined } from "@ant-design/icons";

import { IDisclosure } from "shared/types/legalLingo";
import { Label } from "shared/components/text/Label";
import MultipleStoreSelect from "shared/components/MultipleAccountSelect";
import MultipleBrandSelect from "shared/components/MultipleBrandSelect";
import MultipleLocationSelect from "shared/components/MultipleLocationSelect";

import API from "services";

import DisclosuresV2Collapse from "./editDisclosure/CollapseFields";
import { validateFieldsDisclosure } from "./utils/disclosureValidationUtils";

import styles from "./EditDisclosure.module.scss";
import { IApiResponse } from "shared/types/shared";

message.config({ maxCount: 1 });

interface IEditDisclosureProps {
  disclosureName?: string;
}
interface IEditDisclosureHandlers {
  onCreateDisclosure?: (discToCreate: IDisclosure) => void;
  onUpdateDisclosure?: (discToUpdate: IDisclosure) => void;
  onClose?: () => void;
  validation?: (disclosure: IDisclosure) => boolean;
}

type PropsType = IEditDisclosureProps & IEditDisclosureHandlers;

const defaultDisclosure: IDisclosure = {
  id: 0,
  name: "",
  vin: true,
  condition: [],
  location: [],
  oem: [],
  store: [],
  disclosures: [],
};

const KEY_MSG_SAVE = "Saving Disclosure";

const EditDisclosure = ({
  disclosureName,
  onCreateDisclosure,
  onUpdateDisclosure,
  validation,
  onClose,
}: PropsType): ReactElement => {
  const [activeKey, setActiveKey] = useState<string>("");
  const [disclosure, setDisclosure] = useState<IDisclosure>(defaultDisclosure);
  const [mode, setMode] = useState<"new" | "edit">("new");
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [keyName, setKeyName] = useState<string | undefined>();

  const { editId: discName } = useParams<{ editId: string }>();

  const getDisclosureByName = async (name: string) => {
    try {
      const { result } = await API.services.legalLingoV2.getDisclosure<
        IApiResponse<IDisclosure>
      >({
        id: name,
      });

      setDisclosure({ ...result!, id: 0 });
      setKeyName(result?.name);

      return true;
    } catch (error) {
      if ((error as Error).message.includes("ItemNotFoundException")) {
        setErrorMessage("Disclosure not found");
      } else {
        setErrorMessage("Error loading disclosure");
      }

      return false;
    }
  };

  useEffect(() => {
    if (errorMessage) {
      message.error(errorMessage);
      onClose && onClose();
    }
  }, [errorMessage, onClose]);

  useEffect(() => {
    if (disclosureName) {
      getDisclosureByName(disclosureName);
      setMode("edit");
      return;
    }

    if (discName) {
      getDisclosureByName(discName);
      setMode("edit");
    }
  }, [discName, disclosureName]);

  const messageSuccess = () =>
    message.success({
      content: "Disclosure saved successfully",
      key: "save-disclosure",
    });

  const handleSubmit = async () => {
    const { error: hasError, message: validateErrorMessage } =
      validateFieldsDisclosure(disclosure);

    if (hasError) {
      message.error(validateErrorMessage);
      return;
    }

    if (!validation?.(disclosure)) {
      message.error(`Disclosure with same parameters already exists`);
      return;
    }

    message.loading({
      content: "Saving Disclosure",
      key: "save-disclosure",
    });

    if (mode === "new") {
      try {
        await API.services.legalLingoV2.createDisclosure(disclosure);

        message.success({
          content: "Disclosure created successfully",
          key: KEY_MSG_SAVE,
        });

        onCreateDisclosure?.(disclosure);
      } catch ({ message: exceptionMessage }) {
        message.error({
          content: exceptionMessage,
          key: KEY_MSG_SAVE,
        });

        return;
      }
    }

    if (mode === "edit") {
      try {
        await API.services.legalLingoV2.updateDisclosure(disclosure);

        await API.services.legalLingoV2.updateDisclosure({
          ...disclosure,
          keyName,
        });

        messageSuccess();

        onUpdateDisclosure?.(disclosure);
      } catch ({ message: exceptionMessage }) {
        message.error({
          content: exceptionMessage,
          key: KEY_MSG_SAVE,
        });

        return;
      }
    }
  };

  return (
    <div className={styles.editContainer}>
      <div className={styles.recordContainerV2}>
        <div className={styles.editSectionHeader}>Parameters</div>
        <div className={styles.editSectionContent}>
          <Row gutter={24}>
            <Col span={24}>
              <Row gutter={24}>
                <Col span={5}>
                  <Label>Disclosure Name</Label>
                  <Input
                    placeholder={
                      mode === "edit" ? disclosure.name : "Disclosure Name"
                    }
                    value={disclosure.name}
                    onChange={e => {
                      const name = e.target.value.replace(/\s/g, "-");

                      setDisclosure({
                        ...disclosure,
                        name,
                      });
                    }}
                  />
                </Col>
                <Col span={5}>
                  <Label required>VIN / VINLess</Label>
                  <Radio.Group
                    onChange={({ target }) => {
                      setDisclosure({
                        ...disclosure,
                        vin: target.value === "vin",
                      });
                    }}
                    defaultValue={disclosure.vin ? "vin" : "vinless"}
                    value={disclosure.vin ? "vin" : "vinless"}
                    style={{ marginLeft: "1em" }}
                  >
                    <Radio value={"vin"}>VIN</Radio>
                    <Radio value={"vinless"}>VINLess</Radio>
                  </Radio.Group>
                </Col>
                <Col span={5}>
                  <Label required={true}>Condition(s)</Label>
                  <Checkbox.Group
                    defaultValue={disclosure.condition}
                    value={disclosure.condition}
                    onChange={newColumns =>
                      setDisclosure({
                        ...disclosure,
                        condition: newColumns as string[],
                      })
                    }
                  >
                    <Row justify="space-around" align="top">
                      <Col span={24}>
                        {["New", "Used", "CPO"].slice(0, 12).map(con => (
                          <Fragment key={con}>
                            <Checkbox key={con} value={con}>
                              {con}
                            </Checkbox>
                          </Fragment>
                        ))}
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={5}>
                  <Label>Location</Label>
                  <MultipleLocationSelect
                    style={{ width: "100%" }}
                    defaultValue={disclosure?.location}
                    selectedLocations={disclosure?.location}
                    onChange={(value: string[]) => {
                      setDisclosure({
                        ...disclosure,
                        location: value,
                      });
                    }}
                  />
                </Col>
                <Col span={5}>
                  <Label>OEM</Label>
                  <MultipleBrandSelect
                    style={{ width: "100%" }}
                    defaultValue={disclosure?.oem}
                    selectedBrands={disclosure?.oem}
                    onChange={(value: string[]) => {
                      setDisclosure({
                        ...disclosure,
                        oem: value,
                      });
                    }}
                  />
                </Col>
                <Col span={5}>
                  <Label>Store</Label>
                  <MultipleStoreSelect
                    style={{ width: "100%" }}
                    defaultValue={disclosure?.store}
                    selectedDealers={disclosure?.store}
                    onChange={(value: string[]) => {
                      setDisclosure({
                        ...disclosure,
                        store: value,
                      });
                    }}
                  />
                </Col>
                <Col span={9}>
                  <Button
                    className="legal-lingo-edit-save"
                    icon={<SaveOutlined />}
                    onClick={async () => {
                      handleSubmit();
                    }}
                  >
                    {"Save Changes & Exit"}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <br />
      <div className={styles.recordContainerV2}>
        <div className={styles.editSectionHeader}>Disclosures</div>
        <div className={styles.editSectionContent}>
          <DisclosuresV2Collapse
            activeDisclosureCollapseKey={activeKey}
            currentDisclosureEdit={disclosure}
            setCurrDisc={setDisclosure}
            setActiveDisclosureCollapseKey={key => {
              setActiveKey(key);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(EditDisclosure);
