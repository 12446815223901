import { Col, Row } from "antd";
import styles from "./DynamicText.module.scss";

import { PanelTemplate } from "./dynamicText/PanelTemplate";
import { PanelTextVariables } from "./dynamicText/PanelTextVariables";

export const DynamicText = () => {
  return (
    <Row className={styles.container}>
      <Col span={12} className={styles.left}>
        <PanelTextVariables />
      </Col>
      <Col span={12} className={styles.right}>
        <PanelTemplate />
      </Col>
    </Row>
  );
};
