import { SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, Input } from "antd";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { useState } from "react";
import styles from "./FilterDropdownWithFilterSearch.module.scss";

export const FilterIcon = ({ filtered }: { filtered: boolean }) => {
  return <SearchOutlined color={filtered ? "#1890ff" : undefined} />;
};

export const FilterDropdownWithFilterSearch = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  filters,
}: FilterDropdownProps) => {
  const [searchValue, setSearchValue] = useState("");

  return (
    <div style={{ padding: 8 }} data-cy="search-filter-dropdown">
      <Input
        data-cy="filter-search-input"
        prefix={<SearchOutlined />}
        placeholder={`Search in filters`}
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        className={styles.searchInput}
      />

      <Checkbox.Group
        className={styles.flexCheckboxGroup}
        value={selectedKeys}
        onChange={checkedValues => setSelectedKeys(checkedValues as string[])}
      >
        {filters?.map(filter => {
          const visible = searchValue
            ? (filter.text as string)
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            : true;

          return (
            <Checkbox
              data-cy={visible ? "filter-search-checkbox" : undefined}
              key={filter.value as string}
              value={filter.value}
              className={styles.checkbox}
              style={{ display: visible ? undefined : "none" }}
            >
              {filter.text}
            </Checkbox>
          );
        })}
      </Checkbox.Group>
      <Button
        onClick={() => {
          clearFilters && clearFilters();
          setSelectedKeys([]);
          setSearchValue("");
        }}
        size="small"
        className={styles.resetButton}
      >
        Reset
      </Button>
      <Button
        data-cy="filter-search-apply-button"
        type="primary"
        onClick={() => {
          confirm({
            closeDropdown: true,
          });
        }}
        icon={<SearchOutlined />}
        size="small"
        className={styles.confirmButton}
      >
        Filter
      </Button>
    </div>
  );
};
