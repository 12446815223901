import { Field } from "shared/components/dataListURL/types";
import { DelimitedArrayParam, StringParam } from "use-query-params";
import {
  checkArrayIncludes,
  checkFilterMatch,
  compareString,
  titleCase,
} from "utils/helpers";
import { AdLoadSessionTableEntry } from "../types";

export type AdSessionField = Field<AdLoadSessionTableEntry>;

type Fields = Partial<Record<keyof AdLoadSessionTableEntry, AdSessionField>>;

export type IAdSessionFields = keyof Fields;

export const adSessionFields: Fields = {
  loadStatus: {
    filterFn: (value, record) => checkArrayIncludes(value, record.loadStatus),
    sorterFn: (a, b) => compareString(a.loadStatus, b.loadStatus),
    queryParamConfigFilter: DelimitedArrayParam,
    display: titleCase,
  },
  name: {
    filterFn: (value, record) =>
      checkFilterMatch(value.toString(), record.name),
    sorterFn: (a, b) => compareString(a.name, b.name),
    queryParamConfigFilter: StringParam,
  },
  lastUpdatedAt: {
    sorterFn: (a, b) => a.lastUpdatedAt - b.lastUpdatedAt,
  },
  lastUpdatedBy: {
    filterFn: (value, record) =>
      checkFilterMatch(value.toString(), record.lastUpdatedBy),
    sorterFn: (a, b) =>
      compareString(a.lastUpdatedBy.toString(), b.lastUpdatedBy.toString()),
    queryParamConfigFilter: StringParam,
  },
  createdAt: {
    sorterFn: (a, b) => a.createdAt - b.createdAt,
    defaultSortOrder: "descend",
  },
  createdBy: {
    sorterFn: (a, b) => compareString(a.createdBy, b.createdBy),
    filterFn: (value, record) =>
      checkFilterMatch(value?.toString(), record.createdBy.toString()),
    queryParamConfigFilter: StringParam,
  },
};

export const adSessionFieldKeys = Object.keys(adSessionFields) as Array<
  keyof typeof adSessionFields
>;
