import { memo } from "react";
import { IAccount } from "shared/types/accountManagement";
import GenericAccountSelect, {
  AccountFilters,
} from "./select/GenericAccountSelect";

interface IProps {
  selectedDealer?: string;
  defaultValue?: string;
  onChange?(selectedDealer: string, dealers?: IAccount[]): void;
  onFocus?: React.FocusEventHandler<HTMLElement>;
  autoFocus?: boolean;
  loading?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  className?: string;
  filters?: AccountFilters;
}

function SingleAccountSelect({
  selectedDealer,
  defaultValue,
  onChange,
  onFocus,
  autoFocus,
  loading,
  disabled,
  style,
  className,
  filters,
}: IProps) {
  return (
    <GenericAccountSelect
      style={style}
      className={className}
      autoFocus={autoFocus}
      value={selectedDealer}
      defaultValue={defaultValue}
      placeholder={"Select Account"}
      onFocus={onFocus}
      onChange={onChange}
      disabled={disabled}
      loading={loading}
      filters={filters}
    />
  );
}

export default memo(SingleAccountSelect);
