import { createContext, FC } from "react";
import { FeedRow, TRule } from "shared/types/assetExporter";
import { IConfig } from "shared/types/configuration";

type AssetExporterContext = AssetExporterContextProps &
  AssetExporterContextHandlers;

type AssetExporterContextProps = {
  feedRows: FeedRow[];
  smartColumns: Record<string, TRule[]>;
};

type AssetExporterContextHandlers = {
  processFeed?: () => void;
};

const context = createContext<AssetExporterContext | null>(null);

////////////////////
// Defining Provider
interface IAssetExporterContextProps {
  config?: IConfig;
}

const Provider: FC<IAssetExporterContextProps> = props => {
  const state: AssetExporterContext = {
    feedRows: [],
    smartColumns: {},
    processFeed: () => {
      // fill
    },
  };

  return <context.Provider value={state}>{props.children}</context.Provider>;
};

export default Provider;
