import { AdType } from "screens/adLibrary/facebookUtils/types";
import { useFetchInstantExperiences } from "shared/hooks/adLibrary/useFetchInstantExperiences";
import {
  ElementType,
  IAdToLoadData,
  IInstantExperience,
  InstantExperienceElementStyle,
} from "shared/types/adLibrary";
import { IAccount } from "shared/types/accountManagement";
import { useSelectedStores } from "../../../shared/hooks/reviewStep/useGetRequiredData";
import { getStoreMatch, getUrlValue } from "../../../shared/utils";
import { useAdsTreeData } from "../adsTree/adsTreeContext";
import { getEverythingAdKey } from "../adsTree/helpers.adsTree";
import {
  AdShellEntry,
  CarouselItemEntry,
  EntryFormat,
  EverythingAdCarouselElementItemEntry,
  EverythingAdElementEntry,
  EverythingAdEntry,
} from "../AssignValuesTable.types";
import { useCurrentAdsToLoad } from "../currentAdsToLoadContext";

const adFormatToEntryFormatMap = {
  [AdType.Still]: "Still",
  [AdType.Video]: "Video",
  [AdType.InstantExperience]: "Instant Experience",
  [AdType.Collection]: "Collection",
  [AdType.Carousel]: "Carousel",
  [AdType.AIA]: "AIA",
  [AdType.DPA]: "DPA",
  [AdType.FTA]: "FTA",
} satisfies Record<AdType, EntryFormat>;

const eaElementTypeToEntryFormatMap = {
  PHOTO: "Image",
  BUTTON: "Button",
  FOOTER: "Button",
  CAROUSEL: "Carousel",
  ELEMENT_GROUP: "Product Set",
  VIDEO: "Video",
} satisfies Record<ElementType, EntryFormat>;

export const useDataSource = (): AdShellEntry[] => {
  const { currentAdsToLoad: adsToLoad } = useCurrentAdsToLoad();
  const { selectedStores } = useSelectedStores();
  const { data: instantExperiences = [] } = useFetchInstantExperiences();
  const { checkedKeys } = useAdsTreeData();

  return adsToLoad
    .map(adToLoad => {
      return getAdShellEntry({
        adToLoad,
        instantExperiences,
        selectedStores,
      });
    })
    .filter(({ key }) => checkedKeys.includes(key));
};

export const getAdShellEntry = ({
  adToLoad,
  instantExperiences,
  selectedStores,
}: {
  adToLoad: IAdToLoadData;
  instantExperiences: IInstantExperience[];
  selectedStores: IAccount[];
}): AdShellEntry => {
  const { ad, key: adShellKey } = adToLoad;
  const entryFormat = adFormatToEntryFormatMap[ad.type];
  const basicEntry = {
    key: adShellKey,
    name: ad.inputParameters.name ?? "",
    adShellKey,
    type: "AdShell",
  } satisfies Partial<AdShellEntry>;

  switch (entryFormat) {
    case "Still":
    case "Video": {
      return {
        ...basicEntry,
        format: entryFormat,
        destinationUrl: ad.inputParameters.destinationUrl,
        displayUrl: ad.visuals.displayUrl,
        thumbnail: ad.visuals.thumbnail,
        ctaButton: ad.visuals.ctaButtonText,
      };
    }
    case "Carousel": {
      return {
        ...basicEntry,
        format: entryFormat,
        children: ad.visuals.cards?.map<CarouselItemEntry>((card, index) => ({
          key: `${adShellKey}_Card_${index}`,
          adShellKey,
          name: `Card ${index + 1}`,
          destinationUrl: card.destinationUrl,
          format: card.videoUrl ? "Video" : "Image",
          thumbnail: card.thumbnail,
          type: "CarouselItem",
          cardIndex: index,
          ctaButton: card.ctaButtonText,
        })),
      };
    }
    case "Instant Experience":
    case "Collection": {
      const everythingAdId = ad.visuals.destination?.instantExperienceId;

      const everythingAdEntry =
        everythingAdId &&
        getEverythingAdEntry({
          everythingAdId,
          adShellKey,
          adToLoad,
          parentIds: [adShellKey],
          instantExperiences,
          selectedStores,
        });

      return {
        ...basicEntry,
        format: entryFormat,
        thumbnail: ad.visuals.thumbnail,
        children: everythingAdEntry ? [everythingAdEntry] : undefined,
      };
    }
    case "AIA":
    case "DPA":
    case "FTA": {
      return {
        ...basicEntry,
        format: entryFormat,
      };
    }
    default: {
      const _exhaustiveCheck: never = entryFormat;
      throw Error(`Unknown ad format: ${_exhaustiveCheck}`);
    }
  }
};

const getEverythingAdEntry = ({
  everythingAdId,
  adToLoad,
  parentIds,
  instantExperiences,
  selectedStores,
}: {
  everythingAdId: string;
  adToLoad: IAdToLoadData;
  adShellKey: string;
  parentIds: string[];
  instantExperiences: IInstantExperience[];
  selectedStores: IAccount[];
}): EverythingAdEntry | undefined => {
  const everythingAd = instantExperiences?.find(ie => ie.id === everythingAdId);
  if (!everythingAd?.id) return;

  const everythingAdKey = getEverythingAdKey({
    targetId: everythingAd.id,
    parentIds,
  });

  const children = getEverythingAdElementEntries({
    everythingAdId: everythingAd.id,
    adToLoad,
    parentIds: [...parentIds, everythingAd.id],
    instantExperiences,
    selectedStores,
  });

  return {
    adShellKey: adToLoad.key,
    key: everythingAdKey,
    type: "EverythingAd",
    name: everythingAd.name ?? "Everything Ad",
    format: "Everything Ad",
    children,
  };
};

function getEverythingAdElementEntries({
  everythingAdId,
  adToLoad,
  parentIds,
  instantExperiences,
  selectedStores,
}: {
  everythingAdId: string;
  adToLoad: IAdToLoadData;
  parentIds: string[];
  instantExperiences: IInstantExperience[];
  selectedStores: IAccount[];
}): EverythingAdElementEntry[] | undefined {
  const instantExperience = instantExperiences.find(
    ie => ie.id === everythingAdId,
  );
  if (!instantExperience?.id) return;
  const { account, ieUrlValues, key: adShellKey } = adToLoad;
  const selectedStore = getStoreMatch(selectedStores, account);
  const labels = selectedStore?.labels;

  return instantExperience.body_elements?.map<EverythingAdElementEntry>(
    element => {
      const elementType = element.element_type;

      const elementKey = getEverythingAdKey({
        targetId: element.id,
        parentIds,
      });
      const elementCommonProps = {
        name: element.name ?? eaElementTypeToEntryFormatMap[elementType],
        adShellKey,
        key: elementKey,
        type: "EverythingAdElement",
      } satisfies Partial<EverythingAdElementEntry>;

      switch (elementType) {
        case "PHOTO": {
          if (
            element.style &&
            element.style !== InstantExperienceElementStyle.FIT_TO_WIDTH
          ) {
            return {
              ...elementCommonProps,
              format: "Image",
              hasDestination: false,
              thumbnail: element.url,
            };
          }

          const destinationIeId = element.destination?.instantExperienceId;
          const everythingAdEntry =
            destinationIeId &&
            getEverythingAdEntry({
              everythingAdId: destinationIeId,
              instantExperiences,
              adShellKey,
              adToLoad,
              selectedStores,
              parentIds: [...parentIds, element.id],
            });

          const elementUrlValue = ieUrlValues?.[elementKey];
          const destinationUrl =
            elementUrlValue ??
            getUrlValue(element.destination?.urlLabel, labels);

          return {
            ...elementCommonProps,
            format: "Image",
            hasDestination: true,
            thumbnail: element.url,
            ...(everythingAdEntry
              ? { children: [everythingAdEntry] }
              : { destinationUrl }),
          };
        }
        case "BUTTON": {
          const destinationIeId = element.destination?.instantExperienceId;
          const everythingAdEntry =
            destinationIeId &&
            getEverythingAdEntry({
              everythingAdId: destinationIeId,
              instantExperiences,
              adShellKey,
              adToLoad,
              selectedStores,
              parentIds: [...parentIds, element.id],
            });

          const elementUrlValue = ieUrlValues?.[elementKey];
          const destinationUrl =
            elementUrlValue ??
            getUrlValue(element.destination?.urlLabel, labels);

          return {
            ...elementCommonProps,
            format: "Button",
            ...(everythingAdEntry
              ? { children: [everythingAdEntry] }
              : {
                  destinationUrl,
                }),
          };
        }
        case "FOOTER": {
          const childButtonElement = element.child_elements[0];
          const newParentIds = [...parentIds, element.id];

          const destinationIeId =
            childButtonElement.destination?.instantExperienceId;
          const everythingAdEntry =
            destinationIeId &&
            getEverythingAdEntry({
              everythingAdId: destinationIeId,
              instantExperiences,
              adShellKey,
              adToLoad,
              selectedStores,
              parentIds: [...newParentIds, childButtonElement.id],
            });

          const childButtonElementKey = getEverythingAdKey({
            targetId: childButtonElement.id,
            parentIds: newParentIds,
          });
          const elementUrlValue = ieUrlValues?.[childButtonElementKey];
          const destinationUrl =
            elementUrlValue ??
            getUrlValue(childButtonElement.destination?.urlLabel, labels);

          return {
            ...elementCommonProps,
            key: childButtonElementKey,
            format: "Button",
            ...(everythingAdEntry
              ? { children: [everythingAdEntry] }
              : {
                  destinationUrl,
                }),
          };
        }
        case "CAROUSEL": {
          const children =
            element.child_elements.map<EverythingAdCarouselElementItemEntry>(
              (childPhotoElement, index) => {
                const childPhotoElementKey = getEverythingAdKey({
                  targetId: childPhotoElement.id,
                  parentIds: [...parentIds, element.id],
                });
                const destEverythingAdId =
                  childPhotoElement.destination?.instantExperienceId;
                const everythingAdEntry =
                  destEverythingAdId &&
                  getEverythingAdEntry({
                    everythingAdId: destEverythingAdId,
                    instantExperiences,
                    adShellKey,
                    adToLoad,
                    selectedStores,
                    parentIds: [...parentIds, element.id, childPhotoElement.id],
                  });

                const elementUrlValue = ieUrlValues?.[childPhotoElementKey];
                const destinationUrl =
                  elementUrlValue ??
                  getUrlValue(childPhotoElement.destination?.urlLabel, labels);

                return {
                  adShellKey,
                  key: childPhotoElementKey,
                  name: `Card ${index + 1}`,
                  type: "EverythingAdElement",
                  format: "Image",
                  thumbnail: childPhotoElement.url,
                  hasDestination: true,
                  ...(everythingAdEntry
                    ? { children: [everythingAdEntry] }
                    : { destinationUrl }),
                };
              },
            );
          return {
            ...elementCommonProps,
            format: "Carousel",
            children,
          };
        }
        case "VIDEO": {
          return {
            ...elementCommonProps,
            format: "Video",
            videoUrl: element.url,
            thumbnail: element.thumbnail,
          };
        }
        case "ELEMENT_GROUP": {
          return {
            ...elementCommonProps,
            format: "Product Set",
            productCatalogId: adToLoad.productCatalog?.id,
            productSetId: adToLoad.productSet?.id,
          };
        }
        default: {
          const _exhaustiveCheck: never = elementType;
          throw Error(`Unknown element type: ${_exhaustiveCheck}`);
        }
      }
    },
  );
}
