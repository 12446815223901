import { useMutation, useQueryClient } from "react-query";
import { getErrorMessage } from "utils/errorMessage";
import { ICreateBrandResult, ILogos } from "shared/types/brandsAccounts";
import { uploadImagesToS3 } from "./uploadImageToS3";
import { formatBrandForTable } from "./useGetFormattedBrands";
import API from "services";
import { UploadFile } from "antd/lib/upload/interface";
import { uploadFontsToS3 } from "./uploadFontsToS3";
import { useBrandsAccountsContext } from "screens/brandsAccountsManagement/contexts/BrandsAccountsContext";
import {
  errorNotification,
  successNotification,
} from "shared/components/customNotification/Notification";
import { BRANDS_PAGINATED_QUERY_KEY } from "./useFetchBrands";

interface ICreateBrandParams {
  name: string;
  logos: ILogos;
  fonts: UploadFile[];
}

const createBrand = async ({ logos, fonts, name }: ICreateBrandParams) => {
  try {
    const uploadedFontsPromise = uploadFontsToS3(fonts);
    const uploadedLogosPromise = uploadImagesToS3(logos, "brands-management");

    const [uploadedFonts, uploadedLogos] = await Promise.all([
      uploadedFontsPromise,
      uploadedLogosPromise,
    ]);

    const { error, result } =
      await API.privServices.oemManagement.createOem<ICreateBrandResult>({
        oem_name: name,
        logo_urls_from_S3: JSON.stringify(uploadedLogos),
        enabled: true,
        event_logos: "",
        created_at: Date.now(),
        updated_at: Date.now(),
        logo_url: uploadedLogos.squareImagesFromS3[0] ?? "",
        fonts: uploadedFonts,
      });

    if (error) throw new Error(error.message);
    if (!result) throw new Error("Error creating a brand");

    return result;
  } catch (error) {
    const errMsg = getErrorMessage(error);
    throw new Error(errMsg);
  }
};

interface IUseCreateBrand {
  onSuccess: () => void;
}

export const useCreateBrand = ({ onSuccess }: IUseCreateBrand) => {
  const queryClient = useQueryClient();
  const { setFormTargetElementForUpdate } = useBrandsAccountsContext();

  return useMutation<ICreateBrandResult, Error, ICreateBrandParams>(
    createBrand,
    {
      onSuccess: newBrand => {
        queryClient.invalidateQueries(BRANDS_PAGINATED_QUERY_KEY);

        setFormTargetElementForUpdate(
          formatBrandForTable(newBrand.createdOem, undefined),
        );
        onSuccess();
        successNotification({
          messageLabel: "Brand created successfully",
          size: "big",
        });
      },
      onError: error => {
        errorNotification({
          messageLabel:
            error.message === "Brand name already exists."
              ? error.message
              : "Error creating a brand",
        });
      },
    },
  );
};
