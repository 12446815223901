import { useMutation, useQueryClient } from "react-query";
import API from "services";
import {
  QUERY_KEY_ASSET_BATCHES,
  QUERY_KEY_FEED_LIST,
  TAssetBatch,
  TAssetBatchId,
} from "shared/types/assetExporter";

import { message } from "antd";
import { getErrorMessage } from "utils/errorMessage";
import { COLUMN_ASSET_BATCHES } from "shared/constants/assetExporter";

export const KEY_MESSAGE_DELETE = "delete-asset-batch";

const apiDeleteAssetBatch = async ({
  feedId,
  assetBatchId,
  undo,
}: TAssetBatchId) => {
  try {
    const { deleteAssetBatch } = API.services.assetExporter;
    const { result, error } = await deleteAssetBatch(
      feedId,
      assetBatchId,
      undo,
    );
    if (error) throw new Error(error.message);
    if (!result) throw new Error("Unable to delete asset batch");
    return result;
  } catch (error) {
    const errMsg = getErrorMessage(error);
    throw new Error(errMsg);
  }
};

type TDeleteAssetBatchReq = TAssetBatch & { undo?: boolean };

export const useDeleteAssetBatch = (
  showMessage: (assetBatch: TAssetBatch, allowUndo?: boolean) => void,
) => {
  const queryClient = useQueryClient();

  return useMutation<TAssetBatchId, Error, TDeleteAssetBatchReq>(
    args =>
      apiDeleteAssetBatch({
        feedId: args.feedId,
        assetBatchId: args.assetBatchId || "",
        undo: args.undo,
      }),
    {
      onMutate: assetBatch => {
        const { feedId, assetBatchId, undo } = assetBatch;

        const queryKey = [QUERY_KEY_ASSET_BATCHES, feedId];

        queryClient.cancelQueries(queryKey);

        const previousData: TAssetBatch[] | undefined =
          queryClient.getQueryData<TAssetBatch[]>(queryKey);

        if (!previousData) return;

        if (undo) {
          showMessage(assetBatch);
          const updatedAddData = [...previousData, assetBatch];
          queryClient.setQueryData(queryKey, updatedAddData);
          return { previousData };
        }

        const updatedData = previousData.filter(
          item => item.assetBatchId !== assetBatchId,
        );

        queryClient.setQueryData(queryKey, updatedData);

        showMessage(assetBatch, true);

        return { previousData };
      },
      onError: (error, assetBatch) => {
        queryClient.invalidateQueries([
          QUERY_KEY_ASSET_BATCHES,
          assetBatch.feedId,
        ]);
        const messageKey = `${KEY_MESSAGE_DELETE}-${assetBatch.feedId}-${assetBatch.assetBatchId}`;
        message.destroy(messageKey);
        message.error(error.message);
      },
      onSuccess: (_, assetBatch) => {
        queryClient.invalidateQueries([
          QUERY_KEY_ASSET_BATCHES,
          assetBatch.feedId,
        ]);

        queryClient.invalidateQueries([QUERY_KEY_FEED_LIST]);
        queryClient.invalidateQueries(COLUMN_ASSET_BATCHES);
      },
    },
  );
};
