import { find } from "lodash";
import {
  IInstantExperience,
  InstantExperienceBodyElement,
} from "shared/types/adLibrary";

export const getInstantExperiencesDeep = (
  instantExperiences: IInstantExperience[],
  rootInstantExpId?: string,
): IInstantExperience[] => {
  if (!rootInstantExpId) {
    return [];
  }

  const instantExperience = find(instantExperiences, { id: rootInstantExpId });

  const nestedInstExpIds = getInstExpIdsFromElements(
    instantExperience?.body_elements,
  );

  const nestedInstantExperiences = nestedInstExpIds.flatMap(nestedIe =>
    getInstantExperiencesDeep(instantExperiences, nestedIe),
  );

  return instantExperience
    ? [instantExperience].concat(...nestedInstantExperiences)
    : nestedInstantExperiences;
};

const getInstExpIdsFromElements = (
  elements?: InstantExperienceBodyElement[],
) => {
  if (!elements) return [];

  return elements.reduce<string[]>((prevInstantExpIds, element) => {
    const destinationIEId =
      "destination" in element
        ? element?.destination?.instantExperienceId
        : undefined;

    if (destinationIEId) {
      return prevInstantExpIds.concat(destinationIEId);
    }

    const destinationIEIds: string[] =
      "child_elements" in element
        ? element?.child_elements
            ?.map(el => el.destination?.instantExperienceId)
            ?.filter((ieId): ieId is string => !!ieId)
        : [];

    if (destinationIEIds?.length) {
      return prevInstantExpIds.concat(destinationIEIds);
    }

    return prevInstantExpIds;
  }, []);
};
