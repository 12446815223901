import {
  useContext,
  useMemo,
  createContext,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
  Key,
} from "react";
import { useGetPmaxCampaigns } from "shared/hooks/adLibrary/pmax/useGetPmaxCampaigns";
import { GoogleAdCampaignInternal } from "shared/types/adLibrary";
import { useCampaignDrawer } from "./useCampaignDrawer";
import { FormInstance } from "antd";
import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";
import { pmaxCampaignsFields } from "./pmaxCampaignsTable/fields";
import { usePmaxCampaignsIds } from "screens/adLibrary/shared/hooks/dataListHooks";

type CampaignDrawer = {
  open: boolean;
  data: GoogleAdCampaignInternal;
  assetGroupIds: Key[];
};

type PmaxCampaignsContextType = {
  campaignDrawer: CampaignDrawer;
  setCampaignDrawer: (values: Partial<CampaignDrawer>) => void;
  form: FormInstance;
  loadDrawerOpen: boolean;
  setLoadDrawerOpen: Dispatch<SetStateAction<boolean>>;
  selectedCampaignIds: string[];
  setSelectedCampaignIds: (ids?: string[]) => void;
  filteredCampaigns: GoogleAdCampaignInternal[];
  campaigns: GoogleAdCampaignInternal[];
  selectedCampaigns: GoogleAdCampaignInternal[];
  isLoading: boolean;
};

const Context = createContext<PmaxCampaignsContextType | null>(null);

export const usePmaxCampaignsContext = () => {
  const ctx = useContext(Context);

  if (!ctx) {
    throw new Error(
      "usePmaxCampaignsContext must be used within a PmaxCampaignsDataProvider",
    );
  }
  return ctx;
};

const PmaxCampaignsContext = ({ children }: { children: ReactNode }) => {
  const {
    selectedIds: selectedCampaignIds,
    setSelectedItemIds: setSelectedCampaignIds,
  } = usePmaxCampaignsIds();
  const [loadDrawerOpen, setLoadDrawerOpen] = useState(false);

  const { campaignDrawer, setCampaignDrawer, form } = useCampaignDrawer();

  const {
    data: filteredCampaigns,
    isLoading,
    originalData: campaigns,
  } = useGetPmaxCampaigns();

  const selectedCampaigns = useMemo(
    () =>
      campaigns.filter(campaign => selectedCampaignIds.includes(campaign.id)),
    [campaigns, selectedCampaignIds],
  );
  return (
    <Context.Provider
      value={{
        filteredCampaigns,
        campaigns,
        selectedCampaigns,
        isLoading,
        campaignDrawer,
        setCampaignDrawer,
        selectedCampaignIds,
        setSelectedCampaignIds,
        loadDrawerOpen,
        setLoadDrawerOpen,
        form,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const PmaxCampaignsDataProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <DataListURLProvider fields={pmaxCampaignsFields}>
      <PmaxCampaignsContext>{children}</PmaxCampaignsContext>
    </DataListURLProvider>
  );
};
