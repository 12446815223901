import styles from "./Portal.module.scss";
import { Typography } from "antd";
import { useState } from "react";
import { Navigation } from "../../shared/components/Preview/Navigation";

export const RightPanel = () => {
  const [_, setCurrentAssetIndex] = useState(0); // This will be used to navigate between assets
  const handleNavigate = (index: number) => setCurrentAssetIndex(index);
  const totalAssetCount = 5; // This will be replaced with the actual total asset count

  return (
    <div className={styles.rightPanelWrapper}>
      <div className={styles.rightPanel}>
        <div className={styles.navigationContainer}>
          <Typography.Text strong className={styles.previewTitle}>
            Preview
          </Typography.Text>
          <Navigation
            totalAssetCount={totalAssetCount}
            onNavigate={handleNavigate}
          />
        </div>
      </div>
    </div>
  );
};
