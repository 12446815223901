import { Collapse } from "antd";
import { Dispatch, SetStateAction, useState } from "react";
import { shallowEqual } from "react-redux";
import * as assetBuilderActions from "redux/assetBuilder/assetBuilder.slice";
import {
  IAssetBuilderState,
  ISavedOrderState,
  ISelectedOffer,
  OfferData,
} from "shared/types/assetBuilder";
import { OfferType } from "shared/types/shared";
import styles from "./NewOfferCollapse.module.scss";
import CollapsableHeader from "./newOfferCollapse/CollapsableHeader";
import NewOfferTypeCollapse from "./newOfferCollapse/NewOfferTypeCollapse";
import { useAppSelector } from "shared/hooks/useAppSelector";
import API from "services";
import { useAppDispatch } from "shared/hooks/useAppDispatch";

interface Props {
  feedId: string;
  savedOrder: ISavedOrderState;
  duplicatedOfferData?: Partial<OfferData>;
}
interface Handlers {
  setAddingMode: Dispatch<SetStateAction<boolean>>;
}

const NewOfferCollapse = (props: Props & Handlers) => {
  const { feedId, setAddingMode, savedOrder, duplicatedOfferData } = props;
  const [selectedOfferTypes, setSelectedOfferTypes] = useState<OfferType[]>([]);
  const [isActive, setIsActive] = useState(true);
  const [vin, setVin] = useState("");
  const [activeKey, setActiveKey] = useState<string[]>([]);
  const collapsePanelKey = isActive ? "new-offer-collapse-panel" : "";

  const { offerData } = useAppSelector(
    ({ assetBuilder }: { assetBuilder: IAssetBuilderState }) => ({
      offerData: assetBuilder.offerData,
    }),
    shallowEqual,
  );

  const dispatch = useAppDispatch();

  const closeCollapse = async () => {
    setIsActive(false);
    setAddingMode(false);
    await dispatch(
      assetBuilderActions.saveOffer(false, "create", undefined, feedId),
    );

    const {
      selectedOrder: { dealer_code },
    } = savedOrder;

    const updatedSavedOrder: ISavedOrderState<Record<string, any>> = {
      ...savedOrder,
      selectedOffers: [
        ...(savedOrder.selectedOffers ?? []),
        {
          offerData: { ...offerData, dealerCode: dealer_code },
          offers: selectedOfferTypes,
        } as ISelectedOffer,
      ],
    };
    if (selectedOfferTypes.length > 0) {
      dispatch(
        assetBuilderActions.setSavedOrder({ savedOrder: updatedSavedOrder }),
      );
    }
    dispatch(assetBuilderActions.commitOrder());
    await API.services.assetBuilder.updateOrder(updatedSavedOrder);
    dispatch(assetBuilderActions.setBlankOfferData());
  };

  return (
    <Collapse
      className={styles.OfferCollapse}
      defaultActiveKey={collapsePanelKey}
      activeKey={collapsePanelKey}
    >
      <Collapse.Panel
        className={styles.CollapsePanel}
        key={collapsePanelKey}
        header={
          <CollapsableHeader
            selectedOfferTypes={selectedOfferTypes}
            setSelectedOfferTypes={setSelectedOfferTypes}
            closeCollapse={closeCollapse}
            vin={vin}
            setActiveKey={setActiveKey}
            duplicatedOfferData={duplicatedOfferData}
          />
        }
        showArrow={false}
      >
        <NewOfferTypeCollapse
          selectedOfferTypes={selectedOfferTypes}
          setSelectedOfferTypes={setSelectedOfferTypes}
          savedOrder={props.savedOrder}
          sectionKey={"new"}
          setVin={setVin}
          activeKey={activeKey}
          setActiveKey={setActiveKey}
          duplicatedOfferData={duplicatedOfferData}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

export default NewOfferCollapse;
