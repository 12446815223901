import { V3ExtQuery, V3ExtQueryVariables, useV3ExtQuery } from "graphql/v3.gen";
import { QueryHookOptions } from "@apollo/client";

type V3QueryProps = QueryHookOptions<V3ExtQuery, V3ExtQueryVariables>;

export const useV3Query = <ResultType = V3ExtQuery,>(
  props: V3QueryProps & { variables: V3ExtQueryVariables },
) => {
  const result = useV3ExtQuery(props);

  return {
    ...result,
    data: result.data as Omit<V3ExtQuery, "v2AccessQueryExt"> & {
      v2AccessQueryExt: ResultType;
    },
  };
};
