import React, { useRef } from "react";
import { useImageSize } from "shared/hooks/useImageSize";
import styles from "./ImagePreview.module.scss";

type Props = {
  url: string;
  onClose: () => void;
};

const ImagePreview = (props: Props) => {
  const imgRef = useRef<HTMLImageElement | null>(null);
  const { url, onClose } = props;

  const onOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (imgRef.current?.contains(e.target as Node)) return;

    onClose();
  };

  const { width, height } = useImageSize(url);
  const readyToRender = width && height;

  return readyToRender ? (
    <div className={styles.overlay} onClick={onOverlayClick}>
      <div className={styles.content}>
        <img
          width={width}
          height={height}
          ref={imgRef}
          src={url}
          alt="preview"
        />
      </div>
    </div>
  ) : null;
};

export default ImagePreview;
