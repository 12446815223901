/* eslint-disable react/display-name */
import { useMemo, useEffect, useState, useCallback } from "react";
import { Button, Drawer } from "antd";

import AdsToLoadTable from "../shared/components/AdsToLoadTable";
import DrawerOkButton from "./reviewStepInnerDrawer/DrawerOkButton";
import AssignProductSetsExpandable from "./reviewStepInnerDrawer/expandable/AssignProductSetsExpandable";

import { getReviewAndQAIssuesForInnerStep } from "../shared/components/adsToLoadTable/utils";

import { DRAWER_WIDTH, reviewStepTitles } from "../shared/constants";
import { innerStepColumnKeys } from "../shared/components/adsToLoadTable/constants";

import {
  AdLoadAdStatusEnum,
  AdLoadColumnKey,
  IAdToLoadData,
  IInstantExperience,
  ReviewStepKeys,
} from "shared/types/adLibrary";
import { OnRowDataChangeArgs } from "../shared/types";
import { IAccount } from "shared/types/accountManagement";
import { ExpandableConfig } from "antd/lib/table/interface";

import drawerStyles from "../../AdLoadDrawer.module.scss";
import footerStyles from "../AdLoadDrawerFooter.module.scss";
import expandableStyles from "./reviewStepInnerDrawer/expandable.module.scss";
import { cloneDeep, get, set } from "lodash";
import { CurrentAdsToLoadProvider } from "./reviewStepInnerDrawerV2/currentAdsToLoadContext";
import AssignValuesSection from "./reviewStepInnerDrawerV2/AssignValuesSection";

const {
  CONVERT_TO_VIDEO,
  REVIEW_DESTINATION_URLS,
  ASSIGN_PRODUCT_SETS_AD_LEVEL,
  ASSIGN_PRODUCT_SETS_IE_LEVEL,
} = ReviewStepKeys;

type ExpandableProps = ExpandableConfig<IAdToLoadData> | undefined;

interface IReviewStepInnerDrawerProps {
  adsToLoad: IAdToLoadData[];
  selectedStores?: IAccount[];
  productCatalogIds?: string[];
  instantExperiences?: IInstantExperience[];
  currentInnerStep?: ReviewStepKeys | null;
}

export interface IReviewStepInnerDrawerHandlers {
  onClose?: () => void;
  onSave?: (args: { data: IAdToLoadData[]; willRecheckAds?: boolean }) => void;
}

const ReviewStepInnerDrawer = ({
  adsToLoad,
  selectedStores,
  productCatalogIds,
  currentInnerStep,
  instantExperiences,

  onClose,
  onSave,
}: IReviewStepInnerDrawerProps & IReviewStepInnerDrawerHandlers) => {
  const selectedColumnKeys = useMemo(() => {
    if (!currentInnerStep) return [];
    return innerStepColumnKeys[currentInnerStep];
  }, [currentInnerStep]);

  const isSelectionEnabled = currentInnerStep === CONVERT_TO_VIDEO;

  /** Ads with data currently being edited */
  const [currentAdsToLoad, setCurrentAdsToLoad] = useState<IAdToLoadData[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>(
    currentAdsToLoad.map(adToLoad => adToLoad.key),
  );
  const [wereCollectionsConverted, setWereCollectionsConverted] =
    useState(false);
  const [isConvertingToVideo, setIsConvertingToVideo] = useState(false);

  useEffect(() => {
    const newAdsToLoad = adsToLoad.filter(ad => {
      return currentInnerStep
        ? !!getReviewAndQAIssuesForInnerStep([ad], currentInnerStep).length ||
            (currentInnerStep === REVIEW_DESTINATION_URLS && ad)
        : ad;
    });
    setWereCollectionsConverted(false);
    setCurrentAdsToLoad(newAdsToLoad);

    if (currentInnerStep === CONVERT_TO_VIDEO) {
      setSelectedRowKeys(newAdsToLoad.map(adToLoad => adToLoad.key));
    }
  }, [adsToLoad, currentInnerStep]);

  const multipApplyProductSets = useCallback(
    (modifiedAdToLoad: IAdToLoadData) => {
      const adId = modifiedAdToLoad.ad.id;
      const accountId = modifiedAdToLoad.account.account_id;
      const catalogId = modifiedAdToLoad.productCatalog?.id;

      const newAdsToLoad = currentAdsToLoad.map(adToLoad => {
        if (adToLoad.key === modifiedAdToLoad.key) {
          return { ...adToLoad, productSet: modifiedAdToLoad.productSet };
        }

        const willBeModified =
          adToLoad.ad.id === adId &&
          adToLoad.account.account_id === accountId &&
          adToLoad.productCatalog?.id === catalogId &&
          !adToLoad.productSet?.id;
        if (willBeModified) {
          return { ...adToLoad, productSet: modifiedAdToLoad.productSet };
        }

        return adToLoad;
      });

      setCurrentAdsToLoad(newAdsToLoad);
    },
    [currentAdsToLoad],
  );

  const updateAd = useCallback(
    (modifiedAdToLoad: IAdToLoadData) => {
      const updatedAdsToLoad = currentAdsToLoad.map(adToLoad => {
        return adToLoad.key === modifiedAdToLoad.key
          ? modifiedAdToLoad
          : adToLoad;
      });

      setCurrentAdsToLoad(updatedAdsToLoad);
    },
    [currentAdsToLoad],
  );

  const updateRelatedAds = useCallback(
    (modifiedAdToLoad: IAdToLoadData, path: string[], newValue: any) => {
      const updatedAdsToLoad = currentAdsToLoad.map(adToLoad => {
        if (
          adToLoad.key === modifiedAdToLoad.key ||
          (adToLoad.ad.id === modifiedAdToLoad.ad.id &&
            get(adToLoad, path) === undefined)
        ) {
          return set(cloneDeep(adToLoad), path, newValue);
        }

        return adToLoad;
      });

      setCurrentAdsToLoad(updatedAdsToLoad);
    },
    [currentAdsToLoad],
  );

  const updateAdsStrategy = useCallback(
    (
      currentInnerStep: ReviewStepKeys | undefined | null,
      columnToUpdate?: AdLoadColumnKey,
    ) => {
      if (
        currentInnerStep === ASSIGN_PRODUCT_SETS_AD_LEVEL &&
        columnToUpdate === "Product Set"
      ) {
        return multipApplyProductSets;
      }

      if (currentInnerStep === CONVERT_TO_VIDEO) {
        switch (columnToUpdate) {
          case "Destination URL":
            return (modifiedAdToLoad: IAdToLoadData) =>
              updateRelatedAds(
                modifiedAdToLoad,
                ["ad", "inputParameters", "destinationUrl"],
                modifiedAdToLoad.ad.inputParameters.destinationUrl,
              );
          case "Display URL":
            return (modifiedAdToLoad: IAdToLoadData) =>
              updateRelatedAds(
                modifiedAdToLoad,
                ["ad", "visuals", "displayUrl"],
                modifiedAdToLoad.ad.visuals.displayUrl,
              );
          case "CTA Button":
            return (modifiedAdToLoad: IAdToLoadData) =>
              updateRelatedAds(
                modifiedAdToLoad,
                ["ad", "visuals", "ctaButtonText"],
                modifiedAdToLoad.ad.visuals.ctaButtonText,
              );
          default:
            return updateAd;
        }
      }

      return updateAd;
    },
    [updateRelatedAds, updateAd, multipApplyProductSets],
  );

  const handleDataRowChange = useCallback(
    (args: OnRowDataChangeArgs) => {
      const updateAdsToLoad = updateAdsStrategy(currentInnerStep, args.column);
      updateAdsToLoad(args.data);
    },
    [currentInnerStep, updateAdsStrategy],
  );

  const okButtonPropsToUse = {
    adsToLoad,
    currentAdsToLoad,
    selectedRowKeys,
    currentInnerStep,
    isConvertingToVideo,
    wereCollectionsConverted,
    onSave,
    onClose,
    setCurrentAdsToLoad,
    setIsConvertingToVideo,
    setWereCollectionsConverted,
  };

  const expandableProp: ExpandableProps = useMemo(() => {
    const allowExpandable =
      currentInnerStep &&
      [ASSIGN_PRODUCT_SETS_IE_LEVEL, REVIEW_DESTINATION_URLS].includes(
        currentInnerStep,
      );
    if (!allowExpandable) return;

    const commonProps = {
      expandIconColumnIndex: 1,
      defaultExpandedRowKeys: currentAdsToLoad.map(adToLoad => adToLoad.key),
      expandedRowClassName: () => expandableStyles.exandableRow,
    };

    if (currentInnerStep === REVIEW_DESTINATION_URLS) {
      return commonProps;
    }

    if (currentInnerStep === ASSIGN_PRODUCT_SETS_IE_LEVEL) {
      return {
        ...commonProps,
        expandedRowRender: (record: IAdToLoadData) => (
          <AssignProductSetsExpandable
            record={record}
            productCatalogIds={productCatalogIds}
            currentAdsToLoad={currentAdsToLoad}
            instantExperiences={instantExperiences ?? []}
            setCurrentAdsToLoad={setCurrentAdsToLoad}
          />
        ),
      };
    }
  }, [
    currentInnerStep,
    currentAdsToLoad,
    productCatalogIds,
    instantExperiences,
  ]);

  const statusesToignore =
    currentInnerStep === ReviewStepKeys.ASSIGN_PRODUCT_SETS_AD_LEVEL
      ? [AdLoadAdStatusEnum.WARNING]
      : [AdLoadAdStatusEnum.WARNING, AdLoadAdStatusEnum.ERROR];

  return (
    <Drawer
      width={DRAWER_WIDTH}
      closable={false}
      bodyStyle={{
        padding:
          currentInnerStep !== ReviewStepKeys.REVIEW_DESTINATION_URLS
            ? undefined
            : "2px 10px",
      }}
      footer={
        <div className={`${footerStyles.footerDiv} ${footerStyles.rightDiv}`}>
          <Button type="link" onClick={onClose}>
            Cancel
          </Button>
          <DrawerOkButton {...okButtonPropsToUse} />
        </div>
      }
      onClose={onClose}
      visible={!!currentInnerStep}
      className={drawerStyles.adLoadDrawer}
      title={
        currentInnerStep && (
          <strong>{reviewStepTitles[currentInnerStep]}</strong>
        )
      }
    >
      {currentInnerStep === ReviewStepKeys.REVIEW_DESTINATION_URLS ? (
        <CurrentAdsToLoadProvider
          value={{
            currentAdsToLoad,
            setCurrentAdsToLoad,
            handleDataRowChange,
          }}
        >
          <AssignValuesSection />
        </CurrentAdsToLoadProvider>
      ) : (
        <AdsToLoadTable
          ignoreStatuses={statusesToignore}
          disabledCheckboxes={false}
          expandable={expandableProp}
          adsToLoad={currentAdsToLoad}
          selectedRowKeys={selectedRowKeys}
          productCatalogIds={productCatalogIds}
          instantExperiences={instantExperiences}
          selectedColumnKeys={selectedColumnKeys}
          isSelectionEnabled={isSelectionEnabled}
          wereCollectionsConverted={wereCollectionsConverted}
          onRowDataChange={handleDataRowChange}
          onRowSelectionChange={newSelectedRowKeys => {
            setSelectedRowKeys(newSelectedRowKeys as string[]);
          }}
          scrollOffset={280}
        />
      )}
    </Drawer>
  );
};

export default ReviewStepInnerDrawer;
