import { OperationMode } from "shared/types/inputValues";
import {
  ISingleLogoObject,
  IUploadImageFormInput,
  TUploadGroup,
  TUploadSetLogosInDictionary,
  TUploadSteps,
} from "shared/types/uploadManagement";

interface IUploadImageTypesToS3Props {
  key: TUploadSetLogosInDictionary;
  keysToBeUploaded: string[];
  logosInDictionary: Record<TUploadSetLogosInDictionary, ISingleLogoObject[]>;
  mode: OperationMode;

  uploadImages: (
    imagesToUpload: IUploadImageFormInput[],
    featureName: string,
    group: TUploadGroup,
    mode: OperationMode,
    logoKeysToBeUploaded?: string[],
    newOemButtonClicked?: boolean,
  ) => void;
}

export const uploadImageTypesToS3 = async ({
  key,
  keysToBeUploaded,
  logosInDictionary,
  mode,
  uploadImages,
}: IUploadImageTypesToS3Props) => {
  // If we reach this method, logosInDictionary[key] has at least one unsubmitted logo in it
  const type = key.split("Logos")[0]; // horizontal, vertical, square
  const logosToAdd = logosInDictionary[key];

  const tempArr: IUploadImageFormInput[] = [];
  for (const logo of logosToAdd) {
    if (logo[type as TUploadSteps]) {
      tempArr.push(logo[type as TUploadSteps] as IUploadImageFormInput);
    }
  }

  uploadImages(
    tempArr,
    "dealer-management",
    key.includes("Event")
      ? (`${type[0].toUpperCase()}${type.slice(1)}Event` as TUploadGroup)
      : (`${type[0].toUpperCase()}${type.slice(1)}` as TUploadGroup),
    mode,
    keysToBeUploaded,
    true,
  );
};
