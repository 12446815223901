import { DatePicker, Input as AntdInput, Select as AntdSelect } from "antd";
import { ColumnType } from "antd/lib/table";
import classNames from "classnames";
import { cloneDeep, get, set } from "lodash";
import moment from "moment";
import { useState, useRef, useEffect, ReactNode } from "react";
import { dateFormat } from "shared/constants/dataManagement";
import { formatDateValue } from "utils/helpers";
import styles from "./EditableCell.module.scss";

export enum EditableCellType {
  Input,
  MultipleInput,
  Date,
  Select,
}

export interface SelectOption {
  key: number | string;
  value: any;
  text: string;
}

interface EditableCellProps<T> {
  title: ReactNode;
  editable: boolean;
  editableCellType?: EditableCellType;
  record: T;
  dataIndex: string[];
  selectOptions?: SelectOption[];
  children?: ReactNode;
  isEditing?: boolean;
  getClassNameOnError?: (record: T) => string;
  handleSave: (record: T) => void;
}

const EditableCell = <T extends Record<string, unknown>>({
  title,
  editable,
  editableCellType = EditableCellType.Input,
  record,
  dataIndex,
  selectOptions,
  children,
  getClassNameOnError,
  handleSave,
  isEditing,
  ...restProps
}: EditableCellProps<T> & ColumnType<T>) => {
  const [editing, setEditing] = useState(isEditing);
  const [value, setValue] = useState<string | undefined>(
    record ? get(record, dataIndex) : undefined,
  );
  const inputRef = useRef<AntdInput>(null);

  useEffect(() => {
    if (editing && inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const save = () => {
    toggleEdit();
    const editedRecord = set(cloneDeep(record), dataIndex, value);
    handleSave(editedRecord);
  };

  const getInput = () => {
    switch (editableCellType) {
      case EditableCellType.Select: {
        return (
          <AntdSelect
            className={styles.input}
            ref={inputRef}
            value={value}
            onChange={event => {
              setValue(event);
            }}
            onBlur={save}
          >
            {selectOptions?.map(({ key, text, value: optionValue }) => (
              <AntdSelect.Option key={key} value={optionValue}>
                {text}
              </AntdSelect.Option>
            ))}
          </AntdSelect>
        );
      }

      case EditableCellType.Date: {
        return (
          <DatePicker
            className={styles.input}
            autoFocus={true}
            allowClear={false}
            value={value ? moment(formatDateValue(value)) : null}
            format={dateFormat}
            onChange={(date, dateString) => {
              setValue(Date.parse(dateString).toString());
            }}
            onBlur={save}
          />
        );
      }
      case EditableCellType.MultipleInput:
        return (
          <AntdSelect
            className={styles.input}
            ref={inputRef}
            mode="tags"
            value={value}
            filterOption={inputValue => !!inputValue?.trim()}
            onChange={event => {
              setValue(event);
            }}
            onBlur={save}
          />
        );
      case EditableCellType.Input:
      default:
        return (
          <AntdInput
            className={styles.input}
            ref={inputRef}
            value={value}
            onChange={event => {
              setValue(event.target.value);
            }}
            onPressEnter={save}
            onBlur={save}
          />
        );
    }
  };

  if (editable) {
    return editing ? (
      <td
        {...restProps}
        className={classNames([
          restProps.className,
          getClassNameOnError?.(record),
        ])}
      >
        {getInput()}
      </td>
    ) : (
      <td
        {...restProps}
        onClick={toggleEdit}
        className={classNames([
          restProps.className,
          getClassNameOnError?.(record),
          styles.clickableCell,
        ])}
      >
        {children}
      </td>
    );
  } else {
    return (
      <td
        {...restProps}
        className={classNames([
          restProps.className,
          getClassNameOnError?.(record),
        ])}
      >
        {children}
      </td>
    );
  }
};

export default EditableCell;
