import { Tooltip } from "antd";
import { ReactElement } from "react";
import { COLUMN_ASSET_BATCHES } from "shared/constants/assetExporter";
import { FeedRow } from "shared/types/assetExporter";
import { isFeatureEnabled } from "utils/helpers";
import EditableCell from "./EditableCell";
import CellError from "./editableCell/CellError";
import AssetBatchTags from "./feedConfigurationCell/AssetBatchTags";
import DateField from "./feedConfigurationCell/DateField";
import { getProperty } from "./utils/feedConfigurationUtils";
import { useCellContext } from "./editableCell/CellContext";

type FeedConfigurationCellProps = {
  isSmartCol: boolean;
  field: string;
  value: string | string[];
  row: FeedRow;
  onEditCell: (
    rowIdentifier: string,
    field: string,
    value: string | number | boolean,
    oldValue: string | number | boolean,
  ) => void;
};

const FeedConfigurationCell = ({
  isSmartCol,
  field,
  value,
  row,
  onEditCell,
}: FeedConfigurationCellProps): ReactElement | null => {
  const dateFieldKeys = ["dateInStock", "lastUpdated"];
  const { editingField, editingRow } = useCellContext();

  if (value === "order_number") return null;
  if (dateFieldKeys.includes(field)) return <DateField value={value} />;

  const isAssetBatchColumn = field === COLUMN_ASSET_BATCHES;

  if (isAssetBatchColumn && value && Array.isArray(value))
    return <AssetBatchTags value={value} />;

  if (!isSmartCol) {
    const rowIdentifier = getProperty(row, "rowIdentifier");

    return (
      <EditableCell
        rowIdentifier={rowIdentifier}
        value={value as string}
        onSave={(newValue: string | number, oldValue: string | number) => {
          onEditCell(editingRow, editingField, newValue, oldValue);
        }}
        index={field}
      />
    );
  }

  const adEngineV2Enabled = isFeatureEnabled("ENABLE_AD_ENGINE_V2", false);
  if (adEngineV2Enabled)
    return (
      <Tooltip title={"unable to edit smart columns"}>
        {value ? <span>{value}</span> : <span></span>}
      </Tooltip>
    );

  return value ? <span>{value}</span> : <CellError errorMessage="No match!" />;
};

FeedConfigurationCell.displayName = "FeedConfigurationCell";

export { FeedConfigurationCell };
