import {
  IButtonElement,
  IInstantExperience,
  IPhotoElement,
} from "shared/types/adLibrary";
import { AccountUrl } from "shared/types/accountManagement";
import {
  isButton,
  isImage,
  isFooter,
  isCarousel,
  isProductSet,
} from "utils/adLibrary.validators";
import { isValidUrl } from "utils/validators";
import { getMarkupFromRichText } from "screens/designStudio/editor/instantExperience/shared/utils";
import { EAElementDest } from "screens/adLibrary/facebookUtils/adProto.types";

const getUrlFromLabel = (
  urlLabel: string | undefined,
  urlLabels: AccountUrl[],
) => {
  if (!urlLabel) return undefined;
  if (isValidUrl(urlLabel)) return urlLabel;

  const url = urlLabels.find(label => label.name === urlLabel)?.url ?? "";
  return isValidUrl(url) ? url : undefined;
};

const getElement = <ElType extends IButtonElement | IPhotoElement>({
  element,
  instantExperiences,
  urlLabels,
  elementDests,
}: {
  element: ElType;
  instantExperiences: IInstantExperience[];
  urlLabels: AccountUrl[];
  elementDests: EAElementDest[] | undefined;
}): ElType => {
  const instExp =
    element.destination?.instantExperienceId &&
    instantExperiences.find(
      ie => ie.id === element.destination?.instantExperienceId,
    );
  const everythingAd = instExp
    ? getEverythingAdWithDests({
        instExp,
        instantExperiences,
        urlLabels,
        elementDests,
      })
    : undefined;

  const elementDest = elementDests?.find(dest => dest.elementId === element.id);
  const urlLabel =
    (elementDest?.destType === "url" && elementDest.destinationUrl) ||
    getUrlFromLabel(element.destination?.urlLabel, urlLabels);

  const markup =
    "rich_text" in element && element.rich_text?.plain_text
      ? getMarkupFromRichText(element.rich_text)
      : undefined;

  return {
    ...element,
    destination: {
      urlLabel,
      everythingAd,
    },
    markup,
  };
};

/**
 * Returns a instant experience with all the destinations filled in,
 * including the nested everything ads.
 */
export const getEverythingAdWithDests = ({
  instExp,
  instantExperiences,
  urlLabels,
  elementDests,
}: {
  instExp: IInstantExperience;
  instantExperiences: IInstantExperience[];
  urlLabels: AccountUrl[];
  elementDests?: EAElementDest[];
}): IInstantExperience => {
  return {
    ...instExp,
    body_elements: instExp.body_elements?.map(element => {
      if (isButton(element) || isImage(element)) {
        return getElement({
          element,
          instantExperiences,
          urlLabels,
          elementDests,
        });
      }
      if (isFooter(element)) {
        const buttonElement = element.child_elements?.[0];
        return {
          ...element,
          child_elements: [
            getElement({
              element: buttonElement,
              instantExperiences,
              urlLabels,
              elementDests,
            }),
          ],
        };
      }
      if (isCarousel(element)) {
        return {
          ...element,
          child_elements: element.child_elements?.map(childElement => {
            return getElement({
              element: childElement,
              instantExperiences,
              urlLabels,
              elementDests,
            });
          }),
        };
      }
      if (isProductSet(element)) {
        const elementDest = elementDests?.find(
          dest => dest.elementId === element.id,
        );
        if (elementDest?.destType !== "productSet") return element;
        return {
          ...element,
          product_set_id: elementDest.productSetId,
        };
      }
      return element;
    }),
  };
};
