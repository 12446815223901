/* eslint-disable react/display-name */
import { TableProps } from "antd";
import { memo } from "react";
import { usePMaxDataContext } from "shared/components/contextAPI/pmaxAssetGroup/PMaxDataProvider";
import { HoverActionsVirtualTable } from "shared/components/HoverActionsVirtualTable/HoverActionsVirtualTable";
import useResizableTable from "shared/hooks/useResizableTable";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { IAssetGroup } from "shared/types/adLibrary";
import styles from "./PMaxAssetGroupList.module.scss";
import usePmaxAssetGroupColumns from "./pmaxAssetGroupList/hooks/usePmaxAssetGroupColumns";

const PMaxAssetGroupList = () => {
  const ctx = usePMaxDataContext();
  const { dataSource } = ctx;
  const columns = usePmaxAssetGroupColumns(dataSource);

  const handleChange: TableProps<IAssetGroup>["onChange"] = (
    _pagination,
    filters,
    _sorter,
  ) => {
    ctx.setFilteredInfo(filters);
  };

  const { windowInnerHeight } = useWindowSize();
  const { components } = useResizableTable<IAssetGroup>(
    columns.map(column => ({ ...column, name: column.title })),
  );

  return (
    <div className={styles.container}>
      <HoverActionsVirtualTable
        data-cy="ad-table-container"
        className="ad-table-container"
        scroll={{ y: windowInnerHeight - 245 }}
        rowKey="id"
        pagination={false}
        columns={columns}
        onChange={handleChange}
        components={components}
        dataSource={dataSource}
        rowSelection={{
          selectedRowKeys: ctx.selectedRowIds,
          onChange: ctx.setSelectedRowIds,
          preserveSelectedRowKeys: true,
        }}
        loading={ctx.fetchingData}
      />
    </div>
  );
};

export default memo(PMaxAssetGroupList);
