import { Collapse } from "antd";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { ISavedOrderState, OfferData } from "shared/types/assetBuilder";
import CollapsableHeader from "./offerCollapse/CollapsableHeader";
import OfferTypeCollapse from "./offerCollapse/OfferTypeCollapse";
import styles from "./OfferCollapse.module.scss";
import { OfferType } from "shared/types/shared";
import { TOfferListSection } from "../../select.hooks/useOfferList";
import { TSortByOption } from "../OfferFilterV2";
import { TOfferCollapseData } from "../../SelectV2";
import API from "services";
import { useQueryClient } from "react-query";
import { KeyValues } from "services/assetBuilder";
import { OfferEditFieldObject } from "shared/types/assetBuilder";
import { useParams } from "react-router-dom";
import { TOfferListData } from "screens/assetBuilder/PaymentEngineModal";

export type TSessionUpdateRecords = {
  [key in keyof OfferData]?: {
    current: string | undefined;
    originalSessionValue: string | undefined;
  };
};

export interface Props {
  edited: boolean;
  offer: Partial<OfferData>;
  offerTypes?: OfferType[];
  offerListData?: TOfferListData;
  lastUpdated?: number;
  flagged?: boolean;
  warned?: boolean; // indicates if store is same as order's store
  sortByOptions?: TSortByOption[];
  editedPairs?: TOfferCollapseData["editedPairs"];
  sectionKey: TOfferListSection;
  savedOrder: ISavedOrderState;
  filterOfferTypes?: OfferType[];
  feedId: string;
  duplicatedOfferData?: Partial<OfferData>;
}

export interface Handlers {
  moveOfferTo?: (
    section: TOfferListSection,
    vin: string,
    offerTypes: OfferType[],
  ) => void;
  togglePaymentEngineModal?: (
    initialSelectedRow: OfferEditFieldObject | null,
  ) => void;
  setAddingMode?: Dispatch<SetStateAction<boolean>>;
  setDuplicatedOfferData?: Dispatch<
    SetStateAction<Partial<OfferData> | undefined>
  >;
}

const OfferCollapse = (props: Props & Handlers) => {
  const queryClient = useQueryClient();

  const getTitle = useCallback((offer: Partial<OfferData>) => {
    const { year, make, model } = offer;
    const isNewOffer = !year && !make && !model;
    return isNewOffer
      ? "New Offer"
      : `${year ?? ""} ${make ?? ""} ${model ?? ""}`;
  }, []);

  const [selectedOfferTypes, setSelectedOfferTypes] = useState<OfferType[]>(
    props.offerTypes || [],
  );

  const onOfferSelection = useCallback(
    (offerTypes: OfferType[], vin: string) => {
      setSelectedOfferTypes(() => offerTypes);

      // Here, we need to determine if we want to move this OfferCollapse (by its vin) to other section ("Selected Offers" or "Available Offers")
      //  depending on the selected offer types. Meaning if
      //    - at least one offer type is selected, move to "Selected Offers".
      //    - none of the offer type has been selected, move to "Available Offers".
      props.moveOfferTo?.(
        offerTypes.length > 0 ? "selected" : "available",
        vin,
        offerTypes,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [sessionUpdateRecords, setSessionUpdateRecords] =
    useState<TSessionUpdateRecords>();
  const [revertToOriginalSession, setRevertToOriginalSession] =
    useState<boolean>(false);

  const routeParams = useParams<{ orderId: string }>();

  return (
    <Collapse className={styles.OfferCollapse}>
      <Collapse.Panel
        className={styles.CollapsePanel}
        key={`offer-collapse-panel`}
        header={
          <CollapsableHeader
            title={getTitle(props.offer)}
            edited={props.edited}
            offerData={props.offer}
            selectedOfferTypes={selectedOfferTypes}
            onOfferSelection={offerTypes => {
              onOfferSelection(offerTypes, props.offer.vin || "");
            }}
            lastUpdated={props.lastUpdated}
            errorMessage={
              props.flagged ? "Unable to find offer in feed" : undefined
            }
            warningMessage={
              props.warned
                ? "Offer has different Store from the Order"
                : undefined
            }
            isInvalidFeed={props.flagged || false}
            sortByOptions={props.sortByOptions}
            sessionUpdateRecords={sessionUpdateRecords}
            revertSession={async () => {
              if (!sessionUpdateRecords) return; // sessionUpdateRecords is validated and it should not be empty here.
              setRevertToOriginalSession(false);

              await API.services.assetBuilder.updateOfferFields({
                vin: props.offer.vin,
                orderId: routeParams.orderId,
                keyValues: Object.keys(sessionUpdateRecords).reduce(
                  (acc, offerKey) => {
                    const key = offerKey as keyof OfferData;

                    acc[key] = {
                      value:
                        sessionUpdateRecords[key]?.originalSessionValue || "",
                    };

                    return acc;
                  },
                  {} as KeyValues,
                ),
                sectionKey: props.sectionKey,
              });

              setSessionUpdateRecords(undefined);
              setRevertToOriginalSession(true);
              queryClient.invalidateQueries("offer-list");
            }}
            feedId={props.feedId}
            savedOrder={props.savedOrder}
            setAddingMode={props.setAddingMode}
            setDuplicatedOfferData={props.setDuplicatedOfferData}
          />
        }
      >
        <OfferTypeCollapse
          offer={props.offer}
          offerListData={props.offerListData}
          sectionKey={props.sectionKey}
          selectedOfferTypes={selectedOfferTypes}
          onOfferSelection={offerTypes => {
            onOfferSelection(offerTypes, props.offer.vin || "");
          }}
          isEditDisabled={
            props.flagged || props.sectionKey === "available" || false
          }
          editedPairs={props.editedPairs}
          togglePaymentEngineModal={props.togglePaymentEngineModal}
          revertToOriginalSession={revertToOriginalSession}
          onSessionUpdate={(offerKey, value, originalSessionValue) => {
            if (!offerKey) return;

            if (!value) {
              return setSessionUpdateRecords(prevRecords =>
                !prevRecords
                  ? prevRecords
                  : Object.keys(prevRecords).reduce((acc, key) => {
                      if (offerKey === key) return acc;

                      acc[key as keyof OfferData] = {
                        current: prevRecords[key as keyof OfferData]?.current,
                        originalSessionValue,
                      };

                      return acc;
                    }, {} as TSessionUpdateRecords),
              );
            }

            return setSessionUpdateRecords(prevRecords => {
              return {
                ...(prevRecords || {}),
                [offerKey]: {
                  current: value,
                  originalSessionValue,
                },
              };
            });
          }}
          savedOrder={props.savedOrder}
          filterOfferTypes={props.filterOfferTypes}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

export default OfferCollapse;
