import { PlusOutlined } from "@ant-design/icons";
import { ReactNode } from "react";
import styles from "./Control.module.scss";

type Props = {
  openControl: boolean;
  title?: string;
  controlContent?: ReactNode;

  onAddControl: (e: React.MouseEvent<HTMLSpanElement>) => void;
};
const Control = (props: Props) => {
  return (
    <div className={styles.Control}>
      <div className={styles.Header}>
        <span>{props.title}</span>
        {!props.openControl && (
          <PlusOutlined
            className={styles.PlusIcon}
            onClick={props.onAddControl}
          />
        )}
      </div>

      {props.openControl && props.controlContent}
    </div>
  );
};

export default Control;
