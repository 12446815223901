import { Button, message } from "antd";
import { FC, useState } from "react";
import { User } from "redux/auth/auth.slice";
import API from "services";
import { IStamp, IStampExceptionType } from "shared/types/designStudio";
import { TResource } from "../Editor.context";
import { TCanvasHistory } from "../editor.hooks/useCanvasHistory";

interface StampChangeConfirmFooterProps {
  resource?: TResource;
  canvasHistory?: TCanvasHistory;
  user?: User;
  switchingStampOfferType?: string;
  selectedStampException: {
    type: IStampExceptionType;
    value: string;
  };
}
interface StampChangeConfirmFooterHandlers {
  afterDiscardComplete?: () => void;
  onCancel?: () => void;
  afterSaveComplete?: (stamp?: IStamp) => void;
}

const StampChangeConfirmFooter: FC<
  StampChangeConfirmFooterProps & StampChangeConfirmFooterHandlers
> = props => {
  const [savingStamp, setSavingStamp] = useState<boolean>(false);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        className="discard-button-wrapper"
        style={{ flex: 1, display: "flex" }}
      >
        <Button
          type="primary"
          danger
          onClick={async e => {
            e.preventDefault();

            props.afterDiscardComplete?.();
          }}
        >
          Discard
        </Button>
      </div>

      <div className="rest-button-wrapper" style={{ flex: "initial" }}>
        <Button
          onClick={e => {
            e.preventDefault();

            props.onCancel?.();
          }}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          loading={savingStamp}
          onClick={async e => {
            // The fact that this modal displayed on the screen, we detected changes on the canvas.
            // Clicking on this "Save" button means user wants to save the changes and proceed to different offer type.
            e.preventDefault();

            const history = props.canvasHistory?.history;
            if (!props.switchingStampOfferType || !history?.length) {
              // If the history is empty, there must be something wrong when adding canvas history.
              // The fact that this modal shows up is there is existing history. But when it gets here,
              //  if the history is empty, then it is a bug.
              message.error(
                "There seems to be system error on canvas edtior. Please report this to the support team.",
              );
              return;
            }

            let stampException:
              | {
                  exceptionType: IStampExceptionType;
                  exceptionValue: string;
                }
              | undefined;
            if (props.selectedStampException.value !== "default") {
              stampException = {
                exceptionType: props.selectedStampException.type,
                exceptionValue: props.selectedStampException.value,
              };
            }

            setSavingStamp(true);

            const json = history[history.length - 1];
            API.services.designStudio
              .saveStamp(
                props.resource as IStamp,
                json,
                props.user || undefined,
                stampException,
              )
              .then(res => {
                const { result, error } = res;
                if (error || !result) {
                  message.error(error?.message || "There was an error.");
                  return;
                }

                const { stamp } = result;

                setSavingStamp(false);
                props.afterSaveComplete?.(stamp);
              })
              .catch(() => {
                message.error("Unable to save stamp change.");
                setSavingStamp(false);
                props.afterSaveComplete?.();
              });
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default StampChangeConfirmFooter;
