import { FilterFilled } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { useState } from "react";
import { FormFilters } from "./FormFilters";
import { FormQuery } from "./FormQuery";

export const MediaSearch = () => {
  const [open, setOpen] = useState(false);
  const drawerId = "media-search-drawer";

  const toggle = () => {
    setOpen(open => !open);
  };

  const close = () => {
    setOpen(false);
  };

  return (
    <>
      <FormQuery />
      <Button icon={<FilterFilled />} onClick={toggle}>
        Filters
      </Button>
      <Drawer
        destroyOnClose
        maskClosable
        title="Filter Assets"
        width={500}
        visible={open}
        onClose={close}
        data-id={drawerId}
        footer
      >
        <FormFilters onClose={close} drawerId={drawerId} />
      </Drawer>
    </>
  );
};
