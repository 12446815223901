import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import mediaApi from "./media/media.api";
import { reducers } from "./rootReducers";

const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["configuration/setConfigurationSuccess"],
        ignoredPaths: ["configuration"],
      },
    }).concat(mediaApi.middleware),
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

export default store;
