import { Space, Typography } from "antd";
import { memo } from "react";
import Placeholder from "statics/images/image-placeholder.png";

type Props = {
  thumbnail: string | undefined;
  name: string;
};

const AdNameCell = ({ thumbnail, name }: Props) => {
  return (
    <Space direction="horizontal" align="center">
      <img
        height={32}
        width={32}
        src={thumbnail || Placeholder}
        style={{
          objectFit: "cover",
        }}
      />
      <Typography.Paragraph
        ellipsis={{ rows: 2, tooltip: true }}
        style={{ margin: 0 }}
      >
        {name}
      </Typography.Paragraph>
    </Space>
  );
};

export default memo(AdNameCell);
