import { SetStateAction, useCallback, useMemo } from "react";

import InstantExperienceProductSetsTable, {
  OnProductCatalogSelect,
  OnProductSetSelect,
} from "./assignProductSetsExpandable/InstantExperienceProductSetsTable";

import { returnNestedIEsWithProductSets } from "../../helpers.ie";
import { adsToLoadAfterNestedProductSetChange } from "../../../shared/components/adsToLoadTable/utils";

import { IAdToLoadData, IInstantExperience } from "shared/types/adLibrary";

interface IProps {
  record: IAdToLoadData;
  productCatalogIds?: string[];
  currentAdsToLoad: IAdToLoadData[];
  instantExperiences: IInstantExperience[];
}
interface IHandlers {
  setCurrentAdsToLoad: (value: SetStateAction<IAdToLoadData[]>) => void;
}

type AssignProductSetsExpandableProps = IProps & IHandlers;

const AssignProductSetsExpandable = ({
  record,
  currentAdsToLoad,
  productCatalogIds,
  instantExperiences,
  setCurrentAdsToLoad,
}: AssignProductSetsExpandableProps) => {
  const nestedIEsWithProductSets = useMemo(
    () =>
      returnNestedIEsWithProductSets(
        record.ad.visuals?.destination?.instantExperienceId ?? "",
        instantExperiences,
      ),
    [instantExperiences, record.ad.visuals?.destination?.instantExperienceId],
  );

  const onProductSetSelect: OnProductSetSelect = useCallback(
    ({ productSetId, instantExperienceID, option }) => {
      const newAdsToLoad = adsToLoadAfterNestedProductSetChange({
        instantExperienceID,
        adToLoadKey: record.key,
        currentAdsToLoad: currentAdsToLoad,
        newNestedProductSetData: {
          ...(record.nestedProductSets?.[instantExperienceID] ?? {}),
          productSet: {
            id: productSetId,
            name: option?.children as string,
          },
        },
      });
      setCurrentAdsToLoad(newAdsToLoad);
    },
    [
      record.key,
      currentAdsToLoad,
      record.nestedProductSets,
      setCurrentAdsToLoad,
    ],
  );

  const onProductCatalogSelect: OnProductCatalogSelect = useCallback(
    ({ instantExperienceID, selected }) => {
      const newAdsToLoad = adsToLoadAfterNestedProductSetChange({
        instantExperienceID,
        adToLoadKey: record.key,
        currentAdsToLoad: currentAdsToLoad,
        newNestedProductSetData: {
          productCatalog: selected || undefined,
        },
      });
      setCurrentAdsToLoad(newAdsToLoad);
    },
    [currentAdsToLoad, record.key, setCurrentAdsToLoad],
  );

  return (
    <InstantExperienceProductSetsTable
      record={record}
      productCatalogIds={productCatalogIds}
      nestedIEsWithProductSets={nestedIEsWithProductSets}
      onProductSetSelect={onProductSetSelect}
      onProductCatalogSelect={onProductCatalogSelect}
    />
  );
};

export default AssignProductSetsExpandable;
