import { orderBy } from "lodash";
import {
  IVariableAlias,
  TVariable,
  TVariableAlias,
  TVariableAliasInfo,
} from "shared/types/designStudio";
import { message } from "antd";
import levenshtein from "fast-levenshtein";

export const extractVariables = (text: string) => {
  if (!text || !text.trim()) return [];

  const regex = /{.*?}/g;

  const variables: TVariable[] = [];
  let match: RegExpExecArray | null;
  do {
    match = regex.exec(text);

    if (!match) break;
    const [matchedVariable] = match;

    if (!getVariableName(matchedVariable)) continue; // skip empty variable, ex) {}

    variables.push({
      value: matchedVariable,
      startIndex: match.index,
    });
  } while (match);

  return variables;
};

export const restoreText = (
  maskedText: string,
  variableAlias: TVariableAlias,
) => {
  let copied = maskedText;
  for (const variable in variableAlias) {
    try {
      const alias = variableAlias[variable];
      if (!alias.customVariable?.trim()) continue;

      const reg = new RegExp(
        alias.isCapsOn
          ? alias.customVariable.toUpperCase()
          : alias.customVariable,
        "gi",
      );
      const matches: RegExpExecArray[] = [];
      let match: RegExpExecArray | null;
      let limit = 5;
      do {
        if (limit <= 0) break;
        limit -= 1;

        match = reg.exec(copied);
        if (!match) continue;

        matches.push(match);
      } while (match);

      /**
       * Below will find the closest matched word and replace it with variable.
       * Ex) Let's say we have "{year} wrong_masked {model} masked" and alias { "{make}": "masked" }
       *     Then, we need to replace "masked" at then end with "{make}" and leave "wrong_masked" as is.
       *     In order to do this, we rank each match and take the closet one.
       *
       * There is some exception case we need to handle. Consider the situation below.
       * Let's say we have alias like below.
       * { {year}: "test", {modeltest}: "" }
       * Above, we take the customVariable and find the closest match to replace.
       * In this case, the final extracted variable will include {model{year}} which we dont want.
       * Here, we will take the index of the match and see if it is within variable braces {}. If it is, skip.
       */
      if (matches.length > 0) {
        const ordered = orderBy(
          matches,
          match => {
            const word = getWordAtIndex(copied, match.index);
            const distance = levenshtein.get(alias.customVariable, word);
            return distance;
          },
          ["asc"],
        );
        const match = ordered[0];

        const [mask] = match;
        const prev = copied.substring(0, match.index);
        const post = copied.substring(match.index + mask.length);
        copied = `${prev}${variable}${post}`;
      }
    } catch (err) {
      message.error({
        content: `Text error with ${maskedText}, please re-insert variable.`,
        key: "regexStampErr",
      });
      continue;
    }
  }

  return copied;
};

export const isWithinVariable = (index: number, text: string) => {
  const wordAroundIndex = getWordAtIndex(text, index);
  return (
    wordAroundIndex[0] === "{" &&
    wordAroundIndex[wordAroundIndex.length - 1] === "}"
  );
};

export const getWordAtIndex = (text: string, index: number) => {
  if (index < 0 || text.length <= index) return text;

  // get string to its left
  const left = [];
  for (let i = index - 1; i >= 0; i--) {
    const char = text[i];
    if (char === " ") {
      break;
    }

    left.push(char);
  }

  // get string to its right
  const right = [];
  for (let i = index + 1; i < text.length; i++) {
    const char = text[i];
    if (char === " ") {
      break;
    }

    right.push(char);
  }

  return `${left.reverse().join("")}${text[index]}${right.join("")}`;
};

export const generateTextToDisplay = (
  originalText: string,
  variableAlias: TVariableAlias,
  previouseVariableAlias?: TVariableAlias,
) => {
  const restoredText = restoreText(
    originalText,
    !!previouseVariableAlias ? previouseVariableAlias : variableAlias,
  );
  let copied = restoredText;

  // The mask value filling has to be done starting from the end to the start
  // The reason is that the filling depends on the the startIndex of each variable and it keeps chaging the text.
  // If we start from the index 0, the startIndex gets messed up whenever variable fills in. So if we fill in the mask value from the end,
  //   we do not have to worry about the index issue.
  const variablesOrderByStartIndex = orderBy(
    Object.keys(variableAlias),
    variable => variableAlias[variable].startIndex,
    ["desc"],
  );

  for (const variable of variablesOrderByStartIndex) {
    const aliasInfo = variableAlias[variable];

    copied = maskText(copied, variable, aliasInfo);
  }

  return copied;
};

export const maskText = (
  text: string,
  variable: string,
  variableAliasInfo: TVariableAliasInfo,
) => {
  if (!variable || !variable.trim() || !variableAliasInfo.customVariable)
    return text;

  const { startIndex, customVariable, isCapsOn, isMaskOn } = variableAliasInfo;
  const prev = text.substring(0, startIndex);
  const post = text.substring(startIndex + variable.length);

  let variableToUse = variable;
  if (isMaskOn || isCapsOn) {
    if (isMaskOn) variableToUse = customVariable;
    if (isMaskOn && isCapsOn) variableToUse = variableToUse.toUpperCase();
  }

  return `${prev}${variableToUse}${post}`;
};

export const generateVariableAlias = (
  variables: TVariable[],
  existingAlias?: IVariableAlias | TVariableAlias,
) => {
  if (variables.length === 0) return;

  const alias = variables.reduce((acc, variable) => {
    const { value, startIndex } = variable;
    if (existingAlias?.[value]) {
      const existing = existingAlias[value];
      const { customVariable, isCapsOn, isMaskOn } = existing;
      acc[value] = {
        customVariable,
        isCapsOn,
        isMaskOn,
        startIndex,
      };
    } else {
      acc[value] = {
        customVariable: "",
        isMaskOn: false,
        isCapsOn: false,
        startIndex,
      };
    }
    return acc;
  }, {} as TVariableAlias);

  return alias;
};

export const isDuplicateMask = (
  variableAlias: IVariableAlias | TVariableAlias,
  selectedVariable: string,
  maskValue: string,
) => {
  if (maskValue === "") {
    return false;
  }
  const existingVariablesWithSameMask = Object.keys(variableAlias)
    .filter(variable => variable !== selectedVariable)
    .filter(variable => {
      const { customVariable } = variableAlias[variable];
      const lowerCaseVal = customVariable.toLowerCase();
      const lowerCaseMask = maskValue.toLowerCase();
      const valueIsNotUnique = lowerCaseVal === lowerCaseMask;
      return valueIsNotUnique;
    });

  return existingVariablesWithSameMask.length > 0;
};

export const isSameAsVariableName = (value: string, variables: TVariable[]) => {
  const variableNames = getVariableNames(variables?.map(v => v.value) || []);
  return variableNames.some(v => v?.toLowerCase() === value.toLowerCase());
};

export const getVariableNames = (variables: string[]) => {
  return variables.map(v => getVariableName(v));
};

export const getVariableName = (variable?: string) => {
  if (!variable) return;

  // variable name without braces, Ex) {model} => model
  let copied = variable;
  if (copied[0] === "{" && copied[copied.length - 1] === "}") {
    copied = copied.substring(1, copied.length - 1);
  }

  return copied;
};
