import styles from "./SocialButtons.module.scss";

const SocialButtons = () => {
  return (
    <div className={styles.buttons}>
      <div className={styles.divider}></div>
      <div className={styles.actions}>
        <div className={styles.actionButtonContainer}>
          <div className={styles.iconWrapper}>
            <i className={styles.thumb} />
          </div>
          <span>Like</span>
        </div>
        <div className={styles.actionButtonContainer}>
          <div className={styles.iconWrapper}>
            <i className={styles.comment} />
          </div>
          <span>Comment</span>
        </div>
        <div className={styles.actionButtonContainer}>
          <div className={styles.iconWrapper}>
            <i className={styles.share} />
          </div>
          <span>Share</span>
        </div>
      </div>
    </div>
  );
};

export default SocialButtons;
