import { useState } from "react";
import useDeepEffect from "shared/hooks/useDeepEffect";
import {
  ExtendedObjectType,
  IExtendedFabricObject,
} from "shared/types/designStudio";
import { getTransformedObjects } from "../editor/canvasContainer/canvas.utils/Parsers";

// NOTE: This data schema used to decribe action flow start from layer item to canvas.
//       The actions between layer and canvas should be bi-directional. Meaning certain action
//          from layer affects the canvas and reverse works as well. There will be another data schema
//          called TCanvasAction that will describe action from the canvas to layer item
export type TLayerAction = {
  layer?: TLayerItem;
  action: {
    type: TLayerActionType;

    data?: boolean | string | TLayerOrderAction | TLayerItem[];
  };
};
export type TLayerActionType =
  | "mouseEnter"
  | "mouseLeave"
  | "toggle"
  | "lock"
  | "delete"
  | "rename"
  | "re-order"
  | "selected";

export type TLayerOrderAction = {
  from: TLayerOrder;
  to: TLayerOrder;
};

type TLayerOrder = {
  id?: string;
  index: number;
};

export type TLayerItem = {
  id: string;
  type: ExtendedObjectType;
  original: fabric.Object;
};

export default (args: {
  json: any;
  layerOrderAction?: TLayerOrderAction;
  onOrderChanged?: (layerOrderAction: TLayerOrderAction) => void;
  onComplete?: () => void;
}) => {
  const [objects, setObjects] = useState<IExtendedFabricObject[]>([]);
  const [layers, setLayers] = useState<TLayerItem[]>([]);

  useDeepEffect(() => {
    if (!args.json) {
      // need to reset list
      setObjects([]);
      return;
    }
    const objectTypesToExclude: ExtendedObjectType[] = ["canvas_area", "grid"];

    getTransformedObjects(args.json, {
      top: 0,
      left: 0,
    }).then(objects => {
      setObjects(
        objects.filter(
          obj =>
            !objectTypesToExclude.includes(
              (obj as unknown as IExtendedFabricObject).customType,
            ),
        ),
      );
    });

    args.onComplete?.();
  }, [args.json]);

  useDeepEffect(() => {
    setLayers(
      objects.reverse().map((obj, idx) => {
        const { name, customType, type } = obj as IExtendedFabricObject;
        return {
          id: name || `${type}-${idx}`,
          type: customType || type,
          original: obj,
        } as any;
      }),
    );
  }, [objects]);

  useDeepEffect(() => {
    const { layerOrderAction } = args;
    if (!layerOrderAction) return;

    const { from, to } = layerOrderAction;

    // NOTE: the layers are in reverse orders. So the index has to be reversed again here before sending to Canvas.tsx
    args.onOrderChanged?.({
      from: {
        ...from,
        index: from.index,
      },
      to: {
        ...to,
        index: to.index,
      },
    });

    args.onComplete?.();
  }, [args.layerOrderAction]);

  return [layers, setLayers] as [TLayerItem[], typeof setLayers];
};
