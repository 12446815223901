import { useUser } from "shared/hooks/useUser";
import { useAppShallowEqualSelector } from "shared/hooks/useAppSelector";

import { useMutation, useQueryClient } from "react-query";
import { useOptimisticallyMutateSession } from "./useMutateSession";

import { getSignedPutUrl, uploadToS3 } from "utils/uploadMedia";
import { returnSessionLoadStatus } from "screens/adLibrary/adLoad/adLoadDrawer/loadStep/helpers.load";

import { IUploadImage } from "shared/types/uploadManagement";
import { IAdHistorySession, IAdLoad } from "shared/types/adLibrary";
import { IFacebookAccount } from "screens/adLibrary/facebookUtils/types";

type ModifySessionJsonArgs = {
  sessionId: string;
  sessionData?: IAdLoad;
  selectedAccounts?: IFacebookAccount[] | null;
};

const uploadSessionJsonToS3 = async (args: ModifySessionJsonArgs) => {
  const { sessionId, sessionData } = args;

  try {
    if (!sessionData) {
      throw Error("There is no Session data to upload.");
    }

    const jsonBufferData = JSON.stringify(sessionData || {});

    const jsonBlob = new Blob([jsonBufferData], {
      type: "application/json;charset=utf-8",
    });

    const uploadObj: IUploadImage = {
      featureName: "ad-load",
      fileType: "application/json",
      filename: `session_${sessionId}.json`,
      imageData: jsonBlob,
    };

    const signedPutUrl = await getSignedPutUrl(uploadObj, {
      extendedPath: `/default/${sessionId}`,
    });

    const s3AssetUrl = signedPutUrl.split("?")?.[0];

    if (!s3AssetUrl) {
      throw Error("Signed URL could not be generated.");
    }

    await uploadToS3(uploadObj, signedPutUrl, true);

    return s3AssetUrl;
  } catch (error) {
    throw Error((error as Error).message || "JSON file could not be uploaded");
  }
};

export const useModifySessionJson = (args: ModifySessionJsonArgs) => {
  const user = useUser();
  const queryClient = useQueryClient();
  const { mutate: mutateSessionRecord } = useOptimisticallyMutateSession();

  const {
    review,
    seletedAccounts,
    title: sessionTitle,
  } = useAppShallowEqualSelector(({ adLibrary }) => ({
    review: adLibrary.adLoad.review,
    title: adLibrary.adLoad.adLoadHistory?.title,
    seletedAccounts: adLibrary.selectedFacebookAccounts,
  }));
  const username = user?.email || "alexia@constellationagency.com";

  return useMutation<string, Error, ModifySessionJsonArgs>(
    uploadSessionJsonToS3,
    {
      onSuccess: s3JsonUrl => {
        queryClient.invalidateQueries(["adHistorySessionJson", args.sessionId]);

        const currentSession: IAdHistorySession | undefined = (
          (queryClient.getQueryData(["adHistorySessions", args.sessionId]) ??
            []) as IAdHistorySession[]
        )?.[0];

        queryClient.invalidateQueries([
          "s3ObjectVersions",
          currentSession?.jsonUrl || s3JsonUrl,
        ]);

        if (!currentSession) {
          return;
        }

        queryClient.invalidateQueries(["adHistorySessions", args.sessionId]);
        queryClient.invalidateQueries("adHistorySessionsPages");

        mutateSessionRecord({
          session: {
            ...currentSession,
            name: sessionTitle ?? currentSession.name,
            jsonUrl: currentSession.jsonUrl || s3JsonUrl,
            lastUpdatedAt: Date.now(),
            lastUpdatedBy: username,
            accounts: args.selectedAccounts
              ? { facebook: args.selectedAccounts || seletedAccounts }
              : currentSession.accounts || { facebook: seletedAccounts },
            loadStatus: returnSessionLoadStatus(
              review?.adsToLoad?.filter(adToLoad =>
                review.selectedRowKeys.includes(adToLoad.key),
              ) ?? [],
            ),
          },
          operation: "update",
        });
      },
    },
  );
};
