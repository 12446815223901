import { useMutation, useQueryClient } from "react-query";
import API from "services";
import {
  ICreateLabelReqBody,
  ICreateLabelResponse,
} from "shared/types/inputValues";

const createLabel = async (labelValue: string) => {
  const labelReqBody: ICreateLabelReqBody = {
    name: labelValue,
    // TODO: get username from auth0
    createdBy: "",
  };

  const { result, error, statusCode } =
    await API.services.adLibrary.createLabel(labelReqBody);

  if (error) {
    throw Error(
      `Could not create label: ${
        error.message || `${statusCode} error occurred.`
      }`,
    );
  }

  return result;
};

export const useMutateLabel = () => {
  const queryClient = useQueryClient();

  return useMutation<ICreateLabelResponse["result"], Error, string>(
    createLabel,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("labels");
      },
    },
  );
};
