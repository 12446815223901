import { DeleteOutlined } from "@ant-design/icons";
import { Button, Collapse, Modal, Steps, Tabs, Typography } from "antd";
import { useEffect, useState } from "react";
import ImageUploaderBatch from "shared/components/ImageUploaderBatch";
import {
  ISingleLogoObject,
  IUploadImageFormInput,
  TUploadGroup,
  TUploadLogosS3DictionaryKey,
  TUploadSetLogosInDictionary,
  TUploadSteps,
} from "shared/types/uploadManagement";

import { OperationMode } from "shared/types/inputValues";
import { IBrandRecord } from "shared/types/brandManagement";
import "../../screens/oemManagement/OemLogos.scss";
import "../../shared/styles/Collapse.scss";

import { useIsAdmin } from "shared/hooks/useIsAdmin";
import { industryType } from "utils/helpers";

const { Step } = Steps;
interface IOemLogos {
  mode: OperationMode;
  s3AssetBucket: string;
  uploadImages: (
    imagesToUpload: IUploadImageFormInput[],
    featureName: string,
    group: TUploadGroup,
    mode: OperationMode,
  ) => void;

  singleLogoObject: ISingleLogoObject;
  setSingleLogoObject: (singleLogoObject: ISingleLogoObject) => void;

  showAddOemDrawer: boolean;

  logosInDictionary: Record<TUploadSetLogosInDictionary, ISingleLogoObject[]>;
  setLogosInDictionary: (
    logosInDictionary: Record<TUploadSetLogosInDictionary, ISingleLogoObject[]>,
  ) => void;

  oemToEditWithLogos: IBrandRecord;
  setOemToEditWithLogos: (oemToEditWithLogos: IBrandRecord) => void;

  resetUploadImagesInRedux: () => void;
}

const OemLogos: React.FC<IOemLogos> = ({
  mode,
  s3AssetBucket,
  uploadImages,

  singleLogoObject,
  setSingleLogoObject,

  logosInDictionary,
  setLogosInDictionary,

  oemToEditWithLogos,
  setOemToEditWithLogos,
}) => {
  const isAdmin = useIsAdmin();

  const [currTab, setCurrTab] = useState<"oem-logo-tab" | "oem-event-logo-tab">(
    "oem-logo-tab",
  );

  const [showDeleteLogoBoxModal, toggleShowDeleteLogoBoxModal] =
    useState<boolean>(false);
  const [logoNameToDelete, setLogoNameToDelete] = useState<string>("");
  const [logoGroupToDelete, setLogoGroupToDelete] =
    useState<TUploadLogosS3DictionaryKey>("horizontalImagesFromS3");

  useEffect(() => {
    if (singleLogoObject.group === "Horizontal") {
      currTab === "oem-logo-tab"
        ? setLogosInDictionary({
            ...logosInDictionary,
            horizontalLogos: [
              ...logosInDictionary.horizontalLogos,
              singleLogoObject,
            ],
          })
        : setLogosInDictionary({
            ...logosInDictionary,
            horizontalLogosEvent: [
              ...logosInDictionary.horizontalLogosEvent,
              singleLogoObject,
            ],
          });
    } else if (singleLogoObject.group === "Vertical") {
      currTab === "oem-logo-tab"
        ? setLogosInDictionary({
            ...logosInDictionary,
            verticalLogos: [
              ...logosInDictionary.verticalLogos,
              singleLogoObject,
            ],
          })
        : setLogosInDictionary({
            ...logosInDictionary,
            verticalLogosEvent: [
              ...logosInDictionary.verticalLogosEvent,
              singleLogoObject,
            ],
          });
    } else if (singleLogoObject.group === "Square") {
      currTab === "oem-logo-tab"
        ? setLogosInDictionary({
            ...logosInDictionary,
            squareLogos: [...logosInDictionary.squareLogos, singleLogoObject],
          })
        : setLogosInDictionary({
            ...logosInDictionary,
            squareLogosEvent: [
              ...logosInDictionary.squareLogosEvent,
              singleLogoObject,
            ],
          });
    } else {
      return;
    }
    // eslint-disable-next-line
  }, [singleLogoObject]);

  const deleteAttachedLogoFile = (
    logo: ISingleLogoObject,
    group: string,
    step: string,
  ) => {
    const tempArr: ISingleLogoObject[] = [];
    let oneInstanceAlreadyFound = false;
    for (const logoEle of logosInDictionary[
      group as TUploadSetLogosInDictionary
    ]) {
      if (
        logoEle[step as TUploadSteps]?.filename !==
          logo[step as TUploadSteps]?.filename ||
        oneInstanceAlreadyFound
      ) {
        tempArr.push(logoEle);
      } else {
        oneInstanceAlreadyFound = true;
        continue;
      }
    }

    const tempLogoInDictionaryObject = {
      ...logosInDictionary,
    };
    tempLogoInDictionaryObject[group as TUploadSetLogosInDictionary] = tempArr;

    setLogosInDictionary(tempLogoInDictionaryObject);
  };

  const deleteSubmittedLogoBox = (logoName: string, group: string) => {
    if (!oemToEditWithLogos || !oemToEditWithLogos.logoUrlsFromS3) {
      return;
    }

    const tempLogoBoxes: string[] = [];
    let oneInstanceAlreadyFound = false;
    for (const eleName of oemToEditWithLogos.logoUrlsFromS3[
      group as TUploadLogosS3DictionaryKey
    ]) {
      if (logoName !== eleName || oneInstanceAlreadyFound) {
        tempLogoBoxes.push(eleName);
      } else {
        oneInstanceAlreadyFound = true;
        continue;
      }
    }

    const tempBoxLogoObject = { ...oemToEditWithLogos.logoUrlsFromS3 };
    tempBoxLogoObject[group as TUploadLogosS3DictionaryKey] = tempLogoBoxes;

    setOemToEditWithLogos({
      ...oemToEditWithLogos,
      logoUrlsFromS3: tempBoxLogoObject,
    });
  };

  return (
    <div>
      <Tabs
        onChange={activeKey => {
          setCurrTab(activeKey as "oem-logo-tab" | "oem-event-logo-tab");
        }}
      >
        <Tabs.TabPane
          tab={industryType("auto") ? "OEM Logo" : "Brand Logo"}
          key={"oem-logo-tab"}
        ></Tabs.TabPane>

        <Tabs.TabPane
          tab={industryType("auto") ? "OEM Event Logo" : "Additional Logos"}
          key={"oem-event-logo-tab"}
        ></Tabs.TabPane>
      </Tabs>

      <div>
        <Steps direction="vertical" size="small" current={-1}>
          {["Horizontal", "Vertical", "Square", "Upload"].map(step => {
            if (step === "Upload") {
              return (
                <Step
                  key={step.toLowerCase()}
                  title="Upload"
                  description={
                    <Button
                      style={{
                        zIndex: 100,
                        position: "relative",
                      }}
                      disabled={!isAdmin}
                      onClick={() => {
                        const horizLogosForUpload: IUploadImageFormInput[] = [];
                        for (const horizLogo of currTab === "oem-logo-tab"
                          ? logosInDictionary.horizontalLogos
                          : logosInDictionary.horizontalLogosEvent) {
                          if (horizLogo.horizontal) {
                            horizLogosForUpload.push(horizLogo.horizontal);
                          }
                        }
                        uploadImages(
                          horizLogosForUpload,
                          "oem-management",
                          currTab === "oem-logo-tab"
                            ? "Horizontal"
                            : "HorizontalEvent",
                          mode,
                        );

                        const vertLogosForUpload: IUploadImageFormInput[] = [];
                        for (const vertLogo of currTab === "oem-logo-tab"
                          ? logosInDictionary.verticalLogos
                          : logosInDictionary.verticalLogosEvent) {
                          if (vertLogo.vertical) {
                            vertLogosForUpload.push(vertLogo.vertical);
                          }
                        }
                        uploadImages(
                          vertLogosForUpload,
                          "oem-management",
                          currTab === "oem-logo-tab"
                            ? "Vertical"
                            : "VerticalEvent",
                          mode,
                        );

                        const squareLogosForUpload: IUploadImageFormInput[] =
                          [];
                        for (const squareLogo of currTab === "oem-logo-tab"
                          ? logosInDictionary.squareLogos
                          : logosInDictionary.squareLogosEvent) {
                          if (squareLogo.square) {
                            squareLogosForUpload.push(squareLogo.square);
                          }
                        }
                        uploadImages(
                          squareLogosForUpload,
                          "oem-management",
                          currTab === "oem-logo-tab" ? "Square" : "SquareEvent",
                          mode,
                        );
                        setLogosInDictionary({
                          horizontalLogos: [],
                          verticalLogos: [],
                          squareLogos: [],
                          horizontalLogosEvent: [],
                          verticalLogosEvent: [],
                          squareLogosEvent: [],
                        });

                        setSingleLogoObject({
                          group: "string",
                          feedbackMessage: "",
                        });
                      }}
                    >
                      Submit
                    </Button>
                  }
                />
              );
            }
            return (
              <Step
                key={step.toLowerCase()}
                title={step}
                description={
                  <div>
                    <ImageUploaderBatch
                      title={step}
                      s3AssetBucket={s3AssetBucket}
                      singleLogoObject={singleLogoObject}
                      setSingleLogoObject={setSingleLogoObject}
                      disabled={!isAdmin}
                    />

                    {currTab === "oem-logo-tab"
                      ? logosInDictionary[
                          `${step.toLowerCase()}Logos` as TUploadSetLogosInDictionary
                        ].map((logo: ISingleLogoObject, index) => {
                          return (
                            <div
                              key={`${step}${index}`}
                              style={{ display: "flex" }}
                            >
                              <div>
                                {logo[`${step.toLowerCase()}` as TUploadSteps]
                                  ?.filename || ""}
                              </div>
                              <span
                                onClick={() =>
                                  deleteAttachedLogoFile(
                                    logo,
                                    `${step.toLowerCase()}Logos`,
                                    step.toLowerCase(),
                                  )
                                }
                                style={{
                                  paddingLeft: "4px",
                                  cursor: "pointer",
                                }}
                              >
                                x
                              </span>
                            </div>
                          );
                        })
                      : logosInDictionary[
                          `${step.toLowerCase()}LogosEvent` as TUploadSetLogosInDictionary
                        ].map((logo: ISingleLogoObject, index) => {
                          return (
                            <div
                              key={`${step}${index}`}
                              style={{ display: "flex" }}
                            >
                              <div>
                                {logo[`${step.toLowerCase()}` as TUploadSteps]
                                  ?.filename || ""}
                              </div>
                              <span
                                onClick={() =>
                                  deleteAttachedLogoFile(
                                    logo,
                                    `${step.toLowerCase()}LogosEvent`,
                                    step.toLowerCase(),
                                  )
                                }
                                style={{
                                  paddingLeft: "4px",
                                  cursor: "pointer",
                                }}
                              >
                                x
                              </span>
                            </div>
                          );
                        })}
                  </div>
                }
              />
            );
          })}
        </Steps>

        <br></br>

        <div style={{ fontWeight: "bold" }}>
          {industryType("auto") ? "OEM Logo(s)" : "Brand Logo(s)"}
        </div>
        <Collapse
          className="collapse-container"
          bordered={false}
          expandIconPosition="right"
        >
          {[
            "horizontalImagesFromS3",
            "verticalImagesFromS3",
            "squareImagesFromS3",
          ].map((key: string) => {
            let section = key.split("Images")[0];
            section = section.charAt(0).toUpperCase() + section.slice(1);
            return (
              <Collapse.Panel
                key={`${key}-collapse-oem`}
                className="filter-section-container-panel"
                header={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ paddingRight: "7px" }}>{section}</div>
                    <div className="orange-counter">
                      {`${
                        oemToEditWithLogos?.logoUrlsFromS3?.[
                          key as TUploadLogosS3DictionaryKey
                        ].length || 0
                      }`}
                    </div>
                  </div>
                }
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                  }}
                >
                  {oemToEditWithLogos?.logoUrlsFromS3?.[
                    key as TUploadLogosS3DictionaryKey
                  ].map((logo: string) => {
                    return (
                      <div
                        key={`${logo}-index`}
                        style={{
                          border: "1px dashed gray",
                          width: "30%",
                          height: "80px",
                          background: "lightgray",
                        }}
                      >
                        <div
                          style={{
                            float: "right",
                            cursor: !isAdmin ? "not-allowed" : "pointer",
                          }}
                          onClick={() => {
                            if (!isAdmin) return;
                            setLogoNameToDelete(logo);
                            setLogoGroupToDelete(
                              key as TUploadLogosS3DictionaryKey,
                            );
                            toggleShowDeleteLogoBoxModal(true);
                          }}
                        >
                          <DeleteOutlined />
                        </div>
                        <img
                          style={{
                            maxWidth: "30%",
                            maxHeight: "80px",
                            display: "block",
                          }}
                          src={logo}
                        />
                      </div>
                    );
                  })}
                </div>
              </Collapse.Panel>
            );
          })}
        </Collapse>
        <br />

        {/* OEM EVENT LOGO SECTION */}

        <div style={{ fontWeight: "bold" }}>
          {industryType("auto") ? "OEM Event Logo(s)" : "Additional Logo(s)"}
        </div>
        <Collapse
          className="collapse-container"
          bordered={false}
          expandIconPosition="right"
        >
          {[
            "horizontalEventImagesFromS3",
            "verticalEventImagesFromS3",
            "squareEventImagesFromS3",
          ].map((key: string) => {
            let section = key.split("Event")[0];
            section = section.charAt(0).toUpperCase() + section.slice(1);
            return (
              <Collapse.Panel
                key={`${key}-collapse-event`}
                className="filter-section-container-panel"
                header={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ paddingRight: "7px" }}>{section}</div>
                    <div className="orange-counter">
                      {`${
                        oemToEditWithLogos?.logoUrlsFromS3?.[
                          key as TUploadLogosS3DictionaryKey
                        ]?.length || 0
                      }`}
                    </div>
                  </div>
                }
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                  }}
                >
                  {oemToEditWithLogos?.logoUrlsFromS3?.[
                    key as TUploadLogosS3DictionaryKey
                  ].map((logo: string) => {
                    return (
                      <div
                        key={`${logo}-index`}
                        style={{
                          border: "1px dashed gray",
                          width: "30%",
                          height: "80px",
                          background: "lightgray",
                        }}
                      >
                        <div
                          style={{
                            float: "right",
                            cursor: !isAdmin ? "not-allowed" : "pointer",
                          }}
                          onClick={() => {
                            if (!isAdmin) return;
                            setLogoNameToDelete(logo);
                            setLogoGroupToDelete(
                              key as TUploadLogosS3DictionaryKey,
                            );
                            toggleShowDeleteLogoBoxModal(true);
                          }}
                        >
                          <DeleteOutlined />
                        </div>
                        <img
                          style={{
                            maxWidth: "30%",
                            maxHeight: "80px",
                            display: "block",
                          }}
                          src={logo}
                        />
                      </div>
                    );
                  })}
                </div>
              </Collapse.Panel>
            );
          })}
        </Collapse>

        <Modal
          title="Confirm Deletion"
          visible={showDeleteLogoBoxModal}
          footer={[
            <Button
              key="oem-no-button"
              onClick={() => toggleShowDeleteLogoBoxModal(false)}
            >
              No
            </Button>,
            <Button
              onClick={() => {
                deleteSubmittedLogoBox(logoNameToDelete, logoGroupToDelete);
                toggleShowDeleteLogoBoxModal(false);
              }}
              key="oem-yes-button"
              type={"primary"}
            >
              Yes
            </Button>,
          ]}
        >
          <Typography.Text>
            {`Are you sure you sure you want to delete ${logoNameToDelete}?`}
          </Typography.Text>
        </Modal>
      </div>
    </div>
  );
};

export default OemLogos;
