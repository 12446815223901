import { times } from "lodash";
import { useCallback } from "react";
import { IDimension, IExtendedFabricObject } from "shared/types/designStudio";
import { getDataFromDragEvent } from "utils/canvas/helpers.logo";
import { CanvasHandlers, CanvasProps } from "../Canvas";
import { TImageInsertData } from "./useImageInsertData";
import { findAllOccurrences } from "utils/helpers.string";

export type AddToHistory = (
  canvas: fabric.Canvas,
  originalDimension: IDimension,
  wrapperDiv: HTMLDivElement | null,
  CANVAS_MARGIN: number,
  onCanvasJsonUpdate: CanvasHandlers["onCanvasJsonUpdate"],
  replace?: boolean,
) => void;

type UseCallbacksArgs = {
  canvas?: fabric.Canvas;
  updateImageInsertData: (imageInsertData: TImageInsertData) => void;
  addToHistory: AddToHistory; // CHANGE!!
  props: CanvasProps & CanvasHandlers;
  wrapperDiv: HTMLDivElement | null;
  CANVAS_MARGIN: number;
};
type OnAfterVariableSelected = {
  selectionOffset: number;
  text: string;
  object: IExtendedFabricObject;
};
export default (args: UseCallbacksArgs) => {
  const onAfterDrop = useCallback(
    ({
      eventType: logoEventType,
      dropZone: logoDropZoneType,
      logoPosition: position,
      logoDimension,
    }: ReturnType<typeof getDataFromDragEvent>) => {
      // NOTE: this will trigger useImageInsertData in Editor.context.tsx which will insert object into canvas.
      args.updateImageInsertData({
        type: "LOGO",
        data: {
          logoEventType,
          logoDropZoneType,
          position,
          logoDimension,
        },
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onAfterVariableSelected = useCallback(
    async (funcArgs: OnAfterVariableSelected) => {
      const { canvas, props } = args;
      if (!canvas) return;

      const { object, text, selectionOffset } = funcArgs;
      const textbox = object as unknown as fabric.Textbox;

      // We first need to exit editing mode here.
      // Reason is we will need to clone the object but when textbox is in editing mode, there are few attributes
      //  that are set by fabric internally and those modified attributes affect behavior of the cloned textbox.
      textbox.exitEditing();
      insertText(textbox, text, selectionOffset);

      textbox.enterEditing().set("selectable", true);

      canvas.requestRenderAll();

      args.addToHistory(
        canvas,
        props.dimension,
        args.wrapperDiv,
        args.CANVAS_MARGIN,
        props.onCanvasJsonUpdate,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [args.canvas],
  );

  const insertText = (
    textbox: fabric.Textbox,
    text: string,
    selectionOffset: number,
  ) => {
    const getStyles = () => {
      const defaultStyle = {
        fontSize: textbox.fontSize,
        deltaY: 0,
      };

      const charStyleBeforeSelection =
        textbox.styles[lineIndex]?.[charIndex - 1] ?? defaultStyle;

      return times(text.length, () => charStyleBeforeSelection);
    };

    const { lineIndex, charIndex } = textbox.get2DCursorLocation(
      undefined,
      true,
    );

    const newLineIndices = findAllOccurrences(textbox.text!, "\n");
    const lineOffset = lineIndex === 0 ? 0 : newLineIndices[lineIndex - 1] + 1;
    const insertionStartIndex = lineOffset + charIndex - selectionOffset;
    const insertionEndIndex = lineOffset + charIndex;
    textbox.insertChars(
      text,
      getStyles(),
      insertionStartIndex,
      insertionEndIndex,
    );

    const endOfInsertion =
      lineOffset + charIndex - selectionOffset + text.length;
    textbox.setSelectionStart(endOfInsertion);
    textbox.setSelectionEnd(endOfInsertion);
  };

  return {
    onAfterDrop,
    onAfterVariableSelected,
  };
};
