import { useMutation, useQueries } from "react-query";
import API from "services";
import {
  ExportAssetBatchesResult,
  ExportBatchAssetsBody,
  ExportAssetBatchesStatus,
} from "shared/types/assetExporter";

const exportAssetBatches = async (body: ExportBatchAssetsBody) => {
  const errMsg = "Unable to start Asset Batch export process";
  try {
    const res = await API.services.assetExporter.exportAssetBatches(body);
    if (res.error) throw new Error(errMsg);
    return res.result;
  } catch (error) {
    throw new Error(errMsg);
  }
};

export const useStartExportAssetBatches = () => {
  return useMutation<
    ExportAssetBatchesResult | undefined,
    Error,
    ExportBatchAssetsBody
  >(body => exportAssetBatches(body));
};

const getExportAssetBatchesStatus = async (
  executionId: string,
): Promise<ExportAssetBatchesStatus> => {
  const errorMsg = "Unable to get Asset Batch export status";
  try {
    const res = await API.services.assetExporter.getExportAssetBatchesStatus(
      executionId,
    );
    if (res.error?.message === "Job not found")
      return {
        executionId,
        status: "Processing",
        description: "Processing Data",
        responseJson: {
          percentage: 30,
          outputs: [],
        },
      };
    if (res.error) throw new Error(res.error.message);
    if (!res.result) throw new Error(errorMsg);
    return res.result;
  } catch (error) {
    throw new Error(errorMsg);
  }
};

const exportIdsKey = "assetBatches/executionIds";

export const useAssetBatchesExportRequests = (executionArns: string[]) => {
  return useQueries(
    executionArns.map(executionArn => {
      return {
        queryKey: [exportIdsKey, executionArn],
        queryFn: () => getExportAssetBatchesStatus(executionArn),
        refetchInterval: (data: any) => {
          const job = data as ExportAssetBatchesStatus;
          return !job || !["Completed", "Error"].includes(job.status)
            ? 3000
            : false;
        },
        staleTime: Infinity,
      };
    }),
  );
};
