import { DesignStudioFields } from "screens/designStudio/context/fields";

export const designStudioTableColumns = {
  name: "name",
  category: "category",
  status: "status",
  assetType: "assetType",
  templateType: "templateType",
  mediaType: "mediaType",
  dimensions: "dimensions",
  numberOfStamps: "numberOfStamps",
  artboardName: "artboardName",
  stores: "stores",
  oems: "oems",
  created: "created",
  lastEdited: "lastEdited",
  tags: "tags",
  location: "location",
} as const;

const baseColumns = [
  designStudioTableColumns.name,
  designStudioTableColumns.category,
  designStudioTableColumns.lastEdited,
  designStudioTableColumns.tags,
];

export const defaultColumnsPreset = Object.values(designStudioTableColumns);

export const templatesColumnsPreset = [
  ...baseColumns,
  designStudioTableColumns.templateType,
  designStudioTableColumns.mediaType,
  designStudioTableColumns.assetType,
  designStudioTableColumns.artboardName,
  designStudioTableColumns.dimensions,
  designStudioTableColumns.stores,
  designStudioTableColumns.oems,
  designStudioTableColumns.numberOfStamps,
  designStudioTableColumns.status,
  designStudioTableColumns.location,
];

export const artboardsColumnsPreset = [
  ...baseColumns,
  designStudioTableColumns.assetType,
  designStudioTableColumns.mediaType,
  designStudioTableColumns.dimensions,
  designStudioTableColumns.artboardName,
];

export const stampsColumnsPreset = [
  ...baseColumns,
  designStudioTableColumns.stores,
  designStudioTableColumns.oems,
  designStudioTableColumns.status,
  designStudioTableColumns.location,
  designStudioTableColumns.mediaType,
];

export const columnPresets: { [x in ColumnPresets]: DesignStudioFields[] } = {
  default: defaultColumnsPreset,
  templates: templatesColumnsPreset,
  stamps: stampsColumnsPreset,
  artboards: artboardsColumnsPreset,
} as const;

export type ColumnPresets = "default" | "templates" | "stamps" | "artboards";
