import { TAssetBatch } from "shared/types/assetExporter";
import {
  MatchColumnsTableData,
  RecommendedColumnMatch,
  RecommendedColumnTitle,
  RowType,
} from "../../shared/types";
import { getAssetBatchById } from "./getAssetBatchById";

export const getColumnMatch = (
  recommendedColumnTitle: RecommendedColumnTitle | "",
  assetBatchesForSelectedRows: TAssetBatch[],
  matchColumnsRow: MatchColumnsTableData,
) => {
  const columnMatch: RecommendedColumnMatch = {
    recommendedColumnTitle: recommendedColumnTitle || null,
  };

  if (recommendedColumnTitle) {
    const matchedAssetBatch = getAssetBatchById(
      assetBatchesForSelectedRows,
      matchColumnsRow.assetBatchId,
    );

    if (matchColumnsRow.rowType === RowType.AssetName && matchedAssetBatch) {
      columnMatch.namingRulePattern = matchedAssetBatch.namingRulePattern;
      columnMatch.assetBatchName = matchedAssetBatch.name;
    }

    if (matchColumnsRow.rowType === RowType.Media && matchedAssetBatch) {
      columnMatch.assetBatchId = matchedAssetBatch.assetBatchId;
      columnMatch.assetBatchCreatedAt = matchedAssetBatch.createdAt;
    }
  }

  return columnMatch;
};
