import { fabric } from "fabric";
import {
  IZipExport,
  IVideoParams,
  IUploadCanvasImageResponse,
} from "shared/types/assetBuilder";
import { IVideoObject } from "shared/types/designStudio";
import HttpClient from "services/httpClient";
import { IConfig } from "shared/types/configuration";

const getVideoObject = (videoJson: any) => {
  const obj = videoJson.objects.find((obj: any) => {
    return !!obj.customData?.videoSrc;
  });
  return obj;
};

export const getContainsVideo = (json: any) => {
  return !!json.objects.find((obj: any) => !!obj.customData?.videoSrc);
};

const getIsBackgroundVideo = (videoJson: any) => {
  const index = videoJson.objects.findIndex((obj: any) => {
    const hasVideo = !!obj.customData?.videoSrc;
    const isThmBg =
      obj.customType === "selected_video" ||
      obj.customType === "theme_background";
    return hasVideo && isThmBg;
  });
  return index === 0;
};

const getVideoDimensions = (
  videoObj: IVideoObject,
  templateDimension: { width: number; height: number },
) => {
  const dimension = {
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  };

  dimension.width = Math.floor(
    Math.min(videoObj.width * videoObj.scaleX, templateDimension.width),
  );
  dimension.height = Math.floor(
    Math.min(videoObj.height * videoObj.scaleY, templateDimension.height),
  );

  if (dimension.width % 2 !== 0) {
    dimension.width += 1;
  }
  if (dimension.height % 2 !== 0) {
    dimension.height += 1;
  }

  dimension.x = videoObj.left >= 0 ? videoObj.left : 0;
  dimension.y = videoObj.top >= 0 ? videoObj.top : 0;

  return dimension;
};

const getObjIdx = (json: any, url: string) => {
  const index = json.objects.findIndex((obj: any) => {
    return obj.src === url;
  });
  return index;
};

const getTemplateImageUrl = (
  json: any,
  dimension: { width: number; height: number },
  isVideoInBackground: boolean,
) => {
  return new Promise<string>((resolve, reject) => {
    try {
      const canvas = new fabric.Canvas(null);
      canvas.width = dimension.width;
      canvas.height = dimension.height;

      const canvasJson = {
        ...json,
      };

      if (isVideoInBackground) {
        const { videoSrc } = getVideoObject(canvasJson).customData;
        canvasJson.objects.shift();
        const imgUrl = videoSrc.replace("mp4", "png");
        const idx = getObjIdx(canvasJson, imgUrl);
        canvasJson.objects.splice(0, idx + 1);
      }

      canvas.loadFromJSON(canvasJson, () => {
        resolve(canvas.toDataURL());
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getVideoParams = async (
  videoData: IZipExport,
  config: IConfig,
) => {
  return new Promise<IVideoParams>(async (resolve, reject) => {
    try {
      const { json, dimension, dest, vin, imageOption, filename } = videoData;
      const isBackgroundVideo = getIsBackgroundVideo(json);
      const videoObject = getVideoObject(videoData.json);
      const videoDimension = getVideoDimensions(videoObject, dimension);
      const base64 = await getTemplateImageUrl(
        json,
        dimension,
        isBackgroundVideo,
      );
      const uploadCanvasImageUrl = config.services.uploadCanvasImageUrl;
      const { result, error } =
        await HttpClient.post<IUploadCanvasImageResponse>(
          uploadCanvasImageUrl,
          {
            canvas64Data: {
              canvas: base64,
              templateAndDimensions: videoData.filename,
            },
          },
          {
            cache: "no-cache",
          },
        );

      if (!result?.canvasID || error) {
        throw Error("Error Uploading Video Still Image");
      }
      resolve({
        videoDimension,
        imageS3Key: result.canvasID,
        videoUrl: videoObject.customData.videoSrc,
        isBackgroundVideo: isBackgroundVideo,
        isVideo: true,
        dest,
        vin,
        imageOption,
        filename,
      });
    } catch (err) {
      reject(err);
    }
  });
};
