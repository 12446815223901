import { useMutation } from "react-query";
import API from "services";
import { FEED_STATUS } from "shared/constants/assetExporter";
import {
  GetValidateCredsBody,
  IFeedForm,
  ServerConfigParams,
} from "shared/types/assetExporter";
import { getErrorMessage } from "utils/errorMessage";
import { createNextTimeStamp } from "utils/helpers.assetExporter";
import { v4 as uuidv4 } from "uuid";
import { useAppSelector } from "../useAppSelector";
import { getFeedFormAttrs } from "./utils";

const putServerConfig = async (form: IFeedForm, isUpdatedPwd: boolean) => {
  try {
    const { validateCredentials } = API.services.assetExporter;
    const validateCredsBody: GetValidateCredsBody = {
      host: form.host ?? "",
      // form.password is encrypted password
      password: form.password ?? "",
      path: form.path ?? "",
      port: parseInt(form.port ?? "22"),
      username: form.username ?? "",
    };
    const { error } = await validateCredentials(validateCredsBody);
    if (!!error) throw new Error("Invalid Credentials");
    const feedId = uuidv4();
    const createdAt = Date.now();

    const body: ServerConfigParams = {
      ...form,
      id: feedId,
      createdAt,
      lastEdited: Date.now(),
      permToEncrypt: isUpdatedPwd,
      nextUpdate:
        form.frequency !== "Never" ? createNextTimeStamp(form) : undefined,
      sync: true,
      status: FEED_STATUS,
      lastUpdate: Date.now(),
    };

    const res = await API.services.assetExporter.putServerConfig(body);
    if (!res || !res.result?.success) {
      throw new Error("Unable to set server config");
    }
    const filename = `${feedId}_${createdAt}.csv`;
    return filename;
  } catch (error) {
    const errMsg = getErrorMessage(error);
    throw new Error(errMsg);
  }
};

const useServerConfig = () => {
  const { feedDrawer } = useAppSelector(
    state => state.assetExporter.sourceFeed,
  );
  const isUpdate = feedDrawer.drawerMode === "UPDATE";
  const isUpdatedPwd = !!feedDrawer.tempPassword;
  const body = getFeedFormAttrs(feedDrawer, isUpdate, "server");
  return useMutation<string, Error>(() => putServerConfig(body, isUpdatedPwd));
};

export default useServerConfig;
