import styles from "./MediaLogoItem.module.scss";

interface Props {
  logo: string;
}

export const MediaLogoItem = ({ logo }: Props) => {
  return (
    <div className={styles.host}>
      <img src={logo} />
    </div>
  );
};
