import { useCallback, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

export default () => {
  const isSpacePressed = useRef(false);
  const [paneMovable, setPaneMovable] = useState(false);
  const [paneInMoving, setPaneInMoving] = useState(false);
  useHotkeys(
    "space",
    e => {
      e.preventDefault();
      if (isSpacePressed.current) return;

      setPaneMovable(true);
      isSpacePressed.current = true;
    },
    { keydown: true },
  );
  useHotkeys(
    "space",
    () => {
      setPaneMovable(false);
      isSpacePressed.current = false;
    },
    { keyup: true },
  );

  const onMoveStart = useCallback(() => {
    if (paneInMoving) return;

    setPaneInMoving(true);
  }, [paneInMoving]);

  const onMoveEnd = useCallback(() => {
    if (!paneInMoving) return;

    setPaneInMoving(false);
  }, [paneInMoving]);

  return {
    paneMovable,
    paneInMoving,
    setPaneMovable,
    setPaneInMoving,
    onMoveStart,
    onMoveEnd,
  };
};
