import API from "services";
import {
  FacebookHTTPMethod,
  IFacebookGetResponseData,
  IFacebookProductSet,
  IProxyRequestBody,
} from "./types";
import { template } from "lodash";

// Product Catalog ID should be provided by the selected Store/Dealer
export default class ProductCatalog {
  constructor(readonly productCatalogId: string) {}

  getProductCatalogId() {
    return this.productCatalogId;
  }

  private getEndpoint(url: string) {
    return `/${this.productCatalogId}${url}`;
  }

  private getProxyRequestBody(params: {
    method: FacebookHTTPMethod;
    apiEndpoint: string; // the api endpoint like /campaigns or /<campaign_id/ads
    useProductCatalogId?: boolean; // if true, will use /act_<id>/ prefix
    fieldsString?: string; // parameters in Facebook Edge query
    limit?: number; // limits the number of items to return
  }) {
    const { apiEndpoint, useProductCatalogId, fieldsString, limit, method } =
      params;

    let queryTemplateString = fieldsString ? `?fields=<%= fields%>` : undefined;

    if (limit) {
      queryTemplateString = queryTemplateString
        ? `${queryTemplateString}&limit=${params.limit}`
        : `?limit=${params.limit}`;
    }

    const proxyBody: IProxyRequestBody = {
      method,
      platform: "facebook",
      endpoint: useProductCatalogId
        ? this.getEndpoint(apiEndpoint)
        : apiEndpoint,
      query: queryTemplateString
        ? template(queryTemplateString)({ fields: fieldsString })
        : limit
        ? queryTemplateString
        : undefined,
    };

    return proxyBody;
  }

  async getProductSets() {
    const getAccountsProxyBody: IProxyRequestBody = this.getProxyRequestBody({
      limit: 2000,
      method: "GET",
      useProductCatalogId: true,
      apiEndpoint: "/product_sets",
      fieldsString: "id,name,product_catalog,product_count",
    });

    const { result: productSetsRes, error: productSetsErr } =
      await this.makeRequest<IFacebookGetResponseData<IFacebookProductSet>>(
        getAccountsProxyBody,
      );

    if (productSetsErr || !productSetsRes?.data) {
      return {
        result: null,
        error: {
          message:
            productSetsErr?.message ||
            "Something went wrong while getting the product sets.",
        },
      };
    }

    return { result: { ...productSetsRes }, error: null };
  }

  private makeRequest<TResult>(body: IProxyRequestBody) {
    return API.services.adLibrary.platformProxyRequest<TResult>(body);
  }
}
