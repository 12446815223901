import { IConfig } from "shared/types/configuration";
import { IApiResponse } from "shared/types/shared";
import HttpClient from "./httpClient";

export default ({ config }: { headers: any; config: IConfig }) => ({
  token: (params: MGTokenParams) => {
    return HttpClient.post<IApiResponse<WDTokenResponse>>(
      config.services.media.token,
      params,
    );
  },
});
