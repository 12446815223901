import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Form, Input, Menu, Popover } from "antd";
import {
  DataSourceItemObject,
  DataSourceItemType,
} from "antd/lib/auto-complete";
import Select from "antd/lib/select";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import {
  ComparisonOperator,
  TRule,
  TThenType,
} from "shared/types/assetExporter";
import { ITemplate } from "shared/types/designStudio";
import "./RuleForm.scss";

interface IRuleFormProps {
  rule?: Partial<TRule>; // undefined indicating that the form is for new rule.
  ruleIndex: number;
  templates: ITemplate[];
  templateIsDeleted: boolean;
  dataSource?: DataSourceItemType[];
  editable: boolean; // undefined meaning it is editable
}
interface IRuleFormHandlers {
  onRuleUpdate?: (rule: Partial<TRule>, ruleIndex: number) => void;
}

type UpdateRule = (
  key: string,
  idx: number,
) => (event: string | ChangeEvent<HTMLInputElement>) => void;

type Props = IRuleFormProps & IRuleFormHandlers;

const RuleForm = (props: Props) => {
  const [searchBy, setSearchBy] = useState<string>();
  const { templates, templateIsDeleted } = props;
  const updateRule: UpdateRule = useCallback(
    (key, idx) => event => {
      if (typeof event !== "string") event.preventDefault();

      // NOTE: Below, type casting to ComparisonOperator will be safe since user chose from selections
      const value: string | ComparisonOperator =
        typeof event === "string"
          ? (event as ComparisonOperator)
          : event.target.value;

      let updatedCondition = props.rule?.conditions?.[idx];
      if (!updatedCondition) {
        throw new Error(`Condition does not exist at index ${idx}`);
      }

      updatedCondition = {
        ...updatedCondition,
        leftEq:
          key === "left condition"
            ? { text: value, value }
            : updatedCondition.leftEq,
        op:
          key === "operator"
            ? (value as ComparisonOperator)
            : updatedCondition.op,
        rightEq: key === "right condition" ? value : updatedCondition.rightEq,
      };

      const updatedConditions = props.rule?.conditions?.map(
        (condition, index) => (index === idx ? updatedCondition! : condition),
      );

      const updatedRule: Partial<TRule> = {
        ...(props.rule || {}),
        conditions: updatedConditions,
      };

      props.onRuleUpdate?.(updatedRule, props.ruleIndex);

      setSearchBy(undefined);
    },
    [props],
  );

  const dataSource = useMemo(
    () =>
      !searchBy
        ? props.dataSource || []
        : props.dataSource?.filter(data =>
            typeof data === "string"
              ? data.toLowerCase().includes(searchBy.toLowerCase())
              : (data as DataSourceItemObject).text
                  .toLowerCase()
                  .includes(searchBy.toLowerCase()),
          ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchBy],
  );

  const comparisonOperatorDataSource = useMemo(
    () =>
      Object.values(ComparisonOperator).map(value => ({ text: value, value })),
    [],
  );

  const thenDataSource = useMemo(
    () =>
      !searchBy
        ? templates.map(template => ({
            text: template.name,
            value: template.id,
          }))
        : templates
            .filter(template =>
              template.name.toLowerCase().includes(searchBy.toLowerCase()),
            )
            .map(template => ({ text: template.name, value: template.id })),
    [templates, searchBy],
  );

  const { isRegexOn } = props.rule?.then || { isRegexOn: false };
  const thenType = props.rule?.then?.type || "text";

  const [selectedThenType, setSelectedThenType] = useState<TThenType>(thenType);
  const defaultThenType: TThenType = "text";

  return (
    <Form className="rule-form" layout="vertical">
      <Form.Item label="Name New Rule">
        <Input
          className="rule-name-input"
          placeholder="Rule name"
          value={props.rule?.name}
          onChange={e => {
            e.preventDefault();

            props.onRuleUpdate?.(
              {
                ...(props.rule || {}),
                name: e.target.value,
              },
              props.ruleIndex,
            );
          }}
        />
      </Form.Item>

      {props.rule?.conditions?.map((condition, idx) => {
        return (
          <Form.Item
            key={`condition-${idx}`}
            label={condition.type === "if" ? "IF" : "AND IF"}
          >
            <div className="rule-form-if">
              {["left condition", "operator", "right condition"].map(key => {
                let value;
                if (key === "left condition") {
                  value = condition.leftEq.value;
                } else if (key === "operator") {
                  value = condition.op;
                } else {
                  value = condition.rightEq;
                }
                return key === "left condition" || key === "operator" ? (
                  <AutoComplete
                    className={`rule-condition-${key.replace(/\s+/g, "_")}`}
                    key={`auto-complete-${key.replace(" ", "-")}`}
                    value={value}
                    placeholder={key}
                    dataSource={
                      key === "left condition"
                        ? dataSource
                        : comparisonOperatorDataSource
                    }
                    onSearch={value => {
                      setSearchBy(value);
                    }}
                    onChange={
                      // For AutoComplete, a string will be passed for "value"
                      updateRule(key, idx)
                    }
                    onFocus={() => {
                      setSearchBy(undefined);
                    }}
                  />
                ) : (
                  <Input
                    className={`rule-condition-${key.replace(/\s+/g, "_")}`}
                    key={`input-${key.replace(" ", "-")}`}
                    placeholder={key}
                    value={value}
                    onChange={updateRule(key, idx)}
                  />
                );
              })}

              {props.editable && (
                <div className="delete-and-if">
                  {condition.type === "and-if" && (
                    <Button
                      shape="circle"
                      icon={<CloseOutlined />}
                      onClick={e => {
                        e.preventDefault();

                        const updatedConditions = [
                          ...(props.rule?.conditions || []),
                        ];
                        updatedConditions.splice(idx, 1);

                        props.onRuleUpdate?.(
                          {
                            ...props.rule,
                            conditions: updatedConditions,
                          },
                          props.ruleIndex,
                        );
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          </Form.Item>
        );
      })}
      <div className="rule-form-then">
        <div className="rule-form-then-type">
          <Form.Item label="Type">
            <Select
              className="rule-form-then-type-select"
              defaultValue={props.rule?.then?.type || "text"}
              value={props.rule?.then?.type || "text"}
              onChange={(value: TThenType) => {
                const isDifferent = value !== props.rule?.then?.type;

                setSelectedThenType(value);
                const isRegexOn = value === "regex";

                props.onRuleUpdate?.(
                  {
                    ...(props.rule || {}),
                    then: {
                      ...(props.rule?.then || {}),
                      type: value || props.rule?.then?.type || defaultThenType,
                      text: isDifferent ? "" : props.rule?.then?.text || "",
                      value: isDifferent ? "" : props.rule?.then?.value || "",
                      isRegexOn,
                    },
                  },
                  props.ruleIndex,
                );
              }}
            >
              <Select.Option value="text">Text</Select.Option>
              <Select.Option value="template">Template</Select.Option>
              <Select.Option value="regex">Regex</Select.Option>
            </Select>
          </Form.Item>
        </div>
        <div className="rule-form-then-input">
          <Form.Item label="THEN">
            <AutoComplete
              className="then-input"
              placeholder="then"
              value={props.rule?.then?.text || ""}
              dataSource={
                isRegexOn || selectedThenType === "text" ? [] : thenDataSource
              }
              onBlur={() => {
                setSearchBy(undefined);
              }}
              onSearch={value => {
                // the value in onSearch will be the text that user types.
                // search if there is thenDataSource that contains the value in its text.
                setSearchBy(value);
              }}
              onChange={value => {
                let textToUse: string = value as string;
                let valueToUse: string = value as string;
                if (!isRegexOn) {
                  switch (selectedThenType) {
                    case "template":
                      // we have to find the data source from thenDataSource
                      const found = thenDataSource.find(
                        source => source.value === value,
                      );

                      textToUse = found?.text || (value as string);
                      valueToUse = found?.value || "";
                      break;

                    case "text":
                      textToUse = value as string;
                      valueToUse = value as string;
                      break;

                    default:
                      throw new Error("Unknown Then type.");
                  }
                }

                props.onRuleUpdate?.(
                  {
                    ...(props.rule || {}),
                    then: {
                      ...(props.rule?.then || {}),
                      type: props.rule?.then?.type || defaultThenType,
                      text: textToUse,
                      value: valueToUse,
                    },
                  },
                  props.ruleIndex,
                );
                setSearchBy(undefined);
              }}
            />
            {selectedThenType === "template" && templateIsDeleted && (
              <span className="rule-form-error">
                The template no longer exists.
              </span>
            )}
          </Form.Item>
        </div>
        <div className="rule-form-regex-resource">
          <Form.Item label="Regex Column">
            <AutoComplete
              className="regex-column"
              disabled={!isRegexOn}
              value={props.rule?.then?.regexTargetColumn?.text}
              placeholder={"Regex Column"}
              dataSource={dataSource}
              onSearch={value => {
                setSearchBy(value);
              }}
              onBlur={() => {
                setSearchBy(undefined);
              }}
              onChange={value => {
                const found = (
                  dataSource as Array<{
                    text: string;
                    value: string;
                  }>
                )?.find(data => data.value === value);
                props.onRuleUpdate?.(
                  {
                    ...(props.rule || {}),
                    then: {
                      ...(props.rule?.then || {}),
                      type: props.rule?.then?.type || defaultThenType,
                      text: props.rule?.then?.text || "",
                      value: props.rule?.then?.value || "",
                      regexTargetColumn: {
                        text: found?.text || (value as string),
                        value: found?.value || "",
                      },
                    },
                  },
                  props.ruleIndex,
                );

                setSearchBy(undefined);
              }}
              // onChange={}
            />
          </Form.Item>
        </div>
      </div>

      {props.editable && (
        <div>
          <Popover
            title={null}
            placement="bottom"
            trigger="click"
            content={
              <Menu
                className="rule-form-if-else-select"
                mode="inline"
                selectedKeys={[]}
              >
                <Menu.Item
                  onClick={() => {
                    const updatedConditions = props.rule?.conditions || [];
                    updatedConditions.push({
                      type: "and-if",
                      leftEq: { text: "", value: "" },
                      op: ComparisonOperator.Equals,
                      rightEq: "",
                    });

                    props.onRuleUpdate?.(
                      {
                        ...(props.rule || {}),
                        conditions: updatedConditions,
                      },
                      props.ruleIndex,
                    );
                  }}
                >
                  and if
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              shape="circle"
              icon={<PlusOutlined />}
              className="and-if-button"
            />
          </Popover>
        </div>
      )}
    </Form>
  );
};

export default RuleForm;
