import { AutoComplete, Checkbox, Form } from "antd";
import { debounce } from "lodash";
import { useMediaContext } from "../MediaProvider";
import { useForm } from "./useForm";

import styles from "./FormQuery.module.scss";

export const FormQuery = () => {
  const { isDrawer } = useMediaContext();
  const { formFields, form, setSearchState, onValuesChange, options } =
    useForm();

  const onSelect = () => {
    setSearchState(form.getFieldsValue());
  };

  const onPressEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      setSearchState(form.getFieldsValue());
    }
  };

  return (
    <Form
      form={form}
      fields={formFields}
      className={styles.host}
      onValuesChange={debounce(onValuesChange, 1000)}
    >
      <Form.Item name="query">
        <AutoComplete
          onKeyDown={onPressEnter}
          options={options}
          onSelect={onSelect}
        />
      </Form.Item>
      <Form.Item
        hidden={isDrawer}
        name="published_filter"
        valuePropName="checked"
        className={styles.published}
      >
        <Checkbox onChange={onSelect}>Published</Checkbox>
      </Form.Item>
    </Form>
  );
};
