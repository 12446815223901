import { Button } from "antd";

type Props = {
  currentValue?: string;
  rawFeedValue?: string;
  title?: string;
};

type Handlers = {
  undo?: (currentValue?: string) => void;
};
const RevertNotification = ({
  title,
  currentValue,
  rawFeedValue,
  undo,
}: Props & Handlers) => {
  return (
    <div>
      <div>{`${title} has been reverted to the raw feed value of ${rawFeedValue}.`}</div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          type="primary"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            undo?.(currentValue);
          }}
        >
          Undo
        </Button>
      </div>
    </div>
  );
};

export default RevertNotification;
