/* eslint-disable react/display-name */
import {
  Col,
  Modal,
  Row,
  Switch,
  TableColumnProps,
  Tag,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { addOrRemove, compareString } from "utils/helpers";

import { TableRowSelection } from "antd/es/table/interface";
import { ToolbarButton } from "shared/types/adLibrary";
import { IDashboard } from "shared/types/dashboardManagement";

import ToolbarTable from "shared/components/toolbarTable/ToolbarTable";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import VirtualTable from "shared/components/VirtualTable";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { filterBySearch } from "utils/helpers.toolbar";
import NameCell from "./dashboardTable/NameCell";

import { useDeleteDashboard } from "shared/hooks/dashboardManagement/useDeleteDashboard";
import { useMutateDashboard } from "shared/hooks/dashboardManagement/useMutateDashboard";
import { useIsAdmin } from "shared/hooks/useIsAdmin";
import { renderTags } from "shared/components/TagListOverflow";
interface IDashboardTableHandlers {
  dashboards: IDashboard[];
  isLoading: boolean;
  setEditDashboardData: (dashboardData: IDashboard) => void;
  toggleDashboardDrawer: (showDashboardDrawer: boolean) => void;
}
const DashboardTable = ({
  isLoading,
  dashboards,
  setEditDashboardData,
  toggleDashboardDrawer,
}: IDashboardTableHandlers) => {
  const { windowInnerHeight } = useWindowSize();

  const isAdmin = useIsAdmin();

  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [currentRows, setCurrentRows] = useState<IDashboard[]>([]);
  const [searchValue, setSearchValue] = useState<string>(); // NOTE: undefined indicates component has not been mounted
  const [enabledRowKeys, setEnabledRowKeys] = useState<string[]>([]);
  const [dashboardsData, setDashboardsData] =
    useState<IDashboard[]>(dashboards);
  const { mutate: mutateDashboard } = useMutateDashboard();
  const { mutate: deleteDashboard } = useDeleteDashboard();

  useEffect(() => {
    setEnabledRowKeys(
      dashboards?.map(dashboard =>
        dashboard.enabled ? dashboard?.id || "" : "",
      ) || [],
    );
    setDashboardsData(dashboards);
  }, [dashboards]);

  const onClickEdit = (dashboard: IDashboard) => {
    setEditDashboardData(dashboard);
    toggleDashboardDrawer(true);
  };
  const resetSelection = () => setSelectedRowKeys([]);

  const onClickDelete = (records: IDashboard[]) => {
    Modal.confirm({
      title: "Are you sure you want to delete all selected dashboard(s)?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        records.map(record => record.id && deleteDashboard(record.id));
        resetSelection();
      },
      okType: "danger",
    });
  };
  const rowSelection: TableRowSelection<IDashboard> = {
    selectedRowKeys,
    onChange: (keys, rows) => {
      setSelectedRowKeys(keys);
      setCurrentRows(rows);
    },
  };

  const onClickToggle = (records: IDashboard[]) => {
    const checkedIds = records.map(record => record?.id || "");
    const allEnabled = checkedIds.every(key => enabledRowKeys.includes(key));
    records.map(record => mutateDashboard({ ...record, enabled: !allEnabled }));
    setEnabledRowKeys(
      !allEnabled
        ? [...enabledRowKeys, ...checkedIds]
        : [...enabledRowKeys].filter(key => !checkedIds.includes(key)),
    );
  };
  const convertDate = (date: string) => {
    return moment(parseInt(date)).format("YYYY/MM/DD");
  };

  const columns: Array<TableColumnProps<IDashboard>> = [
    {
      key: "name",
      title: "Name",
      dataIndex: ["title"],
      sorter: (a: any, b: any) => compareString(a.title, b.title),
      render: (title, dashboard) => (
        <NameCell
          title={title}
          onCellClick={() => {
            onClickEdit(dashboard);
          }}
        />
      ),
      width: "20%",
    },
    {
      key: "enable",
      title: "Enabled",
      dataIndex: ["enabled"],
      width: "7%",
      render: (enabled, dashboard) => (
        <Switch
          defaultChecked={enabled}
          checked={enabledRowKeys.includes(dashboard?.id || "")}
          onClick={() => {
            mutateDashboard({ ...dashboard, enabled: !dashboard.enabled });
            setDashboardsData(
              dashboardsData.map(d =>
                d.id === dashboard.id
                  ? { ...dashboard, enabled: !dashboard.enabled }
                  : d,
              ),
            );
            setEnabledRowKeys(addOrRemove(dashboard?.id || "", enabledRowKeys));
          }}
        />
      ),
    },
    {
      key: "lookerId",
      title: "Looker Dashboard ID",
      dataIndex: ["lookerId"],
      width: "7%",
    },
    {
      key: "date",
      title: "Date",
      dataIndex: ["startDate"],
      width: "12%",
      render: (_, dashboard) =>
        dashboard.startDate && dashboard.endDate ? (
          <Typography.Text>{`${convertDate(
            dashboard.startDate,
          )} - ${convertDate(dashboard.endDate)}`}</Typography.Text>
        ) : dashboard.startDate ? (
          <Typography.Text>{dashboard.startDate}</Typography.Text>
        ) : null,
    },
    {
      key: "model",
      title: "Models",
      dataIndex: ["models"],
      render: models =>
        models.map((model: string) => {
          return <Tag key={model}>{model}</Tag>;
        }),
      width: "15%",
    },
    {
      key: "accessFilter",
      title: "Access Filter",
      dataIndex: ["accessFilter"],
      width: "10%",
      render: filter => <Typography.Text>{filter}</Typography.Text>,
    },
    {
      key: "oem",
      title: "OEM",
      dataIndex: ["oem"],
      width: "13%",
      render: renderTags,
    },
    {
      key: "specialCampaign",
      title: "Special Campaigns",
      dataIndex: ["specialCampaign"],
      width: "13%",
      render: renderTags,
    },
    {
      key: "account",
      title: "Accounts",
      dataIndex: ["account"],
      width: "13%",
      render: renderTags,
    },
  ];

  const toolbarContents: ToolbarButton = {
    New: {
      extraInfo: {
        FULL: !isAdmin,
      },
      disabled: !isAdmin,
      onClick: () => {
        onClickEdit({});
        toggleDashboardDrawer(true);
      },
    },
    Edit: {
      onClick: () => onClickEdit(currentRows[0]),
      disabled: currentRows?.length !== 1,
    },
    Delete: {
      onClick: () => onClickDelete(currentRows),
      disabled: !currentRows?.length,
    },
    Toggle: {
      onClick: () => onClickToggle(currentRows),
      disabled: !currentRows?.length,
    },
  };

  const dataSource = filterBySearch(dashboardsData, searchValue || "");
  return (
    <Row style={{ padding: "15px 15px 0" }}>
      <Col span={24}>
        <ToolbarTable
          toolbarContents={toolbarContents}
          searchPlaceholder="Search"
          searchValue={searchValue || ""}
          onSearch={setSearchValue}
          titleTooltip="Search by any information"
        />
        <Row>
          <Col span={24}>
            <VirtualTable
              className="data-management-table"
              size="small"
              scroll={{ y: windowInnerHeight - 200 }}
              rowKey={"id"}
              columns={columns}
              loading={isLoading}
              dataSource={dataSource}
              pagination={false}
              rowSelection={rowSelection}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DashboardTable;
