import { Button, Drawer, notification } from "antd";
import { useCallback } from "react";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import {
  setInstantExperienceImport,
  setSelectedInstantExperienceIds,
} from "redux/designStudio/designStudio.slice";
import { useBatchMutateInstantExperiences } from "shared/hooks/adLibrary/useMutateInstantExperience";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { IInstantExperience } from "shared/types/adLibrary";
import InstantExperiencesImportTable from "./instantExperiencesCompleteImportDrawer/InstantExperiencesImportTable";

type Props = {
  handleClose(): void;
};

const InstantExperiencesCompleteImportDrawer = ({ handleClose }: Props) => {
  const dispatch = useAppDispatch();
  const instantExperiences = useAppSelector(
    state =>
      state.designStudio.instantExperienceImport.instantExperiencesToImport,
  );

  const instantExperienceImportIssues = useAppSelector(
    state =>
      state.designStudio.instantExperienceImport.instantExperienceImportIssues,
  );

  const setInstantExperience = useCallback(
    (instantExperienceUpdate: IInstantExperience) => {
      dispatch(setInstantExperienceImport(instantExperienceUpdate));
    },
    [dispatch],
  );

  const {
    mutateAsync: batchMutateInstantExperiencesAsync,
    isLoading: isCreatingInstantExperiences,
  } = useBatchMutateInstantExperiences();

  const selectedInstantExperienceIds = useAppSelector(
    state =>
      state.designStudio.instantExperienceImport.selectedInstantExperienceIds,
  );
  const handleSetSelectedInstantExperienceIds = useCallback(
    (selectedInstantExperienceIds: string[]) => {
      dispatch(setSelectedInstantExperienceIds(selectedInstantExperienceIds));
    },
    [dispatch],
  );

  const handleImportSelectedInstantExperiences = async () => {
    try {
      await batchMutateInstantExperiencesAsync(
        instantExperiences.filter(ie =>
          selectedInstantExperienceIds?.includes(ie.id ?? ""),
        ),
      );
      handleClose();
    } catch (e) {
      notification.error({
        message: "There was an error trying to complete the import.",
      });
    }
  };

  return (
    <Drawer
      title={
        <div>
          <strong>Review Import:</strong> {instantExperiences.length} instant
          experiences ready to import. Please review before completing the
          import.
        </div>
      }
      visible
      onClose={handleClose}
      width="100%"
      closable
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button onClick={handleClose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button
            loading={isCreatingInstantExperiences}
            onClick={handleImportSelectedInstantExperiences}
            disabled={selectedInstantExperienceIds.length === 0}
            type="primary"
          >
            Complete Import
          </Button>
        </div>
      }
      data-cy="complete-ie-import-drawer"
    >
      <InstantExperiencesImportTable
        instantExperiences={instantExperiences}
        selectedInstantExperienceIds={selectedInstantExperienceIds}
        setSelectedInstantExperienceIds={handleSetSelectedInstantExperienceIds}
        setInstantExperience={setInstantExperience}
        instantExperienceImportIssues={instantExperienceImportIssues}
      />
    </Drawer>
  );
};

export default InstantExperiencesCompleteImportDrawer;
