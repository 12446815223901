import { useState } from "react";
import { LoadStatus } from "screens/adLibrary/facebookUtils/adProto.types";
import FiltersBar from "./adLoadProgressTable/FiltersBar";
import { useColumns } from "./adLoadProgressTable/useColumns";
import { useDataSource } from "./adLoadProgressTable/useDataSource";
import VirtualTable from "shared/components/VirtualTable";
import { useWindowSize } from "shared/hooks/useWindowSize";

export type AdLoadProgressData = {
  id: string;
  adName: string;
  campaignName: string;
  adsetName: string;
  storeName: string;
  format: string;
  status: LoadStatus;
  errorMessage: string | undefined;
  thumbnail: string | undefined;
};

const AdLoadProgressTable = () => {
  const dataSource = useDataSource();
  const columns = useColumns(dataSource);

  const [filterBy, setFilterBy] = useState<LoadStatus | undefined>();

  const filteredDataSource = dataSource.filter(
    ad => !filterBy || ad.status === filterBy,
  );

  const { windowInnerHeight } = useWindowSize();
  return (
    <>
      <FiltersBar
        adLoadProgressData={dataSource}
        filterBy={filterBy}
        setFilterBy={setFilterBy}
      />
      <VirtualTable
        scroll={{ y: windowInnerHeight - 265 }}
        columns={columns}
        dataSource={filteredDataSource}
        rowKey={record => record.id}
        size="small"
        pagination={false}
      />
    </>
  );
};

export default AdLoadProgressTable;
