import { FilterValue, SortOrder } from "antd/lib/table/interface";
import { Key } from "react";
import { UseQueryOptions } from "react-query";
import {
  IDataListColumns,
  IDataListCondition,
  IDataListConfig,
  IDataListFilters,
  IDataListReducerState,
  IDataListSorter,
} from "./types";

export const getInitialState = (
  config: IDataListConfig,
): IDataListReducerState => {
  return {
    config,
    selectedIds: [],
    showSelected: false,
    filters: {},
    globalFilter: "",
    globalConditions: [],
    sort: config.defaultSortByOptions || {},
    conditions: {},
  };
};

export const createDataListConfig = (
  columns: IDataListColumns,
  useQueryOptions: UseQueryOptions,
  defaultSortByOptions?: IDataListSorter,
) => {
  return {
    columns,
    useQueryOptions,
    defaultSortByOptions: defaultSortByOptions || {},
  };
};

export const getRows = <T>(
  queryData: T[],
  state: IDataListReducerState,
  selectedItems: any[],
  tableId: string,
) => {
  const { showSelected } = state;
  const data = showSelected ? selectedItems : queryData;

  return sortData(filterData(data, state, tableId), state);
};

export const filterSelectedRows = (rows: any[], selectedIds: string[]) => {
  return rows.filter(record => selectedIds.includes(record.id));
};

const filterData = <T>(
  queryData: T[],
  state: IDataListReducerState,
  tableId: string,
): T[] => {
  const { conditions, globalConditions, globalFilter } = state;
  let data = queryData;
  if (globalFilter && globalConditions?.length) {
    data = data.filter(record =>
      globalConditions.some(({ value, fn }) => fn(value, record)),
    );
  }
  if (conditions[tableId]?.length) {
    data = data.filter(record =>
      conditions[tableId]?.every(({ value, fn }) => fn(value, record)),
    );
  }

  return data;
};

const sortData = <T>(data: T[], state: IDataListReducerState): T[] => {
  const { sort, config } = state;
  const { columnKey, order } = sort;

  if (columnKey && order && config.columns[columnKey]) {
    const { sorterFn } = config.columns[columnKey];
    if (sorterFn) {
      const clone = data.slice();
      clone.sort((a, b) =>
        order === "ascend" ? sorterFn(a, b) : sorterFn(b, a),
      );

      return clone;
    }
  }

  return data;
};

export const normalizeSortPayload = ({
  columnKey: k,
  order: o,
}: {
  columnKey?: Key;
  order?: SortOrder;
}) => {
  const order = o
    ? ((o.endsWith("end") ? o : `${o}end`) as SortOrder)
    : undefined;
  const columnKey = k && order ? k : undefined;

  return {
    columnKey,
    order,
  };
};

export const createConditions = (
  state: IDataListReducerState,
  tableId?: string,
): IDataListCondition[] => {
  const { config, filters, globalFilter } = state;
  const { columns } = config;

  if (globalFilter) {
    return Object.entries(columns).flatMap(([, val]) => {
      if (typeof val.filterFn === "function") {
        return [{ value: [globalFilter], fn: val.filterFn }];
      }

      return [];
    });
  }

  return tableId && filters[tableId]
    ? Object.entries(filters[tableId]).flatMap(([key, value]) => {
        if (value === null) {
          return [];
        }

        if (typeof columns[key].filterFn !== "function") {
          throw new Error("filterFn missing in columns config");
        }

        return [{ value, fn: columns[key].filterFn! }];
      })
    : [];
};

export const removeFilterValue = (
  filters: IDataListFilters,
  filterKey: string,
  removedValue: React.Key | boolean,
): IDataListFilters => {
  return filters
    ? Object.fromEntries(
        Object.entries(filters).flatMap(([key, val]) => {
          if (key === filterKey) {
            const arr = val.filter(v => v !== removedValue);
            if (arr.length) {
              return [[key, arr]];
            }

            return [];
          }

          return [[key, val]];
        }),
      )
    : {};
};

export const updateFilterValue = (
  filters: IDataListFilters,
  filterKey: string,
  newValue: string | string[] | null,
): IDataListFilters => {
  return removeEmptyFilters({
    ...filters,
    [filterKey]: getArrValue(newValue),
  });
};

export const removeEmptyFilters = (
  filters: Record<string, FilterValue | null>,
): IDataListFilters => {
  return Object.fromEntries(
    Object.entries(filters).filter(([, val]) => val !== null),
  ) as IDataListFilters;
};

const getArrValue = (value: string | string[] | null): string[] | null => {
  if (Array.isArray(value) && value.length) {
    return value;
  }

  if (typeof value === "string" && value.length) {
    return [value];
  }

  return null;
};
