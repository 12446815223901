import { useMutation, useQuery } from "react-query";
import API from "services";
import { FeedStatus, ProcessFeedBody } from "shared/types/assetExporter";

const TIME_10_SEC = 10000;

const processFeeds = async (body: ProcessFeedBody) => {
  const errMsg = "Unable to start feed process";
  try {
    const res = await API.services.assetExporter.processFeed(body);
    if (res.error) throw new Error(errMsg);
  } catch (error) {
    throw new Error(errMsg);
  }
};

export const useProcessFeed = () => {
  return useMutation<void, Error, ProcessFeedBody>(body => processFeeds(body));
};

const getFeedProcessStatus = async (feedId: string) => {
  const errorMsg = "Unable to get feed status";
  try {
    const res = await API.services.assetExporter.getFeedProcessStatus(feedId);
    if (res.error) throw new Error(res.error.message);
    if (!res.result) throw new Error(errorMsg);
    return res.result;
  } catch (error) {
    throw new Error(errorMsg);
  }
};

export const useGetFeedStatus = (feedId: string) => {
  return useQuery<FeedStatus, Error>(
    ["feedStatus", feedId],
    () => getFeedProcessStatus(feedId),
    {
      enabled: !!feedId,
      refetchInterval: TIME_10_SEC,
    },
  );
};
