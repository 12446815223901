import { cloneDeep, isEmpty, omit, orderBy, set, uniq } from "lodash";
import API from "services";
import {
  blankOfferData,
  floatValueFields,
  offerGenerationDescription,
  offerValidationDescription,
} from "shared/constants/assetBuilder";
import * as offerHelpers from "utils/helpers.offer";

import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import imageCompression from "browser-image-compression";
import { AppThunk } from "redux/store";
import { defaultOfferIndex } from "shared/constants/dataManagement";
import GenericError from "shared/errors/GenericError";
import { TRedirectPath } from "shared/hooks/assetBuilder/useRedirect";
import {
  AssetInstanceRecord,
  DealerDataFeed,
  EditedFieldId,
  FeedTab,
  IAssetBuild,
  IAssetBuildInstance,
  IAssetBuilderState,
  IAssetInstance,
  ICanvasData,
  ICheckedOfferFilter,
  ICoopIntegrationData,
  ICoopSubmissionResponse,
  IDeleteWebIntegrationResult,
  IExportImageOrVideoResult,
  IExportedAssetStatus,
  IFeedDataToCSVResponse,
  IGeneratePDFResponse,
  IGetCanvasZipUrlResponse,
  IGetPaymentEngineDataResponse,
  IGetRawOfferByVinOfferResponse,
  IGetRawOfferByVinOfferResult,
  IGetStagesAndRecipientsResponse,
  IGetWFFoldersResponse,
  IGetWFTemplatesResponse,
  IGetWebIntegrationStatusResponse,
  ILauncherData,
  IOfferDataGenerationDescription,
  IOfferItem,
  IOfferListResponse,
  IOfferWithDisclosure,
  IPDFCustomDisclosure,
  IPDFData,
  IProcessedOfferData,
  IProofExportDataResponse,
  IPushToProofResponse,
  IQueryParameters,
  ISavedOrderState,
  ISelectedOffer,
  ISortingOption,
  IUploadAssetToWFResult,
  IUploadAssetToWFResultResponse,
  IUploadCanvasImageResponse,
  IVideoHtmlRes,
  IWebIntegrationInstanceObject,
  IWebIntegrations,
  IWorkfrontProofData,
  IZipExport,
  IZipExportResponse,
  IZipExportUrlResponse,
  OfferData,
  OfferFromService,
  OfferOperationMode,
  OfferValidationErrors,
  ProcessedPaymentEngineData,
  RawOfferData,
  RawSelectedOffers,
  RepeatableOfferKeys,
  SingletonOfferKeys,
  TAssetTypeCount,
  TVisibility,
  VehicleConditions,
  SelectedInstance,
  checkedOfferFilterKeys,
} from "shared/types/assetBuilder";
import {
  AssetExportQueryParams,
  IAssetExportQueryParamObj,
} from "shared/types/assetExport";
import { IConfig } from "shared/types/configuration";
import { IResponse, TAssetType } from "shared/types/designStudio";
import { INewOrder } from "shared/types/newOrders";
import {
  GetPaymentEngineDealDataResult,
  PaymentEngineRequestBody,
} from "shared/types/paymentEngine";
import { OfferType } from "shared/types/shared";
import { getJobStatus } from "utils/aws/lambda";
import { isErrorWithMessage } from "utils/errorMessage";
import { getAssetCount, getStampCount } from "utils/helpers.asset";
import {
  aggregateRebateFieldValues,
  findMatchingDiscRow,
  formatRateAsDecimal,
  processRawSelectedOffers,
} from "utils/helpers.offer";
import { mapPaymentEngineDealDataToOfferData } from "utils/helpers.paymentEngine";
import { getVideoParams } from "utils/helpers.video";
import uuid from "uuid";
import { dedupe } from "utils/helpers.array";
import HttpClient from "services/httpClient";

const {
  REACT_APP_AV2_FEED: feed,
  REACT_APP_AV2_ENABLE_PDF_BACKGROUND: enablePDFInvoker,
  REACT_APP_AV2_ENABLE_COOP_PDF_BACKGROUND: enableCoopPDFInvoker,
} = process.env;

export const pruneInstanceOffers = (instance: IAssetInstance) => {
  return {
    ...instance,
    offers: Object.keys(instance.offers || {}).reduce(
      (offers, vin) => {
        const { offerData, offerTypes } = instance.offers[vin];

        if (
          Object.keys(offerTypes).find(
            offerType => offerTypes[offerType as OfferType],
          )
        ) {
          return {
            ...offers,
            [vin]: {
              offerData,
              offerTypes,
            },
          };
        }

        return offers;
      },
      {} as Record<
        string,
        {
          offerData: OfferData;
          offerTypes: Record<OfferType, boolean>;
        }
      >,
    ),
  };
};

const generateOfferData = (raw: RawOfferData): OfferData => {
  const offerData = { ...raw } as OfferData;
  const keys = Object.keys(offerGenerationDescription) as Array<
    keyof IOfferDataGenerationDescription
  >;

  for (const key of keys) {
    offerData[key] = offerGenerationDescription[key](raw);
  }

  return offerData;
};

const addValidationError = (
  rawOfferData: RawOfferData,
  key: keyof OfferValidationErrors,
  errors: OfferValidationErrors | null = {} as OfferValidationErrors,
): OfferValidationErrors => {
  const validate = offerValidationDescription[key];
  const value = rawOfferData[key];

  return {
    ...errors,
    [key]:
      validate !== undefined
        ? Array.isArray(value)
          ? value.map(validate)
          : validate(value)
        : null,
  } as OfferValidationErrors;
};

const generateValidationErrors = (rawOfferData: RawOfferData) => {
  const keys = Object.keys(rawOfferData) as Array<keyof RawOfferData>;
  let errors = {} as OfferValidationErrors;

  for (const key of keys) {
    errors = addValidationError(rawOfferData, key, errors);
  }

  return errors;
};

type OfferExists = {
  vin: string;
  feedId: string;
};

export const checkIfOfferExists = async (args: OfferExists) => {
  const { vin, feedId } = args;
  try {
    const { getOfferExists } = API.services.assetBuilder;
    const { result, error } = await getOfferExists(feedId, vin);

    if (error || !result) {
      // if there was an error, then the offer was not found
      return false;
    }
    return result.offerExist;
  } catch (error) {
    return false;
  }
};

export type TSetBuildPage = {
  assetBuild: IAssetBuild;
  selectedTab: TAssetType;
};

const getInitialState = (): IAssetBuilderState => {
  return {
    fetchingSelectedOfferList: false,
    fetchingOfferList: false,
    isDataEmpty: false,
    total: undefined,
    currentPage: 1,
    selectedOfferList: [],
    offerList: [],
    rawOfferData: blankOfferData(),
    editedKeys: {},
    offerValidationErrors: blankOfferData(),
    offerData: generateOfferData(blankOfferData()),
    masterSelectedOfferList: [],
    rawSelectedOffers: {},
    selectedOffers: [],
    assetInstances: {},
    checkedOfferFilters: {
      Lease: true,
      "Zero Down Lease": true,
      Finance: true,
      Purchase: true,
      APR: true,
    },
    errorMessage: null,
    s3Url: "",
    pdfUrl: "",
    pdfName: "",
    feedPDFUrl: "",
    canvasIDArr: [],
    processingExport: false,
    processingImageExport: false,
    generalMessage: "",
    selectedTemplateSizes: {},
    sortByFilter: [],

    savedOrder: null,

    redirect: undefined, // this redirection will be happening in AssetBuilder.tsx

    assetInstanceCounter: 0,
    offersWithDisclosures: [],
    disableExport: true,

    useUploadedJellyBean: false,
    uploadedJellybean: "",
    canvasLoadingOrder: [],

    jellyBeanBucket: `${process.env.S3_JELLYBEAN_BUCKET}`,

    editMode: false,

    proofHQTemplates: [],

    wfFolderList: [],

    offerEditsWereSaved: false,

    buildPage: {
      instances: {},
      selectedInstance: [],
    },
    processedPaymentEngineData: null,
    rawPaymentEngineData: null,
    getPaymentEngineDataError: null,
    jellybeanImages: [],
    getJellybeanImagesError: null,
    gettingJellybeanImages: false,

    offerOperationMode: "CREATE",
    searchQuery: "",
    gettingRawOfferByVin: false,
    getRawOfferByVinError: null,
    currentRawOffer: null,
    currentOfferEdit: null,
    offerEditTrackerObject: null,

    parentFileToken: "",
    documentID: "",
    savedSelectTemplateSearch: "",
    savedSelectImageSearch: "",
    assetInstanceComparator: {},
    offerDataComparator: generateOfferData(blankOfferData()),
    saveDialog: false,
    path: "",
    menuRoute: "",
    assetTypeCount: {},
    selectedExportedAssets: {},
    createWebIntegrationResult: { domain: "", instances: [] },
    getWebIntegrationsResult: [],
    launcherImages: [],
    integratedToPage: false,
    vehicleConditionFilter: feed === "ladtech" ? "New" : "All",
    flaggedVins: [],
    missingOfferTypes: [],
    triggerAssetInstance: false,
    executionId: "",
    isExportCompleted: false,
    exportedAssets: [],
    exportAssetsUploaded: 0,
    exportDownloadInProgress: false,
    pdfJobStatus: "pending",
    imageExports: [],
    openOfferOverwriteModal: false,
    orders: {
      creators: [],
    },
  };
};

const assetBuilderSlice = createSlice({
  name: "assetBuilder",
  initialState: getInitialState(),
  reducers: {
    filterOffersByVehicleCondition(
      state,
      { payload: vehicleCondition }: PayloadAction<VehicleConditions>,
    ) {
      return {
        ...state,
        vehicleConditionFilter: vehicleCondition,
      };
    },
    getWebIntegrationsBegin(state) {
      return state;
    },
    getWebIntegrationsFail(state) {
      return {
        ...state,
        getWebIntegrationsResult: [],
        errorMessage: "Failed to get Integrations",
      };
    },
    getWebIntegrationsSuccess(
      state,
      { payload: getWebIntegrationsResult }: PayloadAction<IWebIntegrations[]>,
    ) {
      return {
        ...state,
        getWebIntegrationsResult: getWebIntegrationsResult,
        generalMessage: getWebIntegrationsResult.length
          ? ""
          : "There are no Integrations available to view",
      };
    },
    createWebIntegrationBegin(state) {
      return state;
    },
    createWebIntegrationFail(state) {
      return {
        ...state,
        errorMessage: "Failed to modify integration",
      };
    },
    createWebIntegrationSuccess(
      state,
      { payload: webIntResult }: PayloadAction<IWebIntegrationInstanceObject>,
    ) {
      return {
        ...state,
        createWebIntegrationResult: webIntResult,
      };
    },
    deleteWebIntegrationBegin(state) {
      return state;
    },
    deleteWebIntegrationFail(state) {
      return {
        ...state,
        errorMessage: "Failed to delete integration",
      };
    },
    deleteWebIntegrationSuccess(
      state,
      { payload: deletedIntResult }: PayloadAction<IDeleteWebIntegrationResult>,
    ) {
      return {
        ...state,
        createWebIntegrationResult: deletedIntResult.deletedIntegration,
      };
    },
    getWebIntegrationStatusBegin(state) {
      return state;
    },
    getWebIntegrationStatusFail(state) {
      return {
        ...state,
        integratedToPage: false,
      };
    },
    getWebIntegrationStatusSuccess(
      state,
      { payload }: PayloadAction<IGetWebIntegrationStatusResponse>,
    ) {
      const { result: getWebIntegrationStatusResult } = payload;
      const { isIntegrated } = getWebIntegrationStatusResult!;
      return {
        ...state,
        integratedToPage: isIntegrated,
      };
    },
    saveSelectTemplateSearch(
      state,
      { payload: searchBy }: PayloadAction<string>,
    ) {
      return {
        ...state,
        savedSelectTemplateSearch: searchBy,
      };
    },
    saveSelectImageSearch(
      state,
      { payload: searchByImageName }: PayloadAction<string>,
    ) {
      return {
        ...state,
        savedSelectImageSearch: searchByImageName,
      };
    },
    setAssetInstanceComparator(
      state,
      { payload: assetInstances }: PayloadAction<AssetInstanceRecord>,
    ) {
      return {
        ...state,
        assetInstanceComparator: assetInstances,
      };
    },
    setOfferDataComparator(
      state,
      { payload: offerData }: PayloadAction<OfferData>,
    ) {
      return {
        ...state,
        offerDataComparator: offerData,
      };
    },
    setSaveDialog(
      state,
      { payload }: PayloadAction<{ input: boolean; newPath?: string }>,
    ) {
      const { input, newPath } = payload;
      return {
        ...state,
        saveDialog: input,
        path: newPath || "",
        assetInstances:
          !input && !isEmpty(state.assetInstanceComparator)
            ? state.assetInstanceComparator
            : state.assetInstances,
        offerData:
          !input && !isEmpty(state.offerDataComparator)
            ? state.offerDataComparator
            : state.offerData,
      };
    },
    filterBySortBy(
      state,
      { payload: sortByFilter }: PayloadAction<ISortingOption[]>,
    ) {
      return {
        ...state,
        sortByFilter,
        offerList: state.offerList,
      };
    },
    fetchSelectedOfferListBegin(state) {
      return {
        ...state,
        fetchingSelectedOfferList: true,
      };
    },
    fetchSelectedOfferListFail(state) {
      return state;
    },
    fetchOfferListBegin(state) {
      return {
        ...state,
        fetchingOfferList: true,
      };
    },
    fetchOfferListFail(state) {
      return state;
    },
    fetchOfferListSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        total: number;
        offerList: any;
        currentPage: number;
        searchInput: string;
        sortByFilter: ISortingOption[];
        checkedOfferFilters: ICheckedOfferFilter;
        vehicleConditionFilter: string;
      }>,
    ) {
      const {
        total,
        offerList,
        currentPage,
        searchInput,
        sortByFilter: newsortByFilter,
      } = payload;
      // this fixes the issue where the offerList will be duplicated because of the code that was for pagination
      let offerArr = [
        ...state.offerList,
        ...(offerList as IOfferItem[]),
      ].reduce((arr, offer) => {
        const exists = arr.find(x => x.row.vin === offer.row.vin);
        if (!exists) {
          arr.push(offer);
        }
        return arr;
      }, [] as IOfferItem[]);

      if ((searchInput || newsortByFilter) && currentPage === 1) {
        offerArr = offerList;
      }

      return {
        ...state,
        fetchingOfferList: false,
        isDataEmpty: total === 0 && state.selectedOffers.length === 0,
        currentPage,
        total,
        offerList: offerArr,
        searchQuery: searchInput,
      };
    },
    toggleOverwriteOfferModal(state, { payload }: PayloadAction<boolean>) {
      return {
        ...state,
        openOfferOverwriteModal: payload,
      };
    },
    updateSelectedTab(state, { payload }: PayloadAction<FeedTab>) {
      return {
        ...state,
        currentTab: payload,
      };
    },
    editOffer<T extends SingletonOfferKeys | RepeatableOfferKeys>(
      state: IAssetBuilderState,
      {
        payload,
      }: PayloadAction<{
        key: T | string;
        value: RawOfferData[T];
        fieldIdentifier?: EditedFieldId;
      }>,
    ) {
      const rawData = state.rawOfferData || blankOfferData();

      const { key, value, fieldIdentifier } = payload as
        | {
            key: SingletonOfferKeys;
            value: string;
            fieldIdentifier?: EditedFieldId;
          }
        | {
            key: RepeatableOfferKeys;
            value: string[];
            fieldIdentifier?: EditedFieldId;
          };

      const isValueEdited =
        (state.currentRawOffer?.row || rawData)[
          key as keyof (OfferFromService | RawOfferData)
        ] !== value;

      const newRawData = {
        ...rawData,
        [key]: value,
      } as RawOfferData;

      return {
        ...state,
        rawOfferData: newRawData,
        // AV2-1878: editeKeys initialize from a service, so we need a separate state tracker
        editedKeys: {
          ...state.editedKeys,
          [key]: isValueEdited,
        },
        offerEditTrackerObject: {
          ...state.offerEditTrackerObject,
          [key]: [
            ...(state?.offerEditTrackerObject?.[key] || []),
            fieldIdentifier || true,
          ].filter(dedupe),
        },
        offerValidationErrors: addValidationError(
          newRawData,
          key,
          state.offerValidationErrors,
        ),
        offerData: generateOfferData(newRawData),
      };
    },
    saveOfferBegin(state) {
      return {
        ...state,
        processingExport: true,
      };
    },
    saveOfferFail(state, { payload: error }: PayloadAction<GenericError>) {
      const { message } = error || {
        message: "Failed To Save Offer Edits",
      };
      return {
        ...state,
        errorMessage: message,
        processingExport: false,
        openOfferOverwriteModal: false,
      };
    },
    setEditOfferData(
      state,
      {
        payload,
      }: PayloadAction<{
        rawOfferData: RawOfferData;
        editedKeys: Record<string, boolean>;
        operation?: OfferOperationMode;
      }>,
    ) {
      const { rawOfferData, editedKeys, operation: mode } = payload;

      return {
        ...state,
        rawOfferData,
        offerValidationErrors: generateValidationErrors(rawOfferData),
        editedKeys: editedKeys || {},
        offerData: generateOfferData(rawOfferData),
        offerOperationMode: mode!,
        offerEditTrackerObject: null,
        getRawOfferByVinError: null,
      };
    },
    toggleOffer(
      state,
      {
        payload,
      }: PayloadAction<{ offerType: OfferType; offerData: OfferData }>,
    ) {
      const {
        offerType,
        offerData,
      }: {
        offerType: OfferType;
        offerData: OfferData;
      } = payload;

      const vehicle = state.rawSelectedOffers[offerData.vin] || {
        offerData,
        offerIndex: {},
      };

      const selections = vehicle.offerIndex;

      const rawSelectedOffers = {
        ...state.rawSelectedOffers,
        [offerData.vin]: {
          ...vehicle,
          offerIndex: {
            ...defaultOfferIndex,
            ...selections,
            [offerType]: !selections[offerType],
          },
        },
      };

      let offerDataArray: OfferData[] = [];

      if (state.selectedOffers.length !== 0) {
        offerDataArray = state.selectedOffers.map(offer => offer.offerData);
      } else if (state.offerList.length !== 0) {
        offerDataArray = state.offerList.map(offer => offer.row);
      }

      return {
        ...state,
        rawSelectedOffers,
        selectedOffers: processRawSelectedOffers(
          rawSelectedOffers,
          offerDataArray,
        ),
      };
    },
    updateAssetInstances(
      state,
      {
        payload,
      }: PayloadAction<{
        assetType: string;
        size: string;
        instances: IAssetInstance[] | null;
      }>,
    ) {
      const { size, instances, assetType } = payload;

      if (instances?.length) {
        return {
          ...state,
          assetInstances: {
            ...state.assetInstances,
            [assetType]: {
              ...(state.assetInstances[assetType] || {}),
              [size]: instances.map(pruneInstanceOffers),
            },
          },
        };
      } else {
        return {
          ...state,
          assetInstances: {
            ...state.assetInstances,
            [assetType]: {
              ...omit(state.assetInstances[assetType] || {}, size),
            },
          },
        };
      }
    },
    updateCheckedOfferFilters(
      state,
      {
        payload: checkedOfferFilters,
      }: PayloadAction<{
        title: string;
      }>,
    ) {
      return {
        ...state,
        checkedOfferFilters: {
          ...state.checkedOfferFilters,
          [checkedOfferFilters.title]:
            !state.checkedOfferFilters[
              checkedOfferFilters.title as checkedOfferFilterKeys
            ],
        },
      };
    },
    switchOfferEditMode(state, { payload: editMode }: PayloadAction<boolean>) {
      return {
        ...state,
        editMode,
      };
    },
    resetOfferData(state) {
      return {
        ...state,
        rawOfferData: blankOfferData(),
        offerData: blankOfferData(),
      };
    },
    updateAssetTypeCount(
      state,
      { payload: assetTypeCount }: PayloadAction<TAssetTypeCount>,
    ) {
      return {
        ...state,
        assetTypeCount,
      };
    },
    updateExportedAssetsArr(
      state,
      {
        payload: assetNames,
      }: PayloadAction<Record<string, Record<string, string[]>>>,
    ) {
      return {
        ...state,
        selectedExportedAssets: assetNames,
      };
    },
    assetInstanceCounter(state) {
      // const { assetInstanceCounter, assetInstances: assetInstanceVals } = state;
      const { assetInstanceCounter } = state;
      /*
        temporarily commenting out following logic. [AV2-3291]
         will be resolved on next ticket
      */
      // let counter = 0;
      // for (const property in assetInstanceVals) {
      //   if (assetInstanceVals.hasOwnProperty(property)) {
      //     for (const innerProperty in assetInstanceVals[property]) {
      //       if (assetInstanceVals[property].hasOwnProperty(innerProperty)) {
      //         for (const assetSize of assetInstanceVals[property][
      //           innerProperty
      //         ]) {
      //           if (
      //             !assetSize.offers ||
      //             !assetSize.template ||
      //             !assetSize.visibilities
      //           ) {
      //             break;
      //           }
      //           counter++;
      //         }
      //       }
      //     }
      //   }
      // }
      // let disableButton = true;
      // if (assetInstanceCounter + 1 >= counter) {
      //   disableButton = false;
      // }
      const disableButton = false;

      const assetCounter = assetInstanceCounter + 1;
      return {
        ...state,
        assetInstanceCounter: assetCounter,
        disableExport: disableButton,
      };
    },
    setOfferWithDisclosure(
      state,
      {
        payload: setOfferWithDisclosure,
      }: PayloadAction<{
        selectedOffers: string;
        offerData: OfferData;
        finalDisclosure: string;
      }>,
    ) {
      const { offersWithDisclosures, savedOrder: savedStateOrder } = state;
      const { selectedOffers: savedSelectedOffers } = savedStateOrder || {};
      const combinedOfferArr: IOfferWithDisclosure[] = [
        ...offersWithDisclosures,
      ];
      // need to only add in unique values because if the canvas rerenders, duplicate values are added
      let duplicateOffer = false;
      combinedOfferArr.forEach(offer => {
        if (
          offer.offerData.vin === setOfferWithDisclosure.offerData.vin &&
          offer.finalDisclosure === setOfferWithDisclosure.finalDisclosure
        ) {
          duplicateOffer = true;
        }
      });
      if (!duplicateOffer) {
        combinedOfferArr.push(setOfferWithDisclosure);
      }

      // tries to match the master offer vin with the saved order offer's vin for updates
      // fallback is using the data from the master offer
      const savedOffersWithDisclosures: IOfferWithDisclosure[] =
        combinedOfferArr.map(offer => {
          let savedOffer = offer;
          if (savedSelectedOffers) {
            for (const offWDis of savedSelectedOffers) {
              if (offWDis.offerData.vin === offer.offerData.vin) {
                savedOffer = {
                  selectedOffers: `"${offWDis.offers
                    .join(", ")
                    .replace(/"/g, '\\"')}"`,
                  offerData: offWDis.offerData,
                  finalDisclosure: offer.finalDisclosure,
                };
                break;
              }
            }
          }
          return savedOffer;
        });
      return {
        ...state,
        offersWithDisclosures: [...savedOffersWithDisclosures],
      };
    },
    resetAssetInstanceCounter(state) {
      return {
        ...state,
        assetInstanceCounter: 0,
        disableExport: true,
        canvasLoadingOrder: [],
        offersWithDisclosures: [],
        selectedExportedAssets: {},
        processedPaymentEngineData: null,
        rawPaymentEngineData: null,
        generalMessage: "",
        errorMessage: null,
        assetTypeCount: {},
      };
    },
    generateImagesWithVideoBegin(state) {
      return {
        ...state,
        processingImageExport: true,
      };
    },
    generateImagesWithVideoEnd(state) {
      return {
        ...state,
        processingImageExport: false,
      };
    },
    generateCanvasZipUrlBegin(state) {
      return {
        ...state,
        processingExport: true,
        generalMessage: "Exporting and compressing assets ...",
        isExportCompleted: false,
      };
    },
    generateCanvasZipUrlFail(
      state,
      { payload: error }: PayloadAction<GenericError>,
    ) {
      return {
        ...state,
        errorMessage: `Failed to retrieve zip url ${error?.message}`,
        processingExport: false,
        isExportCompleted: false,
      };
    },
    generateCanvasZipUrlSuccess(
      state,
      { payload: canvasZipResult }: PayloadAction<IGetCanvasZipUrlResponse>,
    ) {
      const { result: canvasZipInnerResult } = canvasZipResult;
      const { s3Url } = canvasZipInnerResult!;
      return {
        ...state,
        s3Url,
        processingExport: false,
        generalMessage: "Asset download completed.",
      };
    },
    updateExportedAssets(
      state,
      { payload }: PayloadAction<IExportImageOrVideoResult[]>,
    ) {
      const exportedAssets = [...(state.exportedAssets || [])];
      exportedAssets.push(...payload);
      return {
        ...state,
        exportedAssets,
        isExportCompleted: false,
      };
    },
    generatePDFBegin(state) {
      return {
        ...state,
        pdfUrl: "",
        processingExport: true,
        generalMessage: "Compressing assets and exporting PDF ...",
      };
    },
    generatePDFFail(state, { payload: error }: PayloadAction<GenericError>) {
      return {
        ...state,
        errorMessage: `Failed to retrieve PDF url: ${error?.message}`,
        processingExport: false,
      };
    },
    generatePDFSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        response: IGeneratePDFResponse;
        executionId?: string;
      }>,
    ) {
      const { response: generatePDFResult, executionId } = payload;
      const { result: generatePDFInnerResult } = generatePDFResult;
      const { pdfRes } = generatePDFInnerResult!;
      const pdfUrl = pdfRes?.pdfUrl;

      return {
        ...state,
        pdfUrl,
        processingExport: false,
        generalMessage:
          enablePDFInvoker === "false" ? "PDF generation completed." : "",
        executionId,
      };
    },
    generatePDFInvokerFail(state) {
      return {
        ...state,
        pdfJobStatus: "failure",
      };
    },
    feedDataToPDFBegin(state) {
      return {
        ...state,
        feedPDFUrl: "",
        processingExport: true,
        generalMessage: "Compressing assets and exporting PDF ...",
      };
    },
    feedDataToPDFFail(state, { payload: error }: PayloadAction<GenericError>) {
      return {
        ...state,
        errorMessage: `Failed to retrieve PDF url: ${error?.message}`,
        processingExport: false,
      };
    },
    feedDataToPDFSuccess(
      state,
      { payload: feedDataToPDFResult }: PayloadAction<IGeneratePDFResponse>,
    ) {
      const { result: feedDataToPDFInnerResult } = feedDataToPDFResult;
      const { pdfRes: feedPDFRes } = feedDataToPDFInnerResult!;
      const { pdfUrl: feedPDFUrl } = feedPDFRes;
      return {
        ...state,
        feedPDFUrl: feedPDFUrl,
        processingExport: false,
        generalMessage: "PDF generation completed.",
      };
    },
    coopSubmissionBegin(state) {
      return {
        ...state,
        processingExport: true,
        errorMessage: "",
        generalMessage: "Compressing assets and exporting PDF for coop ...",
      };
    },
    coopSubmissionFail(state, { payload: error }: PayloadAction<GenericError>) {
      return {
        ...state,
        errorMessage: `Failed to submit to coop: ${error?.message}`,
        processingExport: false,
      };
    },
    coopSubmissionSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        response: ICoopSubmissionResponse;
        executionId?: string;
      }>,
    ) {
      return {
        ...state,
        processingExport: false,
        generalMessage:
          "PDF is being processed for coop. Please wait for a confirmation in Alexia.",
        executionId: payload?.executionId || "",
      };
    },
    generateImagesForLauncherPageSuccess(
      state,
      { payload: generateImagesLauncherResult }: PayloadAction<string[]>,
    ) {
      const images: string[] = [];
      for (const image of generateImagesLauncherResult) {
        images.push(
          `https://${process.env.REACT_APP_S3_UPLOAD_ASSETS}.${
            process.env.REACT_APP_ALEXIA_DOMAIN || "constellationagency.com"
          }/order-export/${image}`,
        );
      }

      return {
        ...state,
        launcherImages: images,
      };
    },
    feedDataToCSVBegin(state) {
      return {
        ...state,
        processingExport: true,
        generalMessage: "Exporting offerData to csv",
      };
    },
    feedDataToCSVFail(state, { payload: error }: PayloadAction<GenericError>) {
      return {
        ...state,
        errorMessage: `Failed to retrieve csv url ${error}`,
        processingExport: false,
      };
    },
    feedDataToCSVSuccess(
      state,
      { payload: offerCSVResponse }: PayloadAction<IFeedDataToCSVResponse>,
    ) {
      const { result: offerCSVInnerResult } = offerCSVResponse;
      // const { csvS3Url } = offerCSVInnerResult; // Line modified in refactoring, s3Url should be the correct field
      const { s3Url } = offerCSVInnerResult!;
      return {
        ...state,
        s3Url, // maybe add another url for the csv
        processingExport: false,
        generalMessage: "CSV download complete.",
      };
    },
    uploadCanvasImageBegin(state) {
      return {
        ...state,
        processingExport: true,
        generalMessage: "Converting canvas to image ...",
      };
    },
    uploadCanvasImageFail(
      state,
      { payload: error }: PayloadAction<GenericError>,
    ) {
      return {
        ...state,
        errorMessage: `Failed to upload canvas ${error}`,
        processingExport: false,
      };
    },
    uploadCanvasImageSuccess(
      state,
      { payload: canvasImageResult }: PayloadAction<IUploadCanvasImageResponse>,
    ) {
      const { result: canvasImageInnerResult } = canvasImageResult;
      const { canvasID } = canvasImageInnerResult!;
      const { canvasIDArr } = state;
      return {
        ...state,
        canvasIDArr: [...canvasIDArr, canvasID],
        processingExport: false,
        generalMessage: "Canvas successfully converted.",
      };
    },
    uploadJellybeanUpdate(
      state,
      {
        payload: jellyBeanResult,
      }: PayloadAction<{
        value: boolean;
        img: string;
      }>,
    ) {
      const { value: jellyBeanBool, img } = jellyBeanResult;
      return {
        ...state,
        useUploadedJellyBean: jellyBeanBool,
        uploadedJellybean: img,
      };
    },
    uploadAssetToWFBegin(state) {
      return {
        ...state,
        processingExport: true,
        generalMessage: "Uploading assets to Workfront ...",
      };
    },
    uploadAssetToWFFail(
      state,
      { payload: error }: PayloadAction<GenericError>,
    ) {
      return {
        ...state,
        errorMessage: `One or more assets failed to upload to workfront ${error}`,
        processingExport: false,
      };
    },
    uploadAssetToWFSuccess(
      state,
      // eslint-disable-next-line
      { payload }: PayloadAction<IUploadAssetToWFResult>,
    ) {
      return {
        ...state,
        processingExport: false,
        generalMessage: "Assets successfully uploaded.",
      };
    },
    proofExportDataBegin(state) {
      return state;
    },
    proofExportDataFail(
      state,
      // eslint-disable-next-line
      { payload: error }: PayloadAction<GenericError>,
    ) {
      return {
        ...state,
        wfProofArr: [],
      };
    },
    proofExportDataSuccess(
      state,
      { payload }: PayloadAction<IProofExportDataResponse>,
    ) {
      return {
        ...state,
        wfProofArr: payload.result?.orderData,
      };
    },
    pushToProofBegin(state) {
      return {
        ...state,
        processingExport: true,
        generalMessage: "Uploading assets to Workfront ...",
      };
    },
    pushToProofFail(state, { payload: error }: PayloadAction<GenericError>) {
      return {
        ...state,
        errorMessage: `One or more assets failed to upload to workfront ${error?.message}`,
        processingExport: false,
      };
    },
    pushToProofSuccess(state) {
      return {
        ...state,
        processingExport: false,
        generalMessage:
          "Request Was Sent To Workfront. Please Check In A Few Minutes",
      };
    },
    getProofTemplatesBegin(state) {
      return {
        ...state,
        processingExport: true,
      };
    },
    getProofTemplatesFail(
      state,
      { payload: error }: PayloadAction<GenericError>,
    ) {
      return {
        ...state,
        processingExport: false,
        errorMessage: `Failed to retrieve templates from workfront - ${
          error ? error : "Some error occurred with the proof api."
        }`,
      };
    },
    getProofTemplatesSuccess(
      state,
      { payload: templateRes }: PayloadAction<IGetWFTemplatesResponse>,
    ) {
      const { result: innerTemplateRes } = templateRes;
      const { templateList } = innerTemplateRes!;

      return {
        ...state,
        processingExport: false,
        proofHQTemplates: templateList,
      };
    },
    getPaymentEngineDataBegin(state) {
      return {
        ...state,
        getPaymentEngineDataError: null,
      };
    },
    getPaymentEngineDataFail(
      state,
      { payload: dsError }: PayloadAction<GenericError>,
    ) {
      return {
        ...state,
        processedPaymentEngineData: null,
        rawPaymentEngineData: null,
        getPaymentEngineDataError: dsError,
      };
    },
    getPaymentEngineDataSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        processedDSData: ProcessedPaymentEngineData;
        rawDSData: GetPaymentEngineDealDataResult;
      }>,
    ) {
      const { processedDSData, rawDSData } = payload;

      return {
        ...state,
        getPaymentEngineDataError: null,
        processedPaymentEngineData: processedDSData,
        rawPaymentEngineData: rawDSData,
      };
    },
    getJellybeanImagesBegin(state) {
      return {
        ...state,
        gettingJellybeanImages: true,
        getJellybeanImagesError: null,
      };
    },
    getJellybeanImagesFail(
      state,
      { payload: error }: PayloadAction<GenericError>,
    ) {
      return {
        ...state,
        errorMessage: `Failed to fetch jellybean images ${error?.message}`,
        gettingJellybeanImages: false,
      };
    },
    getJellybeanImagesSuccess(state, { payload: result }: PayloadAction<any>) {
      return {
        ...state,
        gettingJellybeanImages: false,
        jellybeanImages: result,
      };
    },
    getWorkfrontFoldersBegin(state) {
      return {
        ...state,
        processingExport: true,
      };
    },
    getWorkfrontFoldersFail(
      state,
      // eslint-disable-next-line
      { payload: error }: PayloadAction<GenericError>,
    ) {
      return {
        ...state,
        processingExport: false,
        errorMessage: `Failed to retrieve folders from workfront`,
      };
    },
    getWorkfrontFoldersSuccess(
      state,
      { payload }: PayloadAction<IGetWFFoldersResponse>,
    ) {
      const { result: folderRes } = payload;
      const { res } = folderRes!;

      return {
        ...state,
        processingExport: false,
        wfFolderList: res,
      };
    },
    updateSelectedTemplates(
      state,
      {
        payload: newSelectedTemplates,
      }: PayloadAction<Record<string, Record<string, boolean>>>,
    ) {
      return {
        ...state,
        selectedTemplateSizes: newSelectedTemplates,
      };
    },
    removeFilterTemplate(
      state,
      {
        payload: removeFilterResult,
      }: PayloadAction<{
        selectedTab: string;
        filterOption: string;
      }>,
    ) {
      const { selectedTab, filterOption } = removeFilterResult;
      const { assetInstances } = state;
      const updatedAssetInstances = assetInstances;
      if (
        assetInstances[selectedTab] &&
        assetInstances[selectedTab][filterOption]
      ) {
        delete updatedAssetInstances[selectedTab][filterOption];
      }
      if (Object.keys(updatedAssetInstances[selectedTab]).length === 0) {
        delete updatedAssetInstances[selectedTab];
      }

      return {
        ...state,
        assetInstances: updatedAssetInstances,
      };
    },
    fetchSelectedOfferListSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        formattedSelectedOfferList: {
          row: OfferData;
        }[];
        flaggedVins: string[];
      }>,
    ) {
      const { formattedSelectedOfferList, flaggedVins } = payload;

      const updatedSelOfferList = formattedSelectedOfferList.map(formOffer => {
        const data = state.selectedOffers.find(
          selOffer => selOffer.offerData.vin === formOffer.row.vin,
        );

        return data
          ? {
              ...formOffer,
              row: data.offerData,
            }
          : formOffer;
      });

      return {
        ...state,
        fetchingSelectedOfferList: false,
        masterSelectedOfferList: formattedSelectedOfferList,
        selectedOfferList: updatedSelOfferList,
        flaggedVins,
      };
    },
    offerEditsSaved(
      state,
      {
        payload,
      }: PayloadAction<{
        revertingOffer?: boolean | undefined;
        newSelectedOffers?: ISelectedOffer[];
        result: {
          createdOffer: RawOfferData;
        };
        newEditedKeys: Record<string, boolean>;
      }>,
    ) {
      const { newSelectedOffers, revertingOffer } = payload;
      const newOfferList = [...state.offerList];
      if (!newOfferList[0]) {
        newOfferList[0] = {
          row: payload.result.createdOffer,
          updated: Date.now(),
          editedKeys: payload.newEditedKeys || {},
        };
      } else {
        newOfferList.unshift({
          row: payload.result.createdOffer,
          updated: Date.now(),
          editedKeys: payload.newEditedKeys || {},
        });

        for (let i = 1; i < newOfferList.length; i++) {
          if (newOfferList[i].row.vin === payload.result.createdOffer.vin) {
            newOfferList.splice(i, 1);
            break;
          }
        }
      }

      return {
        ...state,
        selectedOffers: newSelectedOffers?.length
          ? newSelectedOffers
          : state.selectedOffers,
        offerList: newOfferList,
        offerEditsWereSaved: revertingOffer ? state.offerEditsWereSaved : true,
        processingExport: false,
        openOfferOverwriteModal: false,
        generalMessage:
          "Offer successfully updated. Changes can sometimes take a few seconds to show.",
      };
    },
    fetchZipUrlBegin(state) {
      return {
        ...state,
        exportDownloadInProgress: true,
      };
    },
    fetchZipUrlInProgress(state) {
      return {
        ...state,
        exportedAssets: [],
      };
    },
    fetchZipUrlSuccess(state) {
      return {
        ...state,
        exportDownloadInProgress: false,
        exportedAssets: [],
        processingExport: false,
        generalMessage: "Asset download completed.",
      };
    },
    fetchZipUrlFail(state, { payload: error }: PayloadAction<string>) {
      return {
        ...state,
        errorMessage: error,
        exportedAssets: [],
      };
    },
    fetchExportStatusBegin(
      state,
      { payload: exportedAssets }: PayloadAction<IExportedAssetStatus[]>,
    ) {
      return {
        ...state,
        exportedAssets,
        isExportCompleted: false,
      };
    },
    fetchExportStatusFail(
      state,
      { payload: { error } }: PayloadAction<{ error: string }>,
    ) {
      return {
        ...state,
        errorMessage: error,
        isExportCompleted: false,
        exportDownloadInProgress: false,
        exportedAssets: [],
        processingExport: false,
      };
    },
    fetchExportStatusSuccess(
      state,
      { payload: updateAssets }: PayloadAction<IExportedAssetStatus[]>,
    ) {
      const exportedStatus = updateAssets.map(asset => asset.status);

      const successCount = updateAssets.filter(
        asset => asset.status === "success",
      ).length;

      return {
        ...state,
        exportedAssets: updateAssets,
        exportAssetsUploaded: successCount,
        isExportCompleted: !exportedStatus.includes("pending"),
      };
    },
    setImageZipExports(
      state,
      { payload }: PayloadAction<{ imageExports: IExportImageOrVideoResult[] }>,
    ) {
      const { imageExports } = payload;
      return {
        ...state,
        imageExports,
      };
    },
    generatePdfInvoker(
      state,
      {
        payload,
      }: PayloadAction<{
        pdfName: any;
        pdfUrl: any;
      }>,
    ) {
      const { pdfUrl: url, pdfName: name } = payload;

      return {
        ...state,
        pdfUrl: url,
        pdfName: name,
        generalMessage: "PDF generation completed",
      };
    },
    // eslint-disable-next-line
    duplicateOrderStateFail(state, { payload: error }: PayloadAction<string>) {
      return state;
    },
    // eslint-disable-next-line
    duplicateOrderStateSuccess(state, { payload }: PayloadAction<any>) {
      return state;
    },
    fetchOrderStateBegin(state) {
      return state;
    },
    fetchOrderStateFail(state, { payload }: PayloadAction<{ error: string }>) {
      return {
        ...state,
        errorMessage: payload.error,
      };
    },
    fetchOrderStateSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        savedOrder: ISavedOrderState;
      }>,
    ) {
      const { savedOrder } = payload;

      // we have to set rawSelectedOffers in order to auto check checkboxes for the offers saved previously.
      // The data structure is little bit different than the selected offers we are saving.
      // TODO: match the rawSelectedOffers with the selectedOffers saved in DB.
      let savedRawSelectedOffers: RawSelectedOffers = {};

      if (savedOrder.selectedOffers) {
        savedRawSelectedOffers =
          savedOrder.selectedOffers.reduce<RawSelectedOffers>(
            (result, selectedOffer) => {
              if (!selectedOffer) return result;

              const { vin } = selectedOffer.offerData;

              const savedOfferIndex = selectedOffer.offers.reduce<
                Record<OfferType, boolean>
              >((acc, tmpOfferType) => {
                acc[tmpOfferType] = true;

                return acc;
              }, {} as Record<OfferType, boolean>);

              const offerDataWithAggrValues = aggregateRebateFieldValues({
                row: selectedOffer.offerData,
              } as any);

              result[vin] = {
                offerData: { ...offerDataWithAggrValues.row },
                offerIndex: savedOfferIndex,
              };

              return result;
            },
            {},
          );
      }

      /////// TODO: Probably, the action for SET_SAVED_ORDER has to be merged into here.
      return {
        ...state,
        rawSelectedOffers: Object.keys(state.rawSelectedOffers).length
          ? state.rawSelectedOffers
          : savedRawSelectedOffers,
        savedOrder,
        selectedOffers: processRawSelectedOffers(savedRawSelectedOffers),
        assetInstances: savedOrder.assetInstances || {},
        assetInstanceComparator: savedOrder.assetInstances || {},
      };
    },
    resetPdfUrl(state) {
      return {
        ...state,
        pdfUrl: "",
      };
    },
    disableExport(state) {
      return {
        ...state,
        disableExport: true,
      };
    },
    enableExport(state) {
      return {
        ...state,
        disableExport: false,
      };
    },
    resetAssetInstances(state) {
      return {
        ...state,
        assetInstances: {},
        selectedTemplateSizes: {},
        savedOrder: null,
        // clear the selected offers (helps resolve issue AV2-1490)
        rawSelectedOffers: {},
        selectedOffers: [],
      };
    },
    resetOfferEditsWereSaved(state) {
      return {
        ...state,
        offerEditsWereSaved: false,
      };
    },
    resetOfferStates(state) {
      return {
        ...state,
        savedOrder: null,
        rawSelectedOffers: {},
      };
    },
    commitOrderStateSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        newRawSelectedOffers: RawSelectedOffers;
      }>,
    ) {
      return {
        ...state,
        rawSelectedOffers: payload.newRawSelectedOffers,
      };
    },
    commitOrderStateClient(
      state,
      {
        payload,
      }: PayloadAction<{
        savedOrder: {
          selectedOffers: ISelectedOffer[];
          orderId: string;
          selectedOrder: INewOrder;
          assetInstances: AssetInstanceRecord | null;
          canvasData: ICanvasData[] | null;
        };
      }>,
    ) {
      const updatedSelectedOfferList = state.selectedOfferList;

      const { selectedOffers: newSelectedOffersInOrder, assetInstances } =
        payload.savedOrder as ISavedOrderState;
      const newAssetInstances = cloneDeep(assetInstances);

      for (const tab in newAssetInstances) {
        for (const size in newAssetInstances[tab]) {
          const filteredEmptyOffers = newAssetInstances[tab][size].filter(
            instance => !!instance.offers,
          );

          if (
            newAssetInstances[tab][size].length === 0 ||
            filteredEmptyOffers.length === 0
          ) {
            delete newAssetInstances[tab][size];
          } else if (filteredEmptyOffers.length) {
            newAssetInstances[tab][size] = filteredEmptyOffers;
          }
        }

        if (isEmpty(newAssetInstances[tab])) delete newAssetInstances[tab];
      }
      const { rawSelectedOffers } = state;

      const reduceOffersIntoRawOffer = (
        prevOffer: RawSelectedOffers,
        rawOffer: ISelectedOffer,
      ): RawSelectedOffers => {
        const vin = rawOffer.offerData.vin;
        return {
          ...prevOffer,
          [vin]: {
            ...rawSelectedOffers[vin],
            ...rawOffer,
          },
        };
      };

      const newRawSelectedOffers =
        newSelectedOffersInOrder?.reduce<RawSelectedOffers>(
          reduceOffersIntoRawOffer,
          {},
        ) || {};

      return {
        ...state,
        selectedOfferList: updatedSelectedOfferList,
        assetInstanceComparator: newAssetInstances || {},
        offerDataComparator: state.offerData,
        selectedOffers: newSelectedOffersInOrder || [],
        savedOrder: payload.savedOrder as ISavedOrderState,
        assetInstances: newAssetInstances || {},
        rawSelectedOffers: newRawSelectedOffers,
      };
    },
    commitOrderStateFail(state) {
      return {
        ...state,
        errorMessage: "Unable to Save Order Progress",
      };
    },
    setSavedOrder(
      state,
      {
        payload,
      }: PayloadAction<{
        savedOrder: ISavedOrderState;
        redirect?: TRedirectPath;
      }>,
    ) {
      const {
        selectedOffers,
        assetInstances: savedAssetInstances,
        // canvasData,
      } = (payload && (payload.savedOrder as ISavedOrderState)) || {
        selectedOffers: [],
        assetInstances: {},
      };

      const { redirect } = payload;

      return {
        ...state,
        selectedOffers: selectedOffers || [],
        assetInstances: savedAssetInstances || {},
        redirect,
      };
    },
    getPaymentEngineDataReset(state) {
      return {
        ...state,
        getPaymentEngineDataError: null,
        processedPaymentEngineData: null,
        rawPaymentEngineData: null,
      };
    },
    // BUILD ACTIONS
    setSelectedAssetBuildInstance(
      state,
      { payload }: PayloadAction<SelectedInstance[]>,
    ) {
      // Reset
      if (!payload.length) {
        return {
          ...state,
          buildPage: {
            instances: state.buildPage.instances,
            selectedInstance: [],
          },
        };
      }

      const instances = payload.reduce((acc, val) => {
        const instanceKey = `${val.assetType}-${val.size}-${val.order}`;

        return {
          ...acc,
          [instanceKey]: val.instance,
        };
      }, state.buildPage.instances);

      return {
        ...state,
        buildPage: {
          instances,
          selectedInstance: payload,
        },
      };
    },
    deleteSelectedAssetBuildInstance(
      state,
      { payload }: PayloadAction<SelectedInstance>,
    ) {
      const updatedBuildPage: IAssetBuilderState["buildPage"] = {
        ...state.buildPage,
      };

      // Here, we have to re-construct the buildPage.instances
      // Let's say there are 3 instances with order 0, 1, and 2.
      // If the instance with order 1 got deleted, the buildPage.instances has to have instances with order 0 and 1 ONLY.
      const updatedInstances = Object.keys(updatedBuildPage.instances).reduce(
        (acc, key) => {
          const inst = updatedBuildPage.instances[key];
          const [assetType, size, currentOrderInString] = key.split("-");

          // check if the order in string is proper number
          const isSameAssetType = assetType === payload!.assetType;
          const isSameSize = size === payload!.size;
          const isOrderNotValidNumber = /^\d+$/.test(currentOrderInString);

          if (!isSameAssetType || !isSameSize || !isOrderNotValidNumber)
            return {
              ...acc,
              [key]: inst,
            };

          const currentOrder = parseInt(currentOrderInString);

          if (currentOrder === payload!.order)
            return {
              ...acc,
            }; // deleting the instance with payload.order

          // if the current instance with key has order higher than the instance to delete,
          //  re-assign the order with the order subtracted by 1.
          const updatedInstanceKey = `${assetType}-${size}-${currentOrder - 1}`;
          if (currentOrder > payload!.order) {
            return {
              ...acc,
              [updatedInstanceKey]: inst,
            };
          } else {
            return {
              ...acc,
              [key]: inst,
            };
          }
        },
        {} as { [instanceKey: string]: IAssetBuildInstance },
      );

      updatedBuildPage.instances = updatedInstances;

      return {
        ...state,
        buildPage: updatedBuildPage,
      };
    },
    setBuildPage(state, { payload }: PayloadAction<TSetBuildPage>) {
      const { assetBuild } = payload || { assetBuild: {} };
      const { instances: buildInstances } = assetBuild || { instances: {} };

      let ret: { [instanceKey: string]: IAssetBuildInstance } = {};
      for (const assetType in buildInstances) {
        for (const size in buildInstances[assetType]) {
          const instanceArray = buildInstances[assetType][
            size
          ] as IAssetBuildInstance[];

          const flattenedInstances = instanceArray.reduce(
            (acc, assetBuildInstance, index) => {
              const instanceKey = `${assetType}-${size}-${index}`;

              acc[instanceKey] = assetBuildInstance;

              return acc;
            },
            {} as Record<string, IAssetBuildInstance>,
          );

          ret = {
            ...ret,
            ...flattenedInstances,
          };
        }
      }

      return {
        ...state,
        buildPage: {
          ...state.buildPage,
          instances: ret,
        },
      };
    },
    insertLifestyleImage(
      state,
      {
        payload,
      }: PayloadAction<{
        imageUrl: string;
        imageName: string;
      }>,
    ) {
      const { imageUrl, imageName } = payload;
      const selectedInstances = state.buildPage.selectedInstance;
      const selectedIds = selectedInstances.map(i => i.instance.id);
      const updatedInstances = selectedInstances.map(selectedInstance => {
        return {
          ...selectedInstance,
          instance: {
            ...selectedInstance.instance,
            lifestyleImageUrl: imageUrl,
            lifestyleImageName: imageName,
            lifestyleFabricImageJson: undefined,
          },
        };
      });

      const instances = updatedInstances.reduce((acc, val) => {
        const instanceKey = `${val.assetType}-${val.size}-${val.order}`;

        return {
          ...acc,
          [instanceKey]: val.instance,
        };
      }, state.buildPage.instances);

      const assetInstances = Object.fromEntries(
        Object.entries(state.assetInstances).map(([assetType, sizes]) => {
          const updatedSizes = Object.fromEntries(
            Object.entries(sizes).map(([size, instances]) => {
              const updatedInstances = instances.map(instance => {
                if (selectedIds.includes(instance.id)) {
                  return {
                    ...instance,
                    lifestyleImageUrl: imageUrl,
                    lifestyleImageName: imageName,
                    lifestyleFabricImageJson: undefined,
                  };
                }

                return instance;
              });

              return [size, updatedInstances];
            }),
          );

          return [assetType, updatedSizes];
        }),
      );

      return {
        ...state,
        buildPage: {
          ...state.buildPage,
          instances,
        },
        assetInstances,
      };
    },
    triggerAssetInstanceUpdate(state) {
      return {
        ...state,
        triggerAssetInstance: true,
      };
    },
    gatherMissingOfferTypes(
      state,
      { payload: offerTypes }: PayloadAction<string[]>,
    ) {
      return {
        ...state,
        missingOfferTypes: offerTypes,
      };
    },
    setVisibilitiesOfInstance(
      state,
      {
        payload,
      }: PayloadAction<{
        visibilities: TVisibility[];
        assetType: string;
        size: string;
        order: number;
      }>,
    ) {
      return {
        ...state,
        assetInstances: {
          ...state.assetInstances,
          [payload.assetType]: {
            ...state.assetInstances[payload.assetType],
            [payload.size]: state.assetInstances[payload.assetType][
              payload.size
            ].map((assetInstance, idx) => {
              if (idx === payload.order) {
                return {
                  ...assetInstance,
                  visibilities: payload.visibilities,
                };
              }

              return assetInstance;
            }),
          },
        },
      };
    },
    // SELECT ACTIONS
    getRawOfferByVinBegin(state) {
      return {
        ...state,
        gettingRawOfferByVin: true,
        getRawOfferByVinError: null,
      };
    },
    getRawOfferByVinFail(
      state,
      { payload: error }: PayloadAction<GenericError>,
    ) {
      return {
        ...state,
        gettingRawOfferByVin: false,
        getRawOfferByVinError: error,
      };
    },
    getRawOfferByVinSuccess(
      state,
      { payload: getRawOfferRes }: PayloadAction<IGetRawOfferByVinOfferResult>,
    ) {
      if (!getRawOfferRes) {
        return {
          ...state,
          gettingRawOfferByVin: false,
        };
      }
      const { rawOffer, offerEdit } = getRawOfferRes;

      return {
        ...state,
        gettingRawOfferByVin: false,
        currentRawOffer: rawOffer,
        currentOfferEdit: offerEdit,
        getRawOfferByVinError: null,
      };
    },
    resetToMasterDataAction(
      state,
      {
        payload,
      }: PayloadAction<{
        newOfferData: OfferData;
      }>,
    ) {
      const { newOfferData } = payload;
      return {
        ...state,
        offerData: {
          ...newOfferData,
          vin: state.offerData?.vin || "",
        },
      };
    },
    updateRawSelectedOfferAction(state, { payload }: PayloadAction<any>) {
      return {
        ...state,
        rawSelectedOffers: payload,
      };
    },
    setCreatorsAction(state, { payload }: PayloadAction<string[]>) {
      return {
        ...state,
        orders: {
          creators: orderBy(uniq([...state.orders.creators, ...payload])),
        },
      };
    },
  },
});

export const setCreators =
  (creators: string[]): AppThunk =>
  async dispatch => {
    dispatch(setCreatorsAction(creators));
  };

export const getWebIntegrations =
  (domain: string): AppThunk =>
  async dispatch => {
    dispatch(getWebIntegrationsBegin());

    try {
      const response = await API.privServices.assetBuilder.getWebIntegrations<
        IWebIntegrations[]
      >(domain);

      if (isErrorWithMessage(response)) {
        dispatch(getWebIntegrationsFail());
        return;
      }
      dispatch(getWebIntegrationsSuccess(response));
    } catch (error) {
      dispatch(createWebIntegrationFail());
    }
  };

export const createWebIntegration =
  (webIntegrationData: ILauncherData): AppThunk =>
  async dispatch => {
    dispatch(createWebIntegrationBegin());

    try {
      const response =
        await API.privServices.assetBuilder.createWebIntegration<IWebIntegrationInstanceObject>(
          webIntegrationData,
        );

      if (isErrorWithMessage(response)) {
        dispatch(createWebIntegrationFail());
        return;
      }

      dispatch(createWebIntegrationSuccess(response));
    } catch (_) {
      dispatch(createWebIntegrationFail());
    }
  };

export const deleteWebIntegration =
  (webIntegrationData: ILauncherData) => async (dispatch: Dispatch) => {
    dispatch(deleteWebIntegrationBegin());

    try {
      const response =
        await API.privServices.assetBuilder.deleteWebIntegration<IDeleteWebIntegrationResult>(
          webIntegrationData,
        );

      if (isErrorWithMessage(response)) {
        dispatch(deleteWebIntegrationFail());
        return;
      }

      dispatch(deleteWebIntegrationSuccess(response));
    } catch (error) {
      dispatch(deleteWebIntegrationFail());
    }
  };

export const getWebIntegrationStatus =
  (domain: string, widgetURL: string): AppThunk =>
  async dispatch => {
    dispatch(getWebIntegrationStatusBegin());

    try {
      const response =
        await API.privServices.assetBuilder.getWebIntegrationStatus<IGetWebIntegrationStatusResponse>(
          domain,
          widgetURL,
        );

      const { error } = response;

      if (error) {
        dispatch(getWebIntegrationStatusFail());
        return;
      }
      dispatch(getWebIntegrationStatusSuccess(response));
    } catch (_) {
      dispatch(getWebIntegrationStatusFail());
    }
  };

export const fetchSelectedOfferList =
  (selectedOfferVins: string[]): AppThunk =>
  async (dispatch, getState) => {
    dispatch(fetchSelectedOfferListBegin());

    const { assetBuilder } = getState();
    const { result, error } =
      await API.services.assetBuilder.fetchSelectedOfferList(selectedOfferVins);
    if (error || !result) {
      dispatch(fetchSelectedOfferListFail());
      return;
    }

    const { selectedOfferList, flaggedVins } = result;

    let defaultFinalPriceName = "";

    const { savedOrder } = assetBuilder as IAssetBuilderState;

    const newOfferList = selectedOfferList.map(offerItem =>
      offerHelpers.aggregateRebateFieldValues(offerItem),
    );

    const formattedSelectedOfferList = (
      newOfferList as IProcessedOfferData[]
    ).map(offer =>
      offerHelpers.setCalculatedFieldsInOfferData(
        offer as unknown as IProcessedOfferData,
      ),
    );

    if (savedOrder && savedOrder.selectedOrder.dealer_name?.trim() !== "") {
      const { result: dealerResult } = await API.privServices.dealerManagement
        .getDealer(savedOrder.selectedOrder.dealer_name)
        .catch(() => ({ result: null }));
      defaultFinalPriceName = dealerResult?.dealer?.final_price_name || "";
    }

    if (defaultFinalPriceName) {
      for (const offer of formattedSelectedOfferList) {
        if (offer.row.finalPriceName?.trim() !== "") {
          continue;
        }
        offer.row.finalPriceName = defaultFinalPriceName;
      }
    }

    dispatch(
      fetchSelectedOfferListSuccess({
        flaggedVins,
        formattedSelectedOfferList,
      }),
    );
  };

export const fetchOfferList =
  (parameters: IQueryParameters): AppThunk =>
  async (dispatch, getState) => {
    dispatch(fetchOfferListBegin());

    const { assetBuilder } = getState();

    const { result, error } =
      await API.privServices.assetBuilder.fetchOfferList<IOfferListResponse>(
        parameters,
      );

    if (error) {
      // error
      dispatch(fetchOfferListFail());

      return;
    }

    const { total, offerList } = result;

    const { savedOrder, selectedOffers } = assetBuilder as IAssetBuilderState;

    const newOfferList = offerList.map(offerItem =>
      offerHelpers.aggregateRebateFieldValues(offerItem),
    );

    const formattedOfferList = (newOfferList as IProcessedOfferData[]).map(
      offer =>
        offerHelpers.setCalculatedFieldsInOfferData(
          offer as unknown as IProcessedOfferData,
        ),
    );

    if (savedOrder && savedOrder?.selectedOrder.dealer_name?.trim() !== "") {
      let defaultFinalPriceName = "";
      const { result: dealerResult } =
        await API.privServices.dealerManagement.getDealer(
          savedOrder.selectedOrder.dealer_name,
        );
      defaultFinalPriceName = dealerResult?.dealer.final_price_name || "";

      for (const offer of formattedOfferList) {
        if (!offer.row.dealerName) {
          offer.row.dealerName = savedOrder.selectedOrder.dealer_name;
        }
        if (!offer.row.dealerCode) {
          offer.row.dealerCode = savedOrder.selectedOrder.dealer_code;
        }

        if (defaultFinalPriceName && !offer.row.finalPriceName) {
          offer.row.finalPriceName = defaultFinalPriceName;
        }
      }
    }

    const withSelectedOfferList = formattedOfferList.map(offer => {
      const data = selectedOffers.find(
        selOffer => selOffer.offerData.vin === offer.row.vin,
      );

      return data
        ? {
            ...offer,
            row: data.offerData,
          }
        : offer;
    });

    dispatch(
      fetchOfferListSuccess({
        offerList: withSelectedOfferList,
        total,
        currentPage: parameters.currentPage as number,
        searchInput: parameters.searchInput as string,
        sortByFilter: parameters.sortByFilter as ISortingOption[],
        checkedOfferFilters:
          parameters.checkedOfferFilters as ICheckedOfferFilter,
        vehicleConditionFilter: parameters.vehicleCondition as string,
      }),
    );
  };

export const saveNewOffer = (): AppThunk => async (dispatch, getState) => {
  const { assetBuilder, configuration } = getState() as {
    assetBuilder: IAssetBuilderState;
    configuration: { config: IConfig };
  };

  const { offerData, currentTab } = assetBuilder;

  if (!offerData || !currentTab) {
    const error = new Error("Offer data is invalid");
    dispatch(saveOfferFail(error));
    return;
  }
  const offerExists = !offerData.vin
    ? false
    : await checkIfOfferExists({
        vin: offerData.vin,
        feedId: currentTab.feedId,
      });

  if (offerExists) {
    dispatch(toggleOverwriteOfferModal(true));
    return;
  }

  // verify that all offerData entries have valid inputs
  for (let i = 0; i < floatValueFields.length; i++) {
    const field = floatValueFields[i];
    const value = offerData[field as keyof OfferData] as string;
    // value in the form 123,123.1234 - negative numbers not allowed
    if (value && !/^[0-9]{1,3}(?:\,?[0-9]{3})*(?:\.[0-9]{1,4})?$/.test(value)) {
      const error = new Error(
        "Please input valid inputs for highlighted fields",
      );
      dispatch(saveOfferFail(error));
      return;
    }
  }

  const formattedAssetBuilder: IAssetBuilderState = {
    ...assetBuilder,
    rawOfferData: {
      ...assetBuilder.rawOfferData,
      aprRate: formatRateAsDecimal(assetBuilder.rawOfferData?.aprRate ?? ""),
      financeRate: formatRateAsDecimal(
        assetBuilder.rawOfferData?.financeRate ?? "",
      ),
    } as RawOfferData,
  };
  const stateData = { assetBuilder: formattedAssetBuilder, configuration };

  const { useUploadedJellyBean } = assetBuilder;
  return saveOfferData(dispatch, useUploadedJellyBean, stateData, "create");
};

type SaveOfferData = {
  assetBuilder: IAssetBuilderState;
  configuration: {
    config?: IConfig;
  };
};
// AV2-3653: Lithia/LADTech requested that this (calculating dealerDiscount, which needs dealer) be disabled, but will be used later
const saveOfferData = async (
  dispatch: Dispatch,
  useUploadedJellyBean: boolean,
  stateData: SaveOfferData,
  type: "create" | "update",
  revertingOffer?: boolean,
  feedId?: string,
  resetOffer?: RawOfferData,
) => {
  try {
    dispatch(saveOfferBegin());

    const {
      assetBuilder: {
        rawOfferData,
        editedKeys,
        savedOrder,
        selectedOffers,
        currentTab,
      },
    } = stateData;

    const { selectedOrder } = savedOrder || {};
    const {
      dealer_name: dealerName = "",
      dealer_code: dealerCode = "",
      dealer_oem: dealerOem = "",
    } = selectedOrder || {};

    let response = null;

    if (!rawOfferData) {
      throw new Error("Offer data to submit is empty");
    }

    let newOfferData = cloneDeep(resetOffer ?? rawOfferData);

    newOfferData.dealerName = dealerName;
    newOfferData.dealerCode = dealerCode;
    newOfferData.dealerId = dealerCode;
    newOfferData.make = rawOfferData.make ?? dealerOem;
    let newEditedKeys = editedKeys;

    const selectedOfferVins = selectedOffers.map(offer => offer.offerData.vin);
    const shouldUpdateOrderOffer = selectedOfferVins.includes(newOfferData.vin);

    const { offerData: returnedOfferData, editedKeys: returnedEditedKeys } =
      offerHelpers.returnOfferDataWithSplitRepeatFields(
        newOfferData,
        editedKeys,
        shouldUpdateOrderOffer,
      );
    newOfferData = returnedOfferData;
    newEditedKeys = returnedEditedKeys;

    const offerDataObj = { row: newOfferData };

    let formattedOfferDataObj = offerDataObj;

    formattedOfferDataObj = offerHelpers.setCalculatedFieldsInOfferData(
      offerDataObj as unknown as IProcessedOfferData,
      true,
      // { state: dealer?.dealerUsState } as IAccount, // AV2-3653: Lithia/LADTech requested that this be disabled, but will be used later
    );

    // AV2-1649: the following fields were switched to float
    const floatKeys = [
      "monthlyPayment",
      "financePayment",
      "dateInStock",
    ] as Array<keyof RawOfferData>;

    for (const key in formattedOfferDataObj.row) {
      const typedKey = key as keyof RawOfferData;
      const currentVal = newOfferData[typedKey];
      if (
        !floatKeys.includes(typedKey) ||
        !currentVal ||
        typeof currentVal !== "string"
      ) {
        continue;
      }
      const replaceval = currentVal.replace(",", "");
      const parsedFloat = parseFloat(replaceval);
      formattedOfferDataObj.row[typedKey] = parsedFloat as any;
    }

    const formattedNewOfferData = {
      ...formattedOfferDataObj.row,
      year: formattedOfferDataObj.row.year.trim(),
      make: formattedOfferDataObj.row.make.trim(),
      model: formattedOfferDataObj.row.model.trim(),
    } as RawOfferData;

    if (useUploadedJellyBean) {
      const { ...otherKeys } = newEditedKeys;
      newEditedKeys = { ...otherKeys, imageUrl: true };
    }

    if (selectedOfferVins.includes(newOfferData.vin)) {
      const newSelectedOffers = selectedOffers.map(offer => {
        if (offer.offerData.vin === formattedNewOfferData.vin) {
          return {
            ...offer,
            offerData: formattedNewOfferData,
          };
        } else return offer;
      });

      if (feedId) {
        await API.services.assetBuilder.updateOffer(
          feedId,
          formattedNewOfferData,
          newEditedKeys,
        );
      }
      if (resetOffer) {
        return dispatch(
          offerEditsSaved({
            result: {
              createdOffer: resetOffer,
            },
            newEditedKeys: {},
            newSelectedOffers,
          }),
        );
      }

      return dispatch(
        offerEditsSaved({
          revertingOffer,
          newSelectedOffers,
          result: {
            createdOffer: formattedNewOfferData,
          },
          newEditedKeys,
        }),
      );
    }

    if (type === "create") {
      response = await API.services.assetBuilder.createNewOffer(
        currentTab!.feedId,
        formattedNewOfferData,
      );
    } else {
      response = await API.services.assetBuilder.updateOffer(
        currentTab!.feedId,
        formattedNewOfferData,
        newEditedKeys,
      );
    }
    const { error, result } = response;

    if (error || !result) {
      return dispatch(
        saveOfferFail(
          new GenericError({
            message:
              error?.message || "Something went wrong while saving the offer",
          }),
        ),
      );
    }

    return dispatch(
      offerEditsSaved({
        result,
        newEditedKeys,
      }),
    );
  } catch (error) {
    return dispatch(saveOfferFail(error as Error));
  }
};

export const saveOffer =
  (
    useUploadedJellyBean: boolean,
    type: "create" | "update",
    revertingOffer?: boolean,
    feedId?: string,
    resetOffer?: RawOfferData,
  ): AppThunk =>
  async (dispatch, getState) => {
    await saveOfferData(
      dispatch,
      useUploadedJellyBean,
      getState(),
      type,
      revertingOffer,
      feedId,
      resetOffer,
    );
  };

export const setBlankOfferData = () =>
  setEditOfferData({
    rawOfferData: blankOfferData(),
    editedKeys: {},
    operation: "CREATE",
  });

export const fetchExportZipUrl =
  (exportedAssets: IExportedAssetStatus[], ignoreDownload: boolean): AppThunk =>
  async (dispatch, getState) => {
    dispatch(fetchZipUrlBegin());
    if (ignoreDownload) {
      dispatch(fetchZipUrlSuccess());
      return;
    }
    const { configuration } = getState();
    const { config } = configuration;

    const request: RequestInfo = new Request(
      `${(config as IConfig).services.getExportZipUrl}`,
      {
        method: "post",
        body: JSON.stringify({
          s3Data: exportedAssets,
        }),
      },
    );
    dispatch(fetchZipUrlInProgress());
    API.send<IZipExportUrlResponse>(request).then(({ result, error }) => {
      if (error) {
        return dispatch(fetchZipUrlFail(error));
      }
      dispatch(fetchZipUrlSuccess());
      const link = document.createElement("a");
      link.download = `${result.zipFileUrl}`;
      link.href = result.zipFileUrl;
      link.click();
    });
  };

export const fetchExportState =
  (exportedAssets: IExportedAssetStatus[]): AppThunk =>
  async (dispatch, getState) => {
    dispatch(fetchExportStatusBegin(exportedAssets));

    const { configuration } = getState();
    const { config } = configuration;

    const queryParam: string[] = [];

    for (const asset of exportedAssets) {
      queryParam.push(asset.jobId);
    }
    const request: RequestInfo = new Request(
      (config as IConfig).services.getExportState,
      {
        method: "post",
        body: JSON.stringify({ jobIds: queryParam }),
      },
    );
    API.send<IZipExportResponse>(request).then(({ result, error }) => {
      if (error) {
        return dispatch(
          fetchExportStatusFail({
            error: "Unable to export assets. Please try again later.",
          }),
        );
      }
      if (!result) {
        return dispatch(
          fetchExportStatusFail({
            error: "Unable to export assets. Please try again later.",
          }),
        );
      }

      for (const asset of result) {
        if (asset.status === "failure") {
          return dispatch(
            fetchExportStatusFail({
              error: "Unable to export assets. Please try again later.",
            }),
          );
        }
      }
      return dispatch(fetchExportStatusSuccess(result));
    });
  };

export const startVideoHtmlExport =
  (requestBodyExport: IZipExport[], dest?: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(generateCanvasZipUrlBegin());
    const { config } = getState().configuration;
    try {
      const jobId = `${uuid()}_${Date.now()}`;
      const videoExports: IZipExport[] = [];
      const htmlExports: IZipExport[] = [];
      requestBodyExport.forEach(req => {
        if (req.isVideo) {
          videoExports.push({ ...req, dest });
        } else if (req.imageOption === "html") htmlExports.push(req);
      });

      const videoParamsPromises = videoExports.map(videoData =>
        getVideoParams(videoData, config!),
      );

      const videoParams = await Promise.all(videoParamsPromises);
      const requestBody = [...htmlExports, ...videoParams];

      const VideoHtmlPromises = requestBody.map((body, idx) => {
        return new Promise<IExportImageOrVideoResult>(
          async (resolve, reject) => {
            try {
              const id =
                body.isVideo && body.filename
                  ? `${uuid()}_${body.filename}`
                  : `${jobId}_${idx + 1}`;
              const exportApi = API.services.assetBuilder.exportToImageAndVideo;
              const exportRes = await exportApi<IVideoHtmlRes>(body, id);
              if (exportRes.error || !exportRes.result) {
                const error = exportRes.error?.message || "Unable to Export.";
                reject(new Error(error));
              }
              resolve(exportRes.result as IExportImageOrVideoResult);
            } catch (err) {
              reject(err);
            }
          },
        );
      });
      await Promise.all(VideoHtmlPromises).then(vals => {
        dispatch(updateExportedAssets(vals));
      });
    } catch (error) {
      dispatch(
        generateCanvasZipUrlFail(
          new GenericError({
            message: (error as Error).message || "Some error ocurred.",
            errorObject: error,
          }),
        ),
      );
    }
  };

export const generateCanvasZipUrl =
  (
    images: string[],
    templateAndDimensionsArr: string[],
    wfID: string,
    store233: string,
    exportingVideo: boolean,
  ): AppThunk =>
  async dispatch => {
    dispatch(generateCanvasZipUrlBegin());
    try {
      if (exportingVideo) dispatch(generateImagesWithVideoBegin());
      const cleanedAssetNames = templateAndDimensionsArr.map(assetName => {
        const cleanedName = assetName.replace(/\//g, "-");
        return cleanedName;
      });
      let imageIDArr: string[] = [];
      const imageIDArrRes: IUploadCanvasImageResponse[] = [];
      let count = 0;

      for (const base64Canvas of images) {
        const uploadResult =
          await API.privServices.assetBuilder.uploadCanvasImage<IUploadCanvasImageResponse>(
            base64Canvas,
            cleanedAssetNames[count],
          );
        imageIDArrRes.push(uploadResult);
        count += 1;
      }

      const imagesForZipExport: IExportImageOrVideoResult[] = [];

      for (const res of imageIDArrRes) {
        if (res.result) {
          if (exportingVideo) {
            const splitCanvasId = res.result.canvasID.split(".");
            imagesForZipExport.push({
              jobId: splitCanvasId[0],
              status: "success",
              imgExt: splitCanvasId[1],
            });
          }
          imageIDArr.push(`${res.result.canvasID}`);
        }
      }

      if (exportingVideo) {
        dispatch(
          setImageZipExports({
            imageExports: imagesForZipExport,
          }),
        );
        dispatch(generateImagesWithVideoEnd());
        return;
      }

      // videos do not have their data uploaded, so they need to be appended separately
      const videoAssetNames = templateAndDimensionsArr
        .slice(count)
        .filter(filename => !filename.endsWith("_FAILED"));
      imageIDArr = imageIDArr.concat(videoAssetNames);

      const response =
        await API.privServices.assetBuilder.generateCanvasZipUrl<IGetCanvasZipUrlResponse>(
          imageIDArr,
          wfID,
          store233,
        );

      const { error, result } = response;
      if (error) {
        const { message } = error;
        dispatch(
          generateCanvasZipUrlFail(
            new GenericError({
              message: message || "Some error ocurred.",
            }),
          ),
        );
        return;
      }

      if (result && result.s3Url) {
        const { s3Url } = result;
        const link = document.createElement("a");
        link.download = `${s3Url}`;
        link.href = s3Url;
        link.click();
      }

      dispatch(generateCanvasZipUrlSuccess(response));
    } catch (error) {
      dispatch(
        generateCanvasZipUrlFail(
          new GenericError({
            message: (error as Error).message || "Some error ocurred.",
            errorObject: error,
          }),
        ),
      );
    }
  };

export const generatePDFInvoker =
  (): AppThunk => async (dispatch, getState) => {
    try {
      const { configuration, assetBuilder } = getState();
      const { config } = configuration;
      const { executionId } = assetBuilder;

      const { result } = await getJobStatus(config!, executionId!);

      if (result?.jobStatus?.status === "success") {
        const { responseJson } = result?.jobStatus;
        const { pdfName, pdfUrl } = JSON.parse(responseJson || "{}").result;

        dispatch(
          generatePdfInvoker({
            pdfName,
            pdfUrl,
          }),
        );
      }

      if (result?.jobStatus?.status === "failure") {
        dispatch(
          generatePDFFail(
            new GenericError({
              message: "PDF could NOT be generated",
            }),
          ),
        );

        dispatch(generatePDFInvokerFail());
        return;
      }
    } catch (error) {
      const { message } = error as Error;
      dispatch(
        generatePDFFail(
          new GenericError({
            message: message || "Some error ocurred.",
          }),
        ),
      );
      return;
    }
  };

export const generatePDF =
  (
    images: string[],
    templateAndDimensionsArr: string[],
    feedData: IOfferWithDisclosure[],
    userName: string,
    projectName: string,
    currentDate: string,
    customInstanceDisclosures?: IPDFCustomDisclosure[][],
  ): AppThunk =>
  async dispatch => {
    dispatch(generatePDFBegin());
    try {
      const cleanedAssetNames = templateAndDimensionsArr.map(assetName => {
        const cleanedName = assetName.replace(/\//g, "-");
        return cleanedName;
      });
      const pdfData: IPDFData[] = [];
      let count = 0;

      for (const base64Canvas of images) {
        let templateAndDimensions = cleanedAssetNames[count];
        const fileNameMaxLength = 200;
        while (
          templateAndDimensions.length > fileNameMaxLength &&
          templateAndDimensions.includes("_")
        ) {
          templateAndDimensions = templateAndDimensions
            .split("_")
            .slice(1, templateAndDimensions.split("_").length - 1)
            .join("_");
        }

        // ensure max is never exceeded
        templateAndDimensions = templateAndDimensions.substring(
          0,
          fileNameMaxLength,
        );

        const uploadResult =
          await API.privServices.assetBuilder.uploadCanvasImage<IUploadCanvasImageResponse>(
            base64Canvas,
            templateAndDimensions,
          );
        const splitExportedName = cleanedAssetNames[count].split("_");
        const [assetType, idx, dimension] =
          offerHelpers.extractOfferVarsFromName(splitExportedName);
        const index = Number(idx);

        for (const row of feedData) {
          if (
            cleanedAssetNames[count]
              .toLowerCase()
              .includes(row.offerData.vin.toLowerCase())
          ) {
            const { year, make, model, trim } = row.offerData;
            let customDisc = "";
            if (customInstanceDisclosures) {
              for (const batch of customInstanceDisclosures) {
                const foundRow = findMatchingDiscRow(
                  batch,
                  assetType,
                  index,
                  dimension,
                );
                if (foundRow?.cleanedText) {
                  customDisc = foundRow.cleanedText;
                }
              }
            }
            pdfData.push({
              canvasID: uploadResult.result?.canvasID || "",
              pdfOfferData: {
                year,
                make,
                model,
                trim,
                selectedOffers: row.selectedOffers,
                disclaimer: customDisc.length
                  ? customDisc
                  : row.finalDisclosure,
              },
            });
          }
        }
        count += 1;
      }

      const executionId =
        enablePDFInvoker === "true"
          ? `${uuid()}_${Date.now()}_assetBuilderGeneratePDF`
          : undefined;

      const response =
        await API.privServices.assetBuilder.generatePDF<IGeneratePDFResponse>(
          pdfData,
          userName,
          projectName,
          currentDate,
        );

      const { error } = response;

      if (error) {
        const { message } = error;
        dispatch(
          generatePDFFail(
            new GenericError({
              message: message || "Some error ocurred.",
            }),
          ),
        );
        return;
      }

      dispatch(generatePDFSuccess({ response, executionId }));
    } catch (error) {
      dispatch(
        generatePDFFail(
          new GenericError({
            message: (error as Error).message || "Some error ocurred.",
            errorObject: error,
          }),
        ),
      );
    }
  };

export const feedDataToPDF =
  (
    feedData: IOfferWithDisclosure[],
    userName: string,
    projectName: string,
  ): AppThunk =>
  async dispatch => {
    dispatch(feedDataToPDFBegin());
    try {
      const response =
        await API.privServices.assetBuilder.feedDataToPDF<IGeneratePDFResponse>(
          feedData,
          userName,
          projectName,
        );

      const { error } = response;

      if (error) {
        const { message } = error;
        dispatch(
          feedDataToPDFFail(
            new GenericError({
              message: message || "Some error ocurred.",
            }),
          ),
        );
        return;
      }

      dispatch(feedDataToPDFSuccess(response));
    } catch (error) {
      dispatch(
        feedDataToPDFFail(
          new GenericError({
            message: (error as Error).message || "Some error ocurred.",
            errorObject: error,
          }),
        ),
      );
    }
  };

export const coopSubmission =
  (
    images: string[],
    templateAndDimensionsArr: string[],
    feedData: IOfferWithDisclosure[],
    userName: string,
    projectName: string,
    coopData: ICoopIntegrationData,
    order: INewOrder,
    currentDate: string,
  ): AppThunk =>
  async dispatch => {
    dispatch(coopSubmissionBegin());
    try {
      const cleanedAssetNames = templateAndDimensionsArr.map(assetName => {
        const cleanedName = assetName.replace(/\//g, "-");
        return cleanedName;
      });
      const pdfData: IPDFData[] = [];
      let count = 0;

      for (const base64Canvas of images) {
        const uploadResult =
          await API.privServices.assetBuilder.uploadCanvasImage<IUploadCanvasImageResponse>(
            base64Canvas,
            cleanedAssetNames[count],
          );
        const assetTextArr = cleanedAssetNames[count].split("_");
        const client = process.env.REACT_APP_AV2_CLIENT;
        const selectedVin =
          cleanedAssetNames[count].split("_")[
            client === "ladtech" ? assetTextArr.length - 2 : 3
          ];
        for (const row of feedData) {
          if (row.offerData.vin.toLowerCase() === selectedVin.toLowerCase()) {
            const { year, make, model, trim } = row.offerData;
            pdfData.push({
              canvasID: uploadResult.result?.canvasID || "",
              pdfOfferData: {
                year,
                make,
                model,
                trim,
                selectedOffers: row.selectedOffers,
                disclaimer: row.finalDisclosure,
              },
            });
          }
        }
        count += 1;
      }

      const pdfResponse =
        await API.privServices.assetBuilder.generatePDF<IGeneratePDFResponse>(
          pdfData,
          userName,
          projectName,
          currentDate,
        );

      let filename: string = "";

      if (enableCoopPDFInvoker === "true") {
        const formattedProjectName = projectName
          .replace(/ /g, "_")
          .replace(/\//g, "-");
        filename = `${formattedProjectName}_${Date.now()}.pdf`;
      } else {
        filename = pdfResponse.result?.pdfRes.pdfFileName || "";
      }

      if (!filename) {
        throw new Error("no filename was obtained from generation");
      }

      const coopResponse =
        await API.privServices.assetBuilder.coopSubmission<ICoopSubmissionResponse>(
          filename,
          coopData,
          order,
        );

      const { error } = coopResponse;
      if (error) {
        const { message } = error;
        dispatch(
          coopSubmissionFail(
            new GenericError({
              message:
                `COOP: ${message}` || `Failed to obtain response from backend`,
            }),
          ),
        );
        return;
      }

      dispatch(coopSubmissionSuccess({ response: coopResponse }));
    } catch (error) {
      dispatch(
        coopSubmissionFail(
          new GenericError({
            message:
              `COOP: ${(error as Error).message}` ||
              `Failed to obtain response from backend`,
            errorObject: error,
          }),
        ),
      );
    }
  };

export const generateImagesForLauncherPage =
  (images: string[], templateAndDimensionsArr: string[]): AppThunk =>
  async dispatch => {
    try {
      const cleanedAssetNames = templateAndDimensionsArr.map(assetName => {
        const cleanedName = assetName.replace(/\//g, "-");
        return cleanedName;
      });

      const imageIDArr: string[] = [];
      const imageIDArrRes: IUploadCanvasImageResponse[] = [];
      let count = 0;

      let uploadResult: IUploadCanvasImageResponse = {
        result: { canvasID: "" },
        error: { message: "" },
        statusCode: 200,
      };

      for (const base64Canvas of images) {
        const { getFilefromDataUrl, getDataUrlFromFile } = imageCompression;
        const file = await getFilefromDataUrl(base64Canvas, uuid());
        const compressedFile = await imageCompression(file, { maxSizeMB: 1 });
        const compressedImg = await getDataUrlFromFile(compressedFile);
        uploadResult =
          await API.privServices.assetBuilder.uploadCanvasImage<IUploadCanvasImageResponse>(
            compressedImg,
            cleanedAssetNames[count],
          );
        imageIDArrRes.push(uploadResult);
        count += 1;
      }

      for (const res of imageIDArrRes) {
        if (res.result) {
          imageIDArr.push(`${res.result.canvasID}`);
        }
      }

      dispatch(generateImagesForLauncherPageSuccess(imageIDArr));
      if (!uploadResult.error?.message) {
        dispatch(uploadCanvasImageSuccess(uploadResult));
      }
    } catch (error) {
      dispatch(
        generatePDFFail(
          new GenericError({
            message: (error as Error).message || "Some error ocurred.",
            errorObject: error,
          }),
        ),
      );
    }
  };

export const feedDataToCSV =
  (feedData: IOfferWithDisclosure[], orderID: string): AppThunk =>
  async dispatch => {
    dispatch(feedDataToCSVBegin());
    try {
      const response =
        await API.privServices.assetBuilder.feedDataToCSV<IFeedDataToCSVResponse>(
          feedData,
          orderID,
        );

      if (response?.result?.s3Url) {
        const { s3Url } = response.result;
        const link = document.createElement("a");
        link.download = `${s3Url}`;
        link.href = s3Url;
        link.click();
      }

      const { error } = response;
      if (error) {
        const { message } = error;
        dispatch(
          feedDataToCSVFail(
            new GenericError({
              message: message || "Some error ocurred.",
            }),
          ),
        );
        return;
      }

      dispatch(feedDataToCSVSuccess(response));
    } catch (error) {
      dispatch(
        feedDataToCSVFail(
          new GenericError({
            message: (error as Error).message || "Some error ocurred.",
            errorObject: error,
          }),
        ),
      );
    }
  };

export const uploadCanvasImage =
  (canvas: string, templateAndDimensions: string): AppThunk =>
  async dispatch => {
    dispatch(uploadCanvasImageBegin());
    try {
      const response =
        await API.privServices.assetBuilder.uploadCanvasImage<IUploadCanvasImageResponse>(
          canvas,
          templateAndDimensions,
        );

      const { error } = response;
      if (error) {
        const { message } = error;
        dispatch(
          uploadCanvasImageFail(
            new GenericError({
              message: message || "Some error ocurred.",
            }),
          ),
        );
        return;
      }

      dispatch(uploadCanvasImageSuccess(response));
    } catch (error) {
      dispatch(
        uploadCanvasImageFail(
          new GenericError({
            message: (error as Error).message || "Some error ocurred.",
            errorObject: error,
          }),
        ),
      );
    }
  };

export const uploadAssetToWF =
  (
    canvas: string[],
    templateAndDimensions: string[],
    wfProject: string,
  ): AppThunk =>
  async dispatch => {
    dispatch(uploadAssetToWFBegin());
    try {
      const idArr: string[] = [];

      const idArrRes = canvas.map((base64Canvas, index) => {
        return API.privServices.assetBuilder.uploadAssetToWF<IUploadAssetToWFResultResponse>(
          base64Canvas,
          templateAndDimensions[index],
          wfProject,
        );
      });

      Promise.all(idArrRes).then(async responses => {
        for (const res of responses) {
          if (res.result) {
            idArr.push(`${res.result.id}`);
          }
        }
      });

      const response = { id: idArr[0] };

      dispatch(uploadAssetToWFSuccess(response));
    } catch (error) {
      dispatch(
        uploadAssetToWFFail(
          new GenericError({
            message: (error as Error).message || "Some error ocurred.",
            errorObject: error,
          }),
        ),
      );
    }
  };

export const proofExportData =
  (orderId: string): AppThunk =>
  async dispatch => {
    dispatch(proofExportDataBegin());

    try {
      const { proofExportData } = API.services.assetBuilder;
      const response = await proofExportData<IProofExportDataResponse>(orderId);

      const { error } = response;

      if (error) {
        const { message } = error;
        dispatch(
          proofExportDataFail(
            new GenericError({
              message: message || "Some error ocurred.",
            }),
          ),
        );
        return;
      }

      dispatch(proofExportDataSuccess(response));
    } catch (error) {
      dispatch(
        proofExportDataFail(
          new GenericError({
            message: (error as Error).message || "Some error ocurred.",
            errorObject: error,
          }),
        ),
      );
    }
  };

export const getProofTemplates = (): AppThunk => async dispatch => {
  dispatch(getProofTemplatesBegin());

  try {
    const response =
      await API.privServices.assetBuilder.getProofTemplates<IGetWFTemplatesResponse>();

    const { error } = response;

    if (error) {
      const { message } = error;
      dispatch(
        getProofTemplatesFail(
          new GenericError({
            message: message || "Some error ocurred.",
          }),
        ),
      );
      return;
    }

    dispatch(getProofTemplatesSuccess(response));
  } catch (error) {
    dispatch(
      getProofTemplatesFail(
        new GenericError({
          message: (error as Error).message || "Some error ocurred.",
          errorObject: error,
        }),
      ),
    );
  }
};

export const getWorkfrontFolders =
  (wfID: string, wfProject: string): AppThunk =>
  async dispatch => {
    dispatch(getWorkfrontFoldersBegin());

    try {
      const response =
        await API.privServices.assetBuilder.getWorkfrontFolders<IGetWFFoldersResponse>(
          wfID,
          wfProject,
        );

      const { error } = response;

      if (error) {
        const { message } = error;
        dispatch(
          getWorkfrontFoldersFail(
            new GenericError({
              message: message || "Some error ocurred.",
            }),
          ),
        );
        return;
      }

      dispatch(getWorkfrontFoldersSuccess(response));
    } catch (error) {
      dispatch(
        getWorkfrontFoldersFail(
          new GenericError({
            message: (error as Error).message || "Some error ocurred.",
            errorObject: error,
          }),
        ),
      );
    }
  };

export const getPaymentEngineData =
  (paymentEngineRequestBody: PaymentEngineRequestBody): AppThunk =>
  async dispatch => {
    dispatch(getPaymentEngineDataBegin());

    try {
      /*
       As of AV2-1784: the result of one dealType request is returned.
       Possible future TO DO: processedPaymentEngine data
       should be a dictionary maybe of {lease,loan, purchase}, if dealType not provided
     */
      const { result, error } =
        await API.privServices.assetBuilder.getPaymentEngineData<IGetPaymentEngineDataResponse>(
          paymentEngineRequestBody,
        );

      if (!result || error) {
        return dispatch(
          getPaymentEngineDataFail(
            error || {
              name: "Payment Engine Error",
              message: `Could not fetch data for vin ${paymentEngineRequestBody.vehicleParameters?.vin}`,
            },
          ),
        );
      }

      const { res: rawPaymentEngineDealData } = result;
      const paymentengineMappingObj = mapPaymentEngineDealDataToOfferData(
        rawPaymentEngineDealData,
      );

      const processedPaymentEngineDealData: ProcessedPaymentEngineData =
        paymentengineMappingObj;

      dispatch(
        getPaymentEngineDataSuccess({
          processedDSData: processedPaymentEngineDealData,
          rawDSData: rawPaymentEngineDealData,
        }),
      );
    } catch (error) {
      dispatch(
        getPaymentEngineDataFail(
          new GenericError({
            message: `${error}` || "Some error ocurred.",
            errorObject: error,
          }),
        ),
      );
    }
  };

// change to optional year make model
export const getJellybeanImages =
  (
    offerData: OfferData | null,
    searchObj?: { year: string; make: string; model: string },
  ): AppThunk =>
  async dispatch => {
    dispatch(getJellybeanImagesBegin());

    const { year, make, model } = searchObj ||
      offerData || {
        year: "",
        make: "",
        model: "",
      };

    try {
      // TODO: fix return type
      const { result, error } =
        await API.services.assetBuilder.getJellybeanImages<{
          result: unknown;
          error: GenericError;
        }>({
          year: year.trim(),
          make: make.trim(),
          model: model.trim(),
        });

      if (!result || error) {
        return dispatch(
          getJellybeanImagesFail(
            error || {
              name: "Get Jellybean Images Error",
              message: `Could not fetch jellybean images`,
            },
          ),
        );
      }

      dispatch(getJellybeanImagesSuccess(result));
    } catch (error) {
      dispatch(
        getJellybeanImagesFail(
          new GenericError({
            message: `${error}` || "Some error ocurred.",
            errorObject: error,
          }),
        ),
      );
    }
  };

export const pushToProof =
  (
    canvases: string[],
    templateAndDimensionsArr: string[],
    wfProject: string,
    wfID: string,
    templateID: string,
    subject: string,
    proofMessage: string,
    folderID: string,
    parentFileToken: string,
    documentID: string,
    updatedOrder: INewOrder,
    feedData: IOfferWithDisclosure[],
    orderID: string,
    userName: string,
    projectName: string,
    includeAssets: boolean,
    includePDF: boolean,
    includeCSV: boolean,
    currentDate: string,
    proofName: string,
    selectedProofArr: IWorkfrontProofData | null,
    selectedProofKey: string | null,
    customInstanceDisclosures?: IPDFCustomDisclosure[][],
  ): AppThunk =>
  async dispatch => {
    dispatch(pushToProofBegin());
    try {
      // upload canvases first
      // then upload to proof/wf

      const cleanedAssetNames = templateAndDimensionsArr.map(assetName => {
        // note that encoding with %2F might still showup as that in the name
        // for now (-) will be used instead of space in the name
        const cleanedName = assetName.replace(/\//g, "-");
        return cleanedName;
      });
      const imageIDArr: string[] = [];

      // make logic optional for if it checkboxes for pdf or csv were checked off

      let count = 0;
      const pdfData: IPDFData[] = [];
      for await (const base64Canvas of canvases) {
        const uploadCanvasRes =
          await API.privServices.assetBuilder.uploadCanvasImage<IUploadCanvasImageResponse>(
            base64Canvas,
            cleanedAssetNames[count],
          );

        if (uploadCanvasRes.result) {
          imageIDArr.push(`${uploadCanvasRes.result.canvasID}`);
        }
        const assetTextArr = cleanedAssetNames[count].split("_");
        const client = process.env.REACT_APP_AV2_CLIENT;
        const selectedVin =
          cleanedAssetNames[count].split("_")[
            client === "ladtech" ? assetTextArr.length - 2 : 3
          ];
        const splitExportedName = cleanedAssetNames[count].split("_");
        const [assetType, idx, dimension] =
          offerHelpers.extractOfferVarsFromName(splitExportedName);
        const index = Number(idx);
        for (const row of feedData) {
          if (row.offerData.vin.toLowerCase() === selectedVin.toLowerCase()) {
            const { year, make, model, trim } = row.offerData;
            let customDisc = "";
            if (customInstanceDisclosures) {
              for (const batch of customInstanceDisclosures) {
                const foundRow = findMatchingDiscRow(
                  batch,
                  assetType,
                  index,
                  dimension,
                );
                if (foundRow?.cleanedText) {
                  customDisc = foundRow.cleanedText;
                }
              }
            }
            pdfData.push({
              canvasID: uploadCanvasRes.result?.canvasID || "",
              pdfOfferData: {
                year,
                make,
                model,
                trim,
                selectedOffers: row.selectedOffers,
                disclaimer: customDisc.length
                  ? customDisc
                  : row.finalDisclosure,
              },
            });
          }
        }
        count += 1;
      }

      let filename = "";
      if (includePDF) {
        const pdfResponse =
          await API.privServices.assetBuilder.generatePDF<IGeneratePDFResponse>(
            pdfData,
            userName,
            projectName,
            currentDate,
          );

        const { pdfFileName } = pdfResponse.result?.pdfRes || {
          pdfFileName: "",
        };
        filename = pdfFileName;
        if (!filename) {
          throw new Error("no filename was obtained from generation");
        }
      }

      let csvKey = "";
      if (includeCSV) {
        const csvResponse =
          await API.privServices.assetBuilder.feedDataToCSV<IFeedDataToCSVResponse>(
            feedData,
            orderID,
          );

        const { key } = csvResponse.result || {
          key: "",
        };
        csvKey = key;
        if (!key) {
          throw new Error("no filename was obtained from generation");
        }
      }

      const stageAndRecipientResponse =
        await API.privServices.assetBuilder.getStagesAndRecipients<IGetStagesAndRecipientsResponse>(
          templateID,
        );

      let stageAndRecipientString = "";

      if (stageAndRecipientResponse?.result) {
        stageAndRecipientString =
          stageAndRecipientResponse.result.stageAndRecipientString;
      }
      if (!includeAssets) {
        imageIDArr.length = 0;
      }

      const lineBreakMessage =
        proofMessage.match(/[^\r\n]+/g)?.join("<br />") || proofMessage;

      await API.privServices.assetBuilder.pushToProof<IPushToProofResponse>(
        imageIDArr,
        wfProject,
        wfID,
        templateID,
        subject,
        lineBreakMessage,
        stageAndRecipientString,
        folderID,
        parentFileToken,
        documentID,
        updatedOrder,
        csvKey,
        filename,
        proofName,
        selectedProofArr,
        selectedProofKey,
      );
      dispatch(pushToProofSuccess());
    } catch (error) {
      dispatch(
        pushToProofFail(
          new GenericError({
            message: (error as Error).message || "Some error ocurred.",
            errorObject: error,
          }),
        ),
      );
    }
  };

export const duplicateOrderState =
  (
    originalOrderId: string,
    newOrderId: string,
    resetToFeedData: boolean,
  ): AppThunk =>
  async dispatch => {
    const { result, error } =
      await API.privServices.assetBuilder.duplicateOrderState<{
        result: { message: string };
        error: { message: string };
      }>(originalOrderId, newOrderId, resetToFeedData);

    if (error) {
      dispatch(duplicateOrderStateFail(error.message));
    }
    dispatch(duplicateOrderStateSuccess(result));
  };

const getDummyOrder = (
  id: string,
  dealerData?: DealerDataFeed["dealerData"],
) => {
  /*
      This dummy order is needed in order for existing components
      to work with batch asset export orchestration
    */
  const {
    dealer_name = "BMW of Anchorage",
    dealer_code = "AKANCBMW",
    dealer_oem = "BMW",
  } = dealerData ?? {};
  return {
    id,
    address: "110 William Street",
    city: "New York",
    created_at: 1604460249376,
    dealer_name,
    dealer_code,
    dealer_oem,
    final_price_name: null,
    logo_url: null,
    logo_urls_from_S3:
      '{"horizontalImagesFromS3":[],"verticalImagesFromS3":[],"squareImagesFromS3":[],"horizontalEventImagesFromS3":[],"verticalEventImagesFromS3":[],"squareEventImagesFromS3":[]}',
    phone_number: null,
    state: null,
    updated_at: 1604460249376,
    zip: "10038",
  } as unknown as INewOrder;
};

export const fetchFeedOrder =
  (args: AssetExportQueryParams): AppThunk =>
  async dispatch => {
    dispatch(fetchOrderStateBegin());
    const api = API.services.assetBuilder.getFeedOrder;
    const { result, error } = await api(args);

    if (error || !result) {
      return dispatch(
        fetchOrderStateFail({
          error: error.message || "Feed data could not be fetched.",
        }),
      );
    }

    const savedOrder = {
      assetInstances: { ...result.assetInstances },
      selectedOffers: [...(result.selectedOffers || [])],
      orderId: result.orderId,
      selectedOrder: getDummyOrder(result.orderId, result.dealerData),
    } as ISavedOrderState;

    dispatch(setSavedOrder({ savedOrder }));

    dispatch(fetchOrderStateSuccess({ savedOrder }));
  };

export const fetchOrderState =
  (
    orderId: string,
    assetExportQueryObj?: IAssetExportQueryParamObj,
    redirectType?: string,
  ): AppThunk =>
  async (dispatch, getState) => {
    const { configuration } = getState();
    const { config } = configuration;

    dispatch(fetchOrderStateBegin());

    // Sending request to get selected order.
    // Getting order from the server will be better for the case when user refreshes.
    const orderResponse = await HttpClient.get<IResponse<INewOrder, string>>(
      `${(config as IConfig).services.assetBuilder.ordersUrl}/${orderId}`,
    );

    if (orderResponse.error && !assetExportQueryObj?.batchid) {
      return dispatch(
        fetchOrderStateFail({
          error: orderResponse.error,
        }),
      );
    }

    const { order: selectedOrder } =
      configuration.feed === "internal" || !assetExportQueryObj?.batchid
        ? orderResponse.result
        : { order: getDummyOrder(orderId) };

    let fetchOrderStateUrl = `${
      (config as IConfig).services.assetBuilder.orderStateUrl
    }?orderId=${orderId}`;

    if (assetExportQueryObj) {
      for (const param in assetExportQueryObj) {
        const paramValue =
          assetExportQueryObj[param as keyof IAssetExportQueryParamObj];
        if (!paramValue) {
          continue;
        }
        fetchOrderStateUrl = `${fetchOrderStateUrl}&${param}=${encodeURIComponent(
          paramValue,
        )}`;
      }
    }

    const { result, error } = await HttpClient.get<
      IResponse<ISavedOrderState, string>
    >(fetchOrderStateUrl);

    if (error || !result) {
      return dispatch(
        fetchOrderStateFail({
          error: error || "Order data could not be fetched.",
        }),
      );
    }

    const resultTransformed = {
      ...result,
      selectedOffers: (result as any).selectedOffers || [],
    };

    const savedOrder = {
      ...resultTransformed,
      selectedOrder,
    } as ISavedOrderState;

    // depending on the available data, redirect to different page.
    const basePath = `/asset-builder`;
    let redirect: IAssetBuilderState["redirect"];
    if (redirectType === "launcher") {
      redirect = {
        path:
          savedOrder?.assetInstances && !isEmpty(savedOrder.assetInstances)
            ? `${basePath}/orders/${savedOrder.orderId}/offers/asset-launcher`
            : `${basePath}/orders/`,
        defaultPath: `${basePath}/orders/`,
        page: "launcher",
      };
    } else {
      if (savedOrder.assetInstances && !isEmpty(savedOrder.assetInstances)) {
        redirect = {
          path: `${basePath}/orders/${savedOrder.orderId}/offers/review`,
          defaultPath: `${basePath}/orders/${savedOrder.orderId}/offers/select`,
          page: "review",
        };
      } else if (!isEmpty(savedOrder.selectedOffers)) {
        redirect = {
          path: `${basePath}/orders/${savedOrder.orderId}/offers/build`,
          defaultPath: `${basePath}/orders/${savedOrder.orderId}/offers/select`,
          page: "build",
        };
      } else {
        redirect = {
          path: `${basePath}/orders/${savedOrder.orderId}/offers/select`,
          defaultPath: `${basePath}/orders/${savedOrder.orderId}/offers/select`,
          page: "select",
        };
      }
    }

    dispatch(
      setSavedOrder({
        savedOrder,
        redirect,
      }),
    );

    dispatch(fetchOrderStateSuccess({ savedOrder }));
  };

export const commitOrder =
  (creatingOrder?: boolean): AppThunk =>
  async (dispatch, getState) => {
    const { assetBuilder, configuration, newOrders } = getState();
    const {
      savedOrder,
      selectedOffers,
      assetInstances,
      offerEditsWereSaved,
      rawSelectedOffers,
      currentTab,
    } = assetBuilder;
    const { config } = configuration;

    let totalSelectedOffers = 0;
    const offerSet = new Set();

    for (const offer of selectedOffers) {
      totalSelectedOffers += offer.offers.length;
      for (const offerType of offer.offers) {
        offerSet.add(offerType);
      }
    }

    const { assetInstances: assetInstanceVals } = savedOrder || {};
    const totalCounter = assetInstanceVals
      ? getAssetCount(assetInstanceVals)
      : 0;
    const stampTotal = assetInstanceVals ? getStampCount(assetInstances) : 0;

    let savedOrderToUse = {
      ...savedOrder,
      selectedOrder: {
        ...savedOrder?.selectedOrder,
        selectTemplateCollapseSearchInput:
          assetBuilder.savedSelectTemplateSearch,
        selectImageCollapseSearchInput: assetBuilder.savedSelectImageSearch,
        selectedInventory: selectedOffers.length,
        totalSelectedOffers,
        selectedOrderOffers: Array.from(offerSet).join(", \n"),
        num_assets: totalCounter,
        totalUsedStamps: stampTotal,
      } as INewOrder,
    };

    let selectedOffersToUse: ISelectedOffer[] | null = selectedOffers;
    let assetInstancesToUse: Record<
      string,
      Record<string, IAssetInstance[]>
    > | null = assetInstances;

    if (creatingOrder) {
      /*
       AV2-1631 & AV2-1616: the order state in redux needs to be updated
       when creating an order as well
     */

      const initialSelectedOrder = {
        ...newOrders.currentSelectedOrder,
      };
      // TS Complains if any is not used
      delete (initialSelectedOrder as any)?.actions;
      delete (initialSelectedOrder as any)?.key;

      const initialOrder: ISavedOrderState = {
        selectedOffers: null,
        assetInstances: null,
        canvasData: null,
        orderId: newOrders.currentSelectedOrder.id,
        selectedOrder: initialSelectedOrder as INewOrder,
      };
      savedOrderToUse = initialOrder;
      selectedOffersToUse = [];
      assetInstancesToUse = {};
    }

    const selectedOffersToSave: ISelectedOffer[] | null = selectedOffersToUse
      .map(offerHelpers.mapTrimedValues)
      .map(offer => {
        const rawSelectedOffer = rawSelectedOffers[offer.offerData.vin];
        if (!rawSelectedOffer || offer.offerData.imageUrl) return offer;
        return set(
          offer,
          "offerData.imageUrl",
          rawSelectedOffer.offerData.imageUrl,
        );
      });

    const newSavedOrderToUse = {
      ...savedOrderToUse,
      selectedOffers: selectedOffersToSave,
      assetInstances: assetInstancesToUse,
      currentTab: currentTab ?? savedOrder?.currentTab,
    } as ISavedOrderState;

    //commented out for possible future use
    // dispatch({
    //   type: AssetBuilderActionTypes.COMMIT_ORDER_STATE_BEGIN,
    // });

    // separate selectedOffers are being updated whenever user checks check marks.
    // replace the selectedOffers in savedOrder with this up to date data.
    dispatch(
      commitOrderStateClient({
        savedOrder: {
          ...newSavedOrderToUse,
          selectedOffers: selectedOffersToSave,
        },
      }),
    );

    const request: RequestInfo = new Request(
      `${(config as IConfig).services.assetBuilder.orderStateUrl}`,
      {
        method: "post",
        body: JSON.stringify(newSavedOrderToUse),
      },
    );

    const { error } = await API.send<IResponse<ISavedOrderState>>(request);

    if (error) {
      dispatch(commitOrderStateFail());
    } else if (!offerEditsWereSaved) {
      const reduceOffersIntoRawOffer = (
        prevOffer: RawSelectedOffers,
        rawOffer: ISelectedOffer,
      ): RawSelectedOffers => {
        const vin = rawOffer.offerData.vin;
        return {
          ...prevOffer,
          [vin]: {
            ...rawSelectedOffers[vin],
            ...rawOffer,
            offerData: {
              ...rawSelectedOffers[vin].offerData,
              ...rawOffer.offerData,
            },
          },
        };
      };
      const newRawSelectedOffers =
        selectedOffersToSave.reduce<RawSelectedOffers>(
          reduceOffersIntoRawOffer,
          {},
        );
      dispatch(
        commitOrderStateSuccess({
          newRawSelectedOffers: newRawSelectedOffers,
        }),
      );
    }
  };

export const resetToMasterData =
  (vin: string): AppThunk =>
  async (dispatch, getState) => {
    const {
      assetBuilder: { rawSelectedOffers },
    } = getState() as {
      assetBuilder: IAssetBuilderState;
    };

    dispatch(
      resetToMasterDataAction({
        newOfferData: rawSelectedOffers[vin].offerData,
      }),
    );
  };

export const getRawOfferByVin =
  (vin: string): AppThunk =>
  async dispatch => {
    dispatch(getRawOfferByVinBegin());

    const { result, error } =
      await API.privServices.assetBuilder.getRawOfferByVin<IGetRawOfferByVinOfferResponse>(
        vin,
      );

    if (error || !result) {
      return dispatch(
        getRawOfferByVinFail(
          new GenericError({
            message:
              error?.message || `Could not retrieve offer with the VIN ${vin}`,
          }),
        ),
      );
    }

    return dispatch(getRawOfferByVinSuccess(result));
  };

export const updateRawSelectedOffer =
  (offer: OfferData): AppThunk =>
  async (dispatch, getState) => {
    const {
      assetBuilder: { rawSelectedOffers },
    } = getState() as {
      assetBuilder: IAssetBuilderState;
    };

    const updatedRawSelectedOffers = Object.keys(
      rawSelectedOffers,
    ).reduce<RawSelectedOffers>((acc, vin) => {
      const existingRawOffer = rawSelectedOffers[vin];

      return offer.vin === vin
        ? {
            ...acc,
            [vin]: {
              ...existingRawOffer,
              offerData: offer,
            },
          }
        : {
            ...acc,
            [vin]: existingRawOffer,
          };
    }, {});
    dispatch(updateRawSelectedOfferAction(updatedRawSelectedOffers));
  };

export const {
  assetInstanceCounter,
  coopSubmissionBegin,
  coopSubmissionFail,
  coopSubmissionSuccess,
  createWebIntegrationBegin,
  createWebIntegrationFail,
  createWebIntegrationSuccess,
  deleteWebIntegrationBegin,
  deleteWebIntegrationFail,
  deleteWebIntegrationSuccess,
  editOffer,
  feedDataToCSVBegin,
  feedDataToCSVFail,
  feedDataToCSVSuccess,
  feedDataToPDFBegin,
  feedDataToPDFFail,
  feedDataToPDFSuccess,
  fetchOfferListBegin,
  fetchOfferListFail,
  fetchOfferListSuccess,
  fetchSelectedOfferListBegin,
  fetchSelectedOfferListFail,
  filterBySortBy,
  filterOffersByVehicleCondition,
  generateImagesWithVideoBegin,
  generateImagesWithVideoEnd,
  generateCanvasZipUrlBegin,
  generateCanvasZipUrlFail,
  generateCanvasZipUrlSuccess,
  generateImagesForLauncherPageSuccess,
  generatePDFBegin,
  generatePDFFail,
  generatePDFInvokerFail,
  generatePDFSuccess,
  getPaymentEngineDataBegin,
  getPaymentEngineDataFail,
  getPaymentEngineDataSuccess,
  getJellybeanImagesBegin,
  getJellybeanImagesFail,
  getJellybeanImagesSuccess,
  getProofTemplatesBegin,
  getProofTemplatesFail,
  getProofTemplatesSuccess,
  getWebIntegrationStatusBegin,
  getWebIntegrationStatusFail,
  getWebIntegrationStatusSuccess,
  getWebIntegrationsBegin,
  getWebIntegrationsFail,
  getWebIntegrationsSuccess,
  getWorkfrontFoldersBegin,
  getWorkfrontFoldersFail,
  getWorkfrontFoldersSuccess,
  proofExportDataBegin,
  proofExportDataFail,
  proofExportDataSuccess,
  pushToProofBegin,
  pushToProofFail,
  pushToProofSuccess,
  removeFilterTemplate,
  resetAssetInstanceCounter,
  resetOfferData,
  saveOfferBegin,
  saveOfferFail,
  saveSelectImageSearch,
  saveSelectTemplateSearch,
  setAssetInstanceComparator,
  setEditOfferData,
  setOfferDataComparator,
  setOfferWithDisclosure,
  setSaveDialog,
  switchOfferEditMode,
  toggleOffer,
  toggleOverwriteOfferModal,
  updateAssetInstances,
  updateAssetTypeCount,
  updateCheckedOfferFilters,
  updateExportedAssets,
  updateExportedAssetsArr,
  updateSelectedTab,
  updateSelectedTemplates,
  uploadAssetToWFBegin,
  uploadAssetToWFFail,
  uploadAssetToWFSuccess,
  uploadCanvasImageBegin,
  uploadCanvasImageFail,
  uploadCanvasImageSuccess,
  uploadJellybeanUpdate,
  fetchSelectedOfferListSuccess,
  offerEditsSaved,
  fetchZipUrlBegin,
  fetchZipUrlInProgress,
  fetchZipUrlFail,
  fetchZipUrlSuccess,
  fetchExportStatusBegin,
  fetchExportStatusFail,
  fetchExportStatusSuccess,
  setImageZipExports,
  generatePdfInvoker,
  duplicateOrderStateFail,
  duplicateOrderStateSuccess,
  fetchOrderStateBegin,
  fetchOrderStateFail,
  fetchOrderStateSuccess,
  commitOrderStateClient,
  commitOrderStateFail,
  commitOrderStateSuccess,
  disableExport,
  enableExport,
  resetAssetInstances,
  resetOfferEditsWereSaved,
  resetOfferStates,
  resetPdfUrl,
  setSavedOrder,
  getPaymentEngineDataReset,
  deleteSelectedAssetBuildInstance,
  gatherMissingOfferTypes,
  getRawOfferByVinBegin,
  getRawOfferByVinFail,
  getRawOfferByVinSuccess,
  insertLifestyleImage,
  setBuildPage,
  setSelectedAssetBuildInstance,
  setVisibilitiesOfInstance,
  triggerAssetInstanceUpdate,
  resetToMasterDataAction,
  updateRawSelectedOfferAction,
  setCreatorsAction,
} = assetBuilderSlice.actions;

export default assetBuilderSlice.reducer;
