import { first, groupBy } from "lodash";
import {
  IAdRule,
  IPanelRule,
} from "screens/adLibrary/adLoad/adLoadDrawer/shared/types";
import { isMixedKey } from "screens/adLibrary/adLoad/utils";
import {
  AdLoadParametersRulesetObject,
  AdLoadRuleQualifier,
  FacebookAdPart,
  IAdLoadParametersRule,
} from "shared/types/adLibrary";
import { dedupe } from "utils/helpers.array";

export const getPanelRulesByPart = (
  adRules: IAdRule[],
  part: FacebookAdPart,
): IPanelRule[] => {
  const allAdsRules = getAllRules(adRules, part, []);

  return allAdsRules.map(rule => {
    const { id, parameter, qualifier, values } = rule;

    return {
      ids: [id ?? ""],
      parameter,
      qualifier,
      values,
      isNew: false,
    };
  });
};

export const getGroupedPanelRulesByPart = (
  adRules: IAdRule[],
  part: FacebookAdPart,
  newRuleIds: string[],
): IPanelRule[] => {
  return [
    ...getMixedPanelRulesByPart(adRules, part, newRuleIds),
    ...getNewPanelRulesByPart(adRules, part, newRuleIds),
  ];
};

const getNewPanelRulesByPart = (
  adRules: IAdRule[],
  part: FacebookAdPart,
  newRuleIds: string[],
): IPanelRule[] => {
  const allAdsRules = adRules
    .map(adRule => adRule.adParts[part].rules)
    .flat()
    .filter(rule => newRuleIds.includes(rule.id ?? ""));

  const groupedRules = groupBy(
    allAdsRules,
    rule => `${rule.parameter}_${rule.qualifier}_${rule.values.join("_")}`,
  );

  return Object.entries(groupedRules).map(([parameter, rules]) => {
    return {
      ids: getRuleIds(rules),
      parameter: first(rules)?.parameter as string,
      qualifier: first(rules)?.qualifier as AdLoadRuleQualifier,
      values: first(rules)?.values as string[],
      isNew: true,
    };
  });
};

const getMixedPanelRulesByPart = (
  adRules: IAdRule[],
  part: FacebookAdPart,
  newRuleIds: string[],
): IPanelRule[] => {
  const allAdsRules = getAllRules(adRules, part, newRuleIds);

  if (allAdsRules.length === 0) {
    return [];
  }

  if (adsHaveSameParameters(adRules, part, newRuleIds)) {
    const groupedRulesByParameters = groupBy(allAdsRules, "parameter");

    return Object.entries(groupedRulesByParameters).map(
      ([parameter, rules]) => {
        return getGroupedRuleByParameter(parameter, rules);
      },
    );
  }

  return [
    {
      ids: getRuleIds(allAdsRules),
      parameter: isMixedKey,
      qualifier: isMixedKey,
      values: [isMixedKey],
      isNew: false,
    },
  ];
};

const adsHaveSameParameters = (
  adRules: IAdRule[],
  part: FacebookAdPart,
  newRuleIds: string[],
): boolean => {
  const allAdsRules = getAllRules(adRules, part, newRuleIds);
  const numberOfRulesByAd = adRules.map(
    adRule =>
      adRule.adParts[part].rules.filter(
        rule => !newRuleIds.includes(rule.id ?? ""),
      ).length,
  );

  const numberOfDifferentRules = getGroupLength(
    groupBy(allAdsRules, "parameter"),
  );

  return numberOfRulesByAd.every(
    numberOfRules => numberOfRules === numberOfDifferentRules,
  );
};

const getGroupedRuleByParameter = (
  parameter: string,
  rules: IAdLoadParametersRule[],
): IPanelRule => {
  const groupedRulesByQualifier = groupBy(rules, "qualifier");
  const areDifferentQualifiers = getGroupLength(groupedRulesByQualifier) > 1;
  const displayedQualifier = areDifferentQualifiers
    ? isMixedKey
    : first(first(Object.values(groupedRulesByQualifier)))?.qualifier ?? "";

  const groupedRulesByValues = groupBy(rules, "values");
  const areDifferentValues = getGroupLength(groupedRulesByValues) > 1;
  const displayedValues = areDifferentValues
    ? [isMixedKey]
    : first(first(Object.values(groupedRulesByValues)))?.values ?? [];

  return {
    ids: getRuleIds(rules),
    parameter: parameter,
    qualifier: displayedQualifier,
    values: displayedValues,
    isNew: false,
  };
};

const getGroupLength = (group: any) => {
  return Object.keys(group).length;
};

const getRuleIds = (rules: IAdLoadParametersRule[]) => {
  return rules.map(rule => rule.id ?? "").filter(dedupe);
};

const getAllRules = (
  adRules: IAdRule[],
  part: FacebookAdPart,
  newRuleIds: string[],
) => {
  return adRules
    .map(adRule => adRule.adParts[part].rules)
    .flat()
    .filter(rule => !newRuleIds.includes(rule.id ?? ""));
};

export const transformRuleSetsInAdRules = (
  ruleSetObj?: AdLoadParametersRulesetObject | null,
): IAdRule[] => {
  return ruleSetObj
    ? Object.entries(ruleSetObj).map(([adId, rulesByPart]): IAdRule => {
        return {
          adId: adId,
          adParts: {
            campaign: {
              rules: rulesByPart?.campaign ?? [],
            },
            adset: {
              rules: rulesByPart?.adset ?? [],
            },
            ad: {
              rules: rulesByPart?.ad ?? [],
            },
          },
        };
      })
    : [];
};

export const hasAnyFilledRule = (rules?: IAdLoadParametersRule[]) => {
  return (
    rules?.some(
      rule =>
        rule.values.length > 0 && rule.qualifier != "" && rule.parameter != "",
    ) || false
  );
};
