/* eslint-disable react/display-name */
import React, { useState } from "react";
import {
  CheckOutlined,
  InfoCircleFilled,
  RollbackOutlined,
} from "@ant-design/icons";
import { Button, Collapse } from "antd";

import AdsToLoadTable from "../shared/components/AdsToLoadTable";
import AdLoadAdStatusBadge from "../shared/components/adsToLoadTable/AdLoadAdStatusBadge";
import TitleRow from "../shared/components/TitleRow";

import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { setAdLoadState } from "redux/adLibrary/adLibrary.slice";

import { useLocation } from "react-router-dom";

import { moveAdsWithStatusToTop, returnAdLoadStatusText } from "./helpers.ad";
import { compareString } from "utils/helpers";

import { buttonColors } from "../shared/constants";
import { loadStepTableFilters } from "../shared/components/adsToLoadTable/constants";

import { AdType } from "screens/adLibrary/facebookUtils/types";
import { IAdLoadStatus, ReviewStepKeys } from "shared/types/adLibrary";
import { AdsToLoadByAccountId, TopStatusRecord } from "../shared/types";

import styles from "./AdsToLoadByAccountCollapse.module.scss";

interface IAdsToLoadByAccountCollapseProps {
  adLoadingStarted?: boolean;
  isRollbackEnabled?: boolean;
  adLoadingCompleted?: boolean;
  adsGroupedByAccount: AdsToLoadByAccountId;
}

const returnCollapseKey = (accountName: string) =>
  accountName.toLowerCase().split(" ").join("-");

const AdsToLoadByAccountCollapse = ({
  adLoadingStarted,
  isRollbackEnabled,
  adLoadingCompleted,
  adsGroupedByAccount,
}: IAdsToLoadByAccountCollapseProps) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const isViewingHistory = location.pathname === "/ad-library/sessions";

  const [topStatuses, setTopStatuses] = useState<TopStatusRecord>(null);

  return (
    <Collapse
      className={styles.Collapse}
      defaultActiveKey={Object.keys(adsGroupedByAccount).map(key =>
        returnCollapseKey(key),
      )}
    >
      {Object.entries(adsGroupedByAccount).map(entry => {
        const accountName = entry[0];
        const adsToLoad = moveAdsWithStatusToTop(
          entry[1],
          topStatuses?.[accountName],
        );
        const successCount = adsToLoad.filter(
          adToLoad => adToLoad.adLoadStatus.status === "success",
        ).length;
        const failureCount = adsToLoad.filter(
          adToLoad => adToLoad.adLoadStatus.status === "error",
        ).length;
        const processedCount = failureCount + successCount;
        const showButton =
          adLoadingStarted ||
          adLoadingCompleted ||
          !!successCount ||
          !!processedCount;

        return (
          <Collapse.Panel
            key={returnCollapseKey(accountName)}
            header={
              <TitleRow
                title="Client name"
                description={accountName}
                utilities={[
                  showButton && (
                    <span
                      style={{ fontSize: 12 }}
                      key="progress-count-span"
                    >{`${processedCount}/${adsToLoad.length} Ads`}</span>
                  ),
                  showButton && (
                    <Button
                      key="loaded-btn"
                      size="small"
                      disabled={!successCount}
                      onClick={e => {
                        e.stopPropagation();
                        setTopStatuses({
                          ...(topStatuses || {}),
                          [accountName]: "success",
                        });
                      }}
                    >
                      <CheckOutlined
                        style={{
                          color: "#52C41A",
                          opacity: !successCount ? 0.25 : undefined,
                        }}
                      />
                      <span>{successCount} Loaded</span>
                    </Button>
                  ),
                  showButton && (
                    <Button
                      key="failure-btn"
                      size="small"
                      disabled={!failureCount}
                      onClick={e => {
                        e.stopPropagation();
                        setTopStatuses({
                          ...(topStatuses || {}),
                          [accountName]: "error",
                        });
                      }}
                    >
                      <InfoCircleFilled
                        style={{
                          color: buttonColors.error,
                          opacity: !failureCount ? 0.25 : undefined,
                        }}
                      />
                      <span>{failureCount} Failed</span>
                    </Button>
                  ),
                  isRollbackEnabled ? (
                    <Button
                      key="rollback-btn"
                      size="small"
                      onClick={e => e.stopPropagation()}
                    >
                      <RollbackOutlined />
                      <span>Rollback</span>
                    </Button>
                  ) : null,
                ]}
              />
            }
          >
            <AdsToLoadTable
              adsToLoad={adsToLoad}
              uniqueColumns={[
                {
                  title: "Load Status",
                  dataIndex: "adLoadStatus",
                  ...loadStepTableFilters.adLoadStatus,
                  sorter: (a, b) =>
                    compareString(a.adLoadStatus.status, b.adLoadStatus.status),
                  render: (adLoadStatus: IAdLoadStatus, adToLoad) => (
                    <AdLoadAdStatusBadge
                      status={adLoadStatus.status}
                      onErrorClick={
                        !isViewingHistory &&
                        adToLoad.ad.type === AdType.Collection
                          ? () => {
                              dispatch(
                                setAdLoadState({
                                  prop: "currentInnerStep",
                                  data: ReviewStepKeys.CONVERT_TO_VIDEO,
                                }),
                              );
                            }
                          : undefined
                      }
                      facebookError={adLoadStatus.facebookError}
                      description={returnAdLoadStatusText(adLoadStatus)}
                    />
                  ),
                },
              ]}
              selectedColumnKeys={[
                "Ad Name",
                "Ad Set Name",
                "Campaign Name",
                "Format",
              ]}
              scrollOffset={320}
            />
          </Collapse.Panel>
        );
      })}
    </Collapse>
  );
};

export default AdsToLoadByAccountCollapse;
