import { Button, Tag } from "antd";
import { useQPSearch } from "../useQPSearch";
import { useTags } from "./useTags";

import styles from "./MediaFilterTags.module.scss";

export const MediaFilterTags = () => {
  const { resetSearchState } = useQPSearch();
  const { tags, removeTag } = useTags();

  return tags.length ? (
    <div className={styles.host}>
      <strong>Filters</strong>
      <div className={styles.tags}>
        {tags.map(tag => (
          <Tag
            key={`${tag.pos}`}
            color="blue"
            closable
            onClose={ev => {
              ev.preventDefault();
              removeTag(tag.pos);
            }}
          >
            {tag.value}
          </Tag>
        ))}
        <Button type="link" size="small" onClick={resetSearchState}>
          Clear
        </Button>
      </div>
    </div>
  ) : null;
};
