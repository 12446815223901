import { getCAMThumbnail } from "./getCAMThumbnail";

export const getCAMHiResUrl = (asset: WDAsset, removeQueryString = false) => {
  const url = asset.hiResURLRaw ?? getCAMThumbnail(asset, 10).url;
  return removeQueryString ? removeQueryStringFromUrl(url) : url;
};

const removeQueryStringFromUrl = (url: string) => {
  const finalUrl = new URL(url);
  finalUrl.search = "";
  return finalUrl.toString();
};
