/* eslint-disable react/display-name */
import { FC, useRef } from "react";
import "../../review/ReviewPage.scss";

import { CaretRightOutlined } from "@ant-design/icons";

import { Result, Typography, Row, Col, Descriptions, Spin } from "antd";
import GenericError from "shared/errors/GenericError";
import {
  GetPaymentEngineDealDataResult,
  LeaseExplainedUIFieldKeys,
  LeaseExplainedSubsectionHeaders,
  LeaseExplainedSectionDictEntry,
} from "shared/types/paymentEngine";
import {
  returnFeesDetailName,
  returnFieldSubtext,
  returnLeaseExplainedValue,
  returnExtraTopPadding,
} from "utils/helpers.paymentEngine";
import {
  fieldsWithSubtext,
  dynamicUIFieldNames,
  fieldsToBold,
  fieldValsWithBorderBot,
  paddedBottomFields,
  leaseExplainedItemsDictionary,
} from "shared/constants/paymentEngine";
import { IAccount } from "shared/types/accountManagement";

const { Paragraph, Text } = Typography;

interface PaymentEngineLeaseExplainedType {
  getPaymentEngineDataError: GenericError | null;
  rawPaymentEngineData: GetPaymentEngineDealDataResult | null;
  currentDealerData: IAccount | null;
}

const PaymentEngineLeaseExplained: FC<PaymentEngineLeaseExplainedType> = ({
  getPaymentEngineDataError,
  rawPaymentEngineData,
  currentDealerData,
}) => {
  const leftDivRef = useRef<HTMLDivElement | null>(null);

  const storeState = currentDealerData?.state || "<DEALER.STATE>";

  const returnSuggestionParagraph = (text: string) => (
    <Paragraph>
      <CaretRightOutlined className="site-result-demo-error-icon" /> {text}
    </Paragraph>
  );

  const returnFieldNameSpan = (key: LeaseExplainedUIFieldKeys) => {
    let fieldName = <span>{key}</span>;

    if (dynamicUIFieldNames.includes(key)) {
      // Possible TO DO: if the dynamic name does not return, then do not show row
      fieldName = (
        <span>{returnFeesDetailName(key, rawPaymentEngineData)}</span>
      );
    }

    const shouldMakeBold = fieldsToBold.includes(key);

    return (
      <span style={{ fontWeight: shouldMakeBold ? "bold" : "normal" }}>
        {fieldName}
      </span>
    );
  };

  const returnSectionTable = (
    dict: LeaseExplainedSectionDictEntry,
    dictKey: LeaseExplainedSubsectionHeaders,
  ) =>
    Object.keys(dict).map(field => {
      const typedField = field as LeaseExplainedUIFieldKeys;

      const fieldSpan = returnFieldNameSpan(typedField);
      const hasSubtext = fieldsWithSubtext.includes(typedField);
      const hasBorderBot = fieldValsWithBorderBot.includes(typedField);
      const hasBoldText = fieldsToBold.includes(typedField);
      const topPadding = returnExtraTopPadding(typedField, dictKey);
      const addMoreValueWidth = typedField === "Finance at";

      const value = returnLeaseExplainedValue(
        typedField,
        dict,
        rawPaymentEngineData,
      );

      return (
        <div style={{ width: "100%" }} key={field}>
          <Row
            align="bottom"
            style={{
              paddingTop: "0.5em",
              paddingBottom: paddedBottomFields.includes(typedField)
                ? "3.5em"
                : undefined,
              borderBottom: "solid 1px #e9e9e9",
            }}
          >
            <Col
              span={addMoreValueWidth ? 18 : 20}
              style={{
                paddingRight: "1.5em",
                textAlign: "left",
              }}
            >
              <span>
                {fieldSpan}
                <br />
                {hasSubtext && (
                  <Text
                    style={{ fontSize: "10px", color: "rgba(0, 0, 0, 0.65)" }}
                    type="secondary"
                  >
                    {returnFieldSubtext(
                      typedField,
                      storeState,
                      rawPaymentEngineData,
                    )}
                  </Text>
                )}
              </span>
            </Col>
            <Col
              span={addMoreValueWidth ? 6 : 4}
              style={{
                textAlign: "right",
                borderBottom: hasBorderBot ? "solid 1px black" : "none",
                paddingTop: topPadding,
              }}
            >
              <span
                style={{
                  fontWeight: hasBoldText ? "bold" : "normal",
                }}
              >
                {typedField !== "Finance at" && value !== "N/A" ? "$" : ""}
                {value}
              </span>
            </Col>
          </Row>
        </div>
      );
    });

  const leftDivKeys = Object.keys(
    leaseExplainedItemsDictionary,
  ) as LeaseExplainedSubsectionHeaders[];

  const returnSectionKeyDescription = (
    sectionKey: LeaseExplainedSubsectionHeaders,
  ) =>
    sectionKey !== "FEES DUE AT SIGNING" ? (
      sectionKey
    ) : (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>{sectionKey}</Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: 20,
            flex: 1,
          }}
        >
          <Typography>
            Monthly Payment:
            {` ${rawPaymentEngineData?.lease?.monthlyPayment?.amount}`}
          </Typography>
          <Typography>
            Term:{` ${rawPaymentEngineData?.lease?.term} months`}
          </Typography>
        </div>
      </div>
    );

  const returnDescriptionItems = (
    sectionKeys: LeaseExplainedSubsectionHeaders[],
  ) =>
    sectionKeys.map(sectionKey => (
      <Descriptions.Item
        key={`${sectionKey}-descriptions-item`}
        label={returnSectionKeyDescription(sectionKey)}
        span={3}
      >
        {returnSectionTable(
          leaseExplainedItemsDictionary[sectionKey],
          sectionKey,
        )}
      </Descriptions.Item>
    ));

  return (
    <>
      {getPaymentEngineDataError && !rawPaymentEngineData && (
        <Result
          status="error"
          title="Lease Explained Generation Failed"
          subTitle={getPaymentEngineDataError.message}
        >
          <div className="desc">
            <Paragraph>
              <Text strong>Try the following to help resolve the issue:</Text>
            </Paragraph>
            {returnSuggestionParagraph(
              "Verify that the offer data submitted is valid.",
            )}
            {returnSuggestionParagraph(
              "Verify that a deal in your Payment Engine has a matching VIN to this offer",
            )}
          </div>
        </Result>
      )}
      <Spin
        spinning={!rawPaymentEngineData}
        size="large"
        tip="Building Payment Engine Lease Summary..."
      >
        <div
          ref={leftDivRef}
          className={"lease-explained-container"}
          style={{ width: "100%" }}
        >
          <Descriptions layout="vertical" bordered column={3}>
            {returnDescriptionItems(leftDivKeys)}
          </Descriptions>
        </div>
      </Spin>
    </>
  );
};

export default PaymentEngineLeaseExplained;
