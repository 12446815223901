import { useEffect, useState } from "react";

export const useCAMFooter = (id: string) => {
  const [footer, setFooter] = useState<Element | null>(null);

  useEffect(() => {
    setFooter(document.querySelector(`[data-id="${id}"] .ant-drawer-footer`));
  }, [id]);

  return footer;
};
