import API from "services";

import { useMutation } from "react-query";

import {
  ICreateSharedAdReviewReqBody,
  ICreateSharedAdReviewResult,
} from "shared/types/adReview";

const createSharedAdReview = async (
  sharedAdReview: ICreateSharedAdReviewReqBody,
) => {
  const { result, error } = await API.services.adReview.createSharedAdReview(
    sharedAdReview,
  );

  if (error) {
    throw Error(error.message);
  }

  return result;
};

const useMutateSharedAdReview = () => {
  return useMutation<
    ICreateSharedAdReviewResult | null,
    Error,
    ICreateSharedAdReviewReqBody
  >((sharedAdReview: ICreateSharedAdReviewReqBody) =>
    createSharedAdReview(sharedAdReview),
  );
};

export default useMutateSharedAdReview;
