import { returnFilterOptions } from "../shared/components/adsToLoadTable/utils";

import {
  IAdToLoadData,
  IButtonElement,
  IInstantExperience,
  IPhotoElement,
} from "shared/types/adLibrary";
import { flatten } from "lodash";
import { returnIsProductSelectionDisabled } from "../shared/utils";
import {
  isButton,
  isCarousel,
  isFooter,
  isImage,
  isProductSet,
} from "utils/adLibrary.validators";

const getIEFromElementDestination = (
  element: IPhotoElement | IButtonElement,
  instantExperiences: IInstantExperience[],
) => {
  const foundIE = instantExperiences.find(
    ie => ie.id === element.destination?.instantExperienceId,
  );
  return foundIE;
};

export const returnDoesIEHaveProductSet = (
  instantExperienceID: string,
  instantExperiences: IInstantExperience[],
) => {
  const foundIE = instantExperiences.find(ie => ie.id === instantExperienceID);
  return !!foundIE?.body_elements?.find(isProductSet);
};

export const returnNestedIEsWithProductSets = (
  instantExperienceID: string,
  instantExperiences: IInstantExperience[],
  ignoreProductSets = false,
) => {
  const nestedIds = returnNestedProductSetIEIds(
    instantExperienceID,
    instantExperiences,
    ignoreProductSets,
  );
  const nestedIEs = nestedIds.map(
    ieId => instantExperiences.find(ie => ie.id === ieId)!,
  );
  return nestedIEs;
};

/** Returns root IE's elements that have destinations, which lead to IEs that have product sets */
const returnElementsWithNestedProductSets = (
  instantExperienceID: string,
  instantExperiences: IInstantExperience[],
  ignoreProductSets = false,
) => {
  const foundIE = instantExperiences.find(ie => ie.id === instantExperienceID);
  const elementsWithIEDestination = foundIE?.body_elements?.filter(element => {
    if (isImage(element) || isButton(element)) {
      const nestedIE = getIEFromElementDestination(element, instantExperiences);
      return ignoreProductSets
        ? !!nestedIE?.id
        : !!nestedIE?.id &&
            returnDoesIEHaveProductSet(nestedIE.id, instantExperiences);
    }
    if (isCarousel(element) || isFooter(element)) {
      const typedElement = element;
      const children = typedElement.child_elements ?? [];
      const nestedIEs = children
        .map(ele => getIEFromElementDestination(ele, instantExperiences)!)
        .filter(ie => ie);
      const nestedIEsWithProductSets = nestedIEs.filter(ie =>
        ignoreProductSets
          ? !!ie.id
          : !!ie.id && returnDoesIEHaveProductSet(ie.id, instantExperiences),
      );
      return !!nestedIEsWithProductSets.length;
    }
    return false;
  });
  return elementsWithIEDestination ?? [];
};

export const returnIENameFilterOptions = (
  adsToLoad: IAdToLoadData[],
  instantExperiences: IInstantExperience[],
  ignoreProductSets = false,
) =>
  returnFilterOptions(
    instantExperiences
      .filter(ie => {
        const foundInAdToLoad = !!adsToLoad.find(
          adToLoad =>
            adToLoad.ad.visuals?.destination?.instantExperienceId === ie.id,
        );
        const nestedIEsWithProductSets = returnElementsWithNestedProductSets(
          ie.id!,
          instantExperiences,
          ignoreProductSets,
        );
        return foundInAdToLoad && !!nestedIEsWithProductSets?.length;
      })
      .map(ie => ie.name),
  );

type FindIEByNameArgs = {
  ieName: string;
  record: IAdToLoadData;
  instantExperiences: IInstantExperience[];
};

export const findIEByName = ({
  ieName,
  record,
  instantExperiences,
}: FindIEByNameArgs) => {
  const foundIEs = (instantExperiences ?? []).filter(ie => ie.name === ieName);
  const foundIE = foundIEs.find(
    ie => ie.id === record.ad.visuals.destination?.instantExperienceId,
  );
  return foundIE;
};

const returnNestedProductSetIEIds = (
  rootIEId: string,
  instantExperiences: IInstantExperience[],
  ignoreProductSets = false,
) => {
  const elements = returnElementsWithNestedProductSets(
    rootIEId,
    instantExperiences,
    ignoreProductSets,
  );
  return flatten(
    elements.map(element => {
      if (isCarousel(element) || isFooter(element)) {
        return element.child_elements
          .map(childElement => childElement.destination?.instantExperienceId)
          ?.filter((el): el is string => !!el);
      }
      return "destination" in element
        ? [element.destination?.instantExperienceId || ""]
        : [];
    }),
  );
};

export const returnAreNestedProductSetsAssigned = (
  adToLoad: IAdToLoadData,
  instantExperiences: IInstantExperience[],
) => {
  const rootIEId = adToLoad.ad.visuals?.destination?.instantExperienceId;

  if (!rootIEId) return true;

  const ieIDs = returnNestedProductSetIEIds(rootIEId, instantExperiences);

  if (!ieIDs.length) return true;

  const nestedIEIdsWithProductSets = Object.values(
    adToLoad.nestedProductSets ?? {},
  ).filter(nestedProductSetObj => !!nestedProductSetObj.productSet?.id);

  const disabledRoot = returnIsProductSelectionDisabled(
    adToLoad,
    instantExperiences,
  );

  const length = disabledRoot ? ieIDs.length : ieIDs.length + 1;

  return nestedIEIdsWithProductSets.length === length;
};
