import { useCallback, useMemo } from "react";
import { useBrandsAccountsContext } from "screens/brandsAccountsManagement/contexts/BrandsAccountsContext";
import { useDataList } from "shared/components/dataList";
import { QueryClient, useMutation, useQueryClient } from "react-query";
import {
  BrandAccountRecord,
  BrandRecord,
  IDeleteBatchBrandsAccountsResponse,
} from "shared/types/brandsAccounts";
import { getErrorMessage } from "utils/errorMessage";
import API from "services";
import {
  errorNotification,
  successNotification,
} from "shared/components/customNotification/Notification";
import { BRANDS_QUERY_KEY } from "shared/constants/brandsAccounts";
import {
  getAccountsToDelete,
  getBrandsToDelete,
  getUpdatedBrandsOnDelete,
} from "./useDeleteBrandsAccounts.utils";

export const useDeleteBrandsAccounts = (editingTarget?: BrandAccountRecord) => {
  const { selectedAccounts, setSelectedAccounts } = useBrandsAccountsContext();
  const { mutate: deleteBrandsAccounts } = useDeleteMutation();
  const [{ selectedItems }, dataListActions] = useDataList<BrandRecord>();

  const accountsToDelete = useMemo(
    () => getAccountsToDelete(selectedAccounts, selectedItems, editingTarget),
    [selectedAccounts, selectedItems, editingTarget],
  );

  const brandsToDelete = useMemo(
    () => getBrandsToDelete(selectedItems, editingTarget),
    [selectedItems, editingTarget],
  );

  const onDelete = useCallback(
    (deleteTarget?: BrandAccountRecord) => {
      if (deleteTarget) {
        const accountsToDeleteFromTarget = getAccountsToDelete(
          [],
          [],
          deleteTarget,
        );

        const brandsToDeleteFromTarget = getBrandsToDelete([], deleteTarget);

        deleteBrandsAccounts({
          brandNames: brandsToDeleteFromTarget,
          accountNames: accountsToDeleteFromTarget,
          isUndoOperation: false,
        });
      } else {
        deleteBrandsAccounts({
          brandNames: brandsToDelete,
          accountNames: accountsToDelete,
          isUndoOperation: false,
        });
      }

      dataListActions.setSelectedItems([]);
      setSelectedAccounts([]);
    },
    [
      deleteBrandsAccounts,
      setSelectedAccounts,
      dataListActions,
      accountsToDelete,
      brandsToDelete,
    ],
  );

  return { onDelete };
};

interface DeleteData {
  accountNames: string[];
  brandNames: string[];
  isUndoOperation: boolean;
}

export const useDeleteMutation = () => {
  const queryClient = useQueryClient();
  const { updateTargetElementOnDelete } = useBrandsAccountsContext();

  return useMutation<IDeleteBatchBrandsAccountsResponse, Error, DeleteData>(
    ({ accountNames, brandNames, isUndoOperation }) =>
      deleteBrandsAccounts(brandNames, accountNames, isUndoOperation),
    {
      onSettled: (data, error) => {
        if (error || !data) {
          errorNotification({
            messageLabel: "Unable to delete.",
            size: "small",
          });
        } else {
          onSettledSuccess(data, queryClient, updateTargetElementOnDelete);
        }
      },
    },
  );
};

const deleteBrandsAccounts = async (
  brandNames: string[],
  accountNames: string[],
  isUndoOperation: boolean,
) => {
  try {
    const response =
      await API.privServices.brandAccountManagement.deleteBrandsAccounts<IDeleteBatchBrandsAccountsResponse>(
        brandNames,
        accountNames,
        isUndoOperation,
      );
    if (response.error) throw new Error(response.error.message);
    if (!response.result)
      throw new Error("Unable to delete brands and accounts.");

    return response;
  } catch (error) {
    const errMsg = getErrorMessage(error);
    throw new Error(errMsg);
  }
};

const onSettledSuccess = (
  data: IDeleteBatchBrandsAccountsResponse,
  queryClient: QueryClient,
  updateTargetElementOnDelete: (brandsUpdated: BrandRecord[]) => void,
) => {
  const { result } = data;
  const previousBrands = updateQueryState({
    data,
    queryClient,
    isUndoOperation: false,
    updateTargetElementOnDelete,
  });

  const assetsAccountDeleted = result?.movedAccountsUrls ?? 0;
  const assetsBrandDeleted = result?.movedBrandsUrls ?? 0;
  const assetsDeleted = assetsAccountDeleted + assetsBrandDeleted;

  const brandsDeleted = result?.brands.length;
  const accountsDeleted = result?.accounts.length;
  const logosFontsMessage = "logo(s) and font(s) were";
  const logoFontMessage = "logo or font was";

  const brandMessage =
    brandsDeleted === 1
      ? `${result?.brands[0]} Brand was deleted`
      : `${brandsDeleted} Brands were deleted`;

  const accountMessage =
    accountsDeleted === 1
      ? `${result?.accounts[0]} Account was deleted`
      : `${accountsDeleted} Accounts were deleted`;

  const logosMessageAccount =
    assetsAccountDeleted > 1 ? "logos were" : "logo was";

  const assetsDeletedAccountMessage =
    accountsDeleted && assetsAccountDeleted > 0
      ? `. ${assetsAccountDeleted} ${logosMessageAccount} deleted.`
      : "";

  const logosMessageBrand =
    assetsBrandDeleted > 1 ? logosFontsMessage : logoFontMessage;
  const assetsDeletedBrandsMessage =
    brandsDeleted &&
    assetsBrandDeleted > 0 &&
    (!accountsDeleted || accountsDeleted === 0)
      ? `. ${assetsBrandDeleted} ${logosMessageBrand} deleted.`
      : "";

  const accountLabel = accountsDeleted === 1 ? "Account" : "Accounts";
  const assetsMessages =
    assetsDeleted > 1 ? logosFontsMessage : logoFontMessage;
  let messageLabel = "";
  if (brandsDeleted && accountsDeleted) {
    messageLabel = `${brandMessage} along with ${accountsDeleted} ${accountLabel}.${
      assetsDeleted > 0 ? ` ${assetsDeleted} ${assetsMessages} deleted.` : ""
    }`;
  } else if (brandsDeleted) {
    messageLabel = `${brandMessage}${assetsDeletedBrandsMessage}`;
  } else if (accountsDeleted) {
    messageLabel = `${accountMessage}${assetsDeletedAccountMessage}`;
  }

  successNotification({
    messageLabel,
    linkText: "undo",
    dataCy: "undo-delete",
    onClickLink: async () => {
      await deleteBrandsAccounts(
        result?.brands || [],
        result?.accounts || [],
        true,
      );
      updateQueryState({
        data,
        queryClient,
        previousBrands,
        isUndoOperation: true,
        updateTargetElementOnDelete,
      });
    },
    size: "big",
  });
};

type UpdateStateProps = {
  data: IDeleteBatchBrandsAccountsResponse | undefined;
  queryClient: QueryClient;
  previousBrands?: BrandRecord[];
  isUndoOperation: boolean;
  updateTargetElementOnDelete: (brandsUpdated: BrandRecord[]) => void;
};

const updateQueryState = ({
  data,
  queryClient,
  previousBrands,
  isUndoOperation,
  updateTargetElementOnDelete,
}: UpdateStateProps) => {
  let previousBrandsToReturn: BrandRecord[] = [];

  queryClient.setQueryData([BRANDS_QUERY_KEY], (brands?: BrandRecord[]) => {
    if (isUndoOperation) {
      updateTargetElementOnDelete([...(previousBrands ?? [])]);

      return [...(previousBrands ?? [])];
    }

    previousBrandsToReturn = [...(brands ?? [])];

    const brandsUpdated = getUpdatedBrandsOnDelete(data, brands ?? []);
    updateTargetElementOnDelete(brandsUpdated);

    return brandsUpdated;
  });

  return previousBrandsToReturn;
};
