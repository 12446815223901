import { useQuery } from "react-query";
import {
  ApiResponseCampaignPlanner,
  ApiResponseCampaignPlannerInstance,
  CampaignPlanner,
  CampaignPlannerInstanceAd,
} from "screens/campaignManagement/campaignPlanner/types";
import API from "services";
import { queryKeys } from "./queryKeys";
import { IAd } from "shared/types/adLibrary";
import { useMemo } from "react";
import { useCampaignPlannerData } from "screens/adLibrary/shared/hooks/dataListHooks";
import { CampaignAdStatus } from "shared/types/campaignPlanner";
import { unionBy } from "lodash";
import { useFetchAdsWithPlanners } from "screens/adLibrary/shared/hooks/useAds";
import { nonNullable } from "utils/helpers.array";

type Options = {
  enabled?: boolean;
};
export const useFetchCampaignPlanners = ({ enabled }: Options = {}) => {
  return useQuery({
    queryKey: queryKeys.campaignPlanners,
    queryFn: getCampaignPlanners,
    enabled,
  });
};

export const useCampaignPlannersStandalone = () => {
  const planners = useFetchCampaignPlanners({ enabled: true });
  const adsResponse = useFetchAdsWithPlanners({
    includePlanners: true,
  });

  const data = useMemo(
    () => mapPlannerWithAds(planners.data || [], adsResponse.data || []),
    [planners.data, adsResponse.data],
  );

  return { data, planners, ads: adsResponse };
};

export const useCampaignPlanners = () => {
  const { data, planners, ads } = useCampaignPlannersStandalone();
  return useCampaignPlannerData({
    data,
    isLoading: planners.isLoading || ads.isLoading,
    isError: planners.isError || ads.isError,
  });
};

export const getCampaignPlanners = async () => {
  const { result: planners, error } =
    await API.services.campaignPlanner.getCampaignPlanners();

  if (!planners) {
    throw Error(
      error?.message ||
        "Something went wrong while fetching campaign planners.",
    );
  }

  return planners;
};

export const mapPlannerWithAds = (
  planners: ApiResponseCampaignPlanner[],
  ads: IAd[],
): CampaignPlanner[] => {
  if (!ads.length) return [];
  return planners?.map(planner => ({
    ...planner,
    adShells: getPlannerAds(planner.adShellIds, ads),
    instances: planner.instances?.map(instance => ({
      ...instance,
      adShells: getInstanceAds(ads, instance, planner),
    })),
  }));
};

const getPlannerAds = (campaignAds: string[], ads: IAd[]) => {
  return campaignAds
    ?.map(plannerAd => ads.find(ad => ad.id === plannerAd))
    .filter(nonNullable);
};

const getAdsWithSource = (
  adShellIds: string[],
  ads: IAd[],
  source: string,
): Omit<CampaignPlannerInstanceAd, "campaignStatus">[] => {
  return adShellIds
    .map(plannerAd => ads.find(a => a.id === plannerAd))
    .filter(nonNullable)
    .map(ad => ({ ...ad, source }));
};

const getInstanceAds = (
  ads: IAd[],
  instance: ApiResponseCampaignPlannerInstance,
  planner: ApiResponseCampaignPlanner,
): CampaignPlannerInstanceAd[] => {
  const adsWithSource = unionBy(
    getAdsWithSource(planner.adShellIds, ads, planner.name),
    getAdsWithSource(instance.adShellIds, ads, instance.name),
    "id",
  );

  return adsWithSource.map<CampaignPlannerInstanceAd>(ad => ({
    ...ad,
    campaignStatus: instance.adStatuses[ad.id] || CampaignAdStatus.NEVER_LOADED,
  }));
};
