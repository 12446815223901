import { useCallback, useEffect } from "react";
import { useAppSelector } from "shared/hooks/useAppSelector";
import {
  doesAdToLoadHaveQAErrorIssues,
  mapAdsToLoadDataFromFacebookAds,
} from "../../utils";
import { adsToLoadTableColumnNames } from "../../constants";
import { useDispatch } from "react-redux";
import { setAdLoadState } from "redux/adLibrary/adLibrary.slice";
import useFacebookData from "./useFacebookData";
import { toPairs } from "lodash";
import {
  selectAdLoadParameters,
  selectFacebookAdLoadDict,
  selectSelectedAccounts,
  selectSelectedAds,
} from "redux/adLibrary/adLibrary.selectors";

const useBuildAdsToUpdate = () => {
  const dispatch = useDispatch();

  const selectedAccounts = useAppSelector(selectSelectedAccounts);
  const adLoadParameters = useAppSelector(selectAdLoadParameters);
  const selectedAds = useAppSelector(selectSelectedAds);
  const facebookAdLoadDict = useAppSelector(selectFacebookAdLoadDict);

  const {
    instantExperiences,
    loadingData,
    pages,
    productCatalogs,
    productSets,
    selectedStores,
  } = useFacebookData();

  const buildAdsToLoad = useCallback(() => {
    if (loadingData || !facebookAdLoadDict) return;

    const conflictingAdIds = toPairs(adLoadParameters?.conflicts ?? {})
      .map(([adId, conflicts]) => {
        if (conflicts.length > 0) {
          return adId;
        }

        return undefined;
      })
      .filter(Boolean);

    const adsWithouConflicts =
      selectedAds?.filter(ad => !conflictingAdIds.includes(ad.id)) ?? [];

    const initAdsToLoad = mapAdsToLoadDataFromFacebookAds({
      pages: pages ?? [],
      facebookAdLoadDict: facebookAdLoadDict,
      selectedAds: adsWithouConflicts,
      productSets,
      selectedStores,
      productCatalogs: productCatalogs ?? [],
      selectedAccounts: selectedAccounts ?? [],
      instantExperiences,
    });
    const initSelectedRowKeys = initAdsToLoad
      .filter(adToLoad => !doesAdToLoadHaveQAErrorIssues(adToLoad))
      .map(adsToLoad => adsToLoad.key);

    dispatch(
      setAdLoadState({
        prop: "review",
        data: {
          adsToLoad: initAdsToLoad,
          selectedRowKeys: initSelectedRowKeys,
          selectedColumnKeys: adsToLoadTableColumnNames,
        },
      }),
    );
  }, [
    adLoadParameters?.conflicts,
    dispatch,
    facebookAdLoadDict,
    instantExperiences,
    loadingData,
    pages,
    productCatalogs,
    productSets,
    selectedAccounts,
    selectedAds,
    selectedStores,
  ]);

  useEffect(buildAdsToLoad, [buildAdsToLoad]);
};

export default useBuildAdsToUpdate;
