import { Button, Row } from "antd";

type DrawerFooterProps = {
  onClose: () => void;
};

const DrawerFooter = (props: DrawerFooterProps) => {
  return (
    <div className="drawer-footer">
      <Row
        gutter={16}
        align="middle"
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div className="drawer-button-ctn">
          <Button
            data-cy="close-drawer"
            type={"link"}
            className={"drawer-cancel-button"}
            onClick={props.onClose}
          >
            Close
          </Button>
        </div>
      </Row>
    </div>
  );
};

export default DrawerFooter;
