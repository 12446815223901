import { Dispatch, SetStateAction } from "react";
import { Tabs } from "antd";
import { CustomUpload } from "./uploadStep/CustomUpload";
import { ServerUpload } from "./uploadStep/ServerUpload";
import { useAdEngineActions } from "redux/assetExporter/assetExporter.slice";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { RcFile } from "antd/lib/upload";
import { GoogleSheetUpload } from "./uploadStep/GoogleSheetUpload";
import { FeedUploadType } from "shared/types/assetExporter";

type Props = {
  setCustomFile: Dispatch<SetStateAction<RcFile | undefined>>;
  customFile?: RcFile;
};
export const UploadStep = (props: Props) => {
  const { step, latestStep, feedId, drawerMode, frequency, uploadType } =
    useAppSelector(state => state.assetExporter.sourceFeed.feedDrawer);
  const actions = useAdEngineActions();

  const onChange = (key: string) => {
    const typedKey = key as FeedUploadType;

    if (step < latestStep || !!feedId) return;

    actions.setUploadType(typedKey);
  };

  const isEdit = drawerMode === "UPDATE";
  const isServerConfig = !!frequency;
  const isGoogleSheets = uploadType === "googlesheet";

  const hideCustomUpload = isEdit && (isServerConfig || isGoogleSheets);
  const hideServerUpload = isEdit && (isGoogleSheets || !isServerConfig);
  const hideGoogleSheetUpload = isEdit && !isGoogleSheets;

  return (
    <Tabs activeKey={uploadType} onChange={onChange}>
      {!hideCustomUpload && (
        <Tabs.TabPane tab="Upload CSV" key="custom">
          <CustomUpload {...props} />
        </Tabs.TabPane>
      )}
      {!hideServerUpload && (
        <Tabs.TabPane tab="Upload From Server" key="server">
          <ServerUpload />
        </Tabs.TabPane>
      )}
      {!hideGoogleSheetUpload && (
        <Tabs.TabPane tab="Google Spreadsheet" key="googlesheet">
          <GoogleSheetUpload />
        </Tabs.TabPane>
      )}
    </Tabs>
  );
};
