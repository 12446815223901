import { CampaignPlanner, CampaignPlannerInstance } from "../types";
import { Key, useCallback, useMemo } from "react";
import { getColumns } from "./dataList/columns";
import { TEMP_ROW_ID, useCampaignPlanner } from "../CampaignPlannerContext";
import produce from "immer";
import { useCampaignPlannerIds } from "screens/adLibrary/shared/hooks/dataListHooks";
import {
  buildDeletePlannerInstanceMessage,
  createPlannerInstanceObject,
  getEditableColumns,
} from "../utils";
import styles from "./CampaignPlannerInstanceRowTable.module.scss";
import { HoverActionsVirtualTable } from "shared/components/HoverActionsVirtualTable/HoverActionsVirtualTable";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { TableActionsContextProvider } from "shared/components/HoverActionsVirtualTable/TableActionsContext";
import { ContextTableActions } from "shared/components/tableActions/ContextTableActions";
import { InlineTableActions } from "shared/components/tableActions/InlineTableActions";
import { ToolbarButton } from "shared/components/toolbarTable/ToolbarTable";
import { useNavigate } from "react-router-dom";
import { CopyOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Modal } from "antd";

type Props = {
  planner: CampaignPlanner;
};
export const CampaignPlannerInstanceRowTable = ({ planner }: Props) => {
  const {
    newInstance,
    onInstanceEditEnd,
    selectedInstances,
    setSelectedInstances,
    copyInstance,
    deleteSingleInstance,
  } = useCampaignPlanner();

  const { selectedIds } = useCampaignPlannerIds();
  const { windowInnerHeight } = useWindowSize();
  const navigate = useNavigate();

  const onEditEnd = useCallback(
    (instance: CampaignPlannerInstance) =>
      onInstanceEditEnd(instance, planner.id),
    [onInstanceEditEnd, planner.id],
  );
  const { columns: editableColumns, components } = useMemo(
    () =>
      getEditableColumns<CampaignPlannerInstance>(
        getColumns(onEditEnd, planner),
        onEditEnd,
      ),
    [onEditEnd, planner],
  );

  const dataSource = useMemo(() => {
    const tempInstance = newInstance?.isCopy
      ? createPlannerInstanceObject({
          ...newInstance?.instance,
          id: TEMP_ROW_ID,
        })
      : createPlannerInstanceObject();
    return newInstance?.plannerId == planner.id
      ? [tempInstance, ...planner.instances]
      : planner.instances;
  }, [newInstance, planner]);

  const onRowSelectionChange = (keys: Key[]) => {
    // if the parent campaign planner is selected, all instances must be selected
    if (selectedIds.includes(planner.id)) return;
    setSelectedInstances(
      produce<Record<string, string[]>>(instances => {
        instances[planner.id] = keys.map(key => key.toString());
        return instances;
      }),
    );
  };

  const selectedRowKeys = useMemo(
    () => selectedInstances[planner.id] || [],
    [selectedInstances, planner],
  );

  const generateTableActions = (
    record?: CampaignPlannerInstance,
  ): ToolbarButton => ({
    Edit: {
      disabled: false,
      icon: <EditOutlined />,
      onClick: () => navigate(`${planner.id}/instance/${record?.id}`),
      showInContextMenu: true,
      showInInlineMenu: true,
      display: true,
    },
    Duplicate: {
      disabled: !record,
      icon: <CopyOutlined />,
      onClick: () => {
        if (!record) return;
        copyInstance(planner.id, record?.id);
      },
      showInContextMenu: true,
      showInInlineMenu: true,
      display: true,
    },
    Delete: {
      disabled: !record,
      icon: <DeleteOutlined />,
      onClick: () => {
        if (!record) return;

        Modal.confirm({
          ...buildDeletePlannerInstanceMessage(0, 1),
          onOk: () => deleteSingleInstance(planner.id, record?.id),
        });
      },
      showInContextMenu: true,
      showInInlineMenu: true,
      display: true,
    },
  });

  return (
    <TableActionsContextProvider
      renderContextActions={(record?: CampaignPlannerInstance) => (
        <ContextTableActions
          record={record}
          getItemsToRender={generateTableActions}
        />
      )}
      renderHoverActions={(record?: CampaignPlannerInstance) => (
        <InlineTableActions
          record={record}
          getItemsToRender={generateTableActions}
        />
      )}
    >
      <HoverActionsVirtualTable<CampaignPlannerInstance>
        columns={editableColumns}
        dataSource={dataSource}
        className={styles.table}
        rowClassName={styles.row}
        components={components}
        rowSelection={{
          selectedRowKeys,
          onChange: onRowSelectionChange,
        }}
        showHeader={false}
        pagination={false}
        rowKey={record => record.id}
        size="small"
        scroll={{ y: windowInnerHeight - 245 }}
        isNested
      ></HoverActionsVirtualTable>
    </TableActionsContextProvider>
  );
};
