import { Typography, Divider, Space } from "antd";
import {
  getCategoryName,
  getAssetType,
  isTemplate,
  getTemplateType,
} from "../../../helpers";
import { BaseDesignStudioItemCardProps } from "../DesignStudioItemCard";
import styles from "../DesignStudioItemCard.module.scss";

export const CardInfo = ({ item }: BaseDesignStudioItemCardProps) => {
  const isTemplateItem = isTemplate(item);
  return (
    <Space className={styles.details} align="baseline">
      <Typography.Text className={styles.details}>
        {getCategoryName(item)}
      </Typography.Text>
      {isTemplateItem && (
        <>
          <Divider type="vertical" className={styles.divider} />
          <Typography.Text className={styles.details}>
            {getAssetType(item)}
          </Typography.Text>
        </>
      )}

      {isTemplateItem && (
        <>
          <Divider type="vertical" className={styles.divider} />
          <Typography.Text className={styles.details}>
            {getTemplateType(item)}
          </Typography.Text>
        </>
      )}
    </Space>
  );
};
