import { OfferData } from "shared/types/assetBuilder";
import { formSchema } from "screens/assetBuilder/offers/select/offerForm/schema";
import {
  GetPaymentEngineDealDataResult,
  FeedOfferKeys,
  DealType,
  PaymentEngineRequestBody,
  PaymentEngineFirstTierRequestBodyProp,
  PaymentEngineSecondTierRequestBodyProp,
  UpFrontDefinition,
  LeaseExplainedUIFieldKeys,
  LeaseExplainedSubsectionHeaders,
  LeaseExplainedSectionDictEntry,
  FinanceExplainedUIFieldKeys,
  FinanceExplainedSubsectionHeaders,
  FinanceExplainedSectionDictEntry,
  SecondTierObj,
  ThirdTierObj,
  PaymentEngineOfferDataField,
  ParseableOfferData,
} from "shared/types/paymentEngine";
import {
  feedOfferFieldToPaymentEngineFieldDictionary,
  paymentEngineRequestBodyParamNameDict,
  aggregatedFields,
  financeFieldsToBold,
  formatNumberFields,
} from "shared/constants/paymentEngine";
import { dateFormat } from "shared/constants/dataManagement";
import moment from "moment";
import {
  leaseRebateFieldKeys,
  conditionalRebateFieldKeys,
} from "shared/constants/assetBuilder";
import { DSOfferType } from "screens/assetBuilder/offers/select/paymentEngineModal/types";
import { IAccount } from "shared/types/accountManagement";

// As of AV2-1784: Only certain Lease fields were made usable with PaymentEngine
const dynamicPaymentEngineFields = [
  "leaseVehicleSalesPrice",
  "numberOfPayments",
  "monthlyPayment",
  "milesPerYear",
  "totalDueAtSigning",
  "leaseDownPayment",
  "financeTerm",
  "financePayment",
  "financeDownPayment",
] as Array<PaymentEngineOfferDataField>;

const stringToFloat = (value?: string) =>
  value ? parseFloat(value.replace(",", "") || "0") : 0;

export const encodeZeroDownLease = (field: string) => {
  const fieldName =
    field === "leaseVehicleSalesPrice" ? "vehicleSalesPrice" : field;
  return `zeroDownLease${fieldName.charAt(0).toUpperCase()}${fieldName.slice(
    1,
  )}`;
};

export const decodeZeroDownLease = (zeroDownLeaseField: string) => {
  const field = zeroDownLeaseField.split("zeroDownLease")[1];
  const fieldName = field === "VehicleSalesPrice" ? `Lease${field}` : field;
  return `${fieldName.charAt(0).toLowerCase()}${fieldName.slice(1)}`;
};

export const staticPaymentEngineFields = [
  "capCost",
  "capCostReduction",
  "expirationDate",
  "dealerDiscount",
  "minFICO",
  "terminationFee",
  "totalLeaseCharge",
  "residualValue",
  "securityDeposit",
  "netAdjustedCapitalizedCost",
]
  .concat(leaseRebateFieldKeys)
  .concat(conditionalRebateFieldKeys) as Array<PaymentEngineOfferDataField>;

// The order of elements should be maintained
export const paymentEngineFields = dynamicPaymentEngineFields.concat(
  staticPaymentEngineFields,
) as Array<PaymentEngineOfferDataField>;

export const returnOfferCalculationFieldObjects = (
  offerType: string,
  fieldsArray: Array<PaymentEngineOfferDataField>,
) => {
  const offerFormSchemaObj = formSchema.find(
    formGroup => formGroup.title === offerType,
  );
  if (!offerFormSchemaObj) {
    return [];
  }

  const { header, body } = offerFormSchemaObj;
  const headerFields = header.map(obj => {
    const { fields } = obj;
    const flattenedFields = fields.flat();
    return flattenedFields.map(fieldObj => ({
      field: fieldObj.key,
      title: fieldObj.title,
    }));
  });
  const bodyFields = body.map(obj => {
    const { fields } = obj;
    const flattenedFields = fields.flat();
    return flattenedFields.map(fieldObj => ({
      field: fieldObj.key,
      title: fieldObj.title,
    }));
  });
  const flattenedHeaderFields = headerFields.flat();
  const flattenedBodyFields = bodyFields.flat();
  const fieldObjsToScan = flattenedHeaderFields.concat(flattenedBodyFields);
  const paymentEngineFieldObjs = [];
  // The order of field objs needs to match the fields array
  for (const fieldName of fieldsArray) {
    const matchingFieldObj =
      offerType === "Zero Down Lease"
        ? fieldObjsToScan.find(
            obj => obj.field === encodeZeroDownLease(fieldName),
          )
        : fieldObjsToScan.find(obj => obj.field === fieldName);
    if (!matchingFieldObj) continue;

    paymentEngineFieldObjs.push(matchingFieldObj);
  }

  return paymentEngineFieldObjs;
};

export const mapPaymentEngineDealDataToOfferData = (
  paymentEngineDealData: GetPaymentEngineDealDataResult,
  initialOfferData?: ParseableOfferData,
) => {
  const processedPaymentEngineData = initialOfferData || {
    expirationDate: "",
    dealerDiscount: "",

    // for lease
    leaseVehicleSalesPrice: "",
    minFICO: "",
    monthlyPayment: "",
    totalLeaseCharge: "",
    totalDueAtSigning: "",
    residualValue: "",
    securityDeposit: "",
    numberOfPayments: "",
    netAdjustedCapitalizedCost: "",
    milesPerYear: "",
    terminationFee: "",
    capCost: "",
    capCostReduction: "",
    leaseDownPayment: "",

    // lease rebate fields
    leaseRebate1: "",
    leaseRebate1Disclosure: "",
    leaseRebate1Name: "",
    leaseRebate2: "",
    leaseRebate2Disclosure: "",
    leaseRebate2Name: "",
    leaseRebate3: "",
    leaseRebate3Disclosure: "",
    leaseRebate3Name: "",
    leaseRebate4: "",
    leaseRebate4Disclosure: "",
    leaseRebate4Name: "",
    leaseRebate5: "",
    leaseRebate5Disclosure: "",
    leaseRebate5Name: "",
    leaseRebate6: "",
    leaseRebate6Disclosure: "",
    leaseRebate6Name: "",

    // for loan
    financeMinFICO: "",
    amntFinanced: "",
    financePayment: "",
    financeDownPayment: "",
    financeTerm: "",
    financeRate: "",

    // for purchase
  };

  const usuableFeedOfferFields = dynamicPaymentEngineFields.concat(
    staticPaymentEngineFields,
  ) as Array<PaymentEngineOfferDataField>;
  for (const field of usuableFeedOfferFields) {
    if (field === "totalLeaseCharge") {
      // From PaymentEngine: totalDueAtSigning + (monthlyPayment * term) - monthlyPayment + acquisition fee
      if (!paymentEngineDealData.lease) {
        continue;
      }
      const totalDueAtSigning = parseFloat(
        paymentEngineDealData.lease?.totalDueAtSigning?.amount || "0.00",
      );
      const monthlyPayment = parseFloat(
        paymentEngineDealData.lease?.monthlyPayment?.amount || "0.00",
      );
      const term = parseFloat(paymentEngineDealData.lease?.term || "0");
      const feesDetails = paymentEngineDealData.lease?.fees?.feesDetails || [];
      const acquisitionFee = parseFloat(
        feesDetails.find(
          feesDetail => feesDetail.name === "Lease Acquisition Fee",
        )?.amount || "0.00",
      );

      const totalLeaseCharge =
        totalDueAtSigning +
        monthlyPayment * term -
        monthlyPayment +
        acquisitionFee;

      processedPaymentEngineData.totalLeaseCharge = totalLeaseCharge.toFixed(2);
      continue;
    }

    if (leaseRebateFieldKeys.includes(field)) {
      processedPaymentEngineData[field as FeedOfferKeys] =
        returnRebateValue(
          field as LeaseExplainedUIFieldKeys,
          paymentEngineDealData,
        ) || "";
      continue;
    }
    if (conditionalRebateFieldKeys.includes(field)) {
      processedPaymentEngineData[field as FeedOfferKeys] =
        returnConditionalRebateValue(
          field as LeaseExplainedUIFieldKeys,
          paymentEngineDealData,
        ) || "";
      continue;
    }
    if (field === "capCostReduction") {
      continue;
    }
    const dictionaryEntry =
      feedOfferFieldToPaymentEngineFieldDictionary[field as FeedOfferKeys];

    if (!dictionaryEntry) {
      continue;
    }

    const { firstTierProp, secondTierProp, thirdTierProp } = dictionaryEntry;

    /*
       The tiers allow the scan and traversal of the result object,
       which can contain strings or nested objects in its props
     */

    /*
       If firstTierProp DNE, then the entry can't be mapped
       to the feed offer data
     */
    if (!firstTierProp) {
      continue;
    }

    const firstTierVal = paymentEngineDealData[firstTierProp];
    if (!firstTierVal) {
      continue;
    }

    /*
       If there is no second tier prop, then we have the string
       value to return at the first tier of object nesting
     */
    if (!secondTierProp || ["string", "number"].includes(typeof firstTierVal)) {
      processedPaymentEngineData[field as FeedOfferKeys] =
        firstTierVal.toString();
      continue;
    }

    const secondTierObj = firstTierVal as SecondTierObj;
    const secondTierVal = secondTierObj[secondTierProp];
    if (!secondTierVal) {
      continue;
    }

    /*
       If there is no third tier prop, then we have the string
       value to return at the second tier of object nesting
     */
    if (!thirdTierProp || ["string", "number"].includes(typeof secondTierVal)) {
      processedPaymentEngineData[field as FeedOfferKeys] =
        secondTierVal.toString();
      continue;
    }

    const secondTierObject = secondTierVal as ThirdTierObj;
    const thirdTierVal = secondTierObject[thirdTierProp];

    processedPaymentEngineData[field as FeedOfferKeys] =
      thirdTierVal.toString();
  }

  /* Special props to process */

  // AV2-1784: so far the only date prop is expirationDate
  const expDate = processedPaymentEngineData.expirationDate;
  if (expDate) {
    processedPaymentEngineData.expirationDate =
      moment(expDate).format(dateFormat);
  }

  if (paymentEngineDealData.lease) {
    processedPaymentEngineData.capCostReduction =
      paymentEngineDealData.lease?.capCostReduction ||
      (
        stringToFloat(processedPaymentEngineData.capCost) -
        stringToFloat(processedPaymentEngineData.netAdjustedCapitalizedCost)
      ).toString();
  }

  return processedPaymentEngineData;
};

const stringToNumber = (str: string) =>
  !isNaN(Number(str)) ? Number(str) : undefined;

export const generatePaymentEngineRequestBody = (
  originalOffer: OfferData,
  offerType: DSOfferType,
  offerDataUpdatedFields: OfferData,
  dealerData: IAccount | null,
  dealType?: DealType,
  fields?: Array<FeedOfferKeys>,
) => {
  const vehicleParameters = {
    vin: offerDataUpdatedFields.vin,
  };

  const paymentParameters = {
    dealType,
    excludeTaxesFees: true,
    mileage: stringToNumber(originalOffer.milesPerYear),
    term:
      (dealType === "lease"
        ? stringToNumber(originalOffer.numberOfPayments)
        : stringToNumber(originalOffer.financeTerm)) || undefined,
  };

  if (!fields) {
    return { vehicleParameters } as PaymentEngineRequestBody;
  }

  const requestBody: PaymentEngineRequestBody = {
    originalOffer,
    offerType,
    vehicleParameters,
    paymentParameters,
    dealerData,
  };

  const paramGroupKeys = Object.keys(
    paymentEngineRequestBodyParamNameDict,
  ) as PaymentEngineFirstTierRequestBodyProp[];

  for (const field of fields) {
    if (!offerDataUpdatedFields[field]) {
      continue;
    }

    const tempDecodedField = field.includes("zeroDownLease")
      ? (decodeZeroDownLease(field) as FeedOfferKeys)
      : field;

    if (tempDecodedField === "leaseVehicleSalesPrice") {
      requestBody.vehicleParameters!.price = stringToFloat(
        offerDataUpdatedFields[field],
      );
      continue;
    }

    const dictionaryEntry =
      feedOfferFieldToPaymentEngineFieldDictionary[tempDecodedField];

    const { firstTierProp, secondTierProp } = dictionaryEntry;
    const isObjectParam = [
      "totalDueAtSigning",
      "financeDownPayment",
      "leaseDownPayment",
    ].includes(tempDecodedField);

    const paymentEngineReqProp = isObjectParam
      ? "upFrontDefinition"
      : secondTierProp || firstTierProp;

    if (!paymentEngineReqProp) continue;

    const typedProp =
      paymentEngineReqProp as PaymentEngineSecondTierRequestBodyProp;
    const paramGroup = isObjectParam
      ? "paymentParameters"
      : paramGroupKeys.find(paramGroupKey =>
          paymentEngineRequestBodyParamNameDict[paramGroupKey].includes(
            typedProp,
          ),
        );

    if (!paramGroup) continue;
    const parsedFloat = stringToFloat(offerDataUpdatedFields[field].toString());

    // TO DO: map totalDueAtSigning and down payment fields to upFrontDefinition objs
    let valueToPass: UpFrontDefinition | number = parsedFloat;

    if (isObjectParam) {
      const objectValue: UpFrontDefinition = {
        amount: parsedFloat,
        type: field === "financeDownPayment" ? "down" : "dueAtSigning", // "down" is for possible future implementation of load DealType
      };
      valueToPass = objectValue;
    }

    if (!requestBody[paramGroup]) {
      requestBody[paramGroup] = {
        [paymentEngineReqProp as PaymentEngineSecondTierRequestBodyProp]:
          valueToPass,
      };
    } else {
      requestBody[paramGroup] = {
        ...requestBody[paramGroup],
        [paymentEngineReqProp as PaymentEngineSecondTierRequestBodyProp]:
          valueToPass,
      };
    }
  }

  return requestBody;
};

/* LEASE EXPLAINED HELPERS */

// Possible future TO DO: Split helpers.paymentengine into folder and separate files

export const returnFeesDetailName = (
  key: LeaseExplainedUIFieldKeys,
  rawPaymentEngineData: GetPaymentEngineDealDataResult | null,
) => {
  if (!rawPaymentEngineData?.lease?.fees) {
    return key;
  }
  const { lease } = rawPaymentEngineData;
  const { fees } = lease || { fees: { feesDetails: [] } };
  const { feesDetails } = fees || { feesDetails: [] };
  const match = key.match(/\s\d{1,1}$/g);
  if (!match) {
    return key;
  }
  const index = parseInt(match[0]?.trim() || "1") - 1;
  const fieldName = feesDetails[index]?.name || key;

  return fieldName;
};

// Possible TO DO: Might make a type for the field keys with subtext
export const returnFieldSubtext = (
  key: LeaseExplainedUIFieldKeys,
  storeState: string,
  rawPaymentEngineData: GetPaymentEngineDealDataResult | null,
) => {
  let subtext = "";

  switch (key) {
    case "Finance Charge":
      subtext = `Finance at 0.00% ${storeState} sales tax is excluded unless otherwise indicated`;
      break;
    case "The total due at signing is":
      subtext = `with ${
        parseFloat(rawPaymentEngineData?.lease?.term || "1") - 1
      } remaining monthly payments of $${
        rawPaymentEngineData?.lease?.monthlyPayment?.amount || "0.00"
      }`;
      break;
    default:
      break;
  }

  return subtext;
};

// Possible TO DO: make a type for the calculated keys
const returnLeaseExplainedAggrVal = (
  key: LeaseExplainedUIFieldKeys,
  rawPaymentEngineData: GetPaymentEngineDealDataResult | null,
) => {
  if (!rawPaymentEngineData?.lease) {
    return "N/A";
  }

  const { lease } = rawPaymentEngineData;
  const {
    adjustedCapitalizedCost = "0.00",
    term,
    moneyfactor = { value: "0.00" },
    downPayment = "0.00",
    fees = { feesDetails: [{ amount: "0.00" }] },
    depreciation = "0.00",
    financeCharge = "0.00",
    capitalizedCost = "0.00",
    firstMonthPayment = {
      amount: "0.00",
    },
    capCostReduction: paymentEngineCapCostReduction,
    taxes = {
      dueAtSigningTaxesTotal: "0.00",
    },
  } = lease;

  const { value: moneyFactorVal } = moneyfactor;
  const { feesDetails } = fees;

  // AV2-1789: Defined as 0 for now
  const salesTax = 0;

  // only the first two elements are based on the definition of the final calculation
  const feeDetailsSum = feesDetails
    .slice(0, 2)
    .reduce((prev, next) => prev + parseFloat(next?.amount || "0.00"), 0);

  const feesDueAtSigning = parseFloat(downPayment) + feeDetailsSum;
  const totalLeasePayment =
    parseFloat(depreciation) +
    parseFloat(financeCharge) +
    feesDueAtSigning +
    parseFloat(downPayment) +
    salesTax;

  const dueAtSigningTaxesTotal = parseFloat(taxes.dueAtSigningTaxesTotal);
  const totalDueAtSigning =
    feesDueAtSigning +
    parseFloat(firstMonthPayment.amount) +
    dueAtSigningTaxesTotal;
  const calculatedCapCostReduction = paymentEngineCapCostReduction
    ? parseFloat(paymentEngineCapCostReduction)
    : parseFloat(capitalizedCost) - parseFloat(adjustedCapitalizedCost);

  let finalValue: string | number = "";

  switch (key) {
    case "Capitalized Cost Reduction":
      finalValue = calculatedCapCostReduction.toFixed(2).toLocaleString();
      break;
    case "Finance at":
      finalValue = `${term}mo @ ${parseFloat(moneyFactorVal).toFixed(
        5,
      )} money factor`;
      break;
    case "Total Fees Due at Signing":
      finalValue = feesDueAtSigning;
      break;
    case "Fees Due at Signing":
      finalValue = feesDueAtSigning;
      break;
    case "Total Due at Signing":
      finalValue = totalDueAtSigning;
      break;
    case "The total due at signing is":
      finalValue = totalDueAtSigning;
      break;
    case "Rounding":
      finalValue = Math.ceil(totalLeasePayment) - totalLeasePayment;
      break;
    case "The total lease payment is":
      finalValue = Math.ceil(totalLeasePayment);
      break;
    case "Tax on Fees":
      finalValue = Math.ceil(dueAtSigningTaxesTotal);
      break;

    default:
      finalValue = "0.00";
      break;
  }

  if (typeof finalValue === "number" && formatNumberFields.includes(key)) {
    return finalValue.toFixed(2).toLocaleString();
  }

  return finalValue.toString();
};

const returnConditionalRebateValue = (
  key: LeaseExplainedUIFieldKeys,
  rawPaymentEngineData: GetPaymentEngineDealDataResult,
) => {
  const conditionalRebates = (
    rawPaymentEngineData.loan?.rebates?.rebateDetails || []
  ).filter(detail => detail.type === "conditional");
  if (conditionalRebates.length === 0) return "";

  const digitRegex = /\d{1}/g;
  const splitKey = key.toLowerCase().split(/rebate\d{1}/g);
  const rebateField = splitKey[1]?.trim();
  const integerMatch = key.match(digitRegex);
  const index = parseInt(integerMatch?.[0] || "1") - 1;

  if (!digitRegex.test(key)) {
    return "";
  }
  switch (rebateField) {
    case "":
      return conditionalRebates[index]?.amount || "";
    case "disclosure":
      return conditionalRebates[index]?.disclosure || "";

    case "name":
      return conditionalRebates[index]?.name || "";
    default:
      return "";
  }
};

const returnRebateValue = (
  key: LeaseExplainedUIFieldKeys,
  rawPaymentEngineData: GetPaymentEngineDealDataResult,
) => {
  if (!rawPaymentEngineData.lease?.rebates) return "";
  const leaseRebates = rawPaymentEngineData.lease.rebates.rebateDetails.filter(
    detail => detail.type !== "conditional",
  );

  const digitRegex = /\d{1}/g;
  const splitKey = key.toLowerCase().split(/rebate\d{1}/g);
  const rebateField = splitKey[1]?.trim();
  const integerMatch = key.match(digitRegex);
  const index = parseInt(integerMatch?.[0] || "1") - 1;

  if (!digitRegex.test(key)) {
    return "";
  }

  switch (rebateField) {
    case "":
      return leaseRebates[index]?.amount || "";
    case "disclosure":
      return leaseRebates[index]?.disclosure || "";

    case "name":
      return leaseRebates[index]?.name || "";
    default:
      return "";
  }
};

export const returnLeaseExplainedValue = (
  key: LeaseExplainedUIFieldKeys,
  dict: LeaseExplainedSectionDictEntry,
  rawPaymentEngineData: GetPaymentEngineDealDataResult | null,
) => {
  if (!rawPaymentEngineData) {
    return "N/A";
  }

  if (
    leaseRebateFieldKeys.includes(key) &&
    rawPaymentEngineData.lease?.rebates
  ) {
    return returnRebateValue(key, rawPaymentEngineData);
  }

  if (aggregatedFields.includes(key)) {
    return returnLeaseExplainedAggrVal(key, rawPaymentEngineData);
  }

  const entry = dict[key];

  if (!entry) {
    return "N/A";
  }
  const { firstTierProp, secondTierProp, thirdTierProp } = entry;

  if (!firstTierProp) {
    return "N/A";
  }

  const firstTierVal = rawPaymentEngineData[firstTierProp];
  if (!firstTierVal) {
    return "N/A";
  }

  if (!secondTierProp || ["string", "number"].includes(typeof firstTierVal)) {
    return firstTierVal.toString();
  }

  const secondTierObj = firstTierVal as SecondTierObj;
  const secondTierVal = secondTierObj[secondTierProp];

  if (!secondTierVal) {
    return "N/A";
  }

  if (!thirdTierProp || ["string", "number"].includes(typeof secondTierVal)) {
    return secondTierVal.toString();
  }

  const thirdTierObj = secondTierVal as ThirdTierObj;
  const thirdTierVal = thirdTierObj[thirdTierProp];
  const match = key.match(/\s\d{1,1}$/g);

  if (!thirdTierProp || ["string", "number"].includes(typeof secondTierVal)) {
    return secondTierVal.toString();
  }

  // Some thirdTier props can be arrays of objects
  if (Array.isArray(thirdTierVal) && match) {
    const index = match[0] ? parseInt(match[0].trim()) - 1 : 0;
    return (thirdTierVal[index]?.amount || "0.00") as string;
  }

  return thirdTierVal.toString();
};

export const returnFinanceExplainedValue = (
  key: FinanceExplainedUIFieldKeys,
  dict: FinanceExplainedSectionDictEntry,
  rawPaymentEngineData: GetPaymentEngineDealDataResult | null,
) => {
  if (!rawPaymentEngineData) {
    return "N/A";
  }
  if (key === "Finance at") {
    const { loan } = rawPaymentEngineData;
    const apr = (parseFloat(loan?.apr?.value as string) * 100).toFixed(2);
    return `${loan?.term}mo @ ${apr} APR`;
  }

  const entry = dict[key];

  if (!entry) {
    return "N/A";
  }
  const { firstTierProp, secondTierProp, thirdTierProp } = entry;

  if (!firstTierProp) {
    return "N/A";
  }

  const firstTierVal = rawPaymentEngineData[firstTierProp];
  if (!firstTierVal) {
    return "N/A";
  }

  if (!secondTierProp || ["string", "number"].includes(typeof firstTierVal)) {
    return firstTierVal.toString();
  }

  const secondTierObj = firstTierVal as SecondTierObj;
  const secondTierVal = secondTierObj[secondTierProp];

  if (!secondTierVal) {
    return "N/A";
  }

  if (!thirdTierProp || ["string", "number"].includes(typeof secondTierVal)) {
    return secondTierVal.toString();
  }

  const thirdTierObj = secondTierVal as ThirdTierObj;
  const thirdTierVal = thirdTierObj[thirdTierProp];
  const match = key.match(/\s\d{1,1}$/g);

  if (!thirdTierProp || ["string", "number"].includes(typeof secondTierVal)) {
    return secondTierVal.toString();
  }

  if (Array.isArray(thirdTierVal) && match) {
    const index = match[0] ? parseInt(match[0].trim()) - 1 : 0;
    return (thirdTierVal[index]?.amount || "0.00") as string;
  }

  return thirdTierVal.toString();
};

export const shouldMakeValueBoldFinance = (
  key: FinanceExplainedUIFieldKeys,
  dictKey: FinanceExplainedSubsectionHeaders,
) => {
  if (dictKey !== "CAPITAL" && key === "Amount Financed") return false;
  return financeFieldsToBold.includes(key);
};

export const shouldDrawBorderBotFinance = (
  key: FinanceExplainedUIFieldKeys,
  dictKey: FinanceExplainedSubsectionHeaders,
) => {
  if (dictKey === "CAPITAL" && key === "Finance Cash") return true;
  return false;
};

export const returnExtraTopPadding = (
  key: LeaseExplainedUIFieldKeys,
  dictKey: LeaseExplainedSubsectionHeaders,
) => {
  if (dictKey === "DEPRECIATION" && key === "Residual Value") return "2em";
};
