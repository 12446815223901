import { createContext, useContext } from "react";

type RenderFunc<T> = (record?: T) => JSX.Element | null;
type TableActionsContextType<T = any> =
  | {
      renderContextActions: RenderFunc<T>;
      renderHoverActions: RenderFunc<T>;
    }
  | undefined;

const Context = createContext<TableActionsContextType>(undefined);

function TableActionsContextProvider<T>(props: {
  children: React.ReactNode;
  renderContextActions: RenderFunc<T>;
  renderHoverActions: RenderFunc<T>;
}) {
  const { children, renderHoverActions, renderContextActions } = props;
  return (
    <Context.Provider value={{ renderContextActions, renderHoverActions }}>
      {children}
    </Context.Provider>
  );
}

function useTableActionsContext<T>() {
  const context = useContext<TableActionsContextType<T>>(Context);
  if (!context) {
    throw new Error(
      "useTableActions must be used within a TableActionsProvider",
    );
  }
  return context;
}

export { TableActionsContextProvider, useTableActionsContext };
