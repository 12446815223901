import { memo, useMemo, useContext } from "react";
import { Button, Checkbox, Divider, Typography } from "antd";
import { CommentOutlined } from "@ant-design/icons";

import AdPreview from "./facebookAdCard/AdPreview";

import uuid from "uuid";

import cardStyles from "shared/components/Card.module.scss";
import fbCardStyles from "./FacebookAdCard.module.scss";
import { QcStatus } from "shared/types/adLibrary";
import { AdPreviewProps } from "./facebookAdCard/types";
import {
  AdReviewMode,
  AdReviewTableEntry,
} from "shared/types/campaignManagement";
import { AdFormat, IFacebookAd } from "screens/adLibrary/facebookUtils/types";
import classNames from "classnames";
import Paragraph from "antd/lib/typography/Paragraph";
import CommentsOpenerWithData from "shared/button/CommentsOpenerWithData";
import QCStatusSelect from "../shared/components/QCStatusSelect";
import { useGetAdPreviewDimensions } from "shared/hooks/adLibrary/adReview/useGetAdPreviewDimensions";
import MiddleEllipsisText from "shared/components/MiddleEllipsisText";
import { AuthContext } from "contexts/AuthContext";
import { extractPrimaryIdFromAdId } from "../shared/utils/helpers.ad";

interface IProps extends AdPreviewProps {
  facebookAds?: IFacebookAd[];
  parentEntry?: AdReviewTableEntry | undefined;
  commentId?: string;
  mode?: AdReviewMode;
  selectedKeys?: string[];
  // This field is used to render the components in storybook
  forcePreviewFetch?: boolean;
  hideHeaders?: boolean;
}

interface IHandlers {
  onQcStatusChange?: (status: QcStatus) => void;
  setShowDetails?: (showPreview: boolean) => void;
  setSelectedEntry?: (id: string | undefined) => void;
  onSelectedKeysChange?: (checked: boolean, id?: string) => void;
}

export type FacebookAdCardProps = IProps & IHandlers;

const FacebookAdCard = ({
  facebookAds,
  parentEntry,
  facebookAd,
  selectedKeys,
  hideHeaders,
  format = AdFormat.MOBILE_FEED_STANDARD,
  onSelectedKeysChange,
  setSelectedEntry,
  setShowDetails,
}: FacebookAdCardProps) => {
  const { authorized } = useContext(AuthContext);

  const { name, id = uuid() } = facebookAd || {};
  const isSelected = selectedKeys?.includes(id);

  const adPrimaryId = useMemo(
    () => (facebookAd.id ? extractPrimaryIdFromAdId(facebookAd.id) : undefined),
    [facebookAd.id],
  );
  const { dimensions, isLoadingFirstTime: isLoadingDimensionsFirstTime } =
    useGetAdPreviewDimensions(adPrimaryId, format);

  const ids = parentEntry?.id.split("_");
  const selectedFacebookAds = facebookAds?.filter(
    ad => ad.id && ids?.includes(ad.id),
  );

  const adPreviewData =
    format === AdFormat.MOBILE_FEED_STANDARD
      ? facebookAd.mobilePreview?.data[0].body
      : facebookAd.desktopPreview?.data[0].body;

  const handleOpenDetails = () => {
    setSelectedEntry?.(facebookAd.id);
    setShowDetails?.(true);
  };

  return (
    <div
      data-cy="facebook-ad-card"
      className={cardStyles.card}
      style={{
        ...(dimensions || {}),
        maxHeight: "fit-content",
        height: "fit-content",
        backgroundColor: "#ffffff",
      }}
    >
      {!hideHeaders && (
        <>
          <div className={fbCardStyles.cardHeaderDiv}>
            <Checkbox
              checked={isSelected}
              onChange={e => onSelectedKeysChange?.(e.target.checked, id)}
            />

            {authorized && (
              <>
                <div className={fbCardStyles.status}>
                  {facebookAd && parentEntry && selectedFacebookAds && (
                    <>
                      <QCStatusSelect record={parentEntry} align="right" />
                      <Divider type="vertical" />
                    </>
                  )}
                </div>

                <div className={fbCardStyles.comments}>
                  <CommentsOpenerWithData
                    id={parentEntry?.id}
                    key="comments"
                    className={fbCardStyles.commentsButton}
                  >
                    <CommentOutlined style={{ fontSize: 16, lineHeight: 0 }} />
                  </CommentsOpenerWithData>
                </div>
              </>
            )}
          </div>
          <div
            className={classNames(
              cardStyles.flexRow,
              fbCardStyles.titleContainer,
            )}
          >
            <Typography data-cy="card-title" className={fbCardStyles.title}>
              {name && (
                <MiddleEllipsisText containerWidth={300} numberOfRows={3}>
                  {name}
                </MiddleEllipsisText>
              )}
            </Typography>
            <div className={fbCardStyles.subtitle}>
              <Button type="primary" onClick={handleOpenDetails}>
                See Ad
              </Button>
              <Paragraph type="secondary" className={fbCardStyles.adFormat}>
                {parentEntry?.adFormat}
              </Paragraph>
            </div>
          </div>
        </>
      )}
      <div style={{ height: "100%" }}>
        <AdPreview
          adType={facebookAd.type}
          dimensions={dimensions}
          isLoadingDimensionsFirstTime={isLoadingDimensionsFirstTime}
          facebookAd={facebookAd}
          preloadedIframe={adPreviewData}
        />
      </div>
    </div>
  );
};

export default memo(FacebookAdCard);
