import { DataNode } from "antd/lib/tree";
import { isString } from "lodash";
import {
  CampaignKey,
  getAdDestFromKey,
} from "screens/adLibrary/adLoadV2/campaignData.utils";
import { useNormalizedCampaignsData } from "screens/adLibrary/adLoadV2/hooks/useNormalizedCampaignsData";
import { useAds } from "screens/adLibrary/shared/hooks/useAds";
import { returnCanAdBeLoadedToCampaign } from "utils/facebook/helpers.campaign";

export const useDisableInvalidCampaigns = (
  treeData: DataNode[],
  selectedAdIds: string[],
): DataNode[] => {
  const { campaignData } = useNormalizedCampaignsData();
  const { originalData: ads } = useAds();
  const selectedAds = ads.filter(ad => selectedAdIds.includes(ad.id));

  return treeData.map(storeNode => {
    const campaignNodes = storeNode.children?.map(campaignNode => {
      if (!isString(campaignNode.key)) return campaignNode;

      const { campaignId } = getAdDestFromKey(campaignNode.key as CampaignKey);
      const campaign = campaignData.campaigns[campaignId];

      if (!campaign) return campaignNode;

      const shouldBeEnabled =
        selectedAds.every(ad =>
          returnCanAdBeLoadedToCampaign(ad, campaign.objective),
        ) && selectedAds.length > 0;

      if (shouldBeEnabled) {
        return campaignNode;
      }

      return {
        ...campaignNode,
        disabled: true,
        children: campaignNode.children?.map(adSetNode => {
          return {
            ...adSetNode,
            disabled: true,
          };
        }),
      };
    });

    return {
      ...storeNode,
      disabled: campaignNodes?.every(campaignNode => campaignNode.disabled),
      children: campaignNodes,
    };
  });
};
