import { useQuery } from "react-query";
import {
  IAssetBuildInstance,
  IOffer,
  IProcessedOfferData,
  IQueryParameters,
  isStamp,
  OfferData,
} from "shared/types/assetBuilder";
import API from "services";
import { IAccount } from "shared/types/accountManagement";
import GenericError from "shared/errors/GenericError";
import {
  IStateDisclosureElement,
  IStateDisclosureRecord,
  IStateExceptionElement,
  IStateExceptionRecord,
} from "shared/types/legalLingo";
import { IResponse, IStamp, ITemplate } from "shared/types/designStudio";
import { isEmpty } from "lodash";
import { IConfigurationState } from "shared/types/configuration";
import HttpClient from "services/httpClient";

import * as offerHelpers from "utils/helpers.offer";
import { IBrand } from "shared/types/brandManagement";
import { fetchCanvasJson } from "shared/components/contextAPI/shared/RenderTemplate";
import { OfferType } from "shared/types/shared";
import { INewOrder } from "shared/types/newOrders";
import { isFeatureEnabled } from "utils/helpers";
import { useCallback } from "react";

export type TDataCache = {
  dealer?: IAccount; // key as dealer name
  disclosures?: Record<string, IStateDisclosureElement[]>; // key as state
  exceptions?: Record<string, IStateExceptionElement[]>; // key as state-oem
  numberAtThisPriceOfferList?: Record<string, IOffer[]>;
  oems?: IBrand[];
  canvasJsonMap?: Record<string, string>;
  stamps?: (IStamp & { stampJson?: any })[];
};

type TOfferListResult = {
  total: number;
  offerList: OfferData[];
};

const clientName = process.env.REACT_APP_AV2_CLIENT || "internal";

export default (args: {
  order: INewOrder | null;
  instances: Record<string, Record<string, IAssetBuildInstance[]>>;
  config: IConfigurationState["config"];
  assetType?: string;
}) => {
  const getStatesAndOemsFromDealers = useCallback(
    (dealer: IAccount): Array<{ state: string; oem: string }> => {
      return dealer.dealer_oem
        .split(",")
        .map(oem => ({ state: dealer.state, oem: oem.trim() }));
    },
    [],
  );

  const { data } = useQuery<TDataCache | undefined, GenericError>(
    ["render_template", args.order?.id, args.assetType],
    async () => {
      let cache: TDataCache | undefined;
      const offerListFetchParams: Record<string, IQueryParameters> = {}; // NOTE: here, the key will be stringfied IQueryParameters
      const oemNames: string[] = [];
      const templates: ITemplate[] = [];
      let offerTypes: OfferType[] = [];

      for (const assetType in args.instances) {
        for (const size in args.instances[assetType]) {
          for (const instance of args.instances[assetType][size]) {
            const offerData = instance.selectedOffer?.offerData;

            // for offer list
            const { dealerCode, trim, modelCode, msrp } = offerData || {};
            const updatedMsrp = typeof msrp === "number" ? `${msrp}` : msrp;
            const fetchParams: IQueryParameters = {
              dealerCode: dealerCode || "",
              trim: trim || "",
              modelCode: modelCode || "",
              msrp: updatedMsrp?.replace(",", "") || "",
            };
            const fetchParamsKey = JSON.stringify(fetchParams);
            if (!(fetchParamsKey in offerListFetchParams)) {
              offerListFetchParams[fetchParamsKey] = fetchParams;
            }

            const { make } = offerData || {};
            if (make && !oemNames.includes(make)) {
              oemNames.push(make);
            }

            const { template } = instance;
            if (template && !templates.find(tmp => tmp.id === template.id)) {
              templates.push(template);
            }

            offerTypes = [
              ...new Set([
                ...offerTypes,
                ...((instance.selectedOffer?.offerTypes.filter(
                  offerType => offerType !== "PurchasePlaceholder",
                ) as OfferType[]) || []),
                ...(instance.selectedOffer?.purchaseOptions || []),
              ]),
            ];
          }
        }
      }

      try {
        if (!!args.order && !isEmpty(args.order)) {
          const { result } = await API.services.dealerManagement.getDealer(
            args.order.dealer_name,
          );
          const legalLingoV2Enabled = isFeatureEnabled(
            "ENABLE_LEGAL_LINGO_V2",
            false,
          );

          dealer: {
            if (!result?.dealer) break dealer;
            cache = {
              ...(cache || {}),
              dealer: result.dealer,
            };
          }

          disclosures: {
            if (!cache?.dealer || legalLingoV2Enabled) break disclosures;

            // fetch disclosures with states in dealers
            const states = [
              ...new Set(
                getStatesAndOemsFromDealers(cache.dealer).map(
                  stateAndOem => stateAndOem.state,
                ),
              ),
            ]; // removing duplicates

            const disclosuresResponses = await Promise.all(
              states.map(state =>
                API.services.legalLingo.getStateDisclosuresByState<{
                  result: {
                    stateDisclosures: Array<IStateDisclosureRecord>;
                  };
                  error: GenericError | null;
                }>(state),
              ),
            );

            const disclosures = disclosuresResponses
              .filter(res => !!res.result)
              .reduce<Record<string, IStateDisclosureElement[]>>((acc, res) => {
                const { stateDisclosures } = res.result || {};
                const [disclosureObj] = stateDisclosures || [];
                const { disclosures, state } = disclosureObj || {
                  disclosures: [],
                };

                acc[state] = disclosures;

                return acc;
              }, {});

            cache = {
              ...(cache || {}),
              disclosures,
            };
          }

          exceptions: {
            if (!cache?.dealer || legalLingoV2Enabled) break exceptions;

            const statesAndOems = getStatesAndOemsFromDealers(cache.dealer);
            const exceptionResponses = await Promise.all<
              IResponse<IStateExceptionRecord[]>
            >(
              statesAndOems.map(({ state, oem }) =>
                API.services.legalLingo.getStateExceptionsByStateAndOem(
                  state,
                  oem,
                ),
              ),
            );

            const exceptions = exceptionResponses
              .filter(res => !!res.result)
              .reduce<Record<string, IStateExceptionElement[]>>((acc, res) => {
                const { stateExceptions } = res.result || {};
                const [exceptionObj] = stateExceptions || [];
                const { exceptions, state, oem } = exceptionObj || {
                  exceptions: [],
                };

                acc[`${state}-${oem}`] = exceptions;

                return acc;
              }, {});

            cache = {
              ...(cache || {}),
              exceptions,
            };
          }
        }

        if (!isEmpty(offerListFetchParams)) {
          const responses = await Promise.all(
            Object.keys(offerListFetchParams).map<
              Promise<[string, TOfferListResult]>
            >(async key => {
              const params = offerListFetchParams[key];
              const url = `${args.config?.services.fetchOfferListUrl(params)}`;

              const res = await HttpClient.get<{
                result: TOfferListResult;
              }>(url, {
                cache: "no-cache",
              });

              return [key, res.result];
            }),
          );

          const numberAtThisPriceOfferList = responses.reduce<
            Record<string, IOffer[]>
          >((acc, pair) => {
            const [key, result] = pair;
            acc[key] = result.offerList.map(
              offer =>
                offerHelpers.formatFieldValues(
                  offer as unknown as IProcessedOfferData,
                ) as unknown as IOffer,
              // data?.dealer,// AV2-3653: Lithia/LADTech requested that this be disabled, but will be used later
            );

            return acc;
          }, {});

          cache = {
            ...(cache || {}),
            numberAtThisPriceOfferList,
          };
        }

        if (!isEmpty(oemNames)) {
          const responses = await Promise.all(
            oemNames.map(oem =>
              API.services.oemManagement.getOem<{
                result: { oem: IBrand; error: GenericError };
              }>(oem),
            ),
          );
          const oems = responses
            .filter(res => !!res.result)
            .map(res => res.result.oem);

          cache = {
            ...(cache || {}),
            oems,
          };
        }

        if (!isEmpty(templates)) {
          const canvasJsonList = await Promise.all(
            templates
              .filter(template => !!template.canvasJsonUrl)
              .map(template => ({
                id: clientName !== "nu" ? template.id : template.canvasJsonUrl,
                canvasJsonUrl: template.canvasJsonUrl,
              }))
              .map(async data => {
                const json = await fetchCanvasJson(data.canvasJsonUrl!);
                return {
                  id: data.id,
                  canvasJson: json as any,
                };
              }),
          );

          const canvasJsonMap = canvasJsonList.reduce<Record<string, string>>(
            (acc, data) => {
              if (!data.id || !data.canvasJson) return acc;

              acc[data.id] = data.canvasJson;

              return acc;
            },
            {},
          );

          cache = {
            ...(cache || {}),
            canvasJsonMap,
          };
        }

        if (!isEmpty(cache?.canvasJsonMap)) {
          const stampIds = Object.keys(cache!.canvasJsonMap!)
            .map(templateId => {
              const canvasJson = cache!.canvasJsonMap![templateId];
              const { objects } = canvasJson as any; // toJSON from fabric returns any. So this is probably safe to use.
              if (!objects || !Array.isArray(objects)) return null;

              const ids = objects
                .filter(obj => isStamp(obj))
                .map(obj => obj.customData?.stampId); // for type, refer to IExtendedFabricObject and IStampObjectData
              return ids;
            })
            .filter(id => !!id)
            .flat();

          const responses = await Promise.all(
            // NOTE: Using Set, we make sure we dont make duplicated request with same stamp id.
            //       Same stamp can be used multiple times within template.
            Array.from(new Set(stampIds)).map(stampId =>
              API.services.designStudio.getStampById(stampId),
            ),
          );
          const stamps = responses.reduce((prev, curr) => {
            const stampArray = (curr.result?.stamps || []) as IStamp[];
            return prev.concat(stampArray);
          }, [] as Array<IStamp & { stampJson?: any }>);

          const stampsToFetch: IStamp[] = stamps.filter(
            stmp =>
              offerTypes.includes((stmp.offerType || "") as OfferType) &&
              !stmp.stampJson,
          );

          const returnedStamps = await Promise.all(
            stampsToFetch.map(async stmp => {
              let { stampJsonUrl } = stmp;

              // Handle stamp exception case
              stampJsonUrl =
                stmp?.exceptions?.find(
                  exp =>
                    exp.type === "state" && exp.value === cache?.dealer?.state,
                )?.stampJsonUrl || stampJsonUrl;

              if (!stampJsonUrl) return stmp;

              const response = await fetch(`${stampJsonUrl}`, {
                cache: "no-cache",
              });
              const json = await response.json();

              return {
                ...stmp,
                stampJson: json,
              };
            }),
          );

          cache = {
            ...cache,
            stamps: returnedStamps,
          };
        }
      } catch (err) {
        // fetch will be re-tried in RenderTemplate.tsx
      }
      return cache;
    },
    { refetchOnMount: true, refetchOnWindowFocus: false },
  );

  return data;
};
