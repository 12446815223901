import { useHotkeys } from "react-hotkeys-hook";

export default (args: {
  canvas?: fabric.Canvas;
  onComplete: (deletedObject: fabric.Object) => void;
}) => {
  useHotkeys(
    "backspace,delete",
    () => {
      const { canvas } = args;
      if (!canvas) return;

      const activeObject = canvas.getActiveObject();
      if (!activeObject) return;

      canvas.discardActiveObject();

      if (activeObject.type === "activeSelection") {
        (activeObject as fabric.ActiveSelection).forEachObject(obj => {
          canvas.remove(obj);
        });
      } else {
        canvas.remove(activeObject);
      }

      args.onComplete(activeObject);
    },
    [args.canvas],
  );
};
