import { useSetCurrentSession } from "screens/adLibrary/adLoadV2/hooks/useCurrentSession";
import { AdType } from "screens/adLibrary/facebookUtils/types";
import { raise } from "utils/errorMessage";
import { AllEntryTypes } from "../../AssignDestsTable.types";
import ProductCatalogSelect from "shared/components/select/ProductCatalogSelect";
import { findAdProtoDraft } from "screens/adLibrary/facebookUtils/adProto";

const RecordProductCatalogSelect = ({ record }: { record: AllEntryTypes }) => {
  const { setSession } = useSetCurrentSession();

  if (!("productCatalogId" in record)) {
    return null;
  }

  const onChange = (value: string) => {
    setSession(prevSession => {
      const adProtoDraft = findAdProtoDraft({
        adProtoDrafts: prevSession.data.adProtoDrafts,
        adId: record.adId,
        storeName: record.storeName,
      });

      if (
        adProtoDraft.type !== AdType.InstantExperience &&
        adProtoDraft.type !== AdType.Collection
      ) {
        return;
      }

      const elementDest =
        adProtoDraft.elementDests.find(item => item.elementId === record.id) ??
        raise("ElementDest not found");

      if (elementDest.destType === "productSet") {
        elementDest.productCatalogId = value;
        elementDest.productSetId = undefined;
      }
    });
  };

  return (
    <ProductCatalogSelect value={record.productCatalogId} onChange={onChange} />
  );
};

export default RecordProductCatalogSelect;
