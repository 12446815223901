import { isEqual } from "lodash";
import { IDisclosure, IssueType, IssueValue } from "shared/types/legalLingo";

interface IDisclosureValidationIssue {
  type: IssueType;
  value: IssueValue;
}

export interface IDisclosureValidations {
  name: string;
  hasErrors: boolean;
  description?: IDisclosureValidationIssue;
}

export const getValidationForDisclosureId = (
  name: string,
  validationErrors: IDisclosureValidations[],
) =>
  validationErrors?.find(
    disclosureValidation => disclosureValidation.name === name,
  );

export const hasErrors = (
  name: string,
  validationErrors?: IDisclosureValidations[],
) => !!getValidationForDisclosureId(name, validationErrors ?? [])?.hasErrors;

export const validateDisclosures = (
  mode: "edit" | "new" | "duplicate",
  disclosure: IDisclosure,
  inDisclosures: IDisclosure[],
  keyName?: string,
): boolean => {
  const {
    name: disclosureName,
    id,
    disclosures,
    ...disclosureNoName
  } = disclosure;

  const nameChanged =
    mode === "edit" && !!keyName && disclosureName !== keyName;

  const baseDisclosures =
    mode === "edit"
      ? inDisclosures?.filter(
          ({ name }) => name !== (nameChanged ? keyName : disclosureName),
        )
      : inDisclosures;

  const parsedDisclosures = baseDisclosures.map(
    ({
      id: discardId,
      disclosures: discardDisclosures,
      ...disclosuresNoName
    }) => disclosuresNoName,
  );

  return !Boolean(
    parsedDisclosures.find(record => {
      return (
        (nameChanged && isEqual(record.name, disclosureName)) ||
        (isEqual(record.location, disclosureNoName.location) &&
          isEqual(record.condition, disclosureNoName.condition) &&
          isEqual(record.oem, disclosureNoName.oem) &&
          isEqual(record.store, disclosureNoName.store) &&
          record.vin === disclosureNoName.vin)
      );
    }),
  );
};

export const validateFieldsDisclosure = (disclosure: IDisclosure) => {
  const { name, vin, condition, location, oem, store } = disclosure;

  if (!name?.trim().length) return { error: true, message: "Name is required" };

  if (vin === undefined || vin === null)
    return { error: true, message: "VIN is required" };

  if (condition?.length === 0)
    return { error: true, message: "Condition is required" };

  if (location?.length === 0 && oem?.length === 0 && store?.length === 0)
    return {
      error: true,
      message: "At least one Location, OEM, or Store is required.",
    };

  return { error: false, message: null };
};
