import { BadgeProps } from "antd";
import {
  GoogleAdsCallToAction,
  GoogleAdsFeedStatusType,
  GoogleAdsPmaxStatusType,
  IAssetGroup,
} from "shared/types/adLibrary";
import uuid from "uuid";
import { getErrorsFromAssetGroup } from "./utils";

export const getBadgeStatusProp = (
  value: GoogleAdsFeedStatusType | GoogleAdsPmaxStatusType,
): BadgeProps["color"] => {
  switch (value) {
    case "Draft":
      return "blue";
    case "Deleted from feed":
    case "Never Loaded":
    case "Paused":
      return "lightgrey";
    case "Error":
      return "red";
    case "Up to date":
    case "Loaded":
      return "green";
    default:
      return "yellow";
  }
};

export const generateEmptyAssetGroup = () => ({
  id: uuid.v4(),
  uploads: {
    landscapeImages: [],
    squareImages: [],
    portraitImages: [],
    landscapeLogos: [],
    squareLogos: [],
  },
  multiInputAsset: {
    headlines: ["", "", ""],
    longHeadlines: [""],
    descriptions: ["", ""],
    youtubeVideoUrls: [""],
  },
  callToAction: GoogleAdsCallToAction.AUTOMATED,
});

export const loadAssetGroupToGoogleAdsTooltip = (record: IAssetGroup) =>
  record.feedStatus === "Error" || getErrorsFromAssetGroup(record).length > 0
    ? "Asset groups with errors cannot be loaded."
    : "Load to Google Ads";

export const loadAssetGroupSelectionToGoogleAdsTooltip = (
  selection: IAssetGroup[],
) => {
  const hasError = selection.some(({ feedStatus }) => feedStatus === "Error");

  if (hasError)
    return "Asset groups with errors cannot be loaded. Please deselect them before loading.";

  if (selection.length === 0)
    return "Please select asset groups before loading";

  return "Load selected asset groups to Google Ads";
};
