import { Tooltip } from "antd";
import { FC } from "react";

interface IProps {
  name?: string;
  disabled: boolean;
}

interface IHandlers {
  onCellClick: () => void;
}

const EditCell: FC<IProps & IHandlers> = ({
  name = "",
  onCellClick,
  disabled,
}) => {
  const disabledTooltipMessage =
    "This feature is disabled for this account. Contact your team lead to request access.";
  return (
    <div style={{ cursor: !!onCellClick ? "pointer" : "default" }}>
      <Tooltip
        title={disabled ? disabledTooltipMessage : "Click to edit"}
        placement="topLeft"
      >
        <div
          style={{
            color: disabled ? "inherit" : "#1890ff",
            cursor: disabled ? "not-allowed" : "pointer",
          }}
          onClick={onCellClick}
        >
          {name}
        </div>
      </Tooltip>
    </div>
  );
};

export default EditCell;
