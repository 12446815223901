import useDeepEffect from "shared/hooks/useDeepEffect";

export type TCanvasHistory = {
  history?: any[];
  historyIndex: number;
  historyStatus: "undoing" | "redoing" | "idle" | "reset" | "rendering";
};
/**
 * History will be recorded on the following events
 *  - Object modification (move, resize)
 *  - Object insertion
 *  - Object deletion
 *  - Object copy
 * So if you want to check those, please check Canvas.tsx
 */
export default (args: {
  canvasHistory: TCanvasHistory;
  setShouldBlockFromNavigateAway: (block: boolean) => void;
  onComplete?: (updated: TCanvasHistory) => void;
}) => {
  const { canvasHistory } = args;

  useDeepEffect(() => {
    // NOTE: This useEffect only runs when historyStatus === "idle" and this MUST be the case.
    if (canvasHistory.historyStatus !== "idle") return;

    const { history } = canvasHistory;

    if (!history) {
      args.setShouldBlockFromNavigateAway(false);
      return;
    }

    if (history.length === 1) {
      args.onComplete?.({
        ...args.canvasHistory,
        historyIndex: 0,
        history,
      });
      return;
    }
    args.setShouldBlockFromNavigateAway(true);

    if (history && history.length - 2 === canvasHistory.historyIndex) {
      args.onComplete?.({
        ...args.canvasHistory,
        historyIndex: args.canvasHistory.historyIndex + 1,
      });
    } else {
      args.onComplete?.({
        ...args.canvasHistory,
      });
    }
  }, canvasHistory.history);

  useDeepEffect(() => {
    const { historyStatus } = canvasHistory;
    if (historyStatus === "idle") {
      return;
    }

    args.onComplete?.({
      ...canvasHistory,
    });
  }, [canvasHistory.historyIndex]);
};
