import { memo } from "react";
import EverythingAdIcon from "shared/icons/EverythingAdIcon";
import { AllEntryTypes } from "../AssignValuesTable.types";

type Props = {
  record: AllEntryTypes;
};

const AdNameCell = ({ record }: Props) => {
  const hasThumbnailUrl = "thumbnail" in record && record.thumbnail;
  const hasVideoUrl = "videoUrl" in record && record.videoUrl;
  return (
    <span
      style={{
        marginLeft: "children" in record ? undefined : 24,
      }}
    >
      {hasThumbnailUrl && (
        <img
          src={record.thumbnail}
          width="30"
          height="30"
          style={{
            objectFit: "cover",
          }}
        />
      )}
      {!hasThumbnailUrl && hasVideoUrl && (
        <div
          style={{
            display: "inline-block",
            height: "100%",
            verticalAlign: "middle",
          }}
        >
          <video
            preload="metadata"
            width="30"
            height="30"
            controls={false}
            style={{
              objectFit: "cover",
            }}
          >
            <source src={record.videoUrl + "#t=0"} type="video/mp4" />
          </video>
        </div>
      )}
      {record.type === "EverythingAd" && <EverythingAdIcon />}
      <span style={{ marginLeft: 5, verticalAlign: "middle" }}>
        {record.name}
      </span>
    </span>
  );
};

export default memo(AdNameCell);
