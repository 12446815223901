import { SearchOutlined } from "@ant-design/icons";
import { Typography, notification } from "antd";
import { useUpdateEverythingAdHtmlData } from "shared/hooks/everythingAdHtml/useEverythingAdsHtml";
import { EverythingAdHtml } from "shared/types/adLibrary";
import SingleAccountSelect from "shared/components/SingleAccountSelect";
import { ColumnsType } from "antd/lib/table";
import { useUser } from "shared/hooks/useUser";
import { useFetchInstantExperiences } from "shared/hooks/adLibrary/useFetchInstantExperiences";
import { useFetchInfiniteDealers } from "shared/hooks/useFetchDealers";
import { getEverythingAdWithDests } from "../GenerateUrlForm.utils";
import { terms } from "utils/terms";
import { SlugInput } from "./columns/SlugInput";
import { GtmInput } from "./columns/GtmInput";

export const useGeneratedUrls = () => {
  const { mutate: updateEverythingAdData, isLoading: isUpdateLoading } =
    useUpdateEverythingAdHtmlData();

  const user = useUser();
  const { data: instantExperiences } = useFetchInstantExperiences();
  const { dealers } = useFetchInfiniteDealers();

  const updateEverythingAdWithFeedback = (everythingAd?: EverythingAdHtml) => {
    if (!everythingAd) return;

    const instExp = instantExperiences?.find(
      ie => ie.id === everythingAd.data.id,
    );
    const store = dealers.find(
      dealer => dealer.dealer_name === everythingAd.account,
    );
    if (!instExp || !instantExperiences) return null;
    const updatedEverythingAd: EverythingAdHtml = {
      ...everythingAd,
      updatedAt: new Date().toISOString(),
      updatedBy: user?.email ?? "",
      data: getEverythingAdWithDests({
        instExp,
        instantExperiences,
        urlLabels: store?.labels ?? [],
      }),
    };
    updateEverythingAdData(updatedEverythingAd, {
      onSuccess: () => {
        notification.success({
          message: "Everything Ad Url updated successfully",
        });
      },
      onError: error => {
        notification.error(error);
      },
    });
  };

  const updateEverythingAd = (
    attribute: { [key: string]: string },
    everythingAd: EverythingAdHtml,
  ) => {
    if (!everythingAd) return;
    const updatedEverythingAd = {
      ...everythingAd,
      ...attribute,
    };
    updateEverythingAdWithFeedback(updatedEverythingAd);
  };

  const columns: ColumnsType<EverythingAdHtml> = [
    {
      width: 360,
      fixed: "left",
      dataIndex: "slug",
      title: "Slug",
      key: "slug",
      render: (_, everythingAd) => (
        <SlugInput
          slug={everythingAd.slug}
          everythingAd={everythingAd}
          updateEverythingAd={updateEverythingAdWithFeedback}
        />
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
    },
    {
      width: 200,
      dataIndex: "account",
      title: terms.accountName,
      key: "account",
      render: (_, everythingAd) => (
        <SingleAccountSelect
          onChange={(accountId: string) =>
            updateEverythingAd({ account: accountId }, everythingAd)
          }
          selectedDealer={everythingAd.account}
          style={{
            width: "100%",
          }}
        />
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
    },
    {
      width: 200,
      dataIndex: "gtmId",
      title: "GTM ID",
      key: "gtmId",
      render: (_, everythingAd) => (
        <GtmInput
          gtmId={everythingAd.gtmId}
          everythingAd={everythingAd}
          updateEverythingAd={updateEverythingAd}
        />
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
    },
    {
      width: 300,
      dataIndex: "createdAt",
      title: "Created At",
      key: "createdAt",
      render: (_, everythingAd) => (
        <Typography>{everythingAd.createdAt}</Typography>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
    },
    {
      width: 300,
      dataIndex: "createdBy",
      title: "Created By",
      key: "createdBy",
      render: (_, everythingAd) => (
        <Typography>{everythingAd.createdBy}</Typography>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
    },
    {
      width: 250,
      dataIndex: "updatedAt",
      title: "Updated At",
      key: "updatedAt",
      render: (_, everythingAd) => (
        <Typography>{everythingAd.updatedAt}</Typography>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
    },
    {
      width: 300,
      dataIndex: "updatedBy",
      title: "Updated By",
      key: "updatedBy",
      render: (_, everythingAd) => (
        <Typography>{everythingAd.updatedBy}</Typography>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
    },
  ];

  return { columns, updateEverythingAdWithFeedback, isUpdateLoading };
};

export default useGeneratedUrls;
