import { Collapse } from "antd";
import { useMemo } from "react";
import { getCAMThumbnail } from "../media";
import { MediaLogoSectionHeader } from "./MediaLogoHeaderSection";
import { MediaLogosCollapseTitle } from "./MediaLogosCollapseTitle";
import { MediaOemLogosPanelBody } from "./MediaLogosPanelBody";
import { useFetchLogos } from "./useFetchLogos";
import { withFileOrientation } from "./utils";

type Props = {
  searchPayload?: MGSearch;
  titleText: string;
};

const LogoTypes = {
  Horizontal: "Horizontal",
  Vertical: "Vertical",
  Square: "Square",
};

export const MediaLogos = ({ searchPayload, titleText }: Props) => {
  const orientationPayloads = useMemo(() => {
    if (searchPayload) {
      return {
        horizontal: withFileOrientation(searchPayload, "horizontal"),
        vertical: withFileOrientation(searchPayload, "vertical"),
        square: withFileOrientation(searchPayload, "square"),
      };
    }
  }, [searchPayload]);

  const { isLoading: isLoadingHorizontal, assets: horizontalAssets } =
    useFetchLogos(orientationPayloads?.horizontal);
  const { isLoading: isLoadingVertical, assets: verticalAssets } =
    useFetchLogos(orientationPayloads?.vertical);
  const { isLoading: isLoadingSquare, assets: squareAssets } = useFetchLogos(
    orientationPayloads?.square,
  );

  const isLoading = isLoadingHorizontal || isLoadingVertical || isLoadingSquare;

  const logos = useMemo(() => {
    return {
      [LogoTypes.Horizontal]: horizontalAssets || [],
      [LogoTypes.Vertical]: verticalAssets || [],
      [LogoTypes.Square]: squareAssets || [],
    };
  }, [horizontalAssets, verticalAssets, squareAssets]);

  return (
    <div style={{ marginBottom: "2em" }}>
      <MediaLogosCollapseTitle
        titleText={titleText}
        searchPayload={searchPayload}
        matchingLogos={[
          ...horizontalAssets,
          ...verticalAssets,
          ...squareAssets,
        ]}
        isLoading={isLoading}
      />
      <Collapse expandIconPosition="right">
        {Object.keys(LogoTypes).map(key => {
          const images = logos?.[key]
            .map(getCAMThumbnail)
            .map(({ url }) => url);
          return (
            <Collapse.Panel
              key={key}
              header={
                <MediaLogoSectionHeader
                  title={key}
                  count={isLoading ? "-" : images.length}
                />
              }
            >
              <MediaOemLogosPanelBody images={images} />
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </div>
  );
};
