import { loadInstantExperienceForAd } from "./helpers.ie";
import { getAdPrototypeFromAd } from "screens/adLibrary/facebookUtils/adPreview";
import { returnAdCreative } from "screens/adLibrary/facebookUtils/adPreviewCreative";

import { startCase } from "lodash";
import { isFeatureEnabled } from "utils/helpers";
import {
  AdType,
  IFacebookCampaign,
  CreateAdParameters,
  IFacebookAdCreative,
  FacebookCampaignObjective,
} from "screens/adLibrary/facebookUtils/types";
import {
  IAdToLoadData,
  IAdLoadStatus,
  AdLoadAdStatus,
  IInstantExperience,
  AdLoadAdStatusEnum,
  ReviewStepKeys,
} from "shared/types/adLibrary";
import { IAccount } from "shared/types/accountManagement";

const { CONVERSIONS, PRODUCT_CATALOG_SALES } = FacebookCampaignObjective;

const { AIA, DPA, FTA, Carousel, Collection, InstantExperience, Still, Video } =
  AdType;

type InitSelectedAdsToLoadArgs = {
  adsToLoad: IAdToLoadData[];
  selectedRowKeys: string[];
};

export const initSelectedAdsToLoad = (args: InitSelectedAdsToLoadArgs) =>
  args.adsToLoad
    .filter(adToLoad => args.selectedRowKeys.includes(adToLoad.key))
    .map(adToLoad => ({
      ...adToLoad,
      adLoadStatus:
        !adToLoad.facebookAd?.id &&
        !adToLoad.facebookAd?.creative?.id &&
        adToLoad.adLoadStatus.status !== "error"
          ? {
              ...adToLoad.adLoadStatus,
              status: AdLoadAdStatusEnum.IDLE,
            }
          : adToLoad.adLoadStatus,
    }));

export type AssembleFacebookAdCreativeArgs = {
  adToLoad: IAdToLoadData;
  instantExperiences: IInstantExperience[];
  selectedDealer: IAccount;
  pageId: string;
};

/** Return Facebook ad creative object. If an error is thrown,
 *  error.message is stringified JSON of the facebook error
 */
export const assembleFacebookAdCreative = async (
  args: AssembleFacebookAdCreativeArgs,
) => {
  const { ad, account } = args.adToLoad;
  const formParameters = {
    pageId: args.pageId,
    productSetId: args.adToLoad.productSet?.id,
  };

  const canvasUrl =
    [AdType.InstantExperience, AdType.Collection].includes(ad.type) &&
    ad.visuals.destination?.instantExperienceId
      ? await loadInstantExperienceForAd({
          adToLoad: args.adToLoad,
          meta: {
            instantExperiences: args.instantExperiences,
            selectedDealer: args.selectedDealer,
            pageId: args.pageId,
          },
        })
      : undefined;

  const destination = canvasUrl
    ? { ...ad.visuals.destination, websiteURL: canvasUrl }
    : ad.visuals.destination;

  const adPrototype = await getAdPrototypeFromAd(
    {
      ...ad,
      visuals: { ...ad.visuals, destination },
    },
    account.account_id,
  );

  switch (ad.type) {
    case AIA:
      return returnAdCreative({ adType: AIA, adPrototype, formParameters });
    case DPA:
      return returnAdCreative({ adType: DPA, adPrototype, formParameters });
    case FTA:
      return returnAdCreative({ adType: FTA, adPrototype, formParameters });
    case Still:
      return returnAdCreative({
        adPrototype,
        adType: Still,
        formParameters,
      });
    case Video:
      return returnAdCreative({
        adType: Video,
        formParameters,
        adPrototype,
      });
    case Carousel:
      return returnAdCreative({
        formParameters,
        adType: Carousel,
        adPrototype,
      });
    case InstantExperience:
      return returnAdCreative({
        formParameters,
        adType: InstantExperience,
        adPrototype: {
          ...adPrototype,
          canvasUrl: canvasUrl ?? "",
        },
      });
    case Collection:
      return returnAdCreative({
        formParameters,
        adType: Collection,
        adPrototype: {
          ...adPrototype,
          canvasUrl: canvasUrl ?? "",
        },
      });
  }
};

type GetAdNameArgs = {
  adsetName?: string;
  adFormat?: string;
  name: string;
  concatNameEnabled: boolean;
};

const getAdName = ({
  adsetName,
  adFormat,
  name,
  concatNameEnabled,
}: GetAdNameArgs) => {
  return concatNameEnabled ? `${adsetName}_${adFormat}_${name}` : `${name}`;
};

const adToLoadName = (adToLoad: IAdToLoadData) => {
  const concatNameEnabled = isFeatureEnabled("ENABLE_AD_TO_LOAD_CONCAT_NAME");
  const { adset, ad: adShell } = adToLoad;
  const { type: adFormat, inputParameters } = adShell;
  const name = inputParameters.name || "";
  const adsetName = adset.name;

  return getAdName({ adsetName, adFormat, name, concatNameEnabled });
};

type ReturnCreateAdParamsForAdTypeArgs = {
  pixelId: string;
  adToLoad: IAdToLoadData;
  adCreative: IFacebookAdCreative;
};

export const returnCreateAdParams = (
  args: ReturnCreateAdParamsForAdTypeArgs,
): CreateAdParameters | undefined => {
  const { type, inputParameters, visuals } = args.adToLoad.ad;
  const isDynamic = [AIA, DPA, FTA].includes(type);
  const isCarousel = type === Carousel;
  const destUrl = inputParameters.destinationUrl;
  const destinationUrl = !isCarousel
    ? destUrl
    : visuals?.cards?.[0]?.destinationUrl;
  const displayUrl = isDynamic ? visuals?.displayUrl ?? "" : "";

  const createAdParameters: CreateAdParameters = {
    adset_id: args.adToLoad.adset.id!,
    name: adToLoadName(args.adToLoad),
    status: args.adToLoad.facebookStatus,
    creative: args.adCreative.id
      ? { creative_id: args.adCreative.id }
      : args.adCreative,
    tracking_specs: [
      {
        "action.type": ["offsite_conversion"],
        fb_pixel: [args.pixelId!],
      },
    ],
    conversion_domain: returnConversionDomainUrl({
      destinationUrl: destinationUrl ?? displayUrl,
      campaign: args.adToLoad.campaign,
    }),
  };
  return createAdParameters;
};

type ReturnConversionDomainUrlArgs = {
  destinationUrl: string;
  campaign?: IFacebookCampaign;
};

const returnConversionDomainUrl = ({
  destinationUrl,
  campaign,
}: ReturnConversionDomainUrlArgs) => {
  if (
    !campaign ||
    !destinationUrl ||
    ![CONVERSIONS, PRODUCT_CATALOG_SALES].includes(campaign.objective!)
  ) {
    return undefined;
  }

  const httpsSplit = destinationUrl.split(/(https?):\/\//);
  const wwwSplit = httpsSplit[httpsSplit.length - 1].split(/www./);

  const conversionDomain = wwwSplit[wwwSplit.length - 1] || "";
  const suffixRegex = /.[a-z0-9]{2,}/gi;

  if (conversionDomain.endsWith("/")) {
    return conversionDomain.substr(0, conversionDomain.length - 1);
  }

  const suffixMatches = conversionDomain.match(suffixRegex);

  if (!suffixMatches || suffixMatches.length < 2) {
    return conversionDomain;
  }

  return `${suffixMatches[0]}${suffixMatches[1]}`;
};

export const returnAdLoadStatusText = (adLoadStatus: IAdLoadStatus) => {
  if (adLoadStatus.status === "error" && adLoadStatus.facebookError) {
    return adLoadStatus.facebookError.error_user_title || "What is the error";
  }
  if (adLoadStatus.status === "success") return "Loaded";
  if (adLoadStatus.status !== "idle") return startCase(adLoadStatus.status);
  return "Ready to load";
};

export const moveAdsWithStatusToTop = (
  adsToLoad: IAdToLoadData[],
  status?: AdLoadAdStatus,
) => {
  if (!status) return adsToLoad;
  const topAdsToLoad =
    status !== AdLoadAdStatusEnum.WARNING
      ? adsToLoad.filter(adToLoad => adToLoad.adLoadStatus.status === status)
      : adsToLoad.filter(
          adToLoad =>
            !!adToLoad.adLoadStatus.reviewAndQAIssues?.filter(
              issue =>
                issue.requiredStep === ReviewStepKeys.REVIEW_DESTINATION_URLS,
            ).length,
        );

  const topKeys = topAdsToLoad.map(adToLoad => adToLoad.key);
  const botAdsToLoad = adsToLoad.filter(
    adToLoad => !topKeys.includes(adToLoad.key),
  );
  return topAdsToLoad.concat(botAdsToLoad);
};

export const filterAdsByStatus = (
  adsToLoad: IAdToLoadData[],
  status?: AdLoadAdStatusEnum | null,
) => {
  if (!status) return adsToLoad;
  return adsToLoad.filter(
    adToLoad =>
      !!adToLoad.adLoadStatus.reviewAndQAIssues?.filter(issue =>
        status !== AdLoadAdStatusEnum.WARNING
          ? adToLoad.adLoadStatus.status === status
          : issue.requiredStep === ReviewStepKeys.REVIEW_DESTINATION_URLS,
      ).length,
  );
};
