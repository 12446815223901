import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { queryClient } from "queryClient";
import { AppThunk } from "redux/store";
import GenericError from "shared/errors/GenericError";
import { getErrorMessage, isErrorWithMessage } from "utils/errorMessage";
import API from "../../services";
import {
  ICreateNewOrderResponse,
  IDeleteNewOrderResult,
  IGetWorkFrontDataResponse,
  IGetWorkFrontStoreNameResponse,
  INewOrder,
  INewOrderRecord,
  INewOrderState,
  IUpdateNewOrderResponse,
  IWFData,
  StatusOptions,
} from "../../shared/types/newOrders";
import { delay } from "../../utils/helpers";

const getInitialState = (): INewOrderState => {
  return {
    currentSelectedOrder: {
      key: 0,
      id: "0",
      dealer_name: "",
      createdAt: 0,
      creator_name: "Default User",
      dealer_code: "",
      dealer_oem: "",
      is_archived: false,
      num_assets: 0,
      pushed_WF: false,
      expiresAt: 0,
      wfProjectName: "",
      wfProjectNumber: "",
      actions: "ok",
      wfID: "",
      wfFullProjectName: "",
      parentFileToken: "",
      documentID: "",
      proofUploadData: "",
      selectedInventory: 0,
      totalSelectedOffers: 0,
      selectedOrderOffers: "",
      integrationCount: 0,
      selectTemplateCollapseSearchInput: "",
      selectImageCollapseSearchInput: "",
      coopSubmissionNotice: "",
      totalUsedStamps: 0,
      status: StatusOptions.NO_STATUS,
    },
    newOrdersPaginationKey: "",
    newOrdersMessage: "",
    newOrderError: "",
    wfProjectNames: [],
    storeName: "",
    error: null,
    dealerCode233: "",
    wfStoreName: "",
    orderCreated: false,
    result: null,
  };
};

const newOrdersSlice = createSlice({
  name: "newOrders",
  initialState: getInitialState(),
  reducers: {
    createNewOrderBegin(state) {
      return {
        ...state,
        processingNewOrders: true,
        newOrdersMessage: "",
      };
    },
    createNewOrderFail(state, { payload: error }: PayloadAction<GenericError>) {
      return {
        ...state,
        processingNewOrders: false,
        newOrderError: `failed to create new order`,
        error,
      };
    },
    createNewOrderSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        response: ICreateNewOrderResponse;
        message?: string;
      }>,
    ) {
      return {
        ...state,
        newOrdersMessage: payload.message || "successfully added new order",
        processingNewOrders: false,
        result: payload,
        orderCreated:
          payload.message === "The order has been duplicated" ? false : true,
      };
    },
    deleteNewOrderBegin(state) {
      return {
        ...state,
        processingNewOrders: true,
        newOrdersMessage: "",
      };
    },
    deleteNewOrderFail(state, { payload: error }: PayloadAction<GenericError>) {
      return {
        ...state,
        processingNewOrders: false,
        newOrderError: `failed to delete order`,
        error,
      };
    },
    deleteNewOrderSuccess(
      state,
      { payload: deleteNewOrderResult }: PayloadAction<IDeleteNewOrderResult>,
    ) {
      return {
        ...state,
        newOrdersMessage: "successfully removed the order",
        processingNewOrders: false,
        result: { error: null, result: deleteNewOrderResult },
      };
    },
    updateNewOrderBegin(state) {
      return {
        ...state,
        processingNewOrders: true,
        newOrdersMessage: "",
      };
    },
    updateNewOrderFail(state, { payload: error }: PayloadAction<GenericError>) {
      return {
        ...state,
        processingNewOrders: false,
        newOrderError: `failed to update order`,
        error,
      };
    },
    updateNewOrderSuccess(
      state,
      {
        payload,
      }: PayloadAction<{ response: IUpdateNewOrderResponse; message?: string }>,
    ) {
      const { response, message } = payload;
      return {
        ...state,
        newOrdersMessage: message || "successfully updated the order",
        processingNewOrders: false,
        result: {
          message,
          error: null,
          result: response,
        },
      };
    },
    getWFDataBegin(state) {
      return {
        ...state,
        processingNewOrders: true,
      };
    },
    getWFDataFail(state, { payload: error }: PayloadAction<GenericError>) {
      return {
        ...state,
        processingNewOrders: false,
        newOrderError: `failed to get data from workfront`,
        error,
      };
    },

    getWFDataSuccess(
      state,
      { payload: wfResult }: PayloadAction<IGetWorkFrontDataResponse>,
    ) {
      const { result: innerWFResult } = wfResult;
      const { workFrontArray } = innerWFResult!;
      const wfResultRecords = workFrontArray.map(
        (wfEntry: IWFData, index: number) => ({
          key: index,
          name: wfEntry.name,
          ID: wfEntry.ID,
        }),
      );

      return {
        ...state,
        wfProjectNames: wfResultRecords,
      };
    },
    getWFStoreNameBegin(state) {
      return state;
    },
    getWFStoreNameFail(state, { payload: error }: PayloadAction<GenericError>) {
      return {
        ...state,
        newOrderError: `failed to get data from workfront`,
        error,
      };
    },
    getWFStoreNameSuccess(
      state,
      { payload: wfStoreResult }: PayloadAction<IGetWorkFrontStoreNameResponse>,
    ) {
      const { result: innerWFStoreResult } = wfStoreResult;
      const { dealerCode233, wfStoreName } = innerWFStoreResult!;

      return {
        ...state,
        dealerCode233,
        wfStoreName,
      };
    },
    resetOrderFeedback(state) {
      return {
        ...state,
        newOrdersMessage: "",
        newOrderError: "",
        error: null,
      };
    },
    createNewOrderStateUpdate(state) {
      return {
        ...state,
        orderCreated: false,
        error: null,
      };
    },
    onOrderCreated(state) {
      return state;
    },
    onOrderUpdated(state) {
      return state;
    },
  },
});

export const createNewOrder =
  (inputOrder: INewOrder, message?: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(createNewOrderBegin());

    await delay(500);

    try {
      const newOrder: INewOrder = {
        id: inputOrder.id,
        dealer_name: inputOrder.dealer_name,
        createdAt: inputOrder.createdAt,
        creator_name: inputOrder.creator_name,
        dealer_code: inputOrder.dealer_code,
        dealer_oem: inputOrder.dealer_oem,
        is_archived: inputOrder.is_archived,
        num_assets: inputOrder.num_assets,
        pushed_WF: inputOrder.pushed_WF,
        expiresAt: inputOrder.expiresAt,
        wfProjectName: inputOrder.wfProjectName,
        wfProjectNumber: inputOrder.wfProjectNumber,
        wfID: inputOrder.wfID,
        wfFullProjectName: inputOrder.wfFullProjectName,
        parentFileToken: inputOrder.parentFileToken,
        documentID: inputOrder.documentID,
        proofUploadData: inputOrder.proofUploadData,
        selectTemplateCollapseSearchInput: "",
        selectImageCollapseSearchInput: "",
        selectedInventory: inputOrder.selectedInventory,
        totalSelectedOffers: inputOrder.totalSelectedOffers,
        selectedOrderOffers: inputOrder.selectedOrderOffers,
        integrationCount: inputOrder.integrationCount,
        coopSubmissionNotice: inputOrder.coopSubmissionNotice,
        totalUsedStamps: inputOrder.totalUsedStamps,
      };

      const response =
        await API.privServices.newOrder.createNewOrder<ICreateNewOrderResponse>(
          newOrder,
        );

      queryClient.invalidateQueries("orders");
      const { error } = response;

      if (error) {
        const { message } = error;
        dispatch(
          createNewOrderFail(
            new GenericError({
              message: message || "Some error ocurred.",
            }),
          ),
        );
        return;
      }

      dispatch(createNewOrderSuccess({ response, message }));
    } catch (error) {
      const { message } = error as Error;
      dispatch(
        createNewOrderFail(
          new GenericError({
            message: message || "Some error ocurred.",
          }),
        ),
      );
    }
  };

export const deleteNewOrder =
  (deleteOrder: INewOrderRecord): AppThunk =>
  async (dispatch, getState) => {
    dispatch(deleteNewOrderBegin());

    await delay(500);

    try {
      const newOrder: INewOrder = {
        ...deleteOrder,
      };

      const response =
        await API.privServices.newOrder.deleteNewOrder<IDeleteNewOrderResult>(
          newOrder,
        );

      queryClient.invalidateQueries("orders");

      if (isErrorWithMessage(response)) {
        const message = getErrorMessage(response);
        dispatch(
          deleteNewOrderFail(
            new GenericError({
              message: message || "Some error ocurred.",
            }),
          ),
        );
        return;
      }

      dispatch(deleteNewOrderSuccess(response));
    } catch (error) {
      const { message } = error as Error;
      dispatch(
        deleteNewOrderFail(
          new GenericError({
            message: message || "Some error ocurred.",
          }),
        ),
      );
    }
  };

export const updateNewOrder =
  (updateOrder: Partial<INewOrderRecord>, message?: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(updateNewOrderBegin());

    await delay(500);

    try {
      const newOrder: Partial<INewOrder> = {
        ...updateOrder,
      };

      const response =
        await API.privServices.newOrder.updateNewOrder<IUpdateNewOrderResponse>(
          newOrder,
        );

      queryClient.invalidateQueries("orders");
      const { error } = response;

      if (error) {
        const { message } = error;
        dispatch(
          updateNewOrderFail(
            new GenericError({
              message: message || "Some error ocurred.",
            }),
          ),
        );
        return;
      }

      dispatch(updateNewOrderSuccess({ response, message }));
    } catch (error) {
      const { message } = error as Error;
      dispatch(
        updateNewOrderFail(
          new GenericError({
            message: message || "Some error ocurred.",
          }),
        ),
      );
    }
  };

export const getWFData = (): AppThunk => async (dispatch, getState) => {
  dispatch(getWFDataBegin());

  try {
    const response =
      await API.privServices.newOrder.getWFData<IGetWorkFrontDataResponse>();

    const { error } = response;

    if (error) {
      const { message } = error;
      dispatch(
        getWFDataFail(
          new GenericError({
            message: message || "Some error ocurred.",
          }),
        ),
      );
      return;
    }

    dispatch(getWFDataSuccess(response));
  } catch (error) {
    dispatch(
      getWFDataFail(
        new GenericError({
          message: (error as Error).message || "Some error ocurred.",
          errorObject: error,
        }),
      ),
    );
  }
};

export const getWFStoreName =
  (wfID: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(getWFStoreNameBegin());

    try {
      const response =
        await API.privServices.newOrder.getStoreFromWF<IGetWorkFrontStoreNameResponse>(
          wfID,
        );

      const { error } = response;

      if (error) {
        const { message } = error;
        dispatch(
          getWFStoreNameFail(
            new GenericError({
              message: message || "Some error ocurred.",
            }),
          ),
        );
        return;
      }

      dispatch(getWFStoreNameSuccess(response));
    } catch (error) {
      dispatch(
        getWFStoreNameFail(
          new GenericError({
            message: (error as Error).message || "Some error ocurred.",
            errorObject: error,
          }),
        ),
      );
    }
  };

export const {
  createNewOrderBegin,
  createNewOrderFail,
  createNewOrderStateUpdate,
  createNewOrderSuccess,
  deleteNewOrderBegin,
  deleteNewOrderFail,
  deleteNewOrderSuccess,
  getWFDataBegin,
  getWFDataFail,
  getWFDataSuccess,
  getWFStoreNameBegin,
  getWFStoreNameFail,
  getWFStoreNameSuccess,
  onOrderCreated,
  onOrderUpdated,
  resetOrderFeedback,
  updateNewOrderBegin,
  updateNewOrderFail,
  updateNewOrderSuccess,
} = newOrdersSlice.actions;

export default newOrdersSlice.reducer;
