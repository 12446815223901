import { useMemo } from "react";

import {
  useAppSelector,
  useAppShallowEqualSelector,
} from "shared/hooks/useAppSelector";

import { useFetchInfiniteDealers } from "shared/hooks/useFetchDealers";
import { useFetchFacebookObjects } from "shared/hooks/adLibrary/facebook/useFetchFacebookObject";
import { useFetchInstantExperiences } from "shared/hooks/adLibrary/useFetchInstantExperiences";
import { useFetchProductSetsForMultipleCatalogs } from "shared/hooks/adLibrary/facebook/useFetchProductSets";

import { flatten } from "lodash";

export const useSelectedStores = () => {
  const {
    dealers = [],
    isLoading: loadingDealers,
    isFetching: fetchingDealers,
  } = useFetchInfiniteDealers();

  const selectedAccounts = useAppSelector(
    ({ adLibrary }) => adLibrary.selectedFacebookAccounts,
  );

  const selectedStores = useMemo(
    () =>
      dealers?.filter(dealer =>
        selectedAccounts?.find(account => account.name === dealer.dealer_name),
      ),
    [selectedAccounts, dealers],
  );

  return {
    selectedStores,
    isLoading: loadingDealers || fetchingDealers,
  };
};

export const useSelectedStore = (accountId: string) => {
  const { selectedStores } = useSelectedStores();
  const selectedStore = useMemo(
    () =>
      selectedStores?.find(
        dealer => dealer.details?.facebook.fbAccountId === accountId,
      ),
    [accountId, selectedStores],
  );

  return selectedStore;
};

const useGetRequiredData = () => {
  const {
    currentStep,
    selectedAds,
    currentInnerStep,
    selectedAccounts,
    adLoadDestination,
  } = useAppShallowEqualSelector(({ adLibrary }) => ({
    selectedAds: adLibrary.adLoad.selectedAds,
    currentStep: adLibrary.adLoad.currentStep,
    adLoadDestination: adLibrary.adLoad.destination,
    currentInnerStep: adLibrary.adLoad.currentInnerStep,
    selectedAccounts: adLibrary.selectedFacebookAccounts,
  }));
  const { treeData = [] } = adLoadDestination || {};

  const { selectedStores, isLoading: loadingDealers } = useSelectedStores();
  const adsToLoad = useAppSelector(
    state => state.adLibrary.adLoad.review?.adsToLoad,
  );

  const urlLabels = flatten(selectedStores.map(dealer => dealer.labels ?? []));

  const { data: instantExperiences, isLoading: loadingIEs } =
    useFetchInstantExperiences();

  const { data: pages = [], isLoading: loadingPages } = useFetchFacebookObjects(
    {
      adAccounts: selectedAccounts ?? [],
      objectType: "page",
      skipNameMatching: true,
    },
  );

  const { data: productCatalogs = [], isLoading: loadingCatalogs } =
    useFetchFacebookObjects({
      adAccounts: selectedAccounts ?? [],
      objectType: "catalog",
      skipNameMatching: true,
    });

  const productCatalogIds = adsToLoad
    ?.map(ad => ad.productCatalog?.id ?? "")
    .filter(Boolean);

  const { data: productSets, isLoading: loadingProductSets } =
    useFetchProductSetsForMultipleCatalogs({
      productCatalogIds: productCatalogIds ?? [],
    });
  const loadingData = [
    loadingProductSets,
    loadingCatalogs,
    loadingDealers,
    loadingPages,
    loadingIEs,
  ].includes(true);

  return {
    pages,
    treeData,
    urlLabels,
    productSets,
    loadingData,
    currentStep,
    selectedStores,
    productCatalogIds,
    productCatalogs,
    currentInnerStep,
    instantExperiences,
    selectedAds: selectedAds ?? [],
    selectedAccounts: selectedAccounts ?? [],
  };
};

export default useGetRequiredData;
