import { DesignStudioTable } from "./designStudioLayout/DesignStudioTable";
import {
  DesignStudioTableItem,
  useDesignStudioContext,
} from "./context/DesignStudioDataProvider";
import { DesignStudioCards } from "./designStudioLayout/DesignStudioCards";
import { DataListTagsURL } from "shared/components/dataListURL/DataListTagsURL";
import { DesignStudioFields } from "./context/fields";

export const DesignStudioLayout = () => {
  const { layout, filteredDesignStudioAssets, designStudioAssets } =
    useDesignStudioContext();
  const isTableLayout = layout === "table";

  const Layout = isTableLayout ? DesignStudioTable : DesignStudioCards;

  return (
    <>
      <DataListTagsURL<DesignStudioFields, DesignStudioTableItem>
        data={filteredDesignStudioAssets}
        originalData={designStudioAssets}
      />
      <Layout />
    </>
  );
};
