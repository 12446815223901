import { useMutation, useQueryClient } from "react-query";
import { User } from "redux/auth/auth.slice";
import API from "services";
import {
  IBatchModifyInstantExperiencesResult,
  ICarouselElement,
  IFooterElement,
  IInstantExperience,
  IModifyInstantExperienceResult,
  InstantExperienceBodyElement,
} from "shared/types/adLibrary";
import uuid from "uuid";
import { useUser } from "../useUser";

const modifyInstantExperience =
  (user?: User | null) => async (instantExperience: IInstantExperience) => {
    const instantExperienceUpdate = getInstantExperienceUpdate(
      instantExperience,
      user,
    );
    const operation = instantExperience.id ? "update" : "create";
    const { result, error } =
      await API.services.adLibrary.modifyInstantExperiences(
        instantExperienceUpdate,
        operation,
      );

    if (error) {
      throw Error(error.message);
    }

    return result;
  };

const batchModifyInstantExperiences =
  (user?: User | null) => async (instantExperiences: IInstantExperience[]) => {
    const { result, error } =
      await API.services.adLibrary.batchModifyInstantExperiences(
        instantExperiences.map(instantExperience =>
          getInstantExperienceUpdate(instantExperience, user),
        ),
      );

    if (error) {
      throw Error(error.message);
    }

    return result;
  };

const deleteInstantExperience = async (
  instantExperience: IInstantExperience,
) => {
  const { result, error } =
    await API.services.adLibrary.modifyInstantExperiences(
      instantExperience,
      "delete",
    );

  if (error) {
    throw Error(error.message);
  }

  return result;
};

export const useMutateInstantExperience = () => {
  const queryClient = useQueryClient();
  const user = useUser();

  return useMutation<
    IModifyInstantExperienceResult | null,
    Error,
    IInstantExperience
  >(modifyInstantExperience(user), {
    onSettled: () => {
      queryClient.invalidateQueries("instantExperiences");
    },
  });
};

export const useBatchMutateInstantExperiences = () => {
  const queryClient = useQueryClient();
  const user = useUser();
  const batchMutateInstantExperiences = batchModifyInstantExperiences(user);

  return useMutation<
    IBatchModifyInstantExperiencesResult | null,
    Error,
    IInstantExperience[]
  >(batchMutateInstantExperiences, {
    onSettled: () => {
      queryClient.invalidateQueries("instantExperiences");
    },
  });
};

export const useDeleteInstantExperience = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IModifyInstantExperienceResult | null,
    Error,
    IInstantExperience
  >(deleteInstantExperience, {
    onSettled: () => {
      queryClient.invalidateQueries("instantExperiences");
    },
  });
};

const getInstantExperienceUpdate = (
  instantExperience: IInstantExperience,
  user: User | null | undefined,
): IInstantExperience => {
  const isoDate = new Date().toISOString();
  const instantExperienceUpdate = {
    ...instantExperience,
    id: instantExperience?.id || uuid(),
    createdBy: instantExperience?.createdBy ?? user?.email ?? "",
    createdAt: instantExperience?.createdAt ?? isoDate,
    updatedBy: user?.email ?? "",
    updatedAt: isoDate,
  };
  return instantExperienceUpdate;
};

export const getInstantExpCopy = (
  record: IInstantExperience,
): IInstantExperience => {
  const bodyElementsCopy = getBodyElementsCopy(record.body_elements);

  return {
    ...record,
    body_elements: bodyElementsCopy,
    name: `${record.name} - Copy`,
    id: undefined,
    createdAt: undefined,
    createdBy: undefined,
    updatedAt: undefined,
    updatedBy: undefined,
  };
};

const getBodyElementsCopy = (bodyElements?: InstantExperienceBodyElement[]) => {
  return bodyElements?.map<InstantExperienceBodyElement>(element => {
    if (element.element_type === "CAROUSEL") {
      const carouselElement: ICarouselElement = {
        ...element,
        id: uuid(),
        child_elements: element.child_elements?.map(childElement => ({
          ...childElement,
          id: uuid(),
        })),
      };
      return carouselElement;
    }

    if (element.element_type === "FOOTER") {
      const buttonElement = element.child_elements[0];
      const footerElement: IFooterElement = {
        ...element,
        id: uuid(),
        child_elements: [{ ...buttonElement, id: uuid() }],
      };
      return footerElement;
    }

    return {
      ...element,
      id: uuid(),
    };
  });
};
