import { Badge, Button, Space } from "antd";
import { ReactNode, useEffect, useRef, useState } from "react";
import CommentsModal from "shared/components/CommentsModal";

import { CommentOutlined, LoadingOutlined } from "@ant-design/icons";
import { ControlPosition } from "react-draggable";
import { useLocation } from "react-router-dom";
import { User } from "redux/auth/auth.slice";
import { IComment } from "shared/types/shared";

interface IProps {
  objId?: string;
  loading?: boolean;
  loggedInUser?: User;
  comments?: IComment[];
  children?: ReactNode;
  className?: string;
}
interface IHandlers {
  handleCommentInsert?: (comment: IComment) => void;
  handleCommentDelete?: (comment: IComment) => void;
}

type CommentOpenerProps = IProps & IHandlers;

const CommentsOpener = ({
  objId,
  loading,
  comments,
  loggedInUser,
  children,
  className,
  handleCommentInsert,
  handleCommentDelete,
}: CommentOpenerProps) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const linkFromAlert = searchParams.get("fromAlert") === "true";
  const linkForObj = searchParams.has("id") && searchParams.get("id") === objId;
  const [shownOnLoad, setShownOnLoad] = useState(false);
  // Future TODO: detect how many comments were unread by the user
  const [visible, setVisible] = useState(false);
  const [defaultPosition, setDefaultPosition] = useState<
    ControlPosition | undefined
  >(undefined);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const setModalPositionByButton = () => {
    if (buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();

      const modalHeight = (comments?.length || 0) > 0 ? 670 : 270;
      const modalWidth = 520;

      setDefaultPosition({
        y: buttonRect.y - modalHeight,
        // Divided by two because it need to be centralized
        x: buttonRect.x - modalWidth / 2,
      });
    }
  };

  const handleButtonClick = () => {
    setModalPositionByButton();
    setVisible(true);
  };

  if (linkFromAlert && linkForObj && !shownOnLoad) {
    // Wait for drawer animation to finish
    setTimeout(() => {
      handleButtonClick();
      setShownOnLoad(true);
    }, 1000);
  }

  useEffect(setModalPositionByButton, [comments?.length]);

  return (
    <>
      <CommentsModal
        loading={loading}
        visible={visible}
        comments={comments}
        loggedInUser={loggedInUser}
        defaultPosition={defaultPosition}
        handleCommentInsert={handleCommentInsert}
        handleCommentDelete={handleCommentDelete}
        onCancel={() => setVisible(false)}
      />
      {children ? (
        <button
          className={className}
          aria-label="Show Comments"
          onClick={handleButtonClick}
        >
          <Badge
            count={comments?.length}
            size="small"
            offset={[6, 0]}
            overflowCount={9}
          >
            {children}
          </Badge>
        </button>
      ) : (
        <Button ref={buttonRef} type="link" onClick={() => handleButtonClick()}>
          <Space size={16} align="center">
            <Badge
              count={comments?.length}
              size="small"
              offset={[6, 0]}
              overflowCount={9}
            >
              <CommentOutlined
                style={{ fontSize: 16, lineHeight: 0, color: "#1890ff" }}
              />
            </Badge>
            <Space size={16} align="center">
              Add Comment
              <LoadingOutlined
                style={{ visibility: loading ? "visible" : "hidden" }}
              />
            </Space>
          </Space>
        </Button>
      )}
    </>
  );
};

export default CommentsOpener;
