import { Select } from "antd";
import { memo } from "react";
import { useFetchOems } from "shared/hooks/useFetchOems";
import LoadingContent from "shared/components/select/LoadingContent";

interface IProps {
  value?: string | string[];
  defaultValue?: string | string[];
  onChange?(value: string | string[]): void;
  onFocus?: React.FocusEventHandler<HTMLElement>;
  disabled?: boolean;
  placeholder?: string;
  mode?: "multiple";
  style?: React.CSSProperties;
}

function GenericBrandSelect({
  value,
  defaultValue,
  onChange,
  onFocus,
  disabled,
  placeholder,
  mode,
  style,
}: IProps) {
  const {
    oems,
    isLoading: isLoadingBrands,
    isFetchingNextPage,
  } = useFetchOems();
  const loading = isLoadingBrands || isFetchingNextPage;
  const allBrands = loading ? [] : oems;
  return (
    <Select
      showSearch
      style={style}
      defaultValue={defaultValue}
      value={value}
      placeholder={placeholder}
      onFocus={onFocus}
      onChange={onChange}
      loading={loading}
      disabled={disabled}
      notFoundContent={loading ? <LoadingContent /> : undefined}
      mode={mode}
      data-cy="brand-select"
    >
      {allBrands.map(({ oem_name }) => [
        <Select.Option
          key={oem_name}
          value={oem_name}
          data-cy={`option-${oem_name}`}
        >
          {oem_name}
        </Select.Option>,
      ])}
    </Select>
  );
}

export default memo(GenericBrandSelect);
