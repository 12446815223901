import { useState } from "react";
import useDeepEffect from "shared/hooks/useDeepEffect";
import { IDimension, IExtendedFabricObject } from "shared/types/designStudio";
import { getHeight, getWidth } from "utils/fabric/helpers.utils";
import { TVariableSelection } from "../canvas/VariableSelection";

export default (args: {
  target?: IExtendedFabricObject;
  wrapperDiv: HTMLDivElement | null;
  canvasDimension: IDimension;
}) => {
  const [variableSelectionInfo, setVariableSelectionInfo] =
    useState<TVariableSelection>();
  useDeepEffect(() => {
    if (!args.target) {
      setVariableSelectionInfo(undefined);

      return;
    }

    const { width: wrapperWidth = 0, height: wrapperHeight = 0 } =
      args.wrapperDiv?.getBoundingClientRect() || {};
    const margin = {
      top: (wrapperHeight - args.canvasDimension.height) / 2,
      left: (wrapperWidth - args.canvasDimension.width) / 2,
    };

    setVariableSelectionInfo({
      top: (args.target?.top || 0) + margin.top,
      left: (args.target?.left || 0) + margin.left,
      width: getWidth(args.target as fabric.Object),
      height: getHeight(args.target as fabric.Object),
      object: args.target,
    });
  }, [args.target]);

  return variableSelectionInfo;
};
