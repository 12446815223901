import { Typography } from "antd";

export const LogoInstructionText = () => (
  <div style={{ marginBottom: 8 }}>
    <Typography.Text
      style={{
        fontWeight: 400,
        color: "rgba(0, 0, 0, 0.45)",
        fontSize: 12,
      }}
    >
      Please provide a .png file with a transparent background.
      <br />
      Minimum recommended image dimension is 3/4&quot; (60px wide).
    </Typography.Text>
  </div>
);
