import { useQuery } from "react-query";
import API from "services";
import { ProcessedRecord } from "shared/types/assetExporter";
import { getErrorMessage } from "utils/errorMessage";

export const getFeedVersions = async (feedId: string) => {
  try {
    const res = await API.services.assetExporter.getProcessedRecords(
      feedId,
      100,
    );

    const { result, error } = res;

    if (!result) throw new Error("Unable to get feed versions");
    if (!!error) throw new Error(error.message);

    return result.records;
  } catch (error) {
    const errMsg = getErrorMessage(error);
    throw new Error(errMsg);
  }
};

const useFetchFeedVersions = (feedId: string, isDrawerOpen: boolean) => {
  return useQuery<ProcessedRecord[] | undefined, Error>(
    ["feedVersions", feedId],
    () => getFeedVersions(feedId),
    {
      staleTime: Infinity,
      enabled: !!feedId && isDrawerOpen,
    },
  );
};

export default useFetchFeedVersions;
