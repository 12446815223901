import { createSlice } from "@reduxjs/toolkit";
import { createActionsHook } from "react-redux-actions-hook";
import { AppState } from "redux/store";

const INITIAL_LIMIT = 100;

interface IRequestParams {
  platform: "facebook";
  limit: number;
}
interface IAdReviewState {
  requestParams: IRequestParams;
}

const initialState: IAdReviewState = {
  requestParams: {
    platform: "facebook",
    limit: INITIAL_LIMIT,
  },
};

const adReviewSlice = createSlice({
  name: "adReview",
  initialState,
  reducers: {
    resetLimit: state => {
      state.requestParams.limit = INITIAL_LIMIT;
    },
    reduceLimit: state => {
      const reducedLimit = Math.ceil(state.requestParams.limit * 0.7);

      state.requestParams.limit = reducedLimit;
    },
  },
});

//SELECTORS
export const selectRequestParams = (state: AppState) =>
  state.adReview.requestParams;

//ACTIONS
export const useAdReviewActions = createActionsHook(adReviewSlice.actions);

//REDUCERS
export default adReviewSlice.reducer;
