import { unionBy } from "lodash";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getAdKeyFromDest } from "screens/adLibrary/adLoadV2/campaignData.utils";
import {
  AdLoadStatusItem,
  AdProto,
} from "screens/adLibrary/facebookUtils/adProto.types";
import API from "services";

const adLoadExecKey = "adLoadExec";

export const useStartAdLoadExec = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: API.services.adLoad.startExecution,
    onMutate: ({ sessionId, adProtos }) => {
      queryClient.setQueryData<AdLoadStatusItem[] | undefined>(
        [adLoadExecKey, sessionId],
        (prevStatuses = []) => {
          return getNewOptimisticStatuses({
            prevStatuses,
            adProtos,
            sessionId,
          });
        },
      );
    },
    onSettled: data => {
      queryClient.invalidateQueries([adLoadExecKey, data?.sessionId]);
    },
  });
};

type TrackAdLoadOptions = {
  enabled?: boolean;
};

export const useTrackAdLoadExec = (
  sessionId: string | undefined | null,
  { enabled = true }: TrackAdLoadOptions,
) => {
  return useQuery({
    queryKey: [adLoadExecKey, sessionId],
    queryFn: () => API.services.adLoad.trackExecution(sessionId!),
    enabled: enabled && !!sessionId,
    refetchInterval: (statuses = []) => {
      if (statuses.some(item => item.status === "loading")) {
        return 1000;
      }
      return false;
    },
    cacheTime: 0,
  });
};

const getNewOptimisticStatuses = ({
  prevStatuses,
  adProtos,
  sessionId,
}: {
  prevStatuses: AdLoadStatusItem[];
  adProtos: AdProto[];
  sessionId: string;
}) => {
  const newStatuses = adProtos.flatMap<AdLoadStatusItem>(proto => {
    return proto.dests.map<AdLoadStatusItem>(dest => ({
      adId: proto.id,
      accountId: proto.accountId,
      adsetId: dest.adsetId,
      campaignId: dest.campaignId,
      status: "loading",
      sessionId,
      updatedAt: new Date().toISOString(),
    }));
  });

  return unionBy(newStatuses, prevStatuses, status =>
    getAdKeyFromDest({ ...status, storeName: status.accountId }),
  );
};
