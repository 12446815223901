import { IFacebookAd } from "screens/adLibrary/facebookUtils/types";
import styles from "./AdInformations.module.scss";

interface IProps {
  facebookAd?: IFacebookAd;
}

type IAdInformationsProps = IProps;

const AdInformations = ({ facebookAd }: IAdInformationsProps) => (
  <ul className={styles.adInformations}>
    <ul>
      <li>
        <li>
          <b>Campaign: </b>
          <span>{facebookAd?.campaign?.name ?? ""}</span>
        </li>
      </li>
    </ul>
    <ul>
      <li>
        <li>
          <b>Ad set: </b>
          <span>{facebookAd?.adset?.name ?? ""}</span>
        </li>
      </li>
    </ul>
    <ul>
      <li>
        <li>
          <b>Ad: </b>
          <span>{facebookAd?.name ?? ""}</span>
        </li>
      </li>
    </ul>
  </ul>
);

export default AdInformations;
