import { memo, useState } from "react";
import { Row, Col, Input, Form, Radio } from "antd";
import { AdType } from "screens/adLibrary/facebookUtils/types";
import { IAd } from "shared/types/adLibrary";
import { areFieldsRequired as required } from "screens/adLibrary/adWizard.utils";

interface IProps {
  adEdit?: IAd;
  adType: AdType;
}

const AdDesignAIA = ({ adEdit }: IProps) => {
  const [format, setFormat] = useState<"still" | "carousel">(
    adEdit?.visuals?.format || "still",
  );

  return (
    <>
      <Row gutter={24}>
        <Col span={16}>
          <Form.Item name={["visuals", "format"]} label="Format">
            <Radio.Group value={format}>
              <Radio value="still" onClick={() => setFormat("still")}>
                Still
              </Radio>
              <Radio value="carousel" onClick={() => setFormat("carousel")}>
                Carousel
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={["visuals", "utmCode"]}
            label="UTM Code"
            required={required}
            style={{ marginBottom: 5, width: "90%" }}
            rules={[
              {
                required,
                message: "Please add UTM code",
                whitespace: true,
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
      </Row>
      {format === "carousel" && (
        <Row gutter={24}>
          <Col span={16}>
            <Form.Item
              name={["visuals", "creativeOption"]}
              label="Creative Options"
            >
              <Radio.Group>
                <Radio value="single">Single Image</Radio>
                <Radio value="slideshow">Slideshow</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  );
};

export default memo(AdDesignAIA);
