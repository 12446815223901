import { CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Modal, Tabs, message } from "antd";
import { DataSourceItemType } from "antd/lib/auto-complete";
import { isEqual } from "lodash";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import API from "services";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { useFetchTemplates } from "shared/hooks/useFetchTemplates";
import {
  ComparisonOperator,
  SmartColumn,
  TRule,
} from "shared/types/assetExporter";
import uuid from "uuid";
import styles from "./RuleModal.module.scss";
import Footer from "./ruleModal/Footer";
import RuleForm from "./ruleModal/RuleForm";
import { deleteRule, isTemplateSelected } from "./utils/RuleModalUtils";

interface IRuleModalProps {
  columnName?: string;
  columns: string[];
  feedId: string;
  rules: TRule[];
  showModal: boolean;
  smartColumns: SmartColumn;
}
interface IRuleModalHandlers {
  getSmartColumns: () => Promise<void>;
  onModalClose: () => void;
  saveRules: (columnName: string, rules: TRule[]) => Promise<void>;
  setSmartColumns: Dispatch<SetStateAction<SmartColumn>>;
}
type IProps = IRuleModalProps & IRuleModalHandlers;
const ruleNameLength = 20; // the rule's name can fit 20 chars before it looks weird

export const RuleModal = (props: IProps) => {
  const [newRule, setNewRule] = useState<Partial<TRule>>(
    props.rules.length === 0
      ? {
          conditions: [
            {
              type: "if",
              leftEq: { text: "", value: "" },
              op: ComparisonOperator.Equals,
              rightEq: "",
            },
          ],
        }
      : { ...props.rules[0] },
  );

  // below get the copy of the props.rules and deal with it until user saves them.
  const [rules, setRules] = useState<TRule[]>(props.rules);
  const defaultRule = useRef(`rule-create-${rules.length}`);
  const [activeTab, setActiveTab] = useState("");
  const [openedModal, setOpenedModal] = useState<boolean>(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (!props.showModal || openedModal) return;

    setActiveTab(defaultRule.current);
    setSelectedRuleIndex(rules.length);
    setOpenedModal(true);
  }, [openedModal, props.showModal, rules.length]);

  useEffect(() => {
    setRules(props.rules);
    defaultRule.current = `rule-create-${props.rules.length}`;
  }, [props.rules]);

  const feedType = useAppSelector(
    state => state.assetExporter.configure.feedType,
  );

  const { templates: fetchedTemplates, isFetching } = useFetchTemplates({
    status: "PUBLISHED",
  });

  const validateRule = useCallback(
    (rule: Partial<TRule>, idx: number) => {
      if (!rule || !rule.conditions) {
        message.error("The rule is not valid.");
        return;
      }

      const activeTabIndex = activeTab.split("-").reverse()[0];
      const templateIsDeleted =
        !isFetching &&
        rule.then?.type === "template" &&
        rule.then?.value &&
        !fetchedTemplates.find(template => template.id === rule.then?.value);

      if (activeTabIndex === idx.toString() && templateIsDeleted) {
        message.error("The template no longer exists.");
        return;
      }

      // For each condition.leftEq, the value has to be proper keys of "FeedColumnToTitle"
      // For instance, a user might just type "Language" in the field and try to save the rule. If this happens,
      //  the condition.leftEq.text AND condition.leftEq.value will be equal to "Language". Howevery, the condition.leftEq.value MUST be "language" which is the key of "Languate" in "FeedColumnToTitle".
      // Or
      // The condition.leftEq has to be one of the raw key from the first row of feedRows. In this case, the raw key does not exist in "FeedColumnToTitle".
      // This could happen is we forgot to add the raw key to "FeedColumnToTitle" but this key is still valid if value exists in the row.
      // Those keys are passed in props as "columns"
      const wrongFeedColumnTitles = [];
      const emptyRightEqs = []; // empty rightEq
      for (const condition of rule.conditions) {
        const title = props.columns.find(col => col === condition.leftEq.value);
        if (!!title && title === condition.leftEq.text) {
          // check if leftEq is valid but empty rightEq
          if (!condition.rightEq || condition.rightEq.trim() === "") {
            emptyRightEqs.push(condition.leftEq.text);
          }
          continue;
        }

        // wrong value
        // if condition.leftEq.text === "", it means nothing has filled... must notify the user that they need to fill it.
        wrongFeedColumnTitles.push(
          condition.leftEq.text.trim() === ""
            ? "IF condition"
            : condition.leftEq.text,
        );
      }

      if (wrongFeedColumnTitles.length > 0) {
        message.error(
          `${wrongFeedColumnTitles.join(
            ",",
          )} are not valid. You must select valid option from the list.`,
          5,
        );

        return false;
      }

      if (emptyRightEqs.length > 0) {
        message.error(
          `The "right condition" must be set for the following "left condition": ${emptyRightEqs.join(
            ",",
          )}`,
        );

        return false;
      }

      if (rule.then?.isRegexOn) {
        // If isRegexOn is true, validate if the value is valid regex expression
        try {
          new RegExp(rule.then.text as string);
        } catch (err) {
          message.error(`${err}`, 5);

          return false;
        }

        // check if target column is selected
        const isRegexColumnSelected =
          !!rule.then.regexTargetColumn &&
          !!rule.then.regexTargetColumn.text &&
          !!rule.then.regexTargetColumn.value;

        if (!isRegexColumnSelected) {
          message.error("The regex column must be selected.");
          return false;
        }
      }

      const isOtherFieldsValid = !!rule.name && !!rule.then;
      if (!isOtherFieldsValid) {
        const notValidFields = [];
        if (!rule.name) notValidFields.push("Rule name");
        if (!rule.then) notValidFields.push("Then");

        message.error(
          `The following fields are not valid: ${notValidFields.join(",")}`,
        );

        return false;
      }

      return true;
    },
    [activeTab, isFetching, props.columns, fetchedTemplates],
  );

  const resetRule = useCallback(() => {
    setNewRule({
      conditions: [
        {
          type: "if",
          leftEq: { text: "", value: "" },
          op: ComparisonOperator.Equals,
          rightEq: "",
        },
      ],
    });
    setSelectedRuleIndex(rules.length);
    setActiveTab(defaultRule.current);
  }, [rules, defaultRule]);

  const duplicateRule = async (existingRule: TRule) => {
    if (!props.columnName) return;

    const duplicatedRule = {
      ...existingRule,
      name: `${existingRule.name}${
        existingRule.name.includes("duplicated") ? `_${uuid()}` : "_duplicated"
      }`,
    };
    const updatedRules = [...rules, duplicatedRule];

    setRules(updatedRules);
    const areRulesValid = updatedRules.every((rule, idx) =>
      validateRule(rule, idx),
    );

    if (!areRulesValid) return; // proper error message will be displayed within the func

    props.saveRules(props.columnName, updatedRules).then(async () => {
      await props.getSmartColumns();
    });
  };

  const dataSource: DataSourceItemType[] = props.columns.map(columnKey => ({
    text: columnKey,
    value: columnKey,
  }));

  const [selectedRuleIndex, setSelectedRuleIndex] = useState<number>(0);
  const [showUnsavedModal, setShowUnssavedModal] = useState(false);

  const onCancel = (currRules: TRule[], updatedRules: TRule[]) => {
    if (isEqual(currRules, updatedRules)) {
      props.onModalClose();
      setOpenedModal(false);
      resetRule();
      return;
    }
    setShowUnssavedModal(true);
  };

  return (
    <>
      <Modal
        className="rule-form-modal"
        visible={props.showModal}
        onCancel={() => onCancel(props.rules, rules)}
        title={`CREATE NEW SMART COLUMN RULE: ${props.columnName}`}
        width={"70%"}
        footer={
          <Footer
            disabled={saveButtonDisabled}
            onModalClose={() => onCancel(props.rules, rules)}
            onSaveRule={() => {
              if (!props.columnName) return;

              const updatedRules = [...rules];

              if (selectedRuleIndex === updatedRules.length) {
                updatedRules.push(newRule as TRule);
              }

              const areRulesValid = updatedRules.every((rule, idx) =>
                validateRule(rule, idx),
              );

              if (!areRulesValid) return; // proper error message will be displayed within the func

              props.saveRules(props.columnName, updatedRules).then(() => {
                resetRule();
                setSelectedRuleIndex(rules.length);
              });
            }}
            onFormClear={() => {
              resetRule();
            }}
            onDeleteRule={() => {
              const rule = props.rules[selectedRuleIndex];

              Modal.confirm({
                title: (
                  <div>
                    <span>
                      Are you sure you want to delete&nbsp;
                      <span style={{ fontWeight: "bold" }}>{rule.name}</span>
                      &nbsp;rule?
                    </span>
                  </div>
                ),
                onOk: async () => {
                  const delCol = API.services.assetExporter.deleteColRule;
                  const { feedId, columnName } = props;
                  const idx = selectedRuleIndex;
                  const res = await delCol(feedId, columnName!, idx);

                  if (res.error) {
                    message.error(res.error.message);
                    return;
                  }
                  await props.getSmartColumns();
                },
              });
            }}
          />
        }
      >
        <Tabs
          className={styles.tabs}
          tabPosition="left"
          type="card"
          activeKey={
            activeTab.length > 0 && !activeTab.includes("rule-create")
              ? activeTab
              : defaultRule.current
          }
          onChange={key => {
            const idx = key.split("-")[2]; // else at index 2 must exists unless we change the TabPane key.
            setSelectedRuleIndex(parseInt(idx));
            setActiveTab(key);
          }}
        >
          {(
            rules
              .map((existingRule, idx) => ({
                rule: existingRule as Partial<TRule>,
                tab: (
                  <div style={{ overflow: "hidden" }}>
                    <div
                      style={{
                        float: "left",
                      }}
                    >
                      {/* 20 (ruleNameLength) is the max length of characters
                      that can fit in the left tab for the modal */}
                      {existingRule.name.length > ruleNameLength
                        ? `${existingRule.name.substring(0, ruleNameLength)}...`
                        : (existingRule.name as string | ReactNode)}
                    </div>
                    <div
                      style={{
                        marginLeft: "10em",
                      }}
                    >
                      <Button
                        disabled={
                          existingRule?.name !==
                          props.rules[selectedRuleIndex]?.name
                        }
                        type={"text"}
                        icon={<CopyOutlined style={{ margin: "auto" }} />}
                        onClick={async () => {
                          await duplicateRule(existingRule);
                        }}
                      />

                      <Button
                        disabled={
                          existingRule?.name !==
                          props.rules[selectedRuleIndex]?.name
                        }
                        type={"text"}
                        icon={<DeleteOutlined />}
                        onClick={async () => {
                          const { feedId, columnName } = props;
                          await deleteRule({
                            rules: props.rules,
                            selectedRuleIndex,
                            feedId,
                            columnName,
                            getSmartColumns: props.getSmartColumns,
                          });
                        }}
                      />
                    </div>
                  </div>
                ),
                key: `rule-tab-${idx}`,
              }))
              .concat({
                rule: newRule,
                tab: (
                  <div
                    style={{
                      marginLeft: "3em",
                    }}
                  >
                    <span>+ Create New Rule</span>
                  </div>
                ),
                key: defaultRule.current,
              }) as Array<{
              rule: Partial<TRule>;
              tab: string | ReactNode;
              key: string;
            }>
          ).map((obj, idx) => {
            const { rule, key, tab } = obj;
            const templateIsDeleted =
              !!rule?.then?.value &&
              !fetchedTemplates.find(
                template => template.id === rule?.then?.value,
              );
            return (
              <Tabs.TabPane key={key} tab={tab}>
                <RuleForm
                  rule={rule}
                  ruleIndex={idx}
                  dataSource={dataSource}
                  templates={fetchedTemplates}
                  templateIsDeleted={templateIsDeleted}
                  onRuleUpdate={(rule, ruleIndex) => {
                    // if the index is equals to the rules.length, it means user is editing the Create New Rule tab.
                    // We store this new rule in "rule"
                    if (rules.length === 0 || ruleIndex === rules.length) {
                      setNewRule(rule);
                      setSaveButtonDisabled(!isTemplateSelected(rule));
                      return;
                    }

                    const updatedRules = [...rules];
                    updatedRules.splice(ruleIndex, 1, rule as TRule);
                    setRules(updatedRules);
                    const notSelected = updatedRules.some(
                      updatedRule => !isTemplateSelected(updatedRule),
                    );
                    setSaveButtonDisabled(notSelected);
                  }}
                  editable
                />
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      </Modal>
      <Modal
        visible={showUnsavedModal}
        title="Please Confirm"
        cancelText="Discard"
        okText="Cancel"
        onOk={() => {
          setShowUnssavedModal(false);
        }}
        onCancel={() => {
          setShowUnssavedModal(false);
          props.onModalClose();
          setRules(props.rules);
        }}
      >
        <span>Are you sure you want to discard changes?</span>
      </Modal>
    </>
  );
};
