import { useMemo } from "react";
import { Steps as AntSteps } from "antd";
import { useAdLibraryExportContext } from "../adLibrary/AdLibraryExportContext";
import { exportDestinationStepTitles } from "./constants";

const { Step } = AntSteps;

interface StepsProps {
  currentStep: number;
}

export const Steps = ({ currentStep }: StepsProps) => {
  const { exportDestination } = useAdLibraryExportContext();

  const stepTitles = useMemo(() => {
    if (!exportDestination || currentStep === 0) return [];
    return exportDestinationStepTitles[exportDestination];
  }, [exportDestination, currentStep]);

  const stepsWidth = (() => {
    const totalSteps = stepTitles.length + 1; // +1 for export settings
    // calculate width as a percentage, adding an extra step's worth of space at the end
    return `${(100 * totalSteps) / (totalSteps + 1)}%`;
  })();

  return (
    <div style={{ width: stepsWidth }}>
      <AntSteps direction="horizontal" current={currentStep}>
        <Step title={"Export Settings"} />
        {stepTitles.map(stepTitle => (
          <Step title={stepTitle} key={stepTitle} />
        ))}
      </AntSteps>
    </div>
  );
};
