import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";
import { fields } from "./fields";
import { DataProvider } from "./hooks/SalesEnablementDataProvider";
import { DataListTagsURL } from "shared/components/dataListURL/DataListTagsURL";
import styles from "./PrintOptions.module.scss";
import { useDataListURLData } from "shared/components/dataListURL/useDataListURLData";
import { useDataListURLGlobalFilter } from "shared/components/dataListURL/useDataListURLGlobalFilter";
import { useDataListURLSorter } from "shared/components/dataListURL/useDataListURLSorter";
import { TableActionsContextProvider } from "shared/components/HoverActionsVirtualTable/TableActionsContext";
import { ContextTableActions } from "shared/components/tableActions/ContextTableActions";
import { InlineTableActions } from "shared/components/tableActions/InlineTableActions";
import ToolbarTable from "shared/components/toolbarTable/ToolbarTable";
import { ToolbarButton } from "shared/types/adLibrary";
import {
  PrintOptionsTableColumns,
  PrintOptionsTableItem,
} from "shared/types/printOptions";
import { mockData } from "./printOptions/mockPrintOptions";
import { Table } from "./printOptions/Table";
import { EditOutlined } from "@ant-design/icons";
import { DeleteOutlined } from "@mui/icons-material";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";
import { ROUTES } from "./printOptions/constants";
import { PrintOptionsDrawer } from "./printOptions/PrintOptionsDrawer";

export const PrintOptionsContainer = () => {
  const navigate = useNavigateWithSearch();
  const printOptions: PrintOptionsTableItem[] = mockData;

  const { data, selectedItems } = useDataListURLData<
    PrintOptionsTableColumns,
    PrintOptionsTableItem
  >({
    isError: false,
    isLoading: false,
    data: printOptions,
  });

  const { sortKey, sortOrder, sortItems } = useDataListURLSorter<
    PrintOptionsTableColumns,
    PrintOptionsTableItem
  >();
  const { globalFilter, setGlobalFilter } = useDataListURLGlobalFilter();

  const generateToolbarContents = (
    record?: PrintOptionsTableItem,
  ): ToolbarButton => {
    return {
      Edit: {
        disabled: !!record,
        icon: <EditOutlined />,
        showInInlineMenu: true,
        showInContextMenu: true,
      },
      Delete: {
        disabled: !!record,
        icon: <DeleteOutlined />,
        showInInlineMenu: true,
        showInContextMenu: true,
      },
    };
  };

  const toolbarContents: ToolbarButton = {
    New: {
      disabled: false,
      extraInfo: {
        text: "New Print Option",
        tooltip: "Create new Print Option",
      },
      onClick: () => {
        navigate(ROUTES.create);
      },
    },
    Edit: {
      disabled: selectedItems.length !== 1,
      showInInlineMenu: true,
      showInContextMenu: true,
    },
    Delete: {
      disabled: !selectedItems.length,
      showInInlineMenu: true,
      showInContextMenu: true,
    },
  };

  return (
    <div className={styles.container}>
      <ToolbarTable
        titleTooltip="Search"
        toolbarContents={toolbarContents}
        searchPlaceholder="Search"
        onSearch={setGlobalFilter}
        searchValue={globalFilter ?? undefined}
        disableSearch={false}
        previewFormat={false}
        sortingOrder={sortKey && sortOrder ? [sortKey, sortOrder] : undefined}
        onSortChange={([columnKey, order]) => {
          sortItems(columnKey, order);
        }}
        includeFilterOnTableLayout={true}
      />
      <DataListTagsURL<PrintOptionsTableColumns, PrintOptionsTableItem>
        data={data}
        originalData={printOptions}
      />
      <TableActionsContextProvider<PrintOptionsTableItem>
        renderContextActions={(record?: PrintOptionsTableItem) => (
          <ContextTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
        renderHoverActions={record => (
          <InlineTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
      >
        <Table data={data} />
      </TableActionsContextProvider>
    </div>
  );
};

export const PrintOptions = () => {
  return (
    <DataProvider>
      <DataListURLProvider fields={fields}>
        <PrintOptionsContainer />
        <PrintOptionsDrawer />
      </DataListURLProvider>
    </DataProvider>
  );
};
