import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IUploadManagementState,
  TUploadLogosDictionary,
} from "shared/types/uploadManagement";
import {
  IUploadImage,
  IUploadImageFormInput,
  IUploadImagesForm,
  IUploadImagesResponse,
  TUploadGroup,
  IGetPutSignedUrlReqBody,
  IGetSignedPutUrlResponse,
  IUploadImagesResult,
} from "../../shared/types/uploadManagement";

import { delay } from "../../utils/helpers";

import GenericError from "shared/errors/GenericError";
import API from "../../services";
import { OperationMode } from "shared/types/inputValues";
import { dataURLtoBlob } from "utils/helpers.fabric";
import { IDataTableError } from "shared/types/errors";
import { AppThunk } from "redux/store";

const initialState: IUploadManagementState = {
  imagesToUpload: null,
  processingUpload: false,
  result: null,
  error: null,

  logosFromS3InDictionary: {
    horizontalImagesFromS3: [],
    verticalImagesFromS3: [],
    squareImagesFromS3: [],
    horizontalEventImagesFromS3: [],
    verticalEventImagesFromS3: [],
    squareEventImagesFromS3: [],
  },

  logosUploadStatus: null,
};

const uploadManagementSlice = createSlice({
  name: "uploadManagement",
  initialState,
  reducers: {
    resetUploadImagesInRedux(state) {
      return {
        ...state,
        logosFromS3InDictionary: {
          horizontalImagesFromS3: [],
          verticalImagesFromS3: [],
          squareImagesFromS3: [],
          horizontalEventImagesFromS3: [],
          verticalEventImagesFromS3: [],
          squareEventImagesFromS3: [],
        },
        result: null,
        error: null,
      };
    },
    setLogosUploadStatus(
      state,
      {
        payload: logosUploadStatus,
      }: PayloadAction<null | "UPLOADING" | "COMPLETE">,
    ) {
      return {
        ...state,
        logosUploadStatus,
      };
    },
    uploadImagesBegin(state) {
      return {
        ...state,
        processingUpload: true,
      };
    },
    uploadImagesFail(state, { payload: error }: PayloadAction<Error>) {
      return {
        ...state,
        error,
        processingUpload: false,
      };
    },
    uploadImagesSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        result: IUploadImagesResult | null;
        group?: TUploadGroup;
        mode?: OperationMode;
        logoKeysToBeUploaded?: string[];
        newOemButtonClicked?: boolean;
      }>,
    ) {
      const {
        result: uploadImagesResult,
        group,
        mode,
        logoKeysToBeUploaded,
        newOemButtonClicked,
      } = payload;

      const { images } = uploadImagesResult!;

      const tempArrImageUrls = [];
      for (const img of images!) {
        tempArrImageUrls.push(img.imageUrl);
      }

      const groupString = `${group}Images`;

      const newDictionaryState = { ...state.logosFromS3InDictionary };
      for (const prop in state.logosFromS3InDictionary) {
        if (newOemButtonClicked) {
          const type = prop.includes("Event")
            ? prop.split("Event")[0]
            : prop.split("Images")[0]; // horizontal | vertical | square

          const tempKey = prop.includes("Event")
            ? `${type}LogosEvent`
            : `${type}Logos`;

          if (logoKeysToBeUploaded?.includes(tempKey)) {
            if (
              `${group?.[0].toLowerCase()}${group?.slice(1)}ImagesFromS3` ===
              prop
            ) {
              // replace the current state if new oem button has been clicked
              newDictionaryState[prop as keyof TUploadLogosDictionary] = [
                ...tempArrImageUrls,
              ];
            }
          } else {
            // clear the current state if new oem button has been clicked
            newDictionaryState[prop as keyof TUploadLogosDictionary] = [];
          }
        } else {
          if (tempArrImageUrls.length === 0 && mode === "CREATE") {
            continue;
          }
          newDictionaryState[prop as keyof TUploadLogosDictionary] = prop
            .toLowerCase()
            .startsWith(groupString.toLowerCase())
            ? mode === "CREATE"
              ? [
                  ...state.logosFromS3InDictionary[
                    prop as keyof TUploadLogosDictionary
                  ],
                  ...tempArrImageUrls,
                ]
              : tempArrImageUrls
            : mode === "CREATE"
            ? state.logosFromS3InDictionary[
                prop as keyof TUploadLogosDictionary
              ]
            : [];
        }
      }

      return {
        ...state,
        result: uploadImagesResult,
        processingUpload: false,
        imagesToUpload: uploadImagesResult,

        logosFromS3InDictionary: newDictionaryState,
      };
    },
  },
});

export const uploadImages =
  (
    imagesToUpload: IUploadImageFormInput[],
    featureName: string,
    group?: TUploadGroup,
    mode?: OperationMode,
    logoKeysToBeUploaded?: string[],
    newOemButtonClicked?: boolean,
  ): AppThunk =>
  async (dispatch, getState) => {
    dispatch(uploadImagesBegin());

    await delay(500);

    try {
      const uploadImagesForm: IUploadImagesForm = {
        files: imagesToUpload.map(image => {
          const mappedImageObject: IUploadImage = {
            filename: image.filename,
            fileType: image.type,
            imageData: image.file,
            featureName,
            skipStillImageCreation: image.skipStillImageCreation,
          };
          return mappedImageObject;
        }),
      };

      const response =
        await API.privServices.uploadManagement.uploadImages<IUploadImagesResponse>(
          uploadImagesForm,
        );

      const { error } = response;

      if (error) {
        const { message } = error;
        dispatch(
          uploadImagesFail(
            new GenericError({
              message: message || "Some error occurred.",
            }),
          ),
        );
        return;
      }

      dispatch(
        uploadImagesSuccess({
          result: response.result,
          group,
          mode,
          logoKeysToBeUploaded,
          newOemButtonClicked,
        }),
      );
    } catch (error) {
      dispatch(
        uploadImagesFail(
          new GenericError({
            message: (error as Error).message || "Some error occurred.",
            errorObject: error,
          }),
        ),
      );
    }
  };

export const uploadVideos =
  (
    videosToUploadInput: IUploadImageFormInput[],
    featureName: string,
  ): AppThunk =>
  async (dispatch, getState) => {
    dispatch(uploadImagesBegin());

    try {
      const videosToUpload = videosToUploadInput.map(video => {
        const mappedImageObject: IUploadImage = {
          filename: video.filename,
          fileType: video.type,
          imageData: video.file,
          featureName,
          skipStillImageCreation: video.skipStillImageCreation,
        };
        return mappedImageObject;
      });

      // possible to do: make uploading videos logic reusable
      const uploadVideoResponses = await Promise.all(
        videosToUpload.map(async uploadObj => {
          try {
            const getSignedPutUrlReqBody: IGetPutSignedUrlReqBody = {
              uploadParams: {
                file: uploadObj.filename!,
                path: `${uploadObj.featureName}/uploads`,
                contentType: "video/mp4", // possible TO DO: allow for different media types
              },
            };

            const getSignedPutUrlRes =
              (await API.privServices.uploadManagement.getSignedPutUrl(
                getSignedPutUrlReqBody,
              )) as IGetSignedPutUrlResponse;

            if (getSignedPutUrlRes.error || !getSignedPutUrlRes.result) {
              return getSignedPutUrlRes.error as IDataTableError;
            }

            const fileBlob = dataURLtoBlob(uploadObj.imageData as string);

            const commonHeaders: HeadersInit = new Headers();
            commonHeaders.set("Content-Type", "video/mp4");
            commonHeaders.set("Accept", "application/json");

            const putResult = await fetch(getSignedPutUrlRes.result.url, {
              method: "PUT",
              headers: commonHeaders,
              body: fileBlob,
            });

            if (putResult.status !== 200) {
              return { message: "Could not upload mp4 video" };
            }

            uploadObj.imageData = "data:video/mp4";
            const { error: videoErr, result: videoRes } =
              (await API.privServices.uploadManagement.uploadImages({
                files: [uploadObj],
              })) as IUploadImagesResponse;

            if (videoErr || !videoRes?.images) {
              return (videoErr || {
                message: "Could upload video asset.",
              }) as IDataTableError;
            }

            return videoRes as IUploadImagesResult;
          } catch (error) {
            return error as IDataTableError;
          }
        }),
      );

      const videoResults = uploadVideoResponses.filter(
        response => (response as IUploadImagesResult).images,
      ) as IUploadImagesResult[];

      if (videoResults.length === 0) {
        // Possible TO DO: get error messages and aggregate them
        return dispatch(
          uploadImagesFail(
            new GenericError({
              message: "Videos could not be uploaded",
            }),
          ),
        );
      }

      const finalResult = { images: [] } as unknown as IUploadImagesResult;

      for (const result of videoResults) {
        finalResult.images = finalResult.images?.concat(result.images!);
      }

      const finalResponse = { result: finalResult, error: null };

      return dispatch(uploadImagesSuccess({ result: finalResponse.result }));
    } catch (error) {
      return dispatch(
        uploadImagesFail(
          new GenericError({
            message: (error as Error).message || "Some error occurred.",
            errorObject: error,
          }),
        ),
      );
    }
  };

export const {
  resetUploadImagesInRedux,
  setLogosUploadStatus,
  uploadImagesBegin,
  uploadImagesFail,
  uploadImagesSuccess,
} = uploadManagementSlice.actions;

export default uploadManagementSlice.reducer;
