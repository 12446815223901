import { message } from "antd";
import { Platform } from "./types";
import { getErrorMessage } from "utils/errorMessage";

export const getPlatformTokenKey = (platform: Platform) => {
  return ["platformToken", platform];
};

export const handleError = (error: Error | null) => {
  if (error) {
    message.error(getErrorMessage(error) || "Something went wrong", 6);
  }
};
