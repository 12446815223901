import { Button, DatePicker, Space } from "antd";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { startOfDay } from "date-fns";

export const DateFilterDropdown = ({
  setSelectedKeys,
  confirm,
  clearFilters,
}: FilterDropdownProps) => {
  return (
    <Space align="center" size={12} style={{ padding: "1em" }}>
      <DatePicker.RangePicker
        autoFocus={true}
        onChange={date => {
          if (!date || !date[0] || !date[1]) return;
          const dateFrom = startOfDay(date[0].toDate()).getTime();
          const dateTo = startOfDay(date[1].toDate()).getTime();
          setSelectedKeys([`${dateFrom} ${dateTo}`]);
          confirm();
        }}
      />
      <Button
        role="reset"
        onClick={() => {
          clearFilters && clearFilters();
          setSelectedKeys([]);
        }}
        size="small"
      >
        Reset
      </Button>
    </Space>
  );
};
