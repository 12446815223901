// https://usehooks.com/useWindowSize/
import { useState, useEffect } from "react";

type WindowSize = {
  windowInnerWidth: number;
  windowInnerHeight: number;
};

export function useWindowSize(): WindowSize {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    windowInnerWidth: window.innerWidth,
    windowInnerHeight: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        windowInnerWidth: window.innerWidth,
        windowInnerHeight: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}
