import { useAppSelector } from "shared/hooks/useAppSelector";
import { TableFiltersSection } from "shared/components/TableFiltersSection";
import { useAdEngineActions } from "redux/assetExporter/assetExporter.slice";
import { useFilteredFeeds } from "shared/hooks/assetExporter/useFilteredFeeds";

const SummarySelected = () => {
  const { showSelected, selectedFeedIds } = useAppSelector(
    state => state.assetExporter.sourceFeed,
  );
  const { setShowSelected } = useAdEngineActions();

  const { dataSource, originalDataSource } = useFilteredFeeds();

  const selectedCount = selectedFeedIds.length;
  const totalCount = originalDataSource.length;
  const currentItems = dataSource.length;

  return (
    <TableFiltersSection
      totalItems={totalCount}
      selectedCount={selectedCount}
      currentItems={currentItems}
      displaySelectedOnly={showSelected}
      onFilterClick={() => setShowSelected(!showSelected)}
    />
  );
};

export default SummarySelected;
