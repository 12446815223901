import { RcFile } from "antd/lib/upload";
import { useMutation } from "react-query";
import API from "services";
import { getErrorMessage } from "utils/errorMessage";
import { v4 as uuidv4 } from "uuid";

const putCsv = async (file: RcFile) => {
  const feedId = uuidv4();
  const createdAt = Date.now();
  const filename = `${feedId}_${createdAt}.csv`;
  try {
    const { getCsvSignedUrl } = API.services.assetExporter;
    const { result, error } = await getCsvSignedUrl(filename);

    if (error) throw new Error(error.message);
    if (!result) throw new Error("Unable to upload csv");

    await API.services.assetExporter.putCsvFile(result.url, file!);
    return filename;
  } catch (error) {
    const errMsg = getErrorMessage(error);
    throw new Error(errMsg);
  }
};

const useUploadCsv = () => {
  return useMutation<string, Error, RcFile>(file => putCsv(file));
};

export default useUploadCsv;
