import { useCallback, useEffect, useState } from "react";
import { fontFamilyOptions } from "shared/constants/canvas";
import { isFeatureEnabled } from "utils/helpers";
import { loadFontV2 } from "../../ManageText.utils";
import { FontNameMappings } from "../FontProperty.types";
import { loadDynamicFontList } from "../FontProperty.utils";
import FontFaceObserver from "fontfaceobserver";

export default () => {
  const isDynamicFontsEnabled = isFeatureEnabled("ENABLE_DYNAMIC_FONT");

  const [fontFamilies, setFontFamilies] = useState<string[]>(
    fontFamilyOptions || [],
  );
  const [fontMappings, setFontMappings] = useState<FontNameMappings>();
  const [loadedFontNames, setLoadedFontNames] = useState<string[]>([]);

  const loadAllFonts = useCallback(
    async (fontFamily: string) => {
      try {
        const mappings = (fontMappings || {})[fontFamily];
        if (loadedFontNames.includes(fontFamily)) return [];
        await Promise.all(mappings.map(font => loadFontV2(font)));
        setLoadedFontNames(prev => [...new Set([...prev, fontFamily])]);
        const ffo = new FontFaceObserver(fontFamily);
        await ffo.load();
        return;
      } catch (err) {
        throw new Error(`Could not load font: ${fontFamily}`);
      }
    },
    [fontMappings, loadedFontNames, setLoadedFontNames],
  );

  const loadFont = useCallback(
    async (
      fontFamily: string,
      loadFontFn: (fontFamily: string) => Promise<FontFaceObserver | undefined>,
    ) => {
      try {
        const shouldLoadDynamically = fontFamily in (fontMappings || {});
        if (loadedFontNames.includes(fontFamily)) {
          return;
        }
        if (!fontMappings || !shouldLoadDynamically) {
          const loaded = await loadFontFn(fontFamily);
          setLoadedFontNames(prev => [...new Set([...prev, fontFamily])]);
          if (!!loaded) {
            return;
          } else {
            throw new Error(`Could not load font: ${fontFamily}`);
          }
        }
        return await loadAllFonts(fontFamily);
      } catch (err) {
        throw err;
      }
    },
    [loadAllFonts, fontMappings, setLoadedFontNames, loadedFontNames],
  );

  useEffect(() => {
    if (!isDynamicFontsEnabled) return;

    loadDynamicFontList().then(([fontList, fontMappings]) => {
      setFontFamilies(prev => [...prev, ...fontList]);
      setFontMappings(fontMappings);
    });
  }, [isDynamicFontsEnabled]);

  return {
    loadFont,
    fontFamilies,
    fontMappings,
  };
};
