/* eslint-disable react/display-name */
import Columns from "./adsToLoadTable/columns";

import { doesAdToLoadHaveQAErrorIssues } from "../utils";

import { AdLoadColumnKey, IAdToLoadData } from "shared/types/adLibrary";
import { IAdsToLoadTableProps, IAdsToLoadTableHandlers } from "../types";

import styles from "./AdsToLoadTable.module.scss";
import VirtualTable from "shared/components/VirtualTable";
import { useWindowSize } from "shared/hooks/useWindowSize";

type Props = IAdsToLoadTableProps & IAdsToLoadTableHandlers;

const AdsToLoadTable = (props: Props) => {
  const {
    loading,
    expandable,
    adsToLoad = [],
    selectedRowKeys,
    uniqueColumns = [],
    isSelectionEnabled,
    disabledCheckboxes,
    selectedColumnKeys = [],
    productCatalogIds,
    ignoreStatuses,
    instantExperiences,
    wereCollectionsConverted,
    onMultiUrlClick,
    onRowDataChange,
    onRowSelectionChange,
    scrollOffset = 0,
  } = props;

  const { windowInnerHeight } = useWindowSize();

  const allColumns = Columns({
    adsToLoad,
    ignoreStatuses: wereCollectionsConverted ? [] : ignoreStatuses,
    productCatalogIds,
    instantExperiences,
    willHideThumbnails: !!expandable,
    onMultiUrlClick,
    onRowDataChange,
  });

  const selectedColumns = props.selectedColumnKeys
    ? allColumns.filter(column =>
        selectedColumnKeys.includes(column.title as AdLoadColumnKey),
      )
    : allColumns;
  const actualColumns = [...uniqueColumns, ...selectedColumns];

  return (
    <VirtualTable<IAdToLoadData>
      scroll={{ y: windowInnerHeight - scrollOffset }}
      size="small"
      loading={loading}
      pagination={false}
      dataSource={adsToLoad}
      columns={actualColumns}
      expandable={expandable}
      onHeaderRow={() => ({ className: styles.adLoadTableHeader })}
      onRow={() => ({
        className: !!expandable ? styles.adLoadTableRow : undefined,
      })}
      rowSelection={
        isSelectionEnabled
          ? {
              selectedRowKeys,
              onChange: onRowSelectionChange,
              getCheckboxProps: record => ({
                disabled:
                  disabledCheckboxes === undefined
                    ? doesAdToLoadHaveQAErrorIssues(record)
                    : disabledCheckboxes,
              }),
            }
          : undefined
      }
    />
  );
};

export default AdsToLoadTable;
