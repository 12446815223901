import { ApartmentOutlined } from "@ant-design/icons";

const EverythingAdIcon = () => {
  return (
    <ApartmentOutlined
      rotate={270}
      style={{
        backgroundColor: "#F0F0F0",
        padding: 6,
        color: "rgba(0, 0, 0, 0.45)",
        fontSize: 18,
      }}
    />
  );
};

export default EverythingAdIcon;
