import {
  IUploadFont,
  IUploadFontResponse,
} from "shared/types/uploadManagement";
import { getErrorMessage } from "utils/errorMessage";
import { UploadFile } from "antd/lib/upload/interface";
import API from "services";

export const uploadFontsToS3 = async (
  fonts: UploadFile[],
  existingFonts?: UploadFile[],
) => {
  try {
    const { uploadFont } = API.services.uploadManagement;

    const uploadFontsPromises: Promise<IUploadFontResponse>[] = [];
    let uploadedFonts = [
      ...(existingFonts?.map(existingFont => ({
        name: existingFont.name,
        url: existingFont.url ?? "",
      })) ?? []),
    ];

    fonts.forEach(fontFile => {
      const uploadFontsForm: IUploadFont = {
        filename: fontFile.name,
        fileType: fontFile.type ?? "ttl",
        fileData: fontFile.thumbUrl ?? "",
        featureName: "brands-management",
      };

      uploadFontsPromises.push(
        uploadFont<IUploadFontResponse>(uploadFontsForm),
      );
    });

    const uploadFontsResponses = await Promise.all(uploadFontsPromises);

    uploadFontsResponses.forEach(uploadFontResponse => {
      const { error, result: responseResult } = uploadFontResponse;

      if (error) throw new Error(error.message || "Error uploading fonts");
      if (!responseResult) throw new Error("Error uploading fonts");

      uploadedFonts.push({
        name: responseResult.filename,
        url: responseResult.fontUrl,
      });
    });

    return uploadedFonts;
  } catch (error) {
    const errMsg = getErrorMessage(error);
    throw new Error(errMsg);
  }
};
