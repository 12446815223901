export const getStoreLogoPayload = (
  dealerCode: string,
  dealerOem: string,
): MGSearchObj | undefined => {
  if (dealerCode && dealerOem) {
    return {
      store_233: dealerCode,
      make: dealerOem.split(","),
      keywords: ["Store", "Logo"],
    };
  }
};
