import { CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";
import styles from "./UndoMessage.module.scss";

type UndoMessageProps = {
  message: string;
  onClick: () => void;
  onClose: () => void;
  alternativeText?: string;
};

export const UndoMessage = ({
  message,
  onClick,
  onClose,
  alternativeText,
}: UndoMessageProps) => {
  return (
    <div className={styles.container} data-cy="undo-message">
      <span>{message}</span>
      <Button type="link" onClick={onClick} data-cy="undo-button">
        {alternativeText ?? "Undo"}
      </Button>
      <Button type="link" onClick={onClose} className={styles.closeContainer}>
        <CloseOutlined className={styles.close} color="#000" />
      </Button>
    </div>
  );
};
