/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Col, Input, Menu, Popover, Row } from "antd";

import {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
  FC,
  MutableRefObject,
  ChangeEvent,
} from "react";

import { setMenuFilter } from "utils/helpers";

import { TextAreaRef } from "antd/lib/input/TextArea";
import { MenuInfo } from "rc-menu/lib/interface";
import {
  IDisclosureVariable,
  IStateDisclosureElement,
  TDisclosureDataType,
  TDisclosureType,
} from "./../../../../shared/types/legalLingo";

import "./../DisclosuresContent.scss";
import { User } from "redux/auth/auth.slice";

interface IDisclosureCollapseContent {
  loggedInUser: User;
  dataType?: TDisclosureDataType;
  variables: IDisclosureVariable[];
  isParentTabActive: boolean;
  disclosureObjFromParent: IStateDisclosureElement;
  setDisclosureObjStateInParent: (
    offerType: TDisclosureType,
    text: string,
    dataType?: TDisclosureDataType,
  ) => void;
}

type NumberAtPriceDisclosureName = "then_input" | "or_else_input";

interface INumberAtPriceDisclosureObj {
  lt_number_of_vehicles?: number;
  max_number_of_vins?: number;
  then_input?: string;
  or_else_input?: string;
}

const DisclosuresCollapseContent: FC<IDisclosureCollapseContent> = ({
  dataType,
  variables,
  isParentTabActive,
  disclosureObjFromParent,
  setDisclosureObjStateInParent,
}) => {
  useEffect(() => {
    if (!isParentTabActive) {
      toggleVariablesDropdown(false);
    }
  }, [isParentTabActive]);

  const textAreaRef = useRef(null);
  const numberAtPriceRefs: Record<
    NumberAtPriceDisclosureName,
    MutableRefObject<TextAreaRef | null>
  > = {
    then_input: useRef(null),
    or_else_input: useRef(null),
  };
  const { offerType, text } = disclosureObjFromParent;
  const [showVariablesDropdown, toggleVariablesDropdown] =
    useState<boolean>(false);

  const [showNumberAtPriceDropdown, setNumberAtPriceDropdown] = useState<
    Record<NumberAtPriceDisclosureName, boolean>
  >({ then_input: false, or_else_input: false });

  const [numberAtPricePrevText, setNumberAtPricePrevText] = useState<
    Record<NumberAtPriceDisclosureName, string>
  >({ then_input: "", or_else_input: "" });

  const caretIndexRef: MutableRefObject<number | null> = useRef<number>(null);
  const [currMenuFilter, setCurrMenuFilter] = useState<string>("");

  const getDifferentIndex = (a: string, b: string) => {
    let i = 0;
    let j = 0;
    let foundIndex: number | null = null;
    while (j < b.length) {
      if (a[i] !== b[j] || i === a.length) {
        foundIndex = j;
      } else {
        i += 1;
      }
      j += 1;
    }
    return foundIndex;
  };

  const isRegularDisc = offerType !== "Number at this Price";

  const numberAtPriceDisclosureObj = useMemo(
    () =>
      !isRegularDisc && text
        ? (JSON.parse(text) as INumberAtPriceDisclosureObj)
        : {},
    [isRegularDisc, text],
  );

  const numberAtThisPriceDiscIds: NumberAtPriceDisclosureName[] = [
    "then_input",
    "or_else_input",
  ];

  const toggleNumberAtPriceDropdown = (
    id: NumberAtPriceDisclosureName,
    value: boolean,
  ) => {
    const newShowDropdown = { ...showNumberAtPriceDropdown, [id]: value };
    setNumberAtPriceDropdown(newShowDropdown);
  };

  const setNumberAtPricePrevTextById = (
    id: NumberAtPriceDisclosureName,
    newText: string,
  ) => {
    const newPrevTextObj = { ...numberAtPricePrevText, [id]: newText };
    setNumberAtPricePrevText(newPrevTextObj);
  };

  const handleTextChange = useCallback(
    (args: {
      inputText: string;
      selectionStart?: number;
      wasPasted?: boolean;
    }) => {
      const { inputText, selectionStart = 0, wasPasted } = args;
      const numberOfCurliesFromVal = inputText.split("{").length;
      const numberOfCurliesFromState = text.split("{").length;

      setDisclosureObjStateInParent(offerType, inputText, dataType);

      const numLeftCurlies = inputText.split("{").length - 1;
      const numRightCurlies = inputText.split("}").length - 1;

      if (wasPasted) return;

      setMenuFilter(
        numLeftCurlies,
        numRightCurlies,
        caretIndexRef,
        selectionStart,
        inputText,
        text,
        currMenuFilter,
        setCurrMenuFilter,
      );

      if (numberOfCurliesFromVal === numberOfCurliesFromState) return;
      if (numberOfCurliesFromVal < numberOfCurliesFromState) {
        toggleVariablesDropdown(false);
        return;
      }

      toggleVariablesDropdown(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [text, currMenuFilter],
  );

  const handleNumberAtPriceTextChange = (
    event: ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { target } = event;
    const { value, id } = target;

    const typedId = id as NumberAtPriceDisclosureName;
    const textVal = numberAtPriceDisclosureObj
      ? numberAtPriceDisclosureObj[typedId] || ""
      : "";

    const numberOfCurliesFromVal = value.split("{").length;
    const numberOfCurliesFromState = textVal.split("{").length;

    setNumberAtPricePrevTextById(typedId, value);

    const newJson = { ...numberAtPriceDisclosureObj, [id]: value };
    const newJsonString = JSON.stringify(newJson);
    setDisclosureObjStateInParent(offerType, newJsonString, dataType);

    toggleNumberAtPriceDropdown(
      typedId,
      numberOfCurliesFromVal > numberOfCurliesFromState,
    );
  };

  const handleNumberAtPriceNumberChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { target } = event;
    const { value, id } = target;

    const typedId = id as "lt_number_of_vehicles" | "max_number_of_vins";

    const newJson = { ...numberAtPriceDisclosureObj, [typedId]: value };
    const newJsonString = JSON.stringify(newJson);
    setDisclosureObjStateInParent(offerType, newJsonString, dataType);
  };

  const handleNumberAtPriceItemSelect = (item: MenuInfo) => {
    const { key } = item;

    const disclosureKey = (
      Object.keys(showNumberAtPriceDropdown) as NumberAtPriceDisclosureName[]
    ).find(discKey => showNumberAtPriceDropdown[discKey]);

    if (!disclosureKey) {
      return;
    }

    const refToUse = numberAtPriceRefs[disclosureKey];
    const prevTextToUse = numberAtPricePrevText[disclosureKey];
    const disclosureToUse = numberAtPriceDisclosureObj[disclosureKey];

    const currCharArray = (disclosureToUse || "").split("");
    const differentIndex = getDifferentIndex(prevTextToUse, text);

    currCharArray[differentIndex || 0] = `{${key}} `;

    let newText = currCharArray.join("");

    if (newText.includes("{{")) {
      newText = newText.replace("{{", "{");
      newText = newText.replace(`${key}} `, `${key}}`);

      const newJson = {
        ...numberAtPriceDisclosureObj,
        [disclosureKey]: newText,
      };
      const newJsonString = JSON.stringify(newJson);

      toggleNumberAtPriceDropdown(disclosureKey, false);
      setDisclosureObjStateInParent(offerType, newJsonString, dataType);
    }

    if (refToUse && refToUse.current) {
      // @ts-ignore: Object is possibly 'null'.
      refToUse.current.focus();
    }
  };

  const handleItemSelect = useCallback(
    (item: MenuInfo) => {
      const { key } = item;
      const keyToInsert = `{${key}}`;

      const firstPart = text.slice(
        0,
        (caretIndexRef.current || 0) - (currMenuFilter.length + 1),
      );
      const secondPart = text.slice(caretIndexRef.current || 0);
      const newText = firstPart.concat(keyToInsert).concat(secondPart);

      setDisclosureObjStateInParent(offerType, newText, dataType);
      setCurrMenuFilter("");
      toggleVariablesDropdown(false);

      if (textAreaRef && textAreaRef.current) {
        // @ts-ignore: Object is possibly 'null'.
        textAreaRef.current.focus();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [text, currMenuFilter.length, offerType, dataType],
  );

  const variablesMenu = useMemo(() => {
    const filteredVariables = variables.filter(variable =>
      variable.name.toLowerCase().includes(currMenuFilter.toLowerCase()),
    );
    return (
      <div style={{ maxHeight: 120, overflowY: "scroll" }}>
        <Menu
          onClick={
            isRegularDisc ? handleItemSelect : handleNumberAtPriceItemSelect
          }
        >
          {filteredVariables.map(variable => (
            <Menu.Item key={`${variable.name}`}>
              {text.includes(`${variable.name}`) ? (
                <b>{variable.name}</b>
              ) : (
                <span>{variable.name}</span>
              )}
            </Menu.Item>
          ))}
        </Menu>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, variables, currMenuFilter, isRegularDisc]);

  return (
    <>
      {isRegularDisc && (
        <Popover
          title="Insert Variable"
          content={variablesMenu}
          visible={showVariablesDropdown}
          placement="bottom"
          onVisibleChange={() => {
            toggleVariablesDropdown(false);
          }}
        >
          <Input.TextArea
            onFocus={() => {
              setCurrMenuFilter("");
            }}
            style={{ width: "100%" }}
            ref={textAreaRef}
            allowClear={true}
            value={text}
            onChange={event => {
              const { target } = event;
              const { value, selectionStart } = target;
              handleTextChange({ inputText: value, selectionStart });
            }}
            onPaste={event => {
              event.preventDefault();
              handleTextChange({
                wasPasted: true,
                inputText: event.clipboardData.getData("Text"),
              });
            }}
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        </Popover>
      )}
      {!isRegularDisc && (
        <>
          <Row
            align="middle"
            gutter={16}
            justify="center"
            style={{ textAlign: "center", paddingTop: "1em" }}
          >
            <Col span={10} className="number-at-price-input-col">
              <span>If Less than:</span>
              <br />
              <Input
                type="number"
                min="0"
                step="1"
                allowClear={true}
                id="lt_number_of_vehicles"
                onChange={handleNumberAtPriceNumberChange}
                value={
                  numberAtPriceDisclosureObj
                    ? numberAtPriceDisclosureObj.lt_number_of_vehicles
                    : undefined
                }
              />
            </Col>
            <Col span={4} />
            <Col span={10} className="number-at-price-input-col">
              <span>Max # to List:</span>
              <br />
              <Input
                onFocus={() => {
                  setCurrMenuFilter("");
                }}
                type="number"
                min="0"
                step="1"
                allowClear={true}
                id="max_number_of_vins"
                onChange={handleNumberAtPriceNumberChange}
                value={
                  numberAtPriceDisclosureObj
                    ? numberAtPriceDisclosureObj.max_number_of_vins
                    : undefined
                }
              />
            </Col>
          </Row>
          <br />
          {numberAtThisPriceDiscIds.map(key => {
            const title = key
              .split("input")[0]
              .split("_")
              .join(" ")
              .toUpperCase();
            return (
              <Row
                align="middle"
                gutter={16}
                justify="center"
                key={`${key}-row`}
                style={{ paddingTop: "2em" }}
              >
                <Col span={24}>
                  <h2 style={{ textAlign: "center" }}>{title}</h2>
                  <Popover
                    title="Insert Variable"
                    content={variablesMenu}
                    visible={showNumberAtPriceDropdown[key]}
                    autoAdjustOverflow={true}
                    placement="bottom"
                    onVisibleChange={() => {
                      toggleNumberAtPriceDropdown(key, false);
                    }}
                  >
                    <Input.TextArea
                      ref={numberAtPriceRefs[key]}
                      name="number-at-this-price-disclosure"
                      id={key}
                      allowClear={true}
                      value={
                        numberAtPriceDisclosureObj
                          ? numberAtPriceDisclosureObj[key]
                          : undefined
                      }
                      onChange={handleNumberAtPriceTextChange}
                      autoSize={{ minRows: 2, maxRows: 6 }}
                    />
                  </Popover>
                </Col>
              </Row>
            );
          })}
        </>
      )}
    </>
  );
};

export default DisclosuresCollapseContent;
