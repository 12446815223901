import { Tooltip, TooltipProps } from "antd";
import { memo } from "react";

interface IProps {
  children: string;
  width?: number;
  className?: string;
  tooltipProps?: Partial<TooltipProps>;
}

/**
 * Truncates the text if it is longer than the container and adds a tooltip to show the full text.
 * note: Use antd Typography https://ant.design/components/typography/ if possible instead of this component.
 * This one was created because antd Typography.Text with ellipsis is not working with virtual table.
 */
const AutoEllipsisText = ({
  children: text,
  width,
  className,
  tooltipProps,
}: IProps) => {
  return (
    <Tooltip title={text} {...(tooltipProps ?? {})}>
      <div
        className={className}
        style={{
          width: width ?? "100%",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {text}
      </div>
    </Tooltip>
  );
};

export const renderColumnWithEllipsis = (text: string, width?: number) => (
  <AutoEllipsisText width={width}>{text}</AutoEllipsisText>
);

export default memo(AutoEllipsisText);
