import { IConfig } from "shared/types/configuration";

import { IApiResponse, IGetResizedImgQueryParams } from "shared/types/shared";
import HttpClient from "./httpClient";

export default ({ config }: { headers: any; config: IConfig }) => ({
  generatePresignedUrl: (params: {
    contentType: string;
    filename: string;
    s3Path: string;
  }): Promise<IApiResponse<{ url: string }>> => {
    const uploadParams: { file: string; contentType: string; path: string } = {
      file: params.filename,
      contentType: params.contentType,
      path: params.s3Path,
    };

    return HttpClient.post(config.services.getSignedPutUrlUrl, {
      uploadParams,
    });
  },
  getScreenshot: (params: { url: string; width: number; height: number }) => {
    const queryString = new URLSearchParams({
      ...params,
      width: `${params.width}`,
      height: `${params.height}`,
    }).toString();

    return HttpClient.get<{ result: string; error: { message: string } }>(
      config.services.getScreenshotUrl(queryString),
    );
  },
  getResizedImg: (params: IGetResizedImgQueryParams) => {
    return HttpClient.get<{ result: string; error: { message: string } }>(
      config.services.getResizedImgUrl(params),
    );
  },
  getClientVersion: () => {
    return fetch(config.services.getClientVersionUrl);
  },
});
