import {
  IArtboard,
  ICreateArtboardResponse,
  IGenericResponse,
} from "shared/types/designStudio";

import API from "services";
import { IConfig } from "shared/types/configuration";
import {
  createArtboardBegin,
  createArtboardFail,
  createArtboardSuccess,
  updateArtboardBegin,
  updateArtboardFail,
  updateArtboardSuccess,
  resetArtboard,
  deleteArtboardBegin,
  deleteArtboardFail,
  deleteArtboardSuccess,
} from "redux/designStudio/designStudio.slice";
import { AppThunk } from "redux/store";

export const createArtboard =
  (artboard: IArtboard): AppThunk =>
  async (dispatch, getState) => {
    dispatch(createArtboardBegin());

    const { configuration } = getState();
    const { config } = configuration;

    const request: RequestInfo = new Request(
      (config as IConfig).services.designStudio.createArtboardUrl,
      {
        method: "POST",
        body: JSON.stringify({
          artboard,
        }),
      },
    );

    const { result, error } = await API.send<ICreateArtboardResponse>(request);

    if (error) {
      dispatch(createArtboardFail(artboard));
    }

    const { createdArtboard } = result;

    dispatch(createArtboardSuccess(createdArtboard));

    setTimeout(() => {
      dispatch(resetArtboard());
    }, 1000);
  };

export const updateArtboard =
  (artboard: IArtboard): AppThunk =>
  async (dispatch, getState) => {
    dispatch(updateArtboardBegin());
    const { configuration } = getState();

    const request: RequestInfo = new Request(
      configuration.config!.services.designStudio.createArtboardUrl,
      {
        method: "put",
        body: JSON.stringify({
          artboard,
        }),
      },
    );

    const { error, result } = await API.send<
      IGenericResponse<{ updatedArtboard: IArtboard }>
    >(request);

    if (error || !result) {
      const errorObj = error || {
        name: "",
        message: `The artboard could not be updated`,
      };
      dispatch(updateArtboardFail(errorObj));
    } else {
      dispatch(updateArtboardSuccess(result.updatedArtboard));
    }

    setTimeout(() => {
      dispatch(resetArtboard());
    }, 1000);
  };

export const deleteArtboard =
  (artboard: IArtboard): AppThunk =>
  async (dispatch, getState) => {
    dispatch(deleteArtboardBegin());
    const { configuration } = getState();

    const request: RequestInfo = new Request(
      configuration.config!.services.designStudio.createArtboardUrl,
      {
        method: "delete",
        body: JSON.stringify(artboard),
      },
    );

    const { error, result } = await API.send<
      IGenericResponse<{ deletedArtboard: IArtboard }>
    >(request);

    if (error || !result) {
      const errorObj = error || {
        name: "",
        message: `The artboard could not be deleted`,
      };
      dispatch(deleteArtboardFail(errorObj));
    } else {
      dispatch(deleteArtboardSuccess(result.deletedArtboard));
    }

    setTimeout(() => {
      dispatch(resetArtboard());
    }, 1000);
  };
