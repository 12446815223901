import { SearchOutlined } from "@ant-design/icons";
import { Button, Select, Space, Typography } from "antd";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { useState } from "react";
import styles from "./dimensionsFilterDropdown/DimensionsFilterDropdown.module.scss";
import { formatDimensions } from "utils/helpers";
import { IDimension } from "shared/types/designStudio";
import { dedupe } from "utils/helpers.array";

type DimensionsFilterDropdownProps = FilterDropdownProps & {
  items: IDimension[];
};

const canConfirmFilter = (
  dimensions: Partial<IDimension>,
): dimensions is IDimension =>
  dimensions.height !== undefined && dimensions.width !== undefined;

export const DimensionsFilterDropdown = ({
  setSelectedKeys,
  confirm,
  clearFilters,
  items,
}: DimensionsFilterDropdownProps) => {
  const [dimensions, setDimensions] = useState<Partial<IDimension>>({});

  const widths = items
    .map(item => item.width)
    .filter(dedupe)
    .filter(width => {
      if (!dimensions.height) return true;

      return !!items.find(
        item => item.width === width && item.height === dimensions.height,
      );
    })
    .sort();

  const heights = items
    .map(item => item.height)
    .filter(dedupe)
    .filter(height => {
      if (!dimensions.width) return true;
      return !!items.find(
        item => item.height === height && item.width === dimensions.width,
      );
    })
    .sort();

  const setDefaultDimensions = () =>
    setDimensions({ width: undefined, height: undefined });

  const updateDimension = (dimension: keyof IDimension) => (value: number) =>
    setDimensions({ ...dimensions, [dimension]: value });

  const resetFilter = () => {
    clearFilters && clearFilters();
    setSelectedKeys([]);
    setDefaultDimensions();
  };

  const confirmFilter = () => {
    if (canConfirmFilter(dimensions)) {
      setSelectedKeys([formatDimensions(dimensions)]);
      confirm({
        closeDropdown: true,
      });
    }
  };

  return (
    <Space
      direction="vertical"
      className={styles.dimensionsFilterDropdownContainer}
    >
      <Typography.Text>Width</Typography.Text>
      <Select<number>
        showSearch
        className={styles.select}
        allowClear
        value={dimensions.width}
        onChange={updateDimension("width")}
      >
        {widths.map(width => (
          <Select.Option key={width} value={width}>
            {width}
          </Select.Option>
        ))}
      </Select>

      <Typography.Text>Height</Typography.Text>
      <Select
        showSearch
        className={styles.select}
        allowClear
        value={dimensions.height}
        onChange={updateDimension("height")}
      >
        {heights.map(height => (
          <Select.Option key={height} value={height}>
            {height}
          </Select.Option>
        ))}
      </Select>
      <Space direction="horizontal">
        <Button
          onClick={resetFilter}
          size="small"
          className={styles.resetButton}
        >
          Reset
        </Button>
        <Button
          disabled={!canConfirmFilter(dimensions)}
          type="primary"
          onClick={confirmFilter}
          icon={<SearchOutlined />}
          size="small"
          className={styles.confirmButton}
        >
          Filter
        </Button>
      </Space>
    </Space>
  );
};
