import { Badge, Typography, Space } from "antd";
import styles from "./DataListStatusCell.module.scss";

type Props = {
  item: string;
  isDisabled?: boolean;
};

export const DataListStatusCell = ({ item, isDisabled }: Props) => {
  const isPublished = item === "PUBLISHED";
  const badgeStatus = isPublished ? "success" : "error";
  const text = isPublished ? "Published" : "Unpublished";

  return (
    <Space direction="horizontal">
      <Badge status={badgeStatus} />
      <Typography className={isDisabled ? styles.statusDisabled : ""}>
        {text}
      </Typography>
    </Space>
  );
};
