import { flatten } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import API from "services";
import {
  IAdHistorySession,
  IModifyAdHistorySessionResponseData,
  AdLoadModifyRulesPresetOperation,
  CachedAdHistorySessionsPageData,
} from "shared/types/adLibrary";
import { IApiResponse } from "shared/types/shared";

interface IModifySessionArgs {
  session: IAdHistorySession;
  operation: AdLoadModifyRulesPresetOperation;
}

const modifySession = async (args: {
  session: IAdHistorySession;
  operation: AdLoadModifyRulesPresetOperation;
}) => {
  const { result, error } =
    await API.services.adLibrary.modifyAdHistorySessions(
      args.session,
      args.operation,
    );

  if (error || !result) {
    throw Error(error?.message || "Something went wrong.");
  }

  return result;
};

export const useOptimisticallyMutateSession = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IModifyAdHistorySessionResponseData,
    IApiResponse<IModifyAdHistorySessionResponseData>,
    IModifySessionArgs,
    { previousSessions: IAdHistorySession[] }
  >(modifySession, {
    onMutate: async (args: IModifySessionArgs) => {
      await queryClient.cancelQueries("adHistorySessionsPages");

      const previousSessionsPages: CachedAdHistorySessionsPageData | undefined =
        queryClient.getQueryData("adHistorySessionsPages");

      const mapSessions = (prevSession: IAdHistorySession) =>
        prevSession.id === args.session.id ? args.session : prevSession;

      // Snapshot the previous value
      const previousSessions: IAdHistorySession[] | undefined = flatten(
        previousSessionsPages?.pages.map(page =>
          page.sessions.map(mapSessions),
        ),
      );

      queryClient.setQueryData<typeof previousSessionsPages>(
        "adHistorySessionsPages",
        prevData =>
          prevData
            ? {
                pageParams: prevData?.pageParams ?? [],
                pages: prevData?.pages.map(page => ({
                  paginationKey: page.paginationKey,
                  sessions: page.sessions.map(mapSessions),
                })),
              }
            : undefined,
      );

      return { previousSessions };
    },
    onSettled: () => {
      queryClient.invalidateQueries("adHistorySessionsPages");
    },
  });
};
