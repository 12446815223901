import { useMemo } from "react";
import { getNormalizedCampaignsData } from "../campaignData.utils";
import { useSelectedAcctCampaigns } from "./useSelectedAcctCampaigns";
import { useAdLoadSelectedStores } from "./useAdLoadSelectedStores";
import { useAds } from "screens/adLibrary/shared/hooks/useAds";
import { useCurrentSession } from "./useCurrentSession";
import { nonNullable } from "utils/helpers.array";

export const useNormalizedCampaignsData = () => {
  const { adDests, session } = useCurrentSession();

  const { campaignResByAcctId, isLoadingCampaigns } =
    useSelectedAcctCampaigns();
  const { selectedStores } = useAdLoadSelectedStores();
  const { originalData: ads } = useAds();

  const adsToLoad = useMemo(
    () =>
      session.data.adIdsToLoad
        .map(adId => ads.find(ad => ad.id === adId))
        .filter(nonNullable),
    [session, ads],
  );

  const campaignData = useMemo(
    () =>
      getNormalizedCampaignsData({
        campaignResByAcctId,
        selectedStores,
        adDests,
        ads,
        adsToLoad,
      }),
    [campaignResByAcctId, selectedStores, adDests, ads, adsToLoad],
  );

  return {
    campaignData,
    isLoadingCampaigns,
  };
};
