import { Badge, Typography } from "antd";
import { AppColors } from "shared/types/shared";
import { LoadStatus } from "../../shared/types";

type LoadStatusWithMixed = LoadStatus | "Mixed";
type LoadStatusBadgeCellProps = {
  status: LoadStatusWithMixed;
};

const getColor = (status: LoadStatusWithMixed) => {
  switch (status) {
    case "Loaded":
      return AppColors.success;
    case "Failed":
      return AppColors.danger;
    case "Loading":
      return AppColors.primary;
    case "Mixed":
      return AppColors.warning;
  }
};

export const LoadStatusBadgeCell = ({ status }: LoadStatusBadgeCellProps) => {
  return (
    <Badge
      color={getColor(status)}
      text={<Typography.Text>{status}</Typography.Text>}
    />
  );
};
