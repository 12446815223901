import { Col, message as AntMessage, notification, Row, Spin } from "antd";

import { FC, useCallback, useEffect, useState } from "react";

import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { useRouteQuery } from "shared/hooks/useRouteQuery";

// redux
import actions from "../redux/rootActions";

// types
import { IDataTableError } from "shared/types/errors";
import { OperationMode } from "shared/types/inputValues";
import { IGetBrandsResult, IBrandRecord } from "shared/types/brandManagement";
import {
  IUploadImageFormInput,
  IUploadImagesResult,
  TUploadGroup,
  TUploadLogosDictionary,
  TUploadLogosS3DictionaryKey,
} from "shared/types/uploadManagement";

// components
import { CAM_ENABLED } from "shared/components/media";
import OemDrawer from "./oemManagement/OemDrawer";
import OemTable from "./oemManagement/OemTable";
import { MediaOemDrawer } from "./oemManagement/MediaOemDrawer";
import { useNavigate } from "react-router-dom";
import { industryType } from "utils/helpers";
import { useIsAdmin } from "shared/hooks/useIsAdmin";

interface IOemManagement {
  loggedInRole: string;
  oemsResult: null | IGetBrandsResult;
  oemsError: null | IDataTableError;
  oemsMessage: string;
  processingOems: boolean;
  oems: IBrandRecord[];
  getOems: (paginationToken?: string) => void;
  createOem: (inputOem: IBrandRecord) => void;
  updateOem: (inputOem: IBrandRecord) => void;
  deleteOem: (inputOem: IBrandRecord) => void;
  addOrDeleteOemToDynamoDb: (oem: any, action: string) => void;
  resetOemFeedback: () => void;
  uploadImages: (
    imagesToUpload: IUploadImageFormInput[],
    featureName: string,
    group: TUploadGroup,
    mode: OperationMode,
    logoKeysToBeUploaded?: string[],
    newOemButtonClicked?: boolean,
  ) => void;
  resetUploadImagesInRedux: () => void;
  processingUpload: boolean;
  uploadResult: IUploadImagesResult;
  uploadError: null | IDataTableError;
  s3AssetBucket: string;

  imagesToUpload: { images: IUploadImagesResult[] };

  logosFromS3InDictionary: TUploadLogosDictionary;

  logosUploadStatus: null | "UPLOADING" | "COMPLETE";
  setLogosUploadStatus: (status: null | "UPLOADING" | "COMPLETE") => void;
}

const OemManagement: FC<IOemManagement> = ({
  loggedInRole,
  oemsResult,
  oemsError,
  oemsMessage,
  processingOems,
  oems,
  getOems,
  createOem,
  updateOem,
  deleteOem,
  uploadImages,
  resetUploadImagesInRedux,
  processingUpload,
  uploadError,
  s3AssetBucket,
  addOrDeleteOemToDynamoDb,
  resetOemFeedback,
  imagesToUpload,

  logosFromS3InDictionary,
  logosUploadStatus,
  setLogosUploadStatus,
}) => {
  const navigate = useNavigate();
  const oemNameToEdit = useRouteQuery("oem_name");
  const isAdmin = useIsAdmin();

  const [oemToEditWithLogos, setOemToEditWithLogos] = useState<IBrandRecord>({
    key: 0,
    oemName: "",
    logoUrlsFromS3: {
      horizontalImagesFromS3: [],
      verticalImagesFromS3: [],
      squareImagesFromS3: [],
      horizontalEventImagesFromS3: [],
      verticalEventImagesFromS3: [],
      squareEventImagesFromS3: [],
    },

    logoUrl: "",
    eventLogos: [],
  });

  // This is used to see if any changes have been made in the drawer
  const [compareOemToEditWithLogos, setCompareOemToEditWithLogos] =
    useState<IBrandRecord>({
      key: 0,
      oemName: "",
      logoUrlsFromS3: {
        horizontalImagesFromS3: [],
        verticalImagesFromS3: [],
        squareImagesFromS3: [],
        horizontalEventImagesFromS3: [],
        verticalEventImagesFromS3: [],
        squareEventImagesFromS3: [],
      },

      logoUrl: "",
      eventLogos: [],
    });
  const [mode, setMode] = useState<OperationMode>("");

  const [showAddOemDrawer, setShowAddOemDrawer] = useState<boolean>(false);

  const pathParam = industryType("auto")
    ? "oem-management"
    : "brand-management";

  const toggleAddOemDrawer = useCallback(
    (show: boolean, mode: OperationMode, oemNameSelect?: string) => {
      if (show && mode === "UPDATE" && oemNameSelect) {
        navigate(`/${pathParam}?oem_name=${oemNameSelect}`);
      }
      if (!show) {
        navigate(`/${pathParam}`);
      }
      setShowAddOemDrawer(show);
      setMode(mode);
    },
    [navigate, pathParam],
  );
  const [newOemButtonClicked, toggleNewOemButtonClicked] =
    useState<boolean>(false);

  // "horizontalLogos" | "verticalLogos" | "squareLogos" | "horizontalLogosEvent" | "verticalLogosEvent" | "squareLogosEvent"
  const [logoKeysToBeUploaded, setLogoKeysToBeUploaded] = useState<string[]>(
    [],
  );

  useEffect(() => {
    if (!oemToEditWithLogos || !oemToEditWithLogos.logoUrlsFromS3) {
      return;
    }

    if (newOemButtonClicked && logosFromS3InDictionary) {
      if (logoKeysToBeUploaded.length === 0) return;
      // check if logosFromS3InDictionary matches up with logoKeysToBeUploaded
      for (const key of logoKeysToBeUploaded) {
        const type = key.split("Logos")[0]; // horizontal, vertical, square
        const tempKey = key.includes("Event")
          ? `${type}EventImagesFromS3`
          : `${type}ImagesFromS3`;

        if (
          logosFromS3InDictionary[tempKey as TUploadLogosS3DictionaryKey]
            .length === 0
        ) {
          return;
        }
      }
      // If we reach this point without returning, then it matches up.
      // So reset logoKeysToBeUploaded to an empty array.
      // This is so that if we hit this useEffect again, we won't setOemToEditWithLogos twice

      setLogoKeysToBeUploaded([]);
    }

    const { logoUrlsFromS3 } = oemToEditWithLogos;
    const {
      horizontalEventImagesFromS3,
      horizontalImagesFromS3,
      verticalEventImagesFromS3,
      verticalImagesFromS3,
      squareEventImagesFromS3,
      squareImagesFromS3,
    } = logoUrlsFromS3;

    const tempOemToEditWithLogos =
      mode === "UPDATE" || newOemButtonClicked
        ? {
            horizontalImagesFromS3: [
              ...horizontalImagesFromS3,
              ...logosFromS3InDictionary.horizontalImagesFromS3,
            ],
            verticalImagesFromS3: [
              ...verticalImagesFromS3,
              ...logosFromS3InDictionary.verticalImagesFromS3,
            ],
            squareImagesFromS3: [
              ...squareImagesFromS3,
              ...logosFromS3InDictionary.squareImagesFromS3,
            ],
            horizontalEventImagesFromS3: [
              ...horizontalEventImagesFromS3,
              ...logosFromS3InDictionary.horizontalEventImagesFromS3,
            ],
            verticalEventImagesFromS3: [
              ...verticalEventImagesFromS3,
              ...logosFromS3InDictionary.verticalEventImagesFromS3,
            ],
            squareEventImagesFromS3: [
              ...squareEventImagesFromS3,
              ...logosFromS3InDictionary.squareEventImagesFromS3,
            ],
          }
        : {
            horizontalImagesFromS3:
              logosFromS3InDictionary.horizontalImagesFromS3,

            verticalImagesFromS3: logosFromS3InDictionary.verticalImagesFromS3,

            squareImagesFromS3: logosFromS3InDictionary.squareImagesFromS3,

            horizontalEventImagesFromS3:
              logosFromS3InDictionary.horizontalEventImagesFromS3,

            verticalEventImagesFromS3:
              logosFromS3InDictionary.verticalEventImagesFromS3,

            squareEventImagesFromS3:
              logosFromS3InDictionary.squareEventImagesFromS3,
          };

    setOemToEditWithLogos({
      ...oemToEditWithLogos,
      logoUrlsFromS3: tempOemToEditWithLogos,
    });

    if (newOemButtonClicked) {
      setTimeout(() => {
        setLogosUploadStatus("COMPLETE");
        setLogosUploadStatus(null);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logosFromS3InDictionary]);

  useEffect(() => {
    /*
      An ancestor component handles scrolling within the view,
      so a ref cannot be used here currently
      The first .ant-layout-content element is always the main app content component
      This component directly works with the scroll bar
    */
    const layoutContentElement = document.querySelector(".ant-layout-content");
    const { scannedCount, paginationKey = { oem_name: "" } } = oemsResult || {
      scannedCount: 0,
      paginationKey: { oem_name: "" },
    };
    if (layoutContentElement) {
      const handleScroll = (event: any) => {
        const { target } = event;
        if (target) {
          const { scrollHeight, clientHeight, scrollTop } = target;
          if (
            scrollTop >= scrollHeight - clientHeight &&
            oems.length < scannedCount
          ) {
            const backupKey =
              oems.length > 0 && oems[oems.length - 1]
                ? oems[oems.length - 1].oemName
                : "";
            getOems(paginationKey.oem_name || backupKey);
          }
        }
      };

      layoutContentElement.addEventListener("scroll", handleScroll);

      return () =>
        layoutContentElement.removeEventListener("scroll", handleScroll);
    }

    // eslint-disable-next-line
  }, [oemsResult]);

  useEffect(() => {
    getOems();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { paginationKey, scannedCount } = oemsResult || {
      paginationKey: undefined,
      scannedCount: 0,
    };

    if (!paginationKey || oems.length >= scannedCount) {
      return;
    }

    getOems(paginationKey.oem_name || "");
  }, [getOems, oems, oemsResult]);

  useEffect(() => {
    if (oemsError) {
      AntMessage.error(`OEMs error: ${oemsError.message}`);
      resetOemFeedback();
    }
  }, [oemsError, resetOemFeedback]);

  useEffect(() => {
    if (uploadError) {
      AntMessage.error(`Upload error: ${uploadError.message}`);
    }
  }, [uploadError]);

  useEffect(() => {
    if (oemsMessage) {
      AntMessage.success(oemsMessage);
      getOems();
      resetOemFeedback();
    }

    // eslint-disable-next-line
  }, [oemsMessage]);

  const isLoading = processingOems || oemsResult?.paginationKey?.oem_name;

  useEffect(() => {
    const oemToEdit = oems.find(oem => oem.oemName === oemNameToEdit);
    if (oemToEdit) {
      setOemToEditWithLogos(prev => ({ ...prev, ...oemToEdit }));
      setCompareOemToEditWithLogos(oemToEdit);
      toggleAddOemDrawer(true, "UPDATE");
    }
    if (!isLoading && oems?.length && oemNameToEdit && !oemToEdit) {
      notification.info({
        message: "The oem you are trying to edit does not exist",
      });
      navigate("/oem-management", { replace: true });
    }
  }, [oemNameToEdit, oems, isLoading, toggleAddOemDrawer, navigate]);

  const oemDrawerProps = {
    mode,
    processingOems,
    processingUpload,
    showAddOemDrawer,

    s3AssetBucket,
  };

  const onClickNew = () => {
    setOemToEditWithLogos({
      key: 0,
      oemName: "",
      logoUrlsFromS3: {
        horizontalImagesFromS3: [],
        verticalImagesFromS3: [],
        squareImagesFromS3: [],
        horizontalEventImagesFromS3: [],
        verticalEventImagesFromS3: [],
        squareEventImagesFromS3: [],
      },

      logoUrl: "",
      eventLogos: [],
    });
    resetUploadImagesInRedux();
    toggleAddOemDrawer(true, "CREATE");
  };

  return (
    <>
      <Row>
        <Spin spinning={processingOems} size="large">
          <Col span={24}>
            <OemTable
              onClickNew={onClickNew}
              onClickEdit={(record: IBrandRecord) => {
                setOemToEditWithLogos(record);
                setCompareOemToEditWithLogos(record);
                toggleAddOemDrawer(true, "UPDATE", record.oemName);
              }}
              oems={oems}
              processingUpload={processingUpload}
              userRole={loggedInRole}
            />
          </Col>
        </Spin>
      </Row>
      {CAM_ENABLED ? (
        <MediaOemDrawer
          indTypeAuto={industryType("auto")}
          allowDeleteOem={isAdmin}
          allowDeleteOemImage={isAdmin}
          oemToEditWithLogos={oemToEditWithLogos}
          isProcessing={processingOems}
          showAddOemDrawer={showAddOemDrawer}
          toggleAddOemDrawer={(show, mode, oemNameSelect) => {
            onClickNew();
            toggleAddOemDrawer(show, mode, oemNameSelect);
          }}
          oems={oems}
          onDeleteOem={() => {
            deleteOem(oemToEditWithLogos);
            addOrDeleteOemToDynamoDb(oemToEditWithLogos.oemName, "DELETE");
            toggleAddOemDrawer(false, "");
          }}
          onCreateOem={oemName => {
            createOem({ ...oemToEditWithLogos, oemName });
            addOrDeleteOemToDynamoDb(oemName, "ADD");
            toggleAddOemDrawer(true, "UPDATE", oemName);
          }}
          onChangeOem={oemName => {
            setOemToEditWithLogos(prev => ({
              ...prev,
              oemName,
            }));
          }}
          onUpdateOem={updateOem}
        />
      ) : (
        <OemDrawer
          {...oemDrawerProps}
          closeAddOemDrawer={() => {
            toggleAddOemDrawer(false, "");
          }}
          deleteOem={deleteOem}
          addOrDeleteOemToDynamoDb={addOrDeleteOemToDynamoDb}
          oems={oems}
          submitOemForm={oemForm => {
            switch (mode) {
              case "CREATE":
                createOem(oemForm);
                break;
              case "UPDATE":
                updateOem(oemForm);
                break;
              default:
                break;
            }
          }}
          uploadImages={uploadImages}
          imagesToUpload={imagesToUpload}
          oemToEditWithLogos={oemToEditWithLogos}
          setOemToEditWithLogos={setOemToEditWithLogos}
          resetUploadImagesInRedux={resetUploadImagesInRedux}
          toggleNewOemButtonClicked={toggleNewOemButtonClicked}
          newOemButtonClicked={newOemButtonClicked}
          logosUploadStatus={logosUploadStatus}
          setLogoKeysToBeUploaded={setLogoKeysToBeUploaded}
          logoKeysToBeUploaded={logoKeysToBeUploaded}
          setCompareOemToEditWithLogos={setCompareOemToEditWithLogos}
          compareOemToEditWithLogos={compareOemToEditWithLogos}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { auth, oemManagement, uploadManagement, configuration } = state;
  const { config } = configuration;
  const {
    imagesToUpload,
    oemRecords,
    processingOems,
    error: oemsError,
    result: oemsResult,
    oemsMessage,
  } = oemManagement;

  const {
    processingUpload,
    result: uploadResult,
    error: uploadError,

    logosFromS3InDictionary,

    logosUploadStatus,
  } = uploadManagement;
  return {
    loggedInRole: auth.user?.role,
    oems: oemRecords,
    processingOems,
    oemsError,
    oemsResult,
    oemsMessage,
    processingUpload,
    uploadResult,
    uploadError,
    s3AssetBucket: config.s3AssetBucket,
    imagesToUpload,

    logosFromS3InDictionary,

    logosUploadStatus,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => {
  return {
    getOems: (paginationToken = "") => {
      dispatch(actions.oemManagement.getOems(paginationToken));
    },
    createOem: (inputOem: IBrandRecord) => {
      dispatch(actions.oemManagement.createOem(inputOem));
    },
    updateOem: (inputOem: IBrandRecord) => {
      dispatch(actions.oemManagement.updateOem(inputOem));
    },
    deleteOem: (inputOem: IBrandRecord) => {
      dispatch(actions.oemManagement.deleteOem(inputOem));
    },
    resetOemFeedback: () => {
      dispatch(actions.oemManagement.resetOemFeedback());
    },
    uploadImages: (
      imagesToUpload: IUploadImageFormInput[],
      featureName: string,
      group: TUploadGroup,
      mode: OperationMode,
      logoKeysToBeUploaded?: string[],
      newOemButtonClicked?: boolean,
    ) => {
      dispatch(
        actions.uploadManagement.uploadImages(
          imagesToUpload,
          featureName,
          group,
          mode,
          logoKeysToBeUploaded,
          newOemButtonClicked,
        ),
      );
    },
    addOrDeleteOemToDynamoDb: (oem: any, action: string) => {
      dispatch(actions.legalLingo.addOrDeleteOemToDynamoDb(oem, action));
    },

    resetUploadImagesInRedux: () => {
      dispatch(actions.uploadManagement.resetUploadImagesInRedux());
    },
    setLogosUploadStatus: (status: null | "UPLOADING" | "COMPLETE") => {
      dispatch(actions.uploadManagement.setLogosUploadStatus(status));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OemManagement);
