import { ReactComponent as PlayButton } from "statics/images/play_arrow.svg";
import { ReactComponent as PauseButton } from "statics/images/pause.svg";

import "./VideoControl.scss";

interface Props {
  videoControlInfo: {
    id: string;
    top: number;
    left: number;
    width: number;
    height: number;
  };
  isPlaying: boolean;
}

interface Handlers {
  onPlay: () => void;
  onPause: () => void;
}

type VideoControlProps = Props & Handlers;

const VideoControl = (props: VideoControlProps) => {
  return (
    <div
      className="video-control"
      style={{
        top: props.videoControlInfo.top,
        left: props.videoControlInfo.left,
        width: props.videoControlInfo.width,
        height: props.videoControlInfo.height,
      }}
    >
      {!props.isPlaying && <PlayButton onClick={props.onPlay} />}

      {props.isPlaying && <PauseButton onClick={props.onPause} />}
    </div>
  );
};

export default VideoControl;
