import { QueryClient } from "react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      notifyOnChangeProps: "tracked",
      staleTime: 300000,
    },
  },
});

if (window.Cypress) {
  queryClient.setDefaultOptions({
    queries: {
      retry: false,
    },
  });
}
