import { ContextMenu } from "../../ContextMenu";
import { useSelected } from "../../useSelected";
import { Asset } from "./Asset";
import { Folder } from "./Folder";

import styles from "./Resource.module.scss";

type Props = {
  resource: WDFolder | WDAsset;
};

export const Resource = ({ resource }: Props) => {
  const { selectedIds } = useSelected();

  return (
    <div
      className={styles.host}
      data-selected={selectedIds.includes(resource.id)}
    >
      <ContextMenu record={resource}>
        <div>
          {resource.type === "asset" ? (
            <Asset resource={resource} />
          ) : (
            <Folder resource={resource} />
          )}
        </div>
      </ContextMenu>
    </div>
  );
};
