import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _, { negate } from "lodash";
import { queryClient } from "queryClient";
import {
  getInstantExperiencesIssues,
  hasInstantExperienceIssues,
} from "screens/adLibrary/shared/validationUtils";

import GenericError from "shared/errors/GenericError";
import {
  IButtonElement,
  IElementBasicFields,
  IElementFieldErrors,
  IInstantExperience,
  IInstantExperienceValidations,
  InstantExperienceBodyElement,
} from "shared/types/adLibrary";

import { IAccount } from "shared/types/accountManagement";
import {
  AllPublishedStatus,
  FetchingDataStatus,
  IArtboard,
  IDesignStudioState,
  ILayerObject,
  INewStamp,
  IPreviewOfferDataObj,
  IPublishCanvasStatus,
  IStamp,
  IStampExceptionType,
  ITemplate,
  ITemplateTag,
  TemplateListCardToggleProp,
} from "shared/types/designStudio";
import { ILabel } from "shared/types/inputValues";
import { IBrand } from "shared/types/brandManagement";
import { isFooter } from "utils/adLibrary.validators";
import { reorderList } from "utils/helpers";
import { isLastElement } from "utils/helpers.designStudio";
import uuid from "uuid";

const initialState: IDesignStudioState = {
  templates: [],
  fetchingData: null,
  updatingData: null,
  savingDraft: null,

  oems: [],
  paginationKey: null,
  stores: [],
  tags: [],
  artboards: [],
  stamps: [],
  templateToUpdate: null,
  templateToEditor: null,
  stampToEditor: null,
  creatingData: null,
  deletingDataId: null,
  publishingTemplateId: null,

  imageInsertData: null,

  canvasJson: null,

  error: null,

  canvasObjectToAlterIndex: null,
  canvasArrangeValue: "",
  activeLayerObjects: [],
  activeTextSelection: null,
  currentSelectionStyles: [],
  layerObjects: [],
  currentLayerObjectId: "",
  currentLayerOperation: "",
  currentLayerToggleOpProp: "",
  currentTextOperation: "",

  publishCanvasStatus: null,

  stampToUpdate: null,
  artboardToUpdate: null,
  fabricHistory: {
    past: [],
    currentHistoryIndex: null,
    actionType: null,
  },

  fabricClipboard: {
    clipboardObject: null,
    actionType: null,
  },

  alignLayersActionType: null,

  originalFabricObjJson: null,

  selectedStampOfferType: null,

  randomizingData: null,
  previewOfferData: null,
  selectedFilterOem: null,
  selectedFilterStore: null,
  selectedFilterTags: null,
  selectedFilterOfferCounts: "",

  selectedFilterOemStamp: null,
  selectedFilterStateStamp: null,
  selectedFilterDescriptorStamp: null,

  uniqueUUID: "",

  searchInput: "",
  templatesAllPublishedUnpublished: "ALL",

  stampException: {
    type: "state",
    value: "default",
  },

  canvasEditId: null,

  duplicatedTemplate: null,
  selectedFilterDimensionsArtboard: undefined,
  selectedFilterDimensionsTemplate: undefined,

  instantExperienceDraft: null,
  instantExperienceDraftValidations: null,
  selectedInstantExperienceElementId: undefined,

  instantExperienceImport: {
    displayImportTable: false,
    instantExperiencesToImport: [],
    cancelImport: false,
    selectedInstantExperienceIds: [],
    processingInstantExperiences: false,
    instantExperienceImportIssues: [],
  },
  disabledPublishButton: true,
};

const designStudioSlice = createSlice({
  name: "designStudio",
  initialState,
  reducers: {
    setInstantExperienceDraft: {
      reducer(
        state,
        action: PayloadAction<{
          instantExperienceDraft: IInstantExperience | undefined | null;
          wasSaved?: boolean;
        }>,
      ) {
        const { instantExperienceDraft, wasSaved } = action.payload;
        return {
          ...state,
          instantExperienceDraft: instantExperienceDraft,
          instantExperienceDraftValidations: getInstantExperienceValidations(
            instantExperienceDraft,
            state.instantExperienceDraftValidations,
            false,
          ),
          ...(wasSaved
            ? { instantExperienceSaved: instantExperienceDraft }
            : {}),
        };
      },
      prepare(
        instantExperienceDraft: IInstantExperience | undefined | null,
        wasSaved = false,
      ) {
        return {
          payload: {
            instantExperienceDraft,
            wasSaved,
          },
        };
      },
    },
    addInstantExperienceElement(
      state,
      {
        payload: instantExperienceElement,
      }: PayloadAction<InstantExperienceBodyElement>,
    ) {
      const body_elements = state.instantExperienceDraft?.body_elements ?? [];

      const newInstantExperienceDraft = {
        ...state.instantExperienceDraft,
        body_elements: [
          ...body_elements.filter(negate(isFooter)),
          instantExperienceElement,
          ...body_elements.filter(isFooter),
        ],
      };
      return {
        ...state,
        selectedInstantExperienceElementId: instantExperienceElement.id,
        instantExperienceDraft: newInstantExperienceDraft,
        instantExperienceDraftValidations: getInstantExperienceValidations(
          newInstantExperienceDraft,
          state.instantExperienceDraftValidations,
          true,
          instantExperienceElement.id,
        ),
      };
    },
    updateInstantExperienceElement(
      state,
      {
        payload: instantExperienceElement,
      }: PayloadAction<InstantExperienceBodyElement>,
    ) {
      let body_elements: InstantExperienceBodyElement[] =
        state.instantExperienceDraft?.body_elements ?? [];

      const hasNewElementId = !body_elements.some(
        element => element.id === instantExperienceElement.id,
      );

      // when changing from button to footer, here we get the footer element to replace.
      const matchingFooterElement = findMatchingFooterElement(
        body_elements,
        instantExperienceElement.id,
      );

      if (isFooter(instantExperienceElement)) {
        const footerElement = instantExperienceElement;
        const buttonElement = footerElement.child_elements[0];
        body_elements = body_elements
          .filter(
            element =>
              ![buttonElement.id, footerElement.id].includes(element.id),
          )
          .concat(instantExperienceElement);
      } else {
        const instantExperienceElementIdToReplace =
          matchingFooterElement?.id || instantExperienceElement.id;

        body_elements = body_elements.map(element =>
          element.id === instantExperienceElementIdToReplace
            ? instantExperienceElement
            : element,
        );
      }

      const newInstantExperience: IInstantExperience = {
        ...state.instantExperienceDraft,
        body_elements,
      };

      const displayAlerts =
        instantExperienceElement.element_type === "BUTTON" &&
        !!matchingFooterElement &&
        state.instantExperienceDraftValidations?.body_elements.some(
          item => item.id === matchingFooterElement.id && item.displayAlerts,
        );

      return {
        ...state,
        instantExperienceDraft: newInstantExperience,
        instantExperienceDraftValidations: getInstantExperienceValidations(
          newInstantExperience,
          state.instantExperienceDraftValidations,
          displayAlerts,
        ),
        ...(hasNewElementId
          ? { selectedInstantExperienceElementId: instantExperienceElement.id }
          : {}),
      };
    },
    setSelectedInstantExperienceElementId(
      state,
      {
        payload: selectedInstantExperienceElementId,
      }: PayloadAction<string | undefined>,
    ) {
      if (
        selectedInstantExperienceElementId ===
        state.selectedInstantExperienceElementId
      ) {
        return state;
      }
      return {
        ...state,
        selectedInstantExperienceElementId,
        instantExperienceDraftValidations:
          getInstantExperienceValidationsWithAllAlerts(
            state.instantExperienceDraftValidations,
          ),
      };
    },
    deleteInstantExperienceElement(
      state,
      {
        payload: instantExperienceElementId,
      }: PayloadAction<string | undefined>,
    ) {
      const newInstantExperienceDraft = {
        ...state.instantExperienceDraft,
        body_elements: state.instantExperienceDraft?.body_elements?.filter(
          element => element.id !== instantExperienceElementId,
        ),
      };

      return {
        ...state,
        instantExperienceDraft: newInstantExperienceDraft,
        selectedInstantExperienceElementId: undefined,
        instantExperienceDraftValidations: getInstantExperienceValidations(
          newInstantExperienceDraft,
          state.instantExperienceDraftValidations,
        ),
      };
    },
    reorderInstantExperienceElement(
      state,
      {
        payload,
      }: PayloadAction<{ sourceIndex: number; destinationIndex: number }>,
    ) {
      const { sourceIndex, destinationIndex } = payload;
      const body_elements = reorderList(
        state.instantExperienceDraft?.body_elements ?? [],
        sourceIndex,
        destinationIndex,
      );

      return {
        ...state,
        instantExperienceDraft: {
          ...state.instantExperienceDraft,
          body_elements,
        },
      };
    },
    displayInstantExperienceDraftAlerts(state) {
      if (!state.instantExperienceDraftValidations) {
        return state;
      }
      return {
        ...state,
        instantExperienceDraftValidations:
          getInstantExperienceValidationsWithAllAlerts(
            state.instantExperienceDraftValidations,
          ),
      };
    },
    setStampException: {
      reducer(
        state,
        {
          payload,
        }: PayloadAction<{
          exceptionType: IStampExceptionType;
          exceptionValue: string;
        }>,
      ) {
        return {
          ...state,
          stampException: {
            type: payload.exceptionType,
            value: payload.exceptionValue,
          },
        };
      },
      prepare(exceptionType: IStampExceptionType, exceptionValue: string) {
        return {
          payload: {
            exceptionType,
            exceptionValue,
          },
        };
      },
    },
    fireSaveDraft(state) {
      return {
        ...state,
        savingDraft: "SAVE_FIRED",
      };
    },
    saveDraftFail(state, { payload: error }: PayloadAction<GenericError>) {
      return {
        ...state,
        savingDraft: null,
        error,
      };
    },
    saveDraftSuccess(state, { payload: template }: PayloadAction<ITemplate>) {
      return {
        ...state,
        savingDraft: "COMPLETE",
        publishCanvasStatus: null, // this is ok bc even if save draft completed, we need to reset all saving realted states since it isnt possible to do both, saving draft and publish at the same time
        templates: state.templates.map(existingTemplate => {
          if (existingTemplate.id === (template as ITemplate).id) {
            return template;
          }

          return existingTemplate;
        }),
      };
    },
    saveDraftComplete(state) {
      return {
        ...state,
        savingDraft: null,
      };
    },
    alterLayerObjectsBegin(state) {
      return {
        ...state,
        canvasObjectToAlterIndex: null,
        canvasArrangeValue: "",
        activeLayerObjects: [],
        activeTextSelection: null,
        currentLayerObjectId: "",
        currentLayerOperation: "",
      };
    },
    replaceLayerObject(
      state,
      {
        payload,
      }: PayloadAction<{
        layerId: string;
        layerObjects: ILayerObject[];
      }>,
    ) {
      return {
        ...state,
        layerObjects: payload.layerObjects as any,
        currentLayerObjectId: payload.layerId,
        currentLayerToggleOpProp: "",
        currentLayerOperation: "alter",
      };
    },
    publishCanvasBegin(
      state,
      { payload: publishCanvasStatus }: PayloadAction<IPublishCanvasStatus>,
    ) {
      return {
        ...state,
        publishCanvasStatus,
      };
    },
    publishCanvasFail(
      state,
      {
        payload,
      }: PayloadAction<{
        publishCanvasStatus: IPublishCanvasStatus;
        error: GenericError;
      }>,
    ) {
      const canvasStatus =
        (payload &&
          payload.publishCanvasStatus &&
          (payload.publishCanvasStatus as IPublishCanvasStatus)) ||
        null;

      if (!canvasStatus) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        publishCanvasStatus: {
          ...canvasStatus,
          status: "FAIL",
        },
      };
    },
    publishCanvasSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        publishCanvasStatus: IPublishCanvasStatus;
        stamp?: IStamp;
        template?: ITemplate;
      }>,
    ) {
      const newTemplateList = state.templates.map(tmpTemplate => {
        if (!payload.template) {
          return tmpTemplate;
        }

        if (payload.template.id === tmpTemplate.id) {
          return payload.template as ITemplate;
        }

        return tmpTemplate;
      });

      return {
        ...state,
        publishCanvasStatus: {
          ...payload.publishCanvasStatus,
          status: "SUCCESS",
        },
        templates: newTemplateList,
        stamps: state.stamps.map(stmp => {
          if (
            payload?.stamp?.id === stmp.id &&
            payload?.stamp?.offerType === stmp.offerType
          ) {
            return payload.stamp;
          }

          return stmp;
        }),
        stampToEditor: payload?.stamp ? payload.stamp : state.stampToEditor,
      };
    },
    publishCanvasStatusReset(state) {
      return {
        ...state,
        publishCanvasStatus: null,
      };
    },
    randomizeTemplateDataBegin(state) {
      return {
        ...state,
        randomizingData: "IN_PROGRESS",
        previewOfferData: null,
      };
    },
    randomizeTemplateDataSuccess(
      state,
      { payload: previewOfferData }: PayloadAction<IPreviewOfferDataObj>,
    ) {
      return {
        ...state,
        randomizingData: "COMPLETE",
        previewOfferData,
      };
    },
    randomizeTemplateDataFail(
      state,
      { payload: error }: PayloadAction<GenericError>,
    ) {
      return {
        ...state,
        randomizingData: "ERROR",
        error,
      };
    },
    randomizeTemplateDataReset(state) {
      return {
        ...state,
        randomizingData: null,
        previewOfferData: null,
      };
    },
    clearTemplateEditorDataInRedux(state) {
      return {
        ...state,
        templateToEditor: null,
        layerObjects: [],
        canvasJson: null,
        fabricHistory: {
          past: [],
          currentHistoryIndex: null,
          actionType: null,
        },
      };
    },
    // LIBRARY Actions
    fetchDataBegin(
      state,
      { payload: dataType }: PayloadAction<FetchingDataStatus>,
    ) {
      return {
        ...state,
        fetchingData:
          dataType === "artboards" || dataType === "stamps"
            ? dataType
            : state.fetchingData,
        error: null,
        paginationKey: null,
      };
    },
    fetchDataSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        response: {
          data:
            | IBrand[]
            | IAccount[]
            | ITemplateTag[]
            | ITemplate[]
            | IArtboard[]
            | IStamp[];
          paginationKey: string | null;
          error: GenericError | null;
        };
        dataType: FetchingDataStatus;
        append: boolean;
      }>,
    ) {
      const { dataType, response } = payload;
      const { data, paginationKey } = response ?? {};

      // If there was an error, if you be call FETCH_OEMS_FAIL instead of success
      return {
        ...state,
        stores:
          dataType === "accounts" ? (data as IAccount[]) : [...state.stores],
        tags: dataType === "tags" ? (data as ITemplateTag[]) : [...state.tags],
        artboards:
          dataType === "artboards"
            ? (data as IArtboard[])
            : [...state.artboards],
        stamps: dataType === "stamps" ? (data as IStamp[]) : [...state.stamps],
        paginationKey,
        fetchingData: null,
        creatingData: null,
        duplicatedTemplate: null,
      };
    },
    fetchDataFail(state) {
      return {
        ...state,
        fetchingData: null,
        paginationKey: null,

        error: {
          name: "",
          message: "Fetching data failed.",
        },
      };
    },
    setTogglePropId: {
      reducer(
        state,
        {
          payload,
        }: PayloadAction<{
          id: null | string;
          toggleProp: TemplateListCardToggleProp;
        }>,
      ) {
        return {
          ...state,
          [payload.toggleProp as TemplateListCardToggleProp]: payload.id,
        };
      },
      prepare(id: null | string, toggleProp: TemplateListCardToggleProp) {
        return {
          payload: {
            id,
            toggleProp,
          },
        };
      },
    },
    // LIBRARY > NEW TEMPLATE Actions
    searchTemplatesStampsArtboards(
      state,
      { payload: searchInput }: PayloadAction<string>,
    ) {
      return {
        ...state,
        error: null,
        searchInput,
      };
    },
    filterTemplatesAllPublishedUnpublished(
      state,
      { payload: allPublishedUnpublished }: PayloadAction<AllPublishedStatus>,
    ) {
      return {
        ...state,
        error: null,
        templatesAllPublishedUnpublished: allPublishedUnpublished,
        uniqueUUID: uuid(),
      };
    },
    filterTemplateOem(
      state,
      { payload: brandFilter }: PayloadAction<string[] | null>,
    ) {
      return {
        ...state,
        error: null,
        selectedFilterOem: brandFilter,
        uniqueUUID: uuid(),
      };
    },
    filterTemplateStore(
      state,
      { payload: storeFilter }: PayloadAction<string[] | null>,
    ) {
      return {
        ...state,
        error: null,
        selectedFilterStore: storeFilter,
        uniqueUUID: uuid(),
      };
    },
    filterTemplateTags(
      state,
      { payload: tagFilter }: PayloadAction<string[] | null>,
    ) {
      return {
        ...state,
        error: null,
        selectedFilterTags: tagFilter,
        uniqueUUID: uuid(),
      };
    },
    filterTemplateOfferCounts(
      state,
      { payload: offerCountFilter }: PayloadAction<string>,
    ) {
      return {
        ...state,
        error: null,
        selectedFilterOfferCounts: offerCountFilter,
        uniqueUUID: uuid(),
      };
    },
    filterStampOem(
      state,
      { payload: brandFilterStamp }: PayloadAction<string[] | null>,
    ) {
      return {
        ...state,
        error: null,
        selectedFilterOemStamp: brandFilterStamp,
        uniqueUUID: uuid(),
      };
    },
    filterStampState(
      state,
      { payload: stateFilterStamp }: PayloadAction<string[] | null>,
    ) {
      return {
        ...state,
        error: null,
        selectedFilterStateStamp: stateFilterStamp,
        uniqueUUID: uuid(),
      };
    },
    filterStampDescriptor(
      state,
      { payload: brandFilterStamp }: PayloadAction<string[] | null>,
    ) {
      return {
        ...state,
        error: null,
        selectedFilterDescriptorStamp: brandFilterStamp,
        uniqueUUID: uuid(),
      };
    },
    filterArtboardDimensions(
      state,
      {
        payload: dimensionsFilterArtboard,
      }: PayloadAction<{ width: number | null; height: number | null }>,
    ) {
      return {
        ...state,
        error: null,
        selectedFilterDimensionsArtboard: dimensionsFilterArtboard,
        uniqueUUID: uuid(),
      };
    },
    filterTemplateDimensions(
      state,
      {
        payload: dimensionsFilterTemplate,
      }: PayloadAction<{ width: number | null; height: number | null }>,
    ) {
      return {
        ...state,
        error: null,
        selectedFilterDimensionsTemplate: dimensionsFilterTemplate,
        uniqueUUID: uuid(),
      };
    },
    createTagBegin(state) {
      return {
        ...state,
        creatingData: "tag",
        error: null,
      };
    },
    createTagFail(state, { payload: tag }: PayloadAction<ITemplateTag>) {
      return {
        ...state,
        creatingData: null,
        error: {
          name: "",
          message: `The tag, ${tag.name} cannot be created.`,
        },
      };
    },
    createTagSuccess(state, { payload: tag }: PayloadAction<ITemplateTag>) {
      return {
        ...state,
        tags: [...state.tags, tag],
        creatingData: null,
      };
    },
    createTemplateBegin(state) {
      return {
        ...state,
        error: null,
        creatingData: "template",
      };
    },
    createTemplateFail(state, { payload: template }: PayloadAction<ITemplate>) {
      return {
        ...state,
        creatingData: null,
        error: {
          name: "",
          message: `The template, ${template.name} was not created due to an error.`,
        },
      };
    },
    createTemplateSuccess(
      state,
      { payload: template }: PayloadAction<ITemplate>,
    ) {
      return {
        ...state,
        templates: [...state.templates, template],
        layerObjects: [],

        // setting creatingData to "complete_template" will redirect user to the editor page.
        // "html" template do not have canvas. So we can't redirect to editor page and below will block it.
        creatingData:
          template.type !== "html" ? "complete_template" : state.creatingData,
        templateToEditor: template,
        fabricHistory: {
          past: [],
          currentHistoryIndex: null,
          actionType: null,
        },
        duplicatedTemplate: null,
      };
    },
    duplicateTemplateBegin(state) {
      return {
        ...state,
        error: null,
        creatingData: "template",
      };
    },
    duplicateTemplateFail(
      state,
      { payload: error }: PayloadAction<GenericError>,
    ) {
      return {
        ...state,
        creatingData: null,
        error,
      };
    },
    duplicateTemplateSuccess(
      state,
      { payload: template }: PayloadAction<ITemplate>,
    ) {
      return {
        ...state,
        creatingData: null,
        templates: [...state.templates, template],
        duplicatedTemplate: template,
      };
    },
    updateTemplateBegin(state) {
      return {
        ...state,
        creatingData: null,
      };
    },
    updateTemplateFail(state, { payload: error }: PayloadAction<GenericError>) {
      return {
        ...state,
        updatingData: null,
        error,
      };
    },
    updateTemplateSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        template: ITemplate;
        status?: string;
      }>,
    ) {
      const { template: updatedTemplate, status } = payload;

      let updatedTemplateList: ITemplate[];

      if (!status) {
        updatedTemplateList = state.templates.map(existingTemplate => {
          return existingTemplate.id === updatedTemplate.id
            ? updatedTemplate
            : existingTemplate;
        });
      } else {
        updatedTemplateList = state.templates.filter(
          existingTemplate => existingTemplate.id !== updatedTemplate.id,
        );
      }

      return {
        ...state,
        error: null,
        updatingData: status ? "archiving_template" : "complete_template",
        creatingData: null,
        templateToUpdate: null,
        templates: updatedTemplateList,
        duplicatedTemplate: null,
      };
    },
    deleteTemplateBegin(
      state,
      { payload: deletingDataId }: PayloadAction<string>,
    ) {
      return {
        ...state,
        deletingDataId, // the id of the deleting template
      };
    },
    deleteTemplateFail(state, { payload: error }: PayloadAction<GenericError>) {
      return {
        ...state,
        deletingDataId: null,
        error,
      };
    },
    deleteTemplateSuccess(
      state,
      { payload: deletedTemplate }: PayloadAction<ITemplate>,
    ) {
      return {
        ...state,
        error: null,
        deletingDataId: null,
        templates: state.templates.filter(
          existingTemplate => existingTemplate.id !== deletedTemplate.id,
        ),
        duplicatedTemplate: null,
      };
    },
    setTemplateToUpdate(
      state,
      {
        payload: templateToUpdate,
      }: PayloadAction<ITemplate | null | undefined>,
    ) {
      return {
        ...state,
        stampToUpdate: null,
        artboardToUpdate: null,
        templateToUpdate,
      };
    },
    removeTemplateToUpdate(state) {
      return {
        ...state,
        templateToUpdate: null,
      };
    },
    setTemplateToEditor(
      state,
      { payload: template }: PayloadAction<ITemplate | null>,
    ) {
      return {
        ...state,
        stampToEditor: null,
        templateToEditor: template,

        // initialize history
        fabricHistory: {
          past: [],
          currentHistoryIndex: null,
          actionType: null,
        },
      };
    },
    // LIBRARY > NEW ARTBOARD Actions
    createArtboardBegin(state) {
      return {
        ...state,
        error: null,
        creatingData: "artboard",
      };
    },
    createArtboardFail(state, { payload: artboard }: PayloadAction<IArtboard>) {
      return {
        ...state,
        creatingData: null,
        error: {
          name: "",
          message: `The artboard, ${artboard.name} was not created due to an error.`,
        },
      };
    },
    createArtboardSuccess(
      state,
      { payload: artboard }: PayloadAction<IArtboard>,
    ) {
      return {
        ...state,
        artboards: [...state.artboards, artboard],
        creatingData: "complete_artboard",
      };
    },
    updateArtboardBegin(state) {
      return {
        ...state,
        editingArtboardStatus: "begin",
      };
    },
    updateArtboardFail(state, { payload: error }: PayloadAction<GenericError>) {
      return {
        ...state,
        editingArtboardStatus: "error",
        error,
      };
    },
    updateArtboardSuccess(
      state,
      { payload: artboard }: PayloadAction<IArtboard>,
    ) {
      const newArtboards = state.artboards.map(tmpArtboard => {
        if (tmpArtboard.name === artboard.name) {
          return {
            ...artboard,
          };
        }

        return {
          ...tmpArtboard,
        };
      });

      return {
        ...state,
        artboards: newArtboards,
        editingArtboardStatus: "completed",
      };
    },
    resetArtboard(state) {
      return {
        ...state,
        creatingData: null,
        editingArtboardStatus: undefined,
      };
    },
    deleteArtboardBegin(state) {
      return {
        ...state,
        editingArtboardStatus: "begin",
      };
    },
    // eslint-disable-next-line
    deleteArtboardFail(state, error) {
      return state;
    },
    deleteArtboardSuccess(
      state,
      { payload: artboard }: PayloadAction<IArtboard>,
    ) {
      const list = state.artboards.filter(art => art.name !== artboard.name);
      return {
        ...state,
        artboards: list,
        editingArtboardStatus: "completed",
      };
    },
    // LIBRARY > CREATE STAMP Actions
    createStampBegin(state) {
      return {
        ...state,
        error: null,
        creatingData: "stamp",
      };
    },
    createStampFail(
      state,
      { payload: stamp }: PayloadAction<INewStamp | string>,
    ) {
      return {
        ...state,
        creatingData: null,
        error: {
          name: "",
          message: `The stamp, ${
            typeof stamp === "string" ? stamp : stamp.name
          } was not created due to an error.`,
        },
      };
    },
    createStampSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        stamps: IStamp[];
        duplicating?: boolean;
      }>,
    ) {
      const { stamps, duplicating } = payload;

      const stmp = {
        ...(stamps[0] || {}),
      };

      if (duplicating) {
        // to open up the stamp drawer
        return {
          ...state,
          stamps: [...state.stamps, stmp],
          updatingData: "duplicating_stamp",
          stampToUpdate: stmp,
          creatingData: null,
          stampToEditor: null,
        };
      }

      return {
        ...state,
        stamps: [...state.stamps, stmp],
        creatingData: "complete_stamp",
        stampToEditor: null,
      };
    },
    setStampToUpdate(
      state,
      { payload: stampToUpdate }: PayloadAction<IStamp | null | undefined>,
    ) {
      return {
        ...state,
        templateToUpdate: null,
        artboardToUpdate: null,
        stampToUpdate,
        updatingData: null,
      };
    },
    removeStampToUpdate(state) {
      return {
        ...state,
        stampToUpdate: null,
        updatingData: null,
      };
    },
    updateStampBegin(state) {
      return {
        ...state,
        updatingData: "stamp",
      };
    },
    updateStampFail(state, { payload: error }: PayloadAction<GenericError>) {
      return {
        ...state,
        updatingData: null,
        error,
      };
    },
    setArtboardToUpdate(
      state,
      {
        payload: artboardToUpdate,
      }: PayloadAction<IArtboard | null | undefined>,
    ) {
      return {
        ...state,
        artboardToUpdate,
        templateToUpdate: null,
        stampToUpdate: null,
      };
    },
    cancelProcessingInstantExperiences(state) {
      return {
        ...state,
        instantExperienceImport: {
          ...state.instantExperienceImport,
          cancelImport: true,
          processingInstantExperiences: false,
        },
      };
    },
    resetInstantExperiencesImport(state) {
      return {
        ...state,
        instantExperienceImport: initialState.instantExperienceImport,
      };
    },
    startProcessingInstantExperiences(state) {
      return {
        ...state,
        instantExperienceImport: {
          ...state.instantExperienceImport,
          processingInstantExperiences: true,
        },
      };
    },
    finishProcessingInstantExperiences(
      state,
      {
        payload: instantExperiencesToImport,
      }: PayloadAction<IInstantExperience[]>,
    ) {
      const instantExperienceImportIssues = getInstantExperiencesIssues(
        instantExperiencesToImport,
      );

      const selectedInstantExperienceIds = instantExperiencesToImport
        .filter(
          ie => !hasInstantExperienceIssues(ie, instantExperienceImportIssues),
        )
        .map(ie => ie.id ?? "");

      return {
        ...state,
        instantExperienceImport: {
          ...state.instantExperienceImport,
          instantExperiencesToImport: instantExperiencesToImport,
          selectedInstantExperienceIds,
          processingInstantExperiences: false,
          instantExperienceImportIssues,
        },
      };
    },
    setDisplayInstantExperiencesImportTable(
      state,
      { payload: displayImportTable }: PayloadAction<boolean>,
    ) {
      return {
        ...state,
        instantExperienceImport: {
          ...state.instantExperienceImport,
          displayImportTable,
        },
      };
    },

    setSelectedInstantExperienceIds(
      state,
      { payload: selectedInstantExperienceIds }: PayloadAction<string[]>,
    ) {
      return {
        ...state,
        instantExperienceImport: {
          ...state.instantExperienceImport,
          selectedInstantExperienceIds,
        },
      };
    },
    setInstantExperienceImport(
      state,
      { payload: instantExperienceUpdate }: PayloadAction<IInstantExperience>,
    ) {
      const instantExperiencesToImport =
        state.instantExperienceImport.instantExperiencesToImport.map(ie =>
          ie.id === instantExperienceUpdate.id ? instantExperienceUpdate : ie,
        );

      const instantExperienceImportIssues = getInstantExperiencesIssues(
        instantExperiencesToImport,
      );

      return {
        ...state,
        instantExperienceImport: {
          ...state.instantExperienceImport,
          instantExperiencesToImport,
          instantExperienceImportIssues,
        },
      };
    },
    updateStampSuccess(state, { payload: stamps }: PayloadAction<IStamp[]>) {
      const stamp = stamps[0];

      const { id } = stamp;

      const newStamps = [
        ...state.stamps.filter(tmpStamp => tmpStamp.id !== id),
        stamp,
      ];

      return {
        ...state,
        updatingData: "complete_stamp",
        stamps: newStamps,
      };
    },
    deleteStampBegin(state, { payload: id }: PayloadAction<string>) {
      return {
        ...state,
        deletingDataId: id,
      };
    },
    deleteStampFail(state, { payload: error }: PayloadAction<GenericError>) {
      return {
        ...state,
        error,
      };
    },
    deleteStampSuccess(state, { payload: id }: PayloadAction<string>) {
      return {
        ...state,

        deletingDataId: null,
        stamps: state.stamps.filter(tmpStamp => tmpStamp.id !== id),
      };
    },
    setDisabledPublishButton(
      state,
      { payload: disabledPublishButton }: PayloadAction<boolean>,
    ) {
      return {
        ...state,
        disabledPublishButton,
      };
    },
  },
});

function getInstantExperienceValidationsWithAllAlerts(
  instantExperienceDraftValidations?: IInstantExperienceValidations | null,
): IInstantExperienceValidations | undefined | null {
  if (!instantExperienceDraftValidations) {
    return instantExperienceDraftValidations;
  }
  return {
    ...instantExperienceDraftValidations,
    settings: {
      ...instantExperienceDraftValidations.settings,
      displayAlerts: true,
    },
    body_elements: instantExperienceDraftValidations.body_elements.map(
      element => ({
        ...element,
        displayAlerts: true,
        child_elements: element.child_elements?.map(childElement => ({
          ...childElement,
          displayAlerts: true,
        })),
      }),
    ),
  };
}

function findMatchingFooterElement(
  body_elements: InstantExperienceBodyElement[],
  buttonElementId?: string,
) {
  return body_elements.find(
    element =>
      element.element_type === "FOOTER" &&
      element.child_elements.some(child => child.id === buttonElementId),
  );
}

function getInstantExperienceValidations(
  instantExperience?: IInstantExperience | null,
  prevInstantExperienceValidations?: IInstantExperienceValidations | null,
  displayAlerts = true,
  excludeIdFromDisplayAlerts?: string,
): IInstantExperienceValidations {
  const validations: IInstantExperienceValidations = {
    hasErrors: false,
    settings: { hasErrors: false },
    body_elements: [],
  };
  if (!instantExperience) return validations;

  // settings
  if (!instantExperience.name || !instantExperience.oem) {
    validations.settings.displayAlerts =
      prevInstantExperienceValidations?.settings.displayAlerts || displayAlerts;
    validations.hasErrors = true;
    validations.settings.hasErrors = true;
  }

  instantExperience.body_elements?.forEach(element => {
    switch (element.element_type) {
      case "BUTTON":
        {
          const buttonElement = element;
          const elementDisplayAlerts =
            excludeIdFromDisplayAlerts !== buttonElement.id &&
            (prevInstantExperienceValidations?.body_elements.some(
              item => item.id === buttonElement.id && item.displayAlerts,
            ) ||
              displayAlerts);

          const buttonElementValidations = getButtonValidation(
            buttonElement,
            elementDisplayAlerts,
          );
          if (buttonElementValidations.hasErrors) {
            validations.hasErrors = true;
          }
          validations.body_elements.push(buttonElementValidations);
        }
        break;
      case "FOOTER":
        {
          const footerElement = element;
          const [buttonElement] = footerElement.child_elements;
          const elementDisplayAlerts =
            excludeIdFromDisplayAlerts !== footerElement.id &&
            (prevInstantExperienceValidations?.body_elements.some(
              item =>
                (item.id === footerElement.id ||
                  item.id === buttonElement.id) &&
                item.displayAlerts,
            ) ||
              displayAlerts);

          const buttonElementValidations = getButtonValidation(
            buttonElement,
            elementDisplayAlerts,
          );
          if (buttonElementValidations.hasErrors) {
            validations.hasErrors = true;
          }
          validations.body_elements.push({
            ...buttonElementValidations,
            id: footerElement.id,
            element_type: "FOOTER",
          });
        }
        break;
      case "PHOTO":
      case "VIDEO":
        {
          const mediaElement = element;

          const photoElement =
            "destination" in mediaElement ? mediaElement : undefined;
          const elementUrl =
            photoElement?.destination?.urlLabel ||
            photoElement?.destination?.instantExperienceId;

          const hasErrors =
            !mediaElement.url ||
            (element.element_type === "PHOTO" &&
              getHasWebsiteUrlErrors(
                elementUrl,
                isLastElement(instantExperience, element),
                !!photoElement?.destination?.instantExperienceId,
              ));
          const elementDisplayAlerts =
            excludeIdFromDisplayAlerts !== mediaElement.id &&
            (prevInstantExperienceValidations?.body_elements.some(
              item =>
                item.id === mediaElement.id &&
                item.displayAlerts &&
                item.hasErrors,
            ) ||
              displayAlerts);
          if (hasErrors) {
            validations.hasErrors = hasErrors;
          }
          validations.body_elements.push({
            ...getElementBasicFieldProps(mediaElement),
            hasErrors,
            displayAlerts: elementDisplayAlerts,
          });
        }
        break;
      case "CAROUSEL":
        {
          const carouselElement = element;
          const prevCarouselElementValidations =
            prevInstantExperienceValidations?.body_elements.find(
              item => item.id === carouselElement.id,
            );

          let elementDisplayAlerts = false;
          let hasErrors = false;
          const childErrors: IElementFieldErrors[] =
            carouselElement.child_elements.map(item => {
              const itemHasError =
                !item.url ||
                getHasWebsiteUrlErrors(
                  item.destination?.urlLabel,
                  isLastElement(instantExperience, element),
                );
              if (itemHasError) {
                hasErrors = true;
              }
              const prevChildElementValidations =
                prevCarouselElementValidations?.child_elements?.find(
                  child => child.id == item.id,
                );
              const childDisplayAlerts =
                excludeIdFromDisplayAlerts !== carouselElement.id &&
                ((prevChildElementValidations?.displayAlerts &&
                  prevChildElementValidations?.hasErrors) ||
                  displayAlerts);

              if (itemHasError && childDisplayAlerts) {
                elementDisplayAlerts = true;
              }
              return {
                hasErrors: itemHasError,
                displayAlerts: childDisplayAlerts,
                ...getElementBasicFieldProps(item),
              };
            });

          if (hasErrors) {
            validations.hasErrors = true;
          }

          validations.body_elements.push({
            ...getElementBasicFieldProps(carouselElement),
            hasErrors,
            displayAlerts: elementDisplayAlerts,
            child_elements: childErrors,
          });
        }
        break;
      default:
        break;
    }
  });

  return validations;
}

function getElementBasicFieldProps(
  element: IElementBasicFields,
): IElementBasicFields {
  return {
    element_type: element.element_type,
    id: element.id,
    name: element.name,
  };
}

function getButtonValidation(
  buttonElement: IButtonElement,
  elementDisplayAlerts: boolean,
): IElementFieldErrors {
  const hasErrors =
    !buttonElement.rich_text?.plain_text ||
    (!buttonElement.destination?.instantExperienceId &&
      getHasWebsiteUrlErrors(buttonElement.destination?.urlLabel, true));

  return {
    hasErrors: hasErrors,
    displayAlerts: elementDisplayAlerts,
    ...getElementBasicFieldProps(buttonElement),
  };
}

function getHasWebsiteUrlErrors(
  urlLabel?: string,
  required = false,
  isIE = false,
) {
  if (!required && !urlLabel) return false;
  if (isIE && urlLabel) return false;

  const labels = queryClient.getQueryData<{ labels: ILabel[] }>("labels");
  const isLoading = !labels;
  const labelNames = labels?.labels.map(label => label.name) || [];

  const isEmptyWebsite = !urlLabel;
  const isLabel = labelNames.includes(urlLabel || "");
  const isUrl = /^https?:\/\//.test(urlLabel || "");
  const hasDestinationWebsiteError =
    isEmptyWebsite || (!isUrl && !isLoading && !isLabel);
  return hasDestinationWebsiteError;
}

export const {
  addInstantExperienceElement,
  alterLayerObjectsBegin,
  clearTemplateEditorDataInRedux,
  createArtboardBegin,
  createArtboardFail,
  createArtboardSuccess,
  createStampBegin,
  createStampFail,
  createStampSuccess,
  createTagBegin,
  createTagFail,
  createTagSuccess,
  createTemplateBegin,
  createTemplateFail,
  createTemplateSuccess,
  deleteArtboardBegin,
  deleteArtboardFail,
  deleteArtboardSuccess,
  deleteInstantExperienceElement,
  deleteStampBegin,
  deleteStampFail,
  deleteStampSuccess,
  deleteTemplateBegin,
  deleteTemplateFail,
  deleteTemplateSuccess,
  displayInstantExperienceDraftAlerts,
  duplicateTemplateBegin,
  duplicateTemplateFail,
  duplicateTemplateSuccess,
  fetchDataBegin,
  fetchDataFail,
  fetchDataSuccess,
  filterArtboardDimensions,
  filterStampDescriptor,
  filterStampOem,
  filterStampState,
  filterTemplateDimensions,
  filterTemplateOem,
  filterTemplateOfferCounts,
  filterTemplateStore,
  filterTemplateTags,
  filterTemplatesAllPublishedUnpublished,
  fireSaveDraft,
  publishCanvasBegin,
  publishCanvasFail,
  publishCanvasStatusReset,
  publishCanvasSuccess,
  randomizeTemplateDataBegin,
  randomizeTemplateDataFail,
  randomizeTemplateDataReset,
  randomizeTemplateDataSuccess,
  removeStampToUpdate,
  removeTemplateToUpdate,
  reorderInstantExperienceElement,
  replaceLayerObject,
  resetArtboard,
  saveDraftComplete,
  saveDraftFail,
  saveDraftSuccess,
  searchTemplatesStampsArtboards,
  setInstantExperienceDraft,
  setSelectedInstantExperienceElementId,
  setStampException,
  setStampToUpdate,
  setArtboardToUpdate,
  setTemplateToEditor,
  setTemplateToUpdate,
  setTogglePropId,
  updateArtboardBegin,
  updateArtboardFail,
  updateArtboardSuccess,
  updateInstantExperienceElement,
  updateStampBegin,
  updateStampFail,
  updateStampSuccess,
  updateTemplateBegin,
  updateTemplateFail,
  updateTemplateSuccess,
  cancelProcessingInstantExperiences,
  finishProcessingInstantExperiences,
  resetInstantExperiencesImport,
  setDisplayInstantExperiencesImportTable,
  setInstantExperienceImport,
  setSelectedInstantExperienceIds,
  startProcessingInstantExperiences,
  setDisabledPublishButton,
} = designStudioSlice.actions;

// export all actions from child actions
export * from "./editor/actions";
export * from "./library/actions";

export default designStudioSlice.reducer;
