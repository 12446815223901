import { fetchToken } from "./fetchToken";

let promise: Promise<MGTokenObj> | null = null;

export const createPasswordToken = () => {
  if (promise === null) {
    promise = fetchToken({
      grant_type: "password",
    });

    promise.finally(() => {
      promise = null;
    });
  }

  return promise;
};
