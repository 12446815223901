import { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import actions from "../../redux/rootActions";

import { INewOrderState } from "shared/types/newOrders";

import {
  IAssetBuilderState,
  ISavedOrderState,
  OfferData,
  OfferValidationErrors,
  RawOfferData,
  RepeatableOfferKeys,
  SingletonOfferKeys,
  OfferOperationMode,
  IRawOfferDataFromService,
  OfferEditFromService,
  OfferEditFieldObject,
  OfferEditTracker,
  RawSelectedOffers,
  FeedOffer,
  EditedFieldId,
} from "shared/types/assetBuilder";
import { FeedType, IConfigurationState } from "shared/types/configuration";
import "./Offers.scss";
import { OfferForm } from "./offers/select/OfferForm";
import GenericError from "shared/errors/GenericError";
import { Spin } from "antd";

interface INewOfferForm {
  feed: FeedType;
  offerData: OfferData;
  offerValidationErrors: OfferValidationErrors;
  editField: <T extends SingletonOfferKeys | RepeatableOfferKeys>(
    key: T,
    value: RawOfferData[T],
    fieldIdentifier?: EditedFieldId,
  ) => void;
  fromNewOfferForm?: boolean;
  uploadedJellybean?: string;
  switchOfferEditMode?: (editMode: boolean) => void;

  editedKeys: Record<string, boolean>;
  savedOrder: ISavedOrderState | null;
  originalVin: string;

  enablePaymentEngine: boolean;
  offerOperationMode: OfferOperationMode;
  getRawOfferByVin: (vin: string) => void;
  gettingRawOfferByVin: boolean;
  getRawOfferByVinError: GenericError | null;
  currentRawOffer: IRawOfferDataFromService | null;
  currentOfferEdit: OfferEditFromService | null;
  offerEditTrackerObject: OfferEditTracker | null;

  togglePaymentEngineModal?: (
    initialSelectedRow: OfferEditFieldObject | null,
  ) => void;
  offerOperationType: "create" | "update";
  setDuplicating?: (input: boolean) => void;
  setIsDuplicateMode?: (input: boolean) => void;
  setEditOfferData: (
    offerData: OfferData,
    editedKeys: Record<string, boolean>,
    operation?: OfferOperationMode,
  ) => void;
  setResetingToMasterData: (input: boolean) => void;
  rawSelectedOffers: RawSelectedOffers;
}

let temp233 = "";
const NewOfferForm: FC<INewOfferForm> = ({
  feed,
  offerData,
  offerValidationErrors,
  editField,
  uploadedJellybean,
  switchOfferEditMode: changeToEditMode,
  editedKeys,
  savedOrder,

  enablePaymentEngine,
  offerOperationMode,
  getRawOfferByVin,
  gettingRawOfferByVin,
  currentOfferEdit,
  offerEditTrackerObject,

  togglePaymentEngineModal,
  offerOperationType,
  setDuplicating,
  setIsDuplicateMode,
  setEditOfferData,
  originalVin,
  setResetingToMasterData,
  rawSelectedOffers,
}) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const [editing, setEditing] = useState(new Set(""));

  useEffect(() => {
    if (changeToEditMode) {
      changeToEditMode(true);
    }
    if (!offerData?.vin || offerOperationMode !== "UPDATE") {
      return;
    }
    getRawOfferByVin(offerData.vin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const rawOfferData = rawSelectedOffers[originalVin]?.offerData;

    if (!rawOfferData) return;

    for (const offer in rawOfferData as RawOfferData) {
      if (
        offer !== "vin" &&
        rawOfferData[offer as keyof RawOfferData] !==
          offerData[offer as keyof OfferData]
      ) {
        setDisabled(true);
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerData]);

  const tempOffer = {
    ...offerData,
    dealerCode: temp233,
    imageUrl: uploadedJellybean ? uploadedJellybean : offerData.imageUrl,
  } as FeedOffer;

  return (
    <Spin spinning={gettingRawOfferByVin} tip="Getting raw offer data...">
      <OfferForm
        feed={feed}
        fromNewOfferForm={true}
        offerData={tempOffer}
        offerValidationErrors={offerValidationErrors}
        editMode={true}
        editField={editField}
        keyId={
          offerOperationMode === "CREATE" ? "_ID" : `${tempOffer.dealerName}_ID`
        }
        editedKeys={editedKeys}
        enablePaymentEngine={enablePaymentEngine}
        editing={editing}
        setEditing={setEditing}
        checkedOfferFilters={{
          Lease: true,
          "Zero Down Lease": true,
          Finance: true,
          Purchase: true,
          APR: true,
        }}
        savedOrder={savedOrder}
        currentOfferEdit={currentOfferEdit}
        offerEditTrackerObject={offerEditTrackerObject}
        togglePaymentEngineModal={togglePaymentEngineModal}
        setEditOfferData={setEditOfferData}
        offerOperationType={offerOperationType}
        setDuplicating={setDuplicating}
        setIsDuplicateMode={setIsDuplicateMode}
        setResetingToMasterData={setResetingToMasterData}
        disabled={disabled}
      />
    </Spin>
  );
};

const mapStateToProps = (state: any) => {
  const { assetBuilder, configuration, newOrders } = state as {
    assetBuilder: IAssetBuilderState;
    configuration: IConfigurationState;
    newOrders: INewOrderState;
  };
  const { feed, config } = configuration;
  const {
    offerData,
    offerValidationErrors,
    uploadedJellybean,
    offerList,
    savedOrder,

    offerOperationMode,
    gettingRawOfferByVin,
    getRawOfferByVinError,
    currentRawOffer,
    currentOfferEdit,
    offerEditTrackerObject,
    rawSelectedOffers,
  } = assetBuilder;
  const { currentSelectedOrder } = newOrders;

  temp233 = currentSelectedOrder.dealer_code;

  const foundOffer = offerList.find(offer => offer.row.vin === offerData?.vin);

  return {
    feed: feed as FeedType,
    offerData: offerData as OfferData,
    offerValidationErrors: offerValidationErrors as OfferValidationErrors,
    uploadedJellybean,
    editedKeys: foundOffer?.editedKeys || {},
    savedOrder,

    enablePaymentEngine: config?.featureFlags?.enablePaymentEngine || false,
    offerOperationMode,
    gettingRawOfferByVin,
    getRawOfferByVinError,
    currentRawOffer,
    currentOfferEdit,
    offerEditTrackerObject,
    rawSelectedOffers,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  editField: <T extends RepeatableOfferKeys | SingletonOfferKeys>(
    key: T,
    value: RawOfferData[T],
    fieldIdentifier?: EditedFieldId,
  ) => {
    return dispatch(
      actions.assetBuilder.editOffer({
        key,
        value,
        fieldIdentifier,
      }),
    );
  },

  switchOfferEditMode: (editMode: boolean) =>
    dispatch(actions.assetBuilder.switchOfferEditMode(editMode)),

  getRawOfferByVin: (vin: string) => {
    return dispatch(actions.assetBuilder.getRawOfferByVin(vin));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewOfferForm);
