/* eslint-disable react/display-name */
import { useMemo, useCallback, useEffect, useState, Key } from "react";

import ReviewStepHeader from "./reviewStep/ReviewStepHeader";
import ReviewStepInnerDrawer from "./reviewStep/ReviewStepInnerDrawer";
import AdsToLoadTable from "./shared/components/AdsToLoadTable";

import uniqueColumns from "./reviewStep/uniqueColumns";

import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { setAdLoadState } from "redux/adLibrary/adLibrary.slice";
import { useAppShallowEqualSelector } from "shared/hooks/useAppSelector";

import useGetRequiredData from "./shared/hooks/reviewStep/useGetRequiredData";
import useModifyAdLoadReviewState from "./shared/hooks/reviewStep/useModifyAdLoadReviewState";

import {
  wereUrlsReviewed,
  mapAdsToLoadDataAfterUpdate,
  returnAdLoadReviewAndQAStatus,
} from "./shared/utils";
import { filterAdsByStatus } from "./loadStep/helpers.ad";
import { noMoreQAIssues } from "./shared/components/adsToLoadTable/utils";

import { adsToLoadTableColumnNames } from "./shared/constants";

import {
  AdLoadAdStatusEnum,
  AdLoadStepKey,
  IAdLoadReview,
  IAdToLoadData,
  ReviewStepKeys,
} from "shared/types/adLibrary";

const ReviewStep = () => {
  const dispatch = useAppDispatch();
  const [statusSwitchDisplayConfirmation, setStatusSwitchDisplayConfirmation] =
    useState(true);

  const adLoadReview = useAppShallowEqualSelector(
    ({ adLibrary }) => adLibrary.adLoad.review,
  );
  const {
    adsToLoad = [],
    selectedColumnKeys,
    selectedRowKeys,
  } = adLoadReview || {};

  const {
    productSets,
    selectedAds,
    loadingData,
    currentStep,
    selectedStores,
    currentInnerStep,
    productCatalogIds,
    instantExperiences,
  } = useGetRequiredData();

  const isSelectionEnabled = currentStep === AdLoadStepKey.REVIEW_ADS_TO_LOAD;

  const modifyAdLoadReviewState = useModifyAdLoadReviewState();

  const modifyAdsToLoad = (updatedAdsToLoad: IAdToLoadData[]) => {
    const newAdsToLoad = mapAdsToLoadDataAfterUpdate(
      adsToLoad,
      updatedAdsToLoad,
    );
    modifyAdLoadReviewState({
      prop: "adsToLoad",
      data: newAdsToLoad,
      willSaveJsonData: true,
    });
  };

  const setCurrentInnerStep = useCallback(
    (reviewStep?: ReviewStepKeys) => {
      dispatch(
        setAdLoadState({
          prop: "currentInnerStep",
          data: reviewStep || null,
        }),
      );
    },
    [dispatch],
  );

  const returnAdToLoadAfterUpdate = useCallback(
    (updatedAdToLoad: IAdToLoadData) => {
      const adToLoadWithUpdatedStatus: IAdToLoadData = {
        ...updatedAdToLoad,
        adLoadStatus: returnAdLoadReviewAndQAStatus({
          productSets,
          instantExperiences,
          adToLoad: updatedAdToLoad,
          selectedAds: selectedAds ?? [],
          wereDestinationURLsReviewed: wereUrlsReviewed([updatedAdToLoad]),
        }),
      };
      return adToLoadWithUpdatedStatus;
    },
    [instantExperiences, productSets, selectedAds],
  );

  // This logic allows the AntD table to force update and show the latest data
  const [tempColumnKeys, setTempColumnKeys] = useState<string[] | null>(null);
  useEffect(() => {
    if (!tempColumnKeys) return;
    modifyAdLoadReviewState({
      willSaveJsonData: true,
      prop: "selectedColumnKeys",
      data: tempColumnKeys,
    });
    setTempColumnKeys(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempColumnKeys]);

  const [topStatus, setTopStatus] = useState<AdLoadAdStatusEnum | null>(null);
  const filteredAdsToLoad = useMemo(() => {
    const adsWithoutLoadStatus = adsToLoad.map(adToLoad =>
      adToLoad.adLoadStatus.facebookError || adToLoad.facebookAd?.id
        ? {
            ...adToLoad,
            adLoadStatus: returnAdLoadReviewAndQAStatus({
              adToLoad,
              selectedAds,
              productSets,
              instantExperiences,
              wereDestinationURLsReviewed: wereUrlsReviewed([adToLoad]),
            }),
          }
        : adToLoad,
    );
    return filterAdsByStatus(adsWithoutLoadStatus, topStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adsToLoad, topStatus]);

  const ignoreStatuses = useMemo(() => {
    if (topStatus === AdLoadAdStatusEnum.WARNING)
      return [AdLoadAdStatusEnum.ERROR];
    if (topStatus === AdLoadAdStatusEnum.ERROR)
      return [AdLoadAdStatusEnum.WARNING];
  }, [topStatus]);

  useEffect(() => {
    if (topStatus === null) return;
    if (!noMoreQAIssues(adsToLoad, topStatus)) return;
    setTopStatus(null);
  }, [adsToLoad, topStatus]);

  const [willCheckErrors, setWillCheckErrors] = useState(false);

  const deselectRowsIfErrorsFound = useCallback(() => {
    if (!selectedRowKeys?.length || !willCheckErrors) return;
    const rowKeysWithErrors = adsToLoad
      .filter(
        adToLoad =>
          !noMoreQAIssues([adToLoad], AdLoadAdStatusEnum.ERROR) &&
          selectedRowKeys.includes(adToLoad.key),
      )
      .map(adToLoad => adToLoad.key);
    const newSelectedRowKeys = selectedRowKeys.filter(
      key => !rowKeysWithErrors.includes(key),
    );

    const areSameKeys =
      JSON.stringify(newSelectedRowKeys.sort()) ===
      JSON.stringify([...selectedRowKeys].sort());

    if (areSameKeys) return;

    onRowSelectionChange(newSelectedRowKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [willCheckErrors, adsToLoad]);

  useEffect(() => {
    deselectRowsIfErrorsFound();
  }, [willCheckErrors, adsToLoad, deselectRowsIfErrorsFound]);

  const onRowSelectionChange = (selectedRowKeys: Key[]) => {
    modifyAdLoadReviewState({
      willSaveJsonData: true,
      prop: "selectedRowKeys",
      data: selectedRowKeys as string[],
    });
  };

  const onSave = (args: {
    data: IAdToLoadData[];
    willRecheckAds?: boolean;
  }) => {
    const updatedAdsToLoad = mapAdsToLoadDataAfterUpdate(
      adLoadReview?.adsToLoad || [],
      args.data,
    ).map(adToLoad =>
      args.willRecheckAds ? returnAdToLoadAfterUpdate(adToLoad) : adToLoad,
    );

    const rowKeysToConcat = updatedAdsToLoad
      .filter(
        adToLoad =>
          noMoreQAIssues([adToLoad], AdLoadAdStatusEnum.ERROR) &&
          !adLoadReview?.selectedRowKeys.includes(adToLoad.key),
      )
      .map(adToLoad => adToLoad.key);
    const newSelectedRowKeys = (adLoadReview?.selectedRowKeys || []).concat(
      rowKeysToConcat,
    );

    modifyAdLoadReviewState({
      data: {
        ...adLoadReview,
        selectedRowKeys: newSelectedRowKeys,
        adsToLoad: updatedAdsToLoad,
        selectedColumnKeys: [],
      } as IAdLoadReview,
    });

    setTempColumnKeys([
      ...(adLoadReview?.selectedColumnKeys ?? adsToLoadTableColumnNames),
    ]);
  };

  return (
    <>
      <ReviewStepHeader
        loading={loadingData}
        adsToLoad={adsToLoad}
        topStatus={topStatus}
        selectedStores={selectedStores}
        onHeaderButtonClick={status => setTopStatus(status)}
        onColumnKeySelectionChange={columnKeys => {
          modifyAdLoadReviewState({
            prop: "selectedColumnKeys",
            data: columnKeys,
            willSaveJsonData: true,
          });
        }}
      />
      <AdsToLoadTable
        loading={loadingData}
        adsToLoad={filteredAdsToLoad}
        ignoreStatuses={ignoreStatuses}
        uniqueColumns={uniqueColumns({
          adsToLoad,
          modifyAdsToLoad,
          statusSwitchDisplayConfirmation,
          setStatusSwitchDisplayConfirmation,
        })}
        productCatalogIds={productCatalogIds}
        instantExperiences={instantExperiences}
        isSelectionEnabled={isSelectionEnabled}
        selectedRowKeys={selectedRowKeys}
        selectedColumnKeys={selectedColumnKeys}
        onMultiUrlClick={() =>
          setCurrentInnerStep(ReviewStepKeys.REVIEW_DESTINATION_URLS)
        }
        onRowSelectionChange={onRowSelectionChange}
        onRowDataChange={({ data, willRecheckAd }) => {
          modifyAdsToLoad([
            willRecheckAd ? returnAdToLoadAfterUpdate(data) : data,
          ]);
          setWillCheckErrors(!!willRecheckAd);
        }}
        scrollOffset={280}
      />
      <ReviewStepInnerDrawer
        adsToLoad={adsToLoad}
        selectedStores={selectedStores}
        productCatalogIds={productCatalogIds}
        instantExperiences={instantExperiences}
        currentInnerStep={currentInnerStep as ReviewStepKeys}
        onSave={onSave}
        onClose={() => setCurrentInnerStep()}
      />
    </>
  );
};

export default ReviewStep;
