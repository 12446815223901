import { MarketingMaterialTableItem } from "shared/types/marketingMaterials";
import { MaterialStatus } from "shared/types/salesEnablement";

type MaterialStatusBadge = {
  color: string;
  title: string;
  tooltipInfo: string;
};

export const getMaterialBadge = (
  material: MarketingMaterialTableItem,
): MaterialStatusBadge => {
  const errorStatus =
    material?.templateErrorStatus ?? material?.materialErrorStatus;
  if (errorStatus) {
    return {
      color: "red",
      title: errorStatus?.status || "Error",
      tooltipInfo: errorStatus?.message,
    };
  }

  let color = "blue";
  let title = "Unavailable";
  let tooltipInfo =
    "This Material will remain in Draft status until all required items are complete and it is saved.";

  if (material?.materialStatus === MaterialStatus.READY) {
    color = "green";
    title = "Ready to Deliver";
    tooltipInfo = "This material is ready to deliver.";
  } else if (material?.materialStatus === MaterialStatus.DRAFT) {
    title = "Draft";
  }

  return {
    color,
    title,
    tooltipInfo,
  };
};
