import { Steps } from "antd";
import { useTrackCurrentExec } from "../hooks/useCurrentExec";
import {
  useCurrentSession,
  useSetCurrentSession,
} from "../hooks/useCurrentSession";
import { AdLoadStep } from "screens/adLibrary/facebookUtils/adProto.types";
import { raise } from "utils/errorMessage";
import { useAdLoadStepInfo } from "../hooks/useAdLoadStepInfo";

export const AdLoadSteps = () => {
  const { session } = useCurrentSession();
  const { setSession } = useSetCurrentSession();

  const { adsWithError, percent } = useTrackCurrentExec();
  const countAdsWithError = adsWithError.length;

  const adLoadStep = session.data.step;

  const stepNumberMap = useAdLoadStepInfo();

  const onChangeStep = (current: number): void => {
    setSession(prevSession => {
      const [newStep] =
        Object.entries(stepNumberMap).find(
          ([_key, step]) => step.stepNumber === current,
        ) ?? raise("Invalid step number");

      prevSession.data.step = newStep as AdLoadStep;
    });
  };

  return (
    <Steps
      current={stepNumberMap[adLoadStep].stepNumber}
      onChange={onChangeStep}
      percent={percent}
      status={percent === 100 && countAdsWithError ? "error" : undefined}
    >
      {Object.values(stepNumberMap)
        .filter(step => step.visible)
        .map(step => (
          <Steps.Step
            key={step.stepNumber}
            title={step.title}
            disabled={step.disabled}
          />
        ))}
    </Steps>
  );
};
