import React, { Key } from "react";
import { Space, Typography } from "antd";
import { CloseCircleFilled } from "@ant-design/icons/lib/icons";

import {
  returnAdsetsFromCampaignData,
  returnCanAdBeLoadedToCampaign,
  returnTitleFromCampaignObjective,
} from "utils/facebook/helpers.campaign";

import uuid from "uuid";
import { flattenDeep } from "lodash";

import { CheckInfo } from "../../shared/types";
import { DataNode, EventDataNode } from "antd/lib/tree";
import {
  IFacebookAccount,
  IFacebookAdset,
  IFacebookCampaign,
} from "screens/adLibrary/facebookUtils/types";
import { IAd, IAdLoadDestination } from "shared/types/adLibrary";

type ReturnWillCreateAdAtDestinationArgs = {
  ad: IAd;
  adset: IFacebookAdset;
  campaign: IFacebookCampaign;
  checkedTreeKeys: Key[];
};

const returnWillCreateAdAtDestination = (
  args: ReturnWillCreateAdAtDestinationArgs,
) => {
  const canAdBeLoadedToCampaign = returnCanAdBeLoadedToCampaign(
    args.ad,
    args.campaign.objective,
  );
  return (
    canAdBeLoadedToCampaign && args.checkedTreeKeys.includes(args.adset.id!)
  );
};

const returnCanSelectTreeItem = (
  selectedAds: IAd[],
  campaign: IFacebookCampaign,
) => {
  const canLoadAdAtDestinationArr = selectedAds
    .map(ad => returnCanAdBeLoadedToCampaign(ad, campaign.objective))
    .filter(canLoad => canLoad);
  return !selectedAds.length || canLoadAdAtDestinationArr.length;
};

export const emptyDataTitle = "No data available";

const emptyChildListNode: DataNode = {
  key: "0",
  disabled: true,
  checkable: false,
  selectable: false,
  disableCheckbox: true,
  title: emptyDataTitle,
};

export const emptyTreeData: DataNode[] = [
  {
    ...emptyChildListNode,
    key: `${uuid()}`,
    title: (
      <Typography.Text type="secondary">
        {emptyChildListNode.title}
      </Typography.Text>
    ),
  },
];

type IMapAdLoadDataToTreeArgs = {
  ads?: IAd[];
  loading?: boolean;
  disabled?: boolean;
  adDestinationKeys: string[]; // used to get ad to insert
  accounts: IFacebookAccount[];
  checkedKeysToUse: Key[];
  campaigns?: IFacebookCampaign[];
  removedAdDestinationKeys: string[];
};

export const mapAdLoadDataToTree = (args: IMapAdLoadDataToTreeArgs) => {
  return args.accounts.map(account => {
    const isDisabled = args.disabled || args.loading;
    const matchingCampaigns =
      args.campaigns?.filter(
        campaign =>
          campaign.account_id === account.account_id &&
          !!campaign.adsets?.data?.length,
      ) ?? [];
    return {
      key: account.account_id,
      title: account.name,
      isLeaf: false,
      disabled: isDisabled,
      disableCheckbox: isDisabled,
      children: !matchingCampaigns?.length
        ? [{ ...emptyChildListNode, key: `${uuid()}` }]
        : matchingCampaigns.map(campaign =>
            mapCampaignToDataNode({
              campaign,
              isDisabled,
              ads: args.ads,
              checkedKeysToUse: args.checkedKeysToUse,
              adDestinationKeys: args.adDestinationKeys,
              removedAdDestinationKeys: args.removedAdDestinationKeys,
            }),
          ),
    };
  });
};

type MapCampaignToDataNodeArgs = {
  ads?: IAd[];
  isDisabled?: boolean;
  adDestinationKeys: string[];
  campaign: IFacebookCampaign;
  checkedKeysToUse: Key[];
  removedAdDestinationKeys: string[];
};

const mapCampaignToDataNode = (args: MapCampaignToDataNodeArgs): DataNode => {
  const canSelect = returnCanSelectTreeItem(args.ads ?? [], args.campaign);
  const isDisabledOrCantSelect = args.isDisabled || !canSelect;
  return {
    key: args.campaign.id!,
    title: args.campaign.name,
    disabled: isDisabledOrCantSelect,
    disableCheckbox: isDisabledOrCantSelect,
    children: args.campaign.adsets!.data!.map(adset =>
      mapAdsetToDataNode({
        adset,
        ads: args.ads,
        isDisabledOrCantSelect,
        campaign: args.campaign,
        adDestinationKeys: args.adDestinationKeys,
        checkedTreeKeys: args.checkedKeysToUse as string[],
        removedAdDestinationKeys: args.removedAdDestinationKeys,
      }),
    ),
  };
};

type MapAdsetToDataNodeArgs = {
  ads?: IAd[];
  adset: IFacebookAdset;
  checkedTreeKeys: string[];
  campaign: IFacebookCampaign;
  adDestinationKeys: string[];
  isDisabledOrCantSelect: boolean;
  removedAdDestinationKeys: string[];
};

const mapAdsetToDataNode = (args: MapAdsetToDataNodeArgs): DataNode => {
  const filteredAds = args.ads?.filter(
    ad =>
      returnWillCreateAdAtDestination({
        ad,
        adset: args.adset,
        campaign: args.campaign,
        checkedTreeKeys: args.checkedTreeKeys,
      }) &&
      args.adDestinationKeys.find(
        adKey =>
          adKey.includes(args.campaign.id!) &&
          adKey.includes(args.adset.id!) &&
          adKey.includes(ad.id),
      ) &&
      !args.removedAdDestinationKeys?.find(
        adKey =>
          adKey.includes(args.campaign.id!) &&
          adKey.includes(args.adset.id!) &&
          adKey.includes(ad.id),
      ),
  );
  return {
    key: args.adset.id!,
    title: args.adset.name,
    disabled: args.isDisabledOrCantSelect,
    disableCheckbox: args.isDisabledOrCantSelect,
    switcherIcon: !filteredAds?.length ? <div /> : undefined,
    isLeaf: !!filteredAds ? filteredAds.length === 0 : undefined,
    children:
      filteredAds?.map(ad => ({
        key: `${args.campaign.id!}_${args.adset.id!}_${ad.id}`,
        checkable: false,
        title: ad.inputParameters.name,
      })) ?? [],
  };
};

export const flattenDataTree = (treeData: DataNode[]) => {
  const list: { key: string; title: string }[] = [];
  const generateList = (data: DataNode[]) => {
    for (let i = 0; i < data.length; i++) {
      const node = data[i];
      const { key, title } = node;
      list.push({ key: key as string, title: title as string });
      if (node.children) {
        generateList(node.children);
      }
    }
  };
  generateList(treeData);
  return list;
};

type IReturnNodeTitleToRenderArgs = {
  node: DataNode;
  campaigns?: IFacebookCampaign[] | null;
  treeDataFromRedux?: DataNode[];
  ads?: IAd[];
  searchValue?: string;
  onRemoveBtnClick?: () => void;
};

export const returnNodeTitleToRender = (args: IReturnNodeTitleToRenderArgs) => {
  const { node, campaigns, ads, onRemoveBtnClick } = args;

  const foundCampaign = campaigns?.find(campaign => campaign.id === node.key);
  const isAd = !!ads?.find(
    ad => ad.id === node.key?.toString()?.split("_")?.[2],
  );

  const isNodeDisabled = node.disableCheckbox || node.disabled;

  return (
    <div
      style={
        isAd
          ? {
              backgroundColor: "#bae7ff",
            }
          : {
              opacity: isNodeDisabled ? 0.5 : 1,
            }
      }
    >
      <Space>
        {isAd && <CloseCircleFilled role="button" onClick={onRemoveBtnClick} />}
        {node.title}
      </Space>

      {foundCampaign && (
        <Typography.Text type="secondary">
          <br />
          {returnTitleFromCampaignObjective(foundCampaign.objective!)}
        </Typography.Text>
      )}
    </div>
  );
};

type HandleCheckedKeysChangeArgs = {
  info: CheckInfo;
  adsToInsertKeys: string[];
  currentCheckedKeys: string[];
  accounts: IFacebookAccount[];
  campaigns: IFacebookCampaign[];
  currentAdDestinationKeys: string[];
};

export const returnAdDestinationKeysOnCheck = (
  args: HandleCheckedKeysChangeArgs,
) => {
  if (!args.info.checked) {
    return args.currentAdDestinationKeys.filter(adKey =>
      args.info.checkedNodes
        .map(node => node.key.toString())
        .some(key => adKey.includes(key)),
    );
  }

  const newCheckedKeys: string[] = [];
  const pushCheckedKey = (nodes: DataNode[]) => {
    for (const node of nodes) {
      newCheckedKeys.push(node.key.toString());
      if (node.children) pushCheckedKey(node.children!);
    }
  };
  pushCheckedKey([args.info.node]);

  const adDestinationKeysToInsert = returnAdDestinationKeysToInsert({
    ...args,
    newCheckedKeys,
  });
  const newDestinationKeys = args.currentAdDestinationKeys.concat(
    adDestinationKeysToInsert,
  );

  return newDestinationKeys;
};

type ReturnAdDestinationKeysToInsertArgs = HandleCheckedKeysChangeArgs & {
  newCheckedKeys: string[];
};

const returnAdDestinationKeysToInsert = (
  args: ReturnAdDestinationKeysToInsertArgs,
) => {
  let adDestinationKeysToInsert: string[] = [];
  const recursivelyPushKeys = (currentIndex: number) => {
    if (currentIndex > args.newCheckedKeys.length - 1) return;

    const currentKey = args.newCheckedKeys[currentIndex];

    const hasMatchingKey = !!adDestinationKeysToInsert.find(key =>
      key.includes(currentKey),
    );
    if (hasMatchingKey) {
      recursivelyPushKeys(currentIndex + 1);
      return;
    }

    const account = args.accounts.find(
      account => account.account_id === currentKey,
    );
    if (account) {
      const keysToConcat: string[] = flattenDeep(
        args.campaigns
          .filter(campaign => campaign.account_id === account.account_id)
          .map(campaign =>
            (campaign.adsets?.data ?? []).map(adset =>
              args.adsToInsertKeys.map(
                adShellId => `${campaign.id}_${adset.id}_${adShellId}`,
              ),
            ),
          ),
      );
      adDestinationKeysToInsert =
        adDestinationKeysToInsert.concat(keysToConcat);
      recursivelyPushKeys(currentIndex + 1);
      return;
    }

    const campaign = args.campaigns.find(
      campaign => campaign.id === currentKey,
    );
    if (campaign) {
      const keysToConcat: string[] = flattenDeep(
        (campaign.adsets?.data ?? []).map(adset =>
          args.adsToInsertKeys.map(
            adShellId => `${campaign.id}_${adset.id}_${adShellId}`,
          ),
        ),
      );
      adDestinationKeysToInsert =
        adDestinationKeysToInsert.concat(keysToConcat);
      recursivelyPushKeys(currentIndex + 1);
      return;
    }

    const adsets = returnAdsetsFromCampaignData(args.campaigns);
    const adset = adsets.find(adset => adset.id === currentKey);
    if (!adset) {
      recursivelyPushKeys(currentIndex + 1);
      return;
    }

    const keysToConcat = args.adsToInsertKeys.map(
      adShellId => `${adset.campaign_id}_${adset.id}_${adShellId}`,
    );
    adDestinationKeysToInsert = adDestinationKeysToInsert.concat(keysToConcat);
    recursivelyPushKeys(currentIndex + 1);
    return;
  };

  recursivelyPushKeys(0);

  return adDestinationKeysToInsert;
};

export const returnAdLoadDestinationAfterAdRemoval = (args: {
  currentEventDataNode: EventDataNode;
  treeData: DataNode[];
  adLoadDestination?: IAdLoadDestination | null;
  checkedKeysToUse: Key[];
}) => {
  const {
    currentEventDataNode,
    treeData,
    adLoadDestination = {},
    checkedKeysToUse,
  } = args;

  const treePathIndices = currentEventDataNode.pos
    ?.split("-")
    ?.map(indexString => parseInt(indexString));

  // if the indices are not available or the tree is not structured properly, then return current data
  if (!treePathIndices?.length || treePathIndices.length < 5) {
    return adLoadDestination;
  }

  const accountIndex = treePathIndices[1];
  const campaignIndex = treePathIndices[2];
  const adsetIndex = treePathIndices[3];
  const adIndex = treePathIndices[4];

  const newTreeData = [...treeData];

  const adsetItem =
    newTreeData[accountIndex]?.children?.[campaignIndex]?.children?.[
      adsetIndex
    ];

  const adItem = adsetItem?.children?.[adIndex];

  const { key: adsetKey, children: otherAds } = adsetItem || {};
  const campaignItem = newTreeData[accountIndex].children?.[campaignIndex];
  const { key: campaignKey, children: otherAdsets } = campaignItem || {};

  const willUncheckAdset = !otherAds?.length && !!adsetKey;
  const willUncheckCampaign =
    !!campaignKey &&
    willUncheckAdset &&
    !otherAdsets?.filter(
      item => item.key !== adsetKey && checkedKeysToUse.includes(item.key),
    ).length;

  const keysToUncheck: Key[] = willUncheckCampaign
    ? [campaignKey!, ...(campaignItem?.children?.map(adset => adset.key) ?? [])]
    : willUncheckAdset
    ? [adsetKey!]
    : [];

  const newRemovedAdKeys = [
    ...(adLoadDestination?.removedAdDestinationKeys ?? []),
  ]
    .concat(adItem ? [adItem.key.toString()] : [])
    .filter(adKey =>
      !willUncheckCampaign
        ? adKey
        : !adKey.includes(campaignKey!.toString()) &&
          !adKey.includes(adsetKey!.toString()),
    );

  const newCheckedKeysToUse = checkedKeysToUse.filter(
    checkedKey => !keysToUncheck.includes(checkedKey),
  ) as string[];

  const newAdDestinationKeys = [
    ...(adLoadDestination?.adDestinationKeys ?? []),
  ].filter(key => key !== adItem?.key);

  const newAdLoadDestinationData: IAdLoadDestination = {
    ...adLoadDestination,
    treeData: newTreeData,
    checkedKeys: newCheckedKeysToUse,
    adDestinationKeys: newAdDestinationKeys,
    removedAdDestinationKeys: newRemovedAdKeys,
  };

  return newAdLoadDestinationData;
};

export const convertTreeDataToReduxData = (treeData: DataNode[]) => {
  const newTreeData = [...treeData];
  const escapeHTML = (data: DataNode[]) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].switcherIcon) {
        data[i].switcherIcon = "";
      }
      if (data[i].children) {
        escapeHTML(data[i].children!);
      }
    }
  };
  escapeHTML(newTreeData);
  return newTreeData;
};
