import {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import { Col, Input, Menu, Popover, Row } from "antd";

import { setMenuFilter } from "utils/helpers";

import { TextAreaRef } from "antd/lib/input/TextArea";
import { MenuInfo } from "rc-menu/lib/interface";
import {
  IDisclosureVariable,
  IStateDisclosureElement,
  TDisclosureType,
} from "shared/types/legalLingo";

import styles from "./DisclosureContent.module.scss";

interface IDisclosureContentProps {
  variables: IDisclosureVariable[];
  isParentTabActive: boolean;
  disclosureObjFromParent: IStateDisclosureElement;
  placeholder: string;
  defaultValue: string;
}

interface IDisclosureContentHandlers {
  onDisclosureChange: (offerType: TDisclosureType, text: string) => void;
}

type TDisclosureContentProps = IDisclosureContentProps &
  IDisclosureContentHandlers;

type NumberAtPriceDisclosureName = "then_input" | "or_else_input";

interface INumberAtPriceDisclosureObj {
  lt_number_of_vehicles?: number;
  max_number_of_vins?: number;
  then_input?: string;
  or_else_input?: string;
}

const DisclosureContent = ({
  variables,
  isParentTabActive,
  disclosureObjFromParent,
  onDisclosureChange,
}: TDisclosureContentProps): ReactElement => {
  useEffect(() => {
    if (!isParentTabActive) {
      toggleVariablesDropdown(false);
    }
  }, [isParentTabActive]);

  const textAreaRef = useRef(null);

  const [numberAtPriceRefs] = useState<
    Record<
      NumberAtPriceDisclosureName,
      React.MutableRefObject<TextAreaRef | null>
    >
  >({
    then_input: useRef(null),
    or_else_input: useRef(null),
  });

  const { offerType, text } = disclosureObjFromParent;
  const [showVariablesDropdown, toggleVariablesDropdown] =
    useState<boolean>(false);

  const [showNumberAtPriceDropdown, setNumberAtPriceDropdown] = useState<
    Record<NumberAtPriceDisclosureName, boolean>
  >({ then_input: false, or_else_input: false });

  const [numberAtPricePrevText, setNumberAtPricePrevText] = useState<
    Record<NumberAtPriceDisclosureName, string>
  >({ then_input: "", or_else_input: "" });

  const caretIndexRef: React.MutableRefObject<number | null> = useRef(null);
  const [currMenuFilter, setCurrMenuFilter] = useState<string>("");

  const isRegularDisc = offerType !== "Number at this Price";

  const numberAtPriceDisclosureObj = useMemo(
    () =>
      !isRegularDisc && text
        ? (JSON.parse(text) as INumberAtPriceDisclosureObj)
        : {},
    [isRegularDisc, text],
  );

  const numberAtThisPriceDiscIds: NumberAtPriceDisclosureName[] = [
    "then_input",
    "or_else_input",
  ];

  const toggleNumberAtPriceDropdown = useCallback(
    (id: NumberAtPriceDisclosureName, value: boolean) => {
      const newShowDropdown = { ...showNumberAtPriceDropdown, [id]: value };
      setNumberAtPriceDropdown(newShowDropdown);
    },
    [showNumberAtPriceDropdown],
  );

  const putNumberAtPricePrevTextById = (
    id: NumberAtPriceDisclosureName,
    newText: string,
  ) => {
    const newPrevTextObj = { ...numberAtPricePrevText, [id]: newText };
    setNumberAtPricePrevText(newPrevTextObj);
  };

  const handleTextChange = useCallback(
    (args: {
      inputText: string;
      selectionStart?: number;
      wasPasted?: boolean;
    }) => {
      const { inputText, selectionStart = 0, wasPasted } = args;
      const numberOfCurliesFromVal = inputText.split("{").length;
      const numberOfCurliesFromState = text.split("{").length;

      onDisclosureChange(offerType, inputText);

      const numLeftCurlies = inputText.split("{").length - 1;
      const numRightCurlies = inputText.split("}").length - 1;

      if (wasPasted) return;

      setMenuFilter(
        numLeftCurlies,
        numRightCurlies,
        caretIndexRef,
        selectionStart,
        inputText,
        text,
        currMenuFilter,
        setCurrMenuFilter,
      );

      if (numberOfCurliesFromVal === numberOfCurliesFromState) return;
      if (numberOfCurliesFromVal < numberOfCurliesFromState) {
        toggleVariablesDropdown(false);
        return;
      }

      toggleVariablesDropdown(true);
    },
    [text, onDisclosureChange, offerType, currMenuFilter],
  );

  const handleNumberAtPriceTextChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { target } = event;
    const { value, id } = target;

    const typedId = id as NumberAtPriceDisclosureName;
    const textVal = numberAtPriceDisclosureObj
      ? numberAtPriceDisclosureObj[typedId] || ""
      : "";

    const numberOfCurliesFromVal = value.split("{").length;
    const numberOfCurliesFromState = textVal.split("{").length;

    putNumberAtPricePrevTextById(typedId, value);

    const newJson = { ...numberAtPriceDisclosureObj, [id]: value };
    const newJsonString = JSON.stringify(newJson);
    onDisclosureChange(offerType, newJsonString);

    toggleNumberAtPriceDropdown(
      typedId,
      numberOfCurliesFromVal > numberOfCurliesFromState,
    );
  };

  const handleNumberAtPriceNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { target } = event;
    const { value, id } = target;

    const typedId = id as "lt_number_of_vehicles" | "max_number_of_vins";

    const newJson = { ...numberAtPriceDisclosureObj, [typedId]: value };
    const newJsonString = JSON.stringify(newJson);
    onDisclosureChange(offerType, newJsonString);
  };

  const getNewText = (charArray: string[], key: React.Key) => {
    const newText = charArray.join("");

    if (newText.includes("{{")) {
      const testReplaced = newText.replace("{{", "{");
      return testReplaced.replace(`${key}} `, `${key}}`);
    }

    return newText;
  };

  const handleNumberAtPriceItemSelect = useCallback(
    (item: MenuInfo) => {
      const { key } = item;

      const disclosureKey = (
        Object.keys(showNumberAtPriceDropdown) as NumberAtPriceDisclosureName[]
      ).find(discKey => showNumberAtPriceDropdown[discKey]);

      if (!disclosureKey) {
        return;
      }

      const refToUse = numberAtPriceRefs[disclosureKey];
      const prevTextToUse = numberAtPricePrevText[disclosureKey];
      const disclosureToUse = numberAtPriceDisclosureObj[disclosureKey];

      const currCharArray = (disclosureToUse || "").split("");
      const differentIndex = text.length - prevTextToUse.length;

      currCharArray[differentIndex || 0] = `{${key}} `;

      const newText = getNewText(currCharArray, key);

      if (currCharArray.join("").includes("{{")) {
        const newJson = {
          ...numberAtPriceDisclosureObj,
          [disclosureKey]: newText,
        };
        const newJsonString = JSON.stringify(newJson);

        toggleNumberAtPriceDropdown(disclosureKey, false);
        onDisclosureChange(offerType, newJsonString);
      }

      refToUse?.current?.focus();
    },
    [
      showNumberAtPriceDropdown,
      numberAtPriceRefs,
      numberAtPricePrevText,
      numberAtPriceDisclosureObj,
      text,
      toggleNumberAtPriceDropdown,
      onDisclosureChange,
      offerType,
    ],
  );

  const handleItemSelect = useCallback(
    (item: MenuInfo) => {
      const { key } = item;
      const keyToInsert = `{${key}}`;

      const firstPart = text.slice(
        0,
        (caretIndexRef.current || 0) - (currMenuFilter.length + 1),
      );
      const secondPart = text.slice(caretIndexRef.current || 0);
      const newText = firstPart.concat(keyToInsert).concat(secondPart);

      onDisclosureChange(offerType, newText);
      setCurrMenuFilter("");
      toggleVariablesDropdown(false);

      if (textAreaRef && textAreaRef.current) {
        (textAreaRef.current as TextAreaRef).focus();
      }
    },

    [text, currMenuFilter.length, onDisclosureChange, offerType],
  );

  const variablesMenu = useMemo(() => {
    const filteredVariables = variables.filter(variable =>
      variable.name.toLowerCase().includes(currMenuFilter.toLowerCase()),
    );
    return (
      <div style={{ maxHeight: 240, overflowY: "scroll" }}>
        <Menu
          className={styles.menu}
          onClick={
            isRegularDisc ? handleItemSelect : handleNumberAtPriceItemSelect
          }
        >
          {filteredVariables.map(variable => (
            <Menu.Item key={`${variable.name}`} className={styles.menuItem}>
              {text.includes(`${variable.name}`) ? (
                <b>{variable.name}</b>
              ) : (
                <span>{variable.name}</span>
              )}
            </Menu.Item>
          ))}
        </Menu>
      </div>
    );
  }, [
    variables,
    isRegularDisc,
    handleItemSelect,
    handleNumberAtPriceItemSelect,
    currMenuFilter,
    text,
  ]);

  return (
    <>
      {isRegularDisc && (
        <Popover
          className={styles.popover}
          title="Insert Variable"
          content={variablesMenu}
          visible={showVariablesDropdown}
          placement="bottom"
          onVisibleChange={() => {
            toggleVariablesDropdown(false);
          }}
          trigger="click"
        >
          <Input.TextArea
            onFocus={() => {
              setCurrMenuFilter("");
            }}
            style={{ width: "100%" }}
            ref={textAreaRef}
            allowClear={true}
            value={text}
            onChange={event => {
              const { target } = event;

              const { value, selectionStart } = target;
              handleTextChange({ inputText: value, selectionStart });
            }}
            onPaste={event => {
              event.preventDefault();
              handleTextChange({
                wasPasted: true,
                inputText: event.clipboardData.getData("Text"),
              });
            }}
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        </Popover>
      )}
      {!isRegularDisc && (
        <>
          <Row
            align="middle"
            gutter={16}
            justify="center"
            style={{ textAlign: "center", paddingTop: "1em" }}
          >
            <Col span={10} className="number-at-price-input-col">
              <span>If Less than:</span>
              <br />
              <Input
                type="number"
                min="0"
                step="1"
                allowClear={true}
                id="lt_number_of_vehicles"
                onChange={handleNumberAtPriceNumberChange}
                value={numberAtPriceDisclosureObj?.lt_number_of_vehicles}
              />
            </Col>
            <Col span={4} />
            <Col span={10} className="number-at-price-input-col">
              <span>Max # to List:</span>
              <br />
              <Input
                onFocus={() => {
                  setCurrMenuFilter("");
                }}
                type="number"
                min="0"
                step="1"
                allowClear={true}
                id="max_number_of_vins"
                onChange={handleNumberAtPriceNumberChange}
                value={numberAtPriceDisclosureObj?.max_number_of_vins}
              />
            </Col>
          </Row>
          <br />
          {numberAtThisPriceDiscIds.map(key => {
            // key = "first_name_input_last_name_input"
            const title = key
              .split("input")[0]
              .split("_")
              .join(" ")
              .toUpperCase();
            return (
              <Row
                align="middle"
                gutter={16}
                justify="center"
                key={`${key}-row`}
                style={{ paddingTop: "2em" }}
              >
                <Col span={24}>
                  <h2 style={{ textAlign: "center" }}>{title}</h2>
                  <Popover
                    title="Insert Variable"
                    content={variablesMenu}
                    visible={showNumberAtPriceDropdown[key]}
                    autoAdjustOverflow={true}
                    placement="bottom"
                    onVisibleChange={() => {
                      toggleNumberAtPriceDropdown(key, false);
                    }}
                  >
                    <Input.TextArea
                      ref={numberAtPriceRefs[key]}
                      name="number-at-this-price-disclosure"
                      id={key}
                      allowClear={true}
                      value={numberAtPriceDisclosureObj[key]}
                      onChange={handleNumberAtPriceTextChange}
                      autoSize={{ minRows: 2, maxRows: 6 }}
                    />
                  </Popover>
                </Col>
              </Row>
            );
          })}
        </>
      )}
    </>
  );
};

export default DisclosureContent;
