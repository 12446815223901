import { Form } from "antd";
import { assetTypes } from "shared/constants/dataManagement";
import { SearchOutlined } from "@ant-design/icons";
import { Col, Input, Modal, Row, Select, Upload, Tooltip } from "antd";
import { RowTitleProps } from "./types";
import { DrawerRow } from "./drawerForm/DrawerRow";
import { useContext } from "react";
import { DrawerContext } from "../DrawerContext/DrawerContext";
import { DrawerInputString } from "./drawerForm/DrawerInputString";
import { DrawerInputNumber } from "./drawerForm/DrawerInputNumber";
import { CAM_ENABLED } from "shared/components/media";
import { MediaThemeList } from "./MediaThemeList";

export const DrawerForm = () => {
  const {
    FULL,
    artboard,
    customName,
    fileList,
    filteredFileList,
    handlePreview,
    handleThemeImageChange,
    handleInputChange,
    isEdit,
    placeHolder,
    searchBoxValue,
    selectedBrandNames,
    selectedAccountNames,
    selectedTagNames,
    setArtboard,
    setCustomName,
    setFilteredFileList,
    setFileList,
    setIsEdit,
    setSearchBoxValue,
    setSelectedBrandNames,
    setSelectedAccountNames,
    setSelectedTagNames,
    setShowModal,
    showModal,
    tempFileList,
  } = useContext(DrawerContext);
  const rowTitles = [
    { type: "oems", title: "Assign Brand(s) (optional)" },
    { type: "stores", title: "Assign Account(s) (optional)" },
    { type: "tags", title: "Assign Tags(s) (optional)" },
  ];
  const onChangeSelect = (value: string) => {
    setArtboard({ ...artboard, asset_type: value });
  };

  const onChangeImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchBoxValue(e.target.value);
    if (e.target.value === "") {
      setFilteredFileList(fileList);
    } else {
      const lcName = e.target.value.toLowerCase();
      const checkName = (name: string) => name.toLowerCase().includes(lcName);
      const list = fileList.filter(({ name }) => checkName(name));
      setFilteredFileList(list);
    }
  };
  const clearSelections = () => {
    setSelectedBrandNames([]);
    setSelectedAccountNames([]);
    setSelectedTagNames([]);
  };
  const onOkModal = () => {
    setShowModal(false);
    setCustomName("");
    if (isEdit) {
      setIsEdit(false);
    } else {
      const newImage = {
        ...tempFileList[tempFileList.length - 1],
        name: customName,
        oems: selectedBrandNames,
        tags: selectedTagNames,
        stores: selectedAccountNames,
      };
      const newList = [...fileList, newImage];
      setFileList(newList);
      setFilteredFileList(newList);
      setSearchBoxValue("");
    }
    clearSelections();
  };
  const onCancelModal = () => {
    setIsEdit(false);
    setShowModal(false);
    clearSelections();
  };
  return (
    <Form layout="vertical" hideRequiredMark={true}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Select Asset Type">
            <Select
              className="artboard-asset-type-select"
              placeholder=""
              key="artboard-assetType-select"
              value={artboard.asset_type}
              style={{ width: "100%" }}
              disabled={FULL}
              onChange={onChangeSelect}
            >
              {assetTypes.map(assetType => (
                <Select.Option
                  key={`assetType-option-key-${assetType}`}
                  value={assetType}
                >
                  {assetType}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Define Artboard Name">
            <DrawerInputString />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Select Artboard Size">
            <Row gutter={16}>
              <Col span={12}>
                <DrawerInputNumber type="width" />
              </Col>
              <Col span={12}>
                <DrawerInputNumber type="height" />
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
      {CAM_ENABLED ? (
        <MediaThemeList
          artboard={artboard}
          filteredFileList={filteredFileList}
          setFilteredFileList={setFilteredFileList}
          setSearchBoxValue={setSearchBoxValue}
          searchBoxValue={searchBoxValue}
        />
      ) : (
        <Form.Item label="Theme Background Image">
          <div className="theme-images-upload-container">
            <div className="artboard-drawer-add-input">
              <Input
                placeholder="Search for images"
                style={{ marginLeft: "40px" }}
                suffix={
                  <Tooltip title="Search images">
                    <SearchOutlined />
                  </Tooltip>
                }
                onChange={onChangeImages}
                value={searchBoxValue}
              />
            </div>
            <Upload
              className="theme-images-upload"
              listType="picture"
              fileList={filteredFileList}
              onPreview={handlePreview}
              onChange={handleThemeImageChange}
            >
              <div className="artboard-drawer-add-search">
                <div className="artboard-drawer-add-icon" data-cy="upload-btn">
                  +
                </div>
              </div>
            </Upload>
          </div>
          <Modal
            title="Theme Background Image"
            visible={showModal}
            onOk={onOkModal}
            onCancel={onCancelModal}
          >
            <span>Please name your theme image</span>
            <Input
              placeholder={placeHolder}
              value={customName}
              onChange={handleInputChange}
            ></Input>
            <div style={{ marginTop: 10 }}>
              {rowTitles.map(rowTitle => (
                <DrawerRow
                  key={`Row-select-${rowTitle.type}`}
                  rowTitle={rowTitle as RowTitleProps}
                />
              ))}
            </div>
          </Modal>
        </Form.Item>
      )}
    </Form>
  );
};
