import { notification } from "antd";
import { getAdProto } from "screens/adLibrary/facebookUtils/adProto";
import {
  AdLoadStatusItem,
  AdProtoDraft,
  SessionData,
} from "screens/adLibrary/facebookUtils/adProto.types";
import { useAds } from "screens/adLibrary/shared/hooks/useAds";
import { useStartAdLoadExec } from "shared/hooks/adLibrary/adLoad/adLoadExec";
import { useFetchInfiniteDealers } from "shared/hooks/useFetchDealers";
import { IAd, IInstantExperience } from "shared/types/adLibrary";
import { IAccount } from "shared/types/accountManagement";
import { getErrorMessage, raise } from "utils/errorMessage";
import { NormalizedCampaignData } from "../campaignData.utils";
import { useCurrentSession, useSetCurrentSession } from "./useCurrentSession";
import { useNormalizedCampaignsData } from "./useNormalizedCampaignsData";
import { useFetchInstantExperiences } from "shared/hooks/adLibrary/useFetchInstantExperiences";
import { useParams } from "react-router-dom";
import { useTrackCurrentExec } from "./useCurrentExec";

export const useLoadAdsToFb = () => {
  const { originalData: ads } = useAds();
  const { session } = useCurrentSession();
  const { setSession } = useSetCurrentSession();
  const sessionData = session.data;
  const { dealers: stores } = useFetchInfiniteDealers();
  const { mutate: startAdLoadExec, isLoading: isStartingExecution } =
    useStartAdLoadExec();
  const { campaignData } = useNormalizedCampaignsData();
  const { data: instantExperiences = [] } = useFetchInstantExperiences();
  const { instanceId, plannerId } = useParams<{
    instanceId: string;
    plannerId: string;
  }>();

  const { adsWithError } = useTrackCurrentExec();

  const loadAdsToFb = async () => {
    try {
      const adProtos = getAdProtos({
        sessionData,
        ads,
        instantExperiences,
        stores,
        adsetsData: campaignData.adsets,
        adsWithError,
      });

      setSession(prevSession => {
        prevSession.data.step = "load";
      });

      startAdLoadExec(
        {
          adProtos,
          sessionId: session.id,
          plannerId,
          instanceId,
        },
        {
          onError: () => {
            notification.error({
              message: "Error",
              description:
                "Something went wrong while loading ads to Facebook.",
            });
          },
        },
      );
    } catch (error) {
      notification.error({
        message: getErrorMessage(error),
      });
    }
  };

  return {
    loadAdsToFb,
    isStartingExecution,
  };
};

const getAdProtos = ({
  sessionData,
  ads,
  instantExperiences,
  stores,
  adsetsData,
  adsWithError,
}: {
  sessionData: SessionData;
  ads: IAd[];
  instantExperiences: IInstantExperience[];
  stores: IAccount[];
  adsetsData: NormalizedCampaignData["adsets"];
  adsWithError: AdLoadStatusItem[];
}) => {
  return sessionData.adProtoDrafts
    .map(adProtoDraft => ({
      ...adProtoDraft,
      dests: adProtoDraft.dests.filter(adDest => adDest.loadToFb),
    }))
    .map(adProtoDraft => {
      if (!adsWithError.length) return adProtoDraft;

      return filterInDestsWithLoadingErrors(adProtoDraft, adsWithError);
    })
    .filter(adProtoDraft => adProtoDraft.dests.length > 0)
    .map(adProtoDraft => {
      const store =
        stores?.find(
          storeItem => storeItem.dealer_name === adProtoDraft.storeName,
        ) ?? raise("Store not found");
      const ad =
        ads?.find(adItem => adItem.id === adProtoDraft.id) ??
        raise("Ad not found");

      return getAdProto({
        adProtoDraft,
        ad,
        instantExperiences,
        store,
        adsetsData,
      });
    });
};

const filterInDestsWithLoadingErrors = (
  adProtoDraft: AdProtoDraft,
  adsWithError: AdLoadStatusItem[],
): AdProtoDraft => {
  const destsWithLoadingErrors = adProtoDraft.dests.filter(adDest => {
    return adsWithError.some(
      adWithError =>
        adWithError.adId === adProtoDraft.id &&
        adWithError.adsetId === adDest.adsetId,
    );
  });

  return {
    ...adProtoDraft,
    dests: destsWithLoadingErrors,
  };
};
