import { CloseOutlined } from "@ant-design/icons";
import { ModalProps, ProgressProps } from "antd";
import Page from "screens/adLibrary/facebookUtils/page";
import { ICanvasElementParams } from "screens/adLibrary/facebookUtils/types";
import { ElementType, IInstantExperience } from "shared/types/adLibrary";

export type LoadStatus = "idle" | "loading" | "success" | "error";

export function returnDoesIEHaveProductSetElement(ie: IInstantExperience) {
  return !!ie.body_elements?.find(
    bodyElement => bodyElement.element_type === "ELEMENT_GROUP",
  );
}

export function returnModalBaseProps({
  title,
}: {
  title?: string;
}): ModalProps {
  const style = { color: "#ffffff" };
  return {
    visible: true,
    closeIcon: <CloseOutlined style={style} />,
    title: title && <span style={style}>{title}</span>,
  };
}

export function returnProgressStatus(
  loadStatus: LoadStatus,
): ProgressProps["status"] {
  switch (loadStatus) {
    case "loading":
      return "active";
    case "success":
      return "success";
    case "error":
      return "exception";
    default:
      return "normal";
  }
}

export async function returnLoadIsCompleteLink(
  loadStatus: LoadStatus,
  pageId: string,
) {
  if (loadStatus === "error") {
    return "/everything-ads/ads/:instantExperienceId/edit".replace(
      ":instantExperienceId",
      "<ieID>",
    );
  }

  // Possible TO DO: create react query hook for fetchPage
  const fbPage = new Page(pageId);
  const { name: pageName } = await fbPage.getPageData();

  return `https://business.facebook.com/${pageName?.replace(
    " ",
    "-",
  )}-${pageId}}/publishing_tools/?section=ADS_CANVAS`;
}

export const canvasElementTypeToBodyElementMap: Record<
  ElementType,
  keyof ICanvasElementParams
> = {
  BUTTON: "canvas_button",
  FOOTER: "canvas_footer",
  PHOTO: "canvas_photo",
  VIDEO: "canvas_video",
  CAROUSEL: "canvas_carousel",
  ELEMENT_GROUP: "canvas_product_set",
};
