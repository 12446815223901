import { useCallback, useMemo, useState } from "react";
import { useLazyGetAssetEmbedlinksQuery } from "redux/media/media.api";
import { useSearch } from "./useSearch";
import { fontsDownloaded } from "./utils.fonts";

const fontNameMapper = (font: WDAsset) => {
  return font.name.split(".")[0];
};

export const useCAMFonts = () => {
  const { data } = useSearch({
    initParams: {
      keyword: "Font",
    },
    filetype: "woff",
  });
  const [getAssetEmbedLinks] = useLazyGetAssetEmbedlinksQuery();

  const rawFontAssets = useMemo(() => {
    return data?.items || [];
  }, [data]);

  const [loadedFontNames, setLoadedFontNames] = useState<string[]>([]);

  const loadFont = useCallback(
    async (
      fontFamily: string,
      loadFontFn: (fontFamily: string) => Promise<FontFaceObserver | undefined>,
    ) => {
      try {
        if (loadedFontNames.includes(fontFamily)) {
          return;
        }
        if (fontsDownloaded().includes(fontFamily)) {
          await loadFontFn(fontFamily);
          setLoadedFontNames(prev => [...new Set([...prev, fontFamily])]);
          return;
        }

        const font = rawFontAssets.find(
          asset => asset.filename.split(".")[0] === fontFamily,
        );
        if (!font) {
          throw new Error(`${fontFamily} does not exist`);
        }
        const { directlinks } = await getAssetEmbedLinks(
          font.id,
          true,
        ).unwrap();
        const fontFace = new FontFace(
          fontFamily,
          `url(${directlinks.original})`,
          {
            weight: "normal",
            style: "normal",
          },
        );
        const loadedFont = await fontFace.load();
        document.fonts.add(loadedFont);
        await loadFontFn(fontFamily);
        return;
      } catch (err) {
        throw err;
      }
    },
    [rawFontAssets, getAssetEmbedLinks, setLoadedFontNames, loadedFontNames],
  );

  return {
    loadFont,
    fontFamilies: rawFontAssets.map(fontNameMapper),
  };
};
