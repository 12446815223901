import { SyncOutlined } from "@ant-design/icons";
import {
  Row,
  Input,
  Button,
  Collapse,
  Modal,
  message,
  Select,
  DatePicker,
  Checkbox,
  Form,
} from "antd";
import { FC, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  AssetInstanceRecord,
  IAssetBuilderState,
  IAssetInstance,
  IImagesLauncherData,
  ILauncherData,
  InstanceCheckboxDict,
  ISelectedOffer,
  LauncherType,
  OfferData,
  TargetType,
} from "shared/types/assetBuilder";

import { AssetLauncherWidgetModal } from "./AssetLaunchWidgetModal";
import LabelSelectWithAutoComplete from "shared/components/select/LabelSelectWithAutoComplete";
import {
  IAccount,
  IAccountPosition,
  IAccountRecord,
  IGetAccountsResult,
} from "shared/types/accountManagement";
import uuid from "uuid/v4";
import "../../AssetLauncher.scss";
import "./AssetLauncherPanel.scss";

import AssetLauncherPanelSettingsTab from "./AssetLauncherPanelSettingsTab";
import AssetLauncherPanelPositioningTab from "./AssetLauncherPanelPositioningTab";

import { SelectValue } from "antd/lib/select";

import moment from "moment";

import { useRenderTemplate } from "shared/components/RenderTemplateProvider";
import {
  removePurchasePlaceholder,
  returnInitialAssetsToExport,
} from "utils/helpers.asset";
import {
  dealerToDealerRecordData,
  customImageDataCheck,
} from "../../../../../utils/helpers";
import { IDimension, ITemplate } from "shared/types/designStudio";

import { getFontNames } from "utils/helpers.fabric";
import { Canvas } from "fabric/fabric-impl";

interface ICanvasData {
  filename: string;
  dimension: IDimension;
  json: string;
  fonts: string[];
}

interface IAssetLauncherPanel {
  enableLaunchPage: boolean;
  selectedExportedAssets: InstanceCheckboxDict;
  launcherSelectedTab: "website" | "facebook" | "email" | "display" | "social";
  numInstancesInAssetLauncher: number;
  assetInstances: AssetInstanceRecord;
  savedOrder: IAssetBuilderState["savedOrder"];
  getDealer: (dealerName: string) => void;
  dealerResult: IAccount | null | undefined;
  createWebIntegration: (webIntegrationData: ILauncherData) => void;
  getWebIntegrations: (domain: string) => void;
  launcherImages: string[];
  launcherData: ILauncherData;
  setLauncherData: (launcherData: ILauncherData) => void;
  templatesOrderedByDimension: IAssetInstance[];
  setTemplatesOrderedByDimension: (
    templatesOrderedByDimension: IAssetInstance[],
  ) => void;
  goLiveButtonClicked: boolean;
  setGoLiveButtonClicked: (goLiveButtonClicked: boolean) => void;
  generalMessage: string;

  selectedLauncherInstances: Record<number, IAssetInstance>;
  setRefIndex: (index: number) => void;
  setLinkDimension: (dimension: string | string[]) => void;
  setCurrOrderOfSelectedLauncherInstances: (
    currOrderOfSelectedLauncherInstances: number[],
  ) => void;
  setSelectedLauncherInstances: (
    selectedLauncherInstances: Record<number, IAssetInstance>,
  ) => void;
  currOrderOfSelectedLauncherInstances: number[];
  getWebIntegrationStatus: (domain: string, widgetURL: string) => void;
  integratedToPage: boolean;

  updateDealer: (
    inputDealer: IAccountRecord,
    updatedData?: Partial<IAccount>,
  ) => void;
  updateDealerResult: IGetAccountsResult | null;

  openLaunchModal: boolean;
  setOpenLaunchModal: (openLaunchModal: boolean) => void;

  setIsLaunchButtonDisabled: (isLaunchButtonDisabled: boolean) => void;
  orderExpiration?: number;
  selectedOffers?: ISelectedOffer[];
  canvasAssetInstances?: Record<string, Record<string, IAssetInstance[]>>;
}

const AssetLauncherPanel: FC<IAssetLauncherPanel> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  launcherSelectedTab,
  numInstancesInAssetLauncher,
  savedOrder,
  getDealer,
  dealerResult,
  createWebIntegration,
  getWebIntegrations,
  launcherImages,
  launcherData,
  setLauncherData,
  templatesOrderedByDimension,
  setGoLiveButtonClicked,
  generalMessage,
  selectedLauncherInstances,
  setRefIndex,
  setLinkDimension,
  currOrderOfSelectedLauncherInstances,
  selectedOffers,
  canvasAssetInstances,
  getWebIntegrationStatus,
  integratedToPage,
  updateDealer,
  updateDealerResult,
  openLaunchModal,
  setOpenLaunchModal,

  setIsLaunchButtonDisabled,
  orderExpiration,
}) => {
  const [selectedTab, setSelectedTab] = useState<string>("settings");

  const inputTimerRef = useRef<NodeJS.Timeout | null>(null);

  const [type, setType] = useState<LauncherType>("Single");

  const [integrationPosition, setIntegrationPosition] =
    useState<IAccountPosition | null>(null);

  const [openWidgetModal, setOpenWidgetModal] = useState<boolean>(false);

  const [newPositionPreset, setNewPositionPreset] = useState<IAccountPosition>({
    location: "",
    mobileLocation: "",
    name: "",
    relativePath: "",
    positionModifer: "",
    mobilePositionModifer: "",
  });

  const [showNewPositionPresetModal, toggleShowNewPositionPresetModal] =
    useState<boolean>(false);

  const [showDeletePosModal, toggleShowDeletePosModal] =
    useState<boolean>(false);
  const [posIndexToEditOrDelete, setPosIndexToEditOrDelete] = useState<
    number | null
  >(null);

  const [mouseHoverIndex, setMouseHoverIndex] = useState<number | null>(null);

  const [modalLaunchButtonDisabled, setModalLaunchButtonDisabled] =
    useState<boolean>(true);

  const [startDateVal, setStartDateVal] = useState<string | null>(null);
  const [endDateVal, setEndDateVal] = useState<string | null>(null);

  useEffect(() => {
    getDealer(savedOrder?.selectedOrder.dealer_name || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDealerResult]);

  useEffect(() => {
    if (launcherData.domain) {
      getWebIntegrations(launcherData.domain);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [launcherData.domain]);

  useEffect(() => {
    if (dealerResult) {
      const widgetURL = `${process.env.REACT_APP_DOMAIN}/${process.env.REACT_APP_ENV}`;
      getWebIntegrationStatus(`${dealerResult?.dealer_url}`, widgetURL);
      let domain = dealerResult?.dealer_url.replace(/(^\w+:|^)\/\//, "") || "";
      if (domain.endsWith("/")) {
        domain = domain.slice(0, domain.length - 1);
      }
      setLauncherData({
        ...launcherData,
        domain,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealerResult]);

  useEffect(() => {
    // auto create max number of possible image spots in launcherData
    const onLoadTempImages = [];
    for (let i = 1; i <= numInstancesInAssetLauncher; i++) {
      onLoadTempImages.push({
        imageUrl: "",
        imageAltText: "",
        imageLink: "",
        targetData: "",
        target: "_parent" as TargetType,
      });
    }
    setLauncherData({
      ...launcherData,
      images: onLoadTempImages,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numInstancesInAssetLauncher]);

  useEffect(() => {
    if (launcherData.domain) {
      getWebIntegrations(launcherData.domain);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [launcherData.domain]);

  useEffect(() => {
    if (dealerResult) {
      setLauncherData({
        ...launcherData,
        domain: dealerResult?.dealer_url.replace(/(^\w+:|^)\/\//, "") || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealerResult]);

  useEffect(() => {
    // for each template ordered by dimension, if instance is NOT contained in selectedLauncherInstances,
    // return null and update launcherData to empty
    // TODO: refactor this useEffect. the images on the setLauncherData are not changing.

    const clientName = process.env.REACT_APP_AV2_CLIENT || "default";
    const env = process.env.REACT_APP_ENV;

    for (let i = 0; i < templatesOrderedByDimension.length; i++) {
      const idxOfTemplate = i in selectedLauncherInstances ? i : -1;

      const instance = templatesOrderedByDimension[i];

      const { year, make, model, trim } =
        instance.offers[Object.keys(instance.offers)[0]].offerData;

      const storeId = dealerResult?.dealer_name || "";

      const vin =
        Object.keys(instance.offers).length > 0
          ? Object.keys(instance.offers)[0]
          : "";

      const trackingDataQuery: string[] = [];
      [storeId, vin, year, make, model].forEach((attr: string) => {
        if (attr) {
          trackingDataQuery.push(attr);
        }
      });

      // encode querry string and determine if extra tracking metadata exists
      const trackingDataQueryString =
        trackingDataQuery.length > 0
          ? `AID=${clientName}-${env}-${encodeURIComponent(
              trackingDataQuery.join("-"),
            )}`
          : `AID=${clientName}-${env}`;

      if (idxOfTemplate < 0) {
        const tempImageArray = launcherData.images;
        tempImageArray[i] = {
          ...tempImageArray[i],
          imageLink: "",
          imageAltText: `${year || ""} ${make || ""} ${model || ""} ${
            trim || ""
          }`,
          target: "_parent" as TargetType,
          targetData: trackingDataQueryString,
        };
        setLauncherData({
          ...launcherData,
          images: tempImageArray,
        });
      } else {
        if (!launcherData.images[idxOfTemplate]?.imageAltText) {
          const tempImageArray = launcherData.images;
          tempImageArray[i] = {
            ...tempImageArray[i],
            imageAltText: `${year || ""} ${make || ""} ${model || ""} ${
              trim || ""
            }`,
            target: "_parent" as TargetType,
            targetData: trackingDataQueryString,
          };
          setLauncherData({
            ...launcherData,
            images: tempImageArray,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLauncherInstances]);

  useEffect(() => {
    // At this point, launcherImages should only be the selected asets --> images based off of the currOrderOfSelectedLauncherInstances.
    // We can use this order to auto-add the images into launcherData.

    // auto add in imageUrl for each template
    for (let i = 0; i < currOrderOfSelectedLauncherInstances.length; i++) {
      const indexToAddImage = currOrderOfSelectedLauncherInstances[i];
      const imageUrlToAdd = launcherImages[i];

      const tempImageArray = launcherData.images;
      tempImageArray[indexToAddImage] = {
        ...tempImageArray[indexToAddImage],
        imageUrl: imageUrlToAdd,
      };
      setLauncherData({
        ...launcherData,
        images: tempImageArray,
      });
    }
    launcherImages.length > 0 && setModalLaunchButtonDisabled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [launcherImages]);

  const orderLauncherImages = () => {
    const orderedImages: IImagesLauncherData[] = [];
    for (const idx of currOrderOfSelectedLauncherInstances) {
      orderedImages.push(launcherData.images[idx]);
    }

    return orderedImages;
  };

  useEffect(() => {
    if (openLaunchModal) {
      // toggleGoLiveModal(true);
      setGoLiveButtonClicked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openLaunchModal]);

  useEffect(() => {
    // if everything is good, should return false;

    setIsLaunchButtonDisabled(
      !(
        Object.keys(selectedLauncherInstances).length > 0 &&
        isLauncherDataValid(launcherData.images)
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLauncherInstances, launcherData]);

  const isLauncherDataValid = (orderedImages: IImagesLauncherData[]) => {
    // Check Images for invalid link urls
    for (const image of orderedImages) {
      if (image?.imageLink && !image.imageLink.startsWith("https://"))
        return false;
    }

    for (const image of orderedImages) {
      if (image?.imageAltText === "") return false;
    }

    return !(
      (launcherData.startDate || -Infinity) > (launcherData.endDate || Infinity)
    );
  };

  useEffect(() => {
    setLauncherData({
      ...launcherData,
      location: integrationPosition?.location || "",
      mobileLocation: integrationPosition?.mobileLocation || "",
      relativePath: integrationPosition?.relativePath || "",
      positionModifer: integrationPosition?.positionModifer || "",
      mobilePositionModifer: integrationPosition?.mobilePositionModifer || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationPosition]);

  const [dealerAsIDealerRecord, setDealerAsIDealerRecord] =
    useState<IAccountRecord | null>(null);

  useEffect(() => {
    setDealerAsIDealerRecord(dealerToDealerRecordData(dealerResult));
  }, [dealerResult]);

  const [embedHtmlSelected, setEmbedHtmlSelected] = useState(false);
  const renderTemplateContext = useRenderTemplate();

  const [isHtmlExportDisabled, setIsHtmlExportDisabled] = useState(false);

  const isOnlyCustomImage = (idx: number) => {
    return idx >= templatesOrderedByDimension.length;
  };

  const areAllIndexesCustomImages = () => {
    for (const idx of currOrderOfSelectedLauncherInstances) {
      if (idx < templatesOrderedByDimension.length) return false;
    }
    return true;
  };

  const shouldHtmlBeDisabled = (idx: number) => {
    return (
      (isOnlyCustomImage(idx) && launcherData.type === "singleImage") ||
      (launcherData.type !== "singleImage" && areAllIndexesCustomImages())
    );
  };

  useEffect(() => {
    let shouldBeDisabled = false;
    currOrderOfSelectedLauncherInstances.forEach(idx => {
      if (shouldHtmlBeDisabled(idx)) {
        shouldBeDisabled = true;
      }
    });
    setIsHtmlExportDisabled(shouldBeDisabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currOrderOfSelectedLauncherInstances]);

  const isValidCanvasContent = (
    instanceList: number[],
    canvases: Record<string, Canvas> | undefined,
  ) => {
    return (
      (canvases && Object.keys(canvases).length > 0) ||
      (canvases &&
        Object.keys(canvases).length === 0 &&
        instanceList.length > 0) //custom images only
    );
  };

  const packageCanvasContents = (selectedInstanceList: number[]) => {
    let referenceTemplate: ITemplate | null = null;

    for (const idx of selectedInstanceList) {
      referenceTemplate = templatesOrderedByDimension[idx]?.template;
      if (referenceTemplate) break;
    }

    const dimension = `${referenceTemplate?.artboard.width} x ${referenceTemplate?.artboard.height}`;

    const canvasToEmbed = returnInitialAssetsToExport(
      canvasAssetInstances as Record<string, Record<string, IAssetInstance[]>>,
      selectedOffers as ISelectedOffer[],
      true,
    );

    let filenames: string[] = [];

    for (const size in canvasToEmbed.website) {
      filenames = filenames.concat(canvasToEmbed.website[size]);
    }

    filenames = filenames.map((file, idx) => {
      return file.split("__")[0].concat(`__${idx}`);
    });

    filenames = filenames.filter(file => {
      return (
        file.includes(`${dimension}`) &&
        selectedInstanceList.includes(parseInt(file.split("__")[1]))
      );
    });

    const canvases = renderTemplateContext?.getExportingCanvases(filenames);

    if (!canvases || !isValidCanvasContent(selectedInstanceList, canvases)) {
      message.error("There was an error while processing instances.");
      setOpenLaunchModal(false);
      setEmbedHtmlSelected(false);
      return;
    }

    let canvasData = Object.keys(canvases).reduce(
      (acc, filename) => {
        const canvas = canvases[filename];
        const dimension: IDimension = {
          width: canvas.getWidth(),
          height: canvas.getHeight(),
        };
        const json = canvas.toJSON(["customType", "customData", "name"]);
        const fonts = getFontNames(canvas);

        (
          json as unknown as {
            objects: fabric.Object[];
          }
        ).objects.map((obj: any) => {
          delete obj["filters"];
        });

        acc.push({
          filename,
          json,
          dimension,
          fonts,
        });

        return acc;
      },
      [] as Array<{
        filename: string;
        dimension: IDimension;
        json: string;
        fonts: string[]; // list of font family
      }>,
    );

    // arrange the list as in the order they where selected
    canvasData = selectedInstanceList.map(selectedIndex => {
      return (
        canvasData.find(
          canvas =>
            parseInt(canvas.filename[canvas.filename.length - 1]) ===
            selectedIndex,
        ) ||
        canvasData.find(
          canvas =>
            parseInt(
              canvas.filename.substring(
                canvas.filename.length - 2,
                canvas.filename.length,
              ),
            ) === selectedIndex,
        ) ||
        ({} as any)
      );
    });

    return canvasData;
  };

  const getTextAndStampsFromCanvas = (canvasData: ICanvasData[]) => {
    let seoText: string[] = [];
    let stampsText: string[] = [];
    let isCustomImage: boolean[] = [];

    canvasData.forEach((canvas: any) => {
      if (!Object.keys(canvas).length) {
        // its a custom image
        seoText = [...seoText, ""];
        stampsText = [...stampsText, ""];
        isCustomImage = [...isCustomImage, true];
        return;
      }
      const { objects } = canvas.json;
      let text = "";
      let stamp = "";
      for (const obj of objects) {
        if (obj.text) text += `${obj.text} `;
        if (obj.type === "group" && obj.customType === "stamp") {
          obj.objects.forEach((stmp: any) => {
            if (stmp.type === "textbox") {
              stamp += `${stmp.text} `;
            }
          });
        }
      }
      seoText = [...seoText, text];
      stampsText = [...stampsText, stamp];
      isCustomImage = [...isCustomImage, false];
    });
    return {
      seoText,
      stampsText,
      isCustomImage,
    };
  };

  const getSchemaInfoPrice = (offerData: OfferData) => {
    if (offerData.msrp) return offerData.msrp;
    if (offerData.finalPrice) return offerData.finalPrice;
    return "";
  };

  const getSchemaInfo = (canvasData: ICanvasData[]) => {
    const launcherKeys = Object.keys(selectedLauncherInstances);
    const { seoText, stampsText, isCustomImage } =
      getTextAndStampsFromCanvas(canvasData);
    const schemaData = launcherKeys.map((key, idx) => {
      const offers = selectedLauncherInstances[parseInt(key)].offers;
      const vin = Object.keys(offers)[0];
      const offerData = offers[vin].offerData;
      return {
        modelDate: !isCustomImage[idx] ? offerData.year : "",
        vin: !isCustomImage[idx] ? vin : "",
        name: !isCustomImage[idx]
          ? `${offerData.year} ${offerData.make} ${offerData.model} ${
              offerData.trim ? offerData.trim : ""
            }`
          : "",
        price: !isCustomImage[idx] ? getSchemaInfoPrice(offerData) : "",
        currency: !isCustomImage[idx] ? "USD" : "",
        text: seoText[idx] || "",
        stamp: stampsText[idx] || "",
      };
    });

    return schemaData;
  };

  const getOfferImpressions = (canvasData: ICanvasData[]) => {
    const currentOrder = savedOrder?.selectedOrder;
    const launcherKeys = Object.keys(selectedLauncherInstances);
    const { isCustomImage } = getTextAndStampsFromCanvas(canvasData);

    const offerImpressionaData = launcherKeys.map((key, idx) => {
      const offers = selectedLauncherInstances[parseInt(key)].offers;
      const vin = Object.keys(offers)[0];
      const offerData = offers[vin].offerData as OfferData;

      const {
        year,
        make,
        model,
        trim,
        vehicleType,
        msrp,
        numberOfPayments,
        dealerCode,
        dealerId,
        totalDueAtSigning,
        aprRate,
        monthlyPayment,
        financePayment,
        savingsOffMSRP,
        percentOffMSRP,
      } = offerData;
      const offerTypes = Object.keys(offers[vin].offerTypes);
      const customImageCheck = !isCustomImage[idx];
      const assetTheme = `${
        selectedLauncherInstances[parseInt(key)].lifestyleImageName ?? ""
      } | ${selectedLauncherInstances[parseInt(key)].template?.name ?? ""}`;
      let offerPrice = "";
      offerTypes.forEach((offType, index) => {
        const firstIndex = index === 0;
        switch (offType) {
          case "Lease":
            offerPrice += firstIndex ? monthlyPayment : ` | ${monthlyPayment}`;
            break;
          case "APR":
            offerPrice += firstIndex ? aprRate : ` | ${aprRate}`;
            break;
          case "Finance":
            offerPrice += firstIndex ? financePayment : ` | ${financePayment}`;
            break;
          case "Purchase($ Off)":
            offerPrice += firstIndex ? savingsOffMSRP : ` | ${savingsOffMSRP}`;
            break;
          case "Purchase(%)":
            offerPrice += firstIndex ? percentOffMSRP : ` | ${percentOffMSRP}`;
            break;
        }
        return;
      });

      if (!offerPrice) offerPrice = msrp;

      return {
        // some are left blank and will be filled in the backend
        event: "",
        num_of_offers: !isCustomImage[idx] ? `${offerTypes.length}` : "",
        offerImpression_type: !isCustomImage[idx] ? offerTypes.join(" | ") : "",
        offerImpression_year: customImageDataCheck(customImageCheck, year),
        offerImpression_make: customImageDataCheck(customImageCheck, make),
        offerImpression_model: customImageDataCheck(customImageCheck, model),
        offerImpression_trim: customImageDataCheck(customImageCheck, trim),
        offerImpression_body: customImageDataCheck(
          customImageCheck,
          vehicleType,
        ),

        offerImpression_price: customImageDataCheck(
          customImageCheck,
          offerPrice,
        ),
        offerImpression_term: customImageDataCheck(
          customImageCheck,
          numberOfPayments,
        ),
        offerImpression_storeName: currentOrder ? currentOrder.dealer_name : "",
        offerImpression_storeId: dealerCode || dealerId || "",
        offerImpression_dueSigning: customImageDataCheck(
          customImageCheck,
          totalDueAtSigning,
        ),
        offerImpression_theme: assetTheme,
        offerImpression_salesEvent: "",
        offerImpression_positionPreset: `${integrationPosition?.name || ""}`,
        offerImpression_layout: type,

        offerClick_type: !isCustomImage[idx] ? offerTypes.join(" | ") : "",
        offerClick_year: customImageDataCheck(customImageCheck, year),
        offerClick_make: customImageDataCheck(customImageCheck, make),
        offerClick_model: customImageDataCheck(customImageCheck, model),
        offerClick_trim: customImageDataCheck(customImageCheck, trim),
        offerClick_body: customImageDataCheck(customImageCheck, vehicleType),

        offerClick_price: customImageDataCheck(customImageCheck, offerPrice),
        offerClick_term: customImageDataCheck(
          customImageCheck,
          numberOfPayments,
        ),
        offerClick_storeName: currentOrder ? currentOrder.dealer_name : "",
        offerClick_storeId: dealerCode || dealerId || "",
        offerClick_dueSigning: customImageDataCheck(
          customImageCheck,
          totalDueAtSigning,
        ),
        offerClick_theme: assetTheme,
        offerClick_salesEvent: "",
        offerClick_positionPreset: `${integrationPosition?.name || ""}`,
        offerClick_layout: type,
        navigationInteraction_action: "", // this is for the right arrow click etc.
      };
    });

    return offerImpressionaData;
  };

  return (
    <div
      style={{
        overflow: "scroll",
        height: "90vh",
      }}
    >
      <Collapse
        accordion
        className="collapse-container launcher-panel"
        expandIconPosition="right"
        defaultActiveKey={["sliderParameters"]}
      >
        <Collapse.Panel
          key="launcher-widget-status"
          header={`Web Integration Widget`}
        >
          <div style={{ paddingBottom: "25px" }}>
            <div style={{ paddingBottom: "10px" }}>
              <span>Status: </span>
              <span
                style={{
                  color: integratedToPage ? "#00CC00" : "#CCCC00",
                }}
              >
                {integratedToPage ? "Active" : "Inactive"}
              </span>
            </div>
            <Button
              key="save-edits-button"
              className="save-edits-button"
              style={{
                float: "left",
                color: "white",
                backgroundColor: "#0092d0",
                width: "100%",
              }}
              onClick={() => {
                setOpenWidgetModal(!openWidgetModal);
              }}
            >
              Show Code Snippet
            </Button>
          </div>
          {launcherData && (
            <AssetLauncherWidgetModal
              domain={launcherData.domain}
              openWidgetModal={openWidgetModal}
              toggleWidgetModal={() => {
                setOpenWidgetModal(!openWidgetModal);
              }}
            ></AssetLauncherWidgetModal>
          )}
        </Collapse.Panel>

        <Collapse.Panel key="sliderParameters" header="Integration Parameters">
          <div className="template-type-selection-tabs">
            {(["settings", "positioning"] as string[]).map(tab => (
              <div
                key={`template-type-tab-${tab}`}
                className={`template-type-tab ${tab} ${
                  tab === selectedTab ? "selected" : ""
                }`}
                onClick={e => {
                  e.preventDefault();

                  setSelectedTab(tab);
                }}
              >
                {tab === "settings" && "Settings"}
                {tab === "positioning" && "Positioning"}
              </div>
            ))}
          </div>

          {selectedTab === "settings" && (
            <AssetLauncherPanelSettingsTab
              dealerResult={dealerResult}
              launcherData={launcherData}
              setLauncherData={setLauncherData}
              integrationPosition={integrationPosition}
              setIntegrationPosition={setIntegrationPosition}
              setType={setType}
              type={type}
            />
          )}

          {selectedTab === "positioning" && (
            <AssetLauncherPanelPositioningTab
              dealerResult={dealerResult}
              mouseHoverIndex={mouseHoverIndex}
              showNewPositionPresetModal={showNewPositionPresetModal}
              posIndexToEditOrDelete={posIndexToEditOrDelete}
              dealerAsIDealerRecord={dealerAsIDealerRecord}
              newPositionPreset={newPositionPreset}
              showDeletePosModal={showDeletePosModal}
              setNewPositionPreset={setNewPositionPreset}
              setPosIndexToEditOrDelete={setPosIndexToEditOrDelete}
              toggleShowNewPositionPresetModal={
                toggleShowNewPositionPresetModal
              }
              setMouseHoverIndex={setMouseHoverIndex}
              toggleShowDeletePosModal={toggleShowDeletePosModal}
              updateDealer={updateDealer}
            />
          )}
        </Collapse.Panel>
        <Collapse.Panel
          className="selectedAssetsPanel"
          style={{
            minWidth: "100%",
            opacity:
              Object.keys(selectedLauncherInstances).length === 0 ? "0.5" : "",
          }}
          key="launcher-data-fields"
          header={
            <div data-cy="number-of-selected-assets">
              {`Selected Assets: ${
                Object.keys(selectedLauncherInstances).length
              }`}
            </div>
          }
          disabled={Object.keys(selectedLauncherInstances).length === 0}
        >
          <div
            style={{
              maxHeight: "500px",
            }}
          >
            {currOrderOfSelectedLauncherInstances.map(
              (keyAsNum: number, index) => {
                const instance = selectedLauncherInstances[keyAsNum];

                const { year, make, model, trim } =
                  instance.offers[Object.keys(instance.offers)[0]].offerData;
                const dimension = `${instance?.template?.artboard.width} x ${instance?.template?.artboard.height}`;
                return (
                  <div key={`${instance?.template?.name}-key-${index}`}>
                    <div
                      style={{
                        maxWidth: "210px",
                        display: "inline-block",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setLinkDimension(dimension);
                        setRefIndex(index);
                      }}
                    >
                      <b>{`Instance ${index + 1}`}</b>
                      <div style={{ color: "grey" }}>{dimension}</div>
                      <div>{`${instance?.template?.name}`}</div>
                      {instance.originalIndexOrder &&
                        instance.originalIndexOrder <
                          templatesOrderedByDimension.length && (
                          <div>{`${year || ""} ${make || ""} ${model || ""} ${
                            trim || ""
                          }`}</div>
                        )}
                    </div>
                    <Form layout="vertical">
                      <Row gutter={[4, 4]}>
                        <Form.Item label="Link">
                          <LabelSelectWithAutoComplete
                            linkValue={launcherData.images[keyAsNum]?.imageLink}
                            urlLabels={dealerResult?.labels}
                            onChange={(value: SelectValue) => {
                              if (!value) {
                                const tempImageArray = launcherData.images;
                                tempImageArray[keyAsNum] = {
                                  ...tempImageArray[keyAsNum],
                                  imageLink: "",
                                };

                                setLauncherData({
                                  ...launcherData,
                                  images: tempImageArray,
                                });

                                return;
                              }

                              const obj = dealerResult?.labels?.find(
                                label => label.name === value,
                              );

                              if (obj) {
                                const tempImageArray = launcherData.images;
                                tempImageArray[keyAsNum] = {
                                  ...tempImageArray[keyAsNum],
                                  imageLink: obj.url,
                                };

                                setLauncherData({
                                  ...launcherData,
                                  images: tempImageArray,
                                });
                              } else {
                                const tempImageArray = launcherData.images;
                                tempImageArray[keyAsNum] = {
                                  ...tempImageArray[keyAsNum],
                                  imageLink: value as string,
                                };

                                setLauncherData({
                                  ...launcherData,
                                  images: tempImageArray,
                                });
                              }
                            }}
                            onSearch={(value: string) => {
                              const tempImageArray = launcherData.images;
                              tempImageArray[keyAsNum] = {
                                ...tempImageArray[keyAsNum],
                                imageLink: value,
                              };

                              setLauncherData({
                                ...launcherData,
                                images: tempImageArray,
                              });
                            }}
                            width={260}
                          />
                        </Form.Item>
                      </Row>
                      <Row gutter={6}>
                        <Form.Item label="Alt Text">
                          <Input
                            data-cy="alt-text-input"
                            style={{ width: "260px" }}
                            key={`alt-tag-key-${keyAsNum}`}
                            onChange={event => {
                              if (inputTimerRef.current) {
                                clearTimeout(inputTimerRef.current);
                              }
                              const tempImageArray = launcherData.images;
                              tempImageArray[keyAsNum] = {
                                ...tempImageArray[keyAsNum],
                                imageAltText: event.target.value,
                              };

                              setLauncherData({
                                ...launcherData,
                                images: tempImageArray,
                              });
                            }}
                            value={launcherData.images[keyAsNum]?.imageAltText}
                          />
                          {launcherData.images[keyAsNum]?.imageAltText ===
                            "" && (
                            <div style={{ color: "red" }}>
                              This is a required field
                            </div>
                          )}
                        </Form.Item>
                      </Row>
                      <Row gutter={[4, 4]}>
                        <Form.Item label="Target">
                          <Select
                            data-cy="target-select"
                            style={{ width: "260px" }}
                            onChange={(value: string) => {
                              const mapToKey: Record<string, string> = {
                                "New Window/Tab": "_blank",
                                "Existing Window/Tab": "_parent",
                              };
                              const tempImageArray = launcherData.images;
                              tempImageArray[keyAsNum] = {
                                ...tempImageArray[keyAsNum],
                                target: mapToKey[value] as TargetType,
                              };

                              setLauncherData({
                                ...launcherData,
                                images: tempImageArray,
                              });
                            }}
                            value={
                              launcherData.images[keyAsNum]?.target ===
                              "_parent"
                                ? "Existing Window/Tab"
                                : "New Window/Tab"
                            }
                          >
                            {["Existing Window/Tab", "New Window/Tab"].map(
                              (type: string) => {
                                return (
                                  <Select.Option
                                    className="template-tag-item"
                                    key={`${type}-key`}
                                    value={type}
                                  >
                                    {type}
                                  </Select.Option>
                                );
                              },
                            )}
                          </Select>
                        </Form.Item>
                      </Row>
                    </Form>
                  </div>
                );
              },
            )}
          </div>
        </Collapse.Panel>
      </Collapse>

      <Modal
        title={"Go Live Integration Modal"}
        visible={openLaunchModal}
        maskClosable={true}
        onCancel={() => {
          setGoLiveButtonClicked(false);
          setOpenLaunchModal(false);
          setModalLaunchButtonDisabled(true);
          setEmbedHtmlSelected(false);

          // need to set dates to null so Launch Button in Header can be clicked again
          setLauncherData({
            ...launcherData,
            startDate: null,
            endDate: null,
          });
          setStartDateVal(null);
          setEndDateVal(null);
        }}
        closable={true}
        footer={[
          [
            <Button
              key="cancel"
              className="secondary-btn"
              onClick={() => {
                setGoLiveButtonClicked(false);
                setOpenLaunchModal(false);
                setModalLaunchButtonDisabled(true);
                setEmbedHtmlSelected(false);

                // need to set dates to null so Launch Button in Header can be clicked again
                setLauncherData({
                  ...launcherData,
                  startDate: null,
                  endDate: null,
                });
                setStartDateVal(null);
                setEndDateVal(null);
              }}
            >
              Cancel
            </Button>,
            <Button
              data-cy="launch-button-go-live-modal"
              style={{ color: "white", width: "75px" }}
              disabled={
                generalMessage !== "Canvas successfully converted." ||
                !isLauncherDataValid(orderLauncherImages())
              }
              key="save"
              type="primary"
              onClick={() => {
                const guid = uuid();

                const orderedImages = orderLauncherImages();

                if (!isLauncherDataValid(orderedImages)) {
                  message.error("Please Fill out Launcher Panel Correctly");
                } else {
                  const canvasData =
                    packageCanvasContents(
                      currOrderOfSelectedLauncherInstances,
                    ) || [];

                  const schemaInfo = getSchemaInfo(canvasData);
                  const offerImpressions = getOfferImpressions(canvasData);

                  createWebIntegration({
                    ...launcherData,
                    instanceID: guid,
                    images: orderedImages,
                    schemaInfo,
                    offerImpressions,
                  });
                }
                setEmbedHtmlSelected(false);
                // return to defaults
                setLauncherData({
                  ...launcherData,
                  embedAsHTML: false,
                });
                setGoLiveButtonClicked(false);
                setOpenLaunchModal(false);
              }}
            >
              <div>
                {!modalLaunchButtonDisabled ? (
                  "Launch"
                ) : (
                  <SyncOutlined spin style={{ fontSize: 14 }} />
                )}
              </div>
            </Button>,
          ],
        ]}
      >
        <div>
          <Checkbox
            onChange={e => {
              const isEmbedAsHtmlChecked = e.target.checked;

              if (isEmbedAsHtmlChecked) {
                const canvasData = packageCanvasContents(
                  currOrderOfSelectedLauncherInstances,
                );
                setLauncherData({
                  ...launcherData,
                  embedAsHTML: isEmbedAsHtmlChecked,
                  canvasData,
                });
              } else {
                setLauncherData({
                  ...launcherData,
                  embedAsHTML: isEmbedAsHtmlChecked,
                });
              }

              setEmbedHtmlSelected(isEmbedAsHtmlChecked);
            }}
            checked={embedHtmlSelected}
            disabled={isHtmlExportDisabled}
          >
            Embed integration as HTML
          </Checkbox>

          <div>
            Please enter a label to track the launch of this Integration.
            <Input
              style={{ color: "black" }}
              disabled={false}
              key="label-launch-text"
              onChange={event => {
                setLauncherData({
                  ...launcherData,
                  launchLabel: event.target.value,
                });
              }}
            />
          </div>
          <div style={{ paddingTop: "15px" }}>
            <div>
              <p>
                The Start and End dates can be used to schedule the activation
                and deactivation of an Integration.
              </p>
            </div>
            <span
              style={{
                paddingRight: "15px",
              }}
            >
              {`Start Date`}
            </span>
            <DatePicker
              showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
              allowClear={true}
              onChange={(value, date) => {
                const parsedDate: number = Date.parse(date);
                setStartDateVal(date);
                setLauncherData({
                  ...launcherData,
                  startDate: parsedDate,
                });
              }}
              value={
                startDateVal
                  ? moment(`${startDateVal}`, "YYYY/MM/DD HH:mm:ss")
                  : null
              }
            />
          </div>
          <div style={{ paddingTop: "15px" }}>
            <span style={{ paddingRight: "15px" }}>{`End Date `}</span>
            <DatePicker
              showTime={{
                defaultValue: moment(orderExpiration),
              }}
              onOpenChange={(status: boolean) => {
                if (status && !endDateVal) {
                  setEndDateVal(
                    orderExpiration
                      ? moment(orderExpiration).format("YYYY/MM/DD HH:mm:ss")
                      : moment().add(7, "days").format("YYYY/MM/DD HH:mm:ss"),
                  );
                }
              }}
              allowClear={true}
              onChange={(value, date) => {
                const parsedDate: number = Date.parse(date);
                setEndDateVal(date);
                setLauncherData({
                  ...launcherData,
                  endDate: parsedDate,
                });
              }}
              value={
                endDateVal
                  ? moment(`${endDateVal}`, "YYYY/MM/DD HH:mm:ss")
                  : null
              }
            />
          </div>
          {(launcherData.startDate || -Infinity) >
            (launcherData.endDate || Infinity) && (
            <div style={{ color: "red" }}>
              Please enter a Start date which is earlier than the End date
            </div>
          )}
          <div style={{ marginTop: 25 }}>
            <p>Note: All fields are optional</p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const {
    newOrders,
    assetBuilder: { savedOrder },
  } = state;

  // pre-process the assetInstances
  let canvasAssetInstances: Record<
    string,
    Record<string, IAssetInstance[]>
  > = {};
  if (savedOrder?.assetInstances) {
    canvasAssetInstances = removePurchasePlaceholder(
      savedOrder?.assetInstances,
    ); //removePurchasePlaceholder(savedOrder?.assetInstances);
  }

  const { currentSelectedOrder } = newOrders;
  return {
    orderExpiration: currentSelectedOrder.expiresAt,
    selectedOffers: savedOrder?.selectedOffers || [],
    canvasAssetInstances,
  };
};

export default connect(mapStateToProps, null)(AssetLauncherPanel);
