import { useMemo, useState } from "react";
import { Typography, Alert, Table, Spin } from "antd";
import styles from "./AdLibrary.module.scss";
import { getMatchColumnsTableData } from "./utils/getMatchColumnsTableData";
import { getColumnMatch } from "./utils/getColumnMatch";
import { getColumns } from "./MatchColumnsColumns";
import { useFeedContext } from "../../shared/contexts/FeedContext";
import { useFetchArtboards } from "shared/hooks/useFetchArtboards";
import { useAdLibraryExportContext } from "./AdLibraryExportContext";
import { useFetchTemplates } from "./useFetchTemplates";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { AdTypeSelect } from "./AdTypeSelect";
import {
  HandleSelectColumnMatch,
  MatchColumnsTableData,
  RowType,
} from "../shared/types";

export const MatchColumns = () => {
  const {
    columns: feedColumns,
    selectedAssetBatches,
    assetBatches,
    selectedRows,
    assetBatchesForSelectedRows,
  } = useFeedContext();
  const { columnMatches, setColumnMatches, adType } =
    useAdLibraryExportContext();
  const { data: artboards } = useFetchArtboards();
  const [thumbnailErrors, setThumbnailErrors] = useState<string[]>([]);

  const { templates, isLoading: isFetchingTemplates } = useFetchTemplates(
    assetBatchesForSelectedRows,
  );

  const tableData = useMemo(
    () =>
      getMatchColumnsTableData({
        assetBatches,
        selectedAssetBatches,
        feedColumns,
        artboards: artboards?.result?.artboards,
        selectedRows,
      }),
    [assetBatches, selectedAssetBatches, feedColumns, artboards, selectedRows],
  );

  const handleSelectColumnMatch: HandleSelectColumnMatch = (
    recommendedColumnTitle,
    rowData,
  ) => {
    setColumnMatches(previousColumnMatches => {
      const columnMatch = getColumnMatch(
        recommendedColumnTitle,
        assetBatchesForSelectedRows,
        rowData,
      );

      return {
        ...previousColumnMatches,
        [rowData.key]: columnMatch,
      };
    });
  };

  const onThumbnailError = (assetBatchId: string) => {
    setThumbnailErrors(previousThumbnailErrors => [
      ...previousThumbnailErrors,
      assetBatchId,
    ]);
  };

  const columns = getColumns({
    handleSelectColumnMatch,
    columnMatches,
    adType,
    onThumbnailError,
    thumbnailErrors,
  });

  const hasUnmatchedColumns = Object.values(columnMatches).some(
    columnMatch => columnMatch.recommendedColumnTitle === null,
  );

  const hasInvalidDimensions = tableData.some(
    row => row.isValidDimensions === false,
  );

  const getTableRowClassName = (record: MatchColumnsTableData) => {
    const isMatch = columnMatches[record.key]?.recommendedColumnTitle !== null;
    const isValidDimensions = record.isValidDimensions === true;
    const hasThumbnailError =
      record.assetBatchId &&
      thumbnailErrors.includes(record.assetBatchId) &&
      record.rowType === RowType.Media;

    if (record.rowType === RowType.Media && !isValidDimensions)
      return styles.errorRow;
    if (!isMatch || hasThumbnailError) {
      return styles.highlightRow;
    }
    return "";
  };

  return (
    <Spin
      spinning={isFetchingTemplates}
      size="large"
      indicator={<Loading3QuartersOutlined spin />}
      wrapperClassName={styles.spinWrapper}
    >
      <div className={styles.matchColumnsWrapper}>
        <div>
          <Typography.Text>Select the Ad Type to be created:</Typography.Text>
          <AdTypeSelect templates={templates} tableData={tableData} />
        </div>

        {adType && (
          <>
            <Typography.Text>
              <div>
                Use the dropdown on the right to match the names of the asset
                batches’ columns with the fields required by Ad Library.
              </div>
              <div>
                Any fields with unmatched columns can then be filled in manually
                in the next step.
              </div>
            </Typography.Text>

            <div className={styles.alertContainer}>
              {hasInvalidDimensions && (
                <Alert
                  message={`The assets in the asset batches selected have dimensions different from the ones needed for ${adType}. If you proceed, assets in the right dimensions will need to be uploaded manually in Ad Library.`}
                  type="error"
                  showIcon
                />
              )}

              {hasUnmatchedColumns && (
                <Alert
                  message="Unable to match some fields automatically, please match them manually if necessary."
                  type="warning"
                  showIcon
                />
              )}

              {!!thumbnailErrors.length && (
                <Alert
                  message="Thumbnail generation failed unexpectedly. Proceeding may require the manual upload of assets in the Ad Library."
                  type="warning"
                  showIcon
                />
              )}
            </div>

            <Table
              size="small"
              columns={columns}
              dataSource={tableData}
              pagination={false}
              bordered
              rowClassName={getTableRowClassName}
            />
          </>
        )}
      </div>
    </Spin>
  );
};
