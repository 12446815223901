import { Navigate, Outlet, useLocation } from "react-router-dom";
import { CAMConfig } from "./config";
import { useMediaStorage } from "./useMediaStorage";

export const CAMGuard = () => {
  const { pathname, search } = useLocation();
  const [getData] = useMediaStorage();

  if (CAMConfig.active && pathname !== "/media" && !getData()) {
    const params = new URLSearchParams({ state: pathname + search });
    return <Navigate to={`/media?${params}`} />;
  }

  return <Outlet />;
};
