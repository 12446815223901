import { CopyOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

type Props = {
  text: string;
  copyTooltipText?: string;
};

const AlexiaCopyToClipboard = ({
  text,
  copyTooltipText = "Copy to Clipboard",
}: Props) => {
  const [copied, setCopied] = useState(false);

  return (
    <CopyToClipboard
      text={text}
      onCopy={() => {
        setCopied(true);
      }}
    >
      <Tooltip
        title={copied ? "Copied!" : copyTooltipText}
        placement="right"
        color={copied ? "green" : "default"}
        onVisibleChange={(visible: boolean) => !visible && setCopied(false)}
      >
        <CopyOutlined />
      </Tooltip>
    </CopyToClipboard>
  );
};

export default AlexiaCopyToClipboard;
