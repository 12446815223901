import { GoogleAdCampaignInternal } from "shared/types/adLibrary";
import { dedupe, isTruthy } from "utils/helpers.array";

export const getAllPmaxCampaignIds = (campaigns: GoogleAdCampaignInternal[]) =>
  campaigns.map(campaign => campaign.id);

const getLoadStatus = (campaign: GoogleAdCampaignInternal) =>
  campaign.loadStatus;

const createFilterFn = (item: string): { value: string; text: string } => ({
  value: item,
  text: item,
});

export const getLoadStatusFilters = (campaigns: GoogleAdCampaignInternal[]) =>
  campaigns
    .map(getLoadStatus)
    .filter(dedupe)
    .filter(isTruthy)
    .map(createFilterFn);
