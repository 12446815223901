import _, { cloneDeep } from "lodash";
import uuid from "uuid";
import { IConfig, IConfigurationState } from "shared/types/configuration";
import {
  FetchingDataStatus,
  ICreateStateDisclosureResponse,
  ICreateStateExceptionResponse,
  IGetMessageResponse,
  ILegalLingoDataFetchResponse,
  ILegalLingoReduxState,
  IMessagingForStamp,
  IMessagingObjectType,
  IStateDisclosureRecord,
  IStateExceptionRecord,
  IUpdateLegalLingoOEMTablesResponse,
  IUpdateLegalLingoStateTablesResponse,
  Mode,
} from "shared/types/legalLingo";

import { delay } from "../../utils/helpers";

import GenericError from "shared/errors/GenericError";
import API from "../../services";

import { IStamp } from "shared/types/designStudio";
import {
  ILegalLingoOEMTablesResponse,
  ILegalLingoStateTablesResponse,
} from "../../shared/types/legalLingo";
import { queryClient } from "queryClient";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "redux/store";

const initialState: ILegalLingoReduxState = {
  mode: "LEGAL LINGO",
  error: null,
  stateDisclosures: [],
  stateExceptions: [],
  creatingDataStatus: "",
  fetchingData: null,
  stateUpdateSuccessful: false,
  oemUpdateSuccessful: false,
  isFirstLoad: true,
  isFirstMessagingLoad: true,
  messagesandExceptionsArray: [],

  messagesGlobalState: {},
  oemSelected: null,
  stateSelected: null,
  messagingForStamp: null,
  reloadUUID: "",
  stateExceptionRecordsInForm: [],

  createDisclosureSuccessful: false,
  updateDisclosureSuccessful: false,
};

const legalLingoSlice = createSlice({
  name: "legalLingo",
  initialState,
  reducers: {
    setMode(state, { payload: mode }: PayloadAction<Mode>) {
      return {
        ...state,
        mode,
      };
    },
    saveLocalStateMessagesAndExceptions(
      state,
      {
        payload,
      }: PayloadAction<{
        defaultPrimaryText: string;
        defaultSecondaryText: string;
        key: string;
      }>,
    ) {
      const { defaultPrimaryText, defaultSecondaryText, key } = payload;

      const realKey = key === "Zero Down Lease" ? "Zero-Down-Lease" : key;

      const prevKey = key === "" ? "Lease_null_null" : `${realKey}_null_null`;

      const tempMessagesGlobalState = cloneDeep(state.messagesGlobalState);

      if (tempMessagesGlobalState[prevKey]) {
        tempMessagesGlobalState[prevKey].primaryMessage = defaultPrimaryText;
        tempMessagesGlobalState[prevKey].secondaryMessage =
          defaultSecondaryText;
      }

      return {
        ...state,
        messagesGlobalState: tempMessagesGlobalState,

        primaryMessage: defaultPrimaryText,
        secondaryMessage: defaultSecondaryText,
      };
    },
    getMessagesandExceptionsFail(
      state,
      { payload: error }: PayloadAction<Error>,
    ) {
      return {
        ...state,
        error,
      };
    },
    getMessagesandExceptionsSuccess(
      state,
      {
        payload: { messagesandExceptionsArray },
      }: PayloadAction<{
        messagesandExceptionsArray: {
          key: string;
          oemName: string;
          offerType: string;
          primaryMessage: string;
          secondaryMessage: string;
        }[];
      }>,
    ) {
      let arrayLengthError;

      // TODO: this is not an error, it's just empty, should listen for an actual error
      // in order to throw an error, like 401 or 504

      // if (messagesandExceptionsArray.length <= 0) {
      //   arrayLengthError = { name: "", message: "Backend Error" };
      //   return {
      //     ...state,
      //     error: arrayLengthError,
      //   };
      // }

      const messagesGlobalState = (
        messagesandExceptionsArray as Array<IMessagingObjectType>
      ).reduce(
        // tslint:disable-next-line: no-shadowed-variable
        (result, current) => {
          const { oemName } = current;
          const messageObject = {
            primaryMessage: current.primaryMessage,
            secondaryMessage: current.secondaryMessage,
            oem: oemName || "null",
            state: current.state,
            key: current.key,
            offerType: current.offerType,
          };

          result[current.key] = messageObject;

          return result;
        },
        {} as { [key: string]: IMessagingObjectType },
      );

      // TODO: fix this default Lease_null_null
      return {
        ...state,
        messagesGlobalState,
        currentMessagingObject: messagesGlobalState.Lease_null_null ?? {},
        oemSelected: null,
        stateSelected: null,
      };
    },
    filterMessagesAndExceptionsFromTab(
      state,
      {
        payload,
      }: PayloadAction<{
        currTab: string | null;
        oem: string | undefined;
        state: string | undefined;
      }>,
    ) {
      let tempOem: string | null = null;
      let tempState: string | null = null;

      tempOem = payload.oem ? payload.oem : state.oemSelected;
      tempState = payload.state ? payload.state : state.stateSelected;

      let tab =
        payload.currTab === "Zero Down Lease"
          ? "Zero-Down-Lease"
          : payload.currTab;

      tab = tab === "lease" ? "Lease" : tab;

      const tempKey = `${tab}_${tempOem}_${tempState}`;

      const returnObj: IMessagingObjectType = state.messagesGlobalState[
        tempKey
      ] || {
        primaryMessage: "",
        secondaryMessage: "",
        oem: tempOem,
        state: tempState,
      };

      return {
        ...state,
        currentMessagingObject: returnObj,
        oemSelected: returnObj.oem === "null" ? null : returnObj.oem,
        stateSelected: returnObj.state === "null" ? null : returnObj.state,
      };
    },
    saveMessagesandExceptionsFail(
      state,
      { payload: error }: PayloadAction<GenericError>,
    ) {
      return {
        ...state,
        error,
      };
    },
    saveMessagesandExceptionsSuccess(
      state,
      {
        payload: result,
      }: PayloadAction<{
        body: Array<{
          key: string;
          oemName: string;
          offerType: string;
          primaryMessage: string;
          secondaryMessage: string;
          state: string;
        }>;
      }>,
    ) {
      const { body } = result;

      let arrayLengthSaveError;

      if (body.length <= 0) {
        arrayLengthSaveError = { name: "", message: "Backend Error" };
        return {
          ...state,
          error: arrayLengthSaveError,
        };
      }

      return {
        ...state,
        isFirstMessagingLoad: false,
        reloadUUID: uuid(),
      };
    },
    addOrDeleteOemToDynamoDbFail(
      state,
      { payload: error }: PayloadAction<Error>,
    ) {
      return {
        ...state,
        error,
      };
    },
    addOrDeleteOemToDynamoDbSuccess(state, { payload: result }) {
      return state;
    },
    getStateTablesLegalLingosFail(
      state,
      { payload: error }: PayloadAction<Error>,
    ) {
      return state;
    },
    getStateTablesLegalLingosSuccess(
      state,
      {
        payload: stateTablesResult,
      }: PayloadAction<ILegalLingoStateTablesResponse["result"]>,
    ) {
      const {
        stateVehicleInfoTable,
        stateNumberAtThisPriceTable,
        stateLeaseTable,
        stateZeroDownLeaseTable,
        stateFinanceTable,
        statePurchaseTable,
        stateAPRTable,
      } = stateTablesResult!;

      const stateVehicleInfoAlphabetizedResult = _.sortBy(
        stateVehicleInfoTable,
        "lingo_state",
      );

      const stateNumberThisPriceAlphabetizedResult = _.sortBy(
        stateNumberAtThisPriceTable,
        "lingo_state",
      );

      const stateLeaseAlphabetizedResult = _.sortBy(
        stateLeaseTable,
        "lingo_state",
      );
      const stateZeroDownAlphabetizedResult = _.sortBy(
        stateZeroDownLeaseTable,
        "lingo_state",
      );
      const stateFinanceAlphabetizedResult = _.sortBy(
        stateFinanceTable,
        "lingo_state",
      );
      const statePurchaseAlphabetizedResult = _.sortBy(
        statePurchaseTable,
        "lingo_state",
      );
      const stateAPRAlphabetizedResult = _.sortBy(stateAPRTable, "lingo_state");

      return {
        ...state,

        // David Ro TO DO: add these checkedState properties to LegalLingoReduxState interface
        checkedStateVehicleInfoList: stateVehicleInfoAlphabetizedResult,
        checkedStateNumberAtThisPriceList:
          stateNumberThisPriceAlphabetizedResult,
        checkedStateLeaseList: stateLeaseAlphabetizedResult,
        checkedStateZeroDownLeaseList: stateZeroDownAlphabetizedResult,
        checkedStateFinanceList: stateFinanceAlphabetizedResult,
        checkedStatePurchaseList: statePurchaseAlphabetizedResult,
        checkedStateAPRList: stateAPRAlphabetizedResult,
      };
    },
    updateStateTablesLegalLingosFail(
      state,
      { payload: error }: PayloadAction<Error>,
    ) {
      return {
        ...state,
        error,
        stateUpdateSuccessful: false,
      };
    },
    updateStateTablesLegalLingosSuccess(
      state,
      { payload }: PayloadAction<IUpdateLegalLingoStateTablesResponse>,
    ) {
      const { result: stateTablesUpdateResult } = payload;

      const {
        updateStateVehicleInfoLegalLingosResult,
        updateStateNumberAtThisPriceLingosResult,
        updateStateLeaseLegalLingosResult,
        updateStateZeroDownLeaseLegalLingosResult,
        updateStateFinanceLegalLingosResult,
        updateStatePurchaseLegalLingosResult,
        updateStateAPRLegalLingosResult,
      } = stateTablesUpdateResult!;

      const updateStateVehicleInfoAlphabetizedResult = _.sortBy(
        updateStateVehicleInfoLegalLingosResult,
        "lingo_state",
      );
      const updateStateNumberAtThisPriceAlphabetizedResult = _.sortBy(
        updateStateNumberAtThisPriceLingosResult,
        "lingo_state",
      );
      const updateStateLeaseAlphabetizedResult = _.sortBy(
        updateStateLeaseLegalLingosResult,
        "lingo_state",
      );
      const updateStateZeroDownLeaseAlphabetizedResult = _.sortBy(
        updateStateZeroDownLeaseLegalLingosResult,
        "lingo_state",
      );
      const updateStateFinanceAlphabetizedResult = _.sortBy(
        updateStateFinanceLegalLingosResult,
        "lingo_state",
      );
      const updateStatePurchaseAlphabetizedResult = _.sortBy(
        updateStatePurchaseLegalLingosResult,
        "lingo_state",
      );
      const updateStateAPRAlphabetizedResult = _.sortBy(
        updateStateAPRLegalLingosResult,
        "lingo_state",
      );
      return {
        ...state,

        // David Ro TO DO: add these checkedState properties to LegalLingoReduxState interface
        checkedStateVehicleInfoList: updateStateVehicleInfoAlphabetizedResult,
        checkedStateNumberAtThisPriceList:
          updateStateNumberAtThisPriceAlphabetizedResult,
        checkedStateLeaseList: updateStateLeaseAlphabetizedResult,
        checkedStateZeroDownLeaseList:
          updateStateZeroDownLeaseAlphabetizedResult,
        checkedStateFinanceList: updateStateFinanceAlphabetizedResult,
        checkedStatePurchaseList: updateStatePurchaseAlphabetizedResult,
        checkedStateAPRList: updateStateAPRAlphabetizedResult,
        stateUpdateSuccessful: true,
        isFirstLoad: false,
        reloadUUID: uuid(),
      };
    },
    getOemTablesLegalLingosFail(
      state,
      { payload: error }: PayloadAction<Error>,
    ) {
      return state;
    },
    getOemTablesLegalLingosSuccess(
      state,
      {
        payload: oemTablesResult,
      }: PayloadAction<ILegalLingoOEMTablesResponse["result"]>,
    ) {
      const {
        oemVehicleInfoTable,
        oemNumberAtThisPriceTable,
        oemLeaseTable,
        oemZeroDownLeaseTable,
        oemFinanceTable,
        oemPurchaseTable,
        oemAPRTable,
      } = oemTablesResult!;

      const oemVehicleInfoAlphabetizedResult = _.orderBy(
        oemVehicleInfoTable,
        "lingo_oem",
      );

      const oemNumberThisPriceAlphabetizedResult = _.orderBy(
        oemNumberAtThisPriceTable,
        "lingo_state",
      );

      const oemLeaseAlphabetizedResult = _.orderBy(oemLeaseTable, "lingo_oem");
      const oemZeroDownLeaseAlphabetizedResult = _.orderBy(
        oemZeroDownLeaseTable,
        "lingo_oem",
      );
      const oemFinanceAlphabetizedResult = _.orderBy(
        oemFinanceTable,
        "lingo_oem",
      );
      const oemPurchaseAlphabetizedResult = _.orderBy(
        oemPurchaseTable,
        "lingo_oem",
      );
      const oemAPRAlphabetizedResult = _.orderBy(oemAPRTable, "lingo_oem");
      return {
        ...state,

        // David Ro TO DO: add these checkedOem properties to LegalLingoReduxState interface
        checkedOemVehicleInfoList: oemVehicleInfoAlphabetizedResult,
        checkedOemNumberAtThisPriceList: oemNumberThisPriceAlphabetizedResult,
        checkedOemLeaseList: oemLeaseAlphabetizedResult,
        checkedOemZeroDownLeaseList: oemZeroDownLeaseAlphabetizedResult,
        checkedOemFinanceList: oemFinanceAlphabetizedResult,
        checkedOemPurchaseList: oemPurchaseAlphabetizedResult,
        checkedOemAPRList: oemAPRAlphabetizedResult,
        uniqueUUID: uuid(),
        oemUpdateSuccessful: true,
        isFirstLoad: true,
      };
    },
    updateOemTablesLegalLingosFail(
      state,
      { payload: error }: PayloadAction<Error>,
    ) {
      return {
        ...state,
        error,
      };
    },
    updateOemTablesLegalLingosSuccess(
      state,
      { payload }: PayloadAction<IUpdateLegalLingoOEMTablesResponse>,
    ) {
      const { result: oemTablesUpdateResult } = payload;

      const {
        updateOemVehicleInfoLegalLingosResult,
        updateOemNumberAtThisPriceLingosResult,
        updateOemLeaseLegalLingosResult,
        updateOemZeroDownLeaseLegalLingosResult,
        updateOemFinanceLegalLingosResult,
        updateOemPurchaseLegalLingosResult,
        updateOemAPRLegalLingosResult,
      } = oemTablesUpdateResult!;

      const updateOemVehicleInfoAlphabetizedResult = _.orderBy(
        updateOemVehicleInfoLegalLingosResult,
        "lingo_oem",
      );
      const updateOemNumberAtThisPriceAlphabetizedResult = _.orderBy(
        updateOemNumberAtThisPriceLingosResult,
        "lingo_oem",
      );
      const updateOemLeaseAlphabetizedResult = _.orderBy(
        updateOemLeaseLegalLingosResult,
        "lingo_oem",
      );
      const updateOemZeroDownLeaseAlphabetizedResult = _.orderBy(
        updateOemZeroDownLeaseLegalLingosResult,
        "lingo_oem",
      );
      const updateOemFinanceAlphabetizedResult = _.orderBy(
        updateOemFinanceLegalLingosResult,
        "lingo_oem",
      );
      const updateOemPurchaseAlphabetizedResult = _.orderBy(
        updateOemPurchaseLegalLingosResult,
        "lingo_oem",
      );
      const updateOemAPRAlphabetizedResult = _.orderBy(
        updateOemAPRLegalLingosResult,
        "lingo_oem",
      );

      return {
        ...state,

        // David Ro TO DO: add these checkedOem properties to LegalLingoReduxState interface
        checkedOemVehicleInfoList: updateOemVehicleInfoAlphabetizedResult,
        checkedOemNumberAtThisPriceList:
          updateOemNumberAtThisPriceAlphabetizedResult,
        checkedOemLeaseList: updateOemLeaseAlphabetizedResult,
        checkedOemZeroDownLeaseList: updateOemZeroDownLeaseAlphabetizedResult,
        checkedOemFinanceList: updateOemFinanceAlphabetizedResult,
        checkedOemPurchaseList: updateOemPurchaseAlphabetizedResult,
        checkedOemAPRList: updateOemAPRAlphabetizedResult,
        oemUpdateSuccessful: true,
        isFirstLoad: false,
      };
    },
    createStateDisclosureBegin(state) {
      return {
        ...state,
        error: null,
        creatingDataStatus: "disclosure",
      };
    },
    createStateDisclosureFail(
      state,
      { payload: stateDisclosure }: PayloadAction<IStateDisclosureRecord>,
    ) {
      const error = {
        name: "",
        message: `The disclosures for ${stateDisclosure.state} could not be inserted.`,
      };

      return {
        ...state,
        creatingDataStatus: "",
        error,
      };
    },
    createStateDisclosureSuccess(
      state,
      { payload: stateDisclosure }: PayloadAction<IStateDisclosureRecord>,
    ) {
      return {
        ...state,
        stateDisclosures: [...state.stateDisclosures, stateDisclosure],
        creatingDataStatus: "complete_disclosure",
      };
    },
    createStateExceptionBegin(state) {
      return {
        ...state,
        error: null,
        creatingDataStatus: "exception",
      };
    },
    createStateExceptionFail(
      state,
      { payload: stateException }: PayloadAction<IStateExceptionRecord>,
    ) {
      const error = {
        name: "",
        message: `The exceptions for ${stateException.state} could not be inserted.`,
      };

      return {
        ...state,
        creatingDataStatus: "",
        error,
      };
    },
    createStateExceptionSuccess(
      state,
      { payload: stateException }: PayloadAction<IStateExceptionRecord>,
    ) {
      return {
        ...state,
        stateExceptions: [...state.stateExceptions, stateException],
        creatingDataStatus: "complete_exception",
      };
    },
    resetCreateDataSuccess(state) {
      return {
        ...state,
        creatingDataStatus: "",
        error: null,
      };
    },
    fetchDataBegin(
      state,
      { payload: dataType }: PayloadAction<FetchingDataStatus>,
    ) {
      return {
        ...state,
        error: null,
        fetchingData: dataType,
      };
    },
    fetchDataSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        response: {
          data: IStateDisclosureRecord[] | IStateExceptionRecord[];
          error: GenericError | null;
        };
        dataType: FetchingDataStatus;
      }>,
    ) {
      const {
        dataType,
        response: { data },
      } = payload;
      return {
        ...state,
        stateDisclosures:
          (dataType as FetchingDataStatus) === "stateDisclosures"
            ? (data as IStateDisclosureRecord[])
            : [...state.stateDisclosures],
        stateExceptions:
          (dataType as FetchingDataStatus) === "stateExceptions"
            ? (data as IStateExceptionRecord[])
            : [...state.stateExceptions],
        fetchingData: null,
        creatingDataStatus: "",
      };
    },
    fetchDataFail(state) {
      return {
        ...state,
        fetchingData: null,
        error: {
          name: "",
          message: "Fetching data failed.",
        },
      };
    },
    setStateExceptionsInRedux(
      state,
      { payload: stateExceptions }: PayloadAction<IStateExceptionRecord[]>,
    ) {
      return {
        ...state,
        stateExceptionRecordsInForm: stateExceptions,
      };
    },
  },
});

export const getMessagesandExceptions =
  (
    currTab: string | null,
    onLoad?: boolean,
    oem?: string,
    state?: string,
  ): AppThunk =>
  async (dispatch, getState) => {
    await delay(500);
    try {
      if (!onLoad) {
        const tab = currTab === "" ? "lease" : currTab;
        dispatch(
          filterMessagesAndExceptionsFromTab({ currTab: tab, oem, state }),
        );
      } else {
        const response =
          await API.privServices.legalLingo.getMessagesandExceptions<any>();

        const { error } = response;

        if (error) {
          const { message } = error;
          dispatch(
            getMessagesandExceptionsFail(
              new GenericError({
                message: message || "Some error ocurred.",
              }),
            ),
          );
          return;
        }

        dispatch(getMessagesandExceptionsSuccess(response));
      }
    } catch (error) {
      dispatch(
        getMessagesandExceptionsFail(
          new GenericError({
            message: (error as Error).message || "Some error ocurred.",
            errorObject: error,
          }),
        ),
      );
    }
  };

export const saveMessagesandExceptions =
  (
    messages: any,
    offer: string,
    oem: string,
    state: string,
    isDefault?: boolean,
  ): AppThunk =>
  async (dispatch, getState) => {
    await delay(500);

    try {
      if (!isDefault) {
        if (oem === "null" && state === "null") {
          return;
        }
      }

      let adjustedOffer =
        offer === "Zero Down Lease" ? "Zero-Down-Lease" : offer;

      adjustedOffer = offer === "lease" ? "Lease" : adjustedOffer;

      const response =
        await API.privServices.legalLingo.saveMessagesandExceptions<{
          body: Array<{
            key: string;
            oemName: string;
            offerType: string;
            primaryMessage: string;
            secondaryMessage: string;
            state: string;
          }>;
        }>(messages, adjustedOffer, oem, state);

      const { error } = response as any;

      if (error) {
        const { message } = error;
        dispatch(
          saveMessagesandExceptionsFail(
            new GenericError({
              message: message || "Some error ocurred.",
            }),
          ),
        );
        return;
      }

      dispatch(saveMessagesandExceptionsSuccess(response));
    } catch (error) {
      dispatch(
        saveMessagesandExceptionsFail(
          new GenericError({
            message: (error as Error).message || "Some error ocurred.",
            errorObject: error,
          }),
        ),
      );
    }
  };

export const addOrDeleteOemToDynamoDb =
  (oem: any, action: string): AppThunk =>
  async (dispatch, getState) => {
    await delay(500);
    try {
      const response =
        await API.privServices.legalLingo.addOrDeleteOemToDynamoDb<any>(
          oem,
          action,
        );

      const { error } = response;

      // invalidate react query cache
      queryClient.invalidateQueries("oems");

      if (error) {
        const { message } = error;
        dispatch(
          addOrDeleteOemToDynamoDbFail(
            new GenericError({
              message: message || "Some error ocurred.",
            }),
          ),
        );
        return;
      }
      dispatch(addOrDeleteOemToDynamoDbSuccess(response));
    } catch (error) {
      dispatch(
        addOrDeleteOemToDynamoDbFail(
          new GenericError({
            message: (error as Error).message || "Some error ocurred.",
            errorObject: error,
          }),
        ),
      );
    }
  };

export const getStateTablesLegalLingos =
  (): AppThunk => async (dispatch, getState) => {
    await delay(500);
    try {
      const response =
        await API.privServices.legalLingo.getStateTablesLegalLingos<
          ILegalLingoStateTablesResponse["result"]
        >();

      dispatch(getStateTablesLegalLingosSuccess(response));
    } catch (error) {
      dispatch(
        getStateTablesLegalLingosFail(
          new GenericError({
            message: (error as Error).message || "Some error ocurred.",
            errorObject: error,
          }),
        ),
      );
    }
  };

export const updateStateTablesLegalLingos =
  (listToUpdate: any): AppThunk =>
  async (dispatch, getState) => {
    await delay(500);
    try {
      const response =
        await API.privServices.legalLingo.updateStateTablesLegalLingos<IUpdateLegalLingoStateTablesResponse>(
          listToUpdate,
        );
      const { error } = response;

      if (error) {
        const { message } = error;
        dispatch(
          updateStateTablesLegalLingosFail(
            new GenericError({
              message: message || "Some error ocurred.",
            }),
          ),
        );
        return;
      }
      dispatch(updateStateTablesLegalLingosSuccess(response));
    } catch (error) {
      dispatch(
        updateStateTablesLegalLingosFail(
          new GenericError({
            message: (error as Error).message || "Some error ocurred.",
            errorObject: error,
          }),
        ),
      );
    }
  };

export const getOemTablesLegalLingos =
  (): AppThunk => async (dispatch, getState) => {
    await delay(500);
    try {
      const response =
        await API.privServices.legalLingo.getOemTablesLegalLingos<
          ILegalLingoOEMTablesResponse["result"]
        >();

      dispatch(getOemTablesLegalLingosSuccess(response));
    } catch (error) {
      dispatch(
        getOemTablesLegalLingosFail(
          new GenericError({
            message: (error as Error).message || "Some error ocurred.",
            errorObject: error,
          }),
        ),
      );
    }
  };

export const updateOemTablesLegalLingos =
  (listToUpdate: any): AppThunk =>
  async (dispatch, getState) => {
    await delay(500);
    try {
      const response =
        await API.privServices.legalLingo.updateOemTablesLegalLingos<IUpdateLegalLingoOEMTablesResponse>(
          listToUpdate,
        );

      const { error } = response;

      if (error) {
        const { message } = error;
        dispatch(
          updateOemTablesLegalLingosFail(
            new GenericError({
              message: message || "Some error ocurred.",
            }),
          ),
        );
        return;
      }

      dispatch(updateOemTablesLegalLingosSuccess(response));
    } catch (error) {
      dispatch(
        updateOemTablesLegalLingosFail(
          new GenericError({
            message: (error as Error).message || "Some error ocurred.",
            errorObject: error,
          }),
        ),
      );
    }
  };

export const createStateDisclosure =
  (stateDisclosure: IStateDisclosureRecord): AppThunk =>
  async (dispatch, getState) => {
    dispatch(createStateDisclosureBegin());

    const { configuration } = getState();
    const { config } = configuration;

    const request: RequestInfo = new Request(
      (config as IConfig).services.legal.createStateDisclosureUrl,
      {
        method: "POST",
        body: JSON.stringify({
          stateDisclosure,
        }),
      },
    );

    const { result, error } = await API.send<ICreateStateDisclosureResponse>(
      request,
    );

    if (error) {
      dispatch(createStateDisclosureFail(stateDisclosure));
    }

    const { stateDisclosure: createdStateDisclosure } = result;

    dispatch(createStateDisclosureSuccess(createdStateDisclosure));
  };

export const createStateException =
  (stateException: IStateExceptionRecord): AppThunk =>
  async (dispatch, getState) => {
    dispatch(createStateExceptionBegin());

    const { configuration } = getState();
    const { config } = configuration;

    const request: RequestInfo = new Request(
      (config as IConfig).services.legal.createStateExceptionUrl,
      {
        method: "POST",
        body: JSON.stringify({
          stateException,
        }),
      },
    );

    const { result, error } = await API.send<ICreateStateExceptionResponse>(
      request,
    );

    if (error) {
      dispatch(createStateExceptionFail(stateException));
    }

    const { stateException: createdStateException } = result;

    dispatch(createStateExceptionSuccess(createdStateException));
  };

export const resetCreateData = (): AppThunk => dispatch => {
  dispatch(resetCreateDataSuccess());
};

export const fetchData =
  (dataType: FetchingDataStatus, query?: string): AppThunk =>
  async (dispatch, getState) => {
    if (!dataType) {
      return;
    }

    dispatch(fetchDataBegin(dataType));

    const { configuration } = getState();
    const { config } = configuration;

    let url = "";
    let objectKey: "stateDisclosures" | "stateExceptions";

    switch (dataType) {
      case "stateDisclosures":
        url = `${(config as IConfig).services.legal.getStateDisclosuresUrl}?${
          query || ""
        }`;
        objectKey = "stateDisclosures";

        break;

      case "stateExceptions":
        url = `${(config as IConfig).services.legal.getStateExceptionsUrl}?${
          query || ""
        }`;
        objectKey = "stateExceptions";

        break;

      default:
        throw new GenericError({
          message: "The dataType to Legal Lingo actions is not valid.",
        });
    }

    const request: RequestInfo = new Request(url, {
      method: "GET",
    });

    const { result, error } = await API.send<ILegalLingoDataFetchResponse>(
      request,
    );

    if (error) {
      dispatch(fetchDataFail());

      return;
    }

    const { [objectKey]: data = [] } = result || {};

    dispatch(
      fetchDataSuccess({
        response: {
          data: data || [],
          error: null,
        },
        dataType,
      }),
    );
  };

export const {
  addOrDeleteOemToDynamoDbFail,
  addOrDeleteOemToDynamoDbSuccess,
  createStateDisclosureBegin,
  createStateDisclosureFail,
  createStateDisclosureSuccess,
  createStateExceptionBegin,
  createStateExceptionFail,
  createStateExceptionSuccess,
  fetchDataBegin,
  fetchDataFail,
  fetchDataSuccess,
  filterMessagesAndExceptionsFromTab,
  getMessagesandExceptionsFail,
  getMessagesandExceptionsSuccess,
  getOemTablesLegalLingosFail,
  getOemTablesLegalLingosSuccess,
  getStateTablesLegalLingosFail,
  getStateTablesLegalLingosSuccess,
  resetCreateDataSuccess,
  saveLocalStateMessagesAndExceptions,
  saveMessagesandExceptionsFail,
  saveMessagesandExceptionsSuccess,
  setMode,
  setStateExceptionsInRedux,
  updateOemTablesLegalLingosFail,
  updateOemTablesLegalLingosSuccess,
  updateStateTablesLegalLingosFail,
  updateStateTablesLegalLingosSuccess,
} = legalLingoSlice.actions;

export default legalLingoSlice.reducer;
