export const BRANDS_QUERY_KEY = "brands";

export const imageAssetDetail = {
  Square: {
    ratio: 1,
    info: "File with a square size ratio of 1:1",
  },
  Horizontal: {
    ratio: 2,
    info: "File with a horizontal size ratio of 2:1",
  },
  Vertical: {
    ratio: 0.5,
    info: "File with a vertical size ratio of 1:2",
  },
};
