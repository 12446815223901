import { DatePicker, Select, Space } from "antd";
import moment, { Moment } from "moment";
import React, { useState } from "react";
import RestrictedRangePicker from "./RestrictedRangePicker";
import { SelectedDates } from "shared/types/campaignManagement";

type SwitchableDatePickerProps = {
  setSelectedDateRange: (value: [number, number]) => void;
  dateRange: Moment[] | SelectedDates;
  disabled?: boolean;
  validDateRange?: boolean;
};

type PickerType = "range" | "week" | "month" | "quarter";

const { Option } = Select;

const SwitchableDatePicker: React.FC<SwitchableDatePickerProps> = ({
  setSelectedDateRange,
  dateRange,
  disabled,
  validDateRange,
}) => {
  const [type, setType] = useState<PickerType>("range");
  const dateFormat = moment.localeData().longDateFormat("L");

  const handleChangeRangeType = (value: PickerType) => {
    setType(value);

    if (value !== "range") {
      setSelectedDateRange([
        moment(dateRange[0]).startOf(value).valueOf() || 0,
        moment(dateRange[0]).endOf(value).valueOf() || 0,
      ]);
    }
  };

  const handleDateChange = (value: Moment | null) => {
    if (type === "range" || !value) return;

    setSelectedDateRange([
      value.startOf(type).valueOf(),
      value.endOf(type).valueOf(),
    ]);
  };

  return (
    <Space align="center">
      <Select
        disabled={disabled}
        value={type}
        onChange={handleChangeRangeType}
        style={{ minWidth: "100px" }}
        data-cy="date-picker-type"
      >
        <Option value="range">Date</Option>
        <Option value="week">Week</Option>
        <Option value="month">Month</Option>
        <Option value="quarter">Quarter</Option>
      </Select>
      {type !== "range" && (
        <DatePicker
          picker={type}
          disabled={disabled}
          value={validDateRange ? moment(dateRange[0]) : undefined}
          onChange={handleDateChange}
          data-cy="date-picker"
        />
      )}
      {type === "range" && (
        <RestrictedRangePicker
          restriction={{
            unitOfTime: "months",
            units: 2,
          }}
          allowClear={false}
          format={dateFormat}
          value={
            validDateRange
              ? [moment(dateRange[0]), moment(dateRange[1])]
              : undefined
          }
          onChange={(_values, formatString) => {
            setSelectedDateRange([
              moment(formatString[0]).startOf("day").valueOf() || 0,
              moment(formatString[1]).endOf("day").valueOf() || 0,
            ]);
          }}
          placeholder={["From", "To"]}
          disabled={disabled}
          data-cy="date-picker-range"
        />
      )}
    </Space>
  );
};

export default SwitchableDatePicker;
