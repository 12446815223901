import { useQPLayout } from "../useQPLayout";
import { Grid } from "./Grid";
import { Pagination } from "./Pagination";
import { TableSearch } from "./TableSearch";
import { useTableSearch } from "./useTableSearch";

export const SearchMode = () => {
  const [layout] = useQPLayout();
  const { data, isFetching } = useTableSearch();

  return layout === "grid" ? (
    <>
      <Grid data={data?.items} isFetching={isFetching} />
      <Pagination data={data} />
    </>
  ) : (
    <TableSearch data={data} isFetching={isFetching} />
  );
};
