import { Button, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { FilterDropdownProps } from "antd/lib/table/interface";

export const FilterIcon = ({ filtered }: { filtered: boolean }) => {
  return <SearchOutlined color={filtered ? "#1890ff" : undefined} />;
};

export const SearchFilterDropdown = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}: FilterDropdownProps) => {
  return (
    <div style={{ padding: 8 }} data-cy="search-filter-dropdown">
      <Input
        placeholder={`Search`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        style={{ marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          onClick={() => {
            clearFilters && clearFilters();
            setSelectedKeys([]);
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
        <Button
          type="primary"
          onClick={() =>
            confirm({
              closeDropdown: false,
            })
          }
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Filter
        </Button>
      </Space>
    </div>
  );
};
