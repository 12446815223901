import { Spin } from "antd";
import "antd/dist/antd.css";
import { Root } from "Root";
import AlexiaSpin from "shared/components/Spin";
import "symbol-observable";
import { alexiaAppId, facebookVersion } from "utils/constants";
import { initFacebook } from "utils/facebook/facebookSDK";
import "whatwg-fetch";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { initDatadog } from "shared/datadog";
import { createRoot } from "react-dom/client";
initDatadog();
initFacebook({
  appId: alexiaAppId,
  version: facebookVersion,
});

Spin.setDefaultIndicator(<AlexiaSpin />);

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
