import API from "services";
import { videoCompositionEnabled } from "shared/constants/assetExporter";
import { useAssetBatchesContext } from "../contexts/AssetBatchesContext";
import { useAssetBatchesHtmlRenderContext } from "../contexts/AssetBatchesHtmlRenderContext";
import styles from "./HtmlPreview.module.scss";
import { HtmlMaskItems } from "./Preview/HtmlMaskItems";
import { NavTemplateItems } from "./Preview/NavTemplateItems";

export const HtmlPreview = () => {
  const { currentStep } = useAssetBatchesContext();
  const { iframeRef, template } = useAssetBatchesHtmlRenderContext();
  const iframeUrl = template?.id
    ? API.services.assetExporter.getHtmlTemplatePreviewUrl(template.id)
    : undefined;

  return (
    <div className={styles.previewContainer}>
      {!videoCompositionEnabled && (
        <div className={styles.templateNav}>
          <NavTemplateItems />
        </div>
      )}
      <HtmlMaskItems step={currentStep} />
      <div className={styles.preview}>
        <div className={styles.iframeContainer}>
          {iframeUrl && (
            <iframe
              title="HTML Template Preview"
              src={iframeUrl}
              ref={iframeRef}
              width="100%"
              height="100%"
              className={styles.htmlIframe}
              name="html-preview"
            />
          )}
        </div>
      </div>
    </div>
  );
};
