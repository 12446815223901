import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  FacebookAdPart,
  IAdLoadParametersRuleset,
  AdLoadParametersRulesetObject,
} from "shared/types/adLibrary";
import uuid from "uuid";
import { setAdLoadParametersForAd } from "redux/adLibrary/adLibrary.slice";
import {
  IAdRule,
  IPanelRule,
} from "screens/adLibrary/adLoad/adLoadDrawer/shared/types";
import {
  getGroupedPanelRulesByPart,
  getPanelRulesByPart,
  transformRuleSetsInAdRules,
} from "screens/adLibrary/adLoad/adLoadDrawer/selectStep/updateAds/helpers.rules";
import produce from "immer";
import { useAppSelector } from "shared/hooks/useAppSelector";
import {
  selectAdLoadParameters,
  selectDestination,
} from "redux/adLibrary/adLibrary.selectors";

interface IUseAdRulesets {
  adPart: FacebookAdPart;
}

const useAdRulesets = ({ adPart }: IUseAdRulesets) => {
  const dispatch = useDispatch();
  const adLoadParameters = useAppSelector(selectAdLoadParameters);
  const destination = useAppSelector(selectDestination);

  const checkedAdIds = useMemo(
    () => destination?.selectedAds?.map(({ id }) => id) ?? [],
    [destination?.selectedAds],
  );

  const [newRuleIds, setNewRuleIds] = useState<string[]>([]);

  const rules = useMemo((): IPanelRule[] => {
    const isMultipleAds = checkedAdIds.length > 1;

    const adsRules = transformRuleSetsInAdRules(adLoadParameters?.rulesets);
    const checkedAdsRules = adsRules.filter((adRule: IAdRule) =>
      checkedAdIds.includes(adRule.adId),
    );

    if (isMultipleAds) {
      return getGroupedPanelRulesByPart(checkedAdsRules, adPart, newRuleIds);
    }

    return getPanelRulesByPart(checkedAdsRules, adPart);
  }, [adLoadParameters?.rulesets, adPart, checkedAdIds, newRuleIds]);

  const updateRulesetsByAds = (
    updatedRuleSets: AdLoadParametersRulesetObject | null | undefined,
  ) => {
    Object.entries(updatedRuleSets ?? {}).forEach(([id, ruleSets]) => {
      if (!checkedAdIds.includes(id)) return;

      dispatch(
        setAdLoadParametersForAd({
          adShellId: id,
          prop: "rulesets",
          data: ruleSets,
        }),
      );
    });
  };

  const addRule = () => {
    const newRuleIdsToAdd = checkedAdIds.map(_ => uuid());

    const updatedRulesets = checkedAdIds.reduce(
      (newRuleset, id, index) =>
        produce(newRuleset, draftRuleset => {
          draftRuleset?.[id]?.[adPart].push({
            id: newRuleIdsToAdd[index],
            parameter: "",
            qualifier: "",
            values: [],
          });
        }),
      adLoadParameters?.rulesets,
    );

    setNewRuleIds(prevNewRuleIds => [...prevNewRuleIds, ...newRuleIdsToAdd]);
    updateRulesetsByAds(updatedRulesets);
  };

  const clearRules = () => {
    const updatedRulesets = checkedAdIds.reduce(
      (newRuleset, id) =>
        produce(newRuleset, draftRuleset => {
          if (draftRuleset?.[id]?.[adPart]) {
            draftRuleset[id]![adPart] = [];
          }
        }),
      adLoadParameters?.rulesets,
    );

    updateRulesetsByAds(updatedRulesets);
  };

  const updateRule = (ids: string[], modifiedRule: Partial<IPanelRule>) => {
    const updatedRulesets = checkedAdIds.reduce((newRuleset, id) => {
      const rulesets = adLoadParameters?.rulesets?.[id];
      if (!rulesets || !newRuleset) return newRuleset;

      const updatedAdRulesets: IAdLoadParametersRuleset = {
        ...rulesets,
        [adPart]: rulesets[adPart].map(rule =>
          ids.includes(rule.id as string)
            ? {
                ...rule,
                ...modifiedRule,
              }
            : rule,
        ),
      };

      return { ...newRuleset, [id]: updatedAdRulesets };
    }, adLoadParameters?.rulesets);

    updateRulesetsByAds(updatedRulesets);
  };

  const deleteRule = (ids: string[]) => {
    const updatedRulesets = checkedAdIds.reduce((newRuleset, id) => {
      const rulesets = adLoadParameters?.rulesets?.[id];
      if (!rulesets || !newRuleset) return newRuleset;

      const updatedRuleSets: IAdLoadParametersRuleset = {
        ...rulesets,
        [adPart]: rulesets[adPart].filter(
          rule => !ids.includes(rule.id as string),
        ),
      };

      return {
        ...newRuleset,
        [id]: updatedRuleSets,
      };
    }, adLoadParameters?.rulesets);

    updateRulesetsByAds(updatedRulesets);
  };

  return {
    rules,
    addRule,
    clearRules,
    updateRule,
    deleteRule,
  };
};

export default useAdRulesets;
