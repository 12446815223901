import { isTemplateExpired } from "screens/designStudio/helpers";
import { StateKey } from "shared/constants/states";
import { useAuthedAgentLicensedStates } from "shared/hooks/salesEnablement/useAgentLicensedStates";
import { useIsSalesEnablementAdmin } from "shared/hooks/useIsAdmin";
import { Template, TemplateStatus } from "shared/types/salesEnablement";

/**
 * Those statuses are computed based on the template status and expiration date.
 */
export type MarketingMaterialErrorStatus = "Expired" | "Unavailable";

type TemplateFields = Pick<Template, "status" | "expirationDate" | "locations">;

type GetStatusProps = {
  template: TemplateFields;
  licensedStates: StateKey[];
  isSalesEnablementAdmin: boolean;
};

export type TemplateErrorStatus = {
  status: MarketingMaterialErrorStatus;
  message: string;
};

export const useGetTemplateErrorStatusFn = () => {
  const { licensedStates = [] } = useAuthedAgentLicensedStates();
  const isSalesEnablementAdmin = useIsSalesEnablementAdmin();
  return (template: TemplateFields) =>
    getTemplateErrorStatus({
      template,
      licensedStates,
      isSalesEnablementAdmin,
    });
};

function getTemplateErrorStatus({
  template: { status, expirationDate, locations },
  licensedStates,
  isSalesEnablementAdmin,
}: GetStatusProps): TemplateErrorStatus | undefined {
  const templateExpired = isTemplateExpired(expirationDate);

  if (templateExpired) {
    return errorStatusMap.expired;
  }
  const isAvailableForAgentLocations =
    locations?.some(location => licensedStates?.includes(location)) ||
    isSalesEnablementAdmin;

  if (status !== TemplateStatus.PUBLISHED || !isAvailableForAgentLocations) {
    return errorStatusMap.unavailable;
  }
}

const errorStatusMap = {
  expired: {
    status: "Expired",
    message:
      "Compliance approval for the source template has expired. Please reach out to admin if this material is needed.",
  },
  unavailable: {
    status: "Unavailable",
    message:
      "The source template has been unpublished. Please reach out to admin if this material is needed.",
  },
} as const;
