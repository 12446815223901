import { useState } from "react";

import {
  useCreateCampaignPlannerInstance,
  useDeleteCampaignPlannerInstances,
  useEditCampaignPlannerInstance,
  useEditCampaignPlannerInstanceAd,
} from "shared/hooks/campaignPlanner/useMutateCampaignPlannerInstance";

import { differenceBy, pickBy } from "lodash";
import { UpdatePlannerInstanceAdParams } from "shared/types/campaignPlanner";
import { CampaignPlanner, CampaignPlannerInstance } from "../types";
import { useUser } from "shared/hooks/useUser";
import { createPlannerInstanceObject, isNewItem } from "../utils";
import uuid from "uuid";
import { raise } from "utils/errorMessage";

type Props = {
  getPlanner: (id: string) => CampaignPlanner;
};

type NewInstance = {
  instance: Partial<CampaignPlannerInstance>;
  plannerId: string;
  isCopy: boolean;
};

export type PlannerInstanceActions = {
  createPlannerInstance: (
    plannerId: string,
    instance?: Partial<CampaignPlannerInstance>,
  ) => void;
  onInstanceEditEnd: (
    instance: CampaignPlannerInstance,
    plannerId: string,
  ) => void;
  setSelectedInstances: React.Dispatch<
    React.SetStateAction<{
      [instanceId: string]: string[];
    }>
  >;
  deleteSingleInstance: (plannerId: string, instanceId: string) => void;
  deleteInstances: (instances: { [instanceId: string]: string[] }) => void;
  updateAdInstanceStatus: (params: UpdatePlannerInstanceAdParams) => void;
  updateInstanceAds: (
    instance: CampaignPlannerInstance,
    plannerId: string,
    onSuccess?: () => void,
  ) => void;
  copyInstance: (plannerId: string, instanceId: string) => void;
};

export type PlannerInstanceProps = {
  newInstance: NewInstance | undefined;
  selectedInstances: {
    [plannerId: string]: string[];
  };
};

export const usePlannerInstance = ({
  getPlanner,
}: Props): PlannerInstanceActions & PlannerInstanceProps => {
  const user = useUser();

  const [newInstance, setNewInstance] = useState<NewInstance | undefined>();

  const [selectedInstances, setSelectedInstances] = useState<{
    [instanceId: string]: string[];
  }>({});

  const { mutate: createPlannerInstanceMutation } =
    useCreateCampaignPlannerInstance();
  const { mutate: editInstanceMutation } = useEditCampaignPlannerInstance();
  const { mutate: deleteInstancesMutation } =
    useDeleteCampaignPlannerInstances();
  const { mutate: updateInstanceAd } = useEditCampaignPlannerInstanceAd();

  const deleteInstances = (instances: Record<string, string[]>) => {
    deleteInstancesMutation(instances, {
      onSettled: (_, error) => {
        if (error) return;
        setSelectedInstances({});
      },
    });
  };

  const createPlannerInstanceTemp = (
    plannerId: string,
    instance?: Partial<CampaignPlannerInstance>,
  ) => {
    setNewInstance({
      instance: createPlannerInstanceObject(instance),
      plannerId,
      isCopy: !!instance,
    });
  };

  const createPlannerInstance = (
    instance: CampaignPlannerInstance,
    plannerId: string,
  ) => {
    const newInstance: CampaignPlannerInstance = createPlannerInstanceObject({
      id: uuid.v4(),
      name: instance.name,
      createdBy: user?.email || "",
      updatedBy: user?.email || "",
    });
    createPlannerInstanceMutation({
      plannerId,
      instance: newInstance,
    });
  };

  const copyPlannerInstance = (
    instance: CampaignPlannerInstance,
    plannerId: string,
  ) => {
    const adsWithDoNotLoad = newInstance?.instance.adStatuses
      ? pickBy(
          newInstance.instance.adStatuses,
          value => value === "DO_NOT_LOAD",
        )
      : {};
    const copiedInstance = createPlannerInstanceObject({
      ...newInstance?.instance,
      id: uuid.v4(),
      name: instance.name,
      createdBy: user?.email || "",
      updatedBy: user?.email || "",
      adStatuses: adsWithDoNotLoad,
    });

    createPlannerInstanceMutation({
      plannerId,
      instance: copiedInstance,
    });
  };

  const editPlannerInstance = (
    instance: CampaignPlannerInstance,
    plannerId: string,
  ) => {
    const editedInstance = {
      ...instance,
      updatedAt: new Date().toISOString(),
      updatedBy: user?.email || "",
      name: instance.name || "",
    };

    editInstanceMutation({
      plannerId,
      instance: editedInstance,
    });
  };

  const onInstanceEditEnd = (
    instance: CampaignPlannerInstance,
    plannerId: string,
  ) => {
    if (!instance.name || !user) {
      setNewInstance(undefined);
      return;
    }

    if (newInstance?.isCopy) {
      copyPlannerInstance(instance, plannerId);
    } else if (isNewItem(instance)) {
      createPlannerInstance(instance, plannerId);
    } else {
      editPlannerInstance(instance, plannerId);
    }

    setNewInstance(undefined);
  };

  const updateInstanceAds = (
    instance: CampaignPlannerInstance,
    plannerId: string,
    onSuccess?: () => void,
  ) => {
    const updatedInstance: CampaignPlannerInstance = {
      ...instance,
      adShells: differenceBy(
        instance.adShells,
        getPlanner(plannerId).adShells,
        "id",
      ),
    };

    editInstanceMutation(
      {
        plannerId,
        instance: updatedInstance,
        includeAdShells: true,
      },
      {
        onSuccess,
      },
    );
  };

  const deleteSingleInstance = (plannerId: string, instanceId: string) => {
    deleteInstances({
      [plannerId]: [instanceId],
    });
  };

  const updateAdInstanceStatus = (params: UpdatePlannerInstanceAdParams) => {
    updateInstanceAd(params);
  };

  const copyInstance = (plannerId: string, instanceId: string) => {
    const planner = getPlanner(plannerId);
    const instance = planner.instances.find(inst => inst.id == instanceId);

    createPlannerInstanceTemp(
      plannerId,
      instance ??
        raise("Trying to copy an instance of a planner that does not exist"),
    );
  };

  return {
    deleteSingleInstance,
    deleteInstances,
    updateAdInstanceStatus,
    createPlannerInstance: createPlannerInstanceTemp,
    onInstanceEditEnd,
    setSelectedInstances,
    updateInstanceAds,
    selectedInstances,
    newInstance,
    copyInstance,
  };
};
