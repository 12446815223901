import { useCallback } from "react";
import { useLazyGetCurrentUserQuery } from "redux/media/media.api";
import { getSearchState } from "./getSearchState";
import { useCustomSearchAssetsMutation } from "./useCustomSearchAssetsMutation";

type SearchParams = {
  rows?: number;
};

export const useCAMSearch = ({ rows = 1000 }: SearchParams = {}) => {
  const [getCurrentUserTrigger] = useLazyGetCurrentUserQuery();
  const trigger = useCustomSearchAssetsMutation();

  return useCallback(
    async (search: MGSearch): Promise<WDSearchResult> => {
      const { data: currentUser } = await getCurrentUserTrigger(
        undefined,
        true,
      );
      return await trigger(
        getSearchPayload({
          user_id: currentUser!.id,
          search,
          rows,
        }),
      );
    },
    [getCurrentUserTrigger, trigger, rows],
  );
};

type SearchPayload = {
  user_id: number;
  rows: number;
  search: MGSearch;
};

const getSearchPayload = ({
  user_id,
  search,
  rows,
}: SearchPayload): MGSearchPayload => {
  const { query, filters } = getSearchState(search);

  return {
    payload: {
      page: 0,
      user_id,
      query,
      rows,
      filters,
    },
  };
};
