import {
  Checkbox,
  Collapse,
  Form,
  FormInstance,
  InputNumber,
  Select,
  Table,
  Typography,
} from "antd";
import {
  MarketingMaterialPrintDeliveryForm,
  MarketingMaterialPrintOption,
  PrintDeliveryMaterialItem,
} from "shared/types/marketingMaterials";
import styles from "./PrintForm.module.scss";
import { pricePerQuantity } from "./utils/constants";
import { Rule } from "antd/lib/form";

const columns = [
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: "Simplex",
    dataIndex: "simplex",
    key: "simplex",
  },
  {
    title: "Duplex",
    dataIndex: "duplex",
    key: "duplex",
  },
];

export const PrintForm = ({
  form,
  materialId,
  value: material,
  onChange,
}: {
  form: FormInstance<MarketingMaterialPrintDeliveryForm>;
  materialId: string;
  value?: PrintDeliveryMaterialItem;
  onChange?: (material: Partial<PrintDeliveryMaterialItem>) => void;
}) => {
  if (!material) return null;

  const dataSource = pricePerQuantity.map(item => ({
    ...item,
    quantity: `${item.quantity.min}-${item.quantity.max}`,
    simplex: `$${item.simplex.toFixed(2)}`,
    duplex: `$${item.duplex.toFixed(2)}`,
  }));

  const getPriceByQuantity = (quantity: number) => {
    const entry = pricePerQuantity.find(
      item => quantity >= item.quantity.min && quantity <= item.quantity.max,
    );
    if (entry)
      return {
        simplex: entry.simplex,
        duplex: entry.duplex,
      };
    return {
      simplex: 0,
      duplex: 0,
    };
  };

  const onChangeCopies = (copies: number) => {
    const totalAmount =
      copies * getPriceByQuantity(copies)[material?.printOption ?? "simplex"];

    onChange?.({
      ...material,
      copies,
      totalAmount,
    });
  };

  const onChangePrintOption = (printOption: MarketingMaterialPrintOption) => {
    const { copies } = material;
    const totalAmount = copies * getPriceByQuantity(copies)[printOption];
    onChange?.({ ...material, totalAmount, printOption });
  };

  const isSingleMaterial =
    Object.keys(form.getFieldValue("materials")).length === 1 && material;

  const requiredCheckboxRule = (message: string): Rule[] => [
    {
      validator: (_, value) =>
        value ? Promise.resolve() : Promise.reject(new Error(message)),
    },
  ];

  return (
    <div className={styles.formContainer}>
      {isSingleMaterial && (
        <Typography.Title level={4} className={styles.title}>
          {material?.name}
        </Typography.Title>
      )}
      <div className={styles.divContainer}>
        <Form.Item
          name={["materials", materialId, "copies"]}
          label="Copies"
          required
          wrapperCol={{ span: 4 }}
          labelCol={{ span: 6 }}
        >
          <InputNumber min={25} max={999} onChange={onChangeCopies} />
        </Form.Item>
        <Form.Item
          name={["materials", materialId, "printOption"]}
          label="Print Option"
          required
          labelCol={{ span: 6 }}
        >
          <Select
            defaultActiveFirstOption
            onChange={onChangePrintOption}
            options={[
              {
                label: "Simplex",
                value: "simplex",
              },
              {
                label: "Duplex",
                value: "duplex",
              },
            ]}
          />
        </Form.Item>
        <Form.Item labelCol={{ span: 6 }} label="Total Amount">
          <div className={styles.totalAmountContainer}>
            <Typography.Text strong className={styles.totalAmountText}>
              {`$${material?.totalAmount.toFixed(2)}`}
            </Typography.Text>
            <Typography.Text className={styles.totalAmountPages}>
              {` (${material?.copies ?? 0} pages total)`}
            </Typography.Text>
          </div>
        </Form.Item>
      </div>
      <Collapse className={styles.collapsibleTable} defaultActiveKey="table">
        <Collapse.Panel header="Pages and Prices (Bulk Pack)" key="table">
          <Table columns={columns} dataSource={dataSource} pagination={false} />
        </Collapse.Panel>
      </Collapse>
      <div className={styles.confirmationContainer}>
        <Form.Item
          style={{ marginBottom: 0 }}
          required
          label="I confirm that:"
        />
        <Form.Item
          name={["materials", materialId, "confirm", "text"]}
          valuePropName="checked"
          rules={requiredCheckboxRule(
            "Please confirm the text is clear and spelled correctly.",
          )}
        >
          <Checkbox>The text is clear and spelled correctly.</Checkbox>
        </Form.Item>
        <Form.Item
          name={["materials", materialId, "confirm", "images"]}
          valuePropName="checked"
          rules={requiredCheckboxRule(
            "Please confirm the images are correctly placed.",
          )}
        >
          <Checkbox>
            The images are correctly placed; there are no blur or scale issues.
          </Checkbox>
        </Form.Item>
        <Form.Item
          name={["materials", materialId, "confirm", "review"]}
          valuePropName="checked"
          rules={requiredCheckboxRule(
            "Please confirm you have reviewed and approve this material.",
          )}
        >
          <Checkbox>I have reviewed and approve this material.</Checkbox>
        </Form.Item>
      </div>
    </div>
  );
};
