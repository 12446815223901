import { Button, Modal, Typography } from "antd";
import { TUploadLogosS3DictionaryKey } from "shared/types/uploadManagement";
import { IAccountRecord } from "shared/types/accountManagement";
import { deleteSubmittedLogoBox } from "./utils";

interface Props {
  showDeleteLogoBoxModal: boolean;
  toggleShowDeleteLogoBoxModal: React.Dispatch<React.SetStateAction<boolean>>;
  logoNameToDelete: string;
  dealerToEditWithLogos: IAccountRecord;
  logoGroupToDelete: TUploadLogosS3DictionaryKey;
  setDealerToEditWithLogos: (dealerToEditWithLogos: IAccountRecord) => void;
}

const DeletionModal = ({
  dealerToEditWithLogos,
  setDealerToEditWithLogos,
  logoGroupToDelete,
  logoNameToDelete,
  showDeleteLogoBoxModal,
  toggleShowDeleteLogoBoxModal,
}: Props) => (
  <Modal
    title="Confirm Deletion"
    visible={showDeleteLogoBoxModal}
    footer={[
      <Button
        key="oem-no-button"
        onClick={() => toggleShowDeleteLogoBoxModal(false)}
      >
        No
      </Button>,
      <Button
        onClick={() => {
          deleteSubmittedLogoBox({
            logoName: logoNameToDelete,
            group: logoGroupToDelete,
            dealerToEditWithLogos,
            setDealerToEditWithLogos,
          });
          toggleShowDeleteLogoBoxModal(false);
        }}
        key="oem-yes-button"
        type={"primary"}
      >
        Yes
      </Button>,
    ]}
  >
    <Typography.Text>
      {`Are you sure you sure you want to delete ${logoNameToDelete}?`}
    </Typography.Text>
  </Modal>
);

export default DeletionModal;
