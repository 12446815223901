export const initFacebook = ({ appId, version }: fb.InitParams) => {
  if (window.FB) {
    initFacebookSDK({ appId, version });
  } else {
    /*
      We need this function because Facebook needs this to init it SDK asynchronous
      when we don't have the SDK loaded

      Docs: https://developers.facebook.com/docs/javascript/advanced-setup
    */
    window.fbAsyncInit = function () {
      initFacebookSDK({ appId, version });
    };
  }
};

const initFacebookSDK = ({ appId, version }: fb.InitParams) => {
  window.FB?.init({
    appId: appId,
    autoLogAppEvents: true,
    xfbml: true,
    version,
  });
};

export const showCanvas = (canvasId: string) => {
  window.FB?.ui({
    display: "popup",
    method: "canvas_preview",
    canvas_id: canvasId,
  });
};

const scopes = [
  // Never expires.
  "read_audience_network_insights",
  "pages_show_list",
  "ads_management",
  "ads_read",
  "business_management",
  "pages_read_engagement",
  "pages_manage_metadata",
  "pages_read_user_content",
  "pages_manage_ads",
  "pages_manage_posts",

  // Expires after 60 days
  "public_profile",
  "catalog_management",
];

export const fbLogin = (cb: (shortTermToken?: string) => void) => {
  window.FB?.login(
    res => {
      cb(res.authResponse?.accessToken ?? undefined);
    },
    {
      scope: scopes.join(),
    },
  );
};

interface IPermissionsResponse {
  success: boolean;
}

export const removePermissions = (cb: (success: boolean) => void) => {
  fbLogin(() => {
    window.FB?.api(
      "/me/permissions",
      "delete",
      {},
      (response: IPermissionsResponse) => {
        window.FB?.logout(() => {
          cb(response.success ?? false);
        });
      },
    );
  });
};
