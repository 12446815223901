/* eslint-disable react/display-name */
import { Typography } from "antd";
import { ITableColumn } from "shared/components/dataList";
import NameTag from "screens/adLibrary/shared/components/NameTag";
import { ThumbnailCell } from "shared/components/ThumbnailCell";
import styles from "./PmaxReviewStep.module.scss";
import { InfoCircleFilled } from "@ant-design/icons";
import EditableAdToLoadUrlText from "screens/adLibrary/adLoad/adLoadDrawer/shared/components/adsToLoadTable/EditableAdToLoadUrlText";
import { usePMaxDataContext } from "shared/components/contextAPI/pmaxAssetGroup/PMaxDataProvider";
import { PmaxAdReviewItem } from "../../shared/types";
import { getThumbnailImage, pmaxTooltipProps } from "../../utils";

const getUrlCellAttributes = (item: PmaxAdReviewItem) => {
  return {
    className: item.destination.hasUrlError ? styles.pmaxError : undefined,
  };
};

const usePmaxReviewDrawerColumns = (): ITableColumn<PmaxAdReviewItem>[] => {
  const ctx = usePMaxDataContext();
  const columns: ITableColumn<PmaxAdReviewItem>[] = [
    {
      key: "rowStatus",
      title: "",
      width: 40,
      className: styles.errorInfoCell,
      render: (item: PmaxAdReviewItem) =>
        item.destination.hasUrlError ? (
          <div>
            <InfoCircleFilled />
          </div>
        ) : null,
    },
    {
      key: "name",
      title: "Asset Group Name",
      dataIndex: ["assetGroup", "name"],
      width: 350,
      render: (name: string, item) => (
        <ThumbnailCell name={name} image={getThumbnailImage(item.assetGroup)} />
      ),
    },
    {
      key: "storeName",
      title: "Store Name",
      dataIndex: ["dealer", "dealerName"],
      render: (name: string, item) => (
        <NameTag
          id={item.adAccount.id}
          name={name}
          tooltipProps={pmaxTooltipProps}
        />
      ),
    },
    {
      key: "campaign",
      title: "Campaign",
      dataIndex: ["campaign", "name"],
      render: (name: string) => (
        <NameTag id={name} name={name} tooltipProps={pmaxTooltipProps} />
      ),
    },
    {
      key: "finalUrlLabel",
      title: "Final URL Label",
      dataIndex: ["destination", "name"],
      width: 350,
      render: (labelName: string, item) => (
        <EditableAdToLoadUrlText
          showInputOnly
          disableLabelPopulation
          urlLabels={item.dealer.labels}
          willCheckFullUrl={false}
          linkValue={
            !labelName && item.destination.url ? "manual URL" : labelName
          }
          onChange={value =>
            ctx.assetGroupSelection.updateAssetGroupFinalUrl({
              assetGroupId: item.assetGroup.id,
              finalUrl: value,
            })
          }
        />
      ),
      onCell: getUrlCellAttributes,
    },
    {
      key: "finalUrl",
      title: "Final URL",
      width: 350,
      dataIndex: ["destination", "url"],
      render: (finalUrl, item) => {
        if (finalUrl) {
          return <Typography.Text>{finalUrl}</Typography.Text>;
        }

        return item.destination.hasUrlError ? (
          <Typography.Text>{finalUrl}</Typography.Text>
        ) : (
          <Typography.Text>{item.assetGroup.finalUrl}</Typography.Text>
        );
      },
      onCell: getUrlCellAttributes,
    },
  ];

  return columns.map(column => ({
    ...column,
    showSorterTooltip: false,
  }));
};

export default usePmaxReviewDrawerColumns;
