import { Button } from "antd";

import styles from "./EverythingAdNavigationButtons.module.scss";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  PartitionOutlined,
  RedoOutlined,
} from "@ant-design/icons";

interface IProps {
  handleShowEverythingAd: () => void;
  handleBack: () => void;
  handleRestart: () => void;
  handleOpenInEverythingAds: () => void;
  selectedInstantExperienceIdsPath?: string[];
  displayOpenInEverythingAdsButton?: boolean;
  instantExperienceId?: string;
}

const EverythingAdNavigationButtons = ({
  handleShowEverythingAd,
  handleBack,
  handleRestart,
  handleOpenInEverythingAds,
  selectedInstantExperienceIdsPath,
  displayOpenInEverythingAdsButton,
  instantExperienceId,
}: IProps) => (
  <div className={styles.container}>
    {!selectedInstantExperienceIdsPath?.length && (
      <Button
        className={styles.button}
        onClick={handleShowEverythingAd}
        disabled={!instantExperienceId}
        type="primary"
      >
        <ArrowRightOutlined /> See linked Everything Ad
      </Button>
    )}
    {(selectedInstantExperienceIdsPath?.length || 0) > 0 && (
      <>
        <Button className={styles.button} onClick={handleBack}>
          <ArrowLeftOutlined /> Back
        </Button>
        <Button className={styles.button} onClick={handleRestart}>
          <RedoOutlined /> Restart
        </Button>
        {displayOpenInEverythingAdsButton && (
          <Button className={styles.button} onClick={handleOpenInEverythingAds}>
            <PartitionOutlined /> Open in Everything Ads
          </Button>
        )}
      </>
    )}
  </div>
);

export default EverythingAdNavigationButtons;
