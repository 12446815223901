import {
  AdType,
  FillAdInfoColumnTitle,
  RecommendedColumnTitle,
  RecommendedColumnTitles,
} from "../shared/types";

const stillVideoRecommendedColumns = [
  RecommendedColumnTitle.InFeedMedia,
  RecommendedColumnTitle.StoryMedia,
  RecommendedColumnTitle.DisplayURL,
  RecommendedColumnTitle.DestinationURL,
  RecommendedColumnTitle.AdName,
  RecommendedColumnTitle.PrimaryText,
  RecommendedColumnTitle.Headline,
  RecommendedColumnTitle.Description,
  RecommendedColumnTitle.CTAButton,
  RecommendedColumnTitle.UTM,
  RecommendedColumnTitle.Brand,
  RecommendedColumnTitle.Account,
];

export const recommendedColumnMatches = {
  [RecommendedColumnTitle.InFeedMedia]: ["feed", "1:1", "1080x1080"],
  [RecommendedColumnTitle.StoryMedia]: ["story", "9:16", "1920x1080"],
  [RecommendedColumnTitle.AdName]: ["adname", "ad name", "name"],
  [RecommendedColumnTitle.PrimaryText]: [
    "primarytext",
    "primary text",
    "primary",
    "text",
  ],
  [RecommendedColumnTitle.Headline]: ["headline", "head", "line"],
  [RecommendedColumnTitle.Description]: ["description", "desc"],
  [RecommendedColumnTitle.CTAButton]: ["cta button", "cta", "call", "action"],
  [RecommendedColumnTitle.DisplayURL]: ["displayurl", "display"],
  [RecommendedColumnTitle.UTM]: ["utm"],
  [RecommendedColumnTitle.DestinationURL]: ["destinationurl", "destination"],
  [RecommendedColumnTitle.Brand]: ["brand"],
  [RecommendedColumnTitle.Account]: ["account"],
};

export const recommendedToAdInfoColumnMap = {
  [RecommendedColumnTitle.InFeedMedia]: FillAdInfoColumnTitle.InFeedMedia,
  [RecommendedColumnTitle.StoryMedia]: FillAdInfoColumnTitle.StoryMedia,
  [RecommendedColumnTitle.AdName]: FillAdInfoColumnTitle.AdName,
  [RecommendedColumnTitle.DisplayURL]: FillAdInfoColumnTitle.DisplayURL,
  [RecommendedColumnTitle.DestinationURL]: FillAdInfoColumnTitle.DestinationURL,
  [RecommendedColumnTitle.PrimaryText]: FillAdInfoColumnTitle.PrimaryText,
  [RecommendedColumnTitle.Headline]: FillAdInfoColumnTitle.Headline,
  [RecommendedColumnTitle.Description]: FillAdInfoColumnTitle.Description,
  [RecommendedColumnTitle.CTAButton]: FillAdInfoColumnTitle.CTAButton,
  [RecommendedColumnTitle.UTM]: FillAdInfoColumnTitle.UTM,
  [RecommendedColumnTitle.Brand]: FillAdInfoColumnTitle.Brand,
  [RecommendedColumnTitle.Account]: FillAdInfoColumnTitle.Account,
};

const stillVideoFillAdInfoColumns = [
  FillAdInfoColumnTitle.InFeedMedia,
  FillAdInfoColumnTitle.StoryMedia,
  FillAdInfoColumnTitle.AdName,
  FillAdInfoColumnTitle.DisplayURL,
  FillAdInfoColumnTitle.DestinationURL,
  FillAdInfoColumnTitle.PrimaryText,
  FillAdInfoColumnTitle.Headline,
  FillAdInfoColumnTitle.Description,
  FillAdInfoColumnTitle.CTAButton,
  FillAdInfoColumnTitle.UTM,
  FillAdInfoColumnTitle.Brand,
  FillAdInfoColumnTitle.Account,
];

export const fillAdInfoColumns = {
  [AdType.Still]: stillVideoFillAdInfoColumns,
  [AdType.Video]: stillVideoFillAdInfoColumns,
};

export const recommendedColumnTitles: RecommendedColumnTitles = {
  [AdType.Still]: stillVideoRecommendedColumns,
  [AdType.Video]: stillVideoRecommendedColumns,
};
