import { message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { IAccount } from "shared/types/accountManagement";
import { useSchemaValidation } from "utils/helpers.zod";
import { AccountAddress, accountAddressSchema } from "./schema";
import useUpdateDealer from "shared/hooks/useUpdateDealer";

export const useAccountLocationForm = () => {
  const { mutate: updateAccount, isLoading: isUpdating } = useUpdateDealer();
  const [form] = useForm<AccountAddress>();
  const schemaRules = useSchemaValidation(accountAddressSchema);

  const submit = async (originalAccount: IAccount, onSuccess: () => void) => {
    try {
      await form.validateFields();

      const values = form.getFieldsValue();

      const updatedAccount = {
        ...originalAccount,
        ...values,
      };

      updateAccount(updatedAccount, {
        onSettled: async (data, error) => {
          if (!data || error) {
            message.error("Something went wrong");
            return;
          }

          message.success("Account updated successfully");
          onSuccess();
          close();
        },
      });
    } catch (err) {
      message.error("Please fill out all required fields");
    }
  };

  return {
    submit,
    form,
    schemaRules,
    isUpdating,
  };
};
