import { Button, message, notification } from "antd";
import API from "services";
import { OfferData } from "shared/types/assetBuilder";
import styles from "./UndoNotification.module.scss";

type Props = {
  vin?: string;
  deletedOfferEdits: Partial<OfferData>;
};

type Handlers = {
  invalidateQueries: (key: string) => void;
};

const UndoNotification = ({
  vin,
  deletedOfferEdits,
  invalidateQueries,
}: Props & Handlers) => {
  const notificationKey = `notification_key_${vin}`;

  return (
    <div className={styles.UndoNotification}>
      <div>{`${vin} has been reverted to the raw feed data.`}</div>
      <div className={styles.UndoButtonWrapper}>
        <Button
          type="primary"
          onClick={async e => {
            e.preventDefault();
            e.stopPropagation();

            if (!vin) {
              message.error("VIN is invalid.");
              return;
            }

            try {
              // The offer edits were deleted.
              // If user wants to "undo" it, it means user wants to add the deleted data back.
              // In this case, we need to save the deleted data back in the DB.
              // NOTE: when success, it returns 204 which is no content.
              //       otherwise, the catch block will handle the error case.
              await API.services.assetBuilder.saveOfferEdits(
                vin,
                deletedOfferEdits,
              );

              invalidateQueries("offer-list");

              notification.success({
                key: notificationKey,
                message: "Undo was success!",
                description: "Successfully undo the feed data revert.",
                duration: 5,
              });
            } catch (error) {
              notification.error({
                key: notificationKey,
                message: "Unable to undo.",
                description: "Attempted to undo the revert but failed.",
                duration: 5,
              });
            }
          }}
        >
          Undo
        </Button>
      </div>
    </div>
  );
};

export default UndoNotification;
