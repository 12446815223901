/* eslint-disable react/display-name */
import { message, Spin, TablePaginationConfig } from "antd";
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
} from "antd/lib/table/interface";
import { MouseEvent, useCallback, useEffect, useState } from "react";
import TablePopup from "screens/adLibrary/library/adList/adTableListContainer/TablePopup";
import { DataListTags, useDataList } from "shared/components/dataList";
import ToolbarTable, {
  ToolbarButton,
} from "shared/components/toolbarTable/ToolbarTable";
import VirtualTable from "shared/components/VirtualTable";
import {
  useDeletePublicDashboard,
  useDeletePublicDashboards,
} from "shared/hooks/dashboardManagement/useDeleteDashboard";
import useResizableTable from "shared/hooks/useResizableTable";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { IPublicDashboard, PopupState } from "shared/types/dashboardManagement";
import PublicLinkModal from "../PublicLinkModal";
import { useDisplayColumns } from "../shared/hooks/useDisplayColumns";

export const PublicDashboardsList = () => {
  const [showLinkModal, setShowLinkModal] = useState<boolean>(false);
  const [editingDashboard, setEditingDashboard] = useState<IPublicDashboard>();
  const [popupState, setPopupState] = useState<PopupState>({ visible: false });
  const [dataList, dataListActions] = useDataList<IPublicDashboard>();
  const { mutate: deletePublicDashboard } = useDeletePublicDashboard();
  const { mutate: deletePublicDashboards } = useDeletePublicDashboards();

  const defaultSortItems = useCallback(() => {
    if (!dataList.isLoading) dataListActions.sortItems("createdAt", "descend");
  }, [dataList.isLoading, dataListActions]);

  useEffect(defaultSortItems, [defaultSortItems]);

  const onClickEdit = (publicDashboard?: IPublicDashboard) => {
    setEditingDashboard(publicDashboard);
    setShowLinkModal(true);
  };

  const onClickDelete = (del?: IPublicDashboard | IPublicDashboard[]) => {
    if (Array.isArray(del)) {
      const ids = del
        .map(pubDash => pubDash.id)
        .filter((id): id is string => id !== undefined);
      deletePublicDashboards(ids, {
        onSuccess: () => {
          message.info("The links have been deleted");
        },
        onError: () => {
          message.error("The links could not be deleted");
        },
      });
      return;
    }
    if (!del?.id) return;
    deletePublicDashboard(del.id, {
      onSuccess: () => {
        message.info("The link has been deleted");
      },
      onError: () => {
        message.error("The link could not be deleted");
      },
    });
  };

  const onChangeHandler = (
    _: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<IPublicDashboard> | SorterResult<IPublicDashboard>[],
    extra: TableCurrentDataSource<IPublicDashboard>,
  ) => {
    const { action } = extra;
    if (action === "filter") {
      dataListActions.setFilters(filters);
    } else if (action === "sort" && !Array.isArray(sorter)) {
      dataListActions.sortItems(sorter.columnKey, sorter.order);
    }
  };

  const onRowHandler = (record: IPublicDashboard) => ({
    onContextMenu: (event: MouseEvent) => {
      event.preventDefault();
      document.addEventListener("click", function onClickOutside() {
        document.removeEventListener("click", onClickOutside);
      });

      if (!popupState.visible) {
        document.addEventListener("click", function onClickOutside() {
          setPopupState({ visible: false });
          document.removeEventListener("click", onClickOutside);
        });
      }
      setPopupState({
        record,
        visible: true,
        x: event.clientX,
        y: event.clientY,
      });
    },
  });

  const toolbarContents: ToolbarButton = {
    Edit: {
      disabled: dataList.selectedItems.length !== 1,
      onClick: () => onClickEdit(dataList.selectedItems[0]),
    },
    Delete: {
      disabled: dataList.selectedItems.length === 0,
      onClick: () => {
        onClickDelete(dataList.selectedItems);
        dataListActions.setSelectedItems([]);
      },
    },
  };

  const { columns } = useDisplayColumns();
  const { components, resizableColumns } = useResizableTable<IPublicDashboard>(
    columns.map(column => ({ ...column, name: column.title })),
  );

  const { windowInnerHeight } = useWindowSize();
  return (
    <Spin spinning={dataList.isLoading}>
      <div style={{ paddingTop: "1em" }}>
        <ToolbarTable
          searchPlaceholder="Search"
          titleTooltip={""}
          toolbarContents={toolbarContents}
          searchValue={dataList.globalFilter}
          sortingOrder={
            dataList.sort.columnKey
              ? [
                  dataList.sort.columnKey as string,
                  dataList.sort.order?.replace("end", "") as string,
                ]
              : undefined
          }
          onSearch={value => dataListActions.setGlobalFilter(value)}
          onSortChange={([columnKey, order]) => {
            dataListActions.sortItems(columnKey, order);
          }}
        />
        <DataListTags />
        <VirtualTable<IPublicDashboard>
          rowSelection={{
            selectedRowKeys: dataList.selectedIds,
            onChange: dataListActions.setSelectedItems,
          }}
          scroll={{ y: windowInnerHeight - 205 }}
          size={"small"}
          rowKey={record => record.id!}
          columns={resizableColumns}
          components={components}
          dataSource={dataList.rows}
          onRow={onRowHandler}
          onChange={onChangeHandler}
        />
        <PublicLinkModal
          key={`edit-modal-${editingDashboard?.id}`}
          dashboard={editingDashboard?.dashboard}
          publicDashboard={editingDashboard}
          setShowLinkModal={setShowLinkModal}
          showLinkModal={showLinkModal}
        />
        {popupState.record && (
          <TablePopup popupState={popupState}>
            <TablePopup.EditItem
              onClick={() => onClickEdit(popupState.record)}
            />
            <TablePopup.DeleteItem
              onClick={() => onClickDelete(popupState.record)}
            />
          </TablePopup>
        )}
      </div>
    </Spin>
  );
};
