import { Button } from "antd";
import { DesktopOutlined, MobileOutlined } from "@ant-design/icons";

import useSelectedPreviewFormat from "shared/hooks/userPreferences/useSelectedPreviewFormat";
import { AdFormat } from "screens/adLibrary/facebookUtils/types";

import classNames from "classnames";
import styles from "./PreviewFormatButton.module.scss";

type ButtonIconProps = {
  format: AdFormat;
  className?: string;
};

const ButtonIcon = ({ format, className }: ButtonIconProps) => {
  if (format === AdFormat.MOBILE_FEED_STANDARD)
    return <MobileOutlined className={className} />;
  if (format === AdFormat.DESKTOP_FEED_STANDARD)
    return <DesktopOutlined className={className} />;
  return null;
};

type PreviewFormatButtonProps = {
  format: AdFormat;
};

const PreviewFormatButton = ({ format }: PreviewFormatButtonProps) => {
  const { isFormat, setPreviewFormat } = useSelectedPreviewFormat();

  return (
    <Button
      className={classNames({
        [styles.activePreviewBtn]: isFormat(format),
        [styles.inactivePreviewBtn]: !isFormat(format),
      })}
      onClick={() => {
        setPreviewFormat(format);
      }}
      icon={
        <ButtonIcon
          format={format}
          className={classNames({
            [styles.inactivePreivewIcon]: !isFormat(format),
          })}
        />
      }
    />
  );
};

export default PreviewFormatButton;
