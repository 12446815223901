import { fabric } from "fabric";
import {
  ICanvasObject,
  isStamp,
  ExportImageType,
} from "shared/types/assetBuilder";
import {
  IDimension,
  IExtendedFabricObject,
  IScale,
} from "shared/types/designStudio";

/**
 *
 * @param original
 * @param resizedDimension
 *
 * This calculate the width of resized object while keeping aspect ratios.
 */
export const calcWidth = (
  original: IDimension,
  resizedDimension: IDimension,
) => {
  const { height } = resizedDimension;
  return (original.width * height) / original.height;
};

/**
 *
 * @param original
 * @param resizedDimension
 *
 * This calculate the height of resized object while keeping aspect ratios.
 */
export const calcHeight = (
  original: IDimension,
  resizedDimension: IDimension,
) => {
  const { width } = resizedDimension;
  return (original.height * width) / original.width;
};

/**
 *
 * @param defaultDimension
 * @param originalDimension
 *
 * This function is for calculating the preview container dimension repecting the original canvas dimension.
 * This might be used to fit in bigger canvas into smaller size.
 */
export const calcPreviewDimension = ({
  defaultDimension = {
    width: 500,
    height: 500,
  },
  originalDimension,
}: {
  defaultDimension?: IDimension;
  originalDimension: IDimension;
}): IDimension & { resizedOrientation?: "width" | "height" } => {
  let result: IDimension;
  const { width, height } = originalDimension;

  if (width > height) {
    const widthToFit =
      width <= defaultDimension.width ? width : defaultDimension.width;
    result = {
      width: widthToFit,
      height: calcHeight(
        { width, height },
        { ...defaultDimension, width: widthToFit },
      ),
    };

    if (result.height > defaultDimension.height) {
      let currentWidth = result.width;
      let currentHeight = result.height;
      let limit = 500; // limit it to 500 px
      while (currentHeight > defaultDimension.height && limit > 0) {
        currentWidth -= 1;
        currentHeight = calcHeight(originalDimension, {
          width: currentWidth,
          height: currentHeight,
        });
        limit--;
      }

      result = {
        width: currentWidth,
        height: currentHeight,
      };
    }
  } else {
    const heightToFit =
      height <= defaultDimension.height ? height : defaultDimension.height;
    result = {
      width: calcWidth(
        { width, height },
        { ...defaultDimension, height: heightToFit },
      ),
      height: heightToFit,
    };

    if (result.width > defaultDimension.width) {
      const currentWidth = result.width;
      let currentHeight = result.height;
      let limit = 500; // limit it to 500 px
      while (currentWidth > defaultDimension.width && limit > 0) {
        currentHeight -= 1;
        currentHeight = calcWidth(originalDimension, {
          width: currentWidth,
          height: currentHeight,
        });
        limit--;
      }

      result = {
        width: currentWidth,
        height: currentHeight,
      };
    }
  }

  return result;
};

const calcScalesToFit = (
  originalDimension: IDimension,
  dimensionToFit: IDimension,
): IScale => {
  return {
    scaleX: dimensionToFit.width / originalDimension.width,
    scaleY: dimensionToFit.height / originalDimension.height,
  };
};

export const scaleCanvas = ({
  canvas,
  originalDimension,
  resizedCanvasDimension,
}: {
  canvas: fabric.Canvas;
  originalDimension: IDimension;
  resizedCanvasDimension: IDimension;
}) => {
  const adjustedScale = calcScalesToFit(
    originalDimension,
    resizedCanvasDimension,
  );

  if (canvas.backgroundImage) {
    const background = canvas.backgroundImage as fabric.Image;

    background.scaleToWidth(resizedCanvasDimension.width);
    background.scaleToHeight(resizedCanvasDimension.height);

    background.setCoords();
  } else {
    /* This prevents interactions with stamp preview objects */
    canvas.getObjects().forEach(obj => {
      obj.set({
        selectable: false,
        evented: false,
      });
    });
  }

  canvas.getObjects().forEach(obj => {
    obj.set({
      top: (obj.top || 0) * adjustedScale.scaleX,
      left: (obj.left || 0) * adjustedScale.scaleY,
      scaleX: (obj.scaleX || 1) * adjustedScale.scaleX,
      scaleY: (obj.scaleY || 1) * adjustedScale.scaleY,
      selectable: false,
      evented: false,
    });
    obj.setCoords();
  });
  canvas.renderAll();
};

const applyResizeFilterToCanvasImages = (
  canvas: fabric.Canvas,
  replaceObjects?: boolean,
) => {
  canvas.forEachObject(obj => {
    try {
      const scaleFactor = ((obj.scaleX || 0) + (obj.scaleY || 0)) / 2;

      const willScale =
        !isStamp(obj as unknown as ICanvasObject) &&
        ["car_cut", "lifestyle", "canvas_bg", "logo"].includes(
          (obj as IExtendedFabricObject).customType,
        ) &&
        scaleFactor < 0.5;

      if (!willScale) {
        return;
      }

      const filter = new fabric.Image.filters.Resize({
        type: "sliceHack",
        scaleX: obj.scaleX,
        scaleY: obj.scaleY,
      });

      const imageObj = obj as fabric.Image;

      if (imageObj && imageObj.applyFilters) {
        imageObj.filters?.push(filter);

        if (replaceObjects) {
          canvas.remove(obj);
          canvas.add(imageObj.applyFilters([filter]));
        } else {
          imageObj.applyFilters([filter]);
        }
      }
    } catch (error) {
      /*
         if an error is caught that means an image was not loaded correctly,
         so just skip the filtering process for the failing image for now.
       */
    }
  });
  canvas.renderAll();
};

export const returnScaledFabricCanvasDataUrl = (
  canvas: fabric.Canvas,
  imageOption?: ExportImageType,
  quality?: number,
) => {
  try {
    applyResizeFilterToCanvasImages(canvas);
    /* quality value only works for jpeg image type.
       giving 0.95 returns around 40% size compared with the original (1)
     */
    const dataUrl = canvas.toDataURL({ format: imageOption, quality });

    return dataUrl;
  } catch (error) {
    return "";
  }
};
