import { Dispatch, memo, SetStateAction, useMemo } from "react";
import styles from "./OfferListSection.module.scss";
import OfferCollapse, {
  Handlers as OfferCollapseHandlers,
} from "./offerListSection/OfferCollapse";
import { TOfferCollapseData } from "../SelectV2";
import { TCount, TOfferListSection } from "../select.hooks/useOfferList";
import { Button, Empty, Spin } from "antd";
import { TSortByOption } from "./OfferFilterV2";
import { TOfferListData } from "screens/assetBuilder/PaymentEngineModal";
import {
  ISavedOrderState,
  OfferData,
  Tab,
  TGetOfferListResult,
} from "shared/types/assetBuilder";
import { useFetchOfferListV2 } from "shared/hooks/useFetchOfferList";
import useDeepEffect from "shared/hooks/useDeepEffect";
import { LoadingOutlined } from "@ant-design/icons";
import useOfferListParams from "../select.hooks/useOfferListParams";
import { OfferType } from "shared/types/shared";
import NewOfferCollapse from "./offerListSection/NewOfferCollapse";
import { isAuto, isPharma } from "utils/helpers";

interface Props {
  savedOrder: ISavedOrderState;
  searchBy?: string;
  tab: Tab;
  sectionKey: TOfferListSection;
  offerList: TOfferCollapseData[];
  offerListData?: TOfferListData;
  shouldDisplayLoadMore: boolean;
  editedVins?: string[];
  isUpdating: boolean;
  count?: TCount;
  filterField?: string;
  addingMode: boolean;
  duplicatedOfferData?: Partial<OfferData>;
}

interface Handlers extends OfferCollapseHandlers {
  onDataFetchComplete: (
    key: string,
    result: TGetOfferListResult,
    reset: boolean,
  ) => void;
  setAddingMode: Dispatch<SetStateAction<boolean>>;
  setDuplicatedOfferData?: Dispatch<
    SetStateAction<Partial<OfferData> | undefined>
  >;
}

const getTitle = (sectionkey: TOfferListSection) => {
  if (sectionkey === "selected" && isPharma) return "Selected Modules";
  if (isPharma) return "Available Modules";
  if (sectionkey === "selected") return "Selected Offers";
  return "Available Offers";
};

const getEmptyDescription = (sectionkey: TOfferListSection) => {
  if (sectionkey === "selected")
    return "Select available offers below to build into assets";
  return "No available offers";
};

const OfferListSection = (props: Props & Handlers) => {
  const {
    tab,
    searchBy,
    savedOrder,
    sectionKey,
    duplicatedOfferData,
    setDuplicatedOfferData,
  } = props;
  const [fetchOfferListParams, setFetchOfferListParams] = useOfferListParams({
    feedId: tab.id,
    searchBy,
    savedOrder,
    sectionKey,
    filterField: props.filterField,
  });

  const filterOfferTypes = (fetchOfferListParams.offerTypes ??
    []) as OfferType[];

  const { onDataFetchComplete } = props;
  const { data, isLoading } = useFetchOfferListV2({
    ...fetchOfferListParams,
  });

  const dataKey = useMemo(
    () => (sectionKey === "selected" ? sectionKey : `${sectionKey}-${tab.id}`),
    [tab, sectionKey],
  );

  useDeepEffect(() => {
    if (!data) return;
    const reset = fetchOfferListParams.currentPage === 1;

    onDataFetchComplete(dataKey, data, reset);
  }, [data, fetchOfferListParams, dataKey]);

  const { count } = props;
  const shouldDisplayLoadMore =
    sectionKey !== "selected" &&
    !!count &&
    count.total > count.current &&
    count.current !== 0;

  return (
    <div className={styles.OfferListSection}>
      <div className={styles.OfferListSectionTitle}>
        <span>{getTitle(props.sectionKey)}</span>
        {props.isUpdating && (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 15 }} />} />
        )}
      </div>
      {!isLoading && props.offerList.length === 0 && (
        <Empty description={getEmptyDescription(props.sectionKey)} />
      )}

      <Spin className={styles.Spin} tip="Loading..." spinning={isLoading}>
        {props.sectionKey === "selected" && props.addingMode && (
          <NewOfferCollapse
            feedId={tab.id}
            savedOrder={savedOrder}
            setAddingMode={props.setAddingMode}
            duplicatedOfferData={duplicatedOfferData}
          />
        )}
        {props.offerList.map(item => (
          <OfferCollapse
            key={`offer-collapse-key-${props.sectionKey}-${item.offerData.vin}`}
            sectionKey={props.sectionKey}
            edited={!!props.editedVins?.includes(item.offerData.vin || "")}
            offer={item.offerData}
            offerTypes={item.offerTypes}
            offerListData={props.offerListData}
            moveOfferTo={props.moveOfferTo}
            lastUpdated={item.lastUpdated ?? tab.lastUpdate}
            flagged={isAuto ? item.flagged : false}
            warned={item.warned}
            sortByOptions={fetchOfferListParams.sortBy as TSortByOption[]}
            editedPairs={item.editedPairs}
            togglePaymentEngineModal={props.togglePaymentEngineModal}
            savedOrder={savedOrder}
            filterOfferTypes={filterOfferTypes}
            feedId={tab.id}
            setAddingMode={props.setAddingMode}
            setDuplicatedOfferData={setDuplicatedOfferData}
          />
        ))}

        {shouldDisplayLoadMore && (
          <Button
            loading={isLoading}
            onClick={e => {
              e.preventDefault();

              // Need to extract the feedId from dataKey
              // dateKey format is <section>-<feedId>
              // Load more button only available in available section.
              // so get the feedId by removing "available-"

              setFetchOfferListParams(prev => ({
                ...prev,
                currentPage: (prev.currentPage as number) + 1,
              }));
            }}
          >
            Load More
          </Button>
        )}
      </Spin>
    </div>
  );
};

export default memo(OfferListSection);
