import { Select } from "antd";
import { useFetchFacebookCatalogs } from "shared/hooks/adLibrary/facebook/useFetchFacebookObject";

type Props = {
  value: string | undefined;
  onChange: (productCatalogId: string) => void;
};

const ProductCatalogSelect = ({ value, onChange }: Props) => {
  const { data: productCatalogs, isLoading: isLoadingCatalogs } =
    useFetchFacebookCatalogs();

  const selectValue = isLoadingCatalogs ? undefined : value;

  return (
    <Select
      options={productCatalogs?.map(catalog => ({
        value: catalog.id,
        label: `${catalog.name} - ${catalog.id}`,
      }))}
      value={selectValue}
      onChange={onChange}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option?.label?.toString() ?? "")
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      placeholder={
        isLoadingCatalogs
          ? "Loading product catalogs..."
          : "Select a product catalog"
      }
      notFoundContent={`Facebook catalogs not found`}
      loading={isLoadingCatalogs}
      disabled={isLoadingCatalogs}
      style={{ width: 250 }}
    />
  );
};

export default ProductCatalogSelect;
