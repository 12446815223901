import { useRef, useState } from "react";
import styles from "./Search.module.scss";
import { Button, Input } from "antd";
import { ColumnHeightOutlined } from "@ant-design/icons";

type Props = {
  onChange: (searchBy: string) => void;
  onToggleExpand: () => void;
};
const Search = (props: Props) => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [searching, setSearching] = useState<boolean>(false);

  return (
    <div className={styles.search}>
      <div className={styles.input}>
        <Input.Search
          allowClear
          loading={searching}
          placeholder="search"
          onChange={e => {
            setSearching(true);
            const {
              target: { value },
            } = e;

            if (timeoutRef.current) clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => {
              props.onChange(value);
              setSearching(false);
            }, 300);
          }}
        />
      </div>
      <Button icon={<ColumnHeightOutlined />} onClick={props.onToggleExpand} />
    </div>
  );
};

export default Search;
