import { Popover } from "antd";
import { ILifestyleImage } from "shared/types/assetBuilder";
import { ITemplate } from "shared/types/designStudio";
import ImageGalleryUploader from "./ImageGalleryUploader";

type Props = {
  imageSelectionTitle: string;
  template: ITemplate;
  showUploader: boolean;
  offerData?: any;
  lifestyleImages: ILifestyleImage[];
  setLifestyleImages: (lifestyleImages: ILifestyleImage[]) => void;
  toggleUploader: (show: boolean) => void;
};

export const ImageSelectionHeader = (props: Props) => {
  const {
    imageSelectionTitle,
    template,
    lifestyleImages,
    setLifestyleImages,
    showUploader,
    offerData,
    toggleUploader,
  } = props;

  return (
    <div className="title image-selection">
      <span className="image-selection-title">{imageSelectionTitle}</span>
      {template.type === "lifestyle" && (
        <Popover
          trigger="click"
          visible={showUploader}
          onVisibleChange={visible => {
            toggleUploader(visible);
          }}
          placement="right"
          content={
            <ImageGalleryUploader
              offerData={offerData || undefined}
              toggleUploader={show => {
                toggleUploader(show);
              }}
              onImageUploadComplete={lifestyleImage => {
                setLifestyleImages([...lifestyleImages, lifestyleImage]);
              }}
            />
          }
        >
          <span
            className="image-uploader-button"
            onClick={e => {
              e.preventDefault();

              toggleUploader(!showUploader);
            }}
          >
            + Add
          </span>
        </Popover>
      )}
    </div>
  );
};
