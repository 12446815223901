import API from "services";
import { useMutation, useQueries } from "react-query";
import {
  IExportDataProps,
  IAd,
  IExportDataPayload,
  IExportGetFileUrlResponse,
  IAdExportRequest,
} from "shared/types/adLibrary";
import { ITableColumn } from "shared/components/dataList";
import { get } from "lodash";

export const EXPORT_GENERATING = "GENERATING";
export const EXPORT_DONE = "DONE";
const GENERATION_ERROR =
  "Something went wrong while generating the export file.";
const exportIdsKey = "adLibrary/exportIds";

export const useGenerateExportUploadData = () => {
  return useMutation<void, Error, IExportDataProps>(generateExportUploadData);
};

export const useGenerateExportRequests = ({
  adExportRequests,
  handleOnSuccess,
  handleOnError,
}: {
  adExportRequests: IAdExportRequest[];
  handleOnSuccess: (response: IExportGetFileUrlResponse) => void;
  handleOnError: (uuid: string) => void;
}) => {
  return useQueries(
    adExportRequests
      .filter(adExportRequest => adExportRequest.type === "file")
      .map(adExportRequest => {
        return {
          queryKey: [exportIdsKey, adExportRequest.uuid],
          queryFn: () => generateExportGetFileUrl(adExportRequest.uuid),
          refetchInterval: (data: any) => {
            const exportUrlData = data as IExportGetFileUrlResponse;
            return !exportUrlData || exportUrlData.status === EXPORT_GENERATING
              ? 3000
              : false;
          },
          staleTime: Infinity,
          onSuccess: (data: IExportGetFileUrlResponse) => {
            handleOnSuccess(data);
          },
          onError: () => {
            handleOnError(adExportRequest.uuid);
          },
        };
      }),
  );
};

const exportValue = (
  col: ITableColumn<IAd>,
  row: IAd,
  index: number,
): string => {
  if (col.exportFn) {
    return col.exportFn(row);
  }

  const value = col.dataIndex ? get(row, col.dataIndex) : row;
  if (col.render) {
    const testRender = col.render(value, row, index);
    if (typeof testRender === "string") {
      return testRender;
    }
  }

  if (Array.isArray(value)) {
    return value.join(", ");
  }

  return value ?? "";
};

export const prepData = (props: IExportDataProps): IExportDataPayload => {
  const {
    filenamePrefix,
    formData,
    colDefsForExport,
    allColsSelected,
    allRowsSelected,
    ads,
    selectedAdIds,
    uuid,
  } = props;
  const columns = colDefsForExport.reduce(
    (cols: string[], col: ITableColumn<IAd>) => {
      if (allColsSelected || formData.selectedColumnNames.includes(col.title)) {
        return cols.concat(col.title);
      }
      return cols;
    },
    [],
  );
  const data = ads
    .filter(row => allRowsSelected || selectedAdIds.includes(row.id))
    .map(row => {
      const rowData = colDefsForExport
        .filter(col => columns.includes(col.title))
        .map((col, idx) => {
          return {
            [col.title]: exportValue(col, row, idx),
          };
        });
      return Object.assign({}, ...rowData);
    });
  return {
    filename: `${filenamePrefix}-${new Date().toJSON().slice(0, 10)}`,
    destination: formData.destination,
    columns,
    data,
    uuid,
  };
};

const generateExportUploadData = async (props: IExportDataProps) => {
  const exportData = prepData(props);

  const { result, error } =
    await API.services.adLibrary.getExportUploadSignedUrl(exportData.uuid);

  if (error || !result) {
    throw Error(GENERATION_ERROR);
  }

  try {
    await API.services.adLibrary.uploadExportData(result.url, exportData);
  } catch (e) {
    throw Error(GENERATION_ERROR);
  }
};

const generateExportGetFileUrl = async (exportId: string) => {
  const { result, error } =
    await API.services.adLibrary.getExportGeneratedFileUrl(exportId);

  if (!result || error) {
    throw Error(GENERATION_ERROR);
  }

  return result;
};
