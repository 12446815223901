import { Outlet } from "react-router-dom";
import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";
import { useDatadog } from "shared/hooks/useDatadog";
import { fields } from "./campaignManagement/fields";

export const CampaignManagementProvider = () => {
  useDatadog();
  
  return (
    <DataListURLProvider fields={fields}>
      <Outlet />
    </DataListURLProvider>
  );
};
