import Icon, { FileDoneOutlined } from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { TemplateStatus } from "shared/types/salesEnablement";

export const UnpublishIcon = () => (
  <svg width="16" viewBox="0 0 31 32" fill="none">
    <g fill="currentColor">
      <path
        d="M22.2023 7.24917V8.98607C22.2023 9.14529 22.0739 9.27555 21.917 9.27555H19.0311L15.0689 13.3283V14.1968C15.0689 14.356 14.9406 14.4863 14.7836 14.4863H13.8817L5.08223 23.4137V27.7223L25.0557 7.42456V3.15343L21.3081 6.95969H21.917C22.0739 6.95969 22.2023 7.08995 22.2023 7.24917Z"
        fillOpacity="0.85"
      />
      <path
        d="M5.08223 3.05166H25.0557V3.15343L27.4355 0.914356C27.2265 0.672688 26.8249 0.446308 26.4823 0.446308H3.65556C3.02425 0.446308 2.51422 0.96376 2.51422 1.60424V15.7889V25.9969V29.9737C2.51422 30.0712 2.52603 30.1658 2.5483 30.2562L5.08223 27.7223V23.4137V3.05166Z"
        fillOpacity="0.85"
      />
      <path
        d="M27.7184 1.67829C27.7184 1.38532 27.6117 1.11809 27.4355 0.914356L25.0557 3.15343V7.42456L27.7184 4.8889V1.67829Z"
        fillOpacity="0.85"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2023 7.24917V8.98607C22.2023 9.14529 22.0739 9.27555 21.917 9.27555H19.0311H8.22091C8.06398 9.27555 7.93558 9.14529 7.93558 8.98607V7.24917C7.93558 7.08995 8.06398 6.95969 8.22091 6.95969H21.3081H21.917C22.0739 6.95969 22.2023 7.08995 22.2023 7.24917ZM7.93558 12.4599C7.93558 12.3007 8.06398 12.1704 8.22091 12.1704H14.7836C14.9406 12.1704 15.0689 12.3007 15.0689 12.4599V13.3283V14.1968C15.0689 14.356 14.9406 14.4863 14.7836 14.4863H13.8817H8.22091C8.06398 14.4863 7.93558 14.356 7.93558 14.1968V12.4599ZM23.226 21.4339H24.806C25.0378 21.4339 25.1734 21.7017 25.0343 21.8934L20.9611 27.6107C20.847 27.77 20.6151 27.77 20.501 27.6107L17.8688 23.9162C17.7333 23.7244 17.8688 23.4566 18.1006 23.4566H19.6735C19.7188 23.4563 19.7635 23.4669 19.8038 23.4877C19.8442 23.5085 19.879 23.5388 19.9054 23.5761L20.7293 24.7304L22.9941 21.5533C23.0476 21.4773 23.1332 21.4339 23.226 21.4339ZM7.80903 28.5262H13.3569C13.5139 28.5262 13.6423 28.6565 13.6423 28.8157V30.8421C13.6423 31.0013 13.5139 31.1316 13.3569 31.1316H5.27164L7.80903 28.5262ZM25.0557 15.4995V11.0993L27.6237 8.44802V15.4995C27.6237 15.6587 27.4953 15.7889 27.3384 15.7889H25.341C25.1841 15.7889 25.0557 15.6587 25.0557 15.4995Z"
        fillOpacity="0.85"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9276 24.184C13.9276 28.5009 17.3766 32 21.6317 32C25.8867 32 29.3357 28.5009 29.3357 24.184C29.3357 19.8671 25.8867 16.3679 21.6317 16.3679C21.451 16.3679 21.3834 16.371 21.1886 16.3694C20.9938 16.3679 20.8065 16.3679 20.6595 16.4071C20.5124 16.4464 20.4264 16.4547 20.2594 16.4971C20.0851 16.5413 19.8592 16.6006 19.5351 16.6803C18.2636 16.9926 14.2088 22.084 14.2088 22.084C14.0256 22.7522 13.9276 23.4565 13.9276 24.184ZM21.6317 29.6842C23.0797 29.6842 24.4422 29.1124 25.4658 28.0739C26.4895 27.0354 27.053 25.6531 27.053 24.184C27.053 22.7148 26.4895 21.3326 25.4658 20.294C24.4422 19.2555 23.0797 18.6838 21.6317 18.6838C20.1836 18.6838 18.8211 19.2555 17.7975 20.294C17.284 20.815 16.8863 21.4224 16.6174 22.084C16.3503 22.7412 16.2103 23.4518 16.2103 24.184C16.2103 25.6531 16.7738 27.0354 17.7975 28.0739C18.8211 29.1124 20.1836 29.6842 21.6317 29.6842Z"
        fillOpacity="0.85"
      />
      <path
        d="M30.2222 1.89414L28.8906 0.54322L28.5392 0.188369L28.4468 0.0926108C28.0947 0.0738968 28.0947 0.247691 27.7184 0.624135L27.4355 0.914356C27.6117 1.11809 27.7184 1.38532 27.7184 1.67829V4.8889C27.7184 4.8889 29.7784 2.79476 30.2222 2.34445C30.4354 2.12818 30.2222 1.89414 30.2222 1.89414Z"
        fillOpacity="0.85"
      />
      <path
        d="M2.51422 29.9737V25.9969L0.0436878 28.6803C0.0436878 28.6803 -0.0546097 28.8386 0.0436878 28.9758C0.141985 29.1129 1.72081 30.6398 1.72081 30.6398C1.72081 30.6398 1.71963 30.6419 1.77778 30.6968C1.83592 30.7517 2.09514 30.7388 2.16466 30.6398C2.23418 30.5408 2.5483 30.2562 2.5483 30.2562C2.52603 30.1658 2.51422 30.0712 2.51422 29.9737Z"
        fillOpacity="0.85"
      />
    </g>
  </svg>
);

const PublishUnpublishIcon = ({
  status = TemplateStatus.UNPUBLISHED,
  ...props
}: Partial<CustomIconComponentProps> & {
  status?: TemplateStatus.PUBLISHED | TemplateStatus.UNPUBLISHED;
}) => {
  if (status === TemplateStatus.PUBLISHED) {
    return <Icon component={UnpublishIcon} {...props} />;
  }
  return <FileDoneOutlined {...props} />;
};

export default PublishUnpublishIcon;
