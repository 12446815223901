import { CopyOutlined } from "@ant-design/icons";
import { Button, Popover } from "antd";
import { useState } from "react";
import { IAccountRecord } from "shared/types/accountManagement";

import { useIsAdmin } from "shared/hooks/useIsAdmin";
interface IDealerManageUrlsProps {
  dealerToEditWithLogos: IAccountRecord;
}

const DealerManageUrls: React.FC<IDealerManageUrlsProps> = ({
  dealerToEditWithLogos,
}) => {
  const isAdmin = useIsAdmin();

  let dealerUrl = dealerToEditWithLogos.dealerUrl;
  if (
    dealerToEditWithLogos.dealerUrl.startsWith("https://") ||
    dealerToEditWithLogos.dealerUrl.startsWith("http://")
  ) {
    dealerUrl = dealerToEditWithLogos.dealerUrl.split("://")[1];
  }
  const [copiedBool, setCopiedBool] = useState<boolean>(false);
  const widgetURL = `<script type="text/javascript" src="${process.env.REACT_APP_DOMAIN}/${process.env.REACT_APP_ENV}-assetbuilder/v1/asset-builder/web-integration-code?website=${dealerUrl}&id=default"></script>`;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        paddingTop: "5%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <b>{`Copy the code below and paste it onto every page of your website.`}</b>
        <b>{`Paste this code as high in the <head> of the page as possible:`}</b>
        <br />
        <div>
          <textarea
            style={{ width: "450px", height: "100px" }}
            className="widget-url"
            disabled={true}
            value={!isAdmin ? "" : widgetURL}
          />
        </div>
        <div>
          <Popover content={copiedBool ? "Copied!" : "Copy To Clipboard"}>
            <Button
              key="COPY"
              icon={<CopyOutlined />}
              disabled={!isAdmin}
              onClick={() => {
                navigator?.clipboard?.writeText(widgetURL);
                setCopiedBool(true);
              }}
            ></Button>
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default DealerManageUrls;
