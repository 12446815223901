import { Badge, Typography } from "antd";
import { IQCHistoryLog, QCStatus } from "shared/types/shared";
import styles from "./Log.module.scss";
import moment from "moment";
import { timeFormat } from "shared/constants/dataManagement";
import { qcStatusColorMap } from "../../QcStatusBadge";

interface IProps {
  log: IQCHistoryLog;
}

type LogProps = IProps;

const Log = ({ log }: LogProps) => {
  const updateDate = moment(new Date(log.createdAt)).format(timeFormat);
  const creatorName = `${log.firstName} ${log.lastName}`;

  return (
    <li className={styles.log}>
      <Typography>
        <p>
          <b>{updateDate}</b>
          <br />
          {creatorName}
        </p>
      </Typography>
      <Badge
        text={log.status}
        color={qcStatusColorMap[log.status || QCStatus.NOT_QCed]}
      />
    </li>
  );
};

export default Log;
