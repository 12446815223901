import { isEnvVarEquals } from "utils/helpers";
import { isValidUrl } from "utils/validators";
import { IFacebookErrorObject, IFacebookObject } from "./types";

export const createDisplayTitle = (fbObj: IFacebookObject | null) => {
  return fbObj ? `${fbObj.name} - ${fbObj.id}` : "";
};

/**
 * The IDs equate to the following accounts:
 * ["Nissan United Test","FY21_Nissan USA_Tier 2_NA_East-NNE-NSE-NSC", "FY21_Nissan USA_Tier 2_NA_West-NRW-NMW","FY21_Nissan USA_Tier 2_Constellation Test v2"]
 * */
export const nissanUsaAccountIds = [
  "1011244866072317",
  "282802576595416",
  "425581441838666",
  "466633577737905",
  "317455903780255",
  "3164283507187267",
];

export const nissanDemoPage: IFacebookObject = {
  name: "Nissan Demo 1",
  id: "103148821737095",
};

export const alexiaTestPage: IFacebookObject = {
  name: "Alexia Test 1",
  id: "114266546691696",
};

export const nissanDemoCatalog: IFacebookObject = {
  name: "Nissan Demo Catalog 1",
  id: "2918384932893",
};

export const alexiaTestCatalogs = [
  {
    name: "Testing Testing AIA",
    id: "1495298037476630",
  },
];

export const nissanDemoPixel: IFacebookObject = {
  name: "Nissan Demo Pixel 1",
  id: "29394839485739",
};

export const nissanDemoInstagram: IFacebookObject = {
  name: "Nissan Demo Instagram 1",
  id: "39394839485739",
};

export const alexiaTestInstagram: IFacebookObject = {
  name: "Alexia Test Instagram 1",
  id: "3881426661891329",
};

export const alexiaTestPixel: IFacebookObject = {
  name: "Alexia Test Pixel 1",
  id: "2881426661891329",
};

// Possible TODO: include PAGENAME, PRODUCTCATALOG
type DefaultPropName = "ACCOUNTID" | "PAGEID" | "INSTAGRAMACTORID" | "URL";

const getFallbackValue = (propName: DefaultPropName) => {
  const isNU = isEnvVarEquals("CLIENT", "nu");
  if (propName === "ACCOUNTID")
    return isNU ? "3596790690428125" : "2431879793798129";
  if (propName === "PAGEID")
    return isNU ? nissanDemoPage.id : alexiaTestPage.id;
  if (propName === "URL")
    return isNU
      ? "https://www.nissanusa.com/"
      : "https://constellationagency.com/";
  return "";
};

export const getDefaultPropValue = (propName: DefaultPropName) => {
  const envValue = process.env[`REACT_APP_AV2_ADPREVIEW_${propName}`];
  if (!envValue || ["null", "undefined"].includes(envValue)) {
    return getFallbackValue(propName);
  }
  return envValue;
};

export function buildCtaLink(destinationUrl = "", isPreview?: boolean) {
  return isPreview && !isValidUrl(destinationUrl)
    ? process.env.REACT_APP_AV2_ADPREVIEW_URL
    : destinationUrl;
}

export function buildCreativeCtaLinkValue(ctaLink = "", displayUrl = "") {
  const linkCaption = isValidUrl(displayUrl, { optionalProtocol: true })
    ? displayUrl
    : undefined;

  return {
    link_caption: linkCaption,
    link: ctaLink,
  };
}

export function getUserFacingFacebookErrorMessage(error: IFacebookErrorObject) {
  if (error.code === 6202 && error.error_subcode === 1784012)
    return "You can only create one Pixel per ad account.";
  return error.message;
}
