import { SizeType } from "antd/lib/config-provider/SizeContext";
import { TablePaginationConfig } from "antd/lib/table";

type TableSize = false | TablePaginationConfig | undefined;

export const tableProps = {
  bordered: false,
  size: "small" as SizeType,
  pagination: false as TableSize,
  columns: [
    // empty column to fit vertically aligned  with AdName column in parent Row
    { key: "empty-1", width: 240 },
    { key: "empty-2", width: 240 },
  ],
  onHeaderRow: () => ({ style: { display: "none" } }),
  onRow: () => ({ style: { background: "#ffffff" } }),
};
