import { RcFile } from "antd/lib/upload";
import API from "services";

export const getFileInfo = async (
  id: string,
  filename: string,
  attrs: string,
) => {
  const { result, error } = await API.services.salesEnablement.getFileInfo(
    id,
    filename,
    attrs,
  );
  if (error) throw error.message;

  const { type } = result ?? {};
  if (!type) throw new Error("Unable to detect template type.");
  return type;
};
export const processFileUpload = async (file: RcFile) => {
  const {
    result: { url, bucket, filename, id },
  } = await API.services.salesEnablement.getPresignedUrl(file);
  const res = await API.services.salesEnablement.upload(file, url);
  if (!res.ok) throw new Error("Failed to upload file");

  const type = await getFileInfo(id, filename, "type");

  return {
    id,
    type,
    bucket,
    filename,
  };
};

export const processPdfTemplate = async (
  id: string,
  bucket: string,
  filename: string,
) => {
  const { result, error } =
    await API.services.salesEnablement.processPdfTemplate({
      id,
      bucket,
      filename,
    });
  if (error) throw new Error(error.message);

  const { fileUrl, thumbnailUrls } = result;

  return {
    id,
    url: fileUrl,
    thumbnail: thumbnailUrls?.small,
  };
};

export const processHtmlTemplate = async (
  id: string,
  bucket: string,
  filename: string,
) => {
  const { result } = await API.services.salesEnablement.parseHtml(
    bucket,
    `${id}/${filename}`,
    id,
  );
  return {
    id,
    thumbnail: result?.thumbnailUrl,
    ...result,
  };
};

export const initiateIdmlParsing = async (id: string, key: string) => {
  const { result, error } =
    await API.services.salesEnablement.initiateIdmlParsing(id, key);
  if (error) throw new Error(error.message);

  return result;
};

export const getExecutionStatus = async (executionArn: string) => {
  const { result, error } =
    await API.services.salesEnablement.getExecutionStatus(executionArn);
  if (error) throw new Error(error.message);

  return result;
};
