import { Button, Input, Spin, Tag, Typography } from "antd";
import Drawer from "antd/lib/drawer";
import Title from "antd/lib/typography/Title";
import { useFetchDealer } from "shared/hooks/useFetchDealers";
import { AudienceIntegrationLog } from "./types";
import styles from "./AudienceIntegrationLogDetails.module.scss";
import { useMutateAudienceIntegrationLog } from "./hooks/useMutateAudienceIntegrationLog";
import { useState } from "react";

const { Text } = Typography;
const { TextArea } = Input;

type Props = {
  selectedLog: AudienceIntegrationLog;
  onClose: () => void;
};

const AudienceIntegrationLogDetails = ({ onClose, selectedLog }: Props) => {
  const { mutate: updateLog } = useMutateAudienceIntegrationLog();

  const { data: dealer, isLoading } = useFetchDealer(
    selectedLog.account.dealerName,
  );

  const [notes, setNotes] = useState(selectedLog.notes);

  const onSave = () => {
    updateLog({ id: selectedLog.id, notes });
    onClose();
  };

  const oems = dealer?.dealerOem.split(",");

  return (
    <Drawer
      title="Audience Details"
      placement="right"
      width={460}
      closable={false}
      visible
      footer={[
        [
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            disabled={notes === selectedLog.notes}
            onClick={onSave}
          >
            Save
          </Button>,
        ],
      ]}
      footerStyle={{ display: "flex", justifyContent: "end", gap: 8 }}
    >
      {isLoading ? (
        <div className={styles.loadingContainer}>
          <Spin size="large" />
        </div>
      ) : (
        <ul className={styles.contentContainer}>
          <li>
            <Title level={5}>
              Audience Name <Text type="secondary">(read only)</Text>
            </Title>
            <Text>{selectedLog.audience.name}</Text>
          </li>
          <li>
            <Title level={5}>
              OEM <Text type="secondary">(read only)</Text>
            </Title>
            <div>
              {oems?.map((oem, index) => (
                <Tag key={index}>{oem}</Tag>
              ))}
            </div>
          </li>
          <li>
            <Title level={5}>Notes</Title>
            <TextArea
              value={notes}
              onChange={event => setNotes(event.target.value)}
              rows={4}
              placeholder="Enter notes"
            />
          </li>
        </ul>
      )}
    </Drawer>
  );
};

export default AudienceIntegrationLogDetails;
