import { Tooltip } from "antd";
import { AbstractTooltipProps, RenderFunction } from "antd/lib/tooltip";
import { useQPLayout } from "./useQPLayout";

interface Props extends AbstractTooltipProps {
  action: string;
  allowed: boolean;
  enabled: boolean;
  title?: React.ReactNode | RenderFunction;
}

export function TooltipPerms({
  action,
  allowed,
  enabled,
  title,
  ...props
}: Props) {
  const custom = useTitle({ action, allowed, enabled });

  return <Tooltip {...props} title={title ?? custom} />;
}

function useTitle({ enabled, allowed, action }: Partial<Props>) {
  const [layout] = useQPLayout();
  const items = layout === "table" ? "rows" : "cards";

  if (!allowed) {
    return "View access only, please contact your Administrator";
  }

  if (!enabled) {
    return `Select ${items} to ${action}`;
  }

  return action;
}
