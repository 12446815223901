import { Form, Input, Tooltip } from "antd";
import styles from "./DetailsTab.module.scss";
import { ChangeEventHandler, RefObject, forwardRef } from "react";
import { LabelTooltipType } from "antd/lib/form/FormItemLabel";

interface FormInputProps {
  name: string;
  label: string;
  value: string | number | readonly string[] | undefined;
  key: string;
  disabled: boolean;
  onChange: ChangeEventHandler<HTMLInputElement> | undefined;
  validateStatus: "success" | "error" | "validating" | undefined;
  hasFeedback: boolean | undefined;
  feedbackMessage?: string;
  tooltip?: LabelTooltipType;
  dataCy?: string;
  required?: boolean;
  ref?: RefObject<Input>;
  inputTooltip?: string;
  maxLength?: number;
}

const FormInput = forwardRef<Input, FormInputProps>((props, ref) => {
  const {
    required,
    name,
    label,
    hasFeedback,
    feedbackMessage,
    tooltip,
    dataCy,
    key,
    disabled,
    value,
    onChange,
    validateStatus,
    inputTooltip,
    maxLength,
  } = props;

  return (
    <Form.Item
      key={`item-${key}`}
      name={name}
      className={styles.formItem}
      required={required}
      label={label}
      validateStatus={validateStatus}
      hasFeedback={hasFeedback}
      help={feedbackMessage}
      colon={false}
      labelAlign="left"
      tooltip={tooltip}
      preserve={false}
      rules={[
        {
          required: true,
          validator: (_, val) =>
            val !== undefined && val.trim() !== ""
              ? Promise.resolve()
              : Promise.reject(`Please enter ${label.toLowerCase()}`),
        },
      ]}
    >
      <Tooltip title={inputTooltip} visible={disabled ? undefined : false}>
        <div>
          <Input
            data-cy={dataCy}
            ref={ref}
            key={key}
            disabled={disabled}
            value={value}
            onChange={onChange}
            maxLength={maxLength}
            required={required}
          />
        </div>
      </Tooltip>
    </Form.Item>
  );
});

FormInput.displayName = "FormInput";

export default FormInput;
