import { useDownloadAssetMutation } from "redux/media/media.api";
import { notification } from "antd";

export const useDownloadAsset = () => {
  const [downloadAsset] = useDownloadAssetMutation();
  return async ({ id, filename }: WDAsset) => {
    try {
      const data = await downloadAsset(id).unwrap();
      const link = document.createElement("a");
      link.target = "_blank";
      link.download = filename;
      link.href = data;
      link.click();
      notification.success({
        message: `Downloaded ${filename}`,
      });
    } catch (error: any) {
      notification.error({
        message: `Download Failed ${filename}`,
        description: error?.data?.message,
        duration: 0,
      });
    }
  };
};
