/* eslint-disable react/display-name */
import { FileUnknownOutlined, FolderOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useMediaActions } from "redux/media/media.slice";
import { MediaThumb } from "../../MediaThumb";
import { Cell, useColumn } from "../../table";
import { useQPBreadcrumb } from "../../useQPBreadcrumb";
import styles from "./useColName.module.scss";

export const useColName = () => {
  return useColumn("name", {
    renderFn: useRenderFn(),
    title: "Name",
    editable: true,
    fixed: "left",
    onCell: (record: WDResource) => ({
      record: record,
      editable: true,
      dataIndex: "name",
      title: "name",
    }),
  });
};

const useRenderFn = () => {
  const { toFolder } = useQPBreadcrumb();
  const { setAssetsToEdit } = useMediaActions();

  return (text: string, record: WDAsset | WDFolder) => {
    const { type, id, name } = record;

    switch (type) {
      case "folder": {
        return (
          <Link to={{ search: toFolder(id) }}>
            <Cell>
              <FolderOutlined className={styles.folder} />
              <span>{name}</span>
            </Cell>
          </Link>
        );
      }

      case "asset": {
        return (
          <Cell>
            <MediaThumb asset={record} />
            <div
              className={styles.title}
              onClick={() => setAssetsToEdit([record])}
            >
              {name}
            </div>
          </Cell>
        );
      }

      default: {
        return (
          <Cell>
            <FileUnknownOutlined className={styles.folder} />
            <span>{name}</span>
          </Cell>
        );
      }
    }
  };
};
