import { LoadingOutlined } from "@ant-design/icons";
import { Empty } from "antd";
import { memo } from "react";

const LoadingContent = () => {
  return (
    <Empty
      image={<LoadingOutlined spin />}
      description="Loading..."
      imageStyle={{
        fontSize: 35,
        height: 43,
        marginBottom: 8,
      }}
    />
  );
};

export default memo(LoadingContent);
