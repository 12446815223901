import { pick } from "lodash";
import { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { getCampaignDateRange } from "screens/adLibrary/adLoad/adLoadDrawer/shared/utils";
import { returnUpdatedRuleOptions } from "screens/adLibrary/adLoad/utils";
import { useFetchCampaigns } from "shared/hooks/adLibrary/facebook/useFetchCampaigns";
import {
  AdLoadRuleQualifier,
  IAdLibraryState,
  RulesetOptionsDictionary,
} from "shared/types/adLibrary";

const qualifierOptions: AdLoadRuleQualifier[] = [
  "Contains",
  "Doesn't Contain",
  "Equals",
  "Doesn't Equal",
];

export const useAdRuleOptions = () => {
  const { adLoadParameters, adLoadDestination, selectedAccounts } = useSelector(
    ({ adLibrary }: { adLibrary: IAdLibraryState }) => ({
      adLoadParameters: adLibrary.adLoad.adLoadParameters,
      adLoadDestination: adLibrary.adLoad.destination,
      selectedAccounts: adLibrary.selectedFacebookAccounts,
    }),
    shallowEqual,
  );

  const { data: campaigns, isLoading } = useFetchCampaigns({
    limit: 50,
    includeAds: true,
    includeAdsets: true,
    pullDataViaPagination: true,
    startTimes: getCampaignDateRange(),
    adAccountIds: selectedAccounts?.map(account => account.account_id) ?? [],
  });

  // The options and values available for the rules should
  // be determined by the campaigns available. where data
  // is filtered based on taxonomy matches
  const adRuleOptions = useMemo(() => {
    const checkedAdIds =
      adLoadDestination?.selectedAds?.map(({ id }) => id) ?? [];

    const checkedAdsRulesets = pick(adLoadParameters?.rulesets, checkedAdIds);

    let ruleOptions: RulesetOptionsDictionary = {
      campaign: {
        parameter: [],
        qualifier: qualifierOptions,
        values: {},
      },
      adset: {
        parameter: [],
        qualifier: qualifierOptions,
        values: {},
      },
      ad: {
        parameter: [],
        qualifier: qualifierOptions,
        values: {},
      },
    };

    if (
      !campaigns ||
      !adLoadParameters?.taxonomies ||
      !adLoadParameters.delimiters
    ) {
      return ruleOptions;
    }

    for (const campaign of campaigns) {
      const { name: campaignName, adsets } = campaign;

      if (!campaignName) {
        continue;
      }

      ruleOptions = returnUpdatedRuleOptions({
        ruleOptions,
        adPart: "campaign",
        name: campaignName,
        delimiters: adLoadParameters.delimiters,
        taxonomies: adLoadParameters?.taxonomies,
      });

      const selectedCampaignParameters = Object.values(
        checkedAdsRulesets,
      ).flatMap(ruleset => {
        return ruleset?.campaign.map(ruleset => ruleset.parameter) ?? [];
      });

      ruleOptions.campaign.parameter = ruleOptions.campaign.parameter.filter(
        parameter => !selectedCampaignParameters.includes(parameter),
      );

      if (!adsets) {
        continue;
      }

      for (const adset of adsets.data) {
        const { name: adsetName, ads } = adset;
        if (!adsetName) {
          continue;
        }

        ruleOptions = returnUpdatedRuleOptions({
          ruleOptions,
          adPart: "adset",
          name: adsetName,
          delimiters: adLoadParameters.delimiters,
          taxonomies: adLoadParameters?.taxonomies,
        });

        const selectedAdSetParameters = Object.values(
          checkedAdsRulesets,
        ).flatMap(ruleset => {
          return ruleset?.adset.map(ruleset => ruleset.parameter) ?? [];
        });

        ruleOptions.adset.parameter = ruleOptions.adset.parameter.filter(
          parameter => !selectedAdSetParameters.includes(parameter),
        );

        if (!ads) {
          continue;
        }

        for (const ad of ads.data) {
          const { name: adName } = ad;
          if (!adName) {
            continue;
          }

          ruleOptions = returnUpdatedRuleOptions({
            ruleOptions,
            adPart: "ad",
            name: adName,
            taxonomies: adLoadParameters?.taxonomies,
            delimiters: adLoadParameters.delimiters,
          });

          const selectedAdParameters = Object.values(
            checkedAdsRulesets,
          ).flatMap(ruleset => {
            return ruleset?.ad.map(ruleset => ruleset.parameter) ?? [];
          });

          ruleOptions.ad.parameter = ruleOptions.ad.parameter.filter(
            parameter => !selectedAdParameters.includes(parameter),
          );
        }
      }
    }

    return ruleOptions;
  }, [
    adLoadDestination?.selectedAds,
    adLoadParameters?.delimiters,
    adLoadParameters?.rulesets,
    adLoadParameters?.taxonomies,
    campaigns,
  ]);

  return { adRuleOptions, isLoadingOptions: isLoading };
};
