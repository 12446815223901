import { Key, useMemo } from "react";
import styles from "./FilterSection.module.scss";
import { Button, Divider, Tag } from "antd";
import {
  FiltersType,
  TemplateListColumn,
} from "screens/assetExporter/feedConfigurationV2/shared/types";
import { FilterValue } from "antd/lib/table/interface";

const FilterTitleMap: Record<TemplateListColumn, string> = {
  name: "Name",
  status: "Status",
  assetType: "Asset type",
  type: "Template type",
  dimension: "Dimensions",
  artboardName: "Artboard name",
  stores: "Stores",
  oems: "Oems",
  createdAt: "Created",
  lastUpdatedAt: "Last edited",
  stamps: "# Stamps",
  tags: "Tags",
};
type Props<D> = {
  filters: Partial<FiltersType>;
  currentDataSource: D[];
  dataSource: D[];
  showFilteredCount: boolean;
  onRemove: (filterKey: TemplateListColumn, value: boolean | Key) => void;
  onClear: () => void;
};
const FilterSection = <D = any,>(props: Props<D>) => {
  const filteredCount = props.currentDataSource.length;
  const totalCount = props.dataSource.length;
  const countStr = useMemo(() => {
    return props.showFilteredCount
      ? `${filteredCount} / ${totalCount} items`
      : `${totalCount} items`;
  }, [filteredCount, totalCount, props.showFilteredCount]);
  const filterKeys = useMemo(
    () => Object.keys(props.filters) as TemplateListColumn[],
    [props.filters],
  );

  const tags = useMemo(() => {
    const filteredKeys = filterKeys.filter(key => props.filters[key]);

    return (
      <>
        {filteredKeys.map(key => {
          const filterValues = props.filters[key] as FilterValue;

          return (
            <Tags
              key={key}
              templateKey={key}
              filterValues={filterValues}
              props={props}
            />
          );
        })}
        {filteredKeys.length > 0 && (
          <Button type="link" onClick={props.onClear}>
            Clear all
          </Button>
        )}
      </>
    );
  }, [filterKeys, props]);

  return (
    <div className={styles.container}>
      <div>{countStr}</div>
      <Divider type="vertical" />
      <div className={styles.filters}>
        <span>Filters</span>
        {tags}
      </div>
    </div>
  );
};

export default FilterSection;

const Tags = <D = any,>({
  filterValues,
  templateKey,
  props,
}: {
  filterValues: FilterValue;
  templateKey: TemplateListColumn;
  props: Props<D>;
}) => (
  <div>
    {filterValues.map(value => (
      <Tag
        key={`${templateKey}-${value}`}
        closable
        color="blue"
        onClose={() => props.onRemove(templateKey, value)}
      >
        {FilterTitleMap[templateKey]}: {value}
      </Tag>
    ))}
  </div>
);
