import API from "../../../services";
import { useQuery } from "react-query";
import { IGetDashboardSettingsResponse } from "../../types/dashboardManagement";
import { queryKeys } from "./queryKeys";

const getDashboardSettings = (user: string) => async () => {
  const { result, error } =
    await API.services.dashboardManagement.getDashboardSettings(user);

  if (error) {
    throw Error(error.message);
  }

  return result;
};

export const useFetchDashboardSettings = (
  enabled: boolean,
  user: string,
  onSuccess?: (
    data: IGetDashboardSettingsResponse["result"],
  ) => void | undefined,
) => {
  return useQuery<IGetDashboardSettingsResponse["result"], Error>(
    [queryKeys.dashboardSettings, user],
    getDashboardSettings(user),
    {
      onSuccess,
      enabled,
    },
  );
};
