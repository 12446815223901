import { Drawer, Modal, Typography } from "antd";
import { useMemo } from "react";
import { MediaLogos } from "shared/components/MediaLogos";
import { OperationMode } from "shared/types/inputValues";
import { IBrandRecord } from "shared/types/brandManagement";
import { MediaOemForm } from "./MediaOemForm";
import { getOemAndEventLogosPayload } from "./utils";

type Props = {
  indTypeAuto: boolean;
  allowDeleteOem: boolean;
  allowDeleteOemImage: boolean;
  oems: IBrandRecord[];
  isProcessing: boolean;
  showAddOemDrawer: boolean;
  oemToEditWithLogos: IBrandRecord;
  onDeleteOem: () => void;
  onCreateOem: (name: string) => void;
  onUpdateOem: (inputOem: IBrandRecord) => void;
  onChangeOem: (oemName: string) => void;
  toggleAddOemDrawer: (
    show: boolean,
    mode: OperationMode,
    oemNameSelect?: string,
  ) => void;
};

export const MediaOemDrawer = ({
  indTypeAuto,
  oems,
  isProcessing,
  showAddOemDrawer,
  onDeleteOem,
  onCreateOem,
  oemToEditWithLogos,
  toggleAddOemDrawer,
  allowDeleteOem,
  onChangeOem,
}: Props) => {
  const handleClose = () => toggleAddOemDrawer(false, "");
  const { oemName, createdAt } = oemToEditWithLogos;
  const mode: OperationMode = createdAt ? "UPDATE" : "CREATE";
  const entityName = indTypeAuto ? "OEM Name" : "Brand Name";
  const dataName = indTypeAuto ? "OEM" : "Brand Details";
  const titleText: Record<string, string> = {
    CREATE: `Add New ${dataName}`,
    UPDATE: `Edit ${dataName}`,
  };
  const drawerId = "media-oem-drawer";

  const getCreateHandler = () => {
    if (mode === "CREATE") {
      return ({ oemName }: any) => {
        onCreateOem(oemName);
      };
    }
  };

  const getDeleteHandler = () => {
    if (mode === "UPDATE" && allowDeleteOem) {
      return () => {
        Modal.confirm({
          title: "Confirm Deletion",
          content: (
            <Typography.Text>
              {`Are you sure you sure you want to delete ${oemName}?`}
            </Typography.Text>
          ),
          cancelText: "No",
          okText: "Delete",
          okButtonProps: {
            danger: true,
          },
          onOk: onDeleteOem,
        });
      };
    }
  };

  const searchPayload = useMemo(() => {
    if (oemName) {
      return getOemAndEventLogosPayload(oemName);
    }
    return undefined;
  }, [oemName]);

  return (
    <Drawer
      destroyOnClose
      width={720}
      title={titleText[mode]}
      onClose={handleClose}
      visible={showAddOemDrawer}
      data-id={drawerId}
      footer
    >
      <MediaOemForm
        mode={mode}
        oems={oems}
        entityName={entityName}
        oemName={oemName}
        isProcessing={isProcessing}
        submitText={titleText[mode]}
        onCancel={handleClose}
        onDelete={getDeleteHandler()}
        onSubmit={getCreateHandler()}
        onChange={onChangeOem}
        drawerId={drawerId}
      />
      <MediaLogos
        searchPayload={searchPayload}
        titleText={`${dataName} And Event Logo(s)`}
      />
    </Drawer>
  );
};
