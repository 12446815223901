import React from "react";
import { Tooltip, Image } from "antd";
import MissingThumbnail from "statics/images/fileimage-warning.svg";
import { IAd } from "shared/types/adLibrary";
import { AdType } from "screens/adLibrary/facebookUtils/types";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

interface IProps {
  ad: IAd;
  name?: string;
  willHideImage?: boolean;
  willDefaultPreview?: boolean;
  textLengthToEllipsis?: number;
}

interface IHandlers {
  onImageClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onCellClick?: () => void;
}

import classNames from "classnames";
import styles from "./NameCardCell.module.scss";
import EllipsisText from "shared/components/card/EllipsisText";
import AutoEllipsisText from "shared/components/AutoEllipsisText";

type NameCardCellProps = IProps & IHandlers;

const NameCardCell = ({
  ad,
  name = "",
  willHideImage,
  onImageClick,
  onCellClick,
  willDefaultPreview,
  textLengthToEllipsis,
}: NameCardCellProps) => {
  const thumbnailUrl = ad.visuals?.thumbnail25 || ad.visuals?.thumbnail;
  const isDynamicAd = [AdType.AIA, AdType.DPA, AdType.FTA].includes(ad.type);
  const title = isDynamicAd
    ? `${ad.type} ads have no image preview`
    : thumbnailUrl
    ? "Preview image"
    : "Missing image. Click to edit";
  return (
    <div
      className={styles.flexCenter}
      style={{ cursor: !!onCellClick ? "pointer" : "default" }}
    >
      {!willHideImage && (
        <Tooltip title={title}>
          <Image
            className={styles.img}
            preview={{
              visible: willDefaultPreview ? undefined : !onImageClick,
              mask:
                !isDynamicAd && thumbnailUrl ? (
                  <EyeOutlined />
                ) : (
                  <EyeInvisibleOutlined />
                ),
            }}
            onClick={!isDynamicAd && thumbnailUrl ? onImageClick : onCellClick}
            src={thumbnailUrl || MissingThumbnail}
          />
        </Tooltip>
      )}
      <div
        className={classNames({
          [styles.text]: !willHideImage,
        })}
        onClick={onCellClick}
      >
        {textLengthToEllipsis ? (
          <EllipsisText
            text={name}
            charDisplayLimit={textLengthToEllipsis}
            className={classNames({ [styles.clickable]: !!onCellClick })}
          />
        ) : (
          <AutoEllipsisText
            className={classNames({ [styles.clickable]: !!onCellClick })}
          >
            {name}
          </AutoEllipsisText>
        )}
      </div>
    </div>
  );
};

export default NameCardCell;
