import { IFacebookAd } from "screens/adLibrary/facebookUtils/types";
import { CardDimensions } from "./types";

export const getThumbnailFromCreative = (ad?: IFacebookAd) => {
  const { creative, image_url } = ad || {};
  const { object_story_spec, thumbnail_url } = creative || {};
  const { link_data, video_data } = object_story_spec || {};
  return (
    link_data?.picture ||
    video_data?.image_url ||
    thumbnail_url ||
    image_url ||
    ""
  );
};

const widthRegex = /width\=\"\d+\"/gm;
const heightRegex = /height\=\"\d+\"/gm;
const styleRegex = /style=\"([a-zA-Z]|\W)+\"/gm;
const scrollingRegex = /scrolling=\"([a-zA-Z]|\W)+\"\s/gm;

type AdjustIframeArgs = {
  iframe: string;
  offset?: number;
  enableScroll?: boolean;
  dimensions?: CardDimensions;
  interactive?: boolean;
};
export const adjustIframe = ({
  iframe,
  offset = 0,
  dimensions,
  enableScroll,
  interactive = true,
}: AdjustIframeArgs) => {
  if (!dimensions) return iframe;

  const iframeWithNewDims = iframe
    .replace(widthRegex, `width="${dimensions.width}"`)
    .replace(heightRegex, `height="${dimensions.height - offset}"`)
    .replace(
      styleRegex,
      `style="border: none; border-radius: 0px 0px 12px 12px; pointer-events: ${
        interactive ? "auto" : "none"
      };"`,
    )
    .replace(scrollingRegex, `scrolling=${enableScroll ? "yes" : "no"} `);

  return iframeWithNewDims;
};

const supportDOMParser = () => {
  if (!window.DOMParser) return false;
  const parser = new DOMParser();
  try {
    parser.parseFromString("x", "text/html");
  } catch (err) {
    return false;
  }
  return true;
};

const stringToHTML = (value: string) => {
  if (supportDOMParser()) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(value, "text/html");
    return doc.body;
  }
  const element = document.createElement("div");
  element.innerHTML = value;
  return element;
};

export const getIframeDimensions = (iframe: string) => {
  const { firstElementChild } = stringToHTML(iframe);
  return {
    height: parseInt(firstElementChild?.getAttribute("height") || "0"),
    width: parseInt(firstElementChild?.getAttribute("width") || "0"),
  };
};
