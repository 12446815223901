import { useMemo } from "react";
import { useFetchInfiniteDealers } from "shared/hooks/useFetchDealers";
import { useCurrentSession } from "./useCurrentSession";
import { sortBy } from "lodash";

export const useAdLoadSelectedStores = () => {
  const { session } = useCurrentSession();
  const { selectedStoreNames } = session.data;
  const { dealers: stores } = useFetchInfiniteDealers();

  const selectedStores = useMemo(
    () =>
      sortBy(
        stores?.filter(store => selectedStoreNames.includes(store.dealer_name)),
        store => selectedStoreNames.indexOf(store.dealer_name),
      ),
    [stores, selectedStoreNames],
  );

  return { selectedStores };
};
