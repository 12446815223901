import { RecordStatus } from "shared/types/assetExporter";

export const getVersionColor = (status?: RecordStatus) => {
  if (status === "FAILED") return "red";
  if (status === "SUCCEEDED") return "green";
  return "blue"; // is currently running
};

export const getVersionStatus = (status?: RecordStatus) => {
  if (status === "FAILED") return "Failed";
  if (status === "SUCCEEDED") return "Processed";
  return "Processing"; // is currently running
};

export const createLinkAndRoute = (url: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.rel = "noopener noreferrer";
  link.click();
};
