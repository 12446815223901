import { Form, Input, Button, Alert } from "antd";
import SingleAccountSelect from "shared/components/SingleAccountSelect";
import { terms } from "utils/terms";
import { GenerateUrlFormValues, eaBaseUrl } from "../GenerateUrlDrawer";
import { EverythingAdHtml } from "shared/types/adLibrary";
import {
  useCreateEverythingAdsHtml,
  useGetIsEverythingAdHtmlSlugAvailable,
} from "shared/hooks/everythingAdHtml/useEverythingAdsHtml";
import { useFetchInstantExperiences } from "shared/hooks/adLibrary/useFetchInstantExperiences";
import { useFetchInfiniteDealers } from "shared/hooks/useFetchDealers";
import { useUser } from "shared/hooks/useUser";
import { getEverythingAdWithDests } from "./GenerateUrlForm.utils";
import { useState } from "react";
import uuid from "uuid";
import { useDebounce } from "shared/hooks/useDebounce";
import styles from "./GenerateUrlForm.module.scss";

type Props = {
  selectedInstExpId: string;
};

const GenerateUrlForm = ({ selectedInstExpId }: Props) => {
  const [slug, setSlug] = useState(uuid.v4());
  const debouncedSlug = useDebounce(slug, 300);

  const {
    mutate: createEverythingAd,
    data: createdEverythingAd,
    error,
    reset,
    isLoading: isCreatingEverythingAd,
  } = useCreateEverythingAdsHtml();

  const { data: validation, isLoading: isValidating } =
    useGetIsEverythingAdHtmlSlugAvailable({ slug: debouncedSlug });

  const isCreatedSlug =
    createdEverythingAd && debouncedSlug === createdEverythingAd?.slug;

  const isUnavailableSlug = validation?.isAvailable === false && !isCreatedSlug;

  const { data: instantExperiences } = useFetchInstantExperiences();
  const { dealers } = useFetchInfiniteDealers();
  const user = useUser();

  const instExp = instantExperiences?.find(ie => ie.id === selectedInstExpId);
  if (!instExp || !instantExperiences) return null;

  const onFinish = (values: GenerateUrlFormValues) => {
    if (isCreatedSlug) return;
    reset();

    const store = dealers.find(dealer => dealer.dealer_name === values.store);

    const everythingAdHtml: EverythingAdHtml = {
      slug: values.slug,
      createdAt: new Date().toISOString(),
      createdBy: user?.email ?? "",
      environment: process.env.REACT_APP_ENV ?? "",
      instance: process.env.REACT_APP_AV2_CLIENT ?? "",
      data: getEverythingAdWithDests({
        instExp,
        instantExperiences,
        urlLabels: store?.labels ?? [],
      }),
      account: store?.dealer_name ?? "",
      gtmId: values.gtmId ? `GTM-${values.gtmId}` : undefined,
    };

    createEverythingAd(everythingAdHtml);
  };

  const getValidateStatus = () => {
    if (isValidating) return "validating";
    if (isUnavailableSlug) return "error";
    return undefined;
  };

  const initialValues: GenerateUrlFormValues = {
    store: "",
    slug: debouncedSlug,
    gtmId: "",
  };

  return (
    <Form<GenerateUrlFormValues>
      name="generate-ea-html"
      layout="vertical"
      initialValues={initialValues}
      autoComplete="off"
      onFinish={onFinish}
      requiredMark="optional"
      className={styles.generateUrlForm}
    >
      <Form.Item
        className={styles.generateUrlFormItem}
        name="store"
        label={terms.accountName}
        rules={[
          {
            required: true,
            message: `Please select a ${terms.account}`,
          },
        ]}
        tooltip={`Select a ${terms.account}, it will be used to populate the url labels`}
      >
        <SingleAccountSelect
          style={{
            width: "100%",
          }}
        />
      </Form.Item>
      <Form.Item
        className={styles.generateUrlFormItem}
        name="gtmId"
        label="GTM ID"
        rules={[
          {
            pattern: /^[A-Z0-9]{1,10}$/,
            message: "Invalid GTM ID",
          },
        ]}
      >
        <Input addonBefore="GTM-" placeholder="Container ID" />
      </Form.Item>
      <Form.Item
        className={styles.generateUrlFormSlugItem}
        name="slug"
        label="URL slug"
        validateStatus={getValidateStatus()}
        hasFeedback
        help={isUnavailableSlug ? "This URL slug is already in use" : undefined}
        rules={[
          {
            required: true,
            message: "Please enter a URL slug",
          },
          {
            pattern: /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
            message:
              "Invalid URL slug, it can only contain lowercase letters, numbers and dashes",
          },
        ]}
      >
        <Input
          addonBefore={eaBaseUrl}
          onChange={event => setSlug(event.target.value)}
        />
      </Form.Item>
      <Form.Item>
        <Button
          disabled={isValidating || isCreatedSlug}
          loading={isCreatingEverythingAd}
          type="primary"
          htmlType="submit"
        >
          Generate URL
        </Button>
      </Form.Item>
      {error && (
        <Alert
          message={error.message || "Something went wrong"}
          type="error"
          showIcon
          closable
          onClose={reset}
        />
      )}
    </Form>
  );
};
export default GenerateUrlForm;
