import { FC, memo, useMemo, useState } from "react";
import { Collapse, Empty, Spin } from "antd";

import RenderTemplateProviderV2 from "shared/components/contextAPI/shared/RenderTemplate";
import Preview from "../editor/canvas/Preview";
import TemplateCardListContainer from "./templateList/TemplateCardListContainer";

import { shallowEqual, useSelector } from "react-redux";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import actions from "../../../redux/rootActions";

import { orderBy } from "lodash";

import {
  HeaderMenu,
  IDesignStudioState,
  ITemplate,
  TabMenu,
  AllPublishedStatus,
  customFabricAttributes,
  TTemplateType,
  MediaFilterType,
} from "shared/types/designStudio";
import { IConfigurationState } from "shared/types/configuration";

import "./TemplateList.scss";
import { useFetchTemplates } from "shared/hooks/useFetchTemplates";

interface ITemplateList {
  selectedTab: TabMenu;
  selectedHeader: HeaderMenu;
  activeKey: string;
  selectedTemplateType?: TTemplateType;
  selectedMediaType?: MediaFilterType;

  setActiveKey: (input: string) => void;
}

const TemplateList: FC<ITemplateList> = ({
  activeKey,
  setActiveKey,
  selectedHeader,
  selectedTab,
  ...props
}) => {
  const {
    config,
    fetchingData,
    selectedFilterOem,
    selectedFilterStore,
    selectedFilterTags,
    selectedFilterOfferCounts,
    uniqueUUID,
    searchInput,
    templatesAllPublishedUnpublished: selectedFilterStatus,
    paginationKey,
    selectedFilterDimensionsTemplate,
  } = useSelector(
    ({
      designStudio,
      configuration,
    }: {
      designStudio: IDesignStudioState;
      configuration: IConfigurationState;
    }) => {
      const {
        fetchingData,
        templates,
        selectedFilterOem,
        selectedFilterStore,
        selectedFilterTags,
        selectedFilterOfferCounts,
        uniqueUUID,
        searchInput,
        editingArtboardStatus,
        templatesAllPublishedUnpublished,
        updatingData,
        paginationKey,
        selectedFilterDimensionsTemplate,
      } = designStudio;
      const { config } = configuration;
      return {
        config,
        fetchingData,
        templates,
        selectedFilterOem,
        selectedFilterStore,
        selectedFilterTags,
        selectedFilterOfferCounts,
        uniqueUUID,
        searchInput,
        editingArtboardStatus,
        templatesAllPublishedUnpublished,
        updatingData,
        paginationKey,
        selectedFilterDimensionsTemplate,
      };
    },
    shallowEqual,
  );

  const dispatch = useAppDispatch();
  const { setTemplateToEditor } = actions.designStudio;

  const showDeleted = selectedHeader === "DELETED ASSETS";
  const { templates, isFetching: isFetchingTemplates } = useFetchTemplates({
    isDeleted: showDeleted,
  });

  // determine whether to include curremt template into the list or not.
  const shouldIncludeTemplate = ({
    template,
    oemFilters,
    storeFilters,
    tagFilters,
    offerCountFilter,
    statusFilter,
    deletedIds,
    selectedTemplateType,
    selectedMediaType,
    selectedFilterDimensionsTemplate,
  }: {
    template: ITemplate;
    oemFilters: string[] | null;
    storeFilters: string[] | null;
    tagFilters: string[] | null;
    offerCountFilter: string;
    statusFilter: AllPublishedStatus;
    deletedIds: string[];
    selectedTemplateType: ITemplateList["selectedTemplateType"];
    selectedMediaType: ITemplateList["selectedMediaType"];
    selectedFilterDimensionsTemplate?: {
      width: number | null;
      height: number | null;
    };
  }): boolean => {
    const applyOemFilter = !!oemFilters?.length;
    const applyStoreFilter = !!storeFilters?.length;
    const applyTagFilter = !!tagFilters?.length;
    const applyOfferCountFilter = offerCountFilter !== "ALL";
    const applyStatusFilter = statusFilter !== "ALL";
    const applyTemplateTypeFilter = !!selectedTemplateType; // undefined indicates include all
    const applyMediaTypeFilter = !!selectedMediaType;

    if (
      !applyOemFilter &&
      !applyStoreFilter &&
      !applyTagFilter &&
      !applyOfferCountFilter &&
      !applyStatusFilter &&
      !applyTemplateTypeFilter &&
      !applyMediaTypeFilter &&
      !selectedFilterDimensionsTemplate
    )
      return true; // none of the filters are selected.

    const shouldIncludeByDeletion = !deletedIds.includes(template.id || "-");
    const shouldIncludeByOem = applyOemFilter
      ? oemFilters!.filter(filteredOem => template.oems.includes(filteredOem))
          .length > 0
      : true;

    const shouldIncludeByStore = applyStoreFilter
      ? storeFilters!.filter(filteredStore =>
          template.stores.includes(filteredStore),
        ).length > 0
      : true;

    const shouldIncludeByTag = applyTagFilter
      ? tagFilters!.every(filteredTag => template.tags.includes(filteredTag))
      : true;

    const shouldIncludeByOfferCount = applyOfferCountFilter
      ? parseInt(offerCountFilter) === template.numOfStamps
      : true;

    const shouldIncludeByStatus = applyStatusFilter
      ? template.status === statusFilter
      : true;

    const shouldIncludeByTemplateType = applyTemplateTypeFilter
      ? template.type === selectedTemplateType ||
        (!template.type && selectedTemplateType === "carcut") // existing template without type assigned is assumed to be carcut type
      : true; // it only comes here when applyTemplateTypeFilter is either null/undefined, so don't need to worry about other template.

    const includesVideo = ["mp4", "gif"].includes(
      template?.mediaType || "image",
    );

    let shouldIncludeByMediaType = true;

    switch (selectedMediaType) {
      case "video":
        shouldIncludeByMediaType = includesVideo;
        break;
      case "image":
        shouldIncludeByMediaType = !includesVideo;
        break;
      default:
        break;
    }

    let shouldIncludeByWidthAndHeight;
    const { width, height } = selectedFilterDimensionsTemplate || {
      width: null,
      height: null,
    };

    if (width && height) {
      shouldIncludeByWidthAndHeight =
        template.artboard.width === width &&
        template.artboard.height === height;
    } else if (width) {
      shouldIncludeByWidthAndHeight = template.artboard.width === width;
    } else if (height) {
      shouldIncludeByWidthAndHeight = template.artboard.height === height;
    } else {
      shouldIncludeByWidthAndHeight = true;
    }

    return (
      shouldIncludeByDeletion &&
      shouldIncludeByOem &&
      shouldIncludeByStore &&
      shouldIncludeByTag &&
      shouldIncludeByOfferCount &&
      shouldIncludeByStatus &&
      shouldIncludeByTemplateType &&
      shouldIncludeByMediaType &&
      shouldIncludeByWidthAndHeight
    );
  };

  const [deletedTemplateIds, setDeletedTemplateIds] = useState<string[]>([]);
  const addDeletedTemplateIds = (id: string) =>
    setDeletedTemplateIds([...deletedTemplateIds, id]);
  const templatesByAssetType: Record<string, ITemplate[]> = useMemo(() => {
    const filteredBySearchInput = searchInput.trim()
      ? templates.filter(template =>
          template.name.toLowerCase().includes(searchInput.toLowerCase()),
        )
      : templates;
    return filteredBySearchInput.reduce((obj, template) => {
      const shouldInclude = shouldIncludeTemplate({
        template,
        oemFilters: selectedFilterOem,
        storeFilters: selectedFilterStore,
        tagFilters: selectedFilterTags,
        offerCountFilter: selectedFilterOfferCounts,
        statusFilter: selectedFilterStatus,
        selectedTemplateType: props.selectedTemplateType,
        selectedMediaType: props.selectedMediaType,
        selectedFilterDimensionsTemplate,
        deletedIds: deletedTemplateIds,
      });

      if (shouldInclude) {
        if (obj[template.assetType]) {
          obj[template.assetType] = [...obj[template.assetType], template];
        } else if (!obj[template.assetType]) {
          obj[template.assetType] = [template];
        }
      }

      return obj;
    }, {} as Record<string, ITemplate[]>);
  }, [
    templates,
    selectedFilterOem,
    selectedFilterStore,
    selectedFilterTags,
    selectedFilterOfferCounts,
    selectedFilterStatus,
    props.selectedTemplateType,
    props.selectedMediaType,
    searchInput,
    selectedFilterDimensionsTemplate,
    deletedTemplateIds,
  ]);

  const isTemplatesEmpty = !isFetchingTemplates && templates?.length === 0;
  const isTemplatesByAssetTypeEmpty =
    !isTemplatesEmpty && !Object.keys(templatesByAssetType)?.length;
  const [artboardActiveKey, setArtboardActiveKey] = useState("");
  const [previewTemplateId, setPreviewTemplateId] = useState<string>("");
  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null);

  return (
    <>
      {previewTemplateId && (
        <RenderTemplateProviderV2 config={config}>
          <Preview
            canvas={canvas}
            openPreview={!!previewTemplateId}
            onPreviewClose={() => {
              setPreviewTemplateId("");
              dispatch(setTemplateToEditor(null));
              setCanvas(null);
            }}
            isDarkBackground={true}
            customAttributes={customFabricAttributes}
            variableAlias={{}}
          />
        </RenderTemplateProviderV2>
      )}

      {isFetchingTemplates ? (
        <div className="loader">
          <Spin className="spinner" size="large" />
        </div>
      ) : isTemplatesEmpty ? (
        <Empty className="empty" image={Empty.PRESENTED_IMAGE_DEFAULT} />
      ) : isTemplatesByAssetTypeEmpty ? (
        <Empty
          className="empty"
          description="No Templates Found"
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      ) : (
        <Collapse
          className="collapse-container template-list-collapse-container"
          activeKey={activeKey}
        >
          {Object.keys(templatesByAssetType).map(assetType => {
            const groupedTemplates = templatesByAssetType[assetType];

            if (!groupedTemplates) {
              return null;
            }

            // templates need to be sorted by artboard.name, height and width.
            const byArtboards = groupedTemplates.reduce((acc, template) => {
              const { artboard } = template;
              const artboardKey = `${artboard.name}`;

              if (acc[artboardKey]) {
                acc[artboardKey] = [...acc[artboardKey], template];
              } else {
                acc[artboardKey] = [template];
              }

              return acc;
            }, {} as Record<string, ITemplate[]>);

            const sortedArtboardKeys = orderBy(
              Object.keys(byArtboards),
              key => key,
            );

            const numberOfTemplates = groupedTemplates.length;

            return (
              <Collapse.Panel
                key={`template-list-panel-${assetType}`}
                header={
                  <div
                    className={`template-list-collapse ${assetType}`}
                    onClick={() => {
                      activeKey === `template-list-panel-${assetType}`
                        ? setActiveKey("")
                        : setActiveKey(`template-list-panel-${assetType}`);
                    }}
                  >
                    <span className="title">{assetType}</span>
                    <span className="number-counter">{numberOfTemplates}</span>
                    <Spin
                      spinning={!!paginationKey}
                      style={{ marginLeft: "1em" }}
                    />
                  </div>
                }
              >
                {sortedArtboardKeys.map(artboardKey => {
                  return (
                    <Collapse
                      className={`collapse-artboard-container template-list-artboard-collapse-container ${artboardKey.replace(
                        /\s+/g,
                        "_",
                      )}`}
                      activeKey={artboardActiveKey}
                      key={`template-list-panel-${assetType}-${artboardKey}`}
                    >
                      <Collapse.Panel
                        key={`template-list-panel-${assetType}-${artboardKey}`}
                        header={
                          <div
                            className={`template-list-collapse-artboard ${assetType}-${artboardKey}`}
                            onClick={() => {
                              artboardActiveKey ===
                              `template-list-panel-${assetType}-${artboardKey}`
                                ? setArtboardActiveKey("")
                                : setArtboardActiveKey(
                                    `template-list-panel-${assetType}-${artboardKey}`,
                                  );
                            }}
                          >
                            <span className="title">{`${artboardKey}`}</span>
                            <span className="number-counter">
                              {byArtboards[artboardKey].length}
                            </span>
                          </div>
                        }
                      >
                        <TemplateCardListContainer
                          key={`card-list-container-${artboardKey}-${uniqueUUID}`}
                          templates={orderBy(byArtboards[artboardKey], "name")}
                          previewTemplateId={previewTemplateId}
                          setPreviewTemplateId={setPreviewTemplateId}
                          selectedHeader={selectedHeader}
                          selectedFilterOem={selectedFilterOem || []}
                          selectedFilterStore={selectedFilterStore || []}
                          selectedFilterTags={selectedFilterTags || []}
                          addDeletedIds={addDeletedTemplateIds}
                          searchInput={
                            selectedTab === "templates" ? searchInput : ""
                          }
                          templatesAllPublishedUnpublished={
                            selectedFilterStatus
                          }
                          canvas={canvas}
                          setCanvas={setCanvas}
                        />
                      </Collapse.Panel>
                    </Collapse>
                  );
                })}
              </Collapse.Panel>
            );
          })}
        </Collapse>
      )}
    </>
  );
};

export default memo(TemplateList);
