import { innerColumns } from "./columns";
import { Table } from "antd";
import { PmaxReviewItemWithLoadStatus } from "../../shared/types";

type PmaxLoadStepExpandableTableProps = {
  items: PmaxReviewItemWithLoadStatus[];
};

export const PmaxLoadStepExpandableTable = ({
  items,
}: PmaxLoadStepExpandableTableProps) => {
  return (
    <Table<PmaxReviewItemWithLoadStatus>
      columns={innerColumns}
      dataSource={items}
      pagination={false}
      showHeader={false}
    />
  );
};
