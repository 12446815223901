import { Button, Input, Popover, Space, Spin } from "antd";
import { useEffect, useState } from "react";
import ImageUploaderInsideSteps from "shared/components/ImageUploaderInsideSteps";
import OfferImagePopoverUploadButton from "./OfferImagePopoverUploadButton";
import {
  uploadJellybeanUpdate,
  editOffer,
} from "redux/assetBuilder/assetBuilder.slice";
import { uploadImages } from "redux/uploadManagement/uploadManagement.slice";
import {
  IUploadImageFormInput,
  IOfferYMMT,
} from "shared/types/uploadManagement";
import "./OfferImagePopover.scss";
import { useAppShallowEqualSelector } from "shared/hooks/useAppSelector";
import { AppState } from "redux/store";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
interface IOfferImagePopover {
  setNewJellybean: (jellybean: string) => void;
  showUploadContent: boolean;
  toggleUploadContent: () => void;
  togglePopover: () => void;
}

const OfferImagePopoverUploadContent = ({
  setNewJellybean,
  showUploadContent,
  togglePopover,
}: IOfferImagePopover) => {
  const dispatch = useAppDispatch();
  const { offerData, gettingJellybeanImages } = useAppShallowEqualSelector(
    ({ assetBuilder }: AppState) => ({
      offerData: assetBuilder.offerData,
      editMode: assetBuilder.editMode,
      jellybeanImages: assetBuilder.jellybeanImages,
      gettingJellybeanImages: assetBuilder.gettingJellybeanImages,
    }),
  );

  const [clearValues, toggleClearValues] = useState<boolean>(false);

  const [popOverSizeStr, setPopOverSizeStr] = useState<string>("9em");

  const [disableUpload, toggleUpload] = useState<boolean>(true);

  const [showUploadError, toggleUploadError] = useState<boolean>(false);

  const [dimensionsType, changeDimensionsType] = useState<string>("");

  const [vehicleName, updateVehicleName] = useState<string>(
    offerData?.year
      ? `${offerData.year}_${offerData.make}_${offerData.model}_${offerData.trim}`
      : "",
  );

  const [enteredYMMT, setEnteredYMMT] = useState<IOfferYMMT>({
    year: offerData?.year ?? "",
    make: offerData?.make ?? "",
    model: offerData?.model ?? "",
    trim: offerData?.trim ?? "",
  });

  const [imageToUpload, setImageToUpload] = useState<IUploadImageFormInput>({
    file: null,
    filename: "",
    type: "",
    preview: "",
  });

  useEffect(() => {
    dispatch(
      uploadJellybeanUpdate({
        value: false,
        img: "",
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (!disableUpload && !showUploadError) {
      setPopOverSizeStr("12em");
    }
    if (disableUpload && showUploadError) {
      setPopOverSizeStr("14em");
    }
  }, [disableUpload, showUploadError]);

  useEffect(() => {
    const ymmtArr = Object.values(enteredYMMT);
    const vehicleNameArr = ymmtArr.filter(Boolean);
    const vehicleName = vehicleNameArr.join("_");
    updateVehicleName(vehicleName);
  }, [enteredYMMT]);

  const onClear = () => {
    toggleClearValues(true);
    changeDimensionsType("");
    updateVehicleName("");
    toggleClearValues(false);
    toggleUpload(true);
    setPopOverSizeStr("8em");
  };

  const handleImageChange = (imageToAdd: IUploadImageFormInput) => {
    setImageToUpload(imageToAdd);
  };

  const initImageObj = {
    file: null,
    size: 0,
  };
  const [logoObject, setLogo] = useState<{
    horizontal: IUploadImageFormInput;
    vertical: IUploadImageFormInput;
    square: IUploadImageFormInput;
    feedbackMessage: string;
  }>({
    horizontal: {
      ...initImageObj,
    },
    vertical: { ...initImageObj },
    square: { ...initImageObj },
    feedbackMessage: "",
  });

  const uploadJBFunc = async () => {
    const currentDate = Date.now();
    const updatedImageName: IUploadImageFormInput = {
      ...imageToUpload,
      filename: `${vehicleName}_${currentDate}.png`,
    };
    await dispatch(uploadImages([updatedImageName], "offerList"));
    const imgUrl = `https://${process.env.REACT_APP_S3_UPLOAD_ASSETS}.s3.amazonaws.com/offerList/uploads-raw/${vehicleName}_${currentDate}.png`;
    setNewJellybean(imgUrl);
    if (uploadJellybeanUpdate) {
      uploadJellybeanUpdate({
        value: true,
        img: imgUrl,
      });

      dispatch(editOffer({ key: "imageUrl", value: imgUrl }));
    }
    togglePopover();
  };

  return (
    <div className="library-wrapper">
      <Spin spinning={gettingJellybeanImages}>
        {
          <div>
            <div
              className="popOverContent"
              style={{
                width: "42em",
                height: popOverSizeStr,
              }}
            >
              <div className="vehicleInfoContent uploaderSteps">
                <div className="imageUploader">
                  <ImageUploaderInsideSteps
                    title={"Jellybean"}
                    s3AssetBucket={"alexia-assets-prd"}
                    handleImageChange={handleImageChange}
                    setLogo={setLogo}
                    logoObject={logoObject}
                    jellybeanUpload={true}
                    toggleUpload={toggleUpload}
                    toggleUploadError={toggleUploadError}
                    modifyDimensionsString={changeDimensionsType}
                    clearFiles={clearValues}
                    enteredYMMT={enteredYMMT}
                  />
                </div>
                <div
                  className="dimensionsAndFileName"
                  style={{ paddingLeft: "10px" }}
                >
                  {dimensionsType.split("\n").map((item, i) => (
                    <p key={i}>{item}</p>
                  ))}
                </div>
              </div>
              <br />
              <div>
                Vehicle Info (Year Make and Model are required for uploads)
              </div>
              <div className="vehicleInfoContent">
                {!showUploadContent && (
                  <Space>
                    <Input
                      defaultValue={offerData?.year ?? ""}
                      className="inputField"
                      placeholder="* Year"
                      onChange={e => {
                        setEnteredYMMT({
                          ...enteredYMMT,
                          year: e.target.value,
                        });
                      }}
                    ></Input>

                    <Input
                      defaultValue={offerData?.make ?? ""}
                      className="inputField"
                      placeholder="* Make"
                      onChange={e => {
                        setEnteredYMMT({
                          ...enteredYMMT,
                          make: e.target.value,
                        });
                      }}
                    ></Input>
                    <Input
                      defaultValue={offerData?.model ?? ""}
                      className="inputField"
                      placeholder="* Model"
                      onChange={e => {
                        setEnteredYMMT({
                          ...enteredYMMT,
                          model: e.target.value,
                        });
                      }}
                    ></Input>
                    <Input
                      defaultValue={offerData?.trim ?? ""}
                      className="inputField"
                      placeholder="Trim"
                      onChange={e => {
                        setEnteredYMMT({
                          ...enteredYMMT,
                          trim: e.target.value,
                        });
                      }}
                    ></Input>
                  </Space>
                )}
              </div>
              <div className="bottomRow">
                <div className="clearButton">
                  <Button danger onClick={onClear}>
                    Delete
                  </Button>
                </div>

                <div className="uploadButton">
                  {disableUpload ? (
                    <Popover
                      placement="bottomRight"
                      trigger={"hover"}
                      content={
                        "A valid Image, Year, Make, and Model are required for uploads"
                      }
                    >
                      {
                        <OfferImagePopoverUploadButton
                          disableUpload={disableUpload}
                          uploadJBFunc={uploadJBFunc}
                        />
                      }
                    </Popover>
                  ) : (
                    <OfferImagePopoverUploadButton
                      disableUpload={disableUpload}
                      uploadJBFunc={uploadJBFunc}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        }
      </Spin>
    </div>
  );
};

export default OfferImagePopoverUploadContent;
