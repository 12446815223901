import { LinkOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Typography, message } from "antd";
import { FC, memo } from "react";

export interface IProps {
  link: string;
  onClose?: () => void;
  visible?: boolean;
}

const CopyLinkModal: FC<IProps> = ({ link, onClose, visible }) => {
  message.config({ maxCount: 1 });

  return (
    <Modal
      centered
      visible={visible}
      closable
      onCancel={() => {
        onClose?.();
      }}
      title="Image Link URL"
      footer={[
        <Button
          type="link"
          key="share-button"
          title="Copy Link"
          icon={<LinkOutlined style={{ marginTop: 4 }} />}
          onClick={() => {
            if (!navigator?.clipboard) return;
            navigator.clipboard.writeText(link);
            message.info("Image URL copied to clipboard");
          }}
        >
          Copy Link
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <Form.Item>
          <Typography.Text>
            Generated Image can be accessed with this link
          </Typography.Text>
        </Form.Item>
        <Form.Item>
          <Input value={link} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default memo(CopyLinkModal);
