import { Button, Checkbox, Modal, Space } from "antd";
import { useState } from "react";
import {
  ColumnTitle,
  plainColumnNames,
} from "../InstantExperiencesImportTable";

type Props = {
  onHide: () => void;
  setSelectedColumns: (selectedColumns: ColumnTitle[]) => void;
  selectedColumns: ColumnTitle[];
};

function InstantExperiencesImportTableColumnPicker({
  onHide,
  setSelectedColumns,
  selectedColumns,
}: Props) {
  const [tempColumns, setTempColumns] = useState(selectedColumns);
  return (
    <Modal
      title="Show / Hide Columns"
      width="500px"
      onCancel={onHide}
      zIndex={2000}
      visible
      footer={
        <Space>
          <Checkbox
            key="selectAll"
            indeterminate={
              tempColumns.length > 0 &&
              tempColumns.length < plainColumnNames.length
            }
            checked={plainColumnNames.every(column =>
              tempColumns.includes(column),
            )}
            onChange={e =>
              setTempColumns(e.target.checked ? plainColumnNames : [])
            }
          >
            Select All
          </Checkbox>
          <Button
            key="ok"
            type="primary"
            onClick={() => {
              setSelectedColumns(tempColumns);
              onHide();
            }}
          >
            OK
          </Button>
        </Space>
      }
    >
      <Checkbox.Group
        options={plainColumnNames}
        value={tempColumns}
        onChange={newColumns => setTempColumns(newColumns as ColumnTitle[])}
        style={{
          display: "grid",
          gap: "10px",
          gridTemplateColumns: "1fr 1fr",
        }}
      />
    </Modal>
  );
}

export default InstantExperiencesImportTableColumnPicker;
