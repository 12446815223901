import { memo, useMemo } from "react";
import _upperFirst from "lodash/upperFirst";
import { v4 as uuid } from "uuid";

import { Avatar, message, Space } from "antd";
import {
  CheckOutlined,
  ControlOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import {
  getAdDisplayFromAd,
  getColorFromConflictStatus,
} from "screens/adLibrary/utils";

import { shallowEqual, useSelector } from "react-redux";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import {
  setSelectedAdForSummary,
  setSelectedAdForAdLoadParameters,
  setAdLoadParametersForAd,
  setFacebookAdLoadDictForAd,
  setAdLoadConflictIdDict,
} from "redux/adLibrary/adLibrary.slice";

import { useLocation } from "react-router-dom";
import { useDeleteAd } from "shared/hooks/adLibrary/useDeleteAd";
import { useOptimisticallyMutateAd } from "shared/hooks/adLibrary/useMutateAd";
import useSelectedAdIds from "shared/hooks/userPreferences/useSelectedAdIds";

import { removeConflictsWithAdShell } from "screens/adLibrary/adLoad/utils";

import Card from "shared/components/Card";
import EllipsisText from "shared/components/card/EllipsisText";

import { ICardButtonObj } from "shared/types/card";
import {
  ConflictStatus,
  FacebookAdLoadDictionary,
  IAd,
  IAdLibraryState,
  QcStatus,
} from "shared/types/adLibrary";

import classNames from "classnames";
import styles from "./AdCard.module.scss";
import { AppState } from "redux/store";

interface Props {
  ad: IAd;
  onPreviewClick?: (ad: IAd) => void;
  onEditClick?: (ad: IAd) => void;
  isSelectedAdSection?: boolean;
}

const AdCard = ({
  ad,
  onPreviewClick,
  onEditClick,
  isSelectedAdSection,
}: Props) => {
  const { mutate: deleteAd } = useDeleteAd();
  const { mutate: mutateAd } = useOptimisticallyMutateAd();

  const { pathname } = useLocation();
  const path = pathname;
  const onAdLoad = path === "/ad-library/load";

  const user = useSelector((state: AppState) => state.auth.user);
  const {
    selectedAdIdForSummary,
    selectedFacebookAccounts,
    adLoadParameters,
    facebookAdLoadDict,
  } = useSelector(
    (state: { adLibrary: IAdLibraryState }) => ({
      selectedAdIdForSummary: state.adLibrary.selectedAdIdForSummary,
      selectedFacebookAccounts: state.adLibrary.selectedFacebookAccounts,
      facebookAdLoadDict: state.adLibrary.adLoad.facebookAdLoadDict,
      adLoadParameters: state.adLibrary.adLoad.adLoadParameters,
    }),
    shallowEqual,
  );

  const dispatch = useAppDispatch();
  const { isAdSelected, toggleAdSelection } = useSelectedAdIds();

  const isThisCardSelected = selectedAdIdForSummary === ad.id;
  const adDisplay = getAdDisplayFromAd(ad)!;
  const showAdLoadButton = !!selectedFacebookAccounts?.length && onAdLoad;
  const adHasLoadParameters = !!adLoadParameters?.rulesets?.[ad.id] && onAdLoad;

  const conflictStatus = useMemo(() => {
    if (!onAdLoad) {
      return ConflictStatus.EMPTY;
    }

    if (
      !adLoadParameters?.rulesets?.[ad.id] ||
      adLoadParameters.rulesets[ad.id] === null
    ) {
      return ConflictStatus.SETUP;
    }

    const hasConflicts = !!adLoadParameters?.conflicts?.[ad.id]?.length;
    const otherAdsHaveConflicts = !!Object.keys(
      adLoadParameters?.conflicts || {},
    ).filter(adShellId =>
      adLoadParameters?.conflicts?.[adShellId]?.includes(ad.id),
    ).length;

    if (hasConflicts || otherAdsHaveConflicts) {
      return ConflictStatus.CONFLICT;
    }

    return ConflictStatus.READY;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adLoadParameters, onAdLoad]);

  const handleDuplicateAd = () => {
    const newAdId = uuid();
    const newInputParametersId = uuid();
    const newVisualsId = uuid();
    mutateAd(
      {
        ...ad,
        id: newAdId,
        createdAt: new Date().getTime(),
        createdBy: user?.email || "",
        updatedAt: new Date().getTime(),
        updatedBy: user?.email ?? "",
        inputParametersId: newInputParametersId,
        visualsId: newVisualsId,
        visuals: { ...ad.visuals, id: newVisualsId },
        inputParameters: {
          ...ad.inputParameters,
          name: `${ad.inputParameters.name} - Copy`,
          id: newInputParametersId,
        },
      },
      {
        onSuccess: () => {
          message.success(
            `${ad.inputParameters.name} was duplicated successfully`,
          );
        },
        onError: () => {
          message.error("Error duplicating the Ad");
        },
      },
    );
  };

  const CategoryIcon = adDisplay.CategoryIcon;
  const qcAvatarColor = conflictStatus
    ? getColorFromConflictStatus(conflictStatus)
    : adDisplay.qcStatusColor;

  const qcStatus = conflictStatus || adDisplay.qcStatus;
  const isApprovedQc = [
    QcStatus.APPROVED,
    QcStatus.CO_OP_APPROVED,
    QcStatus.INTERNALLY_APPROVED,
    QcStatus.READY_TO_LOAD,
  ].includes(adDisplay.qcStatus);

  const clearAdLoadParametersForAd = () => {
    const newFacebookAdLoadDict: FacebookAdLoadDictionary = {
      ...facebookAdLoadDict,
    };
    delete newFacebookAdLoadDict[ad.id];
    dispatch(
      setFacebookAdLoadDictForAd({
        adShellId: null,
        adPart: "all",
        data: newFacebookAdLoadDict,
      }),
    );

    // remove conflicts object
    if (adLoadParameters?.conflicts?.[ad.id]) {
      const newAdLoadConflicts = removeConflictsWithAdShell(
        adLoadParameters.conflicts,
        ad.id,
      );
      dispatch(
        setAdLoadConflictIdDict({ adShellId: null, data: newAdLoadConflicts }),
      );
    }

    dispatch(
      setAdLoadParametersForAd({
        adShellId: ad.id,
        prop: "rulesets",
        data: null,
      }),
    );
    dispatch(
      setAdLoadParametersForAd({
        adShellId: ad.id,
        prop: "rulesets",
        data: null,
      }),
    );
  };

  const buttonObjsInAvailableAds: ICardButtonObj[] = [
    {
      popConfirmProps: {
        title: "Are you sure you want to duplicate this Ad?",
        onConfirm: handleDuplicateAd,
        okText: "Yes",
        cancelText: "No",
      },
      tooltipProps: {
        placement: "top",
        title: "Duplicate",
      },
      buttonProps: {
        icon: <CopyOutlined />,
      },
      buttonText: "Duplicate",
    },
    {
      popConfirmProps: {
        title: "Are you sure you want to delete this Ad?",
        onConfirm: () =>
          deleteAd(adDisplay.id, {
            onError: () => {
              message.error("Error deleting the Ad");
            },
          }),
        okText: "Yes",
        cancelText: "No",
      },
      tooltipProps: {
        placement: "top",
        title: "Delete",
      },
      buttonProps: {
        icon: <DeleteOutlined />,
      },
      buttonText: "Delete",
    },
  ];

  const buttonsObjects: ICardButtonObj[] = showAdLoadButton
    ? [
        {
          tooltipProps: {
            placement: "top",
            title: adHasLoadParameters
              ? "Edit Ad Load Rulesets"
              : "Edit Rulesets",
          },
          buttonText: "Edit Rulesets",
          buttonProps: {
            className: "disabledButton",
            icon: <ControlOutlined />,
            onClick: () =>
              dispatch(
                setSelectedAdForAdLoadParameters({
                  selectedAd: ad,
                }),
              ),
          },
        },
      ]
    : [
        {
          tooltipProps: {
            placement: "top",
            title: "Edit",
          },
          buttonProps: {
            icon: <EditOutlined />,
            onClick: () => onEditClick?.(ad),
          },
          buttonText: "Edit",
        },
        {
          tooltipProps: {
            placement: "top",
            title: "Preview",
          },
          buttonProps: {
            icon: <EyeOutlined />,
            onClick: () => onPreviewClick?.(ad),
          },
          buttonText: "Preview",
        },
        {
          tooltipProps: {
            placement: "top",
            title: !isApprovedQc
              ? "Only approved ads can be selected for ad loading"
              : isAdSelected(ad.id)
              ? "Deselect"
              : "Select",
          },
          buttonText: isAdSelected(ad.id) ? "Deselect" : "Select",
          buttonProps: isApprovedQc
            ? {
                icon: <CheckOutlined />,
                className: classNames({
                  [styles.buttonSelected]: isAdSelected(ad.id),
                }),
                onClick: () => {
                  if (isAdSelected(ad.id)) {
                    // Fix for AV2-3209: clear rules and conflicts related to current ad shell
                    clearAdLoadParametersForAd();
                  }
                  toggleAdSelection(ad.id);
                },
              }
            : {
                icon: <CheckOutlined />,
                style: {
                  cursor: "default",
                  borderColor: "#d5cac9",
                  color: "rgba(0, 0, 0, 0.25)",
                  boxShadow: "none",
                },
              },
        },
        ...(!isSelectedAdSection ? buttonObjsInAvailableAds : []),
      ];

  const status = (
    <Space key="category-and-oem-div" align="center">
      <Avatar
        style={{
          backgroundColor: qcAvatarColor,
          marginTop: "-3px",
        }}
        size={12}
      />
      {qcStatus}
    </Space>
  );

  const badges = [
    (adDisplay.oems || CategoryIcon) && (
      <Space key="category-and-oem-div" align="center">
        {CategoryIcon && (
          <CategoryIcon
            style={{
              marginLeft: "auto",
              marginTop: "4px",
              fontSize: "14px",
            }}
          />
        )}
        {adDisplay.oems && (
          <EllipsisText text={adDisplay.oems} charDisplayLimit={10} />
        )}
      </Space>
    ),
    _upperFirst(adDisplay.type),
    adDisplay.package && (
      <Space key="category-and-oem-div" align="center">
        <span className={styles.rectangle}>{adDisplay.package}</span>
        Package
      </Space>
    ),
  ];

  const tags = [
    adDisplay?.models || null,
    adDisplay?.description || null,
    adDisplay?.audiences || null,
  ];

  return (
    <Card
      title={adDisplay.name || "--"}
      imageSrc={adDisplay.thumbnail}
      isThisCardSelected={isThisCardSelected}
      onCardClick={() => {
        dispatch(
          setSelectedAdForSummary(isThisCardSelected ? null : adDisplay.id),
        );
      }}
      status={status}
      badges={badges.filter(badge => !!badge)}
      tags={tags.filter(tag => !!tag) as string[]}
      buttonObjects={buttonsObjects.filter(btn => !!btn)}
    />
  );
};

export default memo(AdCard);
