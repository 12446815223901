/* eslint-disable-next-line  @typescript-eslint/no-unused-vars */
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import {
  Connection,
  Elements,
  FlowElement,
  isNode,
  NodeProps,
  NodeTypesType,
  OnConnectStartFunc,
  OnConnectStopFunc,
  OnEdgeUpdateFunc,
  ReactFlowProps,
  Node,
  Edge as FlowEdge,
  OnLoadParams,
  FlowTransform,
} from "react-flow-renderer";
import { IAd, IInstantExperience } from "shared/types/adLibrary";
import useOnSearch, { OnSearchType } from "./useOnSearch";
import FlowInstantExperience from "../everythingAds/Mapper/flowCanvas/FlowInstantExperience";
import useOnSave from "./useOnSave";
import { useLocation } from "react-router-dom";
import useIEElements from "./useIEElements";
import useAutoFlowConnection from "./useAutoFlowConnection";
import useDeepEffect from "shared/hooks/useDeepEffect";
import { getRepositionedElements } from "./utils";
import usePaneDrag from "./usePaneDrag";
import useIsConnecting from "./useIsConnecting";
import Edge from "screens/everythingAds/Mapper/shared/Edge";
import { InitiatingShareLink } from "shared/types/shared";
import useShareLink from "./useShareLink";
import MarketingTile from "screens/everythingAds/Mapper/flowCanvas/MarketingTile";
import URLComponent from "../everythingAds/Mapper/flowCanvas/URL";
import { ILabel } from "shared/types/inputValues";
import { isUrlInputValid } from "utils/validators";

export const MAX_ZOOM = 10;
export const MIN_ZOOM = 0.2;
export const CANVAS_BG_COLOR = "#E8E8E8";
export const NodeTypes: NodeTypesType = {
  ie: FlowInstantExperience,
  ad: MarketingTile,
  url: URLComponent,
};
export const EdgeTypes = {
  deletable: Edge,
};

export type FlowHeights = Record<string, number>;
export type OnIEConnectFunc = (edgeParams: FlowEdge<any> | Connection) => void;
export type OnIEElemtnsRemoveFunc = (elements: EAElements) => void;
export type OnRemoveEdgeFunc = (id: string) => void;

export type FlowEventCallbacks = {
  onConnect: OnIEConnectFunc;
  onElementsRemove?: OnIEElemtnsRemoveFunc;
  onEdgeUpdate?: OnEdgeUpdateFunc;
  onRemoveEdge?: OnRemoveEdgeFunc;
  onSelectionChange?: ReactFlowProps["onSelectionChange"];
};

export type URLData = {
  id?: string;
  type: "url" | "label";
  value: string;
  labelName?: string;
}; // labelName only valid when type === "label"
export const isUrlData = (data: unknown): data is URLData => {
  if (!data || typeof data !== "object") return false;

  const keys = Object.keys(data);
  return ["type", "value"].every(key => keys.includes(key));
};

export type DataType = {
  ie?: IInstantExperience;
  ad?: IAd;
  url?: URLData;
};

export type Element = IInstantExperience | IAd | URLData;
export type EAFlowElement = FlowElement<DataType>;
export type EAFlowNode = Node<DataType>;
export type EANode = NodeProps<DataType>;
export type EAElements = Elements<DataType>;
type Props = {
  selectedFlowElement?: EAFlowElement;
  instantExperiences: IInstantExperience[];
  searchBy?: string;
  isSearching: boolean;
  elements: EAElements;
  isSaving: boolean;
  isConnecting: boolean;
  paneDrag: ReturnType<typeof usePaneDrag>;
  hoveringEdge?: FlowEdge;
  instance?: OnLoadParams;
  isAuthenticated: boolean;
  flowTransform?: FlowTransform;
  ads: IAd[];
  labels: ILabel[];
};

type Handlers = {
  setSelectedFlowElement?: Dispatch<SetStateAction<EAFlowElement | undefined>>;
  setInstantExperiences: (ies: IInstantExperience[]) => void;
  setElements: Dispatch<SetStateAction<Elements<any>>>;
  onSearch: OnSearchType;

  eventCallbacks: FlowEventCallbacks;

  onSave: () => void;
  onConnectStart: OnConnectStartFunc;
  onConnectStop: OnConnectStopFunc;
  setFlowElementHeights: Dispatch<SetStateAction<FlowHeights>>;
  setHoveringEdge: Dispatch<SetStateAction<FlowEdge | undefined>>;
  setInstance: Dispatch<SetStateAction<OnLoadParams | undefined>>;
  setAds: Dispatch<SetStateAction<IAd[]>>;
  setLabels: Dispatch<SetStateAction<ILabel[]>>;
  isValidUrlValue: (val: string) => boolean;
};
type IEContext = Props & Handlers;

const Context = createContext<IEContext | null>(null);
export const useEverythingAdsContext = () => useContext(Context);

type IEProps = {
  children?: ReactNode;
  initiatingShareLink?: InitiatingShareLink;
  onShareLinkUpdate: (url: string) => void;
  isAuthenticated: boolean;
};
const Provider = (props?: IEProps) => {
  // Temporary section for ads. Will be re-factored into custom hook
  const [ads, setAds] = useState<IAd[]>([]);
  const {
    selectedFlowElement,
    setSelectedFlowElement,
    elements,
    setElements,
    updatedElements,
    eventCallbacks,
  } = useIEElements();

  const [instantExperiences, setInstantExperiences] = useState<
    IInstantExperience[]
  >([]);

  const [searchBy, onSearch, isSearching] = useOnSearch();
  const [onSave, isSaving] = useOnSave(updatedElements);
  const connectionEvents = useIsConnecting();
  const paneDrag = usePaneDrag();

  const [flowElementHeights, setFlowElementHeights] = useState<FlowHeights>({});

  const { search: queryString } = useLocation();
  const autoGenElements = useAutoFlowConnection({
    queryString,
  });

  const onAutoGenElements = useCallback(() => {
    if (autoGenElements.length === 0) return;

    setElements(autoGenElements);
  }, [autoGenElements, setElements]);

  const onFlowElementHeights = useCallback(() => {
    const okToProceed =
      elements.filter(ele => isNode(ele)).length ===
      Object.keys(flowElementHeights).length;
    if (!okToProceed) return;

    const updated = getRepositionedElements(elements, flowElementHeights);
    setElements(updated);
    setFlowElementHeights({}); // If we dont reset this here, when user drags new ie into the canvas, it will position the elements on the top of the canvas
  }, [elements, flowElementHeights, setElements]);

  useDeepEffect(onAutoGenElements, [autoGenElements]);
  useDeepEffect(onFlowElementHeights, [elements, flowElementHeights]);

  const [hoveringEdge, setHoveringEdge] = useState<FlowEdge>();

  // share link
  const {
    initiatingShareLink,
    onShareLinkUpdate,
    isAuthenticated = false,
  } = props || {};
  const [instance, setInstance] = useState<OnLoadParams>();
  const [flowTransform, setFlowTransform] = useState<FlowTransform>();

  // NOTE: we have to memoize below function otherwise, it will infinitely re-render the view.
  const onCompleteLoadShareLink = useCallback(
    (json: any) => {
      const { elements, zoom = 0, position } = json;
      const [x = 0, y = 0] = position;

      setElements(elements);
      setFlowTransform({
        zoom,
        x,
        y,
      });
    },
    [setElements],
  );

  const onShareLinkUpdateComplete = useCallback(
    (url: string) => {
      onShareLinkUpdate?.(url);
    },
    [onShareLinkUpdate],
  );
  useShareLink({
    instance,
    initiatingShareLink,
    queryString,
    onComplete: onShareLinkUpdateComplete,
    isAuthenticated,
    onCompleteLoadShareLink,
  }); // Rendering contents when accessed with share link.

  const [labels, setLabels] = useState<ILabel[]>([]);
  const isValidUrlValue = (val: string) => {
    // the val should be one of the following.
    //  - label
    //  - url
    // Otherwise, we can assume that the "val" is invalid.
    return (
      labels.some(label => label.name === val) || isUrlInputValid(val) || false
    );
  };
  const state: IEContext = {
    setFlowElementHeights,
    selectedFlowElement,
    setSelectedFlowElement,
    instantExperiences,
    setInstantExperiences,
    searchBy,
    onSearch,
    isSearching,
    setElements,
    elements,
    eventCallbacks,
    ...connectionEvents,
    onSave,
    isSaving,
    paneDrag,
    hoveringEdge,
    setHoveringEdge,
    setInstance,
    instance,
    isAuthenticated,
    flowTransform,
    setAds,
    ads,
    labels,
    setLabels,
    isValidUrlValue,
  };
  return <Context.Provider value={state}>{props?.children}</Context.Provider>;
};

export default () => ({
  Context,
  Provider,
});
