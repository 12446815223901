import { PictureFilled } from "@ant-design/icons";
import { times } from "lodash";
import { IProduct, IProductSetElement } from "shared/types/adLibrary";
import { memo } from "react";
import styles from "./ProductSetPreview.module.scss";

interface IProps {
  productSetElement: IProductSetElement;
}

const ProductSetPreview = ({ productSetElement }: IProps) => {
  const Product = ({ product }: { product: IProduct }) => (
    <div className={styles.card}>
      <div className={product.source ? styles.cardImage : styles.cardPicture}>
        {product.source ? (
          <img src={product.source} height={150} width={200} />
        ) : (
          <PictureFilled />
        )}
      </div>
      <div className={styles.cardData}>
        <p>{product.headline}</p>
        <p>
          <strong>{product.description}</strong>
        </p>
      </div>
    </div>
  );

  const ProductSetPlaceholder = () => (
    <div>
      <div className={styles.copy}>
        Please Note: The product set shown below is a placeholder. You will be
        able to select product sets when loading to Facebook.
      </div>
      <div className={styles.grid}>
        {times(productSetElement.max_products ?? 50, n => (
          <Product
            key={n}
            product={{ headline: "Headline", description: "Description" }}
          />
        ))}
      </div>
    </div>
  );

  const ProductSet = () => (
    <div className={styles.grid}>
      {productSetElement.product_set?.map((product, index) => (
        <Product key={index} product={product} />
      ))}
    </div>
  );

  return !!productSetElement.product_set ? (
    <ProductSet />
  ) : (
    <ProductSetPlaceholder />
  );
};

export default memo(ProductSetPreview);
