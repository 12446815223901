import React, { createContext, useMemo, useState } from "react";
import { IConfig } from "shared/types/configuration";
import { CampaignPlanner, CampaignPlannerList } from "./types";
import {
  useCreateCampaignPlanner,
  useDeleteCampaignPlanners,
  useEditCampaignPlanner,
  useUpdateCampaignPlannerAds,
} from "shared/hooks/campaignPlanner/useMutateCampaignPlanner";
import { useCampaignPlanners } from "shared/hooks/campaignPlanner/useFetchCampaignPlanner";
import { useUser } from "shared/hooks/useUser";
import uuid from "uuid";
import { message } from "antd";
import { IAd } from "shared/types/adLibrary";
import { useCampaignPlannerIds } from "screens/adLibrary/shared/hooks/dataListHooks";
import { createPlannerObject, getOrphanedInstances, isNewItem } from "./utils";
import {
  PlannerInstanceActions,
  PlannerInstanceProps,
  usePlannerInstance,
} from "./CampaignPlannerContext/usePlannerInstance";
import { longAlert } from "utils/antd/longAlert/longAlert";

type CampaignPlannerContext = CampaignPlannerContextProps &
  CampaignPlannerContextHandlers;

type CampaignPlannerContextProps = {
  data: CampaignPlannerList;
  loading: boolean;
  getPlanner: (id: string) => CampaignPlanner;
} & PlannerInstanceProps;

type CampaignPlannerContextHandlers = {
  createNewCampaign: () => void;
  onEditEnd: (params: CampaignPlanner) => void;
  deleteSelection: () => void;
  updateCampaignPlannerAds: (
    planner: CampaignPlanner,
    ads: IAd[],
    successMessage: string,
  ) => void;
  deleteSinglePlanner: (plannerId: string) => void;
} & PlannerInstanceActions;

const Context = createContext<CampaignPlannerContext | undefined>(undefined);

interface ICampaignPlannerContextProps {
  config?: IConfig;
  children?: React.ReactNode;
}

export const TEMP_ROW_ID = "TEMP_ROW_ID";

const CampaignPlannerProvider = (props: ICampaignPlannerContextProps) => {
  const [tempPlanner, setTempPlanner] = useState<CampaignPlanner | null>(null);

  // Mutations
  // Planners
  const { mutate: createPlannerMutation } = useCreateCampaignPlanner();
  const { mutate: editPlannerMutation } = useEditCampaignPlanner();
  const { mutate: deletePlannersMutation } = useDeleteCampaignPlanners();

  // PlannerAds
  const { mutate: updatePlannerAdsMutation } = useUpdateCampaignPlannerAds();

  const {
    data: planners = [],
    isLoading,
    originalData,
  } = useCampaignPlanners();

  const { selectedIds, setSelectedItemIds } = useCampaignPlannerIds();

  const user = useUser();

  const getPlanner = (id: string) =>
    originalData.find(
      (planner): planner is CampaignPlanner => planner.id === id,
    )!;

  const instance = usePlannerInstance({ getPlanner });

  const createNewCampaign = () => {
    if (tempPlanner) return;

    setTempPlanner(createPlannerObject());
  };

  const onEditEnd = (model: CampaignPlanner) => {
    if (!isNewItem(model)) {
      const updatedPlanner: CampaignPlanner = {
        ...model,
        updatedAt: new Date().toISOString(),
        updatedBy: user?.email || "",
      };
      editPlannerMutation(updatedPlanner, {
        onSuccess: () => {
          message.success(`Planner updated successfully`);
        },
      });
      return;
    }

    if (!model.name) {
      setTempPlanner(null);
      return;
    }

    const newPlanner: CampaignPlanner = createPlannerObject({
      name: model.name,
      id: uuid.v4(),
      createdBy: user?.email || "",
    });
    createPlannerMutation(newPlanner, {
      onSuccess: () => {
        message.success(`Planner: "${newPlanner.name}" created successfully`);
      },
    });
    setTempPlanner(null);
  };

  const deletePlanners = (idsToDelete: string[]) => {
    const ids = idsToDelete.map(key => ({
      campaignPlannerId: key.toString(),
      instanceIds: getInstancesIds(key),
    }));
    if (!ids.length) return;
    deletePlannersMutation(ids, {
      onSettled: (_, error) => {
        if (error) return;
        setSelectedItemIds([]);
      },
    });
  };

  const getInstancesIds = (id: string) => {
    const campaignPlanner = planners.find(planner => planner.id === id);
    if (!campaignPlanner) return [];
    return campaignPlanner.instances.map(instance => instance.id) || [];
  };

  const updateCampaignPlannerAds = (
    planner: CampaignPlanner,
    adShells: IAd[],
    successMessage: string,
  ) => {
    updatePlannerAdsMutation(
      {
        ...planner,
        adShellIds: adShells.map(ad => ad.id),
        instances: [],
      },
      {
        onSuccess: () => {
          longAlert({ type: "success", header: successMessage });
        },
      },
    );
  };

  const deleteSelection = () => {
    // Delete planners and child instances
    deletePlanners(selectedIds);

    //delete remaining instances without selected planners
    instance.deleteInstances(
      getOrphanedInstances(selectedIds, instance.selectedInstances),
    );
  };

  const deleteSinglePlanner = (plannerId: string) => {
    deletePlanners([plannerId]);
  };

  const data = useMemo(
    () => (tempPlanner ? [tempPlanner, ...planners] : planners),
    [tempPlanner, planners],
  );

  return (
    <Context.Provider
      value={{
        data,
        createNewCampaign,
        onEditEnd,
        updateCampaignPlannerAds,
        deleteSelection,
        getPlanner,
        loading: isLoading,
        deleteSinglePlanner,
        ...instance,
      }}
    >
      {props?.children}
    </Context.Provider>
  );
};

function useCampaignPlanner(): CampaignPlannerContext {
  const context = React.useContext(Context);
  if (!context) {
    throw new Error(
      "useCampaignPlanner must be used within a CampaignPlannerProvider",
    );
  }
  return context;
}

export { CampaignPlannerProvider, useCampaignPlanner };
