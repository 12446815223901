import { ShareAltOutlined } from "@ant-design/icons";
import { Button, Card, Spin, Tooltip, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useFetchLookerThumbnail } from "shared/hooks/metrics/useFetchLookerThumbnail";
import { useIsAdmin } from "shared/hooks/useIsAdmin";
import {
  IDashboard,
  IDashboardSetting,
} from "shared/types/dashboardManagement";
import MetricsImage from "statics/images/metrics-placeholder.svg";
import styles from "../Metrics.module.scss";

const CARD_WIDTH = 350;
const CARD_HEIGHT = 400;

type MetricsCardProps = {
  dashboard: IDashboard;
  dashboardSetting?: IDashboardSetting;
  setShowLinkModal: (showLinkModal: boolean) => void;
  setShareDashboard: (dashboard?: IDashboard) => void;
};
const MetricsCard = ({
  dashboard,
  dashboardSetting,
  setShowLinkModal,
  setShareDashboard,
}: MetricsCardProps) => {
  const navigate = useNavigate();
  const { data: thumbnailUrl, isLoading } = useFetchLookerThumbnail(
    dashboard.lookerId,
  );
  const isAdmin = useIsAdmin();

  return (
    <Card
      key={dashboard.id}
      title={
        <div className={styles.cardTitle}>
          <Typography.Text className={styles.dashboardName}>
            {dashboard.title}
          </Typography.Text>
          <Typography.Text className={styles.accountName}>
            {!isAdmin && dashboardSetting
              ? dashboardSetting?.accounts
              : dashboard.account}
          </Typography.Text>
        </div>
      }
      extra={
        <Button
          icon={
            <Tooltip title="Share dashboard link">
              <ShareAltOutlined />
            </Tooltip>
          }
          type={"text"}
          disabled={!dashboard || !isAdmin}
          onClick={e => {
            e.stopPropagation();
            setShowLinkModal(true);
            setShareDashboard(dashboard);
          }}
        />
      }
      hoverable={true}
      bordered={false}
      style={{ width: CARD_WIDTH, height: CARD_HEIGHT }}
      cover={
        <div className={styles.imgContainer}>
          {isLoading ? (
            <Spin spinning={true} />
          ) : (
            <img
              src={
                thumbnailUrl
                  ? `data:image/svg+xml;utf8,${encodeURIComponent(
                      thumbnailUrl,
                    )}`
                  : MetricsImage
              }
            />
          )}
        </div>
      }
      onClick={() => {
        navigate(`detail/${dashboard?.id}`);
      }}
    ></Card>
  );
};

export default MetricsCard;
