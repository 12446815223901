import { TLayerItem } from "screens/designStudio/editor.hooks/useLayers";
import {
  ExtendedObjectType,
  IExtendedFabricObject,
  IStampObjectData,
} from "shared/types/designStudio";

export const getLayerTitle = (layer: TLayerItem, titleCharLimit?: number) => {
  let title;
  const fabricObject = layer.original as IExtendedFabricObject;
  const { customData } = fabricObject || {};
  const { layerName } = customData || {};

  switch (layer.type as ExtendedObjectType) {
    case "stamp":
      title =
        layerName ||
        `Stamp-${(customData as IStampObjectData)?.stampName || "Unkown"}`;
      break;
    case "logo":
      title = layerName || "Logo";
      break;
    case "disclosure":
      title = layerName || "Disclosure";
      break;
    case "car_cut":
      title = layerName || "Vehicle";
      break;
    case "selected_image":
      title = layerName || "Image";
      break;
    case "selected_video":
      title = layerName || "Video";
      break;
    case "canvas_bg":
      title = layerName || "Background";
      break;
    case "shape":
      title = layerName || "Shape";
      break;
    case "theme_background":
      title = layerName || "Theme Background";
      break;
    default:
      title = layerName || layer.original?.type || "Unknown";
  }

  const isLongTitle = title.length > (titleCharLimit || title.length);
  return {
    title: `${title.substr(0, titleCharLimit || title.length)}${
      isLongTitle ? "..." : ""
    }`,
    isLongTitle,
    fullText: title,
  };
};
