import { useCallback } from "react";
import { IPhotoElement } from "shared/types/adLibrary";
import styles from "./FlowImage.module.scss";

type Props = {
  ele: IPhotoElement;
};
const FlowImage = (props: Props) => {
  const onMouseDown = useCallback(
    (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
      e.preventDefault();
    },
    [],
  );
  return (
    <img
      className={styles.flowImage}
      onMouseDown={onMouseDown}
      src={props.ele.url}
      alt="img"
    />
  );
};
export default FlowImage;
