import { useCallback } from "react";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { useAdEngineActions } from "redux/assetExporter/assetExporter.slice";
import { useAppSelector } from "shared/hooks/useAppSelector";

export const useExportExecutionArns = () => {
  const exportExecutionArns = useAppSelector(
    state => state.assetExporter.assetBatches.exportExecutionArns,
  );
  const { setExportExecutionArns } = useAdEngineActions();

  const dispatch = useAppDispatch();
  const handleSetExportExecutionArns = useCallback(
    (exportExecutionArns: string[]) =>
      dispatch(setExportExecutionArns({ exportExecutionArns })),
    [dispatch, setExportExecutionArns],
  );

  return { exportExecutionArns, handleSetExportExecutionArns };
};
