import { getSearchField } from "./getSearchField";
import { getSearchFields } from "./getSearchFields";

export const getSearchState = (search: MGSearch): MGPartialSearch => {
  const initial: MGPartialSearch = {};

  return Object.entries(search).reduce((acc, [key, val]) => {
    // Exclude empty values
    if (
      typeof val === "undefined" ||
      (typeof val === "string" && !val.trim()) ||
      (Array.isArray(val) && !val.length)
    ) {
      return acc;
    }

    if (key === "query") {
      return {
        ...acc,
        query: val,
      };
    }

    if (key === "filters") {
      return {
        ...acc,
        filters: {
          fields: getSearchFields(val.fields),
        },
      };
    }

    const fields = acc.filters?.fields || [];

    return {
      ...acc,
      filters: {
        fields: fields.concat(getSearchField(key, val)),
      },
    };
  }, initial);
};
