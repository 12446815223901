import { useCallback } from "react";
import { useBrandsAccountsContext } from "screens/brandsAccountsManagement/contexts/BrandsAccountsContext";
import { useDataList } from "shared/components/dataList";
import { AccountEdit, BrandRecord } from "shared/types/brandsAccounts";
import { useDeleteBrandsAccounts } from "./useDeleteBrandsAccounts";
import { useFetchDealers } from "../useFetchDealersV2";

export const useBrandsAccountsToolbar = () => {
  const {
    setFormTargetElementForUpdate,
    toggleDrawer,
    setSearch,
    setFormParentBrandId,
    setAccountDrawerOnBrandDrawer,
  } = useBrandsAccountsContext();
  const { dealers } = useFetchDealers();
  const [{ rows }] = useDataList<BrandRecord>();

  const { onDelete } = useDeleteBrandsAccounts();

  interface IOnEditProps {
    clickedTargetId: string;
    isBrand: boolean;
    isOnlyAccountsMode?: boolean;
  }

  const onEdit = useCallback(
    ({ clickedTargetId, isBrand, isOnlyAccountsMode }: IOnEditProps) => {
      const targetItem = isBrand
        ? rows.find(brand => brand.id === clickedTargetId)
        : rows
            .flatMap(brand => brand.children)
            .find(child => child.id === clickedTargetId);
      if (!targetItem) return;

      if (targetItem.type === "Account") {
        const account = dealers.find(
          dealer => dealer.dealer_name === targetItem?.name,
        );
        const targetAccount = account
          ? ({
              account,
              type: "AccountEdit",
            } as AccountEdit)
          : clickedTargetId;

        if (isOnlyAccountsMode) {
          setAccountDrawerOnBrandDrawer({
            drawerMode: "UPDATE_ACCOUNT",
            targetElement: targetAccount,
          });

          return;
        } else {
          setFormTargetElementForUpdate(targetAccount);
        }
      } else {
        setFormTargetElementForUpdate(targetItem);
      }

      toggleDrawer(
        targetItem.type === "Brand" ? "UPDATE_BRAND" : "UPDATE_ACCOUNT",
      );
    },
    [
      setFormTargetElementForUpdate,
      toggleDrawer,
      rows,
      dealers,
      setAccountDrawerOnBrandDrawer,
    ],
  );

  const onNewBrand = useCallback(() => {
    toggleDrawer("CREATE_BRAND");
  }, [toggleDrawer]);

  const onNewAccount = useCallback(
    (brandParentId?: string) => {
      toggleDrawer("CREATE_ACCOUNT");
      if (brandParentId) setFormParentBrandId(brandParentId);
    },
    [toggleDrawer, setFormParentBrandId],
  );

  const onSearch = useCallback(
    (searchInput: string) => {
      setSearch(searchInput);
    },
    [setSearch],
  );

  return {
    onDelete,
    onEdit,
    onNewBrand,
    onNewAccount,
    onSearch,
  };
};
