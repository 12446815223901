import UploadAsset, {
  IOnUploadAssedChange,
} from "shared/components/uploadAsset/UploadAsset";
import { getUploadAssetList } from "../drawers/BrandDrawer.utils";
import { ILogos } from "shared/types/brandsAccounts";
import { getUploadsSchema } from "shared/schemas/brandsAccounts";
import { Dispatch, SetStateAction, useCallback } from "react";
import { imageAssetDetail } from "shared/constants/brandsAccounts";
import styles from "./UploadLogos.module.scss";
import { Row, Typography } from "antd";

interface Props {
  logos: Partial<ILogos> | undefined;
  setLogos: Dispatch<SetStateAction<ILogos>>;
  onRemoveLogo?: (url: string, fieldKey: keyof ILogos) => void;
}

export const UploadLogos = ({ logos, setLogos, onRemoveLogo }: Props) => {
  const onLogosChange = useCallback(
    ({ isAdding, fieldKey, updatedFile }: IOnUploadAssedChange<ILogos>) => {
      setLogos(prev => ({
        ...prev,
        [fieldKey]: isAdding
          ? [...prev[fieldKey], updatedFile]
          : prev[fieldKey]?.filter(f => f.uid !== updatedFile.uid),
      }));

      if (!isAdding && !!onRemoveLogo && !!updatedFile.url) {
        onRemoveLogo(updatedFile.url, fieldKey);
      }
    },
    [setLogos, onRemoveLogo],
  );
  return (
    <>
      <Row>
        <Typography.Text className={styles.logosTitle}>Logos</Typography.Text>
      </Row>

      {getUploadAssetList(logos).map(
        ({ title, fileList, maxLength, key }, idx) => (
          <UploadAsset
            key={`${title}-${idx}`}
            title={title}
            fileList={fileList}
            maxLength={maxLength}
            fieldKey={key}
            onUploadChange={onLogosChange}
            imageAssetDetail={imageAssetDetail}
            validationSchema={getUploadsSchema(title)}
          />
        ),
      )}
    </>
  );
};
