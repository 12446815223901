import { ISortingOption, FeedOffer } from "shared/types/assetBuilder";
import styles from "./OfferFormSortingOptions.module.scss";
import { calcSortingOptionValue } from "./OfferFormSortingOptions.utils";

interface IOfferFormSortingOptionsProps {
  offerData: FeedOffer;
  sortingOptions?: ISortingOption[];
}

export const OfferFormSortingOptions = ({
  offerData,
  sortingOptions = [],
}: IOfferFormSortingOptionsProps) => {
  const rows = sortingOptions
    .filter(offerInfo => offerInfo.key)
    .map(offerInfo => calcSortingOptionValue(offerInfo, offerData));

  return (
    <div className={styles.container}>
      {rows.map(({ key, content, sortingOptionValue }) => {
        return (
          <div key={key}>
            <span>
              <strong>{content}:</strong>
            </span>
            <span>&nbsp; {sortingOptionValue}</span>
          </div>
        );
      })}
    </div>
  );
};
