import { DownCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { message } from "antd";
import { ReactNode, useState } from "react";
import { EditorContext } from "screens/designStudio/Editor.context";
import useDeepEffect from "shared/hooks/useDeepEffect";
import { isStamp, isTemplate, TabMenu } from "shared/types/designStudio";
import { InsertMenu } from "../Toolbar";
import { useBrandsFonts } from "../../propertySectionV2/propertyRow/manageText/fontProperty.hooks/useBrandsFonts";

export type TToolbarMenu = {
  title: InsertMenu;
  onClick?: () => void;
  selectedTab: TabMenu[];
  icon: ReactNode;
};
export default (args: {
  editorContext: EditorContext | null;
  showInsertMenuPopover: boolean;
  currentSelectedMenu?: TToolbarMenu;
  onComplete: (shouldCloseMenu: boolean) => void;
}) => {
  const { editorContext, showInsertMenuPopover } = args;
  const { shouldUseBrandFonts, fontFamilies, loadFont } = useBrandsFonts();

  const [openedSubMenu, setOpenedSubMenus] = useState<
    Partial<Record<InsertMenu, boolean>>
  >({
    Background: false,
    Shapes: false,
    Media: false,
  });
  const availableMenus: TToolbarMenu[] = [
    {
      title: "Stamp",
      onClick: () => {
        if (isTemplate(editorContext?.resource))
          editorContext?.fetchStampsByOEM();

        editorContext?.toggleStampListModalInToolbar(true);
      },
      selectedTab: ["templates"], // stamps can be inserted into a template only.
      icon: <PlusCircleOutlined />,
    },

    {
      title: "Disclosure",
      onClick: async () => {
        if (shouldUseBrandFonts) {
          await loadFont(fontFamilies[0]);
        }
        editorContext?.setImageInsertData({
          type: "DISCLOSURE",
          ...(shouldUseBrandFonts && { data: fontFamilies[0] }),
        });
      },
      selectedTab: ["templates"],
      icon: <PlusCircleOutlined />,
    },
    {
      title: "Text",
      onClick: async () => {
        if (shouldUseBrandFonts) {
          await loadFont(fontFamilies[0]);
        }
        editorContext?.setImageInsertData({
          type: "TEXT",
          ...(shouldUseBrandFonts && { data: fontFamilies[0] }),
        });
      },
      selectedTab: ["templates", "stamps"],
      icon: <PlusCircleOutlined />,
    },
    {
      title: "Car cut",
      onClick: () => {
        editorContext?.setImageInsertData({
          type: "CAR_CUT",
        });
      },
      selectedTab: ["templates"],
      icon: <PlusCircleOutlined />,
    },
    {
      title: "Lifestyle",
      onClick: () => {
        editorContext?.setImageInsertData({
          type: "LIFESTYLE",
        });
      },
      selectedTab: ["templates"],
      icon: <PlusCircleOutlined />,
    },
    {
      title: "Background",
      onClick: () => {
        setOpenedSubMenus(prev => ({
          ...prev,
          Background: !prev.Background,
        }));
      },
      selectedTab: ["templates"],
      icon: <DownCircleOutlined />,
    },
    {
      title: "Media",
      onClick: () => {
        setOpenedSubMenus(prev => ({
          ...prev,
          Media: !prev.Media,
        }));
      },
      selectedTab: ["templates"],
      icon: <DownCircleOutlined />,
    },
    {
      title: "Shapes",
      onClick: () => {
        setOpenedSubMenus(prev => ({
          ...prev,
          Shapes: !prev.Shapes,
        }));
      },
      selectedTab: ["templates"],
      icon: <DownCircleOutlined />,
    },
  ];

  const [menus, setMenus] = useState<TToolbarMenu[]>([]);

  useDeepEffect(() => {
    const { resource } = args.editorContext || {};
    if (!resource) return;

    if (isTemplate(resource)) {
      setMenus(
        availableMenus.filter(menu => menu.selectedTab.includes("templates")),
      );
    } else if (isStamp(resource)) {
      setMenus(
        availableMenus.filter(menu => menu.selectedTab.includes("stamps")),
      );
    } else {
      message.warning("Selected resouce type is unknown.");
    }
  }, [args.editorContext?.resource]);

  useDeepEffect(() => {
    if (!showInsertMenuPopover)
      setOpenedSubMenus(() => {
        return (Object.keys(openedSubMenu) as InsertMenu[]).reduce<
          Partial<Record<InsertMenu, boolean>>
        >((acc, menu) => {
          acc[menu] = false;

          return acc;
        }, {});
      });
  }, [showInsertMenuPopover]);

  useDeepEffect(() => {
    const { currentSelectedMenu } = args;
    if (!currentSelectedMenu) return;

    const subMenuKeys = Object.keys(openedSubMenu);
    if (subMenuKeys.includes(currentSelectedMenu.title)) {
      setOpenedSubMenus(prev => {
        // close all other submenu popover except current one.
        const updated = (Object.keys(prev) as InsertMenu[]).reduce(
          (acc, key) => {
            if (key === currentSelectedMenu.title) {
              acc[key] = true;
            } else {
              acc[key] = false;
            }
            return acc;
          },
          {} as Partial<Record<InsertMenu, boolean>>,
        );
        return updated;
      });
    } else {
      args.onComplete(false);
    }
  }, [args.currentSelectedMenu]);

  return { menus, openedSubMenu };
};
