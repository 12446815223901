import { useState, useEffect, useRef } from "react";

const useHover = <T extends HTMLElement>(
  onHoverIn?: () => void,
  onHoverOut?: () => void,
) => {
  const targetRef = useRef<T>(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const handleMouseEnter = () => {
      setIsHovered(true);
      onHoverIn && onHoverIn();
    };

    const handleMouseLeave = () => {
      setIsHovered(false);
      onHoverOut && onHoverOut();
    };

    const target = targetRef.current;
    if (target) {
      target.addEventListener("mouseenter", handleMouseEnter);
      target.addEventListener("mouseleave", handleMouseLeave);
    }

    return () => {
      if (target) {
        target.removeEventListener("mouseenter", handleMouseEnter);
        target.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, [onHoverIn, onHoverOut]);

  return [targetRef, isHovered] as const;
};

export default useHover;
