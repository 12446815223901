import { message } from "antd";
import { useEffect } from "react";
import { FlowExportObject, OnLoadParams } from "react-flow-renderer";
import API from "services";
import { useRouteQuery } from "shared/hooks/useRouteQuery";
import { InitiatingShareLink } from "shared/types/shared";
import { getEnvVar } from "utils/helpers";
import HttpClient from "services/httpClient";
import { useNavigate } from "react-router-dom";

type Args = {
  queryString: string;
  instance?: OnLoadParams;
  initiatingShareLink?: InitiatingShareLink;
  onComplete: (url: string) => void;
  isAuthenticated: boolean;
  onCompleteLoadShareLink: (json: FlowExportObject) => void;
};

export default (args: Args) => {
  const showErrorMessage = (errorMessage: string) => {
    message.destroy(); // we first need to destroy loading message that was triggered by share link button click.

    message.error(errorMessage);
  };

  const { instance, initiatingShareLink, onComplete, isAuthenticated } = args;
  useEffect(() => {
    if (!instance || !initiatingShareLink) return;

    if (!isAuthenticated) {
      // The contents we are diplaying for non-authenticated user is private.
      // The non-authenticated user should only be able to share that was given.
      const { origin, pathname, search } = window.location;
      const url = `${origin}${pathname}${search}`;
      onComplete?.(url);
      return;
    }

    const uploadJson = async () => {
      // Steps
      // 1. generate put pre-signed url for uploading
      // 2. upload the serialized json
      // 3. generate url (this will be generated with bucket path combined with json file name)

      // generate put pre-signed url
      const filename = `everything-ads-${Date.now()}.json`;
      const contentType = "application/json";
      const { error, result } = await API.services.common.generatePresignedUrl({
        contentType,
        filename,
        s3Path: "share-link/everything-ads",
      });

      if (error || !result)
        return showErrorMessage("Unable to generate share link.");

      const { url } = result; // this is uploading pre-signed url

      // upload json
      const res = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": contentType,
        },
        body: JSON.stringify(instance.toObject()),
      });

      if (res.status !== 200)
        return showErrorMessage("Unable to generate share link.");

      return filename;
    };

    uploadJson().then(filename => {
      if (!filename) return;

      const { origin } = window.location;
      const url = `${origin}/everythingads?shared=${filename}`;
      onComplete(url);
    });
  }, [instance, initiatingShareLink, onComplete, isAuthenticated]);

  const { onCompleteLoadShareLink } = args;
  const sharedLinkQuery = useRouteQuery("shared");
  useEffect(() => {
    if (!sharedLinkQuery) return;

    const fetchJson = async (filename: string) => {
      const prefix = getEnvVar("EVERYTHING_ADS_SHARE_LINK_URL");
      if (!prefix) {
        message.error(
          "Unable to load data due to missing system configuration (share-link-env-prefix).",
        );
        return null;
      }

      const url = `${prefix}${filename}`;
      return HttpClient.get<any>(url, undefined, false);
    };

    fetchJson(sharedLinkQuery).then(json => {
      if (!json) return message.error("Unable to load data.");
      onCompleteLoadShareLink(json);
    });
  }, [onCompleteLoadShareLink, sharedLinkQuery]);

  const navigate = useNavigate();

  // TODO: this is handling auth redirect - fix this
  useEffect(() => {
    if (isAuthenticated || sharedLinkQuery) return; // This condition should go through the regular process of share link.

    navigate("/signin");
  }, [sharedLinkQuery, isAuthenticated, navigate]);
  return {
    elements: [],
  };
};
