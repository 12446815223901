import { InfiniteData, useQueryClient } from "react-query";

import { IQCHistoryLog } from "shared/types/shared";
import { IGetQCHistoryLogsResult } from "shared/types/adReview";

type SetQCHistoryLogsQueryDataArgs = {
  qcHistoryLog: IQCHistoryLog;
  operation: "mutate" | "delete";
};

const useSetQCHistoryLogsQueryData = () => {
  const queryClient = useQueryClient();

  return async ({ qcHistoryLog, operation }: SetQCHistoryLogsQueryDataArgs) => {
    const queryKey = ["qcHistoryLogsPages", qcHistoryLog.id];
    const qcHistoryLogsRes: InfiniteData<IGetQCHistoryLogsResult> | undefined =
      queryClient.getQueryData(queryKey);

    const willDelete = !!qcHistoryLog.id && operation === "delete";

    // Cancel any outgoing re-fetches (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries(queryKey);

    // Optimistically update the qcHistoryLog
    queryClient.setQueryData<typeof qcHistoryLogsRes>(queryKey, data => {
      if (!data) return;

      const newQCHistoryLogPages = data.pages.map((page, index) => {
        const existingQCHistoryLog = page.qcHistoryLogs.some(
          prevQCHistoryLog => prevQCHistoryLog?.id === qcHistoryLog.id,
        );

        const newQCHistoryLogs = !willDelete
          ? page.qcHistoryLogs.map(originalQCHistoryLog =>
              qcHistoryLog.id === originalQCHistoryLog.id
                ? qcHistoryLog
                : originalQCHistoryLog,
            )
          : page.qcHistoryLogs.filter(
              prevQCHistoryLog => prevQCHistoryLog.id !== qcHistoryLog.id,
            );

        const newPageData = {
          ...page,
          qcHistoryLogs: newQCHistoryLogs,
        };

        if (existingQCHistoryLog && index === data.pages.length - 1) {
          newPageData.qcHistoryLogs.push(qcHistoryLog);
        }
        return newPageData;
      });

      return { pages: newQCHistoryLogPages, pageParams: data.pageParams };
    });
    return qcHistoryLogsRes;
  };
};

export default useSetQCHistoryLogsQueryData;
