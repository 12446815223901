import { useState } from "react";
import { Select } from "antd";
import { AdReviewTableEntry } from "shared/types/campaignManagement";
import { QCStatus } from "shared/types/shared";
import QcStatusBadge from "./QcStatusBadge";
import cn from "classnames";

import styles from "./QCStatusSelect.module.scss";
import { useQCStatus } from "../hooks/useQCStatus";

interface IQCStatusSelectProps {
  record: AdReviewTableEntry;
  align?: "default" | "right";
  readonly?: boolean;
}

const QCStatusSelect = ({
  record,
  align = "default",
  readonly = false,
}: IQCStatusSelectProps) => {
  const { handleQCHistoryLogInsert, qcStatus } = useQCStatus({
    record,
  });
  const [displaySelect, setDisplaySelect] = useState(false);

  return (
    <Select
      value={qcStatus}
      disabled={readonly}
      size="small"
      style={{ width: 115 }}
      onMouseEnter={() => !readonly && setDisplaySelect(true)}
      onMouseLeave={() => setDisplaySelect(false)}
      onChange={(value: QCStatus) => {
        handleQCHistoryLogInsert(value);
      }}
      className={cn(styles.qcStatusDropdown, {
        [styles.qcStatusDropdownInvisible]: !displaySelect,
        [styles.qcStatusDropdownAlignRight]:
          !displaySelect && align === "right",
      })}
    >
      <Select.Option value={QCStatus.NOT_QCed}>
        <QcStatusBadge qcStatus={QCStatus.NOT_QCed} />
        {QCStatus.NOT_QCed}
      </Select.Option>
      <Select.Option value={QCStatus.APPROVED}>
        <QcStatusBadge qcStatus={QCStatus.APPROVED} />
        {QCStatus.APPROVED}
      </Select.Option>
      <Select.Option value={QCStatus.DENIED}>
        <QcStatusBadge qcStatus={QCStatus.DENIED} />
        {QCStatus.DENIED}
      </Select.Option>
    </Select>
  );
};

export default QCStatusSelect;
