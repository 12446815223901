import {
  ICreateStampResponse,
  INewStamp,
  IResponse,
  IStamp,
} from "shared/types/designStudio";

import { offerTypes } from "shared/constants/dataManagement";
import { OfferType } from "shared/types/shared";

import {
  createStampBegin,
  createStampFail,
  createStampSuccess,
  deleteStampBegin,
  deleteStampFail,
  deleteStampSuccess,
  updateStampBegin,
  updateStampFail,
  updateStampSuccess,
  fetchData,
} from "redux/designStudio/designStudio.slice";
import { AppThunk } from "redux/store";
import API from "services";
import GenericError from "shared/errors/GenericError";
import { IConfig } from "shared/types/configuration";

/**
 *
 * @param stamp
 * NOTE: the stamp passed into this action will have stamp.offerTypes === null.
 * The reason is that when we create stamp, we have to create stamp for each offer type.
 * Here, before we send the request, create array of all offer type and send it.
 */
export const createStamp =
  (stamp: INewStamp): AppThunk =>
  async (dispatch, getState) => {
    dispatch(createStampBegin());

    const { configuration } = getState();
    const { config } = configuration;

    const request: RequestInfo = new Request(
      config!.services.designStudio.createStampUrl,
      {
        method: "POST",
        body: JSON.stringify({
          stamps: offerTypes.map(offerType => ({
            ...stamp,
            offerType,
          })),
        }),
      },
    );

    const { result, error } = await API.send<ICreateStampResponse>(request);

    if (error) {
      return dispatch(createStampFail(stamp));
    }

    const { stamps: createdStamps } = result; // the endpoint return set of stamps for each offer type

    dispatch(createStampSuccess({ stamps: createdStamps }));
  };

export const duplicateStamp =
  (stamp: IStamp): AppThunk =>
  async (dispatch, getState) => {
    dispatch(createStampBegin());

    const { configuration, designStudio } = getState();
    const { config } = configuration;

    let potentialName = stamp.name;
    let potentialNameExists = true;

    while (potentialNameExists) {
      potentialNameExists = false;
      for (const stamp of designStudio.stamps) {
        if (stamp.name === potentialName) {
          potentialName = `${potentialName}-Copy`;
          potentialNameExists = true;
        }
      }
    }

    const duplicateStamp = {
      ...stamp,
      name: potentialName,
    } as IStamp;

    const request: RequestInfo = new Request(
      (config as IConfig).services.designStudio.duplicateStampUrl,
      {
        method: "POST",
        body: JSON.stringify({
          stampId: duplicateStamp.id,
          stampName: duplicateStamp.name,
        }),
      },
    );

    const { result, error } = await API.send<ICreateStampResponse>(request);

    if (error || !result) {
      return dispatch(createStampFail(stamp));
    }

    const { stamps: createdStamps } = result; // the endpoint returns set of stamps for each offer type

    dispatch(createStampSuccess({ stamps: createdStamps, duplicating: true }));
  };

export const updateStamp =
  (stamp: IStamp): AppThunk =>
  async (dispatch, getState) => {
    dispatch(updateStampBegin());

    const { configuration } = getState();
    const { config } = configuration;

    const request: RequestInfo = new Request(
      (config as IConfig).services.designStudio.createStampUrl,
      {
        method: "put",
        body: JSON.stringify({
          stampToUpdate: stamp,
        }),
      },
    );

    const { result, error } = await API.send<IResponse<IStamp[]>>(request);

    if (error) {
      return dispatch(updateStampFail(error));
    }

    const { stamps } = result || { stamps: null }; // multiple stamps for each offer type

    if (!stamps || stamps.length === 0) {
      return dispatch(
        updateStampFail(
          new GenericError({
            message: "Updating stamps failed.",
          }),
        ),
      );
    }

    dispatch(updateStampSuccess(stamps));
    dispatch(fetchData("stamps"));
  };

export const deleteStamp =
  (id: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(deleteStampBegin(id));

    const { configuration } = getState();
    const { config } = configuration;

    const request: RequestInfo = new Request(
      `${(config as IConfig).services.designStudio.createStampUrl}/${id}`,
      {
        method: "delete",
      },
    );
    const { error } = await API.send<
      IResponse<Array<{ id: string; offerType: string }>>
    >(request); // list of objects with primary key and range key of DynamoDB will be returned.

    if (error) {
      return dispatch(deleteStampFail(error));
    }

    return dispatch(deleteStampSuccess(id));
  };
