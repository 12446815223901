import { AccountDetailsFacebook } from "shared/types/accountManagement";
import FacebookAssetSelect from "./FacebookAssetSelect";
import {
  IFacebookAccount,
  IFacebookAssetType,
  IFacebookObject,
} from "../facebookUtils/types";
import { Input } from "antd";

type Props = {
  title: IFacebookAssetType;
  initialValue: string;
  willSetValueOnLoad: boolean | undefined;
  selectedAccount: IFacebookAccount | null | undefined;
  enableInput: boolean;
  setFacebookIntegration: (facebookIntegration: boolean) => void;
  onSelect: (selected: IFacebookObject | null) => void;
  onChangeInput: React.ChangeEventHandler<HTMLInputElement> | undefined;
};
export const FacebookAssetSelectOrInput = ({
  title,
  initialValue,
  willSetValueOnLoad,
  selectedAccount,
  enableInput,
  setFacebookIntegration,
  onSelect,
  onChangeInput,
}: Props) => {
  return (
    <>
      {enableInput ? (
        <FacebookAssetSelect
          title={title}
          willSetValueOnLoad={willSetValueOnLoad}
          initialValue={initialValue}
          selectedAccount={selectedAccount}
          setFacebookIntegration={setFacebookIntegration}
          onSelect={onSelect}
        />
      ) : (
        <Input value={initialValue} onChange={onChangeInput} />
      )}
    </>
  );
};
