import { useQuery } from "react-query";
import { useCAMHook, useCAMSearch } from "shared/components/media";
import { OfferData } from "shared/types/assetBuilder";
import { IConfig } from "shared/types/configuration";
import { ITemplate } from "shared/types/designStudio";
import { fetchLifestyleImages } from "./ImageSelection.utils";
import { mapToImage } from "./mapToImage";

type Params = {
  template: ITemplate | undefined;
  offerData: OfferData | null;
  config?: IConfig;
  onSettled: () => void;
};

export const useFetchLifestyleImages = ({
  template,
  offerData,
  config,
}: Params) => {
  const searchMedia = useCAMHook(useCAMSearch)();
  const { make, year, vehicleCondition } = offerData ?? {};

  return useQuery(
    ["lifestyleImages", make],
    async () => {
      if (searchMedia) {
        if (vehicleCondition === "New") {
          const baseWDSearchParams: WDSearchField[] = [
            {
              field_name: "keywords",
              filter_type: "exact",
              value: "Lifestyle",
            },
            {
              field_name: "filetype",
              filter_type: "match",
              operator: "OR",
              value: "png,jpg,jpeg",
            },
            {
              field_name: "make",
              filter_type: "exact",
              value: make,
            },
          ];

          const lifeStyleSearch = await searchMedia({
            filters: {
              fields: baseWDSearchParams,
            },
          });

          const imageMapper = mapToImage("lifestyle", year, make);
          const mappedImages = imageMapper(lifeStyleSearch);

          return mappedImages;
        }

        return [];
      }

      return (
        (await fetchLifestyleImages({
          year: year ? parseInt(year) : 0,
          config: config!,
          make,
        })) ?? []
      );
    },
    { enabled: !!template && !!config },
  );
};
