import { useDispatch } from "react-redux";
import { FacebookAdPart, TaxonomiesObject } from "shared/types/adLibrary";
import { setTaxonomies } from "redux/adLibrary/adLibrary.slice";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { selectAdLoadParameters } from "redux/adLibrary/adLibrary.selectors";

interface IUseTaxonomies {
  adPart: FacebookAdPart;
}

const useTaxonomies = ({ adPart }: IUseTaxonomies) => {
  const dispatch = useDispatch();
  const adLoadParameters = useAppSelector(selectAdLoadParameters);

  const updateTaxonomies = (taxonomies: string[]) => {
    const updatedTaxonomies = {
      ...adLoadParameters?.taxonomies,
      [adPart]: taxonomies,
    } as TaxonomiesObject;

    dispatch(setTaxonomies(updatedTaxonomies));
  };

  return {
    taxonomies: adLoadParameters?.taxonomies?.[adPart] ?? [],
    updateTaxonomies,
  };
};

export default useTaxonomies;
