import { useMediaContext } from "./MediaProvider";

export const useFileTypes = () => {
  const { fileTypes } = useMediaContext();
  const canSelect = (resource: WDFolder | WDAsset) => {
    const filetype = "filetype" in resource ? resource.filetype : "folder";

    return fileTypes?.includes(filetype) ?? true;
  };

  return {
    fileTypes,
    canSelect,
  };
};
