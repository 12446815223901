import { useQuery } from "react-query";
import API from "services";
import { Platform, PlatformHookParams, PlatformTokenStatus } from "../types";
import { getPlatformTokenKey } from "../utils";

export const usePlatformTokenStatus = ({
  platform,
  onError,
}: PlatformHookParams) => {
  const getPlatformToken = (platform: Platform) => async () => {
    const { result, error } = await API.services.platformManagement.getToken(
      platform,
    );

    if (error || !result) {
      throw Error(error?.message || "Unknown error");
    }

    return result;
  };

  return useQuery<PlatformTokenStatus | null | undefined, Error>(
    getPlatformTokenKey(platform),
    getPlatformToken(platform),
    {
      onError,
    },
  );
};
