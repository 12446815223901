/* eslint-disable react/display-name */
import { CalendarOutlined, SearchOutlined } from "@ant-design/icons";
import { message, Modal, Spin } from "antd";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { resetInstantExperiencesImport } from "redux/designStudio/designStudio.slice";
import TablePopup from "screens/adLibrary/library/adList/adTableListContainer/TablePopup";
import {
  useEverythingAdsGlobalFilter,
  useEverythingAdsIds,
  useEverythingAdsSorter,
} from "screens/adLibrary/shared/hooks/dataListHooks";
import InstantExperiencesCompleteImportDrawer from "screens/designStudio/library/instantExperiences/InstantExperiencesCompleteImportDrawer";
import { DateFilterDropdown } from "shared/components/DateFilterDropdown";
import { SearchFilterDropdown } from "shared/components/SearchFilterDropdown";
import ToolbarTable, {
  ToolbarButton,
} from "shared/components/toolbarTable/ToolbarTable";
import { DataListTagsURL } from "shared/components/dataListURL/DataListTagsURL";
import DataListURLTable, {
  ITableColumnURL,
} from "shared/components/dataListURL/DataListURLTable";
import {
  getInstantExpCopy,
  useDeleteInstantExperience,
  useMutateInstantExperience,
} from "shared/hooks/adLibrary/useMutateInstantExperience";
import useResizableTable from "shared/hooks/useResizableTable";
import { useWindowSize } from "shared/hooks/useWindowSize";
import useSelectedColumns from "shared/hooks/userPreferences/useSelectedColumns";
import {
  IInstantExperience,
  InstantExperienceTableEntry,
  PopupState,
} from "shared/types/adLibrary";
import { isFeatureEnabled } from "utils/helpers";
import InstantExperienceLoadModal from "../../designStudio/library/instantExperiences/InstantExperienceLoadModal";
import InstantExperiencesImportDrawer from "../../designStudio/library/instantExperiences/InstantExperiencesImportDrawer";
import { useEverythingAds } from "../hooks/useEverythingAdsData";
import styles from "./InstantExperienceList.module.scss";
import UpdatedAtWithRowButtons from "./UpdatedAtWithRowButtons";
import {
  AdLibraryTableColumn,
  IEverythingAdFields,
  everythingAdFieldKeys,
} from "./fields";
import GenerateUrlDrawer from "screens/designStudio/library/instantExperiences/GenerateUrlDrawer";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { useAppDispatch } from "shared/hooks/useAppDispatch";

const InstantExperienceList = ({
  defaultColumns = [
    "Name",
    "Brand",
    "Created By",
    "Created At",
    "Updated By",
    "Updated At",
  ],
}) => {
  const isGenerateUrlEnabled = isFeatureEnabled(
    "ENABLE_EVERYTHING_ADS_GENERATE_URL",
  );

  const [actionRow, setActionRow] = useState<IInstantExperience>();
  const [popupState, setPopupState] = useState<PopupState>({ visible: false });
  const { isColumnSelected, selectedColumns } =
    useSelectedColumns(defaultColumns);
  const {
    data: instantExperiences,
    originalData: originalInstantExperiences,
    isLoading,
  } = useEverythingAds();
  const { globalFilter, setGlobalFilter } = useEverythingAdsGlobalFilter();
  const { sortItems, sortKey, sortOrder } = useEverythingAdsSorter();
  const { setSelectedItemIds, selectedIds } = useEverythingAdsIds();

  const { mutate: createInstantExperience } = useMutateInstantExperience();

  const dispatch = useAppDispatch();
  const [displayImportDrawer, setDisplayImportDrawer] = useState(false);
  const displayImportTable = useAppSelector(
    state => state.designStudio.instantExperienceImport.displayImportTable,
  );
  const [displayGenerateUrl, setDisplayGenerateUrl] = useState(false);
  const [activeRowForUrl, setActiveRowForUrl] = useState<IInstantExperience>();

  const selectedInstExpId = activeRowForUrl?.id ?? selectedIds[0];

  const [rootInstantExperience, setRootInstantExperience] =
    useState<IInstantExperience | null>(null);

  const { mutate: deleteInstantExperience } = useDeleteInstantExperience();
  const navigate = useNavigate();

  const isImportEnabled = isFeatureEnabled("ENABLE_INSTANT_EXPERIENCES_IMPORT");

  const defaultSelected =
    instantExperiences.find(({ id }) => id === selectedIds[0]) ||
    instantExperiences[0];

  const onClickEdit = (id?: string) => {
    navigate(
      "/everything-ads/ads/:instantExperienceId/edit".replace(
        ":instantExperienceId",
        id ?? selectedIds[0] ?? "",
      ),
    );
  };

  const onClickLoadEA = (ea?: IInstantExperience) => {
    setRootInstantExperience(ea ?? defaultSelected);
  };

  const onClickDuplicate = (ea?: IInstantExperience) => {
    const record = ea ?? defaultSelected;
    const recordCopy = getInstantExpCopy(record);

    createInstantExperience(recordCopy, {
      onSuccess: () => {
        message.success(`${record.name} was duplicated`);
      },
      onError: () => {
        message.error(`${record.name} could not be duplicated`);
      },
    });
    setActionRow(undefined);
    setSelectedItemIds([]);
  };

  const onConfirmDelete = (ea?: IInstantExperience) => {
    const record = ea ?? defaultSelected;

    deleteInstantExperience(record, {
      onSuccess: () => {
        message.success(`${record.name} was deleted`);
      },
      onError: () => {
        message.error(`${record.name} could not be deleted`);
      },
    });
    setActionRow(undefined);
    setSelectedItemIds([]);
  };

  const ConfirmModal = () => {
    return (
      <Modal
        title={`Delete Everything Ads`}
        width="300px"
        closable={false}
        visible={!!actionRow}
        okText={"Delete"}
        onCancel={() => setActionRow(undefined)}
        onOk={() => onConfirmDelete(actionRow)}
      >
        {`Are you sure you want to delete ${actionRow?.name}?`}
      </Modal>
    );
  };

  const toolbarContents: ToolbarButton = {
    New: {
      disabled: false,
      onClick: () => {
        navigate("/everything-ads/ads/create");
      },
    },
    Edit: {
      disabled: selectedIds.length !== 1,
      onClick: () => onClickEdit(),
    },
    LoadEA: {
      disabled: selectedIds.length !== 1,
      onClick: () => onClickLoadEA(),
    },
    Duplicate: {
      disabled: selectedIds.length !== 1,
      onClick: () => onClickDuplicate(),
    },
    Delete: {
      disabled: selectedIds.length !== 1,
      onClick: () => setActionRow(defaultSelected),
    },
    Import: {
      disabled: !isImportEnabled,
      onClick: () => setDisplayImportDrawer(true),
    },
    ...(isGenerateUrlEnabled
      ? {
          GenerateUrl: {
            disabled: selectedIds.length !== 1,
            onClick: () => setDisplayGenerateUrl(true),
          },
        }
      : {}),
  };

  const columns: ITableColumnURL<
    IEverythingAdFields,
    InstantExperienceTableEntry
  >[] = [
    {
      key: "name",
      title: "Name",
      dataIndex: ["name"],
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 290,
    },
    {
      key: "oem",
      title: "Brand",
      dataIndex: ["oem"],
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 290,
    },
    {
      key: "createdBy",
      title: "Created By",
      dataIndex: ["createdBy"],

      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 220,
    },
    {
      key: "createdAt",
      title: "Created At",
      dataIndex: ["createdAt"],
      render: (createdAt: string) => {
        return (
          <span data-cy="created-at-cell">
            {moment(createdAt).format("YYYY/MM/DD hh:mmA")}
          </span>
        );
      },
      filterDropdown: DateFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <CalendarOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 120,
    },
    {
      key: "updatedBy",
      title: "Updated By",
      dataIndex: ["updatedBy"],
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 220,
    },
    {
      key: "updatedAt",
      title: "Updated At",
      dataIndex: ["updatedAt"],
      render: (_, record) => {
        const isSelected = selectedIds.includes(record.id ?? "");
        return (
          <UpdatedAtWithRowButtons
            onClickEdit={onClickEdit}
            onClickLoadEA={onClickLoadEA}
            onClickDuplicate={onClickDuplicate}
            setActionRow={setActionRow}
            record={record}
            isSelected={isSelected}
          />
        );
      },
      filterDropdown: DateFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <CalendarOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 200,
    },
  ];

  const { components, resizableColumns } = useResizableTable<
    InstantExperienceTableEntry,
    AdLibraryTableColumn & { name: string }
  >(columns.map(column => ({ ...column, name: column.title })));
  const filteredColumns = (resizableColumns ?? [])
    .filter(c => isColumnSelected(c.title as string))
    .sort(
      (a, b) =>
        selectedColumns.indexOf(a.title as string) -
        selectedColumns.indexOf(b.title as string),
    );

  const { windowInnerHeight } = useWindowSize();
  return (
    <Spin spinning={isLoading}>
      <div className={styles.container}>
        <ConfirmModal />
        <ToolbarTable
          searchPlaceholder="Search"
          titleTooltip={""}
          toolbarContents={toolbarContents}
          searchValue={globalFilter || ""}
          sortingOrder={
            sortKey
              ? [sortKey as string, sortOrder?.replace("end", "") as string]
              : undefined
          }
          onSearch={value => setGlobalFilter(value)}
          onSortChange={([columnKey, order]) => {
            sortItems(columnKey, order);
          }}
        />
        <DataListTagsURL
          data={instantExperiences}
          originalData={originalInstantExperiences}
        />
        <DataListURLTable<IEverythingAdFields, InstantExperienceTableEntry>
          data-cy="everything-ads-table-container"
          scroll={{ y: windowInnerHeight - 205 }}
          size={"small"}
          rowKey={record => record.id}
          rowClassName={styles.everythingAdsLibRow}
          columns={filteredColumns}
          components={components}
          dataSource={instantExperiences}
          type="virtual"
          onRow={record => ({
            onContextMenu: event => {
              event.preventDefault();
              document.addEventListener("click", function onClickOutside() {
                document.removeEventListener("click", onClickOutside);
              });

              if (!popupState.visible) {
                document.addEventListener("click", function onClickOutside() {
                  setPopupState({ visible: false });
                  document.removeEventListener("click", onClickOutside);
                });
              }
              setPopupState({
                record,
                visible: true,
                x: event.clientX,
                y: event.clientY,
              });
            },
          })}
          fieldKeys={everythingAdFieldKeys}
        />
        {rootInstantExperience && (
          <InstantExperienceLoadModal
            instantExperiences={instantExperiences ?? []}
            rootInstantExperience={rootInstantExperience}
            setRootInstantExperience={setRootInstantExperience}
          />
        )}
        {popupState.record && (
          <TablePopup popupState={popupState}>
            <TablePopup.EditItem
              onClick={() => onClickEdit(popupState.record?.id)}
            />
            <TablePopup.LoadItem
              onClick={() => onClickLoadEA(popupState.record)}
            />
            <TablePopup.DuplicateItem
              onClick={() => onClickDuplicate(popupState.record)}
            />
            <TablePopup.DeleteItem
              onClick={() => setActionRow(popupState.record)}
            />
            {isGenerateUrlEnabled && (
              <TablePopup.GenerateUrl
                onClick={() => {
                  setActiveRowForUrl(popupState.record);
                  setDisplayGenerateUrl(true);
                }}
              />
            )}
          </TablePopup>
        )}
      </div>
      {displayImportDrawer && (
        <InstantExperiencesImportDrawer
          handleClose={() => setDisplayImportDrawer(false)}
        />
      )}

      {displayImportTable && (
        <InstantExperiencesCompleteImportDrawer
          handleClose={() => dispatch(resetInstantExperiencesImport())}
        />
      )}
      {displayGenerateUrl && selectedInstExpId && (
        <GenerateUrlDrawer
          handleClose={() => {
            setActiveRowForUrl(undefined);
            setDisplayGenerateUrl(false);
          }}
          selectedInstExpId={selectedInstExpId}
        />
      )}
    </Spin>
  );
};

export default InstantExperienceList;
