import API from "services";

import { useUser } from "../useUser";
import {
  InfiniteData,
  QueryClient,
  useMutation,
  useQueryClient,
} from "react-query";

import { IQCHistoryLog, QCStatus } from "shared/types/shared";
import {
  IBatchModifyQCHistoryLogResult,
  IGetAdsResult,
  IGetQCHistoryLogsResult,
} from "shared/types/adReview";
import { qcHistoryLogsKey } from "./useFetchQCHistoryLogs";
import { first } from "lodash";
import { User } from "redux/auth/auth.slice";

const getNewHistoryLog = (
  qcHistoryLog: IQCHistoryLog,
  user?: User | null,
): IQCHistoryLog => {
  const isoDate = new Date().toISOString();
  return {
    ...qcHistoryLog,
    id: qcHistoryLog?.id,
    lastName: "FIRST_NAME",
    firstName: "LAST_NAME",
    // TODO: rewrite
    // lastName: qcHistoryLog?.lastName ?? user?.lastName,
    // firstName: qcHistoryLog?.firstName ?? user?.firstName,
    createdAt: isoDate,
    updatedAt: isoDate,
  };
};

const modifyQCHistoryLog =
  (user?: User | null) => async (qcHistoryLogs: IQCHistoryLog[]) => {
    const qcHistoryLogsData = qcHistoryLogs.map(qcHistoryLog =>
      getNewHistoryLog(qcHistoryLog, user),
    );
    const { result, error } =
      await API.services.adReview.batchCreateQCHistoryLogs(qcHistoryLogsData);

    if (error) {
      throw Error(error.message);
    }

    return result;
  };

const optimisticUpdateAds = async (
  queryClient: QueryClient,
  qcHistoryLogs: IQCHistoryLog[],
  fetchAdsKey?: string[],
) => {
  if (fetchAdsKey) {
    // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries(fetchAdsKey);

    // Snapshot the previous value
    const previousAds = queryClient.getQueryData(
      fetchAdsKey,
    ) as InfiniteData<IGetAdsResult>;

    // Optimistically update to the new value
    queryClient.setQueryData<InfiniteData<IGetAdsResult> | undefined>(
      fetchAdsKey,
      oldAds => {
        if (oldAds) {
          return {
            ...oldAds,
            pages: oldAds.pages.map(page => {
              return {
                ...page,
                data: page.data.map(ad => {
                  const newQCHistoryLogs = qcHistoryLogs.filter(
                    log => log.adId === ad.id,
                  );
                  return {
                    ...ad,
                    qcHistoryLogs: ad.qcHistoryLogs
                      ? [...newQCHistoryLogs, ...ad.qcHistoryLogs]
                      : undefined,
                  };
                }),
              };
            }),
          };
        }
      },
    );

    return { previousAds };
  }

  return { previousAds: undefined };
};

const optimisticUpdateLogs = async (
  queryClient: QueryClient,
  qcHistoryLogs: IQCHistoryLog[],
  user: User | null | undefined,
) => {
  const groupId = qcHistoryLogs?.[0]?.id;
  // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
  await queryClient.cancelQueries([qcHistoryLogsKey, groupId]);

  // Snapshot the previous value
  const previousQCHistoryLogs = queryClient.getQueryData([
    qcHistoryLogsKey,
    groupId,
  ]) as IGetQCHistoryLogsResult;

  const newQCHistoryLogs = qcHistoryLogs.map(qcHistoryLog =>
    getNewHistoryLog(qcHistoryLog, user),
  );

  // Optimistically update to the new value
  queryClient.setQueryData<IGetQCHistoryLogsResult>(
    [qcHistoryLogsKey, groupId],
    oldQCHistoryLogs => {
      return {
        ...oldQCHistoryLogs,
        qcHistoryLogs: [
          ...newQCHistoryLogs,
          ...(oldQCHistoryLogs?.qcHistoryLogs ?? []),
        ],
      };
    },
  );

  return { previousQCHistoryLogs, groupId };
};

interface IBatchMutateParams {
  fetchAdsKey?: string[];
  options?: {
    onSuccess?: (qcStatus: QCStatus) => void;
    onError: (error: Error) => void;
  };
}

const useBatchMutateQCHistoryLog = (params?: IBatchMutateParams) => {
  const user = useUser();
  const queryClient = useQueryClient();

  return useMutation<
    IBatchModifyQCHistoryLogResult | null,
    Error,
    IQCHistoryLog[],
    {
      previousQCHistoryLogs?: IGetQCHistoryLogsResult;
      groupId?: string;
      previousAds?: InfiniteData<IGetAdsResult>;
    }
  >(modifyQCHistoryLog(user), {
    onMutate: async (qcHistoryLogs: IQCHistoryLog[]) => {
      const { previousQCHistoryLogs, groupId } = await optimisticUpdateLogs(
        queryClient,
        qcHistoryLogs,
        user,
      );

      const { previousAds } = await optimisticUpdateAds(
        queryClient,
        qcHistoryLogs,
        params?.fetchAdsKey,
      );

      return { previousQCHistoryLogs, groupId, previousAds };
    },
    onError: (error, _variables, context) => {
      queryClient.setQueryData(
        [qcHistoryLogsKey, context?.groupId],
        context?.previousQCHistoryLogs,
      );

      if (params?.fetchAdsKey) {
        queryClient.setQueryData(params?.fetchAdsKey, context?.previousAds);
      }

      params?.options?.onError(error);
    },
    onSettled: (_res, _err, _variables, context) => {
      queryClient.invalidateQueries([qcHistoryLogsKey, context?.groupId]);

      if (params?.fetchAdsKey) {
        queryClient.invalidateQueries(params?.fetchAdsKey);
      }
    },
    onSuccess: data => {
      const qcStatus = first(data?.qcHistoryLogs)?.status;

      if (qcStatus && params?.options?.onSuccess) {
        params.options.onSuccess(qcStatus);
      }
    },
  });
};

export default useBatchMutateQCHistoryLog;
