type AiIconProps = {
  height?: number;
  width?: number;
  color?: string;
};

export const AiResizeIcon = ({
  height = 14,
  width = 14,
  color,
}: AiIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 48 48"
    fill={color || "none"}
  >
    <path
      d="M27.2182 0C28.0968 0 28.8091 0.712274 28.8091 1.59091V6.28099H33.4752C34.3424 6.28099 35.0455 6.98402 35.0455 7.85124C35.0455 8.71846 34.3424 9.42149 33.4752 9.42149H28.8091V14.7727C28.8091 15.6514 28.0968 16.3636 27.2182 16.3636C26.3395 16.3636 25.6273 15.6514 25.6273 14.7727V12.7273H25.624V9.42149H13.376C12.4707 9.42149 11.8861 9.42271 11.4412 9.45906C11.0147 9.49391 10.8565 9.55307 10.7789 9.59263C10.4834 9.74318 10.2432 9.9834 10.0926 10.2789C10.0531 10.3565 9.99391 10.5147 9.95906 10.9412C9.92271 11.3861 9.92149 11.9707 9.92149 12.876V25.0906H13.4545C14.3332 25.0906 15.0455 25.8028 15.0455 26.6815C15.0455 27.5601 14.3332 28.2724 13.4545 28.2724H9.92149V32.9752C9.92149 33.8424 9.21846 34.5455 8.35124 34.5455C7.48401 34.5455 6.78099 33.8424 6.78099 32.9752V28.2724H2.09091C1.83248 28.2724 1.58844 28.2108 1.37268 28.1014C0.855472 27.8445 0.5 27.3109 0.5 26.6942C0.5 26.6921 0.500004 26.69 0.500013 26.6879C0.500004 26.6857 0.5 26.6836 0.5 26.6815C0.5 25.8028 1.21227 25.0906 2.09091 25.0906H6.78099L6.78099 12.8155C6.78096 11.9878 6.78094 11.2737 6.82899 10.6855C6.87971 10.0647 6.9917 9.44725 7.29443 8.8531C7.74607 7.96672 8.46672 7.24607 9.3531 6.79443C9.94725 6.4917 10.5647 6.37971 11.1855 6.32899C11.7737 6.28094 12.4877 6.28096 13.3155 6.28099H25.624V1.57025C25.624 0.703024 26.327 0 27.1942 0C27.1982 0 27.2022 1.48304e-05 27.2062 4.44609e-05C27.2102 1.48376e-05 27.2142 0 27.2182 0Z"
      fill={color || "black"}
    />
    <path
      d="M21.8752 18.6119C21.9757 18.1972 22.2483 17.9283 22.5719 17.8052C22.8236 17.7095 23.1061 17.7019 23.3627 17.7826C23.7146 17.8931 24.0175 18.1696 24.1248 18.6119L24.9118 21.8574C25.0139 22.2785 25.3427 22.6073 25.7638 22.7094L29.0093 23.4964C30.1888 23.7824 30.1888 25.46 29.0093 25.746L25.7638 26.533C25.3427 26.6351 25.0139 26.9639 24.9117 27.385L24.1247 30.6305C24.062 30.8893 23.9323 31.0913 23.765 31.2365C23.3603 31.5878 22.7358 31.6069 22.3071 31.294C22.1052 31.1466 21.9467 30.9254 21.8752 30.6305L21.0882 27.385C20.9861 26.9639 20.6573 26.6351 20.2362 26.533L16.9907 25.746C15.8112 25.46 15.8111 23.7824 16.9907 23.4964L20.2362 22.7094C20.6573 22.6073 20.9861 22.2785 21.0882 21.8574L21.8752 18.6119Z"
      fill={color || "black"}
    />
    <path
      d="M32.7073 29.9383C32.5313 29.2125 31.499 29.2125 31.323 29.9383L30.8387 31.9356C30.7758 32.1947 30.5735 32.397 30.3143 32.4599L28.3171 32.9442C27.5913 33.1202 27.5913 34.1525 28.3171 34.3285L30.3143 34.8128C30.5735 34.8757 30.7758 35.078 30.8387 35.3372L31.323 37.3344C31.499 38.0602 32.5313 38.0602 32.7073 37.3344L33.1916 35.3372C33.2545 35.078 33.4568 34.8757 33.716 34.8128L35.7132 34.3285C36.439 34.1525 36.439 33.1202 35.7132 32.9442L33.716 32.4599C33.4568 32.397 33.2545 32.1947 33.1916 31.9356L32.7073 29.9383Z"
      fill={color || "black"}
    />
    <path
      d="M34.5048 18.2717C34.6808 17.5458 35.7132 17.5458 35.8892 18.2717L36.3735 20.2689C36.4363 20.528 36.6386 20.7304 36.8978 20.7932L38.895 21.2775C39.6209 21.4535 39.6209 22.4859 38.895 22.6619L36.8978 23.1462C36.6386 23.209 36.4363 23.4113 36.3735 23.6705L35.8892 25.6677C35.7132 26.3936 34.6808 26.3936 34.5048 25.6677L34.0205 23.6705C33.9577 23.4113 33.7553 23.209 33.4962 23.1462L31.499 22.6619C30.4524 22.5002 30.4524 21.4396 31.499 21.2775L33.4962 20.7932C33.7553 20.7304 33.9577 20.528 34.0205 20.2689L34.5048 18.2717Z"
      fill={color || "black"}
    />
  </svg>
);
