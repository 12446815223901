import { ExportOutlined, CloseOutlined } from "@ant-design/icons";
import { notification } from "antd";
import {
  IExportDataProps,
  IExportGetFileUrlResponse,
} from "shared/types/adLibrary";
import {
  getGsiClient,
  authGoogleUser,
  TokenClientWithCallback,
  sendToGoogleSheets,
  initGapiClient,
} from "utils/google/googleApi";
import {
  Description,
  ErrorDescription,
} from "../components/ExportNotification";
import { prepData } from "./useGenerateExports";
import { blue } from "@ant-design/colors";
import { useAdExportRequests } from "./useAdExportRequests";
import { useEffect } from "react";

export const useGoogleSheetsExports = () => {
  const { removeAdExportRequest } = useAdExportRequests();

  useEffect(() => {
    initGapiClient({
      discoveryDocs: [
        "https://sheets.googleapis.com/$discovery/rest?version=v4",
      ],
    });
  }, []);

  const showExportingNotification = (
    uuid: string,
    urlResponse: IExportGetFileUrlResponse,
  ) => {
    notification.open({
      key: uuid,
      message: "Exporting",
      icon: <ExportOutlined twoToneColor={blue.primary} />,
      description: <Description urlResponse={urlResponse} />,
      duration: null,
      closeIcon: <CloseOutlined />,
      placement: "bottomRight",
      onClose: () => removeAdExportRequest(uuid),
    });
  };

  const showErrorNotification = (uuid: string) => {
    notification.error({
      key: uuid,
      message: <ErrorDescription message="Error authorizing with Google" />,
      placement: "bottomRight",
      duration: null,
      onClose: () => removeAdExportRequest(uuid),
    });
  };

  const handleSheetsExportSuccess = (uuid: string, spreadsheetUrl: string) => {
    const urlResponse: IExportGetFileUrlResponse = {
      uuid,
      status: "DONE",
      url: spreadsheetUrl,
    };
    showExportingNotification(uuid, urlResponse);
    setTimeout(() => {
      const anchor = document.createElement("a");
      anchor.href = spreadsheetUrl;
      anchor.target = "_blank";
      anchor.click();
    }, 1000);
  };

  const startGoogleAuthFlow = async (props: IExportDataProps) => {
    const { uuid } = props;
    const urlResponse: IExportGetFileUrlResponse = {
      uuid,
      status: "GENERATING",
    };
    showExportingNotification(uuid, urlResponse);
    const { columns, data, filename } = prepData(props);
    try {
      const client = getGsiClient(
        "https://www.googleapis.com/auth/spreadsheets",
      );
      await authGoogleUser(client as TokenClientWithCallback, () => {
        sendToGoogleSheets({
          columns,
          data,
          filename,
          handleSuccess: (spreadsheetUrl: string) => {
            handleSheetsExportSuccess(uuid, spreadsheetUrl);
          },
        });
      });
    } catch (e) {
      showErrorNotification(uuid);
    }
  };

  return {
    startGoogleAuthFlow,
  };
};
