import API from "services";
import { useQuery } from "react-query";
import { GoogleAdAccount } from "shared/types/adLibrary";

const getGoogleAdAccounts = async (): Promise<GoogleAdAccount[]> => {
  const { result, error } = await API.services.adLibrary.getGoogleAdAccounts();
  if (!result || error) {
    throw Error(error?.message || "something went wrong!");
  }
  const { results = [] } = result;
  return results.map(rawAdAccount => {
    const { customerClient } = rawAdAccount;
    const { id, descriptiveName, manager } = customerClient;
    return {
      id,
      manager,
      name: descriptiveName,
    };
  });
};

const useFetchGoogleAdAccounts = () => {
  return useQuery(["adAccounts"], () => getGoogleAdAccounts(), {
    staleTime: Infinity,
  });
};

export default useFetchGoogleAdAccounts;
