import { CSSProperties } from "react";
import { ReactComponent as ShareOutlinedIcon } from "statics/images/svg/icons/share-o.svg";

export interface IProps {
  width?: string | number;
  height?: string | number;
  style?: CSSProperties;
}

const ShareOutlined = ({ width = "1em", height = "1em", style }: IProps) => {
  return (
    <span role="img" aria-label="un-publish" className="anticon" style={style}>
      <ShareOutlinedIcon width={width} height={height} />
    </span>
  );
};

export default ShareOutlined;
