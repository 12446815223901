import { FullscreenOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useMediaActions } from "redux/media/media.slice";
import { TooltipPerms } from "./TooltipPerms";
import { usePermsAssets } from "./usePermsAssets";
import { useSelected } from "./useSelected";

export const AssetsMove = () => {
  const { setAssetsToMove } = useMediaActions();
  const { selectedAssets, allAssets } = useSelected();
  const allowed = usePermsAssets("move", true);
  const enabled = allAssets && allowed;

  const handleClick = () => {
    setAssetsToMove(selectedAssets);
  };

  return (
    <TooltipPerms action="Move Assets" allowed={allowed} enabled={allAssets}>
      <Button
        shape="circle"
        icon={<FullscreenOutlined />}
        onClick={handleClick}
        disabled={!enabled}
      />
    </TooltipPerms>
  );
};
