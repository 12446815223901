import { CloseOutlined, ExportOutlined } from "@ant-design/icons";
import { notification, Spin } from "antd";
import { useState } from "react";
import { useAdExportRequests } from "./useAdExportRequests";
import {
  EXPORT_DONE,
  EXPORT_GENERATING,
  useGenerateExportRequests,
} from "./useGenerateExports";
import { blue } from "@ant-design/colors";
import {
  Description,
  ErrorDescription,
} from "../components/ExportNotification";
import { IExportGetFileUrlResponse } from "shared/types/adLibrary";

export function useGenerateExportNotification() {
  const { adExportRequests, handleSetAdExportRequests } = useAdExportRequests();
  const [downloadSent, setDownloadSent] = useState<string[]>([]);

  const successNotification = (urlResponse: IExportGetFileUrlResponse) => {
    if (!urlResponse) return;

    const isGenerating = urlResponse.status === EXPORT_GENERATING;

    notification.open({
      key: urlResponse.uuid,
      message: "Exporting",
      icon: isGenerating ? (
        <Spin />
      ) : (
        <ExportOutlined twoToneColor={blue.primary} />
      ),
      description: <Description urlResponse={urlResponse} />,
      duration: null,
      closeIcon: <CloseOutlined />,
      onClose: () =>
        handleSetAdExportRequests(
          adExportRequests.filter(
            adExportRequest => adExportRequest.uuid !== urlResponse.uuid,
          ),
        ),
      placement: "bottomRight",
    });

    if (
      urlResponse.status === EXPORT_DONE &&
      !downloadSent.includes(urlResponse.uuid)
    ) {
      triggerForceDownload({
        url: urlResponse.url,
        uuid: urlResponse.uuid,
      });
    }
  };

  const triggerForceDownload = ({
    url,
    uuid,
  }: {
    url?: string;
    uuid?: string;
  }) => {
    if (!url || !uuid) {
      return;
    }
    setDownloadSent(prevState => {
      return [...prevState, uuid];
    });

    // Give the notification a second to redraw so the user sees the export has finished
    setTimeout(() => {
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.target = "_blank";
      anchor.click();
    }, 1000);
  };

  const errorNotification = (uuid: string) => {
    handleSetAdExportRequests(
      adExportRequests.filter(adExportRequest => adExportRequest.uuid !== uuid),
    );
    notification.error({
      key: uuid,
      message: <ErrorDescription />,
      placement: "bottomRight",
      duration: null,
    });
  };

  useGenerateExportRequests({
    adExportRequests,
    handleOnSuccess: successNotification,
    handleOnError: errorNotification,
  });
}
