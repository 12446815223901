import message from "antd/lib/message";

import { useMutation, useQueryClient } from "react-query";
import API from "services";
import { DeleteSmartColumn } from "shared/types/assetExporter";
import { getErrorMessage } from "utils/errorMessage";

type TDeleteArgs = {
  feedId: string;
  key: string;
};

const messageKey = "delete-smart-column-key";

const smartColumnDelete = async ({ feedId, key }: TDeleteArgs) => {
  try {
    const response = await API.services.assetExporter.deleteSmartColumn(
      feedId,
      key,
    );

    const { result, error } = response;
    if (error) throw new Error(error.message);
    if (!result) throw new Error("Unable to delete smart column");

    return response;
  } catch (error) {
    const errMsg = getErrorMessage(error);
    throw new Error(errMsg);
  }
};

export const useSmartColumnDelete = () => {
  const queryClient = useQueryClient();
  const result = useMutation<DeleteSmartColumn, Error, TDeleteArgs>(
    args => smartColumnDelete(args),
    {
      onError: error => {
        message.error(
          {
            key: messageKey,
            content: error.message,
          },
          3,
        );
      },
      onSuccess: (_data, variables) => {
        message.success(
          {
            key: messageKey,
            content: `The smart column, ${variables.key} was successfully deleted.`,
          },
          3,
        );
      },
      onSettled: (_response, _error, reqArgs) => {
        queryClient.invalidateQueries(["feedConfig", reqArgs.feedId]);
      },
      onMutate: () => {
        message.destroy(messageKey);
      },
    },
  );

  if (result.isLoading) {
    message.loading({
      key: messageKey,
      content: "Updating feed data...",
    });
  }

  return result;
};
