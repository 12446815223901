import API from "services";
import AdAccount, { adAccount } from "./adAccount";
import { IGetAdVideoResponseData, IProxyRequestBody } from "./types";
import { queryClient } from "queryClient";

export const createAdVideo = async (fileUrl: string, accountId?: string) => {
  const adVideo = await (!accountId
    ? adAccount
    : new AdAccount(accountId)
  ).createAdVideo({
    file_url: fileUrl,
  });

  return { id: adVideo.id };
};

export const getAdVideoData = async (
  videoId: string,
  pageId?: string,
): Promise<IGetAdVideoResponseData> => {
  return adAccount.getAdAssetData<IGetAdVideoResponseData>(
    videoId,
    "video",
    pageId,
  );
};

interface ICheckMediaStatusResponse {
  id: string;
  status: {
    /**
     * Status of a video, either
     * "ready" (uploaded, encoded, thumbnails extracted),
     * "processing" (not ready yet) or
     * "error" (processing failed).
     */
    video_status: "ready" | "processing" | "error";
  };
}

export const checkVideoStatus = async (videoId: string) => {
  const proxyBody: IProxyRequestBody = {
    endpoint: `/${videoId}`,
    method: "GET",
    platform: "facebook",
    query: "?fields=status",
  };

  return API.services.adLibrary.platformProxyRequest<ICheckMediaStatusResponse>(
    proxyBody,
  );
};

const checkVideoIsReady = async (videoId: string) => {
  const { result } = await checkVideoStatus(videoId);
  if (result?.status?.video_status !== "ready") {
    throw result;
  }

  return result;
};

export const pullUntilVideoIsReady = async (
  videoId: string,
): Promise<ICheckMediaStatusResponse> => {
  return queryClient.fetchQuery(
    ["checkVideoStatus", videoId],
    () => checkVideoIsReady(videoId),
    {
      staleTime: Infinity,
      retry: 50,
      retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 5000),
    },
  );
};
