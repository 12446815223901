import { StringParam } from "serialize-query-params";
import { Field } from "shared/components/dataListURL/types";

import { GoogleAdCampaignInternal } from "shared/types/adLibrary";
import { checkDateMatch, formatDateValue } from "utils/helpers";
import { compareStringBy, onFilterBy } from "utils/helpers.table";

export type PmaxCampaignField = Field<GoogleAdCampaignInternal>;

export type PmaxCampaignKey = keyof GoogleAdCampaignInternal;
type Fields = Partial<Record<PmaxCampaignKey, PmaxCampaignField>>;

export type PmaxCampaignsFields = keyof Fields;

const getTimeStamp = (value: string | number | undefined) => {
  if (!value) return 0;
  return new Date(value).getTime();
};

const getDateFieldConfig = (
  fieldName: "lastEditedAt" | "createdAt" | "startDate" | "endDate",
) => ({
  sorterFn: (a: GoogleAdCampaignInternal, b: GoogleAdCampaignInternal) => {
    const aTimeStamp = getTimeStamp(a[fieldName]);
    const bTimeStamp = getTimeStamp(b[fieldName]);
    return aTimeStamp - bTimeStamp;
  },
  filterFn: (value: string | string[], record: GoogleAdCampaignInternal) => {
    return checkDateMatch(
      value.toString(),
      new Date(record[fieldName] ?? "").getTime(),
    );
  },
  queryParamConfigFilter: StringParam,
  display: (value: string) => value.split(" ").map(formatDateValue).join(" - "),
});

export const pmaxCampaignsFields: Fields = {
  name: {
    filterFn: onFilterBy("name", { matchMode: "includes" }),
    sorterFn: compareStringBy("name"),
    queryParamConfigFilter: StringParam,
  },
  dailyBudget: {
    sorterFn: compareStringBy("dailyBudget"),
  },
  startDate: getDateFieldConfig("startDate"),
  endDate: getDateFieldConfig("endDate"),
  loadStatus: {
    filterFn: onFilterBy("loadStatus"),
    sorterFn: compareStringBy("loadStatus"),
    queryParamConfigFilter: StringParam,
  },
  lastEditedAt: getDateFieldConfig("lastEditedAt"),
  lastEditedBy: { sorterFn: compareStringBy("lastEditedBy") },
  createdAt: {
    ...getDateFieldConfig("createdAt"),
    defaultSortOrder: "descend",
  },
};

export const pmaxCampaignsFieldsKeys = Object.keys(
  pmaxCampaignsFields,
) as Array<keyof typeof pmaxCampaignsFields>;
