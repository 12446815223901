import { Button } from "antd";
import {
  IUploadImageFormInput,
  ISingleLogoObject,
  TUploadSetLogosInDictionary,
  TUploadGroup,
} from "shared/types/uploadManagement";
import { OperationMode } from "shared/types/inputValues";
import { IAccountRecord } from "shared/types/accountManagement";
import { useIsAdmin } from "shared/hooks/useIsAdmin";

interface Props {
  mode: OperationMode;
  logosInDictionary: Record<TUploadSetLogosInDictionary, ISingleLogoObject[]>;
  setSingleLogoObject: (singleLogoObject: ISingleLogoObject) => void;
  setLogosInDictionary: (
    logosInDictionary: Record<TUploadSetLogosInDictionary, ISingleLogoObject[]>,
  ) => void;
  uploadImages: (
    imagesToUpload: IUploadImageFormInput[],
    featureName: string,
    group: TUploadGroup,
    mode: OperationMode,
  ) => void;

  dealerToEditWithLogos: IAccountRecord;
  setDealerToEditWithLogos: (dealerToEditWithLogos: IAccountRecord) => void;
}

const UploadStep = ({
  mode,
  logosInDictionary,
  uploadImages,
  setSingleLogoObject,
  setLogosInDictionary,
}: Props) => {
  const isAdmin = useIsAdmin();

  const handleSubmit = () => {
    const getLogosForUpload = (logosKey: TUploadSetLogosInDictionary) =>
      logosInDictionary[logosKey].reduce<IUploadImageFormInput[]>(
        (acc, logo) => {
          const key = Object.keys(logo)[0] as keyof ISingleLogoObject;
          if (logo[key]) {
            acc.push(logo[key] as IUploadImageFormInput);
          }
          return acc;
        },
        [],
      );

    const horizLogosForUpload = getLogosForUpload("horizontalLogos");
    const vertLogosForUpload = getLogosForUpload("verticalLogos");
    const squareLogosForUpload = getLogosForUpload("squareLogos");

    uploadImages(horizLogosForUpload, "dealer-management", "Horizontal", mode);
    uploadImages(vertLogosForUpload, "dealer-management", "Vertical", mode);
    uploadImages(squareLogosForUpload, "dealer-management", "Square", mode);

    setLogosInDictionary({
      horizontalLogos: [],
      verticalLogos: [],
      squareLogos: [],
      horizontalLogosEvent: [],
      verticalLogosEvent: [],
      squareLogosEvent: [],
    });
    setSingleLogoObject({
      group: "string",
      feedbackMessage: "",
    });
  };

  return (
    <Button
      style={{
        zIndex: 100,
        position: "relative",
        marginLeft: 20,
      }}
      disabled={!isAdmin}
      onClick={handleSubmit}
    >
      Submit
    </Button>
  );
};

export default UploadStep;
