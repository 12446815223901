import React, { FC } from "react";
import { Popover, Tooltip, Form } from "antd";
import { FlagFilled } from "@ant-design/icons";
import { IOfferDataProps } from "shared/types/assetBuilder";

const TEXT_LENGTH_LIMIT = 80;
const TITLE_LENGTH_LIMIT = 30;

const returnTextLimit = (title: string, textLimit: number) => {
  let difference = 0;
  if (/Rebate [1-6] Disclosure$/g.test(title)) {
    difference = 20;
  } else if (/Rebate [1-6] Name$/g.test(title)) {
    difference = 60;
  }
  return textLimit - difference;
};

const returnText = (args: {
  value?: string;
  title?: string;
  limit: number;
}) => {
  const { value, title, limit } = args;
  const textLimit = returnTextLimit(title || "", limit);

  if (!value) {
    return <span>-</span>;
  }

  if (value.length <= textLimit) {
    return <span>{value}</span>;
  }

  return (
    <Tooltip title={value}>{`${value.substring(0, textLimit)}...`}</Tooltip>
  );
};

export const OfferText: FC<IOfferDataProps> = ({
  title,
  value,
  className,
  edited,
  originalValue,
}) => (
  <Form.Item
    className={className}
    label={returnText({ value: title, limit: TITLE_LENGTH_LIMIT })}
    style={{ maxHeight: "50px" }}
  >
    {returnText({ value, title, limit: TEXT_LENGTH_LIMIT })}
    {edited && originalValue && !title?.includes("Date") && (
      <Popover content={`The original value is ${originalValue}`}>
        <FlagFilled title="Field edited" style={{ color: "orange" }} />
      </Popover>
    )}
  </Form.Item>
);
