import { PublicDashboardDetail } from "./metrics/PublicDashboardDetail";
import PublicLinkModal from "./metrics/PublicLinkModal";
import useMetricsState from "./metrics/shared/hooks/useMetricsState";
import styles from "./Metrics.module.scss";

export const MetricsShare = () => {
  const { shareDashboard, showLinkModal, setShowLinkModal, setShareDashboard } =
    useMetricsState();
  return (
    <div className={styles.container}>
      <PublicLinkModal
        dashboard={shareDashboard}
        showLinkModal={showLinkModal}
        setShowLinkModal={setShowLinkModal}
      />
      <PublicDashboardDetail
        setShareDashboard={setShareDashboard}
        setShowLinkModal={setShowLinkModal}
      />
    </div>
  );
};
