import { getCAMHiResUrl } from "shared/components/media";
import {
  ILifestyleImage,
  IThemeBackgroundImage,
} from "shared/types/assetBuilder";

export const mapToImage = (
  type: "theme_bg" | "lifestyle",
  year?: string,
  make?: string,
) => {
  return (data: { items: WDAsset[] }): ILifestyleImage[] => {
    return data.items.map(item => {
      const url = getCAMHiResUrl(item, true);
      return {
        url,
        id: url,
        name: item.name,
        img: url,
        type: type,
        year: year ? parseInt(year) : 0,
        make: make ?? "",
      };
    });
  };
};

export const convertThemeToLifestyle = (
  themeImgs: IThemeBackgroundImage[],
  year?: string,
  make?: string,
): ILifestyleImage[] => {
  return themeImgs.map(themeImg => {
    const { url, name, id } = themeImg;
    return {
      url,
      id,
      name,
      img: url,
      type: "theme_bg",
      year: year ? parseInt(year) : 0,
      make: make ?? "",
    };
  });
};
