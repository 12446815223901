import { AdLoadIssue } from "screens/adLibrary/adLoadV2/hooks/useAdLoadIssues";
import { AllEntryTypes } from "../AssignDestsTable.types";

export const getRecordIssue = ({
  record,
  issues,
  field,
}: {
  record: AllEntryTypes;
  issues: AdLoadIssue[];
  field: AdLoadIssue["field"];
}): AdLoadIssue | undefined => {
  return issues.find(({ target, field: issueField }) => {
    if (
      target.adId !== record.adId ||
      target.storeName !== record.storeName ||
      issueField !== field
    ) {
      return false;
    }

    if (record.type === "CarouselItem") {
      return target.cardIndex === record.cardIndex;
    }
    if (record.type === "EverythingAdElement") {
      return target.elementId === record.id;
    }
    return true;
  });
};
