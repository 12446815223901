import NameTag from "screens/adLibrary/shared/components/NameTag";
import { AdAccountWithDealerName } from "shared/types/adLibrary";
import { pmaxTooltipProps } from "../utils";

type AdAccountListProps = {
  adAccounts: AdAccountWithDealerName[];
};

const AdAccountList = ({ adAccounts }: AdAccountListProps) => {
  return (
    <>
      {adAccounts.map(adAccount => (
        <NameTag
          id={
            adAccount?.dealerName
              ? `${adAccount.name} (${adAccount.id})`
              : adAccount.id
          }
          name={adAccount?.dealerName ?? adAccount.name}
          key={adAccount.id}
          tooltipProps={pmaxTooltipProps}
        />
      ))}
    </>
  );
};

export default AdAccountList;
