import { message, notification, Table } from "antd";
import { SorterResult, TableCurrentDataSource } from "antd/lib/table/interface";
import { isEmpty } from "lodash";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { Navigate, useLocation } from "react-router-dom";
import { useAdEngineActions } from "redux/assetExporter/assetExporter.slice";
import API from "services";
import { PROCESS_NOTIFICATION_KEY } from "shared/constants/assetExporter";
import { useFeedConfigFetch } from "shared/hooks/assetExporter/useFeedConfigFetch";
import { useFeedConfigUpdate } from "shared/hooks/assetExporter/useFeedConfigUpdate";
import { useGetSmartCols } from "shared/hooks/assetExporter/useFetchSmartCols";
import { useGetCsvUrl } from "shared/hooks/assetExporter/useGetCsvUrl";
import { useGetFeedStatus } from "shared/hooks/assetExporter/useProcessFeed";
import { useAppSelector } from "shared/hooks/useAppSelector";
import {
  FeedRow,
  FeedTblCol,
  FeedTblRow,
  SmartColumn,
} from "shared/types/assetExporter";
import { AdLibExportDrawer } from "./feedConfiguration/AdLibExportDrawer";
import { ColumnsFilter } from "./feedConfiguration/ColumnsFilter";
import { ExportDrawer } from "./feedConfiguration/ExportDrawer";
import { PaginationButton } from "./feedConfiguration/PaginationButton";
import { processNotification } from "./feedConfiguration/ProcessNotification";
import { RuleModal } from "./feedConfiguration/RuleModal";
import { Toolbar } from "./feedConfiguration/Toolbar";
import { feedRawToFeedConfig } from "./feedConfiguration/utils/feedConfigurationUtils";

import { useSmartColumnDelete } from "shared/hooks/assetExporter/useSmartColumnDelete";
import "./FeedConfiguration.scss";
import { FeedConfigurationCell } from "./feedConfiguration/FeedConfigurationCell";
import { useFeedConfiguration } from "./feedConfiguration/hooks/useFeedConfiguration";
import { SmartColumnTitle } from "./feedConfiguration/SmartColumnTitle";
import { SpinnerLoading } from "./feedConfiguration/SpinnerLoading";
import { FEED_CONFIG_DEFAULT } from "./feedConfiguration/utils/defaultValues";

const FeedConfiguration = () => {
  const { search } = useLocation();
  const { feedId, feedName } = Object.fromEntries(new URLSearchParams(search));
  const queryClient = useQueryClient();
  const { isExportByUser, exportFor, selectedRows } = useAppSelector(
    state => state.assetExporter.configure,
  );
  const actions = useAdEngineActions();
  const { data: latestStatus, dataUpdatedAt } = useGetFeedStatus(feedId);
  const {
    data: downloadUrl,
    isSuccess: isDownloadUrlSuccess,
    mutate: getCsvDownloadUrl,
  } = useGetCsvUrl();
  const {
    data: smartCols,
    isLoading: isGettingSmartCols,
    isSuccess: isGetSmartColsSuccess,
    isError: isGettingSmartColsError,
  } = useGetSmartCols(feedId);

  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [showModal, toggleSmartColumnModal] = useState(false);
  const [selectedSmartColumn, setSelectedSmartColumn] = useState<string>();
  const [feedTableColumns, setFeedTableColumns] = useState<FeedTblCol[]>();
  const [feedTableRows, setFeedTableRows] = useState<FeedTblRow[]>([]);
  const [smartColumns, setSmartColumns] = useState<SmartColumn>({});

  const rowsDisplay = useMemo(
    () => feedTableRows.filter(Boolean),
    [feedTableRows],
  );

  const [displayColumn, setDisplayColumn] = useState<boolean>(false);

  const [columnsNames, setColumnsNames] = useState(
    feedTableColumns?.map(col => col.title as string) ?? [],
  );
  const [selectedColumns, setSelectedColumns] = useState(
    feedTableColumns?.map(col => col.title as string) ?? [],
  );

  const [feedConfig, setFeedConfig] = useState(FEED_CONFIG_DEFAULT);

  const { mutate: patchFeedRowField } = useFeedConfigUpdate();
  const { mutate: deleteSmartColumn } = useSmartColumnDelete();
  const { getTableRows } = useFeedConfiguration();

  const {
    data: feedRawData,
    isLoading: isFeedLoading,
    searchLoading,
    hasMorePages,
    page,
    searchValue,
    fetchArgs,
    setSortArgs,
    onChangeSearch,
    onChangeSort,
    onChangePage,
  } = useFeedConfigFetch(feedId);

  const getFeedRawData = useCallback(() => {
    if (!feedRawData || isFeedLoading) return;

    if (page === "1") {
      setFeedConfig(feedRawToFeedConfig(feedRawData));
      actions.setFeedColumns({
        cols: feedRawData.orders,
        feedType: feedRawData.feedType,
      });
    } else {
      setFeedConfig(prev => ({
        ...prev,
        feedRows: [...prev.feedRows, ...feedRawData.rows],
      }));
    }
  }, [actions, feedRawData, isFeedLoading, page]);

  const getSmartColumns = useCallback(async () => {
    if (!feedId) return;
    const { result } = await API.services.assetExporter.getSmartColumns(feedId);

    if (result?.smartColumns.length === 0) {
      message.warn("No smart columns found.");
    }

    const updatedSmartColumns = result?.smartColumns.reduce((acc, item) => {
      const { column, rules } = item;
      acc[column] = rules;
      return acc;
    }, {} as SmartColumn);
    setSmartColumns(updatedSmartColumns);
  }, [feedId]);

  useEffect(getFeedRawData, [getFeedRawData]);

  const getTableColumns = useCallback(
    (smartColumnsIn: SmartColumn, orderedColumns: string[]) => {
      const smartColumnList = Object.keys(smartColumnsIn);

      return ["order_number"]
        .concat(
          orderedColumns.filter(
            columnsName =>
              !["rowIdentifier", "lastUpdated", "Process"].includes(
                columnsName,
              ),
          ),
        )
        .map(key => {
          let title: string | ReactNode = key;
          if (key === "order_number") {
            title = "";
          } else if (key === "template") {
            title = "Template";
          }

          if (smartColumnList.includes(key)) {
            title = (
              <SmartColumnTitle
                name={key}
                title={key}
                smartColumnsIn={smartColumnsIn}
                onSelectedSmartColumn={key => {
                  setSelectedSmartColumn(key);
                  toggleSmartColumnModal(true);
                }}
                onDeleteSmartColumn={key => {
                  deleteSmartColumn({ feedId, key });
                }}
              />
            );
          }

          const dataIndex = key;
          const leftFixedColumns = ["order_number", "Process", "template"];
          const fixed = leftFixedColumns.includes(key) ? "left" : undefined;

          const isSmartColumn = smartColumnList.includes(key);
          const sorterColumn = isSmartColumn ? undefined : true;

          return {
            title,
            dataIndex,
            editable: true,
            key,
            fixed,
            sorter: sorterColumn,

            // eslint-disable-next-line
            render: (value: string, row: FeedRow) => (
              <FeedConfigurationCell
                isSmartCol={isSmartColumn}
                value={value}
                row={row}
                field={key}
                onEditCell={handlePatchValue}
              />
            ),
          };
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rowsDisplay, feedId],
  );

  const handleChangeTable = useCallback(
    (
      sorter: SorterResult<FeedTblRow> | SorterResult<FeedTblRow>[],
      extra: TableCurrentDataSource<FeedTblRow>,
    ) => {
      if (!sorter) return;

      const { field: column, order } = sorter as {
        field: string;
        order: string;
      };

      if (!order) {
        setSortArgs(undefined);
        return;
      }

      const direction: "asc" | "desc" = order === "ascend" ? "asc" : "desc";
      const { currentDataSource } = extra;
      const firstRecord: any = currentDataSource[0];
      const fieldType = typeof firstRecord[column];
      const isString = fieldType === "string";

      if (isString) {
        onChangeSort({ column, direction });
      } else {
        onChangeSort({ column, direction, type: "number" });
      }
    },
    [onChangeSort, setSortArgs],
  );

  const onSelectedRowChange = useCallback(
    (newSelectedRowKeys: React.Key[]) => {
      actions.setProcessSelectedRows(newSelectedRowKeys as string[]);
    },
    [actions],
  );

  const table = useMemo(
    () => (
      <Table
        dataSource={rowsDisplay.length > 0 ? rowsDisplay : undefined}
        columns={feedTableColumns?.filter(
          col =>
            typeof col.title !== "string" ||
            selectedColumns.includes(col?.title),
        )}
        scroll={{ x: true }}
        loading={{
          indicator: <SpinnerLoading />,
          spinning: isFeedLoading,
        }}
        pagination={false}
        onChange={(_pagination, _filters, sorter, extra) => {
          handleChangeTable(sorter, extra);
        }}
        rowSelection={{
          selectedRowKeys: selectedRows,
          onChange: onSelectedRowChange,
        }}
      ></Table>
    ),
    [
      rowsDisplay,
      feedTableColumns,
      isFeedLoading,
      selectedRows,
      onSelectedRowChange,
      selectedColumns,
      handleChangeTable,
    ],
  );

  const smartColumnNames = useMemo(
    () => Object.keys(smartColumns || {}),
    [smartColumns],
  );

  const updateSelectedCols = useCallback(() => {
    const newColumns = feedTableColumns
      ?.filter(col => typeof col.title === "string" && col.title.length)
      .map(col => col.title as string);

    setSelectedColumns(newColumns ?? []);
    setColumnsNames(newColumns ?? []);
  }, [feedTableColumns]);

  const displayProcessNotification = useCallback(() => {
    if (!latestStatus || !isExportByUser) return;
    processNotification({
      ...latestStatus,
      getCsvDownloadUrl,
      feedId,
      notificationKey: PROCESS_NOTIFICATION_KEY,
      onClose: () => {
        actions.setIsExportByUser(false);
        actions.resetConfigureDrawer();
      },
      exportFor,
      onAdLibClick: () => {
        actions.setIsAdLibExportDrawerOpen(true);
      },
    });
    // NOTE: disabling below as "dataUpdatedAt" is needed to trigger this function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUpdatedAt, feedId, getCsvDownloadUrl, latestStatus]);

  const disableExportBtn = useCallback(() => {
    if (!latestStatus) {
      // if there was an error, api cannot get the latestStatus.
      actions.setIsExportInProgress(false);
      return;
    }
    const statusMsg = latestStatus.message;
    const isInProgress = statusMsg !== "Completed" && statusMsg !== "Failed";
    actions.setIsExportInProgress(isInProgress);
    // NOTE: disabling below as "dataUpdatedAt" is needed to trigger this function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions, dataUpdatedAt, latestStatus]);

  const downloadCsv = useCallback(() => {
    if (!!downloadUrl && isDownloadUrlSuccess) {
      const link = document.createElement("a");
      link.download = downloadUrl;
      link.href = downloadUrl;
      link.click();
    }
  }, [downloadUrl, isDownloadUrlSuccess]);

  const onComponentUnmount = useCallback(() => {
    notification.close(PROCESS_NOTIFICATION_KEY);
    actions.setIsExportByUser(false);
  }, [actions]);

  const normalizeSmartCols = useCallback(() => {
    if (!isGettingSmartCols && isGetSmartColsSuccess && smartCols) {
      if (smartCols.length === 0) {
        message.warn("No smart columns found.");
      }
      const normalized = smartCols.reduce((acc, item) => {
        const { column, rules } = item;
        acc[column] = rules;
        return acc;
      }, {} as SmartColumn);
      setSmartColumns(normalized);
    }
    if (!isGettingSmartCols && isGettingSmartColsError) {
      message.error("Unable to get smart columns");
    }
  }, [
    isGetSmartColsSuccess,
    isGettingSmartCols,
    isGettingSmartColsError,
    smartCols,
  ]);

  const updateFeedDataOnMount = useCallback(() => {
    if (!feedId) {
      setShouldRedirect(true);
    }
  }, [feedId]);

  const updateTableData = useCallback(() => {
    const { feedRows, orderedColumns } = feedConfig;
    const isDataAvailable = !!feedRows?.length && !!orderedColumns?.length;
    if (!isDataAvailable || isFeedLoading) return;
    if (!smartColumns || isEmpty(smartColumns)) {
      message.error("Smart columns are invalid.");
      return;
    }

    const tableColumns = getTableColumns(smartColumns, orderedColumns);
    setFeedTableColumns(tableColumns);
    setSelectedColumns(tableColumns?.map(({ title }) => String(title)));

    setFeedTableRows(getTableRows(feedRows));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    feedConfig.feedRows,
    feedConfig.orderedColumns,
    smartColumns,
    isFeedLoading,
  ]);

  const handlePatchValue = useCallback(
    (
      rowIdentifier: string,
      field: string,
      value: string | number | boolean,
      oldValue: string | number | boolean,
    ) => {
      if (!rowIdentifier) return;

      patchFeedRowField({
        feedId,
        rowIdentifier,
        field,
        value,
        oldValue,
        fetchArgs,
      });
    },
    [feedId, fetchArgs, patchFeedRowField],
  );

  useEffect(updateTableData, [updateTableData]);
  useEffect(updateSelectedCols, [updateSelectedCols]);
  useEffect(updateFeedDataOnMount, [updateFeedDataOnMount]);
  useEffect(normalizeSmartCols, [normalizeSmartCols]);
  useEffect(displayProcessNotification, [displayProcessNotification]);
  useEffect(disableExportBtn, [disableExportBtn]);
  useEffect(downloadCsv, [downloadCsv]);
  useEffect(() => onComponentUnmount, [onComponentUnmount]);

  return (
    <>
      <ExportDrawer
        smartCols={smartColumns}
        feedId={feedId}
        feedConfig={feedConfig}
      />
      <AdLibExportDrawer feedId={feedId} />
      {shouldRedirect && <Navigate to="/ad-engine/source-feed" replace />}
      <div className="feed-output-container">
        <div className="feed-config-title">
          <span>
            {feedName} / {feedId}
          </span>
        </div>
        <Toolbar
          feedConfig={feedConfig}
          feedId={feedId}
          feedName={feedName}
          feedTableRows={rowsDisplay}
          loading={isFeedLoading}
          smartColumnNames={smartColumnNames}
          setSelectedSmartColumn={setSelectedSmartColumn}
          setFeedConfig={setFeedConfig}
          toggleSmartColumnModal={toggleSmartColumnModal}
          searchValue={searchValue}
          searchPlaceholder={"Search..."}
          onSearch={searchParam => onChangeSearch(searchParam)}
          searchLoading={searchLoading}
          setDisplayColumn={setDisplayColumn}
        />

        <div className="data-list">
          <>{table}</>
          <ColumnsFilter
            displayColumn={displayColumn}
            selectedColumns={selectedColumns}
            columnNames={columnsNames}
            setSelectedColumns={setSelectedColumns}
            setDisplayColumn={setDisplayColumn}
          />
          <PaginationButton
            onClick={onChangePage}
            hasMorePages={hasMorePages}
            isFeedLoading={isFeedLoading}
          />
        </div>

        <RuleModal
          columnName={selectedSmartColumn}
          columns={feedConfig.columns?.rowColumns || []}
          feedId={feedId}
          rules={smartColumns?.[selectedSmartColumn || ""] || []}
          smartColumns={smartColumns}
          setSmartColumns={setSmartColumns}
          showModal={showModal}
          onModalClose={() => {
            toggleSmartColumnModal(false);
          }}
          saveRules={async (columnName, updatedRules) => {
            const messageKey = "saving-smart-column";
            message.loading({
              key: messageKey,
              content: "Saving smart column...",
            });

            try {
              const { saveSmartColumn } = API.services.assetExporter;
              const { error } = await saveSmartColumn(
                feedId,
                columnName,
                updatedRules,
              );
              if (error) {
                message.error({
                  key: messageKey,
                  content: error.message,
                });
                return;
              }
              setSmartColumns({
                ...smartColumns,
                [columnName]: updatedRules,
              });
              await getSmartColumns();

              queryClient.invalidateQueries(["feedConfig", feedId]);
            } catch (error) {
              const err = error as { message: string };
              message.error({
                key: messageKey,
                content: err.message,
              });
            } finally {
              message.destroy();
            }
          }}
          getSmartColumns={getSmartColumns}
        />
      </div>
    </>
  );
};

export default FeedConfiguration;
