import ToolbarTable from "shared/components/toolbarTable/ToolbarTable";
import { useSourceFeedToolbar } from "shared/hooks/assetExporter/useSourceFeedToolbar";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { ToolbarButton } from "shared/types/adLibrary";

export const SourceFeedToolbar = () => {
  const { selectedFeedIds, search } = useAppSelector(
    state => state.assetExporter.sourceFeed,
  );
  const {
    isCustomSelected,
    onDelete,
    onEdit,
    onNew,
    onPullLatest,
    onSearch,
  } = useSourceFeedToolbar();

  const isMultipleFeedSelected = selectedFeedIds.length > 1;
  const isNoFeedSelected = selectedFeedIds.length === 0;

  const toolbarContents: ToolbarButton = {
    New: {
      disabled: false,
      onClick: onNew,
      extraInfo: {
        text: "New Feed",
        tooltip: "Create new feed",
      },
    },
    Edit: {
      disabled: isNoFeedSelected || isMultipleFeedSelected,
      onClick: onEdit,
    },
    Delete: {
      disabled: isNoFeedSelected,
      onClick: onDelete,
    },
    PullLatest: {
      disabled: isNoFeedSelected || isCustomSelected(),
      onClick: onPullLatest,
    },
  };

  return (
    <ToolbarTable
      searchValue={search}
      toolbarContents={toolbarContents}
      searchPlaceholder="Search"
      titleTooltip="Search feeds by name"
      onSearch={onSearch}
    />
  );
};
