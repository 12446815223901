import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export const Ellipsis = ({ children }: Props) => {
  return (
    <div
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {children}
    </div>
  );
};
