import { useCallback, useContext, useMemo, useState } from "react";
import { brandsAccountsEnabled } from "utils/helpers";
import { useFetchBrandsByName } from "shared/hooks/brandsAccountsManagement/useFetchBrandsByName";
import { loadFontFromBrand } from "../../ManageText.utils";
import { context } from "../../../../../Editor.context";

export interface UseBrandsFonts {
  loadFont: (fontName: string) => Promise<FontFace | undefined>;
  fontFamilies: string[];
  shouldUseBrandFonts: boolean;
}

export const useBrandsFonts = (): UseBrandsFonts => {
  const editorContext = useContext(context);
  const { data: brands } = useFetchBrandsByName(
    editorContext?.resource?.oems,
  );

  const [loadedFonts, setLoadedFonts] = useState<string[]>([]);

  const brandFonts = useMemo(() => {
    return (brands ?? []).flatMap(brand => brand.fonts);
  }, [brands]);

  const fontFamilies = useMemo(
    () => brandFonts.map(font => font.name),
    [brandFonts],
  );

  const loadFont = useCallback(
    async (fontName: string) => {
      const fontURL = brandFonts.find(font => font.name === fontName)?.url;

      if (!fontURL) throw new Error(`Could not find font: ${fontName}`);

      const alreadyLoaded = loadedFonts.find(
        loadedURL => loadedURL === fontURL,
      );

      if (alreadyLoaded) return;

      const loaded = await loadFontFromBrand(fontName, fontURL);
      if (!!loaded) {
        setLoadedFonts([...loadedFonts, fontURL]);

        return loaded;
      } else {
        throw new Error(`Could not load font: ${fontURL}`);
      }
    },
    [loadedFonts, setLoadedFonts, brandFonts],
  );

  return {
    loadFont,
    fontFamilies,
    shouldUseBrandFonts: !!brandFonts.length && brandsAccountsEnabled,
  };
};
