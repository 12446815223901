import { Carousel } from "antd";
import { Fragment, memo, useRef, useState } from "react";
import { ImgDimensions } from "screens/adLibrary/shared/VisualMedia";
import { ICarouselElement } from "shared/types/adLibrary";
import ImagePreview from "./ImagePreview";
import styles from "./CarouselPreview.module.scss";
import { RightOutlined } from "@ant-design/icons";
import { LIVE_PREVIEW_WIDTH } from "../LivePreview";
import { CarouselRef } from "antd/lib/carousel";

interface IProps {
  carouselElement: ICarouselElement;
  handleSelectInstantExperienceId?: (instantExperienceId: string) => void;
}

const CarouselPreview = ({
  carouselElement,
  handleSelectInstantExperienceId,
}: IProps) => {
  const [imageDimensions, setFirstImageDimensions] = useState<ImgDimensions[]>(
    [],
  );
  const firstImageDimensions = imageDimensions?.[0];

  const imagePlaceholderHeight = LIVE_PREVIEW_WIDTH;
  const imageWidth = LIVE_PREVIEW_WIDTH;

  // calculate first image height from its natural height and its width.
  const firstImageHeight = firstImageDimensions?.naturalHeight
    ? (firstImageDimensions?.naturalHeight * imageWidth) /
      firstImageDimensions?.naturalWidth
    : imagePlaceholderHeight;

  const carouselRef = useRef<CarouselRef | null>(null);

  return (
    <div>
      <Carousel
        style={{
          height: firstImageHeight ?? "auto",
          overflow: "hidden",
          position: "relative",
        }}
        dots={{ className: styles.carouselDots }}
        draggable
        infinite={false}
        ref={carouselRef}
      >
        {carouselElement.child_elements.map((photoElement, index, elements) => (
          <Fragment key={photoElement.id}>
            <div style={{ position: "relative" }}>
              <ImagePreview
                photoElement={photoElement}
                onLoadImgDimensions={dimensions =>
                  setFirstImageDimensions(prev => {
                    const copy = [...prev];
                    copy[index] = dimensions;
                    return copy;
                  })
                }
                minHeight={firstImageHeight}
                handleSelectInstantExperienceId={
                  handleSelectInstantExperienceId
                }
              />
              {index !== elements.length - 1 && (
                <div
                  className={styles.nextBtn}
                  style={{
                    top: firstImageHeight ? firstImageHeight / 2 : "50%",
                    cursor: "pointer",
                  }}
                  onClick={() => carouselRef.current?.next()}
                >
                  <RightOutlined className={styles.animatedIcon} />
                </div>
              )}
            </div>
          </Fragment>
        ))}
      </Carousel>
    </div>
  );
};

export default memo(CarouselPreview);
