import { memo, useEffect } from "react";
import { Col, Row, Button } from "antd";
import { isEqual } from "lodash";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { useParams, useNavigate } from "react-router-dom";
import {
  setInstantExperienceDraft,
  setSelectedInstantExperienceElementId,
  updateInstantExperienceElement,
  displayInstantExperienceDraftAlerts,
} from "redux/designStudio/designStudio.slice";
import Loader from "shared/components/Loader";
import TabContainer from "shared/components/TabContainer";
import ToggleDisplay from "shared/components/ToggleDisplay";
import { useFetchInstantExperiences } from "shared/hooks/adLibrary/useFetchInstantExperiences";
import { useAppShallowEqualSelector } from "shared/hooks/useAppSelector";

import { InstantExperienceBodyElement } from "shared/types/adLibrary";
import ButtonComponent from "./instantExperience/ButtonComponent";
import CarouselComponent from "./instantExperience/CarouselComponent";
import ComponentsSidebar from "./instantExperience/ComponentsSidebar";
import ImageComponent from "./instantExperience/ImageComponent";
import LivePreview, {
  LIVE_PREVIEW_WIDTH,
} from "./instantExperience/LivePreview";
import ProductSetComponent from "./instantExperience/ProductSetComponent";
import Settings, { defaultSettingsValues } from "./instantExperience/Settings";
import ComponentWrapper from "./instantExperience/shared/ComponentWrapper";
import VideoComponent from "./instantExperience/VideoComponent";
import { isLastElement } from "utils/helpers.designStudio";
import { IHeader } from "shared/types/header";
import Header from "shared/components/Header";
import { useMutateInstantExperience } from "shared/hooks/adLibrary/useMutateInstantExperience";
import {
  errorNotification,
  successNotification,
} from "shared/components/customNotification/Notification";

export const inputWidths = {
  minWidth: 250,
  maxWidth: 350,
};

function InstantExperience() {
  const dispatch = useAppDispatch();

  const {
    instantExperienceDraftValidations,
    selectedInstantExperienceElementId,
    instantExperienceDraft,
    instantExperienceSaved,
  } = useAppShallowEqualSelector(state => ({
    instantExperienceDraftValidations:
      state.designStudio.instantExperienceDraftValidations,
    selectedInstantExperienceElementId:
      state.designStudio.selectedInstantExperienceElementId,
    instantExperienceDraft: state.designStudio.instantExperienceDraft,
    instantExperienceSaved: state.designStudio.instantExperienceSaved,
  }));

  // TODO: figure out alternate way to block - beforeUnload etc, backdrop etc
  const shouldPrompt = !isEqual(instantExperienceDraft, instantExperienceSaved);
  // usePrompt("Do you want to discard unsaved changes?", shouldPrompt);
  const {
    mutate: saveInstantExperience,
    isLoading: isSavingInstantExperience,
  } = useMutateInstantExperience();

  const selectedInstantExperienceElement =
    instantExperienceDraft?.body_elements?.find(
      element => element.id === selectedInstantExperienceElementId,
    );

  const { data: instantExperiences } = useFetchInstantExperiences();

  const disableFooter =
    instantExperienceDraft?.body_elements?.some(
      element => element.element_type === "FOOTER",
    ) ?? false;

  useClearReduxDataOnUnmount();
  useSyncInstExpDraftWithRedux();
  useUpdateRouteOnIEIdChange(instantExperienceDraft?.id);

  if (!instantExperienceDraft) {
    return <Loader message="Loading Instant Experience..." />;
  }

  const headerMenus: IHeader = {
    style: {},
    topSteps: {
      selected: "",
      style: {},
      steps: [{}],
    },
    actionButtons: [
      <Button
        key="save-instant-experience"
        className="action-button"
        loading={isSavingInstantExperience}
        onClick={() => {
          // call api to save instant experience and alexia info
          if (instantExperienceDraftValidations?.hasErrors) {
            errorNotification({
              messageLabel: "Please fix the errors before saving",
            });
            dispatch(displayInstantExperienceDraftAlerts());
            return;
          }
          instantExperienceDraft &&
            saveInstantExperience(instantExperienceDraft, {
              onSuccess: result => {
                successNotification({
                  messageLabel: "The Everything Ad was saved",
                });
                const ieId = result?.id || instantExperienceDraft.id;
                dispatch(
                  setInstantExperienceDraft(
                    {
                      ...instantExperienceDraft,
                      id: ieId,
                    },
                    true,
                  ),
                );
              },
              onError: () => {
                errorNotification({
                  messageLabel:
                    "Something happened and we couldn't save the Instant Experience",
                });
              },
            });
        }}
      >
        Save
      </Button>,
    ],
  };

  const getRenderComponent = (
    instantExperienceComponent?: InstantExperienceBodyElement,
  ) => {
    const forceValidations =
      instantExperienceDraftValidations?.body_elements.some(
        element =>
          element.id === instantExperienceComponent?.id &&
          element.hasErrors &&
          element.displayAlerts,
      );
    const commonProps = {
      instantExperiences,
      key: instantExperienceComponent?.id,
      updateElement: (instantExperienceElement: InstantExperienceBodyElement) =>
        dispatch(updateInstantExperienceElement(instantExperienceElement)),
      forceValidations,
      isLastElement: isLastElement(
        instantExperienceDraft,
        instantExperienceComponent,
      ),
    };

    if (
      instantExperienceComponent?.element_type === "BUTTON" ||
      instantExperienceComponent?.element_type === "FOOTER"
    ) {
      return (
        <ButtonComponent
          {...commonProps}
          disableFooter={disableFooter}
          instantExperienceElement={instantExperienceComponent}
        />
      );
    }

    if (instantExperienceComponent?.element_type === "PHOTO") {
      return (
        <ImageComponent
          {...commonProps}
          instantExperienceElement={instantExperienceComponent}
        />
      );
    }

    if (instantExperienceComponent?.element_type === "VIDEO") {
      return (
        <VideoComponent
          {...commonProps}
          instantExperienceElement={instantExperienceComponent}
        />
      );
    }

    if (instantExperienceComponent?.element_type === "ELEMENT_GROUP") {
      return (
        <ProductSetComponent
          {...commonProps}
          instantExperienceElement={instantExperienceComponent}
        />
      );
    }

    if (instantExperienceComponent?.element_type === "CAROUSEL") {
      return (
        <CarouselComponent
          {...commonProps}
          instantExperienceElement={instantExperienceComponent}
        />
      );
    }

    return (
      <Settings
        initialInstantExperience={instantExperienceDraft}
        setInstantExperience={newInstantExperience =>
          dispatch(setInstantExperienceDraft(newInstantExperience))
        }
        instantExperienceDraftValidations={instantExperienceDraftValidations}
      />
    );
  };

  return (
    <div className="tab-container-overflow-inherit">
      <Header
        style={headerMenus.style}
        topSteps={headerMenus.topSteps}
        actionButtons={headerMenus.actionButtons}
      />
      <TabContainer
        displaySearchView={{
          displayNewOffer: false,
          displaySearchInput: false,
          displayPlusButton: false,
        }}
        filterTab={
          <ComponentsSidebar
            components={instantExperienceDraft?.body_elements}
            selectedComponent={selectedInstantExperienceElement}
          />
        }
        contentTabs={[
          {
            title: "Everything Ad",
            component: (
              <Row gutter={[8, 8]} wrap={true}>
                <Col flex="auto" style={{ width: "450px" }}>
                  <ComponentWrapper
                    header={
                      selectedInstantExperienceElement?.name || "Settings"
                    }
                  >
                    <ToggleDisplay show={!selectedInstantExperienceElementId}>
                      {getRenderComponent()}
                    </ToggleDisplay>
                    {instantExperienceDraft?.body_elements?.map(element => (
                      <ToggleDisplay
                        show={selectedInstantExperienceElementId === element.id}
                        key={element.id}
                      >
                        {getRenderComponent(element)}
                      </ToggleDisplay>
                    ))}
                  </ComponentWrapper>
                </Col>
                <Col flex={`${LIVE_PREVIEW_WIDTH}px`}>
                  <ComponentWrapper header={"Preview"} padding={0}>
                    <LivePreview instantExperience={instantExperienceDraft} />
                  </ComponentWrapper>
                </Col>
              </Row>
            ),
          },
        ]}
      />
    </div>
  );
}

export default memo(InstantExperience);

const useClearReduxDataOnUnmount = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(setSelectedInstantExperienceElementId(undefined));
      dispatch(setInstantExperienceDraft(null, true));
    };
  }, [dispatch]);
};

const useSyncInstExpDraftWithRedux = () => {
  const { instantExperienceId } = useParams<{ instantExperienceId: string }>();

  const dispatch = useAppDispatch();
  const { data: instantExperiences, isLoading: isLoadingInstantExperiences } =
    useFetchInstantExperiences();
  const instantExperience = instantExperiences?.find(
    ie => ie.id === instantExperienceId,
  );

  useEffect(() => {
    if (isLoadingInstantExperiences) return;

    const draftInstantExperience = instantExperience || defaultSettingsValues;

    dispatch(setInstantExperienceDraft(draftInstantExperience, true));
  }, [dispatch, instantExperience, isLoadingInstantExperiences]);
};

const useUpdateRouteOnIEIdChange = (ieDraftId: string | undefined) => {
  const { instantExperienceId } = useParams<{ instantExperienceId: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (ieDraftId && instantExperienceId !== ieDraftId) {
      navigate(`/everything-ads/ads/${ieDraftId}/edit`, {
        replace: true,
      });
    }
  }, [instantExperienceId, ieDraftId, navigate]);
};
