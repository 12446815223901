import styles from "../MediaPreview.module.scss";
import PlayIcon from "../PlayIcon";

type Props = {
  videoUrl: string;
  isPaused: boolean;
  height: string | number | undefined;
};

const VideoPreview = ({ videoUrl, isPaused, height }: Props) => {
  return (
    <div style={{ position: "relative" }}>
      <video
        key={videoUrl}
        className={styles.asset}
        controls={!isPaused}
        loop={false}
        autoPlay={true}
        playsInline
        muted
        height={height}
      >
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {isPaused && <PlayIcon className={styles.playIcon} />}
    </div>
  );
};

export default VideoPreview;
