import API from "services";
import { useInfiniteQuery } from "react-query";
import {
  IGetSharedAdReviewParams,
  IGetSharedAdReviewResult,
  ISharedAdReview,
} from "shared/types/adReview";
import { IFacebookAd } from "screens/adLibrary/facebookUtils/types";
import { first } from "lodash";
import { getNextPageParam } from "../utils/helpers.ad";

const getQueryKey = (sharedAdReviewId: string) => {
  const key = "ad-preview/shared-ads";
  return [key, sharedAdReviewId];
};

const getSharedAdReview = async (
  sharedAdReviewId: string,
  params: IGetSharedAdReviewParams,
) => {
  const { result, error } = await API.services.adReview.getSharedAdReview(
    sharedAdReviewId,
    params,
  );
  if (error || !result) {
    throw Error(error?.message || "Something went wrong");
  }
  return result;
};

const mergePages = (pages: IGetSharedAdReviewResult[] | undefined) => {
  return (
    pages?.reduce(
      (acc, page) => acc.concat(page?.data.sharedAds || []),
      [] as IFacebookAd[],
    ) ?? []
  );
};

const buildSharedAdReview = (
  pages: IGetSharedAdReviewResult[] | undefined,
): ISharedAdReview | undefined => {
  const firstPage = first(pages);

  return firstPage
    ? {
        ...firstPage.data,
        sharedAds: mergePages(pages),
      }
    : undefined;
};

export const useFetchSharedAdReview = (
  sharedAdReviewId: string,
  enabled: boolean = true,
) => {
  const result = useInfiniteQuery<IGetSharedAdReviewResult, Error>(
    getQueryKey(sharedAdReviewId),
    ({ pageParam }) =>
      getSharedAdReview(sharedAdReviewId, { after: pageParam }),
    {
      enabled: enabled,
      staleTime: 1000 * 60 * 5,
      getNextPageParam: lastPage => getNextPageParam(lastPage.paging),
    },
  );

  const sharedAdReview = buildSharedAdReview(result.data?.pages);

  return {
    ...result,
    sharedAdReview,
  };
};
