import { ColumnFilterItem } from "antd/lib/table/interface";
import { isNumber } from "lodash";
import { useMemo } from "react";
import { IAssetGroup, NameID } from "shared/types/adLibrary";

type sortableKeys =
  | "name"
  | "id"
  | "feedStatus"
  | "feedName"
  | "pmaxStatus"
  | "lastUpdatedAt"
  | "createdAt";

type filterableKeyNames = Extract<
  keyof IAssetGroup,
  | "name"
  | "feedStatus"
  | "pmaxStatus"
  | "feedName"
  | "adAccounts"
  | "campaigns"
  | "id"
>;

const isNameIdArray = (val: unknown): val is NameID[] =>
  Array.isArray(val) && val.every(item => item.name && item.id);

const filterAssetGroupsBy =
  (key: filterableKeyNames) =>
  (value: string | number | boolean, assetGroup: IAssetGroup) => {
    switch (key) {
      case "campaigns":
      case "adAccounts":
        return (
          assetGroup[key]?.some(
            item => item.name === value || item.id === value,
          ) ?? false
        );
      case "name":
      case "feedName":
      case "feedStatus":
      case "pmaxStatus":
      case "id":
        return assetGroup[key]?.includes(value as string) ?? false;
    }
  };

const getFilterValues = (
  assetGroups: IAssetGroup[] | undefined,
  key: filterableKeyNames,
) => {
  if (!assetGroups?.length) return [];

  return assetGroups
    .map(ag => {
      const items = ag[key];
      if (isNameIdArray(items)) return items?.map(item => item.name);
      return ag[key];
    })
    .flat()
    .filter(
      (item, index, arr): item is string =>
        !!item && arr.indexOf(item) === index,
    )
    .map(val => ({ text: val, value: val }));
};

const sortAssetGroupsBy =
  (key: sortableKeys) =>
  (a: IAssetGroup, b: IAssetGroup): number => {
    const aVal = a[key];
    const bVal = b[key];

    if (!aVal) return 1;
    if (!bVal) return -1;
    if (isNumber(aVal) && isNumber(bVal)) return aVal - bVal;

    return (aVal as string).trim().localeCompare((bVal as string).trim());
  };

const usePmaxAssetGroupFilters = (assetGroups: IAssetGroup[] | undefined) => {
  const filterValues: Record<filterableKeyNames, ColumnFilterItem[]> = useMemo(
    () => ({
      campaigns: getFilterValues(assetGroups, "campaigns"),
      adAccounts: getFilterValues(assetGroups, "adAccounts"),
      name: getFilterValues(assetGroups, "name"),
      feedStatus: getFilterValues(assetGroups, "feedStatus"),
      pmaxStatus: getFilterValues(assetGroups, "pmaxStatus"),
      feedName: getFilterValues(assetGroups, "feedName"),
      id: getFilterValues(assetGroups, "id"),
    }),
    [assetGroups],
  );

  return {
    filterValues,
    filterAssetGroupsBy,
    sortAssetGroupsBy,
  };
};

export default usePmaxAssetGroupFilters;
