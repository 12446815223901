import { useMemo } from "react";
import { MediaLogos } from "shared/components/MediaLogos";
import { useDebounce } from "shared/hooks/useDebounce";
import { IAccountRecord } from "shared/types/accountManagement";
import { getStoreLogoPayload } from "./utils";

type Props = {
  record: IAccountRecord;
};
export const MediaStoreLogos = ({ record }: Props) => {
  const { dealerCode, dealerOem } = useDebounce(record, 500);
  const searchPayload = useMemo(() => {
    return getStoreLogoPayload(dealerCode, dealerOem);
  }, [dealerCode, dealerOem]);

  return <MediaLogos searchPayload={searchPayload} titleText="Store Logo(s)" />;
};
