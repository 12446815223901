import { useCallback } from "react";
import { JsonParam, useQueryParam } from "use-query-params";
import { QP_SEARCH } from "./constants";
import { useSelected } from "./useSelected";

type SetSearchPayload = Pick<
  WDSearchPayload,
  "page" | "query" | "filters" | "published_filter"
>;

export const useQPSearch = () => {
  const { clearSelected } = useSelected();
  const [searchState, setState] = useQueryParam<WDSearchPayload | undefined>(
    QP_SEARCH,
    JsonParam,
  );

  const setSearchState = useCallback(
    (searchPayload: SetSearchPayload) => {
      setState(state => {
        const newState = {
          ...state,
          ...searchPayload,
          page: searchPayload.page || 0,
        };

        if (newState.query || newState.filters || newState.published_filter) {
          return newState;
        }

        return undefined;
      });

      // Clear selected rows on new search.
      clearSelected();
    },
    [setState, clearSelected],
  );

  const resetSearchState = useCallback(() => {
    setState(undefined);
    clearSelected();
  }, [setState, clearSelected]);

  return {
    searchState,
    setSearchState,
    resetSearchState,
  };
};
