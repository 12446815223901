import { Navigate } from "react-router-dom";
import { CAMConfig, CAMHost } from "shared/components/media";

import styles from "./Media.module.scss";

const Media = () => {
  return CAMConfig.active ? (
    <div className={styles.host}>
      <CAMHost />
    </div>
  ) : (
    <Navigate to="/" />
  );
};

export default Media;
