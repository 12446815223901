import { useQuery } from "react-query";
import API from "services";
import { formatRateAsPercentage } from "utils/helpers.offer";
import { OfferData } from "shared/types/assetBuilder";
import { IApiResponse } from "shared/types/shared";

const getRawOffer = (vin: string) => async () => {
  if (!vin) {
    return null;
  }
  const { result, error } = await API.services.assetBuilder.fetchRawOfferByVin<
    IApiResponse<any>
  >(vin);
  if (error) {
    throw Error(error.message);
  }

  const { dateInStock, aprRate, financeRate } = result.rawOffer.row;
  const date = new Date(dateInStock);
  const offerData = {
    ...result.rawOffer.row,
    dateInStock: date.getTime(),
    aprRate: formatRateAsPercentage(aprRate),
    financeRate: formatRateAsPercentage(financeRate),
  };

  return offerData;
};

export const useFetchRawOffer = (vin: string) => {
  return useQuery<OfferData | null, Error>(
    ["rawOffer", vin],
    getRawOffer(vin),
    {
      enabled: false,
      retry: false, // default retry value is set to 3, which leads to 4 requests
      cacheTime: 0,
    },
  );
};
