import { IConfig } from "shared/types/configuration";
import HttpClient from "./httpClient";
import { IGetAccountsResponse } from "shared/types/brandsAccounts";

export default ({ config }: { config: IConfig }) => ({
  getAccounts: async (): Promise<IGetAccountsResponse> => {
    const getAccountsUrl = config.services.getAccountsUrl;

    const makeGetRequest = () =>
      HttpClient.get<IGetAccountsResponse>(getAccountsUrl);

    const response = makeGetRequest();
    return response;
  },
});
