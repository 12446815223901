import { memo, useState } from "react";

import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { setSelectedAdForSummary } from "redux/adLibrary/adLibrary.slice";

import AdWizard, { ActiveKeyType } from "screens/adLibrary/AdWizard";
import CardListContainer from "shared/components/CardListContainer";
import LegacyAdCard from "./adCardListContainer/LegacyAdCard";
import AdCard from "./adCardListContainer/AdCard";

import { IAd } from "shared/types/adLibrary";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { useAds } from "screens/adLibrary/shared/hooks/useAds";

interface Props {
  useLegacyCard?: boolean;
  onEditClick: (ad: IAd) => void;
  onPreviewClick: (ad: IAd) => void;
  onLoadClick?: () => void;
  onDuplicateClick: (ad: IAd) => void;
  onDeleteClick: (ad: IAd) => void;
}

const AdCardListContainer = ({
  useLegacyCard,
  onEditClick,
  onPreviewClick,
  onDuplicateClick,
  onLoadClick,
  onDeleteClick,
}: Props) => {
  const dispatch = useAppDispatch();
  const { data } = useAds();
  const [adForWizard, setAdForWizard] = useState<{
    adForWizard: IAd;
    tabToDisplay: ActiveKeyType;
  } | null>(null);
  const { windowInnerHeight } = useWindowSize();

  const allAvailableHeight = windowInnerHeight - 160;

  return (
    <CardListContainer
      virtualized
      height={allAvailableHeight}
      onDivClick={() => {
        dispatch(setSelectedAdForSummary(null));
      }}
      cards={data.map(ad =>
        useLegacyCard ? (
          <LegacyAdCard key={ad.id} ad={ad} />
        ) : (
          <AdCard
            key={ad.id}
            onEditClick={onEditClick}
            onPreviewClick={onPreviewClick}
            onLoadClick={onLoadClick}
            onDuplicateClick={onDuplicateClick}
            onDeleteClick={onDeleteClick}
            ad={ad}
          />
        ),
      )}
      extraContent={
        adForWizard && (
          <AdWizard
            onClose={() => setAdForWizard(null)}
            adEdit={adForWizard.adForWizard}
            initialActiveTab={adForWizard.tabToDisplay}
          />
        )
      }
    />
  );
};

export default memo(AdCardListContainer);
