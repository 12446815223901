import { Menu } from "antd";
import { orderBy } from "lodash";
import { NavLink } from "react-router-dom";
import { OfferType } from "shared/types/shared";

type Props = {
  offerTypes: OfferType[];
  stampId: string;

  onItemClick: () => void;
};

const OffertypesMenu = (props: Props) => {
  return (
    <Menu selectedKeys={[]}>
      {orderBy(props.offerTypes).map(offerType => (
        <Menu.Item
          key={`stamp-link-${offerType}`}
          onClick={e => {
            e.domEvent.stopPropagation();

            props.onItemClick();
          }}
        >
          <NavLink
            to={
              `/design-studio/editor/stamps/${props.stampId}/editor-v2` +
              `?offerType=${encodeURIComponent(offerType)}`
            }
            target="_blank"
          >
            {offerType}
          </NavLink>
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default OffertypesMenu;
