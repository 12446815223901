import { Button, Form, message, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import { useState } from "react";
import {
  IDashboard,
  IPublicDashboard,
  IShareDashboardForm,
} from "shared/types/dashboardManagement";
import { PublicLinkFilters } from "./PublicLinkFilters";
import { PublicLinkSettings } from "./PublicLinkSettings";
import { useDateValueSelector } from "./shared/hooks/useDateValueSelector";
import { useManagePublicDashboard } from "./shared/hooks/useManagePublicDashboard";
import { splitCsvWithException } from "./shared/utils";

type PublicLinkModalProps = {
  dashboard?: IDashboard;
  publicDashboard?: IPublicDashboard;
  showLinkModal: boolean;
  setShowLinkModal: (showLinkModal: boolean) => void;
};
const PublicLinkModal = ({
  dashboard,
  publicDashboard,
  showLinkModal,
  setShowLinkModal,
}: PublicLinkModalProps) => {
  const [form] = useForm<IShareDashboardForm>();
  const { publicUrl, upsertPublicLink, reset } = useManagePublicDashboard(
    dashboard,
    publicDashboard,
  );
  const dateSelection = useDateValueSelector(dashboard, publicDashboard);
  const [passwordValue, setPasswordValue] = useState("");
  const [dateSelectShowing, setDateSelectShowing] = useState(false);

  const [selectedBrands, setSelectedBrands] = useState<string>(
    publicDashboard?.oem || "",
  );
  const [selectedAccounts, setSelectedAccounts] = useState<string>(
    publicDashboard?.account || "",
  );

  const setShowDateValueSelector = (showing: boolean) =>
    setDateSelectShowing(showing);

  const onValuesChange = (changes: any, allValues: IShareDashboardForm) => {
    if (
      Object.keys(changes).includes("oems") ||
      Object.keys(changes).includes("accounts")
    ) {
      return;
    }
    const updatedFields = Object.keys(allValues)
      .filter(name => form.getFieldError(name).length)
      .map(name => ({ name, errors: [] }));
    form.setFields(updatedFields);
    setPasswordValue(form.getFieldValue("password"));
  };

  const setGeneratedPassword = (password: string) => {
    setPasswordValue(password);
  };

  const initialFormValues = {
    oems: publicDashboard?.oem?.split(","),
    campaigns: publicDashboard?.specialCampaign?.split(","),
    accounts: splitCsvWithException(publicDashboard?.account || ""),
    passwordEnabled: publicDashboard?.isProtected ?? false,
    expirationEnabled:
      publicDashboard?.expirationDate !== undefined ? true : false,
    expirationDate: publicDashboard?.expirationDate
      ? moment(publicDashboard?.expirationDate)
      : undefined,
  };

  const handleSubmitDone = () => {
    const formData = form.getFieldsValue();
    form.validateFields(["password", "expirationValue"]).then(() => {
      upsertPublicLink(
        {
          ...formData,
          oems: selectedBrands.split(","),
          accounts: splitCsvWithException(selectedAccounts),
        },
        dateSelection.selection,
      );
      setShowLinkModal(false);
      reset();
      form.resetFields();

      if (publicDashboard) {
        message.info("The link has been updated", 5);
      }
    });
  };

  const handleSubmitNext = () => {
    if (dateSelection.error) {
      return;
    }

    const formData = form.getFieldsValue();
    upsertPublicLink(
      {
        ...formData,
        oems: selectedBrands.split(","),
        accounts: splitCsvWithException(selectedAccounts),
      },
      dateSelection.selection,
    );

    if (publicDashboard) {
      message.info("The link has been updated", 5);
    }
  };

  const handleChangeMultiSelectAllTags = (
    field: "oem" | "account",
    value: string,
  ) => {
    if (field === "oem") {
      setSelectedBrands(value);
    } else {
      setSelectedAccounts(value);
    }
  };

  return (
    <Modal
      width={1000}
      title="Share link to dashboard"
      visible={showLinkModal}
      onCancel={() => {
        setShowLinkModal(false);
        reset();
      }}
      footer={
        <Button
          type="primary"
          onClick={publicUrl ? handleSubmitDone : handleSubmitNext}
          disabled={dateSelectShowing || !!dateSelection.error}
        >
          {publicUrl ? "Done" : "Next"}
        </Button>
      }
    >
      <Form<IShareDashboardForm>
        form={form}
        initialValues={initialFormValues}
        layout="vertical"
        onValuesChange={onValuesChange}
        key={`share-form-${publicDashboard?.id ?? dashboard?.id}`}
      >
        <PublicLinkFilters
          visible={!!publicUrl === false}
          dateSelection={dateSelection}
          dashboard={dashboard}
          showDateValueSelector={dateSelectShowing}
          setShowDateValueSelector={setShowDateValueSelector}
          handleChangeMultiSelectAllTags={handleChangeMultiSelectAllTags}
          selectedOems={selectedBrands}
          selectedAccounts={selectedAccounts}
        />
        <PublicLinkSettings
          visible={!!publicUrl}
          form={form}
          initialValues={initialFormValues}
          publicUrl={publicUrl}
          setGeneratedPassword={setGeneratedPassword}
          passwordValue={passwordValue}
          allowChangePassword={
            publicDashboard?.isProtected === true ? false : true
          }
        />
      </Form>
    </Modal>
  );
};

export default PublicLinkModal;
