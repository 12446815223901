import { useLayoutEffect, useRef, useState } from "react";
import useDeepEffect from "shared/hooks/useDeepEffect";
import { IDimension } from "shared/types/designStudio";
import { calculateCanvasDimension } from "../canvas.utils/Parsers";

export default (args: {
  dimension: IDimension;
  CANVAS_MARGIN: number;
  div: HTMLDivElement | null;
}): [IDimension, { top: number; left: number }, number] => {
  const [canvasDimension, setCanvasDimension] = useState<IDimension>(
    args.dimension,
  );
  const [margin, setMargin] = useState<{ top: number; left: number }>({
    top: 0,
    left: 0,
  });
  const [widthDiff, setWidthDiff] = useState<number>(0);
  const resizeTimerId = useRef<NodeJS.Timeout>();
  useLayoutEffect(() => {
    if (!args.div) return;

    const resizeCanvas = () => {
      if (resizeTimerId.current) clearTimeout(resizeTimerId.current);

      resizeTimerId.current = setTimeout(
        () => {
          const dimension = calculateCanvasDimension(
            args.div,
            args.dimension,
            args.CANVAS_MARGIN,
          );

          setCanvasDimension(() => dimension);
        },
        resizeTimerId.current ? 200 : 0,
      );
    };

    resizeCanvas();

    window.addEventListener("resize", resizeCanvas);

    return () => {
      window.removeEventListener("resize", resizeCanvas);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args.CANVAS_MARGIN, args.div]);

  useDeepEffect(() => {
    const updatedMargin = {
      top: (canvasDimension.height - args.dimension.height) / 2,
      left: (canvasDimension.width - args.dimension.width) / 2,
    };
    const widthDiff = updatedMargin.left - (margin?.left || 0);
    setMargin(() => updatedMargin);
    setWidthDiff(widthDiff);
  }, [canvasDimension]);

  return [canvasDimension, margin, widthDiff];
};
