import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  MessageOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { Avatar, Badge, Space } from "antd";
import _upperFirst from "lodash/upperFirst";
import { memo, useMemo, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAdLibraryIds } from "screens/adLibrary/shared/hooks/dataListHooks";
import {
  getAdDisplayFromAd,
  getColorFromConflictStatus,
} from "screens/adLibrary/utils";
import Card from "shared/components/Card";
import EllipsisText from "shared/components/card/EllipsisText";
import useComments from "shared/hooks/comments/useComments";
import { ConflictStatus, IAd, IAdLibraryState } from "shared/types/adLibrary";
import { ICardButtonObj } from "shared/types/card";
import styles from "./AdCard.module.scss";

interface Props {
  ad: IAd;
  onPreviewClick: (ad: IAd) => void;
  onEditClick: (ad: IAd) => void;
  onLoadClick?: () => void;
  onDuplicateClick: (ad: IAd) => void;
  onDeleteClick: (ad: IAd) => void;
}

const AdCard = ({
  ad,
  onEditClick,
  onPreviewClick,
  onDuplicateClick,
  onDeleteClick,
}: Props) => {
  const { pathname } = useLocation();
  const path = pathname;
  const onAdLoad = path === "/ad-library/load";
  const commentsContext = useComments({ id: ad.id, mode: "create" });
  const { comments } = commentsContext();
  const defaultAdUrlCopyText = "Copy Ad URL to clipboard";
  const [copyAdUrlText, setCopyAdUrlText] = useState(defaultAdUrlCopyText);

  const { selectedIds, setSelectedItemIds } = useAdLibraryIds();

  const { adLoadParameters } = useSelector(
    (state: { adLibrary: IAdLibraryState }) => ({
      selectedAdIdForSummary: state.adLibrary.selectedAdIdForSummary,
      facebookAdLoadDict: state.adLibrary.adLoad.facebookAdLoadDict,
      adLoadParameters: state.adLibrary.adLoad.adLoadParameters,
    }),
    shallowEqual,
  );

  const adDisplay = getAdDisplayFromAd(ad)!;

  const conflictStatus = useMemo(() => {
    if (!onAdLoad) {
      return ConflictStatus.EMPTY;
    }

    if (
      !adLoadParameters?.rulesets?.[ad.id] ||
      adLoadParameters.rulesets[ad.id] === null
    ) {
      return ConflictStatus.SETUP;
    }

    const hasConflicts = !!adLoadParameters?.conflicts?.[ad.id]?.length;
    const otherAdsHaveConflicts = !!Object.keys(
      adLoadParameters?.conflicts || {},
    ).filter(adShellId =>
      adLoadParameters?.conflicts?.[adShellId]?.includes(ad.id),
    ).length;

    if (hasConflicts || otherAdsHaveConflicts) {
      return ConflictStatus.CONFLICT;
    }

    return ConflictStatus.READY;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adLoadParameters, onAdLoad]);

  const CategoryIcon = adDisplay.CategoryIcon;
  const qcAvatarColor = conflictStatus
    ? getColorFromConflictStatus(conflictStatus)
    : adDisplay.qcStatusColor;

  const qcStatus = conflictStatus || adDisplay.qcStatus;

  const buttonObjsInAvailableAds: ICardButtonObj[] = [
    {
      popConfirmProps: {
        title: "Are you sure you want to duplicate this Ad?",
        onConfirm: () => onDuplicateClick(ad),
        okText: "Yes",
        cancelText: "No",
      },
      tooltipProps: {
        placement: "top",
        title: "Duplicate",
      },
      buttonProps: {
        icon: <CopyOutlined />,
      },
      buttonText: "Duplicate",
    },
    {
      popConfirmProps: {
        title: "Are you sure you want to delete this Ad?",
        onConfirm: () => onDeleteClick(ad),
        okText: "Yes",
        cancelText: "No",
      },
      tooltipProps: {
        placement: "top",
        title: "Delete",
      },
      buttonProps: {
        icon: <DeleteOutlined />,
      },
      buttonText: "Delete",
    },
  ];

  const buttonsObjects: ICardButtonObj[] = [
    {
      tooltipProps: {
        placement: "top",
        title: "Edit",
      },
      buttonProps: {
        icon: <EditOutlined />,
        onClick: () => onEditClick(ad),
      },
      buttonText: "Edit",
    },
    {
      tooltipProps: {
        placement: "top",
        title: "Preview",
      },
      buttonProps: {
        icon: comments.length ? (
          <Badge size="small" count={comments.length} overflowCount={9}>
            <MessageOutlined />
          </Badge>
        ) : (
          <MessageOutlined />
        ),
        onClick: () => onPreviewClick(ad),
      },
      buttonText: "Preview",
    },
    {
      tooltipProps: {
        placement: "top",
        title: copyAdUrlText,
      },
      buttonProps: {
        icon: <LinkOutlined />,
        onClick: () => {
          navigator.clipboard.writeText(ad.adUrl ?? "");
          setCopyAdUrlText("Copied Ad URL to clipboard");
        },
        onMouseEnter: () => {
          setCopyAdUrlText(defaultAdUrlCopyText);
        },
      },
      buttonText: copyAdUrlText,
    },
    ...buttonObjsInAvailableAds,
  ];

  const status = (
    <Space key="category-and-oem-div" align="center">
      <Avatar
        style={{
          backgroundColor: qcAvatarColor,
          marginTop: "-3px",
        }}
        size={12}
      />
      {qcStatus}
    </Space>
  );

  const badges = [
    (adDisplay.oems || CategoryIcon) && (
      <Space key="category-and-oem-div" align="center">
        {CategoryIcon && (
          <CategoryIcon
            style={{
              marginLeft: "auto",
              marginTop: "4px",
              fontSize: "14px",
            }}
          />
        )}
        {adDisplay.oems && (
          <EllipsisText text={adDisplay.oems} charDisplayLimit={10} />
        )}
      </Space>
    ),
    _upperFirst(adDisplay.type),
    adDisplay.package && (
      <Space key="category-and-oem-div" align="center">
        <span className={styles.rectangle}>{adDisplay.package}</span>
        Package
      </Space>
    ),
  ];

  const tags = [
    adDisplay?.models || null,
    adDisplay?.description || null,
    adDisplay?.audiences || null,
  ];
  const isSelected = selectedIds?.includes(ad.id);

  return (
    <Card
      title={adDisplay.name || "--"}
      imageSrc={adDisplay.thumbnail50 || adDisplay.thumbnail}
      isThisCardSelected={isSelected}
      onCardClick={() => {
        const ids = isSelected
          ? selectedIds.filter(key => key !== ad.id)
          : selectedIds.concat(ad.id);

        setSelectedItemIds(ids);
      }}
      status={status}
      badges={badges.filter(badge => !!badge)}
      tags={tags.filter(tag => !!tag) as string[]}
      buttonObjects={buttonsObjects.filter(btn => !!btn)}
    />
  );
};

export default memo(AdCard);
