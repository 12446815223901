import { CopyOutlined } from "@ant-design/icons";
import { Modal, Button, Popover } from "antd";

import { FC, useState } from "react";

interface IAssetLauncherWidgetModal {
  domain: string;
  openWidgetModal: boolean;
  toggleWidgetModal: () => void;
}

export const AssetLauncherWidgetModal: FC<IAssetLauncherWidgetModal> = ({
  domain,
  openWidgetModal,
  toggleWidgetModal,
}) => {
  const [copiedBool, setCopiedBool] = useState<boolean>(false);
  const widgetURL = `<script type="text/javascript" src="${
    process.env.REACT_APP_DOMAIN
  }/${
    process.env.REACT_APP_ENV
  }-assetbuilder/v1/asset-builder/web-integration-code?website=${domain.replace(
    /\/$/,
    "",
  )}&id=default"></script>`;

  return (
    <>
      {openWidgetModal && (
        <Modal
          className="asset-launcher-widget-modal"
          title="Web Integration Widget"
          visible={true}
          onCancel={() => {
            openWidgetModal && toggleWidgetModal();
            setCopiedBool(false);
          }}
          footer={[
            <Button
              key="CLOSE"
              onClick={() => {
                openWidgetModal && toggleWidgetModal();
                setCopiedBool(false);
              }}
            >
              Close
            </Button>,
          ]}
        >
          <b>{`Copy the code below and paste it onto every page of your website.`}</b>
          <br />
          <b>{`Paste this code as high in the <head> of the page as possible:`}</b>
          <br />
          <div>
            <textarea
              style={{ width: "450px", height: "100px" }}
              className="widget-url"
              disabled={true}
              value={widgetURL}
            />
          </div>
          <div>
            <Popover content={copiedBool ? "Copied!" : "Copy To Clipboard"}>
              <Button
                key="COPY"
                icon={<CopyOutlined />}
                onClick={() => {
                  navigator?.clipboard?.writeText(widgetURL);
                  setCopiedBool(true);
                }}
              ></Button>
            </Popover>
          </div>
        </Modal>
      )}
    </>
  );
};
