import { useFetchFacebookCatalogs } from "shared/hooks/adLibrary/facebook/useFetchFacebookObject";
import { isTruthy } from "utils/helpers.array";

type Props = {
  productCatalogIds: string[] | undefined;
};

const ProductCatalogName = ({ productCatalogIds }: Props) => {
  const { data: productCatalogs, isLoading: isLoadingProductCatalogs } =
    useFetchFacebookCatalogs();

  if (isLoadingProductCatalogs) return <>Loading...</>;

  const productCatalogNames =
    productCatalogIds
      ?.map(id => productCatalogs?.find(catalog => catalog.id === id)?.name)
      .filter(isTruthy) ?? [];

  const count = productCatalogNames.length;

  if (count > 1) {
    return <>Multiple ({count})</>;
  }

  return <>{productCatalogNames[0]}</>;
};

export default ProductCatalogName;
