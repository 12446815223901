import { DataNode } from "antd/lib/tree";

type Props = {
  item: DataNode;
  searchValue: string;
};

export const HighlightedTitle = ({ item, searchValue }: Props) => {
  const strTitle = item.title?.toString() || "";
  const index = strTitle.toLowerCase().indexOf(searchValue);
  const foundStr = strTitle.substring(index, index + searchValue.length);
  const beforeStr = strTitle.substring(0, index);
  const afterStr = strTitle.slice(index + searchValue.length);

  return index > -1 ? (
    <>
      {beforeStr}
      <span
        style={{
          color: "#f50",
        }}
      >
        {foundStr}
      </span>
      {afterStr}
    </>
  ) : (
    <>{strTitle}</>
  );
};
