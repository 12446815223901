import { ReactElement } from "react";
import { Button, Card, Typography } from "antd";
import styles from "./IntegrationCard.module.scss";

interface IProps {
  name: string;
  description: string;
  logo: ReactElement;
  isConnected: boolean;
  isLoading: boolean;
  onConnect: () => void;
  onDisconnect: () => void;
  customConnectButton?: ReactElement;
}

const IntegrationCard = ({
  name,
  description,
  logo,
  isConnected,
  isLoading,
  onConnect,
  onDisconnect,
  customConnectButton,
}: IProps) => {
  return (
    <Card className={styles.card}>
      <div className={styles.header}>
        {logo}
        {isConnected ? (
          <Button
            onClick={onDisconnect}
            loading={isLoading}
            disabled={isLoading}
          >
            Disconnect
          </Button>
        ) : customConnectButton ? (
          customConnectButton
        ) : (
          <Button
            type="primary"
            onClick={onConnect}
            loading={isLoading}
            disabled={isLoading}
          >
            Connect
          </Button>
        )}
      </div>
      <div>
        <Typography.Title level={5} style={{ fontWeight: "bold" }}>
          {name}
        </Typography.Title>
        <Typography.Text>{description}</Typography.Text>
      </div>
    </Card>
  );
};

export default IntegrationCard;
