import API from "services";
import { QueryClient, useMutation, useQueryClient } from "react-query";
import {
  ApiResponseCampaignPlanner,
  CampaignPlanner,
  CampaignPlannerList,
} from "screens/campaignManagement/campaignPlanner/types";

import produce from "immer";
import { queryKeys } from "./queryKeys";
import { remove } from "lodash";
import { optimisticUpdate } from "./utils";
import { IAd } from "shared/types/adLibrary";
import { ReactQueryContext } from "./types";
import {
  CreateCampaignPlannerParams,
  DeleteCampaignPlannerParams,
} from "shared/types/campaignPlanner";
import { createPlannerObject } from "screens/campaignManagement/campaignPlanner/utils";

const onMutationError = (
  queryClient: QueryClient,
  context?: ReactQueryContext,
) => {
  queryClient.setQueryData(
    queryKeys.campaignPlanners,
    context?.previousCampaigns,
  );
};
const createCampaignPlanner = async (campaign: CreateCampaignPlannerParams) => {
  const { result, error } =
    await API.services.campaignPlanner.createCampaignPlanner(campaign);

  if (!result) {
    throw Error(
      error?.message ||
        "Something went wrong while creating campaign planners.",
    );
  }

  return result;
};

export const useCreateCampaignPlanner = () => {
  const queryClient = useQueryClient();

  return useMutation<
    ApiResponseCampaignPlanner,
    Error,
    CreateCampaignPlannerParams,
    ReactQueryContext
  >(createCampaignPlanner, {
    onMutate: async planner => {
      await queryClient.cancelQueries({
        queryKey: queryKeys.campaignPlanners,
      });

      const previousCampaigns: ApiResponseCampaignPlanner[] =
        queryClient.getQueryData(queryKeys.campaignPlanners) ?? [];

      const newPlanner = {
        ...createPlannerObject(planner),
        instances: [],
        adShellIds: [],
      };
      optimisticUpdate(queryClient, newPlanner);
      return { previousCampaigns };
    },
    onError: (err, newCampaign, context) => {
      onMutationError(queryClient, context);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.campaignPlanners);
    },
  });
};

const editCampaignPlanner = async ({
  adShells: _,
  ...planner
}: CampaignPlanner) => {
  const { result, error } =
    await API.services.campaignPlanner.editCampaignPlanner(planner);

  if (!result) {
    throw Error(
      error?.message ||
        "Something went wrong while creating campaign planners.",
    );
  }

  return result;
};

export const useEditCampaignPlanner = () => {
  const queryClient = useQueryClient();

  return useMutation<
    ApiResponseCampaignPlanner,
    Error,
    CampaignPlanner,
    ReactQueryContext
  >(editCampaignPlanner, {
    onMutate: async planner => {
      await queryClient.cancelQueries({
        queryKey: queryKeys.campaignPlanners,
      });

      const previousCampaigns: ApiResponseCampaignPlanner[] =
        queryClient.getQueryData(queryKeys.campaignPlanners) ?? [];

      const newPlanner = {
        ...planner,
        adShellIds: planner.adShells.map(ad => ad.id),
        instances: planner.instances?.map(instance => ({
          ...instance,
          adShellIds: instance.adShells.map(ad => ad.id),
          plannerId: planner.id,
        })),
      };
      optimisticUpdate(queryClient, newPlanner);
      return { previousCampaigns };
    },
    onError: (err, newCampaign, context) => {
      onMutationError(queryClient, context);
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.campaignPlanners);
    },
  });
};

const deleteCampaignPlanners = async (
  planners: DeleteCampaignPlannerParams,
) => {
  const { result, error } =
    await API.services.campaignPlanner.deleteCampaignPlanners(planners);

  if (error) {
    throw Error(
      error?.message ||
        "Something went wrong while deleting campaign planners.",
    );
  }

  return result;
};

export const useDeleteCampaignPlanners = () => {
  const queryClient = useQueryClient();

  return useMutation<
    string | null,
    Error,
    DeleteCampaignPlannerParams,
    ReactQueryContext
  >(deleteCampaignPlanners, {
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.campaignPlanners);
    },
    onMutate: async plannersToDelete => {
      await queryClient.cancelQueries({
        queryKey: queryKeys.campaignPlanners,
      });

      const previousCampaigns: ApiResponseCampaignPlanner[] =
        queryClient.getQueryData(queryKeys.campaignPlanners) ?? [];

      queryClient.setQueryData(
        [queryKeys.campaignPlanners],
        produce<CampaignPlannerList>((old: CampaignPlannerList = []) => {
          remove(old, x =>
            plannersToDelete.find(
              planner => planner.campaignPlannerId === x.id,
            ),
          );
        }),
      );
      return { previousCampaigns };
    },
    onError: (err, newCampaign, context) => {
      onMutationError(queryClient, context);
    },
  });
};

const updateCampaignPlannerAds = async (
  campaignPlanner: ApiResponseCampaignPlanner,
) => {
  const { result, error } =
    await API.services.campaignPlanner.updateCampaignPlannerAds({
      id: campaignPlanner.id,
      adShellIds: campaignPlanner.adShellIds,
    });

  if (!result) {
    throw Error(
      error?.message ||
        "Something went wrong while creating campaign planners.",
    );
  }

  return result;
};

export const useUpdateCampaignPlannerAds = () => {
  const queryClient = useQueryClient();

  return useMutation<
    ApiResponseCampaignPlanner,
    Error,
    ApiResponseCampaignPlanner,
    ReactQueryContext
  >(updateCampaignPlannerAds, {
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.campaignPlanners);
    },
    onMutate: async updatePlannerAdsModel => {
      await queryClient.cancelQueries({
        queryKey: queryKeys.campaignPlanners,
      });

      const previousCampaigns: ApiResponseCampaignPlanner[] =
        queryClient.getQueryData(queryKeys.campaignPlanners) ?? [];

      const ads: IAd[] = queryClient.getQueryData("ads") ?? [];

      if (ads.length) {
        optimisticUpdate(queryClient, updatePlannerAdsModel);
      }

      return { previousCampaigns };
    },
    onError: (err, newCampaign, context) => {
      onMutationError(queryClient, context);
    },
  });
};
