const W_CHAR = 7; // width of a character
const W_MARGIN = 24; // width of a margin
const W_PADDING = 50; // width of a padding
const W_MAX = 1024; // max width

export const getNumVisible = (tags: string[], width: number | undefined) => {
  const firstTwo = tags.slice(0, 2);

  const stringSize =
    firstTwo?.join("")?.length * W_CHAR +
    firstTwo?.length * W_MARGIN -
    W_PADDING;

  return stringSize > (width ?? W_MAX) ? 1 : 2;
};
