type Props = {
  selectedImageType: string;
  setSelectedImageType: (imageType: string) => void;
};

export const ImageSelectionTabs = (props: Props) => {
  const { selectedImageType, setSelectedImageType } = props;

  return (
    <div className="image-type-selection-tabs">
      {["brand", "account"].map(type => (
        <div
          key={`image-type-tab-${type}`}
          className={`image-type-tab ${type} ${
            type === selectedImageType ? "selected" : ""
          }`}
          onClick={() => setSelectedImageType(type)}
        >
          {type === "brand" && "Brand Images"}
          {type === "account" && "Account Images"}
        </div>
      ))}
    </div>
  );
};
