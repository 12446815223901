import { AES, enc } from "crypto-js";

import isEmpty from "lodash/isEmpty";
import { getAlexiaPathFromLocation, getEnvVar } from "./helpers";

import { configMenus } from "configMenus";
import {
  sortingProps,
  previewFormatParamDict,
} from "shared/constants/userPreferences";

import { PreferenceKey, URLParameterKey } from "shared/types/userPreferences";
import { AdFormat } from "screens/adLibrary/facebookUtils/types";
import { SelectedDates } from "shared/types/campaignManagement";
import { preferencefilters } from "screens/campaignManagement/adReview/shared/constants/table";

const getAlexiaMenuItemByRootPath = (urlPath: string) => {
  const currentRootPath = getAlexiaPathFromLocation(urlPath);
  const menuItemByPath = configMenus.find(item =>
    item.path?.includes(currentRootPath),
  );

  return menuItemByPath;
};

export const getAlexiaMenuItemByPath = (urlPath: string) => {
  const rootModule = getAlexiaMenuItemByRootPath(urlPath);
  if (rootModule) return rootModule;
  const moduleBySubpath = configMenus.find(item =>
    item.subMenus?.some(subItem => subItem.path?.includes(urlPath)),
  );

  return moduleBySubpath;
};

export const getAlexiaSubModuleByPath = (urlPath: string) => {
  const menuItem = getAlexiaMenuItemByPath(urlPath);
  const subMenuItem = menuItem?.subMenus?.find(
    subItem => subItem.path && urlPath.includes(subItem.path),
  );

  return subMenuItem?.subModule;
};

// Possible TODO: add SECRET_KEY to env
const secretKey = getEnvVar("SECRET_KEY") || "@l3xiaV2!";

const encryptData = <T = string[]>(data: T) => {
  if (!secretKey) return "";
  const cipherText = AES.encrypt(JSON.stringify(data), secretKey).toString();
  return cipherText;
};

const decryptData = <T = string[]>(cipherText: string) => {
  const bytes = AES.decrypt(cipherText, secretKey);
  const decryptedData: T = JSON.parse(bytes.toString(enc.Utf8));
  return decryptedData;
};

export const parsePropValueToParamValue = <T = string[]>(
  propName: PreferenceKey,
  value: T,
) => {
  if (!value || isEmpty(value)) return;

  if (
    ["selectedColumns", "selectedAdIds", "selectedStore"].includes(propName)
  ) {
    /**
     * Certain data is ciphered so that we can include data
     * in the url without the need for caching or fetching from the backend
     * It also helps shorten the URL
     */
    return encryptData(value);
  }

  if (propName === "previewFormat") {
    return previewFormatParamDict[value as unknown as AdFormat];
  }

  if (["layout", "searchValue"].includes(propName)) {
    return (value as unknown as string).toLowerCase();
  }

  if (preferencefilters.includes(propName)) {
    return (value as unknown as string[]).map(val => val.toString()).join(",");
  }

  return (value as unknown as string[])
    .map(val => val.toString().toLowerCase())
    .join(",");
};

const parsePreviewFormatFromParam = <T = string[]>(value: string) =>
  Object.entries(previewFormatParamDict).find(entry => entry[1] === value)?.[0];

const parseSelectedDateRangeFromParam = (
  value: string,
): SelectedDates | undefined => {
  const values = value.split(",");
  if (values.length !== 2) return;
  const numbers = values.map(val => parseInt(val));
  return [numbers[0], numbers[1]];
};

const parseSortingOrderFromParam = (value: string) => {
  const values = value.split(",");
  if (values.length !== 2) return;
  const prop =
    sortingProps.find(prop => values[0].toLowerCase() === prop.toLowerCase()) ||
    "updatedAt";
  const order = values[1] || "desc";
  return [prop, order];
};

export const parseParamValueToPropValue = (
  paramKey: URLParameterKey | undefined,
  value?: string | null,
) => {
  if (!value || !paramKey) return;

  const encryptedProps: URLParameterKey[] = ["columns", "ids", "store"];

  if (encryptedProps.includes(paramKey)) return decryptData(value);
  if (paramKey === "preview") return parsePreviewFormatFromParam(value);
  if (paramKey === "dates") return parseSelectedDateRangeFromParam(value);
  if (["searchValue", "layout"].includes(paramKey)) return value.toLowerCase();

  return value.split(",");
};
