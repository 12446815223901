import React, { useEffect, useState } from "react";
import { Modal, Input, message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { adAccount } from "../facebookUtils/adAccount";
import {
  ICreateOrUpdateResponse,
  IFacebookAccount,
} from "../facebookUtils/types";

const FacebookPixelModal = ({
  showModal,
  setShowModal,
  setCreatePixelSuccess,
  pixelModalData,
}: {
  showModal: boolean;
  pixelModalData: IFacebookAccount | undefined;
  setCreatePixelSuccess: (createPixelSuccess: boolean) => void;
  setShowModal: (showModal: boolean) => void;
}) => {
  const queryClient = useQueryClient();
  const [pixelName, setPixelName] = useState<string>(
    pixelModalData ? `${pixelModalData?.name}'s Pixel` : "",
  );
  useEffect(() => {
    showModal &&
      setPixelName(pixelModalData ? `${pixelModalData?.name}'s Pixel` : "");
  }, [pixelModalData, showModal]);
  const createFacebookPixel = useMutation<ICreateOrUpdateResponse, Error>(
    () => adAccount.createPixel(pixelName, pixelModalData?.account_id || ""),
    {
      onSettled: () => {
        queryClient.invalidateQueries("pixel");
      },
      onSuccess: () => {
        setCreatePixelSuccess(true);
      },
      onError: err => {
        message.error(err.message);
      },
    },
  );

  return (
    <Modal
      title="Create a new Pixel"
      visible={showModal}
      onCancel={() => {
        setShowModal(false);
      }}
      onOk={() => {
        createFacebookPixel.mutate();
        setShowModal(false);
      }}
    >
      <Input
        value={pixelName}
        placeholder={"Enter the name of the pixel"}
        onChange={e => setPixelName(e.target.value)}
      />
    </Modal>
  );
};

export default FacebookPixelModal;
