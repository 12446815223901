import useSelectedDateRange from "shared/hooks/userPreferences/useSelectedDateRange";
import useSelectedLayout from "shared/hooks/userPreferences/useSelectedLayout";
import useSelectedPreviewFormat from "shared/hooks/userPreferences/useSelectedPreviewFormat";
import { useSelectedStore } from "shared/hooks/userPreferences/useSelectedStore";

const useAdReviewPreferences = () => ({
  ...useSelectedLayout(),
  ...useSelectedStore(),
  ...useSelectedDateRange(),
  ...useSelectedPreviewFormat(),
});

export default useAdReviewPreferences;
