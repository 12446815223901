import { CSSProperties } from "react";
import { FeedOffer } from "./assetBuilder";
import { IDataTableError } from "./errors";

export enum OfferType {
  Lease = "Lease",
  ZeroDownLease = "Zero Down Lease",
  Finance = "Finance",
  APR = "APR",
  Purchase = "Purchase($)",
  "Purchase($ Off)" = "Purchase($ Off)",
  "Purchase(%)" = "Purchase(%)",
  "Purchase(Conditional)" = "Purchase(Conditional)",
  Misc = "Misc",
}

export type SelectedOffers = {
  row: FeedOffer;
  updated?: number;
  editedKeys?: Record<string, true>;
};

export const isOfferType = (offerType: any): offerType is OfferType => {
  return (
    !!offerType &&
    [
      OfferType.Lease,
      OfferType.ZeroDownLease,
      OfferType.Finance,
      OfferType.APR,
      OfferType.Purchase,
      OfferType["Purchase($ Off)"],
      OfferType["Purchase(%)"],
      OfferType["Purchase(Conditional)"],
    ].includes(offerType)
  );
};
export interface IApiResponse<TResult = unknown, TError = IDataTableError> {
  result: null | TResult;
  error: null | TError;
}

export type Module =
  | "templates"
  | "assetBuilder"
  | "stamps"
  | "adLibrary"
  | "adLoad";
export type BucketFor =
  | "thumbnail"
  | "canvasjson"
  | "order-state"
  | "lifestyle-image"
  | "json"
  | "session";

export interface IGetS3ObjectVersionsQueryParams {
  bucket_for: BucketFor;
  module: Module;
  url: string;
}

export interface IGetVersionOfJsonFromS3QueryParams
  extends IGetS3ObjectVersionsQueryParams {
  version_id: string;
}

export interface IGetResizedImgQueryParams {
  imgUrl: string;
  width: number;
  height: number;
}

export interface IS3ObjectVersion {
  ETag: string;
  Size: number;
  StorageClass: string;
  Key: string;
  VersionId: string;
  isLatest: boolean;
  LastModified: string;
  Owner: {
    DisplayName: string;
    ID: string;
  };
}

export interface IGetS3ObjectVersionsResponseData {
  s3Url: string;
  versions: IS3ObjectVersion[];
}

export interface IGetVersionOfJsonFromS3ResponseData {
  versionId: string;
  jsonData: any; // Possible TO DO: implement type insertion <T> here
}

export enum AlexiaModule {
  ADMIN = "ADMIN",
  AD_LIBRARY = "AD_LIBRARY",
  ASSET_BUILDER = "ASSET_BUILDER",
  ASSET_EXPORTER = "ASSET_EXPORTER",
  DESIGN_STUDIO = "DESIGN_STUDIO",
  LEGAL_LINGO = "LEGAL_LINGO",
  INSTANT_EXPERIENCE = "INSTANT_EXPERIENCE", // This renamed to EverythingAds
  ASSET_EXPORT = "ASSET_EXPORT",
  CAMPAIGN_MANAGEMENT = "CAMPAIGN_MANAGEMENT",
  METRICS = "METRICS",
  MEDIA = "MEDIA",
  AI_IMAGE_GEN = "AI_IMAGE_GEN",

  // sales enablement specific modules
  HOME = "HOME",
  ACCOUNTS = "ACCOUNTS",
  AGENTS = "AGENTS",
  PRODUCTS = "PRODUCTS",
  TEMPLATES = "TEMPLATES",
  DISCLOSURES = "DISCLOSURES",
  MY_MARKETING_MATERIALS = "MY_MARKETING_MATERIALS",
  ARCHIVE = "ARCHIVE",
  METRIC = "METRIC",
  PRINT_OPTIONS = "PRINT_OPTIONS",
  // ADMIN,
  // MEDIA

  // brochure manament specific module
  BROCHURE_MANAGEMENT = "BROCHURE_MANAGEMENT",
}

export const AlexiaModules: AlexiaModule[] = [
  AlexiaModule.ADMIN,
  AlexiaModule.AD_LIBRARY,
  AlexiaModule.ASSET_BUILDER,
  AlexiaModule.ASSET_EXPORTER,
  AlexiaModule.DESIGN_STUDIO,
  AlexiaModule.LEGAL_LINGO,
  AlexiaModule.INSTANT_EXPERIENCE,
  AlexiaModule.CAMPAIGN_MANAGEMENT,
  AlexiaModule.METRICS,
  AlexiaModule.MEDIA,
];

export const SalesEnablementAdminOnlyModules: AlexiaModule[] = [
  AlexiaModule.DISCLOSURES,
  AlexiaModule.PRODUCTS,
  AlexiaModule.METRIC,
];

export enum AlexiaSubModule {
  // admin
  USER_MANAGEMENT = "USER_MANAGEMENT",
  STORE_MANAGEMENT = "STORE_MANAGEMENT",
  ACCOUNT_MANAGEMENT = "ACCOUNT_MANAGEMENT",
  OEM_MANAGEMENT = "OEM_MANAGEMENT",
  BRAND_MANAGEMENT = "BRAND_MANAGEMENT",
  DASHBOARD_MANAGEMENT = "DASHBOARD_MANAGEMENT",
  BRANDS_MANAGEMENT = "BRANDS_MANAGEMENT",
  PLATFORM_MANAGEMENT = "PLATFORM_MANAGEMENT",

  // legal lingo
  VARIABLES = "VARIABLES",
  DISCLOSURES = "DISCLOSURES",
  MESSAGING = "MESSAGING",

  // ad library
  ADS = "ADS",
  SESSION = "SESSION",
  AD_LOAD = "AD_LOAD",
  PMAX_ASSET_GROUPS = "PMAX_ASSET_GROUPS",

  // campaign management
  AD_REVIEW = "AD_REVIEW",
  AUDIENCE_MANAGER = "AUDIENCE_MANAGER",
  CAMPAIGNS_PMAX = "CAMPAIGNS_PMAX",
  BEESWAX = "BEESWAX",

  // design studio
  TEMPLATES = "TEMPLATES",
  STAMPS = "STAMPS",
  ARTBOARDS = "ARTBOARDS",

  // everything ads
  EVERYTHING_ADS_MAPPER = "EVERYTHING_ADS_MAPPER",
  EVERYTHING_ADS = "EVERYTHING_ADS",

  // metrics
  DASHBOARDS = "DASHBOARDS",
  SHARED_DASHBOARD_MANAGEMENT = "SHARED_DASHBOARD_MANAGEMENT",

  //Campaign planner
  CAMPAIGN_PLANNER = "CAMPAIGN_PLANNER",

  // AI Tools
  AI_IMAGE_GEN = "AI_IMAGE_GEN",
  AI_IMAGE_RESIZE = "AI_IMAGE_RESIZE",

  // metrics - sales enablement
  OVERVIEW = "OVERVIEW",
  DELIVERED_MATERIALS = "DELIVERED_MATERIALS",
  UNDELIVERED_MATERIALS = "UNDELIVERED_MATERIALS",
  SEARCH_FREQUENCY = "SEARCH_FREQUENCY",
  SUPPORT_DATA = "SUPPORT_DATA",
  OSAT = "OSAT",
  NETWORK_ENGAGEMENT = "NETWORK_ENGAGEMENT",
  TEMPLATE_USAGE = "TEMPLATE_USAGE",
}

export enum IScopeColor {
  Full = "green",
  View = "blue",
  Limited = "orange",
  None = "red",
}

/* Color names are based on button and message types in antd */
export const AppColors = {
  danger: "#FF4D4F", // red
  success: "#52C41A", // green
  warning: "#FAAD14", // orange
  primary: "#1890FF", // blue
  secondary: "#D9D9D9", // gray
  tertiary: "#FFFF00", // yellow
  briefComplete: "#722ED1", // golden purple
  edits: "#FA8C16", // sunset orange
  review: "#EB2F96", // magenta
};

type Reaction = Record<
  string,
  {
    value: number;
    users: string[];
  }
>;

export interface IComment {
  id?: string;
  text?: string;
  objectId?: string;
  createdBy?: string;
  createdAt?: string;
  updatedAt?: string;
  firstName?: string;
  lastName?: string;
  readByUsers?: string[];
  reactions?: Reaction;
  edited?: boolean;
}

export type ImageProps = {
  className?: string;
  style?: CSSProperties;
};

export type IndustryType = "auto" | "retail" | "travel" | "pharma" | "all";

export interface IQCHistoryLog {
  id: string;
  adId?: string;
  firstName?: string;
  lastName?: string;
  status?: QCStatus;
  createdAt: string;
  updatedAt?: string;
}

export enum QCStatus {
  APPROVED = "Approved",
  DENIED = "Denied",
  NOT_QCed = "Not QC'ed",
}

export type InitiatingShareLink = {
  module: AlexiaModule;
  at: number; // Date.now() when share button clicked
};
export type SharedProps = {
  initiatingShareLink?: InitiatingShareLink;
  shareLinkUrl?: string;
};

type ObjectKeys<T> = T extends Record<string, any>
  ? (keyof T)[]
  : T extends number
  ? []
  : T extends Array<any> | string
  ? string[]
  : never;

// This helps me do Object.keys but be sure I get the right key type back
export function keys<T extends Record<string, any>>(o: T): ObjectKeys<T> {
  return Object.keys(o) as ObjectKeys<T>;
}
