import API from "services";
import { config } from "../config";
import { LOCAL_STORAGE_KEY } from "../constants";

export const fetchToken = async (body: MGTokenPayload): Promise<MGTokenObj> => {
  const { result, error } = await API.services.media.token({
    ...body,
    client_id: config.wdClientId,
  });

  if (result) {
    const { access_token, refresh_token, expires_in } = result;
    const expiresOn = new Date(Date.now() + expires_in * 1000).getTime();

    return {
      access_token,
      refresh_token,
      expiresOn,
    };
  }

  // Fetch token failed.
  // Remove stored token and throw.
  localStorage.removeItem(LOCAL_STORAGE_KEY);

  throw new Error(error?.message ?? "[WD] Failed to fetch token");
};
