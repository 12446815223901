import { Checkbox, Table, Tooltip, Typography } from "antd";
import { ColumnProps } from "antd/es/table";
import { FC } from "react";
import { connect } from "react-redux";
import { User } from "redux/auth/auth.slice";
import { AppState } from "redux/store";

import "../../LegalLingo.scss";

interface ILegalLingoTableProps {
  baseColumns: Array<ColumnProps<any>>;
  listOfCheckedValues: Array<Record<string, any>>;
  columnTitleProperty: string;
  onChange: (address: [number, string], tableName: string) => void;
  data: Array<{
    field?: string;
    sampleData?: string;
    key?: string;
    title?: string;
  }>;
  tableName: string;
  scroll: number;
  loggedInUser?: User;
}

const LegalLingoTable: FC<ILegalLingoTableProps> = ({
  baseColumns,
  listOfCheckedValues,
  onChange,
  columnTitleProperty,
  data,
  tableName,
  scroll,
}) => {
  const handleCheckboxClick = (
    record: { key: string; sampleData: string; field: string },
    index: number,
  ) => {
    const { field } = record;
    onChange([index, field], tableName);
  };
  if (!listOfCheckedValues) {
    return null;
  }

  const columns: Array<ColumnProps<any>> = listOfCheckedValues.map(
    (value, index) => {
      const title = value[columnTitleProperty];
      return {
        title: (
          <Tooltip title={title}>
            <Typography.Text ellipsis={true}>{title}</Typography.Text>
          </Tooltip>
        ),
        dataIndex: title,
        key: `${index}`,
        width: 80,
        // eslint-disable-next-line
        render: (_text: string, record: any) => {
          const { field } = record;
          return (
            <Checkbox
              checked={value[field] && value[field].includes("true")}
              onClick={() => {
                handleCheckboxClick(record, index);
              }}
            />
          );
        },
      };
    },
  );

  return (
    <div>
      <Table
        columns={[...baseColumns, ...columns]}
        dataSource={data}
        scroll={{ x: scroll }}
        className="data-management-table"
        pagination={false}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  const { auth } = state;
  return {
    loggedInUser: auth.user,
  };
};

export default connect(mapStateToProps)(LegalLingoTable);
