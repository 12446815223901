import { AdsToLoadFiltersObject } from "./utils";
import { AdLoadColumnKey, ReviewStepKeys } from "shared/types/adLibrary";

export const innerStepColumnKeys: Record<ReviewStepKeys, AdLoadColumnKey[]> = {
  REVIEW_DESTINATION_URLS: [
    "Account Name",
    "Ad Name",
    "Destination URL",
    "Display URL",
  ],
  CONVERT_TO_VIDEO: [
    "Account Name",
    "Ad Name",
    "Ad Set Name",
    "Campaign Name",
    "Destination URL",
    "Display URL",
    "CTA Button",
  ],
  ASSIGN_PRODUCT_SETS_AD_LEVEL: [
    "Account Name",
    "Ad Set Name",
    "Ad Name",
    "Product Catalog",
    "Product Set",
  ],
  ASSIGN_PRODUCT_SETS_IE_LEVEL: [
    "Account Name",
    "Ad Name",
    "Instant Experience Name",
    "Product Catalog",
    "Product Set",
  ],
  LINK_INSTANT_EXPERIENCE: [
    "Account Name",
    "Ad Name",
    "Instant Experience Name",
    "Product Catalog",
    "Product Set",
  ],
  INVALID_DESTINATION_URL: [],
};

export const reviewStepTableFilters: AdsToLoadFiltersObject = {
  adLoadStatus: {
    filters: [
      { text: "Off", value: "draft" },
      { text: "On", value: "success" },
      { text: "Warning", value: "warning" },
      { text: "Error", value: "error" },
    ],
    onFilter: (value, record) => record.adLoadStatus.status === value,
  },
};

export const loadStepTableFilters: AdsToLoadFiltersObject = {
  adLoadStatus: {
    filters: [
      { text: "Ready to load", value: "idle" },
      { text: "Processing", value: "loading" },
      { text: "Loaded", value: "success" },
      { text: "Error", value: "error" },
    ],
    onFilter: (value, record) => record.adLoadStatus.status === value,
  },
};

export const activeHeaderButtonStyle = {
  color: "#40a9ff",
  background: "#fff",
  borderColor: "#40a9ff",
};
