import { useQuery } from "react-query";
import { IInstantExperience } from "shared/types/adLibrary";
import API from "services";

export const useFetchInstantExperiences = (
  adId?: string,
  sharedAdReviewId?: string,
  enabled?: boolean,
) => {
  const fetchFacebookInstantExperience = async (
    adId?: string,
    sharedAdReviewId?: string,
  ) => {
    if (!adId) throw Error("adId is required");
    const { result, error } =
      await API.services.adReview.getFacebookInstantExperience(adId, {
        sharedAdReviewId,
      });

    if (!result) {
      throw Error(
        error?.message ||
          "Something went wrong while fetching the Instant Experience.",
      );
    }

    return result;
  };

  return useQuery<IInstantExperience[], Error>(
    ["instantExperience", adId, sharedAdReviewId],
    () => fetchFacebookInstantExperience(adId, sharedAdReviewId),
    {
      enabled: !!adId && enabled,
    },
  );
};
