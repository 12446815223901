import { TreeProps } from "antd";
import { useCallback } from "react";
import { useSetCurrentSession } from "screens/adLibrary/adLoadV2/hooks/useCurrentSession";
import {
  NormalizedCampaignData,
  getAdKeyFromDest,
} from "../../../../campaignData.utils";
import { TargetAdset } from "screens/adLibrary/facebookUtils/adProto.types";
import { useNormalizedCampaignsData } from "screens/adLibrary/adLoadV2/hooks/useNormalizedCampaignsData";

export const useGetCheckedKeys = () => {
  const { campaignData } = useNormalizedCampaignsData();

  const adsetsWithAds = Object.values(campaignData.adsets).filter(
    adset => adset.ads.length > 0,
  );

  const campaignsWithAllAdSetsWithAds = Object.values(
    campaignData.campaigns,
  ).filter(
    campaign =>
      campaign.adsetIds.length &&
      campaign.adsetIds.every(adsetId =>
        adsetsWithAds.some(adset => adset.id === adsetId),
      ),
  );

  const storesWithAllCampaignsWithAllAdSetsWithAds = Object.values(
    campaignData.stores,
  ).filter(
    account =>
      account.campaignIds.length &&
      account.campaignIds.every(campaignId =>
        campaignsWithAllAdSetsWithAds.some(
          campaign => campaign.id === campaignId,
        ),
      ),
  );

  const checkedKeys = [
    ...adsetsWithAds,
    ...campaignsWithAllAdSetsWithAds,
    ...storesWithAllCampaignsWithAllAdSetsWithAds,
  ].map(item => item.key.toString());

  return {
    checkedKeys,
  };
};

export const useOnCheckKeys = ({
  selectedAdIds,
}: {
  selectedAdIds: string[];
}) => {
  const { campaignData } = useNormalizedCampaignsData();
  const { setSession } = useSetCurrentSession();

  const onCheck = useCallback<Exclude<TreeProps["onCheck"], undefined>>(
    (_, checkedKeysInfo) => {
      setSession(prevSession => {
        const targetNodeKey = checkedKeysInfo.node.key.toString();
        const [storeName] = targetNodeKey.toString().split("_");

        const prevProtoDrafts = prevSession.data.adProtoDrafts;
        prevProtoDrafts
          .filter(
            protoDraft =>
              protoDraft.storeName === storeName &&
              selectedAdIds.includes(protoDraft.id),
          )
          .forEach(protoDraft => {
            if (checkedKeysInfo.checked) {
              protoDraft.dests.push(
                ...getNewTargetAdsets({
                  campaignData,
                  targetNodeKey,
                  selectedAdIds,
                }),
              );
            } else {
              protoDraft.dests = protoDraft.dests.filter(dest => {
                return !getAdKeyFromDest({
                  storeName,
                  campaignId: dest.campaignId,
                  adsetId: dest.adsetId,
                  adId: protoDraft.id,
                }).startsWith(targetNodeKey);
              });
            }
          });
      });
    },
    [campaignData, selectedAdIds, setSession],
  );

  return {
    onCheck,
  };
};

const getNewTargetAdsets = ({
  targetNodeKey,
  campaignData,
}: {
  targetNodeKey: string;
  campaignData: NormalizedCampaignData;
  selectedAdIds: string[];
}): TargetAdset[] => {
  const [storeName, campaignId, adsetId] = targetNodeKey.toString().split("_");

  if (adsetId) {
    return [
      {
        adsetId,
        campaignId,
        loadToFb: true,
        productInfo: {
          productCatalogId: campaignData.campaigns[campaignId].productCatalogId,
          productSetId: campaignData.adsets[adsetId].productSetId,
        },
      },
    ];
  }
  if (campaignId) {
    const targetAdsetIds = campaignData.campaigns[campaignId].adsetIds;
    return targetAdsetIds.flatMap(adsetIdItem => {
      return {
        adsetId: adsetIdItem,
        campaignId,
        loadToFb: true,
        productInfo: {
          productCatalogId: campaignData.campaigns[campaignId].productCatalogId,
          productSetId: campaignData.adsets[adsetIdItem].productSetId,
        },
      };
    });
  }
  if (storeName) {
    const targetCampaignIds = campaignData.stores[storeName].campaignIds;
    return targetCampaignIds.flatMap(campaignIdItem => {
      const targetAdsetIds = campaignData.campaigns[campaignIdItem].adsetIds;
      return targetAdsetIds.flatMap(adsetIdItem => {
        return {
          adsetId: adsetIdItem,
          campaignId: campaignIdItem,
          loadToFb: true,
          productInfo: {
            productCatalogId:
              campaignData.campaigns[campaignIdItem].productCatalogId,
            productSetId: campaignData.adsets[adsetIdItem].productSetId,
          },
        };
      });
    });
  }

  return [];
};
