import { useFetchQCHistoryLogs } from "./useFetchQCHistoryLogs";
import useDeleteQCHistoryLog from "./useDeleteQCHistoryLog";
import useBatchMutateQCHistoryLog from "./useMutateQCHistoryLog";

type UseQCHistoryLogsArgs = {
  id?: string;
  fetchAdsKey?: string[];
};

const useQCHistoryLogs = ({ id, fetchAdsKey }: UseQCHistoryLogsArgs) => {
  const {
    qcHistoryLogs,
    isLoading,
    isFetching,
    error: fetchError,
    isError: isFetchError,
    refetch: fetchQCHistoryLogs,
  } = useFetchQCHistoryLogs(id ?? "");

  const {
    error: mutateError,
    mutate: mutateQCHistoryLog,
    isLoading: isMutating,
    isError: isMutateError,
  } = useBatchMutateQCHistoryLog({ fetchAdsKey });

  const {
    error: deleteError,
    mutate: deleteQCHistoryLog,
    isLoading: isDeleting,
    isError: isDeleteError,
  } = useDeleteQCHistoryLog();

  return () => {
    const loadingQCHistoryLogs = [isLoading, isFetching].includes(true);
    const processingQCHistoryLogs = [isMutating, isDeleting].includes(true);
    const isQCHistoryLogsError = [
      isFetchError,
      isMutateError,
      isDeleteError,
    ].includes(true);
    const qcHistoryLogsError = fetchError || mutateError || deleteError;

    return {
      id,
      qcHistoryLogs,
      qcHistoryLogsError,
      isQCHistoryLogsError,
      loadingQCHistoryLogs,
      processingQCHistoryLogs,
      mutateQCHistoryLog,
      deleteQCHistoryLog,
      fetchQCHistoryLogs,
    };
  };
};

export default useQCHistoryLogs;
