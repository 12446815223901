import { Menu, Popover } from "antd";

import SubMenu from "./SubMenu";
import { context } from "../../../Editor.context";
import useMenus, { TToolbarMenu } from "../toolbar.hooks/useMenus";
import { IConfigurationState } from "shared/types/configuration";

import styles from "./InsertButtonMenus.module.scss";
import { FC, useContext, useState } from "react";
interface InsertButtonMenusProps {
  showInsertMenuPopover: boolean;
  config: IConfigurationState["config"];
}

interface InsertButtonMenusHandlers {
  toggleInsertMenuPopover: (show: boolean) => void;
}

const InsertButtonMenus: FC<
  InsertButtonMenusProps & InsertButtonMenusHandlers
> = props => {
  const { showInsertMenuPopover, toggleInsertMenuPopover } = props;

  const editorContext = useContext(context);

  const [currentSelectedMenu, setCurrentSelectedMenu] =
    useState<TToolbarMenu>();

  const { menus, openedSubMenu } = useMenus({
    editorContext,
    showInsertMenuPopover,
    currentSelectedMenu,
    onComplete: showMenu => {
      toggleInsertMenuPopover(showMenu);

      if (!showMenu) setCurrentSelectedMenu(undefined);
    },
  });
  return (
    <Menu
      className={styles.InsertMenu}
      selectedKeys={[]}
      onClick={e => {
        const foundMenu = menus.find(menu => menu.title === e.key);
        setCurrentSelectedMenu(foundMenu);

        foundMenu?.onClick && foundMenu.onClick();
      }}
    >
      {menus
        .filter(menu => menu.selectedTab.includes("templates"))
        .map(menu => {
          const className = `insert-button-${menu.title.replace(/\s+/g, "_")}`;

          return (
            <Menu.Item className={className} key={menu.title}>
              <div
                style={{
                  width: "10em",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ flex: 1 }}>{menu.title}</span>
                <span style={{ flex: "initial", marginLeft: "auto" }}>
                  <Popover
                    className={styles.SubMenuPopover}
                    placement="bottom"
                    trigger="click"
                    visible={showInsertMenuPopover && openedSubMenu[menu.title]}
                    title={null}
                    content={
                      <SubMenu
                        menuType={menu.title}
                        toggleInsertMenuPopover={show => {
                          toggleInsertMenuPopover(show);
                        }}
                        config={props.config}
                      />
                    }
                  >
                    {menu.icon}
                  </Popover>
                </span>
              </div>
            </Menu.Item>
          );
        })}
    </Menu>
  );
};

export default InsertButtonMenus;
