import { fabric } from "fabric";
import uuid from "uuid";

import {
  IDimension,
  IExtendedFabricObject,
  LogoDropZoneType,
  LogoEventType,
} from "shared/types/designStudio";
import * as fabricHelpers from "../../utils/helpers.fabric";

import svgSalesEventHorizontal from "../../statics/images/event_logos/SALES_EVENT_LOGO_horizontal.svg";
import svgSalesEventSquare from "../../statics/images/event_logos/SALES_EVENT_LOGO_square.svg";
import svgSalesEventVertical from "../../statics/images/event_logos/SALES_EVENT_LOGO_vertical.svg";

import svgAccountHorizontal from "../../statics/images/event_logos/ACCOUNT_LOGO_horizontal.svg";
import svgAccountSquare from "../../statics/images/event_logos/ACCOUNT_LOGO_square.svg";
import svgAccountVertical from "../../statics/images/event_logos/ACCOUNT_LOGO_vertical.svg";

import GenericError from "shared/errors/GenericError";
import svgBrandHorizontal from "../../statics/images/event_logos/BRAND_LOGO_horizontal.svg";
import svgBrandSquare from "../../statics/images/event_logos/BRAND_LOGO_square.svg";
import svgBrandVertical from "../../statics/images/event_logos/BRAND_LOGO_vertical.svg";
import { getHeight, getWidth } from "utils/fabric/helpers.utils";

const svgFileUrls: { [key: string]: string } = {
  SALES_EVENT_LOGO_square: svgSalesEventSquare,
  SALES_EVENT_LOGO_vertical: svgSalesEventVertical,
  SALES_EVENT_LOGO_horizontal: svgSalesEventHorizontal,

  ACCOUNT_LOGO_square: svgAccountSquare,
  ACCOUNT_LOGO_vertical: svgAccountVertical,
  ACCOUNT_LOGO_horizontal: svgAccountHorizontal,

  BRAND_LOGO_square: svgBrandSquare,
  BRAND_LOGO_vertical: svgBrandVertical,
  BRAND_LOGO_horizontal: svgBrandHorizontal,
};

export const getSvgLogoFileUrl = (
  eventType: LogoEventType,
  dropZone: LogoDropZoneType,
): string => {
  const svgFileKey = `${eventType}_${dropZone}`;

  if (!svgFileUrls[svgFileKey]) {
    throw new GenericError({
      message: `The SVG file was not imported with key: ${svgFileKey}`,
    });
  }

  return svgFileUrls[svgFileKey];
};

export const getInitialLogoSize = (
  canvasDimension: IDimension,
  dropZone: LogoDropZoneType,
): IDimension => {
  // aspect ratio for each shape (width : height)
  //  square -> 1:1
  //  horizontal -> 3:1
  //  vertical -> 1:3
  //  start with 1/6 of the canvas's smaller side of dimension.
  //  for square, start with 1/3
  let initialSize = 0;
  if (canvasDimension.width < canvasDimension.height) {
    initialSize =
      (dropZone as LogoDropZoneType) === "square"
        ? canvasDimension.width / 3
        : canvasDimension.width / 6;
  } else {
    initialSize =
      (dropZone as LogoDropZoneType) === "square"
        ? canvasDimension.height / 3
        : canvasDimension.height / 6;
  }

  let dimension: IDimension = {
    width: initialSize,
    height: initialSize,
  }; // default to square

  switch (dropZone as LogoDropZoneType) {
    case "horizontal":
      dimension = {
        width: initialSize * 3,
        height: initialSize,
      };
      break;
    case "vertical":
      dimension = {
        width: initialSize,
        height: initialSize * 3,
      };
      break;
  }

  return dimension;
};

export const createLogoFabricObject = ({
  logoFileUrl,
  position,
  eventType,
  dropZone,
  logoDimension,
  shiftToDraggedPosition,
}: {
  logoFileUrl: string;
  position: {
    top: number;
    left: number;
  };
  eventType: LogoEventType;
  dropZone: LogoDropZoneType;
  logoDimension: IDimension;
  shiftToDraggedPosition: boolean;
}): Promise<IExtendedFabricObject> => {
  return new Promise(resolve => {
    fabric.loadSVGFromURL(logoFileUrl, objects => {
      const obj = fabric.util.groupSVGElements(objects, {
        name: uuid(),
        top: position.top,
        left: position.left,
      });

      if ((dropZone as LogoDropZoneType) === "vertical") {
        obj.scaleToHeight(logoDimension.height);
      } else {
        obj.scaleToWidth(logoDimension.width);
      }

      if (shiftToDraggedPosition) {
        // At this point, the object will be insert at the mouse pointer position as top, left.
        // It will feel natual if we shift obj little bit so that the obj's center become the mouse position.
        obj.set({
          top: (obj.top || 0) - getHeight(obj) / 2,
          left: (obj.left || 0) - getWidth(obj) / 2,
        });
      }

      const extendedObj = fabricHelpers.extendFabricObject({
        objectType: "logo",
        object: obj,
        properties: {
          logoEventType: eventType as LogoEventType,
          logoDropZoneType: dropZone as LogoDropZoneType,
          layerName: "",
        },
      });

      resolve(extendedObj);
    });
  });
};

/**
 * @param dragEvent
 * @param canvasDimension
 * @param canvasAreaMargin
 *
 * NOTE: the logoPosition will be relative to the canvasArea (the rect at the center). So 0,0 meaning top, left of this canvasArea
 */
export const getDataFromDragEvent = (
  dragEvent: React.DragEvent<HTMLDivElement>,
  canvasDimension: IDimension,
  canvasAreaMargin?: {
    top: number;
    left: number;
  },
): {
  logoPosition: { top: number; left: number };
  logoDimension: IDimension;
  eventType: LogoEventType;
  dropZone: LogoDropZoneType;
  logoFileUrl: string;
} => {
  const draggedDivElement = dragEvent.target as HTMLDivElement;
  const draggedDivBoundingRect = draggedDivElement.getBoundingClientRect();

  const x =
    dragEvent.clientX -
    draggedDivBoundingRect.left -
    (canvasAreaMargin?.left || 0);
  const y =
    dragEvent.clientY -
    draggedDivBoundingRect.top -
    (canvasAreaMargin?.top || 0);
  const logoEventType = dragEvent.dataTransfer.getData(
    "logoEventType",
  ) as LogoEventType;
  const logoDropZoneType = dragEvent.dataTransfer.getData(
    "logoDropZoneType",
  ) as LogoDropZoneType; // square | vertical | horizontal

  const logoDimension = getInitialLogoSize(canvasDimension, logoDropZoneType);
  return {
    logoPosition: {
      top: y,
      left: x,
    },
    logoDimension,
    eventType: logoEventType,
    dropZone: logoDropZoneType,
    logoFileUrl: getSvgLogoFileUrl(logoEventType, logoDropZoneType),
  };
};
