import {
  CopyOutlined,
  ExclamationCircleFilled,
  HistoryOutlined,
  WarningFilled,
} from "@ant-design/icons";
import { message, notification, Tooltip } from "antd";
import { useQueryClient } from "react-query";
import Checkbox from "antd/lib/checkbox/Checkbox";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ISavedOrderState,
  OfferData,
  SingletonOfferKeys,
} from "shared/types/assetBuilder";
import { OfferType } from "shared/types/shared";
import { toUSD } from "utils/helpers.currency";
import { TSortByOption } from "../../OfferFilterV2";
import API from "services";
import styles from "./CollapsableHeader.module.scss";
import { TSessionUpdateRecords } from "../OfferCollapse";
import { isEmpty } from "lodash";
import modal from "antd/lib/modal";
import OfferTypeBadgeContainer from "./collapsableHeader/OfferTypeBadgeContainer";
import { getDataType, getTitle } from "./CollapsableHeader.utils";
import UndoNotification from "./collapsableHeader/UndoNotification";
import { ReactComponent as SoldIcn } from "statics/images/svg/icons/sold-icon.svg";
import { UseGetTabData } from "shared/hooks/assetBuilder/useGetTabData";
import { convertEpochTimeToFullDateString, isAuto } from "utils/helpers";
import actions from "redux/rootActions";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import useToggleOffer from "shared/hooks/assetBuilder/useToggleOffer";
import { CheckboxChangeEvent } from "antd/es/checkbox";

export type TTransactability = Record<string, string>;
export type TOnOfferSelection = (offers: OfferType[]) => void;
export interface Props {
  edited: boolean;
  title: string;
  offerData: Partial<OfferData>;
  selectedOfferTypes: OfferType[];
  isInvalidFeed?: boolean;
  lastUpdated?: number;
  errorMessage?: string;
  warningMessage?: string;
  sortByOptions?: TSortByOption[];
  sessionUpdateRecords?: TSessionUpdateRecords;
  feedId: string;
  savedOrder: ISavedOrderState;
}

export interface Handlers {
  onOfferSelection?: TOnOfferSelection;
  revertSession?: () => void;
  setAddingMode?: Dispatch<SetStateAction<boolean>>;
  setDuplicatedOfferData?: Dispatch<
    SetStateAction<Partial<OfferData> | undefined>
  >;
}

const CollapsableHeader = (props: Props & Handlers) => {
  const queryClient = useQueryClient();

  // NOTE: for non-auto client, there is no offer types to choose. So assigning an empty array.
  const selectableOfferTypes = useMemo(
    () =>
      isAuto
        ? [
            OfferType.Lease,
            OfferType.ZeroDownLease,
            OfferType.Finance,
            OfferType.Purchase,
            OfferType.APR,
          ]
        : [OfferType.Misc],
    [],
  );

  const offerInfoKeyTitle = useMemo(() => {
    return props.sortByOptions
      ?.filter(option => option.key !== "updated")
      ?.map(
        sortByOption =>
          sortByOption.key === "dateInStock" ? "daysInStock" : sortByOption.key, // NOTE: we need to use daysInStock here. This daysInStock is calculated when we fetch offer list
      )
      ?.map(key => ({
        title: getTitle(key as SingletonOfferKeys),
        dataType: getDataType(key as SingletonOfferKeys),
        key,
      }));
  }, [props.sortByOptions]);

  const didSessionUpdate = useMemo(
    () => !isEmpty(props.sessionUpdateRecords),
    [props.sessionUpdateRecords],
  );

  const onRevertToOriginal = useCallback(
    () => async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();

      if (!props.offerData.vin) {
        message.error("VIN is invalid.");

        return;
      }

      const { result: deletedOfferEdits, error } =
        await API.services.assetBuilder.deleteOfferEdits(props.offerData.vin);

      if (error || !deletedOfferEdits) {
        // NOTE: if deletedOfferEdits is null, it means there was
        //        an error in the api side. So unable to proceed here for undo.
        message.error("Unable to revert data.");

        return;
      }

      // invalidate query here to refresh cache
      queryClient.invalidateQueries("offer-list");

      const notificationKey = `notification_key_${props.offerData.vin}`;

      notification["info"]({
        key: notificationKey,
        message: "Field Value Reverted",
        description: (
          <UndoNotification
            vin={props.offerData.vin}
            deletedOfferEdits={deletedOfferEdits}
            invalidateQueries={key => {
              // NOTE: queryClient wont be available in this component. So do it here.
              queryClient.invalidateQueries(key);
            }}
          />
        ),
        duration: 10,
      });
    },
    [props.offerData.vin, queryClient],
  );

  const dispatch = useAppDispatch();

  const onClickDuplicate = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    const vinExcludedData = {
      ...props.offerData,
      vin: undefined,
      dateInStock: undefined,
    };

    props.setDuplicatedOfferData?.(vinExcludedData);
    props.setAddingMode?.(true);

    dispatch(
      actions.assetBuilder.setEditOfferData({
        rawOfferData: vinExcludedData as unknown as OfferData,
        editedKeys: {},
        operation: "CREATE",
      }),
    );
  };

  const [soldOffer, setSoldOffer] = useState(false);
  const selected = props.selectedOfferTypes.length > 0;
  const {
    feedId,
    offerData: { vin },
  } = props;
  const { dealer_code: dealerCode, dealer_oem: dealerOem } =
    props.savedOrder.selectedOrder;

  const onSelectedData = useCallback(async () => {
    const getTabDataCount = async (vin: string) => {
      const params: UseGetTabData = {
        feedId: feedId ?? "",
        dealerCode: dealerCode ?? "",
        dealerOem: dealerOem ?? "",
        filterBy: "New",
        sortingOptions: [],
        searchBy: vin,
        filterField: "dealerId",
        filterFieldSearch: "",
      };
      const res = await API.services.assetBuilder.getTabData(params, 1);
      const { result, error } = res;
      if (error) {
        return -1;
      }
      return result?.total || 0;
    };

    const VIN_LENGTH = 17;
    if (selected && !soldOffer && (vin ?? "").length <= VIN_LENGTH) {
      const total = await getTabDataCount(vin ?? "");
      if (total === 0) {
        setSoldOffer(true);
      }
    }
  }, [feedId, vin, dealerCode, dealerOem, selected, soldOffer]);

  useEffect(() => {
    onSelectedData();
  }, [onSelectedData]);

  const toggleOffer = useToggleOffer();

  const onChangeCheckBox = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    const toggleOfferTypes = checked
      ? selectableOfferTypes.filter(
          offerType => !props.selectedOfferTypes.includes(offerType),
        )
      : selectableOfferTypes.filter(offerType =>
          props.selectedOfferTypes.includes(offerType),
        );

    toggleOfferTypes.map(offerType => {
      toggleOffer({
        offerType,
        offerData: props.offerData as OfferData,
      });
    });

    props.onOfferSelection?.(!checked ? [] : selectableOfferTypes);
  };

  return (
    <div className={styles.CollapsableHeader}>
      <div className={styles.Left}>
        <div className={styles.Top}>
          <div className={styles.Title}>
            {props.errorMessage && (
              <Tooltip title={props.errorMessage}>
                <ExclamationCircleFilled className={styles.Flagged} />
              </Tooltip>
            )}

            {props.warningMessage && (
              <Tooltip title={props.warningMessage}>
                <WarningFilled className={styles.Warning} />
              </Tooltip>
            )}

            {soldOffer && (
              <span className={styles.Sold}>
                <SoldIcn />
              </span>
            )}
            <span>{props.title}</span>
          </div>
          {isAuto && (
            <OfferTypeBadgeContainer
              offerData={props.offerData}
              isInvalidFeed={props.isInvalidFeed}
              selectedOfferTypes={props.selectedOfferTypes}
              onOfferSelection={props.onOfferSelection}
            />
          )}
          <div className={styles.SelectAll}>
            <Checkbox
              checked={selectableOfferTypes.every(selectable =>
                props.selectedOfferTypes.find(
                  selected => selected === selectable,
                ),
              )}
              onClick={e => {
                e.stopPropagation(); // This is needed to prevent collapse from expanding when checkbox is clicked
              }}
              onChange={onChangeCheckBox}
            >
              {isAuto ? "Select All" : "Select"}
            </Checkbox>
          </div>
        </div>

        <div className={styles.Bottom}>
          {offerInfoKeyTitle?.map(offerInfo => (
            <div
              key={`offer-info-${offerInfo.key}`}
              className={styles.OfferKeyValue}
            >
              <span className={styles.Key}>{offerInfo.title}:</span>
              <span className={styles.Value}>
                &nbsp;
                {offerInfo.dataType === "number"
                  ? props.offerData[offerInfo.key as SingletonOfferKeys]
                  : toUSD(props.offerData[offerInfo.key as SingletonOfferKeys])}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.Right}>
        <div className={styles.Top}>
          <div className={styles.Tools}>
            {props.edited && (
              <Tooltip title="Reset all fields to the default raw feed values">
                <HistoryOutlined onClick={onRevertToOriginal()} />
              </Tooltip>
            )}
            <CopyOutlined onClick={onClickDuplicate} />
          </div>
        </div>

        <div className={styles.Bottom}>
          <div className={styles.LastEditRevert}>
            <div className={styles.LastEdit}>{`Edited: ${
              props.lastUpdated
                ? convertEpochTimeToFullDateString(props.lastUpdated)
                : "--"
            }`}</div>
            {didSessionUpdate && (
              <div className={styles.RevertSession}>
                <Tooltip title="Undo all edits made in this current editing session">
                  <span
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();

                      modal.confirm({
                        title: "Revert session.",
                        content: "Do you want to revert current session?",
                        onOk: () => {
                          props.revertSession?.();
                        },
                      });
                    }}
                  >
                    Revert
                  </span>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollapsableHeader;
