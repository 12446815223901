import { Modal, Button } from "antd";
import { usePMaxDataContext } from "shared/components/contextAPI/pmaxAssetGroup/PMaxDataProvider";
import styles from "./PmaxDrawerFooter.module.scss";

type PmaxUpdateFooterProps = {
  disabled?: boolean;
  onClose: () => void;
};

export const PmaxUpdateDrawerFooter = ({
  disabled,
  onClose,
}: PmaxUpdateFooterProps) => {
  const ctx = usePMaxDataContext();

  const footerDisabled =
    disabled || ctx.isUpdating || !ctx.getComputedSelection("update").length;

  const titleText =
    "Future changes in Google Ads won't change the asset group statuses in Constellation.";
  const detailText =
    "Once you click ”Continue” and all asset groups load successfully, they will show as ”Loaded” in Constellation. Further changes in loaded asset groups made in the Google Ads won't affect their statuses in Constellation.";
  const confirmUpdateModal = () => {
    Modal.confirm({
      title: <b>{titleText}</b>,
      content: <p>{detailText}</p>,
      onOk: ctx.onUpdateGoogleAds,
      okText: "Continue",
    });
  };

  return (
    <div className={styles.buttonContainer}>
      <Button onClick={onClose}>Cancel</Button>
      <Button
        type="primary"
        onClick={confirmUpdateModal}
        loading={ctx.isUpdating}
        disabled={footerDisabled}
      >
        Update Google Ads Status
      </Button>
    </div>
  );
};
