import { Form, Input, notification } from "antd";
import type { Rule } from "antd/lib/form";
import {
  AgentProfile,
  isMissingLicense,
} from "screens/adLibrary/marketingMaterialDrawer/hooks/agentManagement";
import { baseRules } from "screens/adLibrary/marketingMaterialDrawer/utils/helpers";
import InputPhone, { phoneValidator } from "shared/components/InputPhone";
import type { StateKey } from "shared/constants/states";
import { useFetchAgentFeedInfoByEmail } from "shared/hooks/salesEnablement/useAgentFeedData";
import { filterLicensedStates } from "shared/hooks/salesEnablement/useAgentLicensedStates";
import {
  useCreateAgentProfile,
  useUpdateAgentProfile,
} from "shared/hooks/salesEnablement/useAgentProfiles";
import type { AgentFeedInfo } from "shared/types/salesEnablement";

export const agentProfileFormId = "agent-profile-form";

export type AgentProfileFormValues = Omit<
  AgentProfile,
  "id" | "createdAt" | "updatedAt"
>;

type Props = {
  onSuccess: (data: AgentProfile) => void;
  agentProfile: AgentProfile | undefined;
};

const emptyAgentProfile: AgentProfileFormValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
};

const AgentProfileDrawerForm = ({ onSuccess, agentProfile }: Props) => {
  const { mutate: createAgentProfile } = useCreateAgentProfile();
  const { mutate: updateAgentProfile } = useUpdateAgentProfile();
  const getAgentFeedInfo = useFetchAgentFeedInfoByEmail();

  const onFinish = (values: AgentProfileFormValues): void => {
    if (agentProfile) {
      updateAgentProfile(
        { ...agentProfile, ...values },
        {
          onSuccess,
          onError: error => {
            notification.error({
              message: error.message ?? "Error updating agent",
            });
          },
        },
      );
      return;
    }

    createAgentProfile(values, {
      onSuccess,
      onError: error => {
        notification.error({
          message: error.message ?? "Error creating agent",
        });
      },
    });
  };

  return (
    <Form<AgentProfileFormValues>
      id={agentProfileFormId}
      validateTrigger="onBlur"
      layout="vertical"
      initialValues={agentProfile ?? emptyAgentProfile}
      onFinish={onFinish}
    >
      <div
        style={{
          display: "flex",
          gap: "10px",
        }}
      >
        <Form.Item
          label="First Name"
          name="firstName"
          style={{
            width: "100%",
          }}
          rules={baseRules("Please add first name")}
        >
          <Input placeholder="Enter Text" />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastName"
          rules={baseRules("Please add last name")}
          style={{
            width: "100%",
          }}
        >
          <Input placeholder="Enter Text" />
        </Form.Item>
      </div>
      <Form.Item
        label="Email"
        name="email"
        validateFirst
        rules={agentEmailItemRules({ getAgentFeedInfo })}
      >
        <Input placeholder="Enter Text" />
      </Form.Item>

      <div
        style={{
          display: "flex",
          gap: "10px",
        }}
      >
        <Form.Item
          label="Phone"
          name="phoneNumber"
          rules={[
            ...baseRules("Please add phone number"),
            {
              validator: (_, value) => phoneValidator(value),
            },
          ]}
          style={{
            width: "100%",
          }}
        >
          <InputPhone placeholder="Enter Text" />
        </Form.Item>
      </div>
    </Form>
  );
};

export default AgentProfileDrawerForm;

export function agentEmailItemRules({
  getAgentFeedInfo,
}: {
  getAgentFeedInfo: (email: string) => Promise<AgentFeedInfo>;
}) {
  return [
    ...baseRules("Please add email"),
    {
      type: "email",
      message: "Invalid email format",
    },
    ({ getFieldValue }) => ({
      validator: async (_, value) => {
        const locations = getFieldValue(["locations"]) as
          | StateKey[]
          | undefined;
        try {
          if (!value) {
            return Promise.resolve();
          }
          const agentInfo = await getAgentFeedInfo(value);
          if (
            locations &&
            isMissingLicense(
              filterLicensedStates(agentInfo?.licensedStates),
              locations,
            )
          ) {
            return Promise.reject(
              new Error(
                "This agent is not licensed in one or more of the selected states above.",
              ),
            );
          }
          return Promise.resolve();
        } catch (_error) {
          return Promise.reject(
            new Error(
              "Email not found in system. Confirm agent email address and try again.",
            ),
          );
        }
      },
    }),
    {
      validator: async (_, value) => {
        try {
          if (!value) {
            return Promise.resolve();
          }
          await getAgentFeedInfo(value);
          return Promise.resolve();
        } catch (_error) {
          return Promise.reject(
            new Error(
              "Email not found in system. Confirm agent email address and try again.",
            ),
          );
        }
      },
    },
  ] satisfies Rule[];
}
