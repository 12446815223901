import { useMemo } from "react";
import { useGetFormattedBrands } from "shared/hooks/brandsAccountsManagement/useGetFormattedBrands";
import { createDataListConfig } from "shared/components/dataList/utils";
import { DataListProvider } from "shared/components/dataList";
import { BRANDS_QUERY_KEY } from "shared/constants/brandsAccounts";
import { getColumnsForDataFeed } from "./Columns";
import { BrandsAccountsList } from "./BrandsAccountsList";
import { BrandsAccountsProvider } from "./contexts/BrandsAccountsContext";
import styles from "./BrandsAccounts.module.scss";

const BrandsAccounts = () => {
  const { brands, isLoading } = useGetFormattedBrands();

  const dataListConfig = useMemo(() => {
    const columns = getColumnsForDataFeed();

    return createDataListConfig(
      columns,
      {
        queryKey: [BRANDS_QUERY_KEY],
        enabled: !!brands && !isLoading,
        queryFn: async () => brands,
        cacheTime: 0,
      },
      undefined,
    );
  }, [brands, isLoading]);

  return (
    <div className={styles.container}>
      <BrandsAccountsProvider>
        <DataListProvider config={dataListConfig}>
          <BrandsAccountsList />
        </DataListProvider>
      </BrandsAccountsProvider>
    </div>
  );
};

export default BrandsAccounts;
