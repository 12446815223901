import { Col, Row } from "antd";
import { useState } from "react";
import {
  useFetchDashboardAvailableModelsNames,
  useFetchDashboards,
} from "shared/hooks/dashboardManagement/useFetchDashboards";
import DashboardDrawer from "./dashboardManagement/DashboardDrawer";
import DashboardTable from "./dashboardManagement/DashboardTable";
import { IDashboard } from "shared/types/dashboardManagement";
import { useFetchOems } from "shared/hooks/useFetchOems";
import { useFetchSpecialCampaigns } from "shared/hooks/useFetchSpecialCampaigns";

const DashboardManagement = () => {
  const { oems } = useFetchOems();
  const { data: specialCampaigns } = useFetchSpecialCampaigns();
  const { dashboards = [], isLoading } = useFetchDashboards();

  const [editDashboardData, setEditDashboardData] = useState<IDashboard>({});
  const [showDashboardDrawer, toggleDashboardDrawer] = useState<boolean>(false);
  const defaultModels = [
    "alexia_reporting",
    "biohaven",
    "ecommerce",
    "facebook_ads",
    "facebook_product_catalog",
    "facebook_spend",
    "google_adwords",
    "google_analytics",
    "google_sheets",
    "lacarguy",
    "shopify",
    "spend_reports",
    "vin_data",
  ];

  const { data: allDashboardModels } = useFetchDashboardAvailableModelsNames();

  return (
    <>
      <Row>
        <Col span={24}>
          <DashboardTable
            toggleDashboardDrawer={toggleDashboardDrawer}
            dashboards={dashboards}
            isLoading={isLoading}
            setEditDashboardData={setEditDashboardData}
          />
        </Col>
      </Row>
      <DashboardDrawer
        showDashboardDrawer={showDashboardDrawer}
        toggleDashboardDrawer={toggleDashboardDrawer}
        editDashboardData={editDashboardData}
        additionalModels={allDashboardModels || []}
        defaultModels={defaultModels}
        brands={oems || []}
        specialCampaigns={specialCampaigns || []}
      />
    </>
  );
};

export default DashboardManagement;
