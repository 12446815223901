import { useEffect, useCallback } from "react";
import { Drawer, message } from "antd";

import DrawerContent from "./feedVersionsDrawer/DrawerContent";
import DrawerTitle from "./feedVersionsDrawer/DrawerTitle";
import DrawerFooter from "./feedVersionsDrawer/DrawerFooter";
import useFetchFeedVersions from "shared/hooks/assetExporter/useFetchFeedVersions";
import { useDownloadCsv } from "shared/hooks/assetExporter/useDownloadCsv";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { useAdEngineActions } from "redux/assetExporter/assetExporter.slice";

const FeedVersionsDrawer = () => {
  const { selectedFeedIds, isVersionsDrawerOpen } = useAppSelector(
    state => state.assetExporter.sourceFeed,
  );
  const actions = useAdEngineActions();
  const feedId = selectedFeedIds[0];
  const isDrawerOpen = isVersionsDrawerOpen;

  const {
    data: feedVersions,
    status,
    isError: isErrorFetch,
  } = useFetchFeedVersions(feedId, isDrawerOpen);

  const {
    mutate: downloadCsv,
    isLoading: isDownloading,
    isError: isDownloadError,
  } = useDownloadCsv();

  const onDrawerClose = () => {
    actions.setSelectedFeedIds([]);
    actions.setIsVersionsDrawerOpen(false);
  };

  const showMessage = useCallback(() => {
    if (status === "loading") {
      message.info(
        "Recently started process takes a couple of minutes to show...",
      );

      return;
    }

    if (isDownloading) {
      message.info("Downloading CSV...");

      return;
    }

    if (isDownloadError || isErrorFetch) {
      message.error(
        `An error occurred while ${
          isErrorFetch ? "downloading the CSV" : "fetching records"
        }.`,
        3,
      );

      return;
    }

    message.destroy();
  }, [status, isDownloading, isDownloadError, isErrorFetch]);

  useEffect(showMessage, [showMessage]);

  return (
    <Drawer
      data-cy="versions-drawer"
      closable={false}
      destroyOnClose={true}
      className={"drawer"}
      title={<DrawerTitle />}
      width={840}
      onClose={onDrawerClose}
      visible={isVersionsDrawerOpen}
      footer={<DrawerFooter onClose={onDrawerClose} />}
    >
      <DrawerContent
        feedId={feedId}
        feedVersions={feedVersions ?? []}
        onDownload={downloadCsv}
        downloading={isDownloading}
      />
    </Drawer>
  );
};

export default FeedVersionsDrawer;
