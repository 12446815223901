import { useCallback } from "react";
import { TConditionType } from "shared/types/assetExporter";
import { useAssetBatchesContext } from "../contexts/AssetBatchesContext";
import { getUpdatedRuleConditions } from "../contexts/AssetBatchesContext.utils";

type TRuleCondition = {
  index: number;
  operator: "and" | "or";
  columnName: string;
  comparisonOperator: TConditionType;
  value: string;
};

const getEmptyRuleCondition = (index: number) => {
  return {
    index,
    operator: "and",
    columnName: "",
    comparisonOperator: "Equals",
    value: "",
  } as TRuleCondition;
};

export const useAssetBatchesRules = () => {
  const {
    setPreviewCount,
    ruleConditions,
    updateMaxStep,
    currentStep,
    setRuleConditions,
  } = useAssetBatchesContext();
  const indexes = ruleConditions.map(cond => {
    return cond.index;
  });
  const maxIndex = indexes.length > 0 ? Math.max(...indexes) : -1;

  const savePreviewCount = useCallback(
    (newPreviewCount: number) => {
      setPreviewCount(newPreviewCount);
    },
    [setPreviewCount],
  );

  const resetRuleCondition = useCallback(() => {
    updateMaxStep(currentStep);
    setRuleConditions([getEmptyRuleCondition(0)]);
  }, [currentStep, updateMaxStep, setRuleConditions]);

  const updateRuleCondition = useCallback(
    (index: number, updatedElement: TRuleCondition) => {
      updateMaxStep(currentStep);
      const filteredRuleCond = ruleConditions.filter(
        ruleCond => ruleCond.index !== index,
      );
      setRuleConditions(
        [...filteredRuleCond, updatedElement].sort(
          (a: TRuleCondition, b: TRuleCondition) => {
            return a.index - b.index;
          },
        ),
      );
    },
    [currentStep, ruleConditions, updateMaxStep, setRuleConditions],
  );

  const removeRuleCondition = useCallback(
    (index: number) => {
      updateMaxStep(currentStep);
      const filteredRuleCond = ruleConditions.filter(
        ruleCond => ruleCond.index !== index,
      );

      setRuleConditions(getUpdatedRuleConditions(filteredRuleCond));
    },
    [currentStep, ruleConditions, updateMaxStep, setRuleConditions],
  );

  const addEmptyRuleCondition = useCallback(() => {
    updateMaxStep(currentStep);
    setRuleConditions([...ruleConditions, getEmptyRuleCondition(maxIndex + 1)]);
  }, [currentStep, maxIndex, ruleConditions, updateMaxStep, setRuleConditions]);

  return {
    savePreviewCount,
    resetRuleCondition,
    updateRuleCondition,
    removeRuleCondition,
    addEmptyRuleCondition,
  };
};
