import { useQuery, UseQueryOptions } from "react-query";
import API from "services";
import { FeedCron } from "shared/types/assetExporter";

const getFeeds = async () => {
  try {
    const { result, error } = await API.services.assetExporter.getFeedCrons();

    if (error || !result) {
      throw new Error(error?.message);
    }
    return result.feedCrons;
  } catch (error) {
    return [];
  }
};

export const useFetchFeeds = (
  callbacks?: UseQueryOptions<FeedCron[], Error>,
) => {
  return useQuery<FeedCron[], Error>("feedCrons", getFeeds, callbacks);
};
