import { Key } from "react";
import { Menu, message } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { usePMaxDataContext } from "shared/components/contextAPI/pmaxAssetGroup/PMaxDataProvider";
import { ToolbarButton } from "shared/components/toolbarTable/ToolbarTable";
import { generateEmptyAssetGroup } from "screens/adLibrary/pmaxAssetGroups/PmaxAssetGroupList.utils";
import { usePmaxCampaignsContext } from "screens/campaignManagement/googlePerformanceMax/PmaxCampaignsProvider";
import styles from "../SelectedAssetGroupsTable.module.scss";
import { raise } from "utils/errorMessage";

type UseSelectedAssetGroupsTableToolbarProps = {
  rowSelection: Key[];
  setRowSelection: (rowSelection: Key[]) => void;
  openAssetGroupDrawer: () => void;
};

export const useSelectedAssetGroupsTableToolbar = ({
  rowSelection,
  setRowSelection,
  openAssetGroupDrawer,
}: UseSelectedAssetGroupsTableToolbarProps) => {
  const { setEditAssetGroup, setSelectedRowIds, dataSource } =
    usePMaxDataContext();
  const { campaignDrawer, setCampaignDrawer } = usePmaxCampaignsContext();
  const toolbarContents: ToolbarButton = {
    New: {
      extraInfo: {
        text: "New Asset Group",
        dropdownMenu: (
          <Menu>
            <Menu.Item onClick={openAssetGroupDrawer}>
              Select from existing
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                setSelectedRowIds([]);
                setEditAssetGroup(generateEmptyAssetGroup(), "create");
              }}
            >
              Create manually
            </Menu.Item>
            <Menu.Item onClick={() => message.info("Not implemented yet")}>
              Upload from Feed
            </Menu.Item>
          </Menu>
        ),
      },
    },
    Edit: {
      disabled: !rowSelection.length || rowSelection.length > 1,
      onClick: () => {
        const hasSelectedRows = rowSelection.length > 0;
        if (!hasSelectedRows) return;

        const assetGroup =
          dataSource.find(data => data.id === rowSelection[0]) ??
          raise("Asset group not found");

        setEditAssetGroup(assetGroup, "update");
      },
    },
    Delete: {
      disabled: !rowSelection.length,
      extraInfo: {
        icon: <MinusCircleOutlined className={styles.removeIcon} />,
        tooltip: "Remove",
      },
      onClick: () => {
        setCampaignDrawer({
          assetGroupIds: campaignDrawer.assetGroupIds.filter(
            id => !rowSelection.includes(id),
          ),
        });
        setRowSelection([]);
      },
    },
  };
  return { toolbarContents };
};
