import { memo } from "react";
import GenericBrandSelect from "shared/components/select/GenericBrandSelect";

interface IProps {
  selectedBrands?: string[];
  defaultValue?: string[];
  onChange?(selectedBrands: string[]): void;
  onFocus?: React.FocusEventHandler<HTMLElement>;
  disabled?: boolean;
  style?: React.CSSProperties;
}

function MultipleBrandSelect({
  selectedBrands,
  defaultValue,
  onChange,
  onFocus,
  disabled,
  style,
}: IProps) {
  return (
    <GenericBrandSelect
      style={style}
      value={selectedBrands}
      defaultValue={defaultValue}
      placeholder={"Select Brands"}
      onFocus={onFocus}
      onChange={onChange}
      disabled={disabled}
      mode="multiple"
    />
  );
}

export default memo(MultipleBrandSelect);
