import { UseMutateAsyncFunction, useMutation } from "react-query";
import {
  returnAdToLoadStatus,
  returnDataNotFoundObj,
} from "screens/adLibrary/adLoad/adLoadDrawer/loadStep/helpers.load";
import Page from "screens/adLibrary/facebookUtils/page";
import { AdLoadAdStatusEnum, IAdToLoadData } from "shared/types/adLibrary";
import { IAccount } from "shared/types/accountManagement";
import {
  ICreateOrUpdateResponse,
  IFacebookErrorObject,
} from "screens/adLibrary/facebookUtils/types";
import AdAccount from "screens/adLibrary/facebookUtils/adAccount";

type CreateMediaResponse = {
  id: string;
};

type CreateMediaArgs = {
  accountId: string;
  pageId: string;
  url: string;
  mediaType: "photo" | "video";
};

type UseCreateMediaArgs = CreateMediaArgs;

export const useCreatePageMedia = () =>
  useMutation((args: UseCreateMediaArgs) => {
    const facebookPage = new Page(args.pageId);
    return facebookPage.createMemoizedPageMedia({
      url: args.url,
      mediaType: args.mediaType,
    });
  });

export const useCreateAccountMedia = () =>
  useMutation((args: UseCreateMediaArgs) => {
    if (args.mediaType === "video") {
      const adAccount = new AdAccount(args.accountId);

      return adAccount.createMemoizedAdVideo({
        file_url: args.url,
      });
    }

    return Promise.resolve({ id: "" });
  });

type CancellableCreateMedia = UseMutateAsyncFunction<
  CreateMediaResponse,
  unknown,
  UseCreateMediaArgs,
  unknown
>;

type UploadFacebookAdMediaArgs = {
  createMedia: CancellableCreateMedia;
  selectedDealer?: IAccount;
  adToLoad: IAdToLoadData;
};

const uploadFacebookAdMedia = async (args: UploadFacebookAdMediaArgs) => {
  const accountId = args.selectedDealer?.details.facebook.fbAccountId as string;
  const pageId = args.selectedDealer?.details?.facebook?.fbPageId;

  if (!pageId) {
    return returnDataNotFoundObj(args.adToLoad, {
      message: "Missing Page ID",
      error_user_title: "Missing Page ID",
      error_user_msg: `No Facebook page ID was found for the store: ${args.selectedDealer?.dealer_name}`,
    });
  }

  const { ad } = args.adToLoad;

  const createMediaRes = await returnCreateMediaRes({
    accountId,
    pageId,
    assetId: ad.visuals.assetId,
    videoUrl: ad.visuals.videoUrl,
    createMedia: args.createMedia,
  });

  const createMediaForCardsRes = await Promise.all(
    (ad.visuals.cards ?? []).map(async card => {
      if (card.assetId) return card.assetId;
      const createMediaArgs: CreateMediaArgs = {
        accountId: accountId,
        pageId: pageId,
        url: card.videoUrl || "",
        mediaType: "video",
      };
      try {
        const createVideoRes = card.videoUrl
          ? await args.createMedia(createMediaArgs)
          : undefined;
        return createVideoRes?.id || "";
      } catch (_) {
        return "";
      }
    }),
  );
  return returnAdLoadDataAfterUpload({
    createMediaRes,
    createMediaForCardsRes,
    adToLoad: args.adToLoad,
  });
};

type ReturnAdLoadDataAfterUploadArgs = {
  adToLoad: IAdToLoadData;
  createMediaForCardsRes?: string[];
  createMediaRes?: ICreateOrUpdateResponse;
};

type ReturnCreateMediaResArgs = {
  accountId: string;
  pageId: string;
  assetId?: string;
  videoUrl?: string;
  createMedia: CancellableCreateMedia;
};

const returnCreateMediaRes = async (
  args: ReturnCreateMediaResArgs,
): Promise<ICreateOrUpdateResponse | undefined> => {
  if (!args.videoUrl) return;

  if (args.assetId) {
    return { result: { id: args.assetId }, error: null };
  }

  try {
    const createMediaArgs: CreateMediaArgs = {
      accountId: args.accountId,
      pageId: args.pageId,
      url: args.videoUrl,
      mediaType: "video",
    };

    const createVideoRes = await args.createMedia(createMediaArgs);

    return { result: createVideoRes, error: null };
  } catch (error) {
    return { result: null, error: error as IFacebookErrorObject };
  }
};

const returnAdLoadDataAfterUpload = (
  args: ReturnAdLoadDataAfterUploadArgs,
) => ({
  ...args.adToLoad,
  ad: {
    ...args.adToLoad.ad,
    visuals: {
      ...args.adToLoad.ad.visuals,
      assetId: args.createMediaRes?.result?.id,
      cards: args.adToLoad.ad.visuals.cards?.map((card, index) => ({
        ...card,
        assetId: args.createMediaForCardsRes?.[index],
      })),
    },
  },
  adLoadStatus: args.createMediaRes
    ? returnAdToLoadStatus({
        adToLoad: args.adToLoad,
        newAdLoadStatus: "loading",
        response: args.createMediaRes,
      })
    : args.adToLoad.adLoadStatus,
  progressStatus: {
    ...args.adToLoad.progressStatus,
    uploadMedia: args.createMediaRes
      ? returnAdToLoadStatus({
          adToLoad: args.adToLoad,
          newAdLoadStatus: AdLoadAdStatusEnum.SUCCESS,
          response: args.createMediaRes,
        }).status
      : AdLoadAdStatusEnum.SUCCESS,
  },
});

export const useUploadFacebookAdMedia = () =>
  useMutation(uploadFacebookAdMedia);
