import { createContext, SetStateAction, useContext, ReactNode } from "react";
import { DataNode } from "antd/lib/tree";
import { useAdsTreeProps } from "./useAdsTreeProps";
import { useCurrentAdsToLoad } from "../currentAdsToLoadContext";

type AdReviewTreeProps = {
  treeData: DataNode[];
  checkedKeys: React.Key[];
  setCheckedKeys: (checkedKeys: SetStateAction<React.Key[]>) => void;
};

const AdsTreeContext = createContext<AdReviewTreeProps | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export const AdsTreeProvider = ({ children }: Props) => {
  const { currentAdsToLoad } = useCurrentAdsToLoad();

  const adsTreeData = useAdsTreeProps(currentAdsToLoad);
  return (
    <AdsTreeContext.Provider value={adsTreeData}>
      {children}
    </AdsTreeContext.Provider>
  );
};

export const useAdsTreeData = () => {
  const adsTreeContext = useContext(AdsTreeContext);
  if (!adsTreeContext) {
    throw new Error("useAdsTreeData must be used within an AdsTreeProvider");
  }
  return adsTreeContext;
};
