import API from "services";
import { QueryClient, useMutation, useQueryClient } from "react-query";
import {
  ApiResponseCampaignPlanner,
  ApiResponseCampaignPlannerInstance,
  CampaignPlanner,
  CampaignPlannerInstance,
} from "screens/campaignManagement/campaignPlanner/types";

import produce from "immer";
import { queryKeys } from "./queryKeys";
import { optimisticUpdate } from "./utils";
import { ReactQueryContext } from "./types";
import { unionBy } from "lodash";
import { UpdatePlannerInstanceAdParams } from "shared/types/campaignPlanner";

const onMutationError = (
  queryClient: QueryClient,
  context?: ReactQueryContext,
) => {
  queryClient.setQueryData(
    queryKeys.campaignPlanners,
    context?.previousCampaigns,
  );
};

type CreateEditCampaignPlannerInstanceRequest = {
  plannerId: string;
  instance: CampaignPlannerInstance;
  includeAdShells?: boolean;
};

type DeleteCampaignPlannerInstancesRequest = Record<string, string[]>;

const createCampaignPlannerInstance = async ({
  plannerId,
  instance,
}: CreateEditCampaignPlannerInstanceRequest) => {
  const { result, error } =
    await API.privServices.campaignPlanner.createCampaignPlannerInstance(
      plannerId,
      {
        ...instance,
        adShellIds: instance.adShells.map(ad => ad.id),
        plannerId,
      },
    );

  if (!result) {
    throw Error(
      error?.message ||
        "Something went wrong while creating campaign planners.",
    );
  }

  return result;
};

export const useCreateCampaignPlannerInstance = () => {
  const queryClient = useQueryClient();

  return useMutation<
    ApiResponseCampaignPlannerInstance,
    Error,
    CreateEditCampaignPlannerInstanceRequest,
    ReactQueryContext
  >(createCampaignPlannerInstance, {
    onMutate: async ({ plannerId, instance }) => {
      await queryClient.cancelQueries({
        queryKey: queryKeys.campaignPlanners,
      });

      const previousPlanners: ApiResponseCampaignPlanner[] =
        queryClient.getQueryData(queryKeys.campaignPlanners) ?? [];

      const newPlanner = previousPlanners.find(
        planner => planner.id == plannerId,
      );
      if (!newPlanner) return;

      const updatedPlanner = produce((planner: ApiResponseCampaignPlanner) => {
        planner.instances = [
          {
            ...instance,
            adShellIds: instance.adShells.map(ad => ad.id),
            plannerId,
          },
          ...newPlanner.instances,
        ];
      })(newPlanner);

      optimisticUpdate(queryClient, updatedPlanner);
      return { previousCampaigns: previousPlanners };
    },
    onError: (err, newCampaign, context) => {
      onMutationError(queryClient, context);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.campaignPlanners);
    },
  });
};

const editCampaignPlannerInstance = async ({
  plannerId,
  instance,
  includeAdShells,
}: CreateEditCampaignPlannerInstanceRequest) => {
  const apiInstance = {
    ...instance,
    plannerId,
    adShellIds: instance.adShells.map(ad => ad.id),
    adShells: undefined,
  };
  const { error } =
    await API.privServices.campaignPlanner.editCampaignPlannerInstance(
      plannerId,
      apiInstance,
      !!includeAdShells,
    );

  if (error) {
    throw Error(
      error?.message ||
        "Something went wrong while creating campaign planners.",
    );
  }
};

export const useEditCampaignPlannerInstance = () => {
  const queryClient = useQueryClient();

  return useMutation<
    void,
    Error,
    CreateEditCampaignPlannerInstanceRequest,
    ReactQueryContext
  >(editCampaignPlannerInstance, {
    onMutate: async ({ plannerId, instance }) => {
      await queryClient.cancelQueries({
        queryKey: queryKeys.campaignPlanners,
      });

      const previousPlanners: ApiResponseCampaignPlanner[] =
        queryClient.getQueryData(queryKeys.campaignPlanners) ?? [];

      const newPlanner = previousPlanners.find(
        planner => planner.id == plannerId,
      );
      if (!newPlanner) return;

      const updatedPlanner = produce((planner: ApiResponseCampaignPlanner) => {
        planner.instances = unionBy(
          [
            {
              ...instance,
              adShellIds: instance.adShells.map(ad => ad.id),
              plannerId,
            },
          ],
          planner.instances,
          "id",
        );
      })(newPlanner);

      optimisticUpdate(queryClient, updatedPlanner);
      return { previousCampaigns: previousPlanners };
    },
    onError: (err, newCampaign, context) => {
      onMutationError(queryClient, context);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.campaignPlanners);
    },
  });
};

const deleteCampaignPlannerInstances = async (
  instances: DeleteCampaignPlannerInstancesRequest,
) => {
  const { result, error } =
    await API.privServices.campaignPlanner.deleteCampaignPlannerInstances(
      instances,
    );

  if (!result) {
    throw Error(
      error?.message ||
        "Something went wrong while creating campaign planners.",
    );
  }

  return result;
};

export const useDeleteCampaignPlannerInstances = () => {
  const queryClient = useQueryClient();

  return useMutation<string, Error, DeleteCampaignPlannerInstancesRequest, any>(
    deleteCampaignPlannerInstances,
    {
      onMutate: async instances => {
        await queryClient.cancelQueries({
          queryKey: queryKeys.campaignPlanners,
        });

        const previousPlanners: CampaignPlanner[] =
          queryClient.getQueryData(queryKeys.campaignPlanners) ?? [];

        const newPlanners = previousPlanners.map(planner => ({
          ...planner,
          instances: planner.instances.filter(instance =>
            instances[planner.id]?.includes(instance.id),
          ),
        }));

        queryClient.setQueryData([queryKeys.campaignPlanners], newPlanners);

        return { previousCampaigns: previousPlanners };
      },
      onError: (err, newCampaign, context) => {
        onMutationError(queryClient, context);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.campaignPlanners);
      },
    },
  );
};

const editCampaignPlannerInstanceAd = async (
  params: UpdatePlannerInstanceAdParams,
) => {
  const { result, error } =
    await API.privServices.campaignPlanner.editCampaignPlannerInstanceAd(
      params,
    );

  if (!result) {
    throw Error(
      error?.message ||
        "Something went wrong while creating campaign planners.",
    );
  }

  return result;
};

export const useEditCampaignPlannerInstanceAd = () => {
  const queryClient = useQueryClient();

  return useMutation<
    CampaignPlannerInstance,
    Error,
    UpdatePlannerInstanceAdParams,
    ReactQueryContext
  >(editCampaignPlannerInstanceAd, {
    onMutate: async ({ plannerId, instanceId, adId, campaignStatus }) => {
      await queryClient.cancelQueries({
        queryKey: queryKeys.campaignPlanners,
      });

      const previousPlanners: ApiResponseCampaignPlanner[] =
        queryClient.getQueryData(queryKeys.campaignPlanners) ?? [];

      const newPlanner = previousPlanners.find(
        planner => planner.id == plannerId,
      );

      if (!newPlanner) return;

      const updatedPlanner = produce((planner: ApiResponseCampaignPlanner) => {
        const newInstances = planner.instances.map(instance => {
          if (instance.id == instanceId) {
            return {
              ...instance,
              adStatuses: {
                ...instance.adStatuses,
                [adId]: campaignStatus,
              },
            };
          }
          return instance;
        });

        planner.instances = newInstances;
      })(newPlanner);

      optimisticUpdate(queryClient, updatedPlanner);
      return { previousCampaigns: previousPlanners };
    },
    onError: (err, newCampaign, context) => {
      onMutationError(queryClient, context);
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.campaignPlanners);
    },
  });
};
