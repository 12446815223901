import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useMemo, useState } from "react";
import { fbLogin, removePermissions } from "utils/facebook/facebookSDK";
import { ReactComponent as MetaLogo } from "../../statics/images/meta-logo.svg";
import IntegrationCard from "./IntegrationCard";
import { useCreatePlatformToken } from "./shared/hooks/useCreatePlatformToken";
import { useDeletePlatformToken } from "./shared/hooks/useDeletePlatformToken";
import { usePlatformTokenStatus } from "./shared/hooks/usePlatformTokenStatus";
import { PlatformHookParams } from "./shared/types";
import { handleError } from "./shared/utils";

const { confirm } = Modal;

const FacebookIntegrationCard = () => {
  const [isLoadingOnFacebook, setIsLoadingOnFacebook] = useState(false);
  const platformHookParams: PlatformHookParams = {
    platform: "facebook",
    onError: handleError,
  };
  const { data: platformTokenStatus, isLoading: isLoadingPlatformTokenStatus } =
    usePlatformTokenStatus(platformHookParams);
  const { mutate: deletePlatformToken, isLoading: isDeleting } =
    useDeletePlatformToken(platformHookParams);
  const { mutate: createPlatformToken, isLoading: isCreating } =
    useCreatePlatformToken(platformHookParams);

  const isConnected = useMemo(
    () => platformTokenStatus === "active",
    [platformTokenStatus],
  );

  const isLoading = useMemo(
    () =>
      isLoadingPlatformTokenStatus ||
      isDeleting ||
      isCreating ||
      isLoadingOnFacebook,
    [isCreating, isDeleting, isLoadingPlatformTokenStatus, isLoadingOnFacebook],
  );

  const onConnect = () => {
    setIsLoadingOnFacebook(true);

    fbLogin(async shortTermToken => {
      setIsLoadingOnFacebook(false);

      if (shortTermToken) {
        createPlatformToken({
          platform: "facebook",
          shortTermToken: shortTermToken,
        });
      }
    });
  };

  const onDisconnect = () => {
    confirm({
      title: "Are you sure you want to remove this Authentication?",
      icon: <ExclamationCircleOutlined style={{ color: "#FF4D4F" }} />,
      content:
        "Removing this authentication will prevent you from loading assets into this Ad Account.",
      onOk: () => {
        setIsLoadingOnFacebook(true);

        removePermissions(success => {
          setIsLoadingOnFacebook(false);

          if (success) {
            deletePlatformToken("facebook");
          }
        });
      },
    });
  };

  return (
    <IntegrationCard
      name="Meta"
      description="Manage ad accounts, Pages, and the people who work on them."
      isConnected={isConnected}
      isLoading={isLoading}
      logo={<MetaLogo />}
      onConnect={onConnect}
      onDisconnect={onDisconnect}
    />
  );
};

export default FacebookIntegrationCard;
