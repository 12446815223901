import Search from "./assets/Search";
import styles from "./Assets.module.scss";
import IEList from "./assets/IEList";
import { Collapse, CollapsePanelProps } from "antd";
import IECollapseHeader from "./shared/IECollapseHeader";
import MarketingTileList from "./assets/MarketingTileList";
import DestinationUrlLabelList from "./assets/DestinationUrlLabelList";

export type IEAssetsElementType =
  | "IE"
  | "MarketingTile"
  | "DestinationUrlLabel";
const IEAssetsElement: Record<IEAssetsElementType, string> = {
  IE: "Everything Ads",
  MarketingTile: "Marketing Tiles",
  DestinationUrlLabel: "Destination URL/Label",
};
const Layers = () => {
  const collapsePanelProps: Record<IEAssetsElementType, CollapsePanelProps> = {
    DestinationUrlLabel: {
      key: IEAssetsElement.DestinationUrlLabel,
      header: (
        <IECollapseHeader
          title={IEAssetsElement.DestinationUrlLabel}
          type="DestinationUrlLabel"
        />
      ),
    },
    IE: {
      key: IEAssetsElement.IE,
      header: <IECollapseHeader title={IEAssetsElement.IE} type="IE" />,
    },
    MarketingTile: {
      key: IEAssetsElement.MarketingTile,
      header: (
        <IECollapseHeader
          title={IEAssetsElement.MarketingTile}
          type="MarketingTile"
        />
      ),
    },
  };
  return (
    <div className={styles.assets}>
      <Search />
      <div className={styles.assetsContent}>
        <Collapse ghost>
          <Collapse.Panel {...collapsePanelProps.DestinationUrlLabel}>
            <DestinationUrlLabelList />
          </Collapse.Panel>

          <Collapse.Panel {...collapsePanelProps.IE}>
            <IEList />
          </Collapse.Panel>

          <Collapse.Panel {...collapsePanelProps.MarketingTile}>
            <MarketingTileList />
          </Collapse.Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default Layers;
