import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InitiatingShareLink, SharedProps } from "shared/types/shared";

const initialState: SharedProps = {};

const sharedSlice = createSlice({
  name: "shared",
  initialState,
  reducers: {
    onInitiatingShareLink: {
      prepare(module: string, at: number) {
        return { payload: { module, at } };
      },
      reducer(state, action: PayloadAction<{ module: string; at: number }>) {
        return {
          ...state,
          initiatingShareLink: action.payload as InitiatingShareLink,
        };
      },
    },
    onShareLinkUpdate(state, action: PayloadAction<{ url: string }>) {
      return {
        ...state,
        shareLinkUrl: action.payload.url as string,
      };
    },
  },
});

export const { onInitiatingShareLink, onShareLinkUpdate } = sharedSlice.actions;

export default sharedSlice.reducer;
