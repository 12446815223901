import { DataListTagsURL } from "shared/components/dataListURL/DataListTagsURL";
import DataListURLTable from "shared/components/dataListURL/DataListURLTable";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { usePmaxCampaignsColumns } from "./pmaxCampaignsTable/usePmaxCampaignsColumns";
import { usePmaxCampaignsToolbar } from "./pmaxCampaignsTable/usePmaxCampaignsToolbar";
import ToolbarTable from "shared/components/toolbarTable/ToolbarTable";
import { TableEmptyMessage } from "shared/components/TableEmptyMessage";
import { GoogleAdCampaignInternal } from "shared/types/adLibrary";
import {
  PmaxCampaignsFields,
  pmaxCampaignsFieldsKeys,
} from "./pmaxCampaignsTable/fields";
import { usePmaxCampaignsContext } from "./PmaxCampaignsProvider";
import { NewCampaignDrawer } from "./pmaxCampaignsTable/NewCampaignDrawer";
import PMaxDataProvider from "shared/components/contextAPI/pmaxAssetGroup/PMaxDataProvider";
import { initialFormValues } from "./pmaxCampaignsTable/newCampaignDrawer/newCampaignForm/helpers";
import { TableActionsContextProvider } from "shared/components/HoverActionsVirtualTable/TableActionsContext";
import { ContextTableActions } from "shared/components/tableActions/ContextTableActions";
import { InlineTableActions } from "shared/components/tableActions/InlineTableActions";
import { CampaignLoadDrawer } from "./pmaxCampaignsTable/CampaignLoadDrawer";
import { usePmaxCampaignsGlobalFilter } from "screens/adLibrary/shared/hooks/dataListHooks";

export const PmaxCampaignsTable = () => {
  const { windowInnerHeight } = useWindowSize();
  const { toolbarContents, generateToolbarContents } =
    usePmaxCampaignsToolbar();
  const { setCampaignDrawer, campaigns, isLoading, filteredCampaigns } =
    usePmaxCampaignsContext();

  const { globalFilter, setGlobalFilter } = usePmaxCampaignsGlobalFilter();
  const { columns } = usePmaxCampaignsColumns();
  return (
    <>
      <ToolbarTable
        layout="table"
        toolbarContents={toolbarContents}
        searchPlaceholder="Search"
        searchValue={globalFilter ?? ""}
        onSearch={setGlobalFilter}
        titleTooltip="Search"
      />
      <DataListTagsURL<PmaxCampaignsFields, GoogleAdCampaignInternal>
        data={filteredCampaigns}
        originalData={campaigns}
      />

      <TableActionsContextProvider
        renderContextActions={(record?: GoogleAdCampaignInternal) => (
          <ContextTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
        renderHoverActions={(record?: GoogleAdCampaignInternal) => (
          <InlineTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
      >
        <DataListURLTable<PmaxCampaignsFields, GoogleAdCampaignInternal>
          loading={isLoading}
          dataSource={filteredCampaigns}
          columns={columns}
          pagination={false}
          size="small"
          scroll={{ y: windowInnerHeight - 245 }}
          type="hoverActionsVirtualTable"
          fieldKeys={pmaxCampaignsFieldsKeys}
          locale={{
            emptyText: (
              <TableEmptyMessage
                message="No campaigns yet"
                action={() =>
                  setCampaignDrawer({
                    open: true,
                    data: initialFormValues,
                    assetGroupIds: [],
                  })
                }
                actionText="New campaign"
              />
            ),
          }}
        />
      </TableActionsContextProvider>
      <PMaxDataProvider>
        <NewCampaignDrawer />
        <CampaignLoadDrawer />
      </PMaxDataProvider>
    </>
  );
};
