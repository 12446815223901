import { useV3Query } from "./useV3Query";
import { AccountFragment } from "../../../graphql/v3.gen";

export const useV3Accounts = (clientId?: string) => {
  const { data, loading, error } = useV3Query<{ accounts: AccountFragment[] }>({
    variables: {
      input: {
        query: "v2GetAccountsByClient",
        variables: {
          clientId,
        },
      },
    },
    skip: !clientId,
  });

  const accounts = data?.v2AccessQueryExt?.accounts;

  return {
    loading,
    error,
    accounts,
  };
};
