import { useMutation } from "react-query";
import API from "services";
import { FeedCron } from "shared/types/assetExporter";
import { getErrorMessage } from "utils/errorMessage";

const pullFeed = async (feedCron: FeedCron) => {
  try {
    const { pullLatestFeedData } = API.services.assetExporter;
    const { error } = await pullLatestFeedData(feedCron, true);

    if (error) {
      throw new Error(error.message);
    }
  } catch (error) {
    const errMsg = getErrorMessage(error);
    throw new Error(errMsg);
  }
};
const usePullFeed = () => {
  return useMutation<void, Error, FeedCron>(feedCron => pullFeed(feedCron));
};

export default usePullFeed;
