import { EditFilled, FlagFilled } from "@ant-design/icons";
import { DatePicker, Form } from "antd";
import moment from "moment";
import React, { FC, useState } from "react";
import { dateFormat, yyyymmddFormat } from "shared/constants/dataManagement";
import { IOfferDataInputProps } from "shared/types/assetBuilder";
import { returnEpochTimeString, timezoneOffset } from "utils/helpers";

export const OfferDate: FC<
  IOfferDataInputProps & {
    period?: "year" | "month" | "date";
  }
> = ({ title, field, value, onChange, error, edited }) => {
  const dateValue =
    value &&
    (value.includes("/")
      ? value
      : new Date(parseInt(value, 10) + timezoneOffset));
  const [valueChanged, setValueChanged] = useState<boolean>(false);

  // AV2-3445: if the date is invalid, the field value should be empty
  const momentValue = dateValue ? moment(dateValue, yyyymmddFormat) : null;

  let finalValue =
    momentValue?.toString() === "Invalid date" ? null : momentValue;

  if (
    finalValue &&
    field === "expirationDate" &&
    momentValue?.toString() === "Invalid date"
  ) {
    finalValue = moment(dateValue, dateFormat);
  }

  return (
    <Form.Item
      help={error}
      label={
        <span>
          {title}{" "}
          {edited && (
            <FlagFilled
              title="The saved value has been updated."
              style={{ color: "orange" }}
            />
          )}{" "}
          {valueChanged && (
            <EditFilled
              title="Changes were made to this field"
              style={{ color: "#1890ff" }}
            />
          )}
        </span>
      }
    >
      <div onClick={e => e.stopPropagation()}>
        <DatePicker
          className={`value ${error ? "error" : ""}`}
          allowClear={false}
          format={dateFormat}
          value={finalValue}
          onChange={newValue => {
            if (!newValue) {
              return;
            }

            const newStringValue = newValue
              .format(field === "expirationDate" ? dateFormat : yyyymmddFormat)
              .toString();
            const epochTimeString = returnEpochTimeString(newStringValue);

            setValueChanged(true);

            return newValue !== null && onChange && onChange(epochTimeString);
          }}
        />
      </div>
    </Form.Item>
  );
};
