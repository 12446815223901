import { flatten, startCase } from "lodash";

import { compareString } from "utils/helpers";
import { defaultReviewUrlQAIssue } from "../../constants";

import {
  IAdToLoadData,
  ReviewStepKeys,
  NestedProductSetObject,
  IAdLoadReviewAndQAIssue,
  IAdLoadStatus,
  AdLoadAdStatusEnum,
} from "shared/types/adLibrary";
import { ColumnType } from "antd/lib/table";

import classNames from "classnames";
import styles from "./columns.module.scss";
import { AdType } from "screens/adLibrary/facebookUtils/types";
import { terms } from "utils/terms";

export const returnFilterOptions = (values: Array<string | undefined>) =>
  Array.from(new Set(values.filter(val => val) as string[])).map(val => ({
    text: val,
    value: val,
  }));

type IsColumnValidArgs = {
  record: IAdToLoadData;
  key: keyof AdsToLoadFiltersObject;
  ignoreStatuses?: AdLoadAdStatusEnum[];
};

const isValidColumn = (args: IsColumnValidArgs) => {
  if (["destinationUrl", "displayUrl", "ctaButtonText"].includes(args.key)) {
    const shouldReviewDestinationUrls = !!getReviewAndQAIssuesForInnerStep(
      [args.record],
      ReviewStepKeys.REVIEW_DESTINATION_URLS,
    ).length;

    const hasInvalidDestinationUrl = !!getReviewAndQAIssuesForInnerStep(
      [args.record],
      ReviewStepKeys.INVALID_DESTINATION_URL,
    ).length;

    return (
      (!args.ignoreStatuses?.includes(AdLoadAdStatusEnum.WARNING) &&
        shouldReviewDestinationUrls &&
        args.record.ad.type !== AdType.Collection &&
        args.record.ad.type !== AdType.InstantExperience) ||
      (args.key === "destinationUrl" && hasInvalidDestinationUrl)
    );
  }

  if (["rootIEName", "productCatalog"].includes(args.key)) {
    const ignoreProductCatalog =
      args.key !== "productCatalog" || !!args.record.productCatalog?.id;
    return (
      ignoreProductCatalog &&
      !args.ignoreStatuses?.includes(AdLoadAdStatusEnum.ERROR) &&
      !!getReviewAndQAIssuesForInnerStep(
        [args.record],
        ReviewStepKeys.ASSIGN_PRODUCT_SETS_IE_LEVEL,
      ).length &&
      returnAdLoadStatusFromQAIssues(args.record.adLoadStatus) ===
        AdLoadAdStatusEnum.ERROR
    );
  }
  if (args.key === "productSet") {
    return (
      !args.ignoreStatuses?.includes(AdLoadAdStatusEnum.ERROR) &&
      (!!getReviewAndQAIssuesForInnerStep(
        [args.record],
        ReviewStepKeys.CONVERT_TO_VIDEO,
      ).length ||
        !!getReviewAndQAIssuesForInnerStep(
          [args.record],
          ReviewStepKeys.ASSIGN_PRODUCT_SETS_AD_LEVEL,
        ).length ||
        !!getReviewAndQAIssuesForInnerStep(
          [args.record],
          ReviewStepKeys.ASSIGN_PRODUCT_SETS_IE_LEVEL,
        ).length) &&
      returnAdLoadStatusFromQAIssues(args.record.adLoadStatus) ===
        AdLoadAdStatusEnum.ERROR
    );
  }
  return false;
};

export type AdsToLoadFiltersObject = Record<string, ColumnType<IAdToLoadData>>;

export const returnAdsToLoadTableColumnPropsDict = (
  adsToLoad: IAdToLoadData[],
  ignoreStatuses?: AdLoadAdStatusEnum[],
): AdsToLoadFiltersObject => ({
  accountName: {
    key: "accountName",
    width: 200,
    title: terms.accountName,
    dataIndex: ["account", "name"],
    sorter: (a, b) => compareString(a.account?.name, b.account?.name),
    filters: returnFilterOptions(adsToLoad.map(ad => ad.account?.name)),
    onFilter: (value, record) => record.account?.name === value,
  },
  accountId: {
    key: "accountId",
    width: 200,
    title: "Account ID",
    dataIndex: ["account", "account_id"],
    sorter: (a, b) =>
      compareString(a.account?.account_id, b.account?.account_id),
    filters: returnFilterOptions(adsToLoad.map(ad => ad.account?.account_id)),
    onFilter: (value, record) => record.account?.id === value,
  },
  facebookPageName: {
    key: "pageName",
    width: 200,
    title: "Facebook Page Name",
    dataIndex: ["page", "name"],
    sorter: (a, b) => compareString(a.page.name, b.page.name),
    filters: returnFilterOptions(adsToLoad.map(ad => ad.page.name)),
    onFilter: (value, record) => record.page.name === value,
  },
  facebookPageId: {
    key: "pageId",
    width: 175,
    title: "Facebook Page ID",
    dataIndex: ["page", "id"],
    sorter: (a, b) => compareString(a.page.id, b.page.id),
    filters: returnFilterOptions(adsToLoad.map(ad => ad.page.id)),
    onFilter: (value, record) => record.page.id === value,
  },
  campaignName: {
    key: "campaignName",
    width: 200,
    title: "Campaign Name",
    dataIndex: ["campaign", "name"],
    sorter: (a, b) => compareString(a.campaign.name, b.campaign.name),
    filters: returnFilterOptions(
      adsToLoad.map(ad => ad.campaign.name).filter(name => name) as string[],
    ),
    onFilter: (value, record) => record.campaign.name === value,
  },
  objective: {
    width: 125,
    key: "campaignObjective",
    title: "Objective",
    dataIndex: ["campaign", "objective"],
    sorter: (a, b) => compareString(a.campaign.objective, b.campaign.objective),
    filters: returnFilterOptions(
      adsToLoad.map(ad => startCase(ad.campaign.objective || "")),
    ),
    onFilter: (value, record) => record.campaign.objective === value,
  },
  adsetName: {
    key: "adsetName",
    width: 200,
    title: "Ad Set Name",
    dataIndex: ["adset", "name"],
    sorter: (a, b) => compareString(a.adset.name, b.adset.name),
    filters: returnFilterOptions(adsToLoad.map(ad => ad.adset.name)),
    onFilter: (value, record) => record.adset.name === value,
  },
  adName: {
    key: "adName",
    title: "Ad Name",
    width: 250,
    dataIndex: ["ad", "inputParameters", "name"],
    sorter: (a, b) =>
      compareString(a.ad?.inputParameters?.name, b.ad?.inputParameters?.name),
    filters: returnFilterOptions(
      adsToLoad.map(adToLoad => adToLoad.ad?.inputParameters?.name),
    ),
    onFilter: (value, record) => record.ad?.inputParameters?.name === value,
  },
  type: {
    key: "adType",
    width: 125,
    title: "Format",
    dataIndex: ["ad", "type"],
    sorter: (a, b) => compareString(a.ad.type, b.ad.type),
    filters: returnFilterOptions(adsToLoad.map(ad => ad.ad.type)),
    onFilter: (value, record) => record.ad.type === value,
  },
  rootIEName: {
    width: 250,
    key: "rootIEName",
    title: "Instant Experience Name",
    dataIndex: ["ad", "visuals", "destination", "instantExperienceId"],
    onCell: record => ({
      className: classNames({
        [styles.errorCell]: isValidColumn({
          key: "rootIEName",
          ignoreStatuses,
          record,
        }),
      }),
    }),
  },
  destinationUrl: {
    width: 275,
    key: "destinationUrl",
    title: "Destination URL",
    dataIndex: ["ad", "inputParameters", "destinationUrl"],
    sorter: (a, b) =>
      compareString(
        a.ad?.inputParameters?.destinationUrl,
        b.ad?.inputParameters?.destinationUrl,
      ),
    filters: returnFilterOptions(
      adsToLoad.map(adToLoad => adToLoad.ad?.inputParameters?.destinationUrl),
    ),
    onFilter: (value, record) =>
      record.ad?.inputParameters?.destinationUrl === value,
    onCell: record => ({
      className: classNames({
        [styles.errorCell]: isValidColumn({
          key: "destinationUrl",
          ignoreStatuses,
          record,
        }),
      }),
    }),
  },
  displayUrl: {
    width: 275,
    key: "displayUrl",
    title: "Display URL",
    dataIndex: ["ad", "visuals", "displayUrl"],
    sorter: (a, b) =>
      compareString(a.ad.visuals.displayUrl, b.ad.visuals.displayUrl),
    filters: returnFilterOptions(adsToLoad.map(ad => ad.ad.visuals.displayUrl)),
    onFilter: (value, record) => record.ad.visuals.displayUrl === value,
    onCell: record => ({
      className: classNames({
        [styles.warningCell]: isValidColumn({
          key: "displayUrl",
          ignoreStatuses,
          record,
        }),
      }),
    }),
  },
  productCatalog: {
    key: "productCatalog",
    width: 200,
    title: "Product Catalog",
    dataIndex: ["productCatalog", "id"],
    sorter: (a, b) =>
      compareString(a.productCatalog?.name, b.productCatalog?.name),
    onCell: record => ({
      className: classNames({
        [styles.errorCell]: isValidColumn({
          key: "productCatalog",
          ignoreStatuses,
          record,
        }),
      }),
    }),
  },
  productSet: {
    key: "productSet",
    width: 200,
    title: "Product Set",
    dataIndex: ["productSet", "id"],
    sorter: (a, b) => compareString(a.productSet?.name, b.productSet?.name),
    onCell: record => ({
      className: classNames({
        [styles.errorCell]: isValidColumn({
          key: "productSet",
          ignoreStatuses,
          record,
        }),
      }),
    }),
  },
  ctaButtonText: {
    width: 275,
    key: "ctaButtonText",
    title: "CTA Button",
    dataIndex: ["ad", "visuals", "ctaButtonText"],
    onCell: record => ({
      className: classNames({
        [styles.errorCell]: isValidColumn({
          key: "ctaButtonText",
          ignoreStatuses,
          record,
        }),
      }),
    }),
  },
});

export const getReviewAndQAIssuesForInnerStep = (
  adsToLoad: IAdToLoadData[],
  innerStep: ReviewStepKeys,
): IAdLoadReviewAndQAIssue[] =>
  flatten(
    adsToLoad.map(
      adToLoad =>
        adToLoad.adLoadStatus.reviewAndQAIssues?.filter(
          issue => issue.requiredStep === innerStep,
        ) ?? [],
    ),
  );

export const getInnerStepReviewMessageText = (
  numberOfAds: number,
  innerStep: ReviewStepKeys,
): string => {
  const adsText = `Ad${numberOfAds !== 1 ? "s" : ""}`;
  const needText = `need${numberOfAds !== 1 ? "" : "s"}`;
  const hasText = `${numberOfAds !== 1 ? "have" : "has"}`;
  const titles = {
    ASSIGN_PRODUCT_SETS_IE_LEVEL: `${numberOfAds} ${adsText} with Instant Experiences ${needText} Product Sets for each nested Instant Experience`,
    ASSIGN_PRODUCT_SETS_AD_LEVEL: `${numberOfAds} ${adsText} ${needText} product sets`,
    REVIEW_DESTINATION_URLS: `Destination URLs have not been reviewed`,
    CONVERT_TO_VIDEO: `${numberOfAds} Collection ${adsText} ${hasText} less than 4 Products`,
    LINK_INSTANT_EXPERIENCE: `No Instant Experience has been linked`,
    INVALID_DESTINATION_URL: `${numberOfAds} ${adsText} ${hasText} invalid destination URLs`,
  };
  return titles[innerStep];
};

export const returnAdToLoadWithoutReviewQAIssuesForStep = (
  adToLoad: IAdToLoadData,
  adReviewStep: ReviewStepKeys,
): IAdToLoadData => ({
  ...adToLoad,
  adLoadStatus: {
    ...adToLoad.adLoadStatus,
    status:
      adReviewStep === ReviewStepKeys.REVIEW_DESTINATION_URLS
        ? "draft"
        : adToLoad.adLoadStatus.status,
    reviewAndQAIssues: adToLoad.adLoadStatus.reviewAndQAIssues?.filter(
      issue => issue.requiredStep !== adReviewStep,
    ),
  },
});

type AdsToLoadAfterNestedProductSetChangeArgs = {
  adToLoadKey: string;
  instantExperienceID: string;
  currentAdsToLoad: IAdToLoadData[];
  newNestedProductSetData: NestedProductSetObject;
};
export const adsToLoadAfterNestedProductSetChange = (
  args: AdsToLoadAfterNestedProductSetChangeArgs,
) => {
  const newAdsToLoad = [...args.currentAdsToLoad].map(adToLoad => {
    if (adToLoad.key !== args.adToLoadKey) return adToLoad;
    return {
      ...adToLoad,
      nestedProductSets: {
        ...(adToLoad.nestedProductSets ?? {}),
        [args.instantExperienceID]: args.newNestedProductSetData,
      },
    };
  });
  return newAdsToLoad;
};

export const adLoadStatusAfterUrlChange = (
  adLoadStatus: IAdLoadStatus,
): IAdLoadStatus => {
  const newQAIssues = (adLoadStatus.reviewAndQAIssues ?? [])
    .filter(
      issue => issue.requiredStep !== ReviewStepKeys.REVIEW_DESTINATION_URLS,
    )
    .concat([defaultReviewUrlQAIssue]);
  return {
    ...adLoadStatus,
    reviewAndQAIssues: newQAIssues,
  };
};

const returnAdLoadStatusFromQAIssues = (adLoadStatus: IAdLoadStatus) => {
  const isError = !!adLoadStatus.reviewAndQAIssues?.find(
    issue =>
      issue.requiredStep &&
      issue.requiredStep !== ReviewStepKeys.REVIEW_DESTINATION_URLS,
  );
  if (isError) return AdLoadAdStatusEnum.ERROR;
  const isWarning = !!adLoadStatus.reviewAndQAIssues?.find(
    issue => issue.requiredStep === ReviewStepKeys.REVIEW_DESTINATION_URLS,
  );
  if (isWarning) return AdLoadAdStatusEnum.WARNING;
  return adLoadStatus.status;
};

export const noMoreQAIssues = (
  adsToLoad: IAdToLoadData[],
  type?: AdLoadAdStatusEnum,
) => {
  const adsWithIssueType = adsToLoad.filter(
    adToLoad =>
      !!adToLoad.adLoadStatus.reviewAndQAIssues?.filter(issue =>
        type
          ? issue.status === type
          : ["warning", "error"].includes(issue.status),
      ).length,
  );
  return !adsWithIssueType.length;
};
