import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  selectAdLoadParameters,
  selectSelectedAds,
} from "redux/adLibrary/adLibrary.selectors";
import { setAdLoadParametersForAd } from "redux/adLibrary/adLibrary.slice";
import { useAppSelector } from "shared/hooks/useAppSelector";

const useSetupUpdateAdsProcess = () => {
  const dispatch = useDispatch();

  const selectedAds = useAppSelector(selectSelectedAds);
  const adLoadParameters = useAppSelector(selectAdLoadParameters);

  const createRulesets = useCallback(() => {
    const createRuleset = (id: string) => {
      dispatch(
        setAdLoadParametersForAd({
          adShellId: id,
          prop: "rulesets",
          data: {
            campaign: [],
            adset: [],
            ad: [],
          },
        }),
      );
    };

    selectedAds
      ?.map(({ id }) => id)
      .filter(adId => !adLoadParameters?.rulesets?.[adId])
      .forEach(createRuleset);
  }, [selectedAds, adLoadParameters?.rulesets, dispatch]);

  useEffect(createRulesets, [createRulesets]);
};

export default useSetupUpdateAdsProcess;
