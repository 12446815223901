import { FC, memo } from "react";
import { Button, Form, Input, message, Modal, Typography } from "antd";
import { LinkOutlined } from "@ant-design/icons";

import styles from "./ShareSessionModal.module.scss";

export interface IProps {
  sessionId: string;
  sessionTitle?: string;
  useWindowLocation?: boolean;
  onChangeTitle: (title: string) => void;
  onClose?: () => void;
}

const { REACT_APP_AV2_CLIENT, REACT_APP_ENV } = process.env;

const ShareSessionModal: FC<IProps> = ({
  sessionId,
  sessionTitle,
  useWindowLocation,
  onChangeTitle,
  onClose,
}) => {
  message.config({ maxCount: 1 });

  // This helps with Storybook and gives the developer the option to force usage of window.location
  const location = !useWindowLocation
    ? `https://av2-${REACT_APP_AV2_CLIENT?.toLowerCase()}${
        REACT_APP_ENV !== "prd" ? `-${REACT_APP_ENV}` : ""
      }.constellationagency.com/ad-library`
    : window.location;

  // the location will have session_url?=<sessionId> in it already
  const sessionUrl = useWindowLocation
    ? (location as Location).href
    : `${location}/load?session_id=${sessionId}`;

  return (
    <Modal
      visible
      closable
      onCancel={() => {
        onClose?.();
      }}
      wrapClassName={styles.shareSessionModal}
      title="Ad Load Link URL"
      footer={[
        <Button
          type="link"
          key="share-button"
          title="Copy Ad Load session URL"
          icon={<LinkOutlined style={{ marginTop: 4 }} />}
          onClick={() => {
            if (!navigator?.clipboard) return;
            navigator.clipboard.writeText(sessionUrl);
            message.info("Session URL copied to clipboard");
          }}
        >
          Copy Link
        </Button>,
      ]}
    >
      <div className={styles.modalContentDiv}>
        <Typography.Text>
          Anyone with this link can view the Ad Load session. It has been copied
          to your clipboard.
        </Typography.Text>
        <Form layout="vertical" className={styles.form}>
          <Form.Item label="Session Name" className={styles.formItem}>
            <Input
              value={sessionTitle}
              className={styles.shareLinkInput}
              onChange={event => onChangeTitle(event.target.value)}
            />
          </Form.Item>
          <Form.Item label="Session URL" className={styles.formItem}>
            <Input value={sessionUrl} className={styles.shareLinkInput} />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default memo(ShareSessionModal);
