import { Button, Form, Input } from "antd";
import { debounce } from "lodash";
import { createPortal } from "react-dom";
import { ButtonGroup } from "../ButtonGroup";
import { FormItem } from "../FormItem";
import { Loading } from "../Loading";
import { MetaFormItems } from "../MetaFormItem";
import { useCAMFooter } from "../useCAMFooter";
import { useForm } from "./useForm";

type Props = {
  drawerId: string;
  onClose: () => void;
};

export const FormFilters = ({ onClose, drawerId }: Props) => {
  const { formFields, onValuesChange, resetForm, form, schema } = useForm();
  const footer = useCAMFooter(drawerId);

  if (schema && formFields) {
    return (
      <Form
        layout="vertical"
        form={form}
        fields={formFields}
        onValuesChange={debounce(onValuesChange, 1000)}
      >
        <MetaFormItems schema={schema} />
        <div hidden>
          <FormItem name="height">
            <Input hidden />
          </FormItem>
          <FormItem name="width">
            <Input hidden />
          </FormItem>
        </div>
        {footer &&
          createPortal(
            <ButtonGroup>
              <Button danger onClick={resetForm}>
                Reset
              </Button>
              <Button onClick={onClose}>Close</Button>
            </ButtonGroup>,
            footer,
          )}
      </Form>
    );
  }

  return <Loading />;
};
