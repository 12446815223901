import { memo, ReactElement } from "react";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import {
  IDisclosure,
  IDisclosureVariable,
  TDisclosureType,
} from "shared/types/legalLingo";
import {
  internalOfferTypeVariables,
  numberAtThisPriceVariableNames,
  offerTypeVariables,
} from "shared/constants/dataManagement";
import { useAppSelector } from "shared/hooks/useAppSelector";
import DisclosureContent from "./DisclosureContent";

import "shared/styles/Collapse.scss";

interface ICollapseFieldsProps {
  activeDisclosureCollapseKey: string;
  currentDisclosureEdit: IDisclosure; // can load default data off of this
}
interface ICollapseFieldsHandlers {
  setActiveDisclosureCollapseKey: (disclosureCollapseKey: string) => void;
  setCurrDisc: (currDisc: IDisclosure) => void;
}

type PropsType = ICollapseFieldsProps & ICollapseFieldsHandlers;

const CollapseFields = (props: PropsType): ReactElement => {
  const feed = useAppSelector(state => state.configuration.feed);
  const offerTypeVariablesToUse =
    feed === "internal" ? internalOfferTypeVariables : offerTypeVariables;

  const disclosureDefaultValue = (offerTypeToFind: string) => {
    const foundDisclosure = props.currentDisclosureEdit?.disclosures.find(
      ({ offerType }) => offerType === offerTypeToFind,
    );

    return foundDisclosure?.text ?? "";
  };

  const handleDisclosure = (offerTypeToFind: TDisclosureType, text: string) => {
    const foundDisclosure = props.currentDisclosureEdit?.disclosures.find(
      ({ offerType }) => offerType === offerTypeToFind,
    );

    const updatedDisclosures = [
      ...props.currentDisclosureEdit?.disclosures.filter(
        ({ offerType }) =>
          (!!foundDisclosure && offerType !== offerTypeToFind) ||
          !foundDisclosure,
      ),
      { offerType: offerTypeToFind, text },
    ];

    props.setCurrDisc({
      ...props.currentDisclosureEdit,
      disclosures: updatedDisclosures,
    });
  };

  const getVariables = (
    offerType: TDisclosureType,
    variables: IDisclosureVariable[],
  ) => {
    if (offerType === "Vehicle Info") {
      return variables.concat(
        numberAtThisPriceVariableNames.map(varName => ({
          name: varName,
          requiredByStates: [],
        })),
      );
    }

    return variables;
  };

  return (
    <>
      {offerTypeVariablesToUse.map(offerTypeVariable => {
        const { offerType, variables } = offerTypeVariable;
        const currentCollapseKey = `${offerType}-collapse`;
        const isActive =
          currentCollapseKey === props.activeDisclosureCollapseKey;

        const foundDisclosureObj = props.currentDisclosureEdit.disclosures.find(
          disclosureObj => disclosureObj.offerType === offerType,
        ) || { offerType, text: "" };

        return (
          <Collapse
            className="collapse-container disclosure-collapse-container-v2"
            key={`${offerType}-disclosure-collapse-container`}
            activeKey={props.activeDisclosureCollapseKey}
            expandIconPosition={"right"}
            onChange={() => {
              const collapseKey = isActive ? "" : currentCollapseKey;
              props.setActiveDisclosureCollapseKey(collapseKey);
            }}
          >
            <Collapse.Panel
              key={currentCollapseKey}
              header={
                <div style={{ overflowX: "hidden" }}>
                  <div
                    style={{ float: "left" }}
                  >{`${offerType} Disclosure`}</div>
                  <div style={{ float: "left", paddingLeft: "0.5em" }}>
                    {isActive ? <SaveOutlined /> : <EditOutlined />}
                  </div>
                </div>
              }
            >
              <DisclosureContent
                placeholder={`Enter a unique disclosure for ${offerType}`}
                defaultValue={disclosureDefaultValue(offerType)}
                isParentTabActive={isActive}
                variables={getVariables(offerType, variables)}
                disclosureObjFromParent={foundDisclosureObj}
                onDisclosureChange={handleDisclosure}
              />
            </Collapse.Panel>
          </Collapse>
        );
      })}
    </>
  );
};

export default memo(CollapseFields);
