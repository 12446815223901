import { MappingDataForExportPortal } from "shared/types/assetExporter";
import { PortalOptionMatches, PortalOptions } from "../types";

export const getMappingDataForExportPortal = (
  portalOptionMatches: PortalOptionMatches,
): MappingDataForExportPortal => {
  return Object.entries(portalOptionMatches).reduce<MappingDataForExportPortal>(
    (accu, [columnTitle, portalOptionMatch]) => {
      if (!portalOptionMatch) return accu;

      const { portalOption } = portalOptionMatch;

      switch (portalOption) {
        case PortalOptions.Metadata:
          return {
            ...accu,
            metas: [...(accu.metas ?? []), columnTitle],
          };
        case PortalOptions.Filters:
          return {
            ...accu,
            tags: [...(accu.tags ?? []), columnTitle],
          };
        case PortalOptions.DoNotImport:
          return {
            ...accu,
            notInclude: [...(accu.notInclude ?? []), columnTitle],
          };
        default:
          return accu;
      }
    },
    {},
  );
};
