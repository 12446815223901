import { cloneDeep, flattenDeep, isEqual, startCase } from "lodash";

import {
  returnDoesIEHaveProductSet,
  returnAreNestedProductSetsAssigned,
} from "../reviewStep/helpers.ie";
import {
  returnAdsetsFromCampaignData,
  returnTitleFromCampaignObjective,
} from "utils/facebook/helpers.campaign";
import {
  flattenDataTree,
  returnAdDestinationKeysOnCheck,
} from "../selectStep/campaignTree/helpers.tree";
import { getReviewAndQAIssuesForInnerStep } from "./components/adsToLoadTable/utils";

import { defaultReviewUrlQAIssue, stepUrlParamValues } from "./constants";

import {
  AdType,
  FacebookAdStatus,
  IFacebookAccount,
  IFacebookAdset,
  IFacebookCampaign,
} from "screens/adLibrary/facebookUtils/types";
import {
  AdLoadAdStatusEnum,
  AdLoadStepKey,
  FacebookAdLoadDictionary,
  FacebookDataIdsByAd,
  IAd,
  IAdLoadDestination,
  IAdLoadParameters,
  IAdLoadReviewAndQAIssue,
  IAdLoadStatus,
  IAdToLoadData,
  IEUrlValues,
  IInstantExperience,
  ReviewStepKeys,
} from "shared/types/adLibrary";
import { AccountUrl, IAccount } from "shared/types/accountManagement";
import {
  Checked,
  IMapAdsToLoadDataFromTreeDataArgs,
  IReturnAdToLoadFromAdNodeArgs,
  IReturnAdLoadReviewAndQAStatusArgs,
  CheckInfo,
  IMapAdsToLoadDataFromFacebookAdsArgs,
} from "./types";
import { getIEUrlValuesMap } from "../reviewStep/reviewStepInnerDrawer/utils";
import { DataNode } from "antd/lib/tree";
import { getCurrentMoment } from "utils/helpers";
import { isValidUrl } from "utils/validators";

const {
  CONVERT_TO_VIDEO,
  REVIEW_DESTINATION_URLS,
  ASSIGN_PRODUCT_SETS_AD_LEVEL,
  ASSIGN_PRODUCT_SETS_IE_LEVEL,
  LINK_INSTANT_EXPERIENCE,
  INVALID_DESTINATION_URL,
} = ReviewStepKeys;

export const getStoreMatch = (
  stores?: IAccount[],
  fbAccount?: IFacebookAccount,
): IAccount | undefined => {
  return stores?.find(store => store.dealer_name === fbAccount?.name);
};

export const returnDealersWithDuplicateAccountIds = (dealers: IAccount[]) => {
  const accountIds = dealers
    .map(dealer => dealer?.details?.facebook?.fbAccountId)
    .filter(accountId => !!accountId);
  const duplicateIds = accountIds.filter(
    accountId => accountIds.filter(id => id === accountId).length > 1,
  );
  return duplicateIds;
};

export const returnAdLoadDestinationDataAfterAccountRemoval = (
  adLoadDestination: IAdLoadDestination,
  accountToRemove: IFacebookAccount,
): IAdLoadDestination => {
  const newAdLoadDestination: IAdLoadDestination = cloneDeep(adLoadDestination);

  const removalIndex = newAdLoadDestination.treeData?.findIndex(
    accountNode => accountNode.key === accountToRemove.account_id,
  );

  if (removalIndex === -1 || removalIndex === undefined) {
    return adLoadDestination;
  }

  const treeDataToRemove = newAdLoadDestination.treeData!.splice(
    removalIndex,
    1,
  );

  const keysToRemove = flattenDataTree(treeDataToRemove).map(
    treeNode => treeNode.key,
  );

  newAdLoadDestination.checkedKeys = newAdLoadDestination.checkedKeys?.filter(
    key => !keysToRemove.includes(key),
  );
  newAdLoadDestination.expandedKeys = newAdLoadDestination.expandedKeys?.filter(
    key => !keysToRemove.includes(key),
  );

  return newAdLoadDestination;
};

export const returnAdLoadDestinationDataOnCheck = (args: {
  info: CheckInfo;
  checked: Checked;
  checkedKeysToUse: string[];
  expandedKeysToUse: string[];
  accounts: IFacebookAccount[];
  campaigns: IFacebookCampaign[];
  adLoadDestination: IAdLoadDestination;
}): IAdLoadDestination => {
  const {
    info,
    checked,
    accounts,
    campaigns,
    checkedKeysToUse,
    expandedKeysToUse,
    adLoadDestination,
  } = args;
  const checkedArr = checked as string[];

  const newRemovedAdKeys = (
    adLoadDestination?.removedAdDestinationKeys ?? []
  ).filter(adKey => {
    const splitKey = adKey.split("_");
    const campaignId = splitKey[1];
    const adsetId = splitKey[2];
    return checkedArr.includes(campaignId) && checkedArr.includes(adsetId);
  });

  const newKey =
    checkedArr.length > checkedKeysToUse.length
      ? checkedArr.find(key => !checkedKeysToUse.includes(key))
      : undefined;

  const adsets = returnAdsetsFromCampaignData(campaigns ?? []);
  const adsetIds = adsets.map(adset => adset.id!);

  const isNewKeyAdset = !!newKey && adsetIds.includes(newKey);
  const newKeyCampaign = campaigns?.find(campaign => campaign.id === newKey);
  const newKeyAccount = accounts?.find(
    account => account.account_id === newKey,
  );

  let newExpandedKeys = [...expandedKeysToUse] as string[];
  if (newKey && isNewKeyAdset) {
    newExpandedKeys.push(newKey);
  } else if (newKey && !!newKeyCampaign?.adsets) {
    const adsetIds = newKeyCampaign.adsets.data.map(adset => adset.id!);
    newExpandedKeys = newExpandedKeys.concat([newKeyCampaign.id!, ...adsetIds]);
  } else if (newKey && !!newKeyAccount && campaigns) {
    const campaignsFromAccount = campaigns.filter(
      campaign => campaign.account_id === newKeyAccount.account_id,
    );
    const adsetsFromAccount =
      returnAdsetsFromCampaignData(campaignsFromAccount);
    newExpandedKeys = newExpandedKeys.concat([
      newKeyAccount.account_id,
      ...campaignsFromAccount.map(campaign => campaign.id!),
      ...adsetsFromAccount.map(adset => adset.id!),
    ]);
  }

  const newAdLoadDestinationKeys = returnAdDestinationKeysOnCheck({
    info,
    accounts,
    campaigns: campaigns,
    currentCheckedKeys: checkedKeysToUse as string[],
    currentAdDestinationKeys: adLoadDestination?.adDestinationKeys ?? [],
    adsToInsertKeys: (adLoadDestination.selectedAds ?? [])?.map(ad => ad.id),
  });

  return {
    ...adLoadDestination,
    checkedKeys: checkedArr,
    expandedKeys: newExpandedKeys,
    removedAdDestinationKeys: newRemovedAdKeys,
    adDestinationKeys: newAdLoadDestinationKeys,
  };
};

export const mapAdsToLoadDataFromTreeData = (
  args: IMapAdsToLoadDataFromTreeDataArgs,
): IAdToLoadData[] => {
  const adsToLoadData = args.treeData.map(accountNode => {
    const selectedAccount = args.selectedAccounts.find(
      account => account.account_id === accountNode.key,
    );

    const selectedStore = getStoreMatch(args.selectedStores, selectedAccount);
    const { details } = selectedStore ?? {};
    const { fbCatalogId, fbPageId } = details?.facebook ?? {};
    const campaignNodes = accountNode.children ?? [];
    const pageObject = args.pages.find(page => page.id === fbPageId);
    const catalogObject = args.productCatalogs.find(
      catalog => catalog.id === fbCatalogId,
    );

    return campaignNodes.map(campaignNode => {
      const campaignData = args.campaigns?.find(
        campaign => campaign.id === campaignNode.key,
      );
      const adsetNodes = campaignNode.children ?? [];
      return adsetNodes.map(adsetNode => {
        const adsetData = campaignData?.adsets?.data.find(
          adset => adset.id === adsetNode.key,
        );
        const adNodes = adsetNode.children ?? [];
        return adNodes.map(adNode => {
          const currentAdToLoad = args.currentAdsToLoad?.find(
            adToLoad => adToLoad.key === adNode.key,
          );
          if (currentAdToLoad) {
            return {
              ...currentAdToLoad,
              adLoadStatus: returnAdLoadReviewAndQAStatus({
                adToLoad: currentAdToLoad,
                productSets: args.productSets,
                instantExperiences: args.instantExperiences,
                wereDestinationURLsReviewed: args.wereDestinationURLsReviewed,
              }),
            };
          }
          return returnAdToLoadFromAdNode({
            adNode,
            adsetData,
            adsetNode,
            campaignNode,
            campaignData,
            selectedAccount,
            fbPageObject: pageObject,
            selectedAds: args.selectedAds,
            productSets: args.productSets,
            fbCatalogObject: catalogObject,
            urlLabels: selectedStore?.labels,
            dealerFacebookDetails: details?.facebook,
            instantExperiences: args.instantExperiences,
          });
        });
      });
    });
  });

  const flattendData = flattenDeep(adsToLoadData) as IAdToLoadData[];

  return flattendData;
};

export const mapAdsToLoadDataFromFacebookAds = (
  args: IMapAdsToLoadDataFromFacebookAdsArgs,
): IAdToLoadData[] => {
  const adsToLoadData = Object.entries(args.facebookAdLoadDict).map(
    ([adId, facebookObjects]) => {
      return facebookObjects.ad.map(ad => {
        const adNodeId = `${ad.facebookAd.campaign_id}_${ad.facebookAd.adset_id}_${ad.facebookAd.id}_${adId}`;

        const adset = facebookObjects.adset.find(
          adset => adset.id === ad.facebookAd.adset_id,
        ) as IFacebookAdset;

        const campaign = facebookObjects.campaign.find(
          campaign => campaign.id === ad.facebookAd.campaign_id,
        ) as IFacebookCampaign;

        const selectedAccount = args.selectedAccounts.find(
          selectedAccount => selectedAccount.account_id === campaign.account_id,
        );

        const selectedStore = getStoreMatch(
          args.selectedStores,
          selectedAccount,
        );

        const { details } = selectedStore ?? {};
        const { fbCatalogId, fbPageId } = details?.facebook ?? {};
        const pageObject = args.pages.find(page => page.id === fbPageId);

        const catalogObject = args.productCatalogs.find(
          catalog => catalog.id === fbCatalogId,
        );

        return returnAdToLoadFromAdNode({
          adNode: buildNode(adNodeId, ad.facebookAd.name),
          adsetData: adset,
          adsetNode: buildNode(adset.id, adset.name),
          campaignData: campaign,
          campaignNode: buildNode(campaign.id, campaign.name),
          selectedAccount,
          fbPageObject: pageObject,
          selectedAds: args.selectedAds,
          productSets: args.productSets,
          fbCatalogObject: catalogObject,
          // using store labels to get urls
          urlLabels: selectedStore?.labels,
          dealerFacebookDetails: details?.facebook,
          instantExperiences: args.instantExperiences,
          facebookAdId: ad.facebookAd.id,
        });
      });
    },
  );

  const flattendData = flattenDeep(adsToLoadData) as IAdToLoadData[];

  return flattendData;
};

const buildNode = (id?: string, name?: string) => {
  return {
    key: id,
    title: name,
  } as DataNode;
};

export const getUrlValue = (urlValue = "", urlLabels?: AccountUrl[]) => {
  if (!urlValue) return "";
  const foundLabel = urlLabels?.find(
    label => label.name.toLowerCase() === urlValue?.toLowerCase(),
  );
  if (!foundLabel) return urlValue;
  if (foundLabel?.url) return foundLabel.url;
  return "";
};

export const canUseUrlField = (adType: AdType) =>
  ![AdType.Collection, AdType.InstantExperience].includes(adType);

const returnAdToLoadFromAdNode = (
  args: IReturnAdToLoadFromAdNodeArgs,
): IAdToLoadData => {
  const selectedAd = args.selectedAds?.find(ad =>
    args.adNode.key?.toString()?.endsWith?.(ad.id),
  );
  const includeUrl = selectedAd && canUseUrlField(selectedAd.type);
  const ieId = selectedAd?.visuals.destination?.instantExperienceId;
  const ieUrlValues = getIEUrlValues({
    adShellKey: args.adNode.key.toString(),
    ieId,
    urlLabels: args.urlLabels,
    instantExperiences: args.instantExperiences,
  });

  const displayUrl = includeUrl
    ? getUrlValue(selectedAd?.visuals?.displayUrl, args.urlLabels)
    : undefined;

  const destinationUrl = includeUrl
    ? getUrlValue(selectedAd?.inputParameters?.destinationUrl, args.urlLabels)
    : undefined;

  const adToLoad = {
    key: args.adNode.key,
    ad: {
      ...selectedAd,
      visuals: {
        ...(selectedAd?.visuals ?? {}),
        assetId: undefined,
        displayUrl,
        cards: selectedAd?.visuals?.cards?.map(card => ({
          ...card,
          assetId: undefined,
          destinationUrl: getUrlValue(card.destinationUrl, args.urlLabels),
        })),
      },
      inputParameters: {
        ...(selectedAd?.inputParameters ?? {}),
        destinationUrl,
      },
    },
    adLoadStatus: {
      status: "draft",
    },
    progressStatus: {
      uploadMedia: AdLoadAdStatusEnum.DRAFT,
      assembleCreative: AdLoadAdStatusEnum.DRAFT,
      loadToFacebook: AdLoadAdStatusEnum.DRAFT,
    },
    account: args.selectedAccount,
    facebookStatus: FacebookAdStatus.PAUSED,
    adset: {
      id: args.adsetNode.key,
      name: args.adsetNode.title,
    },
    page: args.fbPageObject || { id: args.dealerFacebookDetails?.fbPageId },
    campaign: {
      id: args.campaignNode.key,
      name: args.campaignNode.title,
      objective: args.campaignData?.objective
        ? returnTitleFromCampaignObjective(args.campaignData.objective)
        : "",
    },
    productCatalog: args.fbCatalogObject || {
      id: args.dealerFacebookDetails?.fbCatalogId,
    },
    ieUrlValues,
    facebookAdId: args.facebookAdId,
  } as IAdToLoadData;

  const reviewAndQAStatus = returnAdLoadReviewAndQAStatus({
    adToLoad,
    productSets: args.productSets,
    instantExperiences: args.instantExperiences,
    wereDestinationURLsReviewed: args.wereDestinationURLsReviewed,
  });

  return { ...adToLoad, adLoadStatus: reviewAndQAStatus };
};

type GetIEUrlValuesArgs = {
  ieId: string | undefined;
  adShellKey: string;
  urlLabels?: AccountUrl[];
  instantExperiences?: IInstantExperience[];
};
const getIEUrlValues = ({
  ieId,
  adShellKey,
  urlLabels,
  instantExperiences,
}: GetIEUrlValuesArgs): IEUrlValues | undefined => {
  if (!ieId) return;
  const values = getIEUrlValuesMap({
    ieId,
    instantExperiences,
    urlLabels,
    parentIds: [adShellKey],
  });
  return values;
};

const isInvalidDestinationUrl = (type: AdType, ad: IAd) => {
  const adTypesWithDestinationUrl = [
    AdType.Video,
    AdType.Still,
    AdType.AIA,
    AdType.DPA,
    AdType.FTA,
  ];

  const cards = ad?.visuals?.cards;
  const destinationUrl = ad?.inputParameters?.destinationUrl;

  const isInvalidCarouselDestinationURL =
    type === AdType.Carousel &&
    !cards?.every(
      card => card.destinationUrl && isValidUrl(card.destinationUrl),
    );

  const isInvalidDefaultDestinationURL =
    adTypesWithDestinationUrl.includes(type) &&
    (!destinationUrl || !isValidUrl(destinationUrl));

  return isInvalidCarouselDestinationURL || isInvalidDefaultDestinationURL;
};

export const returnAdLoadReviewAndQAStatus = (
  args: IReturnAdLoadReviewAndQAStatusArgs,
): IAdLoadStatus => {
  const {
    adToLoad,
    instantExperiences = [],
    wereDestinationURLsReviewed,
  } = args;

  const { ad: adShell } = adToLoad;
  const { type, visuals } = adShell;
  const { destination } = visuals;

  const reviewAndQAIssues: IAdLoadReviewAndQAIssue[] =
    wereDestinationURLsReviewed ? [] : [defaultReviewUrlQAIssue];

  if (destination?.instantExperienceId) {
    const adNeedsProductSet =
      returnDoesIEHaveProductSet(
        destination.instantExperienceId,
        instantExperiences,
      ) && !returnIsProductSelectionDisabled(adToLoad, instantExperiences);

    if (adNeedsProductSet && !adToLoad.productSet?.id) {
      reviewAndQAIssues.push({
        status: "error",
        message: "This Ad needs a product set",
        requiredStep: ASSIGN_PRODUCT_SETS_AD_LEVEL,
      });
    }

    const wereNestedProductSetsAssigned = returnAreNestedProductSetsAssigned(
      adToLoad,
      instantExperiences,
    );

    if (!wereNestedProductSetsAssigned) {
      reviewAndQAIssues.push({
        status: "error",
        message:
          "This Ad has Instant Experiences with Dynamic Inventory that need a product sets",
        requiredStep: ASSIGN_PRODUCT_SETS_IE_LEVEL,
      });
    }
  }

  if (type === AdType.Collection) {
    if (adToLoad.productSet?.id) {
      if ((adToLoad.productSet?.product_count ?? 0) < 4) {
        reviewAndQAIssues.push({
          status: "error",
          message: "This collection ad has less than 4 products",
          requiredStep: CONVERT_TO_VIDEO,
        });
      }
    } else {
      reviewAndQAIssues.push({
        status: "error",
        message: "This Ad needs a product set",
        requiredStep: ASSIGN_PRODUCT_SETS_AD_LEVEL,
      });
    }
  }

  if (type === AdType.InstantExperience && !!!adToLoad.ieUrlValues) {
    reviewAndQAIssues.push({
      status: "error",
      message: "No Instant Experience has been linked",
      requiredStep: LINK_INSTANT_EXPERIENCE,
    });
  }

  if (isInvalidDestinationUrl(type, adToLoad.ad)) {
    reviewAndQAIssues.push({
      status: "error",
      message: "",
      requiredStep: INVALID_DESTINATION_URL,
    });
  }

  const issuesHaveErrors = !!reviewAndQAIssues.filter(
    issue => issue.status === "error",
  ).length;

  return {
    reviewAndQAIssues,
    status: issuesHaveErrors ? "error" : "draft",
    errorMessage: issuesHaveErrors ? "Error" : undefined,
  };
};

export const doesAdToLoadHaveQAErrorIssues = (adToLoad: IAdToLoadData) =>
  !!adToLoad.adLoadStatus.reviewAndQAIssues?.filter(
    issue => issue.status === "error",
  )?.length;

export const mapAdsToLoadDataAfterUpdate = (
  adsToLoad: IAdToLoadData[],
  updatedAdsToLoad: IAdToLoadData[],
) => {
  const updateAdsByKey = (adToLoad: IAdToLoadData): IAdToLoadData => {
    const foundData = updatedAdsToLoad.find(item => item.key === adToLoad.key);
    if (foundData) {
      const wereChangesMade = !isEqual(foundData, adToLoad);
      return wereChangesMade ? { ...adToLoad, ...foundData } : adToLoad;
    }
    return adToLoad;
  };

  const updateProductSetByAdId = (adToLoad: IAdToLoadData): IAdToLoadData => {
    const foundUpdatedAdWithSameId = updatedAdsToLoad.find(
      item => item.ad.id === adToLoad.ad.id,
    );
    return foundUpdatedAdWithSameId
      ? {
          ...adToLoad,
          productCatalog: foundUpdatedAdWithSameId.productCatalog,
          productSet: foundUpdatedAdWithSameId.productSet,
          adLoadStatus: foundUpdatedAdWithSameId.adLoadStatus,
        }
      : adToLoad;
  };

  return adsToLoad.map(updateAdsByKey).map(updateProductSetByAdId);
};

export const returnIsProductSelectionDisabled = (
  adToLoad: IAdToLoadData,
  instantExperiences: IInstantExperience[],
) =>
  adToLoad.ad.type !== AdType.Collection &&
  !returnDoesIEHaveProductSet(
    adToLoad.ad.visuals.destination?.instantExperienceId ?? "",
    instantExperiences,
  );

export const wereUrlsReviewed = (currentAdsToLoad: IAdToLoadData[]) => {
  const reviewDestinationUrlsIssues = getReviewAndQAIssuesForInnerStep(
    currentAdsToLoad,
    REVIEW_DESTINATION_URLS,
  );
  const wereDestinationUrlsReviewed = !reviewDestinationUrlsIssues.length;
  return wereDestinationUrlsReviewed;
};

export const getLoadStepFromUrlParams = (searchString: string) => {
  const urlParams = new URLSearchParams(searchString);
  const stepValue = urlParams.get("step");
  return (
    (Object.keys(stepUrlParamValues).find(
      stepKey => stepUrlParamValues[stepKey as AdLoadStepKey] === stepValue,
    ) as AdLoadStepKey) ?? null
  );
};

export const getPreviousAdLoadStep = (currentStep?: AdLoadStepKey | null) => {
  if (currentStep === AdLoadStepKey.LOAD_ADS) {
    return AdLoadStepKey.REVIEW_ADS_TO_LOAD;
  }
  if (currentStep === AdLoadStepKey.REVIEW_ADS_TO_LOAD) {
    return AdLoadStepKey.DESTINATION_SELECTION;
  }
  return null;
};

export const getAdLoadStatusBadgeDescription = (adToLoad: IAdToLoadData) => {
  if (adToLoad.adLoadStatus.status === AdLoadAdStatusEnum.ERROR) return "Error";
  if (adToLoad.facebookStatus === FacebookAdStatus.ACTIVE) return "On";
  if (adToLoad.facebookStatus === FacebookAdStatus.PAUSED) return "Off";
  return startCase(adToLoad.adLoadStatus.status);
};

export const hasConflicts = (
  adId: string,
  adLoadParameters: IAdLoadParameters | null,
) => {
  return (adLoadParameters?.conflicts?.[adId]?.length ?? 0) > 0;
};

export const hasFacebookAdsToUpdate = (
  adId: string,
  facebookAdLoadDict: FacebookAdLoadDictionary | FacebookDataIdsByAd | null,
) => {
  return (facebookAdLoadDict?.[adId]?.ad.length ?? 0) > 0;
};

export const getCampaignDateRange = () => {
  const endRange = getCurrentMoment().add(3, "months");
  const startRange = getCurrentMoment().subtract(3, "months");
  return [startRange.valueOf(), endRange.valueOf()];
};

export const resetAdLoadStatus = (
  adsToLoad: IAdToLoadData[],
  shouldLoadAgain: boolean,
): IAdToLoadData[] =>
  adsToLoad.map(adToLoad => {
    const willLoad =
      (shouldLoadAgain && adToLoad.adLoadStatus.status !== "success") ||
      !shouldLoadAgain;
    return {
      ...adToLoad,
      facebookAd: {
        ...adToLoad.facebookAd,
        id: willLoad ? undefined : adToLoad.facebookAd?.id,
        creative:
          // if creative === undefined , then make API requests for ad creative
          shouldLoadAgain ? adToLoad.facebookAd?.creative : undefined,
      },
      adLoadStatus: willLoad
        ? { ...adToLoad.adLoadStatus, status: "loading" }
        : adToLoad.adLoadStatus,
      progressStatus: willLoad
        ? {
            uploadMedia: AdLoadAdStatusEnum.DRAFT,
            assembleCreative: AdLoadAdStatusEnum.DRAFT,
            loadToFacebook: AdLoadAdStatusEnum.DRAFT,
          }
        : adToLoad.progressStatus,
    };
  });
