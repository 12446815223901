import { Divider, Input, message, Modal, Select } from "antd";

import {
  templateAssetTypes,
  isTemplate,
  ITemplate,
} from "shared/types/designStudio";
import "./ManageArtboard.scss";

import { context } from "../../../Editor.context";
import { PlusOutlined } from "@ant-design/icons";
import API from "services";
import useDeepEffect from "shared/hooks/useDeepEffect";
import { FC, useContext, useEffect, useState } from "react";

const ManageArtboard: FC = () => {
  const [searchBy, setSearchBy] = useState<string>();

  const editorContext = useContext(context);
  const { resource } = editorContext || {};

  const [selectedTags, setSelectedTags] = useState<string[]>();
  useEffect(() => {
    if (!resource) return;

    if (isTemplate(resource)) {
      setSelectedTags(resource.tags);
    }
  }, [resource]);

  const [updatingTags, setUpdatingTags] = useState<boolean>(false);
  useDeepEffect(() => {
    if (!selectedTags || !editorContext?.resource) return;
    if (isTemplate(editorContext.resource)) {
      setUpdatingTags(true);
      const updatedTemplate: ITemplate = {
        ...editorContext.resource!,
        tags: selectedTags,
      };
      API.services.designStudio
        .updateTemplate(updatedTemplate)
        .then(({ result, error }) => {
          if (error?.message || !result) {
            if (!result) message.error("There was system error!");
            else message.error(error?.message || "There was system error!");

            return;
          }

          editorContext?.setResource({
            ...result.template,
          });

          setUpdatingTags(false);
        })
        .catch(err => {
          message.error(`${err}`);
          setUpdatingTags(false);
        });
    }
  }, [selectedTags]);

  return (
    <div className="manage-artboard-container">
      {isTemplate(resource) && (
        <>
          <div className="property-with-title name-property">
            <div className="title">Name</div>
            <Input disabled={true} value={resource.artboard.name} />
          </div>
          <div className="property-with-title asset-type-property">
            <div className="title">Asset Type</div>
            <Select disabled={true} value={resource.assetType || "-"}>
              {templateAssetTypes.map(assetType => (
                <Select.Option key={assetType} value={assetType}>
                  {assetType}
                </Select.Option>
              ))}
            </Select>
          </div>

          <Divider />
          <div className="property-with-title size-property">
            <div className="title">Artboard Size</div>
            <div className="size-fields-container">
              <span>
                <Input disabled={true} value={resource.artboard.width} />
                <span>W</span>
              </span>
              <span>
                <Input disabled={true} value={resource.artboard.height} />
                <span>H</span>
              </span>
            </div>
          </div>

          <Divider />
          <div className="property-with-title tag-property">
            <div className="title">Tags</div>
            <div className="tag-input-container">
              <Select
                loading={updatingTags}
                mode="multiple"
                value={selectedTags}
                onSelect={(value: string) => {
                  const updatedTags = [...(selectedTags || []), value];
                  setSelectedTags(updatedTags);
                  setSearchBy(undefined);
                }}
                onDeselect={(value: string) => {
                  const updatedTags =
                    selectedTags?.filter(tag => tag !== value) || [];
                  setSelectedTags(updatedTags);
                  setSearchBy(undefined);
                }}
                onSearch={value => {
                  if (value) setSearchBy(value);
                  else setSearchBy(undefined);
                }}
                dropdownRender={() => {
                  return (
                    <div
                      className="add-tag-dropdown-container"
                      onMouseDown={e => {
                        e.preventDefault(); // ******** This part is needed to make the onClick method to work properly..
                      }}
                      onClick={() => {
                        if (!searchBy?.trim()) {
                          Modal.warning({
                            title:
                              "The tag name is not valid. Please enter valid tag name.",
                          });

                          return;
                        }

                        Modal.confirm({
                          title: "Do you want to add new tag?",
                          content: (
                            <span className="add-template-tag-container-span">
                              Are you sure you want to add new tag,
                              <span>{searchBy}</span>
                              for this template?
                            </span>
                          ),
                          okText: "Add",
                          onOk: async () => {
                            const updatedTags = [...resource.tags, searchBy];
                            setSelectedTags(updatedTags);
                          },
                        });
                      }}
                    >
                      <PlusOutlined /> Add Tag
                    </div>
                  );
                }}
              >
                {resource.tags.map(tag => {
                  return (
                    <Select.Option value={tag} key={`${tag}-key`}>
                      {tag}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ManageArtboard;
