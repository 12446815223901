import usePreference from "./usePreference";
import { SelectedDates } from "shared/types/campaignManagement";

const useSelectedDateRange = () => {
  const { value: selectedDateRange, setValue: setSelectedDateRange } =
    usePreference<SelectedDates>("selectedDateRange", [0, 0], true);

  return {
    selectedDateRange,
    setSelectedDateRange,
  };
};

export default useSelectedDateRange;
