import {
  IAssetBuildInstance,
  ICanvasObject,
  isThemeBackground,
} from "shared/types/assetBuilder";

export const getCanvasJsonUrlsFromInstances = (
  assetBuildInstances: Record<string, Record<string, IAssetBuildInstance[]>>,
) => {
  return Object.values(assetBuildInstances).flatMap(instanceType =>
    Object.values(instanceType)
      .flatMap(instances =>
        instances.map(instance => instance.template?.canvasJsonUrl),
      )
      .filter((url): url is string => Boolean(url)),
  );
};

export const hasThemeBackgroundSlot = (canvasJsonObj: {
  [key: string]: ICanvasObject[] | string | number;
}) => {
  const objects = canvasJsonObj.objects;
  if (!Array.isArray(objects)) return false;

  return !!objects?.find(isThemeBackground);
};
