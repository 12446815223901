import { Badge, Button, Drawer, Row, Select, Tooltip } from "antd";
import moment from "moment";
import { useState } from "react";
import { connect } from "react-redux";
import actions from "redux/rootActions";
import { useIsAdmin } from "shared/hooks/useIsAdmin";
import { IAssetBuilderState } from "shared/types/assetBuilder";
import {
  INewOrder,
  INewOrderRecord,
  LADStatusOptions,
  StatusOptions,
} from "shared/types/newOrders";
import { isEnvVarEquals } from "utils/helpers";
import { getColorFromStatus } from "../utils";

import styles from "./OrderDetailsDrawer.module.scss";

type Props = {
  currentOrder?: INewOrder;
  visible: boolean;
  onClose: () => void;
  updateNewOrder: (
    updateOrder: Partial<INewOrderRecord>,
    message?: string,
  ) => void;
};

const OrderDetailsDrawer = ({
  currentOrder,
  visible,
  onClose,
  updateNewOrder,
}: Props) => {
  const fields = [
    {
      title: "Created",
      value: moment(currentOrder?.createdAt).format("MM/DD/YY"),
    },
    {
      title: "Expires",
      value: moment(currentOrder?.expiresAt).format("MM/DD/YY"),
    },
    { title: "Creator", value: currentOrder?.creator_name },
    { title: "Project Name", value: currentOrder?.wfProjectName },
    { title: "Status", value: currentOrder?.status },
    { title: "Account DBA", value: currentOrder?.dealer_name },
    { title: "Account ID", value: currentOrder?.dealer_code },
    { title: "Brands", value: currentOrder?.dealer_oem },
    { title: "Assets", value: currentOrder?.num_assets },
    { title: "Inventory", value: currentOrder?.selectedInventory },
    { title: "Offers", value: currentOrder?.totalSelectedOffers },
    { title: "Stamp Count", value: currentOrder?.totalUsedStamps },
    { title: "Integrations", value: currentOrder?.integrationCount },
    {
      title: "Offer Types",
      value: currentOrder?.selectedOrderOffers?.split(", \n").join(", "),
    },
  ];

  const isAdmin = useIsAdmin();

  const isLad = isEnvVarEquals("CLIENT", "ladtech");
  const options = isLad ? LADStatusOptions : StatusOptions;

  const [selectedStatus, setSelectedStatus] = useState<StatusOptions>();

  const onSave = () => {
    if (selectedStatus === currentOrder?.status) return;
    const orderRecordConversion: Partial<INewOrderRecord> = {
      id: currentOrder?.id,
      status: selectedStatus,
    };
    updateNewOrder(orderRecordConversion, "The order status has been updated");
    onClose();
  };
  return (
    <Drawer
      title="Order Details"
      placement="right"
      visible={visible}
      onClose={onClose}
      width={500}
      footer={
        <Row gutter={16} align="middle" className={styles.footer}>
          <div className="form-drawer-button-ctn">
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onSave}>
              Save
            </Button>
          </div>
        </Row>
      }
    >
      {fields.map(({ title, value }, idx) => (
        <div className={styles.infoContainer} key={`details-drawer-${idx}`}>
          <div className={styles.title}>{title}</div>
          {title === "Status" ? (
            <div className={styles.value}>
              <Tooltip
                title={
                  !isAdmin
                    ? "Please check user permissions in order to edit the status"
                    : ""
                }
              >
                <Select
                  style={{ width: "100%" }}
                  defaultValue={value || StatusOptions.NO_STATUS}
                  disabled={!isAdmin}
                  onSelect={value => setSelectedStatus(value as StatusOptions)}
                >
                  {Object.values(options).map(value => (
                    <Select.Option key={value} value={value}>
                      <Badge color={getColorFromStatus(value)} text={value} />
                    </Select.Option>
                  ))}
                </Select>
              </Tooltip>
            </div>
          ) : (
            <div className={styles.value}>{value}</div>
          )}
        </div>
      ))}
    </Drawer>
  );
};

const mapStateToProps = (state: any) => {
  const { assetBuilder } = state as { assetBuilder: IAssetBuilderState };
  const { savedOrder } = assetBuilder;
  const { selectedOrder: currentOrder } = savedOrder || {};
  return { currentOrder };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateNewOrder: (
      updateNewOrder: Partial<INewOrderRecord>,
      message?: string,
    ) => {
      dispatch(actions.newOrders.updateNewOrder(updateNewOrder, message));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailsDrawer);
