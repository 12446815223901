import { useRef } from "react";
import { ReactNode } from "react";
import { Handle as FlowHandle, Position } from "react-flow-renderer";
import classnames from "classnames";
import styles from "./Wrapper.module.scss";
import { DEFAULT_DIMENSION } from "shared/constants/everythingads";
import { useEverythingAdsContext } from "screens/everythingAds.hooks/useContextAPI";
import useIsTryingToConnect from "screens/everythingAds.hooks/useIsTryingToConnect";
import classNames from "classnames";
import { AppColors } from "shared/types/shared";
import { CSSProperties } from "react";
import { ThunderboltFilled } from "@ant-design/icons";

type Props = {
  style?: CSSProperties;
  children?: ReactNode;
  title?: string;
  selected?: boolean;
};

const Container = (props: Props) => {
  const { isConnecting } = useEverythingAdsContext() || {};

  const ref = useRef<HTMLDivElement | null>(null);
  const { isTryingToConnect, onMouseEnter, onMouseLeave } =
    useIsTryingToConnect(isConnecting ?? false);

  const { selected, style } = props;
  const childrenWrapperStyles = selected
    ? { borderColor: AppColors.primary }
    : {};
  const flowHandleStyle: CSSProperties = {
    width: "30px",
    height: "30px",
    left: "-15px",
    border: "none",
    zIndex: 10,
    backgroundColor: AppColors.primary,
  };
  const thunderboltStyle: CSSProperties = {
    color: "white",
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "15px",
    pointerEvents: "none",
  };
  return (
    <div
      ref={ref}
      className={styles.container}
      style={{
        width: DEFAULT_DIMENSION.width,
        ...style,
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <FlowHandle
        style={flowHandleStyle}
        type="target"
        position={Position.Left}
      >
        <ThunderboltFilled style={thunderboltStyle} />
      </FlowHandle>
      <FlowHandle
        className={classnames(
          [styles["target"]].concat(
            isTryingToConnect ? [styles["connecting-target"]] : [],
          ),
        )}
        type="target"
        position={Position.Left}
      />
      <div className={styles.title}>{props.title || "<Title>"}</div>
      {props.children && (
        <div
          className={classNames(styles.body, styles.selected)}
          style={childrenWrapperStyles}
        >
          {props.children}
        </div>
      )}
    </div>
  );
};

export default Container;
