import {
  returnDataNotFoundObj,
  returnAdToLoadStatus,
} from "screens/adLibrary/adLoad/adLoadDrawer/loadStep/helpers.load";
import {
  assembleFacebookAdCreative,
  AssembleFacebookAdCreativeArgs,
} from "screens/adLibrary/adLoad/adLoadDrawer/loadStep/helpers.ad";
import { convertCollectionCreativeToVideoCreative } from "screens/adLibrary/facebookUtils/adPreviewCreative";

import { LoadToFacebookArgs } from "./useMutateFacebookAd";
import {
  AdLoadAdStatusEnum,
  IAdToLoadData,
  IInstantExperience,
} from "shared/types/adLibrary";
import { UseMutateAsyncFunction, useMutation } from "react-query";

import AdAccount from "screens/adLibrary/facebookUtils/adAccount";
import {
  ICreateOrUpdateResponse,
  IFacebookAdCreative,
} from "screens/adLibrary/facebookUtils/types";
import { getErrorMessage } from "utils/errorMessage";

export const useAssembleFacebookAdCreative = () =>
  useMutation(assembleFacebookAdCreative);

type UseCreateAdCreativeArgs = {
  facebookAdAccount: AdAccount;
  creativeParams: IFacebookAdCreative;
};

export const useCreateAdCreative = () =>
  useMutation((args: UseCreateAdCreativeArgs) =>
    args.facebookAdAccount.createAdCreative(args.creativeParams || {}),
  );

type CancellableAssembleFacebookAdCreative = UseMutateAsyncFunction<
  IFacebookAdCreative,
  unknown,
  AssembleFacebookAdCreativeArgs,
  unknown
>;

type CancellableCreateAdCreative = UseMutateAsyncFunction<
  ICreateOrUpdateResponse,
  unknown,
  UseCreateAdCreativeArgs,
  unknown
>;

type AssembleAdCreativeArgs = LoadToFacebookArgs & {
  instantExperiences: IInstantExperience[];
  createAdCreative: CancellableCreateAdCreative;
  assembleFacebookAdCreative: CancellableAssembleFacebookAdCreative;
};

const assembleAdCreative = async (args: AssembleAdCreativeArgs) => {
  if (args.adToLoad.facebookAd?.creative?.id) {
    return {
      ...args.adToLoad,
      progressStatus: {
        ...args.adToLoad.progressStatus,
        assembleCreative: AdLoadAdStatusEnum.SUCCESS,
      },
    };
  }

  if (!args.facebookAdAccount || !args.selectedDealer) {
    return returnDataNotFoundObj(args.adToLoad, {
      message: "Missing Store / Account Data",
      error_user_title: "Missing Store / Account Data",
      error_user_msg: `Store / Account data is missing. Try reselecting the store in the Select step.`,
    });
  }

  let createAdCreativeRes;
  let creativeParams: IFacebookAdCreative | undefined;

  try {
    creativeParams =
      args.adToLoad.wasConvertedToVideo && args.adToLoad.facebookAd?.creative
        ? convertCollectionCreativeToVideoCreative(
            args.adToLoad.facebookAd?.creative,
          )
        : await (args.assembleFacebookAdCreative || assembleFacebookAdCreative)(
            {
              adToLoad: args.adToLoad,
              selectedDealer: args.selectedDealer,
              instantExperiences: args.instantExperiences,
              pageId: args.selectedDealer.details.facebook.fbPageId,
            },
          );
  } catch (error) {
    createAdCreativeRes = {
      result: null,
      error: {
        message: getErrorMessage(error),
      },
    };
  }

  if (!createAdCreativeRes) {
    createAdCreativeRes = await (args.createAdCreative?.({
      facebookAdAccount: args.facebookAdAccount,
      creativeParams: creativeParams || {},
    }) || args.facebookAdAccount.createAdCreative(creativeParams || {}));
  }

  const newAdToLoadData: IAdToLoadData = {
    ...args.adToLoad,
    facebookAd: {
      creative: { ...creativeParams, id: createAdCreativeRes.result?.id },
    },
    adLoadStatus: returnAdToLoadStatus({
      adToLoad: args.adToLoad,
      newAdLoadStatus: "loading",
      response: createAdCreativeRes,
    }),
    progressStatus: {
      ...args.adToLoad.progressStatus,
      assembleCreative: returnAdToLoadStatus({
        adToLoad: args.adToLoad,
        newAdLoadStatus: AdLoadAdStatusEnum.SUCCESS,
        response: createAdCreativeRes,
      }).status,
    },
  };

  return newAdToLoadData;
};

export const useAssembleAdCreative = () => useMutation(assembleAdCreative);
