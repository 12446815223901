import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse, Spin } from "antd";
import { FC } from "react";

// types
import {
  TCheckedVariable,
  TVariableGroupName,
} from "../../../shared/types/legalLingo";

import LegalLingoTable from "./LegalLingoTable";

import * as legalHelpers from "utils/helpers.legal";
import { numberAtThisPriceData } from "../../../shared/constants/dataManagement";

// css
import "../../LegalLingo.scss";

interface ILegalLingoState {
  listOfCheckedStates: TCheckedVariable[];
  listOfCheckedNumberAtThisPriceStates?: TCheckedVariable[];
  listOfCheckedLeaseStates: TCheckedVariable[];
  listOfCheckedZeroDownLeaseStates: TCheckedVariable[];
  listOfCheckedFinanceStates: TCheckedVariable[];
  listOfCheckedPurchaseStates: TCheckedVariable[];
  listOfCheckedAPRStates: TCheckedVariable[];

  onChange: (address: [number, string], table: string) => void;
}

const LegalLingoForm: FC<ILegalLingoState> = ({
  listOfCheckedStates,
  listOfCheckedNumberAtThisPriceStates = [],
  listOfCheckedLeaseStates,
  listOfCheckedZeroDownLeaseStates,
  listOfCheckedFinanceStates,
  listOfCheckedPurchaseStates,
  listOfCheckedAPRStates,
  onChange,
}) => {
  const ladTechLegalLingoVarDict = {
    "Vehicle Info": legalHelpers.returnOfferTypeLingoData("Vehicle Info"),
    "Number at this Price": numberAtThisPriceData,
    Lease: legalHelpers.returnOfferTypeLingoData("Lease"),
    "Zero Down Lease": legalHelpers.returnOfferTypeLingoData("Zero Down Lease"),
    Finance: legalHelpers.returnOfferTypeLingoData("Finance"),
    Purchase: legalHelpers.returnOfferTypeLingoData("Purchase"),
    APR: legalHelpers.returnOfferTypeLingoData("APR"),
  };

  const checkedVarsDict: Record<string, TCheckedVariable[]> = {
    "Vehicle Info": listOfCheckedStates,
    "Number at this Price": listOfCheckedNumberAtThisPriceStates,
    Lease: listOfCheckedLeaseStates,
    "Zero Down Lease": listOfCheckedZeroDownLeaseStates,
    Finance: listOfCheckedFinanceStates,
    Purchase: listOfCheckedPurchaseStates,
    APR: listOfCheckedAPRStates,
  };

  const offerTypeKeys = Object.keys(
    ladTechLegalLingoVarDict,
  ) as TVariableGroupName[];

  return (
    <div>
      <Collapse
        bordered={false}
        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        {offerTypeKeys.map((key, index) => (
          <Collapse.Panel header={`${key} Table`} key={`${index + 1}`}>
            <LegalLingoTable
              baseColumns={legalHelpers.stateLegalLingoColumns[key]}
              data={ladTechLegalLingoVarDict[key]}
              onChange={onChange}
              columnTitleProperty="lingo_state"
              listOfCheckedValues={checkedVarsDict[key]}
              tableName={key}
              scroll={5000}
            />
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  );
};
const LegalLingoState: FC<ILegalLingoState> = props => {
  const {
    listOfCheckedStates,
    listOfCheckedLeaseStates,
    listOfCheckedZeroDownLeaseStates,
    listOfCheckedFinanceStates,
    listOfCheckedPurchaseStates,
    listOfCheckedAPRStates,
  } = props;

  if (
    listOfCheckedStates &&
    listOfCheckedLeaseStates &&
    listOfCheckedZeroDownLeaseStates &&
    listOfCheckedFinanceStates &&
    listOfCheckedPurchaseStates &&
    listOfCheckedAPRStates
  ) {
    return <LegalLingoForm {...props} />;
  }

  return (
    <div className="loading">
      <Spin spinning={true} size="large" />;
    </div>
  );
};

export default LegalLingoState;
