import {
  useMemo,
  useEffect,
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react";
import { Button, message, Space, Tooltip, Typography } from "antd";
import {
  ArrowLeftOutlined,
  CheckOutlined,
  LinkOutlined,
  LoadingOutlined,
  UndoOutlined,
} from "@ant-design/icons";

import { useLocation } from "react-router-dom";
import { useIsFetching, useIsMutating, useQueryClient } from "react-query";

import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { setAdLoadState } from "redux/adLibrary/adLibrary.slice";
import { useAppShallowEqualSelector } from "shared/hooks/useAppSelector";

import { useGoToAdLoadStep } from "./shared/hooks/useGoToAdLoadStep";

import { getPreviousAdLoadStep } from "./shared/utils";
import {
  buttonTitlesForInnerStepOpeners,
  disabledBtnTooltipText,
  enabledButtonStyle,
} from "./shared/constants";

import { AdLoadStepKey, LoadStepKeys } from "shared/types/adLibrary";
import styles from "./AdLoadDrawerFooter.module.scss";

const { LOAD_AGAIN, LOADING_ADS, WAITING_FOR_LOAD, FINISHED_LOADING } =
  LoadStepKeys;
const { REVIEW_ADS_TO_LOAD, LOAD_ADS, DESTINATION_SELECTION } = AdLoadStepKey;

interface IAdLoadDrawerFooterProps {
  setIsShareSessionModalOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}

const AdLoadDrawerFooter = ({
  setIsShareSessionModalOpen,
  onClose,
}: IAdLoadDrawerFooterProps) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  const [isSavedSuccess, setIsSavedSuccess] = useState(false);

  const isSavingSession = !!isMutating && !isSavedSuccess;

  const {
    currentStep,
    selectedAds = [],
    adLoadHistory,
    adLoadReview,
    adLoadDestination,
    currentInnerStep,
  } = useAppShallowEqualSelector(({ adLibrary }) => ({
    currentStep: adLibrary.adLoad.currentStep,
    currentInnerStep: adLibrary.adLoad.currentInnerStep,
    selectedAds: adLibrary.adLoad.selectedAds,
    adLoadHistory: adLibrary.adLoad.adLoadHistory,
    adLoadReview: adLibrary.adLoad.review,
    adLoadDestination: adLibrary.adLoad.destination,
  }));

  const { sessionId } = adLoadHistory || {};
  const { selectedRowKeys = [], adsToLoad } = adLoadReview || {};

  const sessionJsonQueryState = queryClient.getQueryState([
    "adHistorySessionJson",
    sessionId,
  ]);

  const [currentUpdateCount, setCurrentUpdateCount] = useState(
    sessionJsonQueryState?.dataUpdateCount ?? 0,
  );
  const [wasLoadToFBStarted, setWasLoadToFBStarted] = useState(false);
  const [proceedToNextStep, setProceedToNextStep] = useState(false);

  const isOnSessionsPage = location.pathname === "/ad-library/sessions";

  const goToAdLoadStep = useGoToAdLoadStep();

  useEffect(() => {
    setIsSavedSuccess(
      (sessionJsonQueryState?.dataUpdateCount ?? 0) > currentUpdateCount,
    );
    setCurrentUpdateCount(sessionJsonQueryState?.dataUpdateCount ?? 0);
  }, [currentUpdateCount, sessionJsonQueryState]);

  useEffect(() => {
    if (!wasLoadToFBStarted) return;
    message.info("Loading process started.");
    dispatch(
      setAdLoadState({
        prop: "currentInnerStep",
        data: LOADING_ADS,
      }),
    );
    setWasLoadToFBStarted(false);
  }, [dispatch, wasLoadToFBStarted]);

  useEffect(() => {
    if (!isSavingSession && proceedToNextStep) {
      goToAdLoadStep({ adLoadStep: REVIEW_ADS_TO_LOAD });
      setProceedToNextStep(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSavingSession, proceedToNextStep]);

  const canLoadAds = useCallback(() => {
    const thereAreAdsToLoad =
      (adLoadReview?.adsToLoad?.filter(adToLoad => {
        return selectedAds?.find(
          selectedAd => selectedAd.id === adToLoad.ad.id,
        );
      })?.length ?? 0) > 0;

    return thereAreAdsToLoad;
  }, [adLoadReview?.adsToLoad, selectedAds]);

  const currentOkButtonProps = useMemo(() => {
    const rowKeys = selectedRowKeys.filter(key =>
      adsToLoad?.some(adToLoad => key === adToLoad.key),
    );
    const adsText = `Load ${rowKeys.length} Ad${
      rowKeys.length !== 1 ? "s" : ""
    }`;
    if (currentStep === REVIEW_ADS_TO_LOAD) {
      return {
        children: `${adsText} to Facebook`,
        disabled: !rowKeys.length,
        style: rowKeys.length ? enabledButtonStyle : undefined,
        onClick: () => {
          goToAdLoadStep({
            adLoadStep: LOAD_ADS,
            wasLoadButtonClicked: true,
            setWasLoadToFBStarted,
          });
        },
      };
    }
    if (currentStep === LOAD_ADS) {
      const children =
        currentInnerStep === LOAD_AGAIN ? (
          <Space>
            <UndoOutlined />
            <span>{buttonTitlesForInnerStepOpeners[LOAD_AGAIN]}</span>
          </Space>
        ) : (
          buttonTitlesForInnerStepOpeners[
            currentInnerStep ?? WAITING_FOR_LOAD
          ] ?? adsText
        );
      return {
        children: children,
        style:
          currentInnerStep !== LOADING_ADS ? enabledButtonStyle : undefined,
        disabled: currentInnerStep === LOADING_ADS,
        onClick: () => {
          if (currentInnerStep === FINISHED_LOADING) {
            onClose();
            return;
          }
          message.info("Loading process started.");
          dispatch(
            setAdLoadState({
              prop: "currentInnerStep",
              data: LOADING_ADS,
            }),
          );
        },
      };
    }
    if (isOnSessionsPage) {
      return {
        children: "Done",
        onClick: () => onClose(),
        style: enabledButtonStyle,
      };
    }
    return {
      style: canLoadAds() ? enabledButtonStyle : undefined,
      children: proceedToNextStep ? (
        <Space>
          <LoadingOutlined />
          <span>Saving...</span>
        </Space>
      ) : (
        "Preview load"
      ),
      disabled: proceedToNextStep || !canLoadAds(),
      onClick: () => {
        setProceedToNextStep(true);
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location,
    isFetching,
    selectedAds,
    currentStep,
    isSavingSession,
    selectedRowKeys,
    adsToLoad,
    currentInnerStep,
    adLoadDestination?.treeData,
  ]);

  const goToPreviousStep = () => {
    goToAdLoadStep({
      adLoadStep: getPreviousAdLoadStep(currentStep),
    });
  };

  const openShareSessionModal = () => {
    if (!sessionId) {
      message.warn("Session URL could not be generated");
    }
    setIsShareSessionModalOpen(true);
  };

  const willShowClose = currentStep === DESTINATION_SELECTION || !currentStep;

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div className={`${styles.footerDiv} ${styles.leftDiv}`}>
        {!isOnSessionsPage && (
          <Button
            onClick={willShowClose ? onClose : goToPreviousStep}
            icon={!willShowClose && <ArrowLeftOutlined />}
          >
            {willShowClose ? "Close" : "Back"}
          </Button>
        )}
        <Button
          type="link"
          icon={<LinkOutlined />}
          onClick={openShareSessionModal}
        >
          Share
        </Button>
        <Space
          style={{
            opacity: isSavedSuccess || isSavingSession ? 1 : 0,
          }}
        >
          {!isSavingSession && <CheckOutlined style={{ color: "#52C41A" }} />}
          {isSavingSession && <LoadingOutlined />}
          <Typography.Text
            style={!isSavingSession ? { marginRight: 13 } : undefined}
          >
            {!isSavingSession && "Saved"}
            {isSavingSession && "Saving..."}
          </Typography.Text>
        </Space>
      </div>
      <div
        className={`${styles.footerDiv} ${styles.rightDiv}${
          willShowClose ? ` ${styles.selectStepRightDiv}` : ""
        }`}
      >
        <Tooltip
          placement="topLeft"
          title={
            currentOkButtonProps.disabled && currentStep
              ? disabledBtnTooltipText[currentStep]
              : ""
          }
        >
          <Button {...currentOkButtonProps} />
        </Tooltip>
      </div>
    </div>
  );
};

export default AdLoadDrawerFooter;
