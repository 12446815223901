import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  AdType,
  IFacebookAd,
} from "../../../../../adLibrary/facebookUtils/types";

import { Alert, message } from "antd";
import Title from "antd/lib/typography/Title";
import { first } from "lodash";
import { MOBILE_WIDTH } from "screens/campaignManagement/adReview/adCardListContainer/facebookAdCard/constants";
import cardStyles from "shared/components/Card.module.scss";
import LivePreviewHandler from "shared/components/LivePreviewHandler";
import { useGetAdPreviewDimensions } from "shared/hooks/adLibrary/adReview/useGetAdPreviewDimensions";
import { useRouteQuery } from "shared/hooks/useRouteQuery";
import useSelectedPreviewFormat from "shared/hooks/userPreferences/useSelectedPreviewFormat";
import { AdReviewTableEntry } from "shared/types/campaignManagement";
import AdPreview from "../../../adCardListContainer/facebookAdCard/AdPreview";
import { getCanvasId } from "../../utils/helpers.ad";
import styles from "../AdDetailsDrawer.module.scss";
import { useFetchInstantExperiences } from "./AdPreviewSelector/useFetchInstantExperiences";

interface IProps {
  facebookAdsFromEntry: IFacebookAd[] | undefined;
  selectedEntry: AdReviewTableEntry;
  displayPreview?: boolean;
}

type AdPreviewSelectorProps = IProps;

const AdPreviewSelector = ({
  facebookAdsFromEntry,
  selectedEntry,
  displayPreview,
}: AdPreviewSelectorProps) => {
  const { previewFormat } = useSelectedPreviewFormat();
  const sharedAdReviewId = useRouteQuery("sharedAdReviewId") ?? undefined;

  const selectedAd = useMemo(
    () => first(facebookAdsFromEntry),
    [facebookAdsFromEntry],
  );

  const selectedInstantExperienceId = useMemo(
    () => (selectedAd && getCanvasId(selectedAd)) || "",
    [selectedAd],
  );

  const [
    selectedInstantExperienceIdsPath,
    setSelectedInstantExperienceIdsPath,
  ] = useState<string[]>([selectedInstantExperienceId]);

  const shouldDisplayIEPreview =
    !!selectedEntry.adFormat &&
    AdType.InstantExperience === selectedEntry.adFormat &&
    displayPreview;

  const { dimensions, isLoadingFirstTime: isLoadingDimensionsFirstTime } =
    useGetAdPreviewDimensions(selectedAd?.id, previewFormat, selectedAd?.type);

  const adPreviewData = selectedAd?.mobilePreview?.data[0].body;

  const { data: instantExperiences, error: fetchInstantExperiencesError } =
    useFetchInstantExperiences(
      selectedAd?.id,
      sharedAdReviewId,
      shouldDisplayIEPreview,
    );

  const handleInstantExperiencesError = useCallback(() => {
    if (fetchInstantExperiencesError) {
      message.error("Could not fetch Instant Experiences.");
    }
  }, [fetchInstantExperiencesError]);

  useEffect(handleInstantExperiencesError, [handleInstantExperiencesError]);

  const wrapperStyles: CSSProperties = {
    ...(dimensions || {}),
    width: MOBILE_WIDTH,
    maxHeight: "fit-content",
    height: "fit-content",
    backgroundColor: "#ffffff",
  };

  const alertMessage = shouldDisplayIEPreview
    ? "Tapping each section below takes you to the Destination URLs listed on the right"
    : "Tapping the CTA below takes you to the Everything Ad on the right";

  return selectedAd ? (
    <div className={styles.module}>
      <Title style={{ margin: 0 }} level={4} className={styles.title}>
        {!displayPreview && <b>Preview</b>}
      </Title>
      <div className={styles.moduleContent}>
        {AdType.InstantExperience === selectedEntry.adFormat && (
          <Alert
            message={alertMessage}
            type="info"
            showIcon
            className={styles.alert}
          />
        )}
        {shouldDisplayIEPreview ? (
          <LivePreviewHandler
            adType={selectedEntry.adFormat!}
            selectedInstantExperienceIdsPath={selectedInstantExperienceIdsPath}
            setSelectedInstantExperienceIdsPath={
              setSelectedInstantExperienceIdsPath
            }
            instantExperienceId={selectedInstantExperienceId}
            instantExperiences={instantExperiences}
          />
        ) : (
          <div className={cardStyles.card} style={wrapperStyles}>
            <AdPreview
              adType={selectedAd.type}
              dimensions={{ ...dimensions, width: MOBILE_WIDTH }}
              isLoadingDimensionsFirstTime={isLoadingDimensionsFirstTime}
              facebookAd={selectedAd}
              preloadedIframe={adPreviewData}
            />
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default AdPreviewSelector;
