import { Form } from "antd";
import { FieldData } from "rc-field-form/lib/interface";
import { useMemo } from "react";
import { getSearchFields } from "../getSearchFields";
import { useMetaSchema } from "../useMetaSchema";
import { useQPSearch } from "../useQPSearch";

export const useForm = () => {
  const [form] = Form.useForm();
  const { searchState, setSearchState } = useQPSearch();
  const { schema } = useMetaSchema();
  const formFields = useMemo(
    () => getFormFields(searchState, schema),
    [searchState, schema],
  );

  const onValuesChange = (changedValues: any, values: MGFormValues) => {
    const fields = getSearchFields(values);
    setSearchState({ filters: fields.length ? { fields } : undefined });
  };

  const resetForm = () => {
    form.resetFields();
    setSearchState({ filters: undefined });
  };

  return {
    formFields,
    onValuesChange,
    resetForm,
    searchState,
    form,
    schema,
  };
};

const getFormFields = (
  searchState?: WDSearchPayload,
  schemas?: MGSchemas,
): FieldData[] | undefined => {
  if (schemas) {
    const fields = searchState?.filters?.fields ?? [];

    return fields.map(field => {
      return {
        name: field.field_name,
        value: field.value,
      };
    });
  }
};
