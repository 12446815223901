import { Key } from "react";
import omit from "lodash/omit";
import { AdFormat } from "screens/adLibrary/facebookUtils/types";
import {
  AudienceIntegrationLog,
  GetAudienceIntegrationLogsResponse,
  IUpdateLogParams,
} from "screens/campaignManagement/audienceManager/types";
import {
  IAd,
  ICreateOrUpdateAdReqBody,
  IDeleteAdResponseData,
  IGetAdsResponseData,
  IGetAdLoadRulesPresetsQueryParams,
  IGetAdLoadRulesPresetsResponseData,
  IRulesPreset,
  IModifyAdLoadRulesPresetsResponseData,
  AdLoadModifyRulesPresetOperation,
  IGetAdHistorySessionsQueryParams,
  IGetAdHistorySessionsResponseData,
  IAdHistorySession,
  IModifyAdHistorySessionResponseData,
  IInstantExperience,
  IGetInstantExperiencesResult,
  OperationType,
  IModifyInstantExperienceResult,
  GetAdsQueryParams,
  IInputParameters,
  IVisuals,
  IBatchCreateOrUpdateAdReqBody,
  IAdShell,
  ICreateOrUpdateAdResponseData,
  IModifyCommentResult,
  IGetCommentsResult,
  IGetCommentsParams,
  IBatchModifyInstantExperiencesResult,
  IGetInstantExperienceResult,
  IUpdatedAssetGroup,
  IAssetGroupResponse,
  IAudienceSignalResponse,
  IGoogleAdAccountResponse,
  GoogleGeoTargetsResponse,
  IGoogleCampaignResponse,
  IVideoAsset,
  IAssetGroup,
  IExportGetFileUrlResponse,
  IExportDataPayload,
  IExportSignedUrlResponse,
  UpdateAssetGroupsResponse,
  ImportStartExecutionResponseData,
  ImportDescribeExecutionResponseData,
  EverythingAdHtml,
  IsEverythingAdHtmlSlugAvailableResponse,
  NewAssetGroupArgs,
  URLExclusion,
  CreatePmaxCampaignArgs,
  UploadPmaxCampaignsArgs,
  UpdatePmaxCampaignArgs,
  GoogleAdCampaignInternal,
  UploadGoogleAdCampaignOperationResponse,
  GetPmaxUploadStatusResponse,
  UploadPmaxAssetGroupsArgs,
  dateFormat,
} from "shared/types/adLibrary";
import {
  IAdPreviewDimensions,
  PdfInputPayload,
  PdfStartExecRes,
  PdfStatusRes,
} from "shared/types/adReview";
import { IConfig } from "shared/types/configuration";
import {
  ICreateLabelReqBody,
  ICreateLabelResponse,
  IGetLabelsResponse,
} from "shared/types/inputValues";
import {
  IApiResponse,
  IComment,
  IGetS3ObjectVersionsQueryParams,
  IGetS3ObjectVersionsResponseData,
  IGetVersionOfJsonFromS3QueryParams,
  IGetVersionOfJsonFromS3ResponseData,
} from "shared/types/shared";
import { toAssetGroup } from "utils/adLibrary.pmax";
import HttpClient from "./httpClient";
import { IDataTableError } from "shared/types/errors";
import {
  BatchDeliveryMarketingMaterials,
  DeliveryShippingRatesInput,
  DownloadDeliveryInput,
  EmailDeliveryInput,
  FedexShippingMethod,
  MarketingMaterial,
  MarketingMaterialPrintDeliveryInput,
} from "shared/types/marketingMaterials";
import { format } from "date-fns";

export default ({ config }: { headers: any; config: IConfig }) => ({
  getAds: (
    args?: GetAdsQueryParams,
  ): Promise<IApiResponse<IGetAdsResponseData>> => {
    return HttpClient.get(config.services.adLibrary.adsUrl(args));
  },

  createOrUpdateAd: async (
    ad: IAd,
  ): Promise<IApiResponse<ICreateOrUpdateAdResponseData>> => {
    const createOrUpdateAdUrl = config.services.adLibrary.adsUrl();
    const makePostRequest = (body: ICreateOrUpdateAdReqBody) =>
      HttpClient.post<IApiResponse<ICreateOrUpdateAdResponseData>>(
        createOrUpdateAdUrl,
        body,
      );

    const { visuals, inputParameters, updatedBy } = ad;

    const shell = omit(ad, [
      "visuals",
      "inputParameters",
      "instances",
      "planners",
    ]);

    const shellBody: ICreateOrUpdateAdReqBody = {
      adPart: "shell",
      adData: shell,
      updatedBy,
    };

    const inputsBody: ICreateOrUpdateAdReqBody = {
      adPart: "inputs",
      adData: inputParameters,
      updatedBy,
    };
    const visualsBody: ICreateOrUpdateAdReqBody = {
      adPart: "visuals",
      adData: visuals,
      updatedBy,
    };

    const [shellResponse] = await Promise.all([
      makePostRequest(shellBody),
      makePostRequest(inputsBody),
      makePostRequest(visualsBody),
    ]);

    return shellResponse; // TODO: make response with all results
  },
  batchCreateOrUpdateAds: async (
    ads: IAd[],
  ): Promise<IApiResponse<ICreateOrUpdateAdResponseData[]>> => {
    const batchCreateOrUpdateAdUrl = config.services.adLibrary.batchAdsUrl;

    const makePostRequest = (body: IBatchCreateOrUpdateAdReqBody) =>
      HttpClient.post<IApiResponse<ICreateOrUpdateAdResponseData[]>>(
        batchCreateOrUpdateAdUrl,
        body,
      );

    type AdParts = {
      visualsList: IVisuals[];
      inputParametersList: IInputParameters[];
      shellList: IAdShell[];
    };

    // All ads have the same updatedBy
    const updatedBy = ads[0].updatedBy;
    const { visualsList, inputParametersList, shellList } = ads.reduce<AdParts>(
      (acc, ad) => {
        const { visuals, inputParameters, ...shell } = ad;
        const [startDate, endDate] = getRunDates(shell);
        return {
          visualsList: [...acc.visualsList, visuals],
          inputParametersList: [...acc.inputParametersList, inputParameters],
          shellList: [
            ...acc.shellList,
            {
              ...shell,
              runDatesStr: toRunDatesString([startDate, endDate]), // end date is required and validated in RunDate.tsx
            },
          ],
        };
      },
      { visualsList: [], inputParametersList: [], shellList: [] },
    );

    const shellBody: IBatchCreateOrUpdateAdReqBody = {
      adPart: "shell",
      adsData: shellList,
      updatedBy,
    };
    const inputsBody: IBatchCreateOrUpdateAdReqBody = {
      adPart: "inputs",
      adsData: inputParametersList,
      updatedBy,
    };
    const visualsBody: IBatchCreateOrUpdateAdReqBody = {
      adPart: "visuals",
      adsData: visualsList,
      updatedBy,
    };

    const [shellResponse] = await Promise.all([
      makePostRequest(shellBody),
      makePostRequest(inputsBody),
      makePostRequest(visualsBody),
    ]);

    return shellResponse; // TODO: make response with all results
  },

  createOrUpdateAdShell: async (
    ad: IAd,
  ): Promise<IApiResponse<ICreateOrUpdateAdResponseData>> => {
    const createOrUpdateAdUrl = config.services.adLibrary.adsUrl();
    const { updatedBy } = ad;
    const body: ICreateOrUpdateAdReqBody = {
      adPart: "shell",
      adData: omit(ad, ["visuals", "inputParameters"]),
      updatedBy: updatedBy,
    };

    return HttpClient.post<IApiResponse<ICreateOrUpdateAdResponseData>>(
      createOrUpdateAdUrl,
      body,
    );
  },
  batchCreateOrUpdateAdShells: async (
    ads: IAd[],
  ): Promise<IApiResponse<ICreateOrUpdateAdResponseData[]>> => {
    const batchCreateOrUpdateAdUrl = config.services.adLibrary.batchAdsUrl;
    // All ads have the same updatedBy
    const updatedBy = ads[0].updatedBy;
    const adShells = ads.map(ad => omit(ad, ["visuals", "inputParameters"]));
    const body: IBatchCreateOrUpdateAdReqBody = {
      adPart: "shell",
      adsData: adShells,
      updatedBy,
    };

    return HttpClient.post<IApiResponse<ICreateOrUpdateAdResponseData[]>>(
      batchCreateOrUpdateAdUrl,
      body,
    );
  },

  deleteAd: (adId: string): Promise<IApiResponse<IDeleteAdResponseData>> => {
    return HttpClient.delete(config.services.adLibrary.adsUrl(), {
      id: adId,
    });
  },

  getLabels: (query?: string): Promise<IGetLabelsResponse> => {
    return HttpClient.get(
      `${config.services.labelsUrl}${query ? `?${query}` : ""}`,
    );
  },

  createLabel: async (
    createLabelReqBody: ICreateLabelReqBody,
  ): Promise<ICreateLabelResponse> => {
    return HttpClient.post(config.services.labelsUrl, createLabelReqBody);
  },

  platformProxyRequest<TResult, TError = IDataTableError>(
    body: any,
  ): Promise<IApiResponse<TResult, TError>> {
    return HttpClient.post(config.services.platformProxyUrl, body);
  },

  getAdLoadRulesPresets: async (
    queryParams?: IGetAdLoadRulesPresetsQueryParams,
  ): Promise<IApiResponse<IGetAdLoadRulesPresetsResponseData>> => {
    return HttpClient.get(
      config.services.adLibrary.adLoad.getRulesPresetsUrl(queryParams),
    );
  },

  modifyAdLoadRulesPreset: async (
    rulesPreset: IRulesPreset,
    operation: AdLoadModifyRulesPresetOperation,
  ): Promise<IApiResponse<IModifyAdLoadRulesPresetsResponseData>> => {
    return HttpClient.post(
      config.services.adLibrary.adLoad.getRulesPresetsUrl(),
      { rulesPreset, operation },
    );
  },

  getAdHistorySessions: async (
    queryParams?: IGetAdHistorySessionsQueryParams,
  ): Promise<IApiResponse<IGetAdHistorySessionsResponseData>> => {
    return HttpClient.get(
      config.services.adLibrary.adHistory.getSessionsUrl(queryParams),
    );
  },

  getAdHistorySessionsViaPost: async (
    queryParams?: IGetAdHistorySessionsQueryParams,
  ): Promise<IApiResponse<IGetAdHistorySessionsResponseData>> => {
    return HttpClient.post(
      config.services.adLibrary.adHistory.getSessionsPageUrl,
      { ...queryParams },
    );
  },

  modifyAdHistorySessions: async (
    session: IAdHistorySession,
    operation: AdLoadModifyRulesPresetOperation,
  ): Promise<IApiResponse<IModifyAdHistorySessionResponseData>> => {
    return HttpClient.post(
      config.services.adLibrary.adHistory.getSessionsUrl(),
      { session, operation },
    );
  },

  // Possible Future TO DO: move this to a more general service group
  getS3ObjectVersions: async (
    queryParams?: IGetS3ObjectVersionsQueryParams,
  ): Promise<IApiResponse<IGetS3ObjectVersionsResponseData>> => {
    return HttpClient.get(config.services.getS3ObjectVersionsUrl(queryParams));
  },

  // Possible Future TO DO: move this to a more general service group
  getVersionOfJsonFromS3: async (
    queryParams?: IGetVersionOfJsonFromS3QueryParams,
  ): Promise<IApiResponse<IGetVersionOfJsonFromS3ResponseData>> => {
    return HttpClient.get(
      config.services.getVersionOfJsonFromS3Url(queryParams),
    );
  },
  getInstantExperiences: (): Promise<
    IApiResponse<IGetInstantExperiencesResult>
  > => {
    return HttpClient.get(config.services.adLibrary.instantExperiencesUrl);
  },
  getInstantExperience: (
    id: string,
  ): Promise<IApiResponse<IGetInstantExperienceResult>> => {
    const url = `${config.services.adLibrary.instantExperiencesUrl}?id=${id}`;
    return HttpClient.get(url);
  },
  modifyInstantExperiences: (
    instantExperience: IInstantExperience,
    operation: OperationType,
  ): Promise<IApiResponse<IModifyInstantExperienceResult>> => {
    return HttpClient.post(config.services.adLibrary.instantExperiencesUrl, {
      instantExperience,
      operation,
    });
  },
  batchModifyInstantExperiences: (
    instantExperiences: IInstantExperience[],
  ): Promise<IApiResponse<IBatchModifyInstantExperiencesResult>> => {
    return HttpClient.post(
      config.services.adLibrary.batchInstantExperiencesUrl,
      {
        instantExperiences,
      },
    );
  },

  // TODO: add objectId and timestamp
  getComments: (
    params: IGetCommentsParams,
  ): Promise<IApiResponse<IGetCommentsResult>> => {
    return HttpClient.get(config.services.commentsUrl(params));
  },
  modifyComment: (
    comment: IComment,
    operation: OperationType,
  ): Promise<IApiResponse<IModifyCommentResult>> => {
    return HttpClient.post(config.services.commentsUrl(), {
      comment,
      operation,
    });
  },
  generatePdfStartExecution: async (
    pafData: PdfInputPayload,
  ): Promise<PdfStartExecRes> => {
    const result = await HttpClient.post<PdfStartExecRes>(
      config.services.pdfGenerator.generatePdfExecutionsUrl,
      pafData,
    );

    return getResultIfOk(result);
  },
  generatePdfDescribeExecution: async (
    execName: string,
  ): Promise<PdfStatusRes> => {
    const result = await HttpClient.get<PdfStatusRes>(
      `${config.services.pdfGenerator.generatePdfExecutionsUrl}/${execName}`,
    );

    return getResultIfOk(result);
  },
  getAdPreviewDimensions: (
    adId: string,
    adFormat: AdFormat,
  ): Promise<IApiResponse<IAdPreviewDimensions>> => {
    return HttpClient.get(
      `${config.services.adLibrary.adReview.getAdPreviewDimensionsUrl}?adId=${adId}&adFormat=${adFormat}`,
      undefined,
      false,
    );
  },
  pmaxLoad: async (
    args: UploadPmaxCampaignsArgs | UploadPmaxAssetGroupsArgs,
  ) => {
    const url = config.services.adLibrary.pmax.load;
    return HttpClient.post<
      IApiResponse<UploadGoogleAdCampaignOperationResponse>
    >(url, args);
  },
  getPmaxUploadStatus: async (executionId: string) => {
    const url = `${config.services.adLibrary.pmax.load}/${executionId}`;
    return HttpClient.get<IApiResponse<GetPmaxUploadStatusResponse>>(url);
  },
  createPmaxCampaigns: async (args: CreatePmaxCampaignArgs) => {
    const url = config.services.adLibrary.pmax.internal.campaigns;
    return HttpClient.post<IApiResponse<GoogleAdCampaignInternal[]>>(url, args);
  },
  updatePmaxCampaign: (args: UpdatePmaxCampaignArgs) => {
    const url = `${config.services.adLibrary.pmax.internal.campaigns}/${args.campaign.id}`;
    return HttpClient.put<IApiResponse<GoogleAdCampaignInternal>>(url, args);
  },
  deletePmaxcampaigns: (args: { ids: Key[] }) => {
    const url = config.services.adLibrary.pmax.internal.campaigns;
    return HttpClient.delete<IApiResponse<GoogleAdCampaignInternal[]>>(
      url,
      args,
    );
  },
  getPmaxCampaigns: () => {
    const url = config.services.adLibrary.pmax.internal.campaigns;
    return HttpClient.get<IApiResponse<GoogleAdCampaignInternal[]>>(url);
  },
  createAssetGroup: async (args: NewAssetGroupArgs) => {
    const { assetGroup, user, mode } = args;
    const url = config.services.adLibrary.pmax.internal.assetGroups;
    const { result, error } = await HttpClient.post<
      IApiResponse<IAssetGroupResponse>
    >(url, {
      assetGroup,
      user,
      mode,
    });

    if (!result || error) {
      return {
        result: null,
        error: error ?? { message: "Something went wrong" },
      };
    }

    return { result: toAssetGroup(result), error: null };
  },
  updateAssetGroup: async (args: {
    assetGroup: Partial<IUpdatedAssetGroup> | Partial<IAssetGroup>;
    user: { email: string };
  }) => {
    const { assetGroup, user } = args;
    const url = config.services.adLibrary.pmax.internal.assetGroups;
    const { result, error } = await HttpClient.put<
      IApiResponse<IAssetGroupResponse>
    >(url, {
      assetGroup,
      user,
    });

    if (!result || error) {
      return {
        result: null,
        error,
      };
    }

    return { result: toAssetGroup(result), error: null };
  },
  updateAssetGroupStatus: async (assetGroup: Partial<IAssetGroup>) => {
    const { id, feedStatus, pmaxStatus } = assetGroup;
    const url = config.services.adLibrary.pmax.internal.assetGroupStatus;
    const { result, error } = await HttpClient.put<
      IApiResponse<IAssetGroupResponse>
    >(url, {
      id,
      feedStatus,
      pmaxStatus,
    });

    if (!result || error) {
      return {
        result: null,
        error,
      };
    }

    return toAssetGroup(result);
  },

  getAssetGroups: async ({ paginationKey }: { paginationKey?: string }) => {
    const url = config.services.adLibrary.pmax.internal.assetGroups;
    const { result, error } = await HttpClient.get<
      IApiResponse<{
        assetGroups: IAssetGroupResponse[];
        paginationKey?: string;
      }>
    >(`${url}?paginationKey=${paginationKey ?? ""}`);

    if (!result || error) {
      return { assetGroups: [], paginationKey: undefined };
    }

    return {
      assetGroups: result.assetGroups.map(toAssetGroup),
      paginationKey: result.paginationKey,
    };
  },
  deleteAssetGroup: async (assetGroupId: string) => {
    const url = `${
      config.services.adLibrary.pmax.internal.assetGroups
    }/${encodeURIComponent(assetGroupId)}`;
    return await HttpClient.delete<IApiResponse<string>>(url);
  },
  getAudienceSignals: (adAccountId?: string) => {
    const url = `${config.services.adLibrary.pmax.getAudienceSignals}?adAccountId=${adAccountId}`;
    return HttpClient.get<IApiResponse<IAudienceSignalResponse>>(url);
  },
  getGoogleAdAccounts: () => {
    const url = config.services.adLibrary.pmax.getAdAccounts;
    return HttpClient.get<IApiResponse<IGoogleAdAccountResponse>>(url);
  },
  getGoogleGeoTargets: () => {
    const url = config.services.adLibrary.pmax.getGeoTargets;
    return HttpClient.get<IApiResponse<GoogleGeoTargetsResponse>>(url);
  },
  getURLExclusions: () => {
    const url = config.services.adLibrary.pmax.internal.config.urlExclusions;
    return HttpClient.get<IApiResponse<URLExclusion[]>>(url);
  },
  getGoogleCampaigns: (adAccountId: string) => {
    const url = `${config.services.adLibrary.pmax.getCampaigns}?adAccountId=${adAccountId}`;
    return HttpClient.get<IApiResponse<IGoogleCampaignResponse>>(url);
  },
  uploadYoutubeVideos: (videoAssets: IVideoAsset[], oem: string) => {
    const url = config.services.adLibrary.youtube.uploadVideos;
    return HttpClient.post<IApiResponse<any>>(url, {
      videoAssets,
      oem,
    });
  },
  getYoutubeChannels: (oem: string) => {
    const url = `${config.services.adLibrary.youtube.getChannels}?oem=${oem}`;
    return HttpClient.get<IApiResponse<any>>(url);
  },
  getYoutubeAccounts: (oem: string) => {
    const url = `${config.services.adLibrary.youtube.getAccounts}?oem=${oem}`;
    return HttpClient.get<IApiResponse<any>>(url);
  },
  // Updates Pmax status in Google Ads
  updatePmaxAssetGroups: (assetGroups: IAssetGroup[]) => {
    const url = config.services.adLibrary.pmax.assetGroups;
    return HttpClient.put<IApiResponse<UpdateAssetGroupsResponse>>(
      url,
      assetGroups,
    );
  },
  getExportUploadSignedUrl: (uuid: string) => {
    const url = config.services.adLibrary.export.getUploadSignedUrl;
    return HttpClient.post<IApiResponse<IExportSignedUrlResponse>>(url, {
      uuid,
    });
  },
  uploadExportData: (url: string, data: IExportDataPayload) => {
    return HttpClient.put<void>(url, data, undefined, false);
  },
  getExportGeneratedFileUrl: (uuid: string) => {
    const url = `${config.services.adLibrary.export.getGeneratedFileUrl}/${uuid}`;
    return HttpClient.get<IApiResponse<IExportGetFileUrlResponse>>(url);
  },
  importStartExecution: (
    url: string,
    adAccountId?: string,
  ): Promise<IApiResponse<ImportStartExecutionResponseData>> => {
    return HttpClient.post(config.services.adLibrary.importUrl, {
      url,
      adAccountId,
    });
  },
  importDescribeExecution: (
    executionArn: string,
  ): Promise<IApiResponse<ImportDescribeExecutionResponseData>> => {
    return HttpClient.get(
      `${config.services.adLibrary.importUrl}/${executionArn}`,
    );
  },
  getAudienceIntegrationLogs: (next?: string) => {
    return HttpClient.get<IApiResponse<GetAudienceIntegrationLogsResponse>>(
      config.services.audienceManager.audienceIntegrationLogs({ next }),
    );
  },
  updateAudienceIntegrationLog: (id: string, body: IUpdateLogParams) => {
    return HttpClient.put<IApiResponse<AudienceIntegrationLog>>(
      `${config.services.audienceManager.audienceIntegrationLogs()}/${id}`,
      body,
    );
  },
  createEverythingAdHtml: async (everythingAdHtml: EverythingAdHtml) => {
    const result = await HttpClient.post<EverythingAdHtml>(
      config.services.adLibrary.everythingAdsHtmlUrl,
      everythingAdHtml,
    );

    return getResultIfOk(result);
  },
  getIsEverythingAdHtmlSlugAvailable: async (slug: string) => {
    const result =
      await HttpClient.get<IsEverythingAdHtmlSlugAvailableResponse>(
        `${config.services.adLibrary.everythingAdsHtmlUrl}/${slug}/is-available`,
      );

    return getResultIfOk(result);
  },
  getEverythingAdHtmlItemsByEaId: async (eaId: string) => {
    const result = await HttpClient.get<EverythingAdHtml[]>(
      `${config.services.adLibrary.everythingAdsHtmlUrl}/by-ea-id/${eaId}`,
    );

    return getResultIfOk(result);
  },
  uploadImageToFb: async (imageUrl: string, accountId: string) => {
    const result = await HttpClient.post<{
      imageHash: string;
    }>(config.services.adLibrary.adLoad.uploadImageToFbUrl, {
      imageUrl,
      accountId,
    });

    return getResultIfOk(result);
  },
  updateEverythingAdHtmlData: async (everythingAdHtml: EverythingAdHtml) => {
    const result = await HttpClient.put<EverythingAdHtml>(
      `${config.services.adLibrary.everythingAdsHtmlUrl}/${everythingAdHtml.slug}`,
      everythingAdHtml,
    );

    return getResultIfOk(result);
  },

  deleteEverythingAdHtml: async (slug: string) => {
    const result = await HttpClient.delete<EverythingAdHtml>(
      `${config.services.adLibrary.everythingAdsHtmlUrl}/${slug}`,
    );

    return getResultIfOk(result);
  },

  deliveryMarketingMaterial: async (
    deliveryMarketingMaterials: EmailDeliveryInput,
  ) => {
    const result = await HttpClient.post<{ message: string }>(
      config.services.adLibrary.marketingMaterialsUrl + "/delivery",
      deliveryMarketingMaterials,
    );
    return result?.message ?? result;
  },

  deliverMarketingMaterials: async (deliveries: {
    deliveries: DownloadDeliveryInput[];
  }) => {
    const result = await HttpClient.post<BatchDeliveryMarketingMaterials>(
      config.services.adLibrary.archivesUrl,
      deliveries,
    );

    return getResultIfOk(result);
  },

  deliveryMarketingMaterialByPrint: async (
    printDelivery: MarketingMaterialPrintDeliveryInput,
  ) => {
    const result = await HttpClient.post<IApiResponse<string>>(
      config.services.adLibrary.archivesUrl + "/print",
      printDelivery,
    );

    return getResultIfOk(result);
  },

  createMarketingMaterial: async (marketingMaterial: MarketingMaterial) => {
    const result = await HttpClient.post<MarketingMaterial>(
      config.services.adLibrary.marketingMaterialsUrl,
      marketingMaterial,
    );

    return getResultIfOk(result);
  },
  updateMarketingMaterial: async (marketingMaterial: MarketingMaterial) => {
    const result = await HttpClient.put<MarketingMaterial>(
      `${config.services.adLibrary.marketingMaterialsUrl}/${marketingMaterial.id}`,
      marketingMaterial,
    );

    return getResultIfOk(result);
  },
  deleteMarketingMaterial: async (ids: string[]) => {
    return HttpClient.post<string>(
      `${config.services.adLibrary.marketingMaterialsUrl}/delete`,
      {
        ids,
      },
    );
  },
  marketingMaterialDeliveryShippingMethods: async (
    shippingData: DeliveryShippingRatesInput,
  ) => {
    const result = await HttpClient.post<FedexShippingMethod[]>(
      config.services.adLibrary.marketingMaterialsUrl +
        "/delivery/shipping-methods",
      shippingData,
    );

    return getResultIfOk(result);
  },
});

/**
 * This is a workaround, because the HttpClient doesn't throw an error if the response is not ok.
 * TODO: Remove this function when the HttpClient is fixed.
 */
export function getResultIfOk<
  T extends Record<string, unknown> | Record<string, unknown>[] | IApiResponse,
>(result: T): T {
  if ("message" in result && result.message) {
    throw result;
  }

  return result;
}

export const getRunDates = (shell: IAdShell) => {
  const [startDate, endDate] = shell.runDates ?? [];
  return [startDate ?? null, endDate ?? null];
};

export const toRunDatesString = (runDates?: [Date | null, Date | null]) => {
  const [startDate, endDate] = runDates ?? [];
  if (!endDate) return;

  return `${startDate ? format(startDate, dateFormat) : ""},${format(
    endDate,
    dateFormat,
  )}`;
};
