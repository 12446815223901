import { Select, Badge } from "antd";
import { qcStatusOptions, getColorFromQcStatus } from "screens/adLibrary/utils";
import { QcStatus } from "shared/types/adLibrary";

type Props = {
  status?: QcStatus;
  onChange?: (value: QcStatus) => void;
  previousStatus?: QcStatus;
  notifyQcStatusChange: (value: { detail: QcStatus; change: QcStatus }) => void;
  isValid: boolean;
};

const QCStatusSelector = ({
  status,
  onChange,
  previousStatus,
  notifyQcStatusChange,
  isValid,
}: Props) => (
  <Select
    value={isValid ? status : QcStatus.ERROR}
    disabled={!isValid}
    onChange={(qcStatus: QcStatus) => {
      const currentStatus = previousStatus || QcStatus.DRAFT;
      if (currentStatus !== qcStatus) {
        notifyQcStatusChange({
          detail: currentStatus,
          change: qcStatus,
        });
      }
      onChange?.(qcStatus);
    }}
  >
    {qcStatusOptions().map(({ key, text, value }) => {
      const isOptionDisabled = !isValid;
      return (
        <Select.Option disabled={isOptionDisabled} key={key} value={value}>
          <Badge
            style={{ opacity: isOptionDisabled ? 0.5 : 1 }}
            color={getColorFromQcStatus(value)}
            text={text}
          />
        </Select.Option>
      );
    })}
  </Select>
);

export default QCStatusSelector;
