import { message } from "antd";
import { useCallback, useMemo, useState } from "react";

import ToolbarTable, {
  ToolbarButton,
} from "shared/components/toolbarTable/ToolbarTable";
import ConfirmationModal from "shared/components/toolbarTable/ConfirmationModal";
import SessionTable from "./adLoadSessions/SessionsTable";

import { useNavigate } from "react-router-dom";
import { useOptimisticallyMutateSession } from "shared/hooks/adLibrary/adHistory/useMutateSession";

import { useUser } from "shared/hooks/useUser";

import { omit } from "lodash";
import uuid from "uuid";

import { stepUrlParamValues } from "./adLoad/adLoadDrawer/shared/constants";
import { paths } from "./adLoadSessions/utils";

import {
  AdLoadModifyRulesPresetOperation,
  IAdHistorySession,
} from "shared/types/adLibrary";

import { DataListTagsURL } from "shared/components/dataListURL/DataListTagsURL";
import { useDataListURLGlobalFilter } from "shared/components/dataListURL/useDataListURLGlobalFilter";
import { useDataListURLIds } from "shared/components/dataListURL/useDataListURLIds";
import { ModalAction } from "shared/components/toolbarTable/types";
import { useFetchAllSessions } from "shared/hooks/adLibrary/adHistory/useFetchAllSessions";
import { IAdSessionFields } from "./adLoadSessions/sessionsTable/fields";
import { AdLoadSessionTableEntry } from "./adLoadSessions/types";
import { getAllAdIds } from "shared/components/dataListURL/utils";

const AdLoadSessions = () => {
  const user = useUser();
  const navigate = useNavigate();
  const username = user?.email || "alexia@constellationagency.com";

  const { globalFilter, setGlobalFilter } = useDataListURLGlobalFilter();
  const { selectedIds, setSelectedItemIds } =
    useDataListURLIds<AdLoadSessionTableEntry>(getAllAdIds);
  const [currentSessionId, setCurrentSessionId] = useState("");
  const [currentSessionIdForEdit, setCurrentSessionIdForEdit] = useState("");
  const [modalAction, setModalAction] = useState<ModalAction>(null);

  const {
    isLoading,
    data: adHistorySessions,
    originalData,
    isError,
    error,
  } = useFetchAllSessions();

  const { mutate: modifyAdHistorySession, isLoading: isModifying } =
    useOptimisticallyMutateSession();

  const loading = isLoading || isModifying;

  const onModifySession = useCallback(
    (
      session: AdLoadSessionTableEntry,
      operation: AdLoadModifyRulesPresetOperation,
    ) => {
      const newSession =
        operation === "duplicate"
          ? {
              ...omit(session, ["key"]),
              id: uuid(),
              createdBy: username,
              createdAt: Date.now(),
              updatedAt: Date.now(),
              name: session.name.concat(" - Copy"),
            }
          : omit(session, ["key"]);

      modifyAdHistorySession?.(
        {
          operation,
          session: newSession,
        },
        {
          onSuccess: data => {
            message.success(`Session ${data.operation} complete.`);
          },
          onError: response => {
            message.error(`Something went wrong: ${response?.error?.message}`);
          },
        },
      );
    },
    [modifyAdHistorySession, username],
  );

  if (isError) {
    message.error(error?.message || "Something went wrong");
  }

  const selectedRows = useMemo(
    () => adHistorySessions.filter(session => selectedIds.includes(session.id)),
    [adHistorySessions, selectedIds],
  );

  const onEditClick = (record: AdLoadSessionTableEntry) => {
    navigate(
      `${paths["adLoad"]}?session_id=${record.id}&step=${stepUrlParamValues.DESTINATION_SELECTION}`,
    );
  };

  const onConfirmBatchOp = (operation: AdLoadModifyRulesPresetOperation) => {
    selectedRows.map(session => onModifySession(session, operation));
  };

  const toolbarContents: ToolbarButton = {
    Edit: {
      disabled: selectedRows.length !== 1,
      onClick: () => selectedRows?.length && onEditClick(selectedRows[0]),
    },
    Duplicate: {
      disabled: !selectedRows.length,
      onClick: () => setModalAction("Duplicate"),
    },
    Delete: {
      disabled: !selectedRows.length,
      onClick: () => setModalAction("Delete"),
    },
    History: {
      disabled: selectedRows.length !== 1,
      onClick: () => {
        if (!selectedRows?.length) return;
        navigate({
          pathname: location.pathname,
          search: `?session_id=${selectedRows[0].id}&step=${stepUrlParamValues.LOAD_ADS}`,
        });
      },
    },
  };

  return (
    <div style={{ padding: "1em" }}>
      <ToolbarTable
        searchValue={globalFilter ?? ""}
        toolbarContents={toolbarContents}
        searchPlaceholder="Input search text"
        titleTooltip="Search sessions by name"
        onSearch={value => setGlobalFilter(value)}
      />
      <ConfirmationModal
        objectType={"Session"}
        modalAction={modalAction}
        rowCount={selectedIds.length}
        onModalActionChange={setModalAction}
        onSelectedRowKeysChange={setSelectedItemIds}
        onConfirmDelete={() => onConfirmBatchOp("delete")}
        onConfirmDuplicate={() => onConfirmBatchOp("duplicate")}
      />
      <DataListTagsURL<IAdSessionFields, IAdHistorySession>
        data={adHistorySessions}
        originalData={originalData}
      />
      <SessionTable
        loading={loading}
        data={adHistorySessions}
        searchValue={globalFilter ?? ""}
        currentSessionId={currentSessionId}
        currentEditSessionId={currentSessionIdForEdit}
        onModifySession={onModifySession}
        onCurrentSessionIdChange={setCurrentSessionId}
        onCurrentEditSessionIdChange={setCurrentSessionIdForEdit}
      />
    </div>
  );
};

export default AdLoadSessions;
