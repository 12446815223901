import { useMutation } from "react-query";
import API from "services";
import {
  PostProcessPreview,
  ProcessedPreview,
} from "shared/types/assetExporter";
import { getErrorMessage } from "utils/errorMessage";

const processPreview = async (body: PostProcessPreview) => {
  try {
    const response = await API.services.assetExporter.processPreview(body);
    const { error, result } = response;
    if (error) throw new Error(error.message);
    if (!result) throw new Error("Internal Server Error");
    return result;
  } catch (error) {
    const errMsg = getErrorMessage(error);
    throw new Error(errMsg);
  }
};
const useProcessPreview = () => {
  return useMutation<ProcessedPreview, Error, PostProcessPreview>(body =>
    processPreview(body),
  );
};

export default useProcessPreview;
