import { MinusOutlined, RadiusSettingOutlined } from "@ant-design/icons";
import { Popover, Slider } from "antd";
import { useCallback, useState } from "react";
import {
  ICustomShapeData,
  IExtendedFabricObject,
} from "shared/types/designStudio";
import { delay } from "utils/helpers";
import { TShapePropertyKey } from "../ManageText";
import Color from "./shared/Color";
import Control from "./shared/Control";
import styles from "./Stroke.module.scss";

type Props = {
  object?: fabric.Rect | fabric.Circle;
  onChange: (key: TShapePropertyKey, value?: string | number) => void;
};

const Stroke = (props: Props) => {
  const { onChange, object } = props;
  const [openControl, setOpenControl] = useState<boolean>(
    object && object.stroke ? true : false,
  );
  const onClick = useCallback(() => {
    setOpenControl(true);
  }, [setOpenControl]);

  const onStrokePropertyChange = useCallback(
    (key: TShapePropertyKey, value?: string | number) => {
      onChange(key, value);
    },
    [onChange],
  );

  const onDeleteControl = useCallback(
    async (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault();

      setOpenControl(false);

      // Consecutive set states was not properly update states.
      // Giving 100 ms delay in each set state call.
      onChange("strokeWidth", 1);
      await delay(100);
      onChange("stroke", undefined);

      // The property "radius" is rx and ry.
      // If we set this to 0 on non-square shape, the size of the object shrinks to 0.
      const { customData } = object as IExtendedFabricObject;
      const { shape } = customData as ICustomShapeData;
      if (["square", "squircle"].includes(shape)) {
        await delay(100);
        onChange("radius", 0);
      }
    },
    [onChange, object],
  );

  return (
    <Control
      openControl={openControl || !!object?.stroke}
      onAddControl={onClick}
      title="Stroke"
      controlContent={
        <div className={styles.StrokeControl}>
          <div className={`${styles.FlexRow} ${styles.Top}`}>
            <Color
              fill={(object?.stroke as string) || "white"}
              onColorSelected={color => onStrokePropertyChange("stroke", color)}
            />
            <div>{/* Placeholder in order to make the UI work */}</div>
            <MinusOutlined
              className={styles.DeleteIcon}
              onClick={onDeleteControl}
            />
          </div>

          <div className={`${styles.FlexRow} ${styles.Bottom}`}>
            <div className={styles.WidthControl}>
              <Popover
                trigger="click"
                placement="bottom"
                content={
                  <div style={{ width: "13em" }}>
                    <Slider
                      min={0}
                      max={100}
                      value={object?.strokeWidth}
                      onChange={(val: number) =>
                        onStrokePropertyChange("strokeWidth", val)
                      }
                    />
                  </div>
                }
              >
                <div className={styles.Square}></div>
              </Popover>
              <div>{object?.strokeWidth}</div>
            </div>
            <div className={styles.RadiusControl}>
              <Popover
                trigger="click"
                placement="bottom"
                content={
                  <div style={{ width: "13em" }}>
                    <Slider
                      disabled={object?.type !== "rect"}
                      min={0}
                      max={100}
                      value={
                        ((object as fabric.Rect)?.rx ?? 0) /
                        ((object as fabric.Rect)?.scaleY ?? 1)
                      }
                      onChange={(val: number) =>
                        onStrokePropertyChange("radius", val)
                      }
                    />
                  </div>
                }
              >
                <RadiusSettingOutlined />
              </Popover>
              <div>1</div>
            </div>
            <div>{/* Placeholder in order to make the UI work */}</div>
          </div>
        </div>
      }
    />
  );
};

export default Stroke;
