import { useQuery } from "react-query";
import API from "services";
import { IApiResponse, OfferType } from "shared/types/shared";

const getTransactabilityScores = (vin: string) => async () => {
  if (!vin) {
    return null;
  }
  // TODO: fix return type
  const { result, error } =
    await API.services.assetBuilder.getTransactabilityScores<
      IApiResponse<{ [key: string]: string }>
    >(vin);
  if (error) {
    throw Error(error.message);
  }

  return result?.transactabilityScores || {};
};

export const useFetchTransactabilityScores = (vin: string) => {
  return useQuery<{ [key: string]: string } | null, Error>(
    ["transactabilityScores", vin],
    getTransactabilityScores(vin),
    {
      enabled: false,
      retry: false, // default retry value is set to 3, which leads to 4 requests
      cacheTime: 0,
    },
  );
};

const getTransactabilityKey = (offerType: OfferType) => {
  const modifiedOfferType = offerType.toLowerCase().includes("purchase")
    ? OfferType.Purchase
    : offerType;
  switch (modifiedOfferType) {
    case OfferType.Lease:
      return "lease";
    case OfferType.ZeroDownLease:
      return "lease";
    case OfferType.Finance:
      return "loan";
    case OfferType.Purchase:
      return "purchase";
    default:
      return "lease";
  }
};

export const getTransactabilityScore = (
  offerType: OfferType,
  transactabilities?: { [key: string]: string },
) => {
  const transactabilityScoreInString =
    transactabilities?.[getTransactabilityKey(offerType)];
  if (!transactabilityScoreInString) return null;

  return getTransactabilityScoreFromString(transactabilityScoreInString);
};
const getTransactabilityScoreFromString = (score: string) => {
  try {
    return parseInt(score);
  } catch (err) {
    return null;
  }
};
