import { useCallback, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AdLoadSession } from "screens/adLibrary/facebookUtils/adProto.types";
import API from "services";
import produce from "immer";
import { debounce } from "lodash";

const adLoadSessionsKey = "adLoadSessions";

export const useSaveAdLoadSession = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: API.privServices.adLoad.saveSession,
    onSuccess: async session => {
      await queryClient.invalidateQueries([adLoadSessionsKey, session.id]);
    },
  });
};

export type SetSessionCallback = (
  prevSessionDraft: AdLoadSession,
) => AdLoadSession | void;

export const useSetAdLoadSession = (sessionId: string | null | undefined) => {
  const queryClient = useQueryClient();
  const { mutate: saveSession, isLoading: isSavingSession } = useMutation({
    mutationFn: API.privServices.adLoad.saveSession,
  });

  const debouncedSaveSession = useMemo(
    () => debounce(saveSession, 1000),
    [saveSession],
  );

  const setSession = useCallback(
    (setSessionCallback: SetSessionCallback) => {
      const newSession = queryClient.setQueryData<AdLoadSession>(
        [adLoadSessionsKey, sessionId],
        prevSession => {
          if (!prevSession) {
            throw new Error("Session not found");
          }

          const session = produce(prevSession, setSessionCallback);

          return {
            ...session,
            updatedAt: new Date().toISOString(),
          };
        },
      );

      debouncedSaveSession(newSession);
    },
    [debouncedSaveSession, queryClient, sessionId],
  );

  return {
    setSession,
    isLoading: isSavingSession,
  };
};

export const useGetAdLoadSession = (sessionId: string | undefined | null) => {
  return useQuery({
    queryFn: () => API.privServices.adLoad.getSession(sessionId!),
    queryKey: [adLoadSessionsKey, sessionId],
    enabled: !!sessionId,
  });
};
