import { SkipToken, skipToken } from "@reduxjs/toolkit/dist/query";
import { useCallback, useState } from "react";
import { useGetCurrentUserQuery } from "redux/media/media.api";
import { SEARCH_MODE_FILE_TYPES } from "./constants";
import { getSearchState } from "./getSearchState";
import { useCustomSearchAssetsQuery } from "./useCustomSearchAssetsQuery";

type SearchParams = {
  initParams?: MGSearchObj;
  rows?: number;
  withMeta?: boolean;
  filetype?: string;
};

export const useSearch = ({
  initParams = {},
  rows = 1000,
  withMeta = false,
  filetype,
}: SearchParams = {}) => {
  const [page, setPage] = useState(0);
  const [albumId, setAlbumId] = useState<string>();
  const [params, setParams] = useState(initParams);
  const { data: currentUser } = useGetCurrentUserQuery();
  const { data, isFetching } = useCustomSearchAssetsQuery(
    getSearchPayload({
      currentUser,
      params,
      page,
      withMeta,
      rows,
      albumId,
      filetype,
    }),
  );
  const setFields = useCallback((fields: MGSearchObj, folderId?: string) => {
    setParams(fields);
    setAlbumId(folderId);
    setPage(0);
  }, []);

  return { params, setFields, data, isFetching, setPage };
};

type SearchPayload = {
  params: MGSearchObj;
  page: number;
  rows: number;
  withMeta: boolean;
  filetype?: string;
  currentUser?: WDUser;
  albumId?: string;
};

const getSearchPayload = ({
  currentUser,
  params,
  page,
  rows,
  withMeta,
  albumId,
  filetype = SEARCH_MODE_FILE_TYPES,
}: SearchPayload): MGSearchPayload | SkipToken => {
  // Due to limitation in WebDAM API.
  // https://www.damsuccess.com/hc/en-us/articles/202134055-REST-API#getassetxmp
  if (withMeta && rows > 50) {
    throw new Error("Max rows when requesting metadata is 50.");
  }

  if (!currentUser || !Object.keys(params).length) {
    return skipToken;
  }

  const { query, filters = { fields: [] } } = getSearchState(params);

  const filetypeField: WDSearchField = {
    field_name: "filetype",
    filter_type: "match",
    operator: "OR",
    value: filetype,
  };

  return {
    withMeta,
    payload: {
      query,
      page,
      rows,
      album_id: albumId,
      user_id: currentUser.id,
      filters: {
        fields: filters.fields.concat(filetypeField),
      },
    },
  };
};
