import API from "services";
import { useMutation, useQueryClient } from "react-query";
import { IAssetGroup } from "shared/types/adLibrary";

const assetGroupsKey = "asset-groups";

const updatePmaxAssetGroups = (assetGroups: IAssetGroup[]) => {
  return API.services.adLibrary.updatePmaxAssetGroups(assetGroups);
};

export const useUpdatePmaxAssetGroups = () => {
  const queryClient = useQueryClient();
  return useMutation(updatePmaxAssetGroups, {
    onSuccess: (_, payload) => {
      queryClient.setQueryData<IAssetGroup[] | undefined>(
        [assetGroupsKey],
        prevData =>
          prevData?.map(data => {
            const match = payload.find(
              p => p.id === `${data?.feedId}_${data?.name}`,
            );
            return match ? { ...data, ...match } : data;
          }),
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries([assetGroupsKey]);
    },
  });
};
