import { useV3Query } from "./useV3Query";
import { ClientFragment } from "../../../graphql/v3.gen";

export const useV3Clients = () => {
  const { data, loading, error } = useV3Query<{ clients: ClientFragment[] }>({
    variables: {
      input: {
        query: "v2GetClients",
      },
    },
  });

  const clients = data?.v2AccessQueryExt?.clients;

  return {
    loading,
    error,
    clients,
  };
};
