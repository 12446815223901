import { SetStateAction, useCallback } from "react";
import { IAdToLoadData } from "shared/types/adLibrary";
import DestinationUrl from "../../../shared/components/adsToLoadTable/columns/DestinationUrl";
import EditableAdToLoadUrlText from "../../../shared/components/adsToLoadTable/EditableAdToLoadUrlText";
import { useSelectedStore } from "../../../shared/hooks/reviewStep/useGetRequiredData";
import { OnRowDataChangeArgs } from "../../../shared/types";
import produce from "immer";
import { AllEntryTypes } from "../AssignValuesTable.types";
import { isValidUrl } from "utils/validators";
import styles from "./DestinationURLCell.module.scss";
import classNames from "classnames";

type Props = {
  record: AllEntryTypes;
  index: number;
  adToLoad: IAdToLoadData;
  onRowDataChange?: (args: OnRowDataChangeArgs) => void;
  setCurrentAdsToLoad: (value: SetStateAction<IAdToLoadData[]>) => void;
};

const DestinationUrlCell = ({
  record,
  setCurrentAdsToLoad,
  onRowDataChange,
  adToLoad,
}: Props) => {
  const selectedStore = useSelectedStore(adToLoad?.account.account_id);
  const rootIeId = adToLoad.ad.visuals?.destination?.instantExperienceId;
  const { adShellKey } = record;

  const onEverythingAdElementChange = useCallback(
    (value: string) => {
      if (!rootIeId) return;

      setCurrentAdsToLoad(
        produce(draftAdsToLoad => {
          const draftAdToLoad = draftAdsToLoad.find(
            ad => ad.key === adShellKey,
          );
          if (draftAdToLoad?.ieUrlValues) {
            draftAdToLoad.ieUrlValues[record.key] = value ?? "";
          }
        }),
      );
    },
    [rootIeId, setCurrentAdsToLoad, record.key, adShellKey],
  );

  const onCarouselCardUrlChange = useCallback(
    (value: string) => {
      if (!("cardIndex" in record)) return;
      const cardIndex = record.cardIndex;

      setCurrentAdsToLoad(
        produce(draftAdsToLoad => {
          const draftAdToLoad = draftAdsToLoad.find(
            ad => ad.key === record.adShellKey,
          );
          if (draftAdToLoad?.ad?.visuals?.cards?.[cardIndex]) {
            draftAdToLoad.ad.visuals.cards[cardIndex].destinationUrl = value;
          }
        }),
      );
    },
    [record, setCurrentAdsToLoad],
  );

  const isDestinationURLValid = () => {
    if (!("destinationUrl" in record)) return true;

    return record.destinationUrl && isValidUrl(record.destinationUrl);
  };

  if (!("destinationUrl" in record)) return null;

  if (record.type === "EverythingAdElement" || record.type === "CarouselItem") {
    return (
      <div
        className={classNames({
          [styles.errorState]: !isDestinationURLValid(),
        })}
      >
        <EditableAdToLoadUrlText
          showInputOnly
          linkValue={record.destinationUrl}
          willCheckFullUrl={false}
          selectedStore={selectedStore}
          onChange={value =>
            record.type === "CarouselItem"
              ? onCarouselCardUrlChange(value)
              : onEverythingAdElementChange(value)
          }
        />
      </div>
    );
  }

  // ad shell
  return (
    <div
      className={classNames({
        [styles.errorState]: !isDestinationURLValid(),
      })}
    >
      <DestinationUrl
        adToLoad={adToLoad}
        destinationUrl={record.destinationUrl}
        onRowDataChange={onRowDataChange}
      />
    </div>
  );
};

export default DestinationUrlCell;
