import { memo, useState } from "react";
import { Empty } from "antd";
import {
  FixedSizeGrid as Grid,
  GridChildComponentProps,
  FixedSizeGridProps,
} from "react-window";
import ResizeObserver from "rc-resize-observer";

import styles from "./CardListContainer.module.scss";

interface IProps {
  height?: number;
  cards: JSX.Element[];
  virtualized?: boolean;
  gridArgs?: CardGridArgs;
  extraContent?: JSX.Element | null;
  center?: boolean;
}
interface IHandlers {
  onDivClick?: () => void;
}

export type CardListContainerProps = IProps & IHandlers;

const COL_GAP = 20;
const ROW_GAP = 30;
const CARD_WIDTH = 240;
const CARD_HEIGHT = 292;
const DEFAULT_VISIBLE_ROW_COUNT = 3;

type CardGridArgs = {
  cardWidth?: number;
  colGap?: number;
  cardHeight?: number;
  rowGap?: number;
  defaultVisibleRowCount?: number;
};

const gridParams = ({
  colGap = COL_GAP,
  rowGap = ROW_GAP,
  cardWidth = CARD_WIDTH,
  cardHeight = CARD_HEIGHT,
  defaultVisibleRowCount = DEFAULT_VISIBLE_ROW_COUNT,
}: CardGridArgs) => ({
  colGap,
  rowGap,
  cardWidth,
  cardHeight,
  colWidth: cardWidth + colGap,
  rowHeight: cardHeight + rowGap,
  defaultVisibleRowCount,
});

const return2DAarray = (arr: any[], size: number) => {
  const res: any[] = [];
  for (let i = 0; i < arr.length; i = i + size)
    res.push(arr.slice(i, i + size));
  return res;
};

const CardCell = (props: GridChildComponentProps) => {
  return (
    <div style={props.style}>
      {props.data?.[props.rowIndex]?.[props.columnIndex]}
    </div>
  );
};

const CardListContainer = ({
  cards,
  virtualized,
  extraContent,
  onDivClick,
  height,
  center = true,
  gridArgs = {},
}: CardListContainerProps) => {
  const { colWidth, rowHeight, defaultVisibleRowCount } = gridParams(gridArgs);
  const [gridWidth, setGridWidth] = useState(0);

  if (!cards?.length) {
    return (
      <Empty
        description="No data found."
        image={Empty.PRESENTED_IMAGE_DEFAULT}
      />
    );
  }

  const divClassName = virtualized
    ? styles.virtualGridContainer
    : styles.cardContainer;

  const columnCount = Math.floor(gridWidth / colWidth) || 1;
  const spaceUsed = columnCount * colWidth;
  const marginLeft = center ? (gridWidth - spaceUsed) / 2 : 30; // to center the columns
  const defaultHeight =
    cards.length >= columnCount * defaultVisibleRowCount
      ? rowHeight * defaultVisibleRowCount
      : rowHeight *
        (cards.length / columnCount > 1 ? cards.length / columnCount : 1);

  const gridProps: Omit<FixedSizeGridProps, "children"> = {
    columnCount: columnCount,
    columnWidth: colWidth,
    rowCount: Math.ceil(cards.length / columnCount),
    rowHeight: rowHeight,
    width: gridWidth - marginLeft,
    height: height || defaultHeight,
  };

  return (
    <ResizeObserver
      onResize={({ width }) => {
        setGridWidth(width);
      }}
    >
      <div
        data-cy="ad-cardlist-container"
        className={divClassName}
        onClick={e => {
          if (e.target === e.currentTarget) {
            e.stopPropagation();
            onDivClick?.();
          }
        }}
      >
        {!virtualized && cards}
        {virtualized && columnCount && (
          <Grid
            {...gridProps}
            className="virtual-grid"
            style={{ marginLeft }}
            itemData={return2DAarray(cards, columnCount)}
          >
            {CardCell}
          </Grid>
        )}
        {extraContent}
      </div>
    </ResizeObserver>
  );
};

export default memo(CardListContainer);
