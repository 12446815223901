import { IDataTableError } from "shared/types/errors";
import { IQCHistoryLog } from "shared/types/shared";

export enum AdType {
  Collection = "Collection",
  Carousel = "Carousel",
  AIA = "AIA",
  DPA = "DPA",
  FTA = "FTA",
  InstantExperience = "InstantExperience",
  Still = "Still",
  Video = "Video",
}

export interface AdPrototype {
  utm?: string;
  title?: string;
  cards: AdCard[];
  assetId: string;
  format?: string;
  message: string;
  imageHash: string | undefined;
  thumbnail: string | undefined;
  canvasUrl?: string;
  displayUrl?: string;
  footerText?: string;
  destinationUrl?: string;
  creativeOption?: string;
  callToAction?: CallToAction;
}

export interface AdCard {
  imageHash: string | undefined;
  thumbnail: string | undefined;
  headline: string;
  description: string;
  asset_id?: string;
  destination_url?: string;
  callToAction?: CallToAction;
}

export enum AdFormat {
  // AUDIENCE_NETWORK_INSTREAM_VIDEO = "AUDIENCE_NETWORK_INSTREAM_VIDEO",
  // AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE = "AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE",
  // AUDIENCE_NETWORK_OUTSTREAM_VIDEO = "AUDIENCE_NETWORK_OUTSTREAM_VIDEO",
  // AUDIENCE_NETWORK_REWARDED_VIDEO = "AUDIENCE_NETWORK_REWARDED_VIDEO",
  DESKTOP_FEED_STANDARD = "DESKTOP_FEED_STANDARD",
  // FACEBOOK_STORY_MOBILE = "FACEBOOK_STORY_MOBILE",
  // INSTAGRAM_EXPLORE_CONTEXTUAL = "INSTAGRAM_EXPLORE_CONTEXTUAL",
  // INSTAGRAM_EXPLORE_IMMERSIVE = "INSTAGRAM_EXPLORE_IMMERSIVE",
  INSTAGRAM_STANDARD = "INSTAGRAM_STANDARD",
  INSTAGRAM_STORY = "INSTAGRAM_STORY",
  // INSTANT_ARTICLE_RECIRCULATION_AD = "INSTANT_ARTICLE_RECIRCULATION_AD",
  // INSTANT_ARTICLE_STANDARD = "INSTANT_ARTICLE_STANDARD",
  // INSTREAM_VIDEO_DESKTOP = "INSTREAM_VIDEO_DESKTOP",
  // INSTREAM_VIDEO_IMAGE = "INSTREAM_VIDEO_IMAGE",
  // INSTREAM_VIDEO_MOBILE = "INSTREAM_VIDEO_MOBILE",
  // JOB_BROWSER_DESKTOP = "JOB_BROWSER_DESKTOP",
  // JOB_BROWSER_MOBILE = "JOB_BROWSER_MOBILE",
  // MARKETPLACE_MOBILE = "MARKETPLACE_MOBILE",
  // MESSENGER_MOBILE_INBOX_MEDIA = "MESSENGER_MOBILE_INBOX_MEDIA",
  // MESSENGER_MOBILE_STORY_MEDIA = "MESSENGER_MOBILE_STORY_MEDIA",
  // MOBILE_BANNER = "MOBILE_BANNER",
  // MOBILE_FEED_BASIC = "MOBILE_FEED_BASIC",
  MOBILE_FEED_STANDARD = "MOBILE_FEED_STANDARD",
  // MOBILE_FULLWIDTH = "MOBILE_FULLWIDTH",
  // MOBILE_INTERSTITIAL = "MOBILE_INTERSTITIAL",
  // MOBILE_MEDIUM_RECTANGLE = "MOBILE_MEDIUM_RECTANGLE",
  // MOBILE_NATIVE = "MOBILE_NATIVE",
  // RIGHT_COLUMN_STANDARD = "RIGHT_COLUMN_STANDARD",
  // SUGGESTED_VIDEO_DESKTOP = "SUGGESTED_VIDEO_DESKTOP",
  // SUGGESTED_VIDEO_MOBILE = "SUGGESTED_VIDEO_MOBILE",
  // WATCH_FEED_HOME = "WATCH_FEED_HOME",
  // WATCH_FEED_MOBILE = "WATCH_FEED_MOBILE",
}

export enum CallToAction {
  OPEN_LINK = "OPEN_LINK",
  LIKE_PAGE = "LIKE_PAGE",
  SHOP_NOW = "SHOP_NOW",
  PLAY_GAME = "PLAY_GAME",
  INSTALL_APP = "INSTALL_APP",
  USE_APP = "USE_APP",
  CALL = "CALL",
  CALL_ME = "CALL_ME",
  VIDEO_CALL = "VIDEO_CALL",
  INSTALL_MOBILE_APP = "INSTALL_MOBILE_APP",
  USE_MOBILE_APP = "USE_MOBILE_APP",
  MOBILE_DOWNLOAD = "MOBILE_DOWNLOAD",
  BOOK_TRAVEL = "BOOK_TRAVEL",
  LISTEN_MUSIC = "LISTEN_MUSIC",
  WATCH_VIDEO = "WATCH_VIDEO",
  LEARN_MORE = "LEARN_MORE",
  SIGN_UP = "SIGN_UP",
  DOWNLOAD = "DOWNLOAD",
  WATCH_MORE = "WATCH_MORE",
  NO_BUTTON = "NO_BUTTON",
  VISIT_PAGES_FEED = "VISIT_PAGES_FEED",
  APPLY_NOW = "APPLY_NOW",
  CONTACT = "CONTACT",
  BUY_NOW = "BUY_NOW",
  GET_OFFER = "GET_OFFER",
  GET_OFFER_VIEW = "GET_OFFER_VIEW",
  BUY_TICKETS = "BUY_TICKETS",
  UPDATE_APP = "UPDATE_APP",
  GET_DIRECTIONS = "GET_DIRECTIONS",
  BUY = "BUY",
  MESSAGE_PAGE = "MESSAGE_PAGE",
  DONATE = "DONATE",
  SUBSCRIBE = "SUBSCRIBE",
  SAY_THANKS = "SAY_THANKS",
  SELL_NOW = "SELL_NOW",
  SHARE = "SHARE",
  DONATE_NOW = "DONATE_NOW",
  GET_QUOTE = "GET_QUOTE",
  CONTACT_US = "CONTACT_US",
  ORDER_NOW = "ORDER_NOW",
  START_ORDER = "START_ORDER",
  ADD_TO_CART = "ADD_TO_CART",
  VIDEO_ANNOTATION = "VIDEO_ANNOTATION",
  MOMENTS = "MOMENTS",
  RECORD_NOW = "RECORD_NOW",
  REFER_FRIENDS = "REFER_FRIENDS",
  REQUEST_TIME = "REQUEST_TIME",
  GET_SHOWTIMES = "GET_SHOWTIMES",
  LISTEN_NOW = "LISTEN_NOW",
  WOODHENGE_SUPPORT = "WOODHENGE_SUPPORT",
  SOTTO_SUBSCRIBE = "SOTTO_SUBSCRIBE",
  FOLLOW_USER = "FOLLOW_USER",
  EVENT_RSVP = "EVENT_RSVP",
  WHATSAPP_MESSAGE = "WHATSAPP_MESSAGE",
  FOLLOW_NEWS_STORYLINE = "FOLLOW_NEWS_STORYLINE",
  SEE_MORE = "SEE_MORE",
  FIND_A_GROUP = "FIND_A_GROUP",
  FIND_YOUR_GROUPS = "FIND_YOUR_GROUPS",
  PAY_TO_ACCESS = "PAY_TO_ACCESS",
  PURCHASE_GIFT_CARDS = "PURCHASE_GIFT_CARDS",
  FOLLOW_PAGE = "FOLLOW_PAGE",
  SEND_A_GIFT = "SEND_A_GIFT",
  SWIPE_UP_SHOP = "SWIPE_UP_SHOP",
  SWIPE_UP_PRODUCT = "SWIPE_UP_PRODUCT",
}

export enum FacebookAdStatus {
  ACTIVE = "ACTIVE",
  PAUSED = "PAUSED",
  DELETED = "DELETED",
  ARCHIVED = "ARCHIVED",
}

export enum FacebookAdEffectiveStatus {
  ACTIVE = "ACTIVE",
  PAUSED = "PAUSED",
  DELETED = "DELETED",
  PENDING_REVIEW = "PENDING_REVIEW",
  DISAPPROVED = "DISAPPROVED",
  PREAPPROVED = "PREAPPROVED",
  PENDING_BILLING_INFO = "PENDING_BILLING_INFO",
  CAMPAIGN_PAUSED = "CAMPAIGN_PAUSED",
  ARCHIVED = "ARCHIVED",
  ADSET_PAUSED = "ADSET_PAUSED",
  IN_PROCESS = "IN_PROCESS",
  WITH_ISSUES = "WITH_ISSUES",
}

export enum FacebookSpecialAdCategory {
  NONE = "NONE",
  EMPLOYMENT = "EMPLOYMENT",
  HOUSING = "HOUSING",
  CREDIT = "CREDIT",
  ISSUES_ELECTIONS_POLITICS = "ISSUES_ELECTIONS_POLITICS",
}

export enum FacebookCampaignObjective {
  APP_INSTALLS = "APP_INSTALLS",
  BRAND_AWARENESS = "BRAND_AWARENESS",
  CONVERSIONS = "CONVERSIONS",
  EVENT_RESPONSES = "EVENT_RESPONSES",
  LEAD_GENERATION = "LEAD_GENERATION",
  LINK_CLICKS = "LINK_CLICKS",
  LOCAL_AWARENESS = "LOCAL_AWARENESS",
  MESSAGES = "MESSAGES",
  OFFER_CLAIMS = "OFFER_CLAIMS",
  PAGE_LIKES = "PAGE_LIKES",
  POST_ENGAGEMENT = "POST_ENGAGEMENT",
  PRODUCT_CATALOG_SALES = "PRODUCT_CATALOG_SALES",
  REACH = "REACH",
  STORE_VISITS = "STORE_VISITS",
  VIDEO_VIEWS = "VIDEO_VIEWS",
  OUTCOME_TRAFFIC = "OUTCOME_TRAFFIC",
  OUTCOME_AWARENESS = "OUTCOME_AWARENESS",
  OUTCOME_SALES = "OUTCOME_SALES",
  OUTCOME_LEADS = "OUTCOME_LEADS",
}

export interface IInstagramGetResponseData {
  id: string;
  instagram_accounts: {
    data: Array<{ id: string; username: string }>;
  };
}

export interface IFacebookGetResponseData<T> {
  data: Array<T>;
  paging?: IFacebookPaging;
}

export interface IFacebookPaging {
  cursors: {
    before: string;
    after: string;
  };
  next: string;
}

export interface IFacebookAccount {
  /**
   * The facebook id associated with the store (without the act_ prefix)
   * Note: In ad load, the id is `undefined`
   */
  id: string;
  /**
   * In Ad review it's the FB Account Name.
   * In Ad load, it's the dealer/store name. Note: the store name is the dealer's primary key
   */
  name: string;
  /**
   * the facebook account id associated with the store.
   * in ad review, it's starting with `act_`, but in ad load it's just the store id without prefix
   */
  account_id: string;
  /** the account status, 1 means that it's active (1 is what I see we assign everywhere) */
  account_status: number;
}

export interface IGetFacebookCampaignsParams {
  limit?: number;
  includeAdsets?: boolean;
  includeAds?: boolean; // only works if adsets are included
  includeCreative?: boolean;
  pullDataViaPagination?: boolean;
  startTimes?: number[];
}

export interface IFacebookCampaign {
  /** Campaign's ID */
  id: string;
  /** Campaign's name */
  name: string;
  /** ID of the ad account that owns this campaign */
  account_id: string;
  /**
   * Merging of `start_time`s for the ad sets belonging to this campaign.
   * At the campaign level, start_time is a read only field.
   * You can setup start_time at the ad set level.
   */
  start_time: string;
  /**
   * Merging of `stop_times` for the ad sets belonging to this campaign, if available.
   * At the campaign level, `stop_time` is a read only field.
   * You can setup `stop_time` at the ad set level.
   */
  stop_time?: string;
  /** Created Time */
  created_time: string;
  /**
   * Updated Time.
   * If you update spend_cap or daily budget or lifetime budget,
   * this will not automatically update this field.
   */
  updated_time?: string;
  /** If this status is `PAUSED`, all its active ad sets and ads will be paused
   * and have an effective status `CAMPAIGN_PAUSED`.
   * The field returns the same value as 'configured_status', and is the suggested one to use. */
  status: FacebookAdStatus;
  /**
   * Campaign's objective
   * Some objectives are currently in limited beta testing.
   * See the Outcome Ad-Driven Experience Objective Validation section below for more information.
   */
  objective: FacebookCampaignObjective;
  /** Ad Labels associated with this campaign */
  adlabels?: IFacebookCampaignAdLabels[];
  /** special ad categories */
  special_ad_categories?: FacebookSpecialAdCategory[];
  /** The ad sets under this campaign */
  adsets?: IFacebookGetResponseData<IFacebookAdset>;
  promoted_object?: IFacebookCampaignPromotedObject;
}

interface IFacebookCampaignAdLabels {
  id: string;
  name: string;
}

interface IFacebookCampaignPromotedObject {
  product_catalog_id: string;
}

interface IFacebookAdSetPromotedObject {
  product_set_id: string;
}

export interface IFacebookAdset {
  id: string;
  name: string;
  campaign_id: string;
  ads?: IFacebookGetResponseData<IFacebookAd>;
  start_time: string;
  end_time?: string;
  promoted_object?: IFacebookAdSetPromotedObject;
}

export interface IFacebookAd {
  id?: string;
  campaign_id?: string;
  adset_id?: string;
  name?: string;
  image_url?: string;
  created_time?: string;
  updated_time?: string;
  status?: FacebookAdStatus;
  effective_status?: FacebookAdEffectiveStatus;
  adset?: IFacebookAdset;
  campaign?: IFacebookCampaign;
  creative?: IFacebookAdCreative;
  type?: AdType;
  children?: IFacebookAd[];
  qcHistoryLogs?: IQCHistoryLog[];
  mobilePreview?: AdPreview;
  desktopPreview?: AdPreview;
  destinationUrl?: string;
  productSet?: IProductSet;
}

interface IProductCatalog {
  id: string;
  name: string;
}

interface IProductSet {
  id: string;
  name: string;
  catalog: IProductCatalog;
}

type AdPreview = {
  data: {
    body: string;
  }[];
};

type EnrollStatus = "OPT_IN" | "OPT_OUT";

type DegreesOfFreedomSpec = {
  creative_features_spec: {
    standard_enhancements: {
      enroll_status: EnrollStatus;
    };
  };
};

export interface IFacebookAdCreative {
  id?: string;
  name?: string;
  object_story_spec?: IObjectStorySpec;
  thumbnail_url?: string;
  effective_object_story_id?: string;
  url_tags?: string;
  product_set_id?: string;
  instagram_actor_id?: string;
  degrees_of_freedom_spec?: DegreesOfFreedomSpec;
  asset_feed_spec?: AssetFeedSpec;
  /** page level creative spec, used when object_story_spec is not available */
  page_object_story_spec?: {
    page_id: string;
    id: string;
    call_to_action: IFacebookCallToAction;
  };
}

interface AssetFeedSpecBase {
  adlabels: AssetFeedSpecAdLabel[];
}
export interface AssetFeedSpecAdLabel {
  name: string;
  id: string;
}
export interface AssetFeedSpecImage extends AssetFeedSpecBase {
  hash: string;
}
export interface AssetFeedSpecVideos extends AssetFeedSpecBase {
  hash: string;
}
export interface AssetFeedSpecBody extends AssetFeedSpecBase {
  text: string;
}
export interface AssetFeedSpecTitleData extends AssetFeedSpecBase {
  text: string;
}
export interface AssetFeedSpecLinkUrl {
  adlabels: AssetFeedSpecAdLabel[];
  website_url: string;
  display_url: string;
}
export interface AssetFeedSpec {
  images: AssetFeedSpecImage[];
  videos: AssetFeedSpecVideos[];
  bodies: AssetFeedSpecBody[];
  call_to_action_types: string[];
  link_urls: AssetFeedSpecLinkUrl[];
  titles: AssetFeedSpecTitleData[];
  ad_formats: string[];
}

export interface IObjectStorySpec {
  page_id?: string;
  link_data?: IObjectStorySpecLinkData;
  video_data?: IObjectStorySpecVideoData;
  template_data?: IObjectStorySpecTemplateData;
}

export interface IObjectStorySpecVideoData {
  video_id?: string;
  title?: string;
  message?: string;
  image_url?: string;
  image_hash?: string;
  call_to_action?: IFacebookCallToAction;

  // video props data
  link_description?: string;

  // collection props data
  retailer_item_ids?: string[];
  post_click_configuration?: IObjectStorySpecVideoDataPostClickConfig;
}

interface IObjectStorySpecVideoDataPostClickConfig {
  post_click_item_headline: string;
  post_click_item_description: string;
}

export interface IObjectStorySpecLinkData {
  link?: string;
  message?: string;
  name?: string;
  caption?: string;
  attachment_style?: "link" | "default";
  image_hash?: string;
  call_to_action?: IFacebookCallToAction;

  // still props data
  picture?: string;
  description?: string;

  // carousel props data
  child_attachments?: IObjectStorySpecChildAttachment[];
  multi_share_end_card?: boolean;
  multi_share_optimized?: boolean;

  // collection props data
  retailer_item_ids?: string[];
}

export interface IObjectStorySpecTemplateData {
  link?: string;
  message?: string;
  name?: string;
  description?: string;
  call_to_action?: IFacebookCallToAction;
  multi_share_end_card?: boolean; // usually true for all AIA ads
  show_multiple_images?: boolean; // false for AIA still and undefined by default
  force_single_link?: boolean; // true for AIA still and undefined by default
  format_option?: string; // usually carousel_images_multi_items for AIA carousels
}

export interface IObjectStorySpecChildAttachment {
  link: string | undefined;
  picture?: string;
  image_hash?: string;
  name: string;
  call_to_action?: IFacebookCallToAction;
  video_id?: string;
}

interface IFacebookCallToAction {
  type?: CallToAction;
  value?: IFacebookCallToActionValue;
}

interface IFacebookCallToActionValue {
  link_caption?: string;
  link?: string;
  link_format?: string;
}

interface IFacebookTrackingSpecs {
  "action.type": string | string[];
  pixel?: string[];
  fb_pixel?: string | string[];
}

export interface ICreateOrUpdateResult {
  id?: string; // can be id from campaign, adset, or ad (or even creative_id)
  success?: boolean;
}

export interface IFacebookErrorObject {
  message: string;
  type?: string;
  code?: number;
  error_subcode?: number;
  error_user_title?: string;
  error_user_msg?: string;
  fbtrace_id?: string;
}

export interface ICreateOrUpdateResponse {
  result: ICreateOrUpdateResult | null;
  error: IDataTableError | IFacebookErrorObject | null;
}

export interface IUpdateFacebookAdParams {
  creative?: IFacebookAdCreative;
  tracking_specs?: IFacebookTrackingSpecs;
  status?: FacebookAdStatus;
  audience_id?: string;
  name?: string;
}

export type FacebookHTTPMethod = "GET" | "POST" | "PUT" | "DELETE";

export type IProxyRequestBody<TData = any> =
  | {
      data?: TData;
      query?: string;
      endpoint: string;
      platform: "facebook";
      facebookPageId?: string;
      method: FacebookHTTPMethod;
      serverSidePagination?: boolean;
    } & (
      | {
          /** If the `cache` option is set to true, we aim to retrieve the data from the cache if it exists.
           * If the data is not present in the cache, we will retrieve it from Facebook as usual and initiate a background process to fetch the data and store it in the cache.
           * This will enable us to directly retrieve the data from the cache the next time it is requested. */
          cache: true;
          /**
           * The `staleTime` option is used to control how long (in milliseconds) a query result should be considered fresh.
           * If a query result is accessed within the staleTime window, the cached data will be returned immediately without triggering a refresh.
           * If the query result is accessed after the staleTime window has passed,
           * the cached data will be returned but a background refetch will be triggered to update the data.
           */
          staleTime: number;
        }
      | { cache?: false }
    );

/** https://developers.facebook.com/docs/graph-api/reference/canvas/ */
export interface ICanvasParams {
  /** Background color of the canvas. Default value: FFFFFFFF */
  background_color?: string;
  /** A list of all canvas element ids inside this canvas */
  body_element_ids?: string[];
  /** Field used to mark if swipe to open is enabled. Default value: false */
  enable_swipe_to_open?: boolean;
  /** Field used to mark if the canvas is visible or not. Default value: false */
  is_hidden?: boolean;
  /** Field used to mark the publish state of the canvas. Default value: false */
  is_published?: boolean;
  /** Field used to label docs by the editor tool. Default value: Untitled Canvas */
  name?: string;
  /** page access token */
  access_token?: string;
}

/** User and Page */
interface Entity {
  name: string;
  id: string;
}

interface BodyElement {
  name: string;
  element_type: ElementType;
  id: string;
}

/** https://developers.facebook.com/docs/graph-api/reference/canvas/ */
export interface ICanvasFields {
  /** ID of the canvas */
  id: string;

  /** Background color of the canvas */
  background_color: string;

  /** Body element nodes for the canvas */
  body_elements?: BodyElement[];

  /** The canvas link for the canvas */
  canvas_link: string;

  /** The canvas is hidden or not */
  is_hidden?: boolean;

  /** Publish status of the canvas */
  is_published?: boolean;

  /** User who last edited this canvas */
  last_editor?: Entity;

  /** Name used to label the canvas */
  name: string;

  /** Page that owns this canvas */
  owner?: Entity;

  /** Last updated time of the canvas */
  update_time: number;
}

type ElementType =
  | "BUTTON"
  | "FOOTER"
  | "PHOTO"
  | "VIDEO"
  | "ELEMENT_GROUP"
  | "CAROUSEL";

export interface ICanvasOpenURLAction {
  type: "OPEN_URL";
  /** the destination url after user clicks the button, by setting this, the canvas button will have action with OPEN URL type */
  url: string;
}

interface IButtonBasicFields {
  /** The id of the element */
  id: string;
  /** The type of the element */
  element_type: ElementType;

  /** Name of the button. Default value: Button */
  name?: string;
}

export type CanvasElementFieldTypes =
  | IButtonFields
  | IFooterFields
  | ICanvasPhotoParams
  | ICanvasVideoParams;

/** https://developers.facebook.com/docs/graph-api/reference/canvas-button/ */
export interface IButtonFields extends IButtonBasicFields {
  /** The action associated with the button */
  action?: ICanvasOpenURLAction;

  /** The color of the button. Default value: FF000000 */
  button_color?: string;

  /** The text inside the button */
  rich_text: RichText;

  /** The style of the button. Default value: BUTTON_OUTLINE */
  button_style?: ButtonStyle;

  /** The alignment of the text on the button. Default value: CENTER */
  text_alignment?: TextAlignment;

  /** The color of the text on the button. Default value: FF777777 */
  text_color?: string;

  /** The size of the font for the text on the button. Default value: 15 */
  font_size?: string;

  /** The font family of the text on the button. Default value: sans-serif */
  font_family?: string;

  /** The line height of the text on the button. Default value: 1 */
  line_height?: string;

  /** The padding above the button. Default value: 0 */
  top_padding?: string;

  /** The padding below the button. Default value: 0 */
  bottom_padding?: string;

  /** Background color of the button. Default value: FFFFFFFF */
  background_color?: string;
}

export interface IFooterFields {
  /** The id of the element */
  id: string;

  /** The name of the element */
  name?: string;

  /** The child elements inside a footer */
  child_elements?: IButtonBasicFields[];

  /** Background color of the button */
  background_color?: string;

  /** The padding above the button */
  top_padding?: string;

  /** The padding below the button */
  bottom_padding?: string;

  /** The type of the element */
  element_type: ElementType;
}

/** https://developers.facebook.com/docs/graph-api/reference/page/canvas_elements/ */
export interface ICanvasElementParams {
  canvas_button?: ICanvasButtonParams;
  canvas_footer?: ICanvasFooterParams;
  canvas_photo?: ICanvasPhotoParams;
  canvas_video?: ICanvasVideoParams;
  canvas_carousel?: ICanvasCarouselParams;
  canvas_product_set?: ICanvasProductSetParams;
  access_token?: string;
}

export interface ICanvasButtonParams {
  /** Name of the button. Default value: Button */
  name?: string;

  /** The color of the button. Default value: FF000000 */
  button_color?: string;

  /** The text inside the button */
  rich_text: RichText;

  /** The style of the button. Default value: BUTTON_OUTLINE */
  button_style?: ButtonStyle;

  /** the destination url after user clicks the button, by setting this, the canvas button will have action with OPEN URL type */
  open_url_action?: ICanvasOpenURLAction;

  /** The alignment of the text on the button. Default value: CENTER */
  text_alignment?: TextAlignment;

  /** The color of the text on the button. Default value: FF777777 */
  text_color?: string;

  /** The size of the font for the text on the button. Default value: 15 */
  font_size?: number;

  /** The font family of the text on the button. Default value: sans-serif */
  font_family?: string;

  /** The line height of the text on the button. Default value: 1 */
  line_height?: number;

  /** The padding above the button. Default value: 0 */
  top_padding?: number;

  /** The padding below the button. Default value: 0 */
  bottom_padding?: number;

  /** Background color of the button. Default value: FFFFFFFF */
  background_color?: string;
}

export interface ICanvasFooterParams {
  /** Name of the footer element. Default value: Footer */
  name?: string;

  /** The child elements inside a footer, typically, it is a canvas button element. */
  child_elements: string[];

  /** Background color of the button. Default value: FFFFFFFF */
  background_color?: string;
}

export interface ICanvasPhotoParams {
  /** ID of canvas element */
  id?: string;

  /** ID of Facebook photo node used when creating/updating canvas element */
  photo_id?: string;

  /**  Object (Facebook photo node) returned when reading Canvas Photo element*/
  photo?: { id: string };

  name?: string;

  /** See enum CanvasElementStyle */
  style?: CanvasElementStyle;

  /** The readable destination field {type: "OPEN_URL", url:<string>} */
  action?: ICanvasOpenURLAction;

  /** The writable destination field {type: "OPEN_URL", url:<string>} */
  open_url_action?: ICanvasOpenURLAction;
}

export interface ICanvasVideoParams {
  /** ID of canvas element */
  id?: string;

  /** ID of Facebook video node used when creating/updating canvas element */
  video_id?: string;

  /**  Object (Facebook photo node) returned when reading Canvas Photo element*/
  video?: { id: string };

  name?: string;

  /** See enum CanvasElementStyle */
  style?: CanvasElementStyle;
}

export interface ICanvasCarouselParams {
  /** name of element. Defaults to "Carousel" */
  name?: string;

  /** IDs of canvas photo elements */
  child_elements?: string[];
}

export interface ICanvasProductSetParams {
  /** ID of canvas element */
  id?: string;

  /** ID of Facebook product set from a product catalog */
  product_set_id?: string;

  /** Description Text that populates {{<variable>}} data*/
  item_description?: string;

  /** A token to represent which field from the product to show in the product headline */
  item_headline?: string;

  /** Maximum number of products to show */
  max_items?: number;

  /** This field should be set to true when loading a collection ad */
  show_in_feed?: boolean;
}

export type CanvasElementParamTypes =
  | ICanvasButtonParams
  | ICanvasFooterParams
  | ICanvasPhotoParams
  | ICanvasVideoParams
  | ICanvasCarouselParams
  | ICanvasProductSetParams;

interface InlineStyle {
  style: InlineStyleType;
  offset: number;
  length: number;
}
export interface RichText {
  /** The content of the rich text blob */
  plain_text: string;

  /** The inline style list of the rich text */
  inline_styles: InlineStyle[];

  /** The canvas text style */
  block_type: "UNSTYLED";
}

enum InlineStyleEnum {
  BOLD = "BOLD",
  ITALIC = "ITALIC",
  UNDERLINE = "UNDERLINE",
  STRIKETHROUGH = "STRIKETHROUGH",
  CODE = "CODE",
}
type InlineStyleType = keyof typeof InlineStyleEnum;

export enum ButtonStyle {
  BUTTON_OUTLINE = "BUTTON_OUTLINE",
  BUTTON_FILLED = "BUTTON_FILLED",
}

export enum TextAlignment {
  LEFT = "LEFT",
  CENTER = "CENTER",
  RIGHT = "RIGHT",
}

export enum CanvasElementStyle {
  FIT_TO_WIDTH = "FIT_TO_WIDTH",
  FIT_TO_WIDTH_EXPANDABLE = "FIT_TO_WIDTH_EXPANDABLE",
  FIT_TO_HEIGHT = "FIT_TO_HEIGHT",
}

export interface ICreateAdImageResponseData {
  images: {
    bytes: {
      hash: string;
      url: string;
    };
  };
}

interface IAdImage {
  height: number;
  source: string;
  width: string;
}
export interface IGetAdImageResponseData {
  /** array of images in different sizes of same W x H ratio */
  images?: IAdImage[]; //

  /** small thumbnail */
  picture?: string;
}

interface IAdVideoThumbnail {
  id: string;
  height: number;
  uri: string;
  scale: number;
  width: number;
  is_preferred: boolean;
}
export interface IGetAdVideoResponseData {
  /** array of images in different sizes of same W x H ratio */
  thumbnails?: { data: IAdVideoThumbnail[] };

  /** A URL to the raw, playable video file.  */
  source?: string;

  /** used for checking if video can be used in ad creative */
  status?: {
    video_status: "ready" | "processing" | "error";
  };
}

interface Picture {
  data: Data;
}

interface Data {
  height: number;
  is_silhouette: boolean;
  url: string;
  width: number;
}

export interface IFacebookObject {
  id: string;
  name: string;
  access_token?: string;
  product_count?: number;
  picture?: Picture;
}

export type IFacebookAssetType = "page" | "catalog" | "pixel" | "instagram";

export interface IFacebookProductSet extends IFacebookObject {
  product_catalog?: IFacebookObject;
  product_count?: number;
}

export type CreateAdParameters = {
  adset_id: string;
  name: string;
  status: FacebookAdStatus;
  creative: IFacebookAdCreative | { creative_id?: string };
  tracking_specs?: IFacebookTrackingSpecs[];
  conversion_domain?: string;
  degrees_of_freedom_spec?: DegreesOfFreedomSpec;
};
