import { IConfig } from "shared/types/configuration";
import { IApiResponse } from "shared/types/shared";
import {
  IDashboard,
  ICreateOrUpdateDashboardResponseData,
  IDeleteDashboardResponseData,
  IGetDashboardsResponseData,
  IGetDashboardResponseData,
  IPublicDashboard,
  ICreatePublicDashboardResponseData,
  IGetPublicDashboardResponseData,
  IValidatePassword,
  IValidatePasswordResponseData,
  IGetPublicDashboardsResponseData,
  IDeletePublicDashboardResponseData,
  IGetDashboardSettingsResponseData,
  DashboardModel,
} from "shared/types/dashboardManagement";
import HttpClient from "./httpClient";

export default ({ config }: { config: IConfig }) => ({
  createOrUpdatePublicDashboard: async (
    publicDashboard: IPublicDashboard,
  ): Promise<IApiResponse<ICreatePublicDashboardResponseData>> => {
    const createPublicDashboardUrl =
      config.services.createOrUpdatePublicDashboardUrl;
    return HttpClient.post<IApiResponse<ICreatePublicDashboardResponseData>>(
      createPublicDashboardUrl,
      publicDashboard,
    );
  },

  getPublicDashboard: async (
    id: string,
  ): Promise<IApiResponse<IGetPublicDashboardResponseData>> => {
    const getPublicDashboardUrl = config.services.getPublicDashboardUrl.concat(
      `?id=${id}`,
    );

    return HttpClient.get<IApiResponse<IGetPublicDashboardResponseData>>(
      getPublicDashboardUrl,
      {},
      false,
    );
  },

  getPublicDashboards: async (): Promise<
    IApiResponse<IGetPublicDashboardsResponseData>
  > => {
    const getPublicDashboardsUrl = config.services.getPublicDashboardsUrl;

    return HttpClient.get<IApiResponse<IGetPublicDashboardsResponseData>>(
      getPublicDashboardsUrl,
    );
  },

  validatePassword: async (
    args: IValidatePassword,
  ): Promise<IApiResponse<IValidatePasswordResponseData>> => {
    const validatePasswordUrl = config.services.validatePasswordUrl;

    return HttpClient.post<IApiResponse<IValidatePasswordResponseData>>(
      validatePasswordUrl,
      args,
      {},
      false,
    );
  },

  deletePublicDashboard: async (
    id: string,
  ): Promise<IApiResponse<IDeleteDashboardResponseData>> => {
    const deletePublicDashboardUrl =
      config.services.deletePublicDashboardUrl.concat(`/${id}`);

    return HttpClient.delete<IApiResponse<IDeletePublicDashboardResponseData>>(
      deletePublicDashboardUrl,
    );
  },

  deletePublicDashboards: async (
    ids: string[],
  ): Promise<IApiResponse<IDeleteDashboardResponseData>> => {
    const deletePublicDashboardsUrl = config.services.deletePublicDashboardsUrl;

    return HttpClient.delete<IApiResponse<IDeletePublicDashboardResponseData>>(
      deletePublicDashboardsUrl,
      ids,
    );
  },

  getDashboard: async (
    id: string,
  ): Promise<IApiResponse<IGetDashboardResponseData>> => {
    const getDashboardUrl = config.services.getDashboardUrl.concat(`?id=${id}`);

    return HttpClient.get<IApiResponse<IGetDashboardResponseData>>(
      getDashboardUrl,
      {},
      false,
    );
  },

  getDashboards: async (): Promise<
    IApiResponse<IGetDashboardsResponseData>
  > => {
    const getDashboardsUrl = config.services.getDashboardsUrl;
    return HttpClient.get<IApiResponse<IGetDashboardsResponseData>>(
      getDashboardsUrl,
    );
  },

  createOrUpdateDashboard: async (
    dashboard: IDashboard,
  ): Promise<IApiResponse<ICreateOrUpdateDashboardResponseData>> => {
    const createOrUpdateDashboardUrl =
      config.services.createOrUpdateDashboardUrl;

    return HttpClient.post<IApiResponse<ICreateOrUpdateDashboardResponseData>>(
      createOrUpdateDashboardUrl,
      dashboard,
    );
  },
  deleteDashboard: async (
    id: string,
  ): Promise<IApiResponse<IDeleteDashboardResponseData>> => {
    const deleteDashboardUrl = config.services.deleteDashboardUrl.concat(
      `/${id}`,
    );

    return HttpClient.delete<IApiResponse<IDeleteDashboardResponseData>>(
      deleteDashboardUrl,
      id,
    );
  },

  getDashboardSettings: async (
    user: string,
  ): Promise<IApiResponse<IGetDashboardSettingsResponseData>> => {
    const getDashboardSettingsUrl =
      config.services.getDashboardSettingsUrl.concat(
        `?user=${encodeURIComponent(user)}`,
      );

    return HttpClient.get<IApiResponse<IGetDashboardSettingsResponseData>>(
      getDashboardSettingsUrl,
    );
  },
  getDashboardAvailableModels: async (): Promise<
    IApiResponse<DashboardModel[]>
  > => {
    const getAvailableModelsUrl =
      config.services.getDashboardAvailableModelsUrl;
    return HttpClient.get(getAvailableModelsUrl);
  },
});
