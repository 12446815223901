import { useCallback, useState } from "react";
import { Tabs } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { AccountRecord, ILogos } from "shared/types/brandsAccounts";
import { OperationMode } from "shared/types/inputValues";
import { IAccountRecord } from "shared/types/accountManagement";
import { useDeleteBrandsAccounts } from "shared/hooks/brandsAccountsManagement/useDeleteBrandsAccounts";
import { useIsAdmin } from "shared/hooks/useIsAdmin";
import styles from "./AccountBaseDrawer.module.scss";
import { AdPlatformsTab } from "./AdPlatformsTab";
import { UrlTab } from "./UrlTab";
import { DetailsTab } from "./detailsTab/DetailsTab";
import { BaseDrawer } from "../BaseDrawer";

interface AccountBaseDrawerProps {
  visible: boolean;
  mutationIsLoading: boolean;
  buttonText: string;
  drawerTitle: string;
  logos: ILogos;
  form: FormInstance<IAccountRecord>;
  mode: OperationMode;
  accountToEdit: IAccountRecord;
  onSubmit: () => void;
  setLogos: React.Dispatch<React.SetStateAction<ILogos>>;
  onRemoveLogo?: (url: string, fieldKey: keyof ILogos) => void;
  onCancel: () => void;
  setAccountToEdit: (accountToEdit: IAccountRecord) => void;
  accountDrawerRef?: React.MutableRefObject<HTMLDivElement | null>;
}

const validateForm = async (form: FormInstance<IAccountRecord>) => {
  try {
    await form.validateFields();
    return true;
  } catch (error) {
    return false;
  }
};

export const AccountBaseDrawer = ({
  logos,
  mutationIsLoading,
  buttonText,
  drawerTitle,
  form,
  mode,
  accountToEdit,
  onRemoveLogo,
  setLogos,
  onSubmit,
  setAccountToEdit,
  accountDrawerRef,
  ...props
}: AccountBaseDrawerProps) => {
  const isAdmin = useIsAdmin();
  const [requiredFields, setRequiredFields] = useState(false);
  const { onDelete } = useDeleteBrandsAccounts({
    name: accountToEdit.dealerName,
    type: "Account",
  } as AccountRecord);

  const onDeleteAccount = () => {
    onDelete();
    props.onCancel();
  };

  const onSubmitForm = useCallback(async () => {
    const isValid = await validateForm(form);
    if (isValid) {
      onSubmit();
    }
  }, [onSubmit, form]);

  return (
    <BaseDrawer
      {...props}
      clickButtonText={buttonText}
      onClick={onSubmitForm}
      onDelete={mode === "UPDATE" ? onDeleteAccount : undefined}
      title={drawerTitle}
      width="fit-content"
      buttonDisabled={!requiredFields}
      buttonLoading={mutationIsLoading}
    >
      <div ref={accountDrawerRef}>
        <Tabs
          defaultActiveKey="1"
          tabPosition="left"
          className={styles.tabContainer}
        >
          <Tabs.TabPane
            tab="Details"
            key="Details-Account"
            className={styles.tabPane}
            style={{ paddingLeft: 0 }}
          >
            <DetailsTab
              accountToEdit={accountToEdit}
              setAccountToEdit={setAccountToEdit}
              logos={logos}
              setLogos={setLogos}
              onRemoveLogo={onRemoveLogo}
              mode={mode}
              disabled={!isAdmin}
              form={form}
              setRequiredFields={setRequiredFields}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab="Ad Platforms"
            key="Ad-Platforms-Account"
            className={styles.tabPane}
            style={{ paddingLeft: 0 }}
          >
            <AdPlatformsTab
              accountToEdit={accountToEdit}
              setAccountToEdit={setAccountToEdit}
              form={form}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab="URLs"
            key="URLs-Account"
            className={styles.tabPane}
            style={{ paddingLeft: 0 }}
          >
            <UrlTab
              accountToEdit={accountToEdit}
              setAccountToEdit={setAccountToEdit}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </BaseDrawer>
  );
};
