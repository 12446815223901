import { useQuery, useQueryClient } from "react-query";
import API from "services";
import { IImageResizeReq, IImageResizeResponse } from "shared/types/genAI";

export default (args: IImageResizeReq) => {
  const queryClient = useQueryClient();
  const { data, isLoading, refetch, isRefetching } = useQuery<
    IImageResizeReq,
    Error
  >(
    ["resizeImages", args.url],
    () => API.services.genAI.resizeImage(args.url),
    { retry: false, enabled: false },
  );

  const handleCancel = () => {
    queryClient.removeQueries(["resizeImages", args.url]);
  };

  return {
    isFetching: isLoading || isRefetching,
    data,
    handleResizeImage: refetch,
    cancel: handleCancel,
  } as unknown as {
    isFetching: boolean;
    data: IImageResizeResponse;
    handleResizeImage: () => void;
    cancel: () => void;
  };
};
