import { QUERY_KEY_FEED_LIST } from "./../../types/assetExporter";
import message from "antd/lib/message";
import {
  QueryClient,
  QueryKey,
  useMutation,
  useQueryClient,
} from "react-query";

import API from "services";
import {
  FeedTblRow,
  PatchFeedRowResult,
  TRawData,
} from "shared/types/assetExporter";
import { getErrorMessage } from "utils/errorMessage";
import { TFetchArgs } from "./useFeedConfigFetch";
import { successNotification } from "shared/components/customNotification/Notification";

type TEditArgs = {
  feedId: string;
  rowIdentifier: string;
  field: string;
  value: string | number | boolean;
  oldValue: string | number | boolean;
  fetchArgs?: TFetchArgs;
};

const messageKeyEdit = "edit-feed-message-key";

const configFeedUpdate = async ({
  feedId,
  rowIdentifier,
  field,
  value,
}: TEditArgs) => {
  try {
    const body = {
      feedId,
      rowIdentifier,
      [field]: value,
    };

    const response = await API.services.assetExporter.patchFeedRowField(body);

    const { result, error } = response;
    if (error) throw new Error(error.message);
    if (!result) throw new Error("Unable to update feed row field");

    return response;
  } catch (error) {
    const errMsg = getErrorMessage(error);
    throw new Error(errMsg);
  }
};

const updateOptimistic = <T extends FeedTblRow[] | TRawData>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  rowIdentifier: string,
  field: string,
  value: string,
) => {
  queryClient.cancelQueries(queryKey);

  const previousFeedTblRows: T | undefined =
    queryClient.getQueryData<T>(queryKey);

  if (!previousFeedTblRows) return;

  const adEngineV2 = Array.isArray(previousFeedTblRows);
  const evalRows: FeedTblRow[] = adEngineV2
    ? previousFeedTblRows
    : previousFeedTblRows?.rows;

  if (!evalRows?.length) return;

  const newRows = evalRows.map(row =>
    row.rowIdentifier === rowIdentifier ? { ...row, [field]: value } : row,
  ) as T;

  const newFeedConfig: T = adEngineV2
    ? newRows
    : {
        ...previousFeedTblRows,
        rows: newRows,
      };

  queryClient.setQueryData<T>(queryKey, newFeedConfig);
};

export const useFeedConfigUpdate = (queryKey?: QueryKey) => {
  const queryClient = useQueryClient();

  const result = useMutation<PatchFeedRowResult, Error, TEditArgs>(
    args => configFeedUpdate(args),
    {
      onError: () => {
        message.error(
          {
            key: messageKeyEdit,
            content: "Failed to Update.",
          },
          3,
        );

        queryClient.invalidateQueries(queryKey ?? QUERY_KEY_FEED_LIST);
      },
      onSuccess: (_, _arg) => {
        if (_arg.oldValue !== _arg.value) {
          successNotification({
            messageLabel: "Text has been saved",
            size: "big",
            linkText: "Undo",
            onClickLink: () => {
              result.mutate({
                rowIdentifier: _arg.rowIdentifier,
                field: _arg.field,
                value: _arg.oldValue,
                feedId: _arg.feedId,
                oldValue: _arg.oldValue,
              });
            },
          });
        }
      },
      onMutate: ({ rowIdentifier, field, value, fetchArgs }) => {
        const mutateKeys = queryKey ?? [QUERY_KEY_FEED_LIST, fetchArgs];

        updateOptimistic<FeedTblRow[]>(
          queryClient,
          mutateKeys,
          rowIdentifier,
          field,
          String(value),
        );
      },
    },
  );

  if (result.isLoading) {
    message.loading({
      key: messageKeyEdit,
      content: "Updating feed data...",
    });
  }

  return result;
};
