import { useState } from "react";
import {
  internalLeaseVariableNames,
  internalVehicleInfoVariableNames,
  nuCustomVariableNames,
  vehicleInfoVariableNames,
  allVariableNames,
} from "shared/constants/dataManagement";
import useDeepEffect from "shared/hooks/useDeepEffect";
import { IConfigurationState } from "shared/types/configuration";

export default (args: { feed: IConfigurationState["feed"] }) => {
  const [variables, setVariables] = useState<string[]>();

  useDeepEffect(() => {
    switch (args.feed) {
      case "internal":
        setVariables([
          ...internalVehicleInfoVariableNames,
          ...internalLeaseVariableNames,
        ]);
        break;

      case "nu":
        setVariables([
          ...vehicleInfoVariableNames,
          ...nuCustomVariableNames,
          // ...smartColumns TODO: fetch smartcolums and include them here
        ]);
        break;
      default:
        setVariables(allVariableNames);
        break;
    }
  }, [args.feed]);

  return variables;
};
