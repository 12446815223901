import { Button, Space, Checkbox, Dropdown, Menu, Modal, message } from "antd";
import { DownOutlined } from "@ant-design/icons";
import ColumnsOutlined from "shared/icons/ColumnsOutlined";

import { memo, useState, useEffect, FC } from "react";

import styles from "./DisplayColumnPicker.module.scss";

export interface IProps {
  columnKeys: string[];
  selectedColumnKeys?: string[];
  presets?: Record<string, string[]>;
  onConfirm?: (columnKeys: string[]) => void;
}

const DisplayColumnPicker: FC<IProps> = ({
  presets,
  columnKeys,
  selectedColumnKeys: selectedColumnKeysProp,
  onConfirm,
}) => {
  const [selectedColumnKeys, setSelectedColumnKeys] = useState<string[]>(
    selectedColumnKeysProp ?? columnKeys,
  );
  const [selectedPresetKey, setSelectedPresetKey] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (!presets) {
      return;
    }
    onConfirm?.(presets[selectedPresetKey]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPresetKey]);

  const menu = (
    <Menu>
      {["All", ...Object.keys(presets || {}), "Custom"].map(preset => (
        <Menu.Item
          onClick={e => {
            setIsModalVisible(e.key === "Custom");
            setSelectedPresetKey(e.key as string);
          }}
          key={preset}
        >
          {preset}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu} placement="bottomCenter" trigger={["click"]}>
        <Button className={styles.Button}>
          <Space size={6}>
            <ColumnsOutlined />
            <span>View:</span>
            {!selectedPresetKey && (
              <strong>
                {selectedColumnKeys.length === columnKeys.length && "All"}
                {selectedColumnKeys.length !== columnKeys.length && "Custom"}
              </strong>
            )}
            {selectedPresetKey && <strong>{selectedPresetKey}</strong>}
            <DownOutlined style={{ fontSize: 10 }} />
          </Space>
        </Button>
      </Dropdown>
      <Modal
        closable
        width={250}
        cancelText="Reset"
        visible={isModalVisible}
        title="Toggle Table Columns"
        cancelButtonProps={{
          type: "link",
          onClick: () => {
            message.info("Selected columns have been reset.");
            setSelectedPresetKey("");
            setSelectedColumnKeys(columnKeys);
            setIsModalVisible(false);
            onConfirm?.(columnKeys);
          },
        }}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        onOk={() => {
          setIsModalVisible(false);
          onConfirm?.(selectedColumnKeys);
        }}
      >
        <Space direction="vertical">
          <Checkbox.Group
            className={styles.flexCheckboxGroup}
            options={columnKeys}
            value={selectedColumnKeys}
            onChange={checkedValues => {
              setSelectedColumnKeys(checkedValues as string[]);
            }}
          />
        </Space>
      </Modal>
    </>
  );
};

export default memo(DisplayColumnPicker);
