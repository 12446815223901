import { useCallback, useEffect } from "react";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";
import { setFramed } from "redux/settings/settings.slice";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { useAppSelector } from "shared/hooks/useAppSelector";

type BaseMessage = { message: string; value: string };

export const FrameDaemon = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { framed } = useAppSelector(state => state.settings);
  const { pathname, search } = useLocation();
  const navType = useNavigationType();

  const initFrame = useCallback(() => {
    if (window.location !== window.parent.location) {
      dispatch(setFramed(true));
    }
  }, [dispatch]);

  const syncReady = useCallback(() => {
    if (framed) {
      const message = { message: "frame", value: "ready" };
      window.parent.postMessage(message, "*");
    }
  }, [framed]);

  const syncReceive = useCallback(() => {
    const handler = (event: MessageEvent<BaseMessage>) => {
      const { message, value: cleanPath = "" } = event.data;
      const addPreSlashPath = cleanPath.replace(/^\/?/, "/");
      const value = addPreSlashPath;
      if (message === "location" && value !== pathname + search) {
        const [url, qs] = value.split("?");
        const search = qs ? `?${qs}` : "";
        navigate({ pathname: url, search }, { replace: true });
      }
    };
    if (framed) {
      window.addEventListener("message", handler);
      return () => window.removeEventListener("message", handler);
    }
  }, [framed, pathname, search, navigate]);

  const syncSend = useCallback(() => {
    if (framed) {
      const removePreSlashPath = pathname.replace(/^\//, "");
      const v3Module = removePreSlashPath.startsWith("v3");
      const cleanPath =
        (v3Module
          ? removePreSlashPath.replace("v3/", "")
          : removePreSlashPath) + search;
      const message = {
        message: "location",
        value: cleanPath,
        navType,
        v3Module,
      };
      window.parent.postMessage(message, "*");
    }
  }, [framed, pathname, search, navType]);

  // const debugSend = useCallback(() => {
  //   const removePreSlashPath = pathname.replace(/^\//, "");
  //   const cleanPath = removePreSlashPath + search;
  //   const message = { message: "location", value: cleanPath, navType };
  //   console.log("debug:message", message);
  // }, [pathname, search, navType]);

  useEffect(initFrame, [initFrame]);
  useEffect(syncReady, [syncReady]);
  useEffect(syncReceive, [syncReceive]);
  useEffect(syncSend, [syncSend]);
  // useEffect(debugSend, [debugSend]);
  return null;
};
