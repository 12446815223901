import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useAuthenticated } from "shared/hooks/useAuthenticated";

import styles from "./Hamburger.module.scss";
interface IHamburger {
  onClick: () => void;
  collapsed: boolean;
}

const Hamburger = ({ onClick, collapsed }: IHamburger) => {
  const authenticated = useAuthenticated();

  return (
    <button
      className={`hamburger-icon ${styles.hamburger}`}
      onClick={onClick}
      disabled={!authenticated}
    >
      {collapsed && <MenuUnfoldOutlined className={styles.menuIcon} />}
      {!collapsed && <MenuFoldOutlined className={styles.menuIcon} />}
    </button>
  );
};

export default Hamburger;
