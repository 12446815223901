import { CSSProperties } from "react";

import styles from "./Highlight.module.scss";

export type THightlight = {
  id: string; // obj.name property which is uuid
  top: number;
  left: number;
  width: number;
  height: number;
};

interface HighlightProps {
  highlight: THightlight;
  style?: CSSProperties;
}

const Highlight: React.FC<HighlightProps> = props => {
  return (
    <div
      className={styles.Highlight}
      style={{
        top: props.highlight.top,
        left: props.highlight.left,
        width: props.highlight.width,
        height: props.highlight.height,
        ...(props.style || {}),
      }}
    ></div>
  );
};

export default Highlight;
