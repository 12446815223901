import { AppstoreOutlined, TableOutlined } from "@ant-design/icons";
import { Radio } from "antd";
import { useQPLayout } from "./useQPLayout";

export const LayoutSwitcher = () => {
  const [layout, setLayout] = useQPLayout();

  return (
    <Radio.Group value={layout} onChange={e => setLayout(e.target.value)}>
      <Radio.Button value="table">
        <TableOutlined />
      </Radio.Button>
      <Radio.Button value="grid">
        <AppstoreOutlined />
      </Radio.Button>
    </Radio.Group>
  );
};
