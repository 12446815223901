import { memo, useEffect, useState } from "react";
import { ChromePicker, CustomPicker, TwitterPicker } from "react-color";
import { ExportedColorProps } from "react-color/lib/components/common/ColorWrap";
import reactCSS from "reactcss";

interface IColorPicker extends ExportedColorProps {
  displayColorInput?: boolean;
}

function ColorPicker({
  color,
  onChange,
  onChangeComplete,
  displayColorInput,
}: IColorPicker) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  useEffect(() => {
    const body = document.querySelector("body");
    body?.addEventListener("click", closeColorPicker);
    return () => body?.removeEventListener("click", closeColorPicker);
  }, []);

  const border = "1px solid #D9D9D9";
  let colorHex = color?.toString();
  if (colorHex && !colorHex?.startsWith("#")) {
    colorHex = "#" + colorHex;
  }
  const styles = reactCSS({
    default: {
      triangle: {
        width: "0px",
        height: "0px",
        borderStyle: "solid",
        borderWidth: "0 9px 10px 9px",
        borderColor: "transparent transparent #fff transparent",
        position: "absolute",
        top: "-10px",
        left: "12px",
      },
      triangleShadow: {
        width: "0px",
        height: "0px",
        borderStyle: "solid",
        borderWidth: "0 9px 10px 9px",
        borderColor: "transparent transparent rgba(0,0,0,.1) transparent",
        position: "absolute",
        top: "-11px",
        left: "12px",
      },
      color: {
        width: "28px",
        height: "28px",
        background: colorHex,
        position: "relative",
      },
      swatch: {
        background: "#fff",
        borderRadius: "1px",
        border,
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        cursor: "initial",
        top: "calc(100% + 10px)",
        left: "-7px",
        zIndex: 2,
      },
    } as const,
  });

  const toggleColorPicker = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    setDisplayColorPicker(prevValue => !prevValue);
  };

  const closeColorPicker = () => {
    setDisplayColorPicker(false);
  };

  return (
    <div style={{ position: "relative" }}>
      {displayColorInput && (
        <TwitterPicker
          styles={{
            default: {
              body: { padding: "0" },
              card: {
                boxShadow: "none",
                display: "inline-block",
                background: "none",
              },
              hash: {
                border,
                marginRight: "1px",
                borderRadius: "none",
                background: "#FAFAFA",
                cursor: "default",
              },
              input: {
                border,
                borderRadius: "none",
                boxSizing: "border-box",
                height: "30px",
              },
            },
          }}
          colors={[]}
          triangle={"hide"}
          width={"132px"}
          color={color}
          onChange={onChange}
          onChangeComplete={onChangeComplete}
        />
      )}
      <div style={styles.swatch} onClick={toggleColorPicker}>
        <div style={styles.color}>
          {displayColorPicker && (
            <div style={styles.popover} onClick={e => e.stopPropagation()}>
              <div style={styles.triangleShadow} />
              <div style={styles.triangle} />
              <ChromePicker
                color={color}
                onChange={onChange}
                onChangeComplete={onChangeComplete}
                disableAlpha
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(CustomPicker(ColorPicker));
