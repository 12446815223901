import { useEffect, useState } from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import {
  ILifestyleImage,
  IAssetBuilderState,
  SelectedInstance,
} from "shared/types/assetBuilder";
import { ITemplate } from "shared/types/designStudio";
import ImageSelectionList from "./imageSelection/ImageSelectionList";
import { INewOrder } from "shared/types/newOrders";
import { IConfigurationState, IConfig } from "shared/types/configuration";
import { ImageSelectionHeader } from "./imageSelection/ImageSelectionHeader";
import { ImageSelectionTabs } from "./imageSelection/ImageSelectionTabs";
import useProcessImages from "./imageSelection/useProcessImages";

import actions from "redux/rootActions";
import "./ImageSelection.scss";

interface ImgSelectionProps {
  config?: IConfig;
  buildPage?: IAssetBuilderState["buildPage"];
  template?: ITemplate;
  selectedImageType: string;
  currOrder: INewOrder | undefined;
  savedSelectImageSearch: string;
  shouldUpdateThemeImage: boolean;
  selectedInstances: SelectedInstance[];
}

interface ImgSelectionHandlers {
  insertLifestyleImage?: (imageUrl: string, imageName: string) => void;
  saveSelectImageSearch: (searcyBy: string) => void;
  setSelectedImageType: (imageType: string) => void;
  setShouldUpdateThemeImage: (shouldUpdateThemeImage: boolean) => void;
  setNumBackgroundImages: (numBackgroundImages: number) => void;
}

const ImageSelection = (props: ImgSelectionProps & ImgSelectionHandlers) => {
  const [showUploader, toggleUploader] = useState<boolean>(false);
  const [lifestyleImages, setLifestyleImages] = useState<ILifestyleImage[]>([]);
  const [selectedInstance] = props.buildPage?.selectedInstance || [];
  const { instance } = selectedInstance || { instance: null };
  const { selectedOffer } = instance || { selectedOffer: null };
  const { template } = props || { template: instance?.template };
  const { offerData } = selectedOffer || { offerData: null };
  const {
    config,
    selectedImageType,
    setSelectedImageType,
    setShouldUpdateThemeImage,
    selectedInstances,
  } = props;
  const imageSelectionTitle =
    template?.type === "lifestyle" ? "Select image" : "Select Background Asset";

  const { isFetching } = useProcessImages({
    config,
    template,
    selectedImageType,
    setSelectedImageType,
    offerData,
    setLifestyleImages,
    setShouldUpdateThemeImage,
    selectedInstances,
  });

  useEffect(() => {
    if (
      selectedInstance &&
      (Object.keys(selectedInstance?.instance).length || -1) <= 0
    ) {
      setShouldUpdateThemeImage(true);
    }
  }, [setShouldUpdateThemeImage, selectedInstance]);

  if (!template) {
    return null;
  }
  return (
    <div className={`image-selection selection-container`}>
      <ImageSelectionHeader
        imageSelectionTitle={imageSelectionTitle}
        template={template}
        showUploader={showUploader}
        lifestyleImages={lifestyleImages}
        setLifestyleImages={setLifestyleImages}
        toggleUploader={toggleUploader}
      />
      {template.type === "lifestyle" && (
        <ImageSelectionTabs
          selectedImageType={selectedImageType}
          setSelectedImageType={setSelectedImageType}
        />
      )}
      <div className="content image-selection-content">
        <ImageSelectionList
          lifestyleImages={lifestyleImages}
          type={template.type || ""}
          fetching={isFetching}
          imageType={props.selectedImageType}
          config={props.config}
          selectedInstances={selectedInstances}
          instance={selectedInstance?.instance || {}}
          insertLifestyleImage={(imageUrl: string, imageName: string) => {
            props.insertLifestyleImage?.(imageUrl, imageName);
          }}
          saveSelectImageSearch={props.saveSelectImageSearch}
          savedSelectImageSearch={props.savedSelectImageSearch}
          currOrder={props.currOrder}
          onLifestyleImageDeleteComplete={id => {
            setLifestyleImages(lifestyleImages.filter(img => img.id !== id));
          }}
          shouldUpdateThemeImage={props.shouldUpdateThemeImage}
          setShouldUpdateThemeImage={props.setShouldUpdateThemeImage}
          setNumBackgroundImages={props.setNumBackgroundImages}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: {
  configuration: IConfigurationState;
  assetBuilder: IAssetBuilderState;
}) => {
  const {
    configuration: { config },
    assetBuilder: { buildPage },
  } = state;
  return {
    config,
    buildPage,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => {
  return {
    insertLifestyleImage: (imageUrl: string, imageName: string) => {
      dispatch(
        actions.assetBuilder.insertLifestyleImage({ imageUrl, imageName }),
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageSelection);
