import { ColumnProps } from "antd/lib/table";
import {
  legalLingoDataDictionary,
  offerTypeVariables,
  stateOptions,
} from "shared/constants/dataManagement";
import {
  IDisclosureVariable,
  ILegalLingoDataRecord,
  TDisclosureType,
  TRequiredVarsByDisclosureType,
} from "shared/types/legalLingo";

export const returnRequiredVarsByStateOrOem = (
  legalLingoVarMapping: TRequiredVarsByDisclosureType,
  section?: "state" | "oem",
) => {
  const updatedOfferTypeVariables = offerTypeVariables.map(offerTypeGroup => {
    const { offerType, variables } = offerTypeGroup;
    const currentLegalData = legalLingoVarMapping[offerType];
    if (!currentLegalData || currentLegalData.length < 1) {
      return offerTypeGroup;
    }
    const legalDataWithRequiredVars = currentLegalData.filter(legalObj => {
      const lingoKeys = Object.keys(legalObj);
      return (
        lingoKeys.filter(key => legalObj[key].toString().includes("true"))
          .length > 0
      );
    });

    if (legalDataWithRequiredVars.length < 1) {
      return offerTypeGroup;
    }

    const updatedVariables = returnRequiredVariables(
      variables,
      legalDataWithRequiredVars,
      section,
    );

    return {
      offerType,
      variables: updatedVariables,
    };
  });

  return updatedOfferTypeVariables;
};

export const getSnakeCaseVarName = (variable: IDisclosureVariable) => {
  const { name } = variable;
  const match = name.match(/([A-Z]?[^A-Z]*)/g);
  const splitAtCapitalLetter = match
    ? match.slice(0, -1)
    : name.split(/(?=[A-Z])/);

  const joinedSplit = splitAtCapitalLetter.join("_");
  let snakeCaseName = joinedSplit.toLowerCase();

  if (splitAtCapitalLetter.length > 1) {
    if (name.toLowerCase().includes("msrp")) {
      snakeCaseName = snakeCaseName.replace("m_s_r_p", "msrp");
    } else if (name.toLowerCase().includes("fico")) {
      snakeCaseName = snakeCaseName.replace("f_i_c_o", "fico");
    }
  }

  return snakeCaseName;
};

const returnRequiredVariables = (
  variables: IDisclosureVariable[],
  legalDataWithRequiredVars: ILegalLingoDataRecord[],
  section?: "state" | "oem",
) => {
  const updatedVariables = [...variables];
  legalDataWithRequiredVars.forEach(legalObj => {
    const { lingo_state: lingoState, lingo_oem: lingoOem } = legalObj;
    const requiredKeys = Object.keys(legalObj)
      .filter(key => legalObj[key].toString().includes("true"))
      .map(key => key.split("lingo_")[1]);
    const matchingVariables = variables.filter(variable => {
      const snakeCaseName = getSnakeCaseVarName(variable);
      return requiredKeys.includes(snakeCaseName);
    });
    matchingVariables.forEach(matchingVar => {
      const index = updatedVariables.findIndex(
        updatedVar => updatedVar.name === matchingVar.name,
      );

      if (section === "oem") {
        if (!updatedVariables[index].requiredByOems) {
          updatedVariables[index].requiredByOems = [];
        }
        (updatedVariables[index].requiredByOems as string[]).push(
          lingoOem.toString().toLowerCase(),
        );
        updatedVariables[index].requiredByOems = Array.from(
          new Set(updatedVariables[index].requiredByOems),
        );
      } else {
        updatedVariables[index].requiredByStates.push(lingoState.toString());
        updatedVariables[index].requiredByStates = Array.from(
          new Set(updatedVariables[index].requiredByStates),
        );
      }
    });
  });

  return updatedVariables;
};

export const initializeVariableTable = (
  offerType: TDisclosureType,
  oemNames?: string[],
  variableData?: Array<Record<string, string | number>>,
) => {
  const offerTypeVarObj = offerTypeVariables.find(
    obj => obj.offerType === offerType,
  ) || { offerType, variables: [] };

  /* the offerType variable names are all in camelCase */
  const dbVarNames = offerTypeVarObj.variables
    .filter(variable => !variable.name.includes("image"))
    .map(variable => getSnakeCaseVarName(variable));

  const lingoObjects = (
    oemNames && oemNames.length > 0 ? oemNames : stateOptions
  ).map((key: string) => {
    const primaryKey = oemNames ? "lingo_oem" : "lingo_state";
    const objectFromService = variableData
      ? variableData.find(record => record[primaryKey] === key)
      : null;

    const lingoObj: Record<string, string | number> = {
      created_at: objectFromService
        ? objectFromService.created_at
        : Math.round(Date.now() / 1000),
      updated_at: objectFromService
        ? objectFromService.updated_at
        : Math.round(Date.now() / 1000),
    };

    dbVarNames.forEach(name => {
      lingoObj[`lingo_${name}`] = objectFromService
        ? objectFromService[`lingo_${name}`] || "{'checked': false }"
        : "{'checked': false }";
    });

    lingoObj[primaryKey] = key;

    return lingoObj;
  });

  return lingoObjects;
};

export const getCamelCaseFromSnakeCase = (inputString: string) => {
  const splitName = inputString.split("_");
  if (splitName[0] === "lingo") {
    splitName.splice(0, 1);
  }
  const newSplit = splitName.map((str, index) => {
    if (index === 0) {
      return str;
    }
    return `${str.charAt(0).toUpperCase()}${str.substr(1)}`;
  });
  const camelCaseName = newSplit.join("");

  return camelCaseName;
};

const returnTitleFromCamelCase = (name: string) => {
  const match = name.match(/([A-Z]?[^A-Z]*)/g);
  const splitAtCapitalLetter = match
    ? match.slice(0, -1)
    : name.split(/(?=[A-Z])/);

  splitAtCapitalLetter[0] = `${splitAtCapitalLetter[0]
    .charAt(0)
    .toUpperCase()}${splitAtCapitalLetter[0].substr(1)}`;

  let title = splitAtCapitalLetter.join(" ");

  const rebateMatch = title.match(/\sRebate\d{1}/g);
  const customFieldMatch = title.match(/\sCustom\d{1}$/g);

  if (
    (rebateMatch && rebateMatch[0]) ||
    (customFieldMatch && customFieldMatch[0])
  ) {
    const matchItem = ((rebateMatch && rebateMatch[0]) ||
      (customFieldMatch && customFieldMatch[0]))!;
    const digit = matchItem[matchItem.length - 1];
    title = title.replace(digit, ` ${digit}`);
  }

  title = title.replace("Fico", "FICO");
  title = title.replace("Msrp", "MSRP");

  return title;
};

export const returnOfferTypeLingoData = (offerType: TDisclosureType) => {
  const originalData = legalLingoDataDictionary[offerType];
  const offerTypeVarObj = offerTypeVariables.find(
    obj => obj.offerType === offerType,
  ) || { offerType, variables: [] };

  const dbVarNames = offerTypeVarObj.variables
    .filter(variable => !variable.name.includes("image"))
    .map(variable => `lingo_${getSnakeCaseVarName(variable)}`);

  const newData = dbVarNames.map((name, index) => {
    const foundObject = originalData.find(obj => obj.field === name);
    const camelCaseName = getCamelCaseFromSnakeCase(name);
    if (foundObject) {
      return {
        ...foundObject,
        key: `${index}`,
      };
    }
    return {
      key: `${index}`,
      title: returnTitleFromCamelCase(camelCaseName),
      field: name,
      sampleData: "--",
    };
  });

  return newData;
};

export const stateLegalLingoColumns: Record<string, Array<ColumnProps<any>>> = {
  "Vehicle Info": [
    {
      title: "Vehicle Info",
      dataIndex: "title",
      key: "title",
      fixed: "left",
      width: "130px",
    },
    {
      title: "Sample Data",
      dataIndex: "sampleData",
      key: "sampleData",
      fixed: "left",
      width: "100px",
    },
  ],
  "Number at this Price": [
    {
      title: "Number at this Price",
      dataIndex: "title",
      key: "title",
      fixed: "left",
      width: "130px",
    },
    {
      title: "Sample Data",
      dataIndex: "sampleData",
      key: "sampleData",
      fixed: "left",
      width: "130px",
    },
  ],
  Lease: [
    {
      title: "Lease",
      dataIndex: "title",
      key: "title",
      fixed: "left",
      width: "130px",
    },
    {
      title: "Sample Data",
      dataIndex: "sampleData",
      key: "sampleData",
      fixed: "left",
      width: "100px",
    },
  ],
  "Zero Down Lease": [
    {
      title: "Zero Down Lease",
      dataIndex: "title",
      key: "title",
      fixed: "left",
      width: "130px",
    },
    {
      title: "Sample Data",
      dataIndex: "sampleData",
      key: "sampleData",
      fixed: "left",
      width: "100px",
    },
  ],
  Finance: [
    {
      title: "Finance",
      dataIndex: "title",
      key: "title",
      fixed: "left",
      width: "130px",
    },
    {
      title: "Sample Data",
      dataIndex: "sampleData",
      key: "sampleData",
      fixed: "left",
      width: "100px",
    },
  ],
  APR: [
    {
      title: "Purchase",
      dataIndex: "title",
      key: "title",
      fixed: "left",
      width: "130px",
    },
    {
      title: "Sample Data",
      dataIndex: "sampleData",
      key: "sampleData",
      fixed: "left",
      width: "100px",
    },
  ],
  Purchase: [
    {
      title: "Purchase",
      dataIndex: "title",
      key: "title",
      fixed: "left",
      width: "130px",
    },
    {
      title: "Sample Data",
      dataIndex: "sampleData",
      key: "sampleData",
      fixed: "left",
      width: "100px",
    },
  ],
};

export const oemLegalLingoColumns: Record<string, Array<ColumnProps<any>>> = {
  "Vehicle Info": [
    {
      title: "Vehicle Info",
      dataIndex: "title",
      key: "title",
      fixed: "left",
      width: "130px",
    },
    {
      title: "Sample Data",
      dataIndex: "sampleData",
      key: "sampleData",
      fixed: "left",
      width: "150px",
    },
  ],
  "Number at this Price": [
    {
      title: "Number at this Price",
      dataIndex: "title",
      key: "title",
      fixed: "left",
      width: "130px",
    },
    {
      title: "Sample Data",
      dataIndex: "sampleData",
      key: "sampleData",
      fixed: "left",
      width: "150px",
    },
  ],
  Lease: [
    {
      title: "Lease",
      dataIndex: "title",
      key: "title",
      fixed: "left",
      width: "130px",
    },
    {
      title: "Sample Data",
      dataIndex: "sampleData",
      key: "sampleData",
      fixed: "left",
      width: "150px",
    },
  ],
  "Zero Down Lease": [
    {
      title: "Zero Down Lease",
      dataIndex: "title",
      key: "title",
      fixed: "left",
      width: "130px",
    },
    {
      title: "Sample Data",
      dataIndex: "sampleData",
      key: "sampleData",
      fixed: "left",
      width: "150px",
    },
  ],
  Finance: [
    {
      title: "Finance",
      dataIndex: "title",
      key: "title",
      fixed: "left",
      width: "130px",
    },
    {
      title: "Sample Data",
      dataIndex: "sampleData",
      key: "sampleData",
      fixed: "left",
      width: "150px",
    },
  ],
  Purchase: [
    {
      title: "Purchase",
      dataIndex: "title",
      key: "title",
      fixed: "left",
      width: "130px",
    },
    {
      title: "Sample Data",
      dataIndex: "sampleData",
      key: "sampleData",
      fixed: "left",
      width: "150px",
    },
  ],
  APR: [
    {
      title: "APR",
      dataIndex: "title",
      key: "title",
      fixed: "left",
      width: "130px",
    },
    {
      title: "Sample Data",
      dataIndex: "sampleData",
      key: "sampleData",
      fixed: "left",
      width: "150px",
    },
  ],
};
