import { memo, useCallback, useEffect, useState } from "react";
import { Badge, Button } from "antd";
import classNames from "classnames";
import moment from "moment";
import { ProcessedRecord } from "shared/types/assetExporter";
import { getVersionColor, getVersionStatus } from "../utils";
import DetailsRow from "./DetailsRow";
import style from "./DrawerContent.module.scss";
import Link from "antd/lib/typography/Link";
import { TimeAgo } from "shared/components/TimeAgo";

type DrawerContentProps = {
  feedId: string;
  feedVersions: ProcessedRecord[];
  onDownload: (args: ProcessedRecord) => void;
  downloading: boolean;
};

const DrawerContentContainer = ({
  feedId,
  feedVersions,
  onDownload,
  downloading,
}: DrawerContentProps) => {
  const [selectedVersion, setSelectedVersion] = useState<ProcessedRecord>();
  const [indexSelected, setIndexSelected] = useState(0);

  const selectDefaultVersion = useCallback(() => {
    setSelectedVersion(feedVersions[0]);
  }, [feedVersions]);

  useEffect(selectDefaultVersion, [selectDefaultVersion]);

  return (
    <div className={style.drawer}>
      <div className={style.historyContainer}>
        {feedVersions.map((feed, idx) => {
          return (
            <div
              key={feed.createdAt}
              className={classNames(
                style.historyRow,
                indexSelected === idx && style.historyRowActive,
              )}
              onClick={() => {
                setIndexSelected(idx);
                setSelectedVersion(feedVersions[idx]);
              }}
            >
              <span className={style.historyColumn}>
                {`Version ${feedVersions.length - idx}`}
              </span>
              <span className={style.historyColumn}>
                <Badge color={getVersionColor(feed.status)} />
                {getVersionStatus(feed.status)}
              </span>
              <span
                className={classNames(
                  style.historyColumn,
                  style.historyColumnTime,
                )}
              >
                {feed.createdAt && <TimeAgo date={feed.createdAt} />}
              </span>
            </div>
          );
        })}
      </div>
      <div className={style.detailsContainer}>
        {selectedVersion && (
          <div className={style.body}>
            <DetailsRow label="Version:">
              {feedVersions?.length - indexSelected}
            </DetailsRow>
            <DetailsRow label="Status:">
              <Badge color={getVersionColor(selectedVersion.status)} />
              {getVersionStatus(selectedVersion?.status)}
            </DetailsRow>
            <DetailsRow label="Created on:">
              {moment(selectedVersion?.createdAt).format("YYYY/MM/DD hh:mma")}
            </DetailsRow>
            <DetailsRow label="Created by:">
              {selectedVersion?.createdBy}
            </DetailsRow>
            <DetailsRow label="Processed Rows Count:">
              {selectedVersion?.processedRowsCount?.toString() || "0"}
            </DetailsRow>
            <DetailsRow label="Feed Id:">{feedId}</DetailsRow>
            <DetailsRow label="Destination URL:">
              <Link className={style.text} href={selectedVersion?.jsonFileUrl}>
                {selectedVersion?.jsonFileUrl}
              </Link>
            </DetailsRow>

            <div className={style.detailsRow}>
              <div className={style.button}>
                <Button
                  data-cy="version-history-download-button"
                  disabled={
                    selectedVersion?.status !== "SUCCEEDED" || downloading
                  }
                  type="primary"
                  onClick={e => {
                    e.preventDefault();
                    onDownload(selectedVersion);
                  }}
                >
                  Download CSV
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(DrawerContentContainer);
