import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const MetaLogoSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    focusable="false"
  >
    <path
      d="M1.51217 8.31992C1.51217 8.85449 1.6295 9.26491 1.78286 9.51321C1.98394 9.83843 2.28383 9.97621 2.58958 9.97621C2.98393 9.97621 3.34469 9.87835 4.03992 8.91681C4.43252 8.37356 4.87446 7.67573 5.25577 7.07364L5.25578 7.07363C5.41542 6.82156 5.56443 6.58627 5.69474 6.38613L6.29001 5.47153C5.99938 5.05763 5.74935 4.74265 5.56962 4.55167C5.20983 4.16949 4.74732 3.70795 4.00925 3.70795C3.41188 3.70795 2.90458 4.12713 2.48004 4.76832C1.87974 5.67436 1.51217 7.02392 1.51217 8.31992ZM0 8.26589C0 9.08916 0.162608 9.81165 0.493668 10.3623C0.899218 11.038 1.55404 11.4864 2.58958 11.4864C3.46251 11.4864 4.12561 11.0945 4.90214 10.0604C5.34518 9.46987 5.56962 9.11204 6.4557 7.54096L6.89679 6.75955L7.00512 6.56765C7.04055 6.6257 7.07618 6.68465 7.11198 6.74446L8.36757 8.84183C8.78968 9.54777 9.33836 10.3331 9.80769 10.7751C10.4182 11.3511 10.9703 11.4864 11.5935 11.4864C12.4706 11.4864 13.0365 11.0813 13.3691 10.6251C13.4166 10.5599 13.4593 10.4937 13.4976 10.4275C13.814 9.87981 14 9.18653 14 8.24301C14 6.65586 13.6027 5.11788 12.7843 3.89685C12.0365 2.78195 11.0594 2.1875 10.0336 2.1875C9.42259 2.1875 8.81548 2.46014 8.25268 2.95089C7.87229 3.28265 7.51873 3.70143 7.19093 4.14424C6.7875 3.63352 6.41233 3.24426 6.04868 2.94748C5.35881 2.38419 4.69815 2.1875 4.03359 2.1875C3.02951 2.1875 2.13801 2.75967 1.46637 3.61862C1.37043 3.74132 1.27897 3.86988 1.19231 4.00346C0.410904 5.20843 0 6.7688 0 8.26589ZM8.611 6.24885C8.35447 5.82154 8.11095 5.43719 7.87795 5.09148C7.91945 5.02716 7.96076 4.96438 8.00195 4.90318C8.65579 3.93093 9.23759 3.38565 9.96056 3.38565C10.629 3.38565 11.2366 3.82771 11.7055 4.55118C12.3661 5.57114 12.666 6.9986 12.666 8.28487C12.666 9.1875 12.4518 9.97621 11.5935 9.97621C11.2551 9.97621 10.9946 9.84183 10.6227 9.39101C10.3335 9.03999 9.83933 8.29559 8.97079 6.84865L8.611 6.24885Z"
      fillOpacity="0.85"
    />
  </svg>
);

const MetaLogoIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={MetaLogoSvg} {...props} />
);

export default MetaLogoIcon;
