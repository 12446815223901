import ToolbarTable, {
  ToolbarButton,
} from "shared/components/toolbarTable/ToolbarTable";
import PMaxAssetGroupList from "./pmaxAssetGroups/PMaxAssetGroupList";
import AssetGroupDetailDrawer from "./pmaxAssetGroups/pmaxAssetGroupList/AssetGroupDetailDrawer";
import styles from "./PMaxAssetGroups.module.scss";
import { usePMaxDataContext } from "shared/components/contextAPI/pmaxAssetGroup/PMaxDataProvider";
import PmaxLoadDrawer from "./pmaxAssetGroups/PmaxLoadDrawer";
import FilterToolbar from "./pmaxAssetGroups/FilterToolbar";
import { generateEmptyAssetGroup } from "./pmaxAssetGroups/PmaxAssetGroupList.utils";
import { useCallback, useMemo } from "react";
import { InlineTableActions } from "shared/components/tableActions/InlineTableActions";
import { ContextTableActions } from "shared/components/tableActions/ContextTableActions";
import { IAssetGroup } from "shared/types/adLibrary";
import { TableActionsContextProvider } from "shared/components/HoverActionsVirtualTable/TableActionsContext";
import {
  DeleteOutlined,
  EditOutlined,
  FileDoneOutlined,
  PlusOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { pmaxTooltipProps } from "./pmaxAssetGroups/utils";

const PMaxAssetGroups = () => {
  const ctx = usePMaxDataContext();

  const {
    selectedDataSource: selectedAssetGroups,
    dataFilters,
    setDataFilters,
  } = ctx;

  const shouldDisableUpload = useCallback(
    (record?: IAssetGroup) => {
      if (record) {
        return record.feedStatus === "Error";
      }
      return (
        selectedAssetGroups.length == 0 ||
        selectedAssetGroups.some(grp => grp.feedStatus === "Error")
      );
    },
    [selectedAssetGroups],
  );

  const shouldDisableBulkEdit = useCallback(
    (record?: IAssetGroup) =>
      record ? false : ctx.selectedRowIds.length !== 1,
    [ctx.selectedRowIds],
  );

  const loadTooltipMsg = useCallback(
    (record?: IAssetGroup) => {
      if (record) {
        if (record.feedStatus === "Error") {
          return "Asset groups with errors cannot be loaded.";
        }
        return "Load to Google Ads";
      }

      const hasError = selectedAssetGroups.some(
        ({ feedStatus }) => feedStatus === "Error",
      );

      if (hasError)
        return "Asset groups with errors cannot be loaded. Please deselect them before loading.";

      if (selectedAssetGroups.length === 0)
        return "Please select asset groups before loading";

      return "Load selected asset groups to Google Ads";
    },
    [selectedAssetGroups],
  );

  const generateToolbarContents: (
    record?: IAssetGroup,
  ) => ToolbarButton = record => ({
    New: {
      disabled: false,
      onClick: () => {
        ctx.setSelectedRowIds([]);
        ctx.setEditAssetGroup(generateEmptyAssetGroup(), "create");
      },
      icon: <PlusOutlined />,
      extraInfo: {
        text: "New Asset Group",
        tooltip: "Create new Asset Group",
      },
    },
    Edit: {
      disabled: shouldDisableBulkEdit(record),
      icon: <EditOutlined />,
      onClick: () => {
        if (record) {
          ctx.setEditAssetGroup(record, "update");
          return;
        }
        const hasSelectedRows = ctx.selectedRowIds.length > 0;
        if (!hasSelectedRows) return;

        // Set the first row as selected
        const assetGroup = ctx.dataSource.find(
          data => data.id === ctx.selectedRowIds[0],
        );

        ctx.setEditAssetGroup(assetGroup!, "update");
      },
      showInInlineMenu: true,
      showInContextMenu: true,
    },
    Delete: {
      disabled: record != undefined ? !record : !ctx.selectedRowIds.length,
      icon: <DeleteOutlined />,
      onClick: () => {
        ctx.deleteAssetGroups(record);
      },
      showInInlineMenu: true,
      showInContextMenu: true,
    },
    Load: {
      disabled: shouldDisableUpload(record),
      extraInfo: {
        title: "Load to Google Ads",
        tooltip: loadTooltipMsg(record),
      },
      icon: <FileDoneOutlined />,
      onClick: () => {
        record && ctx.setSelectedRowIds([record?.id]);
        ctx.openLoadDrawer(record?.id);
      },
      showInInlineMenu: true,
      showInContextMenu: true,
    },
    Duplicate: {
      disabled: record ? false : ctx.selectedRowIds.length !== 1,
      showInInlineMenu: true,
      showInContextMenu: true,
      icon: <CopyOutlined />,
      onClick: () => (record ? ctx.duplicateAssetGroup(record) : null),
    },
  });

  const toolbarContents = useMemo(generateToolbarContents, [
    ctx,
    loadTooltipMsg,
    shouldDisableBulkEdit,
    shouldDisableUpload,
  ]);

  return (
    <div className={styles.container}>
      <ToolbarTable
        toolbarContents={toolbarContents}
        clientType={"auto"}
        searchPlaceholder="Search"
        searchValue={dataFilters?.search || ""}
        tooltipProps={pmaxTooltipProps}
        onSearch={search => {
          setDataFilters?.({
            displaySelectedOnly: dataFilters?.displaySelectedOnly || false,
            search,
          });
        }}
        titleTooltip="Search by any asset group information"
      />
      <FilterToolbar />
      <TableActionsContextProvider
        renderContextActions={(record?: IAssetGroup) => (
          <ContextTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
        renderHoverActions={(record?: IAssetGroup) => (
          <InlineTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
      >
        <PMaxAssetGroupList />
      </TableActionsContextProvider>
      <AssetGroupDetailDrawer />
      <PmaxLoadDrawer />
    </div>
  );
};
export default PMaxAssetGroups;
