import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import Cell, {
  Props as CellProps,
  Handlers as CellHandlers,
  TCellOfferKey,
} from "./row/Cell";

import styles from "./Row.module.scss";
import { TCollapsableTypes } from "../OfferTypeCollapse";
import { TOfferCollapseData } from "screens/assetBuilder/offers/SelectV2";
import { Button, Space, Tooltip } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useQueryClient } from "react-query";
import {
  ISavedOrderState,
  isRebateOfferKey,
  OfferEditFieldObject,
} from "shared/types/assetBuilder";
import { isEmpty } from "lodash";
import { TOfferListSection } from "screens/assetBuilder/offers/select.hooks/useOfferList";
import { TOfferListData } from "screens/assetBuilder/PaymentEngineModal";
import { OfferType } from "shared/types/shared";
type TRowData = Array<CellProps>;

type RowTypes = "header" | "row" | RepeatableRowTypes;

export type RepeatableRowTypes = "row_repeat_add" | "row_repeat_delete";

export interface Props {
  vin?: string;
  offerType?: TCollapsableTypes; // If header row, offer type is needed. If not, dont need.
  offerListData?: TOfferListData;
  type: RowTypes;
  row: TRowData;
  requirePlaceholderCells?: boolean;
  isSelected?: boolean;
  isEditDisabled?: boolean;
  editedPairs?: TOfferCollapseData["editedPairs"];
  repeatableIndex?: number;
  repeatableLength?: number;
  sectionKey: TOfferListSection;
  revertToOriginalSession?: boolean;
  savedOrder: ISavedOrderState;
  selectedOfferTypes?: OfferType[];
}

export type TOnRepeatableRowUpdateArgs = {
  operation: "add" | "delete";
  offerType: TCollapsableTypes;
  keys: TCellOfferKey[];
  repeatableIndex?: number;
  orderId: string;
};
export interface Handlers {
  onOfferTypeUpdate?: CellHandlers["onOfferTypeUpdate"];
  setSelectedOfferTypes?: Dispatch<SetStateAction<OfferType[]>>;
  setActiveKey?: Dispatch<SetStateAction<string[]>>;
  onDataUpdate?: () => void;
  onSessionUpdate?: CellHandlers["onSessionUpdate"];
  onRepeatableRowUpdate?: (args: TOnRepeatableRowUpdateArgs) => Promise<void>;
  setEditOfferData?: () => void;
  togglePaymentEngineModal?: (
    initialSelectedRow: OfferEditFieldObject | null,
  ) => void;
  setVin?: Dispatch<SetStateAction<string>>;
}

const Row = (props: Props & Handlers) => {
  const queryClient = useQueryClient();
  const classHeaderType = props.type === "header" ? styles.Header : styles.Row;
  const numOfTotalCells = useMemo(() => {
    switch (props.type) {
      case "header":
        return 7;

      case "row_repeat_add":
      case "row_repeat_delete":
        return 4; // -1 for the '+' sign

      default:
        return 5;
    }
  }, [props.type]);

  const onClickRepeatableRow = useCallback(
    (args: {
      operation: "delete" | "add";
      offerType: Props["offerType"];
      row: Props["row"];
      repeatableIndex: Props["repeatableIndex"];
      onRepeatableRowUpdate: Handlers["onRepeatableRowUpdate"];
    }) => {
      if (!args.offerType || args.offerType === "vehicleInfo") return;

      const keys: TCellOfferKey[] = props.row
        .filter(d => !!d.offerKey)
        .map(d => d.offerKey!);
      if (isEmpty(keys)) return;

      const process = async () => {
        await args.onRepeatableRowUpdate?.({
          operation: args.operation,
          offerType: args.offerType!,
          keys,
          repeatableIndex: args.repeatableIndex,
          orderId: props.savedOrder.orderId,
        });

        await queryClient.invalidateQueries("offer-list");
      };

      process();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.savedOrder, props.row],
  );

  const maxRepeatableIndex = useMemo(() => {
    const isRepeatableRow = (
      ["row_repeat_add", "row_repeat_delete"] as Props["type"][]
    ).includes(props.type);
    if (!isRepeatableRow) return 0;

    // user can add up to 6 rebate fields, and up to 5 custom fields
    return props.row.map(d => d.offerKey).every(isRebateOfferKey)
      ? 6 // rebate fields can have 6 items
      : 5; // custom fields can have 5 items
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ableToUpdateRow = useMemo(
    () => (props.repeatableLength || 0) - 1 === props.repeatableIndex,
    [props.repeatableLength, props.repeatableIndex],
  );

  const isRowAddable = useMemo(
    () => (props.repeatableIndex ?? 0) < maxRepeatableIndex,
    [props.repeatableIndex, maxRepeatableIndex],
  );

  const isRowDeletable = useMemo(
    () => (props.repeatableIndex ?? 0) > 0,
    [props.repeatableIndex],
  );
  return (
    <div
      className={`${styles.Row} ${classHeaderType}`}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {props.row.map((item, idx) => {
        return (
          <div
            key={`row-cell-${item.offerKey}-${idx}`}
            className={styles.CellWrapper}
          >
            <Cell
              {...item}
              sectionKey={props.sectionKey}
              repeatableIndex={props.repeatableIndex}
              vin={props.vin}
              offerType={props.offerType}
              offerListData={props.offerListData}
              isOfferTypeSelected={props.isSelected}
              onOfferTypeUpdate={props.onOfferTypeUpdate}
              selectedOfferTypes={props.selectedOfferTypes}
              setSelectedOfferTypes={props.setSelectedOfferTypes}
              setActiveKey={props.setActiveKey}
              isEditDisabled={props.isEditDisabled}
              togglePaymentEngineModal={props.togglePaymentEngineModal}
              isRevertable={
                item.offerKey ? !!props.editedPairs?.[item.offerKey] : false
              }
              feedOriginalValue={
                item.offerKey ? props.editedPairs?.[item.offerKey] : undefined
              }
              onSessionUpdate={props.onSessionUpdate}
              setVin={props.setVin}
              revertToOriginalSession={props.revertToOriginalSession}
            />
          </div>
        );
      })}

      {(props.type === "row_repeat_add" ||
        props.type === "row_repeat_delete") && (
        <Space className={styles.RepeatableFieldButtonWrapper} align="center">
          {ableToUpdateRow && (
            <>
              {isRowAddable && (
                <Tooltip title="Add row">
                  <Button
                    icon={<PlusOutlined />}
                    size="small"
                    onClick={e => {
                      e.preventDefault();

                      // all items in row(which is an array) must have valid "value" in order to add new repeated row.
                      // AV2-5659 lease rebate disclosuer field is optional.
                      const okToProceed = props.row
                        .filter(row => !row.offerKey?.includes("Disclosure"))
                        .every(item => !!item.value?.trim());

                      if (!okToProceed) return;

                      onClickRepeatableRow({
                        operation: "add",
                        offerType: props.offerType,
                        repeatableIndex: props.repeatableIndex,
                        onRepeatableRowUpdate: props.onRepeatableRowUpdate,
                        row: props.row,
                      });
                    }}
                  />
                </Tooltip>
              )}

              {isRowDeletable && (
                <Tooltip title="Delete row">
                  <Button
                    icon={<MinusOutlined />}
                    size="small"
                    onClick={e => {
                      e.preventDefault();

                      onClickRepeatableRow({
                        operation: "delete",
                        offerType: props.offerType,
                        repeatableIndex: props.repeatableIndex,
                        onRepeatableRowUpdate: props.onRepeatableRowUpdate,
                        row: props.row,
                      });
                    }}
                  />
                </Tooltip>
              )}
            </>
          )}
        </Space>
      )}

      {props.row.length < numOfTotalCells
        ? [...Array(numOfTotalCells - props.row.length).keys()].map(num => {
            return (
              <div
                className={styles.CellWrapper}
                key={`placeholder-cell-${num}`}
              ></div>
            );
          })
        : null}
    </div>
  );
};

export default Row;
