import { SearchOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import { ITableColumnURL } from "shared/components/dataListURL/DataListURLTable";
import { FilterDropdownWithFilterSearch } from "shared/components/FilterDropdownWithFilterSearch";
import {
  PrintOptionsTableColumns,
  PrintOptionsTableItem,
} from "shared/types/printOptions";

export const columns: ITableColumnURL<
  PrintOptionsTableColumns,
  PrintOptionsTableItem
>[] = [
  {
    key: "name",
    title: "Name",
    dataIndex: ["name"],
    align: "left",
    filterDropdown: FilterDropdownWithFilterSearch,
    filterIcon: <SearchOutlined />,
    width: 400,
  },
  {
    key: "printMode",
    title: "Print Mode",
    dataIndex: ["printMode"],
    filterDropdown: FilterDropdownWithFilterSearch,
    align: "left",
    filterIcon: <SearchOutlined />,
    width: 160,
    render: (_, record) =>
      !!record?.printMode.length &&
      record.printMode.map(mode => <Tag key={mode}>{mode}</Tag>),
  },
  {
    key: "copyQuantity",
    title: "Copy Quantity",
    dataIndex: ["costPerCopy", "quantity"],
    filterDropdown: FilterDropdownWithFilterSearch,
    align: "left",
    width: 160,
    filterIcon: <SearchOutlined />,
    render: (_, record) =>
      record?.costPerCopy.length == 1 &&
      `${record.costPerCopy[0].quantity.min} - ${record.costPerCopy[0].quantity.max}`,
  },
  {
    key: "copyCost",
    title: "Cost",
    dataIndex: ["costPerCopy", "cost"],
    filterDropdown: FilterDropdownWithFilterSearch,
    align: "left",
    width: 160,
    filterIcon: <SearchOutlined />,
    render: (_, record) =>
      record?.costPerCopy.length == 1 && `$${record.costPerCopy[0].cost}`,
  },
  {
    key: "weight",
    title: "Weight",
    dataIndex: ["weight"],
    align: "left",
    width: 160,
    filterDropdown: FilterDropdownWithFilterSearch,
    filterIcon: <SearchOutlined />,
  },
  {
    key: "associatedTemplates",
    title: "Associated Templates",
    dataIndex: ["associatedTemplates"],
    align: "left",
    width: 160,
    filterDropdown: FilterDropdownWithFilterSearch,
    filterIcon: <SearchOutlined />,
  },
  {
    key: "referenceNumber",
    title: "Reference No",
    dataIndex: ["referenceNumber"],
    align: "left",
    width: 160,
    filterDropdown: FilterDropdownWithFilterSearch,
    filterIcon: <SearchOutlined />,
  },
];
