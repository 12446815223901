import { Popover } from "antd";
import { useCallback } from "react";
import { ColorResult, SketchPicker } from "react-color";
import styles from "./Color.module.scss";

type Props = {
  fill: string;
  onColorSelected: (color: string) => void;
};
const Color = (props: Props) => {
  const { onColorSelected, fill } = props;

  const onChange = useCallback(
    (result: ColorResult) => {
      const { hex } = result;
      onColorSelected(hex);
    },
    [onColorSelected],
  );

  return (
    <div className={styles.Color}>
      <Popover
        trigger="click"
        placement="bottomRight"
        content={
          <SketchPicker disableAlpha={true} color={fill} onChange={onChange} />
        }
      >
        <span
          className={styles.SelectedColor}
          style={{ background: `${fill}` }}
        />
      </Popover>
      <span className={styles.SelectedColorHex}>{fill}</span>
    </div>
  );
};

export default Color;
