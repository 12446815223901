import { Empty, message, Spin, Tooltip } from "antd";
import { context } from "../../../Editor.context";

import "./PrimaryMessage.scss";
import useDeepEffect from "shared/hooks/useDeepEffect";
import { isStamp } from "shared/types/designStudio";
import { CheckOutlined, CopyOutlined } from "@ant-design/icons";
import { FC, useContext, useRef, useState } from "react";

const PrimaryMessage: FC = () => {
  const editorContext = useContext(context);
  const [copied, setCopied] = useState<boolean>(false);
  const renderCopyTooltipTitle = (showCheckMarkAtEnd = false) => {
    return (
      <div>
        {copied ? "copied" : "copy"}
        {showCheckMarkAtEnd ? <CheckOutlined style={{ color: "green" }} /> : ""}
      </div>
    );
  };

  const primaryTextAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const { resource, messagingForStamp } = editorContext || {
    resource: undefined,
  };
  useDeepEffect(() => {
    if (!resource || !isStamp(resource)) return;

    editorContext?.fetchPrimaryMessage(resource);
  }, [resource]);

  return (
    <div className="primary-message-container">
      <Spin tip="Loading..." spinning={editorContext?.loadingPrimaryMessage}>
        {messagingForStamp?.messagings &&
          messagingForStamp.messagings.map(messagingObj => (
            <div
              key={`primary-message-${messagingObj.oem}`}
              className="message-text-container"
            >
              <div className="label">{messagingObj.oem}</div>
              <div className="text-content">
                {messagingObj.messaging.primaryMessage}

                <span>
                  <Tooltip
                    className="copy-tooltip"
                    title={
                      !copied
                        ? renderCopyTooltipTitle(copied)
                        : renderCopyTooltipTitle(copied)
                    }
                    placement="right"
                  >
                    <CopyOutlined
                      className="copy-icon"
                      onClick={() => {
                        if (
                          !primaryTextAreaRef ||
                          !primaryTextAreaRef.current
                        ) {
                          message.error(
                            "There was an error while copying this text.",
                          );
                          return;
                        }
                        primaryTextAreaRef.current.value =
                          messagingObj.messaging.primaryMessage;

                        primaryTextAreaRef.current.select();
                        document.execCommand("copy");

                        setCopied(true);

                        setTimeout(() => {
                          setCopied(false); // reset
                        }, 500);
                      }}
                    />

                    <textarea
                      className="copy-textarea"
                      ref={primaryTextAreaRef}
                      readOnly={true}
                      value={
                        messagingObj
                          ? messagingObj.messaging.primaryMessage
                          : ""
                      }
                    />
                  </Tooltip>
                </span>
              </div>
            </div>
          ))}

        {/* {(!messagingForStamp ||
          !messagingForStamp?.messagings ||
          messagingForStamp.messagings?.length === 0) && (
          <span className="message-text-container">No message</span>
        )} */}
        {editorContext?.messagingForStamp?.messagings?.length === 0 && (
          <Empty />
        )}
      </Spin>
    </div>
  );
};

export default PrimaryMessage;
