import { useCallback } from "react";
import { notification } from "antd";
import { AdReviewTableEntry } from "shared/types/campaignManagement";
import { QCStatus } from "shared/types/shared";
import { useUser } from "shared/hooks/useUser";
import useAdReviewPreferences from "./useAdReviewPreferences";
import { getQueryKey as getFetchAdsKey } from "./useFetchAds";
import { transformMillisecondsToSeconds } from "utils/helpers.date";
import useBatchMutateQCHistoryLog from "shared/hooks/qcHistoryLogs/useMutateQCHistoryLog";
import { getErrorMessage } from "utils/errorMessage";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { selectRequestParams } from "redux/adReview/adReview.slice";

interface IUseQCStatus {
  record: AdReviewTableEntry;
  options?: {
    onSuccess: (qcStatus: QCStatus) => void;
  };
}

export const useQCStatus = ({ record, options }: IUseQCStatus) => {
  const { selectedStore, selectedDateRange } = useAdReviewPreferences();
  const { platform, limit } = useAppSelector(selectRequestParams);
  const user = useUser();

  const onError = (error: Error) => {
    notification.error({
      bottom: 45,
      message: "",
      description:
        getErrorMessage(error) ||
        "Something went wrong when trying to change the status",
      placement: "bottomRight",
    });
  };

  const fetchAdsKey = getFetchAdsKey({
    accountId: selectedStore?.fbAccountId || "",
    since: transformMillisecondsToSeconds(selectedDateRange[0]),
    to: transformMillisecondsToSeconds(selectedDateRange[1]),
    platform,
    limit,
  });

  const { mutate: mutateQCHistoryLog } = useBatchMutateQCHistoryLog({
    fetchAdsKey,
    options: { onSuccess: options?.onSuccess, onError },
  });

  const handleQCHistoryLogInsert = useCallback(
    async (status: QCStatus) => {
      const adIds = record.id.split("_");
      const currentDate = new Date().toISOString();

      const nameWords = user?.name?.split(" ");
      const firstName = nameWords?.[0] ?? "";
      const lastName = nameWords?.[1] ?? "";

      const newQCHistoryLogs = adIds.map(id => ({
        id: record.id,
        adId: id,
        firstName,
        lastName,
        status,
        createdAt: currentDate,
        updatedAt: currentDate,
      }));

      mutateQCHistoryLog(newQCHistoryLogs);
    },
    [record.id, user?.name, mutateQCHistoryLog],
  );

  return {
    handleQCHistoryLogInsert,
    qcStatus: record.qcStatus,
  };
};
