import { Drawer } from "antd";
import GenerateUrlForm from "./generateUrlDrawer/GenerateUrlForm";
import GeneratedUrls from "./generateUrlDrawer/GeneratedUrls";

export const eaBaseUrl = process.env.REACT_APP_AV2_EVERYTHING_ADS_BASE_URL;

export type GenerateUrlFormValues = {
  store: string;
  gtmId: string;
  slug: string;
};

type Props = {
  handleClose: () => void;
  selectedInstExpId: string;
};

const GenerateUrlDrawer = ({ handleClose, selectedInstExpId }: Props) => {
  if (!selectedInstExpId) return null;

  return (
    <Drawer
      title="Generate Everything Ad URL"
      visible
      onClose={handleClose}
      width={"calc(100vw - 48px)"}
      footer={null}
    >
      <GenerateUrlForm selectedInstExpId={selectedInstExpId} />
      <GeneratedUrls everythingAdId={selectedInstExpId} />
    </Drawer>
  );
};

export default GenerateUrlDrawer;
