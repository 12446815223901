import { ExclamationCircleOutlined } from "@ant-design/icons";
import styles from "./CellError.module.scss";

const CellError = ({ errorMessage }: { errorMessage: string }) => {
  return (
    <div className={styles.container}>
      <span className={styles.icon}>
        <ExclamationCircleOutlined />
      </span>
      <span className={styles.message}>{errorMessage}</span>
    </div>
  );
};

export default CellError;
