import { useQuery } from "react-query";
import API from "services";
import { UseGetTabData } from "shared/hooks/assetBuilder/useGetTabData";

export const useRandomOfferSearch = (params: UseGetTabData) => {
  const { data, isError } = useQuery({
    queryKey: [
      "offerSearch",
      params.feedId,
      params.dealerOem,
      params.dealerCode,
    ],
    queryFn: async () => {
      try {
        const { feedId } = params;
        if (!feedId) return undefined;

        const pageRes = await API.services.assetBuilder.getTabData(params, 1);
        return pageRes?.result?.total || 1;
      } catch (_e) {
        return 1;
      }
    },
  });

  return { data, isError };
};
