/* eslint-disable react/display-name */
import { Key, useCallback } from "react";
import { Avatar, Space, Table, Typography, TableColumnProps } from "antd";

import { getColorFromConflictStatus } from "screens/adLibrary/utils";

import { ConflictStatus, IAd, IAdLibraryState } from "shared/types/adLibrary";
import Placeholder from "statics/images/image-placeholder.png";
import { shallowEqual, useSelector } from "react-redux";
import { compareString } from "utils/helpers";
import { hasConflicts, hasFacebookAdsToUpdate } from "../../shared/utils";

interface AdTableEntry {
  key: string;
  id: string;
  name: string;
  thumbnail?: string;
  locations: number;
}

type AdSelectTableProps = {
  ads: IAd[];
  onSelectionChange?: (selectedRowKeys: Key[]) => void;
};

const AdSelectTable = ({ ads = [], onSelectionChange }: AdSelectTableProps) => {
  const { adLoadParameters, facebookDataIdsByAd, adLoadDestination } =
    useSelector(
      ({ adLibrary }: { adLibrary: IAdLibraryState }) => ({
        adLoadParameters: adLibrary.adLoad.adLoadParameters,
        facebookDataIdsByAd: adLibrary.adLoad.facebookDataIdsByAd,
        adLoadDestination: adLibrary.adLoad.destination,
      }),
      shallowEqual,
    );

  const selectedRowKeys =
    adLoadDestination?.selectedAds?.map(ad => ad.id!) || [];

  const returnConflictStatus = useCallback(
    (adId: string) => {
      if (hasConflicts(adId, adLoadParameters)) {
        return ConflictStatus.CONFLICT;
      } else if (hasFacebookAdsToUpdate(adId, facebookDataIdsByAd)) {
        return ConflictStatus.READY;
      }

      return ConflictStatus.EMPTY;
    },
    [adLoadParameters, facebookDataIdsByAd],
  );

  const data: AdTableEntry[] = ads.map(ad => ({
    key: ad.id,
    id: ad.id,
    thumbnail: ad.visuals.thumbnail,
    name: ad.inputParameters.name || "",
    locations: hasConflicts(ad.id, adLoadParameters)
      ? 0
      : facebookDataIdsByAd?.[ad.id]?.ad.length ?? 0,
  }));

  const columns: Array<TableColumnProps<AdTableEntry>> = [
    {
      width: 12,
      title: (
        <Avatar
          size={12}
          style={{
            backgroundColor: getColorFromConflictStatus(ConflictStatus.EMPTY),
          }}
        />
      ),
      render: (_value, record) => {
        return (
          <Avatar
            size={12}
            style={{
              backgroundColor: getColorFromConflictStatus(
                returnConflictStatus(record.id),
              ),
            }}
          />
        );
      },
    },
    {
      title: "Ads",
      dataIndex: "name",
      render: (text, record) => (
        <Space direction="horizontal" align="center">
          <img height={32} width={32} src={record?.thumbnail || Placeholder} />
          <Typography.Paragraph
            ellipsis={{ rows: 2, tooltip: true }}
            style={{ margin: 0 }}
          >
            {text}
          </Typography.Paragraph>
        </Space>
      ),
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => compareString(a.name, b.name),
    },
    {
      title: "Matched",
      dataIndex: "locations",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.locations - b.locations,
      render: locations => `${locations} Locations`,
    },
  ];

  return (
    <Table
      size="small"
      columns={columns}
      dataSource={data}
      pagination={false}
      rowSelection={{
        type: "checkbox",
        selectedRowKeys,
        onChange: onSelectionChange,
        getCheckboxProps: record => ({
          id: record.id,
          name: record.name,
        }),
      }}
    />
  );
};

export default AdSelectTable;
