import { CSSProperties, useCallback, useContext } from "react";
import {
  EdgeProps,
  getBezierPath,
  getEdgeCenter,
  getMarkerEnd,
} from "react-flow-renderer";
import useIEContextAPI from "screens/everythingAds.hooks/useContextAPI";
import styles from "./Edge.module.scss";

const foreignObjectSize = 40;

const Edge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  arrowHeadType,
  markerEndId,
}: EdgeProps) => {
  const { Context } = useIEContextAPI();
  const ieContext = useContext(Context);
  const { hoveringEdge } = ieContext || {};

  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();

      ieContext?.eventCallbacks.onRemoveEdge?.(id);
    },
    [ieContext, id],
  );

  const defaultStyle: CSSProperties = {
    stroke: "#3fa9ff",
    strokeWidth: 2,
  };
  return (
    <>
      <path
        id={id}
        style={{ ...defaultStyle, ...style }}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      {hoveringEdge?.id === id && (
        <foreignObject
          width={foreignObjectSize}
          height={foreignObjectSize}
          x={edgeCenterX - foreignObjectSize / 2}
          y={edgeCenterY - foreignObjectSize / 2}
          className={styles.ButtonWrapper}
          requiredExtensions="http://www.w3.org/1999/xhtml"
        >
          <button className={styles.Button} onClick={onClick}>
            ×
          </button>
        </foreignObject>
      )}
    </>
  );
};

export default Edge;
