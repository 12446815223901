import { useEffect, useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  BeforeCapture,
} from "react-beautiful-dnd";
import styles from "./DraggableList.module.scss";

interface Props<ListItem, OriginalListItem> {
  listItems: ListItem[];
  originalListItems?: OriginalListItem[];
  updateOriginalListItems?: (list: OriginalListItem[]) => void;
  handleOnBeforeCapture?: (beforeCapture: BeforeCapture) => void;
  handleDragEnd?: (droppedItem: DropResult) => void;
}

export const DraggableList = <ListItem, OriginalListItem>({
  listItems,
  originalListItems,
  updateOriginalListItems,
  handleOnBeforeCapture,
  handleDragEnd,
}: Props<ListItem, OriginalListItem>) => {
  const [itemList, setItemList] = useState(listItems);

  useEffect(() => {
    setItemList(listItems);
  }, [listItems]);

  const handleDrop = (droppedItem: DropResult) => {
    const { destination } = droppedItem;
    if (!destination) return;

    const { index: sourceIndex } = droppedItem.source;
    const { index: destinationIndex } = destination;

    const [reorderedItem] = itemList.splice(sourceIndex, 1);
    const [reorderedOriginalItem] = (originalListItems ?? []).splice(
      sourceIndex,
      1,
    );

    itemList.splice(destinationIndex, 0, reorderedItem);
    (originalListItems ?? []).splice(
      destinationIndex,
      0,
      reorderedOriginalItem,
    );

    setItemList([...itemList]);
    updateOriginalListItems?.([...(originalListItems ?? [])]);
    handleDragEnd?.(droppedItem);
  };

  return (
    <DragDropContext
      onDragEnd={handleDrop}
      onBeforeCapture={handleOnBeforeCapture}
    >
      <Droppable droppableId="list-container">
        {provided => (
          <div
            className={styles.listContainer}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {itemList.map((item, index) => (
              <Draggable
                key={index}
                draggableId={index.toString()}
                index={index}
              >
                {provided => (
                  <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                  >
                    {item}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
