import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useMemo } from "react";
import { useCurrentSession } from "screens/adLibrary/adLoadV2/hooks/useCurrentSession";
import { AdType } from "screens/adLibrary/facebookUtils/types";
import { useAds } from "screens/adLibrary/shared/hooks/useAds";
import { compareStringBy } from "utils/helpers.table";
import AdNameCell from "../../../shared/AdNameCell";
import { startCase } from "lodash";

type AdSelectTableEntry = {
  id: string;
  name: string | undefined;
  thumbnail: string | undefined;
  type: AdType;
};

type Props = {
  selectedAdIds: string[];
  setSelectedAdIds: (selectedRowKeys: string[]) => void;
};

const AdSelectTable = ({ selectedAdIds, setSelectedAdIds }: Props) => {
  const data = useDataSource();
  const { isLoading } = useAds();

  return (
    <Table
      loading={isLoading}
      size="small"
      pagination={false}
      columns={columns}
      dataSource={data}
      rowKey={record => record.id}
      rowSelection={{
        type: "checkbox",
        selectedRowKeys: selectedAdIds,
        onChange: newSelectedRowKeys =>
          setSelectedAdIds(newSelectedRowKeys as typeof selectedAdIds),
        getCheckboxProps: record => ({
          id: record.id,
          name: record.name,
        }),
      }}
    />
  );
};

export default AdSelectTable;

const columns: ColumnsType<AdSelectTableEntry> = [
  {
    title: "Ads",
    dataIndex: "name",
    render: (_, record) => (
      <AdNameCell name={record.name ?? ""} thumbnail={record.thumbnail} />
    ),
    sortDirections: ["ascend", "descend"],
    sorter: compareStringBy("name"),
  },
  {
    title: "Format",
    dataIndex: "type",
    render: startCase,
    sortDirections: ["ascend", "descend"],
    sorter: compareStringBy("type"),
  },
];

const useDataSource = () => {
  const { originalData: ads } = useAds();
  const { session } = useCurrentSession();
  const { adIdsToLoad } = session.data;

  return useMemo(() => {
    const selectedAds = ads.filter(ad => adIdsToLoad.includes(ad.id));
    return selectedAds.map<AdSelectTableEntry>(ad => ({
      id: ad.id,
      name: ad.inputParameters.name,
      thumbnail: ad.visuals.thumbnail25,
      type: ad.type,
    }));
  }, [adIdsToLoad, ads]);
};
