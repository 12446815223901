import { useQuery, UseQueryOptions } from "react-query";
import { orderBy } from "lodash";
import API from "services";
import { IStamp } from "shared/types/designStudio";

const getStampsByOEM = (oems: string[]) => async () => {
  const { result, error } = await API.services.designStudio.getStampsByOEM(
    oems,
  );

  if (error) {
    throw Error(error.message);
  }

  const stamps = result?.stamps || [];

  const orderedStamps = orderBy(
    stamps,
    [stmp => stmp.name, stmp => stmp.width, stmp => stmp.height],
    ["asc", "asc", "asc"],
  );

  const stampsGroupedById = orderedStamps.reduce((acc, stmp) => {
    if (acc[stmp.id]) return acc;

    acc[stmp.id] = stmp;

    return acc;
  }, {} as Record<string, IStamp>);

  const stampsList: IStamp[] = Object.keys(stampsGroupedById).reduce(
    (acc, id) => [...acc, stampsGroupedById[id]],
    [] as IStamp[],
  );
  return stampsList;
};

export const useFetchStampsByOEM = (
  oems: string[],
  options: UseQueryOptions<any, any, any>,
) => {
  return useQuery<IStamp[]>(
    ["getStampsByOEM", oems],
    getStampsByOEM(oems),
    options,
  );
};
