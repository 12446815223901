import { fabric } from "fabric";
import {
  customFabricAttributes,
  ExtendedObjectType,
  ICustomVideoData,
  IDimension,
  IExtendedFabricObject,
} from "shared/types/designStudio";
import { getCustomAttributes } from "utils/fabric/helpers.utils";
import { extendFabricObject } from "utils/helpers.fabric";
import { createVideoElement } from "utils/media/utils.input";

type TExportingCanvasArgs = {
  canvas?: fabric.Canvas;
  originalDimension: IDimension;
  wrapperDiv: HTMLDivElement | null;
  CANVAS_MARGIN: number;
};
export const toJSON = async (args: TExportingCanvasArgs) =>
  (await getExportingCanvas(args)).toJSON(customFabricAttributes);

export const toThumbnail = async (args: TExportingCanvasArgs) =>
  generateBase64Thumbnail(await getExportingCanvas(args));

const getExportingCanvas = (args: TExportingCanvasArgs) => {
  const dimension = calculateCanvasDimension(
    args.wrapperDiv,
    args.originalDimension,
    args.CANVAS_MARGIN,
  );

  const reverseMargin = {
    top: ((dimension.height - args.originalDimension.height) / 2) * -1,
    left: ((dimension.width - args.originalDimension.width) / 2) * -1,
  };
  return new Promise<fabric.Canvas>(resolve => {
    const exportingCanvas = new fabric.Canvas(document.createElement("canvas"));
    exportingCanvas.setDimensions({
      width: args.originalDimension.width,
      height: args.originalDimension.height,
    });

    getTransformedObjects(
      args.canvas?.toJSON(customFabricAttributes),
      reverseMargin,
    ).then(objects => {
      objects
        .filter(obj => (obj.customType as ExtendedObjectType) !== "canvas_area")
        .forEach(obj => {
          if ((obj.customType as ExtendedObjectType) === "canvas_bg") {
            exportingCanvas.setBackgroundImage(obj as fabric.Image, () => {
              // fill
            });
          } else {
            exportingCanvas.add(obj);
          }
        });

      resolve(exportingCanvas);
    });
  });
};

export const clearCanvas = (canvas?: fabric.Canvas) => {
  canvas?.getObjects().forEach(obj => {
    if ((obj as any).customType !== "canvas_area") canvas?.remove(obj);
  });
};

/**
 * @param json
 * @param margin This margin can be positive margin (for toJSON) or reverse margin (for fromJSON).
 * @param wrapper
 * @returns
 */
export const getTransformedObjects = async (
  json: any,
  margin: { top: number; left: number },
  wrapper?: IExtendedFabricObject,
) => {
  let objects = [...(json.objects ? json.objects : [])].map(obj => ({
    ...obj,
  }));
  if (json.backgroundImage) {
    objects = [
      {
        ...json.backgroundImage,
        customType: "canvas_bg" as ExtendedObjectType,
        evented: false,
        selectable: false,
      },
      ...objects,
    ];
  }

  const objectPromiseList = objects.map(obj => {
    return new Promise<any>(resolve => {
      // Below transform a type "image", "textbox" to "Image", "Textbox" in camel case
      const typeClassName = fabric.util.string.camelize(
        fabric.util.string.capitalize(obj.type, false),
      );

      const fabricClassObject = (fabric as any)[typeClassName] as any;
      if (!fabricClassObject) return resolve(null);

      fabricClassObject.fromObject(obj, (parsedObj: IExtendedFabricObject) => {
        const options = {
          top: (parsedObj?.top || 0) + margin.top,
          left: (parsedObj?.left || 0) + margin.left,
          clipPath: wrapper,
        };

        switch (parsedObj.customType) {
          case "selected_video":
            const customAttributes = getCustomAttributes(parsedObj);
            createVideoElement(
              (parsedObj.customData as ICustomVideoData).videoSrc,
              "mp4",
            ).then(videoElement => {
              const videoObject = new fabric.Image(videoElement, {
                ...customAttributes,
                ...options,
                name: parsedObj.name,
                width: parsedObj.width,
                height: parsedObj.height,
                scaleX: parsedObj.scaleX,
                scaleY: parsedObj.scaleY,
              });

              const extendedVideoObject = extendFabricObject({
                object: videoObject,
                objectType: "selected_video",
                properties: { ...parsedObj.customData },
              });

              resolve(extendedVideoObject);
            });
            break;

          default:
            parsedObj.set(options);
            resolve(parsedObj);

            break;
        }
      });
    });
  });

  const transformedObjects: any[] = await Promise.all(objectPromiseList).then(
    objects => objects.filter(obj => !!obj),
  );

  return transformedObjects;
};

export const generateBase64Thumbnail = (canvas: fabric.Canvas) => {
  const canvasDataUri = canvas.toDataURL({
    format: "jpeg",
    quality: 0.6,
  });

  return canvasDataUri.replace(/^data:image\/(jpg|jpeg|png);base64,/, "");
};

export const calculateCanvasDimension = (
  wrapperDiv: HTMLDivElement | null,
  originalDimension: IDimension,
  CANVAS_MARGIN: number, // small margin between .canvas-inner-wrapper and the actual canvas
) => {
  const FIXED_VERTICAL_MARGIN = 200;
  const { width } = wrapperDiv?.getBoundingClientRect() || {
    width: 0,
  };

  const dimension: IDimension = {
    width: width - CANVAS_MARGIN * 2,
    height: originalDimension.height + FIXED_VERTICAL_MARGIN,
  };

  return dimension;
};
