import API from "services";
import { useIsFetching, useMutation, useQueries } from "react-query";
import {
  PdfStartExecRes,
  PdfInputPayload,
  PdfStatusRes,
} from "shared/types/adReview";

export const useGeneratePdfStartExecution = () => {
  return useMutation<PdfStartExecRes, Error, PdfInputPayload>(
    API.services.adLibrary.generatePdfStartExecution,
  );
};

const pdfExecutionsKey = "adReview/pdfExecutions";

export const useGeneratePdfDescribeExecutions = (execNames: string[]) => {
  return useQueries(
    execNames.map(execName => {
      return {
        queryKey: [pdfExecutionsKey, execName],
        queryFn: () =>
          API.services.adLibrary.generatePdfDescribeExecution(execName),
        refetchInterval: (data: any) => {
          const executionData = data as PdfStatusRes;
          return !executionData || executionData.status === "loading"
            ? 3000 // polling every 3 seconds when the step function is still running
            : false;
        },
        staleTime: Infinity,
      };
    }),
  );
};

export const useIsLoadingPdfExecutionDescriptions = () => {
  return (
    useIsFetching(pdfExecutionsKey, {
      predicate: query => {
        return query.state.status === "loading";
      },
    }) > 0
  );
};
