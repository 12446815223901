import { CloseOutlined, FilterFilled } from "@ant-design/icons";
import { Button } from "antd";
import classNames from "classnames";
import TagList, { TagType } from "shared/components/TagList";
import { useDataList } from "shared/components/dataList";
import { COLUMN_ASSET_BATCHES } from "shared/constants/assetExporter";
import { IFilter } from "shared/hooks/assetExporter/useFeedConfigFetch";
import styles from "./FeedListDataTags.module.scss";
import { useFeedContext } from "./shared/contexts/FeedContext";
import { DEFAULT_KEY } from "shared/components/dataList/useDataList";

type Props = {
  totalCount: number;
  filteredTotalCount: number;
  setFilterArgs: (filters?: IFilter[]) => void;
  setShowSelectAllOption: (showSelectAllOption: boolean) => void;
  onRemove?: (key: string, value: React.Key | boolean) => void;
};

const displayItemCount = (
  tagCount: number,
  filteredTotalCount: number,
  totalCount: number,
) => {
  if (tagCount > 0) return `${filteredTotalCount} / ${totalCount} items`;
  return `${totalCount} items`;
};

export const FeedListDataTags = (props: Props) => {
  const [dataList, dataListActions] = useDataList();
  const {
    setFilterArgs,
    totalCount,
    filteredTotalCount,
    onRemove,
    setShowSelectAllOption,
  } = props;
  const { assetBatches } = useFeedContext();

  const tags: TagType[] = dataList.filters[DEFAULT_KEY]
    ? Object.entries(dataList.filters[DEFAULT_KEY]).reduce<TagType[]>(
        (acc, [key, val]) => {
          return [
            ...acc,
            ...val.map(value => ({
              key,
              value,
              displayValue: `${key}: ${
                key === COLUMN_ASSET_BATCHES && assetBatches?.length
                  ? assetBatches.find(ab => ab.assetBatchId === value)?.name
                  : value
              }`,
            })),
          ];
        },
        [],
      )
    : [];

  return (
    <>
      <div className={styles.host} data-cy="datalist-tags">
        <span>
          {displayItemCount(tags.length, filteredTotalCount, totalCount ?? 0)}
        </span>
        <strong>Filters</strong>
        <Button
          data-cy="datalist-filter-button"
          onClick={dataListActions.toggleSelectedItems}
          size="small"
          type={dataList.showSelected ? "primary" : "default"}
          disabled={!dataList.selectedIds.length}
          className={classNames([
            styles.filterButton,
            dataList.showSelected ? "ant-tag-blue" : "ant-tag-default",
          ])}
        >
          {dataList.selectedIds.length} selected
          {dataList.showSelected && <CloseOutlined className={styles.close} />}
          {!dataList.showSelected && !!dataList.selectedIds.length && (
            <FilterFilled />
          )}
        </Button>
        <div>
          <TagList
            tags={tags}
            onClear={() => {
              setShowSelectAllOption(false);
              dataListActions.setSelectedItems([]);
              dataListActions.resetFilters();
              setFilterArgs([]);
            }}
            onRemove={(key, value) => {
              setShowSelectAllOption(false);
              dataListActions.setSelectedItems([]);
              dataListActions.removeFilter(key, value);
              const filterArgs = Object.keys(dataList.filters[DEFAULT_KEY])
                .map(filterKey => {
                  if (
                    filterKey !== key &&
                    dataList.filters[DEFAULT_KEY][filterKey]
                  ) {
                    return {
                      column: filterKey,
                      selection: [
                        String(dataList.filters[DEFAULT_KEY][filterKey]),
                      ],
                    };
                  }
                })
                .filter(Boolean) as IFilter[];

              setFilterArgs(filterArgs);
              onRemove?.(key, value);
            }}
          />
        </div>
      </div>
    </>
  );
};
