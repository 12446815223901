import { Tag } from "antd";

const TagListOverflow = ({
  tags,
  displayedTagCount = 3,
}: {
  tags: string[];
  displayedTagCount?: number;
}) => {
  if (!tags?.length) return null;

  return (
    <div>
      {tags.slice(0, displayedTagCount - 1).map((name: string) => (
        <Tag key={name}>{name}</Tag>
      ))}
      {tags.length > displayedTagCount && (
        <Tag>+{tags.length - displayedTagCount}</Tag>
      )}
    </div>
  );
};

export default TagListOverflow;

export const renderTags = (tags: string) => (
  <TagListOverflow tags={tags?.split(",")} />
);
