import { getEnvVar } from "utils/helpers";
import { IndustryType } from "shared/types/shared";
import { DynamicCatalogType } from "shared/components/DynamicVariableSelect";

type IndustyRegexDict = Partial<Record<IndustryType, RegExp>>;
const industryVariableRegex: IndustyRegexDict = {
  auto: /\{(vehicle\.)\S+\}/gim,
  retail: /\{(product\.)\S+\}/gim,
  travel: /\{((hotel | trip | price)\.)\S+\}/gim,
};

type CatalogTypeRegexDict = Partial<Record<DynamicCatalogType, RegExp>>;
const catalogVariableRegex: CatalogTypeRegexDict = {
  AUTOMOTIVE_CATALOG: industryVariableRegex.auto,
  PRODUCT_CATALOG: industryVariableRegex.retail,
  TRAVEL_CATALOG: industryVariableRegex.travel,
};

type DefaultCatalogDict = Record<IndustryType, DynamicCatalogType>;
const industryTypeCatalogDict: DefaultCatalogDict = {
  all: "PRODUCT_CATALOG",
  auto: "AUTOMOTIVE_CATALOG",
  retail: "PRODUCT_CATALOG",
  travel: "TRAVEL_CATALOG",
  pharma: "PHARMA_CATALOG",
};

export const initCatalogType = () => {
  const industry = (getEnvVar("INDUSTRY") || "auto") as IndustryType;
  const catalog = industryTypeCatalogDict[industry];
  return catalog;
};

export const catalogTypeValidator = (
  value: string,
  catalog?: DynamicCatalogType,
) => {
  const catalogToUse = catalog || initCatalogType();
  const forIndustries = Object.entries(catalogVariableRegex).filter(
    entry => entry[1] && !!value.match(entry[1]),
  );

  const multiCatalogs = forIndustries.length > 1;
  const wrongCatalog =
    forIndustries.length === 1 && forIndustries[0][0] !== catalogToUse;

  if (multiCatalogs || wrongCatalog) {
    return Promise.reject(
      "Cannot use variables from different Product catalog types",
    );
  }
  return Promise.resolve();
};
