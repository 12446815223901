import { useEffect, useState } from "react";
import { EAElements } from "./useContextAPI";
import {
  fetchInstantExperience,
  generateEdges,
  generateElements,
} from "./utils";

type Args = {
  queryString: string;
};

export default (args: Args): EAElements => {
  const [autoGenElements, setAutoGenElements] = useState<EAElements>([]);
  const { queryString } = args;
  useEffect(() => {
    const process = async () => {
      const okToProceed = !!queryString;
      if (!okToProceed) return;

      const params = new URLSearchParams(queryString);
      const sourceId = params.get("source");
      if (!sourceId) return;

      const sourceIE = await fetchInstantExperience(sourceId);

      const connectedElements = await generateElements({
        element: sourceIE,
        dataSource: [],
        depth: 0,
        degree: 0,
      });
      if (!connectedElements) return;

      const edges = generateEdges({
        elements: connectedElements,
      });

      const updated = [...connectedElements, ...(edges || [])];
      setAutoGenElements(updated);
    };

    process();
  }, [queryString]);

  return autoGenElements;
};
