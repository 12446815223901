import API from "services";

import { useMutation, useQueryClient, InfiniteData } from "react-query";
import useSetQCHistoryLogsQueryData from "./useSetQCHistoryLogsQueryData";

import { IQCHistoryLog } from "shared/types/shared";
import {
  IGetQCHistoryLogsResult,
  IModifyQCHistoryLogResult,
} from "shared/types/adReview";

const deleteQCHistoryLog = async (qcHistoryLog: IQCHistoryLog) => {
  const { result, error } = await API.services.adReview.modifyQCHistoryLog(
    qcHistoryLog,
    "delete",
  );

  if (error) {
    throw Error(error.message);
  }

  return result;
};

/** TODO: re-implement this without infinite queries */
const useDeleteQCHistoryLog = () => {
  const queryClient = useQueryClient();
  const setQCHistoryLogsQueryData = useSetQCHistoryLogsQueryData();
  return useMutation<
    IModifyQCHistoryLogResult | null,
    Error,
    IQCHistoryLog,
    { previousQCHistoryLogs?: InfiniteData<IGetQCHistoryLogsResult> }
  >(deleteQCHistoryLog, {
    onMutate: async (qcHistoryLog: IQCHistoryLog) => {
      const previousQCHistoryLogs = await setQCHistoryLogsQueryData({
        qcHistoryLog,
        operation: "delete",
      });
      return { previousQCHistoryLogs };
    },
    onError: (_err, qcHistoryLog) => {
      queryClient.invalidateQueries(["qcHistoryLogsPages", qcHistoryLog.id]);
    },
    onSuccess: res => {
      queryClient.invalidateQueries([
        "qcHistoryLogsPages",
        res?.qcHistoryLog.id,
      ]);
    },
  });
};

export default useDeleteQCHistoryLog;
