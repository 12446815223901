import Timeago from "react-timeago";
import moment from "moment";
import { Tooltip } from "antd";
import { mmddyyyyTimeFormat } from "shared/constants/dataManagement";

import styles from "./TimeAgo.module.scss";

interface ITimeAgoProps {
  date: string | number | Date;
  dataCy?: string;
}

export const TimeAgo = ({ date, dataCy }: ITimeAgoProps) => (
  <Tooltip placement="top" title={moment(date).format(mmddyyyyTimeFormat)}>
    <Timeago data-cy={dataCy} className={styles.timeAgo} date={date} title="" />
  </Tooltip>
);
