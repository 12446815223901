import { Table } from "antd";
import { Dispatch, SetStateAction } from "react";
import { editOffer } from "redux/assetBuilder/assetBuilder.slice";
import { useAppDispatch } from "../redux";
import { useMetaColumns, usePagination } from "../table";
import { useColImage } from "../table/cols";

type Props = {
  data?: WDSearchResult;
  isFetching: boolean;
  onSelect: (url: string) => void;
  onPageChange: Dispatch<SetStateAction<number>>;
};

export const Results = ({
  data,
  isFetching,
  onSelect,
  onPageChange,
}: Props) => {
  const dispatch = useAppDispatch();
  const columns = [useColImage(), ...useMetaColumns("tableSelectCols")];
  const pagination = usePagination(onPageChange, data);

  const handleRowSelect = ({ thumbnailurls }: WDAsset) => {
    const lastThumb = thumbnailurls[thumbnailurls.length - 1];
    if (lastThumb) {
      onSelect(lastThumb.url);
      dispatch(editOffer({ key: "imageUrl", value: lastThumb.url }));
    }
  };

  return (
    <Table
      size="small"
      rowKey="id"
      columns={columns}
      dataSource={data?.items}
      loading={isFetching}
      pagination={pagination}
      scroll={{ y: 300 }}
      rowSelection={{
        type: "radio",
        hideSelectAll: true,
        onSelect: handleRowSelect,
      }}
    />
  );
};
