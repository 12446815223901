import { Button, Select } from "antd";
import { IDashboard } from "shared/types/dashboardManagement";

const MultiSelectAllTags = ({
  field,
  options,
  className,
  onChange,
  value,
  disabled,
  placeholder,
}: {
  field: "oem" | "account";
  options: { title: string; value: string }[];
  className?: string;
  onChange: (field: keyof IDashboard, value: string) => void;
  value: string | string[];
  disabled?: boolean;
  placeholder?: string;
}) => {
  const parsedValue = (Array.isArray(value) ? value : value?.split(",")).filter(
    v => v !== "",
  );
  const valueLength = parsedValue.length;

  return (
    <Select
      disabled={disabled}
      className={className}
      allowClear={true}
      placeholder={placeholder || `Select ${field}s`}
      style={{ width: "100%" }}
      dropdownStyle={{ maxHeight: "300px" }}
      onChange={ids => onChange(field, ids?.join(",") || "")}
      value={parsedValue}
      maxTagCount={3}
      maxTagPlaceholder={omittedValues =>
        `+ ${omittedValues.length} ${
          field === "oem" ? "Brands" : "Accounts"
        } ...`
      }
      mode="multiple"
      options={options}
      dropdownRender={(menu: any) => (
        <>
          <div>
            <Button
              type="link"
              disabled={valueLength === options.length}
              onClick={() =>
                onChange(field, options.map(option => option.value).join(","))
              }
            >
              Select all
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              disabled={valueLength === 0}
              onClick={() => onChange(field, "")}
              type="link"
            >
              Unselect all
            </Button>
          </div>
          {menu}
        </>
      )}
    />
  );
};

export default MultiSelectAllTags;
