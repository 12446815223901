import { Menu, MenuProps } from "antd";
import {
  CopyOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  SettingOutlined,
  ClockCircleOutlined,
  CloudDownloadOutlined,
  UploadOutlined,
  DownloadOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { MenuItemProps } from "rc-menu";

interface ITablePopup extends MenuProps {
  popupState: {
    visible: boolean;
    x?: number;
    y?: number;
  };
}

const TablePopup = ({
  popupState: { visible, x, y },
  ...props
}: ITablePopup) => {
  if (!visible || !x || !y) return null;

  return (
    <Menu
      style={{ position: "absolute", left: x, top: y - 60, zIndex: 10 }}
      {...props}
      data-cy="source-feed-right-click"
    />
  );
};

const EditItem = ({ ...props }: MenuItemProps) => (
  <Menu.Item icon={<EditOutlined />} key={"edit"} {...props}>
    Edit
  </Menu.Item>
);
const PreviewItem = ({ ...props }: MenuItemProps) => (
  <Menu.Item icon={<EyeOutlined />} key={"preview"} {...props}>
    Preview
  </Menu.Item>
);
const DuplicateItem = ({ ...props }: MenuItemProps) => (
  <Menu.Item icon={<CopyOutlined />} key={"duplicate"} {...props}>
    Duplicate
  </Menu.Item>
);
const DeleteItem = ({ ...props }: MenuItemProps) => (
  <Menu.Item
    icon={<DeleteOutlined style={{ fill: "red" }} />}
    key={"delete"}
    style={{ color: "red" }}
    {...props}
  >
    Delete
  </Menu.Item>
);

// for assetexporter below
const ConfigureItem = ({ ...props }: MenuItemProps) => (
  <Menu.Item icon={<SettingOutlined />} key={"configure"} {...props}>
    Configure
  </Menu.Item>
);

const VersionsItem = ({ ...props }: MenuItemProps) => (
  <Menu.Item
    icon={<ClockCircleOutlined />}
    key={"versions"}
    {...props}
    data-cy="versions-history"
  >
    Versions
  </Menu.Item>
);

const DownloadCSVItem = ({ ...props }: MenuItemProps) => (
  <Menu.Item icon={<DownloadOutlined />} key={"downloadcsv"} {...props}>
    Download CSV
  </Menu.Item>
);

const PullLatestItem = ({ ...props }: MenuItemProps) => (
  <Menu.Item icon={<CloudDownloadOutlined />} key={"pulllatest"} {...props}>
    Pull Latest
  </Menu.Item>
);

// for design studio below
const LoadItem = ({ ...props }: MenuItemProps) => (
  <Menu.Item icon={<UploadOutlined />} key={"load"} {...props}>
    Load
  </Menu.Item>
);

const GenerateUrlItem = ({ ...props }: MenuItemProps) => (
  <Menu.Item icon={<ShareAltOutlined />} key={"generateurl"} {...props}>
    Generate URL
  </Menu.Item>
);

TablePopup.EditItem = EditItem;
TablePopup.PreviewItem = PreviewItem;
TablePopup.DuplicateItem = DuplicateItem;
TablePopup.DeleteItem = DeleteItem;
TablePopup.VersionsItem = VersionsItem;
TablePopup.ConfigureItem = ConfigureItem;
TablePopup.DownloadCSVItem = DownloadCSVItem;
TablePopup.PullLatestItem = PullLatestItem;
TablePopup.LoadItem = LoadItem;
TablePopup.GenerateUrl = GenerateUrlItem;

export default TablePopup;
