import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type SettingsState = {
  framed: boolean;
};

const initialState: SettingsState = {
  framed: false,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setFramed: (state, action: PayloadAction<boolean>) => {
      state.framed = action.payload;
    },
  },
});

export const { setFramed } = settingsSlice.actions;

export default settingsSlice.reducer;
