import useDeepEffect from "shared/hooks/useDeepEffect";
import { AlignLayersActionType, IDimension } from "shared/types/designStudio";
import * as utils from "../canvas.utils/Utils";

export default (args: {
  canvas?: fabric.Canvas;
  align: AlignLayersActionType;
  canvasAreaMargin: {
    top: number;
    left: number;
  };
  CANVAS_MARGIN: number;
  canvasDimension: IDimension;
  onComplete: () => void;
}) => {
  useDeepEffect(() => {
    if (!args.canvas || !args.align) return;

    const activeObjects = args.canvas.getActiveObjects();
    if (!activeObjects.length) {
      return;
    }

    const selectionBoundaries = utils.getSelectionBoundary({
      objects: activeObjects,
      canvasAreaMargin: args.canvasAreaMargin,
      CANVAS_MARGIN: args.CANVAS_MARGIN,
      canvasDimension: args.canvasDimension,
    });

    utils.alignObjects({
      objects: activeObjects,
      boundary: selectionBoundaries,
      align: args.align || null,
      CANVAS_MARGIN: args.CANVAS_MARGIN,
    });

    if (activeObjects.length > 1) {
      args.canvas.discardActiveObject();
    }

    args.canvas.renderAll();

    args.onComplete();
  }, [args.align, args.canvasAreaMargin, args.canvasDimension]);
};
