import { CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { notification, Spin, Tooltip } from "antd";
import { IconType } from "antd/lib/notification";
import moment from "moment";
import { useEffect } from "react";
import AlexiaCopyToClipboard from "shared/components/CopyLink";
import { mmddyyyyTimeFormat } from "shared/constants/dataManagement";
import { useGeneratePdfDescribeExecutions } from "shared/hooks/adLibrary/adReview/useGeneratePdfExecutions";
import { getDuration } from "utils/helpers.date";
import { usePdfExecNames } from "./usePdfExecNames";
import { PdfStatusRes } from "shared/types/adReview";

/**
 * This hook will display a notification for every PDF you are generating from ad review.
 * It will check the redux store to get the arns of the PDFs you are generating.
 */
export function usePdfGeneratorNotification() {
  const { pdfExecNames, handleSetPdfExecNames } = usePdfExecNames();

  const pdfExecutionDescriptions =
    useGeneratePdfDescribeExecutions(pdfExecNames);

  useEffect(() => {
    pdfExecutionDescriptions.forEach(({ data: execution }) => {
      if (!execution) return;

      const getNotificationType = (): IconType | undefined => {
        if (execution.status === "success") {
          return "success";
        }
        if (execution.status === "error") {
          return "error";
        }
        if (execution.status === "partial_success") {
          return "warning";
        }
      };

      notification.open({
        type: getNotificationType(),
        key: execution?.execName,
        message: "PDF Generation",
        icon: execution.status === "loading" ? <Spin /> : undefined,
        description: <Description execution={execution} />,
        duration: null,
        closeIcon: <CloseOutlined />,
        onClose: () =>
          handleSetPdfExecNames(
            pdfExecNames.filter(arn => arn !== execution?.execName),
          ),
        placement: "bottomRight",
      });
    });
  }, [pdfExecNames, handleSetPdfExecNames, pdfExecutionDescriptions]);
}

export const useAdReviewPdfGeneratorNotification = usePdfGeneratorNotification;

const Description = ({
  execution: { output, status, startedAt, endedAt, ads },
}: {
  execution: PdfStatusRes;
}) => {
  const durationString = getDuration(startedAt, endedAt);
  const failedAds = ads?.filter(ad => ad.status === "error") ?? [];

  const metadata = (
    <div>
      <div>
        <b>Start Date: </b>
        {moment(startedAt).format(mmddyyyyTimeFormat)}
      </div>
      {endedAt && (
        <Tooltip title={durationString} placement="left">
          <div>
            <b>End Date: </b>
            {moment(endedAt).format(mmddyyyyTimeFormat)}
          </div>
        </Tooltip>
      )}
    </div>
  );

  if (status === "success" || status === "partial_success")
    return (
      <div>
        {metadata}
        <span>
          <b>PDF URL: </b>
          <a href={output!.pdfUrl} target="_blank" rel="noreferrer">
            Open
          </a>{" "}
          <AlexiaCopyToClipboard
            text={output!.pdfUrl}
            copyTooltipText="Copy link to PDF"
          />
        </span>
        {status === "partial_success" && (
          <Tooltip
            overlayStyle={{
              maxWidth: 500,
            }}
            title={
              <ul
                style={{
                  padding: 10,
                  margin: 0,
                }}
              >
                {failedAds.map(({ id, name }) => (
                  <li key={id}>{name || id}</li>
                ))}
              </ul>
            }
          >
            <div style={{ color: "red", cursor: "pointer" }}>
              Ads with issues <InfoCircleOutlined />
            </div>
          </Tooltip>
        )}
      </div>
    );

  if (status === "loading") {
    return <div>{metadata}</div>;
  }

  return (
    <div>
      {metadata}
      <div>The PDF Generation Failed</div>
    </div>
  );
};
