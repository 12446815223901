import API from "services";
import { useQuery, UseQueryResult } from "react-query";
import { AdFormat, AdType } from "screens/adLibrary/facebookUtils/types";
import { IAdPreviewDimensions } from "shared/types/adReview";
import {
  DESKTOP_WIDTH,
  MOBILE_WIDTH,
  previewSizes,
} from "screens/campaignManagement/adReview/adCardListContainer/facebookAdCard/constants";

const PLACEHOLDER_HEIGHT = 260;

const fetchAdPreviewDimensions = async (adId: string, adFormat: AdFormat) => {
  const { result, error } = await API.services.adLibrary.getAdPreviewDimensions(
    adId,
    adFormat,
  );

  if (!result) {
    throw Error(error?.message || "Something went wrong while fetching ads.");
  }

  return result;
};

type Response = Omit<
  UseQueryResult<IAdPreviewDimensions, Error>,
  "isLoading"
> & {
  dimensions: IAdPreviewDimensions;
  isLoadingFirstTime: boolean;
};

export const useGetAdPreviewDimensions = (
  adId?: string,
  adFormat: AdFormat = AdFormat.MOBILE_FEED_STANDARD,
  adType: AdType = AdType.Carousel,
): Response => {
  // the api is not working for desktop ad format, so we are skipping it for now
  const shouldGetDimensions =
    !!adId && adFormat === AdFormat.MOBILE_FEED_STANDARD;

  const result = useQuery<IAdPreviewDimensions, Error>(
    ["adDimensions", adId, adFormat],
    () => fetchAdPreviewDimensions(adId!, adFormat),
    {
      staleTime: Infinity,
      enabled: shouldGetDimensions,
      retryDelay: 5000,
    },
  );

  const hasError =
    (result.errorUpdateCount > 0 || result.failureCount > 0) &&
    !result.isSuccess;

  let dimensions: IAdPreviewDimensions = {
    width:
      adFormat === AdFormat.MOBILE_FEED_STANDARD ? MOBILE_WIDTH : DESKTOP_WIDTH,
    height: result.data?.height || PLACEHOLDER_HEIGHT,
  };

  if (hasError || !shouldGetDimensions) {
    dimensions = previewSizes[adFormat]?.[adType] || dimensions;
  }

  return {
    ...result,
    isLoadingFirstTime: result.isLoading && !hasError,
    dimensions,
  };
};
