import { useLayoutEffect, useRef, useState } from "react";

import useDeepEffect from "shared/hooks/useDeepEffect";
import { fabric } from "fabric";
import { initAligningGuidelinesOriginal } from "utils/fabric/helpers.alignment";
import EventHandlers, { FabricEvent } from "../canvas.utils/EventHandlers";
import { IDimension, IExtendedFabricObject } from "shared/types/designStudio";
import { getHighlights } from "../canvas.utils/Utils";
import { useIsHotkeyPressed } from "react-hotkeys-hook";
import { CanvasHandlers, CanvasProps, TCanvasAction } from "../Canvas";
import { calculateCanvasDimension } from "../canvas.utils/Parsers";
import {
  ICanvasObject,
  isLifestyleImage,
  isThemeBackground,
} from "shared/types/assetBuilder";
import { CardLabel } from "shared/components/templatePreview/CardLabel";
import { getHeight, getWidth } from "utils/fabric/helpers.utils";
import { THightlight } from "../canvas/Highlight";

export const CANVAS_MARGIN = 10; // in px

export default (args: {
  props: CanvasProps & CanvasHandlers;
  wrapperDiv: HTMLDivElement | null;
  setHiddenObjectsHighlights: React.Dispatch<
    React.SetStateAction<THightlight[] | undefined>
  >;
  highlightObject: any;
  clearAllBoundingRect: any;
  onComplete: (args: {
    event: FabricEvent;
    hiddenObjectHighlights?: ReturnType<typeof getHighlights>;
    textChangedObject?: IExtendedFabricObject;
    labels?: CardLabel[];
  }) => void;
  setLabels: React.Dispatch<React.SetStateAction<CardLabel[] | undefined>>;
}) => {
  const { props, wrapperDiv } = args;
  const [canvas, setCanvas] = useState<fabric.Canvas>();
  const isPressed = useIsHotkeyPressed();

  useDeepEffect(() => {
    const canvas = new fabric.Canvas("canvas");
    initAligningGuidelinesOriginal(canvas);

    setCanvas(canvas);
  }, []);

  useLayoutEffect(() => {
    if (!canvas || !wrapperDiv) return;

    const { width, height } = wrapperDiv.getBoundingClientRect();
    canvas.setDimensions({
      width,
      height,
    });
  }, [canvas, wrapperDiv]);

  const prevActiveObjectOnMouseDown = useRef<fabric.Object>();
  const isCMDPressed = useIsHotkeyPressed();
  useDeepEffect(() => {
    if (!canvas || !args.wrapperDiv) return;

    const MAX_ZOOM = 20;
    const MIN_ZOOM = 1;

    EventHandlers(canvas, async (eventType, target) => {
      const activeObject = canvas.getActiveObject();
      switch (eventType) {
        case FabricEvent.SELECTION_CREATED:
        case FabricEvent.SELECTION_UPDATED:
        case FabricEvent.SELECTION_CLEARED:
        case FabricEvent.OBJECT_MODIFIED:
        case FabricEvent.OBJECT_MOVED:
        case FabricEvent.OBJECT_SCALED:
          args.onComplete({ event: eventType });
          break;

        case FabricEvent.AFTER_RENDER:
          // if this envent triggered, target is an array of objects since it is possible to select multiple objects.
          const canvasDimension = calculateCanvasDimension(
            args.wrapperDiv,
            props.dimension,
            CANVAS_MARGIN,
          );
          let currentObjects =
            canvas.getObjects() as unknown as IExtendedFabricObject[];
          const possibleActiveSelection = canvas.getActiveObject();
          if (possibleActiveSelection?.type === "activeSelection") {
            possibleActiveSelection.set({
              name: "active_selection",
            });
            currentObjects = [possibleActiveSelection as any];
          }

          // get card label data
          // This only applies for theme image and lifestyle image placeholders
          const labels: CardLabel[] = (
            currentObjects as unknown as ICanvasObject[]
          )
            .filter(obj => isLifestyleImage(obj) || isThemeBackground(obj))
            .map(obj => {
              const { left, top } = obj;
              const width = getWidth(obj);
              const height = getHeight(obj);
              const position = {
                x: left + CANVAS_MARGIN,
                y: top + CANVAS_MARGIN,
              };
              const dimension: IDimension = {
                width,
                height,
              };
              const title = isThemeBackground(obj)
                ? "Theme Background"
                : "Lifestyle Image";

              return {
                title,
                position,
                dimension,
              };
            });

          if (canvas.getZoom() <= MIN_ZOOM) {
            args.onComplete({
              event: FabricEvent.AFTER_RENDER,
              hiddenObjectHighlights: getHighlights({
                objects: currentObjects.filter(obj => !!obj.name),
                canvasMargin: CANVAS_MARGIN,
                canvasAreaDimension: props.dimension,
                canvasDimension: canvasDimension,
              }),
              labels,
            });
          }
          break;

        case FabricEvent.MOUSE_OVER:
          if (target) {
            if (activeObject?.name !== target.name)
              args.highlightObject(target);

            const mouseOverCanvasAction: TCanvasAction = {
              target,
              action: {
                type: "mouseOver",
              },
            };
            props.setCanvasAction(mouseOverCanvasAction);
          }
          break;

        case FabricEvent.MOUSE_OUT:
          args.clearAllBoundingRect();

          const mouseOverCanvasAction: TCanvasAction = {
            action: {
              type: "mouseOut",
            },
          };
          props.setCanvasAction(mouseOverCanvasAction);

          break;

        case FabricEvent.MOUSE_DOWN:
          if (!target) {
            props.setCanvasAction({
              target: prevActiveObjectOnMouseDown.current,
              action: {
                type: "objectDeSelected",
              },
            });

            return;
          }

          prevActiveObjectOnMouseDown.current = activeObject;

          args.clearAllBoundingRect(canvas);

          // fire canvas actoin so that in layer section (or/and other section) can update the UI
          props.setCanvasAction({
            target,
            action: {
              type: "objectSelected",
              keyPressed: isPressed("shift") ? "shift" : undefined,
            },
          });

          args.onComplete({
            event: FabricEvent.MOUSE_DOWN,
          });
          break;

        case FabricEvent.MOUSE_UP:
          args.onComplete({
            event: FabricEvent.MOUSE_UP,
          });
          break;

        case FabricEvent.TEXT_CHANGED:
          args.onComplete({
            event: FabricEvent.TEXT_CHANGED,
            textChangedObject: target.set("selectable", true),
          });
          break;

        case FabricEvent.TEXT_SELECTION_CHANGED:
          props.setCanvasAction({
            target,
            action: {
              type: eventType,
            },
          });
          break;
        case FabricEvent.MOUSE_WHEEL:
          const isPinch = target.e.ctrlKey ? true : false;

          if (!isPinch && !isCMDPressed("cmd")) {
            // if zooming in, doesn't allow to scroll
            // because fabric.js doesn't work properly when zooming in
            if (canvas.getZoom() !== MIN_ZOOM) {
              target.e.preventDefault();
            }
            return;
          }

          args.clearAllBoundingRect();
          args.setLabels(undefined);
          args.setHiddenObjectsHighlights([]);

          // pinch zoom is slower than cmd + scroll so if it is pinch zoom, multiply 3 to delta value
          const delta = isPinch ? target.e.deltaY * 3 : target.e.deltaY;
          let zoom = canvas.getZoom();
          zoom *= 0.999 ** delta;
          if (zoom > MAX_ZOOM) zoom = MAX_ZOOM;
          if (zoom < MIN_ZOOM) zoom = MIN_ZOOM;
          canvas.zoomToPoint(target.pointer, zoom);

          if (canvas.viewportTransform) {
            const orgZoom = canvas.getZoom();
            const width = canvas.getWidth();
            const height = canvas.getHeight();
            // the min of orgZoom is 1
            // formula: (stdConst - MIN_ZOOM) / 2 = 0.5
            // formula: stdConst = 1 + MIN_ZOOM
            const stdConst = 1 + MIN_ZOOM;
            const xMin = ((stdConst - orgZoom) * width) / 2;
            const xMax = (orgZoom * width) / 2;
            const yMin = ((stdConst - orgZoom) * height) / 2;
            const yMax = (orgZoom * height) / 2;

            const point = new fabric.Point(width / 2, height / 2);
            const center = fabric.util.transformPoint(
              point,
              canvas.viewportTransform,
            );

            const clampedCenterX = Math.max(xMin, Math.min(center.x, xMax));
            const clampedCenterY = Math.max(yMin, Math.min(center.y, yMax));
            const diffX = clampedCenterX - center.x;
            const diffY = clampedCenterY - center.y;

            if (diffX != 0 || diffY != 0) {
              canvas.relativePan(new fabric.Point(diffX, diffY));
            }
          }

          target.e.preventDefault();
          target.e.stopPropagation();
      }
    });
  }, [args.wrapperDiv, canvas]);

  return canvas;
};
