import { useRef, useState } from "react";
import { Tooltip, TooltipProps, Typography } from "antd";
import LabelSelectWithAutoComplete, {
  LabelSelectWithAutoCompleteProps,
} from "shared/components/select/LabelSelectWithAutoComplete";
import { IAccount } from "shared/types/accountManagement";

type EditableAdToLoadUrlTextProps = LabelSelectWithAutoCompleteProps & {
  showInputOnly?: boolean;
  selectedStore?: IAccount;
  disableLabelPopulation?: boolean;
  tooltipProps?: Partial<TooltipProps>;
};

const EditableAdToLoadUrlText = ({
  disableLabelPopulation,
  willCheckFullUrl,
  showInputOnly,
  selectedStore,
  linkValue,
  urlLabels,
  disabled,
  onChange,
  width,
  tooltipProps,
}: EditableAdToLoadUrlTextProps) => {
  const timeoutIdRef = useRef<NodeJS.Timeout>();
  const [isEditing, setIsEditing] = useState(false);
  const showInput = isEditing || showInputOnly;
  const labelsFromStore = selectedStore?.labels;
  const labelsToUse = labelsFromStore || urlLabels;

  return (
    <>
      {!showInput && (
        <Tooltip title="Click to edit" {...(tooltipProps ?? {})}>
          <Typography.Link
            onClick={() => {
              setIsEditing(true);
            }}
          >
            {linkValue || "<EMPTY>"}
          </Typography.Link>
        </Tooltip>
      )}
      {showInput && (
        <LabelSelectWithAutoComplete
          forceHideTooltip
          disabled={disabled}
          width={width}
          urlLabels={labelsToUse}
          linkValue={disabled ? "" : linkValue ?? ""}
          willCheckFullUrl={willCheckFullUrl}
          onBlur={() => {
            if (!isEditing || !linkValue) return;
            timeoutIdRef.current = setTimeout(() => {
              setIsEditing(false);
            }, 1000);
          }}
          onChange={(value, labelWasSelected) => {
            if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
            const labelObj = labelsToUse?.find(label => label.name === value);
            const newValue = !disableLabelPopulation
              ? labelObj?.url || value
              : value;
            onChange?.(newValue);
            if (labelWasSelected) {
              setIsEditing(false);
            }
          }}
        />
      )}
    </>
  );
};

export default EditableAdToLoadUrlText;
