import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Input, Table } from "antd";
import LabelSelect from "shared/components/LabelSelect";
import { ITableColumn } from "shared/components/dataList";
import { AccountUrl, IAccountRecord } from "shared/types/accountManagement";
import styles from "./UrlTab.module.scss";
import { useIsAdmin } from "shared/hooks/useIsAdmin";

interface UrlTabProps {
  accountToEdit: IAccountRecord;
  highlightErrors?: boolean;
  setAccountToEdit: (accountToEdit: IAccountRecord) => void;
}

const getCellAttributes = (
  dealerUrl: AccountUrl,
  highlightErrors: boolean = false,
) => ({
  className: highlightErrors && !dealerUrl.url.trim() ? "row-error" : undefined,
});

export const UrlTab = ({
  accountToEdit,
  highlightErrors,
  setAccountToEdit,
}: UrlTabProps) => {
  const isAdmin = useIsAdmin();

  const accountLabelsAndUrls = accountToEdit.labels || [{ name: "", url: "" }];
  const columns: ITableColumn<AccountUrl>[] = [
    {
      key: "label",
      title: "Label",
      dataIndex: ["name"],
      width: 275,
      onCell: dealerUrl => getCellAttributes(dealerUrl, highlightErrors),
      render: (_, item, i) => (
        <LabelSelect
          key={`label-select-${i}`}
          allowInsert={true}
          currentValue={item.name}
          onLabelChange={(labelValue: string) => {
            const tempDealerLabelsAndUrls = [...accountLabelsAndUrls];
            tempDealerLabelsAndUrls[i] = {
              ...tempDealerLabelsAndUrls[i],
              name: labelValue,
            };
            updateAccountLabels(tempDealerLabelsAndUrls);
          }}
        />
      ),
    },
    {
      key: "url",
      title: "URL",
      dataIndex: "url",
      width: 325,
      onCell: dealerUrl => getCellAttributes(dealerUrl, highlightErrors),
      render: (_, item, i) => (
        <div style={{ display: "flex" }}>
          <Input
            key={`url-key-${i}`}
            data-cy="url-input"
            value={item.url}
            disabled={!isAdmin}
            onChange={event => {
              const tempDealerLabelsAndUrls = [...accountLabelsAndUrls];
              tempDealerLabelsAndUrls[i] = {
                ...tempDealerLabelsAndUrls[i],
                url: event.target.value,
              };
              updateAccountLabels(tempDealerLabelsAndUrls);
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            type="text"
            className={styles.deleteButton}
            style={{
              cursor: !isAdmin ? "not-allowed" : "pointer",
            }}
            disabled={!isAdmin}
            onClick={() => {
              if (!isAdmin) return;
              const urlsWithoutCurrent = accountLabelsAndUrls.filter(
                (_labelAndUrl, index) => index !== i,
              );

              updateAccountLabels(
                urlsWithoutCurrent.length
                  ? urlsWithoutCurrent
                  : [{ name: "", url: "" }],
              );
            }}
          />
        </div>
      ),
    },
  ];

  const updateAccountLabels = (labels: AccountUrl[]) =>
    setAccountToEdit({ ...accountToEdit, labels });

  return (
    <div className={styles.container}>
      <Table
        columns={columns}
        dataSource={accountLabelsAndUrls}
        pagination={false}
        size="small"
      />
      <Button
        type="link"
        data-cy="add-url-button"
        style={{
          cursor: !isAdmin ? "not-allowed" : "pointer",
        }}
        onClick={() => {
          if (!isAdmin) return;
          updateAccountLabels([...accountLabelsAndUrls, { name: "", url: "" }]);
        }}
      >
        <PlusOutlined /> Add URL
      </Button>
    </div>
  );
};
