import { useMemo } from "react";
import { Button, Form, Upload } from "antd";
import { UploadFile, UploadProps } from "antd/lib/upload/interface";
import { camelCase } from "lodash";
import { ZodType, ZodTypeDef } from "zod";
import { getErrorFromZod, useAsyncZodValidation } from "utils/helpers.zod";
import * as utils from "./UploadAsset.utils";
import styles from "./UploadFont.module.scss";

export interface IOnUploadFontChange {
  isAdding: boolean;
  file: UploadFile;
}

interface IUploadFontProps {
  title: string;
  fileList: UploadFile[];
  maxLength: number;
  onUploadChange: ({ isAdding, file }: IOnUploadFontChange) => void;
  validationSchema: ZodType<any, ZodTypeDef, any>;
  minimum?: number;
  required?: boolean;
}

const UploadFont = ({
  title,
  fileList,
  required,
  onUploadChange,
  validationSchema,
  ...lengthProps
}: IUploadFontProps) => {
  const { data: validationData } = useAsyncZodValidation(
    fileList,
    validationSchema,
    [title, JSON.stringify(fileList)],
  );

  const computedFiles: UploadFile[] = useMemo(() => {
    if (validationData && validationData.success) return fileList;

    return fileList.map((file, index) => {
      const fileHasError =
        getErrorFromZod(validationData?.error, [index]) || "";

      if (!fileHasError) return file;
      return {
        ...file,
        status: "error",
        response: fileHasError,
        xhr: file.response,
      };
    });
  }, [validationData, fileList]);

  const cancelSubmit = () => {
    return false;
  };

  const onChange: UploadProps["onChange"] = async ({
    file: currentFile,
    fileList: updatedFileList,
  }) => {
    const isAdding = updatedFileList.length > fileList.length;
    const file = isAdding
      ? updatedFileList[updatedFileList.length - 1]
      : currentFile;

    if (!file) return;

    const updatedFile: UploadFile = isAdding
      ? {
          ...file,
          thumbUrl: await utils.toBase64(file.originFileObj), // toBase64 might throw error but this line is fine because we check "file" above.
        }
      : file;

    onUploadChange({ isAdding, file: updatedFile });
  };

  const displayError = useMemo(() => {
    if (validationData && !validationData.success) {
      return (
        getErrorFromZod(validationData.error, []) || "Please check the errors"
      );
    }
    return "";
  }, [validationData]);

  return (
    <Form.Item
      name={camelCase(title)}
      colon={false}
      help={displayError !== "" ? displayError : undefined}
      className={styles.formItem}
      data-cy="upload-font-item"
      rules={[
        {
          validator(_, __, callback) {
            if (validationData?.success || !computedFiles.length) {
              callback();
            } else {
              callback(displayError);
            }
          },
        },
      ]}
    >
      <Upload
        fileList={computedFiles}
        beforeUpload={cancelSubmit}
        onChange={onChange}
        className={styles.upload}
        iconRender={() => null}
        data-cy="upload-font"
      >
        {fileList.length < lengthProps.maxLength && (
          <Button className={styles.addButton} type="link">
            + Add fonts
          </Button>
        )}
      </Upload>
    </Form.Item>
  );
};

export default UploadFont;
