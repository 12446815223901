import { RefObject, useLayoutEffect, useState } from "react";

type Props = {
  ref: RefObject<HTMLElement>;
  /** Dependencies to watch for changes */
  deps?: any[];
};

export const useIsOverflowing = ({ ref, deps }: Props) => {
  const [isOverflowingY, setIsOverflowingY] = useState(false);
  const [isOverflowingX, setIsOverflowingX] = useState(false);

  useLayoutEffect(() => {
    if (!ref.current) return;

    const trigger = () => {
      if (!ref.current) return;

      const { scrollHeight, clientHeight, scrollWidth, clientWidth } =
        ref.current;

      setIsOverflowingX(scrollHeight > clientHeight);
      setIsOverflowingY(scrollWidth > clientWidth);
    };

    const resizeObserver = new ResizeObserver(trigger);
    resizeObserver.observe(ref.current);

    trigger();

    return () => resizeObserver.disconnect();
  }, [ref, deps]);

  return { isOverflowingX, isOverflowingY };
};
