import { memo, useState } from "react";
import { EditorState, ContentBlock, genKey, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import styles from "./SimpleRTE.module.scss";
import { range } from "lodash";
import ColorPicker from "./ColorPicker";
import { Select } from "antd";
import { List } from "immutable";
import Immutable from "immutable";

// https://24ways.org/2010/calculating-color-contrast
function getContrastYIQ(hexColor: string) {
  hexColor = hexColor.replace("#", "");
  const r = parseInt(hexColor.substr(0, 2), 16);
  const g = parseInt(hexColor.substr(2, 2), 16);
  const b = parseInt(hexColor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
}

export interface IRTEValues {
  editorState: EditorState;
  fontSize: number;
  fontFamily: string;
  textColor: string;
}

interface IProps {
  values?: IRTEValues;
  onChange?(key: keyof IRTEValues, value: any): void;
  onValidate?(): void;
}

const SimpleRTE = ({ values, onChange, onValidate }: IProps) => {
  const { editorState, fontFamily, fontSize, textColor = "" } = values || {};
  const handleEditorStateChange = (newEditorState: EditorState) => {
    const valueChanged = !Immutable.is(
      newEditorState.getCurrentContent(),
      editorState!.getCurrentContent(),
    );

    onChange?.("editorState", newEditorState);
    if (valueChanged) {
      onValidate?.();
    }
  };

  const toolbar = {
    options: ["fontFamily", "fontSize", "inline", "colorPicker"],
    inline: {
      options: ["bold", "italic", "underline"],
      className: styles.toolbarButtons,
    },
    fontSize: {
      options: range(6, 60),
      component: function SelectFontSize() {
        return (
          <Select
            onChange={(value: number) => onChange?.("fontSize", value)}
            defaultValue={fontSize}
            showSearch
            style={{ width: 60, height: "fit-content" }}
            className="noErrorStyles"
          >
            {range(6, 61).map(n => (
              <Select.Option key={n} value={n}>
                {n}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    fontFamily: {
      component: function SelectFontFamily() {
        return (
          <Select
            onChange={(value: string) => onChange?.("fontFamily", value)}
            defaultValue={fontFamily}
            style={{
              width: 180,
              marginRight: 3,
              height: "fit-content",
            }}
            showSearch
            className="noErrorStyles"
          >
            {[
              "sans-serif",
              "serif",
              "HelveticaNeue",
              "HelveticaNeue-Light",
              "HelveticaNeue-Medium",
              "HelveticaNeue-UltraLight",
              "HelveticaNeue-CondensedBold",
              "Georgia",
            ].map(fontFamilyItem => (
              <Select.Option key={fontFamilyItem} value={fontFamilyItem}>
                {fontFamilyItem}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    colorPicker: {
      component: function ColorPic() {
        const [internalTextColor, setInternalTextColor] = useState(textColor);
        return (
          <ColorPicker
            color={internalTextColor}
            onChange={color => {
              const newColor = color.hex.replace("#", "").toUpperCase();
              onChange?.("textColor", newColor);
              setInternalTextColor(newColor);
            }}
          />
        );
      },
    },
  };

  return (
    <div>
      <div
        style={{
          fontSize,
          fontFamily,
          color: `#${textColor}`,
          display: "inline-block",
        }}
        className={
          getContrastYIQ(textColor) === "white"
            ? styles.whiteEditor
            : styles.blackEditor
        }
      >
        <Editor
          editorState={editorState}
          onEditorStateChange={handleEditorStateChange}
          wrapperClassName={`${styles.wrapper} customField`}
          toolbarClassName={styles.toolbar}
          editorClassName={styles.editor}
          toolbar={toolbar}
          textAlignment="center"
          stripPastedStyles
          onContentStateChange={() => {
            const blocks = editorState?.getCurrentContent().getBlocksAsArray();

            if ((blocks?.length || 0) > 1) {
              const newEditorState = condenseBlocks(editorState!, blocks);
              handleEditorStateChange(newEditorState);
            }
          }}
        />
      </div>
    </div>
  );
};

/**
 * Condense an array of content blocks into a single block
 * @param  {EditorState} editorState draft-js EditorState instance
 * @param  {Array} blocks Array of ContentBlocks
 * @param  {Object} options
 * @return {EditorState} A modified EditorState instance
 */
function condenseBlocks(
  editorState: EditorState,
  blocks: ContentBlock[] | undefined,
) {
  blocks = blocks || editorState.getCurrentContent().getBlocksAsArray();
  let text = List();
  let characterList = List();

  // Gather all the text/characterList and concat them
  blocks.forEach(block => {
    // Atomic blocks should be ignored (stripped)
    if (block.getType() !== "atomic") {
      text = text.push(replaceNewlines(block.getText()));
      characterList = characterList.concat(block.getCharacterList()).toList();
    }
  });

  // Create a new content block
  const contentBlock = new ContentBlock({
    key: genKey(),
    text: text.join(""),
    type: "unstyled",
    characterList: characterList,
    depth: 0,
  });

  // Update the editor state with the compressed version
  const newContentState = ContentState.createFromBlockArray([contentBlock]);
  // Create the new state as an undoable action
  editorState = EditorState.push(editorState, newContentState, "remove-range");
  // Move the selection to the end
  return EditorState.moveFocusToEnd(editorState);
}

/**
 * Greedy regex for matching newlines
 * @type {RegExp}
 */
const NEWLINE_REGEX = /\n/g;

/**
 * Replace newline characters with the passed string
 * @param  {String} str String to replace
 * @param  {String} replacement Replacement characters
 * @return {String} Modified string
 */
function replaceNewlines(str: string, replacement = " ") {
  return str.replace(NEWLINE_REGEX, replacement);
}

export default memo(SimpleRTE);
