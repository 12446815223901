import { CallToAction } from "screens/adLibrary/facebookUtils/types";
import { getCtaText } from "screens/adLibrary/utils";
import styles from "./CtaSection.module.scss";

type Props = {
  linkCaption: string | undefined;
  title: string | undefined;
  cta: CallToAction | undefined;
  description: string | undefined;
};

const CtaSection = ({ linkCaption, title, cta, description }: Props) => {
  const ctaText = cta ? getCtaText(cta) : "";

  return (
    <div className={styles.cta}>
      <div className={styles.ctaValue}>
        <div className={styles.ctaLinkCaption}>{linkCaption}</div>
        <div className={styles.ctaTitle}>{title}</div>
        <div className={styles.ctaDescription}>{description}</div>
      </div>
      {ctaText && (
        <div className={styles.ctaBtn}>
          <div>{ctaText}</div>
        </div>
      )}
    </div>
  );
};

export default CtaSection;
