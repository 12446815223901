import PlayIcon from "../PlayIcon";
import { ImgDimensions } from "../MediaPreview";
import styles from "../MediaPreview.module.scss";

type Props = {
  thumbnailUrl: string;
  isPaused: boolean;
  setIsPaused: (isPaused: boolean) => void;
  setImageDimensions: (dimensions: ImgDimensions) => void;
  hasVideo: boolean;
};

const ImagePreview = ({
  thumbnailUrl,
  isPaused,
  setIsPaused,
  setImageDimensions,
  hasVideo,
}: Props) => {
  return (
    <div style={{ position: "relative" }}>
      <img
        src={thumbnailUrl}
        alt="thumbnail"
        className={styles.asset}
        onLoad={({ currentTarget }) =>
          setImageDimensions({
            naturalWidth: currentTarget.naturalWidth,
            naturalHeight: currentTarget.naturalHeight,
            clientWidth: currentTarget.clientWidth,
            clientHeight: currentTarget.clientHeight,
          })
        }
        onClick={() => setIsPaused(false)}
      />
      {isPaused && hasVideo && <PlayIcon className={styles.playIcon} />}
    </div>
  );
};

export default ImagePreview;
