import { useEffect, RefObject } from "react";

type Focusable = {
  focus: () => void;
  blur: () => void;
};

/**
 * Utility hook to focus or blur a ref based on a given condition.
 * @example
 * const ref = useRef<HTMLInputElement>();
 * const shouldFocus = true;
 * useRefFocus(ref, shouldFocus);
 */
export const useRefFocus = <T extends Focusable>(
  ref: RefObject<T>,
  condition: boolean,
) => {
  useEffect(() => {
    if (condition && ref?.current) ref.current.focus();
    else ref?.current?.blur();
  }, [condition, ref]);
};
