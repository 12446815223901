import { Button, Tooltip } from "antd";
import { getNextButtonTitle } from "./utils/getNextButtonTitle";
import { useFeedContext } from "../../shared/contexts/FeedContext";
import { useAdLibraryExportContext } from "../adLibrary/AdLibraryExportContext";
import { usePortalExportContext } from "../portal/PortalExportContext";
import { useColumnValidation } from "../portal/useColumnValidation";
import { isNextButtonDisabled } from "./utils/isNextButtonDisabled";
import { getNextButtonText } from "./utils/getNextButtonText";
import { useMetadataValidation } from "../portal/useMetadataValidation";

export const FooterNextButton = ({
  handleNextButtonClick,
  currentStep,
  isStartingExport,
}: {
  handleNextButtonClick: () => void;
  currentStep: number;
  isStartingExport: boolean;
}) => {
  const { selectedAssetBatches } = useFeedContext();
  const {
    exportDestination,
    adType,
    validity: adLibraryValidity,
  } = useAdLibraryExportContext();
  const { formValues } = usePortalExportContext();
  const { validateBrandsColumnQuery, validateAccountsColumnQuery } =
    useColumnValidation();
  const validateMetadataQuery = useMetadataValidation();
  const isBrandColumnValid = validateBrandsColumnQuery.data === true;
  const isAccountColumnValid = validateAccountsColumnQuery.data === true;
  const isMetadataValid = validateMetadataQuery.data !== false;
  const isLoading =
    validateMetadataQuery.isLoading ||
    validateBrandsColumnQuery.isLoading ||
    validateAccountsColumnQuery.isLoading;

  const { brandId, accountId } = formValues;

  return (
    <Tooltip
      title={
        validateMetadataQuery.data === false &&
        "The selected feed columns exceed the maximum data limit. Please select a smaller set of columns."
      }
      placement="topLeft"
    >
      <Button
        onClick={handleNextButtonClick}
        type="primary"
        disabled={isNextButtonDisabled({
          currentStep,
          exportDestination,
          adType,
          brandId,
          accountId,
          selectedAssetBatches,
          adLibraryValidity,
          isBrandColumnValid,
          isAccountColumnValid,
          isMetadataValid,
        })}
        loading={isStartingExport || isLoading}
        title={getNextButtonTitle(
          exportDestination,
          selectedAssetBatches,
          currentStep,
        )}
      >
        {getNextButtonText(exportDestination, currentStep)}
      </Button>
    </Tooltip>
  );
};
