import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Switch,
  Tooltip,
} from "antd";
import { FormInstance } from "antd/es/form/Form";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { IShareDashboardForm } from "shared/types/dashboardManagement";
import styles from "./PublicLink.module.scss";

export const PublicLinkSettings = ({
  publicUrl,
  form,
  initialValues,
  passwordValue,
  allowChangePassword,
  visible,
  setGeneratedPassword,
}: {
  publicUrl?: string;
  form: FormInstance<IShareDashboardForm>;
  initialValues: IShareDashboardForm;
  passwordValue: string;
  allowChangePassword: boolean;
  visible: boolean;
  setGeneratedPassword: (password: string) => void;
}) => {
  const [passwordEnabled, setPasswordEnabled] = useState(
    initialValues.passwordEnabled ?? false,
  );
  const [expirationEnabled, setExpirationEnabled] = useState(
    initialValues.expirationEnabled ?? false,
  );

  const generatePassword = () => {
    const chars =
      "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    const array = new Uint32Array(chars.length);
    window.crypto.getRandomValues(array);

    return Array.from({ length: 12 }).reduce<string>(
      (prev, _, i) => prev + chars[array[i] % chars.length],
      "",
    );
  };

  const generatePasswordHandler = () => {
    const password = generatePassword();
    const fields = form.getFieldsValue();
    form.setFieldsValue({
      ...fields,
      password,
    });
    setGeneratedPassword(password);
  };

  return (
    <div style={{ display: visible ? "block" : "none" }}>
      <Row>
        <Col span={24}>
          <p>Anyone with this link can view the dashboard.</p>
          <p>
            <Input
              value={publicUrl}
              suffix={
                <CopyToClipboard
                  text={publicUrl ?? ""}
                  onCopy={() => {
                    message.success("Copied to clipboard", 1);
                  }}
                >
                  <Button type="link" size="small">
                    copy
                  </Button>
                </CopyToClipboard>
              }
            />
          </p>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <div className={styles.inlineForm}>
            <Form.Item name="expirationEnabled" valuePropName="checked">
              <Switch
                onChange={enabled => {
                  setExpirationEnabled(enabled);
                  form.validateFields(["expirationEnabled"]);
                }}
              />
            </Form.Item>
            Link expires on
            <Form.Item
              name="expirationDate"
              rules={[
                {
                  required: expirationEnabled,
                  message: "Please enter an expiration date",
                },
              ]}
            >
              <DatePicker disabled={!expirationEnabled} />
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <div className={styles.inlineForm}>
            {allowChangePassword ? (
              <Form.Item name="passwordEnabled" valuePropName="checked">
                <Switch
                  onChange={enabled => {
                    setPasswordEnabled(enabled);
                    const fields = form.getFieldsValue();
                    form.setFieldsValue({
                      ...fields,
                      password: enabled ? fields.password : "",
                    });
                  }}
                />
              </Form.Item>
            ) : (
              <Tooltip title="Please create a new link if you want to change password.">
                <Switch disabled={true} checked={passwordEnabled} />
              </Tooltip>
            )}
            Require password
            {allowChangePassword ? (
              <Form.Item
                name="password"
                rules={[
                  {
                    required: allowChangePassword && passwordEnabled,
                    message: "Please enter or generate a password",
                  },
                ]}
              >
                <Input
                  style={{ width: 300 }}
                  disabled={!passwordEnabled}
                  className={styles.passwordInput}
                  placeholder={`type password or click "Generate"`}
                  suffix={
                    passwordEnabled ? (
                      <CopyToClipboard
                        text={passwordValue}
                        onCopy={() => {
                          message.success("Copied to clipboard", 1);
                        }}
                      >
                        <Button type="link" size="small">
                          copy
                        </Button>
                      </CopyToClipboard>
                    ) : (
                      <Button type="link" size="small" disabled>
                        copy
                      </Button>
                    )
                  }
                />
              </Form.Item>
            ) : (
              <Tooltip title="Please create a new link if you want to change password.">
                <div>
                  <Input style={{ width: 300 }} disabled />
                </div>
              </Tooltip>
            )}
            {allowChangePassword && (
              <Button
                type="primary"
                htmlType="submit"
                disabled={!passwordEnabled}
                onClick={generatePasswordHandler}
              >
                Generate
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};
