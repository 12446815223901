import { Popover, Slider } from "antd";
import { useCallback, useMemo } from "react";
import styles from "./Opacity.module.scss";

type Props = {
  opacity: number;
  onOpacityChange: (opacity: number) => void;
};
const Opacity = (props: Props) => {
  const { onOpacityChange, opacity } = props;
  const onChange = useCallback(
    (value: number) => {
      const convertedValue = value / 100;
      onOpacityChange(convertedValue);
    },
    [onOpacityChange],
  );

  const value = useMemo(() => Math.round(opacity * 100), [opacity]);
  return (
    <div className={styles.Opacity}>
      <Popover
        trigger="click"
        placement="bottom"
        content={
          <div style={{ width: "13em" }}>
            <Slider min={0} max={100} value={value} onChange={onChange} />
          </div>
        }
      >
        <span>{`${value}%`}</span>
      </Popover>
    </div>
  );
};

export default Opacity;
