import { useV3Query } from "./useV3Query";
import { ClientFragment } from "../../../graphql/v3.gen";

export const useV3Client = () => {
  const orgId = process.env.REACT_APP_AUTH_ORGANIZATION;

  const { data, loading, error } = useV3Query<{ clients: ClientFragment[] }>({
    variables: {
      input: {
        query: "v2GetClientByOrgId",
        variables: {
          orgId,
        },
      },
    },
  });

  const client = data?.v2AccessQueryExt?.clients[0];

  return {
    loading,
    error,
    client,
  };
};
