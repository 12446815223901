import { Steps } from "antd";
import { FC } from "react";

import { IHeader } from "../types/header";

import "./Header.scss";

const Header: FC<IHeader> = ({
  style,
  topSteps,
  middleSteps,
  actionButtons,
  saveButton,
}) => {
  return (
    <div className="header-container">
      <div style={style} className="header-steps-container">
        <div style={topSteps.style} className="top-steps">
          <Steps size="small">
            {topSteps.steps.map(step => {
              return (
                <Steps.Step
                  key={`top-step-key-${step.title}`}
                  className={
                    topSteps.selected === step.title
                      ? "active enabled"
                      : step.state || "disabled"
                  }
                  icon={<div />}
                  onClick={
                    step.state && step.state === "enabled"
                      ? step.onClick
                      : undefined
                  }
                  title={step.title}
                />
              );
            })}
          </Steps>
        </div>

        {middleSteps &&
          middleSteps.map(innerSteps => (
            <div
              key={`middle-steps-container-key-${JSON.stringify(innerSteps)}`}
              style={innerSteps.style}
              className="middle-steps"
            >
              <Steps size="small">
                {innerSteps.steps.map(step => (
                  <Steps.Step
                    key={`middle-step-key-${step.title}`}
                    className={
                      innerSteps.selected === step.title
                        ? "active enabled"
                        : step.state || "disabled"
                    }
                    data-cy={`${step.title}-${
                      innerSteps.selected !== step.title && "disabled"
                    }`}
                    icon={<div />}
                    title={step.title}
                    onClick={
                      step.state && step.state === "enabled"
                        ? step.onClick
                        : undefined
                    }
                  />
                ))}
              </Steps>
            </div>
          ))}
      </div>
      <div className="action-buttons-container">
        {saveButton}
        {actionButtons &&
          actionButtons.map(actionButton => {
            return actionButton;
          })}
      </div>
    </div>
  );
};

export default Header;
