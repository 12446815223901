/* eslint-disable react/display-name */
import { Form, Input, Typography } from "antd";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { camelCase } from "lodash";

import styles from "../../PMaxAssetGroupList.module.scss";
import detailDrawerStyles from "../AssetGroupDetailDrawer.module.scss";
import { IAssetGroup, MultiInputAssetType } from "shared/types/adLibrary";
import { usePMaxDataContext } from "shared/components/contextAPI/pmaxAssetGroup/PMaxDataProvider";
import {
  GOOGLE_ADS_ASSET_ITEMS_LIMIT,
  multiInputAssetDetail,
} from "screens/adLibrary/constants";
import { getMultiInputAssetSchema } from "shared/schemas/pmax";
import { getErrorFromZod, useZodFormValidation } from "utils/helpers.zod";
import TitleWithDescription from "shared/components/uploadAsset/TitleWithDescription";
import { getRepeatedHeadlines } from "../../utils";

const MultiInputAsset = ({
  title,
  values,
  maxLength, // input characters limit
  fieldKey,
  showLength,
}: {
  title: MultiInputAssetType;
  values: string[];
  maxLength: number;
  fieldKey: keyof IAssetGroup["multiInputAsset"];
  showLength: boolean;
}) => {
  const ctx = usePMaxDataContext();
  const updateAssetGroup = (
    fieldKey: keyof IAssetGroup["multiInputAsset"],
    newValues: string[],
    id?: string,
  ) => {
    if (!id) return;

    ctx.setUpdatedAssetGroups(prev => {
      const { [id]: existingAssetGroup = {} } = prev || {};
      const { multiInputAsset = {} } = existingAssetGroup || {};
      return {
        ...(prev || {}),
        [id]: {
          ...existingAssetGroup,
          multiInputAsset: {
            ...multiInputAsset,
            [fieldKey]: newValues,
          },
        },
      };
    });
    const updatedAssetGroup = {
      ...(ctx.selectedAssetGroup || {}),
      multiInputAsset: {
        ...(ctx.selectedAssetGroup?.multiInputAsset || {}),
        [fieldKey]: newValues,
      },
    };
    ctx.setEditAssetGroup(updatedAssetGroup);
  };
  const deleteRow = (index: number) => {
    const newValues = values.filter((_, idx) => index !== idx);

    updateAssetGroup(fieldKey, newValues, ctx.selectedAssetGroup?.id);
  };
  const addRow = () => {
    const { id } = ctx.selectedAssetGroup || {};
    if (!id) return;

    const newValues = [...values, ""];

    updateAssetGroup(fieldKey, newValues, ctx.selectedAssetGroup?.id);
  };

  const onChange = (newValue: string, idx: number) => {
    const newValues = values.map((value, index) => {
      return index === idx ? newValue : value;
    });

    updateAssetGroup(fieldKey, newValues, ctx.selectedAssetGroup?.id);
  };

  const { minimum, info } = multiInputAssetDetail[title];
  const textAreaStyle = (value: string) => {
    return value?.length > maxLength
      ? styles.textAreaWithError
      : styles.textArea;
  };

  const canDeleteInput = values.length > 1;

  const { multiInputAssets } = useZodFormValidation({
    multiInputAssets: {
      data: values,
      schema: getMultiInputAssetSchema(title),
    },
  });

  const generalError = getErrorFromZod(multiInputAssets.errors, []);

  const repeatedItems =
    title === "Headlines" ? getRepeatedHeadlines(values) : [];

  const shouldShowRepeatedItemsError = (index: number) =>
    repeatedItems.includes(index) && repeatedItems.indexOf(index) !== 0;

  return (
    <div className={detailDrawerStyles.section}>
      <div>
        <TitleWithDescription
          title={title}
          numberLimit={5}
          minimum={minimum}
          info={info}
          required={!!minimum}
        />
        {values.map((value, idx) => (
          <Form.Item
            key={`${camelCase(title)}-${idx}`}
            name={`${camelCase(title)}-${idx}`}
            help={
              shouldShowRepeatedItemsError(idx)
                ? "This item is repeated"
                : getErrorFromZod(multiInputAssets.errors, [idx])
            }
            validateStatus={
              getErrorFromZod(multiInputAssets.errors, [idx]) ||
              repeatedItems.includes(idx)
                ? "error"
                : "success"
            }
          >
            <div className={styles.inlineBox} key={`input-key-${idx}`}>
              <Input.TextArea
                className={textAreaStyle(value)}
                value={value}
                onChange={e => onChange(e.target.value, idx)}
                showCount={showLength}
                maxLength={maxLength <= 90 ? maxLength : undefined}
              />
              <DeleteOutlined
                className={styles.icon}
                onClick={() => (canDeleteInput ? deleteRow(idx) : undefined)}
              />
            </div>
          </Form.Item>
        ))}

        {values?.length < GOOGLE_ADS_ASSET_ITEMS_LIMIT[fieldKey] && (
          <div className={styles.clickableWithIcon} onClick={addRow}>
            <PlusCircleOutlined />
            <Typography.Text
              className={styles.clickable}
            >{`Add ${title}`}</Typography.Text>
          </div>
        )}
      </div>

      {generalError && (
        <div className={detailDrawerStyles.sectionError}>{generalError}</div>
      )}
    </div>
  );
};

export default MultiInputAsset;
