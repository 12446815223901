import { ExclamationCircleFilled } from "@ant-design/icons";
import { Typography } from "antd";
import { pickBy } from "lodash";
import { shallowEqual, useSelector } from "react-redux";
import { IAdLibraryState } from "shared/types/adLibrary";

import styles from "./RuleErrors.module.scss";
import { hasAnyFilledRule } from "./helpers.rules";
import {
  hasMoreAdsThanCapacity,
  LOAD_CAPACITY,
} from "screens/adLibrary/adLoad/utils";

const RuleErrors = () => {
  const {
    adLoadParameters,
    adLoadDestination,
    facebookDataIdsByAd,
    selectedAds,
  } = useSelector(
    ({ adLibrary }: { adLibrary: IAdLibraryState }) => ({
      adLoadParameters: adLibrary.adLoad.adLoadParameters,
      facebookDataIdsByAd: adLibrary.adLoad.facebookDataIdsByAd,
      adLoadDestination: adLibrary.adLoad.destination,
      selectedAds: adLibrary.adLoad.selectedAds,
    }),
    shallowEqual,
  );

  const hasAdWithConflicts = () => {
    return adLoadDestination?.selectedAds?.some(selectedAd => {
      return (adLoadParameters?.conflicts?.[selectedAd.id]?.length ?? 0) > 0;
    });
  };

  const hasAdWithoutRule = () => {
    const adsWithRules = pickBy(adLoadParameters?.rulesets, (ruleset, adId) => {
      const isCheckedAd = adLoadDestination?.selectedAds
        ?.map(ad => ad.id)
        .includes(adId);
      if (isCheckedAd) {
        return (
          hasAnyFilledRule(ruleset?.campaign) ||
          hasAnyFilledRule(ruleset?.adset) ||
          hasAnyFilledRule(ruleset?.ad)
        );
      }

      return false;
    });

    return (
      Object.keys(adsWithRules).length !==
      adLoadDestination?.selectedAds?.length
    );
  };

  const Message = ({ message }: { message: string }) => (
    <div className={styles.message}>
      <ExclamationCircleFilled className={styles.icon} size={16} />
      <Typography.Text className={styles.text}>{message}</Typography.Text>
    </div>
  );

  return (
    <div className={styles.container}>
      {hasAdWithConflicts() && hasAdWithoutRule() && (
        <Message message="Rules have not been set yet for an Ad Shell." />
      )}
      {hasAdWithConflicts() && (
        <Message
          message="The rules of this ad shell are conflicting. 
          Change the applied rules to the selected ad to resolve this."
        />
      )}
      {!hasAdWithConflicts() &&
        hasMoreAdsThanCapacity(selectedAds, facebookDataIdsByAd) && (
          <Message
            message={`You can't load more than ${LOAD_CAPACITY} ads at once.`}
          />
        )}
    </div>
  );
};

export default RuleErrors;
