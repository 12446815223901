import { FileDoneOutlined, FileSyncOutlined } from "@ant-design/icons";
import { Button, Col, notification, Progress, Row, Tag } from "antd";
import styles from "./ProcessNotification.module.scss";
import { LinkButton } from "./exportDrawer/shared/exportMessage/LinkButton";
import StatusDescription from "./exportDrawer/shared/exportMessage/StatusDescription";
import { ExportDestination } from "./exportDrawer/shared/types";

type Props = {
  message: string;
  status: string;
  percentage: number;
  getCsvDownloadUrl: () => void;
  notificationKey: string;
  exportDestination: ExportDestination;
  onClose: () => void;
  onAdLibClick: () => void;
  portalLink?: string;
  sheetLink?: string;
  exportRowCount: number | null;
  exportAssetBatchNames: string[];
  exportFeedName: string | null | undefined;
  exportClientSlug: string | null | undefined;
};

const descriptionCompleted = (
  exportDestination: ExportDestination,
  portalLink: string,
  sheetLink: string,
  onCsvClick: () => void,
  exportClientSlug: string | null | undefined,
  framed: boolean,
) => {
  switch (exportDestination) {
    case ExportDestination.CSVDownload:
      return (
        <div
          onClick={onCsvClick}
          className={`${styles.downloadBtn} ${styles.statusDescription}`}
        >
          Download
        </div>
      );
    case ExportDestination.GoogleSpreadsheet:
      return (
        <div className={`${styles.downloadBtn} ${styles.statusDescription}`}>
          <LinkButton href={portalLink ?? ""} label="Go to Portal" />
          <LinkButton href={sheetLink ?? ""} label="Go to Spreadsheet" />
        </div>
      );
    case ExportDestination.AdLibrary:
      return (
        <div className={`${styles.downloadBtn} ${styles.statusDescription}`}>
          <LinkButton href="/ad-library" label="Go to Ad Library" />
        </div>
      );
    case ExportDestination.Portal:
      const subdomain = process.env.REACT_APP_ENV === "dev" ? "dev" : "app";
      const v3PortalLink = framed
        ? `/v3/portal`
        : `https://${subdomain}.constech.io/${exportClientSlug}/portal`;

      return (
        <div className={`${styles.downloadBtn} ${styles.statusDescription}`}>
          {framed ? (
            <Button
              type="link"
              onClick={() => {
                window.location.href = v3PortalLink;
              }}
            >
              Go to Portal
            </Button>
          ) : (
            <LinkButton href={v3PortalLink} label="Go to Portal" />
          )}
        </div>
      );
    default:
      return "";
  }
};

const getProgressStatus = (status: string) => {
  if (status === "Error") return "exception";
  if (status === "Completed") return "success";
  return "active";
};

const Description = ({
  message,
  status,
  percentage,
  getCsvDownloadUrl,
  exportDestination,
  notificationKey,
  portalLink,
  sheetLink,
  exportRowCount,
  exportAssetBatchNames,
  exportFeedName,
  exportClientSlug,
}: Props) => {
  const currStatus = getProgressStatus(status);
  const isDataProcessing = percentage !== 100 && status !== "Error";

  const isDataExportCompleted = percentage === 100;
  const exportingText = "Generating assets";
  const framed = window.location !== window.parent.location;

  return (
    <div>
      {isDataProcessing ? (
        <span>{message}</span>
      ) : (
        <Row>
          <Col span={24}>
            {status === "Error" ? (
              <div>{message}</div>
            ) : (
              <>
                {exportDestination === ExportDestination.AdLibrary && (
                  <div className={styles.adLibExportDescription}>
                    <div>
                      <strong>{exportRowCount}</strong> ads have been created in
                      Ad Library
                    </div>
                    <div>
                      {`Feed: `}
                      <strong>{exportFeedName}</strong>
                    </div>
                    <div>
                      Asset batches:{" "}
                      {exportAssetBatchNames.map(name => (
                        <Tag key={name}>{name}</Tag>
                      ))}
                    </div>
                  </div>
                )}
                {exportDestination === ExportDestination.CSVDownload && (
                  <div>Export to CSV</div>
                )}
                {exportDestination === ExportDestination.GoogleSpreadsheet && (
                  <div>
                    The assets have been exported to the spreadsheet-enabled
                    portal.
                  </div>
                )}
                {exportDestination === ExportDestination.Portal && (
                  <div>The assets have been exported to Portal.</div>
                )}
              </>
            )}
          </Col>
          <Col span={24}>
            {status === "Error" ? (
              <div className={styles.errorDescription}>
                <div className={styles.errorText}>Failed</div>
                <Button
                  type="link"
                  onClick={() => notification.close(notificationKey)}
                >
                  Return to Configure
                </Button>
              </div>
            ) : (
              <StatusDescription
                exportingText={exportingText}
                isDataExportCompleted={isDataExportCompleted}
              >
                {descriptionCompleted(
                  exportDestination,
                  portalLink ?? "",
                  sheetLink ?? "",
                  getCsvDownloadUrl,
                  exportClientSlug,
                  framed,
                )}
              </StatusDescription>
            )}
          </Col>
        </Row>
      )}

      <Progress percent={percentage} showInfo={true} status={currStatus} />
    </div>
  );
};

export const assetBatchExportNotification = (props: Props) => {
  const currStatus = getProgressStatus(props.status);

  notification.open({
    key: props.notificationKey,
    icon: (
      <div className={styles.notificationIcon}>
        {currStatus !== "success" ? <FileSyncOutlined /> : <FileDoneOutlined />}
      </div>
    ),
    message: (
      <strong>
        {currStatus !== "success" ? "Exporting" : "Export Complete"}
      </strong>
    ),
    description: <Description {...props} />,
    duration: null,
    placement: "bottomRight",
    onClose: props.onClose,
    style: { width: "400px" },
  });
};
