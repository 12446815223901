import { notification } from "antd";
import moment from "moment";
import { useCallback } from "react";
import {
  useGeneratePdfStartExecution,
  useIsLoadingPdfExecutionDescriptions,
} from "shared/hooks/adLibrary/adReview/useGeneratePdfExecutions";
import { useUser } from "shared/hooks/useUser";
import { SelectedStore } from "shared/hooks/userPreferences/useSelectedStore";
import { SelectedDates } from "shared/types/campaignManagement";
import { usePdfExecNames } from "./usePdfExecNames";
import { extractPrimaryIdsFromCarouselAds } from "../utils/helpers.ad";
import { PdfInputPayload } from "shared/types/adReview";

interface IUseAds {
  selectedIds: string[];
  selectedStore?: SelectedStore;
  selectedDateRange: SelectedDates;
}

const usePdfGeneration = ({
  selectedIds,
  selectedStore,
  selectedDateRange,
}: IUseAds) => {
  const user = useUser();

  const {
    mutate: generatePdfStartExecution,
    isLoading: isStartingPdfGeneration,
  } = useGeneratePdfStartExecution();

  const isLoadingPdfExecutionDescriptions =
    useIsLoadingPdfExecutionDescriptions();

  const { pdfExecNames, handleSetPdfExecNames } = usePdfExecNames();

  const handleGeneratePdfStartExecution = useCallback(() => {
    const fbAccountId = selectedStore?.fbAccountId;
    if (!fbAccountId) return;

    const allPrimaryAdsIds = extractPrimaryIdsFromCarouselAds(selectedIds);
    const userFullName = user?.name;

    const formatNumericDate = (date: number) =>
      date ? moment(date)?.format("YYYY/MM/DD") : undefined;

    const payload: PdfInputPayload = {
      ads: allPrimaryAdsIds.map(adId => ({ id: adId })),
      accountId: fbAccountId,
      accountName: selectedStore.name, // Using store name instead of account name
      since: formatNumericDate(selectedDateRange?.[0])!,
      until: formatNumericDate(selectedDateRange?.[1])!,
      fullName: userFullName,
      sender: user?.email,
      timezone:
        Intl?.DateTimeFormat().resolvedOptions().timeZone || "America/New_York",
    };
    generatePdfStartExecution(payload, {
      onSuccess: data => {
        handleSetPdfExecNames(pdfExecNames.concat(data.execName));
      },
      onError: () => {
        notification.error({
          message:
            "Something went wrong while starting the PDF generation process",
          placement: "bottomRight",
        });
      },
    });
  }, [
    generatePdfStartExecution,
    handleSetPdfExecNames,
    pdfExecNames,
    selectedIds,
    selectedDateRange,
    selectedStore?.fbAccountId,
    selectedStore?.name,
    user?.email,
    user?.name,
  ]);

  return {
    isStartingPdfGeneration,
    isLoadingPdfExecutionDescriptions,
    handleGeneratePdfStartExecution,
  };
};
export default usePdfGeneration;
