import { SkipToken, skipToken } from "@reduxjs/toolkit/dist/query";
import { useCallback } from "react";
import { useGetCurrentUserQuery } from "redux/media/media.api";
import { useCustomSearchAssetsQuery } from "../useCustomSearchAssetsQuery";
import { useQPBreadcrumb } from "../useQPBreadcrumb";
import { useQPSearch } from "../useQPSearch";

export const useTableSearch = () => {
  const { searchState, setSearchState } = useQPSearch();
  const { current: albumId } = useQPBreadcrumb();
  const { data: currentUser } = useGetCurrentUserQuery();
  const { data, isFetching } = useCustomSearchAssetsQuery(
    getSearchQuery({
      searchState,
      currentUser,
      albumId,
    }),
  );
  const setPage = useCallback(
    (page: number) => {
      setSearchState({ page });
    },
    [setSearchState],
  );

  return { data, isFetching, setPage };
};

type SearchQuery = {
  searchState?: WDSearchPayload;
  currentUser?: WDUser;
  albumId?: string;
};

const getSearchQuery = ({
  searchState,
  currentUser,
  albumId,
}: SearchQuery): MGSearchPayload | SkipToken => {
  if (searchState && currentUser) {
    const { page, query, filters, published_filter } = searchState;

    return {
      withMeta: true,
      payload: {
        page,
        query,
        filters,
        published_filter,
        rows: 50,
        album_id: albumId,
        user_id: currentUser.id,
      },
    };
  }

  return skipToken;
};
