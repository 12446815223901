import { Collapse } from "antd";
import { FC, useContext, useEffect, useState } from "react";

import ManageLayers from "./propertyRow/ManageLayers";
import ManageArtboard from "./propertyRow/ManageArtboard";
import LogoDropZones from "./propertyRow/LogoDropZones";
import ManageText from "./propertyRow/ManageText";
import ManageShape from "./propertyRow/ManageShape";

import { context } from "../../Editor.context";
import {
  ExtendedObjectType,
  isStamp,
  isTemplate,
} from "shared/types/designStudio";
import useDeepEffect from "shared/hooks/useDeepEffect";
import "../../../../shared/styles/Collapse.scss";
import "./PropertyRow.scss";
import PrimaryMessage from "./propertyRow/PrimaryMessage";
import ManageStamp from "./propertyRow/ManageStamp";

const PropertyRow: FC = () => {
  const editorContext = useContext(context);
  const [enabledPropertySection, setEnabledPropertySection] =
    useState<ExtendedObjectType>();

  useDeepEffect(() => {
    let selectedType: ExtendedObjectType | undefined = undefined;

    if (!editorContext?.selectedObject?.selectable) {
      setEnabledPropertySection(undefined);
      return;
    }

    const { type } = editorContext?.selectedObject || { type: undefined };
    switch (type) {
      case "textbox":
        selectedType = "text";
        break;
      case "rect":
      case "triangle":
      case "circle":
        selectedType = "shape";
        break;
    }
    setEnabledPropertySection(selectedType);
  }, [editorContext?.selectedObject]);

  const [activeKey, setActiveKey] = useState<string[]>();
  useEffect(() => {
    if (!enabledPropertySection) return;

    setActiveKey(prev => [...(prev || []), enabledPropertySection] as string[]);
  }, [enabledPropertySection]);

  return (
    <Collapse
      className={`collapse-container property-row design-studio client-collapse`}
      expandIconPosition="right"
      activeKey={activeKey}
      onChange={key => setActiveKey(key as string[])}
    >
      {isTemplate(editorContext?.resource) && (
        <Collapse.Panel
          key="manage-artboard"
          className={`MANAGE_ARTBOARD`}
          header="Manage Artboard"
        >
          <ManageArtboard />
        </Collapse.Panel>
      )}

      {isStamp(editorContext?.resource) && (
        <Collapse.Panel
          key="manage-stamp"
          className={`MANAGE_ARTBOARD`}
          header="Manage Stamp"
        >
          <ManageStamp />
        </Collapse.Panel>
      )}

      <Collapse.Panel
        key="manage-layers"
        className={`MANAGE_LAYERS`}
        header="Layers"
      >
        <ManageLayers />
      </Collapse.Panel>

      {isTemplate(editorContext?.resource) && (
        <Collapse.Panel key="logo-drop-zone" header="Logo Drop Zones">
          <LogoDropZones />
        </Collapse.Panel>
      )}

      {isStamp(editorContext?.resource) && (
        <Collapse.Panel
          key="primary-message"
          className={`PRIMARY_MESSAGE`}
          header="Primary Message"
        >
          <PrimaryMessage />
        </Collapse.Panel>
      )}
      {enabledPropertySection === "text" && (
        <Collapse.Panel key="text" header="Text" style={{ width: "100%" }}>
          <ManageText
            textbox={editorContext?.selectedObject as fabric.Textbox}
            canvasAction={editorContext?.canvasAction}
            setObjectUpdateAction={objectUpdateAction => {
              editorContext?.setObjectUpdateAction(objectUpdateAction);
            }}
          />
        </Collapse.Panel>
      )}

      {enabledPropertySection === "shape" && (
        <Collapse.Panel key={enabledPropertySection} header="Shape">
          <ManageShape
            selectedObject={editorContext?.selectedObject}
            setObjectUpdateAction={action => {
              editorContext?.setObjectUpdateAction(action);
            }}
          />
        </Collapse.Panel>
      )}
    </Collapse>
  );
};

export default PropertyRow;
