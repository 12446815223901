import {
  ChangeEventHandler,
  Dispatch,
  FC,
  memo,
  SetStateAction,
  useState,
} from "react";
import {
  Button,
  Checkbox,
  CheckboxOptionType,
  Input,
  Popconfirm,
  Space,
  Typography,
} from "antd";
import { FilterFilled, FilterOutlined } from "@ant-design/icons";

import { returnTitleFromCampaignObjective } from "utils/facebook/helpers.campaign";

import {
  FacebookCampaignObjective,
  IFacebookCampaign,
} from "screens/adLibrary/facebookUtils/types";

import styles from "./CampaignTreeFilter.module.scss";

type CampaignData = IFacebookCampaign[] | null;

export interface IProps {
  maxWidth?: number;
  disabled?: boolean;
  loading?: boolean;
  campaigns: CampaignData;
  setFilteredCampaigns: Dispatch<SetStateAction<CampaignData>>;
  onSearchInputChange?: ChangeEventHandler<HTMLInputElement>;
}

const CampaignTreeFilter: FC<IProps> = ({
  loading,
  disabled,
  campaigns,
  onSearchInputChange,
  setFilteredCampaigns,
}) => {
  const [checkedCampaignObjectives, setCheckedCampaignObjectives] = useState<
    FacebookCampaignObjective[]
  >([]);

  const campaignObjectives = Array.from(
    new Set((campaigns ?? [])?.map(campaign => campaign.objective!) ?? []),
  );
  const campaignObjectiveOptions: CheckboxOptionType[] = campaignObjectives.map(
    objective => ({
      label: returnTitleFromCampaignObjective(objective),
      value: objective,
    }),
  );

  const isFilterDisabled = disabled || !campaignObjectiveOptions.length;

  return (
    <Input.Search
      loading={loading}
      disabled={disabled}
      placeholder="Search"
      style={{ marginBottom: 8 }}
      onChange={onSearchInputChange}
      addonAfter={
        <Popconfirm
          overlayClassName={styles.campaignObjectFilterPopover}
          icon={null}
          placement="bottomLeft"
          cancelText="Reset"
          cancelButtonProps={{ type: "link" }}
          onCancel={() => {
            setCheckedCampaignObjectives([]);
            setFilteredCampaigns(campaigns);
          }}
          onConfirm={() => {
            if (!checkedCampaignObjectives.length) {
              setFilteredCampaigns(campaigns);
              return;
            }

            setFilteredCampaigns(
              campaigns?.filter(campaign =>
                checkedCampaignObjectives?.includes(campaign.objective!),
              ) ?? [],
            );
          }}
          title={
            <Space direction="vertical">
              <Typography.Text strong>Campaign Objective</Typography.Text>
              <Checkbox.Group
                disabled={disabled}
                className={styles.flexCheckboxGroup}
                options={campaignObjectiveOptions}
                value={checkedCampaignObjectives}
                onChange={checkedValues => {
                  setCheckedCampaignObjectives(
                    checkedValues as unknown as FacebookCampaignObjective[],
                  );
                }}
              />
            </Space>
          }
          trigger={isFilterDisabled ? [] : undefined}
        >
          <Button
            disabled={isFilterDisabled}
            icon={
              checkedCampaignObjectives.length ? (
                <FilterFilled
                  style={{
                    color: "#1890ff",
                  }}
                />
              ) : (
                <FilterOutlined />
              )
            }
          />
        </Popconfirm>
      }
    />
  );
};

export default memo(CampaignTreeFilter);
