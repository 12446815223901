import { useState, useEffect, ReactElement, memo } from "react";
import { Col, message, Row, Spin, Input, Form, Radio, Checkbox } from "antd";

import API from "services";

import {
  CONDITIONS,
  IDisclosure,
  Modes,
  MODE_DUPLICATE,
  MODE_EDIT,
} from "shared/types/legalLingo";
import { IConfigurationState } from "shared/types/configuration";
import FormDrawer from "shared/components/FormDrawer";
import MultipleStoreSelect from "shared/components/MultipleAccountSelect";
import MultipleOemSelect from "shared/components/MultipleBrandSelect";
import MultipleLocationSelect from "shared/components/MultipleLocationSelect";
import { validateFieldsDisclosure } from "./utils/disclosureValidationUtils";

import style from "./NewEditDrawer.module.scss";

interface INewEditDrawerProps {
  showAddNewEditDrawer: boolean;
  disclosure: IDisclosure;
  mode: Modes;
  config?: IConfigurationState["config"];
}
interface INewEditDrawerHandlers {
  toggleAddNewEditDrawer: (toggle: boolean) => void;
  closeDrawer: () => void;
  onDisclosureUpdated: (disclosure: IDisclosure) => void;
  onDisclosureCreated: (disclosure: IDisclosure) => void;
  onDisclosureDuplicated: (disclosure: IDisclosure) => void;
  validation: (disclosure: IDisclosure) => boolean;
}

type PropsType = INewEditDrawerProps & INewEditDrawerHandlers;

const NewEditDrawer = (props: PropsType): ReactElement => {
  const [disclosure, setDisclosure] = useState<IDisclosure>(
    props.mode === "duplicate"
      ? {
          ...props.disclosure,
          name: `${
            props.disclosure.name.split("-copy")[0]
          }-copy${new Date().getTime()}`,
        }
      : props.disclosure,
  );
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const { validation } = props;
  const [drawerLoader, toggleDrawerLoader] = useState<boolean>(
    !props.disclosure,
  );

  useEffect(() => {
    toggleDrawerLoader(false);
  }, [props.disclosure.name]);

  useEffect(() => {
    const checkValidForm = () => {
      if (!disclosure?.name?.length) return false;

      if (!disclosure?.condition?.length) return false;

      return true;
    };

    setIsValidForm(checkValidForm());
  }, [disclosure, validation]);

  const handleSubmit = async () => {
    const isUpdating = props.mode === MODE_EDIT;
    const isDuplicating = props.mode === MODE_DUPLICATE;

    const messageKey = `${
      isDuplicating ? "duplicate" : "edit"
    }-params-disclosure`;

    const { error: hasError, message: errorMessage } =
      validateFieldsDisclosure(disclosure);

    if (hasError) {
      message.error(errorMessage);
      return;
    }

    if (!validation(disclosure)) {
      message.error(`Disclosure with the same parameters already exists.`);
      return;
    }

    message.loading({
      key: messageKey,
      content: `${isDuplicating ? "Duplicating" : "Updating"} Disclosure...`,
    });

    if (isDuplicating) {
      await API.services.legalLingoV2.createDisclosure(disclosure); //!TODO: Parsing Response

      message.success({
        key: messageKey,
        content: "Disclosure duplicated.",
        duration: 3,
      });

      return props.onDisclosureDuplicated(disclosure);
    } else if (isUpdating) {
      try {
        const editResponse: { result: string } =
          await API.services.legalLingoV2.updateDisclosure({
            ...disclosure,
            keyName: props.disclosure.name,
          });

        const { result: editResult } = editResponse;

        if (!!editResult && editResult.includes("updated")) {
          message.success({
            key: messageKey,
            content: editResult,
            duration: 3,
          });

          props.onDisclosureUpdated(disclosure);
        } else {
          message.error({
            key: messageKey,
            content: editResult ?? "Error updating disclosure.",
            duration: 3,
          });
        }
      } catch (error) {
        message.error({
          key: messageKey,
          content: "There was an error.",
          duration: 3,
        });
      }
    }
  };

  const handleClose = () => {
    props.closeDrawer();
    props.toggleAddNewEditDrawer(false);
  };

  const drawerForm = (
    <Form layout="vertical" hideRequiredMark={false}>
      <Spin
        className="edit-params-drawer-spinner"
        spinning={drawerLoader}
        size="large"
      >
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="disclosureName"
              label="Disclosure Name"
              required={true}
              initialValue={disclosure?.name || "loading..."}
            >
              <Input
                placeholder="Disclosure Name"
                value={disclosure?.name || "loading..."}
                onChange={e => {
                  const name = e.target.value.replace(/\s/g, "-");

                  setDisclosure({
                    ...disclosure,
                    name,
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="disclosureVinVinLess"
              label="VIN / VINLess"
              initialValue={disclosure.vin ? "vin" : "vinless"}
              required={true}
            >
              <Radio.Group
                onChange={({ target }) => {
                  setDisclosure({
                    ...disclosure,
                    vin: target.value === "vin",
                  });
                }}
                value={disclosure.vin ? "vin" : "vinless"}
                style={{ marginLeft: "1em" }}
              >
                <Radio value={"vin"}>VIN</Radio>
                <br />
                <Radio value={"vinless"}>VINLess</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="Condition(s)" required={true}>
              <Checkbox.Group
                defaultValue={disclosure.condition}
                value={disclosure.condition}
                onChange={newColumns =>
                  setDisclosure({
                    ...disclosure,
                    condition: newColumns as string[],
                  })
                }
              >
                <Row justify="space-around" align="top">
                  <Col span={24}>
                    {CONDITIONS.slice(0, 12).map(c => (
                      <div key={c}>
                        <Checkbox key={c} value={c}>
                          {c}
                        </Checkbox>
                        <br />
                      </div>
                    ))}
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="Location">
              <MultipleLocationSelect
                defaultValue={disclosure?.location}
                onChange={(value: string[]) => {
                  setDisclosure({
                    ...disclosure,
                    location: value,
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="OEM">
              <MultipleOemSelect
                defaultValue={disclosure?.oem}
                onChange={(value: string[]) => {
                  setDisclosure({
                    ...disclosure,
                    oem: value,
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="Store">
              <MultipleStoreSelect
                defaultValue={disclosure?.store}
                onChange={(value: string[]) => {
                  setDisclosure({
                    ...disclosure,
                    store: value,
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Spin>
    </Form>
  );

  return (
    <FormDrawer
      mode={props.mode === MODE_EDIT ? "UPDATE" : "DUPLICATE"}
      drawerWidth={720}
      dataName={"Disclosure"}
      showDrawer={props.showAddNewEditDrawer}
      validForm={isValidForm}
      drawerForm={drawerForm}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      processingData={false}
      className={style.editParamsDrawer}
    />
  );
};

export default memo(NewEditDrawer);
