import { Collapse, Select } from "antd";
import { FC, useMemo } from "react";

import { IBrandRecord } from "shared/types/brandManagement";
import "../../../shared/styles/Collapse.scss";
import { useAppShallowEqualSelector } from "shared/hooks/useAppSelector";
import { OrderQueryType } from "../Orders";
import { DecodedValueMap, SetQuery } from "use-query-params";
import { toBrandRecord, useFetchOems } from "shared/hooks/useFetchOems";

interface IFilterSectionOrders {
  query: DecodedValueMap<OrderQueryType>;
  setQuery: SetQuery<OrderQueryType>;
}

const FilterSectionOrders: FC<IFilterSectionOrders> = ({ query, setQuery }) => {
  const { creators } = useAppShallowEqualSelector(({ assetBuilder }) => ({
    creators: (assetBuilder as any).orders.creators,
  }));

  const brandFilters = useMemo(
    () => query.orders_oem?.filter((value): value is string => !!value) ?? [],
    [query.orders_oem],
  );

  const creatorFilters = useMemo(
    () =>
      query.orders_creator?.filter((value): value is string => !!value) ?? [],
    [query.orders_creator],
  );
  const { oems } = useFetchOems();
  const brandRecords: IBrandRecord[] = useMemo(
    () => oems.map(toBrandRecord),
    [oems],
  );

  return (
    <Collapse
      className="collapse-container design-studio-filter-section"
      expandIconPosition="right"
      defaultActiveKey="filter"
    >
      <Collapse.Panel key="filter" header="Filter">
        <div style={{ width: "100%" }}>
          <div className="filter-by">
            <div className="title">Filter By Brand</div>
            <div style={{ padding: "1em" }}>
              <Select
                data-cy="brand-filter-select"
                className="oem-order-input"
                style={{ width: "100%" }}
                placeholder="Select a Brand"
                defaultValue={brandFilters}
                allowClear={true}
                mode="multiple"
                onClear={() => {
                  setQuery({ orders_oem: undefined }, "replaceIn");
                }}
                onSelect={(value: string) => {
                  const tempArr = [...brandFilters, value];
                  setQuery({ orders_oem: tempArr }, "replaceIn");
                }}
                onDeselect={(value: string) => {
                  const brandFilter = value;

                  const tempArr = [...brandFilters];

                  const index = tempArr.indexOf(brandFilter);
                  if (index > -1) {
                    tempArr.splice(index, 1);
                  }
                  setQuery({ orders_oem: tempArr }, "replaceIn");
                }}
              >
                {brandRecords.map(brand => {
                  return (
                    <Select.Option
                      className="oem-order-options"
                      key={`${brand.oemName}-key-${brand.key}`}
                      value={brand.oemName}
                    >
                      {brand.oemName}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            <div className="title">Filter By Creator</div>
            <div style={{ padding: "1em" }}>
              <Select
                data-cy="creator-filter-select"
                className="creator-order-input"
                style={{ width: "100%" }}
                placeholder="Select a Creator"
                defaultValue={creatorFilters}
                allowClear={true}
                onClear={() => {
                  setQuery({ orders_creator: undefined }, "replaceIn");
                }}
                mode="multiple"
                onSelect={(value: string) => {
                  const creatorFilter = value;

                  const tempArr = [...creatorFilters, creatorFilter];

                  setQuery({ orders_creator: tempArr }, "replaceIn");
                }}
                onDeselect={(value: string) => {
                  const creatorFilter = value;

                  const tempArr = [...creatorFilters];

                  const index = tempArr.indexOf(creatorFilter);
                  if (index > -1) {
                    tempArr.splice(index, 1);
                  }

                  setQuery({ orders_creator: tempArr }, "replaceIn");
                }}
              >
                {creators.map((creator: string, index: number) => {
                  return (
                    <Select.Option
                      value={creator}
                      key={`${creator}-key-${index}`}
                    >
                      {creator}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          </div>
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};

export default FilterSectionOrders;
