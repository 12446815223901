import {
  CheckCircleTwoTone,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  LinkOutlined,
  PauseCircleOutlined,
  PauseCircleTwoTone,
  PlayCircleOutlined,
  PlayCircleTwoTone,
} from "@ant-design/icons";

import {
  Modal,
  Row,
  Col,
  Button,
  Table,
  Layout,
  Input,
  Spin,
  DatePicker,
  Tooltip,
} from "antd";

import { FC, useCallback, useEffect, useState } from "react";

import moment from "moment";

import { ColumnProps } from "antd/es/table";

import {
  ILauncherData,
  IWebIntegrationInstanceObject,
  IWebIntegrations,
  IStatusModalLauncherData,
} from "shared/types/assetBuilder";

import { INewOrder, INewOrderRecord } from "shared/types/newOrders";
import { commitOrder } from "redux/assetBuilder/assetBuilder.slice";
import API from "services";

interface IAssetLauncherIntegrationStatusModal {
  webIntegrations: IWebIntegrations[];
  openStatusModal?: boolean;
  toggleStatusModal?: () => void;
  createWebIntegration: (webIntegrationData: ILauncherData) => void;
  deleteWebIntegration: (webIntegrationData: ILauncherData) => void;
  getWebIntegrations: (domain: string) => void;
  createWebIntegrationResult: IWebIntegrationInstanceObject;
  commitOrder: () => Promise<void>;
  updateNewOrder: (updateNewOrder: Partial<INewOrderRecord>) => Promise<void>;
  savedOrder: INewOrder | null;
}

export const AssetLauncherIntegrationStatusModal: FC<IAssetLauncherIntegrationStatusModal> =
  ({
    webIntegrations,
    openStatusModal,
    toggleStatusModal,
    deleteWebIntegration,
    getWebIntegrations,
    updateNewOrder,
    savedOrder,
  }) => {
    const convertDate = (epochStamp: number) => {
      return moment.utc(epochStamp).local().format("MM/DD/YY h:mm A");
    };

    const [statusModalData, setStatusModalData] = useState<
      IStatusModalLauncherData[]
    >([]);

    const [canEditLabel, toggleCanEditLabel] = useState<boolean>(false);

    const [canEditStartDate, toggleCanEditStartDate] = useState<boolean>(false);
    const [canEditEndDate, toggleCanEditEndDate] = useState<boolean>(false);

    const [activeRow, setActiveRow] = useState<string>("");
    const [activeCol, setActiveCol] = useState<number | null>();

    const [currLabelToEdit, setCurrLabelToEdit] = useState<string | null>(null);

    const [currStartDateVal, setCurrStartDateVal] = useState<string | null>(
      null,
    );
    const [currEndDateVal, setCurrEndDateVal] = useState<string | null>(null);

    const [isAttributeUpdating, toggleIsAttributeUpdating] =
      useState<boolean>(false);

    useEffect(() => {
      toggleIsAttributeUpdating(false);
      setActiveCol(null);
    }, []);

    useEffect(() => {
      if (webIntegrations.length > 0) {
        let integrationCount = 0;
        const updatedLauncherModalData = webIntegrations.map(integration => {
          if (integration.instanceStatus === "LIVE") {
            integrationCount++;
          }
          const updatedLauncherData: IStatusModalLauncherData = {
            ...integration,
            integrationStatus: integration.integrationStatus ? true : false,
            images: JSON.parse(integration.images),
            instanceStatus: integration.instanceStatus,
            lastModifiedDate: integration.lastModifiedDate,
            launchLabel: integration.launchLabel ? integration.launchLabel : "",
            startDate: integration.startDate ? integration.startDate : 0,
            endDate: integration.endDate ? integration.endDate : 0,
            manualPause: integration.manualPause
              ? integration.manualPause
              : false,
          };
          return {
            ...updatedLauncherData,
          };
        });

        // note that this update is not with the offer updates in the save button/some other places
        // because this request is only made on this page so the web integrations are in dedux after going to the page
        if (savedOrder && openStatusModal) {
          const updatedOrder: Partial<INewOrderRecord> = {
            id: savedOrder.id,
            key: 0,
            actions: "ok",
            integrationCount: integrationCount || savedOrder.integrationCount,
          };
          updateNewOrder(updatedOrder).then(() => {
            commitOrder();
          });
        }
        setStatusModalData(updatedLauncherModalData);
      }

      // Need this to stop Spin if a label has been edited
      toggleIsAttributeUpdating(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [webIntegrations, openStatusModal]);

    const updateWebInt = useCallback(
      async (webInt: ILauncherData) => {
        toggleIsAttributeUpdating(true);
        await API.services.webInt.updateWebInt(webInt);
        getWebIntegrations(webInt.domain);
        toggleIsAttributeUpdating(false);
      },
      [getWebIntegrations],
    );

    const columnData: Array<{
      key: keyof IStatusModalLauncherData;
      title: string;
    }> = [
      {
        key: "integrationStatus",
        title: "Status",
      },
      {
        key: "instanceStatus",
        title: "Live",
      },
      {
        key: "createdDate",
        title: "Created",
      },
      {
        key: "lastModifiedDate",
        title: "Updated",
      },
      {
        key: "launchLabel",
        title: "Label",
      },
      {
        key: "relativePath",
        title: "Path",
      },
      {
        key: "type",
        title: "Type",
      },
      {
        key: "images", // use the length of array for number of assets
        title: "# Assets",
      },
      {
        key: "startDate",
        title: "Start Date",
      },
      {
        key: "endDate",
        title: "End Date",
      },
    ];

    const generateTableColumns = (
      cData: Array<{
        key: keyof IStatusModalLauncherData;
        title: string;
      }>,
    ): Array<ColumnProps<IStatusModalLauncherData>> => {
      return cData.map((data, colIdx) => {
        const { key, title } = data;

        if (key === "integrationStatus") {
          return {
            key,
            title,
            dataIndex: key,
            width: "2%",
            // eslint-disable-next-line
            render: (integrationStatus: boolean) => {
              return integrationStatus ? (
                <PlayCircleTwoTone
                  twoToneColor="#33cc33"
                  style={{ fontSize: "2em" }}
                />
              ) : (
                <PauseCircleTwoTone
                  twoToneColor="#e6e600"
                  style={{ fontSize: "2em" }}
                />
              );
            },
            sorter: (
              a: IStatusModalLauncherData,
              b: IStatusModalLauncherData,
            ) => {
              if (a[key] < b[key]) {
                return -1;
              } else if (a[key] > b[key]) {
                return 1;
              } else {
                return 0;
              }
            },
          };
        }

        if (key === "instanceStatus") {
          return {
            key,
            title,
            dataIndex: key,
            width: "2%",
            // eslint-disable-next-line
            render: (instanceStatus: string) => {
              return instanceStatus === "LIVE" ? (
                <CheckCircleTwoTone
                  twoToneColor="#33cc33"
                  style={{ fontSize: "2em" }}
                />
              ) : (
                <CheckCircleTwoTone
                  twoToneColor="#e6e600"
                  style={{ fontSize: "2em" }}
                />
              );
            },
            sorter: (
              a: IStatusModalLauncherData,
              b: IStatusModalLauncherData,
            ) => {
              if (a[key] < b[key]) {
                return -1;
              } else if (a[key] > b[key]) {
                return 1;
              } else {
                return 0;
              }
            },
          };
        }

        if (key === "launchLabel") {
          return {
            key,
            title,
            dataIndex: key,
            width: "5%",
            // eslint-disable-next-line react/display-name
            render: (label: string, row, index) => {
              return (
                <Spin
                  spinning={
                    isAttributeUpdating &&
                    index === parseInt(activeRow) &&
                    activeCol === 4
                  }
                  style={{ left: "-90px" }}
                >
                  {canEditLabel && index === parseInt(activeRow) ? (
                    <div style={{ display: "flex" }} key={`${index}-key`}>
                      <div style={{ paddingRight: "10px" }}>
                        <CheckOutlined
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (currLabelToEdit !== label) {
                              // need to add logic to be able to save a label
                              const webIntegration = statusModalData.find(
                                webInt => webInt.instanceID === row.instanceID,
                              );

                              if (!webIntegration) return;

                              updateWebInt({
                                ...webIntegration,
                                launchLabel: currLabelToEdit || "",
                              });
                            }
                            toggleCanEditLabel(false);
                            setCurrLabelToEdit(null);
                            setActiveCol(colIdx);
                          }}
                        ></CheckOutlined>
                        <CloseOutlined
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setCurrLabelToEdit(null);
                            toggleCanEditLabel(false);
                            setActiveCol(null);
                          }}
                        ></CloseOutlined>
                      </div>
                      <Input
                        onChange={event => {
                          setCurrLabelToEdit(event.target.value);
                        }}
                        value={currLabelToEdit || ""}
                      />
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <div>
                        <EditOutlined
                          style={{ cursor: "pointer", paddingRight: "10px" }}
                          onClick={() => {
                            toggleIsAttributeUpdating(false);
                            setCurrLabelToEdit(label);
                            toggleCanEditLabel(true);

                            // can only edit one attribute at a time
                            toggleCanEditStartDate(false);
                            toggleCanEditEndDate(false);
                          }}
                        ></EditOutlined>
                      </div>
                      <div>{label}</div>
                    </div>
                  )}
                </Spin>
              );
            },
          };
        }

        if (key === "startDate" || key === "endDate") {
          return {
            key,
            title,
            dataIndex: key,
            width: "4%",
            // eslint-disable-next-line react/display-name
            render: (date: number, row, index) => {
              return (
                <Spin
                  spinning={
                    isAttributeUpdating &&
                    activeCol === (key === "startDate" ? 8 : 9) &&
                    index === parseInt(activeRow)
                  }
                  style={{ left: "-40px" }}
                >
                  {(key === "startDate" ? canEditStartDate : canEditEndDate) &&
                  index === parseInt(activeRow) ? (
                    <div style={{ display: "flex" }} key={`${index}-key`}>
                      <div style={{ paddingRight: "10px" }}>
                        <CheckOutlined
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (
                              (key === "startDate"
                                ? currStartDateVal
                                : currEndDateVal) ||
                              "-1" !== convertDate(date)
                            ) {
                              // if start or end date has been changed
                              const webIntegration = statusModalData.find(
                                webInt => webInt.instanceID === row.instanceID,
                              );

                              if (!webIntegration) return;

                              if (key === "startDate") {
                                updateWebInt({
                                  ...webIntegration,
                                  startDate: currStartDateVal
                                    ? Date.parse(currStartDateVal)
                                    : null,
                                });
                              } else {
                                updateWebInt({
                                  ...webIntegration,
                                  endDate: currEndDateVal
                                    ? Date.parse(currEndDateVal)
                                    : null,
                                });
                              }
                            }
                            setCurrStartDateVal(null);
                            toggleCanEditStartDate(false);
                            setCurrEndDateVal(null);
                            toggleCanEditEndDate(false);
                          }}
                        ></CheckOutlined>
                        <CloseOutlined
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (key === "startDate") {
                              setCurrStartDateVal(null);
                              toggleCanEditStartDate(false);
                            } else {
                              setCurrEndDateVal(null);
                              toggleCanEditEndDate(false);
                            }

                            setActiveCol(null);
                          }}
                        ></CloseOutlined>
                      </div>
                      <div>
                        <DatePicker
                          showTime={{
                            defaultValue: moment("00:00:00", "HH:mm:ss"),
                          }}
                          allowClear={true}
                          onChange={(value, date) => {
                            const parsedDate: number = Date.parse(date);
                            if (key === "startDate") {
                              setCurrStartDateVal(
                                date ? convertDate(parsedDate) : null,
                              );
                            } else {
                              setCurrEndDateVal(
                                date ? convertDate(parsedDate) : null,
                              );
                            }
                          }}
                          value={
                            (
                              key === "startDate"
                                ? currStartDateVal
                                : currEndDateVal
                            )
                              ? moment(
                                  `${
                                    key === "startDate"
                                      ? currStartDateVal
                                      : currEndDateVal
                                  }`,
                                  "MM/DD/YY HH:mm",
                                )
                              : null
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <EditOutlined
                        style={{ cursor: "pointer", paddingRight: "10px" }}
                        onClick={() => {
                          if (key === "startDate") {
                            toggleCanEditStartDate(true);

                            // only one attribute can be edited at a time
                            toggleCanEditEndDate(false);
                            toggleCanEditLabel(false);

                            if (date) {
                              setCurrStartDateVal(convertDate(date));
                            } else {
                              setCurrStartDateVal(convertDate(Date.now()));
                            }
                          } else {
                            toggleCanEditEndDate(true);

                            // only one attribute can be edited at a time
                            toggleCanEditStartDate(false);
                            toggleCanEditLabel(false);

                            if (date) {
                              setCurrEndDateVal(convertDate(date));
                            } else {
                              setCurrEndDateVal(convertDate(Date.now()));
                            }
                          }

                          setActiveCol(colIdx);
                          toggleIsAttributeUpdating(false);
                        }}
                      ></EditOutlined>
                      <div>{date === 0 ? "" : convertDate(date)}</div>
                    </div>
                  )}
                </Spin>
              );
            },
          };
        }

        if (key === "images") {
          return {
            key,
            title,
            dataIndex: key,
            width: "3%",
            // eslint-disable-next-line
            render: images => {
              return images.length;
            },
            sorter: (
              a: IStatusModalLauncherData,
              b: IStatusModalLauncherData,
            ) => {
              if (a[key].length < b[key].length) {
                return -1;
              } else if (a[key] > b[key]) {
                return 1;
              } else {
                return 0;
              }
            },
          };
        }

        if (key === "createdDate" || key === "lastModifiedDate") {
          return {
            key,
            title,
            dataIndex: key,
            width: "3%",
            // eslint-disable-next-line
            render: (date: number) => {
              if (date === 0) {
                return "";
              }
              return convertDate(date);
            },
            sorter: (
              a: IStatusModalLauncherData,
              b: IStatusModalLauncherData,
            ) => {
              if (a[key] < b[key]) {
                return -1;
              } else if (a[key] > b[key]) {
                return 1;
              } else {
                return 0;
              }
            },
          };
        }
        return {
          key,
          title,
          dataIndex: key,
          width: "3%",
          sorter: (
            a: IStatusModalLauncherData,
            b: IStatusModalLauncherData,
          ) => {
            if (!a[key] || !b[key]) {
              return 0;
            }
            if (a[key] === null) {
              return 1;
            }
            if (b[key] === null) {
              return -1;
            }
            if (a[key] < b[key]) {
              return -1;
            } else if (a[key] > b[key]) {
              return 1;
            } else {
              return 0;
            }
          },
        };
      });
    };

    const actionColumns = ["View", "Live/Pause", "Delete"];
    const generateTableActionColumns = (
      aColumns: string[],
    ): Array<ColumnProps<IStatusModalLauncherData>> => {
      return aColumns.map(column => {
        const columnObject: {
          title: string;
          dataIndex: string;
          fixed: "right";
          width: string;
        } = {
          title: "",
          dataIndex: `actions ${column}`,
          fixed: "right",
          width: "1%",
        };

        switch (column) {
          case "View":
            return {
              ...columnObject,
              // eslint-disable-next-line
              render: (text: string, data: IStatusModalLauncherData) => {
                return (
                  <Tooltip title={"VIEW"}>
                    <Button
                      style={{
                        pointerEvents: "auto",
                      }}
                      type={"primary"}
                      key="VIEW"
                      icon={<LinkOutlined />}
                      onClick={e => {
                        e.preventDefault();

                        const domain = data.domain.startsWith("https://")
                          ? `${data.domain}${data.relativePath}` // relative path already includes '/'
                          : `https://${data.domain}${data.relativePath}`;

                        window.open(domain, "_blank");
                      }}
                    ></Button>
                  </Tooltip>
                );
              },
            };
          case "Live/Pause":
            return {
              ...columnObject,
              // eslint-disable-next-line
              render: (text: string, data: IStatusModalLauncherData, index) => {
                const updatedLauncherData: ILauncherData = {
                  ...data,
                  integrationStatus: data.integrationStatus ? true : false,
                  images: data.images,
                  instanceStatus: data.instanceStatus,
                  lastModifiedDate: Date.now(),
                  startDate: data.startDate === 0 ? null : data.startDate,
                  endDate: data.endDate === 0 ? null : data.endDate,
                };
                const { integrationStatus } = updatedLauncherData;

                return (
                  <Spin
                    spinning={
                      isAttributeUpdating &&
                      index === parseInt(activeRow) &&
                      activeCol === 11
                    }
                  >
                    <Tooltip title={integrationStatus ? "PAUSE" : "LIVE"}>
                      <Button
                        type={data.integrationStatus ? "default" : "primary"}
                        key={`${integrationStatus ? "PAUSE" : "LIVE"}-${
                          data.instanceID
                        }`}
                        icon={
                          integrationStatus ? (
                            <PauseCircleOutlined />
                          ) : (
                            <PlayCircleOutlined />
                          )
                        }
                        onClick={() => {
                          if (integrationStatus) {
                            updateWebInt({
                              ...updatedLauncherData,
                              integrationStatus: false,
                              instanceStatus: "PAUSED",
                              manualPause: true,
                            });
                          } else {
                            updateWebInt({
                              ...updatedLauncherData,
                              integrationStatus: true,
                              instanceStatus: "LIVE",
                              manualPause: false,
                            });
                          }
                          setActiveCol(11);
                        }}
                      ></Button>
                    </Tooltip>
                  </Spin>
                );
              },
            };
          case "Delete":
            return {
              ...columnObject,
              // eslint-disable-next-line
              render: (_: string, data: IStatusModalLauncherData, index) => {
                const updatedLauncherData: ILauncherData = {
                  ...data,
                  integrationStatus: data.integrationStatus ? true : false,
                  images: data.images,
                  instanceStatus: data.instanceStatus,
                  lastModifiedDate: Date.now(),
                  startDate: data.startDate === 0 ? null : data.startDate,
                  endDate: data.endDate === 0 ? null : data.endDate,
                };
                return (
                  <Spin
                    spinning={
                      isAttributeUpdating &&
                      index === parseInt(activeRow) &&
                      activeCol === 12
                    }
                    style={{ left: "5px" }}
                  >
                    <Tooltip title={"DELETE"}>
                      <Button
                        type={data.integrationStatus ? "primary" : "default"}
                        key="DELETE"
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          setActiveCol(12);
                          const toDeleteLauncherData: ILauncherData = {
                            ...updatedLauncherData,
                          };
                          deleteWebIntegration({
                            ...toDeleteLauncherData,
                          });
                        }}
                      ></Button>
                    </Tooltip>
                  </Spin>
                );
              },
            };
          default:
            return {
              ...columnObject,
            };
        }
      });
    };

    return (
      <>
        {openStatusModal && (
          <Modal
            className={
              webIntegrations?.length
                ? "asset-launcher-web-integration-status-modal"
                : ""
            }
            title="Web Integration Status"
            width="1000px"
            visible={true}
            onCancel={() => {
              toggleStatusModal && toggleStatusModal();
            }}
            footer={[
              <Button
                data-cy="close-btn-web-integration-status"
                key="CLOSE"
                onClick={() => {
                  toggleStatusModal && toggleStatusModal();
                }}
              >
                Close
              </Button>,
            ]}
          >
            {webIntegrations?.length && statusModalData?.length ? (
              <Layout>
                <Layout.Content style={{ padding: "1em" }}>
                  <Row>
                    <Col>
                      <Table<IStatusModalLauncherData>
                        data-cy="table-int-status-modal"
                        scroll={{ x: 2000, y: 550 }}
                        pagination={false}
                        className="data-management-table"
                        columns={[
                          ...generateTableColumns(columnData),
                          ...generateTableActionColumns(actionColumns),
                        ]}
                        dataSource={statusModalData}
                        style={{ width: "100%" }}
                        onRow={(_, index) => {
                          return {
                            onClick: () => {
                              setActiveRow(index?.toString() || "");
                            },
                          };
                        }}
                      />
                    </Col>
                  </Row>
                </Layout.Content>
              </Layout>
            ) : (
              <div>There were no integrations found for this domain</div>
            )}
          </Modal>
        )}
      </>
    );
  };
