import { UserOutlined } from "@ant-design/icons";
import { Alert, Button } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryParam, StringParam } from "use-query-params";
import { Loading } from "../Loading";
import { createPasswordToken, getLoginUrl } from "../auth";
import { config } from "../config";
import { useMediaStorage } from "../useMediaStorage";

import styles from "./MediaSignIn.module.scss";

export const MediaSignIn = () => {
  const [state] = useQueryParam("state", StringParam);
  const [, setData] = useMediaStorage();
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!config.standalone) {
      createPasswordToken()
        .then(data => {
          setData(data);
          navigate(state ?? "/");
        })
        .catch(() => {
          setData(null);
          setError(true);
        });
    }
  }, [navigate, setData, state]);

  return config.standalone ? (
    <div className={styles.host}>
      <div>
        <div className={styles.icon}>
          <UserOutlined />
        </div>
        <div>To continue please login to WebDAM</div>
        <Button type="primary" href={getLoginUrl()}>
          Login to Webdam
        </Button>
      </div>
    </div>
  ) : (
    <div className={styles.feedback}>
      {error ? (
        <div>
          <Alert type="error" showIcon message="WD Login Failed" />
          <br />
          <Button type="primary" onClick={() => location.reload()}>
            Reload
          </Button>
        </div>
      ) : (
        <div>
          <Loading />
          <p>WD SignIn</p>
        </div>
      )}
    </div>
  );
};
