import { InfoCircleFilled } from "@ant-design/icons";
import { Tooltip, Button } from "antd";
import { buttonColors } from "screens/adLibrary/adLoad/adLoadDrawer/shared/constants";

interface IProps {
  displayIssues: boolean;
  toggleDisplayIssues: () => void;
  numberOfErrors: number;
}

const buttonSelectedColor = "rgba(9, 109, 217, 1)";

const ErrorsToggle = ({
  displayIssues,
  toggleDisplayIssues,
  numberOfErrors,
}: IProps) => (
  <Tooltip title="Click to view all errors">
    <Button
      onClick={() => toggleDisplayIssues()}
      icon={
        <InfoCircleFilled
          style={{
            color: displayIssues ? buttonSelectedColor : buttonColors.error,
          }}
        />
      }
      style={{
        marginRight: 8,
        ...(displayIssues
          ? {
              borderColor: buttonSelectedColor,
              color: buttonSelectedColor,
            }
          : {}),
      }}
    >
      {numberOfErrors} Errors
    </Button>
  </Tooltip>
);

export default ErrorsToggle;
