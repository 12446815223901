import { Badge } from "antd";
import {
  AdAccountWithDealerName,
  IAssetGroup,
  NameID,
} from "shared/types/adLibrary";
import DateCell from "../../../shared/components/DateCell";
import { SearchOutlined } from "@ant-design/icons";
import usePmaxAssetGroupFilters from "./usePmaxAssetGroupFilters";
import FilterDropdown from "../FilterDropdown";
import { ITableColumn } from "shared/components/dataList";
import { getBadgeStatusProp } from "../../PmaxAssetGroupList.utils";
import NameTag from "screens/adLibrary/shared/components/NameTag";
import { usePMaxDataContext } from "shared/components/contextAPI/pmaxAssetGroup/PMaxDataProvider";
import styles from "../../PMaxAssetGroupList.module.scss";
import AdAccountList from "../AdAccountList";
import { ThumbnailCell } from "shared/components/ThumbnailCell";
import { getThumbnailImage, pmaxTooltipProps } from "../../utils";
import AutoEllipsisText from "shared/components/AutoEllipsisText";

const CellWithElipsisDefault = ({ text }: { text: string }) => (
  <AutoEllipsisText tooltipProps={pmaxTooltipProps}>
    {text || "-"}
  </AutoEllipsisText>
);

const usePmaxAssetGroupColumns = (
  assetGroups: IAssetGroup[] | undefined,
  includeOnly?: string[],
): ITableColumn<IAssetGroup>[] => {
  const { filterValues, filterAssetGroupsBy, sortAssetGroupsBy } =
    usePmaxAssetGroupFilters(assetGroups);

  const { setEditAssetGroup, filteredInfo } = usePMaxDataContext();

  const columns: ITableColumn<IAssetGroup>[] = [
    {
      key: "name",
      title: "Asset Group Name",
      dataIndex: ["name"],
      width: 300,
      render: (name: string, assetGroup: IAssetGroup) => (
        <ThumbnailCell
          name={name}
          image={getThumbnailImage(assetGroup)}
          onClick={() => {
            setEditAssetGroup(assetGroup, "update");
          }}
        />
      ),
      sorter: sortAssetGroupsBy("name"),
      filterDropdown: props => {
        return <FilterDropdown {...props} />;
      },
      filterIcon: filtered => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: filterAssetGroupsBy("name"),
      filteredValue: filteredInfo.name,
    },
    {
      key: "id",
      title: "Unique ID",
      dataIndex: ["id"],
      width: 200,
      sorter: sortAssetGroupsBy("id"),
      filterDropdown: props => {
        return <FilterDropdown {...props} />;
      },
      filterIcon: filtered => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: filterAssetGroupsBy("id"),
      render: (text: string) => <CellWithElipsisDefault text={text} />,
      filteredValue: filteredInfo.id,
    },
    {
      key: "feedName",
      title: "Feed name",
      dataIndex: ["feedName"],
      width: 250,
      render: (value: string) => <CellWithElipsisDefault text={value} />,
      sorter: sortAssetGroupsBy("feedName"),
      filters: filterValues["feedName"],
      onFilter: filterAssetGroupsBy("feedName"),
      filteredValue: filteredInfo.feedName,
    },
    {
      key: "feedStatus",
      title: "Feed status",
      dataIndex: ["feedStatus"],
      width: 150,
      render: value => (
        <div className={styles.statusContainer}>
          <Badge color={getBadgeStatusProp(value)} />
          <span>
            <CellWithElipsisDefault text={value as string} />
          </span>
        </div>
      ),
      sorter: sortAssetGroupsBy("feedStatus"),
      filters: filterValues["feedStatus"],
      onFilter: filterAssetGroupsBy("feedStatus"),
      filteredValue: filteredInfo.feedStatus,
    },
    {
      key: "pmaxStatus",
      title: "PMax Status",
      dataIndex: ["pmaxStatus"],
      width: 150,
      render: value => (
        <div className={styles.statusContainer}>
          <Badge color={getBadgeStatusProp(value)} />
          <span>
            <CellWithElipsisDefault text={value as string} />
          </span>
        </div>
      ),
      sorter: sortAssetGroupsBy("pmaxStatus"),
      filters: filterValues["pmaxStatus"],
      onFilter: filterAssetGroupsBy("pmaxStatus"),
      filteredValue: filteredInfo.pmaxStatus,
    },
    {
      key: "adAccount",
      title: "Account",
      dataIndex: ["adAccounts"],
      filters: filterValues["adAccounts"],
      onFilter: filterAssetGroupsBy("adAccounts"),
      filteredValue: filteredInfo.adAccount,
      render: (adAccounts: AdAccountWithDealerName[]) => (
        <AdAccountList adAccounts={adAccounts} />
      ),
      width: 250,
    },
    {
      key: "campaign",
      title: "Campaign",
      dataIndex: ["campaigns"],
      filters: filterValues["campaigns"],
      onFilter: filterAssetGroupsBy("campaigns"),
      filteredValue: filteredInfo.campaign,
      render: (campaigns: NameID[]) =>
        campaigns?.map((campaign: NameID) => (
          <NameTag
            id={campaign.id}
            name={campaign.name}
            key={campaign.id}
            tooltipProps={pmaxTooltipProps}
          />
        )),
      width: 250,
    },
    {
      key: "createdAt",
      title: "Created",
      dataIndex: ["createdAt"],
      width: 250,
      render: DateCell,
      sorter: sortAssetGroupsBy("createdAt"),
      defaultSortOrder: "descend",
    },
    {
      key: "lastUpdatedAt",
      title: "Last Updated",
      dataIndex: ["lastUpdatedAt"],
      width: 250,
      render: DateCell,
      sorter: sortAssetGroupsBy("lastUpdatedAt"),
    },
  ];

  return columns
    .map(column => ({
      ...column,
      showSorterTooltip: false,
    }))
    .filter(column => (includeOnly ? includeOnly.includes(column.key) : true));
};

export default usePmaxAssetGroupColumns;
