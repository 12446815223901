import { FilterValue } from "antd/lib/table/interface";
import { isValid, parse } from "date-fns";
import { uniq } from "lodash";
import {
  FiltersType,
  TemplateListColumn,
} from "screens/assetExporter/feedConfigurationV2/shared/types";
import { ITemplate, TemplateStatusType } from "shared/types/designStudio";
import { formatDimensions } from "utils/helpers";

export const toDimensionStr = (template: ITemplate) => {
  const { artboard } = template;
  if (!artboard) return;

  return formatDimensions(artboard);
};

export const filterByDateRange = (
  dateToCompare: number | null,
  dateRangeStr: string,
): boolean => {
  const formatStr = "yyyy-MM-dd";
  const [start, end] = dateRangeStr.split(",");
  const startDate = parse(start, formatStr, new Date());
  const endDate = parse(end, formatStr, new Date());
  if (!isValid(startDate) || !isValid(endDate)) return false;

  if (!dateToCompare) return false; // unable to compare. Return false by default.

  return (
    startDate.getTime() <= dateToCompare && dateToCompare <= endDate.getTime()
  );
};

export const applySearchBy = (template: ITemplate, searchBy?: string) =>
  !searchBy
    ? true
    : template.name.toLowerCase().includes(searchBy.toLowerCase());
export const applyFilters = (
  template: ITemplate,
  filters: Record<string, FilterValue | null>,
) => {
  return Object.keys(filters).every(key => {
    const filterValue = filters[key];
    if (!filterValue) return true;

    switch (key) {
      case "name":
        const [value] = filterValue;
        return template.name
          .toLowerCase()
          .includes((value as string).toLowerCase());
      case "status":
        return filterValue.includes(template.status);
      case "assetType":
        return filterValue.includes(template.assetType);
      case "dimension":
        const dimStr = toDimensionStr(template);
        return dimStr ? filterValue.includes(dimStr) : false;
      case "lastEdited":
        const [dateRangeStr = ""] = filterValue;
        return filterByDateRange(
          template.lastUpdatedAt,
          dateRangeStr as string,
        );
      case "tags":
        return template.tags?.some(tag => filterValue.includes(tag));
      case "oems":
        return template.oems?.some(oem => filterValue.includes(oem));
      case "type":
        return filterValue.includes(template.type);
      case "stores":
        return template.stores?.some(store => filterValue.includes(store));
      case "artboardName":
        return filterValue.includes(template.artboard.name);
      case "createdAt":
        const [createdAtDateRangeStr = ""] = filterValue;
        return filterByDateRange(
          template.createdAt,
          createdAtDateRangeStr as string,
        );
      case "stamps":
        return filterValue.includes(template.numOfStamps);
      default:
        return true;
    }
  });
};

export const filter = (templates: ITemplate[], searchBy: any, filters: any) => {
  return templates.filter(t => {
    return applySearchBy(t, searchBy) && applyFilters(t, filters);
  });
};

export type TFilterOption = Record<
  Exclude<TemplateListColumn, "lastUpdatedAt" | "createdAt" | "status">,
  string[]
> &
  Record<"status", TemplateStatusType[]>;

export const getFilterOptions = (templates: ITemplate[]): TFilterOption => ({
  name: uniq(templates.map(template => template.name)),
  assetType: uniq(templates.map(template => template.assetType)),
  tags: uniq(templates.flatMap(template => template.tags).filter(tag => tag)),
  status: ["PUBLISHED", "UN-PUBLISHED"],
  type: uniq(
    templates.filter(template => template.type).map(template => template.type),
  ),
  dimension: uniq(
    templates
      .filter(template => template.artboard)
      .map(({ artboard }) => `${artboard.width} x ${artboard.height}`),
  ),
  stores: uniq(templates.flatMap(template => template.stores)),
  oems: uniq(templates.flatMap(template => template.oems)),
  artboardName: uniq(templates.map(template => template.artboard.name)),
  stamps: uniq(templates.map(template => template.numOfStamps?.toString())),
});

export const disable = (
  template: ITemplate,
  dataFilter: Partial<FiltersType>,
) =>
  template.numOfStamps > 0 ||
  template.status === "UN-PUBLISHED" ||
  !applyFilters(template, dataFilter);
