import { REDIRECT_URI } from "../constants";
import { fetchToken } from "./fetchToken";

let promise: Promise<MGTokenObj> | null = null;

export const createAuthToken = (code: string) => {
  if (promise === null) {
    promise = fetchToken({
      grant_type: "authorization_code",
      redirect_uri: REDIRECT_URI,
      code,
    });

    promise.finally(() => {
      promise = null;
    });
  }

  return promise;
};
