/* eslint-disable react/display-name */
import { SearchOutlined } from "@ant-design/icons";

import { startCase } from "lodash";
import { SearchFilterDropdown } from "shared/components/SearchFilterDropdown";
import { AdLoadAdStatus, SessionLoadStatus } from "shared/types/adLibrary";
import AdLoadAdStatusBadge from "../adLoad/adLoadDrawer/shared/components/adsToLoadTable/AdLoadAdStatusBadge";
import DateCell from "../shared/components/DateCell";
import SessionHistoryModal from "./SessionHistoryModal";
import OnNameRender from "./sessionsTable/OnNameRender";
import {
  AdLoadSessionTableColumn,
  AdLoadSessionTableEntry,
  SessionsTableProps,
} from "./types";

import DataListURLTable from "shared/components/dataListURL/DataListURLTable";
import { useWindowSize } from "shared/hooks/useWindowSize";
import styles from "./SessionsTable.module.scss";
import { adSessionFieldKeys, IAdSessionFields } from "./sessionsTable/fields";

const sessionLoadStatusMap: Record<SessionLoadStatus, AdLoadAdStatus> = {
  failed: "error",
  unknown: "idle",
  loaded: "success",
  "not loaded": "draft",
};

const SessionsTable = ({
  data,
  loading,
  searchValue,
  currentSessionId,
  currentEditSessionId,
  onModifySession,
  onCurrentSessionIdChange,
  onCurrentEditSessionIdChange,
}: SessionsTableProps) => {
  const columns: AdLoadSessionTableColumn[] = [
    {
      key: "loadStatus",
      width: 130,
      title: "Load Status",
      dataIndex: "loadStatus",
      filters: Object.keys(SessionLoadStatus).map(status => ({
        text: startCase(status),
        value: status,
      })),
      render: (_, record) => (
        <AdLoadAdStatusBadge
          status={sessionLoadStatusMap[record.loadStatus ?? "unknown"]}
          description={startCase(record.loadStatus ?? "unknown")}
        />
      ),
    },
    {
      width: 300,
      title: "Session Name",
      dataIndex: "name",
      key: "name",
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      render: (value, record) => (
        <OnNameRender
          record={record}
          value={value}
          searchValue={searchValue}
          currentEditSessionId={currentEditSessionId}
          onModifySession={onModifySession}
          onCurrentEditSessionIdChange={onCurrentEditSessionIdChange}
        />
      ),
    },
    {
      width: 180,
      title: "Last Updated At",
      dataIndex: "lastUpdatedAt",
      key: "lastUpdatedAt",
      render: DateCell,
    },
    {
      width: 300,
      title: "Last Updated By",
      dataIndex: "lastUpdatedBy",
      key: "lastUpdatedBy",
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
    },
    {
      width: 180,
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: DateCell,
    },
    {
      width: 300,
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
    },
  ];

  const { windowInnerHeight } = useWindowSize();

  return (
    <>
      {currentSessionId && (
        <SessionHistoryModal
          sessionId={currentSessionId}
          onClose={() => {
            onCurrentSessionIdChange?.("");
          }}
        />
      )}
      <DataListURLTable<IAdSessionFields, AdLoadSessionTableEntry>
        loading={loading}
        dataSource={data}
        columns={columns}
        pagination={false}
        size="small"
        scroll={{ y: windowInnerHeight - 240 }}
        onHeaderRow={() => ({ className: styles.sessionsTableHeader })}
        type="virtual"
        fieldKeys={adSessionFieldKeys}
      />
    </>
  );
};

export default SessionsTable;
