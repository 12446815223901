import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";
import InstantExperienceList from "./Ads/InstantExperienceList";
import { fields } from "./Ads/fields";

const Ads = () => {
  return (
    <DataListURLProvider fields={fields}>
      <InstantExperienceList />
    </DataListURLProvider>
  );
};

export default Ads;
