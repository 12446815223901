import { User } from "redux/auth/auth.slice";
import {
  AssetExporterState,
  FeedConfigState,
  ProcessFeedBody,
  SmartColumn,
} from "shared/types/assetExporter";
import { encryptPassword } from "utils/helpers";

type GetFeedProcessBody = {
  feedConfig: FeedConfigState;
  configure: AssetExporterState["configure"];
  feedId: string;
  user?: User | null;
};
export const getFeedProcessBody = ({
  configure,
  feedConfig,
  feedId,
  user,
}: GetFeedProcessBody): ProcessFeedBody => {
  const { colsToExport, ftpData, feedType } = configure;
  const isFbAds = feedType === "facebookAds";
  const imageInputColumn = isFbAds ? "Media Link" : feedConfig.imageInput;
  const { url: host, password: pwd, ...allFtpData } = ftpData;
  // pwd is unencrypted password
  const password = encryptPassword(pwd);
  return {
    feedId,
    columnsToExport: colsToExport,
    createdBy: user?.email ?? "",
    imageInputColumn,
    imageOutputColumn: feedConfig.imageOutput,
    videoInputColumn: feedConfig.videoInput,
    videoEndInputColumn: feedConfig.videoEndInput,
    videoOutputColumn: feedConfig.videoOutput,
    videoFrameStart: feedConfig.videoFrameStart,
    videoFrameEnd: feedConfig.videoFrameEnd,
    videoFrameRate: feedConfig.videoFrameRate,
    selectedRows: configure.selectedRows?.length ? configure.selectedRows : [],
    ...allFtpData,
    host,
    password,
  };
};

export const isValidFeedBody = (
  body: ProcessFeedBody,
  smartCols: SmartColumn,
) => {
  const { imageInputColumn, imageOutputColumn, videoOutputColumn } = body;
  const hasImgInput = !!imageInputColumn && imageInputColumn.trim() !== "";
  const isValidInputImg = hasImgInput;
  const isValidOutImg = !!imageOutputColumn && imageOutputColumn.trim() !== "";
  const isOutProcess =
    imageOutputColumn === "Process" || videoOutputColumn === "Process";
  const isImgAsSmartCol = Object.keys(smartCols).includes(imageOutputColumn);
  const isVidAsSmartCol = Object.keys(smartCols).includes(videoOutputColumn);
  const isImgValid = isValidInputImg && isValidOutImg && !isImgAsSmartCol;
  return isImgValid && !isVidAsSmartCol && !isOutProcess;
};
