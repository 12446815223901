import { Col, Form, Input, Row, Typography } from "antd";
import { IAccountRecord } from "shared/types/accountManagement";
import styles from "./DetailsTab.module.scss";
import { GenericLocationSelect } from "shared/components/select/GenericLocationSelect";
import { OperationMode } from "shared/types/inputValues";
import { useDetailsAccountValidations } from "shared/hooks/brandsAccountsManagement/useDetailsAccountValidations";
import { formatPhoneNumber, revertPhoneNumber } from "utils/helpers";

interface AddressProps {
  disabled: boolean;
  mode: OperationMode;
  accountToEdit: IAccountRecord;
  setAccountToEdit: (accountToEdit: IAccountRecord) => void;
}

export const Address = ({
  disabled,
  mode,
  accountToEdit,
  setAccountToEdit,
}: AddressProps) => {
  const {
    onlyNumbersRegex,
    handlePhoneNumberChange,
    handlePhoneNumberPaste,
    handlePhoneNumberCopy,
    handlePhoneNumberCut,
  } = useDetailsAccountValidations({
    accountToEdit,
    setAccountToEdit,
    mode,
  });
  return (
    <>
      <Row>
        <Typography.Text className={styles.title}>Address</Typography.Text>
      </Row>
      <div className={styles.paddingContainer}>
        <Form.Item label="Address">
          <Input
            key="account-address-input"
            data-cy="account-address-input"
            value={accountToEdit.dealerAddress}
            disabled={disabled}
            onChange={({ target }) => {
              const { value: addressVal } = target;
              setAccountToEdit({
                ...accountToEdit,
                dealerAddress: addressVal,
              });
            }}
          />
        </Form.Item>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="City">
              <Input
                data-cy="account-city-input"
                key="account-city-input"
                disabled={disabled}
                value={accountToEdit.dealerCity}
                onChange={({ target }) => {
                  const { value: cityValue } = target;
                  setAccountToEdit({
                    ...accountToEdit,
                    dealerCity: cityValue,
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="State/Province">
              <GenericLocationSelect
                disabled={disabled}
                placeholder="select"
                data-cy="account-state-select"
                defaultValue={accountToEdit.dealerUsState}
                value={accountToEdit.dealerUsState}
                onChange={(value: string) => {
                  setAccountToEdit({
                    ...accountToEdit,
                    dealerUsState: value,
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Zip code">
              <Input
                key="account-zip-input"
                data-cy="account-zip-input"
                value={accountToEdit.dealerZip}
                disabled={disabled}
                onChange={({ target }) => {
                  const { value: zipValue } = target;
                  if (zipValue && zipValue.length > 10) {
                    return;
                  }
                  setAccountToEdit({
                    ...accountToEdit,
                    dealerZip: zipValue,
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Phone number">
              <Input
                type="tel"
                maxLength={10}
                key="account-phone-input"
                data-cy="account-phone-input"
                disabled={disabled}
                placeholder="(000) 000-0000"
                value={accountToEdit.dealerPhoneNumber}
                onCut={handlePhoneNumberCut}
                onCopy={handlePhoneNumberCopy}
                onPaste={handlePhoneNumberPaste}
                onBlur={() => {
                  const formattedPhoneNumber = formatPhoneNumber(
                    accountToEdit.dealerPhoneNumber,
                  );
                  handlePhoneNumberChange(formattedPhoneNumber);
                }}
                onFocus={() => {
                  const reverted = revertPhoneNumber(
                    accountToEdit.dealerPhoneNumber,
                  );
                  handlePhoneNumberChange(reverted);
                }}
                onChange={({ target }) => {
                  const { value: phoneNumberVal } = target;
                  // regex for only allowing string like "1234567890" to pass
                  if (
                    phoneNumberVal &&
                    !onlyNumbersRegex.test(phoneNumberVal)
                  ) {
                    return;
                  }
                  handlePhoneNumberChange(phoneNumberVal);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </>
  );
};
