import { Button, Modal, Typography } from "antd";
import { IAccountRecord } from "shared/types/accountManagement";

interface IModalsProps {
  dealerToEditWithLogos: IAccountRecord;
  shouldDeleteModal: boolean;
  IsSaveOrDiscardModalOpenDealerManagement: boolean;
  onDeleteClick: () => void;
  onCancelClick: () => void;
  toggleShouldDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  toggleIsSaveOrDiscardModalOpenDealerManagement: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

export const DealerDrawerModals = ({
  onCancelClick,
  onDeleteClick,
  toggleShouldDeleteModal,
  shouldDeleteModal,
  dealerToEditWithLogos,
  IsSaveOrDiscardModalOpenDealerManagement,
  toggleIsSaveOrDiscardModalOpenDealerManagement,
}: IModalsProps) => (
  <>
    <Modal
      title="Confirm Deletion"
      visible={shouldDeleteModal}
      closable={false}
      footer={[
        <Button
          key="dealer-no-button"
          onClick={() => toggleShouldDeleteModal(false)}
        >
          No
        </Button>,
        <Button
          data-cy="confirm-store-delete-button"
          onClick={() => {
            onDeleteClick();
          }}
          key="dealer-yes-button"
          type={"primary"}
        >
          Yes
        </Button>,
      ]}
    >
      <Typography.Text>
        {`Are you sure you sure you want to delete ${dealerToEditWithLogos.dealerName}?`}
      </Typography.Text>
    </Modal>

    <Modal
      style={{ zIndex: 100 }}
      visible={IsSaveOrDiscardModalOpenDealerManagement}
      closable={false}
      title="Confirm"
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            toggleIsSaveOrDiscardModalOpenDealerManagement(false);
          }}
        >
          Cancel
        </Button>,
        <Button
          key="discard"
          danger
          type="primary"
          onClick={() => {
            onCancelClick();
          }}
        >
          Discard
        </Button>,
      ]}
    >
      <span>Are you sure you want to discard changes?</span>
    </Modal>
  </>
);
