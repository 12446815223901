import { Badge } from "antd";
import { getStatus } from "../../../helpers";
import { BaseDesignStudioItemCardProps } from "../DesignStudioItemCard";
import styles from "../DesignStudioItemCard.module.scss";

export const PublishStatus = ({ item }: BaseDesignStudioItemCardProps) => {
  const status = getStatus(item);
  const badgeStatus = status === "Published" ? "success" : "error";
  return (
    <div className={styles.status}>
      <Badge status={badgeStatus} className={styles.badge} text={status} />
    </div>
  );
};
