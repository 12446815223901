import { IConfig } from "shared/types/configuration";
import {
  INotification,
  INotificationSettings,
} from "shared/types/notification";
import HttpClient from "./httpClient";

export default ({ config }: { config: IConfig }) => ({
  getNotifications: <T>(user: string): Promise<T> => {
    const { services } = config;
    const getNotificationsUrl = `${services.getNotificationsUrl}?user=${user}`;
    return HttpClient.get(getNotificationsUrl);
  },
  getNotificationSettings: <T>(user: string): Promise<T> => {
    const { services } = config;
    const getNotificationSettingsUrl = `${services.getNotificationSettingsUrl}?user=${user}`;
    return HttpClient.get(getNotificationSettingsUrl);
  },
  createOrUpdateNotification: <T>(notification: INotification): Promise<T> => {
    const { services } = config;
    const createOrUpdateNotificationUrl =
      services.createOrUpdateNotificationUrl;
    return HttpClient.post(createOrUpdateNotificationUrl, notification);
  },
  batchCreateNotifications: <T>(notifications: INotification[]): Promise<T> => {
    const { services } = config;
    const batchCreateNotificationsUrl = services.batchCreateNotificationsUrl;
    return HttpClient.post(batchCreateNotificationsUrl, notifications);
  },
  batchUpdateNotifications: <T>(notifications: INotification[]): Promise<T> => {
    const { services } = config;
    const batchUpdateNotificationsUrl = services.batchUpdateNotificationsUrl;
    return HttpClient.put(batchUpdateNotificationsUrl, notifications);
  },
  updateNotificationSettings: <T>(
    notificationSettings: INotificationSettings,
  ): Promise<T> => {
    const { services } = config;
    const updateNotificationSettingsUrl =
      services.updateNotificationSettingsUrl;
    return HttpClient.post(updateNotificationSettingsUrl, notificationSettings);
  },
});
