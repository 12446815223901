import { Typography } from "antd";
import { usePortalExportContext } from "./PortalExportContext";
import { useFeedContext } from "../../shared/contexts/FeedContext";
import { PortalConfigurationSelect } from "./PortalConfigurationSelect";
import { usePortalExportData } from "./usePortalExportData";
import { useColumnValidation } from "./useColumnValidation";
import { isEnvVarEquals } from "utils/helpers";

export const PortalConfigurationFields = () => {
  const { formValues, updateFormValues } = usePortalExportContext();
  const { columns: feedColumns } = useFeedContext();
  const { brands, accounts, clients, loading } = usePortalExportData();
  const { validateBrandsColumnQuery, validateAccountsColumnQuery } =
    useColumnValidation();

  const { brandId, accountId, brandColumn, accountColumn, clientId } =
    formValues;

  const handleBrandChange = (value: string | undefined) => {
    updateFormValues({ brandId: value, brandColumn: "" });
  };

  const handleAccountChange = (value: string | undefined) => {
    updateFormValues({ accountId: value, accountColumn: "" });
  };

  const handleClientChange = (value: string | undefined) => {
    updateFormValues({
      brandId: undefined,
      brandColumn: "",
      accountId: undefined,
      accountColumn: "",
      clientId: value,
    });
  };

  const handleBrandsColumnChange = async (brandColumn: string) => {
    try {
      updateFormValues({ brandColumn });
    } catch (error) {
      updateFormValues({ brandColumn: "" });
    }
  };

  const handleAccountsColumnChange = async (accountColumn: string) => {
    try {
      updateFormValues({ accountColumn });
    } catch (error) {
      updateFormValues({ accountColumn: "" });
    }
  };

  const isInternalEnv =
    isEnvVarEquals("CLIENT", "av3") || isEnvVarEquals("CLIENT", "internal");

  return (
    <>
      <Typography.Text strong>
        Add fields to the assets to find them in the portal using filters
      </Typography.Text>

      {isInternalEnv && (
        <PortalConfigurationSelect
          label="Client"
          disabled={loading}
          options={clients}
          value={clientId}
          onChange={handleClientChange}
        />
      )}

      <PortalConfigurationSelect
        label="Brand"
        optional
        disabled={loading || validateBrandsColumnQuery.isLoading}
        options={brands}
        value={brandId}
        columnValue={brandColumn}
        onChange={handleBrandChange}
        onColumnChange={handleBrandsColumnChange}
        feedColumns={feedColumns}
        columnValidationError={validateBrandsColumnQuery.data === false}
      />

      <PortalConfigurationSelect
        label="Account"
        disabled={loading || validateAccountsColumnQuery.isLoading}
        options={accounts}
        value={accountId}
        columnValue={accountColumn}
        onChange={handleAccountChange}
        onColumnChange={handleAccountsColumnChange}
        feedColumns={feedColumns}
        columnValidationError={validateAccountsColumnQuery.data === false}
      />
    </>
  );
};
