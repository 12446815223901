import { useCallback } from "react";
import { FeedRow } from "shared/types/assetExporter";

export const useFeedConfiguration = (feedId?: string) => {
  const getTableRows = useCallback((rows: Array<FeedRow>) => {
    return rows.map(row => ({
      key: row.rowIdentifier,
      order_number: "order_number",
      ...row,
    }));
  }, []);

  return { getTableRows };
};
