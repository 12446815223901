import { Key, useCallback } from "react";
import { useMediaActions } from "redux/media/media.slice";
import { getSelected, useAppSelector } from "./redux";

export const useSelected = () => {
  const { setSelectedRows } = useMediaActions();
  const selector = useAppSelector(getSelected);

  const setSelected = useCallback(
    (keys: Key[], selectedRows: any[]) => {
      setSelectedRows(selectedRows);
    },
    [setSelectedRows],
  );

  const clearSelected = useCallback(() => {
    setSelected([], []);
  }, [setSelected]);

  const toggleSelected = useCallback(
    (resource: WDAsset | WDFolder) => {
      const { selectedRows, selectedIds } = selector;

      if (selectedIds.includes(resource.id)) {
        setSelectedRows(selectedRows.filter(r => r.id !== resource.id));
      } else {
        setSelectedRows(selectedRows.concat(resource));
      }
    },
    [selector, setSelectedRows],
  );

  return {
    ...selector,
    setSelected,
    clearSelected,
    toggleSelected,
  };
};
