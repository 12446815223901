import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { setAdLoadState } from "redux/adLibrary/adLibrary.slice";

import {
  returnNumberOfRequiredVideos,
  returnUploadedCount,
} from "../../../loadStep/helpers.load";

import {
  AdLoadAdStatusEnum,
  IAdLoadProgressStatus,
  IAdToLoadData,
  LoadStepKeys,
} from "shared/types/adLibrary";
import { SetStateAction } from "react";

type HandleSelectedAdsToLoadChangeArgs = {
  currentProcessStep: LoadStepKeys;
  selectedAdsToLoad: IAdToLoadData[];
  setCurrentProcessStep?: (value: SetStateAction<LoadStepKeys>) => void;
};

const isStepCompleted = (
  selectedAdsToLoad: IAdToLoadData[],
  step: keyof IAdLoadProgressStatus,
) => {
  const processedAds = selectedAdsToLoad.filter(
    adToLoad =>
      adToLoad.progressStatus[step] === AdLoadAdStatusEnum.SUCCESS ||
      adToLoad.progressStatus[step] === AdLoadAdStatusEnum.ERROR,
  );

  return processedAds.length === selectedAdsToLoad.length;
};

export const useHandleSelectedAdsToLoadChange = () => {
  const dispatch = useAppDispatch();

  return (args: HandleSelectedAdsToLoadChangeArgs) => {
    if (args.currentProcessStep === LoadStepKeys.WAITING_FOR_LOAD) return;

    if (args.currentProcessStep === LoadStepKeys.UPLOADING_MEDIA) {
      const reqVideoCount = returnNumberOfRequiredVideos(
        args.selectedAdsToLoad,
      );
      const uploadedVideoCount = returnUploadedCount(args.selectedAdsToLoad);
      if (reqVideoCount !== uploadedVideoCount) return;

      if (!isStepCompleted(args.selectedAdsToLoad, "uploadMedia")) return;

      args.setCurrentProcessStep?.(LoadStepKeys.ASSEMBLING_CREATIVE);
      return;
    }

    if (args.currentProcessStep === LoadStepKeys.ASSEMBLING_CREATIVE) {
      if (!isStepCompleted(args.selectedAdsToLoad, "assembleCreative")) return;

      args.setCurrentProcessStep?.(LoadStepKeys.LOADING_ADS);
      return;
    }

    if (args.currentProcessStep !== LoadStepKeys.LOADING_ADS) return;

    const loadedAds = args.selectedAdsToLoad.filter(
      adToLoad => !!adToLoad.facebookAd?.id,
    );
    const errorCount = args.selectedAdsToLoad.filter(
      adToLoad => adToLoad.adLoadStatus.status === "error",
    ).length;
    const processedCount = loadedAds.length + errorCount;

    if (processedCount !== args.selectedAdsToLoad.length) return;

    dispatch(
      setAdLoadState({
        prop: "currentInnerStep",
        data: errorCount
          ? LoadStepKeys.LOAD_AGAIN
          : LoadStepKeys.FINISHED_LOADING,
      }),
    );
    args.setCurrentProcessStep?.(
      errorCount ? LoadStepKeys.LOAD_AGAIN : LoadStepKeys.WAITING_FOR_LOAD,
    );
    return;
  };
};
