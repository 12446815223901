import usePreference from "./usePreference";
import { AdFormat } from "screens/adLibrary/facebookUtils/types";

const useSelectedPreviewFormat = (defaultFormat?: AdFormat) => {
  const { value: previewFormat, setValue: setPreviewFormat } = usePreference(
    "previewFormat",
    defaultFormat || AdFormat.MOBILE_FEED_STANDARD,
    true,
  );

  const isFormat = (format: AdFormat) => previewFormat === format;

  return {
    isFormat,
    previewFormat,
    setPreviewFormat,
  };
};

export default useSelectedPreviewFormat;
