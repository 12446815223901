import { useMemo } from "react";
import { Tooltip, Typography } from "antd";

import EditableAdToLoadUrlText from "../EditableAdToLoadUrlText";

import { canUseUrlField } from "../../../utils";
import { adLoadStatusAfterUrlChange } from "../utils";
import { isRowExpandable } from "screens/adLibrary/adLoad/adLoadDrawer/reviewStep/reviewStepInnerDrawer/utils";

import { OnRowDataChangeArgs } from "../../../types";
import { IAdToLoadData } from "shared/types/adLibrary";
import { AdType } from "screens/adLibrary/facebookUtils/types";
import { useSelectedStores } from "../../../hooks/reviewStep/useGetRequiredData";

interface IProps {
  destinationUrl?: string;
  adToLoad: IAdToLoadData;
}

interface IHandlers {
  onMultiUrlClick?: () => void;
  onRowDataChange?: (args: OnRowDataChangeArgs) => void;
}

type DestinationUrlProps = IProps & IHandlers;

const DestinationUrl = ({
  adToLoad,
  destinationUrl,
  onRowDataChange,
  onMultiUrlClick,
}: DestinationUrlProps) => {
  const { selectedStores } = useSelectedStores();
  const selectedStore = useMemo(
    () =>
      selectedStores?.find(
        dealer =>
          dealer.details?.facebook.fbAccountId === adToLoad.account.account_id,
      ),
    [adToLoad.account.account_id, selectedStores],
  );

  const disabled = useMemo(
    () => !canUseUrlField(adToLoad.ad.type),
    [adToLoad.ad.type],
  );

  const urlCount = useMemo(() => {
    if (adToLoad.ad.type === AdType.Carousel) {
      return adToLoad.ad.visuals.cards?.length;
    }
    return adToLoad.ieUrlValues
      ? Object.values(adToLoad.ieUrlValues)?.length
      : 0;
  }, [
    adToLoad.ad.type,
    adToLoad.ieUrlValues,
    adToLoad.ad.visuals.cards?.length,
  ]);

  if (!!urlCount && isRowExpandable(adToLoad)) {
    const isMultiple = urlCount > 1;
    const rootIeValue =
      adToLoad.ieUrlValues && Object.values(adToLoad.ieUrlValues)?.[0];

    if (!onMultiUrlClick) return null;
    return (
      <Tooltip title="Click to view all Destination URLs" placement="topLeft">
        <Typography.Link
          href={"#"}
          onClick={e => {
            e.stopPropagation();
            onMultiUrlClick();
          }}
        >
          {isMultiple
            ? `Multiple (${urlCount})`
            : `${rootIeValue ? rootIeValue : "No"}`}
        </Typography.Link>
      </Tooltip>
    );
  }

  return (
    <EditableAdToLoadUrlText
      showInputOnly
      linkValue={destinationUrl}
      selectedStore={selectedStore}
      disabled={disabled}
      onChange={value => {
        onRowDataChange?.({
          data: {
            ...adToLoad,
            ad: {
              ...adToLoad.ad,
              inputParameters: {
                ...adToLoad.ad.inputParameters,
                destinationUrl: value,
              },
            },
            adLoadStatus: adLoadStatusAfterUrlChange(adToLoad.adLoadStatus),
          },
          column: "Destination URL",
          willRecheckAd: true,
        });
      }}
    />
  );
};

export default DestinationUrl;
