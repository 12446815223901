import { ReactComponent as Rocket } from "statics/images/Rocket_Load.svg";
import styles from "./Spin.module.scss";

const Spin = () => {
  return (
    <div className={`${styles.spin} ${styles.spinning} ant-spin-dot`}>
      <Rocket />
    </div>
  );
};

export default Spin;
