import { Button, Switch, Tooltip } from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import SwitchIndeterminate from "shared/icons/SwitchIndeterminate";

import { SwitchProps } from "antd/lib/switch";

export type IndeterminateSwitchProps = SwitchProps & {
  tooltipTitle?: string;
  indeterminate?: boolean;
  qcMode?: boolean;
  loading?: boolean;
  /** This should typically toggle switch to on */
  onIndeterminateClick?: () => void;
};

const IndeterminateSwitch = (props: IndeterminateSwitchProps) => (
  <Tooltip title={props.tooltipTitle}>
    {!props.indeterminate && (
      <Switch
        size={props.size}
        checked={props.checked}
        disabled={props.disabled}
        loading={props.loading}
        checkedChildren={props.qcMode && <CheckOutlined />}
        unCheckedChildren={props.qcMode && <CloseOutlined />}
        onChange={props.onChange}
      />
    )}
    {props.indeterminate && (
      <Button
        type="link"
        role="switch"
        disabled={props.disabled}
        onClick={props.onIndeterminateClick}
        icon={<SwitchIndeterminate width={"100%"} height={"100%"} />}
        className={`ant-switch${props.disabled ? " ant-switch-disabled" : ""}${
          props.size === "small" ? " ant-switch-small" : ""
        }`}
      />
    )}
  </Tooltip>
);
export default IndeterminateSwitch;
