import { memo, useCallback, useMemo } from "react";

import FacebookAdCard from "./adCardListContainer/FacebookAdCard";
import CardListContainer from "shared/components/CardListContainer";

import { useWindowSize } from "shared/hooks/useWindowSize";
import useSelectedPreviewFormat from "shared/hooks/userPreferences/useSelectedPreviewFormat";

import {
  AD_NAME_DELIMITER,
  mapEntriesToCardData,
} from "./shared/utils/helpers.table";

import { AdReviewTableEntry } from "shared/types/campaignManagement";
import { AdFormat, IFacebookAd } from "screens/adLibrary/facebookUtils/types";
import { useCampaignManagementIds } from "screens/adLibrary/shared/hooks/dataListHooks";

interface IProps {
  facebookAds: IFacebookAd[];
  dataSource?: AdReviewTableEntry[];
}
interface IHandlers {
  setShowDetails: (showDetails: boolean) => void;
  setSelectedEntryId: (id: string) => void;
}

type AdCardListContainerProps = IProps & IHandlers;

const AdCardListContainer = ({
  facebookAds,
  dataSource = [],
  setShowDetails,
  setSelectedEntryId,
}: AdCardListContainerProps) => {
  const { windowInnerHeight } = useWindowSize();
  const allAvailableHeight = windowInnerHeight - 210;

  const { previewFormat } = useSelectedPreviewFormat();
  const { selectedIds, toggleSelectedIds } = useCampaignManagementIds();

  const gridArgs = useMemo(
    () =>
      previewFormat === AdFormat.DESKTOP_FEED_STANDARD
        ? { cardWidth: 540, cardHeight: 850 }
        : { cardWidth: 360, cardHeight: 810 },
    [previewFormat],
  );

  const cardData = useMemo(
    () => mapEntriesToCardData(dataSource, facebookAds),
    [dataSource, facebookAds],
  );

  const getParentId = useCallback(
    (id?: string) => {
      const parentId = dataSource.find(
        entry => id && entry.id.includes(id),
      )?.id;
      return parentId;
    },
    [dataSource],
  );

  const onSelectedKeysChange = useCallback(
    (checked: boolean, id?: string) => {
      if (!id) return;
      toggleSelectedIds(id);
    },
    [toggleSelectedIds],
  );

  const setSelectedEntry = useCallback(
    (id?: string) => {
      const parentId = getParentId(id);
      setSelectedEntryId?.(parentId || "");
    },
    [getParentId, setSelectedEntryId],
  );

  return (
    <CardListContainer
      virtualized
      gridArgs={gridArgs}
      height={allAvailableHeight}
      cards={cardData.map(ad => {
        const parentId = getParentId(ad.id);
        const parentEntry = dataSource.find(entry => entry.id === parentId);
        return (
          <FacebookAdCard
            key={ad.id}
            facebookAd={ad}
            facebookAds={facebookAds}
            parentEntry={parentEntry}
            format={previewFormat}
            selectedKeys={selectedIds}
            setShowDetails={setShowDetails}
            setSelectedEntry={setSelectedEntry}
            onSelectedKeysChange={onSelectedKeysChange}
          />
        );
      })}
    />
  );
};

export default memo(AdCardListContainer);
