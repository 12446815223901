import { useRef } from "react";

export const useBroadCastChannel = (channelName: string, cb?: () => void) => {
  const broadcastChannel = useRef(
    typeof window !== "undefined" && "BroadcastChannel" in window
      ? new BroadcastChannel(channelName)
      : null,
  );

  if (cb && broadcastChannel?.current) {
    broadcastChannel.current.onmessage = event => cb();
  }

  return (message: string) => {
    broadcastChannel?.current?.postMessage(message);
  };
};
