import * as Types from "../typings/types.gen";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type ClientFragment = {
  __typename?: "orgClients";
  id: any;
  name: string;
  orgId?: string | null;
  slug?: string | null;
};

export type BrandFragment = { __typename?: "brands"; id: any; name: string };

export type AccountFragment = {
  __typename?: "clientAccounts";
  id: any;
  name: string;
};

export type V3ExtQueryVariables = Types.Exact<{
  input: Types.V2AccessQueryInput;
}>;

export type V3ExtQuery = { __typename?: "query_root"; v2AccessQueryExt: any };

export const ClientFragmentDoc = gql`
  fragment Client on orgClients {
    id
    name
    orgId
    slug
  }
`;
export const BrandFragmentDoc = gql`
  fragment Brand on brands {
    id
    name
  }
`;
export const AccountFragmentDoc = gql`
  fragment Account on clientAccounts {
    id
    name
  }
`;
export const V3ExtDocument = gql`
  query v3Ext($input: V2AccessQueryInput!) {
    v2AccessQueryExt(input: $input)
  }
`;

/**
 * __useV3ExtQuery__
 *
 * To run a query within a React component, call `useV3ExtQuery` and pass it any options that fit your needs.
 * When your component renders, `useV3ExtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useV3ExtQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useV3ExtQuery(
  baseOptions: Apollo.QueryHookOptions<V3ExtQuery, V3ExtQueryVariables> &
    ({ variables: V3ExtQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<V3ExtQuery, V3ExtQueryVariables>(
    V3ExtDocument,
    options,
  );
}
export function useV3ExtLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<V3ExtQuery, V3ExtQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<V3ExtQuery, V3ExtQueryVariables>(
    V3ExtDocument,
    options,
  );
}
export function useV3ExtSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    V3ExtQuery,
    V3ExtQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<V3ExtQuery, V3ExtQueryVariables>(
    V3ExtDocument,
    options,
  );
}
export type V3ExtQueryHookResult = ReturnType<typeof useV3ExtQuery>;
export type V3ExtLazyQueryHookResult = ReturnType<typeof useV3ExtLazyQuery>;
export type V3ExtSuspenseQueryHookResult = ReturnType<
  typeof useV3ExtSuspenseQuery
>;
export type V3ExtQueryResult = Apollo.QueryResult<
  V3ExtQuery,
  V3ExtQueryVariables
>;
