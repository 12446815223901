import { Select, type SelectProps } from "antd";
import type { SelectValue } from "antd/lib/select";
import { isArray } from "lodash";
import type { OptionsType } from "rc-select/lib/interface";
import { useMemo } from "react";
import { compareStringBy } from "utils/helpers.table";
import { jsxToString } from "utils/jsxToString";

interface Props<VT extends SelectValue = SelectValue> extends SelectProps<VT> {
  /**
   * Property to sort options and filters
   * @default "label"
   */
  sortBy?: string;
  /**
   * Property to filter options by
   * @default "label"
   */
  filterBy?: string;

  /** Use `options` instead */
  children?: never;
}

export const SortedSelect = <VT extends SelectValue = SelectValue>(
  props: Props<VT>,
) => {
  const { sortBy = "label", filterBy = "label", options = [] } = props;

  const sortedOptions = useMemo(
    () => sortOptions(options, sortBy),
    [options, sortBy],
  );

  return (
    <Select<VT>
      showSearch
      placeholder="Please select"
      filterOption={(input, option) => {
        const optionLabel = jsxToString(option?.[filterBy]) || option?.value;
        return optionLabel.toLowerCase().includes(input.toLowerCase());
      }}
      {...props}
      options={sortedOptions}
    />
  );
};

function sortOptions(options: OptionsType, sortBy: string): OptionsType {
  const sorter = (opt1: OptionsType[number], opt2: OptionsType[number]) =>
    compareStringBy<OptionsType[number]>(option => jsxToString(option[sortBy]))(
      opt1,
      opt2,
    );

  return options
    .map(option => {
      return isArray(option.options)
        ? {
            ...option,
            options: [...option.options].sort(sorter),
          }
        : option;
    })
    .sort(sorter);
}
