import { ReactNode } from "react";
import { Space, Typography } from "antd";
import { InfoCircleFilled, WarningFilled } from "@ant-design/icons";

import { buttonColors } from "../constants";

import styles from "./TitleRow.module.scss";

export enum TitleRowType {
  INFO = "info",
  ERROR = "error",
  WARNING = "warning",
}
export interface ITitleRowProps {
  title: string;
  type?: TitleRowType;
  description?: string;
  addTopSpacing?: boolean;
  utilities?: ReactNode[];
}

const TitleRow = ({
  type,
  title,
  description,
  utilities,
  addTopSpacing,
}: ITitleRowProps) => (
  <div style={{ width: "100%", height: "100%", display: "inline-block" }}>
    <Space
      className={styles.leftSpace}
      style={{
        marginTop: addTopSpacing ? 12.4 : undefined,
      }}
    >
      {type && ["info", "error"].includes(type) && (
        <InfoCircleFilled
          style={{
            color: buttonColors[type],
          }}
        />
      )}
      {type === "warning" && (
        <WarningFilled style={{ color: buttonColors[type] }} />
      )}
      {title && (
        <Typography.Text strong={!!description}>
          {title}
          {description && ": "}
        </Typography.Text>
      )}
      {description && <Typography.Text>{description}</Typography.Text>}
    </Space>
    {utilities && (
      <Space
        className={styles.rightSpace}
        style={{
          marginTop: addTopSpacing ? 6 : undefined,
        }}
      >
        {utilities.map(utility => utility)}
      </Space>
    )}
  </div>
);

export default TitleRow;
