import { useCallback } from "react";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { setAdExportRequests } from "redux/adLibrary/adLibrary.slice";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { IAdExportRequest } from "shared/types/adLibrary";

export const useAdExportRequests = () => {
  const adExportRequests = useAppSelector(
    state => state.adLibrary.adExportRequests,
  );

  const dispatch = useAppDispatch();
  const handleSetAdExportRequests = useCallback(
    (adExportRequests: IAdExportRequest[]) =>
      dispatch(setAdExportRequests({ adExportRequests })),
    [dispatch],
  );

  const removeAdExportRequest = (uuid: string) => {
    handleSetAdExportRequests(
      adExportRequests.filter(adExportRequest => adExportRequest.uuid !== uuid),
    );
  };

  return { adExportRequests, handleSetAdExportRequests, removeAdExportRequest };
};
