import { DatePicker, Form } from "antd";
import moment, { Moment } from "moment";
import { dateFormat } from "shared/constants/dataManagement";
import { INewOrderRecord } from "shared/types/newOrders";

type DateRowCommonProps = {
  orderToSubmit: Partial<INewOrderRecord>;
  onChangeDate: (value: Moment | null, date: string) => void;
};

export const DateRowCommon = ({
  orderToSubmit,
  onChangeDate,
}: DateRowCommonProps) => {
  return (
    <Form.Item label="Select Expiration Date">
      <DatePicker
        placeholder={"Expires After"}
        format={dateFormat}
        value={
          orderToSubmit?.expiresAt
            ? moment(orderToSubmit.expiresAt)
            : moment().add(7, "days")
        }
        onChange={onChangeDate}
      />
    </Form.Item>
  );
};
