import { LOCAL_STORAGE_KEY } from "../constants";
import { fetchToken } from "./fetchToken";

let promise: Promise<string> | null = null;

export const getAccessToken = async () => {
  const { access_token, refresh_token, expiresOn }: MGTokenObj = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEY) ?? "{}",
  );

  // Running promise.
  if (promise) {
    return promise;
  }

  // Expired refresh token.
  if (expiresOn < Date.now()) {
    promise = refreshToken(refresh_token).finally(() => {
      promise = null;
    });

    return promise;
  }

  return access_token;
};

const refreshToken = async (refresh_token: string) => {
  try {
    // Fetch new token.
    const { access_token, expiresOn } = await fetchToken({
      grant_type: "refresh_token",
      refresh_token,
    });

    // Store new token.
    localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify({ access_token, expiresOn, refresh_token }),
    );

    return access_token;
  } catch {
    // Refresh token failed.
    // Reload to force SignIn and throw.
    location.reload();

    throw new Error("[WD] Failed to refresh token");
  }
};
