import styles from "./Portal.module.scss";
import { PortalConfigurationFields } from "./PortalConfigurationFields";
import { PortalConfigurationTable } from "./PortalConfigurationTable";

export const LeftPanel = () => (
  <div className={styles.leftPanelWrapper}>
    <PortalConfigurationFields />
    <PortalConfigurationTable />
  </div>
);
