import { IAccount } from "shared/types/accountManagement";
import { getRandomInt } from "utils/helpers";
import { IPreviewSelectedOptions } from "shared/types/assetBuilder";
import { ITemplateAndStampData } from "shared/types/designStudio";

// adapted from randomizePreviewData in designStudio action.ts
export const useDealerData = (
  dealers: IAccount[],
  templateAndStampData: ITemplateAndStampData,
  preSelectedOptions?: IPreviewSelectedOptions,
) => {
  let chosenOem = preSelectedOptions?.oem || "";
  let chosenStore = preSelectedOptions?.storeName || "";
  let chosenCondition = preSelectedOptions?.vehicleCondition || "All";
  let dealerCodeToUse = "";

  if (dealers) {
    const { template, stamp } = templateAndStampData;

    if (template) {
      const { stores, oems } = template;
      let attemps = 0;
      const processedOemIndexList: number[] = [];
      while (
        chosenStore === "" &&
        processedOemIndexList.length < oems.length &&
        attemps < 100
      ) {
        attemps++;

        const currentOemIndex = Math.floor(Math.random() * oems.length);
        if (processedOemIndexList.includes(currentOemIndex)) continue; // skip if already tried with this oem

        processedOemIndexList.push(currentOemIndex);

        const oem = oems[currentOemIndex];
        const dealerList =
          (stores.length > 0
            ? dealers?.filter(dealerItem =>
                stores.some(
                  dealerFromTemplate =>
                    dealerItem.dealer_name.toLowerCase() ===
                    dealerFromTemplate.toLowerCase(),
                ),
              )
            : dealers?.filter(
                dealerItem =>
                  dealerItem.dealer_oem
                    ?.toLowerCase()
                    .indexOf(oem.toLowerCase()) !== -1,
              )) ?? [];

        const currentDealerIndex = Math.floor(
          Math.random() * dealerList.length,
        );

        chosenOem = oem;
        chosenStore = dealerList[currentDealerIndex]?.dealer_name || "";
      }
    } else if (stamp) {
      const { oem, oems = [], stores = [] } = stamp;
      chosenOem = typeof oem === "string" ? oem : oem?.oem_name;
      if (oems.length > 0) {
        // AV2-1541: found that stamp previews would not show if oem is empty
        // but oems is populated.
        const randomIndex = getRandomInt(oems.length); // select a random index in the array
        chosenOem = oems.length > 1 ? oems[randomIndex] || oems[0] : oems[0];
      }
      if (stores.length > 0) {
        const randomStore = stores[Math.floor(Math.random() * stores.length)];
        chosenStore =
          typeof randomStore === "string"
            ? randomStore
            : randomStore?.dealer_name;
      }
    }

    /*
    if there is a store to use, get
    its data so the dealer code can be retrieved
  */

    let foundDealer: IAccount | null | undefined;

    if (chosenStore) {
      foundDealer =
        dealers?.length > 0
          ? dealers.find(dealer => dealer.dealer_name === chosenStore)
          : null;
    } else {
      /*
      if there is no randomly chosenStore from template,
      find a random store among stores with the chosenOem
    */
      const chosenOemDealers =
        dealers?.length > 0
          ? dealers.filter(
              dealer =>
                dealer.dealer_oem === chosenOem ||
                dealer.dealer_oem?.split(",").includes(chosenOem),
            )
          : [];

      foundDealer =
        chosenOemDealers.length > 0
          ? chosenOemDealers[
              Math.floor(Math.random() * chosenOemDealers.length)
            ]
          : null;
      chosenStore = foundDealer ? foundDealer.dealer_name : "";
    }

    dealerCodeToUse = foundDealer ? foundDealer.dealer_code : "";
  }

  return { chosenOem, chosenStore, chosenCondition, dealerCodeToUse };
};
