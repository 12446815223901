import { memo, CSSProperties, ReactNode } from "react";
import styles from "./VisualMedia.module.scss";

export interface ImgDimensions {
  naturalWidth: number;
  naturalHeight: number;
}

interface IProps {
  thumbnailUrl?: string;
  videoUrl?: string;
  placeholder?: ReactNode;
  displayVideoControls?: boolean;
  mediaHeight?: string | number;
  imageMinHeight?: string | number;
  style?: CSSProperties;
  onLoadImgDimensions?: ({
    naturalWidth,
    naturalHeight,
  }: ImgDimensions) => void;
}

const VisualMedia = ({
  videoUrl,
  thumbnailUrl,
  placeholder,
  displayVideoControls = true,
  mediaHeight,
  onLoadImgDimensions,
  imageMinHeight,
  style,
}: IProps) => {
  let component: JSX.Element;
  if (videoUrl) {
    component = (
      <video
        key={videoUrl}
        className={styles.asset}
        controls={displayVideoControls}
        loop
        autoPlay
        muted
        height={mediaHeight}
        style={{ ...style }}
        onClick={({ currentTarget: video }) => {
          if (!displayVideoControls) {
            video.paused ? video.play() : video.pause();
          }
        }}
      >
        <source
          src={videoUrl}
          type="video/mp4" /* TODO: support dynamic type*/
        />
        <track default kind="captions" srcLang="en" src="" />
        Your browser does not support the video tag.
      </video>
    );
  } else if (thumbnailUrl) {
    component = (
      <img
        style={{ ...style, minHeight: imageMinHeight }}
        height={mediaHeight}
        src={thumbnailUrl}
        alt="thumbnail"
        className={styles.asset}
        onLoad={({ currentTarget }) =>
          onLoadImgDimensions?.({
            naturalWidth: currentTarget.naturalWidth,
            naturalHeight: currentTarget.naturalHeight,
          })
        }
      />
    );
  } else {
    component = (
      <div className={styles.asset} style={{ ...style }}>
        <div>{placeholder || ""}</div>
      </div>
    );
  }

  return <div>{component}</div>;
};

export default memo(VisualMedia);
