import { Input, message, Typography, Divider, Space } from "antd";
import { CopyOutlined } from "@ant-design/icons";

import { IFacebookErrorObject } from "screens/adLibrary/facebookUtils/types";

interface IProps {
  facebookError?: IFacebookErrorObject;
}

const ErrorModalContent = ({ facebookError }: IProps) => {
  const errorMsg = facebookError?.error_user_msg || facebookError?.message;
  return (
    <Space direction="vertical" size={1}>
      {errorMsg && <Typography.Text>{errorMsg}</Typography.Text>}
      {errorMsg && <Divider style={{ margin: "12px 0" }} />}
      <Typography.Text strong>Technical Details</Typography.Text>
      <Input.TextArea
        style={{ minHeight: 140, marginBottom: "1em" }}
        value={JSON.stringify(facebookError || {})}
      />
      <Typography.Link
        onClick={() => {
          navigator?.clipboard?.writeText(JSON.stringify(facebookError || {}));
          message.info("Technical details have been copied to clipboard");
        }}
      >
        <CopyOutlined /> Copy to clipboard
      </Typography.Link>
    </Space>
  );
};

export default ErrorModalContent;
