import { Button, Drawer } from "antd";
import { memo, PropsWithChildren } from "react";

type FilterDrawerProps = {
  filterDrawer: boolean;
  setFilterDrawer: (filterDrawer: boolean) => void;
  isSomeFilterApplied: boolean;
  resetFilters: () => void;
};

const FilterDrawer = ({
  filterDrawer,
  setFilterDrawer,
  isSomeFilterApplied,
  resetFilters,
  children,
}: PropsWithChildren<FilterDrawerProps>) => (
  <Drawer
    title="Filter by:"
    width={500}
    placement="right"
    closable={true}
    onClose={() => setFilterDrawer(false)}
    visible={filterDrawer}
    footerStyle={{ display: "flex", justifyContent: "flex-end" }}
    footer={
      <Button
        type="link"
        disabled={!isSomeFilterApplied}
        onClick={resetFilters}
      >
        Reset Filters
      </Button>
    }
  >
    {children}
  </Drawer>
);

export default memo(FilterDrawer);
