import { mergeWith } from "lodash";
import { config } from "./config";

export const withCustomPayload = (
  payload: MGSearchPayload,
  pathname: string,
): MGSearchPayload => {
  if (pathname.startsWith("/media")) {
    return payload;
  }

  return mergeWith({}, config.getCustomPayload(), payload, customizer);
};

const customizer = (obj: any, src: any) => {
  if (Array.isArray(obj)) {
    return obj.concat(src);
  }
};
