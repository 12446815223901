type AiIconProps = {
  height?: number;
  width?: number;
  color?: string;
};

export const AiIcon = ({ height = 14, width = 14, color }: AiIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 32 32"
    style={{ marginRight: width === 14 && height === 14 ? 8 : 0 }}
    fill={color || "none"}
  >
    <path
      d="M8.58603 5.11211C8.94559 3.6293 11.0545 3.6293 11.4141 5.11211L12.4034 9.19217C12.5318 9.72158 12.9451 10.1349 13.4745 10.2633L17.5546 11.2527C19.0374 11.6122 19.0374 13.7211 17.5546 14.0807L13.4745 15.07C12.9451 15.1984 12.5318 15.6118 12.4034 16.1412L11.4141 20.2212C11.0545 21.704 8.94559 21.704 8.58603 20.2212L7.59668 16.1412C7.4683 15.6118 7.05495 15.1984 6.52554 15.07L2.44549 14.0807C0.962671 13.7211 0.96267 11.6122 2.44548 11.2527L6.52554 10.2633C7.05495 10.1349 7.4683 9.72158 7.59668 9.19217L8.58603 5.11211Z"
      fill={color || "black"}
    />
    <path
      d="M20.4632 19.351C20.6845 18.4385 21.9823 18.4385 22.2035 19.351L22.8124 21.8618C22.8914 22.1876 23.1457 22.442 23.4715 22.521L25.9823 23.1298C26.8948 23.3511 26.8948 24.6489 25.9823 24.8702L23.4715 25.479C23.1457 25.558 22.8914 25.8124 22.8124 26.1382L22.2035 28.649C21.9823 29.5615 20.6845 29.5615 20.4632 28.649L19.8544 26.1382C19.7754 25.8124 19.521 25.558 19.1952 25.479L16.6844 24.8702C15.7719 24.6489 15.7719 23.3511 16.6844 23.1298L19.1952 22.521C19.521 22.442 19.7754 22.1876 19.8544 21.8618L20.4632 19.351Z"
      fill={color || "black"}
    />
    <path
      d="M24.4632 4.68438C24.6845 3.77187 25.9823 3.77187 26.2036 4.68438L26.8124 7.19518C26.8914 7.52097 27.1458 7.77534 27.4716 7.85434L29.9824 8.46317C30.8949 8.68444 30.8949 9.98223 29.9824 10.2035L27.4716 10.8123C27.1458 10.8913 26.8914 11.1457 26.8124 11.4715L26.2036 13.9823C25.9823 14.8948 24.6845 14.8948 24.4632 13.9823L23.8544 11.4715C23.7754 11.1457 23.521 10.8913 23.1953 10.8123L20.6844 10.2035C19.3688 10.0003 19.3688 8.66695 20.6844 8.46317L23.1953 7.85434C23.521 7.77534 23.7754 7.52097 23.8544 7.19518L24.4632 4.68438Z"
      fill={color || "black"}
    />
  </svg>
);
