import { MGOrientation, orientations } from "shared/constants/media";

type Fields = {
  fields: WDSearchField[];
};

export const withFileOrientation = (
  searchPayload: MGSearch,
  orientation: MGOrientation,
): MGSearch | undefined => {
  const orientationValue = orientations.get(orientation);

  if (searchPayload) {
    if (searchPayload.filters) {
      const { fields } = searchPayload.filters as Fields;
      return {
        ...searchPayload,
        filters: {
          fields: [
            ...fields,
            {
              field_name: "orientation",
              filter_type: "exact",
              value: orientationValue,
              operator: "AND",
            },
          ],
        },
      };
    } else {
      return {
        ...searchPayload,
        orientation: orientationValue,
      };
    }
  }
};
