import React from "react";
import { Radio, RadioChangeEvent, Space } from "antd";
import styles from "../ExportDrawer.module.scss";

import { RadioBtnRowVal, ROW_SELECTION } from "shared/types/assetExporter";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { useAdEngineActions } from "redux/assetExporter/assetExporter.slice";

const options = [
  {
    label: "Export selected rows",
    value: ROW_SELECTION.EXPORT_SELECTED,
  },
  {
    label: "Export all rows",
    value: ROW_SELECTION.EXPORT_ALL,
  },
];

export const RowSelection = () => {
  const state = useAppSelector(state => state.assetExporter.configure);
  const actions = useAdEngineActions();
  const hasSelectedRows = state.selectedRows.length > 0;
  const radioValue: RadioBtnRowVal = hasSelectedRows
    ? "exportSelectedRows"
    : "exportAllRows";

  const onChange = (e: RadioChangeEvent) => {
    const value: RadioBtnRowVal = e.target.value;
    if (value === "exportAllRows") {
      actions.setProcessSelectedRows([]);
    }
  };
  return (
    <>
      <div className={styles.radioSelectionTitle}>Row Selection</div>
      <Radio.Group value={radioValue} onChange={onChange}>
        <Space direction="vertical">
          {options.map(opt => (
            <Radio key={opt.value} value={opt.value}>
              {opt.label}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </>
  );
};
