import DataListURLTable from "shared/components/dataListURL/DataListURLTable";
import { useDataListURLData } from "shared/components/dataListURL/useDataListURLData";
import { useWindowSize } from "shared/hooks/useWindowSize";
import {
  PrintOptionsCopy,
  PrintOptionsTableColumns,
  PrintOptionsTableItem,
} from "shared/types/printOptions";
import { columns } from "./columns";
import styles from "./NestedCopiesTable.module.scss";

export const NestedCopiesTable = ({ data }: { data: PrintOptionsCopy[] }) => {
  const { windowInnerHeight, windowInnerWidth } = useWindowSize();

  const normalizedData: PrintOptionsTableItem[] = data.map(printOptionCopy => ({
    id: printOptionCopy.id,
    name: "",
    printMode: [printOptionCopy.mode],
    weight: "",
    associatedTemplates: "",
    referenceNumber: "",
    costPerCopy: [printOptionCopy],
  }));

  const tableData = useDataListURLData<
    PrintOptionsTableColumns,
    PrintOptionsTableItem
  >({
    data: normalizedData,
    isLoading: false,
    isError: false,
  });

  // This is necessary to align the nested table with the parent table due to the row selection
  const nestedColumns = columns.map(column => {
    if (column.key === "name") {
      return {
        ...column,
        width: 440,
      };
    }
    return column;
  });

  return (
    <DataListURLTable<PrintOptionsTableColumns, PrintOptionsTableItem>
      type="normal"
      fieldKeys={["copyQuantity", "copyCost"]}
      dataSource={tableData.data}
      className={styles.table}
      rowClassName={styles.row}
      columns={nestedColumns}
      scroll={{
        y: windowInnerHeight - 245,
        x: windowInnerWidth - 20,
      }}
      disableRowSelection
      showHeader={false}
      pagination={false}
      size="small"
    />
  );
};
