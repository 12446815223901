import API from "services";
import { useMutation, useQueryClient } from "react-query";
import { useUser } from "shared/hooks/useUser";
import { GoogleAdCampaignInternal } from "shared/types/adLibrary";
import { pmaxCampaignsQueryKey } from "./constants";

export const useUpdatePmaxCampaign = () => {
  const user = useUser();
  const queryClient = useQueryClient();
  return useMutation(
    (campaign: GoogleAdCampaignInternal) => {
      if (!user) return Promise.reject("User not found");
      return API.services.adLibrary.updatePmaxCampaign({
        campaign,
        user,
      });
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(pmaxCampaignsQueryKey);
      },
    },
  );
};
