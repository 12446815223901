import API from "services";
import { useQuery } from "react-query";
import { IApiResponse } from "shared/types/shared";

const cleanSVGResponse = (res: string) => {
  return res?.replace('<?xml version="1.0"?>', "") ?? "";
};
const getLookerThumbnail = async (dashboardId?: string) => {
  if (!dashboardId) {
    return null;
  }
  try {
    const { result } = await API.services.metrics.getLookerThumbnail<
      IApiResponse<string>
    >(dashboardId);
    return cleanSVGResponse(result!);
  } catch (error) {
    if (error instanceof Error) {
      throw Error(error.message);
    }
    throw Error("Unknown error occurred while fetching looker thumbnail.");
  }
};

export const useFetchLookerThumbnail = (dashboardId?: string) => {
  return useQuery<string | null, Error>(
    ["metrics", dashboardId],
    () => getLookerThumbnail(dashboardId),
    {
      enabled: !!dashboardId,
      staleTime: Infinity,
      retry: false,
    },
  );
};
