import { useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import API from "services";
import {
  GetPrcdFeedForAdLib,
  PrcdFeedForAdLib,
} from "shared/types/assetExporter";
import { getErrorMessage } from "utils/errorMessage";
import { useUser } from "../useUser";

const getProcessedFeed = async (args: GetPrcdFeedForAdLib) => {
  try {
    const { result, error } =
      await API.services.assetExporter.getPrcdFeedForAdLib(args);

    if (!!error) throw new Error(error.message);
    if (!result) throw new Error("An error ocurred");

    return result;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};
const useFetchProcessedFeed = (feedId: string, isAdLibExportOpen: boolean) => {
  const user = useUser();
  const createdBy = user?.email ?? "";
  const result = useInfiniteQuery<PrcdFeedForAdLib, Error>(
    ["csvPreview", feedId],
    ({ pageParam = undefined }) =>
      getProcessedFeed({ feedId, createdBy, id: pageParam }),
    {
      getNextPageParam: lastPage => {
        const nextPage = lastPage.lastEvaluatedKey;
        if (!nextPage) return false;
        return nextPage;
      },
      staleTime: Infinity,
      enabled: !!feedId && isAdLibExportOpen,
    },
  );
  const rows = useMemo(() => {
    return (
      result.data?.pages.flatMap(page => {
        return page.rows;
      }) ?? []
    );
  }, [result.data?.pages]);

  return {
    ...result,
    rows,
  };
};

export default useFetchProcessedFeed;
