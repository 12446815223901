import { useDatadog } from "shared/hooks/useDatadog";
import BrandsAccounts from "./brandsAccountsManagement/BrandsAccounts";

const BrandsAccountsManagement = () => {
  useDatadog();

  return <BrandsAccounts />;
};

export default BrandsAccountsManagement;
