import API from "services";
import { useQuery } from "react-query";
import { YoutubeChannel } from "shared/types/adLibrary";

const getYoutubeChannels = async (oem: string): Promise<YoutubeChannel[]> => {
  const { result, error } = await API.services.adLibrary.getYoutubeChannels(
    oem,
  );
  if (error) {
    throw Error(error?.message || "something went wrong!");
  }
  return result;
};

const useFetchYoutubeChannels = (oem: string, connected: boolean) => {
  return useQuery(["youtubeChannels", oem], () => getYoutubeChannels(oem), {
    enabled: connected,
    retry: false,
  });
};

export default useFetchYoutubeChannels;
