import { useEffect, useState } from "react";
import { Result } from "antd";
import { useDisplayPublicDashboard } from "../hooks/useDisplayPublicDashboard";
import { sendDataToLookerIframe } from "../utils/LookerUtils";

// Using same URL file for all environments
const COOKIE_CHECK_DOMAIN = "https://alexia-share.constellationenv.com";
const COOKIE_CHECK_URL = `${COOKIE_CHECK_DOMAIN}/av2/prd/third-party-cookie-check/cookie.html`;

export const MetricsIframe = ({
  iframeUrl,
  dashboardId,
}: {
  iframeUrl: string;
  dashboardId: string;
}) => {
  const [cookiesAllowed, setCookiesAllowed] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const { publicDashboardData } = useDisplayPublicDashboard(dashboardId);

  const handlePostMessage = (e: MessageEvent) => {
    if (e.origin !== COOKIE_CHECK_DOMAIN) {
      return;
    }
    const response = JSON.parse(e.data);
    if (response.result) {
      setCookiesAllowed(true);
    } else {
      setShowWarning(true);
    }
  };

  useEffect(() => {
    window.addEventListener("message", handlePostMessage);
    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  }, []);

  const testIframe = (
    <iframe
      title="Cookie Check"
      src={COOKIE_CHECK_URL}
      onLoad={e =>
        sendDataToLookerIframe(
          e.target as HTMLIFrameElement,
          { test: "cookie" },
          "*",
        )
      }
      style={{ display: "none" }}
    />
  );

  const lookerIframe = (
    <iframe
      title="Insights Preview Metrics Iframe!"
      src={iframeUrl}
      width="100%"
      height="100%"
      id="looker-iframe"
      onLoad={e =>
        sendDataToLookerIframe(e.target as HTMLIFrameElement, {
          type: "dashboard:filters:update",
          filters: {
            Account: publicDashboardData?.dashboard?.account || "",
            Oem: publicDashboardData?.dashboard?.oem || "",
          },
        })
      }
    />
  );

  const warning = (
    <Result
      status="warning"
      title="Third-party cookies are not enabled in this browser"
      subTitle="To use this feature, you must have third party cookies enabled on your browser and have ad block turned off."
    />
  );

  return cookiesAllowed ? (
    lookerIframe
  ) : (
    <>
      {testIframe}
      {showWarning && warning}
    </>
  );
};
