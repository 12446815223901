import { createRef, useEffect, useState } from "react";
import { Modal, Space } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Draggable, {
  ControlPosition,
  DraggableBounds,
  DraggableEventHandler,
} from "react-draggable";
import loadable from "@loadable/component";
const CommentList = loadable(() => import("./commentsModal/CommentList"));
const CommentInput = loadable(() => import("./commentsModal/CommentInput"));

import { useWindowSize } from "shared/hooks/useWindowSize";

import { IComment } from "shared/types/shared";

import styles from "./CommentsModal.module.scss";
import { User } from "redux/auth/auth.slice";

interface IProps {
  loading?: boolean;
  visible?: boolean;
  loggedInUser?: User;
  comments?: IComment[];
  defaultPosition?: ControlPosition;
}

interface IHandlers {
  onCancel?: () => void;
  handleCommentInsert?: (comment: IComment) => void;
  handleCommentDelete?: (comment: IComment) => void;
}

export type CommentModalProps = IProps & IHandlers;

const CommentsModal = ({
  loading,
  comments,
  loggedInUser,
  visible = true,
  defaultPosition,
  onCancel,
  handleCommentInsert,
  handleCommentDelete,
}: CommentModalProps) => {
  const [inputWidth, setInputWidth] = useState<number>();
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState<DraggableBounds>({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = createRef<HTMLDivElement>();
  const { windowInnerWidth, windowInnerHeight } = useWindowSize();

  useEffect(() => {
    if (!draggleRef?.current) return;
    setInputWidth(draggleRef?.current?.getBoundingClientRect().width - 75);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowInnerWidth]);

  const onStart: DraggableEventHandler = (event, uiData) => {
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    if (!targetRect) return;
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: windowInnerWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: windowInnerHeight - (targetRect?.bottom - uiData?.y),
    });
  };

  return (
    <Modal
      style={
        defaultPosition && {
          top: defaultPosition.y,
          left: defaultPosition.x,
          position: "absolute",
        }
      }
      mask={false}
      footer={null}
      visible={visible}
      maskClosable={false}
      width={520}
      wrapClassName={styles.draggableModalWrap}
      title={
        <div
          className={styles.titleDiv}
          onMouseOver={() => {
            if (!disabled) return;
            setDisabled(false);
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
        >
          {!comments?.length && "New Comment"}
          {!!comments && (
            <Space size={4}>
              {!!comments?.length && `${comments.length} Comments`}
              {loading && <LoadingOutlined />}
            </Space>
          )}
        </div>
      }
      onCancel={onCancel}
      bodyStyle={{ paddingRight: 0, paddingTop: 14 }}
      modalRender={modal => (
        <Draggable
          enableUserSelectHack
          allowAnyClick
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
    >
      <Space direction="vertical" size={20} className={styles.commentListSpace}>
        {!!comments?.length && (
          <CommentList
            onDelete={handleCommentDelete}
            onSubmit={handleCommentInsert}
            comments={comments}
            width={inputWidth || 420}
          />
        )}
        <CommentInput
          user={loggedInUser}
          width={inputWidth || 450}
          onSubmit={handleCommentInsert}
        />
      </Space>
    </Modal>
  );
};

export default CommentsModal;
