import { Fragment, memo, useState } from "react";

import {
  Button,
  Popconfirm,
  Space,
  Tooltip,
  TooltipProps,
  Image,
  Popover,
  Spin,
  Typography,
} from "antd";
import Link from "antd/lib/typography/Link";

import Badge from "./card/Badge";
import Tag from "./card/Tag";
import EllipsisButton from "./card/EllipsisButton";

import placeholder from "statics/images/missing-thumbnail.png";

import classNames from "classnames";
import styles from "./Card.module.scss";

import { ICardButtonObj } from "shared/types/card";

export interface IProps {
  title?: string;
  imageSrc?: string;
  status?: React.ReactNode;
  badges: React.ReactNode[];
  buttonObjects: ICardButtonObj[];
  tags?: string[];
  overlayItemLimit?: number;
  buttonLimit?: number;
  isThisCardSelected?: boolean;
  spinProps?: { spinning?: boolean; tip?: string };
  isImageTooSmallForScaleDown?: boolean;
  backgroundColor?: string;
}
interface IHandlers {
  onCardClick?: () => void;
}

const returnButtonWithToolTip = (
  toolTipProps: TooltipProps,
  Button: JSX.Element,
) => <Tooltip {...toolTipProps}>{Button}</Tooltip>;

const returnElementFromButtonObj = (
  buttonObj: ICardButtonObj,
  willReturnAsLink?: boolean,
) => {
  const {
    buttonProps,
    tooltipProps,
    popConfirmProps,
    buttonText,
    popoverProps,
  } = buttonObj;
  const dataCy = `${buttonText.toLowerCase().replace(" ", "-")}-button`;

  const button = !willReturnAsLink ? (
    <Button
      data-cy={dataCy}
      shape="circle"
      className={styles[`${buttonProps.className || "cardButton"}`]}
      {...buttonProps}
    />
  ) : (
    <Link
      data-cy={dataCy}
      onClick={buttonProps.onClick}
      onMouseEnter={buttonProps.onMouseEnter}
    >
      {buttonText}
    </Link>
  );

  const reactElement =
    (!willReturnAsLink && tooltipProps) ||
    (willReturnAsLink && tooltipProps && tooltipProps.title !== buttonText)
      ? returnButtonWithToolTip(tooltipProps, button)
      : button;

  if (popConfirmProps) {
    return <Popconfirm {...popConfirmProps}>{reactElement}</Popconfirm>;
  }

  if (popoverProps) {
    return <Popover {...popoverProps}>{reactElement}</Popover>;
  }

  return reactElement;
};

const returnTagsToDisplay = (args: {
  overlayItemLimit: number;
  badgesLength: number;
  tags: string[];
}) => {
  const { overlayItemLimit, badgesLength, tags } = args;
  const tagsLimit = overlayItemLimit - (badgesLength + 1);

  const tagsElements = tags.map((tag, index) => (
    <Tag key={`card-tag-${index}`} text={tag} />
  ));

  if (tags.length <= tagsLimit) {
    return tagsElements;
  }

  const tagsToDisplay = tagsElements.slice(0, tagsLimit - 1);
  const tagsInEllispsis = tags.slice(tagsLimit - 1);
  const tagWithTooltip = (
    <Tooltip
      placement="topRight"
      title={tagsInEllispsis.join(", ")}
      // eslint-disable-next-line react/no-children-prop
      children={[
        <Tag
          key={`card-tag-ellipsis`}
          text={`+${tags.length - tagsToDisplay.length} more`}
        />,
      ]}
    />
  );
  return tagsToDisplay.concat(tagWithTooltip);
};

const returnButtonsToDisplay = (args: {
  buttonObjs: ICardButtonObj[];
  buttonLimit: number;
}) => {
  const { buttonObjs, buttonLimit } = args;
  if (buttonObjs.length <= buttonLimit + 1) {
    return buttonObjs.map((btnObj, index) => (
      <Fragment key={index}>{returnElementFromButtonObj(btnObj)}</Fragment>
    ));
  }
  const buttonsToDisplay = buttonObjs
    .slice(0, buttonLimit)
    .map(btnObj => returnElementFromButtonObj(btnObj));
  const linkBtnsInPopover = buttonObjs
    .slice(buttonLimit)
    .map(btnObj => returnElementFromButtonObj(btnObj, true));

  const popoverContent = (
    <Space direction="vertical" style={{ textAlign: "center" }}>
      {linkBtnsInPopover.map((btn, index) => (
        <div key={`popover-btn-div-${index}`}>
          {btn}
          {index < linkBtnsInPopover.length - 1 && (
            <hr style={{ marginBottom: "auto" }} />
          )}
        </div>
      ))}
    </Space>
  );

  return buttonsToDisplay.concat(
    <EllipsisButton
      dataCy={"more-button-wrapper"}
      popoverContent={popoverContent}
    />,
  );
};

// Possible TO DO: implement dynamic height change based on window size
const IMG_HEIGHT = 240;
const MAX_TAGS_DIV_WIDTH = 130;
const MAX_CARD_DIV_HEIGHT = 300;

const Card = ({
  title,
  imageSrc,
  status,
  badges = [],
  tags = [],
  overlayItemLimit = 8,
  buttonObjects = [],
  buttonLimit = 5,
  isThisCardSelected,
  spinProps,
  isImageTooSmallForScaleDown = false,
  backgroundColor,
  onCardClick,
}: IProps & IHandlers) => {
  const buttonsToDisplay = returnButtonsToDisplay({
    buttonLimit,
    buttonObjs: buttonObjects,
  });
  const tagsToDisplay = returnTagsToDisplay({
    overlayItemLimit,
    badgesLength: badges.length,
    tags,
  });

  const [isError, setIsError] = useState(false);
  const [cardHover, setCardHover] = useState(false);

  return (
    <div
      data-cy="card-div"
      className={classNames(styles.card, {
        [styles.cardSelected]: isThisCardSelected,
      })}
      onMouseEnter={() => setCardHover(true)}
      onMouseLeave={() => setCardHover(false)}
      style={
        !!onCardClick || isThisCardSelected !== undefined
          ? {
              cursor: "pointer",
              height: isThisCardSelected
                ? MAX_CARD_DIV_HEIGHT - 3
                : MAX_CARD_DIV_HEIGHT - 8,
            }
          : undefined
      }
      onClick={e => {
        e.stopPropagation();
        onCardClick?.();
      }}
    >
      <div className={`${styles.flexRow}`} data-cy="card-title">
        <Typography.Paragraph
          ellipsis={{ rows: 2, tooltip: true }}
          style={{ margin: 0 }}
        >
          {title}
        </Typography.Paragraph>
      </div>
      <Spin
        spinning={!!spinProps?.spinning}
        tip={spinProps?.tip}
        size="large"
        wrapperClassName={`${styles.cardSpin}${
          isImageTooSmallForScaleDown ? ` ${styles.cardSpinWithScaleDown}` : ""
        }`}
      >
        <Image
          alt="thumbnail"
          preview={false}
          height={IMG_HEIGHT}
          fallback={placeholder}
          src={imageSrc || "error"}
          className={
            isImageTooSmallForScaleDown && !isError
              ? styles.withPadding
              : styles.noPadding
          }
          wrapperClassName={
            !isImageTooSmallForScaleDown || isError
              ? styles.noPaddingImageWrapper
              : undefined
          }
          style={
            isThisCardSelected
              ? { borderRadius: "0px 0px 10px 10px" }
              : undefined
          }
          wrapperStyle={backgroundColor ? { backgroundColor } : undefined}
          onError={() => {
            setIsError(true);
          }}
        />
      </Spin>
      <div className={styles.cardItemsOverlayDiv}>
        {status && (
          <div className={styles.itemsDiv} style={{ order: 0 }}>
            <Badge style={{ marginTop: 0 }} content={status} />
          </div>
        )}
        <div className={styles.itemsDiv} style={{ order: 1, maxWidth: "50%" }}>
          {badges.map((badge, index) => (
            <Badge key={`card-badge-${index}`} content={badge} />
          ))}
        </div>
        <div
          className={`${styles.itemsDiv} ${styles.tagItems}`}
          style={{
            order: 2,
            maxWidth: MAX_TAGS_DIV_WIDTH,
          }}
        >
          {tagsToDisplay}
        </div>
      </div>
      <div
        className={`${
          cardHover ? `${styles.buttons}` : `${styles.buttonsInvisible}`
        }`}
        onClick={e => e.stopPropagation()}
      >
        {buttonsToDisplay}
      </div>
    </div>
  );
};

export default memo(Card);
