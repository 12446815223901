import { message, Select } from "antd";
import { isEmpty, sortBy } from "lodash";
import { CSSProperties, useEffect, useMemo, useState } from "react";
import { IFacebookAccount } from "../facebookUtils/types";
import { useFetchFacebookAccounts } from "shared/hooks/useFetchFacebookAccounts";
import { useIsAdmin } from "shared/hooks/useIsAdmin";
import { useUser } from "shared/hooks/useUser";
interface IProps {
  disabled?: boolean;
  enabledWhileLoading?: boolean;
  placeholder?: string;
  initialValue?: string;
  style?: CSSProperties;
  displayAccountId?: boolean;
  mode?: "multiple";
  selectedAccount?: IFacebookAccount | null;
  selectedAccounts?: IFacebookAccount[] | null;
  facebookNull?: boolean;
}

interface IHandlers {
  onClear?: () => void;
  onSelect: (selectedAccount: IFacebookAccount) => void;
  onDeselect?: (deselectedAccount: IFacebookAccount) => void;
}

const FacebookAccountSelect = ({
  mode,
  style,
  disabled,
  placeholder,
  initialValue,
  selectedAccount,
  selectedAccounts,
  displayAccountId,
  enabledWhileLoading,
  onClear,
  onSelect,
  onDeselect,
  ...props
}: IProps & IHandlers) => {
  const [searchAccountInput, setSearchAccountInput] = useState<string>("");
  const isAdmin = useIsAdmin();
  const user = useUser();

  const storesTitles = user?.stores?.map(store => store.toLowerCase());

  const {
    result: accounts = [],
    isLoading,
    error: fetchError,
  } = useFetchFacebookAccounts();

  const filteredAccounts = useMemo(() => {
    const newAccounts = accounts.filter(item => {
      const name = item.name.toLowerCase();
      const accountId = item.account_id;
      const searchInput = searchAccountInput.toLowerCase();

      if (!isAdmin) {
        return (
          storesTitles?.includes(name) || storesTitles?.includes(searchInput)
        );
      }

      return (
        name.startsWith(searchAccountInput) ||
        name.includes(searchInput) ||
        accountId.startsWith(searchAccountInput)
      );
    });

    return sortBy([...newAccounts], "name");
  }, [accounts, isAdmin, searchAccountInput, storesTitles]);

  if (fetchError) {
    message.error(
      fetchError?.message ||
        "An unknown error occurred while fetching accounts.",
    );
  }

  /* Possible TO DO: make this component reusable as FacebookObjectSelect, which
   * would allow selection of Accounts, Campaigns, or Adsets
   */

  /* Possible TO DO: Make optional tooltip. If props are provided,
   * the tool tip will show around the select
   */

  const getDisplayValue = (account: IFacebookAccount) => {
    return displayAccountId
      ? `${account?.name} - ${account?.account_id}`
      : account.name;
  };

  useEffect(() => {
    if (accounts) {
      const initialAccount = accounts.find(
        (account: IFacebookAccount) => account.account_id === initialValue,
      );
      initialAccount && onSelect(initialAccount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);

  const placeholderText =
    mode === "multiple" ? "Select ad accounts" : "Select an ad account";

  const getValue = () => {
    if (props.facebookNull && !selectedAccount) {
      return isLoading && !enabledWhileLoading
        ? "Fetching ad accounts..."
        : placeholderText;
    }

    return selectedAccount?.name
      ? getDisplayValue(selectedAccount)
      : selectedAccounts?.map(account => account.name);
  };

  const selectPlaceholder =
    placeholder ?? (isLoading && !enabledWhileLoading)
      ? "Fetching ad accounts..."
      : placeholderText;

  const value = getValue();
  return (
    <Select
      data-cy="account-select"
      mode={mode}
      allowClear={!!onClear}
      onClear={onClear}
      showSearch={true}
      filterOption={true}
      style={style}
      loading={isLoading && !enabledWhileLoading}
      disabled={(isLoading || disabled) && !enabledWhileLoading}
      value={value}
      onBlur={() => {
        setSearchAccountInput("");
      }}
      placeholder={selectPlaceholder}
      notFoundContent={
        enabledWhileLoading ? "Fetching ad accounts..." : "Ad account not found"
      }
      onSearch={(value: string) => {
        // timeout of 250ms gives smooth searching effect
        setTimeout(() => {
          setSearchAccountInput(value);
        }, 250);
      }}
      onDeselect={(value: string) => {
        setSearchAccountInput("");

        if (!mode || !selectedAccounts) {
          return;
        }

        const foundAccount = accounts.find(
          account => getDisplayValue(account) === value,
        );
        if (!foundAccount) {
          message.error("An error occurred while select the account.");
          return;
        }

        onDeselect?.(foundAccount);
      }}
      onSelect={(value: string) => {
        setSearchAccountInput("");

        if (!!value) {
          const foundAccount = accounts.find(
            account => getDisplayValue(account) === value,
          );
          if (!foundAccount) {
            message.error("An error occurred while select the account.");
            return;
          }

          onSelect(foundAccount);
        } else {
          onSelect({
            id: "",
            name: "No FB Page Selected -",
            account_id: "",
            account_status: 1,
          });
        }
      }}
    >
      {props.facebookNull && (
        <Select.Option value={""}>No FB Account Selected -</Select.Option>
      )}

      {filteredAccounts?.map((account: IFacebookAccount) => {
        const displayValue = getDisplayValue(account);
        return (
          <Select.Option
            data-cy="account-option"
            value={displayValue}
            key={displayValue}
          >
            {displayValue}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default FacebookAccountSelect;
