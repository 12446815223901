import { isEmpty } from "lodash";
import { useState } from "react";
import { ISavedOrderState } from "shared/types/assetBuilder";
import { isFeatureEnabled } from "utils/helpers";
import useDeepEffect from "../useDeepEffect";

export type TAssetBuilderPage =
  | "select"
  | "build"
  | "review"
  | "orders"
  | "launcher";
export type TRedirectPath = {
  path: string;
  defaultPath: string;
  page: TAssetBuilderPage;
};

type TUseRedirectArgs = {
  savedOrder?: ISavedOrderState;
  processingOrderId?: string;
  launcherOrderId?: string;
  page?: TAssetBuilderPage;
};

export const useRedirect = (
  args: TUseRedirectArgs,
): [typeof redirectPath, typeof setRedirectPath] => {
  const inlineEditingEnabled = isFeatureEnabled("ENABLE_INLINE_EDITING");

  const [redirectPath, setRedirectPath] = useState<TRedirectPath>();
  useDeepEffect(() => {
    const { savedOrder } = args;
    if (!savedOrder || (!args.processingOrderId && !args.launcherOrderId))
      return;

    const basePath = `/asset-builder/orders/${savedOrder.orderId}/offers`;
    const defaultPath = `${basePath}/select`;

    if (savedOrder.assetInstances && !isEmpty(savedOrder.assetInstances)) {
      const page: TAssetBuilderPage = args.page ?? "review";
      const path =
        page === "launcher"
          ? `${basePath}/asset-launcher`
          : `${basePath}/review`;
      setRedirectPath({
        path,
        defaultPath,
        page,
      });
    } else if (!isEmpty(savedOrder.selectedOffers)) {
      setRedirectPath({
        path: `${basePath}/build`,
        defaultPath,
        page: "build",
      });
    } else {
      const page: TAssetBuilderPage = args.page ?? "select";
      const path =
        page === "launcher"
          ? `${basePath}/asset-launcher`
          : `${basePath}/select${inlineEditingEnabled ? "-v2" : ""}`;
      setRedirectPath({
        path,
        defaultPath,
        page,
      });
    }
  }, [args.savedOrder, args.processingOrderId, args.launcherOrderId]);

  return [redirectPath, setRedirectPath];
};
