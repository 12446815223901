import { Select } from "antd";
import { callToActionValues } from "screens/adLibrary/constants";
import { CallToAction } from "screens/adLibrary/facebookUtils/types";

type Props = {
  value: CallToAction | undefined;
  onChange: (cta: CallToAction) => void;
};

const CtaButtonSelect = ({ value: cta, onChange }: Props) => {
  return (
    <Select
      value={cta}
      onChange={onChange}
      style={{
        width: 150,
      }}
    >
      {callToActionValues.map(({ key, text, value }) => (
        <Select.Option key={key} value={value}>
          {text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CtaButtonSelect;
