import { Form } from "antd";
import { FieldData } from "rc-field-form/lib/interface";
import { useMemo, useState } from "react";
import { useQPSearch } from "../useQPSearch";
import { useAutocomplete } from "./useAutocomplete";

type Values = {
  query: string;
  published_filter: boolean;
};

export const useForm = () => {
  const [form] = Form.useForm();
  const { searchState, setSearchState } = useQPSearch();
  const [searchPayload, setSearchPayload] = useState<Values>({
    query: searchState?.query || "",
    published_filter: !!searchState?.published_filter,
  });
  const formFields = useMemo(() => mapFields(searchPayload), [searchPayload]);
  const options = useAutocomplete(searchPayload.query);

  const onValuesChange = (changedValues: any, values: Values) => {
    setSearchPayload(values);
  };

  return {
    formFields,
    onValuesChange,
    setSearchState,
    options,
    searchPayload,
    form,
  };
};

const mapFields = (searchPayload?: WDSearchPayload): FieldData[] => {
  return [
    {
      name: "query",
      value: searchPayload?.query,
    },
    {
      name: "published_filter",
      value: searchPayload?.published_filter,
    },
  ];
};
