import { Select } from "antd";
import { useCallback, useEffect } from "react";
import { useAdEngineActions } from "redux/assetExporter/assetExporter.slice";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { useFetchInfiniteDealers } from "shared/hooks/useFetchDealers";
import { useFetchOems } from "shared/hooks/useFetchOems";
import { isPharma } from "utils/helpers";
import {
  OPTION_DO_NOT_IMPORT,
  OPTION_IMPORT_NOT_FORMAT,
} from "../mapColStep/Toolbar";

import styles from "./AssetBuilderConfigStep.module.scss";

const { Option } = Select;

const LABEL_BRAND = isPharma ? "Company" : "Brand";
const LABEL_ACCOUNT = isPharma ? "Brand" : "Account";

export const AssetBuilderConfigStep = () => {
  const { feedDrawer: state } = useAppSelector(
    state => state.assetExporter.sourceFeed,
  );
  const actions = useAdEngineActions();
  const { oems, isLoading: isFetchinBrands } = useFetchOems();
  const { dealers, isLoading: isFetchingAccounts } = useFetchInfiniteDealers();

  const enableNextBtnOnUpdate = useCallback(() => {
    if (state.drawerMode === "UPDATE") actions.enableNextButton();
  }, [actions, state.drawerMode]);

  useEffect(enableNextBtnOnUpdate, [enableNextBtnOnUpdate]);

  const getLabelOption = (matchedFrom: string, matchedTo?: string) => {
    if (
      !matchedTo ||
      [OPTION_DO_NOT_IMPORT, OPTION_IMPORT_NOT_FORMAT].includes(matchedTo)
    )
      return matchedFrom;

    return matchedTo;
  };

  return (
    <>
      <p>Select the field you want to filter by</p>
      <Select
        className={styles.colSelection}
        onChange={actions.setFilterColHeader}
        value={state.filterColHeader}
      >
        {state.columnData
          .filter(({ matchedTo }) => matchedTo !== OPTION_DO_NOT_IMPORT)
          .map(({ matchedTo, matchedFrom }, idx) => (
            <Option
              value={getLabelOption(matchedFrom, matchedTo)!}
              key={`assetbuilder-config-${matchedFrom ?? idx}`}
            >
              {getLabelOption(matchedFrom, matchedTo)}
            </Option>
          ))}
      </Select>
      <p>
        <b>Set your tags</b>
      </p>
      <p>{LABEL_BRAND}</p>
      <Select
        mode="multiple"
        className={styles.colSelection}
        loading={isFetchinBrands}
        disabled={isFetchinBrands}
        onChange={actions.setBrandTags}
        value={state.oemTags}
      >
        {oems.map(({ oem_name }) => (
          <Option value={oem_name} key={oem_name}>
            {oem_name}
          </Option>
        ))}
      </Select>
      <p>{LABEL_ACCOUNT}</p>
      <Select
        mode="multiple"
        className={styles.colSelection}
        loading={isFetchingAccounts}
        disabled={isFetchingAccounts}
        onChange={actions.setAccountTags}
        value={state.storeTags}
      >
        {dealers.map(({ dealer_name }) => (
          <Option value={dealer_name} key={dealer_name}>
            {dealer_name}
          </Option>
        ))}
      </Select>
    </>
  );
};
