import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { Input, InputProps } from "antd";
import { useContext } from "react";
import useIEContextAPI from "screens/everythingAds.hooks/useContextAPI";
import styles from "./Search.module.scss";

const Search = () => {
  const { Context } = useIEContextAPI();
  const ieContext = useContext(Context);

  const searchProps: InputProps = {
    prefix: <SearchOutlined />,
    placeholder: "Search",
    bordered: false,
    suffix: (
      <div className={styles.searchSuffix}>
        {ieContext?.isSearching && <LoadingOutlined />}
      </div>
    ),
    onChange: e => ieContext?.onSearch(e.target.value),
  };
  return (
    <div>
      <Input {...searchProps} />
    </div>
  );
};

export default Search;
