import { Collapse, Dropdown, Menu, Button } from "antd";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import {
  addInstantExperienceElement,
  setSelectedInstantExperienceElementId,
  reorderInstantExperienceElement,
  deleteInstantExperienceElement,
  setInstantExperienceDraft,
} from "redux/designStudio/designStudio.slice";
import { useAppSelector } from "shared/hooks/useAppSelector";
import {
  ElementType,
  InstantExperienceBodyElement,
} from "shared/types/adLibrary";
import {
  getDefaultButtonValues,
  getDefaultFooterValues,
} from "./ButtonComponent";
import { getDefaultCarouselValues } from "./CarouselComponent";
import styles from "./ComponentsSidebar.module.scss";
import ComponentItem from "./componentsSidebar/ComponentItem";
import { getDefaultPhotoValues } from "./ImageComponent";
import { getDefaultProductSetValues } from "./ProductSetComponent";
import { getDefaultVideoValues } from "./VideoComponent";
import { negate } from "lodash";
import { isFooter } from "utils/adLibrary.validators";

interface IProps {
  selectedComponent?: InstantExperienceBodyElement;
  components?: InstantExperienceBodyElement[] | null;
}

const componentOptions: {
  value: ElementType;
  text: string;
}[] = [
  { value: "FOOTER", text: "Button" },
  { value: "CAROUSEL", text: "Carousel" },
  { value: "PHOTO", text: "Image" },
  { value: "VIDEO", text: "Video" },
  { value: "ELEMENT_GROUP", text: "Product Set" },
];

function ComponentsSidebar({ selectedComponent, components }: IProps) {
  const dispatch = useAppDispatch();
  const instantExperienceDraftValidations = useAppSelector(
    state => state.designStudio.instantExperienceDraftValidations,
  );
  const instantExperienceDraft = useAppSelector(
    state => state.designStudio.instantExperienceDraft,
  );
  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const {
      source: { index: sourceIndex },
      destination: { index: destinationIndex },
    } = result;

    if (sourceIndex === destinationIndex) {
      return;
    }

    dispatch(
      reorderInstantExperienceElement({ sourceIndex, destinationIndex }),
    );
  };
  const handleAddComponent = ({ key }: { key: React.Key }) => {
    let element: InstantExperienceBodyElement;
    switch (key as ElementType) {
      case "FOOTER":
        // NOTE: we are enforcing to have only ONE footer element.
        // So here, we should check if there is already footer element added or not.
        // If there is footer element, then we should add a regular button element.
        // By default, we are adding button as a footer.
        const hasFooter = components?.some(isFooter);
        element = hasFooter
          ? getDefaultButtonValues()
          : getDefaultFooterValues();
        break;
      case "PHOTO":
        element = getDefaultPhotoValues();
        break;
      case "VIDEO":
        element = getDefaultVideoValues();
        break;
      case "ELEMENT_GROUP":
        element = getDefaultProductSetValues();
        break;
      case "CAROUSEL":
        element = getDefaultCarouselValues();
        break;

      default:
        throw "This component type is not supported yet";
    }

    dispatch(addInstantExperienceElement(element));
  };

  const handleChangeComponentName = (newName: string, id?: string) =>
    !!newName &&
    dispatch(
      setInstantExperienceDraft({
        ...instantExperienceDraft,
        body_elements: instantExperienceDraft?.body_elements?.map(element =>
          element.id === id ? { ...element, name: newName } : element,
        ),
      }),
    );

  return (
    <>
      <Collapse
        className="collapse-container no-padding"
        defaultActiveKey={"components"}
      >
        <Collapse.Panel
          key="components"
          header="Components"
          collapsible={"disabled"}
          showArrow={false}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <ComponentItem
              name={"Settings"}
              handleSelect={() =>
                dispatch(setSelectedInstantExperienceElementId(undefined))
              }
              selected={undefined === selectedComponent?.id}
              pinned={false}
              hasErrors={
                instantExperienceDraftValidations?.settings.displayAlerts &&
                instantExperienceDraftValidations?.settings.hasErrors
              }
            />
            <Droppable droppableId="component">
              {providedDroppable => (
                <div
                  {...providedDroppable.droppableProps}
                  ref={providedDroppable.innerRef}
                >
                  {components
                    ?.filter(negate(isFooter))
                    .map(({ id, name }, index) => (
                      <Draggable key={id} draggableId={id || ""} index={index}>
                        {providedDraggable => (
                          <div
                            key={id}
                            {...providedDraggable.draggableProps}
                            {...providedDraggable.dragHandleProps}
                            ref={providedDraggable.innerRef}
                            style={{
                              ...providedDraggable.draggableProps.style,
                              cursor: "pointer",
                            }}
                          >
                            <ComponentItem
                              id={id}
                              name={name}
                              handleSelect={() =>
                                dispatch(
                                  setSelectedInstantExperienceElementId(id),
                                )
                              }
                              handleDelete={() =>
                                dispatch(deleteInstantExperienceElement(id))
                              }
                              handleChangeName={name =>
                                handleChangeComponentName(name, id)
                              }
                              selected={id === selectedComponent?.id}
                              pinned={false}
                              hasErrors={instantExperienceDraftValidations?.body_elements.some(
                                element => {
                                  return (
                                    element.id === id &&
                                    element.hasErrors &&
                                    element.displayAlerts
                                  );
                                },
                              )}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {providedDroppable.placeholder}
                </div>
              )}
            </Droppable>
            {components?.filter(isFooter).map(({ id, name }) => (
              <ComponentItem
                key={id}
                id={id}
                name={name}
                handleSelect={() =>
                  dispatch(setSelectedInstantExperienceElementId(id))
                }
                handleDelete={() =>
                  dispatch(deleteInstantExperienceElement(id))
                }
                handleChangeName={name => handleChangeComponentName(name, id)}
                selected={id === selectedComponent?.id}
                pinned
                hasErrors={instantExperienceDraftValidations?.body_elements.some(
                  element =>
                    element.id === id &&
                    element.hasErrors &&
                    element.displayAlerts,
                )}
              />
            ))}
          </DragDropContext>
        </Collapse.Panel>
      </Collapse>
      <Dropdown
        trigger={["click"]}
        overlay={
          <Menu onClick={handleAddComponent}>
            {componentOptions.map(({ value, text }) => (
              <Menu.Item key={value}>{text}</Menu.Item>
            ))}
          </Menu>
        }
      >
        <Button type="primary" className={styles.componentSelect}>
          + Add Component
        </Button>
      </Dropdown>
    </>
  );
}

export default ComponentsSidebar;
