import { message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import API from "services";
import {
  IAccount,
  IUpdateAccountResponse,
} from "shared/types/accountManagement";
import { dealerPagesQueryKey } from "./useFetchDealers";

const updateDealer = async (updatedDealer: IAccount) => {
  // TODO: !! v2.5 -> update API.services to API.privServices
  const { result, error } =
    await API.services.dealerManagement.updateDealer<IUpdateAccountResponse>(
      updatedDealer,
    );

  if (!result || error) {
    throw Error();
  }

  return result;
};

const useUpdateDealer = () => {
  const queryClient = useQueryClient();
  return useMutation(updateDealer, {
    onMutate: async (updatedDealer: IAccount) => {
      await queryClient.cancelQueries(["dealer", updatedDealer.dealer_name]);

      const previousDealer = queryClient.getQueryData<IAccount>([
        "dealer",
        updatedDealer.dealer_name,
      ]);

      queryClient.setQueryData(["dealer", updatedDealer.dealer_name], {
        ...previousDealer,
        ...updatedDealer,
      });

      return { previousDealer };
    },
    onSettled: (data, error, variables) => {
      if (!data || error) {
        message.error("Something went wrong while updating the store.");
      }

      queryClient.refetchQueries(["dealer", variables.dealer_name]);
      queryClient.refetchQueries(dealerPagesQueryKey);
    },
  });
};

export default useUpdateDealer;
