import { useState } from "react";

import TabContainer from "../../shared/components/TabContainer";
import { stateOptions } from "../../shared/constants/dataManagement";
import {
  IStateDisclosureElement,
  IStateDisclosureRecord,
  TDisclosureDataType,
} from "../../shared/types/legalLingo";
import "./LegalDisclosures.scss";
import DisclosuresContent from "./legalDisclosures/DisclosuresContent";
import OfferTypeVariablesSection from "./legalDisclosures/OfferTypeVariablesSection";

interface ILegalDisclosures {
  selectedStateTab: string;
  setSelectedStateTab: (tabName: string) => void;
  setStateDisclosuresObjByStateParent: (
    state: string,
    disclosures: IStateDisclosureElement[],
  ) => void;
  originalStateDisclosureObj: IStateDisclosureRecord;
  exceptionsChangesWereMade: boolean;
  toggleChangesWereMade: (
    changesWereMade: boolean,
    dataType?: TDisclosureDataType,
  ) => void;
  shouldFireSaveException: boolean;
  setShouldFireSaveExceptions: (shouldFire: boolean) => void;
}

const LegalDisclosures: React.FC<ILegalDisclosures> = ({
  selectedStateTab,
  setSelectedStateTab,
  setStateDisclosuresObjByStateParent,
  originalStateDisclosureObj,
  exceptionsChangesWereMade,
  toggleChangesWereMade,
  shouldFireSaveException,
  setShouldFireSaveExceptions,
}) => {
  const [activeDisclosureCollapseKey, setActiveDisclosureCollapseKey] =
    useState<string>("");

  const [selectedOemsByState, setSelectedOemsByState] = useState<
    Record<string, string>
  >({});

  const selectOemByState = (oem: string) => {
    const newSelectedOemsByState = { ...selectedOemsByState };

    if (!selectedOemsByState[selectedStateTab]) {
      newSelectedOemsByState[selectedStateTab] = "";
    }

    newSelectedOemsByState[selectedStateTab] = oem;

    setSelectedOemsByState(newSelectedOemsByState);
  };

  const stateTabs = [...stateOptions].map((stateValue: string) => ({
    title: stateValue,
    component: (
      <DisclosuresContent
        currentStateTab={stateValue}
        selectedOem={selectedOemsByState[selectedStateTab] || ""}
        setSelectedOem={selectOemByState}
        activeDisclosureCollapseKey={activeDisclosureCollapseKey}
        setActiveDisclosureCollapseKey={(disclosureCollapseKey: string) => {
          setActiveDisclosureCollapseKey(disclosureCollapseKey);
          setOfferTypeKey(disclosureCollapseKey);
        }}
        setDisclosuresByStateInParent={(state, disclosures) => {
          setStateDisclosuresObjByStateParent(state, disclosures);
        }}
        originalStateDisclosureObj={originalStateDisclosureObj}
        exceptionsChangesWereMade={exceptionsChangesWereMade}
        toggleChangesWereMade={toggleChangesWereMade}
        shouldFireSaveException={shouldFireSaveException}
        setShouldFireSaveExceptions={setShouldFireSaveExceptions}
      />
    ),
  }));

  const [currentOfferTypeKey, setOfferTypeKey] = useState<string>("");

  return (
    <div className="state-tabs-container">
      <TabContainer
        onChange={(tab: string) => {
          setActiveDisclosureCollapseKey("");
          setSelectedStateTab(tab || "");
        }}
        filterTab={
          <OfferTypeVariablesSection
            selectedStateTab={selectedStateTab}
            selectedOem={selectedOemsByState[selectedStateTab] || ""}
            currentOfferTypeKey={currentOfferTypeKey}
            setOfferTypeKey={setOfferTypeKey}
            originalStateDisclosureObj={originalStateDisclosureObj}
          />
        }
        selectedTab={selectedStateTab}
        contentTabs={stateTabs}
        displaySearchView={{
          displayNewOffer: false,
          displaySearchInput: false,
          displayPlusButton: false,
        }}
      />
    </div>
  );
};

export default LegalDisclosures;
