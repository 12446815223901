import { ReactNode } from "react";
import { ReactComponent as Saturn } from "statics/images/Saturn.svg";
import { ReactComponent as Astronaut } from "statics/images/Astronaut.svg";
import "./Empty.scss";

export type Props = {
  message?: string;
  loadTrigger?: ReactNode;
};
const Empty = ({ message, loadTrigger }: Props) => {
  const icon = loadTrigger ? <Astronaut /> : <Saturn />;
  const defaultMessage = loadTrigger
    ? "Looks like we're having trouble starting up."
    : "No data available";

  return (
    <div className="empty">
      {icon}
      <div className="content">
        <div className="message">{message || defaultMessage}</div>
        {loadTrigger && <div className="trigger">{loadTrigger}</div>}
      </div>
    </div>
  );
};

export default Empty;
