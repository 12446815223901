import { Typography, Tooltip } from "antd";
import { DesignStudioAssetType } from "../../context/DesignStudioDataProvider";
import { DesignStudioFields } from "../../context/fields";
import { explainEmptyColumn } from "../../helpers";

export type DesignStudioItemCellProps = {
  value: string | null;
  itemType: DesignStudioAssetType;
  column: DesignStudioFields;
};

export const DesignStudioItemCell = ({
  value,
  itemType,
  column,
}: DesignStudioItemCellProps) => {
  if (value) return <Typography>{value}</Typography>;
  return (
    <Tooltip title={explainEmptyColumn(itemType, column)} placement="topLeft">
      <Typography>-</Typography>
    </Tooltip>
  );
};
