import { isEmpty } from "lodash";
import { useEffect, useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import API from "services";
import { IAccount } from "shared/types/accountManagement";

export const ACCOUNT_QUERY_KEY = "dealers";

type ReturnType = {
  dealers: IAccount[];
  isFetching: boolean;
};
export const useFetchDealers = (): ReturnType => {
  const { data, hasNextPage, fetchNextPage, isFetching, isFetchingNextPage } =
    useFetchDealersPaginated();

  useEffect(() => {
    if (!hasNextPage || isFetchingNextPage) return;

    fetchNextPage();
  }, [hasNextPage, fetchNextPage, isFetchingNextPage]);

  const dealers = useMemo(
    () => data?.pages.flatMap(page => page?.dealers ?? []).sort() ?? [],
    [data?.pages],
  );
  return {
    dealers,
    isFetching,
  };
};

const useFetchDealersPaginated = () => {
  return useInfiniteQuery({
    queryKey: ACCOUNT_QUERY_KEY,
    queryFn: getDealersPaginated,
    getNextPageParam: lastPage => {
      return !isEmpty(lastPage?.paginationKey)
        ? lastPage?.paginationKey.dealer_name
        : undefined;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
};

type GetDealersResponse = {
  result: {
    dealers: IAccount[];
    paginationKey: Record<"dealer_name", string>;
  } | null;
  error: {
    message: string;
  } | null;
};
const getDealersPaginated = async ({ pageParam }: { pageParam?: string }) => {
  const { result, error } =
    await API.services.dealerManagement.getDealers<GetDealersResponse>(
      pageParam,
    );

  if (error) {
    throw Error(error.message || "Unknown error!");
  }

  return result;
};
