import { useQuery } from "react-query";
import API from "services";

export const useOfferEdits = (vins: string[]) => {
  return useQuery(["offer-edits", vins], () => getOfferEdits(vins));
};

const getOfferEdits = async (
  vins: string[],
): Promise<Array<Record<string, string>>> => {
  if (!vins.length) return [];

  const { result, error } = await API.services.assetBuilder.getOfferEdits<{
    edits: Array<Record<string, string>>;
  }>(vins);

  const { edits } = result || {};

  if (error) {
    throw new Error(error.message);
  }

  return edits || [];
};
