import { Select } from "antd";
import { useCallback, useMemo } from "react";
import { FilterBy } from "shared/components/FilterByInput/FIlterByInput";
import { BrandLogo } from "shared/types/brandsAccounts";
import { dedupe } from "utils/helpers.array";
import { useDataList } from "../dataList";
import styles from "./LogoSelectFilters.module.scss";
import { DEFAULT_KEY } from "../dataList/useDataList";

export const LogoSelectFilterList = () => {
  const [dataList, dataListActions] = useDataList<BrandLogo>();

  type OptionData = { label: string; value: string };
  const isOptionData = useCallback(
    (value: any): value is OptionData =>
      typeof value === "object" && value.label && value.value,
    [],
  );

  const getUniqueColumnValues = useCallback(
    (column: string) =>
      dataList.data
        .map(row => row[column as keyof BrandLogo])
        .filter(dedupe)
        .map(name => {
          if (!name) return;
          return { label: name, value: name };
        })
        .filter(isOptionData),
    [dataList.data, isOptionData],
  );

  const nameValues = useMemo(
    () => getUniqueColumnValues("name"),
    [getUniqueColumnValues],
  );

  const brandValues = useMemo(
    () => getUniqueColumnValues("brand"),
    [getUniqueColumnValues],
  );

  const accountValues = useMemo(
    () => getUniqueColumnValues("account"),
    [getUniqueColumnValues],
  );

  return (
    <div data-cy="logo-select-filter-container">
      <FilterBy title="Name">
        <Select
          allowClear
          mode="tags"
          className={styles.select}
          value={dataList.filters[DEFAULT_KEY].name}
          onChange={value =>
            dataListActions.updateFilter("name", value as string[])
          }
          tokenSeparators={[","]}
          options={nameValues}
        />
      </FilterBy>
      <FilterBy title="Asset Type">
        <Select
          allowClear
          mode="multiple"
          className={styles.select}
          value={dataList.filters[DEFAULT_KEY].assetType}
          data-cy="logo-select-filter-asset-type-select"
          onChange={value => dataListActions.updateFilter("assetType", value)}
          defaultValue={dataList.filters[DEFAULT_KEY].assetType}
        >
          <Select.Option value="Image">Image</Select.Option>
        </Select>
      </FilterBy>
      <FilterBy title="Logo Type">
        <Select
          allowClear
          mode="multiple"
          className={styles.select}
          value={dataList.filters[DEFAULT_KEY].logoType}
          data-cy="logo-select-filter-logo-type-select"
          onChange={value => dataListActions.updateFilter("logoType", value)}
          defaultValue={dataList.filters[DEFAULT_KEY].logoType}
        >
          <Select.Option value="OEM">OEM</Select.Option>
          <Select.Option value="Sales Event">Sales Event</Select.Option>
          <Select.Option value="Store">Store</Select.Option>
        </Select>
      </FilterBy>
      <FilterBy title="Artboard name">
        <Select
          allowClear
          mode="multiple"
          className={styles.select}
          value={dataList.filters[DEFAULT_KEY].artboardName}
          data-cy="logo-select-filter-artboard-name-select"
          onChange={value =>
            dataListActions.updateFilter("artboardName", value)
          }
          defaultValue={dataList.filters[DEFAULT_KEY].artboardName}
        >
          <Select.Option value="Horizontal">Horizontal</Select.Option>
          <Select.Option value="Square">Square</Select.Option>
          <Select.Option value="Vertical">Vertical</Select.Option>
        </Select>
      </FilterBy>
      <FilterBy title="Brand">
        <Select
          allowClear
          mode="tags"
          className={styles.select}
          value={dataList.filters[DEFAULT_KEY].brand}
          onChange={value =>
            dataListActions.updateFilter("brand", value as string[])
          }
          tokenSeparators={[","]}
          options={brandValues}
        />
      </FilterBy>
      <FilterBy title="Account">
        <Select
          allowClear
          mode="tags"
          className={styles.select}
          value={dataList.filters[DEFAULT_KEY].account}
          onChange={value =>
            dataListActions.updateFilter("account", value as string[])
          }
          tokenSeparators={[","]}
          options={accountValues}
        />
      </FilterBy>
    </div>
  );
};
