import { IAccountRecord } from "shared/types/accountManagement";

export const validDuplicate = (
  dealers: IAccountRecord[],
  dealerToEditWithLogos: IAccountRecord,
): boolean => {
  const findDealer = dealers.some(
    (dealer: IAccountRecord) =>
      dealer.dealerName.toLowerCase() ===
      dealerToEditWithLogos.dealerName.toLowerCase(),
  );

  return !findDealer;
};

export const doesSignUpFormHaveChanges = (
  dealerToEditHaveChanges: IAccountRecord,
  compareDealerToEditWithLogos: IAccountRecord,
) => {
  const tempObj = replaceDealerNameWithNewName(dealerToEditHaveChanges);

  const tempCompare = { ...compareDealerToEditWithLogos };

  // coop details username and password auto filled - we don't want to compare this
  delete tempObj["coopDetails"];
  delete tempCompare["coopDetails"];

  return JSON.stringify(tempCompare) !== JSON.stringify(tempObj);
};

export const replaceDealerNameWithNewName = (dealerRecord: IAccountRecord) => {
  const newUpdatedDealerRecord = { ...dealerRecord };
  if ("newDealerName" in newUpdatedDealerRecord) {
    newUpdatedDealerRecord["dealerName"] =
      newUpdatedDealerRecord["newDealerName"] || "";
    const { newDealerName, ...rest } = newUpdatedDealerRecord;
    return rest;
  }

  return newUpdatedDealerRecord;
};
