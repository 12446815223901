import { useEffect, useState } from "react";
import { Tab } from "shared/types/assetBuilder";
import API from "services";
import { uniq } from "lodash";

type Args = {
  orderId?: string;
};
export default (args: Args) => {
  const [currentTab, setCurrentTab] = useState<Tab>();
  const [loading, setLoading] = useState(false);
  const [selectedTabs, setSelectedTabs] = useState<Tab[]>([]);
  const [availableTabs, setAvailableTabs] = useState<Tab[]>([]);

  const { orderId } = args;
  useEffect(() => {
    if (!orderId) return;

    const fetchTabs = async () => {
      const { result, error } = await API.services.assetBuilder.getTabsV2(
        orderId,
      );

      if (error || !result) return;

      const { selected, available } = result;

      setSelectedTabs(prev => prev.concat(selected));
      setAvailableTabs(available);
    };

    setLoading(true);
    fetchTabs().finally(() => {
      setLoading(false);
    });
  }, [orderId]);

  useEffect(() => {
    if (!!currentTab || !selectedTabs) return;

    // setting default current tabs
    const [defaultTab] = selectedTabs; // default tab, ladtech one will always be in the front of the array.
    setCurrentTab(defaultTab);
  }, [currentTab, selectedTabs, setCurrentTab]);

  const onTabAdd = async (tab: Tab) => {
    if (!orderId) return;

    try {
      const feedIds = uniq(selectedTabs.map(tab => tab.id).concat([tab.id]));
      await API.services.newOrder.updateNewOrder({
        id: orderId,
        feedIds,
      });

      setSelectedTabs(prev => {
        const alreadySelected = prev.some(t => t.id === tab.id);
        if (alreadySelected) return prev;

        return [...prev, tab]; // new tab selected
      });
      setAvailableTabs(prev => {
        const exists = prev.some(t => t.id === tab.id);
        if (exists) {
          // this means user selected new tab
          return prev.filter(t => t.id !== tab.id);
        }

        return prev;
      });
      setCurrentTab(tab);
    } catch (err) {
      // err
    }
  };

  const onTabDelete = async (tab: Tab) => {
    if (!orderId) return;

    try {
      const feedIds = uniq(
        selectedTabs.filter(t => t.id !== tab.id).map(t => t.id),
      );
      await API.services.newOrder.updateNewOrder({
        id: orderId,
        feedIds,
      });

      setSelectedTabs(prev => {
        return prev.filter(t => t.id !== tab.id);
      });
      setAvailableTabs(prev => {
        return uniq([...prev, tab]);
      });

      const lastTab = selectedTabs[selectedTabs.length - 2];
      if (lastTab) setCurrentTab(lastTab);
    } catch (err) {
      // err
    }
  };
  const onTabChange = (tab: Tab) => {
    setCurrentTab(tab);
  };

  return {
    currentTab,
    onTabAdd,
    onTabChange,
    onTabDelete,
    loading,
    selectedTabs,
    availableTabs,
  };
};
