import { Tabs } from "antd";
import { useLocation } from "react-router-dom";
import { useAppShallowEqualSelector } from "shared/hooks/useAppSelector";
import { AdLoadStepKey } from "shared/types/adLibrary";
import DrawerTitle from "./DrawerTitle";
import CreateNewAds from "./selectStep/CreateNewAds";
import UpdateAds from "./selectStep/UpdateAds";
import { isEnvVarEquals, isFeatureEnabled } from "utils/helpers";

const { TabPane } = Tabs;

const isDefaultTabUpdateAds = isEnvVarEquals("CLIENT", "nu");
const defaultActiveKey = isDefaultTabUpdateAds ? "2" : "1";

const isNewAdLoadEnabled = isFeatureEnabled("ENABLE_NEW_AD_LOAD");

const SelectStep = () => {
  const location = useLocation();

  const { adLoadHistory } = useAppShallowEqualSelector(({ adLibrary }) => ({
    adLoadHistory: adLibrary.adLoad.adLoadHistory,
  }));

  const extraContent = {
    left: (
      <DrawerTitle
        sessionId={adLoadHistory?.sessionId}
        currentStep={AdLoadStepKey.DESTINATION_SELECTION}
        isOnSessionsPage={location.pathname === "/ad-library/sessions"}
      />
    ),
  };

  return (
    <Tabs
      tabBarExtraContent={extraContent}
      defaultActiveKey={defaultActiveKey}
      tabBarStyle={{ margin: "6px 0" }}
    >
      <TabPane tab="Create New Ads" key="1">
        <CreateNewAds />
      </TabPane>
      {isNewAdLoadEnabled && (
        <TabPane tab="Update Existing Ads" key="2">
          <UpdateAds />
        </TabPane>
      )}
    </Tabs>
  );
};

export default SelectStep;
