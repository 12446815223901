import { PlusOutlined } from "@ant-design/icons";
import { Button, Input, message, Modal } from "antd";
import { useState } from "react";
import { useCreateFolderMutation } from "redux/media/media.api";
import { TooltipPerms } from "./TooltipPerms";
import { usePermissions } from "./usePermissions";
import { useQPBreadcrumb } from "./useQPBreadcrumb";

export const FolderCreate = () => {
  const [visible, setVisible] = useState(false);
  const { current } = useQPBreadcrumb();
  const { folders } = usePermissions();
  const [name, setName] = useState("");
  const [createFolder, { isLoading }] = useCreateFolderMutation();
  const allowed = folders.includes("create");

  const handleOpen = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
    setName("");
  };

  const handleOk = async () => {
    try {
      const body = {
        name,
        parent: current,
        status: "active",
      };
      await createFolder(body).unwrap();
      handleClose();
      message.success("Folder was created.");
    } catch {
      message.error("Failed to create folder.");
    }
  };

  return (
    <>
      <TooltipPerms action="Create Folder" enabled allowed={allowed}>
        <Button
          shape="round"
          icon={<PlusOutlined />}
          disabled={!allowed}
          onClick={handleOpen}
        >
          New Folder
        </Button>
      </TooltipPerms>
      <Modal
        destroyOnClose
        title="New Folder"
        visible={visible}
        onOk={handleOk}
        onCancel={handleClose}
        footer={[
          <Button key="back" onClick={handleClose}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={handleOk}
          >
            Ok
          </Button>,
        ]}
      >
        <Input
          autoFocus
          placeholder="New folder"
          value={name}
          onChange={ev => setName(ev.target.value)}
        />
      </Modal>
    </>
  );
};
