import { createContext, PropsWithChildren } from "react";
import { Config } from "shared/hooks/useConfig";

type ConfigContextProps = {
  config: Config;
};

export const ConfigContext = createContext<ConfigContextProps>(
  {} as ConfigContextProps,
);

type ConfigProviderProps = PropsWithChildren<{
  config: Config;
}>;

export const ConfigProvider = ({ children, config }: ConfigProviderProps) => (
  <ConfigContext.Provider value={{ config }}>{children}</ConfigContext.Provider>
);

export const ConfigConsumer = ConfigContext.Consumer;
