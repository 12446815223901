import { Modal } from "antd";
import { ConfirmationModalProps } from "./types";

const ConfirmationModal = ({
  rowCount,
  objectType,
  modalAction,
  onConfirmDelete,
  onConfirmDuplicate,
  onModalActionChange,
  onSelectedRowKeysChange,
}: ConfirmationModalProps) => {
  return (
    <Modal
      title={`${modalAction} ${objectType}s`}
      width="300px"
      closable={false}
      visible={!!modalAction}
      okText={modalAction}
      onCancel={() => onModalActionChange?.(null)}
      onOk={() => {
        if (modalAction === "Delete") {
          onConfirmDelete?.();
        } else if (modalAction === "Duplicate") {
          onConfirmDuplicate?.();
        }
        onModalActionChange?.(null);
        onSelectedRowKeysChange?.([]);
      }}
    >
      {`Are you sure you want to ${modalAction?.toLowerCase()} ${
        rowCount ?? 0
      } ${objectType}${(rowCount ?? 0) > 1 ? "s" : ""}?`}
    </Modal>
  );
};

export default ConfirmationModal;
