import { memo } from "react";
import { Form, Typography } from "antd";

import Layout from "../shared/Layout";
import MediaUpload from "../shared/MediaUpload";

import { IUploadMediaUrls } from "utils/uploadMedia";
import { InstantExperienceElementStyle } from "shared/types/adLibrary";
export interface IVideoComponentForm {
  layoutOption?: InstantExperienceElementStyle;
  fileUrls: IUploadMediaUrls;
}

interface IProps {
  initialValues: IVideoComponentForm;
  onValuesChange(values?: IVideoComponentForm): void;
  forceValidations?: boolean;
}

const VideoComponentForm = ({
  onValuesChange,
  initialValues,
  forceValidations,
}: IProps) => {
  return (
    <Form<IVideoComponentForm>
      layout="vertical"
      requiredMark={"optional"}
      initialValues={initialValues}
      onValuesChange={(_, allValues) => onValuesChange(allValues)}
    >
      <Form.Item style={{ maxWidth: 362 }}>
        <Typography.Title level={4}>Video</Typography.Title>
      </Form.Item>
      <Form.Item
        required
        name="fileUrls"
        valuePropName="fileUrls"
        trigger="setFileUrls"
      >
        <MediaUpload mediaType="video" validateRequired={forceValidations} />
      </Form.Item>
      <Form.Item name="layoutOption" valuePropName="layoutOption">
        <Layout elementType="VIDEO" />
      </Form.Item>
    </Form>
  );
};

export default memo(VideoComponentForm);
