import { useQuery } from "react-query";
import { TemplateFile } from "shared/types/salesEnablement";

export function useFetchHtmlFromUrl(templateFile?: TemplateFile) {
  return useQuery<TemplateFile | undefined>(
    ["selected-file", templateFile?.id],
    async () => {
      if (!templateFile?.url) return;

      const html = await fetch(templateFile.url).then(res => res.text());

      return {
        ...templateFile,
        html,
      };
    },
    {
      enabled: !!templateFile,
    },
  );
}
