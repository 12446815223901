import {
  DeleteOutlined,
  PlusOutlined,
  InfoCircleFilled,
} from "@ant-design/icons";
import { Button, Input, Table } from "antd";
import LabelSelect from "shared/components/LabelSelect";
import { AccountUrl, IAccountRecord } from "shared/types/accountManagement";

import "./DealerManageUrls.scss";
import { ITableColumn } from "shared/components/dataList";
import { useIsAdmin } from "shared/hooks/useIsAdmin";

interface IDealerManageUrlsProps {
  dealerToEditWithLogos: IAccountRecord;
  disabledLabels?: string[];
  highlightErrors?: boolean;
}
interface IDealerManageUrlsHandlers {
  setDealerToEditWithLogos: (dealerToEditWithLogos: IAccountRecord) => void;
}

const getCellAttributes = (
  dealerUrl: AccountUrl,
  highlightErrors: boolean = false,
) => ({
  className: highlightErrors && !dealerUrl.url.trim() ? "row-error" : undefined,
});

const DealerManageUrls: React.FC<
  IDealerManageUrlsProps & IDealerManageUrlsHandlers
> = ({
  dealerToEditWithLogos,
  setDealerToEditWithLogos,
  disabledLabels,
  highlightErrors,
}) => {
  const isAdmin = useIsAdmin();

  const dealerLabelsAndUrls = dealerToEditWithLogos.labels || [];
  const columns: ITableColumn<AccountUrl>[] = [
    {
      key: "rowStatus",
      title: "",
      dataIndex: [],
      width: 40,
      onCell: dealerUrl => getCellAttributes(dealerUrl, highlightErrors),
      render: (_, item) =>
        highlightErrors && !item.url.trim() ? (
          <div className="error-icon">
            <InfoCircleFilled />
          </div>
        ) : null,
    },
    {
      key: "label",
      title: "Label",
      dataIndex: ["name"],
      width: 275,
      onCell: dealerUrl => getCellAttributes(dealerUrl, highlightErrors),
      render: (_, item, i) => (
        <LabelSelect
          disabled={disabledLabels?.includes(item.name)}
          key={`label-select-${i}`}
          allowInsert={true}
          currentValue={item.name}
          onLabelChange={(labelValue: string) => {
            const tempDealerLabelsAndUrls = [...dealerLabelsAndUrls];
            tempDealerLabelsAndUrls[i] = {
              ...tempDealerLabelsAndUrls[i],
              name: labelValue,
            };
            updateDealerLabels(tempDealerLabelsAndUrls);
          }}
        />
      ),
    },
    {
      key: "url",
      title: "URL",
      dataIndex: "url",
      width: 325,
      onCell: dealerUrl => getCellAttributes(dealerUrl, highlightErrors),
      render: (_, item, i) => (
        <div style={{ display: "flex" }}>
          <Input
            key={`url-key-${i}`}
            value={item.url}
            disabled={!isAdmin}
            onChange={event => {
              const tempDealerLabelsAndUrls = [...dealerLabelsAndUrls];
              tempDealerLabelsAndUrls[i] = {
                ...tempDealerLabelsAndUrls[i],
                url: event.target.value,
              };
              updateDealerLabels(tempDealerLabelsAndUrls);
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            type="text"
            style={{
              marginLeft: "0.7em",
              cursor: !isAdmin ? "not-allowed" : "pointer",
            }}
            disabled={disabledLabels?.includes(item.name) || !isAdmin}
            onClick={() => {
              if (!isAdmin) return;
              const urlsWithoutCurrent = dealerLabelsAndUrls.filter(
                (_labelAndUrl, index) => index !== i,
              );

              updateDealerLabels(urlsWithoutCurrent);
            }}
          />
        </div>
      ),
    },
  ];

  const updateDealerLabels = (labels: AccountUrl[]) =>
    setDealerToEditWithLogos({ ...dealerToEditWithLogos, labels });

  return (
    <div>
      <Table
        columns={columns}
        dataSource={dealerLabelsAndUrls}
        pagination={false}
        size="small"
      />
      <Button
        type="link"
        style={{
          cursor: !isAdmin ? "not-allowed" : "pointer",
        }}
        onClick={() => {
          if (!isAdmin) return;
          updateDealerLabels([...dealerLabelsAndUrls, { name: "", url: "" }]);
        }}
      >
        <PlusOutlined /> Add URL
      </Button>
    </div>
  );
};

export default DealerManageUrls;
