import Card from "shared/components/Card";
import { useDataList } from "shared/components/dataList";
import { BrandLogo } from "shared/types/brandsAccounts";

export const LogoCard = ({ logo }: { logo: BrandLogo }) => {
  const [dataList, dataListActions] = useDataList<BrandLogo>();
  const isSelected = dataList.selectedIds.includes(logo.id);

  return (
    <Card
      title={logo.name}
      imageSrc={logo.logoUrl}
      badges={[logo.logoType, logo.artboardName]}
      buttonObjects={[]}
      onCardClick={() => {
        if (isSelected) return dataListActions.toggleSelectedItem(logo.id);
        dataListActions.setSelectedItems([logo.id]);
      }}
      isThisCardSelected={isSelected}
      backgroundColor="#D9D9D9"
    />
  );
};
