import { Badge, Select, Table, Typography } from "antd";
import { ColumnType } from "antd/lib/table";
import { useEffect, useCallback, useMemo, useState } from "react";
import { feedTypes, WARNING_COLOR } from "shared/constants/assetExporter";

import styles from "./MapColStep.module.scss";
import { WarningFilled } from "@ant-design/icons";
import useFetchPreview from "shared/hooks/assetExporter/useFetchPreview";
import { getBadgeHexColor, getColVal, isColDisabled } from "./utils";
import { useAdEngineActions } from "redux/assetExporter/assetExporter.slice";
import { useAppSelector } from "shared/hooks/useAppSelector";
import Link from "antd/lib/typography/Link";
import {
  Toolbar,
  OPTION_DO_NOT_IMPORT,
  OPTION_IMPORT_NOT_FORMAT,
} from "./mapColStep/Toolbar";
import { Key } from "antd/lib/table/interface";
import { adEngineFBAdsUrl } from "utils/constants";

const { Option, OptGroup } = Select;
const { Paragraph } = Typography;

export const MapColStep = () => {
  const { feedDrawer } = useAppSelector(
    state => state.assetExporter.sourceFeed,
  );
  const actions = useAdEngineActions();
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const isUpdate =
    feedDrawer.drawerMode === "UPDATE" && !!feedDrawer.uploadedFile;
  const feedId = feedDrawer.feedId;
  const { isLoading, columnHeaders: colHeaders } = useFetchPreview({
    filename: feedDrawer.uploadedFile!,
    isUpdate,
    feedId,
    isServer: feedDrawer.uploadType === "server",
  });

  useEffect(() => {
    actions.setInitialColumnState();
  }, [actions]);

  const recommendedColumns = useMemo(
    () =>
      Array.from(new Set(feedDrawer.recommendedColumns.map(col => col.name))),
    [feedDrawer.recommendedColumns],
  );

  const handleSelectorChange = useCallback(
    (record: Record<string, any>, selected?: string) => {
      actions.setColData({
        matchedFrom: record.columnFoundInSheet,
        matchedTo: selected,
      });
    },
    [actions],
  );

  const columns: ColumnType<Record<string, any>>[] = [
    {
      title: "Column found in sheet",
      dataIndex: "columnFoundInSheet",
      key: "columnFoundInSheet",
    },
    {
      title: <Badge color="#D9D9D9" className={styles.statusDot}></Badge>,
      dataIndex: "badge",
      key: "badge",
      render: function renderBadgeCol(_, record) {
        const hexColor = getBadgeHexColor(feedDrawer.columnData, record);
        return <Badge color={hexColor} className={styles.statusDot}></Badge>;
      },
    },
    {
      title: "Recommended column names",
      dataIndex: "recommendedColumnNames",
      key: "recommendedColumnNames",
      render: function renderRecCol(_, record) {
        const value = getColVal(feedDrawer.columnData, record);

        return (
          <Select
            showSearch
            style={{ width: "100%" }}
            allowClear
            value={value ?? ""}
            placeholder="select"
            onChange={(selected?: string) => {
              handleSelectorChange(record, selected);
            }}
          >
            <Option value={""}>{"select"}</Option>
            <OptGroup label="Options">
              <Option
                value={OPTION_IMPORT_NOT_FORMAT}
                data-cy={"feed-option-recommended-import-not-format"}
              >
                {OPTION_IMPORT_NOT_FORMAT}
              </Option>
              <Option
                value={OPTION_DO_NOT_IMPORT}
                data-cy={"feed-option-recommended-do-not-import"}
              >
                {OPTION_DO_NOT_IMPORT}
              </Option>
            </OptGroup>
            <OptGroup label="Columns">
              {recommendedColumns.map(name => {
                const isRequired = !!feedDrawer.recommendedColumns.find(
                  recCol => recCol.name === name,
                )?.required;
                return (
                  <Option
                    key={name}
                    value={name}
                    disabled={isColDisabled(feedDrawer.columnData, name)}
                  >
                    {name}{" "}
                    {isRequired && (
                      <span className={styles.requiredField}>*</span>
                    )}
                  </Option>
                );
              })}
            </OptGroup>
          </Select>
        );
      },
    },
  ];

  const dataSource: Record<string, any>[] = (colHeaders ?? []).map(header => ({
    key: header,
    columnFoundInSheet: header,
  }));
  const hasWarnings = feedDrawer.columnData.some(data => !data.isMatched);

  const onRowClassName = (record: Record<string, any>) => {
    if (getBadgeHexColor(feedDrawer.columnData, record) === WARNING_COLOR) {
      return styles.tableRowWarning;
    }
    return "";
  };

  const handleChangeSelected = useCallback(
    (value?: string) =>
      selectedRowKeys.forEach(key => {
        const record = dataSource.find(
          ({ columnFoundInSheet }) => columnFoundInSheet === key,
        );
        if (record) {
          actions.setColData({
            matchedFrom: record.columnFoundInSheet,
            matchedTo: value,
          });
        }
      }),
    [actions, dataSource, selectedRowKeys],
  );

  const rowSelection = useMemo(
    () => ({
      hideSelectAll: true,
      selectedRowKeys,
      onChange: (allSelectedIds: Key[]) => {
        setSelectedRowKeys(allSelectedIds as string[]);
      },
    }),
    [selectedRowKeys],
  );

  return (
    <>
      <Paragraph>Select the platform this feed will be used for:</Paragraph>
      <Select
        className={styles.adSelection}
        onChange={type =>
          actions.setSelectedType({ type, columnsHeaders: colHeaders })
        }
        value={feedDrawer.selectedType}
        disabled={isLoading}
        loading={isLoading}
      >
        {feedTypes.map(type => (
          <Option key={type.type} value={type.type}>
            {type.name}
          </Option>
        ))}
      </Select>
      {hasWarnings && feedDrawer.selectedType === "facebookAds" && (
        <Paragraph className={styles.warningText}>
          <span>
            If creating ads for Ad Library, please first review the formatting
            guidelines.{" "}
          </span>
          <Link download={adEngineFBAdsUrl} href={adEngineFBAdsUrl}>
            Download and view here
          </Link>
        </Paragraph>
      )}
      <div className={!feedDrawer.selectedType ? styles.hideTable : ""}>
        <Paragraph>
          Use the dropdown on the right hand column to map your uploaded data to
          the recommended format. You can choose to ignore the recommended
          formats by selecting “Import without formatting”.
        </Paragraph>
        {hasWarnings && (
          <Paragraph
            className={styles.warningText}
            data-cy="feed-mapping-warning"
          >
            <span>
              <WarningFilled className={styles.warningIcon} /> Unable to map
              some fields, please make selections before continuing
            </span>
            <Link onClick={() => actions.sortColData()}>View warnings</Link>
          </Paragraph>
        )}
        <Toolbar
          allowedKeys={dataSource.map(({ key }) => key)}
          selectedRowKeys={selectedRowKeys}
          onChangeSelected={handleChangeSelected}
          onSelectRowKeys={setSelectedRowKeys}
        />
        <Table
          rowSelection={rowSelection}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          rowClassName={onRowClassName}
        />
      </div>
    </>
  );
};
