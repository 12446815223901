import { selPerms } from "redux/auth/auth.slice";
import { useAppSelector } from "./useAppSelector";

export const useCheckPerms = (rbac: string | string[]) => {
  const perms = useAppSelector(selPerms);
  if (perms) {
    return Array.isArray(rbac)
      ? rbac.some(item => perms.includes(item))
      : perms.includes(rbac);
  } else {
    return false;
  }
};
