import { AutoComplete, Button } from "antd";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useEverythingAdsContext } from "../../everythingAds.hooks/useContextAPI";

import styles from "./RightPanel.module.scss";
import { isUrlInputValid } from "utils/validators";
import { AppColors } from "shared/types/shared";
import API from "services";

const RightPanel = () => {
  const {
    selectedFlowElement,
    isSaving,
    onSave,
    setElements,
    isValidUrlValue,
  } = useEverythingAdsContext() || {};
  const { data } = selectedFlowElement || {};
  const { ie, url } = data || {};
  const { id } = ie || {};

  const ieDesignStudioUrl = id ? `/everything-ads/ads/${id}/edit` : null;

  const { labels } = useEverythingAdsContext() || {};
  const [value, setValue] = useState<string>();
  const onValueChange = (value: string) => {
    setValue(value);

    const type = isUrlInputValid(value) ? "url" : "label"; // default type should be 'label'

    setElements?.(elements => {
      return elements.map(ele => {
        if (ele.id !== selectedFlowElement?.id) return ele;

        const { data } = ele;
        const { url } = data || {};
        if (!url) return ele;

        return {
          ...ele,
          data: {
            url: {
              ...url,
              type,
              value,
              labelName: type === "label" ? value : undefined,
            },
          },
        };
      });
    });
  };

  const onClear = () => {
    setValue(undefined);
  };

  useEffect(() => {
    setValue(url?.value);
  }, [url]);
  const searchOptions = useMemo(() => {
    return labels?.map(({ name }) => ({ lable: name, value: name })) || [];
  }, [labels]);
  const [searchBy, setSearchBy] = useState<string>();
  const onSearch = useCallback(
    searchBy => {
      setSearchBy(searchBy);
    },
    [setSearchBy],
  );

  const timerRef = useRef<NodeJS.Timer>();
  const [isUrlValid, setIsUrlValid] = useState<boolean>(true);
  useEffect(() => {
    if (!value) {
      setIsUrlValid(true);
      return;
    }

    // if labels were not fetched before, we need to check the current value by sending request to the server.
    const isUrl = isValidUrlValue?.(value);
    if (isUrl) {
      setIsUrlValid(true);
      return;
    }

    // if not url, we need to check if it is valid label. The thing is at any point, "labels" might not be available. In this case, we need to check by fetching.
    const labelsAvaiable = (labels?.length || 0) > 0;
    if (labelsAvaiable) {
      setIsUrlValid(labels?.some(item => item.name === value) || false);
      return;
    }

    // Sending request to check if label exist with its name "value"
    const checkLabel = async () => {
      try {
        const { result, error } = await API.services.adLibrary.getLabels(
          `name=${value}`,
        );
        if (error || !result) {
          setIsUrlValid(false);
          return;
        }
        setIsUrlValid(true);
      } catch (err) {
        setIsUrlValid(false);
      }
    };

    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      checkLabel();
    }, 700);
  }, [value, labels, isValidUrlValue]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>Properties</div>

      <div className={styles.content}>
        {ieDesignStudioUrl && (
          <>
            <div>Here is where properties of objects selected in canvas go</div>
            <Button>
              <NavLink target="_blank" to={ieDesignStudioUrl}>
                Edit in Design Studio
              </NavLink>
            </Button>
          </>
        )}

        {url && (
          <>
            <div>Destination Website</div>
            <AutoComplete
              style={
                !isUrlValid
                  ? { border: `1px solid ${AppColors.danger}` }
                  : undefined
              }
              allowClear
              value={value}
              onChange={onValueChange}
              className={styles.autocomplete}
              placeholder="Select URL or Label"
              onSearch={onSearch}
              options={searchOptions.filter(op =>
                op.value.includes(searchBy || ""),
              )}
              onSelect={onValueChange}
              onClear={onClear}
            />
            {!isUrlValid && (
              <div style={{ color: AppColors.danger }}>Not valid input.</div>
            )}
          </>
        )}
      </div>
      <div className={styles.footer}>
        <Button loading={isSaving} onClick={onSave}>
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default RightPanel;
