import { Select, Typography } from "antd";
import styles from "./Portal.module.scss";
import { FeedTblCol } from "shared/types/assetExporter";
import { PortalColumnSelect } from "./PortalColumnSelect";

interface Option {
  id: string;
  name: string;
}

interface PortalConfigurationSelectProps {
  label: string;
  optional?: boolean;
  disabled: boolean;
  options?: Option[];
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  columnValue?: string;
  onColumnChange?: (value: string) => void;
  feedColumns?: FeedTblCol[];
  columnValidationError?: boolean;
}

export const PortalConfigurationSelect: React.FC<
  PortalConfigurationSelectProps
> = ({
  label,
  optional,
  disabled,
  options = [],
  value,
  columnValue,
  onChange,
  onColumnChange,
  feedColumns,
  columnValidationError,
}) => {
  const isColumnSelectVisible = !!onColumnChange && !!feedColumns;

  const renderOptions = () => {
    if (isColumnSelectVisible) {
      return (
        <>
          <Select.Option value="">Feed Column</Select.Option>
          <Select.OptGroup label={`${label}s`}>
            {options.map(option => (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select.OptGroup>
        </>
      );
    }

    return options.map(option => (
      <Select.Option key={option.id} value={option.id}>
        {option.name}
      </Select.Option>
    ));
  };

  return (
    <div>
      <div>
        {!optional && <Typography.Text type="danger">* </Typography.Text>}
        <Typography.Text>{label}</Typography.Text>
        {optional && (
          <Typography.Text type="secondary"> (optional)</Typography.Text>
        )}
      </div>
      <div className={styles.selectContainer}>
        <Select
          disabled={disabled}
          className={styles.select}
          onChange={onChange}
          value={value}
          allowClear
          placeholder="select"
          showSearch
          optionFilterProp="children"
        >
          {renderOptions()}
        </Select>
        {isColumnSelectVisible && (
          <PortalColumnSelect
            feedColumns={feedColumns}
            isVisible={value === ""}
            onChange={onColumnChange}
            disabled={disabled}
            value={columnValue}
            columnValidationError={columnValidationError || false}
            columnType={label}
          />
        )}
      </div>
    </div>
  );
};
