import { useMutation } from "react-query";
import API from "services";
import { GetValidateCredsBody } from "shared/types/assetExporter";
import { getErrorMessage } from "utils/errorMessage";
import { encryptPassword } from "utils/helpers";

const validateCreds = async (body: GetValidateCredsBody) => {
  try {
    const { validateCredentials } = API.services.assetExporter;
    // body.password is unencrypted password
    const password = encryptPassword(body.password);
    const response = await validateCredentials({ ...body, password });
    if (!!response.error) throw new Error(response.error.message);
    if (response.result?.hasDuplicateHeader)
      throw new Error("Duplicate Header");

    return;
  } catch (error) {
    const errMsg = getErrorMessage(error);
    throw new Error(errMsg);
  }
};

const useValidateCreds = () => {
  return useMutation<void, Error, GetValidateCredsBody>(validateCreds);
};

export default useValidateCreds;
