import { useCallback, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { UploadFile } from "antd/lib/upload/interface";
import { IBrandFormValues, ILogos } from "shared/types/brandsAccounts";
import { useCreateBrand } from "shared/hooks/brandsAccountsManagement/useCreateBrand";
import { BrandBaseDrawer } from "./BrandBaseDrawer";

interface Props {
  onCancel: () => void;
  visible: boolean;
}

export const NewBrandDrawer = (props: Props) => {
  const [form] = useForm<IBrandFormValues>();

  const [logos, setLogos] = useState<ILogos>({
    horizontalLogos: [],
    squareLogos: [],
    verticalLogos: [],
  });

  const [fonts, setFonts] = useState<UploadFile[]>([]);

  const onResetForm = useCallback(() => {
    form.resetFields();
    setLogos({
      horizontalLogos: [],
      squareLogos: [],
      verticalLogos: [],
    });
    setFonts([]);
  }, [form]);

  const onCreateBrandSuccess = useCallback(() => {
    onResetForm();
    props.onCancel();
  }, [props, onResetForm]);

  const { mutate: createBrand, isLoading: createBrandIsLoading } =
    useCreateBrand({ onSuccess: onCreateBrandSuccess });

  const onSubmit = useCallback(async () => {
    createBrand({
      logos,
      fonts,
      ...form.getFieldsValue(),
    });
  }, [form, createBrand, logos, fonts]);

  return (
    <BrandBaseDrawer
      buttonText="Save"
      drawerTitle="New Brand"
      mutationIsLoading={createBrandIsLoading}
      onSubmit={onSubmit}
      logos={logos}
      setLogos={setLogos}
      form={form}
      fonts={fonts}
      setFonts={setFonts}
      {...props}
    />
  );
};
