import Icon from "@ant-design/icons";
import { DataNode } from "antd/lib/tree";
import { ReactChild } from "react";
import { IAdValidations } from "screens/adLibrary/adImport/adValidationUtils";
import {
  AdType,
  CallToAction,
  FacebookAdStatus,
  IFacebookAccount,
  IFacebookAd,
  IFacebookAdset,
  IFacebookCampaign,
  IFacebookErrorObject,
  IFacebookObject,
} from "screens/adLibrary/facebookUtils/types";
import { IComment, IndustryType, Module } from "./shared";
import { ToolbarButtonKey, ToolbarButtonValue } from "./toolbar";
import { UploadFile } from "antd/lib/upload/interface";
import { ITableColumn } from "shared/components/dataList/types";
import { IAdFields } from "screens/adLibrary/fields";
import { ITableColumnURL } from "shared/components/dataListURL/DataListURLTable";
import { Field } from "shared/components/dataListURL/types";
import {
  ApiResponseCampaignPlanner,
  ApiResponseCampaignPlannerInstance,
} from "screens/campaignManagement/campaignPlanner/types";
import { IFile } from "./uploadAsset";
import { CampaignAdStatus } from "./campaignPlanner";
import { User } from "redux/auth/auth.slice";
import { AdValidation } from "screens/adLibrary/shared/validationUtils";
import { AdMedia, MultiUploadSizing } from "./uploadManagement";
import { Moment } from "moment-timezone";

/**
 * @param limit `number` How many records to return
 * @param paginationKey `string` ID of the starting ad shell in a page of records to pull
 */
export type GetAdsQueryParams = {
  limit?: number;
  paginationKey?: string;
};
export interface IGetAdsResponseData {
  ads: IAd[];
  paginationKey?: string;
}
export interface IGetAdsResponseData {
  ads: IAd[];
}
export interface ICreateOrUpdateAdResponseData {
  id: string;
}
export interface IDeleteAdResponseData {
  adId: string;
  visualsId: string;
  inputParametersId: string;
}

// ad related types

type AdFormat = "still" | "carousel";
type AdCreativeOption = "single" | "slideshow";
type AdPart = "shell" | "inputs" | "visuals";
type AdData = IAdShell | IVisuals | IInputParameters;

export interface ICreateOrUpdateAdReqBody {
  adPart: AdPart;
  adData: AdData;
  updatedBy: string;
}
export interface IBatchCreateOrUpdateAdReqBody {
  adPart: AdPart;
  adsData: AdData[];
  updatedBy: string;
}

export enum QcStatus {
  ERROR = "ERROR",
  EMPTY = "",
  DRAFT = "DRAFT",
  DISAPPROVED = "DISAPPROVED",
  APPROVED = "APPROVED",
  AWAITING_APPROVAL = "AWAITING APPROVAL",
  INTERNALLY_APPROVED = "INTERNALLY APPROVED",
  CO_OP_APPROVED = "CO-OP APPROVED",
  // truecar QC status options
  TRUECAR_CO_OP_APPROVED = "TRUECAR APPROVED",
  TRUECAR_INTERNALLY_APPROVED = "APPROVED",
  // bearaby QC status options
  CREATIVE_BRIEF_COMPLETE = "CREATIVE_BRIEF_COMPLETE",
  BEARABY_REVIEW = "BEARABY_REVIEW",
  CREATIVE_EDITS = "CREATIVE_EDITS",
  CREATIVE_APPROVED = "CREATIVE_APPROVED",
  READY_TO_LOAD = "READY_TO_LOAD",
  // klarnco QC status options
  EDITS_NEEDED = "EDITS_NEEDED",
  CREATIVE_UPDATED = "CREATIVE_UPDATED",
  // henry-rose QC status options
  CLIENT_REVIEW = "CLIENT_REVIEW",
}

export enum ConflictStatus {
  EMPTY = "",
  READY = "READY",
  SETUP = "SETUP",
  CONFLICT = "CONFLICT",
}

export enum AdCategory {
  EMPTY = "",
  EVENT = "Event",
  RETAIL = "Retail",
  MODEL = "Model",
  DEALER_BRANDING = "Dealer Branding",
}

export type AdditionalCardPlacement = {
  headline: string;
  description: string;
  destinationUrl?: string;
  size: MultiUploadSizing;
} & AdMedia;

export interface ICard {
  thumbnail: string;
  thumbnail25?: string;
  thumbnail50?: string;
  headline: string;
  description: string;
  assetId?: string;
  videoUrl?: string;
  filename?: string;
  destinationUrl?: string;
  ctaButtonText?: CallToAction;
  additionalPlacements?: AdditionalCardPlacement[];
}

export interface IInputParameters {
  id: string;
  name?: string;
  status?: string;
  oems?: string[];
  dealers?: string[];
  models?: {
    year: string;
    name: string;
  }[];
  audiences?: string[];
  tags?: string[];
  client?: string;
  description?: string;
  destinationUrl?: string;
  utm?: string;
  package?: string;
  campaignStartDate?: string;
  campaignEndDate?: string;
  campaign?: string;
  campaignMonth?: string;
  // non-auto
  year?: string;
  month?: string[];
  adType?: AdType;
  version?: string;
  strategy?: string;
  storyPlacement?: string;
  // This field is used by Bearaby
  instagramLink?: string;
}

export interface IVisuals {
  id: string;
  thumbnail25?: string;
  thumbnail50?: string;
  ctaButtonText?: CallToAction;
  postCopy: string; // the ad message
  headline?: string; // the ad single visual headline
  headlineDescription?: string; // link description
  primaryText?: string;
  cards?: ICard[];
  displayUrl?: string;
  format?: AdFormat;
  creativeOption?: AdCreativeOption;
  destination?: IDestinationValues;
  utmCode?: string;

  // IN_FEED Ad media information for Still/Video ads
  thumbnail?: string;
  assetId?: string;
  videoUrl?: string;
  filename?: string;

  additionalPlacements?: AdMedia[];
}

export interface IAdShell {
  id: string;
  platform: string;
  industry: IndustryType;
  type: AdType;
  runDates?: [Date | null, Date | null];
  runDatesStr?: string; // this will be stored in dynamoDB
  category?: AdCategory;
  qcStatus: QcStatus;
  createdAt: number;
  createdBy: string;
  updatedAt: number;
  updatedBy: string;
  userHistory?: string[];
  inputParametersId?: string;
  visualsId?: string;
  // moving the logic of selected ads to the localStorage
  // isSelected?: boolean;
  isDeleted?: boolean;
  adUrl?: string;
  // campaignPlanner related fields
  source?: string;
  campaignStatus?: CampaignAdStatus;

  planners?: ApiResponseCampaignPlanner[];
  instances?: ApiResponseCampaignPlannerInstance[];

  validation?: AdValidation;
}

export interface IAd extends IAdShell {
  visuals: IVisuals;
  inputParameters: IInputParameters;
}

export type ChildCard = Partial<IAd> & {
  isChild: true;
  parentAdId: string;
  cardIndex: number;
};

export interface IAdWithChildren extends IAd {
  children?: ChildCard[];
}

export interface IAdDisplay {
  id: string;
  name?: string;
  thumbnail?: string;
  thumbnail25?: string;
  thumbnail50?: string;
  type: AdType;
  category?: AdCategory;
  CategoryIcon: typeof Icon | null;
  qcStatus: QcStatus;
  qcStatusColor: string;
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  oems?: string;
  models?: string;
  audiences?: string;
  tags?: string;
  client?: string;
  description?: string;
  destinationUrl?: string;
  package?: string;
  campaignStartDate?: string;
  campaignEndDate?: string;
  campaignMonth?: string;
}

// redux types
export interface IAdLibraryState {
  selectedAdIdForSummary: string | null;
  isAdLoadVisible: boolean;
  selectedFacebookAccounts?: IFacebookAccount[] | null;
  adLoad: IAdLoad;
  adImport: {
    adsToImport: IAd[];
    displayImportTable: boolean;
    cancelImport: boolean;
    selectedAdIds: string[];
    processingAds: boolean;
    adValidations: IAdValidations[];
  };
  adReview: {
    pdfExecNames: string[];
  };
  adExportRequests: IAdExportRequest[];
}

export interface IAdLoad {
  currentStep?: AdLoadStepKey | null;
  currentInnerStep?: ReviewStepKeys | LoadStepKeys | null;
  selectedAdForParameters: IAd | null;
  conflictingAdShellIdForEdit?: string | null;
  prevSelectedAdForParameters: IAd | null;
  adLoadParameters: IAdLoadParameters | null;
  facebookAdLoadDict: FacebookAdLoadDictionary | null;
  facebookDataIdsByAd: FacebookDataIdsByAd | null;
  adLoadHistory: IAdLoadHistoryItem | null;
  selectedAds: IAd[] | null;
  destination?: IAdLoadDestination | null;
  review?: IAdLoadReview | null;
  triggerUpdateProp?:
    | "treeData"
    | "selectedAds"
    | "selectedAccounts"
    | "title"
    | "adsToLoad";
}

export type FacebookAdPart = "campaign" | "adset" | "ad";
export type AdLoadRuleQualifier =
  | "Contains"
  | "Doesn't Contain"
  | "Equals"
  | "Doesn't Equal";

export type AdLoadRuleDelimiter = "_" | "-";

export interface IAdLoadParametersRule {
  id?: string;
  parameter: string; // will be type matching from taxonomy
  qualifier: AdLoadRuleQualifier | "" | "isMixed"; // will be qualifier type
  values: string[]; // strings from multi-select - pulled from matching taxonomy + campaigns from selected account
}

// Possible Future TO DO: implement multi-platform (google, snapchat) Ad Load Parameters
export interface IAdLoadParametersRuleset {
  campaign: IAdLoadParametersRule[];
  adset: IAdLoadParametersRule[];
  ad: IAdLoadParametersRule[];
}

// the key is the adShellId
export type AdLoadParametersRulesetObject = Record<
  string,
  IAdLoadParametersRuleset | null
>;

export interface IAdLoadParametersRulesPresetNamesObject {
  campaign: string;
  adset?: string;
  ad?: string;
}

// the key is the adShellId
type AdLoadParametersRulesPresetNamesDict = Record<
  string,
  IAdLoadParametersRulesPresetNamesObject | null
>;

export type TaxonomiesObject = Record<FacebookAdPart, string[]>;
export type DelimterObject = Record<FacebookAdPart, string>;

export type AdLoadRuleConflictIdDict = Record<string, string[]>;
export interface IAdLoadParameters {
  rulesPresetsNames?: AdLoadParametersRulesPresetNamesDict | null;
  rulesets: AdLoadParametersRulesetObject | null;
  conflicts: AdLoadRuleConflictIdDict | null;
  taxonomies: TaxonomiesObject | null;
  delimiters: DelimterObject | null;
}

export interface IRulesetOptionsObject {
  parameter: string[];
  qualifier: string[];
  values: Record<string, string[]>; // for each parameter option, there can be multiple values
}
export type RulesetOptionsDictionary = Record<
  FacebookAdPart,
  IRulesetOptionsObject
>;

export interface IFacebookAdToLoad {
  facebookAd: IFacebookAd;
  title: string; // will be the ad's name | ad type maybe ?
  description: string; // Facebook API status here (Load is in progress, load completed, load failed)
  adLoadStatus: IAdLoadStatus;
  adAccount?: {
    id: string;
    name: string;
  };
}

export interface IAdLoadFacebookDataDictionary {
  campaign: IFacebookCampaign[];
  adset: IFacebookAdset[];
  ad: IFacebookAdToLoad[];
}

export type FacebookAdLoadDictionary = Record<
  string,
  IAdLoadFacebookDataDictionary
>;

interface FacebookDataIds {
  campaign: string[];
  adset: string[];
  ad: string[];
}

export type FacebookDataIdsByAd = Record<string, FacebookDataIds>;

export type AdLoadAdStatus =
  | "idle"
  | "loading"
  | "success"
  | "error"
  | "warning"
  | "draft"
  | "ready";

export enum AdLoadAdStatusEnum {
  IDLE = "idle",
  LOADING = "loading",
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  DRAFT = "draft",
  READY = "ready",
}

export interface IAdLoadReviewAndQAIssue {
  status: AdLoadAdStatus;
  message: string;
  requiredStep?: ReviewStepKeys;
}
export interface IAdLoadStatus {
  errorMessage?: string; // message returned in API response
  status: AdLoadAdStatus;
  facebookError?: IFacebookErrorObject;
  reviewAndQAIssues?: IAdLoadReviewAndQAIssue[];
}

export interface IAdLoadProgressStatus {
  uploadMedia: AdLoadAdStatus;
  assembleCreative: AdLoadAdStatus;
  loadToFacebook: AdLoadAdStatus;
}

export interface IAdLoadHistoryItem {
  sessionId: string;
  sessionJsonUrl: string;
  title: string;
  description: string;
  logText?: string;
}

interface IAdLoadRuleConflictObj {
  adShellId: string;
  adShellName: string;
  ruleset: IAdLoadParametersRuleset;
  conflictAdShellIds: string[];
}

export type AdLoadRuleConflictDict = Record<string, IAdLoadRuleConflictObj>;

export interface IRulesPreset {
  name: string;
  level: FacebookAdPart;
  platform: string;
  rules: IAdLoadParametersRule[];
  delimiter: string;
  taxonomy: string[]; // Possible future TO DO: create taxonomy table for client - taxonomy - delimiter - level
}

export interface IGetAdLoadRulesPresetsQueryParams {
  name?: string;
  platform?: string;
  level?: FacebookAdPart; // Possible future TO DO: include more levels that aren't exclusive to FB
}

export interface IGetAdLoadRulesPresetsResponseData {
  presets: IRulesPreset[];
}

export type AdLoadModifyRulesPresetOperation =
  | "create"
  | "update"
  | "delete"
  | "duplicate";
export interface IModifyAdLoadRulesPresetsResponseData {
  name: string;
  operation: AdLoadModifyRulesPresetOperation;
}

/**
 * The account data we store for the different sessions
 */
interface IAdHistorySessionAccount {
  /** FB Account id */
  account_id: string;
  /** Dealer/Store Name */
  name: string;
}

type AdHistorySessionPlatform = "facebook";

type AdHistorySessionAccounts = Record<
  AdHistorySessionPlatform,
  IAdHistorySessionAccount[]
>;

export enum SessionLoadStatus {
  Failed = "failed",
  Loaded = "loaded",
  NotLoaded = "not loaded",
  Unknown = "unknown",
}
export interface IAdHistorySession {
  id: string;
  name: string;
  module: Module;
  jsonUrl?: string;
  lastUpdatedAt: number;
  lastUpdatedBy: string;
  createdAt: number;
  createdBy: string;
  accounts: AdHistorySessionAccounts; // ex : {Facebook:[{name:”Alexia Test 1”, id: “12345678”}]};
  loadStatus?: SessionLoadStatus;
}

export interface IGetAdHistorySessionsResponseData {
  sessions: IAdHistorySession[];
  paginationKey?: string;
}

export interface IGetAdHistorySessionsQueryParams {
  id?: string;
  name?: string;
  module?: Module;
  created_by?: string;
  updated_by?: string;
  updated_at?: number; // get sessions starting from this time
  created_at?: number; // get sessions starting from this time
  limit?: number;
  paginationKey?: string;
}

export interface IModifyAdHistorySessionResponseData {
  id: string;
  name: string;
  operation: AdLoadModifyRulesPresetOperation;
}

export interface IGetAdHistorySessionJsonQueryParams {
  sessionId: string;
  sessionJsonUrl: string;
}

interface IClientPage {
  name: string; // page name
  id: string; // page id
}

export interface IGetInstantExperiencesResult {
  instantExperiences: IInstantExperience[];
}
export interface IGetInstantExperienceResult {
  instantExperiences: IInstantExperience;
}

export interface IModifyInstantExperienceResult {
  id: string;
  name: string;
  canvasId?: string;
  operation: OperationType;
}

export interface IBatchModifyInstantExperiencesResult {
  id: string[];
}

export type OperationType = "create" | "update" | "delete";

export interface IGetCommentsResult {
  comments: IComment[];
  lastEvaluatedKey?: Partial<IComment>;
}

export interface IGetCommentsParams {
  object?: string;
  timestamp?: string;
}

export interface IModifyCommentResult {
  comment: IComment;
  operation?: OperationType;
}

// Instant experience validations
interface IFieldErrors {
  hasErrors: boolean;
  displayAlerts?: boolean;
}

export interface IElementFieldErrors
  extends IFieldErrors,
    IElementBasicFields {}

interface IBodyElementErrors extends IElementFieldErrors {
  child_elements?: IElementFieldErrors[];
}

export interface IInstantExperienceValidations {
  hasErrors: boolean;
  settings: IFieldErrors;
  body_elements: IBodyElementErrors[];
}

// Instant experiences types
export interface IInstantExperience {
  id?: string;
  name?: string;
  swipeToOpen?: boolean;
  canvasId?: string;
  canvaslink?: string;
  clientPage?: IClientPage;
  oem?: string;
  year?: string;
  model?: string;
  salesEvent?: string;
  language?: string;
  status?: string;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  backgroundColor?: string;

  utm?: string;
  pixel?: string;

  body_elements?: InstantExperienceBodyElement[];
}

export type InstantExperienceTableEntry = {
  id: string;
  name: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  oem: string;
};

export type PopupState = {
  record?: IInstantExperience;
  visible: boolean;
  x?: number;
  y?: number;
};

export type ElementType =
  | "BUTTON"
  | "FOOTER"
  | "PHOTO"
  | "VIDEO"
  | "ELEMENT_GROUP" // Product Set
  | "CAROUSEL";

export interface IInstantExperienceToLoad extends IInstantExperience {
  isRoot?: boolean;
}

export type InstantExperienceBodyElement =
  | IButtonElement
  | IFooterElement
  | IPhotoElement
  | IVideoElement
  | IProductSetElement
  | ICarouselElement;

export type IElementWithDestination =
  | IButtonElement
  | IPhotoElement
  | IFooterElement
  | ICarouselElement;

export interface IElementBasicFields {
  /** The id of the element */
  id: string;
  /** The type of the element */
  element_type: ElementType;
  /** Name of the button. Default value: Button */
  name?: string;
}

export interface IButtonElement extends IElementBasicFields {
  /** The color of the button. Default value: #000000 */
  button_color?: string;
  /** The text inside the button */
  rich_text?: RichText;
  /** The style of the button. Default value: BUTTON_OUTLINE */
  button_style?: ButtonStyle;
  /** The color of the text on the button. Default value: #777777 */
  text_color?: string;
  /** The size of the font for the text on the button. Default value: 15 */
  font_size?: number;
  /** The font family of the text on the button. Default value: sans-serif */
  font_family?: string;
  /** Background color of the button. Default value: #FFFFFF */
  background_color?: string;
  /** this will be used for creating open_url_action */
  destination?: IInstantExperienceElementDestination;

  element_type: "BUTTON";

  /** the button markup, only be available on everything ad html */
  markup?: string;
}

export enum ButtonStyle {
  BUTTON_OUTLINE = "BUTTON_OUTLINE",
  BUTTON_FILLED = "BUTTON_FILLED",
}

interface RichText {
  /** The content of the rich text blob */
  plain_text: string;
  /** The inline style list of the rich text */
  inline_styles: InlineStyle[];
  /** The canvas text style */
  block_type: "UNSTYLED";
}

interface InlineStyle {
  style: InlineStyleType;
  offset: number;
  length: number;
}

type InlineStyleType =
  | "BOLD"
  | "CODE"
  | "ITALIC"
  | "STRIKETHROUGH"
  | "UNDERLINE";

export interface IFooterElement extends IElementBasicFields {
  /** The child elements inside a footer */
  child_elements: [IButtonElement];
  /** Background color of the button */
  background_color?: string;

  element_type: "FOOTER";
}

export interface IPhotoElement extends IElementBasicFields {
  /** S3 url to image asset */
  url?: string;
  /** See enum InstantExperienceElementStyle */
  style?: InstantExperienceElementStyle;
  /** this will be used for creating open_url_action */
  destination?: IInstantExperienceElementDestination;

  element_type: "PHOTO";
}

export interface IVideoElement extends IElementBasicFields {
  /** S3 url to video asset */
  url?: string;

  /** S3 url to video thumbnail */
  thumbnail?: string;

  /** See enum InstantExperienceElementStyle */
  style?: InstantExperienceElementStyle;

  element_type: "VIDEO";
}

export interface IProductSetElement extends IElementBasicFields {
  /** A token to represent which field from the product to show in the product headline */
  item_headline?: string;
  /** A token to represent which field from the product to show in the product description */
  item_description?: string;
  /** Maximum number of products to show */
  max_products?: number;
  /** ID of Facebook product set from a product catalog. Selected during load IE process */
  product_set_id?: string;
  /** Product Set  */
  product_set?: IProduct[];

  element_type: "ELEMENT_GROUP";
}

export interface IProduct {
  headline: string;
  description: string;
  source?: string;
}

export interface ICarouselElement extends IElementBasicFields {
  /** The child elements of the carousel. Typically, they are canvas photo elements */
  child_elements: IPhotoElement[];

  element_type: "CAROUSEL";
}

export interface IInstantExperienceElementDestination {
  /** if label is used, this will be populated in the load process based on selected account */
  urlLabel?: string;
  /** the id of the instant experience to be used for an instant experience url destination*/
  instantExperienceId?: string;

  /**
   * The everything ad, only be available on everything ad html
   * and ad load v2
   * */
  everythingAd?: IInstantExperience;
}

export enum InstantExperienceElementStyle {
  FIT_TO_WIDTH = "FIT_TO_WIDTH",
  FIT_TO_WIDTH_EXPANDABLE = "FIT_TO_WIDTH_EXPANDABLE",
  FIT_TO_HEIGHT = "FIT_TO_HEIGHT",
}
export interface IDestinationValues {
  websiteURL?: string;
  instantExperienceId?: string;
}

interface IFacebookDestinationData extends IFacebookAccount {
  campaigns: IFacebookCampaign[]; // campaigns contains adsets, adsets.data contains ads
}
export interface IAdLoadDestination {
  selectedAds?: IAd[];
  removedAdDestinationKeys?: string[]; // ad key format: <adShell.id>_<campaign.id>_<adset.id>
  checkedKeys?: string[];
  expandedKeys?: string[];
  treeData?: DataNode[];
  adDestinationKeys?: string[]; // ad key format: <adShell.id>_<campaign.id>_<adset.id>
  facebookData?: IFacebookDestinationData[];
}

export type AdLoadColumnKey =
  | "Off/On"
  | "Status"
  | "Load Status"
  | "Account Name"
  | "Account ID"
  | "Store Name DBA"
  | "Facebook Page Name"
  | "Facebook Page ID"
  | "Campaign Name"
  | "Objective"
  | "Ad Set Name"
  | "Ad Name"
  | "Format"
  | "Destination URL"
  | "Display URL"
  | "Product Catalog"
  | "Product Set"
  | "CTA Button"
  | "Instant Experience Name";

export interface IAdLoadReview {
  adsToLoad?: IAdToLoadData[];
  selectedColumnKeys: AdLoadColumnKey[];
  selectedRowKeys: string[];
}

export type AdLoadDestinationData =
  | IFacebookDestinationData[]
  | DataNode[]
  | IAd[]
  | string[];

export enum AdLoadStepKey {
  DESTINATION_SELECTION = "DESTINATION_SELECTION",
  REVIEW_ADS_TO_LOAD = "REVIEW_ADS_TO_LOAD",
  LOAD_ADS = "LOAD_ADS",
}

export enum ReviewStepKeys {
  REVIEW_DESTINATION_URLS = "REVIEW_DESTINATION_URLS",
  CONVERT_TO_VIDEO = "CONVERT_TO_VIDEO",
  ASSIGN_PRODUCT_SETS_AD_LEVEL = "ASSIGN_PRODUCT_SETS_AD_LEVEL",
  ASSIGN_PRODUCT_SETS_IE_LEVEL = "ASSIGN_PRODUCT_SETS_IE_LEVEL",
  LINK_INSTANT_EXPERIENCE = "LINK_INSTANT_EXPERIENCE",
  INVALID_DESTINATION_URL = "INVALID_DESTINATION_URL",
}

export enum LoadStepKeys {
  WAITING_FOR_LOAD = "WAITING_FOR_LOAD",
  LOADING_ADS = "LOADING_ADS",
  LOAD_AGAIN = "LOAD_AGAIN",
  FINISHED_LOADING = "FINISHED_LOADING",
  UPLOADING_MEDIA = "UPLOADING_MEDIA",
  ASSEMBLING_CREATIVE = "ASSEMBLING_CREATIVE",
}

export type NestedProductSetObject = {
  productCatalog?: IFacebookObject;
  productSet?: IFacebookObject;
};
type NestedProductSets = Record<string, NestedProductSetObject>;

type DestinationUrl = string | undefined;
export type IEUrlValues = { [ieElementKey: string]: DestinationUrl };

export interface IAdToLoadData {
  /**
   * key = <campaign.id_adset.id_ad.id> // for create
   * key = <campaign.id_adset.id_facebookAd.id_ad.id> // for update
   */
  key: string;
  account: IFacebookAccount;
  page: IFacebookObject;
  campaign: IFacebookCampaign;
  adset: IFacebookAdset;
  facebookAd?: IFacebookAd;
  ad: IAd;
  wasConvertedToVideo?: boolean;
  /** Status of ad when loading to Facebook */
  facebookStatus: FacebookAdStatus;
  adLoadStatus: IAdLoadStatus;
  progressStatus: IAdLoadProgressStatus;
  productCatalog?: IFacebookObject;
  productSet?: IFacebookObject;
  /** { [instantExperience.id] : {PRODUCT_CATALOG, PRODUCT_SET} } */
  nestedProductSets?: NestedProductSets;
  ieUrlValues?: IEUrlValues;
  facebookAdId?: string;
}

export type ToolbarButton = Partial<
  Record<ToolbarButtonKey, ToolbarButtonValue>
>;

export interface IAdIssue {
  type: "info" | "warning" | "error";
  message: string;
  actions?: ReactChild[];
}

interface CachedPageData<T> {
  pages: Array<T>;
  pageParams: string[];
}

export type CachedAdHistorySessionsPageData =
  CachedPageData<IGetAdHistorySessionsResponseData>;

export enum ClientType {
  "auto" = "Automotive",
  "retail" = "Retail",
  "travel" = "Travel",
  "pharma" = "Pharma",
  "all" = "All",
}

export interface IAdForm
  extends Pick<IAdShell, "type" | "category" | "qcStatus" | "industry"> {
  visuals: Pick<
    IVisuals,
    | "ctaButtonText"
    | "postCopy"
    | "thumbnail"
    | "videoUrl"
    | "headline"
    | "headlineDescription"
    | "displayUrl"
    | "primaryText"
    | "format"
    | "creativeOption"
    | "destination"
    | "utmCode"
  >;
  inputParameters: Omit<
    IInputParameters,
    | "id"
    | "campaignStartDate"
    | "campaignEndDate"
    | "campaignMonth"
    | "package"
    | "client"
  > & {
    campaignRangeDate?: [moment.Moment | null, moment.Moment | null];
    package?: string[];
    client?: string[];
  };
  runDates?: {
    start: Moment | null;
    end: Moment | null;
  };
}
export type NameID = {
  id: string;
  name: string;
};

export type AdAccountWithDealerName = NameID & { dealerName?: string } & {
  dealerOem?: string;
};

export type UploadGoogleAdCampaignOperationResponse = {
  startDate: string;
  executionArn: string;
};

export type AssetGroupUploadOutput =
  | {
      result: IAssetGroupDetail[];
      error: null;
    }
  | {
      result: null;
      error: { message: string; assetGroupName: string };
    };

type PmaxUploadStatusJson = {
  percentage: number;
  output?: AssetGroupUploadOutput[];
};

export type PmaxUploadStatus = "processing" | "success" | "failed";
export type GetPmaxUploadStatusResponse = {
  executionId?: string;
  description: string;
  status: PmaxUploadStatus;
  responseJson: PmaxUploadStatusJson;
};

export type GoogleAdCampaignInternalStatus = "Draft" | "Loaded";

// Internal representation of a Performance Max campaign
export type GoogleAdCampaignInternal = {
  id: string;
  name: string;
  locationTarget?: string;
  radius?: number;
  radiusUnit?: "km" | "mi";
  excludeLocations?: string[];
  startDate?: string;
  endDate?: string;
  totalBudget?: string;
  dailyBudget?: string;
  rulesetName?: string;
  urlExclusions?: string[];
  lastEditedAt?: number;
  lastEditedBy?: string;
  createdAt?: number;
  loadStatus?: GoogleAdCampaignInternalStatus;
  assetGroupIds?: string[];
};

export type GoogleAdCampaignInternalForm = Omit<
  GoogleAdCampaignInternal,
  | "assetGroupIds"
  | "loadStatus"
  | "lastUpdatedAt"
  | "lastUpdatedBy"
  | "createdAt"
>;

export interface IVideoAsset {
  videoUrl: string;
  title: string;
  description?: string;
  privacyStatus?: "public" | "private" | "unlisted";
}

export interface IAssetGroup {
  id: string; // only used on the client side, <feed id>_<name>
  feedId: string;
  name: string;
  feedStatus: GoogleAdsFeedStatusType;
  feedName?: string;
  pmaxStatus: GoogleAdsPmaxStatusType;
  adAccounts?: AdAccountWithDealerName[];
  campaigns?: NameID[];
  pmaxAssetGroupId?: string[];
  uploads: {
    landscapeImages?: UploadFile<IFile>[];
    squareImages?: UploadFile<IFile>[];
    portraitImages?: UploadFile<IFile>[];
    squareLogos?: UploadFile<IFile>[];
    landscapeLogos?: UploadFile<IFile>[];
  };
  videoAssets?: IVideoAsset[];
  multiInputAsset: {
    headlines?: string[];
    longHeadlines?: string[];
    descriptions?: string[];
    youtubeVideoUrls?: string[]; // leaving this for direct youtube url upload
  };
  finalUrl: string;
  businessName: string;
  callToAction: GoogleAdsCallToAction;
  displayPath: [string | undefined, string | undefined];
  createdBy?: string;
  createdAt?: number;
  lastUpdatedBy?: string;
  lastUpdatedAt?: number;
}

export type EditableAssetGroupKey =
  | Extract<
      keyof IAssetGroup,
      "finalUrl" | "businessName" | "callToAction" | "audienceSignal" | "name"
    >
  | ("displayPath0" | "displayPath1");

export interface IAssetGroupDetail {
  id: string;
  pmaxAssetGroupId: string;
  campaign: NameID;
  adAccount: AdAccountWithDealerName;
  status: GoogleAdsFeedStatusType;
  createdAt: number;
  lastUpdatedAt: number;
}

export type GoogleAdsFeedStatusType =
  | "Draft"
  | "Up to date"
  | "Deleted from feed"
  | "Error";

export type GoogleAdsPmaxStatusType = "Loaded" | "Never Loaded" | "Paused";

export type NewAssetGroupArgs = {
  assetGroup: Partial<IUpdatedAssetGroup>;
  user: { email: string };
  mode: "new";
};

export type CreatePmaxCampaignArgs = {
  campaigns: GoogleAdCampaignInternal[];
  user: User;
};

export type UploadPmaxCampaignsArgs = {
  user: User;
  campaignIds: string[];
  adAccount: { id: string; dealerName: string; dealerOem?: string };
};

export type CampaignWithResourceName = {
  campaign: {
    name: string;
    assetGroupIds: string[];
    id: string;
  };
  resourceName: string;
};

export type UploadPmaxAssetGroupsArgs = {
  user: User;
  campaignsWithResourceNames: CampaignWithResourceName[];
  adAccount: { id: string; dealerName: string; dealerOem?: string };
};

export type UpdatePmaxCampaignArgs = {
  campaign: GoogleAdCampaignInternal;
  user: User;
};

export interface IUpdatedAssetGroup extends Omit<IAssetGroup, "uploads"> {
  uploads: Uploads;
}

export type Uploads = {
  landscapeImages?: UpdatedUpload;
  squareImages?: UpdatedUpload;
  portraitImages?: UpdatedUpload;
  squareLogos?: UpdatedUpload;
  landscapeLogos?: UpdatedUpload;
};
export interface IAssetGroupResponse
  extends Omit<IAssetGroup, "uploads" | "multiInputAsset" | "displayPath"> {
  displayPath: string;
  landscapeImages?: IFile[];
  squareImages?: IFile[];
  portraitImages?: IFile[];
  squareLogos?: IFile[];
  landscapeLogos?: IFile[];
  videos: IFile[];
  videoAssets: IVideoAsset[];
  headlines: string[];
  longHeadlines: string[];
  descriptions: string[];
  youtubeVideoUrls: string[];
}

export type URLExclusion = {
  name: string;
  exclusions: string[];
};

export type UpdatedUpload = {
  deleted: UploadFile<IFile>[];
  added: UploadFile<IFile>[];
};

export enum GoogleAdsCallToAction {
  AUTOMATED = "Automated (recommended)",
  LEARN_MORE = "Learn More",
  GET_QUOTE = "Get Quote",
  APPLY_NOW = "Apply Now",
  SIGN_UP = "Sign Up",
  CONTACT_US = "Contact Us",
  SUBSCRIBE = "Subscribe",
  DOWNLOAD = "Download",
  BOOK_NOW = "Book Now",
  SHOP_NOW = "Shop Now",
}

interface IAudienceSignal {
  audience: {
    id: string;
    name: string;
    resourceName: string;
    status: string;
    dimensions: any[];
  };
}

export interface IAudienceSignalResponse {
  results?: IAudienceSignal[];
  fieldMask: string;
}

export interface IGoogleAdAccountResponse {
  results?: IGoogleAdAccountResult[];
  fieldMask: string;
}

export type GeoTargetValue = { id: string; name: string; resourceName: string };
export type GoogleGeoTargetsResponse = GeoTargetValue[];

interface IGoogleAdAccountResult {
  customerClient: {
    id: string;
    descriptiveName: string;
    manager: boolean;
    resourceName: string;
  };
}

export interface YoutubeUploadResponse {
  data: {
    etag: string;
    id: string;
    kind: "youtube#video";
    player: {
      embedHtml: string;
    };
    snippet: {
      categoryId: string;
      channelId: string;
      channelTitle: string;
      description: string;
      publishedAt: string;
    };
  };
}

export interface IGoogleCampaignResponse {
  results?: IGoogleCampaignResult[];
  fieldMask: string;
}

interface IGoogleCampaignResult {
  campaign: {
    id: string;
    name: string;
    status: string;
    resourceName: string;
  };
}

export type GoogleAdAccount = {
  id: string;
  name: string;
  manager: boolean;
  campaigns?: GoogleAdCampaign[];
};

export type GoogleAdCampaign = {
  id: string;
  name: string;
  status: string;
  startDate?: string;
  endDate?: string;
  createdAt?: number;
  lastEditedBy?: string;
  lastEditedAt?: number;
  type?: string;
  budget?: number;
  loadStatus?: string;
  assetGroups?: IAssetGroup[];
  resourceName: string;
};

type IAdExportSelectionOption = "all" | "selected";

export interface IAdExportForm {
  rowSelection: IAdExportSelectionOption;
  columnSelection: IAdExportSelectionOption;
  selectedRowIds: string[];
  selectedColumnNames: string[];
  destination: "googleSheets" | "csv" | "excel" | "zip";
}

export interface IExportDataProps {
  filenamePrefix: string;
  uuid: string;
  formData: IAdExportForm;
  colDefsForExport: ITableColumn<IAd>[];
  ads: IAd[];
  selectedAdIds: string[];
  allRowsSelected: boolean;
  allColsSelected: boolean;
}

export interface IExportSignedUrlResponse {
  uuid: string;
  url: string;
}
export interface IExportGetFileUrlResponse {
  uuid: string;
  status: "GENERATING" | "DONE";
  url?: string;
}

export interface IExportDataPayload {
  filename: string;
  destination: string;
  columns: string[];
  data: Record<string, string>[];
  uuid: string;
}

export interface IAdExportRequest {
  uuid: string;
  type: "file" | "googleSheets";
}

export type ImageAssetType =
  | "Landscape Images"
  | "Square Images"
  | "Portrait Images"
  | "Square Logos"
  | "Landscape Logos";

export type MultiInputAssetType =
  | "Headlines"
  | "Long Headlines"
  | "Descriptions"
  | "Youtube Video URLs";

export type UpdateAssetGroupsResponse = {
  results?: IAssetGroup[];
  fieldMask: string;
};

export interface ImportStartExecutionResponseData {
  executionArn: string;
  startDate: string;
}

export interface ImportDescribeExecutionError {
  errorType?: string;
  errorMessage?: string;
  trace?: string[] | string;
}

export interface ImportDescribeExecutionOutput {
  location?: string;
  type?: string;
  thumbnailUrl?: string;
  assetId?: string;
  error?: {
    Error: string;
    Cause: string;
  };
}

export interface ImportDescribeExecutionResponseData
  extends ImportStartExecutionResponseData {
  status: ExecutionStatus;
  output: ImportDescribeExecutionOutput;
  stopDate: string;
}

type ExecutionStatus =
  | "RUNNING"
  | "SUCCEEDED"
  | "FAILED"
  | "TIMED_OUT"
  | "ABORTED";

export type ImportStartExecutionResult = {
  result: {
    executionArn: string;
  } | null;
  error: {
    message: string;
  } | null;
};

export type AdLibraryTableColumn<AdType = IAd> = ITableColumnURL<
  IAdFields,
  AdType
>;

export type AdLibraryField = Field<IAd>;

export type EverythingAdHtml = {
  slug: string;
  /** stringified everything ad */
  data: IInstantExperience;
  createdAt: string;
  createdBy: string;
  updatedAt?: string;
  updatedBy?: string;
  /** client instance name */
  instance: string;
  /** environment name */
  environment: string;
  /** selected account (aka dealer and store) */
  account: string;
  gtmId: string | undefined;
};

export type IsEverythingAdHtmlSlugAvailableResponse = {
  isAvailable: boolean;
};

export type YoutubeChannel = {
  id: string;
  title: string;
  description: string;
  customUrl: string;
};

export type YoutubeAccounts = {
  names: string[];
  emails: string[];
};

export const dateFormat = "yyyy-MM-dd";
