import { useCallback, useEffect, useState } from "react";
import { fetchBackgroundThemeImages } from "screens/assetBuilder/offers/build/buildSteps/ImageSelection.utils";
import {
  getCAMThumbnail,
  useCAMHook,
  useCAMSearch,
} from "shared/components/media";
import { IConfig } from "shared/types/configuration";
import { IArtboard, ITemplate } from "shared/types/designStudio";

type ThemeImage = {
  id: string;
  name: string;
  url: string;
};

export const useThemeImages = (
  template: ITemplate | null,
  config?: IConfig,
) => {
  const searchMedia = useCAMHook(useCAMSearch)();
  const [themeImages, setThemeImages] = useState<ThemeImage[]>();

  const fetchThemeImages = useCallback(
    (
      config: IConfig,
      artboard: IArtboard,
    ): Promise<ThemeImage[] | undefined> => {
      // WebDAM themes
      if (searchMedia) {
        const params = {
          width: String(artboard.width),
          height: String(artboard.height),
          keywords: "theme",
        };

        return searchMedia(params).then(data => {
          return data.items.flatMap(asset => {
            const thumb = getCAMThumbnail(asset);
            if (thumb) {
              return {
                id: asset.id,
                name: asset.name,
                url: thumb.url,
              };
            }

            return [];
          });
        });
      }

      // Legacy themes
      return fetchBackgroundThemeImages({
        config,
        ids: artboard.theme_images!,
      });
    },
    [searchMedia],
  );

  useEffect(() => {
    const { artboard, type } = template ?? {};

    if (config && artboard && type !== "lifestyle") {
      fetchThemeImages(config, artboard).then(setThemeImages);
    }
  }, [config, template, fetchThemeImages]);

  return { themeImages };
};
