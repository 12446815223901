import { UploadFileStatus } from "antd/lib/upload/interface";
import { IAssetGroup, IAssetGroupResponse } from "shared/types/adLibrary";
import { IFile } from "shared/types/uploadAsset";

export const toAssetGroup = (
  assetGroupRes: IAssetGroupResponse,
): IAssetGroup => {
  const {
    landscapeImages,
    squareImages,
    portraitImages,
    squareLogos,
    landscapeLogos,
    headlines,
    longHeadlines,
    youtubeVideoUrls,
    descriptions,
    displayPath,
    ...rest
  } = assetGroupRes;

  const [displayPath0, displayPath1] = displayPath?.split("/") ?? [
    undefined,
    undefined,
  ];
  return {
    ...rest,
    id: `${rest.feedId}_${rest.name}`,
    displayPath: [displayPath0, displayPath1],
    uploads: {
      landscapeImages: toUploadFiles(landscapeImages),
      squareImages: toUploadFiles(squareImages),
      portraitImages: toUploadFiles(portraitImages),
      squareLogos: toUploadFiles(squareLogos),
      landscapeLogos: toUploadFiles(landscapeLogos),
    },
    multiInputAsset: {
      headlines,
      longHeadlines,
      descriptions,
      youtubeVideoUrls,
    },
  };
};

const toUploadFiles = (imgAssets?: IFile[]) =>
  imgAssets?.map(imgAsset => ({
    uid: imgAsset.id,
    name: imgAsset.name,
    status: "done" as UploadFileStatus,
    url: imgAsset.url,
    response: imgAsset,
  })) || [];

const assetGroupSearchableKeys = [
  "name",
  "id",
  "feedStatus",
  "pmaxStatus",
  "feedName",
  "adAccounts",
  "campaigns",
] as const;

export const searchInAssetGroup = (assetGroup: IAssetGroup, search: string) => {
  const searchLower = search.toLowerCase();
  return assetGroupSearchableKeys.some(key => {
    const value = assetGroup[key];
    if (Array.isArray(value)) {
      return value.some(item =>
        item?.name?.toLowerCase().includes(searchLower),
      );
    }
    return value?.toLowerCase().includes(searchLower);
  });
};
