import { useCallback, useRef } from "react";
import { Button, Input, message, Modal, Typography } from "antd";
import { useMemo } from "react";
import { useState } from "react";
import { LinkOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CSSProperties } from "react";

export const isSharablePathname = (pathname: string) => {
  const sharablePathnames = ["/everythingads"];

  return sharablePathnames.some(url => pathname.startsWith(url));
};

// defining styles here ceause this component is defined in custom hook.
const styles: Record<string, CSSProperties> = {
  "modal-title": {
    fontWeight: "bold",
    color: "black",
  },
  "share-link-input-title": {
    fontWeight: "bold",
    color: "black",
    marginTop: 8,
  },
};

type Args = {
  title?: string;
  link?: string;
};

export default (args: Args) => {
  const { title, link } = args;
  const [showModal, toggleModal] = useState<boolean>(false);
  const inputRef = useRef<Input>(null);

  useEffect(() => {
    if (!link) return;

    message.destroy();

    toggleModal(true);
  }, [link, toggleModal]);

  const toggle = useCallback(
    (show: boolean) => {
      toggleModal(show);
    },
    [toggleModal],
  );
  useEffect(() => {
    if (!showModal) return;

    inputRef.current?.focus();
    inputRef.current?.select();
  }, [showModal, inputRef]);

  const onCopyLinkButtonClick = useCallback(() => {
    message.info({ content: "Share URL copied to clipboard." });
  }, []);

  const ShareLinkModal = useMemo(() => {
    return (
      <Modal
        visible={showModal}
        title={<div style={styles["modal-title"]}>{title || "Share URL"}</div>}
        onCancel={() => toggle(false)}
        footer={
          <CopyToClipboard text={link || ""}>
            <Button
              type="link"
              icon={<LinkOutlined />}
              onClick={onCopyLinkButtonClick}
            >
              Copy link
            </Button>
          </CopyToClipboard>
        }
      >
        <div>
          <div>
            Share this URL for another viewer to see a preview of these ads. It
            has been copied to your clipboard.
          </div>
          <div>
            <Typography style={styles["share-link-input-title"]}>
              Share URL
            </Typography>
            <Input ref={inputRef} value={link} />
          </div>
        </div>
      </Modal>
    );
  }, [showModal, title, toggle, inputRef, onCopyLinkButtonClick, link]);

  return {
    toggle,
    Modal: ShareLinkModal,
  };
};
