import HttpClient from "./httpClient";
import { IConfig } from "shared/types/configuration";
import { ILauncherData } from "shared/types/assetBuilder";

export default ({ config }: { headers: any; config: IConfig }) => ({
  updateWebInt: async (webInt: ILauncherData) => {
    const url = config.services.assetBuilder.updateWebIntUrl;
    return HttpClient.put<ILauncherData>(url, webInt);
  },
});
