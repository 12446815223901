import { Col, message, Row } from "antd";
import { memo, ReactElement, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import API from "services";

import { DisclosureType, IDisclosure, Modes } from "shared/types/legalLingo";
import {
  ToolbarTable,
  ToolbarButton,
} from "shared/components/toolbarTable/ToolbarTable";
import { useDatadog } from "shared/hooks/useDatadog";

import DisclosuresTable from "screens/legalLingoV2/DisclosuresTable";
import SummaryToolbar from "shared/components/SummaryToolbar";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { LayoutType } from "shared/types/layout";
import { IndustryType } from "shared/types/shared";

import { filterBySearch } from "utils/helpers.toolbar";

import { useFetchDisclosures } from "shared/hooks/legalLingo/useFetchDisclosures";

import { getEnvVar, isEnvVarEquals } from "utils/helpers";

import ConfirmModal from "./legalLingoV2/disclosuresTable/ConfirmModal";
import LegalEditDisclosure from "./legalLingoV2/EditDisclosure";
import NewEditDrawer from "./legalLingoV2/NewEditDrawer";
import { validateDisclosures } from "./legalLingoV2/utils/disclosureValidationUtils";

import "./LegalLingoV2.scss";

message.config({ maxCount: 1 });

type ModalType = "Delete" | "Duplicate" | null;
const sortOptions = ["updatedAt", "desc"];
const PATH_NEW: string = "/disclosures/new";

const LegalLingo = (): ReactElement => {
  const config = useAppSelector(state => state.configuration.config);

  const isNewDisclosureEnabled = !isEnvVarEquals("CLIENT", "nu");
  const industry = getEnvVar("INDUSTRY");

  const [displaySelectedDisclosures, setDisplaySelectedDisclosures] =
    useState<boolean>(false);
  const [clientType, setClientType] = useState<IndustryType>(
    (industry as IndustryType) || "retail",
  );
  const [disclosures, setDisclosures] = useState<IDisclosure[]>([]);
  const [displayColumn, setDisplayColumn] = useState<boolean>(false);
  const [drawerMode, setDrawerMode] = useState<Modes | null>(null);
  const [expand, setExpand] = useState<boolean>(false);
  const [modalAction, setModalAction] = useState<ModalType>(null);
  const [paramDrawerOpen, setParamDrawerOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [sortingOrder, setSortingOrder] = useState<string[]>(sortOptions);
  const [selectedDisclosures, setSelectedDisclosures] =
    useState<IDisclosure[]>(disclosures);
  const [nameDisclosureEdit, setNameDisclosureEdit] = useState<
    string | undefined
  >("");

  const navigate = useNavigate();
  const { pathname: pathName } = useLocation();

  const { disclosures: initialDisclosures, isLoading } = useFetchDisclosures();

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>(
    selectedDisclosures?.map(disclosure => disclosure.name) ??
      selectedDisclosures ??
      [],
  );

  useDatadog();
  const isLegalLingoNewPage = pathName === PATH_NEW && !isNewDisclosureEnabled;

  // TODO: refactoring to load the component after finish loading the disclosures
  // so we could remove this useEffect
  useEffect(() => {
    !!initialDisclosures && setDisclosures(initialDisclosures);
  }, [initialDisclosures]);

  useEffect(() => {
    !selectedRowKeys.length && setDisplaySelectedDisclosures(false);

    const discToSelect = disclosures.filter(disclosure =>
      selectedRowKeys.includes(disclosure.name),
    );

    setSelectedDisclosures(discToSelect);
  }, [disclosures, selectedRowKeys]);

  const disclosureFilter = (disclosure: DisclosureType): boolean => {
    const { name, store, location, oem, condition } = disclosure;

    const searchValueLowerCase = searchValue.toLowerCase();
    const nameLowerCase = name?.toLowerCase();
    const storeLowerCase = store?.map(storeItem => storeItem.toLowerCase());
    const locationLowerCase = location?.map(item => item.toLowerCase());
    const oemLowerCase = oem?.map(oemItem => oemItem.toLowerCase());
    const conditionLowerCase = condition?.map(item => item.toLowerCase());

    return (
      nameLowerCase?.includes(searchValueLowerCase) ||
      storeLowerCase?.includes(searchValueLowerCase) ||
      locationLowerCase?.includes(searchValueLowerCase) ||
      oemLowerCase?.includes(searchValueLowerCase) ||
      conditionLowerCase?.includes(searchValueLowerCase)
    );
  };

  const filterRecords = (
    records: DisclosureType[],
    recordFilter: (disclosure: DisclosureType) => boolean | undefined,
  ) => {
    return records
      .filter(recordFilter)
      .filter(disclosure => disclosureFilter(disclosure));
  };

  const filteredRecords = filterRecords(disclosures, disclosureFilter);

  const selectedRows = filteredRecords.filter(({ name }) =>
    selectedRowKeys.includes(name),
  );

  const onEditClick = (disclosure: DisclosureType) =>
    navigate(`/legal-lingo-v2/edit/${disclosure.name.replace(/ /g, "-")}`);

  const onNewClick = () => navigate(`/legal-lingo-v2/new`);

  const toolbarContents: ToolbarButton = {
    New: {
      disabled: isLegalLingoNewPage,
      onClick: () => onNewClick(),
    },
    Edit: {
      disabled: selectedRows.length !== 1 || isLegalLingoNewPage,
      onClick: () => selectedRows?.length && onEditClick(selectedRows[0]),
    },
    Duplicate: {
      disabled: selectedRows.length !== 1 || isLegalLingoNewPage,
      onClick: () => onDuplicate(),
    },
    Delete: {
      disabled: !selectedRows.length || isLegalLingoNewPage,
      onClick: () => setModalAction("Delete"),
    },
    Params: {
      disabled: selectedRows.length !== 1 || isLegalLingoNewPage,
      onClick: () => onEditParams(),
    },
    Column: {
      onClick: () => setDisplayColumn(!displayColumn),
      extraInfo: { iconOnly: true },
    },
    Expand: {
      onClick: () => setExpand(!expand),
    },
    Sort: {
      onClick: () => null,
      extraInfo: [
        {
          value: "updatedAt",
          label: "Last Edit",
          children: [
            {
              value: "desc",
              label: "Descending",
            },
            {
              value: "asc",
              label: "Ascending",
            },
          ],
        },
        {
          value: "createdAt",
          label: "Created Date",
          children: [
            {
              value: "desc",
              label: "Descending",
            },
            {
              value: "asc",
              label: "Ascending",
            },
          ],
        },
      ],
    },
  };

  const sortedDisclosures = filterBySearch(filteredRecords, searchValue).sort(
    (a: IDisclosure, b: IDisclosure) => {
      const sortBy = sortingOrder[0] as keyof IDisclosure;
      const orderBy = sortingOrder[1];
      return orderBy === "asc"
        ? (a[sortBy] as number) - (b[sortBy] as number)
        : (b[sortBy] as number) - (a[sortBy] as number);
    },
  );

  const displayedDisclosures =
    displaySelectedDisclosures && selectedRowKeys.length
      ? sortedDisclosures.filter(disc => selectedRowKeys.includes(disc.name))
      : sortedDisclosures;

  const onEditParams = () => {
    setDrawerMode("edit");
    setParamDrawerOpen(!paramDrawerOpen);
  };

  const onDuplicate = () => {
    setDrawerMode("duplicate");
    setParamDrawerOpen(!paramDrawerOpen);
  };

  const onConfirmDuplicate = async (newDisclosure: IDisclosure) => {
    const newDisclosures = [newDisclosure, ...disclosures];
    setDisclosures(newDisclosures);

    message.success(
      `The ${selectedRows.length} disclosure${
        selectedRows.length > 1 ? "s were" : " was"
      } duplicated successfully`,
    );

    setModalAction(null);
    setSelectedRowKeys([]);
    setParamDrawerOpen(false);

    try {
      const currentDisclosures =
        await API.services.legalLingoV2.getDisclosures();

      const newDuplicateDisclosures = (
        currentDisclosures as { result: IDisclosure[] }
      ).result.filter(({ name }) => name !== newDisclosure.name);

      setDisclosures([newDisclosure, ...newDuplicateDisclosures]);
    } catch (error) {
      message.error((error as Error).message);
    }
  };

  const onConfirmDelete = async () => {
    for await (const disclosure of selectedDisclosures) {
      API.services.legalLingoV2.deleteDisclosure(disclosure);
    }

    setDisclosures(
      disclosures.filter(({ name }) => !selectedRowKeys.includes(name)),
    );

    message.success(
      `The ${selectedRows.length} disclosure${
        selectedRows.length > 1 ? "s" : ""
      } were deleted successfully`,
    );

    setModalAction(null);
    setSelectedDisclosures([]);
    setSelectedRowKeys([]);
  };

  const handleNewDisclosure = async (newDisclosure: IDisclosure) => {
    navigate("/legal-lingo-v2");

    const newDisclosures = [newDisclosure, ...disclosures];
    setDisclosures(newDisclosures);
  };

  const handleUpdatedDisclosure = async (updatedDisclosure: IDisclosure) => {
    navigate("/legal-lingo-v2");

    const nameChanged = updatedDisclosure.name !== nameDisclosureEdit;

    const newDisclosures = disclosures.map(record => {
      return (!nameChanged && record.name === updatedDisclosure.name) ||
        (nameChanged && record.name === nameDisclosureEdit)
        ? { ...record, ...updatedDisclosure }
        : record;
    });

    setDisclosures(newDisclosures);
    setSelectedDisclosures([]);
    setSelectedRowKeys([]);
  };

  return (
    <Routes>
      <Route
        index
        element={
          <div className="legal-lingo-root-container">
            <ConfirmModal
              visible={!!modalAction}
              onCancel={() => setModalAction(null)}
              onOk={onConfirmDelete}
              modalAction={modalAction}
            >
              {`Are you sure you want to ${modalAction?.toLowerCase()} ${
                selectedRows.length
              } disclosure${selectedRows.length > 1 ? "s" : ""}?`}
            </ConfirmModal>

            <Row>
              <Col span={24}>
                <ToolbarTable
                  layout={LayoutType.TABLE}
                  toolbarContents={toolbarContents}
                  clientType={clientType}
                  searchPlaceholder="Search"
                  searchValue={searchValue}
                  sortingOrder={sortingOrder}
                  onSearch={setSearchValue}
                  onSortChange={setSortingOrder}
                  titleTooltip="Search by any disclosure information"
                />
                <SummaryToolbar
                  selectedCount={selectedRowKeys.length}
                  totalCount={sortedDisclosures.length}
                  displaySelected={displaySelectedDisclosures}
                  setDisplaySelected={setDisplaySelectedDisclosures}
                />
                <DisclosuresTable
                  disclosures={displayedDisclosures}
                  expand={expand}
                  displayColumn={displayColumn}
                  clientType={clientType}
                  setClientType={setClientType}
                  setDisplayColumn={setDisplayColumn}
                  selectedRowKeys={selectedRowKeys}
                  setSelectedRowKeys={setSelectedRowKeys}
                  onEditClick={onEditClick}
                  isLoading={isLoading}
                  onDisclosureSelect={setNameDisclosureEdit}
                />
                {paramDrawerOpen &&
                  selectedRows.length === 1 &&
                  !!drawerMode && (
                    <NewEditDrawer
                      toggleAddNewEditDrawer={setParamDrawerOpen}
                      showAddNewEditDrawer={paramDrawerOpen}
                      closeDrawer={() => setParamDrawerOpen(false)}
                      onDisclosureDuplicated={onConfirmDuplicate}
                      onDisclosureUpdated={(disclosure: IDisclosure): void => {
                        const nameChanged =
                          disclosure.name !== selectedRows[0].name;

                        const updatedDisclosures = disclosures.map(record => {
                          return (!nameChanged &&
                            record.name === disclosure.name) ||
                            (nameChanged &&
                              record.name === selectedRows[0].name)
                            ? { ...record, ...disclosure }
                            : record;
                        });

                        setDisclosures(updatedDisclosures);
                        setSelectedDisclosures([]);
                        setSelectedRowKeys([]);
                        setParamDrawerOpen(false);
                      }}
                      disclosure={selectedRows[0]}
                      mode={drawerMode}
                      config={config}
                      onDisclosureCreated={() => null}
                      validation={(disclosureEdited: IDisclosure) =>
                        validateDisclosures(
                          drawerMode,
                          disclosureEdited,
                          disclosures,
                          selectedRows[0].name,
                        )
                      }
                    />
                  )}
              </Col>
            </Row>
          </div>
        }
      />

      <Route
        path="new"
        element={
          <LegalEditDisclosure
            onCreateDisclosure={handleNewDisclosure}
            validation={(disclosureNew: IDisclosure) =>
              validateDisclosures("new", disclosureNew, disclosures)
            }
          />
        }
      />

      <Route
        path="edit/:editId"
        element={
          <LegalEditDisclosure
            disclosureName={nameDisclosureEdit}
            onUpdateDisclosure={handleUpdatedDisclosure}
            validation={(disclosureEdited: IDisclosure) =>
              validateDisclosures(
                "edit",
                disclosureEdited,
                disclosures,
                nameDisclosureEdit,
              )
            }
            onClose={() => navigate("/legal-lingo-v2")}
          />
        }
      />
    </Routes>
  );
};

export default memo(LegalLingo);
