import {
  AdLoadSession,
  AdLoadStartExecPayload,
  AdLoadStatusItem,
} from "screens/adLibrary/facebookUtils/adProto.types";
import { IConfig } from "shared/types/configuration";
import HttpClient from "./httpClient";
import { getResultIfOk } from "./adLibrary";

export default ({ config }: { config: IConfig }) => ({
  async startExecution(payload: AdLoadStartExecPayload) {
    const { executionsUrl } = config.services.adLoad;

    const result = await HttpClient.post<{
      execName: string;
      sessionId: string;
      payloadLocation: string;
    }>(executionsUrl, payload);

    return getResultIfOk(result);
  },
  async trackExecution(execName: string) {
    const { executionsUrl } = config.services.adLoad;

    const result = await HttpClient.get<{ statuses: AdLoadStatusItem[] }>(
      `${executionsUrl}/${execName}`,
    );

    return getResultIfOk(result).statuses;
  },
  async saveSession(payload: Omit<AdLoadSession, "updatedAt">) {
    const { sessionsUrl } = config.services.adLoad;

    const result = await HttpClient.put<AdLoadSession>(
      `${sessionsUrl}/${payload.id}`,
      payload,
    );

    return getResultIfOk(result);
  },
  async getSession(sessionId: string) {
    const { sessionsUrl } = config.services.adLoad;

    const result = await HttpClient.get<AdLoadSession>(
      `${sessionsUrl}/${sessionId}`,
    );

    return getResultIfOk(result);
  },
});
