import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import useDeepEffect from "shared/hooks/useDeepEffect";
import {
  ISavedOrderState,
  TOfferListQueryParameters,
} from "shared/types/assetBuilder";
import { parseSearchParams } from "../Select.utils";
import { TOfferListSection } from "./useOfferList";

type Args = {
  feedId: string;
  searchBy?: string;
  savedOrder: ISavedOrderState;
  sectionKey: TOfferListSection;
  filterField?: string;
};
export default (
  args: Args,
): [
  TOfferListQueryParameters,
  Dispatch<SetStateAction<TOfferListQueryParameters>>,
] => {
  const {
    searchBy,
    savedOrder: {
      selectedOffers,
      selectedOrder: { dealer_code: dealerCode },
    },
    feedId,
    sectionKey,
    filterField,
  } = args;

  const defaultFetchParams: TOfferListQueryParameters = useMemo(
    () => ({
      vehicleCondition: "All",
      sortBy: [
        {
          key: "msrp",
          order: "asc",
        },
      ],
      dealerCode,
      feedId,
      ...(sectionKey === "selected"
        ? { vins: selectedOffers?.map(o => o.offerData.vin) || [] }
        : {}),
    }),
    [dealerCode, feedId, selectedOffers, sectionKey],
  );

  const [fetchOfferListParams, setFetchOfferListParams] =
    useState<TOfferListQueryParameters>(defaultFetchParams);

  const location = useLocation();
  useEffect(() => {
    if (!location.search) return;

    const setParamsFromSearch = () => {
      const params = new URLSearchParams(location.search);
      const parsedSearchParams = parseSearchParams(params);
      const searchParams: TOfferListQueryParameters | null = parsedSearchParams
        ? {
            ...parsedSearchParams,
            currentPage: 1,
          }
        : null;
      if (!searchParams) return;

      setFetchOfferListParams(prev => ({
        ...searchParams,

        // NOTE: below parameters are not in the query string. So we need to persist previous values.
        feedId: prev.feedId,
        dealerCode: prev.dealerCode,
        vins: prev.vins,
      }));
    };

    setParamsFromSearch();
  }, [location.search]);

  useEffect(() => {
    setFetchOfferListParams(prev => ({ ...prev, searchBy }));
  }, [searchBy]);

  useDeepEffect(() => {
    if (!filterField) return;

    const [filterFieldFeedId, filterFieldKeyValue] = filterField.split("_"); // <feed id>_<filter field>-<filter field value>
    const [key, value] = filterFieldKeyValue?.split("-") || [];
    if (!key || !value || filterFieldFeedId !== feedId) {
      setFetchOfferListParams(prev => ({
        ...prev,
        filterField: undefined,
      }));
      return;
    }

    setFetchOfferListParams(prev => ({
      ...prev,
      filterField: filterFieldKeyValue,
    }));
  }, [filterField, feedId]);

  return [fetchOfferListParams, setFetchOfferListParams];
};
