import { Button } from "antd";
import { IssueGroup } from "../validationUtils";

type Props = {
  toggleInstantExperiencesToView: (
    field: IssueGroup,
    idsToSelect: string[],
  ) => void;
  elementIdsWithIssues: string[];
  group: IssueGroup;
  selectedGroupToView?: IssueGroup;
};

function ViewIssuesButton({
  toggleInstantExperiencesToView,
  elementIdsWithIssues,
  selectedGroupToView,
  group,
}: Props) {
  const isSelectedGroup = selectedGroupToView === group;
  return (
    <Button
      type="link"
      onClick={() =>
        toggleInstantExperiencesToView(group, elementIdsWithIssues)
      }
      style={{
        fontWeight: isSelectedGroup ? "bold" : "normal",
      }}
    >
      View {elementIdsWithIssues.length} issue
      {elementIdsWithIssues.length > 1 ? "s" : ""}
    </Button>
  );
}

export default ViewIssuesButton;
