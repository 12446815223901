import { useState } from "react";
import { IAd } from "shared/types/adLibrary";
import { filterBySearch } from "utils/helpers.toolbar";
import { IAdValidations } from "../adValidationUtils";
import AdImportToolbar from "./adImportTable/AdImportToolbar";
import { getIssues } from "./adImportTableUtils";
import AdImportTableContainer from "./adImportTable/AdImportTableContainer";

interface Props {
  ads: IAd[];
  setAd: (adId: string, ad: IAd) => void;
  setAds: (ads: IAd[]) => void;
  selectedAdIds: string[];
  setSelectedAdIds: (ads: string[]) => void;
  adValidations?: IAdValidations[];
}

const AdImportTable = ({
  ads,
  setAd,
  setAds,
  selectedAdIds,
  setSelectedAdIds,
  adValidations,
}: Props) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [displayColumn, setDisplayColumn] = useState<boolean>(false);
  const [adsToView, setAdsToView] = useState<string[]>([]);
  const [selectedIssuesGroup, setSelectedIssuesGroup] = useState<string>();
  const validationErrors = adValidations?.filter(
    validation => validation.hasErrors,
  );

  const filteredAds = filterBySearch(
    ads.filter(ad => !selectedIssuesGroup || adsToView.includes(ad.id)),
    searchValue,
  );

  const toggleAdsToView = (field: string, adIdsToSelect: string[]) => {
    const isSelectedGroup = field === selectedIssuesGroup;
    if (isSelectedGroup) {
      setSelectedIssuesGroup(undefined);
      setAdsToView([]);
    } else {
      setSelectedIssuesGroup(field);
      setAdsToView(adIdsToSelect);
    }
  };

  const issues = getIssues(
    validationErrors,
    selectedIssuesGroup,
    toggleAdsToView,
  );

  return (
    <div style={{ width: "100%" }}>
      <AdImportToolbar
        issues={issues}
        searchValue={searchValue}
        onSearch={setSearchValue}
        setDisplayColumn={setDisplayColumn}
      />

      <AdImportTableContainer
        ads={filteredAds}
        setAd={setAd}
        setAds={setAds}
        displayColumn={displayColumn}
        setDisplayColumn={setDisplayColumn}
        selectedRowKeys={selectedAdIds}
        setSelectedRowKeys={setSelectedAdIds}
        stickyColumns={["Name"]}
        validationErrors={validationErrors}
      />
    </div>
  );
};

export default AdImportTable;
