import { useEffect, useMemo, useState } from "react";
import {
  InfoCircleFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Modal,
  Col,
  Row,
  Collapse,
  Button,
  Typography,
  Space,
  message,
  Empty,
  Tooltip,
} from "antd";

import moment from "moment";

import AdLoadStatusTable from "../shared/AdLoadStatusTable";
import CollapseHeader from "../adLoad/adLoadModal/CollapseHeader";

import { useLocation } from "react-router-dom";
import { useQueryClient } from "react-query";

import { useFetchSessions } from "shared/hooks/adLibrary/adHistory/useFetchSessions";
import { useFetchS3ObjectVersions } from "shared/hooks/useFetchS3ObjectVersions";
import { useFetchVersionOfJsonFromS3 } from "shared/hooks/useFetchVersionOfJsonFromS3";

import { returnAdNameToDisplay } from "../adLoad/utils";

import { IAd, IAdHistorySession, IAdLoad } from "shared/types/adLibrary";
import { BucketFor, Module } from "shared/types/shared";

import { dateFormat } from "shared/constants/dataManagement";

import "./../adLoad/AdLoadModal.scss";

type SessionHistoryModalProps = {
  sessionId?: string;
  onClose: () => void;
};

const SessionHistoryModal = (props: SessionHistoryModalProps) => {
  const location = useLocation();
  const queryClient = useQueryClient();

  const currentSession: IAdHistorySession | undefined = (
    (queryClient.getQueryData(["adHistorySessions", props.sessionId]) ??
      []) as IAdHistorySession[]
  )?.[0];

  const splitUrl = location.search.split("?session_id=")?.[1];
  const idFromUrl = splitUrl?.split("&")?.[0];

  const {
    refetch: getSessionData,
    isFetching,
    isLoading,
    isError,
    error,
  } = useFetchSessions({
    queryParams: {
      id: idFromUrl || props.sessionId,
    },
    enabled: false,
  });

  const versionQueryParams = {
    bucket_for: "session" as BucketFor,
    module: "adLoad" as Module,
    url: currentSession?.jsonUrl || "",
  };

  const {
    data: sessionVersions,
    isFetching: isFetchingVersions,
    isLoading: isLoadingVersions,
  } = useFetchS3ObjectVersions({
    enabled: !!props.sessionId && !!currentSession?.jsonUrl,
    queryParams: versionQueryParams,
  });

  const [currentVersionId, setCurrentVersionId] = useState("");

  const {
    data: versionOfJson,
    refetch: fetchVersionJson,
    isFetching: isFetchingVersionOfJson,
    isLoading: isLoadingVersionOfJson,
  } = useFetchVersionOfJsonFromS3({
    enabled: false,
    queryParams: {
      ...versionQueryParams,
      version_id: currentVersionId,
    },
  });

  const currentSessionJson: IAdLoad | undefined = versionOfJson?.jsonData;

  const loading =
    isFetching ||
    isLoading ||
    isFetchingVersions ||
    isLoadingVersions ||
    isFetchingVersionOfJson ||
    isLoadingVersionOfJson;

  if (isError) {
    message.error(
      error?.error?.message ||
        "Something went wrong while pulling session data.",
    );
  }

  useEffect(() => {
    if (currentSession?.jsonUrl) {
      return;
    }
    getSessionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSession]);

  useEffect(() => {
    if (!sessionVersions) {
      return;
    }
    setCurrentVersionId(sessionVersions?.[0]?.VersionId || "");
  }, [sessionVersions]);

  useEffect(() => {
    if (!currentVersionId) {
      return;
    }
    fetchVersionJson();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVersionId]);

  useEffect(() => {
    if (!versionOfJson) {
      return;
    }
    const { versionId } = versionOfJson;
    setCurrentVersionId(versionId);
  }, [versionOfJson]);

  const {
    adLoadParameters,
    adLoadHistory,
    facebookAdLoadDict: savedFacebookAdLoadDict,
    selectedAds: adLoadSelectedAds,
  } = currentSessionJson || {};

  const currentVersionIndex = sessionVersions
    ? sessionVersions.findIndex(
        versionObj => versionObj.VersionId === currentVersionId,
      )
    : -1;
  const currentVersion = sessionVersions?.[currentVersionIndex];

  const {
    title: historyItemTitle,
    description: historyItemDesc,
    logText,
  } = adLoadHistory || {};

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedAds: IAd[] = adLoadSelectedAds ?? [];

  const adsToLoad = useMemo(
    () => selectedAds.filter(ad => adLoadParameters?.rulesets?.[ad.id]),
    [adLoadParameters, selectedAds],
  );

  const splitLogText = logText?.split(`\n\r`);

  const versionTimestamp =
    currentVersion?.LastModified &&
    `${moment(currentVersion?.LastModified).format(dateFormat)} at ${moment(
      currentVersion?.LastModified,
    ).format("hh:mm:ss a")}`;

  return (
    <Modal
      style={{ top: "2%" }}
      width={1280}
      title={
        <span style={{ color: "#ffffff" }}>
          {loading && <LoadingOutlined />} Session History Snapshot -{" "}
          {currentSession?.name}
        </span>
      }
      visible={true}
      maskClosable={false}
      onCancel={() => props.onClose()}
      footer={[
        currentVersion && (
          <Space key="version-id-text" style={{ float: "left" }}>
            <Typography.Text type="secondary">
              Date and time recorded:
            </Typography.Text>
            <Typography.Text type="secondary">
              {versionTimestamp}
            </Typography.Text>
          </Space>
        ),
        <Button
          disabled={currentVersionIndex <= 0}
          key="prev-history-item-btn"
          title="View a newer version of the current session's data"
          onClick={() => {
            setCurrentVersionId(
              sessionVersions?.[currentVersionIndex - 1]?.VersionId || "",
            );
          }}
        >
          Newer
        </Button>,
        <Button
          key="next-history-item-btn"
          disabled={currentVersionIndex >= (sessionVersions?.length || 0) - 1}
          title="View an older version of the current session's data"
          onClick={() => {
            setCurrentVersionId(
              sessionVersions?.[currentVersionIndex + 1]?.VersionId || "",
            );
          }}
        >
          Older
        </Button>,
      ]}
    >
      <div className="ad-load-modal-content">
        {adLoadHistory && (
          <Row gutter={24}>
            <Col span={6}>
              <Space>
                {historyItemTitle && <Typography.Text>Title: </Typography.Text>}
                <Typography.Text>{historyItemTitle}</Typography.Text>
              </Space>
            </Col>
            <Col span={10}>
              {historyItemDesc && (
                <Typography.Text>Description: </Typography.Text>
              )}
              <Typography.Text>{historyItemDesc}</Typography.Text>
            </Col>
          </Row>
        )}
        {adLoadHistory && <br />}
        <Row
          align="middle"
          justify="center"
          gutter={16}
          style={{
            overflowY: "scroll",
            maxHeight: 450,
            paddingRight: "1em",
            paddingLeft: "1em",
          }}
        >
          <Col span={23}>
            {!adsToLoad?.length && (
              <Empty
                image={
                  loading
                    ? Empty.PRESENTED_IMAGE_DEFAULT
                    : Empty.PRESENTED_IMAGE_SIMPLE
                }
                description={
                  <span>
                    {loading && "Loading session data..."}
                    {!loading && "No rulesets were found"}
                  </span>
                }
              />
            )}
            {!!adsToLoad.length && (
              <Collapse className={`ad-load-modal-collapse`}>
                {adsToLoad.map((ad, index) => {
                  const { id: adShellId } = ad;
                  const displayTitle = returnAdNameToDisplay(ad, index);
                  const facebookAdsToLoad =
                    savedFacebookAdLoadDict?.[adShellId]?.ad || [];

                  return (
                    <Collapse.Panel
                      header={
                        <CollapseHeader
                          title={displayTitle}
                          adShellId={adShellId}
                          overrideBadgeToggle={true}
                          facebookAdsToLoad={facebookAdsToLoad}
                        />
                      }
                      key={`ad-shell-load-collapse-${index}`}
                      style={{ paddingRight: 0 }}
                    >
                      <AdLoadStatusTable
                        facebookAdsToLoad={facebookAdsToLoad}
                      />
                    </Collapse.Panel>
                  );
                })}
              </Collapse>
            )}
          </Col>
        </Row>
        {!!adsToLoad?.length && (
          <Row gutter={6} style={{ marginTop: "1.5em" }}>
            <Col span={22} style={{ textAlign: "center" }}>
              <Tooltip title="Most recent log text.">
                <Typography.Text type="secondary">
                  {loading && "Getting log text..."}
                  {!loading && <InfoCircleFilled />}{" "}
                  {!loading && splitLogText?.[splitLogText.length - 1]}
                  {!loading &&
                    !splitLogText?.length &&
                    "Rulesets were modified."}
                </Typography.Text>
              </Tooltip>
            </Col>
          </Row>
        )}
      </div>
    </Modal>
  );
};

export default SessionHistoryModal;
