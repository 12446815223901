import { Avatar } from "antd";
import { animated, useSpring } from "react-spring";

import LithiaLogo from "statics/images/lithia-logo-sm.png";

import { getEnvVar } from "utils/helpers";

import styles from "../Layout.module.scss";

type TitleProps = {
  collapsed?: boolean;
};

const Title = ({ collapsed }: TitleProps) => {
  const appTitle = getEnvVar("TITLE");
  const clientName = getEnvVar("CLIENT") || "internal";

  const AnimatedAvatarComponent = animated(Avatar);

  const logoProps = useSpring({
    display: "inline-block",
    verticalAlign: "middle",
    marginLeft: collapsed ? "0.8em" : "0.1em",
  });

  const textProps = useSpring({
    color: "black",
    fontSize: "1.3rem",
    paddingLeft: "0.5em",
    display: "inline-block",
    verticalAlign: "middle",
    opacity: collapsed ? 0 : 1,
    textTransform: clientName === "internal" ? "uppercase" : "capitalize",
  });

  const alexiaLogoProps = useSpring({ opacity: collapsed ? 0 : 1 });

  return (
    <div className={styles.siderHeader}>
      {clientName === "ladtech" && (
        <div className={styles.animatedDivWrapper}>
          <AnimatedAvatarComponent
            size="small"
            style={logoProps}
            src={LithiaLogo}
          />
          <animated.span style={textProps}>{appTitle}</animated.span>
        </div>
      )}
    </div>
  );
};

export default Title;
