import { DatePicker, Input, Select } from "antd";
import moment from "moment";
import { memo } from "react";
import { useCampaignManagementFilters } from "screens/adLibrary/shared/hooks/dataListHooks";
import { getAdFormatTypes } from "screens/adLibrary/utils";
import { FilterBy } from "shared/components/FilterByInput/FIlterByInput";
import { dateFormat } from "shared/constants/dataManagement";
import { QCStatus } from "shared/types/shared";
import { formatDateValue } from "utils/helpers";
import styles from "./AdReviewDrawerFilters.module.scss";
import { deliveryStatusOptions } from "./adTableListContainer/columns";
import QcStatusBadge from "./shared/components/QcStatusBadge";

const AdFilter = () => {
  const { filters, updateFilter } = useCampaignManagementFilters();

  return (
    <div data-cy="ad-filter-container">
      <FilterBy title="Name of Ad">
        <Input
          data-cy="ad-filter-name-input"
          value={filters.adName}
          onChange={event => {
            updateFilter("adName", event.target.value);
          }}
        />
      </FilterBy>
      <FilterBy title="QC Status">
        <Select
          allowClear
          mode="multiple"
          className={styles.select}
          value={filters.status}
          data-cy="ad-filter-status-select"
          onChange={value => updateFilter("status", value)}
        >
          <Select.Option value={QCStatus.NOT_QCed}>
            <QcStatusBadge qcStatus={QCStatus.NOT_QCed} />
            {QCStatus.NOT_QCed}
          </Select.Option>
          <Select.Option value={QCStatus.APPROVED}>
            <QcStatusBadge qcStatus={QCStatus.APPROVED} />
            {QCStatus.APPROVED}
          </Select.Option>
          <Select.Option value={QCStatus.DENIED}>
            <QcStatusBadge qcStatus={QCStatus.DENIED} />
            {QCStatus.DENIED}
          </Select.Option>
        </Select>
      </FilterBy>
      <FilterBy title="Created Date">
        <div className={styles.container}>
          <DatePicker
            data-cy="ad-filter-created-date-input"
            format={dateFormat}
            style={{ marginRight: 2 }}
            value={
              filters.createdTime?.[0]
                ? moment(formatDateValue(filters.createdTime?.[0]))
                : null
            }
            onChange={(date, dateString) => {
              updateFilter(
                "createdTime",
                Date.parse(dateString).toString() ?? null,
              );
            }}
          />
        </div>
      </FilterBy>
      <FilterBy title="Start Date">
        <div className={styles.container}>
          <DatePicker
            format={dateFormat}
            value={
              filters.startDate?.[0]
                ? moment(formatDateValue(filters.startDate?.[0]))
                : null
            }
            onChange={(date, dateString) => {
              updateFilter(
                "startDate",
                Date.parse(dateString).toString() ?? null,
              );
            }}
          />
        </div>
      </FilterBy>
      <FilterBy title="End Date">
        <div className={styles.container}>
          <DatePicker
            format={dateFormat}
            style={{ marginRight: 2 }}
            value={
              filters.endDate?.[0]
                ? moment(formatDateValue(filters.endDate?.[0]))
                : null
            }
            onChange={(date, dateString) => {
              updateFilter(
                "endDate",
                Date.parse(dateString).toString() ?? null,
              );
            }}
          />
        </div>
      </FilterBy>
      <FilterBy title="Delivery">
        <Select
          allowClear
          mode="multiple"
          className={styles.select}
          value={filters.effectiveStatus}
          onChange={value => updateFilter("effectiveStatus", value)}
        >
          {deliveryStatusOptions.map(({ text, value }) => (
            <Select.Option key={value} value={value}>
              {text}
            </Select.Option>
          ))}
        </Select>
      </FilterBy>
      <FilterBy title="Ad Format">
        <Select
          data-cy="ad-filter-ad-format-select"
          allowClear
          mode="multiple"
          className={styles.select}
          value={filters.adFormat}
          onChange={value => updateFilter("adFormat", value)}
        >
          {getAdFormatTypes().map(({ key, text, value }) => (
            <Select.Option key={key} value={value}>
              {text}
            </Select.Option>
          ))}
        </Select>
      </FilterBy>
      <FilterBy title="Destination URL">
        <Input
          data-cy="ad-filter-destination-url-input"
          value={filters.destinationUrl}
          onChange={event => updateFilter("destinationUrl", event.target.value)}
        />
      </FilterBy>
    </div>
  );
};

export default memo(AdFilter);
