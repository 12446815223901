import ToolbarTable, {
  ToolbarButton,
} from "shared/components/toolbarTable/ToolbarTable";
import { DataListTagsURL } from "shared/components/dataListURL/DataListTagsURL";
import { useSalesEnablementContext } from "./hooks/SalesEnablementDataProvider";
import {
  Template,
  TemplateKeyToLabel,
  TemplateStatus,
} from "shared/types/salesEnablement";
import { useDataListURLSorter } from "shared/components/dataListURL/useDataListURLSorter";
import { useDataListURLData } from "shared/components/dataListURL/useDataListURLData";
import { useDataListURLGlobalFilter } from "shared/components/dataListURL/useDataListURLGlobalFilter";
import { useMemo, useState } from "react";
import {
  NotificationOutlined,
  EditOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { Action } from "shared/components/CardV2";
import { useIsSalesEnablementAdmin } from "shared/hooks/useIsAdmin";
import Table from "./templates/Table";
import Cards from "./templates/Cards";
import styles from "./TemplateList.module.scss";
import { useGetTemplateErrorStatusFn } from "./templates/errorStatus.utils";
import { ROUTES } from "./templates/constants";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";
import { TableRowSelection } from "antd/lib/table/interface";
import { useLayoutPreference } from "shared/hooks/useLayoutPreference";
import { castArray } from "lodash";
import { filterableMetrics, isTemplateExpired } from "./helpers";
import PublishUnpublishIcon from "shared/components/toolbarTable/PublishUnpublishIcon";
import { useToggleTemplateStatus } from "./hooks/useToggleTemplateStatus";
import TemplatesFilterDrawer from "./templates/TemplatesFilterDrawer";
import { Outlet } from "react-router-dom";

const localStorageLayoutKey = "TemplatesLayout";

const TemplateList = ({
  showActions,
  view = "full",
  select = "multiple",
}: {
  showActions?: boolean;
  view?: "full" | "compact";
  select?: "multiple" | "single";
}) => {
  const {
    sortableKeys,
    templates: allTemplates,
    isTemplateLoading: loading,
  } = useSalesEnablementContext();
  const [layout, setLayout] = useLayoutPreference(
    localStorageLayoutKey,
    "card",
  );
  const navigate = useNavigateWithSearch();
  const isSalesEnablementAdmin = useIsSalesEnablementAdmin();
  const getTemplateErrorStatus = useGetTemplateErrorStatusFn();
  const toggleTemplateStatus = useToggleTemplateStatus();

  const templates = useMemo(() => {
    if (view === "compact" || !isSalesEnablementAdmin) {
      return filterableMetrics(
        allTemplates.filter(template => !getTemplateErrorStatus(template)),
      );
    }

    return filterableMetrics(allTemplates);
  }, [allTemplates, isSalesEnablementAdmin, getTemplateErrorStatus, view]);

  const {
    data,
    originalData,
    isLoading,
    selectedItems,
    setSelectedItemIds,
    toggleSelectedIds,
  } = useDataListURLData<keyof Template, Template>({
    isError: false,
    isLoading: loading,
    data: templates,
  });

  const { sortKey, sortOrder, sortItems } = useDataListURLSorter<
    keyof Template,
    Template
  >();

  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const toolbarContents: ToolbarButton = {
    New: {
      onClick: () => navigate("new"),
      extraInfo: {
        text: "New Template",
      },
      display: isSalesEnablementAdmin,
    },
    ...(isSalesEnablementAdmin && {
      Edit: {
        disabled: selectedItems.length !== 1,
        onClick: () => navigate(`${selectedItems[0].id}`),
      },
      Duplicate: {
        disabled:
          selectedItems.length !== 1 ||
          isTemplateExpired(selectedItems[0].expirationDate),
        onClick: () => navigate(`${selectedItems[0].id}/duplicate`),
        extraInfo: {
          tooltip:
            selectedItems.length > 1
              ? `Only one template can be duplicated at a time`
              : undefined,
        },
      },
      Publish: {
        disabled:
          selectedItems.length !== 1 ||
          selectedItems[0].status === TemplateStatus.PUBLISHED ||
          isTemplateExpired(selectedItems[0].expirationDate),
        onClick: async () => {
          await toggleTemplateStatus({ record: selectedItems[0] });
        },
      },
    }),
    SelectWithSort: {
      onClick: () => null,

      extraInfo: sortableKeys.map(key => ({
        label: TemplateKeyToLabel[key],
        value: key,
      })),
    },
  };

  const cardActions: Action<Template>[] = isSalesEnablementAdmin
    ? [
        {
          type: "edit",
          tooltipTitle: () => "Edit",
          icon: () => <EditOutlined />,
          handler: item => () => {
            if (!item) return;
            navigate(`${item.id}`);
          },
        },
        {
          type: "duplicate",
          tooltipTitle: () => "Duplicate",
          icon: () => <CopyOutlined />,
          handler: item => () => {
            if (!item) return;
            navigate(`${item.id}/duplicate`);
          },
          disabled: item => isTemplateExpired(item?.expirationDate),
        },
        {
          type: "create",
          tooltipTitle: () => "Create Marketing Material",
          icon: () => <NotificationOutlined />,
          handler: item => () => {
            if (!item) return;
            navigate(ROUTES.newMaterial(item.id));
          },
          disabled: item => isTemplateExpired(item?.expirationDate),
        },
        {
          type: "publish",
          icon: item => <PublishUnpublishIcon status={item?.status} />,
          tooltipTitle: item =>
            item?.status === TemplateStatus.PUBLISHED ? "Unpublish" : "Publish",
          handler: item => async () => {
            if (!item) return;
            await toggleTemplateStatus({ record: item });
          },
          disabled: item => isTemplateExpired(item?.expirationDate),
        },
      ]
    : [
        {
          type: "create",
          title: "Create Marketing Material",
          tooltipTitle: () => "Create Marketing Material",
          icon: () => <NotificationOutlined />,
          handler: item => () => {
            if (!item) return;
            navigate(ROUTES.newMaterial(item.id));
          },
          disabled: item => isTemplateExpired(item?.expirationDate),
        },
      ];

  const rowSelection: TableRowSelection<Template> | undefined = useMemo(
    () =>
      select === "single"
        ? {
            type: "radio",
            onSelect: key => setSelectedItemIds([key.id]),
            hideSelectAll: true,
            selectedRowKeys: selectedItems.map(item => item.id),
          }
        : undefined,
    [setSelectedItemIds, selectedItems, select],
  );

  const { globalFilter, setGlobalFilter } = useDataListURLGlobalFilter();

  return (
    <div className={styles.container}>
      <ToolbarTable
        layout={layout}
        titleTooltip="Search"
        searchPlaceholder="Search"
        toolbarContents={showActions ? toolbarContents : {}}
        onSearch={setGlobalFilter}
        searchValue={globalFilter ?? undefined}
        disableSearch={false}
        onClickLayout={setLayout}
        previewFormat={false}
        sortingOrder={sortKey && sortOrder ? [sortKey, sortOrder] : undefined}
        onSortChange={([columnKey, order]) => {
          sortItems(columnKey, order);
        }}
        includeFilterOnTableLayout={true}
      />
      <DataListTagsURL<keyof Template, Template>
        data={data}
        originalData={originalData}
        onAddFilterClick={() => setOpenFilter(true)}
        showSelectAll={layout === "card" && view !== "compact"}
      />
      <div>
        {layout === "table" && (
          <Table
            templates={data}
            originalTemplates={originalData}
            loading={isLoading}
            view={view}
            rowSelection={rowSelection}
          />
        )}
        {layout === "card" && (
          <Cards
            isLoading={isLoading}
            templates={data}
            actions={select === "single" ? [] : cardActions}
            defaultAction={id => navigate(id)}
            isAdmin={isSalesEnablementAdmin}
            selectedItems={selectedItems}
            view={view}
            toggleSelectedIds={ids =>
              select === "single"
                ? setSelectedItemIds(castArray(ids))
                : toggleSelectedIds(ids)
            }
            selectType={select}
          />
        )}
      </div>
      <TemplatesFilterDrawer
        open={openFilter}
        onClose={() => setOpenFilter(false)}
      />
      <Outlet />
    </div>
  );
};

export default TemplateList;
