import { useState } from "react";
import { message as AntMessage } from "antd";
import { IArtboard } from "shared/types/designStudio";
import { UploadChangeParam } from "antd/lib/upload";
import { BgImage, IFileList, ImgFiles } from "../artboardDrawer/types";
import {
  defaultImage,
  getFileAttrs,
  mapBgImageToIFile,
  stripExt,
  uploadVideo,
} from "../artboardDrawer/utils";

interface Props {
  artboard: IArtboard;
  setSearchBoxValue: React.Dispatch<React.SetStateAction<string>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setCustomName: React.Dispatch<React.SetStateAction<string>>;
}

export const useDrawerImage = ({
  setSearchBoxValue,
  artboard,
  setShowModal,
  setCustomName,
}: Props) => {
  const [fileList, setFileList] = useState<ImgFiles[]>([]);
  const [filteredFileList, setFilteredFileList] = useState<ImgFiles[]>([]);
  const [placeHolder, setPlaceHolder] = useState("");
  const [tempFileList, setTempFileList] = useState<ImgFiles[]>([]);
  const [bgImages, setBgImages] = useState<BgImage[]>([]);
  const [currImage, setCurrImage] = useState<BgImage>(defaultImage);

  const removeFileFromList = (file: ImgFiles) => {
    setFileList(prevList => prevList.filter(img => img.uid !== file.uid));
    setFilteredFileList(prevList =>
      prevList.filter(img => img.uid !== file.uid),
    );
    setSearchBoxValue("");
  };

  const updateBgImage = (image: BgImage) => {
    const index = bgImages.findIndex(bgImage => bgImage.id === image.id);
    const newImages = index >= 0 ? [...bgImages] : bgImages.concat(image);
    newImages.splice(index, 1, image);
    setBgImages(newImages);
  };

  const editThemeImageFile = (image: BgImage) => {
    const index = bgImages.findIndex(bgImage => bgImage.id === image.id);
    if (index >= 0) {
      setFileList(prevList =>
        prevList.map(file =>
          file.uid === image.id ? mapBgImageToIFile(image, file) : file,
        ),
      );
    }
  };

  const handleThemeImageChange = async ({
    file: uploadFile,
    fileList: uploadFileList,
  }: UploadChangeParam<ImgFiles>) => {
    if (uploadFile.status === "removed") {
      removeFileFromList(uploadFile);
      return;
    }
    uploadFile.status = "done";
    const isVideo = !!uploadFile.type?.includes("video");
    const newFile = uploadFile.originFileObj;
    const { width, height } = artboard;
    const fileAttrs = await getFileAttrs({ file: newFile, width, height });
    if (fileAttrs.isValid) {
      const fileName = stripExt(uploadFile.name);
      if (isVideo) {
        const { result, error } = await uploadVideo(uploadFile as IFileList);
        if (error || !result) {
          AntMessage.error("Unable to Upload Video");
          return;
        }
        (uploadFile as IFileList).videoSrc = result.images?.[0].imageUrl || "";
      }
      setShowModal(true);
      setCustomName(fileName);
      setPlaceHolder(fileName);
      setTempFileList(uploadFileList);
    } else {
      const errorMsg = `${fileAttrs.width}x${fileAttrs.height} image could not be used for ${artboard.width}x${artboard.height} artboard.`;
      AntMessage.error(errorMsg);
    }
  };

  return {
    fileList,
    filteredFileList,
    placeHolder,
    tempFileList,
    currImage,
    bgImages,
    updateBgImage,
    editThemeImageFile,
    handleThemeImageChange,
    setFileList,
    setPlaceHolder,
    setFilteredFileList,
    setCurrImage,
    setBgImages,
  };
};
