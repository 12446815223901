import { useTrackAdLoadExec } from "shared/hooks/adLibrary/adLoad/adLoadExec";
import { useCurrentSession } from "./useCurrentSession";
import { AdLoadStatusItem } from "screens/adLibrary/facebookUtils/adProto.types";
import { typedGroupBy } from "utils/helpers.array";

type TrackExec = {
  /** all statuses */
  execStatuses: AdLoadStatusItem[];
  /** computed percentage of the process */
  percent: number | undefined;
  /** ads that failed to load */
  adsWithError: AdLoadStatusItem[];
  /** ads that were loaded successfully */
  adsLoaded: AdLoadStatusItem[];
  /** ads that are still loading */
  adsLoading: AdLoadStatusItem[];
  /** ads that finished loading, computed from loaded + with error */
  adsFinished: AdLoadStatusItem[];
};

/**
 * Track the current execution status of the ad load process
 * this will only work in the load step
 * @returns {TrackExec}
 */
export const useTrackCurrentExec = (): TrackExec => {
  const { session, adDests } = useCurrentSession();

  const { data: executionStatuses } = useTrackAdLoadExec(session.id, {
    enabled: session.data.step === "load",
  });

  const filteredExecStatuses =
    (session.data.step === "load" &&
      executionStatuses?.filter(execStatus =>
        adDests.some(
          adDest =>
            adDest.adId === execStatus.adId &&
            adDest.adsetId === execStatus.adsetId,
        ),
      )) ||
    [];

  const {
    success: adsLoaded = [],
    error: adsWithError = [],
    loading: adsLoading = [],
  } = typedGroupBy(filteredExecStatuses, exec => exec.status);

  const adsFinished = adsLoaded.concat(adsWithError);

  const percent = !!filteredExecStatuses.length
    ? (adsFinished.length / filteredExecStatuses.length) * 100
    : undefined;

  return {
    percent,
    adsWithError,
    adsFinished,
    adsLoaded,
    adsLoading,
    execStatuses: filteredExecStatuses,
  };
};
