import { isValidUrl } from "utils/validators";
import {
  AdType,
  AdPrototype,
  CallToAction,
  IFacebookAdCreative,
  IObjectStorySpecChildAttachment,
  IObjectStorySpecVideoData,
  IObjectStorySpecLinkData,
  IObjectStorySpec,
} from "./types";
import {
  buildCreativeCtaLinkValue,
  buildCtaLink,
  getDefaultPropValue,
} from "./utils";

interface IFormParameters {
  pageId?: string;
  productSetId?: string;
  forceDummyData?: boolean;
}

const { AIA, DPA, FTA, Carousel, Collection, InstantExperience, Still, Video } =
  AdType;

const canvasAdCreative = (
  adPrototype: AdPrototype,
  formParameters: IFormParameters,
) => {
  const adCreative: IFacebookAdCreative = {
    object_story_spec: returnObjectStorySpecForAdWithIE({
      adPrototype,
      adType: InstantExperience,
      formParameters,
    }),
    url_tags: adPrototype.utm,
  };

  return adCreative;
};

const collectionAdCreative = (
  adPrototype: AdPrototype,
  formParameters: IFormParameters,
) => {
  const adCreative: IFacebookAdCreative = {
    product_set_id: formParameters.productSetId,
    object_story_spec: returnObjectStorySpecForAdWithIE({
      adPrototype,
      formParameters,
      adType: Collection,
    }),
    url_tags: adPrototype.utm,
  };

  return adCreative;
};

type returnObjectStorySpecForAdWithIEArgs = {
  adType: AdType;
  adPrototype: AdPrototype;
  formParameters: IFormParameters;
};

const returnObjectStorySpecForAdWithIE = ({
  adType,
  adPrototype,
  formParameters,
}: returnObjectStorySpecForAdWithIEArgs) => {
  const { pageId, forceDummyData } = formParameters;

  const videoData: IObjectStorySpecVideoData = {
    title: adPrototype.title,
    message: adPrototype.message,
    video_id: adPrototype.assetId,
    image_hash: adPrototype.imageHash,
    call_to_action: {
      type: adPrototype.callToAction || CallToAction.LEARN_MORE,
      value: {
        link: adPrototype.canvasUrl || "",
      },
    },
    retailer_item_ids: adType === Collection ? ["0", "0", "0", "0"] : undefined,
  };

  const linkData: IObjectStorySpecLinkData = {
    message: adPrototype.message,
    name: adPrototype.title,
    link: forceDummyData ? getDefaultPropValue("URL") : adPrototype.canvasUrl,
    image_hash: adPrototype.imageHash,
    call_to_action: {
      type: adPrototype.callToAction || CallToAction.LEARN_MORE,
    },
    retailer_item_ids: adType === Collection ? ["0", "0", "0", "0"] : undefined,
  };

  const objectStorySpec: IObjectStorySpec = {
    page_id: pageId,
    video_data: adPrototype.assetId ? videoData : undefined,
    link_data: !adPrototype.assetId ? linkData : undefined,
  };

  return objectStorySpec;
};

const daaAdCreative = (
  adPrototype: AdPrototype,
  formParameters: IFormParameters,
) => {
  const {
    title,
    format,
    message,
    displayUrl,
    callToAction,
    creativeOption,
    footerText: adDescription,
  } = adPrototype;
  const { pageId, productSetId } = formParameters;
  const format_option = (() => {
    if (!format) {
      return "carousel_images_multi_items";
    } else if (format === "still") {
      return "single_image";
    } else if (format === "carousel") {
      return creativeOption === "single"
        ? "carousel_images_single_item"
        : "carousel_slideshows";
    } else {
      return "carousel_images_multi_items";
    }
  })();

  const adCreative: IFacebookAdCreative = {
    product_set_id: productSetId,
    object_story_spec: {
      page_id: pageId,
      template_data: {
        message,
        name: title,
        link: displayUrl,
        description: adDescription,
        call_to_action: {
          type: callToAction || CallToAction.LEARN_MORE,
        },
        multi_share_end_card: creativeOption !== "single",
        format_option,
      },
    },
    url_tags: adPrototype.utm,
  };

  return adCreative;
};
const stillAdCreative = (
  adPrototype: AdPrototype,
  formParameters: IFormParameters,
  isForLiveAd?: boolean,
) => {
  const {
    title,
    message,
    imageHash,
    footerText,
    callToAction,
    destinationUrl,
    displayUrl,
  } = adPrototype;
  const { pageId } = formParameters;
  const ctaLink = buildCtaLink(destinationUrl, isForLiveAd);

  return {
    object_story_spec: {
      page_id: pageId,
      link_data: {
        message,
        name: title,
        image_hash: imageHash,
        description: footerText,
        link: ctaLink,
        call_to_action: {
          type: callToAction || CallToAction.LEARN_MORE,
          value: buildCreativeCtaLinkValue(ctaLink, displayUrl),
        },
      },
    },
    url_tags: adPrototype.utm,
  };
};

const carouselAdCreative = (
  adPrototype: AdPrototype,
  formParameters: IFormParameters,
) => {
  const { message, displayUrl, callToAction: adCallToAction } = adPrototype;
  const { pageId } = formParameters;

  const cards: IObjectStorySpecChildAttachment[] = adPrototype.cards.map(
    card => {
      const {
        imageHash,
        headline,
        description,
        callToAction: cardCallToAction,
      } = card;
      const { asset_id: assetId, destination_url: url } = card;
      const link = isValidUrl(url || "") ? url : undefined;

      return {
        description,
        name: headline,
        video_id: assetId || undefined,
        image_hash: imageHash,
        link,
        call_to_action: {
          type: cardCallToAction || CallToAction.LEARN_MORE,
          value: {
            link,
          },
        },
      };
    },
  );

  const adCreative: IFacebookAdCreative = {
    object_story_spec: {
      page_id: pageId,
      link_data: {
        message,
        caption: displayUrl || "",
        link: displayUrl,
        child_attachments: cards,
        multi_share_end_card: false,
        multi_share_optimized: false,
        call_to_action: {
          type: adCallToAction || CallToAction.LEARN_MORE,
        },
      },
    },
    url_tags: adPrototype.utm,
  };

  return adCreative;
};

// This returns creative needed for live video ads
const returnVideoAdCreative = (
  adPrototype: AdPrototype,
  formParameters: IFormParameters,
  isForLiveAd?: boolean,
) => {
  const {
    title,
    assetId,
    message,
    imageHash,
    displayUrl,
    footerText,
    callToAction,
    destinationUrl,
  } = adPrototype;

  const { pageId } = formParameters;
  const ctaLink = buildCtaLink(destinationUrl, isForLiveAd);

  const adCreative: IFacebookAdCreative = {
    object_story_spec: {
      page_id: pageId,
      video_data: {
        title,
        message,
        video_id: assetId,
        image_hash: imageHash,
        link_description: footerText,
        call_to_action: {
          type: callToAction || CallToAction.LEARN_MORE,
          value: buildCreativeCtaLinkValue(ctaLink, displayUrl),
        },
      },
    },
    url_tags: adPrototype.utm,
  };

  return adCreative;
};

type ReturnAdCreativeArgs = {
  adType: AdType;
  isForLiveAd?: boolean;
  adPrototype: AdPrototype;
  formParameters: IFormParameters;
};

export const returnAdCreative = ({
  adType,
  isForLiveAd,
  adPrototype,
  formParameters,
}: ReturnAdCreativeArgs) => {
  switch (adType) {
    case Carousel:
      return carouselAdCreative(adPrototype, formParameters);
    case InstantExperience:
      return canvasAdCreative(adPrototype, formParameters);
    case Collection:
      return collectionAdCreative(adPrototype, formParameters);
    case AIA:
    case DPA:
    case FTA:
      return daaAdCreative(adPrototype, formParameters);
    case Still:
      return stillAdCreative(adPrototype, formParameters, isForLiveAd);
    case Video:
      return returnVideoAdCreative(adPrototype, formParameters, isForLiveAd);
    default:
      return {} as IFacebookAdCreative;
  }
};

export const convertCollectionCreativeToVideoCreative = (
  collectionAdCreative: IFacebookAdCreative,
): IFacebookAdCreative => ({
  ...collectionAdCreative,
  id: undefined,
  product_set_id: undefined,
  name: collectionAdCreative.name?.replace(/collection/i, "Cinemagraph"),
  object_story_spec: {
    ...collectionAdCreative.object_story_spec,
    video_data: {
      ...collectionAdCreative.object_story_spec?.video_data,
      retailer_item_ids: undefined,
      call_to_action: {
        value: {
          link: collectionAdCreative.object_story_spec?.video_data
            ?.call_to_action?.value?.link,
        },
        type:
          collectionAdCreative.object_story_spec?.video_data?.call_to_action
            ?.type ?? CallToAction.LEARN_MORE,
      },
    },
  },
});
