import { Input, Popover, Spin, Table, Select } from "antd";
import { ColumnProps } from "antd/es/table";
import { useEffect, useState } from "react";
import {
  uploadJellybeanUpdate,
  getJellybeanImages,
  editOffer,
} from "redux/assetBuilder/assetBuilder.slice";
import "./OfferImagePopover.scss";
import { useAppShallowEqualSelector } from "shared/hooks/useAppSelector";
import { AppState } from "redux/store";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { flatten, toUpper } from "lodash";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { OfferData, IJellybeanData } from "shared/types/assetBuilder";
import { fullyDecodeURIComponent } from "utils/helpers.string";

const defaultRowParams = {
  imageUrl: "",
  year: "",
  make: "",
  model: "",
  trim: "",
  desc: "",
};

interface IOfferImagePopover {
  offerData?: OfferData;
  setNewJellybean: (jellybean: string) => void;
  showUploadContent: boolean;
  toggleUploadContent: () => void;
  buttonActionString: string;
  toggleOkButton: () => void;
  toggleUndoButton: () => void;
}

interface OfferRow {
  color: {
    jellybeanData: IJellybeanData;
    color: string;
  }[];
  key: string;
  imageUrl: string;
  year: string;
  make: string;
  model: string;
  trim: string;
  body: string;
  evox: boolean;
  desc: string;
}

interface RowItem {
  imageUrl: string;
  year: string;
  make: string;
  model: string;
  trim: string | undefined;
  desc: string;
}

const OfferImagePopoverSearchContent = ({
  setNewJellybean,
  showUploadContent,
  buttonActionString,
  toggleOkButton,
  toggleUndoButton,
}: IOfferImagePopover) => {
  const dispatch = useAppDispatch();
  const { offerData, jellybeanImages, gettingJellybeanImages } =
    useAppShallowEqualSelector(({ assetBuilder }: AppState) => ({
      offerData: assetBuilder.offerData,
      editMode: assetBuilder.editMode,
      jellybeanImages: assetBuilder.jellybeanImages,
      gettingJellybeanImages: assetBuilder.gettingJellybeanImages,
    }));

  const [enteredYear, setEnteredYear] = useState<string>(offerData?.year ?? "");
  const [enteredMake, setEnteredMake] = useState<string>(offerData?.make ?? "");
  const [enteredModel, setEnteredModel] = useState<string>(
    offerData?.model ?? "",
  );
  const [filterString, setFilterString] = useState<string>(
    offerData?.trim ?? "",
  );

  const evoxFeatureFlag = useAppSelector(
    ({ configuration }) =>
      configuration.config?.featureFlags?.enableEvoxJellybeans,
  );

  const filteredJellybean =
    jellybeanImages?.filter((vehicle: IJellybeanData) => {
      const { url } = vehicle;
      const [jbNameRaw] = url.split("/").slice(-1); // ex) https://alexia-assets-prd.s3.amazonaws.com/evox/2020_Honda_CR-V_LX_13_EVOX_Platinum%20White%20Pearl.png ====> 2020_Honda_CR-V_LX_13_EVOX_Platinum%20White%20Pearl.png

      const jellybeanNameParts = fullyDecodeURIComponent(jbNameRaw)
        .replace(/([:])/g, "/") // Jellybean trims may contain "/" at times, some of which have been replaced with ":" (older jellybeans)
        .toLowerCase() // We convert the jellybean name to lowercase to make the filter case-insensitive
        .split("_"); // We split the jellybean name into parts

      const filterValue = filterString.trim().toLowerCase();

      // We match jellybeans that contain the filter string, or jellybeans that contain the filter string with special characters removed.
      // For example, jellybeans with trim "R/T Plus" should be matched by the filter "rt", "r/t", "R/T plus", etc
      return jellybeanNameParts.some(
        namePart =>
          namePart.includes(filterValue) ||
          namePart.replace(/([^a-zA-Z0-9 ]*)/g, "").includes(filterValue),
      );
    }) || jellybeanImages;

  // do request when yym changes
  useEffect(() => {
    if (!enteredYear || !enteredMake || !enteredModel) return;
    const delayDebounceFn = setTimeout(() => {
      dispatch(
        getJellybeanImages(offerData, {
          year: enteredYear,
          make: enteredMake,
          model: enteredModel,
        }),
      );
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [enteredYear, enteredMake, enteredModel, dispatch, offerData]);

  let trimArr: string[] = [];

  /*
    There aer 2 types of jellybean images.(EVOX or Non-EVOX).
    There are many files that do not follow the naming rules.

    incorrect format
    year_make_model_sequence_EVOX_color

    year_make_model_trim_sequence
    year_make_model_trim_color_description
    year_make_model_description
    year_make_model_description_color
    year_make_model_bodyStyle_trim_color

    old foramt
    year_make_model_trim_sequence_EVOX_color
    year_make_model_trim                      length : 4
    year_make_model_trim_color                length : 5

    new format
    year_make_model_trim_sequence_EVOX_color - correct format             length: 7
    year_make_model_trim_bodyStyle_color - correct foramt                 length: 6
    year_make_model_trim_bodyStyle_color_description - correct format     length: 7
  */
  const minEvoxFormatLength = 7;
  const minFormatLength = 6;
  const bodyStyleIndex = 4;
  const colorIndex = 5;
  const descIndex = 6;

  const bodyExtractor = (imageUrl: string) => {
    if (!imageUrl) return "Default";
    const hasEvox = imageUrl.includes("EVOX_");
    const vehicleData = imageUrl.split("_");
    const isMinFormat = vehicleData.length >= minFormatLength;

    if (!isMinFormat || hasEvox) return "Default";

    return vehicleData[bodyStyleIndex];
  };
  const colorExtractor = (imageUrl: string) => {
    if (!imageUrl) return "Default";

    const hasEvox = imageUrl.includes("EVOX_");
    const vehicleData = imageUrl.split("_");
    const urlSplitByEvox = imageUrl.split("EVOX_");
    const isColorFormat = toUpper(vehicleData[colorIndex]) !== "NA";
    const isMinEvoxFormat = vehicleData.length >= minEvoxFormatLength;
    const isMinFormat = vehicleData.length >= minFormatLength;
    const hasEvoxOrColor = hasEvox || isColorFormat;
    const isEvoxOrMinFormat = hasEvox || isMinFormat;
    const isNotValidEvoxFormat = !isMinEvoxFormat || urlSplitByEvox.length < 2;

    if (
      (hasEvox && isNotValidEvoxFormat) ||
      !isEvoxOrMinFormat ||
      !hasEvoxOrColor
    )
      return "Default";

    return hasEvox
      ? urlSplitByEvox[1].split("%20").join(" ")
      : vehicleData[colorIndex];
  };
  const descExtractor = (imageUrl: string) => {
    if (!imageUrl) return "";
    // Only non-Evox file has description
    // year_make_model_trim_bodyStyle_color_description - correct format     length: 7
    const hasEvox = imageUrl.includes("EVOX_");
    const vehicleData = imageUrl.split("_");

    if (hasEvox || vehicleData.length < minEvoxFormatLength) return "";

    return vehicleData.slice(descIndex, vehicleData.length).join("_");
  };

  const generateTableDataV2 = (
    filteredJellybeans: IJellybeanData[],
  ): Array<OfferRow> => {
    trimArr = [];
    const tempEvoxFeatureFlagJBArr =
      filteredJellybeans?.filter(
        (jellybean: IJellybeanData) =>
          (evoxFeatureFlag && jellybean?.url?.includes("EVOX_")) ||
          !jellybean?.url?.includes("EVOX_"),
      ) || filteredJellybeans;

    const evoxFeatureFlagJBArr = tempEvoxFeatureFlagJBArr
      // remove duplicates
      .filter(
        (value, index, self) =>
          index === self.findIndex(t => t.url === value.url),
      )
      // new format must have more than 6 length (non-evox)
      // new format must have more than 7 length (evox)
      .filter(
        value =>
          (!value.url.includes("EVOX_") &&
            value.url.split("_").length >= minFormatLength) ||
          (value.url.includes("EVOX_") &&
            value.url.split("_").length >= minEvoxFormatLength),
      );

    const vehicleArr = evoxFeatureFlagJBArr.map(jellybeanData => {
      /*
        this function maps out the rows for the table based off of the image urls
        this will return each unique ymmt as a row with an array of colors for the vehicle
        example url: https://alexia-assets-prd.s3.amazonaws.com/evox/2021_Honda_Civic_LX_0_EVOX_Cosmic%20Blue%20Metallic.png
        id would end up looking like: 2021_Honda_Civic_LX_0_EVOX_Cosmic Blue Metallic
        ymmt would look like: 2021_Honda_Civic_LX
      */

      const id =
        jellybeanData?.url
          ?.split("/")[4]
          ?.replace(".png", "")
          ?.replace("%20", " ") || "";
      const ymmt = id.includes("EVOX_")
        ? id.split("EVOX_")[0].split("_").slice(0, 4).join("_")
        : id.split("_").slice(0, 5).join("_");

      const ymmtValid = ymmt.split("_").length > 3;
      trimArr?.push(ymmt?.split("_")[3]?.split("%20")?.join(" "));

      const capitalizedBody =
        jellybeanData?.data?.body?.charAt(0)?.toUpperCase() +
        jellybeanData?.data?.body?.slice(1);

      const [year, make, model, trim, body] = ymmt.split("_");

      const result = {
        key: ymmt + "_" + descExtractor(id),
        imageUrl: jellybeanData.url,
        year: ymmtValid ? year : "",
        make: ymmtValid ? make : "",
        // Models and trims may occasionally have a colon in them. This comes from the original CSV file used to generate the jellybeans.
        // Colons were used as a replacement character for slashes at some point on the CSV files.
        // This should be a temporary fix until we can update CSVs to include the real models and trims instead of having character replacements.
        // TODO: update the CSVs
        model: ymmtValid
          ? fullyDecodeURIComponent(model).replace(":", "/")
          : "",
        trim: ymmtValid ? fullyDecodeURIComponent(trim).replace(":", "/") : "",
        body:
          capitalizedBody ||
          (Number.isNaN(Number(body)) ? body : bodyExtractor(id)),
        evox: id.includes("EVOX_"),
        color: [
          {
            jellybeanData,
            color: colorExtractor(id),
          },
        ],
        desc: descExtractor(id),
      };
      return result;
    });

    const lcModel =
      enteredModel.toLowerCase() || offerData?.model.toLowerCase() || "";
    const filteredVehicles = vehicleArr.filter(row => {
      const lcRowModel = row.model.toLowerCase();
      return lcModel.includes(lcRowModel);
    });

    const uniqueKeys = filteredVehicles
      .map(vehicle => vehicle.key)
      .filter((data, index, arr) => arr.indexOf(data) === index);

    const vehicleSet = uniqueKeys.map(uniqueKey => {
      const dupes = filteredVehicles.filter(
        vehicle => vehicle.key === uniqueKey,
      );
      const colorsArr = flatten(dupes.map(dupe => dupe.color));
      const descArr = flatten(dupes.map(dupe => dupe.desc));
      return {
        ...dupes[0],
        color: colorsArr,
        desc: descArr[descArr.length - 1],
      };
    });

    return vehicleSet;
  };

  const ymmtExtractor = (imageUrl: string) => {
    const id = imageUrl
      .split("/")[4]
      ?.split(".")[0]
      ?.replace(".png", "")
      .replace("%20", " ");
    const ymmt = id.split("EVOX_")[0].split("_").slice(0, 4).join("_");
    return ymmt.split("_");
  };

  // if row is clicked, set updatedRow to clicked row
  // set origninalImg
  // if row or tab is clicked, set updatedRow and update the image on the side
  // if delete is clicked, set updated row to empty and revert current image to what it used to be
  // if ok is clicked, keep the image, update the ymmt (if applicable) and have the new data as the updated originalImg
  const [originalImg, setOriginalImg] = useState<RowItem>(defaultRowParams);
  const [updatedRow, setUpdatedRow] = useState<RowItem>(defaultRowParams);

  useEffect(() => {
    const { year, make, model, trim, imageUrl } = offerData || {
      year: "",
      make: "",
      model: "",
      imageUrl: "",
    };
    !originalImg.imageUrl &&
      setOriginalImg({
        imageUrl,
        year,
        make,
        model,
        trim,
        desc: descExtractor(imageUrl),
      });
  }, [offerData, originalImg.imageUrl]);

  useEffect(() => {
    if (!buttonActionString) {
      return;
    }
    const { year, make, model, trim, vin } = offerData || {
      year: "",
      make: "",
      model: "",
    };
    if (updatedRow.imageUrl) {
      if (buttonActionString === "UNDO") {
        setNewJellybean(`${originalImg.imageUrl}`);
        dispatch(
          editOffer({ key: "imageUrl", value: `${originalImg.imageUrl}` }),
        );
        setUpdatedRow(defaultRowParams);
        return;
      }
      setOriginalImg(updatedRow);
      if ((!year && !make && !model) || !vin) {
        dispatch(editOffer({ key: "year", value: `${updatedRow.year}` }));
        dispatch(editOffer({ key: "make", value: `${updatedRow.make}` }));
        dispatch(editOffer({ key: "model", value: `${updatedRow.model}` }));
        (!trim || updatedRow.trim) &&
          dispatch(editOffer({ key: "trim", value: `${updatedRow.trim}` }));
      }
      //set original row to the updated one to reset the process
      setUpdatedRow(defaultRowParams);
    }
  }, [
    buttonActionString,
    dispatch,
    offerData,
    originalImg.imageUrl,
    setNewJellybean,
    updatedRow,
  ]);

  const columns: Array<ColumnProps<OfferRow>> = [
    {
      title: "",
      dataIndex: "color",
      key: "color",
      // eslint-disable-next-line react/display-name
      render: (
        color: {
          jellybeanData: IJellybeanData;
          color: string;
        }[],
      ) => {
        return (
          <div>
            <Popover
              content={
                <div>
                  <img
                    style={{ width: "200px", height: "160px" }}
                    src={color[0].jellybeanData.url}
                  />
                </div>
              }
              trigger="hover"
            >
              <img
                style={{ width: "20px", height: "16px" }}
                src={color[0].jellybeanData.url}
              />{" "}
            </Popover>
          </div>
        );
      },
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
    },
    {
      title: "Make",
      dataIndex: "make",
      key: "make",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Trim",
      dataIndex: "trim",
      key: "trim",
      sorter: (a, b) => {
        return a.trim.localeCompare(b.trim);
      },
    },
    {
      title: "Body",
      dataIndex: "body",
      key: "body",
      sorter: (a, b) => {
        return a.body.localeCompare(b.trim);
      },
    },
    {
      title: "Exterior Color",
      dataIndex: "color",
      key: "color",
      // eslint-disable-next-line react/display-name
      render: (
        color: {
          jellybeanData: IJellybeanData;
          color: string;
        }[],
      ) => {
        return (
          <Select
            style={{ width: 120 }}
            defaultValue={color.length ? color[0]?.color : ""}
            onChange={selectedKey => {
              toggleOkButton();
              toggleUndoButton();
              setNewJellybean(`${selectedKey}`);
              if (uploadJellybeanUpdate) {
                uploadJellybeanUpdate({
                  value: true,
                  img: `${selectedKey}`,
                });

                const [yearImg, makeImg, modelImg, trimImg] =
                  ymmtExtractor(selectedKey);

                dispatch(
                  editOffer({ key: "imageUrl", value: `${selectedKey}` }),
                );

                setUpdatedRow({
                  imageUrl: selectedKey,
                  year: yearImg,
                  make: makeImg,
                  model: modelImg,
                  trim: trimImg,
                  desc: descExtractor(selectedKey),
                });
              }
            }}
          >
            {color.map(index => {
              return (
                <Select.Option
                  key={`${index.color}-${index}`}
                  value={index.jellybeanData.url}
                >
                  {index.color}
                </Select.Option>
              );
            })}
          </Select>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "desc",
      key: "desc",
    },
  ];

  return (
    <div className="library-wrapper">
      <Spin spinning={gettingJellybeanImages}>
        {
          <div>
            {!showUploadContent && (
              <div>
                <b>Vehicle Information</b>
                <br />
              </div>
            )}
            <div style={{ display: "flex" }}>
              {!showUploadContent && (
                <div>
                  <Input
                    style={{ width: "5em" }}
                    placeholder="Year"
                    onChange={e => {
                      setEnteredYear(e.target.value);
                    }}
                  ></Input>{" "}
                  <Input
                    style={{ width: "10em" }}
                    placeholder="Make"
                    onChange={e => {
                      setEnteredMake(e.target.value);
                    }}
                  ></Input>{" "}
                  <Input
                    style={{ width: "10em" }}
                    placeholder="Model"
                    onChange={e => {
                      setEnteredModel(e.target.value);
                    }}
                  ></Input>
                </div>
              )}
              {!showUploadContent && <div style={{ width: "10em" }} />}
            </div>
            <br />
            {!showUploadContent && (
              <div>
                <b>Filter Current Selection</b>
                <br />
              </div>
            )}
            <div className="search-wrapper">
              <div className="search-jellybean">
                <Input
                  placeholder="Filter"
                  allowClear={true}
                  value={filterString}
                  onChange={e => {
                    setFilterString(e.target.value);
                  }}
                />
              </div>
            </div>
            <br />
            {!showUploadContent && (
              <Table
                style={{ width: 800 }}
                className="offer-image-search-popover"
                dataSource={generateTableDataV2(filteredJellybean)}
                columns={columns}
                pagination={{ pageSize: 30 }}
                size="small"
                onRow={record => ({
                  onClick: event => {
                    // selects vehicle if row is clicked (except on the color selector)
                    // if color dropdown is clicked, it doesn't change the vehicle unless a color is chosen
                    toggleOkButton();
                    toggleUndoButton();
                    if (event.target instanceof Element) {
                      if (
                        !event.target.className.includes(
                          "ant-select-selection-item",
                        ) &&
                        !event.target.className.includes("item-option-content")
                      ) {
                        setNewJellybean(`${record.imageUrl}`);
                        if (uploadJellybeanUpdate) {
                          uploadJellybeanUpdate({
                            value: true,
                            img: `${record.imageUrl}`,
                          });
                          dispatch(
                            editOffer({
                              key: "imageUrl",
                              value: `${record.imageUrl}`,
                            }),
                          );

                          const [yearImg, makeImg, modelImg, trimImg] =
                            ymmtExtractor(record.imageUrl);

                          setUpdatedRow({
                            imageUrl: record.imageUrl,
                            year: yearImg,
                            make: makeImg,
                            model: modelImg,
                            trim: trimImg,
                            desc: descExtractor(record.imageUrl),
                          });
                        }
                      }
                    }
                  },
                })}
              ></Table>
            )}
          </div>
        }
      </Spin>
    </div>
  );
};

export default OfferImagePopoverSearchContent;
