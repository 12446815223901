import styles from "./MarketingMaterialsCard.module.scss";
import Card, { Action } from "shared/components/CardV2";
import { useDataListURLIds } from "shared/components/dataListURL/useDataListURLIds";
import { getAllAdIds } from "shared/components/dataListURL/utils";
import { MarketingMaterialTableItem } from "shared/types/marketingMaterials";
import { Spin } from "antd";
import { useFetchTemplates } from "shared/hooks/designStudio/useFetchTemplates";
import { Template } from "shared/types/salesEnablement";
import { startCase, upperCase } from "lodash";
import EmptyMarketingMaterials from "./EmptyMarketingMaterials";
import { getMaterialBadge } from "./utils/getMarketingMaterialBadge";

type Props = {
  materials: MarketingMaterialTableItem[];
  actions: Action<MarketingMaterialTableItem>[];
  defaultAction: (id: string) => void;
  isLoading: boolean;
};

const Cards = ({ materials, actions, isLoading, defaultAction }: Props) => {
  const { selectedIds, toggleSelectedIds } =
    useDataListURLIds<MarketingMaterialTableItem>(getAllAdIds);
  const { templates = [] } = useFetchTemplates();

  return (
    <Spin spinning={isLoading}>
      <div className={styles.container}>
        {!materials.length && <EmptyMarketingMaterials />}
        {!!materials.length &&
          materials.map(material => {
            const materialStatusBadge = getMaterialBadge(material);
            const template = templates.find(t => t.id === material.templateId);
            const tags = template ? getTemplateTags(template) : [];
            const infos =
              material.templateDeliveryMethods?.map(startCase) ?? [];

            return (
              <Card<MarketingMaterialTableItem>
                isSelected={selectedIds.some(id => id === material.id)}
                onSelect={() => toggleSelectedIds(material.id)}
                item={material}
                key={material.id}
                title={material.name}
                statusInfo={materialStatusBadge}
                tags={tags}
                infos={infos}
                actions={actions}
                thumbnail={material.templateThumbnail}
                checkboxType="checkbox"
                defaultAction={() => defaultAction(material.id)}
              />
            );
          })}
      </div>
    </Spin>
  );
};

function getTemplateTags(template: Template) {
  const salesCycleTags = template.salesCycle?.map(startCase) ?? [];
  const audienceTags = template.audience?.map(startCase) ?? [];
  const materialTypeTags = template.materialType?.map(startCase) ?? [];
  const languageTags = Object.keys(template.files).map(upperCase) ?? [];

  const customizableTags = [
    template.customizable ? "Customizable" : "Non-customizable",
  ];

  return [
    ...customizableTags,
    ...salesCycleTags,
    ...audienceTags,
    ...materialTypeTags,
    ...languageTags,
  ];
}

export default Cards;
