import { Input } from "antd";
import { FormItem } from "./FormItem";
import { KeywordsSelect } from "./KeywordsSelect";
import { SelectMeta } from "./SelectMeta";
import { config } from "./config";

type Props = {
  schema: MGSchemas;
};

export const MetaFormItems = ({ schema }: Props) => {
  return (
    <>
      <div>
        <FormItem name="keyword">
          {config.keywordsUrl ? <KeywordsSelect /> : <SelectMeta mode="tags" />}
        </FormItem>
      </div>
      {schema.xmpschema.map(item => {
        return (
          <div key={item.field}>
            {item.type === "text" && item.field !== "keyword" && (
              <FormItem name={item.field}>
                <Input allowClear />
              </FormItem>
            )}
            {item.type === "textarea" && (
              <FormItem name={item.field}>
                <Input.TextArea rows={2} />
              </FormItem>
            )}
            {item.type === "select" && (
              <FormItem name={item.field}>
                <SelectMeta />
              </FormItem>
            )}
            {item.type === "multiselect" && (
              <FormItem name={item.field}>
                <SelectMeta />
              </FormItem>
            )}
          </div>
        );
      })}
    </>
  );
};
