import { useQuery } from "react-query";
import API from "services";
import { GetMediaColumnsQueryParams } from "shared/types/assetExporter";

export const useGetMediaColumns = (
  feedId: string,
  queryParams: GetMediaColumnsQueryParams,
) => {
  return useQuery(
    ["media-columns", feedId, queryParams],
    () => API.services.assetExporter.getMediaColumns(feedId, queryParams),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: !!feedId && !!queryParams,
    },
  );
};
