import { useTemplateRenderContext } from "screens/designStudio/hooks/TemplateRenderDataProvider";
import { Language, Page, TemplateFile } from "shared/types/salesEnablement";
import LayerInput from "./marketingMaterialForm/LayerInput";
import styles from "./MarketingMaterialFormComponent.module.scss";
import { Form } from "antd";

type Props = {
  file?: TemplateFile;
  disabled?: boolean;
  language: Language;
  disclosureFieldId?: string;
};

function MarketingMaterialFormComponent({
  file,
  disabled,
  language,
  disclosureFieldId,
}: Props) {
  return file?.type === "pdf" ? null : (
    <Form.Provider>
      <div className={styles.formPagesContainer}>
        <div className={styles.header}>
          <span>Component Page</span>
        </div>
        <div className={styles.formPagesWrapper}>
          {file?.pages?.map(page => (
            <FormComponent
              page={page}
              key={page.id}
              disabled={disabled}
              language={language}
              disclosureFieldId={disclosureFieldId}
            />
          ))}
        </div>
      </div>
    </Form.Provider>
  );
}

function FormComponent({
  page,
  disabled,
  language,
  disclosureFieldId,
}: {
  page: Page;
  disabled?: boolean;
  language: Language;
  disclosureFieldId?: string;
}) {
  const { onLayerHover } = useTemplateRenderContext();

  return (
    <>
      {page.layers?.map(layer => (
        <div
          className={styles.formComponentContainer}
          key={`layer-${layer.id}`}
          onMouseEnter={() => {
            !disabled && onLayerHover(layer);
          }}
        >
          <LayerInput
            layer={layer}
            language={language}
            disabled={disabled}
            disclosureFieldId={disclosureFieldId}
          />
        </div>
      ))}
    </>
  );
}

export default MarketingMaterialFormComponent;
