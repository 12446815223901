import API from "services";
import { useMutation } from "react-query";
import { queryClient } from "queryClient";
import { QUERY_KEYS } from "screens/adLibrary/marketingMaterials/utils/constants";
import { notification } from "antd";

import { ArchiveItem } from "shared/types/salesEnablementArchive";
import produce from "immer";

export const useUpdateArchiveOrderStatus = () => {
  return useMutation({
    mutationFn: API.services.salesEnablement.updateArchiveOrderStatus,
    onSettled: () => {
      queryClient.invalidateQueries([QUERY_KEYS.printArchives]);
    },
    onMutate: async archives => {
      await queryClient.cancelQueries(QUERY_KEYS.printArchives);

      const previousArchives: ArchiveItem[] =
        queryClient.getQueryData(QUERY_KEYS.printArchives) ?? [];

      if (!previousArchives) {
        return;
      }

      const updatedArchives = produce(previousArchives, draft => {
        draft.forEach(item => {
          const archiveToUpdate = archives.find(a => a.id === item.id);
          if (archiveToUpdate) {
            item.orderStatus = archiveToUpdate.orderStatus;
          }
        });
      });

      queryClient.setQueryData(QUERY_KEYS.printArchives, updatedArchives);

      return previousArchives;
    },
    onError: () => {
      notification.error({
        message: "Error",
        description: "Something went wrong updating the order status.",
      });
    },
  });
};
