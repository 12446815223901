import { useEffect, useState } from "react";
import { LinkOutlined, TagOutlined } from "@ant-design/icons";
import Wrapper from "../shared/Wrapper";
import styles from "./URL.module.scss";
import { EANode } from "../../../everythingAds.hooks/useContextAPI";
import { Spin } from "antd";
import { isUrlInputValid } from "utils/validators";
import API from "../../../../services";
import { DEFAULT_DIMENSION } from "shared/constants/everythingads";

const URL = (props: EANode) => {
  const {
    data: { url: urlData },
    selected,
  } = props;

  const { type, value } = urlData || {};

  const [loading, setLoading] = useState(false);
  const [screenshot, setScreenshot] = useState<string>();
  useEffect(() => {
    if (!value) return;

    const isUrl = isUrlInputValid(value);
    if (!isUrl) return;

    const fetchScreenshot = async () => {
      setLoading(true);

      const { result: base64 } = await API.services.common.getScreenshot({
        url: value,
        width: DEFAULT_DIMENSION.width,
        height: DEFAULT_DIMENSION.height,
      });

      setScreenshot(`data:image/jpeg;base64,${base64}`);
      setLoading(false);
    };

    fetchScreenshot();
  }, [value, setScreenshot]);

  return (
    <Wrapper title={value} selected={selected}>
      <div className={styles.url}>
        <div className={styles.content}>
          {type === "url" && <LinkOutlined className={styles.icon} />}

          {type === "label" && <TagOutlined className={styles.icon} />}
          <span className={styles.text}>{value}</span>
        </div>
      </div>

      {type === "url" && (
        <Spin tip="Loading..." spinning={loading}>
          <div className={styles.preview}>
            {screenshot && <img src={screenshot} alt="screenshot" />}
          </div>
        </Spin>
      )}
    </Wrapper>
  );
};

export default URL;
