import { QueryKey, UseQueryOptions, useQueries } from "react-query";
import { CreateAssetGroupSelection } from "screens/adLibrary/pmaxAssetGroups/pmaxLoadDrawer/hooks/useCreateNewAssetGroupSelector";
import API from "services";

const getGoogleCampaigns = async (selection: CreateAssetGroupSelection) => {
  const { result, error } = await API.services.adLibrary.getGoogleCampaigns(
    selection.adAccount.id,
  );

  if (error) {
    throw error;
  }

  return {
    ...result,
    ...selection,
  };
};

const useGetGoogleCampaigns = (
  selection: CreateAssetGroupSelection[],
  onSettled: (
    data: Awaited<ReturnType<typeof getGoogleCampaigns> | undefined>,
    error: Error | null,
  ) => void,
) => {
  const queries: readonly UseQueryOptions<
    unknown,
    Error | null,
    Awaited<ReturnType<typeof getGoogleCampaigns> | undefined>,
    QueryKey
  >[] = selection.map(currentSelection => ({
    queryKey: ["googleCampaigns", currentSelection.adAccount.id],
    queryFn: () => getGoogleCampaigns(currentSelection),
    staleTime: 0,
    onSettled,
    refetchOnWindowFocus: false,
  }));

  return useQueries(queries);
};

export default useGetGoogleCampaigns;
