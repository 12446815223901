import { Checkbox, Table, Typography } from "antd";
import styles from "./Portal.module.scss";
import { useFeedContext } from "../../shared/contexts/FeedContext";
import { getPortalConfigurationTableData } from "./utils/getPortalConfigurationTableData";
import { usePortalExportContext } from "./PortalExportContext";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { getSelectedPortalColumns } from "./utils/getSelectedPortalColumns";
import { RowType } from "../shared/types";
import { PortalConfigurationTableData } from "./types";
import { useMetadataValidation } from "./useMetadataValidation";

export const PortalConfigurationTable = () => {
  const { formValues, updateFormValues } = usePortalExportContext();
  const { columns: feedColumns } = useFeedContext();
  const validateMetadataQuery = useMetadataValidation();

  const handleCheckboxChange = (
    event: CheckboxChangeEvent,
    rowData: PortalConfigurationTableData,
  ) => {
    const updatedColumns = getSelectedPortalColumns(
      formValues.feedColumns,
      rowData.title,
      event.target.checked,
    );
    updateFormValues({ feedColumns: updatedColumns });
  };

  const columns = [
    {
      title: "Other feed columns to be also added as fields",
      render: (_: string, rowData: PortalConfigurationTableData) => (
        <Checkbox
          className={styles.checkbox}
          disabled={rowData.rowType === RowType.Media}
          checked={formValues.feedColumns.includes(rowData.title)}
          onChange={event => handleCheckboxChange(event, rowData)}
        >
          <span className={styles.checkboxLabel}>{rowData.title}</span>
        </Checkbox>
      ),
    },
  ];

  const tableData = getPortalConfigurationTableData(feedColumns);

  return (
    <div className={styles.tableWrapper}>
      {validateMetadataQuery.data === false && (
        <Typography.Text type="danger">
          The selected feed columns exceed the maximum data limit. Please select
          a smaller set of columns.
        </Typography.Text>
      )}
      <Table
        className={styles.table}
        columns={columns}
        dataSource={tableData}
        pagination={false}
        bordered
        size="small"
      />
    </div>
  );
};
