import {
  AdLoadAdStatusEnum,
  AdLoadColumnKey,
  AdLoadRuleDelimiter,
  AdLoadStepKey,
  LoadStepKeys,
  ReviewStepKeys,
} from "shared/types/adLibrary";
import { TitleRowType } from "./components/TitleRow";

export const adLoadStepTitles: Record<AdLoadStepKey, string> = {
  DESTINATION_SELECTION: "Load to Facebook",
  REVIEW_ADS_TO_LOAD: "Review Ad Load",
  LOAD_ADS: "Ads Loading to Facebook",
};

export const reviewStepTitles: Record<ReviewStepKeys, string> = {
  REVIEW_DESTINATION_URLS: "Review Destination URLs",
  CONVERT_TO_VIDEO: "Convert to Video",
  ASSIGN_PRODUCT_SETS_AD_LEVEL: "Assign Product Sets: Ad Level",
  ASSIGN_PRODUCT_SETS_IE_LEVEL: "Assign Product Sets: Instant Experience Level",
  LINK_INSTANT_EXPERIENCE: "Link Instant Experience",
  INVALID_DESTINATION_URL: "",
};

export const enabledButtonStyle = {
  color: "#fff",
  background: "#1890ff",
  borderColor: "#1890ff",
};

export const buttonColors: Record<TitleRowType, string> = {
  info: "rgba(24, 144, 255, 1)",
  error: "rgba(255, 77, 79, 1)",
  warning: "rgba(250, 173, 20, 1)",
};

export const columnPresets = {
  Campaigns: [
    "Ad Name",
    "Account Name",
    "Account ID",
    "Facebook Page Name",
    "Facebook Page ID",
    "Product Catalog",
    "Campaign Name",
    "Objective",
  ],
  "Ad Sets": [
    "Ad Name",
    "Ad Set Name",
    "Objective",
    "Campaign Name",
    "Product Catalog",
    "Product Set",
  ],
  Ads: [
    "Ad Name",
    "Format",
    "Destination URL",
    "Display URL",
    "Product Catalog",
    "Product Set",
    "Ad Set Name",
    "Campaign Name",
  ],
};

export const adsToLoadTableColumnNames: AdLoadColumnKey[] = [
  "Account Name",
  "Account ID",
  "Facebook Page Name",
  "Facebook Page ID",
  "Campaign Name",
  "Objective",
  "Ad Set Name",
  "Ad Name",
  "Format",
  "Instant Experience Name",
  "Destination URL",
  "Display URL",
  "Product Catalog",
  "Product Set",
  "Product Set",
  "CTA Button",
];

export const buttonTitlesForInnerStepOpeners: Partial<
  Record<ReviewStepKeys | LoadStepKeys, string>
> = {
  ASSIGN_PRODUCT_SETS_IE_LEVEL: "Assign Product Sets to Instant Experiences",
  ASSIGN_PRODUCT_SETS_AD_LEVEL: "Assign Product Sets",
  REVIEW_DESTINATION_URLS: "Review Destination URLs",
  CONVERT_TO_VIDEO: "Convert to Video",
  LOAD_AGAIN: "Load Again",
  FINISHED_LOADING: "Done",
  LOADING_ADS: "Loading",
};

export const disabledBtnTooltipText: Partial<Record<AdLoadStepKey, string>> = {
  DESTINATION_SELECTION: "All Ads Must have a destination before loading.",
  REVIEW_ADS_TO_LOAD:
    "At least one valid ad must be selected in order to load.",
  LOAD_ADS: "Ads are currently loading.",
};

export const stepUrlParamValues: Record<AdLoadStepKey, string> = {
  DESTINATION_SELECTION: "select",
  REVIEW_ADS_TO_LOAD: "review",
  LOAD_ADS: "load",
};

export const defaultReviewUrlQAIssue = {
  status: AdLoadAdStatusEnum.WARNING,
  message: "This Ad's Destination URL needs review",
  requiredStep: ReviewStepKeys.REVIEW_DESTINATION_URLS,
};

export const DRAWER_WIDTH = "calc(100vw - 48px)";

export const delimiterOptions: AdLoadRuleDelimiter[] = ["_", "-"];
