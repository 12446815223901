import { fabric } from "fabric";
import { FeedType, IConfigurationState } from "shared/types/configuration";
import * as fabricHelpers from "utils/helpers.fabric";
import * as offerHelpers from "utils/helpers.offer";
import { TData, TReloadArgs } from "./RenderTemplate";
import { isEmpty, isEqual } from "lodash";
import API from "services";
import {
  ICanvasObject,
  ILogoObject,
  IOffer,
  IProcessedOfferData,
  IQueryParameters,
  isStamp,
  OfferData,
  TVisibility,
} from "shared/types/assetBuilder";
import {
  IDimension,
  IExtendedFabricObject,
  IResponse,
} from "shared/types/designStudio";
import { IBrand } from "shared/types/brandManagement";
import { getHeight, getWidth } from "utils/fabric/helpers.utils";
import { IPlaceholder } from "utils/helpers.fabric";

/**
 * NOTE this import imports itself. This is fine since ES6 supports cyclic dependencies.
 * Refer to https://exploringjs.com/es6/ch_modules.html#sec_cyclic-dependencies
 *
 * The reason for this is to make few functions needs to be mocked in test file.
 * For example, the function "getReplacedFabricImage()" uses "getFabricImage()" and "createImagePlaceholder()" defined in this file.
 * Jest cannot mock these two functions when we need to test "getReplacedFabricImage()" unless we imports itself and use them.
 */
import * as helpers from "./RenderTemplate.utils";

type TFetchDataFromOfferArgs = {
  reloadingArgs: TReloadArgs; // updating data
  data?: TData; // existing data... NOTE: inital data is undefined from RenderTemplate.tsx
  config: IConfigurationState["config"];
  feed?: FeedType;
};

export const fetchDataFromOfferIfNotEqual = async (
  args: TFetchDataFromOfferArgs,
): Promise<TData> => {
  let updatedData: TData = {
    ...args.data,
  };

  // fetch new data if old offer (args.data.offer) is not equal to new offer (args.reloadingArgs.offer)
  if (!isEqual(args.data?.offer, args.reloadingArgs.offer)) {
    // frist assign new offer
    updatedData = {
      ...updatedData,
      offer: args.reloadingArgs.offer,
    };

    const { data } = args;
    const { offer } = args.reloadingArgs;

    // get raw offer data
    if (!isEqual(data?.offer?.offerData.vin, offer?.offerData.vin)) {
      // below will fill offer, and rawOffer in TData
      const offerDataWithAggrFields = offerHelpers.aggregateRebateFieldValues({
        row: offer?.offerData,
      } as any);

      const formattedOfferData = offerHelpers.formatFieldValues(
        offerDataWithAggrFields,
        true,
        // data?.dealer, // AV2-3653: Lithia/LADTech requested that this be disabled, but will be used later
      );

      updatedData = {
        ...updatedData,
        offer: {
          offerTypes: offer?.offerTypes || [],
          offerData: formattedOfferData.row,
        },
        rawOffer: offer?.offerData,
      };

      const { offerData } = offer || {};
      const { dealerCode, trim, modelCode, msrp } = offerData || {};
      const updatedMsrp = typeof msrp === "number" ? `${msrp}` : msrp;

      const queryParameters: IQueryParameters = {
        dealerCode: dealerCode || "",
        trim: trim || "",
        modelCode: modelCode || "",
        msrp: updatedMsrp?.replace(",", "") || "",
      };

      const fetchParamsKey = JSON.stringify(queryParameters); // stringfied parameters are set as key to a Record. Plz check useFetchRenderTemplate.tsx
      let offerList =
        args.reloadingArgs.renderTemplateCache?.numberAtThisPriceOfferList?.[
          fetchParamsKey
        ];

      if (!offerList || isEmpty(offerList)) {
        const url = `${args.config?.services.fetchOfferListUrl(
          queryParameters,
        )}`;

        const request: RequestInfo = new Request(url, {
          method: "GET",
          cache: "no-cache",
        });

        const { result: numberAtThisPriceResult } = await API.send<{
          result: {
            total: number;
            offerList: OfferData[];
          };
        }>(request);
        const { offerList: numberAtThisPriceOfferList } =
          numberAtThisPriceResult || {
            offerList: [],
          };

        /* 
          AV2-4265: When vehicles are sold, they are removed from ES, 
          but we still want to count them in the Number at This Price disclosure
        */
        if (!numberAtThisPriceOfferList?.length && offer?.offerData) {
          numberAtThisPriceOfferList.push({
            row: offer.offerData,
          } as unknown as OfferData);
        }

        offerList = numberAtThisPriceOfferList.map(
          offer =>
            offerHelpers.formatFieldValues(
              offer as unknown as IProcessedOfferData,
              true,
            ) as unknown as IOffer,
        );
      }

      updatedData = {
        ...updatedData,
        numberAtThisPriceOfferList: offerList,
      };
    }

    const { services } = args.config || {};

    let brand = args.reloadingArgs.renderTemplateCache?.oems?.find(
      tmpBrand =>
        tmpBrand.oem_name === args.reloadingArgs.offer?.offerData.make,
    );

    if (!brand) {
      const { make } = offer!.offerData;
      const lcMake = make.toLowerCase();
      const brandUrl = `${services?.getOemUrl}?name=${lcMake}`;
      const request = new Request(brandUrl, {
        method: "GET",
        cache: "no-cache",
      });

      try {
        const { result, error } = await API.send<IResponse<IBrand>>(request);
        if (error) {
          throw new Error("invalid brand, retrying without lowercase make");
        }
        brand = result?.oem;
      } catch (error) {
        const fallbackUrl = `${services?.getOemUrl}?name=${make}`;
        const fallbackRequest = new Request(fallbackUrl, {
          method: "GET",
          cache: "no-cache",
        });
        const { result: fallbackResult } = await API.send<IResponse<IBrand>>(
          fallbackRequest,
        );
        brand = fallbackResult?.oem;
      }
    }

    updatedData = {
      ...updatedData,
      oem: brand,
    };
  }

  return updatedData;
};

/**
 * This function will return image url of logo type placeholder in template.
 * It returns string if it finds one otherwise, return null.
 *
 * @param data
 * @param object
 */
export const getLogoImageUrl = (data: TData, object: ILogoObject) => {
  const {
    customData: { logoEventType },
  } = object;

  const { oem, dealer } = data;

  try {
    let logoUrlsFromS3: string | null = "";
    let logoUrlKey: string | null = "";
    if (logoEventType === "OEM_LOGO" || logoEventType === "BRAND_LOGO") {
      logoUrlsFromS3 = oem?.logo_urls_from_S3 || null;
      logoUrlKey = `${object.customData.logoDropZoneType}ImagesFromS3`;
    } else if (
      logoEventType === "STORE_LOGO" ||
      logoEventType === "ACCOUNT_LOGO"
    ) {
      logoUrlsFromS3 = dealer?.logo_urls_from_S3 || null;
      logoUrlKey = `${object.customData.logoDropZoneType}ImagesFromS3`;
    } else {
      logoUrlsFromS3 = oem?.logo_urls_from_S3 || null;
      logoUrlKey = `${object.customData.logoDropZoneType}EventImagesFromS3`;
    }

    const logoUrls = JSON.parse(logoUrlsFromS3 || "{}");
    const logoUrl = logoUrls[logoUrlKey]?.[0];

    if (!logoUrl) {
      return null;
    }

    // attach timestamp at the end of the url to ignore the cache
    const logoImageUrl = `${logoUrl}?timestamp=${Date.now()}`;

    return logoImageUrl;
  } catch (error) {
    return null;
  }
};

export const createImagePlaceholder = async (
  object: ICanvasObject,
  placeholder?: IPlaceholder,
) => {
  const width = placeholder?.dimension.width || getWidth(object);
  const height = placeholder?.dimension.height || getHeight(object);
  const placeholderCanvas = document.createElement("canvas");
  placeholderCanvas.width = width;
  placeholderCanvas.height = height;

  const ctx = placeholderCanvas.getContext("2d");
  ctx!.rect(0, 0, width, height);
  ctx!.fillStyle = "rgba(128, 128, 128, .5)";
  ctx!.fillRect(0, 0, width, height);

  // creating placeholder image
  const image = await new Promise<fabric.Image>(resolve => {
    fabric.Image.fromURL(
      placeholderCanvas.toDataURL(),
      img => {
        img.set({
          width,
          height,
          top: object.top,
          left: object.left,
        });

        resolve(img);
      },
      { crossOrigin: "anonymous" },
    );
  });

  return image;
};

/**
 * This function will replace image with imageUrl and center the image in the bounding box.
 * @param fabricImage
 * @param imageUrl
 */
export const getReplacedFabricImage = async (
  fabricImage: fabric.Image | fabric.Group,
  imageUrl: string,
  placeholder?: IPlaceholder,
  boundingRects?: {
    wrapperRect: ClientRect;
    canvasRect: ClientRect;
  },
  fitTo?: keyof IDimension,
  useRawImage?: boolean,
) => {
  let img: fabric.Image;
  try {
    img = await helpers.getFabricImage(imageUrl);
  } catch (err) {
    return await helpers.createImagePlaceholder(
      fabricImage as unknown as ICanvasObject,
      placeholder,
    );
  }
  const { name, customType, customData } =
    fabricImage as unknown as IExtendedFabricObject;

  const extendedImage = fabricHelpers.extendFabricObject({
    objectType: customType,
    object: !!placeholder
      ? fabricHelpers.returnRePositionedImage(
          img,
          placeholder,
          boundingRects,
          fitTo,
          useRawImage,
        )
      : img,
    properties: {
      ...customData,
    },
  });

  extendedImage.set({
    name,
    selectable: false,
    evented: false,
  });

  return extendedImage;
};

export const getFabricImage = (imageUrl: string) => {
  return new Promise<fabric.Image>((resolve, reject) => {
    try {
      fabric.Image.fromURL(
        imageUrl,
        img =>
          img
            ? resolve(img)
            : reject(new Error(`failed to load image with url: ${imageUrl}`)),
        { crossOrigin: "anonymous" },
      );
    } catch (err) {
      reject(new Error(`failed to load image with url: ${imageUrl}`));
    }
  });
};

export const getStampVisibility = (
  objects: fabric.Object[] | IExtendedFabricObject[],
  visibilities?: TVisibility[],
) => {
  const stamps = objects.filter(obj =>
    isStamp(obj as unknown as ICanvasObject),
  );

  return visibilities?.find(vis =>
    stamps.some(
      (stmp, idx) =>
        vis.id === (stmp as unknown as ICanvasObject).customData?.stampId &&
        vis.order === idx,
    ),
  );
};
