import { useEffect, useCallback, useRef } from "react";
import { message } from "antd";
import { createDataSource } from "../utils/helpers.table";
import { ReduceAmountError, useFetchAds } from "./useFetchAds";
import { transformMillisecondsToSeconds } from "utils/helpers.date";
import { isEmpty } from "lodash";
import { SelectedDates } from "shared/types/campaignManagement";
import {
  selectRequestParams,
  useAdReviewActions,
} from "redux/adReview/adReview.slice";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { SelectedStore } from "shared/hooks/userPreferences/useSelectedStore";

interface IFilters {
  selectedAdIds: string[];
  selectedStore?: SelectedStore;
  selectedDateRange: SelectedDates;
  isGroupedAds: boolean;
}

interface IUseAds {
  filters: IFilters;
}

const SINCE_POSITION = 0;
const TO_POSITION = 1;
const RETRY_LIMIT = 4;

const useAds = ({ filters }: IUseAds) => {
  const { resetLimit, reduceLimit } = useAdReviewActions();

  const { platform, limit } = useAppSelector(selectRequestParams);
  const numberOfRetries = useRef(0);

  const accountId = filters.selectedStore?.fbAccountId;
  const since = filters.selectedDateRange[SINCE_POSITION]
    ? transformMillisecondsToSeconds(filters.selectedDateRange[SINCE_POSITION])
    : undefined;
  const to = filters.selectedDateRange[TO_POSITION]
    ? transformMillisecondsToSeconds(filters.selectedDateRange[TO_POSITION])
    : undefined;

  const {
    ads,
    isSuccess: isGetAdsSuccess,
    isLoading: isLoadingFacebookAds,
    isFetching: isFetchingAds,
    error: getAdsError,
    isError: isGetAdsError,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useFetchAds(
    {
      platform: platform,
      accountId: accountId as string,
      since: since as number,
      to: to as number,
      limit: limit,
    },
    accountId != undefined && since != undefined && to != undefined,
  );

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  const handleRequestError = useCallback(() => {
    if (isGetAdsError && getAdsError instanceof ReduceAmountError === false) {
      message.error(
        getAdsError?.error?.message ||
          `An error occurred while pulling ads for ${accountId}`,
        6,
      );
    }
  }, [accountId, getAdsError, isGetAdsError]);

  useEffect(handleRequestError, [handleRequestError]);

  const handleReduceAmountError = useCallback(() => {
    if (getAdsError instanceof ReduceAmountError) {
      if (numberOfRetries.current < RETRY_LIMIT) {
        reduceLimit();
        numberOfRetries.current += 1;
      }
    }
  }, [getAdsError, reduceLimit]);

  useEffect(handleReduceAmountError, [handleReduceAmountError]);

  const clearRequestParams = () => {
    resetLimit();
    numberOfRetries.current = 0;
  };

  useEffect(clearRequestParams, [
    filters.selectedStore,
    filters.selectedDateRange,
    resetLimit,
  ]);

  const dataSource = createDataSource(ads, filters.isGroupedAds);

  return {
    ads,
    dataSource,
    isError: !isGetAdsSuccess,
    isLoading: (isLoadingFacebookAds || isFetchingAds) && isEmpty(ads),
  };
};
export default useAds;
