import { TabPaneProps, Tabs } from "antd";
import Assets from "./leftPanel/Assets";
import styles from "./LeftPanel.module.scss";

type IETabType = "Layers" | "Assets";

const IETab: Record<IETabType, string> = {
  Layers: "Layers",
  Assets: "Assets",
};
const layersPaneProps: TabPaneProps = {
  disabled: true,
  tab: IETab.Layers,
};

const assetsPaneProps: TabPaneProps = {
  tab: IETab.Assets,
};

const LeftPanel = () => {
  return (
    <Tabs className={`${styles.leftPanelTabs}`} defaultActiveKey={IETab.Assets}>
      <Tabs.TabPane key={IETab.Layers} {...layersPaneProps}></Tabs.TabPane>
      <Tabs.TabPane key={IETab.Assets} {...assetsPaneProps}>
        <Assets />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default LeftPanel;
