import { DownOutlined } from "@ant-design/icons";
import { Checkbox, Tag } from "antd";
import { Dispatch, SetStateAction, useState } from "react";
import { OfferData } from "shared/types/assetBuilder";
import { OfferType } from "shared/types/shared";
import styles from "./CollapsableHeader.module.scss";

interface Props {
  selectedOfferTypes: OfferType[];
  vin: string;
  duplicatedOfferData?: Partial<OfferData>;
}

interface Handlers {
  setSelectedOfferTypes: Dispatch<SetStateAction<OfferType[]>>;
  closeCollapse: () => Promise<void>;
  setActiveKey: Dispatch<SetStateAction<string[]>>;
}

const CollapsableHeader = (props: Props & Handlers) => {
  const selectableOfferTypes = [
    OfferType.Lease,
    OfferType.ZeroDownLease,
    OfferType.Finance,
    OfferType.Purchase,
    OfferType.APR,
  ];

  const {
    selectedOfferTypes,
    setSelectedOfferTypes,
    closeCollapse,
    setActiveKey,
    duplicatedOfferData,
  } = props;

  const getTitle = (offer: Partial<OfferData> | undefined) => {
    if (!offer || (!offer?.year && !offer?.make && !offer?.model))
      return "New Offer";

    const { year, make, model } = offer;
    return `${year} ${make} ${model}`;
  };

  const title = getTitle(duplicatedOfferData);

  const [indeterminate, setIndeterminate] = useState(false);
  const [checked, setChecked] = useState(false);

  const getUpdatedOfferTypes = (offerType: OfferType) => {
    if (selectedOfferTypes.includes(offerType))
      return [...selectedOfferTypes.filter(selected => selected !== offerType)];

    return [...selectedOfferTypes, offerType];
  };

  const getContainsAll = (selectedOfferTypes: OfferType[]) => {
    return selectableOfferTypes.every(selectable =>
      selectedOfferTypes.find(selected => selected === selectable),
    );
  };

  const getContainsAny = (selectedOfferTypes: OfferType[]) => {
    return selectableOfferTypes.some(selectable =>
      selectedOfferTypes.find(selected => selected === selectable),
    );
  };

  const toggleOfferType = (offerType: OfferType) => {
    const updatedSelectedOfferTypes = getUpdatedOfferTypes(offerType);
    setSelectedOfferTypes(updatedSelectedOfferTypes);

    const containsAll = getContainsAll(updatedSelectedOfferTypes);
    const containsAny = getContainsAny(updatedSelectedOfferTypes);
    setIndeterminate(containsAny && !containsAll);

    setActiveKey(
      updatedSelectedOfferTypes.map(offerType => {
        return `offer-type-collapse-${offerType}`;
      }),
    );

    if (containsAll) setChecked(true);
    else setChecked(false);
  };

  const onClickSelectAll = () => {
    const containsAll = getContainsAll(selectedOfferTypes);

    if (containsAll) {
      setSelectedOfferTypes([]);
      setChecked(false);
      setActiveKey([]);
    } else {
      setSelectedOfferTypes([...selectableOfferTypes]);
      setChecked(true);
      setActiveKey(
        selectableOfferTypes.map(offerType => {
          return `offer-type-collapse-${offerType}`;
        }),
      );
    }
  };

  return (
    <div className={styles.CollapsableHeader}>
      <div className={styles.Left}>
        <div className={styles.Top}>
          <div className={styles.Title}>
            <span>{title}</span>
          </div>
          <div className={styles.OfferTypeTags}>
            {selectableOfferTypes.map(offerType => {
              return (
                <span key={`offer-type-${offerType}`}>
                  <Tag
                    className={`${styles.OfferTypeTag} ${
                      !selectedOfferTypes.includes(offerType)
                        ? styles.Disabled
                        : ""
                    }`}
                    onClick={() => toggleOfferType(offerType)}
                  >
                    {offerType}
                  </Tag>
                </span>
              );
            })}
          </div>
          <div className={styles.SelectAll}>
            <Checkbox
              indeterminate={indeterminate}
              checked={checked}
              onChange={() => onClickSelectAll()}
            >
              Select All
            </Checkbox>
          </div>
        </div>
      </div>
      <div className={styles.Right}>
        <div className={styles.Top}>
          <div className={styles.Tools}>
            <DownOutlined
              onClick={() => {
                if (props.vin) closeCollapse();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollapsableHeader;
