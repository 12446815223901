import { Tag, Tooltip, TooltipProps } from "antd";
import { NameID } from "shared/types/adLibrary";

type Props = { tooltipProps?: Partial<TooltipProps> } & NameID;

const NameTag = ({ id, name, tooltipProps }: Props) => {
  return (
    <Tooltip title={id} {...(tooltipProps ?? {})}>
      <Tag key={id}>{name}</Tag>
    </Tooltip>
  );
};

export default NameTag;
