import { Form, Row, Col } from "antd";
import { IAd } from "shared/types/adLibrary";
import DynamicVariablesInput from "shared/components/DynamicVariablesInput";
import { AdType } from "screens/adLibrary/facebookUtils/types";
import { DynamicCatalogType } from "shared/components/DynamicVariableSelect";
import { areFieldsRequired as required } from "screens/adLibrary/adWizard.utils";

interface IProps {
  adEdit?: IAd;
  adType?: AdType;
}

const getCatalog = (adType?: AdType): DynamicCatalogType => {
  if (adType === AdType.AIA) return "AUTOMOTIVE_CATALOG";
  if (adType === AdType.FTA) return "TRAVEL_CATALOG";
  return "PRODUCT_CATALOG";
};

export const AdAIAForm = ({ adEdit, adType }: IProps) => {
  const catalog = getCatalog(adType);

  return (
    <div>
      <Row gutter={24} align="middle">
        <Col span={14}>
          <Form.Item
            name={["visuals", "primaryText"]}
            label="Primary Text"
            rules={[
              {
                required,
                whitespace: true,
                message: "Please add a primary text",
              },
            ]}
          >
            <DynamicVariablesInput
              textAreaWidth={400}
              autoSize={{ minRows: 2, maxRows: 6 }}
              catalog={catalog}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} align="middle">
        <Col span={14}>
          <Form.Item
            name={["visuals", "headline"]}
            label="Headline"
            rules={[
              {
                required,
                whitespace: true,
                message: "Please add a headline",
              },
            ]}
          >
            <DynamicVariablesInput
              catalog={catalog}
              textAreaWidth={400}
              maxLength={120}
              showCount
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} align="middle">
        <Col span={14}>
          <Form.Item
            name={["visuals", "headlineDescription"]}
            label="News Feed Link Description"
            rules={[
              {
                required,
                whitespace: true,
                message: "Please add a news feed link description",
              },
            ]}
          >
            <DynamicVariablesInput
              catalog={catalog}
              textAreaWidth={400}
              maxLength={120}
              showCount
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};
