import { Spin } from "antd";

export const SpinnerLoading = () => {
  return (
    <Spin
      spinning={true}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    />
  );
};
