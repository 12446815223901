import { memo } from "react";
import { Form, Radio } from "antd";
import {
  ElementType,
  InstantExperienceElementStyle,
} from "shared/types/adLibrary";
import { getLayoutOptionText } from "./utils";

interface IProps {
  disabled?: boolean;
  layoutOption?: InstantExperienceElementStyle;
  elementType: ElementType;
  onChange?: (
    InstantExperienceElementStyle: InstantExperienceElementStyle,
  ) => void;
}

const optionsByElementType: Partial<
  Record<ElementType, InstantExperienceElementStyle[]>
> = {
  PHOTO: [
    InstantExperienceElementStyle.FIT_TO_WIDTH,
    InstantExperienceElementStyle.FIT_TO_WIDTH_EXPANDABLE,
    InstantExperienceElementStyle.FIT_TO_HEIGHT,
  ],
  VIDEO: [
    InstantExperienceElementStyle.FIT_TO_WIDTH,
    InstantExperienceElementStyle.FIT_TO_HEIGHT,
  ],
};

const Layout = ({ elementType, layoutOption, onChange, disabled }: IProps) => {
  const options = optionsByElementType[elementType];
  return (
    <Form.Item label="Fit To:" required>
      <Radio.Group
        disabled={disabled}
        value={layoutOption}
        onChange={e =>
          onChange?.(e.target.value as InstantExperienceElementStyle)
        }
      >
        {options?.map(option => (
          <Radio.Button key={option} value={option}>
            {getLayoutOptionText(elementType, option)}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

export default memo(Layout);
