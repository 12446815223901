import { message, Modal } from "antd";
import { TRule } from "shared/types/assetExporter";
import API from "services";

type deleteRuleParams = {
  rules: TRule[];
  selectedRuleIndex: number;
  feedId: string;
  columnName: string | undefined;
  getSmartColumns: () => void;
};
export const deleteRule = async (params: deleteRuleParams) => {
  const { rules, selectedRuleIndex, feedId, columnName, getSmartColumns } =
    params;
  const rule = rules[selectedRuleIndex];

  Modal.confirm({
    title: (
      <div>
        <span>
          Are you sure you want to delete&nbsp;
          <span style={{ fontWeight: "bold" }}>{rule.name}</span>
          &nbsp;rule?
        </span>
      </div>
    ),
    onOk: async () => {
      const delCol = API.services.assetExporter.deleteColRule;
      const idx = selectedRuleIndex;
      const res = await delCol(feedId, columnName!, idx);
      if (res.error) {
        message.error(res.error.message);
        return;
      }
      await getSmartColumns();
    },
  });
};

export const isTemplateSelected = (rule: Partial<TRule>) =>
  rule.then?.type !== "template" || rule.then?.value;
