import {
  CheckOutlined,
  ControlOutlined,
  LoadingOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Checkbox,
  DatePicker,
  Input,
  notification,
  Spin,
  Tooltip,
} from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import "antd/dist/antd.css";
import styles from "./Cell.module.scss";
import {
  OfferData,
  OfferRebateKeys,
  OfferEditFieldObject,
  RepeatableOfferKeys,
  IAssetBuilderState,
  SingletonOfferKeys,
} from "shared/types/assetBuilder";
import { OfferType } from "shared/types/shared";
import Carousel from "./cell/Carousel";
import { TCollapsableTypes } from "../../OfferTypeCollapse";
import API from "services";
import { dateFormat } from "shared/constants/dataManagement";
import { returnMomentTime } from "utils/helpers";
import TextArea from "./cell/TextArea";
import { queryClient } from "queryClient";
import OfferImage from "./cell/OfferImage";
import { getOfferKey } from "./Cell.utils";
import RevertNotification from "./cell/RevertNotification";
import { TOfferListSection } from "screens/assetBuilder/offers/select.hooks/useOfferList";
import { useParams } from "react-router-dom";
import PaymentEngineModal, {
  TOfferListData,
} from "screens/assetBuilder/PaymentEngineModal";
import { shallowEqual } from "react-redux";
import { isEmpty } from "lodash";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { editOffer } from "redux/assetBuilder/assetBuilder.slice";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

type CellType =
  | "offer_info"
  | "text"
  | "date"
  | "computed"
  | "score"
  | "image"
  | "carousel";

type TSavingState = "saving" | "idle" | "error" | "completed";
export type TCellOfferKey =
  | keyof OfferData
  | RepeatableOfferKeys
  | OfferRebateKeys;

export interface Props {
  vin?: string;
  offerType?: TCollapsableTypes;
  offerListData?: TOfferListData;
  type: CellType;
  title?: string;
  offerKey?: TCellOfferKey;
  editable?: boolean;
  value?: string;
  isEditDisabled?: boolean;
  isOfferTypeSelected?: boolean;
  allowPaymentEngine?: boolean;
  warningMessage?: string;
  parser?: (value: string) => string;
  formatter?: (value: string) => string;
  isRevertable?: boolean;
  feedOriginalValue?: string;
  repeatableIndex?: number;
  sectionKey?: TOfferListSection;
  revertToOriginalSession?: boolean;
  selectedOfferTypes?: OfferType[];
}

export interface Handlers {
  setEditOfferData?: () => void;
  onOfferTypeUpdate?: (updatedOfferType: OfferType, shouldAdd: boolean) => void;
  setSelectedOfferTypes?: Dispatch<SetStateAction<OfferType[]>>;
  setActiveKey?: Dispatch<SetStateAction<string[]>>;
  onSessionUpdate?: (
    offerKey: Props["offerKey"],
    value: string | null,
    originalSessionValue?: string,
  ) => void;
  setVin?: Dispatch<SetStateAction<string>>;
  togglePaymentEngineModal?: (
    initialSelectedRow: OfferEditFieldObject | null,
  ) => void;
}

const Cell = (props: Props & Handlers) => {
  const componentSize = useMemo<SizeType>(() => "middle", []);
  const getPlaceholderText = useCallback(
    (text: string | undefined) => text ?? "N/A",
    [],
  );
  const parseScore = useCallback((score?: string) => {
    if (!score) return 0;

    try {
      return Number.parseInt(score);
    } catch (err) {
      return 0;
    }
  }, []);

  const [saving, setSaving] = useState<TSavingState>("idle");
  const [errorMessage, setErrorMessage] = useState<string>();
  const [value, setValue] = useState<string | undefined>(props.value);
  const originalSessionValue = useMemo(() => props.value, [props.value]);
  const isModified = useMemo(
    () => originalSessionValue !== value,
    [originalSessionValue, value],
  );

  useEffect(() => {
    if (props.revertToOriginalSession) {
      setValue(props.value);
    }
  }, [props.value, props.revertToOriginalSession]);

  const revertTooltipMessage = useMemo(
    () =>
      `This value has been updated. Click to revert back to feed value ${
        props.feedOriginalValue || originalSessionValue || "-"
      }.`,
    [originalSessionValue, props.feedOriginalValue],
  );

  const routeParams = useParams<{ orderId: string }>();
  const [showDSModal, toggleDSModal] = useState<boolean>(false);
  const [editedVin, setEditedVin] = useState<string>();

  const sectionKey = useMemo(() => props.sectionKey, [props.sectionKey]);

  const timeoutIdRef = useRef<NodeJS.Timeout>();
  const textInputRef = useRef<Input | null>(null);
  const dispatch = useAppDispatch();

  const onChange = useCallback<(value?: string) => Promise<TSavingState>>(
    value => {
      return new Promise<TSavingState>(resolve => {
        if (sectionKey === "new") {
          const processedValue = props.parser?.(value || "") || value;
          setValue(processedValue);
          // new offer
          if (!props.offerKey) return;

          const offerKey = getOfferKey(
            props.offerKey,
            props.repeatableIndex,
          ) as SingletonOfferKeys | RepeatableOfferKeys;
          dispatch(editOffer({ key: offerKey, value: value ?? "" }));
          offerKey === "vin" && props.setVin && props.setVin(value ?? "");
          return;
        }

        if (!props.vin || !props.offerKey) return;

        const offerKey = getOfferKey(props.offerKey, props.repeatableIndex);

        if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);

        const processedValue = props.parser?.(value || "") || value;
        setValue(processedValue);

        setErrorMessage(undefined);
        setSaving("saving");

        timeoutIdRef.current = setTimeout(() => {
          API.services.assetBuilder
            .updateOfferFields({
              vin: props.vin,
              orderId: routeParams.orderId,
              keyValues: {
                [offerKey]: {
                  value: processedValue,
                },
              },
              sectionKey: sectionKey!,
            })
            .then(() => {
              setSaving("completed");

              const isSessionUpdated = processedValue !== originalSessionValue;
              const okToProceed = isSessionUpdated && !!processedValue;
              props.onSessionUpdate?.(
                props.offerKey,
                okToProceed ? processedValue! : null,
                originalSessionValue,
              ); // null means remove the session record

              if (isSessionUpdated)
                queryClient.invalidateQueries(["offer-list"]);

              resolve("completed");
            })
            .catch(() => {
              setErrorMessage("Update failed!");
              setSaving("error");
              resolve("error");
            });
        }, 700);
      });
    },
    [dispatch, originalSessionValue, props, routeParams.orderId, sectionKey],
  );

  const getUpdatedOfferTypes = (checked: boolean) => {
    if (checked)
      return [
        ...(props.selectedOfferTypes ?? []),
        props.offerType as OfferType,
      ];

    return (props.selectedOfferTypes ?? []).filter(
      offerType => offerType !== (props.offerType as OfferType),
    );
  };

  const onCheckBoxChange = (e: CheckboxChangeEvent) => {
    if (!props.offerType) return;

    const {
      target: { checked },
    } = e;

    props.onOfferTypeUpdate?.(
      props.offerType as OfferType, // safe since we check if props.offerType is not equal to 'vehicleInfo'
      checked,
    );

    const updatedSelectedOfferTypes = getUpdatedOfferTypes(checked);

    props.setSelectedOfferTypes?.(updatedSelectedOfferTypes);
    props.setActiveKey?.(
      updatedSelectedOfferTypes.map(offerType => {
        return `offer-type-collapse-${offerType}`;
      }),
    );
  };

  useEffect(() => {
    switch (saving) {
      case "completed":
        // NOTE: Reason why assigning timeout id is to cancel current state of saving if new input is being inserted
        timeoutIdRef.current = setTimeout(() => {
          setSaving("idle");
        }, 2000);
        break;
    }
  }, [saving]);

  const revertButtonComponent = useMemo(
    () => (
      <Tooltip title={revertTooltipMessage}>
        <RollbackOutlined
          className={styles.RevertIcon}
          onClick={async e => {
            e.preventDefault();
            e.stopPropagation();

            if (!props.vin) return;

            /**
             * When this button is clicked, we need to revert the value back to the original feed value, not the original session value.
             * See the chat here, https://constellationagency.slack.com/archives/C0201G6DC1M/p1625258975006000
             */

            // Get original feed value
            const currentValue = value; // This will be used when user wants to "Undo" the rollback.
            const rawFeedValue =
              props.feedOriginalValue || originalSessionValue;

            const savingStateAfterRevert = await onChange(rawFeedValue);

            const notificationKey = `notification_key_revert_${props.vin}`;

            const undo = async () => {
              // back to the value before the revert
              const savingStateAfterUndo = await onChange(currentValue);
              const notificationType: "success" | "error" =
                savingStateAfterUndo === "completed" ? "success" : "error";
              const message =
                savingStateAfterUndo === "completed"
                  ? "Undo Success"
                  : "Undo Failed";
              const description =
                savingStateAfterUndo === "completed"
                  ? "The value reverted back to the recent change."
                  : "Unable to undo the value to its latest change.";

              notification[notificationType]({
                key: notificationKey,
                message,
                description,
              });
            };

            const notificationType: "info" | "error" =
              savingStateAfterRevert === "completed" ? "info" : "error";
            const message =
              savingStateAfterRevert === "completed"
                ? "Field Value Reverted"
                : "Revert Failed";
            const description: string | ReactNode =
              savingStateAfterRevert === "completed" ? (
                <RevertNotification
                  title={props.title}
                  rawFeedValue={rawFeedValue}
                  undo={undo}
                />
              ) : (
                "Unable to revert the value to its raw feed value."
              );

            notification[notificationType]({
              key: notificationKey,
              message,
              description,
            });
          }}
        />
      </Tooltip>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [originalSessionValue, value],
  );

  const usingTextarea = useMemo(
    () =>
      props.offerKey?.toLowerCase().includes("disclosure") &&
      props.type === "text",
    [props.offerKey, props.type],
  );

  const onImageUrlChange = useCallback(
    (url: string) => {
      onChange(url);
    },
    [onChange],
  );

  const notDisplayVin =
    props.title?.toLowerCase() === "vin" && (props.value ?? "").length > 17;

  const { offerData, editedKeys, savedOrder } = useAppSelector(
    ({ assetBuilder }: { assetBuilder: IAssetBuilderState }) => ({
      offerData: assetBuilder.offerData,
      editedKeys: assetBuilder.editedKeys,
      savedOrder: assetBuilder.savedOrder,
    }),
    shallowEqual,
  );

  const { orderId } = savedOrder || {};
  const { offerKey } = props;

  useEffect(() => {
    if (
      isEmpty(editedKeys) ||
      !offerData ||
      !orderId ||
      !offerKey ||
      !editedVin ||
      offerData.vin !== editedVin
    )
      return;

    const keysToUpdate = Object.entries(editedKeys)
      .filter(([, value]) => !!value)
      .map(([key]) => key);

    const toUpdateValue = offerData[offerKey as keyof OfferData];
    if (Array.isArray(toUpdateValue)) return;

    const shouldProceedToUpdate =
      keysToUpdate.includes(offerKey!) && toUpdateValue;
    if (!shouldProceedToUpdate) return;
    setValue(toUpdateValue);
  }, [orderId, offerKey, offerData, editedKeys, setValue, editedVin]);

  const { title } = props;
  const toggleDS = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (!offerKey || !title || !value) return;

      e.preventDefault();
      e.stopPropagation();

      toggleDSModal(true);
    },
    [offerKey, title, value],
  );

  const initialSelectedRow = {
    field: props.offerKey as keyof OfferData,
    title: props.title,
    value: value,
    vin: props.vin,
  } as unknown as OfferEditFieldObject;

  const formattedInputValue = props.formatter?.(value || "") || value;

  const showCheckBox =
    props.type === "offer_info" &&
    props.offerType !== "vehicleInfo" &&
    props.offerType !== OfferType.Misc;

  return (
    <div className={`${styles.Cell} ${saving === "error" ? styles.Error : ""}`}>
      {props.title && (
        <div className={styles.Title}>
          {showCheckBox && (
            <Checkbox
              checked={!!props.isOfferTypeSelected}
              onClick={e => {
                e.stopPropagation();
              }}
              onChange={onCheckBoxChange}
            />
          )}
          <span className={styles.TitleTextWrapper}>{props.title}</span>
        </div>
      )}
      <div className={styles.Content}>
        {props.type === "image" && (
          <OfferImage
            vin={props.vin}
            url={props.value}
            setEditOfferData={props.setEditOfferData}
            onImageUrlChange={onImageUrlChange}
          />
        )}
        {props.type === "carousel" && (
          <Carousel value={props.value} title={props.title} />
        )}
        {props.type === "text" && (
          <>
            {!props.editable && !notDisplayVin && (
              <span>
                {props.formatter?.(props.value || "") || props.value || "N/A"}
              </span>
            )}

            {props.editable && (
              <>
                <div className={styles.Input}>
                  {usingTextarea ? (
                    <TextArea
                      value={value}
                      isEditDisabled={props.isEditDisabled}
                      className={`${isModified ? styles.Modified : ""}`}
                      isModified={isModified}
                      revertButtonComponent={revertButtonComponent}
                      onChange={onChange}
                    />
                  ) : (
                    <Input
                      ref={textInputRef}
                      className={`${isModified ? styles.Modified : ""}`}
                      size={componentSize}
                      disabled={props.isEditDisabled}
                      placeholder={getPlaceholderText(undefined)}
                      value={formattedInputValue}
                      suffix={
                        props.isRevertable ||
                        originalSessionValue !== formattedInputValue ? (
                          revertButtonComponent
                        ) : (
                          <span />
                        )
                      } // empty span is needed here. Refer https://ant.design/components/input/#FAQ
                      // To prevent the collapse from opening
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onChange={e => onChange(e.target.value)}
                    />
                  )}

                  {props.allowPaymentEngine && (
                    <>
                      <Button
                        className={styles.PaymentEngineButton}
                        size={componentSize}
                        icon={<ControlOutlined />}
                        disabled={props.isEditDisabled}
                        onClick={toggleDS}
                      />
                      <PaymentEngineModal
                        showDSModal={showDSModal}
                        toggleDSModal={toggleDSModal}
                        setEditedVin={setEditedVin}
                        initialSelectedRow={initialSelectedRow}
                        offerListData={props.offerListData}
                        vin={props.vin ?? ""}
                      />
                    </>
                  )}
                </div>
              </>
            )}
          </>
        )}

        {props.type === "computed" && (
          <Input
            className={styles.Disabled}
            disabled
            size={componentSize}
            placeholder={getPlaceholderText(undefined)}
            value={props.value}
          />
        )}
        {props.type === "date" && (
          <DatePicker
            size={componentSize}
            className={styles.DatePicker}
            disabled={props.isEditDisabled}
            format={dateFormat}
            value={returnMomentTime(value)}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onChange={value =>
              onChange(value ? `${(value.unix() || 0) * 1000}` : "")
            }
          />
        )}
        {props.type === "score" && (
          <div className={styles.ScoreContainer}>
            {/* Default value for score is N/A */}
            {props.value !== "N/A" && (
              <Badge
                color={parseScore(props.value) <= 75 ? "#FF4D4F" : "#52C31B"}
              />
            )}
            <span>{props.value ?? "N/A"}</span>
          </div>
        )}

        <div className={styles.WarningMessage}>
          <div className={styles.SavingMessage}>
            {saving === "saving" && (
              <Spin
                className={styles.SpinnerContainer}
                spinning={saving === "saving"}
                indicator={
                  <LoadingOutlined className={styles.LoadingSpinner} />
                }
              />
            )}

            {saving === "completed" && (
              <CheckOutlined style={{ color: "#65C645" }} />
            )}
            <span className={styles.Message}>
              {saving === "saving" && "Saving..."}
              {saving === "completed" && "Saved"}
              {saving === "error" && (
                <span className={styles.Error}>{errorMessage || "Error!"}</span>
              )}
            </span>

            {saving === "idle" && props.warningMessage}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cell;
