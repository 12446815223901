import API from "services";

export const getPublicDashboards = async () => {
  const { result, error } =
    await API.services.dashboardManagement.getPublicDashboards();

  if (error || !result) {
    throw Error(error?.message || "Something went wrong");
  }

  return result?.dashboards;
};
