import { useEverythingAdsData } from "screens/adLibrary/shared/hooks/dataListHooks";
import { useFetchInstantExperiences } from "shared/hooks/adLibrary/useFetchInstantExperiences";
import { InstantExperienceTableEntry } from "shared/types/adLibrary";

export const useEverythingAds = () => {
  const { data, isLoading, isError } = useFetchInstantExperiences();

  return useEverythingAdsData({
    data: data as InstantExperienceTableEntry[],
    isLoading,
    isError,
  });
};
