import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";

import {
  AutoComplete,
  Button,
  Input,
  Modal,
  Popover,
  Spin,
  Tooltip,
} from "antd";

import { SearchOutlined } from "@ant-design/icons";

import {
  FeedConfigState,
  FeedTblRow,
  MediaInput,
} from "shared/types/assetExporter";

import styles from "./Toolbar.module.scss";
import ColumnsOutlined from "shared/icons/ColumnsOutlined";
import { useAdEngineActions } from "redux/assetExporter/assetExporter.slice";
import { useAppSelector } from "shared/hooks/useAppSelector";

interface IToolbarProps {
  feedConfig: FeedConfigState;
  feedId: string;
  feedTableRows: FeedTblRow[];
  loading: boolean;
  smartColumnNames: string[];
  feedName: string;
  searchValue: string;
  searchPlaceholder: string;
  searchLoading: boolean;
}

interface IToolbarHandlers {
  setSelectedSmartColumn: Dispatch<SetStateAction<string | undefined>>;
  toggleSmartColumnModal: Dispatch<SetStateAction<boolean>>;
  setFeedConfig: Dispatch<SetStateAction<FeedConfigState>>;
  onSearch: (value: string) => void;
  setDisplayColumn: (value: boolean) => void;
}

type ToolbarProps = IToolbarProps & IToolbarHandlers;
const { Search } = Input;

export const Toolbar = ({
  searchValue,
  searchPlaceholder,
  onSearch,
  setDisplayColumn,
  ...props
}: ToolbarProps) => {
  const actions = useAdEngineActions();
  const feedType = useAppSelector(
    state => state.assetExporter.configure.feedType,
  );
  const {
    orderedColumns,
    imageInput,
    imageOutput,
    videoInput,
    videoEndInput,
    videoOutput,
    videoFrameStart,
    videoFrameEnd,
    videoFrameRate,
  } = props.feedConfig;

  const columnDataSource = useMemo(() => {
    const assetCols = ["image[0].url", "video[0].url"];
    return (orderedColumns || []).concat(assetCols);
  }, [orderedColumns]);

  const [filteredDataSource, setFilteredDataSource] = useState<string[]>();
  const [displayConfigBtns, setDisplayConfigBtns] = useState(false);

  useEffect(() => {
    setFilteredDataSource(columnDataSource);
  }, [columnDataSource]);

  const updateConfig = (key: MediaInput, value: string) => {
    props.setFeedConfig({
      ...props.feedConfig,
      [key]: value,
    });
  };

  return (
    <div className={styles.summary}>
      <div className={styles.toolbar}>
        <div className={styles.left}>
          <div className={styles.configName}>
            <Button
              onClick={e => {
                e.preventDefault();
                setDisplayConfigBtns(prev => !prev);
              }}
              className={styles.feedSelectButton}
            >
              Advanced Options
            </Button>
            <Button
              onClick={() => actions.toggleConfigureDrawer(true)}
              className={styles.feedSelectButton}
              data-cy="export-btn"
            >
              Export
            </Button>
          </div>
        </div>
        <div className={styles.search}>
          <Tooltip title={"Enter Value"} trigger={"focus"} placement="topLeft">
            <Search
              allowClear={true}
              value={searchValue}
              data-cy="search-toolbar-input"
              className="search-toolbar-input"
              placeholder={searchPlaceholder}
              onChange={value => {
                onSearch(value.target.value);
              }}
              disabled={props.searchLoading}
              suffix={
                props?.searchLoading && (
                  <Spin
                    className={styles.searchSpin}
                    spinning={true}
                    size="small"
                  />
                )
              }
            />
          </Tooltip>
          <Tooltip title={"Select columns to display"}>
            <Button
              icon={<ColumnsOutlined />}
              onClick={() => setDisplayColumn(true)}
            />
          </Tooltip>
        </div>

        {displayConfigBtns && (
          <div className={styles.right}>
            <div className="smart-column">
              <span className="feed-menu-button">
                <Button
                  className="smart-column-add-button"
                  type="primary"
                  icon={<SearchOutlined />}
                  disabled={props.loading}
                  onClick={e => {
                    e.preventDefault();

                    props.setSelectedSmartColumn(undefined);

                    Modal.confirm({
                      className: "smart-column-add-modal",
                      title: "Name of the smart column?",

                      content: (
                        <div>
                          <Input
                            className="smart-column-name-input"
                            placeholder="Smart column name"
                            onChange={evt => {
                              // NOTE: all spaces will be replaced with _
                              props.setSelectedSmartColumn(
                                evt.target.value.trim().replace(/\s+/g, "_"),
                              );
                            }}
                          />
                        </div>
                      ),
                      onCancel: () => {
                        props.setSelectedSmartColumn(undefined);
                      },
                      onOk: () => {
                        props.toggleSmartColumnModal(true);
                      },
                    });
                  }}
                >
                  Smart Column
                </Button>
              </span>
            </div>

            <div className="mark-as-input">
              <span className="feed-menu-button">
                <Popover
                  placement="bottom"
                  trigger="click"
                  content={
                    <div
                      className="mark-image-video-wrapper"
                      data-cy="mark-image-input-wrapper"
                    >
                      <AutoComplete
                        defaultValue={imageInput}
                        dataSource={filteredDataSource}
                        onSearch={val => {
                          setFilteredDataSource(
                            !val || val.trim() === ""
                              ? columnDataSource
                              : columnDataSource?.filter(dsVal =>
                                  dsVal
                                    .toLowerCase()
                                    .includes(val.toLowerCase()),
                                ),
                          );
                        }}
                        onChange={val => updateConfig("imageInput", val)}
                      ></AutoComplete>
                      {imageOutput !== undefined &&
                        !orderedColumns
                          ?.concat(
                            [...Array(6).keys()].map(
                              idx => `image[${idx}].url`,
                            ),
                          )
                          .includes(imageOutput || "") && (
                          <div className="warning">
                            * Currently selected column is new column. It will
                            be created in the final feed data.
                          </div>
                        )}
                    </div>
                  }
                >
                  <Button
                    type="primary"
                    className="mark-image-input-button"
                    data-cy="mark-image-input-button"
                  >
                    Mark Image Input
                  </Button>
                </Popover>
              </span>
            </div>

            <div className="mark-as-output">
              <span className="feed-menu-button">
                <Popover
                  placement="bottom"
                  trigger="click"
                  content={
                    <div
                      className="mark-image-video-wrapper"
                      data-cy="mark-image-output-wrapper"
                    >
                      <AutoComplete
                        defaultValue={imageOutput}
                        dataSource={filteredDataSource}
                        onSearch={val => {
                          setFilteredDataSource(
                            !val || val.trim() === ""
                              ? columnDataSource
                              : columnDataSource?.filter(dsVal =>
                                  dsVal
                                    .toLowerCase()
                                    .includes(val.toLowerCase()),
                                ),
                          );
                        }}
                        onChange={val => updateConfig("imageOutput", val)}
                      ></AutoComplete>
                      {imageOutput !== undefined &&
                        !orderedColumns
                          ?.concat(
                            [...Array(6).keys()].map(
                              idx => `image[${idx}].url`,
                            ),
                          )
                          .includes(imageOutput || "") && (
                          <div className="warning">
                            * Currently selected column is new column. It will
                            be created in the final feed data.
                          </div>
                        )}
                    </div>
                  }
                >
                  <Button
                    type="primary"
                    className="mark-image-output-button"
                    data-cy="mark-image-output-button"
                  >
                    Mark Image Output
                  </Button>
                </Popover>
              </span>
            </div>
            <div className="mark-as-input">
              <span className="feed-menu-button">
                <Popover
                  placement="bottom"
                  trigger="click"
                  content={
                    <div
                      className="mark-image-video-wrapper"
                      data-cy="mark-video-output-wrapper"
                    >
                      <AutoComplete
                        defaultValue={videoInput}
                        dataSource={filteredDataSource}
                        onSearch={val => {
                          setFilteredDataSource(
                            !val || val.trim() === ""
                              ? columnDataSource
                              : columnDataSource?.filter(dsVal =>
                                  dsVal
                                    .toLowerCase()
                                    .includes(val.toLowerCase()),
                                ),
                          );
                        }}
                        onChange={val => updateConfig("videoInput", val)}
                      ></AutoComplete>
                      {videoInput !== undefined &&
                        !orderedColumns
                          ?.concat(
                            [...Array(6).keys()].map(
                              idx => `video[${idx}].url`,
                            ),
                          )
                          .includes(videoInput || "") && (
                          <div className="warning">
                            * Currently selected column is new column. It will
                            be created in the final feed data.
                          </div>
                        )}
                    </div>
                  }
                >
                  <Button
                    type="primary"
                    className="mark-image-output-button"
                    data-cy="mark-video-output-button"
                  >
                    Mark Video Input
                  </Button>
                </Popover>
              </span>
            </div>

            <div className="mark-as-input">
              <span className="feed-menu-button">
                <Popover
                  placement="bottom"
                  trigger="click"
                  content={
                    <div
                      className="mark-image-video-wrapper"
                      data-cy="mark-video-output-wrapper"
                    >
                      <AutoComplete
                        defaultValue={videoEndInput}
                        dataSource={filteredDataSource}
                        onSearch={val => {
                          setFilteredDataSource(
                            !val || val.trim() === ""
                              ? columnDataSource
                              : columnDataSource?.filter(dsVal =>
                                  dsVal
                                    .toLowerCase()
                                    .includes(val.toLowerCase()),
                                ),
                          );
                        }}
                        onChange={val => updateConfig("videoEndInput", val)}
                      ></AutoComplete>
                      {videoEndInput !== undefined &&
                        !orderedColumns
                          ?.concat(
                            [...Array(6).keys()].map(
                              idx => `video[${idx}].url`,
                            ),
                          )
                          .includes(videoEndInput || "") && (
                          <div className="warning">
                            * Currently selected column is new column. It will
                            be created in the final feed data.
                          </div>
                        )}
                    </div>
                  }
                >
                  <Button
                    type="primary"
                    className="mark-image-output-button"
                    data-cy="mark-video-output-button"
                  >
                    Mark Video End Input
                  </Button>
                </Popover>
              </span>
            </div>

            <div className="mark-as-output">
              <span className="feed-menu-button">
                <Popover
                  placement="bottom"
                  trigger="click"
                  content={
                    <div
                      className="mark-image-video-wrapper"
                      data-cy="mark-video-output-wrapper"
                    >
                      <Input
                        defaultValue={videoFrameStart}
                        value={videoFrameStart}
                        onChange={e =>
                          updateConfig("videoFrameStart", e.target.value)
                        }
                      />
                    </div>
                  }
                >
                  <Button
                    type="primary"
                    className="mark-image-output-button"
                    data-cy="mark-video-output-button"
                  >
                    Mark Video Frame Start
                  </Button>
                </Popover>
              </span>
            </div>

            <div className="mark-as-output">
              <span className="feed-menu-button">
                <Popover
                  placement="bottom"
                  trigger="click"
                  content={
                    <div
                      className="mark-image-video-wrapper"
                      data-cy="mark-video-output-wrapper"
                    >
                      <Input
                        defaultValue={videoFrameEnd}
                        value={videoFrameEnd}
                        onChange={e =>
                          updateConfig("videoFrameEnd", e.target.value)
                        }
                      />
                    </div>
                  }
                >
                  <Button
                    type="primary"
                    className="mark-image-output-button"
                    data-cy="mark-video-output-button"
                  >
                    Mark Video Frame End
                  </Button>
                </Popover>
              </span>
            </div>

            <div className="mark-as-output">
              <span className="feed-menu-button">
                <Popover
                  placement="bottom"
                  trigger="click"
                  content={
                    <div
                      className="mark-image-video-wrapper"
                      data-cy="mark-video-output-wrapper"
                    >
                      <Input
                        defaultValue={videoFrameRate}
                        value={videoFrameRate}
                        onChange={e =>
                          updateConfig("videoFrameRate", e.target.value)
                        }
                      />
                    </div>
                  }
                >
                  <Button
                    type="primary"
                    className="mark-image-output-button"
                    data-cy="mark-video-output-button"
                  >
                    Mark Video Frame Rate
                  </Button>
                </Popover>
              </span>
            </div>
            <div className="mark-as-output">
              <span className="feed-menu-button">
                <Popover
                  placement="bottom"
                  trigger="click"
                  content={
                    <div
                      className="mark-image-video-wrapper"
                      data-cy="mark-video-output-wrapper"
                    >
                      <AutoComplete
                        defaultValue={videoOutput}
                        dataSource={filteredDataSource}
                        onSearch={val => {
                          setFilteredDataSource(
                            !val || val.trim() === ""
                              ? columnDataSource
                              : columnDataSource?.filter(dsVal =>
                                  dsVal
                                    .toLowerCase()
                                    .includes(val.toLowerCase()),
                                ),
                          );
                        }}
                        onChange={val => updateConfig("videoOutput", val)}
                      ></AutoComplete>
                      {videoOutput !== undefined &&
                        !orderedColumns
                          ?.concat(
                            [...Array(6).keys()].map(
                              idx => `video[${idx}].url`,
                            ),
                          )
                          .includes(videoOutput || "") && (
                          <div className="warning">
                            * Currently selected column is new column. It will
                            be created in the final feed data.
                          </div>
                        )}
                    </div>
                  }
                >
                  <Button
                    type="primary"
                    className="mark-image-output-button"
                    data-cy="mark-video-output-button"
                  >
                    Mark Video Output
                  </Button>
                </Popover>
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
