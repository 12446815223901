import { useForm } from "antd/lib/form/Form";
import { useState, Key } from "react";
import {
  GoogleAdCampaignInternal,
  GoogleAdCampaignInternalForm,
} from "shared/types/adLibrary";
import {
  dateStringToMoment,
  initialFormValues,
} from "./pmaxCampaignsTable/newCampaignDrawer/newCampaignForm/helpers";

type CampaignDrawer = {
  open: boolean;
  data: GoogleAdCampaignInternal;
  assetGroupIds: Key[];
};

type TransformedFormData = Omit<
  GoogleAdCampaignInternalForm,
  "startDate" | "endDate"
> & {
  // Although we want to avoid using moment as much as possible, the Antd DatePicker component is deeply coupled with it
  startDate?: moment.Moment;
  endDate?: moment.Moment;
};

export const useCampaignDrawer = () => {
  // form is a subset of drawer.data, containing only the fields that are part of the form and need to be validated
  // drawer.data is the source of truth to be used when submitting to the API
  const [form] = useForm<TransformedFormData>();
  const [drawer, setDrawer] = useState<CampaignDrawer>({
    open: false,
    data: initialFormValues,
    assetGroupIds: [],
  });

  const setCampaignDrawer = (values: Partial<CampaignDrawer>) => {
    if (values.data) {
      form.setFieldsValue({
        ...values.data,
        startDate: dateStringToMoment(values.data.startDate),
        endDate: dateStringToMoment(values.data.endDate),
      });
    }
    setDrawer(prevDrawer => ({
      ...prevDrawer,
      ...values,
    }));
  };

  return { campaignDrawer: drawer, setCampaignDrawer, form };
};
