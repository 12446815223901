import { useQuery } from "react-query";
import API from "services";

import {
  IApiResponse,
  IGetS3ObjectVersionsQueryParams,
  IGetS3ObjectVersionsResponseData,
  IS3ObjectVersion,
} from "shared/types/shared";

interface IUseFetchS3ObjectVersionArgs {
  queryParams?: IGetS3ObjectVersionsQueryParams;
  enabled?: boolean;
}

const getS3ObjectVersions =
  (queryParams?: IGetS3ObjectVersionsQueryParams) => async () => {
    const { result, error } = await API.services.adLibrary.getS3ObjectVersions(
      queryParams,
    );

    if (error || !result) {
      throw Error(error?.message || "Something went wrong.");
    }

    return result.versions;
  };

export const useFetchS3ObjectVersions = (
  args?: IUseFetchS3ObjectVersionArgs,
) => {
  return useQuery<
    IS3ObjectVersion[] | undefined,
    IApiResponse<IGetS3ObjectVersionsResponseData>
  >(
    args?.queryParams?.url
      ? ["s3ObjectVersions", args.queryParams.url]
      : "s3ObjectVersions",
    getS3ObjectVersions(args?.queryParams),
    { staleTime: 180000, enabled: args?.enabled },
  );
};
