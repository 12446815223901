import {
  PushpinOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { Space, Popconfirm, Typography, Input } from "antd";
import classNames from "classnames";
import { memo, useCallback, useState } from "react";
import useOutsideClick from "shared/hooks/useOutsideClick";
import styles from "./ComponentItem.module.scss";

interface IProps {
  handleDelete?(): void;
  handleSelect?(id?: string): void;
  selected?: boolean;
  pinned?: boolean;
  id?: string;
  name?: string;
  hasErrors?: boolean;
  handleChangeName?: (text: string) => void;
}

const ComponentItem = ({
  handleDelete,
  handleSelect,
  selected,
  id,
  name = "",
  pinned,
  hasErrors,
  handleChangeName,
}: IProps) => {
  const [editing, setEditing] = useState(false);
  const [nameTemp, setNameTemp] = useState(name);

  const handleCommitChange = useCallback(() => {
    if (editing && nameTemp !== name) {
      handleChangeName?.(nameTemp);
    }
    setEditing(false);
  }, [nameTemp, editing, name, handleChangeName]);

  const targetRef = useOutsideClick(handleCommitChange);

  const isEditable = !!handleChangeName;
  return (
    <div
      key={id}
      onClick={() => handleSelect?.(id)}
      className={classNames(styles.component, {
        [styles.selected]: selected,
      })}
    >
      <span
        onDoubleClick={e => {
          e.stopPropagation();
          if (!isEditable) return;
          setEditing(true);
        }}
        ref={targetRef}
      >
        {editing ? (
          <Input
            value={nameTemp}
            onChange={e => setNameTemp(e.target.value)}
            autoFocus
            onFocus={e => e.target.select()}
            className={styles.nameInput}
            onKeyDown={e => {
              if (e.key === "Enter") {
                handleCommitChange();
              }
              if (e.key === "Esc" || e.key === "Escape") {
                setNameTemp(name);
                setEditing(false);
              }
            }}
          />
        ) : (
          <Typography.Text
            className={classNames(styles.name, {
              [styles.editableName]: isEditable,
              [styles.editingName]: editing,
            })}
            ellipsis={{ tooltip: name }}
          >
            {name}
          </Typography.Text>
        )}
      </span>
      <div onClick={e => e.stopPropagation()}>
        <Space size="middle" align="center" className={styles.icons}>
          {hasErrors && (
            <ExclamationCircleFilled style={{ color: "#FF4D4F" }} />
          )}
          {pinned && <PushpinOutlined />}
          {handleDelete && (
            <Popconfirm
              title={"Delete this component?"}
              okText="Yes"
              cancelText="No"
              placement="right"
              onConfirm={() => handleDelete()}
            >
              <DeleteOutlined />
            </Popconfirm>
          )}
        </Space>
      </div>
    </div>
  );
};

export default memo(ComponentItem);
