import { isBoolean } from "lodash";
import { useCallback, useState } from "react";

/**
 * @param initialState The initial state
 * @returns Returns the current state and the function toggle the current state.
 * Optionally you could explicitly specify the value you want to set, but the main goal is to use it as a toggle.
 */
export const useToggle = (
  initialState: boolean = false,
): [boolean, (newState?: boolean) => void] => {
  const [state, setState] = useState<boolean>(initialState);

  const toggle = useCallback(
    (newState?: boolean) =>
      isBoolean(newState) ? setState(newState) : setState(state => !state),
    [],
  );

  return [state, toggle];
};
