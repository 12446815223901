import {
  ISingleLogoObject,
  TUploadLogosS3DictionaryKey,
  TUploadSetLogosInDictionary,
  TUploadSteps,
} from "shared/types/uploadManagement";
import { IAccountRecord } from "shared/types/accountManagement";
import { removeItemOrDups } from "utils/helpers.array";

interface IUpdateLogosInDirectionaryProps {
  singleLogoObject: ISingleLogoObject;
  setLogosInDictionary: (
    logosInDictionary: Record<TUploadSetLogosInDictionary, ISingleLogoObject[]>,
  ) => void;
  logosInDictionary: Record<TUploadSetLogosInDictionary, ISingleLogoObject[]>;
}

export const updateLogosInDictionary = ({
  logosInDictionary,
  setLogosInDictionary,
  singleLogoObject,
}: IUpdateLogosInDirectionaryProps) => {
  const groupKeyMap = {
    Horizontal: "horizontalLogos",
    Vertical: "verticalLogos",
    Square: "squareLogos",
  } as const;

  const key = groupKeyMap[singleLogoObject.group as keyof typeof groupKeyMap];

  if (key) {
    setLogosInDictionary({
      ...logosInDictionary,
      [key]: [...logosInDictionary[key], singleLogoObject],
    });
  }
};

interface IDeleteAttachedLogoFileProps {
  logo: ISingleLogoObject;
  group: string;
  step: string;
  logosInDictionary: Record<TUploadSetLogosInDictionary, ISingleLogoObject[]>;
  setLogosInDictionary: (
    logosInDictionary: Record<TUploadSetLogosInDictionary, ISingleLogoObject[]>,
  ) => void;
}

export const deleteAttachedLogoFile = ({
  group,
  logo,
  logosInDictionary,
  setLogosInDictionary,
  step,
}: IDeleteAttachedLogoFileProps) => {
  const tempArr: ISingleLogoObject[] = removeItemOrDups(
    logosInDictionary[group as TUploadSetLogosInDictionary],
    logoEle =>
      logoEle[step as TUploadSteps]?.filename ===
      logo[step as TUploadSteps]?.filename,
  );

  const tempLogoInDictionaryObject = {
    ...logosInDictionary,
  };
  tempLogoInDictionaryObject[group as TUploadSetLogosInDictionary] = tempArr;

  setLogosInDictionary(tempLogoInDictionaryObject);
};

interface IDeleteSubmittedLogoBoxProps {
  logoName: string;
  group: string;
  dealerToEditWithLogos: IAccountRecord;
  setDealerToEditWithLogos: (dealerToEditWithLogos: IAccountRecord) => void;
}

export const deleteSubmittedLogoBox = ({
  dealerToEditWithLogos,
  group,
  logoName,
  setDealerToEditWithLogos,
}: IDeleteSubmittedLogoBoxProps) => {
  if (!dealerToEditWithLogos || !dealerToEditWithLogos.logoUrlsFromS3) {
    return;
  }

  const tempLogoBoxes: string[] = removeItemOrDups(
    dealerToEditWithLogos.logoUrlsFromS3[group as TUploadLogosS3DictionaryKey],
    eleName => logoName === eleName,
  );

  const tempBoxLogoObject = { ...dealerToEditWithLogos.logoUrlsFromS3 };
  tempBoxLogoObject[group as TUploadLogosS3DictionaryKey] = tempLogoBoxes;

  setDealerToEditWithLogos({
    ...dealerToEditWithLogos,
    logoUrlsFromS3: tempBoxLogoObject,
  });
};
