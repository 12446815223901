import { useFetchAds } from "shared/hooks/adLibrary/useFetchAds";
import { IAd } from "shared/types/adLibrary";
import { useAdLibraryData } from "./dataListHooks";
import { ApiResponseCampaignPlanner } from "screens/campaignManagement/campaignPlanner/types";
import { useFetchCampaignPlanners } from "shared/hooks/campaignPlanner/useFetchCampaignPlanner";
import { useMemo } from "react";
import { groupBy, mapValues } from "lodash";

export const AdsQueryKey = "ads";

export const useAds = ({
  includePlanners = false,
}: {
  includePlanners?: boolean;
} = {}) => {
  const result = useFetchAdsWithPlanners({ includePlanners });
  return useAdLibraryData(result);
};

export const useFetchAdsWithPlanners = ({
  includePlanners = false,
}: {
  includePlanners?: boolean;
} = {}) => {
  const adsResult = useFetchAds();
  const plannersResult = useFetchCampaignPlanners({ enabled: includePlanners });

  const result = useMemo(
    () => mapAdsWithPlanners(adsResult.data || [], plannersResult.data || []),
    [adsResult.data, plannersResult.data],
  );

  return {
    data: result,
    isLoading: adsResult.isLoading || plannersResult.isLoading,
    isError: adsResult.isError || plannersResult.isError,
  };
};

const getAdsInPlanners = (apiPlanners: ApiResponseCampaignPlanner[]) => {
  const allPlanners = apiPlanners.flatMap(planner =>
    planner.adShellIds.map(ad => ({ adId: ad, planner })),
  );
  const plannersGroupedByAdId = groupBy(allPlanners, item => item.adId);
  const plannersByAdId = mapValues(plannersGroupedByAdId, planners =>
    planners.map(({ planner }) => planner),
  );

  const allAdInstances = apiPlanners
    .flatMap(planner =>
      planner.instances.map(instance => ({
        ...instance,
        plannerId: planner.id,
      })),
    )
    .flatMap(instance =>
      instance.adShellIds.map(ad => ({ adId: ad, instance })),
    );

  const instancesGrouped = groupBy(allAdInstances, item => item.adId);
  const instancesByAdId = mapValues(instancesGrouped, instances =>
    instances.map(({ instance }) => instance),
  );

  return {
    plannersByAdId,
    instancesByAdId,
  };
};

export const mapAdsWithPlanners = (
  ads: IAd[],
  apiPlanners: ApiResponseCampaignPlanner[],
): IAd[] => {
  const { plannersByAdId, instancesByAdId } = getAdsInPlanners(apiPlanners);

  return ads.map(ad => {
    return {
      ...ad,
      planners: plannersByAdId[ad.id] ?? [],
      instances: instancesByAdId[ad.id] ?? [],
    };
  });
};
