import { useMutation, useQuery, useQueryClient } from "react-query";
import API from "services";
import {
  EverythingAdHtml,
  IsEverythingAdHtmlSlugAvailableResponse,
} from "shared/types/adLibrary";

const isValidEverythingAdSlugKey = "isValidEverythingAdSlug";
const everythingAdHtmlItemsKey = "everythingAdHtmlItems";

export const useCreateEverythingAdsHtml = () => {
  const queryClient = useQueryClient();

  return useMutation<EverythingAdHtml, Error, EverythingAdHtml>({
    mutationFn: API.services.adLibrary.createEverythingAdHtml,
    onSettled: data => {
      queryClient.invalidateQueries([isValidEverythingAdSlugKey, data?.slug]);
      queryClient.invalidateQueries([everythingAdHtmlItemsKey]);
    },
  });
};

export const useGetIsEverythingAdHtmlSlugAvailable = ({
  slug,
}: {
  slug: string;
}) => {
  return useQuery<IsEverythingAdHtmlSlugAvailableResponse, Error>({
    queryKey: [isValidEverythingAdSlugKey, slug],
    queryFn: () =>
      API.services.adLibrary.getIsEverythingAdHtmlSlugAvailable(slug),
    retry: false,
    enabled: !!slug,
  });
};

export const useGetEverythingAdHtmlItemsByEaId = ({
  eaId,
}: {
  eaId: string;
}) => {
  return useQuery<EverythingAdHtml[], Error>({
    queryKey: [everythingAdHtmlItemsKey, eaId],
    queryFn: () => API.services.adLibrary.getEverythingAdHtmlItemsByEaId(eaId),
    retry: false,
    enabled: !!eaId,
  });
};

export const useUpdateEverythingAdHtmlData = () => {
  const queryClient = useQueryClient();
  return useMutation<EverythingAdHtml, Error, EverythingAdHtml>({
    mutationFn: API.services.adLibrary.updateEverythingAdHtmlData,
    onSettled: data => {
      queryClient.invalidateQueries([isValidEverythingAdSlugKey, data?.slug]);
      queryClient.invalidateQueries([everythingAdHtmlItemsKey]);
    },
  });
};

export const useDeleteEverythingAdHtml = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (slug: string) => API.services.adLibrary.deleteEverythingAdHtml(slug),
    {
      onSettled: (_data, _error, slug) => {
        queryClient.invalidateQueries([isValidEverythingAdSlugKey, slug]);
        queryClient.invalidateQueries([everythingAdHtmlItemsKey]);
      },
    },
  );
};
