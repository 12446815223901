import { Tag } from "antd";
import styles from "./CollapseHeader.module.scss";

interface Props {
  collapseKey: string;
  size: string;
  isCollapseExpanded: boolean;
  assetCount: number;
  onCollapseExpand: (shouldExpand: boolean, size: string) => void;
}

const CollapseHeader = (props: Props) => {
  return (
    <div
      className={styles.host}
      data-cy={`header-${props.collapseKey}`}
      onClick={() => {
        props.onCollapseExpand(!props.isCollapseExpanded, props.size);
      }}
    >
      <span>{props.size}</span>
      <Tag color="blue">{props.assetCount}</Tag>
    </div>
  );
};

export default CollapseHeader;
