import EllipsisText from "./EllipsisText";
interface IProps {
  text: string;
  charDisplayLimit?: number;
}

import styles from "./Badge.module.scss";

const Tag = (props: IProps) => {
  return (
    <div data-cy={"tag-div"} className={`${styles.cardItem} ${styles.cardTag}`}>
      <EllipsisText {...props} />
    </div>
  );
};

export default Tag;
