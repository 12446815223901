import { ShareAltOutlined } from "@ant-design/icons";
import { Button, Tooltip, Typography } from "antd";
import moment from "moment";
import { useIsAdmin } from "shared/hooks/useIsAdmin";
import { IDashboard } from "shared/types/dashboardManagement";
import styles from "../Metrics.module.scss";

const lookerDateFormat = "YYYY-MM-DD";

export const DashboardDetailHeader = ({
  dashboard,
  dateOverride,
  setShareDashboard,
  setShowLinkModal,
}: {
  dashboard?: IDashboard;
  dateOverride?: {
    startDate?: string | number;
    endDate?: string | number;
  };
  setShareDashboard?: (dashboard?: IDashboard) => void;
  setShowLinkModal?: (showLinkModal: boolean) => void;
}) => {
  const lookerID = dashboard?.lookerId ?? "--";
  const isAdmin = useIsAdmin();
  const getDateFormat = (value: string | number) =>
    `${moment(typeof value === "string" ? parseInt(value) : value).format(
      lookerDateFormat,
    )}`;
  const dates = {
    startDate: dateOverride?.startDate ?? dashboard?.startDate,
    endDate: dateOverride?.endDate ?? dashboard?.endDate,
  };
  const date =
    dates?.startDate && dates?.endDate
      ? `${getDateFormat(dates.startDate)} to ${getDateFormat(dates.endDate)}`
      : "";

  return (
    <div className={styles.detailHeader}>
      <div className={styles.detailInfo}>
        <Typography.Text>{`Looker ID: ${lookerID}`}</Typography.Text>
        <Typography.Text
          className={styles.detailText}
        >{`Default Date: ${date}`}</Typography.Text>
      </div>
      <Button
        icon={
          <Tooltip title="Share dashboard link">
            <ShareAltOutlined />
          </Tooltip>
        }
        disabled={
          !setShareDashboard || !setShowLinkModal || !dashboard || !isAdmin
        }
        type={"text"}
        onClick={e => {
          e.stopPropagation();
          if (!dashboard) return;
          setShareDashboard?.(dashboard);
          setShowLinkModal?.(true);
        }}
      />
    </div>
  );
};
