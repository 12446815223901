import { Tooltip } from "antd";
import { blue } from "@ant-design/colors";
import { useCallback, useMemo, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

type Props = {
  url: string;
};

export const CopyUrlToClipboard = ({ url }: Props) => {
  const [copied, setCopied] = useState(false);
  const [width, setWidth] = useState("100%");
  const [isHover, setIsHover] = useState(false);
  const clickable = useMemo(() => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  }, [url]);
  const setWidthCallback = useCallback((node: HTMLSpanElement) => {
    if (
      node &&
      node.parentElement &&
      node.offsetWidth < node.parentElement.offsetWidth
    ) {
      // Set containing div to width of URL and add 1px to avoid ellipsis kicking in
      setWidth(`${node.offsetWidth + 1}px`);
    }
  }, []);

  return (
    <>
      {clickable ? (
        <CopyToClipboard
          text={url}
          onCopy={() => {
            setCopied(true);
          }}
        >
          <Tooltip
            title={copied ? "Copied to clipboard" : "Copy to clipboard"}
            placement="top"
            onVisibleChange={(visible: boolean) => !visible && setCopied(false)}
          >
            <div
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
              style={{
                width: width,
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                color: isHover ? blue[5] : undefined,
                textDecoration: isHover ? "underline" : "none",
                cursor: "pointer",
              }}
            >
              <span ref={setWidthCallback}>{url}</span>
            </div>
          </Tooltip>
        </CopyToClipboard>
      ) : (
        <div
          style={{
            width: "100%",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {url}
        </div>
      )}
    </>
  );
};
