import { memo } from "react";
import { IAccount } from "shared/types/accountManagement";
import GenericAccountSelect, {
  AccountFilters,
} from "./select/GenericAccountSelect";

interface IProps {
  selectedDealers?: string[];
  defaultValue?: string[];
  onSelect?(value: string, dealers?: IAccount[]): void;
  onDeselect?(value: string, dealers?: IAccount[]): void;
  onChange?(selectedDealers: string[], dealers?: IAccount[]): void;
  onFocus?: React.FocusEventHandler<HTMLElement>;
  autoFocus?: boolean;
  loading?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  filters?: AccountFilters;
}

function MultipleAccountSelect({
  selectedDealers,
  defaultValue,
  onSelect,
  onDeselect,
  onChange,
  onFocus,
  autoFocus,
  loading,
  disabled,
  style,
  filters,
}: IProps) {
  return (
    <GenericAccountSelect
      style={style}
      autoFocus={autoFocus}
      value={selectedDealers}
      defaultValue={defaultValue}
      placeholder={"Select Accounts"}
      onFocus={onFocus}
      onChange={onChange}
      disabled={disabled}
      loading={loading}
      mode="multiple"
      filters={filters}
      onSelect={onSelect}
      onDeselect={onDeselect}
    />
  );
}

export default memo(MultipleAccountSelect);
