import { get } from "object-path";
import { useContext, useMemo } from "react";
import { context } from "shared/components/contextAPI/shared/RenderTemplate";
import { orientations } from "shared/constants/media";
import { IBox } from "../CardToggle";

export const useMediaSelectPayload = (box: IBox) => {
  const renderTemplateContextV2 = useContext(context);

  return useMemo(() => {
    const obj = renderTemplateContextV2 ?? {};
    const { make, dealerCode, dealerId } = get(obj, "data.offer.offerData");
    const { logoDropZoneType } = get(box, "object.customData");
    const orientation = orientations.get(logoDropZoneType);

    return {
      oemPayload: {
        keyword: ["Logo", "Manufacturer"],
        make,
        orientation,
      },
      storePayload: {
        keyword: ["Logo", "Store"],
        store_233: dealerCode ?? dealerId,
        orientation,
      },
    };
  }, [renderTemplateContextV2, box]);
};
