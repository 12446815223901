import {
  FeedOfferFieldToPaymentEngineFieldDict,
  PaymentEngineFirstTierRequestBodyProp,
  PaymentEngineSecondTierRequestBodyProp,
  DepreciationFieldKeys,
  FeedOfferFieldToPaymentEngineFieldDictEntry,
  AggregatedOfferFieldDictEntry,
  FeesDueAtSigningFieldKeys,
  LeaseExplainedUIFieldDictionary,
  LeaseExplainedUIFieldKeys,
  PaymentEngineOfferDataField,
  FinanceCapitalFieldKeys,
  FinanceFinanceChargeFieldKeys,
  FinanceExplainedUIFieldDictionary,
  FinanceExplainedUIFieldKeys,
} from "shared/types/paymentEngine";

export const dynamicPaymentEngineFields = [
  "leaseVehicleSalesPrice",
  "numberOfPayments",
  "monthlyPayment",
  "milesPerYear",
  "zeroDownLeaseNumberOfPayments",
  "zeroDownLeaseMonthlyPayment",
  "zeroDownLeaseVehicleSalesPrice",
  "zeroDownLeaseMilesPerYear",
  "financeTerm",
  "financePayment",
  "financeDownPayment",
  "totalDueAtSigning",
] as Array<PaymentEngineOfferDataField>;

export const feedOfferFieldToPaymentEngineFieldDictionary: FeedOfferFieldToPaymentEngineFieldDict =
  {
    expirationDate: {
      firstTierProp: "expirationDate",
    },
    dealerDiscount: {
      firstTierProp: "pricing",
      secondTierProp: "dealerDiscount",
    },

    /* Lease - lease props */
    leaseVehicleSalesPrice: {
      firstTierProp: "pricing",
      secondTierProp: "priceBeforeCustomerRebates",
    },
    minFICO: {
      firstTierProp: "general",
      secondTierProp: "credit",
      thirdTierProp: "minFico",
    },
    monthlyPayment: {
      firstTierProp: "lease",
      secondTierProp: "monthlyPayment",
      thirdTierProp: "amount",
    },
    totalLeaseCharge: {
      // As of AV2-1882: totalDueAtSigning + (monthlyPayment * term) - monthlyPayment + acquisition fee
    },
    totalDueAtSigning: {
      firstTierProp: "lease",
      secondTierProp: "totalDueAtSigning",
      thirdTierProp: "amount",
    },
    residualValue: {
      firstTierProp: "lease",
      secondTierProp: "residual",
      thirdTierProp: "value",
    },
    securityDeposit: {
      firstTierProp: "lease",
      secondTierProp: "securityDeposit",
      thirdTierProp: "amount",
    },
    numberOfPayments: {
      firstTierProp: "lease",
      secondTierProp: "term",
    },
    netAdjustedCapitalizedCost: {
      firstTierProp: "lease",
      secondTierProp: "adjustedCapitalizedCost",
    },
    milesPerYear: {
      firstTierProp: "lease",
      secondTierProp: "mileage",
    },
    terminationFee: {
      firstTierProp: "lease",
      secondTierProp: "terminationFee",
    },
    capCost: {
      firstTierProp: "lease",
      secondTierProp: "capitalizedCost",
    },
    capCostReduction: {
      firstProp: "capCost",
      secondProp: "netAdjustedCapitalizedCost",
    },

    /* Finance - loan props */
    financeMinFICO: {
      firstTierProp: "general",
      secondTierProp: "credit",
      thirdTierProp: "minFico",
    },
    financePayment: {
      firstTierProp: "loan",
      secondTierProp: "monthlyPayment",
      thirdTierProp: "amount",
    },
    amntFinanced: {
      firstTierProp: "loan",
      secondTierProp: "totalFinanced",
    },
    financeDownPayment: {
      firstTierProp: "loan",
      secondTierProp: "downPayment",
    },
    financeTerm: {
      firstTierProp: "loan",
      secondTierProp: "term",
    },
    financeRate: {
      firstTierProp: "loan",
      secondTierProp: "apr",
      thirdTierProp: "value",
    },
    leaseDownPayment: {
      firstTierProp: "lease",
      secondTierProp: "downPayment",
    },

    /* Possible TO DO: add purchase entries */
  };

const buyerParameterNames = [
  "pageId",
  "properties",
] as Array<PaymentEngineSecondTierRequestBodyProp>;
const paymentParameterNames = [
  "buyerZipcode",
  "dealType",
  "dealerZipcode",
  "ficoScore",
  "mileage",
  "monthlyPayment",
  "term",
  "upFrontDefinition",
] as Array<PaymentEngineSecondTierRequestBodyProp>;

const vehicleParameterNames = [
  "msrp",
  "odometer",
  "price",
  "vin",
] as Array<PaymentEngineSecondTierRequestBodyProp>;

export const paymentEngineRequestBodyParamNameDict: Record<
  PaymentEngineFirstTierRequestBodyProp,
  Array<PaymentEngineSecondTierRequestBodyProp>
> = {
  buyerParameters: buyerParameterNames,
  paymentParameters: paymentParameterNames,
  vehicleParameters: vehicleParameterNames,
};

/* LEASING EXPLAINED SCREEN IN DS MODAL */

const depreciationSectionDict: Record<
  DepreciationFieldKeys,
  FeedOfferFieldToPaymentEngineFieldDictEntry & AggregatedOfferFieldDictEntry
> = {
  "Sales Price": {
    firstTierProp: "pricing",
    secondTierProp: "priceBeforeCustomerRebates",
  },
  // need to use feedOfferFieldToPaymentEngineFieldDictionary lookup
  "Capitalized Cost Reduction": {
    firstProp: "capCost",
    secondProp: "netAdjustedCapitalizedCost",
  },
  "Adjusted Cap Cost": {
    firstTierProp: "lease",
    secondTierProp: "adjustedCapitalizedCost",
  },
  "Lease Cash": {
    firstTierProp: "lease",
    secondTierProp: "rebates",
    thirdTierProp: "rebatesTotal",
  },
  "Residual Value": {
    firstTierProp: "lease",
    secondTierProp: "residual",
    thirdTierProp: "value",
  },
  "Finance at": {
    firstTierProp: "lease",
    secondTierProp: "moneyfactor",
    thirdTierProp: "value",
  },
};

const feesDueAtSigningSectionDict: Record<
  FeesDueAtSigningFieldKeys,
  FeedOfferFieldToPaymentEngineFieldDictEntry & AggregatedOfferFieldDictEntry
> = {
  "Down Payment": {
    firstTierProp: "lease",
    secondTierProp: "downPayment",
  },
  // title string will be replaced with {dealInformation > lease > fees > feesDetails > name} 1 feesDetails[0].name
  "Lease Fee Details Name 1": {
    firstTierProp: "lease",
    secondTierProp: "fees",
    thirdTierProp: "feesDetails", // amount is an array here so feesDetails[0].amount
  },
  // title string will be replaced with {dealInformation > lease > fees > feesDetails > name} 2 feesDetails[1].name
  "Total Fees Due at Signing": {
    /*
       Calculated with:
       {dealInformation > lease > downPayment}
       +
       {dealInformation > lease > fees > feesDetails > amount 1}
       +
       {dealInformation > lease > fees > feesDetails > amount 2}
     */
  },
  "First Month's Payment": {
    firstTierProp: "lease",
    secondTierProp: "firstMonthPayment",
    thirdTierProp: "amount",
  },
  "Total Due at Signing": {
    firstTierProp: "lease",
    secondTierProp: "totalDueAtSigning",
    thirdTierProp: "amount",
  },
};

export const leaseExplainedItemsDictionary: LeaseExplainedUIFieldDictionary = {
  DEPRECIATION: depreciationSectionDict,
  "FEES DUE AT SIGNING": feesDueAtSigningSectionDict,
};

export const aggregatedFields: LeaseExplainedUIFieldKeys[] = [
  "Capitalized Cost Reduction",
  "Finance at",
  "Total Fees Due at Signing",
  "Fees Due at Signing",
  "Rounding",
  "Total Due at Signing",
  "The total lease payment is",
  "The total due at signing is",
];

export const fieldsWithSubtext: LeaseExplainedUIFieldKeys[] = [
  "Finance Charge",
  "The total due at signing is",
];

export const dynamicUIFieldNames: LeaseExplainedUIFieldKeys[] = [
  "Lease Fee Details Name 1",
];

export const fieldsToBold: LeaseExplainedUIFieldKeys[] = [
  "Total Due at Signing",
];

export const fieldValsWithBorderBot: LeaseExplainedUIFieldKeys[] = [
  "Sales Price",
  "Lease Cash",
  "Residual Value",
  "Rounding",
  "Tax on Fees",
];

export const paddedBottomFields: LeaseExplainedUIFieldKeys[] = [
  "The total lease payment is",
  "The total due at signing is",
];

export const formatNumberFields: LeaseExplainedUIFieldKeys[] = [
  "Capitalized Cost Reduction",
  "Total Fees Due at Signing",
  "Total Due at Signing",
  "Fees Due at Signing",
  "The total due at signing is",
  "Rounding",
  "The total lease payment is",
];

/* FINANCING EXPLAINED SCREEN UN DS MODAL */

const financeCapitalSectionDict: Record<
  FinanceCapitalFieldKeys,
  FeedOfferFieldToPaymentEngineFieldDictEntry & AggregatedOfferFieldDictEntry
> = {
  "Sales Price": {
    firstTierProp: "pricing",
    secondTierProp: "priceBeforeCustomerRebates",
  },
  "Down Payment": {
    firstTierProp: "loan",
    secondTierProp: "downPayment",
  },
  // need to use feedOfferFieldToPaymentEngineFieldDictionary lookup
  "Finance Cash": {
    firstTierProp: "loan",
    secondTierProp: "rebates",
    thirdTierProp: "rebatesTotal",
  },
  "Amount Financed": {
    firstTierProp: "loan",
    secondTierProp: "totalFinanced",
  },
};

const financeFinanceChargeSectionDict: Record<
  FinanceFinanceChargeFieldKeys,
  FeedOfferFieldToPaymentEngineFieldDictEntry & AggregatedOfferFieldDictEntry
> = {
  "Amount Financed": {
    firstTierProp: "loan",
    secondTierProp: "totalFinanced",
  },
  "Finance at": {
    firstTierProp: "loan",
    secondTierProp: "moneyfactor",
  },
  "Finance Charge": {
    firstTierProp: "loan",
    secondTierProp: "financeCharge",
  },
  Lender: {
    firstTierProp: "general",
    secondTierProp: "lender",
    thirdTierProp: "name",
  },
};

export const financeExplainedItemsDictionary: FinanceExplainedUIFieldDictionary =
  {
    CAPITAL: financeCapitalSectionDict,
    "FINANCE CHARGE": financeFinanceChargeSectionDict,
  };

export const financeFieldsWithSubtext: FinanceExplainedUIFieldKeys[] = [
  "Finance Charge",
];

export const financeFieldsToBold: FinanceExplainedUIFieldKeys[] = [
  "Amount Financed",
  "Total Due at Signing",
];
