import { Key } from "react";
import API from "services";
import { useMutation, useQueryClient } from "react-query";
import { pmaxCampaignsQueryKey } from "./constants";
import { message } from "antd";

const loadKey = "deletingCamapaigns";

export const useDeletePmaxCampaigns = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (campaignIds: Key[]) =>
      API.services.adLibrary.deletePmaxcampaigns({ ids: campaignIds }),
    {
      onMutate: () => {
        message.loading({ content: "Deleting Campaigns...", key: loadKey });
      },
      onSettled: data => {
        message.destroy(loadKey);

        if (data?.result) message.success("Campaigns Deleted Successfully");
        else message.error("Error Deleting Campaigns");

        queryClient.invalidateQueries(pmaxCampaignsQueryKey);
      },
    },
  );
};
