import { ToolbarButton } from "shared/types/adLibrary";
import { parseInlineMenuActions } from "./utils";
import styles from "./InlineTableActions.module.scss";
import { keys } from "shared/types/shared";
import { ToolbarButtonSwitch } from "../toolbarTable/ToolbarButtonSwitch";

export interface Props<T> {
  getItemsToRender: (record: T) => ToolbarButton;
  record: T;
}

export function InlineTableActions<T>({ getItemsToRender, record }: Props<T>) {
  const parsedItems = parseInlineMenuActions(getItemsToRender, record);
  return (
    <ul className={styles.actionsContainer}>
      {keys(parsedItems).map(itemKey => (
        <li key={itemKey}>
          <ToolbarButtonSwitch
            buttonKey={itemKey}
            target={parsedItems[itemKey]}
          />
        </li>
      ))}
    </ul>
  );
}
