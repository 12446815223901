import { CSSProperties } from "react";
import { ReactComponent as StampOutlinedIcon } from "statics/images/svg/icons/stamp-o.svg";

export interface IProps {
  width?: string | number;
  height?: string | number;
  style?: CSSProperties;
}

const StampOutlined = ({ width = "1em", height = "1em", style }: IProps) => {
  return (
    <span role="img" aria-label="un-publish" className="anticon" style={style}>
      <StampOutlinedIcon width={width} height={height} />
    </span>
  );
};

export default StampOutlined;
