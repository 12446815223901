import { useEffect, useMemo } from "react";

import { Collapse, notification } from "antd";
import OfferTypeVariablesCollapse from "./offerTypeVariablesSection/OfferTypeVariablesCollapse";

import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import actions from "../../../redux/rootActions";

import {
  IOfferTypeVariable,
  IStateDisclosureRecord,
  IStateExceptionRecord,
  TRequiredVarsByDisclosureType,
} from "shared/types/legalLingo";
import * as legalHelpers from "utils/helpers.legal";

import _ from "lodash";
import {
  offerTypeVariables,
  internalOfferTypeVariables,
} from "shared/constants/dataManagement";
import "./OfferTypeVariablesSection.scss";
import { FeedType } from "shared/types/configuration";

interface IOfferTypeVariablesSection {
  selectedStateTab: string;
  selectedOem: string;
  currentOfferTypeKey: string;
  setOfferTypeKey: (offerTypeKey: string) => void;
  requiredStateVarsByDisclosureType: TRequiredVarsByDisclosureType;
  requiredOemVarsByDisclosureType: TRequiredVarsByDisclosureType;
  getStateTablesLegalLingos: () => void;
  getOemTablesLegalLingos: () => void;
  originalStateDisclosureObj: IStateDisclosureRecord;
  stateExceptionRecordsInForm: IStateExceptionRecord[];
  creatingDataStatus: string;
  feed: FeedType;
}

const OfferTypeVariablesSection: React.FC<IOfferTypeVariablesSection> = ({
  selectedStateTab,
  selectedOem,
  currentOfferTypeKey,
  setOfferTypeKey,
  requiredStateVarsByDisclosureType,
  requiredOemVarsByDisclosureType,
  getStateTablesLegalLingos,
  originalStateDisclosureObj,
  getOemTablesLegalLingos,
  stateExceptionRecordsInForm,
  creatingDataStatus,
  feed,
}) => {
  useEffect(() => {
    getStateTablesLegalLingos();
    getOemTablesLegalLingos();

    // eslint-disable-next-line
  }, []);

  const requiredVarsByState = useMemo(() => {
    const returnedReqByState = legalHelpers.returnRequiredVarsByStateOrOem(
      requiredStateVarsByDisclosureType,
    );

    const reqDisclosureTypeGroups = returnedReqByState.filter(
      variableObj =>
        variableObj.variables.filter(
          variable =>
            variable.requiredByStates.length > 0 &&
            variable.requiredByStates.includes(selectedStateTab.toUpperCase()),
        ).length > 0,
    );

    const disclosureVarsReqByCurrentState = reqDisclosureTypeGroups.map(
      variableObj => ({
        ...variableObj,
        variables: variableObj.variables.filter(variable =>
          variable.requiredByStates.includes(selectedStateTab.toUpperCase()),
        ),
      }),
    );

    return disclosureVarsReqByCurrentState;
  }, [requiredStateVarsByDisclosureType, selectedStateTab]);

  const requiredVarsByOem = useMemo(() => {
    const returnedReqByOem = legalHelpers.returnRequiredVarsByStateOrOem(
      requiredOemVarsByDisclosureType,
      "oem",
    );

    const reqDisclosureTypeGroups = returnedReqByOem.filter(
      variableObj =>
        variableObj.variables.filter(
          variable =>
            variable.requiredByStates.length > 0 &&
            variable.requiredByStates.includes(
              selectedStateTab.toUpperCase(),
            ) &&
            variable.requiredByOems &&
            variable.requiredByOems.length > 0 &&
            variable.requiredByOems.includes(selectedOem.toLowerCase()),
        ).length > 0,
    );

    const disclosureVarsReqByCurrentOem = reqDisclosureTypeGroups.map(
      variableObj => ({
        ...variableObj,
        variables: variableObj.variables.filter(
          variable =>
            variable.requiredByStates.includes(
              selectedStateTab.toUpperCase(),
            ) &&
            variable.requiredByOems &&
            variable.requiredByOems.length > 0 &&
            variable.requiredByOems.includes(selectedOem.toLowerCase()),
        ),
      }),
    );

    return disclosureVarsReqByCurrentOem;
  }, [requiredOemVarsByDisclosureType, selectedStateTab, selectedOem]);

  const variablesRegex = /\{(\w+)\}|\{(Expiration Date)\}|\{(Extra Cost)\}/g;
  const curlyRegex = /\{|\}/g;

  const varNamesFoundInStateDisclosures = useMemo(() => {
    if (originalStateDisclosureObj.disclosures.length === 0) {
      return [];
    }
    let variableNames: string[] = [];

    for (const disclosure of originalStateDisclosureObj.disclosures) {
      const variablesInText = disclosure.text.match(variablesRegex);
      if (variablesInText) {
        const varsWithNoCurlies = variablesInText.map(variableName =>
          variableName.replace(curlyRegex, ""),
        );
        variableNames = variableNames.concat(varsWithNoCurlies);
      }
    }

    return variableNames;

    // eslint-disable-next-line
  }, [originalStateDisclosureObj]);

  const varNamesFoundInStateOemExceptions = useMemo(() => {
    const originalStateOemExceptionObj = stateExceptionRecordsInForm.find(
      exceptionObj =>
        exceptionObj.state.toLowerCase() === selectedStateTab.toLowerCase() &&
        exceptionObj.oem === selectedOem,
    );
    if (
      !originalStateOemExceptionObj ||
      originalStateOemExceptionObj.exceptions.length === 0
    ) {
      return [];
    }
    let variableNames: string[] = [];

    for (const exception of originalStateOemExceptionObj.exceptions) {
      const variablesInText = exception.text.match(variablesRegex);
      if (variablesInText) {
        const varsWithNoCurlies = variablesInText.map(variableName =>
          variableName.replace(curlyRegex, ""),
        );
        variableNames = variableNames.concat(varsWithNoCurlies);
      }
    }

    return variableNames;

    // eslint-disable-next-line
  }, [stateExceptionRecordsInForm]);

  const returnNotificationMessage = (
    missingVars: string[],
    dataType: "disclosure" | "exception",
  ) => (
    <div>
      {missingVars.length <= 10 &&
        `Required variables are missing from the disclosures: ${missingVars.join(
          ", ",
        )}`}
      {missingVars.length > 10 && (
        <div>
          <span>Required variables are missing from the {dataType}s:</span>
          <ul style={{ maxHeight: "150px", overflowY: "scroll" }}>
            {missingVars.map(variable => (
              <li key={`${variable}-li`}>{variable}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );

  const handleUnusedVariables = (
    reqVariableGroups: IOfferTypeVariable[],
    varNames: string[],
    dataType: "disclosure" | "exception",
  ) => {
    const unfilledVarsGroups = reqVariableGroups.filter(groupObjs => {
      const unusedVars = groupObjs.variables.filter(
        varObj => !varNames.includes(varObj.name),
      );
      return unusedVars.length > 0;
    });
    if (unfilledVarsGroups.length < 1) {
      return;
    }
    const unusedVarNames = _.flatten(
      unfilledVarsGroups.map(varGroupObj =>
        varGroupObj.variables
          .map(varObj => varObj.name)
          .filter(name => !varNamesFoundInStateOemExceptions.includes(name)),
      ),
    );

    const category =
      dataType === "exception" ? selectedOem : selectedStateTab.toUpperCase();

    notification.open({
      type: "warning",
      placement: "bottomRight",
      message: `Required Exception variables by ${category} are missing.`,
      description: returnNotificationMessage(unusedVarNames, dataType),
      duration: 8,
    });
  };

  useEffect(() => {
    if (creatingDataStatus === "exception" && selectedOem) {
      handleUnusedVariables(
        requiredVarsByOem,
        varNamesFoundInStateOemExceptions,
        "exception",
      );
    }

    // eslint-disable-next-line
  }, [
    creatingDataStatus,
    requiredVarsByOem,
    varNamesFoundInStateOemExceptions,
    selectedOem,
  ]);

  useEffect(() => {
    if (creatingDataStatus === "disclosure") {
      handleUnusedVariables(
        requiredVarsByState,
        varNamesFoundInStateDisclosures,
        "disclosure",
      );
    }

    // eslint-disable-next-line
  }, [
    creatingDataStatus,
    requiredVarsByState,
    varNamesFoundInStateDisclosures,
  ]);

  const offerTypeVarsToUse =
    feed === "internal" ? internalOfferTypeVariables : offerTypeVariables;

  return (
    <Collapse
      className="collapse-container offerTypes-variables-filter-section"
      expandIconPosition="right"
      defaultActiveKey="filter"
    >
      <Collapse.Panel key="filter" header="Variables by Offer Type">
        <div className="filter-section-fields-container">
          <div style={{ width: "100%" }}>
            <div>
              {offerTypeVarsToUse.map(offerTypeVariable => (
                <div key={`${offerTypeVariable.offerType}-dropdown`}>
                  <OfferTypeVariablesCollapse
                    selectedStateTab={selectedStateTab}
                    selectedOem={selectedOem}
                    offerType={offerTypeVariable.offerType}
                    variables={offerTypeVariable.variables}
                    activeOfferTypeKey={currentOfferTypeKey}
                    setActiveOfferTypeKey={setOfferTypeKey}
                    variableNamesInDisclosures={varNamesFoundInStateDisclosures}
                    variableNamesInExceptions={
                      varNamesFoundInStateOemExceptions
                    }
                    requiredVarsByOem={requiredVarsByOem.find(
                      obj => obj.offerType === offerTypeVariable.offerType,
                    )}
                    requiredVarsByState={requiredVarsByState.find(
                      obj => obj.offerType === offerTypeVariable.offerType,
                    )}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};

const mapStateToProps = (state: any) => {
  const { legalLingo, configuration } = state;

  const { feed } = configuration;

  const {
    checkedStateVehicleInfoList,
    checkedStateNumberAtThisPriceList,
    checkedStateLeaseList,
    checkedStateZeroDownLeaseList,
    checkedStateFinanceList,
    checkedStatePurchaseList,
    checkedStateAPRList,

    checkedOemVehicleInfoList,
    checkedOemNumberAtThisPriceList,
    checkedOemLeaseList,
    checkedOemZeroDownLeaseList,
    checkedOemFinanceList,
    checkedOemPurchaseList,
    checkedOemAPRList,

    stateExceptionRecordsInForm,
    creatingDataStatus,
  } = legalLingo;

  const requiredStateVarsByDisclosureType: TRequiredVarsByDisclosureType = {
    "Vehicle Info": checkedStateVehicleInfoList,
    "Number at this Price": checkedStateNumberAtThisPriceList,
    Lease: checkedStateLeaseList,
    "Zero Down Lease": checkedStateZeroDownLeaseList,
    Finance: checkedStateFinanceList,
    Purchase: checkedStatePurchaseList,
    APR: checkedStateAPRList,
    "Extra Cost": [],
    "Expiration Date": [],
  };

  const requiredOemVarsByDisclosureType: TRequiredVarsByDisclosureType = {
    "Vehicle Info": checkedOemVehicleInfoList,
    "Number at this Price": checkedOemNumberAtThisPriceList,
    Lease: checkedOemLeaseList,
    "Zero Down Lease": checkedOemZeroDownLeaseList,
    Finance: checkedOemFinanceList,
    Purchase: checkedOemPurchaseList,
    APR: checkedOemAPRList,
    "Extra Cost": [],
    "Expiration Date": [],
  };

  return {
    requiredStateVarsByDisclosureType,
    requiredOemVarsByDisclosureType,
    stateExceptionRecordsInForm,
    creatingDataStatus,
    feed,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => {
  return {
    getStateTablesLegalLingos: () => {
      dispatch(actions.legalLingo.getStateTablesLegalLingos());
    },
    getOemTablesLegalLingos: () => {
      dispatch(actions.legalLingo.getOemTablesLegalLingos());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OfferTypeVariablesSection);
