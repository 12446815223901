import { ApiResponseCampaignPlanner } from "screens/campaignManagement/campaignPlanner/types";
import { IAd } from "shared/types/adLibrary";

export const DeleteAdWithPlannersError = ({
  ad,
  planners,
}: {
  ad: IAd;
  planners: ApiResponseCampaignPlanner[];
}) => {
  return (
    <>
      {ad.planners?.length ? <h3>Planners</h3> : null}
      <ul>
        {ad.planners?.map(planner => (
          <li key={planner.id}>
            <a
              href={`/campaign-management/campaign-planner/${planner.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {planner.name}
            </a>
          </li>
        ))}
      </ul>
      {ad.instances?.length ? <h3>Instances</h3> : null}
      <ul>
        {ad.instances?.map(instance => {
          const instancePlanner = planners?.find(planner =>
            planner.instances?.find(
              instanceId => instanceId.id === instance.id,
            ),
          );
          return (
            <li key={instance.id}>
              <a
                href={`/campaign-management/campaign-planner/${instancePlanner?.id}/instance/${instance.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {instance.name}
              </a>
            </li>
          );
        })}
      </ul>
    </>
  );
};
