import React, { useContext, useState } from "react";
import { PortalOptionMatches } from "../shared/types";

interface OffPlatformExportContextType {
  portalOptionMatches: PortalOptionMatches;
  setPortalOptionMatches: React.Dispatch<
    React.SetStateAction<PortalOptionMatches>
  >;
}

const OffPlatformExportContext = React.createContext<
  OffPlatformExportContextType | undefined
>(undefined);

export const OffPlatformExportProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [portalOptionMatches, setPortalOptionMatches] =
    useState<PortalOptionMatches>({});

  const contextValue: OffPlatformExportContextType = {
    portalOptionMatches,
    setPortalOptionMatches,
  };

  return (
    <OffPlatformExportContext.Provider value={contextValue}>
      {children}
    </OffPlatformExportContext.Provider>
  );
};

OffPlatformExportProvider.displayName = "OffPlatformExportProvider";

export const useOffPlatformExportContext = () => {
  const offPlatformExportContext = useContext(OffPlatformExportContext);
  if (!offPlatformExportContext) {
    throw new Error(
      "useOffPlatformExportContext must be used within an OffPlatformExportProvider",
    );
  }
  return offPlatformExportContext;
};
