import {
  FetchingDataStatus,
  IDesignStudioDataFetchResponse,
  IDesignStudioState,
} from "shared/types/designStudio";

import API from "services";
import GenericError from "shared/errors/GenericError";
import { IConfig } from "shared/types/configuration";
import { IAccount } from "shared/types/accountManagement";
import { roundTimeStamp } from "utils/helpers";
import {
  fetchDataBegin,
  fetchDataFail,
  fetchDataSuccess,
} from "../designStudio.slice";
import { AppThunk } from "redux/store";
import HttpClient from "services/httpClient";

// export all actions from child actions
export * from "./newTemplate/actions";
export * from "./newArtboard/actions";
export * from "./createStamp/actions";

export const fetchData =
  (dataType: FetchingDataStatus, query?: string): AppThunk =>
  async (dispatch, getState) => {
    if (!dataType) {
      // this FetchingDataStatus can be null which indicates that the app is in idle.
      // the dataType must not be null but adding this part just in case.
      return;
    }

    const { designStudio } = getState();

    // rounded date to nearest 15 seconds so service always has a small layer of cache
    const dateQueryParam = `${query ? "&" : ""}date=${roundTimeStamp(
      15 * 1000,
    )}`;

    dispatch(fetchDataBegin(dataType));

    const { configuration } = getState();
    const { config } = configuration;

    let url = "";
    let objectKey:
      | "oems"
      | "dealers"
      | "tags"
      | "templates"
      | "artboards"
      | "stamps";
    const append = false;

    // NOTE: due to heavy template loads, fetching will be done separately.
    switch (dataType) {
      case "accounts":
        url = `${(config as IConfig).services.getDealersUrl}${
          query ? `?${query}` : ""
        }`;
        objectKey = "dealers";

        break;

      case "tags":
        url = (config as IConfig).services.designStudio.getTagsUrl;
        objectKey = "tags";

        break;

      case "artboards":
        url = `${(config as IConfig).services.designStudio.getArtboardsUrl}?${
          query || ""
        }`;
        objectKey = "artboards";
        break;

      case "stamps":
        url = `${(config as IConfig).services.designStudio.getStampsUrl}?${
          query || ""
        }${dateQueryParam}`;
        objectKey = "stamps";

        break;
      default:
        throw new GenericError({
          message: "The dataType to DesignStudio actions is not valid.",
        });
    }

    const { result, error } =
      await HttpClient.get<IDesignStudioDataFetchResponse>(url);

    if (error) {
      dispatch(fetchDataFail());

      return;
    }

    const { [objectKey]: data, paginationKey } = result || {
      data: [],
      paginationKey: null,
    };

    let dataToUse = data;

    if (objectKey === "dealers") {
      const { stores } = designStudio as IDesignStudioState;
      const typedData = data as IAccount[];
      const newItems = typedData.filter(
        dealer =>
          !stores.find(store => dealer.dealer_name === store.dealer_name),
      );

      const newData = [...stores, ...newItems];
      dataToUse = newData;
    }

    dispatch(
      fetchDataSuccess({
        response: {
          data: dataToUse || [],
          paginationKey,
          error: null,
        },
        dataType,
        append,
      }),
    );
  };
