import { useCallback, useState } from "react";
import styles from "./MarketingMaterials.module.scss";
import { Outlet } from "react-router-dom";
import { useLayoutPreference } from "shared/hooks/useLayoutPreference";
import { MarketingMaterialTableItem } from "shared/types/marketingMaterials";
import { useFetchMarketingMaterials } from "./marketingMaterials/hooks/useFetchMarketingMaterials";
import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";
import { DataProvider } from "screens/designStudio/hooks/SalesEnablementDataProvider";
import MarketingMaterialsTable from "./marketingMaterials/MarketingMaterialsTable";
import Cards from "./marketingMaterials/MarketingMaterialsCard";
import { marketingMaterialFields } from "./marketingMaterials/marketingMaterialsTable/fields";
import { useMarketingMaterialActions } from "./marketingMaterials/hooks/useMarketingMaterialActions";
import {
  disabledPrintDeliveryMessage,
  ROUTES,
} from "./marketingMaterials/utils/constants";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";
import { usePdfDeliveryData } from "./marketingMaterials/hooks/usePdfDeliveryData";
import MarketingMaterialFilterDrawer from "./marketingMaterials/MarketingMaterialFilterDrawer";
import { Toolbar } from "./marketingMaterials/Toolbar";
import { getMarketingMaterialValidationErrors } from "./marketingMaterials/utils/getMarketingMaterialValidation";
import { useFetchTemplates } from "shared/hooks/designStudio/useFetchTemplates";
import { longAlert } from "utils/antd/longAlert/longAlert";
import { useFetchAgentFeedInfoByEmail } from "shared/hooks/salesEnablement/useAgentFeedData";
import { useMarketingMaterialErrorStatus } from "./marketingMaterials/hooks/useMarketingMaterialErrorStatus";
import { PrintDrawer } from "./marketingMaterials/PrintDrawer";
import { isRawEnvVarEquals } from "utils/helpers";
import {
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  MailOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { DataListTagsURL } from "shared/components/dataListURL/DataListTagsURL";
import { useDataListURLData } from "shared/components/dataListURL/useDataListURLData";
import { MaterialStatus, TemplateStatus } from "shared/types/salesEnablement";

const localStorageLayoutKey = "MarketingMaterialsLayout";

const MarketingMaterialsContainer = () => {
  const [layout] = useLayoutPreference(localStorageLayoutKey, "card");
  const { onDelete, onDuplicate } = useMarketingMaterialActions();
  const { getMarketingMaterialErrorStatus } = useMarketingMaterialErrorStatus();
  const [openFilter, setOpenFilter] = useState(false);

  const navigateWithSearch = useNavigateWithSearch();

  const {
    marketingMaterials,
    isLoading: marketingMaterialsLoading,
    isError,
  } = useFetchMarketingMaterials();

  const { templates } = useFetchTemplates();

  const getAgentInfo = useFetchAgentFeedInfoByEmail();

  const { data, originalData, isLoading, selectedItems } = useDataListURLData<
    keyof MarketingMaterialTableItem,
    MarketingMaterialTableItem
  >({
    isError,
    isLoading: marketingMaterialsLoading,
    data: marketingMaterials,
  });

  const { getPdfDeliveryData, isLoading: isPdfLoading } = usePdfDeliveryData();

  const validateSelectedMaterials = useCallback(
    async (selectedMaterials: MarketingMaterialTableItem[]) => {
      const validatePromises = selectedMaterials.map(material => {
        const materialTemplate = templates.find(
          template => template.id === material.templateId,
        );
        if (!materialTemplate) return Promise.resolve({ isValid: false });

        return getMarketingMaterialValidationErrors({
          material,
          template: materialTemplate,
          getAgentInfo,
          materialErrorStatus: getMarketingMaterialErrorStatus(
            materialTemplate,
            material,
          ),
        });
      });
      return Promise.all(validatePromises);
    },
    [templates, getAgentInfo, getMarketingMaterialErrorStatus],
  );

  const downloadPdfs = async (
    selectedMaterials: MarketingMaterialTableItem[],
  ) => {
    const areMaterialsValid = selectedMaterials.every(material => {
      const materialTemplate = templates.find(
        template => template.id === material.templateId,
      );
      if (!materialTemplate) return false;

      const errorStatus =
        material.templateErrorStatus || material.materialErrorStatus;
      if (
        errorStatus &&
        (errorStatus.status === "Expired" ||
          errorStatus.status === "Unavailable")
      ) {
        return false;
      }

      return (
        material.materialStatus === MaterialStatus.READY &&
        materialTemplate.status === TemplateStatus.PUBLISHED
      );
    });

    if (!areMaterialsValid) {
      longAlert({
        header:
          "One or more selected materials are not Ready to Deliver and cannot be downloaded.",
        type: "error",
      });
      return;
    }
    getPdfDeliveryData(selectedMaterials);
  };

  const getSendByEmailStatus = useCallback(
    (selectedMaterials: MarketingMaterialTableItem[]) => {
      if (selectedMaterials.length === 0)
        return {
          isSendByEmailDisabled: true,
          sendByEmailTooltip: "Select at least one material to send by email",
        };
      if (
        selectedMaterials.some(
          item => !item?.templateDeliveryMethods?.includes("email"),
        )
      )
        return {
          isSendByEmailDisabled: true,
          sendByEmailTooltip:
            "PDFs can't be sent by email. Please select only HTML files to send by email.",
        };

      if (
        selectedMaterials.some(
          item =>
            !!item.templateErrorStatus ||
            item.templateStatus !== TemplateStatus.PUBLISHED,
        )
      )
        return {
          isSendByEmailDisabled: true,
          sendByEmailTooltip:
            selectedMaterials.length === 1
              ? "The selected material is unavailable and can't be sent by email."
              : "Some selected materials are unavailable and can't be sent by email.",
        };

      if (selectedMaterials.some(item => !!item.materialErrorStatus))
        return {
          isSendByEmailDisabled: true,
          sendByEmailTooltip:
            selectedMaterials.length === 1
              ? "Action cannot be performed. This material contains errors."
              : "Action cannot be performed. Some selected materials contain errors.",
        };
      return {
        isSendByEmailDisabled: false,
        sendByEmailTooltip: "Send by Email",
      };
    },
    [],
  );

  const onSendByEmail = async (
    selectedMaterials: MarketingMaterialTableItem[],
  ) => {
    const areMaterialsValid = (
      await validateSelectedMaterials(selectedMaterials)
    ).every(({ isValid }) => isValid);
    if (!areMaterialsValid) {
      longAlert({
        header:
          "One of the selected materials is incomplete and unable to be Emailed.",
        type: "error",
      });
      return;
    }
    navigateWithSearch(`${ROUTES.emailDelivery(selectedMaterials[0].id)}`);
  };

  const onPrint = async (selectedMaterials: MarketingMaterialTableItem[]) => {
    const areMaterialsValid = (
      await validateSelectedMaterials(selectedMaterials)
    ).every(({ isValid }) => isValid);
    if (!areMaterialsValid) {
      longAlert({
        header:
          "One of the selected materials are incomplete and unable to be Printed.",
        type: "error",
      });
      return;
    }
    navigateWithSearch(ROUTES.print());
  };

  const disabled = (material: MarketingMaterialTableItem | undefined) =>
    !!material?.templateErrorStatus;
  const tooltipOrError =
    (tooltip: string) => (material: MarketingMaterialTableItem | undefined) =>
      material?.templateErrorStatus?.message ?? tooltip;

  const canDownload = (material: MarketingMaterialTableItem | undefined) =>
    !!material &&
    !!material.templateDeliveryMethods?.includes("download") &&
    material.templateStatus === TemplateStatus.PUBLISHED &&
    material.materialStatus === MaterialStatus.READY;

  const isPrintEnabled = isRawEnvVarEquals("REACT_APP_ENV", "dev");

  return (
    <div className={styles.container}>
      <Toolbar
        selectedItems={selectedItems}
        getSendByEmailStatus={getSendByEmailStatus}
        getPdfDeliveryData={downloadPdfs}
        isPdfDownloading={isPdfLoading}
        onSendByEmail={onSendByEmail}
        onPrint={onPrint}
      />
      <DataListTagsURL<
        keyof MarketingMaterialTableItem,
        MarketingMaterialTableItem
      >
        data={data}
        originalData={originalData}
        onAddFilterClick={() => setOpenFilter(true)}
        showSelectAll={layout === "card"}
      />
      <div>
        {layout === "table" && (
          <MarketingMaterialsTable
            data={data}
            originalData={originalData}
            loading={isLoading}
            isPdfLoading={isPdfLoading}
            onDownload={downloadPdfs}
            onEmail={onSendByEmail}
            onPrint={onPrint}
          />
        )}
        {layout === "card" && (
          <Cards
            materials={data}
            isLoading={isLoading}
            defaultAction={id => navigateWithSearch(id)}
            actions={[
              {
                type: "edit",
                icon: <EditOutlined />,
                handler: item => () => {
                  if (!item) return;
                  navigateWithSearch(`${item.id}`);
                },
                tooltipTitle: () => "Edit",
              },
              {
                type: "duplicate",
                icon: <CopyOutlined />,
                handler: item => () => {
                  if (!item) return;
                  onDuplicate({ material: item, mode: "optimistic" });
                },
                disabled,
                tooltipTitle: tooltipOrError("Duplicate"),
              },
              {
                type: "email",
                icon: <MailOutlined />,
                handler: item => () => {
                  if (!item) return;
                  onSendByEmail([item]);
                },
                disabled: material =>
                  !!material &&
                  getSendByEmailStatus([material]).isSendByEmailDisabled,
                tooltipTitle: material =>
                  (material &&
                    getSendByEmailStatus([material]).sendByEmailTooltip) ??
                  "Send by Email",
              },
              {
                type: "download",
                icon: <DownloadOutlined />,
                handler: item => () => {
                  if (!item) return;
                  downloadPdfs([item]);
                },
                disabled: material =>
                  !canDownload(material) ||
                  !!material?.materialErrorStatus ||
                  isPdfLoading,
                tooltipTitle: material => {
                  if (canDownload(material)) return "Download";
                  return (
                    material?.materialErrorStatus?.message ??
                    "This material can't be downloaded."
                  );
                },
              },
              {
                type: "print",
                icon: <PrinterOutlined />,
                handler: item => () => {
                  if (!item) return;
                  onPrint([item]);
                },
                tooltipTitle: () =>
                  isPrintEnabled ? "Print" : disabledPrintDeliveryMessage,
                disabled: () => !isPrintEnabled,
              },
              {
                type: "delete",
                icon: <DeleteOutlined />,
                handler: item => () => {
                  if (!item) return;
                  onDelete({ ids: [item.id], mode: "optimistic" });
                },
                tooltipTitle: () => "Delete",
              },
            ]}
          />
        )}
      </div>
      <Outlet />
      <MarketingMaterialFilterDrawer
        open={openFilter}
        onClose={() => setOpenFilter(false)}
      />
    </div>
  );
};

const MarketingMaterials = () => {
  return (
    <DataProvider>
      <DataListURLProvider fields={marketingMaterialFields}>
        <MarketingMaterialsContainer />
        <PrintDrawer />
      </DataListURLProvider>
    </DataProvider>
  );
};

export default MarketingMaterials;
