import { Table } from "antd";
import { usePagination } from "../table";
import { useColsSearch } from "./useColsSearch";
import { useMediaTable } from "./useMediaTable";
import { useTableSearch } from "./useTableSearch";

type Props = {
  isFetching: boolean;
  data?: WDSearchResult;
};

export const TableSearch = ({ data, isFetching }: Props) => {
  const { components, rowSelection } = useMediaTable();
  const { setPage } = useTableSearch();
  const pagination = usePagination(setPage, data);
  const columns = useColsSearch();

  return (
    <div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={data?.items}
        loading={isFetching || !data}
        rowSelection={rowSelection}
        components={components}
        pagination={pagination}
        scroll={{
          x: "max-content",
        }}
      />
    </div>
  );
};
