import DataListURLTable from "shared/components/dataListURL/DataListURLTable";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { RightOutlined } from "@ant-design/icons";
import {
  PrintOptionsTableColumns,
  PrintOptionsTableItem,
} from "shared/types/printOptions";
import { columns } from "./table/columns";
import { printOptionsFieldsKeys } from "./table/fields";
import { NestedCopiesTable } from "./table/NestedCopiesTable";

type Props = {
  data: PrintOptionsTableItem[];
};

export const Table = ({ data }: Props) => {
  const { windowInnerHeight, windowInnerWidth } = useWindowSize();

  return (
    <DataListURLTable<PrintOptionsTableColumns, PrintOptionsTableItem>
      loading={false}
      dataSource={data}
      columns={columns}
      pagination={false}
      size="small"
      scroll={{ y: windowInnerHeight - 265, x: windowInnerWidth - 20 }}
      type="virtual"
      fieldKeys={printOptionsFieldsKeys}
      expandable={{
        expandedRowRender: record => {
          return <NestedCopiesTable data={record.costPerCopy} />;
        },
        rowExpandable: () => true,
        expandIcon: ({ expanded, onExpand, record }) => (
          <RightOutlined
            style={{
              transition: "all 0.1s ease-in-out",
              transform: `${
                expanded ? "rotate(90deg)" : "rotate(0deg)"
              } scale(0.8)`,
            }}
            onClick={e => onExpand(record, e)}
          />
        ),
      }}
    />
  );
};
