import { castArray, isEmpty, xor } from "lodash";
import { useDataListURLContext } from "./dataListURLContext";
import { useCallback } from "react";

export const useDataListURLIds = <RecordType>(
  getAllIds: (data: RecordType[]) => string[],
) => {
  const { selectedIds, setSelectedIds, showSelected, setShowSelected } =
    useDataListURLContext();

  const setSelectedItemIds = useCallback(
    (keys: string[] | undefined) => {
      setSelectedIds(keys ?? []);

      if (isEmpty(keys)) {
        setShowSelected(false);
      }
    },
    [setSelectedIds, setShowSelected],
  );

  const toggleSelectedIds = (key: string | string[]) => {
    setSelectedIds(previousKeys => {
      const newSelectedIds = xor(previousKeys, castArray(key));

      if (isEmpty(newSelectedIds)) {
        setShowSelected(false);
      }

      return newSelectedIds;
    });
  };

  const toggleShowSelected = () => {
    setShowSelected(previousShowSelected => !previousShowSelected);
  };

  const toggleSelectAll = (dataSource: any[]) => {
    const computedIds = getAllIds(dataSource);
    if (!dataSource.length || selectedIds.length === computedIds.length) {
      setSelectedIds([]);
      setShowSelected(false);
    } else if (dataSource.length > 0) {
      setSelectedIds(computedIds);
    }
  };

  return {
    selectedIds,
    showSelected,
    setSelectedItemIds,
    toggleSelectedIds,
    toggleShowSelected,
    toggleSelectAll,
  };
};
