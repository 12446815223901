import { DataNode } from "antd/lib/tree";
import React, { useState, useMemo, SetStateAction } from "react";
import { useFetchInstantExperiences } from "shared/hooks/adLibrary/useFetchInstantExperiences";
import { IAdToLoadData } from "shared/types/adLibrary";
import { getNormalizedAdData, getAdsTreeData } from "./helpers.adsTree";

type AdReviewTreeProps = {
  treeData: DataNode[];
  checkedKeys: React.Key[];
  setCheckedKeys: (checkedKeys: SetStateAction<React.Key[]>) => void;
};

export const useAdsTreeProps = (
  adsToLoad: IAdToLoadData[],
): AdReviewTreeProps => {
  const { data: instantExperiences = [] } = useFetchInstantExperiences();

  const treeNodeData = useMemo(
    () => getNormalizedAdData(adsToLoad, instantExperiences),
    [adsToLoad, instantExperiences],
  );

  const treeData: DataNode[] = useMemo(() => {
    return getAdsTreeData(treeNodeData);
  }, [treeNodeData]);

  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>(() =>
    adsToLoad.map(ad => ad.key),
  );

  return {
    treeData,
    checkedKeys,
    setCheckedKeys,
  };
};
