import API from "services";
import { useMutation, useQueryClient } from "react-query";
import {
  IDeleteDashboardResponseData,
  IDeletePublicDashboardResponseData,
} from "shared/types/dashboardManagement";
import { queryKeys } from "./queryKeys";

const deleteDashboard = async (id: string) => {
  const { result, error } =
    await API.services.dashboardManagement.deleteDashboard(id);

  if (error) {
    throw Error(error.message);
  }

  return result;
};

const deletePublicDashboard = async (id: string) => {
  const { result, error } =
    await API.services.dashboardManagement.deletePublicDashboard(id);

  if (error) {
    throw Error(error.message);
  }

  return result;
};

const deletePublicDashboards = async (ids: string[]) => {
  const { result, error } =
    await API.services.dashboardManagement.deletePublicDashboards(ids);

  if (error) {
    throw Error(error.message);
  }

  return result;
};

export const useDeleteDashboard = () => {
  const queryClient = useQueryClient();

  return useMutation<IDeleteDashboardResponseData | null, Error, string>(
    deleteDashboard,
    {
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.dashboards);
      },
    },
  );
};

export const useDeletePublicDashboard = () => {
  const queryClient = useQueryClient();

  return useMutation<IDeletePublicDashboardResponseData | null, Error, string>(
    deletePublicDashboard,
    {
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.publicDashboards);
      },
    },
  );
};

export const useDeletePublicDashboards = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IDeletePublicDashboardResponseData | null,
    Error,
    string[]
  >(deletePublicDashboards, {
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.publicDashboards);
    },
  });
};
