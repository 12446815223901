import { useMemo } from "react";
import { produce } from "immer";
import { TableProps } from "antd";
import { getFillAdInfoTableData } from "./utils/getFillAdInfoTableData";
import { getColumns } from "./FillAdInfoColumns";
import { useFeedContext } from "../../shared/contexts/FeedContext";
import { StringParam, useQueryParams } from "use-query-params";
import { useDataList } from "shared/components/dataList";
import { FeedTblRow } from "shared/types/assetExporter";
import {
  successNotification,
  warningNotification,
} from "shared/components/customNotification/Notification";
import { useAdLibraryExportContext } from "./AdLibraryExportContext";
import { useCellContext } from "screens/assetExporter/feedConfiguration/editableCell/CellContext";
import { isCallToActionValue } from "./utils/callToActionValuesValidations";
import { isValidDisplayUrl, isValidUrl } from "./utils/urlValuesValidations";
import { validateRowData } from "./utils/validateRowData";
import {
  AdInfoUpdates,
  FillAdInfoTableData,
  RecommendedColumnTitle,
} from "../shared/types";

type UseFillAdInfoTable = (page: number) => {
  columns: TableProps<FillAdInfoTableData>["columns"];
  data: FillAdInfoTableData[];
  isLoading: boolean;
};

export const useFillAdInfoTable: UseFillAdInfoTable = page => {
  const [dataList] = useDataList<FeedTblRow>();
  const { assetBatchesForSelectedRows, selectedRows, feedId } =
    useFeedContext();
  const {
    columnMatches,
    adType,
    adInfoUpdates,
    setAdInfoUpdates,
    validity,
    setValidity,
  } = useAdLibraryExportContext();
  const { editingField, editingRow } = useCellContext();

  const [queryParams] = useQueryParams({ feedName: StringParam });
  const { feedName } = queryParams;

  const selectedFeedRows = useMemo(
    () =>
      dataList.data.filter(feedRow =>
        selectedRows.includes(feedRow.rowIdentifier),
      ),
    [dataList, selectedRows],
  );

  const tableData = useMemo(() => {
    if (!feedName || !adType) return [];
    const data: FillAdInfoTableData[] = getFillAdInfoTableData({
      assetBatchesForSelectedRows,
      data: selectedFeedRows,
      feedName,
      columnMatches,
      adType,
      page,
      feedId,
      adInfoUpdates,
    });

    validateRowData(data, validity, setValidity);
    return data;
  }, [
    feedName,
    adType,
    assetBatchesForSelectedRows,
    selectedFeedRows,
    columnMatches,
    page,
    feedId,
    adInfoUpdates,
    validity,
    setValidity,
  ]);

  const handleSave = (value: string) => {
    const rowIdentifier = selectedFeedRows[+editingRow].rowIdentifier;
    const previousValues = { ...adInfoUpdates };
    const isNotValidDisplayUrl =
      editingField === RecommendedColumnTitle.DisplayURL &&
      !isValidDisplayUrl(value);

    setAdInfoUpdates(
      produce(previousAdInfoUpdates => {
        let updatedValue = value;
        if (isNotValidDisplayUrl)
          updatedValue = value.replace(/^https?:\/\//, "");

        previousAdInfoUpdates[rowIdentifier] = {
          ...previousAdInfoUpdates[rowIdentifier],
          [editingField]: updatedValue,
        };
        return previousAdInfoUpdates;
      }),
    );

    const isValidCTA =
      editingField === RecommendedColumnTitle.CTAButton &&
      isCallToActionValue(value);
    const isValidDestinationURL =
      editingField === RecommendedColumnTitle.DestinationURL &&
      isValidUrl(value);

    if (isValidCTA || isValidDestinationURL) {
      setValidity(prevValidity => ({
        ...prevValidity,
        [editingRow]: {
          ...prevValidity[editingRow],
          [RecommendedColumnTitle.CTAButton]: isValidCTA,
          [RecommendedColumnTitle.DestinationURL]: isValidDestinationURL,
        },
      }));
    }

    if (isNotValidDisplayUrl)
      warningNotification({
        messageLabel:
          "Text has been saved. Any / or http(s) was automatically removed to meet Ad Library requirements for the Display URL field.",
        size: "big",
        linkText: "Undo",
        duration: 10,
        key: `warningMsg-${rowIdentifier}`,
        onClickLink: () => setAdInfoUpdates(previousValues),
      });
    else
      successNotification({
        messageLabel: "Text has been saved.",
        size: "big",
        linkText: "Undo",
        duration: 10,
        key: `successMsg-${rowIdentifier}`,
        onClickLink: () => setAdInfoUpdates(previousValues),
      });
  };

  const handleApplyToAll = (value: string) => {
    const previousValues = { ...adInfoUpdates };

    setAdInfoUpdates(previousAdInfoUpdates =>
      selectedRows.reduce<AdInfoUpdates>((acc, rowIdentifier) => {
        acc[rowIdentifier] = {
          ...previousAdInfoUpdates[rowIdentifier],
          [editingField]: value,
        };
        return acc;
      }, {}),
    );

    successNotification({
      messageLabel: "The changes have been applied to all rows and saved.",
      size: "big",
      linkText: "Undo",
      duration: 10,
      onClickLink: () => setAdInfoUpdates(previousValues),
    });
  };

  const columns = getColumns(adType, handleSave, handleApplyToAll);

  return {
    columns,
    data: tableData,
    isLoading: dataList.isLoading,
  };
};
