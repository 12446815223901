import { useQuery } from "react-query";
import API from "services";
import {
  IRawOfferDataFromService,
  OfferData,
  TGetOfferListItem,
  TGetOfferListResult,
  TOfferListQueryParameters,
} from "shared/types/assetBuilder";
import moment from "moment";

const processRawOfferData = (
  rawOfferData: IRawOfferDataFromService["row"],
): Partial<OfferData> | null => {
  if (!rawOfferData) return null;

  const { vin, rowIdentifier } = rawOfferData;
  if (!vin && !rowIdentifier) return null; // There is no unique id.. unable to proceed.

  return {
    ...rawOfferData,
    daysInStock: `${calculateDaysDiff(rawOfferData.dateInStock) || "--"}`,
    vin: rawOfferData.vin ?? rawOfferData["rowIdentifier"],
  };
};

export const calculateDaysDiff = (
  rawDateInStock?: number | string,
  timeOffeset?: number,
): number | null => {
  if (!rawDateInStock) return null;

  const dateInStockMilli: number =
    typeof rawDateInStock === "string"
      ? parseInt(rawDateInStock)
      : rawDateInStock;

  const dateInStock = moment(dateInStockMilli + (timeOffeset || 0));
  const now = moment(Date.now());

  const daysInStock = now.diff(dateInStock, "days");

  return daysInStock;
};

export const useFetchOfferListV2 = (
  params: TOfferListQueryParameters,
  enabled: boolean = true,
) => {
  return useQuery(["offer-list", params], () => getOfferListV2(params), {
    enabled,
  });
};

const getOfferListV2 = async (
  params: TOfferListQueryParameters,
): Promise<TGetOfferListResult> => {
  const { result, error } = await API.services.assetBuilder.fetchOfferListV2<{
    result: TGetOfferListResult;
    error: { message: string };
  }>(params);

  if (error) {
    throw new Error(error.message);
  }

  const offerList = result.offerList?.map(rawOffer => ({
    ...rawOffer,
    row: processRawOfferData(rawOffer.row),
  })) as TGetOfferListItem[];

  return {
    ...result,
    offerList,
  };
};
