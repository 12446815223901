import { Button, Checkbox, Col, Divider, Modal, Row } from "antd";
import { Fragment } from "react";
import style from "./ColumnsFilter.module.scss";

interface IFilterProps {
  showDisplayColumnModal: boolean;
  displayColumns: string[];
  totalColumns: string[];
}

interface IFilterHandlers {
  setDisplayColumns: (data: string[]) => void;
  setShowDisplayColumnModal: (showDisplayColumnModal: boolean) => void;
}

type TColumnFilters = IFilterProps & IFilterHandlers;

export const ColumnsFilter = ({
  showDisplayColumnModal,
  displayColumns,
  totalColumns,
  setDisplayColumns,
  setShowDisplayColumnModal,
}: TColumnFilters) => {
  const isSelectedAll = totalColumns.length === displayColumns.length;

  return (
    <Modal
      className={style.modal}
      title="Show / Hide Columns"
      width="auto"
      onCancel={() => {
        setShowDisplayColumnModal(false);
      }}
      visible={showDisplayColumnModal}
      footer={
        <div className={style.footer}>
          <Checkbox
            className={style.selectAll}
            key="selectAll"
            checked={isSelectedAll}
            onClick={() => setDisplayColumns(isSelectedAll ? [] : totalColumns)}
          >
            Select All
          </Checkbox>
          <Button
            disabled={
              displayColumns.length === totalColumns.length ||
              displayColumns.length === 0
            }
            key="ok"
            type="primary"
            onClick={() => {
              setShowDisplayColumnModal(false);
            }}
          >
            OK
          </Button>
        </div>
      }
    >
      <Col>
        <Row className={style.disclaimer}>
          Columns shown are unique to each feed.
        </Row>
        <Checkbox.Group
          defaultValue={displayColumns}
          value={displayColumns}
          onChange={newColumns => {
            const sortedDisplayColumns = totalColumns
              .map(col => {
                if (newColumns.find(newCol => newCol === col)) return col;
              })
              .filter(Boolean);
            setDisplayColumns(sortedDisplayColumns as string[]);
          }}
          className={style.checkboxGroup}
        >
          <Row className={style.container} align="top">
            {totalColumns.map((name, index) => (
              <Fragment key={name}>
                <Checkbox
                  style={{ width: "45%", marginLeft: "8px" }}
                  value={name}
                  checked={displayColumns.includes(name)}
                >
                  {name}
                </Checkbox>
                {(index + 1) % 2 === 1 && (
                  <Divider type="vertical" className={style.divider} />
                )}
              </Fragment>
            ))}
          </Row>
        </Checkbox.Group>
      </Col>
    </Modal>
  );
};
