import { memo, useMemo } from "react";
import { Form, message, Select } from "antd";

import {
  returnDealerRecordsFromDealers,
  useFetchInfiniteDealers,
} from "shared/hooks/useFetchDealers";

import { IAccountRecord } from "shared/types/accountManagement";

interface IProps {
  selectedDealer?: IAccountRecord;
  onDealerSelect: (dealer?: IAccountRecord) => void;
}

function LoadDestination({ selectedDealer, onDealerSelect }: IProps) {
  const {
    dealers = [],
    isError,
    error,
    isFetching,
    isLoading,
  } = useFetchInfiniteDealers();

  const loadingStores = isFetching || isLoading;

  if (isError) {
    message.error(error?.message || "Could not fetch stores.");
  }

  const dealerRecords = useMemo(
    () => returnDealerRecordsFromDealers(dealers),
    [dealers],
  );

  return (
    <Form.Item style={{ paddingTop: "1em" }} label="Client Page Name">
      <Select
        showSearch
        placeholder={loadingStores ? "Fetching stores..." : "Select a Store"}
        value={selectedDealer?.dealerName}
        loading={loadingStores}
        disabled={loadingStores}
        onSelect={(dealerName: string) => {
          onDealerSelect(
            dealerRecords?.find(
              (dealer: IAccountRecord) => dealer.dealerName === dealerName,
            ),
          );
        }}
        onDeselect={() => {
          onDealerSelect();
        }}
      >
        {dealerRecords?.map((dealer: IAccountRecord) => (
          <Select.Option key={dealer.dealerName} value={dealer.dealerName}>
            {dealer.dealerName}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default memo(LoadDestination);
