import { IDataListColumns } from "shared/components/dataList";
import {
  checkFilterMatch,
  compareString,
} from "utils/helpers";
import { AudienceIntegrationLog } from "./types";

export const dataColumns: IDataListColumns<AudienceIntegrationLog> = {
  audienceName: {
    filterFn: (value, record) => {
      return checkFilterMatch(value?.toString(), record.audience.name);
    },
    sorterFn(a: AudienceIntegrationLog, b: AudienceIntegrationLog) {
      return compareString(a.audience.name, b.audience.name);
    },
  },
  audienceId: {
    filterFn: (value, record) => {
      return checkFilterMatch(value?.toString(), record.audience.id);
    },
    sorterFn(a: AudienceIntegrationLog, b: AudienceIntegrationLog) {
      return +a.audience.id - +b.audience.id;
    },
  },
  platform: {
    filterFn: (value, record) => {
      return checkFilterMatch(value?.toString(), record.platform);
    },
    sorterFn(a: AudienceIntegrationLog, b: AudienceIntegrationLog) {
      return compareString(a.platform, b.platform);
    },
  },
  dealerName: {
    filterFn: (value, record) => {
      return checkFilterMatch(value?.toString(), record.account.dealerName);
    },
    sorterFn(a: AudienceIntegrationLog, b: AudienceIntegrationLog) {
      return compareString(a.account.dealerName, b.account.dealerName);
    },
  },
  adAccountName: {
    filterFn: (value, record) => {
      return checkFilterMatch(value?.toString(), record.account.adAccountName);
    },
    sorterFn(a: AudienceIntegrationLog, b: AudienceIntegrationLog) {
      return compareString(a.account.adAccountName, b.account.adAccountName);
    },
  },
  adAccountId: {
    filterFn: (value, record) => {
      return checkFilterMatch(value?.toString(), record.account.adAccountId);
    },
    sorterFn(a: AudienceIntegrationLog, b: AudienceIntegrationLog) {
      return +a.account.adAccountId - +b.account.adAccountId;
    },
  },
  createdAt: {
    sorterFn(a: AudienceIntegrationLog, b: AudienceIntegrationLog) {
      return a.createdAt - b.createdAt;
    },
  },
  updatedAt: {
    sorterFn(a: AudienceIntegrationLog, b: AudienceIntegrationLog) {
      return a.updatedAt - b.updatedAt;
    },
  },
};
