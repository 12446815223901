import { Typography, Form, FormInstance } from "antd";
import { memo, ReactElement } from "react";
import InputParameters from "../adWizard/AdViewEdit/InputParameters";
import { AdType } from "../facebookUtils/types";
import { IAd, IAdForm, QcStatus } from "shared/types/adLibrary";
import { IndustryType } from "shared/types/shared";
import QCStatusSelector from "./AdViewEdit/QCStatusSelector";

interface IProps {
  adEdit?: IAd;
  form: FormInstance<IAdForm>;
  clientType: IndustryType;
  adType: AdType;
  setQcStatusChange: ({
    detail,
    change,
  }: {
    detail: QcStatus;
    change: QcStatus;
  }) => void;
  isValid: boolean;
}

function AdParameters({
  adEdit,
  form,
  clientType,
  adType,
  setQcStatusChange,
  isValid,
}: IProps): ReactElement {
  return (
    <div className="form-column">
      <div className="column-title">
        <Typography.Text>{"Parameters"}</Typography.Text>
        <div style={{ width: 200 }}>
          <Form.Item name="qcStatus" valuePropName="status">
            <QCStatusSelector
              previousStatus={adEdit?.qcStatus}
              notifyQcStatusChange={setQcStatusChange}
              isValid={isValid}
            />
          </Form.Item>
        </div>
      </div>
      <div>
        {adType && (
          <InputParameters
            form={form}
            clientType={clientType}
            adType={adType}
          />
        )}
      </div>
    </div>
  );
}

export default memo(AdParameters);
