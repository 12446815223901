import { CheckOutlined } from "@ant-design/icons";

const NoIssues = () => (
  <div style={{ flex: "none", lineHeight: "32px", marginRight: 8 }}>
    <CheckOutlined style={{ color: "#52C41A", fontSize: 16 }} />{" "}
    <span>No Issues</span>
  </div>
);

export default NoIssues;
