import { memo, useCallback, useEffect, useState } from "react";
import { Button, Select, Typography } from "antd";
import {
  IAdLoadParametersRule,
  IRulesetOptionsObject,
} from "shared/types/adLibrary";
import { DeleteOutlined } from "@ant-design/icons";

import styles from "./RuleInput.module.scss";
import { IPanelRule } from "../../shared/types";
import { isMixedKey } from "screens/adLibrary/adLoad/utils";
import { isEmpty } from "lodash";

interface IRuleInputProps {
  rule: IPanelRule;
  updateRule: (rule: Partial<IPanelRule>) => void;
  deleteRule: () => void;
  adRuleOptions: IRulesetOptionsObject;
  isLoadingOptions: boolean;
}

const RuleInput = ({
  rule,
  updateRule,
  deleteRule,
  adRuleOptions,
  isLoadingOptions,
}: IRuleInputProps) => {
  const [componentRule, setComponentRule] =
    useState<IAdLoadParametersRule>(rule);

  const onChange = (
    key: keyof IAdLoadParametersRule,
    value: string | string[],
  ) => {
    const updatedRule = { ...componentRule, [key]: value };

    const isRuleCompleted =
      updatedRule.parameter &&
      updatedRule.qualifier &&
      !isEmpty(updatedRule.values);

    if (isRuleCompleted) {
      updateRule(updatedRule);
      return;
    }

    setComponentRule(updatedRule);
  };

  const syncRuleWithComponentState = useCallback(() => {
    setComponentRule(rule);
  }, [rule]);

  useEffect(syncRuleWithComponentState, [syncRuleWithComponentState]);

  const isInputDisabled = (inputValue: string | string[]) => {
    if (Array.isArray(inputValue)) {
      return inputValue.includes(isMixedKey);
    }

    return inputValue === isMixedKey;
  };

  return (
    <div className={styles.ruleInputContainer}>
      <div className={styles.inputContainer}>
        <Typography.Text>Parameter</Typography.Text>
        <Select
          className={styles.input}
          value={componentRule.parameter}
          onSelect={value => onChange("parameter", value)}
          disabled={
            isInputDisabled(componentRule.parameter) || isLoadingOptions
          }
          loading={isLoadingOptions}
        >
          {adRuleOptions.parameter.map(option => (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className={styles.inputContainer}>
        <Typography.Text>Qualifier</Typography.Text>
        <Select
          className={styles.input}
          value={componentRule.qualifier}
          onSelect={value => onChange("qualifier", value)}
          disabled={isInputDisabled(componentRule.qualifier)}
        >
          {adRuleOptions.qualifier.map(option => (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className={styles.inputContainer}>
        <Typography.Text>Values</Typography.Text>
        <Select
          className={styles.input}
          mode="multiple"
          value={componentRule.values}
          onChange={value => onChange("values", value)}
          disabled={isInputDisabled(componentRule.values)}
        >
          {(adRuleOptions.values[componentRule.parameter] || []).map(option => (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      </div>
      <Button
        icon={<DeleteOutlined />}
        type="text"
        size="middle"
        onClick={deleteRule}
      />
    </div>
  );
};

export default memo(RuleInput);
