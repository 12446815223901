import { useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import useUserPreferences from "./useUserPreferences";

import {
  parseParamValueToPropValue,
  parsePropValueToParamValue,
} from "utils/userPreferences";
import isEmpty from "lodash/isEmpty";

import {
  propToUrlParamDict,
  urlParamToPropDict,
} from "shared/constants/userPreferences";

import {
  PreferenceRecord,
  URLParameterKey,
} from "shared/types/userPreferences";

const useUrlParameters = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentPagePreferences, setPreferences } = useUserPreferences();

  const getPreferncesFromUrl = useCallback(() => {
    const urlParams = new URLSearchParams(location.search);
    const preferences: PreferenceRecord = { ...currentPagePreferences };

    if (isEmpty(preferences)) return;
    for (const param in urlParamToPropDict) {
      const value = urlParams.get(param);
      if (!value) continue;
      const paramKey = param as URLParameterKey;
      const prop = urlParamToPropDict[paramKey];
      const parsedValue = parseParamValueToPropValue(paramKey, value);
      preferences[prop] = parsedValue as string[];
    }

    return preferences;
  }, [location.search, currentPagePreferences]);

  // Update URL based on changes to locally-stored preferences
  const updateUrlOnPreferencesChange = useCallback(
    (preferences: typeof currentPagePreferences) => {
      const urlParams = new URLSearchParams(location.search);
      // possible TODO: refactor this
      for (const prop in preferences) {
        const typedProp = prop as keyof typeof currentPagePreferences;
        const key = propToUrlParamDict[typedProp];
        const value = preferences[typedProp];
        const parsedValue = parsePropValueToParamValue(typedProp, value);
        if (!key || !value || !parsedValue) continue;
        urlParams.set(key, parsedValue);
      }

      navigate({ search: urlParams.toString() });
    },
    [location.search, navigate],
  );

  const initPreferences = useCallback(() => {
    const preferencesFromUrl = getPreferncesFromUrl();
    if (!preferencesFromUrl) return;
    setPreferences(preferencesFromUrl);
    updateUrlOnPreferencesChange(preferencesFromUrl);
  }, [getPreferncesFromUrl, setPreferences, updateUrlOnPreferencesChange]);

  const clearUrlPreferences = useCallback(
    (preferenceKeys: URLParameterKey[]) => {
      const urlParams = new URLSearchParams(location.search);

      preferenceKeys.forEach(key => urlParams.delete(key));

      navigate({ search: urlParams.toString() });
    },
    [navigate, location.search],
  );

  return {
    initPreferences,
    getPreferncesFromUrl,
    updateUrlOnPreferencesChange,
    clearUrlPreferences,
  };
};

export default useUrlParameters;
