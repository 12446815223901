import { Button, message as AntMessage, message, Modal } from "antd";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  IAccountManagementState,
  IAccountRecord,
} from "shared/types/accountManagement";
import {
  INewOrder,
  INewOrderRecord,
  IWFProjectNameRecord,
  OrderStatus,
} from "shared/types/newOrders";
import actions from "../../redux/rootActions";
import TabContainer from "../../shared/components/TabContainer";
import NewOrderDrawer from "./orders/NewOrderDrawer";
import OrdersTable from "./orders/OrdersTable";

// TODO: The css styles needed in this page should be re-factored into Orders.scss
import "../AssetBuilder.scss";

import { useNavigate } from "react-router-dom";
import { IAssetBuilderState } from "shared/types/assetBuilder";

import { isEmpty } from "lodash";
import { AppState } from "redux/store";

import { useFetchOrderState } from "shared/hooks/assetBuilder/useFetchOrderState";
import {
  TAssetBuilderPage,
  useRedirect,
} from "shared/hooks/assetBuilder/useRedirect";
import useDeepEffect from "shared/hooks/useDeepEffect";
import { IConfigurationState } from "shared/types/configuration";
import FilterSectionOrders from "./orders/FilterSectionOrders";

import { User } from "redux/auth/auth.slice";
import { useIsAdmin } from "shared/hooks/useIsAdmin";
import { ArrayParam, StringParam, useQueryParams } from "use-query-params";

interface INewOrdersManagement {
  enableWorkfront: boolean;
  newOrdersMessage: string;
  createNewOrder: (inputNewOrder: INewOrder, message?: string) => void;
  deleteNewOrder: (deleteNewOrder: INewOrderRecord) => void;
  updateNewOrder: (
    updateNewOrder: Partial<INewOrderRecord>,
    message?: string,
  ) => void;
  duplicateOrderState: (
    originalOrderId: string,
    newOrderId: string,
    resetToFeedData: boolean,
  ) => void;
  resetOrderFeedback: () => void;
  getWFData: () => void;
  wfProjectNames: IWFProjectNameRecord[] | any[];
  dealerRecords: IAccountRecord[];
  dealerCode233: string;
  showNewOrderDrawer: boolean;
  toggleNewOrderDrawer: (open: boolean) => void;
  redirect?: IAssetBuilderState["redirect"];
  currentSelectedOrder: INewOrderRecord;
  orderCreated: boolean;
  resetAssetInstances: () => void;
  commitOrder: (creatingOrder?: boolean) => Promise<void>;
  saveSelectTemplateSearch: (searchBy: string) => void;
  saveSelectImageSearch: (searchBy: string) => void;
  // setSelectedTabOrders: (selectedTab: string) => void;
  user?: User;
  config?: IConfigurationState["config"];
  onOrderCreated?: (order: INewOrder) => void;
  onOrderUpdated?: (order: INewOrder) => void;
}

const queryConfig = {
  orders_tab: StringParam,
  orders_search: StringParam,
  orders_creator: ArrayParam,
  orders_oem: ArrayParam,
};

export type OrderQueryType = typeof queryConfig;

const NewOrders: FC<INewOrdersManagement> = ({
  enableWorkfront,
  newOrdersMessage,
  createNewOrder,
  deleteNewOrder,
  updateNewOrder,
  duplicateOrderState,
  resetOrderFeedback,
  getWFData,
  dealerRecords,
  wfProjectNames,
  showNewOrderDrawer,
  toggleNewOrderDrawer,
  currentSelectedOrder,
  orderCreated,
  resetAssetInstances,
  commitOrder,
  saveSelectTemplateSearch,
  saveSelectImageSearch,
  ...props
}) => {
  const isAdmin = useIsAdmin();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLauncherModalOpen, toggleIsLauncherModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    // AV2-1631: ensure that all asset instances are cleared when going to the orders page
    resetAssetInstances();

    saveSelectTemplateSearch("");
    saveSelectImageSearch("");

    if (enableWorkfront) {
      getWFData();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (newOrdersMessage) {
      AntMessage.success(newOrdersMessage);
      toggleNewOrderDrawer(false);
      resetOrderFeedback();
    }

    // eslint-disable-next-line
  }, [newOrdersMessage]);

  const navigate = useNavigate();
  const [processingOrderId, setProcessingOrderId] = useState<
    string | undefined
  >(undefined);
  const [launcherOrderId, setLauncherOrderId] = useState<string | undefined>(
    undefined,
  );

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (currentSelectedOrder && currentSelectedOrder.id !== "0") {
        resetAssetInstances();
        commitOrder(true).then(() => {
          navigate(
            `/asset-builder/orders/${currentSelectedOrder.id}/offers/select`,
          );
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commitOrder, navigate, orderCreated, resetAssetInstances]);

  const [orderForUpdate, setOrderForUpdate] =
    useState<INewOrderRecord | null>();
  const [fetchingOrderId, setFetchingOrderId] = useState<string>();
  const savedOrder = useFetchOrderState(fetchingOrderId);
  const [redirectPath, setRedirectPath] = useRedirect({
    savedOrder,
    launcherOrderId,
    processingOrderId,
    page: !!launcherOrderId ? "launcher" : undefined,
  });

  const [query, setQuery] = useQueryParams(queryConfig);

  useDeepEffect(() => {
    if (!redirectPath) return;

    const shouldRedirect = (
      ["select", "launcher"] as TAssetBuilderPage[]
    ).includes(redirectPath.page);
    if (shouldRedirect) {
      const okToProceed =
        redirectPath.page !== "launcher" ||
        !isEmpty(savedOrder?.assetInstances);
      if (!okToProceed) {
        message.error("No Assets Exist for this Order");
        setLauncherOrderId(undefined);
        setRedirectPath(undefined);
        return;
      }

      // If we don't give delay, the page will redirect to order page.
      setTimeout(() => {
        navigate(redirectPath.path);
      }, 2000);
    } else {
      // If it hits this else block, we need to display redirect options for the user.
      // Redirection will be handled in the modal.
      setIsModalOpen(true);
    }
  }, [redirectPath]);

  const orderTableProps = {
    openOrderDrawer: (order: INewOrderRecord) => {
      setOrderForUpdate(order);
      toggleNewOrderDrawer(true);
    },
    deleteNewOrder: deleteNewOrder,
    createNewOrder: createNewOrder,
    updateNewOrder: updateNewOrder,
    duplicateOrderState: duplicateOrderState,

    dealerRecords: dealerRecords,
    fetchOrderStateV2: (orderRecord: INewOrderRecord | INewOrder) => {
      setFetchingOrderId(orderRecord.id);
    },
    setProcessingOrderId: (orderId: string) => {
      setProcessingOrderId(orderId);
    },
    processingOrderId: processingOrderId,
    resetAssetInstances: resetAssetInstances,
    launcherOrderId: launcherOrderId,
    setLauncherOrderId: (orderId: string) => {
      setLauncherOrderId(orderId);
    },
  };

  const buildOrReviewText =
    redirectPath?.page === "review"
      ? "BUILD or " + redirectPath?.page.toUpperCase()
      : redirectPath?.page.toUpperCase();

  const isInlineEditingEnabled = useMemo(
    () => process.env.REACT_APP_AV2_ENABLE_INLINE_EDITING === "true",
    [],
  );

  const handleRedirect = () => {
    if (!redirectPath?.path) return;

    navigate(redirectPath.path);
  };

  const redirectButtons = [
    <Button
      className="secondary-btn"
      key="Go to Select"
      disabled={!isAdmin}
      onClick={() => {
        if (!redirectPath?.defaultPath) return;

        navigate(redirectPath.defaultPath);
      }}
    >
      Go to select
    </Button>,

    ...(isInlineEditingEnabled
      ? [
          <Button
            className="secondary-btn"
            key="Select-inline-editing"
            disabled={!isAdmin}
            onClick={e => {
              e.preventDefault();

              navigate(`${redirectPath?.defaultPath}-v2`);
            }}
          >
            Inline Editing
          </Button>,
        ]
      : []),
    <Button
      data-cy="build-page-submit-button"
      key="skip-to-build-submit"
      className="skip-to-build-button"
      type={!isAdmin ? undefined : "primary"}
      disabled={!isAdmin}
      style={{
        display: redirectPath?.page !== "review" ? "none" : "inline-block",
      }}
      onClick={() => {
        if (!redirectPath?.path) return;

        const buildPath = redirectPath.path.replace("review", "build");
        navigate(buildPath);
      }}
    >
      {`Go to build`}
    </Button>,
    <Button
      key="skip-to-submit"
      className="skip-to-button"
      type="primary"
      onClick={() => handleRedirect()}
    >
      {`Go to ${redirectPath?.page}`}
    </Button>,
  ];

  const orderStatusArr: OrderStatus[] = ["active", "expired", "archived"];
  const titles: Record<OrderStatus, string> = {
    active: "Active",
    expired: "Expired",
    archived: "Archived",
  };

  return (
    <div className="asset-builder-container">
      <TabContainer
        searchQuery={query.orders_search}
        onSearchInputChange={(search: string) => {
          setQuery({ orders_search: !search ? null : search }, "replaceIn");
        }}
        displaySearchView={{
          displayNewOffer: false,
          displaySearchInput: true,
          displayPlusButton: false,
        }}
        displayFilterSection={true}
        filterTab={<FilterSectionOrders query={query} setQuery={setQuery} />}
        onChange={(tab: string) => setQuery({ orders_tab: tab }, "replaceIn")}
        selectedTab={query.orders_tab ?? "active"}
        contentTabs={orderStatusArr.map(status => ({
          title: titles[status],
          component: (
            <OrdersTable
              key={`orders-table-${status}`}
              {...orderTableProps}
              orderStatus={status}
              query={query}
            />
          ),
        }))}
      />

      <NewOrderDrawer
        showAddNewOrderDrawer={showNewOrderDrawer}
        toggleAddNewOrderDrawer={toggleNewOrderDrawer}
        processingNewOrders={false}
        wfProjectNames={wfProjectNames}
        user={props.user}
        enableWorkfront={enableWorkfront}
        config={props.config}
        resetOrderForUpdate={() => {
          setOrderForUpdate(null);
        }}
        onOrderCreated={createdOrder => {
          props.onOrderCreated?.(createdOrder);
          // addRecord(createdOrder as INewOrderRecord);
          setOrderForUpdate(null);
        }}
        onOrderUpdated={order => {
          props.onOrderUpdated?.(order);
          // updateRecord(order as INewOrderRecord);
          setOrderForUpdate(null);
        }}
        orderForUpdate={orderForUpdate}
      />

      <Modal
        visible={isModalOpen}
        title={`Skip to ${!isAdmin ? "REVIEW" : buildOrReviewText}?`}
        maskClosable={true}
        onOk={() => {
          if (!redirectPath?.path) return;

          navigate(redirectPath.path);
        }}
        onCancel={() => {
          setIsModalOpen(false);
          setProcessingOrderId(undefined);
          setRedirectPath(undefined);
        }}
        closable={true}
        footer={redirectButtons}
      >
        {!isAdmin ? (
          <span>
            <p>User Permission Required.</p>
            <p>{`It looks like there is saved data for this order. You can only skip to REVIEW`}</p>
          </span>
        ) : (
          <p>{`It looks like there is saved data for this order. You can skip to ${buildOrReviewText}.`}</p>
        )}
      </Modal>
      <Modal
        visible={isLauncherModalOpen}
        title={"Do you want to move to the Web Launcher Page?"}
        maskClosable={true}
        onCancel={() => {
          toggleIsLauncherModalOpen(false);
        }}
        closable={true}
        footer={[
          [
            <Button
              data-cy="no-btn-modal-launcher-page"
              className="secondary-btn"
              key="Go to Select"
              onClick={() => {
                toggleIsLauncherModalOpen(false);
              }}
            >
              No
            </Button>,
            <Button
              data-cy="orders-page-submit-button"
              key="submit"
              className="skip-to-button"
              type="primary"
              onClick={() => {
                if (!redirectPath?.path) return;

                navigate(redirectPath.path);
              }}
            >
              {`Go to ${redirectPath?.page}`}
            </Button>,
          ],
        ]}
      >
        <p>{`Go to Web Launcher Page.`}</p>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state: AppState) => {
  const { auth, newOrders, dealerManagement, configuration } = state;
  const { config } = configuration;
  const {
    error,
    newOrdersMessage,
    wfProjectNames,
    dealerCode233,
    currentSelectedOrder,
    orderCreated,
  } = newOrders;

  const { assetBuilder } = state;
  const { redirect } = assetBuilder as IAssetBuilderState;

  const { dealerRecords } = dealerManagement as IAccountManagementState;

  return {
    newOrdersMessage,
    error,
    user: auth.user,

    dealerRecords,

    wfProjectNames,
    dealerCode233,

    redirect,
    currentSelectedOrder,
    orderCreated,
    enableWorkfront: config?.featureFlags?.enableWorkfront
      ? config?.featureFlags?.enableWorkfront
      : false,

    config,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    createNewOrder: (inputNewOrder: INewOrder, message?: string) => {
      dispatch(actions.newOrders.createNewOrder(inputNewOrder, message));
    },
    deleteNewOrder: (deleteNewOrder: INewOrderRecord) => {
      dispatch(actions.newOrders.deleteNewOrder(deleteNewOrder));
    },
    updateNewOrder: (
      updateNewOrder: Partial<INewOrderRecord>,
      message?: string,
    ) => {
      dispatch(actions.newOrders.updateNewOrder(updateNewOrder, message));
    },
    duplicateOrderState: (
      originalOrderId: string,
      newOrderId: string,
      resetToFeedData: boolean,
    ) => {
      dispatch(
        actions.assetBuilder.duplicateOrderState(
          originalOrderId,
          newOrderId,
          resetToFeedData,
        ),
      );
    },
    resetOrderFeedback: () => {
      dispatch(actions.newOrders.resetOrderFeedback());
    },
    getWFData: () => {
      dispatch(actions.newOrders.getWFData());
    },
    resetAssetInstances: () => {
      dispatch(actions.assetBuilder.resetAssetInstances());
    },
    commitOrder: (creatingOrder?: boolean) => {
      return dispatch(
        actions.assetBuilder.commitOrder(creatingOrder),
      ) as Promise<void>;
    },
    saveSelectTemplateSearch: (searchBy: string) => {
      dispatch(actions.assetBuilder.saveSelectTemplateSearch(searchBy));
    },
    saveSelectImageSearch: (searchBy: string) => {
      dispatch(actions.assetBuilder.saveSelectImageSearch(searchBy));
    },
    onOrderCreated: () => {
      dispatch(actions.newOrders.onOrderCreated());
    },
    onOrderUpdated: () => {
      dispatch(actions.newOrders.onOrderUpdated());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewOrders);
