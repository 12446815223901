import { useCallback, useEffect } from "react";
import { AppState } from "redux/store";
import { message as AntMessage } from "antd";
import API from "services";
import { CAM_ENABLED } from "shared/components/media";
import GenericError from "shared/errors/GenericError";
import { useAppShallowEqualSelector } from "shared/hooks/useAppSelector";
import { IArtboard } from "shared/types/designStudio";
import { defaultArtboard } from "../artboardDrawer/utils";
import { ImgFiles } from "../artboardDrawer/types";

interface Props {
  artboard: IArtboard;
  artboardToEdit: IArtboard | undefined | null;
  closeDrawer: () => void;
  setArtboard: React.Dispatch<React.SetStateAction<IArtboard>>;
  setFileList: React.Dispatch<React.SetStateAction<ImgFiles[]>>;
  setFilteredFileList: React.Dispatch<React.SetStateAction<ImgFiles[]>>;
}

export const useDrawerArtboard = ({
  closeDrawer,
  artboardToEdit,
  setArtboard,
  artboard,
  setFileList,
  setFilteredFileList,
}: Props) => {
  const { artboards, creatingData, editingArtboardStatus, templates } =
    useAppShallowEqualSelector(({ designStudio }: AppState) => ({
      artboards: designStudio.artboards,
      creatingData: designStudio.creatingData,
      editingArtboardStatus: designStudio.editingArtboardStatus,
      templates: designStudio.templates,
    }));

  const onCreateArtboard = useCallback(() => {
    if (creatingData === "complete_artboard") {
      AntMessage.success("Artboard created successfully.");
      closeDrawer();
    }
  }, [creatingData, closeDrawer]);

  const onEditArtboard = useCallback(() => {
    const handleUpdateFileLists = async () => {
      try {
        const lifeStyleApi = API.services.assetBuilder.getLifeStyleImages;
        const bgImgs = await lifeStyleApi(artboardToEdit?.theme_images);
        const bg25 = bgImgs.map(img =>
          img.url.endsWith(".mp4")
            ? img
            : { ...img, thumbUrl: img.url25, url: img.url },
        );
        setFileList(bg25);
        !CAM_ENABLED && setFilteredFileList(bg25);
      } catch (err) {
        AntMessage.error((err as GenericError).message);
      }
    };

    if (artboardToEdit) {
      setArtboard({ ...artboardToEdit });
      if (artboardToEdit.theme_images) {
        handleUpdateFileLists();
      } else {
        setFileList([]);
        setFilteredFileList([]);
      }
    } else {
      setArtboard({ ...defaultArtboard });
      setFileList([]);
      setFilteredFileList([]);
    }
  }, [artboardToEdit, setArtboard, setFileList, setFilteredFileList]);

  const onUpdateArtboard = useCallback(() => {
    if (editingArtboardStatus === "completed") {
      AntMessage.success("Artboard has been updated.");
    }
  }, [editingArtboardStatus]);

  const doesArtboardHaveChanges = () => {
    const savedArtboard = artboards.find(board => board.name === artboard.name);
    return JSON.stringify(savedArtboard) !== JSON.stringify(artboard);
  };

  useEffect(onCreateArtboard, [onCreateArtboard]);
  useEffect(onEditArtboard, [onEditArtboard]);
  useEffect(onUpdateArtboard, [onUpdateArtboard]);

  return {
    templates,
    onUpdateArtboard,
    doesArtboardHaveChanges,
    creatingData,
    artboards,
  };
};
