import { useMemo } from "react";
import { useFetchInfiniteDealers } from "shared/hooks/useFetchDealers";
import { useFetchFacebookAccounts } from "shared/hooks/useFetchFacebookAccounts";

export const useFillteredAccountsByOEM = (oems: string | undefined) => {
  const { result: accounts = [], isLoading: isLoadingAccounts } =
    useFetchFacebookAccounts();

  const { dealers, isLoading: isLoadingDealers } = useFetchInfiniteDealers();

  const filteredAccountsByDealerOem = useMemo(() => {
    const dealersWithFBAccountId = dealers.filter(
      dealer => dealer.details?.facebook?.fbAccountId,
    );

    const dealersWithSelectedOem = dealersWithFBAccountId.filter(dealer => {
      const dealerOems = dealer.dealer_oem.split(",");
      const selectedOems = oems?.split(",") || [];
      return dealerOems.some(oem => selectedOems.includes(oem.trim()));
    });

    const fbAccountIds = dealersWithSelectedOem.map(
      dealer => dealer.details?.facebook?.fbAccountId,
    );

    return accounts.filter(account =>
      fbAccountIds.includes(account.account_id),
    );
  }, [accounts, dealers, oems]);

  return {
    filteredAccountsByDealerOem,
    isLoading: isLoadingAccounts || isLoadingDealers,
  };
};
