import { Tooltip } from "antd";
import { NavLink } from "react-router-dom";
import { ReactComponent as Edit } from "../../../../../statics/images/edit.svg";
import { IEAssetsElementType } from "../Assets";
import styles from "./IECollapseHeader.module.scss";

type Props = {
  title: string;
  type: IEAssetsElementType;
};
const IECollapseHeader = (props: Props) => {
  const { type } = props;
  const pageExists = type === "IE" || type === "MarketingTile";
  const getTooltipTitle = (type: IEAssetsElementType) => {
    switch (type) {
      case "IE":
        return "Open Everything Ad Library in a New Tab";
      case "MarketingTile":
        return "Open Ad Library in a New Tab";

      default:
        return;
    }
  };
  const getTargetUrl = (type: IEAssetsElementType) => {
    switch (type) {
      case "IE":
        return "everything-ads/ads";

      case "MarketingTile":
        return "ad-library";
      default:
        return;
    }
  };

  return (
    <div className={styles.collapseHeader}>
      <span>{props.title}</span>
      {pageExists && (
        <Tooltip title={getTooltipTitle(type)}>
          <NavLink
            className={styles.ieLibraryLink}
            target="_blank"
            to={getTargetUrl(type) || "#"}
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <Edit />
          </NavLink>
        </Tooltip>
      )}
    </div>
  );
};

export default IECollapseHeader;
