import { IConfig } from "shared/types/configuration";
import { IUserSignUpForm } from "shared/types/userManagement";
import HttpClient from "./httpClient";

export default ({ headers, config }: { headers: any; config: IConfig }) => ({
  createUser: <T>(form: IUserSignUpForm): Promise<T> => {
    const { services } = config;

    return fetch(services.createUserUrl, {
      method: "POST",
      headers,
      body: JSON.stringify({
        signUpForm: form,
      }),
    }).then<T>(response => {
      return response.json();
    });
  },

  deleteUser: <T>(form: IUserSignUpForm): Promise<T> => {
    const { services } = config;

    return fetch(services.deleteUserUrl, {
      method: "DELETE",
      headers,
      body: JSON.stringify({
        signUpForm: form,
      }),
    }).then<T>(response => {
      return response.json();
    });
  },

  updateUser: <T>(form: IUserSignUpForm, id: string): Promise<T> => {
    const { services } = config;

    return fetch(services.updateUserUrl, {
      method: "PUT",
      headers,
      body: JSON.stringify({
        updateForm: form,
        userName: id,
      }),
    }).then<T>(response => {
      return response.json();
    });
  },

  getUsers: <T>(
    paginationToken: string | undefined,
    searchUsersInput: string,
  ): Promise<T> => {
    const { services } = config;
    let url: string = services.getUsersUrl;

    if (paginationToken) {
      const encodedPaginationToken = encodeURIComponent(paginationToken);
      url = `${services.getUsersUrl}?paginationToken=${encodedPaginationToken}`;
    }
    if (searchUsersInput) {
      url = url.includes("paginationToken")
        ? `${url}&searchUsersInput=${searchUsersInput}`
        : `${url}?searchUsersInput=${searchUsersInput}`;
    }

    return fetch(url, {
      method: "GET",
      headers,
    }).then<T>(response => {
      return response.json();
    });
  },

  updateUserStatus: <T>(
    userIdentifier: string,
    userStatus: boolean,
  ): Promise<T> => {
    const updateUserStatusUrl = config.services.updateUserStatusUrl.replace(
      "{userId}",
      userIdentifier,
    );

    return HttpClient.post(updateUserStatusUrl, { userStatus });
  },

  resetUserPassword: <T>(email: string): Promise<T> => {
    const { services } = config;

    return fetch(services.resetUserPasswordUrl, {
      method: "POST",
      headers,
      body: JSON.stringify({
        email,
      }),
    }).then<T>(response => {
      return response.json();
    });
  },
});
