import { Tooltip } from "antd";
import moment from "moment";
import Timeago from "react-timeago";
import { MM_DD_YYYY_FORMAT } from "./constants";

type Props = {
  date?: string | number | Date | null;
};

export const DateFormater = ({ date }: Props) => {
  return date ? (
    <Tooltip placement="top" title={moment(date).format(MM_DD_YYYY_FORMAT)}>
      <Timeago date={date} />
    </Tooltip>
  ) : null;
};
