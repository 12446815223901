import useIEContextAPI from "./everythingAds.hooks/useContextAPI";
import Layout from "./everythingAds/Mapper/shared/Layout";
import LeftPanel from "./everythingAds/Mapper/LeftPanel";
import RightPanel from "./everythingAds/Mapper/RightPanel";
import { useDatadog } from "shared/hooks/useDatadog";

import { ReactFlowProvider } from "react-flow-renderer";
import { SharedProps } from "shared/types/shared";
import { shallowEqual, useSelector } from "react-redux";
import { useCallback } from "react";
import { useAppDispatch } from "shared/hooks/useAppDispatch";

import * as actions from "../redux/shared/shared.slice";
import { useAuthenticated } from "shared/hooks/useAuthenticated";
import FlowCanvas from "./everythingAds/Mapper/FlowCanvas";

const EverythingAds = () => {
  const { Provider: IEProvider } = useIEContextAPI();
  useDatadog();

  const { initiatingShareLink } = useSelector(
    ({ shared }: { shared: SharedProps }) => ({
      initiatingShareLink: shared.initiatingShareLink,
    }),
    shallowEqual,
  );
  const dispatch = useAppDispatch();

  const onShareLinkUpdate = useCallback(
    (url: string) => {
      dispatch(actions.onShareLinkUpdate({ url }));
    },
    [dispatch],
  );
  const isAuthenticated = useAuthenticated();

  return (
    <IEProvider
      initiatingShareLink={initiatingShareLink}
      onShareLinkUpdate={onShareLinkUpdate}
      isAuthenticated={isAuthenticated}
    >
      <ReactFlowProvider>
        <Layout
          leftPanel={isAuthenticated ? <LeftPanel /> : null}
          rightPanel={isAuthenticated ? <RightPanel /> : null}
        >
          <FlowCanvas />
        </Layout>
      </ReactFlowProvider>
    </IEProvider>
  );
};

export default EverythingAds;
