import { useEffect, useMemo, useRef } from "react";
import styles from "./ListItem.module.scss";
import { IInstantExperience } from "shared/types/adLibrary";
import { Element } from "screens/everythingAds.hooks/useContextAPI";
import { isAd, isInstantExp } from "utils/adLibrary.validators";
import { isURLData } from "screens/everythingAds.hooks/useContextAPI.utils";
import { ReactNode } from "react";

type Props = {
  children?: ReactNode;
  element: Element;
  isSelected?: boolean;
  onClick?: (
    element: Element,
  ) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onDragStart?: (
    element: Element,
  ) => (e: React.DragEvent<HTMLDivElement>) => void;
};

const ListItem = (props: Props) => {
  const title = useMemo(() => {
    if (isAd(props.element)) return props.element.inputParameters.name || "-";
    else if (isInstantExp(props.element))
      return (props.element as IInstantExperience).name;
    else if (isURLData(props.element))
      return props.element.labelName || props.element.value;
  }, [props.element]);

  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!ref.current || !props.isSelected) return;

    ref.current.scrollIntoView({ behavior: "smooth" });
  }, [props.isSelected]);
  return (
    <div
      ref={ref}
      className={`${styles.item} ${props.isSelected ? styles.selected : ""}`}
      draggable={true}
      onDragStart={props.onDragStart?.(props.element)}
      onClick={props.onClick?.(props.element)}
    >
      {props.children && props.children}

      {!props.children && (
        <>
          <div className={styles.title}>{title}</div>
          {/* {props.connected && (
        <Tooltip title="This IE is linked to other IEs">
          <NodeIndexOutlined />
        </Tooltip>
      )} */}
        </>
      )}
    </div>
  );
};

export default ListItem;
