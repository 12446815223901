import { Checkbox, Col, Input, Modal, Radio, Row, Select, Slider } from "antd";
import { ISelectValue } from "shared/types/inputValues";
import { useState, useEffect, FC } from "react";
import {
  ExportDestination,
  ExportType,
  ExportImageType,
  ExportVideoType,
  IWorkfrontProofData,
  ICurrentProofVersion,
} from "shared/types/assetBuilder";
import "./ReviewPage.scss";
import { isPharma } from "utils/helpers";

interface IReviewExportModalProps {
  enableWorkfront: boolean;
  showExportDrawer: boolean;
  templateID: string;
  folderID: string;
  subject: string;
  proofMessage: string;
  imageOption?: ExportImageType;
  proofHQTemplates: Array<{ templateName: string; templateID: string }>;
  wfFolderList: Array<{ name: string; id: string }>;
  includeAssets: boolean;
  includeCSV: boolean;
  includePDF: boolean;
  selectedAssetBool: boolean;
  proofUploadData: string | undefined;
  containsVideo: boolean;
  proofName: string;
  wfProofArr?: IWorkfrontProofData[];
}

interface IReviewExportModalHandlers {
  toggleExportDrawer: (toggle: boolean) => void;
  setTemplateID: (value: string) => void;
  setTemplateName: (value: string) => void;
  setFolderID: (value: string) => void;
  setFolderName: (value: string) => void;
  setSubject: (value: string) => void;
  setMessage: (value: string) => void;
  feedDataToCSV: () => void;
  handleExport: (
    destination: ExportDestination,
    exportOption?: "all" | "selected" | undefined,
    imageOption?: ExportImageType,
    videoOption?: ExportVideoType,
    quality?: number,
  ) => Promise<void>;
  setImageOption: (value?: ExportImageType) => void;
  setUseVersioning: (value: boolean) => void;
  setIncludeAssets: (value: boolean) => void;
  setIncludeCSV: (value: boolean) => void;
  setIncludePDF: (value: boolean) => void;
  setProofName: (value: string) => void;
  exportAsHtml: () => void;
  feedDataToPDF: () => void;
  setSelectedProofToUpdate: (selectedProof: string) => void;
}

interface IRadioButtonSelection {
  pdf?: ExportType;
  zip?: ExportType;
  workfront?: ExportType;
  csv?: ExportType;
  coop?: ExportType;
}

const ReviewExportModal: FC<
  IReviewExportModalProps & IReviewExportModalHandlers
> = ({
  enableWorkfront,
  showExportDrawer,
  toggleExportDrawer,
  handleExport,
  imageOption,
  setImageOption,
  proofHQTemplates,
  setTemplateID,
  setTemplateName,
  setProofName,
  subject,
  setSubject,
  proofMessage,
  setMessage,
  feedDataToCSV,
  wfFolderList,
  setFolderID,
  setFolderName,
  setUseVersioning,
  includeAssets,
  includeCSV,
  includePDF,
  setIncludeAssets,
  setIncludePDF,
  setIncludeCSV,
  proofUploadData,
  containsVideo,
  selectedAssetBool,
  wfProofArr,
  setSelectedProofToUpdate,
  ...props
}) => {
  const [exportBool, setExportBool] = useState<boolean>(false);
  const [pdfBool, setPDFBool] = useState<boolean>(false);
  const [offerPDFBool, setOfferPDFBool] = useState<boolean>(false);
  const [htmlBool, setHtmlBool] = useState<boolean>(false);
  const [videoOption, setVideoOption] = useState<ExportVideoType>("mp4");
  const [jpgQuality, setJPGQuality] = useState<number>(0.95);
  const [uploadBool, setUploadBool] = useState<boolean>(false);
  const [wfImageOption, setWFImageOption] = useState<ExportImageType>("jpeg");
  const [wfVideoOption, setWFVideoOption] = useState<ExportVideoType>("mp4");
  const [wfJpgQuality, setWFJPGQuality] = useState<number>(0.95);
  const [folderBool, setFolderBool] = useState<boolean>(false);
  const [exportCSVBool, setExportCSVBool] = useState<boolean>(false);
  const [enableTemplateAndFolder, setEnableTemplateAndFolder] =
    useState<boolean>(false);
  const [teamUrl, setTeamUrl] = useState<string>("");
  const [docID, setDocID] = useState<string>("");
  const [proofVersioningArr, setProofVersioningArr] = useState<
    Array<{ version: number; proofData: ICurrentProofVersion; teamUrl: string }>
  >([]);
  const [initialCheckboxChecked, setInitialCheckboxCheck] =
    useState<boolean>(false);
  const [radioButtonSelection, setRadioButtonSelection] =
    useState<IRadioButtonSelection>({
      pdf: "all",
      zip: "all",
      workfront: "all",
      csv: "all",
    });

  const formatDate = (date: string) => {
    return new Date(parseInt(date, 10)).toLocaleDateString("en-US");
  };

  useEffect(() => {
    if (wfProofArr) {
      const initialBlankProof: {
        version: number;
        proofData: ICurrentProofVersion;
        teamUrl: string;
      } = {
        version: 0,
        proofData: {
          createdAt: "",
          documentID: "",
          folderID: "",
          id: "",
          parentFileToken: "",
          stageAndRecipientString: "",
          templateID: "",
          proofName: "",
        },
        teamUrl: "",
      };
      const versions = [initialBlankProof];
      for (const index of wfProofArr) {
        const versionJSON = JSON.parse(index.versions);
        const keys = Object.keys(versionJSON);
        for (const key of keys) {
          versions.push({
            version: versionJSON[key].length,
            proofData: versionJSON[key][versionJSON[key].length - 1],
            teamUrl: index.teamUrl || "",
          });
        }
      }
      setProofVersioningArr(versions);
    }
  }, [wfProofArr]);

  useEffect(() => {
    if (!showExportDrawer) {
      setSelectedProofToUpdate("{}");
      setInitialCheckboxCheck(false);
      setUseVersioning(false);
    }
  }, [
    showExportDrawer,
    initialCheckboxChecked,
    setSelectedProofToUpdate,
    setUseVersioning,
  ]);

  // documentID is used for the logic for the proof url. either add to the obj or add a new key in the order obj for the results array
  useEffect(() => {
    setRadioButtonSelection({
      ["pdf"]: selectedAssetBool ? "selected" : "all",
      ["zip"]: selectedAssetBool ? "selected" : "all",
      ["workfront"]: selectedAssetBool ? "selected" : "all",
      ["csv"]: selectedAssetBool ? "selected" : "all",
    });
  }, [selectedAssetBool]);

  useEffect(() => {
    const proofDataJSON = JSON.parse(proofUploadData ? proofUploadData : "{}");
    const { templateID, folderID } = proofDataJSON;
    if (wfFolderList.length && !folderID) {
      const { id, name } = wfFolderList?.[0] || {};
      id && setFolderID(id);
      name && setFolderName(name);
    }
    if (!templateID || !folderID || !proofHQTemplates.length) {
      return;
    }
    const selectedID = proofHQTemplates.filter(
      index => index.templateID === templateID,
    );
    if (selectedID.length) {
      const { templateName, templateID: selectedTempID } =
        selectedID?.[0] || {};
      if (selectedTempID === templateID && templateName) {
        setTemplateName(templateName);
        setTemplateID(templateID);
        setFolderBool(true);
      }
    }

    const selectedFolder = wfFolderList.filter(index => index.id === folderID);
    if (selectedFolder.length) {
      const { name, id } = selectedFolder?.[0] || {};
      if (id === folderID && name) {
        setFolderName(name);
        setFolderID(id);
      }
    }
  }, [
    wfFolderList,
    proofUploadData,
    proofHQTemplates,
    setFolderID,
    setFolderName,
    setTemplateName,
    setTemplateID,
  ]);

  const jpgQualityMarks = {
    0.8: "0.8",
    0.95: "0.95",
    1: "1",
  };

  const returnRadioGroup = (
    exportDestination: ExportDestination,
    disableCondition: boolean,
  ) => {
    return (
      <Radio.Group
        onChange={({ target }) => {
          setRadioButtonSelection({
            ...radioButtonSelection,
            [exportDestination]: target.value || "all",
          });
        }}
        disabled={disableCondition}
        value={
          radioButtonSelection[exportDestination] === "all" ? "all" : "selected"
        }
      >
        <Radio
          checked={radioButtonSelection[exportDestination] === "all"}
          value={"all"}
        >
          Export All
        </Radio>
        <br />
        <Radio
          checked={radioButtonSelection[exportDestination] === "selected"}
          value={"selected"}
        >
          Export Selected
        </Radio>
        <br />
      </Radio.Group>
    );
  };

  const modalContent = (
    <div className="review-export-modal">
      <Row gutter={24}>
        {/* this is the left side --export as pdf */}
        <Col span={11}>
          <b>
            <Checkbox
              onChange={() => {
                setPDFBool(!pdfBool);
              }}
            >
              Download as PDF
            </Checkbox>
          </b>
          <div className="line" />
          <br />
          {returnRadioGroup("pdf", !pdfBool)}
        </Col>
        {/* this is the right side  --export as zip. will do first*/}
        <Col span={11}>
          {/* possible TO DO: programmatically generate checkbox headers*/}
          <b>
            <Checkbox
              onChange={() => {
                setExportBool(!exportBool);
              }}
            >
              Download as Zip
            </Checkbox>
          </b>
          <div className="line" />
          <br />
          <div>
            {returnRadioGroup("zip", !exportBool)}
            {exportBool && (
              <div>
                <b>Image Option</b>
                <div className="line" />
                <br />
                {containsVideo ? (
                  <div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Checkbox
                        checked={imageOption === "png"}
                        onChange={() => {
                          imageOption === "png"
                            ? setImageOption(undefined)
                            : setImageOption("png");
                        }}
                      >
                        PNG
                      </Checkbox>
                      <Checkbox
                        checked={imageOption === "jpeg"}
                        style={{ margin: 0 }}
                        onChange={() => {
                          imageOption === "jpeg"
                            ? setImageOption(undefined)
                            : setImageOption("jpeg");
                        }}
                      >
                        JPG
                      </Checkbox>
                    </div>
                    {imageOption === "jpeg" && (
                      <Slider
                        min={0.8}
                        max={1}
                        step={0.05}
                        marks={jpgQualityMarks}
                        onChange={(v: number) => setJPGQuality(v)}
                        value={jpgQuality}
                      />
                    )}
                    <div>
                      <b>Video Option</b>
                      <div className="line" />
                      <br />
                      <Radio.Group
                        onChange={({ target }) => {
                          setVideoOption(target.value.toLowerCase());
                        }}
                        disabled={!exportBool}
                        value={videoOption.toUpperCase()}
                      >
                        <Radio checked={videoOption === "mp4"} value={"MP4"}>
                          MP4
                        </Radio>
                        <br />
                        <Radio checked={videoOption === "gif"} value={"GIF"}>
                          GIF
                        </Radio>
                      </Radio.Group>
                    </div>
                  </div>
                ) : (
                  <div>
                    <Radio.Group
                      onChange={({ target }) => {
                        if (target.value === "JPG") setImageOption("jpeg");
                        else setImageOption("png");
                      }}
                      disabled={!exportBool}
                      value={imageOption === "jpeg" ? "JPG" : "PNG"}
                    >
                      <Radio checked={imageOption === "png"} value={"PNG"}>
                        PNG
                      </Radio>
                      <br />
                      <Radio checked={imageOption === "jpeg"} value={"JPG"}>
                        JPG
                      </Radio>
                    </Radio.Group>
                    {imageOption !== "png" && (
                      <Slider
                        min={0.8}
                        max={1}
                        step={0.05}
                        marks={jpgQualityMarks}
                        onChange={(v: number) => setJPGQuality(v)}
                        value={jpgQuality}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </Col>
      </Row>
      <br />
      {!isPharma && (
        <>
          <Row gutter={24}>
            {/*--send to WF */}
            <Col span={23}>
              <b>
                <Checkbox
                  onChange={() => {
                    setOfferPDFBool(!offerPDFBool);
                  }}
                >
                  Download PDF of Offer Data
                </Checkbox>
              </b>
            </Col>
          </Row>
          <br />
        </>
      )}
      <Row gutter={24}>
        {/*--send to WF */}
        <Col span={23}>
          <b>
            <Checkbox
              onChange={() => {
                setExportCSVBool(!exportCSVBool);
              }}
            >
              Download CSV of Feed Data
            </Checkbox>
          </b>
        </Col>
      </Row>
      <br />
      <Row gutter={24}>
        {/*--send to WF */}
        <Col span={23}>
          <b>
            <Checkbox
              onChange={() => {
                setHtmlBool(!htmlBool);
              }}
            >
              Download as HTML
            </Checkbox>
          </b>
        </Col>
      </Row>
      <br />
      {enableWorkfront && (
        <Row gutter={24}>
          {/*--send to WF */}
          <Col span={23}>
            <b>
              <Checkbox
                onChange={() => {
                  setUploadBool(!uploadBool);
                  setFolderBool(true);
                }}
              >
                Send To Workfront
              </Checkbox>
            </b>
            <div className="line" />
            <div>
              <br />
              <Checkbox
                disabled={!uploadBool}
                defaultChecked={includePDF}
                onChange={() => {
                  setIncludePDF(!includePDF);
                }}
              >
                Include PDF
              </Checkbox>
              <br />
              <Checkbox
                disabled={!uploadBool}
                defaultChecked={includeCSV}
                onChange={() => {
                  setIncludeCSV(!includeCSV);
                }}
              >
                Include CSV of Feed Data
              </Checkbox>
              <br />
              <Checkbox
                disabled={!uploadBool}
                defaultChecked={includeAssets}
                onChange={() => {
                  setIncludeAssets(!includeAssets);
                }}
              >
                Include Asset Images
              </Checkbox>

              <br />
              {returnRadioGroup("workfront", !uploadBool)}
              {uploadBool && includeAssets && (
                <div>
                  <b>Image Option</b>
                  <div className="line" />
                  <br />
                  <Radio.Group
                    onChange={({ target }) => {
                      if (target.value === "JPG") setWFImageOption("jpeg");
                      else setWFImageOption("png");
                    }}
                    disabled={!uploadBool}
                    value={wfImageOption === "jpeg" ? "JPG" : "PNG"}
                  >
                    <Radio checked={wfImageOption === "png"} value={"PNG"}>
                      PNG
                    </Radio>
                    <br />
                    <Radio checked={wfImageOption === "jpeg"} value={"JPG"}>
                      JPG
                    </Radio>
                  </Radio.Group>
                  {wfImageOption !== "png" && (
                    <Slider
                      min={0.8}
                      max={1}
                      step={0.05}
                      marks={jpgQualityMarks}
                      onChange={(v: number) => setWFJPGQuality(v)}
                      value={wfJpgQuality}
                    />
                  )}
                  {containsVideo && (
                    <div>
                      <b>Video Option</b>
                      <div className="line" />
                      <br />
                      <Radio.Group
                        onChange={({ target }) => {
                          setWFVideoOption(target.value.toLowerCase());
                        }}
                        disabled={!uploadBool}
                        value={wfVideoOption.toUpperCase()}
                      >
                        <Radio checked={wfVideoOption === "mp4"} value={"MP4"}>
                          MP4
                        </Radio>
                        <br />
                        <Radio checked={wfVideoOption === "gif"} value={"GIF"}>
                          GIF
                        </Radio>
                      </Radio.Group>
                    </div>
                  )}
                </div>
              )}
            </div>
            <br />
            <div>Proof Selection</div>
            <Select
              labelInValue
              showSearch={true}
              style={{ width: 400 }}
              placeholder="Proofs"
              onChange={(proof: ISelectValue) => {
                if (proof.label === "Create New Proof") {
                  setEnableTemplateAndFolder(true);
                  setUseVersioning(false);
                  return;
                }
                setUseVersioning(true);
                setEnableTemplateAndFolder(false);
                const selectedProofOption = JSON.parse(proof.value);
                setTeamUrl(JSON.parse(selectedProofOption.teamUrl).teamUrl);
                setDocID(JSON.parse(selectedProofOption.teamUrl).docID);
                setSelectedProofToUpdate(
                  JSON.stringify(selectedProofOption.proofData),
                );
                const splitProofName = proof.label.split("|");
                setProofName(
                  splitProofName[2] && splitProofName[3]
                    ? splitProofName[2] + " | " + splitProofName[3]
                    : "",
                );
              }}
            >
              {proofVersioningArr?.map(index => (
                <Select.Option
                  key={`proof-select${index.version}${
                    index.proofData?.createdAt || Date.now()
                  }`}
                  value={JSON.stringify({
                    proofData: index.proofData,
                    teamUrl: index.teamUrl,
                  })}
                >
                  {`${
                    index.version === 0
                      ? "Create New Proof"
                      : `V${index.version} | ${formatDate(
                          index.proofData.createdAt,
                        )} | ${index?.proofData?.proofName ?? ""}`
                  }`}
                </Select.Option>
              ))}
            </Select>
            <br />
            <br />
            <div>Select a Workflow Template</div>
            <Select
              showSearch={true}
              style={{ width: 400 }}
              placeholder="Templates"
              disabled={!uploadBool || !enableTemplateAndFolder}
              onChange={(value: string) => {
                const selectedID = proofHQTemplates.filter(
                  index => index.templateName === value,
                );
                if (selectedID.length) {
                  const { templateName, templateID } = selectedID?.[0] || {};
                  const splitProofName = props.proofName.split("|") || [
                    templateName,
                  ];

                  const currProofName =
                    splitProofName.length > 1
                      ? `${templateName}${" | "}${splitProofName[1]}`
                      : templateName;
                  setProofName(currProofName);
                  if (templateName === value && templateID) {
                    setTemplateName(templateName);
                    setTemplateID(templateID);
                    setFolderBool(true);
                  }
                }
                // Note might need to later on add logic to uncheck/select the versioning option
                // if another template is chosen
              }}
            >
              {proofHQTemplates?.map(
                (index: { templateName: string; templateID: string }) => (
                  <Select.Option
                    key={`state-select-option-${index.templateName}`}
                    value={index.templateName}
                  >
                    {index.templateName}
                  </Select.Option>
                ),
              )}
            </Select>

            <br />
            <br />
            <div>Select a Folder</div>
            <Select
              showSearch={true}
              style={{ width: 400 }}
              placeholder="Available Folders"
              disabled={!uploadBool || !folderBool || !enableTemplateAndFolder}
              onChange={(value: string) => {
                const selectedFolder = wfFolderList.filter(
                  index => index.name === value,
                );
                if (selectedFolder.length) {
                  const { name, id } = selectedFolder?.[0] || {};
                  if (name === value && id) {
                    const currProofName = `${props.proofName}${" | "}${name}`;

                    setProofName(currProofName);
                    setFolderName(name);
                    setFolderID(id);
                  }
                }
                // Note might need to later on add logic to uncheck/select the versioning option
                // if another folder is chosen
              }}
            >
              {wfFolderList?.map((index: { name: string; id: string }) => (
                <Select.Option
                  key={`state-select-option-${index.name}`}
                  value={index.name}
                >
                  {index.name}
                </Select.Option>
              ))}
            </Select>
            <br />
            <br />
            <div>Attach a message to the proof (optional)</div>
            <div>Subject</div>
            <Input
              disabled={!uploadBool}
              value={subject}
              onChange={event => {
                const { target } = event;
                const { value } = target;
                setSubject(value);
              }}
            />
            <div>Message</div>
            <div className="review-message-box" style={{ width: "100%" }}>
              <textarea
                disabled={!uploadBool}
                rows={10}
                cols={10}
                style={{
                  width: "100%",
                  height: "10em",
                  whiteSpace: "pre-wrap",
                }}
                value={proofMessage}
                onChange={event => {
                  const { target } = event;
                  const { value } = target;
                  setMessage(value);
                }}
              />
            </div>

            <br />
            {docID && teamUrl && !enableTemplateAndFolder && (
              <div>
                <div>Document URL:</div>
                <a
                  href={`${process.env?.REACT_APP_WORKFRONT_DOMAIN}/document/${docID}/`}
                  rel="noopener noreferrer"
                  target="_blank"
                >{`${process.env?.REACT_APP_WORKFRONT_DOMAIN}/document/${docID}/`}</a>
                <br />
                <br />
                <div>Proof URL:</div>
                <a href={teamUrl} rel="noopener noreferrer" target="_blank">
                  {teamUrl}
                </a>
              </div>
            )}
          </Col>
        </Row>
      )}
    </div>
  );

  return (
    <>
      <Modal
        title="Export"
        visible={showExportDrawer}
        okButtonProps={{
          disabled:
            (!pdfBool &&
              !exportBool &&
              !uploadBool &&
              !exportCSVBool &&
              !htmlBool &&
              !offerPDFBool) ||
            Object.values(radioButtonSelection).length < 1,
        }}
        onOk={() => {
          if (exportBool) {
            // Eventual TO DO: handle PDF exporting
            handleExport(
              "zip",
              radioButtonSelection.zip,
              imageOption,
              videoOption,
              jpgQuality,
            );
          }
          if (pdfBool) {
            handleExport(
              "pdf",
              radioButtonSelection.pdf,
              "jpeg",
              undefined,
              0.8,
            );
          }
          if (offerPDFBool) {
            props.feedDataToPDF();
          }
          if (uploadBool) {
            handleExport(
              "workfront",
              radioButtonSelection.workfront,
              wfImageOption,
              wfVideoOption,
              wfJpgQuality,
            );
          }
          if (exportCSVBool) {
            feedDataToCSV();
          }

          if (htmlBool) {
            // export to html
            props.exportAsHtml();
          }
          toggleExportDrawer(false);
        }}
        onCancel={() => {
          toggleExportDrawer(false);
        }}
      >
        <div>{modalContent}</div>
      </Modal>
    </>
  );
};

export default ReviewExportModal;
