import { useMemo } from "react";
import { FormInstance } from "antd/es/form/Form";
import { useGetPmaxURLExclusions } from "screens/adLibrary/shared/hooks/useGetPmaxURLExclusions";
import { useGoogleGeoTargets } from "screens/adLibrary/shared/hooks/useGoogleGeoTargets";
import { GoogleAdCampaignInternalForm } from "shared/types/adLibrary";
import { usePmaxCampaignsContext } from "screens/campaignManagement/googlePerformanceMax/PmaxCampaignsProvider";
import {
  calcDailyBudget,
  getDateToUse,
  getAccountsWithFullAddress,
} from "./helpers";
import { googleAdCampaignFormSchema } from "shared/schemas/pmax";
import { IAccount } from "shared/types/accountManagement";
import { useSchemaValidation } from "utils/helpers.zod";

export const usePmaxCampaignForm = (
  form: FormInstance<GoogleAdCampaignInternalForm>,
  accounts: IAccount[],
) => {
  const { data: geoTargetData, isLoading: isLoadingGeoTargets } =
    useGoogleGeoTargets();
  const { data: urlExclusionsData, isLoading: isLoadingExclusions } =
    useGetPmaxURLExclusions();

  const accountsWithAddress = getAccountsWithFullAddress(accounts);
  const schemaRules = useSchemaValidation(
    googleAdCampaignFormSchema(accountsWithAddress),
  );

  const geoTargetOptions =
    geoTargetData?.result?.map(option => ({
      value: option.resourceName,
      label: option.name,
    })) || [];

  const rulesetOptions = useMemo(() => {
    const newRulesetOption = {
      value: "newRuleset",
      label: "Set up a new ruleset",
    };

    const mappedOptions =
      urlExclusionsData?.result?.map(urlExclusion => ({
        value: urlExclusion.name,
        label: urlExclusion.name,
      })) || [];

    return [newRulesetOption, ...mappedOptions];
  }, [urlExclusionsData]);

  const { campaignDrawer, setCampaignDrawer } = usePmaxCampaignsContext();

  const newRulesetPreset = [""];
  const getUrlExclusionsFromPreset = (presetName: string) =>
    urlExclusionsData?.result?.find(
      urlExclusion => urlExclusion.name === presetName,
    )?.exclusions || newRulesetPreset;

  const handleValuesChange = (
    changedValues: Partial<GoogleAdCampaignInternalForm>,
    values: GoogleAdCampaignInternalForm,
  ) => {
    const dailyBudget = changedValues.totalBudget
      ? calcDailyBudget(
          parseInt(changedValues.totalBudget),
          getDateToUse(values.startDate),
          getDateToUse(values.endDate),
        )
      : values.dailyBudget;

    const urlExclusions = changedValues.rulesetName
      ? getUrlExclusionsFromPreset(changedValues.rulesetName)
      : values.urlExclusions;

    const updatedFormData = {
      ...values,
      dailyBudget,
      urlExclusions,
    };

    form.setFieldsValue(updatedFormData);
    setCampaignDrawer({
      data: {
        ...campaignDrawer.data,
        ...updatedFormData,
      },
    });
  };

  return {
    accountsWithAddress,
    schemaRules,
    isLoadingExclusions,
    isLoadingGeoTargets,
    geoTargetOptions,
    rulesetOptions,
    handleValuesChange,
  };
};
