export const sendDataToLookerIframe = (
  iframe: HTMLIFrameElement | null,
  messageObject: Record<string, unknown>,
  targetOrigin = "https://constellationagency.looker.com/",
) => {
  if (iframe && iframe.contentWindow) {
    iframe.contentWindow.postMessage(
      JSON.stringify(messageObject),
      targetOrigin,
    );
    iframe.contentWindow.postMessage(
      JSON.stringify({
        type: "dashboard:run",
      }),
      targetOrigin,
    );
  }
};
