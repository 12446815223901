import { Image, Typography } from "antd";
import ErrorImage from "statics/images/missing-thumbnail.png";

const { Link, Text } = Typography;

type Props = {
  name: string;
  image?: string | null;
  onClick?: () => void;
};

export const ThumbnailCell = ({ name, image, onClick }: Props) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      gap: 8,
    }}
  >
    <Image
      src={image ?? ErrorImage}
      fallback={ErrorImage}
      height={30}
      width={30}
      onClick={onClick}
      preview={false}
      style={{ cursor: "pointer", minWidth: 30 }}
    />
    {onClick ? (
      <Link onClick={onClick} ellipsis>
        {name}
      </Link>
    ) : (
      <Text ellipsis>{name}</Text>
    )}
  </div>
);
