import { useQuery } from "react-query";
import API from "services";
import { FeedConfig } from "shared/types/assetExporter";
import { getErrorMessage } from "utils/errorMessage";

const getCurrConfig = async (feedId: string) => {
  try {
    const res = await API.services.assetExporter.getFeedConfig(feedId);
    const { result, error } = res;
    if (!result) throw new Error("Unable to get feed configuration");
    if (!!error) throw new Error(error.message);
    return result;
  } catch (error) {
    const errMsg = getErrorMessage(error);
    throw new Error(errMsg);
  }
};
const useFetchCurrConfig = (feedId: string, enabled: boolean) => {
  return useQuery<FeedConfig, Error>(
    ["feedConfig", feedId],
    () => getCurrConfig(feedId),
    {
      enabled,
    },
  );
};

export default useFetchCurrConfig;
