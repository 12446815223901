import { Form, Drawer, Button, Typography } from "antd";
import { NavLink } from "react-router-dom";
import { updateDealer } from "redux/dealerManagement/dealerManagement.slice";
import { IFacebookAccount } from "screens/adLibrary/facebookUtils/types";
import { FacebookAccountDetails } from "screens/adLibrary/shared/FacebookAccountDetails";
import { successNotification } from "shared/components/customNotification/Notification";
import { IAccountRecord } from "shared/types/accountManagement";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { useAppSelector } from "shared/hooks/useAppSelector";
import style from "./ChangeFBAccountIDDrawer.module.scss";

interface Props {
  storeName: string;
  handleClose: () => void;
  dealerToEdit: IAccountRecord;
  setDealerToEdit: (dealerToEdit: IAccountRecord) => void;
  handleSuccess: () => void;
}

const ChangeFBAccountIDDrawer = ({
  handleClose,
  storeName,
  dealerToEdit,
  setDealerToEdit,
  handleSuccess,
}: Props) => {
  const dispatch = useAppDispatch();

  const selectedFacebookAccounts: IFacebookAccount[] =
    useAppSelector(state => state.adLibrary.selectedFacebookAccounts) || [];

  const handleSave = () => {
    handleSuccess();
    dispatch(updateDealer(dealerToEdit));

    successNotification({
      messageLabel: "FB Account information updated succesfully",
    });
  };

  return (
    <Drawer
      title={`${storeName} - Edit Facebook Account`}
      visible
      onClose={handleClose}
      width={928}
      closable
      footer={
        <div className={style.container}>
          <Button className={style.button} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => handleSave()}
            className={style.button}
          >
            Save
          </Button>
        </div>
      }
    >
      <div>
        <Form layout="vertical" requiredMark={false}>
          <Typography className={style.message}>
            Please set up FB Account information below. You can change them
            later in
            <NavLink to={"/store-management"} className={style.adminLink}>
              Admin / Store Management.
            </NavLink>
          </Typography>
          <FacebookAccountDetails
            dealerToEdit={dealerToEdit}
            setDealerToEdit={setDealerToEdit}
            selectedFacebookAccounts={selectedFacebookAccounts}
          />
        </Form>
      </div>
    </Drawer>
  );
};

export default ChangeFBAccountIDDrawer;
