import { TablePaginationConfig } from "antd";
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
} from "antd/lib/table/interface";
import { useState } from "react";
import CardListContainer from "shared/components/CardListContainer";
import { useDataList } from "shared/components/dataList";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { BrandLogo } from "shared/types/brandsAccounts";
import VirtualTable from "../VirtualTable";
import ImagePreview from "../imagePreviewOverlay/ImagePreview";
import { LogoCard } from "./LogoCard";
import { useLogoSelectContext } from "./context/LogoSelectContext";
import { useLogoSelectTableColumns } from "./hooks/useLogoSelectTableColumns";

export const LogoSelectTable = () => {
  const { layout } = useLogoSelectContext();
  const [previewUrl, setPreviewUrl] = useState<string>();
  const { tableColumns } = useLogoSelectTableColumns({ setPreviewUrl });
  const [dataList, dataListActions] = useDataList<BrandLogo>();
  const { windowInnerHeight } = useWindowSize();
  const allAvailableHeight = windowInnerHeight - 250;

  const onChange = (
    _: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<BrandLogo> | SorterResult<BrandLogo>[],
    extra: TableCurrentDataSource<BrandLogo>,
  ) => {
    if (extra.action == "sort" && !Array.isArray(sorter)) {
      if (!sorter.column) {
        dataListActions.sortItems(undefined, undefined);
        return;
      }
      dataListActions.sortItems(sorter.columnKey, sorter.order);
    }
  };

  return (
    <>
      {layout === "table" ? (
        <>
          <VirtualTable<BrandLogo>
            data-cy="logo-select-table"
            scroll={{ y: allAvailableHeight - 25 }}
            rowKey={record => record.id}
            pagination={false}
            rowSelection={{
              type: "radio",
              selectedRowKeys: dataList.selectedIds,
              onSelect: record => dataListActions.setSelectedItems([record.id]),
            }}
            columns={tableColumns}
            dataSource={dataList.rows}
            bordered={true}
            onChange={onChange}
            size="small"
          />
          {previewUrl && (
            <ImagePreview
              url={previewUrl}
              onClose={() => setPreviewUrl(undefined)}
            />
          )}
        </>
      ) : (
        <CardListContainer
          virtualized
          data-cy="logo-select-cards"
          height={allAvailableHeight}
          center={false}
          cards={dataList.rows.map(logo => (
            <LogoCard key={logo.id} logo={logo} />
          ))}
        />
      )}
    </>
  );
};
