import {
  Col,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  DatePicker,
  message,
  Button,
} from "antd";
import { useCallback, useState, useEffect, FC } from "react";
import { INewOrder } from "shared/types/newOrders";
import {
  ExportImageType,
  ICoopIntegrationData,
  ExportDestination,
  ExportType,
  coopBrand,
  ExportVideoType,
} from "shared/types/assetBuilder";
import { defaultDealerData } from "utils/helpers";
import "./ReviewPage.scss";
import { IApiResponse } from "shared/types/shared";
import { IAccount, IGetAccount } from "shared/types/accountManagement";
import { IConfigurationState } from "shared/types/configuration";
import {
  subaruInternetSubCategories,
  subaruSDCInternetSubCategories,
  toyotaInternetSubCategories,
  nissanInternetSubCategories,
  fordInternetSubCategories,
  jlrInternetSubCategories,
} from "./ReviewConstants";
import HttpClient from "../../../../services/httpClient";
import { coopInstructions } from "../../../../shared/constants/assetBuilder";
const { RangePicker } = DatePicker;
interface ICoopSubmissionModalProps {
  savedOrder: INewOrder;
  showCoopModal: boolean;
  currentDealer: IAccount;
  coopToSubmit: ICoopIntegrationData;
  selectedAssetBool: boolean;
  setCoopToSubmit: (coopToSubmit: ICoopIntegrationData) => void;
  config?: IConfigurationState["config"];
  coopSupported: boolean;
}
interface ICoopSubmissionModalHandlers {
  toggleCoopModal: (toggle: boolean) => void;
  setCurrentDealer: (currentDealer: IAccount) => void;
  handleExport: (
    destination: ExportDestination,
    exportOption?: "all" | "selected" | undefined,
    imageOption?: ExportImageType,
    videoOption?: ExportVideoType,
    quality?: number,
  ) => Promise<void>;
}
interface IRadioButtonSelection {
  coop?: ExportType;
}

const CoopSubmissionModal: FC<
  ICoopSubmissionModalProps & ICoopSubmissionModalHandlers
> = ({
  savedOrder,
  showCoopModal,
  toggleCoopModal,
  setCurrentDealer,
  handleExport,
  selectedAssetBool,
  config,
  ...props
}) => {
  message.config({ maxCount: 1 });
  const [currentDealerName, setCurrentDealerName] = useState<string>("");
  const [coopDealer, setCoopDealer] = useState<IAccount>({
    ...defaultDealerData(),
  });
  const [radioButtonSelection, setRadioButtonSelection] =
    useState<IRadioButtonSelection>({
      coop: "all",
    });
  const [coopButtonBool, setCoopButtonBool] = useState<boolean>(false);
  const [coopSubCategories, setCoopSubCategories] = useState<
    Array<{ category: string; code: string }>
  >([]);
  const [coopStoreSite, setCoopStoreSite] = useState<string>("");

  const setDealerData = useCallback(
    async (dealerName: string) => {
      try {
        const { services } = config || {};
        const dealerUrl = `${
          services?.getDealerUrl
        }?dealerName=${encodeURIComponent(dealerName || "")}`;
        const dealerRequestRes = await HttpClient.get<
          IApiResponse<IGetAccount>
        >(dealerUrl);
        const dealer = dealerRequestRes?.result?.dealer;
        if (dealer && dealer.coopDetails) {
          setCoopDealer(dealer);
          setCoopStoreSite(dealer.coopDetails.coopSite);
        }
      } catch (err) {
        message.warning("Dealer data could not be currently retrieved");
      }
    },
    [config],
  );

  useEffect(() => {
    if (savedOrder?.dealer_name) {
      setCurrentDealerName(savedOrder.dealer_name);
    }
  }, [savedOrder]);

  useEffect(() => {
    if (showCoopModal) {
      setDealerData(currentDealerName);
    }
  }, [currentDealerName, setDealerData, showCoopModal]);

  useEffect(() => {
    if (coopDealer.dealer_url) {
      setCurrentDealer(coopDealer);
    }
  }, [coopDealer, setCurrentDealer]);

  useEffect(() => {
    if (props.currentDealer?.coopDetails?.coopUsername) {
      const dealer = props.currentDealer;
      const instructions = coopInstructions || [];
      if (dealer?.dealer_oem === "Subaru") {
        const subaruComplianceSite = instructions.find(account => {
          if (
            account.oem === "Subaru" &&
            dealer.coopDetails?.coopSite.includes(account.compliance_site)
          ) {
            return dealer.dealer_oem.includes(account.oem);
          }
        })?.compliance_site;
        const subaruDoms = ["subarunet.com", "subaruadfund.com", "sdccoop.com"];
        const notValidDom = !subaruDoms.some(dom =>
          subaruComplianceSite?.includes(dom),
        );
        if (notValidDom) {
          setCoopButtonBool(true);
          message.warning(
            <div>
              <p>
                {
                  "This Account's Compliance Site information is incorrect. Please enter the coop site again: "
                }
                <a href={`${window.location.origin}/store-management`}>
                  Store Management
                </a>
              </p>
            </div>,
          );
        }
      }
      if (
        !dealer.coopDetails?.coopUsername ||
        !dealer.coopDetails?.coopSite ||
        dealer.coopDetails?.coopLoginLocked
      ) {
        setCoopButtonBool(true);
        message.warning(
          <div>
            <p>
              {dealer.coopDetails?.coopLoginLocked
                ? "This Account has previously failed a submission and has been temporarily locked in Store Management. Please enter the coop credentials again to unlock the store for future submissions"
                : "This Account is missing the Coop Compliance details. Please go to Store Management to fill in this info:"}
              <a href={`${window.location.origin}/store-management`}>
                Store Management
              </a>
            </p>
          </div>,
        );
      }
    }
  }, [props.currentDealer]);

  useEffect(() => {
    const brand = savedOrder.dealer_oem as coopBrand;
    switch (brand) {
      case "Toyota":
        setCoopSubCategories(toyotaInternetSubCategories);
        break;
      case "Nissan":
        setCoopSubCategories(nissanInternetSubCategories);
        break;
      case "Jaguar":
      case "Land Rover":
        setCoopSubCategories(jlrInternetSubCategories);
        break;
      case "Subaru":
        if (coopStoreSite.includes("sdccoop")) {
          setCoopSubCategories(subaruSDCInternetSubCategories);
          break;
        }
        setCoopSubCategories(subaruInternetSubCategories);
        break;
      case "Ford":
        setCoopSubCategories(fordInternetSubCategories);
        break;
      case null:
      default:
        break;
    }
  }, [coopStoreSite, savedOrder.dealer_oem]);

  useEffect(() => {
    setRadioButtonSelection({
      coop: selectedAssetBool ? "selected" : "all",
    });
  }, [selectedAssetBool]);

  const returnRadioGroup = () => {
    return (
      <Radio.Group
        onChange={({ target }) => {
          setRadioButtonSelection({
            coop: target.value || "all",
          });
        }}
        value={radioButtonSelection?.coop === "all" ? "all" : "selected"}
      >
        <Radio checked={radioButtonSelection?.coop === "all"} value={"all"}>
          All
        </Radio>
        <br />
        <Radio
          checked={radioButtonSelection?.coop === "selected"}
          value={"selected"}
        >
          Selected
        </Radio>
        <br />
      </Radio.Group>
    );
  };

  const genericModalContent = (
    <div className="review-export-modal">
      <Row gutter={24}>
        <Col span={23}>
          {
            <div>
              <p>Which assets should be included in the submitted PDF?</p>
              {returnRadioGroup()}
            </div>
          }
        </Col>
      </Row>
      <br />
      <Row gutter={24}>
        <Col span={23}>{<div>Brand: {`${savedOrder.dealer_oem}`}</div>}</Col>{" "}
        {/*insert oem from redux here */}
      </Row>
    </div>
  );

  const modalContent = (
    <div className="review-export-modal">
      <Row gutter={24}>
        <Col span={23}>
          {
            <div>
              <p>Which assets should be included in the submitted PDF?</p>
              {returnRadioGroup()}
            </div>
          }
        </Col>
      </Row>
      <br />
      <Row gutter={24}>
        <Col span={23}>{<div>Brand: {`${savedOrder.dealer_oem}`}</div>}</Col>{" "}
        {/*insert brand from redux here */}
      </Row>
      <br />{" "}
      <Row gutter={24}>
        <Col span={23}>
          {
            <div>
              <p>Medium: Internet</p>
              <Select
                showSearch={true}
                style={{ width: 400 }}
                onChange={(value: string) => {
                  props.setCoopToSubmit({
                    ...props.coopToSubmit,
                    publication: "Ladtech",
                    primaryCategory: "internet",
                    subCategory: value,
                  });
                }}
                defaultValue={
                  coopSubCategories.length ? coopSubCategories[0]?.category : ""
                }
              >
                {coopSubCategories?.map(index => (
                  <Select.Option
                    key={`subCat-select-option-${index.code}`}
                    value={index.code}
                  >
                    {index.category}
                  </Select.Option>
                ))}
              </Select>
            </div>
          }
        </Col>
      </Row>
      <br />
      <Row gutter={24}>
        <Col span={23}>
          {
            <div>
              <p>
                {savedOrder.dealer_oem === "Ford"
                  ? "Is this advertisement targeting a multi-cultural market?"
                  : "Will this be running solely on the Dealer Website? DBA not required if Yes."}
              </p>
              <Select
                showSearch={true}
                style={{ width: 400 }}
                onChange={(value: string) => {
                  props.setCoopToSubmit({
                    ...props.coopToSubmit,
                    dealerSiteOnly: value,
                  });
                }}
                defaultValue={savedOrder.dealer_oem === "Ford" ? "No" : "Yes"}
              >
                {["Yes", "No"]?.map(index => (
                  <Select.Option
                    key={`dealer-web-option-${index}`}
                    value={index}
                  >
                    {index}
                  </Select.Option>
                ))}
              </Select>
            </div>
          }
        </Col>
      </Row>
      <br />
      <Row gutter={24}>
        <Col span={23}>
          {
            <div>
              <div>Publication: Ladtech</div> <br />{" "}
              <div>Start and End Dates:</div>
              <RangePicker
                format={"MM/DD/YYYY"}
                onChange={(_dates, dateStrings) => {
                  props.setCoopToSubmit({
                    ...props.coopToSubmit,
                    startDate: dateStrings[0] || "",
                    endDate: dateStrings[1] || "",
                    dealerName: savedOrder.dealer_name,
                    dealerOEM: savedOrder.dealer_oem,
                  });
                }}
              />
            </div>
          }
        </Col>
      </Row>
      <br />
      {savedOrder.dealer_oem !== "Toyota" && (
        <div>
          <Row gutter={24}>
            <Col span={23}>
              {
                <div>
                  <p>
                    Is this a resubmission (If Yes, please provide a reference
                    number)
                  </p>
                  <Select
                    showSearch={true}
                    style={{ width: 400 }}
                    onChange={(value: string) => {
                      props.setCoopToSubmit({
                        ...props.coopToSubmit,
                        resubmission: value === "Yes" ? true : false,
                      });
                    }}
                    defaultValue={"No"}
                  >
                    {["No", "Yes"]?.map(index => (
                      <Select.Option
                        key={`dealer-web-option-${index}`}
                        value={index}
                      >
                        {index}
                      </Select.Option>
                    ))}
                  </Select>
                  <br /> <br />
                  <p>Reference Code</p>
                  <Input
                    onChange={event => {
                      const { target } = event;
                      const { value } = target;
                      props.setCoopToSubmit({
                        ...props.coopToSubmit,
                        referenceCode: value,
                      });
                    }}
                  />
                </div>
              }
            </Col>
          </Row>
          <br />
          <Row gutter={24}>
            <Col span={23}>
              {
                <div>
                  <div>
                    <p>Emails to CC (required)</p>
                    <Input
                      value={props?.coopToSubmit?.ccEmails}
                      onChange={event => {
                        const { target } = event;
                        const { value } = target;
                        props.setCoopToSubmit({
                          ...props.coopToSubmit,
                          ccEmails: value,
                        });
                      }}
                    />
                    <br />
                    <br />
                  </div>
                </div>
              }
            </Col>
          </Row>
        </div>
      )}
    </div>
  );

  return (
    <>
      <Modal
        title={
          savedOrder.dealer_oem
            ? `Coop Submission for ${savedOrder.dealer_oem}`
            : "Coop Submission"
        }
        visible={showCoopModal}
        onCancel={() => {
          toggleCoopModal(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              toggleCoopModal(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            disabled={coopButtonBool}
            key="submit"
            type="primary"
            onClick={() => {
              if (props.coopToSubmit && props.coopSupported) {
                const { startDate, endDate, ccEmails, phoneNumber, dealerOEM } =
                  props.coopToSubmit;
                if (
                  !startDate ||
                  !endDate ||
                  !ccEmails ||
                  (dealerOEM === "Honda" && !phoneNumber)
                ) {
                  message.warning(
                    <div>
                      <p>
                        Some required fields are missing. Please double check
                        and try again.
                      </p>
                    </div>,
                  );
                  return;
                }
              }
              handleExport(
                "coop",
                radioButtonSelection.coop,
                "jpeg",
                undefined,
                0.8,
              );
              toggleCoopModal(false);
            }}
          >
            Submit
          </Button>,
        ]}
      >
        {props.coopSupported && <div>{modalContent}</div>}
        {!props.coopSupported && <div>{genericModalContent}</div>}
      </Modal>
    </>
  );
};
export default CoopSubmissionModal;
