import API from "services";
import { QueryFunctionContext, useInfiniteQuery } from "react-query";
import {
  IGetAdsError,
  IGetAdsParams,
  IGetAdsResult,
} from "shared/types/adReview";
import { IFacebookAd } from "screens/adLibrary/facebookUtils/types";
import { getNextPageParam } from "../utils/helpers.ad";
import { getErrorMessage } from "utils/errorMessage";
import { useMemo } from "react";

export class ReduceAmountError extends Error {}

export const getQueryKey = ({
  accountId,
  since,
  to,
  platform,
  limit,
}: IGetAdsParams) => {
  const key = "ad-review/ads";
  const dateRange = [since, to].join(", ");
  return [key, accountId, dateRange, platform, `${limit}`];
};

const getAds = async (params: IGetAdsParams) => {
  try {
    const { result, error } = await API.services.adReview.getAds(params);

    if (error || !result) {
      throw Error(error?.message || "Something went wrong");
    }

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    if (errorMessage.includes("Please reduce the amount of data"))
      throw new ReduceAmountError(errorMessage);

    throw error;
  }
};

const mergePages = (pages: IGetAdsResult[] | undefined) => {
  return (
    pages?.reduce(
      (acc, page) => acc.concat(page?.data || []),
      [] as IFacebookAd[],
    ) ?? []
  );
};

export const useFetchAds = (params: IGetAdsParams, enabled: boolean = true) => {
  const handleGetAds = ({ pageParam }: QueryFunctionContext) => {
    return getAds({
      after: pageParam,
      limit: params.limit,
      platform: params.platform,
      accountId: params.accountId,
      since: params.since,
      to: params.to,
    });
  };

  const result = useInfiniteQuery<IGetAdsResult, IGetAdsError>(
    getQueryKey(params),
    handleGetAds,
    {
      enabled: enabled,
      staleTime: 1000 * 60 * 5,
      getNextPageParam: lastPage => getNextPageParam(lastPage.paging),
      retry: false,
    },
  );

  const ads = useMemo(() => {
    return mergePages(result.data?.pages);
  }, [result.data?.pages]);

  return {
    ...result,
    ads,
  };
};
