import { DeleteOutlined, ReloadOutlined } from "@ant-design/icons/lib/icons";
import { HoverActionsVirtualTable } from "shared/components/HoverActionsVirtualTable/HoverActionsVirtualTable";
import { TableActionsContextProvider } from "shared/components/HoverActionsVirtualTable/TableActionsContext";
import { ContextTableActions } from "shared/components/tableActions/ContextTableActions";
import { InlineTableActions } from "shared/components/tableActions/InlineTableActions";
import { EverythingAdHtml, ToolbarButton } from "shared/types/adLibrary";
import { useWindowSize } from "shared/hooks/useWindowSize";
import useGeneratedUrls from "./useGeneratedUrls";
import { useDeleteEverythingAdHtml } from "shared/hooks/everythingAdHtml/useEverythingAdsHtml";
import { notification } from "antd";

type Props = {
  everythingAdsHtml: EverythingAdHtml[];
  isLoadingEverythingAdsHtml: boolean;
};

export const GeneratedUrlsTable = ({
  everythingAdsHtml,
  isLoadingEverythingAdsHtml,
}: Props) => {
  const { columns, updateEverythingAdWithFeedback, isUpdateLoading } =
    useGeneratedUrls();
  const { windowInnerHeight } = useWindowSize();
  const { mutate: deleteSlug, isLoading: isDeleteLoading } =
    useDeleteEverythingAdHtml();

  const onDeleteSlug = (slug?: string) => {
    if (!slug) return;
    deleteSlug(slug, {
      onSuccess: () => {
        notification.success({
          message: "Everything Ad URL deleted successfully",
        });
      },
      onError: () => {
        notification.error({
          message: "Error deleting URL",
        });
      },
    });
  };

  const isTableLoading =
    isUpdateLoading || isLoadingEverythingAdsHtml || isDeleteLoading;

  const generateToolbarContents: (
    record?: EverythingAdHtml,
  ) => ToolbarButton = record => ({
    CustomButton: {
      showInInlineMenu: true,
      showInContextMenu: true,
      type: "inline",
      disabled: isTableLoading,
      onClick: () => updateEverythingAdWithFeedback(record),
      extraInfo: {
        title: "Reload",
        text: "Reload",
        tooltipText: "Reload",
        icon: <ReloadOutlined />,
      },
    },
    Delete: {
      showInInlineMenu: true,
      showInContextMenu: true,
      type: "inline",
      icon: <DeleteOutlined />,
      disabled: isTableLoading,
      onClick: () => onDeleteSlug(record?.slug),
    },
  });

  return (
    <TableActionsContextProvider<EverythingAdHtml>
      renderContextActions={(record?: EverythingAdHtml) => (
        <ContextTableActions
          getItemsToRender={generateToolbarContents}
          record={record}
        />
      )}
      renderHoverActions={(record?: EverythingAdHtml) => (
        <InlineTableActions
          getItemsToRender={generateToolbarContents}
          record={record}
        />
      )}
    >
      <HoverActionsVirtualTable
        rowKey={item => item.slug}
        dataSource={everythingAdsHtml}
        columns={columns}
        scroll={{ y: windowInnerHeight - 245 }}
      />
    </TableActionsContextProvider>
  );
};
