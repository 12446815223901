import { CSSProperties, ReactNode } from "react";
import styles from "./Badge.module.scss";

interface IProps {
  content: ReactNode;
  style?: CSSProperties;
}

const Badge = ({ content, style }: IProps) => {
  return (
    <div
      style={style}
      className={`${styles.cardItem} ${styles.cardBadge}${
        typeof content === "string" ? ` ${styles.cardItemTextOnly}` : ""
      }`}
    >
      {content}
    </div>
  );
};

export default Badge;
