import { useState } from "react";
import {
  Menu,
  Modal,
  Checkbox,
  Button,
  Row,
  Col,
  Space,
  Typography,
} from "antd";

import {
  columnPresets,
  designStudioTableColumns,
  ColumnPresets,
} from "./columnFilters/columns";
import { DesignStudioFields } from "screens/designStudio/context/fields";
import { DesignStudioTableColumn } from "./hooks/useDesignStudioTableColumns";

type ColumnFilterProps = {
  columns: DesignStudioTableColumn[];
  selectedColumns: string[];
  setSelectedColumns: (columns: string[]) => void;
  open: boolean;
};

export const ColumnsFilter = ({
  open,
  columns,
  selectedColumns,
  setSelectedColumns,
}: ColumnFilterProps) => {
  const [tempColumnSelection, setTempColumnSelection] = useState<{
    key: ColumnPresets;
    columns: DesignStudioFields[];
  }>({
    key: "default",
    columns: columnPresets.default,
  });

  const allColumnsSelected = columns.every(c =>
    tempColumnSelection.columns.includes(c.key),
  );

  const selectAllHandler = () => {
    if (tempColumnSelection.key === "default" && allColumnsSelected) {
      setTempColumnSelection(prev => ({
        key: prev.key,
        columns: [designStudioTableColumns.name],
      }));
      return;
    }
    if (allColumnsSelected) {
      setTempColumnSelection(prev => ({
        ...prev,
        columns: columnPresets[prev.key],
      }));
    } else {
      setTempColumnSelection(prev => ({
        ...prev,
        columns: columns.map(c => c.key),
      }));
    }
  };

  const closeModal = () => {
    setSelectedColumns(columnPresets.default);
    setTempColumnSelection({ key: "default", columns: columnPresets.default });
  };

  const updateColumns = (columns: DesignStudioFields[]) =>
    setTempColumnSelection(prev => ({ key: prev.key, columns }));

  const updatePreset = (key: ColumnPresets) =>
    setTempColumnSelection({ key, columns: columnPresets[key] });

  const confirmColumnSelection = () => {
    setSelectedColumns(tempColumnSelection.columns);
  };

  const isDisabled = (key: DesignStudioFields) => {
    const currentPreset = tempColumnSelection.key;

    if (key === designStudioTableColumns.name) return true;
    if (currentPreset === "default") return false;
    if (columnPresets[currentPreset].includes(key)) return true;
    return false;
  };
  return (
    <Modal
      title={<Typography.Text strong>Show / Hide Columns</Typography.Text>}
      width="550px"
      onCancel={closeModal}
      visible={open}
      footer={
        <div>
          <Checkbox
            key="selectAll"
            checked={allColumnsSelected}
            onClick={selectAllHandler}
          >
            Select All
          </Checkbox>
          <Button key="ok" onClick={confirmColumnSelection}>
            OK
          </Button>
        </div>
      }
    >
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Menu
            defaultSelectedKeys={["default"]}
            onClick={({ key }) => updatePreset(key as ColumnPresets)}
          >
            <Menu.Item key="default">Default</Menu.Item>
            <Menu.Item key="templates">Template</Menu.Item>
            <Menu.Item key="stamps">Stamp</Menu.Item>
            <Menu.Item key="artboards">Artboard</Menu.Item>
          </Menu>
        </Col>
        <Col span={16}>
          <Checkbox.Group
            defaultValue={selectedColumns}
            value={tempColumnSelection.columns}
            onChange={newColumns =>
              updateColumns(newColumns as DesignStudioFields[])
            }
          >
            <Space wrap direction="vertical" style={{ height: 300 }}>
              {columns.map(column => (
                <Checkbox
                  key={column.key}
                  value={column.key}
                  disabled={isDisabled(column.key)}
                >
                  {column.title}
                </Checkbox>
              ))}
            </Space>
          </Checkbox.Group>
        </Col>
      </Row>
    </Modal>
  );
};
