import { Upload, message, UploadProps, Typography, Image, Button } from "antd";
import {
  DeleteOutlined,
  EyeOutlined,
  LoadingOutlined,
  PictureOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useCallback, useState } from "react";
import { RcFile } from "antd/lib/upload/interface";
import { useUploadMarketingMaterialMedia } from "shared/hooks/adLibrary/marketingMaterials";
import { useParams } from "react-router-dom";
import styles from "./ImageLayer.module.scss";
import { ImageField } from "shared/types/marketingMaterials";
import { raise } from "utils/errorMessage";
import { useMaterialFormInstance } from "screens/adLibrary/marketingMaterials/MarketingMaterialsProvider";
import { AccountLogosDrawer } from "./imageLayer/AccountLogosDrawer";
import { AccountLogo } from "shared/types/salesEnablement";
import { LogoInstructionText } from "shared/components/salesEnablement/LogoInstructionText";
import { Rule } from "antd/lib/form";
import { validateLogo } from "shared/components/uploadAsset/UploadAsset.utils";

const ImageLayer = ({
  onChange,
  value,
  disabled,
}: {
  onChange?: (value: ImageField | undefined) => void;
  value?: ImageField;
  disabled?: boolean;
}) => {
  const [preview, setPreview] = useState<boolean>(false);
  const form = useMaterialFormInstance();
  const { upload, uploadByAccountUrl } = useUploadMarketingMaterialMedia();
  const { materialId } = useParams<{ materialId: string }>();
  const [accountLogosOpen, setAccountLogosOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string>("");

  const [uploadStatus, setUploadStatus] = useState<
    "done" | "error" | "default" | "uploading"
  >(value ? "done" : "default");

  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    accept: "image/*",
    beforeUpload: async (file: RcFile) => {
      setFileName(file.name);
      try {
        await validateLogo(file);
        setUploadStatus("done");
        setError(null);
        return true;
      } catch (err) {
        const error = err as Error;
        setError(error.message);
        setUploadStatus("error");
        return false;
      }
    },
    customRequest: async ({ file }) => {
      const assetFile = file as RcFile;
      setUploadStatus("uploading");
      try {
        const result = await upload(
          assetFile,
          (materialId || form?.getFieldValue("id")) ??
            raise("No marketing material ID found"),
        );
        onChange?.({ ...result, type: "image" });
        setUploadStatus("done");
      } catch (e) {
        message.error("Failed to upload image");
        setUploadStatus("error");
      }
    },
  };

  const onAccountLogoUpload = async ({ name, url }: AccountLogo) => {
    setUploadStatus("uploading");
    setError(null);

    try {
      const result = await uploadByAccountUrl(name);
      if (!result || !url) throw new Error("Failed to upload image");
      onChange?.({ ...result, url, type: "image" });
      setUploadStatus("done");
    } catch (e) {
      message.error("Failed to upload image");
      setUploadStatus("error");
      setError("Failed to upload image");
    }
  };

  const deleteImage = useCallback(() => {
    setError(null);
    setFileName("");
    onChange?.(undefined);
    setUploadStatus("default");
  }, [onChange]);

  const Placeholder = useCallback(() => {
    return (
      <div className={styles.placeholder}>
        {uploadStatus === "done" && !!value?.url && (
          <Image
            src={value?.url}
            preview={{
              maskClassName: styles.imagePreviewMask,
              visible: preview,
              onVisibleChange: setPreview,
              mask: (
                <div className={styles.actionButtons}>
                  <Button
                    icon={<EyeOutlined style={{ color: "white" }} />}
                    type="text"
                  />
                  {!disabled && (
                    <Button
                      icon={
                        <DeleteOutlined
                          onClick={deleteImage}
                          style={{ color: "white" }}
                        />
                      }
                      type="text"
                    />
                  )}
                </div>
              ),
            }}
            style={{ maxWidth: 120, maxHeight: 100 }}
          />
        )}
        {uploadStatus === "default" && (
          <>
            <PlusOutlined className={styles.plusIcon} />
            <Typography.Text>Upload</Typography.Text>
          </>
        )}
        {uploadStatus === "uploading" && (
          <>
            <LoadingOutlined className={styles.plusIcon} />
            <Typography.Text>Loading</Typography.Text>
          </>
        )}
        {uploadStatus === "error" && (
          <>
            <PictureOutlined className={styles.pictureIcon} />
            <div className={`${styles.mask} mask`}>
              <div className={styles.actionButtons}>
                {!disabled && (
                  <Button
                    icon={
                      <DeleteOutlined
                        onClick={deleteImage}
                        style={{ color: "white" }}
                      />
                    }
                    type="text"
                  />
                )}
              </div>
            </div>
            <div className={styles.errorFileName}>{fileName}</div>
          </>
        )}
      </div>
    );
  }, [value, uploadStatus, preview, deleteImage, disabled, fileName]);

  return (
    <>
      <LogoInstructionText />
      <div className={styles.imageSelector}>
        <Upload.Dragger
          {...uploadProps}
          className={`${styles.dragger} ${
            uploadStatus === "error" ? styles.error : ""
          } ${uploadStatus === "done" ? styles.done : ""}`}
          showUploadList={false}
          openFileDialogOnClick={uploadStatus === "default"}
          disabled={disabled}
        >
          <Placeholder />
        </Upload.Dragger>
        {!value?.url && (
          <div className={styles.imageLibraryPicker}>
            or
            <a onClick={() => (!disabled ? setAccountLogosOpen(true) : null)}>
              Select from Account Logos
            </a>
          </div>
        )}
        <AccountLogosDrawer
          onClose={() => setAccountLogosOpen(false)}
          isOpen={accountLogosOpen}
          onSelectLogo={onAccountLogoUpload}
        />
      </div>
      {error && <Typography.Text type="danger">{error}</Typography.Text>}
    </>
  );
};

export const validateRequired: Rule[] = [
  {
    validator: async (_, value?: ImageField) => {
      if (!value) throw new Error("Image is required");

      if (!value.url || !value.spAssetId) throw new Error("Image is required");
      return Promise.resolve();
    },
    type: "object",
    required: true,
    message: "This field is required.",
  },
];
export default ImageLayer;
