import { Pagination as AntdPagination } from "antd";
import { usePagination } from "../table";
import { useTableSearch } from "./useTableSearch";

import styles from "./Pagination.module.scss";

type Props = {
  data?: WDSearchResult;
};

export const Pagination = ({ data }: Props) => {
  const { setPage } = useTableSearch();
  const pagination = usePagination(setPage, data);

  return (
    <div className={styles.host}>
      {data && <AntdPagination {...pagination} />}
    </div>
  );
};
