import Title from "antd/lib/typography/Title";
import style from "./DrawerContent.module.scss";

const DrawerTitle = () => {
  return (
    <div className={style.drawer}>
      {["Version History", "Details"].map(title => (
        <div key={title} className={style.title}>
          <Title level={3} className={style.h3}>
            {title}
          </Title>
        </div>
      ))}
    </div>
  );
};

export default DrawerTitle;
