import { memo, useState } from "react";
import { Menu, Modal, Checkbox, Button, Row, Col } from "antd";

import { industryType, isEnvVarEquals } from "utils/helpers";

import { ClientType } from "shared/types/adLibrary";
import { IndustryType } from "shared/types/shared";
import { defaultColumns } from "shared/constants/legalLingoV2";

interface IColumnsFilterProps {
  displayColumn: boolean;
  selectedColumns: string[];
  columnNames: string[];
  clientType: IndustryType;
}
interface IColumnsFilterHandlers {
  setSelectedColumns: (data: string[]) => void;
  setDisplayColumn: (displayColumn: boolean) => void;
  setClientType: (clientType: IndustryType) => void;
}

type PropsType = IColumnsFilterProps & IColumnsFilterHandlers;

const basicColumns = defaultColumns;

// Modal - Columns visible in table.
const ColumnsFilter = ({
  displayColumn,
  selectedColumns,
  columnNames,
  clientType,
  setSelectedColumns,
  setDisplayColumn,
  setClientType,
}: PropsType) => {
  const [tempColumns, setTempColumns] = useState<string[]>(selectedColumns);

  const [tempClientType, setTempClientType] =
    useState<IndustryType>(clientType);

  const sharedColumns = basicColumns;

  const industrySpecificColumns = {
    auto: basicColumns,
    retail: basicColumns,
    travel: basicColumns,
    pharma: basicColumns,
  };

  const industryColumnTitle =
    tempClientType === "all"
      ? columnNames.map(name => name)
      : columnNames.filter(
          name =>
            sharedColumns.includes(name) ||
            industrySpecificColumns[tempClientType].includes(name),
        );

  return (
    <Modal
      className="disclosures-table-column-modal"
      title="Show / Hide Columns"
      width="500px"
      onCancel={() => {
        setDisplayColumn(false);
        setTempColumns(selectedColumns);
      }}
      visible={displayColumn}
      footer={
        <div>
          <Checkbox
            key="selectAll"
            checked={industryColumnTitle.every(c => tempColumns.includes(c))}
            onClick={() => {
              if (industryColumnTitle.every(c => tempColumns.includes(c))) {
                setTempColumns(basicColumns);
              } else {
                setTempColumns(industryColumnTitle);
              }
            }}
          >
            Select All
          </Checkbox>
          <Button
            key="ok"
            onClick={() => {
              setSelectedColumns(
                industryColumnTitle.filter(t => tempColumns.includes(t)),
              );
              setClientType(tempClientType);
              setDisplayColumn(false);
            }}
          >
            OK
          </Button>
        </div>
      }
    >
      {industryType("auto") && isEnvVarEquals("CLIENT", "internal") && (
        <Menu
          defaultSelectedKeys={[clientType]}
          onClick={({ key }) => {
            setTempClientType(key as IndustryType);
          }}
        >
          {Object.entries(ClientType).map(([key, value]) => {
            return <Menu.Item key={key}>{value}</Menu.Item>;
          })}
        </Menu>
      )}
      <Checkbox.Group
        defaultValue={selectedColumns}
        value={tempColumns}
        onChange={newColumns => setTempColumns(newColumns as string[])}
      >
        <Row justify="space-around" align="top">
          <Col span={11}>
            {industryColumnTitle
              .slice(0, (industryColumnTitle.length + 1) / 2)
              .map(title => (
                <Checkbox
                  key={title}
                  value={title}
                  disabled={basicColumns.includes(title)}
                >
                  {title}
                </Checkbox>
              ))}
          </Col>
          <Col span={11}>
            {industryColumnTitle
              .slice((industryColumnTitle.length + 1) / 2)
              .map(title => (
                <Checkbox key={title} value={title}>
                  {title}
                </Checkbox>
              ))}
          </Col>
        </Row>
      </Checkbox.Group>
    </Modal>
  );
};

export default memo(ColumnsFilter);
