import usePreference from "./usePreference";

const useSelectedColumns = (defaultColumns: string[]) => {
  const { value: selectedColumns, setValue: setSelectedColumns } =
    usePreference("selectedColumns", defaultColumns, true);

  const isColumnSelected = (columnName: string) =>
    selectedColumns.includes(columnName);

  const toggleColumnSelection = (columnName: string) => {
    const columns = isColumnSelected(columnName)
      ? selectedColumns.filter(value => value !== columnName)
      : selectedColumns.concat(columnName);
    setSelectedColumns(columns);
  };

  return {
    selectedColumns,
    isColumnSelected,
    setSelectedColumns,
    toggleColumnSelection,
  };
};

export default useSelectedColumns;
