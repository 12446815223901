import { useState } from "react";
import { Button, Col, Drawer, Input, Row, Table, TableColumnProps } from "antd";
import { MinMaxInput } from "./PrintOptionsDrawer/MinMaxInput";
import {
  PrintOptionsCopy,
  PrintOptionsCopyQuantity,
} from "shared/types/printOptions";
import uuid from "uuid";
import { DeleteOutlined } from "@ant-design/icons";
import { PrintOptionsForm } from "./PrintOptionsDrawer/PrintOptionsForm";
import { DrawerFooter } from "./PrintOptionsDrawer/DrawerFooter";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";
import { matchPath } from "react-router-dom";
import { ROUTES } from "./constants";

export const PrintOptionsDrawer = () => {
  const navigate = useNavigateWithSearch();
  const [dataSource, setDataSource] = useState<PrintOptionsCopy[]>([
    {
      id: uuid.v4(),
      quantity: { min: 5, max: 10 },
      cost: "",
      mode: "SIMPLEX",
    },
    {
      id: uuid.v4(),
      quantity: { min: 11, max: 20 },
      cost: "",
      mode: "SIMPLEX",
    },
  ]);

  const onChangeQuantity = (id: string, value: PrintOptionsCopyQuantity) => {
    const newDataSource = dataSource.map(item => {
      if (item.id === id) {
        return { ...item, ...value };
      }
      return item;
    });
    setDataSource(newDataSource);
  };

  const columns: TableColumnProps<PrintOptionsCopy>[] = [
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: "50%",
      render: (_, record: PrintOptionsCopy) => (
        <MinMaxInput
          min={record.quantity.min}
          max={record.quantity.max}
          onChange={value => onChangeQuantity(record.id, value)}
        />
      ),
    },
    {
      title: "Simplex Cost per Copy",
      dataIndex: "cost",
      key: "cost",
      width: "50%",
      render: (_, record: PrintOptionsCopy) => (
        <Row gutter={12} align="middle">
          <Col span={20}>
            <Input prefix="$" />
          </Col>
          <Col span={4}>
            <DeleteOutlined onClick={() => handleDeleteRow(record.id)} />
          </Col>
        </Row>
      ),
    },
  ];

  const handleAddRow = () => {
    const lastItem = dataSource[dataSource.length - 1];

    const newRow: PrintOptionsCopy = {
      id: uuid.v4(),
      quantity: {
        min: Number(lastItem.quantity.max) + 1,
        max: undefined,
      },
      cost: "",
      mode: "SIMPLEX",
    };
    setDataSource([...dataSource, newRow]);
  };

  const handleDeleteRow = (id: string) => {
    const newDataSource = dataSource.filter(item => item.id !== id);
    setDataSource(newDataSource);
  };

  const onClose = () => {
    navigate(ROUTES.home);
  };

  const handleSave = () => {
    navigate(ROUTES.home);
  };

  const isPrintOptionsDrawerVisible = !!matchPath(
    { path: ROUTES.create },
    location.pathname,
  );

  return (
    <Drawer
      title="Print Options"
      width={928}
      visible={isPrintOptionsDrawerVisible}
      onClose={onClose}
      closable={false}
      destroyOnClose
      footer={<DrawerFooter onCancel={onClose} onSave={handleSave} />}
    >
      <PrintOptionsForm />
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        footer={() => (
          <Button type="link" onClick={handleAddRow}>
            Add Row
          </Button>
        )}
      />
    </Drawer>
  );
};
