import { CSSProperties, useContext, useEffect, useMemo } from "react";
import { Handle, Position } from "react-flow-renderer";
import useIEContextAPI, {
  EANode,
} from "screens/everythingAds.hooks/useContextAPI";
import { ElementType } from "shared/types/adLibrary";

import { AppColors } from "shared/types/shared";
import FlowItem from "../shared/FlowItem";
import styles from "./FlowInstantExperience.module.scss";
import FlowButton from "./flowInstantExperience/FlowButton";
import FlowCarousel from "./flowInstantExperience/FlowCarousel";
import FlowImage from "./flowInstantExperience/FlowImage";
import FlowProductSet from "./flowInstantExperience/FlowProductSet";
import FlowVideo from "./flowInstantExperience/FlowVideo";
import NoElement from "./flowInstantExperience/NoElement";
import {
  isButton,
  isCarousel,
  isFooter,
  isImage,
  isProductSet,
  isVideo,
} from "utils/adLibrary.validators";
import Wrapper from "../shared/Wrapper";

const heightAdjustableElements: ElementType[] = ["BUTTON"];
const FlowInstantExperience = (props: EANode) => {
  const { selected } = props;
  const { Context } = useIEContextAPI();
  const ieContext = useContext(Context);
  const { paneDrag, setElements } = ieContext || {};

  const { ie } = props.data || {};
  const bodyElements = ie?.body_elements?.filter(ele => !!ele) || [];

  const { paneMovable } = paneDrag || {};
  useEffect(() => {
    setElements?.(prev =>
      prev.map(ele => ({
        ...ele,
        style: {
          cursor: "default",
          pointerEvents: paneMovable ? "none" : "all",
        },
      })),
    );
  }, [paneMovable, setElements]);

  const contentStyle: CSSProperties = useMemo(
    () =>
      selected
        ? {
            borderColor: AppColors.primary,
          }
        : {},
    [selected],
  );

  return (
    <Wrapper title={ie?.name || "Unknown"} selected={selected}>
      {ie ? (
        <div className={styles.body} style={contentStyle}>
          <Handle type="target" position={Position.Left} />

          {bodyElements.length === 0 && <NoElement />}

          {bodyElements.map(ele => {
            const autoHeight = heightAdjustableElements.includes(
              ele.element_type,
            );
            const style: CSSProperties = autoHeight ? {} : {};
            return (
              <FlowItem key={`flow-item-${ele.id}`} style={style} ele={ele}>
                {isImage(ele) && <FlowImage ele={ele} />}
                {isVideo(ele) && <FlowVideo ele={ele} />}
                {isButton(ele) && <FlowButton ele={ele} />}
                {isProductSet(ele) && <FlowProductSet ele={ele} />}
                {isCarousel(ele) && <FlowCarousel ele={ele} />}
                {isFooter(ele) && <FlowButton ele={ele} />}
              </FlowItem>
            );
          })}
        </div>
      ) : (
        <div>Error</div>
      )}
    </Wrapper>
  );
};

export default FlowInstantExperience;
