import { queryClient } from "queryClient";
import { useMutation } from "react-query";
import API from "services";
import { AssetExporterState, IFeedForm } from "shared/types/assetExporter";
import { getErrorMessage } from "utils/errorMessage";
import {
  convertRepeatForServer,
  convertStartTimeForServer,
  createNextTimeStamp,
} from "utils/helpers.assetExporter";
import { getFeedFormAttrs } from "./utils";

const createFeedCron = async (
  drawerData: AssetExporterState["sourceFeed"]["feedDrawer"],
) => {
  try {
    const isUpdate = drawerData.drawerMode === "UPDATE";
    const serverForm: IFeedForm = getFeedFormAttrs(
      drawerData,
      isUpdate,
      "server",
    );
    const customForm = getFeedFormAttrs(drawerData, isUpdate, "custom");
    const columnData = [...drawerData.columnData];
    const smartCols = [...drawerData.selectedSmartCols];
    const feedFileName = drawerData.uploadedFile!;
    const feedType = drawerData.uploadType;
    const socialFeedType = drawerData.selectedType as string;
    const data = {
      feedFileName,
      columnData,
      smartCols,
      socialFeedType,
      isUpdate,
      feedType,
    };
    const serverBody = {
      ...data,
      feedForm: {
        ...serverForm,
        repeat: convertRepeatForServer(serverForm),
        startTime: convertStartTimeForServer(serverForm.startTime),
        nextUpdate:
          serverForm.frequency !== "Never"
            ? createNextTimeStamp(serverForm)
            : undefined,
      },
      googleSheetId: drawerData.googleSheetId,
      googleSheetName: drawerData.googleSheetName,
    };
    const customBody = { ...data, feedForm: customForm };
    const body = feedType === "custom" ? customBody : serverBody;
    const { createMappedFeedCron } = API.services.assetExporter;
    const { result, error } = await createMappedFeedCron(body);
    if (error) throw new Error(error.message);
    if (!result) throw new Error("Unable to create cron");
    return result;
  } catch (error) {
    const errMsg = getErrorMessage(error);
    throw new Error(errMsg);
  }
};

export const useCreateFeedCron = () => {
  return useMutation<
    { success: boolean },
    Error,
    AssetExporterState["sourceFeed"]["feedDrawer"]
  >(data => createFeedCron(data), {
    onSettled: () => {
      queryClient.invalidateQueries("feedCrons");
    },
  });
};

const deleteFeedCron = async (feedId: string) => {
  try {
    const response = await API.services.assetExporter.deleteCronFeed(feedId);
    if (response.error) throw new Error(response.error.message);
    if (!response.result) throw new Error("Unable to delete feed.");
    return response.result.deletedFeedId;
  } catch (error) {
    const errMsg = getErrorMessage(error);
    throw new Error(errMsg);
  }
};
export const useDeleteFeedCron = () => {
  return useMutation<string, Error, string>(feedId => deleteFeedCron(feedId), {
    onSettled: () => {
      queryClient.invalidateQueries("feedCrons");
    },
  });
};
