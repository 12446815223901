/* eslint-disable react/display-name */
import { FC, useRef } from "react";
import "../../review/ReviewPage.scss";

import { CaretRightOutlined } from "@ant-design/icons";

import { Result, Typography, Row, Col, Descriptions, Spin } from "antd";
import GenericError from "shared/errors/GenericError";
import {
  GetPaymentEngineDealDataResult,
  FinanceExplainedUIFieldKeys,
  FinanceExplainedSubsectionHeaders,
  FinanceExplainedSectionDictEntry,
} from "shared/types/paymentEngine";
import {
  returnFinanceExplainedValue,
  shouldMakeValueBoldFinance,
  shouldDrawBorderBotFinance,
} from "utils/helpers.paymentEngine";
import {
  financeFieldsWithSubtext,
  financeExplainedItemsDictionary,
} from "shared/constants/paymentEngine";
import { IAccount } from "shared/types/accountManagement";

const { Paragraph, Text } = Typography;

interface PaymentEngineFinanceExplainedType {
  getPaymentEngineDataError: GenericError | null;
  rawPaymentEngineData: GetPaymentEngineDealDataResult | null;
  currentDealerData: IAccount | null;
}

const PaymentEngineFinanceExplained: FC<PaymentEngineFinanceExplainedType> = ({
  getPaymentEngineDataError,
  rawPaymentEngineData,
  currentDealerData,
}) => {
  const leftDivRef = useRef<HTMLDivElement | null>(null);

  const storeState = currentDealerData?.state || "<DEALER.STATE>";

  const returnSuggestionParagraph = (text: string) => (
    <Paragraph>
      <CaretRightOutlined className="site-result-demo-error-icon" /> {text}
    </Paragraph>
  );

  const returnFieldNameSpan = (
    key: FinanceExplainedUIFieldKeys,
    dictKey: FinanceExplainedSubsectionHeaders,
  ) => {
    const fieldName = <span>{key}</span>;

    const shouldMakeBold = shouldMakeValueBoldFinance(key, dictKey);

    return (
      <span style={{ fontWeight: shouldMakeBold ? "bold" : "normal" }}>
        {fieldName}
      </span>
    );
  };

  const returnSectionTable = (
    dict: FinanceExplainedSectionDictEntry,
    dictKey: FinanceExplainedSubsectionHeaders,
  ) =>
    Object.keys(dict).map(field => {
      const typedField = field as FinanceExplainedUIFieldKeys;

      const fieldSpan = returnFieldNameSpan(typedField, dictKey);
      const hasSubtext = financeFieldsWithSubtext.includes(typedField);
      const hasBorderBot = shouldDrawBorderBotFinance(typedField, dictKey);
      const hasBoldText = shouldMakeValueBoldFinance(typedField, dictKey);
      const isLender = typedField === "Lender";

      const value = returnFinanceExplainedValue(
        typedField,
        dict,
        rawPaymentEngineData,
      );

      return (
        <div style={{ width: "100%" }} key={field}>
          <Row
            align="bottom"
            style={{
              paddingTop: "0.5em",
              borderBottom: "solid 1px #e9e9e9",
            }}
          >
            <Col
              span={isLender ? 8 : 20}
              style={{
                paddingRight: "1.5em",
                textAlign: "left",
              }}
            >
              <span>
                {fieldSpan}
                <br />
                {hasSubtext && (
                  <Text
                    style={{ fontSize: "10px", color: "rgba(0, 0, 0, 0.65)" }}
                    type="secondary"
                  >
                    {typedField === "Finance Charge"
                      ? `Finance at 0.00% ${storeState} sales tax is excluded unless otherwise indicated`
                      : ""}
                  </Text>
                )}
              </span>
            </Col>
            <Col
              span={isLender ? 16 : 4}
              style={{
                textAlign: "right",
                borderBottom: hasBorderBot ? "solid 1px black" : "none",
              }}
            >
              <span
                style={{
                  fontWeight: hasBoldText ? "bold" : "normal",
                }}
              >
                {typedField !== "Finance at" &&
                value !== "N/A" &&
                typedField !== "Lender"
                  ? "$"
                  : ""}
                {value}
              </span>
            </Col>
          </Row>
        </div>
      );
    });

  const keys = Object.keys(
    financeExplainedItemsDictionary,
  ) as FinanceExplainedSubsectionHeaders[];

  const returnDescriptionItems = (
    sectionKeys: FinanceExplainedSubsectionHeaders[],
  ) =>
    sectionKeys.map(sectionKey => (
      <Descriptions.Item
        key={`${sectionKey}-descriptions-item`}
        label={sectionKey}
        span={3}
      >
        {returnSectionTable(
          financeExplainedItemsDictionary[sectionKey],
          sectionKey,
        )}
      </Descriptions.Item>
    ));

  return (
    <>
      {getPaymentEngineDataError && !rawPaymentEngineData && (
        <Result
          status="error"
          title="Finance Explained Generation Failed"
          subTitle={getPaymentEngineDataError.message}
        >
          <div className="desc">
            <Paragraph>
              <Text strong>Try the following to help resolve the issue:</Text>
            </Paragraph>
            {returnSuggestionParagraph(
              "Verify that the offer data submitted is valid.",
            )}
            {returnSuggestionParagraph(
              "Verify that a deal in your Payment Engine has a matching VIN to this offer",
            )}
          </div>
        </Result>
      )}
      <Spin
        spinning={!rawPaymentEngineData}
        size="large"
        tip="Building Payment Engine Finance Summary..."
      >
        <div
          ref={leftDivRef}
          className={"finance-explained-container"}
          style={{ width: "100%" }}
        >
          <Descriptions layout="vertical" bordered column={3}>
            {returnDescriptionItems(keys)}
          </Descriptions>
        </div>
      </Spin>
    </>
  );
};

export default PaymentEngineFinanceExplained;
