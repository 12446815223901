import { Form, Upload, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { RcFile, UploadFile, UploadProps } from "antd/lib/upload/interface";
import { camelCase } from "lodash";
import { ZodType, ZodTypeDef } from "zod";
import { IFile } from "shared/types/uploadAsset";
import TitleWithDescription from "shared/components/uploadAsset/TitleWithDescription";
import * as utils from "./UploadAsset.utils";
import styles from "./UploadAsset.module.scss";
import * as helpers from "../../../utils/helpers";
import { useValidateFiles } from "./useValidateFiles";

export interface IOnUploadAssedChange<T> {
  isAdding: boolean;
  fieldKey: keyof T;
  updatedFile: UploadFile<any>;
}

export interface IUploadAssetProps<T> {
  title: string;
  fileList: UploadFile<IFile>[];
  maxLength: number;
  fieldKey: keyof T;
  onUploadChange: (args: IOnUploadAssedChange<T>) => void;
  imageAssetDetail: Record<string, { ratio: number; info: string }>;
  validationSchema: ZodType<any, ZodTypeDef, any>;
  minimum?: number;
  required?: boolean;
}

const UploadAsset = ({
  title,
  fileList,
  fieldKey,
  required,
  onUploadChange,
  imageAssetDetail,
  validationSchema,
  ...lengthProps
}: IUploadAssetProps<any>) => {
  const assetRatio = imageAssetDetail[title].ratio;

  const { computedFiles, validationError, formRules } = useValidateFiles({
    fileList,
    schema: validationSchema,
    title,
  });

  const beforeUpload = (file: RcFile) => {
    utils
      .validateLocalFile(file, assetRatio)
      .catch(err => message.error(`${err}`));
    return false;
  };

  const onChange: UploadProps["onChange"] = async ({
    file: currentFile,
    fileList: updatedFileList,
  }) => {
    const isAdding = updatedFileList.length > fileList.length;
    const file = isAdding
      ? updatedFileList[updatedFileList.length - 1]
      : currentFile;

    if (!file) return;

    const updatedFile: UploadFile<any> = isAdding
      ? {
          ...file,
          name: helpers.generateGuidFilename(file.name),
          thumbUrl: await utils.toBase64(file.originFileObj), // toBase64 might throw error but this line is fine because we check "file" above.
        }
      : file;

    onUploadChange({ isAdding, fieldKey, updatedFile });
  };

  return (
    <Form.Item
      data-cy={`upload-asset-${fieldKey.toString()}`}
      name={camelCase(title)}
      label={
        <TitleWithDescription
          title={title}
          info={imageAssetDetail[title].info}
          required={required}
          {...lengthProps}
        />
      }
      colon={false}
      className={styles.formItem}
      help={validationError !== "" ? validationError : undefined}
      rules={formRules}
    >
      <Upload
        beforeUpload={beforeUpload}
        listType="picture-card"
        fileList={computedFiles}
        onChange={onChange}
        data-cy={fieldKey}
      >
        {fileList.length < lengthProps.maxLength && (
          <div className={styles.uploadButton}>
            <PlusOutlined />
            <div>Upload</div>
          </div>
        )}
      </Upload>
    </Form.Item>
  );
};

export default UploadAsset;
