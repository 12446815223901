import { useCallback, useEffect } from "react";
import { selectAdLoad } from "redux/adLibrary/adLibrary.selectors";
import { useModifySessionJson } from "shared/hooks/adLibrary/adHistory/useMutateSessionJson";
import { useAppSelector } from "shared/hooks/useAppSelector";

export const useSyncDataWithBackend = () => {
  const adLoad = useAppSelector(selectAdLoad);

  const { mutate: mutateSessionJson } = useModifySessionJson({
    sessionId: adLoad.adLoadHistory?.sessionId ?? "",
  });

  const syncAdLoadWithBackend = useCallback(() => {
    if (adLoad.adLoadHistory?.sessionId) {
      mutateSessionJson({
        sessionId: adLoad.adLoadHistory.sessionId,
        sessionData: adLoad,
      });
    }
  }, [adLoad, mutateSessionJson]);

  useEffect(syncAdLoadWithBackend, [syncAdLoadWithBackend]);
};
