import API from "services";
import { useQuery } from "react-query";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

const fetchClientVersion = async () => {
  if (isLocalhost) return process.env.REACT_APP_AV2_VERSION;
  const response = await API.services.common.getClientVersion();
  const data = await response.text();

  return data.trim(); // remove trailing newline character from the response text;
};

const REFETCH_INTERVAL = 1000 * 60 * 10; // 10 minutes
/*
 * This hook is used to fetch the client version by looking at the .version file that gets generated in our build process.
 * It can be used to determine if the client is using an older version of the app.
 */
const useFetchClientVersion = () => {
  const initialVersionNumber = process.env.REACT_APP_AV2_VERSION;
  const { data: updatedVersionNumber } = useQuery(
    "app-version",
    fetchClientVersion,
    {
      staleTime: Infinity,
      refetchInterval: REFETCH_INTERVAL,
    },
  );

  return {
    version: updatedVersionNumber,
    updateAvailable:
      !!updatedVersionNumber && updatedVersionNumber !== initialVersionNumber,
  };
};

export default useFetchClientVersion;
