import { DeleteOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import { TUploadLogosS3DictionaryKey } from "shared/types/uploadManagement";
import { IAccountRecord } from "shared/types/accountManagement";
import "../../../shared/styles/Collapse.scss";
import "../../../screens/oemManagement/OemLogos.scss";

interface Props {
  dealerToEditWithLogos: IAccountRecord;
  setLogoNameToDelete: (value: React.SetStateAction<string>) => void;
  setLogoGroupToDelete: (
    value: React.SetStateAction<TUploadLogosS3DictionaryKey>,
  ) => void;
  toggleShowDeleteLogoBoxModal: (value: React.SetStateAction<boolean>) => void;
}

const UploadedLogos = ({
  dealerToEditWithLogos,
  setLogoGroupToDelete,
  setLogoNameToDelete,
  toggleShowDeleteLogoBoxModal,
}: Props) => {
  const keys = [
    "horizontalImagesFromS3",
    "verticalImagesFromS3",
    "squareImagesFromS3",
  ];

  const renderHeader = (section: string, count: number) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ paddingRight: "7px" }}>{section}</div>
      <div className="orange-counter">{count}</div>
    </div>
  );

  const renderLogo = (logo: string, key: string) => (
    <div
      key={`${logo}-index`}
      style={{
        border: "1px dashed gray",
        width: "30%",
        height: "80px",
        background: "lightgray",
      }}
    >
      <div
        style={{ float: "right", cursor: "pointer" }}
        onClick={() => {
          setLogoNameToDelete(logo);
          setLogoGroupToDelete(key as TUploadLogosS3DictionaryKey);
          toggleShowDeleteLogoBoxModal(true);
        }}
      >
        <DeleteOutlined />
      </div>
      <img
        style={{
          maxWidth: "30%",
          maxHeight: "80px",
          display: "block",
        }}
        src={logo}
      />
    </div>
  );

  return (
    <Collapse
      className="collapse-container"
      bordered={false}
      expandIconPosition="right"
    >
      {keys.map((key: string) => {
        let section = key.split("Images")[0];
        section = section.charAt(0).toUpperCase() + section.slice(1);

        const logoSection =
          dealerToEditWithLogos?.logoUrlsFromS3?.[
            key as TUploadLogosS3DictionaryKey
          ];

        return (
          <Collapse.Panel
            key={`${key}-collapse-oem`}
            className="filter-section-container-panel"
            header={renderHeader(section, logoSection?.length || 0)}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap",
              }}
            >
              {logoSection?.map((logo: string) => renderLogo(logo, key))}
            </div>
          </Collapse.Panel>
        );
      })}
    </Collapse>
  );
};

export default UploadedLogos;
