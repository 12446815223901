import { IConfig } from "shared/types/configuration";
import HttpClient from "./httpClient";

export default ({ headers, config }: { headers: any; config: IConfig }) => ({
  getSpecialCampaigns: <T>(): Promise<T> => {
    const { services } = config;
    const getSpecialCampaignsUrl = services.getSpecialCampaignsUrl;
    return HttpClient.get(getSpecialCampaignsUrl);
  },
});
