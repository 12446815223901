/* eslint-disable react/display-name */
import { Table, Button, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { useRef, useState } from "react";

import Highlighter from "react-highlight-words";
import AdLoadAdStatusBadge from "../adLoad/adLoadDrawer/shared/components/adsToLoadTable/AdLoadAdStatusBadge";

import { useAppShallowEqualSelector } from "shared/hooks/useAppSelector";

import { compareString } from "utils/helpers";

import { IFacebookAdToLoad } from "shared/types/adLibrary";
import { ColumnsType } from "antd/es/table";
import { terms } from "utils/terms";

interface IProps {
  facebookAdsToLoad: IFacebookAdToLoad[];
}

type AdLoadTableEntry = { key: string } & IFacebookAdToLoad;

const AdLoadStatusTable = (props: IProps) => {
  const { facebookAdsToLoad } = props;

  const { selectedAccounts } = useAppShallowEqualSelector(({ adLibrary }) => ({
    selectedAccounts: adLibrary.selectedFacebookAccounts,
  }));

  const accountFilters = selectedAccounts?.map(account => ({
    text: account.name,
    value: account.account_id,
  }));

  const [searchNameText, setSearchNameText] = useState("");
  const searchNameInputNameRef = useRef<Input | null>(null);

  const columns: ColumnsType<AdLoadTableEntry> = [
    {
      title: "Load Status",
      dataIndex: "adLoadStatus",
      width: 300,
      filters: [
        {
          text: "Ready",
          value: "idle",
        },
        {
          text: "In progress",
          value: "loading",
        },
        {
          text: "Success",
          value: "success",
        },
        {
          text: "Failed",
          value: "error",
        },
      ],
      render: (text, { description, adLoadStatus }) => (
        <AdLoadAdStatusBadge
          description={description}
          status={adLoadStatus.status}
          facebookError={adLoadStatus.facebookError}
        />
      ),

      onFilter: (value, record) => record.adLoadStatus.status === value,
      sorter: (a, b) =>
        compareString(a.adLoadStatus.status, b.adLoadStatus.status),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Ad Name",
      dataIndex: "title",
      width: 550,
      sorter: (a, b) => compareString(a.title, b.title),
      sortDirections: ["ascend", "descend"],
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchNameInputNameRef}
            placeholder={`Search Ad Name`}
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
              setSearchNameText(selectedKeys[0] as string);
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => {
                confirm();
                setSearchNameText(selectedKeys[0] as string);
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters?.();
                setSearchNameText("");
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record.title
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchNameInputNameRef?.current?.select(), 100);
        }
      },
      render: text => (
        <Highlighter
          autoEscape
          searchWords={[searchNameText]}
          textToHighlight={text ? text.toString() : ""}
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        />
      ),
    },
    {
      title: terms.accountName,
      dataIndex: "adAccount",
      width: 150,
      render: (text, record) => record.adAccount?.name,
      filters: accountFilters,
      onFilter: (value, record) => record?.adAccount?.id === value,
      sorter: (a, b) =>
        compareString(a.adAccount?.name || "N/A", b.adAccount?.name || "N/A"),
      sortDirections: ["ascend", "descend"],
    },
  ];

  const data: AdLoadTableEntry[] = facebookAdsToLoad.map((adToLoad, index) => ({
    ...adToLoad,
    key: (index + 1).toString(),
  }));

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      style={{ overflowY: "scroll", maxHeight: 200 }}
    />
  );
};

export default AdLoadStatusTable;
