import {
  Space,
  Typography,
  Form,
  Input,
  Row,
  Col,
  Select,
  Tooltip,
  DatePicker,
  Button,
  InputNumber,
  FormInstance,
} from "antd";
import GenericStoreSelect from "shared/components/select/GenericAccountSelect";
import { isPastDate, numberInputFormatter } from "./newCampaignForm/helpers";
import styles from "../../GooglePerformanceMax.module.scss";
import { OmittedTags } from "shared/components/OmittedTags";
import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { usePmaxCampaignForm } from "./newCampaignForm/usePmaxCampaignForm";
import { GoogleAdCampaignInternalForm } from "shared/types/adLibrary";
import { useFetchInfiniteDealers } from "shared/hooks/useFetchDealers";
import { MissingLocationAlert } from "./newCampaignForm/MissingLocationAlert";
import { AccountLocationDrawer } from "./newCampaignForm/AccountLocationDrawer";
import { useMemo, useState } from "react";
import { RequiredMark } from "./newCampaignForm/RequiredMark";

export const NewCampaignForm = ({
  form,
}: {
  form: FormInstance<GoogleAdCampaignInternalForm>;
}) => {
  const { dealers: accounts, isFetching } = useFetchInfiniteDealers();
  const {
    handleValuesChange,
    isLoadingGeoTargets,
    isLoadingExclusions,
    geoTargetOptions,
    rulesetOptions,
    schemaRules,
    accountsWithAddress,
  } = usePmaxCampaignForm(form, accounts);

  const [isAddressDrawerOpen, setIsAddressDrawerOpen] = useState(false);

  const selectedAccountName = form.getFieldValue("locationTarget");
  const selectedAccount = useMemo(
    () => accounts.find(a => a.dealer_name === selectedAccountName),
    [accounts, selectedAccountName],
  );
  const displayMissingLocationAlert =
    !isFetching &&
    selectedAccountName &&
    !accountsWithAddress.includes(selectedAccountName);

  return (
    <>
      <Form layout="vertical" form={form} onValuesChange={handleValuesChange}>
        {displayMissingLocationAlert && (
          <MissingLocationAlert
            accountName={selectedAccountName}
            onClick={() => setIsAddressDrawerOpen(true)}
          />
        )}
        <Form.Item
          label={
            <Typography.Text>
              Campaign Name
              <RequiredMark />
            </Typography.Text>
          }
          name="name"
          rules={[schemaRules]}
        >
          <Input />
        </Form.Item>

        <Row gutter={16} align="bottom">
          <Col span={12}>
            <Form.Item
              label={
                <Tooltip
                  title="The address of the Account will be targeted"
                  placement="topRight"
                >
                  <Space>
                    <Typography.Text>
                      Location Target
                      <RequiredMark />
                    </Typography.Text>
                    <InfoCircleOutlined />
                  </Space>
                </Tooltip>
              }
              name="locationTarget"
              rules={[schemaRules]}
            >
              <GenericStoreSelect placeholder="Select an Account to target" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Radius" name="radius" rules={[schemaRules]}>
              <InputNumber min={1} max={500} className={styles.inputNumber} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="radiusUnit" rules={[schemaRules]}>
              <Select>
                <Select.Option value="mi">mi</Select.Option>
                <Select.Option value="km">km</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Exclude Locations" name="excludeLocations">
          <Select
            mode="multiple"
            showSearch
            disabled={isLoadingGeoTargets}
            loading={isLoadingGeoTargets}
            options={geoTargetOptions}
            maxTagCount={isLoadingGeoTargets ? 0 : 5}
            optionFilterProp="label"
            maxTagPlaceholder={omittedValues => (
              <OmittedTags
                values={omittedValues.map(omitted => omitted.label)}
              />
            )}
          />
        </Form.Item>

        <Row gutter={16} align="top">
          <Col span={12}>
            <Form.Item
              label={
                <Typography.Text>
                  Start Date
                  <RequiredMark />
                </Typography.Text>
              }
              name="startDate"
              rules={[schemaRules]}
            >
              <DatePicker
                format="MM-DD-YYYY"
                disabledDate={isPastDate}
                className={styles.fullWidth}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <Tooltip
                  title="Only for special campaigns"
                  placement="topRight"
                >
                  <Space>
                    <Typography.Text>End Date</Typography.Text>
                    <InfoCircleOutlined />
                  </Space>
                </Tooltip>
              }
              name="endDate"
              shouldUpdate
            >
              <DatePicker
                format="MM-DD-YYYY"
                disabledDate={date =>
                  isPastDate(date, form.getFieldValue("startDate"))
                }
                className={styles.fullWidth}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} align="top">
          <Col span={12}>
            <Form.Item
              label={
                <Tooltip
                  title="Type to calculate the average daily budget"
                  placement="topRight"
                >
                  <Space>
                    <Typography.Text>Budget</Typography.Text>
                    <InfoCircleOutlined />
                  </Space>
                </Tooltip>
              }
              name="totalBudget"
              rules={[schemaRules]}
            >
              <InputNumber
                className={styles.inputNumber}
                formatter={numberInputFormatter}
                stringMode
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <Typography.Text>
                  Daily Budget
                  <RequiredMark />
                </Typography.Text>
              }
              name="dailyBudget"
              rules={[schemaRules]}
            >
              <InputNumber
                className={styles.inputNumber}
                formatter={numberInputFormatter}
                stringMode
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Add exclusions and create rules"
          name="rulesetName"
          className={styles.urlExclusionsSection}
          shouldUpdate
        >
          <Select
            placeholder="Select a Ruleset"
            disabled={isLoadingExclusions}
            loading={isLoadingExclusions}
            options={rulesetOptions}
          />
        </Form.Item>

        <Form.List name="urlExclusions">
          {(fields, { add, remove }) =>
            form.getFieldValue("rulesetName") && (
              <Form.Item className={styles.exclusionList}>
                {fields.map((field, index) => (
                  <Form.Item noStyle shouldUpdate key={field.key}>
                    {() => (
                      <Row
                        gutter={8}
                        align="middle"
                        className={styles.exclusionListRow}
                      >
                        <Col span={4}>URL</Col>
                        <Col span={6}>contains</Col>
                        <Col span={12}>
                          <Form.Item {...field} name={[field.name]} noStyle>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          {!!fields.length && (
                            <Button
                              className={styles.removeButton}
                              icon={<DeleteOutlined />}
                              shape="circle"
                              size="small"
                              type="text"
                              onClick={() => {
                                fields.length > 1
                                  ? remove(index)
                                  : form.setFieldsValue({
                                      urlExclusions: [""],
                                    });
                              }}
                            />
                          )}
                        </Col>
                      </Row>
                    )}
                  </Form.Item>
                ))}

                <Button
                  type="link"
                  icon={<PlusCircleOutlined />}
                  size="small"
                  onClick={() => add()}
                >
                  Add rule
                </Button>
              </Form.Item>
            )
          }
        </Form.List>
      </Form>
      <Space direction="vertical" size="small" className={styles.infoList}>
        <Typography.Text strong>Campaign Objective</Typography.Text>
        <Typography.Text>
          Create a campaign without a goal&apos;s guidance
        </Typography.Text>
        <Typography.Text strong>Conversion Goals</Typography.Text>
        <Typography.Text>Customer-level conversion goals</Typography.Text>
        <Typography.Text strong>Bidding</Typography.Text>
        <Typography.Text>Maximize Conversions</Typography.Text>
      </Space>
      <AccountLocationDrawer
        key={selectedAccountName}
        account={selectedAccount}
        isOpen={isAddressDrawerOpen}
        close={() => setIsAddressDrawerOpen(false)}
        onSuccess={() =>
          // Cleanup errors from the locationTarget field
          form.setFields([{ name: "locationTarget", errors: [] }])
        }
      />
    </>
  );
};
