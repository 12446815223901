import API from "services";
import { useQuery } from "react-query";
import { orderBy } from "lodash";
import { IGetQCHistoryLogsResult } from "shared/types/adReview";

export const qcHistoryLogsKey = "qcHistoryLogs";

const getQCHistoryLogs = (id: string) => async () => {
  const { result, error } = await API.services.adReview.getQCHistoryLogs({
    id,
  });
  if (error || !result) {
    throw Error(error?.message || "Something went wrong");
  }
  return result;
};

export const useFetchQCHistoryLogs = (id: string) => {
  const result = useQuery<IGetQCHistoryLogsResult, Error>(
    [qcHistoryLogsKey, id],
    getQCHistoryLogs(id),
    {
      staleTime: 1000 * 60 * 5, // consider fresh for 5 minutes
    },
  );

  const qcHistoryLogs = orderBy(
    result.data?.qcHistoryLogs ?? [],
    ["createdAt"],
    "desc",
  );

  return {
    ...result,
    qcHistoryLogs,
  };
};
