import API from "services";

import { useMutation, useQueryClient, InfiniteData } from "react-query";

import { IComment } from "shared/types/shared";
import {
  IGetCommentsResult,
  IModifyCommentResult,
} from "shared/types/adLibrary";

const deleteComment = async (comment: IComment) => {
  const { result, error } = await API.services.adLibrary.modifyComment(
    comment,
    "delete",
  );

  if (error) {
    throw Error(error.message);
  }

  return result;
};

const useDeleteComment = () => {
  const queryClient = useQueryClient();
  return useMutation<
    IModifyCommentResult | null,
    Error,
    IComment,
    { previousComments?: InfiniteData<IGetCommentsResult> }
  >(deleteComment, {
    onError: (_err, comment) => {
      queryClient.invalidateQueries(["commentsPages", comment.objectId]);
    },
    onSuccess: res => {
      queryClient.invalidateQueries(["commentsPages", res?.comment.objectId]);
    },
  });
};

export default useDeleteComment;
