import { IAccount } from "shared/types/accountManagement";
import { IArtboard, ITemplate, ITemplateTag } from "shared/types/designStudio";
import { IBrandRecord } from "shared/types/brandManagement";
import { BgImage, IFileList, ImgFiles } from "./types";
import { OperationMode } from "shared/types/inputValues";
import { RcFile } from "antd/lib/upload";
import { IUploadImage } from "shared/types/uploadManagement";
import API from "services";
import uuid from "uuid";

export const defaultArtboard = {
  name: "",
  asset_type: "",
  width: 0,
  height: 0,
  created_at: null,
  updated_at: null,
};

export const defaultImage = {
  type: "theme_bg",
  width: 0,
  height: 0,
  name: "",
  createdBy: "",
  brands: [],
  accounts: [],
  tags: [],
  id: "",
  url: "",
  url25: "",
  url50: "",
  createdAt: 0,
};

export const mapBgImageToIFile = (
  bgImage: BgImage,
  iFile: ImgFiles,
): ImgFiles => ({
  uid: iFile.uid,
  thumbUrl: iFile.thumbUrl,
  ...bgImage,
});

type FilterDataProps = {
  brandRecords: IBrandRecord[];
  accounts: IAccount[];
  tags: ITemplateTag[];
  searchBy: string;
};

export const stripExt = (str: string) => str.replace(/\.[^/.]+$/, "");

export const filterData = ({
  brandRecords,
  accounts,
  tags,
  searchBy,
}: FilterDataProps) => {
  if (searchBy.toLowerCase().trim() === "") {
    return {
      filteredOems: brandRecords,
      filteredStores: accounts,
      filteredTags: tags,
    };
  } else {
    const filteredOems = brandRecords.filter(brand =>
      brand.oemName.toLowerCase().includes(searchBy.toLowerCase()),
    );
    const filteredStores = accounts.filter(account =>
      account.dealer_name.toLowerCase().includes(searchBy.toLowerCase()),
    );
    const filteredTags = tags.filter(tag =>
      tag.name.toLowerCase().includes(searchBy.toLowerCase()),
    );
    return { filteredOems, filteredStores, filteredTags };
  }
};

export const prependItem = (value: string, list: string[]) => [...list, value];

export const filterHelper = (removeOption: string, list: string[]) =>
  list.filter((oem: string) => oem !== removeOption);

type CalcTemplateTypes = {
  mode: OperationMode;
  templates: ITemplate[];
  artboardToEdit?: IArtboard | null;
};

export const calcTemplateCount = ({
  mode,
  templates,
  artboardToEdit,
}: CalcTemplateTypes) => {
  if (mode !== "UPDATE" || templates.length < 1 || !artboardToEdit) return 0;
  return templates.filter(
    template => template.artboard.name === artboardToEdit.name,
  ).length;
};

const getImageUri = (file: RcFile) =>
  new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = progressEvent => {
      const dataUri = progressEvent.target?.result;
      if (dataUri) {
        resolve(dataUri as string);
      } else {
        reject("No dataUri found");
      }
    };
    fileReader.readAsDataURL(file);
  });

export const isMissingFields = (mode: string, artboard: IArtboard) => {
  return (
    (mode === "CREATE" && !artboard.name.trim()) ||
    artboard.asset_type.trim() === "" ||
    !artboard.width ||
    artboard.width <= 0 ||
    !artboard.height ||
    artboard.height <= 0
  );
};

export const isNameDuplicated = (
  mode: string,
  existingArtboards: IArtboard[],
  currentArboard: IArtboard,
) => {
  return (
    mode === "CREATE" &&
    existingArtboards.find(
      existingArtboard => existingArtboard.name === currentArboard.name,
    )
  );
};

type ElDimension = { elWidth: number; elHeight: number };

const getVideoDimensions = (file: RcFile) => {
  return new Promise<ElDimension>(async resolve => {
    const videoElement = document.createElement("video");
    videoElement.onloadedmetadata = () => {
      resolve({
        elWidth: videoElement.videoWidth,
        elHeight: videoElement.videoHeight,
      });
    };
    videoElement.src = await getImageUri(file);
  });
};

const getImageDimensions = (file: RcFile) => {
  return new Promise<ElDimension>(async resolve => {
    const imgElement = document.createElement("img");
    imgElement.onload = () => {
      resolve({
        elWidth: imgElement.width,
        elHeight: imgElement.height,
      });
    };
    imgElement.src = await getImageUri(file);
  });
};

type ElDimensionType = {
  file: RcFile;
  fileType: "img" | "video";
};
const getElDimensions = ({ file, fileType }: ElDimensionType) => {
  if (fileType === "video") return getVideoDimensions(file);
  return getImageDimensions(file);
};

type FileAttrCheck = {
  file?: RcFile;
  width: number;
  height: number;
};

type FileAttrs = {
  isValid: boolean;
  width: number;
  height: number;
};

export const getFileAttrs = async ({ file, width, height }: FileAttrCheck) =>
  new Promise<FileAttrs>(async (resolve, reject) => {
    try {
      if (file) {
        const fileType = file.type.includes("video") ? "video" : "img";
        const { elWidth, elHeight } = await getElDimensions({ file, fileType });
        resolve({
          isValid: elWidth === width && elHeight === height,
          width: elWidth,
          height: elHeight,
        });
      } else {
        reject("File is required");
      }
    } catch (error) {
      reject(error);
    }
  });

type ImageUploadAttrs = {
  file: IFileList;
  width: number;
  height: number;
  email?: string;
};

export type ImageUploadBody = {
  bgImage: {
    type: string;
    createdBy?: string;
    width: number;
    height: number;
    name: string;
    oems: string[];
    tags: string[];
    stores: string[];
  };
  imageBase64: string;
  imageType: string;
  videoSrc: string;
};

export const getImageBody = async ({
  file,
  width,
  height,
  email,
}: ImageUploadAttrs): Promise<ImageUploadBody> => {
  const imageUri = await getImageUri(file.originFileObj);
  const imageType = file.originFileObj.type.split("/")[1];
  const imgRegex = /^data:image\/(jpg|jpeg|png);base64,/;
  const imageBase64 = imageUri.replace(imgRegex, "");
  const isVideo = file.originFileObj.type.includes("video");
  return {
    bgImage: {
      type: "theme_bg",
      createdBy: email,
      width,
      height,
      name: file.name,
      oems: file.brands,
      tags: file.tags,
      stores: file.accounts,
    },
    imageBase64: isVideo ? "" : imageBase64,
    imageType,
    videoSrc: file.videoSrc ?? "",
  };
};

export const uploadVideo = async (file: IFileList) => {
  const imageUri = await getImageUri(file.originFileObj);
  const videoParams: IUploadImage = {
    imageData: imageUri,
    filename: `${uuid()}.${file.originFileObj.type.split("/")[1]}`,
    fileType: file.type,
    featureName: "design-studio",
  };
  return API.services.designStudio.uploadVideo(videoParams);
};
