import { format } from "date-fns";
import moment from "moment";
import { IDataListColumns } from "shared/components/dataList";
import { dateFormat } from "shared/constants/dataManagement";
import { IPublicDashboard } from "shared/types/dashboardManagement";
import { checkFilterMatch, compareString } from "utils/helpers";

export const columns: IDataListColumns<IPublicDashboard> = {
  dashboard: {
    filterFn: (value, record) => {
      return checkFilterMatch(
        value?.toString(),
        record.dashboard?.title?.toString(),
      );
    },
    sorterFn(a: IPublicDashboard, b: IPublicDashboard) {
      return compareString(a.dashboard?.title, b.dashboard?.title);
    },
  },
  link: {
    filterFn: (value, record) => {
      return checkFilterMatch(value?.toString(), record.id?.toString());
    },
    sorterFn(a: IPublicDashboard, b: IPublicDashboard) {
      return compareString(a.id, b.id);
    },
  },
  password: {
    filterFn: (value, record) => {
      return checkFilterMatch(
        value?.toString(),
        record.isProtected ? "yes" : "no",
      );
    },
    sorterFn(a: IPublicDashboard, b: IPublicDashboard) {
      return compareString(
        a.isProtected ? "yes" : "no",
        b.isProtected ? "yes" : "no",
      );
    },
  },
  expirationDate: {
    filterFn: (value, record) => {
      return record.expirationDate
        ? value === moment(record.expirationDate).format(dateFormat)
        : false;
    },
    sorterFn(a: IPublicDashboard, b: IPublicDashboard) {
      if (!a.expirationDate || !b.expirationDate) return 0;
      return a.expirationDate - b.expirationDate;
    },
    display(value) {
      return value ? moment(value).format(dateFormat) : "";
    },
  },
  createdAt: {
    filterFn: (value, record) => {
      return record.createdAt
        ? value === moment(record.createdAt).format(dateFormat)
        : false;
    },
    sorterFn(a: IPublicDashboard, b: IPublicDashboard) {
      return (a.createdAt ?? 0) - (b.createdAt ?? 0);
    },
    display(value) {
      return format(value, dateFormat);
    },
  },
  oem: {
    filterFn: (value, record) => {
      return checkFilterMatch(value?.toString(), record.oem?.toString());
    },
    sorterFn(a: IPublicDashboard, b: IPublicDashboard) {
      return compareString(a.oem, b.oem);
    },
  },
  account: {
    filterFn: (value, record) => {
      return checkFilterMatch(value?.toString(), record.account?.toString());
    },
    sorterFn(a: IPublicDashboard, b: IPublicDashboard) {
      return compareString(a.account, b.account);
    },
  },
  campaign: {
    filterFn: (value, record) => {
      return checkFilterMatch(
        value?.toString(),
        record.specialCampaign?.toString(),
      );
    },
    sorterFn(a: IPublicDashboard, b: IPublicDashboard) {
      return compareString(a.specialCampaign, b.specialCampaign);
    },
  },
  createdBy: {
    filterFn: (value, record) => {
      return checkFilterMatch(value?.toString(), record.createdBy?.toString());
    },
    sorterFn(a: IPublicDashboard, b: IPublicDashboard) {
      return compareString(a.createdBy ?? "", b.createdBy ?? "");
    },
  },
  date: {
    filterFn: (value, record) => {
      if (!record.dateValueSelection) return false;
      const parsed = JSON.parse(record.dateValueSelection);
      return checkFilterMatch(
        value?.toString(),
        parsed["inputValue"].toString(),
      );
    },
    sorterFn(a: IPublicDashboard, b: IPublicDashboard) {
      const parsedA = a.dateValueSelection
        ? JSON.parse(a.dateValueSelection)
        : "";
      const parsedB = b.dateValueSelection
        ? JSON.parse(b.dateValueSelection)
        : "";
      return compareString(parsedA["inputValue"], parsedB["inputValue"]);
    },
  },
};
