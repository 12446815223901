import { message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import API from "services";
import { IAssetGroup } from "shared/types/adLibrary";

export default () => {
  const queryClient = useQueryClient();
  const deleteKey = "delete_asset_group";

  const { mutateAsync: deleteAssetGroup } = useMutation(
    (assetGroupId: string) =>
      API.services.adLibrary.deleteAssetGroup(assetGroupId),
    {
      onSettled(data, error, deletedAssetGroupId) {
        if (!error && !data?.error) {
          queryClient.setQueryData<IAssetGroup[] | undefined>(
            "asset-groups",
            prevData =>
              prevData?.filter(
                assetGroup => assetGroup.id !== deletedAssetGroupId,
              ),
          );
        }
      },
    },
  );

  return useMutation(
    async (assetGroupsToDelete: IAssetGroup[]) => {
      message.loading({ key: deleteKey, content: "Deleting..." });

      return Promise.allSettled(
        assetGroupsToDelete.map(({ id }) => deleteAssetGroup(id)),
      );
    },
    {
      onSettled(data, error) {
        const rejectedPromises = data?.filter(p => p.status === "rejected");

        if (error || rejectedPromises?.length) {
          message.error("Error deleting asset groups");
        }

        queryClient.invalidateQueries("asset-groups");
        message.destroy(deleteKey);
      },
    },
  );
};
