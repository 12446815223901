import { Select, Checkbox, type SelectProps } from "antd";
import type { SelectValue, LabeledValue } from "antd/lib/select";
import type { OptionsType, OptionData } from "rc-select/lib/interface";
import { useMemo } from "react";
import { compareStringBy } from "utils/helpers.table";

const { Option } = Select;

interface Props<VT extends SelectValue = SelectValue> extends SelectProps<VT> {
  /**
   * Property to sort options and filters
   * @default "label"
   */
  sortBy?: string;
  /**
   * Property to filter options by
   * @default "label"
   */
  filterBy?: string;

  /** Use `options` instead */
  children?: never;
  options?: OptionsType;
}

export const SortedCheckSelect = <VT extends SelectValue = SelectValue>(
  props: Props<VT>,
) => {
  const {
    sortBy = "label",
    filterBy = "label",
    options = [],
    value,
    onChange,
    ...restProps
  } = props;

  const sortedOptions = useMemo(
    () => options.slice().sort(compareStringBy(sortBy)),
    [options, sortBy],
  );

  const handleOptionClick = (checkedValue: OptionData["value"]) => {
    let newValue: VT;

    if (Array.isArray(value)) {
      const arrayValue = value;

      const isAlreadySelected = arrayValue.some((val: OptionData["value"]) =>
        typeof val === "object" && "value" in val
          ? (val as LabeledValue).value === checkedValue
          : val === checkedValue,
      );

      newValue = isAlreadySelected
        ? (arrayValue.filter((val: OptionData["value"]) => {
            if (typeof val === "object" && "value" in val) {
              return (val as LabeledValue).value !== checkedValue;
            }
            return val !== checkedValue;
          }) as VT)
        : ([...arrayValue, checkedValue] as VT);
    } else {
      newValue = [checkedValue] as VT;
    }

    onChange?.(newValue, []);
  };

  return (
    <Select<VT>
      mode="multiple"
      style={{ width: "100%" }}
      placeholder="Please select"
      showSearch
      filterOption={(input, option) => {
        const optionLabel = option?.[filterBy] || option?.value;
        return optionLabel.toLowerCase().includes(input.toLowerCase());
      }}
      optionLabelProp="label"
      value={value}
      onChange={onChange}
      {...restProps}
    >
      {sortedOptions.map(option => {
        const isChecked = Array.isArray(value)
          ? value.some(val =>
              typeof val === "object" && "value" in val
                ? (val as LabeledValue).value === option.value
                : val === option.value,
            )
          : false;

        return (
          <Option
            key={option.value as string}
            value={option.value}
            label={option.label}
            disabled={option.disabled}
            onClick={() => handleOptionClick(option.value)}
          >
            <Checkbox
              checked={isChecked}
              disabled={option.disabled}
              onClick={e => e.stopPropagation()}
            >
              {option.label}{" "}
              {!isChecked && option?.count !== undefined ? (
                <span>({option.count})</span>
              ) : null}
            </Checkbox>
          </Option>
        );
      })}
    </Select>
  );
};
