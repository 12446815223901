import { IAd } from "shared/types/adLibrary";
import { AdStatusMap, CampaignAdStatus } from "shared/types/campaignPlanner";

export type CampaignPlannerInstanceAd = IAd & {
  source: string;
  campaignStatus: CampaignAdStatus;
};

export type CampaignPlanner = {
  name: string;
  id: string;
  adShells: IAd[];
  updatedAt: string;
  createdAt: string;
  createdBy: string;
  updatedBy: string;
  condition: CampaignPlannerCondition;
  status: CampaignPlannerStatus;
  instances: CampaignPlannerInstance[];
};

export type ApiResponseCampaignPlanner = Omit<
  CampaignPlanner,
  "adShells" | "instances"
> & {
  adShellIds: string[];
  instances: ApiResponseCampaignPlannerInstance[];
};

export type ApiResponseCampaignPlannerInstance = Omit<
  CampaignPlannerInstance,
  "adShells"
> & {
  adShellIds: string[];
  plannerId: string;
};

export type CampaignPlannerList = CampaignPlanner[];

export type CampaignPlannerInstance = {
  name: string;
  id: string;
  adShells: CampaignPlannerInstanceAd[];
  adStatuses: AdStatusMap;
  updatedAt: string;
  createdAt: string;
  updatedBy: string;
  createdBy: string;
  status: CampaignPlannerInstanceStatus;
  condition: CampaignPlannerCondition;
};

export enum CampaignPlannerStatus {
  DRAFT = "DRAFT",
  AWAITING_APPROVAL = "AWAITING_APPROVAL",
  INTERNALLY_APPROVED = "INTERNALLY_APPROVED",
  DISAPPROVED = "DISAPPROVED",
  HAS_REQUESTS = "HAS_REQUESTS",
}

export enum CampaignPlannerInstanceStatus {
  INTERNALLY_APPROVED = "INTERNALLY_APPROVED",
  HAS_REQUESTS = "HAS_REQUESTS",
}

export enum CampaignPlannerCondition {
  ACTIVE = "ACTIVE",
  IN_PROGRESS = "IN_PROGRESS",
  EXPIRED = "EXPIRED",
}

export type UpdatePlannerInstanceAdRequestBody = {
  adId: string;
  instanceId: string;
  plannerId: string;
  campaignStatus: CampaignAdStatus;
};
