import { Form, Radio } from "antd";
import { memo, useMemo } from "react";
import InstantExperienceSelect from "shared/components/InstantExperienceSelect";
import LabelSelect from "shared/components/LabelSelect";
import { useFetchLabels } from "shared/hooks/useFetchLabels";
import { inputWidths } from "../../InstantExperience";

export type DestinationType = "website" | "instantExperience";

export interface IDestinationValues {
  urlLabel?: string;
  instantExperienceId?: string;
}

interface IProps {
  destinationValues?: IDestinationValues;
  onChange?(destinationValues: IDestinationValues): void;
  destination: DestinationType;
  setDestination(destination: DestinationType): void;
  selectedBrand?: string;
  setSelectedBrand(selectedBrand: string): void;
  required?: boolean;
  shouldValidate?: boolean;
  disabled?: boolean;
  showAsterisk?: boolean;
}

const Destination = ({
  destination,
  setDestination,
  selectedBrand,
  setSelectedBrand,
  destinationValues,
  onChange,
  required,
  shouldValidate,
  disabled,
  showAsterisk,
}: IProps) => {
  const { data, isLoading } = useFetchLabels();
  const labelNames = data?.labels.map(label => label.name) || [];

  const isEmptyWebsite = !destinationValues?.urlLabel;
  const isLabel = labelNames.includes(destinationValues?.urlLabel || "");
  const isUrl = /^https?:\/\//.test(destinationValues?.urlLabel || "");

  let hasDestinationWebsiteError = false;

  if (shouldValidate && required && isEmptyWebsite) {
    hasDestinationWebsiteError = true;
  }
  if (shouldValidate && !isEmptyWebsite && !isUrl && !isLoading && !isLabel) {
    hasDestinationWebsiteError = true;
  }

  const label = useMemo(() => {
    return required && showAsterisk ? (
      <div>
        Destination
        <span style={{ color: "#f44336" }}>*</span>
      </div>
    ) : (
      "Destination"
    );
  }, [required, showAsterisk]);

  return (
    <>
      <Form.Item label={label} required={true}>
        <Radio.Group
          value={destination}
          onChange={e => {
            setDestination(e.target.value);
            onChange?.({});
          }}
          disabled={disabled}
        >
          <Radio.Button value="website">Website</Radio.Button>
          <Radio.Button value="instantExperience">Everything Ad</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {destination === "website" && (
        <Form.Item
          style={{ width: inputWidths.minWidth, marginBottom: 0 }}
          validateStatus={hasDestinationWebsiteError ? "error" : undefined}
          help={hasDestinationWebsiteError ? "Destination is required" : ""}
        >
          <LabelSelect
            allowFreeText
            placeholder="Select a label or type free-form URL"
            allowInsert={false}
            currentValue={destinationValues?.urlLabel}
            onLabelChange={(labelValue: string) => {
              onChange?.({
                urlLabel: labelValue,
              });
            }}
            disabled={disabled}
          />
        </Form.Item>
      )}
      {destination === "instantExperience" && (
        <InstantExperienceSelect
          onChange={onChange}
          destination={destinationValues}
          selectedBrand={selectedBrand}
          setSelectedBrand={setSelectedBrand}
          shouldValidate={shouldValidate}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default memo(Destination);
