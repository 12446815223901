import {
  FeedConfigState,
  TRawData,
} from "shared/types/assetExporter";

export function getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
  return o[propertyName];
}

export const feedRawToFeedConfig = (feed: TRawData): FeedConfigState => {
  const response: FeedConfigState = {
    columns: feed.columns,
    columnsToExport: feed.orders,
    feedRows: feed.rows,
    imageInput: feed.imageInputColumn,
    imageOutput: feed.imageOutputColumn,
    orderedColumns: feed.orders,
    videoInput: feed.videoInputColumn,
    videoEndInput: feed.videoEndInputColumn,
    videoOutput: feed.videoOutputColumn,
    videoFrameStart: feed.videoFrameStart,
    videoFrameEnd: feed.videoFrameEnd,
    videoFrameRate: feed.videoFrameRate,
  };

  return response;
};
