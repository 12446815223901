import { useColumn } from "../useColumn";

export const useColDimensions = () => {
  return useColumn("dimensions", {
    title: "Dimensions",
    renderFn(text, { type, width, height }) {
      return <span>{type === "asset" ? `${width}x${height}` : null}</span>;
    },
  });
};
