import { Button, Row } from "antd";

type Props = {
  onDelete?: () => void;
  onCancel: () => void;
  onSave: () => void;
};

export const DrawerFooter = ({ onDelete, onCancel, onSave }: Props) => {
  return (
    <Row justify="end" gutter={16}>
      {onDelete && (
        <Button danger onClick={onDelete}>
          Delete
        </Button>
      )}
      <Button style={{ marginRight: 8 }} onClick={onCancel}>
        Cancel
      </Button>
      <Button type="primary" onClick={onSave}>
        Save
      </Button>
    </Row>
  );
};
