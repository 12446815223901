import { Modal, Checkbox, Button, Row, Col, Divider } from "antd";
import style from "./ColumnsFilter.module.scss";

interface IFilterProps {
  displayColumn: boolean;
  selectedColumns: string[];
  columnNames: string[];
}

interface IFilterHandlers {
  setSelectedColumns: (data: string[]) => void;
  setDisplayColumn: (displayColumn: boolean) => void;
}

type TColumnFilters = IFilterProps & IFilterHandlers;

export const ColumnsFilter = ({
  displayColumn,
  selectedColumns,
  columnNames,
  setSelectedColumns,
  setDisplayColumn,
}: TColumnFilters) => {
  const isSelectedAll = columnNames.length === selectedColumns.length;

  return (
    <Modal
      className={style.modal}
      title="Show / Hide Columns"
      width="auto"
      onCancel={() => {
        setDisplayColumn(false);
      }}
      visible={displayColumn}
      footer={
        <div className={style.footer}>
          <Checkbox
            className={style.selectAll}
            key="selectAll"
            checked={isSelectedAll}
            onClick={() => setSelectedColumns(isSelectedAll ? [] : columnNames)}
          >
            Select All
          </Checkbox>
          <Button
            disabled={
              selectedColumns.length === columnNames.length ||
              selectedColumns.length === 0
            }
            key="ok"
            type="primary"
            onClick={() => {
              setDisplayColumn(false);
            }}
          >
            OK
          </Button>
        </div>
      }
    >
      <Col>
        <Row className={style.disclaimer}>
          Columns shown are unique to each feed.
        </Row>
        <Checkbox.Group
          defaultValue={selectedColumns}
          value={selectedColumns}
          onChange={newColumns => {
            setSelectedColumns(newColumns as string[]);
          }}
          className={style.checkboxGroup}
        >
          <Row className={style.container} align="top">
            {columnNames.map((name, index) => (
              <>
                <Checkbox
                  style={{ width: "45%", marginLeft: "8px" }}
                  key={name}
                  value={name}
                  checked={selectedColumns.includes(name)}
                >
                  {name}
                </Checkbox>
                {(index + 1) % 2 === 1 && (
                  <Divider type="vertical" className={style.divider} />
                )}
              </>
            ))}
          </Row>
        </Checkbox.Group>
      </Col>
    </Modal>
  );
};
