import { useMutation, useQueryClient } from "react-query";
import { NewAssetGroupArgs } from "shared/types/adLibrary";
import API from "services";
import { useUser } from "shared/hooks/useUser";

export const useCreateNewAssetGroup = () => {
  const user = useUser();
  const queryClient = useQueryClient();

  return useMutation(
    (args: Omit<NewAssetGroupArgs, "user">) => {
      if (!user) return Promise.reject("User not found");
      if (args.mode === "new")
        return API.services.adLibrary.createAssetGroup({
          ...args,
          user,
        });

      const newAssetGroupName = `${args.assetGroup.name} (COPY)`;

      return API.services.adLibrary.createAssetGroup({
        ...args,
        user,
        assetGroup: {
          ...args.assetGroup,
          id: `manual_${newAssetGroupName}`,
          name: newAssetGroupName,
        },
      });
    },
    {
      onSettled() {
        queryClient.invalidateQueries("asset-groups");
      },
    },
  );
};
