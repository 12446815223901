/* eslint-disable react/display-name */
import {
  CopyOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  FieldTimeOutlined,
  FlagFilled,
} from "@ant-design/icons";
import {
  Checkbox,
  Col,
  Collapse,
  Form,
  message,
  Modal,
  Row,
  Tag,
  Tooltip,
} from "antd";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import API from "services";
import { floatValueFields } from "shared/constants/assetBuilder";
import { defaultOfferIndex } from "shared/constants/dataManagement";
import { dynamicPaymentEngineFields } from "shared/constants/paymentEngine";
import { useFetchRawOffer } from "shared/hooks/assetBuilder/useFetchRawOffer";
import {
  AprOfferKey,
  checkedOfferFilterKeys,
  EditedFieldId,
  FeedOffer,
  ICheckedOfferFilter,
  IOfferDataInputProps,
  IOfferDataProps,
  ISavedOrderState,
  isEditableField,
  ISortingOption,
  OfferData,
  OfferDataField,
  OfferDataRow,
  OfferEditFieldObject,
  OfferEditFromService,
  OfferEditTracker,
  OfferOperationMode,
  OfferValidationErrors,
  RawOfferData,
  RawSelectedOffers,
  RepeatableOfferKeys,
  SingletonOfferKeys,
} from "shared/types/assetBuilder";
import { FeedType } from "shared/types/configuration";
import { IApiResponse, OfferType } from "shared/types/shared";
import { ReactComponent as SoldIcn } from "statics/images/svg/icons/sold-icon.svg";
import {
  convertEpochTimeToFullDateString,
  formatDateValue,
  timezoneOffset,
} from "utils/helpers";
import {
  calculateAprPayment,
  formatOfferDataFieldValue,
  formatRateAsPercentage,
  repeatableFieldKeys,
  returnRepeatableTitle,
} from "utils/helpers.offer";
import "./OfferForm.scss";
import { OfferFormSortingOptions } from "./offerForm/OfferFormSortingOptions";
import { formSchema, internalFormSchema } from "./offerForm/schema";
import { getFieldKeyId } from "screens/assetBuilder/utils";

interface IOfferFormPropsBase {
  feedId?: string;
  feed: FeedType;
  offerData: FeedOffer;
  sortingOptions?: ISortingOption[];
  editedKeys?: Record<string, boolean>;
  enablePaymentEngine: boolean;
  checkedOfferFilters: ICheckedOfferFilter;
  fromNewOfferForm?: boolean;
  saveOffer?: (
    useUploadedJellyBean: boolean,
    type: "create" | "update",
    revertingOffer?: boolean,
    feedId?: string,
    resetOffer?: RawOfferData,
  ) => Promise<void>;
  orderId?: string;
  keyId: string;
  timestamp?: number;
  activeVins?: string[];
  onActiveCollapseChange?: (activeVins: string[]) => void;
  savedOrder?: ISavedOrderState | null;

  currentOfferEdit?: OfferEditFromService | null;
  offerEditTrackerObject?: OfferEditTracker | null;
  editing?: Set<string>;
  setEditing?: (editing: Set<string>) => void;
  setEditOfferData: (
    data: OfferData,
    editedKeys: Record<string, boolean>,
    operation?: OfferOperationMode,
  ) => void;
  offerOperationType?: "create" | "update";
  setDuplicating?: (input: boolean) => void;
  setIsDuplicateMode?: (input: boolean) => void;
  isAvailableOffer?: boolean;
  setResetingToMasterData?: (input: boolean) => void;
  commitOrder?: () => Promise<void>;
  disabled?: boolean;
  selected?: boolean;
  checkOfferStillValid?: (offerVin: string) => Promise<boolean>;
  setRawOfferUpdated?: (input: boolean) => void;
  fetchOrderState?: () => void;
}

type OfferFormProps = IOfferFormPropsBase &
  (
    | {
        editMode: true;
        offerValidationErrors: OfferValidationErrors;
        editField: <T extends SingletonOfferKeys | RepeatableOfferKeys>(
          key: T,
          value: RawOfferData[T],
          fieldIdentifier?: EditedFieldId,
        ) => void;
        togglePaymentEngineModal?: (
          initialSelectedRow: OfferEditFieldObject | null,
        ) => void;
      }
    | {
        editMode: false;
        toggleOffer: (offerType: OfferType, offerData: OfferData) => void;
        rawSelectedOffers: RawSelectedOffers;
        commitOrder?: () => Promise<void>;
      }
  );

export const OfferForm = (props: OfferFormProps) => {
  const {
    feedId,
    feed,
    offerData,
    editMode,
    editedKeys,
    editing,
    setEditing,
    checkedOfferFilters,
    fromNewOfferForm,
    enablePaymentEngine,
    keyId,
    timestamp,
    currentOfferEdit = null,
    offerEditTrackerObject = null,
    sortingOptions,
    savedOrder,
    selected,
    checkOfferStillValid,
    setRawOfferUpdated,
    fetchOrderState,
  } = props;
  const formSchemaToUse = feed === "internal" ? internalFormSchema : formSchema;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { data: rawOffer } = useFetchRawOffer(offerData.vin);

  const updateLastUpdated = async (isOn: boolean) => {
    if (offerData && props.saveOffer && isOn) {
      props.setEditOfferData(offerData, {});
      await props.saveOffer(false, "update", undefined, feedId);
    }
  };

  const [soldOffer, setSoldOffer] = useState<boolean>(false);

  const onSelectedData = useCallback(async () => {
    if (selected && checkOfferStillValid && !soldOffer) {
      const result = await checkOfferStillValid(offerData.vin);

      setSoldOffer(!result);
    }
  }, [selected, checkOfferStillValid, soldOffer, offerData.vin]);

  useEffect(() => {
    onSelectedData();
  }, [onSelectedData]);

  const renderField =
    (repeatIndex: number, sectionTitle?: string) =>
    (field: OfferDataField<keyof OfferData>) => {
      // transactabilityScore is a field that gets updated on all offer types when edited
      const fieldKeyId = getFieldKeyId(
        field.key,
        repeatIndex,
        field.key === "transactabilityScore" ? undefined : sectionTitle,
      );
      const notDisplayVin = field.key === "vin" && offerData.vin.length > 17;
      const isVinless =
        field.key === "vin" &&
        offerData.isVinless &&
        (!props.offerOperationType || props.offerOperationType === "update");

      if (isEditableField(field) && props.editMode) {
        const { offerValidationErrors, editField } = props;
        const { key, title, Component, Input, colspan, help } = field;
        const edited =
          offerEditTrackerObject?.[key]?.includes(true) ||
          !!offerEditTrackerObject?.[key]?.find(
            editedKey => editedKey === fieldKeyId,
          );
        const isDisclosureField =
          key.toLowerCase().includes("disclosure") &&
          !key.startsWith("additional");
        let className = colspan === 2 ? "colspan-2" : "";

        if (isDisclosureField) {
          className = className.concat("disclosure-data");
        }

        const isArray = repeatableFieldKeys.includes(key);
        const defaultValue = isArray ? [""] : "";
        const valueToUse = isArray
          ? offerData[key]
          : offerData[key]?.toString();

        const value =
          offerData === null || isVinless ? defaultValue : valueToUse;

        const error: string[] | string =
          offerValidationErrors && offerValidationErrors[key];

        const singledValue = Array.isArray(value) ? value[repeatIndex] : value;
        let renderValue = editing?.has(fieldKeyId)
          ? singledValue
          : formatOfferDataFieldValue(key, singledValue);

        const renderError: string = Array.isArray(error)
          ? error[repeatIndex]
          : error;
        if (key === "dateInStock") {
          renderValue = formatDateValue(value as string);
        }

        const displayTitle = returnRepeatableTitle(title, repeatIndex);

        const originalValueToUse = rawOffer
          ? rawOffer[key as SingletonOfferKeys]
          : "";

        const cleanedOriginalValue = formatOfferDataFieldValue(
          key,
          originalValueToUse?.toString() || "",
        );
        // Disabled VIN editing of records. when keyId="_ID", a new offer is being created
        const disableVinInput = key === "vin" && keyId !== "_ID";

        const componentProps: IOfferDataProps & { key: string } = {
          key,
          edited,
          className,
          title: displayTitle,
          value: notDisplayVin ? "" : renderValue,
          originalValue: cleanedOriginalValue,
        };

        const componentToRender = <Component {...componentProps} />;

        const handleAprPaymentUpdate = (key: AprOfferKey, value: string) => {
          const offer = {
            row: {
              aprRate: offerData["aprRate"],
              aprTerm: offerData["aprTerm"],
              aprAmntFinanced: offerData["aprAmntFinanced"],
            },
          };
          offer.row[key] = value;
          editField("aprPayment", calculateAprPayment(offer), fieldKeyId);
        };

        let isInvalidValue = false;
        if (floatValueFields.includes(key)) {
          const regex = /^\$?\d{1,9}(,\d{1,5})*(\.\d{1,2})?$/;
          // This regex returns true if value is a number or float
          if (!regex.test(renderValue)) {
            isInvalidValue = true;
          }
        }

        const inputProps: IOfferDataInputProps = {
          ...componentProps,
          help,
          field: key,
          enablePaymentEngine,
          value: renderValue,
          error: renderError,
          savedEditedValue: currentOfferEdit?.[key as SingletonOfferKeys],
          valueChanged: edited,
          togglePaymentEngineModal: props.togglePaymentEngineModal,
          isPaymentEngineField:
            feed === "ladtech" &&
            dynamicPaymentEngineFields.includes(key) &&
            title !== "Sales Price",
        };

        const inputToRender = (
          <div
            style={{
              border:
                editMode && isInvalidValue && renderValue
                  ? "1px solid red"
                  : undefined,
              padding:
                editMode && isInvalidValue && renderValue ? "10px" : undefined,
            }}
          >
            <Input
              {...inputProps}
              onChange={newValue => {
                if (editing && !editing?.has(key) && setEditing) {
                  editing.add(fieldKeyId);
                  setEditing(editing);
                }
                if (Array.isArray(value)) {
                  const newValues = [...value];
                  newValues[repeatIndex] = newValue;

                  editField(key, newValues, fieldKeyId);
                  return;
                }
                if (["aprRate", "aprTerm", "aprAmntFinanced"].includes(key)) {
                  handleAprPaymentUpdate(key as AprOfferKey, newValue);
                }
                editField(key, newValue, fieldKeyId);
              }}
            />
          </div>
        );

        return (
          <Col
            key={`${key}-col`}
            span={isDisclosureField ? 8 : 5}
            style={isDisclosureField ? { paddingRight: "2.5em" } : {}}
          >
            {!editMode || disableVinInput ? componentToRender : inputToRender}
          </Col>
        );
      } else {
        const { key, title, Component } = field;

        const originalValueToUse = rawOffer
          ? rawOffer[key as SingletonOfferKeys]
          : "";

        let value =
          offerData === null || isVinless ? "" : (offerData[key] as string);

        const isDisclosureField =
          key.toLowerCase().includes("disclosure") &&
          !key.startsWith("additional");
        const isRebateField = key.toLowerCase().includes("rebate");
        const className =
          isDisclosureField && !isRebateField ? "disclosure-data" : "";

        const cleanedValue = isVinless
          ? ""
          : !Array.isArray(value)
          ? formatOfferDataFieldValue(key, offerData[key]?.toString() || "")
          : value;

        const cleanedOriginalValue = formatOfferDataFieldValue(
          key,
          originalValueToUse?.toString() || "",
        );

        const edited = props.isAvailableOffer
          ? !!editedKeys && editedKeys[key]
          : title === "Make"
          ? cleanedOriginalValue.trim().toLowerCase() !==
            cleanedValue.trim().toLowerCase()
          : cleanedOriginalValue?.toString().trim() !==
            cleanedValue?.toString().trim();

        if (key === "daysInStock" && !offerData.daysInStock) {
          const dateInStock = offerData.dateInStock
            ? formatOfferDataFieldValue(
                "dateInStock",
                offerData.dateInStock.toString(),
              )
            : "";
          const inStock = offerData.dateInStock
            ? parseInt(dateInStock, 10) + timezoneOffset
            : 0;
          const diff = Date.now() - inStock;
          value = !inStock ? "N/A" : (diff / (1000 * 60 * 60 * 24)).toFixed(0);
        } else if (
          key === "expirationDate" &&
          offerData.vehicleCondition === "Used"
        ) {
          value = formatDateValue(
            formatOfferDataFieldValue(
              key,
              offerData.expirationDate ||
                savedOrder?.selectedOrder.expiresAt.toString() ||
                "",
            ),
          );
        } else if (key.toLowerCase().includes("date")) {
          value = formatDateValue(cleanedValue);
        } else if (key === "transactabilityScore") {
          value = "N/A";
        } else if (notDisplayVin) {
          value = "";
        } else {
          value = cleanedValue;
        }

        return (
          <Col
            key={`col-${key}`}
            span={isDisclosureField ? 8 : 5}
            style={
              isDisclosureField
                ? { textAlign: "justify", paddingRight: "2.5em" }
                : {}
            }
          >
            {Array.isArray(value) ? (
              value.map((val, index) => (
                <Component
                  title={returnRepeatableTitle(title, index)}
                  value={val}
                  key={`${key}-${index}`}
                  edited={edited}
                  originalValue={cleanedOriginalValue}
                  className={className}
                />
              ))
            ) : (
              <Component
                title={title}
                value={value}
                key={key}
                edited={edited}
                originalValue={cleanedOriginalValue}
                className={className}
              />
            )}
          </Col>
        );
      }
    };

  const renderRows = (
    title: string,
    rows: OfferDataRow[],
    _sectionIndex: number,
    isHeader = false,
  ) =>
    rows.reduce<JSX.Element[]>((renderedRows, row, rowIndex) => {
      const position = isHeader ? "header" : "body";
      const id = row.fields[0]?.title;
      if (!row.repeatable) {
        return [
          ...renderedRows,
          <Row
            className={`offer-data-row ${position}`}
            key={`row_${rowIndex}`}
            gutter={8}
          >
            {(row.fields as any[]).map(renderField(0, title))}
          </Row>,
        ];
      }
      const ret: JSX.Element[] = [];
      const repeats = 5;

      for (let k = 0; k <= (props.editMode ? repeats : 0); k++) {
        ret.push(
          <Row
            className={`offer-data-row ${position}`}
            key={`row_${rowIndex}_${k}_${id}`}
            gutter={8}
          >
            {(row.fields as any[]).map(renderField(k))}
          </Row>,
        );
      }

      return [...renderedRows, ...ret];
    }, []);

  // This will return object in the format below.
  // { <vin>: <div className="tag">{<offer type>}</div>[] }
  const renderTags = (tmpProps: OfferFormProps, vin: string): ReactNode[] => {
    if (tmpProps.editMode) {
      return [];
    }

    // this is an object with vin as its key.
    // 1HGCV1F14LA006646: {offerData: {…}, offerIndex: {…}}
    const { rawSelectedOffers, toggleOffer } = tmpProps;

    // offerIndex is an object in below format.
    // offerIndex: {Lease: true}
    const offerIndex = {
      ...defaultOfferIndex,
      ...(rawSelectedOffers[vin]?.offerIndex || {}),
    };

    if (!offerIndex) {
      return [];
    }
    const allSelected = Object.keys(offerIndex).every(
      offerType => offerIndex[offerType as OfferType],
    );
    const anySelected = Object.keys(offerIndex).some(
      offerType => offerIndex[offerType as OfferType],
    );

    return [
      ...Object.keys(offerIndex).map(offerType => {
        return (
          <Tag
            className={`tag${
              offerIndex[offerType as OfferType] ? "-active" : ""
            }`}
            key={`tag-${vin}-${offerType}`}
            onClick={e => {
              e.stopPropagation();
              toggleOffer(offerType as OfferType, offerData);
              updateLastUpdated(!offerIndex[offerType as OfferType]);
            }}
          >
            {offerType}
          </Tag>
        );
      }),
      <Checkbox
        key={`tag-select-all`}
        className="tag-select-all"
        checked={allSelected}
        indeterminate={!allSelected && anySelected}
        onClick={e => {
          e.stopPropagation();
          if (allSelected) {
            Object.keys(offerIndex).map(offerType => {
              toggleOffer(offerType as OfferType, offerData);
            });
          } else {
            Object.keys(offerIndex)
              .filter(offerType => !offerIndex[offerType as OfferType])
              .map(offerType => {
                toggleOffer(offerType as OfferType, offerData);
              });
            updateLastUpdated(true);
          }
        }}
      >
        Select All
      </Checkbox>,
    ];
  };

  const editActiveKey = fromNewOfferForm ? [keyId] : [offerData.vin];

  return (
    <Form
      layout="vertical"
      style={{
        width: "100%",
      }}
    >
      <Modal
        title="Reset Offer Data"
        visible={openModal}
        onOk={async () => {
          if (props.saveOffer && props.commitOrder) {
            const feed = feedId || "";
            const rowID = offerData.rowIdentifier || "";
            // TODO: fix return type
            const { result, error } =
              await API.services.assetExporter.updateRawOfferById<
                IApiResponse<RawOfferData>
              >(feed, rowID);
            if (result && !error) {
              const resetOffer = result;
              resetOffer.aprRate = formatRateAsPercentage(resetOffer.aprRate);
              resetOffer.financeRate = formatRateAsPercentage(
                resetOffer.financeRate,
              );
              await props.saveOffer(false, "update", true, feed, resetOffer);
              await props.commitOrder();
              fetchOrderState?.();
              setRawOfferUpdated && setRawOfferUpdated(true);
            } else {
              message.error("There is no data in the Feed for this VIN");
            }
          }
          setOpenModal(false);
        }}
        onCancel={() => setOpenModal(false)}
        okText="Reset"
        cancelText="Cancel"
      >
        <p>
          Are you sure you want to reset all edited fields to their default
          values?
        </p>
      </Modal>
      <Collapse
        activeKey={editMode ? editActiveKey : props.activeVins}
        className="select-form-collapse"
        bordered={false}
        expandIconPosition="right"
        onChange={data => {
          props.onActiveCollapseChange?.(data as string[]);
        }}
      >
        <Collapse.Panel
          key={fromNewOfferForm ? keyId : props.offerData.vin}
          header={
            <>
              <div className="offer-heading">
                <span className="offer-heading-ymmt">
                  {soldOffer && (
                    <span style={{ paddingRight: "5px" }}>
                      <SoldIcn />
                    </span>
                  )}
                  <span>
                    {offerData.year
                      ? `${offerData.year} ${offerData.make} ${
                          offerData.model
                        } ${offerData.trim || ""}`
                      : "New Offer"}
                  </span>
                  {"dmsStatusCode" in offerData &&
                    offerData["dmsStatusCode"] === "T" &&
                    !soldOffer && (
                      <span className="offer-heading-in-transit">
                        <Tooltip title="Vehicle In Transit">
                          <b>
                            <i>In Transit</i>
                          </b>
                        </Tooltip>
                      </span>
                    )}
                </span>
                <span className="tag-container">
                  {renderTags(props, offerData.vin)}
                </span>
                {!props.editMode && (
                  <div className="actions-container">
                    {timestamp ? (
                      <span className="last-modified-date">
                        <span className="last-modified-date-header">
                          Last Modified Date:
                        </span>
                        <span>
                          {convertEpochTimeToFullDateString(timestamp)}
                        </span>
                      </span>
                    ) : (
                      <div className="invalid-offer">
                        <ExclamationCircleFilled className="invalid-offer-icon" />
                        <Tooltip
                          title={
                            "Last Modified Date is not available. This can happen if the offer has custom vin."
                          }
                        >
                          {"Last Modified Date is not available"}
                        </Tooltip>
                      </div>
                    )}
                    <FieldTimeOutlined
                      onClick={async e => {
                        e.stopPropagation();
                        setOpenModal(true);
                      }}
                    />
                    <NavLink
                      end
                      to={`/asset-builder/orders/${props.orderId}/offers/edit-offer/${offerData.vin}`}
                    >
                      <EditOutlined
                        className="edit-button"
                        data-cy="edit-button"
                        onClick={async () => {
                          props.setEditOfferData(offerData, editedKeys || {});
                          if (props.commitOrder) {
                            await props.commitOrder();
                          }
                        }}
                      />
                    </NavLink>
                  </div>
                )}
                {/* TO DO: use other means instead of current offerData.vin to show edit icon*/}
                {props.editMode && (
                  <div className="actions-container">
                    {!offerData.vin && (
                      <Tooltip title="Reset to Feed Data">
                        <FlagFilled
                          className="reset-to-feed-btn"
                          style={{
                            cursor: !props.disabled ? "default" : "pointer",
                            color: !props.disabled ? "grey" : "orange",
                          }}
                          onClick={() => {
                            if (!props.disabled) return;

                            props.setResetingToMasterData &&
                              props.setResetingToMasterData(true);
                          }}
                        />
                      </Tooltip>
                    )}
                    {offerData.vin && (
                      <CopyOutlined
                        className="duplicate-button"
                        onClick={() => {
                          if (props.setDuplicating) props.setDuplicating(true);
                          if (props.setIsDuplicateMode)
                            props.setIsDuplicateMode(true);
                        }}
                      />
                    )}
                    {enablePaymentEngine && (
                      <EditOutlined
                        className="edit-button"
                        onClick={() => {
                          return (
                            /* Open 'Lease' dealer science modal as default (when clicking edit icon) */
                            props.togglePaymentEngineModal?.({
                              field: "monthlyPayment",
                              title: "Monthly Payment",
                              value: 0,
                            })
                          );
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
              <OfferFormSortingOptions
                sortingOptions={sortingOptions}
                offerData={props.offerData}
              />
            </>
          }
        >
          <Collapse
            className="offer-detail-list"
            bordered={false}
            expandIconPosition="right"
          >
            {formSchemaToUse.map((section, sectionIndex) => {
              if (
                section.title !== "Vehicle Info" &&
                !checkedOfferFilters[section.title as checkedOfferFilterKeys]
              ) {
                return null;
              }
              return (
                <Collapse.Panel
                  key={`section_${sectionIndex}`}
                  header={
                    <div>
                      <br />
                      <h2 className="section-title">
                        {!props.editMode && section.offerType !== undefined && (
                          <Checkbox
                            checked={
                              props.rawSelectedOffers[offerData.vin] &&
                              props.rawSelectedOffers[offerData.vin].offerIndex[
                                section.offerType
                              ]
                            }
                            onClick={e => {
                              e.stopPropagation();
                              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                              props.toggleOffer(section.offerType!, offerData);
                            }}
                          />
                        )}
                        <span style={{ paddingLeft: "0.3em" }}>
                          {section.title}
                        </span>
                      </h2>
                      <div>
                        {renderRows(
                          section.title,
                          section.header,
                          sectionIndex,
                          true,
                        )}
                      </div>
                    </div>
                  }
                >
                  {renderRows(section.title, section.body, sectionIndex, false)}
                </Collapse.Panel>
              );
            })}
          </Collapse>
        </Collapse.Panel>
      </Collapse>
    </Form>
  );
};
