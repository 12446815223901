import { useCallback, useState } from "react";
import { OnConnectStartFunc, OnConnectStopFunc } from "react-flow-renderer";

export default () => {
  const [isConnecting, setIsConnecting] = useState(false);
  const onConnectStart: OnConnectStartFunc = useCallback(() => {
    setIsConnecting(true);
  }, []);

  const onConnectStop: OnConnectStopFunc = useCallback(() => {
    setIsConnecting(false);
  }, []);

  return {
    isConnecting,
    onConnectStart,
    onConnectStop,
  };
};
