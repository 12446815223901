import { ReactNode } from "react";
import { FacebookAdEffectiveStatus } from "screens/adLibrary/facebookUtils/types";
import { Badge, Space, Typography } from "antd";
import { ReactComponent as RejectIcon } from "statics/images/svg/icons/reject.svg";

interface IProps {
  status: FacebookAdEffectiveStatus;
}

const DeliveryStatusBadge = ({ status }: IProps) => {
  const Active = () => (
    <Space size={4} align="center">
      <Badge color="#52C41A" />
      <span>Active</span>
    </Space>
  );

  const Rejected = () => (
    <Space size={4} align="center">
      <RejectIcon />
      <span>Rejected</span>
    </Space>
  );

  const Off = () => <Typography.Text type="secondary">Off</Typography.Text>;

  const deliveryStatusMessageDic: Partial<
    Record<FacebookAdEffectiveStatus, ReactNode>
  > = {
    ACTIVE: <Active />,
    PAUSED: <Off />,
    DISAPPROVED: <Rejected />,
    CAMPAIGN_PAUSED: <Off />,
    ADSET_PAUSED: <Off />,
    WITH_ISSUES: <Rejected />,
    PENDING_REVIEW: <Typography.Text>Pending</Typography.Text>,
  };

  return (
    <div>
      {!!deliveryStatusMessageDic[status] ? (
        deliveryStatusMessageDic[status]
      ) : (
        <span>Unknown Status</span>
      )}
    </div>
  );
};

export default DeliveryStatusBadge;
