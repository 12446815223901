import moment from "moment";
import { Dispatch, SetStateAction, useState } from "react";
import { dateFormat } from "shared/constants/dataManagement";
import {
  DatePeriod,
  DatePreviousInterval,
  DateValueSelection,
  IDashboard,
  IPublicDashboard,
} from "shared/types/dashboardManagement";

export type DateValueSelectorProps = {
  selection?: DateValueSelection;
  error?: string;
  isValid: boolean;
  updateSelection: (selection: DateValueSelection) => void;
  validateSelection: () => void;
};

export const useDateValueSelector = (
  dashboard?: IDashboard,
  publicDashboard?: IPublicDashboard,
): DateValueSelectorProps => {
  const [selection, setSelection] = useState<DateValueSelection | undefined>(
    getInitialValue(publicDashboard ?? dashboard),
  );
  const [error, setError] = useState<string | undefined>();
  const isValid = error === undefined;
  const updateSelection = (sel: DateValueSelection) => {
    setSelection(getUpdatedDashboard(sel));
  };

  return {
    selection,
    error,
    isValid,
    updateSelection,
    validateSelection: () => validateSelection(selection, setError),
  };
};

const getUpdatedDashboard = (
  sel: DateValueSelection,
): SetStateAction<DateValueSelection | undefined> => {
  return prev => {
    const datePeriod = sel.datePeriod ?? prev?.datePeriod ?? "isPrevious";
    const previousInterval =
      datePeriod === "isPrevious"
        ? sel.previousInterval ?? prev?.previousInterval
        : undefined;
    const dateRange =
      datePeriod === "isInRange" ? sel.dateRange ?? prev?.dateRange : undefined;
    const newVal = {
      datePeriod,
      previousInterval,
      dateRange,
    };
    const inputValue = getInputFieldValue(newVal);
    return { ...newVal, inputValue };
  };
};

type DateValueSelectionParsed = {
  datePeriod?: DatePeriod;
  dateRange?: number[];
  previousInterval?: DatePreviousInterval;
  inputValue?: string;
};

const isPublicDashboard = (
  dashboard: IPublicDashboard | IDashboard | undefined,
): dashboard is IPublicDashboard => {
  return (
    dashboard !== undefined &&
    (dashboard as IPublicDashboard).dashboardId !== undefined
  );
};

const getPublicDashboardInitVal = (dashboard: IPublicDashboard) => {
  if (dashboard.dateValueSelection) {
    const dateValSel = JSON.parse(
      dashboard.dateValueSelection,
    ) as DateValueSelectionParsed;

    return {
      ...dateValSel,
      dateRange: dateValSel?.dateRange
        ? [
            moment(Number(dateValSel?.dateRange?.[0])),
            moment(Number(dateValSel?.dateRange?.[1])),
          ]
        : undefined,
    };
  }
};

const getDashboardInitVal = (dashboard?: IDashboard) => {
  if (dashboard?.startDate && dashboard?.endDate) {
    const dateValSel = {
      datePeriod: "isInRange",
      dateRange: [
        moment(Number(dashboard?.startDate)),
        moment(Number(dashboard?.endDate)),
      ],
    } as DateValueSelection;
    return { ...dateValSel, inputValue: getInputFieldValue(dateValSel) };
  }
};

const getInitialValue = (
  dashboard: IDashboard | IPublicDashboard | undefined,
) => {
  if (isPublicDashboard(dashboard)) return getPublicDashboardInitVal(dashboard);
  return getDashboardInitVal(dashboard);
};

const getInputFieldValue = (selection: DateValueSelection | undefined) => {
  if (!selection) return "";
  const { datePeriod, dateRange, previousInterval } = selection;
  if (datePeriod === "isInRange") {
    const fromRange = dateRange?.[0].format(dateFormat) ?? "-";
    const untilRange = dateRange?.[1].format(dateFormat) ?? "-";
    return `is from ${fromRange} until ${untilRange}`;
  }
  return `is previous ${previousInterval ?? "-"}`;
};

const validateSelection = (
  selection: DateValueSelection | undefined,
  setError: Dispatch<SetStateAction<string | undefined>>,
) => {
  if (!selection) return;
  const { datePeriod, previousInterval, dateRange } = selection;
  if (datePeriod === "isPrevious" && !previousInterval) {
    setError("You must select a previous time period");
    return;
  }
  if (datePeriod === "isInRange" && (!dateRange || dateRange.length !== 2)) {
    setError("You must select a valid date range");
    return;
  }
  setError(undefined);
};
