import { ProgressProps } from "antd";
import {
  AdLoadAdStatusEnum,
  IAdLoadProgressStatus,
  IAdToLoadData,
} from "shared/types/adLibrary";

type ProgressCount = {
  [key in keyof IAdLoadProgressStatus]: {
    errorCount: number;
    successCount: number;
    totalCount: number;
  };
};

export const getProgressCount = (
  selectedAdsToLoad: IAdToLoadData[],
): ProgressCount => {
  const getProgressCountByStep = (step: keyof IAdLoadProgressStatus) => {
    const errorCount = selectedAdsToLoad.filter(
      adToLoad => adToLoad.progressStatus[step] === AdLoadAdStatusEnum.ERROR,
    ).length;
    const successCount = selectedAdsToLoad.filter(
      adToLoad => adToLoad.progressStatus[step] === AdLoadAdStatusEnum.SUCCESS,
    ).length;

    return {
      errorCount,
      successCount,
      totalCount: selectedAdsToLoad.length,
    };
  };

  const uploadMediaProgress = getProgressCountByStep("uploadMedia");
  const assembleCreativeProgress = getProgressCountByStep("assembleCreative");
  const loadToFacebookProgress = getProgressCountByStep("loadToFacebook");

  return {
    uploadMedia: uploadMediaProgress,
    assembleCreative: assembleCreativeProgress,
    loadToFacebook: loadToFacebookProgress,
  };
};

export const getProgressPercent = (
  selectedAdsToLoad: IAdToLoadData[],
  progressCount: ProgressCount,
) => {
  const NUMBER_OF_STEPS = 3;

  const getPercentByStep = (
    numberOfAds: number,
    step: keyof IAdLoadProgressStatus,
  ) => {
    const totalPercentByStep = 100 / NUMBER_OF_STEPS;
    const { successCount, errorCount } = progressCount[step];

    const loadedAds = successCount + errorCount;
    const currentStepPercent = (totalPercentByStep * loadedAds) / numberOfAds;
    return currentStepPercent;
  };

  const numberOfAds = selectedAdsToLoad.length;
  const uploadMediaPercent = getPercentByStep(numberOfAds, "uploadMedia");
  const assembleCreativePercent = getPercentByStep(
    numberOfAds,
    "assembleCreative",
  );
  const loadToFacebookPercent = getPercentByStep(numberOfAds, "loadToFacebook");

  return Math.ceil(
    uploadMediaPercent + assembleCreativePercent + loadToFacebookPercent,
  );
};

export const getProgressStatus = (
  progressPercent: number,
  numberOfFailedAds: number,
): ProgressProps["status"] => {
  if (progressPercent === 100 && numberOfFailedAds === 0) {
    return "success";
  }

  if (progressPercent === 100 && numberOfFailedAds > 0) {
    return "exception";
  }

  if (progressPercent > 0 && progressPercent < 100) {
    return "normal";
  }

  return "active";
};

export const getNumberOfFailedAds = (selectedAdsToLoad: IAdToLoadData[]) => {
  return (
    selectedAdsToLoad.filter(
      adToLoad => adToLoad.adLoadStatus.status === AdLoadAdStatusEnum.ERROR,
    ).length ?? 0
  );
};
