import { Col, Divider, Row, Switch } from "antd";
import {
  STEP_CREATE_BATCH,
  STEP_CREATE_BATCH_V2,
} from "shared/types/assetExporter";
import styles from "./MaskItemsControls.module.scss";
import { useAssetBatchesRenderContext } from "../../contexts/AssetBatchesRenderContext";

export type MaskItemsProps = {
  step: STEP_CREATE_BATCH | STEP_CREATE_BATCH_V2;
};

type MaskItemsControlsProps = {
  onChangeMediaMask: (val: boolean) => void;
  onChangeTextMask: (val: boolean) => void;
  isMediaMaskOn: boolean;
  isTextMaskOn: boolean;
} & MaskItemsProps;

export const MaskItemsControls = ({
  step,
  onChangeMediaMask,
  onChangeTextMask,
  isMediaMaskOn,
  isTextMaskOn,
}: MaskItemsControlsProps) => {
  const { disabledMediaMask, disabledTextMask } =
    useAssetBatchesRenderContext();

  const spanArr =
    step === STEP_CREATE_BATCH.THREE_TEXT ? [5, 4, 6, 4] : [0, 0, 13, 11];

  return (
    <Row className={styles.container}>
      {step === STEP_CREATE_BATCH.THREE_TEXT && (
        <>
          <Col span={spanArr[0]} className={styles.label}>
            <span>Text</span>
          </Col>
          <Col span={spanArr[1]} className={styles.switch}>
            <Switch
              onChange={onChangeTextMask}
              checked={isTextMaskOn}
              disabled={disabledTextMask}
              size={"small"}
            />
          </Col>
          <Col span={5} className={styles.divider}>
            <Divider type="vertical" />
          </Col>
        </>
      )}
      <Col span={spanArr[2]} className={styles.label}>
        <span>Media</span>
      </Col>
      <Col span={spanArr[3]} className={styles.switch}>
        <Switch
          onChange={onChangeMediaMask}
          checked={isMediaMaskOn}
          disabled={disabledMediaMask}
          size={"small"}
        />
      </Col>
    </Row>
  );
};
