import {
  DeleteOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  LinkOutlined,
  LockOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { Modal, Popover, Tooltip, Typography } from "antd";
import { ReactNode, useCallback, useContext, useMemo, useState } from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import {
  TLayerActionType,
  TLayerItem,
} from "screens/designStudio/editor.hooks/useLayers";
import { offerTypes } from "shared/constants/dataManagement";
import {
  IExtendedFabricObject,
  isCanvasBackground,
  IStampObjectData,
} from "shared/types/designStudio";
import { context } from "../../../../Editor.context";
import { getLayerTitle } from "../manageLayers.utils/Utils";

import styles from "./LayerItem.module.scss";
import useCallbacks from "./layerItem.hooks/useCallbacks";
import OffertypesMenu from "./layerItem/OffertypesMenu";

interface LayerItemProps {
  layer: TLayerItem;
  draggableProvided?: DraggableProvided;
  highlight: boolean;
}

interface LayerItemHandlers {
  updateSelectedLayer: (layer: TLayerItem) => void;
}

const LayerItem = (props: LayerItemProps & LayerItemHandlers) => {
  const editorContext = useContext(context);
  const {
    draggableProvided = {
      draggableProps: {
        style: {},
      },
      dragHandleProps: {},
      innerRef: undefined,
    },
  } = props;
  const { isLongTitle, fullText } = getLayerTitle(props.layer, 15);
  const [hoveringLayerId, setHoveringLayerId] = useState<string>();
  const [showStampOffertypePopover, toggleStampOffertypePopover] =
    useState<boolean>(false);

  const mouseEnterDelay = useMemo(() => 0.7, []);
  const [isHidden, toggle] = useState<boolean>(!!props.layer.original?.visible);

  const { onLayerItemClick } = useCallbacks();

  const onMouseEnterLeaver = useCallback(
    (type: TLayerActionType) =>
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();

        editorContext?.setLayerAction({
          layer: props.layer,
          action: {
            type,
          },
        });
      },
    [props.layer, editorContext],
  );

  const onToggle = useCallback(
    (isHidden: boolean) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();

      toggle(!isHidden);

      editorContext?.setLayerAction({
        layer: props.layer,
        action: {
          type: "toggle",
          data: !isHidden,
        },
      });
    },
    [editorContext, props.layer],
  );

  const onMouseEnterTitle = useCallback(
    (id: string) => (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();

      setHoveringLayerId(id);
    },
    [setHoveringLayerId],
  );
  const onMouseLeaveTitle = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();

      setHoveringLayerId(undefined);
    },
    [setHoveringLayerId],
  );
  const titleTooltipVisibility =
    !isLongTitle || hoveringLayerId !== props.layer.id ? false : undefined;

  const layerActions = [
    ...(props.layer.type === "stamp" ? ["Link"] : []),
    "Lock",
    "Delete",
  ];
  return (
    <div
      className={`${styles.LayerItem} ${
        props.highlight ? styles.Highlight : ""
      }`}
      ref={draggableProvided?.innerRef}
      {...draggableProvided.draggableProps}
      {...draggableProvided.dragHandleProps}
      style={{
        ...draggableProvided.draggableProps.style,
      }}
      onClick={onLayerItemClick(props.layer, () => {
        props.updateSelectedLayer(props.layer);
      })}
      onMouseEnter={onMouseEnterLeaver("mouseEnter")}
      onMouseLeave={onMouseEnterLeaver("mouseLeave")}
    >
      <div className={`${styles.VisibilityToggleButton} ${styles.Button}`}>
        <Tooltip
          mouseEnterDelay={mouseEnterDelay}
          title={isHidden ? "Hide object" : "Show object"}
        >
          <span onClick={onToggle(isHidden)}>
            {isHidden ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          </span>
        </Tooltip>
      </div>

      <div
        className={styles.Title}
        onMouseEnter={onMouseEnterTitle(props.layer.id)}
        onMouseLeave={onMouseLeaveTitle}
      >
        <Tooltip
          mouseEnterDelay={mouseEnterDelay}
          title={fullText}
          visible={titleTooltipVisibility}
        >
          <Typography.Text
            style={{ color: "white", width: 150, display: "inline-block" }}
            editable={{
              onChange: val => {
                editorContext?.setLayerAction({
                  layer: props.layer,
                  action: {
                    type: "rename",
                    data: val,
                  },
                });
              },
            }}
            ellipsis
          >
            {fullText}
          </Typography.Text>
        </Tooltip>
      </div>

      <div className={styles.ButtonWrapper}>
        {layerActions.map(type => {
          let className;
          let tooltipTitle;
          let icon: ReactNode;
          switch (type) {
            case "Link":
              className = `${styles.ObjectLinkButton} ${styles.Button}`;
              tooltipTitle = "Click to edit stamp";

              const { original } = props.layer;
              const customData = (original as unknown as IExtendedFabricObject)
                ?.customData as IStampObjectData;
              const stampId = customData?.stampId || "";
              const originalStampId = customData?.originalStampId;
              icon = (
                <Popover
                  trigger="click"
                  placement="bottom"
                  visible={showStampOffertypePopover}
                  onVisibleChange={show => {
                    toggleStampOffertypePopover(show);
                  }}
                  content={
                    <OffertypesMenu
                      offerTypes={offerTypes}
                      stampId={originalStampId ?? stampId}
                      onItemClick={() => {
                        toggleStampOffertypePopover(false);
                      }}
                    />
                  }
                >
                  <LinkOutlined
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation(); // This will prevent the actual object being selected. Link button click should not select object.

                      toggleStampOffertypePopover(true);
                    }}
                  />
                </Popover>
              );
              break;
            case "Lock":
              className = `${styles.ObjectLockButton} ${styles.Button}`;
              const { selectable } = props.layer.original;

              if (isCanvasBackground(props.layer)) {
                tooltipTitle = "Background cannot be un-locked.";
              } else {
                tooltipTitle = selectable
                  ? "Click to lock object"
                  : "Click to un-lock object";
              }

              icon = (
                <span
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    const selectable = props.layer.original.selectable ?? true; // we assume an object is selectable by default.

                    editorContext?.setLayerAction({
                      layer: props.layer,
                      action: {
                        type: "lock",
                        data: !selectable,
                      },
                    });

                    if (selectable) {
                      editorContext?.setCanvasAction!({
                        target: props.layer.original,
                        action: {
                          type: "objectDeSelected",
                        },
                      });
                    }
                  }}
                >
                  {selectable ? (
                    <UnlockOutlined />
                  ) : (
                    <LockOutlined style={{ color: "#ff4d4f" }} />
                  )}
                </span>
              );
              break;
            case "Delete":
              className = `${styles.ObjectDeleteButton} ${styles.Button}`;
              tooltipTitle = "Click to delete object";
              icon = (
                <DeleteOutlined
                  onClick={e => {
                    e.preventDefault();

                    Modal.confirm({
                      title: "Delete this object from the canvas?",
                      onOk: () => {
                        editorContext?.setLayerAction({
                          layer: props.layer,
                          action: {
                            type: "delete",
                          },
                        });
                      },
                      okText: "Delete",
                      okType: "danger",
                    });
                  }}
                />
              );
              break;
          }

          const showOffertypePopover =
            type !== "Link" || !showStampOffertypePopover ? undefined : false;

          return (
            <div key={`layer-item-button-${type}`} className={className}>
              <Tooltip
                mouseEnterDelay={mouseEnterDelay}
                title={tooltipTitle}
                visible={showOffertypePopover}
              >
                {icon}
              </Tooltip>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LayerItem;
