import { EyeOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useMediaActions } from "redux/media/media.slice";
import { TooltipPerms } from "./TooltipPerms";
import { useSelected } from "./useSelected";

export const AssetsEdit = () => {
  const { setAssetsToEdit } = useMediaActions();
  const { selectedAssets, allAssets } = useSelected();

  const handleClick = () => {
    setAssetsToEdit(selectedAssets);
  };

  return (
    <TooltipPerms action="View Assets" allowed enabled={allAssets}>
      <Button
        shape="circle"
        icon={<EyeOutlined />}
        onClick={handleClick}
        disabled={!allAssets}
      />
    </TooltipPerms>
  );
};
