import { useGetRequiredData } from "./useGetRequiredData";
import useGetIsMounted from "shared/hooks/useGetIsMounted";
import {
  useCreateAccountMedia,
  useUploadFacebookAdMedia,
} from "shared/hooks/adLibrary/adLoad/useCreatePageMedia";

import {
  handleLoadProcess,
  recursivelyRacePromises,
  returnLoadToFacebookArgs,
} from "../../../loadStep/helpers.load";

import { HandleAdLoadProcessStepArgs, PromiseTracker } from "../../types";
import { getEnvVar } from "utils/helpers";

export const useHandleUploadFacebookAdMedia = () => {
  const isMounted = useGetIsMounted();
  const { selectedAccounts, selectedDealers } = useGetRequiredData();

  const { mutateAsync: createAccountMedia } = useCreateAccountMedia();
  const { mutateAsync: uploadFacebookAdMedia } = useUploadFacebookAdMedia();

  return async (args: HandleAdLoadProcessStepArgs) => {
    if (!isMounted()) return;
    const promiseObjs: PromiseTracker[] = args.selectedAdsToLoad.map(
      adToLoad => {
        const uploadMediaArgs = returnLoadToFacebookArgs({
          adToLoad,
          selectedDealers: selectedDealers,
          selectedAccounts: selectedAccounts,
        });

        return {
          key: adToLoad.key,
          promise: handleLoadProcess(
            adToLoad,
            () =>
              uploadFacebookAdMedia({
                createMedia: createAccountMedia,
                adToLoad: uploadMediaArgs.adToLoad,
                selectedDealer: uploadMediaArgs.selectedDealer,
              }),
            () => Promise.resolve(adToLoad),
          ),
        };
      },
    );
    recursivelyRacePromises(promiseObjs, args.setCurrentAdToLoad);
  };
};
