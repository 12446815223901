import { ExportOutlined } from "@ant-design/icons";
import { Col, notification, Progress, Row } from "antd";
import { UseMutateFunction } from "react-query";

import {
  ExportForOption,
  FeedStatus,
  GetCsvUrlArgs,
} from "shared/types/assetExporter";

import styles from "./ProcessNotification.module.scss";

type Props = FeedStatus & {
  feedId: string;
  getCsvDownloadUrl: UseMutateFunction<string, Error, GetCsvUrlArgs, unknown>;
  notificationKey: string;
  exportFor: ExportForOption[];
  onClose: () => void;
  onAdLibClick: () => void;
};

const getStatus = (percentage: number) => {
  const isFailedStatus = percentage === -1;
  const isSuccessStatus = percentage === 100;
  if (isFailedStatus) return "exception";
  if (isSuccessStatus) return "success";
  return "active";
};
const Description = ({
  message,
  percentage,
  getCsvDownloadUrl,
  feedId,
  exportFor,
  onAdLibClick,
}: Props) => {
  const currStatus = getStatus(percentage);
  const isDataProcessing = percentage < 80;
  const isCsvExport = exportFor.includes("CSV download");
  const isFtpExport = exportFor.includes("FTP");
  const isAdLibExport = exportFor.includes("Ad Library");
  const isCsvExportCompleted = percentage > 90;
  const isFtpExportCompleted = percentage === 100;
  const exportingText = "Exporting";
  const completedText = "Completed";
  const adLibText = "Review and Complete Import";
  const onCsvClick = () => {
    getCsvDownloadUrl({ feedId });
  };

  return (
    <div>
      {isDataProcessing ? (
        <span>{message}</span>
      ) : (
        <Row>
          <Col span={12}>
            {isAdLibExport && <div>Export for Ad Library</div>}
            {isCsvExport && <div>Export to CSV</div>}
            {isFtpExport && <div>Export to FTP</div>}
          </Col>
          <Col span={12}>
            {isAdLibExport && (
              <div
                className={`${styles.downloadBtn} ${
                  styles.statusDescription
                }`}
                onClick={onAdLibClick}
              >
                {adLibText}
              </div>
            )}
            {isCsvExport &&
              (isCsvExportCompleted ? (
                <div
                  onClick={onCsvClick}
                  className={`${styles.downloadBtn} ${styles.statusDescription}`}
                >
                  Download
                </div>
              ) : (
                <div className={styles.statusDescription}>{exportingText}</div>
              ))}
            {isFtpExport &&
              (isFtpExportCompleted ? (
                <div className={styles.statusDescription}>
                  {completedText}
                </div>
              ) : (
                <div className={styles.statusDescription}>{exportingText}</div>
              ))}
          </Col>
        </Row>
      )}

      <Progress percent={percentage} showInfo={true} status={currStatus} />
    </div>
  );
};

export const processNotification = (props: Props) => {
  notification.open({
    key: props.notificationKey,
    icon: (
      <div className={styles.notificationIcon}>
        <ExportOutlined />
      </div>
    ),
    message: <strong>Export Status</strong>,
    description: <Description {...props} />,
    duration: null,
    placement: "bottomRight",
    onClose: props.onClose,
    style: { width: "460px" },
  });
};
