import { Tooltip } from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { TableComponents } from "rc-table/lib/interface";
import { forwardRef, useState } from "react";
import { Resizable, ResizableProps, ResizeCallbackData } from "react-resizable";

const ResizableTitle = ({
  onResize,
  width,
  ...restProps
}: {
  width: number;
  onResize: ResizableProps["onResize"];
}) => {
  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      axis="x"
      handle={
        <span
          style={{
            position: "absolute",
            right: -5,
            bottom: 0,
            zIndex: 10,
            width: 10,
            height: "100%",
            cursor: "col-resize",
          }}
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

interface ResizableColumn<RecordType> extends ColumnType<RecordType> {
  name: string;
}

const useResizableTable = <
  RecordType,
  GenericColumn extends ResizableColumn<RecordType> = ResizableColumn<RecordType>,
>(
  columns: GenericColumn[] | undefined,
  rowTooltipMap: Record<string, string> = {},
) => {
  const [columnWidths, setColumnWidths] = useState(
    () => columns?.map(({ name, width }) => ({ name, width })) || [],
  );

  const handleResize =
    (name?: string) =>
    (_: any, { size }: ResizeCallbackData) => {
      setColumnWidths(prevColumnWidths => {
        const columnIndex = prevColumnWidths.findIndex(
          col => col.name === name,
        );
        if (columnIndex < 0) return prevColumnWidths;

        const nextColumns = [...prevColumnWidths];

        nextColumns[columnIndex] = {
          ...nextColumns[columnIndex],
          width: size.width,
        };
        return nextColumns;
      });
    };

  const resizableColumns = columns?.map(col => ({
    ...col,
    width:
      columnWidths.find(({ name }) => col.name === name)?.width ?? col.width,
    onHeaderCell: (column: ColumnType<RecordType>) =>
      ({
        width: column.width,
        onResize: handleResize(col.name),
      } as any),
  }));

  const tooltipRow = forwardRef(function Tr(
    props: { children: Element; "data-row-key": string },
    ref: React.Ref<any>,
  ) {
    const tooltip = rowTooltipMap[props["data-row-key"]];
    if (tooltip) {
      return (
        <Tooltip title={tooltip} placement="topLeft">
          <tr {...props} ref={ref} />
        </Tooltip>
      );
    }
    return <tr {...props} ref={ref} />;
  });

  const components: TableComponents<RecordType> | undefined = {
    header: { cell: ResizableTitle },
    body: {
      row: tooltipRow,
    },
  };

  return { resizableColumns, components };
};

export default useResizableTable;
