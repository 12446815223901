import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";
import { isDataDogEnabled } from "shared/datadog";
import { useUser } from "./useUser";

export const useDatadog = () => {
  const user = useUser();

  useEffect(() => {
    if (!isDataDogEnabled) return;
    if (user) datadogRum.startSessionReplayRecording();
    return () => datadogRum.stopSessionReplayRecording();
  }, [user]);
};
