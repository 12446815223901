import {
  useGetAdLoadSession,
  useSetAdLoadSession,
} from "shared/hooks/adLibrary/adLoad/adLoadSessions";
import { AdLoadSession } from "screens/adLibrary/facebookUtils/adProto.types";
import { StringParam, useQueryParam } from "use-query-params";
import { useCallback } from "react";
import { AdDest } from "../campaignData.utils";

export const useSessionParam = () => {
  const [sessionId, setSessionId] = useQueryParam("session", StringParam);
  const closeSession = useCallback(() => {
    setSessionId(undefined);
  }, [setSessionId]);

  return {
    sessionId,
    setSessionId,
    closeSession,
  };
};

export const useCurrentSession = () => {
  const { sessionId } = useSessionParam();
  const { data, isLoading: isLoadingSession } = useGetAdLoadSession(sessionId);

  if (!sessionId) {
    throw new Error("Session ID is required");
  }

  const session = getSessionWithDefaults(data, sessionId);

  const sessionData = session.data;
  const adDests = sessionData.adProtoDrafts.flatMap<AdDest>(adProtoDraft =>
    adProtoDraft.dests.map(adset => ({
      adId: adProtoDraft.id,
      campaignId: adset.campaignId,
      adsetId: adset.adsetId,
      storeName: adProtoDraft.storeName,
      loadToFb: adset.loadToFb,
    })),
  );

  const adDestToLoad = adDests.filter(adDest => adDest.loadToFb);

  return {
    session,
    adDests,
    adDestToLoad,
    isLoadingSession,
  };
};

export const useSetCurrentSession = () => {
  const { sessionId } = useSessionParam();
  const { setSession } = useSetAdLoadSession(sessionId);

  if (!sessionId) {
    throw new Error("Session ID is required");
  }

  return {
    /**
     * function to update the session from the prev one
     * you can either "mutate" the `prevSession` object or return a new one
     * since it's an immer proxy
     * */
    setSession,
  };
};

/**
 * Returns a session with default values,
 * useful for backwards compatibility with old sessions
 */
const getSessionWithDefaults = (
  session: AdLoadSession | undefined,
  sessionId: string,
): AdLoadSession => ({
  id: session?.id ?? sessionId,
  name: session?.name ?? "",
  data: {
    step: session?.data.step ?? "select",
    selectedStoreNames: session?.data.selectedStoreNames ?? [],
    adIdsToLoad: session?.data.adIdsToLoad ?? [],
    adProtoDrafts: session?.data.adProtoDrafts ?? [],
  },
  updatedAt: session?.updatedAt ?? new Date().toISOString(),
});
