import { Form, Input } from "antd";
import { debounce } from "lodash";
import { FieldData } from "rc-field-form/lib/interface";
import styles from "./Search.module.scss";

type Props = {
  fields: MGSearchObj;
  onChange: (value: MGSearchObj) => void;
};

export const Search = ({ fields, onChange }: Props) => {
  const handleChange = (changedFields: FieldData[], allFields: FieldData[]) => {
    const data = allFields.reduce((acc, { name, value }) => {
      const key = Array.isArray(name) ? name[0] : name;

      return { ...acc, [key]: value };
    }, {});

    onChange(data);
  };

  return (
    <Form
      initialValues={fields}
      onFieldsChange={debounce(handleChange, 1000)}
      className={styles.host}
    >
      <fieldset>
        <legend>Vehicle Information</legend>
        <div>
          <Form.Item name="year">
            <Input placeholder="Year" />
          </Form.Item>
          <Form.Item name="make">
            <Input placeholder="Make" />
          </Form.Item>
          <Form.Item name="model">
            <Input placeholder="Model" />
          </Form.Item>
          <Form.Item name="trim">
            <Input placeholder="Trim" />
          </Form.Item>
        </div>
      </fieldset>
      <fieldset>
        <legend>Filter Current Selection</legend>
        <div>
          <Form.Item name="query">
            <Input placeholder="Filter" />
          </Form.Item>
        </div>
      </fieldset>
    </Form>
  );
};
