import React, { ReactNode } from "react";
import { Button, Select, Space } from "antd";

import { SearchOutlined } from "@ant-design/icons";

import { FilterDropdownProps } from "antd/lib/table/interface";

interface Filter {
  text: ReactNode;
  value: string | number;
}

const FilterDropdown = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  filters,
  clearFilters,
}: FilterDropdownProps) => {
  const parseFilters = (): Filter[] | undefined => {
    return filters?.map((filter): Filter => {
      if (typeof filter.value === "boolean") {
        return {
          text: filter.text,
          value: "",
        };
      } else {
        return {
          text: filter.text,
          value: filter.value,
        };
      }
    });
  };

  const parsedFilters = parseFilters();

  return (
    <div style={{ padding: 8 }}>
      <Select
        mode="multiple"
        showSearch
        style={{ width: "100%" }}
        value={selectedKeys.map(key => key.toString())}
        onChange={setSelectedKeys}
      >
        {parsedFilters?.map(filter => [
          <Select.Option key={filter.value} value={filter.value}>
            {filter.text}
          </Select.Option>,
        ])}
      </Select>
      <Space size="middle" style={{ marginTop: 8 }}>
        <Button
          onClick={() => {
            clearFilters?.();
            setSelectedKeys([]);
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
        <Button
          type="primary"
          onClick={() =>
            confirm({
              closeDropdown: false,
            })
          }
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Filter
        </Button>
      </Space>
    </div>
  );
};

export default FilterDropdown;
