import { FC, useState } from "react";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";

import Placeholder from "statics/images/image-placeholder.png";
import ErrorImage from "statics/images/missing-thumbnail.png";

export interface Props {
  value?: string;
  title?: string;
}

const Carousel: FC<Props> = ({ value, title }) => {
  const [currentImgIndex, setCurrentImgIndex] = useState(0);

  // We assume that | will be the separated for image urls
  const imageUrls: string[] = value?.split("|") ?? [];
  const currentImgSrc = imageUrls[currentImgIndex] || "";

  const isNoImage = !imageUrls.length;
  const isOnFirstImg = currentImgIndex === 0;
  const isOnLastImg = currentImgIndex === imageUrls.length - 1;
  return (
    <Space direction="horizontal" size="small" align="center">
      <Button
        type="link"
        icon={<LeftCircleOutlined />}
        disabled={isOnFirstImg || isNoImage}
        onClick={() => {
          if (isOnFirstImg) return;
          setCurrentImgIndex(currentImgIndex - 1);
        }}
      />
      <img
        style={{ width: 64 }}
        src={currentImgSrc || Placeholder}
        alt={title}
        onError={event => {
          (event.target as HTMLImageElement).src = ErrorImage;
        }}
      />
      <Button
        type="link"
        icon={<RightCircleOutlined />}
        disabled={isOnLastImg || isNoImage}
        onClick={() => {
          if (isOnLastImg) return;
          setCurrentImgIndex(currentImgIndex + 1);
        }}
      />
    </Space>
  );
};

export default Carousel;
