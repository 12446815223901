import { IConfig } from "shared/types/configuration";
import HttpClient from "./httpClient";
import {
  ApiResponseCampaignPlanner,
  ApiResponseCampaignPlannerInstance,
  CampaignPlanner,
  CampaignPlannerInstance,
  UpdatePlannerInstanceAdRequestBody,
} from "screens/campaignManagement/campaignPlanner/types";
import { IApiResponse } from "shared/types/shared";
import {
  CreateCampaignPlannerParams,
  DeleteCampaignPlannerParams,
  UpdateCampaignPlannerAdsParams,
  UpdatePlannerInstanceAdParams,
} from "shared/types/campaignPlanner";

export default ({ config }: { config: IConfig }) => ({
  createCampaignPlanner: (
    campaign: CreateCampaignPlannerParams,
  ): Promise<IApiResponse<ApiResponseCampaignPlanner>> => {
    const createCampaignPlannerUrl =
      config.services.campaignPlanner.createCampaignPlannerUrl;

    return HttpClient.post<IApiResponse<ApiResponseCampaignPlanner>>(
      createCampaignPlannerUrl,
      campaign,
    );
  },
  editCampaignPlanner: (
    planner: Partial<CampaignPlanner>,
  ): Promise<IApiResponse<ApiResponseCampaignPlanner>> => {
    const editCampaignPlanner =
      config.services.campaignPlanner.editCampaignPlannerUrl;

    const requestBody: Partial<CampaignPlanner> = planner;
    return HttpClient.put<IApiResponse<ApiResponseCampaignPlanner>>(
      `${editCampaignPlanner}/${planner.id}`,
      requestBody,
    );
  },
  getCampaignPlanners: (): Promise<
    IApiResponse<ApiResponseCampaignPlanner[]>
  > => {
    const getCampaignPlannersUrl =
      config.services.campaignPlanner.getCampaignPlannersUrl;

    return HttpClient.get<IApiResponse<ApiResponseCampaignPlanner[]>>(
      getCampaignPlannersUrl,
    );
  },
  deleteCampaignPlanners: (
    planners: DeleteCampaignPlannerParams,
  ): Promise<IApiResponse<string>> => {
    const getCampaignPlannersUrl =
      config.services.campaignPlanner.deleteCampaignPlannerUrl;

    return HttpClient.delete<IApiResponse<string>>(getCampaignPlannersUrl, {
      ids: planners,
    });
  },

  updateCampaignPlannerAds: (
    updateModel: UpdateCampaignPlannerAdsParams,
  ): Promise<IApiResponse<ApiResponseCampaignPlanner>> => {
    const updateCampaignPlannerAdsUrl =
      config.services.campaignPlanner.updateCampaignPlannerAdsUrl;

    const requestBody: Partial<UpdateCampaignPlannerAdsParams> = updateModel;
    return HttpClient.post<IApiResponse<ApiResponseCampaignPlanner>>(
      `${updateCampaignPlannerAdsUrl}/${updateModel.id}/ads`,
      requestBody,
    );
  },

  createCampaignPlannerInstance: (
    plannerId: string,
    instance: ApiResponseCampaignPlannerInstance,
  ): Promise<IApiResponse<ApiResponseCampaignPlannerInstance>> => {
    const createCampaignPlannerInstanceUrl =
      config.services.campaignPlanner.createCampaignPlannerInstanceUrl(
        plannerId,
      );

    return HttpClient.post<IApiResponse<ApiResponseCampaignPlannerInstance>>(
      createCampaignPlannerInstanceUrl,
      { plannerId, instance },
    );
  },

  deleteCampaignPlannerInstances: (
    instances: Record<string, string[]>,
  ): Promise<IApiResponse<string>> => {
    const deleteCampaignPlannerInstancesUrl =
      config.services.campaignPlanner.deleteCampaignPlannerInstancesUrl;

    return HttpClient.delete<IApiResponse<string>>(
      deleteCampaignPlannerInstancesUrl,
      instances,
    );
  },

  editCampaignPlannerInstance: (
    plannerId: string,
    instance: ApiResponseCampaignPlannerInstance,
    includeAdShells: boolean,
  ): Promise<IApiResponse<CampaignPlannerInstance>> => {
    const updateCampaignPlannerInstanceUrl =
      config.services.campaignPlanner.updateCampaignPlannerInstanceUrl(
        plannerId,
        instance.id,
      );

    return HttpClient.put<IApiResponse<CampaignPlannerInstance>>(
      updateCampaignPlannerInstanceUrl,
      { plannerId, instance, includeAdShells },
    );
  },

  editCampaignPlannerInstanceAd: (params: UpdatePlannerInstanceAdParams) => {
    const editPlannerInstanceAdUrl =
      config.services.campaignPlanner.updatePlannerInstanceAdsUrl(params);

    return HttpClient.put<IApiResponse<CampaignPlannerInstance>>(
      editPlannerInstanceAdUrl,
      params,
    );
  },

  updateModifiedAdsPlannersHandler: (
    updates: UpdatePlannerInstanceAdRequestBody[],
  ) => {
    const updateModifiedAdsPlannersUrl =
      config.services.campaignPlanner.updateModifiedAdsPlannersUrl;

    return HttpClient.post<IApiResponse<string>>(updateModifiedAdsPlannersUrl, {
      updates,
    });
  },
});
