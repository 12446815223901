import { useMetaColumns } from "../table";
import {
  useColCreatedBy,
  useColDateCreated,
  useColDateExpired,
  useColDimensions,
  useColName,
  useColSize,
  useColStatus,
  useColType,
} from "../table/cols";

export const useColsSearch = () => {
  const metaCols = useMetaColumns("tableSearchCols");

  return [
    useColName(),
    useColType(),
    useColStatus(),
    useColDimensions(),
    useColSize(),
    useColDateCreated(),
    useColCreatedBy(),
    ...metaCols,
    useColDateExpired(),
  ];
};
