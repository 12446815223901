import { useEffect, useState } from "react";
import { getCAMThumbnail, useCAMSearch } from "shared/components/media";
import uuid from "uuid";
import { ImgFiles } from "./types";

export const useThemeBGSearch = (payload: MGSearchObj) => {
  const searchMedia = useCAMSearch();
  const [items, setItems] = useState<ImgFiles[]>([]);

  useEffect(() => {
    if (payload.width && payload.height) {
      searchMedia(payload).then(res => {
        const formattedItems = res.items.map(item => {
          return {
            uid: uuid(),
            name: item.filename,
            thumbUrl: getCAMThumbnail(item).url,
          };
        });

        setItems(formattedItems);
      });
    }
  }, [payload, searchMedia]);

  return items;
};
