import { useState, useEffect, useMemo } from "react";

import {
  message,
  DatePicker,
  Input,
  Radio,
  Select,
  Tooltip,
  Form,
  Typography,
} from "antd";

import { IDashboard } from "shared/types/dashboardManagement";
import FormDrawer from "shared/components/FormDrawer";
import { useMutateDashboard } from "shared/hooks/dashboardManagement/useMutateDashboard";
import { InfoCircleOutlined } from "@ant-design/icons";
import styles from "./DashboardDrawer.module.scss";
import { IBrand } from "shared/types/brandManagement";
import moment from "moment";
import MultiSelectAllTags from "./MultiSelectAllTags";
import { useFillteredAccountsByOEM } from "./useFillteredAccountsByOEM";
import { difference } from "lodash";

interface IDashboardDrawerProps {
  defaultModels: string[];
  additionalModels: string[];
  brands: IBrand[];
  specialCampaigns: string[];
  editDashboardData: IDashboard;
  showDashboardDrawer: boolean;
}

interface IDashboardDrawerHandlers {
  toggleDashboardDrawer: (toggle: boolean) => void;
}

const DashboardDrawer = ({
  defaultModels,
  additionalModels,
  brands,
  specialCampaigns,
  editDashboardData,
  showDashboardDrawer,
  toggleDashboardDrawer,
}: IDashboardDrawerProps & IDashboardDrawerHandlers) => {
  const [form] = Form.useForm();
  const [dateFormat, setDateFormat] = useState<"date" | "range">("date");
  const [localDashboardStatus, setLocalDashboardStatus] = useState<IDashboard>({
    enabled: false,
  });

  const dateOptions = useMemo(
    () => [
      "Today",
      "Yesterday",
      "7 days",
      "14 days",
      "30 days",
      "This Week",
      "Last Week",
      "This Month",
      "Last Month",
      "This Year",
      "Last Year",
    ],
    [],
  );

  const modelOptions = useMemo(() => {
    const defaultOptions = {
      label: "Default Models",
      options: defaultModels.map(modelOption => ({
        label: modelOption,
        value: modelOption,
      })),
    };
    if (!additionalModels?.length) return [defaultOptions];

    const additionalOptions = {
      label: "Additional Models",
      options: difference(additionalModels, defaultModels).map(modelOption => ({
        label: modelOption,
        value: modelOption,
      })),
    };

    return [defaultOptions, additionalOptions];
  }, [defaultModels, additionalModels]);

  useEffect(() => {
    editDashboardData && setLocalDashboardStatus(editDashboardData);
    setDateFormat(
      editDashboardData.startDate && editDashboardData.endDate
        ? "range"
        : "date",
    );
  }, [editDashboardData]);

  const { mutate: mutateDashboard, isLoading: isMutatingDashboard } =
    useMutateDashboard();
  const minimumRequiredFields = ["oem", "specialCampaign", "account"];

  const handleSubmit = async () => {
    await form.validateFields().catch(e => {
      const invalidFieldFound = !!e.errorFields?.length;
      if (invalidFieldFound) throw e;
      return e.values;
    });
    const fieldsValue = form.getFieldsValue();
    const missingField = minimumRequiredFields.every(
      field => !fieldsValue[field],
    );
    if (missingField) {
      return message.warn(
        "One of the following fields is required: OEM, Special Campaigns or Accounts",
      );
    }

    mutateDashboard(
      { ...localDashboardStatus },
      {
        onSuccess: () => {
          message.success("Dashboard has been updated");
          handleClose();
        },
        onError: () => {
          message.error("Something went wrong while updating the dashboard");
        },
      },
    );
  };

  const handleClose = () => {
    toggleDashboardDrawer(false);
  };

  const updateDashboardField = (
    key: keyof IDashboard,
    value: boolean | string | string[],
  ) => {
    setLocalDashboardStatus({ ...localDashboardStatus, [key]: value });
  };

  const onSelectDate = (date: any) => {
    if (dateOptions.includes(date)) {
      setLocalDashboardStatus({
        ...localDashboardStatus,
        startDate: date,
        endDate: undefined,
      });
      return;
    }
    const [startDate, endDate] = date;
    if (startDate && endDate) {
      setLocalDashboardStatus({
        ...localDashboardStatus,
        startDate: startDate.toDate().getTime().toString(),
        endDate: endDate.toDate().getTime().toString(),
      });
    }
  };
  const rangeDate =
    localDashboardStatus.startDate && localDashboardStatus.endDate
      ? ([
          moment(parseInt(localDashboardStatus.startDate)),
          moment(parseInt(localDashboardStatus.endDate)),
        ] as any)
      : null;
  const presetDate =
    localDashboardStatus.startDate &&
    dateOptions.includes(localDashboardStatus.startDate)
      ? localDashboardStatus.startDate
      : "";
  useEffect(() => {
    form.setFieldsValue({
      title: localDashboardStatus.title,
      lookerId: localDashboardStatus.lookerId,
      models: localDashboardStatus.models,
      brand: localDashboardStatus.oem,
      specialCampaign: localDashboardStatus.specialCampaign,
      accessFilter: localDashboardStatus.accessFilter,
      account: localDashboardStatus.account,
    });
  }, [form, localDashboardStatus, dateOptions]);

  const blockEnabledField = Object.entries(localDashboardStatus).some(
    ([key, value]) => {
      return (
        ["title", "lookerId", "models", "accessFilter", "account"].includes(
          key,
        ) && !value
      );
    },
  );

  const FieldLabel = ({ title }: { title: string }) => {
    return (
      <>
        <Typography.Text className={styles.labelText}>{title}</Typography.Text>
        <Tooltip
          title={
            "One of these fields is required: Brand, Special Campaigns or Accounts"
          }
        >
          <InfoCircleOutlined style={{ fontSize: 10 }} />
        </Tooltip>
      </>
    );
  };

  const { filteredAccountsByDealerOem, isLoading: isLoadingAccounts } =
    useFillteredAccountsByOEM(localDashboardStatus.oem);

  const drawerForm = (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={{
        title: localDashboardStatus.title,
        lookerId: localDashboardStatus.lookerId,
        models: localDashboardStatus.models,
        accessFilter: localDashboardStatus.accessFilter,
        account: localDashboardStatus.account,
        specialCampaign: localDashboardStatus.specialCampaign,
        oem: localDashboardStatus.oem,
      }}
    >
      <Form.Item
        name="title"
        label="Dashboard Name"
        rules={[
          {
            required: true,
            whitespace: true,
            message: "Please enter dashboard name",
          },
        ]}
      >
        <Tooltip
          title={"This name will appear on the client side in the Metrics tab"}
          placement="left"
        >
          <Input
            value={localDashboardStatus.title}
            onChange={e => updateDashboardField("title", e.target.value)}
          />
        </Tooltip>
      </Form.Item>
      <Form.Item
        name="lookerId"
        label="Looker Dashboard ID"
        rules={[
          {
            required: true,
            whitespace: true,
            message: "Please enter an looker id",
          },
        ]}
      >
        <Tooltip title={"This ID should come from Looker."} placement="left">
          <Input
            value={localDashboardStatus.lookerId}
            onChange={e => updateDashboardField("lookerId", e.target.value)}
          />
        </Tooltip>
      </Form.Item>
      <Form.Item
        name="models"
        label="Models"
        rules={[
          {
            required: true,
            message: "Please enter models",
          },
        ]}
      >
        <Tooltip
          title={
            "This is a Looker-specific requirement. Choose from the pre-defined drop-downs, or type to create your own."
          }
          placement="left"
        >
          <Select
            mode="tags"
            style={{ width: "100%" }}
            value={localDashboardStatus.models || []}
            maxTagCount={2}
            onChange={modelTags => updateDashboardField("models", modelTags)}
            options={modelOptions}
          />
        </Tooltip>
      </Form.Item>
      <Form.Item
        name="accessFilter"
        label="Access Filter"
        rules={[
          {
            required: true,
            whitespace: true,
            message: "Please enter access filter",
          },
        ]}
      >
        <Tooltip title={"Select access filter"} placement="left">
          <Select
            style={{ width: "100%" }}
            allowClear={true}
            showSearch={true}
            value={localDashboardStatus.accessFilter || ""}
            onChange={filterTag =>
              updateDashboardField("accessFilter", filterTag)
            }
            options={modelOptions}
          />
        </Tooltip>
      </Form.Item>
      <Form.Item
        name="brand"
        label={<FieldLabel title={"Brand (Auto only)"} />}
      >
        <Tooltip title={"Select brands"} placement="left">
          <MultiSelectAllTags
            field="oem"
            className={styles.multiSelect}
            onChange={updateDashboardField}
            value={localDashboardStatus.oem || ""}
            options={brands.map(brand => ({
              title: brand.oem_name,
              value: brand.oem_name,
            }))}
          />
        </Tooltip>
      </Form.Item>
      <Form.Item
        name="specialCampaign"
        label={<FieldLabel title={"Special Campaigns"} />}
      >
        <Tooltip title={"Select special campaigns"} placement="left">
          <Select
            style={{ width: "100%" }}
            mode="tags"
            allowClear={true}
            showSearch={true}
            value={
              !!localDashboardStatus.specialCampaign
                ? localDashboardStatus.specialCampaign.split(",")
                : []
            }
            onChange={specialCampaignTag =>
              updateDashboardField(
                "specialCampaign",
                specialCampaignTag?.join(",") || "",
              )
            }
          >
            {specialCampaigns.map(specialCampaignOption => (
              <Select.Option
                key={specialCampaignOption}
                value={specialCampaignOption}
              >
                {specialCampaignOption}
              </Select.Option>
            ))}
          </Select>
        </Tooltip>
      </Form.Item>
      <Form.Item name="account" label={<FieldLabel title={"Accounts"} />}>
        <Tooltip title={"Select account names"} placement="left">
          <MultiSelectAllTags
            field="account"
            onChange={updateDashboardField}
            value={localDashboardStatus.account || ""}
            options={filteredAccountsByDealerOem.map(account => ({
              title: account.name,
              value: account.name,
            }))}
            disabled={isLoadingAccounts}
          />
        </Tooltip>
      </Form.Item>
      <Form.Item name="date" label="Date">
        <Radio.Group value={dateFormat}>
          <Radio
            value={"date"}
            onChange={() => {
              setDateFormat("date");
            }}
          >
            Date
          </Radio>
          <Radio
            value={"range"}
            onChange={() => {
              setDateFormat("range");
            }}
          >
            Range
          </Radio>
        </Radio.Group>
        {dateFormat === "date" && (
          <Select
            style={{ width: "60%" }}
            onChange={onSelectDate}
            value={presetDate}
          >
            {dateOptions.map(option => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        )}
        {dateFormat === "range" && (
          <DatePicker.RangePicker
            autoFocus={true}
            onChange={onSelectDate}
            value={rangeDate}
          />
        )}
      </Form.Item>

      <Form.Item label="Enabled">
        <Radio.Group value={localDashboardStatus.enabled}>
          <Radio
            value={true}
            disabled={blockEnabledField}
            onChange={() => updateDashboardField("enabled", true)}
          >
            True
          </Radio>
          <Radio
            value={false}
            onChange={() => updateDashboardField("enabled", false)}
          >
            False
          </Radio>
        </Radio.Group>
      </Form.Item>
    </Form>
  );

  return (
    <FormDrawer
      mode={Object.keys(editDashboardData).length ? "UPDATE" : "CREATE"}
      drawerWidth={450}
      dataName={"Dashboard"}
      processingData={false}
      processingUpload={isMutatingDashboard}
      showDrawer={showDashboardDrawer}
      validForm={true}
      drawerForm={drawerForm}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
    />
  );
};

export default DashboardDrawer;
