import { fabric } from "fabric";
import uuid from "uuid";
import {
  IExtendedFabricObject,
  ICustomVideoData,
} from "../../shared/types/designStudio";
import * as fabricHelpers from "../helpers.fabric";

interface videoProcessingOptions {
  fabricObj: fabric.Object;
  canvas: fabric.Canvas;
  isForAsset?: boolean;
  originalIndex?: number;
  shouldBeActive?: boolean;
  htmlCanvas?: HTMLCanvasElement;
  onCompleteCallback?: (recorder?: any) => void;
}

export const prepareVideoForInitRender = ({
  fabricObj,
  canvas,
  originalIndex,
  isForAsset,
  onCompleteCallback,
  shouldBeActive,
}: videoProcessingOptions) => {
  const obj = fabricObj as IExtendedFabricObject;
  const hasVideoSrc = !!(obj.customData as ICustomVideoData).videoSrc;
  const isBgVideo = obj.customType === "theme_background" && hasVideoSrc;
  const src = (obj.customData as ICustomVideoData).videoSrc;

  if (!src) {
    /*
        Possible TO DO: Implement Layer Error system:
        Show warning sign on certain layers and/or load placeholder
        image for "broken" objects
    */
    return;
  }

  const imageSrc = src.replace(".mp4", ".png");

  const imagePromise = new Promise<fabric.Image>((resolve, reject) =>
    fabric.Image.fromURL(
      imageSrc,
      img =>
        img
          ? resolve(img)
          : reject(new Error(`Logo image is: ${JSON.stringify(img)}`)),
      {
        crossOrigin: "anonymous",
        top: isBgVideo ? 0 : obj.top,
        left: isBgVideo ? 0 : obj.left,
        width: obj.width,
        height: obj.height,
        scaleX: obj.scaleX,
        scaleY: obj.scaleY,
        opacity: obj.opacity,
        name: obj.name || uuid(),
      },
    ),
  );

  imagePromise.then(loadedImage => {
    const { layerName, duration } = obj.customData as ICustomVideoData & {
      layerName: string;
    };

    const newExtObj = fabricHelpers.extendFabricObject({
      objectType: isBgVideo ? "theme_background" : "selected_video",
      object: loadedImage,
      properties: {
        duration,
        layerName,
        videoSrc: src,
      },
    });

    if (isForAsset) {
      newExtObj.set({ evented: false, selectable: false });
    }

    if (shouldBeActive) {
      // this field is only set to true when starting/stopping video in editor
      canvas.discardActiveObject();
    }

    canvas.remove(obj);

    if (originalIndex !== undefined && originalIndex !== -1) {
      canvas.insertAt(newExtObj, originalIndex, false);
    } else {
      canvas.add(newExtObj);
    }

    if (shouldBeActive) {
      canvas.setActiveObject(newExtObj);
    }

    const videoElements = document.getElementsByTagName("video");

    for (let i = videoElements.length - 1; i >= 0; i--) {
      videoElements[i].parentNode?.removeChild(videoElements[i]);
    }

    canvas.renderAll();

    onCompleteCallback?.();
  });
};

export const renderPlayVideoObject = ({
  fabricObj,
  canvas,
  originalIndex,
  isForAsset,
  shouldBeActive,
}: videoProcessingOptions) => {
  const { customData, customType } = fabricObj as IExtendedFabricObject;
  const isBgVideo = customType === "theme_background";
  const srcUrl = (fabricObj as fabric.Image).getSrc();
  const propsToUse = {
    top: isBgVideo ? 0 : fabricObj.top,
    left: isBgVideo ? 0 : fabricObj.left,
    width: fabricObj.width,
    height: fabricObj.height,
    scaleX: fabricObj.scaleX,
    scaleY: fabricObj.scaleY,
    opacity: fabricObj.opacity,
    name: fabricObj.name,
    // TO DO: add mediaType to fabricObj for tracking gif, webm, mp4, etc. type
  };

  if (srcUrl && !srcUrl.endsWith(".mp4")) {
    const videoSrc = srcUrl.replace(".png", ".mp4");
    const fabricVideo = fabricHelpers.createVideoObject(videoSrc, {
      width: propsToUse.width,
      height: propsToUse.height,
      top: isBgVideo ? 0 : propsToUse.top,
      left: isBgVideo ? 0 : propsToUse.left,
    });

    const { layerName, duration } = customData as ICustomVideoData & {
      layerName: string;
    };

    const extendedVideo = fabricHelpers.extendFabricObject({
      object: fabricVideo,
      objectType: isBgVideo ? "theme_background" : "selected_video",
      properties: {
        videoSrc,
        layerName,
        duration,
      },
    });

    extendedVideo.set({
      ...propsToUse,
      top: isBgVideo ? 0 : propsToUse.top,
      left: isBgVideo ? 0 : propsToUse.left,
    });
    if (isForAsset) {
      extendedVideo.set({ evented: false, selectable: false });
    }

    if (shouldBeActive) {
      // this field is only set to true when starting/stopping video in editor
      canvas.discardActiveObject();
    }

    canvas.remove(fabricObj);

    if (originalIndex !== undefined) {
      canvas.insertAt(extendedVideo, originalIndex, false);
    } else {
      canvas.add(extendedVideo);
    }

    if (shouldBeActive) {
      canvas.setActiveObject(extendedVideo);
    }

    (fabricVideo.getElement() as HTMLVideoElement).play();
    fabric.util.requestAnimFrame(function render() {
      try {
        canvas.renderAll();
        fabric.util.requestAnimFrame(render);
      } catch (error) {}
    });
    return;
  }
};

type GetMediaElCheck = {
  url: string;
  width: number;
  height: number;
};
const getVideoElement = ({ url, width, height }: GetMediaElCheck) => {
  const videoE = document.createElement("video");
  videoE.width = width;
  videoE.height = height;
  videoE.muted = true;
  videoE.loop = true;
  videoE.crossOrigin = "anonymous";
  const source = document.createElement("source");
  source.src = url;
  source.type = "video/mp4";
  videoE.appendChild(source);
  return videoE;
};

const getImageElement = ({ url, width, height }: GetMediaElCheck) => {
  const img = document.createElement("img");
  img.src = url;
  img.width = width;
  img.height = height;
  img.crossOrigin = "anonymous";
  return img;
};

type GetMediaEl = GetMediaElCheck & { type: "video" | "img" };
const getMediaElement = ({ url, width, height, type }: GetMediaEl) => {
  if (type === "video") return getVideoElement({ url, width, height });
  return getImageElement({ url, width, height });
};

type ResetFabricObjType = {
  canvas: fabric.Canvas;
  obj: IExtendedFabricObject;
};
const resetFabricObj = ({ canvas, obj }: ResetFabricObjType) => {
  canvas.remove(obj);
  canvas.add(obj);
};

type ObjAttrsCheck = {
  isThmBg: boolean;
  obj: IExtendedFabricObject;
};
const getObjAttrs = ({ isThmBg, obj }: ObjAttrsCheck) => {
  return {
    left: isThmBg ? 0 : obj.left,
    top: isThmBg ? 0 : obj.top,
    scaleX: isThmBg ? 1 : obj.scaleX,
    scaleY: isThmBg ? 1 : obj.scaleY,
  };
};

type PlayVideoType = {
  isThmBg: boolean;
  canvas: fabric.Canvas;
};
const playVideoCanvas = ({ isThmBg, canvas }: PlayVideoType) => {
  canvas.getObjects().forEach(object => {
    const obj = object as IExtendedFabricObject;
    const { customType } = obj;
    const { customData } = obj as { customData: ICustomVideoData };

    if (!customData?.videoSrc) {
      return resetFabricObj({ canvas, obj });
    }
    const url = customData.videoSrc;

    if (customType === "theme_background" || customType === "selected_video") {
      const width = (isThmBg ? canvas.width : obj.width) || 0;
      const height = (isThmBg ? canvas.height : obj.height) || 0;
      const videoEl = getMediaElement({ url, width, height, type: "video" });
      const fabVideo = new fabric.Image(videoEl, getObjAttrs({ isThmBg, obj }));
      fabVideo.set({ evented: false, selectable: false });
      canvas.add(fabVideo);
      (fabVideo.getElement() as HTMLVideoElement).play();
      canvas.renderAll();
      fabric.util.requestAnimFrame(function render() {
        try {
          canvas.renderAll();
          fabric.util.requestAnimFrame(render);
        } catch (error) {}
      });
    } else {
      resetFabricObj({ canvas, obj });
    }
  });
};

const pauseVideoCanvas = ({ isThmBg, canvas }: PlayVideoType) => {
  canvas.getObjects().forEach(object => {
    const obj = object as IExtendedFabricObject;
    const { customType } = obj;
    const { customData } = obj as { customData: ICustomVideoData };

    if (!customData?.videoSrc) {
      return resetFabricObj({ canvas, obj });
    }
    const videoUrl = customData.videoSrc;
    const url = videoUrl.replace("mp4", "png");
    if (customType === "theme_background" || customType === "selected_video") {
      const width = (isThmBg ? canvas.width : obj.width) || 0;
      const height = (isThmBg ? canvas.height : obj.height) || 0;
      const imgEl = getMediaElement({ url, width, height, type: "img" });
      const fabImg = new fabric.Image(imgEl, getObjAttrs({ isThmBg, obj }));
      fabImg.set({ evented: false, selectable: false });
      canvas.add(fabImg);
      canvas.renderAll();
    } else {
      resetFabricObj({ canvas, obj });
    }
  });
};

type VideoInitType = {
  canvas: fabric.Canvas;
  playVideo?: boolean;
};
export const prepareAllVideosInCanvas = async ({
  canvas,
  playVideo,
}: VideoInitType) => {
  const isThmBg = !!canvas.getObjects().find(object => {
    const obj = object as IExtendedFabricObject;
    const { customType: type } = obj;
    const { customData } = obj as { customData: ICustomVideoData };
    return type === "theme_background" && !!customData.videoSrc;
  });

  if (playVideo) {
    playVideoCanvas({ isThmBg, canvas });
  } else {
    pauseVideoCanvas({ isThmBg, canvas });
  }
};

export const blobToDataURL = (
  blob: Blob,
  callback: (dataUrl: string | ArrayBuffer | null) => void,
) => {
  const reader = new FileReader();
  reader.onload = event => {
    if (event?.target?.result) {
      callback(event.target.result);
    }
  };
  reader.readAsDataURL(blob);
};

export const returnCanvasContainsVideo = (canvas: fabric.Canvas) => {
  const videoObjects = (canvas.getObjects() as IExtendedFabricObject[]).filter(
    obj => obj.customType === "selected_video",
  );
  return videoObjects.length > 0;
};

export const setSrcForEachVideoObjInCanvasJson = (
  canvasJson: {
    [key: string]: string | number | fabric.Object[];
  } | null,
) => {
  if (!canvasJson) {
    return;
  }

  for (const obj of canvasJson.objects as IExtendedFabricObject[]) {
    if (obj.customType !== "selected_video") {
      continue;
    }
    (obj as any).src = (obj.customData as ICustomVideoData).videoSrc.replace(
      ".mp4",
      ".png",
    );
  }
};
