import { memo } from "react";
import { Form, Space, Tooltip, Typography } from "antd";
import WarningResult from "./WarningResult";
import LegacyProductSetSelect from "shared/components/select/LegacyProductSetSelect";

import {
  IInstantExperienceToLoad,
  IProductSetElement,
} from "shared/types/adLibrary";
import { IAccountRecord } from "shared/types/accountManagement";
import { EnterOutlined, StarTwoTone } from "@ant-design/icons";

interface IProps {
  selectedDealer?: IAccountRecord;
  instantExperiencesToLoad: IInstantExperienceToLoad[];
  setInstantExperiencesToLoad: React.Dispatch<
    React.SetStateAction<IInstantExperienceToLoad[]>
  >;
}

function LoadProductSets({
  selectedDealer,
  instantExperiencesToLoad,
  setInstantExperiencesToLoad,
}: IProps) {
  const productCatalogId = selectedDealer?.details?.facebook?.fbCatalogId || "";

  if (!productCatalogId) {
    return (
      <WarningResult
        subjectName={selectedDealer?.dealerName}
        message="does not have a Facebook product catalog ID to use"
      />
    );
  }

  const rootInstantExperience = instantExperiencesToLoad.find(ie => ie.isRoot)!;

  return (
    <Space direction="vertical">
      {instantExperiencesToLoad.map((ie, ieIndex) => (
        <Space
          key={`ie-product-sets-${ieIndex}`}
          direction="vertical"
          style={!ie.isRoot ? { marginLeft: "2em" } : undefined}
        >
          {ie.isRoot && (
            <div>
              <Typography.Title level={5} style={{ display: "inline" }}>
                {ie.name}{" "}
              </Typography.Title>
              <Tooltip title="This is the root Instant Experience">
                <StarTwoTone
                  style={{ opacity: 0.75, fontSize: 12, cursor: "help" }}
                />
              </Tooltip>
            </div>
          )}
          {!ie.isRoot && (
            <div>
              <Tooltip
                title={`This instant experience is referenced within ${rootInstantExperience.name}`}
              >
                <EnterOutlined
                  style={{
                    opacity: 0.75,
                    transform: "rotateY(180deg)",
                    cursor: "help",
                  }}
                />
              </Tooltip>{" "}
              <Typography.Text>
                <b>{ie.name}</b>
              </Typography.Text>
            </div>
          )}
          {ie.body_elements?.map(
            (bodyElement, bodyElementIndex) =>
              bodyElement.element_type === "ELEMENT_GROUP" && (
                <Form.Item
                  key={`product-set-select-${bodyElementIndex}`}
                  label={bodyElement.name}
                  style={{ marginBottom: "0.5em", marginLeft: "0.25em" }}
                >
                  <LegacyProductSetSelect
                    productCatalogId={productCatalogId}
                    onSelect={value => {
                      const updatedElement: IProductSetElement = {
                        ...bodyElement,
                        product_set_id: value as string,
                      };

                      const elementIndex = ie.body_elements!.findIndex(
                        element => element.id === bodyElement.id,
                      );

                      const newBodyElements = [...ie.body_elements!];
                      newBodyElements.splice(elementIndex, 1, updatedElement);

                      const updatedIE: IInstantExperienceToLoad = {
                        ...ie,
                        body_elements: newBodyElements,
                      };

                      const newIEsToLoad = [...instantExperiencesToLoad];

                      newIEsToLoad.splice(ieIndex, 1, updatedIE);
                      setInstantExperiencesToLoad(newIEsToLoad);
                    }}
                  />
                </Form.Item>
              ),
          )}
        </Space>
      ))}
    </Space>
  );
}

export default memo(LoadProductSets);
