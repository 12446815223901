import { Key, memo } from "react";
import Typography from "antd/lib/typography";
import { useAppSelector } from "shared/hooks/useAppSelector";
import AdSelectTable from "./updateAds/AdSelectTable";
import styles from "./UpdateAds.module.scss";
import { Collapse, Divider } from "antd";
import RulePanel from "./updateAds/RulePanel";
import { setAdLoadState } from "redux/adLibrary/adLibrary.slice";
import { useDispatch } from "react-redux";
import RuleErrors from "./updateAds/RuleErrors";
import { useAdRuleOptions } from "screens/adLibrary/shared/hooks/useAdRuleOptions";
import AccountSelect from "./updateAds/AccountSelect";
import useSetupUpdateAdsProcess from "screens/adLibrary/shared/hooks/useSetupUpdateAdsProcess";
import useBuildAdsToUpdate from "../shared/hooks/selectStep/useBuildAdsToUpdate";
import {
  selectDestination,
  selectSelectedAccounts,
  selectSelectedAds,
} from "redux/adLibrary/adLibrary.selectors";
import { useMapRulesToLocations } from "./updateAds/useMapRulesToLocations";
import { useSyncDataWithBackend } from "./updateAds/useSyncDataWithBackend";
import { isEmpty } from "lodash";

const { Panel } = Collapse;

const UpdateAds = () => {
  const dispatch = useDispatch();

  const selectedAds = useAppSelector(selectSelectedAds);
  const destination = useAppSelector(selectDestination);
  const selectedAccounts = useAppSelector(selectSelectedAccounts);

  useSetupUpdateAdsProcess();
  useMapRulesToLocations();
  useSyncDataWithBackend();
  useBuildAdsToUpdate();

  const { adRuleOptions, isLoadingOptions } = useAdRuleOptions();

  const checkedAdIds = destination?.selectedAds?.map(({ id }) => id) ?? [];
  const rulePanelKey = checkedAdIds.sort().join(",");

  const onSelectionChange = (selectedRowKeys: Key[]) => {
    const selectedAdsForDestination =
      selectedAds?.filter(ad => selectedRowKeys.includes(ad.id)) ?? [];

    const updatedData = {
      ...(destination || {}),
      selectedAds: selectedAdsForDestination,
    };

    dispatch(
      setAdLoadState({
        prop: "destination",
        data: updatedData,
      }),
    );
  };

  return (
    <div className={styles.updateAdsContainer}>
      <div className={styles.selectAdsContainer}>
        <Typography.Text>Select Ads</Typography.Text>
        <AdSelectTable
          ads={selectedAds ?? []}
          onSelectionChange={onSelectionChange}
        />
      </div>
      <Divider type="vertical" className={styles.divider} />
      <div className={styles.destinationContainer}>
        <AccountSelect />
        <RuleErrors />
        {!isEmpty(selectedAccounts) && (
          <Collapse
            bordered={false}
            defaultActiveKey={["1", "2", "3"]}
            className={styles.panelGroup}
          >
            <Panel header="Campaign" key="1" className={styles.rulePanel}>
              <RulePanel
                key={rulePanelKey}
                isLoadingOptions={isLoadingOptions}
                adPart="campaign"
                disabled={checkedAdIds.length === 0}
                adRuleOptions={adRuleOptions.campaign}
              />
            </Panel>
            <Panel header="Ad Set" key="2" className={styles.rulePanel}>
              <RulePanel
                key={rulePanelKey}
                isLoadingOptions={isLoadingOptions}
                adPart="adset"
                disabled={checkedAdIds.length === 0}
                adRuleOptions={adRuleOptions.adset}
              />
            </Panel>
            <Panel header="Ad" key="3" className={styles.rulePanel}>
              <RulePanel
                key={rulePanelKey}
                isLoadingOptions={isLoadingOptions}
                adPart="ad"
                disabled={checkedAdIds.length === 0}
                adRuleOptions={adRuleOptions.ad}
              />
            </Panel>
          </Collapse>
        )}
      </div>
    </div>
  );
};

export default memo(UpdateAds);
