// to do: see if there are other fontWeights we can use
export const fontWeightOptions: string[] = ["lighter", "normal", "bold"];

export const customFonts: string[] = [
  "Acura Bespoke-Bold",
  "Acura Bespoke-Book",
  "Acura Bespoke-Light",
  "Acura Bespoke-Regular",
  "Acura-Performance",
  "Acura Performance-V2",
  "Acura Futura-CondensedBoldOblique",
  "ApexNew-Medium",
  "ApexNew-Bold",
  "ApexNew-BookItalic",
  "ApexNew-Book",
  "ApexNew-Heavy",
  "ApexNew-HeavyItalic",
  "ApexNew-LightItalic",
  "ApexNew-Light",
  "ApexNew-MediumItalic",
  "ApexNew-BoldItalic",
  "ApexNew-Thin",
  "ApexNew-ThinItalic",
  "ApexNew-Ultra",
  "ApexNew-UltraItalic",
  "ArialCE",
  "ArialCEBold",
  "ArialCEBoldItalic",
  "ArialCEItalic",
  "AudiTypeV03-Bold",
  "AudiTypeV03-BoldItalic",
  "AudiTypeV03-ExtendedBoldIta",
  "AudiTypeV03-ExtendedBold",
  "AudiTypeV03-ExtendedItalic",
  "AudiTypeV03-ExtendedNormal",
  "AudiTypeV03-Italic",
  "AudiTypeV03-Normal",
  "Avenir 35 Light",
  "Avenir 35 LightOblique",
  "Avenir 45 Book",
  "Avenir 55 Roman",
  "Avenir 45 BookOblique",
  "Avenir 65 Medium",
  "Avenir 65 MediumOblique",
  "Avenir 85 HeavyOblique",
  "Avenir 85 Heavy",
  "Avenir 95 Black",
  "BMWTypeNextLatin-Bold",
  "BMWTypeNextLatin-Light",
  "BMWTypeNextLatin-Regular",
  "BMWTypeNextLatin-Thin",
  "BMWTypeNextLatinTT-Bold",
  "BMWTypeNextLatinTT-Light",
  "BMWTypeNextLatinTT-Regular",
  "BMWTypeNextLatinTT-Thin",
  "CadillacSansA-Bold",
  "CadillacSansA-BoldItalic",
  "CadillacSansA-Book",
  "CadillacSansA-BookItalic",
  "CadillacSansA-ExtraBold",
  "CadillacSansA-ExtraBoldItalic",
  "CadillacSansA-Light",
  "CadillacSansA-LightItalic",
  "CadillacSansA-MediumItalic",
  "CadillacSansA-Medium",
  "CadillacSansA-SemiBold",
  "CadillacSansA-SemiBoldItalic",
  "CadillacSansA-Thin",
  "CadillacSansA-ThinItalic",
  "CadillacSerif-Bold",
  "CadillacSerif-BoldItalic",
  "CadillacSerif-Heavy",
  "CadillacSerif-HeavyItalic",
  "CadillacSerif-Italic",
  "CadillacSerif-Medium",
  "CadillacSerif-MediumItalic",
  "CadillacSerif-Regular",
  "CadillacSerif-SemiBold",
  "CadillacSerif-SemiBoldItalic",
  "CadillacGothic-WideMedium",
  "CadillacGothic-WideBold",
  "CadillacGothic-Regular",
  "CadillacGothic-Medium",
  "CadillacGothic-Bold",
  "CadillacGothic-WideRegular",

  "Louis-Bold",
  "Louis-BoldItalic",
  "Louis-CondensedDemi",
  "Louis-CondensedRegular",
  "Louis-Regular",
  "Louis-Italic",
  "DIN Alternate Bold",
  "DIN Condensed Bold",
  "DINOT-BlackItalic",
  "DINOT-Black",
  "DINOT-BoldItalic",
  "DINOT-Bold",
  "DINOT-CondBlack",
  "DINOT-Cond",
  "DINOT-CondBlackIta",
  "DINOT-CondBold",
  "DINOT-CondIta",
  "DINOT-CondBoldIta",
  "DINOT-CondLight",
  "DINOT-CondLightIta",
  "DINOT-CondMedium",
  "DINOT-CondMediIta",
  "DINOT-Light",
  "DINOT-Italic",
  "DINOT-Medium",
  "DINOT-LightItalic",
  "DINOT-MediumItalic",
  "DINOT",
  "Frutiger LT 45 Light",
  "Frutiger LT 47 Light Condensed",
  "Frutiger LT 55 Roman",
  "Frutiger LT 65 Bold",
  "Frutiger LT 67 Bold Condensed",
  "Frutiger LT Std 46 Light Italic",
  "Futura",
  "FuturaBold",
  "FuturaExtended",
  "FuturaStd-Light",
  "FuturaStd-LightOblique",
  "FuturaStd-MediumOblique",
  "Futura Condensed Bold",
  "FordAntennaCompWGL-BlackItalic",
  "FordAntennaCompWGL-Black",
  "FordAntennaCompWGL-BoldItalic",
  "FordAntennaCompWGL-Bold",
  "FordAntennaCompWGL-ExtLight",
  "FordAntennaCompWGL-ExtLigIt",
  "FordAntennaCompWGL-LightIta",
  "FordAntennaCompWGL-Light",
  "FordAntennaCompWGL-Med",
  "FordAntennaCompWGL-MedItalic",
  "FordAntennaCompWGL-Reg",
  "FordAntennaCompWGL-RegItalic",
  "FordAntennaCompWGL-Semibold",
  "FordAntennaCompWGL-SemiboldIta",
  "FordAntennaCompWGL-Thin",
  "FordAntennaCompWGL-ThinItalic",
  "FordAntennaCondWGL-Black",
  "FordAntennaCondWGL-BlackItalic",
  "FordAntennaCondWGL-Bold",
  "FordAntennaCondWGL-BoldItalic",
  "FordAntennaCondWGL-ExtLightIta",
  "FordAntennaCondWGL-ExtraLight",
  "FordAntennaCondWGL-Light",
  "FordAntennaCondWGL-LightItalic",
  "FordAntennaCondWGL-Med",
  "FordAntennaCondWGL-MedItalic",
  "FordAntennaCondWGL-Reg",
  "FordAntennaCondWGL-RegItalic",
  "FordAntennaCondWGL-Thin",
  "FordAntennaExtraCondWGL-Black",
  "FordAntennaCondWGL-ThinItalic",
  "FordAntennaExtraCondWGL-BlackIt",
  "FordAntennaExtraCondWGL-Bold",
  "FordAntennaExtraCondWGL-ExtLig",
  "FordAntennaExtraCondWGL-BoldIt",
  "FordAntennaExtraCondWGL-ExtLigIt",
  "FordAntennaExtraCondWGL-LigIt",
  "FordAntennaExtraCondWGL-Light",
  "FordAntennaExtraCondWGL-Med",
  "FordAntennaExtraCondWGL-MedIt",
  "FordAntennaExtraCondWGL-Reg",
  "FordAntennaExtraCondWGL-RegIta",
  "FordAntennaExtraCondWGL-Semibold",
  "FordAntennaExtraCondWGL-SemiboldIta",
  "FordAntennaExtraCondWGL-Thin",
  "FordAntennaExtraCondWGL-ThinIta",
  "FordAntennaWGL-Black",
  "FordAntennaWGL-BlackItalic",
  "FordAntennaWGL-Bold",
  "FordAntennaWGL-BoldItalic",
  "FordAntennaWGL-ExtraLight",
  "FordAntennaWGL-ExtraLightItalic",
  "FordAntennaWGL-Light",
  "FordAntennaWGL-LightItalic",
  "FordAntennaWGL-Medium",
  "FordAntennaWGL-MediumItalic",
  "FordAntennaWGL-Regular",
  "FordAntennaWGL-RegularItalic",
  "FordAntennaWGL-Semibold",
  "FordAntennaWGL-SemiboldItalic",
  "FordAntennaWGL-Thin",
  "FordAntennaWGL-ThinItalic",
  "Genesis Sans Head Bold",
  "Genesis Sans Head Light",
  "Genesis Sans Head Medium",
  "Genesis Sans Head Regular",
  "GenesisSansText-Bold",
  "GenesisSansText-BoldItalic",
  "GenesisSansText-Italic",
  "GenesisSansText-Medium",
  "GenesisSansText-MediumItalic",
  "GenesisSansText-Regular",
  "Gill Sans Bold Italic",
  "Gill Sans Heavy Italic",
  "Gill Sans Bold",
  "Gill Sans Italic",
  "Gill Sans Heavy",
  "Gill Sans Light",
  "Gill Sans Light Italic",
  "Gill Sans Medium",
  "Gill Sans Medium Italic",
  "GillSans Condensed Bold",
  "Gill Sans",
  "GillSans Condensed",
  "Gotham-Book",
  "Gotham-BookItalic",
  "Gotham-ExtraLight",
  "Gotham-ExtraLightItalic",
  "Gotham-Light",
  "Gotham-LightItalic",
  "Gotham-Medium",
  "Gotham-MediumItalic",
  "Gotham-BoldItalic",
  "Gotham-Rounded-Book",
  "Gotham-Rounded-Bold-Italic",
  "Gotham-Rounded-Medium-Italic",
  "Gotham-Rounded-Medium",
  "Gotham-Thin",
  "Gotham-ThinItalic",
  "Gotham-Ultra",
  "Gotham-UltraItalic",
  "Gotham-bold",
  "Gotham-extra-narrow-bold-italic",
  "Gotham-extra-narrow-bold",
  "Gotham-extra-narrow-book-italic",
  "Gotham-extra-narrow-book",
  "Gotham-Rounded-Light",
  "Gotham-Rounded-Book-Italic",
  "Gotham-Rounded-Bold",
  "Gotham-rounded-light-italic",
  "GothamNarrow-Black",
  "GothamNarrow-BookItalic",
  "GothamNarrow-Light",
  "GothamNarrow-Medium",
  "GothamNarrow-MediumItalic",
  "GothamNarrow-LightItalic",
  "GothamNarrow-Thin",
  "GothamNarrow-ThinItalic",
  "GothamNarrow-Ultra",
  "GothamNarrow-UltraItalic",
  "GothamNarrow-XLight",
  "GothamNarrow-XLightItalic",
  "Gotham-extra-narrow-medium-italic",
  "GothamNarrow-Book",
  "Gotham-extra-narrow-medium",
  "Gotham-extra-narrow-light",
  "Gotham-extra-narrow-light-italic",
  "Helvetica-Bold",
  "Helvetica-Light",
  "Helvetica-Regular",
  "HelveticaNeueLTStd-BdCn",
  "HelveticaNeueLTStd-BlkCn",
  "HelveticaNeueLTStd-Cn",
  "HelveticaNeueLTStd-HvCn",
  "HelveticaNeueLTStd-MdCn",
  "HelveticaNeueLTStd-XBlkCn",
  "InfinitiBrand-Bold",
  "InfinitiBrand-Light",
  "InfinitiBrand-Regular",
  "InfinitiBrandCY-Bold",
  "InfinitiBrandCY-Light",
  "InfinitiBrandCY-Regular",
  "InfinitiBrandL1-Bold",
  "InfinitiBrandL1-Light",
  "InfinitiBrandL1-Regular",
  "InfinitiBrandLP-Bold",
  "InfinitiBrandLP-Light",
  "InfinitiBrandLP-Regular",
  "AvenirNextRoundedPro-Bold",
  "AvenirNextRoundedPro-BoldIt",
  "AvenirNextRoundedPro-Demi",
  "AvenirNextRoundedPro-DemiIt",
  "AvenirNextRoundedPro-Italic",
  "AvenirNextRoundedPro-MedIt",
  "AvenirNextRoundedPro-Med",
  "AvenirNextRoundedPro-Reg",
  "HyundaiSansHead-Bold",
  "HyundaiSansHead-Light",
  "HyundaiSansHead-Medium",
  "HyundaiSansHead-Regular",
  "HyundaiSansText-Bold",
  "HyundaiSansText-BoldItalic",
  "HyundaiSansText-Italic",
  "HyundaiSansText-Medium",
  "HyundaiSansText-MediumItalic",
  "HyundaiSansText-Regular",
  "Jaguar-Bold",
  "Jaguar-ExtraBold",
  "Jaguar-Regular",
  "JaguarModern-Bold",
  "JaguarModern-Regular",
  "JLREmeric-ExtraLight",
  "JLREmeric-Regular",
  "JLREmeric-SemiBold",
  "DesignKB",
  "DesignKL",
  "DesignKM",
  "KiaSignatureOTFBold",
  "KiaSignatureOTFLight",
  "KiaSignatureOTFRegular",
  "LandRover-Bold",
  "LandRover-Demi",
  "LandRover-Light",
  "LandRover-Medium",
  "Liberator-Heavy",
  "Liberator-Light",
  "Liberator-Medium",
  "Lincoln-ProximaNova-Bold",
  "Lincoln-ProximaNova-BoldIt",
  "Lincoln-ProximaNova-RegIt",
  "Lincoln-ProximaNova-Reg",
  "LincolnMillerB-BlackItalic",
  "LincolnMillerB-Black",
  "LincolnMillerB-BoldItalic",
  "LincolnMillerB-Bold",
  "LincolnMillerB-Light",
  "LincolnMillerB-Italic",
  "LincolnMillerB-Roman",
  "LincolnMillerB-SemiboldItalic",
  "LincolnMillerBCond-Black",
  "LincolnMillerBCond-BlackItalic",
  "LincolnMillerBCond-BoldItalic",
  "LincolnMillerBCond-Bold",
  "LincolnMillerBCond-Italic",
  "LincolnMillerBCond-Light",
  "LincolnMillerBCond-LightItalic",
  "LincolnMillerBCond-Roman",
  "LincolnMillerBCond-Semibold",
  "LincolnMillerBCond-SemiboldItalic",
  "LincolnMillerB-Semibold",
  "LincolnMillerB-LightItalic",
  "Lincoln-ProximaNova-Bold",
  "Lincoln-ProximaNova-BoldIt",
  "Lincoln-ProximaNova-Reg",
  "Lincoln-ProximaNova-RegIt",
  "LincolnMillerB-Italic",
  "LincolnMillerB-Roman",
  "LincolnMillerB-SemiboldItalic",
  "MINISerif-Regular",
  "MINISerif-Bold",
  "MiniRg",
  "Everett-Bold",
  "Everett-Light",
  "Everett-Medium",
  "Everett-Regular",
  "Everett-Thin",
  "EverettMono-Regular",
  "MazdaType-Bold",
  "MazdaType-BoldItalic",
  "MazdaType-Italic",
  "MazdaType-Medium",
  "MazdaType-MediumItalic",
  "MazdaType-Regular",
  "Muli",
  "Muli-BoldItalic",
  "Muli-Bold",
  "Muli-ExtraLightItalic",
  "Muli-ExtraLight",
  "Muli-Italic",
  "Muli-Light",
  "Muli-LightItalic",
  "Muli-Semi-BoldItalic",
  "Muli-SemiBold",
  "Muli-Black",
  "Nobel Black Italic",
  "Nobel Black",
  "Nobel Bold Italic",
  "Nobel Bold",
  "Nobel Book",
  "Nobel Book Italic",
  "Nobel Light Italic",
  "Nobel Light",
  "Nobel Regular Italic",
  "Nobel Regular",
  "Novecentosanswide-Bold",
  "Novecentosanswide-DemiBold",
  "Novecentosanswide-Light",
  "Novecentosanswide-Medium",
  "Novecentosanswide-Normal",
  "Roboto-Black",
  "Roboto-BlackItalic",
  "Roboto-Bold",
  "Roboto-Italic",
  "Roboto-BoldItalic",
  "Roboto-LightItalic",
  "Roboto-Light",
  "Roboto-MediumItalic",
  "Roboto-Medium",
  "Roboto-Thin",
  "Roboto-Regular",
  "Roboto-ThinItalic",
  "SlatePro-Black",
  "Stratum1-Black",
  "Stratum1-Bold",
  "Stratum1-Light",
  "Stratum1-Medium",
  "Stratum1-Regular",
  "Stratum1-Thin",
  "Stratum2-Black",
  "Stratum2-Bold",
  "Stratum2-Light",
  "Stratum2-Medium",
  "Stratum2-Regular",
  "Stratum2-Thin",
  "TradeGothicLTStd-Bd2Obl",
  "TradeGothicLTStd-Bold",
  "TradeGothicLTStd-BoldExt",
  "TradeGothicLTStd-Cn18",
  "TradeGothicLTStd-BoldObl",
  "TradeGothicLTStd-Extended",
  "TradeGothicLTStd-Cn18Obl",
  "TradeGothicLTStd-Obl",
  "TradeGothicLTStd-Light",
  "TradeGothicLTStd",
  "Volvo Novum-BoldItalic",
  "Volvo Novum-Italic",
  "Volvo Novum-Bold",
  "Volvo Novum-Light",
  "Volvo Novum-LightItalic",
  "Volvo Novum-Regular",
  "Volvo Novum-SemiLight",
  "Volvo Novum-Medium",
  "Volvo Novum-MediumItalic",
  "Volvo Novum-SemiLightItalic",
  "Volvo Sans Regular",
  "VolvoSansPro-Light",
  "VolvoBroadPro",
  "VolvoSans Bold",
  "CorpABol",
  "CorpABolIta",
  "CorpADem",
  "CorpADemIta",
  "CorpALig",
  "CorpALigIta",
  "CorpAReg",
  "CorpARegIta",
  "CorpSBol",
  "CorpSBolIta",
  "CorpSDemIta",
  "CorpSDem",
  "CorpSExtBol",
  "CorpSLig",
  "CorpSReg",
  "Nissan Brand Bold",
  "Nissan Brand Italic",
  "Nissan Brand Light",
  "Nissan Brand Regular",
  "PorscheNext-Bold",
  "PorscheNext-BoldItalic",
  "PorscheNext-Italic",
  "PorscheNext-Regular",
  "PorscheNext-Thin",
  "PorscheNext-ThinItalic",
  "ToyotaType-Black",
  "ToyotaType-BlackIt",
  "ToyotaType-Bold",
  "ToyotaType-BoldIt",
  "ToyotaType-BookIt",
  "ToyotaType-Book",
  "ToyotaType-Light",
  "ToyotaType-LightIt",
  "ToyotaType-Regular",
  "ToyotaType-RegularIt",
  "ToyotaType-Semibold",
  "ToyotaType-SemiboldIt",
  "Univers_47_CondensedLight_Bold_Italic",
  "Univers_55",
  "Univers_55_Bold",
  "Univers_55_Italic",
  "VWHead-Bold",
  "VWHead-BoldItalic",
  "VWHead-ExtraBold",
  "VWHead-Regular",
  "VWHead-RegularItalic",
  "VWHead-Light",
  "VWText-Bold",
  "VWText-BoldItalic",
  "VWText-Regular",
  "VWText-Light",
  "VWText-RegularItalic",

  "FocoBlack",
  "FocoBlack-Italic",
  "Foco-Bold",
  "Foco-BoldItalic",
  "Foco-Italic",
  "Foco-Light",
  "Foco-LightItalic",
  "Foco-Regular",

  "AvenirNextLTPro-Medium",
  "AvenirNextLTPro-It",
  "AvenirNextLTPro-Bold",
  "AvenirNextLTPro-Regular",

  "IBMPlexSans-Light",
  "EBGaramond-Medium",

  "CircularXX-Black",
  "CircularXX-BlackItalic",
  "CircularXX-Bold",
  "CircularXX-BoldItalic",
  "CircularXX-Book",
  "CircularXX-BookItalic",
  "CircularXX-ExtraBlack",
  "CircularXX-ExtraBlackItalic",
  "CircularXX-Italic",
  "CircularXX-Light",
  "CircularXX-LightItalic",
  "CircularXX-Medium",
  "CircularXX-MediumItalic",
  "CircularXX-Regular",
  "CircularXX-Thin",
  "CircularXX-ThinItalic",

  "MMC-Regular",
  "MMC-Medium",
  "MMC-Bold",

  "OpenSans-Regular",
  "OpenSans-SemiBold",
  "OpenSans-ExtraBold",

  "NotoSans-Black",
  "NotoSans-BlackItalic",
  "NotoSans-Bold",
  "NotoSans-BoldItalic",
  "NotoSans-ExtraBold",
  "NotoSans-ExtraBoldItalic",
  "NotoSans-ExtraLight",
  "NotoSans-ExtraLightItalic",
  "NotoSans-Italic",
  "NotoSans-Light",
  "NotoSans-LightItalic",
  "NotoSans-Medium",
  "NotoSans-MediumItalic",
  "NotoSans-Regular",
  "NotoSans-SemiBold",
  "NotoSans-SemiBoldItalic",
  "NotoSans-Thin",
  "NotoSans-ThinItalic",

  "HarmoniaSansPro-LightItalic",
  "HarmoniaSansPro-Light",
  "HarmoniaSansPro-Italic",
  "HarmoniaSansPro-Cond",
  "HarmoniaSansPro-BoldItalic",
  "HarmoniaSansPro-BoldCond",
  "HarmoniaSansPro-Bold",
  "HarmoniaSansPro-BlackItalic",
  "HarmoniaSansPro-SemiBdCond",
  "HarmoniaSansPro-Black",
  "HarmoniaSansMonoStd-Regular",
  "HarmoniaSansMonoStd-Italic",
  "HarmoniaSansMonoStd-BoldIt",
  "HarmoniaSansMonoStd-Bold",
  "HarmoniaSansPro-SemiBd",
  "HarmoniaSansPro-SemiBdItalic",
  "HarmoniaSansPro-Regular",
];

export const fontFamilyOptions: string[] = Array.from(
  new Set(
    [
      "Arial",
      "Calibri",
      "Geneva",
      "Optima",
      "Serif",
      "Sans-serif",
      "Times New Roman",
      "Mixed",
      ...customFonts,
    ].sort(),
  ),
);
