import { Input } from "antd";
import FacebookAccountSelect from "./FacebookAccountSelect";
import { AccountDetailsFacebook } from "shared/types/accountManagement";
import { IFacebookAccount } from "../facebookUtils/types";

type Props = {
  facebook: AccountDetailsFacebook;
  disabled: boolean | undefined;
  facebookNull: boolean | undefined;
  displayAccountId: boolean | undefined;
  selectedAccount: IFacebookAccount | null | undefined;
  selectedAccounts: IFacebookAccount[] | null | undefined;
  enableInput: boolean;
  onSelect: (selectedAccount: IFacebookAccount) => void;
  onChangeInput: React.ChangeEventHandler<HTMLInputElement> | undefined;
};

export const FacebookAccountSelectOrInput = ({
  facebook,
  disabled,
  onSelect,
  selectedAccounts,
  selectedAccount,
  enableInput,
  onChangeInput,
  facebookNull,
  displayAccountId,
}: Props) => {
  return (
    <>
      {enableInput ? (
        <FacebookAccountSelect
          selectedAccounts={selectedAccounts}
          selectedAccount={selectedAccount}
          displayAccountId={displayAccountId}
          initialValue={facebook.fbAccountId}
          disabled={disabled}
          onSelect={onSelect}
          facebookNull={facebookNull}
        />
      ) : (
        <Input value={facebook.fbAccountId} onChange={onChangeInput} />
      )}
    </>
  );
};
