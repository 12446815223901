import { TCanvasHistory } from "./editor.hooks/useCanvasHistory";

export const didStampChange = (canvasHistory: TCanvasHistory): boolean => {
  const historyCount = canvasHistory.history?.length ?? 0;
  if (historyCount > 0) {
    // There was changes detected. Ask user to save or discard it.
    return true;
  }

  return false;
};

export const discardChanges = () => {
  // fill
};
export const saveChanges = () => {
  // fill
};
