import { ReactElement } from "react";
import { Button, Drawer, Space } from "antd";

interface Props {
  onCancel: () => void;
  onClick: () => void;
  onDelete?: () => void;
  visible: boolean;
  title: string;
  children: ReactElement;
  clickButtonText: string;
  width?: string;
  buttonDisabled?: boolean;
  buttonLoading?: boolean;
}

export const BaseDrawer = ({
  onCancel,
  onDelete,
  visible,
  title,
  onClick,
  clickButtonText,
  children,
  width,
  buttonDisabled,
  buttonLoading,
}: Props) => (
  <Drawer
    title={<strong>{title}</strong>}
    placement="right"
    visible={visible}
    width={width ?? "80%"}
    destroyOnClose={true}
    footer={
      <Space>
        {onDelete && (
          <Button danger onClick={onDelete}>
            Delete
          </Button>
        )}
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          type="primary"
          disabled={buttonDisabled}
          loading={buttonLoading}
          onClick={onClick}
          data-cy="save-btn"
        >
          {clickButtonText}
        </Button>
      </Space>
    }
    footerStyle={{ display: "flex", justifyContent: "flex-end" }}
    onClose={onCancel}
    bodyStyle={{ padding: 0 }}
  >
    {children}
  </Drawer>
);
