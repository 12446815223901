import {
  AccountRecord,
  BrandAccountRecord,
  BrandRecord,
} from "shared/types/brandsAccounts";
import { IDataListCondition } from "shared/components/dataList/types";
import { onFilterBy } from "utils/helpers.table";

export const filterChildren = (
  children: AccountRecord[],
  conditions: IDataListCondition[],
) => {
  if (!children) return [];

  return children.filter(child => {
    if (conditions?.length > 0) {
      return conditions?.every(({ value, fn }) => fn(value, child));
    }

    return true;
  });
};

export const mapRows = (
  rows: BrandRecord[],
  conditions: IDataListCondition[],
) => {
  return rows.reduce((acc: BrandRecord[], row) => {
    const newChildren = filterChildren(row.children, conditions);

    const shouldIncludeRow = row.children.length && !newChildren.length;
    if (!shouldIncludeRow) {
      acc.push({
        ...row,
        children: newChildren,
      });
    }

    return acc;
  }, []);
};

export const filterRows = (rows: BrandRecord[], search: string) => {
  if (!search || search === "") return rows.filter(row => row !== undefined);

  return rows.filter(
    row =>
      row !== undefined &&
      (row?.children?.some(child =>
        child.name.toLowerCase().includes(search.toLowerCase()),
      ) ||
        row?.name.toLowerCase().includes(search.toLowerCase())),
  );
};

export const filterColumn = (
  brandKey: keyof BrandAccountRecord,
  value: string | string[],
  record: BrandAccountRecord,
): boolean => {
  const checkMatch = (
    valueToCompare: string,
    recordToCompare: BrandAccountRecord,
  ) =>
    onFilterBy(brandKey)(valueToCompare, recordToCompare) ||
    (recordToCompare.type === "Brand" &&
      recordToCompare.children?.some(child =>
        onFilterBy(brandKey)(valueToCompare, child),
      ));

  if (Array.isArray(value)) return value.some(v => checkMatch(v, record));

  return checkMatch(value, record);
};
