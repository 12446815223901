import { isEmpty } from "lodash";
import { useState } from "react";
import { MenuInfo } from "rc-menu/lib/interface";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input, Menu, Select, Space } from "antd";

import {
  AdFilter,
  adFilters,
  adFiltersCyAttr,
  adTypes,
  qcStatus,
} from "../../../screens/campaignManagement/adReview/shared/constants/table";

import { useCampaignManagementFilters } from "screens/adLibrary/shared/hooks/dataListHooks";
import {
  IAdReviewFields,
  adReviewFieldKeys,
} from "screens/campaignManagement/fields";
import styles from "./EnhancedSearch.module.scss";

const EnhancedSearch = () => {
  const [searchBy, setSearchBy] = useState<
    (typeof adReviewFieldKeys)[number] | null
  >(null);
  const [searchValue, setSearchValue] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { updateFilter, filters } = useCampaignManagementFilters();

  const handleVisibleChange = (isVisible: boolean) => {
    setIsOpen(isVisible);
    if (!isVisible && showSearch && isEmpty(searchValue)) {
      clearFilter();
    }
  };

  const handleSearch = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearchValue(e.target.value);
  };

  const handleReset = () => {
    setSearchValue("");
  };

  const clearFilter = () => {
    setSearchBy(null);
    setSearchValue("");
    setShowSearch(false);
    setIsOpen(false);
  };

  const handleFilter = () => {
    if (searchBy) {
      const filterKey = adReviewFieldKeys.find(filter => filter === searchBy);
      if (filterKey) {
        updateFilter(filterKey, searchValue);
      }
    }
    clearFilter();
  };

  const handleClickItem = (item: MenuInfo) => {
    setSearchBy(item.key as IAdReviewFields);
    setShowSearch(true);
  };

  const renderItem = (item: AdFilter, dataCy: string) => {
    if (item.label === "Ad Format") {
      return (
        <Select
          key={item.value}
          className={styles.submenuSelect}
          data-cy={dataCy}
          mode="multiple"
          showSearch={false}
          showArrow
          bordered={false}
          maxTagCount={0}
          maxTagPlaceholder="Ad Format"
          placeholder="Ad Format"
          value={filters.adFormat}
          onChange={(value: string[]) => {
            updateFilter("adFormat", value);
          }}
        >
          {adTypes.map(value => (
            <Select.Option key={value} value={value}>
              {value}
            </Select.Option>
          ))}
        </Select>
      );
    } else if (item.label === "Ad Status") {
      return (
        <Select
          key={item.value}
          className={styles.submenuSelect}
          data-cy={dataCy}
          mode="multiple"
          showSearch={false}
          showArrow
          bordered={false}
          maxTagCount={0}
          maxTagPlaceholder="Ad Status"
          placeholder="Ad Status"
          value={filters.status}
          onChange={(value: string[]) => {
            updateFilter("status", value);
          }}
        >
          {qcStatus.map(value => (
            <Select.Option key={value} value={value}>
              {value}
            </Select.Option>
          ))}
        </Select>
      );
    } else {
      return (
        <Menu.Item key={item.value} data-cy={dataCy} onClick={handleClickItem}>
          {item.label}
        </Menu.Item>
      );
    }
  };

  const menu = (
    <>
      {isEmpty(searchBy) && isOpen && !showSearch && (
        <Menu mode="inline" style={{ width: 260 }}>
          {adFilters.map(item => renderItem(item, adFiltersCyAttr[item.label]))}
        </Menu>
      )}
      {showSearch && (
        <div
          style={{
            padding: 8,
            background: "#FFFFFF",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
            borderRadius: "2px",
            width: 260,
          }}
        >
          <Input
            data-cy="search-input"
            placeholder={`Search`}
            value={searchValue}
            onChange={handleSearch}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button onClick={handleReset} size="small" style={{ width: 90 }}>
              Reset
            </Button>
            <Button
              data-cy="filter-button"
              type="primary"
              onClick={handleFilter}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Filter
            </Button>
          </Space>
        </div>
      )}
    </>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      className={styles.select}
      visible={isOpen}
      onVisibleChange={handleVisibleChange}
    >
      <Button data-cy="search-filter-button">
        <span style={{ float: "left" }}>Search and Filter</span>
        <DownOutlined
          style={{
            color: "rgba(0, 0, 0, 0.25)",
            float: "right",
            marginRight: -4,
            marginTop: 6,
            fontSize: 12,
            lineHeight: 1,
          }}
        />
      </Button>
    </Dropdown>
  );
};

export default EnhancedSearch;
