import { useFetchCampaigns } from "shared/hooks/adLibrary/facebook/useFetchCampaigns";
import { getCampaignDateRange } from "screens/adLibrary/adLoad/adLoadDrawer/shared/utils";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { selectSelectedAccounts } from "redux/adLibrary/adLibrary.selectors";

export const useCampaigns = () => {
  const selectedAccounts = useAppSelector(selectSelectedAccounts);

  const { data: campaigns, isLoading } = useFetchCampaigns({
    limit: 50,
    includeAds: true,
    includeAdsets: true,
    pullDataViaPagination: true,
    startTimes: getCampaignDateRange(),
    adAccountIds: selectedAccounts?.map(account => account.account_id) ?? [],
  });

  return { campaigns, isLoading };
};
