import { isNil } from "lodash";

export const getFieldValue = (value: MGSearchValue): string | string[] => {
  if (isNil(value)) {
    return "";
  }

  if (Array.isArray(value)) {
    return value.flatMap(getFieldValue);
  }

  return String(value).trim();
};
