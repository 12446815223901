import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { delay } from "../../utils/helpers";

import API from "../../services";
import { AppThunk } from "redux/store";
import GenericError from "shared/errors/GenericError";
import {
  IDeleteBrandResponse,
  IDeleteBrandResult,
  IGetBrandsResponse,
  IGetBrandsResult,
  IBrand,
  IBrandManagementState,
  IBrandRecord,
  IUpdateOemResponse,
} from "shared/types/brandManagement";
import {
  ICreateBrandResult,
  IUpdateBrandResult,
} from "shared/types/brandsAccounts";

const initialState: IBrandManagementState = {
  processingOems: false,
  oemsMessage: "",
  oemPaginationKey: "",
  oemRecords: [],
  result: null,
  error: null,
};

const oemManagementSlice = createSlice({
  name: "oemManagement",
  initialState,
  reducers: {
    getOemsBegin(state) {
      return {
        ...state,
        processingOems: true,
      };
    },
    getOemsFail(state, { payload: error }: PayloadAction<Error>) {
      return {
        ...state,
        processingOems: false,
        error,
      };
    },
    getOemsSuccess(
      state,
      {
        payload: { response, paginationToken },
      }: PayloadAction<{
        response: IGetBrandsResponse;
        paginationToken: string;
      }>,
    ) {
      const getOems = () => {
        const { result: getOemsResult } = response;
        const { oems, paginationKey } = getOemsResult as IGetBrandsResult;
        const { oem_name: paginationKeyOem = "" } = paginationKey || {};

        const oemRecords: IBrandRecord[] = oems.map(
          (oem: IBrand, index: number): IBrandRecord => {
            const logoUrls = oem.logo_urls_from_S3
              ? JSON.parse(oem.logo_urls_from_S3)
              : {
                  horizontalEventImagesFromS3: [],
                  horizontalImagesFromS3: [],
                  squareEventImagesFromS3: [],
                  squareImagesFromS3: [],
                  verticalEventImagesFromS3: [],
                  verticalImagesFromS3: [],
                };

            return {
              key: index,
              oemName: oem.oem_name,
              logoUrl: oem.logo_url,
              eventLogos: oem.event_logos ? oem.event_logos.split(",") : [""],
              logoUrlsFromS3: logoUrls,
              createdAt: oem.created_at,
              updatedAt: oem.updated_at,
              enabled: oem.enabled,
              youtubeConnected: oem.youtubeConnected,
            };
          },
        );

        return {
          ...state,
          oemRecords: [...oemRecords],
          processingOems: false,
          oemPaginationKey: paginationKeyOem || "",
          result: getOemsResult,
        };
      };

      const getOemsPage = () => {
        const { result: getOemsFromPageResult } = response;
        const { oems: oemsFromPage, paginationKey: paginationKeyFromPage } =
          getOemsFromPageResult as IGetBrandsResult;
        const { oem_name: paginationKeyOemFromPage = "" } =
          paginationKeyFromPage || {};

        const oemRecordsFromPage: IBrandRecord[] = oemsFromPage.map(
          (oem: IBrand, index: number): IBrandRecord => {
            const logoUrls = oem.logo_urls_from_S3
              ? JSON.parse(oem.logo_urls_from_S3)
              : {
                  horizontalEventImagesFromS3: [],
                  horizontalImagesFromS3: [],
                  squareEventImagesFromS3: [],
                  squareImagesFromS3: [],
                  verticalEventImagesFromS3: [],
                  verticalImagesFromS3: [],
                };
            return {
              key:
                state.oemRecords.length > 0
                  ? state.oemRecords.length + index
                  : index,
              oemName: oem.oem_name,
              logoUrl: oem.logo_url,
              eventLogos: oem.event_logos ? oem.event_logos.split(",") : [""],
              logoUrlsFromS3: logoUrls,
              createdAt: oem.created_at,
              updatedAt: oem.updated_at,
              enabled: oem.enabled,
              youtubeConnected: oem.youtubeConnected,
            };
          },
        );

        const newOemRecords = [...state.oemRecords, ...oemRecordsFromPage];
        const updatedResult: IGetBrandsResult = {
          ...getOemsFromPageResult,
          oems: getOemsFromPageResult?.oems ?? [],
          scannedCount: state.result
            ? (state.result as IGetBrandsResult).scannedCount
            : getOemsFromPageResult?.scannedCount ?? 0,
        };

        return {
          ...state,
          oemRecords: newOemRecords,
          processingOems: false,
          oemPaginationKey: paginationKeyOemFromPage || "",
          result: updatedResult,
        };
      };

      if (paginationToken) {
        return getOemsPage();
      } else {
        return getOems();
      }
    },
    createOemBegin(state) {
      return {
        ...state,
        oemRecords: [...state.oemRecords],
        oemsMessage: "",
        processingOems: true,
      };
    },
    createOemFail(state, { payload: error }: PayloadAction<Error>) {
      return {
        ...state,
        error,
        processingOems: false,
      };
    },
    createOemSuccess(state, { payload: createdOem }: PayloadAction<IBrand>) {
      const { oemRecords: prevOemRecords } = state;

      const newOemRecord: IBrandRecord = {
        key: prevOemRecords.length,
        oemName: createdOem.oem_name,
        logoUrl: createdOem.logo_url,
        eventLogos: createdOem.event_logos
          ? createdOem.event_logos.split(",")
          : [""],
        createdAt: createdOem.created_at,
        updatedAt: createdOem.updated_at,
        enabled: true,
        youtubeConnected: false,
      };

      const updatedOemRecords = prevOemRecords.filter(
        (oem: IBrandRecord) => oem.oemName !== newOemRecord.oemName,
      );

      updatedOemRecords.push(newOemRecord);

      return {
        ...state,
        oemRecords: [...updatedOemRecords],
        oemsMessage: "OEM Created successfully.",
        processingOems: false,
      };
    },
    updateOemBegin(state) {
      return {
        ...state,
        oemsMessage: "",
        processingOems: true,
      };
    },
    updateOemFail(state, { payload: error }: PayloadAction<Error>) {
      return {
        ...state,
        error,
        processingOems: false,
      };
    },
    updateOemSuccess(state, { payload }: PayloadAction<IUpdateOemResponse>) {
      const { updatedOem, sendMessage } = payload;
      const { oemRecords: oemRecordsBeforeUpdate } = state;

      const originalOemRecord = oemRecordsBeforeUpdate.find(
        oemRecord => oemRecord.oemName === updatedOem.oem_name,
      );

      const updatedOemRecord: IBrandRecord = {
        key: originalOemRecord
          ? originalOemRecord.key
          : oemRecordsBeforeUpdate.length + 1,
        oemName: updatedOem.oem_name,
        logoUrl: updatedOem.logo_url,
        eventLogos: updatedOem.event_logos
          ? updatedOem.event_logos.split(",")
          : [""],
        createdAt: updatedOem.created_at,
        updatedAt: updatedOem.updated_at,
        enabled: updatedOem.enabled,
        youtubeConnected: updatedOem.youtubeConnected,
      };

      const oemRecordsAfterUpdate = oemRecordsBeforeUpdate.filter(
        (oem: IBrandRecord) => oem.oemName !== updatedOemRecord.oemName,
      );

      oemRecordsAfterUpdate.push(updatedOemRecord);

      return {
        ...state,
        oemRecords: [...oemRecordsAfterUpdate],
        oemsMessage: sendMessage === false ? "" : "OEM Updated successfully.",
        processingOems: false,
      };
    },
    deleteOemBegin(state) {
      return {
        ...state,
        oemsMessage: "",
        processingOems: true,
      };
    },
    deleteOemFail(state, { payload: error }: PayloadAction<Error>) {
      return {
        ...state,
        error,
        processingOems: false,
      };
    },
    deleteOemSuccess(state, { payload }: PayloadAction<IDeleteBrandResponse>) {
      const { result: deleteOemResult } = payload;
      const { deletedOem } = deleteOemResult as IDeleteBrandResult;
      const { oemRecords: oemRecordsBeforeDelete } = state;

      const oemRecordsAfterDelete = oemRecordsBeforeDelete.filter(
        (oem: IBrandRecord) => oem.oemName !== deletedOem.oem_name,
      );

      return {
        ...state,
        result: deleteOemResult,
        oemRecords: [...oemRecordsAfterDelete],
        oemsMessage: "OEM Deleted successfully.",
        processingOems: false,
      };
    },
    resetOemFeedbackSuccess(state) {
      return {
        ...state,
        oemsMessage: "",
        error: null,
      };
    },
  },
});

export const getOems =
  (paginationToken = ""): AppThunk =>
  async (dispatch, getState) => {
    dispatch(getOemsBegin());

    await delay(500);

    try {
      const response =
        await API.privServices.oemManagement.getOems<IGetBrandsResponse>(
          paginationToken,
        );

      const { error } = response;

      if (error) {
        const { message } = error;
        dispatch(
          getOemsFail(
            new GenericError({
              message: message || "Some error ocurred.",
            }),
          ),
        );
        return;
      }
      dispatch(getOemsSuccess({ response, paginationToken }));
    } catch (error) {
      dispatch(
        getOemsFail(
          new GenericError({
            message: (error as Error).message || "Some error ocurred.",
            errorObject: error,
          }),
        ),
      );
    }
  };

export const createOem =
  (inputOem: IBrandRecord): AppThunk =>
  async dispatch => {
    dispatch(createOemBegin());

    await delay(500);
    try {
      const oem: IBrand = {
        oem_name: inputOem.oemName.trim(),
        logo_urls_from_S3: JSON.stringify(inputOem.logoUrlsFromS3) || null,
        logo_url: inputOem.logoUrl.trim(),
        event_logos: inputOem.eventLogos.join(","),
        created_at: inputOem.createdAt || Date.now(),
        updated_at: Date.now(),
        enabled: true,
        fonts: [],
        youtubeConnected: false,
      };

      const response =
        await API.privServices.oemManagement.createOem<ICreateBrandResult>(oem);

      const { error, result } = response;

      if (error || !result) {
        dispatch(
          createOemFail(
            new GenericError({
              message: error?.message || "Some error ocurred.",
            }),
          ),
        );
        return;
      }
      dispatch(createOemSuccess(result.createdOem));
    } catch (error) {
      dispatch(
        createOemFail(
          new GenericError({
            message: (error as Error).message || "Some error ocurred.",
            errorObject: error,
          }),
        ),
      );
    }
  };

export const updateOem =
  (inputOem: IBrandRecord, sendMessage: boolean = true): AppThunk =>
  async dispatch => {
    dispatch(updateOemBegin());

    await delay(500);
    try {
      const oem: IBrand = {
        oem_name: inputOem.oemName.trim(),
        logo_urls_from_S3: JSON.stringify(inputOem.logoUrlsFromS3) || null,
        logo_url: inputOem.logoUrl.trim(),
        event_logos: inputOem.eventLogos.join(","),
        created_at: inputOem.createdAt || Date.now(),
        updated_at: Date.now(),
        enabled: inputOem.enabled ?? false,
        fonts: [],
        youtubeConnected: inputOem.youtubeConnected ?? false,
      };

      const response =
        await API.privServices.oemManagement.updateOem<IUpdateBrandResult>(oem);

      const { error, result } = response;

      if (error || !result) {
        dispatch(
          createOemFail(
            new GenericError({
              message: error?.message || "Some error ocurred.",
            }),
          ),
        );
        return;
      }

      const newResponse = {
        updatedOem: result.updatedOem,
        sendMessage,
      };
      dispatch(updateOemSuccess(newResponse));
    } catch (error) {
      dispatch(
        updateOemFail(
          new GenericError({
            message: (error as Error).message || "Some error ocurred.",
            errorObject: error,
          }),
        ),
      );
    }
  };

export const deleteOem =
  (inputOem: IBrandRecord): AppThunk =>
  async (dispatch, getState) => {
    dispatch(deleteOemBegin());

    await delay(500);

    try {
      const oem: IBrand = {
        oem_name: inputOem.oemName.trim(),
        logo_url: "",
        event_logos: "",
        created_at: inputOem.createdAt || Date.now(),
        updated_at: Date.now(),
        enabled: false,
        fonts: [],
        youtubeConnected: false,
      };

      const response =
        await API.privServices.oemManagement.deleteOem<IDeleteBrandResponse>(
          oem,
        );

      const { error } = response;

      if (error) {
        const { message } = error;
        dispatch(
          deleteOemFail(
            new GenericError({
              message: message || "Some error ocurred.",
            }),
          ),
        );
        return;
      }

      dispatch(deleteOemSuccess(response));
    } catch (error) {
      dispatch(
        deleteOemFail(
          new GenericError({
            message: (error as Error).message || "Some error ocurred.",
            errorObject: error,
          }),
        ),
      );
    }
  };

export const resetOemFeedback = (): AppThunk => dispatch => {
  dispatch(resetOemFeedbackSuccess());
};

export const {
  createOemBegin,
  createOemFail,
  createOemSuccess,
  deleteOemBegin,
  deleteOemFail,
  deleteOemSuccess,
  getOemsBegin,
  getOemsFail,
  getOemsSuccess,
  resetOemFeedbackSuccess,
  updateOemBegin,
  updateOemFail,
  updateOemSuccess,
} = oemManagementSlice.actions;

export default oemManagementSlice.reducer;
