import React, { useContext, useState } from "react";

interface CellContextType {
  editingField: string;
  isEditing: boolean;
  newValue: string | number;
  oldValue: string | number;
  editingRow: string;
  setEditingRow: React.Dispatch<React.SetStateAction<string>>;
  setEditingField: React.Dispatch<React.SetStateAction<string>>;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  setNewValue: React.Dispatch<React.SetStateAction<string | number>>;
  setOldValue: React.Dispatch<React.SetStateAction<string | number>>;
}

const CellContext = React.createContext<CellContextType | undefined>(undefined);

export const CellProvider = React.memo(
  ({ children }: { children: React.ReactNode }) => {
    const [editingField, setEditingField] = useState("");
    const [editingRow, setEditingRow] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [newValue, setNewValue] = useState<string | number>("");
    const [oldValue, setOldValue] = useState<string | number>("");

    const contextValue: CellContextType = {
      isEditing,
      setIsEditing,
      oldValue,
      setOldValue,
      newValue,
      setNewValue,
      editingField,
      setEditingField,
      editingRow,
      setEditingRow,
    };

    return (
      <CellContext.Provider value={contextValue}>
        {children}
      </CellContext.Provider>
    );
  },
);

CellProvider.displayName = "CellProvider";

export const useCellContext = () => {
  const cellContext = useContext(CellContext);
  if (!cellContext) {
    throw new Error("useCellContext must be used within an CellProvider");
  }
  return cellContext;
};
