import { isEmpty } from "lodash";
import { OfferData, RepeatableOfferKeys } from "shared/types/assetBuilder";
import { OfferType } from "shared/types/shared";
import { TCellPropsArray, TCollapsableRow } from "./OfferTypeCollapse";
import { RepeatableRowTypes } from "./offerTypeCollapse/Row";

export const generateRabateFieldRows = (
  offerType: OfferType,
  offerData?: Partial<OfferData>,
): TCollapsableRow[] => {
  const rebateKeyObj = getRebateKeys(offerType);

  if (!rebateKeyObj) return [];

  const { rebateKey, rebateNameKey, rebateDisclosureKey } = rebateKeyObj;

  const rebates = offerData ? offerData[rebateKey] : undefined;
  const rebateNames = offerData ? offerData[rebateNameKey] : undefined;
  const rebateDisclosures = offerData
    ? offerData[rebateDisclosureKey]
    : undefined;

  const invalidRebateValues = !validateRebateValues(rebates, rebateNames);
  if (invalidRebateValues) {
    const idx = 0;
    const noRebates = isEmpty(rebates) || isEmpty(rebateNames);
    return [
      {
        type: getRepeatableRowType(idx),
        repeatableIndex: idx,
        repeatableLength: noRebates ? 0 : 1,
        row: [
          {
            postfix: "Amount",
            offerKey: rebateKey,
            value: rebates?.[idx],
            idx,
          },
          {
            postfix: "Name",
            offerKey: rebateNameKey,
            value: rebateNames?.[idx],
            idx,
          },
          {
            postfix: "Disclosure",
            offerKey: rebateDisclosureKey,
            value: rebateDisclosures?.[idx],
            idx,
          },
        ].reduce((acc, obj) => {
          return acc.concat([
            {
              type: "text",
              value: obj.value?.[obj.idx], // value in string[] for each rebate related field
              editable: true,
              title: `${offerType} Rebate ${obj.idx + 1} ${obj.postfix}`,
              offerKey: obj.offerKey,
            },
          ]);
        }, [] as TCellPropsArray),
      },
    ];
  }

  // rebate fields should be validate before this
  return [...Array(rebates!.length || 1).keys()].map(idx => {
    return {
      type: getRepeatableRowType(idx),
      repeatableIndex: idx,
      repeatableLength: rebates!.length,
      row: [
        {
          postfix: "Amount",
          offerKey: rebateKey,
          value: rebates?.[idx],
          idx,
        },
        {
          postfix: "Name",
          offerKey: rebateNameKey,
          value: rebateNames?.[idx],
          idx,
        },
        {
          postfix: "Disclosure",
          offerKey: rebateDisclosureKey,
          value: rebateDisclosures?.[idx],
          idx,
        },
      ].reduce((acc, obj) => {
        return acc.concat([
          {
            type: "text",
            value: obj.value, // value in string[] for each rebate related field
            editable: true,
            title: `${offerType} Rebate ${obj.idx + 1} ${obj.postfix}`,
            offerKey: obj.offerKey,
          },
        ]);
      }, [] as TCellPropsArray),
    };
  });
};

type RebateKeys = {
  rebateKey: RepeatableOfferKeys;
  rebateNameKey: RepeatableOfferKeys;
  rebateDisclosureKey: RepeatableOfferKeys;
};

const getRebateKeys = (offerType: OfferType) => {
  const rebateKeys: { [key in OfferType]?: RebateKeys } = {
    [OfferType.Lease]: {
      rebateKey: "leaseRebate",
      rebateNameKey: "leaseRebateName",
      rebateDisclosureKey: "leaseRebateDisclosure",
    },
    [OfferType.ZeroDownLease]: {
      rebateKey: "zeroDownLeaseRebate",
      rebateNameKey: "zeroDownLeaseRebateName",
      rebateDisclosureKey: "zeroDownLeaseRebateDisclosure",
    },
    [OfferType.Purchase]: {
      rebateKey: "purchaseRebate",
      rebateNameKey: "purchaseRebateName",
      rebateDisclosureKey: "purchaseRebateDisclosure",
    },
    [OfferType["Purchase(Conditional)"]]: {
      rebateKey: "conditionalRebate" as RepeatableOfferKeys,
      rebateNameKey: "conditionalRebateName" as RepeatableOfferKeys,
      rebateDisclosureKey: "conditionalRebateDisclosure" as RepeatableOfferKeys,
    },
  };

  return rebateKeys[offerType];
};
export const validateRebateValues = (
  rebateValues?: string[],
  rebateNameValues?: string[],
): boolean => {
  return !isEmpty(rebateValues) && !isEmpty(rebateNameValues);
};

export const generateCustomFieldRows = (
  offerType: OfferType,
  offerData?: Partial<OfferData>,
): TCollapsableRow[] => {
  const okToProceed = [
    OfferType.Lease,
    OfferType.ZeroDownLease,
    OfferType.Purchase,
    OfferType.Finance,
    OfferType.APR,
  ].includes(offerType);
  if (!okToProceed) return [];

  const customKey = getCustomKey(offerType);

  const customFieldValues = offerData ? offerData[customKey] : undefined;

  return [...Array(customFieldValues?.length || 1).keys()].map(idx => ({
    type: getRepeatableRowType(idx),
    repeatableIndex: idx,
    repeatableLength: customFieldValues?.length ?? 1,
    row: [
      {
        type: "text",
        value: customFieldValues?.[idx], // value in string[] for each rebate related field
        editable: true,
        title: `${offerType} Custom ${idx + 1}`,
        offerKey: customKey,
      },
    ],
  }));
};

const getCustomKey = (offerType: OfferType): RepeatableOfferKeys => {
  const customKeys: { [key in OfferType]?: RepeatableOfferKeys } = {
    [OfferType.Lease]: "leaseCustom",
    [OfferType.ZeroDownLease]: "zeroDownLeaseCustom",
    [OfferType.Purchase]: "purchaseCustom",
    [OfferType.Finance]: "financeCustom",
    [OfferType.APR]: "aprCustom",
  };

  return customKeys[offerType]!;
};

const getRepeatableRowType = (idx: number): RepeatableRowTypes => {
  return idx === 0 ? "row_repeat_add" : "row_repeat_delete";
};
