import { useCallback, useEffect, useMemo, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { Card, Button, Typography, Divider, Row, Col } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import YoutubeLogo from "statics/images/youtube-logo.svg";
import { useYoutubeOAuthConsentUrl } from "./shared/hooks/useYoutubeOAuthConsentUrl";
import useFetchYoutubeChannels from "screens/adLibrary/shared/hooks/useFetchYoutubeChannels";
import useFetchYoutubeAccounts from "screens/adLibrary/shared/hooks/useFetchYoutubeAccounts";
import { handleError } from "./shared/utils";
import styles from "./YoutubeIntegration.module.scss";
import {
  errorNotification,
  successNotification,
} from "shared/components/customNotification/Notification";

export const YoutubeIntegration = ({
  oem,
  connected,
  setConnection,
  moduleUrl,
  isNew = false,
}: {
  oem: string;
  connected: boolean;
  setConnection: (connection: boolean) => void;
  moduleUrl: string;
  isNew?: boolean;
}) => {
  const [isLoadingOnYoutube, setIsLoadingOnYoutube] = useState(false);
  const { isLoading: isLoadingConsentUrl, refetch } = useYoutubeOAuthConsentUrl(
    {
      onSuccess: (data: string | null | undefined) => {
        if (data) {
          location.href = data;
        }
      },
      onError: handleError,
      oem,
      moduleUrl,
    },
  );
  const { data: youtubeChannels } = useFetchYoutubeChannels(oem, connected);
  const { data: youtubeAccounts } = useFetchYoutubeAccounts(oem, connected);
  const linkedChannel = youtubeChannels?.[0].title || "-";
  const linkedAccountName = youtubeAccounts?.names?.[0] || "-";
  const [event, setEvent] = useQueryParam("event", StringParam);
  const eventMessage = useCallback(() => {
    if (event === "success") {
      successNotification({
        messageLabel: "Youtube account has been connected",
        duration: 4,
        size: "big",
      });
      setEvent(undefined);
    }
    if (event === "error") {
      errorNotification({
        messageLabel:
          "An error occurred while connecting with Youtube. Please try again.",
        duration: 4,
        size: "big",
      });
    }
  }, [event, setEvent]);

  useEffect(eventMessage, [eventMessage]);

  const isLoading = useMemo(
    () => isLoadingOnYoutube || isLoadingConsentUrl,
    [isLoadingOnYoutube, isLoadingConsentUrl],
  );

  const onConnect = async () => {
    setIsLoadingOnYoutube(true);
    await refetch();
  };

  const onDisconnect = () => {
    setConnection(false);
    successNotification({
      key: "youtubeSuccess",
      messageLabel: "Youtube account has been disconnected",
      duration: 15,
      linkText: "Undo",
      onClickLink: () => {
        setConnection(true);
      },
      size: "big",
      maxMsgQty: 2,
    });
  };

  return (
    <Card className={styles.card}>
      <div className={styles.content}>
        <span>
          <img src={YoutubeLogo} width="20px" height="20px" />
          <Typography.Text className={styles.youtubeText}>
            Youtube
          </Typography.Text>
        </span>
        <span>
          {connected && (
            <Button
              className={styles.connectedButton}
              icon={<CheckCircleOutlined twoToneColor="#52C41A" />}
              disabled={true}
              size="small"
            >
              Connected
            </Button>
          )}
          <Button
            danger={connected}
            type={connected ? "default" : "primary"}
            onClick={connected ? onDisconnect : onConnect}
            disabled={isLoading || isNew}
            loading={isLoading}
            size="small"
          >
            {connected ? "Disconnect" : "Connect"}
          </Button>
        </span>
      </div>
      {connected && (
        <Row>
          <Divider className={styles.divider} />
          <Col span={12} className={styles.detail}>
            <>
              <Typography.Text>Account</Typography.Text>
              <Typography.Text>{linkedAccountName}</Typography.Text>
            </>
          </Col>
          <Col span={12} className={styles.detail}>
            <Typography.Text>Channel</Typography.Text>
            <Typography.Text>{linkedChannel}</Typography.Text>
          </Col>
        </Row>
      )}
    </Card>
  );
};
