import { Form } from "antd";
import { omitBy } from "lodash";
import { Loading } from "../Loading";
import { MetaFormItems } from "../MetaFormItem";
import { getFieldValue } from "../getFieldValue";
import { useMetaSchema } from "../useMetaSchema";

type Props = {
  onChange: (values: MGFormValues) => void;
};

export const FormMeta = ({ onChange }: Props) => {
  const [form] = Form.useForm();
  const { schema } = useMetaSchema();

  if (schema) {
    return (
      <Form
        layout="vertical"
        form={form}
        onValuesChange={(_, values) => {
          onChange(omitBy(values, v => !getFieldValue(v).length));
        }}
      >
        <MetaFormItems schema={schema} />
      </Form>
    );
  }

  return <Loading />;
};
