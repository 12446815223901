import { Table } from "antd";
import { useState } from "react";
import ExpandIcon from "shared/components/ExpandIcon";
import styles from "./AssignDestsTable.module.scss";
import { AllEntryTypes } from "./AssignDestsTable.types";
import { useColumns } from "./assignDestsTable/useColumns";
import { useDataSource } from "./assignDestsTable/useDataSource";
import { useFilteredProtosDrafts } from "../../hooks/useFilteredProtosDrafts";
import { getAdProtoDraftPredicate } from "screens/adLibrary/facebookUtils/adProto";

export type TableType = "assignProductSets" | "assignDestinations";

type Props = {
  tableType: TableType;
};
const AssignDestsTable = ({ tableType }: Props) => {
  const [hoveringRecord, setHoveringRecord] = useState<AllEntryTypes>();
  const dataSource = useDataSource(tableType);

  const { adProtoDraftsWithDestUrls, adProtoDraftsWithProductSets } =
    useFilteredProtosDrafts();

  const filteredDataSource = dataSource.filter(record => {
    return tableType === "assignProductSets"
      ? adProtoDraftsWithProductSets.some(getAdProtoDraftPredicate(record))
      : adProtoDraftsWithDestUrls.some(getAdProtoDraftPredicate(record));
  });

  const columns = useColumns(filteredDataSource, tableType);

  return (
    <Table
      scroll={{ x: "max-content", y: "calc(100vh - 210px)" }}
      size="small"
      columns={columns}
      dataSource={filteredDataSource}
      pagination={false}
      sticky
      rowKey={record => record.fullPath}
      rowClassName={record => {
        return hoveringRecord &&
          record.fullPath.includes(hoveringRecord?.fullPath)
          ? styles.highlightRow
          : "";
      }}
      onRow={record => ({
        onMouseEnter: () => setHoveringRecord(record),
        onMouseLeave: () => setHoveringRecord(undefined),
      })}
      expandable={{
        defaultExpandAllRows: true,
        expandIcon: function expandIcon(props) {
          return <ExpandIcon {...props} />;
        },
        indentSize: 28,
      }}
    />
  );
};

export default AssignDestsTable;
