import {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import styles from "./Layout.module.scss";

const useIsDragging = (div: HTMLDivElement | null) => {
  const isDragging = useRef(false);
  useEffect(() => {
    if (!div) return;

    const onMouseDown = (e: MouseEvent) => {
      if (!e.target) return;

      if (div.contains(e.target as any)) isDragging.current = true;
    };
    const onMouseUp = (e: MouseEvent) => {
      e.preventDefault();

      isDragging.current = false;
    };

    window.addEventListener("mousedown", onMouseDown);
    window.addEventListener("mouseup", onMouseUp);

    return () => {
      window.removeEventListener("mousedown", onMouseDown);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, [div]);

  return isDragging.current;
};

const useResizeX = (div: HTMLDivElement | null) => {
  const isDragging = useIsDragging(div);

  const [x, setX] = useState(0);

  const mouseMove = useCallback(
    (e: MouseEvent) => {
      if (!isDragging) return;
      setX(e.clientX);
    },
    [isDragging],
  );

  useEffect(() => {
    document.addEventListener("mousemove", mouseMove);

    return () => {
      document.removeEventListener("mousemove", mouseMove);
    };
  }, [mouseMove]);

  return x;
};

const PANEL_WIDTH = 300;
const OFFSET = 100;
export type Props = {
  children?: ReactNode;
  leftPanel?: ReactNode;
  rightPanel?: ReactNode;
};

const Layout = (props: Props) => {
  const [resizable, setResizable] = useState(false);

  const triggerRef = useRef<HTMLDivElement>(null);
  const x = useResizeX(triggerRef.current);

  const panelRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!panelRef || !panelRef.current || !x) return;
    if (x < PANEL_WIDTH - OFFSET || x > PANEL_WIDTH + OFFSET) return;
    panelRef.current.style.width = `${x}px`;
  }, [x]);

  const onMouseEnter = useCallback(() => {
    setResizable(true);
  }, []);
  const onMouseLeave = useCallback(() => {
    setResizable(false);
  }, []);

  return (
    <div className={styles.layout}>
      {props.leftPanel && (
        <div
          ref={panelRef}
          className={`${styles.left} ${styles.panel} ${
            resizable ? styles.resizable : ""
          }`}
        >
          <div
            ref={triggerRef}
            className={styles.trigger}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          ></div>
          {props.leftPanel}
        </div>
      )}
      <div className={styles.canvas_area}>{props.children}</div>
      {props.rightPanel && (
        <div className={`${styles.right} ${styles.panel}`}>
          {props.rightPanel}
        </div>
      )}
    </div>
  );
};

export default Layout;
