import React from "react";
import { Badge, Select, Tooltip } from "antd";
import { getColorFromStatus } from "screens/assetBuilder/utils";
import {
  INewOrderTableRecord,
  LADStatusOptions,
  PharmaStatusOptions,
  StatusOptions,
} from "shared/types/newOrders";

type Props = {
  value: StatusOptions;
  disabled: boolean;
  record: INewOrderTableRecord;
  options:
    | typeof StatusOptions
    | typeof PharmaStatusOptions
    | typeof LADStatusOptions;

  handleStatusUpdate: (
    record: INewOrderTableRecord,
    status: StatusOptions,
  ) => void;
};
const StatusSelect = (props: Props) => {
  const { disabled, value, record, handleStatusUpdate, options } = props;
  const title = disabled
    ? "Please check user permissions in order to edit the status"
    : "";
  return (
    <Tooltip title={title}>
      <Select
        style={{ width: "100%" }}
        data-cy={`orders-table-status-${record.key}`}
        value={value || StatusOptions.NO_STATUS}
        disabled={disabled}
        onSelect={value => handleStatusUpdate(record, value)}
      >
        {Object.values(options).map(value => (
          <Select.Option key={value} value={value}>
            <Badge color={getColorFromStatus(value)} text={value} />
          </Select.Option>
        ))}
      </Select>
    </Tooltip>
  );
};

export default StatusSelect;
