import {
  Platform,
  PlatformTokenStatus,
} from "screens/platformManagement/shared/types";
import { IConfig } from "shared/types/configuration";
import { IApiResponse } from "shared/types/shared";
import HttpClient from "./httpClient";

export default ({ config }: { headers: any; config: IConfig }) => ({
  createToken: (body: {
    platform: Platform;
    shortTermToken: string;
  }): Promise<IApiResponse<string>> => {
    return HttpClient.post(
      config.services.platformManagement.createPlatformToken(),
      body,
    );
  },
  deleteToken: (platform: Platform): Promise<IApiResponse<void>> => {
    return HttpClient.delete(
      config.services.platformManagement.deletePlatformToken(platform),
    );
  },
  getToken: (
    platform: Platform,
  ): Promise<IApiResponse<PlatformTokenStatus>> => {
    return HttpClient.get(
      config.services.platformManagement.getPlatformToken(platform),
    );
  },
  getGoogleOAuthConsentUrl: (): Promise<IApiResponse<string>> => {
    return HttpClient.get(
      config.services.platformManagement.getGoogleOAuthConsentUrl(),
    );
  },
  getYoutubeOAuthConsentUrl: (oem: string, moduleUrl: string): Promise<IApiResponse<string>> => {
    return HttpClient.get(
      config.services.platformManagement.getYoutubeOAuthConsentUrl(oem, moduleUrl),
    );
  },
});
