import { useQuery } from "react-query";
import Page from "screens/adLibrary/facebookUtils/page";

const useFetchPageDetails = ({ pageId }: { pageId?: string }) => {
  const getPageData = () => {
    if (!pageId) return;

    const fbPage = new Page(pageId);
    return fbPage.getPageData();
  };

  return useQuery({
    queryKey: ["fbPage", pageId],
    queryFn: getPageData,
    enabled: !!pageId,
  });
};

export default useFetchPageDetails;
