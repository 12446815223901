import { useV3Query } from "./useV3Query";
import { BrandFragment } from "../../../graphql/v3.gen";

export const useV3Brands = (clientId?: string) => {
  const { data, loading, error } = useV3Query<{ brands: BrandFragment[] }>({
    variables: {
      input: {
        query: "v2GetBrandsByClient",
        variables: {
          clientId,
        },
      },
    },
    skip: !clientId,
  });

  const brands = data?.v2AccessQueryExt?.brands;

  return {
    loading,
    error,
    brands,
  };
};
