import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";

// types
import {
  TCheckedVariable,
  TVariableGroupName,
} from "../../../shared/types/legalLingo";

import LegalLingoTable from "./LegalLingoTable";

import * as legalHelpers from "utils/helpers.legal";
import { numberAtThisPriceData } from "../../../shared/constants/dataManagement";

// css
import "../../LegalLingo.scss";
import { FC } from "react";

interface ILegalLingoOem {
  listOfCheckedVehicleInfoOems: TCheckedVariable[];
  listOfCheckedNumberAtThisPriceOems?: TCheckedVariable[];
  listOfCheckedLeaseOems: TCheckedVariable[];
  listOfCheckedZeroDownLeaseOems: TCheckedVariable[];
  listOfCheckedFinanceOems: TCheckedVariable[];
  listOfCheckedPurchaseOems: TCheckedVariable[];
  listOfCheckedAPROems: TCheckedVariable[];

  onChange: (address: [number, string], table: string) => void;
}

const LegalLingoForm: FC<ILegalLingoOem> = ({
  listOfCheckedVehicleInfoOems,
  listOfCheckedNumberAtThisPriceOems = [],
  listOfCheckedLeaseOems,
  listOfCheckedZeroDownLeaseOems,
  listOfCheckedFinanceOems,
  listOfCheckedPurchaseOems,
  listOfCheckedAPROems,
  onChange,
}) => {
  const ladTechLegalLingoVarDict = {
    "Vehicle Info": legalHelpers.returnOfferTypeLingoData("Vehicle Info"),
    "Number at this Price": numberAtThisPriceData,
    Lease: legalHelpers.returnOfferTypeLingoData("Lease"),
    "Zero Down Lease": legalHelpers.returnOfferTypeLingoData("Zero Down Lease"),
    Finance: legalHelpers.returnOfferTypeLingoData("Finance"),
    Purchase: legalHelpers.returnOfferTypeLingoData("Purchase"),
    APR: legalHelpers.returnOfferTypeLingoData("APR"),
  };

  const checkedVarsDict: Record<string, TCheckedVariable[]> = {
    "Vehicle Info": listOfCheckedVehicleInfoOems,
    "Number at this Price": listOfCheckedNumberAtThisPriceOems,
    Lease: listOfCheckedLeaseOems,
    "Zero Down Lease": listOfCheckedZeroDownLeaseOems,
    Finance: listOfCheckedFinanceOems,
    Purchase: listOfCheckedPurchaseOems,
    APR: listOfCheckedAPROems,
  };

  const offerTypeKeys = Object.keys(
    ladTechLegalLingoVarDict,
  ) as TVariableGroupName[];

  return (
    <div>
      <Collapse
        bordered={false}
        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        {offerTypeKeys.map((key, index) => (
          <Collapse.Panel header={`${key} Table`} key={`${index + 1}`}>
            <LegalLingoTable
              baseColumns={legalHelpers.oemLegalLingoColumns[key]}
              data={ladTechLegalLingoVarDict[key]}
              onChange={onChange}
              columnTitleProperty="lingo_oem"
              listOfCheckedValues={checkedVarsDict[key]}
              tableName={key}
              scroll={5000}
            />
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  );
};
const LegalLingoOem: FC<ILegalLingoOem> = props => {
  return <LegalLingoForm {...props} />;
};

export default LegalLingoOem;
