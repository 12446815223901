import { Tag, Tooltip } from "antd";
import { useMemo } from "react";
import { useFetchInfiniteDealers } from "shared/hooks/useFetchDealers";

type Props = {
  storeName: string;
};

const StoreTag = ({ storeName }: Props) => {
  const { dealers: stores } = useFetchInfiniteDealers();

  const fbAccountId = useMemo(() => {
    const store = stores.find(({ dealer_name }) => dealer_name === storeName);

    return store?.details.facebook.fbAccountId;
  }, [stores, storeName]);

  return (
    <Tooltip title={`Account ID: ${fbAccountId}`}>
      <Tag>{storeName}</Tag>
    </Tooltip>
  );
};

export default StoreTag;
