import { notification } from "antd";
import { useQuery } from "react-query";
import { adAccount } from "screens/adLibrary/facebookUtils/adAccount";

import {
  ICreateOrUpdateResponse,
  IFacebookAccount,
  IFacebookAssetType,
  IFacebookObject,
} from "screens/adLibrary/facebookUtils/types";
import { getErrorMessage } from "utils/errorMessage";

interface IGetFacebookObjectsArgs {
  adAccounts: IFacebookAccount[];
  objectType: IFacebookAssetType;
  skipNameMatching?: boolean;
}

type FetchFacebookObjectsArgs = {
  accountId: string;
  accountName: string;
  skipNameMatching?: boolean;
  objectType: IFacebookAssetType;
};

const fetchFacebookObjects = async ({
  accountId,
  objectType,
  accountName,
  skipNameMatching,
}: FetchFacebookObjectsArgs) => {
  const fetchArgs = { accountId, accountName, skipNameMatching };
  try {
    if (objectType === "page") return await adAccount.getPages(fetchArgs);
    if (objectType === "pixel") return await adAccount.getPixels(fetchArgs);
    if (objectType === "catalog") return await adAccount.getCatalogs(fetchArgs);
    return { result: null, error: { message: "Object type not supported." } };
  } catch (errorResponse) {
    return {
      result: null,
      error: { ...(errorResponse as ICreateOrUpdateResponse).error },
    };
  }
};

const getFacebookObjects =
  (args: IGetFacebookObjectsArgs & { objectType: IFacebookAssetType }) =>
  async () => {
    const { adAccounts, objectType, skipNameMatching } = args;

    const objectsResData = skipNameMatching
      ? [
          await fetchFacebookObjects({
            objectType,
            skipNameMatching,
            accountName: "",
            accountId: "",
          }),
        ]
      : await Promise.all(
          adAccounts.map(
            async account =>
              await fetchFacebookObjects({
                objectType,
                skipNameMatching,
                accountName: account.name,
                accountId: account.account_id,
              }),
          ),
        );

    // Possible future TO DO: split campaigns into {<accountId>:campaigns[]} dictionary
    let aggregatedData: IFacebookObject[] = [];

    objectsResData.forEach((response, index) => {
      const account = adAccounts[index];
      const { account_id: accountId, name } = account;
      const { result, error } = response;
      if (!result || error) {
        notification.error({
          message: "An error occurred while pulling Facebook data",
          description: `From account ${name} (${accountId}): ${
            (error as Error)?.message || "Something went wrong"
          }`,
        });
        return;
      }
      aggregatedData = aggregatedData.concat(result.data);
    });

    return aggregatedData;
  };

export const useFetchFacebookObjects = (
  args: IGetFacebookObjectsArgs,
  options?: {
    enabled?: boolean;
    retry?: boolean;
    cacheTime?: number;
    staleTime?: number;
  },
) => {
  return useQuery<IFacebookObject[] | undefined, Error>(
    [
      `facebook_${args.objectType}s`,
      args.adAccounts.map(account => account.account_id).join(","),
    ],
    getFacebookObjects(args),
    {
      enabled: options?.enabled,
      retry: options?.retry ?? false,
      cacheTime: options?.cacheTime ?? 300000,
      staleTime: options?.staleTime ?? Infinity,
    },
  );
};

export const useFetchFacebookCatalogs = () => {
  return useQuery<IFacebookObject[] | undefined, Error>({
    queryKey: [`facebook_catalogs`],
    staleTime: Infinity,
    queryFn: async () => {
      const { result, error } = await fetchFacebookObjects({
        objectType: "catalog",
        skipNameMatching: true,
        accountName: "",
        accountId: "",
      });

      if (!result || error) {
        throw new Error(
          getErrorMessage(error) ||
            "Something went wrong while fetching catalogs.",
        );
      }

      return result.data;
    },
  });
};
