import { DateFormater } from "../../DateFormater";
import { useColumn } from "../useColumn";

export const useColExpiration = () => {
  return useColumn("expiration", {
    title: "Expiration",
    renderFn(text: string, record: WDAsset | WDFolder) {
      if (record.type === "asset") {
        return <DateFormater date={record.expiration?.date} />;
      }
    },
  });
};
