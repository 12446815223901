import { Button, Drawer, notification, Space } from "antd";
import { useCallback, useEffect } from "react";
import {
  finishProcessingAds,
  resetAdImport,
  setAdImport,
  setAdsImport,
  setSelectedAdIds,
} from "redux/adLibrary/adLibrary.slice";
import { useAdEngineActions } from "redux/assetExporter/assetExporter.slice";
import AdImportTable from "screens/adLibrary/adImport/adCompleteImportDrawer/AdImportTable";
import { useBatchMutateAds } from "shared/hooks/adLibrary/useMutateAd";
import useFetchProcessedFeed from "shared/hooks/assetExporter/useFetchProcessedFeed";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { IAd } from "shared/types/adLibrary";

type Props = {
  feedId: string;
};
export const AdLibExportDrawer = ({ feedId }: Props) => {
  const { isExportAdLibOpen } = useAppSelector(
    state => state.assetExporter.configure,
  );
  const actions = useAdEngineActions();
  const { rows, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useFetchProcessedFeed(feedId, isExportAdLibOpen);

  // below follows same pattern as in src/screens/adLibrary/adImport/AdCompleteImportDrawer.tsx
  const { mutateAsync: batchMutateAdsAsync, isLoading: isCreatingAds } =
    useBatchMutateAds();

  const ads = useAppSelector(state => state.adLibrary.adImport.adsToImport);
  const selectedAdIds = useAppSelector(
    state => state.adLibrary.adImport.selectedAdIds,
  );
  const adValidations = useAppSelector(
    state => state.adLibrary.adImport.adValidations,
  );

  const dispatch = useAppDispatch();

  const setAd = (adId: string, adUpdate: IAd) => {
    dispatch(setAdImport({ adId, adUpdate }));
  };

  const setAds = (ads: IAd[]) => {
    dispatch(setAdsImport({ ads }));
  };

  const handleSetSelectedAdIds = (selectedAdIds: string[]) => {
    dispatch(setSelectedAdIds({ selectedAdIds }));
  };

  const handleClose = () => {
    dispatch(resetAdImport());
    actions.setIsAdLibExportDrawerOpen(false);
  };

  const handleImportSelectedAds = async () => {
    try {
      const selectedAds = ads.filter(ad => selectedAdIds.includes(ad.id));
      await batchMutateAdsAsync(selectedAds);
      handleClose();
    } catch (e) {
      notification.error({
        message: "There was an error trying to complete the import.",
      });
    }
  };

  const updateAdsToImport = useCallback(() => {
    if (!hasNextPage && !isFetchingNextPage) {
      dispatch(finishProcessingAds({ ads: rows }));
    }
  }, [dispatch, hasNextPage, isFetchingNextPage, rows]);

  const getNextPage = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  useEffect(getNextPage, [getNextPage]);
  useEffect(updateAdsToImport, [updateAdsToImport]);

  return (
    <Drawer
      visible={isExportAdLibOpen}
      title={<strong>Review Export to Ad Library</strong>}
      width="80%"
      footer={
        <Space>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="primary"
            loading={isCreatingAds}
            onClick={handleImportSelectedAds}
          >
            Complete Export
          </Button>
        </Space>
      }
      footerStyle={{ display: "flex", justifyContent: "flex-end" }}
      onClose={handleClose}
    >
      <AdImportTable
        ads={ads}
        selectedAdIds={selectedAdIds}
        setSelectedAdIds={handleSetSelectedAdIds}
        setAd={setAd}
        setAds={setAds}
        adValidations={adValidations}
      />
    </Drawer>
  );
};
