import { Table } from "antd";
import { useState } from "react";
import ExpandIcon from "shared/components/ExpandIcon";
import styles from "./AssignValuesTable.module.scss";
import { AllEntryTypes } from "./AssignValuesTable.types";
import { useColumns } from "./assignValuesTable/useColumns";
import { useDataSource } from "./assignValuesTable/useDataSource";

const AssignValuesTable = () => {
  const [hoveringRecord, setHoveringRecord] = useState<AllEntryTypes>();
  const dataSource = useDataSource();
  const columns = useColumns();

  return (
    <Table
      style={{ minWidth: 0, width: "100%" }}
      scroll={{ x: "max-content", y: "calc(100vh - 160px)" }}
      size="small"
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      sticky
      rowClassName={record => {
        return hoveringRecord && record.key.includes(hoveringRecord?.key)
          ? styles.highlightRow
          : "";
      }}
      onRow={record => ({
        onMouseEnter: () => setHoveringRecord(record),
        onMouseLeave: () => setHoveringRecord(undefined),
      })}
      expandable={{
        defaultExpandAllRows: true,
        expandIcon: function expandIcon(props) {
          return <ExpandIcon {...props} />;
        },
        indentSize: 28,
      }}
    />
  );
};
export default AssignValuesTable;
