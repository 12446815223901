import { useMemo } from "react";
import { useFetchPixelsForAccounts } from "screens/adLibrary/facebookUtils/assetQuery";

import { IAccount } from "shared/types/accountManagement";
import { IFacebookObject } from "screens/adLibrary/facebookUtils/types";

type PixelWarningArgs = {
  selectedStores?: IAccount[];
};

const storeNamesWithMismatches = (
  stores: IAccount[],
  pixelObjs: IFacebookObject[],
) => {
  const storesWithMismatches = stores.filter(
    dealer =>
      !pixelObjs.some(
        pixelObj => pixelObj.id === dealer.details.facebook.fbPixelId,
      ),
  );
  return storesWithMismatches.map(dealer => dealer.dealer_name);
};

const useFacebookPxelWarning = (args: PixelWarningArgs) => {
  const fbAccountIds =
    args.selectedStores?.map(dealer => dealer.details.facebook.fbAccountId) ??
    [];
  const { data: pixelsArr } = useFetchPixelsForAccounts(
    fbAccountIds.filter(id => !!id),
  );

  const pixelWarning = useMemo(() => {
    if (!args.selectedStores || !pixelsArr) return "";
    const storeNames = storeNamesWithMismatches(args.selectedStores, pixelsArr);
    if (!storeNames.length) return "";
    return `Assigned pixels don't match with available pixels from Facebook (${storeNames.join(
      ", ",
    )})`;
  }, [pixelsArr, args.selectedStores]);

  return pixelWarning;
};

export default useFacebookPxelWarning;
