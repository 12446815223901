import { FC, useEffect } from "react";

import { IAccount, IAccountPosition } from "shared/types/accountManagement";
import { Row, Input, Select, Checkbox, Form } from "antd";
import { ILauncherData, LauncherType } from "shared/types/assetBuilder";

interface IAssetLauncherPanelSettingsTabProps {
  dealerResult: IAccount | null | undefined;
  launcherData: ILauncherData;
  integrationPosition: IAccountPosition | null;
  type: LauncherType;
}

interface IAssetLauncherPanelSettingsTabHandlers {
  setIntegrationPosition: (
    integrationPosition: IAccountPosition | null,
  ) => void;
  setLauncherData: (launcherData: ILauncherData) => void;
  setType: (type: LauncherType) => void;
}

const AssetLauncherPanelSettingsTab: FC<
  IAssetLauncherPanelSettingsTabProps & IAssetLauncherPanelSettingsTabHandlers
> = ({
  dealerResult,
  integrationPosition,
  setIntegrationPosition,
  launcherData,
  setLauncherData,
  type,
  setType,
}) => {
  useEffect(() => {
    if (!!dealerResult?.web_int_positions?.length) {
      setIntegrationPosition(dealerResult.web_int_positions[0]);
    }
  }, [dealerResult, setIntegrationPosition]);
  return (
    <Form layout="vertical" hideRequiredMark={true}>
      <Row gutter={[4, 4]}>
        <Form.Item label="Name">
          <Input
            style={{ color: "black", width: "260px" }}
            disabled={false}
            key="domain-text"
            value={dealerResult?.dealer_url.replace(/(^\w+:|^)\/\//, "") || ""}
          />
        </Form.Item>
      </Row>
      <Row gutter={[4, 4]}>
        <Form.Item label="Position Presets">
          <Select
            style={{ width: "260px" }}
            onChange={(event: string) => {
              const index = parseInt(event.split("-key-")[1]) as number;

              const position = dealerResult?.web_int_positions?.[index];

              if (position) {
                setIntegrationPosition(position);
              }

              // based on the position name, set the launcherData
              setLauncherData({
                ...launcherData,
                relativePath: position?.relativePath || "",
                location: position?.location || "",
                mobileLocation: position?.mobileLocation || "",
              });
            }}
            value={integrationPosition?.name || ""}
          >
            {(dealerResult?.web_int_positions || []).map(
              (pos: IAccountPosition, index) => {
                return (
                  <Select.Option
                    className="template-tag-item"
                    key={`${pos.name}-key-${index}`}
                    value={`${pos.name}-key-${index}`}
                  >
                    {pos.name}
                  </Select.Option>
                );
              },
            )}
          </Select>
        </Form.Item>
      </Row>
      <Row gutter={[4, 4]}>
        <Form.Item label="Type">
          <Select
            style={{ width: "260px" }}
            onChange={(event: string) => {
              const mapToKey: Record<LauncherType, string> = {
                Grid: "grid",
                Carousel: "carousel",
                "Full Width Carousel": "fullWidthCarousel",
                Single: "singleImage",
                Stack: "stack",
                "Multi-Image Carousel": "multiCarousel",
              };
              const key = event.split("-key")[0] as LauncherType;
              setType(event.split("-key")[0] as LauncherType);
              setLauncherData({
                ...launcherData,
                type: mapToKey[key],
              });
            }}
            value={type}
          >
            {[
              "Single",
              "Grid",
              "Carousel",
              "Full Width Carousel",
              "Stack",
              "Multi-Image Carousel",
            ].map((type: string) => {
              return (
                <Select.Option
                  className="template-tag-item"
                  key={`${type}-key`}
                  value={type}
                >
                  {type}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        {["carousel", "multiCarousel", "fullWidthCarousel"].includes(
          launcherData.type,
        ) && (
          <div>
            <Checkbox
              onChange={e => {
                setLauncherData({
                  ...launcherData,
                  isAutoScrollEnabled: e.target.checked,
                });
              }}
            >
              Auto-Scroll
            </Checkbox>

            {launcherData.isAutoScrollEnabled && (
              <div>
                <div>
                  Transition Time:{" "}
                  <Input
                    style={{ color: "black", width: "260px" }}
                    disabled={false}
                    placeholder="ex. 0.75 (time is in seconds)"
                    key="carousel-transition-time"
                    value={launcherData.transitionTime || ""}
                    onChange={e => {
                      const val = e.target.value;
                      const regex = /^(0|[1-9]\d*)(\.\d+)?$/;
                      if (val[0] === ".") return;
                      if (
                        val.match(regex) ||
                        val[val.length - 1] === "." ||
                        val === ""
                      ) {
                        setLauncherData({
                          ...launcherData,
                          transitionTime: val,
                        });
                      }
                    }}
                  />
                </div>
                <div style={{ paddingTop: "10px" }}>
                  Time Until Next Transition:{" "}
                  <Input
                    style={{ color: "black", width: "260px" }}
                    disabled={false}
                    placeholder="ex. 4 (time is in seconds)"
                    key="carousel-time-next-transition"
                    value={launcherData.timeUntilNextTransition || ""}
                    onChange={e => {
                      const val = e.target.value;
                      const regex = /^(0|[1-9]\d*)(\.\d+)?$/;
                      if (val[0] === ".") return;
                      if (
                        val.match(regex) ||
                        val[val.length - 1] === "." ||
                        val === ""
                      ) {
                        setLauncherData({
                          ...launcherData,
                          timeUntilNextTransition: val,
                        });
                      }
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </Row>
    </Form>
  );
};

export default AssetLauncherPanelSettingsTab;
