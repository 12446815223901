import { Button } from "antd";
import { Mode } from "../AssetGroupDetailDrawer";
import styles from "./Footer.module.scss";

// If you want to know how the count on the bottom of the edit drawer created when user clicks on one of the row,
//  please refer to this ticket, https://theconstellationagency.atlassian.net/browse/CP-308
//  See "Desired Behavior" section.

type Props = {
  mode: Mode;
  isLoading: boolean;
  onCancel: () => void;
  onSave: () => void;
};

const Footer = ({ mode, isLoading, onSave, onCancel }: Props) => {
  return (
    <div className={styles.footer}>
      <div className={styles.actionButtons}>
        <Button key="cancel" onClick={onCancel}>
          {mode === "create" ? "Cancel" : "Close"}
        </Button>
        <Button
          key="submit"
          type="primary"
          form="ad-form"
          htmlType="submit"
          loading={isLoading}
          onClick={onSave}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default Footer;
