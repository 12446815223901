import { Tag, Tooltip } from "antd";
import { PropsWithChildren } from "react";

export const TooltipTag = ({
  title,
  children,
}: PropsWithChildren<{ title: string }>) => {
  return (
    <Tooltip title={title}>
      <Tag>{children}</Tag>
    </Tooltip>
  );
};
