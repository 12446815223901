import { SkipToken, skipToken } from "@reduxjs/toolkit/dist/query";
import { useLocation } from "react-router-dom";
import { useSearchAssetsQuery } from "redux/media/media.api";
import { withCustomPayload } from "./withCustomPayload";

export const useCustomSearchAssetsQuery = (
  search: MGSearchPayload | SkipToken,
) => {
  const { pathname } = useLocation();

  return useSearchAssetsQuery(
    search === skipToken ? skipToken : withCustomPayload(search, pathname),
  );
};
