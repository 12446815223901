export const subaruInternetSubCategories = [
  {
    category: "Animated Banner (GIF)",
    code: "Anibanner",
  },
  {
    category: "Broadcast",
    code: "Broadcast",
  },
  {
    category: "E-Mail",
    code: "email",
  },
  {
    category: "Internet Banner",
    code: "banner",
  },
  {
    category: "Internet Radio Display",
    code: "InterRadio",
  },
  {
    category: "Internet Radio Script/Storyboard",
    code: "IntRadScr",
  },
  {
    category: "Logo",
    code: "logo",
  },
  {
    category: "Mobile Media",
    code: "MM",
  },
  {
    category: "Over-the-Top",
    code: "OTT",
  },
  {
    category: "Over-the-Top Script/Storyboard",
    code: "OTTScript",
  },
  {
    category: "Pre-roll/Online Video",
    code: "SubPreRoll",
  },
  {
    category: "Pre-roll/Online Video Script/Storyboard",
    code: "POVS",
  },
  {
    category: "Rich Media/Video Banner",
    code: "RM",
  },
  {
    category: "Search Engine Marketing Keywords",
    code: "SubSE",
  },
  {
    category: "Search Engine Optimization",
    code: "SEOpt",
  },
  {
    category: "Social Media Advertising - GIF",
    code: "SMGIF",
  },
  {
    category: "Social Media Advertising - Banner",
    code: "SMBanner",
  },
  {
    category: "Social Media Advertising - Online Video",
    code: "SMVideo",
  },
  {
    category: "Social Media Management",
    code: "SubSocial",
  },
  {
    category: "Web Specials",
    code: "spec",
  },
];

export const subaruSDCInternetSubCategories = [
  {
    category: "Broadcast",
    code: "Broadcast",
  },
  {
    category: "E-Mail",
    code: "email",
  },
  {
    category: "Internet Banner",
    code: "banner",
  },
  {
    category: "Logo",
    code: "logo",
  },
  {
    category: "Mobile Media",
    code: "MM",
  },
  {
    category: "Rich Media/Video Banner",
    code: "RM",
  },
  {
    category: "Search Engines",
    code: "searcheng",
  },
  {
    category: "Social Media",
    code: "Social",
  },
  {
    category: "Web Specials",
    code: "spec",
  },
];

export const nissanInternetSubCategories = [
  {
    category: "Blog",
    code: "Blog",
  },
  {
    category: "Broadcast",
    code: "brdcst",
  },
  {
    category: "Internet Banner",
    code: "banner",
  },
  {
    category: "Mass E-Mail",
    code: "MassEmail",
  },
  {
    category: "News Letter",
    code: "Newsl",
  },
  {
    category: "Search Engines",
    code: "searcheng",
  },
  {
    category: "Social Media",
    code: "Social",
  },
  {
    category: "Third Party Site",
    code: "thirdparty",
  },
  // // TODO: this option needs a url so we won't support this for now
  // {
  //   category: "Web Page",
  //   code: "webp",
  // },
  {
    category: "Web Site Slider",
    code: "WebSlider",
  },
  {
    category: "Web Specials",
    code: "spec",
  },
];

export const toyotaInternetSubCategories = [
  {
    category: "Internet",
    code: "Internet",
  },
  {
    category: "Auto Shows",
    code: "Auto Shows",
  },
  {
    category: "Cinema",
    code: "Cinema",
  },
  {
    category: "Direct Mail",
    code: "Direct Mail",
  },
  {
    category: "Facebook",
    code: "Facebook",
  },
  {
    category: "Magazines",
    code: "Magazines",
  },
  {
    category: "Mall Displays",
    code: "Mall Displays",
  },
  {
    category: "Newspaper",
    code: "Newspaper",
  },
  {
    category: "Other",
    code: "Other",
  },
  {
    category: "Outdoor",
    code: "Outdoor",
  },
  {
    category: "Radio",
    code: "Radio",
  },
  {
    category: "Website Review",
    code: "Website Review",
  },
  {
    category: "Website Review",
    code: "Website Review",
  },
  {
    category: "Yellow Pages",
    code: "Yellow Pages",
  },
];

export const fordInternetSubCategories = [
  {
    category: "Broadcast",
    code: "Broadcast",
  },
  {
    category: "E-Mail",
    code: "email",
  },
  {
    category: "Internet Banner",
    code: "banner",
  },
  {
    category: "Mobile Media",
    code: "MM",
  },
  {
    category: "Search Engines",
    code: "searcheng",
  },
  {
    category: "Social Media",
    code: "Social",
  },
];

export const jlrInternetSubCategories = [
  {
    category: "E-Mail",
    code: "email",
  },
  {
    category: "Internet Banner",
    code: "banner",
  },
  {
    category: "Search Engines",
    code: "searcheng",
  },
  {
    category: "Social Media",
    code: "Social",
  },

  {
    category: "Web Page",
    code: "webp",
  },
];
