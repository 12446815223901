import { useCallback, useState } from "react";
import styles from "./OfferImage.module.scss";

import Placeholder from "statics/images/car_image_placeholder.png";
import OfferImagePopoverV2 from "screens/assetBuilder/offers/select/offerForm/OfferImagePopoverV2"; // NOTE: This has to be moved to offerImage/ later when inline editing is in use.

type Props = {
  vin?: string;
  url?: string;
};

type Handlers = {
  setEditOfferData?: () => void;
  onImageUrlChange: (url: string) => void;
};

const OfferImage = ({
  url,
  setEditOfferData,
  onImageUrlChange,
}: Props & Handlers) => {
  const [displayImageUploadButton, setDisplayImageUploadButton] =
    useState<boolean>(false);

  const [selectedJellybean, setSelectedJellybean] = useState<string>();

  const onMouseToggle = useCallback(
    (toggle: boolean) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();

      setDisplayImageUploadButton(toggle);
    },
    [],
  );

  const onJellybeanChange = useCallback(
    (jellybean: string) => {
      setSelectedJellybean(jellybean);

      onImageUrlChange(jellybean);
    },
    [onImageUrlChange],
  );

  const onEditOfferData = useCallback(() => {
    setEditOfferData?.(); // NOTE: This function is defined in OfferTypeCollpase.tsx in the Header Row.tsx
  }, [setEditOfferData]);

  return (
    <div
      className={styles.ImageWrapper}
      onMouseEnter={onMouseToggle(true)}
      onMouseLeave={onMouseToggle(false)}
    >
      <img src={selectedJellybean || url || Placeholder} />

      <OfferImagePopoverV2
        enable={displayImageUploadButton}
        setNewJellybean={onJellybeanChange}
        setEditOfferData={onEditOfferData}
      />
    </div>
  );
};

export default OfferImage;
