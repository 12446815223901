import { useMutation, useQueryClient } from "react-query";
import { getErrorMessage } from "utils/errorMessage";
import { BrandRecord, ILogos } from "shared/types/brandsAccounts";
import { BRANDS_QUERY_KEY } from "shared/constants/brandsAccounts";
import { uploadImagesToS3 } from "./uploadImageToS3";
import { formatAccountForTable } from "./useGetFormattedBrands";
import API from "services";
import {
  ICreateAccountResponse,
  ICreateAccountResult,
  IAccountRecord,
  IAccount,
} from "shared/types/accountManagement";
import {
  errorNotification,
  successNotification,
} from "shared/components/customNotification/Notification";
import { returnDealerDataObjFromRecord } from "redux/dealerManagement/dealerManagement.slice";
import { useBrandsAccountsContext } from "screens/brandsAccountsManagement/contexts/BrandsAccountsContext";
import { ACCOUNT_QUERY_KEY } from "../useFetchDealersV2";

interface ICreateAccountParams {
  account: IAccountRecord;
  logos: ILogos;
}

const createAccount = async ({ logos, account }: ICreateAccountParams) => {
  try {
    const uploadedLogos = await uploadImagesToS3(logos, "dealer-management");

    account.logoUrlsFromS3 = uploadedLogos;
    account.logoUrl = uploadedLogos.squareImagesFromS3[0] ?? "";
    account.createdAt = Date.now();
    account.updatedAt = Date.now();
    account.enabled = true;
    const { error, result } =
      await API.privServices.dealerManagement.createDealer<ICreateAccountResponse>(
        returnDealerDataObjFromRecord(account),
      );

    if (error) throw new Error(error.message);
    if (!result) throw new Error("Error creating account");

    return result;
  } catch (error) {
    const errMsg = getErrorMessage(error);
    throw new Error(errMsg);
  }
};

interface IUseCreateAccount {
  onSuccess: () => void;
}

export const useCreateAccount = ({ onSuccess }: IUseCreateAccount) => {
  const queryClient = useQueryClient();
  const { setFormTargetElementForUpdate } = useBrandsAccountsContext();

  return useMutation<ICreateAccountResult, Error, ICreateAccountParams>(
    createAccount,
    {
      onSuccess: newAccount => {
        queryClient.invalidateQueries(ACCOUNT_QUERY_KEY);

        const brandsResponse =
          queryClient.getQueryData<BrandRecord[]>(BRANDS_QUERY_KEY);
        brandsResponse?.forEach(brand => {
          if (brand.name === newAccount.createdDealer.dealer_oem) {
            const brandUpdated = {
              ...brand,
              children: [
                ...brand.children,
                formatAccountForTable(newAccount.createdDealer, brand.name),
              ],
            };
            setFormTargetElementForUpdate(brandUpdated);
          }
        });

        onSuccess();
        successNotification({
          messageLabel: "Account created successfully",
          size: "big",
        });
      },
      onError: error => {
        errorNotification({
          messageLabel:
            error.message === "Account name already exists."
              ? error.message
              : "Error creating account",
        });
      },
    },
  );
};
