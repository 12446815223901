import { DeleteOutlined } from "@ant-design/icons";
import { Button, message, Modal } from "antd";
import { useDeleteAssetsMutation } from "redux/media/media.api";
import { handleApiError } from "./handleApiError";
import { TooltipPerms } from "./TooltipPerms";
import { useFetchPermissions } from "./useFetchPermissions";
import { usePermsAssets } from "./usePermsAssets";
import { useSelected } from "./useSelected";

export const AssetsDelete = () => {
  const [deleteAssets] = useDeleteAssetsMutation();
  const { selectedAssets, clearSelected, allAssets } = useSelected();
  const allowed = usePermsAssets("delete", true);
  const enabled = allAssets && allowed;
  const fetchPerms = useFetchPermissions();

  const showConfirm = () => {
    const ids = selectedAssets.map(i => i.id);
    const folderIds = selectedAssets.map(asset => asset.folder.id);

    const modal = Modal.confirm({
      title: "Delete Assets",
      content: `Are you sure you want to delete ${ids.length} assets?`,
      okText: "Delete",
      okButtonProps: {
        danger: true,
        disabled: true,
        loading: true,
      },
      async onOk() {
        try {
          await deleteAssets(ids).unwrap();
          clearSelected();
          message.success(`${ids.length} assets deleted.`);
        } catch (err) {
          handleApiError(err);
        }
      },
    });

    fetchPerms(folderIds, "delete").then(canDelete => {
      modal.update(({ okButtonProps, ...rest }) => {
        return {
          ...rest,
          okButtonProps: {
            ...okButtonProps,
            disabled: !canDelete,
            loading: false,
          },
        };
      });
    });

    return modal;
  };

  return (
    <TooltipPerms action="Delete Assets" allowed={allowed} enabled={allAssets}>
      <Button
        danger
        shape="circle"
        icon={<DeleteOutlined />}
        onClick={showConfirm}
        disabled={!enabled}
      />
    </TooltipPerms>
  );
};
