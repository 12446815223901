import { Select } from "antd";
import { memo } from "react";

const automotiveCatalogVariables = {
  "Body Style": "vehicle.body_style",
  City: "vehicle.city",
  "Custom Label 0": "vehicle.custom_label_0",
  "Dealer Name": "vehicle.dealer_name",
  Description: "vehicle.description",
  "Exterior Color": "vehicle.exterior_color",
  Make: "vehicle.make",
  Mileage: "vehicle.mileage",
  Model: "vehicle.model",
  Price: "vehicle.price",
  Region: "vehicle.region",
  "Sale Price": "vehicle.sale_price",
  Title: "vehicle.title",
  Trim: "vehicle.trim",
  URL: "vehicle.url",
  "Vehicle ID": "vehicle.vehicle_id",
  VIN: "vehicle.vin",
  Year: "vehicle.year",
};

const productCatalogVariables = {
  Brand: "product.brand",
  "Retailer ID": "product.retailer_id ",
  Description: "product.description",
  Price: "product.price",
  "Current Price": "product.current_price",
  "Custom Label 0": "product.custom_label_0",
  "Custom Label 1": "product.custom_label_1",
  "Custom Label 2": "product.custom_label_2",
  "Custom Label 3": "product.custom_label_3",
  "Custom Label 4": "product.custom_label_4",
  URL: "product.url",
};

const travelCatalogVariables = {
  Name: "hotel.name",
  "Hotel ID": "hotel.hotel_id",
  Description: "hotel.description",
  Brand: "hotel.brand",
  City: "hotel.city",
  Country: "hotel.country",
  "Base Price": "hotel.base_price",
  Price: "hotel.price",
  "Lowest Price": "price.lowest_price",
  "Sale Price": "hotel.sale_price",
  "Total Price": "hotel.total_price",
  "Check-in Date": "trip.checkin_date",
  "Check-out Date": "trip.checkout_date",
  "Length of Stay": "trip.length_of_stay",
  Neighborhood: "hotel.neighborhood",
  "Number of travelers": "trip.num_travelers",
  "Number of adults": "trip.num_adults",
  "Number of children": "trip.num_children",
  "Star Rating": "hotel.star_rating",
  "Guest Rating": "hotel.guest_rating",
};

const pharmaCatalogVariables = {
  Brand: "pharma.brand",
  "Retailer ID": "pharma.retailer_id ",
  Description: "pharma.description",
  Price: "pharma.price",
  "Current Price": "pharma.current_price",
  "Custom Label 0": "pharma.custom_label_0",
  "Custom Label 1": "pharma.custom_label_1",
  "Custom Label 2": "pharma.custom_label_2",
  "Custom Label 3": "pharma.custom_label_3",
  "Custom Label 4": "pharma.custom_label_4",
  URL: "pharma.url",
};

export type DynamicCatalogType =
  | "PRODUCT_CATALOG"
  | "AUTOMOTIVE_CATALOG"
  | "TRAVEL_CATALOG"
  | "PHARMA_CATALOG";

const catalogs = {
  TRAVEL_CATALOG: travelCatalogVariables,
  PRODUCT_CATALOG: productCatalogVariables,
  AUTOMOTIVE_CATALOG: automotiveCatalogVariables,
  PHARMA_CATALOG: pharmaCatalogVariables,
};

const DynamicVariableSelect = ({
  onSelect,
  catalog = "PRODUCT_CATALOG",
}: {
  onSelect: (value: string) => void;
  catalog: DynamicCatalogType;
}) => {
  const options = catalogs[catalog];

  return (
    <Select
      style={{ width: 150 }}
      showSearch
      autoFocus
      open
      onSelect={onSelect}
    >
      {Object.entries(options).map(([key, value]) => (
        <Select.Option key={key} value={value}>
          {key}
        </Select.Option>
      ))}
    </Select>
  );
};

export default memo(DynamicVariableSelect);
