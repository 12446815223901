import { CheckCircleTwoTone } from "@ant-design/icons";
import { Button, Form, Input, message, Select } from "antd";
import { SelectValue } from "antd/lib/select";
import { ChangeEventHandler, useCallback, useEffect, useState } from "react";
import { UseQueryOptions } from "react-query";
import { useAdEngineActions } from "redux/assetExporter/assetExporter.slice";
import { gsConnectErrorMsg } from "shared/constants/assetExporter";
import { useGetGoogleSheet } from "shared/hooks/assetExporter/useFetchGoogleSheet";
import { useAppSelector } from "shared/hooks/useAppSelector";
import {
  AdEngineGoogleSheetForm,
  GoogleSheetsData,
} from "shared/types/assetExporter";
import { CsvTemplateOptions } from "./CsvTemplateOptions";

import styles from "./GoogleSheetUpload.module.scss";

const urlRegex = new RegExp(/^https:\/\/docs.google.com\/spreadsheets\/d\/.*/);

export const GoogleSheetUpload = () => {
  const {
    googleSheetUrl,
    feedName,
    googleSheetName,
    drawerMode,
    googleSheetId,
  } = useAppSelector(state => state.assetExporter.sourceFeed.feedDrawer);
  const actions = useAdEngineActions();

  const isUpdate = drawerMode === "UPDATE";
  const [errorMsg, setErrorMsg] = useState<string>();

  const fetchOptions: UseQueryOptions<GoogleSheetsData, Error> = {
    onError: (err: Error) => {
      if (err.message.includes("unique")) {
        setErrorMsg(err.message);
        return;
      }
      setErrorMsg(gsConnectErrorMsg);
    },
    onSuccess: () => {
      setErrorMsg(undefined);
    },
  };

  const {
    data: sheetData,
    isLoading,
    isSuccess,
  } = useGetGoogleSheet(googleSheetId, fetchOptions);

  const onValueChange: ChangeEventHandler<HTMLInputElement> = event => {
    event.preventDefault();
    const key = event.target.name as keyof AdEngineGoogleSheetForm;
    const value = event.target.value;

    if (key === "googleSheetUrl") {
      if (urlRegex.test(value)) {
        setErrorMsg(undefined);
      } else {
        setErrorMsg("Invalid Url");
      }
    }
    actions.setGoogleSheetData({ key, value });
  };

  const onConnectClick = () => {
    // NOTE: sheetUrl is in form "docs.google.com/spreadsheets/d/<SHEET-ID>/edit<other-random-strings>"
    const sheetId = googleSheetUrl.match(/\/d\/(.*?)\/edit.*/)?.[1];

    if (!sheetId) {
      message.error("Unable to get sheetId");
    } else {
      actions.setGoogleSheetData({ key: "googleSheetId", value: sheetId });
    }
  };

  const onDisconnectClick = () => {
    actions.resetGoogleSheetData();
    actions.setIsNextBtnDisabled(true);
  };

  const onSheetSelect = useCallback(
    (value: SelectValue) => {
      actions.setGoogleSheetData({
        key: "googleSheetName",
        value: value as string,
      });
      actions.setIsNextBtnDisabled(false);
    },
    [actions],
  );

  const syncSheetData = useCallback(() => {
    if (!sheetData || isUpdate) return;
    actions.setFeedName(sheetData.docTitle);

    if (sheetData?.sheetTitles.length > 0) {
      onSheetSelect(sheetData.sheetTitles[0]);
    }
  }, [actions, sheetData, isUpdate, onSheetSelect]);

  useEffect(syncSheetData, [syncSheetData]);

  return (
    <>
      <Form layout="vertical">
        <Form.Item
          label="Enter Google Spreadsheet URL"
          required={true}
          rules={[{ required: true, pattern: urlRegex }]}
          help={errorMsg}
          hasFeedback={!!errorMsg}
          validateStatus={!!errorMsg ? "error" : undefined}
        >
          <Input
            placeholder="https://"
            name="googleSheetUrl"
            onChange={onValueChange}
            disabled={isLoading || isSuccess}
            value={googleSheetUrl}
          />
        </Form.Item>
        <Form.Item>
          <Button
            disabled={
              (!!errorMsg && errorMsg != gsConnectErrorMsg) || !googleSheetUrl
            }
            loading={isLoading}
            onClick={onConnectClick}
            icon={isSuccess && <CheckCircleTwoTone twoToneColor="#52C41A" />}
            style={
              isSuccess
                ? {
                    backgroundColor: "#F6FFED",
                    color: "#52C41A",
                    borderColor: "#B7EB8F",
                  }
                : {}
            }
          >
            {isSuccess ? "Connected" : "Connect"}
          </Button>
          {isSuccess && (
            <Button
              onClick={onDisconnectClick}
              danger={true}
              className={styles.disconnected}
            >
              Disconnect
            </Button>
          )}
        </Form.Item>
        {isSuccess && (
          <>
            <Form.Item
              label={
                <span>
                  Feed name{" "}
                  <span className={styles.feedNameTitle}>(optional)</span>
                </span>
              }
            >
              <Input
                name="feedName"
                onChange={onValueChange}
                value={feedName}
              />
            </Form.Item>
            <Form.Item label="Sheet">
              <Select onChange={onSheetSelect} value={googleSheetName}>
                {sheetData?.sheetTitles.map(sheet => (
                  <Select.Option key={sheet} value={sheet}>
                    {sheet}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}
      </Form>
      <CsvTemplateOptions type="googlesheet" />
    </>
  );
};
