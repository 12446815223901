import HttpClient from "./httpClient";
import { isEmpty } from "lodash";
import GenericError from "shared/errors/GenericError";
import { IConfig } from "shared/types/configuration";
import {
  IAccount,
  IAccountResult,
  IGetAccountsResponse,
} from "shared/types/accountManagement";

export default ({ config }: { headers: any; config: IConfig }) => ({
  createDealer: async <T>(inputDealer: IAccount): Promise<T> => {
    const { services } = config;
    return HttpClient.post<T>(services.createDealerUrl, {
      dealer: inputDealer,
    });
  },

  getAllDealers: <T>(): Promise<T> => {
    const { services } = config;

    const getDealersUrl = services.getDealersUrl;

    return HttpClient.get<IGetAccountsResponse>(getDealersUrl)
      .then(async response => {
        const { result } = response;

        if (!result || (result.scannedCount === 0 && !result.paginationKey)) {
          return {
            error: {
              message: "Could not locate any dealers",
            },
          } as any;
        }
        let dealerRecords = [...result.dealers];

        let paginationToken: { dealer_name: string } | undefined =
          result.paginationKey;

        while (paginationToken && !isEmpty(paginationToken)) {
          const url = getDealersUrl.concat(
            `?paginationToken=${paginationToken.dealer_name}`,
          );

          paginationToken = await HttpClient.get<IGetAccountsResponse>(
            url,
          ).then(async response => {
            const { result } = response;

            dealerRecords = [...dealerRecords, ...(result?.dealers || [])];
            return result?.paginationKey;
          });
        }
        return {
          result: { dealerRecords },
          error: null,
        };
      })
      .catch(() => {
        return {
          result: null,
          error: {
            message: "Error occurred while fetching dealers",
          },
        };
      });
  },

  getDealers: <T>(paginationToken: string = ""): Promise<T> => {
    const { services } = config;
    let getDealersUrl = services.getDealersUrl;

    if (paginationToken) {
      getDealersUrl = getDealersUrl.concat(
        `?paginationToken=${paginationToken}`,
      );
    }

    return HttpClient.get(getDealersUrl);
  },

  getDealer: async (dealerName: string): Promise<IAccountResult> => {
    const { services } = config;
    const getDealersUrl = services.getDealerUrl;

    try {
      return await HttpClient.get<IAccountResult>(
        `${getDealersUrl}?dealerName=${dealerName}`,
      );
    } catch (err) {
      return {
        result: null,
        error: {
          message: "There was an error while obtaining dealer.",
        },
      };
    }
  },

  getDealerByNames: async (names: string[]) => {
    try {
      const url = `${config.services.getDealerByNamesUrl}?names=${names
        .map(name => encodeURIComponent(name))
        .join(",")}`;

      return await HttpClient.get<{ result: IAccount[]; error: GenericError }>(
        url,
      );
    } catch (err) {
      const error = new GenericError({
        message: `${err}`,
      });

      return {
        result: null,
        error,
      };
    }
  },

  getDealersByPaginatinationToken: <T>(
    paginationToken: string = "",
  ): Promise<T> => {
    const { services } = config;
    let getDealersUrl = services.getDealersUrl;

    if (paginationToken) {
      getDealersUrl = getDealersUrl.concat(
        `?paginationToken=${paginationToken}`,
      );
    }

    return HttpClient.get(getDealersUrl);
  },

  deleteDealer: <T>(inputDealer: IAccount): Promise<T> => {
    const { services } = config;

    return HttpClient.delete(services.deleteDealerUrl, {
      dealer: inputDealer,
    });
  },

  updateDealer: <T>(
    inputDealer: IAccount,
    updatedData?: Partial<IAccount>,
    logosToRemove?: string[],
  ): Promise<T> => {
    const { services } = config;

    return HttpClient.put(services.updateDealerUrl, {
      dealer: inputDealer,
      updatedData: updatedData || [],
      logosToRemove: logosToRemove || [],
    });
  },
});
