import { Alert, Button, Typography } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import styles from "./MissingLocationAlert.module.scss";

type MissingLocationAlertProps = {
  accountName: string;
  onClick: () => void;
};

export const MissingLocationAlert = ({
  accountName,
  onClick,
}: MissingLocationAlertProps) => {
  return (
    <Alert
      showIcon
      icon={<CloseCircleFilled />}
      type="error"
      className={styles.alertContainer}
      message={
        <div className={styles.alertContent}>
          <Typography
            className={styles.message}
          >{`Address is missing for the ${accountName} account`}</Typography>
          <Button size="small" type="link" onClick={onClick}>
            Set location information
          </Button>
        </div>
      }
    />
  );
};
