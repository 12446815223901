import { useAuth0 } from "@auth0/auth0-react";
import { Amplify } from "aws-amplify";
import { rbac } from "config/rbac";
import { decodeJwt, JWTPayload } from "jose";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { addPerms, initUser, Permissions, User } from "redux/auth/auth.slice";
import API from "services";
import HttpClient from "services/httpClient";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import "shared/styles/themes/theme.scss";
import { ConfigContext } from "./ConfigContext";
import { setDatdogSessionUser } from "shared/datadog";
import capitalize from "lodash/capitalize";
import { fetchAuthSession } from "aws-amplify/auth";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { CredProvider } = require("./CredProvider");

type AuthExtProps = {
  authorized: boolean;
};

type AuthContextProps = AuthExtProps;

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps,
);

type AuthProviderProps = PropsWithChildren<object>;

type TokenPayload = JWTPayload & Permissions;

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const { config } = useContext(ConfigContext);
  const [authorized, setAuthorized] = useState(false);

  const dispatch = useAppDispatch();
  const {
    getAccessTokenSilently,
    isAuthenticated,
    user: authUser,
  } = useAuth0<User>();

  const handleAuth = useCallback(() => {
    const fetchToken = async () => {
      const isTestEnv = !!window.Cypress;
      try {
        const cypressAuthInfo = JSON.parse(
          localStorage.getItem("auth0Cypress")!,
        );
        Amplify.configure({
          // TODO: will add for v3 interop
          // aws_appsync_graphqlEndpoint: gqlEp(config.gqlId, config.region),
          // aws_appsync_region: config.region,
          // aws_appsync_authenticationType: "OPENID_CONNECT",
          Auth: {
            Cognito: {
              identityPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
            },
          },
        });

        const credentialsProvider = new CredProvider(
          process.env.REACT_APP_AWS_REGION,
          process.env.REACT_APP_COGNITO_USER_POOL_ID,
        );
        Amplify.configure({}, { Auth: { credentialsProvider } });

        const token = window.Cypress
          ? cypressAuthInfo
          : await getAccessTokenSilently();
        const info = decodeJwt(token) as TokenPayload;
        const { permissions: decodedPerms } = info;
        const user = {
          sub: info.sub ?? "",
          email: authUser?.email ?? "",
          name: authUser?.name ?? "",
          app_metadata: authUser?.app_metadata ?? {},
          user_metadata: authUser?.user_metadata
            ? {
                ...authUser.user_metadata,
                ...(authUser.user_metadata.firstName
                  ? { firstName: capitalize(authUser.user_metadata.firstName) }
                  : {}),
                ...(authUser.user_metadata.lastName
                  ? {
                      lastName: capitalize(authUser.user_metadata.lastName),
                    }
                  : {}),
              }
            : {},
        };
        dispatch(initUser({ user }));
        setDatdogSessionUser(user);
        const permissions = isTestEnv
          ? [rbac.manageAdmin, rbac.manageSalesEnablementAdmin]
          : decodedPerms;
        dispatch(addPerms({ permissions }));
        const itemKey = "aws-amplify-federatedInfo";
        const checkFed = window.localStorage.getItem(itemKey);
        if (!checkFed && !window.Cypress) {
          credentialsProvider.loadFederatedLogin({
            domain: config.auth.domain,
            token,
          });
          await fetchAuthSession();
        }
        const isAdmin = permissions?.includes(rbac.manageAdmin) ?? false;
        HttpClient.isAdmin = isAdmin;
        HttpClient.token = token;
        API.token = token;
        setAuthorized(true);
        API.privServices.base.upserUserAttrs(user);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("error", error);
      }
    };
    if (isAuthenticated || window.Cypress) {
      fetchToken();
    }
  }, [
    authUser?.app_metadata,
    authUser?.email,
    authUser?.name,
    authUser?.user_metadata,
    config.auth.domain,
    dispatch,
    getAccessTokenSilently,
    isAuthenticated,
  ]);

  useEffect(handleAuth, [handleAuth]);
  return (
    <AuthContext.Provider value={{ authorized }}>
      {children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;
