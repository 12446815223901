import { DropDownProps } from "../../types";
import { Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";

export const DropDown = ({ searchBy, createNewTag }: DropDownProps) => {
  const onClick = () => {
    if (searchBy.trim() === "") {
      Modal.warning({
        title: "The tag name is not valid. Please enter valid tag name.",
      });
    } else {
      Modal.confirm({
        title: "Do you want to add new tag?",
        content: (
          <span className="add-template-tag-container-span">
            Are you sure you want to add new tag,
            <span>{searchBy}</span>
            for this theme background?
          </span>
        ),
        okText: "Add",
        cancelText: "Cancel",
        onOk: createNewTag,
      });
    }
  };
  return (
    <div className="add-tag-dropdown-container" onClick={onClick}>
      <PlusOutlined /> Add Tag
    </div>
  );
};
