import API from "services";
import { useQuery } from "react-query";
import { YoutubeAccounts } from "shared/types/adLibrary";

const getYoutubeAccounts = async (oem: string): Promise<YoutubeAccounts> => {
  const { result, error } = await API.services.adLibrary.getYoutubeAccounts(
    oem,
  );
  if (error) {
    throw Error(error?.message || "something went wrong!");
  }
  return result;
};

const useFetchYoutubeAccounts = (oem: string, connected: boolean) => {
  return useQuery(["youtubeAccounts", oem], () => getYoutubeAccounts(oem), {
    enabled: connected,
    retry: false,
  });
};

export default useFetchYoutubeAccounts;
