import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "redux/auth/auth.slice";
import { AppThunk } from "redux/store";
import {
  ICreateUserResponse,
  IUserSignUpForm,
  IUserState,
  Mode,
  UserInfo
} from "shared/types/userManagement";

const initialState: IUserState = {
  addingUser: false,
  signUpForm: {},
  mode: "USER MANAGEMENT", // "USER MANAGEMENT" | "ADD USER";
  result: undefined,
  error: null,
  gettingUsers: false,
  users: [],
  updatingUserStatus: false,
  paginationToken: "",
  uuid: "",
  numberOfUsers: 0,
  updatedUser: undefined,
  userEmailsSet: [],
};

type TUserError = {
  code: string;
  message: string;
};

const userManagementSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    addUserBegin(state) {
      return {
        ...state,
        addingUser: true,
        error: null,
        successObject: null,
      };
    },
    addUserSuccess(
      state,
      {
        payload: result,
      }: PayloadAction<{
        response: ICreateUserResponse["result"];
        header: string;
        message: string;
      }>,
    ) {
      // const { response } = result;
      // const {
      //   user,
      //   userCreateDate,
      //   userLastModifiedDate,
      //   userName,
      //   userStatus,
      // } = response!;

      // TODO: need to figure this out

      // const newUser: UserInfo = {
      //   enabled: true,
      //   user: {
      //     ...(user || {}),
      //     toolAccess: user.toolAccess
      //       ? JSON.parse(user.toolAccess as unknown as string)
      //       : null,
      //     stores: user.stores
      //       ? JSON.parse(user.stores as unknown as string)
      //       : null,
      //     dashboards: user.dashboards
      //       ? JSON.parse(user.dashboards as unknown as string)
      //       : null,
      //   },
      //   userCreateDate,
      //   userLastModifiedDate,
      //   userName,
      //   userStatus,
      // };

      // const newUserList = [...state.users, newUser];
      // const emailsSet = new Set(state.userEmailsSet);
      // emailsSet.add(user.email);

      // return {
      //   ...state,
      //   addingUser: false,
      //   error: null,
      //   result,
      //   users: newUserList,
      //   numberOfUsers: state.numberOfUsers + 1,
      //   userEmailsSet: [...emailsSet],
      // };
      return state;
    },
    addUserFail(
      state,
      { payload: error }: PayloadAction<{ code: string; message: string }>,
    ) {
      return {
        ...state,
        addingUser: false,
        error,
        successObject: null,
      };
    },
    deleteUserSuccess(
      state,
      {
        payload: deleteUserSuccess,
      }: PayloadAction<{
        header: string;
        message: string;
        form: IUserSignUpForm;
      }>,
    ) {
      // const { form: userForm } = deleteUserSuccess;

      // const deleteUserArray = state.users.filter(userObj => {
      //   return userObj.user.email !== userForm.email;
      // });

      // const emailsSetDelete = new Set(state.userEmailsSet);
      // emailsSetDelete.delete(userForm.email);

      // return {
      //   ...state,
      //   addingUser: false,
      //   error: null,
      //   deleteUserSuccess,
      //   users: deleteUserArray,
      //   numberOfUsers: state.numberOfUsers - 1,
      //   userEmailsSet: [...emailsSetDelete],
      // };
      return state;
    },
    deleteUserFail(
      state,
      {
        payload: deleteUserError,
      }: PayloadAction<{ code: string; message: string }>,
    ) {
      return {
        ...state,
        addingUser: false,
        error: deleteUserError,
        successObject: null,
      };
    },
    updateCurrentUser(state, { payload: user }: PayloadAction<User>) {
      return state;
    },
    updateUserSuccess(
      state,
      {
        payload: updateResult,
      }: PayloadAction<{ user: User; userName: string }>,
    ) {
      // const { user: userToUpdate, userName: emailToUpdate } = updateResult;
      // const { users } = state;

      // // tslint:disable-next-line: no-shadowed-variable
      // const updatedUserList = users.map(user => {
      //   if (user.user.email === emailToUpdate) {
      //     return {
      //       ...user,
      //       user: {
      //         ...userToUpdate,
      //         email: user.user.email,
      //       },
      //     };
      //   }
      //   return user;
      // });

      // return {
      //   ...state,
      //   users: updatedUserList,
      //   updatedUser: updateResult,
      //   error: null,
      // };
      return state;
    },
    updateUserFail(
      state,
      {
        payload: updateUserError,
      }: PayloadAction<{ code: string; message: string }>,
    ) {
      return {
        ...state,
        error: updateUserError || {},
      };
    },
    getUsersBegin(state) {
      return {
        ...state,
        gettingUsers: true,
        error: null,
      };
    },
    getUsersSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        response: {
          PaginationToken?: string;
          Users: any[];
          numberOfUsers: number;
        };
        searchUsersInput: string;
      }>,
    ) {
      // const { response, searchUsersInput } = payload;
      // const {
      //   PaginationToken: paginationToken,
      //   Users: users,
      //   numberOfUsers,
      // } = response;

      // const returnedUsers = users;

      // const stateUsers = [...state.users];
      // const userSet: Set<string> = new Set(state.userEmailsSet);

      // for (const returnedUser of returnedUsers) {
      //   if (!userSet.has(returnedUser.user.email)) {
      //     userSet.add(returnedUser.user.email);

      //     let newToolAccess: ToolAccess;
      //     if (!returnedUser.user?.toolAccess) {
      //       newToolAccess =
      //         defaultPermissionsByJobRole[returnedUser.user?.group as Groups];
      //     } else {
      //       const incomingToolAccess = JSON.parse(
      //         returnedUser.user?.toolAccess || "{}",
      //       );
      //       const validKeys = Object.keys(incomingToolAccess).every(
      //         toolAccess => {
      //           return !!AlexiaModule[toolAccess as AlexiaModule];
      //         },
      //       );

      //       !validKeys
      //         ? (newToolAccess =
      //             defaultPermissionsByJobRole[
      //               returnedUser.user?.group as Groups
      //             ])
      //         : (newToolAccess = incomingToolAccess);
      //     }

      //     stateUsers.push({
      //       ...returnedUser,
      //       user: {
      //         ...returnedUser.user,
      //         toolAccess: newToolAccess,
      //         stores: JSON.parse(returnedUser.user?.stores || "[]") || [],
      //         dashboards:
      //           JSON.parse(returnedUser.user?.dashboards || "[]") || [],
      //       },
      //     });

      //   }
      // }

      // return {
      //   ...state,
      //   users: stateUsers,
      //   gettingUsers: false,
      //   paginationToken,
      //   numberOfUsers,
      //   error: null,
      //   userEmailsSet: [...userSet],
      // };
      return state;
    },
    getUsersFail(state, { payload: error }: PayloadAction<Error>) {
      return {
        ...state,
        gettingUsers: false,
        error,
      };
    },
    updateUserStatusBegin(state) {
      return {
        ...state,
        updatingUserStatus: true,
        error: null,
        result: null,
      };
    },
    updateUserStatusSuccess(
      state,
      {
        payload: updateUserStatusResult,
      }: PayloadAction<{
        header: string;
        message: string;
        updatedIsUserActive: any;
        updatedUserName: any;
        updatedUser?: UserInfo;
      }>,
    ) {
      // const { updatedIsUserActive, updatedUserName } = updateUserStatusResult;
      // tslint:disable-next-line: no-shadowed-variable
      // const updatedUsers = state.users.map(user => {
      //   if (user.userName === updatedUserName) {
      //     const updatedUser: UserInfo = {
      //       ...user,
      //       enabled: updatedIsUserActive,
      //     };
      //     updateUserStatusResult.updatedUser = updatedUser;
      //     return updatedUser;
      //   }
      //   return user;
      // });

      // return {
      //   ...state,
      //   updatingUserStatus: false,
      //   error: null,
      //   users: updatedUsers,
      //   result: updateUserStatusResult,
      // };
      return state;
    },
    updateUserStatusFail(
      state,
      { payload: updateUserStatusError }: PayloadAction<{ message: string }>,
    ) {
      return {
        ...state,
        updatingUserStatus: false,
        error: updateUserStatusError,
        result: null,
      };
    },
    resetUserFeedbackSuccess(state) {
      return {
        ...state,
        error: null,
      };
    },
    setSignUpForm(state, { payload: form }: PayloadAction<IUserSignUpForm>) {
      return {
        ...state,
        signUpForm: form,
      };
    },
    setMode(state, { payload: mode }: PayloadAction<Mode>) {
      return {
        ...state,
        addingUser: false,
        mode,
        error: null,
        successObject: null,
      };
    },
  },
});

// export const addUser =
//   (form: IUserSignUpForm): AppThunk =>
//   async (dispatch, getState) => {
//     dispatch(addUserBegin());

//     await delay(500);
//     try {
//       const response =
//         await API.privServices.userManagement.createUser<ICreateUserResponse>(
//           form,
//         );

//       const { error } = response;

//       if (error) {
//         // most likely, it is system error.
//         // since error.message is being used to give user feedback in AddUser.tsx,
//         // format the obj passed into addUserFail()
//         const { code, message } = error || {
//           code: "System Error",
//           message: "There was an error in the system while adding a User.",
//         };

//         dispatch(
//           addUserFail({
//             code,
//             message,
//           }),
//         );

//         return;
//       }

//       dispatch(
//         addUserSuccess({
//           header: "User has been added",
//           message: `Email has been sent with temporary password.`,
//           response: response.result,
//         }),
//       );
//     } catch (error) {
//       const err = error as TUserError;
//       dispatch(
//         addUserFail(
//           err || {
//             code: "",
//             message: "Error while attempting to add user. ",
//           },
//         ),
//       );
//     }
//   };

// export const deleteUser =
//   (form: IUserSignUpForm): AppThunk =>
//   async (dispatch, getState) => {
//     await delay(500);
//     try {
//       const response =
//         await API.privServices.userManagement.deleteUser<ICreateUserResponse>(
//           form,
//         );

//       const { error } = response;

//       if (error) {
//         // most likely, it is system error.
//         // since error.message is being used to give user feedback in AddUser.tsx,
//         // format the obj passed into addUserFail()
//         const { code, message } = error || {
//           code: "System Error",
//           message: "There was an error in the system while deleting a User.",
//         };

//         dispatch(
//           deleteUserFail({
//             code,
//             message,
//           }),
//         );

//         return;
//       }

//       dispatch(
//         deleteUserSuccess({
//           header: "User has been deleted",
//           message: "",
//           form,
//         }),
//       );
//     } catch (error) {
//       const err = error as TUserError;

//       dispatch(
//         deleteUserFail(
//           err || {
//             code: "",
//             message: "Error while attempting to delete user. ",
//           },
//         ),
//       );
//     }
//   };

// export const updateUser =
//   (form: IUserSignUpForm, id: string): AppThunk =>
//   async (dispatch, getState) => {
//     const currentUser = await authenticators.getCurrentUser();

//     await delay(500);

//     const { attributes } = form as { attributes: any };

//     if (attributes.firstName === "" || attributes.lastName === "") {
//       const code = "Empty Fields Error";
//       const message = "First Name and Last Name Fields must be filled out";
//       dispatch(
//         updateUserFail({
//           code,
//           message,
//         }),
//       );
//     }

//     const updatingCurrentUser =
//       (await currentUser.getUsername()) === form.email;

//     if (updatingCurrentUser) {
//       const attributeNames: string[] = Object.keys(attributes);
//       const userAttributesForCognito = attributeNames.reduce<
//         Record<string, string>
//       >((attrObj, name) => {
//         if (name === "phoneNumber") return attrObj;
//         const stringifyUserAttr = ["toolAccess", "stores", "dashboards"];

//         attrObj[`custom:${name}`] = stringifyUserAttr.includes(name)
//           ? JSON.stringify(attributes[name])
//           : attributes[name];
//         return attrObj;
//       }, {});

//       try {
//         await authenticators.updateUserAttributes(
//           currentUser,
//           userAttributesForCognito,
//         );

//         dispatch(
//           updateCurrentUser({
//             ...form.attributes,
//             email: form.email,
//           } as IUser),
//         );
//       } catch (error) {
//         const err = error as TUserError;
//         throw new GenericError({
//           message: err.message,
//         });
//       }
//     }

//     try {
//       const response =
//         await API.privServices.userManagement.updateUser<ICreateUserResponse>(
//           form,
//           id,
//         );

//       const { error, result } = response;
//       if (error || !result) {
//         // most likely, it is system error.
//         // since error.message is being used to give user feedback in AddUser.tsx,
//         // format the obj passed into addUserFail()
//         const { code, message } = error || {
//           code: "System Error",
//           message: "There was an error in the system while adding a User.",
//         };
//         dispatch(
//           updateUserFail({
//             code,
//             message,
//           }),
//         );

//         return;
//       }

//       const { user, userName } = result;

//       dispatch(updateUserSuccess({ user, userName }));
//     } catch (error) {
//       const err = error as TUserError;
//       dispatch(
//         updateUserFail(
//           err || {
//             code: "",
//             message: "Error while attempting to edit user. ",
//           },
//         ),
//       );
//     }
//   };

// export const getUsers =
//   (
//     paginationToken: string | undefined,
//     searchUsersInput: string = "",
//   ): AppThunk =>
//   async (dispatch, getState) => {
//     dispatch(getUsersBegin());

//     await delay(500);
//     try {
//       const response =
//         await API.privServices.userManagement.getUsers<IGetUsersResponse>(
//           paginationToken,
//           searchUsersInput,
//         );

//       const { result, error } = response;

//       if (error) {
//         const { message } = error;

//         dispatch(getUsersFail(new GenericError(message)));
//         return;
//       }

//       dispatch(
//         getUsersSuccess({
//           response: result,
//           searchUsersInput,
//         }),
//       );
//     } catch (error) {
//       const err = error as { message: string } | undefined;
//       dispatch(
//         getUsersFail(
//           new GenericError({
//             message: err?.message || "Some error occurred.",
//             errorObject: `${error}`,
//           }),
//         ),
//       );
//     }
//   };

// export const updateUserStatus =
//   (userName: string, userStatus: boolean): AppThunk =>
//   async dispatch => {
//     dispatch(updateUserStatusBegin());

//     try {
//       // TODO: fix return type
//       const { result, error } =
//         await API.services.userManagement.updateUserStatus<any>(
//           userName,
//           userStatus,
//         );

//       if (error) {
//         const { code, message } = error || {
//           code: "System Error",
//           message:
//             "There was an error in the system while updating the user's status",
//         };

//         dispatch(
//           updateUserStatusFail({
//             code,
//             message,
//           }),
//         );

//         return;
//       }

//       const {
//         isUserActive: newIsUserActive = false,
//         userName: returnedUserName = "",
//       } = result || {};

//       dispatch(
//         updateUserStatusSuccess({
//           header: "The user's active status has been updated",
//           message: `The user has been ${
//             !newIsUserActive ? "Deactivated" : "Activated"
//           }`,
//           updatedIsUserActive: newIsUserActive,
//           updatedUserName: returnedUserName || userName,
//         }),
//       );
//     } catch (error) {
//       const err = error as TUserError;
//       dispatch(
//         updateUserStatusFail(
//           err || {
//             code: "",
//             message: "Error while attempting to activate user. ",
//           },
//         ),
//       );
//     }
//   };

export const resetUserFeedback = (): AppThunk => dispatch => {
  dispatch(resetUserFeedbackSuccess());
};

export const {
  addUserBegin,
  addUserFail,
  addUserSuccess,
  deleteUserFail,
  deleteUserSuccess,
  getUsersBegin,
  getUsersFail,
  getUsersSuccess,
  resetUserFeedbackSuccess,
  updateCurrentUser,
  updateUserFail,
  updateUserStatusBegin,
  updateUserStatusFail,
  updateUserStatusSuccess,
  updateUserSuccess,
  setSignUpForm,
  setMode,
} = userManagementSlice.actions;

export default userManagementSlice.reducer;
