import {
  useContext,
  createContext,
  ReactNode,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";
import {
  FileDrawerMode,
  DrawerDisabledMap,
  Language,
  Template,
  TemplateStatus,
} from "shared/types/salesEnablement";
import { keys } from "utils/helpers";
import { useForm } from "antd/lib/form/Form";
import { FormInstance } from "antd/es/form/Form";
import { useFetchTemplates } from "shared/hooks/designStudio/useFetchTemplates";
import { useFileData } from "./useFileData";
import { TemplateForm } from "../templates/fileDrawer/FormFields.schema";
import { useAuthedAgentLicensedStates } from "shared/hooks/salesEnablement/useAgentLicensedStates";
import { StateKey, stateKeys } from "shared/constants/states";
import { useIsSalesEnablementAdmin } from "shared/hooks/useIsAdmin";
import { useGetTemplateErrorStatusFn } from "../templates/errorStatus.utils";
import { raise } from "utils/errorMessage";

type ContextType = {
  templates: Template[];
  isTemplateLoading: boolean;
  sortableKeys: (keyof Template)[];
  uploadForm?: FormInstance<TemplateForm>;
  isFormDirty: boolean;
  setIsFormDirty: Dispatch<SetStateAction<boolean>>;
  files: Template["files"];
  setFiles: Dispatch<SetStateAction<Template["files"]>>;
  hasFiles: boolean;
  status: Template["status"];
  setStatus: Dispatch<SetStateAction<Template["status"]>>;
  searchLayersBy?: string;
  onSearchLayersBy?: (value: string) => void;
  data: Record<Language, ReturnType<typeof useFileData>>;
  selectedTemplate?: Template;
  setSelectedTemplate: Dispatch<SetStateAction<Template | undefined>>;
  fileDrawerMode?: FileDrawerMode;
  setFileDrawerMode: Dispatch<SetStateAction<FileDrawerMode | undefined>>;
  drawerDisabledMap: DrawerDisabledMap | undefined;
  setDrawerDisabledMap: Dispatch<SetStateAction<DrawerDisabledMap | undefined>>;
  availableLocations: StateKey[];
  setSelectedTemplateById: (templateId: string) => void;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  componentsTabsFilter: string;
  setComponentsTabsFilter: Dispatch<SetStateAction<string>>;
  activeLanguageTab: Language;
  setActiveLanguageTab: Dispatch<SetStateAction<Language>>;
};

const Context = createContext<ContextType | null>(null);

export const useSalesEnablementContext = () => {
  const ctx = useContext(Context);

  if (!ctx) {
    throw new Error(
      "useSalesEnablementContext must be used within SalesEnablementDataProvider.",
    );
  }
  return ctx;
};

const DataContext = ({ children }: { children: ReactNode }) => {
  const { templates: allTemplates, isTemplateLoading } = useFetchTemplates();
  const { licensedStates } = useAuthedAgentLicensedStates();
  const isSalesEnablementAdmin = useIsSalesEnablementAdmin();
  const availableLocations = useMemo(() => {
    if (isSalesEnablementAdmin) return stateKeys;
    return licensedStates ?? [];
  }, [isSalesEnablementAdmin, licensedStates]);
  const getTemplateErrorStatus = useGetTemplateErrorStatusFn();
  const [activeLanguageTab, setActiveLanguageTab] = useState<Language>("en");

  const templates = useMemo(() => {
    if (isSalesEnablementAdmin) return allTemplates;

    return allTemplates.filter(template => !getTemplateErrorStatus(template));
  }, [allTemplates, getTemplateErrorStatus, isSalesEnablementAdmin]);

  const sortableKeys = useMemo(() => {
    const [template] = templates || [];
    return keys<keyof Template>(
      !!template
        ? {
            name: template.name,
            status: template.status,
            audience: template.audience,
            locations: template.locations,
            languages: template.languages,
            customizable: template.customizable,
            deliveryMethods: template.deliveryMethods,
            salesCycle: template.salesCycle,
            materialType: template.materialType,
            ...(isSalesEnablementAdmin
              ? {
                  expirationDate: template.expirationDate,
                }
              : {}),
            lastUpdatedAt: template.lastUpdatedAt,
          }
        : {},
    );
  }, [templates, isSalesEnablementAdmin]);

  const [fileDrawerMode, setFileDrawerMode] = useState<
    FileDrawerMode | undefined
  >();

  const [drawerDisabledMap, setDrawerDisabledMap] = useState<
    DrawerDisabledMap | undefined
  >();

  const [selectedTemplate, setSelectedTemplate] = useState<Template>();

  const [uploadForm] = useForm<TemplateForm>();
  const [isFormDirty, setIsFormDirty] = useState(false);

  const [files, setFiles] = useState<Template["files"]>({});
  const [status, setStatus] = useState<Template["status"]>(
    TemplateStatus.UNPUBLISHED,
  );

  const [open, setOpen] = useState<boolean>(false);
  const [componentsTabsFilter, setComponentsTabsFilter] =
    useState<string>("all");

  const hasFiles = useMemo(
    () => !!files && Object.values(files).length > 0,
    [files],
  );

  const setError = (lang: Language) => {
    setFiles(prev => ({
      ...prev,
      [lang]: !prev[lang]
        ? prev[lang]
        : {
            ...prev[lang]!,
            status: "error",
          },
    }));
  };

  const dataEn = useFileData({
    file: files?.en,
    onError: () => setError("en"),
  });
  const dataEs = useFileData({
    file: files?.es,
    onError: () => setError("es"),
  });

  const setSelectedTemplateById = useCallback(
    (templateId: string) => {
      setSelectedTemplate(
        templates?.find(template => template.id === templateId) ??
          raise(`Template ${templateId} not found`),
      );
    },
    [templates],
  );

  const memoizedContextValue: ContextType = useMemo(
    () => ({
      templates,
      isTemplateLoading,
      sortableKeys,
      uploadForm,
      files,
      setFiles,
      hasFiles,
      selectedTemplate,
      setSelectedTemplate,
      fileDrawerMode,
      setFileDrawerMode,
      data: { en: dataEn, es: dataEs },
      availableLocations,
      status,
      setStatus,
      isFormDirty,
      setIsFormDirty,
      setSelectedTemplateById,
      drawerDisabledMap,
      setDrawerDisabledMap,
      setOpen,
      open,
      componentsTabsFilter,
      setComponentsTabsFilter,
      activeLanguageTab,
      setActiveLanguageTab,
    }),
    [
      templates,
      isTemplateLoading,
      sortableKeys,
      uploadForm,
      files,
      setFiles,
      hasFiles,
      selectedTemplate,
      setSelectedTemplate,
      fileDrawerMode,
      setFileDrawerMode,
      dataEn,
      dataEs,
      availableLocations,
      status,
      setStatus,
      isFormDirty,
      setIsFormDirty,
      setSelectedTemplateById,
      drawerDisabledMap,
      setDrawerDisabledMap,
      setOpen,
      open,
      componentsTabsFilter,
      setComponentsTabsFilter,
      activeLanguageTab,
      setActiveLanguageTab,
    ],
  );

  return (
    <Context.Provider value={memoizedContextValue}>{children}</Context.Provider>
  );
};

export const DataProvider = ({ children }: { children: ReactNode }) => {
  return <DataContext>{children}</DataContext>;
};
