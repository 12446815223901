import { useQuery } from "react-query";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import API from "services";
import useDeepEffect from "../useDeepEffect";
import { fetchOrderStateSuccess } from "redux/assetBuilder/assetBuilder.slice";

const fetchOrderState = (id?: string) => async () => {
  if (!id) return;

  const { result: savedOrder, error } =
    await API.services.assetBuilder.fetchOrderState(id);
  if (error) {
    throw Error(error.message);
  }

  return savedOrder;
};

export const useFetchOrderState = (id?: string) => {
  const dispatch = useAppDispatch();

  const result = useQuery(["order-state", id], fetchOrderState(id));

  useDeepEffect(() => {
    const { data: savedOrder } = result;

    if (!savedOrder) return;

    dispatch(
      fetchOrderStateSuccess({
        savedOrder: {
          ...savedOrder,
          selectedOffers:
            savedOrder.selectedOffers ?? [],
        },
      }),
    );
  }, [result.data]);

  return result.data;
};
