import {
  TLayerAction,
  TLayerOrderAction,
} from "screens/designStudio/editor.hooks/useLayers";
import useDeepEffect from "shared/hooks/useDeepEffect";
import { fabric } from "fabric";
import { message } from "antd";
import { IExtendedFabricObject } from "shared/types/designStudio";
import { TLayerItem } from "screens/designStudio/editor.hooks/useLayers";

export default (args: {
  canvas?: fabric.Canvas;
  layerAction?: TLayerAction;
  clearAllBoundingRect: (canvas: fabric.Canvas) => void;
  highlightObject: (target?: fabric.Object) => void;
  onComplete: (layerAction: TLayerAction) => void;
}) => {
  const { canvas, layerAction, clearAllBoundingRect, highlightObject } = args;

  useDeepEffect(() => {
    if (!canvas || !layerAction) return;

    const {
      action: { type, data },
      layer: { original } = { original: null },
    } = layerAction;
    const target = canvas
      ?.getObjects()
      .find(obj => obj.name === original?.name);

    switch (type) {
      case "toggle":
        if (!target) {
          message.error(
            "The selected object cannot be found. This is most likely system error.",
          );
          return;
        }

        target.set({
          visible: data as boolean,
        });
        canvas.renderAll();

        break;

      case "lock":
        target?.set({
          selectable: !!data,
        });

        canvas.discardActiveObject(); // NOTE: We must de-select the active object otherwiwse, it will be movable while being selected

        canvas.renderAll();
        break;

      case "delete":
        if (!target) {
          message.error(
            "The selected object cannot be found. This is most likely system error.",
          );
          return;
        }
        canvas.remove(target);

        break;

      case "re-order":
        const layerOrderAction = data as TLayerOrderAction;

        let {
          from: { index: fromIndex },
          to: { index: toIndex },
        } = layerOrderAction;

        // The fromIndex and toIndex have to be adjusted because of the below objects are not shown in the layer section
        //  - canvas_area
        //  - grid

        let additionalIndexCount = 1; // starts with 1 because canvas_area will always be in the canvas.
        const isGridShown = canvas
          .getObjects()
          .find(
            obj =>
              (obj as unknown as IExtendedFabricObject).customType === "grid",
          );
        if (isGridShown) additionalIndexCount += 1;

        fromIndex += additionalIndexCount;
        toIndex += additionalIndexCount;

        const objects = canvas.getObjects();
        const fromObject = objects[fromIndex];

        canvas.moveTo(fromObject, toIndex);
        canvas.renderAll();

        break;

      case "selected":
        canvas.discardActiveObject();

        const layers = data as TLayerItem[];
        if (layers.length === 0) return;

        const isMultiple = layers.length > 1;
        if (!isMultiple) {
          const object = canvas
            .getObjects()
            .find(obj => obj.name === layers[0].id);
          if (!object) return;

          canvas.setActiveObject(object);
        } else {
          const ids = layers.map(layer => layer.id);
          const objects = canvas
            .getObjects()
            .filter(obj => ids.includes(obj.name || ""));

          const activeSelection = new fabric.ActiveSelection(objects, {
            canvas,
          });
          canvas.setActiveObject(activeSelection);
        }
        canvas.requestRenderAll();

        break;

      case "mouseEnter":
        clearAllBoundingRect(canvas);
        highlightObject(target);
        break;
      case "mouseLeave":
        clearAllBoundingRect(canvas);

        break;

      case "rename":
        if (!target) break;

        const { customData } = target as IExtendedFabricObject;
        (target as IExtendedFabricObject).set({
          customData: {
            ...(customData || {}),
            layerName: (data as string) || target.type || "Unknown",
          },
        });
        break;
    }

    args.onComplete(layerAction);
  }, [layerAction]);
};
