import { Card, DatePicker, Input, Select } from "antd";
import useOutsideClick from "shared/hooks/useOutsideClick";
import {
  DatePreviousInterval,
  DateValueSelection,
} from "shared/types/dashboardManagement";

type DateValueSelectorProps = {
  dateValueSelection?: DateValueSelection;
  updateValue: (value: DateValueSelection) => void;
  hide: () => void;
};

export const DateValueSelector = ({
  dateValueSelection,
  updateValue,
  hide,
}: DateValueSelectorProps) => {
  const targetRef = useOutsideClick(hide);

  const { RangePicker } = DatePicker;
  const rangeConfig = {
    rules: [
      {
        type: "array" as const,
        required: false,
      },
    ],
  };

  return (
    <span ref={targetRef}>
      <Card
        onClick={e => e.stopPropagation()}
        style={{
          width:
            !dateValueSelection ||
            dateValueSelection.datePeriod === "isPrevious"
              ? 300
              : 450,
          float: "right",
          marginTop: 12,
        }}
      >
        <Input.Group compact>
          <Select
            style={{ width: 150 }}
            defaultValue={dateValueSelection?.datePeriod ?? "isPrevious"}
            onChange={val => updateValue({ datePeriod: val })}
          >
            <Select.Option value="isPrevious">is previous</Select.Option>
            <Select.Option value="isInRange">is in range</Select.Option>
          </Select>
          {(!dateValueSelection ||
            dateValueSelection?.datePeriod === "isPrevious") && (
            <Select
              style={{ width: 100 }}
              defaultValue={dateValueSelection?.previousInterval}
              onChange={val =>
                updateValue({
                  previousInterval: val as DatePreviousInterval,
                })
              }
            >
              <Select.Option value="day">day</Select.Option>
              <Select.Option value="week">week</Select.Option>
              <Select.Option value="month">month</Select.Option>
              <Select.Option value="quarter">quarter</Select.Option>
              <Select.Option value="year">year</Select.Option>
            </Select>
          )}
          {dateValueSelection?.datePeriod === "isInRange" && (
            <RangePicker
              defaultValue={
                dateValueSelection.dateRange
                  ? [
                      dateValueSelection.dateRange[0],
                      dateValueSelection.dateRange[1],
                    ]
                  : undefined
              }
              onChange={val =>
                updateValue({ dateRange: val as moment.Moment[] })
              }
              {...rangeConfig}
            />
          )}
        </Input.Group>
      </Card>
    </span>
  );
};
