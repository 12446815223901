import { Space, Tag } from "antd";

type Props = {
  title: string;
  count: string | number;
};

export const MediaLogoSectionHeader = ({ title, count }: Props) => {
  return (
    <Space align="center">
      <div>{title}</div>
      <Tag color="blue">{count}</Tag>
    </Space>
  );
};
