import { useEffect } from "react";
import { AdReviewTableEntry } from "shared/types/campaignManagement";
import QCStatusButtons from "./QCStatusButtons";

interface IQCEnhancementModeProps {
  selectedEntry: AdReviewTableEntry;
  selectedEntryIds?: string[];
  qcSize: number;
  isQCNextAdEnabled: boolean;
  setIsNextButtonDisabled: (enabled: boolean) => void;
  skip: () => void;
}

const QCEnhancementMode = ({
  selectedEntry,
  selectedEntryIds,
  qcSize,
  isQCNextAdEnabled,
  setIsNextButtonDisabled,
  skip,
}: IQCEnhancementModeProps) => {
  useEffect(() => {
    if (qcSize === 1 && selectedEntryIds?.[0] === selectedEntry?.id) {
      setIsNextButtonDisabled(true);
    }
  }, [qcSize, selectedEntryIds, selectedEntry?.id, setIsNextButtonDisabled]);

  return (
    <QCStatusButtons
      record={selectedEntry}
      skip={isQCNextAdEnabled && qcSize >= 1 ? skip : undefined}
    />
  );
};

export default QCEnhancementMode;
