import { convertFromRaw, convertToRaw, EditorState, genKey } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { RichText } from "screens/adLibrary/facebookUtils/types";
import {
  ElementType,
  InstantExperienceElementStyle,
} from "shared/types/adLibrary";

/** Used for Facebook canvas style prop in photo and video elements */
type LayoutOptionText =
  | "Width"
  | "Width (Linkable)"
  | "Width (Tap to Expand)"
  | "Height (Tilt to Pan)";

export function getLayoutOptionText(
  elementType: ElementType,
  style?: InstantExperienceElementStyle,
): LayoutOptionText {
  if (style === InstantExperienceElementStyle.FIT_TO_WIDTH || !style) {
    return elementType === "PHOTO" ? "Width (Linkable)" : "Width";
  }
  if (style === InstantExperienceElementStyle.FIT_TO_WIDTH_EXPANDABLE) {
    return "Width (Tap to Expand)";
  }
  return "Height (Tilt to Pan)";
}

export function getEditorState(facebookRichText?: RichText): EditorState {
  return facebookRichText
    ? EditorState.createWithContent(
        convertFromRaw({
          blocks: [
            {
              text: facebookRichText.plain_text ?? "",
              type: facebookRichText.block_type.toLowerCase(),
              inlineStyleRanges: facebookRichText.inline_styles,
              key: genKey(),
              depth: 0,
              entityRanges: [],
            },
          ],
          entityMap: {},
        }),
      )
    : EditorState.createEmpty();
}

export function getMarkupFromRichText(facebookRichText?: RichText) {
  const editorState = getEditorState(facebookRichText);
  const rawContentState = convertToRaw(editorState.getCurrentContent());

  const markup = draftToHtml(rawContentState);
  return markup;
}
