import { Collapse } from "antd";
import { useCallback, useMemo } from "react";
import { ISavedOrderState, OfferData } from "shared/types/assetBuilder";

import styles from "./OfferTypeCollapse.module.scss";
import Row, {
  Handlers as RowHandlers,
  Props as RowProps,
  TOnRepeatableRowUpdateArgs,
} from "./offerTypeCollapse/Row";
import { OfferType } from "shared/types/shared";
import { Props as CellProps } from "./offerTypeCollapse/row/Cell";
import { Handlers as CollapsableHeaderHandlers } from "./CollapsableHeader";
import { getHeaderSchema, getRowSchema } from "./OfferTypeCollapse.schema";
import { TOfferCollapseData } from "screens/assetBuilder/offers/SelectV2";
import API from "services";
import { getRepeatableFieldKeys } from "./OfferTypeCollapse.utils";
import { KeyValues } from "services/assetBuilder";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import actions from "redux/rootActions";
import { OfferEditFieldObject } from "shared/types/assetBuilder";
import { TOfferListSection } from "screens/assetBuilder/offers/select.hooks/useOfferList";
import { TOfferListData } from "screens/assetBuilder/PaymentEngineModal";
import { isAuto } from "utils/helpers";

export type TCollapsableTypes = "vehicleInfo" | OfferType;

export type TCellPropsArray = Array<CellProps>;
export type TCollapsableHeader = {
  [key in TCollapsableTypes]?: TCellPropsArray;
};
export type TCollapsableRow = {
  type: RowProps["type"];
  repeatableIndex?: number;
  repeatableLength?: number;
  row: TCellPropsArray;
};
export type TCollapsableRows = {
  [key in TCollapsableTypes]?: TCollapsableRow[];
};

export interface Props {
  offer: Partial<OfferData>;
  offerListData?: TOfferListData;
  isEditDisabled?: boolean;
  selectedOfferTypes: OfferType[];
  editedPairs?: TOfferCollapseData["editedPairs"];
  sectionKey: TOfferListSection;
  revertToOriginalSession?: boolean;
  savedOrder: ISavedOrderState;
  filterOfferTypes?: OfferType[];
}

interface Handlers extends CollapsableHeaderHandlers {
  onDataUpdate?: () => void;
  onSessionUpdate?: RowHandlers["onSessionUpdate"];
  togglePaymentEngineModal?: (
    initialSelectedRow: OfferEditFieldObject | null,
  ) => void;
}

const OfferTypeCollapse = (props: Props & Handlers) => {
  const dispatch = useAppDispatch();

  const fieldKey2D = useMemo<{
    headers: TCollapsableHeader;
    rows: TCollapsableRows;
  }>(() => {
    return {
      headers: {
        vehicleInfo: getHeaderSchema("vehicleInfo", props.offer),
        Lease: getHeaderSchema(OfferType.Lease, props.offer),
        [OfferType.ZeroDownLease]: getHeaderSchema(
          OfferType.ZeroDownLease,
          props.offer,
        ),
        Finance: getHeaderSchema(OfferType.Finance, props.offer),
        [OfferType.Purchase]: getHeaderSchema(OfferType.Purchase, props.offer),
        APR: getHeaderSchema(OfferType.APR, props.offer),
      },
      rows: {
        vehicleInfo: getRowSchema("vehicleInfo", props.offer),
        Lease: getRowSchema(OfferType.Lease, props.offer),
        [OfferType.ZeroDownLease]: getRowSchema(
          OfferType.ZeroDownLease,
          props.offer,
        ),
        Finance: getRowSchema(OfferType.Finance, props.offer),
        [OfferType.Purchase]: getRowSchema(OfferType.Purchase, props.offer),
        APR: getRowSchema(OfferType.APR, props.offer),
      },
    };
  }, [props.offer]);

  const genericFieldKey2D = useMemo<{
    headers: TCollapsableHeader;
    rows: TCollapsableRows;
  }>(() => {
    return {
      headers: {
        Misc: getHeaderSchema(OfferType.Misc, props.offer),
      },
      rows: {
        Misc: getRowSchema(OfferType.Misc, props.offer),
      },
    };
  }, [props.offer]);

  const onRepeatableRowUpdate = useCallback(
    async (args: TOnRepeatableRowUpdateArgs) => {
      if (!props.offer.vin) return;

      // Here, some explanines on this offset.
      // If "add", we need to add +2 to the index because we are adding fields for the next.
      //  Ex) if current index is 1, then current field names are leaseRebate2, leaseRebate2Name...
      //      But here, we need to add the next fields, leaseRebate3, leaseRebate3Name... So +2.
      // But if "delete", we need to delete the current fields, so +1.
      const offset = args.operation === "add" ? 2 : 1;

      const keys = getRepeatableFieldKeys(
        args.keys,
        args.repeatableIndex ?? 0,
        offset,
      );

      if (!keys) return;

      await API.services.assetBuilder.updateOfferFields({
        vin: props.offer.vin,
        keyValues: keys.reduce((acc, key) => {
          acc[key as keyof OfferData] = {
            value: "",
            shouldRemove: args.operation === "delete",
          };

          return acc;
        }, {} as KeyValues),
        sectionKey: props.sectionKey,
        orderId: args.orderId,
      });

      return;
    },
    [props.offer.vin, props.sectionKey],
  );

  const onOfferTypeUpdate = useCallback(
    (offerType: OfferType, shouldAdd: boolean) => {
      props.onOfferSelection?.(
        shouldAdd
          ? [...new Set([...props.selectedOfferTypes, offerType])]
          : props.selectedOfferTypes.filter(t => t !== offerType),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onOfferSelection],
  );

  const setEditOfferData = useCallback(() => {
    // NOTE: This is setting necessary offer data in redux for evox popover to be working properly.
    dispatch(
      actions.assetBuilder.setEditOfferData({
        rawOfferData: props.offer as OfferData,
        editedKeys: {},
        operation: "UPDATE",
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headers = fieldKey2D.headers;
  const genericHeaders = genericFieldKey2D.headers;
  const headerKeys = Object.keys(headers) as Array<TCollapsableTypes>;
  const genericHeaderKeys = Object.keys(
    genericHeaders,
  ) as Array<TCollapsableTypes>;
  const activeKey = [`offer-type-collapse-${OfferType.Misc}`];

  return (
    <div className={styles.OfferFieldList}>
      <Collapse
        className={styles.Collapse}
        {...(!isAuto && { activeKey: activeKey })}
      >
        {isAuto &&
          headerKeys.map(collapsableHeaderType => {
            const row = headers[collapsableHeaderType]!.map(cellProps => ({
              ...cellProps,
            }));

            const isVehicleInfo = collapsableHeaderType === "vehicleInfo";
            const hasFilterOfferTypes =
              props.filterOfferTypes?.length ?? -1 > 0;
            const containsHeaderType = props.filterOfferTypes?.includes(
              collapsableHeaderType as OfferType,
            );

            if (!isVehicleInfo && hasFilterOfferTypes && !containsHeaderType)
              return;

            return (
              <Collapse.Panel
                key={`offer-type-collapse-${collapsableHeaderType}`}
                className={styles.CollapsePanel}
                header={
                  <Row
                    type="header"
                    vin={props.offer.vin}
                    offerType={collapsableHeaderType as TCollapsableTypes}
                    offerListData={props.offerListData}
                    row={row}
                    isSelected={props.selectedOfferTypes.includes(
                      collapsableHeaderType as OfferType,
                    )}
                    isEditDisabled={props.isEditDisabled}
                    togglePaymentEngineModal={props.togglePaymentEngineModal}
                    onOfferTypeUpdate={onOfferTypeUpdate}
                    editedPairs={props.editedPairs}
                    onSessionUpdate={props.onSessionUpdate}
                    setEditOfferData={setEditOfferData}
                    sectionKey={props.sectionKey}
                    revertToOriginalSession={props.revertToOriginalSession}
                    savedOrder={props.savedOrder}
                  />
                }
              >
                {collapsableHeaderType in fieldKey2D.rows && (
                  <div className={styles.RowWrapper}>
                    {fieldKey2D.rows[collapsableHeaderType]?.map((obj, idx) => {
                      return (
                        <Row
                          key={`content-row-${idx}`}
                          offerType={collapsableHeaderType}
                          offerListData={props.offerListData}
                          {...obj}
                          isEditDisabled={props.isEditDisabled}
                          vin={props.offer.vin}
                          editedPairs={props.editedPairs}
                          onSessionUpdate={props.onSessionUpdate}
                          onRepeatableRowUpdate={onRepeatableRowUpdate}
                          sectionKey={props.sectionKey}
                          savedOrder={props.savedOrder}
                        />
                      );
                    })}
                  </div>
                )}
              </Collapse.Panel>
            );
          })}
        {!isAuto &&
          genericHeaderKeys.map(collapsableHeaderType => {
            return (
              <Collapse.Panel
                key={`offer-type-collapse-${collapsableHeaderType}`}
                className={styles.CollapsePanel}
                header={
                  <div className={styles.CollapsePanelHeader}>All Fields</div>
                }
              >
                {
                  <div className={styles.RowWrapper}>
                    {genericFieldKey2D.rows[collapsableHeaderType]?.map(
                      (obj, idx) => {
                        return (
                          <Row
                            key={`content-row-misc-${idx}`}
                            offerType={collapsableHeaderType}
                            offerListData={props.offerListData}
                            {...obj}
                            isEditDisabled={props.isEditDisabled}
                            vin={props.offer.vin}
                            editedPairs={props.editedPairs}
                            onSessionUpdate={props.onSessionUpdate}
                            onRepeatableRowUpdate={onRepeatableRowUpdate}
                            sectionKey={props.sectionKey}
                            savedOrder={props.savedOrder}
                          />
                        );
                      },
                    )}
                  </div>
                }
              </Collapse.Panel>
            );
          })}
      </Collapse>
    </div>
  );
};

export default OfferTypeCollapse;
