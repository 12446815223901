import { FC } from "react";
import { NavLink } from "react-router-dom";

interface IProps {
  to: string;
  children: string;
  Icon?: FC;
}

const MenuLink = ({ to, children, Icon }: IProps) => {
  return (
    <NavLink to={to}>
      {Icon && <Icon />}
      {children}
    </NavLink>
  );
};

export default MenuLink;
