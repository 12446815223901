import { Tooltip } from "antd";
import { ReactNode } from "react";

type OmittedTagsProps = {
  values: ReactNode[];
};
export const OmittedTags = ({ values }: OmittedTagsProps) => {
  const filteredValues = values.filter(Boolean);

  return (
    <Tooltip title={filteredValues.join(", ")}>
      +{filteredValues.length} more
    </Tooltip>
  );
};
