import { message } from "antd";

import _flatten from "lodash/flatten";
import _uniqBy from "lodash/uniqBy";
import _sortBy from "lodash/sortBy";

import {
  IAdLoadParameters,
  FacebookAdLoadDictionary,
  IAdLoadParametersRuleset,
  DelimterObject,
  FacebookAdPart,
  TaxonomiesObject,
  IAd,
  IFacebookAdToLoad,
  RulesetOptionsDictionary,
  AdLoadRuleConflictDict,
  AdLoadRuleConflictIdDict,
  AdLoadRuleQualifier,
  FacebookDataIdsByAd,
  IAdLoadFacebookDataDictionary,
  AdLoadAdStatusEnum,
} from "shared/types/adLibrary";
import {
  IFacebookCampaign,
  IFacebookAdset,
  IFacebookAd,
  IFacebookAccount,
} from "../facebookUtils/types";
import { mapValues, toPairs } from "lodash";

export const returnUpdatedRuleOptions = (args: {
  name: string;
  adPart: FacebookAdPart;
  delimiters: DelimterObject;
  taxonomies: TaxonomiesObject;
  ruleOptions: RulesetOptionsDictionary;
}) => {
  const { name, delimiters, adPart, taxonomies, ruleOptions } = args;
  const splitName = name.split(delimiters[adPart]);
  const leftoverSplitName = splitName.slice(taxonomies[adPart].length - 1);

  // if the name split is less than the taxonomy choices, then
  // the name will not work with filling in the rule options
  if (splitName.length < taxonomies[adPart].length) {
    return ruleOptions;
  }

  for (let i = 0; i < taxonomies[adPart].length; i += 1) {
    const parameter = taxonomies[adPart][i];

    if (!ruleOptions[adPart].parameter.includes(parameter)) {
      ruleOptions[adPart].parameter.push(parameter);
    }

    const valueToCheck =
      i === taxonomies[adPart].length - 1 && leftoverSplitName.length > 1
        ? leftoverSplitName.join(delimiters[adPart])
        : splitName[i];

    if (!ruleOptions[adPart].values[parameter]) {
      ruleOptions[adPart].values[parameter] = [];
    }

    if (ruleOptions[adPart].values[parameter].includes(valueToCheck)) continue;

    ruleOptions[adPart].values[parameter].push(valueToCheck);
  }

  return ruleOptions;
};

const returnCampaignDoesHasAds = (campaign: IFacebookCampaign) =>
  (
    campaign.adsets?.data?.filter(adset => (adset.ads?.data.length ?? 0) > 0) ??
    []
  ).length > 0;

const returnMatchingFacebookObjects = (args: {
  data: IFacebookCampaign[] | IFacebookAdset[] | IFacebookAd[];
  adPart: FacebookAdPart;
  delimiters: DelimterObject;
  taxonomies: TaxonomiesObject;
  ruleset: IAdLoadParametersRuleset;
}) => {
  const { data, delimiters, adPart, taxonomies, ruleset } = args;
  const rules = ruleset[adPart];
  if (!rules) {
    return [];
  }

  let matchingObjects = data;

  for (const rule of rules) {
    const { parameter, qualifier, values } = rule;

    if (!parameter || !qualifier || values.length < 1) {
      continue;
    }

    let newMatchingCampaigns: {
      name?: string | undefined;
    }[] = [];

    if (["Equals", "Contains"].includes(rule.qualifier)) {
      newMatchingCampaigns = (
        matchingObjects as Array<{
          name?: string;
        }>
      ).filter(obj =>
        returnIsValueMatchForRule({
          obj,
          adPart,
          qualifier: qualifier as AdLoadRuleQualifier,
          delimiters,
          taxonomies,
          parameter,
          values,
        }),
      );
    } else {
      /*
       * AV2-3216: Fix for value filter when using Doesn't Equal and Doesn't Contain rules
       * Values used have to be filter out matches sequentially
       */
      for (const value of values) {
        newMatchingCampaigns = (
          newMatchingCampaigns.length
            ? newMatchingCampaigns
            : (matchingObjects as Array<{
                name?: string;
              }>)
        ).filter(obj =>
          returnIsValueMatchForRule({
            obj,
            adPart,
            qualifier: qualifier as AdLoadRuleQualifier,
            delimiters,
            taxonomies,
            parameter,
            values: [value],
          }),
        );
      }
    }

    matchingObjects = newMatchingCampaigns;
  }

  return matchingObjects;
};

const returnIsValueMatchForRule = (args: {
  obj: {
    name?: string | undefined;
  };
  adPart: FacebookAdPart;
  delimiters: DelimterObject;
  taxonomies: TaxonomiesObject;
  qualifier: AdLoadRuleQualifier;
  parameter: string;
  values: string[];
}) => {
  const { obj, delimiters, adPart, taxonomies, qualifier, parameter, values } =
    args;
  const splitName = obj.name?.split(delimiters[adPart]) || [];
  const leftoverSplitName = splitName.slice(taxonomies[adPart].length - 1);

  if (splitName.length < taxonomies[adPart].length) {
    return false;
  }

  const parameterIndex = taxonomies[adPart].indexOf(parameter);

  const nameValue =
    parameterIndex === taxonomies[adPart].length - 1 &&
    leftoverSplitName.length > 1
      ? leftoverSplitName.join(delimiters[adPart])
      : splitName[parameterIndex];

  if (parameterIndex === -1 || !nameValue) {
    return false;
  }

  let isMatch = true;

  // Possible TO DO: make values case insensitive
  switch (qualifier) {
    case "Equals":
      isMatch = values.includes(nameValue);
      break;
    case "Doesn't Equal":
      isMatch = !values.includes(nameValue);
      break;
    case "Contains": {
      isMatch = !!values.filter(value => nameValue.split(value).length > 1)
        .length;
      break;
    }
    case "Doesn't Contain": {
      isMatch = !!values.filter(value => nameValue.split(value).length === 1)
        .length;
      break;
    }
    default: {
      break;
    }
  }

  return isMatch;
};

export const returnAdNameToDisplay = (ad: IAd, index: number) => {
  const { inputParameters, category, platform, type } = ad;
  const { name, oems } = inputParameters || {};

  const displayTitle =
    name ||
    `${category ? `${category} ` : ""}${platform} ${
      (oems?.length || 0) > 0 ? `(${oems?.join(",")}) ` : " "
    }${type}${index === -1 ? "" : ` ${index + 1}`}`;

  return displayTitle;
};

export const returnFacebookAdLoadDict = (args: {
  adLoadParameters: Partial<IAdLoadParameters> | null;
  campaigns: IFacebookCampaign[];
  selectedAccounts?: IFacebookAccount[] | null;
}): FacebookAdLoadDictionary => {
  const { adLoadParameters, campaigns, selectedAccounts } = args;
  const { rulesets, taxonomies, delimiters } = adLoadParameters || {};
  const adsToLoadObject: FacebookAdLoadDictionary = {};

  try {
    const campaignsHaveNoAds =
      campaigns.filter(campaign => returnCampaignDoesHasAds(campaign)).filter
        .length === 0;

    if (!rulesets || campaignsHaveNoAds || !taxonomies || !delimiters) {
      return adsToLoadObject;
    }

    for (const adShellId in rulesets) {
      if (!rulesets[adShellId]) {
        continue;
      }

      adsToLoadObject[adShellId] = { ad: [], adset: [], campaign: [] };

      const ruleset = rulesets[adShellId] as IAdLoadParametersRuleset;

      let matchingCampaigns = returnMatchingFacebookObjects({
        adPart: "campaign",
        data: campaigns,
        delimiters,
        taxonomies,
        ruleset,
      }) as IFacebookCampaign[];

      const adsets = _flatten(
        matchingCampaigns
          .map(campaign => campaign.adsets?.data)
          .filter(adsets => adsets),
      ) as IFacebookAdset[];

      let matchingAdsets = returnMatchingFacebookObjects({
        adPart: "adset",
        data: adsets,
        delimiters,
        taxonomies,
        ruleset,
      }) as IFacebookAdset[];

      let validCampaignDic = Object.fromEntries(
        matchingAdsets.map(adSet => [adSet.campaign_id, adSet]),
      );

      matchingCampaigns = matchingCampaigns.filter(
        campaign => campaign.id && validCampaignDic[campaign.id],
      );

      const ads = _flatten(
        matchingAdsets.map(adset => adset.ads?.data).filter(ads => ads),
      ) as IFacebookAd[];

      const matchingAds = returnMatchingFacebookObjects({
        adPart: "ad",
        data: ads,
        delimiters,
        taxonomies,
        ruleset,
      }) as IFacebookAd[];

      const validAdSetDic = Object.fromEntries(
        matchingAds.map(ad => [ad.adset_id, ad]),
      );

      matchingAdsets = matchingAdsets.filter(
        adset => adset.id && validAdSetDic[adset.id],
      );

      validCampaignDic = Object.fromEntries(
        matchingAdsets.map(adSet => [adSet.campaign_id, adSet]),
      );

      matchingCampaigns = matchingCampaigns.filter(
        campaign => campaign.id && validCampaignDic[campaign.id],
      );

      const campaignDic = Object.fromEntries(
        matchingCampaigns.map(campaign => [campaign.id, campaign]),
      );

      adsToLoadObject[adShellId].ad = matchingAds.map(ad => {
        const { campaign_id: campaignId = "", adset_id: adsetId = "" } = ad;
        const adLoadAd = buildFacebookAdToLoad(ad);

        if (!campaignId || !adsetId) {
          adLoadAd.adLoadStatus.status = "error";
          adLoadAd.adLoadStatus.errorMessage =
            "Referential campaign data could not be found.";
        }

        const foundCampaign = campaignDic[campaignId];

        if (foundCampaign?.account_id) {
          adLoadAd.adAccount = {
            id: foundCampaign.account_id,
            name:
              selectedAccounts?.find(
                account => account.account_id === foundCampaign.account_id,
              )?.name || "N/A",
          };
        }

        return adLoadAd;
      });

      adsToLoadObject[adShellId].adset = matchingAdsets.map(adset => ({
        ...adset,
        ads: undefined,
      }));

      adsToLoadObject[adShellId].campaign = matchingCampaigns.map(campaign => ({
        ...campaign,
        adsets: undefined,
      }));
    }
  } catch (error) {
    message.error("Something went wrong while preparing ads to modify.");
  }

  return adsToLoadObject;
};

const buildFacebookAdToLoad = (ad: IFacebookAd): IFacebookAdToLoad => {
  return {
    facebookAd: ad,
    title: ad.name ?? "",
    description: "Ready to load ad",
    adLoadStatus: {
      status: AdLoadAdStatusEnum.IDLE,
    },
  };
};

const getFacebookDataDictionaries = (
  campaigns: IFacebookCampaign[] | undefined,
) => {
  const filterUndefined = (obj: any) => obj != undefined;

  const campaignDic = Object.fromEntries(
    campaigns?.map(campaign => [campaign.id, campaign]) ?? [],
  ) as Record<string, IFacebookCampaign>;

  const adSets =
    (campaigns
      ?.flatMap(campaign => campaign.adsets?.data)
      .filter(filterUndefined) as IFacebookAdset[]) ?? [];

  const adSetDic = Object.fromEntries(
    adSets?.map(adSet => [adSet.id, adSet]) ?? [],
  ) as Record<string, IFacebookAdset>;

  const ads =
    (adSets
      ?.flatMap(adSet => adSet.ads?.data)
      .filter(filterUndefined) as IFacebookAd[]) ?? [];

  const adDic = Object.fromEntries(ads?.map(ad => [ad.id, ad]) ?? []) as Record<
    string,
    IFacebookAd
  >;

  return { campaignDic, adSetDic, adDic };
};

export const getFacebookDataByIds = (
  facebookDataIdsByAd: FacebookDataIdsByAd,
  campaigns?: IFacebookCampaign[],
) => {
  const { campaignDic, adSetDic, adDic } =
    getFacebookDataDictionaries(campaigns);

  const facebookAdLoadDic = mapValues(
    facebookDataIdsByAd,
    (facebookDataIds): IAdLoadFacebookDataDictionary => {
      return {
        campaign: facebookDataIds.campaign.map(
          campaignId => campaignDic[campaignId],
        ),
        adset: facebookDataIds.adset.map(adSetId => adSetDic[adSetId]),
        ad: facebookDataIds.ad.map(adId => buildFacebookAdToLoad(adDic[adId])),
      };
    },
  );

  return facebookAdLoadDic;
};

export const getIdsFromFacebookDic = (
  facebookAdLoadDictionary: FacebookAdLoadDictionary,
): FacebookDataIdsByAd => {
  return mapValues(facebookAdLoadDictionary, facebookAdLoadData => {
    return {
      campaign: facebookAdLoadData.campaign.map(
        campaign => campaign.id as string,
      ),
      adset: facebookAdLoadData.adset.map(adset => adset.id as string),
      ad: facebookAdLoadData.ad.map(ad => ad.facebookAd.id as string),
    };
  });
};

export const LOAD_CAPACITY = 500;

export const hasMoreAdsThanCapacity = (
  selectedAds: IAd[] | null,
  facebookDataIdsByAd: FacebookDataIdsByAd | null,
) => {
  const numberOfAdsToLoad =
    selectedAds?.reduce((numberOfAds, selectedAd) => {
      return (
        numberOfAds + (facebookDataIdsByAd?.[selectedAd.id]?.ad.length ?? 0)
      );
    }, 0) ?? 0;

  return numberOfAdsToLoad > LOAD_CAPACITY;
};

export const getIdDictFromConflictDict = (
  adLoadRuleConflictDict: AdLoadRuleConflictDict,
): AdLoadRuleConflictIdDict => {
  const adLoadRuleConflictIdDict: AdLoadRuleConflictIdDict = {};

  for (const adShellId in adLoadRuleConflictDict) {
    adLoadRuleConflictIdDict[adShellId] =
      adLoadRuleConflictDict[adShellId].conflictAdShellIds;
  }

  return adLoadRuleConflictIdDict;
};

export const removeConflictsWithAdShell = (
  adLoadConflicts: AdLoadRuleConflictIdDict,
  adShellId: string,
) => {
  const newAdLoadConflicts: AdLoadRuleConflictIdDict = {
    ...adLoadConflicts,
  };

  for (const id in newAdLoadConflicts) {
    if (id === adShellId) {
      continue;
    }
    newAdLoadConflicts[id] = newAdLoadConflicts[id].filter(
      id => id !== adShellId,
    );
  }

  delete newAdLoadConflicts[adShellId];

  return newAdLoadConflicts;
};

interface IAdLoadAdProgressTracker {
  adLoadings: IFacebookAdToLoad[];
  adSuccesses: IFacebookAdToLoad[];
  adErrors: IFacebookAdToLoad[];
  adsProcessed: IFacebookAdToLoad[];
}

export const returnAdsProcessed = (
  facebookAdsToLoad: IFacebookAdToLoad[],
): IAdLoadAdProgressTracker => {
  const adLoadings = facebookAdsToLoad.filter(
    ad => ad.adLoadStatus.status === "loading",
  );
  const adSuccesses = facebookAdsToLoad.filter(
    ad => ad.adLoadStatus.status === "success",
  );
  const adErrors = facebookAdsToLoad.filter(
    ad => ad.adLoadStatus.status === "error",
  );
  const adsProcessed = _uniqBy(
    [...adSuccesses, ...adErrors],
    ad => ad.facebookAd.id,
  );

  return { adLoadings, adSuccesses, adErrors, adsProcessed };
};

export const initAdLoadParameters: IAdLoadParameters = {
  rulesets: null,
  conflicts: null,
  // Future TO DO: the user should be able to define their own taxonmy
  // where the order of the options will be important
  taxonomies: {
    campaign: [
      "Audience Group",
      "Demographic Segment",
      "Model",
      "KPI",
      "Quarter",
      "IO Code (Free Form/Estimate Code)",
      "Channel",
      "Region/DMA Name/DMA Code",
      "Campaign Name (Free Form)",
    ],
    adset: [
      "Targeting Description",
      "Channel (Campaign Name)",
      "Placement",
      "Model (Campaign Name)",
      "Audience Size",
      "3rd Party Audience Abbreviation",
      "Ad Set Start Date",
      "Ad Set End Date",
    ],
    ad: [
      "Ad Start Date",
      "Ad End Date",
      "Content Description",
      "Ad Type/Placement Type",
      "Video Length",
      "Aspect Ratio",
      "DCM Placement ID",
    ],
  },
  // Future TO DO: implement delimiter choices
  delimiters: {
    campaign: "_",
    adset: "_",
    ad: "_",
  },
};

export const returnAdAccountsWithoutRepeatNames = (
  facebookAccounts: IFacebookAccount[],
) => {
  const sortedAccounts = _sortBy(facebookAccounts, "name");
  const repeatingNames: string[] = [];
  for (let i = 0; i < sortedAccounts.length; i++) {
    if (sortedAccounts[i + 1]?.name !== sortedAccounts[i].name) {
      continue;
    }
    repeatingNames.push(sortedAccounts[i].name);
  }

  const accountsWithoutRepeatNames = facebookAccounts.map(account => {
    const nameIsRepeat = repeatingNames.includes(account.name);
    if (!nameIsRepeat) {
      return account;
    }
    return { ...account, name: `${account.name} (${account.account_id})` };
  });

  return accountsWithoutRepeatNames;
};

export const getConflictingAdIds = (
  adId: string,
  facebookAdIdsOfCurrentAd: string[],
  facebookAdLoadDict: FacebookDataIdsByAd,
) => {
  const facebookAdLoadDictEntries = toPairs(facebookAdLoadDict);

  const facebookAdLoadDictEntriesWithConflicts =
    facebookAdLoadDictEntries.filter(
      ([comparedAdId, comparedFacebookAdLoadDataDict]) => {
        if (adId === comparedAdId) {
          return false;
        }

        return comparedFacebookAdLoadDataDict.ad.some(
          facebookAdIdOfComparedAd =>
            facebookAdIdsOfCurrentAd.includes(facebookAdIdOfComparedAd),
        );
      },
    );

  const conflictAdIds = facebookAdLoadDictEntriesWithConflicts.map(
    ([conflictAdId, _conflictFacebookAdLoadDataDict]) => {
      return conflictAdId;
    },
  );

  return conflictAdIds;
};

export const isMixedKey = "isMixed";
