import { uniq } from "lodash";
import { IAdToLoadData, IInstantExperience } from "shared/types/adLibrary";
import produce from "immer";
import { DataNode } from "antd/lib/tree";
import { getInstantExperiencesDeep } from "utils/helpers.instantExperiences";

interface BasicFields {
  id: string;
  name: string;
}
interface AccountData extends BasicFields {
  campaignIds: string[];
}
interface CampaignData extends BasicFields {
  adSetIds: string[];
  accountId: string;
}
interface AdSetData extends BasicFields {
  adIds: string[];
  campaignId: string;
}
interface AdData extends BasicFields {
  instantExperienceIds: string[];
}

type TreeNodeData = {
  accounts: {
    [accountId: string]: AccountData;
  };
  campaigns: {
    [campaignId: string]: CampaignData;
  };
  adSets: {
    [adSetId: string]: AdSetData;
  };
  ads: {
    [adId: string]: AdData;
  };
  instantExperiences: {
    [instantExperienceId: string]: BasicFields;
  };
};

export function getNormalizedAdData(
  adsToLoad: IAdToLoadData[],
  instantExperiences: IInstantExperience[],
): TreeNodeData {
  return adsToLoad.reduce<TreeNodeData>(
    (prevTreeNodeData, currentAd) => {
      const { account, campaign, adset, ad } = currentAd;
      const adInstantExperiences = getInstantExperiencesDeep(
        instantExperiences,
        ad.visuals.destination?.instantExperienceId,
      );
      const instantExperienceIds = adInstantExperiences.map(ie => ie.id!);

      return produce(prevTreeNodeData, draft => {
        const prevCampaignIds =
          draft.accounts[account.account_id]?.campaignIds ?? [];
        const prevAdSetIds = draft.campaigns[campaign.id!]?.adSetIds ?? [];
        const prevAdIds = draft.adSets[adset.id!]?.adIds ?? [];

        draft.accounts[account.account_id] = {
          id: account.account_id,
          name: account.name,
          campaignIds: uniq(prevCampaignIds.concat(campaign.id!)),
        };

        draft.campaigns[campaign.id!] = {
          id: campaign.id!,
          name: campaign.name!,
          adSetIds: uniq(prevAdSetIds.concat(adset.id!)),
          accountId: account.account_id,
        };

        draft.adSets[adset.id!] = {
          id: adset.id!,
          name: adset.name!,
          adIds: uniq(prevAdIds.concat(ad.id!)),
          campaignId: campaign.id!,
        };

        draft.ads[ad.id] = {
          id: ad.id,
          name: ad.inputParameters.name!,
          instantExperienceIds: instantExperienceIds,
        };

        adInstantExperiences.forEach(ie => {
          draft.instantExperiences[ie.id!] = {
            id: ie.id!,
            name: ie.name!,
          };
        });
      });
    },
    {
      accounts: {},
      campaigns: {},
      adSets: {},
      ads: {},
      instantExperiences: {},
    },
  );
}

export function getAdsTreeData(treeNodeData: TreeNodeData): DataNode[] {
  return Object.values(treeNodeData.accounts).map(account => ({
    title: account.name,
    key: account.id,
    children: getCampaignNodes(account, treeNodeData),
  }));
}

function getCampaignNodes(
  account: AccountData,
  treeNodeData: TreeNodeData,
): DataNode[] {
  return account.campaignIds.map<DataNode>(campaignId => {
    const campaign = treeNodeData.campaigns[campaignId];
    return {
      title: campaign.name,
      key: campaignId,
      children: getAdSetNodes(campaign, treeNodeData),
    };
  });
}

function getAdSetNodes(
  campaign: CampaignData,
  treeNodeData: TreeNodeData,
): DataNode[] | undefined {
  return campaign.adSetIds.map<DataNode>(adSetId => {
    const adSet = treeNodeData.adSets[adSetId];
    return {
      title: adSet.name,
      key: adSetId,
      children: getAdNodes(adSet, treeNodeData),
    };
  });
}

function getAdNodes(
  adSet: AdSetData,
  treeNodeData: TreeNodeData,
): DataNode[] | undefined {
  const adSetId = adSet.id;
  const campaignId = adSet.campaignId;
  return adSet.adIds.flatMap<DataNode>(adId => {
    const ad = treeNodeData.ads[adId];
    return {
      title: ad.name,
      key: getAdShellKey({ campaignId, adSetId, adId }),
      isLeaf: true,
    };
  });
}

export function getAdShellKey({
  campaignId,
  adSetId,
  adId,
}: {
  campaignId: string;
  adSetId: string;
  adId: string;
}) {
  return `${campaignId}_${adSetId}_${adId}`;
}

type GetEverythingAdKeyProps = {
  /** id of the everything ad or everything ad element */
  targetId: string;
  /** id of all the parent elements up to the target one */
  parentIds: string[];
};

export function getEverythingAdKey({
  targetId,
  parentIds,
}: GetEverythingAdKeyProps) {
  return `${parentIds.join("_")}_${targetId}`;
}
