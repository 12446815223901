import { Button, Checkbox, Divider } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import styles from "./Toolbar.module.scss";

export const OPTION_DO_NOT_IMPORT = "Do not import";
export const OPTION_IMPORT_NOT_FORMAT = "Import without formatting";

interface IToolbarFields {
  allowedKeys: string[];
  selectedRowKeys: string[];
}

interface IToolbarHandlers {
  onChangeSelected: (value?: string) => void;
  onSelectRowKeys: (keys: string[]) => void;
}

type ToolbarProps = IToolbarFields & IToolbarHandlers;

const Toolbar = ({
  allowedKeys,
  selectedRowKeys,
  onChangeSelected,
  onSelectRowKeys,
}: ToolbarProps) => {
  const isSelectedAll = selectedRowKeys.length === allowedKeys.length;
  const isCleanButtonDisabled = !Boolean(selectedRowKeys?.length);

  const handleSelectAll = () => {
    onSelectRowKeys(isSelectedAll ? [] : allowedKeys);
  };

  return (
    <>
      <Divider />
      <div className={styles.toolbar}>
        <Checkbox
          key="selectAll"
          checked={isSelectedAll}
          onClick={handleSelectAll}
        >
          Select All
        </Checkbox>
        <div className={styles.toolbarButtons}>
          <Button
            onClick={() => onChangeSelected(OPTION_IMPORT_NOT_FORMAT)}
            disabled={isCleanButtonDisabled}
          >
            {OPTION_IMPORT_NOT_FORMAT}
          </Button>
          <Button
            onClick={() => onChangeSelected(OPTION_DO_NOT_IMPORT)}
            disabled={isCleanButtonDisabled}
          >
            {OPTION_DO_NOT_IMPORT}
          </Button>
          <Button
            onClick={() => onChangeSelected()}
            disabled={isCleanButtonDisabled}
          >
            <DeleteOutlined />
            Clean
          </Button>
        </div>
      </div>
    </>
  );
};

export { Toolbar };
