import { Key, useCallback, useMemo, useState } from "react";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Select, Tooltip } from "antd";
import styles from "./SelectWithSort.module.scss";

interface ISelectWithSortProps {
  extraInfo: { label: string; value: string }[];
  sortingOrder?: string[];
  onSortChange?: (value: Key[]) => void;
  disabled?: boolean;
}

export const SelectWithSort = ({
  extraInfo,
  sortingOrder,
  disabled,
  onSortChange,
}: ISelectWithSortProps) => {
  const [sortBy, direction] = sortingOrder || [];

  const [directionHover, setDirectionHover] = useState(false);

  const onChange = useCallback(
    (value?: string) => {
      if (onSortChange) {
        onSortChange(value ? [value, direction || "ascend"] : []);
      }
    },
    [onSortChange, direction],
  );

  const onDirectionChange = useCallback(() => {
    if (onSortChange) {
      const directionToChange = direction === "ascend" ? "descend" : "ascend";

      onSortChange([sortBy, directionToChange]);
    }
  }, [sortBy, direction, onSortChange]);

  const directionTooltipText = useMemo(
    () => (direction === "ascend" ? "sort descending" : "sort ascending"),
    [direction],
  );

  return (
    <div className={styles.customSelect}>
      <Tooltip title={directionHover && directionTooltipText}>
        <div
          className={`${styles.arrows} ${direction}`}
          onClick={onDirectionChange}
          onMouseEnter={() => setDirectionHover(true)}
          onMouseLeave={() => setDirectionHover(false)}
        >
          <div className={styles.arrowsWrapper}>
            <CaretUpOutlined className={styles.arrowUp} />
            <CaretDownOutlined className={styles.arrowDown} />
          </div>
        </div>
      </Tooltip>
      <Tooltip title={"sort by"}>
        <Select
          data-cy="sort-toolbar-button"
          disabled={disabled}
          className={styles.selectInput}
          options={extraInfo}
          onChange={onChange}
          allowClear={false}
          showArrow={false}
          value={sortBy}
        />
      </Tooltip>
    </div>
  );
};
