// import { fabric } from "fabric";

import { CanvasObject } from "shared/types/assetBuilder";
import { customFabricAttributes } from "shared/types/designStudio";

export const getWidth = (obj: fabric.Object | CanvasObject): number => {
  return (obj.width || 0) * (obj.scaleX || 1);
};

export const getHeight = (obj: fabric.Object | CanvasObject): number => {
  return (obj.height || 0) * (obj.scaleY || 1);
};

export const getCustomAttributes = (obj: any): Record<string, any> => {
  if (!obj) return {};

  return customFabricAttributes.reduce<Record<string, any>>((acc, key) => {
    if (key in obj) {
      acc[key] = obj[key];
    }

    return acc;
  }, {});
};

export const clone = <T extends fabric.Object>(object: T): Promise<T> => {
  return new Promise((resolve, reject) => {
    object.clone((cloned: any) => {
      try {
        resolve(cloned);
      } catch (err) {
        reject();
      }
    }, customFabricAttributes);
  });
};
