import API from "services";

import { getDefaultPropValue } from "./utils";

import {
  ButtonStyle,
  CanvasElementFieldTypes,
  CanvasElementStyle,
  IButtonFields,
  ICanvasElementParams,
  ICanvasFields,
  ICanvasParams,
  ICanvasPhotoParams,
  ICanvasVideoParams,
  IFacebookObject,
  IFooterFields,
  IProxyRequestBody,
  TextAlignment,
} from "./types";
import { IApiResponse } from "shared/types/shared";
import memoizee from "memoizee";
import { pullUntilVideoIsReady } from "./adVideo";

export interface ICreateCanvasResponseData {
  id: string;
}

interface IUpdateCanvasResponseData {
  success: boolean;
}

type CreateMediaResponse = {
  id: string;
};

type GetPageDataOptions = { includeAccessToken?: boolean };

type CreatePageMediaArgs = {
  url: string;
  mediaType: "photo" | "video";
};

export default class Page {
  constructor(readonly pageId: string) {}

  getPageId() {
    return this.pageId;
  }

  private getEndpoint(url: string) {
    return `/${this.pageId}${url}`;
  }

  async getPageData(options?: GetPageDataOptions): Promise<IFacebookObject> {
    const proxyBody: IProxyRequestBody<ICanvasParams> = {
      endpoint: this.getEndpoint(""),
      method: "GET",
      platform: "facebook",
      query: `?fields=id,name,picture${
        options?.includeAccessToken ? ",access_token" : ""
      }`,
    };

    const { result, error } = await this.makeRequest<IFacebookObject>(
      proxyBody,
    );
    if (error || !result) {
      throw Error(error?.message);
    }

    return result;
  }

  async createCanvas(
    params: ICanvasParams,
  ): Promise<ICreateCanvasResponseData> {
    const proxyBody: IProxyRequestBody<ICanvasParams> = {
      data: params,
      method: "POST",
      platform: "facebook",
      facebookPageId: this.pageId,
      endpoint: this.getEndpoint("/canvases"),
    };

    const { result, error } = await this.makeRequest<ICreateCanvasResponseData>(
      proxyBody,
    );
    if (error || !result) {
      throw Error(
        error?.message || "There was an error creating the instant experience",
      );
    }

    return result;
  }

  async updateCanvas(
    canvasId: string,
    params: ICanvasParams,
  ): Promise<ICreateCanvasResponseData> {
    const proxyBody: IProxyRequestBody<ICanvasParams> = {
      endpoint: "/" + canvasId,
      method: "POST",
      platform: "facebook",
      data: params,
    };

    const { result, error } = await this.makeRequest<IUpdateCanvasResponseData>(
      proxyBody,
    );
    if (error || !result?.success) {
      throw Error(
        error?.message || "There was an error updating the instant experience",
      );
    }

    return {
      id: canvasId,
    };
  }

  async getCanvas(canvasId: string): Promise<ICanvasFields> {
    // mock object used as a workaround to dynamically get the keys from the interface
    const mockObject: ICanvasFields = {
      id: "",
      background_color: "",
      name: "",
      body_elements: [],
      is_hidden: false,
      is_published: false,
      canvas_link: "",
      update_time: 0,
      last_editor: { id: "", name: "" },
      owner: { id: "", name: "" },
    };
    const proxyBody: IProxyRequestBody<ICanvasFields> = {
      endpoint: "/" + canvasId,
      method: "GET",
      platform: "facebook",
      query: `?fields=${encodeURIComponent(
        JSON.stringify(Object.keys(mockObject)),
      )}`,
    };

    const { result, error } = await this.makeRequest<ICanvasFields>(proxyBody);
    if (error || !result) {
      throw Error(error?.message);
    }

    return result;
  }

  static getButtonElementFields(): string[] {
    // mock object used as a workaround to dynamically get the keys from the interface
    const mockObject: IButtonFields = {
      id: "",
      background_color: "",
      name: "",
      element_type: "BUTTON",
      rich_text: { inline_styles: [], plain_text: "", block_type: "UNSTYLED" },
      action: { type: "OPEN_URL", url: "" },
      bottom_padding: "",
      top_padding: "",
      button_color: "",
      button_style: ButtonStyle.BUTTON_FILLED,
      font_family: "",
      font_size: "",
      line_height: "",
      text_alignment: TextAlignment.CENTER,
      text_color: "",
    };

    return Object.keys(mockObject);
  }

  static getFooterElementFields(): string[] {
    // mock object used as a workaround to dynamically get the keys from the interface
    const mockObject: IFooterFields = {
      id: "",
      background_color: "",
      name: "",
      element_type: "FOOTER",
      bottom_padding: "",
      child_elements: [],
      top_padding: "",
    };

    return Object.keys(mockObject);
  }

  static getPhotoElementFields(): string[] {
    // mock object used as a workaround to dynamically get the keys from the interface
    const mockObject: ICanvasPhotoParams = {
      name: "",
      action: { type: "OPEN_URL", url: "" },
      photo: { id: "" },
      style: CanvasElementStyle.FIT_TO_WIDTH,
    };

    return Object.keys(mockObject);
  }

  static getVideoElementFields(): string[] {
    // mock object used as a workaround to dynamically get the keys from the interface
    const mockObject: ICanvasVideoParams = {
      name: "",
      video: { id: "" },
      style: CanvasElementStyle.FIT_TO_WIDTH,
    };

    return Object.keys(mockObject);
  }

  async getElement<TFields extends CanvasElementFieldTypes>(
    elementId: string,
    fields: string[],
  ): Promise<TFields> {
    const proxyBody: IProxyRequestBody<TFields> = {
      endpoint: "/" + elementId,
      method: "GET",
      platform: "facebook",
      query: `?fields=${encodeURIComponent(JSON.stringify(fields))}`,
    };

    const { result, error } = await this.makeRequest<TFields>(proxyBody);
    if (error || !result) {
      throw Error(error?.message);
    }

    return result;
  }

  async createElements(
    params: ICanvasElementParams,
  ): Promise<ICreateCanvasResponseData> {
    const proxyBody: IProxyRequestBody<ICanvasElementParams> = {
      data: params,
      method: "POST",
      platform: "facebook",
      facebookPageId: this.pageId,
      endpoint: this.getEndpoint("/canvas_elements"),
    };

    const { result, error } = await this.makeRequest<ICreateCanvasResponseData>(
      proxyBody,
    );
    if (error || !result) {
      throw Error(
        error?.message || "There was an error creating the instant experience",
      );
    }

    return result;
  }

  async createElementsAndReturn(
    params: ICanvasElementParams,
  ): Promise<IApiResponse<ICreateCanvasResponseData>> {
    const proxyBody: IProxyRequestBody<ICanvasElementParams> = {
      data: params,
      method: "POST",
      platform: "facebook",
      facebookPageId: this.pageId,
      endpoint: this.getEndpoint("/canvas_elements"),
    };

    try {
      const { result, error } =
        await this.makeRequest<ICreateCanvasResponseData>(proxyBody);

      if (error || !result) {
        return { result: null, error };
      }

      return { result, error: null };
    } catch (errorRes) {
      return { result: null, error: (errorRes as IApiResponse).error };
    }
  }

  async updateElement<TParams>(
    elementId: string,
    params: TParams,
  ): Promise<ICreateCanvasResponseData> {
    const proxyBody: IProxyRequestBody<TParams> = {
      endpoint: "/" + elementId,
      method: "POST",
      platform: "facebook",
      data: params,
    };

    const { result, error } = await this.makeRequest<IUpdateCanvasResponseData>(
      proxyBody,
    );

    if (error || !result?.success) {
      throw Error(error?.message || "There was an error updating the element");
    }

    return {
      id: elementId,
    };
  }

  /**  Upload a video or image from a URL to the test facebook page, so it can be used in canvases */
  // AV2-3877
  async createPageMedia({
    url,
    mediaType,
  }: CreatePageMediaArgs): Promise<CreateMediaResponse> {
    const data =
      mediaType === "video"
        ? { file_url: url, published: false }
        : { url, published: false };

    const proxyBody: IProxyRequestBody = {
      data,
      method: "POST",
      platform: "facebook",
      facebookPageId: this.pageId,
      endpoint: this.getEndpoint(`/${mediaType}s`),
    };

    const { result, error } = await this.makeRequest<CreateMediaResponse>(
      proxyBody,
    );
    if (error || !result) {
      throw Error(error?.message);
    }

    if (mediaType === "video") {
      await pullUntilVideoIsReady(result.id);
    }

    return result;
  }

  /**
   * Memoized method to create a page media.
   * It is memoized for 15 minutes to avoid creating the same media multiple times
   */
  createMemoizedPageMedia(args: CreatePageMediaArgs) {
    return createMemoizedPageMedia({ ...args, pageId: this.pageId });
  }

  private makeRequest<TResult>(body: IProxyRequestBody) {
    return API.services.adLibrary.platformProxyRequest<TResult>(body);
  }
}

/**
 * Memoized function to create a page media
 * It is memoized for 15 minutes to avoid creating the same media multiple times
 */
const createMemoizedPageMedia = memoizee(
  async ({
    mediaType,
    url,
    pageId,
  }: CreatePageMediaArgs & { pageId: string }) => {
    const page = new Page(pageId);
    return page.createPageMedia({
      url,
      mediaType,
    });
  },
  {
    promise: true,
    normalizer: ([{ mediaType, url, pageId }]) => {
      return `${mediaType}_${url}_${pageId}`;
    },
    maxAge: 1000 * 60 * 15,
  },
);

const fbPage = new Page(getDefaultPropValue("PAGEID"));

export { fbPage };
