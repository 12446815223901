import { Form, Input, Select } from "antd";
import { useMemo, useState } from "react";
import { OptionGroupLayer } from "shared/types/salesEnablement";
import styles from "./LayerInput.module.scss";
import {
  getNormalizedUrlValue,
  getOptionGroupChildOptions,
} from "utils/helpers.salesEnablement";
import { OptionsField } from "shared/types/marketingMaterials";
import { mapValues } from "lodash";
import { isLicenseField } from "../hooks/agentManagement";
import { Rule } from "antd/lib/form";
import { LicenseField } from "./LicenseField";
import { baseRules } from "../utils/helpers";

const OptionGroupLayerInput = ({
  layer,
  value,
  onChange,
  disabled,
}: {
  layer: OptionGroupLayer;
  value?: OptionsField;
  onChange?: (val: OptionsField) => void;
  disabled?: boolean;
}) => {
  const [optionValue, setOptionValue] = useState<
    OptionsField["optionId"] | undefined
  >(value?.optionId || undefined);

  const childOptionsValues = value?.childOptions;

  // This is to support copying the same variable name in the string
  // only the first time it gets updated
  const [dirtyFields, setDirtyFields] = useState<Record<string, boolean>>(
    mapValues(value?.childOptions || {}, option => !!option),
  );

  const optionsMap = useMemo(() => {
    return layer.options?.reduce<Record<string, string>>((map, option) => {
      map[option.id] = option.value;
      return map;
    }, {});
  }, [layer.options]);

  const childVariables = useMemo(() => {
    if (!optionValue) return [];
    // Find all variables in the string {{variable}}
    const childOptions = getOptionGroupChildOptions(optionsMap[optionValue]);

    // Remove the curly braces around them
    return (
      childOptions?.map((val, index) => ({
        value: val,
        index,
        option: optionValue,
      })) || []
    );
  }, [optionValue, optionsMap]);

  const onMainOptionChange = (mainOpt: string | undefined) => {
    if (!mainOpt) {
      setOptionValue(undefined);
      onChange?.({ optionId: "", childOptions: {}, type: "options" });
      return;
    }

    const childOptions = getOptionGroupChildOptions(mainOpt);
    const childValues = Object.fromEntries(childOptions.map(val => [val, ""]));
    setOptionValue(mainOpt);
    setDirtyFields(mapValues(childValues, () => false));
    onChange?.({ optionId: mainOpt, childOptions: {}, type: "options" });
  };

  const onChildOptionsChange = (
    childOptionValue: string,
    childOptionName: string,
    index: number,
  ) => {
    if (!optionValue) return;

    const accessor = `${childOptionName}-${index}`;

    setDirtyFields(prevDirtyFields => ({
      ...prevDirtyFields,
      [accessor]: true,
    }));

    const isUrl = childOptionName.toLowerCase().includes("url");
    const newValue = isUrl
      ? getNormalizedUrlValue(childOptionValue)
      : childOptionValue;

    const newVal = {
      ...childOptionsValues,
      ...copyValueInDuplicatedVariables(childOptionName, newValue),
      [accessor]: newValue,
    };

    onChange?.({
      optionId: optionValue,
      childOptions: newVal,
      type: "options",
    });
  };

  const copyValueInDuplicatedVariables = (
    childOptionName: string,
    childOptionValue: string,
  ) => {
    const isDuplicatedVariable =
      childVariables.filter(
        childVariable => childVariable.value === childOptionName,
      ).length > 1;

    if (isDuplicatedVariable) {
      const duplicatedVariableAccessors = childVariables
        .filter(childVariable => childVariable.value === childOptionName)
        .map(childVariable => `${childVariable.value}-${childVariable.index}`);

      return duplicatedVariableAccessors.reduce((accumulator, dvAccessor) => {
        if (!dirtyFields[dvAccessor]) {
          return {
            ...accumulator,
            [dvAccessor]: childOptionValue,
          };
        }
        return accumulator;
      }, {});
    }

    return {};
  };

  return (
    <div className={styles.formItem}>
      <Select<string>
        placeholder="Please Select"
        options={layer.options?.map(option => ({
          label: option.value,
          value: option.id,
        }))}
        value={optionValue}
        onChange={onMainOptionChange}
        disabled={disabled}
        allowClear
      />
      <div className={styles.childVariablesContainer}>
        {childVariables?.map((variable, index) => {
          const accessor = `${variable.value}-${variable.index}`;
          const fieldName = ["fields", layer.id, "childOptions", accessor];
          if (isLicenseField(variable.value))
            return (
              <Form.Item key={accessor} name={fieldName}>
                <LicenseField
                  label={variable.value}
                  fieldName={["fields", layer.id, "childOptions", accessor]}
                />
              </Form.Item>
            );
          const value = childOptionsValues?.[accessor];
          return (
            <Form.Item
              key={accessor}
              label={variable.value}
              name={["fields", layer.id, "childOptions", accessor]}
              validateFirst
              validateTrigger={["onBlur"]}
              rules={baseRules()}
              dependencies={[
                ["fields", layer.id, "childOptions"],
                ["locations"],
              ]}
            >
              <Input
                placeholder={variable.value}
                value={value}
                onChange={e => {
                  onChildOptionsChange(e.target.value, variable.value, index);
                }}
                disabled={disabled}
              />
            </Form.Item>
          );
        })}
      </div>
    </div>
  );
};

export const validateRequired: Rule[] = [
  {
    validator: async (_, value?: OptionsField) => {
      if (!value?.optionId) throw new Error("This field is required");
    },
    type: "object",
    required: true,
  },
];

export default OptionGroupLayerInput;
