import { RcFile } from "antd/lib/upload";
import { useMutation, useQueryClient } from "react-query";
import { QUERY_KEYS } from "screens/adLibrary/marketingMaterials/utils/constants";
import API from "services";
import { uploadToS3 } from "shared/components/media/utils.upload";
import { MarketingMaterial } from "shared/types/marketingMaterials";
import { PagedQueryResponse } from "../useInfiniteFetchAll";
import produce from "immer";

/** if `optimistic` is passed, the mutation will update the cache before the server response */
export type MutationMode = "optimistic" | "regular";

type Config = {
  mode?: MutationMode;
};

export const useCreateMarketingMaterial = ({ mode }: Config = {}) => {
  const queryClient = useQueryClient();

  return useMutation<MarketingMaterial, Error, MarketingMaterial>({
    mutationFn: API.services.adLibrary.createMarketingMaterial,
    onSettled: () => {
      return queryClient.invalidateQueries(QUERY_KEYS.marketingMaterials);
    },
    ...(mode === "optimistic" && {
      onMutate: async newMaterial => {
        const previousMaterials = queryClient.getQueryData<
          PagedQueryResponse<{ items: MarketingMaterial[] }>
        >(QUERY_KEYS.marketingMaterials);

        if (!previousMaterials || !previousMaterials.pages.length) {
          return;
        }

        await queryClient.cancelQueries(QUERY_KEYS.marketingMaterials);

        const newMaterials = [...previousMaterials.pages[0].items, newMaterial];
        const newPages = [...previousMaterials.pages];
        newPages[0].items = newMaterials;

        queryClient.setQueryData(QUERY_KEYS.marketingMaterials, {
          pages: newPages,
        });

        return previousMaterials;
      },
      onError: (_error, _newMaterial, context) => {
        queryClient.setQueryData(QUERY_KEYS.marketingMaterials, context);
      },
    }),
  });
};

export const useUpdateMarketingMaterial = () => {
  const queryClient = useQueryClient();

  return useMutation<MarketingMaterial, Error, MarketingMaterial>({
    mutationFn: API.services.adLibrary.updateMarketingMaterial,
    onSettled: () => {
      return queryClient.invalidateQueries(QUERY_KEYS.marketingMaterials);
    },
  });
};

export const useDeleteMarketingMaterial = ({ mode }: Config = {}) => {
  const queryClient = useQueryClient();

  return useMutation<string, Error, string[]>({
    mutationFn: API.services.adLibrary.deleteMarketingMaterial,
    onSettled: () => {
      queryClient.invalidateQueries(QUERY_KEYS.marketingMaterials);
    },
    ...(mode === "optimistic" && {
      onMutate: async ids => {
        await queryClient.cancelQueries(QUERY_KEYS.marketingMaterials);

        const previousMaterials = queryClient.getQueryData<
          PagedQueryResponse<{ items: MarketingMaterial[] }>
        >(QUERY_KEYS.marketingMaterials);

        if (!previousMaterials) {
          return;
        }

        const newMaterials = produce(previousMaterials, draft => {
          draft.pages.forEach(page => {
            page.items = page.items.filter(item => !ids.includes(item.id));
          });
        });

        queryClient.setQueryData(QUERY_KEYS.marketingMaterials, newMaterials);

        return previousMaterials;
      },
      onError: (_error, _ids, context) => {
        queryClient.setQueryData(QUERY_KEYS.marketingMaterials, context);
      },
    }),
  });
};

export const useDeliveryMarketingMaterial = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: API.services.adLibrary.deliveryMarketingMaterial,
    onSettled: () => {
      queryClient.invalidateQueries(QUERY_KEYS.marketingMaterials);
    },
  });
};

export const useMarketingMaterialDeliveryShippingMethods = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: API.services.adLibrary.marketingMaterialDeliveryShippingMethods,
    onSettled: () => {
      queryClient.invalidateQueries(QUERY_KEYS.shippingMethods);
    },
  });
};

export const useDeliveryMarketingMaterialByPrint = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: API.services.adLibrary.deliveryMarketingMaterialByPrint,
    onSettled: () => {
      queryClient.invalidateQueries(QUERY_KEYS.deliveries);
    },
  });
};

const uploadFail = () => Promise.reject(new Error("Failed to upload"));
export const useUploadMarketingMaterialMedia = () => {
  const upload = async (file: RcFile, materialId: string) => {
    const fileName = file.name.replaceAll(" ", "_");
    const { result } = await API.services.common.generatePresignedUrl({
      contentType: file.type,
      filename: fileName,
      s3Path: `marketing-materials/${materialId}`,
    });

    if (!result?.url) {
      return uploadFail();
    }

    const s3UploadResult = await uploadToS3(result.url, file);
    if (!s3UploadResult.ok) {
      return uploadFail();
    }

    const { result: renderAssetResult } =
      await API.services.salesEnablement.uploadRenderAsset(
        `marketing-materials/${materialId}/${encodeURIComponent(fileName)}`,
      );

    if (!renderAssetResult.length) {
      return uploadFail();
    }

    const resultUrl = result.url.split("?")[0];
    const [spAsset] = renderAssetResult;

    return { url: resultUrl, spAssetId: spAsset.id };
  };

  const uploadByAccountUrl = async (name: string) => {
    const { result: renderAssetResult } =
      await API.services.salesEnablement.uploadRenderAsset(
        `account/uploads-raw/${encodeURIComponent(name)}`,
      );
    if (!renderAssetResult.length) {
      return uploadFail();
    }

    const [spAsset] = renderAssetResult;

    return { spAssetId: spAsset.id };
  };

  return { upload, uploadByAccountUrl };
};

export const useCreateMarketingMaterialDelivery = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: API.services.adLibrary.deliverMarketingMaterials,
    onSettled: () => {
      queryClient.invalidateQueries(QUERY_KEYS.deliveries);
    },
  });
};
