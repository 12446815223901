import { Button, ButtonProps } from "antd";
import { GoogleSignInIcon } from "./GoogleSignInIcon";

export const GoogleSignInButton = (props: ButtonProps) => {
  return (
    <Button
      {...props}
      style={{ backgroundColor: "#4285F4", padding: 0, border: 0 }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <GoogleSignInIcon width={32} height={32} />
        <div
          style={{
            padding: "0 8px",
            color: "white",
            fontFamily: "Roboto-Bold",
            fontSize: 14,
          }}
        >
          Sign In with Google
        </div>
      </div>
    </Button>
  );
};
