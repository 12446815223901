import { ReactText } from "react";
import GenericError from "shared/errors/GenericError";
import { OfferType } from "./shared";

export type Mode = "LEGAL LINGO";

export const CONDITIONS: string[] = ["New", "Used", "CPO"];
export const MODE_EDIT: string = "edit";
export const MODE_DUPLICATE: string = "duplicate";
export type Modes = "new" | "edit" | "duplicate";

export type IDisclosure = {
  id: number;
  name: string;
  vin: boolean;
  condition: string[];
  location: string[];
  oem: string[];
  store: string[];
  disclosures: IStateDisclosureElement[];
  keyName?: string;
};

export type DisclosureType = IDisclosure;

export type IssueType = "error" | "warning";
export type IssueValue = "empty" | "invalid";

export type GetDisclosureQueryParams = {
  id: string;
  limit?: number;
  paginationKey?: string;
};

export type GetDisclosuresQueryParams = {
  limit?: number;
  paginationKey?: string;
};

export interface IGetDisclosureResponseData {
  data: IDisclosure[];
  disclosure: IDisclosure;
  paginationKey?: string;
}

export type HeaderMenu = "VARIABLES" | "DISCLOSURES" | "MESSAGING";

export type FetchingDataStatus = null | "stateDisclosures" | "stateExceptions";

export interface ILegalLingoDataRecord {
  [key: string]: string | number;
}

export interface ILegalLingoReduxState {
  messagesandExceptionsArray: any;
  mode: string;
  error: GenericError | null;
  stateDisclosures: IStateDisclosureRecord[] | [];
  stateExceptions: IStateExceptionRecord[] | [];
  creatingDataStatus: string;
  fetchingData: FetchingDataStatus;
  stateUpdateSuccessful: boolean;
  oemUpdateSuccessful: boolean;
  isFirstLoad: boolean;
  isFirstMessagingLoad: boolean;
  messagesGlobalState: {
    [key: string]: IMessagingObjectType;
  };
  currentMessagingObject?: IMessagingObjectType;
  oemSelected: string | null;
  stateSelected: string | null;
  messagingForStamp: null | IMessagingForStamp;

  reloadUUID: string;
  stateExceptionRecordsInForm: IStateExceptionRecord[];

  createDisclosureSuccessful: boolean;
  updateDisclosureSuccessful: boolean;

  // David Ro TO DO: add these checkedState and checkedOem properties
}

export interface IMessagingForStamp {
  loading: boolean;
  error?: GenericError;
  messagings?: Array<{
    oem: string;
    messaging: IMessagingObjectType;
  }>;
}

export interface IMessagingObjectType {
  primaryMessage: string;
  secondaryMessage: string;
  oem: string | null;
  oemName?: string | null;
  state: string | null;
  key: string;
  offerType: OfferType;
}
// STATE TAB

export interface ILegalLingoStateTablesResponse {
  result: null | {
    stateVehicleInfoTable: ILegalLingoVehicleInfoTable[];
    stateNumberAtThisPriceTable: ILegalLingoNumberAtThisTable[];
    stateLeaseTable: ILegalLingoLeaseTable[];
    stateZeroDownLeaseTable: ILegalLingoZeroDownLeaseTable[];
    stateFinanceTable: ILegalLingoFinanceTable[];
    statePurchaseTable: ILegalLingoPurchaseTable[];
    stateAPRTable: ILegalLingoAPRTable[];
  };
  statusCode: null | number;
  error: null | {
    code: string;
    type: string;
    message: string;
  };
}

export interface IUpdateLegalLingoStateTablesResponse {
  result: null | {
    updateStateVehicleInfoLegalLingosResult: ILegalLingoVehicleInfoTable[];
    updateStateNumberAtThisPriceLingosResult: ILegalLingoNumberAtThisTable[];
    updateStateLeaseLegalLingosResult: ILegalLingoLeaseTable[];
    updateStateZeroDownLeaseLegalLingosResult: ILegalLingoZeroDownLeaseTable[];
    updateStateFinanceLegalLingosResult: ILegalLingoFinanceTable[];
    updateStatePurchaseLegalLingosResult: ILegalLingoPurchaseTable[];
    updateStateAPRLegalLingosResult: ILegalLingoAPRTable[];
  };
  statusCode: null | number;
  error: null | {
    code: string;
    type: string;
    message: string;
  };
}

// OEM TAB

export interface ILegalLingoOEMTablesResponse {
  result: null | {
    oemVehicleInfoTable: ILegalLingoVehicleInfoTable[];
    oemLeaseTable: ILegalLingoLeaseTable[];
    oemNumberAtThisPriceTable: ILegalLingoNumberAtThisTable[];
    oemZeroDownLeaseTable: ILegalLingoZeroDownLeaseTable[];
    oemFinanceTable: ILegalLingoFinanceTable[];
    oemPurchaseTable: ILegalLingoPurchaseTable[];
    oemAPRTable: ILegalLingoAPRTable[];
  };
  statusCode: null | number;
  error: null | {
    code: string;
    type: string;
    message: string;
  };
}

export interface IUpdateLegalLingoOEMTablesResponse {
  result: null | {
    updateOemVehicleInfoLegalLingosResult: ILegalLingoVehicleInfoTable[];
    updateOemNumberAtThisPriceLingosResult: ILegalLingoLeaseTable[];
    updateOemLeaseLegalLingosResult: ILegalLingoNumberAtThisTable[];
    updateOemZeroDownLeaseLegalLingosResult: ILegalLingoZeroDownLeaseTable[];
    updateOemFinanceLegalLingosResult: ILegalLingoFinanceTable[];
    updateOemPurchaseLegalLingosResult: ILegalLingoPurchaseTable[];
    updateOemAPRLegalLingosResult: ILegalLingoAPRTable[];
  };
  statusCode: null | number;
  error: null | {
    code: string;
    type: string;
    message: string;
  };
}

// Vehicle Info Table
export interface ILegalLingoVehicleInfoTable {
  lingo_state?: string;
  lingo_oem?: string;
  lingo_vin: string;
  lingo_days_in_stock: string;
  lingo_msrp: string;
  lingo_trim: string;
  lingo_drivetrain: string;
  lingo_transmission: string;
  lingo_model_code: string;
  lingo_year: string;
  lingo_expiration_date: string;
  lingo_package: string;
  lingo_feature_two: string;
  lingo_condition: string;
  lingo_make: string;
  lingo_model: string;
  lingo_feature_one: string;
  lingo_cylinders: string;
  lingo_accessories: string;
  lingo_number_at_this_price: string;
  created_at: number;
  updated_at: number;
}

export interface ILegalLingoNumberAtThisTable {
  lingo_vins_list: string;
  lingo_vins_at_this_price: string;
  created_at: number;
  updated_at: number;
}

// Lease Table
export interface ILegalLingoLeaseTable {
  lingo_state?: string;
  lingo_oem?: string;
  lingo_number_of_payments: string;
  lingo_monthly_payment: string;
  lingo_total_due_at_signing: string;
  lingo_residual_sales_value: string;
  lingo_total_due_at_signing_including_rebates: string;
  lingo_capitalized_cost: string;
  lingo_cap_cost_reduction: string;
  lingo_net_adjusted_capitalized_cost: string;
  lingo_total_lease_charge: string;
  lingo_total_payments: string;
  lingo_security_deposit: string;
  lingo_termination_fee: string;
  lingo_fico: string;
  lingo_miles_per_year: string;
  lingo_cents_per_mile: string;
  lingo_acquisition_fee: string;
  lingo_additional_lease_disclosure: string;
  lingo_lease_rebate_one_amount: string;
  lingo_lease_rebate_one_name: string;
  lingo_lease_rebate_one_disclosure: string;
  created_at: number;
  updated_at: number;
}

export interface ILegalLingoZeroDownLeaseTable {
  lingo_state?: string;
  lingo_oem?: string;

  lingo_zero_down_monthly_payment: string;
  lingo_zero_down_total_payments: string;
  lingo_zero_down_capitalized_cost: string;
  lingo_zero_down_security_deposit: string;
  lingo_zero_down_miles_per_year: string;
  lingo_zero_down_acquisition_fee: string;
  lingo_zero_down_termination_fee: string;
  lingo_zero_down_number_of_payments: string;
  lingo_zero_down_fico: string;
  lingo_zero_down_total_lease_charge: string;
  lingo_zero_down_total_due_at_signing_including_rebates: string;
  lingo_zero_down_lease_rebate_one_amount: string;
  lingo_zero_down_cents_per_mile: string;
  lingo_zero_down_cap_cost_reduction: string;
  lingo_zero_down_total_due_at_signing: string;
  lingo_zero_down_lease_rebate_one_name: string;
  lingo_zero_down_net_adjusted_capitalized_cost: string;
  lingo_zero_down_lease_rebate_one_disclosure: string;
  lingo_zero_down_residual_sales_value: string;

  created_at: number;
  updated_at: number;
}
export interface ILegalLingoFinanceTable {
  lingo_state?: string;
  lingo_oem?: string;

  lingo_finance_down_payment: string;
  lingo_term: string;
  lingo_amount_financed: string;
  lingo_rate: string;
  lingo_payment: string;
  lingo_finance_min_fico: string;

  created_at: number;
  updated_at: number;
}
export interface ILegalLingoPurchaseTable {
  lingo_state?: string;
  lingo_oem?: string;

  lingo_savings_off_msrp: string;
  lingo_net_cost: string;
  lingo_dealer_discount: string;
  lingo_percent_off_msrp_value: string;
  lingo_price_after_conditional_rebates: string;
  lingo_dealer_price: string;

  lingo_final_price: string;
  lingo_savings_off_msrp_description: string;
  lingo_additional_purchase_disclosure: string;

  lingo_total: string;

  created_at: number;
  updated_at: number;
}
export interface ILegalLingoAPRTable {
  lingo_state?: string;
  lingo_oem?: string;

  lingo_apr_model_trim: string;
  lingo_apr_amount_per_thousand: string;
  lingo_apr_expiration_date: string;
  lingo_apr_year: string;
  lingo_apr_term: string;
  lingo_additional_apr_disclosure: string;
  lingo_apr_make: string;
  lingo_apr_rate: string;
  lingo_apr_condition: string;

  created_at: number;
  updated_at: number;
}

export interface IDisclosureVariable {
  name: string;
  requiredByStates: string[];
  requiredByOems?: string[];
}

export interface IOfferTypeVariable {
  offerType: TDisclosureType;
  variables: IDisclosureVariable[];
}

export interface ICreateStateDisclosureResponse {
  result: { stateDisclosure: IStateDisclosureRecord };
  error: GenericError | null;
}

export interface IStateDisclosureRecord {
  state: string;
  disclosures: IStateDisclosureElement[];
}

export interface IStateDisclosureElement {
  offerType: TDisclosureType;
  text: string;
}

export interface ICreateStateExceptionResponse {
  result: { stateException: IStateExceptionRecord };
  error: GenericError | null;
}

export interface ICreateStateExceptionResponse {
  result: { stateException: IStateExceptionRecord };
  error: GenericError | null;
}

export interface IStateExceptionRecord {
  state: string;
  oem: string;
  exceptions: IStateExceptionElement[];
}

export type IStateExceptionElement = IStateDisclosureElement;

export interface ILegalLingoDataFetchResponse {
  result: {
    stateDisclosures?: IStateDisclosureRecord[];
    stateExceptions?: IStateExceptionRecord[];
    data?: [];
  };
  error: GenericError | null;
}

export type TDisclosureDataType = "disclosure" | "exception";

export interface IGetMessageResponse {
  result:
    | null
    | {
        oem: string;
        messaging: IMessagingObjectType; // made it optional because this might be {} if data not found
      }[];
  error: null | GenericError;
}

export type TDisclosureType =
  | "Vehicle Info"
  | "Number at this Price"
  | "Lease"
  | "Zero Down Lease"
  | "Finance"
  | "Purchase"
  | "APR"
  | "Extra Cost"
  | "Expiration Date";

export type TRequiredVarsByDisclosureType = {
  [key in TDisclosureType]: ILegalLingoDataRecord[];
};

export type TCheckedVariable =
  | ILegalLingoVehicleInfoTable
  | ILegalLingoNumberAtThisTable
  | ILegalLingoLeaseTable
  | ILegalLingoZeroDownLeaseTable
  | ILegalLingoPurchaseTable
  | ILegalLingoFinanceTable
  | ILegalLingoAPRTable
  | Record<string, ReactText>;

export type TVariableGroupName =
  | "Vehicle Info"
  | "Number at this Price"
  | "Lease"
  | "Zero Down Lease"
  | "Finance"
  | "Purchase"
  | "APR";
