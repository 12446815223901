import { useCallback, useLayoutEffect, useRef } from "react";

import "./ManageShape.scss";
import { TObjectUpdateAction, TShapePropertyKey } from "./ManageText";
import Fill from "./manageShape/Fill";
import Stroke from "./manageShape/Stroke";

interface ManageShapeProps {
  selectedObject?: fabric.Object; // canvasAction is set whenever user selects an object from the canvas
}

interface ManageShapeHandlers {
  setObjectUpdateAction: (action: TObjectUpdateAction) => void;
}

const ShapeColor: React.FC<ManageShapeProps & ManageShapeHandlers> = props => {
  const { selectedObject, setObjectUpdateAction } = props;
  const ref = useRef<HTMLDivElement | null>(null);
  useLayoutEffect(() => {
    // NOTE: To meet the design requirement, needed to change styles of the Collapse.Panel but this was not supported by antd.
    //       So here, accessing DOM to change its style.
    // Design: https://www.figma.com/file/cjXk5nYt3EAJowhAyihuDK/Design-Studio?node-id=5%3A130
    if (!ref.current) return;

    const parentNode = (ref.current as HTMLDivElement)
      .parentNode as HTMLElement;
    parentNode.style.padding = "0px";
    parentNode.style.background = "white";
  }, []);

  const onChange = useCallback(
    (key: TShapePropertyKey, value: string | number | undefined) => {
      if (!selectedObject) return;

      setObjectUpdateAction({
        target: selectedObject,
        action: {
          type: "shape",
          data: {
            key,
            value,
          },
        },
      });
    },
    [selectedObject, setObjectUpdateAction],
  );

  return (
    <div ref={ref} className="shape-color-container">
      <Fill
        object={props.selectedObject as fabric.Rect | fabric.Circle}
        onChange={onChange}
      />
      <Stroke
        object={props.selectedObject as fabric.Rect | fabric.Circle}
        onChange={onChange}
      />
    </div>
  );
};

export default ShapeColor;
