import { useCallback } from "react";
import {
  AdDestKey,
  AdsetData,
  NormalizedCampaignData,
  StoreData,
  getAdDestFromKey,
} from "../../../../campaignData.utils";
import { useNormalizedCampaignsData } from "../../../../hooks/useNormalizedCampaignsData";
import { useSetCurrentSession } from "../../../../hooks/useCurrentSession";
import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { DataNode } from "antd/lib/tree";
import { findAdProtoDraft } from "screens/adLibrary/facebookUtils/adProto";

export const useCampaignsTreeData = () => {
  const { campaignData, isLoadingCampaigns } = useNormalizedCampaignsData();
  const { setSession } = useSetCurrentSession();
  const removeAdDest = useCallback(
    (adKey: AdDestKey) =>
      setSession(prevSession => {
        const adDest = getAdDestFromKey(adKey);
        const adProtoDraft = findAdProtoDraft({
          adProtoDrafts: prevSession.data.adProtoDrafts,
          adId: adDest.adId,
          storeName: adDest.storeName,
        });

        adProtoDraft.dests = adProtoDraft.dests.filter(
          item =>
            item.adsetId !== adDest.adsetId ||
            item.campaignId !== adDest.campaignId,
        );
      }),
    [setSession],
  );
  const treeData = getCampaignsTreeData({ campaignData, removeAdDest });

  return {
    treeData,
    isLoadingCampaigns,
  };
};

type TreeDataArgs = {
  campaignData: NormalizedCampaignData;
  removeAdDest: (adKey: AdDestKey) => void;
};

const getCampaignsTreeData = ({ campaignData, removeAdDest }: TreeDataArgs) => {
  return Object.values(campaignData.stores).map(store =>
    getStoreNode({ store, campaignData, removeAdDest }),
  );
};

const getStoreNode = ({
  store,
  campaignData,
  removeAdDest,
}: {
  store: StoreData;
} & TreeDataArgs): DataNode => {
  return {
    key: store.key,
    title: store.storeName,
    isLeaf: false,
    disableCheckbox: store.campaignIds.length === 0,
    children: store.campaignIds?.map(campaignId =>
      getCampaignNode({ campaignData, campaignId, removeAdDest }),
    ),
  };
};

const getCampaignNode = ({
  campaignData,
  campaignId,
  removeAdDest,
}: {
  campaignId: string;
} & TreeDataArgs): DataNode => {
  const campaign = campaignData.campaigns[campaignId];
  return {
    key: campaign.key,
    title: campaign.name,
    isLeaf: false,
    children: campaign.adsetIds.map(adsetId =>
      getAdsetNode({ campaignData, adsetId, removeAdDest }),
    ),
  };
};

const getAdsetNode = ({
  campaignData,
  adsetId,
  removeAdDest,
}: {
  adsetId: string;
} & TreeDataArgs): DataNode => {
  const adset = campaignData.adsets[adsetId];
  return {
    key: adset.key,
    title: adset.name,
    isLeaf: false,
    children: adset.ads.map(adId =>
      getAdNode({ campaignData, adset, adId, removeAdDest }),
    ),
  };
};

const getAdNode = ({
  campaignData,
  adset,
  adId,
  removeAdDest,
}: {
  adset: AdsetData;
  adId: string;
} & TreeDataArgs): DataNode => {
  const adsetAdData = campaignData.adsetAd[`${adset.key}_${adId}`];
  const adData = campaignData.ads[adId];

  return {
    key: adsetAdData.key,
    title: adData.name,
    isLeaf: true,
    checkable: false,
    switcherIcon: (
      <Button
        onClick={() => removeAdDest(adsetAdData.key)}
        icon={<DeleteOutlined />}
        type="text"
      />
    ),
  };
};
