import React, { useContext, useState } from "react";
import {
  AdInfoUpdates,
  AdType,
  ExportDestination,
  RecommendedColumnMatches,
  ValidityRecord,
} from "../shared/types";

interface AdLibraryExportContextType {
  columnMatches: RecommendedColumnMatches;
  adInfoUpdates: AdInfoUpdates;
  adType: AdType | null;
  exportDestination: ExportDestination | null;
  validity: Record<string, ValidityRecord>;
  setColumnMatches: React.Dispatch<
    React.SetStateAction<RecommendedColumnMatches>
  >;
  setAdInfoUpdates: React.Dispatch<React.SetStateAction<AdInfoUpdates>>;
  setAdType: React.Dispatch<React.SetStateAction<AdType | null>>;
  setExportDestination: React.Dispatch<
    React.SetStateAction<ExportDestination | null>
  >;
  setValidity: React.Dispatch<
    React.SetStateAction<Record<string, ValidityRecord>>
  >;
}

const AdLibraryExportContext = React.createContext<
  AdLibraryExportContextType | undefined
>(undefined);

export const AdLibraryExportProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  // adInfoUpdates represents a diff between the ad data computed from the matched columns and
  // the updates made by the user in the fill ad info table.
  // Only rows/columns which have been updated by the user in the fill ad info table will be defined.
  const [adInfoUpdates, setAdInfoUpdates] = useState<AdInfoUpdates>({});
  const [adType, setAdType] = useState<AdType | null>(null);
  const [columnMatches, setColumnMatches] = useState<RecommendedColumnMatches>(
    {},
  );
  const [exportDestination, setExportDestination] =
    useState<ExportDestination | null>(null);
  const [validity, setValidity] = useState<Record<string, ValidityRecord>>({});

  const contextValue: AdLibraryExportContextType = {
    adType,
    setAdType,
    columnMatches,
    setColumnMatches,
    exportDestination,
    setExportDestination,
    adInfoUpdates,
    setAdInfoUpdates,
    validity,
    setValidity,
  };

  return (
    <AdLibraryExportContext.Provider value={contextValue}>
      {children}
    </AdLibraryExportContext.Provider>
  );
};

AdLibraryExportProvider.displayName = "AdLibraryExportProvider";

export const useAdLibraryExportContext = () => {
  const adLibraryExportContext = useContext(AdLibraryExportContext);
  if (!adLibraryExportContext) {
    throw new Error(
      "useAdLibraryExportContext must be used within an AdLibraryExportProvider",
    );
  }
  return adLibraryExportContext;
};
