import {
  ExclamationCircleTwoTone,
} from "@ant-design/icons";
import { Progress, Typography } from "antd";
import AlexiaCopyToClipboard from "shared/components/CopyLink";
import { IExportGetFileUrlResponse } from "shared/types/adLibrary";
import { EXPORT_GENERATING, EXPORT_DONE } from "../hooks/useGenerateExports";

const { Text } = Typography;

export const Description = ({
  urlResponse,
}: {
  urlResponse: IExportGetFileUrlResponse;
}) => {
  const status =
    urlResponse.status === EXPORT_GENERATING ? "active" : "success";
  const message =
    urlResponse.status === EXPORT_DONE && urlResponse.url
      ? "Exported"
      : "Exporting";

  return (
    <div>
      <span>{message}</span>
      <div>
        <Progress percent={100} status={status} showInfo={false} />
      </div>
      {urlResponse.status === EXPORT_DONE && urlResponse.url && (
        <span>
          <a href={urlResponse.url} target="_blank" rel="noreferrer">
            Open
          </a>{" "}
          <AlexiaCopyToClipboard
            text={urlResponse.url}
            copyTooltipText="Copy link to Export"
          />
        </span>
      )}
    </div>
  );
};

export const ErrorDescription = ({ message }: { message?: string }) => {
  return (
    <div>
      <span>Exporting</span>
      <div>
        <Progress percent={100} status="exception" showInfo={false} />
      </div>
      <Text type="danger">
        <ExclamationCircleTwoTone twoToneColor="red" /> {message}
      </Text>
    </div>
  );
};
