import { previewSizes } from "screens/campaignManagement/adReview/adCardListContainer/facebookAdCard/constants";
import useMarketingPrototype from "screens/everythingAds.hooks/useMarketingPrototype";
import { DEFAULT_DIMENSION } from "shared/constants/everythingads";
import { IAd } from "shared/types/adLibrary";
import Placeholder from "statics/images/ad_loader.gif";
import styles from "./MarketingPrototype.module.scss";

type Props = {
  ad: IAd;
};
const MarketingPrototype = (props: Props) => {
  const { Still } = previewSizes.MOBILE_FEED_STANDARD || {};
  const defaultIframeDim = {
    width: Still?.width || DEFAULT_DIMENSION.width,
    height: Still?.height || DEFAULT_DIMENSION.height,
  };
  const { ad } = props;
  const { iframe, isLoading, error } = useMarketingPrototype({ ad });

  return (
    <>
      {error && (
        <div
          style={{
            width: defaultIframeDim.width,
            height: defaultIframeDim.height,
          }}
        >{`${error}`}</div>
      )}

      {(!iframe || isLoading) && (
        <img
          className={styles.placeholder}
          width={defaultIframeDim.width}
          height={defaultIframeDim.height}
          src={Placeholder}
        />
      )}

      {iframe && !isLoading && (
        <div dangerouslySetInnerHTML={{ __html: iframe }} />
      )}
    </>
  );
};

export default MarketingPrototype;
