import { Button, DatePicker, Popover, Tooltip } from "antd";
import styles from "./RunDate.module.scss";
import { useMemo, useState } from "react";
import { IAd } from "shared/types/adLibrary";
import moment from "moment";
import classNames from "classnames";
import { format } from "date-fns";
import { useLocation } from "react-router-dom";

type Props<T extends IAd = IAd> = {
  ad: T;
  value?: { start?: Date; end?: Date };
  onComplete: (
    range: [start: Date | null, end: Date | null],
    toAll: boolean,
    ad: T,
  ) => void;
};
const RunDate = <T extends IAd = IAd>({ ad, value, onComplete }: Props<T>) => {
  const [range, setRange] = useState<{ start?: Date; end?: Date } | undefined>(
    value,
  );
  const rangeMoment = useMemo(() => {
    const { start, end } = range ?? {};

    return [
      start ? moment(start) : undefined,
      end ? moment(end) : undefined,
    ] as [moment.Moment | undefined, moment.Moment | undefined];
  }, [range]);

  const hasValidDateRange = (value?: { start?: Date; end?: Date }) => {
    const { end } = value ?? {};
    return !!end;
  };
  const toDateRange = (value?: { start?: Date; end?: Date }) => {
    const { start, end } = value ?? {};
    const dateFormat = "MM/dd/yy";
    if (!end) throw new Error("End date is required");

    const startPart = start ? (
      format(start, dateFormat)
    ) : (
      <span style={{ color: "gray" }}>Start Date</span>
    );
    const endPart = format(end, dateFormat);
    return (
      <div>
        {startPart} - {endPart}
      </div>
    );
  };
  const [open, setOpen] = useState(false);
  const apply = (toAll: boolean) => {
    if (!range) return;
    const { start, end } = range;
    if (!end) {
      setErrorMessage("End date is required");
      return;
    }

    onComplete([start ?? null, end ?? null], toAll, ad as T);
    setOpen(false);
  };

  const onDateChange = (date: Date | null, which: "start" | "end") => {
    setRange(prev => ({
      ...prev,
      [which]: date,
    }));
  };

  const [errorMessage, setErrorMessage] = useState<string>();
  const forPlannerInstance =
    ad.instances?.some(inst => inst.name === ad.source) ?? false;
  const { pathname } = useLocation();
  const plannerInstanceLevel = pathname.includes("instance");
  const getClassName = () => {
    if (!hasValidDateRange(value)) return;

    if (plannerInstanceLevel) {
      return forPlannerInstance ? styles.valid : undefined;
    }

    return styles.valid;
  };
  const campaignPlannerLevel = !plannerInstanceLevel && !ad.source;

  return (
    <Popover
      trigger="click"
      visible={
        open &&
        (campaignPlannerLevel || (plannerInstanceLevel && forPlannerInstance))
      }
      onVisibleChange={open => {
        setOpen(open);
      }}
      content={
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.title}>Edit Run Date</div>
            <div className={styles.description}>
              With an end date set: Loaded status change to Pause Requested, and
              Pending or Load Requested change to Do Not Load.
            </div>
          </div>
          <div className={styles.datePickerContainer}>
            <DatePicker
              placeholder="Start date"
              defaultValue={rangeMoment?.[0]}
              onChange={date => onDateChange(date?.toDate() ?? null, "start")}
            />

            <DatePicker
              className={classNames(
                styles["date-picker"],
                !!errorMessage ? styles.error : undefined,
              )}
              placeholder="End date"
              defaultValue={rangeMoment?.[1]}
              onChange={date => onDateChange(date?.toDate() || null, "end")}
            />
          </div>

          {errorMessage && <div className={styles.error}>{errorMessage}</div>}
          <div className={styles.footer}>
            <Button type="link" onClick={() => apply(false)}>
              Apply
            </Button>
            <Button type="link" onClick={() => apply(true)}>
              Apply to all
            </Button>
          </div>
        </div>
      }
    >
      <div
        className={classNames(styles.clickToSelect, getClassName())}
        onClick={e => {
          e.stopPropagation();
          setOpen(true);
        }}
      >
        <Tooltip
          title={
            "Only client request ads can have the run dates edited from the planner instance"
          }
          visible={
            plannerInstanceLevel && !forPlannerInstance ? undefined : false
          }
        >
          {hasValidDateRange(value) && toDateRange(value)}
          {!hasValidDateRange(value) && "Click to select"}
        </Tooltip>
      </div>
    </Popover>
  );
};

export default RunDate;
