import {
  ClockCircleOutlined,
  CloudDownloadOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { useCallback, useEffect } from "react";
import { useAdEngineActions } from "redux/assetExporter/assetExporter.slice";
import { TableActionsContextProvider } from "shared/components/HoverActionsVirtualTable/TableActionsContext";
import { ToolbarButton } from "shared/components/toolbarTable/ToolbarTable";
import { ContextTableActions } from "shared/components/tableActions/ContextTableActions";
import { InlineTableActions } from "shared/components/tableActions/InlineTableActions";
import { useSourceFeedToolbar } from "shared/hooks/assetExporter/useSourceFeedToolbar";
import { FeedTableAttrs } from "shared/types/assetExporter";
import styles from "./SourceFeed.module.scss";
import { ConfigureFeedTable } from "./sourceFeed/ConfigureFeedTable";
import { DeleteFeedsModal } from "./sourceFeed/DeleteFeedsModal";
import FeedVersionsDrawer from "./sourceFeed/FeedVersionsDrawer";
import { NewFeedDrawer } from "./sourceFeed/NewFeedDrawer";
import { SourceFeedToolbar } from "./sourceFeed/SourceFeedToolbar";
import SummarySelected from "./sourceFeed/SummarySelected";

const SourceFeed = () => {
  const { setSelectedFeedIds } = useAdEngineActions();

  const onComponentUnmount = useCallback(() => {
    setSelectedFeedIds([]);
  }, [setSelectedFeedIds]);

  useEffect(() => onComponentUnmount, [onComponentUnmount]);

  const {
    wasFailed,
    isCustomSelected,
    onConfigure,
    onDelete,
    onEdit,
    onVersion,
    onDownloadCSV,
    onPullLatest,
  } = useSourceFeedToolbar();

  const generateToolbarContents: (
    record?: FeedTableAttrs,
  ) => ToolbarButton = record => ({
    Edit: {
      onClick: () => onEdit(record?.feedId),
      showInInlineMenu: true,
      showInContextMenu: true,
      icon: <EditOutlined />,
    },
    Configure: {
      onClick: () => onConfigure(record?.feedId),
      showInInlineMenu: true,
      showInContextMenu: true,
      extraInfo: { iconOnly: true, buttonShape: "circle" },
      icon: <SettingOutlined />,
    },
    Versions: {
      onClick: () => {
        setSelectedFeedIds([record?.feedId ?? ""]);
        onVersion();
      },
      showInInlineMenu: true,
      showInContextMenu: true,
      icon: <ClockCircleOutlined />,
      extraInfo: { iconOnly: true, buttonShape: "circle" },
    },
    DownloadCSV: {
      onClick: () => onDownloadCSV(record?.feedId),
      disabled: wasFailed(record?.feedId),
      showInInlineMenu: true,
      showInContextMenu: true,
      icon: <DownloadOutlined />,
      extraInfo: {
        iconOnly: true,
        buttonShape: "circle",
        title: "Download CSV",
      },
    },
    PullLatest: {
      onClick: () => onPullLatest(record?.feedId),
      disabled: isCustomSelected(record?.feedId),
      showInInlineMenu: true,
      showInContextMenu: true,
      icon: <CloudDownloadOutlined />,
      extraInfo: {
        iconOnly: true,
        buttonShape: "circle",
        title: "Pull Latest",
      },
    },
    Delete: {
      onClick: () => {
        setSelectedFeedIds([record?.feedId ?? ""]);
        onDelete();
      },
      showInInlineMenu: true,
      showInContextMenu: true,
      icon: <DeleteOutlined />,
    },
  });

  return (
    <div className={styles.sourceFeedContainer}>
      <DeleteFeedsModal />
      <SourceFeedToolbar />
      <SummarySelected />
      <TableActionsContextProvider
        renderContextActions={(record?: FeedTableAttrs) => (
          <ContextTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
        renderHoverActions={(record?: FeedTableAttrs) => (
          <InlineTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
      >
        <ConfigureFeedTable />
      </TableActionsContextProvider>
      <NewFeedDrawer />
      <FeedVersionsDrawer />
    </div>
  );
};

export default SourceFeed;
