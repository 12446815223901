import { CSSProperties, ReactNode, useContext } from "react";
import styles from "./FlowItem.module.scss";
import { HandleProps, Position, useStoreState } from "react-flow-renderer";
import { InstantExperienceBodyElement } from "shared/types/adLibrary";
import * as utils from "../../utils";
import useIEContextAPI from "screens/everythingAds.hooks/useContextAPI";
import FlowPlaceholder from "./FlowPlaceholder";
import Handle from "./Handle";

/**
 * FlowItem component is used for those contents inside instant exp. (ex. Image, Button, Video, etc...)
 *
 */
type Props = {
  children?: ReactNode;
  style?: CSSProperties;
  ele?: InstantExperienceBodyElement;
  handlePosition?: HandleProps["position"];
};
const FlowItem = (props: Props) => {
  const [, , zoom] = useStoreState(store => store.transform);
  const zoomedOut = zoom <= 0.5;

  const { Context } = useIEContextAPI();
  const ieContext = useContext(Context);

  return (
    <div
      className={`${styles.flowItem} ${
        (props.ele?.element_type && styles[props.ele.element_type]) ?? ""
      }`}
      style={props.style}
    >
      {props.children}
      {zoomedOut && (
        <div className={styles.flowPlaceholderWrapper}>
          <FlowPlaceholder elementType={props.ele?.element_type} />
        </div>
      )}

      {utils.shouldHaveSourceHandle(props.ele) && (
        <Handle
          id={props.ele?.id || ""}
          position={props.handlePosition || Position.Right}
          type="source"
          isValidConnection={utils.isValidConnection(ieContext?.elements)}
        />
      )}
    </div>
  );
};

export default FlowItem;
