import { memo, useEffect, useState } from "react";
import {
  Input,
  Typography,
  Form,
  Radio,
  Switch,
  DatePicker,
  Button,
  Row,
  Col,
} from "antd";
import { ColorResult } from "react-color";
import ColorPicker from "shared/components/ColorPicker";
import {
  IInstantExperience,
  IInstantExperienceValidations,
} from "shared/types/adLibrary";
import moment, { Moment } from "moment";
import { inputWidths } from "../InstantExperience";
import SingleOemSelect from "shared/components/SingleBrandSelect";
import { NavLink } from "react-router-dom";
import { isFeatureEnabled } from "utils/helpers";

type ThemeOption = "light" | "dark" | "custom";

interface ISettingsForm {
  instantExperienceName: string;
  oem?: string;
  year?: Moment;
  model?: string;
  salesEvent?: string;
  language?: string;

  utm?: string;
  pixel?: string;

  swipeToOpen: boolean;
  themeOption: ThemeOption;
  backgroundColor?: string;
}

interface IProps {
  initialInstantExperience?: IInstantExperience | null;
  setInstantExperience: (instantExperience: IInstantExperience) => void;
  instantExperienceDraftValidations?: IInstantExperienceValidations | null;
  hideGoToDiem?: boolean;
}

const LIGHT_COLOR = "FFFFFF";
const DARK_COLOR = "000000";

const getColorFromTheme = (theme?: ThemeOption, color?: string) => {
  if (theme === "light") {
    return LIGHT_COLOR;
  } else if (theme === "dark") {
    return DARK_COLOR;
  }
  return color;
};

export const defaultSettingsValues: IInstantExperience = {
  name: "",
  swipeToOpen: false,
  backgroundColor: LIGHT_COLOR,
};

const getInitialThemeOption = (backgroundColor?: string): ThemeOption => {
  if (backgroundColor === LIGHT_COLOR || !backgroundColor) {
    return "light";
  }
  if (backgroundColor === DARK_COLOR) {
    return "dark";
  }
  return "custom";
};

const getInitialValues = (
  initialInstantExperience?: IInstantExperience | null,
): ISettingsForm => {
  const themeOption = getInitialThemeOption(
    initialInstantExperience?.backgroundColor,
  );
  return {
    backgroundColor: initialInstantExperience?.backgroundColor,
    instantExperienceName: initialInstantExperience?.name ?? "",
    themeOption,
    swipeToOpen: initialInstantExperience?.swipeToOpen ?? false,
    oem: initialInstantExperience?.oem,
    year: initialInstantExperience?.year
      ? moment(initialInstantExperience?.year, "YYYY")
      : undefined,
    language: initialInstantExperience?.language,
    model: initialInstantExperience?.model,
    salesEvent: initialInstantExperience?.salesEvent,
    utm: initialInstantExperience?.utm,
    pixel: initialInstantExperience?.pixel,
  };
};

function Settings({
  initialInstantExperience,
  instantExperienceDraftValidations,
  setInstantExperience,
  hideGoToDiem,
}: IProps) {
  const initialValues = getInitialValues(initialInstantExperience);
  const [themeOption, setThemeOption] = useState<ThemeOption>(
    initialValues.themeOption,
  );
  const [swipeToOpen, setSwipeToOpen] = useState(initialValues.swipeToOpen);

  const [form] = Form.useForm<ISettingsForm>();

  useEffect(() => {
    if (
      instantExperienceDraftValidations?.settings.displayAlerts &&
      instantExperienceDraftValidations?.settings.hasErrors
    ) {
      form.validateFields();
    }
  }, [
    form,
    instantExperienceDraftValidations?.settings.displayAlerts,
    instantExperienceDraftValidations?.settings.hasErrors,
  ]);

  return (
    <Form<ISettingsForm>
      layout="vertical"
      requiredMark={"optional"}
      form={form}
      initialValues={initialValues}
      onValuesChange={(_, values) => {
        if (!values) {
          return;
        }

        const newInstantExperience: IInstantExperience = {
          ...initialInstantExperience,
          name: values.instantExperienceName,
          swipeToOpen: values.swipeToOpen,
          oem: values.oem,
          year: values.year?.format("YYYY"),
          backgroundColor: getColorFromTheme(
            values.themeOption,
            values.backgroundColor,
          ),
          utm: values.utm,
          pixel: values.pixel,
        };
        setInstantExperience(newInstantExperience);
      }}
    >
      <Row gutter={15} justify="start">
        <Col flex={1} style={inputWidths}>
          <div style={{ marginBottom: 10 }}>
            <Typography.Text strong>General</Typography.Text>
          </div>
          <Form.Item
            name="instantExperienceName"
            label="Everything Ad Name"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please input a name",
              },
            ]}
          >
            <Input placeholder="Everything Ad Name" />
          </Form.Item>
          <Form.Item label="Theme" name="themeOption" required>
            <Radio.Group
              onChange={e => {
                const themeOptionValue: ThemeOption = e.target.value;
                setThemeOption(themeOptionValue);
                if (themeOptionValue !== "custom") {
                  form.setFieldsValue({
                    backgroundColor: getColorFromTheme(themeOptionValue),
                  });
                }
              }}
            >
              <Radio.Button value="light">Light</Radio.Button>
              <Radio.Button value="dark">Dark</Radio.Button>
              <Radio.Button value="custom">Custom</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="backgroundColor"
            valuePropName="color"
            required
            getValueFromEvent={(color: ColorResult) =>
              color.hex.toUpperCase().replace("#", "")
            }
            label="Background color"
            hidden={themeOption !== "custom"}
          >
            <ColorPicker displayColorInput />
          </Form.Item>
          <div
            style={{
              marginRight: "10px",
              display: "inline-flex",
              height: 30,
              alignItems: "center",
            }}
          >
            Swipe to open{" "}
          </div>
          <Form.Item
            name="swipeToOpen"
            valuePropName="checked"
            required
            style={{
              display: "inline-flex",
              flexDirection: "row-reverse",
              alignItems: "center",
            }}
            labelCol={{ style: { padding: "0 10px" } }}
            label={swipeToOpen ? "ON" : "OFF"}
            labelAlign="right"
            tooltip={{
              placement: "right",
              title:
                "When people reach the end of your Everything Ad, they can continue swiping to open the final link. The last component of your Everything Ads needs to contain a valid link to a website or app.",
            }}
          >
            <Switch onChange={setSwipeToOpen} />
          </Form.Item>
          {!hideGoToDiem && isFeatureEnabled("ENABLE_DIEM") && (
            <Form.Item name="DIEM" label="Everything Ad Mapper">
              <Button>
                <NavLink
                  target="_blank"
                  to={`/everything-ads/mapper?source=${initialInstantExperience?.id}`}
                >
                  Go to Mapper
                </NavLink>
              </Button>
            </Form.Item>
          )}
        </Col>
        <Col flex={1} style={inputWidths}>
          <div style={{ marginBottom: 10 }}>
            <Typography.Text strong>Alexia</Typography.Text>
          </div>
          <Form.Item
            name="oem"
            label="Brand"
            rules={[{ required: true, message: "Please select a brand" }]}
            valuePropName="selectedBrand"
          >
            <SingleOemSelect />
          </Form.Item>
          <Form.Item name="year" label="Year">
            <DatePicker
              picker="year"
              style={{
                width: "100%",
                ...inputWidths,
              }}
            />
          </Form.Item>
          <Form.Item name="pixel" label="Pixel ID">
            <Input placeholder="Enter Pixel ID" allowClear />
          </Form.Item>
          <Form.Item name="utm" label="UTM">
            <Input.TextArea placeholder="Enter UTM" rows={4} allowClear />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default memo(Settings);
