import { useCallback, useEffect, useState } from "react";
import {
  Connection,
  Edge,
  addEdge,
  removeElements,
  isEdge,
} from "react-flow-renderer";
import {
  FlowEventCallbacks,
  EAElements,
  EAFlowElement,
  OnIEConnectFunc,
  OnIEElemtnsRemoveFunc,
  OnRemoveEdgeFunc,
} from "./useContextAPI";
import {
  getUpdatedElementsOnConnect,
  getUpdatedElementsOnRemoveEdge,
  getUpdatedElementsOnRemove,
  updateReferences,
} from "./utils";

type ReturnType = {
  selectedFlowElement?: EAFlowElement;
  setSelectedFlowElement?: React.Dispatch<
    React.SetStateAction<EAFlowElement | undefined>
  >;
  elements: EAElements;
  setElements: React.Dispatch<React.SetStateAction<EAElements>>;
  updatedElements: EAElements;
  eventCallbacks: FlowEventCallbacks;
};

type OnEdgeUpdateParams = {
  oldEdge: Edge;
  newConnection: Connection;
};
type OnRemoveEdgeParams = {
  edge: Edge;
};
type OnFlowUpdate = {
  type: "OnConnect" | "OnElementsRemove" | "OnRemoveEdge";
  params:
    | Edge
    | Connection
    | EAElements
    | OnEdgeUpdateParams
    | OnRemoveEdgeParams;
};

export default (): ReturnType => {
  const defaultElements: EAElements = [];
  const [elements, setElements] = useState<EAElements>(defaultElements);
  const [updatedElements, setUpdatedElements] = useState<EAElements>([]);
  const [onFlowUpdate, setOnFlowUpdate] = useState<OnFlowUpdate>();
  const onConnect: OnIEConnectFunc = edgeParams => {
    setElements(ele =>
      updateReferences(
        addEdge(
          {
            ...edgeParams,
            type: "deletable",
          },
          ele,
        ),
      ),
    );
    // When instances are connected, we need to update the source IE instance's reference
    setOnFlowUpdate({
      type: "OnConnect",
      params: edgeParams,
    });
  };

  // NOTE: here, the elements passed to OnIEElemtnsRemoveFunc is EAElements | Edge[]
  const onElementsRemove: OnIEElemtnsRemoveFunc = removedElements => {
    setElements(ele => updateReferences(removeElements(removedElements, ele)));

    // When an instances are removed, we need to delete reference on every source instances's body elements and
    //  references that all of body elements under this instance.
    setOnFlowUpdate({
      type: "OnElementsRemove",
      params: removedElements,
    });
  };

  const onRemoveEdge: OnRemoveEdgeFunc = (id: string) => {
    setElements(elements => {
      const theEdge = elements.find(
        ele => isEdge(ele) && ele.id === id,
      ) as Edge;
      if (!theEdge) return elements;

      const updated = getUpdatedElementsOnRemoveEdge(theEdge, elements);

      return elements
        .filter(ele => ele.id !== id)
        .map(ele => {
          const updatedEle = updated?.find(
            updatedEle => updatedEle.id === ele.id,
          );
          return updatedEle ?? ele;
        });
    });

    const edge = elements
      .filter(ele => isEdge(ele))
      .find(ele => ele.id === id) as Edge;
    if (!edge) return; // error????

    // When an edge is removed, the reference of source instance's body element has to be updated.
    setOnFlowUpdate({
      type: "OnRemoveEdge",
      params: {
        edge,
      },
    });
  };

  const [selectedFlowElement, setSelectedFlowElement] =
    useState<EAFlowElement>();

  const onSelectionChange = useCallback((elements: EAElements | null) => {
    const [selected] = elements || [];
    setSelectedFlowElement(selected);
  }, []);

  useEffect(() => {
    if (!onFlowUpdate) return;

    // NOTE: make sure we clear the onFlowUpdate state after the process
    let updatedElements: EAElements | undefined;
    switch (onFlowUpdate.type) {
      case "OnConnect":
        updatedElements = getUpdatedElementsOnConnect(
          onFlowUpdate.params as Edge | Connection,
          elements,
        );
        if (!updatedElements) return;

        break;

      case "OnElementsRemove":
        updatedElements = getUpdatedElementsOnRemove(
          onFlowUpdate.params as EAElements,
          elements,
        );

        break;

      case "OnRemoveEdge":
        const params = onFlowUpdate.params as OnRemoveEdgeParams;
        updatedElements = getUpdatedElementsOnRemoveEdge(params.edge, elements);

        break;
    }

    if (!updatedElements) return;

    setUpdatedElements(elements => {
      return [
        ...elements.filter(
          ele => !updatedElements!.some(updated => updated.id === ele.id),
        ),
        ...updatedElements!,
      ];
    });

    setOnFlowUpdate(undefined);
  }, [elements, onFlowUpdate]);

  return {
    selectedFlowElement,
    setSelectedFlowElement,
    elements,
    setElements,
    updatedElements,
    eventCallbacks: {
      onConnect,
      onElementsRemove,
      onRemoveEdge,
      onSelectionChange,
    },
  };
};
