import { Divider } from "antd";
import AdsTree from "./AdsTree";
import { AdsTreeProvider } from "./adsTree/adsTreeContext";
import AssignValuesTable from "./AssignValuesTable";

const AssignValuesSection = () => {
  return (
    <AdsTreeProvider>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          gap: 15,
        }}
      >
        <AdsTree />
        <Divider
          type="vertical"
          style={{ alignSelf: "stretch", height: "auto" }}
        />
        <AssignValuesTable />
      </div>
    </AdsTreeProvider>
  );
};

export default AssignValuesSection;
