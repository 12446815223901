import { isEqual } from "lodash";
import { useEffect } from "react";
import { usePrevious } from "utils/helpers.hooks";

export default (func: any, dependencies: any) => {
  const prevDependencies = usePrevious<any>(dependencies);
  useEffect(() => {
    if (!isEqual(prevDependencies, dependencies)) func();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevDependencies, dependencies]);
};
