import {
  UserPreferences,
  URLParamToPropDict,
  PropToURLParamDict,
  PreviewFormatParamDict,
} from "shared/types/userPreferences";

export const initialPreferences: UserPreferences = {
  AD_LIBRARY: {
    ADS: {
      selectedAdIds: [],
      selectedColumns: [
        "Name",
        "Ad Format",
        "Status",
        "Campaign Planners",
        "Created",
        "Last Edit",
        "Last Edit By",
        "Tags",
        "Ad URL",
        "Destination URL",
      ],
    },
  },
};

export const urlParamToPropDict: URLParamToPropDict = {
  store: "selectedStore",
  dates: "selectedDateRange",
  layout: "layout",
  preview: "previewFormat",
  ids: "selectedAdIds",
  columns: "selectedColumns",
};

export const propToUrlParamDict: PropToURLParamDict = {
  selectedStore: "store",
  selectedDateRange: "dates",
  layout: "layout",
  previewFormat: "preview",
  selectedAdIds: "ids",
  selectedColumns: "columns",
};

export const previewFormatParamDict: PreviewFormatParamDict = {
  DESKTOP_FEED_STANDARD: "desktop",
  MOBILE_FEED_STANDARD: "mobile",
  INSTAGRAM_STANDARD: "instagram",
};

export const sortingProps = ["createdAt", "updatedAt"];
