/* eslint-disable react/display-name */
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Col, Modal, Row, Switch, Table } from "antd";
import { ColumnProps } from "antd/es/table";
import { ReactElement, useEffect, useState } from "react";

import { useAppDispatch } from "shared/hooks/useAppDispatch";

import { IAccountRecord } from "shared/types/accountManagement";
import { FeedType } from "shared/types/configuration";
import { TableRowSelection } from "antd/es/table/interface";
import { filterBySearch } from "utils/helpers.toolbar";

import ToolbarTable, {
  ToolbarButton,
} from "shared/components/toolbarTable/ToolbarTable";

import { industryType, filterEnableBy } from "utils/helpers";

import {
  FilterIcon,
  SearchFilterDropdown,
} from "shared/components/SearchFilterDropdown";

import { deleteDealer } from "redux/dealerManagement/dealerManagement.slice";
import actions from "redux/rootActions";
import EditCell from "screens/userManagement/userTable/EditCell";
import IntermediateSwitch from "shared/button/IntermediateSwitch";
import { useIsAdmin } from "shared/hooks/useIsAdmin";
import {
  compareStringBy,
  getFiltersFromData,
  onFilterBy,
} from "utils/helpers.table";

interface IDealerProps {
  userRole: string;
  dealers: IAccountRecord[];
  dealersMessage: string;
  feed: FeedType;
}

interface IDealerHandlers {
  onClickNew: () => void;
  onClickEdit: (record: IAccountRecord) => void;
  onClickDuplicate: (record: IAccountRecord) => void;
}

type PropsType = IDealerProps & IDealerHandlers;

const DealerTable = ({
  userRole,
  dealers,
  dealersMessage,
  feed,
  onClickNew,
  onClickEdit,
  onClickDuplicate,
}: PropsType): ReactElement => {
  const isAdmin = useIsAdmin();
  const dispatch = useAppDispatch();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [filteredData, setFilteredData] = useState<IAccountRecord[]>(
    dealers || [],
  );
  const [currentRows, setCurrentRows] = useState<IAccountRecord[]>([]);
  const [currentActiveRows, setCurrentActiveRows] = useState<IAccountRecord[]>(
    [],
  );
  const [currentInactiveRows, setCurrentInactiveRows] = useState<
    IAccountRecord[]
  >([]);
  const [searchValue, setSearchValue] = useState<string>();
  const [inputTimer, setInputTimer] = useState<NodeJS.Timeout | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!dealers || dealers.length === 0) return;
    setFilteredData(dealers);
  }, [dealers]);

  useEffect(() => {
    if (!dealersMessage) {
      return;
    }
  }, [dealersMessage]);

  useEffect(() => {
    // searchValue === undefined indicates the component has not been mounted.
    // Then, we should not filter any users.
    if (searchValue === undefined) return;

    if (inputTimer) clearTimeout(inputTimer);

    const tmpTimer = setTimeout(() => {
      const updatedDealers = filterBySearch(dealers, searchValue);
      setFilteredData(updatedDealers);
    }, 1000);

    setInputTimer(tmpTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const onClickToggleActive = async (
    record: IAccountRecord,
    valToUpdate: boolean,
  ) => {
    const updateRecord = {
      ...record,
      enabled: valToUpdate,
    };
    await dispatch(actions.dealerManagement.updateDealer(updateRecord));
  };

  const onClickToggleActiveAll = async (
    records: IAccountRecord[],
    valToUpdate: boolean,
  ) => {
    const promises = records.map((record, i) => {
      const updateDealer = { ...record, enabled: valToUpdate };
      const isFinalRecord = i === records.length - 1;
      return dispatch(
        actions.dealerManagement.updateDealer(updateDealer, isFinalRecord),
      );
    });
    await Promise.all(promises);
  };

  const isAllActive = !filteredData.some(
    dealer => !filterEnableBy(dealer, true),
  );

  const isAllInactive = !filteredData.some(
    dealer => !filterEnableBy(dealer, false),
  );

  const onChangeEnabled = async (
    record: IAccountRecord,
    valToUpdate: boolean,
  ) => {
    !isAdmin && (await onClickToggleActive(record, valToUpdate));
    if (currentRows.some(row => row.key === record.key)) {
      if (valToUpdate) {
        setCurrentActiveRows([...currentActiveRows, record] ?? []);
        setCurrentInactiveRows(
          [...currentInactiveRows.filter(row => row.key !== record.key)] ?? [],
        );
      } else {
        setCurrentActiveRows(
          [...currentActiveRows.filter(row => row.key !== record.key)] ?? [],
        );
        setCurrentInactiveRows([...currentInactiveRows, record] ?? []);
      }
    }
  };

  const onClickIntermediateSwitch = () =>
    !isAdmin &&
    onClickToggleActiveAll(
      filteredData.filter(dealer => filterEnableBy(dealer, false)),
      true,
    );

  const onChangeTitleSwitch = (valToUpdate: boolean) => {
    !isAdmin &&
      onClickToggleActiveAll(
        filteredData.filter(dealer => !filterEnableBy(dealer, valToUpdate)),
        valToUpdate,
      );
  };

  const getFilters = getFiltersFromData(dealers);

  const columns: Array<ColumnProps<IAccountRecord>> = [
    {
      key: "enabled",
      title: () =>
        !isAllActive && !isAllInactive ? (
          <IntermediateSwitch onClick={onClickIntermediateSwitch} />
        ) : (
          <Switch
            onChange={valToUpdate => onChangeTitleSwitch(valToUpdate)}
            checked={isAllActive}
          />
        ),
      width: "8%",
      render: record => (
        <Switch
          disabled={isAdmin}
          onChange={valToUpdate => onChangeEnabled(record, valToUpdate)}
          checked={record.enabled ?? false}
        />
      ),
    },
    {
      key: "dealerName",
      title: (
        <strong>
          {industryType("auto") ? "Store Name (DBA)" : "Account Name"}
        </strong>
      ),
      dataIndex: "dealerName",
      width: "25%",
      sorter: compareStringBy("dealerName"),
      filterDropdown: SearchFilterDropdown,
      filterIcon: FilterIcon,
      onFilter: onFilterBy("dealerName", {
        matchMode: "includes",
        caseInsensitive: true,
      }),
      render: (name: string, record: IAccountRecord) => (
        <EditCell
          disabled={isAdmin}
          name={name}
          onCellClick={() => !isAdmin && onClickEdit(record)}
        />
      ),
    },
    industryType("auto")
      ? {
          key: "dealerCode",
          title: (
            <strong>
              {feed !== "ladtech"
                ? "Store Name (Dealer ID)"
                : "Store Name (2-3-3)"}
            </strong>
          ),
          dataIndex: "dealerCode",
          width: "15%",
          sorter: compareStringBy("dealerCode"),
          filters: getFilters("dealerCode"),
          onFilter: onFilterBy("dealerCode"),
        }
      : {},
    {
      key: "dealerUsState",
      title: <strong>Location</strong>,
      dataIndex: "dealerUsState",
      width: "10%",
      sorter: compareStringBy("dealerUsState"),
      filters: getFilters("dealerUsState"),
      onFilter: onFilterBy("dealerUsState"),
    },
    {
      key: "dealerOem",
      title: (
        <strong>{industryType("auto") ? "Store OEM(s)" : "Brand(s)"}</strong>
      ),
      dataIndex: "dealerOem",
      width: "20%",
      sorter: compareStringBy("dealerOem"),
      filters: getFilters("dealerOem", { splitBy: "," }),
      onFilter: onFilterBy("dealerOem", {
        matchMode: "includes",
      }),
    },
    {
      key: "dealerUrl",
      title: (
        <strong>{industryType("auto") ? "Store URL" : "Account URL"}</strong>
      ),
      dataIndex: "dealerUrl",
      width: "40%",
      sorter: compareStringBy("dealerUrl"),
      filterDropdown: SearchFilterDropdown,
      filterIcon: FilterIcon,
      onFilter: onFilterBy("dealerUrl", {
        caseInsensitive: true,
        matchMode: "includes",
      }),
    },
    {
      key: "fbAccountId",
      title: <strong>FB Account ID</strong>,
      dataIndex: ["details", "facebook", "fbAccountId"],
      width: "15%",
      sorter: compareStringBy(dealer => dealer.details?.facebook?.fbAccountId),
      filterDropdown: SearchFilterDropdown,
      filterIcon: FilterIcon,
      onFilter: onFilterBy(dealer => dealer.details?.facebook?.fbAccountId, {
        matchMode: "includes",
      }),
    },
    {
      key: "fbPageId",
      title: <strong>FB Page ID</strong>,
      dataIndex: ["details", "facebook", "fbPageId"],
      width: "15%",
      sorter: compareStringBy(dealer => dealer.details?.facebook?.fbPageId),
      filterDropdown: SearchFilterDropdown,
      filterIcon: FilterIcon,
      onFilter: onFilterBy(dealer => dealer.details?.facebook?.fbPageId, {
        matchMode: "includes",
      }),
    },

    {
      key: "fbCatalogId",
      title: <strong>Catalog ID</strong>,
      dataIndex: ["details", "facebook", "fbCatalogId"],
      width: "15%",
      sorter: compareStringBy(dealer => dealer.details?.facebook?.fbCatalogId),
      filterDropdown: SearchFilterDropdown,
      filterIcon: FilterIcon,
      onFilter: onFilterBy(dealer => dealer.details?.facebook?.fbCatalogId, {
        matchMode: "includes",
      }),
    },
    {
      key: "fbPixelId",
      title: <strong>FB Pixel ID</strong>,
      dataIndex: ["details", "facebook", "fbPixelId"],
      width: "15%",
      sorter: compareStringBy(dealer => dealer.details?.facebook?.fbPixelId),
      filterDropdown: SearchFilterDropdown,
      filterIcon: FilterIcon,
      onFilter: onFilterBy(dealer => dealer.details?.facebook?.fbPixelId, {
        matchMode: "includes",
      }),
    },
    {
      key: "fbInstagramId",
      title: <strong>FB Instagram ID</strong>,
      dataIndex: ["details", "facebook", "fbInstagramId"],
      width: "15%",
      sorter: compareStringBy(
        dealer => dealer.details?.facebook?.fbInstagramId,
      ),
      filterDropdown: SearchFilterDropdown,
      filterIcon: FilterIcon,
      onFilter: onFilterBy(dealer => dealer.details?.facebook?.fbInstagramId, {
        matchMode: "includes",
      }),
    },
  ];

  const rowSelection: TableRowSelection<IAccountRecord> = {
    selectedRowKeys,
    onChange: (keys, rows) => {
      setSelectedRowKeys(keys);
      setCurrentRows(rows);
      setCurrentActiveRows(rows.filter(row => filterEnableBy(row, true)) ?? []);
      setCurrentInactiveRows(
        rows.filter(row => filterEnableBy(row, false)) ?? [],
      );
    },
  };
  const singleSelected = selectedRowKeys && selectedRowKeys.length === 1;
  const hasSelected = selectedRowKeys && selectedRowKeys.length > 0;

  const resetSelection = () => setSelectedRowKeys([]);
  const onClickDelete = (record: IAccountRecord) => {
    Modal.confirm({
      title: "Are you sure you want to delete this store?",
      icon: <ExclamationCircleOutlined />,
      className: "confirm-store-delete-button",
      onOk() {
        resetSelection();
        dispatch(deleteDealer(record));
      },
      okType: "danger",
    });
  };

  const selectedAllActive = currentInactiveRows.length === 0;
  const selectedAllInactive = currentActiveRows.length === 0;

  const onClickActiveAll = async () => {
    if (!selectedAllActive) {
      await onClickToggleActiveAll(currentInactiveRows, !selectedAllActive);
      setCurrentActiveRows([...currentActiveRows, ...currentInactiveRows]);
      setCurrentInactiveRows([]);
    } else {
      await onClickToggleActiveAll(currentActiveRows, !selectedAllActive);
      setCurrentInactiveRows([...currentActiveRows, ...currentInactiveRows]);
      setCurrentActiveRows([]);
    }
  };

  const onClickActiveSome = async () => {
    await onClickToggleActiveAll(currentRows, true);
    setCurrentActiveRows(currentRows);
    setCurrentInactiveRows([]);
  };

  const toolbarContents: ToolbarButton = {
    New: {
      // extraInfo: {
      //   FULL,
      // },
      disabled: !isAdmin,
      onClick: onClickNew,
    },
    Edit: {
      disabled: !singleSelected || !isAdmin,
      onClick: () => onClickEdit(currentRows[0]),
    },
    Duplicate: {
      disabled: !singleSelected || !isAdmin,
      onClick: () => onClickDuplicate(currentRows[0]),
    },
    Delete: {
      disabled: !singleSelected || !isAdmin,
      onClick: () => onClickDelete(currentRows[0]),
    },
    ActiveAll: {
      display: selectedAllActive || selectedAllInactive,
      disabled: !hasSelected || !isAdmin,
      onClick: () => onClickActiveAll(),
      checked: hasSelected && selectedAllActive,
    },
    ActiveSome: {
      display: !selectedAllActive && !selectedAllInactive,
      disabled: !hasSelected || !isAdmin,
      onClick: () => onClickActiveSome(),
    },
  };

  return (
    <Row style={{ padding: "15px 15px 0" }}>
      <Col span={24}>
        <ToolbarTable
          toolbarContents={toolbarContents}
          searchPlaceholder="Search"
          searchValue={searchValue || ""}
          onSearch={setSearchValue}
          titleTooltip="Search by any information"
        />
        <Row>
          <Col span={24}>
            <Table<IAccountRecord>
              data-cy="dealer-table-result"
              size="small"
              scroll={{ x: 1000, y: "calc(100vh - 230px)" }}
              columns={columns}
              dataSource={filteredData}
              pagination={false}
              rowSelection={rowSelection}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DealerTable;
