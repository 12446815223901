import * as React from "react";
import FlowItem from "screens/everythingAds/Mapper/shared/FlowItem";

const NoElement = () => {
  return (
    <FlowItem>
      <div>No elements</div>
    </FlowItem>
  );
};

export default NoElement;
