import { useCallback } from "react";
import { useQueryClient } from "react-query";

import { useAppDispatch } from "shared/hooks/useAppDispatch";
import {
  setAdLoadState,
  toggleIsAdLoadVisible,
  setSelectedFacebookAccounts,
} from "redux/adLibrary/adLibrary.slice";

import { initAdLoadParameters } from "screens/adLibrary/adLoad/utils";

type ClearAdLoadStateArgs = {
  currentSessionId?: string;
};

const useClearAdLoadState = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  return useCallback(
    (args: ClearAdLoadStateArgs) => {
      dispatch(toggleIsAdLoadVisible(false));
      dispatch(setSelectedFacebookAccounts(null));
      dispatch(
        setAdLoadState({
          data: {
            currentStep: null,
            adLoadParameters: initAdLoadParameters,
            adLoadHistory: null,
            selectedAds: null,
            facebookAdLoadDict: null,
            facebookDataIdsByAd: null,
            selectedAdForParameters: null,
            prevSelectedAdForParameters: null,
          },
        }),
      );

      queryClient.removeQueries([
        "adHistorySessionJson",
        args.currentSessionId ?? null,
      ]);
      queryClient.removeQueries([
        "adHistorySessions",
        args?.currentSessionId ?? null,
      ]);
    },
    [dispatch, queryClient],
  );
};

export default useClearAdLoadState;
