import { SwitchProps } from "antd";
import IndeterminateSwitch from "shared/button/IndeterminateSwitch";

interface IProps {
  checked?: boolean;
  disabled?: boolean;
  isForMultiple?: boolean;
  indeterminate?: boolean;
  size?: SwitchProps["size"];
}
interface IHandlers {
  onCheckedChange: (checked: boolean) => void;
  onIndeterminateClick?: () => void;
}

type FacebookAdStatusSwitchProps = IProps & IHandlers;

const returnTooltipTitle = (args: FacebookAdStatusSwitchProps) => {
  if (args.disabled) return "";
  if (args.indeterminate) return "Load Inactive and Active Ads";
  if (args.checked) return "Load as Active";
  return "Load as Inactive";
};

const FacebookAdStatusSwitch = (props: FacebookAdStatusSwitchProps) => {
  return (
    <IndeterminateSwitch
      size={props.size}
      checked={props.checked}
      disabled={props.disabled}
      tooltipTitle={returnTooltipTitle(props)}
      indeterminate={props.indeterminate}
      onIndeterminateClick={props.onIndeterminateClick}
      onChange={props.onCheckedChange}
    />
  );
};

export default FacebookAdStatusSwitch;
