import { memo, useEffect, useState } from "react";
import { Form, InputNumber, Select, Space, Typography } from "antd";
import DynamicVariablesInput from "shared/components/DynamicVariablesInput";

import {
  initCatalogType,
  catalogTypeValidator,
} from "utils/facebook/helpers.productset";
import { useForm } from "antd/lib/form/Form";

import { DynamicCatalogType } from "shared/components/DynamicVariableSelect";

export interface IProductSetComponentForm {
  maxProducts?: number;
  itemHeadline?: string;
  itemDescription?: string;
}

interface IProps {
  initialValues: IProductSetComponentForm;
  onValuesChange(values?: IProductSetComponentForm): void;
}

const ProductSetComponentForm = ({ initialValues, onValuesChange }: IProps) => {
  const [form] = useForm<IProductSetComponentForm>();
  const [catalog, setCatalog] = useState<DynamicCatalogType>(initCatalogType());

  // Validate fields on catalog change
  useEffect(() => {
    const fields = [];
    const headline = form.getFieldValue("itemHeadline");
    const description = form.getFieldValue("itemDescription");
    if (!!headline?.length) fields.push("itemHeadline");
    if (!!description?.length) fields.push("itemDescription");
    form.validateFields(fields);
  }, [catalog, form]);

  return (
    <Form<IProductSetComponentForm>
      form={form}
      layout="vertical"
      requiredMark={"optional"}
      initialValues={initialValues}
      onValuesChange={(_, allValues) => onValuesChange(allValues)}
    >
      <Form.Item>
        <Typography.Title level={4}>Product Set</Typography.Title>
        <Typography.Paragraph>
          Input the product set parameters to show in your Instant Experience.
        </Typography.Paragraph>
      </Form.Item>
      <Form.Item
        style={{ maxWidth: 320 }}
        label="Select the type of catalog variables"
        required
      >
        <Select value={catalog} onChange={setCatalog} placeholder="Select one">
          <Select.Option value="PRODUCT_CATALOG">Product Catalog</Select.Option>
          <Select.Option value="AUTOMOTIVE_CATALOG">
            Automotive Catalog
          </Select.Option>
          <Select.Option value="TRAVEL_CATALOG">Travel Catalog</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="maxProducts"
        label="Display up to"
        getValueFromEvent={(value: number) => {
          if (!value) {
            return 50;
          }
          return parseInt(value.toString());
        }}
        rules={[{ required: true, message: "Please add the max products" }]}
      >
        <InputNumber min={1} max={50} />
      </Form.Item>
      <Space style={{ width: "100%" }}>
        <Form.Item
          name="itemHeadline"
          label="Product Headline"
          rules={[
            {
              max: 255,
              message: "Product Headline cannot exceed 255 characters",
            },
            {
              validator: (_, value) => catalogTypeValidator(value, catalog),
            },
          ]}
        >
          <DynamicVariablesInput catalog={catalog} />
        </Form.Item>
      </Space>
      <Space style={{ width: "100%" }}>
        <Form.Item
          name="itemDescription"
          label="Product Description"
          rules={[
            {
              validator: (_, value) => catalogTypeValidator(value, catalog),
            },
          ]}
        >
          <DynamicVariablesInput catalog={catalog} />
        </Form.Item>
      </Space>
    </Form>
  );
};

export default memo(ProductSetComponentForm);
