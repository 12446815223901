import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Form, Input, Tooltip, Typography } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { UploadFile } from "antd/lib/upload/interface";
import ResizeObserver from "rc-resize-observer";
import {
  AccountEdit,
  IBrandFormValues,
  ILogos,
} from "shared/types/brandsAccounts";
import { imageAssetDetail } from "shared/constants/brandsAccounts";
import {
  brandFontSchema,
  getUploadsSchema,
} from "shared/schemas/brandsAccounts";
import UploadAsset, {
  IOnUploadAssedChange,
} from "shared/components/uploadAsset/UploadAsset";
import UploadFont from "shared/components/uploadAsset/UploadFont";
import { YoutubeIntegration } from "screens/platformManagement/YoutubeIntegration";
import { BaseDrawer } from "./BaseDrawer";
import { getUploadAssetList } from "./BrandDrawer.utils";
import { BrandsAccountsTable } from "../BrandsAccountsTable";
import { useBrandsAccountsContext } from "../contexts/BrandsAccountsContext";
import { AccountDrawer } from "./account/AccountDrawer";
import styles from "./BrandDrawer.module.scss";

interface Props {
  onCancel: () => void;
  visible: boolean;
  onSubmit: (youtubeConnectedNewValue?: boolean) => Promise<void>;
  mutationIsLoading: boolean;
  buttonText: string;
  drawerTitle: string;
  logos: ILogos;
  fonts: UploadFile[];
  form: FormInstance<IBrandFormValues>;
  setLogos: React.Dispatch<React.SetStateAction<ILogos>>;
  setFonts: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  onRemoveLogo?: (url: string, fieldKey: keyof ILogos) => void;
  onRemoveFont?: (url: string) => void;
  nameToEdit?: string;
  onDelete?: () => void;
  youtubeConnected?: boolean;
  setYoutubeConnected?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BrandBaseDrawer = ({
  nameToEdit,
  logos,
  setLogos,
  onSubmit,
  mutationIsLoading,
  buttonText,
  drawerTitle,
  form,
  onRemoveLogo,
  onRemoveFont,
  setFonts,
  fonts,
  youtubeConnected,
  setYoutubeConnected,
  ...props
}: Props) => {
  const accountDrawerRef = useRef<HTMLDivElement>(null);
  const [accountDrawerWidth, setAccountDrawerWidth] = useState(0);

  useLayoutEffect(() => {
    if (accountDrawerRef.current) {
      setAccountDrawerWidth(accountDrawerRef.current.offsetWidth);
    }
  }, [setAccountDrawerWidth, accountDrawerRef]);

  const nameNotEditableMessage =
    "Once you click ”Save”, ”Brand name” will not be editable";

  const onLogosChange = useCallback(
    ({ isAdding, fieldKey, updatedFile }: IOnUploadAssedChange<ILogos>) => {
      setLogos(prev => ({
        ...prev,
        [fieldKey]: isAdding
          ? [...(prev?.[fieldKey] ?? []), updatedFile]
          : prev[fieldKey]?.filter(f => f.uid !== updatedFile.uid),
      }));

      if (!isAdding && !!onRemoveLogo && !!updatedFile.url) {
        onRemoveLogo(updatedFile.url, fieldKey);
      }
    },
    [setLogos, onRemoveLogo],
  );

  const onFontsChange = useCallback(
    ({ isAdding, file }: { isAdding: boolean; file: UploadFile }) => {
      setFonts?.(prev =>
        isAdding ? [...prev, file] : prev.filter(f => f.uid !== file.uid),
      );

      if (!isAdding && !!onRemoveFont && !!file.url) {
        onRemoveFont(file.url);
      }
    },
    [setFonts, onRemoveFont],
  );

  const validateForm = useCallback(async () => {
    try {
      await form.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  }, [form]);

  const onSubmitForm = useCallback(async () => {
    const isValid = await validateForm();
    if (isValid) {
      onSubmit();
    }
  }, [validateForm, onSubmit]);

  const {
    drawerState,
    onlyAccountsMode,
    accountDrawerOnBrandDrawer,
    setAccountDrawerOnBrandDrawer,
  } = useBrandsAccountsContext();

  const accountsToDisplayForAccountsMode = useMemo(() => {
    if (!onlyAccountsMode) return [];
    if (
      typeof drawerState.targetElement !== "string" &&
      drawerState.targetElement?.type === "Brand"
    )
      return drawerState.targetElement.children;

    return [];
  }, [drawerState.targetElement, onlyAccountsMode]);

  const drawerWidth = useMemo(() => {
    if (accountDrawerOnBrandDrawer.drawerMode === "NONE") return "90%";

    return accountDrawerWidth ? (accountDrawerWidth + 200).toString() : "90%";
  }, [accountDrawerOnBrandDrawer.drawerMode, accountDrawerWidth]);

  return (
    <>
      <ResizeObserver
        onResize={() =>
          setAccountDrawerWidth(accountDrawerRef.current?.clientWidth ?? 0)
        }
      >
        <BaseDrawer
          {...props}
          clickButtonText={buttonText}
          onClick={onSubmitForm}
          title={drawerTitle}
          width={drawerWidth}
          buttonDisabled={mutationIsLoading}
          buttonLoading={mutationIsLoading}
        >
          <Form
            name="brand-form"
            form={form}
            layout="inline"
            id="brandForm"
            onChange={validateForm}
            className={styles.form}
          >
            <div className={styles.formColumn}>
              <Form.Item
                label="Brand name"
                data-cy="brand-name"
                name="name"
                className={styles.formItem}
                colon={false}
                labelAlign="left"
                initialValue={nameToEdit}
                validateStatus={
                  form.getFieldError("name").some(Boolean) ? "error" : undefined
                }
                help={
                  drawerState.drawerMode === "UPDATE_BRAND"
                    ? nameNotEditableMessage
                    : "brand name is required"
                }
                required
                rules={[
                  {
                    required: true,
                    message: "brand name is required",
                  },
                ]}
              >
                <Tooltip
                  title={"Once saved, ”Brand name” is not editable"}
                  visible={!!nameToEdit ? undefined : false}
                >
                  <div>
                    <Input
                      disabled={!!nameToEdit}
                      value={nameToEdit}
                      onChange={event =>
                        form.setFieldsValue({ name: event.target.value })
                      }
                    />
                  </div>
                </Tooltip>
              </Form.Item>

              {nameToEdit && setYoutubeConnected && (
                <Form.Item
                  initialValue={youtubeConnected}
                  className={`${styles.formItem} ${styles.integrationsContainer}`}
                  name={"youtubeConnected"}
                >
                  <span className={styles.logosTitle}>Integrations</span>
                  <YoutubeIntegration
                    oem={nameToEdit}
                    connected={youtubeConnected ?? false}
                    setConnection={(connection: boolean) => {
                      setYoutubeConnected(connection);
                      form.setFieldsValue({ youtubeConnected: connection });
                      onSubmitForm();
                    }}
                    moduleUrl="brands-accounts-management"
                  />
                </Form.Item>
              )}

              <div className={styles.logosContainer}>
                <span className={styles.logosTitle}>Logos</span>
                {getUploadAssetList(logos).map(
                  ({ title, fileList, maxLength, key }, idx) => (
                    <UploadAsset
                      key={`${title}-${idx}`}
                      title={title}
                      fileList={fileList}
                      maxLength={maxLength}
                      fieldKey={key}
                      onUploadChange={onLogosChange}
                      imageAssetDetail={imageAssetDetail}
                      validationSchema={getUploadsSchema(title)}
                    />
                  ),
                )}
              </div>
              <div className={styles.logosContainer}>
                <span className={styles.logosTitle}>Fonts</span>
                <UploadFont
                  title="fontToUpload"
                  maxLength={10}
                  fileList={fonts ?? []}
                  onUploadChange={onFontsChange}
                  validationSchema={brandFontSchema}
                />
              </div>
            </div>
            <div className={styles.accountsColumn}>
              {!!nameToEdit && (
                <>
                  <Typography.Title level={5} className={styles.title}>
                    Accounts
                  </Typography.Title>
                  <BrandsAccountsTable
                    tableDataCy="accounts-table"
                    accountsToDisplayForAccountsMode={
                      accountsToDisplayForAccountsMode
                    }
                    onlyAccountsMode={onlyAccountsMode}
                    tableId="accounts"
                  />

                  <div
                    className={styles.addAccountButton}
                    data-cy="add-account-child-button"
                    onClick={() => {
                      setAccountDrawerOnBrandDrawer({
                        drawerMode: "CREATE_ACCOUNT",
                      });
                    }}
                  >
                    + Add account
                  </div>
                </>
              )}
            </div>
          </Form>
        </BaseDrawer>
      </ResizeObserver>

      {accountDrawerOnBrandDrawer.drawerMode === "CREATE_ACCOUNT" && (
        <AccountDrawer
          brandNameFromDrawer={nameToEdit}
          visible={accountDrawerOnBrandDrawer.drawerMode === "CREATE_ACCOUNT"}
          title="New Account"
          mode={"CREATE"}
          onCancel={() => setAccountDrawerOnBrandDrawer({ drawerMode: "NONE" })}
          accountDrawerRef={accountDrawerRef}
        />
      )}
      {accountDrawerOnBrandDrawer.drawerMode === "UPDATE_ACCOUNT" && (
        <AccountDrawer
          title="Edit Account"
          visible={accountDrawerOnBrandDrawer.drawerMode === "UPDATE_ACCOUNT"}
          mode={"UPDATE"}
          onCancel={() => setAccountDrawerOnBrandDrawer({ drawerMode: "NONE" })}
          accountToUpdate={
            (accountDrawerOnBrandDrawer.targetElement as AccountEdit).account
          }
          accountDrawerRef={accountDrawerRef}
        />
      )}
    </>
  );
};
