import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space } from "antd";
import { FilterDropdownProps } from "antd/lib/table/interface";
import styles from "./FilterDropdown.module.scss";

const FilterDropdown = (props: FilterDropdownProps) => {
  const { setSelectedKeys, selectedKeys, confirm, clearFilters } = props;

  return (
    <div className={styles.container}>
      <Input
        className={styles.input}
        placeholder={`Search`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => {
          confirm?.();
        }}
      />
      <Space>
        <Button
          className={styles.button}
          onClick={() => {
            clearFilters?.();
          }}
          size="small"
        >
          Reset
        </Button>
        <Button
          className={styles.button}
          type="primary"
          onClick={() => confirm?.()}
          icon={<SearchOutlined />}
          size="small"
        >
          Filter
        </Button>
      </Space>
    </div>
  );
};

export default FilterDropdown;
