import { Select } from "antd";
import { CheckCircleFilled, InfoCircleFilled } from "@ant-design/icons";
import styles from "./OffPlatform.module.scss";
import { PortalOptionMatches, PortalOptions, RowType } from "../shared/types";
import { HandleSelectPortalOptions, PortalOptionMatchTableData } from "./types";

export const getColumns = (
  portalOptionMatches: PortalOptionMatches,
  handleSelectPortalOption: HandleSelectPortalOptions,
) => [
  {
    title: "Feed columns",
    dataIndex: "title",
  },
  {
    width: 50,
    align: "center" as const,
    render: (_: string, rowData: PortalOptionMatchTableData) => {
      const portalOptionMatch = portalOptionMatches[rowData.key]?.portalOption;
      const isMatch = portalOptionMatch != null;
      const isMediaRow = rowData.rowType === RowType.Media;

      return isMatch || isMediaRow ? (
        <CheckCircleFilled className={styles.checkIcon} />
      ) : (
        <InfoCircleFilled className={styles.infoIcon} />
      );
    },
  },
  {
    title: "Show in the Portal as",
    render: (_: string, rowData: PortalOptionMatchTableData) => {
      const isMediaRow = rowData.rowType === RowType.Media;
      const isInternalColumn = [RowType.AssetBatch, RowType.AssetName].includes(
        rowData.rowType,
      );

      const getValue = () => {
        const portalOption = portalOptionMatches[rowData.key]?.portalOption;
        if (isMediaRow) return PortalOptions.Filename;
        return portalOption ?? undefined;
      };

      const getOptions = () => {
        const mapAsOption = { label: <span>Map as</span>, value: "" };
        const doNotImportOption = {
          label: <span>Do not import</span>,
          value: PortalOptions.DoNotImport,
        };
        const portalOptions = {
          label: <span>Options</span>,
          options: [
            { label: <span>Filters</span>, value: PortalOptions.Filters },
            { label: <span>Metadata</span>, value: PortalOptions.Metadata },
          ],
        };

        if (isInternalColumn) return [mapAsOption, portalOptions];
        return [mapAsOption, doNotImportOption, portalOptions];
      };

      return (
        <Select
          placeholder="Map as"
          value={getValue()}
          disabled={isMediaRow}
          onSelect={recommendedColumnTitle =>
            handleSelectPortalOption(recommendedColumnTitle, rowData)
          }
          className={styles.columnTitleInput}
          options={getOptions()}
        />
      );
    },
  },
];
