import { ExclamationCircleOutlined } from "@ant-design/icons";
import { message, Modal } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { ReactComponent as GoogleAdsLogo } from "../../statics/images/google-ads.svg";
import IntegrationCard from "./IntegrationCard";
import { useDeletePlatformToken } from "./shared/hooks/useDeletePlatformToken";
import { useGoogleOAuthConsentUrl } from "./shared/hooks/useGoogleOAuthConsentUrl";
import { usePlatformTokenStatus } from "./shared/hooks/usePlatformTokenStatus";
import { PlatformHookParams } from "./shared/types";
import { handleError } from "./shared/utils";
import { GoogleSignInButton } from "shared/button/GoogleSignInButton";

const { confirm } = Modal;

export const GoogleAdsIntegrationCard = () => {
  const [isLoadingOnGoogle, setIsLoadingOnGoogle] = useState(false);
  const platformHookParams: PlatformHookParams = {
    platform: "google",
    onError: handleError,
  };
  const { data: platformTokenStatus, isLoading: isLoadingPlatformTokenStatus } =
    usePlatformTokenStatus(platformHookParams);
  const { mutate: deletePlatformToken, isLoading: isDeleting } =
    useDeletePlatformToken(platformHookParams);
  const { isLoading: isLoadingConsentUrl, refetch } = useGoogleOAuthConsentUrl({
    onSuccess: (data: string | null | undefined) => {
      if (data) {
        location.href = data;
      }
    },
    onError: handleError,
  });
  const [event] = useQueryParam("event", StringParam);
  const eventMessage = useCallback(() => {
    if (event === "success") {
      message.success("Google Ads API connected");
      return;
    }
    if (event === "error") {
      message.error(
        "An error occurred while connecting with Google Ads API. Please try again.",
      );
    }
  }, [event]);
  useEffect(eventMessage, [eventMessage]);

  const isConnected = useMemo(
    () => platformTokenStatus === "active",
    [platformTokenStatus],
  );

  const isLoading = useMemo(
    () =>
      isLoadingPlatformTokenStatus ||
      isDeleting ||
      isLoadingOnGoogle ||
      isLoadingConsentUrl,
    [
      isDeleting,
      isLoadingPlatformTokenStatus,
      isLoadingOnGoogle,
      isLoadingConsentUrl,
    ],
  );

  const onConnect = async () => {
    setIsLoadingOnGoogle(true);
    await refetch();
  };

  const onDisconnect = () => {
    confirm({
      title: "Are you sure you want to remove this Authorization?",
      icon: <ExclamationCircleOutlined style={{ color: "#FF4D4F" }} />,
      content:
        "Removing this authorization may affect any integrations currently using your Google Ads account.",
      onOk: () => {
        deletePlatformToken("google");
      },
    });
  };

  return (
    <IntegrationCard
      name="Google Ads API"
      description="Authorize your Google account to give Alexia API access to Google Ads API on your behalf"
      isConnected={isConnected}
      isLoading={isLoading}
      logo={<GoogleAdsLogo />}
      onConnect={onConnect}
      onDisconnect={onDisconnect}
      customConnectButton={
        <GoogleSignInButton onClick={onConnect} disabled={isLoading} />
      }
    />
  );
};
