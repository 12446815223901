import createPersistedState from "use-persisted-state";

import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";

import {
  getAlexiaMenuItemByPath,
  getAlexiaSubModuleByPath,
} from "utils/userPreferences";

import {
  initialPreferences,
  propToUrlParamDict,
} from "shared/constants/userPreferences";

import { parseParamValueToPropValue } from "utils/userPreferences";

import {
  PreferenceKey,
  PreferenceRecord,
  UserPreferences,
} from "shared/types/userPreferences";

import { AlexiaModule } from "shared/types/shared";

const useUserPreferencesState =
  createPersistedState<UserPreferences>("userPreferences");

type SetPreferenceArgs<T = string[]> = {
  propName: PreferenceKey;
  // for now, the only kind of data stored is string[]
  data: T;
};

const ALLOWED_MODULES = [
  AlexiaModule.AD_LIBRARY,
  AlexiaModule.CAMPAIGN_MANAGEMENT,
];

const useUserPreferences = () => {
  const [userPreferences, setUserPreferencesState] =
    useUserPreferencesState(initialPreferences);
  const { pathname } = useLocation();
  const location = useLocation();
  const alexiaModule = useMemo(
    () => getAlexiaMenuItemByPath(pathname)?.module,
    [pathname],
  );
  const subModule = useMemo(
    () => getAlexiaSubModuleByPath(pathname),
    [pathname],
  );

  /** This gets locally-stored data for the current active page, if applicable **/
  const currentPagePreferences = useMemo(() => {
    if (!alexiaModule || !subModule || !ALLOWED_MODULES.includes(alexiaModule))
      return;
    return userPreferences?.[alexiaModule]?.[subModule];
  }, [alexiaModule, subModule, userPreferences]);

  const getParamValueToPropValue = useCallback(
    (propName: PreferenceKey) => {
      const urlParams = new URLSearchParams(location.search);
      const paramKey = propToUrlParamDict[propName];
      const paramValue = urlParams.get(`${paramKey}`);
      return parseParamValueToPropValue(paramKey, paramValue);
    },
    [location],
  );

  /** Get a specific prop from user preferences found in the current active page **/
  const getPreference = useCallback(
    <T = string[]>(propName: PreferenceKey) => {
      if (alexiaModule && !ALLOWED_MODULES.includes(alexiaModule))
        return getParamValueToPropValue(propName) as unknown as T | undefined;
      return currentPagePreferences?.[propName] as unknown as T | undefined;
    },
    [currentPagePreferences, alexiaModule, getParamValueToPropValue],
  );

  /** Set a single prop value **/
  const setPreference = useCallback(
    <T = string[]>(args: SetPreferenceArgs<T>) => {
      const alexiaModule = getAlexiaMenuItemByPath(pathname)?.module;
      const subModule = getAlexiaSubModuleByPath(pathname);
      if (
        !alexiaModule ||
        !subModule ||
        !ALLOWED_MODULES.includes(alexiaModule)
      )
        return;

      setUserPreferencesState(currentPreferences => {
        const preferences = currentPreferences || initialPreferences;

        return {
          ...preferences,
          [alexiaModule]: {
            ...(preferences[alexiaModule] || {}),
            [subModule]: {
              ...(preferences[alexiaModule]?.[subModule] || {}),
              [args.propName]: args.data,
            },
          },
        };
      });
    },
    [pathname, setUserPreferencesState],
  );

  /** Set all preferences for the current active page, if applicable **/
  const setPreferences = useCallback(
    (data: PreferenceRecord) => {
      if (
        !alexiaModule ||
        !subModule ||
        !ALLOWED_MODULES.includes(alexiaModule)
      )
        return;
      const currentPrefs = userPreferences || initialPreferences;
      const newPreferences = {
        ...currentPrefs,
        [alexiaModule]: {
          [subModule]: data,
        },
      };
      setUserPreferencesState(newPreferences);
    },
    [alexiaModule, setUserPreferencesState, subModule, userPreferences],
  );

  return {
    getPreference,
    setPreference,
    setPreferences,
    userPreferences,
    currentPagePreferences,
  };
};

export default useUserPreferences;
