import { message } from "antd";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAdEngineActions } from "redux/assetExporter/assetExporter.slice";
import { useAppSelector } from "../useAppSelector";
import { useDownloadCsv } from "./useDownloadCsv";
import { getFeedVersions } from "./useFetchFeedVersions";
import { useGetFormattedFeeds } from "./useGetFormattedFeeds";
import usePullFeed from "./usePullFeed";

export const useSourceFeedToolbar = () => {
  const { rightClickPopup, selectedFeedIds } = useAppSelector(
    state => state.assetExporter.sourceFeed,
  );

  const actions = useAdEngineActions();
  const navigate = useNavigate();

  const { feeds, feedsFromServer } = useGetFormattedFeeds();
  const { mutateAsync: pullFeed } = usePullFeed();
  const { mutate: downloadCsv } = useDownloadCsv();

  const popupState = useMemo(
    () => ({
      visible: rightClickPopup.visible,
      x: rightClickPopup.displayX,
      y: rightClickPopup.displayY,
    }),
    [
      rightClickPopup.displayX,
      rightClickPopup.displayY,
      rightClickPopup.visible,
    ],
  );

  const isCustomSelected = useCallback(
    (clickedFeedId?: string) => {
      const feedIds = clickedFeedId ? [clickedFeedId] : selectedFeedIds;
      return feeds
        .filter(({ feedId }) => feedIds.includes(feedId))
        .some(feed => feed.frequency === "Not supported");
    },
    [feeds, selectedFeedIds],
  );

  const wasFailed = useCallback(
    (clickedFeedId?: string) => {
      return feeds
        .filter(({ feedId }) => clickedFeedId === feedId)
        .some(feed => feed.currStatus === "FAILED");
    },
    [feeds],
  );

  const onConfigure = useCallback(
    (clickedFeedId?: string) => {
      const currId = selectedFeedIds[0] ?? clickedFeedId;
      const currFeed = feeds.find(({ feedId }) => feedId === currId);
      if (!currFeed) return;
      const { feedId, feedName } = currFeed;
      const url = `/ad-engine/configure?feedName=${feedName}&feedId=${feedId}`;
      navigate(url);
    },
    [feeds, navigate, selectedFeedIds],
  );

  const onDelete = useCallback(() => {
    actions.setIsDeleteModalOpen(true);
  }, [actions]);

  const onEdit = useCallback(
    (clickedFeedId?: string) => {
      const currId = selectedFeedIds[0] ?? clickedFeedId;
      const currFeed = feedsFromServer.find(({ id }) => id === currId);
      if (!currFeed) return;
      actions.setFeedFormForUpdate(currFeed);
      actions.toggleDrawer("UPDATE");
    },
    [actions, feedsFromServer, selectedFeedIds],
  );

  const onNew = useCallback(() => {
    actions.toggleDrawer("CREATE");
  }, [actions]);

  const onPullLatest = useCallback(
    async (clickedFeedId?: string) => {
      const msgKey = "pullLatestKey";
      message.loading({ key: msgKey, content: "Pulling feed(s)" });
      const feedIds =
        clickedFeedId && typeof clickedFeedId === "string"
          ? [clickedFeedId]
          : selectedFeedIds;
      try {
        const selectedFeeds = feedsFromServer.filter(feed =>
          feedIds.includes(feed.id),
        );
        await Promise.all(
          selectedFeeds.map(feed => {
            pullFeed({
              ...feed,
            });
          }),
        );
        message.success({
          key: msgKey,
          content: "Successfully pulled feed(s)",
        });
      } catch (error) {
        message.error({ key: msgKey, content: "Unable to pull feed(s)" });
      }
    },
    [feedsFromServer, pullFeed, selectedFeedIds],
  );

  const onDownloadCSV = useCallback(
    async (clickedFeedId?: string) => {
      const msgKey = "downloadCSVKey";
      message.loading({ key: msgKey, content: "Downloading CSV" });
      const feedIds = clickedFeedId ? [clickedFeedId] : selectedFeedIds;
      try {
        const selectedFeeds = feedsFromServer.find(feed =>
          feedIds.includes(feed.id),
        );

        if (!selectedFeeds) {
          message.error(
            { key: msgKey, content: "Unable to download CSV" },
            3000,
          );
          return;
        }

        const latestFeedVersion = await getFeedVersions(selectedFeeds.id);

        downloadCsv(latestFeedVersion[0]);
      } catch (error) {
        message.error({ key: msgKey, content: "Unable to download CSV" }, 3000);
      }
    },
    [downloadCsv, feedsFromServer, selectedFeedIds],
  );

  const onSearch = useCallback(
    (searchInput: string) => {
      actions.setSearchInput(searchInput);
    },
    [actions],
  );

  const onVersion = useCallback(() => {
    actions.setIsVersionsDrawerOpen(true);
  }, [actions]);

  return {
    isCustomSelected,
    wasFailed,
    onConfigure,
    onDelete,
    onEdit,
    onNew,
    onDownloadCSV,
    onPullLatest,
    onSearch,
    onVersion,
    popupState,
  };
};
