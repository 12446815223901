import { groupBy, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import API from "services";
import useDeepEffect from "shared/hooks/useDeepEffect";
import { calculateDaysDiff } from "shared/hooks/useFetchOfferList";
import {
  IAssetBuilderState,
  MetaFeedMapping,
  OfferRebateKeys,
  SingletonOfferKeys,
  Tab,
  TGetOfferListResult,
} from "shared/types/assetBuilder";
import { IApiResponse } from "shared/types/shared";
import { TMovingOfferData, TOfferCollapseData } from "../SelectV2";
import { useOfferEdits } from "./useOfferEdits";

export type TOfferListSection = "selected" | "available" | "new";
export type TCount = {
  total: number;
  current: number;
};

export default (args: {
  offerListResults: Record<string, TGetOfferListResult>;

  savedOrder: IAssetBuilderState["savedOrder"];
  movingOfferData?: TMovingOfferData;
  currentTab?: Tab;
  onOfferMoved?: (
    offerListData: { [key in TOfferListSection]?: TOfferCollapseData[] },
    movingOfferData: TMovingOfferData,
    cb?: () => void,
  ) => Promise<void>;
}) => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const [counts, setCounts] = useState<Record<string, TCount>>();
  const [fetchedOfferCount, setFetchedOfferCount] = useState<number>(0);
  const [offerListData, setOfferListData] =
    useState<{ [key in TOfferListSection]?: TOfferCollapseData[] }>();

  const [editedVins, setEditedVins] = useState<string[]>();

  const { offerListResults, savedOrder } = args;

  const { selected: selectedOfferListResult } = offerListResults;
  // For selected offer list
  useDeepEffect(() => {
    const okToProceed = !!savedOrder && !isEmpty(selectedOfferListResult);
    if (!okToProceed) return;

    const setSelectedOffers = async () => {
      const {
        selectedOffers: savedSelectedOffers,
        selectedOrder: { dealer_code },
        meta,
      } = savedOrder!;

      // NOTE: We need to do some data transformation here for the offerData under selectedOffers.
      //       For those fetched offerList, the transformation will be done in useFecthOfferList.ts.
      //       Plz refer to the logic used in that file.
      const selectedOffers = savedSelectedOffers?.map(item => {
        const offer = selectedOfferListResult.offerList?.find(
          o => o.row.vin === item.offerData.vin,
        );
        if (!offer) {
          return {
            ...item,
            offerData: {
              ...item.offerData,
              daysInStock: `${
                calculateDaysDiff(item.offerData.dateInStock) || "--"
              }`,
            },
          };
        }

        return {
          ...item,
          offerData: {
            ...offer.row,
            daysInStock: `${calculateDaysDiff(offer.row.dateInStock) || "--"}`,
          },
        };
      });

      // NOTE: below is to get the list of vins that arent in DB (ES)
      const { feedMappings } = (meta as {
        feedMappings: Array<MetaFeedMapping>;
      }) || { feedMappings: [] };
      const feedIdMappings = groupBy(
        feedMappings?.filter(mapping => mapping.vin) ?? [],
        mapping => mapping.feedId,
      ); // { <feedId> : Array<{vin, feedId}> }

      const promises = Object.keys(feedIdMappings).reduce<
        Array<Promise<IApiResponse<{ flaggedVins: string[] }>>>
      >((acc, feedId) => {
        const mappings = feedIdMappings[feedId];
        if (mappings.length === 0) return acc;

        const vins = mappings.map(m => m.vin);
        return [...acc, API.services.assetBuilder.getFlaggedVins(feedId, vins)];
      }, []);

      const result = await Promise.all(promises);

      const flaggedVins = result.reduce<Array<string>>((acc, item) => {
        const { result } = item;
        if (!result) return acc;

        const { flaggedVins } = result;

        return [...acc, ...(flaggedVins || [])];
      }, []);

      // for selected offers
      const selected =
        selectedOffers?.map<TOfferCollapseData>(selected => ({
          offerData: selected.offerData,
          offerTypes: selected.offers,
          flagged: flaggedVins.includes(selected.offerData.vin),
          warned: ![
            selected.offerData.dealerCode,
            selected.offerData.dealerId,
          ].includes(dealer_code),
        })) || [];

      setOfferListData(prev => ({
        ...(prev || {}),
        selected,
      }));
    };

    setSelectedOffers();
  }, [selectedOfferListResult, savedOrder]);

  const { currentTab } = args;
  // For available offer list
  useDeepEffect(() => {
    const okToProceed =
      !isEmpty(offerListResults) && !!savedOrder && !!currentTab;

    if (!okToProceed) return;

    const { selectedOffers } = savedOrder!;
    const selectedOfferVins = selectedOffers?.map(offer => offer.offerData.vin);

    const key = `available-${currentTab.id}`;
    const offerListResult = offerListResults[key];
    const { offerList, total, editedVins } = offerListResult || {};
    if (!offerList) return;

    // for available offers
    const available = offerList
      .filter(offer => !selectedOfferVins?.includes(offer.row.vin))
      .map<TOfferCollapseData>(offer => ({
        editedPairs: offer.editedPairs,
        offerData: offer.row,
        offerTypes: [],
        lastUpdated: offer.updated,
        editedKeys: Object.keys(offer.editedPairs) as Array<
          SingletonOfferKeys | OfferRebateKeys
        >,
      }));

    setOfferListData(prev => ({
      ...(prev || {}),
      available,
    }));
    setCounts(prev => ({
      ...(prev || {}),
      [currentTab.id]: {
        total,
        current: available.length,
      },
    }));

    setEditedVins(editedVins);
  }, [offerListResults, savedOrder, currentTab]);

  const { movingOfferData, onOfferMoved } = args;
  useDeepEffect(() => {
    if (!movingOfferData) return;

    let selected: TOfferCollapseData[] | undefined = undefined;
    let available: TOfferCollapseData[] | undefined = undefined;

    const { section } = movingOfferData || {};

    const { vin, offerTypes } = movingOfferData;

    setOfferListData(offerListData => {
      // NOTE: the "section" is where the selected offer is moving to.
      switch (section) {
        case "selected":
          const offerFromAvailable = offerListData?.available?.find(
            o => o.offerData.vin === vin,
          );

          if (!offerFromAvailable) {
            // This means the offer was already in "Selected Offers" section and changed offer types.
            selected =
              offerListData?.selected?.map(item =>
                item.offerData.vin === vin ? { ...item, offerTypes } : item,
              ) || [];
            available = offerListData?.available;

            break;
          }

          selected = [
            ...(offerListData?.selected || []),
            { ...offerFromAvailable, offerTypes: offerTypes },
          ];
          available = offerListData?.available?.filter(
            o => o.offerData.vin !== vin,
          );

          break;

        case "available":
          const offerFromSelected = offerListData?.selected?.find(
            o => o.offerData.vin === vin,
          );
          if (!offerFromSelected) {
            selected = offerListData?.selected;
            available = offerListData?.available;

            break;
          }

          available = [
            ...(offerListData?.available || []),
            { ...offerFromSelected, offerTypes: [] },
          ];
          selected = offerListData?.selected?.filter(
            o => o.offerData.vin !== vin,
          );

          break;
      }

      const updatedOfferListData = {
        selected,
        available,
      };

      setIsUpdating(true);
      onOfferMoved?.(updatedOfferListData, movingOfferData, () => {
        setIsUpdating(false);
      });

      return updatedOfferListData;
    });
  }, [movingOfferData, onOfferMoved, setIsUpdating]);

  useDeepEffect(() => {
    if (!offerListData) return;

    const { selected, available } = offerListData;
    setFetchedOfferCount((selected?.length ?? 0) + (available?.length ?? 0));
  }, [offerListData]);

  const { selected } = offerListData || {};
  const selectedVins =
    (selected
      ?.map(item => item.offerData.vin)
      .filter(vin => !!vin) as string[]) || [];

  const { data: edits } = useOfferEdits(selectedVins);
  useEffect(() => {
    if (!edits?.length) return;

    setOfferListData(prev => {
      const { selected } = prev || {};
      if (!selected) return prev;

      const updatedSelected = selected.map(offer => {
        const lastUpdatedStr = edits.find(
          edit => edit.vin === offer.offerData.vin,
        )?.lastUpdated;
        const lastUpdated =
          typeof lastUpdatedStr === "string" ? +lastUpdatedStr : lastUpdatedStr;

        if (movingOfferData?.vin === offer.offerData.vin)
          return {
            ...offer,
            lastUpdated: Date.now(),
          };

        return {
          ...offer,
          lastUpdated: lastUpdated || offer.lastUpdated,
        };
      });
      return {
        ...(prev || {}),
        selected: updatedSelected,
      };
    });
  }, [edits, movingOfferData]);

  return {
    offerListData,
    counts,
    editedVins,
    isUpdating,
    fetchedOfferCount,
  };
};
