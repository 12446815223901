import "./ArtboardDrawer.scss";
import FormDrawer from "shared/components/FormDrawer";
import { DrawerForm } from "./artboardDrawer/DrawerForm";
import { ArtboardDrawerProps } from "./artboardDrawer/types";
import { DrawerContext } from "./DrawerContext/DrawerContext";
import { useContext } from "react";
import { useAppShallowEqualSelector } from "shared/hooks/useAppSelector";
import { AppState } from "redux/store";

export const ArtboardDrawer = ({
  showArtboardDrawer = false,
}: ArtboardDrawerProps) => {
  const { creatingData, editingArtboardStatus } = useAppShallowEqualSelector(
    ({ designStudio }: AppState) => ({
      creatingData: designStudio.creatingData,
      editingArtboardStatus: designStudio.editingArtboardStatus,
    }),
  );

  const { artboardToEdit, mode = "" } = useContext(DrawerContext);

  const { handleClose, handleSubmit, handleDelete } = useContext(DrawerContext);
  const isBegin = editingArtboardStatus === "begin";
  const isArtboard = creatingData === "artboard";
  const processingData = isArtboard || isBegin;

  return (
    <FormDrawer
      mode={mode}
      drawerWidth={720}
      dataName={"Artboard"}
      processingData={processingData}
      showDrawer={showArtboardDrawer}
      validForm={true}
      drawerForm={<DrawerForm />}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      handleDelete={handleDelete}
      allowDelete={!!artboardToEdit}
    />
  );
};
