import { memo } from "react";
import styles from "./CustomAdPreview.module.scss";
import MediaPreview from "./customAdPreview/MediaPreview";
import PageDetails from "./customAdPreview/PageDetails";
import SocialButtons from "./customAdPreview/SocialButtons";
import AdMessage from "./customAdPreview/AdMessage";
import CtaSection from "./customAdPreview/CtaSection";
import { CallToAction } from "screens/adLibrary/facebookUtils/types";

type CustomAdPreviewProps = {
  videoUrl: string | undefined;
  thumbnailUrl: string | undefined;
  pageId: string | undefined;
  message: string | undefined;
  linkCaption: string | undefined;
  title: string | undefined;
  cta: CallToAction | undefined;
  description: string | undefined;
};

const CustomAdPreview = ({
  videoUrl,
  thumbnailUrl,
  pageId,
  message,
  linkCaption,
  title,
  cta,
  description,
}: CustomAdPreviewProps) => {
  return (
    <div className={styles.previewContainer}>
      <PageDetails pageId={pageId} />
      <AdMessage message={message} />
      <div className={styles.body}>
        <div className={styles.media}>
          <MediaPreview videoUrl={videoUrl} thumbnailUrl={thumbnailUrl} />
        </div>
        <CtaSection
          linkCaption={linkCaption}
          title={title}
          cta={cta}
          description={description}
        />
        <div className={styles.line}></div>
      </div>
      <SocialButtons />
    </div>
  );
};

export default memo(CustomAdPreview);
