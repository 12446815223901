import { context } from "../../../../Editor.context";

import { LogoDropZoneType, LogoEventType } from "shared/types/designStudio";
import Rectangle from "./logoDropZone/Rectangle";

import "./LogoDropZone.scss";
import { TLogoDropZone } from "screens/designStudio/editor/canvasContainer/canvas.hooks/useImageInsertData";
import { FC, useContext } from "react";
import { logoColorByType } from "utils/helpers.designStudio";

interface ILogoDropZone {
  type: LogoDropZoneType;
  eventType: LogoEventType;
}

const LogoDropZone: FC<ILogoDropZone> = ({ type, eventType }) => {
  const editorContext = useContext(context);

  const borderStyleTl = {
    borderTop: `solid 2px ${logoColorByType(eventType)}`,
    borderLeft: `solid 2px ${logoColorByType(eventType)}`,
    borderBottom: "none",
    borderRight: "none",
  };

  const borderStyleTr = {
    borderTop: `solid 2px ${logoColorByType(eventType)}`,
    borderRight: `solid 2px ${logoColorByType(eventType)}`,
    borderBottom: "none",
    borderLeft: "none",
  };

  const borderStyleBr = {
    borderBottom: `solid 2px ${logoColorByType(eventType)}`,
    borderRight: `solid 2px ${logoColorByType(eventType)}`,
    borderTop: "none",
    borderLeft: "none",
  };

  const borderStyleBl = {
    borderBottom: `solid 2px ${logoColorByType(eventType)}`,
    borderLeft: `solid 2px ${logoColorByType(eventType)}`,
    borderTop: "none",
    borderRight: "none",
  };

  return (
    <div
      className="logo-drop-zone-container"
      onClick={e => {
        e.preventDefault();

        editorContext?.setImageInsertData({
          type: "LOGO",
          data: {
            logoDropZoneType: type,
            logoEventType: eventType,
          } as TLogoDropZone,
        });
      }}
    >
      {type === "square" && (
        <>
          <div className="shape-inner-container">
            <Rectangle
              style={{
                container: {
                  width: "5em",
                  height: "5em",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                },
                border: {
                  tl: {
                    left: "-2%",
                    top: "-2%",
                    ...borderStyleTl,
                  },
                  tr: {
                    right: "-2%",
                    top: "-2%",
                    ...borderStyleTr,
                  },
                  br: {
                    right: "-2%",
                    bottom: "-2%",
                    ...borderStyleBr,
                  },
                  bl: {
                    left: "-2%",
                    bottom: "-2%",
                    ...borderStyleBl,
                  },
                },
                center: { width: "90%", height: "90%" },
              }}
              dropZoneType="square"
              eventType={eventType}
            />
          </div>

          <div className="title">Square</div>
        </>
      )}

      {type === "vertical" && (
        <>
          <div className="shape-inner-container">
            <Rectangle
              style={{
                container: {
                  width: "25%",
                  height: "80%",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                },
                border: {
                  tl: { left: "7%", top: "-2%", ...borderStyleTl },
                  tr: { right: "7%", top: "-2%", ...borderStyleTr },
                  br: { right: "7%", bottom: "-2%", ...borderStyleBr },
                  bl: { left: "7%", bottom: "-2%", ...borderStyleBl },
                },
                center: { width: "70%", height: "90%" },
              }}
              dropZoneType="vertical"
              eventType={eventType}
            />
          </div>
          <div className="title">Vertical</div>
        </>
      )}

      {type === "horizontal" && (
        <>
          <div className="shape-inner-container">
            <Rectangle
              style={{
                container: {
                  width: "65%",
                  height: "50%",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                },
                border: {
                  tl: {
                    left: "12%",
                    top: "-7%",
                    width: "20%",
                    height: "40%",
                    ...borderStyleTl,
                  },
                  tr: {
                    right: "12%",
                    top: "-7%",
                    width: "20%",
                    height: "40%",
                    ...borderStyleTr,
                  },
                  br: {
                    right: "12%",
                    bottom: "-7%",
                    width: "20%",
                    height: "40%",
                    ...borderStyleBr,
                  },
                  bl: {
                    left: "12%",
                    bottom: "-7%",
                    width: "20%",
                    height: "40%",
                    ...borderStyleBl,
                  },
                },
                center: { width: "70%", height: "90%" },
              }}
              dropZoneType="horizontal"
              eventType={eventType}
            />
          </div>
          <div className="title">Horizontal</div>
        </>
      )}
    </div>
  );
};

export default LogoDropZone;
