import { Button, Drawer, Tooltip } from "antd";
import styles from "../PmaxDrawerFooter.module.scss";
import { AccountUrl, IAccountRecord } from "shared/types/accountManagement";
import { useState } from "react";
import useUpdateDealer from "shared/hooks/useUpdateDealer";
import { returnDealerDataObjFromRecord } from "redux/dealerManagement/dealerManagement.slice";
import { StoreManagementLabelDrawerContent } from "./StoreManagementLabelDrawerContent";

type StoreManagementLabelDrawerProps = {
  dealer: IAccountRecord;
  isOpen: boolean;
  labelsToFix: AccountUrl[];
  onClose: () => void;
};

const StoreManagementLabelDrawer = ({
  dealer,
  isOpen,
  onClose,
  labelsToFix,
}: StoreManagementLabelDrawerProps) => {
  const computedDealerFromProps = {
    ...dealer,
    labels: [...(dealer.labels || []), ...labelsToFix],
  };
  const [dealerToUpdate, setDealerToUpdate] = useState<IAccountRecord>(
    computedDealerFromProps,
  );

  const { mutate: updateDealer, isLoading } = useUpdateDealer();

  const saveDisabled = dealerToUpdate.labels?.some(label => !label.url);

  const submitChanges = () => {
    updateDealer(returnDealerDataObjFromRecord(dealerToUpdate), {
      onSettled: (data, error) => {
        if (!data || error) return;
        onClose();
      },
    });
  };
  return (
    <Drawer
      visible={isOpen}
      closable={false}
      maskClosable={false}
      width={720}
      afterVisibleChange={visible =>
        visible && setDealerToUpdate(computedDealerFromProps)
      }
      destroyOnClose
      footer={
        <div className={styles.buttonContainer}>
          <Button onClick={onClose}>Cancel</Button>
          <Tooltip
            placement="left"
            title={
              saveDisabled
                ? "A Store cannot be saved if there are duplicate labels or empty values."
                : ""
            }
          >
            <Button
              type="primary"
              onClick={submitChanges}
              disabled={saveDisabled}
              loading={isLoading}
            >
              Save
            </Button>
          </Tooltip>
        </div>
      }
    >
      <StoreManagementLabelDrawerContent
        dealer={dealerToUpdate}
        labelsToFix={labelsToFix}
        updateDealer={setDealerToUpdate}
      />
    </Drawer>
  );
};

export default StoreManagementLabelDrawer;
