import { useCallback } from "react";
import usePreference from "./usePreference";

const useSelectedAdIds = () => {
  const { value: selectedAdIds, setValue: setSelectedAdIds } = usePreference(
    "selectedAdIds",
    [] as string[],
    true,
  );

  const isAdSelected = useCallback(
    (adId: string) => selectedAdIds.includes(adId),
    [selectedAdIds],
  );
  const toggleAdSelection = (adId: string) => {
    const newIds = isAdSelected(adId)
      ? selectedAdIds.filter(value => value !== adId)
      : selectedAdIds.concat(adId);
    setSelectedAdIds(newIds);
  };

  return {
    isAdSelected,
    selectedAdIds,
    setSelectedAdIds,
    toggleAdSelection,
  };
};

export default useSelectedAdIds;
