import { ReactNode, useMemo } from "react";
import { useLocation } from "react-router-dom";
import useDeleteComment from "./useDeleteComment";
import { useFetchInfiniteComments } from "./useFetchComments";
import useMutateComment from "./useMutateComment";

export type UseCommentsArgs = {
  id?: string;
  mode?: "edit" | "create";
  children?: ReactNode;
  className?: string;
  extraInfo?: any;
};

const useComments = ({ id, mode }: UseCommentsArgs) => {
  const location = useLocation();
  const section = location.pathname.split("/")[1];
  const objectId = useMemo(
    () => (section && id ? `${section.toUpperCase()}-${id}` : undefined),
    [section, id],
  );

  const {
    comments,
    isLoading,
    isFetching,
    error: fetchError,
    isError: isFetchError,
    refetch: fetchComments,
  } = useFetchInfiniteComments(objectId ?? "", {
    enabled: !!objectId,
    refetchIntervalInBackground: mode !== "create",
  });

  const {
    error: mutateError,
    mutate: mutateComment,
    isLoading: isMutating,
    isError: isMutateError,
  } = useMutateComment();

  const {
    error: deleteError,
    mutate: deleteComment,
    isLoading: isDeleting,
    isError: isDeleteError,
  } = useDeleteComment();

  return () => {
    const loadingComments = [
      isLoading,
      isFetching,
      isMutating,
      isDeleting,
    ].includes(true);
    const isCommentsError = [
      isFetchError,
      isMutateError,
      isDeleteError,
    ].includes(true);
    const commentsError = fetchError || mutateError || deleteError;

    const isChangingComments = isMutating || isDeleting;

    return {
      objectId,
      comments,
      commentsError,
      loadingComments,
      isCommentsError,
      isChangingComments,
      mutateComment,
      deleteComment,
      fetchComments,
    };
  };
};

export default useComments;
