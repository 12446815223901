import { Collapse, Select, Typography } from "antd";
import { delimiterOptions } from "../../shared/constants";

import styles from "./RuleConfig.module.scss";

const { Panel } = Collapse;

interface IRuleConfigProps {
  taxonomies: string[];
  updateTaxonomies: (taxonomies: string[]) => void;
  delimiter: string;
  updateDelimiter: (delimiter: string) => void;
  disabled?: boolean;
}

const RuleConfig = ({
  taxonomies,
  updateTaxonomies,
  delimiter,
  updateDelimiter,
  disabled = false,
}: IRuleConfigProps) => {
  return (
    <Collapse bordered={false} defaultActiveKey="1">
      <Panel header="Delimiter and Taxonomy" key="1" className={styles.panel}>
        <div className={styles.inputContainer}>
          <Typography.Text>Delimiter</Typography.Text>
          <Select
            className={styles.delimiter}
            value={delimiter}
            onSelect={value => updateDelimiter(value)}
            disabled={disabled}
          >
            {delimiterOptions.map(option => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className={styles.inputContainer}>
          <Typography.Text>Taxonomy</Typography.Text>
          <Select
            style={{ width: "100%" }}
            mode="tags"
            value={taxonomies}
            filterOption={inputValue => !!inputValue?.trim()}
            disabled={disabled}
            onChange={updatedTaxonomies => updateTaxonomies(updatedTaxonomies)}
          />
        </div>
      </Panel>
    </Collapse>
  );
};

export default RuleConfig;
