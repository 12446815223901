import { memo } from "react";
import EverythingAdIcon from "shared/icons/EverythingAdIcon";

type Props = {
  name: string;
  thumbnail: string | undefined;
  itemType?: "EverythingAd";
  wrapperStyle?: {
    marginLeft?: number;
  };
};

const NestedItemNameCell = ({
  thumbnail,
  itemType,
  name,
  wrapperStyle,
}: Props) => {
  return (
    <span style={wrapperStyle}>
      {!!thumbnail && (
        <img
          src={thumbnail}
          width="32"
          height="32"
          style={{
            objectFit: "cover",
          }}
        />
      )}
      {itemType === "EverythingAd" && <EverythingAdIcon />}
      <span style={{ marginLeft: 5, verticalAlign: "middle" }}>{name}</span>
    </span>
  );
};

export default memo(NestedItemNameCell);
