import { useCallback } from "react";
import { Badge, Button, notification, Popconfirm } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { FacebookAdStatus } from "screens/adLibrary/facebookUtils/types";
import { AdReviewTableEntry } from "shared/types/campaignManagement";
import { QCStatus } from "shared/types/shared";
import { AppColors } from "shared/types/shared";
import { useQCStatus } from "../hooks/useQCStatus";

interface IQCStatusButtonsProps {
  status?: FacebookAdStatus;
  record: AdReviewTableEntry;
  skip?: () => void;
}

const QCStatusButtons = ({ record, skip }: IQCStatusButtonsProps) => {
  const onSuccess = (status: QCStatus) => {
    notification.info({
      bottom: 45,
      message: "",
      description: `Status changed to ${status}`,
      placement: "bottomRight",
    });
  };

  const { handleQCHistoryLogInsert, qcStatus } = useQCStatus({
    record,
    options: {
      onSuccess,
    },
  });

  const onQCHistoryLogChange = useCallback(
    async (status: QCStatus) => {
      await handleQCHistoryLogInsert(status);
      if (skip) {
        skip();
      }
    },
    [handleQCHistoryLogInsert, skip],
  );

  const ApprovedButtons = () => {
    return (
      <>
        <Popconfirm
          title={`Are you sure you want to change the status to "Not QC'ed?"`}
          okText="Yes"
          cancelText="No"
          placement="top"
          onConfirm={() => onQCHistoryLogChange(QCStatus.NOT_QCed)}
        >
          <Button
            style={{
              borderColor: AppColors.success,
              color: AppColors.success,
            }}
          >
            <Badge status="success" />
            Approved
          </Button>
        </Popconfirm>
        <Button
          style={{
            borderColor: AppColors.secondary,
            color: AppColors.secondary,
          }}
          onClick={() => {
            onQCHistoryLogChange(QCStatus.DENIED);
          }}
        >
          <Badge status="default" />
          Denied
        </Button>
      </>
    );
  };

  const DeniedButtons = () => {
    return (
      <>
        <Button
          style={{
            borderColor: AppColors.secondary,
            color: AppColors.secondary,
          }}
          onClick={() => {
            onQCHistoryLogChange(QCStatus.APPROVED);
          }}
        >
          <Badge status="default" />
          Approved
        </Button>
        <Popconfirm
          title={`Are you sure you want to change the status to "Not QC'ed?"`}
          okText="Yes"
          cancelText="No"
          placement="top"
          onConfirm={() => onQCHistoryLogChange(QCStatus.NOT_QCed)}
        >
          <Button danger>
            <Badge status="error" />
            Denied
          </Button>
        </Popconfirm>
      </>
    );
  };

  const NotQCedButtons = () => {
    return (
      <>
        <Button
          style={{ borderColor: AppColors.secondary, color: "black" }}
          icon={<CheckOutlined style={{ color: AppColors.success }} />}
          onClick={() => {
            onQCHistoryLogChange(QCStatus.APPROVED);
          }}
        >
          Approve
        </Button>
        <Button
          style={{ borderColor: AppColors.secondary, color: "black" }}
          icon={<CloseOutlined style={{ color: AppColors.danger }} />}
          onClick={() => {
            onQCHistoryLogChange(QCStatus.DENIED);
          }}
        >
          Deny
        </Button>
      </>
    );
  };

  switch (qcStatus) {
    case QCStatus.APPROVED:
      return <ApprovedButtons />;
    case QCStatus.DENIED:
      return <DeniedButtons />;
    default:
      return <NotQCedButtons />;
  }
};

export default QCStatusButtons;
