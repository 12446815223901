import { Input } from "antd";
import { ChangeEvent, useContext } from "react";
import { DrawerContext } from "../../DrawerContext/DrawerContext";

type DrawerInputNumberProps = {
  type: "width" | "height";
};

export const DrawerInputNumber = ({ type }: DrawerInputNumberProps) => {
  const { FULL, artboard, setArtboard } = useContext(DrawerContext);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setArtboard({ ...artboard, [type]: parseInt(value, 10) });
  };
  return (
    <Input
      data-cy={`artboard-${type}-input`}
      type="number"
      min={0}
      prefix={type.slice(0, 1).toUpperCase()}
      disabled={FULL}
      value={artboard[type]}
      suffix="px"
      onChange={onChange}
    />
  );
};
