import { useMemo, SetStateAction } from "react";
import { Button } from "antd";

import {
  getReviewAndQAIssuesForInnerStep,
  returnAdToLoadWithoutReviewQAIssuesForStep,
} from "../../shared/components/adsToLoadTable/utils";

import { enabledButtonStyle } from "../../shared/constants";

import { AdType } from "screens/adLibrary/facebookUtils/types";
import { IAdToLoadData, ReviewStepKeys } from "shared/types/adLibrary";
import { IReviewStepInnerDrawerHandlers } from "../ReviewStepInnerDrawer";

const {
  CONVERT_TO_VIDEO,
  REVIEW_DESTINATION_URLS,
  ASSIGN_PRODUCT_SETS_AD_LEVEL,
  ASSIGN_PRODUCT_SETS_IE_LEVEL,
} = ReviewStepKeys;

interface IDrawerOkButtonProps {
  selectedRowKeys: string[];
  adsToLoad: IAdToLoadData[];
  isConvertingToVideo: boolean;
  wereCollectionsConverted: boolean;
  currentAdsToLoad: IAdToLoadData[];
  currentInnerStep: ReviewStepKeys | null | undefined;
}

interface IDrawerOkButtonHandlers {
  onSave: IReviewStepInnerDrawerHandlers["onSave"];
  onClose: IReviewStepInnerDrawerHandlers["onClose"];
  setIsConvertingToVideo: (value: SetStateAction<boolean>) => void;
  setWereCollectionsConverted: (value: SetStateAction<boolean>) => void;
  setCurrentAdsToLoad: (value: SetStateAction<IAdToLoadData[]>) => void;
}

type Props = IDrawerOkButtonProps & IDrawerOkButtonHandlers;

const returnOkButtonProps = (args: Props) => {
  const currentInnerStepQAIssues = args.currentInnerStep
    ? getReviewAndQAIssuesForInnerStep(
        args.currentAdsToLoad,
        args.currentInnerStep,
      )
    : [];
  if (args.currentInnerStep === REVIEW_DESTINATION_URLS) {
    const wereDestinationUrlsReviewed = !currentInnerStepQAIssues.length;
    return {
      style: enabledButtonStyle,
      children: wereDestinationUrlsReviewed ? "Save Changes" : "Confirm",
      onClick: () => {
        if (wereDestinationUrlsReviewed) {
          args.onSave?.({ data: args.currentAdsToLoad, willRecheckAds: true });
          args.onClose?.();
          return;
        }
        const updatedAdsToLoad = [...args.currentAdsToLoad].map(adToLoad =>
          returnAdToLoadWithoutReviewQAIssuesForStep(
            adToLoad,
            args.currentInnerStep!,
          ),
        );
        args.setCurrentAdsToLoad(updatedAdsToLoad);
      },
    };
  }
  if (args.currentInnerStep === CONVERT_TO_VIDEO) {
    const invalidConvertedAd =
      // handle video-converted-collections ads without CTA or Destination URLs
      args.wereCollectionsConverted &&
      !!args.currentAdsToLoad.filter(({ ad }) => {
        return !ad.visuals.ctaButtonText || !ad.inputParameters.destinationUrl;
      }).length;
    const disabled =
      args.isConvertingToVideo ||
      !args.currentAdsToLoad.length ||
      invalidConvertedAd;
    const textForConvert = args.isConvertingToVideo
      ? "Converting"
      : `Convert ${currentInnerStepQAIssues.length} Ads`;
    return {
      disabled,
      style: !disabled ? enabledButtonStyle : undefined,
      children: args.wereCollectionsConverted ? "Done" : textForConvert,
      onClick: () => {
        if (args.wereCollectionsConverted) {
          args.onSave?.({
            data: args.currentAdsToLoad,
            willRecheckAds: true,
          });
          args.onClose?.();
          return;
        }
        args.setIsConvertingToVideo(true);

        const updatedAdsToLoad = [...args.currentAdsToLoad].map(adToLoad => {
          if (!args.selectedRowKeys?.includes(adToLoad.key)) adToLoad;
          const newAdProp = {
            ...adToLoad.ad,
            inputParameters: {
              ...adToLoad.ad.inputParameters,
              name: adToLoad.ad.inputParameters.name?.replace(
                /collection/i,
                "Cinemagraph",
              ),
            },
            type: adToLoad.ad.visuals.videoUrl ? AdType.Video : AdType.Still,
          };
          return {
            ...returnAdToLoadWithoutReviewQAIssuesForStep(
              adToLoad,
              args.currentInnerStep!,
            ),
            ad: newAdProp,
            wasConvertedToVideo: true,
          };
        });
        args.setCurrentAdsToLoad(updatedAdsToLoad);

        // This gives the ilusion of something processing
        setTimeout(() => {
          args.setWereCollectionsConverted(true);
          args.setIsConvertingToVideo(false);
        }, 1000);
      },
    };
  }
  if (args.currentInnerStep === ASSIGN_PRODUCT_SETS_AD_LEVEL) {
    return {
      style: enabledButtonStyle,
      children: "Save Changes",
      onClick: () => {
        args.onSave?.({
          data: [...args.currentAdsToLoad].map(adToLoad =>
            adToLoad?.productCatalog?.id &&
            adToLoad?.productSet?.id &&
            args.currentInnerStep
              ? returnAdToLoadWithoutReviewQAIssuesForStep(
                  adToLoad,
                  args.currentInnerStep,
                )
              : adToLoad,
          ),
          willRecheckAds: true,
        });
        args.onClose?.();
      },
    };
  }
  if (args.currentInnerStep === ASSIGN_PRODUCT_SETS_IE_LEVEL) {
    const changesWereMade =
      JSON.stringify(args.adsToLoad) !== JSON.stringify(args.currentAdsToLoad);
    return {
      children: "Save Changes",
      disabled: !changesWereMade,
      style: changesWereMade ? enabledButtonStyle : undefined,
      onClick: () => {
        args.onSave?.({
          data: [...args.currentAdsToLoad].map(adToLoad =>
            adToLoad?.productCatalog?.id &&
            adToLoad?.productSet?.id &&
            adToLoad.nestedProductSets &&
            args.currentInnerStep
              ? returnAdToLoadWithoutReviewQAIssuesForStep(
                  adToLoad,
                  args.currentInnerStep,
                )
              : adToLoad,
          ),
          willRecheckAds: true,
        });
        args.onClose?.();
      },
    };
  }

  return {
    children: "Save Changes",
    style: enabledButtonStyle,
    onClick: () => {
      args.onSave?.({ data: args.currentAdsToLoad });
      args.onClose?.();
    },
  };
};

const DrawerOkButton = (props: Props) => {
  const currentOkButtonProps = useMemo(
    () => returnOkButtonProps(props),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      props.adsToLoad,
      props.selectedRowKeys,
      props.currentAdsToLoad,
      props.currentInnerStep,
      props.isConvertingToVideo,
      props.wereCollectionsConverted,
    ],
  );
  return <Button {...currentOkButtonProps} />;
};

export default DrawerOkButton;
