import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Popover } from "antd";
import { COLUMN_ASSET_BATCHES } from "shared/constants/assetExporter";
import { SmartColumn } from "shared/types/assetExporter";
import { isFeatureEnabled } from "utils/helpers";

type SmartColumnTitleProps = {
  name: string;
  title: string;
  smartColumnsIn: SmartColumn;
  onSelectedSmartColumn: (key: string) => void;
  onDeleteSmartColumn: (key: string) => void;
};

export const SmartColumnTitle = ({
  name,
  title,
  smartColumnsIn,
  onSelectedSmartColumn,
  onDeleteSmartColumn,
}: SmartColumnTitleProps) => {
  const adEngineV2Enabled = isFeatureEnabled("ENABLE_AD_ENGINE_V2", false);

  const requiredColumns = adEngineV2Enabled
    ? [COLUMN_ASSET_BATCHES]
    : ["template", "Process"];

  return (
    <div className={`smart-column-header ${name}`}>
      <span className="title">{title}</span>
      <Popover
        title="Smart Column"
        content={
          <div className="smart-column-popover">
            <Button
              className="existing-column-new-rule-button"
              style={{ width: "100%" }}
              icon={<PlusOutlined />}
              onClick={() => {
                onSelectedSmartColumn(name);
              }}
            >
              {!!smartColumnsIn[name]?.length ? "Edit Rules" : "New Rule"}
            </Button>
            {!requiredColumns.includes(name) && (
              <Button
                className="existing-column-delete-smart-column-button"
                style={{ width: "100%", marginTop: "0.5em" }}
                type="primary"
                danger
                icon={<DeleteOutlined />}
                onClick={() => {
                  Modal.confirm({
                    title: `Are you sure you want to delete ${name}?`,
                    content: `Click Ok if you want to delete ${name} smart column.`,
                    okType: "primary",
                    okButtonProps: { danger: true },
                    onOk: () => onDeleteSmartColumn(name),
                  });
                }}
              >
                Delete
              </Button>
            )}
          </div>
        }
      >
        <Button
          icon={<PlusOutlined />}
          size="small"
          className={`smart-column-rule-modal-button ${name}`}
          style={{ border: "none", background: "transparent" }}
        />
      </Popover>
    </div>
  );
};
