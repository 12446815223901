import { Col, Divider, Row, Typography } from "antd";
import AdSelectTable from "./createNewAds/AdSelectTable";
import { terms } from "utils/terms";
import MultipleStoreSelect from "shared/components/MultipleAccountSelect";
import CampaignTree from "./createNewAds/CampaignTree";
import { useCurrentSession } from "../../hooks/useCurrentSession";
import { useState } from "react";
import { useSelectStore } from "./createNewAds/useSelectStore";

const CreateNewAds = () => {
  const { session } = useCurrentSession();
  const { selectedStoreNames, adIdsToLoad } = session.data;
  const [selectedAdIds, setSelectedAdIds] = useState(adIdsToLoad);

  const { onSelectStore, onDeselectStore } = useSelectStore();

  return (
    <Row gutter={[16, 16]}>
      <Col span={24} md={12}>
        <Typography.Text>Select Ads</Typography.Text>
        <AdSelectTable
          selectedAdIds={selectedAdIds}
          setSelectedAdIds={setSelectedAdIds}
        />
      </Col>
      <Col span={24} md={12}>
        <div>
          <Typography.Text>{terms.accountName}</Typography.Text>
          <MultipleStoreSelect
            autoFocus={true}
            style={{ width: "100%" }}
            selectedDealers={selectedStoreNames}
            onSelect={onSelectStore}
            onDeselect={onDeselectStore}
            filters={{
              enabled: true,
              hasFbAccount: true,
            }}
          />
        </div>
        <Divider
          style={{
            margin: "8px 0",
          }}
        />
        <div>
          <Typography.Text>Destination Preview</Typography.Text>
          <CampaignTree selectedAdIds={selectedAdIds} />
        </div>
      </Col>
    </Row>
  );
};

export default CreateNewAds;
