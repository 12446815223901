import { SortOrder } from "antd/lib/table/interface";
import { createEnumParam, useQueryParams, withDefault } from "use-query-params";
import { makeFieldParam } from "utils/helpers.query";
import { useDataListURLContext } from "./dataListURLContext";
import { getEntries } from "./utils";

export const useDataListURLSorter = <FieldKey extends string, RecordType>() => {
  const { fields } = useDataListURLContext<FieldKey, RecordType>();

  const [defaultSortKey, defaultField] = getEntries(fields).find(
    ([, field]) => !!field?.defaultSortOrder,
  ) ?? [undefined, {}];

  const [query, setQuery] = useQueryParams({
    sortKey: withDefault(makeFieldParam<FieldKey | "">(), defaultSortKey),
    sortOrder: withDefault<
      "ascend" | "descend" | "" | undefined | null,
      "ascend" | "descend" | undefined
    >(
      createEnumParam(["ascend", "descend", ""]),
      defaultField?.defaultSortOrder,
    ),
  });

  const sortItems = (key: FieldKey, order?: SortOrder) => {
    setQuery(previousQuery => ({
      ...previousQuery,
      sortKey: order ? key : "",
      sortOrder: order ?? "",
    }));
  };

  return {
    ...query,
    sortItems,
    sortKey: query.sortKey === "" ? undefined : query.sortKey,
    sortOrder: query.sortOrder === "" ? undefined : query.sortOrder,
  };
};
