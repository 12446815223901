import { isRebateOfferKey } from "shared/types/assetBuilder";
import { TCellOfferKey } from "./offerTypeCollapse/row/Cell";

/**
 * Slack reference: https://constellationagency.slack.com/archives/C0201G6DC1M/p1626968446026800
 *
 * @param keys
 * @param repeatableIndex This is zero base current repeatable row index.
 * @param offset This typically differs based on the row update operations, "add" | "delete".
 *
 * @returns string[]
 */
export const getRepeatableFieldKeys = (
  keys: TCellOfferKey[],
  repeatableIndex: number,
  offset?: number,
) => {
  const index = (repeatableIndex || 0) + (offset || 0);

  const isRebateKey = keys.every(key => isRebateOfferKey(key));
  if (isRebateKey) {
    // form a base key name. Ex) leaseRebateX where X is a number from 1-6
    const baseKey = keys.find(key => key.toLowerCase().endsWith("rebate"));
    if (!baseKey) return null;

    const key = `${baseKey}${index}`;
    return [key, `${key}Name`, `${key}Disclosure`];
  }

  return keys.map(key => `${key}${index}`);
};
