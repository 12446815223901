import API from "services";

import { useMutation, useQueryClient } from "react-query";
import useSetAdsQueryData from "./useSetAdsQueryData";

import { IDeleteAdResponseData } from "shared/types/adLibrary";

const deleteAd = async (adId: string) => {
  const { result, error } = await API.services.adLibrary.deleteAd(adId);

  if (error) {
    throw Error(error.message);
  }

  return result;
};

export const useDeleteAd = () => {
  const queryClient = useQueryClient();
  const setAdsQueryData = useSetAdsQueryData();

  return useMutation<IDeleteAdResponseData | null, Error, string>(deleteAd, {
    // Optimistic update for delete ad
    onMutate: async (adId: string) => {
      await setAdsQueryData({ adId, operation: "delete" });
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries("ads");
    },
  });
};
