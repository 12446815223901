import { notification } from "antd";
import isArray from "lodash/isArray";
import { createContext, useContext, useMemo, useRef } from "react";
import { connect } from "react-redux";
import API from "services";
import {
  FeedType,
  IConfig,
  IConfigurationState,
} from "shared/types/configuration";
import { IDimension, IExtendedFabricObject } from "shared/types/designStudio";
import {
  IUploadImageFormInput,
  IUploadImage,
  IUploadImagesResponse,
  IUploadedImage,
} from "shared/types/uploadManagement";
import { prepareAllVideosInCanvas } from "utils/fabric/helpers.media";
import { returnScaledFabricCanvasDataUrl } from "utils/fabric/helpers.scale";
import {
  createGetCanvasData,
  createGetDealerData,
  createGetDisclosureData,
  createGetExceptionData,
  createGetBrandData,
  createGetOfferData,
  createGetStampData,
  createRenderPreview,
  createRenderStamp,
  createRenderTemplate,
  downloadCanvas,
} from "utils/helpers.fabric";
import { v4 as uuidv4 } from "uuid";
import { ExportImageType } from "shared/types/assetBuilder";

const createUploadMedia = () => {
  return async (
    mediaToUpload: IUploadImageFormInput | IUploadImageFormInput[],
    destination?: string,
    exportImageType?: ExportImageType,
    hasVideos?: boolean,
  ) => {
    let uploadObjsToUse: IUploadImage[] = [];
    let resultImages: IUploadedImage[] = [];
    let errorUrls: string[] = [];

    if (isArray(mediaToUpload)) {
      const uploadObjs = mediaToUpload.map(mediaObj => ({
        filename: mediaObj.filename,
        fileType: exportImageType ? `image/${exportImageType}` : mediaObj.type,
        imageData: mediaObj.file,
        featureName: destination || "order-export",
      }));

      uploadObjsToUse = uploadObjs;
    } else {
      const uploadObj: IUploadImage = {
        filename: mediaToUpload.filename,
        fileType: mediaToUpload.type,
        imageData: mediaToUpload.file,
        featureName: destination || "order-export",
        skipStillImageCreation: mediaToUpload.skipStillImageCreation,
      };
      uploadObjsToUse = [uploadObj];
    }

    const uploadImgObjs = uploadObjsToUse.filter(
      obj => !obj.fileType?.includes("video"),
    );

    const { error: imgErr, result: imgRes } =
      (await API.privServices.uploadManagement.uploadImages({
        files: uploadImgObjs,
      })) as IUploadImagesResponse;

    if (imgErr || !imgRes?.images) {
      errorUrls = uploadImgObjs.map(obj => obj.filename!);
    } else {
      resultImages = imgRes.images;
    }

    if (!!resultImages.length && !hasVideos) {
      notification.success({
        message: "Upload Successful",
        description: `${resultImages.length} asset(s) were uploaded.`,
        placement: "bottomRight",
      });
    } else if (!hasVideos) {
      notification.info({
        message: "No Assets were uploaded",
        placement: "bottomRight",
      });
    }

    if (errorUrls.length > 0) {
      notification.warning({
        message: "Uploads Unsuccessful",
        description: `${errorUrls.length} asset(s) could not be uploaded.`,
        placement: "bottomRight",
      });
    }

    return resultImages;
  };
};

type ProcessAssetResult = {
  fileName: string;
  base64: string;
  isVideo?: boolean;
};

export interface IRenderTemplateContext {
  renderTemplate: ReturnType<typeof createRenderTemplate>;
  renderStamp: ReturnType<typeof createRenderStamp>;
  renderPreview: ReturnType<typeof createRenderPreview>;
  getCanvasData: ReturnType<typeof createGetCanvasData>;
  getStampData: ReturnType<typeof createGetStampData>;
  getOemData: ReturnType<typeof createGetBrandData>;
  getDealerData: ReturnType<typeof createGetDealerData>;
  getDisclosureData?: ReturnType<typeof createGetDisclosureData>;
  getExceptionData?: ReturnType<typeof createGetExceptionData>;
  uploadMedia?: ReturnType<typeof createUploadMedia>;

  addCanvasToIndex: (
    canvas: HTMLCanvasElement,
    filename: string,
    dimensions?: IDimension,
  ) => void;

  addFabricCanvasToIndex: (
    canvas: fabric.Canvas,
    filename: string,
    containsVideo?: boolean,
  ) => void;
  removeFabricCanvasFromIndex: (filename: string) => void;

  removeCanvasFromIndex: (filename: string) => void;
  downloadAll: () => void;
  toDataURLsAndFilename: (
    exportingFilenames: string[],
    imageOption?: ExportImageType,
    quality?: number,
    fromAsssetLauncher?: boolean,
  ) => Promise<
    Array<{
      fileName: string;
      base64: string;
    }>
  >;

  uploadedVideoUrls?: string[];

  processVideoAsset: (
    filename: string,
    wfProjectNumber: string,
  ) => Promise<ProcessAssetResult | null>;

  videoFilenames: () => string[];
  getExportingCanvases: (filenames: string[]) => Record<string, fabric.Canvas>;
}

const RenderTemplateContext = createContext<IRenderTemplateContext | null>(
  null,
);

export const useRenderTemplate = () => useContext(RenderTemplateContext);

const RenderTemplateProvider: React.FC<{
  config?: IConfig;
  feed?: FeedType;
}> = ({ config, children }) => {
  const downloadIndex = useRef<Record<string, HTMLCanvasElement>>({});
  const downloadCanvasIndex = useRef<Record<string, fabric.Canvas>>({});
  const downloadVideoCanvasIndex = useRef<Record<string, fabric.Canvas>>({});
  const downloadIndexDimensions = useRef<Record<string, IDimension>>({});

  const uploadedVideoUrlRef = useRef<string[]>([]);

  const addCanvasToIndex = (
    canvas: HTMLCanvasElement,
    filename: string,
    dimensions?: IDimension,
  ) => {
    downloadIndex.current[filename] = canvas;
    if (dimensions) {
      downloadIndexDimensions.current[filename] = dimensions;
    }
  };

  const addFabricCanvasToIndex = (
    canvas: fabric.Canvas,
    filename: string,
    containsVideo?: boolean,
  ) => {
    if (containsVideo) {
      downloadVideoCanvasIndex.current[filename] = canvas;
    } else {
      downloadCanvasIndex.current[filename] = canvas;
    }
  };

  const removeFabricCanvasFromIndex = (filename: string) => {
    delete downloadCanvasIndex.current[filename];
  };

  const removeCanvasFromIndex = (filename: string) => {
    delete downloadIndex.current[filename];
  };

  const downloadAll = () =>
    Object.keys(downloadIndex.current).forEach(filename => {
      downloadCanvas(downloadIndex.current[filename], filename);
    });

  const toDataURLsAndFilename = async (
    exportingFilenames: string[],
    imageOption?: ExportImageType,
    quality?: number,
    fromAssetLauncher?: boolean,
  ) => {
    const exportingCanvasData = [];

    const specificOrderedIndices: number[] = exportingFilenames.map(
      filename => {
        return parseInt(filename.split("__")[filename.split("__").length - 1]);
      },
    );

    for (const filename in downloadCanvasIndex.current) {
      if (!exportingFilenames.find(tmpFilename => tmpFilename === filename))
        continue;

      const videoFabricCanvasIndex = downloadVideoCanvasIndex.current[filename];

      if (videoFabricCanvasIndex) continue;

      exportingCanvasData.push({
        fileName: filename.replace("%", "percent"),
        base64: returnScaledFabricCanvasDataUrl(
          downloadCanvasIndex.current[filename],
          imageOption,
          quality,
        ),
      });
    }

    let specificOrderedIndicesIndex = 0;
    const maxIndex = Math.max(...specificOrderedIndices);
    const masterLauncherArray = [];
    for (let i = 0; i <= maxIndex; i++) {
      if (specificOrderedIndices.includes(i)) {
        masterLauncherArray.push(
          exportingCanvasData[specificOrderedIndicesIndex],
        );
        specificOrderedIndicesIndex++;
      } else {
        masterLauncherArray.push({ fileName: "", base64: "" });
      }
    }

    const assetLauncherOrderedImages = [];
    for (const idx of specificOrderedIndices) {
      assetLauncherOrderedImages.push(masterLauncherArray[idx]);
    }
    return fromAssetLauncher ? assetLauncherOrderedImages : exportingCanvasData;
  };

  const getExportingCanvases = (filenames: string[]) => {
    return Object.keys(downloadCanvasIndex.current).reduce((acc, filename) => {
      if (filenames.includes(filename)) {
        acc[filename] = downloadCanvasIndex.current[filename];
      }

      return acc;
    }, {} as Record<string, fabric.Canvas>);
  };

  const processVideoAsset = async (
    filename: string,
    wfProjectNumber: string,
  ) => {
    if (
      !downloadVideoCanvasIndex.current ||
      Object.keys(downloadVideoCanvasIndex.current).length < 1
    ) {
      return null;
    }

    const videoFabricCanvasIndex = downloadVideoCanvasIndex.current[filename];
    const canvasIndex = downloadIndex.current[filename];

    if (!videoFabricCanvasIndex || !canvasIndex) {
      return null;
    }

    const canvasId = uuidv4();
    const exportedAssetName =
      `${canvasId}_${wfProjectNumber}_${filename}`.replace("/", "-");

    const canvasHtmlElement = canvasIndex as any;
    const videoObjects = videoFabricCanvasIndex
      .getObjects()
      .filter(
        obj => (obj as IExtendedFabricObject).customType === "selected_video",
      ) as IExtendedFabricObject[];
    const hasVideos = videoObjects.length > 0;

    // This obj is meant to keep the types consistent for asset objects
    const returnObj = {
      fileName: exportedAssetName,
      base64: "",
      isVideo: true,
    };

    if (!canvasHtmlElement || !hasVideos) {
      return returnObj;
    }

    prepareAllVideosInCanvas({
      canvas: videoFabricCanvasIndex,
      playVideo: true,
    });

    return returnObj;
  };

  const context: IRenderTemplateContext | null = useMemo(() => {
    if (config) {
      const getStampData = createGetStampData();
      const getCanvasData = createGetCanvasData();
      const getDealerData = createGetDealerData();
      const getOemData = createGetBrandData();
      const getDisclosureData = createGetDisclosureData();
      const getExceptionData = createGetExceptionData();
      const getOfferData = createGetOfferData();
      const uploadMedia = createUploadMedia();

      return {
        getStampData,
        getCanvasData,
        getOemData,
        getDealerData,
        getDisclosureData,
        renderTemplate: createRenderTemplate(
          getCanvasData,
          getStampData,
          getOemData,
          getDealerData,
          getDisclosureData,
          getExceptionData,
          getOfferData,
        ),
        uploadMedia,
        renderStamp: createRenderStamp(
          getStampData,
          getOemData,
          getDealerData,
          getOfferData,
        ),
        renderPreview: createRenderPreview(
          getStampData,
          getOemData,
          getDealerData,
          getDisclosureData,
          getExceptionData,
          getOfferData,
        ),
        addCanvasToIndex,
        removeCanvasFromIndex,
        addFabricCanvasToIndex,
        removeFabricCanvasFromIndex,

        downloadAll,
        toDataURLsAndFilename,

        uploadedVideoUrls: uploadedVideoUrlRef.current,

        processVideoAsset,
        videoFilenames: () => Object.keys(downloadVideoCanvasIndex.current),
        getExportingCanvases,
      };
    }

    return null;
  }, [config]);

  return (
    <RenderTemplateContext.Provider value={context}>
      {children}
    </RenderTemplateContext.Provider>
  );
};

const mapStateToProps = ({
  configuration,
}: {
  configuration: IConfigurationState;
}) => {
  const { config, feed } = configuration;
  return { config, feed };
};

export default connect(mapStateToProps)(RenderTemplateProvider);
