import { Form, Input, Typography } from "antd";
import { useMemo, useState } from "react";
import { EverythingAdHtml } from "shared/types/adLibrary";
import styles from "./GtmInput.module.scss";

export const GtmInput = ({
  gtmId,
  everythingAd,
  updateEverythingAd,
}: {
  gtmId: string | undefined;
  everythingAd: EverythingAdHtml;
  updateEverythingAd: (
    attribute: { [key: string]: string },
    everythingAd: EverythingAdHtml,
  ) => void;
}) => {
  // GTM ID is in the format GTM-XXXXX, we only want the XXXXX part
  const gtmInitialValue = useMemo(() => gtmId?.split("-")[1] ?? "", [gtmId]);
  const [newGtm, setNewGtm] = useState(gtmInitialValue);
  const [isEditing, setIsEditing] = useState(false);

  const onEditGtm = () => {
    setIsEditing(false);
    if (newGtm === everythingAd.gtmId) return;
    updateEverythingAd({ gtmId: newGtm ? `GTM-${newGtm}` : "" }, everythingAd);
  };

  return isEditing ? (
    <Form initialValues={{ gtm: newGtm }}>
      <Form.Item
        className={styles.gtmInput}
        name={"gtm"}
        rules={[
          {
            pattern: /^[A-Z0-9]{1,10}$/,
            message: "Invalid GTM ID",
          },
        ]}
      >
        <Input
          addonBefore="GTM-"
          onChange={e => setNewGtm(e.target.value)}
          value={newGtm}
          onPressEnter={onEditGtm}
          onBlur={onEditGtm}
        />
      </Form.Item>
    </Form>
  ) : (
    <Typography.Text
      data-cy="edit-gtm-id"
      editable={{
        onStart: () => setIsEditing(true),
      }}
    >
      {newGtm}
    </Typography.Text>
  );
};
