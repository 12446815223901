import { useColumn } from "../useColumn";

export const useColCreatedBy = () => {
  return useColumn("createdby", {
    title: "Created By",
    renderFn(text: string, record: WDAsset | WDFolder) {
      if (record.type === "asset") {
        return <span>{record.user?.name || ""}</span>;
      }
    },
  });
};
