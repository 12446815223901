import { Button, DatePicker, Drawer, message } from "antd";
import moment, { Moment } from "moment";
import { useState } from "react";
import {
  useRemoveExpirationMutation,
  useSetExpirationMutation,
} from "redux/media/media.api";
import { useMediaActions } from "redux/media/media.slice";
import { handleApiError } from "./handleApiError";
import { useAppSelector } from "./redux";
import { useFetchPerms } from "./useFetchPerms";
import { useSelected } from "./useSelected";

import styles from "./FileExpire.module.scss";

export const FileExpire = () => {
  const assetsToExpire = useAppSelector(({ media }) => media.assetsToExpire);
  const { setAssetsToExpire } = useMediaActions();
  const { clearSelected } = useSelected();
  const [setExpiration, setProps] = useSetExpirationMutation();
  const [removeExpiration, removeProps] = useRemoveExpirationMutation();
  const [timestamp, setTimestamp] = useState<number>();
  const defaultDalue = getDefaultValue(assetsToExpire);
  const visible = !!assetsToExpire;
  const folderIds = assetsToExpire?.map(a => a.folder.id) || [];
  const { canEdit } = useFetchPerms(folderIds, visible);

  const onClose = () => {
    setAssetsToExpire();
  };

  const onChange = (value: Moment | null) => {
    setTimestamp(value?.unix());
  };

  const onSubmit = (
    trigger: typeof setExpiration | typeof removeExpiration,
  ) => {
    return async () => {
      try {
        const payload = assetsToExpire!.map(({ id }) => ({ id, timestamp }));
        await trigger({ payload }).unwrap();
        clearSelected();
        message.success(`${assetsToExpire!.length} files were updated`);
        onClose();
      } catch (error) {
        handleApiError(error);
      }
    };
  };

  return (
    <Drawer
      title="Set Expiration Date"
      destroyOnClose
      maskClosable
      width={500}
      onClose={onClose}
      visible={visible}
      footerStyle={{ display: "flex", justifyContent: "end", gap: "1em" }}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="remove"
          danger
          onClick={onSubmit(removeExpiration)}
          loading={removeProps.isLoading}
          disabled={!canEdit}
        >
          Remove
        </Button>,
        <Button
          key="ok"
          type="primary"
          onClick={onSubmit(setExpiration)}
          loading={setProps.isLoading}
          disabled={!timestamp || !canEdit}
        >
          Save
        </Button>,
      ]}
    >
      <div className={styles.body}>
        <p>Expiration Date</p>
        <DatePicker
          defaultValue={defaultDalue}
          onChange={onChange}
          disabledDate={d => d.isBefore(moment())}
        />
      </div>
    </Drawer>
  );
};

const getDefaultValue = (assetsToExpire?: WDAsset[]) => {
  if (assetsToExpire) {
    const len = assetsToExpire.length;
    const values = assetsToExpire.map(asset => asset.expiration?.dateUnix);
    const allEqual = new Set(values).size === 1;

    if (allEqual || len === 1) {
      const unix = assetsToExpire[0].expiration?.dateUnix;
      return unix ? moment.unix(unix) : undefined;
    }
  }
};
