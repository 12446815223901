import { Button } from "antd";
import "./Footer.scss";

interface IFooterProps {
  disabled: boolean;
  onModalClose: () => void;
  onSaveRule: () => void;
  onFormClear: () => void;
  onDeleteRule: () => void;
}

const Footer = ({ onFormClear, onSaveRule, disabled }: IFooterProps) => {
  return (
    <div className="rule-form-footer">
      <div className="right">
        <Button
          type={"text"}
          className="clear-rule-button"
          size="large"
          onClick={e => {
            e.preventDefault();
            onFormClear();
          }}
        >
          CLEAR
        </Button>
        <Button
          size="large"
          type="primary"
          disabled={disabled}
          onClick={e => {
            e.preventDefault();
            onSaveRule();
          }}
        >
          SAVE
        </Button>
      </div>
    </div>
  );
};

export default Footer;
