import { memo, ReactElement } from "react";
import { TreeSelect } from "antd";

import LoadingContent from "shared/components/select/LoadingContent";
import { fullCountryStateOptions } from "shared/constants/dataManagement";

interface IProps {
  className?: string;
  value?: string | string[];
  defaultValue?: string | string[];
  disabled?: boolean;
  placeholder?: string;
  multiple?: boolean;
  style?: React.CSSProperties;
  onChange?(value: string | string[]): void;
  onFocus?: React.FocusEventHandler<HTMLElement>;
}

// *************************************************************************************************
const GenericLocation = (props: IProps): ReactElement => {
  const isLoadingLocations = false;
  const locations = fullCountryStateOptions;

  const loading = isLoadingLocations;
  const allLocations = loading ? [] : locations;

  return (
    <TreeSelect
      className={props.className}
      allowClear={true}
      showSearch
      style={props.style}
      defaultValue={props.defaultValue}
      value={props.value}
      placeholder={props.placeholder}
      onFocus={props.onFocus}
      treeNodeFilterProp={'title'}
      onChange={props.onChange}
      loading={loading}
      disabled={props.disabled}
      notFoundContent={loading ? <LoadingContent /> : undefined}
      treeCheckable={props.multiple === true}
      multiple={props.multiple === true}
      treeDefaultExpandAll={!props.multiple}
    >
      {allLocations.map(({ name, abbreviation, states }) => [
        <TreeSelect.TreeNode
          key={abbreviation}
          title={name}
          value={abbreviation}
          selectable={false}     
        >
          {states.map(({ name, abbreviation }) => {
            return (
              <TreeSelect.TreeNode
                key={abbreviation}
                value={abbreviation}
                title={name}
                isLeaf={true}
              />
            );
          })}
        </TreeSelect.TreeNode>,
      ])}
    </TreeSelect>
  );
};

export const GenericLocationSelect = memo(GenericLocation);
