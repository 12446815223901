import { setSelectedFacebookAccounts } from "redux/adLibrary/adLibrary.slice";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { useAppShallowEqualSelector } from "shared/hooks/useAppSelector";

import { queryClient } from "queryClient";
import { useFetchSessionJson } from "shared/hooks/adLibrary/adHistory/useFetchSessionJson";

import useGetIsMounted from "shared/hooks/useGetIsMounted";
import useHandleLoadSessionData from "./useHandleLoadSessionData";

import { isEmpty } from "lodash";

import { IAdHistorySession } from "shared/types/adLibrary";
import { IFacebookAccount } from "screens/adLibrary/facebookUtils/types";

const useSetSessionJsonData = () => {
  const dispatch = useAppDispatch();
  const getIsMounted = useGetIsMounted();

  const { sessionId, selectedAccounts } =
    useAppShallowEqualSelector(({ adLibrary }) => ({
      sessionId: adLibrary.adLoad.adLoadHistory?.sessionId,
      selectedAccounts: adLibrary.selectedFacebookAccounts,
    })) ?? {};

  const locationSearchSplit = location.search.split("?session_id=") ?? [];
  const currentSessionId =
    sessionId || locationSearchSplit?.[1]?.split("&")?.[0];

  const currentSession: IAdHistorySession | undefined = (
    (queryClient.getQueryData(["adHistorySessions", currentSessionId]) ??
      []) as IAdHistorySession[]
  )?.[0];

  const handleLoadSessionData = useHandleLoadSessionData();

  const { refetch: getCurrentSessionJson } = useFetchSessionJson({
    params: {
      sessionId: currentSessionId,
      sessionJsonUrl: currentSession?.jsonUrl || "",
    },
  });

  return async () => {
    if (!getIsMounted()) return;

    const accountsToUse = isEmpty(currentSession?.accounts?.facebook)
      ? selectedAccounts
      : (currentSession?.accounts?.facebook as IFacebookAccount[]);

    const response = await getCurrentSessionJson?.();
    // this allows the accounts to be set even if there is no session json to be pulled(like when starting a session)
    dispatch(setSelectedFacebookAccounts(accountsToUse || null));

    // Apply if entering from session where ther is no ad list (if session was close before rules were applied)
    if (!response.data) {
      handleLoadSessionData({
        session: currentSession,
      });
    }
  };
};

export default useSetSessionJsonData;
