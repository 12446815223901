import { format, getYear, isValid } from "date-fns";
import CellError from "../editableCell/CellError";
import { isNumber } from "lodash";

interface DateFieldProps {
  value: string | string[];
}

const DateField = ({ value }: DateFieldProps) => {
  if (value && isNumber(value)) {
    const valueDate = new Date(value);

    if (!!valueDate && isValid(valueDate) && getYear(valueDate) > 2000) {
      return <span>{format(new Date(value), "MM/dd/yy")}</span>;
    }
  }

  return <CellError errorMessage="Invalid date" />;
};

export default DateField;
