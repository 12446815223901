import { memo, useEffect, useState } from "react";
import { Form, Select, Button, Space } from "antd";
import { EyeInvisibleOutlined } from "@ant-design/icons";
import SingleBrandSelect from "shared/components/SingleBrandSelect";
import { useFetchInstantExperiences } from "shared/hooks/adLibrary/useFetchInstantExperiences";
import { IDestinationValues } from "shared/types/adLibrary";
import { IFormValidationAttributes } from "shared/types/inputValues";
import Modal from "antd/lib/modal/Modal";
import LivePreview from "screens/designStudio/editor/instantExperience/LivePreview";

interface IProps {
  enablePreview?: boolean;
  destination?: IDestinationValues;
  selectedBrand?: string;
  setSelectedBrand(selectedBrand?: string): void;
  onChange?(destinationValues: IDestinationValues): void;
  shouldValidate?: boolean;
  disabled?: boolean;
}

const INPUT_WIDTH = 300;

const InstantExperienceSelect = ({
  enablePreview,
  selectedBrand,
  setSelectedBrand,
  destination,
  onChange,
  shouldValidate,
  disabled,
}: IProps) => {
  const { data: instantExperiences, isLoading: isLoadingInstantExperiences } =
    useFetchInstantExperiences();

  const [brandSelectValidationAttributes, setBrandSelectValidationAttributes] =
    useState<IFormValidationAttributes | null>(null);

  // This helps prevent dropdown from
  const [manualFalse, setManualFalse] = useState<undefined | false>();
  const [displayInstantExperiencePreview, setDisplayInstantExperiencePreview] =
    useState(false);

  useEffect(() => {
    if (!destination?.instantExperienceId) {
      return;
    }

    const brand = instantExperiences?.find(
      ie => ie.id === destination.instantExperienceId,
    )?.oem;

    setSelectedBrand(brand);
  }, [destination?.instantExperienceId, instantExperiences, setSelectedBrand]);

  const hasInstantExperienceError =
    shouldValidate && !destination?.instantExperienceId;

  const selectedInstantExperience = instantExperiences?.find(
    ie => ie.id === destination?.instantExperienceId,
  );

  return (
    <Space align="start">
      <Form.Item
        validateStatus={brandSelectValidationAttributes?.validationStatus}
        help={brandSelectValidationAttributes?.feedbackMessage}
        style={{
          width: !enablePreview ? INPUT_WIDTH / 2 : INPUT_WIDTH / 2 - 10,
        }}
      >
        <SingleBrandSelect
          selectedBrand={selectedBrand}
          disabled={disabled}
          onChange={brand => {
            setSelectedBrand(brand);
            // the IE has to be reset when selecting a brand
            onChange?.({
              instantExperienceId: undefined,
            });
          }}
          onFocus={() => {
            setBrandSelectValidationAttributes(null);
          }}
        />
      </Form.Item>
      <Form.Item
        style={{
          width: !enablePreview ? INPUT_WIDTH : INPUT_WIDTH / 2 + 10,
          marginBottom: 0,
        }}
        validateStatus={hasInstantExperienceError ? "error" : undefined}
        help={hasInstantExperienceError ? "Select an Everything Ad" : ""}
      >
        <Select
          style={{ maxWidth: 230 }}
          showSearch
          filterOption={(input, option) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          open={manualFalse}
          placeholder="Type to search Everything Ads"
          loading={isLoadingInstantExperiences}
          disabled={disabled}
          value={destination?.instantExperienceId}
          onMouseEnter={() => {
            if (selectedBrand) {
              return;
            }
            setManualFalse(false);
          }}
          onFocus={event => {
            if (selectedBrand) {
              setManualFalse(undefined);
              return;
            }
            setBrandSelectValidationAttributes({
              hasFeedback: true,
              validationStatus: "error",
              feedbackMessage: "Select a brand first",
            });
            event.target.blur();
          }}
          onChange={value =>
            onChange?.({
              instantExperienceId: value,
            })
          }
          data-cy="instant-experience-select"
        >
          {instantExperiences
            ?.filter(ie => ie.oem === selectedBrand)
            ?.map(ie => (
              <Select.Option key={ie.id} value={ie.id || ""}>
                {ie.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      {enablePreview && (
        <Form.Item>
          <Button
            onClick={() => {
              setDisplayInstantExperiencePreview(true);
            }}
            disabled={!selectedInstantExperience}
          >
            <EyeInvisibleOutlined /> View
          </Button>
        </Form.Item>
      )}
      {selectedInstantExperience && (
        <Modal
          visible={displayInstantExperiencePreview}
          title="Instant Experience Preview"
          width={405}
          bodyStyle={{ padding: 0 }}
          footer={false}
          closable
          onCancel={() => setDisplayInstantExperiencePreview(false)}
          style={{ top: 0 }}
        >
          <LivePreview instantExperience={selectedInstantExperience} />
        </Modal>
      )}
    </Space>
  );
};

export default memo(InstantExperienceSelect);
