import { DesignStudioLayout } from "./DesignStudioLayout";
import { DesignStudioTableHeader } from "./designStudioLayout/designStudioTable/DesignStudioTableHeader";
import { DesignStudioDataProvider } from "./context/DesignStudioDataProvider";
import { useState } from "react";
import { setArtboardToUpdate } from "redux/designStudio/designStudio.slice";
import { DesignStudioDrawerState } from "screens/DesignStudio";
import { ArtboardDrawer } from "./ArtboardDrawer";
import { DrawerProvider } from "./DrawerContext/DrawerContext";
import NewStampDrawer from "./NewStampDrawer";
import NewTemplateDrawer from "./NewTemplateDrawer";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { EditorDrawer } from "./designStudioV2/EditorDrawer";
import { TemplatePreview } from "./designStudioLayout/shared/templatePreview/TemplatePreview";
import { FilterDrawer } from "./designStudioV2/FilterDrawer";
import { DESIGN_STUDIO_V2_EDITOR_ENABLED } from "./designStudioV2/constants";

export const DesignStudioV2 = () => {
  const [drawer, setDrawer] = useState<DesignStudioDrawerState>(null);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const artboardToUpdate = useAppSelector(
    ({ designStudio }) => designStudio.artboardToUpdate,
  );

  const openCreateDrawer = (type: "template" | "stamp" | "artboard") => {
    setDrawer({
      show: true,
      mode: "CREATE",
      type,
    });
  };

  const closeDrawer = () => {
    setDrawer(null);
  };

  return (
    <DesignStudioDataProvider>
      <DesignStudioTableHeader
        openCreateDrawer={openCreateDrawer}
        openFilterDrawer={() => setFilterDrawerOpen(true)}
      />
      <DesignStudioLayout />
      <TemplatePreview />
      <DrawerProvider
        artboardToEdit={artboardToUpdate}
        mode={drawer?.type === "artboard" ? drawer.mode : undefined}
        resetArtboardToEdit={setArtboardToUpdate}
        closeDrawer={closeDrawer}
      >
        <ArtboardDrawer
          showArtboardDrawer={drawer?.type === "artboard" && drawer?.show}
        />
      </DrawerProvider>

      <NewTemplateDrawer
        mode={drawer?.type === "template" ? drawer.mode : undefined}
        showNewTemplateDrawer={drawer?.type === "template" && drawer.show}
        closeDrawer={closeDrawer}
      />

      <NewStampDrawer
        mode={drawer?.type === "stamp" ? drawer.mode : undefined}
        showNewStampDrawer={drawer?.type === "stamp" && drawer.show}
        closeDrawer={closeDrawer}
      />
      <FilterDrawer
        isOpen={filterDrawerOpen}
        closeDrawer={() => setFilterDrawerOpen(false)}
      />
      {DESIGN_STUDIO_V2_EDITOR_ENABLED && <EditorDrawer />}
    </DesignStudioDataProvider>
  );
};
