import { memo } from "react";
import CardListContainer from "shared/components/CardListContainer";
import TemplateCard from "./templateCardListContainer/TemplateCard";

import {
  HeaderMenu,
  IArtboard,
  ITemplate,
  AllPublishedStatus,
} from "shared/types/designStudio";

import "./TemplateCardListContainer.scss";

interface IListContainer {
  selectedHeader: HeaderMenu;
  templates: ITemplate[];
  previewTemplateId: string;
  setPreviewTemplateId: (previewTemplateId: string) => void;

  selectedFilterOem: string[];
  selectedFilterStore: string[];
  selectedFilterTags: string[];
  searchInput: string;
  templatesAllPublishedUnpublished: AllPublishedStatus;

  canvas: fabric.Canvas | null;
  setCanvas: (canvas: fabric.Canvas) => void;
  addDeletedIds: (deletedIds: string) => void;
}

const TemplateCardListContainer: React.FC<IListContainer> = ({
  selectedHeader,
  templates,
  previewTemplateId,
  setPreviewTemplateId,
  canvas,
  setCanvas,
  addDeletedIds,
}) => {
  // the templates must be sorted and grouped by its artboard.
  // So all element in 'templates' must have same artbard.
  const artboard: IArtboard | null =
    (templates && templates.length > 0 && templates[0].artboard) || null;

  if (!artboard) {
    return null;
  }

  return (
    <div className="template-list-container">
      <div className="template-card-list-container">
        <CardListContainer
          virtualized={true}
          cards={templates.map(template => (
            <TemplateCard
              key={template.id || `${template.name}-${template.createdAt}`}
              template={template}
              selectedHeader={selectedHeader}
              previewTemplateId={previewTemplateId}
              setPreviewTemplateId={setPreviewTemplateId}
              canvas={canvas}
              setCanvas={setCanvas}
              archiveTemplate={addDeletedIds}
            />
          ))}
        />
      </div>
    </div>
  );
};

export default memo(TemplateCardListContainer);
