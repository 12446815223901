/* eslint-disable react/display-name */
import { Col, Modal, Row, Switch, Table } from "antd";
import { ColumnProps } from "antd/es/table";
import { useState, useEffect } from "react";
import { filterEnableBy } from "../../utils/helpers";
import { IBrandRecord } from "shared/types/brandManagement";
import { TableRowSelection } from "antd/es/table/interface";
import ToolbarTable, {
  ToolbarButton,
} from "shared/components/toolbarTable/ToolbarTable";
import { filterBySearch } from "utils/helpers.toolbar";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import { industryType } from "utils/helpers";

import { useAppDispatch } from "shared/hooks/useAppDispatch";

import { deleteOem } from "redux/oemManagement/oemManagement.slice";
import actions from "redux/rootActions";
import EditCell from "screens/userManagement/userTable/EditCell";
import IntermediateSwitch from "shared/button/IntermediateSwitch";
import { useIsAdmin } from "shared/hooks/useIsAdmin";
import { CAM_ENABLED } from "shared/components/media";
import {
  compareStringBy,
  getFiltersFromData,
  onFilterBy,
} from "utils/helpers.table";
interface IOemTable {
  userRole: string;
  oems: IBrandRecord[];
  processingUpload: boolean;
  onClickNew: () => void;
  onClickEdit: (record: IBrandRecord) => void;
}

const OemTable: React.FC<IOemTable> = ({ oems, onClickNew, onClickEdit }) => {
  const isAdmin = useIsAdmin();

  const dispatch = useAppDispatch();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [currentRows, setCurrentRows] = useState<IBrandRecord[]>([]);
  const [currentActiveRows, setCurrentActiveRows] = useState<IBrandRecord[]>(
    [],
  );
  const [currentInactiveRows, setCurrentInactiveRows] = useState<
    IBrandRecord[]
  >([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredOems, setFilteredOems] = useState<IBrandRecord[]>(oems || []);

  const [inputTimer, setInputTimer] = useState<NodeJS.Timeout | undefined>(
    undefined,
  );

  useEffect(() => {
    setFilteredOems(oems);
  }, [oems]);

  useEffect(() => {
    if (inputTimer) {
      clearTimeout(inputTimer);
    }
    const tmpTimer = setTimeout(() => {
      const updatedOems = filterBySearch(oems, searchValue);
      setFilteredOems(updatedOems);
    }, 1000);

    if (setInputTimer) {
      setInputTimer(tmpTimer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, oems]);

  const onClickToggleActive = async (
    record: IBrandRecord,
    valToUpdate: boolean,
  ) => {
    const updateRecord = {
      ...record,
      enabled: valToUpdate,
    };
    await dispatch(actions.oemManagement.updateOem(updateRecord));
  };

  const onClickToggleActiveAll = async (
    records: IBrandRecord[],
    valToUpdate: boolean,
  ) => {
    const promises = records.map((record, i) => {
      const updateOem = { ...record, enabled: valToUpdate };
      const isFinalRecord = i === records.length - 1;
      return dispatch(
        actions.oemManagement.updateOem(updateOem, isFinalRecord),
      );
    });
    await Promise.all(promises);
  };

  const isAllActive = !filteredOems.some(oem => !filterEnableBy(oem, true));

  const isAllInactive = !filteredOems.some(oem => !filterEnableBy(oem, false));

  const onChangeEnabled = async (
    record: IBrandRecord,
    valToUpdate: boolean,
  ) => {
    isAdmin && (await onClickToggleActive(record, valToUpdate));
    if (currentRows.some(row => row.key === record.key)) {
      if (valToUpdate) {
        setCurrentActiveRows([...currentActiveRows, record] ?? []);
        setCurrentInactiveRows(
          [...currentInactiveRows.filter(row => row.key !== record.key)] ?? [],
        );
      } else {
        setCurrentActiveRows(
          [...currentActiveRows.filter(row => row.key !== record.key)] ?? [],
        );
        setCurrentInactiveRows([...currentInactiveRows, record] ?? []);
      }
    }
  };

  const onClickIntermediateSwitch = () =>
    isAdmin &&
    onClickToggleActiveAll(
      filteredOems.filter(oem => filterEnableBy(oem, false)),
      true,
    );

  const onChangeTitleSwitch = (valToUpdate: boolean) => {
    isAdmin &&
      onClickToggleActiveAll(
        filteredOems.filter(oem => !filterEnableBy(oem, valToUpdate)),
        valToUpdate,
      );
  };
  const getFilters = getFiltersFromData(oems);

  const columns: Array<ColumnProps<IBrandRecord>> = [
    {
      key: "enabled",
      title: () =>
        !isAllActive && !isAllInactive ? (
          <IntermediateSwitch onClick={onClickIntermediateSwitch} />
        ) : (
          <Switch
            onChange={valToUpdate => onChangeTitleSwitch(valToUpdate)}
            checked={isAllActive}
          />
        ),
      width: "5%",
      render: record => (
        <Switch
          disabled={!isAdmin}
          onChange={valToUpdate => onChangeEnabled(record, valToUpdate)}
          checked={record.enabled ?? false}
        />
      ),
    },
    {
      key: "oemName",
      title: `${industryType("auto") ? "OEM" : "Brand"}`,
      dataIndex: "oemName",
      width: CAM_ENABLED ? "95%" : "20%",
      sorter: compareStringBy("oemName"),
      filters: getFilters("oemName"),
      onFilter: onFilterBy("oemName"),
      // eslint-disable-next-line react/display-name
      render: (name: string, oem: IBrandRecord) => (
        <EditCell
          disabled={!isAdmin}
          name={name}
          onCellClick={() => isAdmin && onClickEdit(oem)}
        />
      ),
    },
    ...(CAM_ENABLED
      ? []
      : [
          {
            key: "eventLogos",
            title: `${
              industryType("auto") ? "Event Logos" : "Additional Logos"
            }`,
            dataIndex: "eventLogos",
            width: "77%",

            // eslint-disable-next-line
            render: (_text: string, record: IBrandRecord) => (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {[
                  ...(record?.logoUrlsFromS3?.squareEventImagesFromS3 || []),
                ].map((logoUrl: string, index: number) => {
                  return (
                    <span
                      key={index}
                      style={{
                        paddingRight: "10px",
                        background: "lightgray",
                        width: 100,
                        height: 100,
                      }}
                    >
                      <img alt="" src={logoUrl} height="100" width="100" />
                      <br />
                    </span>
                  );
                })}
              </div>
            ),
          },
        ]),
  ];

  const rowSelection: TableRowSelection<IBrandRecord> = {
    selectedRowKeys,
    onChange: (keys, rows) => {
      setSelectedRowKeys(keys);
      setCurrentRows(rows);
      setCurrentActiveRows(rows.filter(row => filterEnableBy(row, true)) ?? []);
      setCurrentInactiveRows(
        rows.filter(row => filterEnableBy(row, false)) ?? [],
      );
    },
  };
  const singleSelected = selectedRowKeys && selectedRowKeys.length === 1;
  const hasSelected = selectedRowKeys && selectedRowKeys.length > 0;
  const resetSelection = () => setSelectedRowKeys([]);
  const onClickDelete = (record: IBrandRecord) => {
    Modal.confirm({
      title: "Are you sure you want to delete this OEM?",
      icon: <ExclamationCircleOutlined />,
      className: "confirm-delete-modal",
      onOk() {
        resetSelection();
        dispatch(deleteOem(record));
      },
      okType: "danger",
    });
  };

  const selectedAllActive = currentInactiveRows.length === 0;
  const selectedAllInactive = currentActiveRows.length === 0;

  const onClickActiveAll = async () => {
    if (!selectedAllActive) {
      await onClickToggleActiveAll(currentInactiveRows, !selectedAllActive);
      setCurrentActiveRows([...currentActiveRows, ...currentInactiveRows]);
      setCurrentInactiveRows([]);
    } else {
      await onClickToggleActiveAll(currentActiveRows, !selectedAllActive);
      setCurrentInactiveRows([...currentActiveRows, ...currentInactiveRows]);
      setCurrentActiveRows([]);
    }
  };

  const onClickActiveSome = async () => {
    await onClickToggleActiveAll(currentRows, true);
    setCurrentActiveRows(currentRows);
    setCurrentInactiveRows([]);
  };

  const toolbarContents: ToolbarButton = {
    New: {
      extraInfo: {
        FULL: !isAdmin,
      },
      disabled: !isAdmin,
      onClick: onClickNew,
    },
    Edit: {
      disabled: !singleSelected || !isAdmin,
      onClick: () => onClickEdit(currentRows[0]),
    },
    Delete: {
      disabled: !singleSelected || !isAdmin,
      onClick: () => onClickDelete(currentRows[0]),
    },
    ActiveAll: {
      display: selectedAllActive || selectedAllInactive,
      disabled: !hasSelected || !isAdmin,
      onClick: () => onClickActiveAll(),
      checked: hasSelected && selectedAllActive,
    },
    ActiveSome: {
      display: !selectedAllActive && !selectedAllInactive,
      disabled: !hasSelected || !isAdmin,
      onClick: () => onClickActiveSome(),
    },
  };

  return (
    <Row style={{ padding: "14px 0" }}>
      <Col span={24}>
        <ToolbarTable
          toolbarContents={toolbarContents}
          searchPlaceholder="Search"
          searchValue={searchValue}
          onSearch={setSearchValue}
          titleTooltip="Search by any information"
        />
        <Row>
          <Col span={24}>
            <Table<IBrandRecord>
              data-cy="oem-table-result"
              className="data-management-table"
              size="small"
              scroll={{ x: 1000, y: "calc(100vh - 209px)" }}
              columns={columns}
              dataSource={filteredOems}
              pagination={false}
              rowSelection={rowSelection}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default OemTable;
