import { useMemo, useContext } from "react";
import actions from "redux/rootActions";
import { Col, Row, Select, Spin, Form } from "antd";
import { useAppShallowEqualSelector } from "shared/hooks/useAppSelector";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { useUser } from "shared/hooks/useUser";
import { DropDown } from "./drawerRow/DropDown";
import { filterData } from "../utils";
import { DrawerRowProps } from "../types";
import { DrawerContext } from "../../DrawerContext/DrawerContext";
import { AppState } from "redux/store";

export const DrawerRow = ({ rowTitle }: DrawerRowProps) => {
  const {
    onRowSelect,
    onRowDeselect,
    searchBy,
    selectedBrandNames,
    selectedAccountNames,
    selectedTagNames,
    setSearchBy,
    setSelectedTagNames,
  } = useContext(DrawerContext);

  const user = useUser();
  const dispatch = useAppDispatch();
  const { oemRecords } = useAppShallowEqualSelector(
    ({ oemManagement }: AppState) => ({
      oemRecords: oemManagement.oemRecords,
    }),
  );
  const { stores, tags } = useAppShallowEqualSelector(
    ({ designStudio }: AppState) => ({
      stores: designStudio.stores,
      tags: designStudio.tags,
    }),
  );

  const data = filterData({
    brandRecords: oemRecords,
    accounts: stores,
    tags,
    searchBy,
  });
  const { filteredOems, filteredStores, filteredTags } = data;

  const createNewTag = () => {
    const newTag = {
      name: searchBy,
      createdAt: new Date().getTime(),
      createdBy: user?.email || "",
    };
    setSelectedTagNames([...selectedTagNames, newTag.name]);
    dispatch(actions.designStudio.createTag(newTag));
    setSearchBy("");
  };

  const getSelectValue = () => {
    if (rowTitle.type === "brands") return selectedBrandNames;
    if (rowTitle.type === "accounts") return selectedAccountNames;
    if (rowTitle.type === "tags") return selectedTagNames;
    return [];
  };

  const onFocus = () => {
    if (!oemRecords.length) dispatch(actions.oemManagement.getOems());
    if (!stores.length) dispatch(actions.designStudio.fetchData("accounts"));
    if (!tags.length) dispatch(actions.designStudio.fetchData("tags"));
    setSearchBy("");
  };

  const onSelect = (value: string) => {
    setSearchBy("");
    onRowSelect(rowTitle.type, value);
  };

  const onDeselect = (value: string) => {
    onRowDeselect(rowTitle.type, value);
  };

  const isBrandsOrAccounts =
    rowTitle.type === "brands" || rowTitle.type === "accounts";
  const emptyOemOrStore =
    filteredOems.length === 0 || filteredStores.length === 0;

  const displaySpinner = isBrandsOrAccounts && emptyOemOrStore;

  const normalizedOptionList = useMemo(() => {
    if (rowTitle.type === "brands") {
      return filteredOems.map(brand => ({ name: brand.oemName }));
    }
    if (rowTitle.type === "accounts") {
      return filteredStores.map(account => ({ name: account.dealer_name }));
    }
    if (rowTitle.type === "tags") {
      return filteredTags.map(tag => ({ name: tag.name }));
    }
  }, [filteredOems, filteredStores, filteredTags, rowTitle.type]);

  return (
    <Row key={`Row-select-${rowTitle.type}`} gutter={16}>
      <Col span={24}>
        <Form.Item label={rowTitle.title}>
          <Select
            showSearch={true}
            key={`new-themebg-${rowTitle.type}-select`}
            mode="multiple"
            filterOption={true}
            style={{ width: "100%" }}
            value={getSelectValue()}
            onFocus={onFocus}
            loading={false}
            onSearch={setSearchBy}
            onSelect={onSelect}
            onDeselect={onDeselect}
            dropdownRender={menu => (
              <>
                {displaySpinner && (
                  <Spin
                    spinning={true}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />
                )}
                {!isBrandsOrAccounts && !menu.props.options.length && (
                  <DropDown searchBy={searchBy} createNewTag={createNewTag} />
                )}
                {!emptyOemOrStore && !!menu.props.options.length && menu}
              </>
            )}
          >
            {normalizedOptionList?.map(item => (
              <Select.Option
                key={`${item.name}-option-key-${item.name}`}
                value={item.name}
              >
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
};
