import { message } from "antd";
import filter from "lodash/filter";
import includes from "lodash/includes";

import { useEffect, useMemo, useState } from "react";

// components
import FormDrawer from "shared/components/FormDrawer";

// types
import { IFormValidationAttributes } from "shared/types/inputValues";
import {
  ISingleLogoObject,
  TUploadSetLogosInDictionary,
} from "shared/types/uploadManagement";

// constants and utils
import { cleanNameStringForComparison, industryType } from "utils/helpers";
import { uploadImageTypesToS3 } from "utils/helpers.s3";
import DealerDetails from "./DealerDetails";
import { TDealerDrawerProps } from "./DealerDrawer.types";
import { DealerDrawerModals } from "./DealerDrawerModals";
import { DealerTabs } from "./DealerTabs";
import {
  doesSignUpFormHaveChanges,
  replaceDealerNameWithNewName,
  validDuplicate,
} from "./dealerDrawer.utils";
import { useIsAdmin } from "shared/hooks/useIsAdmin";

const defaultValidationAttributes = {
  timeoutId: undefined,
  validationStatus: undefined,
  hasFeedback: undefined,
  feedbackMessage: "",
};
const defaultLogoAttributes = {
  horizontalLogos: [],
  verticalLogos: [],
  squareLogos: [],
  horizontalLogosEvent: [],
  verticalLogosEvent: [],
  squareLogosEvent: [],
};

const DealerDrawer = ({
  initialDealerObj,
  mode,
  processingDealers,
  showAddDealerDrawer,
  closeAddDealerDrawer,
  submitDealerForm,
  deleteDealerForm,
  s3AssetBucket,
  processingUpload,
  uploadImages,
  dealers,
  dealerToEditWithLogos,
  setDealerToEditWithLogos,
  toggleNewDealerButtonClicked,
  logosUploadStatus,
  setLogoKeysToBeUploaded,
  feed,
  compareDealerToEditWithLogos,
  setCompareDealerToEditWithLogos,
}: TDealerDrawerProps) => {
  const isAdmin = useIsAdmin();
  // all the event logos will not be used since this is for Dealers
  const [logosInDictionary, setLogosInDictionary] = useState<
    Record<TUploadSetLogosInDictionary, ISingleLogoObject[]>
  >(defaultLogoAttributes);

  const [singleLogoObject, setSingleLogoObject] = useState<ISingleLogoObject>({
    group: "string",
    feedbackMessage: "",
  });

  const [shouldDeleteModal, toggleShouldDeleteModal] = useState<boolean>(false);

  const [
    IsSaveOrDiscardModalOpenDealerManagement,
    toggleIsSaveOrDiscardModalOpenDealerManagement,
  ] = useState<boolean>(false);

  const [dealerNameValidationAttributes, setDealerNameValidationAttributes] =
    useState<IFormValidationAttributes>(defaultValidationAttributes);

  const [dealerCodeValidationAttributes, setDealerCodeValidationAttributes] =
    useState<IFormValidationAttributes>(defaultValidationAttributes);

  const [dealerUrlValidationAttributes, setDealerUrlValidationAttributes] =
    useState<IFormValidationAttributes>(defaultValidationAttributes);

  const [
    dealerLogoUrlValidationAttributes,
    setDealerLogoUrlValidationAttributes,
  ] = useState<IFormValidationAttributes>(defaultValidationAttributes);

  const [validForm, setValidForm] = useState(true);

  message.config({ maxCount: 1 });

  const invalidUrls = useMemo(() => {
    if (!dealerToEditWithLogos.labels) {
      return false;
    }
    const duplicateLabels =
      filter(
        dealerToEditWithLogos.labels.map(({ name }) => name),
        (val, i, iteratee) => includes(iteratee, val, i + 1),
      ).length > 0;
    const emptyLabels =
      dealerToEditWithLogos.labels.filter(({ name, url }) => !url || !name)
        .length > 0;
    return duplicateLabels || emptyLabels;
  }, [dealerToEditWithLogos]);

  useEffect(() => {
    setLogosInDictionary(defaultLogoAttributes);
  }, []);

  useEffect(() => {
    const isUpdateValidation = mode === "UPDATE";
    const isDuplicateValidation = mode === "DUPLICATE";

    if (isUpdateValidation || isDuplicateValidation) {
      const invalidDealerName =
        dealerNameValidationAttributes.hasFeedback &&
        dealerNameValidationAttributes.validationStatus === "error";
      const invalidDealerCode =
        dealerCodeValidationAttributes.hasFeedback &&
        dealerCodeValidationAttributes.validationStatus === "error";
      const invalidDealerUrl =
        dealerUrlValidationAttributes.hasFeedback &&
        dealerUrlValidationAttributes.validationStatus === "error";

      const validFormUpdate = !(
        invalidDealerName ||
        invalidDealerCode ||
        invalidDealerUrl ||
        invalidUrls
      );

      setValidForm(validFormUpdate);

      return;
    }

    const invalidDealerNameStatus =
      !dealerNameValidationAttributes.validationStatus ||
      dealerNameValidationAttributes.validationStatus === "error";
    const invalidDealerAutoStatus =
      industryType("auto") &&
      (!dealerCodeValidationAttributes.validationStatus ||
        dealerCodeValidationAttributes.validationStatus === "error");
    const invalidDealerUrlStatus =
      !dealerUrlValidationAttributes.validationStatus ||
      dealerUrlValidationAttributes.validationStatus === "error";

    const validFormOthers = !(
      invalidDealerNameStatus ||
      invalidDealerAutoStatus ||
      invalidDealerUrlStatus ||
      invalidUrls
    );

    setValidForm(validFormOthers);
  }, [
    dealerNameValidationAttributes,
    dealerCodeValidationAttributes,
    dealerUrlValidationAttributes,
    dealerLogoUrlValidationAttributes,
    dealerToEditWithLogos,
    mode,
    invalidUrls,
  ]);

  useEffect(() => {
    if (logosUploadStatus) {
      toggleNewDealerButtonClicked(false);
      closeAddDealerDrawer();
    }
    if (logosUploadStatus === "COMPLETE") {
      submitDealerForm(dealerToEditWithLogos);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logosUploadStatus]);

  const handleClose = (discardButtonClicked?: boolean) => {
    if (
      doesSignUpFormHaveChanges(
        dealerToEditWithLogos,
        compareDealerToEditWithLogos,
      ) &&
      !discardButtonClicked
    ) {
      toggleIsSaveOrDiscardModalOpenDealerManagement(true);
      return;
    }
    setDealerNameValidationAttributes(defaultValidationAttributes);
    setDealerCodeValidationAttributes(defaultValidationAttributes);
    setDealerUrlValidationAttributes(defaultValidationAttributes);
    setDealerLogoUrlValidationAttributes(defaultValidationAttributes);

    setDealerToEditWithLogos({
      ...initialDealerObj,
    });

    setLogosInDictionary(defaultLogoAttributes);

    setSingleLogoObject({
      group: "string",
      feedbackMessage: "",
    });

    closeAddDealerDrawer();
    setCompareDealerToEditWithLogos(initialDealerObj);
    toggleIsSaveOrDiscardModalOpenDealerManagement(false);
  };

  const handleDelete = () => {
    toggleShouldDeleteModal(true);
  };

  const handleSubmit = () => {
    // isAllLogosSubmitted will remain true if the user has not added any unsubmitted logos
    let isAllLogosSubmitted = true;

    const tempArr = [];

    if (
      mode === "DUPLICATE" &&
      !validDuplicate(dealers, dealerToEditWithLogos)
    ) {
      message.error("Dealer name already exists");
      return;
    }

    for (const key in logosInDictionary) {
      if (logosInDictionary[key as TUploadSetLogosInDictionary].length > 0) {
        tempArr.push(key);
      }
    }
    if (tempArr.length > 0) {
      setLogoKeysToBeUploaded(tempArr);
      isAllLogosSubmitted = false;
    }
    // Second for loop is to upload the logos
    for (const key in logosInDictionary) {
      if (logosInDictionary[key as TUploadSetLogosInDictionary].length > 0) {
        uploadImageTypesToS3({
          key: key as TUploadSetLogosInDictionary,
          keysToBeUploaded: tempArr,
          logosInDictionary,
          mode,
          uploadImages,
        });
      }
    }

    const matchingDealer = dealers.find(dealer => {
      const currentName = cleanNameStringForComparison(dealer.dealerName);
      const newName =
        mode === "UPDATE"
          ? dealerToEditWithLogos.newDealerName
          : dealerToEditWithLogos.dealerName;
      const newNameTo = cleanNameStringForComparison(newName || "");

      return newNameTo === currentName;
    });

    const didTheDealerNameChange =
      mode === "UPDATE" &&
      dealerToEditWithLogos.newDealerName !== dealerToEditWithLogos.dealerName;

    if (matchingDealer && (mode === "CREATE" || didTheDealerNameChange)) {
      message.warning(`The desired store name is currently in use.`);
      return;
    }

    if (isAllLogosSubmitted) {
      submitDealerForm(dealerToEditWithLogos);

      const nameUpdatedRecord = replaceDealerNameWithNewName(
        dealerToEditWithLogos,
      );
      setDealerToEditWithLogos(nameUpdatedRecord);
      setCompareDealerToEditWithLogos(nameUpdatedRecord);
      setValidForm(false);
    }
    toggleIsSaveOrDiscardModalOpenDealerManagement(false);
  };

  const onDeleteClick = () => {
    deleteDealerForm(dealerToEditWithLogos);
    toggleShouldDeleteModal(false);
    setDealerNameValidationAttributes(defaultValidationAttributes);
    setDealerCodeValidationAttributes(defaultValidationAttributes);
    setDealerUrlValidationAttributes(defaultValidationAttributes);
    setDealerLogoUrlValidationAttributes(defaultValidationAttributes);
  };

  const onCancelClick = () => {
    setDealerToEditWithLogos(initialDealerObj);
    setCompareDealerToEditWithLogos(initialDealerObj);
    toggleIsSaveOrDiscardModalOpenDealerManagement(false);
    closeAddDealerDrawer();
    handleClose(true);
  };

  const drawerForm = (
    <DealerTabs
      dealerToEditWithLogos={dealerToEditWithLogos}
      setDealerToEditWithLogos={setDealerToEditWithLogos}
      mode={mode}
    >
      <DealerDetails
        dealerNameValidationAttributes={dealerNameValidationAttributes}
        setDealerNameValidationAttributes={setDealerNameValidationAttributes}
        dealerCodeValidationAttributes={dealerCodeValidationAttributes}
        setDealerCodeValidationAttributes={setDealerCodeValidationAttributes}
        dealerUrlValidationAttributes={dealerUrlValidationAttributes}
        setDealerUrlValidationAttributes={setDealerUrlValidationAttributes}
        dealerToEditWithLogos={dealerToEditWithLogos}
        setDealerToEditWithLogos={setDealerToEditWithLogos}
        mode={mode}
        feed={feed}
        s3AssetBucket={s3AssetBucket}
        singleLogoObject={singleLogoObject}
        setSingleLogoObject={setSingleLogoObject}
        logosInDictionary={logosInDictionary}
        setLogosInDictionary={setLogosInDictionary}
        uploadImages={uploadImages}
        disabled={!isAdmin}
        isProcessing={processingDealers}
      />
    </DealerTabs>
  );

  const submitDisabledTooltip = !validForm
    ? "A Store cannot be saved if there are duplicate labels or empty values."
    : "";

  return (
    <>
      <DealerDrawerModals
        IsSaveOrDiscardModalOpenDealerManagement={
          IsSaveOrDiscardModalOpenDealerManagement
        }
        dealerToEditWithLogos={dealerToEditWithLogos}
        onCancelClick={onCancelClick}
        onDeleteClick={onDeleteClick}
        shouldDeleteModal={shouldDeleteModal}
        toggleIsSaveOrDiscardModalOpenDealerManagement={
          toggleIsSaveOrDiscardModalOpenDealerManagement
        }
        toggleShouldDeleteModal={toggleShouldDeleteModal}
      />
      <FormDrawer
        mode={mode}
        drawerWidth={720}
        dataName={industryType("auto") ? "Store" : "Account Details"}
        extendedName={
          mode !== "DUPLICATE" ? dealerToEditWithLogos.dealerName : ""
        }
        processingData={processingDealers}
        processingUpload={processingUpload}
        showDrawer={showAddDealerDrawer}
        validForm={validForm}
        submitDisabledTooltip={submitDisabledTooltip}
        drawerForm={drawerForm}
        handleClose={handleClose}
        handleDelete={handleDelete}
        handleSubmit={handleSubmit}
        allowDelete={isAdmin}
      />
    </>
  );
};

export default DealerDrawer;
