import { uniqBy } from "lodash";
import { useQuery } from "react-query";
import { useCAMHook, useCAMSearch } from "shared/components/media";
import { OfferData } from "shared/types/assetBuilder";
import { IConfig } from "shared/types/configuration";
import { ITemplate } from "shared/types/designStudio";
import { fetchBackgroundThemeImages } from "./ImageSelection.utils";
import { convertThemeToLifestyle, mapToImage } from "./mapToImage";

type Params = {
  offerData: OfferData | null;
  template?: ITemplate;
  config?: IConfig;
  onSettled?: (data: any) => void;
};

export const useFetchThemeImages = ({
  template,
  offerData,
  config,
  onSettled,
}: Params) => {
  const searchMedia = useCAMHook(useCAMSearch)();
  const { make, year, dealerCode } = offerData ?? {};

  return useQuery(
    [
      "themeImages",
      make,
      dealerCode,
      template?.artboard?.width,
      template?.artboard?.height,
    ],
    async () => {
      const {
        width = 0,
        height = 0,
        theme_images = "",
      } = template?.artboard ?? {};

      if (searchMedia) {
        const baseWDSearchParams: WDSearchField[] = [
          {
            field_name: "width",
            filter_type: "exact",
            value: width.toString(),
          },
          {
            field_name: "height",
            filter_type: "exact",
            value: height.toString(),
          },
          {
            field_name: "keywords",
            filter_type: "exact",
            value: "theme",
          },
          {
            field_name: "filetype",
            filter_type: "match",
            operator: "OR",
            value: "png,jpg,jpeg",
          },
        ];

        const additionalWDSearchParams: WDSearchField[] = [
          {
            field_name: "make",
            filter_type: "exact",
            value: make,
          },
          {
            field_name: "store_233",
            filter_type: "exact",
            value: dealerCode,
          },
        ];

        const results = await Promise.all([
          searchMedia({
            filters: {
              fields: baseWDSearchParams,
            },
          }),
          searchMedia({
            filters: {
              fields: baseWDSearchParams.concat(additionalWDSearchParams),
            },
          }),
        ]);

        const combinedResults = results
          .map(mapToImage("theme_bg", year, make))
          .flat();

        return uniqBy(combinedResults, "name");
      }

      const bgThemeImagesResult = await fetchBackgroundThemeImages({
        ids: theme_images,
        config: config!,
      });

      return convertThemeToLifestyle(bgThemeImagesResult, year, make) ?? [];
    },
    {
      enabled: !!template?.artboard && !!config,
      onSettled,
    },
  );
};
