import { message, Modal } from "antd";
import { useAdEngineActions } from "redux/assetExporter/assetExporter.slice";
import { useGetFormattedFeeds } from "shared/hooks/assetExporter/useGetFormattedFeeds";
import { useDeleteFeedCron } from "shared/hooks/assetExporter/useMutateFeeds";

import { useAppSelector } from "shared/hooks/useAppSelector";

export const DeleteFeedsModal = () => {
  const { isDeleteModalOpen, selectedFeedIds } = useAppSelector(
    state => state.assetExporter.sourceFeed,
  );
  const actions = useAdEngineActions();
  const { feeds } = useGetFormattedFeeds();
  const { mutateAsync: deleteFeedCron } = useDeleteFeedCron();

  const selectedFeeds = feeds.filter(({ feedId }) =>
    selectedFeedIds.includes(feedId),
  );

  const onModalClose = () => {
    actions.setIsDeleteModalOpen(false);
    actions.setSelectedFeedIds([]);
  };

  const onDelete = async () => {
    const msgKey = "deleteKey";
    message.loading({ key: msgKey, content: "Deleteing feed(s)" });
    try {
      const selectedFeeds = feeds.filter(({ feedId }) =>
        selectedFeedIds.includes(feedId),
      );
      await Promise.all(
        selectedFeeds.map(({ feedId }) => deleteFeedCron(feedId)),
      );
      message.success({ key: msgKey, content: "Feed(s) deleted" });
      onModalClose();
    } catch (error) {
      message.error({ key: msgKey, content: "Unable to delete feed(s)" });
    }
  };

  return (
    <Modal
      title={<strong>Confirm Deletion</strong>}
      visible={isDeleteModalOpen}
      onOk={onDelete}
      onCancel={onModalClose}
    >
      <p>Confirm that you want to delete the following feed(s)</p>
      <ul>
        {selectedFeeds.map(({ feedId, feedName }) => (
          <li key={feedId}>{feedName}</li>
        ))}
      </ul>
    </Modal>
  );
};
