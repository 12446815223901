import { Select, SelectProps, Tooltip, Tag } from "antd";
import { GoogleAdAccount } from "shared/types/adLibrary";
import styles from "./GoogleAdAccountSelect.module.scss";
import useGoogleAdsAccounts from "./hooks/useGoogleAdsAccounts";

/*
 * antd allows to add custom props to Select.Option, which we are using to make the entire GoogleAccount object accessible via "label"
 * Unfortunately, antd does not account for this on the tagRenderer props, so we have to set label as any
 *
 * Also, antd does not export the tagRender props, so we have to extrat it and do some type gymnastics
 * TODO: review when upgrading antd
 */

type TagRendererProps = Parameters<
  Extract<SelectProps<string>["tagRender"], (props: any) => React.ReactElement>
>[0] & { label: any };

const tagRenderer = ({ label, closable, onClose }: TagRendererProps) => {
  return (
    <Tooltip title={`CID: ${label.id}`}>
      <Tag closable={closable} onClose={onClose}>
        {label.name}
      </Tag>
    </Tooltip>
  );
};

interface GoogleAdAccountSelectProps
  extends SelectProps<GoogleAdAccount["id"]> {
  allowsMultiple?: boolean;
}

const GoogleAdAccountSelect = ({
  allowsMultiple,
  value,
  ...restProps
}: GoogleAdAccountSelectProps) => {
  const { data: adAccounts, isLoading } = useGoogleAdsAccounts();

  return (
    <Select
      placeholder="Select ad account"
      value={isLoading ? "Fetching ad accounts..." : value}
      data-cy="google-account-select"
      allowClear
      showSearch
      loading={isLoading}
      disabled={isLoading}
      optionFilterProp="search" // Value to search by
      mode={allowsMultiple ? "multiple" : undefined}
      optionLabelProp={allowsMultiple ? "data" : undefined} // Accessible as 'label' in tagRender
      tagRender={allowsMultiple ? tagRenderer : undefined}
      {...restProps}
    >
      {adAccounts?.map((acct: GoogleAdAccount) => (
        <Select.Option
          data-cy={`google-account-select-option-${acct.name}`}
          key={acct.id}
          value={acct.id}
          label={acct.name}
          data={acct}
          search={`${acct.name}${acct.id}`}
        >
          <span>{acct.name}</span>
          <br />
          <span className={styles.accountCID}>CID: {acct.id}</span>
        </Select.Option>
      ))}
    </Select>
  );
};

export default GoogleAdAccountSelect;
