import { Field } from "shared/components/dataListURL/types";
import { StringParam } from "use-query-params";
import { checkFilterMatch, compareString } from "utils/helpers";
import { InstantExperienceTableEntry } from "shared/types/adLibrary";
import moment from "moment";
import { ITableColumnURL } from "shared/components/dataListURL/DataListURLTable";
import { compareStringBy, onFilterBy } from "utils/helpers.table";

export type EverythingAdField = Field<InstantExperienceTableEntry>;

type Fields = Partial<
  Record<keyof InstantExperienceTableEntry, EverythingAdField>
>;

export type IEverythingAdFields = keyof Fields;

export const fields: Fields = {
  name: {
    filterFn: (value, record) =>
      checkFilterMatch(value.toString(), record.name),
    sorterFn: (a, b) => compareString(a.name, b.name),
    queryParamConfigFilter: StringParam,
  },
  oem: {
    filterFn: onFilterBy("oem", {
      matchMode: "includes",
      caseInsensitive: true,
    }),
    sorterFn: compareStringBy("oem"),
    queryParamConfigFilter: StringParam,
  },
  updatedAt: {
    sorterFn: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
  },
  updatedBy: {
    filterFn: (value, record) =>
      checkFilterMatch(value.toString(), record.updatedBy),
    sorterFn: (a, b) =>
      compareString(a.updatedBy?.toString(), b.updatedBy?.toString()),
    queryParamConfigFilter: StringParam,
  },
  createdAt: {
    sorterFn: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    defaultSortOrder: "descend",
  },
  createdBy: {
    sorterFn: (a, b) => compareString(a.createdBy, b.createdBy),
    filterFn: (value, record) =>
      checkFilterMatch(value?.toString(), record.createdBy?.toString()),
    queryParamConfigFilter: StringParam,
  },
};

export const everythingAdFieldKeys = Object.keys(fields) as Array<
  keyof typeof fields
>;

export type AdLibraryTableColumn = ITableColumnURL<
  IEverythingAdFields,
  InstantExperienceTableEntry
>;
