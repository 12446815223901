import API from "services";
import { QueryFunctionContext, useInfiniteQuery } from "react-query";

import { orderBy } from "lodash";

import { IGetCommentsResult } from "shared/types/adLibrary";
import { IComment } from "shared/types/shared";

const getCommentsPage = async ({
  queryKey,
  pageParam: lastEvaluatedKey,
}: QueryFunctionContext) => {
  const { result, error } = await API.services.adLibrary.getComments(
    lastEvaluatedKey
      ? {
          object: lastEvaluatedKey?.objectId,
          timestamp: lastEvaluatedKey?.createdAt,
        }
      : { object: queryKey[1] as string },
  );

  if (error || !result) {
    throw Error(error?.message || "Something went wrong");
  }

  return result;
};

type FetchInfCommentsOptions = {
  enabled?: boolean;
  refetchIntervalInBackground?: boolean;
};

export const useFetchInfiniteComments = (
  objectId: string,
  options: FetchInfCommentsOptions,
) => {
  const result = useInfiniteQuery<IGetCommentsResult, Error>(
    ["commentsPages", objectId],
    getCommentsPage,
    {
      ...options,
      staleTime: 30000,
      refetchInterval: options.refetchIntervalInBackground && 5000,
      getNextPageParam: lastPage => lastPage?.lastEvaluatedKey,
    },
  );

  if (result.hasNextPage && !result.isFetchingNextPage) {
    result.fetchNextPage();
  }

  const comments = orderBy(
    result.data?.pages?.reduce(
      (acc, page) => acc.concat(page?.comments || []),
      [] as IComment[],
    ) ?? [],
    ["createdAt"],
    "desc",
  );

  return {
    ...result,
    comments,
  };
};
