import { FC, ReactNode } from "react";
import { Input } from "antd";
import styles from "./TextArea.module.scss";

export interface Props {
  value?: string;
  revertButtonComponent?: ReactNode;
  className: string;
  isModified: boolean;
  isEditDisabled?: boolean;
}

interface Handlers {
  onChange?: (value?: string) => void;
}

const TextArea: FC<Props & Handlers> = props => {
  return (
    <div className={`${styles.TextArea} ${props.className}`}>
      <Input.TextArea
        autoSize
        disabled={props.isEditDisabled}
        value={props.value}
        onChange={e => props.onChange?.(e.target.value)}
      />

      <div className={styles.RevertButtonWrapper}>
        {props.isModified && props.revertButtonComponent}
      </div>
    </div>
  );
};

export default TextArea;
