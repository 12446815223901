import { useState } from "react";
import {
  QueryParamAdapter,
  QueryParamAdapterComponent,
} from "use-query-params";

export const InMemoryAdapter: QueryParamAdapterComponent = ({
  children,
}: {
  children: (adapter: QueryParamAdapter) => React.ReactElement | null;
}) => {
  const [search, setSearch] = useState("");
  const [state, setState] = useState<Record<string, unknown>>({});

  const adapter: QueryParamAdapter = {
    replace(location) {
      setSearch(location.search || "?");
      setState(location.state);
    },
    push(location) {
      setSearch(location.search || "?");
      setState(location.state);
    },
    get location() {
      return {
        search,
        state,
      };
    },
  };

  return children(adapter);
};
