import { useMutation } from "react-query";
import API from "services";
import { GetCsvUrlArgs } from "shared/types/assetExporter";

const getCsvUrl = async (params: GetCsvUrlArgs) => {
  const errorMsg = "Unable to get download url";
  try {
    const api = API.services.assetExporter.getCsvDownloadUrl;
    const res = await api(params);
    if (!!res.error) throw new Error(res.error.message);
    if (!res.result) throw new Error(errorMsg);
    return res.result.url;
  } catch (error) {
    throw new Error(errorMsg);
  }
};

export const useGetCsvUrl = () => {
  return useMutation<string, Error, GetCsvUrlArgs>(getCsvUrl);
};
