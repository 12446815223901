export const getOemAndEventLogosPayload = (
  oemName: string,
): MGSearch | undefined => {
  if (oemName) {
    return {
      filters: {
        fields: [
          {
            field_name: "make",
            filter_type: "exact",
            value: oemName,
            operator: "AND",
          },
          {
            field_name: "keyword",
            filter_type: "exact",
            value: ["Logo", "Manufacturer"],
            operator: "AND",
          },
        ],
      },
    };
  }
};

export const isNameInputValid = (name: string) => {
  const regex = /^[a-z](?!.* {2})[ \w.,:;_'-]{2,100}$/gim;
  return name && regex.test(name);
};

export const cleanNameStringForComparison = (nameString: string) => {
  const noSpaceName = nameString.replace(/\s/g, "");
  return noSpaceName.toLowerCase();
};
