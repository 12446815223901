import GenericError from "shared/errors/GenericError";
import {
  ILifestyleImage,
  IThemeBackgroundImage,
} from "shared/types/assetBuilder";
import { IConfig } from "shared/types/configuration";
import API from "services";

export const fetchBackgroundThemeImages = async (args: {
  ids: string;
  config: IConfig;
}): Promise<IThemeBackgroundImage[]> => {
  if (!args.config || !args.ids) {
    return [];
  }

  let requestBody: { ids: string; lastId?: string } = {
    ids: args.ids,
  };

  const {
    services: {
      assetBuilder: { fetchThemeBackgroundImageUrl },
    },
  } = args.config;

  let request: RequestInfo = new Request(fetchThemeBackgroundImageUrl, {
    method: "POST",
    cache: "no-cache",
    body: JSON.stringify(requestBody),
  });

  let allBackgroundImages: IThemeBackgroundImage[] = [];

  let response = await API.send<{
    result: {
      backgroundThemeImages: IThemeBackgroundImage[];
      lastEvaluatedId?: string;
    };
    error: GenericError;
  }>(request);

  if (response.error || !response.result?.backgroundThemeImages) return [];

  allBackgroundImages = [...(response.result.backgroundThemeImages || [])];

  while (response.result.lastEvaluatedId) {
    requestBody = {
      ids: args.ids,
      lastId: response.result.lastEvaluatedId,
    };
    request = new Request(fetchThemeBackgroundImageUrl, {
      method: "POST",
      cache: "no-cache",
      body: JSON.stringify(requestBody),
    });
    response = await API.send<{
      result: {
        backgroundThemeImages: IThemeBackgroundImage[];
        lastEvaluatedId?: string;
      };
      error: GenericError;
    }>(request);
    allBackgroundImages = [
      ...allBackgroundImages,
      ...(response.result.backgroundThemeImages || []),
    ];
  }

  return allBackgroundImages;
};

export const fetchLifestyleImages = async (args: {
  year?: number;
  make?: string;
  ids?: string;
  config: IConfig;
}): Promise<ILifestyleImage[]> => {
  if (!args.config) return [];

  // The request should be sent with (year and make) or (ids) but not both.
  if (!!args.year && !!args.make && !!args.ids) return [];

  const queryParams: { [key: string]: string | number | undefined } = {
    year: args.year,
    make: args.make,
    ids: args.ids,
  };
  const queryString = Object.keys(queryParams)
    .reduce((acc, key) => {
      acc.push(!!queryParams[key] ? `${key}=${queryParams[key]}` : ""); // Here, key has to be checked if it is truthy value bc trim is optional

      return acc;
    }, [] as string[])
    .filter(val => val && val.trim())
    .join("&");

  const {
    services: {
      assetBuilder: { uploadLifestyleImageUrl },
    },
  } = args.config;
  const request: RequestInfo = new Request(
    `${uploadLifestyleImageUrl}?${queryString}`,
    {
      method: "GET",
      cache: "no-cache",
    },
  );

  const { result, error } = await API.send<{
    result: { lifestyleImages: ILifestyleImage[] };
    error: GenericError;
  }>(request);

  if (error || !result?.lifestyleImages) return [];

  return result.lifestyleImages;
};

export const isSameImage = (imgUrl1?: string, imgUrl2?: string) => {
  if (!imgUrl1 || !imgUrl2) return false;
  if (imgUrl1 === imgUrl2) return true;
  // return true for same image with different dimensions (webDAM)
  const re = /(?<=com\/)(.*?)(?<=_)/;
  return imgUrl1.replace(re, "") === imgUrl2.replace(re, "");
};
