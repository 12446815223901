import { useQuery } from "react-query";
import API from "services";
import {
  ITemplate,
  QUERY_KEY_PREVIEW_TEMPLATE,
} from "shared/types/designStudio";

const getTemplatePreviewUrl = async (templateId: string) => {
  return API.services.assetExporter.getHtmlTemplatePreviewUrl(templateId);
};

const useGetTemplatePreviewUrl = (template?: ITemplate) => {
  const isHtmlEnabled = template?.type === "html" && !!template?.id;
  const isImageEnabled = template?.type !== "html" && !!template?.thumbnailUrl;

  const enabled = isHtmlEnabled || isImageEnabled;

  return useQuery<string | null, Error>(
    [QUERY_KEY_PREVIEW_TEMPLATE, isHtmlEnabled, isImageEnabled, template],
    () => {
      if (isImageEnabled) return template.thumbnailUrl;
      if (isHtmlEnabled && template.id) return getTemplatePreviewUrl(template.id);

      return null;
    },
    {
      enabled,
    },
  );
};

export default useGetTemplatePreviewUrl;
