import { Tag } from "antd";
import styles from "./PharmaFilter.module.scss";

interface Props {
  brandProduct: string;
}

const PharmaFilter = (props: Props) => {
  const { brandProduct } = props;
  return (
    <div className={styles.pharmaFilterContainer}>
      <div>
        <div className={styles.pharmaFilterTitle}>
          Brand/Product
          <div>
            <Tag className={styles.fielterField}>{brandProduct}</Tag>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PharmaFilter;
