import { useFetchCampaignsV2 } from "shared/hooks/adLibrary/facebook/useFetchCampaigns";
import { isTruthy } from "utils/helpers.array";
import { useAdLoadSelectedStores } from "./useAdLoadSelectedStores";

export const useSelectedAcctCampaigns = () => {
  const { selectedStores } = useAdLoadSelectedStores();

  const adAccountIds =
    selectedStores
      ?.map(store => store.details?.facebook?.fbAccountId)
      .filter(isTruthy) ?? [];

  return useFetchCampaignsV2({
    accountIds: adAccountIds,
  });
};
