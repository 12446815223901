import { Badge, BadgeProps } from "antd";
import { AppColors, QCStatus } from "shared/types/shared";
import styles from "./QCStatusSelect.module.scss";

export const qcStatusColorMap: Record<QCStatus, string> = {
  [QCStatus.APPROVED]: AppColors.success,
  [QCStatus.DENIED]: AppColors.danger,
  [QCStatus.NOT_QCed]: AppColors.secondary,
};

const QcStatusBadge = ({
  qcStatus,
  ...rest
}: BadgeProps & { qcStatus: QCStatus }) => {
  return (
    <Badge
      className={styles.bigBadge}
      color={qcStatusColorMap[qcStatus || QCStatus.NOT_QCed]}
      {...rest}
    />
  );
};

export default QcStatusBadge;
