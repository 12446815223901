import { useGetRequiredData } from "./useGetRequiredData";
import useGetIsMounted from "shared/hooks/useGetIsMounted";
import {
  useCreateAdCreative,
  useAssembleAdCreative,
  useAssembleFacebookAdCreative,
} from "shared/hooks/adLibrary/adLoad/useAssembleAdCreative";

import {
  returnLoadToFacebookArgs,
  recursivelyRacePromises,
  handleLoadProcess,
} from "../../../loadStep/helpers.load";

import { HandleAdLoadProcessStepArgs, PromiseTracker } from "../../types";

export const useHandleAssembleCreative = () => {
  const isMounted = useGetIsMounted();
  const { instantExperiences, selectedAccounts, selectedDealers } =
    useGetRequiredData();

  const { mutateAsync: createAdCreative } = useCreateAdCreative();
  const { mutateAsync: assembleFacebookAdCreative } =
    useAssembleFacebookAdCreative();
  const { mutateAsync: assembleAdCreative } = useAssembleAdCreative();

  return (args: HandleAdLoadProcessStepArgs) => {
    if (!args.setCurrentAdToLoad || !isMounted()) return;
    const promiseObjs: PromiseTracker[] = args.selectedAdsToLoad.map(
      adToLoad => {
        const assembleCreativeArgs = {
          instantExperiences,
          ...returnLoadToFacebookArgs({
            adToLoad,
            selectedDealers,
            selectedAccounts,
          }),
          createAdCreative,
          assembleFacebookAdCreative,
        };
        return {
          key: adToLoad.key,
          promise: handleLoadProcess(
            adToLoad,
            () => assembleAdCreative(assembleCreativeArgs),
            () => Promise.resolve(adToLoad),
          ),
        };
      },
    );
    recursivelyRacePromises(promiseObjs, args.setCurrentAdToLoad);
  };
};
