import { useMemo } from "react";
import { useIsAdmin } from "../useIsAdmin";
import { useUser } from "../useUser";
import usePreference from "./usePreference";

export interface SelectedStore {
  /** The store name. This is the primary key */
  name: string;
  /** The store's associated fb account id */
  fbAccountId: string;
}

export const useSelectedStore = () => {
  const { value: selectedStore, setValue: setSelectedStore } = usePreference<
    SelectedStore | undefined
  >("selectedStore", undefined, true);

  const user = useUser();
  const isAdmin = useIsAdmin();

  const filteredStores = useMemo(() => {
    if (user && !isAdmin) {
      const userStores = user.stores?.map(store => store.toLowerCase());
      const storeName = selectedStore?.name;

      return storeName && userStores?.includes(storeName)
        ? selectedStore
        : undefined;
    }

    return selectedStore;
  }, [isAdmin, selectedStore, user]);

  return {
    selectedStore: filteredStores,
    setSelectedStore,
  };
};
