import { useAppDispatch } from "shared/hooks/useAppDispatch";
import {
  setAdLoadState,
  toggleIsAdLoadVisible,
} from "redux/adLibrary/adLibrary.slice";
import { useNavigate } from "react-router-dom";
import uuid from "uuid";
import { stepUrlParamValues } from "screens/adLibrary/adLoad/adLoadDrawer/shared/constants";
import { useAds } from "./useAds";

type StartNewAdLoadSessionArgs = {
  sessionId?: string;
};

const useStartNewAdLoadSession = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedItems: selectedAds } = useAds();

  return (args: StartNewAdLoadSessionArgs) => {
    dispatch(
      setAdLoadState({
        prop: "selectedAds",
        data: selectedAds || null,
      }),
    );

    navigate({
      pathname: `/ad-library/load`,
      search: `?session_id=${args.sessionId || uuid()}&step=${
        stepUrlParamValues.DESTINATION_SELECTION
      }`,
    });

    dispatch(toggleIsAdLoadVisible(true));
  };
};

export default useStartNewAdLoadSession;
