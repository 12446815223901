import { Dropdown } from "antd";
import { ReactNode, useState } from "react";
import { useMenuAsset } from "./MediaTable/useMenuAsset";
import { useMenuFolder } from "./MediaTable/useMenuFolder";

type Props = {
  children: ReactNode;
  record: any;
};

export const ContextMenu = ({ record, children }: Props) => {
  const [visible, setVisible] = useState(false);
  const useMenu = getMenu(record);
  const menu = useMenu(record, visible);

  return (
    <Dropdown
      overlay={menu}
      trigger={["contextMenu"]}
      onVisibleChange={setVisible}
    >
      {children}
    </Dropdown>
  );
};

const getMenu = (record?: WDResource) => {
  switch (record?.type) {
    case "asset":
      return useMenuAsset;
    case "folder":
      return useMenuFolder;
    default:
      return useMenuVoid;
  }
};

const useMenuVoid = (record: WDResource, visible: boolean) => <></>;
