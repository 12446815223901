import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { MouseEventHandler, useCallback, useEffect, useState } from "react";
import { useAdEngineActions } from "redux/assetExporter/assetExporter.slice";
import {
  duplicateHeaderErrorMsg,
  generalConnectErrorMsg,
  noSuchFileErrorMsg,
} from "shared/constants/assetExporter";
import useValidateCreds from "shared/hooks/assetExporter/useValidateCreds";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { GetValidateCredsBody } from "shared/types/assetExporter";
import styles from "./ServerUpload.module.scss";

const VALID_CREDS_COLOR = "#33cc33";
const INVALID_CREDS_COLOR = "#f44336";

export const ServerUpload = () => {
  const {
    username,
    feedName,
    host,
    path,
    password: orgPassword,
    tempPassword,
    drawerMode,
    port,
    isCredValidated,
  } = useAppSelector(state => state.assetExporter.sourceFeed.feedDrawer);
  const actions = useAdEngineActions();
  const {
    mutate: validateCreds,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useValidateCreds();

  const isUpdate = drawerMode === "UPDATE";
  const password = isUpdate ? tempPassword : orgPassword;

  const isDisabled = !username || !host || !path || !password;
  const [uploadError, setUploadError] = useState("");

  const onValidateCredsClick: MouseEventHandler<HTMLElement> = e => {
    e.preventDefault();
    const body: GetValidateCredsBody = {
      host: host ?? "",
      password: password ?? "",
      path: path ?? "",
      port: parseInt(port ?? "22"),
      username: username ?? "",
    };
    validateCreds(body);
  };

  const onServerValidation = useCallback(() => {
    if (isSuccess) {
      actions.setServerValidation(true);
      setUploadError("");
      return;
    }

    if (isError && error.message.includes("No such file")) {
      setUploadError(noSuchFileErrorMsg);
      return;
    }

    if (isError && error.message.includes("Duplicate Header")) {
      setUploadError(duplicateHeaderErrorMsg);
      return;
    }

    if (isError) {
      actions.setServerValidation(false);
      setUploadError(generalConnectErrorMsg);
    }
  }, [actions, error?.message, isError, isSuccess]);

  const enableNextBtn = useCallback(() => {
    if (isUpdate) {
      actions.enableNextButton();
    }
  }, [actions, isUpdate]);

  useEffect(enableNextBtn, [enableNextBtn]);
  useEffect(onServerValidation, [onServerValidation]);

  const getGeneralErrorStyle = () => {
    if (uploadError === generalConnectErrorMsg) return { borderColor: "red" };
  };

  const getFileErrorStyle = () => {
    if (uploadError === noSuchFileErrorMsg) return { borderColor: "red" };
  };

  return (
    <Form layout="vertical" hideRequiredMark={true}>
      <div>
        {uploadError === noSuchFileErrorMsg && (
          <div className={styles.invalidCreds}>{uploadError}</div>
        )}
        <Form.Item label={<b>Add Feed Name</b>} required={true}>
          <Input
            value={feedName}
            onChange={e => actions.setFeedName(e.target.value)}
            placeholder={"Example_Feed_2022_12"}
            data-cy="feed-name"
          />
        </Form.Item>
        <Form.Item label={<b>URL</b>}>
          <Input
            value={host}
            onChange={e => actions.setHost(e.target.value)}
            placeholder={"ftp.example.com"}
            data-cy="feed-url"
            style={getGeneralErrorStyle() || getFileErrorStyle()}
          />
        </Form.Item>
        <Form.Item label={<b>File Path</b>}>
          <Input
            value={path}
            onChange={e => actions.setPath(e.target.value)}
            placeholder={"/testing/example_data.csv"}
            data-cy="feed-file-path"
            style={getGeneralErrorStyle() || getFileErrorStyle()}
          />
          {uploadError === duplicateHeaderErrorMsg && (
            <div className={styles.invalidCreds}>{uploadError}</div>
          )}
        </Form.Item>
        <Form.Item label={<b>Port</b>}>
          <Input
            value={port}
            onChange={e => actions.setPort(e.target.value)}
            placeholder={"22"}
            style={getGeneralErrorStyle() || getFileErrorStyle()}
          />
        </Form.Item>
      </div>
      <div className="drawer-datafeed-container">
        <h4>
          <b>
            {!isUpdate
              ? "VALIDATE DATAFEED CREDENTIALS"
              : "UPDATE DATAFEED CREDENTIALS"}
          </b>
        </h4>
        <div className={styles.authentication}>
          <Form.Item
            label="Username"
            required
            className={styles.username}
            style={{ marginBottom: "1em" }}
          >
            <Input
              value={username}
              onChange={e => actions.setUsername(e.target.value)}
              prefix={<UserOutlined />}
              suffix={
                isCredValidated === true ? (
                  <CheckCircleTwoTone
                    twoToneColor={VALID_CREDS_COLOR}
                    className={styles.validCreds}
                  />
                ) : isCredValidated === false ? (
                  <CloseCircleTwoTone
                    twoToneColor={INVALID_CREDS_COLOR}
                    className={styles.invalidCreds}
                  />
                ) : null
              }
              data-cy="feed-username"
              placeholder="Username"
              style={getGeneralErrorStyle()}
            />
          </Form.Item>
          <Form.Item
            label="Password"
            required
            className={styles.password}
            style={{ marginBottom: "1em" }}
          >
            <Input
              value={password}
              onChange={e => actions.setPassword(e.target.value)}
              prefix={<LockOutlined />}
              suffix={
                isCredValidated === true ? (
                  <CheckCircleTwoTone
                    twoToneColor={VALID_CREDS_COLOR}
                    className={styles.validCreds}
                  />
                ) : isCredValidated === false ? (
                  <CloseCircleTwoTone
                    twoToneColor={INVALID_CREDS_COLOR}
                    className={styles.invalidCreds}
                  />
                ) : null
              }
              data-cy="feed-password"
              type="password"
              placeholder="Password"
              style={getGeneralErrorStyle()}
            />
          </Form.Item>
        </div>
        {uploadError === generalConnectErrorMsg && (
          <div className={styles.generalError}>{uploadError}</div>
        )}
        <Form.Item>
          <Button onClick={() => actions.clearNameAndPswd}>Clear</Button>
          <Button
            onClick={onValidateCredsClick}
            loading={isLoading}
            disabled={isDisabled}
            data-cy="feed-validate-creds"
          >
            Validate Credentials
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};
