import { createSelector } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "redux/store";

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export const getSelected = createSelector(
  (state: AppState) => state.media.selectedRows,
  rows => {
    const selectedIds = rows.map(r => r.id);
    const selectedAssets = rows.filter(r => r.type === "asset") as WDAsset[];
    const selectedFolders = rows.filter(r => r.type === "folder") as WDFolder[];
    const allFolders = !!rows.length && selectedFolders.length === rows.length;
    const allAssets = !!rows.length && selectedAssets.length === rows.length;

    return {
      selectedRows: rows,
      selectedIds,
      selectedAssets,
      selectedFolders,
      allFolders,
      allAssets,
    };
  },
);
