import { useMemo, useState } from "react";
import useDeepEffect from "shared/hooks/useDeepEffect";
import { TVariable, TVariableAlias } from "shared/types/designStudio";

import * as utils from "../VariableAlias.utils";

type UseVariablesArgs = {
  textbox: fabric.Textbox;
};
export default (args: UseVariablesArgs) => {
  const [variables, setVariables] = useState<TVariable[]>();
  const { text, variableAlias } = args.textbox as fabric.Textbox & {
    variableAlias: TVariableAlias;
  };

  const restoredText = useMemo(
    () => utils.restoreText(text || "", variableAlias),
    [text, variableAlias],
  );

  useDeepEffect(() => {
    const variables = utils.extractVariables(restoredText);
    setVariables(variables);
  }, [restoredText]);

  return variables;
};
