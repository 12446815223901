import { Button, Tag } from "antd";
import { LoadStatus } from "screens/adLibrary/facebookUtils/adProto.types";
import StatusIcon from "./StatusIcon";
import { CloseOutlined } from "@ant-design/icons";
import styles from "./FilterButton.module.scss";

export const FilterButton = ({
  filterBy,
  status,
  children,
  setFilterBy,
}: {
  status: LoadStatus;
  children: React.ReactNode;
  filterBy: LoadStatus | undefined;
  setFilterBy: (filterBy: LoadStatus | undefined) => void;
}) => {
  const isFiltering = filterBy === status;
  return (
    <Button
      type="link"
      className={styles.buttonWrapper}
      onClick={() => setFilterBy(isFiltering ? undefined : status)}
    >
      <Tag
        className={isFiltering ? styles.tagActive : undefined}
        style={{
          margin: 0,
        }}
        icon={<StatusIcon status={status} loadingSpin={false} />}
      >
        {children}
        {isFiltering && <CloseOutlined className={styles.tagIcon} />}
      </Tag>
    </Button>
  );
};
