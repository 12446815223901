import { message } from "antd";
import { useMutation } from "react-query";
import { createLinkAndRoute } from "screens/assetExporter/sourceFeed/utils";
import API from "services";
import { GetCsvUrl } from "shared/types/assetExporter";
import { getErrorMessage } from "utils/errorMessage";

const msgKey = "downloadCSVKey";

type Targs = {
  feedId: string;
  createdAt?: number;
};

const createAndDownloadCsv = async ({
  feedId,
  createdAt,
}: Targs) => {
  message.loading({ key: msgKey, content: "Downloading CSV" }, 0);

  try {
    const response = await API.services.assetExporter.getCsvDownloadUrl({
      feedId,
      createdAt,
    });

    const { result, error } = response;
    if (error) throw new Error(error.message);
    if (!result) throw new Error("Unable to create cron");

    createLinkAndRoute(result.url);
    return response;
  } catch (error) {
    const errMsg = getErrorMessage(error);
    throw new Error(errMsg);
  }
};

export const useDownloadCsv = () => {
  return useMutation<GetCsvUrl, Error, Targs>(
    args => createAndDownloadCsv(args),
    {
      onError: () => {
        message.error(
          {
            key: msgKey,
            content: "Error downloading CSV",
          },
          3000,
        );
      },
      onSuccess: () => {
        message.success(
          {
            key: msgKey,
            content: "CSV downloaded successfully",
          },
          3000,
        );
      },
    },
  );
};
