import { Button, Empty } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styles from "./tableEmptyMessage/TableEmptyMessage.module.scss";

type TableEmptyMessageProps = {
  message: string;
  action: () => void;
  actionText: string;
};

export const TableEmptyMessage = ({
  message,
  action,
  actionText,
}: TableEmptyMessageProps) => {
  return (
    <Empty
      className={styles.tableEmptyMessageContainer}
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={message}
    >
      <Button
        onClick={action}
        icon={<PlusOutlined />}
        type="primary"
        className={styles.actionButton}
      >
        {actionText}
      </Button>
    </Empty>
  );
};
