import { UploadFile } from "antd/lib/upload/interface";
import {
  IAssetGroup,
  IUpdatedAssetGroup,
  ImageAssetType,
  MultiInputAssetType,
  EditableAssetGroupKey,
} from "shared/types/adLibrary";
import { IFile } from "shared/types/uploadAsset";

export const getUploadAssetList = (
  assetGroup?: Partial<IAssetGroup>,
): Array<{
  title: ImageAssetType;
  fileList: UploadFile<IFile>[];
  minimum: number;
  maxLength: number;
  key: keyof IAssetGroup["uploads"];
}> => [
  {
    title: "Landscape Images",
    fileList: assetGroup?.uploads?.landscapeImages || [],
    minimum: 1,
    maxLength: 20,
    key: "landscapeImages",
  },
  {
    title: "Square Images",
    fileList: assetGroup?.uploads?.squareImages || [],
    minimum: 1,
    maxLength: 20,
    key: "squareImages",
  },
  {
    title: "Portrait Images",
    fileList: assetGroup?.uploads?.portraitImages || [],
    minimum: 0,
    maxLength: 5,
    key: "portraitImages",
  },

  {
    title: "Square Logos",
    fileList: assetGroup?.uploads?.squareLogos || [],
    minimum: 1,
    maxLength: 5,
    key: "squareLogos",
  },
  {
    title: "Landscape Logos",
    fileList: assetGroup?.uploads?.landscapeLogos || [],
    minimum: 0,
    maxLength: 5,
    key: "landscapeLogos",
  },
];

export const getMultiInputAssetList = (
  assetGroup?: Partial<IAssetGroup>,
): Array<{
  title: MultiInputAssetType;
  values: string[];
  maxLength: number;
  key: keyof IAssetGroup["multiInputAsset"];
  showLength: boolean;
}> => [
  {
    title: "Headlines",
    values: assetGroup?.multiInputAsset?.headlines || [],
    maxLength: 30,
    key: "headlines",
    showLength: true,
  },
  {
    title: "Long Headlines",
    values: assetGroup?.multiInputAsset?.longHeadlines || [],
    maxLength: 90,
    key: "longHeadlines",
    showLength: true,
  },
  {
    title: "Descriptions",
    values: assetGroup?.multiInputAsset?.descriptions || [],
    maxLength: 90,
    key: "descriptions",
    showLength: true,
  },
];

export const getNextAssetGroupIndex = (args: {
  assetGroups: IAssetGroup[];
  currentAssetGroup?: Partial<IAssetGroup>;
  key: "up" | "down";
}) => {
  const { assetGroups, currentAssetGroup, key } = args;
  const currentRowIndex = assetGroups.findIndex(
    ds => ds.id === currentAssetGroup?.id,
  );

  return key === "up"
    ? currentRowIndex === 0
      ? assetGroups.length - 1 // when user press "up" while at the top of the list, we should select the last element (rotate)
      : currentRowIndex - (1 % assetGroups.length)
    : (currentRowIndex + 1) % assetGroups.length;
};

export const convertDisplayPath = (
  key: "displayPath0" | "displayPath1",
  value: string,
  existingAssetGroup?: Partial<IAssetGroup> | Partial<IUpdatedAssetGroup>,
): [string | undefined, string | undefined] => {
  const [dPath0, dPath1] = existingAssetGroup?.displayPath || [];

  return [
    key === "displayPath0" ? value.trim() || undefined : dPath0,
    key === "displayPath1" ? value.trim() || undefined : dPath1,
  ];
};

export const getUpdatedAssetGroup = (
  key: EditableAssetGroupKey,
  value: string,
  assetGroup: Partial<IAssetGroup> | Partial<IUpdatedAssetGroup>,
) => {
  const isDisplayPath = key === "displayPath0" || key === "displayPath1";

  return isDisplayPath
    ? {
        displayPath: convertDisplayPath(key, value, assetGroup),
      }
    : {
        [key]: value,
      };
};

export const visibilityOptions = [
  {
    label: "Unlisted",
    value: "unlisted",
    description: "Anyone with the video link can watch your video",
  },
  {
    label: "Private",
    value: "private",
    description: "Only you and people you choose can watch your video",
  },
  {
    label: "Public",
    value: "public",
    description: "Everyone can watch your video",
  },
];
