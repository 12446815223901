import { memo, useState } from "react";
import { Button, Input, Space, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import DynamicVariableSelect, {
  DynamicCatalogType,
} from "shared/components/DynamicVariableSelect";
import { TextAreaProps } from "antd/lib/input";

interface IProps {
  onChange?(value: string): void;
  value?: string;
  textAreaWidth?: string | number;
  maxLength?: number;
  showCount?: boolean;
  autoSize?: boolean | TextAreaProps["autoSize"];
  catalog?: DynamicCatalogType;
}

const DynamicVariablesInput = ({
  onChange,
  value,
  textAreaWidth,
  maxLength,
  showCount,
  autoSize = true,
  catalog,
}: IProps) => {
  const [isOpened, setIsOpened] = useState(false);
  const displayMaxLengthExceed = (text: string) => {
    maxLength &&
      maxLength <= text.length &&
      message.warning("You have exceeded the maximum character limit.");
  };
  return (
    <Space align="start" style={{ width: "100%" }}>
      <Input.TextArea
        autoSize={autoSize}
        style={{ width: textAreaWidth ?? 320 }}
        onChange={e => {
          onChange?.(e.target.value);
          displayMaxLengthExceed(e.target.value);
        }}
        value={value}
        maxLength={maxLength}
        showCount={showCount}
        spellCheck={false}
      />
      {catalog && (
        <div onBlur={() => setIsOpened(false)}>
          <Space>
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={<PlusOutlined style={{ height: 16 }} />}
              onClick={e => {
                e.stopPropagation();
                setIsOpened(prev => !prev);
              }}
              onBlur={e => e.stopPropagation()}
            />
            {isOpened && (
              <DynamicVariableSelect
                onSelect={(selectedValue: string) => {
                  const updatedText = `${value || ""} {{${selectedValue}}}`;
                  displayMaxLengthExceed(updatedText);
                  onChange?.(updatedText.slice(0, maxLength).trim());
                  setIsOpened(false);
                }}
                catalog={catalog}
              />
            )}
          </Space>
        </div>
      )}
    </Space>
  );
};

export default memo(DynamicVariablesInput);
