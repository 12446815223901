/* eslint-disable react/display-name */
import { CalendarOutlined } from "@ant-design/icons";
import { Badge, TableColumnProps } from "antd";
import { useMemo } from "react";
import { useAdEngineActions } from "redux/assetExporter/assetExporter.slice";
import { returnFilterOptions } from "screens/adLibrary/adLoad/adLoadDrawer/shared/components/adsToLoadTable/utils";
import TagsGroup from "screens/legalLingoV2/disclosuresTable/TagsGroup";
import AutoEllipsisText from "shared/components/AutoEllipsisText";
import { DateFilterDropdown } from "shared/components/DateFilterDropdown";
import { FilterDropdownWithFilterSearch } from "shared/components/FilterDropdownWithFilterSearch";
import { HoverActionsVirtualTable } from "shared/components/HoverActionsVirtualTable/HoverActionsVirtualTable";
import { TimeAgo } from "shared/components/TimeAgo";
import { useFilteredFeeds } from "shared/hooks/assetExporter/useFilteredFeeds";
import { useSourceFeedToolbar } from "shared/hooks/assetExporter/useSourceFeedToolbar";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { useWindowSize } from "shared/hooks/useWindowSize";
import {
  FeedTableAttrs,
  SourceFeedRightClickPopup,
} from "shared/types/assetExporter";
import { checkDateMatch, compareString } from "utils/helpers";
import styles from "./ConfigureFeedTable.module.scss";
import { getVersionColor } from "./utils";

const NOT_SUPPORTED = "Not supported";

export const ConfigureFeedTable = () => {
  const { selectedFeedIds, rightClickPopup } = useAppSelector(
    state => state.assetExporter.sourceFeed,
  );
  const actions = useAdEngineActions();

  const { dataSource, isLoading } = useFilteredFeeds();

  const { onConfigure } = useSourceFeedToolbar();

  const defaultRightClickState: SourceFeedRightClickPopup = {
    displayX: 0,
    displayY: 0,
    visible: false,
  };

  const onRightClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    feedId: string,
  ) => {
    actions.setRightClickPopup({
      displayX: event.clientX,
      displayY: event.clientY,
      visible: true,
    });
    actions.setSelectedFeedIds([feedId]);
  };

  const { windowInnerWidth } = useWindowSize();

  const feedNameWidth = windowInnerWidth > 1400 ? 400 : windowInnerWidth / 3.5;
  const columns: Array<TableColumnProps<FeedTableAttrs>> = useMemo(
    () => [
      {
        title: <b>Data Source</b>,
        dataIndex: "feedName",
        key: "feedName",
        width: feedNameWidth,
        fixed: "left",
        sorter: (a: FeedTableAttrs, b: FeedTableAttrs) => {
          return compareString(a.feedName.trim(), b.feedName.trim());
        },
        render: (feedName: string, feed: FeedTableAttrs) => (
          <span
            className={styles.sourceFeedClickable}
            onClick={() => {
              onConfigure(feed.feedId);
            }}
          >
            <AutoEllipsisText>{feedName}</AutoEllipsisText>
          </span>
        ),
        filters: returnFilterOptions(
          dataSource?.map(feed => feed.feedName).sort(),
        ),
        filterDropdown: FilterDropdownWithFilterSearch,
        onFilter: (value, record) => record.feedName === value,
      },
      {
        title: <b>Versions</b>,
        dataIndex: "versions",
        key: "versions",
        width: 115,
        sorter: (a: FeedTableAttrs, b: FeedTableAttrs) => {
          return a.versions - b.versions;
        },
        render: (versions: number, feed: FeedTableAttrs) => {
          const { currStatus, feedId } = feed;
          const badgeColor = getVersionColor(currStatus);
          return (
            <span
              className={styles.sourceFeedClickable}
              onClick={() => {
                actions.setIsVersionsDrawerOpen(true);
                actions.setSelectedFeedIds([feedId]);
              }}
            >
              <Badge color={badgeColor} />V{versions}
            </span>
          );
        },
        filters: returnFilterOptions(
          dataSource
            ?.map(feed => "V" + Math.max(feed.versions))
            .sort((aVersion: string, bVersion: string) => {
              return Number(aVersion.slice(1)) - Number(bVersion.slice(1));
            }),
        ),
        filterDropdown: FilterDropdownWithFilterSearch,
        onFilter: (value, record) => "V" + Math.max(record.versions) === value,
      },
      {
        title: <b>Created</b>,
        dataIndex: "createdAt",
        key: "createdAt",
        width: 150,
        sorter: (a: FeedTableAttrs, b: FeedTableAttrs) => {
          return (b.createdAt ?? 0) - (a.createdAt ?? 0);
        },
        render: (createdAt: number) => <TimeAgo date={createdAt} />,
        filterDropdown: DateFilterDropdown,
        filterIcon: (filtered: boolean) => (
          <CalendarOutlined color={filtered ? "#1890ff" : undefined} />
        ),
        onFilter: (value, record) =>
          checkDateMatch(value?.toString(), record.createdAt ?? 0),
      },
      {
        title: <b>Last edited</b>,
        dataIndex: "lastEdited",
        key: "lastEdited",
        width: 150,
        sorter: (a: FeedTableAttrs, b: FeedTableAttrs) => {
          return (b.lastEdited ?? 0) - (a.lastEdited ?? 0);
        },
        render: (lastEdited: number) => <TimeAgo date={lastEdited} />,
        filterDropdown: DateFilterDropdown,
        filterIcon: (filtered: boolean) => (
          <CalendarOutlined color={filtered ? "#1890ff" : undefined} />
        ),
        onFilter: (value, record) =>
          checkDateMatch(value?.toString(), record.lastEdited ?? 0),
      },
      {
        title: <b>Feed type</b>,
        dataIndex: "type",
        key: "type",
        width: 150,
        sorter: (a: FeedTableAttrs, b: FeedTableAttrs) => {
          return compareString(a.type, b.type);
        },
        filters: returnFilterOptions(dataSource?.map(feed => feed.type).sort()),
        onFilter: (value, record) => record.type === value,
      },
      {
        title: <b>Last update</b>,
        dataIndex: "lastUpdate",
        key: "lastUpdate",
        width: 150,
        sorter: (a: FeedTableAttrs, b: FeedTableAttrs) => {
          const aLastUpdate = a.lastUpdate === NOT_SUPPORTED ? 0 : a.lastUpdate;
          const bLastUpdate = b.lastUpdate === NOT_SUPPORTED ? 0 : b.lastUpdate;

          return bLastUpdate - aLastUpdate;
        },
        render: (lastUpdate: number | typeof NOT_SUPPORTED) =>
          lastUpdate === NOT_SUPPORTED ? (
            <span className={styles.disabled}>{NOT_SUPPORTED}</span>
          ) : (
            <TimeAgo date={lastUpdate} />
          ),
        filterDropdown: DateFilterDropdown,
        filterIcon: (filtered: boolean) => (
          <CalendarOutlined color={filtered ? "#1890ff" : undefined} />
        ),
        onFilter: (value, record) =>
          checkDateMatch(value?.toString(), record.lastUpdate as number),
      },
      {
        title: <b>Frequency</b>,
        dataIndex: "frequency",
        key: "frequency",
        width: 150,
        sorter: (a: FeedTableAttrs, b: FeedTableAttrs) => {
          return compareString(a.frequency, b.frequency);
        },
        render(frequency: string) {
          if (frequency === NOT_SUPPORTED)
            return <span className={styles.disabled}>{NOT_SUPPORTED}</span>;

          return frequency;
        },
        filters: returnFilterOptions(
          dataSource?.map(feed => feed.frequency).sort(),
        ),
        onFilter: (value, record) => record.frequency === value,
      },
      {
        title: <b>Next update</b>,
        dataIndex: "nextUpdate",
        key: "nextUpdate",
        width: 150,
        sorter: (a: FeedTableAttrs, b: FeedTableAttrs) => {
          const aNextUpdate =
            !a.nextUpdate || a.nextUpdate === NOT_SUPPORTED ? 0 : a.nextUpdate;

          const bNextUpdate =
            !b.nextUpdate || b.nextUpdate === NOT_SUPPORTED ? 0 : b.nextUpdate;

          return bNextUpdate - aNextUpdate;
        },
        render: (nextUpdate: number | typeof NOT_SUPPORTED) =>
          nextUpdate === NOT_SUPPORTED ? (
            <span className={styles.disabled}>{NOT_SUPPORTED}</span>
          ) : nextUpdate ? (
            <TimeAgo date={nextUpdate} />
          ) : undefined,
        filterDropdown: DateFilterDropdown,
        filterIcon: (filtered: boolean) => (
          <CalendarOutlined color={filtered ? "#1890ff" : undefined} />
        ),
        onFilter: (value, record) =>
          checkDateMatch(value?.toString(), (record.nextUpdate ?? 0) as number),
      },
      {
        title: <b>Feed ID</b>,
        dataIndex: "feedId",
        key: "feedId",
        width: 150,
        sorter: (a: FeedTableAttrs, b: FeedTableAttrs) => {
          return compareString(a.feedId, b.feedId);
        },
        render: (feedId: string) => {
          return <AutoEllipsisText>{feedId}</AutoEllipsisText>;
        },
        filters: returnFilterOptions(
          dataSource?.map(feed => feed.feedId).sort(),
        ),
        filterDropdown: FilterDropdownWithFilterSearch,
        onFilter: (value, record) => record.feedId === value,
      },
      {
        title: <b>Filter field</b>,
        dataIndex: "filterColHeader",
        key: "filterColHeader",
        width: 150,
        filters: returnFilterOptions(
          dataSource?.map(feed => feed.filterColHeader).sort(),
        ),
        filterDropdown: FilterDropdownWithFilterSearch,
        onFilter: (value, record) => record.filterColHeader === value,
      },
      {
        title: <b>Brand</b>,
        dataIndex: "oemTags",
        key: "brandTags",
        width: 150,
        render: (tags: string[]) => {
          return <TagsGroup tags={tags ?? []} collapsable={true} />;
        },
        filters: returnFilterOptions(
          dataSource?.flatMap(feed => feed.oemTags).sort(),
        ),
        filterDropdown: FilterDropdownWithFilterSearch,
        onFilter: (value, record) => record.oemTags.includes(value as string),
      },
      {
        title: <b>Account</b>,
        dataIndex: "storeTags",
        key: "accountTags",
        width: 150,
        render: (tags: string[]) => {
          return <TagsGroup tags={tags ?? []} collapsable={true} />;
        },
        filters: returnFilterOptions(
          dataSource?.flatMap(feed => feed.storeTags).sort(),
        ),
        filterDropdown: FilterDropdownWithFilterSearch,
        onFilter: (value, record) => record.storeTags.includes(value as string),
      },
    ],
    [actions, dataSource, feedNameWidth, onConfigure],
  );

  const { windowInnerHeight } = useWindowSize();

  return (
    <HoverActionsVirtualTable
      dataSource={dataSource}
      scroll={{ y: windowInnerHeight - 200, x: 2415 }}
      rowKey={record => record.feedId}
      columns={columns}
      pagination={false}
      loading={isLoading}
      rowSelection={{
        onChange: allSelectedIds => {
          actions.setSelectedFeedIds(allSelectedIds as string[]);
        },
        selectedRowKeys: selectedFeedIds,
      }}
      className={styles.feedTable}
      onRow={record => ({
        onContextMenu: event => {
          event.preventDefault();
          document.addEventListener("click", function onClickOutside() {
            document.removeEventListener("click", onClickOutside);
          });

          if (!rightClickPopup.visible) {
            document.addEventListener("click", function onClickOutside() {
              actions.setRightClickPopup(defaultRightClickState);
              document.removeEventListener("click", onClickOutside);
            });
          }
          onRightClick(event, record.feedId);
        },
      })}
    />
  );
};
