import { ThumbnailCell } from "shared/components/ThumbnailCell";
import { isArtboard } from "../../helpers";
import { DesignStudioTableItem } from "screens/designStudio/context/DesignStudioDataProvider";

export const DesignStudioItemNameCell = ({
  item,
  onClick,
}: {
  item: DesignStudioTableItem;
  onClick: () => void;
}) => {
  return (
    <ThumbnailCell
      name={item.asset.name}
      image={isArtboard(item) ? undefined : item.asset.thumbnailUrl}
      onClick={onClick}
    />
  );
};
