import { memo, ReactElement } from "react";
import {
  ICarouselElement,
  InstantExperienceBodyElement,
} from "shared/types/adLibrary";
import CarouselComponentForm, {
  ICarouselComponentForm,
} from "./carouselComponent/CarouselComponentForm";
import { getDefaultPhotoValues } from "./ImageComponent";
import uuid from "uuid";
import { useAppSelector } from "shared/hooks/useAppSelector";

interface IProps {
  instantExperienceElement: ICarouselElement;
  updateElement(instantExperienceElement: InstantExperienceBodyElement): void;
  isLastElement?: boolean;
}

export const getDefaultCarouselValues = (): ICarouselElement => ({
  id: uuid(),
  element_type: "CAROUSEL",
  name: "Carousel",
  child_elements: [getDefaultPhotoValues(), getDefaultPhotoValues()],
});

function CarouselComponent({
  instantExperienceElement: carouselElement,
  updateElement,
  isLastElement,
}: IProps): ReactElement {
  const instantExperienceDraftValidations = useAppSelector(
    state => state.designStudio.instantExperienceDraftValidations,
  );
  const carouselElementValidations =
    instantExperienceDraftValidations?.body_elements.find(
      element => element.id === carouselElement.id,
    );

  const childrenIdsToDisplayValidations =
    carouselElementValidations?.child_elements
      ?.filter(item => item.hasErrors && item.displayAlerts)
      .map(item => item.id!);

  const values: ICarouselComponentForm = {
    childElements: carouselElement.child_elements,
  };

  function getInstantExperienceElement(
    values: ICarouselComponentForm,
  ): ICarouselElement {
    return {
      id: carouselElement.id,
      name: carouselElement.name,
      element_type: "CAROUSEL",
      child_elements: values.childElements,
    };
  }

  return (
    <CarouselComponentForm
      values={values}
      onValuesChange={values => {
        if (!values) {
          return;
        }
        updateElement(getInstantExperienceElement(values));
      }}
      childrenIdsToDisplayValidations={childrenIdsToDisplayValidations}
      isLastElement={isLastElement}
    />
  );
}

export default memo(CarouselComponent);
