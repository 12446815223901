import { memo } from "react";
import { Button, Tooltip, Divider, Typography } from "antd";
import { FilterFilled } from "@ant-design/icons";
import styles from "./SummaryToolbar.module.scss";
import { AdType } from "screens/adLibrary/facebookUtils/types";
import { QCStatus } from "shared/types/shared";
import ChipsFilter from "./SummaryToolbar/ChipsFilter";

interface IProps {
  selectedCount: number;
  totalCount: number;
  displaySelected: boolean;
  filterTags?: (AdType | QCStatus | string)[];
  selectedTags?: (AdType | QCStatus | string)[];
  placeholder?: string;
  extraElements?: React.ReactNode[];
  dataSourceCount?: number;
  enhancedSearch?: boolean;
}

interface IHandlers {
  setDisplaySelected: (display: boolean) => void;
  clearFilters?: () => void;
  setTagSelected?: (tag: AdType | QCStatus | string) => void;
  onSelectAll?: () => void;
}

type PropsType = IProps & IHandlers;

const SummaryToolbarContainer = ({
  placeholder,

  selectedCount,
  totalCount,
  dataSourceCount,

  displaySelected,
  setDisplaySelected,

  onSelectAll,

  filterTags,
  selectedTags,
  setTagSelected,
  clearFilters,

  extraElements,
  enhancedSearch,
}: PropsType) => {
  const hasChipsFilter =
    filterTags &&
    selectedTags &&
    clearFilters &&
    setTagSelected &&
    setTagSelected;

  const getToggleAllLabel = () => {
    return dataSourceCount === selectedCount ? "Deselect All" : "Select All";
  };

  return (
    <div className={styles.toolbar}>
      <div className={styles.content}>
        {placeholder && (
          <span className={styles.placeholder}>{placeholder}</span>
        )}
        <Tooltip
          title={displaySelected ? "Show everything" : "Show only selected"}
        >
          <Button
            size="small"
            icon={!placeholder ? <FilterFilled /> : undefined}
            disabled={!selectedCount}
            onClick={() => setDisplaySelected(!displaySelected)}
          >{`${selectedCount} cards selected`}</Button>
        </Tooltip>
      </div>
      <Divider className={styles.divider} type="vertical" />
      <div className={styles.content}>
        <Typography.Text>{`${totalCount} ads`}</Typography.Text>
      </div>
      {onSelectAll && <Divider className={styles.divider} type="vertical" />}
      {onSelectAll && (
        <Button className={styles.selectAll} type="link" onClick={onSelectAll}>
          {getToggleAllLabel()}
        </Button>
      )}
      {((hasChipsFilter && !enhancedSearch) ||
        (enhancedSearch && !!selectedTags?.length)) && (
        <Divider type="vertical" />
      )}
      {hasChipsFilter && (
        <ChipsFilter
          filterTags={filterTags}
          selectedTags={selectedTags}
          clearFilters={clearFilters}
          setTagSelected={setTagSelected}
          enhancedSearch={enhancedSearch}
        />
      )}
      {extraElements?.length && (
        <div style={{ order: 2, marginLeft: "auto" }}>
          {extraElements.map(element => element)}
        </div>
      )}
    </div>
  );
};

export default memo(SummaryToolbarContainer);
