import { Progress, Space, Typography } from "antd";
import styles from "./ProgressNotificationDescription.module.scss";
import { IAdToLoadData } from "shared/types/adLibrary";
import { InfoCircleFilled } from "@ant-design/icons";
import classnames from "classnames";
import {
  getNumberOfFailedAds,
  getProgressCount,
  getProgressPercent,
  getProgressStatus,
} from "./helpers.progress";

const SUCESSFULL_COLOR = "#52c41a";

interface Props {
  selectedAdsToLoad: IAdToLoadData[];
  onRetry: () => void;
}

const ProgressNotificationDescription = ({
  selectedAdsToLoad,
  onRetry,
}: Props) => {
  const progressCount = getProgressCount(selectedAdsToLoad);
  const progressPercent = getProgressPercent(selectedAdsToLoad, progressCount);
  const numberOfFailedAds = getNumberOfFailedAds(selectedAdsToLoad);
  const progressStatus = getProgressStatus(progressPercent, numberOfFailedAds);

  const ProgressRow = ({
    name,
    successCount,
    totalCount,
  }: {
    name: string;
    successCount: number;
    totalCount: number;
  }) => (
    <li className={styles.item}>
      <span>{name}</span>
      <span>{`${successCount}/${totalCount}`}</span>
    </li>
  );

  return (
    <div>
      <ul className={styles.list}>
        <ProgressRow
          name="Loading Ads"
          successCount={progressCount.uploadMedia.successCount}
          totalCount={progressCount.uploadMedia.totalCount}
        />
        <ProgressRow
          name="Creating Ads"
          successCount={progressCount.assembleCreative.successCount}
          totalCount={progressCount.assembleCreative.totalCount}
        />
        <ProgressRow
          name="Finalizing Ads"
          successCount={progressCount.loadToFacebook.successCount}
          totalCount={progressCount.loadToFacebook.totalCount}
        />
        {numberOfFailedAds > 0 && (
          <li className={classnames([styles.item, styles.errorItem])}>
            <div className={styles.errorText}>
              <Space>
                <InfoCircleFilled />
                <span>
                  {numberOfFailedAds > 1
                    ? `${numberOfFailedAds} Failed ads`
                    : `${numberOfFailedAds} Failed ad`}
                </span>
              </Space>
            </div>
            <Typography.Link
              onClick={onRetry}
              disabled={progressStatus !== "exception"}
            >
              Retry
            </Typography.Link>
          </li>
        )}
      </ul>
      <Progress
        percent={progressPercent}
        status={progressStatus}
        strokeColor={
          progressStatus != "exception" ? SUCESSFULL_COLOR : undefined
        }
        size="small"
      />
    </div>
  );
};
export default ProgressNotificationDescription;
