import FontFaceObserver from "fontfaceobserver";
import { memoize } from "lodash";
import { getAccessToken } from "./auth";
import { config } from "./config";

const searchResultEmpty: WDSearchResult = {
  success: 1,
  offset: 0,
  limit: 1000,
  total_count: 0,
  items: [],
  facets: [],
};

const searchFontsPayload: WDSearchPayload = {
  rows: 1000,
  filters: {
    fields: [
      {
        field_name: "filetype",
        filter_type: "match",
        value: "woff",
      },
    ],
  },
};

const searchV2 = async (payload: WDSearchPayload): Promise<WDSearchResult> => {
  const response = await fetch(`${config.wdBaseUrl}/v2/search`, {
    body: JSON.stringify(payload),
    method: "POST",
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });

  if (response.ok) {
    return await response.json();
  }

  return searchResultEmpty;
};

const getAssetEmbedLinks = async (
  assetId: string,
): Promise<WDEmbedLinksResponse> => {
  const response = await fetch(
    `${config.wdBaseUrl}/assets/${assetId}/embedlinks`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return await response.json();
  }

  throw new Error(`Could not fetch webdam embedlinks from ${assetId}`);
};

export const fontsDownloaded = () => {
  const { fonts } = document;
  return [...new Set(Array.from(fonts.entries()).map(([font]) => font.family))];
};

export const loadFontFromCAM = async (
  fontFamily: string,
): Promise<FontFaceObserver | undefined> => {
  try {
    // Fetching all fonts (asset array) from webdam
    const response = (await memoizedSearchV2(
      searchFontsPayload,
    )) as WDSearchResult;
    const font = response.items.find(
      asset => asset.filename.split(".")[0] === fontFamily,
    );

    if (!font) {
      throw new Error(`${fontFamily} does not exist`);
    }

    // Retrieving font file download link
    const { directlinks } = (await memoizedGetEmbedLinks(
      font.id,
    )) as WDEmbedLinksResponse;

    // Loading downloaded font into browser
    const fontFace = new FontFace(fontFamily, `url(${directlinks.original})`, {
      weight: "normal",
      style: "normal",
    });
    const loadedFont = await fontFace.load();
    document.fonts.add(loadedFont);

    // Making elements using this font aware the font is ready to be used
    const ffo = new FontFaceObserver(fontFamily);
    await ffo.load();
    return ffo;
  } catch (err) {
    return;
  }
};

const memoizedSearchV2 = memoize(searchV2);
const memoizedGetEmbedLinks = memoize(getAssetEmbedLinks);
