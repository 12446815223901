import {
  IInstantExperience,
  InstantExperienceBodyElement,
} from "shared/types/adLibrary";
import { HeaderMenu, LogoEventType, TabMenu } from "shared/types/designStudio";
import { isFeatureEnabled } from "./helpers";

export const designStudioTabs = {
  templatesEnabled: isFeatureEnabled("ENABLE_TEMPLATES", true),
  stampsEnabled: isFeatureEnabled("ENABLE_STAMPS", true),
  artboardsEnabled: isFeatureEnabled("ENABLE_ARTBOARDS", true),
  instantExperienceEnabled: isFeatureEnabled(
    "ENABLE_INSTANT_EXPERIENCES",
    true,
  ),
};

export const defaultTab = () => {
  const tab = Object.entries(designStudioTabs).find(([_, value]) => value);
  if (tab) {
    switch (tab[0]) {
      case "templatesEnabled":
        return "/design-studio/library/templates";
      case "stampsEnabled":
        return "/design-studio/library/stamps";
      case "artboardsEnabled":
        return "/design-studio/library/artboards";
    }
  }
  return "";
};

export const libraryRoute = (
  selectedHeader: HeaderMenu,
  selectedTab: TabMenu,
) => {
  if (selectedHeader !== "DELETED ASSETS" && selectedTab === "everything-ads") {
    return "/everything-ads/ads";
  }
  return `/design-studio/library/${selectedTab}`;
};

export const isLastElement = (
  instantExperience: IInstantExperience,
  component?: InstantExperienceBodyElement,
) => {
  return (
    component?.id ===
    instantExperience.body_elements?.[
      instantExperience.body_elements?.length - 1
    ]?.id
  );
};

export const logoColorByType = (eventType: LogoEventType) => {
  switch (eventType) {
    case "ACCOUNT_LOGO":
    case "STORE_LOGO":
      return "#5D69B1";
    case "BRAND_LOGO":
    case "OEM_LOGO":
      return "#E58608";
    case "SALES_EVENT_LOGO":
    default:
      return "#52BCA3";
  }
};
