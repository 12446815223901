import { useEffect, useRef, useState } from "react";
import { Empty, Menu, Popover, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Tab } from "shared/types/assetBuilder";
import styles from "./FeedSelector.module.scss";

type Props = {
  tabs: Tab[];
  loading: boolean;
  onSelect: (tab: Tab) => void;
};
const FeedSelector = (props: Props) => {
  const [open, setOpen] = useState(false);

  const ref = useRef<HTMLSpanElement | null>(null);
  useEffect(() => {
    const onClickOutside = (e: MouseEvent) => {
      if (!e.target) return;

      if (ref.current?.contains(e.target as HTMLElement)) return;

      setOpen(false);
    };
    window.addEventListener("click", onClickOutside);

    return () => {
      window.removeEventListener("click", onClickOutside);
    };
  }, []);

  const { tabs, loading, onSelect } = props;

  return (
    <Popover
      visible={open}
      placement={"bottom"}
      content={
        <Spin tip="Loading..." spinning={loading}>
          <div className={styles.FeedSelect}>
            {!loading && tabs.length === 0 && <Empty />}
            {!loading && tabs.length > 0 && (
              <Menu style={{ border: 0 }} mode="vertical" selectedKeys={[]}>
                {tabs.map(tab => (
                  <Menu.Item
                    key={`menu-item-tab-${tab.id}`}
                    onClick={e => {
                      e.domEvent.stopPropagation();

                      onSelect(tab);

                      setOpen(false);
                    }}
                  >
                    {tab.name}
                  </Menu.Item>
                ))}
              </Menu>
            )}
          </div>
        </Spin>
      }
    >
      <span
        ref={ref}
        onClick={e => {
          setOpen(!open);
          e.stopPropagation();
        }}
        className={styles.AddFeedButton}
      >
        <PlusOutlined />
        <span>Add Feed</span>
      </span>
    </Popover>
  );
};

export default FeedSelector;
