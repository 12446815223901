import { TableCardLayout } from "shared/types/toolbar";
import usePreference from "./usePreference";

const useSelectedLayout = () => {
  // Possble TODO: set default layout based on client and/or industry
  const { value: layout, setValue: setLayout } = usePreference<TableCardLayout>(
    "layout",
    "card",
    true,
  );

  return {
    layout,
    setLayout,
  };
};

export default useSelectedLayout;
