import { CSSProperties, ReactNode } from "react";

type Props = {
  justify?: CSSProperties["justifyContent"];
  children: ReactNode;
};

export const ButtonGroup = ({ justify, children }: Props) => {
  const justifyContent = justify ?? "end";

  return (
    <div style={{ display: "flex", justifyContent, gap: "1em" }}>
      {children}
    </div>
  );
};
