import { PaymentEngineOfferDataField } from "shared/types/paymentEngine";

export const multiSelectableFieldNames: PaymentEngineOfferDataField[] = [
  "leaseVehicleSalesPrice",
  "numberOfPayments",
  "monthlyPayment",
  "totalDueAtSigning",
  "milesPerYear",
  "leaseDownPayment",
  "zeroDownLeaseNumberOfPayments",
  "zeroDownLeaseMonthlyPayment",
  "zeroDownLeaseVehicleSalesPrice",
  "zeroDownLeaseMilesPerYear",
  "financeTerm",
  "financePayment",
  "financeDownPayment",
];
