import { IAd } from "shared/types/adLibrary";
import MarketingPrototype from "./marketingTile/MarketingPrototype";
import Handle from "../shared/Handle";
import Wrapper from "../shared/Wrapper";
import { NodeProps } from "react-flow-renderer";
import { shouldHaveSourceHandle } from "../../utils";

type Props = NodeProps<{ ad: IAd }>;

const MarketingTile = (props: Props) => {
  const {
    data: { ad },
    selected,
  } = props;
  const {
    inputParameters: { name },
  } = ad || {};

  return (
    <Wrapper style={{ width: "auto" }} title={name || "-"} selected={selected}>
      <MarketingPrototype ad={ad} />

      {shouldHaveSourceHandle(ad) && <Handle id={ad.id} type="source" />}
    </Wrapper>
  );
};

export default MarketingTile;
