import { useState } from "react";
import { DatePicker } from "antd";
import type { RangePickerProps } from "antd/lib/date-picker";
import type { Moment, unitOfTime } from "moment";

const { RangePicker } = DatePicker;

type RangeValue = [Moment | null, Moment | null] | null;

type Props = {
  restriction: {
    unitOfTime: unitOfTime.Diff;
    units: number;
  };
} & RangePickerProps;

const RestrictedRangePicker = ({ restriction, ...props }: Props) => {
  const { unitOfTime, units } = restriction;
  const [dates, setDates] = useState<RangeValue>(null);

  const disabledDate = (current: Moment) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], unitOfTime) >= units;
    const tooEarly = dates[1] && dates[1].diff(current, unitOfTime) >= units;
    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  return (
    <RangePicker
      {...props}
      value={dates || props.value}
      disabledDate={disabledDate}
      onCalendarChange={setDates}
      onOpenChange={onOpenChange}
    />
  );
};

export default RestrictedRangePicker;
