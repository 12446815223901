import {
  Divider,
  Drawer,
  Form,
  Input,
  Select,
  Typography,
  message,
} from "antd";
import { useCallback, useRef } from "react";
import VideoAsset from "./assetGroupDetailDrawer/VideoAsset";
import MultiInputAsset from "./assetGroupDetailDrawer/MultiInputAsset";
import styles from "./AssetGroupDetailDrawer.module.scss";
import {
  GoogleAdsCallToAction,
  EditableAssetGroupKey,
  IAssetGroup,
  Uploads,
} from "shared/types/adLibrary";
import { useForm } from "antd/lib/form/Form";
import Footer from "./assetGroupDetailDrawer/Footer";
import { usePMaxDataContext } from "shared/components/contextAPI/pmaxAssetGroup/PMaxDataProvider";
import {
  getMultiInputAssetList,
  getUpdatedAssetGroup,
  getUploadAssetList,
} from "./AssetGroupDetailDrawer.utils";
import {
  businessNameSchema,
  finalUrlSchema,
  getUploadsSchema,
} from "shared/schemas/pmax";
import { getErrorFromZod, useZodFormValidation } from "utils/helpers.zod";
import EditableAdToLoadUrlText from "screens/adLibrary/adLoad/adLoadDrawer/shared/components/adsToLoadTable/EditableAdToLoadUrlText";
import { useFetchInfiniteDealers } from "shared/hooks/useFetchDealers";
import { flatten } from "lodash";
import TitleWithDescription from "shared/components/uploadAsset/TitleWithDescription";
import produce from "immer";
import { imageAssetDetail } from "screens/adLibrary/constants";
import {
  OnUploadChange,
  UploadAssetFromCAM,
} from "shared/components/uploadAsset/UploadAssetFromCAM";

export type Mode = "create" | "update" | "idle";

const AssetGroupDetailDrawer = ({
  onSuccess,
}: {
  onSuccess?: (ids: string[]) => void;
}) => {
  const [form] = useForm();
  const {
    selectedAssetGroup,
    isSaving,
    mode,
    setUpdatedAssetGroups,
    setEditAssetGroup,
    setMode,
    save,
  } = usePMaxDataContext();

  const uploadAssetList = getUploadAssetList(selectedAssetGroup);
  const multiInputAssetList = getMultiInputAssetList(selectedAssetGroup);
  const { dealers = [] } = useFetchInfiniteDealers();
  const urlLabels = flatten(dealers.map(dealer => dealer.labels ?? []));

  const timeRef = useRef<NodeJS.Timeout>();
  const onChange = (value: string, key: EditableAssetGroupKey, id?: string) => {
    if (!id) return;

    const prevSelectedAssetGroup = selectedAssetGroup || {};

    setEditAssetGroup({
      ...(prevSelectedAssetGroup || {}),
      ...getUpdatedAssetGroup(key, value, prevSelectedAssetGroup),
    });

    if (timeRef.current) clearTimeout(timeRef.current);

    timeRef.current = setTimeout(() => {
      setUpdatedAssetGroups(prev => {
        const { [id]: existingAssetGroup = {} } = prev || {};
        return {
          ...(prev || {}),
          [id]: {
            ...existingAssetGroup,
            ...getUpdatedAssetGroup(key, value, existingAssetGroup),
          },
        };
      });
    }, 300);
  };

  const assetId = selectedAssetGroup?.id;

  const { finalUrlValidation, businessNameValidation } = useZodFormValidation({
    finalUrlValidation: {
      data: selectedAssetGroup?.finalUrl,
      schema: finalUrlSchema,
    },
    businessNameValidation: {
      data: selectedAssetGroup?.businessName,
      schema: businessNameSchema,
    },
  });

  const clearAssetGroupUpdates = () => {
    if (!assetId) return;
    setUpdatedAssetGroups(prev => {
      const { [assetId]: _, ...rest } = prev || {};
      return rest;
    });
    setMode("idle");
  };

  const onUploadAssetChange: OnUploadChange<Uploads> = useCallback(
    ({ type, fieldKey, value }) => {
      const { id } = selectedAssetGroup || {};
      if (!id) return;
      setUpdatedAssetGroups(prev => {
        const { uploads: existingUploads } = prev?.[id] || {};

        const baseState = {
          ...(prev?.[id] || {}),
          uploads: {
            ...(existingUploads || {}),
            [fieldKey]: {
              added: prev?.[id]?.uploads?.[fieldKey]?.added || [],
              deleted: prev?.[id]?.uploads?.[fieldKey]?.deleted || [],
            },
          },
        };

        const isRemovingCopyFile =
          type === "remove" &&
          baseState.uploads[fieldKey]?.added.some(
            addedFile => addedFile.uid === value.uid,
          );

        const newState = produce(baseState, draft => {
          if (type === "add") {
            draft.uploads[fieldKey]!.added.push(...value);
            return;
          }

          if (isRemovingCopyFile) {
            const copyIndex =
              draft.uploads[fieldKey]?.added.findIndex(
                addedFile => addedFile.uid === value.uid,
              ) ?? -1;

            copyIndex !== -1 &&
              draft.uploads[fieldKey]!.added.splice(copyIndex, 1);
          } else {
            draft.uploads[fieldKey]!.deleted.push(value);
          }
        });

        return {
          ...(prev || {}),
          [id]: {
            ...(prev?.[id] || {}),
            ...newState,
          },
        };
      });

      const { uploads } = selectedAssetGroup || {};

      const newSelectedAssetGroup: Partial<IAssetGroup> = {
        ...selectedAssetGroup,
        uploads: {
          ...(uploads || {}),
          [fieldKey]:
            type === "add"
              ? [...(uploads?.[fieldKey] || []), ...value]
              : uploads?.[fieldKey]?.filter(f => f.uid !== value.uid),
        },
      };

      setEditAssetGroup(newSelectedAssetGroup);
    },
    [selectedAssetGroup, setUpdatedAssetGroups, setEditAssetGroup],
  );

  const requiredFieldKeys: Array<keyof IAssetGroup["uploads"]> = [
    "landscapeImages",
    "squareImages",
    "squareLogos",
  ];

  return (
    <Drawer
      destroyOnClose
      className={styles.drawer}
      visible={mode !== "idle"}
      closable={false}
      maskClosable={false}
      width={"95%"}
      onClose={() => {
        setMode("idle");
      }}
      footer={
        <Footer
          mode={mode}
          isLoading={!!isSaving}
          onCancel={clearAssetGroupUpdates}
          onSave={async () => {
            try {
              const ids = await save();
              if (ids?.length && onSuccess) onSuccess(ids);
              message.success("Successfully saved asset group");
              setMode("idle");
            } catch (e: unknown) {
              message.error("Failed to save asset group");
            }
          }}
        />
      }
    >
      <Typography.Title level={5}>
        {mode === "create" ? "New asset group" : selectedAssetGroup?.name}
      </Typography.Title>
      <Divider className={styles.divider} />
      <Form name="pmax-form" form={form} layout="vertical" id="pmax">
        <div className={styles.formColumn}>
          {mode === "create" && (
            <Form.Item label={"Asset group name"}>
              <Input
                value={selectedAssetGroup?.name}
                onChange={e => onChange(e.target.value, "name", assetId)}
              />
            </Form.Item>
          )}

          {uploadAssetList.map(
            ({ title, fileList, minimum, maxLength, key }, idx) => (
              <UploadAssetFromCAM
                key={`${title}-${idx}`}
                title={title}
                fileList={fileList}
                minimum={minimum}
                maxLength={maxLength}
                fieldKey={key}
                required={requiredFieldKeys.includes(key)}
                onUploadChange={onUploadAssetChange}
                validationSchema={getUploadsSchema(title)}
                imageAssetDetail={imageAssetDetail}
              />
            ),
          )}
        </div>
        <Divider type="vertical" />
        <div className={styles.formColumn}>
          <VideoAsset assets={selectedAssetGroup?.videoAssets || []} />
          {multiInputAssetList.map(
            ({ title, values, maxLength, key, showLength }, idx) => (
              <MultiInputAsset
                key={`${title}-${idx}`}
                title={title}
                values={values}
                maxLength={maxLength}
                fieldKey={key}
                showLength={showLength}
              />
            ),
          )}
        </div>
        <Divider type="vertical" />
        <div className={styles.formColumn}>
          <Form.Item
            label={<TitleWithDescription title="Final URL" required />}
            help={getErrorFromZod(finalUrlValidation.errors, [])}
            validateStatus={!finalUrlValidation.success ? "error" : "success"}
          >
            <EditableAdToLoadUrlText
              showInputOnly
              disableLabelPopulation
              urlLabels={urlLabels}
              willCheckFullUrl={false}
              linkValue={selectedAssetGroup?.finalUrl}
              onChange={value => onChange(value, "finalUrl", assetId)}
            />
          </Form.Item>
          <Form.Item
            label={<TitleWithDescription title="Business Name" required />}
            help={getErrorFromZod(businessNameValidation.errors, [])}
            validateStatus={
              !businessNameValidation.success ? "error" : "success"
            }
          >
            <Input
              value={selectedAssetGroup?.businessName}
              onChange={e => onChange(e.target.value, "businessName", assetId)}
            />
          </Form.Item>
          <Form.Item label={"Call To Action"}>
            <Select
              value={selectedAssetGroup?.callToAction}
              onChange={value => onChange(value, "callToAction", assetId)}
            >
              {Object.values(GoogleAdsCallToAction).map((cta: string) => {
                return (
                  <Select.Option key={cta} value={cta}>
                    {cta}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label={"Display Path"}>
            <div className={styles.path}>
              <Typography.Text>/</Typography.Text>
              <Input.TextArea
                className={styles.pathInput}
                value={selectedAssetGroup?.displayPath?.[0]}
                onChange={e =>
                  onChange(e.target.value, "displayPath0", assetId)
                }
                maxLength={15}
                autoSize={{ minRows: 1, maxRows: 1 }}
                showCount
              />
              <Typography.Text>/</Typography.Text>
              <Input.TextArea
                className={styles.pathInput}
                value={selectedAssetGroup?.displayPath?.[1]}
                onChange={e =>
                  onChange(e.target.value, "displayPath1", assetId)
                }
                autoSize={{ minRows: 1, maxRows: 1 }}
                maxLength={15}
                showCount
              />
            </div>
          </Form.Item>
        </div>
      </Form>
    </Drawer>
  );
};

export default AssetGroupDetailDrawer;
