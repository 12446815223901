import { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  AdLoadRuleConflictDict,
  IAdLoadParameters,
  FacebookDataIdsByAd,
  AdLoadParametersRulesetObject,
  IAd,
} from "shared/types/adLibrary";
import {
  setAdLoadConflictIdDict,
  updateFacebookAdLoadDictionary,
  updateFacebookDataIdsByAd,
} from "redux/adLibrary/adLibrary.slice";
import {
  getConflictingAdIds,
  getFacebookDataByIds,
  getIdDictFromConflictDict,
  getIdsFromFacebookDic,
  hasMoreAdsThanCapacity,
  returnFacebookAdLoadDict,
} from "screens/adLibrary/adLoad/utils";
import { pickBy, toPairs } from "lodash";
import { hasAnyFilledRule } from "screens/adLibrary/adLoad/adLoadDrawer/selectStep/updateAds/helpers.rules";
import { useAppSelector } from "shared/hooks/useAppSelector";
import {
  IFacebookAccount,
  IFacebookCampaign,
} from "screens/adLibrary/facebookUtils/types";
import { useCampaigns } from "screens/adLibrary/adLoad/adLoadDrawer/selectStep/updateAds/useCampaigns";
import {
  selectAdLoadParameters,
  selectSelectedAccounts,
  selectSelectedAds,
} from "redux/adLibrary/adLibrary.selectors";

export const useMapRulesToLocations = () => {
  const dispatch = useDispatch();

  const { campaigns } = useCampaigns();
  const selectedAds = useAppSelector(selectSelectedAds);
  const adLoadParameters = useAppSelector(selectAdLoadParameters);
  const selectedAccounts = useAppSelector(selectSelectedAccounts);

  const partialAdLoadParameters = useMemo(
    () => ({
      rulesets: adLoadParameters?.rulesets,
      taxonomies: adLoadParameters?.taxonomies,
      delimiters: adLoadParameters?.delimiters,
    }),
    [
      adLoadParameters?.delimiters,
      adLoadParameters?.rulesets,
      adLoadParameters?.taxonomies,
    ],
  );

  const mapRulesToLocations = useCallback(() => {
    const facebookDataIds = getFacebookDataIds(
      partialAdLoadParameters,
      campaigns,
      selectedAccounts,
    );
    const facebookAdDictionary = getFacebookDataDictionary(
      facebookDataIds,
      campaigns,
      selectedAds,
    );
    const adLoadRuleConflictIdDict = getAdLoadRuleConflictIdDict(
      partialAdLoadParameters.rulesets,
      facebookDataIds,
    );

    dispatch(updateFacebookDataIdsByAd(facebookDataIds));
    dispatch(updateFacebookAdLoadDictionary(facebookAdDictionary));
    dispatch(
      setAdLoadConflictIdDict({
        data: adLoadRuleConflictIdDict,
      }),
    );
  }, [
    campaigns,
    dispatch,
    partialAdLoadParameters,
    selectedAccounts,
    selectedAds,
  ]);

  useEffect(mapRulesToLocations, [mapRulesToLocations]);
};

const getFacebookDataIds = (
  adLoadParameters: Partial<IAdLoadParameters> | null,
  campaigns: IFacebookCampaign[] | undefined,
  selectedAccounts: IFacebookAccount[] | null | undefined,
) => {
  if (!campaigns || !selectedAccounts) {
    return {};
  }

  const newFacebookAdLoadDic = returnFacebookAdLoadDict({
    adLoadParameters: adLoadParameters,
    campaigns,
    selectedAccounts,
  });

  const adIdsWithRules = Object.keys(
    pickBy(
      adLoadParameters?.rulesets,
      ruleset =>
        hasAnyFilledRule(ruleset?.campaign) ||
        hasAnyFilledRule(ruleset?.adset) ||
        hasAnyFilledRule(ruleset?.ad),
    ),
  );
  const updatedFacebookAdLoadDic = pickBy(
    newFacebookAdLoadDic,
    (_content, adId) => adIdsWithRules.includes(adId),
  );

  return getIdsFromFacebookDic(updatedFacebookAdLoadDic);
};

const getFacebookDataDictionary = (
  facebookDataIdsByAd: FacebookDataIdsByAd | null,
  campaigns: IFacebookCampaign[] | undefined,
  selectedAds: IAd[] | null,
) => {
  if (
    !facebookDataIdsByAd ||
    hasMoreAdsThanCapacity(selectedAds, facebookDataIdsByAd)
  ) {
    return {};
  }

  return getFacebookDataByIds(facebookDataIdsByAd, campaigns);
};

const getAdLoadRuleConflictIdDict = (
  rulesets: AdLoadParametersRulesetObject | null | undefined,
  facebookDataIdsByAd: FacebookDataIdsByAd | null,
) => {
  if (!facebookDataIdsByAd || !rulesets) {
    return {};
  }

  const newAdLoadRuleConflictDict = toPairs(facebookDataIdsByAd).reduce(
    (currentNewAdLoadRuleConflictDict, [adId, facebookAdLoadDataDict]) => {
      const ruleset = rulesets[adId];
      if (!ruleset) {
        return currentNewAdLoadRuleConflictDict;
      }

      const ruleConflict = {
        adShellId: adId,
        ruleset: ruleset,
        adShellName: "",
        conflictAdShellIds: getConflictingAdIds(
          adId,
          facebookAdLoadDataDict.ad,
          facebookDataIdsByAd ?? {},
        ),
      };

      return { ...currentNewAdLoadRuleConflictDict, [adId]: ruleConflict };
    },
    {} as AdLoadRuleConflictDict,
  );

  return getIdDictFromConflictDict(newAdLoadRuleConflictDict);
};
