import { Button, Divider, Space } from "antd";
import { InfoCircleFilled, WarningFilled } from "@ant-design/icons";

import DisplayColumnPicker from "shared/components/select/DisplayColumnPicker";
import TitleRow, { TitleRowType } from "../shared/components/TitleRow";

import { useCallback, useMemo } from "react";

import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { setAdLoadState } from "redux/adLibrary/adLibrary.slice";

import useFacebookPixelWarning from "../shared/hooks/reviewStep/usePixelWarning";

import { sum } from "lodash";

import {
  getReviewAndQAIssuesForInnerStep,
  getInnerStepReviewMessageText,
  noMoreQAIssues,
} from "../shared/components/adsToLoadTable/utils";

import {
  adsToLoadTableColumnNames,
  buttonColors,
  buttonTitlesForInnerStepOpeners,
  columnPresets,
} from "../shared/constants";
import { activeHeaderButtonStyle } from "../shared/components/adsToLoadTable/constants";

import { ButtonProps } from "antd/es/button";
import {
  AdLoadAdStatusEnum,
  AdLoadColumnKey,
  IAdLoadReviewAndQAIssue,
  IAdToLoadData,
  ReviewStepKeys,
} from "shared/types/adLibrary";
import { IAccount } from "shared/types/accountManagement";

const {
  CONVERT_TO_VIDEO,
  REVIEW_DESTINATION_URLS,
  ASSIGN_PRODUCT_SETS_AD_LEVEL,
  ASSIGN_PRODUCT_SETS_IE_LEVEL,
  LINK_INSTANT_EXPERIENCE,
  INVALID_DESTINATION_URL,
} = ReviewStepKeys;

interface IProps {
  loading?: boolean;
  selectedStores?: IAccount[];
  adsToLoad?: IAdToLoadData[];
  topStatus?: AdLoadAdStatusEnum | null;
  selectedColumnKeys?: AdLoadColumnKey[];
}
interface IHandlers {
  onHeaderButtonClick?: (status: AdLoadAdStatusEnum | null) => void;
  onColumnKeySelectionChange?: (columnKeys: string[]) => void;
}

type ReviewStepHeaderProps = IProps & IHandlers;

const ReviewStepHeader = ({
  topStatus,
  adsToLoad,
  selectedStores,
  selectedColumnKeys,
  onHeaderButtonClick,
  onColumnKeySelectionChange,
  loading,
}: ReviewStepHeaderProps) => {
  const dispatch = useAppDispatch();
  const pixelWarning = useFacebookPixelWarning({ selectedStores });

  const getGoToButtonProps = useCallback(
    (innerStepKey: ReviewStepKeys): ButtonProps => ({
      type: "link",
      onClick: () =>
        dispatch(
          setAdLoadState({
            prop: "currentInnerStep",
            data: innerStepKey,
          }),
        ),
    }),
    [dispatch],
  );

  const errorsAndWarningsByInnerStep: Record<
    ReviewStepKeys,
    IAdLoadReviewAndQAIssue[]
  > = useMemo(
    () => ({
      ASSIGN_PRODUCT_SETS_IE_LEVEL: getReviewAndQAIssuesForInnerStep(
        adsToLoad ?? [],
        ASSIGN_PRODUCT_SETS_IE_LEVEL,
      ),
      ASSIGN_PRODUCT_SETS_AD_LEVEL: getReviewAndQAIssuesForInnerStep(
        adsToLoad ?? [],
        ASSIGN_PRODUCT_SETS_AD_LEVEL,
      ),
      REVIEW_DESTINATION_URLS: getReviewAndQAIssuesForInnerStep(
        adsToLoad ?? [],
        REVIEW_DESTINATION_URLS,
      ),
      CONVERT_TO_VIDEO: getReviewAndQAIssuesForInnerStep(
        adsToLoad ?? [],
        CONVERT_TO_VIDEO,
      ),
      LINK_INSTANT_EXPERIENCE: getReviewAndQAIssuesForInnerStep(
        adsToLoad ?? [],
        LINK_INSTANT_EXPERIENCE,
      ),
      INVALID_DESTINATION_URL: getReviewAndQAIssuesForInnerStep(
        adsToLoad ?? [],
        INVALID_DESTINATION_URL,
      ),
    }),
    [adsToLoad],
  );

  const validationRows = useMemo(
    () =>
      Object.entries(errorsAndWarningsByInnerStep)
        .map(entry => {
          const issues = entry[1];
          if (!issues?.length) {
            return;
          }
          const innerStep = entry[0] as ReviewStepKeys;
          return {
            type:
              innerStep !== REVIEW_DESTINATION_URLS
                ? TitleRowType.ERROR
                : TitleRowType.WARNING,
            addTopSpacing: true,
            title: getInnerStepReviewMessageText(issues.length, innerStep),
            utilities: [
              <Button
                disabled={loading}
                key="goto-btn"
                {...getGoToButtonProps(innerStep)}
              >
                {buttonTitlesForInnerStepOpeners[innerStep]}
              </Button>,
            ],
          };
        })
        .filter(row => !!row),
    [errorsAndWarningsByInnerStep, getGoToButtonProps, loading],
  );

  const warningsCount =
    errorsAndWarningsByInnerStep[REVIEW_DESTINATION_URLS].length;
  const errorCount = sum(
    Object.entries(errorsAndWarningsByInnerStep).map(entry =>
      entry[0] !== REVIEW_DESTINATION_URLS ? entry[1].length : 0,
    ),
  );

  const wasErrorClicked = topStatus === AdLoadAdStatusEnum.ERROR;
  const wasWarningClicked = topStatus === AdLoadAdStatusEnum.WARNING;

  const infoText = wasWarningClicked
    ? "Issues are optional fields and can also be fixed later once ads are loaded."
    : "Ads cannot be loaded until the following issues are addressed.";

  const isQAValid = useMemo(() => noMoreQAIssues(adsToLoad || []), [adsToLoad]);

  return (
    <>
      <Space
        size={-15}
        direction="vertical"
        style={{ width: "100%" }}
        split={<Divider type="horizontal" style={{ margin: "6px 0" }} />}
      >
        <TitleRow
          addTopSpacing
          title={isQAValid ? "" : infoText}
          type={isQAValid ? undefined : TitleRowType.INFO}
          utilities={[
            errorCount ? (
              <Button
                key="errors-btn"
                style={wasErrorClicked ? activeHeaderButtonStyle : undefined}
                onClick={event => {
                  if (wasErrorClicked) {
                    event.currentTarget.blur();
                  }
                  onHeaderButtonClick?.(
                    !wasErrorClicked ? AdLoadAdStatusEnum.ERROR : null,
                  );
                }}
              >
                <InfoCircleFilled style={{ color: buttonColors.error }} />
                <span>
                  {errorCount} Error{errorCount !== 1 ? "s" : ""}
                </span>
              </Button>
            ) : null,
            warningsCount ? (
              <Button
                key="warnings-btn"
                style={wasWarningClicked ? activeHeaderButtonStyle : undefined}
                onClick={event => {
                  if (wasWarningClicked) {
                    event.currentTarget.blur();
                  }
                  onHeaderButtonClick?.(
                    !wasWarningClicked ? AdLoadAdStatusEnum.WARNING : null,
                  );
                }}
              >
                <WarningFilled style={{ color: buttonColors.warning }} />
                <span>
                  {warningsCount} Warning{warningsCount !== 1 ? "s" : ""}
                </span>
              </Button>
            ) : null,
            <div key="column-picker-div">
              <DisplayColumnPicker
                presets={columnPresets}
                onConfirm={onColumnKeySelectionChange}
                columnKeys={adsToLoadTableColumnNames}
                selectedColumnKeys={selectedColumnKeys}
              />
            </div>,
          ]}
        />
        {!!pixelWarning && (
          <TitleRow
            addTopSpacing
            title={pixelWarning}
            type={TitleRowType.WARNING}
            key={`data-validation-row-pixel`}
          />
        )}
        {validationRows.map((validationRowProps, index) => (
          <TitleRow
            key={`data-validation-row-${index}`}
            {...validationRowProps!}
          />
        ))}
        <Divider type="horizontal" style={{ margin: "6px 0", opacity: 0 }} />
      </Space>
    </>
  );
};

export default ReviewStepHeader;
