import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Typography,
} from "antd";
import { useEffect, useRef, useState } from "react";

import FormDrawer from "shared/components/FormDrawer";

// types
import InputTextField from "antd/lib/input/Input";
import {
  IFormValidationAttributes,
  OperationMode,
} from "shared/types/inputValues";
import { IBrandRecord } from "shared/types/brandManagement";
import {
  ISingleLogoObject,
  IUploadImageFormInput,
  IUploadImagesResult,
  TUploadSetLogosInDictionary,
  TUploadGroup,
} from "shared/types/uploadManagement";

import * as validators from "../../utils/validators";

import { cleanNameStringForComparison, industryType } from "utils/helpers";

import { useIsAdmin } from "shared/hooks/useIsAdmin";
import OemLogos from "./OemLogos";
import { uploadImageTypesToS3 } from "utils/helpers.s3";
import { YoutubeIntegration } from "../platformManagement/YoutubeIntegration";
import styles from "./OemDrawer.module.scss";

const defaultValidationAttributes = {
  timeoutId: undefined,
  validationStatus: undefined,
  hasFeedback: undefined,
  feedbackMessage: "",
};
const defaultLogoAttributes = {
  horizontalLogos: [],
  verticalLogos: [],
  squareLogos: [],
  horizontalLogosEvent: [],
  verticalLogosEvent: [],
  squareLogosEvent: [],
};

interface IOemDrawer {
  mode: OperationMode;
  oems: IBrandRecord[];
  processingOems: boolean;
  showAddOemDrawer: boolean;
  closeAddOemDrawer: () => void;
  deleteOem: (inputOem: IBrandRecord) => void;
  addOrDeleteOemToDynamoDb: (oem: any, action: string) => void;
  submitOemForm: (inputOem: IBrandRecord) => void | null;
  s3AssetBucket: string;
  processingUpload: boolean;

  uploadImages: (
    imagesToUpload: IUploadImageFormInput[],
    featureName: string,
    group: TUploadGroup,
    mode: OperationMode,
    logoKeysToBeUploaded?: string[],
    newOemButtonClicked?: boolean,
  ) => void;
  resetUploadImagesInRedux: () => void;
  imagesToUpload: { images: IUploadImagesResult[] };

  oemToEditWithLogos: IBrandRecord;
  setOemToEditWithLogos: (oemToEditWithLogos: IBrandRecord) => void;

  compareOemToEditWithLogos: IBrandRecord;
  setCompareOemToEditWithLogos: (
    compareOemToEditWithLogos: IBrandRecord,
  ) => void;

  logosUploadStatus: null | "UPLOADING" | "COMPLETE";
  toggleNewOemButtonClicked: (submitButtonClicked: boolean) => void;
  newOemButtonClicked: boolean;

  logoKeysToBeUploaded: string[];
  setLogoKeysToBeUploaded: (logoKeysToBeUploaded: string[]) => void;
}

const OemDrawer: React.FC<IOemDrawer> = ({
  mode,
  oems,
  processingOems,
  showAddOemDrawer,
  closeAddOemDrawer,
  deleteOem,
  submitOemForm,
  s3AssetBucket,
  processingUpload,
  addOrDeleteOemToDynamoDb,

  uploadImages,

  oemToEditWithLogos,
  setOemToEditWithLogos,

  resetUploadImagesInRedux,
  toggleNewOemButtonClicked,
  logosUploadStatus,

  setLogoKeysToBeUploaded,

  compareOemToEditWithLogos,
  setCompareOemToEditWithLogos,
}) => {
  const isAdmin = useIsAdmin();
  const [logosInDictionary, setLogosInDictionary] = useState<
    Record<TUploadSetLogosInDictionary, ISingleLogoObject[]>
  >({
    horizontalLogos: [],
    verticalLogos: [],
    squareLogos: [],
    horizontalLogosEvent: [],
    verticalLogosEvent: [],
    squareLogosEvent: [],
  });

  useEffect(() => {
    setLogosInDictionary({
      horizontalLogos: [],
      verticalLogos: [],
      squareLogos: [],
      horizontalLogosEvent: [],
      verticalLogosEvent: [],
      squareLogosEvent: [],
    });
  }, []);

  const [singleLogoObject, setSingleLogoObject] = useState<ISingleLogoObject>({
    group: "string",
    feedbackMessage: "",
  });

  const oemNameFieldRef = useRef<InputTextField>(null);

  const [showDeleteOemModal, toggleDeleteOemModal] = useState<boolean>(false);

  const [
    isSaveOrDiscardModalOpenOemManagement,
    toggleIsSaveOrDiscardModalOpenOemManagement,
  ] = useState<boolean>(false);

  const [oemNameValidationAttributes, setOemNameValidationAttributes] =
    useState<IFormValidationAttributes>({
      timeoutId: undefined,
      validationStatus: undefined,
      hasFeedback: undefined,
      feedbackMessage: "",
    });

  const [oemLogoUrlValidationAttributes, setOemLogoUrlValidationAttributes] =
    useState<IFormValidationAttributes>({
      timeoutId: undefined,
      validationStatus: undefined,
      hasFeedback: undefined,
      feedbackMessage: "",
    });

  const [
    oemEventLogoUrlsValidationAttributes,
    setOemEventLogoUrlsValidationAttributes,
  ] = useState<IFormValidationAttributes>({
    timeoutId: undefined,
    validationStatus: undefined,
    hasFeedback: undefined,
    feedbackMessage: "",
  });

  const [validForm, setValidForm] = useState(true);

  useEffect(() => {
    let invalidOemName: boolean | undefined = true;

    if (mode === "UPDATE") {
      invalidOemName =
        oemNameValidationAttributes.validationStatus === "error" &&
        oemNameValidationAttributes.hasFeedback;
    } else {
      invalidOemName =
        !oemNameValidationAttributes.validationStatus ||
        oemNameValidationAttributes.validationStatus === "error";
    }

    setValidForm(!invalidOemName || false);
  }, [
    mode,
    oemNameValidationAttributes,
    oemLogoUrlValidationAttributes,
    oemEventLogoUrlsValidationAttributes,
    oemToEditWithLogos,
  ]);

  const doesOemFormHaveChanges = (oemToEditWithLogos: IBrandRecord) => {
    return (
      JSON.stringify(compareOemToEditWithLogos) !==
      JSON.stringify(oemToEditWithLogos)
    );
  };

  const handleClose = (discardButtonPressed?: boolean) => {
    if (doesOemFormHaveChanges(oemToEditWithLogos) && !discardButtonPressed) {
      toggleIsSaveOrDiscardModalOpenOemManagement(true);
      return;
    }
    setOemNameValidationAttributes(defaultValidationAttributes);
    setOemLogoUrlValidationAttributes(defaultValidationAttributes);
    setOemEventLogoUrlsValidationAttributes(defaultValidationAttributes);
    resetUploadImagesInRedux();
    setLogosInDictionary(defaultLogoAttributes);

    setSingleLogoObject({
      group: "string",
      feedbackMessage: "",
    });

    toggleNewOemButtonClicked(false);
    closeAddOemDrawer();
    setCompareOemToEditWithLogos({
      key: 0,
      oemName: "",
      logoUrlsFromS3: {
        horizontalImagesFromS3: [],
        verticalImagesFromS3: [],
        squareImagesFromS3: [],
        horizontalEventImagesFromS3: [],
        verticalEventImagesFromS3: [],
        squareEventImagesFromS3: [],
      },

      logoUrl: "",
      eventLogos: [],
    });
  };

  const handleDelete = () => {
    toggleDeleteOemModal(true);
    addOrDeleteOemToDynamoDb(oemToEditWithLogos.oemName, "DELETE");
  };

  const handleSubmit = () => {
    const matchingOem = oems.find(oem => {
      const currentName = cleanNameStringForComparison(oem.oemName);
      const newNameTo = cleanNameStringForComparison(
        oemToEditWithLogos.oemName,
      );
      return currentName === newNameTo;
    });
    if (matchingOem && mode === "CREATE") {
      message.warning(`The desired oem name is currently in use.`);
      return;
    }

    toggleNewOemButtonClicked(true);

    // isAllLogosSubmitted will remain true if the user has not added any unsubmitted logos
    let isAllLogosSubmitted = true;

    // First for loop is to first set the logo keys to be uploaded so they are already in OemManagement.tsx
    const tempArr = [];
    for (const key in logosInDictionary) {
      if (logosInDictionary[key as TUploadSetLogosInDictionary].length > 0) {
        tempArr.push(key);
      }
    }
    if (tempArr.length > 0) {
      setLogoKeysToBeUploaded(tempArr);
      isAllLogosSubmitted = false;
    }
    // Second for loop is to upload the logos
    for (const key in logosInDictionary) {
      if (logosInDictionary[key as TUploadSetLogosInDictionary].length > 0) {
        uploadImageTypesToS3({
          key: key as TUploadSetLogosInDictionary,
          keysToBeUploaded: tempArr,
          logosInDictionary,
          mode,
          uploadImages,
        });
      }
    }

    setOemNameValidationAttributes(defaultValidationAttributes);
    setOemLogoUrlValidationAttributes(defaultValidationAttributes);
    setOemEventLogoUrlsValidationAttributes(defaultValidationAttributes);

    if (isAllLogosSubmitted) {
      submitOemForm(oemToEditWithLogos);
    }
    setLogosInDictionary(defaultLogoAttributes);
    setSingleLogoObject({
      group: "string",
      feedbackMessage: "",
    });

    addOrDeleteOemToDynamoDb(oemToEditWithLogos.oemName, "ADD");
  };

  useEffect(() => {
    if (logosUploadStatus) {
      toggleNewOemButtonClicked(false);
    }

    if (logosUploadStatus === "COMPLETE") {
      submitOemForm(oemToEditWithLogos);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logosUploadStatus]);

  const drawerForm = (
    <Form layout="vertical" data-cy="oem-form">
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            className={styles.formTitle}
            label={industryType("auto") ? "Enter OEM Name" : "Enter Brand Name"}
            validateStatus={oemNameValidationAttributes.validationStatus}
            hasFeedback={oemNameValidationAttributes.hasFeedback}
            help={oemNameValidationAttributes.feedbackMessage}
          >
            <Input
              data-cy="name-oem-form"
              ref={oemNameFieldRef}
              key="oem-name-input"
              required={true}
              value={oemToEditWithLogos.oemName}
              disabled={mode === "UPDATE"}
              onChange={(event: any) => {
                const { target } = event;
                const { value: updatedOemName } = target || {
                  value: undefined,
                };

                const { timeoutId } = oemNameValidationAttributes;

                if (timeoutId) {
                  clearTimeout(timeoutId);
                }

                if (updatedOemName === "") {
                  setOemNameValidationAttributes({
                    timeoutId: undefined,
                    validationStatus: undefined,
                    hasFeedback: undefined,
                    feedbackMessage: "",
                  });
                } else {
                  const newTimeoutId = setTimeout(() => {
                    const isNameInputValid =
                      validators.isNameInputValid(updatedOemName);

                    setOemNameValidationAttributes({
                      ...oemNameValidationAttributes,
                      validationStatus: isNameInputValid ? "success" : "error",
                      hasFeedback: true,
                      feedbackMessage: isNameInputValid
                        ? "valid OEM name"
                        : "Invalid OEM name. Make sure there are no consecutive spaces.",
                    });
                  }, 300);

                  setOemNameValidationAttributes({
                    timeoutId: newTimeoutId,
                    validationStatus: "validating",
                    hasFeedback: true,
                    feedbackMessage: "",
                  });
                }

                setOemToEditWithLogos({
                  ...oemToEditWithLogos,
                  oemName: updatedOemName,
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label={"Integrations"}>
            <YoutubeIntegration
              oem={oemToEditWithLogos?.oemName}
              connected={oemToEditWithLogos.youtubeConnected || false}
              setConnection={(connection: boolean) =>
                submitOemForm({
                  ...oemToEditWithLogos,
                  youtubeConnected: connection,
                })
              }
              moduleUrl="oem-management"
              isNew={mode === "CREATE"}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <OemLogos
            s3AssetBucket={s3AssetBucket}
            uploadImages={uploadImages}
            singleLogoObject={singleLogoObject}
            setSingleLogoObject={setSingleLogoObject}
            showAddOemDrawer={showAddOemDrawer}
            logosInDictionary={logosInDictionary}
            setLogosInDictionary={setLogosInDictionary}
            oemToEditWithLogos={oemToEditWithLogos}
            setOemToEditWithLogos={setOemToEditWithLogos}
            mode={mode}
            resetUploadImagesInRedux={resetUploadImagesInRedux}
          />
        </Col>
      </Row>
    </Form>
  );

  return (
    <>
      <Modal
        title="Confirm Deletion"
        visible={showDeleteOemModal}
        footer={[
          <Button
            key="oem-no-button"
            onClick={() => toggleDeleteOemModal(false)}
          >
            No
          </Button>,
          <Button
            data-cy="modal-confirm-delete-yes-button"
            onClick={() => {
              deleteOem(oemToEditWithLogos);
              toggleDeleteOemModal(false);
            }}
            key="oem-yes-button"
            type={"primary"}
          >
            Yes
          </Button>,
        ]}
      >
        <Typography.Text>
          {`Are you sure you sure you want to delete ${oemToEditWithLogos.oemName}?`}
        </Typography.Text>
      </Modal>

      <Modal
        className={styles.confirmModal}
        visible={isSaveOrDiscardModalOpenOemManagement}
        title="Confirm"
        closable={false}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              toggleIsSaveOrDiscardModalOpenOemManagement(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="discard"
            data-cy="modal-discard-button"
            danger
            type="primary"
            onClick={async () => {
              toggleIsSaveOrDiscardModalOpenOemManagement(false);
              closeAddOemDrawer();
              setOemToEditWithLogos({
                key: 0,
                oemName: "",
                logoUrlsFromS3: {
                  horizontalImagesFromS3: [],
                  verticalImagesFromS3: [],
                  squareImagesFromS3: [],
                  horizontalEventImagesFromS3: [],
                  verticalEventImagesFromS3: [],
                  squareEventImagesFromS3: [],
                },

                logoUrl: "",
                eventLogos: [],
              });
              handleClose(true);
            }}
          >
            Discard
          </Button>,
        ]}
      >
        <span>Are you sure you want to discard changes?</span>
      </Modal>

      <FormDrawer
        handleClose={handleClose}
        handleDelete={handleDelete}
        handleSubmit={handleSubmit}
        mode={mode}
        drawerWidth={420}
        dataName={industryType("auto") ? "OEM" : "Brand Details"}
        drawerForm={drawerForm}
        processingData={processingOems}
        processingUpload={processingUpload}
        showDrawer={showAddOemDrawer}
        validForm={validForm}
        allowDelete={isAdmin}
      />
    </>
  );
};

export default OemDrawer;
