import { notification } from "antd";
import { useQueries, useQuery } from "react-query";
import AdAccount from "screens/adLibrary/facebookUtils/adAccount";
import {
  IFacebookCampaign,
  IFacebookErrorObject,
} from "screens/adLibrary/facebookUtils/types";
import { useMemoDeepCompare } from "shared/hooks/useMemoDeepCompare";

interface IGetCampaignsArgs {
  limit?: number;
  includeAds: boolean;
  adAccountId?: string;
  startTimes?: number[];
  includeAdsets: boolean;
  adAccountIds?: string[];
  includeCreative?: boolean;
  pullDataViaPagination?: boolean;
}

const getCampaigns = (args: IGetCampaignsArgs) => async () => {
  const {
    limit,
    startTimes,
    includeAds,
    adAccountId,
    adAccountIds,
    includeAdsets,
    includeCreative,
    pullDataViaPagination,
  } = args;

  if (adAccountId) {
    const adAccount = new AdAccount(adAccountId);

    const { result, error } = await adAccount.getCampaigns({
      limit,
      startTimes,
      includeAds,
      includeAdsets,
      includeCreative,
      pullDataViaPagination,
    });

    if (error) {
      throw Error(error.message);
    }

    return result?.data;
  }

  if (!adAccountIds) {
    throw Error("Facebook account IDs must be provided.");
  }

  const campaignsResData = await Promise.all(
    adAccountIds.map(async id => {
      const adAccount = new AdAccount(id);
      try {
        return await adAccount.getCampaigns({
          limit,
          startTimes,
          includeAds,
          includeAdsets,
          includeCreative,
          pullDataViaPagination,
        });
      } catch (errorResponse) {
        const { error } = errorResponse as { error: IFacebookErrorObject };
        return { result: null, error: { ...error } };
      }
    }),
  );

  // Possible future TO DO: split campaigns into {<accountId>:campaigns[]} dictionary
  let aggregatedCampaigns: IFacebookCampaign[] = [];

  campaignsResData.forEach((response, index) => {
    const accountId = adAccountIds[index];
    const { result, error } = response;
    if (!result || error) {
      notification.error({
        message: "An error occurred while pulling campaign data",
        description: `From account ${accountId}: ${
          error?.message || "Something went wrong"
        }`,
      });
      return;
    }
    aggregatedCampaigns = aggregatedCampaigns.concat(result.data);
  });

  return aggregatedCampaigns;
};

const queryKey = (args: IGetCampaignsArgs) => {
  const namePart = "facebookCampaigns";
  const accountPart = args.adAccountIds
    ? args.adAccountIds.join(", ")
    : args.adAccountId;
  const startTimePart = args.startTimes?.join(", ");
  return [namePart, accountPart, startTimePart];
};

export const useFetchCampaigns = (
  args: IGetCampaignsArgs,
  options?: { enabled?: boolean; retry?: boolean },
) => {
  return useQuery<IFacebookCampaign[] | undefined, Error>(
    queryKey(args),
    getCampaigns(args),
    {
      enabled: options?.enabled,
      retry: options?.retry,
      staleTime: 1800000,
    },
  );
};

export type CampaignResByAcctId = ReturnType<
  typeof useFetchCampaignsV2
>["campaignResByAcctId"];

export const useFetchCampaignsV2 = ({
  accountIds,
}: {
  accountIds: string[];
}) => {
  const results = useQueries(
    accountIds.map(accountId => ({
      queryKey: ["facebookCampaignsV2", accountId],
      queryFn: getCampaigns({
        adAccountId: accountId,
        includeAds: false,
        includeAdsets: true,
        includeCreative: false,
        limit: 100,
        pullDataViaPagination: true,
      }),
      staleTime: 1800000,
      enabled: !!accountId,
    })),
  );

  const campaignsGroupedByAccountId = accountIds.reduce<{
    [accountId: string]: (typeof results)[number];
  }>((acc, accountId, index) => {
    const result = results[index];
    return {
      ...acc,
      [accountId]: result,
    };
  }, {});

  const campaignResByAcctId = useMemoDeepCompare(campaignsGroupedByAccountId);

  const isLoadingCampaigns = results.some(result => result.isLoading);

  return { campaignResByAcctId, isLoadingCampaigns };
};
