import { isEmpty } from "lodash";
import {
  TOfferFilterField,
  TOfferListQueryParameters,
  TVehicleCondition,
} from "shared/types/assetBuilder";
import { isOfferType, OfferType } from "shared/types/shared";
import { isVehicleCondition } from "./select/OfferFilter.utils";
import { TSortByOption } from "./select/OfferFilterV2";

export const parseSearchParams = (params: URLSearchParams) => {
  let updatedParams: TOfferListQueryParameters = {};
  // vehicleCondition
  let vehicleCondition = params.get("vehicleCondition"); // NOTE: if this param is empty, it is considered to be "All"
  if (!vehicleCondition) vehicleCondition = "All";

  if (!isVehicleCondition(vehicleCondition)) return;

  updatedParams = {
    ...updatedParams,
    vehicleCondition,
  };

  // offerTypes
  const offerTypes = params.get("offerTypes")?.split(","); // If undefined, we display all.
  const isInvalidOfferTypes =
    !isEmpty(offerTypes) &&
    !offerTypes?.every(offerType => isOfferType(offerType));

  if (isInvalidOfferTypes) return;

  if (!!offerTypes) {
    updatedParams = {
      ...updatedParams,
      offerTypes: offerTypes as OfferType[],
    };
  }

  const sortBy = params.get("sortBy")?.split(",");
  if (!!sortBy) {
    const sortByOptions = sortBy.map<TSortByOption>(sortByString => {
      const [key, order] = sortByString.split("-");
      return {
        key,
        order,
      } as TSortByOption;
    });

    updatedParams = {
      ...updatedParams,
      sortBy: sortByOptions,
    };
  }

  return updatedParams;
};

export const defaultVehicleCondition: TVehicleCondition = "All";
export const defaultSortByOptions: TSortByOption[] = [
  {
    key: "msrp",
    order: "asc",
  },
];
export const parseVehicleCondition = (params: URLSearchParams) => {
  const vehicleConditionFilterKey: TOfferFilterField = "vehicleCondition";
  const vehicleCondition = params.get(vehicleConditionFilterKey);
  return isVehicleCondition(vehicleCondition)
    ? vehicleCondition
    : defaultVehicleCondition;
};

export const parseOfferTypes = (params: URLSearchParams) => {
  const offerTypes = params.get("offerTypes")?.split(","); // If undefined, we display all.
  const invalidOfferTypes = !offerTypes?.every(offerType =>
    isOfferType(offerType),
  );

  if (invalidOfferTypes) return;

  return offerTypes as OfferType[];
};

export const parseSortBy = (params: URLSearchParams) => {
  const sortByKey: TOfferFilterField = "sortBy";
  const sortBy = params.get(sortByKey)?.split(",");
  if (!sortBy) return defaultSortByOptions;

  const sortByOptions = sortBy.map<TSortByOption>(sortByString => {
    const [key, order] = sortByString.split("-");
    return {
      key,
      order,
    } as TSortByOption;
  });

  return sortByOptions;
};
