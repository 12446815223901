import { EditOutlined } from "@ant-design/icons";
import { Button, Collapse, Input, Menu, Popover } from "antd";

import { connect } from "react-redux";

import { setMenuFilter } from "utils/helpers";

import {
  offerTypeVariables,
  internalOfferTypeVariables,
} from "../../../shared/constants/dataManagement";

import { FeedType } from "shared/types/configuration";
import { FC } from "react";
import { AppState } from "redux/store";
import { User } from "redux/auth/auth.slice";

interface IPrimarySecondaryMessages {
  loggedInUser?: User;
  caretIndexRef: any;
  primaryText: string;
  secondaryText: string;
  showPrimaryVariablesDropdown: boolean;
  showSecondaryVariablesDropdown: boolean;

  togglePrimaryVariablesDropdown: (bool: boolean) => void;
  toggleSecondaryVariablesDropdown: (bool: boolean) => void;
  setPrimaryText: (text: string) => void;
  setSecondaryText: (text: string) => void;
  currMenuFilter: string;
  setCurrMenuFilter: (currMenuFilter: string) => void;
  feed: FeedType;
}

const PrimarySecondaryMessages: FC<IPrimarySecondaryMessages> = ({
  caretIndexRef,
  primaryText,
  secondaryText,
  showPrimaryVariablesDropdown,
  showSecondaryVariablesDropdown,

  togglePrimaryVariablesDropdown,
  toggleSecondaryVariablesDropdown,
  setPrimaryText,
  setSecondaryText,

  currMenuFilter,
  setCurrMenuFilter,
  feed,
}) => {
  const offerTypeVariablesToUse =
    feed === "internal" ? internalOfferTypeVariables : offerTypeVariables;

  const vehicleInfoVars =
    offerTypeVariablesToUse.find(el => el.offerType === "Vehicle Info")
      ?.variables || [];

  const PopupContentData: FC<{
    setMessage: (message: string) => void;
    message: string;
  }> = ({ setMessage, message }) => {
    return (
      <Menu
        onClick={item => {
          const { key } = item;

          const keyToInsert = `{${key}}`;

          const { current } = caretIndexRef;

          if (current === null) {
            return;
          }

          togglePrimaryVariablesDropdown(false);
          toggleSecondaryVariablesDropdown(false);

          const firstPart = message.slice(
            0,
            current - (currMenuFilter.length + 1),
          );
          const secondPart = message.slice(current);

          const replacedText = firstPart.concat(keyToInsert).concat(secondPart);

          setMessage(replacedText);
        }}
        mode="vertical"
        style={{
          maxHeight: "400px",
          overflowY: "scroll",
          width: "500px",
          paddingLeft: "35%",
        }}
      >
        {vehicleInfoVars.map(variable => {
          if (
            !variable.name.toLowerCase().includes(currMenuFilter.toLowerCase())
          ) {
            return null;
          }
          return (
            <Menu.Item key={`${variable.name}`}>
              <span>{variable.name}</span>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  return (
    <div style={{ width: "400px" }}>
      <Collapse
        className="legal-messaging-pm-sm-collapse"
        expandIconPosition={"right"}
        expandIcon={() => {
          return <Button icon={<EditOutlined />} ghost={true} />;
        }}
      >
        <Collapse.Panel key={"primary-message"} header={"Primary Message"}>
          <Popover
            title="Insert Variable"
            content={
              <PopupContentData
                setMessage={setPrimaryText}
                message={primaryText}
              />
            }
            visible={showPrimaryVariablesDropdown}
            placement="bottom"
            onVisibleChange={() => {
              togglePrimaryVariablesDropdown(false);
            }}
          >
            <Input.TextArea
              onFocus={() => {
                setCurrMenuFilter("");
              }}
              value={primaryText}
              placeholder="Please Type"
              autoSize={{ minRows: 4, maxRows: 6 }}
              onChange={(event: any) => {
                const { target } = event;
                const { value } = target || {
                  value: undefined,
                };

                const numberOfCurliesFromVal = value.split("{").length;
                const numberOfCurliesFromState = primaryText.split("{").length;

                setPrimaryText(value);

                const numLeftCurlies = value.split("{").length - 1;
                const numRightCurlies = value.split("}").length - 1;

                setMenuFilter(
                  numLeftCurlies,
                  numRightCurlies,
                  caretIndexRef,
                  target.selectionStart,
                  event.target.value,
                  primaryText,
                  currMenuFilter,
                  setCurrMenuFilter,
                );

                if (numberOfCurliesFromVal > numberOfCurliesFromState) {
                  caretIndexRef.current = target.selectionStart;

                  togglePrimaryVariablesDropdown(
                    numberOfCurliesFromVal > numberOfCurliesFromState,
                  );
                }
              }}
            />
          </Popover>
        </Collapse.Panel>
      </Collapse>

      <Collapse
        className="legal-messaging-pm-sm-collapse"
        expandIconPosition={"right"}
        expandIcon={() => {
          return <Button icon={<EditOutlined />} ghost={true} />;
        }}
      >
        <Collapse.Panel key={"secondary-message"} header={"Secondary Message"}>
          <Popover
            title="Insert Variable"
            content={
              <PopupContentData
                setMessage={setSecondaryText}
                message={secondaryText}
              />
            }
            visible={showSecondaryVariablesDropdown}
            placement="top"
            onVisibleChange={() => {
              toggleSecondaryVariablesDropdown(false);
            }}
          >
            <Input.TextArea
              onFocus={() => {
                setCurrMenuFilter("");
              }}
              value={secondaryText}
              placeholder="Please Type"
              autoSize={{ minRows: 4, maxRows: 6 }}
              onChange={(event: any) => {
                const { target } = event;
                const { value } = target || {
                  value: undefined,
                };

                const numberOfCurliesFromVal = value.split("{").length;
                const numberOfCurliesFromState =
                  secondaryText.split("{").length;

                setSecondaryText(value);

                const numLeftCurlies = value.split("{").length - 1;
                const numRightCurlies = value.split("}").length - 1;

                setMenuFilter(
                  numLeftCurlies,
                  numRightCurlies,
                  caretIndexRef,
                  target.selectionStart,
                  event.target.value,
                  secondaryText,
                  currMenuFilter,
                  setCurrMenuFilter,
                );

                if (numberOfCurliesFromVal > numberOfCurliesFromState) {
                  caretIndexRef.current = target.selectionStart;

                  toggleSecondaryVariablesDropdown(
                    numberOfCurliesFromVal > numberOfCurliesFromState,
                  );
                }
              }}
            />
          </Popover>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  const { configuration, auth } = state;
  const { feed } = configuration;
  return {
    loggedInUser: auth.user,
    feed,
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrimarySecondaryMessages);
