import { Drawer, Space, Button, Typography, Form, Input, Col, Row } from "antd";
import { IAccount } from "shared/types/accountManagement";
import { GenericLocationSelect } from "shared/components/select/GenericLocationSelect";
import { useAccountLocationForm } from "./accountLocationDrawer/useAccountLocationForm";

type AccountLocationDrawerProps = {
  isOpen: boolean;
  close: () => void;
  onSuccess: () => void;
  account?: IAccount;
};

export const AccountLocationDrawer = ({
  account,
  isOpen,
  close,
  onSuccess,
}: AccountLocationDrawerProps) => {
  const { form, schemaRules, submit, isUpdating } = useAccountLocationForm();

  if (!account) return null;

  return (
    <Drawer
      title={<Typography>Edit Account - {account.dealer_name}</Typography>}
      destroyOnClose
      visible={isOpen}
      closable={false}
      maskClosable={false}
      width={"40%"}
      footer={
        <Space>
          <Button onClick={close}>Cancel</Button>
          <Button
            type="primary"
            onClick={() => submit(account, onSuccess)}
            loading={isUpdating}
            disabled={isUpdating}
          >
            Save
          </Button>
        </Space>
      }
    >
      <Form
        layout="vertical"
        form={form}
        preserve={false}
        initialValues={account}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="address"
              label="Enter an Address"
              rules={[schemaRules]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="city" label="Enter a City" rules={[schemaRules]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="zip"
              label="Enter a Zip Code"
              rules={[schemaRules]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="state"
              label="Select Location"
              shouldUpdate
              rules={[schemaRules]}
            >
              <GenericLocationSelect
                defaultValue={account.state}
                value={account.state}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};
