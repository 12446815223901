import { Button, Popover, Row, Typography } from "antd";
import { OperationMode } from "shared/types/inputValues";
import styles from "./DetailsTab.module.scss";
import { IAccountRecord } from "shared/types/accountManagement";
import { CopyOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useIsAdmin } from "shared/hooks/useIsAdmin";

interface CodeSnippetProps {
  mode: OperationMode;
  accountToEdit: IAccountRecord;
}
export const CodeSnippet = ({ mode, accountToEdit }: CodeSnippetProps) => {
  const [copiedBool, setCopiedBool] = useState<boolean>(false);

  const isAdmin = useIsAdmin();

  let dealerUrl = accountToEdit.dealerUrl;
  if (
    accountToEdit.dealerUrl.startsWith("https://") ||
    accountToEdit.dealerUrl.startsWith("http://")
  ) {
    dealerUrl = accountToEdit.dealerUrl.split("://")[1];
  }
  const widgetURL = `<script type="text/javascript" src="${process.env.REACT_APP_DOMAIN}/${process.env.REACT_APP_ENV}-assetbuilder/v1/asset-builder/web-integration-code?website=${dealerUrl}&id=default"></script>`;
  const rows = Math.ceil(widgetURL.length / 50); // assuming 50 characters per row

  return (
    <>
      <Row>
        <Typography.Text className={styles.title}>Code Snippet</Typography.Text>
      </Row>
      {mode === "CREATE" && (
        <>
          <Typography.Paragraph className={styles.paragraph}>
            Code Snippet will appear upon creation.
          </Typography.Paragraph>
        </>
      )}
      {mode === "UPDATE" && (
        <div className={styles.paddingContainer}>
          <Typography.Paragraph
            className={styles.codeSnippetDescription}
          >{`Copy the code below and paste it onto every page of your website. Paste this code as high in the <head> of the page as possible:`}</Typography.Paragraph>
          <div>
            <textarea
              className={`widget-url ${styles.textAreaContainer}`}
              disabled={true}
              value={!isAdmin ? "" : widgetURL}
              rows={rows}
            />
          </div>
          <div>
            <Popover content={copiedBool ? "Copied!" : "Copy To Clipboard"}>
              <Button
                key="COPY"
                icon={<CopyOutlined />}
                disabled={!isAdmin}
                onClick={() => {
                  navigator?.clipboard?.writeText(widgetURL);
                  setCopiedBool(true);
                }}
              ></Button>
            </Popover>
          </div>
        </div>
      )}
    </>
  );
};
