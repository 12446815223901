import { createContext, SetStateAction, useContext, ReactNode } from "react";
import { IAdToLoadData } from "shared/types/adLibrary";
import { OnRowDataChangeArgs } from "../../shared/types";

type CurrentAdsToLoadProps = {
  handleDataRowChange?: (args: OnRowDataChangeArgs) => void;
  currentAdsToLoad: IAdToLoadData[];
  setCurrentAdsToLoad: (value: SetStateAction<IAdToLoadData[]>) => void;
};

const CurrentAdsToLoadContext = createContext<
  CurrentAdsToLoadProps | undefined
>(undefined);

type Props = {
  children: ReactNode;
  value: CurrentAdsToLoadProps;
};

export const CurrentAdsToLoadProvider = (props: Props) => {
  return <CurrentAdsToLoadContext.Provider {...props} />;
};

export const useCurrentAdsToLoad = () => {
  const currentAdsToLoadContext = useContext(CurrentAdsToLoadContext);
  if (!currentAdsToLoadContext) {
    throw new Error(
      "useCurrentAdsToLoad must be used within an CurrentAdsToLoadProvider",
    );
  }
  return currentAdsToLoadContext;
};
