import Typography from "antd/lib/typography";
import { useAppSelector } from "shared/hooks/useAppSelector";
import MultipleStoreSelect from "shared/components/MultipleAccountSelect";
import { notification } from "antd";
import { setSelectedFacebookAccounts } from "redux/adLibrary/adLibrary.slice";
import { useDispatch } from "react-redux";
import { IAccount } from "shared/types/accountManagement";
import { IFacebookAccount } from "screens/adLibrary/facebookUtils/types";
import { returnDealersWithDuplicateAccountIds } from "../../shared/utils";
import { terms } from "utils/terms";

const hasDuplicatedFBAdAccountIds = (stores: IAccount[]) => {
  const foundDuplicateIds = returnDealersWithDuplicateAccountIds(stores);
  return foundDuplicateIds.length > 0;
};

const hasStoresWithoutId = (stores: IAccount[]) => {
  const dealersWithNoFacebookId = stores
    .filter(dealer => !dealer.details?.facebook.fbAccountId)
    .map(dealer => dealer.dealer_name);

  return dealersWithNoFacebookId.length > 0;
};

const AccountSelect = () => {
  const dispatch = useDispatch();

  const selectedAccounts = useAppSelector(
    ({ adLibrary }) => adLibrary.selectedFacebookAccounts ?? [],
  );

  const onStoresChange = (
    selectedStoreNames: string[],
    dealers: IAccount[],
  ) => {
    const foundStores = selectedStoreNames
      .map(dealerName =>
        dealers?.find(dealer => dealer.dealer_name === dealerName),
      )
      .filter(dealer => !!dealer) as IAccount[];

    if (hasDuplicatedFBAdAccountIds(foundStores)) {
      notification.warning({
        message: "Could not selected store",
        description: "Duplicate Facebook account IDs were found.",
      });
      return;
    }

    if (hasStoresWithoutId(foundStores)) {
      notification.warning({
        message: "Could not selected store",
        description: `The chosen ${terms.account} does not have Facebook Account ID`,
      });
      return;
    }

    updateFacebookAccounts(foundStores);
  };

  const updateFacebookAccounts = (stores: IAccount[]) => {
    const updatedFacebookAccounts: IFacebookAccount[] = stores.map(dealer => ({
      account_status: 1,
      name: dealer.dealer_name,
      account_id: dealer.details!.facebook.fbAccountId,
      id: dealer.details!.facebook.fbAccountId.split("act_")[1],
    }));

    dispatch(setSelectedFacebookAccounts(updatedFacebookAccounts));
  };

  return (
    <div>
      <Typography.Text>{terms.accountName}</Typography.Text>
      <MultipleStoreSelect
        autoFocus
        style={{ width: "100%" }}
        selectedDealers={selectedAccounts?.map(account => account.name)}
        onChange={onStoresChange}
        filters={{
          enabled: true,
        }}
      />
    </div>
  );
};

export default AccountSelect;
