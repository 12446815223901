const PlayIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={64}
      height={64}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={32}
        cy={32}
        r={31}
        fill="#000"
        fillOpacity={0.4}
        stroke="#fff"
        strokeWidth={2}
      />
      <path d="M49 31.5L22.75 48.388V14.611L49 31.5z" fill="#fff" />
    </svg>
  );
};

export default PlayIcon;
