import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { Button, Checkbox, Collapse, Select, Tag, Input } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  IAssetBuilderState,
  SingletonOfferKeys,
  Tab,
  TOfferFilterField,
  TVehicleCondition,
} from "shared/types/assetBuilder";

import "../OfferFilter.scss";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useNavigate, useLocation } from "react-router-dom";
import { OfferType } from "shared/types/shared";
import { isEmpty } from "lodash";
import { isVehicleCondition } from "./OfferFilter.utils";
import { FeedType } from "shared/types/configuration";
import { isAuto, isPharma } from "utils/helpers";
import PharmaFilter from "./PharmaFilter";

type TOfferFilter = {
  vehicleCondition: TVehicleCondition;
  offerTypes: OfferType[];
  sortBy: TSortByOption[];
};

export type TSortByOption = {
  key: TSortByKey;
  order: "asc" | "desc";
};

export type TSortByKey = SingletonOfferKeys | "updated";

const getSortByKeys = (feed: FeedType) => {
  if (!isAuto) return [];

  return [
    "msrp",
    feed === "ladtech" ? "monthlyPayment" : "leaseMonthlyPayment",
    "financePayment",
    "dateInStock",
    "savingsOffMSRP",
    "updated",
    "finalPrice",
    "aprRate",
  ] as TSortByKey[];
};

const getDefaultSortBy = () => {
  if (!isAuto) return [];

  return [
    {
      key: "msrp",
      order: "asc",
    },
  ] as TSortByOption[];
};
const sortByTitleMap: { [key in TSortByKey]?: string } = {
  msrp: "MSRP",
  monthlyPayment: "Lease Payment",
  financePayment: "Finance Payment",
  dateInStock: "Days In Stock",
  savingsOffMSRP: "Total Savings Off MSRP",
  updated: "Last Updated",
  finalPrice: "Final Price",
  aprRate: "APR Rate",
};

interface Props {
  feed: FeedType;
  tab?: Tab;
  onFilterFieldChange: (value: string) => void;
  savedOrder: IAssetBuilderState["savedOrder"];
}

const OfferFilter = (props: Props) => {
  const ulStyle = {
    listStyleType: "none",
    paddingLeft: "0",
  };

  const selectableOfferTypes = useMemo<OfferType[]>(
    () => [
      OfferType.Lease,
      OfferType.ZeroDownLease,
      OfferType.Finance,
      OfferType.Purchase,
      OfferType.APR,
    ],
    [],
  );
  const offerTypeFilterOptions: Array<{ title: string; offerType: OfferType }> =
    selectableOfferTypes.map(offerType => {
      let title = "-";
      switch (offerType) {
        case OfferType.Lease:
          title = "Lease";
          break;
        case OfferType.ZeroDownLease:
          title = "Zero Down Lease";
          break;
        case OfferType.Finance:
          title = "Finance";
          break;
        case OfferType.Purchase:
          title = "Purchase";
          break;
        case OfferType.APR:
          title = "APR";
          break;
      }

      return {
        title,
        offerType,
      };
    });

  const sortByKeys: TSortByKey[] = getSortByKeys(props.feed);

  const initFilter = useMemo<TOfferFilter>(
    () => ({
      vehicleCondition: "All",
      offerTypes: [],
      sortBy: getDefaultSortBy(),
    }),
    [],
  );
  const [filter, setFilter] = useState<TOfferFilter>(initFilter);

  const navigate = useNavigate();
  const location = useLocation();

  const toSearchParams = useCallback((filter: TOfferFilter) => {
    const urlParams = new URLSearchParams();
    if (filter.vehicleCondition) {
      urlParams.append("vehicleCondition", filter.vehicleCondition);
    }

    if (!isEmpty(filter.offerTypes)) {
      urlParams.append("offerTypes", filter.offerTypes.join(","));
    }

    if (!isEmpty(filter.sortBy)) {
      const sortByQueryString = filter.sortBy
        .map(sortBy => `${sortBy.key}-${sortBy.order}`)
        .join(",");
      urlParams.append("sortBy", sortByQueryString);
    }

    return urlParams;
  }, []);
  useEffect(() => {
    if (!!location.search) return;

    const params = toSearchParams(initFilter);
    // need to replace because we don't want to add to history so that back button works
    navigate({ search: params.toString() }, { replace: true });
  }, [navigate, initFilter, location.search, toSearchParams]);

  // to update filter based on url search params
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let updatedFilter: TOfferFilter = initFilter;

    const vehicleCondition = params.get(
      "vehicleCondition",
    ) as TVehicleCondition;

    if (isVehicleCondition(vehicleCondition)) {
      updatedFilter = {
        ...(updatedFilter || {}),
        vehicleCondition,
      };
    }
    const offerTypesString = params.get("offerTypes");
    if (offerTypesString) {
      const offerTypes = offerTypesString.split(",") as OfferType[];
      updatedFilter = {
        ...updatedFilter,
        offerTypes,
      };
    } else {
      updatedFilter = {
        ...updatedFilter,
        offerTypes: selectableOfferTypes,
      };
    }

    const sortBy = params.get("sortBy");
    if (sortBy) {
      updatedFilter = {
        ...updatedFilter,
        sortBy: sortBy.split(",").map(sortByQueryString => {
          const queries = sortByQueryString.split("-");
          const invalid = queries.length !== 2;
          if (invalid) return null;

          return {
            key: queries[0],
            order: queries[1],
          };
        }) as TSortByOption[],
      };
    } else {
      updatedFilter = {
        ...updatedFilter,
        sortBy: [],
      };
    }

    setFilter(updatedFilter);
  }, [initFilter, location.search, selectableOfferTypes]);

  // NOTE: This func is used when field value changes.
  // And this will update the url query string which will
  //  trigger the useEffect above which is responsible for updating filter based on the query string.
  const onFieldChange = useCallback(
    (
        field: TOfferFilterField,
        fieldValue: TVehicleCondition | OfferType | TSortByOption[],
      ) =>
      async (e: any) => {
        let updatedFilter = { ...filter };
        switch (field) {
          case "vehicleCondition":
            updatedFilter = {
              ...updatedFilter,

              vehicleCondition: fieldValue as TVehicleCondition,
            };
            break;

          case "offerTypes":
            const offerTypes = e.target.checked
              ? Array.from(
                  new Set([
                    ...updatedFilter.offerTypes,
                    fieldValue as OfferType,
                  ]),
                )
              : updatedFilter.offerTypes.filter(
                  offerType => offerType !== fieldValue,
                );
            updatedFilter = {
              ...updatedFilter,

              offerTypes,
            };
            break;

          case "sortBy":
            updatedFilter = {
              ...updatedFilter,
              sortBy: fieldValue as TSortByOption[],
            };
            break;
        }

        const params = toSearchParams(updatedFilter);
        // check if search params from location and filter are same.

        navigate({ search: params.toString() });

        return field;
      },
    [filter, navigate, toSearchParams],
  );

  const reorder = useCallback(
    (list: TSortByOption[], startIndex: number, endIndex: number) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    },
    [],
  );

  const { tab } = props;
  const { filterField } = tab || {};
  const isKnownFilterField =
    !filterField || filterField === "make" || filterField === "dealerId";

  const searchTimeoutRef = useRef<NodeJS.Timeout>();
  const onFilterFieldChange = (value: string) => {
    if (searchTimeoutRef.current) clearTimeout(searchTimeoutRef.current);

    searchTimeoutRef.current = setTimeout(() => {
      props.onFilterFieldChange(`${tab?.id}_${tab?.filterField}-${value}`);
    }, 800);
  };

  return (
    <Collapse
      className="filter-collapse-container"
      defaultActiveKey={["filter-panel"]}
      bordered={false}
      expandIconPosition="right"
    >
      <Collapse.Panel
        key="filter-panel"
        className="filter-section-container-panel filter-offer-container"
        header="Filter Offers"
      >
        {!isPharma && (
          <div className="filter-field-container">
            Filter Field
            <div>
              {isKnownFilterField ? (
                <Tag className="known-filter-field">{filterField}</Tag>
              ) : (
                <div className="unknown-filter-field">
                  <Tag className="unknown-field">{filterField}:</Tag>
                  <Input.Search
                    placeholder="Filter value"
                    allowClear={true}
                    onChange={e => onFilterFieldChange(e.target.value)}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {isPharma && (
          <PharmaFilter
            brandProduct={props.savedOrder?.selectedOrder.dealer_name ?? ""}
          />
        )}
        {!isPharma && (
          <div className="vehicle-condition-filter">
            Vehicle Condition
            <Button.Group className="filter-vehicle-condition-button-group">
              {(["New", "Used", "CPO", "All"] as TVehicleCondition[]).map(
                condition => (
                  <Button
                    key={`vehicle-condition-button-${condition}`}
                    type={
                      filter.vehicleCondition === condition
                        ? "primary"
                        : "default"
                    }
                    onClick={onFieldChange("vehicleCondition", condition)}
                  >
                    {condition}
                  </Button>
                ),
              )}
            </Button.Group>
          </div>
        )}

        <div className="filter-section-option-container">
          {!isPharma && (
            <div className="by-offer-type">
              <div className="label">Offer Type</div>
              <div className="filter-options">
                <ul style={ulStyle}>
                  {offerTypeFilterOptions.map(filterOption => (
                    <li
                      key={`offer-type-filter-option-key-${filterOption.title}`}
                    >
                      <Checkbox
                        checked={filter.offerTypes.includes(
                          filterOption.offerType,
                        )}
                        onChange={onFieldChange(
                          "offerTypes",
                          filterOption.offerType,
                        )}
                      >
                        {filterOption.title}
                      </Checkbox>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          <div className="sort-by">
            <div className="label">Sort By</div>
            <div className="sort-options">
              <DragDropContext
                onDragEnd={result => {
                  const { source, destination } = result;
                  if (isEmpty(filter.sortBy) || !source || !destination) return;

                  const updated = reorder(
                    filter.sortBy,
                    source.index,
                    destination.index,
                  );

                  onFieldChange("sortBy", updated)(null); // NOTE: onFieldChange func call returns another func. So we need to call it.
                }}
              >
                <Select<TSortByKey>
                  placeholder={"Select Sorting Options"}
                  style={{ width: 200 }}
                  onSelect={sortKey => {
                    const alreadyExist = filter.sortBy?.some(
                      option => option.key === sortKey,
                    );
                    if (alreadyExist) return;

                    const updated: TSortByOption[] = [
                      ...filter.sortBy,
                      {
                        key: sortKey,
                        order: "desc",
                      },
                    ];

                    onFieldChange("sortBy", updated)(null); // NOTE: onFieldChange func call returns another func. So we need to call it.
                  }}
                >
                  {sortByKeys.map(key => {
                    return (
                      <Select.Option value={`${key}`} key={`${key}`}>
                        {sortByTitleMap[key]}
                      </Select.Option>
                    );
                  })}
                </Select>
                <Droppable droppableId="droppable">
                  {provided => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {filter.sortBy?.map(
                        (item: TSortByOption, index: number) => (
                          <Draggable
                            key={item.key}
                            draggableId={item.key}
                            index={index}
                          >
                            {provided => (
                              <div
                                className={`sort-option-box ${item.key}`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Tag
                                  className="offer-filter-sort-by-tag"
                                  closable={true}
                                  onClose={() => {
                                    const updated = filter.sortBy.filter(
                                      (option: TSortByOption) => {
                                        return option.key !== item.key;
                                      },
                                    );
                                    onFieldChange("sortBy", updated)(null);
                                  }}
                                >
                                  <span className="sort-by-wrapper">
                                    <span>{sortByTitleMap[item.key]}</span>
                                    <span>
                                      {item.order === "desc" && (
                                        <CaretDownOutlined
                                          onClick={onFieldChange(
                                            "sortBy",
                                            filter.sortBy.map(option =>
                                              option.key === item.key
                                                ? {
                                                    ...option,
                                                    order:
                                                      option.key === item.key
                                                        ? "asc"
                                                        : "desc",
                                                  }
                                                : option,
                                            ),
                                          )}
                                        />
                                      )}
                                      {item.order === "asc" && (
                                        <CaretUpOutlined
                                          onClick={onFieldChange(
                                            "sortBy",
                                            filter.sortBy.map(option => ({
                                              ...option,
                                              order:
                                                option.key === item.key
                                                  ? "desc"
                                                  : "asc",
                                            })),
                                          )}
                                        />
                                      )}
                                    </span>
                                  </span>
                                </Tag>
                              </div>
                            )}
                          </Draggable>
                        ),
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};

export default OfferFilter;
