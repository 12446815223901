import { Modal } from "antd";
import styles from "./HtmlTemplatePreview.module.scss";
import { CloseOutlined } from "@ant-design/icons";

type HtmlTemplatePreviewProps = {
  previewUrl: string;
  onPreviewClose: () => void;
};
export const HtmlTemplatePreview = ({
  previewUrl,
  onPreviewClose,
}: HtmlTemplatePreviewProps) => {
  return (
    <Modal
      className={styles.previewModal}
      visible={!!previewUrl}
      onCancel={onPreviewClose}
      title={<span className={styles.title}>PREVIEW</span>}
      width={"80%"}
      bodyStyle={{ height: "100%", padding: 0, background: "#2d2f30" }}
      closeIcon={<CloseOutlined className={styles.closeIcon} />}
      footer={null}
    >
      <iframe
        title="HTML Template Preview"
        src={previewUrl}
        className={styles.previewIframe}
        name="html-preview"
      />
    </Modal>
  );
};
