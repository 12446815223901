/* eslint-disable react/display-name */
import { useMemo } from "react";
import { TableColumnProps, Tooltip, Select } from "antd";

import DestinationUrl from "./columns/DestinationUrl";
import EllipsisText from "shared/components/card/EllipsisText";
import EditableAdToLoadUrlText from "./EditableAdToLoadUrlText";
import LegacyProductSetSelect from "shared/components/select/LegacyProductSetSelect";
import NameCardCell from "screens/adLibrary/shared/components/NameCardCell";
import FacebookAssetSelect from "screens/adLibrary/shared/FacebookAssetSelect";

import useGetRequiredData from "../../hooks/reviewStep/useGetRequiredData";

import {
  findIEByName,
  returnIENameFilterOptions,
} from "../../../reviewStep/helpers.ie";
import {
  adLoadStatusAfterUrlChange,
  returnAdsToLoadTableColumnPropsDict,
} from "./utils";
import { compareString } from "utils/helpers";
import { canUseUrlField, returnIsProductSelectionDisabled } from "../../utils";

import { IAdToLoadData } from "shared/types/adLibrary";
import {
  IAdsToLoadTableColumnsProps,
  AdsToLoadTableColumnsHandlers,
} from "../../types";
import { FilterFilled } from "@ant-design/icons";
import { AdType, CallToAction } from "screens/adLibrary/facebookUtils/types";

type Props = IAdsToLoadTableColumnsProps & AdsToLoadTableColumnsHandlers;
const callToActionValues = [
  { key: 0, value: CallToAction.LEARN_MORE, text: "Learn More" },
  { key: 1, value: CallToAction.SHOP_NOW, text: "Shop Now" },
  { key: 2, value: CallToAction.GET_OFFER, text: "Get Offer" },
  { key: 3, value: CallToAction.CONTACT_US, text: "Contact Us" },
  { key: 4, value: CallToAction.GET_QUOTE, text: "Get Quote" },
];

const Columns = ({
  ignoreStatuses,
  adsToLoad = [],
  productCatalogIds,
  instantExperiences = [],
  onMultiUrlClick,
  onRowDataChange,
  willHideThumbnails,
}: Props): Array<TableColumnProps<IAdToLoadData>> => {
  const { productCatalogs, selectedStores } = useGetRequiredData();

  const columnPropsDict = useMemo(
    () => returnAdsToLoadTableColumnPropsDict(adsToLoad, ignoreStatuses),
    [adsToLoad, ignoreStatuses],
  );
  const columns: Array<TableColumnProps<IAdToLoadData>> = [
    columnPropsDict.accountName,
    columnPropsDict.accountId,
    columnPropsDict.facebookPageName,
    columnPropsDict.facebookPageId,
    {
      ...columnPropsDict.campaignName,
      render: campaignName =>
        EllipsisText({ text: campaignName, charDisplayLimit: 60 }),
    },
    columnPropsDict.objective,
    {
      ...columnPropsDict.adsetName,
      render: adsetName =>
        EllipsisText({ text: adsetName, charDisplayLimit: 60 }),
    },
    {
      ...columnPropsDict.adName,
      render: (name, adToLoad) => (
        <NameCardCell
          name={name}
          ad={adToLoad.ad}
          willDefaultPreview
          textLengthToEllipsis={70}
          willHideImage={
            willHideThumbnails && adToLoad.ad.type === AdType.Carousel
          }
        />
      ),
    },
    columnPropsDict.type,
    {
      ...columnPropsDict.rootIEName,
      filters: returnIENameFilterOptions(adsToLoad, instantExperiences),
      onFilter: (value, record) =>
        !!findIEByName({ record, ieName: value as string, instantExperiences }),
      sorter: (a, b) => {
        const ieA = (instantExperiences ?? []).find(
          ie => ie.id === a.ad.visuals.destination?.instantExperienceId,
        );
        const ieB = (instantExperiences ?? []).find(
          ie => ie.id === b.ad.visuals.destination?.instantExperienceId,
        );
        return compareString(ieA?.name, ieB?.name);
      },
      render: (value: string) => {
        const foundIE = instantExperiences?.find(ie => ie.id === value);
        return foundIE?.name || value;
      },
    },
    {
      ...columnPropsDict.destinationUrl,
      render: (destinationUrl: string, adToLoad) => (
        <DestinationUrl
          adToLoad={adToLoad}
          destinationUrl={destinationUrl}
          onMultiUrlClick={onMultiUrlClick}
          onRowDataChange={onRowDataChange}
        />
      ),
    },
    {
      ...columnPropsDict.displayUrl,
      render: (displayUrl, adToLoad) => (
        <EditableAdToLoadUrlText
          showInputOnly
          linkValue={displayUrl}
          selectedStore={selectedStores?.find(
            dealer =>
              dealer.details?.facebook.fbAccountId ===
              adToLoad.account.account_id,
          )}
          disabled={
            !canUseUrlField(adToLoad.ad.type) ||
            adToLoad.ad.type === AdType.Carousel
          }
          onChange={value => {
            onRowDataChange?.({
              data: {
                ...adToLoad,
                adLoadStatus: adLoadStatusAfterUrlChange(adToLoad.adLoadStatus),
                ad: {
                  ...adToLoad.ad,
                  visuals: { ...adToLoad.ad.visuals, displayUrl: value },
                },
              },
              column: "Display URL",
            });
          }}
        />
      ),
    },
    {
      ...columnPropsDict.productCatalog,
      render: (productCatalogId, adToLoad) => {
        const disabled = returnIsProductSelectionDisabled(
          adToLoad,
          instantExperiences,
        );
        return (
          <FacebookAssetSelect
            title={"catalog"}
            style={{ width: 260 }}
            disabled={disabled}
            initialValue={productCatalogId}
            value={productCatalogId}
            preLoadedData={productCatalogs}
            selectedStore={selectedStores.find(
              dealer =>
                dealer.details.facebook.fbAccountId ===
                adToLoad.account.account_id,
            )}
            selectedAccount={adToLoad.account}
            setFacebookIntegration={() => {
              return;
            }}
            onSelect={selected => {
              onRowDataChange?.({
                data: {
                  ...adToLoad,
                  productCatalog: selected ?? undefined,
                  productSet: undefined,
                },
                willRecheckAd: true,
              });
            }}
          />
        );
      },
    },
    {
      ...columnPropsDict.productSet,
      render: (productSetId, adToLoad) => {
        const disabled = returnIsProductSelectionDisabled(
          adToLoad,
          instantExperiences,
        );
        return (
          <LegacyProductSetSelect
            disabled={disabled}
            style={{ width: 175 }}
            productCatalogIds={productCatalogIds}
            productCatalogId={
              !disabled && adToLoad.productCatalog?.id
                ? adToLoad.productCatalog?.id
                : ""
            }
            productSetId={!disabled ? productSetId : undefined}
            onSelect={(selectedProductSetId, option, productSet) => {
              onRowDataChange?.({
                data: {
                  ...adToLoad,
                  productSet: {
                    id: selectedProductSetId,
                    name: (option?.children as string) ?? "",
                    product_count: productSet?.product_count,
                  },
                },
                column: "Product Set",
                willRecheckAd: true,
              });
            }}
          />
        );
      },
    },
    {
      ...columnPropsDict.ctaButtonText,
      render: (ctaButtonText, adToLoad) => {
        return (
          <Select
            style={{ width: 175 }}
            value={ctaButtonText}
            disabled={
              adToLoad.ad.type === AdType.Collection ||
              adToLoad.ad.type === AdType.InstantExperience
            }
            onSelect={ctaButtonText => {
              onRowDataChange?.({
                data: {
                  ...adToLoad,
                  ad: {
                    ...adToLoad.ad,
                    visuals: {
                      ...adToLoad.ad.visuals,
                      ctaButtonText: ctaButtonText as any,
                      cards: adToLoad.ad.visuals.cards?.map(card => ({
                        ...card,
                        ctaButtonText: ctaButtonText as any,
                      })),
                    },
                  },
                },
                column: "CTA Button",
                willRecheckAd: true,
              });
            }}
          >
            {callToActionValues.map(({ key, text, value }) => (
              <Select.Option key={key} value={value}>
                {text}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
  ];

  const addFilterIconWithTooltip = (
    column: TableColumnProps<IAdToLoadData>,
  ) => ({
    ...column,
    filterIcon: column.filters ? (
      <Tooltip title={`Filter by ${column.title}`}>
        <FilterFilled />
      </Tooltip>
    ) : undefined,
  });

  return columns.map(addFilterIconWithTooltip);
};

export default Columns;
