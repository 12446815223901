import { useGetFormattedFeeds } from "shared/hooks/assetExporter/useGetFormattedFeeds";
import { UseGetTabData } from "shared/hooks/assetBuilder/useGetTabData";
import { useDealerData } from "./useDealerData";
import { useRandomOfferSearch } from "./useRandomOfferSearch";
import { IStamp, ITemplate } from "shared/types/designStudio";
import { IPreviewSelectedOptions } from "shared/types/assetBuilder";
import { IAccount } from "shared/types/accountManagement";

export const useGetOfferTotal = (
  dealers: IAccount[],
  template: ITemplate | null,
  stamp: IStamp | null,
  preSelectedOptions?: IPreviewSelectedOptions,
) => {
  const { chosenOem, chosenCondition, dealerCodeToUse } = useDealerData(
    dealers,
    { template: template ?? null, stamp: stamp ?? null },
    preSelectedOptions,
  );

  const { feeds } = useGetFormattedFeeds();

  const defaultFeed = feeds.find(feed => feed.feedName.includes("Default"));
  const feedId = defaultFeed?.feedId || "";

  const params: UseGetTabData = {
    feedId: feedId ?? "",
    dealerCode: dealerCodeToUse ?? "",
    dealerOem: chosenOem ?? "",
    filterBy: chosenCondition ?? "New",
    sortingOptions: [],
    searchBy: "",
    filterField: "dealerId",
    filterFieldSearch: "",
  };

  const { data: previewOfferTotal } = useRandomOfferSearch(params);

  return { params, feedId, previewOfferTotal };
};
