import { IConfig } from "shared/types/configuration";
import HttpClient from "./httpClient";

export default ({ config }: { headers: any; config: IConfig }) => ({
  deleteBrandsAccounts: <T>(
    brandNames: string[],
    accountNames: string[],
    isUndoOperation: boolean,
  ) =>
    HttpClient.put<T>(config.services.deleteBrandsAccountsUrl, {
      brandNames,
      accountNames,
      isUndoOperation,
    }),
});
