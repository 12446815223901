import { Col, Row } from "antd";
import { DataListProvider } from "shared/components/dataList";
import { LogoSelectDrawer } from "shared/components/logoSelect/LogoSelectDrawer";
import { LogoSelectProvider } from "shared/components/logoSelect/context/LogoSelectContext";
import { useLogoSelectDataListConfig } from "shared/components/logoSelect/hooks/useLogoSelectDataListConfig";
import commonStyles from "../shared/components/CommonDrawer.module.scss";
import styles from "./ManageMedia.module.scss";
import { PanelTemplate } from "./dynamicText/PanelTemplate";
import { PanelMediaVariables } from "./manageMedia/PanelMediaVariables";

export const ManageMedia = () => {
  const { dataListConfig } = useLogoSelectDataListConfig();

  return (
    <Row className={commonStyles.body}>
      <Col span={10} className={commonStyles.bodyLeft}>
        <DataListProvider config={dataListConfig}>
          <LogoSelectProvider>
            <LogoSelectDrawer />
            <PanelMediaVariables />
          </LogoSelectProvider>
        </DataListProvider>
      </Col>
      <Col span={14} className={styles.bodyRight}>
        <PanelTemplate />
      </Col>
    </Row>
  );
};
