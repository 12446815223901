import { MediaBreadCrumb } from "./MediaBreadCrumb";
import { MediaFilterTags } from "./MediaFilterTags";
import { MediaToolbar } from "./MediaToolbar";

import styles from "./MediaHeader.module.scss";

export const MediaHeader = () => {
  return (
    <div className={styles.host}>
      <MediaToolbar />
      <MediaFilterTags />
      <MediaBreadCrumb />
    </div>
  );
};
