export const defaultResizeDimensions = [
  {
    width: 1080,
    height: 1080,
    label: "Social",
    selected: false,
    id: 0,
  },
  {
    width: 1400,
    height: 250,
    label: "Website",
    selected: false,
    id: 1,
  },
  {
    width: 160,
    height: 600,
    label: "Display",
    selected: false,
    id: 2,
  },
  {
    width: 600,
    height: 380,
    label: "Email",
    selected: false,
    id: 3,
  },
];
