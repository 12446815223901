import { useMemo, useState, useEffect, useCallback } from "react";
import { Tree } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";

import CampaignTreeFilter from "./campaignTree/CampaignTreeFilter";

import {
  convertTreeDataToReduxData,
  emptyDataTitle,
  emptyTreeData,
  flattenDataTree,
  mapAdLoadDataToTree,
  returnAdLoadDestinationAfterAdRemoval,
  returnNodeTitleToRender,
} from "./campaignTree/helpers.tree";
import { returnAdLoadDestinationDataOnCheck } from "../shared/utils";

import { Checked, CheckInfo } from "../shared/types";
import { Key } from "antd/lib/table/interface";
import { DataNode, EventDataNode } from "antd/lib/tree";
import {
  IFacebookAccount,
  IFacebookCampaign,
} from "screens/adLibrary/facebookUtils/types";
import {
  AdLoadDestinationData,
  IAd,
  IAdLoad,
  IAdLoadDestination,
} from "shared/types/adLibrary";

import styles from "./CampaignTree.module.scss";

export interface IProps {
  ads: IAd[];
  loading?: boolean;
  disabled?: boolean;
  accounts: IFacebookAccount[];
  campaigns?: IFacebookCampaign[];
  adLoadDestination?: IAdLoadDestination | null;
  updateAdLoadDestinationData?: (
    prop: keyof IAdLoadDestination | null,
    data: AdLoadDestinationData | IAdLoadDestination,
    triggerUpdateProp?: IAdLoad["triggerUpdateProp"],
  ) => void;
}

const CampaignTree = ({
  ads,
  loading,
  disabled,
  accounts,
  campaigns,
  adLoadDestination,
  updateAdLoadDestinationData,
}: IProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [expandedKeys, setExpandedKeys] = useState<Key[]>(
    accounts?.map(account => account.account_id) ?? [],
  );
  const [willFireSave, setWillFireSave] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState<Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [filteredCampaigns, setFilteredCampaigns] = useState<
    IFacebookCampaign[] | null
  >(null);
  const [wasExcludeClicked, setWasExcludeClicked] = useState(false);
  const [currentEventDataNode, setCurrentEventDataNode] =
    useState<EventDataNode | null>(null);

  const {
    adDestinationKeys,
    removedAdDestinationKeys,
    checkedKeys: checkedKeysProp,
    expandedKeys: expandedKeysProp,
  } = adLoadDestination || {};

  // Enable expanding accounts and camaigns by default on load
  useEffect(() => {
    setExpandedKeys(
      (accounts?.map(account => account.account_id) ?? []).concat(
        campaigns?.map(campaign => campaign.id!) ?? [],
      ),
    );
  }, [accounts, campaigns]);

  const checkedKeysToUse = checkedKeysProp || checkedKeys;
  const expandedKeysToUse = expandedKeysProp || expandedKeys;

  const treeData: DataNode[] = useMemo(
    () =>
      mapAdLoadDataToTree({
        ads,
        loading,
        disabled,
        accounts,
        checkedKeysToUse,
        adDestinationKeys: adDestinationKeys ?? [],
        removedAdDestinationKeys: removedAdDestinationKeys ?? [],
        campaigns: filteredCampaigns || campaigns,
      }),

    [
      ads,
      loading,
      disabled,
      accounts,
      checkedKeysToUse,
      adDestinationKeys,
      removedAdDestinationKeys,
      filteredCampaigns,
      campaigns,
    ],
  );

  const flattenedTreeData = useMemo(
    () => flattenDataTree(treeData),
    [treeData],
  );

  const actualExpandedKeys = loading
    ? []
    : expandedKeysToUse.filter(key =>
        flattenedTreeData.find(item => item.key === key),
      );
  const actualCheckedKeys = checkedKeysToUse.filter(key =>
    flattenedTreeData.find(item => item.key === key),
  );

  const getParentKey = useCallback((key: string, tree: DataNode[]) => {
    let parentKey: string = "";
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item: any) => item.key === key)) {
          parentKey = node.key as string;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }
    return parentKey;
  }, []);

  const hasKeyInTree = useCallback((data: DataNode[], key: string) => {
    if (
      data.some(
        (item: DataNode) =>
          (item.title as string).toLowerCase().indexOf(key.toLowerCase()) > -1,
      )
    )
      return true;
    if (
      data.some((item: DataNode) =>
        item.children ? hasKeyInTree(item.children, key) : false,
      )
    )
      return true;
    return false;
  }, []);

  const returnSearchedTreeData = useCallback(
    (data: DataNode[]): DataNode[] => {
      if (!!searchValue && !hasKeyInTree(data, searchValue)) {
        return [{ key: "" }];
      }
      return data
        .map((item: DataNode) => {
          const itemTitle = item.title as string;
          const index = itemTitle
            .toLowerCase()
            .indexOf(searchValue.toLowerCase());

          const beforeStr = itemTitle.substr(0, index);
          const atStr = itemTitle.substr(index, searchValue.length);
          const afterStr = itemTitle.substr(index + searchValue.length);
          const title =
            index > -1 ? (
              <span>
                {beforeStr}
                <span
                  className={
                    item.title !== emptyDataTitle
                      ? styles.searchTreeValue
                      : undefined
                  }
                >
                  {atStr}
                </span>
                {afterStr}
              </span>
            ) : (
              <span>{item.title}</span>
            );
          if (item.children) {
            if (!!searchValue && !hasKeyInTree(item.children, searchValue)) {
              if (index > -1) {
                return { ...item, title };
              }
              return { key: "" };
            }
            return {
              ...item,
              title,
              isLeaf: false,
              children: returnSearchedTreeData(item.children),
            };
          } else if (index > -1) {
            return {
              ...item,
              title,
            };
          }
          return { key: "" };
        })
        .filter(d => !!d.key);
    },
    [hasKeyInTree, searchValue],
  );

  // modify treedata in redux (for session) when selected ads or checkboxes are modified
  useEffect(() => {
    // Prevent updates to tree data in redux if campaign data is loading
    if (!checkedKeysProp || loading || !willFireSave) {
      return;
    }
    updateAdLoadDestinationData?.(
      "treeData",
      convertTreeDataToReduxData(treeData),
      "treeData",
    );

    setWillFireSave(false);
    // these props will trigger a treeData update, which will trigger a session save
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedKeysProp, adDestinationKeys]);

  // When ad selection changes, get the latest treeData and save
  useEffect(() => {
    setWillFireSave(true);
  }, [adDestinationKeys]);

  useEffect(() => {
    if (!wasExcludeClicked || !currentEventDataNode) return;

    const newAdLoadDestinationData = returnAdLoadDestinationAfterAdRemoval({
      checkedKeysToUse,
      adLoadDestination,
      currentEventDataNode,
      treeData: convertTreeDataToReduxData(treeData),
    });

    if (newAdLoadDestinationData) {
      updateAdLoadDestinationData?.(null, newAdLoadDestinationData, "treeData");
    }

    setWasExcludeClicked(false);
    setCurrentEventDataNode(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wasExcludeClicked, currentEventDataNode]);

  const filteredTreeData = useMemo(
    () => returnSearchedTreeData(treeData),
    [returnSearchedTreeData, treeData],
  );

  const isDisabled = disabled || loading || !filteredTreeData.length;

  const titleRender = useCallback(
    (node: DataNode) =>
      returnNodeTitleToRender({
        node,
        ads,
        campaigns: filteredCampaigns || campaigns,
        searchValue,
        onRemoveBtnClick: () => {
          setWasExcludeClicked(true);
        },
      }),
    [campaigns, filteredCampaigns, ads, searchValue],
  );

  const onCheck = useCallback(
    (checked: Checked, info: CheckInfo) => {
      if (isDisabled) return;

      if (!updateAdLoadDestinationData) {
        setCheckedKeys(checked as Key[]);
        return;
      }

      const newAdLoadDestinationData = returnAdLoadDestinationDataOnCheck({
        info,
        checked,
        accounts,
        campaigns: campaigns ?? [],
        adLoadDestination: adLoadDestination!,
        checkedKeysToUse: checkedKeysToUse as string[],
        expandedKeysToUse: expandedKeysToUse as string[],
      });

      setWillFireSave(true);

      updateAdLoadDestinationData(null, newAdLoadDestinationData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      adLoadDestination,
      expandedKeysToUse,
      checkedKeysToUse,
      isDisabled,
      campaigns,
      accounts,
    ],
  );

  return (
    <>
      <CampaignTreeFilter
        loading={loading}
        disabled={isDisabled}
        campaigns={campaigns ?? null}
        onSearchInputChange={e => {
          const { value } = e.target;
          const expandedKeys = flattenedTreeData
            .map(item => {
              if (item.title.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                return getParentKey(item.key, treeData);
              }
              return null;
            })
            .filter((item, i, self) => item && self.indexOf(item) === i);

          if (updateAdLoadDestinationData) {
            updateAdLoadDestinationData(
              "expandedKeys",
              expandedKeys as string[],
            );
          } else {
            setExpandedKeys(expandedKeys as string[]);
          }

          setSearchValue(value);
          setAutoExpandParent(true);
        }}
        setFilteredCampaigns={setFilteredCampaigns}
      />
      <div className={styles.searchableTreeDiv}>
        <Tree
          multiple
          virtual={true}
          height={740}
          disabled={isDisabled}
          checkedKeys={actualCheckedKeys}
          selectedKeys={actualCheckedKeys}
          expandedKeys={actualExpandedKeys}
          showLine={!!campaigns?.length}
          checkable={!!filteredTreeData.length}
          autoExpandParent={autoExpandParent}
          switcherIcon={<CaretDownOutlined />}
          treeData={filteredTreeData.length ? filteredTreeData : emptyTreeData}
          titleRender={titleRender}
          onClick={(event, node) => {
            if (isDisabled) return;

            const isAd = !!ads?.find(
              ad => ad.id === node.key?.toString()?.split("_")?.[2],
            );
            if (!isAd) return;

            setCurrentEventDataNode(node);
          }}
          onExpand={expandedKeys => {
            if (isDisabled) return;

            if (!updateAdLoadDestinationData) {
              setExpandedKeys(expandedKeys as Key[]);
            } else {
              updateAdLoadDestinationData(
                "expandedKeys",
                expandedKeys as string[],
              );
            }
            setAutoExpandParent(false);
          }}
          onCheck={onCheck}
        />
      </div>
    </>
  );
};

export default CampaignTree;
