import moment from "moment";

const config: Partial<MGConfig> = {
  wdClientId: "5cf79ca632bb9f6578dd53d620f0fe7c8e34dbce",
  wdBrandPortalId: 2797,
  active: true,
  integrations: true,
  standalone: true,
  keywordsUrl: "https://static.lithia.io/lad/constellation/WebdamKeywords.json",
  metaFields: {
    country: "countryname",
    name: "objectname",
    campaign: "customfield4",
    message: "customfield5",
    description: "caption",
    store_233: "customfield13",
    keywords: "keyword",
    store_dba: "customfield24",
    year: "customfield16",
    make: "customfield11",
    model: "customfield22",
    brand: "customfield8",
    trim: "customfield25",
    color: "customfield27",
    body_style: "customfield26",
    orientation: "customfield28",
  },
  extraFields: [
    {
      field_name: "keyword",
      filter_type: "exact",
      operator: "AND",
      value: "LADtech", // Keep casing.
    },
  ],
  tableSearchCols: [
    "headline", //
    "customfield11",
    "customfield16",
  ],
  tableSelectCols: [
    "customfield16",
    "customfield11",
    "customfield22",
    "customfield25",
    "customfield26",
    "customfield27",
    "caption",
  ],
  getCustomPayload(): Partial<MGSearchPayload> {
    return {
      payload: {
        published_filter: true,
        filters: {
          fields: [
            {
              field_name: "date_expiration",
              filter_type: "date_range",
              begin: moment().format("yyyy-MM-DD"),
              format: "yyyy-MM-dd",
            },
          ],
        },
      },
    };
  },
};

const dev: Partial<MGConfig> = {};

const stg: Partial<MGConfig> = {};

const prd: Partial<MGConfig> = {};

export default {
  ...config,
  ...{
    dev,
    stg,
    prd,
  }[process.env.REACT_APP_ENV!],
};
