import { useMutation, useQueryClient } from "react-query";
import Page, {
  fbPage,
  ICreateCanvasResponseData,
} from "screens/adLibrary/facebookUtils/page";
import {
  CanvasElementParamTypes,
  ICanvasElementParams,
  IFacebookErrorObject,
} from "screens/adLibrary/facebookUtils/types";

export const useMutateFbCanvasElements = <
  TParams extends CanvasElementParamTypes,
>(
  elementType: keyof ICanvasElementParams,
  canvasElementId?: string,
) => {
  const queryClient = useQueryClient();

  return useMutation<
    ICreateCanvasResponseData,
    { result: null; error: IFacebookErrorObject },
    { params: CanvasElementParamTypes; fbPage?: Page }
  >(
    (args: { params: CanvasElementParamTypes; fbPage?: Page }) =>
      canvasElementId
        ? (args.fbPage || fbPage).updateElement<TParams>(
            canvasElementId,
            args.params as TParams,
          )
        : (args.fbPage || fbPage).createElements({
            [elementType]: args.params,
          }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["fbCanvasElements", canvasElementId]);
      },
    },
  );
};
