import { FC, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Row, Upload } from "antd";

import * as helpers from "../../utils/helpers";

import { RcFile } from "antd/lib/upload";
import { ISingleLogoObject } from "shared/types/uploadManagement";

interface IImageUploaderBatch {
  s3AssetBucket?: string;

  title: string;
  toggleUpload?: () => void;
  modifyDimensionsString?: (dimensionsString: string) => void;
  clearFiles?: boolean;

  singleLogoObject: ISingleLogoObject;

  setSingleLogoObject: (singleLogoObject: ISingleLogoObject) => void;

  disabled?: boolean;
}

interface IImageUploaderProps {
  listType: "text" | "picture-card" | "picture" | undefined;
  defaultFileList?: any[];
  fileList?: any[];
  className: string;
  multiple: boolean;
}

const ImageUploaderBatch: FC<IImageUploaderBatch> = ({
  title,
  toggleUpload,
  modifyDimensionsString,
  clearFiles,
  setSingleLogoObject,
  disabled,
}) => {
  const [errorMessage, setErrorMessage] = useState("");

  const props: IImageUploaderProps = {
    listType: "text",
    className: "upload-list-inline",
    multiple: false,
  };

  const validateFile = (file: RcFile) => {
    const { size, type } = file;
    const isJpgOrPng = type === "image/jpeg" || type === "image/png";
    const ismax1M = size ? size / 1024 / 1024 <= 1 : false;

    const feedbackMessage = [];
    let validUpload = isJpgOrPng && ismax1M;

    if (!ismax1M) feedbackMessage.push("image must be smaller than 1MB");
    if (!isJpgOrPng) feedbackMessage.push("image must be a JPG/PNG file");

    return {
      validUpload,
      feedbackMessage: feedbackMessage.join(", "),
    };
  };

  const roundToNearest = (value: number) => Math.ceil(value / 10);

  const calculateDimension = (a: number, b: number) =>
    roundToNearest(a) === roundToNearest(b);

  const checkImageDimension = (
    file: RcFile,
    title: string,
  ): Promise<string | false> => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", event => {
        const _loadedImageUrl = event.target?.result;
        const image = document.createElement("img");
        image.src = _loadedImageUrl as string;

        image.addEventListener("load", () => {
          const { width, height } = image;

          switch (title) {
            case "Horizontal":
              return resolve(
                calculateDimension(height * 2, width)
                  ? false
                  : "please upload a file with a horizontal size ratio of 2:1",
              );
            case "Vertical":
              return resolve(
                calculateDimension(height, width * 2)
                  ? false
                  : "please upload a file with a vertical size ratio of 1:2",
              );
            case "Square":
              return resolve(
                calculateDimension(height, width)
                  ? false
                  : "please upload a file with a square size ratio of 1:1",
              );
            case "Jellybean":
              if (toggleUpload) {
                toggleUpload();
              }

              return resolve(false);
            default:
              return resolve("please upload a file with valid dimensions");
          }
        });
      });
    });
  };

  let manualFileList: RcFile[] = [];

  return (
    <Row>
      <span style={{ display: "flex" }}>
        <div style={{ paddingLeft: "20px" }}>
          <Upload
            {...props}
            fileList={clearFiles ? [] : manualFileList}
            beforeUpload={async (file, fileList) => {
              // processes file
              setErrorMessage("");
              const validateImage = validateFile(file);

              const { validUpload } = validateImage;

              if (!validUpload) {
                setErrorMessage(validateImage.feedbackMessage);

                return false;
              }

              if (modifyDimensionsString) {
                const _URL = window.URL || window.webkitURL;
                const img = new Image();
                img.src = _URL.createObjectURL(file);
                img.onload = () => {
                  modifyDimensionsString(
                    `Dimensions: ${img.width}px x ${img.height}px Format: ${file.type}`,
                  );
                };
              }

              const dimesionsErrorMsg = await checkImageDimension(file, title);

              if (dimesionsErrorMsg) {
                setErrorMessage(dimesionsErrorMsg);

                return false;
              }

              helpers.getBase64DataUrl(file, imageDataUrl => {
                if (
                  typeof imageDataUrl !== "string" ||
                  imageDataUrl.length <= 0
                ) {
                  setErrorMessage("there is an error with the selected file");

                  return false;
                }

                const tempImage = document.createElement("img");
                tempImage.setAttribute("src", imageDataUrl);

                const guidFilename = helpers.generateGuidFilename(file.name);

                setSingleLogoObject({
                  [`${title.toLowerCase()}`]: {
                    type: file.type,
                    file: imageDataUrl || null,
                    filename: guidFilename,
                    preview: imageDataUrl,
                    size: file.size,
                  },
                  group: title,
                  feedbackMessage: "",
                });
              });

              manualFileList = fileList;

              return false;
            }}
          >
            <Button
              disabled={disabled}
              style={{
                zIndex: 99,
                borderColor: errorMessage ? "red" : "",
              }}
            >
              <UploadOutlined />
              Attach File
            </Button>
          </Upload>
          {errorMessage && (
            <div style={{ color: "red", paddingTop: "8px" }}>
              {errorMessage}
            </div>
          )}
        </div>
      </span>
    </Row>
  );
};

export default ImageUploaderBatch;
