import produce from "immer";
import {
  FacebookCampaignObjective,
  IFacebookAdset,
  IFacebookCampaign,
} from "screens/adLibrary/facebookUtils/types";
import { CampaignResByAcctId } from "shared/hooks/adLibrary/facebook/useFetchCampaigns";
import { IAd } from "shared/types/adLibrary";
import { IAccount } from "shared/types/accountManagement";

/** `${storeName}` */
type StoreKey = `${string}`;
/** `${storeName}_${campaignId}` */
export type CampaignKey = `${StoreKey}_${string}`;
/** `${storeName}_${campaignId}_${adsetId}` */
type AdSetKey = `${CampaignKey}_${string}`;
/** `${storeName}_${campaignId}_${adsetId}_${adId}` */
export type AdDestKey = `${AdSetKey}_${string}`;
export type AdDest = {
  storeName: string;
  campaignId: string;
  adsetId: string;
  adId: string;
  loadToFb: boolean;
};

export const getAdDestFromKey = (adKey: AdDestKey | CampaignKey) => {
  const [storeName, campaignId, adsetId, adId] = adKey.split("_");
  return {
    storeName,
    campaignId,
    adsetId,
    adId,
  };
};

export const getAdKeyFromDest = (adDest: Omit<AdDest, "loadToFb">) => {
  const { storeName, campaignId, adsetId, adId } = adDest;
  return `${storeName}_${campaignId}_${adsetId}_${adId}` satisfies AdDestKey;
};

interface BasicFields {
  id: string;
  name: string;
}

export interface StoreData {
  accountId: string;
  storeName: string;
  isLoadingCampaigns: boolean;
  campaignIds: string[];
  campaignIdsWithAds: string[];
  hasAds: boolean;
  key: StoreKey;
}

export interface CampaignData extends BasicFields {
  adsetIds: string[];
  adsetIdsWithAds: string[];
  storeName: string;
  key: CampaignKey;
  objective: FacebookCampaignObjective;
  productCatalogId?: string;
}

export interface AdsetData extends BasicFields {
  campaignId: string;
  key: AdSetKey;
  ads: string[];
  productSetId?: string;
}

export interface AdsetAdData {
  adsetId: string;
  adId: string;
  productCatalogId?: string;
  productSetId?: string;
  key: AdDestKey;
}

export type NormalizedCampaignData = {
  stores: {
    [storeName: string]: StoreData;
  };
  campaigns: {
    [campaignId: string]: CampaignData;
  };
  adsets: {
    [adsetId: string]: AdsetData;
  };
  adsetAd: {
    [adKey: AdDestKey]: AdsetAdData;
  };
  ads: {
    [adId: string]: BasicFields;
  };
};

type CampaignsDataArgs = {
  campaignResByAcctId: CampaignResByAcctId;
  selectedStores: IAccount[];
  adDests: AdDest[];
  ads: IAd[];
  adsToLoad: IAd[];
};

export const getNormalizedCampaignsData = ({
  campaignResByAcctId,
  selectedStores,
  adDests,
  ads,
  adsToLoad,
}: CampaignsDataArgs) => {
  return selectedStores.reduce<NormalizedCampaignData>(
    (prevTreeNodeData, currentStore) =>
      produce(prevTreeNodeData, campaignsDataDraft => {
        addStore({
          campaignsDataDraft,
          currentStore,
          campaignResByAcctId,
          adDests,
          ads,
          adsToLoad,
        });
      }),
    {
      stores: {},
      campaigns: {},
      adsets: {},
      adsetAd: {},
      ads: {},
    },
  );
};

const addStore = ({
  currentStore,
  campaignResByAcctId,
  campaignsDataDraft,
  adDests,
  ads,
}: {
  currentStore: IAccount;
  campaignsDataDraft: NormalizedCampaignData;
  campaignResByAcctId: CampaignResByAcctId;
  adDests: AdDest[];
  ads: IAd[];
  adsToLoad: IAd[];
}) => {
  const storeName = currentStore.dealer_name;
  const accountId = currentStore.details.facebook.fbAccountId;

  const { data: matchingCampaigns = [], isLoading: isLoadingCampaigns } =
    campaignResByAcctId[accountId];

  const campaignIds = matchingCampaigns.map(campaign => campaign.id);

  campaignsDataDraft.stores[storeName] = {
    accountId,
    storeName,
    key: storeName,
    isLoadingCampaigns,
    campaignIds,
    campaignIdsWithAds: campaignIds.filter(campaignId =>
      adDests.some(adDest => adDest.campaignId === campaignId),
    ),
    hasAds: adDests.some(adDest => adDest.storeName === storeName),
  };

  matchingCampaigns.forEach(
    addCampaigns({ storeName, campaignsDataDraft, adDests, ads }),
  );
};

const addCampaigns = ({
  storeName,
  campaignsDataDraft,
  adDests,
  ads,
}: {
  storeName: string;
  campaignsDataDraft: NormalizedCampaignData;
  adDests: AdDest[];
  ads: IAd[];
}) => {
  return (campaign: IFacebookCampaign) => {
    const adsetsData = campaign.adsets?.data ?? [];

    const campaignKey = `${storeName}_${campaign.id}` as const;
    const adsetIds = adsetsData.map(adset => adset.id);
    campaignsDataDraft.campaigns[campaign.id] = {
      id: campaign.id,
      name: campaign.name,
      objective: campaign.objective,
      productCatalogId: campaign.promoted_object?.product_catalog_id,
      key: campaignKey,
      adsetIds,
      adsetIdsWithAds: adsetIds.filter(adsetId =>
        adDests.some(adDest => adDest.adsetId === adsetId),
      ),
      storeName,
    };

    adsetsData.forEach(
      addAdsets({ campaignKey, adDests, campaignsDataDraft, campaign, ads }),
    );
  };
};

const addAdsets = ({
  campaignKey,
  adDests,
  campaignsDataDraft,
  campaign,
  ads,
}: {
  campaignKey: CampaignKey;
  adDests: AdDest[];
  campaignsDataDraft: NormalizedCampaignData;
  campaign: IFacebookCampaign;
  ads: IAd[];
}) => {
  return (adset: IFacebookAdset) => {
    const adsetKey = `${campaignKey}_${adset.id}` as const;
    const adsData = adDests.filter(adDest => adDest.adsetId === adset.id);

    campaignsDataDraft.adsets[adset.id] = {
      id: adset.id,
      name: adset.name,
      campaignId: campaign.id,
      key: adsetKey,
      ads: adsData.map(adKey => adKey.adId),
      productSetId: adset.promoted_object?.product_set_id,
    };

    adsData.forEach(
      addAds({ ads, campaignsDataDraft, adset, campaignKey: campaign.id }),
    );
  };
};

const addAds = ({
  ads,
  campaignsDataDraft,
  adset,
  campaignKey,
}: {
  ads: IAd[];
  campaignsDataDraft: NormalizedCampaignData;
  adset: IFacebookAdset;
  campaignKey: string;
}) => {
  return (adDest: AdDest) => {
    const adId = adDest.adId;
    const ad = ads.find(adItem => adItem.id === adId);
    const adKey = getAdKeyFromDest(adDest);
    if (!adId || !ad) return;

    campaignsDataDraft.adsetAd[adKey] = {
      adsetId: adset.id,
      productCatalogId:
        campaignsDataDraft.campaigns[campaignKey].productCatalogId,
      productSetId: campaignsDataDraft.adsets[adset.id].productSetId,
      adId,
      key: adKey,
    };

    if (!campaignsDataDraft.ads[adId]) {
      campaignsDataDraft.ads[adId] = {
        id: adId,
        name: ad.inputParameters.name ?? "",
      };
    }
  };
};
