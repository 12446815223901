import { useMemo } from "react";
import { queryClient } from "queryClient";
import {
  IBrand,
  BrandRecord,
  AccountRecord,
} from "shared/types/brandsAccounts";
import { IAccount } from "shared/types/accountManagement";
import { BRANDS_QUERY_KEY } from "shared/constants/brandsAccounts";
import { useFetchBrands } from "./useFetchBrands";
import { useFetchDealers } from "../useFetchDealersV2";
import { LogoUrlsFromS3 } from "shared/types/brandManagement";

const getLogoUrl = (logoUrl: string) => {
  if (logoUrl?.includes("imageUrl")) {
    const logoUrlParsed = JSON.parse(logoUrl);
    return logoUrlParsed["square"]?.["imageUrl"];
  }

  return logoUrl;
};

export const formatBrandForTable = (
  brand: IBrand,
  dealers: IAccount[] | undefined,
): BrandRecord => {
  const {
    oem_name: brandName,
    logo_urls_from_S3,
    logo_url: logoUrl,
    created_at,
    fonts,
    youtubeConnected,
  } = brand;

  let logosFromS3: LogoUrlsFromS3;

  try {
    logosFromS3 = JSON.parse(logo_urls_from_S3 ?? "{}");
  } catch (error) {
    logosFromS3 = {
      horizontalEventImagesFromS3: [],
      horizontalImagesFromS3: [],
      squareEventImagesFromS3: [],
      squareImagesFromS3: [],
      verticalEventImagesFromS3: [],
      verticalImagesFromS3: [],
    };
  }

  const logos = Object.values(logosFromS3).flatMap(
    (logosFromOneType: string[]) => logosFromOneType,
  );

  const brandLogo = getLogoUrl(logoUrl);

  return {
    id: brandName,
    key: brandName,
    name: brandName,
    logosFromS3,
    logos,
    logoUrl: brandLogo,
    createdAt: created_at,
    type: "Brand",
    fonts,
    youtubeConnected: youtubeConnected ?? false,
    children:
      dealers?.map((dealer: IAccount) =>
        formatAccountForTable(dealer, brandName),
      ) ?? [],
  };
};

export const formatAccountForTable = (dealer: IAccount, brandName: string) => {
  let logosFromS3: LogoUrlsFromS3;

  try {
    logosFromS3 = JSON.parse(dealer.logo_urls_from_S3 ?? "{}");
  } catch (error) {
    logosFromS3 = {
      horizontalEventImagesFromS3: [],
      horizontalImagesFromS3: [],
      squareEventImagesFromS3: [],
      squareImagesFromS3: [],
      verticalEventImagesFromS3: [],
      verticalImagesFromS3: [],
    };
  }
  const logos = Object.values(logosFromS3).flatMap(
    (logosFromOneType: string[]) => logosFromOneType,
  );

  return {
    parentId: brandName,
    name: dealer.dealer_name,
    location: dealer.state ?? "",
    url: dealer.dealer_url ?? "",
    code: dealer.dealer_code,
    logos: logos,
    id: `${brandName}-${dealer.dealer_code}-${dealer.dealer_name}}`,
    key: `${brandName}-${dealer.dealer_code}-${dealer.dealer_name}}`,
    fbAccountId: dealer.details?.facebook.fbAccountId ?? "",
    fbCatalogId: dealer.details?.facebook.fbCatalogId ?? "",
    fbInstagramId: dealer.details?.facebook.fbInstagramId ?? "",
    fbPageId: dealer.details?.facebook.fbPageId ?? "",
    fbPixelId: dealer.details?.facebook.fbPixelId ?? "",
    googleadAccountCID: dealer.details?.google?.adAccountCID ?? "",
    createdAt: dealer.created_at,
    type: "Account",
  } as AccountRecord;
};

export const useGetFormattedBrands = () => {
  const { dealers, isFetching: isDealersFetching } = useFetchDealers();
  const { brands, isFetching } = useFetchBrands();
  const formattedBrands = useMemo(() => {
    queryClient.invalidateQueries(BRANDS_QUERY_KEY);

    return (
      brands.map(brand =>
        formatBrandForTable(
          brand,
          dealers.filter(dealer => {
            return dealer.dealer_oem === brand.oem_name;
          }),
        ),
      ) ?? []
    );
  }, [brands, dealers]);

  return {
    brands: formattedBrands,
    isLoading: isFetching || isDealersFetching,
  };
};
