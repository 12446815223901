import { Button, message, notification, Modal } from "antd";
import { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { NavLink, useNavigate, useLocation } from "react-router-dom";

import * as assetBuilderActions from "redux/assetBuilder/assetBuilder.slice";
import * as dealerManagementActions from "redux/dealerManagement/dealerManagement.slice";

import AssetBuilderHeader, {
  SelectedItem,
  SelectedSubItem,
} from "./assetBuilder/AssetBuilderHeader";
import NewOfferForm from "./assetBuilder/NewOfferForm";
import TabContainer from "shared/components/TabContainer";
import {
  IAssetBuilderState,
  OfferData,
  SingletonOfferKeys,
  RepeatableOfferKeys,
  RawOfferData,
  OfferOperationMode,
  HeaderMatchType,
  OfferEditFieldObject,
  ProcessedPaymentEngineData,
  IQueryParameters,
  VehicleConditions,
  ISortingOption,
  FeedTab,
  URLPattern,
} from "shared/types/assetBuilder";
import { FeedType } from "shared/types/configuration";
import OfferFormPaymentEngineModal from "./assetBuilder/offers/select/offerForm/OfferFormPaymentEngineModal";
import { GetPaymentEngineDealDataResult } from "shared/types/paymentEngine";
import GenericError from "shared/errors/GenericError";
import {
  IAccountManagementState,
  IAccount,
} from "shared/types/accountManagement";
import { floatValueFields } from "shared/constants/assetBuilder";
import { isEmpty } from "lodash";
import { UseGetTabData } from "shared/hooks/assetBuilder/useGetTabData";
import API from "services";

interface ISelectedHeader {
  selectedItem: SelectedItem;
  selectedSubItem?: SelectedSubItem;
  actionButtons: any[];
}

interface IEditOffer {
  useUploadedJellyBean: boolean;
  savedOrder: IAssetBuilderState["savedOrder"];
  commitOrder: () => Promise<void>;
  saveOffer: (
    useUploadedJellyBean: boolean,
    type: "create" | "update" /*,dealer?: IAccountRecord,*/,
    feedId?: string,
  ) => Promise<void>;
  getPaymentEngineDataReset: () => void;
  offerEditsWereSaved: boolean;
  resetOfferEditsWereSaved: () => void;
  processedPaymentEngineData: ProcessedPaymentEngineData | null;
  rawPaymentEngineData: GetPaymentEngineDealDataResult | null;
  getPaymentEngineDataError: GenericError | null;
  offerData: OfferData;
  editField: <T extends SingletonOfferKeys | RepeatableOfferKeys>(
    key: T | string,
    value: RawOfferData[T],
  ) => void;
  generalMessage: string;
  errorMessage: string | null;
  processingExport: boolean;
  offerOperationMode: OfferOperationMode;

  getDealer: (dealerName: string) => void;
  currentDealerData: IAccount | null;
  setOfferDataComparator: (offerData: OfferData) => void;
  setSaveDialog: (input: boolean, path?: string) => void;
  saveDialog: boolean;
  path: string;
  setEditOfferData: (
    offerData: OfferData,
    editedKeys: Record<string, boolean>,
    operation?: OfferOperationMode,
  ) => void;
  resetToMasterData: (vin: string) => void;
  fetchOfferList: (parameter: IQueryParameters) => void;
  offerList: Array<{
    row: OfferData;
    updated?: number;
    editedKeys: Record<string, boolean>;
  }>;
  feedId?: string;
  feed: FeedType;
  vehicleConditionFilter: VehicleConditions;
  checkedOfferFilters: {
    Lease: boolean;
    "Zero Down Lease": boolean;
    Finance: boolean;
    Purchase: boolean;
    APR: boolean;
  };
  searchQuery: string;
  sortByFilter: ISortingOption[];
  openOfferOverwriteModal: boolean;
  toggleOverwriteOfferModal: (value: boolean) => void;
  saveNewOffer: () => Promise<boolean>;
  currentTab?: FeedTab;
  resetOfferData: () => void;
}

const EditOffer: FC<IEditOffer> = ({
  getPaymentEngineDataReset,
  useUploadedJellyBean,
  commitOrder,
  savedOrder,
  saveOffer,
  offerEditsWereSaved,
  resetOfferEditsWereSaved,
  processedPaymentEngineData,
  rawPaymentEngineData,
  getPaymentEngineDataError,
  offerData,
  editField,
  generalMessage,
  errorMessage,
  processingExport,
  offerOperationMode,
  resetOfferData,
  getDealer,
  currentDealerData,
  setOfferDataComparator,
  setSaveDialog,
  saveDialog,
  path,
  setEditOfferData,
  resetToMasterData,
  offerList,
  openOfferOverwriteModal,
  toggleOverwriteOfferModal,
  currentTab,
  feedId,
}) => {
  const [showDSModal, toggleDSModal] = useState(false);
  const [initialSelectedRow, setinitialSelectedRow] =
    useState<OfferEditFieldObject | null>(null);

  const navigate = useNavigate();
  const [fetchOfferListInvoked, setFetchOfferListInvoked] = useState(false);

  useEffect(() => {
    if (offerData?.vin) {
      return setOfferDataComparator(offerData);
    }

    const vin = pathname.split("/").pop();
    const foundSelectedOffer = savedOrder?.selectedOffers?.find(
      offer => offer.offerData.vin === vin,
    );
    const foundAvailableOffer = offerList.find(offer => offer.row.vin === vin);

    if (isEmpty(foundSelectedOffer) && isEmpty(foundAvailableOffer)) {
      setFetchOfferListInvoked(true);

      return;
    } else if (!isEmpty(foundSelectedOffer) && !isEmpty(foundAvailableOffer)) {
      setEditOfferData(
        {
          ...(foundSelectedOffer?.offerData as RawOfferData),
          vin: vin || "",
        },
        {},
        "UPDATE",
      );

      return;
    } else if (!isEmpty(foundSelectedOffer) && isEmpty(foundAvailableOffer)) {
      setEditOfferData(
        {
          ...(foundSelectedOffer?.offerData as RawOfferData),
          vin: vin || "",
        },
        {},
        "UPDATE",
      );

      return;
    } else if (isEmpty(foundSelectedOffer) && !isEmpty(foundAvailableOffer)) {
      setEditOfferData(
        {
          ...(foundAvailableOffer?.row as RawOfferData),
          vin: vin || "",
        },
        {},
        "UPDATE",
      );

      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerList]);

  const { pathname } = useLocation();
  const [duplicating, setDuplicating] = useState<boolean>(false);
  const [isDuplicateMode, setIsDuplicateMode] = useState<boolean>(false);

  const type =
    offerOperationMode === "CREATE" || isDuplicateMode ? "create" : "update";

  useEffect(() => {
    return () => resetOfferData();
  }, [resetOfferData]);

  useEffect(() => {
    if (
      !fetchOfferListInvoked ||
      pathname.includes("/create") ||
      isDuplicateMode
    )
      return;

    const vin = pathname.split("/").pop();
    const foundAvailableOffer = offerList.find(offer => offer.row.vin === vin);

    if (fetchOfferListInvoked && isEmpty(foundAvailableOffer)) {
      message.error("Invalid URL", 5);
      return;
    }
  }, [fetchOfferListInvoked, offerList, pathname, isDuplicateMode]);

  useEffect(() => {
    if (!generalMessage && !errorMessage) {
      return;
    } else if (generalMessage) {
      message.success(generalMessage, 5);
    } else if (errorMessage) {
      message.error(errorMessage, 5);
    }
  }, [generalMessage, errorMessage]);

  useEffect(() => {
    // reset offerEditsWereSaved only when this page is first opened
    if (!offerEditsWereSaved) {
      return;
    }
    resetOfferEditsWereSaved();
  }, [offerData, offerEditsWereSaved, resetOfferEditsWereSaved]);

  const getOrdersActionButtons = () => [
    <Button
      key="go-back-button"
      className="action-button"
      loading={processingExport}
      onClick={() => {
        navigate(-1);
      }}
    >
      GO BACK
    </Button>,
    <Button
      key="build-asset-action-button"
      className="action-button"
      loading={processingExport}
      onClick={async () => {
        if (!offerData.vin) {
          notification.warning({
            message: "Invalid Offer data.",
            description: "A VIN is required in order to create a new offer",
            placement: "bottomRight",
          });
          return;
        }

        for (let i = 0; i < floatValueFields.length; i++) {
          const field = floatValueFields[i];
          const value = offerData[field as keyof OfferData] as string;

          if (
            value &&
            !/^[0-9]{1,3}(?:\,?[0-9]{3})*(?:\.[0-9]{1,4})?$/.test(value)
          ) {
            message.error("Please input valid inputs for highlighted fields");
            return;
          }
        }
        await saveOffer(useUploadedJellyBean, type, feedId);
      }}
    >
      {`${offerOperationMode || "Save"} OFFER`}
    </Button>,
  ];

  const getOffersActionButtons = (
    pathnameParam: string,
    actionButtonsFor: "select" | "build" | "review",
  ) => {
    switch (actionButtonsFor) {
      case "select":
        return [
          <Button key="asset-builder-offers-select">
            <NavLink
              onClick={() => {
                navigate(-1);
              }}
              to={`${pathnameParam.replace("/select", "")}/build`}
            >
              Build Asset
            </NavLink>
          </Button>,
        ];

      case "build":
        return [
          <Button key="asset-builder-offers-select">
            <NavLink
              onClick={() => {
                navigate(-1);
              }}
              to={`${pathnameParam.replace("/build", "")}/review`}
            >
              Review
            </NavLink>
          </Button>,
        ];

      default:
        return [];
    }
  };

  const [selectedHeader, setSelectedHeader] = useState<ISelectedHeader>({
    selectedItem: "ORDERS",
    actionButtons: getOrdersActionButtons(),
  });

  const [enabledHeaders, setEnabledHeaders] = useState<HeaderMatchType[]>([
    "orders",
  ]);

  useEffect(() => {
    const offersSelectRegex = /\/asset-builder\/orders\/.+\/offers\/select$/g;
    const offersBuildRegex = /\/asset-builder\/orders\/.+\/offers\/build$/g;
    const offersReviewRegex = /\/asset-builder\/orders\/.+\/offers\/review$/g;

    let headersToEnabled: HeaderMatchType[] = ["orders"];
    let headersToSelect: ISelectedHeader = {
      selectedItem: "OFFERS",
      selectedSubItem: "INPUT",
      actionButtons: getOrdersActionButtons(),
    };

    if (offersSelectRegex.test(pathname)) {
      headersToEnabled = ["orders", "offers"];
      headersToSelect = {
        selectedItem: "OFFERS",
        selectedSubItem: "SELECT",
        actionButtons: getOffersActionButtons(pathname, "select"),
      };
    } else if (offersBuildRegex.test(pathname)) {
      headersToEnabled = ["orders", "offers", "select", "build"];
      headersToSelect = {
        selectedItem: "OFFERS",
        selectedSubItem: "BUILD",
        actionButtons: getOffersActionButtons(pathname, "build"),
      };
    } else if (offersReviewRegex.test(pathname)) {
      headersToEnabled = ["orders", "offers", "select", "build", "review"];
      headersToSelect = {
        selectedItem: "OFFERS",
        selectedSubItem: "REVIEW",
        actionButtons: getOffersActionButtons(pathname, "review"),
      };
    }

    setEnabledHeaders(headersToEnabled);
    setSelectedHeader(headersToSelect);

    // eslint-disable-next-line
  }, [pathname, offerData, useUploadedJellyBean, offerOperationMode]);

  const [orderHasBeenClicked, setOrderHasBeenClicked] =
    useState<boolean>(false);

  const [resetingToMasterData, setResetingToMasterData] =
    useState<boolean>(false);

  const [originalVin, setOriginalVin] = useState<string>("");

  const getTabData = async (vin: string) => {
    const params: UseGetTabData = {
      feedId: currentTab?.feedId ?? "",
      dealerCode: savedOrder?.selectedOrder?.dealer_code ?? "",
      dealerOem: savedOrder?.selectedOrder?.dealer_oem ?? "",
      filterBy: "All",
      sortingOptions: [],
      searchBy: vin,
      filterField: "dealerId",
      filterFieldSearch: "",
    };

    const res = await API.services.assetBuilder.getTabData(params, 1);
    const { result, error: apiError } = res;

    if (!result || !!apiError) return;
    return res.result;
  };

  const headerSaveEdits = async (operation: "create" | "update") => {
    if (offerData.vin && operation === "create") {
      const result = await getTabData(offerData.vin);
      if (!result) {
        message.error("Unable to check duplicate vin number");
      }
      const isExistVin =
        (result?.total ?? 0) > 0 &&
        result?.offerList.some(offer => offer.vin === offerData.vin);
      if (isExistVin) {
        message.error(
          "VIN already exists. To save this offer, remove or change the VIN value.",
          5,
        );
        return;
      }
    }
    await saveOffer(useUploadedJellyBean, operation, feedId);
    await commitOrder();
    const splitUrl = pathname.split("/");
    const { DOMAIN, ASSETBUILDER, ORDER, ORDERID } = URLPattern;
    const url = [
      splitUrl[DOMAIN],
      splitUrl[ASSETBUILDER],
      splitUrl[ORDER],
      splitUrl[ORDERID],
      "offers/select",
    ].join("/");

    navigate(url);
  };

  return (
    <div className="outerDiv">
      <Modal
        title={"Unsaved Changes Detected"}
        visible={saveDialog}
        maskClosable={true}
        onOk={() => {
          navigate("/asset-builder/orders");
        }}
        onCancel={() => {
          setSaveDialog(false);
        }}
        closable={true}
        footer={[
          [
            <Button
              key="cancel"
              className="secondary-btn"
              onClick={() => {
                setSaveDialog(false);
                resetOfferEditsWereSaved();
                orderHasBeenClicked
                  ? navigate("/asset-builder/orders")
                  : navigate(path);
              }}
            >
              Continue without saving
            </Button>,
            <Button
              key="save"
              type="primary"
              onClick={async () => {
                if (offerData.vin && type === "create") {
                  const result = await getTabData(offerData.vin);
                  if (!result) {
                    message.error("Unable to check duplicate vin number");
                  }
                  const isExistVin =
                    (result?.total ?? 0 > 0) &&
                    result?.offerList.some(
                      offer => offer.vin === offerData.vin,
                    );
                  if (isExistVin) {
                    message.error(
                      "VIN already exists. To save this offer, remove or change the VIN value.",
                      5,
                    );
                    return;
                  }
                }
                await saveOffer(useUploadedJellyBean, type, feedId);
                await commitOrder();
                resetOfferEditsWereSaved();
                setSaveDialog(false);
                orderHasBeenClicked
                  ? navigate("/asset-builder/orders")
                  : navigate(path);
              }}
            >
              Save
            </Button>,
          ],
        ]}
      >
        <p>Would you like to save your edits before leaving Asset Builder?</p>
      </Modal>

      <AssetBuilderHeader
        selectedItem={selectedHeader.selectedItem}
        selectedSubItem={selectedHeader.selectedSubItem}
        actionButtons={selectedHeader.actionButtons}
        enabledHeaders={enabledHeaders}
        setOrderHasBeenClicked={setOrderHasBeenClicked}
        headerSaveEdits={headerSaveEdits}
        isDuplicateMode={isDuplicateMode}
      />

      <Modal
        visible={
          duplicating ||
          (openOfferOverwriteModal && pathname.endsWith("/create-offer")) ||
          resetingToMasterData
        }
        title={
          duplicating
            ? "Duplicate Offer?"
            : openOfferOverwriteModal
            ? `Overwrite Offer?`
            : `Reset Data to Offer Data`
        }
        maskClosable={true}
        onCancel={() => {
          setResetingToMasterData(false);
          toggleOverwriteOfferModal(false);
          setDuplicating(false);
        }}
        closable={true}
        footer={[
          [
            <Button
              key="submit"
              type="primary"
              onClick={async () => {
                if (duplicating) {
                  setOriginalVin(offerData.vin);
                  setEditOfferData(
                    {
                      ...offerData,
                      vin: "",
                    },
                    {},
                    "CREATE",
                  );
                  setResetingToMasterData(false);
                  toggleOverwriteOfferModal(false);
                  setDuplicating(false);
                  return;
                }

                if (resetingToMasterData) {
                  resetToMasterData(originalVin);
                  setResetingToMasterData(false);
                  toggleOverwriteOfferModal(false);
                  setDuplicating(false);
                  return;
                }

                await saveOffer(
                  useUploadedJellyBean,
                  "create" /*, currentDealer*/,
                );
              }}
            >
              Yes
            </Button>,
            <Button
              key="cancel"
              type="default"
              onClick={() => {
                setResetingToMasterData(false);
                toggleOverwriteOfferModal(false);
                setDuplicating(false);
                setIsDuplicateMode(false);
              }}
            >
              No
            </Button>,
          ],
        ]}
      >
        {resetingToMasterData ? (
          <p>
            Are you sure you want to revert all of the edited fields to the feed
            data?
          </p>
        ) : (
          <p>
            {`It looks like an offer with the VIN`} <b>{offerData.vin}</b>
            {` is already in use. ${
              duplicating
                ? "Would you like to duplicate it"
                : "Would you like to overwite it"
            }?`}
          </p>
        )}
      </Modal>

      <TabContainer
        displaySearchView={{
          displayNewOffer: false,
          displaySearchInput: false,
          displayPlusButton: false,
        }}
        displayFilterSection={false}
        contentTabs={[
          {
            title: `Input Variables - ${currentTab?.feedName}`,
            component: (
              <div>
                <NewOfferForm
                  setEditOfferData={setEditOfferData}
                  offerOperationType={type}
                  setDuplicating={setDuplicating}
                  setIsDuplicateMode={setIsDuplicateMode}
                  setResetingToMasterData={setResetingToMasterData}
                  togglePaymentEngineModal={offerEditFieldObj => {
                    const dealerName =
                      savedOrder?.selectedOrder?.dealer_name ||
                      offerData.dealerName;
                    if (!currentDealerData && dealerName) {
                      getDealer(dealerName);
                    }
                    setinitialSelectedRow(offerEditFieldObj);
                    toggleDSModal(true);
                  }}
                  originalVin={originalVin}
                />
                <OfferFormPaymentEngineModal
                  getPaymentEngineDataReset={getPaymentEngineDataReset}
                  showDSModal={showDSModal}
                  toggleDSModal={toggleDSModal}
                  processedPaymentEngineData={processedPaymentEngineData}
                  rawPaymentEngineData={rawPaymentEngineData}
                  getPaymentEngineDataError={getPaymentEngineDataError}
                  offerData={offerData}
                  editField={editField}
                  processingExport={processingExport}
                  initialSelectedRow={initialSelectedRow}
                  currentDealerData={currentDealerData}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const { assetBuilder, newOrders, dealerManagement, configuration } = state;
  const {
    useUploadedJellyBean,
    savedOrder,
    offerEditsWereSaved,
    processedPaymentEngineData,
    rawPaymentEngineData,
    getPaymentEngineDataError,
    offerData,
    generalMessage,
    errorMessage,
    processingExport,
    offerOperationMode,
    saveDialog,
    path,
    offerList,
    vehicleConditionFilter,
    searchQuery,
    sortByFilter,
    checkedOfferFilters,
    openOfferOverwriteModal,
    currentTab,
  } = assetBuilder as IAssetBuilderState;

  const { feed } = configuration;

  const { dealerResult } = dealerManagement as IAccountManagementState;

  return {
    currentTab,
    openOfferOverwriteModal,
    useUploadedJellyBean,
    savedOrder,
    offerEditsWereSaved,
    processedPaymentEngineData,
    rawPaymentEngineData,
    getPaymentEngineDataError,
    generalMessage,
    errorMessage,
    processingExport,
    offerData: offerData as OfferData,
    offerOperationMode,
    currentDealerData: dealerResult?.dealer || null,
    saveDialog,
    path,
    offerList,
    feed,
    vehicleConditionFilter,
    searchQuery,
    sortByFilter,
    checkedOfferFilters,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  toggleOverwriteOfferModal: (value: boolean) => {
    return dispatch(assetBuilderActions.toggleOverwriteOfferModal(value));
  },
  saveNewOffer: () => {
    return dispatch(
      assetBuilderActions.saveNewOffer(),
    ) as unknown as Promise<boolean>;
  },
  commitOrder: () => {
    return dispatch(
      assetBuilderActions.commitOrder(),
    ) as unknown as Promise<void>;
  },
  saveOffer: (
    useUploadedJellyBean: boolean,
    type: "create" | "update",
    feedId?: string,
  ) => {
    return dispatch(
      assetBuilderActions.saveOffer(
        useUploadedJellyBean,
        type,
        undefined,
        feedId,
      ),
    ) as unknown as Promise<void>;
  },
  resetOfferEditsWereSaved: () =>
    dispatch(assetBuilderActions.resetOfferEditsWereSaved()),
  getPaymentEngineDataReset: () => {
    dispatch(assetBuilderActions.getPaymentEngineDataReset());
  },
  editField: <T extends RepeatableOfferKeys | SingletonOfferKeys>(
    key: T | string,
    value: RawOfferData[T],
  ) => dispatch(assetBuilderActions.editOffer({ key, value })),
  getDealer: (dealerName: string) =>
    dispatch(dealerManagementActions.getDealer(dealerName)),
  setOfferDataComparator: (offerData: OfferData) => {
    dispatch(assetBuilderActions.setOfferDataComparator(offerData));
  },
  setSaveDialog: (input: boolean, path?: string) => {
    dispatch(assetBuilderActions.setSaveDialog({ input, newPath: path }));
  },
  setEditOfferData: (
    offerData: OfferData,
    editedKeys: Record<string, boolean>,
    operation?: OfferOperationMode,
  ) => {
    dispatch(
      assetBuilderActions.setEditOfferData({
        rawOfferData: offerData,
        editedKeys: editedKeys,
        operation: operation,
      }),
    );
  },
  resetToMasterData: (vin: string) =>
    dispatch(assetBuilderActions.resetToMasterData(vin)),
  fetchOfferList: (parameter: IQueryParameters) =>
    dispatch(assetBuilderActions.fetchOfferList(parameter)),
  resetOfferData: () => dispatch(assetBuilderActions.resetOfferData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditOffer);
