import {
  IUploadImagesForm,
  IUploadImagesResponse,
  TUploadLogosS3DictionaryKey,
} from "shared/types/uploadManagement";
import { ILogos } from "shared/types/brandsAccounts";
import { LogoUrlsFromS3 } from "shared/types/brandManagement";
import { getErrorMessage } from "utils/errorMessage";
import API from "services";

export const uploadImagesToS3 = async (
  logos: ILogos,
  destination: string,
  existingLogos?: ILogos,
) => {
  try {
    const uploadedLogosByType = {
      horizontalImagesFromS3: existingLogos?.horizontalLogos.length
        ? existingLogos.horizontalLogos.map(
            horizontalLogo => horizontalLogo.url,
          )
        : [],
      verticalImagesFromS3: existingLogos?.verticalLogos.length
        ? existingLogos.verticalLogos.map(verticalLogo => verticalLogo.url)
        : [],
      squareImagesFromS3: existingLogos?.squareLogos.length
        ? existingLogos.squareLogos.map(squareLogo => squareLogo.url)
        : [],
      horizontalEventImagesFromS3: [],
      verticalEventImagesFromS3: [],
      squareEventImagesFromS3: [],
    } as LogoUrlsFromS3;

    const { uploadImages } = API.services.uploadManagement;

    const uploadLogosPromises: Promise<IUploadImagesResponse>[] = [];

    Object.entries(logos).forEach(([key]) => {
      const uploadImagesForm: IUploadImagesForm = {
        files: logos[key as keyof ILogos].map(image => ({
          filename: image.name,
          fileType: image.type,
          imageData: image.thumbUrl,
          featureName: destination,
        })),
      };

      uploadLogosPromises.push(
        uploadImages<IUploadImagesResponse>(uploadImagesForm),
      );
    });

    const uploadLogosResponsesByType = await Promise.all(uploadLogosPromises);

    uploadLogosResponsesByType.forEach((uploadLogosResponse, index) => {
      const logoTypeKey = Object.keys(logos)[index];
      const { error, result: responseResult } = uploadLogosResponse;

      if (error) throw new Error(error.message || "Error uploading logos");
      const imagesWithError = responseResult?.images?.filter(
        image => image["error"],
      );
      if (imagesWithError?.length) throw new Error("Error uploading logos");

      if (responseResult?.images?.length)
        uploadedLogosByType[parseLogoKey(logoTypeKey as keyof ILogos)].push(
          ...responseResult.images.map(image => image.imageUrl),
        );
    });

    return uploadedLogosByType;
  } catch (error) {
    const errMsg = getErrorMessage(error);
    throw new Error(errMsg);
  }
};

const parseLogoKey = (key: keyof ILogos) => {
  const keyDictionary = {
    horizontalLogos: "horizontalImagesFromS3",
    verticalLogos: "verticalImagesFromS3",
    squareLogos: "squareImagesFromS3",
  };

  return keyDictionary[key] as TUploadLogosS3DictionaryKey;
};
