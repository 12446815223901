import { Space, Typography } from "antd";
import DealerManageUrls from "screens/dealerManagement/dealerDrawer/DealerManageUrls";
import { IAccountRecord, AccountUrl } from "shared/types/accountManagement";

type StoreManagementLabelDrawerContentProps = {
  dealer: IAccountRecord;
  labelsToFix: AccountUrl[];
  updateDealer: (dealer: IAccountRecord) => void;
};

export const StoreManagementLabelDrawerContent = ({
  dealer,
  labelsToFix,
  updateDealer,
}: StoreManagementLabelDrawerContentProps) => (
  <Space direction="vertical">
    <Space>
      <Typography.Title level={5}>
        <strong>{dealer.dealerName}</strong>: Manage URLs
      </Typography.Title>
    </Space>
    <Typography.Text>
      Please add the URLs below. You can change them later in{" "}
      <Typography.Link
        target={"_blank"}
        href={`/store-management?store_key=${dealer.key}`}
      >
        Admin / Store Management
      </Typography.Link>
    </Typography.Text>
    <DealerManageUrls
      dealerToEditWithLogos={dealer}
      setDealerToEditWithLogos={updateDealer}
      disabledLabels={labelsToFix.map(label => label.name)}
      highlightErrors
    />
  </Space>
);
