import { memo } from "react";
import _omit from "lodash/omit";
import _isNil from "lodash/isNil";

interface IToggleDisplayProps extends React.HTMLAttributes<any> {
  if?: boolean;
  show?: boolean;
  hide?: boolean;
  tag?: string;
  children?: React.ReactNode;
}

function shouldHide(props: IToggleDisplayProps) {
  if (!_isNil(props.show)) {
    return !props.show;
  } else if (!_isNil(props.hide)) {
    return props.hide;
  }
  return false;
}

const ToggleDisplay = (props: IToggleDisplayProps) => {
  if (props.if === false) {
    return null;
  }

  const style: React.CSSProperties = shouldHide(props)
    ? {
        display: "none",
      }
    : {};

  const Tag = (props.tag || "div") as keyof JSX.IntrinsicElements;

  // prevent our props from being leaked down onto the children
  const newProps = _omit(props, ["tag", "if", "show", "hide", "style"]);

  return <Tag style={{ ...props.style, ...style }} {...newProps} />;
};

export default memo(ToggleDisplay);
