import {
  setAdLoadState,
  toggleIsAdLoadVisible,
  setSelectedFacebookAccounts,
} from "redux/adLibrary/adLibrary.slice";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { useAppShallowEqualSelector } from "shared/hooks/useAppSelector";
import { isEmpty } from "lodash";

import { initAdLoadParameters } from "screens/adLibrary/adLoad/utils";
import { getLoadStepFromUrlParams } from "screens/adLibrary/adLoad/adLoadDrawer/shared/utils";

import {
  IAdLoad,
  AdLoadStepKey,
  IAdHistorySession,
} from "shared/types/adLibrary";
import { IFacebookAccount } from "screens/adLibrary/facebookUtils/types";
import { useAds } from "./useAds";

type HandleLoadSessionDataArgs = {
  session?: IAdHistorySession;
  sessionJsonData?: IAdLoad;
};

const useHandleLoadSessionData = () => {
  const dispatch = useAppDispatch();
  const { selectedItems: selectedAds } = useAds();

  const { currentStep, selectedAccounts = [] } = useAppShallowEqualSelector(
    ({ adLibrary }) => ({
      currentStep: adLibrary.adLoad.currentStep,
      selectedAccounts: adLibrary.selectedFacebookAccounts,
    }),
  );

  return (args: HandleLoadSessionDataArgs) => {
    if (!args.session) return;

    const accountsToUse = (
      isEmpty(args.session.accounts?.facebook)
        ? selectedAccounts
        : args.session.accounts?.facebook
    ) as IFacebookAccount[];

    if (args.sessionJsonData) {
      dispatch(setSelectedFacebookAccounts(accountsToUse || null));
      const adLoadStateFromJson: IAdLoad = {
        ...args.sessionJsonData,
        currentStep: currentStep || getLoadStepFromUrlParams(location.search),
      };
      dispatch(setAdLoadState({ data: adLoadStateFromJson }));
      dispatch(toggleIsAdLoadVisible(true));
    } else {
      dispatch(setSelectedFacebookAccounts(accountsToUse || null));
      dispatch(
        setAdLoadState({
          data: {
            selectedAds: selectedAds || null,
            currentStep: AdLoadStepKey.DESTINATION_SELECTION,
            adLoadHistory: {
              sessionId: args.session.id,
              title: args.session.name,
            },
            destination: { selectedAds },
            adLoadParameters: initAdLoadParameters,
          } as IAdLoad,
        }),
      );
    }
  };
};

export default useHandleLoadSessionData;
