import { FeedConfigState } from "shared/types/assetExporter";

export const FEED_CONFIG_DEFAULT: FeedConfigState = {
  columns: null,
  columnsToExport: [],
  feedRows: [],
  imageInput: "",
  imageOutput: "",
  orderedColumns: [],
  videoInput: "",
  videoEndInput: "",
  videoOutput: "",
  videoFrameStart: "",
  videoFrameEnd: "",
  videoFrameRate: "",
};
