import { intersection } from "lodash";
import { MIXED_VALUE } from "../constants";

const getUniqueValueOrMixed = (arr: string[]): string => {
  return arr.length && arr.every(v => v === arr[0]) ? arr[0] : MIXED_VALUE;
};

const getIntersectionValues = (values: string[]): string => {
  const arrays = values.map(val => {
    if (typeof val === "string" && val.length) {
      return val.split(",").map(v => v.trim());
    }

    return [];
  });
  const common = intersection(...arrays);
  const hasMixed = arrays.some(arr => arr.length !== common.length);
  if (hasMixed) {
    common.unshift(MIXED_VALUE);
  }

  return common.join();
};

const getMapFn = (field: string) => {
  return (schema: WDMetaDataSchema) => {
    if (field === "keyword" || schema.type === "multiselect") {
      return getIntersectionValues;
    }

    return getUniqueValueOrMixed;
  };
};

export const getConfig = (schemas: MGSchemas): MGAssetsMetaConfig => {
  return Object.fromEntries(
    schemas.xmpschema.map(({ field }) => {
      return [
        field,
        {
          mapTo: getMapFn(field),
        },
      ];
    }),
  );
};
