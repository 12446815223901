import { IConfig } from "shared/types/configuration";
import { IStamp } from "shared/types/designStudio";
import {
  IMessagingObjectType,
  IStateDisclosureRecord,
  IStateExceptionRecord,
  GetDisclosureQueryParams,
  IGetDisclosureResponseData,
} from "shared/types/legalLingo";
import { IApiResponse } from "shared/types/shared";
import HttpClient from "./httpClient";

export default ({ headers, config }: { headers: any; config: IConfig }) => ({
  getMessagesandExceptions: <T>(): Promise<T> => {
    const { services } = config;
    const getMessagesandExceptionsUrl =
      services.legal.getMessagesandExceptionsUrl;
    return fetch(getMessagesandExceptionsUrl, {
      method: "GET",
      headers,
    }).then<T>(response => {
      return response.json();
    });
  },
  saveMessagesandExceptions: <T>(
    messages: any,
    offer: string,
    oem: string,
    state: string,
  ): Promise<T> => {
    const { services } = config;
    const saveMessagesandExceptionsUrl =
      services.legal.saveMessagesandExceptionsUrl;
    return fetch(saveMessagesandExceptionsUrl, {
      method: "PUT",
      headers,
      body: JSON.stringify({
        messages,
        offer,
        oem,
        state,
      }),
    }).then<T>(response => {
      return response.json();
    });
  },
  addOrDeleteOemToDynamoDb: <T>(oem: any, action: string): Promise<T> => {
    const { services } = config;
    const addOrDeleteOemToDynamoDbUrl =
      services.legal.addOrDeleteOemToDynamoDbUrl;
    return fetch(addOrDeleteOemToDynamoDbUrl, {
      method: "POST",
      headers,
      body: JSON.stringify({
        oem,
        action,
      }),
    }).then<T>(response => {
      return response.json();
    });
  },
  getStateTablesLegalLingos: <T>(): Promise<T> => {
    const { services } = config;
    const getStateTablesLegalLingosUrl =
      services.legal.getStateTablesLegalLingosUrl;

    return HttpClient.get(getStateTablesLegalLingosUrl);
  },
  updateStateTablesLegalLingos: <T>(listToUpdate: any): Promise<T> => {
    const { services } = config;

    const updateStateTablesLegalLingosUrl =
      services.legal.updateStateTablesLegalLingosUrl;

    return HttpClient.put(updateStateTablesLegalLingosUrl, {
      listToUpdate,
    });
  },
  getOemTablesLegalLingos: <T>(): Promise<T> => {
    const { services } = config;
    const getOemTablesLegalLingosUrl =
      services.legal.getOemTablesLegalLingosUrl;

    return HttpClient.get(getOemTablesLegalLingosUrl);
  },
  updateOemTablesLegalLingos: <T>(listToUpdate: any): Promise<T> => {
    const { services } = config;

    const updateOemTablesLegalLingosUrl =
      services.legal.updateOemTablesLegalLingosUrl;

    return fetch(updateOemTablesLegalLingosUrl, {
      method: "PUT",
      headers,
      body: JSON.stringify({
        listToUpdate,
      }),
    }).then<T>(response => {
      return response.json();
    });
  },
  getStateDisclosuresByState: <T>(state: string): Promise<T> => {
    const { services } = config;
    const getStateDisclosuresUrl = `${
      services.legal.getStateDisclosuresUrl
    }?state=${state.toLowerCase()}`;

    return HttpClient.get(getStateDisclosuresUrl);
  },
  getStateExceptionsByStateAndOem: <T>(
    state: string,
    oem: string,
  ): Promise<T> => {
    const { services } = config;
    const getStateExceptionsUrl = `${
      services.legal.getStateExceptionsUrl
    }?state=${state.toLowerCase()}&oem=${oem}`;

    return HttpClient.get(getStateExceptionsUrl);
  },
  createStateDisclosure: <T>(
    inputStateDisclosure: IStateDisclosureRecord,
  ): Promise<T> => {
    const { services } = config;

    const createStateDisclosureUrl = services.legal.createStateDisclosureUrl;
    return HttpClient.post(createStateDisclosureUrl, {
      stateDisclosure: inputStateDisclosure,
    });
  },
  createStateException: <T>(
    inputStateException: IStateExceptionRecord,
  ): Promise<T> => {
    const { services } = config;

    const createStateExceptionUrl = services.legal.createStateExceptionUrl;
    return fetch(createStateExceptionUrl, {
      method: "POST",
      headers,
      body: JSON.stringify({
        stateDisclosure: inputStateException,
      }),
    }).then<T>(response => {
      return response.json();
    });
  },

  getMessageForStamp: async (
    stamp: IStamp,
  ): Promise<{
    error: null | {
      message: string;
    };
    result: {
      oem: string;
      messaging: IMessagingObjectType; // made it optional because this might be {} if data not found
    }[];
  }> => {
    const url = `${config.services.legal.getMessageUrl}?offerType=${
      stamp.offerType || ""
    }&oems=${stamp.oems.join(",")}&state=${stamp.state || ""}`;
    return HttpClient.get(url);
  },
  getDisclosure: (
    args?: GetDisclosureQueryParams,
  ): Promise<IApiResponse<IGetDisclosureResponseData>> => {
    return HttpClient.get(config.services.legal.getDisclosureUrl(args));
  },
  getDisclosures: <T>(): Promise<T> => {
    const { services } = config;
    const getDisclosuresUrl = services.legal.getDisclosuresUrl;
    return fetch(getDisclosuresUrl, {
      method: "GET",
      headers,
    }).then<T>(response => {
      return response.json();
    });
  },
});
