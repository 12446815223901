import { CloseOutlined, ImportOutlined } from "@ant-design/icons";
import { Button, notification, Progress } from "antd";
import styles from "./ProcessingImportNotification.module.scss";

export interface ImportNotificationProps {
  columnsIgnored: number;
  importedItems: number;
  totalItems: number;
  notificationKey: string;
  handleCancel: () => void;
  handleReview: () => void;
  itemLabel: string;
}

const CLOSE_ICON_CLASS = "import-ads-close-icon";

export const openProcessingImportNotification = (
  props: ImportNotificationProps,
) => {
  setDisplayCloseIcon(true);

  const { notificationKey, totalItems, importedItems, handleCancel } = props;
  const finishedImporting = importedItems === totalItems;
  notification.open({
    key: notificationKey,
    icon: <ImportOutlined className={styles.importIcon} />,
    message: "Importing File",
    description: (
      <Description {...props} finishedImporting={finishedImporting} />
    ),
    duration: null,
    className: styles.notification,
    closeIcon: <CloseIcon />,
    placement: "bottomRight",
    onClose: handleCancel,
  });
};

/**
 * Sets the display attribute to "block" of "none", depending on the value of the passed in boolean.
 * Using this vanilla JavaScript approach because it's not working with React code.
 */
export const setDisplayCloseIcon = (display: boolean) => {
  const closeIcon = document.querySelector<HTMLElement>("." + CLOSE_ICON_CLASS);

  if (closeIcon) {
    closeIcon.style.display = display ? "block" : "none";
  }
};

interface StatisticsRowProps {
  label: string;
  value: string;
}

const StatisticsRow = ({ label, value }: StatisticsRowProps) => (
  <div className={styles.statisticsRow}>
    <div>{label}</div>
    <div>{value}</div>
  </div>
);

interface DescriptionProps extends ImportNotificationProps {
  finishedImporting: boolean;
}

const Description = ({
  columnsIgnored,
  importedItems,
  totalItems,
  notificationKey,
  finishedImporting,
  handleCancel,
  handleReview,
  itemLabel,
}: DescriptionProps) => {
  const percentOfImportedItems = Math.round((importedItems * 100) / totalItems);

  const buttonText = finishedImporting
    ? "Review and Complete Import"
    : "Cancel Import";

  const buttonHandler = () => {
    notification.close(notificationKey);
    finishedImporting ? handleReview() : handleCancel();
  };

  return (
    <div>
      <div className={styles.statistics}>
        <StatisticsRow
          label={`${itemLabel} Imported`}
          value={`${importedItems}/${totalItems}`}
        />
        {/* <StatisticsRow label={"Errors Detected"} value={"Not Defined Yet"} /> */}
        <StatisticsRow
          label={"Columns Ignored"}
          value={columnsIgnored.toString()}
        />

        <Button
          type="link"
          onClick={buttonHandler}
          className={styles.statisticsBtn}
        >
          {buttonText}
        </Button>
      </div>
      <Progress
        percent={percentOfImportedItems}
        status={finishedImporting ? "success" : "active"}
      />
    </div>
  );
};

const CloseIcon = () => (
  <div className={CLOSE_ICON_CLASS}>
    <CloseOutlined />
  </div>
);
