import { RcFile, UploadFile } from "antd/lib/upload/interface";
import {
  validateFile,
  validateLocalFile,
} from "shared/components/uploadAsset/UploadAsset.utils";
import { IFile } from "shared/types/uploadAsset";

export const isLocalFile = (
  file: UploadFile<IFile> | UploadFile<RcFile> | RcFile,
): file is UploadFile<RcFile> | RcFile => {
  return file.uid.startsWith("rc-upload");
};

export const imageRatioValidator = async (
  file: UploadFile<IFile> | UploadFile<RcFile> | RcFile,
  ratio: number,
) => {
  try {
    if (isLocalFile(file)) {
      await validateLocalFile(file, ratio);
    } else {
      await validateFile(file, ratio);
    }
    return true;
  } catch (err) {
    return false;
  }
};
