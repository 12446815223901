import { Col, Form, Input, message, Row } from "antd";
import InputTextField from "antd/lib/input/Input";
import { FC, useCallback, useRef } from "react";
import { FeedType } from "shared/types/configuration";
import { IAccountRecord } from "shared/types/accountManagement";
import {
  IFormValidationAttributes,
  OperationMode,
} from "shared/types/inputValues";
import { industryType } from "utils/helpers";
import * as validators from "../../../utils/validators";

import { CAM_ENABLED } from "shared/components/media";
import MultipleBrandSelect from "shared/components/MultipleBrandSelect";
import { GenericLocationSelect } from "shared/components/select/GenericLocationSelect";
import {
  ISingleLogoObject,
  IUploadImageFormInput,
  TUploadGroup,
  TUploadSetLogosInDictionary,
} from "shared/types/uploadManagement";
import { formatPhoneNumber, revertPhoneNumber } from "../../../utils/helpers";
import { MediaStoreLogos } from "../MediaStoreLogos";
import DealerLogos from "../DealerLogos";

interface IDealerDetailsProps {
  dealerNameValidationAttributes: IFormValidationAttributes;
  dealerCodeValidationAttributes: IFormValidationAttributes;
  dealerUrlValidationAttributes: IFormValidationAttributes;
  dealerToEditWithLogos: IAccountRecord;
  mode: OperationMode;
  feed: FeedType;
  s3AssetBucket: string;
  singleLogoObject: ISingleLogoObject;
  logosInDictionary: Record<TUploadSetLogosInDictionary, ISingleLogoObject[]>;
  disabled: boolean;
  isProcessing: boolean;
}
interface IDealerDetailsHandlers {
  setDealerNameValidationAttributes: (
    dealerNameValidationAttributes: IFormValidationAttributes,
  ) => void;
  setDealerCodeValidationAttributes: (
    dealerCodeValidationAttributes: IFormValidationAttributes,
  ) => void;
  setDealerUrlValidationAttributes: (
    dealerUrlValidationAttributes: IFormValidationAttributes,
  ) => void;

  setDealerToEditWithLogos: (dealerToEditWithLogos: IAccountRecord) => void;
  setSingleLogoObject: (singleLogoObject: ISingleLogoObject) => void;
  setLogosInDictionary: (
    logosInDictionary: Record<TUploadSetLogosInDictionary, ISingleLogoObject[]>,
  ) => void;

  uploadImages: (
    imagesToUpload: IUploadImageFormInput[],
    featureName: string,
    group: TUploadGroup,
    mode: OperationMode,
    logoKeysToBeUploaded?: string[],
    newOemButtonClicked?: boolean,
  ) => void;
}

const DealerDetails: FC<IDealerDetailsProps & IDealerDetailsHandlers> = ({
  dealerNameValidationAttributes,
  dealerCodeValidationAttributes,
  dealerUrlValidationAttributes,

  dealerToEditWithLogos,
  singleLogoObject,
  logosInDictionary,

  mode,
  feed,
  s3AssetBucket,
  disabled,

  setDealerNameValidationAttributes,
  setDealerCodeValidationAttributes,
  setDealerUrlValidationAttributes,
  setDealerToEditWithLogos,
  setSingleLogoObject,
  setLogosInDictionary,
  uploadImages,
}) => {
  const dealerNameFieldRef = useRef<InputTextField>(null);
  const dealerCodeFieldRef = useRef<InputTextField>(null);
  const dealerUrlFieldRef = useRef<InputTextField>(null);

  const onlyNumbersRegex = /^\d+(\d+)?$/;

  const handlePhoneNumberChange = (newPhoneNumber: string) => {
    setDealerToEditWithLogos({
      ...dealerToEditWithLogos,
      dealerPhoneNumber: newPhoneNumber,
    });
  };

  const handlePhoneNumberPaste = (
    event: React.ClipboardEvent<HTMLInputElement>,
  ) => {
    event.preventDefault();
    const { clipboardData, currentTarget } = event;
    const { value: phoneNumberVal = "" } = currentTarget;
    const numberToPaste = clipboardData.getData("text/plain");

    let replacedNumbers = revertPhoneNumber(numberToPaste);

    const validUnformattedDigits =
      onlyNumbersRegex.test(replacedNumbers) && replacedNumbers.length === 10;

    if (!validUnformattedDigits) {
      message.warning(
        "Paste failed. The text does not comply with formats: (###) ###-#### or ##########",
      );
      replacedNumbers = phoneNumberVal;
    }

    handlePhoneNumberChange(replacedNumbers);
  };

  const handlePhoneNumberCopy = (
    event: React.ClipboardEvent<HTMLInputElement>,
  ) => {
    event.preventDefault();
    const { clipboardData, currentTarget } = event;
    const { value: phoneNumberVal = "" } = currentTarget;
    clipboardData.setData("text/plain", formatPhoneNumber(phoneNumberVal));
  };

  const handlePhoneNumberCut = (
    event: React.ClipboardEvent<HTMLInputElement>,
  ) => {
    event.preventDefault();

    const { currentTarget, clipboardData } = event;
    const { value, selectionStart, selectionEnd } = currentTarget;

    const cutString = value.substr(
      selectionStart || 0,
      selectionEnd || value.length,
    );

    if (cutString.length === 10) {
      clipboardData.setData("text/plain", formatPhoneNumber(cutString));
    }

    handlePhoneNumberChange(value.replace(cutString, ""));
  };

  const isForAuto = industryType("auto") || industryType("all");

  const selectedBrands = !!dealerToEditWithLogos.dealerOem
    ? dealerToEditWithLogos.dealerOem.split(",")
    : [];
  const onSelectBrands = useCallback(
    (newselectedBrands: string[]): void =>
      setDealerToEditWithLogos({
        ...dealerToEditWithLogos,
        dealerOem: newselectedBrands.join(","),
      }),
    [dealerToEditWithLogos, setDealerToEditWithLogos],
  );

  return (
    <div>
      <Form layout="vertical" hideRequiredMark={true}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label={
                isForAuto ? "Enter Location Name (DBA)" : "Enter Account Name"
              }
              validateStatus={dealerNameValidationAttributes.validationStatus}
              hasFeedback={dealerNameValidationAttributes.hasFeedback}
              help={dealerNameValidationAttributes.feedbackMessage}
            >
              <Input
                data-cy="dealer-name-input"
                ref={dealerNameFieldRef}
                key="dealer-name-input"
                disabled={disabled}
                required={true}
                value={
                  mode === "UPDATE" && dealerToEditWithLogos.newDealerName
                    ? dealerToEditWithLogos.newDealerName
                    : dealerToEditWithLogos.dealerName
                }
                onChange={event => {
                  const { target } = event;
                  const { value: updatedDealerName } = target || {
                    value: undefined,
                  };

                  const { timeoutId } = dealerNameValidationAttributes;

                  if (timeoutId) {
                    clearTimeout(timeoutId);
                  }

                  if (updatedDealerName === "") {
                    setDealerNameValidationAttributes({
                      timeoutId: undefined,
                      validationStatus: undefined,
                      hasFeedback: undefined,
                      feedbackMessage: "",
                    });
                  } else {
                    const newTimeoutId = setTimeout(() => {
                      const isDealerNameValid =
                        validators.isNameInputValid(updatedDealerName);

                      setDealerNameValidationAttributes({
                        ...dealerNameValidationAttributes,
                        validationStatus: isDealerNameValid
                          ? "success"
                          : "error",
                        hasFeedback: true,
                        feedbackMessage: isDealerNameValid
                          ? "valid Store Name (DBA)"
                          : "Invalid Store Name (DBA). Make sure there are no consecutive spaces.",
                      });
                    }, 300);

                    setDealerNameValidationAttributes({
                      timeoutId: newTimeoutId,
                      validationStatus: "validating",
                      hasFeedback: true,
                      feedbackMessage: "",
                    });
                  }

                  const updatedDealer =
                    mode === "UPDATE"
                      ? {
                          ...dealerToEditWithLogos,
                          newDealerName: updatedDealerName,
                        }
                      : {
                          ...dealerToEditWithLogos,
                          dealerName: updatedDealerName,
                        };

                  setDealerToEditWithLogos(updatedDealer);
                }}
              />
            </Form.Item>
          </Col>
          {isForAuto && (
            <Col span={12}>
              <Form.Item
                label={
                  feed !== "ladtech"
                    ? "Enter Location Name (Dealer ID)"
                    : "Enter Location Name (2-3-3)"
                }
                validateStatus={dealerCodeValidationAttributes.validationStatus}
                hasFeedback={dealerCodeValidationAttributes.hasFeedback}
                help={dealerCodeValidationAttributes.feedbackMessage}
              >
                <Input
                  data-cy="dealer-code-input"
                  ref={dealerCodeFieldRef}
                  key="dealer-code-input"
                  disabled={disabled}
                  required={true}
                  value={dealerToEditWithLogos.dealerCode}
                  onChange={(event: any) => {
                    const { target } = event;
                    const { value: updatedDealerCode } = target || {
                      value: undefined,
                    };

                    const { timeoutId } = dealerCodeValidationAttributes;

                    if (timeoutId) {
                      clearTimeout(timeoutId);
                    }

                    if (updatedDealerCode === "") {
                      setDealerCodeValidationAttributes({
                        timeoutId: undefined,
                        validationStatus: undefined,
                        hasFeedback: undefined,
                        feedbackMessage: "",
                      });
                    } else {
                      const newTimeoutId = setTimeout(() => {
                        if (feed === "ladtech") {
                          const isDealerCodeValid =
                            updatedDealerCode.length === 8;

                          setDealerCodeValidationAttributes({
                            ...dealerCodeValidationAttributes,
                            validationStatus: isDealerCodeValid
                              ? "success"
                              : "error",
                            hasFeedback: true,
                            feedbackMessage: isDealerCodeValid
                              ? "valid Store Name (2-3-3)"
                              : "Invalid Store Name (2-3-3). The code must be 8 characters long.",
                          });
                        } else {
                          setDealerCodeValidationAttributes({
                            ...dealerCodeValidationAttributes,
                            validationStatus:
                              updatedDealerCode.length > 0
                                ? "success"
                                : "error",
                            hasFeedback: true,
                            feedbackMessage:
                              updatedDealerCode.length > 0
                                ? "valid Store Name (Dealer ID)"
                                : "Invalid Store Name (Dealer ID). Please enter a code",
                          });
                        }
                      }, 300);

                      setDealerCodeValidationAttributes({
                        timeoutId: newTimeoutId,
                        validationStatus: "validating",
                        hasFeedback: true,
                        feedbackMessage: "",
                      });
                    }

                    setDealerToEditWithLogos({
                      ...dealerToEditWithLogos,
                      dealerCode: updatedDealerCode,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Enter an Address">
              <Input
                key="dealer-address-input"
                value={dealerToEditWithLogos.dealerAddress}
                disabled={disabled}
                onChange={({ target }) => {
                  const { value: addressVal } = target;
                  setDealerToEditWithLogos({
                    ...dealerToEditWithLogos,
                    dealerAddress: addressVal,
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Enter a City">
              <Input
                data-cy="dealer-city-input"
                key="dealer-city-input"
                disabled={disabled}
                value={dealerToEditWithLogos.dealerCity}
                onChange={({ target }) => {
                  const { value: cityValue } = target;
                  setDealerToEditWithLogos({
                    ...dealerToEditWithLogos,
                    dealerCity: cityValue,
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Select Location">
              <GenericLocationSelect
                disabled={disabled}
                defaultValue={dealerToEditWithLogos.dealerUsState}
                value={dealerToEditWithLogos.dealerUsState}
                onChange={(value: string) => {
                  setDealerToEditWithLogos({
                    ...dealerToEditWithLogos,
                    dealerUsState: value,
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Enter a Zip Code">
              <Input
                key="dealer-zip-input"
                value={dealerToEditWithLogos.dealerZip}
                disabled={disabled}
                onChange={({ target }) => {
                  const { value: zipValue } = target;
                  if (
                    zipValue &&
                    (zipValue.length > 5 || !onlyNumbersRegex.test(zipValue))
                  ) {
                    return;
                  }
                  setDealerToEditWithLogos({
                    ...dealerToEditWithLogos,
                    dealerZip: zipValue,
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Enter URL"
              validateStatus={dealerUrlValidationAttributes.validationStatus}
              hasFeedback={dealerUrlValidationAttributes.hasFeedback}
              help={dealerUrlValidationAttributes.feedbackMessage}
            >
              <Input
                data-cy="dealer-url-input"
                ref={dealerUrlFieldRef}
                key="dealer-url-input"
                disabled={disabled}
                value={dealerToEditWithLogos.dealerUrl}
                onChange={(event: any) => {
                  const { target } = event;
                  const { value: updatedDealerUrl } = target || {
                    value: undefined,
                  };

                  const { timeoutId } = dealerNameValidationAttributes;

                  if (timeoutId) {
                    clearTimeout(timeoutId);
                  }

                  if (updatedDealerUrl === "") {
                    setDealerUrlValidationAttributes({
                      timeoutId: undefined,
                      validationStatus: undefined,
                      hasFeedback: undefined,
                      feedbackMessage: "",
                    });
                  } else {
                    const newTimeoutId = setTimeout(() => {
                      const isDealerNameValid =
                        validators.isUrlInputValid(updatedDealerUrl) &&
                        !updatedDealerUrl.endsWith("/");

                      setDealerUrlValidationAttributes({
                        ...dealerNameValidationAttributes,
                        validationStatus: isDealerNameValid
                          ? "success"
                          : "error",
                        hasFeedback: true,
                        feedbackMessage: isDealerNameValid
                          ? "valid Store URL"
                          : "A Valid Store URL requires http:// or https://, a .suffix, and must not end with a /",
                      });
                    }, 300);

                    setDealerUrlValidationAttributes({
                      timeoutId: newTimeoutId,
                      validationStatus: "validating",
                      hasFeedback: true,
                      feedbackMessage: "",
                    });
                  }
                  setDealerToEditWithLogos({
                    ...dealerToEditWithLogos,
                    dealerUrl: updatedDealerUrl,
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Enter a Phone number">
              <Input
                type="tel"
                maxLength={10}
                key="dealer-phone-input"
                disabled={disabled}
                value={dealerToEditWithLogos.dealerPhoneNumber}
                onCut={handlePhoneNumberCut}
                onCopy={handlePhoneNumberCopy}
                onPaste={handlePhoneNumberPaste}
                onBlur={() => {
                  const formattedPhoneNumber = formatPhoneNumber(
                    dealerToEditWithLogos.dealerPhoneNumber,
                  );
                  handlePhoneNumberChange(formattedPhoneNumber);
                }}
                onFocus={() => {
                  const reverted = revertPhoneNumber(
                    dealerToEditWithLogos.dealerPhoneNumber,
                  );
                  handlePhoneNumberChange(reverted);
                }}
                onChange={({ target }) => {
                  const { value: phoneNumberVal } = target;
                  // regex for only allowing string like "1234567890" to pass
                  if (
                    phoneNumberVal &&
                    !onlyNumbersRegex.test(phoneNumberVal)
                  ) {
                    return;
                  }
                  handlePhoneNumberChange(phoneNumberVal);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        {isForAuto && (
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Enter a Final Price Name">
                <Input
                  key="dealer-final-price-name-input"
                  value={dealerToEditWithLogos.dealerFinalPriceName}
                  disabled={disabled}
                  onChange={event => {
                    const { target } = event;
                    const { value: finalPriceNamevVal } = target || {
                      value: "",
                    };
                    setDealerToEditWithLogos({
                      ...dealerToEditWithLogos,
                      dealerFinalPriceName: finalPriceNamevVal,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24}>
            <Form.Item label={"Select or Multi-Select Brand(s)"}>
              <MultipleBrandSelect
                selectedBrands={selectedBrands}
                onChange={onSelectBrands}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>
        {CAM_ENABLED ? (
          <MediaStoreLogos record={dealerToEditWithLogos} />
        ) : (
          <DealerLogos
            mode={mode}
            s3AssetBucket={s3AssetBucket}
            singleLogoObject={singleLogoObject}
            setSingleLogoObject={setSingleLogoObject}
            logosInDictionary={logosInDictionary}
            setLogosInDictionary={setLogosInDictionary}
            uploadImages={uploadImages}
            dealerToEditWithLogos={dealerToEditWithLogos}
            setDealerToEditWithLogos={setDealerToEditWithLogos}
          />
        )}
      </Form>
    </div>
  );
};

export default DealerDetails;
