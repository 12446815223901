import { useSearch } from "../useSearch";
import { Results } from "./Results";
import { Search } from "./Search";

type Props = {
  initParams?: MGSearchObj;
  onSelect: (url: string) => void;
};

export const CAMTableSelect = ({ initParams, onSelect }: Props) => {
  const { params, setFields, data, isFetching, setPage } = useSearch({
    initParams,
    rows: 50,
    withMeta: true,
  });

  return (
    <div style={{ width: 900 }}>
      <Search fields={params} onChange={setFields} />
      <Results
        data={data}
        isFetching={isFetching}
        onSelect={onSelect}
        onPageChange={setPage}
      />
    </div>
  );
};
