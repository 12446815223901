import { useEffect, useState } from "react";
import { useCAMSearch } from "../media";

export const useFetchLogos = (payload?: MGSearch) => {
  const searchMedia = useCAMSearch({ rows: 1000 });
  const [assets, setAssets] = useState<WDAsset[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (payload) {
      setIsLoading(true);
      searchMedia(payload)
        .then(data => setAssets(data.items))
        .finally(() => setIsLoading(false));
    }
  }, [payload, searchMedia]);

  return { isLoading, assets };
};
