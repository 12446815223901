import API from "services";
import { useFeedContext } from "../../shared/contexts/FeedContext";
import { usePortalExportContext } from "./PortalExportContext";
import { useQuery, useQueryClient } from "react-query";
import { AppState } from "redux/store";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { useRef } from "react";

export const useMetadataValidation = () => {
  const queryClient = useQueryClient();
  const { feedId, selectedRows } = useFeedContext();
  const { formValues } = usePortalExportContext();

  const { feedColumns } = formValues;
  const user = useAppSelector((state: AppState) => state.auth.user);
  const { selectedAssetBatches } = useFeedContext();

  const previousFeedColumnsRef = useRef<string[]>([]);

  const validateMetadata = async (
    columnNames: string[],
    createdBy: string,
    assetBatchIds: string[],
  ) => {
    try {
      const { result: isValid } =
        await API.services.assetExporter.validatePortalExportMetadata({
          feedId,
          columnNames,
          rowIdentifiers: selectedRows,
          createdBy,
          assetBatchIds,
        });

      return isValid;
    } catch (error) {
      return false;
    }
  };

  const validateMetadataQuery = useQuery(
    ["validateMetadata", feedColumns, user.email, selectedAssetBatches],
    async () => {
      await queryClient.cancelQueries([
        "validateMetadata",
        previousFeedColumnsRef.current,
        user.email,
        selectedAssetBatches,
      ]);
      previousFeedColumnsRef.current = feedColumns;

      return validateMetadata(feedColumns, user.email, selectedAssetBatches);
    },
    {
      enabled: !!feedColumns.length,
    },
  );

  return validateMetadataQuery;
};
