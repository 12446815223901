import { memo } from "react";
import RuleConfig from "../rulePanel/RuleConfig";
import RuleInput from "../rulePanel/RuleInput";
import { Button } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

import styles from "./RulePanel.module.scss";
import { FacebookAdPart, IRulesetOptionsObject } from "shared/types/adLibrary";
import useAdRulesets from "screens/adLibrary/shared/hooks/useAdRulesets";
import useTaxonomies from "screens/adLibrary/shared/hooks/useTaxonomies";
import useDelimiter from "screens/adLibrary/shared/hooks/useDelimiter";

interface IRulePanelProps {
  adPart: FacebookAdPart;
  adRuleOptions: IRulesetOptionsObject;
  isLoadingOptions: boolean;
  disabled?: boolean;
}

const RulePanel = ({
  adPart,
  adRuleOptions,
  isLoadingOptions,
  disabled = false,
}: IRulePanelProps) => {
  const { rules, addRule, clearRules, updateRule, deleteRule } = useAdRulesets({
    adPart,
  });
  const { taxonomies, updateTaxonomies } = useTaxonomies({ adPart });
  const { delimiter, updateDelimiter } = useDelimiter({ adPart });

  return (
    <>
      <RuleConfig
        taxonomies={taxonomies}
        updateTaxonomies={updateTaxonomies}
        delimiter={delimiter}
        updateDelimiter={updateDelimiter}
        disabled={disabled}
      />
      {rules.map((rule, index) => (
        <RuleInput
          key={index}
          rule={rule}
          updateRule={updatedRule => updateRule(rule.ids, updatedRule)}
          deleteRule={() => deleteRule(rule.ids)}
          adRuleOptions={adRuleOptions}
          isLoadingOptions={isLoadingOptions}
        />
      ))}
      <div className={styles.actionButtons}>
        <Button
          type="link"
          icon={<PlusOutlined />}
          size="middle"
          onClick={addRule}
          disabled={disabled}
        >
          Add rule
        </Button>
        <Button
          type="link"
          icon={<DeleteOutlined />}
          size="middle"
          onClick={clearRules}
          disabled={disabled || rules.length === 0}
        >
          Clear rules
        </Button>
      </div>
    </>
  );
};

export default memo(RulePanel);
