import {
  Checkbox,
  Image,
  Typography,
  Badge,
  BadgeProps,
  Space,
  Divider,
  Button,
  Tooltip,
  Radio,
} from "antd";
import styles from "./CardV2.module.scss";
import classNames from "classnames";
import EllipsisText from "./card/EllipsisText";
import { TooltipTag } from "screens/designStudio/designStudioLayout/shared/TooltipTag";
import ErrorImage from "statics/images/missing-thumbnail.png";
import { Fragment } from "react";

export type Action<T = any> = {
  type:
    | "create"
    | "edit"
    | "preview"
    | "properties"
    | "duplicate"
    | "publish"
    | "archive"
    | "delete"
    | "download"
    | "print"
    | "email";
  icon?: ((item?: T) => React.ReactNode) | React.ReactNode;
  title?: string;
  tooltipTitle?: (item?: T) => string;
  handler?: (item?: T) => () => void;
  disabled?: (item?: T) => boolean;
};

export type Props = {
  statusInfo?:
    | {
        color: BadgeProps["color"];
        title: string;
        tooltipInfo?: string;
      }
    | {
        status: BadgeProps["status"];
        title: string;
        tooltipInfo?: string;
      };
  tags: string[];
  actions?: Action[];
  isSelected?: boolean;
  onSelect?: (selected: boolean) => void;
  thumbnail?: string;
  title: string;
  infos?: string[];
  checkboxType?: "radio" | "checkbox" | "none";
  defaultAction?: () => void;
};

const Card = <T,>(props: Props & { item: T }) => {
  const {
    statusInfo,
    tags,
    actions,
    isSelected = false,
    onSelect,
    thumbnail,
    title,
    infos,
    checkboxType,
    defaultAction,
  } = props;
  const hasTags = (tags?.length ?? 0) > 0;
  const [first, second, ...rest] = tags ?? [];

  const clickableProps = defaultAction
    ? {
        onClick: defaultAction,
        style: {
          cursor: "pointer",
        },
      }
    : {};

  return (
    <div
      className={
        actions?.length
          ? classNames([
              styles.container,
              styles.containerActions,
              { [styles.selected]: isSelected },
            ])
          : styles.container
      }
    >
      <CardCheckbox
        type={checkboxType}
        isSelected={isSelected}
        onSelect={onSelect}
      />
      {statusInfo && (
        <Tooltip
          visible={statusInfo ? undefined : false}
          title={statusInfo?.tooltipInfo}
        >
          <div className={styles.status}>
            <Badge
              {...("status" in statusInfo
                ? { status: statusInfo.status }
                : { color: statusInfo.color })}
              className={styles.badge}
              text={statusInfo.title}
            />
          </div>
        </Tooltip>
      )}
      <div className={styles.imageContainer}>
        <Image
          width={"100%"}
          src={thumbnail ?? ErrorImage}
          fallback={ErrorImage}
          preview={false}
          {...clickableProps}
        />
      </div>
      <div className={styles.infoContainer}>
        <Typography.Text className={styles.name} {...clickableProps}>
          <EllipsisText text={title} charDisplayLimit={40} />
        </Typography.Text>
        <Space className={styles.details} align="baseline">
          {infos?.map((info, idx) => (
            <Fragment key={info}>
              {info && (
                <EllipsisText
                  className={styles.details}
                  text={info}
                  charDisplayLimit={8}
                />
              )}
              {idx < infos.length - 1 && (
                <Divider type="vertical" className={styles.divider} />
              )}
            </Fragment>
          ))}
        </Space>
        {hasTags && (
          <div>
            <TooltipTag title={first}>{first}</TooltipTag>
            {second && <TooltipTag title={second}>{second}</TooltipTag>}
            {rest.length > 0 && (
              <TooltipTag title={rest.join(", ")}>+{rest.length}</TooltipTag>
            )}
          </div>
        )}
      </div>

      {actions && (
        <div className={styles.actions}>
          {actions.map(action => (
            <Tooltip
              visible={action.tooltipTitle ? undefined : false}
              key={action.type}
              title={action.tooltipTitle?.(props.item)}
              overlayStyle={{ whiteSpace: "pre-line", maxWidth: "348px" }}
            >
              <Button
                disabled={action.disabled?.(props.item)}
                icon={
                  typeof action.icon === "function"
                    ? action.icon(props.item)
                    : action.icon
                }
                size="middle"
                onClick={action.handler?.(props.item)}
                className={styles.actionButton}
              >
                {action.title}
              </Button>
            </Tooltip>
          ))}
        </div>
      )}

      <div className={classNames(styles.hoverBorder)} />
    </div>
  );
};

const CardCheckbox = ({
  type,
  isSelected,
  onSelect,
}: {
  type?: "radio" | "checkbox" | "none";
  isSelected: boolean;
  onSelect?: (isSelected: boolean) => void;
}) => {
  if (type === "none") return null;
  return type === "checkbox" ? (
    <Checkbox
      className={classNames([
        styles.checkbox,
        { [styles.visible]: isSelected },
      ])}
      checked={isSelected}
      onClick={() => {
        if (window.getSelection()?.toString()) return;
        onSelect?.(!isSelected);
      }}
    />
  ) : (
    <Radio
      className={classNames([
        styles.checkbox,
        { [styles.visible]: isSelected },
      ])}
      checked={isSelected}
      onClick={() => {
        if (window.getSelection()?.toString()) return;
        onSelect?.(!isSelected);
      }}
    />
  );
};
export default Card;
