import { UploadFile } from "antd/lib/upload/interface";
import { useMemo } from "react";
import { IAccountRecord } from "shared/types/accountManagement";
import { IFile } from "shared/types/uploadAsset";

interface Props {
  accountToEdit: IAccountRecord;
}

export const useLogos = ({ accountToEdit }: Props) => {
  const logosEmpty = {
    horizontalLogos: [],
    squareLogos: [],
    verticalLogos: [],
  };
  const filterLogos = (logos: UploadFile<IFile>[], isOrigFileObj: boolean) =>
    logos.filter(logo => !!logo.originFileObj === isOrigFileObj);

  const parseBrandLogoToEdit = (imageUrl: string, index: number) => ({
    uid: `exising-logo-${index}`,
    name: `exising-logo-${index}`,
    url: imageUrl,
  });

  const initialLogoValues = useMemo(
    () => ({
      horizontalLogos:
        accountToEdit?.logoUrlsFromS3?.horizontalImagesFromS3.map(
          parseBrandLogoToEdit,
        ) || [],
      squareLogos:
        accountToEdit?.logoUrlsFromS3?.squareImagesFromS3.map(
          parseBrandLogoToEdit,
        ) || [],
      verticalLogos:
        accountToEdit?.logoUrlsFromS3?.verticalImagesFromS3.map(
          parseBrandLogoToEdit,
        ) || [],
    }),
    [accountToEdit],
  );

  return {
    filterLogos,
    initialLogoValues,
    logosEmpty,
  };
};
