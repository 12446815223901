import { useQuery } from "react-query";
import {
  generateAdPreview,
  getAdPrototypeFromAd,
} from "screens/adLibrary/facebookUtils/adPreview";
import { AdFormat } from "screens/adLibrary/facebookUtils/types";
import { getDefaultPropValue } from "screens/adLibrary/facebookUtils/utils";
import { IAd } from "shared/types/adLibrary";

type Args = {
  ad: IAd;
};
export default (args: Args) => {
  const { ad } = args;

  const {
    data: frame,
    isLoading,
    error,
  } = useQuery<string, Error>(
    ["fbPrototypeEverythingAds"],
    async () => {
      const accountId = getDefaultPropValue("ACCOUNTID");
      const prototype = await getAdPrototypeFromAd(ad, accountId);

      return generateAdPreview(
        AdFormat.MOBILE_FEED_STANDARD,
        ad.type,
        prototype,
      );
    },
    {
      staleTime: Infinity,
      retry: false,
    },
  );

  const iframe =
    frame
      ?.replace(/scrolling=\"([a-zA-Z]|\W)+\"\s/gm, `scrolling="no"`)
      ?.replace(
        /style=\"([a-zA-Z]|\W)+\"/gm,
        `style="border: none; pointer-events: none;"`,
      ) || "";

  return {
    iframe,
    isLoading,
    error,
  };
};
