import { Button, Drawer, Row, Tooltip } from "antd";
import classNames from "classnames";
import { ReactNode } from "react";
import { OperationMode } from "shared/types/inputValues";
import { industryType } from "utils/helpers";

import "./FormDrawer.scss";

interface IFormDrawer {
  mode: OperationMode;
  dataName: string;
  drawerWidth: number;
  showDrawer: boolean;
  processingData: boolean;
  validForm: boolean;
  processingUpload?: boolean;
  drawerForm: ReactNode | null;
  handleClose: () => void;
  handleDelete?: () => void;
  handleSubmit: () => void;
  deleteButtonText?: string;
  allowDelete?: boolean;
  extendedName?: string;
  className?: string;
  submitDisabledTooltip?: string;
}

// !TODO: Refactor - reduces cognitive complexity.
const FormDrawer: React.FC<IFormDrawer> = ({
  mode = "",
  dataName = "",
  extendedName = "",
  drawerWidth = 720,
  drawerForm = null,
  showDrawer = false,
  validForm = false,
  processingData = false,
  processingUpload = false,
  handleClose,
  handleDelete,
  handleSubmit,
  deleteButtonText,
  allowDelete,
  className,
  submitDisabledTooltip = "",
}) => {
  const displayName = extendedName
    ? `${dataName} - ${extendedName}`
    : `${dataName}`;

  const drawerTitle =
    (mode === "UPDATE" && `Edit ${displayName}`) ||
    (mode === "DUPLICATE" && `Duplicate ${displayName}`);

  const industryTitle = industryType("auto")
    ? `Add New ${displayName}`
    : `Add ${displayName}`;

  const titleText = `${drawerTitle || industryTitle}`;

  const isFeed = dataName === "Feed";
  const btnText = isFeed ? "Add" : titleText;

  const titleNode: ReactNode = (
    <h3 className="form-drawer-header-text">{titleText}</h3>
  );

  return (
    <Drawer
      data-cy="form-drawer"
      destroyOnClose={true}
      className={classNames("form-drawer", className)}
      title={titleNode}
      width={drawerWidth}
      onClose={() => {
        handleClose();
      }}
      visible={showDrawer}
      footer={
        <div className="form-drawer-footer">
          <Row
            gutter={16}
            align="middle"
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <div className="form-drawer-button-ctn">
              {handleDelete && (
                <Button
                  data-cy="drawer-delete-button"
                  key="drawer-delete-button"
                  className="drawer-delete-button"
                  danger
                  disabled={
                    !allowDelete ? true : mode !== "UPDATE" || processingData
                  }
                  onClick={handleDelete}
                >
                  {deleteButtonText || "Delete"}
                </Button>
              )}
              <Button
                type={isFeed ? "link" : undefined}
                className={isFeed ? "" : "drawer-cancel-button"}
                key="drawer-cancel-button"
                disabled={processingData}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Tooltip title={submitDisabledTooltip} placement="left">
                <Button
                  data-cy="drawer-submit-cu-button"
                  key="drawer-submit-button"
                  type="primary"
                  onClick={handleSubmit}
                  disabled={!validForm}
                  loading={processingData || processingUpload}
                >
                  {mode === "UPDATE" ? "Save" : btnText}
                </Button>
              </Tooltip>
            </div>
          </Row>
        </div>
      }
    >
      <div className="form-drawer-content">{drawerForm}</div>
    </Drawer>
  );
};

export default FormDrawer;
