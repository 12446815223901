import { Collapse } from "antd";
import { Dispatch, SetStateAction, useMemo } from "react";
import { TOfferListSection } from "screens/assetBuilder/offers/select.hooks/useOfferList";
import { ISavedOrderState, OfferData } from "shared/types/assetBuilder";
import { OfferType } from "shared/types/shared";
import {
  TCollapsableHeader,
  TCollapsableRows,
  TCollapsableTypes,
} from "../offerCollapse/OfferTypeCollapse";
import {
  getNewHeaderSchema,
  getNewRowSchema,
} from "../offerCollapse/OfferTypeCollapse.schema";
import Row from "../offerCollapse/offerTypeCollapse/Row";
import styles from "./NewOfferTypeCollapse.module.scss";

interface Props {
  selectedOfferTypes: OfferType[];
  savedOrder: ISavedOrderState;
  sectionKey: TOfferListSection;
  activeKey: string[];
  duplicatedOfferData?: Partial<OfferData>;
}

interface Handlers {
  setSelectedOfferTypes: Dispatch<SetStateAction<OfferType[]>>;
  setVin: Dispatch<SetStateAction<string>>;
  setActiveKey: Dispatch<SetStateAction<string[]>>;
}

const NewOfferTypeCollapse = (props: Props & Handlers) => {
  const { duplicatedOfferData } = props;
  const fieldKey2D = useMemo<{
    headers: TCollapsableHeader;
    rows: TCollapsableRows;
  }>(() => {
    return {
      headers: {
        vehicleInfo: getNewHeaderSchema("vehicleInfo", duplicatedOfferData),
        Lease: getNewHeaderSchema(OfferType.Lease, duplicatedOfferData),
        [OfferType.ZeroDownLease]: getNewHeaderSchema(
          OfferType.ZeroDownLease,
          duplicatedOfferData,
        ),
        Finance: getNewHeaderSchema(OfferType.Finance, duplicatedOfferData),
        [OfferType.Purchase]: getNewHeaderSchema(
          OfferType.Purchase,
          duplicatedOfferData,
        ),
        APR: getNewHeaderSchema(OfferType.APR, duplicatedOfferData),
      },
      rows: {
        vehicleInfo: getNewRowSchema("vehicleInfo", duplicatedOfferData),
        Lease: getNewRowSchema(OfferType.Lease, duplicatedOfferData),
        [OfferType.ZeroDownLease]: getNewRowSchema(
          OfferType.ZeroDownLease,
          duplicatedOfferData,
        ),
        Finance: getNewRowSchema(OfferType.Finance, duplicatedOfferData),
        [OfferType.Purchase]: getNewRowSchema(
          OfferType.Purchase,
          duplicatedOfferData,
        ),
        APR: getNewRowSchema(OfferType.APR, duplicatedOfferData),
      },
    };
  }, [duplicatedOfferData]);

  return (
    <div className={styles.OfferFieldList}>
      <Collapse
        className={styles.Collapse}
        activeKey={props.activeKey}
        onChange={currentActiveKey => {
          props.setActiveKey(currentActiveKey as string[]);
        }}
      >
        {(Object.keys(fieldKey2D.headers) as Array<TCollapsableTypes>).map(
          collapsableHeaderType => {
            const row = fieldKey2D.headers[collapsableHeaderType]!.map(
              props => ({
                ...props,
              }),
            );

            return (
              <Collapse.Panel
                key={`offer-type-collapse-${collapsableHeaderType}`}
                className={styles.CollapsePanel}
                header={
                  <Row
                    type="header"
                    row={row}
                    isSelected={props.selectedOfferTypes.includes(
                      collapsableHeaderType as OfferType,
                    )}
                    selectedOfferTypes={props.selectedOfferTypes}
                    setActiveKey={props.setActiveKey}
                    setSelectedOfferTypes={props.setSelectedOfferTypes}
                    savedOrder={props.savedOrder}
                    sectionKey={props.sectionKey}
                    offerType={collapsableHeaderType}
                    setVin={props.setVin}
                  />
                }
              >
                {collapsableHeaderType in fieldKey2D.rows && (
                  <div className={styles.RowWrapper}>
                    {fieldKey2D.rows[collapsableHeaderType]?.map((obj, idx) => {
                      return (
                        <Row
                          key={`content-row-${idx}`}
                          offerType={collapsableHeaderType}
                          {...obj}
                          sectionKey={props.sectionKey}
                          savedOrder={props.savedOrder}
                        />
                      );
                    })}
                  </div>
                )}
              </Collapse.Panel>
            );
          },
        )}
      </Collapse>
    </div>
  );
};

export default NewOfferTypeCollapse;
