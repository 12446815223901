import ToolbarTable from "shared/components/toolbarTable/ToolbarTable";
import { ToolbarButton } from "shared/types/adLibrary";
import styles from "../Metrics.module.scss";

type MetricsHeaderProps = {
  searchValue: string;
  setSearchValue: (searchValue: string) => void;
};

const MetricsHeader = ({ searchValue, setSearchValue }: MetricsHeaderProps) => {
  const toolbarContents: ToolbarButton = {
    Filter: {
      disabled: true,
      onClick: () => null,
    },
    Sort: {
      disabled: true,
      onClick: () => null,
      extraInfo: [
        {
          value: "name",
          label: "By Name",
          children: [
            {
              value: "desc",
              label: "Descending",
            },
            {
              value: "asc",
              label: "Ascending",
            },
          ],
        },
      ],
    },
  };

  return (
    <div className={styles.toolbar}>
      <ToolbarTable
        searchValue={searchValue}
        searchPlaceholder="Search"
        titleTooltip="Search by dashboard name, account, or ID"
        toolbarContents={toolbarContents}
        sortingOrder={["name", "desc"]}
        onSearch={setSearchValue}
      />
    </div>
  );
};

export default MetricsHeader;
