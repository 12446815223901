import { useAppShallowEqualSelector } from "shared/hooks/useAppSelector";

import { useQueryClient } from "react-query";
import { useOptimisticallyMutateSession } from "shared/hooks/adLibrary/adHistory/useMutateSession";

import { useLocation } from "react-router-dom";
import { useUser } from "shared/hooks/useUser";
import useHandleLoadSessionData from "./useHandleLoadSessionData";

import moment from "moment";

import { IAdHistorySession } from "shared/types/adLibrary";

const useCreateNewSession = () => {
  const user = useUser();
  const location = useLocation();
  const queryClient = useQueryClient();
  const handleLoadSessionData = useHandleLoadSessionData();
  const { mutate: modifyAdHistorySession } = useOptimisticallyMutateSession();

  const { selectedAdsForAdLoad, selectedFacebookAccounts, adLoadHistory } =
    useAppShallowEqualSelector(({ adLibrary }) => ({
      adLoadHistory: adLibrary.adLoad.adLoadHistory,
      selectedAdsForAdLoad: adLibrary.adLoad.selectedAds,
      selectedFacebookAccounts: adLibrary.selectedFacebookAccounts,
    }));

  const username = user?.email || "alexia@constellationagency.com";
  const locationSearchSplit = location.search.split("?session_id=") ?? [];
  const currentSessionId =
    adLoadHistory?.sessionId || locationSearchSplit?.[1]?.split("&")?.[0];

  return () => {
    const initialSessionName = selectedAdsForAdLoad
      ? `${selectedAdsForAdLoad?.length ?? 0} Ads - ${moment().format(
          "MMM DD",
        )}`
      : `Ad Load Session ${currentSessionId}`;

    const newSession: IAdHistorySession = {
      id: currentSessionId,
      name: initialSessionName,
      createdAt: Date.now(),
      createdBy: username,
      lastUpdatedAt: Date.now(),
      lastUpdatedBy: username,
      accounts: {
        facebook: selectedFacebookAccounts ?? [],
      },
      module: "adLoad",
    };

    modifyAdHistorySession?.(
      {
        operation: "create",
        session: newSession,
      },
      {
        onSuccess: result => {
          queryClient.setQueryData<IAdHistorySession[] | undefined>(
            ["adHistorySessions", result.id],
            () => [newSession],
          );
          handleLoadSessionData({ session: newSession });
        },
      },
    );
  };
};

export default useCreateNewSession;
