import { PictureFilled } from "@ant-design/icons";
import { IProductSetElement } from "shared/types/adLibrary";
import styles from "./FlowProductSet.module.scss";

type Props = {
  ele: IProductSetElement;
};

const FlowProductSet = (props: Props) => {
  return (
    <div className={styles.card}>
      <div className={styles.picture}>
        <PictureFilled />
      </div>
      <div className={styles.footer}>
        <div>{props.ele.item_headline}</div>
        <div>
          <strong>{props.ele.item_description}</strong>
        </div>
      </div>
    </div>
  );
};

export default FlowProductSet;
