import { useQuery } from "react-query";
import API from "services";
import { IGetLabelsResponse } from "shared/types/inputValues";

const getLabels = async () => {
  const { result, error, statusCode } =
    await API.services.adLibrary.getLabels();

  if (error) {
    throw Error(
      `Could not fetch labels: ${
        error.message || `${statusCode} error occurred.`
      }`,
    );
  }

  return result;
};

export const useFetchLabels = () => {
  return useQuery<IGetLabelsResponse["result"], Error>("labels", getLabels);
};
