import { useState } from "react";
import { columns } from "screens/metrics/PublicDashboardsMgmt/columns";
import { createDataListConfig } from "shared/components/dataList";
import { IDashboard } from "shared/types/dashboardManagement";
import { isFeatureEnabled } from "utils/helpers";
import { getPublicDashboards } from "./useFetchPublicDashboards";

export const useMetricsState = () => {
  const dashboardEnabled = isFeatureEnabled("ENABLE_LOOKER_DASHBOARDS", false);
  const [showLinkModal, setShowLinkModal] = useState<boolean>(false);
  const [shareDashboard, setShareDashboard] = useState<
    IDashboard | undefined
  >();
  const config = createDataListConfig(columns, {
    queryKey: "publicDashboards",
    queryFn: getPublicDashboards,
    staleTime: 300000,
  });

  return {
    dashboardEnabled,
    showLinkModal,
    setShowLinkModal,
    shareDashboard,
    setShareDashboard,
    config,
  };
};

export default useMetricsState;
