import { Button, Popover } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";

import styles from "./EllipsisButton.module.scss";
import { ReactNode } from "react";

interface IProps {
  popoverContent: ReactNode;
  dataCy?: string;
}

const EllipsisButton = ({ popoverContent, ...props }: IProps) => {
  return (
    <Popover
      key="ellispis-action-btn"
      className={styles.ellipsisButton}
      content={popoverContent}
      trigger="hover"
    >
      <Button
        data-cy={props.dataCy}
        shape="circle"
        icon={<EllipsisOutlined />}
      />
    </Popover>
  );
};

export default EllipsisButton;
