import { Outlet } from "react-router-dom";
import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { useDatadog } from "shared/hooks/useDatadog";
import { CampaignPlannerProvider } from "./campaignPlanner/CampaignPlannerContext";
import {
  CampaignPlannerFieldKeys,
  fields as campaignPlannerFields,
} from "./campaignPlanner/campaignPlannerList/dataList/fields";
import { CampaignPlanner } from "./campaignPlanner/types";

const CampaignPlannerContainer = () => {
  const config = useAppSelector(state => state.configuration.config);
  useDatadog();

  return (
    <DataListURLProvider<CampaignPlannerFieldKeys, CampaignPlanner>
      fields={campaignPlannerFields}
    >
      <CampaignPlannerProvider config={config}>
        <Outlet />
      </CampaignPlannerProvider>
    </DataListURLProvider>
  );
};

export default CampaignPlannerContainer;

