import { useCallback, useRef, useState } from "react";

export type OnSearchType = (value: string) => void;

export default (): [string, (value: string) => void, boolean] => {
  const searchTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const [searchBy, setSearchBy] = useState("");
  const [isSearch, setIsSearching] = useState(false);
  const onSearch = useCallback<OnSearchType>(
    value => {
      if (searchBy === value) return;

      setIsSearching(true);

      if (searchTimeoutRef.current) clearTimeout(searchTimeoutRef.current);

      searchTimeoutRef.current = setTimeout(() => {
        setSearchBy(value);

        setIsSearching(false);
      }, 300);
    },
    [searchBy],
  );

  return [searchBy, onSearch, isSearch];
};
