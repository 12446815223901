import { IAssetGroup, UpdatedUpload } from "shared/types/adLibrary";
import { PmaxLoadMode } from "./PMaxDataProvider";
import { v4 } from "uuid";
import { isLocalFile } from "shared/schemas/uploadAssets";

export const isUnableToLoad = (assetGroup: IAssetGroup, mode: PmaxLoadMode) => {
  const { feedStatus, pmaxStatus } = assetGroup;

  if (mode === "new") {
    return feedStatus === "Deleted from feed";
  }

  return (
    (feedStatus === "Draft" && pmaxStatus === "Never Loaded") ||
    (feedStatus === "Up to date" && pmaxStatus === "Loaded") ||
    pmaxStatus === "Never Loaded"
  );
};

export const processImgAssetForUpload = (imgAsset: UpdatedUpload) => {
  const uid = v4();
  const { added = [], deleted = [] } = imgAsset;

  return {
    added: added.map(file =>
      isLocalFile(file)
        ? {
            uid,
            base64: file.thumbUrl?.replace(
              /data\:image\/(png|jpeg|jpg);base64,/i,
              "",
            ),
            file: {
              name: file.originFileObj?.name || file.name,
              type: file.originFileObj?.type,
            },
          }
        : file,
    ),
    deleted: deleted.map(uploadFile => {
      const response =
        typeof uploadFile.response === "string"
          ? uploadFile.xhr
          : uploadFile.response;
      return { ...response };
    }),
  };
};
