import { useMutation, useQueryClient } from "react-query";
import API from "services";
import { ITagCreateResponse, ITagFetchResponse } from "services/tagManagement";

const addNewTag = async (newTag: string) => {
  const { result, error } =
    await API.services.tagManagement.createTag<ITagCreateResponse>(newTag);

  if (error) {
    throw Error(error.message);
  }

  return result;
};

export const useMutateTag = () => {
  const queryClient = useQueryClient();

  return useMutation<
    ITagCreateResponse["result"],
    Error,
    string,
    { previousTags?: ITagFetchResponse["result"] }
  >(addNewTag, {
    onMutate: async newTag => {
      await queryClient.cancelQueries("tags");

      const previousTags =
        queryClient.getQueryData<ITagFetchResponse["result"]>("tags");

      if (previousTags) {
        queryClient.setQueryData("tags", [...previousTags, newTag]);
      }

      return { previousTags };
    },
    onError: (err, newTag, context) => {
      if (context?.previousTags) {
        queryClient.setQueryData("tags", context.previousTags);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries("tags");
    },
  });
};
