import {
  createContext,
  memo,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  BrandRecord,
  BrandsDrawerMode,
  TargetElement,
} from "shared/types/brandsAccounts";
interface IDrawerState {
  targetElement?: TargetElement;
  drawerMode: BrandsDrawerMode;
}

interface IBrandsAccountsContext {
  selectedAccounts: string[];
  setSelectedAccounts: (accounts: string[]) => void;
  isDrawerOpen: boolean;
  drawerState: IDrawerState;
  toggleDrawer: (mode: BrandsDrawerMode) => void;
  resetDrawer: () => void;
  setFormTargetElementForUpdate: (targetElement: TargetElement) => void;
  setFormParentBrandId: (brandId: string) => void;
  search: string;
  setSearch: (search: string) => void;
  isDeleteModalOpen: boolean;
  setIsDeleteModalOpen: (isOpen: boolean) => void;
  onlyAccountsMode: boolean;
  updateTargetElementOnDelete: (brandsUpdated: BrandRecord[]) => void;
  accountDrawerOnBrandDrawer: IDrawerState;
  setAccountDrawerOnBrandDrawer: React.Dispatch<
    React.SetStateAction<IDrawerState>
  >;
}

const Context = createContext<IBrandsAccountsContext | null>(null);

interface Props {
  children: ReactNode;
}
const ContextProvider = ({ children }: Props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [drawerState, setDrawerState] = useState<IDrawerState>({
    drawerMode: "CREATE_BRAND",
  });
  const [search, setSearch] = useState("");
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [accountDrawerOnBrandDrawer, setAccountDrawerOnBrandDrawer] =
    useState<IDrawerState>({ drawerMode: "NONE" });

  const toggleDrawer = (mode: BrandsDrawerMode) => {
    setIsDrawerOpen(true);
    setDrawerState(prevVal => ({ ...prevVal, drawerMode: mode }));
  };

  const resetDrawer = () => {
    setDrawerState({
      drawerMode: "NONE",
    });
    setIsDrawerOpen(false);
  };

  const setFormTargetElementForUpdate = (targetElement: TargetElement) => {
    setDrawerState(prevVal => ({ ...prevVal, targetElement }));
  };

  const setFormParentBrandId = (brandId: string) => {
    setDrawerState(prevVal => ({
      drawerMode: prevVal.drawerMode,
      targetElement: brandId,
    }));
  };

  const updateTargetElementOnDelete = (brandsUpdated: BrandRecord[]) => {
    const currTarget = drawerState.targetElement;

    const target = currTarget;

    if (target && typeof target !== "string" && target.type === "Brand") {
      const brandUpdated = brandsUpdated.find(
        brand => brand.id === target.name,
      );
      if (brandUpdated) {
        setFormTargetElementForUpdate(brandUpdated);
      }
    }
  };

  const onlyAccountsMode = useMemo(
    () =>
      isDrawerOpen &&
      (drawerState.drawerMode === "CREATE_BRAND" ||
        drawerState.drawerMode === "UPDATE_BRAND"),
    [isDrawerOpen, drawerState.drawerMode],
  );

  return (
    <Context.Provider
      value={{
        selectedAccounts,
        setSelectedAccounts,
        isDrawerOpen,
        drawerState,
        toggleDrawer,
        resetDrawer,
        setFormTargetElementForUpdate,
        search,
        setSearch,
        setFormParentBrandId,
        isDeleteModalOpen,
        setIsDeleteModalOpen,
        onlyAccountsMode,
        updateTargetElementOnDelete,
        accountDrawerOnBrandDrawer,
        setAccountDrawerOnBrandDrawer,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const BrandsAccountsProvider = memo(ContextProvider);

export const useBrandsAccountsContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error(
      "Brands & Accounts Context must be used within a ContextProvider",
    );
  }

  return context;
};
