import { useState, useCallback } from "react";
import { Drawer, Space, Button, Row, Col, message, Typography } from "antd";
import { usePmaxCampaignsContext } from "../PmaxCampaignsProvider";
import styles from "../GooglePerformanceMax.module.scss";
import { NewCampaignForm } from "./newCampaignDrawer/NewCampaignForm";
import PMaxAssetGroups from "screens/adLibrary/PMaxAssetGroups";
import { usePMaxDataContext } from "shared/components/contextAPI/pmaxAssetGroup/PMaxDataProvider";
import { SelectedAssetGroupsTable } from "./newCampaignDrawer/newCampaignForm/SelectedAssetGroupsTable";
import {
  initialFormValues,
  toInternalGoogleAdCampaign,
} from "./newCampaignDrawer/newCampaignForm/helpers";
import { useCreatePmaxCampaigns } from "shared/hooks/adLibrary/pmax/useCreatePmaxCampaigns";
import { useUpdatePmaxCampaign } from "shared/hooks/adLibrary/pmax/useUpdatePmaxCampaign";
import { IApiResponse } from "shared/types/shared";

export const NewCampaignDrawer = () => {
  const [assetGroupDrawerOpen, setAssetGroupDrawerOpen] = useState(false);
  const { setSelectedRowIds, selectedRowIds: currentSelection } =
    usePMaxDataContext();
  const { campaignDrawer, setCampaignDrawer, form } = usePmaxCampaignsContext();
  const { mutate: createCampaign, isLoading: isCreatingCampaign } =
    useCreatePmaxCampaigns();
  const { mutate: updateCampaign, isLoading: isUpdatingCampaign } =
    useUpdatePmaxCampaign();

  const isLoading = isCreatingCampaign || isUpdatingCampaign;

  const submit = useCallback(async () => {
    try {
      await form.validateFields(); // Throws if form is invalid
      const mode = campaignDrawer.data.id ? "edit" : "create";
      const successMessage = `Campaign ${
        mode === "edit" ? "updated" : "created"
      } successfully`;

      const onSettled = (data?: IApiResponse) => {
        if (!data?.result) {
          message.error(data?.error?.message ?? "Something went wrong.");
          return;
        }

        setCampaignDrawer({
          open: false,
          data: initialFormValues,
          assetGroupIds: [],
        });

        message.success(successMessage);
      };

      const campaign = toInternalGoogleAdCampaign(
        campaignDrawer.data,
        campaignDrawer.assetGroupIds,
      );

      mode === "edit"
        ? updateCampaign(campaign, { onSettled })
        : createCampaign([campaign], { onSettled });
    } catch (e) {
      message.error(
        "Could not create campaign, please review the errors below.",
      );
    }
  }, [
    campaignDrawer.assetGroupIds,
    campaignDrawer.data,
    createCampaign,
    form,
    setCampaignDrawer,
    updateCampaign,
  ]);

  return (
    <Drawer
      title={"Create Performance Max Campaign"}
      destroyOnClose
      visible={campaignDrawer.open}
      closable={false}
      maskClosable={false}
      className={styles.drawerBody}
      width={"98%"}
      footer={
        <Space className={styles.drawerFooter}>
          <Button
            onClick={() =>
              setCampaignDrawer({
                open: false,
                data: initialFormValues,
                assetGroupIds: [],
              })
            }
          >
            Cancel
          </Button>
          <Button onClick={submit} type="primary" loading={isLoading}>
            Save
          </Button>
        </Space>
      }
    >
      <Row gutter={16} className={styles.drawerContent}>
        <Col span={8} className={styles.formSection}>
          <NewCampaignForm form={form} />
        </Col>
        <Col span={16} className={styles.assetGroupSection}>
          <Typography.Title level={5}>Asset Groups</Typography.Title>
          <SelectedAssetGroupsTable
            openAssetGroupDrawer={() => setAssetGroupDrawerOpen(true)}
          />
        </Col>
      </Row>
      <Drawer
        title={"Performance Max / Asset Groups"}
        destroyOnClose
        visible={assetGroupDrawerOpen}
        closable={false}
        afterVisibleChange={() => setSelectedRowIds([])}
        maskClosable={false}
        className={styles.drawerBody}
        width={"100%"}
        footer={
          <Space className={styles.drawerFooter}>
            <Button
              onClick={() => {
                setAssetGroupDrawerOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setCampaignDrawer({
                  assetGroupIds: [
                    ...campaignDrawer.assetGroupIds,
                    ...currentSelection,
                  ],
                });
                setAssetGroupDrawerOpen(false);
                setSelectedRowIds([]);
              }}
              type="primary"
              loading={false}
            >
              Add selected
            </Button>
          </Space>
        }
      >
        <div className={styles.assetGroupDrawerContent}>
          <PMaxAssetGroups />
        </div>
      </Drawer>
    </Drawer>
  );
};
