import API from "services";
import { OfferData } from "shared/types/assetBuilder";
import { isCorrectJellybeanUrlForOffer } from "utils/helpers";

type TGetJellybean = {
  result: {
    data: {
      body: string;
    };
    url: string;
  }[];
  error: Error;
};

export const getJellybeanImages = async (
  offerData: OfferData,
  searchObj?: { year: string; make: string; model: string },
) => {
  const { year, make, model } = searchObj ||
    offerData || {
      year: "",
      make: "",
      model: "",
    };

  try {
    const { result, error } =
      await API.services.assetBuilder.getJellybeanImages<TGetJellybean>({
        year: year.trim(),
        make: make.trim(),
        model: model.trim(),
      });

    if (!result || error) {
      return;
    }

    const resultUrls = result.map(item => item.url);

    const resultWithTrim = resultUrls.find(url =>
      isCorrectJellybeanUrlForOffer(url, offerData),
    );

    if (resultWithTrim) return resultWithTrim;

    return resultUrls.find(url =>
      isCorrectJellybeanUrlForOffer(url, {
        year: offerData.year,
        make: offerData.make,
        model: offerData.model,
      }),
    );
  } catch (error) {
    return;
  }
};
