import { Badge, Tag, Modal } from "antd";
import { useMemo } from "react";
import useToggleOffer from "shared/hooks/assetBuilder/useToggleOffer";
import { OfferType } from "shared/types/shared";
import { OfferData } from "shared/types/assetBuilder";
import { TOnOfferSelection } from "../CollapsableHeader";
import styles from "./OfferTypeBadgeContainer.module.scss";
import { ExclamationCircleFilled } from "@ant-design/icons";

type Props = {
  offerData: Partial<OfferData>;
  selectedOfferTypes: OfferType[];
  isInvalidFeed?: boolean;
};

type Handlers = {
  onOfferSelection?: TOnOfferSelection;
};

const OfferTypeBadgeContainer = ({
  offerData,
  selectedOfferTypes,
  onOfferSelection,
  isInvalidFeed,
}: Props & Handlers) => {
  const toggleOffer = useToggleOffer();
  const selectableOfferTypes = useMemo(
    () => [
      OfferType.Lease,
      OfferType.ZeroDownLease,
      OfferType.Finance,
      OfferType.Purchase,
      OfferType.APR,
    ],
    [],
  );

  const removedFromSelectedDock = (
    offerTypes: OfferType[],
    updatedOfferType: OfferType,
  ) => {
    return offerTypes.length === 1 && offerTypes.includes(updatedOfferType);
  };

  const onOfferTypeClick =
    (
      selectedOfferTypes: OfferType[],
      offerType: OfferType,
      cb: (updatedOfferTypes: OfferType[]) => void,
    ) =>
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();

      if (
        isInvalidFeed &&
        removedFromSelectedDock(selectedOfferTypes, offerType)
      ) {
        Modal.confirm({
          title: "Delete offer from feed",
          content:
            "Removing from selected offer will permanently delete this offer. Are you sure you want to remove this from your feed?",
          okText: "Remove",
          icon: <ExclamationCircleFilled style={{ color: "#FF4D4F" }} />,
          onOk() {
            toggleOffer({ offerType, offerData: offerData as OfferData });
            const selected = selectedOfferTypes.some(t => t === offerType);
            const updated = selected
              ? selectedOfferTypes.filter(t => t !== offerType)
              : [...selectedOfferTypes, offerType];

            cb(updated);
          },
        });
      } else {
        toggleOffer({ offerType, offerData: offerData as OfferData });
        const selected = selectedOfferTypes.some(t => t === offerType);
        const updated = selected
          ? selectedOfferTypes.filter(t => t !== offerType)
          : [...selectedOfferTypes, offerType];

        cb(updated);
      }
    };

  return (
    <div className={styles.OfferTypeTags}>
      {selectableOfferTypes.map(offerType => {
        return (
          <Badge
            key={`offer-type-${offerType}`}
            className={`${styles.TransactabilityBadge}`}
            dot={true}
          >
            <Tag
              className={`${styles.OfferTypeTag} ${
                !selectedOfferTypes.includes(offerType) ? styles.Disabled : ""
              }`}
              onClick={onOfferTypeClick(
                selectedOfferTypes,
                offerType,
                updatedOfferTypes => {
                  onOfferSelection?.(updatedOfferTypes);
                },
              )}
            >
              {offerType}
            </Tag>
          </Badge>
        );
      })}
    </div>
  );
};

export default OfferTypeBadgeContainer;
