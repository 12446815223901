import { CheckCircleTwoTone } from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  Collapse,
  Divider,
  message,
  Row,
  Tooltip,
} from "antd";
import { useEffect, useMemo, useState } from "react";

import { connect } from "react-redux";
import actions from "../../../redux/rootActions";

import {
  fullStateOptions,
  offerTypeVariables,
} from "../../../shared/constants/dataManagement";
import {
  ILegalLingoReduxState,
  IStateDisclosureElement,
  IStateDisclosureRecord,
  IStateExceptionRecord,
  TDisclosureDataType,
  TDisclosureType,
} from "../../../shared/types/legalLingo";
import {
  IGetBrandsResult,
  IBrandManagementState,
  IBrandRecord,
} from "../../../shared/types/brandManagement";

import DisclosuresCollapse from "./disclosuresContent/DisclosuresCollapse";

import "../../../shared/styles/Collapse.scss";
import "./DisclosuresContent.scss";
import _, { cloneDeep } from "lodash";

interface IDisclosuresContent {
  oems: IBrandRecord[];
  getOems: (paginationToken?: string) => void;
  processingOems: boolean;
  oemPaginationKey?: string;
  currentStateTab: string;
  selectedOem: string;
  setSelectedOem: (oem: string) => void;
  activeDisclosureCollapseKey: string;
  setActiveDisclosureCollapseKey: (disclosureCollapseKey: string) => void;
  setDisclosuresByStateInParent: (
    state: string,
    disclosures: IStateDisclosureElement[],
  ) => void;
  originalStateDisclosureObj: IStateDisclosureRecord;
  stateExceptions: IStateExceptionRecord[];
  getStateExceptions: (state: string, oem?: string) => void;
  createStateException: (stateException: IStateExceptionRecord) => void;
  creatingDataStatus: string;
  exceptionsChangesWereMade: boolean;
  toggleChangesWereMade: (
    changesWereMade: boolean,
    dataType?: TDisclosureDataType,
  ) => void;
  shouldFireSaveException: boolean;
  setShouldFireSaveExceptions: (shouldFire: boolean) => void;
  setStateExceptionsInRedux: (stateExceptions: IStateExceptionRecord[]) => void;
  stateExceptionRecordsInForm: IStateExceptionRecord[];
}

const DisclosuresContent: React.FC<IDisclosuresContent> = ({
  oems,
  getOems,
  processingOems,
  oemPaginationKey,
  currentStateTab,
  selectedOem,
  setSelectedOem,
  activeDisclosureCollapseKey,
  setActiveDisclosureCollapseKey,
  setDisclosuresByStateInParent,
  originalStateDisclosureObj,
  stateExceptions,
  getStateExceptions,
  creatingDataStatus,
  exceptionsChangesWereMade,
  toggleChangesWereMade,
  createStateException,
  shouldFireSaveException,
  setShouldFireSaveExceptions,
  setStateExceptionsInRedux,
  stateExceptionRecordsInForm,
}) => {
  useEffect(() => {
    getOems();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (originalStateDisclosureObj.disclosures.length > 0) {
      setCurrentDisclosuresByOfferType(originalStateDisclosureObj.disclosures);
    }
  }, [originalStateDisclosureObj.disclosures]);

  const [currentDisclosuresByOfferType, setCurrentDisclosuresByOfferType] =
    useState<IStateDisclosureElement[]>(
      originalStateDisclosureObj.disclosures || [],
    );

  const [showOemSelect, toggleOemSelect] = useState<boolean>(false);
  const [activeExceptionKey, setActiveExceptionCollapseKey] =
    useState<string>("");

  useEffect(() => {
    if (
      showOemSelect &&
      (stateExceptions.length < 1 ||
        !stateExceptions.find(
          stateException => stateException.state === currentStateTab,
        ))
    ) {
      getStateExceptions(currentStateTab);
    }

    // eslint-disable-next-line
  }, [showOemSelect]);

  useEffect(() => {
    if (creatingDataStatus === "complete_exception") {
      message.success("Exceptions saved successfully.");
      getStateExceptions(currentStateTab, selectedOem);
    }

    // eslint-disable-next-line
  }, [creatingDataStatus]);

  useEffect(() => {
    if (shouldFireSaveException && exceptionsChangesWereMade) {
      message.info(`Saving Exceptions for ${currentStateTab}...`);
      setShouldFireSaveExceptions(false);
      toggleChangesWereMade(false, "exception");

      const exceptionObjsForCurrentState = stateExceptionRecordsInForm.filter(
        obj => obj.state === currentStateTab,
      );

      // to do: write batch create service
      exceptionObjsForCurrentState.forEach(obj => createStateException(obj));
    } else {
      setShouldFireSaveExceptions(false);
    }

    // eslint-disable-next-line
  }, [shouldFireSaveException]);

  useEffect(() => {
    if (stateExceptions.length < 1 || !selectedOem) {
      return;
    }

    const foundExceptionsObject = stateExceptions.find(
      stateException =>
        stateException &&
        stateException.state === currentStateTab &&
        stateException.oem === selectedOem,
    ) || { exceptions: [], oem: selectedOem, state: currentStateTab };
    const newExceptionsObjects = [...stateExceptionRecordsInForm];

    const foundIndex = stateExceptionRecordsInForm.findIndex(
      obj => obj.state === currentStateTab && obj.oem === selectedOem,
    );

    if (foundIndex !== -1) {
      return;
    }

    newExceptionsObjects.push(foundExceptionsObject);
    setStateExceptionsInRedux(newExceptionsObjects);

    // eslint-disable-next-line
  }, [stateExceptions, selectedOem, currentStateTab]);

  useEffect(() => {
    const foundExceptionsInFormForState = stateExceptionRecordsInForm.filter(
      exceptionRecord => exceptionRecord.state === currentStateTab,
    );
    const currentStateException = stateExceptions.filter(
      exceptionRecord =>
        exceptionRecord && exceptionRecord.state === currentStateTab,
    );

    if (
      foundExceptionsInFormForState.length > 0 ||
      currentStateException.length < 1
    ) {
      return;
    }

    const newExceptionsObjects = [...stateExceptionRecordsInForm].concat(
      stateExceptions,
    );

    setStateExceptionsInRedux(newExceptionsObjects);
  }, [
    currentStateTab,
    stateExceptions,
    stateExceptionRecordsInForm,
    setStateExceptionsInRedux,
  ]);

  const fulfilledExceptionObjsMemo = useMemo(() => {
    if (stateExceptionRecordsInForm.length < 1) {
      return [];
    }

    const currestStateExceptionsInForm = stateExceptionRecordsInForm.filter(
      exceptionObj => exceptionObj && exceptionObj.state === currentStateTab,
    );

    if (currestStateExceptionsInForm.length < 1) {
      return [];
    }

    const fulfilledExceptionObjs = currestStateExceptionsInForm.filter(
      exceptionObj => {
        const { exceptions } = exceptionObj;
        const fulfilledVehicleInfo = exceptions.find(
          exception =>
            exception.offerType === "Vehicle Info" &&
            exception.text.trim() !== "",
        );
        const fulfilledExceptions = exceptions.filter(
          exception => exception.text && exception.text.trim() !== "",
        );

        return (
          !!fulfilledVehicleInfo ||
          fulfilledExceptions.length === offerTypeVariables.length
        );
      },
    );

    return fulfilledExceptionObjs;
  }, [stateExceptionRecordsInForm, currentStateTab]);

  const sortedOems = useMemo(() => {
    if (oems.length < 1) {
      return [];
    }

    return _.sortBy(oems, "oemName");
  }, [oems]);

  const insertObjIntoStateByOfferType = (
    offerType: TDisclosureType,
    text: string,
    dataType?: TDisclosureDataType,
  ) => {
    if (dataType === "exception") {
      const matchingExceptionObjIndex = stateExceptionRecordsInForm.findIndex(
        obj => obj.state === currentStateTab && obj.oem === selectedOem,
      );

      const newExceptionsObjects = cloneDeep(stateExceptionRecordsInForm);

      if (newExceptionsObjects[matchingExceptionObjIndex]) {
        const filteredExceptions = stateExceptionRecordsInForm[
          matchingExceptionObjIndex
        ].exceptions.filter(exception => exception.offerType !== offerType);

        const newExceptions = [...filteredExceptions, { offerType, text }];

        newExceptionsObjects[matchingExceptionObjIndex].exceptions =
          newExceptions;
      } else {
        newExceptionsObjects.push({
          oem: selectedOem,
          state: currentStateTab,
          exceptions: [{ offerType, text }],
        });
      }

      toggleChangesWereMade(true, "exception");

      setStateExceptionsInRedux(newExceptionsObjects);
    } else {
      const stateArray = currentDisclosuresByOfferType;

      const filteredObjects = stateArray.filter(
        disclosureObj => disclosureObj.offerType !== offerType,
      );

      const newStateArray = [...filteredObjects, { offerType, text }];
      setCurrentDisclosuresByOfferType(newStateArray);
      setDisclosuresByStateInParent(currentStateTab, newStateArray);
    }
  };

  let currentExceptionObj: IStateExceptionRecord = {
    oem: selectedOem,
    state: currentStateTab,
    exceptions: [],
  };
  if (selectedOem) {
    const foundExceptionObj = stateExceptionRecordsInForm.find(
      obj => obj.oem === selectedOem && obj.state === currentStateTab,
    );
    if (foundExceptionObj) {
      currentExceptionObj = foundExceptionObj;
    }
  }
  const panelKey = `${currentStateTab}-disclosure-panel`;
  const fullStateObject = fullStateOptions.find(
    stateObject => stateObject.abbreviation === currentStateTab,
  );
  const stateName = fullStateObject ? fullStateObject.name : currentStateTab;

  const variableTipText = (
    <span className="disclosure-title-tip">
      {" Type { } to trigger smart dropdown to insert variables"}
    </span>
  );

  const showOrHideOemSelect = () => {
    if (!showOemSelect && oems.length < 1) {
      getOems();
    }
    setActiveExceptionCollapseKey("");
    toggleOemSelect(!showOemSelect);
  };

  return (
    <div style={{ paddingRight: "1.75em" }}>
      <Collapse
        className="collapse-container"
        defaultActiveKey={[panelKey]}
        expandIconPosition={"right"}
      >
        <Collapse.Panel
          collapsible="disabled"
          key={panelKey}
          header={"Create Disclosures and Exceptions"}
        >
          <div style={{ width: "100%", padding: "2em" }}>
            <Row gutter={16}>
              <Col span={12}>
                <Row>
                  <Col>
                    <h2 className="disclosure-title-text">
                      {stateName} Disclosures
                    </h2>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "1em" }}>
                  <Col>{variableTipText}</Col>
                </Row>
                <Row>
                  <Col className="disclosures-column">
                    <DisclosuresCollapse
                      currentStateTab={currentStateTab}
                      activeDisclosureCollapseKey={activeDisclosureCollapseKey}
                      currentDisclosuresByOfferType={
                        currentDisclosuresByOfferType
                      }
                      setDisclosuresByStateInParent={
                        setDisclosuresByStateInParent
                      }
                      setActiveDisclosureCollapseKey={
                        setActiveDisclosureCollapseKey
                      }
                      insertDisclosureObjIntoStateByOfferType={
                        insertObjIntoStateByOfferType
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <Col>
                    <h2 className="disclosure-title-text">
                      {selectedOem ? selectedOem : stateName} Exceptions
                      {"   "}
                      <Button
                        onClick={() => {
                          setSelectedOem("");
                          showOrHideOemSelect();
                        }}
                      >
                        Select OEM
                      </Button>
                      {oems.length > 0 && (showOemSelect || selectedOem) && (
                        <Tooltip
                          placement="right"
                          title={"OEMs with all exceptions filled"}
                        >
                          <span className={`number-counter`}>
                            {fulfilledExceptionObjsMemo.length}
                          </span>
                        </Tooltip>
                      )}
                    </h2>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "0.8em" }}>
                  <Col style={!selectedOem ? { opacity: 0 } : {}}>
                    {variableTipText}
                  </Col>
                </Row>
                {selectedOem && (
                  <Row>
                    <Col className="disclosures-column">
                      <DisclosuresCollapse
                        dataType="exception"
                        currentStateTab={currentStateTab}
                        activeDisclosureCollapseKey={activeExceptionKey}
                        currentDisclosuresByOfferType={
                          currentExceptionObj.exceptions
                        }
                        setDisclosuresByStateInParent={
                          setDisclosuresByStateInParent
                        }
                        setActiveDisclosureCollapseKey={
                          setActiveExceptionCollapseKey
                        }
                        insertDisclosureObjIntoStateByOfferType={
                          insertObjIntoStateByOfferType
                        }
                      />
                    </Col>
                  </Row>
                )}
                {showOemSelect && (
                  <Row>
                    <Col className="exceptions-column">
                      <Row>
                        <Col className="exceptions-select-oem-content">
                          <b>
                            {
                              "Start Creating An Exception By Selecting an OEM Below"
                            }
                          </b>
                          <Divider className="exceptions-select-oem-header-divider" />
                        </Col>
                      </Row>
                      <Row>
                        {(!oems || oems.length < 1) && !processingOems && (
                          <Alert
                            message="Error"
                            description="It looks like OEMs could not be loaded. Refresh the page and try again."
                            type="error"
                            showIcon={true}
                          />
                        )}
                        {sortedOems.length > 0 &&
                          sortedOems.map(oem => (
                            <Row
                              key={`${oem.oemName}-button-row`}
                              className="exlcusion-oem-select-row"
                            >
                              <Button
                                className="exlcusion-oem-select-item"
                                block={true}
                                onClick={() => {
                                  setSelectedOem(oem.oemName);
                                  toggleOemSelect(false);
                                }}
                              >
                                {oem.oemName}{" "}
                                {fulfilledExceptionObjsMemo.find(
                                  exceptionObj =>
                                    exceptionObj.oem === oem.oemName,
                                ) && (
                                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                                )}
                              </Button>
                            </Row>
                          ))}
                        {oemPaginationKey && (
                          <Row className="exlcusion-oem-select-row">
                            <Button
                              style={{ border: "none" }}
                              block={true}
                              loading={processingOems}
                              onClick={() => {
                                getOems(
                                  oemPaginationKey ||
                                    oems[oems.length - 1].oemName,
                                );
                              }}
                            >
                              {processingOems ? "Loading" : "Load More"}
                            </Button>
                          </Row>
                        )}
                      </Row>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const { oemManagement, legalLingo } = state;
  const { oemRecords, result, processingOems } =
    oemManagement as IBrandManagementState;
  const { stateExceptions, creatingDataStatus, stateExceptionRecordsInForm } =
    legalLingo as ILegalLingoReduxState;
  const { paginationKey } = (result as IGetBrandsResult) || {
    paginationKey: { oem_name: "" },
  };
  return {
    oems: oemRecords,
    oemPaginationKey: paginationKey ? paginationKey.oem_name : "",
    processingOems,
    stateExceptions,
    creatingDataStatus,
    stateExceptionRecordsInForm,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getOems: (paginationToken?: string) => {
      dispatch(actions.oemManagement.getOems(paginationToken));
    },
    getStateExceptions: (state: string, oem = "") => {
      let query = `state=${encodeURIComponent(state.toLowerCase())}`;
      if (oem) {
        query = query.concat(`&oem=${encodeURIComponent(oem)}`);
      }
      dispatch(actions.legalLingo.fetchData("stateExceptions", query));
    },
    createStateException: (stateException: IStateExceptionRecord) => {
      dispatch(actions.legalLingo.createStateException(stateException));
    },
    setStateExceptionsInRedux: (stateExceptions: IStateExceptionRecord[]) => {
      dispatch(actions.legalLingo.setStateExceptionsInRedux(stateExceptions));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DisclosuresContent);
