export const authEndpoints = {
  audience: process.env.REACT_APP_AUTH_AUDIENCE,
  domain: process.env.REACT_APP_AUTH_DOMAIN,
};

export const envAuth = {
  ...authEndpoints,
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  organization: process.env.REACT_APP_AUTH_ORGANIZATION,
};

const envConfig = {
  region: process.env.REACT_APP_AWS_REGION,
  auth: envAuth,
  identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
};

const env = {
  name: process.env.REACT_APP_ENV,
  cid: process.env.REACT_APP_ENV,
  xcid: `${process.env.REACT_APP_AV2_CLIENT}-${process.env.REACT_APP_ENV}`,
  ...envConfig,
};

export const configs = { env };

export const configsArr = [env];

export type Config = (typeof configsArr)[number];
