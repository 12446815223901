import { useMemo } from "react";
import { createDataListConfig } from "shared/components/dataList";
import { useFetchLogos } from "shared/components/logoSelect/hooks/useFetchLogos";
import { SELECT_LOGOS_QUERY_KEY, fields } from "../logo.utils";

export const useLogoSelectDataListConfig = () => {
  const { logos, isFetching } = useFetchLogos();

  const dataListConfig = useMemo(() => {
    return createDataListConfig(fields, {
      queryKey: [SELECT_LOGOS_QUERY_KEY],
      enabled: !isFetching && !!logos,
      queryFn: async () => logos,
    });
  }, [logos, isFetching]);

  return {
    dataListConfig,
  };
};
