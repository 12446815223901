import {
  ExclamationCircleOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  HighlightOutlined,
  HighlightFilled,
} from "@ant-design/icons";
import { Tooltip, Divider } from "antd";
import { FC, useState, useCallback, useMemo } from "react";
import { IAssetInstance } from "shared/types/assetBuilder";
import { ExtendedObjectType, LogoEventType } from "shared/types/designStudio";

import "./ToggleForm.scss";

interface ToggleFormProps {
  size: string;
  disableToggles: boolean;
  initialHighlights: { [key in ExtendedObjectType]?: boolean };
  initialShowHideObjects: {
    [key in ExtendedObjectType | LogoEventType]?: {
      toggle: boolean;
      action: "show" | "hide";
    };
  };
}

interface ToggleFormHandlers {
  updateAssetInstances: (size: string, instances: IAssetInstance[]) => void;
  updateHighlights: (
    type: ExtendedObjectType,
    size: string,
    isHighlighted: boolean,
  ) => void;

  updateShowHideObjects: (
    type: ExtendedObjectType | LogoEventType,
    size: string,
    action: "show" | "hide",
    isShown: boolean,
  ) => void;
}

const ToggleForm: FC<ToggleFormProps & ToggleFormHandlers> = props => {
  const [showHideObjects, setShowHideObjects] = useState<{
    [key in ExtendedObjectType | LogoEventType]?: {
      toggle: boolean;
      action: "show" | "hide";
    };
  }>(props.initialShowHideObjects || {});

  const [highlights, setHighlights] = useState<{
    [key in ExtendedObjectType]?: boolean;
  }>(props.initialHighlights || {});

  const logoEventTypes: LogoEventType[] = useMemo(
    () => ["STORE_LOGO", "SALES_EVENT_LOGO", "OEM_LOGO"],
    [],
  );

  const getToggleButtonTitle = useCallback(
    (type: ExtendedObjectType | LogoEventType) => {
      switch (type) {
        case "stamp":
          return "Stamps";
        case "logo":
          return "All Logos";
        case "SALES_EVENT_LOGO":
          return "Sales Event Logo";
        case "OEM_LOGO":
        case "BRAND_LOGO":
          return "Brand Logo";
        case "STORE_LOGO":
        case "ACCOUNT_LOGO":
          return "Account Logo";
        case "lifestyle":
          return "Lifestyle Images";
        case "disclosure":
          return "Disclosures";
        default:
          return "-";
      }
    },
    [],
  );

  const handleEyeClick = (
    type: LogoEventType | ExtendedObjectType,
    action: "hide" | "show",
  ) => {
    const updatedShowHideObjects = {
      ...(showHideObjects || {}),
      [type]: {
        ...(showHideObjects?.[type] || {}),
        action: action,
        toggle: !(showHideObjects?.[type]?.toggle ?? true), // if showHideObjects is undefined, we assume that object is visible by default
      },
    };
    setShowHideObjects(updatedShowHideObjects);

    props.updateShowHideObjects(
      type as ExtendedObjectType | LogoEventType,
      props.size,
      action,
      updatedShowHideObjects[type]!.toggle,
    );
  };

  const updateHighlight = useCallback((on: boolean, type) => {
    setHighlights(prev => {
      const updatedHighlights = {
        ...prev,
        [type]: on,
      };

      return updatedHighlights;
    });
    props.updateHighlights(type, props.size, on);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="show-hide-highlight-toolbar">
      <div className="ctn">
        <span className="title-tooltip show-hide-highlight">
          Show / Hide / Highlight
          <Tooltip title="Display, Hide, and/or Highlight specific Objects within all Instances of this Asset Size.">
            <ExclamationCircleOutlined />
          </Tooltip>
        </span>
        <div className="item">
          <Tooltip title="Show All">
            <EyeOutlined onClick={() => handleEyeClick("stamp", "show")} />
          </Tooltip>
          <Tooltip title="Hide All">
            <EyeInvisibleOutlined
              onClick={() => handleEyeClick("stamp", "hide")}
            />
          </Tooltip>
          <Tooltip title="Highlight All">
            {!highlights["stamp"] ? (
              <HighlightOutlined
                onClick={() => updateHighlight(true, "stamp")}
              />
            ) : (
              <HighlightFilled
                onClick={() => updateHighlight(false, "stamp")}
              />
            )}
          </Tooltip>
          <span className="title">{getToggleButtonTitle("stamp")}</span>
        </div>
      </div>
      <Divider
        type="vertical"
        style={{
          height: "3rem",
        }}
      />
      <div className="ctn">
        {(["lifestyle", "disclosure"] as ExtendedObjectType[]).map(type => {
          return (
            <div key={`highlight-${type}`} className="item">
              <Tooltip title="Highlight All">
                {!highlights[type] ? (
                  <HighlightOutlined
                    onClick={() => updateHighlight(true, type)}
                  />
                ) : (
                  <HighlightFilled
                    onClick={() => updateHighlight(false, type)}
                  />
                )}
              </Tooltip>
              <span className="title">{getToggleButtonTitle(type)}</span>
            </div>
          );
        })}
      </div>
      <Divider
        type="vertical"
        style={{
          height: "3rem",
        }}
      />
      <div className="ctn">
        <div className="item">
          <Tooltip title="Show All">
            <EyeOutlined onClick={() => handleEyeClick("logo", "show")} />
          </Tooltip>
          <Tooltip title="Hide All">
            <EyeInvisibleOutlined
              onClick={() => handleEyeClick("logo", "hide")}
            />
          </Tooltip>
          <Tooltip title="Highlight All">
            {!highlights["logo"] ? (
              <HighlightOutlined
                onClick={() => updateHighlight(true, "logo")}
              />
            ) : (
              <HighlightFilled onClick={() => updateHighlight(false, "logo")} />
            )}
          </Tooltip>
          <span className="title">{getToggleButtonTitle("logo")}</span>
        </div>
        <div className="logo-items" data-cy="logo-items">
          {([...logoEventTypes] as LogoEventType[]).map(type => {
            return (
              <div key={`show-hide-highlight-${type}`} className="item">
                <Tooltip title="Show All">
                  <EyeOutlined onClick={() => handleEyeClick(type, "show")} />
                </Tooltip>
                <Tooltip title="Hide All">
                  <EyeInvisibleOutlined
                    onClick={() => handleEyeClick(type, "hide")}
                  />
                </Tooltip>
                <span className="title">{getToggleButtonTitle(type)}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ToggleForm;
