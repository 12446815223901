import { RocketOutlined } from "@ant-design/icons";

import { BackTop, Button, message, Modal, Popover } from "antd";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import {
  INewOrder,
  INewOrderRecord,
  StatusOptions,
} from "shared/types/newOrders";
import AssetBuilderHeader, {
  SelectedItem,
  SelectedSubItem,
} from "./assetBuilder/AssetBuilderHeader";
import Offers from "./assetBuilder/Offers";
import Orders from "./assetBuilder/Orders";

import {
  AssetBuilderVerb,
  AssetInstanceRecord,
  HeaderMatchType,
  IAssetBuilderState,
  ICheckedOfferFilter,
  ISelectedOffer,
  OfferData,
  RawSelectedOffers,
} from "shared/types/assetBuilder";
import actions from "../redux/rootActions";
import { getAssetCount, getStampCount } from "../utils/helpers.asset";

import { useQueryClient } from "react-query";
import { IAssetExportQueryParamObj } from "shared/types/assetExport";
import { IConfig } from "shared/types/configuration";
import { processRawSelectedOffers } from "utils/helpers.offer";
import "./AssetBuilder.scss";
import { useDatadog } from "shared/hooks/useDatadog";
import { useIsAdmin } from "shared/hooks/useIsAdmin";
import { isEnvVarEquals, isFeatureEnabled } from "utils/helpers";

interface ISelectedHeader {
  selectedItem: SelectedItem;
  selectedSubItem?: SelectedSubItem;
  actionButtons: any[];
}

interface IAssetBuilder {
  config?: IConfig;
  checkedOfferFilters: ICheckedOfferFilter;
  commitOrder: () => Promise<void>;
  generalMessage: string;
  errorMessage: string | null;
  savedOrder: IAssetBuilderState["savedOrder"];
  currentSelectedOrder: INewOrderRecord;

  disableExport: boolean;
  selectedOffers: ISelectedOffer[];
  assetInstances: AssetInstanceRecord;

  rawSelectedOffers: RawSelectedOffers;
  setAssetInstanceComparator: (assetInstance: AssetInstanceRecord) => void;
  saveDialog: boolean;
  setSaveDialog: (input: boolean) => void;
  newPath: string;
  redirect?: IAssetBuilderState["redirect"];
  fetchOrderState: (
    orderId: string,
    assetExportQueryObj?: IAssetExportQueryParamObj,
    redirectType?: string,
  ) => void;
  loggedInRole: string;
  enableLauncher: boolean;
  selectedOfferList: Array<{
    row: OfferData;
    updated?: number;
    editedKeys?: Record<string, true>;
  }>;
  totalSelectedOffers: number;
  offerTypes: string;
  updateNewOrder: (updateNewOrder: Partial<INewOrderRecord>) => Promise<void>;
}

const AssetBuilder: FC<IAssetBuilder> = ({
  checkedOfferFilters,
  commitOrder,
  disableExport,
  selectedOffers,
  assetInstances,

  rawSelectedOffers,
  savedOrder,
  currentSelectedOrder,
  generalMessage,
  errorMessage,
  saveDialog,
  setSaveDialog,
  newPath,
  redirect,
  fetchOrderState,
  enableLauncher,
  totalSelectedOffers,
  offerTypes,
  updateNewOrder,
}) => {
  const initOrder: INewOrder = {
    id: "",
    dealer_name: "",
    createdAt: Date.now(),
    creator_name: "",
    dealer_code: "",
    dealer_oem: "",
    is_archived: false,
    num_assets: 0,
    pushed_WF: false,
    expiresAt: Date.now(),
    wfProjectName: "",
    wfProjectNumber: "",
    wfID: "",
    wfFullProjectName: "",
    parentFileToken: "",
    documentID: "",
    proofUploadData: "",
    selectTemplateCollapseSearchInput: "",
    selectImageCollapseSearchInput: "",
    selectedInventory: 0,
    totalSelectedOffers: 0,
    selectedOrderOffers: "",
    integrationCount: 0,
    coopSubmissionNotice: "",
    totalUsedStamps: 0,
  };

  const isAdmin = useIsAdmin();

  const localSelectedOffers = useMemo(() => {
    const rawSelectedOffersArray = processRawSelectedOffers(rawSelectedOffers);
    if (selectedOffers.length < 1 && rawSelectedOffersArray.length < 1) {
      return [];
    }

    if (selectedOffers.length < 1) {
      return rawSelectedOffersArray;
    }

    return selectedOffers;
  }, [rawSelectedOffers, selectedOffers]);

  const [showNeworderDrawer, toggleNewOrderDrawer] = useState(false);

  const [openStatusModal, setOpenStatusModal] = useState(false);

  const getDisabledCoop = () => {
    const disabledCoopArr = [
      "BMW",
      "MINI",
      "Aston Martin",
      "Bugatti",
      "Lexus",
      "Maserati",
      "McLaren",
      "Rolls Royce",
      "Volvo",
      "Porsche",
      "Buick",
      "GMC",
      "Chevrolet",
      "Jaguar",
      "Land Rover",
    ];
    if (savedOrder?.selectedOrder) {
      const oemArr = savedOrder.selectedOrder.dealer_oem.split(",");
      return oemArr.some(oem => disabledCoopArr.includes(oem));
    }
    return false;
  };
  const disabledCoop = getDisabledCoop();

  const [openLaunchModal, setOpenLaunchModal] = useState(false);
  const [isLaunchButtonDisabled, setIsLaunchButtonDisabled] = useState(true);

  const [addingMode, setAddingMode] = useState(false);

  useDatadog();

  useEffect(() => {
    if (!generalMessage && !errorMessage) {
      return;
    } else if (generalMessage) {
      const lowerCaseMessage = generalMessage.toLowerCase();
      (lowerCaseMessage.includes("success") ||
        lowerCaseMessage.includes("saved") ||
        lowerCaseMessage.includes("complete")) &&
        message.success(generalMessage);
    } else if (errorMessage) {
      message.error(errorMessage);
    }
  }, [generalMessage, errorMessage]);

  // This will be passed to ReviewPage.tsx for toggling export modal
  const [showExportDrawer, toggleExportDrawer] = useState<boolean>(false);
  const [showCoopModal, toggleCoopModal] = useState<boolean>(false);
  const [forceRenderImages, toggleForceRenderImages] = useState<boolean>(false);

  const isInternalEnv = isEnvVarEquals("CLIENT", "internal");
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  queryClient.invalidateQueries("render_template");
  const returnActionButton = (props: {
    buttonText: string;
    objectName: string;
    currentVerb: AssetBuilderVerb;
    destinationVerb: AssetBuilderVerb;
    disableCondition: boolean;
    pathnameParam: string;
  }) => {
    return props.disableCondition ? (
      <Popover
        key="action-button-popover"
        className="action-button-popover action-button-header"
        content={`At least one valid ${props.objectName} is required in order to ${props.destinationVerb}`}
        placement="leftTop"
      >
        <Button
          key="asset-builder-offers-select-disabled"
          className={`action-button-header ${
            props.disableCondition ? "action-button-header-disabled" : ""
          }`}
          disabled={props.disableCondition}
        >
          {props.buttonText}
        </Button>
      </Popover>
    ) : (
      <Button
        key="asset-builder-offers-select"
        className="action-button action-button-header"
        onClick={() => {
          const inlineEditingEnabled = isFeatureEnabled(
            "ENABLE_INLINE_EDITING",
          );
          const fromInlineEditingPage = /select-v2/i.test(props.pathnameParam);

          if (inlineEditingEnabled && fromInlineEditingPage) {
            navigate(
              `${props.pathnameParam.replace(/\/?select(-v2)?\/?/i, "")}/${
                props.destinationVerb
              }`,
            );

            return;
          }

          const { assetInstances: assetInstanceVals, selectedOrder } =
            savedOrder || {};

          if (selectedOrder) {
            const assetCounter = assetInstanceVals
              ? getAssetCount(assetInstanceVals)
              : selectedOrder.num_assets;
            const stampTotal = assetInstances
              ? getStampCount(assetInstances)
              : selectedOrder.totalUsedStamps;
            const updatedOrder: Partial<INewOrderRecord> = {
              id: selectedOrder.id,
              key: 0,
              actions: "ok",
              selectedInventory:
                selectedOffers.length || selectedOrder.selectedInventory,
              totalSelectedOffers:
                totalSelectedOffers || selectedOrder.totalSelectedOffers,
              selectedOrderOffers:
                offerTypes || selectedOrder.selectedOrderOffers,
              num_assets: assetCounter,
              totalUsedStamps: stampTotal,
            };
            updateNewOrder(updatedOrder).then(() => {
              commitOrder().then(() => {
                navigate(
                  `${props.pathnameParam.replace(
                    `/${props.currentVerb}`,
                    "",
                  )}/${props.destinationVerb}`,
                );
              });
            });
          }
        }}
      >
        {props.buttonText}
      </Button>
    );
  };

  const countGeneratedInstances = () => {
    let usedTemplateCounter = 0;
    for (const typeKey in assetInstances) {
      for (const sizeKey in assetInstances[typeKey]) {
        const currentInstances = assetInstances[typeKey][sizeKey];
        if (currentInstances.length < 1) {
          continue;
        }
        const emptyInstances = currentInstances.filter(instance => {
          // As of AV2-1631: only one offer is selectable in an instance
          if (!instance.offers) {
            return true;
          }

          const numberOfOffers = Object.keys(instance.offers).length;
          let foundInstanceWithSelectedOffer = null;

          if (numberOfOffers > 0) {
            foundInstanceWithSelectedOffer = selectedOffers.find(
              selectedOffer =>
                selectedOffer.offerData.vin &&
                instance.offers[selectedOffer.offerData.vin],
            );
          }

          return (
            !instance.template ||
            numberOfOffers < 1 ||
            !foundInstanceWithSelectedOffer
          );
        });
        if (emptyInstances.length === currentInstances.length) {
          continue;
        }
        usedTemplateCounter += 1;
      }
    }

    return usedTemplateCounter;
  };

  const coopButton = (disabledExport: boolean) => {
    return (
      <Button
        key="asset-builder-coop-select"
        className={`${
          disabledCoop ? "" : "action-button"
        } action-button-header`}
        disabled={disabledExport || !isAdmin || disabledCoop}
        style={
          disabledExport || !isAdmin || disabledCoop
            ? {
                backgroundColor: "#fcaf77",
                opacity: "0.75",
              }
            : {}
        }
        onClick={() => {
          toggleCoopModal(true);
          toggleForceRenderImages(true);
        }}
      >
        Coop
      </Button>
    );
  };

  const getOffersActionButtons = (
    pathnameParam: string,
    actionButtonsFor: AssetBuilderVerb,
    disabledExport: boolean,
  ) => {
    switch (actionButtonsFor) {
      case "select":
        return [
          returnActionButton({
            pathnameParam,
            objectName: "offer",
            currentVerb: "select",
            destinationVerb: "build",
            buttonText: "Build Asset",
            disableCondition: localSelectedOffers.length < 1,
          }),
        ];
      case "build":
        return [
          returnActionButton({
            pathnameParam,
            objectName: "instance",
            currentVerb: "build",
            destinationVerb: "review",
            buttonText: "Review",
            disableCondition: countGeneratedInstances() < 1,
          }),
        ];
      case "review":
        const disableInternalLaunch =
          isInternalEnv &&
          ![
            StatusOptions.COOP_APPROVED,
            StatusOptions.COOP_NOT_REQUIRED,
          ].includes(currentSelectedOrder.status || StatusOptions.NO_STATUS);
        const isLauncherDisabled = !isAdmin || disableInternalLaunch;
        const reviewButtonArr = [
          <Button
            key="asset-builder-export-select"
            className="action-button action-button-header"
            disabled={disabledExport || !isAdmin}
            style={
              disabledExport || !isAdmin
                ? {
                    backgroundColor: "#fcaf77",
                    opacity: "0.75",
                  }
                : {}
            }
            onClick={() => {
              toggleExportDrawer(true);
              toggleForceRenderImages(true);
            }}
          >
            Export
          </Button>,
          disabledCoop && (
            <Popover
              key="action-button-popover"
              className="action-button-popover"
              content={"Co-op functionality not supported for this OEM."}
              placement="bottomLeft"
              trigger={"hover"}
            >
              {coopButton(disabledExport)}
            </Popover>
          ),
          !disabledCoop && coopButton(disabledExport),
          enableLauncher && (
            <NavLink
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "4px",
                width: "6em",
                marginLeft: "4px",
                opacity: isLauncherDisabled ? "0.75" : "1.00",
                cursor: isLauncherDisabled ? "not-allowed" : "pointer",
              }}
              className="action-button action-button-header"
              key="asset-builder-launch-button"
              to={redirect?.path || ""}
              onClick={e => {
                e.preventDefault();
                if (isLauncherDisabled) return;
                const order = savedOrder?.selectedOrder
                  ? { ...savedOrder.selectedOrder }
                  : initOrder;

                fetchOrderState(order.id, undefined, "launcher");

                navigate(
                  `/asset-builder/orders/${savedOrder?.selectedOrder?.id}/offers/asset-launcher`,
                );
              }}
            >
              Launch
            </NavLink>
          ),
        ];
        return reviewButtonArr;
      case "launcher":
        return [
          <Button
            data-cy="rocket-status-button"
            key="asset-builder-offers-select"
            className="action-button action-button-header"
            onClick={() => {
              setOpenStatusModal(true);
            }}
            icon={<RocketOutlined />}
          >
            Status
          </Button>,
          <Button
            data-cy="rocket-launch-button"
            style={{ opacity: isLaunchButtonDisabled ? "0.75" : "1" }}
            key="asset-builder-offers-launch"
            className="action-button action-button-header"
            disabled={isLaunchButtonDisabled || !isAdmin}
            onClick={() => {
              setOpenLaunchModal(true);
            }}
          >
            Launch
          </Button>,
        ];
      default:
        return [];
    }
  };

  const { pathname } = useLocation();

  const getHeadersToEnable = () => {
    const offersSelectRegex =
      /\/asset-builder\/orders\/.+\/offers\/select(-v2)?$/g;
    const offersBuildRegex = /\/asset-builder\/orders\/.+\/offers\/build$/g;
    const offersReviewRegex = /\/asset-builder\/orders\/.+\/offers\/review$/g;
    const offersLauncherRegex =
      /\/asset-builder\/orders\/.+\/offers\/asset-launcher$/g;

    let headersToEnabled: HeaderMatchType[] = ["orders"];
    let headersToSelect: ISelectedHeader = {
      selectedItem: "ORDERS",
      selectedSubItem: undefined,
      actionButtons: [
        <Button
          key="build-asset-action-button"
          className="asset-builder action-button action-button-header"
          disabled={!isAdmin}
          onClick={() => {
            toggleNewOrderDrawer(true);
          }}
        >
          + New Order
        </Button>,
      ],
    };

    if (offersSelectRegex.test(pathname)) {
      headersToEnabled = selectedOffers.length
        ? ["orders", "offers", "build"]
        : ["orders", "offers"];
      headersToSelect = {
        selectedItem: "OFFERS",
        selectedSubItem: "SELECT",
        actionButtons: getOffersActionButtons(
          pathname,
          "select",
          disableExport,
        ),
      };
    } else if (offersBuildRegex.test(pathname)) {
      headersToEnabled = ["orders", "offers", "select", "build"];
      headersToSelect = {
        selectedItem: "OFFERS",
        selectedSubItem: "BUILD",
        actionButtons: getOffersActionButtons(pathname, "build", disableExport),
      };
    } else if (offersReviewRegex.test(pathname)) {
      headersToEnabled = ["orders", "offers", "select", "build", "review"];
      headersToSelect = {
        selectedItem: "OFFERS",
        selectedSubItem: "REVIEW",
        actionButtons: getOffersActionButtons(
          pathname,
          "review",
          disableExport,
        ),
      };
    } else if (offersLauncherRegex.test(pathname)) {
      headersToEnabled = ["launch"];
      headersToSelect = {
        selectedItem: "AD LAUNCHER",
        actionButtons: getOffersActionButtons(
          pathname,
          "launcher",
          disableExport,
        ),
      };
    }

    return { headersToEnabled, headersToSelect };
  };

  const { headersToSelect: selectedHeader, headersToEnabled: enabledHeaders } =
    getHeadersToEnable();

  const [orderHasBeenClicked, setOrderHasBeenClicked] = useState(false);

  const uniqueUUID = useMemo(
    () => savedOrder?.orderId || "uuid",
    [savedOrder?.orderId],
  );

  const toggleOpenStatusModal = useCallback(() => {
    setOpenStatusModal(prevOpenStatusModal => !prevOpenStatusModal);
  }, []);
  const toggleOpenLaunchModal = useCallback(() => {
    setOpenLaunchModal(prevOpenLaunchModal => !prevOpenLaunchModal);
  }, []);

  const [searchBy, setSearchBy] = useState("");

  return (
    <div className="asset-builder-container">
      {!pathname.includes("review") && (
        <Modal
          title={"Unsaved Changes Detected"}
          visible={saveDialog}
          maskClosable={true}
          onOk={() => {
            if (orderHasBeenClicked) navigate("/asset-builder/orders");
            else navigate(newPath);
          }}
          onCancel={() => {
            setSaveDialog(false);
          }}
          closable={true}
          footer={[
            [
              <Button
                key="cancel"
                className="secondary-btn"
                onClick={() => {
                  setSaveDialog(false);
                  orderHasBeenClicked
                    ? navigate("/asset-builder/orders")
                    : navigate(newPath);
                }}
              >
                Continue without saving
              </Button>,
              <Button
                key="save"
                type="primary"
                onClick={() => {
                  commitOrder().then(() => {
                    orderHasBeenClicked
                      ? navigate("/asset-builder/orders")
                      : navigate(newPath);
                  });
                  setSaveDialog(false);
                }}
              >
                Save
              </Button>,
            ],
          ]}
        >
          <p>Would you like to save your edits before leaving Asset Builder?</p>
        </Modal>
      )}
      {["edit-offer", "create-offer"].every(
        path => !pathname.includes(path),
      ) && (
        <AssetBuilderHeader
          selectedItem={selectedHeader.selectedItem}
          selectedSubItem={selectedHeader.selectedSubItem}
          actionButtons={selectedHeader.actionButtons}
          enabledHeaders={enabledHeaders}
          setOrderHasBeenClicked={setOrderHasBeenClicked}
          countGeneratedInstances={countGeneratedInstances}
          disabledExport={disableExport}
          toggleExportDrawer={toggleExportDrawer}
          toggleForceRenderImages={toggleForceRenderImages}
          disabledCoop={disabledCoop}
          toggleCoopModal={toggleCoopModal}
          isLaunchButtonDisabled={isLaunchButtonDisabled}
          setOpenStatusModal={setOpenStatusModal}
          setOpenLaunchModal={setOpenLaunchModal}
          setSearchBy={setSearchBy}
          searchBy={searchBy}
          setAddingMode={setAddingMode}
        />
      )}

      <Routes>
        <Route
          path="orders"
          element={
            <Orders
              showNewOrderDrawer={showNeworderDrawer}
              toggleNewOrderDrawer={toggleNewOrderDrawer}
            />
          }
        />

        <Route
          path="orders/:orderId/offers/*"
          element={
            <Offers
              key={uniqueUUID}
              checkedOfferFilters={checkedOfferFilters}
              showCoopModal={showCoopModal}
              toggleCoopModal={toggleCoopModal}
              showExportDrawer={showExportDrawer}
              toggleExportDrawer={toggleExportDrawer}
              forceRenderImages={forceRenderImages}
              toggleForceRenderImages={toggleForceRenderImages}
              openStatusModal={openStatusModal}
              setOpenStatusModal={toggleOpenStatusModal}
              openLaunchModal={openLaunchModal}
              setOpenLaunchModal={toggleOpenLaunchModal}
              setIsLaunchButtonDisabled={setIsLaunchButtonDisabled}
              setSearchBy={setSearchBy}
              searchBy={searchBy}
              addingMode={addingMode}
              setAddingMode={setAddingMode}
            />
          }
        />
      </Routes>
      <BackTop
        visibilityHeight={200}
        style={{ bottom: 20, right: 20 }}
        target={() => {
          return (
            (document.querySelector(
              "main.ant-layout-content",
            ) as HTMLElement) || window
          );
        }}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const { assetBuilder, auth, configuration, newOrders } = state;
  const { config } = configuration;
  const {
    fetchingOfferList,
    checkedOfferFilters,
    savedOrder,
    assetInstances,
    disableExport,
    selectedOffers,
    selectedOfferList,
    rawSelectedOffers,
    saveDialog,
    path,
    generalMessage,
    errorMessage,
    redirect,
  } = assetBuilder as IAssetBuilderState;
  const { currentSelectedOrder } = newOrders;

  let totalSelectedOffers = 0;
  const offerSet = new Set();

  for (const offer of selectedOffers) {
    totalSelectedOffers += offer.offers.length;
    for (const offerType of offer.offers) {
      offerSet.add(offerType);
    }
  }
  return {
    loggedInRole: auth?.user?.role || "USER",
    currentSelectedOrder,
    fetchingOfferList,
    checkedOfferFilters,
    generalMessage,
    errorMessage,
    savedOrder,
    disableExport,
    selectedOffers,
    assetInstances,
    rawSelectedOffers,
    saveDialog,
    newPath: path,
    redirect,
    enableLauncher: config?.featureFlags?.enableLauncher
      ? config?.featureFlags?.enableLauncher
      : false,
    offerTypes: Array.from(offerSet).join(", \n"),
    totalSelectedOffers,
    selectedOfferList,
    config,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    commitOrder: () => {
      return dispatch(actions.assetBuilder.commitOrder()) as Promise<void>;
    },
    updateNewOrder: (updateNewOrder: Partial<INewOrderRecord>) => {
      return dispatch(
        actions.newOrders.updateNewOrder(updateNewOrder),
      ) as Promise<void>;
    },
    setAssetInstanceComparator: (assetInstances: AssetInstanceRecord) => {
      dispatch(actions.assetBuilder.setAssetInstanceComparator(assetInstances));
    },
    setSaveDialog: (input: boolean) => {
      dispatch(actions.assetBuilder.setSaveDialog({ input }));
    },
    fetchOrderState: (
      orderId: string,
      assetExportQueryObj?: IAssetExportQueryParamObj,
      redirectType?: string,
    ) => {
      dispatch(
        actions.assetBuilder.fetchOrderState(
          orderId,
          assetExportQueryObj,
          redirectType,
        ),
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetBuilder);
