import { Button, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import styles from "./Notification.module.scss";

interface Props {
  keyMessage: string;
}

export const CloseButton = ({ keyMessage }: Props) => (
  <Button
    type="link"
    onClick={() => {
      message.destroy(keyMessage);
    }}
    className={styles.closeContainer}
  >
    <CloseOutlined className={styles.close} color="#000" />
  </Button>
);
