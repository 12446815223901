import {
  useGetAutocompleteOptionsQuery,
  useGetCurrentUserQuery,
} from "redux/media/media.api";
import { useCallback, useMemo } from "react";
import { SkipToken, skipToken } from "@reduxjs/toolkit/dist/query";
import { useQPBreadcrumb } from "../useQPBreadcrumb";
import { OptionData, OptionGroupData } from "rc-select/lib/interface";
import { uniqBy } from "lodash";
import { Link } from "react-router-dom";

import styles from "./useAutocomplete.module.scss";

export const useAutocomplete = (
  query: string,
): OptionData[] | OptionGroupData[] => {
  const { current: albumId = "0", toFolder } = useQPBreadcrumb();
  const { data: currentUser } = useGetCurrentUserQuery();

  const [assetPayload, folderPayload] = useMemo<
    WDAutoCompletePayload[] | SkipToken[]
  >(() => {
    if (!currentUser || query.length <= 1) {
      return [skipToken, skipToken];
    }

    return [
      {
        query,
        user_id: `${currentUser.id}`,
        size: "15",
        album_id: albumId,
      },
      {
        query,
        user_id: `${currentUser.id}`,
        size: "15",
        album_id: albumId,
        type: "folder",
      },
    ];
  }, [query, albumId, currentUser]);

  const { data: assets } = useGetAutocompleteOptionsQuery(assetPayload);
  const { data: folders } = useGetAutocompleteOptionsQuery(folderPayload);

  const optionMapper = useCallback(
    (arr: WDAutocompleteOption[]): OptionData[] => {
      const getLabel = ({
        type,
        text,
        album_path,
        id,
      }: WDAutocompleteOption) => {
        if (type === "asset") {
          return text;
        } else {
          return (
            <Link to={{ search: toFolder([id]) }}>
              {text} <small>{album_path}</small>
            </Link>
          );
        }
      };

      return uniqBy(arr, "text").map((o: WDAutocompleteOption) => {
        const { id, type, text } = o;
        return {
          value: type === "asset" ? text : "",
          className: styles.optionLinkWrapper,
          label: getLabel(o),
          type: type,
          key: id.toString(),
        };
      });
    },
    [toFolder],
  );

  const options = useMemo(() => {
    const assetsOptionGroup = {
      label: "Assets",
      options: optionMapper(assets?.options || []),
    };
    const folderOptionGroup = {
      label: "Folders",
      options: optionMapper(folders?.options || []),
    };

    return [assetsOptionGroup, folderOptionGroup].filter(
      optGroup => optGroup.options.length > 0,
    );
  }, [assets, folders, optionMapper]);

  return options;
};
