import { Tooltip } from "antd";
import styles from "./MiddleEllipsisText.module.scss";
import { getTextWithEllipses } from "utils/helpers.string";

const WORD_WIDTH = 9;

interface IProps {
  children: string;
  containerWidth: number;
  numberOfRows: number;
}

const MiddleEllipsisText = ({
  children: text,
  containerWidth,
  numberOfRows,
}: IProps) => {
  const splitStringByRow = (string: string, rowLength: number) => {
    return string.match(new RegExp(`.{1,${rowLength}}`, "g"));
  };

  const {
    text: parsedText,
    rowLength,
    hasEllipses,
  } = getTextWithEllipses(text, containerWidth, numberOfRows, WORD_WIDTH);

  const getRows = () => splitStringByRow(parsedText, rowLength);

  const Row = ({ text }: { text: string }) => {
    return <span className={styles.row}>{text}</span>;
  };

  return hasEllipses ? (
    <Tooltip title={text} className={styles.container}>
      {getRows()?.map((text, index) => (
        <Row key={index} text={text} />
      ))}
    </Tooltip>
  ) : (
    <div className={styles.container}>
      {getRows()?.map((text, index) => (
        <Row key={index} text={text} />
      ))}
    </div>
  );
};

export default MiddleEllipsisText;
