import { useEffect, useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import { isEmpty } from "lodash";
import API from "services";
import { IBrand } from "shared/types/brandsAccounts";

type ReturnType = {
  brands: IBrand[];
  isFetching: boolean;
  refetch: () => void;
};

export const useFetchBrands = (): ReturnType => {
  const {
    data,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    refetch,
  } = useFetchBrandsPaginated();

  useEffect(() => {
    if (!hasNextPage || isFetchingNextPage) return;

    fetchNextPage();
  }, [hasNextPage, fetchNextPage, isFetchingNextPage]);

  const brands = useMemo(
    () => data?.pages.flatMap(page => page?.oems ?? []).sort() ?? [],
    [data?.pages],
  );
  return {
    brands,
    isFetching,
    refetch,
  };
};

export const BRANDS_PAGINATED_QUERY_KEY = "oems";

const useFetchBrandsPaginated = () =>
  useInfiniteQuery({
    queryKey: BRANDS_PAGINATED_QUERY_KEY,
    queryFn: getOemsPaginated,
    getNextPageParam: lastPage => {
      return !isEmpty(lastPage?.paginationKey)
        ? lastPage?.paginationKey?.oem_name
        : undefined;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

type GetOemsResponse = {
  result: {
    oems: IBrand[];
    paginationKey: Record<"oem_name", string>;
  } | null;
  error: {
    message: string;
  } | null;
};

const getOemsPaginated = async ({ pageParam }: { pageParam?: string }) => {
  const { result, error } =
    await API.services.oemManagement.getOems<GetOemsResponse>(pageParam);

  if (error) {
    throw Error(error.message || "Unknown error!");
  }

  return result;
};
