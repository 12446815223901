import {
  BrandAccountRecord,
  BrandRecord,
  IDeleteBatchBrandsAccountsResponse,
} from "shared/types/brandsAccounts";

export const getAccountsToDelete = (
  selectedAccounts: string[],
  selectedItems: BrandRecord[],
  editingTarget: BrandAccountRecord | undefined,
) => {
  if (editingTarget?.type === "Account") return [editingTarget.name];

  if (editingTarget?.type === "Brand")
    return editingTarget.children.map(child => child.name);

  const brandsChildren = selectedItems.flatMap(brandSelected =>
    brandSelected.children.map(child => child.name),
  );

  const selectedAccountsWithBrandsChildren = selectedAccounts
    .filter(
      selectedAccount =>
        !selectedItems
          .map(brandSelected => brandSelected.name)
          .includes(selectedAccount),
    )
    .concat(brandsChildren);

  return [...new Set(selectedAccountsWithBrandsChildren)];
};

export const getBrandsToDelete = (
  selectedItems: BrandRecord[],
  editingTarget: BrandAccountRecord | undefined,
) => {
  if (editingTarget?.type === "Account") return [];
  if (editingTarget?.type === "Brand") return [editingTarget.name];

  return selectedItems.map(selectedItem => selectedItem.name);
};

export const getUpdatedBrandsOnDelete = (
  data: IDeleteBatchBrandsAccountsResponse | undefined,
  brands: BrandRecord[],
) => {
  let newBrands: BrandRecord[] = [];

  if (data?.result?.accounts?.length) {
    newBrands = brands.map(brand => {
      const newAccounts = brand.children.filter(
        account => !data?.result?.accounts?.includes(account.name),
      );
      return { ...brand, children: newAccounts };
    });
  } else {
    newBrands = brands;
  }

  if (data?.result?.brands?.length) {
    newBrands = newBrands.filter(
      brand => !data.result?.brands?.includes(brand.name),
    );
  }

  return newBrands;
};
