import { message } from "antd";
import { useCallback, useState } from "react";
import { useUser } from "shared/hooks/useUser";
import { EAElements } from "./useContextAPI";
import { v4 as uuid } from "uuid";
import { IAd } from "shared/types/adLibrary";
import { useOptimisticallyMutateAd } from "shared/hooks/adLibrary/useMutateAd";
import { useMutateInstantExperience } from "shared/hooks/adLibrary/useMutateInstantExperience";

export default (updatedElements: EAElements): [() => void, boolean] => {
  const { mutate: mutateAd } = useOptimisticallyMutateAd();
  const { mutate: mutateInstExp } = useMutateInstantExperience();
  const user = useUser();
  const [isSaving, setIsSaving] = useState(false);
  const onSave = useCallback(() => {
    setIsSaving(true);

    // make sure all required data is there
    const allElementsValid = updatedElements.every(
      ele => ele.data?.ie || ele.data?.ad?.id,
    );
    if (!allElementsValid)
      return message.error("Some of elements are not valid.");

    const isoDate = new Date().toISOString();

    Promise.all(
      updatedElements.map(({ data }) => {
        return new Promise((resolve, reject) => {
          const { ie, ad } = data || {};
          if (!ie && !ad) return reject();

          if (ad) {
            const adUpdate: IAd = {
              ...ad,
              updatedBy: user?.email || "",
              updatedAt: Date.now(),
            };

            mutateAd(adUpdate, {
              onSuccess: data => {
                resolve(data);
              },
              onError: err => {
                reject(err.message);
              },
            });
          } else {
            const instantExperienceUpdate = {
              ...ie,
              id: ie?.id || uuid(),
              updatedBy: user?.email ?? "",
              updatedAt: isoDate,
            };

            mutateInstExp(instantExperienceUpdate, {
              onSuccess: data => {
                resolve(data);
              },
              onError: err => {
                reject(err.message);
              },
            });
          }
        });
      }),
    )
      .then(() => {
        message.success("Save success.");
      })
      .catch(() => {
        message.error("There was an error.");
      })
      .finally(() => {
        setIsSaving(false);
      });
  }, [updatedElements, user, mutateAd, mutateInstExp]);

  return [onSave, isSaving];
};
