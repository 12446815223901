import { Table, TableProps } from "antd";
import "./VirtualTable.scss";
import { useVT } from "virtualizedtableforantd4";
import classNames from "classnames";
import { TableComponents, VtOpts } from "virtualizedtableforantd4/dist/cjs/vt";
import { useMemo } from "react";

type VtProps = {
  scroll: VtOpts["scroll"] & { x?: number };
};

export type VirtualTableProps<RecordType> = Omit<
  TableProps<RecordType>,
  "scroll"
> &
  VtProps;

// eslint-disable-next-line @typescript-eslint/ban-types
function VirtualTable<RecordType extends object = any>(
  props: VirtualTableProps<RecordType>,
) {
  const { scroll, components, dataSource } = props;

  const [vt, setVT] = useVT(() => ({ scroll }), []);

  useMemo(
    () => components && setVT(components as TableComponents),
    [components, setVT],
  );

  return (
    <Table<RecordType>
      {...props}
      dataSource={dataSource}
      className={classNames([props.className, "virtual-table"])}
      pagination={false}
      components={vt}
    />
  );
}

export default VirtualTable;
