export const getShouldDisplayArr = (tags: string[]) => {
  const MAX_CHAR_COUNT = 50;
  const MAX_CHAR_COUNT_PER_TAG = 20;
  const MARGIN_TAG = 3;
  let sumCharCount = 0;

  return tags.map((_, idx) => {
    sumCharCount +=
      (tags[idx].length > MAX_CHAR_COUNT_PER_TAG
        ? MAX_CHAR_COUNT_PER_TAG
        : tags[idx].length) + MARGIN_TAG;

    // fit the max char length and the last tag
    if (sumCharCount === MAX_CHAR_COUNT && idx === tags.length - 1) return true;
    // need to left space for the +# tag
    if (sumCharCount < MAX_CHAR_COUNT - MARGIN_TAG) return true;
    return false;
  });
};
