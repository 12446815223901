import { Col, Row, Spin, Typography } from "antd";
import { FC } from "react";

// statics
import ContstellationLoadingImage from "../../statics/images/Loading.gif";

const clientName = process.env.REACT_APP_AV2_CLIENT;

interface ILoader {
  message: string | undefined;
}
const Loader: FC<ILoader> = ({ message }) => {
  return (
    <Row
      style={{
        height: "100vh",
      }}
    >
      <Col
        style={{
          position: "relative",
          height: "100%",
        }}
        span={24}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
            }}
          >
            {clientName === "internal" ? (
              <img
                style={{
                  width: "10em",
                  display: "block",
                  margin: "0 auto",
                }}
                src={ContstellationLoadingImage}
                alt="loading"
              />
            ) : (
              <Spin size="large" tip="Loading..." />
            )}
          </div>
          {clientName === "internal" && (
            <Typography.Title level={4}>{message}</Typography.Title>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default Loader;
