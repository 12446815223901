import { CalendarOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useMediaActions } from "redux/media/media.slice";
import { TooltipPerms } from "./TooltipPerms";
import { usePermsAssets } from "./usePermsAssets";
import { useSelected } from "./useSelected";

export const AssetsExpire = () => {
  const { setAssetsToExpire } = useMediaActions();
  const { selectedAssets, allAssets } = useSelected();
  const allowed = usePermsAssets("move", true);
  const enabled = allAssets && allowed;

  const handleClick = () => {
    setAssetsToExpire(selectedAssets);
  };

  return (
    <TooltipPerms action="Set Expiration" allowed={allowed} enabled={allAssets}>
      <Button
        shape="circle"
        icon={<CalendarOutlined />}
        onClick={handleClick}
        disabled={!enabled}
      />
    </TooltipPerms>
  );
};
