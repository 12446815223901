import { fabric } from "fabric";
import { useCallback } from "react";
import { useHotkeys } from "react-hotkeys-hook";

type Args = {
  canvas?: fabric.Canvas;
};
export default (args: Args) => {
  const moveObjectBy = useCallback(
    (step: number, code: string, obj: fabric.Object) => {
      switch (code) {
        case "ArrowLeft":
          obj.set({ left: (obj.left || 0) - step });
          break;
        case "ArrowUp":
          obj.set({ top: (obj.top || 0) - step });
          break;
        case "ArrowRight":
          obj.set({ left: (obj.left || 0) + step });
          break;
        case "ArrowDown":
          obj.set({ top: (obj.top || 0) + step });
          break;
      }
    },
    [],
  );

  useHotkeys(
    "left,down,right,up",
    e => {
      const activeObj = args.canvas?.getActiveObject();
      if (!activeObj) return;

      e.preventDefault();
      const { code } = e;

      moveObjectBy(1, code, activeObj);
      args.canvas?.requestRenderAll();
    },
    undefined,
    [args.canvas],
  );

  useHotkeys(
    "shift+left, shift+right, shift+up, shift+down",
    e => {
      const activeObj = args.canvas?.getActiveObject();
      if (!activeObj) return;

      e.preventDefault();
      const { code } = e;

      moveObjectBy(10, code, activeObj);
      args.canvas?.requestRenderAll();
    },
    undefined,
    [args.canvas],
  );
  return [];
};
