import { Button, Form, Upload } from "antd";
import { UploadProps } from "antd/lib/upload/interface";
import { camelCase } from "lodash";
import TitleWithDescription from "shared/components/uploadAsset/TitleWithDescription";
import { CAMConfig, CAMSelect } from "shared/components/media";
import styles from "./UploadAsset.module.scss";
import { useValidateFiles } from "./useValidateFiles";
import { IUploadAssetProps } from "./UploadAsset";
import { Uploads } from "shared/types/adLibrary";

type UpdatedFile = {
  uid: string;
  name: string;
  url: string;
};

export type OnUploadChange<T> = (args: OnFilesChangeProps<T>) => void;
export type AddFilePayload<T> = {
  type: "add";
  fieldKey: keyof T;
  value: UpdatedFile[];
};

export type RemoveFilePayload<T> = {
  type: "remove";
  fieldKey: keyof T;
  value: UpdatedFile;
};

type OnFilesChangeProps<T> = AddFilePayload<T> | RemoveFilePayload<T>;

type UploadAssetProps<T> = Omit<IUploadAssetProps<T>, "onUploadChange"> & {
  onUploadChange: OnUploadChange<T>;
};

export const UploadAssetFromCAM = ({
  title,
  fileList,
  fieldKey,
  required,
  onUploadChange,
  imageAssetDetail,
  validationSchema,
  ...lengthProps
}: UploadAssetProps<Uploads>) => {
  const { validationError, formRules, computedFiles } = useValidateFiles({
    fileList,
    schema: validationSchema,
    title,
  });

  const onChange: UploadProps["onChange"] = async ({ file }) => {
    if (!file.url) return;
    onUploadChange({
      type: "remove",
      fieldKey,
      value: { ...file, url: file.url ?? "" },
    });
  };

  return (
    <Form.Item
      data-cy={`upload-asset-${fieldKey.toString()}`}
      name={camelCase(title)}
      label={
        <TitleWithDescription
          title={title}
          info={imageAssetDetail[title].info}
          required={required}
          {...lengthProps}
        />
      }
      colon={false}
      className={styles.formItem}
      help={validationError !== "" ? validationError : undefined}
      rules={formRules}
    >
      <Upload
        listType="picture-card"
        fileList={computedFiles}
        onChange={onChange}
        data-cy={fieldKey}
      />
      {CAMConfig.active && fileList.length < lengthProps.maxLength && (
        <CAMSelect
          maxSelected={lengthProps.maxLength - fileList.length}
          title={`Select or upload (max ${
            lengthProps.maxLength - fileList.length
          })`}
          fileTypes={["png", "jpeg", "jpg"]}
          onClose={async (assets: WDAsset[]) => {
            const processedAssets = assets.map(asset => ({
              uid: asset.id,
              name: asset.filename,
              url: asset.hiResURLRaw,
              thumbUrl: asset.hiResURLRaw,
            }));

            onUploadChange({
              type: "add",
              fieldKey,
              value: processedAssets,
            });
          }}
        >
          <Button disabled={false}>Add from CAM</Button>
        </CAMSelect>
      )}
    </Form.Item>
  );
};
