import { ReactNode } from "react";

import styles from "./Cell.module.scss";

type Props = {
  children: ReactNode;
};

export const Cell = ({ children }: Props) => {
  return <div className={styles.host}>{children}</div>;
};
