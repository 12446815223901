import {
  CheckOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import { Button, Input, Modal, Space, Tooltip, Upload, message } from "antd";
import { RcFile } from "antd/lib/upload";
import { uniq } from "lodash";
import Papa from "papaparse";
import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useRef,
  useState,
} from "react";
import { useAdEngineActions } from "redux/assetExporter/assetExporter.slice";
import {
  customUploadErrorMsg,
  duplicateHeaderErrorMsg,
} from "shared/constants/assetExporter";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { CsvTemplateOptions } from "./CsvTemplateOptions";
import styles from "./CustomUpload.module.scss";

type Props = {
  setCustomFile: Dispatch<SetStateAction<RcFile | undefined>>;
  customFile?: RcFile;
};

export const CustomUpload = (props: Props) => {
  const { feedDrawer } = useAppSelector(
    state => state.assetExporter.sourceFeed,
  );
  const actions = useAdEngineActions();

  const [displayWarning, setDisplayWarning] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const enableUpload = useRef(false);
  const replaceFileBtn = useRef<HTMLButtonElement>(null);

  const parsePromise = (file: RcFile) => {
    return new Promise<string[]>(resolve => {
      Papa.parse<string[]>(file, {
        step: (results, parser) => {
          resolve(results.data);
          parser.abort();
        },
      });
    });
  };

  const beforeUpload = async (file: RcFile) => {
    try {
      setUploadError("");
      if (file.type !== "text/csv") {
        message.error("Only files of type CSV can be uploaded");
      } else {
        const headers: string[] = await parsePromise(file);
        const duplicatedHeader = uniq(headers).length !== headers.length;
        if (duplicatedHeader) {
          setUploadError(duplicateHeaderErrorMsg);
          return Upload.LIST_IGNORE;
        }
        props.setCustomFile(file);
        actions.setCustomFileName(file.name);
      }
      return false;
    } catch (error) {
      setUploadError(customUploadErrorMsg);
      return Upload.LIST_IGNORE;
    }
  };

  const replaceFile = async (file: RcFile) => {
    try {
      setUploadError("");
      const headers: string[] = await parsePromise(file);
      const duplicatedHeader = uniq(headers).length !== headers.length;
      if (duplicatedHeader) {
        setUploadError(duplicateHeaderErrorMsg);
        props.setCustomFile(undefined);
        actions.disableNextButton();
        return Upload.LIST_IGNORE;
      }

      props.setCustomFile(file);
      actions.enableNextButton();
      enableUpload.current = false;
    } catch (error) {
      setUploadError(customUploadErrorMsg);
      return Upload.LIST_IGNORE;
    }
  };

  const isCreate = feedDrawer.drawerMode === "CREATE";
  const fileName = props.customFile?.name;

  const openConfirmModal: MouseEventHandler<HTMLElement> = e => {
    if (!enableUpload.current && !isCreate) {
      e.stopPropagation();
      setDisplayWarning(true);
    }
  };
  const handleOk = () => {
    enableUpload.current = true;
    setDisplayWarning(false);
    replaceFileBtn.current?.click();
  };
  const handleDeleteFile = () => {
    props.setCustomFile(undefined);
    enableUpload.current = true;
  };

  return (
    <>
      {!props.customFile && isCreate ? (
        <>
          <p>Choose your document to be uploaded</p>
          <div>
            <Upload.Dragger
              multiple={false}
              accept="text/csv"
              beforeUpload={beforeUpload}
            >
              <p className="ant-upload-drag-icon">
                <CloudUploadOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag CSV file to this area to upload
              </p>
            </Upload.Dragger>
          </div>
          {uploadError && (
            <div className={styles.uploadError}>{uploadError}</div>
          )}
          <CsvTemplateOptions type="custom" />
        </>
      ) : (
        <div style={{ paddingBottom: "1em" }}>
          <Modal
            title="Confirm csv Update"
            visible={displayWarning}
            onCancel={() => setDisplayWarning(false)}
            onOk={() => handleOk()}
          >
            Uploading a new file will overwrite any existing data, including any
            edits made to the feed. Smart Columns and Rules will still apply. Do
            you wish to continue?
          </Modal>
          <Space size="middle">
            <div>
              <PaperClipOutlined /> {fileName ?? "Click to upload a new file"}
              {isCreate && (
                <Button
                  icon={<DeleteOutlined />}
                  type={"text"}
                  onClick={handleDeleteFile}
                />
              )}
            </div>

            {!isCreate && (
              <>
                <Tooltip title="Upload another file instead">
                  <Upload
                    multiple={false}
                    beforeUpload={replaceFile}
                    showUploadList={false}
                    accept="text/csv"
                  >
                    <Button
                      icon={<CloudUploadOutlined />}
                      onClick={openConfirmModal}
                      ref={replaceFileBtn}
                    />
                  </Upload>
                </Tooltip>
                <CheckOutlined style={{ color: "#52C41A", fontSize: 24 }} />
              </>
            )}
          </Space>
          {uploadError && (
            <div className={styles.uploadError}>{uploadError}</div>
          )}
          <div style={{ paddingTop: "1em" }}>
            <span>Feed name (optional)</span>
            <Input
              value={feedDrawer.feedName}
              onChange={e => actions.setFeedName(e.target.value)}
            />
          </div>
        </div>
      )}
    </>
  );
};
