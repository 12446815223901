import { useCallback } from "react";
import { useAppSelector } from "shared/hooks/useAppSelector";
import {
  mapAdsToLoadDataFromTreeData,
  doesAdToLoadHaveQAErrorIssues,
} from "../../utils";
import { adsToLoadTableColumnNames } from "../../constants";
import { useDispatch } from "react-redux";
import { setAdLoadState } from "redux/adLibrary/adLibrary.slice";
import useFacebookData from "./useFacebookData";
import {
  selectDestination,
  selectSelectedAccounts,
  selectSelectedAds,
} from "redux/adLibrary/adLibrary.selectors";
import { useFetchCampaignsToCreate } from "./useFetchCampaignsToCreate";

const useBuildAdsToCreate = () => {
  const dispatch = useDispatch();

  const selectedAccounts = useAppSelector(selectSelectedAccounts);
  const destination = useAppSelector(selectDestination);
  const selectedAds = useAppSelector(selectSelectedAds);

  const {
    instantExperiences,
    loadingData,
    pages,
    productCatalogs,
    productSets,
    selectedStores,
  } = useFacebookData();

  const { isLoadingCampaigns, campaigns } = useFetchCampaignsToCreate();

  const buildAdsToLoad = useCallback(() => {
    if (loadingData || isLoadingCampaigns || !destination?.treeData) return;

    const initAdsToLoad = mapAdsToLoadDataFromTreeData({
      pages: pages ?? [],
      treeData: destination.treeData,
      campaigns: campaigns ?? [],
      selectedAds: selectedAds ?? [],
      productSets,
      selectedStores,
      productCatalogs: productCatalogs ?? [],
      selectedAccounts: selectedAccounts ?? [],
      instantExperiences,
    });
    const initSelectedRowKeys = initAdsToLoad
      .filter(adToLoad => !doesAdToLoadHaveQAErrorIssues(adToLoad))
      .map(adsToLoad => adsToLoad.key);

    dispatch(
      setAdLoadState({
        prop: "review",
        data: {
          adsToLoad: initAdsToLoad,
          selectedRowKeys: initSelectedRowKeys,
          selectedColumnKeys: adsToLoadTableColumnNames,
        },
      }),
    );
  }, [
    loadingData,
    isLoadingCampaigns,
    destination?.treeData,
    pages,
    campaigns,
    selectedAds,
    productSets,
    selectedStores,
    productCatalogs,
    selectedAccounts,
    instantExperiences,
    dispatch,
  ]);

  return { buildAdsToLoad };
};

export default useBuildAdsToCreate;
