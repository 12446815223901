export const adsImportCSVTemplateUrl =
  "https://alexia-share.constellationenv.com/templates/automatic_ads_uploader_template.csv";

export const adEngineAssetBuilderUrl =
  "https://alexia-share.constellationenv.com/templates/asset_builder_template.csv";

export const adEngineFBAdsUrl =
  "https://alexia-share.constellationenv.com/templates/facebook_ads_template.csv";

export const adEngineFBAutoUrl =
  "https://alexia-share.constellationenv.com/templates/fb_catalog_vehicle_offer.csv";

export const adEngineFBProductUrl =
  "https://alexia-share.constellationenv.com/templates/fb_catalog_products.csv";

export const adEngineGoogleMerchantUrl =
  "https://alexia-share.constellationenv.com/templates/google_merchant_center.csv";

export const adEnginePinterestProductUrl =
  "https://alexia-share.constellationenv.com/templates/pinterest_product_sample_csv_feed.csv";

export const adEngineSnapchatProductUrl =
  "https://alexia-share.constellationenv.com/templates/snapchat_catalog_reqs.csv";

export const adEngineTiktokProductUrl =
  "https://alexia-share.constellationenv.com/templates/tiktok_catalogues_template.csv";

export const alexiaAppId = "1480296522041789";

export const dynamicFontMappingsUrl =
  "https://alexia-fonts.s3.amazonaws.com/mappings.json";

export const facebookVersion = "v15.0";

export const ftpSecretKey = "pxFkaK4tCcXcavZAry2hh";

export const instantExperiencesImportCSVTemplateUrl =
  "https://alexia-share.constellationenv.com/templates/automatic_instant_experiences_uploader.csv";

export const shouldEnableNotifications = false;
