import { MediaLogoItem } from "./MediaLogoItem";

import styles from "./MediaLogosPanelBody.module.scss";

type Props = {
  images: string[];
};

export const MediaOemLogosPanelBody = ({ images = [] }: Props) => {
  return (
    <>
      <div className={styles.host}>
        {images.map((logo: string) => {
          return <MediaLogoItem key={logo} logo={logo} />;
        })}
      </div>
    </>
  );
};
