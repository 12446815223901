import sortBy from "lodash/sortBy";
import { PlusOutlined } from "@ant-design/icons";
import {
  AutoComplete,
  AutoCompleteProps,
  Button,
  message,
  Modal,
  Select,
  Spin,
  Typography,
} from "antd";
import { useMemo, useState } from "react";
import styles from "./LabelSelect.module.scss";
import { ILabel } from "shared/types/inputValues";
import { useFetchLabels } from "shared/hooks/useFetchLabels";
import { useMutateLabel } from "shared/hooks/useMutateLabel";

// Possible TO DO: enable optional multi-select

interface ILabelSelectProps {
  placeholder?: string;
  currentValue?: string;
  allowInsert?: boolean;
  disabled?: boolean;
  allowFreeText?: boolean;
}

interface ILabelSelectHandlers {
  onLabelChange: (labelValue: string) => void;
}

const LabelSelect: React.VFC<ILabelSelectProps & ILabelSelectHandlers> = ({
  onLabelChange,
  currentValue,
  allowInsert,
  placeholder,
  disabled,
  allowFreeText,
}) => {
  const [searchLabelInput, setSearchLabelInput] = useState<string>("");

  const {
    data,
    isLoading,
    isError: isFetchError,
    error: fetchError,
  } = useFetchLabels();
  const labels = data?.labels;

  const {
    mutate: mutateLabel,
    isLoading: isSavingLabel,
    isError: isMutateError,
    error: mutateError,
  } = useMutateLabel();

  const filteredLabels = useMemo(() => {
    const newLabels = (labels || []).filter(item => {
      return item.name.toLowerCase().includes(searchLabelInput.toLowerCase());
    });
    const sortedFilteredLabels = sortBy([...newLabels], "name");
    return sortedFilteredLabels;
  }, [labels, searchLabelInput]);

  const onAddLabel = () => {
    if (searchLabelInput.trim() === "") {
      Modal.warning({
        title: "The label is not valid. Please enter valid label.",
      });

      return;
    }

    mutateLabel(searchLabelInput, {
      onSuccess: () => {
        onLabelChange(searchLabelInput);
        setSearchLabelInput("");
      },
      onError: error => {
        message.warning(error?.message || `An unknown error occurred.`);
      },
    });
  };

  const addLabelItem = (
    <Button className={styles.addLabelButton} type="text" onClick={onAddLabel}>
      <PlusOutlined />
      <Typography.Text>
        Add new label <b>{searchLabelInput}</b>
      </Typography.Text>
    </Button>
  );

  const loading = isLoading || isSavingLabel;

  if (isFetchError) {
    message.error(
      fetchError?.message || "An unknown error occurred while fetching labels.",
    );
  }

  if (isMutateError) {
    message.error(
      mutateError?.message ||
        "An unknown error occurred while inserting label.",
    );
  }

  const placeholderText = isSavingLabel
    ? "Inserting label..."
    : isLoading
    ? "Loading..."
    : placeholder;

  const isAbleToAddLabel = useMemo(
    () =>
      allowInsert &&
      !!searchLabelInput.length &&
      filteredLabels.every(value => value.name !== searchLabelInput),
    [allowInsert, searchLabelInput, filteredLabels],
  );

  const props: AutoCompleteProps = {
    showSearch: true,
    style: { width: "100%" },
    placeholder: placeholderText,
    disabled: disabled,
    dropdownRender: menu => {
      return (
        <>
          {!!menu.props.options.length && menu}
          {!menu.props.options.length && isLoading && <Spin size="small" />}
          {isAbleToAddLabel && addLabelItem}
        </>
      );
    },
    onSearch: setSearchLabelInput,
    onChange: onLabelChange,
    value: currentValue,
    children: filteredLabels.map((label: ILabel) => (
      <Select.Option value={label.name} key={label.name} data-cy="label-select-option">
        {label.name}
      </Select.Option>
    )),
  };

  return allowFreeText ? (
    <AutoComplete {...props} allowClear data-cy="label-select" />
  ) : (
    <Select {...props} loading={loading} data-cy="label-select" />
  );
};

export default LabelSelect;
