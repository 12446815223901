import { interceptResponse } from "./interceptors";

class HttpClientClass {
  private _isAdmin: boolean = false;
  private _token: string = "";

  public set isAdmin(isAdmin: boolean) {
    this._isAdmin = isAdmin;
  }

  public set token(token: string) {
    this._token = token;
  }

  public async httpRequest<TResponse>(
    url: string,
    body?: any,
    customConfig: RequestInit = {},
    isPrivate: boolean = true,
  ): Promise<TResponse> {
    const accessToken = isPrivate ? this._token : null;

    const headers: HeadersInit = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    const config: RequestInit = {
      method: body ? "POST" : "GET",
      ...customConfig,
      headers: {
        ...headers,
        ...(customConfig.headers ?? []),
      },
    };
    if (body) {
      config.body = JSON.stringify(body);
    }

    return fetch(url, config)
      .then<TResponse>(async response => {
        let data;
        if (response.status !== 204) {
          data = await response.json();
        }

        await interceptResponse(response, data, this._isAdmin);

        if (response.ok) {
          return data;
        } else {
          return Promise.reject(data);
        }
      })
      .catch(err => err);
  }

  public get<TResponse>(
    url: string,
    customConfig: RequestInit = {},
    isPrivate: boolean = true,
  ): Promise<TResponse> {
    return this.httpRequest<TResponse>(
      url,
      null,
      {
        ...customConfig,
        method: "GET",
      },
      isPrivate,
    );
  }

  public post<TResponse>(
    url: string,
    body: any,
    customConfig: RequestInit = {},
    isPrivate: boolean = true,
  ): Promise<TResponse> {
    return this.httpRequest<TResponse>(
      url,
      body,
      {
        ...customConfig,
        method: "POST",
      },
      isPrivate,
    );
  }

  public patch<TResponse>(
    url: string,
    body: any,
    customConfig: RequestInit = {},
    isPrivate: boolean = true,
  ): Promise<TResponse> {
    return this.httpRequest<TResponse>(
      url,
      body,
      {
        ...customConfig,
        method: "PATCH",
      },
      isPrivate,
    );
  }

  public put<TResponse>(
    url: string,
    body: any,
    customConfig: RequestInit = {},
    isPrivate: boolean = true,
  ): Promise<TResponse> {
    return HttpClient.httpRequest<TResponse>(
      url,
      body,
      {
        ...customConfig,
        method: "PUT",
      },
      isPrivate,
    );
  }

  public delete<TResponse>(
    url: string,
    body?: any,
    customConfig: RequestInit = {},
  ): Promise<TResponse> {
    return this.httpRequest<TResponse>(url, body, {
      ...customConfig,
      method: "DELETE",
    });
  }
}

const HttpClient = new HttpClientClass();

export default HttpClient;
