import { useQuery } from "react-query";
import API from "services";
import { ITagFetchResponse } from "services/tagManagement";

const getTags = async () => {
  const { result, error } =
    await API.services.tagManagement.getTags<ITagFetchResponse>();

  if (error || !result) {
    throw Error(error?.message || "Error fetching tags");
  }

  return result;
};

export const useFetchTags = () => {
  return useQuery<ITagFetchResponse["result"], Error>("tags", getTags, {
    staleTime: Infinity,
  });
};
