import { useBrandsAccountsContext } from "./contexts/BrandsAccountsContext";
import { AccountDrawer } from "./drawers/account/AccountDrawer";
import { EditBrandDrawer } from "./drawers/EditBrandDrawer";
import { NewBrandDrawer } from "./drawers/NewBrandDrawer";

export const DrawerSelector = () => {
  const { drawerState, isDrawerOpen, resetDrawer } = useBrandsAccountsContext();

  switch (drawerState.drawerMode) {
    case "CREATE_BRAND":
      return <NewBrandDrawer onCancel={resetDrawer} visible={isDrawerOpen} />;
    case "UPDATE_BRAND":
      return typeof drawerState.targetElement !== "string" &&
        drawerState.targetElement?.type === "Brand" ? (
        <EditBrandDrawer
          onCancel={() => {
            resetDrawer();
          }}
          visible={isDrawerOpen}
          brandToEdit={drawerState.targetElement}
        />
      ) : (
        <></>
      );
    case "CREATE_ACCOUNT":
      return (
        <AccountDrawer
          visible={isDrawerOpen}
          title="New Account"
          mode={"CREATE"}
          onCancel={resetDrawer}
        />
      );
    case "UPDATE_ACCOUNT":
      return typeof drawerState.targetElement !== "string" &&
        drawerState.targetElement?.type === "AccountEdit" ? (
        <AccountDrawer
          visible={isDrawerOpen}
          title="Edit Account"
          mode={"UPDATE"}
          onCancel={resetDrawer}
          accountToUpdate={drawerState.targetElement.account}
        />
      ) : (
        <></>
      );
    default:
      return <></>;
  }
};
