import { Button } from "antd";
import { useMemo } from "react";
import { CAMSelect } from "shared/components/media";
import {
  IAssetBuildInstance,
  ILifestyleImage,
} from "shared/types/assetBuilder";
import { TTemplateType } from "shared/types/designStudio";
import { mapToImage } from "../mapToImage";

type Props = {
  type: TTemplateType | "";
  instance: IAssetBuildInstance | null;
  imageSelection: (selectedImg: ILifestyleImage) => Promise<void>;
};

export const MediaImageSelection = ({
  type,
  instance,
  imageSelection,
}: Props) => {
  const payload: MGSearchObj = useMemo(() => {
    const keyWordParams = {
      keywords: type === "lifestyle" ? ["Lifestyle"] : "theme",
    };
    const themeParams = {
      width: String(instance?.template?.artboard.width ?? 0),
      height: String(instance?.template?.artboard.height ?? 0),
    };
    const lifeStyleParms = {
      make: instance?.selectedOffer?.offerData.make,
    };

    const imageParams = type === "lifestyle" ? lifeStyleParms : themeParams;
    return {
      ...keyWordParams,
      ...imageParams,
    };
  }, [instance, type]);

  return (
    <CAMSelect
      width="50vw"
      layout="grid"
      maxSelected={1}
      search={payload}
      fileTypes={["png", "jpeg", "jpg"]}
      onClose={async (assets: WDAsset[]) => {
        const imageMapper = mapToImage(
          type === "lifestyle" ? "lifestyle" : "theme_bg",
        );
        const [image] = imageMapper({ items: assets });
        await imageSelection(image);
      }}
    >
      <Button type="link">Advanced CAM Search</Button>
    </CAMSelect>
  );
};
