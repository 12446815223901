import { memo, ReactElement, useMemo } from "react";
import { IndustryType } from "shared/types/shared";
import { industryType, isEnvVarEquals } from "utils/helpers";
import { GenericLocationSelect } from "shared/components/select/GenericLocationSelect";

interface IProps {
  selectedLocations?: string[];
  defaultValue?: string[];
  disabled?: boolean;
  clientType?: IndustryType;
  style?: React.CSSProperties;
  onChange?(selectedLocations: string[]): void;
  onFocus?: React.FocusEventHandler<HTMLElement>;
}

const MultipleLocation = (props: IProps): ReactElement => {
  const { clientType } = props;
  const placeholderText = useMemo(() => {
    if (!clientType || !isEnvVarEquals("CLIENT", "internal")) {
      return industryType("auto") ? "Select Locations" : "Select States";
    }
    return clientType === "auto" ? "Select Locations" : "Select States";
  }, [clientType]);

  return (
    <GenericLocationSelect
      style={props.style}
      placeholder={placeholderText}
      defaultValue={props.defaultValue}
      value={props.selectedLocations}
      disabled={props.disabled}
      multiple={true}
      onFocus={props.onFocus}
      onChange={props.onChange}
    />
  );
};

const MultipleLocationSelect = memo(MultipleLocation);
export default MultipleLocationSelect;
