import { Typography } from "antd";

export type HyperlinkProps = {
  href: string;
  limit?: number;
  text?: string;
  target?: string;
};

const Hyperlink = ({ href, text, target = "_blank" }: HyperlinkProps) => {
  const link = /http(s)?\:\/\//.test(href) ? href : `//${href}`;
  return (
    <Typography.Link target={target} rel="noreferrer" href={link}>
      {text ?? href}
    </Typography.Link>
  );
};

export default Hyperlink;
