import { IExtendedFabricObject } from "shared/types/designStudio";
import { getDataFromDragEvent } from "utils/canvas/helpers.logo";

export enum FabricEvent {
  AFTER_RENDER = "after:render",
  OBJECT_MODIFIED = "object:modified",
  OBJECT_ADDED = "object:added",
  OBJECT_SELECTED = "object:selected",
  OBJECT_MOVED = "object:moved",
  OBJECT_SCALED = "object:scaled",
  SELECTION_CREATED = "selection:created",
  SELECTION_UPDATED = "selection:updated",
  SELECTION_CLEARED = "selection:cleared",
  MOUSE_DOWN = "mouse:down",
  MOUSE_UP = "mouse:up",
  MOUSE_OVER = "mouse:over",
  MOUSE_OUT = "mouse:out",
  TEXT_CHANGED = "text:changed",
  TEXT_SELECTION_CHANGED = "text:selection:changed",
  MOUSE_WHEEL = "mouse:wheel",
}

export const EventsAddableToHistory: FabricEvent[] = [
  FabricEvent.OBJECT_ADDED,
  FabricEvent.OBJECT_MOVED,
  FabricEvent.OBJECT_SCALED,
  FabricEvent.TEXT_CHANGED,
];

export default (
  canvas: fabric.Canvas,
  onComplete: (eventType: FabricEvent, target: any) => void,
) => {
  canvas.on(FabricEvent.AFTER_RENDER, () => {
    onComplete(FabricEvent.AFTER_RENDER, null);
  });

  // attach event handlers here
  canvas.on(FabricEvent.OBJECT_MODIFIED, e => {
    onComplete(FabricEvent.OBJECT_MODIFIED, e.target ? e.target : null);
  });

  canvas.on(FabricEvent.OBJECT_ADDED, e => {
    onComplete(FabricEvent.OBJECT_ADDED, e.target ? e.target : null);
  });

  canvas.on(FabricEvent.SELECTION_CREATED, e => {
    onComplete(FabricEvent.SELECTION_CREATED, {
      selected: (e as any).selected ? (e as any).selected : [],
      deselected: (e as any).deselected ? (e as any).deselected : [],
    });
  });

  canvas.on(FabricEvent.SELECTION_UPDATED, e => {
    onComplete(FabricEvent.SELECTION_UPDATED, {
      selected: (e as any).selected ? (e as any).selected : [],
      deselected: (e as any).deselected ? (e as any).deselected : [],
    });
  });

  canvas.on(FabricEvent.SELECTION_CLEARED, e => {
    onComplete(FabricEvent.SELECTION_CLEARED, {
      selected: [],
      deselected: (e as any).deselected ? (e as any).deselected : [],
    });
  });

  canvas.on(FabricEvent.MOUSE_OVER, e => {
    onComplete(FabricEvent.MOUSE_OVER, e.target);
  });

  canvas.on(FabricEvent.MOUSE_OUT, e => {
    onComplete(FabricEvent.MOUSE_OUT, e.target);
  });

  canvas.on(FabricEvent.MOUSE_DOWN, e => {
    onComplete(FabricEvent.MOUSE_DOWN, e.target);
  });

  canvas.on(FabricEvent.MOUSE_UP, e => {
    onComplete(FabricEvent.MOUSE_UP, e.target);
  });

  canvas.on(FabricEvent.TEXT_CHANGED, e => {
    onComplete(FabricEvent.TEXT_CHANGED, e.target);
  });

  canvas.on(FabricEvent.MOUSE_WHEEL, e => {
    onComplete(FabricEvent.MOUSE_WHEEL, e);
  });

  canvas.on(FabricEvent.OBJECT_MOVED, e => {
    onComplete(FabricEvent.OBJECT_MOVED, e);
  });

  canvas.on(FabricEvent.OBJECT_SCALED, e => {
    onComplete(FabricEvent.OBJECT_SCALED, e);
  });

  canvas.on(FabricEvent.TEXT_SELECTION_CHANGED, e => {
    onComplete(FabricEvent.TEXT_SELECTION_CHANGED, e);
  });
};

type OnDropArgs = {
  canvasArea?: IExtendedFabricObject;
  canvasAreaMargin: { top: number; left: number };
  cb: (args: ReturnType<typeof getDataFromDragEvent>) => void;
};
export const onDrop = (args: OnDropArgs) => {
  return args.canvasArea
    ? (e: any) => {
        e.preventDefault();

        const { canvasArea, canvasAreaMargin, cb } = args;
        const { width = 0, height = 0 } = canvasArea || {};
        const canvasDimension = {
          width,
          height,
        };

        const result = getDataFromDragEvent(e, canvasDimension, {
          top: canvasAreaMargin.top,
          left: canvasAreaMargin.left,
        });

        cb(result);
      }
    : undefined;
};
