interface IGenericError {
  message: string;
  errorObject?: string | undefined | unknown;
}

export default class GenericError extends Error {
  public message: string;
  public errorObject?: any;

  constructor({ message = "", errorObject }: IGenericError) {
    super(message);

    this.message = message;
    this.errorObject = errorObject ? JSON.stringify(errorObject) : "";
  }
}
