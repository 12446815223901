import { CSSProperties, MouseEvent } from "react";
import classNames from "classnames";
import { RightOutlined } from "@ant-design/icons";
import styles from "./ExpandIcon.module.scss";
import { Tooltip } from "antd";

interface DefaultExpandIconProps<RecordType> {
  onExpand: (record: RecordType, e: MouseEvent<HTMLElement>) => void;
  record: RecordType;
  expanded: boolean;
  expandable: boolean;
  tooltip?: string;
  style?: CSSProperties;
}

function ExpandIcon<RecordType>({
  onExpand,
  record,
  expanded,
  expandable,
  tooltip,
  style,
}: DefaultExpandIconProps<RecordType>) {
  return expandable ? (
    <Tooltip title={tooltip} popupVisible={!!tooltip} placement="right">
      <button
        aria-label={expanded ? "Collapse" : "Expand"}
        type="button"
        onClick={e => {
          onExpand(record, e!);
          e.stopPropagation();
        }}
        className={classNames(styles.icon, {
          [styles.expanded]: expanded,
        })}
        style={style}
      >
        <RightOutlined style={{ fontSize: 12 }} />
      </button>
    </Tooltip>
  ) : null;
}

export default ExpandIcon;
