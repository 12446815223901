import { SingletonOfferKeys } from "shared/types/assetBuilder";

export const getTitle = (key: SingletonOfferKeys) => {
  switch (key) {
    case "msrp":
      return "MSRP";
    case "daysInStock":
      return "Days In Stock";
    case "monthlyPayment":
      return "Lease Payment";
    case "financePayment":
      return "Finance Payment";
    case "savingsOffMSRP":
      return "Total Savings Off MSRP";
    case "finalPrice":
      return "Final Price";
    default:
      return "-";
  }
};

export const getDataType = (key: SingletonOfferKeys): "currency" | "number" => {
  switch (key) {
    case "daysInStock":
      return "number";

    default:
      return "currency";
  }
};
