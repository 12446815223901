interface IProps {
  title?: string;
  textLengthToEllipsis?: number;
}

interface IHandlers {
  onImageClick?: () => void;
  onCellClick?: () => void;
}

import classNames from "classnames";
import styles from "./NameCell.module.scss";
import EllipsisText from "shared/components/card/EllipsisText";

type NameCellProps = IProps & IHandlers;

const NameCell = ({
  title = "",
  onCellClick,
  textLengthToEllipsis = 45,
}: NameCellProps) => {
  return (
    <div
      className={styles.flexCenter}
      style={{ cursor: !!onCellClick ? "pointer" : "default" }}
    >
      <div onClick={onCellClick}>
        <EllipsisText
          className={classNames({ [styles.clickable]: !!onCellClick })}
          text={title}
          charDisplayLimit={textLengthToEllipsis}
        />
      </div>
    </div>
  );
};

export default NameCell;
