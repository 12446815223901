import { Tree } from "antd";
import { TreeProps } from "antd/lib/tree";
import { CaretDownOutlined } from "@ant-design/icons";
import { useAdsTreeData } from "./adsTree/adsTreeContext";
import { useWindowSize } from "shared/hooks/useWindowSize";

const AdsTree = () => {
  const { treeData, checkedKeys, setCheckedKeys } = useAdsTreeData();
  const { windowInnerHeight } = useWindowSize();

  return (
    <Tree
      defaultExpandAll
      multiple
      showLine={{ showLeafIcon: true }}
      checkable
      selectable={false}
      style={{ width: 350 }}
      height={windowInnerHeight - 150}
      switcherIcon={<CaretDownOutlined />}
      treeData={treeData}
      onCheck={setCheckedKeys as TreeProps["onCheck"]}
      checkedKeys={checkedKeys}
    />
  );
};

export default AdsTree;
