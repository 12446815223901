import { useDataList } from "shared/components/dataList";
import ToolbarTable from "shared/components/toolbarTable/ToolbarTable";
import { useBrandsAccountsToolbar } from "shared/hooks/brandsAccountsManagement/useBrandsAccountsToolbar";
import { ToolbarButton } from "shared/types/adLibrary";
import { BrandRecord } from "shared/types/brandsAccounts";
import { useBrandsAccountsContext } from "./contexts/BrandsAccountsContext";

export const SourceBrandsAccountsToolbar = () => {
  const [dataList] = useDataList<BrandRecord>();

  const { onDelete, onEdit, onNewBrand, onSearch, onNewAccount } =
    useBrandsAccountsToolbar();

  const { search } = useBrandsAccountsContext();

  const isMultipleTargetIdSelected = dataList.selectedIds.length > 1;
  const isNoTargetIdSelected = dataList.selectedIds.length === 0;
  const isOneBrandSelected =
    dataList.selectedItems.length === 1 &&
    dataList.selectedItems[0].type === "Brand";

  const toolbarContents: ToolbarButton = {
    New: {
      disabled: false,
      onClick: onNewBrand,
      extraInfo: {
        text: "New Brand",
        tooltip: "Create new brand",
      },
    },
    Edit: {
      disabled: isNoTargetIdSelected || isMultipleTargetIdSelected,
      onClick: () => {
        onEdit({
          clickedTargetId: dataList.selectedIds[0],
          isBrand: !!dataList.selectedItems[0],
        });
      },
    },
    Delete: {
      disabled: isNoTargetIdSelected,
      onClick: () => {
        onDelete();
      },
    },
    CustomButton: {
      disabled: !isOneBrandSelected,

      onClick: () => {
        onNewAccount(dataList.selectedItems[0].name);
      },
      extraInfo: {
        text: "New Account",
        dataCy: "new-account-button",
      },
    },
  };

  return (
    <ToolbarTable
      searchValue={search}
      toolbarContents={toolbarContents}
      searchPlaceholder="Search"
      titleTooltip="Search brands by name"
      onSearch={onSearch}
    />
  );
};
