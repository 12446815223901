import { FC, useState } from "react";
import { Popover } from "antd";
import { IOfferDataInputProps } from "shared/types/assetBuilder";
import Placeholder from "../../../../../statics/images/car_image_placeholder.png";
import OfferImagePopoverV2 from "./OfferImagePopoverV2";

export const OfferImage: FC<IOfferDataInputProps> = ({
  title,
  value,
  className = "",
}) => {
  const [uploadedJellybean, setJellybean] = useState<string>("");

  return (
    <div className={`offer-image-container ${className}`}>
      <Popover
        content={
          <div>
            <img
              style={{ width: "300px", height: "240px", objectFit: "contain" }}
              src={
                (uploadedJellybean ? uploadedJellybean : value) ||
                "https://av2-dev.constellationagency.com/static/media/car_cut.f463f64a.png"
              }
              alt={title}
              onError={event => {
                (event.target as HTMLImageElement).src = Placeholder;
              }}
            />
          </div>
        }
        trigger="hover"
      >
        <img
          src={
            (uploadedJellybean ? uploadedJellybean : value) ||
            "https://av2-dev.constellationagency.com/static/media/car_cut.f463f64a.png"
          }
          alt={title}
          onError={event => {
            (event.target as HTMLImageElement).src = Placeholder;
          }}
        />
      </Popover>
      <OfferImagePopoverV2 setNewJellybean={setJellybean} />
    </div>
  );
};
