interface IConfigurationError {
  message: string;
  errorObj: any | null;
}

export default class ConfigurationError extends Error {
  public message: string;
  public errorObj: any;

  constructor({ message, errorObj = null }: IConfigurationError) {
    super(message);

    this.message = message;
    this.errorObj = errorObj;
  }
}
