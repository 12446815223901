import API from "services";
import { IConfig } from "shared/types/configuration";

export type JobStatusString = "pending" | "success" | "failure";
interface IJobStatusResponse {
  error: { message: string } | null;
  result: {
    jobStatus: IJobStatus;
  } | null;
}
interface IJobStatus {
  executionId: string;
  status: JobStatusString;
  description?: string;
  responseJson?: string;
}

export const getJobStatus = async (
  config: IConfig,
  jobExecId: string,
): Promise<IJobStatusResponse> => {
  const request: RequestInfo = new Request(
    `${config.services.jobStatusUrl}?executionId=${jobExecId}`,
    {
      method: "GET",
      cache: "no-cache",
    },
  );

  const { error, result } = await API.send<IJobStatusResponse>(request);

  if (error || !result) {
    return { error, result: null };
  }

  return { error, result };
};
