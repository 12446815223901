import { Checkbox, Space } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useAdEngineActions } from "redux/assetExporter/assetExporter.slice";
import { feedTypes } from "shared/constants/assetExporter";
import { useAppSelector } from "shared/hooks/useAppSelector";

import styles from "../ExportDrawer.module.scss";

export const ColumnsToExport = () => {
  const { colsToExport, feedType, feedColumns } = useAppSelector(
    state => state.assetExporter.configure,
  );
  const actions = useAdEngineActions();

  const filterInternalCols = (col: string) => {
    const lcCol = col.toLowerCase();
    return (
      lcCol !== "template" &&
      lcCol !== "process" &&
      lcCol !== "rowidentifier" &&
      lcCol !== "lastupdated"
    );
  };

  const onCheckBoxChange = (
    e: CheckboxChangeEvent,
    col: string,
    required: boolean,
  ) => {
    e.preventDefault();
    if (required) return;
    if (e.target.checked) actions.setColsToExport([...colsToExport, col]);
    else actions.setColsToExport(colsToExport.filter(c => c !== col));
  };
  const recommendedCols = feedTypes.find(({ type }) => type === feedType);
  const requiredCols =
    recommendedCols?.recommendedColumns
      .filter(col => col.required)
      .map(col => col.name) ?? [];
  return (
    <>
      <div
        className={`${styles.radioSelectionTitle} ${styles.sectionTopSpacing}`}
      >
        Select columns to export
      </div>
      <div id="columns-to-export">
        <Space direction="vertical">
          {feedColumns.filter(filterInternalCols).map((col, idx) => {
            const isRequired = requiredCols.includes(col);
            return (
              <Checkbox
                key={col}
                checked={colsToExport.includes(col)}
                onChange={e => onCheckBoxChange(e, col, isRequired)}
                disabled={isRequired}
                data-cy={`colToExport-${idx}`}
              >
                {col}
                {isRequired && <span className={styles.requiredField}> *</span>}
              </Checkbox>
            );
          })}
        </Space>
      </div>
    </>
  );
};
