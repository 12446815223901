import { Row, Col, Form } from "antd";
import { IFacebookAccount, IFacebookObject } from "../facebookUtils/types";
import FacebookAssetSelect from "./FacebookAssetSelect";
import {
  AccountDetailsFacebook,
  IAccountRecord,
} from "shared/types/accountManagement";
import { useCallback, useState } from "react";
import FacebookPixelModal from "./FacebookPixelModal";
import { onlyAllowNumbers } from "./validationUtils";
import { FacebookAccountSelectOrInput } from "./FacebookAccountSelectOrInput";
import { FacebookAssetSelectOrInput } from "./FacebookAssetSelectOrInput";
import { useIsAdmin } from "shared/hooks/useIsAdmin";

type Props = {
  selectedFacebookAccounts: IFacebookAccount[] | null | undefined;
  dealerToEdit: IAccountRecord;
  setDealerToEdit: (dealerToEdit: IAccountRecord) => void;
};

export const FacebookAccountDetails = ({
  selectedFacebookAccounts,
  dealerToEdit,
  setDealerToEdit,
}: Props) => {
  const isAdmin = useIsAdmin();
  const { facebook } = dealerToEdit.details;
  const [selectedAccount, setSelectedAccount] = useState<IFacebookAccount>();
  const [showPixelModal, setShowPixelModal] = useState(false);
  const [pixelModalData, setPixelModalData] = useState<IFacebookAccount>();
  const [facebookIntegration, setFacebookIntegration] = useState(true);
  const [createPixelSuccess, setCreatePixelSuccess] = useState(false);

  const updateFacebookAsset = useCallback(
    (name: keyof AccountDetailsFacebook, value: string) => {
      setDealerToEdit({
        ...dealerToEdit,
        details: {
          ...dealerToEdit.details,
          facebook: {
            ...dealerToEdit.details.facebook,
            [name]: value,
          },
        },
        enabled: true,
      });
    },
    [dealerToEdit, setDealerToEdit],
  );

  const handleFacebookAccountSelect = useCallback(
    (account: IFacebookAccount | undefined) => {
      setSelectedAccount(account);

      if (!!account) updateFacebookAsset("fbAccountId", account.account_id);
    },
    [updateFacebookAsset],
  );

  const handlePixelSelect = useCallback(
    (pixel: IFacebookObject | null) => {
      updateFacebookAsset("fbPixelId", pixel?.id || "");
    },
    [updateFacebookAsset],
  );

  const handleCatalogSelect = useCallback(
    (catalog: IFacebookObject | null) => {
      updateFacebookAsset("fbCatalogId", catalog?.id || "");
    },
    [updateFacebookAsset],
  );

  const handleInstagramSelect = useCallback(
    (instagram: IFacebookObject | null) => {
      updateFacebookAsset("fbInstagramId", instagram?.id || "");
    },
    [updateFacebookAsset],
  );

  const handlePageSelect = useCallback(
    (page: IFacebookObject | null) => {
      updateFacebookAsset("fbPageId", page?.id || "");
    },
    [updateFacebookAsset],
  );

  const handleAccountIdChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      onlyAllowNumbers(value) && updateFacebookAsset("fbAccountId", value);
    },
    [updateFacebookAsset],
  );

  const handleCatalogIdChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      onlyAllowNumbers(value) && updateFacebookAsset("fbCatalogId", value);
    },
    [updateFacebookAsset],
  );

  const handlePageIdChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      onlyAllowNumbers(value) && updateFacebookAsset("fbPageId", value);
    },
    [updateFacebookAsset],
  );

  const handleCreatePixel = (value: string) => {
    setShowPixelModal(true);
    setPixelModalData(selectedAccount);
    onlyAllowNumbers(value) && updateFacebookAsset("fbPixelId", value);
  };

  return (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label={"FB Account ID"}>
            <FacebookAccountSelectOrInput
              disabled={!isAdmin}
              selectedAccounts={selectedFacebookAccounts}
              selectedAccount={selectedAccount}
              facebook={facebook}
              onSelect={handleFacebookAccountSelect}
              onChangeInput={handleAccountIdChange}
              enableInput={facebookIntegration}
              displayAccountId={true}
              facebookNull={true}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={"Pixel ID"}>
            {facebookIntegration && (
              <FacebookAssetSelect
                title={"pixel"}
                willSetValueOnLoad
                initialValue={facebook.fbPixelId}
                createPixel={handleCreatePixel}
                selectedAccount={selectedAccount}
                createPixelSuccess={createPixelSuccess}
                setFacebookIntegration={setFacebookIntegration}
                onSelect={handlePixelSelect}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label={"Catalog ID"}>
            <FacebookAssetSelectOrInput
              title={"catalog"}
              willSetValueOnLoad
              selectedAccount={selectedAccount}
              setFacebookIntegration={setFacebookIntegration}
              onSelect={handleCatalogSelect}
              enableInput={facebookIntegration}
              initialValue={facebook.fbCatalogId}
              onChangeInput={handleCatalogIdChange}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={"Instagram ID"}>
            {facebookIntegration && (
              <FacebookAssetSelect
                title={"instagram"}
                willSetValueOnLoad
                pageId={facebook.fbPageId}
                initialValue={facebook.fbInstagramId}
                selectedAccount={selectedAccount}
                setFacebookIntegration={setFacebookIntegration}
                onSelect={handleInstagramSelect}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label={"FB Page ID"}>
            <FacebookAssetSelectOrInput
              title={"page"}
              willSetValueOnLoad
              selectedAccount={selectedAccount}
              setFacebookIntegration={setFacebookIntegration}
              onSelect={handlePageSelect}
              enableInput={facebookIntegration}
              initialValue={facebook.fbPageId}
              onChangeInput={handlePageIdChange}
            />
          </Form.Item>
        </Col>
      </Row>
      {selectedAccount && (
        <FacebookPixelModal
          showModal={showPixelModal}
          setShowModal={setShowPixelModal}
          setCreatePixelSuccess={setCreatePixelSuccess}
          pixelModalData={pixelModalData}
        />
      )}
    </>
  );
};
