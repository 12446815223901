import { useEffect, useState } from "react";
import { Tabs, Steps } from "antd";
import {
  ISingleLogoObject,
  IUploadImageFormInput,
  TUploadSetLogosInDictionary,
  TUploadLogosS3DictionaryKey,
  TUploadGroup,
} from "shared/types/uploadManagement";

import { OperationMode } from "shared/types/inputValues";
import { IAccountRecord } from "shared/types/accountManagement";

import { industryType } from "utils/helpers";
import { updateLogosInDictionary } from "./DealerLogos/utils";
import LogoSection from "./DealerLogos/LogoSection";
import UploadStep from "./DealerLogos/UploadStep";
import DefaultStep from "./DealerLogos/DefaultStep";
import DeletionModal from "./DealerLogos/DeletionModal";
import UploadedLogos from "./DealerLogos/UploadedLogos";
import { useIsAdmin } from "shared/hooks/useIsAdmin";

const { Step } = Steps;

interface IDealerLogos {
  s3AssetBucket: string;
  mode: OperationMode;
  singleLogoObject: ISingleLogoObject;
  setSingleLogoObject: (singleLogoObject: ISingleLogoObject) => void;
  logosInDictionary: Record<TUploadSetLogosInDictionary, ISingleLogoObject[]>;
  setLogosInDictionary: (
    logosInDictionary: Record<TUploadSetLogosInDictionary, ISingleLogoObject[]>,
  ) => void;
  uploadImages: (
    imagesToUpload: IUploadImageFormInput[],
    featureName: string,
    group: TUploadGroup,
    mode: OperationMode,
  ) => void;

  dealerToEditWithLogos: IAccountRecord;
  setDealerToEditWithLogos: (dealerToEditWithLogos: IAccountRecord) => void;
}

const DealerLogos = ({
  mode,
  s3AssetBucket,
  uploadImages,
  singleLogoObject,
  setSingleLogoObject,
  logosInDictionary,
  setLogosInDictionary,

  dealerToEditWithLogos,
  setDealerToEditWithLogos,
}: IDealerLogos) => {
  const isAdmin = useIsAdmin();

  const [showDeleteLogoBoxModal, toggleShowDeleteLogoBoxModal] =
    useState(false);
  const [logoNameToDelete, setLogoNameToDelete] = useState("");
  const [logoGroupToDelete, setLogoGroupToDelete] =
    useState<TUploadLogosS3DictionaryKey>("horizontalImagesFromS3");

  useEffect(() => {
    updateLogosInDictionary({
      singleLogoObject,
      setLogosInDictionary,
      logosInDictionary,
    });
    // eslint-disable-next-line
  }, [singleLogoObject]);

  return (
    <div>
      <Tabs>
        <Tabs.TabPane
          tab={industryType("auto") ? "Dealer Logo" : "Account-Specific Logo"}
        />
      </Tabs>

      <div>
        <Steps direction="vertical" size="small" current={-1}>
          {["Horizontal", "Vertical", "Square", "Upload"].map(step => {
            if (step === "Upload") {
              return (
                <Step
                  key={step.toLowerCase()}
                  title="Upload"
                  disabled={!isAdmin}
                  description={
                    <UploadStep
                      key={step.toLowerCase()}
                      dealerToEditWithLogos={dealerToEditWithLogos}
                      logosInDictionary={logosInDictionary}
                      mode={mode}
                      setDealerToEditWithLogos={setDealerToEditWithLogos}
                      setLogosInDictionary={setLogosInDictionary}
                      setSingleLogoObject={setSingleLogoObject}
                      uploadImages={uploadImages}
                    />
                  }
                />
              );
            }

            return (
              <Step
                key={step.toLowerCase()}
                title={step}
                description={
                  <DefaultStep
                    key={step.toLocaleLowerCase()}
                    logosInDictionary={logosInDictionary}
                    s3AssetBucket={s3AssetBucket}
                    setLogosInDictionary={setLogosInDictionary}
                    setSingleLogoObject={setSingleLogoObject}
                    singleLogoObject={singleLogoObject}
                    step={step}
                  />
                }
              />
            );
          })}
        </Steps>
        <LogoSection />
        <UploadedLogos
          dealerToEditWithLogos={dealerToEditWithLogos}
          setLogoGroupToDelete={setLogoGroupToDelete}
          setLogoNameToDelete={setLogoNameToDelete}
          toggleShowDeleteLogoBoxModal={toggleShowDeleteLogoBoxModal}
        />
        <DeletionModal
          dealerToEditWithLogos={dealerToEditWithLogos}
          logoGroupToDelete={logoGroupToDelete}
          logoNameToDelete={logoNameToDelete}
          setDealerToEditWithLogos={setDealerToEditWithLogos}
          showDeleteLogoBoxModal={showDeleteLogoBoxModal}
          toggleShowDeleteLogoBoxModal={toggleShowDeleteLogoBoxModal}
        />
      </div>
    </div>
  );
};

export default DealerLogos;
