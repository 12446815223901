import { LIMIT_RECORDS_PER_CALL } from "shared/constants/assetBuilder";
import { IConfig } from "shared/types/configuration";
import { INewOrder, OrderStatus } from "shared/types/newOrders";
import HttpClient from "./httpClient";

export default ({ headers, config }: { headers: any; config: IConfig }) => ({
  createNewOrder: <T>(inputOrder: INewOrder): Promise<T> => {
    const { services } = config;

    const createOrdersUrl = services.newOrder.newOrders;
    return fetch(createOrdersUrl, {
      method: "POST",
      headers,
      body: JSON.stringify({
        newOrder: inputOrder,
      }),
    }).then<T>(response => {
      return response.json();
    });
  },

  getNewOrders: <T>(paginationToken = ""): Promise<T> => {
    const { services } = config;

    let getOrdersUrl = services.newOrder.newOrders;

    if (paginationToken) {
      getOrdersUrl = getOrdersUrl.concat(`?paginationToken=${paginationToken}`);
    }

    return HttpClient.get(getOrdersUrl);
  },

  getNewOrdersAsync: <T>(
    paginationKey?: string,
    status?: OrderStatus,
    limit = LIMIT_RECORDS_PER_CALL,
  ): Promise<T> => {
    const { services } = config;

    const searchParams = new URLSearchParams({ limit: `${limit + 1}` });
    if (paginationKey) searchParams.append("paginationKey", paginationKey);
    if (status) searchParams.append("status", status);

    const queryStr = searchParams.toString();
    const url = `${services.newOrder.newOrders}?${queryStr}`;
    return HttpClient.get(url);
  },

  deleteNewOrder: <T>(deleteOrder: INewOrder): Promise<T> => {
    const { services } = config;

    const deleteOrdersUrl = services.newOrder.newOrders;
    return fetch(deleteOrdersUrl, {
      method: "DELETE",
      headers,
      body: JSON.stringify({
        newOrder: deleteOrder,
      }),
    }).then<T>(response => {
      return response.json();
    });
  },

  updateNewOrder: <T>(updateOrder: Partial<INewOrder>): Promise<T> => {
    const { services } = config;

    const updateOrderUrl = services.newOrder.newOrders;
    return HttpClient.put(updateOrderUrl, {
      newOrder: updateOrder,
    });
  },

  getWFData: <T>(): Promise<T> => {
    const { services } = config;

    const getWFUrl = services.workFrontUrl;
    return fetch(getWFUrl, {
      method: "GET",
      headers,
    }).then<T>(response => {
      return response.json();
    });
  },

  getStoreFromWF: <T>(wfID: string): Promise<T> => {
    const { services } = config;
    const getStoreUrl = services.workFrontUrl;
    return fetch(getStoreUrl, {
      method: "POST",
      headers,
      body: JSON.stringify({
        wfInfo: {
          wfID,
        },
      }),
    }).then<T>(response => {
      return response.json();
    });
  },
});
