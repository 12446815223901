import { memo, useEffect, useMemo, useState } from "react";
import {
  Button,
  notification,
  Progress,
  Space,
  Typography,
  Form,
  Tooltip,
} from "antd";
import {
  LoadingOutlined,
  RocketOutlined,
  WarningFilled,
} from "@ant-design/icons";
import WarningResult from "./WarningResult";

import { useMutateFbCanvas } from "shared/hooks/adLibrary/facebook/useMutateFbCanvas";
import { useMutateFbCanvasElements } from "shared/hooks/adLibrary/facebook/useMutateFbCanvasElement";
import { useNavigate } from "react-router-dom";

import _startCase from "lodash/startCase";

import Page from "screens/adLibrary/facebookUtils/page";

import {
  LoadStatus,
  returnLoadIsCompleteLink,
  returnProgressStatus,
  canvasElementTypeToBodyElementMap,
} from "./utils";

import { IAccountRecord } from "shared/types/accountManagement";
import {
  IInstantExperience,
  IInstantExperienceElementDestination,
  IInstantExperienceToLoad,
  InstantExperienceBodyElement,
  InstantExperienceElementStyle,
} from "shared/types/adLibrary";
import {
  CanvasElementParamTypes,
  ICanvasButtonParams,
  ICanvasCarouselParams,
  ICanvasElementParams,
  ICanvasFooterParams,
  ICanvasOpenURLAction,
  ICanvasPhotoParams,
  ICanvasProductSetParams,
  ICanvasVideoParams,
} from "screens/adLibrary/facebookUtils/types";

interface IProps {
  loadStatus: LoadStatus;
  setLoadStatus: React.Dispatch<React.SetStateAction<LoadStatus>>;
  selectedDealer?: IAccountRecord;
  instantExperiences: IInstantExperience[];
  instantExperiencesToLoad: IInstantExperienceToLoad[];
  setInstantExperiencesToLoad: React.Dispatch<
    React.SetStateAction<IInstantExperienceToLoad[]>
  >;
}

const HIDDEN_FIELD = "{{field.hide}}";

function LoadInstantExperiences({
  loadStatus,
  setLoadStatus,
  selectedDealer,
  instantExperiences,
  instantExperiencesToLoad,
  setInstantExperiencesToLoad,
}: IProps) {
  const navigate = useNavigate();

  const rootInstantExperience = instantExperiencesToLoad.find(
    ieToLoad => ieToLoad.isRoot,
  )!;
  const nestedInstantExperiences = instantExperiencesToLoad.filter(
    ieToLoad => !ieToLoad.isRoot,
  );

  const facebookPageId = selectedDealer?.details?.facebook?.fbPageId || "";

  const [currentIEToLoadId, setCurrentIEToLoadId] = useState("");
  const [currentElementId, setCurrentElementId] = useState("");
  const currentIEToLoad = useMemo(
    () =>
      currentIEToLoadId
        ? instantExperiencesToLoad.find(ie => ie.id === currentIEToLoadId)
        : undefined,
    [currentIEToLoadId, instantExperiencesToLoad],
  );

  const [elementType, setCurrentElementType] = useState<
    keyof ICanvasElementParams | undefined
  >(undefined);

  /** {<Instant Experience ID>: [<array of ids returned from facebook after each element creation>]} */
  const [bodyElementIdsDict, setBodyElementIdsDict] = useState<
    Record<string, string[]>
  >({});

  const defaultElementErrorMesssage =
    "An unknown error occurred while loading the instant experience";

  const [ieLoadErrorTrackers, setIELoadErrorTrackers] = useState<
    Array<{ ieName: string; reason: string; ieID: string }>
  >([]);

  const [progressCount, setProgressCount] = useState(0);

  const totalProgress = instantExperiencesToLoad
    .map(ieToLoad => {
      const length = (ieToLoad.body_elements ?? []).length;
      return length + (length ? 1 : 0); // +1 is count for loading fb canvas
    })
    .reduce((acc, c) => acc + c, 0);
  const progressPercent = (progressCount / totalProgress) * 100;

  const [progressTextLog, setProgressTextLog] = useState<string[]>([]);
  const [loadIsCompleteLink, setLoadIsCompleteLink] = useState("");

  const { mutateAsync: createFbCanvas } = useMutateFbCanvas?.();
  const { mutateAsync: createFbCanvasElement } = useMutateFbCanvasElements?.(
    elementType!,
  );

  const returnOpenUrlActionFromDestination = (
    ieDestination?: IInstantExperienceElementDestination,
  ): ICanvasOpenURLAction | undefined => {
    if (!ieDestination) {
      return;
    }

    let destinationUrl = "";

    // At this point, we assume that the IE is published (no deeper nesting)
    if (ieDestination.instantExperienceId) {
      const iesToScan = instantExperiences
        .filter(
          ie =>
            !instantExperiencesToLoad
              .map(ieToLoad => ieToLoad.id)
              .includes(ie.id),
        )
        .concat(instantExperiencesToLoad);
      const { canvasId: foundCanvasId } = iesToScan.find(
        ie => ie.id === ieDestination.instantExperienceId,
      ) || { canvasId: "" };

      // Possible TO DO: load IE if no canvasUrl found, but all in one go
      destinationUrl = `https://fb.com/canvas_doc/${foundCanvasId || ""}`;
    } else if (ieDestination.urlLabel) {
      destinationUrl =
        selectedDealer?.labels?.find(
          label => label.name === ieDestination.urlLabel,
        )?.url ?? "";
    }

    return { type: "OPEN_URL", url: destinationUrl };
  };

  const returnCanvasElementParamsFromIEElement = async (args: {
    element: InstantExperienceBodyElement;
    store?: IAccountRecord;
    pageId: string;
  }): Promise<CanvasElementParamTypes | null> => {
    const { element, store, pageId } = args;

    const fbPage = new Page(pageId);

    switch (element.element_type) {
      case "BUTTON":
        const buttonElement = element;
        return {
          top_padding: 48,
          bottom_padding: 48,
          name: buttonElement.name,
          rich_text: buttonElement.rich_text,
          font_size: buttonElement.font_size,
          text_color: buttonElement.text_color,
          font_family: buttonElement.font_family,
          button_color: buttonElement.button_color,
          button_style: buttonElement.button_style,
          background_color: buttonElement.background_color,
          open_url_action: returnOpenUrlActionFromDestination(
            buttonElement.destination,
          ),
        } as ICanvasButtonParams;
      case "FOOTER":
        const footerElement = element;
        // We assume that footers can only have one element (button) in Alexia
        const childBtnIEElement = footerElement.child_elements[0];
        const childBtnCanvasElement =
          (await returnCanvasElementParamsFromIEElement({
            element: childBtnIEElement,
            store,
            pageId,
          })!) as ICanvasButtonParams;
        const { id: childButtonId } = await createFbCanvasElement({
          fbPage,
          params: childBtnCanvasElement,
        });
        setCurrentElementType("canvas_footer");
        return {
          name: element.name,
          background_color: footerElement.background_color,
          child_elements: [childButtonId],
        } as ICanvasFooterParams;
      case "PHOTO":
        const photoElement = element;
        const { id: photoId } = await fbPage.createPageMedia({
          url: photoElement.url!,
          mediaType: "photo",
        });
        return {
          photo_id: photoId,
          name: element.name,
          style: photoElement.style,
          open_url_action:
            InstantExperienceElementStyle.FIT_TO_WIDTH === photoElement.style ||
            !photoElement.style
              ? returnOpenUrlActionFromDestination(photoElement.destination)
              : undefined,
        } as ICanvasPhotoParams;
      case "VIDEO":
        const videoElement = element;
        const { id: videoId } = await fbPage.createPageMedia({
          url: videoElement.url!,
          mediaType: "video",
        });
        return {
          video_id: videoId,
          name: element.name,
          style: videoElement.style,
        } as ICanvasVideoParams;
      case "CAROUSEL":
        const carouselElement = element;
        const childElements = await Promise.all(
          carouselElement.child_elements.map(async childElement => {
            return await returnCanvasElementParamsFromIEElement({
              element: childElement,
              pageId,
              store,
            });
          }),
        );
        const photoIds = await Promise.all(
          childElements.map(async element => {
            const { id: photoId } = await createFbCanvasElement({
              fbPage,
              params: element!,
            });
            return photoId;
          }),
        );
        setCurrentElementType("canvas_carousel");
        return { child_elements: photoIds } as ICanvasCarouselParams;
      case "ELEMENT_GROUP":
        const productSetElement = element;
        return {
          name: element.name,
          product_set_id: productSetElement.product_set_id,
          max_items: productSetElement.max_products,
          item_headline: productSetElement.item_headline || HIDDEN_FIELD,
          item_description: productSetElement.item_description || HIDDEN_FIELD,
        } as ICanvasProductSetParams;
      default:
        return null;
    }
  };

  const handleLoadError = (
    description: string,
    currentIE: IInstantExperienceToLoad,
  ) => {
    setLoadStatus("error");
    setCurrentElementId("");
    setCurrentIEToLoadId("");
    setCurrentElementType(undefined);
    notification.error({
      message: `Error in ${currentIE.name}`,
      description,
      duration: 8,
    });
    returnLoadIsCompleteLink("error", facebookPageId).then(linkString => {
      setProgressTextLog([
        ...progressTextLog,
        "IE Load draft process completed with errors.",
      ]);
      setLoadIsCompleteLink(linkString);
      setIELoadErrorTrackers([
        ...ieLoadErrorTrackers,
        {
          ieName: currentIE.name!,
          ieID: currentIE.id!,
          reason: description,
        },
      ]);
      setProgressCount(totalProgress);
    });
  };

  // Start load process, start with a nested instant experience
  useEffect(() => {
    if (["success", "error"].includes(loadStatus)) return;

    setLoadStatus("loading");

    const firstIE = nestedInstantExperiences.length
      ? nestedInstantExperiences[0]
      : rootInstantExperience;

    setProgressTextLog([
      ...progressTextLog,
      `Starting load IE draft process...`,
    ]);

    setCurrentIEToLoadId(firstIE.id!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadStatus]);

  // For current canvas, start element creation process, starting with first body_element
  useEffect(() => {
    if (!currentIEToLoad?.body_elements) {
      if (currentIEToLoad) {
        handleLoadError(
          `Instant Experience ${currentIEToLoad?.name} does not have any elements`,
          currentIEToLoad,
        );
      }
      return;
    }

    setProgressTextLog([
      ...progressTextLog,
      `Creating body elements for ${currentIEToLoad.name}...`,
    ]);

    setCurrentElementType(
      canvasElementTypeToBodyElementMap[
        currentIEToLoad.body_elements[0].element_type!
      ],
    );
    setCurrentElementId(currentIEToLoad.body_elements[0].id!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIEToLoadId]);

  // For each element in Instant experience, make api call to facebook to make canvas element
  useEffect(() => {
    if (!currentIEToLoad?.body_elements || !currentElementId) {
      return;
    }
    const elementIndex = currentIEToLoad.body_elements.findIndex(
      element => element.id === currentElementId,
    );

    const elementToLoad = currentIEToLoad.body_elements[elementIndex];

    if (elementToLoad?.element_type === "FOOTER") {
      // footer elements consist of one button element, so this needs to be loaded first
      setCurrentElementType("canvas_button");
    } else if (elementToLoad?.element_type === "CAROUSEL") {
      // carousels consist of photo elements, so these need to be loaded first
      setCurrentElementType("canvas_photo");
    } else {
      setCurrentElementType(
        canvasElementTypeToBodyElementMap[elementToLoad?.element_type],
      );
    }

    returnCanvasElementParamsFromIEElement({
      element: elementToLoad,
      store: selectedDealer,
      pageId: facebookPageId,
    })
      .then(canvasElement => {
        if (!canvasElement) {
          handleLoadError(
            `${elementToLoad.element_type} not supported`,
            currentIEToLoad,
          );
          return;
        }

        const fbPage = new Page(facebookPageId);
        createFbCanvasElement?.(
          { params: canvasElement, fbPage },
          {
            onError: ({ error }) => {
              handleLoadError(
                `Error with ${_startCase(
                  elementToLoad!.element_type,
                )} element ${elementToLoad?.name}: ${
                  error.error_user_msg ||
                  error.message ||
                  defaultElementErrorMesssage
                }`,
                currentIEToLoad,
              );
            },
            onSuccess: ({ id: newElementId }) => {
              const bodyElementIds = [
                ...(bodyElementIdsDict[currentIEToLoadId] ?? []),
                newElementId,
              ];
              const newBodyElementsIdsDict = {
                ...bodyElementIdsDict,
                [currentIEToLoadId]: bodyElementIds,
              };

              const progressAfterElementCreate = progressCount + 1;

              setProgressCount(progressAfterElementCreate);

              setBodyElementIdsDict(newBodyElementsIdsDict);

              // If not at last element, continue creating elements for current IE
              if (elementIndex < currentIEToLoad.body_elements!.length - 1) {
                setCurrentElementId(
                  currentIEToLoad.body_elements![elementIndex + 1].id!,
                );
                return;
              }

              const newProgressLogText = [
                ...progressTextLog,
                `Loading draft of ${currentIEToLoad.name} to Facebook...`,
              ];

              setProgressTextLog(newProgressLogText);

              createFbCanvas?.(
                {
                  params: {
                    name: currentIEToLoad.name,
                    background_color: currentIEToLoad.backgroundColor,
                    enable_swipe_to_open: currentIEToLoad.swipeToOpen,
                    body_element_ids: bodyElementIds,
                  },
                  fbPage,
                },
                {
                  onError: ({ error }) => {
                    handleLoadError(
                      error.error_user_msg ||
                        error.message ||
                        defaultElementErrorMesssage,
                      currentIEToLoad,
                    );
                  },
                  onSuccess: ({ id: canvasId }) => {
                    const progressAfterCanvasCreate =
                      progressAfterElementCreate + 1;

                    notification.success({
                      message: `${currentIEToLoad.name} was loaded successfully`,
                    });

                    setProgressCount(progressAfterCanvasCreate);

                    // Set canvasUrl in instant experiences to load
                    const ieToLoadIndex = instantExperiencesToLoad.findIndex(
                      ie => ie.id === currentIEToLoad.id,
                    );

                    const newIEsToLoad = [...instantExperiencesToLoad];
                    newIEsToLoad.splice(ieToLoadIndex, 1, {
                      ...instantExperiencesToLoad[ieToLoadIndex],
                      canvasId,
                    });

                    setInstantExperiencesToLoad(newIEsToLoad);

                    // If the root IE's canvas draft was created, then the load process is complete
                    if (currentIEToLoad.isRoot) {
                      setLoadStatus("success");
                      setProgressTextLog([
                        ...newProgressLogText,
                        "Process complete.",
                      ]);
                      setCurrentElementId("");
                      setCurrentIEToLoadId("");
                      returnLoadIsCompleteLink("success", facebookPageId).then(
                        linkString => {
                          setLoadIsCompleteLink(linkString);
                        },
                      );
                      return;
                    }

                    // If the IE is not the root, then the load process must continue on the nested IEs
                    const currentIEIndex = nestedInstantExperiences.findIndex(
                      ie => ie.id === currentIEToLoadId,
                    );

                    // If the last created canvas was at the last index of nested IEs, start loading the root IE
                    setCurrentIEToLoadId(
                      (currentIEIndex < nestedInstantExperiences.length - 1
                        ? nestedInstantExperiences[currentIEIndex + 1]
                        : rootInstantExperience
                      ).id!,
                    );
                  },
                },
              );
            },
          },
        );
      })
      .catch(({ error }) => {
        handleLoadError(
          `Error with ${elementToLoad.element_type} element: ${
            error?.message || defaultElementErrorMesssage
          }`,
          currentIEToLoad,
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentElementId]);

  if (!facebookPageId) {
    return (
      <WarningResult
        subjectName={selectedDealer?.dealerName}
        message="does not have a Facebook page ID to use"
      />
    );
  }

  return (
    <>
      <Space align="start">
        <RocketOutlined style={{ fontSize: 24, color: "#40a9ff" }} />
        <Space direction="vertical">
          <Space align="center">
            {loadStatus === "loading" && (
              <Typography.Title level={5}>
                Loading Draft to Facebook <LoadingOutlined />
              </Typography.Title>
            )}
            {loadStatus === "success" && (
              <Typography.Title level={5}>Load Successful</Typography.Title>
            )}
            {loadStatus === "error" && (
              <Typography.Title level={5}>Load Failed</Typography.Title>
            )}
          </Space>
          <Typography.Text>
            {loadStatus === "loading" &&
              `${rootInstantExperience!
                .name!} is loading to Facebook. Please wait
            shortly for confirmation`}
            {loadStatus === "success" &&
              `${rootInstantExperience!.name!} has loaded`}
            {loadStatus === "error" &&
              `${rootInstantExperience!.name!} has failed to load.`}
          </Typography.Text>
          {progressTextLog.length && (
            <Typography.Text type="secondary">
              {progressTextLog[progressTextLog.length - 1]}
            </Typography.Text>
          )}
          {loadStatus === "success" && (
            <Button
              type="link"
              style={{ padding: 0 }}
              target="_blank"
              rel="noopener noreferrer"
              href={loadIsCompleteLink}
              title="Open to Facebook Business manager to view loaded Instant Experiences"
            >
              Access the preview in Facebook
            </Button>
          )}
          {loadStatus === "error" && (
            <Space direction="vertical">
              {ieLoadErrorTrackers.map(ieTracker => (
                <Form.Item key={ieTracker.ieID} noStyle>
                  <Space>
                    <Tooltip title={ieTracker.reason} placement="bottom">
                      <Space>
                        <WarningFilled style={{ color: "#faad14" }} />
                        <span>{ieTracker.ieName} - </span>
                      </Space>
                    </Tooltip>
                    <Button
                      type="link"
                      style={{ padding: 0 }}
                      title="Go to Instant Experience editor"
                      onClick={() => {
                        navigate(
                          loadIsCompleteLink.replace("<ieID>", ieTracker.ieID),
                        );
                      }}
                    >
                      Edit instant experience
                    </Button>
                  </Space>
                </Form.Item>
              ))}
            </Space>
          )}
        </Space>
      </Space>
      <Progress
        percent={progressPercent}
        showInfo={loadStatus !== "loading"}
        style={{ marginTop: "1.5em" }}
        status={returnProgressStatus(loadStatus)}
      />
    </>
  );
}

export default memo(LoadInstantExperiences);
