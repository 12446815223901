import { Button, Tooltip } from "antd";
import { useCurrentSession, useSessionParam } from "../hooks/useCurrentSession";
import { AdLoadStepInfo, useAdLoadStepInfo } from "../hooks/useAdLoadStepInfo";
import { AdLoadStep } from "screens/adLibrary/facebookUtils/adProto.types";
import { ReactNode } from "react";

export const AdLoadFooter = ({ onClose }: { onClose?: () => void }) => {
  const { closeSession } = useSessionParam();
  const { session } = useCurrentSession();
  const { step } = session.data;

  const closeDrawer = () => {
    onClose?.();
    closeSession();
  };

  const adLoadStepInfo = useAdLoadStepInfo();

  const getNextBtn = (stepInfo: AdLoadStepInfo) => {
    return (
      <Tooltip
        title={!stepInfo.canContinue ? stepInfo.errorMessage : undefined}
      >
        <Button
          type="primary"
          onClick={stepInfo.onClickNext}
          disabled={!stepInfo.canContinue}
        >
          {stepInfo.nextButtonText}
        </Button>
      </Tooltip>
    );
  };

  const getBackBtn = (stepInfo: AdLoadStepInfo) => (
    <Button
      onClick={stepInfo.onClickBack}
      type="default"
      style={{
        marginLeft: "auto",
        marginRight: 8,
      }}
    >
      Back
    </Button>
  );

  const stepComponentMap = {
    select: getNextBtn(adLoadStepInfo.select),
    productSets: (
      <>
        {getBackBtn(adLoadStepInfo.productSets)}
        {getNextBtn(adLoadStepInfo.productSets)}
      </>
    ),
    destinations: (
      <>
        {getBackBtn(adLoadStepInfo.destinations)}
        {getNextBtn(adLoadStepInfo.destinations)}
      </>
    ),
    review: (
      <>
        {getBackBtn(adLoadStepInfo.review)}
        {getNextBtn(adLoadStepInfo.review)}
      </>
    ),
    load: getNextBtn(adLoadStepInfo.load),
  } satisfies Record<AdLoadStep, ReactNode>;

  return (
    <>
      <Button onClick={closeDrawer} type="default">
        Close
      </Button>
      {stepComponentMap[step]}
    </>
  );
};
