import { QueryFunctionContext, useInfiniteQuery, useQuery } from "react-query";
import API from "services";
import {
  IAdHistorySession,
  IGetAdHistorySessionsQueryParams,
  IGetAdHistorySessionsResponseData,
} from "shared/types/adLibrary";
import { IApiResponse } from "shared/types/shared";

interface IUseFetchAdHistorySessionsArgs {
  queryParams?: IGetAdHistorySessionsQueryParams;
  enabled?: boolean;
}

const getAdHistorySession =
  (queryParams?: IGetAdHistorySessionsQueryParams) => async () => {
    const { result, error } = await API.services.adLibrary.getAdHistorySessions(
      queryParams,
    );

    if (error || !result) {
      throw Error(error?.message || "Something went wrong.");
    }

    return result?.sessions;
  };

export const useFetchSessions = (args?: IUseFetchAdHistorySessionsArgs) => {
  return useQuery<
    IAdHistorySession[] | undefined,
    IApiResponse<IGetAdHistorySessionsResponseData>
  >(
    args?.queryParams?.id
      ? ["adHistorySessions", args.queryParams.id]
      : "adHistorySessions", // Possible TO DO: store query data with query param as well
    getAdHistorySession(args?.queryParams),
    { staleTime: 180000, enabled: args?.enabled },
  );
};

const fetchSessionsPaginated = async ({
  pageParam: paginationKey,
}: QueryFunctionContext) => {
  const { result, error } =
    await API.services.adLibrary.getAdHistorySessionsViaPost({
      limit: 100,
      paginationKey,
    });

  if (!result) {
    throw Error(
      error?.message || "Something went wrong while fetching sessions.",
    );
  }

  return result;
};

export const useFetchInfiniteSessions = () => {
  const result = useInfiniteQuery<IGetAdHistorySessionsResponseData, Error>(
    "adHistorySessionsPages",
    fetchSessionsPaginated,
    {
      staleTime: 300000,
      getNextPageParam: lastPage => {
        if (!lastPage?.paginationKey) return false;
        const lastItem = lastPage.sessions[lastPage.sessions.length - 1];
        /** DynamoDB requires both id and createdAt for session records */
        const paginationKey = JSON.stringify({
          id: lastItem.id,
          createdAt: lastItem.createdAt,
        });
        return paginationKey;
      },
    },
  );

  if (result.hasNextPage && !result.isFetchingNextPage) {
    result.fetchNextPage();
  }

  const adHistorySessions =
    result?.data?.pages?.reduce(
      (acc, page) => acc.concat(page?.sessions ?? []),
      [] as IAdHistorySession[],
    ) ?? [];

  return {
    ...result,
    adHistorySessions,
  };
};
