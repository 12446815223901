import { message } from "antd";
import { groupBy, isEmpty, mapValues } from "lodash";
import { FC, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import TabContainer from "shared/components/TabContainer";
import {
  AssetInstanceRecord,
  IAssetBuilderState,
  TAssetTypeCount,
  IAssetInstance,
  ISelectedOffer,
  ISavedOrderState,
  FeedTab,
  SelectedInstance,
} from "shared/types/assetBuilder";

import {
  IDesignStudioState,
  ITemplate,
  TAssetType,
  templateAssetTypes,
} from "shared/types/designStudio";
import { INewOrderRecord } from "shared/types/newOrders";
import BuildSteps, { TBuildStep } from "./build/BuildSteps";
import BuildAssetList from "./build/BuildAssetList";

import actions from "../../../redux/rootActions";

import {
  assetInstancesToAssetBuild,
  assetBuildInstanceToAssetInstance,
} from "utils/helpers.asset";

import { IBrandManagementState } from "shared/types/brandManagement";
import { TSetBuildPage } from "redux/assetBuilder/assetBuilder.slice";

import "./Build.scss";
import { IConfigurationState } from "shared/types/configuration";
import { useFetchTemplatesConcurrently } from "shared/hooks/useFetchTemplatesConcurrently";
import uuid from "uuid";

interface BuildProps {
  assetInstances: AssetInstanceRecord;
  availableOffers: ISelectedOffer[];
  selectedTemplateSizes: Record<string, Record<string, boolean>>;
  savedOrder: IAssetBuilderState["savedOrder"] | null;
  config: IConfigurationState["config"];
  feedTabs?: FeedTab[];
}

interface BuildHandlers {
  updateAssetInstances: (
    assetType: string,
    size: string,
    instances: IAssetInstance[] | null,
  ) => void;
  removeFilterTemplate: (
    selectedAssetTypeTab: string,
    filterOption: string,
  ) => void;
  setSelectedAssetBuildInstance: (args: SelectedInstance[]) => void;
  setBuildPage: (args: TSetBuildPage) => void;
  updateAssetTypeCount: (assetTypeCount: TAssetTypeCount) => void;
  updateNewOrder: (updateNewOrder: Partial<INewOrderRecord>) => void;
  resetAssetInstanceCounter: () => void;
  setAssetInstanceComparator: (assetInstances: AssetInstanceRecord) => void;
}

const Build: FC<BuildProps & BuildHandlers> = props => {
  message.config({
    duration: 2,
    maxCount: 1,
  });

  const { removeFilterTemplate, savedOrder } = props;
  const { templates, isFetching } = useFetchTemplatesConcurrently({
    status: "PUBLISHED",
  });

  const [shouldUpdateThemeImage, setShouldUpdateThemeImage] =
    useState<boolean>(false);

  useEffect(() => {
    props.setAssetInstanceComparator(assetInstances);
    const oemsInString = savedOrder?.selectedOrder.dealer_oem;
    if (!oemsInString) {
      message.error("Saved OEM is invalid. This is system error.");

      return;
    }

    setShouldUpdateThemeImage(false);
    // eslint-disable-next-line
  }, []);

  const templatesBySizeAndType = useMemo(
    () => {
      if (!templates || templates.length < 1) {
        return {};
      }

      return mapValues(
        groupBy(
          templates.filter(template => template?.artboard), // Filter out templates without artboard
          ({ artboard: { asset_type } }: ITemplate) =>
            asset_type?.toLowerCase(),
        ),
        templatesForType =>
          groupBy(
            templatesForType,
            ({ artboard: { height, width } }: ITemplate) =>
              `${width} x ${height}`,
          ),
      );
    },

    // eslint-disable-next-line
    [templates],
  );

  const templateTypes = Object.keys(templatesBySizeAndType);
  const [selectedAssetTypeTab, setSelectedAssetTypeTab] = useState<string>(
    templateTypes[0],
  );

  useEffect(() => {
    if (!selectedAssetTypeTab) {
      for (const tab in assetInstances) {
        for (const dimension in assetInstances[tab]) {
          if (assetInstances[tab][dimension].length === 0) {
            continue;
          }

          setSelectedAssetTypeTab(tab);
          return;
        }
      }
      const templateTypes = Object.keys(templatesBySizeAndType);
      setSelectedAssetTypeTab(templateTypes[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templatesBySizeAndType]);

  useEffect(() => {
    if (!savedOrder || !savedOrder.assetInstances) {
      return;
    }

    const fetchedAssetBuild = assetInstancesToAssetBuild(
      savedOrder.assetInstances,
      props.availableOffers,
    );

    props.setBuildPage({
      assetBuild: fetchedAssetBuild,
      selectedTab: selectedAssetTypeTab as TAssetType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedOrder]);

  const { assetInstances } = props;

  const assetBuild = useMemo(() => {
    const availableOffers = savedOrder?.selectedOffers ?? [];
    return assetInstancesToAssetBuild(assetInstances, availableOffers);
  }, [assetInstances, savedOrder?.selectedOffers]);

  const [activeStep, setActiveStep] = useState<TBuildStep>();
  return (
    <TabContainer
      displaySearchView={{
        displayNewOffer: true,
        displaySearchInput: true,
        displayPlusButton: false,
      }}
      selectedTab={selectedAssetTypeTab}
      onChange={newSelectedTab => {
        if (selectedAssetTypeTab !== newSelectedTab) {
          props.setSelectedAssetBuildInstance([]);
          setSelectedAssetTypeTab(newSelectedTab);
        }
      }}
      // NOTE: here, templateAassetTypes is used to keep the order of the tabs.
      contentTabs={templateAssetTypes
        .map(assetType => assetType.toLowerCase())
        .filter(assetType =>
          templateTypes.some(
            savedAssetType => assetType === savedAssetType.toLowerCase(),
          ),
        )
        .map(assetType => {
          return {
            title: assetType.charAt(0).toUpperCase() + assetType.slice(1),
            component: (
              <BuildAssetList
                assetType={assetType}
                config={props.config}
                feedTabs={props.feedTabs}
                setShouldUpdateThemeImage={setShouldUpdateThemeImage}
                instancesBySize={assetBuild.instances[assetType] || {}}
                offers={assetBuild.offers}
                updateAssetInstances={(size, instances) => {
                  props.updateAssetInstances(assetType, size, instances);
                }}
                templatesBySize={templatesBySizeAndType[assetType]}
                selectedTemplateSizes={getSelectedAssetSizeArray({
                  assetInstances,
                })}
                currentOrder={savedOrder?.selectedOrder}
                selectedAssetTypeTab={selectedAssetTypeTab}
                setSelectedAssetBuildInstance={
                  props.setSelectedAssetBuildInstance
                }
                onNewAssetInstance={() => setActiveStep("SELECT_OFFER")}
              />
            ),
          };
        })}
      filterTab={
        <BuildSteps
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          loadingTemplates={isFetching}
          setShouldUpdateThemeImage={setShouldUpdateThemeImage}
          shouldUpdateThemeImage={shouldUpdateThemeImage}
          assetBuild={assetBuild}
          selectedTab={selectedAssetTypeTab}
          removeFilterTemplate={removeFilterTemplate}
          toggleSize={size => {
            // ToggleSize called when user selects new size from the panel.
            // When this happens, it is the first instance for selected size.
            // So safe to assume that this instance is at index 0;
            const id = uuid.v4();

            props.setSelectedAssetBuildInstance([
              {
                size,
                assetType: selectedAssetTypeTab,
                order: 0,
                instance: {
                  id,
                },
              },
            ]);

            props.updateAssetInstances(selectedAssetTypeTab, size, [
              {
                id,
                template: null,
                offers: {},
              },
            ]);
          }}
          templatesBySize={templatesBySizeAndType[selectedAssetTypeTab]}
          selections={getSelectedAssetSizes({
            assetInstances,
          })}
          savedOrder={savedOrder}
          updateAssetInstance={(instance, assetType, size, order) => {
            if (Array.isArray(instance)) {
              props.updateAssetInstances(assetType, size, instance);
            } else {
              const assetBuildInstancesForSize = assetBuild.instances[
                assetType
              ]?.[size]?.map((assetBuildInstance, index) =>
                index === order
                  ? instance
                  : assetBuildInstanceToAssetInstance(assetBuildInstance),
              ) as IAssetInstance[];

              if (!assetBuildInstancesForSize) return;

              props.updateAssetInstances(
                assetType,
                size,
                assetBuildInstancesForSize,
              );
            }
          }}
          deleteInstance={args => {
            props.updateAssetInstances(
              args.assetType || "",
              args.size || "",
              null,
            );
          }}
        />
      }
    />
  );
};

// helper functions
type TGetSelectedAssetSizesArgs = {
  assetInstances: Record<string, Record<string, IAssetInstance[]>>;
};
const getSelectedAssetSizes = (args: TGetSelectedAssetSizesArgs) => {
  if (!args.assetInstances) return {};

  const ret: Record<string, Record<string, boolean>> = {};
  for (const assetType in args.assetInstances) {
    const instance = args.assetInstances[assetType];

    if (!instance) continue;

    for (const size in instance) {
      if (isEmpty(instance[size])) continue;

      if (!ret[assetType]) ret[assetType] = {};

      ret[assetType][size] = true;
    }
  }

  return ret;
};

const getSelectedAssetSizeArray = (args: TGetSelectedAssetSizesArgs) => {
  const ret = [];
  for (const assetType in args.assetInstances) {
    for (const size in args.assetInstances[assetType]) {
      ret.push(size);
    }
  }

  return ret;
};

const mapStateToProps = (state: {
  designStudio: IDesignStudioState;
  assetBuilder: IAssetBuilderState;
  oemManagement: IBrandManagementState;
  configuration: IConfigurationState;
}) => {
  const {
    assetBuilder: { assetInstances, savedOrder },
    configuration: { config },
  } = state;

  const { assetBuilder, oemManagement } = state;
  const { selectedOffers, selectedTemplateSizes } =
    assetBuilder as IAssetBuilderState;
  const { oemRecords } = oemManagement;

  const updatedSelectedOffers: ISelectedOffer[] = [];
  for (const offer of selectedOffers) {
    const { offerData } = offer;
    const { vehicleCondition, expirationDate } = offerData;
    const updatedOffer = offer;
    if (vehicleCondition === "Used" || vehicleCondition === "CPO") {
      if (!expirationDate || parseInt(expirationDate) < Date.now()) {
        updatedSelectedOffers.push({
          ...updatedOffer,
          offerData: {
            ...updatedOffer.offerData,
            expirationDate: `${
              (savedOrder as ISavedOrderState).selectedOrder.expiresAt
            }`,
          },
        });
        continue;
      }
    }

    updatedSelectedOffers.push(updatedOffer);
  }

  return {
    config,
    oems: oemRecords,
    assetInstances,
    availableOffers: updatedSelectedOffers,
    selectedTemplateSizes,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  fetchOffers: () =>
    dispatch(actions.assetBuilder.fetchOfferList({ currentPage: 1 })),
  updateAssetInstances: (
    assetType: string,
    size: string,
    instances: IAssetInstance[] | null,
  ) =>
    dispatch(
      actions.assetBuilder.updateAssetInstances({ assetType, size, instances }),
    ),
  removeFilterTemplate: (selectedAssetTypeTab: string, filterOption: string) =>
    dispatch(
      actions.assetBuilder.removeFilterTemplate({
        selectedTab: selectedAssetTypeTab,
        filterOption,
      }),
    ),
  setSelectedAssetBuildInstance: (args: SelectedInstance[]) => {
    dispatch(actions.assetBuilder.setSelectedAssetBuildInstance(args));
  },
  setBuildPage: (args: TSetBuildPage) => {
    dispatch(actions.assetBuilder.setBuildPage(args));
  },
  updateNewOrder: (updateNewOrder: Partial<INewOrderRecord>) => {
    dispatch(actions.newOrders.updateNewOrder(updateNewOrder));
  },
  updateAssetTypeCount: (assetTypeCount: TAssetTypeCount) => {
    dispatch(actions.assetBuilder.updateAssetTypeCount(assetTypeCount));
  },
  resetAssetInstanceCounter: () => {
    dispatch(actions.assetBuilder.resetAssetInstanceCounter());
  },
  setAssetInstanceComparator: (assetInstances: AssetInstanceRecord) => {
    dispatch(actions.assetBuilder.setAssetInstanceComparator(assetInstances));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Build);
