import { QueryClient } from "react-query";
import { queryKeys } from "./queryKeys";
import { ApiResponseCampaignPlanner } from "screens/campaignManagement/campaignPlanner/types";
import produce from "immer";

export const optimisticUpdate = (
  queryClient: QueryClient,
  newPlanner: ApiResponseCampaignPlanner,
) => {
  queryClient.setQueryData(
    [queryKeys.campaignPlanners],
    produce<ApiResponseCampaignPlanner[]>(
      (previousPlanners: ApiResponseCampaignPlanner[] = []) => {
        const plannerIndex = previousPlanners.findIndex(
          planner => planner.id === newPlanner.id,
        );
        if (plannerIndex === -1) return previousPlanners.concat(newPlanner);
        previousPlanners[plannerIndex] = newPlanner;
      },
    ),
  );
};
