import { IConfig } from "shared/types/configuration";
import { IDisclosure } from "shared/types/legalLingo";
import HttpClient from "services/httpClient";
import { IApiResponse } from "shared/types/shared";

export default ({ config }: { config: IConfig }) => ({
  getDisclosures: (): Promise<IApiResponse<IDisclosure[]>> => {
    const { services } = config;
    const getDisclosuresUrl = services.legal.getDisclosuresUrl;
    return HttpClient.get(getDisclosuresUrl);
  },
  getDisclosure: <T>(searchParams: {
    id?: string;
    storeName?: string;
    stateName?: string;
    condition?: string;
    vin?: string;
    oem?: string;
  }): Promise<T> => {
    const { id, storeName, stateName, condition, vin, oem } = searchParams;
    // condition sometimes comes as all caps or all lowercase
    const capitalizedCondition =
      condition && condition.length > 2
        ? condition.charAt(0).toUpperCase() + condition.slice(1).toLowerCase()
        : condition;

    // all vins have 17 chars (only really old vins don't but we do not support them)
    const validVin = vin?.length === 17 ? vin : "";
    const params = {
      ...(id && { disclosureName: id }),
      ...(storeName && { storeName }),
      ...(stateName && { stateName }),
      ...(capitalizedCondition && { condition: capitalizedCondition }),
      ...{ vin: validVin }, // need this param to be there even if its empty
      ...(oem && { oem: oem }),
    };
    const queryString = new URLSearchParams(params).toString();
    const url = config.services.legal.updateDisclosureUrl;
    const getDisclosureUrl = `${url}?${queryString}`;

    return HttpClient.get(getDisclosureUrl);
  },
  createDisclosure: <T>(disclosure: IDisclosure): Promise<T> => {
    const { id, ...filteredDiscToCreate } = disclosure;
    const createDisclosureUrl = config.services.legal.createDisclosureUrl;
    return HttpClient.post(createDisclosureUrl, {
      lingoDataToCreate: { ...filteredDiscToCreate, createdAt: Date.now() },
    });
  },
  updateDisclosure: <T>(disclosure: IDisclosure): Promise<T> => {
    const { id, ...filteredDiscToCreate } = disclosure;
    const updateDisclosureUrl = config.services.legal.updateDisclosureUrl;
    return HttpClient.put(updateDisclosureUrl, {
      lingoDataToUpdate: { ...filteredDiscToCreate },
    });
  },

  deleteDisclosure: <T>(disclosure: IDisclosure): Promise<T> => {
    const deleteDisclosureUrl = `${config.services.legal.updateDisclosureUrl}?lingoDataToDelete=${disclosure.name}`;
    return HttpClient.delete(deleteDisclosureUrl);
  },
});
