import { useEffect, useLayoutEffect, useRef } from "react";

/**
 * Check whether a click event was fired outside the element with the returned `targetRef`
 * and trigger the callback
 * @param callback The callback function that will be called when clicking outside the element with `targetRef`
 * @returns The reference to assign to the element we want to monitor the outside click
 */
const useOutsideClick = <T extends HTMLElement>(callback: () => void) => {
  const targetRef = useRef<T>(null);

  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (targetRef.current && !targetRef.current.contains(e.target as Node)) {
        callbackRef.current();
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return targetRef;
};

export default useOutsideClick;
