import { useState } from "react";
import { IThemeBackgroundImage } from "shared/types/assetBuilder";
import { filterHelper, prependItem } from "../artboardDrawer/utils";
import { RowTitleType } from "../artboardDrawer/types";

interface Props {
  isEdit: boolean;
  updateBgImage: (image: IThemeBackgroundImage) => void;
  setCurrImage: React.Dispatch<React.SetStateAction<IThemeBackgroundImage>>;
  currImage: IThemeBackgroundImage;
  editThemeImageFile: (image: IThemeBackgroundImage) => void;
}

export const useDrawerRow = ({
  isEdit,
  updateBgImage,
  setCurrImage,
  currImage,
  editThemeImageFile,
}: Props) => {
  const [selectedBrandNames, setSelectedBrandNames] = useState<string[]>([]);
  const [selectedAccountNames, setSelectedAccountNames] = useState<string[]>(
    [],
  );
  const [selectedTagNames, setSelectedTagNames] = useState<string[]>([]);

  const rowOperations = {
    brands: { fn: setSelectedBrandNames, arr: selectedBrandNames },
    accounts: { fn: setSelectedAccountNames, arr: selectedAccountNames },
    tags: { fn: setSelectedTagNames, arr: selectedTagNames },
  };

  const updateImage = (type: RowTitleType, option: string, isAdd: boolean) => {
    const updates = {
      [type]: isAdd
        ? prependItem(option, currImage[type])
        : filterHelper(option, currImage[type]),
    };
    updateBgImage({ ...currImage, ...updates });
    setCurrImage({ ...currImage, ...updates });
    editThemeImageFile(currImage);
  };

  const onRowSelect = (type: RowTitleType, newOption: string) => {
    if (isEdit) {
      updateImage(type, newOption, true);
    }
    const { fn, arr } = rowOperations[type];
    fn([...arr, newOption]);
  };

  const onRowDeselect = (type: RowTitleType, removeOption: string) => {
    if (isEdit) {
      updateImage(type, removeOption, false);
    }
    const { fn, arr } = rowOperations[type];
    fn(arr.filter(opt => opt !== removeOption));
  };

  return {
    onRowSelect,
    onRowDeselect,
    selectedBrandNames,
    selectedAccountNames,
    selectedTagNames,
    setSelectedBrandNames,
    setSelectedAccountNames,
    setSelectedTagNames,
  };
};
