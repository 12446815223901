import {
  formSchema,
  internalFormSchema,
} from "screens/assetBuilder/offers/select/offerForm/schema";
import { OfferType } from "shared/types/shared";
import { IOfferTypeVariable, TDisclosureType } from "./../types/legalLingo";
import {
  SingletonOfferKeys,
  IOfferDataSection,
} from "shared/types/assetBuilder";

// Possible TO DO: write date formatter function
export const dateFormat = "MM/DD/YYYY";
export const timeFormat = "hh:mma";
export const yyyymmddFormat = `YYYY/MM/DD ${timeFormat}`;
export const mmddyyyyTimeFormat = `MM/DD/YYYY ${timeFormat}`;

export const fullCountryStateOptions = [
  {
    name: "United States",
    abbreviation: "US",
    states: [
      {
        name: "Alaska",
        abbreviation: "AK",
      },
      {
        name: "Alabama",
        abbreviation: "AL",
      },
      {
        name: "Arkansas",
        abbreviation: "AR",
      },
      {
        name: "Arizona",
        abbreviation: "AZ",
      },
      {
        name: "California",
        abbreviation: "CA",
      },
      {
        name: "Colorado",
        abbreviation: "CO",
      },
      {
        name: "Connecticut",
        abbreviation: "CT",
      },
      {
        name: "Delaware",
        abbreviation: "DE",
      },
      {
        name: "Florida",
        abbreviation: "FL",
      },
      {
        name: "Georgia",
        abbreviation: "GA",
      },
      {
        name: "Hawaii",
        abbreviation: "HI",
      },
      {
        name: "Iowa",
        abbreviation: "IA",
      },
      {
        name: "Idaho",
        abbreviation: "ID",
      },
      {
        name: "Illinois",
        abbreviation: "IL",
      },
      {
        name: "Indiana",
        abbreviation: "IN",
      },
      {
        name: "Kansas",
        abbreviation: "KS",
      },
      {
        name: "Kentucky",
        abbreviation: "KY",
      },
      {
        name: "Louisiana",
        abbreviation: "LA",
      },

      {
        name: "Massachusetts",
        abbreviation: "MA",
      },
      {
        name: "Maryland",
        abbreviation: "MD",
      },

      {
        name: "Maine",
        abbreviation: "ME",
      },
      {
        name: "Michigan",
        abbreviation: "MI",
      },
      {
        name: "Minnesota",
        abbreviation: "MN",
      },
      {
        name: "Missouri",
        abbreviation: "MO",
      },
      {
        name: "Mississippi",
        abbreviation: "MS",
      },
      {
        name: "Montana",
        abbreviation: "MT",
      },
      {
        name: "North Carolina",
        abbreviation: "NC",
      },
      {
        name: "North Dakota",
        abbreviation: "ND",
      },
      {
        name: "Nebraska",
        abbreviation: "NE",
      },
      {
        name: "New Hampshire",
        abbreviation: "NH",
      },
      {
        name: "New Jersey",
        abbreviation: "NJ",
      },
      {
        name: "New Mexico",
        abbreviation: "NM",
      },
      {
        name: "Nevada",
        abbreviation: "NV",
      },
      {
        name: "New York",
        abbreviation: "NY",
      },
      {
        name: "Ohio",
        abbreviation: "OH",
      },
      {
        name: "Oklahoma",
        abbreviation: "OK",
      },
      {
        name: "Oregon",
        abbreviation: "OR",
      },
      {
        name: "Pennsylvania",
        abbreviation: "PA",
      },
      {
        name: "Rhode Island",
        abbreviation: "RI",
      },
      {
        name: "South Carolina",
        abbreviation: "SC",
      },
      {
        name: "South Dakota",
        abbreviation: "SD",
      },
      {
        name: "Tennessee",
        abbreviation: "TN",
      },
      {
        name: "Texas",
        abbreviation: "TX",
      },
      {
        name: "Utah",
        abbreviation: "UT",
      },
      {
        name: "Virginia",
        abbreviation: "VA",
      },
      {
        name: "Vermont",
        abbreviation: "VT",
      },
      {
        name: "Washington",
        abbreviation: "WA",
      },
      {
        name: "Wisconsin",
        abbreviation: "WI",
      },
      {
        name: "West Virginia",
        abbreviation: "WV",
      },
      {
        name: "Wyoming",
        abbreviation: "WY",
      },
    ],
  },
  {
    name: "Canada",
    abbreviation: "CN",
    states: [
      {
        name: "Alberta",
        abbreviation: "AB",
      },
      {
        name: "British Columbia",
        abbreviation: "BC",
      },
      {
        name: "Manitoba",
        abbreviation: "MB",
      },
      {
        name: "New Brunswick",
        abbreviation: "NB",
      },
      {
        name: "Newfoundland and Labrador",
        abbreviation: "NL",
      },
      {
        name: "Northwest Territories",
        abbreviation: "NT",
      },
      {
        name: "Nova Scotia",
        abbreviation: "NS",
      },
      {
        name: "Nunavut",
        abbreviation: "NU",
      },
      {
        name: "Ontario",
        abbreviation: "ON",
      },
      {
        name: "Prince Edward Island",
        abbreviation: "PE",
      },
      {
        name: "Quebec",
        abbreviation: "QC",
      },
      {
        name: "Saskatchewan",
        abbreviation: "SK",
      },
      {
        name: "Yukon",
        abbreviation: "YT",
      },
    ],
  },
] as const;

export const fullStateOptions = fullCountryStateOptions.reduce<
  Array<{ name: string; abbreviation: string }>
>((acc, { states }) => {
  return [...acc, ...states];
}, []);

export const stateOptions = fullStateOptions.map(
  ({ abbreviation }) => abbreviation,
);

export const assetTypes = ["Display", "Email", "Social", "Website", "Misc"];

export const purchaseOptions: OfferType[] = [
  OfferType.Purchase,
  OfferType["Purchase($ Off)"],
  OfferType["Purchase(%)"],
  OfferType["Purchase(Conditional)"],
];

export const offerTypes: OfferType[] = [
  OfferType.Lease,
  OfferType.ZeroDownLease,
  OfferType.Finance,
  OfferType.APR,
].concat(purchaseOptions);

export const defaultOfferIndex = {
  [OfferType.Lease]: false,
  [OfferType.ZeroDownLease]: false,
  [OfferType.Finance]: false,
  [OfferType.Purchase]: false,
  [OfferType.APR]: false,
};

const vehicleInfoData = [
  {
    key: "1",
    title: "VIN",
    field: "lingo_vin",
    sampleData: "",
  },
  {
    key: "2",
    title: "MSRP",
    field: "lingo_msrp",
    sampleData: "",
  },
  {
    key: "3",
    title: "Expiration Date",
    field: "lingo_expiration_date",
    sampleData: "",
  },
  {
    key: "4",
    title: "Days in Stock",
    field: "lingo_days_in_stock",
    sampleData: "",
  },
  {
    key: "5",
    title: "Year",
    field: "lingo_year",
    sampleData: "2017",
  },
  {
    key: "6",
    title: "Make",
    field: "lingo_make",
    sampleData: "Honda",
  },
  {
    key: "7",
    title: "Model",
    field: "lingo_model",
    sampleData: "Accord",
  },
  {
    key: "8",
    title: "Trim",
    field: "lingo_trim",
    sampleData: "XL",
  },
  {
    key: "9",
    title: "Drivetrain",
    field: "lingo_drivetrain",
    sampleData: "",
  },
  {
    key: "10",
    title: "Transmission",
    field: "lingo_transmission",
    sampleData: "Super Charged",
  },
  {
    key: "11",
    title: "Cylinders",
    field: "lingo_cylinders",
    sampleData: "",
  },
  {
    key: "12",
    title: "Feature 1",
    field: "lingo_feature_one",
    sampleData: "",
  },
  {
    key: "13",
    title: "Feature 2",
    field: "lingo_feature_two",
    sampleData: "",
  },
  {
    key: "14",
    title: "Condition",
    field: "lingo_condition",
    sampleData: "",
  },
  {
    key: "15",
    title: "Accessories",
    field: "lingo_accessories",
    sampleData: "",
  },
  {
    key: "16",
    title: "Model Code",
    field: "lingo_model_code",
    sampleData: "",
  },
  {
    key: "17",
    title: "Package",
    field: "lingo_package",
    sampleData: "",
  },
  {
    key: "18",
    title: "Number at this Price",
    field: "lingo_number_at_this_price",
    sampleData: "",
  },
];

export const numberAtThisPriceData = [
  {
    key: "1",
    title: "VINs at this Price",
    field: "lingo_vins_at_this_price",
    sampleData: "5",
  },
  {
    key: "2",
    title: "VINs List",
    field: "lingo_vins_list",
    sampleData: "XXXXXXXX, XXXXXXXX",
  },
];

const internalVehicleInfoData = [
  {
    key: "1",
    title: "vin",
    field: "lingo_vin",
    sampleData: "",
  },
  {
    key: "2",
    title: "year",
    field: "lingo_year",
    sampleData: "2017",
  },
  {
    key: "3",
    title: "make",
    field: "lingo_make",
    sampleData: "Honda",
  },
  {
    key: "4",
    title: "model",
    field: "lingo_model",
    sampleData: "Accord",
  },
  {
    key: "5",
    title: "trim",
    field: "lingo_trim",
    sampleData: "XL",
  },
];

const leaseData = [
  {
    key: "1",
    title: "Number Of Payments",
    field: "lingo_number_of_payments",
    sampleData: "--",
  },
  {
    key: "2",
    title: "Monthly Payment",
    field: "lingo_monthly_payment",
    sampleData: "--",
  },

  {
    key: "3",
    title: "Total Due at Signing",
    field: "lingo_total_due_at_signing",
    sampleData: "--",
  },
  {
    key: "4",
    title: "Residual Sales Value",
    field: "lingo_residual_sales_value",
    sampleData: "--",
  },
  {
    key: "5",
    title: "Total Due at Signing Including Rebates",
    field: "lingo_total_due_at_signing_including_rebates",
    sampleData: "--",
  },
  {
    key: "6",
    title: "Capitalized Cost",
    field: "lingo_capitalized_cost",
    sampleData: "--",
  },
  {
    key: "7",
    title: "Cap Cost Reduction",
    field: "lingo_cap_cost_reduction",
    sampleData: "--",
  },
  {
    key: "8",
    title: "Net Adjusted Capitalized Cost",
    field: "lingo_net_adjusted_capitalized_cost",
    sampleData: "--",
  },
  {
    key: "9",
    title: "Total Lease Charge",
    field: "lingo_total_lease_charge",
    sampleData: "--",
  },
  {
    key: "10",
    title: "Total Payments",
    field: "lingo_total_payments",
    sampleData: "--",
  },
  {
    key: "11",
    title: "Security Deposit",
    field: "lingo_security_deposit",
    sampleData: "--",
  },
  {
    key: "12",
    title: "Termination Fee",
    field: "lingo_termination_fee",
    sampleData: "--",
  },
  {
    key: "13",
    title: "FICO",
    field: "lingo_fico",
    sampleData: "--",
  },
  {
    key: "14",
    title: "Miles Per Year",
    field: "lingo_miles_per_year",
    sampleData: "--",
  },
  {
    key: "15",
    title: "Cents Per Mile",
    field: "lingo_cents_per_mile",
    sampleData: "--",
  },
  {
    key: "16",
    title: "Acquisition Fee",
    field: "lingo_acquisition_fee",
    sampleData: "--",
  },
  {
    key: "17",
    title: "Additional Lease Disclosure",
    field: "lingo_additional_lease_disclosure",
    sampleData: "--",
  },
  {
    key: "18",
    title: "Lease Rebate 1 Amount",
    field: "lingo_lease_rebate_one_amount",
    sampleData: "--",
  },
  {
    key: "19",
    title: "Lease Rebase 1 Name",
    field: "lingo_lease_rebate_one_name",
    sampleData: "--",
  },
  {
    key: "20",
    title: "Lease Rebate 1 Disclosure",
    field: "lingo_lease_rebate_one_disclosure",
    sampleData: "--",
  },
  {
    key: "21",
    title: "Lease Rebate 2 Amount",
    field: "lingo_lease_rebate_two_amount",
    sampleData: "--",
  },
  {
    key: "22",
    title: "Lease Rebase 2 Name",
    field: "lingo_lease_rebate_two_name",
    sampleData: "--",
  },
  {
    key: "23",
    title: "Lease Rebate 2 Disclosure",
    field: "lingo_lease_rebate_two_disclosure",
    sampleData: "--",
  },
  {
    key: "24",
    title: "Lease Rebate 3 Amount",
    field: "lingo_lease_rebate_three_amount",
    sampleData: "--",
  },
  {
    key: "25",
    title: "Lease Rebase 3 Name",
    field: "lingo_lease_rebate_three_name",
    sampleData: "--",
  },
  {
    key: "26",
    title: "Lease Rebate 3 Disclosure",
    field: "lingo_lease_rebate_three_disclosure",
    sampleData: "--",
  },
  {
    key: "27",
    title: "Lease Rebate 4 Amount",
    field: "lingo_lease_rebate_four_amount",
    sampleData: "--",
  },
  {
    key: "28",
    title: "Lease Rebase 4 Name",
    field: "lingo_lease_rebate_four_name",
    sampleData: "--",
  },
  {
    key: "29",
    title: "Lease Rebate 4 Disclosure",
    field: "lingo_lease_rebate_four_disclosure",
    sampleData: "--",
  },
  {
    key: "30",
    title: "Lease Rebate 5 Amount",
    field: "lingo_lease_rebate_five_amount",
    sampleData: "--",
  },
  {
    key: "31",
    title: "Lease Rebase 5 Name",
    field: "lingo_lease_rebate_five_name",
    sampleData: "--",
  },
  {
    key: "32",
    title: "Lease Rebate 5 Disclosure",
    field: "lingo_lease_rebate_five_disclosure",
    sampleData: "--",
  },
  {
    key: "33",
    title: "Lease Rebate 6 Amount",
    field: "lingo_lease_rebate_six_amount",
    sampleData: "--",
  },
  {
    key: "34",
    title: "Lease Rebase 6 Name",
    field: "lingo_lease_rebate_six_name",
    sampleData: "--",
  },
  {
    key: "35",
    title: "Lease Rebate 6 Disclosure",
    field: "lingo_lease_rebate_six_disclosure",
    sampleData: "--",
  },
];

const zeroDownLeaseData = [
  {
    key: "1",
    title: "Number Of Payments",
    field: "lingo_zero_down_number_of_payments",
    sampleData: "--",
  },
  {
    key: "2",
    title: "Monthly Payment",
    field: "lingo_zero_down_monthly_payment",
    sampleData: "--",
  },

  {
    key: "3",
    title: "Total Due at Signing",
    field: "lingo_zero_down_total_due_at_signing",
    sampleData: "--",
  },
  {
    key: "4",
    title: "Residual Sales Value",
    field: "lingo_zero_down_residual_sales_value",
    sampleData: "--",
  },
  {
    key: "5",
    title: "Total Due at Signing Including Rebates",
    field: "lingo_zero_down_total_due_at_signing_including_rebates",
    sampleData: "--",
  },
  {
    key: "6",
    title: "Capitalized Cost",
    field: "lingo_zero_down_capitalized_cost",
    sampleData: "--",
  },
  {
    key: "7",
    title: "Cap Cost Reduction",
    field: "lingo_zero_down_cap_cost_reduction",
    sampleData: "--",
  },
  {
    key: "8",
    title: "Net Adjusted Capitalized Cost",
    field: "lingo_zero_down_net_adjusted_capitalized_cost",
    sampleData: "--",
  },
  {
    key: "9",
    title: "Total Lease Charge",
    field: "lingo_zero_down_total_lease_charge",
    sampleData: "--",
  },
  {
    key: "10",
    title: "Total Payments",
    field: "lingo_zero_down_total_payments",
    sampleData: "--",
  },
  {
    key: "11",
    title: "Security Deposit",
    field: "lingo_zero_down_security_deposit",
    sampleData: "--",
  },
  {
    key: "12",
    title: "Termination Fee",
    field: "lingo_zero_down_termination_fee",
    sampleData: "--",
  },
  {
    key: "13",
    title: "FICO",
    field: "lingo_zero_down_fico",
    sampleData: "--",
  },
  {
    key: "14",
    title: "Miles Per Year",
    field: "lingo_zero_down_miles_per_year",
    sampleData: "--",
  },
  {
    key: "15",
    title: "Cents Per Mile",
    field: "lingo_zero_down_cents_per_mile",
    sampleData: "--",
  },
  {
    key: "16",
    title: "Acquisition Fee",
    field: "lingo_zero_down_acquisition_fee",
    sampleData: "--",
  },
  {
    key: "17",
    title: "Zero Down Lease Rebate 1 Amount",
    field: "lingo_zero_down_lease_rebate_one_amount",
    sampleData: "--",
  },
  {
    key: "18",
    title: "Zero Down Lease Rebase 1 Name",
    field: "lingo_zero_down_lease_rebate_one_name",
    sampleData: "--",
  },
  {
    key: "19",
    title: "Zero Down Lease Rebate 1 Disclosure",
    field: "lingo_zero_down_lease_rebate_one_disclosure",
    sampleData: "--",
  },
  {
    key: "20",
    title: "Zero Down Lease Rebate 2 Amount",
    field: "lingo_zero_down_lease_rebate_two_amount",
    sampleData: "--",
  },
  {
    key: "21",
    title: "Zero Down Lease Rebase 2 Name",
    field: "lingo_zero_down_lease_rebate_two_name",
    sampleData: "--",
  },
  {
    key: "22",
    title: "Zero Down Lease Rebate 2 Disclosure",
    field: "lingo_zero_down_lease_rebate_two_disclosure",
    sampleData: "--",
  },
  {
    key: "23",
    title: "Zero Down Lease Rebate 3 Amount",
    field: "lingo_zero_down_lease_rebate_three_amount",
    sampleData: "--",
  },
  {
    key: "24",
    title: "Zero Down Lease Rebase 3 Name",
    field: "lingo_zero_down_lease_rebate_three_name",
    sampleData: "--",
  },
  {
    key: "25",
    title: "Zero Down Lease Rebate 3 Disclosure",
    field: "lingo_zero_down_lease_rebate_three_disclosure",
    sampleData: "--",
  },
  {
    key: "26",
    title: "Zero Down Lease Rebate 4 Amount",
    field: "lingo_zero_down_lease_rebate_four_amount",
    sampleData: "--",
  },
  {
    key: "27",
    title: "Zero Down Lease Rebase 4 Name",
    field: "lingo_zero_down_lease_rebate_four_name",
    sampleData: "--",
  },
  {
    key: "28",
    title: "Zero Down Lease Rebate 4 Disclosure",
    field: "lingo_zero_down_lease_rebate_four_disclosure",
    sampleData: "--",
  },
  {
    key: "29",
    title: "Zero Down Lease Rebate 5 Amount",
    field: "lingo_zero_down_lease_rebate_five_amount",
    sampleData: "--",
  },
  {
    key: "30",
    title: "Zero Down Lease Rebase 5 Name",
    field: "lingo_zero_down_lease_rebate_five_name",
    sampleData: "--",
  },
  {
    key: "31",
    title: "Zero Down Lease Rebate 5 Disclosure",
    field: "lingo_zero_down_lease_rebate_five_disclosure",
    sampleData: "--",
  },
  {
    key: "32",
    title: "Zero Down Lease Rebate 6 Amount",
    field: "lingo_zero_down_lease_rebate_six_amount",
    sampleData: "--",
  },
  {
    key: "33",
    title: "Zero Down Lease Rebase 6 Name",
    field: "lingo_zero_down_lease_rebate_six_name",
    sampleData: "--",
  },
  {
    key: "34",
    title: "Zero Down Lease Rebate 6 Disclosure",
    field: "lingo_zero_down_lease_rebate_six_disclosure",
    sampleData: "--",
  },
];
const financeData = [
  {
    key: "1",
    title: "Rate",
    field: "lingo_rate",
    sampleData: "--",
  },
  {
    key: "2",
    title: "Term",
    field: "lingo_term",
    sampleData: "--",
  },

  {
    key: "3",
    title: "Payment",
    field: "lingo_payment",
    sampleData: "--",
  },
  {
    key: "4",
    title: "Amount Financed",
    field: "lingo_amount_financed",
    sampleData: "--",
  },
  {
    key: "5",
    title: "Finance Down Payment",
    field: "lingo_finance_down_payment",
    sampleData: "--",
  },
  {
    key: "6",
    title: "Finance Min FICO",
    field: "lingo_finance_min_fico",
    sampleData: "--",
  },
];
const purchaseData = [
  {
    key: "1",
    title: "Dealer Discount",
    field: "lingo_dealer_discount",
    sampleData: "--",
  },
  {
    key: "2",
    title: "Dealer Enrolled Price",
    field: "lingo_dealer_price",
    sampleData: "--",
  },

  {
    key: "3",
    title: "Final Price",
    field: "lingo_final_price",
    sampleData: "--",
  },
  {
    key: "4",
    title: "Savings Off MSRP",
    field: "lingo_savings_off_msrp",
    sampleData: "--",
  },
  {
    key: "5",
    title: "Net Cost",
    field: "lingo_net_cost",
    sampleData: "--",
  },
  {
    key: "6",
    title: "Total",
    field: "lingo_total",
    sampleData: "--",
  },
  {
    key: "7",
    title: "Price After Conditional Rebates",
    field: "lingo_price_after_conditional_rebates",
    sampleData: "--",
  },
  {
    key: "8",
    title: "Savings Off MSRP Description",
    field: "lingo_savings_off_msrp_description",
    sampleData: "--",
  },
  {
    key: "9",
    title: "% off MSRP Value",
    field: "lingo_percent_off_msrp_value",
    sampleData: "--",
  },
  {
    key: "10",
    title: "Additional Purchase Disclosure",
    field: "lingo_additional_purchase_disclosure",
    sampleData: "--",
  },
  //////////////// ADD PURCHASE VARS //////////////////////////

  {
    key: "11",
    title: "Purchase Rebate 1 Amount",
    field: "lingo_purchase_rebate_one_amount",
    sampleData: "--",
  },
  {
    key: "12",
    title: "Purchase Rebate 1 Name",
    field: "lingo_purchase_rebate_one_name",
    sampleData: "--",
  },
  {
    key: "13",
    title: "Purchase Rebate 1 Disclosure",
    field: "lingo_purchase_rebate_one_disclosure",
    sampleData: "--",
  },
  {
    key: "14",
    title: "Purchase Rebate 2 Amount",
    field: "lingo_purchase_rebate_two_amount",
    sampleData: "--",
  },
  {
    key: "15",
    title: "Purchase Rebate 2 Name",
    field: "lingo_purchase_rebate_two_name",
    sampleData: "--",
  },
  {
    key: "16",
    title: "Purchase Rebate 2 Disclosure",
    field: "lingo_purchase_rebate_two_disclosure",
    sampleData: "--",
  },
  {
    key: "17",
    title: "Purchase Rebate 3 Amount",
    field: "lingo_purchase_rebate_three_amount",
    sampleData: "--",
  },
  {
    key: "18",
    title: "Purchase Rebate 3 Name",
    field: "lingo_purchase_rebate_three_name",
    sampleData: "--",
  },
  {
    key: "19",
    title: "Purchase Rebate 3 Disclosure",
    field: "lingo_purchase_rebate_three_disclosure",
    sampleData: "--",
  },
  {
    key: "20",
    title: "Purchase Rebate 4 Amount",
    field: "lingo_purchase_rebate_four_amount",
    sampleData: "--",
  },
  {
    key: "21",
    title: "Purchase Rebate 4 Name",
    field: "lingo_purchase_rebate_four_name",
    sampleData: "--",
  },
  {
    key: "22",
    title: "Purchase Rebate 4 Disclosure",
    field: "lingo_purchase_rebate_four_disclosure",
    sampleData: "--",
  },
  {
    key: "23",
    title: "Purchase Rebate 5 Amount",
    field: "lingo_purchase_rebate_five_amount",
    sampleData: "--",
  },
  {
    key: "24",
    title: "Purchase Rebate 5 Name",
    field: "lingo_purchase_rebate_five_name",
    sampleData: "--",
  },
  {
    key: "25",
    title: "Purchase Rebate 5 Disclosure",
    field: "lingo_purchase_rebate_five_disclosure",
    sampleData: "--",
  },
  {
    key: "26",
    title: "Purchase Rebate 6 Amount",
    field: "lingo_purchase_rebate_six_amount",
    sampleData: "--",
  },
  {
    key: "27",
    title: "Purchase Rebate 6 Name",
    field: "lingo_purchase_rebate_six_name",
    sampleData: "--",
  },
  {
    key: "28",
    title: "Purchase Rebate 6 Disclosure",
    field: "lingo_purchase_rebate_six_disclosure",
    sampleData: "--",
  },
  /////////////////////// CONDITIONAL ///////////////////////////
  {
    key: "29",
    title: "Conditional Rebate 1 Amount",
    field: "lingo_conditional_rebate_one_amount",
    sampleData: "--",
  },
  {
    key: "30",
    title: "Conditional Rebate 1 Name",
    field: "lingo_conditional_rebate_one_name",
    sampleData: "--",
  },
  {
    key: "31",
    title: "Conditional Rebate 1 Disclosure",
    field: "lingo_conditional_rebate_one_disclosure",
    sampleData: "--",
  },
  {
    key: "32",
    title: "Conditional Rebate 2 Amount",
    field: "lingo_conditional_rebate_two_amount",
    sampleData: "--",
  },
  {
    key: "33",
    title: "Conditional Rebate 2 Name",
    field: "lingo_conditional_rebate_two_name",
    sampleData: "--",
  },
  {
    key: "34",
    title: "Conditional Rebate 2 Disclosure",
    field: "lingo_conditional_rebate_two_disclosure",
    sampleData: "--",
  },
  {
    key: "35",
    title: "Conditional Rebate 3 Amount",
    field: "lingo_conditional_rebate_three_amount",
    sampleData: "--",
  },
  {
    key: "36",
    title: "Conditional Rebate 3 Name",
    field: "lingo_conditional_rebate_three_name",
    sampleData: "--",
  },
  {
    key: "37",
    title: "Conditional Rebate 3 Disclosure",
    field: "lingo_conditional_rebate_three_disclosure",
    sampleData: "--",
  },
  {
    key: "38",
    title: "Conditional Rebate 4 Amount",
    field: "lingo_conditional_rebate_four_amount",
    sampleData: "--",
  },
  {
    key: "39",
    title: "Conditional Rebate 4 Name",
    field: "lingo_conditional_rebate_four_name",
    sampleData: "--",
  },
  {
    key: "40",
    title: "Conditional Rebate 4 Disclosure",
    field: "lingo_conditional_rebate_four_disclosure",
    sampleData: "--",
  },
  {
    key: "41",
    title: "Conditional Rebate 5 Amount",
    field: "lingo_conditional_rebate_five_amount",
    sampleData: "--",
  },
  {
    key: "42",
    title: "Conditional Rebate 5 Name",
    field: "lingo_conditional_rebate_five_name",
    sampleData: "--",
  },
  {
    key: "43",
    title: "Conditional Rebate 5 Disclosure",
    field: "lingo_conditional_rebate_five_disclosure",
    sampleData: "--",
  },
  {
    key: "44",
    title: "Conditional Rebate 6 Amount",
    field: "lingo_conditional_rebate_six_amount",
    sampleData: "--",
  },
  {
    key: "45",
    title: "Conditional Rebate 6 Name",
    field: "lingo_conditional_rebate_six_name",
    sampleData: "--",
  },
  {
    key: "46",
    title: "Conditional Rebate 6 Disclosure",
    field: "lingo_conditional_rebate_six_disclosure",
    sampleData: "--",
  },
];
const aprData = [
  {
    key: "1",
    title: "Condition",
    field: "lingo_apr_condition",
    sampleData: "--",
  },
  {
    key: "2",
    title: "Expiration Date",
    field: "lingo_apr_expiration_date",
    sampleData: "--",
  },

  {
    key: "3",
    title: "Additional APR Disclosure",
    field: "lingo_additional_apr_disclosure",
    sampleData: "--",
  },
  {
    key: "4",
    title: "Year",
    field: "lingo_apr_year",
    sampleData: "--",
  },
  {
    key: "5",
    title: "Make",
    field: "lingo_apr_make",
    sampleData: "--",
  },
  {
    key: "6",
    title: "Model Trim",
    field: "lingo_apr_model_trim",
    sampleData: "--",
  },
  {
    key: "7",
    title: "Rate",
    field: "lingo_apr_rate",
    sampleData: "--",
  },
  {
    key: "8",
    title: "Term",
    field: "lingo_apr_term",
    sampleData: "--",
  },
];

export const legalLingoDataDictionary: Record<
  TDisclosureType,
  Array<Record<string, string>>
> = {
  "Vehicle Info": vehicleInfoData,
  "Number at this Price": numberAtThisPriceData,
  Lease: leaseData,
  "Zero Down Lease": zeroDownLeaseData,
  Finance: financeData,
  Purchase: purchaseData,
  APR: aprData,
  "Expiration Date": [],
  "Extra Cost": [],
};

export const internalVehicleInfoVariableNames = internalVehicleInfoData.map(
  vehicleInfoObj => vehicleInfoObj.title,
);

export const numberAtThisPriceVariableNames = [
  "vinsAtThisPrice",
  "vinsList",
  "numberAtThisPrice",
];

export const storeVariableNames = [
  "dealerName",
  "address",
  "city",
  "state",
  "zip",
  "storeUrl",
  "phoneNumber",
  "finalPriceName",
];

/*
  TO DO:
  create separate array of vehicleInfo variable names for TemplateEditor / Canvas
  that includes {Secondary Message}and {Full Disclosure}
*/

// TO DO: create title - variable key matching for inserting offerType variables
export const internalLeaseVariableNames = [
  "leaseTerm",
  "numberOfPayments",
  "leaseDownPayment",
  "leaseExpirationDate",
  "leaseMonthlyPayment",
  "leaseTotalDueAtSigning",
  "leaseSellingPrice",
  "centsPerMile",
  "milesPerYear",
  "securityDeposit",
  "additionalLeaseDisclosure",
] as Array<SingletonOfferKeys>;

const extraCostVariableNames = [] as string[];

const expirationDateVariableNames = ["Expiration Date"];

const returnFormSchemaOfferTypeVariables = (
  formSchema: IOfferDataSection[],
) => {
  const variableMapping = formSchema.map(formObj => {
    const { title, header, body } = formObj;
    const headerFields = header.map(obj => {
      const { fields } = obj;
      const flattenedFields = fields.flat();
      return flattenedFields.map(field => field.key);
    });
    const bodyFields = body.map(obj => {
      const { fields } = obj;
      const flattenedFields = fields.flat();
      return flattenedFields.map(field => field.key);
    });
    const flattenedHeaderFields = headerFields.flat();
    const flattenedBodyFields = bodyFields.flat();

    const sortedVarNames = [
      ...flattenedHeaderFields,
      ...flattenedBodyFields,
    ].sort();

    /* Image url is not a variable to be populate */
    const finalVarNames: string[] = sortedVarNames.filter(
      variable => variable !== "imageUrl",
    );

    const isRebateField = (varName: string) => {
      const lowerCase = varName.toLowerCase();
      return (
        lowerCase.endsWith("rebate") ||
        /rebate(name|disclosure)/g.test(lowerCase)
      );
    };

    const rebateVarNames = finalVarNames.filter(varName =>
      isRebateField(varName),
    );

    const incrRebateVarNames = [];
    for (const varName of rebateVarNames) {
      const splitName = varName.split("Rebate");
      if (splitName.length < 2) {
        continue;
      }
      for (let i = 1; i < 7; i++) {
        const incrVarName = `${splitName[0]}Rebate${i}${splitName[1]}`;
        incrRebateVarNames.push(incrVarName);
      }
    }

    const customFieldVarNames = finalVarNames.filter(varName =>
      varName.endsWith("Custom"),
    );

    const incrCustomFieldVarNames = [];
    for (const varName of customFieldVarNames) {
      const splitName = varName.split("Custom");
      if (splitName[1] !== "") {
        continue;
      }
      for (let i = 1; i < 6; i++) {
        const incrVarName = `${varName}${i}`;
        incrCustomFieldVarNames.push(incrVarName);
      }
    }

    const newVarNames = finalVarNames
      .filter(varName => !varName.endsWith("Custom"))
      .filter(varName => !isRebateField(varName))
      .concat(incrRebateVarNames)
      .concat(incrCustomFieldVarNames);

    return {
      offerType: title,
      variables: newVarNames.map(varName => ({
        name: varName,
        requiredByStates: [] as string[],
      })),
    } as IOfferTypeVariable;
  });

  return variableMapping;
};

const internalFormSchmaOfferTypeVars =
  returnFormSchemaOfferTypeVariables(internalFormSchema);

const formSchemaOfferTypeVariables =
  returnFormSchemaOfferTypeVariables(formSchema);

formSchemaOfferTypeVariables.splice(1, 0, {
  offerType: "Number at this Price",
  variables: numberAtThisPriceVariableNames.map(variableName => ({
    name: variableName,
    requiredByStates: [],
  })),
});

export const nuCustomVariableNames: string[] = [
  "NU_Disclosure1",
  "NU_Disclosure2",
  "NU_purchaseDescription",
  "NU_paymentDayLimit",
  "NU_APRtermLimit",
];

export const vehicleInfoVariableNames: string[] = (
  (formSchemaOfferTypeVariables.find(
    obj => obj.offerType === "Vehicle Info",
  ) || { variables: [] }) as IOfferTypeVariable
).variables.map(variable => variable.name);

export const offerTypeVariables: IOfferTypeVariable[] = [
  ...formSchemaOfferTypeVariables,
  {
    offerType: "Extra Cost",
    variables: extraCostVariableNames.map(variableName => ({
      name: variableName,
      requiredByStates: stateOptions,
    })),
  },
  {
    offerType: "Expiration Date",
    variables: expirationDateVariableNames.map(variableName => ({
      name: variableName,
      requiredByStates: stateOptions,
    })),
  },
];

export const allVariableNames = offerTypeVariables.reduce(
  (acc: string[], offerTypeVariable: IOfferTypeVariable) => {
    const names = offerTypeVariable.variables.map(v => v.name);
    const combined = [...new Set(acc.concat(names))];
    return combined;
  },
  [...storeVariableNames],
);

export const internalOfferTypeVariables: IOfferTypeVariable[] = [
  ...internalFormSchmaOfferTypeVars,
  {
    offerType: "Extra Cost",
    variables: extraCostVariableNames.map(variableName => ({
      name: variableName,
      requiredByStates: stateOptions,
    })),
  },
  {
    offerType: "Expiration Date",
    variables: expirationDateVariableNames.map(variableName => ({
      name: variableName,
      requiredByStates: stateOptions,
    })),
  },
];
