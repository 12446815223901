/* eslint-disable react/display-name */
import { useMemo, useEffect, FC } from "react";
import {
  ProcessedPaymentEngineData,
  OfferEditFieldObject,
} from "shared/types/assetBuilder";
import "../../review/ReviewPage.scss";

import { SyncOutlined } from "@ant-design/icons";

import { Table, Input, Spin } from "antd";

import {
  paymentEngineFields,
  returnOfferCalculationFieldObjects,
  staticPaymentEngineFields,
  decodeZeroDownLease,
} from "utils/helpers.paymentEngine";
import { ColumnProps } from "antd/lib/table";
import { dynamicPaymentEngineFields } from "shared/constants/paymentEngine";
import { dateFormat } from "shared/constants/dataManagement";
import moment from "moment";
import {
  FeedOfferKeys,
  PaymentEngineOfferDataField,
  ParseableOfferData,
} from "shared/types/paymentEngine";
import { timezoneOffset } from "utils/helpers";
import { TableRowSelection } from "antd/lib/table/interface";

interface PaymentEngineOfferCalcTable {
  previewRequested: boolean;
  processedPaymentEngineData: ProcessedPaymentEngineData | null;
  offerData: ParseableOfferData;
  initialSelectedRow?: OfferEditFieldObject | null; // this keeps track of which field opened the modal
  feedOfferFieldValueEdits: ParseableOfferData | null;
  setFieldValueInFeedOfferEdits: (
    field: keyof ParseableOfferData,
    value: string,
  ) => void;
  checkboxSelectedRows: OfferCalcRow[];
  setCheckboxSelectedRow: (row: OfferCalcRow[]) => void;
  multiSelectableFieldNames: Array<PaymentEngineOfferDataField>;
}

type OfferCalcRow = {
  key: string; // key in table
  field: PaymentEngineOfferDataField;
  title: string; // Name Col
  value: string | number; // Original Value col
  dsValue: string | number; // Updated Value co
};

export const PaymentEngineOfferCalcTable: FC<PaymentEngineOfferCalcTable> = ({
  processedPaymentEngineData,
  offerData,
  previewRequested,
  initialSelectedRow,
  feedOfferFieldValueEdits,
  setFieldValueInFeedOfferEdits,
  checkboxSelectedRows,
  setCheckboxSelectedRow,
  multiSelectableFieldNames,
}) => {
  // fields with changeable values

  const offerType = initialSelectedRow?.field.includes("zeroDownLease")
    ? "Zero Down Lease"
    : initialSelectedRow?.field.includes("finance")
    ? "Finance"
    : "Lease";
  const dynamicFieldObjs = returnOfferCalculationFieldObjects(
    offerType,
    dynamicPaymentEngineFields,
  );
  // fields with static values
  const vehicleInfoFieldObjs = returnOfferCalculationFieldObjects(
    "Vehicle Info",
    paymentEngineFields,
  );
  const staticFieldObjs = returnOfferCalculationFieldObjects(
    offerType,
    staticPaymentEngineFields,
  );

  const paymentEngineFieldObjs = dynamicFieldObjs
    .concat(vehicleInfoFieldObjs)
    .concat(staticFieldObjs);

  const dataRows = useMemo(() => {
    // TO DO: only enable modal in edit mode

    if (!offerData?.vin) {
      return [];
    }

    return (
      paymentEngineFieldObjs?.map((fieldObj, index) => {
        let { field } = fieldObj;

        /*
               Possible TO DO: if the edited value is empty, revert it back to
               the original offer field value (when the modal was just opened)
             */

        const valueToUse =
          feedOfferFieldValueEdits?.[
            field as keyof ParseableOfferData
          ]?.toString();

        field = field.includes("zeroDownLease")
          ? (decodeZeroDownLease(field) as PaymentEngineOfferDataField)
          : field;

        return {
          key: index.toString(),
          ...fieldObj,
          value: valueToUse || "",
          dsValue: processedPaymentEngineData?.[field as FeedOfferKeys] || "",
        };
      }) || []
    );

    // tslint:disable-next-line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerData, processedPaymentEngineData, feedOfferFieldValueEdits]);

  const columns: Array<ColumnProps<OfferCalcRow>> = [
    // the select column is its own thing,
    {
      title: "Name",
      dataIndex: "title",
      align: "center",
      width: 75,
    },
    {
      title: "Feed Value",
      dataIndex: "value",
      align: "center",
      width: 100,
      render: (text: string, record: OfferCalcRow) => {
        if (!checkboxSelectedRows.find(sRow => sRow.field === record.field)) {
          return (
            <span>
              {/* This condition checks if text is epoch time string */}
              {record.field.toLowerCase().includes("date") && text.length > 10
                ? moment(new Date(parseInt(text) + timezoneOffset)).format(
                    dateFormat,
                  )
                : text}
            </span>
          );
        }
        return (
          <Input
            type="text"
            value={record.value}
            onChange={e =>
              setFieldValueInFeedOfferEdits(record.field, e.target.value)
            }
          />
        );
      },
    },
    {
      title: "Updated Value",
      dataIndex: "dsValue",
      align: "center",
      width: 100,
      render: (text: string, record: OfferCalcRow) => {
        const roundFloat = text.includes(".")
          ? parseFloat(text).toFixed(2).toString()
          : text;
        // remove comma and parse string value to float
        const parsed = parseFloat(
          offerData[record.field]?.toString().replace(",", ""),
        );
        // format Date to compare changes if field is 'expirationDate', else compare int/float value
        const valueChanged =
          record.field === "expirationDate"
            ? text !== record.dsValue
            : roundFloat.includes(".")
            ? roundFloat !== parsed.toFixed(2).toString()
            : roundFloat !== parsed.toString();
        const value =
          record.field === "expirationDate"
            ? roundFloat
            : roundFloat.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return (
          <>
            {previewRequested ? (
              <Spin
                indicator={<SyncOutlined spin style={{ fontSize: 16 }} />}
              />
            ) : (
              <span
                style={{
                  color: valueChanged ? "#0091ff" : "#000000A6",
                  fontWeight: valueChanged ? "bold" : "normal",
                }}
              >
                {" "}
                {value}
              </span>
            )}
          </>
        );
      },
    },
    // TO DO: Aditional Info Column
  ];

  type SelectionType = "checkbox";

  const rowSelection: TableRowSelection<OfferCalcRow> = {
    columnWidth: 10,

    type: "checkbox" as SelectionType,

    /*
       AV2-1784: The state of the radio button and checkboxes
       needes to be track, so that the initial selected row
       can function properly
     */
    selectedRowKeys:
      checkboxSelectedRows.length > 0
        ? checkboxSelectedRows.map(sRow => sRow.key)
        : [],

    onChange: (
      selectedRowKeys: (number | string)[],
      selectedRows: OfferCalcRow[],
    ) => {
      const isCheckboxRow = selectedRows.find(sRow =>
        multiSelectableFieldNames.includes(sRow.field),
      );

      if (isCheckboxRow) {
        setCheckboxSelectedRow(selectedRows);
        return;
      }
      setCheckboxSelectedRow([]);
    },

    getCheckboxProps: (record: OfferCalcRow) => ({
      name: record.title,
      className: !multiSelectableFieldNames.includes(record.field)
        ? "single-select-toggle"
        : "",
      disabled: false,
    }),
  };

  useEffect(() => {
    /*
	   AV2-1784: Set initial selected row when opening
	   the modal via field input button
	 */

    if (!initialSelectedRow || dataRows.length < 1) {
      return;
    }
    const { field } = initialSelectedRow;
    const matchingRowObj = dataRows.find(row => row.field === field);

    if (!matchingRowObj) {
      return;
    }

    if (multiSelectableFieldNames.includes(field)) {
      setCheckboxSelectedRow([matchingRowObj]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataRows}
        pagination={false}
      />
    </>
  );
};
