import { convertToRaw } from "draft-js";
import { memo, ReactElement, useState } from "react";
import { ButtonStyle } from "screens/adLibrary/facebookUtils/types";
import {
  IButtonElement,
  IFooterElement,
  InstantExperienceBodyElement,
} from "shared/types/adLibrary";
import uuid from "uuid";
import ButtonComponentForm, {
  IButtonComponentForm,
} from "./buttonComponent/ButtonComponentForm";
import { DestinationType } from "./shared/Destination";
import { getEditorState } from "./shared/utils";

interface IProps {
  disableFooter: boolean;
  instantExperienceElement: IButtonElement | IFooterElement;
  updateElement(instantExperienceElement: InstantExperienceBodyElement): void;
  forceValidations?: boolean;
}

const defaultButtonValues: Omit<IButtonElement, "id"> = {
  element_type: "BUTTON",
  background_color: "FFFFFF",
  button_color: "000000",
  text_color: "777777",
  button_style: ButtonStyle.BUTTON_OUTLINE,
  font_family: "sans-serif",
  font_size: 15,
  name: "Button",
};

export const getDefaultButtonValues = (): IButtonElement => ({
  ...defaultButtonValues,
  id: uuid(),
});

export const getDefaultFooterValues = (): IFooterElement => ({
  id: uuid(),
  element_type: "FOOTER",
  name: "Button",
  background_color: defaultButtonValues.background_color,
  child_elements: [getDefaultButtonValues()],
});

function ButtonComponent({
  disableFooter,
  instantExperienceElement,
  updateElement,
  forceValidations,
}: IProps): ReactElement {
  const isFooterElement = instantExperienceElement.element_type === "FOOTER";
  const buttonElement = isFooterElement
    ? instantExperienceElement.child_elements?.[0]
    : instantExperienceElement;

  const [destination, setDestination] = useState<DestinationType>(
    buttonElement.destination?.instantExperienceId
      ? "instantExperience"
      : "website",
  );
  const [selectedBrand, setSelectedBrand] = useState<string | undefined>();

  const initialValues: IButtonComponentForm = {
    format: {
      editorState: getEditorState(buttonElement?.rich_text),
      fontSize: buttonElement?.font_size,
      fontFamily: buttonElement?.font_family,
      textColor: buttonElement?.text_color,
    },
    buttonColor: buttonElement?.button_color,

    buttonStyle: buttonElement?.button_style,
    backgroundColor: buttonElement?.background_color,

    destinationValues:
      destination === "instantExperience"
        ? {
            instantExperienceId:
              buttonElement?.destination?.instantExperienceId,
          }
        : { urlLabel: buttonElement?.destination?.urlLabel },
    fixToBottom: isFooterElement,
  };

  function getInstantExperienceElement(
    values: IButtonComponentForm,
  ): IButtonElement | IFooterElement {
    const richTextRaw = convertToRaw(
      values.format.editorState.getCurrentContent(),
    );
    const block = richTextRaw.blocks[0];
    const buttonElementUpdate: IButtonElement = {
      rich_text: {
        plain_text: block.text,
        block_type: block.type as "UNSTYLED",
        inline_styles: block.inlineStyleRanges,
      },
      font_family: values.format.fontFamily,
      font_size: values.format.fontSize,
      text_color: values.format.textColor,
      background_color: values.backgroundColor,
      button_style: values.buttonStyle,
      button_color: values.buttonColor,
      element_type: "BUTTON",
      id: buttonElement.id,
      destination: {
        instantExperienceId: values.destinationValues?.instantExperienceId,
        urlLabel: values.destinationValues?.urlLabel,
      },
      name: buttonElement.name ?? "Button",
    };

    if (values.fixToBottom) {
      const footerElementUpdate: IFooterElement = {
        element_type: "FOOTER",
        background_color: values.backgroundColor,
        id: instantExperienceElement.id || uuid(),
        name: buttonElement.name,
        child_elements: [buttonElementUpdate],
      };

      return footerElementUpdate;
    }

    return buttonElementUpdate;
  }

  return (
    <ButtonComponentForm
      destination={destination}
      setDestination={setDestination}
      setSelectedBrand={setSelectedBrand}
      selectedBrand={selectedBrand}
      initialValues={initialValues}
      disableFooter={disableFooter && !isFooterElement}
      onValuesChange={values => {
        if (!values) {
          return;
        }
        updateElement(getInstantExperienceElement(values));
      }}
      forceValidations={forceValidations}
      elementId={buttonElement.id}
    />
  );
}

export default memo(ButtonComponent);
