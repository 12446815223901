import AdAccount from "screens/adLibrary/facebookUtils/adAccount";

import { UseMutateAsyncFunction, useMutation } from "react-query";

import { returnCreateAdParams } from "screens/adLibrary/adLoad/adLoadDrawer/loadStep/helpers.ad";
import {
  returnAdToLoadStatus,
  returnDataNotFoundObj,
} from "screens/adLibrary/adLoad/adLoadDrawer/loadStep/helpers.load";

import { AdLoadAdStatusEnum, IAdToLoadData } from "shared/types/adLibrary";
import { IAccount } from "shared/types/accountManagement";
import {
  CreateAdParameters,
  ICreateOrUpdateResponse,
  IFacebookAd,
  IUpdateFacebookAdParams,
} from "screens/adLibrary/facebookUtils/types";

export type HandleFacebookAdArgs = {
  facebookAdAccount: AdAccount;
  params: CreateAdParameters;
};

type UseCreateFacebookAdArgs = HandleFacebookAdArgs;

export const useCreateFacebookAd = () =>
  useMutation((args: UseCreateFacebookAdArgs) =>
    args.facebookAdAccount.createAd(args.params),
  );

type UseUpdateFacebookAdArgs = {
  facebookAdAccount: AdAccount;
  facebookAd: IFacebookAd;
  params: IUpdateFacebookAdParams;
};

export const useUpdateFacebookAd = () =>
  useMutation((args: UseUpdateFacebookAdArgs) =>
    args.facebookAdAccount.updateAd({
      facebookAd: args.facebookAd,
      params: args.params,
    }),
  );

type CancellableHandleFacebookAd = UseMutateAsyncFunction<
  ICreateOrUpdateResponse,
  unknown,
  UseCreateFacebookAdArgs,
  unknown
>;

export type LoadToFacebookArgs = {
  adToLoad: IAdToLoadData;
  selectedDealer?: IAccount;
  facebookAdAccount?: AdAccount;
  handleFacebookAd?: CancellableHandleFacebookAd;
};

const loadAdToFacebook = async (args: LoadToFacebookArgs) => {
  if (args.adToLoad.adLoadStatus.status === "success") return args.adToLoad;

  if (!args.facebookAdAccount || !args.selectedDealer) {
    return returnDataNotFoundObj(args.adToLoad, {
      message: "Missing Page ID",
      error_user_title: "Missing Page ID",
      error_user_msg: `No Facebook page ID was found for the store: ${args.selectedDealer?.dealer_name}`,
    });
  }

  /**
   * @ Constellation, the team requires pixels
   * because of the types of campaigns that are managed,
   */
  if (!args.selectedDealer.details.facebook.fbPixelId) {
    const adLoadStatus = returnAdToLoadStatus({
      response: {
        error: {
          error_user_title: "Pixel is missing",
          message: "Invalid parameter",
          error_user_msg: `Check if the Facebook Pixel is missing from ${args.selectedDealer.dealer_name} in Store Management.`,
        },
        result: null,
      },
      adToLoad: args.adToLoad,
      newAdLoadStatus: AdLoadAdStatusEnum.ERROR,
    });

    return {
      ...args.adToLoad,
      adLoadStatus: adLoadStatus,
      progressStatus: {
        ...args.adToLoad.progressStatus,
        loadToFacebook: adLoadStatus.status,
      },
    };
  }

  if (
    !args.adToLoad.facebookAd?.creative?.id &&
    args.adToLoad.adLoadStatus.facebookError
  ) {
    const adLoadStatus = returnAdToLoadStatus({
      response: {
        error: {
          ...args.adToLoad.adLoadStatus.facebookError,
        },
        result: null,
      },
      adToLoad: args.adToLoad,
      newAdLoadStatus: "error",
    });

    return {
      ...args.adToLoad,
      adLoadStatus: adLoadStatus,
      progressStatus: {
        ...args.adToLoad.progressStatus,
        loadToFacebook: adLoadStatus.status,
      },
    };
  }

  const params = returnCreateAdParams({
    adToLoad: args.adToLoad,
    adCreative: args.adToLoad.facebookAd?.creative ?? {},
    pixelId: args.selectedDealer.details.facebook.fbPixelId,
  })!;

  const facebookResponse = await (args.handleFacebookAd?.({
    facebookAdAccount: args.facebookAdAccount,
    params: params,
  }) || args.facebookAdAccount.createAd(params));

  const adLoadStatus = returnAdToLoadStatus({
    response: facebookResponse,
    adToLoad: args.adToLoad,
    newAdLoadStatus: "success",
  });

  const newAdToLoadData: IAdToLoadData = {
    ...args.adToLoad,
    facebookAd: {
      ...args.adToLoad.facebookAd,
      id: args.adToLoad.facebookAdId ?? facebookResponse.result?.id,
    },
    adLoadStatus: adLoadStatus,
    progressStatus: {
      ...args.adToLoad.progressStatus,
      loadToFacebook: adLoadStatus.status,
    },
  };

  return newAdToLoadData;
};

export const useMutateFacebookAd = () => useMutation(loadAdToFacebook);
