import { envAuth } from "./configs";

const jmpDev = {
  name: "JMPDev",
  cid: "jmp-dev",
  xcid: "av3-dev",
  region: "us-east-1",
  auth: envAuth,
  identityPoolId: "us-east-1:a498acbf-79a0-484b-8c7f-6c771ec42d70",
  gqlId: "ohskppdudzdublf7fpzoi4wldi",
};

const eChung = {
  name: "EChung",
  cid: "echung-dev",
  xcid: "av3-dev",
  region: "us-east-1",
  auth: envAuth,
  identityPoolId: "us-east-1:010f163e-d8ee-46fc-8ab7-1fd7449add05",
  gqlId: "fgguf2p36zgkdmjr77ajcqsy4u",
};

const yoelvis = {
  name: "Yoelvis",
  cid: "yoelvis-dev",
  xcid: "av3-dev",
  region: "us-east-1",
  auth: envAuth,
  identityPoolId: "us-east-1:e028a955-01cb-4af6-8204-a2a822b148b3",
  gqlId: "gbawehjvknaylmohkdsee4baqa",
};

const nauman = {
  name: "Nauman",
  cid: "nauman-dev",
  xcid: "av3-dev",
  region: "us-east-1",
  auth: envAuth,
  identityPoolId: "us-east-1:17530d1e-94e4-4c74-bd41-b32a4067932f",
  gqlId: "uxwiqjcoxvgy5dqoqkvclrubxq",
};

const brycen = {
  name: "Brycen",
  cid: "brycen-dev",
  xcid: "av3-dev",
  region: "us-east-1",
  auth: envAuth,
  identityPoolId: "us-east-1:22a6e59c-97a7-4636-a0db-f8f91f9b26d3",
  gqlId: "z74m5m4tyvamxo5p27kqfgstnq",
};

export const devsArr = [jmpDev, eChung, yoelvis, nauman, brycen];
