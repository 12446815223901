import { ColumnsType } from "antd/lib/table";
import { useCallback } from "react";
import { AdType } from "screens/adLibrary/facebookUtils/types";
import EditableAdToLoadUrlText from "../../../shared/components/adsToLoadTable/EditableAdToLoadUrlText";
import { adLoadStatusAfterUrlChange } from "../../../shared/components/adsToLoadTable/utils";
import { useSelectedStores } from "../../../shared/hooks/reviewStep/useGetRequiredData";
import { canUseUrlField, getStoreMatch } from "../../../shared/utils";
import { AllEntryTypes } from "../AssignValuesTable.types";
import { useCurrentAdsToLoad } from "../currentAdsToLoadContext";
import AdNameCell from "./AdNameCell";
import DestinationUrlCell from "./DestinationUrlCell";

export const useColumns = (): ColumnsType<AllEntryTypes> => {
  const {
    currentAdsToLoad: adsToLoad,
    setCurrentAdsToLoad,
    handleDataRowChange,
  } = useCurrentAdsToLoad();
  const { selectedStores } = useSelectedStores();

  const renderName = useCallback((_, record: AllEntryTypes) => {
    return <AdNameCell record={record} />;
  }, []);

  const renderDestinationUrl = useCallback(
    (_, record: AllEntryTypes, index: number) => {
      if (!("destinationUrl" in record)) return null;
      const adToLoad = adsToLoad.find(ad => ad.key === record.adShellKey);
      if (!adToLoad) return null;

      return (
        <DestinationUrlCell
          record={record}
          index={index}
          adToLoad={adToLoad}
          onRowDataChange={handleDataRowChange}
          setCurrentAdsToLoad={setCurrentAdsToLoad}
        />
      );
    },
    [adsToLoad, handleDataRowChange, setCurrentAdsToLoad],
  );

  const renderDisplayUrl = useCallback(
    (_, record: AllEntryTypes) => {
      if (
        record.type !== "AdShell" ||
        (record.format !== "Still" && record.format !== "Video")
      )
        return null;

      const adToLoad = adsToLoad.find(ad => ad.key === record.key);
      if (!adToLoad) return null;

      const selectedStore = getStoreMatch(selectedStores, adToLoad.account);
      return (
        <EditableAdToLoadUrlText
          showInputOnly
          willCheckFullUrl={false}
          linkValue={record.displayUrl}
          selectedStore={selectedStore}
          disabled={
            !canUseUrlField(adToLoad.ad.type) ||
            adToLoad.ad.type === AdType.Carousel
          }
          onChange={value => {
            handleDataRowChange?.({
              data: {
                ...adToLoad,
                adLoadStatus: adLoadStatusAfterUrlChange(adToLoad.adLoadStatus),
                ad: {
                  ...adToLoad.ad,
                  visuals: { ...adToLoad.ad.visuals, displayUrl: value },
                },
              },
              column: "Display URL",
            });
          }}
        />
      );
    },
    [adsToLoad, handleDataRowChange, selectedStores],
  );

  return [
    {
      title: "Ad Name",
      dataIndex: "name",
      key: "name",
      width: "max-content",
      render: renderName,
      onCell: () => ({
        style: {
          minWidth: 350,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          verticalAlign: "middle",
        },
      }),
    },
    {
      title: "Format",
      dataIndex: "format",
      key: "format",
      width: 150,
      className: "text-dimmed",
    },
    {
      title: "Destination URL",
      dataIndex: "destinationUrl",
      key: "destinationUrl",
      width: 250,
      render: renderDestinationUrl,
    },
    {
      title: "Display URL",
      dataIndex: "displayUrl",
      key: "displayUrl",
      width: 250,
      render: renderDisplayUrl,
    },
  ];
};
