import { IConfig } from "shared/types/configuration";
import { IDataTableError } from "shared/types/errors";
import HttpClient from "./httpClient";

type ICreatedTag = {
  name: string;
  createdAt: string;
  updatedAt: string;
  enabled: boolean;
};

type ICreateTagResult = {
  createdTag: ICreatedTag;
};

interface ITagResponse {
  error: IDataTableError | null;
  statusCode: number;
}

export interface ITagCreateResponse extends ITagResponse {
  result: ICreateTagResult | null;
}

export interface ITagFetchResponse extends ITagResponse {
  result: string[] | null;
}

export default ({
  headers,
  config,
}: {
  headers?: Headers;
  config: IConfig;
}) => ({
  createTag: async <ITagCreateResponse>(
    inputTag: string,
  ): Promise<ITagCreateResponse> => {
    const { services } = config;

    const body = {
      tag: {
        name: inputTag,
      },
    };

    return HttpClient.post<ITagCreateResponse>(services.createTagUrl, body, {
      headers,
    });
  },

  getTags: <ITagFetchResponse>(): Promise<ITagFetchResponse> => {
    const { services } = config;

    const getTagsUrl = services.getTagsUrl;

    return HttpClient.get(getTagsUrl);
  },
});
