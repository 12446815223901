import { CSSProperties } from "react";
import { IButtonElement, IFooterElement } from "shared/types/adLibrary";
import { isFooter } from "utils/adLibrary.validators";
import styles from "./FlowButton.module.scss";

type Props = {
  ele: IButtonElement | IFooterElement;
};

const FlowButton = (props: Props) => {
  const { ele } = props;
  const buttonElement = isFooter(ele) ? ele.child_elements[0] : ele;

  const wrapperStyle: CSSProperties = {
    backgroundColor: `#${props.ele.background_color}`,
  };
  const buttonStyle: CSSProperties = {
    fontSize: buttonElement.font_size,
    fontFamily: buttonElement.font_family,
    color: `#${buttonElement.text_color}`,
    border: `2px solid #${buttonElement.button_color}`,
  };

  return (
    <div className={styles.flowButton} style={wrapperStyle}>
      <div className={styles.buttonPreview} style={buttonStyle}>
        {buttonElement.rich_text?.plain_text}
      </div>
    </div>
  );
};

export default FlowButton;
