import { DateFormater } from "../../DateFormater";
import { useColumn } from "../useColumn";

export const useColDateModified = () => {
  return useColumn("datemodified", {
    title: "Last Modified",
    renderFn(text: string, record: WDAsset | WDFolder) {
      if (record.type === "asset") {
        return <DateFormater date={record.datemodified} />;
      }
    },
  });
};
