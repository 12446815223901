import { Collapse, Typography } from "antd";
import { useGetEverythingAdHtmlItemsByEaId } from "shared/hooks/everythingAdHtml/useEverythingAdsHtml";
import { GeneratedUrlsTable } from "./generatedUrls/GeneratedUrlsTable";

type Props = {
  everythingAdId: string;
};

const GeneratedUrls = ({ everythingAdId }: Props) => {
  const { data: everythingAdsHtml, isFetching: isLoadingEverythingAdsHtml } =
    useGetEverythingAdHtmlItemsByEaId({
      eaId: everythingAdId,
    });

  if (!everythingAdsHtml?.length) return null;

  const sortedEverythingAdsHtml = [...everythingAdsHtml].sort((a, b) =>
    b.createdAt.localeCompare(a.createdAt),
  );

  return (
    <Collapse defaultActiveKey={[]} ghost>
      <Collapse.Panel
        header={
          <Typography.Text style={{ fontWeight: "bold" }}>
            Generated URLs {`(${sortedEverythingAdsHtml.length})`}
          </Typography.Text>
        }
        key="1"
      >
        <GeneratedUrlsTable
          everythingAdsHtml={sortedEverythingAdsHtml}
          isLoadingEverythingAdsHtml={isLoadingEverythingAdsHtml}
        />
      </Collapse.Panel>
    </Collapse>
  );
};
export default GeneratedUrls;
