import { CSSProperties } from "react";

import { LogoDropZoneType, LogoEventType } from "shared/types/designStudio";
import "./Rectangle.scss";

interface IRectangle {
  style: {
    container: CSSProperties;
    border: {
      [key: string]: CSSProperties;
    };
    center: CSSProperties;
  };
  dropZoneType: LogoDropZoneType;
  eventType: LogoEventType;
}

const Square: React.FC<IRectangle> = ({ style, dropZoneType, eventType }) => {
  return (
    <div
      className="drop-zone-shape-background"
      style={style.container}
      draggable={true}
      onDragStart={event => {
        event.dataTransfer.setData("logoDropZoneType", dropZoneType);
        event.dataTransfer.setData("logoEventType", eventType);
      }}
    >
      <div className="top-left" style={style.border.tl} />
      <div className="top-right" style={style.border.tr} />
      <div className="bottom-right" style={style.border.br} />
      <div className="bottom-left" style={style.border.bl} />

      <div className="center" style={style.center} />
    </div>
  );
};

export default Square;
