import API from "services";
import { useMutation, useQueryClient } from "react-query";
import { useUser } from "shared/hooks/useUser";
import { IUpdatedAssetGroup, IAssetGroup } from "shared/types/adLibrary";

export const useUpdateAssetGroup = () => {
  const queryClient = useQueryClient();
  const user = useUser();
  return useMutation(
    (assetGroup: Partial<IUpdatedAssetGroup> | Partial<IAssetGroup>) => {
      if (!user) return Promise.reject("User not found");
      return API.services.adLibrary.updateAssetGroup({
        assetGroup,
        user,
      });
    },
    {
      onSettled() {
        queryClient.invalidateQueries("asset-groups");
      },
    },
  );
};
