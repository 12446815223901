import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { IInstantExperience } from "shared/types/adLibrary";
import moment from "moment";
import styles from "./UpdatedAtWithRowButtons.module.scss";
import classNames from "classnames";

interface Props {
  onClickEdit: (id: string) => void;
  onClickLoadEA: (ea?: IInstantExperience) => void;
  onClickDuplicate: (ea?: IInstantExperience) => void;
  setActionRow: React.Dispatch<
    React.SetStateAction<IInstantExperience | undefined>
  >;
  record: IInstantExperience;
  isSelected: boolean;
}

const UpdatedAtWithRowButtons = ({
  onClickEdit,
  onClickLoadEA,
  onClickDuplicate,
  setActionRow,
  record,
  isSelected,
}: Props) => {
  return (
    <div>
      <div className={styles.updateatContainer}>
        {moment(record.updatedAt).format("YYYY/MM/DD hh:mmA")}
      </div>
      <div
        className={classNames(
          { [styles.selectedButtonContainer]: isSelected },
          styles.buttonContainer,
          "buttonContainer",
        )}
      >
        <Tooltip title={"Edit"}>
          <Button
            data-cy="edit-toolbar-button"
            className="action-btn-toolbar"
            icon={<EditOutlined />}
            shape="circle"
            onClick={() => onClickEdit(record.id ?? "")}
          />
        </Tooltip>
        <Tooltip title={"Load"}>
          <Button
            data-cy="load-ea-toolbar-button"
            className="action-btn-toolbar"
            icon={<UploadOutlined />}
            shape="circle"
            onClick={() => onClickLoadEA(record)}
          />
        </Tooltip>
        <Tooltip title={"Duplicate"}>
          <Button
            data-cy="duplicate-toolbar-button"
            className="action-btn-toolbar"
            icon={<CopyOutlined />}
            shape="circle"
            onClick={() => onClickDuplicate(record)}
          />
        </Tooltip>
        <Tooltip title={"Delete"}>
          <Button
            data-cy="delete-toolbar-button"
            className="action-btn-toolbar"
            icon={<DeleteOutlined />}
            shape="circle"
            danger
            onClick={() => setActionRow(record)}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default UpdatedAtWithRowButtons;
