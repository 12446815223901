import { fabric } from "fabric";

export const addGrid = (
  gridGroup: fabric.Group | null,
  width: number,
  height: number,
) => {
  if (gridGroup) return;

  gridGroup = createGridGroup(width, height);
  gridGroup.addWithUpdate();

  if (!gridGroup) return null;
  else return gridGroup;
};

export const createGridGroup = (width: number, height: number) => {
  const size = 50;
  const lines = [];
  const options = {
    stroke: "#CCC",
    selectable: false,
    type: "line",
  };

  for (let i = 0; i < width / size; i += 1) {
    lines.push(new fabric.Line([i * size, 0, i * size, height], options));
  }

  for (let i = 0; i < height / size; i += 1) {
    lines.push(new fabric.Line([0, i * size, width, i * size], options));
  }

  const group = new fabric.Group(lines, {
    selectable: false,
    evented: false,
  });

  return group;
};
