import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { IDataListURLFields } from "./types";

type IDataListURLContext<FieldKey extends string = string, RecordType = any> =
  | {
      fields: IDataListURLFields<FieldKey, RecordType>;
      selectedIds: string[];
      showSelected: boolean;
      setSelectedIds: Dispatch<SetStateAction<string[]>>;
      setShowSelected: Dispatch<SetStateAction<boolean>>;
    }
  | undefined;

const DataListURLContext = createContext<IDataListURLContext>(undefined);

type Props<FieldKey extends string, RecordType> = {
  children: ReactNode;
  fields: IDataListURLFields<FieldKey, RecordType>;
  defaultState?: { selectedIds?: string[]; showSelected?: boolean };
};

const DataListURLProvider = <FieldKey extends string, RecordType>({
  fields,
  children,
  defaultState,
}: Props<FieldKey, RecordType>) => {
  const [selectedIds, setSelectedIds] = useState<string[]>(
    defaultState?.selectedIds || [],
  );
  const [showSelected, setShowSelected] = useState(
    defaultState?.showSelected || false,
  );

  return (
    <DataListURLContext.Provider
      value={{
        fields,
        selectedIds,
        setSelectedIds,
        showSelected,
        setShowSelected,
      }}
    >
      {children}
    </DataListURLContext.Provider>
  );
};

const useDataListURLContext = <FieldKey extends string, RecordType>() => {
  const context =
    useContext<IDataListURLContext<FieldKey, RecordType>>(DataListURLContext);
  if (context === undefined) {
    throw new Error(
      "useDataListURLContext must be used within a DataListURLProvider",
    );
  }
  return context;
};

export { DataListURLProvider, useDataListURLContext };
