import { connect } from "react-redux";

import {
  HeaderMenu,
  TabMenu,
  ITemplate,
  FetchingDataStatus,
  IDesignStudioState,
  CanvasProcessing,
  isTemplate,
  IPublishCanvasStatus,
} from "shared/types/designStudio";

import { IContentTab } from "shared/types/tabContainer";
import TabContainer from "../../shared/components/TabContainer";
import PropertySection from "./editor/PropertySectionV2";

import actions from "../../redux/rootActions";
import CanvasContainer from "./editor/CanvasContainer";

import EditorProvider, { TResource, TTargetParams } from "./Editor.context";
import GenericError from "shared/errors/GenericError";
import { IConfigurationState } from "shared/types/configuration";
import { User } from "redux/auth/auth.slice";
import { AppState } from "redux/store";
import { useParams } from "react-router-dom";

interface IEditorProps {
  selectedHeader: HeaderMenu;
  selectedTab: TabMenu;
  savingDraft: CanvasProcessing;
  feed: IConfigurationState["feed"];
  config: IConfigurationState["config"];
  user: User;
  publishCanvasStatus: IPublishCanvasStatus | null;
}

interface IEditorHandlers {
  fetchData: (dataType: FetchingDataStatus) => void;
  saveDraftSuccess: (template: ITemplate) => void;
  saveDraftFail: (error: GenericError) => void;
  publishCanvasStatusReset: () => void;
  updateShouldBlockFromNavigateAway: (
    block: boolean,
    resource?: TResource,
  ) => void;
}

const Editor: React.FC<IEditorProps & IEditorHandlers> = ({
  selectedHeader,
  selectedTab,
  ...props
}) => {
  let contentTabs: IContentTab[] = [];
  const { targetTab, targetId } = useParams<TTargetParams>();
  switch (selectedTab) {
    case "templates":
      contentTabs = [
        {
          title: "Templates",
          component: (
            <CanvasContainer
              savingDraft={props.savingDraft}
              updateSavingDraft={(status, resource, error) => {
                switch (status) {
                  case "success":
                    if (!isTemplate(resource)) return;

                    props.saveDraftSuccess(resource);

                    break;

                  case "fail":
                    if (!error) break;

                    props.saveDraftFail(error);
                    break;

                  default:
                    break;
                }
              }}
              feed={props.feed}
              config={props.config}
              user={props.user}
              publishCanvasStatusReset={props.publishCanvasStatusReset}
              publishCanvasStatus={props.publishCanvasStatus}
              updateShouldBlockFromNavigateAway={(block, resource) => {
                props.updateShouldBlockFromNavigateAway(block, resource);
              }}
            />
          ),
        },
      ];
      break;
    case "stamps":
      contentTabs = [
        {
          title: "Stamps",
          component: (
            <CanvasContainer
              savingDraft={props.savingDraft}
              updateSavingDraft={() => {
                // fill
              }}
              feed={props.feed}
              config={props.config}
              user={props.user}
              publishCanvasStatusReset={props.publishCanvasStatusReset}
              publishCanvasStatus={props.publishCanvasStatus}
              updateShouldBlockFromNavigateAway={block => {
                props.updateShouldBlockFromNavigateAway(block);
              }}
            />
          ),
        },
      ];
      break;

    default:
      break;
  }

  return (
    <div className="design-studio-editor-container">
      <EditorProvider targetTab={targetTab} targetId={targetId}>
        <TabContainer
          displaySearchView={{
            displayNewOffer: false,
            displaySearchInput: false,
            displayPlusButton: false,
          }}
          filterTab={
            <PropertySection
              selectedHeader={selectedHeader}
              selectedTab={selectedTab}
            />
          }
          selectedTab={selectedTab as string}
          contentTabs={contentTabs}
        />
      </EditorProvider>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  const { designStudio, configuration, auth } = state;
  const { savingDraft, publishCanvasStatus } =
    designStudio as IDesignStudioState;

  const { user } = auth;
  const { feed, config } = configuration as IConfigurationState;
  return {
    savingDraft,
    feed,
    config,
    user,
    publishCanvasStatus,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchData: (dataType: FetchingDataStatus) => {
      dispatch(actions.designStudio.fetchData(dataType));
    },
    saveDraftSuccess: (template: ITemplate) => {
      dispatch(actions.designStudio.saveDraftSuccess(template));
    },
    saveDraftFail: (error: GenericError) => {
      dispatch(actions.designStudio.saveDraftFail(error));
    },
    publishCanvasStatusReset: () => {
      dispatch(actions.designStudio.publishCanvasStatusReset());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
