import { AssetMetadata } from "../AssetMetadata";
import { MediaHeader } from "../MediaHeader";
import { MediaProvider } from "../MediaProvider";
import { MediaSignIn } from "../MediaSignIn";
import { MediaTable } from "../MediaTable";
import { useMediaStorage } from "../useMediaStorage";

import styles from "./CAMHost.module.scss";

export const CAMHost = () => {
  const [getData] = useMediaStorage();

  return getData() ? (
    <MediaProvider>
      <div className={styles.host}>
        <MediaHeader />
        <MediaTable />
        <AssetMetadata />
      </div>
    </MediaProvider>
  ) : (
    <MediaSignIn />
  );
};
