import { memo, ReactElement } from "react";
import { Popover, Tag } from "antd";
import { getNumVisible } from "./tagsGroup/tagsGroupUtils";

import style from "./TagsGroup.module.scss";

type PropsType = {
  tags: string[];
  width?: number;
  collapsable?: boolean;
};

const MARGIN_SMALL = "2px";
const MARGIN_NORMAL = "4px";

// Tags with a limited number of tags are displayed in a popover
const TagsGroup = ({ tags, width, collapsable }: PropsType): ReactElement => {
  const numTags = tags?.length ?? 0;
  const numVisible = getNumVisible(tags, width);
  const tagsNotVisible = numTags - numVisible;

  if (collapsable && tagsNotVisible > 0) {
    return (
      <>
        {tags?.slice(0, numVisible).map((item: string, index) => {
          return <Tag key={index}>{item}</Tag>;
        })}
        <Tag>
          <Popover
            overlayClassName={style.tagsGroupPopover}
            placement="bottomLeft"
            content={
              <div style={{ overflow: "auto", maxHeight: "90vh" }}>
                {tags?.slice(numVisible, numTags)?.map((item: string) => (
                  <>
                    <Tag
                      key={item}
                      style={{
                        margin: `${MARGIN_NORMAL} ${MARGIN_SMALL}`,
                      }}
                    >
                      {item}
                    </Tag>
                    <br />
                  </>
                ))}
              </div>
            }
          >{`+${tagsNotVisible}`}</Popover>
        </Tag>
      </>
    );
  }

  return (
    <>
      {tags?.map((value: string, index) => (
        <Tag key={index}>{value}</Tag>
      ))}
    </>
  );
};

export default memo(TagsGroup);
