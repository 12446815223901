import { useQuery } from "react-query";
import API from "services";
import { IInstantExperience } from "shared/types/adLibrary";

export const getInstantExperiences = async () => {
  const { result, error } =
    await API.services.adLibrary.getInstantExperiences();

  if (error || !result) {
    throw Error(error?.message || "Something went wrong");
  }

  return result?.instantExperiences;
};

export const useFetchInstantExperiences = () => {
  return useQuery<IInstantExperience[] | undefined, Error>(
    "instantExperiences",
    getInstantExperiences,
    {
      staleTime: Infinity,
    },
  );
};
