import { CloudDownloadOutlined } from "@ant-design/icons";
import { Button, Modal, notification } from "antd";
import { useUnpublishAssetsMutation } from "redux/media/media.api";
import { TooltipPerms } from "./TooltipPerms";
import { handleApiError } from "./handleApiError";
import { usePermsAssets } from "./usePermsAssets";
import { useQPSearch } from "./useQPSearch";
import { useSelected } from "./useSelected";

export const AssetsUnpublish = () => {
  const allowed = usePermsAssets("edit", true);
  const { selectedIds, allAssets, clearSelected } = useSelected();
  const { searchState } = useQPSearch();
  const [unpublishAssets] = useUnpublishAssetsMutation();
  const enabled = allowed && allAssets;

  const showConfirm = () => {
    return Modal.confirm({
      title: "Unpublish Assets",
      content: `Are you sure you want to unpublish ${selectedIds.length} assets?`,
      okText: "Unpublish",
      okButtonProps: {
        danger: true,
      },
      async onOk() {
        try {
          await unpublishAssets(selectedIds).unwrap();
          clearSelected();
          const key = Date.now().toString();
          const btn = (
            <Button
              type="primary"
              size="small"
              onClick={() => notification.close(key)}
            >
              Ok
            </Button>
          );

          notification.success({
            message: "Unpublishing Assets",
            description: `${selectedIds.length} assets are being unpublished. This action may take a few minutes to complete.`,
            key,
            btn,
            duration: 0,
          });
        } catch (error) {
          handleApiError(error);
        }
      },
    });
  };

  return !!searchState?.published_filter ? (
    <TooltipPerms
      action="Unpublish Assets"
      allowed={allowed}
      enabled={allAssets}
    >
      <Button
        danger
        shape="circle"
        icon={<CloudDownloadOutlined />}
        onClick={showConfirm}
        disabled={!enabled}
      />
    </TooltipPerms>
  ) : null;
};
