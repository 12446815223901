/* eslint-disable react/display-name */
import { useEffect, useMemo } from "react";
import { Table } from "antd";

import LegacyProductSetSelect from "shared/components/select/LegacyProductSetSelect";
import FacebookAssetSelect from "screens/adLibrary/shared/FacebookAssetSelect";

import useGetRequiredData from "../../../../shared/hooks/reviewStep/useGetRequiredData";

import { tableProps } from "../constants";

import { GroupProps } from "antd/lib/avatar";
import { OptionProps } from "antd/lib/select";
import { IFacebookObject } from "screens/adLibrary/facebookUtils/types";
import { IAdToLoadData, IInstantExperience } from "shared/types/adLibrary";
import { getStoreMatch } from "screens/adLibrary/adLoad/adLoadDrawer/shared/utils";

interface IIEProductSetsTableProps {
  record: IAdToLoadData;
  productCatalogIds?: string[] | undefined;
  nestedIEsWithProductSets: IInstantExperience[];
}

type OnProductSetSelectArgs = {
  productSetId: string;
  instantExperienceID: string;
  option?: OptionProps | GroupProps;
};
export type OnProductSetSelect = (args: OnProductSetSelectArgs) => void;

type OnProductCatalogSelectArgs = {
  selected: IFacebookObject | null;
  instantExperienceID: string;
};
export type OnProductCatalogSelect = (args: OnProductCatalogSelectArgs) => void;

interface IIEProductSetsTableHandlers {
  onProductSetSelect?: OnProductSetSelect;
  onProductCatalogSelect?: OnProductCatalogSelect;
}

const InstantExperienceProductSetsTable = ({
  record,
  productCatalogIds,
  nestedIEsWithProductSets,

  onProductSetSelect,
  onProductCatalogSelect,
}: IIEProductSetsTableProps & IIEProductSetsTableHandlers) => {
  const { productCatalogs, selectedStores } = useGetRequiredData();
  const preloadedCatalogs = useMemo(() => {
    const selectedStore = getStoreMatch(selectedStores, record.account);
    const storeName = selectedStore?.dealer_name || "";
    const catalogId = selectedStore?.details?.facebook?.fbCatalogId;
    if (!storeName) return productCatalogs;
    return productCatalogs.filter(
      catalog =>
        catalog.id === catalogId ||
        catalog.name.toLowerCase().includes(storeName.toLowerCase()),
    );
  }, [productCatalogs, selectedStores, record]);

  useEffect(() => {
    if (!preloadedCatalogs.length) return;
    for (const ie of nestedIEsWithProductSets) {
      if (!ie.id || !!record.nestedProductSets?.[ie.id]) continue;
      onProductCatalogSelect?.({
        selected: preloadedCatalogs[0],
        instantExperienceID: ie.id!,
      });
    }
  }, [
    preloadedCatalogs,
    record.nestedProductSets,
    nestedIEsWithProductSets,
    onProductCatalogSelect,
  ]);

  return (
    <Table<IInstantExperience>
      dataSource={nestedIEsWithProductSets}
      {...tableProps}
      columns={[
        ...tableProps.columns,
        { key: "name", dataIndex: "name", width: 250 },
        {
          key: "productCatalog",
          render: (value, ieRecord) => {
            return (
              <FacebookAssetSelect
                title={"catalog"}
                style={{ width: 260 }}
                preLoadedData={preloadedCatalogs}
                selectedAccount={record.account}
                initialValue={
                  record.nestedProductSets?.[`${ieRecord.id}`]?.productCatalog
                    ?.id || ""
                }
                value={
                  record.nestedProductSets?.[`${ieRecord.id}`]?.productCatalog
                    ?.id || ""
                }
                setFacebookIntegration={() => {
                  return;
                }}
                onSelect={selected => {
                  onProductCatalogSelect?.({
                    selected,
                    instantExperienceID: ieRecord.id!,
                  });
                }}
              />
            );
          },
        },
        {
          key: "productSet",
          render: (value, ieRecord) => {
            const productSetObj = record.nestedProductSets?.[`${ieRecord.id}`];
            return (
              <LegacyProductSetSelect
                style={{ width: 175 }}
                productCatalogIds={productCatalogIds}
                productSetId={productSetObj?.productSet?.id}
                disabled={!productSetObj?.productCatalog?.id}
                productCatalogId={productSetObj?.productCatalog?.id || ""}
                onSelect={(selectedProductSetId, option) => {
                  onProductSetSelect?.({
                    option,
                    instantExperienceID: ieRecord.id!,
                    productSetId: selectedProductSetId,
                  });
                }}
              />
            );
          },
        },
      ]}
    />
  );
};

export default InstantExperienceProductSetsTable;
