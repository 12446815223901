import { Drawer, Button } from "antd";
import ShareOutlined from "shared/icons/ShareOutlined";

const DestinationDrawer = ({
  destinationUrls,
  destinationDrawer,
}: {
  destinationUrls: (string | undefined)[];
  destinationDrawer: boolean;
}) => {
  return (
    <Drawer
      headerStyle={{ backgroundColor: "#fff !important" }}
      title={<b>Destination URLs</b>}
      width={400}
      placement="right"
      closable={true}
      visible={destinationDrawer}
      footer={
        <Button type="primary" style={{ float: "right" }}>
          OK
        </Button>
      }
    >
      <span>
        <b>Destination URL:</b>
      </span>
      <ul>
        {destinationUrls.map(url => (
          <li key={url}>
            {url}
            <span
              onClick={e => {
                e.preventDefault();
                window.open(url, "_blank");
              }}
            >
              <ShareOutlined style={{ marginLeft: 8, cursor: "pointer" }} />
            </span>
          </li>
        ))}
      </ul>
    </Drawer>
  );
};

export default DestinationDrawer;
