import { InfoCircleFilled } from "@ant-design/icons";
import { Alert, Space, Typography, Button } from "antd";
import { PmaxAdReviewItem } from "../../shared/types";
import styles from "./PmaxReviewStep.module.scss";

export const AlertSection = ({
  onDefineUrls,
  itemsWithErrors,
}: {
  onDefineUrls: () => void;
  itemsWithErrors: PmaxAdReviewItem[];
}) => {
  if (itemsWithErrors.length === 0) return null;

  const dealerName = itemsWithErrors[0].dealer.dealerName;
  const missingURLs = itemsWithErrors.filter(
    item => item.destination.name && !item.destination.url,
  );
  const missingURLLabels = itemsWithErrors.filter(
    item => !item.destination.name,
  );

  const missingURLMessage =
    missingURLs.length === 1
      ? `1 asset group for store "${dealerName}" has its final URL label with no URL defined.`
      : `${itemsWithErrors.length} asset groups for store "${dealerName}" have final URLs labels with no URLs defined.`;

  const missingURLLabelsMessage =
    missingURLLabels.length === 1
      ? `1 asset group for store "${dealerName}" has no final URL label. Please type a label or URL.`
      : `${itemsWithErrors.length} asset groups for store "${dealerName}" have no final URL label. Please type a label or URL`;

  return (
    <Space direction="vertical" className={styles.fullWidth}>
      {!!missingURLs.length && (
        <ErrorMessage
          message={missingURLMessage}
          action={{ text: "Define Final URLs", onClick: onDefineUrls }}
        />
      )}
      {!!missingURLLabels.length && (
        <ErrorMessage message={missingURLLabelsMessage} />
      )}
    </Space>
  );
};

type ErrorMessageProps = {
  message: string;
  action?: {
    onClick: () => void;
    text: string;
  };
};

const ErrorMessage = ({ message, action }: ErrorMessageProps) => (
  <Alert
    message={
      <Space>
        <Typography.Text>{message}</Typography.Text>
        {action && (
          <Button type="link" onClick={action.onClick}>
            {action.text}
          </Button>
        )}
      </Space>
    }
    type="error"
    icon={<InfoCircleFilled />}
    showIcon
    action
  />
);
