import { intersection } from "lodash";
import { AdLibraryField, IAd } from "shared/types/adLibrary";
import { DelimitedArrayParam, StringParam } from "use-query-params";
import {
  checkArrayIncludes,
  checkDateMatch,
  checkFilterMatch,
  formatDateValue,
  titleCase,
  getQcStatus,
} from "utils/helpers";
import { compareStringBy, onFilterBy } from "utils/helpers.table";

type Fields = {
  name: AdLibraryField;
  type: AdLibraryField;
  runDate: AdLibraryField;
  qcStatus: AdLibraryField;
  createdAt: AdLibraryField;
  updatedAt: AdLibraryField;
  updatedBy: AdLibraryField;
  destinationUrl: AdLibraryField;
  postCopy: AdLibraryField;
  headline: AdLibraryField;
  displayUrl: AdLibraryField;
  headlineDescription: AdLibraryField;
  utm: AdLibraryField;
  campaignStartDate: AdLibraryField;
  campaignEndDate: AdLibraryField;
  audiences: AdLibraryField;
  ctaButtonText: AdLibraryField;
  tags: AdLibraryField;
  store: AdLibraryField;
  adAccount: AdLibraryField;
  package: AdLibraryField;
  oem: AdLibraryField;
  brand: AdLibraryField;
  modelYear: AdLibraryField;
  modelName: AdLibraryField;
  year: AdLibraryField;
  month: AdLibraryField;
  version: AdLibraryField;
  strategy: AdLibraryField;
  storyPlacement: AdLibraryField;
  category: AdLibraryField;
  createdBy: AdLibraryField;
  inMarketStartDate: AdLibraryField;
  inMarketEndDate: AdLibraryField;
  adUrl: AdLibraryField;
  source: AdLibraryField;
  campaignStatus: AdLibraryField;
  planners: AdLibraryField;
};

export type IAdFields = keyof Fields;

export const fields: Fields = {
  name: {
    filterFn: onFilterBy<IAd>(ad => ad.inputParameters?.name, {
      caseInsensitive: true,
      matchMode: "includes",
    }),
    sorterFn: compareStringBy<IAd>(ad => ad.inputParameters?.name),
    queryParamConfigFilter: StringParam,
  },
  type: {
    filterFn: (value, record) => {
      return checkArrayIncludes(value, record.type);
    },
    sorterFn: compareStringBy("type"),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  runDate: {
    display(value) {
      return value.split(" ").map(formatDateValue).join(" - ");
    },
  },
  qcStatus: {
    filterFn: (value, record) => {
      const currentStatus = getQcStatus(record, record.validation?.isValid);
      return checkArrayIncludes(value, currentStatus);
    },
    sorterFn: compareStringBy("qcStatus"),
    display: titleCase,
    queryParamConfigFilter: DelimitedArrayParam,
  },
  createdAt: {
    filterFn: (value, record) => {
      return checkDateMatch(value?.toString(), record.createdAt);
    },
    sorterFn(a: IAd, b: IAd) {
      return a.createdAt - b.createdAt;
    },
    defaultSortOrder: "descend",
    display(value) {
      return value.split(" ").map(formatDateValue).join(" - ");
    },
    queryParamConfigFilter: StringParam,
  },
  updatedAt: {
    filterFn: (value, record) => {
      return checkDateMatch(value?.toString(), record.updatedAt);
    },
    sorterFn(a: IAd, b: IAd) {
      return a.updatedAt - b.updatedAt;
    },
    display(value) {
      return value.split(" ").map(formatDateValue).join(" - ");
    },
    queryParamConfigFilter: StringParam,
  },
  updatedBy: {
    filterFn: onFilterBy("updatedBy", {
      caseInsensitive: true,
      matchMode: "includes",
    }),
    sorterFn: compareStringBy("updatedBy"),
    queryParamConfigFilter: StringParam,
  },
  destinationUrl: {
    filterFn: onFilterBy<IAd>(ad => ad.inputParameters?.destinationUrl, {
      caseInsensitive: false,
      matchMode: "includes",
    }),
    sorterFn: compareStringBy<IAd>(ad => ad.inputParameters?.destinationUrl),
    queryParamConfigFilter: StringParam,
  },
  postCopy: {
    filterFn: onFilterBy<IAd>(ad => ad.visuals.postCopy, {
      caseInsensitive: true,
      matchMode: "includes",
    }),
    sorterFn: compareStringBy<IAd>(ad => ad.visuals.postCopy),
    queryParamConfigFilter: StringParam,
  },
  headline: {
    filterFn: onFilterBy<IAd>(ad => ad.visuals.headline, {
      caseInsensitive: true,
      matchMode: "includes",
    }),
    sorterFn: compareStringBy<IAd>(ad => ad.visuals.headline),
    queryParamConfigFilter: StringParam,
  },
  displayUrl: {
    filterFn: onFilterBy<IAd>(ad => ad.visuals.displayUrl, {
      caseInsensitive: false,
      matchMode: "includes",
    }),
    sorterFn: compareStringBy<IAd>(ad => ad.visuals.displayUrl),
    queryParamConfigFilter: StringParam,
  },
  headlineDescription: {
    filterFn: onFilterBy<IAd>(ad => ad.visuals.headlineDescription, {
      caseInsensitive: true,
      matchMode: "includes",
    }),
    sorterFn: compareStringBy<IAd>(ad => ad.visuals.headlineDescription),
    queryParamConfigFilter: StringParam,
  },
  utm: {
    filterFn: onFilterBy<IAd>(ad => ad.inputParameters?.utm, {
      caseInsensitive: true,
      matchMode: "includes",
    }),
    sorterFn: compareStringBy<IAd>(ad => ad.inputParameters?.utm),
    queryParamConfigFilter: StringParam,
  },
  campaignStartDate: {
    filterFn: (value, record) => {
      return checkDateMatch(
        value?.toString(),
        parseInt(record.inputParameters?.campaignStartDate || "0"),
      );
    },
    sorterFn: compareStringBy<IAd>(ad => ad.inputParameters?.campaignStartDate),
    display(value) {
      return value.split(" ").map(formatDateValue).join(" - ");
    },
    queryParamConfigFilter: StringParam,
  },
  campaignEndDate: {
    filterFn: (value, record) => {
      return checkDateMatch(
        value?.toString(),
        parseInt(record.inputParameters?.campaignEndDate || "0"),
      );
    },
    sorterFn: compareStringBy<IAd>(ad => ad.inputParameters?.campaignEndDate),
    display(value) {
      return value.split(" ").map(formatDateValue).join(" - ");
    },
    queryParamConfigFilter: StringParam,
  },
  audiences: {
    filterFn: (value, record) => {
      return !!record.inputParameters?.audiences?.some(v => value.includes(v));
    },
    queryParamConfigFilter: DelimitedArrayParam,
  },
  ctaButtonText: {
    filterFn: (value, record) => {
      return value.includes(record.visuals?.ctaButtonText);
    },
    sorterFn: compareStringBy<IAd>(ad => ad.visuals.ctaButtonText),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  tags: {
    filterFn: (value, record) => {
      return !!record.inputParameters?.tags?.some(tag =>
        value.some((v: string) => tag?.toLowerCase().includes(v.toLowerCase())),
      );
    },
    queryParamConfigFilter: DelimitedArrayParam,
  },
  store: {
    filterFn: onFilterBy<IAd>(ad => ad.inputParameters?.client, {
      caseInsensitive: true,
      matchMode: "includes",
    }),
    sorterFn: compareStringBy<IAd>(ad => ad.inputParameters?.client),
    queryParamConfigFilter: StringParam,
  },
  adAccount: {
    filterFn: (value, record) => {
      return checkFilterMatch(
        value?.toString(),
        record.inputParameters?.client?.toString(),
      );
    },
    sorterFn: compareStringBy<IAd>(ad => ad.inputParameters?.client),
    queryParamConfigFilter: StringParam,
  },
  package: {
    filterFn: (value, record) => {
      const arr = record.inputParameters?.package
        ?.split(",")
        .map((char: string) => char.trim());

      return !!arr?.some((v: string) => value.includes(v));
    },
    queryParamConfigFilter: DelimitedArrayParam,
  },
  oem: {
    filterFn: (value, record) => {
      return !!record.inputParameters?.oems?.some(v => value.includes(v));
    },
    queryParamConfigFilter: DelimitedArrayParam,
  },

  brand: {
    filterFn: (value, record) => {
      return checkFilterMatch(
        value?.toString(),
        record.inputParameters?.oems?.join(", "),
      );
    },
    queryParamConfigFilter: StringParam,
  },
  modelYear: {
    filterFn: (value, record) => {
      return checkFilterMatch(
        value?.toString(),
        record.inputParameters?.models?.map(model => model.year).join(","),
      );
    },
    queryParamConfigFilter: StringParam,
  },
  modelName: {
    filterFn: (value, record) => {
      return checkFilterMatch(
        value?.toString(),
        record.inputParameters?.models?.map(model => model.name).join(","),
      );
    },
    queryParamConfigFilter: StringParam,
  },
  year: {
    filterFn: (value, record) => {
      return record.category?.indexOf(value?.toString()) === 0;
    },
    sorterFn: compareStringBy<IAd>(ad => ad.inputParameters?.year),
    queryParamConfigFilter: StringParam,
  },
  month: {
    filterFn: (value: string[], record) => {
      return (
        intersection(value, record.inputParameters?.month).length ==
        value.length
      );
    },
    queryParamConfigFilter: StringParam,
  },
  version: {
    filterFn: (value, record) => {
      return record.category?.indexOf(value?.toString()) === 0;
    },
    sorterFn: compareStringBy<IAd>(ad => ad.inputParameters?.version),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  strategy: {
    filterFn: (value, record) => {
      return record.inputParameters?.strategy?.indexOf(value as string) === 0;
    },
    sorterFn: compareStringBy<IAd>(ad => ad.inputParameters?.strategy),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  storyPlacement: {
    filterFn: (value, record) => {
      return (
        record.inputParameters?.storyPlacement?.indexOf(value as string) === 0
      );
    },
    sorterFn: compareStringBy<IAd>(ad => ad.inputParameters?.storyPlacement),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  category: {
    filterFn: (value, record) => {
      return value.includes(record.category);
    },
    queryParamConfigFilter: StringParam,
  },
  createdBy: {
    filterFn: onFilterBy<IAd>("createdBy", {
      caseInsensitive: true,
      matchMode: "includes",
    }),
    queryParamConfigFilter: StringParam,
  },
  inMarketStartDate: {
    filterFn: ([value], record) => {
      const startDate = record.inputParameters?.campaignStartDate;
      const val = parseInt(value);

      if (startDate && !Number.isNaN(val)) {
        return parseInt(startDate) >= val;
      }

      return false;
    },
    display: formatDateValue,
    queryParamConfigFilter: StringParam,
  },
  inMarketEndDate: {
    filterFn: ([value], record) => {
      const endDate = record.inputParameters?.campaignEndDate;
      const val = parseInt(value);

      if (endDate && !Number.isNaN(val)) {
        return parseInt(endDate) <= val;
      }

      return false;
    },
    display: formatDateValue,
    queryParamConfigFilter: StringParam,
  },
  adUrl: {
    filterFn: onFilterBy("adUrl", {
      caseInsensitive: false,
      matchMode: "includes",
    }),
    sorterFn: compareStringBy("adUrl"),
    display: titleCase,
    queryParamConfigFilter: DelimitedArrayParam,
  },
  source: {
    filterFn: (value, record) => {
      return record.source?.indexOf(value?.toString()) === 0;
    },
    sorterFn: compareStringBy("source"),
    display: titleCase,
    queryParamConfigFilter: StringParam,
  },
  campaignStatus: {
    filterFn: (value, record) => {
      return record.campaignStatus?.indexOf(value?.toString()) === 0;
    },
    sorterFn: compareStringBy("campaignStatus"),
    display: titleCase,
    queryParamConfigFilter: DelimitedArrayParam,
  },
  planners: {
    filterFn: (value, record) => {
      return !![...(record.planners || []), ...(record.instances || [])].some(
        planner =>
          value.some((v: string) =>
            planner?.name?.toLowerCase().includes(v.toLowerCase()),
          ),
      );
    },
    queryParamConfigFilter: DelimitedArrayParam,
  },
};

export const adFieldKeys = Object.keys(fields) as Array<keyof typeof fields>;
