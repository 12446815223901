import { useCallback, useEffect, useState } from "react";
import { FileDoneOutlined } from "@ant-design/icons";
import { message, notification } from "antd";
import styles from "./ProgressNotificationDescription.module.scss";
import { IAdToLoadData, LoadStepKeys } from "shared/types/adLibrary";
import ProgressNotificationDescription from "./ProgressNotificationDescription";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { setAdLoadState } from "redux/adLibrary/adLibrary.slice";

interface IProps {
  adLoadingStarted: boolean;
  adLoadingComplete: boolean;
}

export const useProgressNotification = ({
  adLoadingStarted,
  adLoadingComplete,
}: IProps) => {
  const dispatch = useAppDispatch();
  const [shouldBeClosed, setShouldBeClosed] = useState(false);

  const showProgressBar = useCallback(() => {
    if (adLoadingStarted || adLoadingComplete) {
      setShouldBeClosed(false);
    }
  }, [adLoadingComplete, adLoadingStarted]);

  useEffect(showProgressBar, [showProgressBar]);

  const loadAgain = useCallback(() => {
    message.info("Loading process started.");

    dispatch(
      setAdLoadState({
        prop: "currentInnerStep",
        data: LoadStepKeys.LOADING_ADS,
      }),
    );
  }, [dispatch]);

  const updateProgressNotification = useCallback(
    (selectedAdsToLoad: IAdToLoadData[]) => {
      if (shouldBeClosed) return;

      notification.open({
        key: "progressNotification",
        message: "Loading Ads",
        icon: <FileDoneOutlined style={{ color: "#1890FF" }} />,
        className: styles.description,
        placement: "bottomRight",
        duration: 0,
        bottom: 50,
        description: (
          <ProgressNotificationDescription
            selectedAdsToLoad={selectedAdsToLoad}
            onRetry={loadAgain}
          />
        ),
        onClose: () => setShouldBeClosed(true),
      });
    },
    [loadAgain, shouldBeClosed],
  );

  const closeNotification = useCallback(() => {
    notification.close("progressNotification");
  }, []);

  return { updateProgressNotification, closeNotification };
};
