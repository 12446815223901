import API from "services";
import { useMutation, useQueryClient } from "react-query";
import { Platform, PlatformHookParams } from "../types";
import { getPlatformTokenKey } from "../utils";

interface ICreatePlatformTokenParams {
  platform: Platform;
  shortTermToken: string;
}

export const useCreatePlatformToken = ({
  platform,
  onError,
}: PlatformHookParams) => {
  const queryClient = useQueryClient();

  const createPlatformToken = async ({
    platform,
    shortTermToken,
  }: ICreatePlatformTokenParams) => {
    const { result, error } = await API.services.platformManagement.createToken(
      { platform, shortTermToken },
    );

    if (error || !result) {
      throw Error(error?.message || "Unknown error");
    }

    return result;
  };

  return useMutation<string, Error, ICreatePlatformTokenParams>(
    createPlatformToken,
    {
      onSettled: () => {
        queryClient.invalidateQueries(getPlatformTokenKey(platform));
      },
      onError,
    },
  );
};
