import { Position } from "react-flow-renderer";
import { ICarouselElement } from "shared/types/adLibrary";
import styles from "./FlowCarousel.module.scss";
import { Carousel } from "antd";
import FlowItem from "../../shared/FlowItem";
import FlowImage from "./FlowImage";

type Props = {
  ele: ICarouselElement;
};

const FlowCarousel = (props: Props) => {
  return (
    <div className={styles.carousel}>
      {props.ele.child_elements && (
        <div className={styles.imageWrapper}>
          <Carousel autoplay>
            {props.ele.child_elements.map(ele => (
              <div key={`carousel-image-${ele.id}`}>
                <img src={ele.url} alt="carousel" />
              </div>
            ))}
          </Carousel>

          {props.ele.child_elements.map((imageEle, idx) => (
            <div
              key={`carousel-image-${imageEle.id}`}
              className={styles.imageWrapper}
              style={{
                transform: `translateX(${100 * (idx + 1)}%)`,
                position: "absolute",
                top: 0,
              }}
            >
              <FlowItem ele={imageEle} handlePosition={Position.Top}>
                <FlowImage ele={imageEle} />
              </FlowItem>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FlowCarousel;
