import Page, {
  ICreateCanvasResponseData,
} from "screens/adLibrary/facebookUtils/page";

import { canvasElementTypeToBodyElementMap } from "screens/designStudio/library/instantExperiences/instantExperienceLoad/utils";

import {
  ICanvasParams,
  CanvasElementParamTypes,
  ICanvasButtonParams,
  ICanvasFooterParams,
  ICanvasPhotoParams,
  ICanvasVideoParams,
  ICanvasCarouselParams,
  ICanvasProductSetParams,
  ICanvasOpenURLAction,
  AdType,
} from "screens/adLibrary/facebookUtils/types";
import {
  IAdToLoadData,
  IInstantExperience,
  IInstantExperienceElementDestination,
  InstantExperienceBodyElement,
  InstantExperienceElementStyle,
  IAd,
  IEUrlValues,
  IButtonElement,
  IPhotoElement,
} from "shared/types/adLibrary";
import { IAccount } from "shared/types/accountManagement";

import { getUrlValue } from "../shared/utils";
import {
  doesElementHaveDestination,
  isUrlLabelValueValid,
} from "../reviewStep/reviewStepInnerDrawer/utils";
import { isCarousel, isFooter, isImage } from "utils/adLibrary.validators";
import { getEverythingAdKey } from "../reviewStep/reviewStepInnerDrawerV2/adsTree/helpers.adsTree";
import { getErrorMessage } from "utils/errorMessage";
import { IDataTableError } from "shared/types/errors";
import AdAccount from "screens/adLibrary/facebookUtils/adAccount";

type LoadAdMeta = {
  instantExperiences: IInstantExperience[];
  selectedDealer: IAccount;
  pageId: string;
};

type LoadInstantExperienceForAdArgs = {
  adToLoad: IAdToLoadData;
  meta: LoadAdMeta;
};

/** returns canvasUrl `https://fb.com/canvas_doc/${canvasId}`
 *  If an error is thrown, error.message is stringified JSON of the facebook error
 */
export const loadInstantExperienceForAd = async ({
  adToLoad,
  meta: { instantExperiences, pageId, selectedDealer },
}: LoadInstantExperienceForAdArgs) => {
  const rootIEId = adToLoad.ad.visuals.destination?.instantExperienceId;
  const ieToLoad = instantExperiences.find(ie => ie.id === rootIEId);
  const ieToLoadUrlVals = adToLoad.ieUrlValues ?? {};

  if (!ieToLoad?.id) return;

  const productSetId = adToLoad.productSet?.id;
  const isForCollection = adToLoad.ad.type === AdType.Collection;

  const bodyElements = isForCollection
    ? [
        getFirstElementForAdWithIE(adToLoad.ad),
        ...(ieToLoad.body_elements ?? []),
      ]
    : ieToLoad.body_elements;

  const { result, error } = await loadInstantExperienceToFacebook({
    instantExperienceToLoad: {
      ...ieToLoad,
      body_elements: bodyElements,
    },
    meta: {
      pageId,
      productSetId,
      selectedDealer,
      isForCollection,
      instantExperiences,
      ieToLoadUrlVals,
      parentIds: [adToLoad.key],
      adToLoad,
    },
  });

  if (error) {
    throw error;
  }

  return `https://fb.com/canvas_doc/${result?.id || ""}`;
};

export const getFirstElementForAdWithIE = (
  ad: IAd,
): InstantExperienceBodyElement => {
  if (ad.visuals.videoUrl) {
    return {
      id: ad.id,
      name: "Video",
      element_type: "VIDEO",
      url: ad.visuals.videoUrl,
      thumbnail: ad.visuals.thumbnail,
    };
  }
  return {
    id: ad.id,
    name: "Photo",
    element_type: "PHOTO",
    url: ad.visuals.thumbnail,
  };
};

export const insertUrlValueIntoElement = <
  ElementType extends InstantExperienceBodyElement,
>({
  element,
  parentIds,
  ieToLoadUrlVals,
}: {
  element: ElementType;
  parentIds: string[];
  ieToLoadUrlVals: IEUrlValues;
}): ElementType => {
  if (!doesElementHaveDestination(element)) return element;

  if (isFooter(element)) {
    const buttonElement = element.child_elements[0];
    const buttonElementWithUrl = getElementWithUrlValue({
      element: buttonElement,
      parentIds: [...parentIds, element.id],
      ieToLoadUrlVals,
    });
    return {
      ...element,
      child_elements: [buttonElementWithUrl],
    };
  }

  if (isCarousel(element)) {
    return {
      ...element,
      child_elements: element.child_elements.map(photoChildElement => {
        return getElementWithUrlValue({
          element: photoChildElement,
          parentIds: [...parentIds, element.id],
          ieToLoadUrlVals,
        });
      }),
    };
  }

  if (
    isImage(element) &&
    element.style &&
    element.style !== InstantExperienceElementStyle.FIT_TO_WIDTH
  ) {
    return element;
  }

  return getElementWithUrlValue({
    element,
    parentIds,
    ieToLoadUrlVals,
  });
};

const getElementWithUrlValue = <
  ElementType extends IButtonElement | IPhotoElement,
>({
  element,
  parentIds,
  ieToLoadUrlVals,
}: {
  element: ElementType;
  parentIds: string[];
  ieToLoadUrlVals: IEUrlValues;
}) => {
  if (!!element.destination?.instantExperienceId) return element;

  const elementKey = getEverythingAdKey({
    targetId: element.id,
    parentIds: parentIds,
  });
  const urlValue = ieToLoadUrlVals[elementKey];

  return {
    ...element,
    destination: { ...element.destination, urlLabel: urlValue },
  };
};

type IELoadMeta = {
  instantExperiences: IInstantExperience[];
  isForCollection: boolean;
  selectedDealer: IAccount;
  productSetId?: string;
  pageId: string;
  ieToLoadUrlVals: IEUrlValues;
  adToLoad: IAdToLoadData;
  parentIds: string[];
};

type LoadInstantExperienceToFacebookArgs = {
  instantExperienceToLoad: IInstantExperience;
  meta: IELoadMeta;
};

/**
 * Load all elements of an instant experience to facebook and recursively load all child instant experience elements
 */
const loadInstantExperienceToFacebook = async ({
  instantExperienceToLoad,
  meta,
}: LoadInstantExperienceToFacebookArgs): Promise<{
  result: ICreateCanvasResponseData | null;
  error: IDataTableError | null;
}> => {
  const createElementResults = await uploadCanvasElements({
    instantExperienceToLoad,
    meta: {
      ...meta,
      parentIds: [...meta.parentIds, instantExperienceToLoad.id!],
    },
  });

  const errors = createElementResults.filter(
    res => res.error || !res.result?.id,
  );

  if (errors.length) {
    return { result: null, error: errors[0].error };
  }

  const bodyElementIds = createElementResults.map(res => res.result!.id);

  const createCanvasRes = await uploadCanvas(
    meta.pageId,
    instantExperienceToLoad,
    bodyElementIds,
  );

  return { result: createCanvasRes, error: null };
};

const uploadCanvasElements = ({
  instantExperienceToLoad,
  meta,
}: LoadInstantExperienceToFacebookArgs) => {
  const fbPage = new Page(meta.pageId);
  return Promise.all(
    instantExperienceToLoad!.body_elements!.map(async bodyElement => {
      try {
        const bodyElementWithUrl = insertUrlValueIntoElement({
          element: bodyElement,
          parentIds: meta.parentIds,
          ieToLoadUrlVals: meta.ieToLoadUrlVals,
        });
        const canvasElementParams = await getCanvasElementParamsFromIEElement({
          element: bodyElementWithUrl,
          meta,
        });
        const elementType =
          canvasElementTypeToBodyElementMap[bodyElement.element_type];
        const { result, error } = await fbPage.createElementsAndReturn({
          [elementType!]: canvasElementParams,
        });
        if (error) {
          return { result: null, error };
        }
        return { result, error: null };
      } catch (errorRes) {
        return {
          result: null,
          error: {
            message: getErrorMessage(errorRes),
          },
        };
      }
    }),
  );
};

const uploadCanvas = (
  pageId: string,
  instantExperienceToLoad: IInstantExperience,
  bodyElementIds: string[],
) => {
  const fbPage = new Page(pageId);
  const canvasParams: ICanvasParams = {
    name: instantExperienceToLoad!.name,
    background_color: instantExperienceToLoad!.backgroundColor,
    enable_swipe_to_open: instantExperienceToLoad!.swipeToOpen,
    body_element_ids: bodyElementIds,
    is_published: true,
  };
  return fbPage.createCanvas(canvasParams);
};

type GetCanvasElementParamsFromIEElementArgs = {
  element: InstantExperienceBodyElement;
  meta: IELoadMeta;
};

const getCanvasElementParamsFromIEElement = async ({
  element,
  meta,
}: GetCanvasElementParamsFromIEElementArgs): Promise<CanvasElementParamTypes | null> => {
  const fbPage = new Page(meta.pageId);
  const adAccount = new AdAccount(meta.adToLoad.account.account_id);

  switch (element.element_type) {
    case "BUTTON":
      const buttonElement = element;
      return {
        top_padding: 48,
        bottom_padding: 48,
        name: buttonElement.name,
        rich_text: buttonElement.rich_text,
        font_size: buttonElement.font_size,
        text_color: buttonElement.text_color,
        font_family: buttonElement.font_family,
        button_color: buttonElement.button_color,
        button_style: buttonElement.button_style,
        background_color: buttonElement.background_color,
        open_url_action: await getOpenUrlActionFromDestination({
          ieDestination: buttonElement.destination!,
          meta: {
            ...meta,
            parentIds: [...meta.parentIds, buttonElement.id],
          },
        }),
      } as ICanvasButtonParams;
    case "FOOTER":
      const footerElement = element;
      // We assume that footers can only have one element (button) in Alexia
      const childBtnIEElement = footerElement.child_elements[0];
      const childBtnCanvasElement = (await getCanvasElementParamsFromIEElement({
        element: childBtnIEElement,
        meta: {
          ...meta,
          parentIds: [...meta.parentIds, footerElement.id],
        },
      })!) as ICanvasButtonParams;

      const { result: elementRes, error } =
        await fbPage.createElementsAndReturn({
          canvas_button: childBtnCanvasElement!,
        });

      if (error) {
        throw new Error(JSON.stringify(error));
      }

      return {
        name: element.name,
        background_color: footerElement.background_color,
        child_elements: [elementRes?.id],
      } as ICanvasFooterParams;
    case "PHOTO":
      const photoElement = element;
      const { id: photoId } = await fbPage.createMemoizedPageMedia({
        mediaType: "photo",
        url: photoElement.url!,
      });
      return {
        photo_id: photoId,
        name: element.name,
        style: photoElement.style,
        open_url_action:
          InstantExperienceElementStyle.FIT_TO_WIDTH === photoElement.style ||
          !photoElement.style
            ? await getOpenUrlActionFromDestination({
                ieDestination: photoElement.destination!,
                meta: {
                  ...meta,
                  parentIds: [...meta.parentIds, photoElement.id],
                },
              })
            : undefined,
      } as ICanvasPhotoParams;
    case "VIDEO":
      const videoElement = element;
      const { id: videoId } = await adAccount.createMemoizedAdVideo({
        file_url: videoElement.url!,
      });
      return {
        video_id: videoId,
        name: element.name,
        style: videoElement.style,
      } as ICanvasVideoParams;
    case "CAROUSEL":
      const carouselElement = element;
      const childElements = await Promise.all(
        carouselElement.child_elements.map(childElement =>
          getCanvasElementParamsFromIEElement({
            element: childElement,
            meta: {
              ...meta,
              parentIds: [
                ...meta.parentIds,
                carouselElement.id,
                childElement.id,
              ],
            },
          }),
        ),
      );
      const photoIds = await Promise.all(
        childElements.map(async childElement => {
          const { result, error } = await fbPage.createElementsAndReturn({
            canvas_photo: childElement!,
          });
          if (error) {
            throw new Error(JSON.stringify(error));
          }
          return result?.id;
        }),
      ).catch(error => {
        throw new Error(error.message);
      });
      return { child_elements: photoIds } as ICanvasCarouselParams;
    case "ELEMENT_GROUP":
      const productSetElement = element;
      return {
        name: element.name,
        show_in_feed: !!meta.isForCollection,
        max_items: productSetElement.max_products,
        item_headline: productSetElement.item_headline,
        item_description: productSetElement.item_description,
        product_set_id: meta.productSetId || productSetElement.product_set_id,
      } as ICanvasProductSetParams;
    default:
      return null;
  }
};

type ReturnOpenUrlActionFromDestinationArgs = {
  ieDestination: IInstantExperienceElementDestination;
  meta: IELoadMeta;
};

const getOpenUrlActionFromDestination = async ({
  ieDestination,
  meta: {
    instantExperiences,
    selectedDealer,
    pageId,
    isForCollection,
    productSetId,
    ieToLoadUrlVals,
    parentIds,
    adToLoad,
  },
}: ReturnOpenUrlActionFromDestinationArgs): Promise<
  ICanvasOpenURLAction | undefined
> => {
  if (!ieDestination) {
    return;
  }

  let destinationUrl = "";

  if (ieDestination.instantExperienceId) {
    const nestedInstantExperience = instantExperiences?.find(
      ie => ie.id === ieDestination.instantExperienceId,
    );

    if (!nestedInstantExperience?.id) throw Error("Nested IE not found");

    const { result, error } = await loadInstantExperienceToFacebook({
      instantExperienceToLoad: nestedInstantExperience,
      meta: {
        instantExperiences,
        selectedDealer,
        pageId,
        isForCollection,
        productSetId,
        ieToLoadUrlVals,
        parentIds,
        adToLoad,
      },
    });

    if (error) {
      throw error;
    }

    destinationUrl = `https://fb.com/canvas_doc/${result?.id || ""}`;
  } else if (ieDestination.urlLabel) {
    const urlValue = getUrlValue(
      ieDestination.urlLabel,
      selectedDealer?.labels,
    );
    const isValueValid = isUrlLabelValueValid(
      urlValue,
      selectedDealer?.labels || [],
    );

    destinationUrl = isValueValid ? urlValue : "";
  }

  return { type: "OPEN_URL", url: destinationUrl };
};
