import { useCallback } from "react";

import { Badge, BadgeProps, Modal, Typography } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";

import ErrorModalContent from "./adLoadAdStatusBadge/ErrorModalContent";

import {
  FacebookAdStatus,
  IFacebookErrorObject,
} from "screens/adLibrary/facebookUtils/types";
import { AdLoadAdStatus, IAdLoadStatus } from "shared/types/adLibrary";
import { AppColors } from "shared/types/shared";

export interface IProps {
  description: string;
  status: AdLoadAdStatus;
  adLoadStatus?: IAdLoadStatus;
  facebookStatus?: FacebookAdStatus;
  facebookError?: IFacebookErrorObject;

  onErrorClick?: () => void;
}

const getBadgeStatus = (adLoadStatus: AdLoadAdStatus): BadgeProps["status"] => {
  switch (adLoadStatus) {
    case "loading":
      return "processing";
    case "draft":
    case "idle":
      return "default";
    default:
      return adLoadStatus as BadgeProps["status"];
  }
};

const getErrorMessage = (adLoadStatus?: IAdLoadStatus) => {
  const firstError = adLoadStatus?.reviewAndQAIssues?.find(
    issue => issue.status === "error",
  );
  return firstError?.message || adLoadStatus?.errorMessage;
};

const getStatusColor = (
  status: AdLoadAdStatus,
  fbStatus?: FacebookAdStatus,
) => {
  if (fbStatus === FacebookAdStatus.ACTIVE) {
    return AppColors.success;
  }
  return ["draft", "ready"].includes(status) ? AppColors.secondary : undefined;
};

const AdLoadAdStatusBadge = ({
  status,
  description,
  adLoadStatus,
  facebookError,
  facebookStatus,

  onErrorClick,
}: IProps) => {
  const showErrorModal = useCallback(() => {
    if (!facebookError) return;
    Modal.error({
      width: 450,
      closable: true,
      maskClosable: true,
      icon: <InfoCircleFilled />,
      content: <ErrorModalContent facebookError={facebookError} />,
      title: <b>{facebookError.error_user_title || "Error Details"}</b>,
    });
  }, [facebookError]);

  return (
    <Badge
      status={getBadgeStatus(status)}
      color={getStatusColor(status, facebookStatus)}
      text={
        <span>
          {status === "error" && facebookError && (
            <Typography.Link
              title={
                !onErrorClick
                  ? "Click here to view error details."
                  : "Command (Mac) or CTRL (Windows) + Click to view error details"
              }
              onClick={e => {
                if (onErrorClick && !e.metaKey && !e.ctrlKey) {
                  onErrorClick();
                  return;
                }
                showErrorModal();
              }}
            >
              {description}
            </Typography.Link>
          )}
          {status === "error" && !facebookError && (
            <Typography.Text title={getErrorMessage(adLoadStatus)}>
              {description}
            </Typography.Text>
          )}
          {status !== "error" && !facebookError && description}
        </span>
      }
    />
  );
};

export default AdLoadAdStatusBadge;
