import { keys } from "lodash";
import { Key, useCallback, useEffect, useState } from "react";
import { FilterDropdownWithFilterSearch } from "../FilterDropdownWithFilterSearch";
import {
  FilterConfirmProps,
  FilterDropdownProps,
} from "antd/lib/table/interface";
import { DEFAULT_KEY, useDataList } from "./useDataList";

export interface DataListColumnFilterSearchProps extends FilterDropdownProps {
  column: string;
}

export function DataListColumnFilterSearch<T>(
  props: DataListColumnFilterSearchProps,
) {
  const [dataList, dataListActions] = useDataList<T>();
  const { column, selectedKeys } = props;
  const [selectedKeysInternal, setSelectedKeysInternal] =
    useState<Key[]>(selectedKeys);

  // Needed to sync internal key list with dataList.filters when they change externally
  useEffect(
    () => setSelectedKeysInternal(dataList.filters[DEFAULT_KEY][column] || []),
    [dataList.filters, column],
  );

  const confirmHandler = useCallback(
    (closeDropdown?: FilterConfirmProps) => {
      props.confirm?.(closeDropdown);
      if (!closeDropdown) return;
      const filters = selectedKeysInternal.reduce(
        (acc: Record<string, string[]>, value: Key) => {
          if (!(column in acc)) {
            return { ...acc, [column]: [value.toString()] };
          }
          return { ...acc, [column]: [...acc[column], value.toString()] };
        },
        {},
      );
      if (keys(filters).length) {
        const updatedFilters = Object.assign(
          dataList.filters[DEFAULT_KEY],
          filters,
        );
        dataListActions.setFilters(updatedFilters);
        return;
      }
      dataListActions.updateFilter(column, null);
    },
    [column, dataList.filters, dataListActions, props, selectedKeysInternal],
  );

  return (
    <FilterDropdownWithFilterSearch
      {...props}
      clearFilters={() => {
        dataListActions.updateFilter(column, null);
        setSelectedKeysInternal([]);
      }}
      selectedKeys={selectedKeysInternal}
      setSelectedKeys={setSelectedKeysInternal}
      confirm={confirmHandler}
    />
  );
}
