import { useCallback, useMemo, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import {
  IBrandFormValues,
  BrandRecord,
  ILogos,
  Font,
} from "shared/types/brandsAccounts";
import { useUpdateBrand } from "shared/hooks/brandsAccountsManagement/useUpdateBrand";
import { BrandBaseDrawer } from "./BrandBaseDrawer";
import { UploadFile } from "antd/lib/upload/interface";
import { IFile } from "shared/types/uploadAsset";
import { useDeleteBrandsAccounts } from "shared/hooks/brandsAccountsManagement/useDeleteBrandsAccounts";
import { useDataList } from "shared/components/dataList";

interface Props {
  onCancel: () => void;
  visible: boolean;
  brandToEdit: BrandRecord;
}

export const EditBrandDrawer = ({ brandToEdit, ...props }: Props) => {
  const [form] = useForm<IBrandFormValues>();
  const [_, dataListActions] = useDataList<BrandRecord>();

  const initialLogoValues = useMemo(
    () => parseLogosToEdit(brandToEdit),
    [brandToEdit],
  );

  const initialFontsValues = useMemo(
    () => brandToEdit?.fonts?.map(parseFontToEdit),
    [brandToEdit],
  );

  const [logos, setLogos] = useState<ILogos>(initialLogoValues);
  const [fonts, setFonts] = useState<UploadFile[]>(initialFontsValues);

  const [logosToRemove, setLogosToRemove] = useState<
    Record<keyof ILogos, string[]>
  >({
    horizontalLogos: [],
    squareLogos: [],
    verticalLogos: [],
  });
  const [fontsToRemove, setFontsToRemove] = useState<string[]>([]);

  const [youtubeConnected, setYoutubeConnected] = useState(
    brandToEdit.youtubeConnected,
  );

  const { onDelete } = useDeleteBrandsAccounts(brandToEdit);
  const { mutate: updateBrand, isLoading: createBrandIsLoading } =
    useUpdateBrand({
      onSuccess: (brand: BrandRecord) => {
        setLogosToRemove({
          horizontalLogos: [],
          squareLogos: [],
          verticalLogos: [],
        });
        setFontsToRemove([]);

        setFonts(brand.fonts.map(parseFontToEdit));
        setLogos(parseLogosToEdit(brand));
      },
    });

  const onSubmit = useCallback(async () => {
    const formValues = {
      logosToUpload: {
        horizontalLogos: filterLogos(logos.horizontalLogos, true),
        squareLogos: filterLogos(logos.squareLogos, true),
        verticalLogos: filterLogos(logos.verticalLogos, true),
      },
      existingLogos: {
        horizontalLogos: filterLogos(logos.horizontalLogos, false),
        squareLogos: filterLogos(logos.squareLogos, false),
        verticalLogos: filterLogos(logos.verticalLogos, false),
      },
      createdAt: brandToEdit.createdAt,
      fontsToUpload: filterFonts(fonts, true),
      existingFonts: filterFonts(fonts, false),
      ...form.getFieldsValue(),
    };

    updateBrand({
      ...formValues,
      logosToRemove: Object.values(logosToRemove).flat(),
      fontsToRemove,
    });
    dataListActions.resetFilters("accounts");
  }, [
    logos.horizontalLogos,
    logos.squareLogos,
    logos.verticalLogos,
    brandToEdit.createdAt,
    fonts,
    form,
    updateBrand,
    logosToRemove,
    fontsToRemove,
    dataListActions,
  ]);

  const onRemoveLogo = useCallback(
    (url: string, fieldKey: keyof ILogos) => {
      setLogosToRemove(prev => ({
        ...prev,
        [fieldKey]: [...prev[fieldKey], url],
      }));
    },
    [setLogosToRemove],
  );

  const onRemoveFont = useCallback(
    (url: string) => {
      setFontsToRemove(prev => [...prev, url]);
    },
    [setFontsToRemove],
  );

  return (
    <BrandBaseDrawer
      buttonText="Save"
      drawerTitle="Edit Brand"
      mutationIsLoading={createBrandIsLoading}
      onSubmit={onSubmit}
      logos={logos}
      setLogos={setLogos}
      form={form}
      onRemoveLogo={onRemoveLogo}
      nameToEdit={brandToEdit.name}
      onRemoveFont={onRemoveFont}
      fonts={fonts}
      setFonts={setFonts}
      onDelete={() => {
        onDelete();
        dataListActions.resetFilters("accounts");
        props.onCancel();
      }}
      youtubeConnected={youtubeConnected}
      setYoutubeConnected={setYoutubeConnected}
      {...props}
    />
  );
};

const parseLogosToEdit = (brand: BrandRecord) => ({
  horizontalLogos:
    brand.logosFromS3.horizontalImagesFromS3?.map(parseBrandLogoToEdit),
  squareLogos: brand.logosFromS3.squareImagesFromS3?.map(parseBrandLogoToEdit),
  verticalLogos:
    brand.logosFromS3.verticalImagesFromS3?.map(parseBrandLogoToEdit),
});

const filterLogos = (logos: UploadFile<IFile>[], isOrigFileObj: boolean) =>
  logos?.filter(logo => !!logo.originFileObj === isOrigFileObj) ?? [];

const filterFonts = (fonts: UploadFile[], isOrigFileObj: boolean) =>
  fonts.filter(font => !!font.originFileObj === isOrigFileObj);

const parseBrandLogoToEdit = (imageUrl: string, index: number) => ({
  uid: `exising-logo-${index}`,
  name: `exising-logo-${index}`,
  url: imageUrl,
});

const parseFontToEdit = ({ name, url }: Font, index: number) => ({
  uid: `exising-font-${index}`,
  name,
  url,
  type: "font/ttf",
});
