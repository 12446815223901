import { Select, Typography } from "antd";
import styles from "./Portal.module.scss";
import { FeedTblCol } from "shared/types/assetExporter";
import { COLUMN_ASSET_BATCHES } from "shared/constants/assetExporter";

interface PortalColumnSelectProps {
  feedColumns: FeedTblCol[];
  isVisible: boolean;
  onChange: (value: string) => void;
  disabled: boolean;
  value: string | undefined;
  columnValidationError: boolean;
  columnType: string;
}

export const PortalColumnSelect = ({
  feedColumns,
  isVisible,
  onChange,
  disabled,
  value,
  columnValidationError,
  columnType,
}: PortalColumnSelectProps) => {
  const feedColumnOptions = feedColumns
    .filter(
      column =>
        !!column.key && !!column.title && column.key !== COLUMN_ASSET_BATCHES,
    )
    .map(column => ({
      label: column.title,
      value: column.key,
    }));

  if (!isVisible) return null;
  return (
    <div className={styles.columnSelectWrapper}>
      <div>
        <Typography.Text type="danger">* </Typography.Text>
        <Typography.Text>Feed Column</Typography.Text>
      </div>
      <div>
        <Select
          className={styles.select}
          onChange={value => onChange(String(value))}
          disabled={disabled}
          value={value || undefined}
          placeholder="select"
        >
          {feedColumnOptions.map(feedColumn => {
            return (
              <Select.Option key={feedColumn.value} value={feedColumn.value}>
                {feedColumn.label}
              </Select.Option>
            );
          })}
        </Select>
      </div>
      {columnValidationError && (
        <Typography.Text type="danger">{`This column has invalid '${columnType.toLowerCase()}' values. Please select a valid one.`}</Typography.Text>
      )}
    </div>
  );
};
