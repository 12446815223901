import { Select } from "antd";

import { useQueryClient } from "react-query";
import { useFetchProductSets } from "shared/hooks/adLibrary/facebook/useFetchProductSets";

import { OptionProps } from "antd/lib/select";
import { GroupProps } from "antd/lib/avatar";
import { IFacebookProductSet } from "screens/adLibrary/facebookUtils/types";
import { CSSProperties, useCallback, useMemo } from "react";

interface IProductSetSelectProps {
  disabled?: boolean;
  style?: CSSProperties;
  productCatalogId: string;
  productCatalogIds?: string[];
  productSetId?: string;
}

interface IProductSetSelectHandlers {
  onSelect: (
    productSetId: string,
    option?: OptionProps | GroupProps,
    productSet?: IFacebookProductSet,
  ) => void;
}

const LegacyProductSetSelect = ({
  productCatalogIds,
  productCatalogId,
  productSetId,
  onSelect,
  disabled,
  style,
}: IProductSetSelectProps & IProductSetSelectHandlers) => {
  const queryClient = useQueryClient();
  const cachedProductSets: IFacebookProductSet[] | undefined =
    queryClient.getQueryData(["facebookProductSets", productCatalogId]) ||
    queryClient.getQueryData([
      "facebookProductSets",
      productCatalogIds?.sort().join(","),
    ]);

  const ignoreCache =
    !cachedProductSets?.length ||
    !productCatalogIds?.includes(productCatalogId);

  const {
    isFetching,
    isLoading,
    data: productSets,
  } = useFetchProductSets({
    productCatalogId: ignoreCache ? productCatalogId : "",
  });

  const loadingProductSets = isLoading || isFetching;

  const options = useMemo(() => {
    if (ignoreCache) {
      return productSets;
    }

    return (
      cachedProductSets?.filter(productSet =>
        productCatalogId
          ? productSet.product_catalog?.id === productCatalogId
          : productSet,
      ) ?? []
    );
  }, [cachedProductSets, ignoreCache, productCatalogId, productSets]);

  const handleSelect = useCallback(
    (selectedProductSetId: string, option: OptionProps | GroupProps) => {
      onSelect(
        selectedProductSetId,
        option,
        options?.find(option => option.id === selectedProductSetId),
      );
    },
    [onSelect, options],
  );

  return (
    <Select
      showSearch
      style={style}
      value={productSetId}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      loading={loadingProductSets}
      disabled={disabled || loadingProductSets || !productCatalogId}
      placeholder={
        loadingProductSets ? "Fetching product sets..." : "Select a product set"
      }
      onSelect={handleSelect}
    >
      {options?.map(productSet => (
        <Select.Option key={`option-${productSet.id}`} value={productSet.id}>
          {productSet.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default LegacyProductSetSelect;
