import styles from "./googlePerformanceMax/GooglePerformanceMax.module.scss";
import { PmaxCampaignsDataProvider } from "./googlePerformanceMax/PmaxCampaignsProvider";
import { PmaxCampaignsTable } from "./googlePerformanceMax/PmaxCampaignsTable";

export const GooglePerformanceMax = () => {
  return (
    <PmaxCampaignsDataProvider>
      <div className={styles.container}>
        <PmaxCampaignsTable />
      </div>
    </PmaxCampaignsDataProvider>
  );
};
