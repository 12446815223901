import { Empty, Input, Menu, notification, Popover, Spin, Tag } from "antd";
import { FC, useContext, useState } from "react";
import { context } from "../../../Editor.context";

import styles from "./StampSelection.module.scss";

interface StampSelectionHandlers {
  onStampSelected: () => void;
}

const StampSelection: FC<StampSelectionHandlers> = props => {
  const editorContext = useContext(context);
  const [searchBy, setSearchBy] = useState<string>();
  return (
    <div className={styles.StampSizeMenuContainer}>
      <Input.Search
        className="stamp-search-input"
        placeholder="Stamp name"
        onChange={e => {
          setSearchBy(e.target.value);
        }}
      />
      {editorContext?.isFetchingStampsForInsert && (
        <Spin className={styles.StampListMenuLoader} tip="Loading..." />
      )}

      {!editorContext?.isFetchingStampsForInsert &&
        !editorContext?.stampsForInsert?.length && (
          <Empty className={styles.StampListEmpty} />
        )}

      {!editorContext?.isFetchingStampsForInsert &&
        !!editorContext?.stampsForInsert?.length && (
          <div className={styles.StampList}>
            <Menu
              className={styles.StampListMenu}
              selectedKeys={[]}
              onClick={({ key: id }) => {
                const selectedStamps =
                  editorContext?.stampsForInsert?.filter(
                    stmp => stmp.id === id,
                  ) || [];

                if (!selectedStamps) {
                  notification.error({
                    message: "Stamp cannot be found.",
                    description:
                      "The stamp you selected cannot be found due to an error.",
                  });

                  return;
                }

                // We save thumbnail for each offer type.
                // This means that if an offer type was never updated, the thumbnail url is empty/invalid.
                // There was an issue where inserted stamp is blank in the canvas and this probably caused it.
                // Pick first stamp that has valid thumbnail url instead of pick the first one in the array.

                const selectedFirstStamp = selectedStamps.find(
                  stmp => !!stmp.thumbnailUrl,
                );
                if (!selectedFirstStamp) {
                  notification.error({
                    message: "Stamp was never updated.",
                    description:
                      "It looks like this stamp was never updated. We cannot proceed since there is no content to display in the stamp.",
                  });

                  return;
                }

                editorContext.setImageInsertData({
                  type: "STAMP",
                  data: selectedFirstStamp,
                });

                props.onStampSelected();
              }}
            >
              {editorContext.stampsForInsert
                .filter(stmp =>
                  searchBy
                    ? stmp.name.toLowerCase().includes(searchBy.toLowerCase())
                    : true,
                )
                .map(stmp => (
                  <Menu.Item key={stmp.id} className={styles.StampListMenuItem}>
                    <Popover
                      placement="rightTop"
                      mouseEnterDelay={0.5}
                      content={
                        <div>
                          <img
                            style={{ margin: "0.5em" }}
                            src={stmp.thumbnailUrl || undefined}
                          />
                        </div>
                      }
                    >
                      <div className={styles.StampListMenuItemWrapper}>
                        <div>{`${stmp.name}`}</div>
                        <div>
                          {stmp.oems?.map(oem => (
                            <Tag key={`stamp-tag-${oem}`}>{oem}</Tag>
                          ))}

                          <Tag
                            key={`stamp-tag-size-${stmp.width}x${stmp.height}`}
                          >
                            {`${stmp.width} x ${stmp.height}`}
                          </Tag>
                        </div>
                      </div>
                    </Popover>
                  </Menu.Item>
                ))}
            </Menu>
          </div>
        )}
    </div>
  );
};

export default StampSelection;
