import { FileImageFilled, UpCircleFilled } from "@ant-design/icons";
import { memo, useMemo } from "react";
import VisualMedia, {
  ImgDimensions,
} from "screens/adLibrary/shared/VisualMedia";
import {
  InstantExperienceElementStyle,
  IPhotoElement,
} from "shared/types/adLibrary";
import { LIVE_PREVIEW_HEIGHT, LIVE_PREVIEW_WIDTH } from "../LivePreview";
import styles from "./MediaPreview.module.scss";

interface IProps {
  photoElement: IPhotoElement;
  isLastElement?: boolean;
  onLoadImgDimensions?: ({
    naturalWidth,
    naturalHeight,
  }: ImgDimensions) => void;
  minHeight?: number;
  handleSelectInstantExperienceId?: (instantExperienceId: string) => void;
}

const ImagePreview = ({
  photoElement,
  isLastElement,
  onLoadImgDimensions,
  minHeight,
  handleSelectInstantExperienceId,
}: IProps) => {
  const shouldDisplaySwipeToLoad =
    isLastElement &&
    photoElement.style === InstantExperienceElementStyle.FIT_TO_WIDTH &&
    photoElement.destination?.urlLabel;

  const minHeightToUse = useMemo(() => {
    if (minHeight) return minHeight;
    if (photoElement.url) return "auto";
    return;
  }, [minHeight, photoElement.url]);

  const isNavigable =
    !!handleSelectInstantExperienceId &&
    !!photoElement.destination?.instantExperienceId;

  return (
    <div
      onClick={() =>
        isNavigable &&
        handleSelectInstantExperienceId?.(
          photoElement.destination!.instantExperienceId!,
        )
      }
      style={{ cursor: isNavigable ? "pointer" : "auto" }}
    >
      <VisualMedia
        mediaHeight={
          photoElement.style === InstantExperienceElementStyle.FIT_TO_HEIGHT
            ? LIVE_PREVIEW_HEIGHT
            : undefined
        }
        imageMinHeight={minHeightToUse}
        thumbnailUrl={photoElement.url}
        placeholder={
          <div
            className={styles.mediaPlaceholder}
            style={{
              width: LIVE_PREVIEW_WIDTH,
              height: minHeightToUse ?? LIVE_PREVIEW_WIDTH,
            }}
          >
            <FileImageFilled style={{ color: "#AFB4BD" }} />
          </div>
        }
        onLoadImgDimensions={onLoadImgDimensions}
      />
      {shouldDisplaySwipeToLoad && (
        <div className={styles.swipeIndicator}>
          <UpCircleFilled style={{ fontSize: 20 }} />
          <div className={styles.swipeToLoad}>Swipe to Load Website</div>
        </div>
      )}
    </div>
  );
};

export default memo(ImagePreview);
