import { Alert, message } from "antd";
import styles from "./longAlert.module.scss";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  InfoCircleFilled,
} from "@ant-design/icons";

type MessageType = "error" | "success" | "info";
type Props = {
  type: MessageType;
  body?: JSX.Element;
  header: JSX.Element | string;
  duration?: number;
};

const IconMap: Record<MessageType, JSX.Element> = {
  error: <CloseCircleFilled style={{ fill: "red" }} />,
  success: <CheckCircleFilled style={{ fill: "green" }} />,
  info: <InfoCircleFilled style={{ fill: "lightblue" }} />,
};

export const longAlert = ({ type, body, header, duration }: Props) => {
  const MessageContent = () => {
    return (
      <div className={styles.messageContainer}>
        {IconMap[type]}
        <div>
          <div>{header}</div>
          <div className={styles.body}>{body}</div>
        </div>
      </div>
    );
  };

  message[type]({
    content: (
      <Alert
        type={type}
        message={<MessageContent />}
        closable
        onClose={() => message.destroy("long-alert")}
      />
    ),
    icon: <></>,
    className: styles.container,
    duration,
    key: "long-alert",
  });
};
