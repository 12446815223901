import { Col } from "antd";
import styles from "./CommonDrawer.module.scss";

type FieldTitleProps = {
  title: string;
};

export const FieldTitle = ({ title }: FieldTitleProps) => {
  return (
    <Col span={24} className={styles.fieldTitle}>
      {title}
    </Col>
  );
};
