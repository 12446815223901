import { useGetFoldersQuery } from "redux/media/media.api";
import { useQPBreadcrumb } from "../useQPBreadcrumb";
import { useQPLayout } from "../useQPLayout";
import { Grid } from "./Grid";
import { TableList } from "./TableList";

export const ListMode = () => {
  const [layout] = useQPLayout();
  const { current } = useQPBreadcrumb();
  const { data, isFetching } = useGetFoldersQuery(current);

  return layout === "grid" ? (
    <Grid data={data} isFetching={isFetching} />
  ) : (
    <TableList data={data} isFetching={isFetching} />
  );
};
