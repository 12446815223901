import { DesignStudioTableItem } from "screens/designStudio/context/DesignStudioDataProvider";
import styles from "./DesignStudioItemCard.module.scss";
import { Typography, Image, Checkbox } from "antd";
import { CardInfo } from "./designStudioItemCard/CardInfo";
import { CardTags } from "./designStudioItemCard/CardTags";
import { CardActions } from "./designStudioItemCard/CardActions";
import ErrorImage from "statics/images/missing-thumbnail.png";
import EllipsisText from "shared/components/card/EllipsisText";
import { PublishStatus } from "./designStudioItemCard/PublishStatus";
import classNames from "classnames";
import { isArtboard, getThumbnailUrl } from "screens/designStudio/helpers";

export type BaseDesignStudioItemCardProps = {
  item: DesignStudioTableItem;
};

type DesignStudioItemCardProps = BaseDesignStudioItemCardProps & {
  isSelected: boolean;
  showCheckbox: boolean;
  onChange: (id: string, checked: boolean) => void;
};

export const DesignStudioItemCard = ({
  item,
  isSelected,
  showCheckbox,
  onChange,
}: DesignStudioItemCardProps) => {
  const showPublishStatus = !isArtboard(item);
  return (
    <div
      className={styles.container}
      onClick={() => {
        if (!showCheckbox || window.getSelection()?.toString()) return;
        showCheckbox && onChange(item.id, !isSelected);
      }}
    >
      <Checkbox
        className={classNames(styles.checkbox, {
          [styles.visible]: showCheckbox,
        })}
        checked={isSelected}
        onChange={e => onChange(item.id, e.target.checked)}
      />
      {showPublishStatus && <PublishStatus item={item} />}
      <div className={styles.imageContainer}>
        <Image
          src={getThumbnailUrl(item) ?? ErrorImage}
          fallback={ErrorImage}
          preview={false}
        />
      </div>
      <div className={styles.infoContainer}>
        <Typography.Text className={styles.name}>
          <EllipsisText text={item.asset.name} charDisplayLimit={40} />
        </Typography.Text>
        <CardInfo item={item} />
        <CardTags item={item} />
      </div>
      <CardActions item={item} />
      <div
        className={classNames(styles.hoverBorder, {
          [styles.selected]: isSelected,
        })}
      />
    </div>
  );
};
