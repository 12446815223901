import { DataNode, Key } from "rc-tree/lib/interface";

export const formatFolders = (data: WDFolder[]): DataNode[] => {
  return data.map(asset => {
    return { title: asset.name, key: +asset.id };
  });
};

export const updateTreeData = (
  key: Key,
  treeData: DataNode[],
  children: DataNode[],
): DataNode[] => {
  return treeData.map(node => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }

    if (node.children) {
      return {
        ...node,
        children: updateTreeData(key, node.children, children),
      };
    }

    return node;
  });
};
