import { Spin, SpinProps } from "antd";

type Props = SpinProps & {
  justify?: React.CSSProperties["justifyContent"];
  align?: React.CSSProperties["alignItems"];
};

export const Loading = ({ justify, align, ...spinProps }: Props) => {
  const justifyContent = justify ?? "center";
  const alignItems = align ?? "center";

  return (
    <div style={{ display: "flex", justifyContent, alignItems }}>
      <Spin {...spinProps} />
    </div>
  );
};
