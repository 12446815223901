import { Space } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import DateCell from "screens/adLibrary/shared/components/DateCell";

import { useIsFetching } from "react-query";

import { adLoadStepTitles } from "./shared/constants";

import { AdLoadStepKey } from "shared/types/adLibrary";
import { useFetchSessions } from "shared/hooks/adLibrary/adHistory/useFetchSessions";

type DrawerTitleProps = {
  sessionId?: string;
  isOnSessionsPage?: boolean;
  currentStep?: AdLoadStepKey | null;
};

const DrawerTitle = ({
  sessionId,
  currentStep,
  isOnSessionsPage,
}: DrawerTitleProps) => {
  const { data: sessions } = useFetchSessions({
    queryParams: { id: sessionId },
    enabled: !!sessionId,
  });

  const session = sessions?.[0];

  const isFetching = !!useIsFetching();

  return (
    <Space>
      <span style={{ fontWeight: "bold", fontSize: "16px" }}>
        {!isOnSessionsPage &&
          adLoadStepTitles[currentStep || AdLoadStepKey.DESTINATION_SELECTION]}
        {isOnSessionsPage && (
          <>
            Session History
            {session && ": "}
            {session && (
              <span>
                {session.name}, Created at {DateCell(session.createdAt)}
              </span>
            )}
          </>
        )}
      </span>
      <LoadingOutlined
        style={{ visibility: isFetching ? "visible" : "hidden" }}
      />
    </Space>
  );
};

export default DrawerTitle;
