/* eslint-disable react/display-name */
import { memo, useEffect, useState } from "react";
import { TableColumnProps, Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { compareString, checkFilterMatch, getUniqValues } from "utils/helpers";

import { IDisclosure } from "shared/types/legalLingo";
import { IndustryType } from "shared/types/shared";
import VirtualTable from "shared/components/VirtualTable";
import { SearchFilterDropdown } from "shared/components/SearchFilterDropdown";
import useSelectedColumns from "shared/hooks/userPreferences/useSelectedColumns";
import { useWindowSize } from "shared/hooks/useWindowSize";
import useResizableTable from "shared/hooks/useResizableTable";
import { defaultColumns as columnsDefault } from "shared/constants/legalLingoV2";

import ColumnsFilter from "./disclosuresTable/ColumnsFilter";
import TagsGroup from "./disclosuresTable/TagsGroup";
import {
  getValidationForDisclosureId,
  IDisclosureValidations,
} from "./utils/disclosureValidationUtils";

import "./DisclosuresTable.scss";

const VinVinlessValues = ["VIN", "VIN-less"];

type DisclosureTableColumns = TableColumnProps<IDisclosure> & {
  width: string | number;
};

interface DisclosureTableColumnProps extends DisclosureTableColumns {
  title: string;
}

interface IDisclosuresTableProps {
  disclosures: IDisclosure[];
  displayColumn: boolean;
  expand: boolean;
  clientType: IndustryType;
  selectedRowKeys: string[];
  defaultColumns?: string[];
  stickyColumns?: string[];
  validationErrors?: IDisclosureValidations[];
  isLoading: boolean;
}
interface IDisclosuresTableHandlers {
  setClientType: (clientType: IndustryType) => void;
  setDisplayColumn: (displayColumn: boolean) => void;
  setSelectedRowKeys: (selectedRowKeys: any[]) => void;
  onDisclosureSelect: (discName: string) => void;
  onEditClick?: (disclosure: IDisclosure) => void;
  onPreviewClick?: (disclosure: IDisclosure) => void;
  onDeleteClick?: (disclosure: IDisclosure) => void;
  onDuplicateClick?: (disclosure: IDisclosure) => void;
}

type PropsType = IDisclosuresTableProps & IDisclosuresTableHandlers;

type PopupStateType = {
  record?: IDisclosure;
  visible: boolean;
  x?: number;
  y?: number;
};

const DisclosuresTable = ({
  disclosures,
  expand,
  displayColumn,
  defaultColumns = columnsDefault,
  clientType,
  selectedRowKeys,
  stickyColumns,
  validationErrors,
  setClientType,
  setDisplayColumn,
  setSelectedRowKeys,
  isLoading,
  onDisclosureSelect,
}: PropsType) => {
  const { isColumnSelected, selectedColumns, setSelectedColumns } =
    useSelectedColumns(defaultColumns);
  const [popupState, setPopupState] = useState<PopupStateType>({
    visible: false,
  });
  const [locationValues, setLocations] = useState<string[] | undefined>([]);
  const [conditionValues, setConditions] = useState<string[] | undefined>([]);
  const [oemValues, setOems] = useState<string[] | undefined>([]);
  const { windowInnerHeight } = useWindowSize();

  useEffect(() => {
    const newLocations: string[] | undefined = getUniqValues(
      disclosures,
      disclosure => disclosure.location || undefined,
    );

    const newConditions: string[] | undefined = getUniqValues(
      disclosures,
      disclosure => disclosure.condition || undefined,
    );

    const newOems: string[] | undefined = getUniqValues(
      disclosures,
      disclosure => disclosure.oem || undefined,
    );

    setLocations(newLocations);
    setConditions(newConditions);
    setOems(newOems);
  }, [disclosures]);

  const optionsMap = (options: string[] | undefined) => {
    return options?.map(value => {
      return {
        text: value,
        value: value,
      };
    });
  };

  const columns: DisclosureTableColumnProps[] = [
    {
      title: "Disclosures",
      dataIndex: "name",
      render: (name: string) => {
        return name;
      },
      sorter: (a: IDisclosure, b: IDisclosure) => compareString(a.name, b.name),
      fixed: "left",
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      onFilter: (value, record) =>
        checkFilterMatch(value?.toString(), record?.name?.toString()),
      width: 180,
    },
    {
      title: "Vin / Vin-less",
      dataIndex: "vin",
      render: (vin: boolean) => <Tag>{`${vin ? "VIN" : "VINLess"}`}</Tag>,
      sorter: (a: IDisclosure, b: IDisclosure) =>
        compareString(a.vin.toString(), b.vin.toString()),
      filters: optionsMap(VinVinlessValues),
      onFilter: (value, record: IDisclosure) =>
        record.vin === (value === "VIN" ? true : false),
      width: 90,
    },
    {
      title: "Condition",
      dataIndex: "condition",
      render: (condition: string[]) => <TagsGroup tags={condition} />,
      sorter: (a: IDisclosure, b: IDisclosure) =>
        compareString(a.condition[0] ?? "", b.condition[0] ?? ""),
      filters: optionsMap(conditionValues),
      onFilter: (value, record: IDisclosure) =>
        (record.condition?.indexOf(value as string) ?? -1) !== -1,
      width: 120,
    },
    {
      title: "Location",
      dataIndex: "location",
      render: (arrString: string[]) => (
        <TagsGroup tags={arrString} width={160} collapsable />
      ),
      sorter: (a: IDisclosure, b: IDisclosure) =>
        compareString(a.location[0] ?? "", b.location[0] ?? ""),
      filters: optionsMap(locationValues),
      onFilter: (value, record: IDisclosure) =>
        (record.location?.indexOf(value as string) ?? -1) !== -1,
      width: 160,
    },
    {
      title: "OEM",
      dataIndex: "oem",
      render: (arrString: string[]) => (
        <TagsGroup tags={arrString} width={160} collapsable />
      ),
      sorter: (a: IDisclosure, b: IDisclosure) =>
        compareString(a.oem[0] ?? "", b.oem[0] ?? ""),
      filters: optionsMap(oemValues),
      onFilter: (value, record: IDisclosure) =>
        (record.oem?.indexOf(value as string) ?? -1) !== -1,
      width: 160,
    },
    {
      title: "Store",
      dataIndex: "store",
      render: (arrString: string[]) => (
        <TagsGroup tags={arrString} width={160} collapsable />
      ),
      sorter: (a: IDisclosure, b: IDisclosure) =>
        compareString(a.store[0] ?? "", b.store[0] ?? ""),
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      onFilter: (value, record) =>
        checkFilterMatch(value?.toString(), record?.store?.toString()),
      width: 160,
    },
  ];

  stickyColumns?.length &&
    columns.forEach((column: any) => {
      if (stickyColumns.includes(column.title)) {
        column.fixed = "left";
      }
    });

  const { components, resizableColumns } = useResizableTable<IDisclosure>(
    columns.map(column => ({ ...column, name: column.title })),
  );

  const filteredColumns = (resizableColumns ?? [])
    .filter(c => isColumnSelected(c.title as string))
    .sort(
      (a, b) =>
        selectedColumns.indexOf(a.title as string) -
        selectedColumns.indexOf(b.title as string),
    );

  return (
    <>
      <VirtualTable
        loading={isLoading}
        data-cy="disclosures-table-container"
        className="disclosures-table-container"
        scroll={{ y: windowInnerHeight - (expand ? 280 : 245) }}
        rowKey={record => record.name}
        pagination={false}
        size={expand ? "large" : "small"}
        rowSelection={{
          selectedRowKeys,
          onChange: (selectionRowKeys: React.ReactText[]) => {
            onDisclosureSelect(
              `${
                selectionRowKeys.length
                  ? selectionRowKeys[selectionRowKeys.length - 1]
                  : ""
              }`,
            );
            setSelectedRowKeys(selectionRowKeys);
          },
          getCheckboxProps: record => ({
            disabled:
              getValidationForDisclosureId(record.name, validationErrors ?? [])
                ?.hasErrors ?? false,
          }),
        }}
        columns={filteredColumns}
        components={components}
        dataSource={disclosures}
        onRow={record => ({
          onContextMenu: event => {
            event.preventDefault();
            document.addEventListener("click", function onClickOutside() {
              document.removeEventListener("click", onClickOutside);
            });

            if (!popupState.visible) {
              document.addEventListener("click", function onClickOutside() {
                setPopupState({ visible: false });
                document.removeEventListener("click", onClickOutside);
              });
            }
            setPopupState({
              record,
              visible: true,
              x: event.clientX,
              y: event.clientY,
            });
          },
        })}
      />
      <ColumnsFilter
        displayColumn={displayColumn}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        setDisplayColumn={setDisplayColumn}
        columnNames={columns.map(column => column.title)}
        clientType={clientType}
        setClientType={setClientType}
      />
    </>
  );
};

export default memo(DisclosuresTable);
