import { Breadcrumb, Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useCampaignPlannersStandalone } from "shared/hooks/campaignPlanner/useFetchCampaignPlanner";
import {
  CampaignPlanner,
  CampaignPlannerInstance,
} from "screens/campaignManagement/campaignPlanner/types";
import MenuLink from "./MenuLink";
import { useMemo } from "react";

type PlannerItemDropdownProps<
  T extends CampaignPlanner | CampaignPlannerInstance,
> = {
  items: T[];
  selectedItem: string;
  getUrl: (item: T) => string;
};

const PlannerItemDropdown = <
  T extends CampaignPlanner | CampaignPlannerInstance,
>({
  items,
  selectedItem,
  getUrl,
}: PlannerItemDropdownProps<T>) => {
  return (
    <Menu
      selectedKeys={[selectedItem]}
      style={{ overflow: "auto", maxHeight: "70vh" }}
    >
      {items?.map(item => (
        <Menu.Item key={item.id}>
          <MenuLink to={getUrl(item)}>{item.name}</MenuLink>
        </Menu.Item>
      ))}
    </Menu>
  );
};

export const CampaignPlannerBreadcrumb = () => {
  const { data: planners } = useCampaignPlannersStandalone();
  const location = useLocation();
  const navigate = useNavigate();

  const plannerId = location.pathname.split("/")[3];
  const instanceId = location.pathname.split("/")[5];

  const currentPlanner = useMemo(
    () => planners.find(planner => planner.id == plannerId),
    [planners, plannerId],
  );

  const currentInstance = useMemo(
    () => currentPlanner?.instances.find(instance => instance.id == instanceId),
    [currentPlanner, instanceId],
  );

  if (!currentPlanner || !planners) return null;
  return (
    <>
      <div style={{ whiteSpace: "nowrap" }}>
        <Breadcrumb.Item
          onClick={() => {
            if (currentInstance) {
              navigate(
                `/campaign-management/campaign-planner/${currentPlanner.id}`,
              );
            }
          }}
          overlay={
            <PlannerItemDropdown
              items={planners}
              getUrl={item =>
                `/campaign-management/campaign-planner/${item.id}`
              }
              selectedItem={plannerId}
            />
          }
        >
          <span
            style={{
              cursor: "default",
              color: !currentInstance ? "black" : undefined,
            }}
          >
            {currentPlanner?.name}
          </span>
        </Breadcrumb.Item>
      </div>
      {currentPlanner?.instances.length > 0 && (
        <Breadcrumb.Item
          overlay={
            <PlannerItemDropdown
              items={currentPlanner.instances}
              getUrl={item =>
                `/campaign-management/campaign-planner/${plannerId}/instance/${item.id}`
              }
              selectedItem={instanceId}
            />
          }
        >
          <span
            style={{
              cursor: "default",
              opacity: currentInstance?.name ? 1 : "0.5",
            }}
          >
            {currentInstance?.name ?? "Select planner instance"}
          </span>
        </Breadcrumb.Item>
      )}
    </>
  );
};
