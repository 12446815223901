import { MediaThumbHover } from "../../MediaThumbHover";
import { useColumn } from "../useColumn";

export const useColImage = (size = 40) => {
  return useColumn("thumbnailurls", {
    title: "Image",
    width: size + 16,
    renderFn(text: string, record: WDAsset) {
      return <MediaThumbHover asset={record} size={size} />;
    },
  });
};
