import { memo, ReactElement } from "react";
import {
  InstantExperienceBodyElement,
  IProductSetElement,
} from "shared/types/adLibrary";
import uuid from "uuid";
import ProductSetComponentForm, {
  IProductSetComponentForm,
} from "./productSetComponent/ProductSetComponentForm";

interface IProps {
  instantExperienceElement: IProductSetElement;
  updateElement(instantExperienceElement: InstantExperienceBodyElement): void;
}

const defaultProductSetValues: Omit<IProductSetElement, "id"> = {
  element_type: "ELEMENT_GROUP",
  name: "Product Set",
  item_headline: "",
  item_description: "",
  max_products: 50,
};

export const getDefaultProductSetValues = (): IProductSetElement => ({
  ...defaultProductSetValues,
  id: uuid(),
});

function ProductSetComponent({
  instantExperienceElement,
  updateElement,
}: IProps): ReactElement {
  const initialValues: IProductSetComponentForm = {
    maxProducts: instantExperienceElement.max_products,
    itemDescription: instantExperienceElement.item_description,
    itemHeadline: instantExperienceElement.item_headline,
  };

  function getInstantExperienceElement(
    values: IProductSetComponentForm,
  ): IProductSetElement {
    return {
      id: instantExperienceElement.id,
      name: instantExperienceElement.name,
      element_type: "ELEMENT_GROUP",
      max_products: values.maxProducts,
      item_headline: values.itemHeadline,
      item_description: values.itemDescription,
    };
  }

  return (
    <ProductSetComponentForm
      initialValues={initialValues}
      onValuesChange={values => {
        if (!values) {
          return;
        }
        updateElement(getInstantExperienceElement(values));
      }}
    />
  );
}

export default memo(ProductSetComponent);
