import { message } from "antd";

export const handleApiError = (error: any): void => {
  let text = "Something went wrong";

  try {
    if (typeof error?.data?.message === "string") {
      text = error.data.message;
    } else if (Array.isArray(error?.data)) {
      text = error.data[0].message;
    }
  } catch {
    console.log("Unknown error structure", error);
  }

  message.error(text);
};
