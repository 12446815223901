import { useCallback } from "react";
import { LOCAL_STORAGE_KEY } from "./constants";

type StorageObj = MGTokenObj | null;

export const useMediaStorage = (): [
  () => StorageObj,
  (v: StorageObj) => void,
] => {
  const getState = () => {
    try {
      const item = localStorage.getItem(LOCAL_STORAGE_KEY);

      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.log("Could not get localStorage for MG", error);
      return null;
    }
  };

  const setState = useCallback((value: StorageObj): void => {
    try {
      if (value === null) {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
      } else {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(value));
      }
    } catch (error) {
      console.log("Could not set localStorage for MG", value, error);
    }
  }, []);

  return [getState, setState];
};
