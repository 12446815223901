const convertCommaInc = (text: string) => {
  return text.replaceAll(", Inc", " Inc");
};

const revertCommaInc = (inputs: string[]) => {
  return inputs.map(account => account.replace(" Inc", ", Inc"));
};

export const splitCsvWithException = (csv: string) => {
  return revertCommaInc(convertCommaInc(csv || "").split(","));
};
