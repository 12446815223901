import { FC, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import actions from "../redux/rootActions";

import Header from "../shared/components/Header";
import GenericError from "../shared/errors/GenericError";
import {
  FetchingDataStatus,
  HeaderMenu,
  ILegalLingoReduxState,
  IStateDisclosureElement,
  IStateDisclosureRecord,
  IStateExceptionRecord,
  TDisclosureDataType,
} from "../shared/types/legalLingo";

import { CloseOutlined } from "@ant-design/icons";

import { Button, message, Modal, Spin } from "antd";
import { IHeader } from "shared/types/header";
import LegalDisclosures from "./legalLingo/LegalDisclosures";
import LegalMessaging from "./legalLingo/LegalMessaging";
import LegalVariables from "./legalLingo/LegalVariables";
import { useDatadog } from "shared/hooks/useDatadog";
import { User } from "redux/auth/auth.slice";

message.config({ maxCount: 1 });

interface ILegalLingo {
  loggedInUser: User;
  error: GenericError | null;
  creatingDataStatus: string;
  createStateDisclosure: (stateDisclosure: IStateDisclosureRecord) => void;
  fetchingData: FetchingDataStatus;
  getStateDisclosures: (state?: string) => void;
  stateDisclosures: IStateDisclosureRecord[];
  resetCreateData: () => void;
  stateExceptionRecordsInForm: IStateExceptionRecord[];
}

const LegalLingo: FC<ILegalLingo> = ({
  error,
  creatingDataStatus,
  createStateDisclosure,
  fetchingData,
  getStateDisclosures,
  stateDisclosures,
  resetCreateData,
}) => {
  const [selectedHeader] = useState<HeaderMenu>("DISCLOSURES");
  const [selectedStateTab, setSelectedStateTab] = useState<string>("ak");
  const [disclosuresChangesWereMade, toggleDisclosuresChangesWereMade] =
    useState<boolean>(false);
  const [exceptionsChangesWereMade, toggleExceptionsChangesWereMade] =
    useState<boolean>(false);
  const [currentDisclosuresByState, setCurrentDisclosuresByState] = useState<
    IStateDisclosureRecord[]
  >([]);
  const [shouldFireSaveException, setShouldFireSaveExceptions] =
    useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [pathToPush, setPathToPush] = useState<string>("");

  const navigate = useNavigate();

  // TODO: rewrite this in the future
  // history.listen(({ pathname }) => {
  //   setPathToPush(pathname);
  // });

  /*
    history.block() returns a type of UnregisterCallback,
    which cannot be used, so any was applied
  */
  // const unblockHandle = useRef<any>();

  const toggleChangesWereMade = (
    changesWereMade: boolean,
    dataType?: TDisclosureDataType,
  ) => {
    if (dataType === "exception") {
      toggleExceptionsChangesWereMade(changesWereMade);
    } else {
      toggleDisclosuresChangesWereMade(changesWereMade);
    }
  };

  const finalizeSave = () => {
    // if (unblockHandle) {
    //   unblockHandle.current();
    // }
    // TODO: derive value for path to push
    navigate(pathToPush);
  };

  useDatadog();

  // TODO: figure out alternate way to block - beforeUnload etc, backdrop etc
  // useEffect(() => {
  //   unblockHandle.current = history.block(({ pathname }) => {
  //     if (!disclosuresChangesWereMade && !exceptionsChangesWereMade) {
  //       return;
  //     }
  //     setPathToPush(pathname);
  //     setIsModalOpen(true);
  //     return false;
  //   });
  //   return () => {
  //     // tslint:disable-next-line: no-unused-expression
  //     unblockHandle.current.current && unblockHandle.current.current();
  //   };
  // });

  useEffect(() => {
    if (selectedHeader === "DISCLOSURES") {
      getStateDisclosures(selectedStateTab);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedHeader === "DISCLOSURES") {
      if (error) {
        message.error(error.message);
      }
      if (creatingDataStatus === "complete_disclosure") {
        message.success(
          `Changes for ${selectedStateTab.toUpperCase()} saved successfully`,
        );
        if (pathToPush) {
          // the timeout allows the user to see the message ^^^
          setTimeout(() => {
            finalizeSave();
          }, 500);

          // reset status so that messages don't repeat
          resetCreateData();
        } else {
          getStateDisclosures(selectedStateTab);
        }
      }
    }

    // eslint-disable-next-line
  }, [error, selectedHeader, creatingDataStatus]);

  const foundStateDisclosureObj = currentDisclosuresByState.find(
    stateDisclosureObj =>
      stateDisclosureObj &&
      stateDisclosureObj.state === selectedStateTab.toUpperCase(),
  ) ||
    stateDisclosures.find(
      stateDisclosureObj =>
        stateDisclosureObj &&
        stateDisclosureObj.state === selectedStateTab.toUpperCase(),
    ) || { state: selectedStateTab.toUpperCase(), disclosures: [] };

  const headerMenus: IHeader = {
    style: {
      display: "flex",
      alignItem: "center",
      flex: "initial",
      width: "35em",
    },
    topSteps: {
      selected: selectedHeader,
      style: {
        flex: 2,
      },
      steps: [
        {
          title: "VARIABLES",
          onClick: () => {
            navigate(`/legal-lingo/variables`);
          },
          state: "enabled",
        },
        { title: "DISCLOSURES" },
        {
          title: "MESSAGING",
          onClick: () => {
            navigate(`/legal-lingo/messaging`);
          },
          state: "enabled",
        },
      ],
    },

    middleSteps: [],
    actionButtons: [
      <Button
        className="action-button"
        disabled={creatingDataStatus === "disclosure"}
        onClick={() => {
          if (selectedHeader === "DISCLOSURES") {
            toggleDisclosuresChangesWereMade(false);
            saveStateDisclosure();
          }
        }}
        key="save-button"
      >
        Save
        {disclosuresChangesWereMade || exceptionsChangesWereMade ? "*" : ""}
      </Button>,
    ],
  };

  const insertStateDisclosureObjectIntoState = (
    state: string,
    disclosures: IStateDisclosureElement[],
  ) => {
    const filteredDisclosuresByState = currentDisclosuresByState.filter(
      disclosuresByStateObj => disclosuresByStateObj.state !== state,
    );
    toggleDisclosuresChangesWereMade(true);
    setCurrentDisclosuresByState([
      ...filteredDisclosuresByState,
      { state, disclosures },
    ]);
  };

  const saveStateDisclosure = () => {
    const stateDisclosuresRecordToSave = currentDisclosuresByState.find(
      disclosuresByStateObj =>
        disclosuresByStateObj.state === selectedStateTab.toUpperCase(),
    );
    if (!stateDisclosuresRecordToSave) {
      message.warning("No disclosures have been altered for this state");
    } else {
      message.info("Saving location disclosures...");
      // having a timeout gives a more natural feel to the messages
      setTimeout(() => {
        const stateDisclosureWithTrimmedText = {
          state: stateDisclosuresRecordToSave.state,
          disclosures: stateDisclosuresRecordToSave.disclosures.map(
            disclosure => ({ ...disclosure, text: disclosure.text.trim() }),
          ),
        } as IStateDisclosureRecord;
        createStateDisclosure(stateDisclosureWithTrimmedText);
      }, 750);
    }

    setShouldFireSaveExceptions(true);
  };

  return (
    <div className="legal-lingo-root-container">
      <Modal
        className="confirmation-modal"
        closeIcon={<CloseOutlined />}
        visible={isModalOpen}
        title={`Save or Discard Changes`}
        maskClosable={true}
        onCancel={() => {
          setIsModalOpen(false);
          setPathToPush("");
        }}
        closable={true}
        footer={[
          [
            <Button
              key="discard-button"
              onClick={() => {
                setIsModalOpen(false);
                window.location.assign(
                  `${window.location.origin}${pathToPush}`,
                );
              }}
            >
              Discard
            </Button>,
            <Button
              key="save-button"
              type="primary"
              onClick={() => {
                setIsModalOpen(false);
                saveStateDisclosure();
              }}
            >
              Save
            </Button>,
          ],
        ]}
      >
        <p>Changes have been made. Would you like to save or discard them?</p>
      </Modal>

      <Header
        style={headerMenus.style}
        topSteps={headerMenus.topSteps}
        actionButtons={headerMenus.actionButtons}
      />

      <div>
        {selectedHeader === "VARIABLES" && <LegalVariables />}
        {selectedHeader === "DISCLOSURES" && (
          <Spin
            spinning={
              creatingDataStatus === "disclosure" ||
              creatingDataStatus === "exception" ||
              fetchingData === "stateDisclosures" ||
              fetchingData === "stateExceptions"
            }
            size="large"
          >
            <LegalDisclosures
              selectedStateTab={selectedStateTab}
              setSelectedStateTab={stateTab => {
                setSelectedStateTab(stateTab);
                getStateDisclosures(stateTab);
              }}
              originalStateDisclosureObj={foundStateDisclosureObj}
              setStateDisclosuresObjByStateParent={
                insertStateDisclosureObjectIntoState
              }
              exceptionsChangesWereMade={exceptionsChangesWereMade}
              toggleChangesWereMade={toggleChangesWereMade}
              shouldFireSaveException={shouldFireSaveException}
              setShouldFireSaveExceptions={setShouldFireSaveExceptions}
            />
          </Spin>
        )}
        {selectedHeader === "MESSAGING" && <LegalMessaging />}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const { legalLingo, auth } = state;
  const {
    error,
    creatingDataStatus,
    stateDisclosures,
    fetchingData,
    stateExceptionRecordsInForm,
  } = legalLingo as ILegalLingoReduxState;
  return {
    loggedInUser: auth.user,
    error,
    creatingDataStatus,
    fetchingData,
    stateDisclosures,
    stateExceptionRecordsInForm,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    resetCreateData: () => {
      dispatch(actions.legalLingo.resetCreateData());
    },
    createStateDisclosure: (stateDisclosure: IStateDisclosureRecord) => {
      dispatch(actions.legalLingo.createStateDisclosure(stateDisclosure));
    },
    getStateDisclosures: (state = "") => {
      const query = state
        ? `state=${encodeURIComponent(state.toLowerCase())}`
        : "";
      dispatch(actions.legalLingo.fetchData("stateDisclosures", query));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LegalLingo);
