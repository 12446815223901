import API from "services";
import { useQuery } from "react-query";
import { QueryHookParams } from "../types";

export const useGoogleOAuthConsentUrl = ({
  onSuccess,
  onError,
}: QueryHookParams) => {
  const getGoogleOAuthConsentUrl = () => async () => {
    const { result, error } =
      await API.services.platformManagement.getGoogleOAuthConsentUrl();

    if (error || !result) {
      throw Error(error?.message || "Unknown error");
    }

    return result;
  };

  return useQuery<string, Error>(
    ["google-oauth-consent-url"],
    getGoogleOAuthConsentUrl(),
    {
      enabled: false,
      onSuccess,
      onError,
    },
  );
};
