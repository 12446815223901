import { memo } from "react";
import { Menu } from "antd";
import { IMenu, ISubMenu } from "shared/types/menu";
import MenuLink from "./MenuLink";
import { useAuthenticated } from "shared/hooks/useAuthenticated";

interface IProps {
  selectedMenu: IMenu;
  selectedSubMenu: ISubMenu;
  setCollapsed?: (value: boolean) => void;
}

const SubmenusOverlay = ({ selectedMenu, selectedSubMenu }: IProps) => {
  const authenticated = useAuthenticated();

  const selectedSubMenuKey = selectedSubMenu?.header
    ? [selectedSubMenu?.header]
    : [];

  return (
    <Menu selectedKeys={selectedSubMenuKey}>
      {selectedMenu.subMenus?.map(submenu => (
        <Menu.Item key={submenu.header} disabled={!authenticated}>
          <MenuLink to={submenu.path || ""}>{submenu.header}</MenuLink>
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default memo(SubmenusOverlay);
