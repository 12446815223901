import { useCallback } from "react";
import { useAssetBatchesValueMappingContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesValueMappingContext";

export const useHightlightVarEvents = (mappingKey: string) => {
  const { highlightVariable } = useAssetBatchesValueMappingContext();

  const onMouseEnter: React.MouseEventHandler = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      highlightVariable("enter", mappingKey);
    },
    [highlightVariable, mappingKey],
  );

  const onMouseLeave: React.MouseEventHandler = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      highlightVariable("leave", mappingKey);
    },
    [highlightVariable, mappingKey],
  );

  const onFocus = useCallback(
    (mKey: string) => {
      highlightVariable("focus", mKey);
    },
    [highlightVariable],
  );

  const onBlur = useCallback(
    (mKey: string) => {
      highlightVariable("blur", mKey);
    },
    [highlightVariable],
  );

  const onValueChange = useCallback(
    (mKey: string, emptyValue: boolean) => {
      if (emptyValue) highlightVariable("emptyValue", mKey);
      else highlightVariable("fillValue", mKey);
    },
    [highlightVariable],
  );

  return {
    onMouseEnter,
    onMouseLeave,
    onFocus,
    onBlur,
    onValueChange,
  };
};
