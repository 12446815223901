import {
  IAd,
  IButtonElement,
  ICarouselElement,
  IFooterElement,
  IInstantExperience,
  InstantExperienceBodyElement,
  IPhotoElement,
  IProductSetElement,
  IVideoElement,
} from "shared/types/adLibrary";

export const isAd = (ad: unknown): ad is IAd => {
  if (!ad || typeof ad !== "object") return false;

  const keys = Object.keys(ad);
  const checkingKeys: Array<keyof IAd> = ["visuals", "inputParameters"];
  return checkingKeys.every(checkingKey => keys.includes(checkingKey));
};

export const isInstantExp = (data: unknown): data is IInstantExperience => {
  if (!data || typeof data !== "object") return false;

  const keysToCheck = ["body_elements", "swipeToOpen"];
  return Object.keys(data).some(key => keysToCheck.includes(key));
};

export const isButton = (
  ele: InstantExperienceBodyElement,
): ele is IButtonElement => ele?.element_type === "BUTTON";

export const isFooter = (
  ele: InstantExperienceBodyElement,
): ele is IFooterElement => ele?.element_type === "FOOTER";

export const isImage = (
  ele: InstantExperienceBodyElement,
): ele is IPhotoElement => ele?.element_type === "PHOTO";

export const isVideo = (
  ele: InstantExperienceBodyElement,
): ele is IVideoElement => ele?.element_type === "VIDEO";

export const isProductSet = (
  ele: InstantExperienceBodyElement,
): ele is IProductSetElement => ele?.element_type === "ELEMENT_GROUP";

export const isCarousel = (
  ele: InstantExperienceBodyElement,
): ele is ICarouselElement => ele?.element_type === "CAROUSEL";
