import { IConfig } from "shared/types/configuration";
import { IBrand } from "shared/types/brandManagement";
import HttpClient from "./httpClient";
import { IApiResponse } from "shared/types/shared";

export default ({ config }: { headers: any; config: IConfig }) => ({
  createOem: <T>(inputOem: IBrand) =>
    HttpClient.post<IApiResponse<T>>(config.services.createOemUrl, {
      oem: inputOem,
    }),

  getOems: <T>(paginationToken: string = ""): Promise<T> => {
    const { services } = config;

    let getOemsUrl = services.getOemsUrl;

    if (paginationToken) {
      getOemsUrl = getOemsUrl.concat(`?paginationToken=${paginationToken}`);
    }

    return HttpClient.get(getOemsUrl);
  },

  getOemsByName: <T>(names: string[]): Promise<T> => {
    const { services } = config;

    const url = `${services.getOemsByNameUrl}?names=${names
      .map(name => encodeURIComponent(name))
      .join(",")}`;

    return HttpClient.get(url);
  },

  getOem: <T>(name: string): Promise<T> => {
    const { services } = config;

    const getOemUrl = services.getOemUrl.concat(`?name=${name}`);

    return HttpClient.get(getOemUrl);
  },

  updateOem: <T>(
    inputOem: IBrand,
    logosToRemove?: string[],
    fontsToRemove?: string[],
  ) =>
    HttpClient.put<IApiResponse<T>>(config.services.updateOemUrl, {
      oem: inputOem,
      logosToRemove,
      fontsToRemove,
    }),
  deleteOem: <T>(inputOem: IBrand): Promise<T> => {
    const { services } = config;

    return HttpClient.delete(services.deleteOemUrl, {
      oem: inputOem,
    });
  },
});
