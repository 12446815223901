import { useQuery } from "react-query";
import API from "services";
import { SmartCol } from "shared/types/assetExporter";

const getSmartCols = async (feedId: string) => {
  const errMsg = "Unable to get smart columns";
  try {
    const { result, error } = await API.services.assetExporter.getSmartColumns(
      feedId,
    );
    if (!result) throw new Error(errMsg);
    if (!!error) throw new Error(error.message);
    return result.smartColumns;
  } catch (error) {
    throw new Error(errMsg);
  }
};

export const useGetSmartCols = (feedId: string) => {
  return useQuery<SmartCol[], Error>(
    ["smartCols", feedId],
    () => getSmartCols(feedId),
    {
      enabled: !!feedId,
    },
  );
};
