import { ColumnProps } from "antd/lib/table";
import { ColumnFilterItem } from "antd/lib/table/interface";
import { get } from "object-path";
import { ReactNode } from "react";
import { useMetaSchema } from "../useMetaSchema";

export interface Options<T> {
  width?: number | string;
  filter?: [string];
  filters?: [string, ColumnFilterItem[]];
  filterMultiple?: boolean;
  sorter?: boolean;
  editable?: boolean;
  ellipsis?: boolean;
  align?: "left" | "right" | "center";
  fixed?: "left" | "right";
  title?: string;
  renderFn?: (text: any, record: T, index?: number) => ReactNode;
  onCell?: (record: T) => any;
}

export function createColum<T = any>(
  key: string,
  options: Options<T> = {},
  { getLabel }: ReturnType<typeof useMetaSchema>,
): ColumnProps<T> {
  const [dataIndex, ...path] = key.split(".");
  const { renderFn, filters, filter, title } = options;
  const [columnKey] = filter || filters || [key];
  const [, columnFilters] = filters || [];
  const fullPath = path.join(".");

  return {
    ...options,
    dataIndex,
    title: title ?? getLabel(fullPath),
    key: columnKey,
    filters: columnFilters,
    render(value: any, record: T) {
      const text = path ? get(value, fullPath) ?? "" : value;
      return renderFn ? renderFn(text, record) : text;
    },
  };
}
