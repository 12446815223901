import { useState, useEffect } from "react";
import { Alert } from "antd";

import classNames from "classnames";
import actions from "../../redux/rootActions";

import styles from "./Layout.module.scss";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import useFetchClientVersion from "shared/hooks/useFetchClientVersion";

const NewVersionAlert = () => {
  const dispatch = useAppDispatch();
  const [alertDismissed, setAlertDismissed] = useState(false);

  const { updateAvailable } = useFetchClientVersion();

  useEffect(() => {
    dispatch(actions.configuration.setConfiguration());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Alert
      className={classNames({
        [styles.notification]: true,
        [styles.hiddenNewVersionAlert]: !updateAvailable || alertDismissed,
      })}
      message={
        <div
          onClick={e => {
            e.preventDefault();
            setAlertDismissed(true);
            window.location.reload();
          }}
        >
          A new version is available! Click here to refresh.
        </div>
      }
      banner={true}
      closable={true}
    />
  );
};

export default NewVersionAlert;
