import { Button, Tag } from "antd";

export type TagType<KeyType extends string = string> = {
  key: KeyType;
  value: React.Key | boolean;
  displayValue?: string;
};

type TagListProps<KeyType extends string> = {
  tags: Array<TagType<KeyType>>;
  onClear: (tableId?: string) => void;
  onRemove: (
    key: KeyType,
    value: React.Key | boolean,
    tableId?: string,
  ) => void;
  tableId?: string;
};

const TagList = <KeyType extends string>(props: TagListProps<KeyType>) => {
  const { tags, onClear, onRemove, tableId } = props;

  return (
    <div data-cy="filter-tags">
      {tags.map(({ key, value, displayValue }) => {
        return (
          <Tag
            closable
            color="blue"
            key={`${value}-${key}`}
            title={`${key}: ${displayValue || value}`}
            onClose={() => onRemove(key, value, tableId)}
          >
            {displayValue || value}
          </Tag>
        );
      })}
      {tags.length > 1 ? (
        <Button type="link" size="small" onClick={() => onClear(tableId)}>
          Clear all
        </Button>
      ) : null}
    </div>
  );
};

export default TagList;
