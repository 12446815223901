import ImageUploaderBatch from "shared/components/ImageUploaderBatch";
import {
  ISingleLogoObject,
  TUploadSetLogosInDictionary,
  TUploadSteps,
} from "shared/types/uploadManagement";
import { deleteAttachedLogoFile } from "./utils";
import { useIsAdmin } from "shared/hooks/useIsAdmin";

interface Props {
  s3AssetBucket: string;
  singleLogoObject: ISingleLogoObject;
  setSingleLogoObject: (singleLogoObject: ISingleLogoObject) => void;
  logosInDictionary: Record<TUploadSetLogosInDictionary, ISingleLogoObject[]>;
  setLogosInDictionary: (
    logosInDictionary: Record<TUploadSetLogosInDictionary, ISingleLogoObject[]>,
  ) => void;
  step: string;
}

const DefaultStep = ({
  s3AssetBucket,
  singleLogoObject,
  setSingleLogoObject,
  logosInDictionary,
  setLogosInDictionary,
  step,
}: Props) => {
  const isAdmin = useIsAdmin();

  const renderLogo = (logo: ISingleLogoObject, index: number) => (
    <div key={`${step}${index}`} style={{ display: "flex" }}>
      <div>{logo[`${step.toLowerCase()}` as TUploadSteps]?.filename || ""}</div>
      <span
        onClick={() =>
          deleteAttachedLogoFile({
            logo,
            group: `${step.toLowerCase()}Logos`,
            step: step.toLowerCase(),
            logosInDictionary,
            setLogosInDictionary,
          })
        }
        style={{
          paddingLeft: "4px",
          cursor: "pointer",
        }}
      >
        x
      </span>
    </div>
  );

  return (
    <div>
      <ImageUploaderBatch
        title={step}
        s3AssetBucket={s3AssetBucket}
        singleLogoObject={singleLogoObject}
        setSingleLogoObject={setSingleLogoObject}
        disabled={!isAdmin}
      />
      <div style={{ paddingTop: 8, paddingLeft: 20 }}>
        {logosInDictionary[
          `${step.toLowerCase()}Logos` as TUploadSetLogosInDictionary
        ].map(renderLogo)}
      </div>
    </div>
  );
};

export default DefaultStep;
