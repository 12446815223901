import { ReactElement, useCallback, useMemo } from "react";
import {
  IInstantExperience,
  InstantExperienceBodyElement,
} from "shared/types/adLibrary";
import LivePreview from "screens/designStudio/editor/instantExperience/LivePreview";
import { last } from "lodash";
import { AdType } from "screens/adLibrary/facebookUtils/types";
import EverythingAdNavigationButtons from "screens/adLibrary/adWizard/shared/EverythingAdNavigationButtons";
import { useNavigate } from "react-router-dom";
import styles from "./LivePreviewHandler.module.scss";
import classNames from "classnames";

interface IProps {
  adType: AdType;
  selectedInstantExperienceIdsPath: string[];
  setSelectedInstantExperienceIdsPath: (newPath: string[]) => void;
  instantExperienceId: string;
  instantExperiences: IInstantExperience[] | undefined;
  getRootElementForCollectionAd?: () => InstantExperienceBodyElement;
  children?: ReactElement;
}

function LivePreviewHandler({
  adType,
  selectedInstantExperienceIdsPath,
  setSelectedInstantExperienceIdsPath,
  instantExperienceId,
  instantExperiences,
  getRootElementForCollectionAd,
  children,
}: IProps) {
  const navigate = useNavigate();

  const getSelectedInstantExperience = useCallback(
    (
      selectedInstantExperienceIdsPath: string[],
      instantExperiences: IInstantExperience[] | undefined,
      instantExperienceId: string,
      adType: AdType,
    ): IInstantExperience | null | undefined => {
      const lastSelectedInstantExperienceId = last(
        selectedInstantExperienceIdsPath,
      );

      const selectedInstantExperience = lastSelectedInstantExperienceId
        ? instantExperiences?.find(
            ie => ie.id === lastSelectedInstantExperienceId,
          )
        : null;

      const isCollectionAd = adType === AdType.Collection;
      const isTheRootInstantExperience =
        lastSelectedInstantExperienceId === instantExperienceId;

      if (
        !isCollectionAd ||
        !isTheRootInstantExperience ||
        !getRootElementForCollectionAd
      )
        return selectedInstantExperience;

      // Add media as first element for collection ads root instant experience
      return {
        ...selectedInstantExperience,
        body_elements: [
          getRootElementForCollectionAd(),
          ...(selectedInstantExperience?.body_elements ?? []),
        ],
      };
    },
    [getRootElementForCollectionAd],
  );

  const handleAdPreviewClick = () => {
    setSelectedInstantExperienceIdsPath([instantExperienceId]);
  };

  const handleBackInstantExperience = () => {
    setSelectedInstantExperienceIdsPath(
      selectedInstantExperienceIdsPath.slice(0, -1),
    );
  };

  const handleSelectInstantExperienceId = (instantExperienceId: string) => {
    setSelectedInstantExperienceIdsPath(
      selectedInstantExperienceIdsPath.concat(instantExperienceId),
    );
  };

  const handleRestart = () => {
    setSelectedInstantExperienceIdsPath([]);
  };

  const hadnleOpenInEverythingAds = () => {
    navigate(
      "/everything-ads/ads/:instantExperienceId/edit".replace(
        ":instantExperienceId",
        selectedInstantExperience?.id ?? "",
      ),
    );
  };

  const selectedInstantExperience = useMemo(
    () =>
      getSelectedInstantExperience(
        selectedInstantExperienceIdsPath,
        instantExperiences,
        instantExperienceId,
        adType,
      ),
    [
      adType,
      getSelectedInstantExperience,
      instantExperienceId,
      instantExperiences,
      selectedInstantExperienceIdsPath,
    ],
  );

  const displayOpenInEverythingAdsButton = !location.pathname.includes(
    "/campaign-management/ad-review",
  );

  return (
    <div
      className={classNames(styles.previewHandlerContainer, {
        [styles.previewHandlerContainerWithoutChildren]: !children,
      })}
    >
      {[AdType.InstantExperience, AdType.Collection].includes(adType) &&
        children && (
          <EverythingAdNavigationButtons
            handleBack={handleBackInstantExperience}
            instantExperienceId={instantExperienceId}
            selectedInstantExperienceIdsPath={selectedInstantExperienceIdsPath}
            handleRestart={handleRestart}
            handleOpenInEverythingAds={hadnleOpenInEverythingAds}
            handleShowEverythingAd={handleAdPreviewClick}
            displayOpenInEverythingAdsButton={displayOpenInEverythingAdsButton}
          />
        )}
      {selectedInstantExperience ? (
        <>
          <LivePreview
            instantExperience={selectedInstantExperience}
            handleSelectInstantExperienceId={handleSelectInstantExperienceId}
            handleBack={handleBackInstantExperience}
          />
        </>
      ) : (
        <div onClick={handleAdPreviewClick} style={{ cursor: "pointer" }}>
          {children}
        </div>
      )}
    </div>
  );
}

export default LivePreviewHandler;
