import { dedupe } from "utils/helpers.array";
import { useFetchInfiniteDealers } from "./useFetchDealers";
import { useMemo } from "react";

/**
 * Hook to get all labels that are used in at least one store
 * @returns The list of labels from all stores
 */
export const useStoreLabels = () => {
  const { dealers = [], isLoading } = useFetchInfiniteDealers();

  const allStoreLabels = useMemo(
    () => dealers.flatMap(dealer => dealer.labels ?? []),
    [dealers],
  );

  const urlLabels = useMemo(
    () =>
      dealers
        .flatMap(dealer => dealer.labels ?? [])
        .map(label => label.name)
        .filter(dedupe),
    [dealers],
  );

  return { urlLabels, allStoreLabels, isLoading };
};
