import { ReactComponent as SwitchIndeterminateIcon } from "statics/images/svg/icons/switch-indeterminate-o.svg";
import { IconProps } from "./types";

const SwitchIndeterminate = ({ width = 44, height = 22, style }: IconProps) => {
  return (
    <span
      role="img"
      aria-label="switch"
      className="anticon"
      style={{
        ...(style ?? {}),
        fill: style?.fill || "white",
      }}
    >
      <SwitchIndeterminateIcon width={width} height={height} />
    </span>
  );
};

export default SwitchIndeterminate;
