import { Typography } from "antd";
import styles from "./PlatformManagement.module.scss";
import FacebookIntegrationCard from "./platformManagement/FacebookIntegrationCard";
import { GoogleAdsIntegrationCard } from "./platformManagement/GoogleAdsIntegrationCard";
import { envVarCsvContains } from "utils/helpers";

const PlatformManagement = () => {
  const metaEnabled = envVarCsvContains("ENABLE_PLATFORM_MANAGEMENT", "meta");
  const googleEnabled = envVarCsvContains(
    "ENABLE_PLATFORM_MANAGEMENT",
    "google",
  );
  const enabled = metaEnabled || googleEnabled;
  return (
    <div className={styles.screen}>
      <div className={styles.container}>
        <div className={styles.description}>
          <Typography.Title
            level={4}
            style={{ fontWeight: "bold", textAlign: "center" }}
          >
            {enabled ? "Available Integrations" : "No Integrations Enabled"}
          </Typography.Title>
          {enabled && (
            <Typography.Title level={5} type="secondary">
              Explore the available platforms to manage your account
            </Typography.Title>
          )}
        </div>
        <div className={styles.cards}>
          {metaEnabled && <FacebookIntegrationCard />}
          {googleEnabled && <GoogleAdsIntegrationCard />}
        </div>
      </div>
    </div>
  );
};

export default PlatformManagement;
