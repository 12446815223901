import {
  CarOutlined,
  ControlOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditFilled,
  InboxOutlined,
} from "@ant-design/icons";
import { Modal, Space, Tooltip, Typography } from "antd";
import { memo } from "react";

import { Link, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import actions from "redux/rootActions";
import { useAppDispatch } from "shared/hooks/useAppDispatch";

import Card from "shared/components/Card";

import { orderBy } from "lodash";
import { offerTypes } from "shared/constants/dataManagement";
import { roundTimeStamp } from "utils/helpers";

import { ICardButtonObj } from "shared/types/card";
import {
  HeaderMenu,
  IDesignStudioState,
  IStamp,
} from "shared/types/designStudio";

import { useIsAdmin } from "shared/hooks/useIsAdmin";
import "./StampCard.scss";

interface ICard {
  stamp: IStamp; // this has to be list of stamps... for each offer type
  selectedHeader: HeaderMenu;
}

type PopoverMenuItemType =
  | "edit"
  | "duplicate"
  | "delete"
  | "publish"
  | "archive"; // this type is defined within the component since it will be local type

const StampCard: React.FC<ICard> = ({ stamp, selectedHeader }) => {
  const isAdmin = useIsAdmin();

  const accessDeniedMessage =
    "This feature is disabled for this account. Contact your team lead to request access.";

  const { deletingDataId } = useSelector(
    ({ designStudio }: { designStudio: IDesignStudioState }) => ({
      deletingDataId: designStudio.deletingDataId,
    }),
  );

  const dispatch = useAppDispatch();
  const { setStampToUpdate, duplicateStamp, updateStamp, deleteStamp } =
    actions.designStudio;

  const MINUTE = 1000 * 60;

  const { search } = useLocation();

  const onPopoverMenuClick =
    (type: PopoverMenuItemType, disable: boolean) =>
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      event.preventDefault();

      if (disable) return;

      switch (type) {
        case "edit":
          // NOTE that is will update all stamp with same id.
          // So if there is 7 different offer types, even though we are sending the first stamp in stamps
          // it updates all of them.
          dispatch(setStampToUpdate(stamp));

          break;

        case "duplicate":
          Modal.confirm({
            title: "Are you sure you want to duplicate?",
            content: (
              <div>
                Are you sure you want to duplicate
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                    marginLeft: "1em",
                  }}
                >
                  {stamp.name}
                </span>
                ?
              </div>
            ),

            onOk: () => {
              dispatch(
                duplicateStamp({
                  ...stamp,
                  name: `${stamp.name}-Copy`,
                } as IStamp),
              );
            },
          });
          break;

        case "archive":
          Modal.confirm({
            title: "Are you sure you want to archive?",
            content: (
              <div>
                Are you sure you want to archive?
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                    marginLeft: "1em",
                  }}
                >
                  {stamp.name}
                </span>
                ?
              </div>
            ),

            okType: "primary",
            okButtonProps: { danger: true },
            onOk: () => {
              dispatch(updateStamp({ ...stamp, isDeleted: true }));
            },
          });
          break;

        case "delete":
          Modal.confirm({
            title: "Are you sure you want to delete?",
            content: (
              <div>
                Are you sure you want to delete
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                    marginLeft: "1em",
                  }}
                >
                  {stamp.name}
                </span>
                ?
              </div>
            ),

            okType: "primary",
            okButtonProps: { danger: true },
            onOk: () => {
              dispatch(deleteStamp(stamp.id));
            },
          });
          break;

        default:
          break;
      }
    };

  const cardTags = stamp.tags.map(tag => {
    return {
      type: "tag",
      value: tag,
    };
  });

  const tagsArray = [
    {
      type: "state",
      value: stamp.state,
    },
    ...cardTags,
  ];

  const oemsText = stamp.oems?.join(", ") || "";

  const activeStampButtons: ICardButtonObj[] = [
    {
      tooltipProps: {
        title: !isAdmin ? accessDeniedMessage : "Edit Parameters",
      },
      buttonText: "Edit Parameters",
      buttonProps: {
        style: {
          cursor: !isAdmin ? "not-allowed" : "pointer",
        },
        icon: <ControlOutlined style={!isAdmin ? { opacity: 0.3 } : {}} />,
        onClick: onPopoverMenuClick("edit", !isAdmin),
      },
    },
    {
      tooltipProps: {
        title: !isAdmin ? accessDeniedMessage : "Duplicate",
      },
      buttonText: "Duplicate",
      buttonProps: {
        style: {
          cursor: !isAdmin ? "not-allowed" : "pointer",
        },
        onClick: onPopoverMenuClick("duplicate", !isAdmin),
        icon: <CopyOutlined style={!isAdmin ? { opacity: 0.3 } : {}} />,
      },
    },
    {
      tooltipProps: {
        title: !isAdmin ? accessDeniedMessage : "Archive",
      },
      buttonText: "Archive",
      buttonProps: {
        style: {
          cursor: !isAdmin ? "not-allowed" : "pointer",
        },
        onClick: onPopoverMenuClick("archive", !isAdmin),
        icon: <InboxOutlined style={!isAdmin ? { opacity: 0.3 } : {}} />,
      },
    },
  ];

  const archivedTemplateButtons: ICardButtonObj[] = [
    {
      tooltipProps: {
        title: !isAdmin ? accessDeniedMessage : "Delete Forever",
      },
      buttonText: "Delete Forever",
      buttonProps: {
        style: {
          cursor: !isAdmin ? "not-allowed" : "pointer",
        },
        onClick: onPopoverMenuClick("delete", !isAdmin),
        icon: <DeleteOutlined style={!isAdmin ? { opacity: 0.3 } : {}} />,
      },
    },
  ];

  const editV2Button: ICardButtonObj = {
    tooltipProps: {
      title: !isAdmin ? accessDeniedMessage : "Edit",
      placement: "bottom",
    },
    buttonText: "Edit V2",
    buttonProps: {
      style: {
        cursor: !isAdmin ? "not-allowed" : "pointer",
      },
      icon: <EditFilled style={!isAdmin ? { opacity: 0.3 } : {}} />,
    },
    popoverProps: {
      mouseEnterDelay: 0.25,
      overlayClassName: "offerTypeSelect",
      content: isAdmin && (
        <ul className="stamp-setting-menu-container">
          {orderBy(offerTypes).map(offerType => {
            return (
              <li key={`popover-menu-offer-type-${offerType}`}>
                <Link
                  to={
                    `/design-studio/editor/stamps/${stamp.id}/editor-v2` +
                    `?offerType=${encodeURIComponent(offerType)}`
                  }
                >
                  {offerType}
                </Link>
              </li>
            );
          })}
        </ul>
      ),
    },
  };

  const buttonsObjects: ICardButtonObj[] = [
    editV2Button,
    ...(selectedHeader !== "DELETED ASSETS"
      ? activeStampButtons
      : archivedTemplateButtons),
  ];

  const badges = [
    <Space key="oems-badge">
      <CarOutlined
        style={{
          marginLeft: "auto",
          marginTop: "4px",
          fontSize: "14px",
        }}
      />
      {(stamp.oems?.length || 0) === 1 && (
        <Typography.Text>{oemsText}</Typography.Text>
      )}
      {(stamp.oems?.length || 0) > 1 && (
        <Tooltip title={oemsText}>
          <Typography.Text>{oemsText.split(", ")[0]}...</Typography.Text>
        </Tooltip>
      )}
    </Space>,
  ];

  const tags = tagsArray.map(tag => tag.value).filter(tag => !!tag);

  return (
    <Card
      isImageTooSmallForScaleDown
      spinProps={{
        tip: "Deleting this stamp...",
        spinning: deletingDataId ? deletingDataId === stamp.id : false,
      }}
      title={stamp.name}
      badges={badges}
      tags={tags}
      buttonObjects={buttonsObjects}
      imageSrc={`${stamp.thumbnailUrl}?time=${roundTimeStamp(MINUTE)}`}
    />
  );
};

export default memo(StampCard);
