import { Radio, RadioChangeEvent, Space } from "antd";

import styles from "../ExportDrawer.module.scss";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { COL_SELECTION, RadioBtnColVal } from "shared/types/assetExporter";
import { useAdEngineActions } from "redux/assetExporter/assetExporter.slice";

export const ColumnSelection = () => {
  const { colsToExport, feedColumns } = useAppSelector(
    state => state.assetExporter.configure,
  );
  const actions = useAdEngineActions();

  const options = [
    {
      label: "Export selected columns",
      value: "exportSelectedColumns",
      disabled: false,
    },
    {
      label: "Export all columns",
      value: "exportAllColumns",
      disabled: false,
    },
  ];
  const isExportAllCols = colsToExport.length === feedColumns.length;

  const checkedValue: RadioBtnColVal = isExportAllCols
    ? COL_SELECTION.EXPORT_ALL
    : COL_SELECTION.EXPORT_SELECTED;

  const onSelectionChange = (e: RadioChangeEvent) => {
    e.preventDefault();
    if (e.target.value === COL_SELECTION.EXPORT_ALL) {
      actions.setColsToExport(feedColumns);
    }
  };

  return (
    <>
      <div
        className={`${styles.radioSelectionTitle} ${styles.sectionTopSpacing}`}
      >
        Column Selection
      </div>
      <Radio.Group value={checkedValue} onChange={onSelectionChange}>
        <Space direction="vertical">
          {options.map(opt => (
            <Radio
              key={opt.value}
              value={opt.value}
              disabled={opt.disabled}
              data-cy={`column-check-${opt.value}`}
            >
              {opt.label}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </>
  );
};
