import { memo, useEffect, useState } from "react";
import { Button, message, Space, Upload, Typography } from "antd";
import { LoadingOutlined, PlusOutlined, SyncOutlined } from "@ant-design/icons";

import { getBase64DataUrl, generateGuidFilename } from "utils/helpers";
import { IUploadMediaUrls, uploadMedia } from "utils/uploadMedia";

import { IUploadImageFormInput } from "shared/types/uploadManagement";

import "./MediaUpload.scss";
import styles from "./MediaUpload.module.scss";
import classNames from "classnames";
import useGetIsMounted from "shared/hooks/useGetIsMounted";

interface IProps {
  mediaType: "image" | "video";
  fileUrls?: IUploadMediaUrls;
  setFileUrls?(fileUrls: IUploadMediaUrls): void;
  validateRequired?: boolean;
}

const acceptedFormats: Record<IProps["mediaType"], string[]> = {
  video: ["mp4", "quicktime", "mov"],
  image: ["jpeg", "bmp", "png", "gif", "tiff"],
};

const sizeLimits: Record<IProps["mediaType"], number> = {
  image: 4000000, // in bytes
  video: 25000000,
};

const VIDEO_TIME_LIMIT = 120; // in seconds

const MediaUpload = ({
  mediaType = "image",
  fileUrls,
  setFileUrls,
  validateRequired,
}: IProps) => {
  const getIsMounted = useGetIsMounted();
  const [mediaErrorMessage, setMediaErrorMessage] = useState<string | null>(
    null,
  );
  const [uploadingMedia, setUploadingMedia] = useState(false);
  const [uploadComplete, setUploadingComplete] = useState(false);

  const mediaNotUploadedError = validateRequired && !fileUrls?.thumbnail;

  useEffect(() => {
    const mediaTypeText = mediaType === "image" ? "Image" : "Video";
    setMediaErrorMessage(
      mediaNotUploadedError ? `Please upload ${mediaTypeText}` : null,
    );
  }, [mediaNotUploadedError, mediaType]);

  useEffect(() => {
    if (!fileUrls?.thumbnail) {
      return;
    }
    setUploadingComplete(true);
  }, [fileUrls]);

  const accept = acceptedFormats[mediaType]
    .filter(dataType => dataType !== "mov")
    .map(dataType => `${mediaType}/${dataType}`)
    .join(", ");

  const isMediaComplete = uploadComplete && fileUrls?.thumbnail;

  return (
    <>
      <Space align={!isMediaComplete ? "start" : "end"}>
        {(!uploadComplete || !fileUrls?.videoUrl) && (
          <Upload
            name="avatar"
            accept={accept}
            className={classNames("mediaUploader", {
              customFieldHasError: mediaNotUploadedError,
            })}
            listType="picture-card"
            disabled={uploadComplete}
            showUploadList={false}
            beforeUpload={file => {
              setMediaErrorMessage(null);
              const { type, size } = file;
              let errorDescription = "";
              if (!type.startsWith(mediaType)) {
                errorDescription = `The file is not the correct type (${mediaType}).`;
              } else if (
                !acceptedFormats[mediaType].includes(
                  type.split("/")[1]?.toLowerCase(),
                )
              ) {
                const formatList = acceptedFormats[mediaType]
                  .map(type => type.toUpperCase())
                  .filter(type => type !== "quicktime") // quicktime is the base64 type of .mov files, not a file ext.
                  .join(", ");
                errorDescription = `The file is not the correct format. Accepted formats: ${formatList}.`;
              } else if (size > sizeLimits[mediaType]) {
                errorDescription = `File must not exceed ${
                  sizeLimits[mediaType] / 1000000
                }MB`;
              }

              if (errorDescription) {
                setMediaErrorMessage(errorDescription);
                setUploadingMedia(false);
                return false;
              }

              return true;
            }}
            customRequest={async ({ file }) => {
              setUploadingMedia(true);
              setMediaErrorMessage(null);
              const assetFile = file as File;
              const mediaDataUrl = await getBase64DataUrl(assetFile);

              const mediaToUpload: IUploadImageFormInput = {
                file: mediaDataUrl,
                filename: generateGuidFilename(assetFile.name),
                size: assetFile.size,
                type: assetFile.type,
              };
              try {
                if (mediaType !== "video") {
                  const assetData = await uploadMedia(
                    mediaToUpload,
                    "instant-experience",
                  );

                  if (!getIsMounted()) {
                    return;
                  }

                  setFileUrls?.({
                    thumbnail: assetData.thumbnail,
                  });
                  setUploadingComplete(true);

                  const img = document.createElement("img");
                  img.setAttribute("id", "dummy-img");
                  img.onload = () => {
                    if (img.width >= 1080) {
                      setMediaErrorMessage(null);
                      return;
                    }
                    setMediaErrorMessage(
                      "Uploaded image width is lower than the recommended size. Try uploading an image with a width of 1080 pixels.",
                    );
                  };
                  img.src = assetData.thumbnail as string;
                  document.getElementById("dummy-img")?.remove();
                  setUploadingMedia(false);
                  setUploadingComplete(true);
                  return;
                }

                const dummyVideo = document.createElement("video");
                dummyVideo.preload = "metadata";

                dummyVideo.onloadedmetadata = async () => {
                  (window.URL || window.webkitURL).revokeObjectURL(
                    dummyVideo.src,
                  );
                  if (dummyVideo.duration > VIDEO_TIME_LIMIT) {
                    setMediaErrorMessage("Video exceeds 2 minutes.");
                    setUploadingMedia(false);
                    setUploadingComplete(false);
                    dummyVideo.remove();
                    return;
                  }

                  const assetData = await uploadMedia(
                    mediaToUpload,
                    "instant-experience",
                  );
                  if (!getIsMounted()) {
                    return;
                  }

                  setFileUrls?.(assetData);
                  setUploadingMedia(false);
                  setUploadingComplete(true);
                  dummyVideo.remove();
                };

                dummyVideo.src = (
                  window.URL || window.webkitURL
                ).createObjectURL(assetFile);

                return;
              } catch (error) {
                message.error("An error occurred while uploading media");
                setUploadingMedia(false);
              }
            }}
          >
            {fileUrls?.thumbnail ? (
              <img
                src={fileUrls.thumbnail}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              <div>
                {uploadingMedia ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            )}
          </Upload>
        )}
        {!isMediaComplete && (
          <Typography.Paragraph style={{ width: 240 }}>
            {mediaType === "video" &&
              "Upload a video file (.mp4 or .mov). Recommended: keep your videos under 2 minutes and use captions so that people can still engage without audio."}
            {mediaType === "image" && "Recommended Image width: 1080px"}
          </Typography.Paragraph>
        )}
        {isMediaComplete && fileUrls!.videoUrl && (
          <video
            controls
            width={220}
            height={128}
            className="videoPlayer"
            src={fileUrls!.videoUrl}
          />
        )}
        {uploadComplete && (
          <Button
            style={
              mediaType === "video" && fileUrls?.videoUrl
                ? { top: -8 }
                : undefined
            }
            icon={<SyncOutlined />}
            onClick={async () => {
              setUploadingMedia(false);
              setUploadingComplete(false);
              setFileUrls?.({ thumbnail: "" });
              setMediaErrorMessage(null);
            }}
          >
            Replace
          </Button>
        )}
      </Space>
      {!!mediaErrorMessage && (
        <span className={styles.errorMessage}>{mediaErrorMessage}</span>
      )}
    </>
  );
};

export default memo(MediaUpload);
