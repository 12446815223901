import { useAssetBatchesHtmlRenderContext } from "../../contexts/AssetBatchesHtmlRenderContext";
import { MaskItemsControls, MaskItemsProps } from "./MaskItemsControls";

export const HtmlMaskItems = ({ step }: MaskItemsProps) => {
  const {
    isMediaMaskOn,
    isTextMaskOn,
    toggleMediaMask,
    toggleTextMask,
    setCurrentSwitchType,
  } = useAssetBatchesHtmlRenderContext();

  const onChangeMediaMask = (val: boolean) => {
    toggleMediaMask(val);
    setCurrentSwitchType("media");
  };

  const onChangeTextMask = (val: boolean) => {
    toggleTextMask(val);
    setCurrentSwitchType("text");
  };

  return (
    <MaskItemsControls
      key={"html-mask-items"}
      step={step}
      onChangeMediaMask={onChangeMediaMask}
      onChangeTextMask={onChangeTextMask}
      isMediaMaskOn={isMediaMaskOn}
      isTextMaskOn={isTextMaskOn}
    />
  );
};
