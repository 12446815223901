import {
  ChangeEvent,
  ClipboardEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { IAccountRecord } from "shared/types/accountManagement";
import {
  IFormValidationAttributes,
  OperationMode,
} from "shared/types/inputValues";
import * as validators from "../../../utils/validators";
import { warningNotification } from "shared/components/customNotification/Notification";
import { formatPhoneNumber, revertPhoneNumber } from "utils/helpers";
import { FormInstance } from "antd";

interface IDetailsAccountValidations {
  accountToEdit: IAccountRecord;
  setAccountToEdit: (accountToEdit: IAccountRecord) => void;
  mode: OperationMode;
  accountNameValidationAttributes?: IFormValidationAttributes;
  accountCodeValidationAttributes?: IFormValidationAttributes;
  accountUrlValidationAttributes?: IFormValidationAttributes;
  form?: FormInstance<IAccountRecord>;
  setAccountNameValidationAttributes?: React.Dispatch<
    React.SetStateAction<IFormValidationAttributes>
  >;
  setAccountCodeValidationAttributes?: React.Dispatch<
    React.SetStateAction<IFormValidationAttributes>
  >;
  setAccountUrlValidationAttributes?: React.Dispatch<
    React.SetStateAction<IFormValidationAttributes>
  >;
  setRequiredFields?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useDetailsAccountValidations = ({
  accountToEdit,
  mode,
  form,
  accountCodeValidationAttributes,
  accountNameValidationAttributes,
  accountUrlValidationAttributes,
  setAccountCodeValidationAttributes,
  setAccountNameValidationAttributes,
  setAccountUrlValidationAttributes,
  setAccountToEdit,
  setRequiredFields,
}: IDetailsAccountValidations) => {
  const onlyNumbersRegex = /^\d+(\d+)?$/;
  const withoutConsecutiveSpaces = /^(?!.*  )/;

  const [isValidForm, setIsValidForm] = useState({
    account: true,
    code: true,
    url: true,
  });

  const validateForm = useCallback(
    async (formToValidate: FormInstance) => {
      try {
        const values = await formToValidate.validateFields([
          mode === "UPDATE" ? "newDealerName" : "dealerName",
          "dealerCode",
          "dealerUrl",
        ]);
        const requiredFieldsExist = Object.values(values).every(
          value => value !== undefined,
        );
        const validateStatus =
          requiredFieldsExist &&
          isValidForm.account &&
          isValidForm.code &&
          isValidForm.url;

        setRequiredFields?.(validateStatus);
      } catch (error) {}
    },
    [isValidForm, setRequiredFields, mode],
  );

  useEffect(() => {
    if (form) validateForm(form);
  }, [isValidForm, form, validateForm]);

  const handlePhoneNumberChange = (newPhoneNumber: string) => {
    setAccountToEdit({
      ...accountToEdit,
      dealerPhoneNumber: newPhoneNumber,
    });
  };

  const handlePhoneNumberPaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { clipboardData, currentTarget } = event;
    const { value: phoneNumberVal = "" } = currentTarget;
    const numberToPaste = clipboardData.getData("text/plain");

    let replacedNumbers = revertPhoneNumber(numberToPaste);

    const validUnformattedDigits =
      onlyNumbersRegex.test(replacedNumbers) && replacedNumbers.length === 10;

    if (!validUnformattedDigits) {
      warningNotification({
        messageLabel:
          "Paste failed. The text does not comply with formats: (###) ###-#### or ##########",
      });
      replacedNumbers = phoneNumberVal;
    }

    handlePhoneNumberChange(replacedNumbers);
  };

  const handlePhoneNumberCopy = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { clipboardData, currentTarget } = event;
    const { value: phoneNumberVal = "" } = currentTarget;
    clipboardData.setData("text/plain", formatPhoneNumber(phoneNumberVal));
  };

  const handlePhoneNumberCut = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();

    const { currentTarget, clipboardData } = event;
    const { value, selectionStart, selectionEnd } = currentTarget;

    const cutString = value.substr(
      selectionStart || 0,
      selectionEnd || value.length,
    );

    if (cutString.length === 10) {
      clipboardData.setData("text/plain", formatPhoneNumber(cutString));
    }

    handlePhoneNumberChange(value.replace(cutString, ""));
  };

  const handelClickAccountName = (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const { target } = event;
      const { value: updatedDealerName } = target || {
        value: undefined,
      };

      form?.setFieldsValue(
        mode === "UPDATE"
          ? { newDealerName: updatedDealerName }
          : { dealerName: updatedDealerName },
      );
      const { timeoutId } = accountNameValidationAttributes ?? {};

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      if (updatedDealerName === "" || updatedDealerName === undefined) {
        setAccountNameValidationAttributes?.({
          timeoutId: undefined,
          validationStatus: undefined,
          hasFeedback: undefined,
          required: true,
          feedbackMessage: "",
        });
      } else {
        const newTimeoutId = setTimeout(() => {
          const isAccountNameValid =
            withoutConsecutiveSpaces.test(updatedDealerName);
          setIsValidForm({
            ...isValidForm,
            account: isAccountNameValid || false,
          });
          setAccountNameValidationAttributes?.({
            ...accountNameValidationAttributes,
            validationStatus: isAccountNameValid ? "success" : "error",
            hasFeedback: true,
            required: true,
            feedbackMessage: isAccountNameValid
              ? "Valid Account Name (DBA)"
              : "Invalid Accaount Name (DBA). Make sure there are no consecutive spaces.",
          });
        }, 300);

        setAccountNameValidationAttributes?.({
          timeoutId: newTimeoutId,
          validationStatus: "validating",
          hasFeedback: true,
          required: true,
          feedbackMessage: "",
        });
      }

      const updatedAccount =
        mode === "UPDATE"
          ? {
              ...accountToEdit,
              newDealerName: updatedDealerName,
            }
          : {
              ...accountToEdit,
              dealerName: updatedDealerName,
            };

      setAccountToEdit(updatedAccount);
    } catch (error) {}
  };

  const handleClickAccountCode = (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const { target } = event;
      const { value: updatedAccountCode } = target || {
        value: undefined,
      };
      form?.setFieldsValue({ dealerCode: updatedAccountCode });

      const { timeoutId } = accountCodeValidationAttributes ?? {};

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      if (updatedAccountCode === "" || updatedAccountCode === undefined) {
        setAccountCodeValidationAttributes?.({
          timeoutId: undefined,
          validationStatus: undefined,
          hasFeedback: undefined,
          required: true,
          feedbackMessage: "",
        });
      } else {
        const newTimeoutId = setTimeout(() => {
          const isAccountCodeValid = updatedAccountCode.length === 8;
          setIsValidForm({
            ...isValidForm,
            code: isAccountCodeValid || false,
          });
          setAccountCodeValidationAttributes?.({
            ...accountCodeValidationAttributes,
            validationStatus: isAccountCodeValid ? "success" : "error",
            hasFeedback: true,
            required: true,
            feedbackMessage: isAccountCodeValid
              ? "Valid Account Name (2-3-3)"
              : "Invalid Account Name (2-3-3). The code must be 8 characters long.",
          });
        }, 300);

        setAccountCodeValidationAttributes?.({
          timeoutId: newTimeoutId,
          validationStatus: "validating",
          hasFeedback: true,
          required: true,
          feedbackMessage: "",
        });
      }

      setAccountToEdit({
        ...accountToEdit,
        dealerCode: updatedAccountCode,
      });
    } catch (error) {}
  };

  const handelClickAccountUrl = (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const { target } = event;
      const { value: updatedAccountUrl } = target || {
        value: undefined,
      };
      form?.setFieldsValue({ dealerUrl: updatedAccountUrl });

      const { timeoutId } = accountUrlValidationAttributes ?? {};

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      if (updatedAccountUrl === "" || updatedAccountUrl === undefined) {
        setAccountUrlValidationAttributes?.({
          timeoutId: undefined,
          validationStatus: undefined,
          hasFeedback: undefined,
          required: true,
          feedbackMessage: "",
        });
      } else {
        const newTimeoutId = setTimeout(() => {
          const isAccountUrlValid =
            validators.isUrlInputValid(updatedAccountUrl) &&
            !updatedAccountUrl.endsWith("/");
          setIsValidForm({
            ...isValidForm,
            url: isAccountUrlValid || false,
          });
          setAccountUrlValidationAttributes?.(
            (prev: IFormValidationAttributes) => {
              return {
                ...prev,
                validationStatus: isAccountUrlValid ? "success" : "error",
                hasFeedback: true,
                feedbackMessage: isAccountUrlValid
                  ? "valid Account URL"
                  : "A Valid Account URL requires http:// or https://, a .suffix, and must not end with a /",
              };
            },
          );
        }, 300);

        setAccountUrlValidationAttributes?.({
          timeoutId: newTimeoutId,
          validationStatus: "validating",
          hasFeedback: true,
          required: true,
          feedbackMessage: "",
        });
      }
      setAccountToEdit({
        ...accountToEdit,
        dealerUrl: updatedAccountUrl,
      });
    } catch (error) {}
  };

  return {
    onlyNumbersRegex,
    handelClickAccountName,
    handleClickAccountCode,
    handelClickAccountUrl,
    handlePhoneNumberChange,
    handlePhoneNumberPaste,
    handlePhoneNumberCopy,
    handlePhoneNumberCut,
  };
};
