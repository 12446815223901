import { Button, message, Spin } from "antd";
import { useEffect, useMemo, useReducer, useRef, useState } from "react";

import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

// redux
import actions from "../../redux/rootActions";

// types
import Header from "shared/components/Header";
import {
  ILegalLingoAPRTable,
  ILegalLingoFinanceTable,
  ILegalLingoLeaseTable,
  ILegalLingoNumberAtThisTable,
  ILegalLingoOEMTablesResponse,
  ILegalLingoPurchaseTable,
  ILegalLingoStateTablesResponse,
  ILegalLingoVehicleInfoTable,
  ILegalLingoZeroDownLeaseTable,
  Mode,
  TDisclosureType,
} from "../../shared/types/legalLingo";

import "./../LegalLingo.scss";

import LegalLingoOem from "screens/legalLingo/legalVariables/LegalLingoOem";
import LegalLingoState from "screens/legalLingo/legalVariables/LegalLingoState";
import TabContainer from "shared/components/TabContainer";

import _ from "lodash";
import { useNavigate } from "react-router-dom";
import GenericError from "shared/errors/GenericError";
import { IHeader } from "shared/types/header";
import { initializeVariableTable } from "utils/helpers.legal";

interface ILegalLingo {
  checkedStateVehicleInfoList: ILegalLingoVehicleInfoTable[];
  checkedStateNumberAtThisPriceList?: ILegalLingoNumberAtThisTable[];
  checkedOemVehicleInfoList: ILegalLingoVehicleInfoTable[];

  checkedStateLeaseList: ILegalLingoLeaseTable[];
  checkedOemNumberAtThisPriceList?: ILegalLingoNumberAtThisTable[];
  checkedOemLeaseList: ILegalLingoLeaseTable[];

  checkedStateZeroDownLeaseList: ILegalLingoZeroDownLeaseTable[];
  checkedOemZeroDownLeaseList: ILegalLingoZeroDownLeaseTable[];

  checkedStateFinanceList: ILegalLingoFinanceTable[];
  checkedOemFinanceList: ILegalLingoFinanceTable[];

  checkedStatePurchaseList: ILegalLingoPurchaseTable[];
  checkedOemPurchaseList: ILegalLingoPurchaseTable[];

  checkedStateAPRList: ILegalLingoAPRTable[];
  checkedOemAPRList: ILegalLingoAPRTable[];

  stateUpdateSuccessful: boolean;
  oemUpdateSuccessful: boolean;
  isFirstLoad: boolean;

  uniqueUUID: string;
  reloadUUID: string;

  error: GenericError | null;

  getOemTablesLegalLingos: () => void;
  updateOemTablesLegalLingos: (oemListToUpdate: any) => void;

  getStateTablesLegalLingos: () => void;
  updateStateTablesLegalLingos: (stateListToUpdate: any) => void;
}

const LegalLingoForm: React.FC<ILegalLingo> = ({
  checkedStateVehicleInfoList,
  checkedStateNumberAtThisPriceList = [],
  checkedStateLeaseList,

  checkedOemVehicleInfoList,
  checkedOemNumberAtThisPriceList = [],
  checkedOemLeaseList,

  updateOemTablesLegalLingos,
  updateStateTablesLegalLingos,

  checkedOemZeroDownLeaseList,
  checkedStateZeroDownLeaseList,

  checkedOemFinanceList,
  checkedStateFinanceList,

  checkedStatePurchaseList,
  checkedOemPurchaseList,

  checkedStateAPRList,
  checkedOemAPRList,

  stateUpdateSuccessful,
  oemUpdateSuccessful,
  isFirstLoad,

  reloadUUID,
}) => {
  const availableOems = useMemo(() => {
    if (checkedOemVehicleInfoList.length < 1) {
      return [];
    }
    const oemNamesArray = checkedOemVehicleInfoList.map(
      record => record.lingo_oem || "",
    );
    const oemNamesFiltered = oemNamesArray.filter(record => record);

    return oemNamesFiltered;
  }, [checkedOemVehicleInfoList]);

  const useLocalList = (
    listFromRedux: any[],
    tableGroup: "state" | "oem",
    offerType: TDisclosureType,
  ) => {
    const [localList, updateLocalList] = useReducer(
      (currStateList: any[], [index, field]: [number, string]) => {
        const newStateList =
          currStateList.length < 1
            ? initializeVariableTable(
                offerType,
                tableGroup === "oem" ? availableOems : undefined,
              )
            : [...currStateList];
        const returnObject = currStateList[index]
          ? { ...currStateList[index] }
          : { ...newStateList[index] };

        if (!returnObject[field]) {
          returnObject[field] = "{'checked':'false'}";
        }

        if (returnObject[field].includes("false")) {
          returnObject[field] = returnObject[field].replace("false", "true");
        } else {
          returnObject[field] = returnObject[field].replace("true", "false");
        }
        newStateList[index] = returnObject;
        return newStateList;
      },
      listFromRedux, // original value
    );

    let listToUse = localList;
    if (tableGroup === "state") {
      listToUse =
        localList.length < 1
          ? initializeVariableTable(offerType, undefined)
          : initializeVariableTable(offerType, undefined, localList);
    } else {
      listToUse =
        localList.length < 1
          ? initializeVariableTable(offerType, availableOems)
          : initializeVariableTable(offerType, availableOems, localList);
    }
    return {
      localList: listToUse,
      updateLocalList,
    };
  };
  const currentTabRef = useRef<string>("");
  const [allChangesSaved, toggleHasUnsavedChanges] = useState<boolean>(true);

  const navigate = useNavigate();

  const headerMenus: IHeader = {
    style: {
      display: "flex",
      alignItem: "center",
      flex: "initial",
      width: "35em",
    },
    topSteps: {
      selected: "VARIABLES",
      style: {
        flex: 2,
      },
      steps: [
        {
          title: "VARIABLES",
          state: "enabled",
        },
        {
          title: "DISCLOSURES",
          state: "enabled",
          onClick: () => {
            navigate("/legal-lingo/disclosures");
          },
        },
        {
          title: "MESSAGING",
          state: "enabled",
          onClick: () => {
            navigate("/legal-lingo/messaging");
          },
        },
      ],
    },

    middleSteps: [],
    actionButtons: [
      <Button
        onClick={() => {
          if (!currentTabRef) {
            return;
          }

          if (currentTabRef.current === "by oem") {
            updateOemTablesLegalLingos({
              localListOfCheckedOems,
              localListOfCheckedNumberAtThisPriceOems,
              locallistOfCheckedLeaseOems,
              locallistOfCheckedZeroDownLeaseOems,
              locallistOfCheckedFinanceOems,
              locallistOfCheckedPurchaseOems,
              localListOfCheckedAPROems,
            });
          } else {
            updateStateTablesLegalLingos({
              localListOfCheckedStates,
              localListOfCheckedNumberAtThisPriceStates,
              localListOfCheckedLeaseStates,
              localListOfCheckedZeroDownLeaseStates,
              localListOfCheckedFinanceStates,
              localListOfCheckedPurchaseStates,
              localListOfCheckedAPRStates,
            });
          }

          message.info("Saving Legal Lingo Info...");
        }}
        className="action-button"
        key="save-button"
        disabled={allChangesSaved}
      >
        Save
      </Button>,
    ],
  };

  useEffect(() => {
    if (!isFirstLoad) {
      if (stateUpdateSuccessful && oemUpdateSuccessful) {
        message.success("Saved Successfully!");
        toggleHasUnsavedChanges(true);
      } else {
        message.warning("There was a problem with Saving");
      }
    }
    // eslint-disable-next-line
  }, [reloadUUID]);

  // State
  const {
    localList: localListOfCheckedStates,
    updateLocalList: updateLocalListOfCheckedStates,
  } = useLocalList(checkedStateVehicleInfoList, "state", "Vehicle Info");

  const {
    localList: localListOfCheckedNumberAtThisPriceStates,
    updateLocalList: updateLocalListOfCheckedNumberAtThisPriceStates,
  } = useLocalList(
    checkedStateNumberAtThisPriceList,
    "state",
    "Number at this Price",
  );

  const {
    localList: localListOfCheckedLeaseStates,
    updateLocalList: updateLocalListOfCheckedLeaseStates,
  } = useLocalList(checkedStateLeaseList, "state", "Lease");
  const {
    localList: localListOfCheckedZeroDownLeaseStates,
    updateLocalList: updateLocalListOfCheckedZeroDownLeaseStates,
  } = useLocalList(checkedStateZeroDownLeaseList, "state", "Zero Down Lease");

  const {
    localList: localListOfCheckedFinanceStates,
    updateLocalList: updateLocalListOfCheckedFinanceStates,
  } = useLocalList(checkedStateFinanceList, "state", "Finance");

  const {
    localList: localListOfCheckedPurchaseStates,
    updateLocalList: updateLocalListOfCheckedPurchaseStates,
  } = useLocalList(checkedStatePurchaseList, "state", "Purchase");

  const {
    localList: localListOfCheckedAPRStates,
    updateLocalList: updateLocalListOfCheckedAPRStates,
  } = useLocalList(checkedStateAPRList, "state", "APR");

  // OEM
  const {
    localList: localListOfCheckedOems,
    updateLocalList: updateLocalListOfCheckedOems,
  } = useLocalList(checkedOemVehicleInfoList, "oem", "Vehicle Info");

  const {
    localList: localListOfCheckedNumberAtThisPriceOems,
    updateLocalList: updateLocalListOfCheckedNumberAtThisPriceOems,
  } = useLocalList(
    _.orderBy(checkedOemNumberAtThisPriceList, "lingo_oem"),
    "oem",
    "Number at this Price",
  );

  const {
    localList: locallistOfCheckedLeaseOems,
    updateLocalList: updateLocalListLeaseOems,
  } = useLocalList(checkedOemLeaseList, "oem", "Lease");

  const {
    localList: locallistOfCheckedZeroDownLeaseOems,
    updateLocalList: updateLocalListZeroDownLeaseOems,
  } = useLocalList(checkedOemZeroDownLeaseList, "oem", "Zero Down Lease");

  const {
    localList: locallistOfCheckedFinanceOems,
    updateLocalList: updateLocalListFinanceOems,
  } = useLocalList(checkedOemFinanceList, "oem", "Finance");

  const {
    localList: locallistOfCheckedPurchaseOems,
    updateLocalList: updateLocalListPurchaseOems,
  } = useLocalList(checkedOemPurchaseList, "oem", "Purchase");

  const {
    localList: localListOfCheckedAPROems,
    updateLocalList: updateLocalListAPROems,
  } = useLocalList(checkedOemAPRList, "oem", "APR");

  return (
    <div>
      <Header
        style={headerMenus.style}
        topSteps={headerMenus.topSteps}
        actionButtons={headerMenus.actionButtons}
      />
      <div>
        <TabContainer
          displayFilterSection={false}
          displaySearchView={{
            displayNewOffer: false,
            displaySearchInput: false,
            displayPlusButton: false,
          }}
          onChange={(key: string) => (currentTabRef.current = key)}
          contentTabs={[
            {
              title: "By Location",
              component: (
                <LegalLingoState
                  listOfCheckedStates={localListOfCheckedStates}
                  listOfCheckedNumberAtThisPriceStates={
                    localListOfCheckedNumberAtThisPriceStates
                  }
                  listOfCheckedLeaseStates={localListOfCheckedLeaseStates}
                  listOfCheckedZeroDownLeaseStates={
                    localListOfCheckedZeroDownLeaseStates
                  }
                  listOfCheckedFinanceStates={localListOfCheckedFinanceStates}
                  listOfCheckedPurchaseStates={localListOfCheckedPurchaseStates}
                  listOfCheckedAPRStates={localListOfCheckedAPRStates}
                  onChange={(address, table) => {
                    toggleHasUnsavedChanges(false);
                    if (table === "Vehicle Info") {
                      updateLocalListOfCheckedStates(address);
                    }
                    if (table === "Number at this Price") {
                      updateLocalListOfCheckedNumberAtThisPriceStates(address);
                    }
                    if (table === "Lease") {
                      updateLocalListOfCheckedLeaseStates(address);
                    }
                    if (table === "Zero Down Lease") {
                      updateLocalListOfCheckedZeroDownLeaseStates(address);
                    }
                    if (table === "Finance") {
                      updateLocalListOfCheckedFinanceStates(address);
                    }
                    if (table === "Purchase") {
                      updateLocalListOfCheckedPurchaseStates(address);
                    }
                    if (table === "APR") {
                      updateLocalListOfCheckedAPRStates(address);
                    }
                  }}
                />
              ),
            },
            {
              title: "By OEM",
              component: (
                <LegalLingoOem
                  listOfCheckedVehicleInfoOems={localListOfCheckedOems}
                  listOfCheckedNumberAtThisPriceOems={
                    localListOfCheckedNumberAtThisPriceOems
                  }
                  listOfCheckedLeaseOems={locallistOfCheckedLeaseOems}
                  listOfCheckedZeroDownLeaseOems={
                    locallistOfCheckedZeroDownLeaseOems
                  }
                  listOfCheckedFinanceOems={locallistOfCheckedFinanceOems}
                  listOfCheckedPurchaseOems={locallistOfCheckedPurchaseOems}
                  listOfCheckedAPROems={localListOfCheckedAPROems}
                  onChange={(address, table) => {
                    toggleHasUnsavedChanges(false);
                    if (table === "Vehicle Info") {
                      updateLocalListOfCheckedOems(address);
                    }
                    if (table === "Number at this Price") {
                      updateLocalListOfCheckedNumberAtThisPriceOems(address);
                    }
                    if (table === "Lease") {
                      updateLocalListLeaseOems(address);
                    }
                    if (table === "Zero Down Lease") {
                      updateLocalListZeroDownLeaseOems(address);
                    }
                    if (table === "Finance") {
                      updateLocalListFinanceOems(address);
                    }
                    if (table === "Purchase") {
                      updateLocalListPurchaseOems(address);
                    }
                    if (table === "APR") {
                      updateLocalListAPROems(address);
                    }
                  }}
                />
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};
const LegalVariables: React.FC<ILegalLingo> = props => {
  const {
    checkedStateVehicleInfoList,
    checkedStateLeaseList,

    checkedOemVehicleInfoList,
    checkedOemLeaseList,

    getOemTablesLegalLingos,
    getStateTablesLegalLingos,

    checkedOemZeroDownLeaseList,
    checkedStateZeroDownLeaseList,

    checkedOemFinanceList,
    checkedStateFinanceList,

    checkedStatePurchaseList,
    checkedOemPurchaseList,

    checkedStateAPRList,
    checkedOemAPRList,

    uniqueUUID,

    error,
  } = props;

  useEffect(() => {
    getStateTablesLegalLingos();
    getOemTablesLegalLingos();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  if (
    checkedStateVehicleInfoList &&
    checkedOemVehicleInfoList &&
    checkedOemLeaseList &&
    checkedStateLeaseList &&
    checkedOemZeroDownLeaseList &&
    checkedStateZeroDownLeaseList &&
    checkedOemFinanceList &&
    checkedStateFinanceList &&
    checkedStatePurchaseList &&
    checkedOemPurchaseList &&
    checkedStateAPRList &&
    checkedOemAPRList
  ) {
    return <LegalLingoForm key={uniqueUUID} {...props} />;
  }

  return (
    <div className="loading">
      <Spin spinning={true} size="large" />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const { legalLingo, auth } = state;

  const {
    checkedStateVehicleInfoList,
    checkedStateNumberAtThisPriceList,
    checkedStateLeaseList,

    checkedOemVehicleInfoList,
    checkedOemNumberAtThisPriceList,
    checkedOemLeaseList,

    checkedStateZeroDownLeaseList,
    checkedOemZeroDownLeaseList,

    checkedOemFinanceList,
    checkedStateFinanceList,

    checkedStatePurchaseList,
    checkedOemPurchaseList,

    checkedStateAPRList,
    checkedOemAPRList,

    uniqueUUID,

    stateUpdateSuccessful,
    oemUpdateSuccessful,
    isFirstLoad,

    reloadUUID,

    error,
  } = legalLingo;

  return {
    loggedInUser: auth.user,

    checkedStateVehicleInfoList,
    checkedStateNumberAtThisPriceList,
    checkedStateLeaseList,

    checkedStateZeroDownLeaseList,
    checkedOemZeroDownLeaseList,

    error,

    checkedOemVehicleInfoList,
    checkedOemNumberAtThisPriceList,
    checkedOemLeaseList,

    checkedOemFinanceList,
    checkedStateFinanceList,

    checkedStatePurchaseList,
    checkedOemPurchaseList,

    checkedStateAPRList,
    checkedOemAPRList,

    uniqueUUID,

    stateUpdateSuccessful,
    oemUpdateSuccessful,
    isFirstLoad,

    reloadUUID,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => {
  return {
    setMode: (mode: Mode) => {
      dispatch(actions.legalLingo.setMode(mode));
    },
    getStateTablesLegalLingos: () => {
      dispatch(actions.legalLingo.getStateTablesLegalLingos());
    },

    updateStateTablesLegalLingos: (
      stateListToUpdate: ILegalLingoStateTablesResponse[],
    ) => {
      dispatch(
        actions.legalLingo.updateStateTablesLegalLingos(stateListToUpdate),
      );
    },
    getOemTablesLegalLingos: () => {
      dispatch(actions.legalLingo.getOemTablesLegalLingos());
    },

    updateOemTablesLegalLingos: (
      oemListToUpdate: ILegalLingoOEMTablesResponse[],
    ) => {
      dispatch(actions.legalLingo.updateOemTablesLegalLingos(oemListToUpdate));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LegalVariables);
