import { useQuery } from "react-query";
import { IGetSpecialCampaignsResponse } from "shared/types/specialCampaign";
import API from "services";

const getSpecialCampaigns = async () => {
  const { result, error } =
    await API.services.specialCampaign.getSpecialCampaigns<IGetSpecialCampaignsResponse>();

  if (error) {
    throw Error(error.message);
  }

  return result;
};

export const useFetchSpecialCampaigns = () => {
  return useQuery<IGetSpecialCampaignsResponse["result"], Error>(
    "specialCampaigns",
    getSpecialCampaigns,
  );
};
