import { Select, Tooltip, Tag } from "antd";
import {
  InfoCircleFilled,
  CheckCircleFilled,
  QuestionCircleOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import styles from "./AdLibrary.module.scss";
import { getFilteredColumns } from "./utils/getFilteredColumns";
import {
  AdType,
  HandleSelectColumnMatch,
  MatchColumnsTableData,
  RecommendedColumnMatches,
  RecommendedColumnTitle,
  RowType,
} from "../shared/types";
import { recommendedColumnTitles } from "./constants";

const getAdLibraryFieldOptions = (
  adType: AdType,
  columnMatches: RecommendedColumnMatches,
  isMediaRow: boolean,
) => {
  const getOptions = (options: RecommendedColumnTitle[]) =>
    options.map(option => ({
      label: option,
      value: option,
      disabled: Object.values(columnMatches).some(
        columnMatch => columnMatch.recommendedColumnTitle === option,
      ),
    }));

  const recommendedMediaColumns = [
    RecommendedColumnTitle.InFeedMedia,
    RecommendedColumnTitle.StoryMedia,
  ];

  const adLibraryOptions = isMediaRow
    ? getOptions(recommendedMediaColumns)
    : getOptions(
        getFilteredColumns(
          recommendedColumnTitles[adType],
          recommendedMediaColumns,
        ),
      );

  return [
    { label: <span>Match with</span>, value: "" },
    {
      label: <span>Ad Library Fields</span>,
      options: adLibraryOptions,
    },
  ];
};

export const getColumns = ({
  handleSelectColumnMatch,
  columnMatches,
  adType,
  onThumbnailError,
  thumbnailErrors,
}: {
  handleSelectColumnMatch: HandleSelectColumnMatch;
  columnMatches: RecommendedColumnMatches;
  adType: AdType | null;
  onThumbnailError: (assetBatchId: string) => void;
  thumbnailErrors: string[];
}) => {
  if (!adType) return;

  const handleThumbnailError = (assetBatchId: string | undefined) => {
    if (!assetBatchId) return;
    onThumbnailError(assetBatchId);
  };

  return [
    {
      title: "Assets and Feed Columns",
      render: (_: string, rowData: MatchColumnsTableData) => (
        <div className={styles.feedColumnCellWrapper}>
          {rowData.rowType !== RowType.Media && rowData.title}
          {rowData.rowType === RowType.AssetName && (
            <Tooltip
              title={rowData.tooltipText}
              className={styles.assetBatchNameTooltip}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          )}
          {rowData.rowType === RowType.Media && (
            <div className={styles.thumbnailWrapper}>
              <img
                onError={() => handleThumbnailError(rowData?.assetBatchId)}
                className={styles.thumbnail}
                src={rowData.thumbnailURL}
              />
            </div>
          )}
          {[RowType.AssetName, RowType.Media].includes(rowData.rowType) && (
            <Tag className={styles.assetBatchNameTag}>
              {rowData.assetBatchName}
            </Tag>
          )}
        </div>
      ),
    },
    {
      width: 50,
      align: "center" as const,
      render: (_: string, rowData: MatchColumnsTableData) => {
        const isMatch =
          columnMatches[rowData.key]?.recommendedColumnTitle !== null;
        const isValidDimensions = rowData.isValidDimensions === true;
        const hasThumbnailError =
          rowData.assetBatchId &&
          thumbnailErrors.includes(rowData.assetBatchId) &&
          rowData.rowType === RowType.Media;

        if (rowData.rowType === RowType.Media && !isValidDimensions) {
          return <CloseCircleFilled className={styles.errorIcon} />;
        }
        if (!isMatch || hasThumbnailError) {
          return <InfoCircleFilled className={styles.infoIcon} />;
        }
        return <CheckCircleFilled className={styles.checkIcon} />;
      },
    },
    {
      title: "Ad Library Fields",
      render: (_: string, rowData: MatchColumnsTableData) => (
        <Select
          placeholder="Match with"
          value={
            columnMatches[rowData.key]?.recommendedColumnTitle ?? undefined
          }
          onSelect={recommendedColumnTitle =>
            handleSelectColumnMatch(recommendedColumnTitle, rowData)
          }
          disabled={rowData.isValidDimensions === false}
          className={styles.columnTitleInput}
          options={getAdLibraryFieldOptions(
            adType,
            columnMatches,
            !!rowData?.thumbnailURL,
          )}
        />
      ),
    },
  ];
};
