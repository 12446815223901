export const getPortalDataOptions = (
  data: { name: string; id: string }[] | undefined,
  loading: boolean,
) => {
  if (loading || !data) return [];
  return data.map(({ name, id }) => ({
    name,
    id,
  }));
};
