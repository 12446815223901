import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import {
  offerTypeVariables,
  numberAtThisPriceVariableNames,
  internalOfferTypeVariables,
} from "../../../../shared/constants/dataManagement";
import {
  IOfferTypeVariable,
  IStateDisclosureElement,
  IStateExceptionElement,
  TDisclosureDataType,
  TDisclosureType,
} from "../../../../shared/types/legalLingo";
import DisclosureCollapseContent from "./DisclosureCollapseContent";

import "../../../../shared/styles/Collapse.scss";
import "../DisclosuresContent.scss";
import { FeedType } from "shared/types/configuration";
import { connect } from "react-redux";
import { FC } from "react";
import { AppState } from "redux/store";
import { User } from "redux/auth/auth.slice";

interface IDisclosuresCollapse {
  loggedInUser: User;
  dataType?: TDisclosureDataType;
  currentStateTab: string;
  activeDisclosureCollapseKey: string;
  currentDisclosuresByOfferType:
    | IStateDisclosureElement[]
    | IStateExceptionElement[];
  setDisclosuresByStateInParent: (
    state: string,
    disclosures: IStateDisclosureElement[],
  ) => void;
  setActiveDisclosureCollapseKey: (disclosureCollapseKey: string) => void;
  insertDisclosureObjIntoStateByOfferType: (
    offerType: TDisclosureType,
    text: string,
    dataType?: TDisclosureDataType,
  ) => void;
  feed: FeedType;
}

const DisclosuresCollapse: FC<IDisclosuresCollapse> = ({
  loggedInUser,
  dataType,
  currentStateTab,
  activeDisclosureCollapseKey,
  currentDisclosuresByOfferType = [],
  setDisclosuresByStateInParent,
  setActiveDisclosureCollapseKey,
  insertDisclosureObjIntoStateByOfferType,
  feed,
}) => {
  const offerTypeVariablesToUse =
    feed === "internal" ? internalOfferTypeVariables : offerTypeVariables;

  return (
    <>
      {offerTypeVariablesToUse.map(offerTypeVariable => {
        const { offerType, variables } =
          offerTypeVariable as IOfferTypeVariable;
        const currentCollapseKey = `${offerType}-collapse`;
        const isActive = currentCollapseKey === activeDisclosureCollapseKey;

        const foundDisclosureObj = currentDisclosuresByOfferType.find(
          disclosureObj => disclosureObj.offerType === offerType,
        ) || { offerType, text: "" };

        return (
          <Collapse
            className="collapse-container disclosure-collapse-container"
            key={`${offerType}-disclosure-collpase-container`}
            activeKey={activeDisclosureCollapseKey}
            expandIconPosition={"right"}
            expandIcon={() => {
              if (isActive) {
                return <SaveOutlined />;
              }
              return <EditOutlined />;
            }}
            onChange={() => {
              const collapseKey = isActive ? "" : currentCollapseKey;
              if (
                collapseKey !== activeDisclosureCollapseKey &&
                dataType !== "exception"
              ) {
                setDisclosuresByStateInParent(
                  currentStateTab,
                  currentDisclosuresByOfferType,
                );
              }
              setActiveDisclosureCollapseKey(collapseKey);
            }}
          >
            <Collapse.Panel
              key={currentCollapseKey}
              header={`${offerType} ${
                dataType === "exception" ? "Exception" : "Disclosure"
              }`}
            >
              <DisclosureCollapseContent
                loggedInUser={loggedInUser}
                dataType={dataType}
                isParentTabActive={isActive}
                variables={
                  offerType === "Vehicle Info"
                    ? variables.concat(
                        numberAtThisPriceVariableNames.map(varName => ({
                          name: varName,
                          requiredByStates: [],
                        })),
                      )
                    : variables
                }
                disclosureObjFromParent={foundDisclosureObj}
                setDisclosureObjStateInParent={
                  insertDisclosureObjIntoStateByOfferType
                }
              />
            </Collapse.Panel>
          </Collapse>
        );
      })}
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  const { configuration, auth } = state;
  const { feed } = configuration;
  return {
    feed,
    loggedInUser: auth.user,
  };
};

export default connect(mapStateToProps)(DisclosuresCollapse);
