import { fabric } from "fabric";
import uuid from "uuid";
import * as fabricHelpers from "../helpers.fabric";
import {
  ICustomObjectData,
  ICustomShapeData,
  ICustomVideoData,
  IDimension,
  IExtendedFabricObject,
  imageType,
  IStampObjectData,
} from "shared/types/designStudio";
import carCutImagePath from "statics/images/car_cut.png";
import imagePlaceholderPath from "../../statics/images/stampplaceholder.svg";
import { TLogoDropZone } from "screens/designStudio/editor/canvasContainer/canvas.hooks/useImageInsertData";
import { createLogoFabricObject, getSvgLogoFileUrl } from "./helpers.logo";
import { createVideoElement } from "utils/media/utils.input";

type TCanvasAreaMargin = {
  top: number;
  left: number;
};

type TPasteParams = {
  canvasDimension: IDimension;
  canvasAreaMargin: TCanvasAreaMargin;
  pasteData: IExtendedFabricObject;
};

export const paste = ({
  canvasDimension,
  canvasAreaMargin,
  pasteData,
}: TPasteParams) => {
  const upperCaseType: imageType = (
    !!pasteData.customType
      ? pasteData.customType.toUpperCase()
      : pasteData.type?.toUpperCase() === "TEXTBOX"
      ? "TEXT"
      : pasteData.type?.toUpperCase()
  ) as imageType;
  const { top, left } = canvasAreaMargin;
  const widthToUse = canvasDimension.width / 3;
  const heightToUse = canvasDimension.height / 3;
  const scaledWidth =
    pasteData.width && pasteData.scaleX
      ? pasteData.width * pasteData.scaleX
      : widthToUse;
  const scaledHeight =
    pasteData.height && pasteData.scaleY
      ? pasteData.height * pasteData.scaleY
      : heightToUse;

  const getExtendedCarCutObject = (img: fabric.Image) => {
    img.scaleToWidth(scaledWidth);
    img.set({
      name: uuid(),
      top,
      left,
    });

    const extendedImg = fabricHelpers.extendFabricObject({
      object: img,
      objectType: "car_cut",
      properties: {
        layerName: "",
      },
    });

    return extendedImg;
  };

  const getExtendedLifeStyleObject = () => {
    const rect = new fabric.Rect({
      name: uuid(),
      top,
      left,
      width: scaledWidth,
      height: scaledHeight,
      opacity: pasteData.opacity,
      fill: pasteData.fill,
      selectable: false,
    });

    const extendedRect = fabricHelpers.extendFabricObject({
      object: rect,
      objectType: "lifestyle",
      properties: {
        layerName: "Lifestyle",
      },
    });

    return extendedRect;
  };

  const getExtendedBackgroundObject = () => {
    const themeRect = new fabric.Rect({
      name: uuid(),
      top,
      left,
      width: canvasDimension.width,
      height: canvasDimension.height,
      opacity: 0.8,
      fill: pasteData.fill,
      selectable: false,
    });

    const extendedThemeRect = fabricHelpers.extendFabricObject({
      object: themeRect,
      // dynamic background using same lifestyle objectType
      objectType: "theme_background",
      properties: {
        layerName: "Theme Background",
      },
    });

    return extendedThemeRect;
  };

  const getExtendedStampObject = (img: fabric.Image) => {
    const stampCustomData = pasteData.customData as IStampObjectData &
      ICustomObjectData;
    const { stampName, layerName, stampId } = stampCustomData;
    img.scaleToWidth(scaledWidth);
    img.set({
      top,
      left,
      name: uuid(),
    });

    // The below custom attributes MUST BE IN customFabricAttributes array.
    const extendedImage = fabricHelpers.extendFabricObject({
      objectType: "stamp",
      object: img,
      properties: {
        originalStampId: stampId,
        stampId: uuid(),
        stampName,
        layerName,
      },
    });

    return extendedImage;
  };

  const getExtendedLogoObject = () => {
    const {
      logoEventType,
      logoDropZoneType,
      position: draggedPosition,
      logoDimension,
    } = pasteData.customData as TLogoDropZone;

    const dimension: IDimension = {
      width: logoDimension ? logoDimension.width : scaledWidth,
      height: logoDimension ? logoDimension.height : scaledHeight,
    };

    const logoFileUrl = getSvgLogoFileUrl(logoEventType, logoDropZoneType);

    const position: {
      top: number;
      left: number;
    } = {
      top: top + (draggedPosition?.top || 0),
      left: left + (draggedPosition?.left || 0),
    };

    // automatically corrects width/height ratio for logo here
    return createLogoFabricObject({
      logoFileUrl,
      position,
      eventType: logoEventType,
      dropZone: logoDropZoneType,
      logoDimension: dimension,
      shiftToDraggedPosition: !!draggedPosition,
    });
  };

  const getExtendedShapeObject = () => {
    const shapeCustomData = pasteData.customData as ICustomShapeData &
      ICustomObjectData;
    const defaultColor = "#A9A9A9";
    const { shape } = shapeCustomData;
    const {
      scaleX = 1,
      scaleY = 1,
      fill = defaultColor,
      stroke = "",
      strokeWidth = 1,
    } = pasteData;

    const rx = (pasteData as fabric.Rect).rx ?? 0;
    const ry = (pasteData as fabric.Rect).ry ?? 0;
    const scaledRx = rx * scaleX;
    const scaledRy = ry * scaleY;
    const layerName = shapeCustomData.layerName;
    const shapeId = uuid();

    const shapePosition = {
      top,
      left,
    };

    switch (shape) {
      case "square":
        const rect = new fabric.Rect({
          ...shapePosition,
          name: shapeId,
          width: scaledWidth,
          height: scaledHeight,
          fill,
          selectable: true,
          stroke,
          strokeWidth,
          rx: scaledRx,
          ry: scaledRy,
        });

        const extendedRect = fabricHelpers.extendFabricObject({
          objectType: "shape",
          object: rect,
          properties: {
            layerName,
            shape,
            fill: fill as string,
          },
        });
        extendedRect.set({ strokeUniform: true });
        return extendedRect;

      case "circle":
        const circle = new fabric.Circle({
          name: shapeId,
          ...shapePosition,
          radius: scaledWidth * 0.5,
          fill,
          selectable: true,
          stroke,
          strokeWidth,
        });

        const extendedCircle = fabricHelpers.extendFabricObject({
          objectType: "shape",
          object: circle,
          properties: {
            layerName,
            shape,
            fill: fill as string,
          },
        });

        extendedCircle.set({ strokeUniform: true });
        return extendedCircle;

      case "squircle":
        const squircle = new fabric.Rect({
          name: shapeId,
          ...shapePosition,
          width: scaledWidth,
          height: scaledHeight,
          fill,
          selectable: true,
          stroke,
          strokeWidth,
          rx: scaledRx,
          ry: scaledRy,
        });

        const extendedSquircle = fabricHelpers.extendFabricObject({
          objectType: "shape",
          object: squircle,
          properties: {
            layerName,
            shape,
            fill: fill as string,
          },
        });
        extendedSquircle.set({ strokeUniform: true });
        return extendedSquircle;

      case "triangle":
        const triangle = new fabric.Triangle({
          name: shapeId,
          ...shapePosition,
          width: scaledWidth,
          height: scaledHeight,
          fill,
          selectable: true,
          stroke,
          strokeWidth,
        });

        const extendedTriangle = fabricHelpers.extendFabricObject({
          objectType: "shape",
          object: triangle,
          properties: {
            layerName,
            shape,
            fill: fill as string,
          },
        });
        extendedTriangle.set({ strokeUniform: true });
        return extendedTriangle;
    }
  };

  const getExtendedDisclosureObject = () => {
    const {
      text = "",
      lineHeight = 1.16,
      width,
      fill = "white",
      fontFamily = "HelveticaNeueLTStd-Cn",
      fontSize = 12,
      fontStyle = "normal",
      fontWeight = "normal",
      textAlign = "left",
      opacity = 1,
      charSpacing = 0,
      superscript,
      subscript,
      styles,
    } = pasteData as unknown as fabric.Textbox;

    const textbox = new fabric.Textbox("{Disclosure}", {
      top,
      left,
      width,
      fill,
      name: uuid(),
      fontFamily: fontFamily,
      fontSize: fontSize,
      editable: false,
      text,
      lineHeight,
      fontStyle,
      fontWeight,
      textAlign,
      opacity,
      charSpacing,
      subscript,
      superscript,
      styles,
    });

    fabricHelpers.disableTextboxControls(textbox);

    const extendedDisclosureTextbox = fabricHelpers.extendFabricObject({
      objectType: "disclosure",
      object: textbox,
      properties: {
        layerName: "",
      },
    });

    return extendedDisclosureTextbox;
  };

  const getExtendedTextObject = () => {
    const {
      text = "",
      lineHeight,
      fill = "rgb(0,0,0)",
      fontFamily = "Times New Roman",
      fontSize = 40,
      fontStyle = "normal",
      fontWeight = "normal",
      textAlign = "left",
      opacity = 1,
      charSpacing = 0,
      superscript,
      subscript,
      styles,
      scaleX,
    } = pasteData as unknown as fabric.Textbox;
    const scaledFontSize = fontSize * (scaleX || 1);

    const textboxObject = fabricHelpers.initiateTextbox({
      text,
      properties: {
        width: scaledWidth,
        top,
        left,
        lineHeight,
        name: uuid(),
        objectCaching: false,
        fill,
        fontFamily,
        fontSize: scaledFontSize,
        fontStyle,
        fontWeight,
        textAlign,
        opacity,
        charSpacing,
        superscript,
        subscript,
        styles,
      },
    });

    const extendedTextbox = fabricHelpers.extendFabricObject({
      objectType: "text",
      object: textboxObject,
      properties: {
        layerName: "textbox",
        verticalAlign: "top",
        textboxHeight: textboxObject.getBoundingRect().height,
      },
    });

    return extendedTextbox;
  };

  const getExtendedVideoObject = async () => {
    const { videoSrc, duration } = pasteData.customData as ICustomVideoData;
    const videoElement = await createVideoElement(videoSrc, "mp4");

    const videoObject = new fabric.Image(videoElement, {
      name: uuid(),
      top,
      left,
    });

    videoObject.scaleToWidth(scaledWidth);

    const extendedVideoObject = fabricHelpers.extendFabricObject({
      object: videoObject,
      objectType: "selected_video",
      properties: {
        layerName: "video",
        videoSrc: videoSrc,
        duration: duration,
      },
    });

    return extendedVideoObject;
  };

  const getExtendedImageObject = (img: fabric.Image) => {
    img.scaleToWidth(scaledWidth);
    img.set({
      top,
      left,
      name: uuid(),
    });

    const extendedImage = fabricHelpers.extendFabricObject({
      objectType: "selected_image",
      object: img,
      properties: {
        layerName: "image",
      },
    });

    return extendedImage;
  };

  return new Promise<IExtendedFabricObject | null>(resolve => {
    switch (upperCaseType) {
      case "CAR_CUT":
        fabric.Image.fromURL(
          carCutImagePath,
          img => {
            resolve(getExtendedCarCutObject(img));
          },
          {
            crossOrigin: "anonymous",
          },
        );
        break;

      case "LIFESTYLE":
        resolve(getExtendedLifeStyleObject());
        break;

      case "THEME_BACKGROUND":
        resolve(getExtendedBackgroundObject());
        break;

      case "STAMP":
        {
          const fetchStamp = async () => {
            const thumbnailUrl = pasteData.src;
            const headReq = await fetch(thumbnailUrl || "", {
              method: "HEAD",
            });

            if (headReq.status >= 300) return resolve(null);
            const url = thumbnailUrl || imagePlaceholderPath;

            fabric.Image.fromURL(
              url,
              img => {
                resolve(getExtendedStampObject(img));
              },
              {
                crossOrigin: "anonymous",
              },
            );
          };

          fetchStamp();
        }

        break;

      case "LOGO":
        resolve(getExtendedLogoObject());
        break;

      case "SHAPE":
        resolve(getExtendedShapeObject());
        break;

      case "DISCLOSURE":
        resolve(getExtendedDisclosureObject());
        break;

      case "TEXT":
        resolve(getExtendedTextObject());
        break;

      case "SELECTED_VIDEO":
        resolve(getExtendedVideoObject());
        break;
      case "SELECTED_IMAGE":
        {
          if (!pasteData.src) {
            resolve(null);
            break;
          }

          fabric.Image.fromURL(pasteData.src, img => {
            resolve(getExtendedImageObject(img));
          });
        }
        break;

      // cannot be copied
      case "BACKGROUND":
      default:
        resolve(null);
        return;
    }
  });
};
