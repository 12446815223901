import { SelectProps as AntSelectProps, Tag } from "antd";
import { MIXED_VALUE } from "../constants";
import { useMetaSchema } from "../useMetaSchema";

type SelectMode = "multiple" | "tags" | undefined;
export type SelectProps = AntSelectProps<string | string[]>;

export const useSelect = ({
  id,
  value,
  options,
  mode,
}: SelectProps): SelectProps => {
  const { getSchema } = useMetaSchema();
  const { values, type } = getSchema(id!) || {};

  return {
    tagRender,
    mode: getMode(mode, type),
    options: getOptions(options?.map(({ value }) => value) || values),
    value: getValue(mode, value),
  };
};

const getMode = (mode?: SelectMode, type?: WDMetaDataType): SelectMode => {
  if (mode) {
    return mode;
  }
  if (type === "multiselect") {
    return "multiple";
  }
};

const tagRender = (props: any) => {
  const { label, value, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={value !== MIXED_VALUE}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  );
};

const getOptions = (values: string[] = []) => {
  return values
    .map(value => ({ label: value, value }))
    .filter(v => v.value !== MIXED_VALUE);
};

const getValue = (mode: SelectMode, value?: string | string[]) => {
  if (!value) {
    return [];
  }

  if (Array.isArray(value)) {
    return value;
  }

  return ["multiple", "tags"].includes(mode as string)
    ? value.split(",").map((v: string) => v.trim())
    : value;
};
