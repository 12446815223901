import { getFieldName } from "./getFieldName";
import { getFieldValue } from "./getFieldValue";

export const getSearchField = (
  key: string,
  value: MGSearchValue,
): WDSearchField => {
  return {
    value: getFieldValue(value),
    field_name: getFieldName(key),
    filter_type: "exact",
    operator: Array.isArray(value) ? "OR" : "AND",
  };
};
