import { Modal, Button, Typography } from "antd";
import { orderBy } from "lodash";
import { DesignStudioTableItem } from "screens/designStudio/context/DesignStudioDataProvider";
import { offerTypes } from "shared/constants/dataManagement";
import styles from "../designStudioTable/DesignStudioTable.module.scss";
import { isPublished } from "../../helpers";

export const showEditStampPrompt = (
  stampId: string,
  navigate: (itemId: string, params?: string) => void,
) => {
  Modal.info({
    title: "Select an Offer Type",
    okText: "Close",
    centered: true,
    icon: null,
    content: (
      <div>
        <ul className={styles.offerTypeList}>
          {orderBy(offerTypes).map(offerType => {
            return (
              <li key={`popover-menu-offer-type-${offerType}`}>
                <Button
                  type="link"
                  onClick={() => {
                    navigate(stampId, `offerType=${offerType}`);
                    Modal.destroyAll();
                  }}
                >
                  {offerType}
                </Button>
              </li>
            );
          })}
        </ul>
      </div>
    ),
    onOk: () => {
      Modal.destroyAll();
    },
  });
};

export const showArchivePrompt = (assetNames: string[], onOk: () => void) => {
  Modal.confirm({
    title: "Are you sure you want to archive these assets?",
    onOk,
    content: (
      <div>
        <ul>
          {assetNames.map(assetName => (
            <li key={`bulk-archive-${assetName}`}>
              <Typography.Text strong>{assetName}</Typography.Text>
            </li>
          ))}
        </ul>
      </div>
    ),
  });
};

export const showDeletePrompt = (onOk: () => void) => {
  Modal.confirm({
    title: "Confirm Deletion",
    content: "Are you sure you want to delete this artboard?",
    okText: "Yes",
    cancelText: "No",
    onOk,
  });
};

export const showPublishPrompt = (
  item: DesignStudioTableItem,
  onOk: () => void,
) => {
  const actionName = isPublished(item) ? "un-publish" : "publish";

  Modal.confirm({
    title: `Are you sure you want to ${actionName} this ${item.itemType}?`,
    content: (
      <div>
        <Typography.Text>Are you sure you want to {actionName}</Typography.Text>{" "}
        <Typography.Text strong>{item.asset.name}?</Typography.Text>
      </div>
    ),
    okType: "primary",
    onOk,
  });
};

export const showDuplicatePrompt = (
  item: DesignStudioTableItem,
  onOk: () => void,
) => {
  Modal.confirm({
    title: "Are you sure you want to duplicate this asset?",
    content: (
      <div>
        <Typography.Text>Are you sure you want to duplicate</Typography.Text>{" "}
        <Typography.Text strong>{item.asset.name}?</Typography.Text>
      </div>
    ),

    onOk,
  });
};
