import { Space, Tag } from "antd";
import classNames from "classnames";
import { useFeedContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/FeedContext";
import AutoEllipsisText from "shared/components/AutoEllipsisText";
import { TagsGroup } from "shared/components/TagsGroup";
import { useDataList } from "shared/components/dataList";
import { FeedTblRow } from "shared/types/assetExporter";
import { getShouldDisplayArr } from "utils/helpers.tags";
import styles from "./AssetBatchTags.module.scss";
import { DEFAULT_KEY } from "shared/components/dataList/useDataList";

interface AssetBatchTagsProps {
  value: string | string[];
}

const AssetBatchTags = ({ value }: AssetBatchTagsProps) => {
  const { assetBatches: notSortedAssetBatches, editingAssetBatch } =
    useFeedContext();
  const [dataList] = useDataList<FeedTblRow>();

  const getTagClassName = (isActive: boolean, isUpdatedAssetBatch: boolean) => {
    if (isUpdatedAssetBatch) return classNames(styles.tag, styles.updatedTag);
    if (isActive) return classNames(styles.tag, styles.activeTag);
    return styles.tag;
  };

  const filteredAssetBatcheIds = [
    ...(dataList?.filters[DEFAULT_KEY]?.assetBatches ?? []),
  ].sort();

  const assetBatches = [...notSortedAssetBatches].sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  const validAssetBatches = assetBatches
    ?.filter(({ assetBatchId, name }) => !!assetBatchId && !!name)
    ?.filter(({ assetBatchId }) => value.includes(String(assetBatchId)));

  const filteredAssetBatchNames = validAssetBatches
    .filter(assetBatch =>
      filteredAssetBatcheIds.includes(assetBatch.assetBatchId ?? ""),
    )
    .map(assetBatch => {
      return {
        name: assetBatch.name ?? "",
        assetBatchId: assetBatch.assetBatchId,
      };
    });

  const unfilteredAssetBatchNames = validAssetBatches
    .filter(
      assetBatch =>
        !filteredAssetBatcheIds.includes(assetBatch.assetBatchId ?? ""),
    )
    .map(assetBatch => {
      return {
        name: assetBatch.name ?? "",
        assetBatchId: assetBatch.assetBatchId,
      };
    });

  const assetBatchesNameAndId = [
    ...filteredAssetBatchNames,
    ...unfilteredAssetBatchNames,
  ];

  const tags = assetBatchesNameAndId.map(({ name }) => name);
  if (!assetBatchesNameAndId?.length) return null;
  const shouldDisplayArr = getShouldDisplayArr(
    assetBatchesNameAndId.map(({ name }) => name),
  );

  return (
    <Space size={[0, 8]} wrap>
      {assetBatchesNameAndId.map(
        (
          assetBatch: {
            name: string;
            assetBatchId: string | undefined;
          },
          idx: number,
        ) => {
          const tag = assetBatch.name;
          if (!shouldDisplayArr[idx] && shouldDisplayArr[idx - 1]) {
            const remainingTags = [...tags].splice(idx, tags.length - idx);
            const filteredRemainingTags = remainingTags.filter(remainingTag =>
              filteredAssetBatchNames
                .map(({ name }) => name)
                .includes(remainingTag),
            );
            return (
              <TagsGroup
                tags={remainingTags}
                key={tag}
                activeTags={filteredRemainingTags}
              />
            );
          }
          if (!shouldDisplayArr[idx]) return;
          const isActive = filteredAssetBatchNames.some(
            assetBatchName => assetBatchName.name === tag,
          );
          const isUpdatedAssetBatch =
            editingAssetBatch?.assetBatchId === assetBatch.assetBatchId;
          return (
            <Tag
              className={getTagClassName(isActive, isUpdatedAssetBatch)}
              key={tag}
            >
              <AutoEllipsisText>{tag}</AutoEllipsisText>
            </Tag>
          );
        },
      )}
    </Space>
  );
};

export default AssetBatchTags;
