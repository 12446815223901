import { Space, Tag } from "antd";
import AutoEllipsisText from "./AutoEllipsisText";
import { TagsGroup } from "./TagsGroup";
import styles from "./TagColumn.module.scss";
import { getShouldDisplayArr } from "utils/helpers.tags";

export type TagType<KeyType extends string = string> = {
  key: KeyType;
  value: React.Key | boolean;
  displayValue?: string;
};

type TagColumnProps = {
  tags: Array<string>;
};

const TagColumn = ({ tags }: TagColumnProps) => {
  const shouldDisplayArr = getShouldDisplayArr(tags);

  return (
    <Space size={[0, 8]} wrap>
      {tags.map((tag: string, idx: number) => {
        if (!shouldDisplayArr[idx] && shouldDisplayArr[idx - 1]) {
          const remainingTags = [...tags].splice(idx, tags.length - idx);

          return <TagsGroup tags={remainingTags} key={tag} />;
        }

        if (!shouldDisplayArr[idx]) return;

        return (
          <Tag className={styles.tag} key={tag}>
            <AutoEllipsisText>{tag}</AutoEllipsisText>
          </Tag>
        );
      })}
    </Space>
  );
};

export default TagColumn;
