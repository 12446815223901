import { FC, memo, useState } from "react";
import { Collapse } from "antd";

import orderBy from "lodash/orderBy";
import groupBy from "lodash/groupBy";

import StampCard from "./stampCardListContainer/StampCard";
import CardListContainer from "shared/components/CardListContainer";

import {
  HeaderMenu,
  IDesignStudioState,
  IStamp,
} from "shared/types/designStudio";

import "./StampCardListContainer.scss";

interface IListContainer {
  oem: string;
  stamps: IStamp[];

  selectedHeader: HeaderMenu;
  selectedFilterOemStamp: IDesignStudioState["selectedFilterOemStamp"];
  selectedFilterStateStamp: IDesignStudioState["selectedFilterStateStamp"];

  searchInput: string;
}

const StampListContainer: FC<IListContainer> = ({
  oem,
  stamps,
  selectedHeader,
  selectedFilterOemStamp,
  selectedFilterStateStamp,

  searchInput,
}) => {
  // first group by id
  const groupedById = stamps.reduce((acc, stamp) => {
    if (acc[stamp.id]) {
      return acc;
    }

    acc[stamp.id] = stamp;

    return acc;
  }, {} as Record<string, IStamp>);

  const groupedByDimensions = groupBy(
    Object.keys(groupedById).reduce((acc, stampId) => {
      acc.push(groupedById[stampId]);

      return acc;
    }, [] as IStamp[]),
    stamp => `${stamp.width}x${stamp.height}`,
  );

  const sortedDimensionKeys = orderBy(Object.keys(groupedByDimensions), [
    key => Number.parseInt(key.split("x")[0], 10),
    key => Number.parseInt(key.split("x")[1], 10),
  ]);

  const [dimensionActiveKey, setDimensionActiveKey] = useState("");
  const shouldFilterByOem = !!selectedFilterOemStamp?.length;
  const shouldFilterByState = !!selectedFilterStateStamp?.length;

  const returnFilteredStamps = (stamps: IStamp[]) => {
    return stamps.filter(stamp => {
      // no filters have been set. List all stamps.
      if (!shouldFilterByOem && !shouldFilterByState) {
        return true;
      } else if (shouldFilterByOem && !shouldFilterByState) {
        for (const oemsStamp of stamp.oems || []) {
          if (selectedFilterOemStamp?.[0]?.includes(oemsStamp)) {
            return true;
          }
        }
        return false;
      } else if (!shouldFilterByOem && shouldFilterByState) {
        return (selectedFilterStateStamp as string[]).includes(stamp.state);
      } else {
        // This else covers if both filters are set
        for (const oemsStamp of stamp.oems || []) {
          if (
            selectedFilterOemStamp?.[0]?.includes(oemsStamp) &&
            selectedFilterStateStamp?.includes(stamp.state)
          ) {
            return true;
          }
        }
        return false;
      }
    });
  };

  const returnedStampsFilteredBySearch = (
    stamps: IStamp[],
    searchInput: string,
  ) => {
    const filteredStamps = returnFilteredStamps(stamps);
    if (!searchInput) {
      return filteredStamps;
    }
    return filteredStamps.filter(stamp =>
      stamp.name.toLowerCase().includes(searchInput.toLowerCase()),
    );
  };

  return (
    <>
      {sortedDimensionKeys.map(dim => {
        return (
          <Collapse
            className="collapse-artboard-container stamp-list-artboard-collapse-container"
            activeKey={dimensionActiveKey}
            key={`stamp-list-panel-${oem}-${dim}`}
          >
            <Collapse.Panel
              key={`stamp-list-panel-${oem}-${dim}`}
              className={`stamp-list-panel-${oem}-${dim}`}
              header={
                <div
                  className={`stamp-list-collapse-artboard ${oem}-${dim}`}
                  onClick={() => {
                    dimensionActiveKey === `stamp-list-panel-${oem}-${dim}`
                      ? setDimensionActiveKey("")
                      : setDimensionActiveKey(`stamp-list-panel-${oem}-${dim}`);
                  }}
                >
                  <span className="title">{`${dim}`}</span>
                  <span className="number-counter">
                    {groupedByDimensions[dim].length}
                  </span>
                </div>
              }
            >
              <div
                className="stamp-list-container"
                key={`${dim}-list-container`}
              >
                <div className="stamp-card-list-container">
                  <CardListContainer
                    virtualized
                    cards={returnedStampsFilteredBySearch(
                      groupedByDimensions[dim],
                      searchInput,
                    ).map(stamp => (
                      <StampCard
                        key={`stamp-card-${stamp.id}`}
                        selectedHeader={selectedHeader}
                        stamp={stamp}
                      />
                    ))}
                  />
                </div>
              </div>
            </Collapse.Panel>
          </Collapse>
        );
      })}
    </>
  );
};

export default memo(StampListContainer);
