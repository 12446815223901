import { Col, Form, Input, Row, Select } from "antd";
import MultiSelectAllTags from "screens/dashboardManagement/MultiSelectAllTags";
import { IDashboard } from "shared/types/dashboardManagement";
import { DateValueSelector } from "./shared/components/DateValueSelector";
import { DateValueSelectorProps } from "./shared/hooks/useDateValueSelector";
import { splitCsvWithException } from "./shared/utils";

export const PublicLinkFilters = ({
  dashboard,
  dateSelection,
  visible,
  showDateValueSelector,
  setShowDateValueSelector,
  handleChangeMultiSelectAllTags,
  selectedOems,
  selectedAccounts,
}: {
  dashboard?: IDashboard;
  dateSelection: DateValueSelectorProps;
  visible: boolean;
  showDateValueSelector: boolean;
  setShowDateValueSelector: (showing: boolean) => void;
  handleChangeMultiSelectAllTags: (
    field: "oem" | "account",
    value: string,
  ) => void;
  selectedOems: string;
  selectedAccounts: string;
}) => {
  const { isValid, updateSelection, validateSelection, error, selection } =
    dateSelection;

  const getOptions = (csv?: string): string[] => {
    return !csv ? [] : splitCsvWithException(csv);
  };

  const brands = getOptions(dashboard?.oem);
  const accounts = getOptions(dashboard?.account);
  const campaigns = getOptions(dashboard?.specialCampaign);

  return (
    <div style={{ display: visible ? "block" : "none" }}>
      <Row>
        <p>Configure the details of the dashboard you want to share:</p>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item label="Brand">
            <MultiSelectAllTags
              field="oem"
              value={selectedOems}
              placeholder="Select brands"
              options={brands.map(brand => ({
                title: brand,
                value: brand,
              }))}
              onChange={(_, values) =>
                handleChangeMultiSelectAllTags("oem", values)
              }
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Account">
            <MultiSelectAllTags
              field="account"
              value={splitCsvWithException(selectedAccounts)}
              options={
                accounts?.map(account => ({
                  title: account,
                  value: account,
                })) || []
              }
              onChange={(_, values) =>
                handleChangeMultiSelectAllTags("account", values)
              }
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Campaign" name="campaigns">
            <Select
              mode="multiple"
              placeholder="is any value"
              disabled={!campaigns.length}
            >
              {campaigns?.map(camp => (
                <Select.Option key={camp} value={camp}>
                  {camp}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Date"
            validateStatus={!isValid ? "error" : undefined}
            help={error}
          >
            <Form.Item name="dateRange" noStyle>
              <Input type="hidden" />
            </Form.Item>
            <Input
              value={selection?.inputValue}
              onClick={e => {
                e.stopPropagation();
                setShowDateValueSelector(true);
              }}
              style={{ position: "relative" }}
            />
            {showDateValueSelector && (
              <DateValueSelector
                dateValueSelection={selection}
                updateValue={sel => {
                  updateSelection(sel);
                }}
                hide={() => {
                  setShowDateValueSelector(false);
                  validateSelection();
                }}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};
