import { CommaField, EditedFieldId } from "shared/types/assetBuilder";
import { StatusOptions, LADStatusOptions } from "shared/types/newOrders";
import { AppColors } from "shared/types/shared";
import { timezoneOffset } from "utils/helpers";

export const getColorFromStatus = (
  Status: StatusOptions | LADStatusOptions,
) => {
  switch (Status) {
    case LADStatusOptions.PENDING:
      return AppColors.primary;
    case LADStatusOptions.APPROVED:
      return AppColors.success;
    case LADStatusOptions.NO_STATUS:
      return AppColors.secondary;
    case StatusOptions.NO_STATUS:
      return AppColors.secondary;
    case StatusOptions.COOP_READY_TO_SUBMIT:
    case StatusOptions.COOP_SUBMITTED:
      return AppColors.primary;
    case StatusOptions.COOP_NOT_REQUIRED:
    case StatusOptions.COOP_APPROVED:
      return AppColors.success;
    case StatusOptions.COOP_DENIED:
      return AppColors.danger;
    default:
      return "";
  }
};

export const convertDate = (epochStamp: number): string => {
  const createdTimeStampCon = new Date(epochStamp + timezoneOffset);
  const currentCreatedDate: string =
    createdTimeStampCon.getMonth() +
    1 +
    "/" +
    createdTimeStampCon.getDate() +
    "/" +
    createdTimeStampCon.getFullYear().toString().substr(-2);
  return currentCreatedDate;
};

export const getFieldKeyId = (
  key: CommaField,
  index: number,
  section?: string,
): EditedFieldId => {
  if (section) return `${key}-${index}-${section}`;
  return `${key}-${index}`;
};
