import { Menu, message } from "antd";
import { imageType, TShape } from "shared/types/designStudio";
import { InsertMenu } from "../Toolbar";
import { context } from "../../../Editor.context";
import { IConfigurationState } from "shared/types/configuration";
import {
  createVideoElement,
  getUriFromUrl,
  readFileFromInput,
} from "utils/media/utils.input";
import { capitalize } from "lodash";
import { IUploadImage } from "shared/types/uploadManagement";
import { v4 as uuid } from "uuid";
import {
  CAMSelect,
  CAM_ENABLED,
  getCAMHiResUrl,
} from "shared/components/media";
import API from "services";

import styles from "./SubMenu.module.scss";
import { FC, useContext } from "react";
import classNames from "classnames";

interface SubMenuProps {
  menuType: InsertMenu;
  config: IConfigurationState["config"];
}

interface SubMenuHandlers {
  toggleInsertMenuPopover: (show: boolean) => void;
}

const SubMenu: FC<SubMenuProps & SubMenuHandlers> = props => {
  const editorContext = useContext(context);

  return (
    <Menu
      className={classNames(styles.SubMenu, styles.shapeMenu)}
      selectedKeys={[]}
    >
      {props.menuType === "Shapes" &&
        (["square", "circle", "triangle", "squircle"] as TShape[]).map(
          shape => (
            <Menu.Item
              key={`shape-menu-item-${shape}`}
              className={styles.shapeMenuItem}
              onClick={e => {
                e.domEvent.preventDefault();

                editorContext?.setImageInsertData({
                  type: "SHAPE",
                  data: shape,
                });

                // Suppose to close shape menu when one of the shape is clicked.
                // But calling toggleShapeMenu(false) was not closing it.
                // So closing the shape menu will be done in one of useEffect
                props.toggleInsertMenuPopover(false);
              }}
            >
              <div className={styles.shapeWrapper}>
                {shape === "triangle" && (
                  <div className={styles.triangleInnerWrapper}>
                    <div
                      className={classNames(styles.shape, styles[shape])}
                    ></div>
                  </div>
                )}

                {shape !== "triangle" && (
                  <div
                    className={classNames(styles.shape, styles[shape])}
                  ></div>
                )}
              </div>
            </Menu.Item>
          ),
        )}

      {props.menuType === "Background" && (
        <Menu className={styles.SubMenu} selectedKeys={[]}>
          {(["canvas", "theme"] as ("canvas" | "theme")[]).map(subMenuType => (
            <Menu.Item
              key={`${subMenuType}`}
              onClick={() => {
                switch (subMenuType) {
                  case "theme":
                    editorContext?.setImageInsertData({
                      type: "THEME_BACKGROUND",
                    });
                    break;

                  case "canvas":
                    readFileFromInput("image").then(data => {
                      const type: imageType = "BACKGROUND";
                      editorContext?.setImageInsertData({
                        type,
                        data,
                      });
                    });
                    break;
                }
                props.toggleInsertMenuPopover(false);
              }}
            >
              {capitalize(subMenuType)}
            </Menu.Item>
          ))}
        </Menu>
      )}

      {props.menuType === "Media" && (
        <Menu className={`${styles.SubMenu}`} selectedKeys={[]}>
          {CAM_ENABLED && (
            <Menu.Item
              key="cam"
              onClick={() => {
                props.toggleInsertMenuPopover(false);
              }}
              className={styles.CamContainer}
            >
              <CAMSelect
                maxSelected={1}
                fileTypes={["png", "jpeg", "jpg", "mp4", "mov"]}
                onClose={async ([asset]) => {
                  const { filetype, filename, filesize } = asset;
                  const url = getCAMHiResUrl(asset, true);
                  const type: imageType = ["mp4", "mov"].includes(filetype)
                    ? "SELECTED_VIDEO"
                    : "SELECTED_IMAGE";

                  const fileType =
                    type === "SELECTED_VIDEO"
                      ? `video/${filetype}`
                      : `image/${filetype}`;

                  editorContext?.setImageInsertData({
                    type,
                    data: {
                      dataUri: await getUriFromUrl(url, filename),
                      file: {
                        type: fileType,
                        size: +filesize,
                      },
                      sourceUrl: url,
                      element:
                        type === "SELECTED_VIDEO"
                          ? await createVideoElement(url, "mp4")
                          : undefined,
                    },
                  });
                }}
              >
                <span>CAM</span>
              </CAMSelect>
            </Menu.Item>
          )}
          {(["video", "image"] as ("video" | "image")[]).map(subMenuType => (
            <Menu.Item
              key={`${subMenuType}`}
              onClick={() => {
                const messageKey = `uploading-${subMenuType}`;

                readFileFromInput(subMenuType)
                  .then(data => {
                    message.loading({
                      key: messageKey,
                      content: `Uploading ${subMenuType}...`,
                    });

                    const fileExtension = data.file.type.split("/")[1];
                    const uploadImageParams: IUploadImage = {
                      imageData: data.dataUri,
                      filename: `${uuid()}.${fileExtension}`,
                      fileType: data.file.type,
                      featureName: "design-studio",
                    };

                    API.services.designStudio
                      .uploadVideo(uploadImageParams)
                      .then(async ({ result, error }) => {
                        if (error || !result) {
                          message.error({
                            key: messageKey,
                            content: error?.message || "Unknown error.",
                          });
                          return;
                        }
                        const { images } = result;
                        if (images?.length !== 1) {
                          message.error({
                            key: messageKey,
                            content: "Unknown error.",
                          });
                          return;
                        }

                        const { imageUrl } = images[0];

                        const type: imageType =
                          subMenuType === "image"
                            ? "SELECTED_IMAGE"
                            : "SELECTED_VIDEO";

                        message.destroy(messageKey);

                        editorContext?.setImageInsertData({
                          type,
                          data: {
                            ...data,
                            sourceUrl: imageUrl,
                            element:
                              subMenuType === "video"
                                ? await createVideoElement(imageUrl, "mp4")
                                : undefined,
                          },
                        });
                      });
                  })
                  .catch(errorMessage => {
                    message.error({
                      key: messageKey,
                      content: errorMessage,
                    });
                  });

                props.toggleInsertMenuPopover(false);
              }}
            >
              {capitalize(subMenuType)}
            </Menu.Item>
          ))}
        </Menu>
      )}
    </Menu>
  );
};

export default SubMenu;
