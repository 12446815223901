import { CSSProperties } from "react";
import { ReactComponent as UnpublishOutlinedIcon } from "statics/images/svg/icons/unpublish-o.svg";

export interface IProps {
  width?: string | number;
  height?: string | number;
  style?: CSSProperties;
}

const UnpublishOutlined = ({
  width = "1em",
  height = "1em",
  style,
}: IProps) => {
  return (
    <span role="img" aria-label="un-publish" className="anticon" style={style}>
      <UnpublishOutlinedIcon width={width} height={height} />
    </span>
  );
};

export default UnpublishOutlined;
