import { Menu, Popover } from "antd";
import { FC } from "react";
import { IConfigurationState } from "shared/types/configuration";
import { IExtendedFabricObject } from "shared/types/designStudio";
import useVariables from "../canvas.hooks/useVariables";

export type TVariableSelection = {
  top: number;
  left: number;
  width: number;
  height: number;
  object: IExtendedFabricObject;
};

interface VariableSelectionProps {
  variableSelection: TVariableSelection;
  currMenuFilter: string;
  feed: IConfigurationState["feed"];
  config: IConfigurationState["config"];
}

interface VariableSelectionHandlers {
  onVisibleChange: (visible: boolean) => void;
  setVariableSelection: (
    variableSelection: IExtendedFabricObject | undefined,
  ) => void;
  setCurrMenuFilter: (currMenuFilter: string) => void;
  onTextUpdated: (args: {
    selectionOffset: number;
    text: string;
    object: IExtendedFabricObject;
  }) => void;
}

const VariableSelection: FC<
  VariableSelectionProps & VariableSelectionHandlers
> = props => {
  const variables = useVariables({ feed: props.feed });
  const filteredVariables = props.currMenuFilter
    ? variables?.filter(variable => variable.includes(props.currMenuFilter))
    : variables;

  return (
    <Popover
      visible={!!props.variableSelection}
      trigger="click"
      onVisibleChange={props.onVisibleChange}
      placement="bottom"
      title={"Insert Variable"}
      content={
        <div
          style={{
            width: "20em",
            height: "20em",
            overflowY: "auto",
          }}
        >
          <Menu multiple={false}>
            {filteredVariables?.map(variable => {
              return (
                <Menu.Item
                  key={`${variable}`}
                  title={variable}
                  onClick={() => {
                    props.onTextUpdated({
                      selectionOffset: props.currMenuFilter.length,
                      text: `${variable}}`,
                      object: props.variableSelection.object,
                    });
                    props.setVariableSelection(undefined);
                    props.setCurrMenuFilter("");
                  }}
                >
                  {variable}
                </Menu.Item>
              );
            })}
          </Menu>
        </div>
      }
    >
      <div
        style={{
          display: !!props.variableSelection ? "block" : "none",
          position: "absolute",

          backgroundColor: "transparent",
          zIndex: -1,

          width: props.variableSelection?.width,
          height: props.variableSelection?.height,

          top: props.variableSelection?.top,
          left: props.variableSelection?.left,
        }}
      />
    </Popover>
  );
};

export default VariableSelection;
