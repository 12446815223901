import { Col, Row, Switch } from "antd";
import { useAssetBatchesContext } from "../../contexts/AssetBatchesContext";
import { useAssetBatchesValueMappingContext } from "../../contexts/AssetBatchesValueMappingContext";
import styles from "./NavTemplateItems.module.scss";
import { videoCompositionEnabled } from "shared/constants/assetExporter";
import { Navigation } from "./Navigation";

export const NavTemplateItems = () => {
  const {
    rows,
    setPreviewLoading,
    previewCount,
    compositions,
    setPreventAutoScroll,
  } = useAssetBatchesContext();

  const { onSelectedRowChange, showVariablesOn, setShowVariablesOn } =
    useAssetBatchesValueMappingContext();

  const handleNavigate = (index: number) => {
    setPreviewLoading(true);
    setPreventAutoScroll(true);
    compositions.forEach(composition => {
      onSelectedRowChange(rows[index], composition.compositionId);
    });
    setPreviewLoading(false);
  };

  return (
    <>
      <Navigation totalAssetCount={previewCount} onNavigate={handleNavigate} />
      {videoCompositionEnabled && (
        <div className={styles.showVariablesContainer}>
          <Row>
            <Col>
              <span>Show variables</span>
            </Col>
            <Col className={styles.switchContainer}>
              <Switch
                size={"small"}
                onChange={setShowVariablesOn}
                checked={showVariablesOn}
              />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};
