import moment from "moment";
import { useMemo } from "react";
import { useMutatePublicDashboard } from "shared/hooks/dashboardManagement/useMutateDashboard";
import { useUser } from "shared/hooks/useUser";
import {
  IDashboard,
  DateValueSelection,
  IPublicDashboard,
  IShareDashboardForm,
} from "shared/types/dashboardManagement";

export const useManagePublicDashboard = (
  dashboard?: IDashboard,
  publicDashboard?: IPublicDashboard,
) => {
  const {
    mutate: createOrUpdatePublicDashboard,
    data,
    reset,
  } = useMutatePublicDashboard();
  const user = useUser();

  const getExpirationDate = (formData: IShareDashboardForm) => {
    if (!formData.expirationEnabled || !formData.expirationDate) return;

    return getMomentUnixMs(moment(formData.expirationDate));
  };

  const getMomentUnixMs = (time: moment.Moment) => time.unix() * 1000;

  const publicUrl = useMemo(() => {
    if (!data) return;
    return `${window.location.origin.replace(/\/$/, "")}/metrics/share/${
      publicDashboard?.id ?? data?.id
    }`;
  }, [data, publicDashboard]);

  const getDateRange = (dateValueSelection?: DateValueSelection) => {
    if (!dateValueSelection) return;
    return dateValueSelection.datePeriod === "isInRange"
      ? dateValueSelection.dateRange
      : [moment().subtract(1, dateValueSelection.previousInterval), moment()];
  };

  const getDateValueSelectionStr = (sel?: DateValueSelection) => {
    if (!sel) return;
    if (!sel.dateRange || sel.dateRange.length === 0) {
      return JSON.stringify(sel);
    }
    return JSON.stringify({
      ...sel,
      dateRange: [
        getMomentUnixMs(sel.dateRange[0]),
        getMomentUnixMs(sel.dateRange[1]),
      ],
    });
  };

  const upsertPublicLink = (
    formData: IShareDashboardForm,
    dateValueSelection?: DateValueSelection,
  ) => {
    const dateRange = getDateRange(dateValueSelection);

    const requestBody = {
      id: publicDashboard?.id ?? data?.id,
      dashboardId: publicDashboard?.dashboardId ?? dashboard?.id ?? "",
      account: formData.accounts?.join(",") ?? "",
      oem: formData.oems?.join(",") ?? "",
      specialCampaign: formData.campaigns?.join(","),
      startDate: dateRange?.[0] ? getMomentUnixMs(dateRange?.[0]) : undefined,
      endDate: dateRange?.[1] ? getMomentUnixMs(dateRange?.[1]) : undefined,
      password: formData.passwordEnabled ? formData.password : undefined,
      expirationDate: getExpirationDate(formData),
      createdBy: user?.email,
      dateValueSelection: getDateValueSelectionStr(dateValueSelection),
    };

    createOrUpdatePublicDashboard(requestBody);
  };

  return {
    publicUrl,
    upsertPublicLink,
    reset,
  };
};
