import { isEmpty } from "lodash";
import { StringParam, useQueryParam } from "use-query-params";

export const useDataListURLGlobalFilter = () => {
  const [globalFilter, setGlobalFilterParam] = useQueryParam(
    "globalFilter",
    StringParam,
  );

  const setGlobalFilter = (value: string) => {
    setGlobalFilterParam(isEmpty(value) ? undefined : value);
  };

  return {
    globalFilter,
    setGlobalFilter,
  };
};
