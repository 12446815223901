import { UploadFile } from "antd/lib/upload/interface";
import { useMutation, useQueryClient } from "react-query";
import { getErrorMessage } from "utils/errorMessage";
import {
  BrandRecord,
  IUpdateBrandResult,
  ILogos,
} from "shared/types/brandsAccounts";
import { BRANDS_QUERY_KEY } from "shared/constants/brandsAccounts";
import { uploadImagesToS3 } from "./uploadImageToS3";
import { formatBrandForTable } from "./useGetFormattedBrands";
import API from "services";
import { uploadFontsToS3 } from "./uploadFontsToS3";
import { useBrandsAccountsContext } from "screens/brandsAccountsManagement/contexts/BrandsAccountsContext";
import {
  errorNotification,
  successNotification,
} from "shared/components/customNotification/Notification";

interface IUpdateBrandParams {
  name: string;
  logosToUpload: ILogos;
  existingLogos: ILogos;
  fontsToUpload: UploadFile[];
  existingFonts: UploadFile[];
  createdAt: number;
  logosToRemove: string[];
  fontsToRemove: string[];
  youtubeConnected?: boolean;
  id?: string;
}

const updateBrand = async ({
  logosToUpload,
  existingLogos,
  name,
  createdAt,
  logosToRemove,
  existingFonts,
  fontsToRemove,
  fontsToUpload,
  youtubeConnected,
}: IUpdateBrandParams) => {
  try {
    const uploadedFontsPromise = uploadFontsToS3(fontsToUpload, existingFonts);
    const uploadedLogosPromise = uploadImagesToS3(
      logosToUpload,
      "brands-management",
      existingLogos,
    );

    const [uploadedFonts, uploadedLogos] = await Promise.all([
      uploadedFontsPromise,
      uploadedLogosPromise,
    ]);

    const { error, result } =
      await API.services.oemManagement.updateOem<IUpdateBrandResult>(
        {
          oem_name: name,
          logo_urls_from_S3: JSON.stringify(uploadedLogos),
          enabled: true,
          event_logos: "",
          created_at: createdAt ?? Date.now(),
          updated_at: Date.now(),
          logo_url: uploadedLogos.squareImagesFromS3[0] ?? "",
          fonts: uploadedFonts,
          youtubeConnected,
        },
        logosToRemove,
        fontsToRemove,
      );

    if (error) throw new Error(error.message);
    if (!result) throw new Error("Error updating the brand");

    return result;
  } catch (error) {
    const errMsg = getErrorMessage(error);
    throw new Error(errMsg);
  }
};

interface UseUpdateBrandProps {
  onSuccess: (brand: BrandRecord) => void;
}

export const useUpdateBrand = ({ onSuccess }: UseUpdateBrandProps) => {
  const queryClient = useQueryClient();
  const { setFormTargetElementForUpdate } = useBrandsAccountsContext();

  return useMutation<IUpdateBrandResult, Error, IUpdateBrandParams>(
    updateBrand,
    {
      onSuccess: updatedBrand => {
        let formattedBrand = formatBrandForTable(
          updatedBrand.updatedOem,
          undefined,
        );

        queryClient.setQueryData(
          [BRANDS_QUERY_KEY],
          (brands?: BrandRecord[]) => {
            return (
              brands?.map(brand => {
                if (brand.name === formattedBrand.name) {
                  formattedBrand = {
                    ...formattedBrand,
                    children: brand.children,
                  };

                  return formattedBrand;
                }

                return brand;
              }) ?? [formattedBrand]
            );
          },
        );

        setFormTargetElementForUpdate(formattedBrand);
        onSuccess(formattedBrand);
        successNotification({
          messageLabel: "Brand updated successfully",
          size: "big",
        });
      },
      onError: () => {
        errorNotification({
          messageLabel: "Error updating a brand",
          size: "big",
        });
      },
    },
  );
};
