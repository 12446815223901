import { UploadOutlined } from "@ant-design/icons";
import { Button, Row, Upload, message } from "antd";

import * as helpers from "../../utils/helpers";

import { RcFile } from "antd/lib/upload";
import {
  IUploadImageFormInput,
  IOfferYMMT,
} from "shared/types/uploadManagement";
import { FC, useState, useEffect } from "react";

interface IImageUploaderInsideSteps {
  s3AssetBucket?: string;
  handleImageChange: (imageToUpload: IUploadImageFormInput) => void;

  jellybeanUpload?: boolean;
  setLogo: (logoObject: any) => void;
  logoObject: any;

  title: string;
  toggleUpload?: (upload: boolean) => void;
  toggleUploadError?: (error: boolean) => void;
  modifyDimensionsString?: (dimensionsString: string) => void;
  clearFiles?: boolean;
  enteredYMMT?: IOfferYMMT;
}

interface IImageUploaderProps {
  listType: "text" | "picture-card" | "picture" | undefined;
  defaultFileList?: any[];
  fileList?: any[];
  className: string;
  multiple: boolean;
}

const ImageUploaderInsideSteps: FC<IImageUploaderInsideSteps> = ({
  handleImageChange,
  title,
  setLogo,
  logoObject,
  toggleUpload,
  toggleUploadError,
  modifyDimensionsString,
  clearFiles,
  jellybeanUpload,
  enteredYMMT,
}) => {
  const [ymmt, setYmmt] = useState<IOfferYMMT>(
    enteredYMMT ?? {
      year: "",
      make: "",
      model: "",
    },
  );

  useEffect(() => {
    if (enteredYMMT) {
      const { year, make, model } = enteredYMMT;

      setYmmt(enteredYMMT);
      if (year && make && model) {
        toggleUpload?.(false);
        return;
      }
      toggleUpload?.(true);
      return;
    }
  }, [enteredYMMT, toggleUpload]);

  const props: IImageUploaderProps = {
    listType: "text",
    className: "upload-list-inline",
    multiple: false,
  };

  const returnImageFeedback = (file: { size?: number; type: string }) => {
    let validUpload;
    let feedbackMessage = [];
    const { size, type } = file;
    const isJpgOrPng = type === "image/jpeg" || type === "image/png";
    const ismax1M = size ? size / 1024 / 1024 <= 1 : false;

    // jellybeans uploaded with this uploader have a 2MB limit instead of 1MB
    const ismax2MB = size ? size / 1024 / 1024 <= 2 : false;

    if (!ismax1M) {
      validUpload = false;
      feedbackMessage.push("Image must smaller than 1MB");
    }

    if (!isJpgOrPng) {
      validUpload = false;
      feedbackMessage.push("The Image must be a JPG/PNG file");
    }

    if (isJpgOrPng && (ismax1M || (jellybeanUpload && ismax2MB))) {
      toggleUploadError?.(false);
      validUpload = true;
      feedbackMessage = [""];
    }

    if (jellybeanUpload && !ismax2MB) {
      toggleUploadError?.(true);
      validUpload = false;
      message.warning("File uploaded exceeds the limit of 2MB");
    }

    return {
      validUpload,
      feedbackMessage: `${feedbackMessage.join(", ")}`,
    };
  };

  let manualFileList: RcFile[] = [];
  return (
    <Row>
      <span style={{ display: "flex" }}>
        <div style={{ paddingLeft: "20px" }}>
          <Upload
            {...props}
            fileList={clearFiles ? [] : manualFileList}
            beforeUpload={(file, fileList) => {
              // processes file
              const validateImage = returnImageFeedback(file);
              if (modifyDimensionsString) {
                const _URL = window.URL || window.webkitURL;
                const img = new Image();
                img.src = _URL.createObjectURL(file);
                img.onload = () => {
                  modifyDimensionsString(
                    `Dimensions: ${img.width}px x ${img.height}px Format: ${
                      file.type
                    }
                    ${
                      (file.size / 1024 / 1024 > 2 &&
                        jellybeanUpload &&
                        "\nImage is too large. Maximum accepted size is 2mb") ||
                      ""
                    }
                    `,
                  );
                };
              }
              const { validUpload, feedbackMessage } = validateImage;

              if (validUpload) {
                helpers.getBase64DataUrl(file, imageDataUrl => {
                  if (
                    typeof imageDataUrl === "string" &&
                    imageDataUrl.length > 0
                  ) {
                    const tempImage = document.createElement("img");
                    tempImage.setAttribute("src", imageDataUrl);

                    let validDimension: number | boolean = false;
                    switch (title) {
                      case "Horizontal":
                        validDimension =
                          Math.ceil((tempImage.height * 2) / 10) * 10 ===
                          Math.ceil(tempImage.width / 10) * 10;
                        break;
                      case "Vertical":
                        validDimension =
                          Math.ceil(tempImage.height / 10) * 10 ===
                          Math.ceil((tempImage.width * 2) / 10) * 10;
                        break;
                      case "Square":
                        validDimension =
                          Math.ceil(tempImage.height / 10) * 10 ===
                          Math.ceil(tempImage.width / 10) * 10;
                        break;

                      case "Jellybean":
                        validDimension = true;
                        const { year, make, model } = ymmt;
                        if (year && make && model) toggleUpload?.(false);
                        break;

                      default:
                        validDimension = false;
                        break;
                    }

                    if (validDimension) {
                      const guidFilename = helpers.generateGuidFilename(
                        file.name,
                      );
                      handleImageChange({
                        type: file.type,
                        file: imageDataUrl || null, // to locate s3 bucket
                        filename: guidFilename, // unique filename for image when we upload to s3 to not overwite any images
                        preview: imageDataUrl, // image preview before s3
                        size: file.size,
                      });
                      setLogo({
                        ...logoObject,
                        [`${title.toLowerCase()}`]: {
                          type: file.type,
                          file: imageDataUrl || null,
                          filename: guidFilename,
                          preview: imageDataUrl,
                          size: file.size,
                        },
                        feedbackMessage: "",
                      });
                    } else {
                      setLogo({
                        ...logoObject,
                        feedbackMessage: `Invalid Image Dimensions for ${title}`,
                      });
                    }
                  }
                });
              } else {
                setLogo({
                  ...logoObject,
                  feedbackMessage,
                });
              }
              manualFileList = fileList;
              return false;
            }}
          >
            <Button style={{ zIndex: 99 }}>
              <UploadOutlined />
              {jellybeanUpload ? "Upload File" : "Attach File"}
            </Button>
          </Upload>
        </div>
      </span>
    </Row>
  );
};

export default ImageUploaderInsideSteps;
