import { message, Empty } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import drawerStyles from "../AdDetailsDrawer.module.scss";
import styles from "./AdQCHistory.module.scss";
import { AdReviewTableEntry } from "shared/types/campaignManagement";
import useQCHistoryLogs from "shared/hooks/qcHistoryLogs/useQCHistoryLogs";
import { IFacebookAd } from "screens/adLibrary/facebookUtils/types";
import Title from "antd/lib/typography/Title";
import { groupBy, uniqWith } from "lodash";
import moment from "moment";
import { formatDateValue } from "utils/helpers";
import Log from "./AdQCHistory/Log";

interface IProps {
  selectedEntry: AdReviewTableEntry | undefined;
  facebookAdsFromEntry: IFacebookAd[] | undefined;
}

type AdQCHistoryProps = IProps;

const AdQCHistory = ({
  selectedEntry,
  facebookAdsFromEntry,
}: AdQCHistoryProps) => {
  const qcHistoryLogsContext = useQCHistoryLogs({ id: selectedEntry?.id });
  const {
    qcHistoryLogs,
    qcHistoryLogsError,
    isQCHistoryLogsError,
    loadingQCHistoryLogs,
  } = qcHistoryLogsContext();

  const getGroupKey = (updatedAt: string) => {
    const today = new Date();
    const yesterday = moment().subtract(1, "day").toDate();

    if (formatDateValue(today.toDateString()) === formatDateValue(updatedAt)) {
      return "Today";
    } else if (
      formatDateValue(yesterday.toDateString()) === formatDateValue(updatedAt)
    ) {
      return "Yesterday";
    }

    return moment(formatDateValue(updatedAt)).fromNow();
  };

  const groupHistoryLogs = () => {
    return groupBy(qcHistoryLogs, historyLog =>
      getGroupKey(historyLog.updatedAt!),
    );
  };

  const GroupedHistory = () => {
    const groupedLogs = Object.entries(groupHistoryLogs());

    return (
      <>
        {groupedLogs.map(([groupName, historyGroup]) => {
          const dedupedHistoryGroup = uniqWith(
            historyGroup,
            (log1, log2) =>
              log1.updatedAt === log2.updatedAt && log1.id === log2.id,
          );
          return (
            <li key={groupName}>
              <Title level={5} type="secondary">
                {groupName}
              </Title>
              <ul>
                {dedupedHistoryGroup.map(log => {
                  const uniqueKey = `${log.updatedAt}_${log.adId}`;
                  return <Log key={uniqueKey} log={log} />;
                })}
              </ul>
            </li>
          );
        })}
      </>
    );
  };

  const QCHistoryLogs = () => {
    if (loadingQCHistoryLogs) {
      return <LoadingOutlined />;
    } else if (Object.entries(groupHistoryLogs()).length === 0) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    } else {
      return <ul className={styles.list}>{<GroupedHistory />}</ul>;
    }
  };

  if (isQCHistoryLogsError || qcHistoryLogsError) {
    message.error(qcHistoryLogsError?.message || "Something went wrong.");
  }

  return (
    <div
      data-cy="ad-qc-history-logs"
      className={drawerStyles.module}
      style={{ minWidth: 400 }}
    >
      <Title style={{ margin: 0 }} level={4} className={drawerStyles.title}>
        <b>QC History Log</b>
      </Title>
      <div className={drawerStyles.moduleContent}>{<QCHistoryLogs />}</div>
    </div>
  );
};

export default AdQCHistory;
