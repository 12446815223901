import adLibrary from "./adLibrary/adLibrary.slice";
import adReview from "./adReview/adReview.slice";
import assetBuilder from "./assetBuilder/assetBuilder.slice";
import assetExporter from "./assetExporter/assetExporter.slice";
import auth from "./auth/auth.slice";
import configuration from "./configuration/configuration.slice";
import dealerManagement from "./dealerManagement/dealerManagement.slice";
import designStudio from "./designStudio/designStudio.slice";
import legalLingo from "./legalLingo/legalLingo.slice";
import newOrders from "./newOrders/newOrders.slice";
import oemManagement from "./oemManagement/oemManagement.slice";
import shared from "./shared/shared.slice";
import settings from "./settings/settings.slice";
import uploadManagement from "./uploadManagement/uploadManagement.slice";
import userManagement from "./userManagement/userManagement.slice";
import mediaReducers from "./media";

/**
 * Follow this rule.
 *  - The name of the reduce should match the parent directory name that contains the reducer.
 *  - Each sub-directory withing redux should be lower case.
 *  - Each sub-directory should contain actions and reducer.
 */
export const reducers = {
  adLibrary,
  adReview,
  assetBuilder,
  assetExporter,
  auth,
  configuration,
  dealerManagement,
  designStudio,
  legalLingo,
  newOrders,
  oemManagement,
  shared,
  settings,
  uploadManagement,
  userManagement,
  ...mediaReducers,
};
