import { useMemo } from "react";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { useFetchFacebookObjects } from "shared/hooks/adLibrary/facebook/useFetchFacebookObject";
import { useFetchProductSetsForMultipleCatalogs } from "shared/hooks/adLibrary/facebook/useFetchProductSets";
import { useFetchInstantExperiences } from "shared/hooks/adLibrary/useFetchInstantExperiences";
import { useFetchInfiniteDealers } from "shared/hooks/useFetchDealers";

const useFacebookData = () => {
  const selectedAccounts = useAppSelector(
    ({ adLibrary }) => adLibrary.selectedFacebookAccounts,
  );

  const {
    dealers,
    isLoading: loadingDealers,
    isFetching: fetchingDealers,
  } = useFetchInfiniteDealers();

  const selectedStores = useMemo(
    () =>
      dealers?.filter(dealer =>
        selectedAccounts?.find(account => account.name === dealer.dealer_name),
      ),
    [selectedAccounts, dealers],
  );

  const { data: instantExperiences, isLoading: loadingIEs } =
    useFetchInstantExperiences();

  const { data: pages, isLoading: loadingPages } = useFetchFacebookObjects(
    {
      adAccounts: selectedAccounts ?? [],
      objectType: "page",
      skipNameMatching: true,
    },
    {
      enabled: !!selectedAccounts?.length,
    },
  );

  const { data: productCatalogs, isLoading: loadingCatalogs } =
    useFetchFacebookObjects(
      {
        adAccounts: selectedAccounts ?? [],
        objectType: "catalog",
        skipNameMatching: true,
      },
      {
        enabled: !!selectedAccounts?.length,
      },
    );

  const productCatalogIds = useMemo(
    () =>
      selectedStores
        ?.map(dealer => dealer.details.facebook.fbCatalogId)
        .filter(id => !!id),
    [selectedStores],
  );

  const { data: productSets, isLoading: loadingProductSets } =
    useFetchProductSetsForMultipleCatalogs({
      productCatalogIds: productCatalogIds,
    });

  const loadingData = useMemo(
    () =>
      [
        loadingProductSets,
        loadingCatalogs,
        loadingDealers,
        fetchingDealers,
        loadingPages,
        loadingIEs,
      ].includes(true),
    [
      fetchingDealers,
      loadingCatalogs,
      loadingDealers,
      loadingIEs,
      loadingPages,
      loadingProductSets,
    ],
  );

  return {
    instantExperiences,
    loadingData,
    pages,
    productCatalogs,
    productSets,
    selectedStores,
  };
};

export default useFacebookData;
