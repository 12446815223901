import { memo } from "react";
import {
  IInstantExperience,
  InstantExperienceBodyElement,
} from "shared/types/adLibrary";
import ButtonPreview from "./LivePreview/ButtonPreview";
import styles from "./LivePreview.module.scss";
import VideoPreview from "./LivePreview/VideoPreview";
import ImagePreview from "./LivePreview/ImagePreview";
import CarouselPreview from "./LivePreview/CarouselPreview";
import { LeftOutlined } from "@ant-design/icons";
import ProductSetPreview from "./LivePreview/ProductSetPreview";

interface IProps {
  instantExperience: IInstantExperience;
  handleSelectInstantExperienceId?: (instantExperienceId: string) => void;
  handleBack?: () => void;
}

export const LIVE_PREVIEW_WIDTH = 405;
export const LIVE_PREVIEW_HEIGHT = 720;

const LivePreview = ({
  instantExperience,
  handleSelectInstantExperienceId,
  handleBack,
}: IProps) => {
  const getComponent = (
    instantExperienceElement?: InstantExperienceBodyElement,
    isLastElement?: boolean,
  ) => {
    switch (instantExperienceElement?.element_type) {
      case "BUTTON":
      case "FOOTER":
        return (
          <ButtonPreview
            instantExperienceElement={instantExperienceElement}
            handleSelectInstantExperienceId={handleSelectInstantExperienceId}
          />
        );
      case "PHOTO":
        return (
          <ImagePreview
            photoElement={instantExperienceElement}
            isLastElement={isLastElement}
            handleSelectInstantExperienceId={handleSelectInstantExperienceId}
          />
        );

      case "VIDEO":
        return <VideoPreview videoElement={instantExperienceElement} />;

      case "CAROUSEL":
        return (
          <CarouselPreview
            carouselElement={instantExperienceElement}
            handleSelectInstantExperienceId={handleSelectInstantExperienceId}
          />
        );

      case "ELEMENT_GROUP":
        return (
          <ProductSetPreview productSetElement={instantExperienceElement} />
        );

      default:
        return null;
    }
  };
  const isFooterElement = (element: InstantExperienceBodyElement) =>
    element.element_type === "FOOTER";
  const isNotFooterElement = (element: InstantExperienceBodyElement) =>
    !isFooterElement(element);
  return (
    <div
      className={styles.livePreviewContainer}
      style={{
        backgroundColor: `#${instantExperience.backgroundColor}`,
        width: LIVE_PREVIEW_WIDTH,
        height: LIVE_PREVIEW_HEIGHT,
      }}
    >
      <div
        className={styles.backIcon}
        onClick={handleBack}
        style={{ cursor: handleBack ? "pointer" : "auto" }}
      >
        <LeftOutlined />
      </div>
      <div className={styles.scrollableArea}>
        {instantExperience?.body_elements
          ?.filter(isNotFooterElement)
          ?.map((element, index, elements) => {
            const isLastElement = index === elements.length - 1;
            return (
              <div key={element.id}>{getComponent(element, isLastElement)}</div>
            );
          })}
      </div>
      <div>
        {instantExperience?.body_elements
          ?.filter(isFooterElement)
          ?.map(element => (
            <div key={element.id}>{getComponent(element)}</div>
          ))}
      </div>
    </div>
  );
};

export default memo(LivePreview);
