import { useMemo } from "react";

import { useAppShallowEqualSelector } from "shared/hooks/useAppSelector";

import { useFetchInfiniteDealers } from "shared/hooks/useFetchDealers";
import { useFetchInstantExperiences } from "shared/hooks/adLibrary/useFetchInstantExperiences";

export const useGetRequiredData = () => {
  const selectedAccounts = useAppShallowEqualSelector(
    ({ adLibrary }) => adLibrary.selectedFacebookAccounts,
  );
  const { data: instantExperiences = [] } = useFetchInstantExperiences();
  const { dealers = [] } = useFetchInfiniteDealers();
  const selectedDealers = useMemo(
    () =>
      dealers?.filter(dealer =>
        selectedAccounts?.find(account => account.name === dealer.dealer_name),
      ),
    [selectedAccounts, dealers],
  );
  return { selectedAccounts, selectedDealers, instantExperiences };
};
