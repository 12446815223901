import { ComponentProps } from "react";
import { SelectMeta } from "../SelectMeta";
import { useGetKeywordsQuery } from "redux/media/media.api";

type SelectProps = ComponentProps<typeof SelectMeta>;

export const KeywordsSelect = (props: SelectProps) => {
  const { data: keywords } = useGetKeywordsQuery();
  return <SelectMeta {...props} mode="multiple" options={keywords} />;
};
