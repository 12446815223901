import { DeleteOutlined } from "@ant-design/icons";
import { Button, Tooltip, Modal } from "antd";
import { Tab } from "shared/types/assetBuilder";

import styles from "./TabDeleteButton.module.scss";

type Props = {
  tab: Tab;
  onDelete: (tab: Tab) => void;
};

const TabDeleteButton = (props: Props) => {
  const { tab, onDelete } = props;
  return (
    <div className={styles.TabDeleteButton}>
      <span>{tab.name}</span>
      <Tooltip title="Remove feed">
        <Button
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();

            Modal.confirm({
              title: `Are you sure you want to delete ${tab.name}?`,
              content: `Click Ok if you want to delete ${tab.name}`,
              onOk: () => onDelete(tab),
            });
          }}
          type="text"
          icon={<DeleteOutlined className={styles.DeleteIcon} />}
        />
      </Tooltip>
    </div>
  );
};

export default TabDeleteButton;
