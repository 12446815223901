import { Input, Form } from "antd";
import React, { FC } from "react";
import { IOfferDataInputProps } from "shared/types/assetBuilder";

export const OfferInputCalculated: FC<IOfferDataInputProps> = ({
  title,
  value,
  error,
  className,
}) => (
  <Form.Item
    className={className}
    help={error || "This value is calculated from other fields"}
    label={title}
  >
    <Input
      className={`value ${error ? "error" : ""}`}
      type="text"
      disabled={true}
      value={value}
    />
  </Form.Item>
);
