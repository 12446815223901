import { QueryFunctionContext, useInfiniteQuery, useQuery } from "react-query";
import API from "services";
import {
  IGetBrandsResponse,
  IGetBrandResponse,
  IBrand,
  IGetBrandsResult,
  IBrandRecord,
  LogoUrlsFromS3,
} from "shared/types/brandManagement";

export const useFetchOems = () => {
  const result = useInfiniteQuery<IGetBrandsResponse["result"], Error>(
    "oems",
    getOems,
    {
      staleTime: Infinity,
      getNextPageParam: lastPage => lastPage?.paginationKey?.oem_name || false,
    },
  );

  if (result.hasNextPage && !result.isFetchingNextPage) {
    result.fetchNextPage();
  }

  const data = accumulateOemPages(result.data?.pages);

  const oems = [...data].sort((oem1, oem2) => {
    if (oem1.oem_name.toLowerCase() === "generic") return -1;
    if (oem2.oem_name.toLowerCase() === "generic") return 1;
    return oem1.oem_name.localeCompare(oem2.oem_name);
  });

  return {
    ...result,
    oems,
  };
};

export const accumulateOemPages = (
  pages: (IGetBrandsResult | null)[] | undefined,
): IBrand[] =>
  pages?.reduce((acc, page) => acc.concat(page?.oems || []), [] as IBrand[]) ??
  [];

export const useFetchOem = (name: string) => {
  return useQuery<IGetBrandResponse["result"], Error>(
    ["oem", name],
    getOem(name),
  );
};

const getOems = async ({
  pageParam: paginationToken,
}: QueryFunctionContext) => {
  const { result, error } =
    await API.services.oemManagement.getOems<IGetBrandsResponse>(
      paginationToken,
    );

  if (error) {
    throw Error(error.message);
  }

  return result;
};

const getOem = (name: string) => async () => {
  if (!name) {
    return null;
  }
  const { result, error } =
    await API.services.oemManagement.getOem<IGetBrandResponse>(name);
  if (error) {
    throw Error(error.message);
  }

  return result;
};

export const toBrandRecord = (brand: IBrand): IBrandRecord => {
  const {
    oem_name: oemName,
    logo_url: logoUrl,
    event_logos: eventLogosStr,
    logo_urls_from_S3,
    created_at: createdAt,
    updated_at: updatedAt,
    enabled,
  } = brand;
  let logoUrlsFromS3: LogoUrlsFromS3 | undefined;
  try {
    logoUrlsFromS3 = logo_urls_from_S3
      ? JSON.parse(logo_urls_from_S3)
      : {
          horizontalEventImagesFromS3: [],
          horizontalImagesFromS3: [],
          squareEventImagesFromS3: [],
          squareImagesFromS3: [],
          verticalEventImagesFromS3: [],
          verticalImagesFromS3: [],
        };
  } catch (_) {
    // nothing to fill..
  }
  return {
    key: oemName,
    oemName,
    logoUrl,
    eventLogos: eventLogosStr?.split(",") ?? [],
    logoUrlsFromS3,
    createdAt,
    updatedAt,
    enabled,
  };
};
