import { Key, useState } from "react";
import { TableProps } from "antd";
import AssetGroupDetailDrawer from "screens/adLibrary/pmaxAssetGroups/pmaxAssetGroupList/AssetGroupDetailDrawer";
import usePmaxAssetGroupColumns from "screens/adLibrary/pmaxAssetGroups/pmaxAssetGroupList/hooks/usePmaxAssetGroupColumns";
import PmaxLoadDrawer from "screens/adLibrary/pmaxAssetGroups/PmaxLoadDrawer";
import { pmaxTooltipProps } from "screens/adLibrary/pmaxAssetGroups/utils";
import { usePMaxDataContext } from "shared/components/contextAPI/pmaxAssetGroup/PMaxDataProvider";
import { TableEmptyMessage } from "shared/components/TableEmptyMessage";
import ToolbarTable from "shared/components/toolbarTable/ToolbarTable";
import VirtualTable from "shared/components/VirtualTable";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { IAssetGroup } from "shared/types/adLibrary";
import { useSelectedAssetGroupsTableToolbar } from "./selectedAssetGroupsTable/useSelectedAssetGroupsTableToolbar";
import { usePmaxCampaignsContext } from "screens/campaignManagement/googlePerformanceMax/PmaxCampaignsProvider";

type SelectedAssetGroupsTableProps = {
  openAssetGroupDrawer: () => void;
};

export const SelectedAssetGroupsTable = ({
  openAssetGroupDrawer,
}: SelectedAssetGroupsTableProps) => {
  const { campaignDrawer, setCampaignDrawer } = usePmaxCampaignsContext();
  const {
    setFilteredInfo,
    dataSource,
    dataFilters,
    setDataFilters,
    fetchingData,
  } = usePMaxDataContext();
  const assetGroupsToDisplay = campaignDrawer?.assetGroupIds
    ? dataSource.filter(assetGroup =>
        campaignDrawer?.assetGroupIds?.includes(assetGroup.id),
      )
    : [];

  const columns = usePmaxAssetGroupColumns(assetGroupsToDisplay, [
    "name",
    "feedName",
    "feedStatus",
    "pmaxStatus",
  ]);

  const handleChange: TableProps<IAssetGroup>["onChange"] = (
    _pagination,
    filters,
    _sorter,
  ) => {
    setFilteredInfo(filters);
  };

  const { windowInnerHeight } = useWindowSize();
  const [rowSelection, setRowSelection] = useState<Key[]>([]);

  const { toolbarContents } = useSelectedAssetGroupsTableToolbar({
    rowSelection,
    setRowSelection,
    openAssetGroupDrawer,
  });

  const hasAssetGroups = Boolean(assetGroupsToDisplay?.length);
  return (
    <div>
      {hasAssetGroups && (
        <ToolbarTable
          toolbarContents={toolbarContents}
          clientType={"auto"}
          searchPlaceholder="Search"
          searchValue={dataFilters?.search || ""}
          tooltipProps={pmaxTooltipProps}
          onSearch={search => {
            setDataFilters?.({
              displaySelectedOnly: dataFilters?.displaySelectedOnly || false,
              search,
            });
          }}
          titleTooltip="Search by any asset group information"
        />
      )}
      <VirtualTable
        data-cy="ad-table-container"
        className="ad-table-container"
        scroll={{ y: windowInnerHeight - 245 }}
        loading={fetchingData}
        rowKey="id"
        size="small"
        pagination={false}
        showHeader={hasAssetGroups}
        columns={columns}
        onChange={handleChange}
        dataSource={assetGroupsToDisplay}
        locale={{
          emptyText: (
            <TableEmptyMessage
              message="No Asset Groups yet"
              action={openAssetGroupDrawer}
              actionText="Add Asset Groups"
            />
          ),
        }}
        rowSelection={{
          selectedRowKeys: rowSelection,
          onChange: setRowSelection,
          preserveSelectedRowKeys: true,
        }}
      />
      <AssetGroupDetailDrawer
        onSuccess={ids => {
          setCampaignDrawer({
            assetGroupIds: [...campaignDrawer.assetGroupIds, ...ids],
          });
        }}
      />
      <PmaxLoadDrawer />
    </div>
  );
};
