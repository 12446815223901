import API from "services";
import { useMutation, useQueryClient } from "react-query";
import {
  ICreateOrUpdateNotificationResponse,
  IBatchUpdateNotificationsResponse,
  IBatchCreateNotificationsResponse,
  INotification,
} from "shared/types/notification";

const createOrUpdateNotification = async (notification: INotification) => {
  // TODO: fix return type
  const { result, error } =
    await API.services.notification.createOrUpdateNotification<ICreateOrUpdateNotificationResponse>(
      notification,
    );

  if (!result) {
    throw Error(
      error?.message ||
        "Something went wrong while creating/updating notification",
    );
  }
  return result;
};

const batchCreateNotifications = async (notifications: INotification[]) => {
  // TODO: fix return type
  const { result, error } =
    await API.services.notification.batchCreateNotifications<IBatchCreateNotificationsResponse>(
      notifications,
    );

  if (!result) {
    throw Error(
      error?.message ||
        "Something went wrong while batch creating notifications",
    );
  }
  return result;
};

const batchUpdateNotifications = async (notifications: INotification[]) => {
  // TODO: fix return type
  const { result, error } =
    await API.services.notification.batchUpdateNotifications<IBatchUpdateNotificationsResponse>(
      notifications,
    );

  if (!result) {
    throw Error(
      error?.message ||
        "Something went wrong while batch updating notifications",
    );
  }
  return result;
};

export const useMutateNotification = () => {
  const queryClient = useQueryClient();
  return useMutation<
    ICreateOrUpdateNotificationResponse["result"],
    Error,
    INotification
  >(createOrUpdateNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries("notifications");
    },
  });
};

export const useBatchUpdateNotification = () => {
  const queryClient = useQueryClient();
  return useMutation<
    IBatchUpdateNotificationsResponse["result"],
    Error,
    INotification[]
  >(batchUpdateNotifications, {
    onSuccess: () => {
      queryClient.invalidateQueries("notifications");
    },
  });
};

export const useBatchCreateNotification = () => {
  const queryClient = useQueryClient();
  return useMutation<
    IBatchCreateNotificationsResponse["result"],
    Error,
    INotification[]
  >(batchCreateNotifications, {
    onSuccess: () => {
      queryClient.invalidateQueries("notifications");
    },
  });
};
