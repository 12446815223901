import { message } from "antd";
import moment from "moment";
import { queryClient } from "queryClient";
import { useEffect } from "react";
import {
  useFetchDashboard,
  useFetchPublicDashboard,
} from "shared/hooks/dashboardManagement/useFetchDashboards";
import { useValidatePassword } from "shared/hooks/dashboardManagement/useMutateDashboard";
import { useFetchPublicLookerIframe } from "shared/hooks/metrics/useFetchLookerIframe";
import {
  IGetPublicDashboardResponseData,
  IPublicDashboard,
} from "shared/types/dashboardManagement";

export const useDisplayPublicDashboard = (id: string) => {
  const { data: publicDashboardData, isLoading: loadingPublicDashboard } =
    useFetchPublicDashboard(id, fetchPublicDashboardSuccess);
  const { data: dashboardData } = useFetchDashboard(
    publicDashboardData?.dashboard.dashboardId,
  );
  const {
    mutate: validatePassword,
    isLoading: validatePasswordLoading,
    isError,
    isSuccess,
  } = useValidatePassword();

  const isAuthorized = () => {
    if (loadingPublicDashboard) return;
    if (!!publicDashboardData?.dashboard.isProtected === false) return true;
    if (!validatePasswordLoading && isError) return false;
    return !validatePasswordLoading && isSuccess;
  };

  const authorized = isAuthorized();
  const expired =
    publicDashboardData && dashboardIsExpired(publicDashboardData.dashboard);

  const { data: iframeUrl, isLoading: loadingIframe } =
    useFetchPublicLookerIframe(
      authorized && !expired ? publicDashboardData?.dashboard : undefined,
    );

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries([
        "metrics",
        publicDashboardData?.dashboard,
      ]);
    };
  }, [publicDashboardData?.dashboard]);

  return {
    publicDashboardData,
    loadingPublicDashboard,
    dashboardData,
    iframeUrl,
    loadingIframe,
    validatePassword,
    expired,
    authorized,
    isError,
  };
};

const dashboardIsExpired = (dashboard: IPublicDashboard) => {
  if (!dashboard.expirationDate) return false;
  return moment(dashboard.expirationDate).isBefore(moment());
};

const fetchPublicDashboardSuccess = (
  data: IGetPublicDashboardResponseData | null,
) => {
  if (data && dashboardIsExpired(data?.dashboard)) {
    message.destroy();
    message.error("The dashboard is expired", 0);
  }
};
