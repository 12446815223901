import { FC, useState } from "react";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { Button, Form, Space } from "antd";

import { IOfferDataInputProps } from "shared/types/assetBuilder";

import Placeholder from "statics/images/image-placeholder.png";
import ErrorImage from "statics/images/missing-thumbnail.png";

export const OfferImageCarousel: FC<IOfferDataInputProps> = ({
  value,
  title,
  className = "",
}) => {
  const [currentImgIndex, setCurrentImgIndex] = useState(0);

  // We assume that | will be the separated for image urls
  const imageUrls: string[] = value?.split(",") ?? [];
  const currentImgSrc = imageUrls[currentImgIndex] || "";

  const isOnFirstImg = currentImgIndex === 0;
  const isOnLastImg = currentImgIndex === imageUrls.length - 1;

  return (
    <Form.Item className={className} label={title}>
      <Space direction="horizontal" size="small" align="center">
        <Button
          type="link"
          icon={<LeftCircleOutlined />}
          disabled={isOnFirstImg}
          onClick={() => {
            if (isOnFirstImg) return;
            setCurrentImgIndex(currentImgIndex - 1);
          }}
        />
        <img
          style={{ width: 128 }}
          src={currentImgSrc || Placeholder}
          alt={title}
          onError={event => {
            (event.target as HTMLImageElement).src = ErrorImage;
          }}
        />
        <Button
          type="link"
          icon={<RightCircleOutlined />}
          disabled={isOnLastImg}
          onClick={() => {
            if (isOnLastImg) return;
            setCurrentImgIndex(currentImgIndex + 1);
          }}
        />
      </Space>
    </Form.Item>
  );
};
