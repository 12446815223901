import { useMemo } from "react";
import { useDataListContext } from "./DataListContext";
import { IDataListActions, IDataListState } from "./types";

export const DEFAULT_KEY = "default";

export const useDataList = <TData = any>(): [
  IDataListState<TData>,
  IDataListActions,
] => {
  const [state, dispatch] = useDataListContext();
  const actions: IDataListActions = useMemo(
    () => ({
      setFilters(filters, tableId) {
        dispatch({
          type: "SET_FILTERS",
          payload: { tableId: tableId ?? DEFAULT_KEY, filters },
        });
      },
      updateFilter(key, value, tableId) {
        dispatch({
          type: "UPDATE_FILTER",
          payload: { tableId: tableId ?? DEFAULT_KEY, key, value },
        });
      },
      removeFilter(key, value, tableId) {
        dispatch({
          type: "REMOVE_FILTER",
          payload: { tableId: tableId ?? DEFAULT_KEY, key, value },
        });
      },
      resetFilters(tableId) {
        dispatch({
          type: "RESET_FILTERS",
          payload: { tableId: tableId ?? DEFAULT_KEY },
        });
      },
      sortItems(columnKey, order) {
        dispatch({
          type: "SORT_ITEMS",
          payload: { columnKey, order },
        });
      },
      setSelectedItems(ids) {
        dispatch({
          type: "SET_SELECTED_ITEMS",
          payload: { ids },
        });
      },
      toggleSelectedItems() {
        dispatch({
          type: "TOGGLE_SELECTED_ITEMS",
        });
      },
      toggleSelectedItem(id: string) {
        dispatch({
          type: "TOGGLE_SELECTED_ITEM",
          payload: { id },
        });
      },
      setGlobalFilter(value) {
        dispatch({
          type: "SET_GLOBAL_FILTER",
          payload: { value },
        });
      },
    }),
    [dispatch],
  );

  return [state, actions];
};
