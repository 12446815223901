import { Dispatch, FC, SetStateAction, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useAppDispatch } from "shared/hooks/useAppDispatch";

import * as assetBuilderActions from "redux/assetBuilder/assetBuilder.slice";

import {
  IAssetBuilderState,
  OfferData,
  RawOfferData,
  OfferEditFieldObject,
} from "shared/types/assetBuilder";
import OfferFormPaymentEngineModal from "./offers/select/offerForm/OfferFormPaymentEngineModal";

import { IConfigurationState } from "shared/types/configuration";
import { IAccountManagementState } from "shared/types/accountManagement";
import { TOfferCollapseData } from "./offers/SelectV2";

export type TOfferListData = {
  selected?: TOfferCollapseData[];
  available?: TOfferCollapseData[];
};

interface PaymentEngineModalProps {
  showDSModal: boolean;
  toggleDSModal: (showDSModal: boolean) => void;
  setEditedVin?: Dispatch<SetStateAction<string | undefined>>;
  initialSelectedRow: OfferEditFieldObject;
  offerListData?: TOfferListData;
  vin: string;
}

const PaymentEngineModal: FC<PaymentEngineModalProps> = ({
  showDSModal,
  toggleDSModal,
  setEditedVin,
  initialSelectedRow,
  offerListData,
  vin,
}) => {
  const dispatch = useAppDispatch();
  const {
    processedPaymentEngineData,
    rawPaymentEngineData,
    getPaymentEngineDataError,
    offerData,
    processingExport,
    currentDealerData,
  } = useSelector(
    ({
      assetBuilder,
      dealerManagement,
      configuration,
    }: {
      assetBuilder: IAssetBuilderState;
      dealerManagement: IAccountManagementState;
      configuration: IConfigurationState;
    }) => ({
      savedOrder: assetBuilder.savedOrder,
      processedPaymentEngineData: assetBuilder.processedPaymentEngineData,
      rawPaymentEngineData: assetBuilder.rawPaymentEngineData,
      getPaymentEngineDataError: assetBuilder.getPaymentEngineDataError,
      offerData: assetBuilder.offerData,
      processingExport: assetBuilder.processingExport,
      offerList: assetBuilder.offerList,
      vehicleConditionFilter: assetBuilder.vehicleConditionFilter,
      searchQuery: assetBuilder.searchQuery,
      sortByFilter: assetBuilder.sortByFilter,
      checkedOfferFilters: assetBuilder.checkedOfferFilters,
      feed: configuration.feed,
      currentDealerData: dealerManagement.dealerResult?.dealer || null,
    }),
    shallowEqual,
  );

  useEffect(() => {
    const foundSelectedOfferFromData = offerListData?.selected?.find(
      selectedOffer => selectedOffer.offerData?.vin === vin,
    );
    const foundSelectedOffer = foundSelectedOfferFromData
      ? {
          offerData: foundSelectedOfferFromData?.offerData,
          offers: foundSelectedOfferFromData?.offerTypes,
        }
      : undefined;

    const isFoundAvailableOffer = !!offerListData?.available?.find(
      availableOffer => availableOffer.offerData?.vin === vin,
    );

    const updateOfferDataWithSelected = async () => {
      await dispatch(
        assetBuilderActions.setEditOfferData({
          rawOfferData: {
            ...(foundSelectedOffer?.offerData as RawOfferData),
            vin: vin ?? "",
          },
          editedKeys: {},
          operation: "UPDATE",
        }),
      );
    };

    const updateOfferDataWithAvailable = async () => {
      await dispatch(
        assetBuilderActions.setEditOfferData({
          rawOfferData: {
            ...(offerListData?.available?.find(
              availableOffer => availableOffer.offerData?.vin === vin,
            )?.offerData as RawOfferData),
            vin: vin ?? "",
          },
          editedKeys: {},
          operation: "UPDATE",
        }),
      );
    };

    if (showDSModal) {
      if (foundSelectedOffer) {
        updateOfferDataWithSelected();
      } else if (isFoundAvailableOffer) {
        updateOfferDataWithAvailable();
      }
    }
  }, [
    dispatch,
    offerListData?.available,
    offerListData?.selected,
    vin,
    showDSModal,
  ]);

  useEffect(() => {
    if (showDSModal && vin) {
      dispatch(assetBuilderActions.setOfferDataComparator(offerData!));
    }
  }, [dispatch, vin, offerData, showDSModal]);

  return (
    <OfferFormPaymentEngineModal
      getPaymentEngineDataReset={() =>
        dispatch(assetBuilderActions.getPaymentEngineDataReset())
      }
      showDSModal={showDSModal}
      toggleDSModal={toggleDSModal}
      setEditedVin={setEditedVin}
      processedPaymentEngineData={processedPaymentEngineData}
      rawPaymentEngineData={rawPaymentEngineData}
      getPaymentEngineDataError={getPaymentEngineDataError}
      offerData={offerData as OfferData}
      editField={(key, value) =>
        dispatch(assetBuilderActions.editOffer({ key, value }))
      }
      processingExport={processingExport}
      initialSelectedRow={initialSelectedRow}
      currentDealerData={currentDealerData}
    />
  );
};

export default PaymentEngineModal;
