import { Input } from "antd";
import { ChangeEvent, useContext } from "react";
import { DrawerContext } from "../../DrawerContext/DrawerContext";

export const DrawerInputString = () => {
  const { FULL, artboard, mode = "", setArtboard } = useContext(DrawerContext);

  const isUpdate = mode === "UPDATE";

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const updateKey = isUpdate ? "new_name" : "name";
    setArtboard({ ...artboard, [updateKey]: value });
  };
  return (
    <Input
      data-cy="artboard-name-input"
      type="text"
      disabled={isUpdate || FULL}
      value={isUpdate ? artboard.name : artboard.new_name}
      onChange={onChange}
    />
  );
};
