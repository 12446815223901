import { SearchOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { ForwardRefRenderFunction, forwardRef } from "react";
import styles from "./SearchInput.module.scss";

const SearchInput: ForwardRefRenderFunction<Input, FilterDropdownProps> = (
  props,
  ref,
) => {
  return (
    <div className={styles.searchFilter}>
      <Input
        ref={ref}
        value={props.selectedKeys[0]}
        onChange={e =>
          props.setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => props.confirm({ closeDropdown: true })}
      />
      <div className={styles.buttons}>
        <Button
          type="primary"
          onClick={() => props.confirm({ closeDropdown: true })}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            props.clearFilters?.();
            props.setSelectedKeys([]);
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    </div>
  );
};

export default forwardRef(SearchInput);
