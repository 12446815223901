import { DragEvent } from "react";
import { Connection, Edge, isEdge, OnLoadParams } from "react-flow-renderer";
import {
  DataType,
  EAElements,
  EAFlowElement,
  Element,
  isUrlData,
} from "screens/everythingAds.hooks/useContextAPI";
import { ICarouselElement } from "shared/types/adLibrary";
import {
  isAd,
  isButton,
  isCarousel,
  isFooter,
  isImage,
  isInstantExp,
} from "utils/adLibrary.validators";

type GetHandleTopArgs = {
  idx: number;
  offset?: {
    top?: number;
  };
  pivotHeight: number;
  gap?: number;
};

export const getHandleTop = (args: GetHandleTopArgs) =>
  (args.offset?.top || 0) +
  args.pivotHeight / 2 +
  (args.pivotHeight * args.idx + (args.gap || 0) * args.idx);

export const onDragOver = (e: DragEvent<HTMLElement>) => {
  e.preventDefault();
  e.dataTransfer.dropEffect = "move";
};

type DragContentType = {
  type: "ie" | "ad" | "url";
  element: Element;
};
export const onDrop = async (
  e: DragEvent<HTMLElement>,
  flowWrapper: HTMLDivElement | null,
  flowInstance?: OnLoadParams<any>,
) => {
  e.preventDefault();

  try {
    const reactFlowBounds = flowWrapper?.getBoundingClientRect();

    const position = flowInstance?.project({
      x: e.clientX - (reactFlowBounds?.left ?? 0),
      y: e.clientY - (reactFlowBounds?.top ?? 0),
    });

    if (!position) throw new Error("There was an error.");

    const contentStr = e.dataTransfer.getData("text/plain") as string;
    const { element } = JSON.parse(contentStr) as DragContentType;

    const isSupportedData =
      isAd(element) || isInstantExp(element) || isUrlData(element);

    if (!isSupportedData) throw new Error("Not supported data.");

    return {
      position,
      element,
    };
  } catch (err) {
    throw err;
  }
};

export const onDragStart = (
  event: DragEvent<HTMLDivElement>,
  element: Element,
) => {
  if (!element) return;

  const contentType = "text/plain";
  const draggingContent = {
    element,
  };

  event.dataTransfer.setData(contentType, JSON.stringify(draggingContent));
  event.dataTransfer.effectAllowed = "move";
};

/**
 * An element can only have one connection. An element meaning the element in "body_elements" of an instant experience object that can have other IE ref (ex. button)
 * The reason is because it does not make sense to have two or more different references that the element is pointing to.
 * @param elements flow elements
 * @returns boolean
 *
 */
export const isValidConnection =
  (elements?: EAElements) => (connection: Connection) => {
    const { source, sourceHandle } = connection;
    const sourceFlowElement = elements?.find(ele => ele.id === source);

    const sourceBodyElement = getSourceBodyElement(
      sourceHandle || "",
      sourceFlowElement,
    );
    if (!sourceBodyElement) return false;

    const ableToSetDest =
      !!sourceBodyElement &&
      (isFooter(sourceBodyElement) ||
        isButton(sourceBodyElement) ||
        isImage(sourceBodyElement));
    if (!ableToSetDest) return false;

    return (
      !hasConnection(connection, elements) &&
      !isCircularRef(connection, elements)
    );
  };

export const getSourceBodyElement = (
  sourceHandle: string,
  sourceFlowElement?: EAFlowElement,
) => {
  if (!sourceFlowElement) return;
  const { ie } = sourceFlowElement.data || {};
  const { body_elements } = ie || {};

  const foundBodyElement =
    body_elements?.find(ele => ele.id === sourceHandle) ||
    // for caraousel element
    body_elements
      ?.filter(isCarousel)
      .find(({ child_elements }) =>
        child_elements.find(childEle => childEle.id === sourceHandle),
      );

  if (foundBodyElement && isCarousel(foundBodyElement)) {
    // here, we need to find the flow image elmenet in this carousel element.
    return foundBodyElement.child_elements.find(ele => ele.id === sourceHandle);
  }

  return foundBodyElement;
};

export const hasConnection = (
  connection: Connection,
  elements?: EAElements,
) => {
  const { sourceHandle } = connection;
  return (
    elements?.some(ele => isEdge(ele) && ele.sourceHandle === sourceHandle) ??
    false
  );
};

export const isCircularRef = (
  connection: Connection,
  elements?: EAElements,
) => {
  if (!elements) return false;

  const backwardRefs = getBackwardReferences(elements, connection);

  const { target } = connection;
  const targetElement = elements.find(ele => ele.id === target);
  if (!targetElement) throw new Error(`The target(${target}) must exist.`);

  const { data } = targetElement;
  const targetDataId = getDataId(data);
  if (!targetDataId)
    throw new Error(
      `The target element (${target}) must contain data (id: ${targetDataId}).`,
    );

  if (backwardRefs.includes(targetDataId)) return true;

  let limit = 100;
  const edges = elements.filter(
    ele => isEdge(ele) && ele.source === targetElement.id,
  ) as Array<Edge>;
  while (edges.length > 0) {
    const nextEdge = edges.shift();
    if (!nextEdge) continue;

    const { target } = nextEdge;

    const targetElement = elements.find(ele => ele.id === target); // NOTE: this is flow element, NOT IE
    if (!targetElement) continue;

    const { data } = targetElement;
    const targetDataId = getDataId(data);
    if (!targetDataId)
      throw new Error(
        `The target element (${target}) must contain data (id: ${targetDataId}).`,
      );

    if (backwardRefs.includes(targetDataId)) return true;

    edges.push(
      ...(elements.filter(
        ele => isEdge(ele) && ele.source === targetElement.id,
      ) as Array<Edge>),
    );

    limit -= 1;
    if (limit < 0) break;
  }

  return false;
};

// All element's data id (ad.id, ie.id, or url label name) of previous flow elements INCLUDING the source element.
export const getBackwardReferences = (
  elements: EAElements,
  connection: Connection,
): string[] => {
  const { source } = connection;

  const sourceElement = elements.find(ele => ele.id === source);
  if (!sourceElement) throw new Error(`The source (${source}) must exist.`);

  if (!elementHasValidData(sourceElement))
    throw new Error(`The source (${source}) does not have valid data.`);

  const { data } = sourceElement;

  let limit = 100;
  const refs = [getDataId(data) || ""]; // MUST exist at least one id
  const queue = [sourceElement];
  while (queue.length > 0) {
    const current = queue.shift();
    const edge = elements.find(
      ele => isEdge(ele) && ele.target === current?.id,
    ) as Edge;
    if (!edge) break;

    const { source: nextSource } = edge;
    if (nextSource === source) break; // This means that there is no previous flow elements. This should not happening because this is like self-referencing but found one case. So handling it in this line.

    const prevElement = elements.find(ele => ele.id === nextSource);
    if (!prevElement) throw new Error(`The source(${source} must exist.`);

    queue.push(prevElement);

    const { data } = prevElement;
    const foundId = getDataId(data);
    if (foundId) refs.push(foundId);

    limit -= 1;

    if (limit <= 0) throw new Error("Possibly it ran into inifite loop.");
  }

  return refs;
};

export const elementHasValidData = (ele: EAFlowElement) => {
  const { data } = ele;

  return !!getDataId(data);
};

const getDataId = (data?: DataType) => {
  const { ie, ad, url } = data || {};

  return ie?.id || ad?.id || url?.id;
};
/**
 * There are type of flow elements that can have source handle (where user can create connection line from).
 *  - Ad flow element
 *  - Instant exp.
 *    - Image flow element (including images within carousel)
 *    - Button element
 * So basically, this function will be used to determine if a flow element should have source handle or not.
 * If this function returns true, the flow element will have source handle attached to its body.
 *
 * // NOTE: Carousel is also connectable but not included here because the carousel object
 *          itself isnt connectable, only the elements (images) under it.
 * @param ele flow element
 * @returns boolean
 */
export const shouldHaveSourceHandle = (ele: any): boolean => {
  return isButton(ele) || isImage(ele) || isAd(ele) || isFooter(ele);
};
