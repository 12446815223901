import { memo, useState } from "react";
import { Row, Col, Form } from "antd";
import { AdType } from "screens/adLibrary/facebookUtils/types";
import { IAd, IDestinationValues } from "shared/types/adLibrary";
import InstantExperienceSelect from "shared/components/InstantExperienceSelect";
import TextArea from "antd/lib/input/TextArea";
import { areFieldsRequired as required } from "screens/adLibrary/adWizard.utils";

interface IProps {
  adEdit?: IAd;
  adType: AdType;
  shouldDisplayErrors: boolean;
}

const AdDesign = ({ adEdit, adType, shouldDisplayErrors }: IProps) => {
  const [selectedBrand, setSelectedBrand] = useState<string>();

  return (
    <>
      <Row>
        <Col span={24}>
          <Form.Item
            name={["visuals", "postCopy"]}
            label="Primary Text"
            rules={[
              {
                required:
                  required && ![AdType.Video, AdType.Still].includes(adType),
                message: "Please add a Primary Text",
                whitespace: true,
              },
            ]}
          >
            <TextArea autoSize={{ maxRows: 3 }} data-cy="primary-text-input" />
          </Form.Item>
        </Col>
        <Col>
          {(adType === AdType.Collection ||
            adType === AdType.InstantExperience) && (
            <Form.Item
              name={["visuals", "destination"]}
              label="Link an Everything Ad"
              valuePropName="destination"
              hasFeedback={false}
              validateStatus="success"
              rules={[
                {
                  required,
                  validator: (_, value: IDestinationValues) => {
                    if (value?.instantExperienceId || !required) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Select an Everything Ad"));
                  },
                },
              ]}
              data-cy="link-to-instant-experience-form-item"
            >
              <InstantExperienceSelect
                selectedBrand={selectedBrand}
                setSelectedBrand={setSelectedBrand}
                shouldValidate={shouldDisplayErrors}
              />
            </Form.Item>
          )}
        </Col>
      </Row>
    </>
  );
};

export default memo(AdDesign);
