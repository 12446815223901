import { Form, Radio, Checkbox } from "antd";
import { useForm } from "antd/lib/form/Form";
import { EditorState } from "draft-js";
import { memo, useEffect } from "react";
import { ColorResult } from "react-color";
import { ButtonStyle } from "screens/adLibrary/facebookUtils/types";
import ColorPicker from "shared/components/ColorPicker";
import SimpleRTE, { IRTEValues } from "shared/components/SimpleRTE";
import Destination, {
  DestinationType,
  IDestinationValues,
} from "../shared/Destination";

interface IProps {
  initialValues?: IButtonComponentForm;
  destination: DestinationType;
  setDestination(destination: DestinationType): void;
  selectedBrand?: string;
  setSelectedBrand(brandId: string): void;
  disableFooter: boolean;
  onValuesChange(values?: IButtonComponentForm): void;
  forceValidations?: boolean;
  elementId?: string;
}

export interface IButtonComponentForm {
  format: {
    editorState: EditorState;
    fontSize?: number;
    fontFamily?: string;
    textColor?: string;
  };
  buttonColor?: string;
  buttonStyle?: ButtonStyle;
  backgroundColor?: string;
  destinationValues?: IDestinationValues;
  fixToBottom?: boolean;
}

const ButtonComponentForm = ({
  destination,
  setDestination,
  setSelectedBrand,
  selectedBrand,
  initialValues,
  disableFooter,
  onValuesChange,
  forceValidations,
  elementId,
}: IProps) => {
  const [form] = useForm<IButtonComponentForm>();

  useEffect(() => {
    if (forceValidations) {
      form.validateFields();
    }
  }, [forceValidations, form]);
  return (
    <Form<IButtonComponentForm>
      form={form}
      layout="vertical"
      requiredMark={"optional"}
      initialValues={initialValues}
      onValuesChange={(_, allValues) => onValuesChange(allValues)}
    >
      <Form.Item
        name="format"
        getValueFromEvent={(key: string, value: any) => {
          return {
            ...form.getFieldValue("format"),
            [key]: value,
          };
        }}
        valuePropName="values"
        label="Button Label"
        required
        rules={[
          {
            required: true,
            validator: (_, value: IRTEValues) => {
              if (!value.editorState.getCurrentContent().hasText()) {
                return Promise.reject("Please input a button label");
              }
              return Promise.resolve();
            },
          },
        ]}
        validateTrigger="onValidate"
      >
        <SimpleRTE />
      </Form.Item>
      <Form.Item name="buttonStyle" label="Button Style" required>
        <Radio.Group>
          <Radio.Button value="BUTTON_OUTLINE">Outline</Radio.Button>
          <Radio.Button value="BUTTON_FILLED">Fill</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="buttonColor"
        valuePropName="color"
        required
        getValueFromEvent={(color: ColorResult) =>
          color.hex.toUpperCase().replace("#", "")
        }
        label="Button Color"
      >
        <ColorPicker displayColorInput />
      </Form.Item>
      <Form.Item
        name="backgroundColor"
        valuePropName="color"
        required
        getValueFromEvent={(color: ColorResult) =>
          color.hex.toUpperCase().replace("#", "")
        }
        label="Background Color"
      >
        <ColorPicker displayColorInput />
      </Form.Item>
      <Form.Item name="destinationValues" valuePropName="destinationValues">
        <Destination
          destination={destination}
          selectedBrand={selectedBrand}
          setDestination={setDestination}
          setSelectedBrand={setSelectedBrand}
          required
          shouldValidate={forceValidations}
        />
      </Form.Item>

      <Form.Item name="fixToBottom" valuePropName="checked">
        <Checkbox disabled={disableFooter} id={"fixToBottom-" + elementId}>
          Fix button to the bottom of the view
        </Checkbox>
      </Form.Item>
    </Form>
  );
};

export default memo(ButtonComponentForm);
