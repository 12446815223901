import { IAccountRecord } from "shared/types/accountManagement";
import {
  BrandLogo,
  ImageType,
  LogoEntity,
  LogoType,
} from "shared/types/brandsAccounts";
import { checkFilterMatch } from "utils/helpers";
import { compareStringBy } from "utils/helpers.table";
import { IDataListColumns } from "../dataList/types";

export const SELECT_LOGOS_QUERY_KEY = "select-logos";

export const getImageType = (logoType: string) => {
  const regex = /(horizontal|vertical|square)/g;
  const matches = logoType.match(regex);
  if (!matches || matches?.length === 0) return;
  const imageType = matches[0];
  return (imageType.charAt(0).toUpperCase() + imageType.slice(1)) as ImageType;
};

export const isAccountRecord = (record: any): record is IAccountRecord => {
  return !!record?.hasOwnProperty("dealerName");
};

export const getLogoType = (
  entity: LogoEntity,
  typePrefix: string,
): LogoType => {
  if (typePrefix.toLowerCase().includes("event")) return "Sales Event";
  if (isAccountRecord(entity)) return "Account";
  return "Brand";
};

export const getLogoData = (logoKey: string, entity: LogoEntity) => {
  const typePrefix = logoKey.split("ImagesFromS3")[0];
  const logoType = getLogoType(entity, typePrefix);
  const brand = isAccountRecord(entity) ? entity.dealerOem : entity.oemName;
  const account = isAccountRecord(entity) ? entity.dealerName : "";
  const artboardName = getImageType(typePrefix);
  return {
    brand,
    account,
    logoType,
    artboardName,
  };
};

export const getLogosForEntity = (entity: LogoEntity, entityName: string) => {
  const logos: BrandLogo[] = [];
  Object.keys(entity.logoUrlsFromS3 ?? {}).forEach(logoKey => {
    const logoUrls = entity.logoUrlsFromS3![
      logoKey as keyof typeof entity.logoUrlsFromS3
    ] as string[];
    if (!logoUrls || logoUrls.length === 0) return;
    logoUrls.forEach(logoUrl => {
      const { brand, logoType, account, artboardName } = getLogoData(
        logoKey,
        entity,
      );
      if (!artboardName || !logoUrl) return;
      logos.push({
        id: logoUrl,
        name: entityName,
        logoUrl,
        logoType,
        artboardName,
        brand,
        account,
        assetType: "Image",
      });
    });
  });
  return logos;
};

export const filterColumn = (
  logoKey: keyof BrandLogo,
  value: string | string[],
  record: BrandLogo,
): boolean => {
  const filterMatch = (val: string, recordValue: string | undefined) =>
    checkFilterMatch(val, recordValue);

  if (Array.isArray(value))
    return value.some(v => filterMatch(v, record[logoKey]?.toString()));

  return filterMatch(value, record[logoKey]?.toString());
};

export const fields: IDataListColumns<BrandLogo> = {
  name: {
    sorterFn: compareStringBy("name"),
    filterFn: (value: string | string[], record: BrandLogo) =>
      filterColumn("name", value, record),
  },
  logoType: {
    sorterFn: compareStringBy("logoType"),
    filterFn: (value: string | string[], record: BrandLogo) =>
      filterColumn("logoType", value, record),
  },
  artboardName: {
    sorterFn: compareStringBy("artboardName"),
    filterFn: (value: string | string[], record: BrandLogo) =>
      filterColumn("artboardName", value, record),
  },
  assetType: {
    sorterFn: compareStringBy("assetType"),
    filterFn: (value: string | string[], record: BrandLogo) =>
      filterColumn("assetType", value, record),
  },
  brand: {
    sorterFn: compareStringBy("brand"),
    filterFn: (value: string | string[], record: BrandLogo) =>
      filterColumn("brand", value, record),
  },
  account: {
    sorterFn: compareStringBy("account"),
    filterFn: (value: string | string[], record: BrandLogo) =>
      filterColumn("account", value, record),
  },
};
