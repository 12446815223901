import { Typography } from "antd";
import { useColumn } from "shared/components/dataList";
import { TimeAgo } from "shared/components/TimeAgo";
import { AudienceIntegrationLog, Platform } from "../types";

const { Link } = Typography;

type Props = {
  onClickOnAudienceName: (log: AudienceIntegrationLog) => void;
};

export const useTableColumns = ({ onClickOnAudienceName }: Props) => {
  return [
    useColumn<AudienceIntegrationLog>({
      key: "audienceName",
      title: "Audience Name",
      dataIndex: ["audience", "name"],
      render: function AudienceLog(
        audienceName: string,
        record: AudienceIntegrationLog,
      ) {
        if (record.platform === Platform.Google) return audienceName;
        return (
          <Link onClick={() => onClickOnAudienceName(record)}>
            {audienceName}
          </Link>
        );
      },
    }),
    useColumn<AudienceIntegrationLog>({
      key: "audienceId",
      title: "Audience Id",
      dataIndex: ["audience", "id"],
    }),
    useColumn<AudienceIntegrationLog>({
      key: "platform",
      title: "Ad Channel ID",
      dataIndex: ["platform"],
      render: platform => platform.charAt(0).toUpperCase() + platform.slice(1),
    }),
    useColumn<AudienceIntegrationLog>({
      key: "dealerName",
      title: "Store Name",
      dataIndex: ["account", "dealerName"],
    }),
    useColumn<AudienceIntegrationLog>({
      key: "adAccountName",
      title: "Ad Account Name",
      dataIndex: ["account", "adAccountName"],
    }),
    useColumn<AudienceIntegrationLog>({
      key: "adAccountId",
      title: "Ad Account Id",
      dataIndex: ["account", "adAccountId"],
    }),
    useColumn<AudienceIntegrationLog>({
      key: "createdAt",
      title: "Created Date",
      dataIndex: ["createdAt"],
      render: function TimeAgoComponent(createdAt: Date) {
        return <TimeAgo date={createdAt} />;
      },
    }),
    useColumn<AudienceIntegrationLog>({
      key: "updatedAt",
      title: "Last Update",
      dataIndex: ["updatedAt"],
      render: function TimeAgoComponent(updatedAt: Date) {
        return <TimeAgo date={updatedAt} />;
      },
    }),
  ];
};
