import { Select } from "antd";
import Link from "antd/lib/typography/Link";
import { feedTypes } from "shared/constants/assetExporter";
import { FeedUploadType } from "shared/types/assetExporter";

type CsvTemplateOptionProps = {
  type: FeedUploadType;
};
const { Option } = Select;

export const CsvTemplateOptions = ({ type }: CsvTemplateOptionProps) => {
  const customMsg = "Need a CSV Template?";
  const googlesheetMsg = "Need a template for your Google Spreadsheet?";
  const message = type === "custom" ? customMsg : googlesheetMsg;

  return (
    <>
      {message}
      <Select
        value="Get it Here"
        style={{ width: 110, color: "#1890ff" }}
        bordered={false}
        dropdownMatchSelectWidth={false}
      >
        {feedTypes
          .filter(t => t.type !== "custom")
          .map(feed => (
            <Option key={feed.type} value={feed.type}>
              <Link
                download={!feed.redirect && feed.downloadTemplateUrl}
                href={feed.downloadTemplateUrl}
                target={feed.redirect ? "_blank" : ""}
                style={{ color: "#000" }}
              >
                {feed.name}
              </Link>
            </Option>
          ))}
      </Select>
    </>
  );
};
