import { memo, useEffect, useRef, useState } from "react";
import { Button, Form, Popconfirm, Radio, Typography } from "antd";
import { IPhotoElement } from "shared/types/adLibrary";
import ImageComponent, { getDefaultPhotoValues } from "../ImageComponent";
import { animated, useSpring } from "react-spring";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { reorderList } from "utils/helpers";
import {
  DeleteOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";
import ToggleDisplay from "shared/components/ToggleDisplay";

export interface ICarouselComponentForm {
  childElements: IPhotoElement[];
}

interface IProps {
  values: ICarouselComponentForm;
  onValuesChange(values?: ICarouselComponentForm): void;
  childrenIdsToDisplayValidations?: string[];
  isLastElement?: boolean;
}

const CarouselComponentForm = ({
  values,
  onValuesChange,
  childrenIdsToDisplayValidations,
  isLastElement,
}: IProps) => {
  const photos = values.childElements;
  const setPhotos = (newPhotos: IPhotoElement[]) => {
    onValuesChange({ ...values, childElements: newPhotos });
  };

  const [selectedPhotoId, setSelectedPhotoId] = useState(photos[0]?.id);

  const prevSelectedPhotoId = useRef(selectedPhotoId);
  useEffect(() => {
    prevSelectedPhotoId.current = selectedPhotoId;
  }, [selectedPhotoId]);

  const springProps = useSpring({
    from: { opacity: 0.2 },
    to: { opacity: 1 },
    reset: selectedPhotoId !== prevSelectedPhotoId.current,
  });
  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const {
      source: { index: sourceIndex },
      destination: { index: destinationIndex },
    } = result;

    if (sourceIndex === destinationIndex) {
      return;
    }

    setPhotos(reorderList(photos, sourceIndex, destinationIndex));
  };
  return (
    <>
      <Form.Item style={{ maxWidth: 450 }}>
        <Typography.Title level={4}>Carousel</Typography.Title>
        <Typography.Paragraph>
          Upload 2-10 images to show them in a carousel format. If images are
          not the same size, they will be cropped to match your first image.
        </Typography.Paragraph>
      </Form.Item>
      <Form.Item>
        <DragDropContext onDragEnd={onDragEnd}>
          <Radio.Group
            value={selectedPhotoId}
            onChange={e => setSelectedPhotoId(e.target.value)}
          >
            <Droppable droppableId="carouselPhotos" direction="horizontal">
              {providedDroppable => (
                <div
                  {...providedDroppable.droppableProps}
                  ref={providedDroppable.innerRef}
                  style={{ display: "flex" }}
                >
                  {photos.map((photo, index) => (
                    <Draggable
                      key={photo.id}
                      draggableId={photo.id || ""}
                      index={index}
                    >
                      {providedDraggable => {
                        const shouldDisplayErrors =
                          childrenIdsToDisplayValidations?.includes(photo.id!);
                        return (
                          <div
                            key={photo.id}
                            {...providedDraggable.draggableProps}
                            {...providedDraggable.dragHandleProps}
                            ref={providedDraggable.innerRef}
                            style={{
                              ...providedDraggable.draggableProps.style,
                              cursor: "pointer",
                            }}
                          >
                            <Radio.Button
                              style={{
                                paddingLeft: shouldDisplayErrors ? 13 : 15,
                                paddingRight: shouldDisplayErrors ? 13 : 15,
                              }}
                              key={photo.id}
                              value={photo.id}
                            >
                              {shouldDisplayErrors ? (
                                <ExclamationCircleFilled
                                  style={{
                                    color: "#FF4D4F",
                                    display: "inline-block",
                                    verticalAlign: "middle",
                                    height: 14,
                                  }}
                                />
                              ) : (
                                index + 1
                              )}
                            </Radio.Button>
                          </div>
                        );
                      }}
                    </Draggable>
                  ))}
                  {providedDroppable.placeholder}
                </div>
              )}
            </Droppable>
          </Radio.Group>
        </DragDropContext>
        {photos.length < 10 && (
          <Button
            icon={
              <PlusOutlined
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  height: 16,
                }}
              />
            }
            style={{
              marginLeft: 10,
            }}
            onClick={() => {
              const defaultPhotoValues = getDefaultPhotoValues();
              setPhotos(photos.concat(defaultPhotoValues));
              setSelectedPhotoId(defaultPhotoValues.id);
            }}
          />
        )}
        {photos.map(photo => (
          <ToggleDisplay
            show={photo.id === selectedPhotoId}
            key={photo.id}
            style={{
              marginTop: 9,
              padding: "20px 10px 0",
              backgroundColor: "#f0f0f0",
              maxWidth: 450,
              position: "relative",
            }}
          >
            <animated.div style={springProps}>
              {photos.length > 2 && (
                <Popconfirm
                  placement="topRight"
                  title="Delete this card?"
                  onConfirm={() => {
                    const newPhotos = photos.filter(
                      photoItem => photoItem.id !== selectedPhotoId,
                    );
                    setSelectedPhotoId(newPhotos[0]?.id);
                    setPhotos(newPhotos);
                  }}
                >
                  <Button
                    icon={<DeleteOutlined />}
                    style={{
                      position: "absolute",
                      right: 10,
                      top: 10,
                      zIndex: 10,
                    }}
                  />
                </Popconfirm>
              )}
              <ImageComponent
                hideLayoutOption
                hideTitle
                instantExperienceElement={photo}
                forceValidations={childrenIdsToDisplayValidations?.includes(
                  photo.id!,
                )}
                updateElement={photoElement => {
                  setPhotos(
                    photos.map(photoItem =>
                      photoItem.id === photo.id ? photoElement : photoItem,
                    ),
                  );
                }}
                isLastElement={isLastElement}
              />
            </animated.div>
          </ToggleDisplay>
        ))}
      </Form.Item>
    </>
  );
};

export default memo(CarouselComponentForm);
