import React, { FC, useMemo } from "react";
import _ from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
import { Tooltip, Checkbox, Modal, Spin } from "antd";

import { ITemplate } from "shared/types/designStudio";
import { IAssetBuilderState } from "shared/types/assetBuilder";

interface SizeSelectionProps {
  selections?: Record<string, Record<string, boolean>>; // asset type -> size -> boolean
  selectedTab: string | undefined;
  loading: boolean;
  templatesBySize: Record<string, ITemplate[]>;
  savedOrder: IAssetBuilderState["savedOrder"];

  showTemplateCountTooltip?: {
    assetType: string;
    size: string;
  };
}
interface SizeSelectionHandlers {
  toggleSize: (size: string) => void;
  removeFilterTemplate: (selectedTab: string, filterOption: string) => void;
  toggleTemplateCountTooltip: (args?: {
    assetType: string;
    size: string;
  }) => void;
  deleteInstance: (args: { assetType: string; size: string }) => void;
}

const SizeSelection: FC<SizeSelectionProps & SizeSelectionHandlers> = props => {
  const availableTemplatesExist =
    props.templatesBySize && !_.isEmpty(props.templatesBySize);

  const sortAssetSizes = (templatesBySize: string[]) => {
    const reconfiguredTemplatesBySize = templatesBySize.map(size => {
      const firstSplit = size.split("x");
      const secondSplit = size.split("x");

      const firstSplitFirstInt = parseInt(firstSplit[0].trim());
      const firstSplitSecondInt = parseInt(secondSplit[1].trim());

      const secondSplitFirstInt = parseInt(firstSplit[0].trim());
      const secondSplitSecondInt = parseInt(secondSplit[1].trim());

      return {
        fSFI: firstSplitFirstInt,
        fSSI: firstSplitSecondInt,
        sSFI: secondSplitFirstInt,
        sSSI: secondSplitSecondInt,
      };
    });

    const sortedTemplatesBySize = reconfiguredTemplatesBySize.sort((a, b) => {
      if (a["fSFI"] !== b["sSFI"]) {
        return a["fSFI"] - b["sSFI"];
      } else {
        return a["fSSI"] - b["sSSI"];
      }
    });

    const sortedFormatted = sortedTemplatesBySize.map(size => {
      return `${size["fSFI"]} x ${size["fSSI"]}`;
    });

    return sortedFormatted;
  };

  const sortedAssetSizes = useMemo(
    () => sortAssetSizes(Object.keys(props.templatesBySize || {})),
    [props.templatesBySize],
  );

  const selectedOffersOEMList = () => {
    const oemList: Set<string> = new Set();
    for (const offer of props.savedOrder?.selectedOffers || []) {
      if (offer.offerData.make) {
        oemList.add(offer.offerData.make.toLowerCase());
      }
    }

    return oemList;
  };
  const selectedOffersOems = selectedOffersOEMList();

  return (
    <div className={`selection-container`}>
      <div className="title">Size</div>
      <div className="content">
        <ul className="size-selection-ul">
          {!availableTemplatesExist && <li>No Templates</li>}

          {availableTemplatesExist &&
            sortedAssetSizes.map(templateSize => {
              let numberOfTemplatesBySize = 0;
              for (
                let i = 0;
                i < props.templatesBySize[templateSize].length;
                i++
              ) {
                const template = props.templatesBySize[templateSize][i];
                if (
                  template.oems.length === 0 ||
                  template.oems.some(oem =>
                    selectedOffersOems.has(oem.toLowerCase()),
                  )
                ) {
                  numberOfTemplatesBySize += 1;
                }
              }
              if (numberOfTemplatesBySize === 0) return null;

              return (
                <li key={`offer-type-filter-option-key-${templateSize}`}>
                  <Tooltip
                    title={`${numberOfTemplatesBySize} template${
                      numberOfTemplatesBySize > 1 ? "s" : ""
                    }`}
                    trigger="hover"
                    placement="rightTop"
                  >
                    <div style={{ display: "inline-block" }}>
                      <Checkbox
                        className="size-select-checkbox"
                        checked={
                          props.selections?.[
                            props.selectedTab?.toLowerCase() || ""
                          ]?.[templateSize]
                        }
                        onChange={e => {
                          if (!e.target.checked) {
                            Modal.confirm({
                              title: "Delete template size.",
                              content: `This will remove all ${templateSize} assets.`,
                              okType: "primary",
                              okButtonProps: { danger: true },
                              okText: "Delete",
                              onOk: () => {
                                props.deleteInstance({
                                  assetType: props.selectedTab || "",
                                  size: templateSize,
                                });
                              },
                            });
                            return;
                          }

                          props.toggleSize(templateSize);
                        }}
                        onMouseEnter={() => {
                          props.toggleTemplateCountTooltip({
                            assetType: props.selectedTab || "",
                            size: templateSize,
                          });
                        }}
                        onMouseLeave={() => {
                          props.toggleTemplateCountTooltip(undefined);
                        }}
                      >
                        {templateSize}
                      </Checkbox>
                    </div>
                  </Tooltip>
                </li>
              );
            })}
        </ul>
        <div className="loading-spinner">
          <Spin indicator={<LoadingOutlined />} spinning={props.loading} />
        </div>
      </div>
    </div>
  );
};

export default SizeSelection;
