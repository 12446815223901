import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Collapse, Menu, message, Spin, Tabs } from "antd";
import { useEffect, useRef, useState } from "react";

import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";

import {
  offerTypes,
  stateOptions,
} from "../../shared/constants/dataManagement";

// redux
import actions from "../../redux/rootActions";

// types
import Header from "../../shared/components/Header";

import TabContainer from "shared/components/TabContainer";
import {
  ILegalLingoReduxState,
  IMessagingObjectType,
} from "shared/types/legalLingo";
import "../../shared/styles/Collapse.scss";

import { IHeader } from "shared/types/header";
import PrimarySecondaryMessages from "./legalMessaging/primarySecondaryMessages";
import PrimarySecondaryMessagesExceptions from "./legalMessaging/primarySecondaryMessagesExceptions";
import { AppState } from "redux/store";

interface ILegalLingoMessaging {
  oemRecords: any[];

  oemSelected: string | null;
  stateSelected: string | null;

  saveMessagesandExceptions: (
    messages: { primaryMessage: string; secondaryMessage: string },
    offer: string,
    oem: string,
    state: string,
    isDefault: boolean,
  ) => void;
  getMessagesandExceptions: (
    currTab: string | null | any,
    onLoad?: boolean,
    oem?: string | null,
    state?: string | null,
  ) => void;
  saveLocalStateMessagesAndExceptions: (messagesExceptionsObject: any) => void;

  currentMessagingObject?: IMessagingObjectType;
}

const LegalLingoForm: React.FC<ILegalLingoMessaging> = ({
  oemRecords,

  oemSelected,
  stateSelected,

  saveMessagesandExceptions,
  getMessagesandExceptions,
  saveLocalStateMessagesAndExceptions,
  currentMessagingObject,
}) => {
  const navigate = useNavigate();

  const headerMenus: IHeader = {
    style: {
      display: "flex",
      alignItem: "center",
      flex: "initial",
      width: "35em",
    },
    topSteps: {
      selected: "MESSAGING",
      style: {
        flex: 2,
      },
      steps: [
        {
          title: "VARIABLES",
          state: "enabled",
          onClick: () => {
            navigate("/legal-lingo/variables");
          },
        },
        {
          title: "DISCLOSURES",
          state: "enabled",
          onClick: () => {
            navigate("/legal-lingo/disclosures");
          },
        },
        {
          title: "MESSAGING",
          state: "enabled",
        },
      ],
    },
    middleSteps: [],
    actionButtons: [
      <Button
        onClick={() => {
          saveMessagesandExceptions(
            {
              primaryMessage: defaultPrimaryText,
              secondaryMessage: defaultSecondaryText,
            },
            currentTab,
            "null",
            "null",
            true,
          );
          saveMessagesandExceptions(
            {
              primaryMessage: exceptionPrimaryText,
              secondaryMessage: exceptionSecondaryText,
            },
            currentTab,
            oemExceptionSelected || "null",
            stateExceptionSelected || "null",
            false,
          );
          message.info("Saving Legal Lingo Messages Info...");
        }}
        className="action-button"
        key="save-button"
      >
        Save
      </Button>,
    ],
  };

  const caretIndexRef: React.MutableRefObject<number | null> =
    useRef<number>(null);
  const currentTabRef = useRef<string>("");

  const [showPrimaryVariablesDropdown, togglePrimaryVariablesDropdown] =
    useState<boolean>(false);
  const [showSecondaryVariablesDropdown, toggleSecondaryVariablesDropdown] =
    useState<boolean>(false);

  const [
    showPrimaryVariablesDropdownExceptions,
    togglePrimaryVariablesDropdownExceptions,
  ] = useState<boolean>(false);
  const [
    showSecondaryVariablesDropdownExceptions,
    toggleSecondaryVariablesDropdownExceptions,
  ] = useState<boolean>(false);

  const [defaultSecondaryText, setDefaultSecondaryText] = useState<string>(
    (currentMessagingObject && currentMessagingObject.secondaryMessage) || "",
  );
  const [defaultPrimaryText, setDefaultPrimaryText] = useState<string>(
    (currentMessagingObject && currentMessagingObject.primaryMessage) || "",
  );

  const [exceptionSecondaryText, setExceptionSecondaryText] = useState<string>(
    (currentMessagingObject && currentMessagingObject.secondaryMessage) || "",
  );
  const [exceptionPrimaryText, setExceptionPrimaryText] = useState<string>(
    (currentMessagingObject && currentMessagingObject.primaryMessage) || "",
  );

  const [currentTab, setCurrentTab] = useState<string>("lease");

  const [exceptionsTitle, setExceptionsTitle] = useState<string>("Message");

  const [oemExceptionSelected, setOemExceptionSelected] = useState<
    string | null
  >(null);
  const [stateExceptionSelected, setStateExceptionSelected] = useState<
    string | null
  >(null);

  const [shouldCreateException, setShouldCreateException] =
    useState<boolean>(false);

  const [currMenuFilter, setCurrMenuFilter] = useState<string>("");

  useEffect(() => {
    if (
      currentMessagingObject &&
      currentMessagingObject.oem === "null" &&
      currentMessagingObject.state === "null"
    ) {
      setDefaultPrimaryText(
        (currentMessagingObject && currentMessagingObject.primaryMessage) || "",
      );
      setDefaultSecondaryText(
        (currentMessagingObject && currentMessagingObject.secondaryMessage) ||
          "",
      );
    } else {
      setExceptionPrimaryText(
        (currentMessagingObject && currentMessagingObject.primaryMessage) || "",
      );
      setExceptionSecondaryText(
        (currentMessagingObject && currentMessagingObject.secondaryMessage) ||
          "",
      );
    }
  }, [currentMessagingObject]);

  useEffect(() => {
    setOemExceptionSelected(oemSelected);
    setStateExceptionSelected(stateSelected);
  }, [oemSelected, stateSelected]);

  useEffect(() => {
    if (oemExceptionSelected && stateExceptionSelected) {
      setExceptionsTitle(`${oemExceptionSelected} ${stateExceptionSelected}`);
    }
  }, [oemExceptionSelected, stateExceptionSelected]);

  const offerButtons = offerTypes.map((tab: string) => ({
    title: tab,
    component: (
      <Collapse
        defaultActiveKey="1"
        activeKey="1"
        className="collapse-container"
        expandIconPosition={"right"}
        expandIcon={() => {
          return (
            <Button
              icon={<PlusCircleOutlined />}
              ghost={true}
              onClick={() => {
                setShouldCreateException(true);
              }}
              style={{ padding: "0 15px" }}
            >
              Create Exception
            </Button>
          );
        }}
      >
        <Collapse.Panel key={"1"} header={"Create Messages and Add Exceptions"}>
          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ width: "45%", paddingLeft: "15px" }}>
              <div>
                <br />
                <br />
                <div style={{ fontSize: "18px", fontWeight: "bold" }}>BASE</div>
                <div>{"* Type {} to trigger dropdown to insert variables"}</div>
              </div>
              <br />
              <br />
              <PrimarySecondaryMessages
                caretIndexRef={caretIndexRef}
                primaryText={defaultPrimaryText}
                secondaryText={defaultSecondaryText}
                showPrimaryVariablesDropdown={showPrimaryVariablesDropdown}
                showSecondaryVariablesDropdown={showSecondaryVariablesDropdown}
                togglePrimaryVariablesDropdown={togglePrimaryVariablesDropdown}
                toggleSecondaryVariablesDropdown={
                  toggleSecondaryVariablesDropdown
                }
                setPrimaryText={setDefaultPrimaryText}
                setSecondaryText={setDefaultSecondaryText}
                currMenuFilter={currMenuFilter}
                setCurrMenuFilter={setCurrMenuFilter}
              />
            </div>

            {/* Exceptions */}
            {shouldCreateException && (
              <div
                style={{
                  paddingLeft: "50px",
                  height: "490",
                  width: "100%",
                }}
              >
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingRight: "50px",
                    paddingLeft: "50px",
                    width: "50%",
                  }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      paddingTop: "10px",
                    }}
                  >
                    {exceptionsTitle} Exceptions
                  </span>
                  <Button
                    onClick={() => {
                      setExceptionsTitle("Message");
                      setOemExceptionSelected(null);
                      setStateExceptionSelected(null);
                      getMessagesandExceptions(currentTabRef.current, true);
                    }}
                  >
                    Clear
                  </Button>
                </div>
                <br />

                {/* They can choose to Select OEM or Select State */}
                {/* Based on which OEM or which State they choose, we will show them the Primary and Secondary Messages */}
                {/* Maybe render this using some sort of boolean */}
                <TabContainer
                  displayFilterSection={false}
                  displaySearchView={{
                    displayNewOffer: false,
                    displaySearchInput: false,
                    displayPlusButton: false,
                  }}
                  contentTabs={[
                    {
                      title: "By OEM",
                      component: (
                        <Menu
                          onClick={event => {
                            const key = event.key.toString();
                            setExceptionsTitle(key);
                            setOemExceptionSelected(key);

                            getMessagesandExceptions(
                              currentTabRef.current,
                              false,
                              key,
                              stateExceptionSelected,
                            );
                          }}
                          mode="vertical"
                          style={{ maxHeight: "400px", overflowY: "scroll" }}
                        >
                          {oemExceptionSelected ? (
                            <div style={{ width: "45% " }}>
                              <PrimarySecondaryMessagesExceptions
                                caretIndexRef={caretIndexRef}
                                primaryText={exceptionPrimaryText}
                                secondaryText={exceptionSecondaryText}
                                showPrimaryVariablesDropdown={
                                  showPrimaryVariablesDropdownExceptions
                                }
                                showSecondaryVariablesDropdown={
                                  showSecondaryVariablesDropdownExceptions
                                }
                                togglePrimaryVariablesDropdown={
                                  togglePrimaryVariablesDropdownExceptions
                                }
                                toggleSecondaryVariablesDropdown={
                                  toggleSecondaryVariablesDropdownExceptions
                                }
                                setPrimaryText={setExceptionPrimaryText}
                                setSecondaryText={setExceptionSecondaryText}
                                currMenuFilter={currMenuFilter}
                                setCurrMenuFilter={setCurrMenuFilter}
                              />
                            </div>
                          ) : (
                            oemRecords.map(oemRecord => (
                              <Menu.Item key={`${oemRecord.oemName}`}>
                                <span style={{ paddingLeft: "65px" }}>
                                  {oemRecord.oemName}
                                </span>
                              </Menu.Item>
                            ))
                          )}
                        </Menu>
                      ),
                    },
                    {
                      title: "By Location",
                      component: (
                        <Menu
                          onClick={event => {
                            const key = event.key.toString();
                            setExceptionsTitle(key);
                            setStateExceptionSelected(key);

                            getMessagesandExceptions(
                              currentTabRef.current,
                              false,
                              stateExceptionSelected,
                              key,
                            );
                          }}
                          mode="vertical"
                          style={{ maxHeight: "400px", overflowY: "scroll" }}
                        >
                          {stateExceptionSelected ? (
                            <div style={{ width: "45% " }}>
                              <PrimarySecondaryMessagesExceptions
                                caretIndexRef={caretIndexRef}
                                primaryText={exceptionPrimaryText}
                                secondaryText={exceptionSecondaryText}
                                showPrimaryVariablesDropdown={
                                  showPrimaryVariablesDropdownExceptions
                                }
                                showSecondaryVariablesDropdown={
                                  showSecondaryVariablesDropdownExceptions
                                }
                                togglePrimaryVariablesDropdown={
                                  togglePrimaryVariablesDropdownExceptions
                                }
                                toggleSecondaryVariablesDropdown={
                                  toggleSecondaryVariablesDropdownExceptions
                                }
                                setPrimaryText={setExceptionPrimaryText}
                                setSecondaryText={setExceptionSecondaryText}
                                currMenuFilter={currMenuFilter}
                                setCurrMenuFilter={setCurrMenuFilter}
                              />
                            </div>
                          ) : (
                            stateOptions.map(state => (
                              <Menu.Item key={`${state}`}>
                                <span style={{ paddingLeft: "65px" }}>
                                  {state}
                                </span>
                              </Menu.Item>
                            ))
                          )}
                        </Menu>
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </Collapse.Panel>
      </Collapse>
    ),
  }));

  return (
    <div>
      <Header
        style={headerMenus.style}
        topSteps={headerMenus.topSteps}
        actionButtons={headerMenus.actionButtons}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",

          paddingTop: "10px",
        }}
      >
        <Tabs
          style={{ width: "100%" }}
          onChange={activeKey => {
            saveLocalStateMessagesAndExceptions({
              defaultPrimaryText,
              defaultSecondaryText,
              key: currentTabRef.current,
            });
            setShouldCreateException(false);
            setOemExceptionSelected(null);
            setStateExceptionSelected(null);

            currentTabRef.current = activeKey;
            setCurrentTab(activeKey);
            getMessagesandExceptions(activeKey, false, "null", "null");
          }}
        >
          {offerButtons.map(tabObject => {
            const { title, component } = tabObject;

            // NOTE: in order for the antd Tab to switch between each tab, it needs unique
            // key attribute to its Tabs.TabPane. For this key, the title will be lower cased and used as its key.

            return (
              <Tabs.TabPane key={title} tab={title}>
                <div>{component}</div>
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      </div>
      <br />
    </div>
  );
};

interface ILegalMessagingOriginal {
  oemRecords: any[];

  oemSelected: string | null;
  stateSelected: string | null;

  getMessagesandExceptions: (
    currTab: string | null,
    onLoad?: boolean,
    oem?: string | null,
    state?: string | null,
  ) => void;
  saveMessagesandExceptions: (
    messages: { primaryMessage: string; secondaryMessage: string },
    offer: string,
    oem: string,
    state: string,
    isDefault?: boolean,
  ) => void;
  getOems: () => void;
  saveLocalStateMessagesAndExceptions: (messagesExceptionsObject: any) => void;
  currentMessagingObject?: IMessagingObjectType;

  isFirstMessagingLoad: boolean;
  reloadUUID: string;
}

const LegalMessaging: React.FC<ILegalMessagingOriginal> = props => {
  const {
    getMessagesandExceptions,
    getOems,
    oemRecords,
    currentMessagingObject,

    isFirstMessagingLoad,
    reloadUUID,
  } = props;
  useEffect(() => {
    getMessagesandExceptions(null, true);
    getOems();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isFirstMessagingLoad) {
      message.success("Saved Successfully!");
    }

    // eslint-disable-next-line
  }, [reloadUUID]);

  if (currentMessagingObject && oemRecords) {
    return <LegalLingoForm {...props} />;
  }
  return (
    <div className="loading">
      <Spin spinning={true} size="large" />
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  const { legalLingo, oemManagement } = state;

  const {
    error,
    currentMessagingObject,
    oemSelected,
    stateSelected,
    isFirstMessagingLoad,
    reloadUUID,
  } = legalLingo as ILegalLingoReduxState;

  const { oemRecords } = oemManagement;
  return {
    error,
    currentMessagingObject,
    oemRecords,

    oemSelected,
    stateSelected,

    isFirstMessagingLoad,
    reloadUUID,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => {
  return {
    getMessagesandExceptions: (
      currTab: string | null,
      onLoad?: boolean,
      oem?: any,
      state?: any,
    ) => {
      dispatch(
        actions.legalLingo.getMessagesandExceptions(
          currTab,
          onLoad,
          oem,
          state,
        ),
      );
    },
    saveMessagesandExceptions: (
      messages: {
        primaryMessage: string;
        secondaryMessage: string;
      },
      offer: string,
      oem: string,
      state: string,
      isDefault?: boolean,
    ) => {
      dispatch(
        actions.legalLingo.saveMessagesandExceptions(
          messages,
          offer,
          oem,
          state,
          isDefault,
        ),
      );
    },
    getOems: () => {
      dispatch(actions.oemManagement.getOems());
    },
    saveLocalStateMessagesAndExceptions: (messagesExceptionsObject: any) => {
      dispatch(
        actions.legalLingo.saveLocalStateMessagesAndExceptions(
          messagesExceptionsObject,
        ),
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LegalMessaging);
