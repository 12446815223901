import { EditFilled, FlagFilled, SelectOutlined } from "@ant-design/icons";
import { Input, Popover, notification, Button, Form } from "antd";
import React, { FC, useState } from "react";
import { IOfferDataInputProps } from "shared/types/assetBuilder";
import { ArgsProps } from "antd/lib/notification";

export const OfferInput: FC<IOfferDataInputProps> = ({
  field,
  title,
  value,
  onChange,
  error,
  className,
  originalValue,
  savedEditedValue,
  isPaymentEngineField,
  enablePaymentEngine,
  togglePaymentEngineModal,
  valueChanged = false,
  help,
}) => {
  /*
       TO DO: need to include rawOfferData.vin here or use another way to ensure
       that the PaymentEngine button only shows when editing an offer (
	   or maybe get the dealer science offer first)
    */

  // 1733 TO DO: use savedEditedValue for determining initial reverted state
  const [tempValue, setTempValue] = useState<string>(
    (savedEditedValue?.toString() || value) ?? "",
  );

  let formattedOriginalValue = originalValue?.toString().replace(",", "");

  if (formattedOriginalValue?.slice(-3) === ".00") {
    formattedOriginalValue = formattedOriginalValue.replace(".00", "");
  }

  const formattedInputValue = value?.toString().replace(",", "");

  const currentValueReverted = formattedOriginalValue === formattedInputValue;
  const verb = currentValueReverted ? "restored" : "reverted";

  const popoverContent = `The ${
    currentValueReverted ? "edited" : "original"
  } value is ${currentValueReverted ? tempValue : formattedOriginalValue}`; // TO DO 1733: preserve edited value on revert

  const iconStyle = {
    color: "orange",
    opacity: currentValueReverted ? 0.5 : 1,
  };

  const notificationKey = "value-change-notification";
  const notificationProps: ArgsProps = {
    key: notificationKey,
    message: `Field value ${verb}`,
    description: `${title} has been ${verb} to the ${
      currentValueReverted ? "edited" : "raw feed"
    } value`,
    placement: "bottomRight",
    // Possible TO DO: 1733: Implement undo button
  };
  return (
    <Form.Item
      className={className}
      help={error || help}
      label={
        <span>
          {title}{" "}
          {originalValue && !currentValueReverted && (
            <Popover content={popoverContent}>
              <FlagFilled
                style={iconStyle}
                title={`The saved value has been updated.${
                  originalValue ? ` Click to have edited value ${verb}` : ""
                }`}
                onClick={event => {
                  event.stopPropagation();
                  if (!onChange) {
                    return;
                  }
                  setTempValue(value ?? "");
                  onChange(
                    currentValueReverted
                      ? tempValue
                      : formattedOriginalValue
                      ? formattedOriginalValue
                      : "",
                  );
                  notification.info(notificationProps);
                }}
              />
            </Popover>
          )}{" "}
          {valueChanged && (
            <EditFilled
              title="Changes were made to this field"
              style={{ color: "#1890ff" }}
            />
          )}
        </span>
      }
    >
      <div style={{ width: "100%" }}>
        <Input
          data-cy={`${field}`}
          className={`value ${error ? "error" : ""}`}
          style={{
            width: isPaymentEngineField ? "75%" : "100%",
            float: "left",
          }}
          type="text"
          value={value}
          onClick={e => e.stopPropagation()}
          onChange={e => {
            onChange?.(e.target.value);
          }}
        />
        {isPaymentEngineField && enablePaymentEngine && (
          <Button
            icon={<SelectOutlined />}
            className="paymentengine-button"
            title="Open PaymentEngine Modal with the adjacent field selected"
            onClick={e => {
              e.stopPropagation();
              return (
                field &&
                togglePaymentEngineModal &&
                togglePaymentEngineModal({ field, title, value: value ?? "" })
              );
            }}
          />
        )}
      </div>
    </Form.Item>
  );
};
