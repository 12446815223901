import { DateFormater } from "../../DateFormater";
import { useColumn } from "../useColumn";

export const useColDateCreated = () => {
  return useColumn("datecreated", {
    title: "Created Date",
    renderFn(text: string, record: WDAsset | WDFolder) {
      if (record.type === "asset") {
        return <DateFormater date={record.datecreated} />;
      }
    },
  });
};
