export const isFulfilled = <T>(
  input: PromiseSettledResult<T>,
): input is PromiseFulfilledResult<T> => {
  return input.status === "fulfilled";
};

export const isRejected = (
  input: PromiseSettledResult<unknown>,
): input is PromiseRejectedResult => {
  return input.status === "rejected";
};
