// tslint:disable: forin
import _, { cloneDeep } from "lodash";
import { IStateDisclosureElement } from "shared/types/legalLingo";
import {
  AprOfferKey,
  AssetInstanceRecord,
  CommaField,
  DealerDiscountKey,
  FeedOffer,
  IAssetInstance,
  IOffer,
  IPDFCustomDisclosure,
  IPDFDisclosureParams,
  IProcessedOfferData,
  IRawOfferDataFromService,
  ISelectedOffer,
  OfferCustomKeys,
  OfferData,
  OfferRebateKeys,
  RawOfferData,
  RawSelectedOffers,
  RepeatableOfferKeys,
  SingletonOfferKeys,
} from "../shared/types/assetBuilder";
import { IDisclosure } from "../shared/types/legalLingo";
import { OfferType } from "../shared/types/shared";
import { formatNumberValue, returnEpochTimeString } from "./helpers";
import {
  commaFormattedFields,
  skippableProps,
} from "shared/constants/assetBuilder";
import moment from "moment";
import { dateFormat, yyyymmddFormat } from "shared/constants/dataManagement";

export interface INumberAtPriceDisclosureObj {
  lt_number_of_vehicles: number;
  max_number_of_vins: number;
  then_input: string;
  or_else_input: string;
}
const returnEditedRepeatedFields = (
  rawOfferData: RawOfferData,
  editedKeys: Record<string, boolean>,
) => {
  /* all repeatable values are arrays */
  const arrayFields = Object.keys(rawOfferData).filter(key =>
    Array.isArray(rawOfferData[key as keyof RawOfferData]),
  );
  return Object.keys(editedKeys).filter(key => arrayFields.includes(key));
};
/*
  The only values that need to be aggregated and split are rebate fields,
  the follow the pattern A, where A = <substring>Rebate<"" | "Disclosure" | "Name">
  the split keys have index i between Rebate and the suffix ^^^
*/
export const returnNumberRebateFieldName = (
  fieldName: string,
  index: number,
) => {
  const lowerCaseKey = fieldName.toLowerCase();
  const nameOrDisclosure = new RegExp(/disclosure|name|amount/);
  let numberedField = `${fieldName}${index}`;
  if (nameOrDisclosure.test(lowerCaseKey)) {
    const { index: startIndexOfPatt = 0 } = lowerCaseKey.match(
      nameOrDisclosure,
    ) || {
      index: 0,
    };
    numberedField =
      startIndexOfPatt > 0
        ? `${fieldName.substr(0, startIndexOfPatt)}${index}${fieldName.substr(
            startIndexOfPatt,
          )}`
        : fieldName;
  }
  return numberedField;
};

export const returnRepeatableTitle = (title: string, repeatIndex: number) => {
  let displayTitle = title;
  const rebateRegex = new RegExp(/\srebate\s/, "g");

  if (rebateRegex.test(title.toLowerCase())) {
    const newTitle = returnNumberRebateFieldName(title, repeatIndex + 1);
    displayTitle = newTitle.replace(/\d{1}/g, `${repeatIndex + 1} `);
  } else if (title.endsWith("Custom")) {
    displayTitle = `${title} ${repeatIndex + 1}`;
  }

  return displayTitle;
};

export const aggregateRepeatingValuesInOfferList = (
  offerList: Array<{ row: OfferData }>,
) => {
  if (!offerList || offerList.length === 0) {
    return offerList;
  }

  try {
    const repeatingKeys = returnRepeatingKeys(offerList);
    return offerList.map(offer => {
      return aggregateValuesForRepeatingKey(offer, repeatingKeys);
    });
  } catch (error) {
    return offerList;
  }
};
export const returnOfferDataWithSplitRepeatFields = (
  rawOfferData: RawOfferData,
  editedKeys: Record<string, boolean>,
  retainArrays?: boolean,
) => {
  const editedArrayKeys = returnEditedRepeatedFields(rawOfferData, editedKeys);
  if (editedArrayKeys.length < 1) {
    return { offerData: rawOfferData, editedKeys };
  }
  // TO DO: typecasting
  const newRawOfferData = { ...rawOfferData } as any;
  const newEditedKeys = { ...editedKeys };
  // handle new custom fields here
  editedArrayKeys.forEach(editedKey => {
    const arrayVal: string[] = newRawOfferData[editedKey as keyof RawOfferData];
    for (let i = 0; i < arrayVal.length; i += 1) {
      const numberedField = returnNumberRebateFieldName(editedKey, i + 1);
      newEditedKeys[numberedField] = true;
      newRawOfferData[numberedField] = arrayVal[i];
    }

    if (!retainArrays) {
      delete newEditedKeys[editedKey];
      delete newRawOfferData[editedKey];
    }
  });
  return { offerData: newRawOfferData, editedKeys: newEditedKeys };
};
const aggregateValuesForRepeatingKey = (
  offerListItem: { row: OfferData },
  repeatingKeys: string[],
) => {
  const endings = ["", "name", "disclosure"];
  const newOfferListItem = { ...offerListItem };
  const groupedRepeatingKeys = groupFieldsByOfferTypeBasedOnName(repeatingKeys);
  const offerDataKeys = Object.keys(groupedRepeatingKeys);
  const keysToAggregateMatrix = offerDataKeys.map(key =>
    /* The first key is <offerType>Rebate */
    returnFieldsForAggregation(offerListItem, groupedRepeatingKeys[key][0]),
  );
  const keysToAggregate = _.flatten(keysToAggregateMatrix);
  const groupedKeysToAggregate =
    groupFieldsByOfferTypeBasedOnName(keysToAggregate);
  offerDataKeys.forEach(offerTypeKey => {
    const currentRepeatGroup = groupedRepeatingKeys[offerTypeKey] || [];
    const currAggFieldGroup = groupedKeysToAggregate[offerTypeKey] || [];
    return currentRepeatGroup.map((field, index) => {
      const currEndPattern = endings[index];
      const offerField = field as RepeatableOfferKeys;
      newOfferListItem.row[offerField] = [];
      const fieldsToUse = currAggFieldGroup.filter(aggField => {
        const lowerCaseField = aggField.toLowerCase();
        if (index === 0) {
          return (
            !lowerCaseField.includes(endings[1]) &&
            !lowerCaseField.includes(endings[2])
          );
        }
        return lowerCaseField.split(currEndPattern).length > 1;
      });
      const values = fieldsToUse.map(fieldToUse => {
        const dataToReturn =
          newOfferListItem.row[fieldToUse as RepeatableOfferKeys] || "";
        delete newOfferListItem.row[fieldToUse as RepeatableOfferKeys];
        return dataToReturn;
      });
      const flattenedVals = _.flatten(values);
      const finalValues = flattenedVals.map(val => {
        if (!val) {
          return "";
        }
        return val;
      });
      newOfferListItem.row[offerField] =
        flattenedVals.filter(val => val).length > 0 ? finalValues : [""];
      return finalValues;
    });
  });
  return newOfferListItem;
};
const returnFieldsForAggregation = (
  offerListItem: { row: OfferData },
  repeatingPattern: string,
) => {
  const { row } = offerListItem;
  const containsNumberRegex = new RegExp(/\d/);
  return Object.keys(row)
    .filter(
      key =>
        key
          .toLowerCase()
          .startsWith(repeatingPattern.toLowerCase().toLowerCase()) &&
        containsNumberRegex.test(key),
    )
    .sort();
};
const returnRepeatingKeys = (offerList: Array<{ row: OfferData }>) => {
  /* all offers will have the same fields, so we can use the first element */
  const { row } = offerList[0];
  const keys = Object.keys(row).sort();
  const containsNumberRegex = new RegExp(/\d/);
  const rebateFields = keys.filter(
    key =>
      containsNumberRegex.test(key) && key.toLowerCase().includes("rebate"),
  );
  const sortedRebateFields = rebateFields.sort();
  const groupedFields = groupFieldsByOfferTypeBasedOnName(sortedRebateFields);
  const repeatingKeysMatrix = Object.keys(groupedFields).map(key => {
    const splitJoinedFields = groupedFields[key].map(field => {
      return field.split(containsNumberRegex).join("");
    });
    const setArray = Array.from(new Set(splitJoinedFields));
    const first = setArray[0];
    return setArray.filter(ele => ele.startsWith(first));
  });
  const repeatingKeys = _.flatten(repeatingKeysMatrix);

  return repeatingKeys;
};
const groupFieldsByOfferTypeBasedOnName = (fields: string[]) => {
  const offerTypes = Object.values(OfferType);
  return _.groupBy(fields, field => {
    let offerTypeToUse = "Vehicle Info";
    offerTypes.forEach(offerType => {
      const lowerCaseOfferType = offerType
        .replace(/[^a-zA-Z ]/g, "")
        .split(" ")
        .join("")
        .toLowerCase();
      const substr = field
        .toLowerCase()
        .split(lowerCaseOfferType.toLowerCase());
      if (substr.length > 1 && substr[0] === "") {
        offerTypeToUse = offerType;
      }
    });
    return offerTypeToUse;
  });
};
export const returnNumberAtThisPriceText = (
  numberAtPriceDisclosureObj: IStateDisclosureElement,
  offerList: IOffer[],
  currentVin?: string,
  feedOffer?: FeedOffer[],
  offers?: ISelectedOffer[],
) => {
  const numberAtPriceJson = JSON.parse(
    numberAtPriceDisclosureObj.text,
  ) as INumberAtPriceDisclosureObj;
  const {
    then_input: thenText = "",
    or_else_input: orElseText = "",
    max_number_of_vins: maxNumberOfVins,
    lt_number_of_vehicles: numberOfVehicles,
  } = numberAtPriceJson;

  const text =
    (offerList.length || feedOffer?.length || 1) < numberOfVehicles
      ? thenText
      : orElseText;

  return replaceNumberAtPriceVarText(
    text,
    offerList,
    maxNumberOfVins,
    currentVin,
    feedOffer,
    offers,
  );
};

const returnVinList = (offers: OfferData[]) => {
  return offers.map(offer => offer.vin);
};

export const replaceNumberAtPriceVarText = (
  text = "",
  offerList: IOffer[],
  maxNumberOfVins: number,
  currentVin?: string,
  feedOffer?: FeedOffer[],
  offers?: ISelectedOffer[],
) => {
  if (!text) {
    return "";
  }

  const limitOffersBaseOnNumberOfVins = (
    offers: OfferData[] | ISelectedOffer[] = [],
    maxNumberOfVins: number,
  ): OfferData[] | ISelectedOffer[] => {
    return offers.length > maxNumberOfVins
      ? offers.slice(0, maxNumberOfVins)
      : offers;
  };

  const compareOffers = (currentOffer: OfferData, offer: OfferData) => {
    return (
      offer.model === currentOffer.model &&
      offer.year === currentOffer.year &&
      offer.trim === currentOffer.trim &&
      offer.make === currentOffer.make &&
      offer.msrp.toString() === currentOffer.msrp.toString()
    );
  };

  const removeDuplicateVins = (vins: string[], offers: OfferData[]) => {
    const seenVins = new Set<string>(vins);
    return offers.filter(offer => !seenVins.has(offer.vin));
  };

  const processVinsAtPriceValue = (
    offers: OfferData[] = [],
    feedOffer: OfferData[] = [],
    currentVin?: string,
  ): OfferData[] => {
    if (!offers.length && !feedOffer.length) {
      return [];
    }
    if (offers.length + feedOffer.length === 1) {
      return offers.length ? offers : feedOffer;
    }

    const currentOffer = offers.find(offer => offer.vin === currentVin);

    if (!currentOffer) {
      return [];
    }

    const validatedSelectedFeedOffersWithoutCurrentVin = offers.filter(offer =>
      compareOffers(currentOffer, offer),
    );
    const validatedAvailableFeedOffers = feedOffer.filter(offer =>
      compareOffers(currentOffer, offer),
    ) as OfferData[];

    return [
      ...validatedSelectedFeedOffersWithoutCurrentVin,
      ...validatedAvailableFeedOffers,
    ];
  };

  const selectedOffersVin = offers?.map(o => o.offerData.vin) || [];

  const vinsAtPriceValue = processVinsAtPriceValue(
    offers?.map(o => o.offerData),
    removeDuplicateVins(selectedOffersVin, feedOffer as OfferData[]),
    currentVin,
  );

  const limitedAvailableOffers = limitOffersBaseOnNumberOfVins(
    vinsAtPriceValue,
    maxNumberOfVins,
  ) as OfferData[];

  const vinsListValue = returnVinList(limitedAvailableOffers);
  if (vinsListValue.length < 1 && currentVin) vinsListValue.push(currentVin);

  let newText = text.replace(
    /\{vinsAtThisPrice\}/gi,
    `${vinsAtPriceValue.length}`,
  );
  newText = newText.replace(/\{vinsList}/gi, `${vinsListValue.join(", ")}`);
  return newText;
};

export const repeatableFieldKeys: string[] = [
  "conditionalRebate",
  "conditionalRebateDisclosure",
  "conditionalRebateName",
  "leaseRebate",
  "leaseRebateDisclosure",
  "leaseRebateName",
  "purchaseRebate",
  "purchaseRebateDisclosure",
  "purchaseRebateName",
  "zeroDownLeaseRebate",
  "zeroDownLeaseRebateName",
  "zeroDownLeaseRebateDisclosure",
  "leaseCustom",
  "zeroDownLeaseCustom",
  "purchaseCustom",
  "financeCustom",
  "aprCustom",
  "leaseRebate",
  "leaseRebateDisclosure",
  "leaseRebateName",
  "leaseConditionalRebate",
  "leaseConditionalRebateDisclosure",
  "leaseConditionalRebateName",
  "purchaseRebate",
  "purchaseRebateDisclosure",
  "purchaseRebateName",
  "financeConditionalRebate",
  "financeConditionalRebateDisclosure",
  "financeConditionalRebateName",
  "zeroDownLeaseRebate",
  "zeroDownLeaseRebateName",
  "zeroDownLeaseRebateDisclosure",
  "zeroDownLeaseConditionalRebate",
  "zeroDownLeaseConditionalRebateName",
  "zeroDownLeaseConditionalRebateDisclosure",
  "leaseCustom",
  "zeroDownLeaseCustom",
  "purchaseCustom",
  "financeCustom",
  "aprCustom",
];

export const aggregateRebateFieldValues = (offerItem: IOffer) => {
  const rawOfferItem = offerItem as any as IRawOfferDataFromService;

  const propKeys = Object.keys(rawOfferItem.row);

  const regex1 = new RegExp("Rebate[1-6][A-Z]");
  const regex2 = new RegExp("Rebate[1-6]$");
  const regex3 = new RegExp("Custom[1-5]$");

  const keysToAggregate = [
    ...propKeys.filter(key => regex1.test(key)),
    ...propKeys.filter(key => regex2.test(key)),
    ...propKeys.filter(key => regex3.test(key)),
  ];

  const sortedKeysToAggr = keysToAggregate.sort();

  const tempRepeatKeyValDict: Record<RepeatableOfferKeys, string[]> = {
    conditionalRebate: [],
    conditionalRebateDisclosure: [],
    conditionalRebateName: [],
    leaseRebate: [],
    leaseRebateDisclosure: [],
    leaseRebateName: [],
    leaseConditionalRebate: [],
    leaseConditionalRebateDisclosure: [],
    leaseConditionalRebateName: [],
    purchaseRebate: [],
    purchaseRebateDisclosure: [],
    purchaseRebateName: [],
    zeroDownLeaseRebate: [],
    zeroDownLeaseRebateName: [],
    zeroDownLeaseRebateDisclosure: [],
    zeroDownLeaseConditionalRebate: [],
    zeroDownLeaseConditionalRebateName: [],
    zeroDownLeaseConditionalRebateDisclosure: [],
    financeConditionalRebate: [],
    financeConditionalRebateName: [],
    financeConditionalRebateDisclosure: [],
    leaseCustom: [],
    zeroDownLeaseCustom: [],
    purchaseCustom: [],
    financeCustom: [],
    aprCustom: [],
  };

  const aggregatedKeys = Object.keys(tempRepeatKeyValDict);

  sortedKeysToAggr.forEach(key => {
    const valueToPush =
      rawOfferItem.row[key as SingletonOfferKeys & OfferRebateKeys] || "";

    const matchingRepeatKey = aggregatedKeys.find(
      matchKey => matchKey === key.replace(/(\d)/, ""),
    );

    if (matchingRepeatKey) {
      tempRepeatKeyValDict[matchingRepeatKey as RepeatableOfferKeys].push(
        valueToPush,
      );
    }
  });

  aggregatedKeys.forEach(key => {
    const currentArray = [...tempRepeatKeyValDict[key as RepeatableOfferKeys]];
    const filteredArray = currentArray.filter(item => item);
    if (filteredArray.length < 1) {
      tempRepeatKeyValDict[key as RepeatableOfferKeys] = [""];
    }
  });

  const offerItemToUse: IProcessedOfferData = {
    ...rawOfferItem,
    row: {
      ...(rawOfferItem.row as Record<SingletonOfferKeys, string>),
      ...tempRepeatKeyValDict,
    } as RawOfferData,
  };

  // Eliminate empty rows that were previously defined
  const rebateFieldGroups = Array.from(
    new Set(
      sortedKeysToAggr
        .filter(key => key.split("Rebate").length > 1)
        .map(key => key.split("Rebate")[0]),
    ),
  );

  const customFieldGroups = Array.from(
    new Set(
      sortedKeysToAggr
        .filter(key => key.split("Custom").length > 1)
        .map(key => key.split("Custom")[0]),
    ),
  );

  for (let i = 0; i < customFieldGroups.length; i++) {
    const groupFields = sortedKeysToAggr.filter(key =>
      key.startsWith(customFieldGroups[i]),
    );

    for (let j = 1; j <= 5; j++) {
      const regex = new RegExp(`Custom${j}$`);
      const customField = groupFields.find(key => regex.test(key));

      if (!customField) {
        continue;
      }

      const filterArray = [customField?.replace(/(\d)/, "")].filter(
        field => field,
      );

      const matchingRepeatKeys = aggregatedKeys.filter(matchKey =>
        filterArray.includes(matchKey),
      );

      const customFieldValue = rawOfferItem.row[customField as OfferCustomKeys];
      const filterAmounts = [customFieldValue].filter(val => !val);
      const willRemoveRow = filterAmounts.length === filterArray.length;

      if (!willRemoveRow) {
        continue;
      }

      for (let k = 0; k < matchingRepeatKeys.length; k++) {
        offerItemToUse.row[
          matchingRepeatKeys[k] as RepeatableOfferKeys
        ]?.splice(j - 1, 1);
      }
    }
  }

  for (let i = 0; i < rebateFieldGroups.length; i++) {
    const groupFields = sortedKeysToAggr.filter(key =>
      key.startsWith(rebateFieldGroups[i]),
    );

    for (let j = 1; j <= 6; j++) {
      const regex1 = new RegExp(`Rebate${j}`);
      const regex2 = new RegExp(`Rebate${j}$`);

      const rebateAmountField = groupFields.find(key => regex2.test(key));
      const rebateNameField = groupFields.find(
        key => regex1.test(key) && key.endsWith("Name"),
      );
      const rebateDisclosureField = groupFields.find(
        key => regex1.test(key) && key.endsWith("Disclosure"),
      );

      if (!rebateAmountField && rebateNameField && rebateDisclosureField) {
        continue;
      }

      const filterArray = [
        rebateAmountField?.replace(/(\d)/, ""),
        rebateNameField?.replace(/(\d)/, ""),
        rebateDisclosureField?.replace(/(\d)/, ""),
      ].filter(field => field);

      const matchingRepeatKeys = aggregatedKeys.filter(matchKey =>
        filterArray.includes(matchKey),
      );

      const rebateAmount =
        rawOfferItem.row[rebateAmountField as OfferRebateKeys];
      const rebateName = rawOfferItem.row[rebateNameField as OfferRebateKeys];
      const rebateDisclosure =
        rawOfferItem.row[rebateDisclosureField as OfferRebateKeys];

      const filterAmounts = [rebateAmount, rebateName, rebateDisclosure].filter(
        val => !val,
      );

      const willRemoveRow = filterAmounts.length === filterArray.length;

      if (!willRemoveRow) {
        continue;
      }

      for (let k = 0; k < matchingRepeatKeys.length; k++) {
        const repeatkey = matchingRepeatKeys[k] as RepeatableOfferKeys;
        offerItemToUse.row[repeatkey]?.splice(j - 1, 1);

        /*
          Fix for AV2-3461: when clearing rebate fields to the initial state,
          the array will sometimes be cleared to be []
        */
        if (!offerItemToUse.row[repeatkey]?.length) {
          // the rebate value arrays need to be initialized with [""]
          offerItemToUse.row[repeatkey] = [""];
        }
      }
    }
  }

  const regex4 = new RegExp("[1-6]{1}");
  /*
    Av2-1513: It was found that sometimes the single rebate
    fields do not erase along with the aggregated array counterpart.
    This logic fixes that
  */
  for (const key of sortedKeysToAggr) {
    const value = offerItemToUse.row[key as SingletonOfferKeys];
    if (!value) {
      continue;
    }
    const splitKey = key.split(/[1-6]{1}/g);
    const aggregatedKey = `${splitKey[0]}${splitKey[1] || ""}`;
    const typedAggrKey = aggregatedKey as RepeatableOfferKeys;
    const keyIsValid = aggregatedKeys.includes(aggregatedKey);

    if (!keyIsValid) {
      continue;
    }

    const aggregatedValue =
      tempRepeatKeyValDict[typedAggrKey] ||
      (rawOfferItem.row as unknown as OfferData)[typedAggrKey];

    const index = key.match(regex4)?.[0];

    if (!index || aggregatedValue?.[parseInt(index) - 1]) {
      continue;
    }

    delete offerItemToUse.row[key as SingletonOfferKeys];
  }

  return offerItemToUse;
};

const skipPropRegex = /term|fico|centsper|cylinders|disclosure|numberof/g;
const percentPropRegex = /rate|percent/g;
const percentageRegex = /^\d+(?:\.\d+)?\%$/g;
const priceRegex = /^(\d+,\d+(,\d+)*).\d{2}$/g;

const addZeroToEndProps = [
  "centsPerMile",
  "zeroDownLeaseCentsPerMile",
] as Array<keyof OfferData>;

export const formatFieldValues = (
  offer: IProcessedOfferData,
  isRendering?: boolean,
) => {
  const rowObject = setCalculatedFieldsInOfferData(offer, false /*, dealer*/);

  for (const prop in rowObject.row) {
    const typedProp = prop as keyof OfferData;
    const propValue = rowObject.row[typedProp];

    if (Array.isArray(propValue) || !propValue) {
      continue;
    }

    (rowObject.row[typedProp] as string) = formatOfferDataFieldValue(
      typedProp,
      propValue.toString(),
      isRendering,
    );
  }

  return rowObject;
};

const calculatePMT = (ir: number, np: number, pv: number, fv = 0, type = 0) => {
  /*
   * ir   - interest rate per month
   * np   - number of periods (months)
   * pv   - present value
   * fv   - future value
   * type - when the payments are due:
   *        0: end of the period, e.g. end of month (default)
   *        1: beginning of period
   */

  if (ir === 0) return -(pv + fv) / np;

  const pvif = Math.pow(1 + ir, np);
  let pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);

  if (type === 1) pmt /= 1 + ir;

  return pmt;
};

export const calculateAprPayment = (
  offer: IProcessedOfferData | { row: { [key in AprOfferKey]: string } },
) => {
  const { aprRate = 0, aprTerm = 0, aprAmntFinanced = 0 } = offer.row;
  if (!aprRate || !aprTerm || !aprAmntFinanced) {
    return "N/A";
  }

  const aprRateValue = parseFloat(formatRateAsDecimal(aprRate));
  const aprTermValue = parseInt(aprTerm, 10);

  const aprAmntFinancedValue = parseInt(aprAmntFinanced.replace(",", ""), 10);

  // Payment = (1000)  * (APR Rate/12) / (1-(1+APR Rate/12)^(-APR Term))
  const paymentValue =
    calculatePMT(aprRateValue / 12, aprTermValue, aprAmntFinancedValue) * -1;
  // (1000 * (aprRateValue / 12)) /
  // (1 - Math.pow(1 + aprRateValue / 12, -aprTermValue));

  return paymentValue.toFixed(2);
};

export const calculateDealerDiscount = (
  offer: IProcessedOfferData | { row: { [key in DealerDiscountKey]: string } },
  dealerState?: string,
) => {
  const { msrp, dealerPrice, accessoryPrice } = offer.row;

  const msrpValue = parseFloat((msrp || "0").replace(",", ""));
  const dealerPriceValue = parseFloat((dealerPrice || "0").replace(",", ""));
  const accessoryPriceValue = parseFloat(
    (accessoryPrice || "0").replace(",", ""),
  );

  const accessoryPriceNotZero =
    !isNaN(accessoryPriceValue) && accessoryPriceValue !== 0;

  if (dealerState === "TX" && accessoryPriceNotZero) {
    return "0";
  }

  const dealerDiscountValue =
    msrpValue - dealerPriceValue - accessoryPriceValue;

  if (isNaN(dealerDiscountValue)) {
    return "N/A";
  }

  return dealerDiscountValue.toFixed(2);
};

export const calculatePercentageOffMSRP = ({
  msrp,
  savingsOffMSRP,
}: {
  msrp: string | number;
  savingsOffMSRP: string | number;
}) => {
  const msrpToUse =
    typeof msrp === "string" ? parseFloat(msrp.replace(",", "")) : msrp;

  const savingsOffMSRPToUse = parseFloat(
    typeof savingsOffMSRP === "string" && savingsOffMSRP.includes("$")
      ? savingsOffMSRP.split("$")[1].replace(",", "")
      : savingsOffMSRP.toString().replace(",", ""),
  );

  let percentageOffMSRPValue = (savingsOffMSRPToUse / msrpToUse) * 100;

  if (!isFinite(percentageOffMSRPValue)) {
    percentageOffMSRPValue = 0;
  }

  return `${
    percentageOffMSRPValue !== 0 ? percentageOffMSRPValue.toFixed(2) : 0
  }%`;
};

export const setCalculatedFieldsInOfferData = (
  offer: IProcessedOfferData,
  removeFormatting?: boolean,
) => {
  const rowObject = cloneDeep(offer) as { row: OfferData };

  rowObject.row.aprPayment = calculateAprPayment(offer);

  /*
  // Original ticket: https://theconstellationagency.atlassian.net/browse/AV2-3472 - calculate dealerDiscount
  // AV2-3653: Lithia/LADTech requested that this be disabled, but will be used later
  rowObject.row.dealerDiscount = removeFormatting
    ? calculateDealerDiscount(offer, dealer?.state)
    : formatOfferDataFieldValue(
        "dealerDiscount",
        calculateDealerDiscount(offer, dealer?.state),
      );
  */

  // AV2-2099: calculate percentageOffMSRP dynamically
  rowObject.row.percentOffMSRP = calculatePercentageOffMSRP({
    msrp: rowObject.row.msrp,
    savingsOffMSRP: rowObject.row.savingsOffMSRP,
  });

  if (removeFormatting) {
    for (const field in rowObject.row) {
      const value = rowObject.row[field as keyof OfferData];
      if (
        !commaFormattedFields.includes(field as CommaField) ||
        typeof value !== "string"
      ) {
        continue;
      }

      (rowObject.row[field as keyof OfferData] as string) = value.replace(
        ",",
        "",
      );
    }
  }

  return rowObject;
};

export const processRawSelectedOffers = (
  rawSelectedOffers: RawSelectedOffers,
  offerDataArray?: OfferData[],
) => {
  const ret: ISelectedOffer[] = [];

  const filtered = Object.assign(
    {},
    ...Object.entries(rawSelectedOffers)
      .filter(([, value]) => value.offerIndex !== undefined)
      .map(([key, value]) => ({ [key]: value })),
  );

  for (const vin in filtered) {
    const { offerData, offerIndex } = rawSelectedOffers[vin];

    const offers = (Object.keys(offerIndex) as OfferType[]).filter(
      offerType => offerIndex[offerType],
    );

    const selectedOfferData =
      offerDataArray && offerDataArray.find(offerData => offerData.vin === vin);

    if (offers.length) {
      ret.push({
        offerData: selectedOfferData || offerData,
        offers,
      });
    }
  }

  return ret;
};

export const returnNewRebateFieldArray = (
  rebateFieldArray: string[],
  newRebateValue: string,
  index: number,
) => {
  if (index > rebateFieldArray.length) {
    return [...rebateFieldArray, newRebateValue];
  }

  return [
    ...rebateFieldArray.slice(0, index),
    newRebateValue,
    ...rebateFieldArray.slice(index + 1),
  ];
};

export const dateVariableNames = [
  "expirationDate",
  "leaseExpirationDate",
  "dateInStock",
] as Array<keyof OfferData>;

export const applyCommaFormat = (
  value: string | number,
  willRoundValue?: boolean,
) => {
  if (!value) {
    return "0";
  }

  const newValue =
    typeof value === "number"
      ? (value as number).toLocaleString()
      : value.includes(",")
      ? value
      : (
          Math.round((parseFloat(value) + Number.EPSILON) * 100) / 100
        ).toLocaleString();

  if (newValue === "NaN") {
    return value.toString();
  }

  if (willRoundValue) {
    return Math.round(parseFloat(newValue.replace(",", "")))
      .toLocaleString()
      .split(".")[0];
  }

  if (newValue.match(/\.0{1,}$/g)) {
    return newValue.split(/\.0{1,}$/g)[0];
  }

  if (newValue.match(/\.[1-9]{1}$/g)) {
    return `${newValue}0`;
  }

  return newValue;
};

export const formatOfferDataFieldValue = (
  typedProp: keyof OfferData,
  propValue: string,
  isRendering?: boolean,
) => {
  if (!typedProp || !propValue) {
    return "";
  }
  let valueToReturn = propValue?.trim?.() || "";

  if (["aprRate", "financeRate"].includes(typedProp) && valueToReturn) {
    return valueToReturn;
  }

  if (typedProp.toLowerCase().endsWith("rate")) {
    if (valueToReturn.startsWith(".")) {
      valueToReturn = `0${valueToReturn}`;
    }

    return valueToReturn.replace("%", "");
  }

  if (!valueToReturn) {
    return "";
  }

  const formatCentsPerMile =
    addZeroToEndProps.includes(typedProp) && /(\.\d{1})$/g.test(valueToReturn);

  if (addZeroToEndProps.includes(typedProp)) {
    /*
    AV2-1663: Add 0 to end of centsPerMile fields in LADTech feed,
    if there is only 1 decimal in value
  */
    if (formatCentsPerMile) {
      return `${valueToReturn}0`;
    }

    return !isNaN(Number(valueToReturn))
      ? Number(valueToReturn).toFixed(2).toString()
      : valueToReturn;
  }

  if (commaFormattedFields.includes(typedProp as CommaField)) {
    return applyCommaFormat(valueToReturn);
  }

  const lowerCaseProp = typedProp.toLowerCase();

  if (
    (!isRendering || moment(valueToReturn).isValid()) && // is not for rendering or valid string-format date
    lowerCaseProp !== "offerenddate" && // CP-651: offerenddate should not be converted to epoch time
    lowerCaseProp.includes("date")
  ) {
    if (
      typedProp === "expirationDate" &&
      valueToReturn.split(/am|pm$/).length > 1
    ) {
      // if expiration date was saved in "YYYY/MM/DD hh:mma", it becomes invalid
      const dateWithTime = moment(valueToReturn, yyyymmddFormat);
      valueToReturn = dateWithTime.format(dateFormat);
    }
    return returnEpochTimeString(valueToReturn);
  }

  // Hotfix: Below code assumes that "valueToReturn" is number string.
  // In case of "valueToReturn" equals something like "9000-some-text", parseFloat() below returns 900 which isnt correct.
  // Checking here if the "valueToReturn" is actually a number string.
  if (Number.isNaN(+valueToReturn)) return valueToReturn || "";

  const floatParsed = parseFloat(valueToReturn);
  const skipProp =
    skippableProps.includes(typedProp) ||
    skipPropRegex.test(lowerCaseProp) ||
    formatCentsPerMile;
  const isNumberFormatted =
    percentageRegex.test(valueToReturn) || priceRegex.test(valueToReturn);

  if (skipProp || isNaN(floatParsed) || isNumberFormatted || !valueToReturn) {
    return valueToReturn || "";
  }

  // Unintended fields enter this if statement and have a comma format.
  // any name or any disclosure must be filtered
  if (
    ["ladtech", "internal"].includes(
      process.env.REACT_APP_AV2_CLIENT || "internal",
    ) &&
    !typedProp.toLowerCase().endsWith("name") &&
    !typedProp.toLowerCase().endsWith("disclosure")
  ) {
    const isLargePercentage =
      percentPropRegex.test(lowerCaseProp) && parseFloat(valueToReturn) > 1;

    return formatNumberValue(
      valueToReturn.toString().replace(",", ""),
      isLargePercentage,
    );
  }

  return valueToReturn;
};

export const formatRateAsPercentage = (value: string): string => {
  if (!value) {
    return "";
  }

  const floatValue = parseFloat(value);
  const result =
    value.endsWith("%") || floatValue > 1
      ? floatValue
      : _.round(floatValue * 100, 2);

  return Number.isFinite(result) ? result.toString() : "";
};

export const formatRateAsDecimal = (value: string): string => {
  if (!value) {
    return "";
  }

  const floatValue = parseFloat(value);

  const result = _.round(floatValue / 100, 4);

  return Number.isFinite(result) ? result.toString() : "";
};

// handles rate value inconsistencies
export const formatRate = (value: string): string => {
  if (!value) {
    return "";
  }

  const floatValue = parseFloat(value);
  if (floatValue >= 100) {
    return formatRateAsDecimal(value);
  }
  return formatRateAsPercentage(value);
};

export const mapRateValuesAsDecimal = (
  offer: ISelectedOffer,
): ISelectedOffer => {
  return {
    ...offer,
    offerData: {
      ...offer.offerData,
      aprRate: formatRateAsDecimal(offer.offerData.aprRate),
      financeRate: formatRateAsDecimal(offer.offerData.financeRate),
    },
  };
};

export const mapRateValuesAsPercentage = (
  offer: ISelectedOffer,
): ISelectedOffer => {
  return {
    ...offer,
    offerData: {
      ...offer.offerData,
      aprRate: formatRateAsPercentage(offer.offerData.aprRate),
      financeRate: formatRateAsPercentage(offer.offerData.financeRate),
    },
  };
};

export const mapTrimedValues = (offer: ISelectedOffer): ISelectedOffer => {
  return {
    ...offer,
    offerData: {
      ...offer.offerData,
      year: offer.offerData.year?.trim() || "",
      make: offer.offerData.make?.trim() || "",
      model: offer.offerData.model?.trim() || "",
    },
  };
};

export const findDimensionFromName = (assetName: string[]) => {
  return assetName.filter(partOfName => partOfName.includes(" x "))?.[0];
};

export const getBgFileName = (
  assetName: string[],
  assetInstances: AssetInstanceRecord,
) => {
  const dimension = findDimensionFromName(assetName);
  const imgIndex = Number([...assetName].reverse()[0]);
  const assetType = assetName[0];
  const bgFileName =
    assetInstances[assetType]?.[dimension]?.[imgIndex]?.lifestyleImageName;

  return bgFileName ?? "";
};

export const extractOfferVarsFromName = (assetName: string[]) => {
  // note that internal and ladtech have different naming patterns but
  // the parts being used are universal between the 2
  const [_firstPart, _secondPart, assetType] = assetName;
  const reversedAssetName = [...assetName].reverse();
  const [idx, _secondLast, dimension] = reversedAssetName;
  return [assetType, idx, dimension];
};

export const findMatchingDiscRow = (
  batch: IPDFCustomDisclosure[],
  assetType: string,
  index: number,
  dimension: string,
) => {
  return batch.find(row => {
    return (
      row.cleanedText.length &&
      row?.assetType === assetType &&
      row?.idx === index &&
      row?.dimension === dimension
    );
  });
};

export const findValidCustomDisclosure = (instance: IAssetInstance) => {
  const defaultDisclosure: IDisclosure = {
    id: 0,
    name: "",
    vin: true,
    condition: [],
    location: [],
    oem: [],
    store: [],
    disclosures: [],
  };

  if (instance.disclosure) {
    const { selectedAsset, currentSize, allSizes } = instance.disclosure;
    return selectedAsset || currentSize || allSizes || defaultDisclosure;
  }
  return defaultDisclosure;
};

export const pushCustomDiscs = (
  foundCustomDiscs: IPDFDisclosureParams[],
  discToCheck: IPDFDisclosureParams,
  assetType: string,
  idx: number,
  dimension: string,
) => {
  if (
    discToCheck &&
    discToCheck.assetType === assetType &&
    discToCheck.dimension === dimension &&
    discToCheck.disclosure
  ) {
    const customDisc: IPDFDisclosureParams = {
      assetType,
      idx,
      dimension,
      vin: discToCheck.vin,
      disclosure: discToCheck.disclosure,
    };
    foundCustomDiscs.push(customDisc);
  }
  return foundCustomDiscs;
};

export const useFoundDisc = (
  feedId: string,
  offerData: OfferData,
  foundDisclosure?: IPDFDisclosureParams,
) => {
  if (!feedId) return true;
  if (foundDisclosure) {
    if (
      offerData.vin !== foundDisclosure.vin ||
      !!foundDisclosure.disclosure.disclosures.length
    ) {
      return true;
    }
  }
  return false;
};
