import { getFieldName } from "./getFieldName";
import { getFieldValue } from "./getFieldValue";
import { getSearchField } from "./getSearchField";

export const getSearchFields = (
  values: WDSearchField[] | MGSearchObj,
): WDSearchField[] => {
  if (Array.isArray(values)) {
    return values.map(toSearchField).filter(filterEmpty);
  }

  return Object.entries(values)
    .map(args => getSearchField(...args))
    .filter(filterEmpty);
};

const toSearchField = (field: WDSearchField): WDSearchField => {
  return {
    ...field,
    field_name: getFieldName(field.field_name),
    value: getFieldValue(field.value),
  };
};

const filterEmpty = (field: WDSearchField): boolean => {
  return !!field.value?.length;
};
