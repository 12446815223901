import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { OfferType } from "shared/types/shared";
import { OfferData } from "shared/types/assetBuilder";
import { toggleOffer } from "redux/assetBuilder/assetBuilder.slice";

type ToggleOfferArgs = {
  offerType: OfferType;
  offerData: OfferData;
};

const useToggleOffer = () => {
  const dispatch = useAppDispatch();
  return (args: ToggleOfferArgs) => {
    const { offerData, offerType } = args;

    dispatch(
      toggleOffer({
        offerData,
        offerType,
      }),
    );
  };
};

export default useToggleOffer;
