import { useNavigate, useLocation } from "react-router-dom";

import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { setAdLoadState } from "redux/adLibrary/adLibrary.slice";

import { stepUrlParamValues } from "../constants";

import { AdLoadStepKey } from "shared/types/adLibrary";
import { SetStateAction, useCallback } from "react";

type useGoToStepArgs = {
  wasLoadButtonClicked?: boolean;
  adLoadStep: AdLoadStepKey | null;
  setWasLoadToFBStarted?: (value: SetStateAction<boolean>) => void;
};

export const useGoToAdLoadStep = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  return useCallback(
    (args: useGoToStepArgs) => {
      if (!args.adLoadStep) {
        dispatch(
          setAdLoadState({
            prop: "currentStep",
            data: null,
          }),
        );
        return;
      }

      const urlParams = new URLSearchParams(location.search);
      urlParams.set("step", stepUrlParamValues[args.adLoadStep]);
      navigate({ search: urlParams.toString() });

      args.setWasLoadToFBStarted?.(
        !!args.wasLoadButtonClicked &&
          args.adLoadStep === AdLoadStepKey.LOAD_ADS,
      );
    },
    [dispatch, navigate, location.search],
  );
};
