import { useCallback } from "react";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { setPdfExecNames } from "redux/adLibrary/adLibrary.slice";
import { useAppSelector } from "shared/hooks/useAppSelector";

export const usePdfExecNames = () => {
  const pdfExecNames = useAppSelector(
    state => state.adLibrary.adReview.pdfExecNames,
  );

  const dispatch = useAppDispatch();
  const handleSetPdfExecNames = useCallback(
    (newPdfExecNames: string[]) =>
      dispatch(setPdfExecNames({ pdfExecNames: newPdfExecNames })),
    [dispatch],
  );

  return { pdfExecNames, handleSetPdfExecNames };
};
