import { upperFirst } from "lodash";
import { useCallback } from "react";
import { useGetMetaSchemaQuery } from "redux/media/media.api";

export const useMetaSchema = () => {
  const { data: schema } = useGetMetaSchemaQuery();

  const getSchema = useCallback(
    (key: string) => {
      return schema?.xmp[key];
    },
    [schema],
  );

  const getLabel = useCallback(
    (key: string) => {
      return getSchema(key)?.label ?? upperFirst(key);
    },
    [getSchema],
  );

  return {
    schema,
    getSchema,
    getLabel,
  };
};
