import { COLUMN_ASSET_BATCHES } from "shared/constants/assetExporter";
import { FeedTblCol } from "shared/types/assetExporter";
import { RowType } from "../../shared/types";
import { PortalConfigurationTableData } from "../types";

export const getPortalConfigurationTableData = (
  feedColumns: FeedTblCol[],
): PortalConfigurationTableData[] => {
  const assetRowData = {
    key: "asset-row",
    title: "asset",
    rowType: RowType.Media,
  };

  const rowData = feedColumns
    .filter(
      feedColumn =>
        typeof feedColumn.title === "string" &&
        !!feedColumn.title &&
        feedColumn.key !== COLUMN_ASSET_BATCHES,
    )
    .map(feedColumn => ({
      key: feedColumn.key,
      title: typeof feedColumn.title === "string" ? feedColumn.title : "",
      rowType: RowType.Feed,
    }));

  return [assetRowData, ...rowData];
};
