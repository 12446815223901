import { Button, Drawer, Tooltip, message } from "antd";
import { Key } from "rc-tree/lib/interface";
import { useState } from "react";
import { useUpdateAssetPropsMutation } from "redux/media/media.api";
import { useMediaActions } from "redux/media/media.slice";
import { ButtonGroup } from "../ButtonGroup";
import { SelectFolder } from "../SelectFolder";
import { handleApiError } from "../handleApiError";
import { useAppSelector } from "../redux";
import { useFetchPerms } from "../useFetchPerms";
import { useSelected } from "../useSelected";

export const FileMove = () => {
  const assetsToMove = useAppSelector(state => state.media.assetsToMove);
  const [isMoving, setIsMoving] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState<number>();
  const [updateAssetProps] = useUpdateAssetPropsMutation();
  const { setAssetsToMove } = useMediaActions();
  const { clearSelected } = useSelected();
  const folderIds = assetsToMove?.map(a => a.folder.id) || [];
  const { canMove } = useFetchPerms(folderIds, !!folderIds.length);

  const onClose = () => {
    setAssetsToMove();
  };

  const onSelect = ([key]: Key[]) => {
    setSelectedFolder(+key);
  };

  const onSubmit = async () => {
    setIsMoving(true);
    try {
      const props = assetsToMove!.map(({ id }) => ({
        id,
        folder: { id: selectedFolder! },
      }));
      await updateAssetProps({ props }).unwrap();
      message.success(`Moved ${assetsToMove!.length} file(s)`);
      clearSelected();
    } catch (err: any) {
      handleApiError(err);
    } finally {
      setIsMoving(false);
      onClose();
    }
  };

  return (
    <Drawer
      title="Move file"
      destroyOnClose
      maskClosable
      width={500}
      onClose={onClose}
      visible={!!assetsToMove}
      footer={
        <ButtonGroup>
          <Button onClick={onClose}>Cancel</Button>
          <Tooltip title={!canMove && "Requires move permission"}>
            <Button
              type="primary"
              onClick={onSubmit}
              loading={isMoving}
              disabled={!selectedFolder || !canMove}
            >
              Move
            </Button>
          </Tooltip>
        </ButtonGroup>
      }
    >
      <div>
        <p>Choose the folder where you want to move the file(s)</p>
        <SelectFolder onSelect={onSelect} />
      </div>
    </Drawer>
  );
};
