import { PrintOptionsTableItem } from "shared/types/printOptions";

export const mockData: PrintOptionsTableItem[] = [
  {
    id: "1",
    name: "Article",
    printMode: ["SIMPLEX", "DUPLEX"],
    costPerCopy: [
      { id: "1", quantity: { min: 1, max: 50 }, mode: "SIMPLEX", cost: "0.10" },
      {
        id: "2",
        quantity: { min: 51, max: 100 },
        mode: "DUPLEX",
        cost: "0.08",
      },
    ],
    weight: "100g",
    associatedTemplates: "101",
    referenceNumber: "REF-001",
  },
  {
    id: "2",
    name: "Brochure",
    printMode: ["DUPLEX"],
    costPerCopy: [
      { id: "1", quantity: { min: 1, max: 50 }, mode: "SIMPLEX", cost: "0.15" },
      {
        id: "2",
        quantity: { min: 51, max: 100 },
        mode: "SIMPLEX",
        cost: "0.12",
      },
    ],
    weight: "150g",
    associatedTemplates: "102",
    referenceNumber: "REF-002",
  },
  {
    id: "3",
    name: "Poster 8.5x11",
    printMode: ["SIMPLEX"],
    costPerCopy: [
      { id: "1", quantity: { min: 1, max: 50 }, mode: "SIMPLEX", cost: "0.20" },
      {
        id: "2",
        quantity: { min: 51, max: 100 },
        mode: "DUPLEX",
        cost: "0.18",
      },
    ],
    weight: "200g",
    associatedTemplates: "103",
    referenceNumber: "REF-003",
  },
  {
    id: "4",
    name: "Poster 11x17",
    printMode: ["SIMPLEX"],
    costPerCopy: [
      { id: "1", quantity: { min: 1, max: 50 }, mode: "DUPLEX", cost: "0.25" },
      {
        id: "2",
        quantity: { min: 51, max: 100 },
        mode: "SIMPLEX",
        cost: "0.22",
      },
    ],
    weight: "250g",
    associatedTemplates: "104",
    referenceNumber: "REF-004",
  },
  {
    id: "5",
    name: "Contact Card",
    printMode: ["SIMPLEX", "DUPLEX"],
    costPerCopy: [
      { id: "1", quantity: { min: 1, max: 50 }, mode: "SIMPLEX", cost: "0.05" },
      {
        id: "2",
        quantity: { min: 51, max: 100 },
        mode: "DUPLEX",
        cost: "0.04",
      },
    ],
    weight: "50g",
    associatedTemplates: "105",
    referenceNumber: "REF-005",
  },
  {
    id: "6",
    name: "Buckslip",
    printMode: ["SIMPLEX"],
    costPerCopy: [
      { id: "1", quantity: { min: 1, max: 50 }, mode: "SIMPLEX", cost: "0.12" },
      {
        id: "2",
        quantity: { min: 51, max: 100 },
        mode: "SIMPLEX",
        cost: "0.10",
      },
    ],
    weight: "75g",
    associatedTemplates: "106",
    referenceNumber: "REF-006",
  },
];
