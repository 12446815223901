import { useCallback, useEffect, useLayoutEffect } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import { TableActionsContextProvider } from "shared/components/HoverActionsVirtualTable/TableActionsContext";
import { ToolbarButton } from "shared/components/toolbarTable/ToolbarTable";
import { ContextTableActions } from "shared/components/tableActions/ContextTableActions";
import { InlineTableActions } from "shared/components/tableActions/InlineTableActions";
import { BrandAccountRecord, BrandRecord } from "shared/types/brandsAccounts";
import { useBrandsAccountsToolbar } from "shared/hooks/brandsAccountsManagement/useBrandsAccountsToolbar";
import { useDataList } from "shared/components/dataList";
import { SourceBrandsAccountsToolbar } from "./SourceBrandsAccountsToolbar";
import SummarySelected from "./SummarySelected";
import { BrandsAccountsTable } from "./BrandsAccountsTable";
import { DrawerSelector } from "./DrawerSelector";
import { useBrandsAccountsContext } from "./contexts/BrandsAccountsContext";
import { StringParam, useQueryParam } from "use-query-params";

export const BrandsAccountsList = () => {
  const [_, dataListActions] = useDataList<BrandRecord>();

  const onComponentUnmount = useCallback(() => {
    dataListActions.setSelectedItems([]);
  }, [dataListActions]);

  useEffect(() => onComponentUnmount, [onComponentUnmount]);

  const { onDelete, onEdit, onNewAccount } = useBrandsAccountsToolbar();

  const { onlyAccountsMode, isDrawerOpen } = useBrandsAccountsContext();

  const [brandName, setBrandName] = useQueryParam("oem_name", StringParam);

  useLayoutEffect(() => {
    if (!brandName) return;

    onEdit({ clickedTargetId: brandName, isBrand: true });
    if (isDrawerOpen) {
      setBrandName(undefined);
    }
  }, [brandName, onEdit, setBrandName, isDrawerOpen]);

  const generateToolbarContents: (
    record?: BrandAccountRecord,
  ) => ToolbarButton = useCallback(
    record => ({
      Edit: {
        onClick: () => {
          onEdit({
            clickedTargetId: record?.id ?? "",
            isBrand: record?.type === "Brand",
            isOnlyAccountsMode: onlyAccountsMode,
          });
        },
        showInInlineMenu: true,
        showInContextMenu: true,
        icon: <EditOutlined />,
      },
      Delete: {
        onClick: () => {
          if (!record) return;
          onDelete(record);
        },
        showInInlineMenu: true,
        showInContextMenu: true,
        icon: <DeleteOutlined />,
      },
      ...(record?.type === "Brand" && {
        CustomButton: {
          disabled: false,
          onClick: () => {
            onNewAccount(record?.id);
          },
          extraInfo: {
            text: "New Account",
            title: "New Account",
            tooltipText: "New Account",
          },
          showInInlineMenu: true,
          showInContextMenu: true,
          icon: <FileAddOutlined />,
        },
      }),
    }),
    [onEdit, onDelete, onNewAccount, onlyAccountsMode],
  );

  return (
    <>
      <SourceBrandsAccountsToolbar />
      <SummarySelected tableId="brands" />
      <TableActionsContextProvider
        renderContextActions={(record?: BrandAccountRecord) => (
          <ContextTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
        renderHoverActions={(record?: BrandAccountRecord) => {
          if (!onlyAccountsMode)
            return (
              <InlineTableActions
                getItemsToRender={generateToolbarContents}
                record={record}
              />
            );

          return null;
        }}
      >
        <DrawerSelector />
        <BrandsAccountsTable tableId="brands" />
      </TableActionsContextProvider>
    </>
  );
};
