import { ITableColumn } from "shared/components/dataList";
import { ThumbnailCell } from "shared/components/ThumbnailCell";
import { PmaxLoadItem, PmaxReviewItemWithLoadStatus } from "../../shared/types";
import { getThumbnailImage } from "../../utils";
import { LoadStatusBadgeCell } from "./LoadStatusBadgeCell";
import styles from "./PmaxLoadStep.module.scss";
import { getComputedCampaignLoadStatus } from "./utils";

export const columns: ITableColumn<PmaxLoadItem>[] = [
  {
    key: "campaign",
    title: "Campaign",
    dataIndex: ["campaign"],
  },
  {
    key: "loadStatus",
    title: "Load Status",
    width: 200,
    render: (_, item) => (
      <LoadStatusBadgeCell status={getComputedCampaignLoadStatus(item)} />
    ),
  },
];

export const innerColumns: ITableColumn<PmaxReviewItemWithLoadStatus>[] = [
  {
    key: "name",
    title: "Name",
    className: styles.innerRow,
    render: (_, item) => (
      <ThumbnailCell
        name={item.assetGroup.name}
        image={getThumbnailImage(item.assetGroup)}
      />
    ),
  },
  {
    key: "loadStatus",
    title: "Load Status",
    className: styles.innerRow,
    width: 200,
    render: (_, item) => <LoadStatusBadgeCell status={item.loadStatus} />,
  },
];
