import * as React from "react";
import { useCallback } from "react";

export default (isConnecting: boolean) => {
  const [isTryingToConnect, setIsTryingToConnect] = React.useState(false);
  const onMouseEnter = React.useCallback(() => {
    if (!isConnecting) return;

    setIsTryingToConnect(true);
  }, [isConnecting, setIsTryingToConnect]);

  const onMouseLeave = useCallback(() => {
    setIsTryingToConnect(false);
  }, [setIsTryingToConnect]);

  return {
    isTryingToConnect,
    onMouseEnter,
    onMouseLeave,
  };
};
