import * as assetBuilderActions from "./assetBuilder/assetBuilder.slice";
import * as authActions from "./auth/auth.slice";
import * as configurationActions from "./configuration/configuration.slice";
import * as dealerManagementActions from "./dealerManagement/dealerManagement.slice";
import * as designStudioActions from "./designStudio/designStudio.slice";
import * as legalLingoActions from "./legalLingo/legalLingo.slice";
import * as newOrdersActions from "./newOrders/newOrders.slice";
import * as oemManagementActions from "./oemManagement/oemManagement.slice";
import * as uploadManagementActions from "./uploadManagement/uploadManagement.slice";
import * as userManagementActions from "./userManagement/userManagement.slice";
import * as adLibraryActions from "./adLibrary/adLibrary.slice";
import * as shared from "./shared/shared.slice";
import * as settings from "./settings/settings.slice";

const actions = {
  auth: {
    ...authActions,
  },
  oemManagement: {
    ...oemManagementActions,
  },
  dealerManagement: {
    ...dealerManagementActions,
  },
  userManagement: {
    ...userManagementActions,
  },
  uploadManagement: {
    ...uploadManagementActions,
  },
  configuration: {
    ...configurationActions,
  },
  assetBuilder: {
    ...assetBuilderActions,
  },
  legalLingo: {
    ...legalLingoActions,
  },
  designStudio: {
    ...designStudioActions,
  },
  newOrders: {
    ...newOrdersActions,
  },
  adLibrary: {
    ...adLibraryActions,
  },
  shared: {
    ...shared,
  },
  settings: {
    ...settings,
  },
};

export default actions;
