import { Button, Collapse, Empty, message, Spin, Table } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

import actions from "../../../redux/rootActions";

import {
  FetchingDataStatus,
  IArtboard,
  IDesignStudioState,
  TabMenu,
} from "shared/types/designStudio";

import "./ArtboardList.scss";
import { formatDimensions } from "utils/helpers";

interface IArtboardList {
  artboards: IArtboard[];
  fetchingData: FetchingDataStatus;
  fetchData: (dataType: FetchingDataStatus, query?: string) => void;

  onArtboardEdit?: (artboard: IArtboard) => void;

  searchInput: string;
  selectedTab: TabMenu;

  selectedFilterDimensionsArtboard?: {
    width: number | null;
    height: number | null;
  };
}

const ArtboardList: React.FC<IArtboardList> = ({
  artboards,
  fetchData,
  fetchingData,

  onArtboardEdit,

  searchInput,
  selectedTab,
  selectedFilterDimensionsArtboard,
}) => {
  useEffect(() => {
    fetchData("artboards");

    // eslint-disable-next-line
  }, []);

  const [groupedArtboards, setGroupedArtboards] = useState<
    Record<
      string,
      Array<{ name: string; dimension: string; asset_type: string }>
    >
  >({});
  const [orderedArtboardAssetTypes, setOrderedArtboardAssetTypes] = useState<
    string[]
  >([]);
  useEffect(() => {
    const artboardSearch =
      selectedTab === "artboards" ? searchInput.trim() : "";

    const transformedArtboards = artboards
      .filter(artboard =>
        artboardSearch
          ? artboard.name.toLowerCase().includes(artboardSearch.toLowerCase())
          : true,
      )
      .filter(artboard => {
        const { width, height } = selectedFilterDimensionsArtboard || {
          width: null,
          height: null,
        };

        if (width && height) {
          return artboard.width === width && artboard.height === height;
        } else if (width) {
          return artboard.width === width;
        } else if (height) {
          return artboard.height === height;
        } else return true;
      })
      .map(artboard => {
        return {
          key: artboard.name,
          name: artboard.name,
          dimension: formatDimensions(artboard),
          asset_type: artboard.asset_type, // this is for grouping below
          action: {
            artboardName: artboard.name, // in order to pass data for action buttion column
          },
        };
      });

    const grouped = _.groupBy(
      transformedArtboards,
      artboard => artboard && artboard.asset_type,
    );
    setGroupedArtboards(grouped);

    const orderedAssetTypes = _.orderBy(
      Object.keys(grouped),
      assetType => assetType,
    );
    setOrderedArtboardAssetTypes(orderedAssetTypes);
  }, [artboards, searchInput, selectedTab, selectedFilterDimensionsArtboard]);

  return (
    <>
      {fetchingData === "artboards" && (
        <div className="loader">
          <Spin className="spinner" size="large" />
        </div>
      )}

      {fetchingData !== "artboards" &&
        orderedArtboardAssetTypes &&
        orderedArtboardAssetTypes.length === 0 && (
          <Empty className="empty" image={Empty.PRESENTED_IMAGE_DEFAULT} />
        )}

      {fetchingData !== "artboards" &&
        orderedArtboardAssetTypes &&
        orderedArtboardAssetTypes.length > 0 && (
          <Collapse className="collapse-container">
            {orderedArtboardAssetTypes.map(assetType => {
              const numberOfArtboards = groupedArtboards[assetType].length;

              return (
                <Collapse.Panel
                  key={assetType}
                  className="artboard-list-collapse-panel"
                  header={
                    <div className="artboard-list-collapse">
                      <span className="title">{assetType}</span>
                      <span className="number-counter">
                        {numberOfArtboards}
                      </span>
                    </div>
                  }
                >
                  <Table
                    dataSource={_.orderBy(groupedArtboards[assetType], [
                      "name",
                      "dimension",
                    ])}
                  >
                    <Table.Column
                      title="Name"
                      dataIndex="name"
                      key="name"
                      width="25%"
                    />
                    <Table.Column
                      title="Dimension"
                      dataIndex="dimension"
                      key="dimension"
                      width="25%"
                    />
                    <Table.Column
                      title="Action"
                      dataIndex="action"
                      key="action"
                      width="10%"
                      align="center"
                      render={data => (
                        <Button
                          className={`edit-button-for-${data.artboardName}`}
                          style={{ width: "10em" }}
                          type="primary"
                          onClick={() => {
                            const { artboardName } = data;
                            const artboard = artboards.find(
                              tmpArtboard => tmpArtboard.name === artboardName,
                            );
                            if (!artboard) {
                              message.error(
                                "Cannot proceed with selected artboard.",
                              );
                              return;
                            }
                            if (onArtboardEdit) {
                              onArtboardEdit(artboard);
                            }
                          }}
                        >
                          Edit
                        </Button>
                      )}
                    />
                  </Table>
                </Collapse.Panel>
              );
            })}
          </Collapse>
        )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { designStudio } = state;
  const {
    fetchingData,
    artboards,
    searchInput,
    selectedFilterDimensionsArtboard,
  } = designStudio as IDesignStudioState;

  return {
    fetchingData,
    artboards,

    searchInput,
    selectedFilterDimensionsArtboard,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchData: (dataType: FetchingDataStatus, query?: string) => {
      dispatch(actions.designStudio.fetchData(dataType, query));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArtboardList);
