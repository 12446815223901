import { Button, Col, Form, Input, Row } from "antd";
import { RuleObject } from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import { debounce } from "lodash";
import { FieldData } from "rc-field-form/lib/interface";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useCAMFooter } from "shared/components/media";
import { OperationMode } from "shared/types/inputValues";
import { IBrandRecord } from "shared/types/brandManagement";
import { cleanNameStringForComparison, isNameInputValid } from "./utils";

interface Props {
  drawerId: string;
  mode: OperationMode;
  entityName: string;
  oemName: string;
  isProcessing: boolean;
  oems: IBrandRecord[];
  onDelete?: () => void;
  onCancel: () => void;
  onSubmit?: (oemName: any) => void;
  onChange: (oemName: string) => void;
  submitText: string;
}

export const MediaOemForm = ({
  drawerId,
  mode,
  entityName,
  isProcessing,
  oems,
  oemName,
  onDelete,
  onCancel,
  onSubmit,
  onChange,
  submitText,
}: Props) => {
  const [form] = useForm();
  const [validForm, setValidForm] = useState<boolean>();
  const footer = useCAMFooter(drawerId);

  useEffect(() => {
    setValidForm(mode === "CREATE" ? false : true);
  }, [mode]);

  const validatorOemName = (_: RuleObject, value: string) => {
    if (mode === "UPDATE") {
      return Promise.resolve();
    } else {
      if (!value || value.length === 0) {
        return Promise.reject(new Error(`${entityName} is required`));
      }
      if (!isNameInputValid(value)) {
        return Promise.reject(
          new Error(
            `Invalid ${entityName}. Make sure there are no consecutive spaces.`,
          ),
        );
      }
      if (
        oems.some(
          o =>
            cleanNameStringForComparison(o.oemName) ===
            cleanNameStringForComparison(value),
        )
      ) {
        return Promise.reject(
          new Error(`There is an ${entityName} with the same name`),
        );
      }
      return Promise.resolve();
    }
  };

  const onFieldsChange = ([field]: FieldData[]) => {
    onChange(field.value);
    setValidForm(!form.getFieldsError().some(({ errors }) => errors.length));
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFieldsChange={debounce(onFieldsChange, 500)}
      onFinish={onSubmit}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="oemName"
            initialValue={oemName}
            rules={[
              {
                validator: validatorOemName,
              },
            ]}
            label={`Enter ${entityName}`}
          >
            <Input
              required={true}
              value={oemName}
              disabled={mode === "UPDATE"}
            />
          </Form.Item>
        </Col>
      </Row>
      {footer &&
        createPortal(
          <div style={{ display: "flex", justifyContent: "end", gap: "1em" }}>
            <Button onClick={onCancel} disabled={isProcessing}>
              Cancel
            </Button>
            {onDelete && (
              <Button
                danger
                type="primary"
                onClick={onDelete}
                disabled={isProcessing}
              >
                Delete
              </Button>
            )}
            {onSubmit && (
              <Button
                type="primary"
                onClick={form.submit}
                disabled={!validForm || isProcessing}
                loading={isProcessing}
              >
                {submitText}
              </Button>
            )}
          </div>,
          footer,
        )}
    </Form>
  );
};
