import { useColumn } from "../useColumn";

export const useColModifiedBy = () => {
  return useColumn("datemodified", {
    title: "Modified by",
    renderFn(text: string, record: WDAsset | WDFolder) {
      if (record.type === "asset") {
        return <span>{record.modified_by.name}</span>;
      }
    },
  });
};
