import { Select, Tooltip } from "antd";
import styles from "./AdLibrary.module.scss";
import { AdType, MatchColumnsTableData } from "../shared/types";
import { useFeedContext } from "../../shared/contexts/FeedContext";
import { getColumnMatches } from "./utils/getColumnMatches";
import { ITemplate } from "shared/types/designStudio";
import { useAdLibraryExportContext } from "./AdLibraryExportContext";
import { calculateTotalDuration } from "../../shared/utils";
import { TAssetBatch } from "shared/types/assetExporter";
import { hasHTMLTemplateCompositions } from "../../assetBatchDrawer/utils";

const isVideoAssetBatch = (assetBatch: TAssetBatch, templates: ITemplate[]) => {
  const duration = calculateTotalDuration(assetBatch.compositions);
  const isHTMLTemplate = hasHTMLTemplateCompositions(
    assetBatch.compositions,
    templates,
  );
  return duration > 0 && !isHTMLTemplate;
};

const isStillAssetBatch = (assetBatch: TAssetBatch, templates: ITemplate[]) => {
  const duration = calculateTotalDuration(assetBatch.compositions);
  const isHTMLTemplate = hasHTMLTemplateCompositions(
    assetBatch.compositions,
    templates,
  );
  return duration === 0 && !isHTMLTemplate;
};

export const AdTypeSelect = ({
  templates,
  tableData,
}: {
  templates: ITemplate[];
  tableData: MatchColumnsTableData[];
}) => {
  const { assetBatches, selectedAssetBatches, assetBatchesForSelectedRows } =
    useFeedContext();
  const { setColumnMatches, adType, setAdType } = useAdLibraryExportContext();

  const selectedAssetBatchesData = assetBatches.filter(
    assetBatch =>
      assetBatch.assetBatchId &&
      selectedAssetBatches.includes(assetBatch.assetBatchId),
  );

  const hasVideoComposition = selectedAssetBatchesData.some(assetBatch =>
    isVideoAssetBatch(assetBatch, templates),
  );

  const hasStillComposition = selectedAssetBatchesData.some(assetBatch =>
    isStillAssetBatch(assetBatch, templates),
  );

  const handleAdTypeSelect = (newAdType: AdType) => {
    const newColumnMatches = getColumnMatches({
      adType: newAdType,
      feedColumns: tableData,
      assetBatchesForSelectedRows,
    });
    setColumnMatches(previousColumnMatches => ({
      ...previousColumnMatches,
      ...newColumnMatches,
    }));
    setAdType(newAdType);
  };

  return (
    <div className={styles.adTypeInputWrapper}>
      <Select
        placeholder="Select"
        className={styles.adTypeInput}
        value={adType ?? undefined}
        onSelect={handleAdTypeSelect}
      >
        {Object.values(AdType).map(adTypeValue => {
          const disabledStillOption =
            adTypeValue === AdType.Still && hasVideoComposition;
          const disabledVideoOption =
            adTypeValue === AdType.Video && hasStillComposition;
          const disabled = disabledStillOption || disabledVideoOption;

          const getTooltipText = () => {
            if (!disabled) return "";
            if (disabledStillOption)
              return "A selected asset batch contains video compositions, which are not compatible with Still ads.";
            if (disabledVideoOption)
              return "A selected asset batch contains still compositions, which are not compatible with Video ads.";
          };

          return (
            <Select.Option
              disabled={disabled}
              value={adTypeValue}
              key={adTypeValue}
            >
              <Tooltip
                trigger="hover"
                placement="topLeft"
                title={getTooltipText()}
              >
                <div>{adTypeValue}</div>
              </Tooltip>
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};
