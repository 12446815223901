/* eslint-disable react/display-name */
import { SearchOutlined } from "@ant-design/icons";
import { Spin, message, Table } from "antd";
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from "antd/lib/table/interface";
import { memo, useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useUpsertAssetBatch } from "screens/assetExporter/feedConfigurationV2/shared/hooks/useUpsertAssetBatch";
import { FilterDropdownWithFilterSearch } from "shared/components/FilterDropdownWithFilterSearch";
import { SearchFilterDropdown } from "shared/components/SearchFilterDropdown";
import { UndoMessage } from "shared/components/UndoMessage";
import { useDataList } from "shared/components/dataList";
import {
  IDataListFilters,
  IDataListState,
  ITableColumn,
} from "shared/components/dataList/types";
import { DEFAULT_KEY } from "shared/components/dataList/useDataList";
import ToolbarTable, {
  ToolbarButton,
} from "shared/components/toolbarTable/ToolbarTable";
import {
  COLUMN_ASSET_BATCHES,
  videoCompositionEnabled,
} from "shared/constants/assetExporter";
import { IFilter } from "shared/hooks/assetExporter/useFeedConfigFetch";
import useResizableTable from "shared/hooks/useResizableTable";
import { useWindowSize } from "shared/hooks/useWindowSize";
import {
  ASSET_BATCH_SELECTION,
  FeedTblCol,
  FeedTblColWithRender,
  FeedTblRow,
  QUERY_KEY_ASSET_BATCHES,
  TAssetBatch,
  TAssetBatchRequest,
  TSortArgs,
} from "shared/types/assetExporter";
import { StringParam, useQueryParams } from "use-query-params";
import { AssetBatchDrawer } from "./AssetBatchDrawer";
import { AssetBatchDrawerV2 } from "./AssetBatchDrawerV2";
import { AssetBatchesPanel } from "./AssetBatchesPanel";
import { ColumnsFilter } from "./ColumnsFilter";
import { ExportDrawer } from "./exportDrawer/shared/ExportDrawer";
import styles from "./FeedList.module.scss";
import { FeedListDataTags } from "./FeedListDataTags";
import { FeedListTitle } from "./FeedListTitle";
import { AssetBatchesProvider } from "./shared/contexts/AssetBatchesContext";
import { AssetBatchesValueMappingProvider } from "./shared/contexts/AssetBatchesValueMappingContext";
import { useFeedContext } from "./shared/contexts/FeedContext";
import { VideoStitchingProvider } from "./shared/contexts/VideoStitchingContext";
import { MediaPreviewProvider } from "./shared/contexts/MediaPreviewContext";
import { AdLibraryExportProvider } from "./exportDrawer/adLibrary/AdLibraryExportContext";
import { OffPlatformExportProvider } from "./exportDrawer/offPlatform/OffPlatformExportContext";
import { PortalExportProvider } from "./exportDrawer/portal/PortalExportContext";

const PAGE_LIMIT = 30;

const getSize = (expand: boolean) => (expand ? "large" : "small");
// if it shows data with expanded column, table size need to be adjusted.
// if the table is expanded, it has 16px more for column header of height.
const getScrollSize = (expand: boolean, windowInnerHeight: number) =>
  expand ? windowInnerHeight - 261 : windowInnerHeight - 245;

const getColumns = (
  feedTableColumns: FeedTblColWithRender[],
  displayColumns: string[],
  filters: IDataListFilters,
  assetBatches: TAssetBatch[],
) => {
  return (
    feedTableColumns
      ?.filter(
        col =>
          typeof col.title !== "string" || displayColumns.includes(col?.title),
      )
      .filter(
        col =>
          col.key !== COLUMN_ASSET_BATCHES ||
          (col.key === COLUMN_ASSET_BATCHES && Boolean(assetBatches.length)),
      )
      .map(column => {
        const isAssetBatchColumn = column.key === COLUMN_ASSET_BATCHES;
        return {
          key: column.key,
          title: column.title,
          dataIndex: [column.dataIndex],
          fixed: column.fixed,
          render: column.render,
          width: isAssetBatchColumn ? 350 : 200,
          ...(column.key !== COLUMN_ASSET_BATCHES &&
            column.key !== "order_number" && {
              filterDropdown: SearchFilterDropdown,
              filterIcon: filtered => (
                <SearchOutlined
                  style={{ color: filtered ? "#1890ff" : undefined }}
                />
              ),
              sorter: true,
              filteredValue: filters[column.key] || null,
            }),

          ...(column.key === COLUMN_ASSET_BATCHES && {
            filterDropdown: FilterDropdownWithFilterSearch,
            filters: assetBatches.map(({ assetBatchId, name }) => ({
              text: name,
              value: assetBatchId,
            })),
            filteredValue: filters[column.key] || null,
          }),
        } as ITableColumn<FeedTblRow>;
      }) ?? []
  );
};

const getTotalColumns = (feedTableColumns: FeedTblCol[]) =>
  feedTableColumns
    ?.filter(col => typeof col.title === "string" && col.title.length)
    .map(col => col.title as string) ?? [];

const getSortingOrder = (dataList: IDataListState<FeedTblRow>) => {
  return dataList.sort.columnKey
    ? [
        dataList.sort.columnKey as string,
        dataList.sort.order?.replace("end", "") as string,
      ]
    : undefined;
};

type Props = {
  feedId: string;
  feedTableColumns: FeedTblCol[];
  searchValue: string;
  totalCount: number;
  totalFilteredRows: FeedTblRow[];
  filteredTotalCount: number;
  filterArgs?: IFilter[];
  setSearchValue: (val: string) => void;
  setFilterArgs: (filters?: IFilter[]) => void;
  setSortArgs: (sortArgs?: TSortArgs) => void;
};

const FeedListContainer = (props: Props) => {
  const {
    feedTableColumns,
    searchValue,
    filterArgs,
    totalCount,
    totalFilteredRows,
    filteredTotalCount,
    setSearchValue,
    setFilterArgs,
    setSortArgs,
  } = props;
  const { windowInnerHeight } = useWindowSize();
  const [openExportDrawer, setExportDrawer] = useState(false);
  const [expand, setExpand] = useState(false);
  const [showDisplayColumnModal, setShowDisplayColumnModal] = useState(false);
  const totalColumns = getTotalColumns(feedTableColumns);
  const [displayColumns, setDisplayColumns] = useState(totalColumns);
  const [showSelectAllOption, setShowSelectAllOption] = useState(false);
  const [filteredRows, setFilteredRows] = useState<FeedTblRow[]>([]);
  const [dataList, dataListActions] = useDataList<FeedTblRow>();
  const {
    assetBatches,
    isFetching,
    feedId,
    onSelectAssetBatches,
    onSelectAllAssetBatches,
    selectedAssetBatches,
    updateExportOption,
    setAssetBatchesForSelectedRows,
    setAssetBatchDrawerMode,
    setOpenAssetBatchDrawer,
    editingAssetBatch,
    setEditingAssetBatch,
    setIsUpdated,
  } = useFeedContext();
  const [queryParams, setQueryParams] = useQueryParams({
    feedName: StringParam,
    feedId: StringParam,
    assetId: StringParam,
  });
  const { mutate: upsertAssetBatch, isLoading: isCreatingAssetBatch } =
    useUpsertAssetBatch();
  const setupExportView = useCallback(() => {
    const exportOption =
      (dataList.filters[DEFAULT_KEY]?.assetBatches ?? []).length === 0
        ? ASSET_BATCH_SELECTION.EXPORT_ALL
        : ASSET_BATCH_SELECTION.EXPORT_SOME;
    updateExportOption(exportOption);
    if (exportOption === ASSET_BATCH_SELECTION.EXPORT_SOME) {
      onSelectAssetBatches([...dataList.filters[DEFAULT_KEY].assetBatches]);
      return;
    }
    onSelectAllAssetBatches();
  }, [
    dataList.filters,
    onSelectAllAssetBatches,
    onSelectAssetBatches,
    updateExportOption,
  ]);

  const onExportClick = useCallback(() => {
    setupExportView();
    setExportDrawer(true);
  }, [setExportDrawer, setupExportView]);

  const selectedRowsAssetBatches = dataList.selectedItems
    .filter(row => row.assetBatches)
    .flatMap(row => row.assetBatches);

  const toolbarContents: ToolbarButton = {
    New: {
      disabled: false,
      extraInfo: { text: "Create asset batch" },
      onClick: () => {
        setAssetBatchDrawerMode("Create");
        setOpenAssetBatchDrawer(true);
      },
    },
    Export: {
      disabled:
        dataList.selectedItems.length === 0 ||
        selectedRowsAssetBatches.length === 0,
      display: true,
      extraInfo: { tooltipText: "Export created asset batches" },
      onClick: onExportClick,
    },
    Column: {
      onClick: () => setShowDisplayColumnModal(!showDisplayColumnModal),
      extraInfo: { iconOnly: true },
    },
    Expand: {
      onClick: () => setExpand(!expand),
    },
  };

  const columns: ITableColumn<FeedTblRow>[] = getColumns(
    feedTableColumns,
    displayColumns,
    dataList.filters[DEFAULT_KEY] ?? {},
    assetBatches,
  );

  const { components, resizableColumns = [] } = useResizableTable<FeedTblRow>(
    columns.map(column => ({
      ...column,
      name: column.title,
    })),
  );

  const filteredColumns = resizableColumns.sort(
    (a, b) =>
      displayColumns.indexOf(a.title as string) -
      displayColumns.indexOf(b.title as string),
  );

  const onChange = (
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<FeedTblRow> | SorterResult<FeedTblRow>[],
    extra: TableCurrentDataSource<FeedTblRow>,
  ) => {
    if (extra.action === "filter") {
      dataListActions.setFilters(_filters);

      setShowSelectAllOption(false);
      dataListActions.setSelectedItems([]);
      const _filterArgs = Object.keys(_filters)
        .filter(key => _filters[key])
        .map(key => {
          if (_filters[key]) {
            return {
              column: key,
              selection: Array.isArray(_filters[key])
                ? _filters[key]
                : [String(_filters[key])],
            };
          }
        }) as IFilter[];

      setFilterArgs(_filterArgs);

      const assetBatchFilters = _filterArgs
        .filter(({ column }) => column === "assetBatches")
        ?.flatMap(({ selection }) => selection);
      onSelectAssetBatches([...assetBatchFilters]);

      return;
    }

    if (extra.action == "sort" && !Array.isArray(sorter)) {
      if (!sorter.column) {
        setSortArgs(undefined);
        dataListActions.sortItems(undefined, undefined);
        return;
      }
      const sortArgs: TSortArgs = {
        column: sorter.columnKey as string,
        direction: sorter.order === "ascend" ? "asc" : "desc",
      };

      dataListActions.sortItems(sorter.columnKey, sorter.order);
      setSortArgs(sortArgs);
    }
  };

  const handleSubmit = (assetBatch: TAssetBatchRequest) => {
    const fixedAssetBatch: TAssetBatchRequest = videoCompositionEnabled
      ? assetBatch
      : {
          ...assetBatch,
          compositions: assetBatch.compositions?.map(composition => ({
            ...composition,
            duration: 0,
          })),
        };
    upsertAssetBatch(fixedAssetBatch);
    setOpenAssetBatchDrawer(false);
    setEditingAssetBatch(undefined);
  };

  const handleSelectAssetBatch = ({ assetBatchId }: TAssetBatch) => {
    if (!assetBatchId) return;

    dataListActions.resetFilters();
    const selection = [assetBatchId];

    setFilterArgs([
      {
        column: "assetBatches",
        selection,
      },
    ]);

    setShowSelectAllOption(false);
    dataListActions.setSelectedItems([]);
    dataListActions.setFilters({
      assetBatches: selection,
    });

    onSelectAssetBatches([assetBatchId]);
  };

  const resetFilterAssetBatch = () => {
    setShowSelectAllOption(false);
    dataListActions.setSelectedItems([]);
    dataListActions.resetFilters();
    setFilterArgs([]);
    dataListActions.setFilters({});
    onSelectAllAssetBatches();
  };

  const rowSelectionOnChange = (selectedRowKeys: React.Key[]) => {
    dataListActions.setSelectedItems(selectedRowKeys);
    const filteredSelectedRows = totalFilteredRows.filter(row =>
      selectedRowKeys.includes(row.rowIdentifier),
    );
    const assetBatchIds = filteredSelectedRows.flatMap(row => row.assetBatches);
    setAssetBatchesForSelectedRows(
      assetBatches.filter(
        ({ assetBatchId }) =>
          assetBatchId && assetBatchIds.includes(assetBatchId),
      ),
    );

    const isAllSelected = selectedRowKeys.length === dataList.rows.length;
    const overPageLimit = totalFilteredRows.length > PAGE_LIMIT;

    if (isAllSelected && overPageLimit) {
      setShowSelectAllOption(true);
      return;
    }
    setShowSelectAllOption(false);
  };

  const queryClient = useQueryClient();

  const undoMessage = (assetBatch: TAssetBatch, batchName: string) => {
    const { assetBatchId } = assetBatch;
    const messageKey = `editAssetBatch-${feedId}-${assetBatchId}`;

    message.success({
      className: styles.undoMessage,
      key: messageKey,
      content: (
        <UndoMessage
          message={`Configuration updates to ${batchName} has been saved.`}
          onClick={() => {
            const queryKey = [QUERY_KEY_ASSET_BATCHES, feedId];
            queryClient.cancelQueries(queryKey);
            upsertAssetBatch(assetBatch, {
              onSuccess: () => {
                message.destroy(messageKey);
                restoredMessage(messageKey);
              },
            });
          }}
          onClose={() => {
            message.destroy(messageKey);
          }}
        />
      ),
      duration: 20,
      size: "small",
    });
  };

  const restoredMessage = (messageKey: string) => {
    message.destroy(messageKey);
    message.success({
      className: styles.undoMessage,
      key: messageKey,
      content: "Asset Batch Restored.",
      duration: 2,
    });
  };

  const onClose = (showUndoMessage: boolean, batchName: string) => {
    const { assetId: _, ...restParams } = queryParams;
    setQueryParams(restParams, "replace");
    setOpenAssetBatchDrawer(false);

    if (!showUndoMessage) {
      setIsUpdated(false);
      setEditingAssetBatch(undefined);
    }

    if (showUndoMessage && editingAssetBatch) {
      setIsUpdated(true);
      setTimeout(() => undoMessage(editingAssetBatch, batchName), 2500);
    }
  };

  return (
    <Spin spinning={dataList.isLoading || isCreatingAssetBatch || isFetching}>
      <div className={styles.container}>
        <ToolbarTable
          searchPlaceholder="Search"
          titleTooltip={""}
          toolbarContents={toolbarContents}
          searchValue={searchValue}
          sortingOrder={getSortingOrder(dataList)}
          onSearch={val => setSearchValue(val)}
          onSortChange={([columnKey, order]) => {
            dataListActions.sortItems(columnKey, order);
          }}
          layout={"table"}
        />
        <FeedListDataTags
          setFilterArgs={setFilterArgs}
          totalCount={totalCount}
          filteredTotalCount={filteredTotalCount}
          setShowSelectAllOption={setShowSelectAllOption}
          onRemove={(key, value) => {
            if (key === "assetBatches")
              onSelectAssetBatches(
                selectedAssetBatches.filter(id => id !== value),
              );
          }}
        />
        <AssetBatchesProvider
          columns={feedTableColumns.map(column => column.dataIndex) ?? []}
          rows={[...filteredRows]}
          feedId={feedId}
        >
          <MediaPreviewProvider>
            <AssetBatchesValueMappingProvider>
              <VideoStitchingProvider>
                <div className={styles.tableContainer}>
                  <AssetBatchesPanel
                    feedId={feedId}
                    onClick={handleSelectAssetBatch}
                    onReset={resetFilterAssetBatch}
                    setOpenAssetBatchDrawer={setOpenAssetBatchDrawer}
                    setAssetBatchDrawerMode={setAssetBatchDrawerMode}
                  />
                  <div
                    className={styles.table}
                    style={{
                      maxHeight:
                        windowInnerHeight - (showSelectAllOption ? 310 : 205),
                    }}
                  >
                    <Table<FeedTblRow>
                      title={
                        showSelectAllOption
                          ? () => (
                              <FeedListTitle
                                feedId={feedId}
                                totalFilteredRows={totalFilteredRows}
                                setShowSelectAllOption={setShowSelectAllOption}
                              />
                            )
                          : undefined
                      }
                      key={"tableKey" + filterArgs?.toString()}
                      rowSelection={{
                        selectedRowKeys: dataList.selectedIds,
                        onChange: rowSelectionOnChange,
                      }}
                      scroll={{ y: getScrollSize(expand, windowInnerHeight) }}
                      size={getSize(expand)}
                      rowKey={record => record.rowIdentifier}
                      columns={filteredColumns}
                      components={components}
                      dataSource={dataList.rows}
                      onChange={onChange}
                      className={styles.virtualTable}
                      pagination={false}
                    />
                  </div>
                </div>
                <ColumnsFilter
                  showDisplayColumnModal={showDisplayColumnModal}
                  displayColumns={displayColumns}
                  totalColumns={totalColumns}
                  setDisplayColumns={setDisplayColumns}
                  setShowDisplayColumnModal={setShowDisplayColumnModal}
                />
                {!videoCompositionEnabled ? (
                  <AssetBatchDrawer
                    onClose={onClose}
                    onSubmit={handleSubmit}
                    setFilteredRows={setFilteredRows}
                  />
                ) : (
                  <AssetBatchDrawerV2
                    onClose={onClose}
                    onSubmit={handleSubmit}
                    setFilteredRows={setFilteredRows}
                  />
                )}
              </VideoStitchingProvider>
            </AssetBatchesValueMappingProvider>
          </MediaPreviewProvider>
        </AssetBatchesProvider>
        <AdLibraryExportProvider>
          <OffPlatformExportProvider>
            <PortalExportProvider>
              <ExportDrawer
                open={openExportDrawer}
                onClose={() => {
                  setExportDrawer(false);
                }}
              />
            </PortalExportProvider>
          </OffPlatformExportProvider>
        </AdLibraryExportProvider>
      </div>
    </Spin>
  );
};

export const FeedList = memo(FeedListContainer);
export default FeedListContainer;
