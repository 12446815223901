import { Button, Tabs, Typography } from "antd";
import { memo, ReactElement, useState } from "react";
import { IAd } from "shared/types/adLibrary";
import AdPreviewFrame from "../AdPreviewFrame";
import { AdFormat, AdType } from "../facebookUtils/types";
import { useFetchInstantExperiences } from "shared/hooks/adLibrary/useFetchInstantExperiences";
import { getFirstElementForAdWithIE } from "../adLoad/adLoadDrawer/loadStep/helpers.ie";
import LivePreviewHandler from "shared/components/LivePreviewHandler";
import { isFeatureEnabled } from "utils/helpers";
import AdLibraryCustomAdPreview from "./AdPreview/AdLibraryCustomAdPreview";

const { TabPane } = Tabs;

interface IProps {
  ad?: IAd | null;
  adDraft: IAd;
  validateAndCall: (
    message: string,
    callback: (adUpdate: IAd) => void,
    skipValidations?: string[],
  ) => Promise<void>;
  previewDisabled: boolean;
  setPreviewDisabled: (previewDisabled: boolean) => void;
}

const isCustomPreviewEnabled = isFeatureEnabled(
  "ENABLE_AD_LIBRARY_CUSTOM_PREVIEW",
);

function AdPreview({
  ad,
  adDraft,
  validateAndCall,
  previewDisabled,
  setPreviewDisabled,
}: IProps): ReactElement {
  const [displayLayout, setDisplayLayout] = useState<AdFormat | "layout">(
    isCustomPreviewEnabled ? "layout" : AdFormat.MOBILE_FEED_STANDARD,
  );
  const [updatePreview, setUpdatePreview] = useState<boolean>(false);

  const [
    selectedInstantExperienceIdsPath,
    setSelectedInstantExperienceIdsPath,
  ] = useState<string[]>([]);

  const { data: instantExperiences } = useFetchInstantExperiences();

  const isFbDisplay = Object.keys(AdFormat).includes(displayLayout);

  return (
    <div className="form-column">
      <div className="column-title">
        <Typography.Text>{"Preview"}</Typography.Text>
        {Object.keys(AdFormat).includes(displayLayout) && (
          <Button
            disabled={previewDisabled}
            onClick={() =>
              validateAndCall(
                "There is an error in your ad. Please fix all errors before updating the preview.",
                () => {
                  setUpdatePreview(!updatePreview);
                  setSelectedInstantExperienceIdsPath([]);
                  setPreviewDisabled(true);
                },
                ["postCopy", "headline", "ctaButtonText", "media"],
              )
            }
          >
            Update Preview
          </Button>
        )}
      </div>
      <Tabs
        activeKey={displayLayout}
        onChange={value => setDisplayLayout(value as AdFormat)}
        size="middle"
      >
        {isCustomPreviewEnabled && <TabPane tab="Layout" key="layout" />}
        <TabPane tab="Mobile" key={AdFormat.MOBILE_FEED_STANDARD} />
        <TabPane tab="Desktop" key={AdFormat.DESKTOP_FEED_STANDARD} />
        <TabPane tab="Instagram" key={AdFormat.INSTAGRAM_STANDARD} />
        <TabPane tab="Story" key={AdFormat.INSTAGRAM_STORY} />
      </Tabs>
      {ad && isFbDisplay && ad?.visuals && (
        <div>
          <LivePreviewHandler
            adType={ad?.type as AdType}
            selectedInstantExperienceIdsPath={selectedInstantExperienceIdsPath}
            setSelectedInstantExperienceIdsPath={
              setSelectedInstantExperienceIdsPath
            }
            instantExperienceId={
              ad?.visuals.destination?.instantExperienceId ?? ""
            }
            instantExperiences={instantExperiences}
            getRootElementForCollectionAd={() => getFirstElementForAdWithIE(ad)}
          >
            <AdPreviewFrame
              ad={ad}
              adFormat={displayLayout as AdFormat}
              updatePreview={updatePreview}
            />
          </LivePreviewHandler>
        </div>
      )}
      {isCustomPreviewEnabled && !isFbDisplay && (
        <AdLibraryCustomAdPreview
          adVisuals={adDraft.visuals}
          adType={adDraft.type}
        />
      )}
    </div>
  );
}

export default memo(AdPreview);
