import { useCallback } from "react";
import {
  Element,
  useEverythingAdsContext,
} from "screens/everythingAds.hooks/useContextAPI";
import ListContainer from "../shared/ListContainer";
import ListItem from "../shared/ListItem";
import * as utils from "../../../utils";
import { useFetchAds } from "shared/hooks/adLibrary/useFetchAds";
import { AdType } from "screens/adLibrary/facebookUtils/types";
import { useMemo } from "react";

const MarketingTileList = () => {
  // NOTE: we need to filter out "Still" ads because it cannot have instant exp link.
  const { data: ads = [], isLoading } = useFetchAds();
  const filteredAds = useMemo(
    () =>
      ads.filter(ad => {
        const isValidAd =
          ad.type === AdType.InstantExperience || ad.type === AdType.Collection;

        return isValidAd;
      }),
    [ads],
  );
  const { searchBy } = useEverythingAdsContext() || {};

  const onDragStart = useCallback(
    (element: Element) => (e: React.DragEvent<HTMLDivElement>) =>
      utils.onDragStart(e, element),
    [],
  );
  return (
    <ListContainer isLoading={isLoading}>
      {filteredAds
        ?.filter(ad =>
          searchBy
            ? ad.inputParameters?.name
                ?.toLowerCase()
                .includes(searchBy.toLowerCase())
            : true,
        )
        .map(ad => {
          return (
            <ListItem
              key={`ad-list-item-${ad.id}`}
              element={ad}
              onDragStart={onDragStart}
            />
          );
        })}
    </ListContainer>
  );
};

export default MarketingTileList;
