export const getSelectedPortalColumns = (
  currentSelectedColumns: string[],
  columnTitle: string,
  isSelected: boolean,
): string[] => {
  if (isSelected) {
    return [...currentSelectedColumns, columnTitle];
  } else {
    return currentSelectedColumns.filter(column => column !== columnTitle);
  }
};
