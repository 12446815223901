import { Collapse } from "antd";

import PropertyRow from "./propertySectionV2/PropertyRow";

import { HeaderMenu, TabMenu } from "shared/types/designStudio";
import "../../../shared/styles/Collapse.scss";
import "./PropertySection.scss";
import { FC } from "react";

interface IPropertySection {
  selectedHeader: HeaderMenu;
  selectedTab: TabMenu;
}

const PropertySection: FC<IPropertySection> = () => {
  return (
    <Collapse
      className="collapse-container"
      expandIconPosition="right"
      expandIcon={() => null}
      activeKey="property-panel"
    >
      <Collapse.Panel key="property-panel" header="--">
        <PropertyRow />
      </Collapse.Panel>
    </Collapse>
  );
};

export default PropertySection;
