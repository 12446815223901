import { Form, Input } from "antd";
import { createElement, useEffect, useRef } from "react";
import {
  useUpdateAssetPropsMutation,
  useUpdateFolderPropsMutation,
} from "redux/media/media.api";
import { useMediaActions } from "redux/media/media.slice";
import { handleApiError } from "../handleApiError";
import { useAppSelector } from "../redux";

type Props = {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof WDResource;
  record: WDResource;
  element?: string;
};

export const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  element,
  ...restProps
}: Props) => {
  const [form] = Form.useForm();
  const [updateAssetProps] = useUpdateAssetPropsMutation();
  const [updateFolderProps] = useUpdateFolderPropsMutation();
  const inputRef = useRef<Input>(null);
  const { setEditableRow } = useMediaActions();
  const editableRow = useAppSelector(state => state.media.editableRow);

  useEffect(() => {
    if (editable && record?.id === editableRow?.id) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
    }
  }, [record, editableRow, editable]);

  const save = async () => {
    try {
      if (record[dataIndex] === form.getFieldValue(dataIndex)) {
        return;
      }
      const values = await form.validateFields();
      switch (record.type) {
        case "folder": {
          const updatedValue = await updateFolderProps({
            props: {
              id: record.id,
              ...values,
            },
          }).unwrap();
          form.setFieldsValue({ [dataIndex]: updatedValue[dataIndex] });
          break;
        }
        case "asset": {
          const updatedValue = await updateAssetProps({
            props: [
              {
                id: record.id,
                ...values,
              },
            ],
          }).unwrap();
          form.setFieldsValue({ [dataIndex]: updatedValue[0][dataIndex] });
        }
      }
    } catch (err) {
      form.resetFields();
      handleApiError(err);
    } finally {
      setEditableRow(undefined);
    }
  };

  let childNode = children;

  if (editable) {
    childNode =
      editableRow?.id === record?.id ? (
        <Form
          form={form}
          autoComplete="off"
          initialValues={{ [dataIndex]: record[dataIndex] }}
        >
          <Form.Item
            style={{ margin: 0 }}
            name={dataIndex}
            rules={[
              {
                required: true,
                message: `${title} is required.`,
              },
            ]}
          >
            <Input
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              onKeyDown={e => {
                if (e.key === "Escape") {
                  setEditableRow(undefined);
                  form.resetFields();
                  e.stopPropagation();
                }
              }}
            />
          </Form.Item>
        </Form>
      ) : (
        <div style={{ overflow: "hidden" }}>{children}</div>
      );
  }

  return createElement(
    element || "td",
    // TODO: remove after checking overflow is not required
    // { ...restProps, style: { overflow: "hidden" } },
    restProps,
    childNode,
  );
};
