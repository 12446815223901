import { config } from "../config";

const params = new URLSearchParams({
  response_type: "code",
  client_id: config.wdClientId,
  redirect_uri: `${location.origin}/media-callback`,
});

export const getLoginUrl = () => {
  params.set("state", location.pathname + location.search);

  return `${config.wdBaseUrl}/oauth2/authorize?${params}`;
};
