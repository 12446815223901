import { message } from "antd";
import { useMemo } from "react";
import { UseQueryOptions } from "react-query";
import { FeedCron } from "shared/types/assetExporter";
import { useAppShallowEqualSelector } from "../useAppSelector";
import { useGetFormattedFeeds } from "./useGetFormattedFeeds";

export function useFilteredFeeds() {
  const { selectedFeedIds, showSelected, search } = useAppShallowEqualSelector(
    state => ({
      selectedFeedIds: state.assetExporter.sourceFeed.selectedFeedIds,
      showSelected: state.assetExporter.sourceFeed.showSelected,
      search: state.assetExporter.sourceFeed.search,
    }),
  );

  const fetchCallbacks: UseQueryOptions<FeedCron[], Error> = {
    onError: () => {
      message.error("Unable to fetch feeds");
    },
  };

  const { feeds, isLoading } = useGetFormattedFeeds(fetchCallbacks);

  const dataSource = useMemo(() => {
    const data = showSelected
      ? feeds.filter(({ feedId }) => selectedFeedIds.includes(feedId))
      : feeds;

    if (!search) {
      return data;
    }

    return data.filter(({ feedName }) => {
      const lcName = feedName.toLowerCase();
      return lcName.includes(search.toLowerCase());
    });
  }, [feeds, search, selectedFeedIds, showSelected]);

  return { dataSource, originalDataSource: feeds, isLoading };
}
