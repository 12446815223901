import { ReactNode, createContext, useContext } from "react";

type MediaContext = {
  fileTypes?: string[];
  isDrawer?: boolean;
};

const MediaContext = createContext({} as MediaContext);

interface Props extends MediaContext {
  children: ReactNode;
}

export const MediaProvider = ({
  children,
  fileTypes,
  isDrawer = false,
}: Props) => {
  return (
    <MediaContext.Provider value={{ fileTypes, isDrawer }}>
      {children}
    </MediaContext.Provider>
  );
};

export const useMediaContext = () => useContext(MediaContext);
