import { TableColumnProps } from "antd";
import { isFunction } from "lodash";
import { HTMLAttributes } from "react";
import EditableCell, { EditableCellType, SelectOption } from "./EditableCell";

export interface EditableColumnParameters<T> extends TableColumnProps<T> {
  editable?: boolean | ((record: T) => boolean);
  getClassNameOnError?: (record: T) => string;
  editableCellType?: EditableCellType;
  selectOptions?: SelectOption[];
  itemRef?: (r: T) => React.RefObject<HTMLElement>;
}

interface Props<
  RecordType,
  GenericColumn extends EditableColumnParameters<RecordType> = EditableColumnParameters<RecordType>,
> {
  columns: GenericColumn[] | undefined;
  handleSave: (row: RecordType) => void;
}

const setupEditableCells = <
  RecordType,
  GenericColumn extends EditableColumnParameters<RecordType> = EditableColumnParameters<RecordType>,
>({
  columns,
  handleSave,
}: Props<RecordType, GenericColumn>) => {
  const processedColumns = columns?.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: RecordType) =>
        ({
          record,
          editable: isFunction(col.editable)
            ? col.editable(record)
            : col.editable,
          editableCellType: col.editableCellType,
          dataIndex: col.dataIndex,
          selectOptions: col.selectOptions,
          getClassNameOnError: col.getClassNameOnError,
          handleSave,
        } as HTMLAttributes<HTMLElement>),
    };
  });

  return {
    components: {
      body: {
        cell: EditableCell,
      },
    },
    columns: processedColumns,
  };
};

export { setupEditableCells };
