import { TablePaginationConfig } from "antd";

type PageConfig = {
  offset: number;
  limit: number;
  total_count: number;
};

export const usePagination = (
  setPage: (page: number) => void,
  config?: PageConfig,
): TablePaginationConfig | false => {
  if (config) {
    return {
      size: "small",
      hideOnSinglePage: true,
      showSizeChanger: false,
      current: (config.offset ?? 0) + 1,
      total: config.total_count,
      showTotal: (total, [a, b]) => `${a}-${b} of ${total} items`,
      pageSize: config.limit,
      position: ["bottomCenter"],
      onChange(page) {
        setPage(page - 1);
      },
    };
  }

  return false;
};
