import { memo, useState } from "react";
import { Image, Spin } from "antd";

import Empty from "shared/components/Empty";
import AlexiaSpin from "shared/components/Spin";

import placeholder from "statics/images/missing-thumbnail.png";

import { getThumbnailFromCreative } from "./utils";

import { AdPreviewProps } from "./types";
import { AdType } from "screens/adLibrary/facebookUtils/types";

import cardStyles from "shared/components/Card.module.scss";
import fbCardStyles from "../FacebookAdCard.module.scss";

import classNames from "classnames";

Spin.setDefaultIndicator(<AlexiaSpin />);

const AdPreview = ({
  adType,
  dimensions,
  facebookAd,
  enableScroll,
  preloadedIframe: iframe,
  isLoadingDimensionsFirstTime,
}: AdPreviewProps) => {
  const [isLoadingFrame, setIsLoadingFrame] = useState(true);

  const imageSrc = getThumbnailFromCreative(facebookAd);
  const iframeSrc = iframe?.match(/src="(.*?)"/)?.[1].replace(/&amp;/g, "&");

  if (!iframeSrc && imageSrc) {
    return (
      <Image
        alt="thumbnail"
        preview={false}
        height={dimensions?.height}
        fallback={placeholder}
        src={imageSrc}
        className={cardStyles.noPadding}
      />
    );
  }

  return (
    <div
      data-cy="ad-preview"
      className={classNames({ [fbCardStyles.emptyPreview]: !iframe })}
    >
      {iframeSrc && (
        <Spin
          spinning={isLoadingDimensionsFirstTime || isLoadingFrame}
          size="large"
        >
          <iframe
            src={iframeSrc}
            width={dimensions?.width}
            height={dimensions?.height}
            scrolling={enableScroll ? "yes" : "no"}
            frameBorder="0"
            title={facebookAd.id}
            onLoad={() => setIsLoadingFrame(false)}
          ></iframe>
        </Spin>
      )}
      {!iframeSrc && <Empty message={"No preview available."} />}
    </div>
  );
};

export default memo(AdPreview);
