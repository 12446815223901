import { IVideoElement } from "shared/types/adLibrary";

type Props = {
  ele: IVideoElement;
};
const FlowVideo = (props: Props) => {
  return (
    <video width="100%" autoPlay loop>
      <source src={props.ele.url} type="video/mp4" />
      Your browser does not support HTML video.
    </video>
  );
};

export default FlowVideo;
