import { Modal, Typography } from "antd";
import { useMemo, useReducer } from "react";
import useGetGoogleCampaigns from "screens/adLibrary/shared/hooks/useGetGoogleCampaigns";
import {
  DataNodeKeyType,
  getCampaignKey,
} from "screens/adLibrary/shared/hooks/usePmaxTreeDataNodes";
import {
  GoogleAdAccount,
  GoogleAdCampaign,
  IAssetGroup,
} from "shared/types/adLibrary";
import produce from "immer";

const removeAccountPrompt = (dealerName: string, onOk: () => void) =>
  Modal.confirm({
    okText: "Yes",
    cancelText: "No",
    title: "Deselect Client",
    onOk,
    content: (
      <Typography.Paragraph style={{ marginBottom: 0 }}>
        Selected destination data for <b>{dealerName}</b> will be removed. Would
        you like to continue?
      </Typography.Paragraph>
    ),
  });

export type CreateAssetGroupSelectionHandlers = {
  selection: CreateAssetGroupSelection[];
  computedSelectedKeys: string[];
  isFetchingGoogleCampaigns: boolean;
  addAccount: (selection: CreateAssetGroupSelection) => void;
  removeAccount: (dealerName: string) => void;
  setAssetGroups: (payload: SetAssetGroupsPayload) => void;
  removeAssetGroups: (payload: RemoveAssetGroupsPayload) => void;
  updateAssetGroupFinalUrl: (payload: UpdateAssetGroupFinalUrlPayload) => void;
  resetSelection: () => void;
};

export type CreateAssetGroupSelection = {
  dealerName: string;
  adAccount: GoogleAdAccount;
};

type SetAssetGroupsPayload = {
  type: DataNodeKeyType;
  id: string | undefined;
  assetGroups: IAssetGroup[] | undefined;
};

type RemoveAssetGroupsPayload = {
  type: DataNodeKeyType;
  id: string | undefined;
};

type UpdateAssetGroupFinalUrlPayload = {
  assetGroupId: string;
  finalUrl: string;
};

type CreateAssetGroupSelectionAction =
  | { type: "ADD"; payload: CreateAssetGroupSelection }
  | { type: "REMOVE"; payload: string }
  | {
      type: "SET_CAMPAIGNS";
      payload: { dealerName: string; campaigns: GoogleAdCampaign[] };
    }
  | {
      type: "SET_ASSET_GROUPS";
      payload: SetAssetGroupsPayload;
    }
  | {
      type: "REMOVE_ALL_ASSET_GROUPS";
      payload: RemoveAssetGroupsPayload;
    }
  | {
      type: "UPDATE_ASSET_GROUP_FINAL_URL";
      payload: UpdateAssetGroupFinalUrlPayload;
    }
  | { type: "RESET" };

const reducer = produce(
  (
    draft: CreateAssetGroupSelection[],
    action: CreateAssetGroupSelectionAction,
  ): CreateAssetGroupSelection[] => {
    switch (action.type) {
      case "ADD":
        draft.push(action.payload);
        break;
      case "REMOVE":
        draft.splice(
          draft.findIndex(selection => selection.dealerName === action.payload),
          1,
        );
        break;
      case "SET_CAMPAIGNS":
        draft.forEach(selection => {
          if (selection.dealerName === action.payload.dealerName) {
            selection.adAccount.campaigns = action.payload.campaigns;
          }
        });
        break;
      case "SET_ASSET_GROUPS":
        draft.forEach(selection => {
          selection.adAccount?.campaigns?.forEach(campaign => {
            if (
              action.payload.type === "ad-account" &&
              action.payload.id === selection.adAccount?.id
            ) {
              campaign.assetGroups = action.payload.assetGroups;
            }

            const selectedSpecificCampaign =
              action.payload.type === "campaign" &&
              campaign.id === action.payload.id;
            if (selectedSpecificCampaign) {
              campaign.assetGroups = action.payload.assetGroups;
            }
          });
        });
        break;
      case "REMOVE_ALL_ASSET_GROUPS":
        draft.forEach(selection => {
          selection.adAccount?.campaigns?.forEach(campaign => {
            if (
              action.payload.type === "ad-account" &&
              action.payload.id === selection.adAccount?.id
            ) {
              campaign.assetGroups = undefined;
            }

            const selectedSpecificCampaign =
              action.payload.type === "campaign" &&
              campaign.id === action.payload.id;
            if (selectedSpecificCampaign) {
              campaign.assetGroups = undefined;
            }
          });
        });
        break;
      case "UPDATE_ASSET_GROUP_FINAL_URL":
        draft.forEach(selection => {
          selection.adAccount?.campaigns?.forEach(campaign => {
            campaign.assetGroups?.forEach(assetGroup => {
              if (assetGroup.id === action.payload.assetGroupId) {
                assetGroup.finalUrl = action.payload.finalUrl;
              }
            });
          });
        });
        break;
      case "RESET":
        draft = [];
        break;
      default:
        break;
    }

    return draft;
  },
);

const useCreateNewAssetGroupSelector =
  (): CreateAssetGroupSelectionHandlers => {
    const [selection, dispatch] = useReducer(reducer, []);
    const results = useGetGoogleCampaigns(selection, (data, error) => {
      if (error) {
        Modal.error({
          title: "Error",
          content: "There was an error fetching campaigns",
        });
      }

      if (!data) return;

      updateAccount(
        data.dealerName,
        data.results?.map(campaignResponse => campaignResponse.campaign) || [],
      );
    });

    const isFetchingGoogleCampaigns = results.some(result => result.isFetching);

    const addAccount = (selection: CreateAssetGroupSelection) => {
      dispatch({
        type: "ADD",
        payload: selection,
      });
    };

    const removeAccount = (dealerName: string) =>
      removeAccountPrompt(dealerName, () =>
        dispatch({ type: "REMOVE", payload: dealerName }),
      );

    const updateAccount = (dealerName: string, campaigns: GoogleAdCampaign[]) =>
      dispatch({ type: "SET_CAMPAIGNS", payload: { dealerName, campaigns } });

    const setAssetGroups = (payload: SetAssetGroupsPayload) =>
      dispatch({
        type: "SET_ASSET_GROUPS",
        payload,
      });

    const removeAssetGroups = (payload: RemoveAssetGroupsPayload) => {
      dispatch({
        type: "REMOVE_ALL_ASSET_GROUPS",
        payload,
      });
    };

    const resetSelection = () => dispatch({ type: "RESET" });
    const updateAssetGroupFinalUrl = (
      payload: UpdateAssetGroupFinalUrlPayload,
    ) =>
      dispatch({
        type: "UPDATE_ASSET_GROUP_FINAL_URL",
        payload,
      });

    const computedSelectedKeys = useMemo(
      () =>
        selection
          .flatMap(sel => sel.adAccount)
          .flatMap(adAccount => {
            if (!adAccount.campaigns) return [];

            return adAccount.campaigns
              .filter(campaign => campaign?.assetGroups?.length)
              .map(campaign => getCampaignKey(adAccount, campaign));
          }),
      [selection],
    );

    return {
      selection,
      computedSelectedKeys,
      addAccount,
      removeAccount,
      setAssetGroups,
      removeAssetGroups,
      isFetchingGoogleCampaigns,
      resetSelection,
      updateAssetGroupFinalUrl,
    };
  };

export default useCreateNewAssetGroupSelector;
