import { Button, Result, Typography } from "antd";
import { useNavigate } from "react-router-dom";

interface IProps {
  subjectName?: string;
  message: string;
}

const WarningResult = ({ subjectName, message }: IProps) => {
  const navigate = useNavigate();

  return (
    <Result
      status="warning"
      title={
        <Typography.Text>
          <b>{subjectName}</b> {message}
        </Typography.Text>
      }
      extra={
        <Button
          type="primary"
          key="store-mgmt"
          onClick={() => {
            navigate("/store-management");
          }}
        >
          Go to Store Management
        </Button>
      }
    />
  );
};

export default WarningResult;
