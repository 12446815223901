import { CAM_ENABLED } from "./config";

export const useCAMHookWithFallback = <T, K, Z>(
  mediaHook: T,
  fallbackHook: K,
  brandsHook: Z,
  shouldUseBrandFonts: boolean,
) => {
  if (shouldUseBrandFonts) return brandsHook;

  return CAM_ENABLED ? mediaHook : fallbackHook;
};
