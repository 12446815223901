import API from "services";
import { useMutation, useQueryClient } from "react-query";
import { Platform, PlatformHookParams } from "../types";
import { getPlatformTokenKey } from "../utils";

export const useDeletePlatformToken = ({
  platform,
  onError,
}: PlatformHookParams) => {
  const queryClient = useQueryClient();

  const deletePlatformToken = async (platform: Platform) => {
    const { result, error } = await API.services.platformManagement.deleteToken(
      platform,
    );

    if (error || !result) {
      throw Error(error?.message || "Unknown error");
    }

    return result;
  };

  return useMutation<void, Error, Platform>(deletePlatformToken, {
    onSettled: () => {
      queryClient.invalidateQueries(getPlatformTokenKey(platform));
    },
    onError,
  });
};
