export type UpdatePlannerInstanceAdParams = {
  plannerId: string;
  instanceId: string;
  adId: string;
  campaignStatus: CampaignAdStatus;
};

export type UpdateCampaignPlannerAdsParams = {
  id: string;
  adShellIds: string[];
};

export type DeleteCampaignPlannerParams = {
  campaignPlannerId: string;
  instanceIds: string[];
}[];

export type CreateCampaignPlannerParams = {
  name: string;
  id: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
};

export type AdStatusMap = {
  [adId: string]: CampaignAdStatus;
};

export enum CampaignAdStatus {
  LOAD_REQUESTED = "LOAD_REQUESTED",
  PAUSE_REQUESTED = "PAUSE_REQUESTED",
  DO_NOT_LOAD = "DO_NOT_LOAD",
  PAUSED = "PAUSED",
  LOADED = "LOADED",
  LOADED_EDITED = "LOADED_EDITED",
  NEVER_LOADED = "NEVER_LOADED",
  REACTIVATION_REQUESTED = "REACTIVATION_REQUESTED",
}
