import { FC, memo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import AssetExporterProvider from "shared/components/contextAPI/assetExporter";
import { useDatadog } from "shared/hooks/useDatadog";
import { IConfigurationState } from "shared/types/configuration";
import { adEngineV2Enabled } from "utils/helpers";
import FeedConfiguration from "./assetExporter/FeedConfiguration";
import FeedConfigurationV2 from "./assetExporter/FeedConfigurationV2";
import SourceFeed from "./assetExporter/SourceFeed";
import { CellProvider } from "./assetExporter/feedConfiguration/editableCell/CellContext";

interface IAssetExporter {
  config?: IConfigurationState["config"];
}

const AssetExporter: FC<IAssetExporter> = props => {
  useDatadog();
  return (
    <CellProvider>
      <AssetExporterProvider config={props.config}>
        <div className="design-studio-root-container">
          <Routes>
            <Route path="source-feed" element={<SourceFeed />} />
            <Route
              path="configure"
              element={
                adEngineV2Enabled ? (
                  <FeedConfigurationV2 />
                ) : (
                  <FeedConfiguration />
                )
              }
            />
            <Route index element={<Navigate to="source-feed" replace />} />
          </Routes>
        </div>
      </AssetExporterProvider>
    </CellProvider>
  );
};

const mapStateToProps = (state: { configuration: IConfigurationState }) => {
  const { configuration } = state;
  const { config } = configuration;

  return {
    config,
  };
};

export default connect(mapStateToProps, null)(memo(AssetExporter));
