import useUserPreferences from "../useUserPreferences";
import { useNavigate, useLocation } from "react-router-dom";

import { parsePropValueToParamValue } from "utils/userPreferences";
import { propToUrlParamDict } from "shared/constants/userPreferences";

import { PreferenceKey } from "shared/types/userPreferences";
import { useMemo, useCallback, useRef } from "react";

const usePreference = <T = string[]>(
  propName: PreferenceKey,
  defaultValue: T,
  updateUrl?: boolean,
) => {
  const defaultValueRef = useRef(defaultValue);

  const navigate = useNavigate();
  const location = useLocation();
  const { getPreference, setPreference } = useUserPreferences();
  const value = useMemo(
    () => getPreference<T>(propName) || defaultValueRef.current,
    [getPreference, propName],
  );
  const setValue = useCallback(
    (data: T) => {
      setPreference({ propName, data });
      const paramKey = propToUrlParamDict[propName];
      if (updateUrl && paramKey) {
        const urlParams = new URLSearchParams(location.search);
        urlParams.set(
          paramKey,
          parsePropValueToParamValue(propName, data) || "",
        );
        navigate({ search: urlParams.toString() });
      }
    },
    [navigate, location.search, propName, setPreference, updateUrl],
  );

  return { value, setValue };
};

export default usePreference;
