import { DateFormater } from "../../DateFormater";
import { useColumn } from "../useColumn";

export const useColDateExpired = () => {
  return useColumn("dateexpired", {
    title: "Expiration",
    renderFn(text: string, record: WDAsset | WDFolder) {
      if (record.type === "asset") {
        return <DateFormater date={record.dateexpired} />;
      }
    },
  });
};
