import { Popover, Tag } from "antd";
import styles from "./TagsGroup.module.scss";
import { Fragment } from "react";
import classNames from "classnames";

interface TagsGroupProps {
  tags: string[];
  activeTags?: string[];
}

export const TagsGroup = ({ tags, activeTags }: TagsGroupProps) => {
  const isActive = (activeTags?.length ?? 0) > 0;
  return (
    <Popover
      overlayClassName={styles.tagsGroupPopover}
      placement="bottomLeft"
      content={
        <div className={styles.tagsContainer}>
          {tags.map((item: string) => (
            <Fragment key={item}>
              <Tag
                className={
                  activeTags?.includes(item)
                    ? classNames(styles.innerTag, styles.activeTag)
                    : styles.innerTag
                }
              >
                {item}
              </Tag>
              <br />
            </Fragment>
          ))}
        </div>
      }
    >
      <Tag
        data-cy="tags-group"
        className={
          isActive ? classNames(styles.tag, styles.activeTag) : styles.tag
        }
      >
        {`+${tags.length}`}
      </Tag>
    </Popover>
  );
};
