import { Typography, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import classNames from "classnames";
import styles from "./TitleWithDescription.module.scss";

const TitleWithDescription = ({
  title,
  numberLimit,
  minimum,
  info,
  error = false,
  required = false,
}: {
  title: string;
  numberLimit?: number;
  minimum?: number;
  info?: string;
  error?: boolean;
  required?: boolean;
}) => {
  const minimumText = !!minimum ? minimum : "up";
  return (
    <span>
      <Typography.Text className={styles.title}>
        {title}
        {required && <span style={{ color: "red" }}>* </span>}
      </Typography.Text>
      {numberLimit && (
        <Typography.Text
          className={classNames(
            error ? styles.textColorWithError : styles.textColor,
            styles.description,
          )}
        >
          {` (add ${minimumText} to ${numberLimit})`}
        </Typography.Text>
      )}
      {info && (
        <Tooltip
          className={styles.tooltip}
          title={info}
          mouseEnterDelay={0.5}
          trigger={["hover", "click"]}
        >
          <InfoCircleOutlined />
        </Tooltip>
      )}
    </span>
  );
};

export default TitleWithDescription;
