import { Dispatch, SetStateAction } from "react";
import { Table } from "antd";
import { useWindowSize } from "shared/hooks/useWindowSize";
import styles from "./AdLibrary.module.scss";
import { useFillAdInfoTable } from "./useFillAdInfoTable";
import { useFeedContext } from "../../shared/contexts/FeedContext";
import { useCellContext } from "screens/assetExporter/feedConfiguration/editableCell/CellContext";

interface FillAdInfoTableProps {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
}

export const FillAdInfoTable = ({ page, setPage }: FillAdInfoTableProps) => {
  const { selectedRows } = useFeedContext();
  const { windowInnerHeight } = useWindowSize();
  const { columns, data, isLoading } = useFillAdInfoTable(page);
  const {
    setEditingRow,
    setEditingField,
    setIsEditing,
    setNewValue,
    setOldValue,
  } = useCellContext();

  const handlePageChange = (nextPage: number) => {
    setNewValue("");
    setOldValue("");
    setEditingRow("");
    setEditingField("");
    setIsEditing(false);

    setPage(nextPage);
  };

  return (
    <Table
      columns={columns}
      dataSource={data}
      size="small"
      scroll={{ y: windowInnerHeight - 300, x: "max-content" }}
      loading={isLoading}
      pagination={{
        size: "small",
        showSizeChanger: false,
        total: selectedRows.length,
        showTotal: (total, [a, b]) => `Displaying ${a}-${b} of ${total} items`,
        pageSize: 30,
        position: ["bottomCenter"],
        onChange: handlePageChange,
        className: styles.tablePagination,
      }}
      bordered
    />
  );
};
