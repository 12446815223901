import { Drawer, Button } from "antd";
import { useCallback } from "react";
import { componentTypeTextMap } from "screens/adLibrary/shared/validationUtils";
import ButtonComponent from "screens/designStudio/editor/instantExperience/ButtonComponent";
import CarouselComponent from "screens/designStudio/editor/instantExperience/CarouselComponent";
import ImageComponent from "screens/designStudio/editor/instantExperience/ImageComponent";
import ProductSetComponent from "screens/designStudio/editor/instantExperience/ProductSetComponent";
import Settings from "screens/designStudio/editor/instantExperience/Settings";
import VideoComponent from "screens/designStudio/editor/instantExperience/VideoComponent";
import {
  IInstantExperience,
  InstantExperienceBodyElement,
} from "shared/types/adLibrary";

type Props = {
  instantExperienceElementEdit?: InstantExperienceBodyElement;
  instantExperienceEdit?: IInstantExperience;
  hideDrawer: () => void;
  setInstantExperience: (instantExperienceUpdate: IInstantExperience) => void;
  setInstantExperienceElementEdit: (
    value: React.SetStateAction<InstantExperienceBodyElement | undefined>,
  ) => void;
};

const InstantExperiencesEditComponentDrawer = ({
  instantExperienceElementEdit,
  instantExperienceEdit,
  hideDrawer,
  setInstantExperience,
  setInstantExperienceElementEdit,
}: Props) => {
  const updateElement = useCallback(
    (newElement: InstantExperienceBodyElement): void => {
      const bodyElementsUpdated = instantExperienceEdit?.body_elements?.map(
        element =>
          element.id === instantExperienceElementEdit?.id
            ? newElement
            : element,
      );
      setInstantExperience({
        ...instantExperienceEdit,
        body_elements: bodyElementsUpdated,
      });
      setInstantExperienceElementEdit(newElement);
    },
    [
      setInstantExperience,
      instantExperienceEdit,
      instantExperienceElementEdit?.id,
      setInstantExperienceElementEdit,
    ],
  );

  const componentProps = {
    updateElement,
    forceValidations: true,
    disableFooter: false,
  };

  return (
    <Drawer
      visible
      width={650}
      title={`${
        instantExperienceElementEdit?.element_type
          ? componentTypeTextMap[instantExperienceElementEdit?.element_type]
          : "Instant Experience"
      } Settings`}
      zIndex={1001}
      closable={true}
      onClose={hideDrawer}
      footer={
        <Button type="primary" onClick={hideDrawer}>
          Done
        </Button>
      }
      footerStyle={{ display: "flex", justifyContent: "flex-end" }}
    >
      {instantExperienceEdit && !instantExperienceElementEdit && (
        <Settings
          initialInstantExperience={instantExperienceEdit}
          setInstantExperience={setInstantExperience}
          hideGoToDiem
        />
      )}
      {instantExperienceElementEdit?.element_type === "PHOTO" && (
        <ImageComponent
          {...componentProps}
          instantExperienceElement={instantExperienceElementEdit}
        />
      )}
      {instantExperienceElementEdit?.element_type === "VIDEO" && (
        <VideoComponent
          {...componentProps}
          instantExperienceElement={instantExperienceElementEdit}
        />
      )}
      {(instantExperienceElementEdit?.element_type === "BUTTON" ||
        instantExperienceElementEdit?.element_type === "FOOTER") && (
        <ButtonComponent
          {...componentProps}
          instantExperienceElement={instantExperienceElementEdit}
        />
      )}
      {instantExperienceElementEdit?.element_type === "CAROUSEL" && (
        <CarouselComponent
          {...componentProps}
          instantExperienceElement={instantExperienceElementEdit}
        />
      )}
      {instantExperienceElementEdit?.element_type === "ELEMENT_GROUP" && (
        <ProductSetComponent
          {...componentProps}
          instantExperienceElement={instantExperienceElementEdit}
        />
      )}
    </Drawer>
  );
};

export default InstantExperiencesEditComponentDrawer;
