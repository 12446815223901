import { IAd, QcStatus } from "shared/types/adLibrary";
import { hasErrors, IAdValidations } from "../../../adValidationUtils";
import IndeterminateSwitch from "shared/button/IndeterminateSwitch";

interface Props {
  ads: IAd[];
  setAds: (ads: IAd[]) => void;
  validationErrors?: IAdValidations[];
}

const StatusSwitch = ({ ads, setAds, validationErrors }: Props) => {
  const adDoesNotHaveErrors = (ad: IAd) => !hasErrors(ad.id, validationErrors);

  const allChecked = ads
    .filter(adDoesNotHaveErrors)
    .every(ad => ad.qcStatus === QcStatus.INTERNALLY_APPROVED);

  const allUnchecked = ads.every(
    ad => ad.qcStatus !== QcStatus.INTERNALLY_APPROVED,
  );

  const indeterminate = !allChecked && !allUnchecked;

  const getAllValidAdsAsApproved = () =>
    ads.map(ad => ({
      ...ad,
      qcStatus: !hasErrors(ad.id, validationErrors)
        ? QcStatus.INTERNALLY_APPROVED
        : QcStatus.DRAFT,
    }));

  const getAllAdsAsDraft = () =>
    ads.map(ad => ({
      ...ad,
      qcStatus: QcStatus.DRAFT,
    }));

  const getStatusTooltip = () => {
    if (allChecked) {
      return "Import as Internally Approved";
    }

    return allUnchecked
      ? "Import as Draft"
      : "Import Draft and Internally Approved";
  };

  return (
    <IndeterminateSwitch
      size="small"
      checked={allChecked}
      tooltipTitle={getStatusTooltip()}
      indeterminate={indeterminate}
      onIndeterminateClick={() => {
        setAds(getAllValidAdsAsApproved());
      }}
      onChange={checked => {
        const newAds = checked
          ? getAllValidAdsAsApproved()
          : getAllAdsAsDraft();
        setAds(newAds);
      }}
    />
  );
};

export default StatusSwitch;
