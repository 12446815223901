import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { clientsArr } from "../../config/clients";
import { Config, configs, configsArr } from "../../config/configs";
import { devsArr } from "../../config/devs";

export type { Config } from "../../config/configs";

export const useConfig = () => {
  const location = useLocation();
  const [config, setConfig] = useState<Config>();

  const checkConfig = useCallback(() => {
    const evalConfig = (altConfig: Config, altCid?: string, save = false) => {
      const allConfigs = [...configsArr, ...clientsArr, ...devsArr];
      const checkConfig = allConfigs.find(({ cid }) => cid === altCid);
      const newConfig = checkConfig?.cid === altCid ? checkConfig : altConfig;
      if (save && newConfig?.cid) {
        window.localStorage.setItem("cid", JSON.stringify(newConfig.cid));
      }
      setConfig(newConfig);
    };

    const [subDomain] = window.location.hostname.split(".");
    const isPreview = subDomain.startsWith("alexia-exp-client");
    if (isPreview) {
      const checkCid = window.localStorage.getItem("cid");
      const cid = checkCid ? JSON.parse(checkCid) : "";
      const qCid = new URLSearchParams(location.search).get("cid");
      evalConfig(configs.env, qCid || cid, true);
    } else {
      evalConfig(configs.env, subDomain);
    }
  }, [location.search]);

  useEffect(checkConfig, [checkConfig]);
  return { config };
};
