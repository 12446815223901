import {
  DesignStudioTableItem,
  useDesignStudioContext,
} from "screens/designStudio/context/DesignStudioDataProvider";
import {
  mediaTypeFilters,
  categoryFilters,
  locationFilters,
  getArtboardName,
  getAssetType,
  getCategoryName,
  getCreatedAgo,
  getDimensions,
  getMediaType,
  getNumberOfStamps,
  getOemsArray,
  getTemplateType,
  getUpdatedAgo,
  getAssetTypeFilters,
  getStoresFilters,
  getTemplateTypeFilters,
  getArtboardNameFilters,
  getNumberOfStampsFilters,
  getOemsFilters,
  getTagsFilters,
  getAllDimensions,
  getLocation,
  getStoresArray,
  getTags,
  statusFilters,
} from "../../../helpers";
import { DesignStudioItemStatusCell } from "../DesignStudioItemStatusCell";
import { DesignStudioItemTagsCell } from "../DesignStudioItemTagsCell";
import { DesignStudioItemNameCell } from "../DesignStudioItemNameCell";
import { useDesignStudioTableActions } from "./useDesignStudioTableActions";
import { CalendarOutlined, SearchOutlined } from "@ant-design/icons";
import { SearchFilterDropdown } from "shared/components/SearchFilterDropdown";
import { FilterDropdownWithFilterSearch } from "shared/components/FilterDropdownWithFilterSearch";
import { useDesignStudioFilters } from "screens/adLibrary/shared/hooks/dataListHooks";
import { DateFilterDropdown } from "shared/components/DateFilterDropdown";
import { DimensionsFilterDropdown } from "shared/components/DimensionsFilterDropdown";
import { ITableColumnURL } from "shared/components/dataListURL/DataListURLTable";
import { DesignStudioFields } from "screens/designStudio/context/fields";
import { DesignStudioItemCell } from "../DesignStudioItemCell";

export type DesignStudioTableColumn = ITableColumnURL<
  DesignStudioFields,
  DesignStudioTableItem
>;

export const useDesignStudioTableColumns = (): DesignStudioTableColumn[] => {
  const { designStudioAssets } = useDesignStudioContext();
  const { editParams } = useDesignStudioTableActions();
  const { filters } = useDesignStudioFilters();

  return [
    {
      key: "name",
      title: "Name",
      fixed: "left",
      width: 300,
      filterDropdown: SearchFilterDropdown,
      filterIcon: (
        <SearchOutlined color={filters?.name ? "#1890ff" : undefined} />
      ),
      render: (_, item) => (
        <DesignStudioItemNameCell
          item={item}
          onClick={() => editParams.handler(item)}
        />
      ),
    },
    {
      key: "category",
      title: "Category",
      width: 150,
      filters: categoryFilters,
      render: (_, item) => (
        <DesignStudioItemCell
          itemType={item.itemType}
          value={getCategoryName(item)}
          column="category"
        />
      ),
    },
    {
      key: "status",
      title: "Status",
      width: 120,
      filters: statusFilters,
      render: (_, item) => <DesignStudioItemStatusCell item={item} />,
    },
    {
      key: "assetType",
      title: "Asset Type",
      width: 140,
      filters: getAssetTypeFilters(designStudioAssets),
      filterDropdown: FilterDropdownWithFilterSearch,
      render: (_, item) => (
        <DesignStudioItemCell
          itemType={item.itemType}
          value={getAssetType(item)}
          column="assetType"
        />
      ),
    },
    {
      key: "templateType",
      title: "Template Type",
      width: 160,
      filters: getTemplateTypeFilters(designStudioAssets),
      filterDropdown: FilterDropdownWithFilterSearch,
      render: (_, item) => (
        <DesignStudioItemCell
          value={getTemplateType(item)}
          itemType={item.itemType}
          column="templateType"
        />
      ),
    },
    {
      key: "mediaType",
      title: "Media Type",
      width: 140,
      filters: mediaTypeFilters,
      render: (_, item) => (
        <DesignStudioItemCell
          itemType={item.itemType}
          value={getMediaType(item)}
          column="mediaType"
        />
      ),
    },
    {
      key: "dimensions",
      title: "Dimensions",
      width: 150,
      filterDropdown: props => (
        <DimensionsFilterDropdown
          {...props}
          items={getAllDimensions(designStudioAssets)}
        />
      ),
      render: (_, item) => (
        <DesignStudioItemCell
          itemType={item.itemType}
          value={getDimensions(item)}
          column="dimensions"
        />
      ),
    },
    {
      key: "artboardName",
      title: "Artboard Name",
      width: 160,
      filters: getArtboardNameFilters(designStudioAssets),
      filterDropdown: FilterDropdownWithFilterSearch,
      render: (_, item) => (
        <DesignStudioItemCell
          itemType={item.itemType}
          value={getArtboardName(item)}
          column="artboardName"
        />
      ),
    },
    {
      key: "stores",
      title: "Accounts",
      width: 160,
      onCell: () => ({ style: { overflowX: "hidden" } }),
      filters: getStoresFilters(designStudioAssets),
      filterDropdown: FilterDropdownWithFilterSearch,
      render: (_, item) => (
        <DesignStudioItemTagsCell
          tags={getStoresArray(item)}
          itemType={item.itemType}
          column="stores"
        />
      ),
    },
    {
      key: "oems",
      title: "Brands",
      filters: getOemsFilters(designStudioAssets),
      filterDropdown: FilterDropdownWithFilterSearch,
      width: 120,
      render: (_, item) => (
        <DesignStudioItemTagsCell
          tags={getOemsArray(item)}
          itemType={item.itemType}
          column="oems"
        />
      ),
    },
    {
      key: "numberOfStamps",
      title: "# Stamps",
      width: 120,
      filters: getNumberOfStampsFilters(designStudioAssets),
      render: (_, item) => (
        <DesignStudioItemCell
          itemType={item.itemType}
          value={getNumberOfStamps(item)}
          column="numberOfStamps"
        />
      ),
    },
    {
      key: "location",
      title: "Location",
      width: 150,
      filters: locationFilters,
      render: (_, item) => {
        const locationValue = getLocation(item);
        return (
          <DesignStudioItemTagsCell
            tags={locationValue ? [locationValue] : []}
            itemType={item.itemType}
            column="location"
          />
        );
      },
    },
    {
      key: "created",
      title: "Created",
      width: 150,
      filterDropdown: DateFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <CalendarOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      render: (_, item) => (
        <DesignStudioItemCell
          itemType={item.itemType}
          value={getCreatedAgo(item)}
          column="created"
        />
      ),
    },
    {
      key: "lastEdited",
      title: "Last Edited",
      width: 150,
      filterDropdown: DateFilterDropdown,
      render: (_, item) => (
        <DesignStudioItemCell
          itemType={item.itemType}
          value={getUpdatedAgo(item)}
          column="lastEdited"
        />
      ),
    },
    {
      key: "tags",
      title: "Tags",
      width: 300,
      filters: getTagsFilters(designStudioAssets),
      filterDropdown: FilterDropdownWithFilterSearch,
      render: (_, item) => (
        <DesignStudioItemTagsCell
          tags={getTags(item)}
          column="tags"
          itemType={item.itemType}
        />
      ),
    },
  ];
};
