import { memo, ReactElement } from "react";
import VideoComponentForm, {
  IVideoComponentForm,
} from "./videoComponent/VideoComponentForm";
import {
  InstantExperienceBodyElement,
  InstantExperienceElementStyle,
  IVideoElement,
} from "shared/types/adLibrary";
import uuid from "uuid";

interface IProps {
  instantExperienceElement: IVideoElement;
  updateElement(instantExperienceElement: InstantExperienceBodyElement): void;
  forceValidations?: boolean;
}

const defaultVideoValues: Omit<IVideoElement, "id"> = {
  element_type: "VIDEO",
  name: "Video",
  style: InstantExperienceElementStyle.FIT_TO_WIDTH,
};

export const getDefaultVideoValues = (): IVideoElement => ({
  ...defaultVideoValues,
  id: uuid(),
});

function VideoComponent({
  instantExperienceElement: videoElement,
  updateElement,
  forceValidations,
}: IProps): ReactElement {
  const initialValues: IVideoComponentForm = {
    layoutOption: videoElement.style,
    fileUrls: { videoUrl: videoElement.url, thumbnail: videoElement.thumbnail },
  };

  function getInstantExperienceElement(
    values: IVideoComponentForm,
  ): IVideoElement {
    return {
      id: videoElement.id,
      name: videoElement.name,
      element_type: "VIDEO",
      url: values.fileUrls?.videoUrl,
      thumbnail: values.fileUrls?.thumbnail,
      style: values.layoutOption,
    };
  }

  return (
    <VideoComponentForm
      initialValues={initialValues}
      onValuesChange={values => {
        if (!values) {
          return;
        }
        updateElement(getInstantExperienceElement(values));
      }}
      forceValidations={forceValidations}
    />
  );
}

export default memo(VideoComponent);
