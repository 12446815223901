import { Tag } from "antd";

interface Props {
  tags: string[];
}

const Tags = ({ tags }: Props) => {
  const colors = ["green", "geekblue", "red"];
  return (
    <>
      {tags?.map((tag, idx) => (
        <Tag color={colors[idx % 3]} key={tag}>
          {tag}
        </Tag>
      ))}
    </>
  );
};

export default Tags;
