import { useMemo } from "react";
import { UseQueryOptions } from "react-query";
import { FeedCron, FeedTableAttrs } from "shared/types/assetExporter";
import { useFetchFeeds } from "./useFetchFeeds";

const NOT_SUPPORTED = "Not supported";

const formatFeedForTable = (feed: FeedCron): FeedTableAttrs => {
  const isCustom = feed.feedUploadType === "custom";

  return {
    feedId: feed.id,
    feedName: feed.feedName,
    filterColHeader: feed.filterColHeader,
    frequency: !isCustom && !!feed.frequency ? feed.frequency : NOT_SUPPORTED,
    repeat: !feed.repeat || feed.repeat === "--" ? undefined : feed.repeat,
    key: feed.id,
    lastUpdate: isCustom ? NOT_SUPPORTED : feed.lastUpdate,
    locationTags: feed.locationTags,
    nextUpdate: isCustom ? NOT_SUPPORTED : feed.nextUpdate,
    oemTags: feed.oemTags,
    currStatus: feed.processedStatus ?? "RUNNING",
    storeTags: feed.storeTags,
    versions: feed.version ?? 0,
    type: feed.type,
    createdAt: feed.createdAt ?? 0,
    lastEdited: feed.lastEdited ?? 0,
  };
};

export const useGetFormattedFeeds = (
  callbacks?: UseQueryOptions<FeedCron[], Error>,
) => {
  const { data, isLoading, isSuccess, isError } = useFetchFeeds(callbacks);
  const feeds = useMemo(() => data?.map(formatFeedForTable) ?? [], [data]);
  const feedsFromServer = useMemo(() => data ?? [], [data]);

  return {
    feeds,
    feedsFromServer,
    isLoading,
    isError,
    isSuccess,
  };
};
