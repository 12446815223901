import {
  DesignStudioItemCell,
  DesignStudioItemCellProps,
} from "./DesignStudioItemCell";
import { TooltipTag } from "../shared/TooltipTag";

type DesignStudioItemTagsCellProps = {
  tags: string[];
} & Omit<DesignStudioItemCellProps, "value">;

export const DesignStudioItemTagsCell = ({
  tags,
  column,
  itemType,
}: DesignStudioItemTagsCellProps) => {
  if (!tags.length)
    return (
      <DesignStudioItemCell column={column} value={null} itemType={itemType} />
    );

  const [first, second, ...rest] = tags;

  if (tags.length > 1)
    return (
      <>
        <TooltipTag title={first}>{first}</TooltipTag>
        <TooltipTag title={first}>{second}</TooltipTag>
        {rest.length > 0 && (
          <TooltipTag title={rest.join(", ")}>+{rest.length}</TooltipTag>
        )}
      </>
    );

  return <TooltipTag title={first}>{first}</TooltipTag>;
};
