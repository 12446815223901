import { generatePath, useNavigate } from "react-router-dom";

import TabContainer from "../../shared/components/TabContainer";
import FilterSection from "./library/FilterSection";
import StampList from "./library/StampList";
import TemplateList from "./library/TemplateList";

import { useAppDispatch } from "shared/hooks/useAppDispatch";
import {
  HeaderMenu,
  IArtboard,
  ITemplateTag,
  MediaFilterType,
  TabMenu,
  TTemplateType,
} from "shared/types/designStudio";
import { designStudioTabs } from "utils/helpers.designStudio";
import "./Library.scss";
import ArtboardList from "./library/ArtboardList";

import { kebabCase, lowerCase } from "lodash";

import { searchTemplatesStampsArtboards } from "redux/designStudio/designStudio.slice";

const libQueryConfig = {
  search: StringParam,
  tags: StringParam,
  stores: StringParam,
  oem_t: StringParam,
  offer_count: StringParam,
  type: StringParam,
  media: StringParam,
  oem_s: StringParam,
  states: StringParam,
};

export type LibraryQueryTypes = typeof libQueryConfig;

import { FC, useState } from "react";
import { useIsAdmin } from "shared/hooks/useIsAdmin";
import { StringParam, useQueryParams } from "use-query-params";
interface ILibrary {
  selectedTab: TabMenu;
  selectedHeader: HeaderMenu;
  filterTemplateOem: (filter: string[] | null) => void;
  filterTemplateStore: (filter: string[] | null) => void;
  filterTemplateTags: (tags: string[] | null) => void;
  filterTemplateOfferCounts: (count: string) => void;

  filterStampOem: (filter: string[] | null) => void;
  filterStampState: (filter: string[] | null) => void;
  filterStampDescriptor: (tags: string[] | null) => void;

  tags: ITemplateTag[];

  onArtboardEdit: (artboard: IArtboard) => void;

  selectedTemplateType?: TTemplateType;
}

const Library: FC<ILibrary> = ({
  selectedTab,
  selectedHeader,

  filterTemplateOem,
  filterTemplateStore,
  filterTemplateTags,
  filterTemplateOfferCounts,

  filterStampOem,
  filterStampState,
  filterStampDescriptor,

  tags,
  onArtboardEdit,
}) => {
  const dispatch = useAppDispatch();
  const isAdmin = useIsAdmin();

  const [libQuery, setLibQuery] = useQueryParams(libQueryConfig);

  const navigate = useNavigate();

  const [selectedTemplateType, setSelectedTemplateType] =
    useState<TTemplateType>(libQuery.type as TTemplateType);

  const [selectedMediaType, setSelectedMediaType] = useState<
    MediaFilterType | undefined
  >(libQuery.media as MediaFilterType);

  const [activeKey, setActiveKey] = useState<string>("");

  return (
    <TabContainer
      searchQuery={libQuery.search ?? ""}
      onSearchInputChange={(newSearchBy: string) => {
        setLibQuery({ search: newSearchBy || null }, "replaceIn");
        dispatch(searchTemplatesStampsArtboards(newSearchBy));
      }}
      displaySearchView={{
        displayNewOffer: false,
        displaySearchInput: true,
        displayPlusButton: false,
      }}
      displayFilterSection={true}
      onChange={tab => {
        if (tab.toLowerCase() === "everything ads") {
          return navigate("/everything-ads/ads");
        }
        const routeToGo =
          selectedHeader === "DELETED ASSETS"
            ? "/design-studio/deleted/:targetTab"
            : "/design-studio/library/:targetTab";
        return navigate(generatePath(routeToGo, { targetTab: kebabCase(tab) }));
      }}
      filterTab={
        <FilterSection
          selectedTab={selectedTab}
          selectedHeader={selectedHeader}
          filterTemplateOem={filterTemplateOem}
          filterTemplateStore={filterTemplateStore}
          filterTemplateTags={filterTemplateTags}
          filterTemplateOfferCounts={filterTemplateOfferCounts}
          tags={tags}
          libQuery={libQuery}
          setLibQuery={setLibQuery}
          filterStampOem={filterStampOem}
          filterStampState={filterStampState}
          filterStampDescriptor={filterStampDescriptor}
          selectedTemplateType={selectedTemplateType}
          onSelectedTemplateTypeChange={templateType => {
            if (templateType) setSelectedTemplateType(templateType);
          }}
          selectedMediaType={selectedMediaType}
          onSelectedMediaTypeChange={(mediaType?: MediaFilterType) => {
            setSelectedMediaType(mediaType);
          }}
        />
      }
      selectedTab={lowerCase(selectedTab)}
      contentTabs={[
        {
          title: "Templates",
          hidden: !designStudioTabs.templatesEnabled,
          component: (
            <TemplateList
              activeKey={activeKey}
              setActiveKey={setActiveKey}
              selectedTab={selectedTab}
              selectedHeader={selectedHeader}
              selectedTemplateType={selectedTemplateType}
              selectedMediaType={selectedMediaType}
            />
          ),
        },
        {
          title: "Stamps",
          hidden: !designStudioTabs.stampsEnabled,
          component: (
            <StampList
              activeKey={activeKey}
              setActiveKey={setActiveKey}
              selectedTab={selectedTab}
              selectedHeader={selectedHeader}
            />
          ),
        },
        ...(selectedHeader !== "DELETED ASSETS" && isAdmin
          ? [
              {
                title: "Artboards",
                hidden: !designStudioTabs.artboardsEnabled,
                component: (
                  <ArtboardList
                    onArtboardEdit={onArtboardEdit}
                    selectedTab={selectedTab}
                  />
                ),
              },
            ]
          : []),
      ]}
    />
  );
};

export default Library;
