import {
  DesignStudioTableItem,
  useDesignStudioContext,
} from "screens/designStudio/context/DesignStudioDataProvider";
import {
  categoryFilters,
  getDimensionFilters,
  getAssetTypeFilters,
  getTemplateTypeFilters,
  mediaTypeFilters,
  statusFilters,
  getArtboardNameFilters,
  getStoresFilters,
  getNumberOfStampsFilters,
  getOemsFilters,
  locationFilters,
  getTagsFilters,
  dateFilterToRange,
} from "../../helpers";
import { useCallback } from "react";
import { useDesignStudioFilters } from "screens/adLibrary/shared/hooks/dataListHooks";
import { DesignStudioFields } from "screens/designStudio/context/fields";
import { useFilterDataByFilters } from "shared/components/dataListURL/useDataListURLData";
import { useSyncedState } from "shared/hooks/useSyncedState";
import { isEqual } from "lodash";

export const useFilterFields = () => {
  const { designStudioAssets } = useDesignStudioContext();
  const { filters, resetFilters, setFilters } = useDesignStudioFilters();
  const [tempFilters, setTempFilters] =
    useSyncedState<Partial<typeof filters>>(filters);

  const updateTempFilter = useCallback(
    (name: keyof typeof tempFilters, value: string[]) =>
      setTempFilters({
        ...tempFilters,
        [name]: value,
      }),
    [tempFilters, setTempFilters],
  );

  const prefilteredItems = useFilterDataByFilters<
    DesignStudioFields,
    DesignStudioTableItem
  >(designStudioAssets, tempFilters);

  const filterFields = [
    {
      title: "Name",
      key: "name",
      value: tempFilters.name,
      options: null,
      type: "text",
    },
    {
      title: "Category",
      key: "category",
      value: tempFilters.category,
      options: categoryFilters,
      type: "select",
    },
    {
      title: "Status",
      key: "status",
      value: tempFilters.status,
      options: statusFilters,
      type: "select",
    },
    {
      title: "Asset Type",
      key: "assetType",
      value: tempFilters.assetType,
      options: getAssetTypeFilters(designStudioAssets),
      type: "select",
    },
    {
      title: "Template Type",
      key: "templateType",
      value: tempFilters.templateType,
      options: getTemplateTypeFilters(designStudioAssets),
      type: "select",
    },
    {
      title: "Media Type",
      key: "mediaType",
      value: tempFilters.mediaType,
      options: mediaTypeFilters,
      type: "select",
    },
    {
      title: "Dimensions",
      key: "dimensions",
      value: tempFilters.dimensions,
      options: getDimensionFilters(designStudioAssets),
      type: "select",
    },
    {
      title: "Artboard Name",
      key: "artboardName",
      value: tempFilters.artboardName,
      options: getArtboardNameFilters(designStudioAssets),
      type: "select",
    },
    {
      title: "Accounts",
      key: "stores",
      value: tempFilters.stores,
      options: getStoresFilters(designStudioAssets),
      type: "select",
    },
    {
      title: "Brands",
      key: "oems",
      value: tempFilters.oems,
      options: getOemsFilters(designStudioAssets),
      type: "select",
    },
    {
      title: "# Stamps",
      key: "numberOfStamps",
      value: tempFilters.numberOfStamps,
      options: getNumberOfStampsFilters(designStudioAssets),
      type: "select",
    },
    {
      title: "Location",
      key: "location",
      value: tempFilters.location,
      options: locationFilters,
      type: "select",
    },
    {
      title: "Created",
      key: "created",
      value: tempFilters?.created
        ? dateFilterToRange(tempFilters.created)
        : undefined,
      options: null,
      type: "date",
    },
    {
      title: "Last Edited",
      key: "lastEdited",
      value: tempFilters?.lastEdited
        ? dateFilterToRange(tempFilters.lastEdited)
        : undefined,
      options: null,
      type: "date",
    },
    {
      title: "Tags",
      key: "tags",
      value: tempFilters.tags,
      options: getTagsFilters(designStudioAssets),
      type: "select",
    },
  ] as const;

  const hasUncommittedFilters = !isEqual(filters, tempFilters);

  return {
    filterFields,
    prefilteredItems,
    tempFilters,
    setTempFilters,
    hasUncommittedFilters,
    updateTempFilter,
    filters,
    resetFilters,
    setFilters,
  };
};
