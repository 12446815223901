import { SetStateAction, useCallback, useMemo } from "react";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { setAdLoadState } from "redux/adLibrary/adLibrary.slice";
import { useAppShallowEqualSelector } from "shared/hooks/useAppSelector";

import { IAdToLoadData, LoadStepKeys } from "shared/types/adLibrary";
import { resetAdLoadStatus } from "../../utils";

type HandleCurrentInnerStepChangeArgs = {
  currentProcessStep: LoadStepKeys;
  selectedAdsToLoad: IAdToLoadData[];
  setCurrentProcessStep?: (value: SetStateAction<LoadStepKeys>) => void;
  setSelectedAdsToLoad?: (value: SetStateAction<IAdToLoadData[]>) => void;
};

/** Use this to handle changes to adLibrary.adLoad.currentInnerStep in LoadStep.tsx*/
export const useHandleCurrentInnerStepChange = () => {
  const dispatch = useAppDispatch();
  const { review: adLoadReview, currentInnerStep } = useAppShallowEqualSelector(
    ({ adLibrary }) => ({
      review: adLibrary.adLoad.review,
      currentInnerStep: adLibrary.adLoad.currentInnerStep,
    }),
  );
  const { adsToLoad = [] } = useMemo(
    () => adLoadReview || { adsToLoad: [] },
    [adLoadReview],
  );

  return useCallback(
    (args: HandleCurrentInnerStepChangeArgs) => {
      if (currentInnerStep === null) {
        return;
      }

      if (
        currentInnerStep === LoadStepKeys.FINISHED_LOADING ||
        currentInnerStep === LoadStepKeys.LOAD_AGAIN
      ) {
        const newAdsToLoad = adsToLoad.map(
          adToLoad =>
            args.selectedAdsToLoad.find(
              selected => selected.key === adToLoad.key,
            ) || adToLoad,
        );
        dispatch(
          setAdLoadState({
            prop: "review",
            triggerUpdateProp: "adsToLoad",
            data: {
              ...(adLoadReview || {
                selectedRowKeys: [],
                selectedColumnKeys: [],
              }),
              adsToLoad: newAdsToLoad,
            },
          }),
        );
        if (currentInnerStep === LoadStepKeys.LOAD_AGAIN) {
          args.setCurrentProcessStep?.(LoadStepKeys.LOAD_AGAIN);
        }
        return;
      }

      if (currentInnerStep !== LoadStepKeys.LOADING_ADS) return;

      const isLoadingAgain =
        args.currentProcessStep === LoadStepKeys.LOAD_AGAIN;
      const newSelectedAdsToLoad: IAdToLoadData[] = resetAdLoadStatus(
        args.selectedAdsToLoad,
        isLoadingAgain,
      );

      args.setSelectedAdsToLoad?.(newSelectedAdsToLoad);
      args.setCurrentProcessStep?.(LoadStepKeys.UPLOADING_MEDIA);
    },
    [adLoadReview, adsToLoad, currentInnerStep, dispatch],
  );
};
