import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";
import API from "services";
import { GoogleSheetsData } from "shared/types/assetExporter";
import { v4 as uuidv4 } from "uuid";

const getGoogleSheets = (sheetId: string) => async () => {
  const { result, error } = await API.services.assetExporter.getGoogleSheets(
    sheetId,
  );

  if (!!error) throw new Error(error.message);
  if (!result) throw new Error("Unable to get Google Sheets");

  return result;
};

export const useGetGoogleSheet = (
  sheetId: string,
  options?: UseQueryOptions<GoogleSheetsData, Error>,
) => {
  return useQuery<GoogleSheetsData, Error>(
    ["googleSheets", sheetId],
    getGoogleSheets(sheetId),
    {
      ...options,
      enabled: sheetId !== "",
    },
  );
};

export type SheetData = {
  sheetId: string;
  sheetName: string;
};

const postGoogleSheet = async (args: SheetData) => {
  const feedId = uuidv4();
  const createdAt = Date.now();
  const body = {
    ...args,
    feedId,
    createdAt,
  };
  const { result, error } = await API.services.assetExporter.postGoogleSheets(
    body,
  );
  if (!!error) throw new Error(error.message);
  if (!result) throw new Error("Unable to post Google Sheets");

  const filename = `${feedId}_${createdAt}.csv`;
  return filename;
};

export const usePostGoogleSheet = (
  options?: Omit<UseMutationOptions<string, Error, SheetData>, "mutationFn">,
) => {
  return useMutation<string, Error, SheetData>(postGoogleSheet, options);
};
