import { Key, useCallback, useEffect, useMemo, useState } from "react";
import { notification, Modal, Typography, Divider, Space } from "antd";

import MultipleAccountSelect from "shared/components/MultipleAccountSelect";
import AdSelectTable from "../selectStep/AdSelectTable";
import CampaignTree from "../selectStep/CampaignTree";

import {
  setAdLoadState,
  setSelectedFacebookAccounts,
} from "redux/adLibrary/adLibrary.slice";
import { useDispatch } from "react-redux";
import { useAppShallowEqualSelector } from "shared/hooks/useAppSelector";

import moment from "moment";

import {
  returnDealersWithDuplicateAccountIds,
  returnAdLoadDestinationDataAfterAccountRemoval,
} from "../shared/utils";

import {
  IAdLoad,
  IAdLoadDestination,
  IAdLoadHistoryItem,
  AdLoadDestinationData,
} from "shared/types/adLibrary";
import { IAccount, IAccountRecord } from "shared/types/accountManagement";
import { IFacebookAccount } from "screens/adLibrary/facebookUtils/types";

import styles from "../../AdLoadDrawer.module.scss";
import useBuildAdsToCreate from "../shared/hooks/selectStep/useBuildAdsToCreate";
import { useFetchCampaignsToCreate } from "../shared/hooks/selectStep/useFetchCampaignsToCreate";
import { terms } from "utils/terms";
import { TableFiltersSection } from "shared/components/TableFiltersSection";
import { FilterValue } from "antd/lib/table/interface";
import { TagType } from "shared/components/TagList";
import produce from "immer";
import { warningNotification } from "shared/components/customNotification/Notification";
import ChangeFBAccountIDDrawer from "./duplicatedFBAccountsIDs/ChangeFBAccountIDDrawer";
import { dealerToDealerRecordData } from "utils/helpers";

const CreateNewAds = () => {
  const dispatch = useDispatch();
  const [selectedAdsFiltered, setSelectedAdsFiltered] = useState(false);
  const [duplicatedFBIdStore, setDuplicatedFBIdStore] = useState<IAccount>();
  const [duplicatedFBIdStoreRecord, setDuplicatedFBIdStoreRecord] =
    useState<IAccountRecord>();
  const [selectedStores, setSelectedStores] = useState<IAccount[]>([]);
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});
  const [changeFBAccountID, setChangeFBAccountID] = useState(false);

  const {
    adLoadHistory,
    selectedAds = [],
    adLoadDestination,
    selectedAccounts = [],
  } = useAppShallowEqualSelector(({ adLibrary }) => ({
    selectedAds: adLibrary.adLoad.selectedAds,
    adLoadHistory: adLibrary.adLoad.adLoadHistory,
    adLoadDestination: adLibrary.adLoad.destination,
    selectedAccounts: adLibrary.selectedFacebookAccounts,
  }));

  const initialSessionName = `${
    selectedAds?.length ?? 0
  } Ads - ${moment().format("MMM DD")}`;

  const { buildAdsToLoad } = useBuildAdsToCreate();
  useEffect(buildAdsToLoad, [buildAdsToLoad]);

  const { isLoadingCampaigns, isFetchingCampaigns, campaigns } =
    useFetchCampaignsToCreate();

  const isLoadingOrFetching = isLoadingCampaigns || isFetchingCampaigns;

  const updateAdLoadDestinationData = useCallback(
    (
      prop: keyof IAdLoadDestination | null,
      data: AdLoadDestinationData | IAdLoadDestination,
      triggerUpdateProp?: IAdLoad["triggerUpdateProp"],
    ) => {
      const newData: IAdLoadDestination = !prop
        ? (data as IAdLoadDestination)
        : {
            ...(adLoadDestination || {}),
            [prop]: data as AdLoadDestinationData,
          };

      dispatch(
        setAdLoadState({
          prop: "destination",
          data: newData,
          triggerUpdateProp,
        }),
      );
    },
    [adLoadDestination, dispatch],
  );

  const updateAdLoadStateAfterStoreChange = useCallback(
    (foundStoreData: IAccount[], accountToRemove?: IFacebookAccount) => {
      const newSessionTitle = foundStoreData.length
        ? `${foundStoreData
            .map(store => store.dealer_name)
            .join("- ")} - ${initialSessionName}`
        : initialSessionName;

      dispatch(
        setAdLoadState({
          prop: "adLoadHistory",
          data: {
            ...(adLoadHistory || {}),
            title: newSessionTitle,
          } as IAdLoadHistoryItem,
        }),
      );

      const facebookAccounts = foundStoreData.map<IFacebookAccount>(store => ({
        account_status: 1,
        name: store.dealer_name,
        account_id: store.details!.facebook.fbAccountId,
        id: store.details!.facebook.fbAccountId.split("act_")[1],
      }));

      dispatch(setSelectedFacebookAccounts(facebookAccounts));

      updateAdLoadDestinationData(
        null,
        accountToRemove
          ? returnAdLoadDestinationDataAfterAccountRemoval(
              adLoadDestination!,
              accountToRemove,
            )
          : adLoadDestination!,
        "selectedAccounts", // this triggers the session data to be saves
      );
    },
    [
      initialSessionName,
      dispatch,
      adLoadHistory,
      updateAdLoadDestinationData,
      adLoadDestination,
    ],
  );

  const onStoresChange = useCallback(
    (selectedStoreNames: string[], stores: IAccount[]) => {
      const foundStoreData = selectedStoreNames
        .map(storeName =>
          stores?.find(dealer => dealer.dealer_name === storeName),
        )
        .filter((store): store is IAccount => !!store);
      const foundDuplicateIds =
        returnDealersWithDuplicateAccountIds(foundStoreData);
      const dealersWithNoFacebookId = foundStoreData
        .filter(dealer => !dealer.details?.facebook.fbAccountId)
        .map(dealer => dealer.dealer_name);

      if (dealersWithNoFacebookId.length) {
        notification.warning({
          message: "Could not selected store",
          description: "The chosen store does not have Facebook Account ID",
        });
        return;
      }

      if (foundDuplicateIds.length) {
        setSelectedStores(foundStoreData);
        const lastFound = foundStoreData.slice().pop();
        setDuplicatedFBIdStore(lastFound);
        setDuplicatedFBIdStoreRecord(dealerToDealerRecordData(lastFound));

        const description =
          "The Facebook Account ID for " +
          lastFound?.dealer_name +
          " is also being used in another selected store, but each store must have a unique ID.";

        warningNotification({
          messageLabel: description,
          linkText: "Add a unique FB Account ID",
          onClickLink: () => setChangeFBAccountID(true),
          size: "big",
        });
        return;
      }

      // If a store is removed, then the selected account data will be removed
      if (selectedStoreNames.length < (selectedAccounts?.length ?? 0)) {
        const storeToRemove = selectedAccounts?.find(
          account => !selectedStoreNames.includes(account.name),
        );
        if (storeToRemove) {
          Modal.confirm({
            okText: "Yes",
            cancelText: "No",
            title: "Deselect Client",
            content: (
              <Typography.Paragraph style={{ marginBottom: 0 }}>
                Selected destination data for <b>{storeToRemove.name}</b> will
                be removed. Would you like to continue?
              </Typography.Paragraph>
            ),
            onOk: () =>
              updateAdLoadStateAfterStoreChange(foundStoreData, storeToRemove),
          });
          return;
        }
      }

      updateAdLoadStateAfterStoreChange(foundStoreData);
    },
    [selectedAccounts, updateAdLoadStateAfterStoreChange],
  );

  const handleSuccessChangeFBIds = () => {
    let storeResult = selectedStores;
    if (
      duplicatedFBIdStore &&
      !selectedStores.some(
        store => store.dealer_name === duplicatedFBIdStore.dealer_name,
      )
    )
      storeResult.concat(duplicatedFBIdStore);
    updateAdLoadStateAfterStoreChange(storeResult);
  };

  const filteredAds = useMemo(() => {
    return selectedAdsFiltered
      ? selectedAds?.filter(ad =>
          adLoadDestination?.selectedAds?.some(x => x.id == ad.id),
        ) || []
      : selectedAds || [];
  }, [selectedAds, adLoadDestination, selectedAdsFiltered]);

  const tags: TagType[] = Object.entries(tableFilters).reduce<TagType[]>(
    (acc, [key, val]) => {
      return [
        ...acc,
        ...(val?.map(value => ({
          key,
          value,
          displayValue: value.toString(),
        })) || []),
      ];
    },
    [],
  );

  const onRemoveTag = (key: string, value: boolean | Key) => {
    setTableFilters(
      produce(draft => {
        draft[key] = draft[key]?.filter(x => x !== value) || [];
      }),
    );
  };

  return (
    <div className={styles.adLoadDrawerContent}>
      <Space direction="vertical" className={styles.spaceDrawer}>
        <Typography.Text>Select Ads</Typography.Text>
        <TableFiltersSection
          tags={tags}
          clearTags={() => {
            setTableFilters({});
          }}
          removeTag={onRemoveTag}
          onFilterClick={() => setSelectedAdsFiltered(!selectedAdsFiltered)}
          selectedCount={adLoadDestination?.selectedAds?.length || 0}
          displaySelectedOnly={selectedAdsFiltered}
          totalItems={selectedAds?.length || 0}
          currentItems={filteredAds?.length || 0}
        />
        <AdSelectTable
          ads={filteredAds ?? []}
          adLoadDestination={adLoadDestination}
          onSelectionChange={selectedKeys => {
            const selectedAdsForDestination = (selectedAds ?? []).filter(ad =>
              selectedKeys.includes(ad.id),
            );
            updateAdLoadDestinationData(
              "selectedAds",
              selectedAdsForDestination,
            );
            if (selectedKeys.length === 0) {
              setSelectedAdsFiltered(false);
            }
          }}
          onFiltersChange={setTableFilters}
          filters={tableFilters}
        />
      </Space>
      <Divider type="vertical" className={styles.dividerVerticalDrawer} />
      <div className={styles.accountContainer}>
        <Typography.Text>{terms.accountName}</Typography.Text>
        <MultipleAccountSelect
          autoFocus={true}
          style={{ width: "100%" }}
          loading={isLoadingOrFetching}
          disabled={isLoadingOrFetching}
          selectedDealers={selectedAccounts?.map(account => account.name)}
          onChange={onStoresChange}
          filters={{
            enabled: true,
          }}
        />
        <Divider type="horizontal" className={styles.dividerHorizontalDrawer} />
        <Typography.Text>Destination Preview</Typography.Text>
        <CampaignTree
          loading={isLoadingCampaigns}
          ads={selectedAds ?? []}
          campaigns={campaigns ?? []}
          accounts={selectedAccounts ?? []}
          adLoadDestination={adLoadDestination}
          disabled={isLoadingOrFetching || !selectedAccounts?.length}
          updateAdLoadDestinationData={updateAdLoadDestinationData}
        />
      </div>
      {changeFBAccountID && duplicatedFBIdStoreRecord && (
        <ChangeFBAccountIDDrawer
          storeName={duplicatedFBIdStoreRecord.dealerName}
          handleClose={() => {
            setDuplicatedFBIdStore(undefined);
            setChangeFBAccountID(false);
          }}
          handleSuccess={handleSuccessChangeFBIds}
          dealerToEdit={duplicatedFBIdStoreRecord}
          setDealerToEdit={setDuplicatedFBIdStoreRecord}
        />
      )}
    </div>
  );
};

export default CreateNewAds;
