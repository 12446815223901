import { ReactElement, PropsWithChildren, memo } from "react";
import { Modal } from "antd";

interface IModalProps {
  modalAction: string | null;
  visible: boolean;
}
interface IModalHandlers {
  onCancel: () => void;
  onOk: () => void;
}

type PropsType = PropsWithChildren<IModalProps & IModalHandlers>;

const ConfirmModal = ({
  children,
  modalAction,
  visible,
  onCancel,
  onOk,
}: PropsType): ReactElement => {
  return (
    <Modal
      title={`${modalAction} Disclosures`}
      width="300px"
      closable={false}
      visible={visible}
      okText={modalAction}
      onCancel={onCancel}
      onOk={onOk}
    >
      {children}
    </Modal>
  );
};

export default memo(ConfirmModal);
