import {
  isDisclosureGroupLayer,
  isProductGroupLayer,
} from "screens/designStudio/templates/fileDrawer/utils";
import { useFetchDisclosures } from "shared/hooks/admin/useDisclosures";
import {
  ErrorStatus,
  MarketingMaterial,
} from "shared/types/marketingMaterials";
import { MaterialStatus, Template } from "shared/types/salesEnablement";
import { findMatchingCondition } from "utils/helpers.salesEnablement";

export const useMarketingMaterialErrorStatus = () => {
  const { data: disclosures } = useFetchDisclosures();

  const getMarketingMaterialErrorStatus = (
    template: Template,
    material: MarketingMaterial,
  ): ErrorStatus | undefined => {
    const layers = template.files[material.language]?.pages?.flatMap(
      page => page.layers || [],
    );
    const disclosureLayers = layers?.filter(layer =>
      isDisclosureGroupLayer(layer),
    );
    if (!layers || !disclosureLayers?.length) return;

    const productIds = Object.values(material?.fields ?? {}).flatMap(field => {
      if (typeof field !== "string" && field?.type === "product") {
        return field.productsData.flatMap(({ productId }) => productId ?? []);
      }
      return [];
    });
    const productTypes =
      material.productTypeOffer ??
      template.files[material.language]?.pages?.flatMap(
        page =>
          page.layers?.flatMap(layer =>
            isProductGroupLayer(layer) ? layer.productTypes : [],
          ) ?? [],
      ) ??
      [];
    const matchingDisclosures = disclosures
      ?.flatMap(({ fields, runAllConditions }) =>
        findMatchingCondition(
          fields,
          runAllConditions,
          material.locations,
          productIds,
          productTypes,
        ),
      )
      .filter(disclosure => disclosure.then?.[material.language]);

    return !matchingDisclosures?.length
      ? {
          status: MaterialStatus.ERROR,
          message:
            "No disclosures available for the current configuration. Please contact support.",
        }
      : undefined;
  };
  return { getMarketingMaterialErrorStatus };
};
