import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { updateAdLoadReview } from "redux/adLibrary/adLibrary.slice";
import { IAdLoadReview, IAdToLoadData } from "shared/types/adLibrary";

type ModifyAdLoadReviewStateArgs = {
  willSaveJsonData?: boolean;
  prop?: keyof IAdLoadReview;
  data: IAdLoadReview | string[] | IAdToLoadData[];
};

const useModifyAdLoadReviewState = () => {
  const dispatch = useAppDispatch();

  return (args: ModifyAdLoadReviewStateArgs) => {
    dispatch(
      updateAdLoadReview({
        prop: args.prop,
        triggerUpdateProp: args.willSaveJsonData ? "adsToLoad" : undefined,
        data: args.data,
      }),
    );
  };
};

export default useModifyAdLoadReviewState;
