import { useMemo } from "react";

export const useMemoDeepCompare = <T,>(object: T) => {
  /*
    https://github.com/TanStack/query/issues/5137
    useQueries has a reference problem that was fixed in the alpha version of react-query, while we don't update 
    to the alpha version we need to stringify and parse the data to avoid the reference problem
  */
  const stringifiedPages = JSON.stringify(object);

  return useMemo(() => JSON.parse(stringifiedPages), [stringifiedPages]) as T;
};
