import { industryType } from "utils/helpers";
import { AdType } from "screens/adLibrary/facebookUtils/types";
import { QCStatus } from "shared/types/shared";
import { PreferenceKey } from "shared/types/userPreferences";
import { adReviewFieldKeys } from "screens/campaignManagement/fields";

export const adTypes = [
  industryType("all") || industryType("auto") ? AdType.AIA : null,
  industryType("all") || industryType("retail") ? AdType.DPA : null,
  industryType("all") || industryType("retail") || industryType("travel")
    ? AdType.FTA
    : null,
  AdType.Video,
  AdType.Still,
  AdType.Carousel,
  AdType.Collection,
  AdType.InstantExperience,
].filter(Boolean) as AdType[];

export const qcStatus = Object.values(QCStatus);

export type AdFilterLabel =
  | "Ad Name"
  | "Ad Format"
  | "Ad Status"
  | "Ad Set"
  | "Campaign"
  | "CTA"
  | "Destination URL"
  | "UTM"
  | "Copy"
  | "Headline"
  | "News Link Description"
  | "Display URL";

export type AdFilter = {
  label: AdFilterLabel;
  value: (typeof adReviewFieldKeys)[number];
};

export const adFilters: AdFilter[] = [
  { label: "Ad Name", value: "adName" },
  { label: "Ad Format", value: "adFormat" },
  { label: "Ad Status", value: "status" },
  { label: "Ad Set", value: "adSetName" },
  { label: "Campaign", value: "campaignName" },
  { label: "CTA", value: "cta" },
  { label: "Destination URL", value: "destinationUrl" },
  { label: "UTM", value: "utm" },
  { label: "Copy", value: "copy" },
  { label: "Headline", value: "headline" },
  { label: "News Link Description", value: "newsLinkDescription" },
  { label: "Display URL", value: "displayUrl" },
];

export const adFiltersCyAttr: Record<AdFilterLabel, string> = {
  "Ad Name": "ad-name",
  "Ad Format": "ad-format",
  "Ad Status": "ad-status",
  "Ad Set": "ad-set",
  Campaign: "campaign",
  CTA: "cta",
  "Destination URL": "destination-url",
  UTM: "utm",
  Copy: "copy",
  Headline: "headline",
  "News Link Description": "news-link-description",
  "Display URL": "display-url",
};

export const preferencefilters: PreferenceKey[] = [
  "selectedAdFormats",
  "selectedQcStatus",
  "selectedAdName",
  "selectedAdSetName",
  "selectedCampaignName",
  "selectedDestinationUrl",
  "selectedCta",
  "selectedUtm",
  "selectedCopy",
  "selectedHeadline",
  "selectedNewsLinkDescription",
  "selectedDisplayUrl",
];
