import { Button, Col, Drawer, Row, Space, message } from "antd";
import { useCallback, useEffect } from "react";
import { useAdEngineActions } from "redux/assetExporter/assetExporter.slice";
import { PROCESS_NOTIFICATION_KEY } from "shared/constants/assetExporter";
import { useProcessFeed } from "shared/hooks/assetExporter/useProcessFeed";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { useUser } from "shared/hooks/useUser";
import { FeedConfigState, SmartColumn } from "shared/types/assetExporter";
import { processNotification } from "./ProcessNotification";
import { ColumnSelection } from "./exportDrawer/ColumnSelection";
import { ColumnsToExport } from "./exportDrawer/ColumnsToExport";
import { ExportOptions } from "./exportDrawer/ExportOptions";
import { RowSelection } from "./exportDrawer/RowSelection";
import { getFeedProcessBody, isValidFeedBody } from "./exportDrawer/utils";

type Props = {
  smartCols: SmartColumn;
  feedId: string;
  feedConfig: FeedConfigState;
};
export const ExportDrawer = ({ smartCols, feedId, feedConfig }: Props) => {
  const user = useUser();
  const configure = useAppSelector(state => state.assetExporter.configure);
  const {
    exportFor,
    areFtpCredsValid,
    isExportDrawerOpen,
    isExportInProgress,
  } = configure;
  const actions = useAdEngineActions();
  const {
    mutate: processFeed,
    isLoading: isStartingProcess,
    isSuccess: isProcessStarted,
    isError: isProcessError,
    reset: resetMutationState,
  } = useProcessFeed();

  const isFtpSelected = exportFor.includes("FTP");
  const isFtpValid = isFtpSelected && areFtpCredsValid;
  const isNonFtpExportValid = exportFor.length > 0 && !isFtpSelected;
  const isReadyForExport = isFtpSelected ? isFtpValid : isNonFtpExportValid;

  const onExport = () => {
    const body = getFeedProcessBody({ configure, feedId, user, feedConfig });

    if (!isValidFeedBody(body, smartCols)) {
      const errorMsg = `The input/output column configuration is invalid.`;
      message.error(errorMsg);
      return;
    }
    processFeed(body);
    actions.setIsExportByUser(true);
  };

  const closeDrawerOnSuccess = useCallback(() => {
    if (!isStartingProcess && isProcessStarted && isExportDrawerOpen) {
      resetMutationState();
      actions.toggleConfigureDrawer(false);
      processNotification({
        getCsvDownloadUrl: () => {
          return;
        }, // leaving blank as there is no csv to download at this stage
        feedId: "",
        notificationKey: PROCESS_NOTIFICATION_KEY,
        onClose: () => {
          actions.setIsExportByUser(false);
          actions.resetConfigureDrawer();
        },
        message: "Processing Data",
        percentage: 0,
        exportFor,
        onAdLibClick: () => {
          actions.setIsAdLibExportDrawerOpen(true);
        },
      });
    }
  }, [
    actions,
    isProcessStarted,
    isStartingProcess,
    resetMutationState,
    exportFor,
    isExportDrawerOpen,
  ]);

  const displayErrorMsgOnFailure = useCallback(() => {
    if (!isStartingProcess && isProcessError) {
      message.error("Unable to start feed process");
    }
  }, [isProcessError, isStartingProcess]);

  useEffect(closeDrawerOnSuccess, [closeDrawerOnSuccess]);
  useEffect(displayErrorMsgOnFailure, [displayErrorMsgOnFailure]);

  return (
    <Drawer
      visible={isExportDrawerOpen}
      title={<strong>Export Settings</strong>}
      width="60%"
      footer={
        <Space>
          <Button onClick={() => actions.toggleConfigureDrawer(false)}>
            Cancel
          </Button>
          <Button
            type="primary"
            disabled={!isReadyForExport || isExportInProgress}
            loading={isStartingProcess}
            onClick={onExport}
            data-cy="drawer-export-btn"
          >
            Export
          </Button>
        </Space>
      }
      footerStyle={{ display: "flex", justifyContent: "flex-end" }}
      onClose={() => actions.toggleConfigureDrawer(false)}
    >
      <Row>
        <Col span={12}>
          <RowSelection />
          <ColumnSelection />
          <ColumnsToExport />
        </Col>
        <Col span={12}>
          <ExportOptions />
        </Col>
      </Row>
    </Drawer>
  );
};
