import { useCallback, useContext, useEffect } from "react";
import useIEContextAPI from "screens/everythingAds.hooks/useContextAPI";

import ListContainer from "../shared/ListContainer";
import ListItem from "../shared/ListItem";
import { IAd, IInstantExperience } from "shared/types/adLibrary";
import * as utils from "../../../utils";
import { Element } from "screens/everythingAds.hooks/useContextAPI";
import { useStoreActions } from "react-flow-renderer";
import { useFetchInstantExperiences } from "shared/hooks/adLibrary/useFetchInstantExperiences";

const IEList = () => {
  const { Context } = useIEContextAPI();
  const ieContext = useContext(Context);
  const { selectedFlowElement, setSelectedFlowElement, elements } =
    ieContext || {};

  const { isFetching, data } = useFetchInstantExperiences();
  useEffect(() => {
    if (!data) return;

    ieContext?.setInstantExperiences(data);
  }, [data, ieContext]);
  const searchBy = ieContext?.searchBy;

  const onDragStart = useCallback(
    (element: IInstantExperience | IAd) =>
      (e: React.DragEvent<HTMLDivElement>) =>
        utils.onDragStart(e, element),
    [],
  );

  const onClick = useCallback(
    (element: Element) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();

      setSelectedFlowElement?.(
        elements?.find(ele => ele.data?.ie?.id === element.id),
      );
    },
    [elements, setSelectedFlowElement],
  );

  const setSelectedElements = useStoreActions(
    actions => actions.setSelectedElements,
  );
  useEffect(() => {
    if (!selectedFlowElement) return;
    setSelectedElements([selectedFlowElement]);
  }, [selectedFlowElement, setSelectedElements]);

  return (
    <ListContainer isLoading={isFetching}>
      {ieContext?.instantExperiences
        .filter(({ name }) =>
          searchBy
            ? name?.toLowerCase().includes(searchBy?.toLowerCase())
            : true,
        )
        .map(ie => {
          return (
            <ListItem
              key={`ie-assets-list-item-${ie.id}-${ie.name}`}
              element={ie}
              isSelected={ie.id === ieContext.selectedFlowElement?.data?.ie?.id}
              onDragStart={onDragStart}
              onClick={onClick}
            />
          );
        })}
    </ListContainer>
  );
};

export default IEList;
