import { notification } from "antd";

import { useAppDispatch } from "shared/hooks/useAppDispatch";
import * as assetBuilderActions from "redux/assetBuilder/assetBuilder.slice";

import {
  PaymentEngineOfferDataField,
  FeedOfferKeys,
  ParseableOfferData,
} from "shared/types/paymentEngine";

import { OfferData } from "shared/types/assetBuilder";
import { DSOfferType, OfferCalcRow } from "../paymentEngineModal/types";
import { generatePaymentEngineRequestBody } from "utils/helpers.paymentEngine";
import { IAccount } from "shared/types/accountManagement";

type RequestPaymentEngineDataArgs = {
  offerType: DSOfferType;
  dealType: "lease" | "loan";
  offerData: ParseableOfferData;
  checkboxSelectedRows: OfferCalcRow[];
  feedOfferFieldValueEdits: OfferData | null;
  setPreviewRequested: (value: boolean) => void;
  dealerData: IAccount | null;
};

const useRequestPaymentEngineData = ({
  dealType,
  offerData,
  offerType,
  checkboxSelectedRows,
  feedOfferFieldValueEdits,
  setPreviewRequested,
  dealerData,
}: RequestPaymentEngineDataArgs) => {
  const dispatch = useAppDispatch();
  return async () => {
    setPreviewRequested(true);
    const blockSubmit = !checkboxSelectedRows
      .map(row => row.field)
      .every(
        (fieldName: PaymentEngineOfferDataField) =>
          !!feedOfferFieldValueEdits?.[fieldName],
      );
    if (blockSubmit) {
      notification.warning({
        message: "Payment engine update blocked",
        description: "The field value cannot be empty.",
      });
      setPreviewRequested(false);
      return;
    }
    const selectedFields = checkboxSelectedRows.map(
      sRow => sRow.field,
    ) as FeedOfferKeys[];

    const paymentEngineRequestBody = generatePaymentEngineRequestBody(
      offerData,
      offerType,
      feedOfferFieldValueEdits || offerData,
      dealerData,
      dealType,
      selectedFields,
    );
    /* AV2-2049-3: Assign 0 for totalDueAtSigning on Zero-Down lease. Update code if possible
     */
    offerType === "ZERO DOWN LEASE" &&
      Object.assign(paymentEngineRequestBody, {
        paymentParameters: {
          ...paymentEngineRequestBody.paymentParameters,
          upFrontDefinition: {
            amount: 0,
            type: "down",
          },
        },
      });
    dispatch(
      assetBuilderActions.getPaymentEngineData(paymentEngineRequestBody),
    );
  };
};

export default useRequestPaymentEngineData;
