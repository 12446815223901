import { ToolbarButton } from "shared/components/toolbarTable/ToolbarTable";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { usePmaxCampaignsContext } from "../PmaxCampaignsProvider";
import {
  initialFormValues,
  isPastDate,
  toInternalGoogleAdCampaignForm,
} from "./newCampaignDrawer/newCampaignForm/helpers";
import { GoogleAdCampaignInternal } from "shared/types/adLibrary";
import { useMemo, useCallback } from "react";
import { useDeletePmaxCampaigns } from "shared/hooks/adLibrary/pmax/useDeletePmaxCampaign";
import { useCreatePmaxCampaigns } from "shared/hooks/adLibrary/pmax/useCreatePmaxCampaigns";
import { message } from "antd";
import moment from "moment";
import uuid from "uuid";

export const usePmaxCampaignsToolbar = () => {
  const {
    setCampaignDrawer,
    selectedCampaignIds,
    campaigns,
    setLoadDrawerOpen,
  } = usePmaxCampaignsContext();
  const { mutate: deletePmaxCampaigns } = useDeletePmaxCampaigns();
  const { mutate: createCampaigns } = useCreatePmaxCampaigns();

  const selectedCampaigns = useMemo(
    () => campaigns.filter(c => selectedCampaignIds.includes(c.id)),
    [campaigns, selectedCampaignIds],
  );

  const shouldDisableEditAction = useCallback(
    (record?: GoogleAdCampaignInternal) =>
      record ? false : selectedCampaignIds.length !== 1,
    [selectedCampaignIds],
  );

  const shouldDisableDuplicateOrDeleteAction = useCallback(
    (record?: GoogleAdCampaignInternal) =>
      !record && !selectedCampaignIds.length,
    [selectedCampaignIds],
  );

  const generateToolbarContents: (
    record?: GoogleAdCampaignInternal,
  ) => ToolbarButton = record => ({
    New: {
      disabled: false,
      onClick: () => {
        setCampaignDrawer({
          open: true,
          data: initialFormValues,
          assetGroupIds: [],
        });
      },
      extraInfo: {
        text: "New Campaign",
        tooltip: "Create new Google Performance Campaign",
      },
    },
    Edit: {
      disabled: shouldDisableEditAction(record),
      icon: <EditOutlined />,
      showInContextMenu: true,
      showInInlineMenu: true,
      onClick: () => {
        const elementToEdit = record ? record : selectedCampaigns[0];

        if (!elementToEdit) return;
        const { assetGroupIds, form } =
          toInternalGoogleAdCampaignForm(elementToEdit);
        setCampaignDrawer({
          open: true,
          data: form,
          assetGroupIds,
        });
      },
    },
    Duplicate: {
      disabled: shouldDisableDuplicateOrDeleteAction(record),
      showInInlineMenu: true,
      showInContextMenu: true,
      icon: <CopyOutlined />,
      onClick: () => {
        const campaignsToDuplicate = record ? [record] : selectedCampaigns;

        const campaignCopies: GoogleAdCampaignInternal[] =
          campaignsToDuplicate.map(originalCampaign => ({
            ...originalCampaign,
            name: `${originalCampaign.name} - copy`,
            id: uuid(),
          }));

        createCampaigns(campaignCopies, {
          onSettled: data => {
            if (data?.result)
              message.success("Campaigns duplicated successfully");
            else message.error("Error duplicating campaigns");
          },
        });
      },
    },
    Delete: {
      disabled: shouldDisableDuplicateOrDeleteAction(record),
      icon: <DeleteOutlined />,
      onClick: () => {
        const idsToDelete = record ? [record.id] : selectedCampaignIds;
        deletePmaxCampaigns(idsToDelete);
      },
      showInContextMenu: true,
      showInInlineMenu: true,
    },
    Load: {
      disabled: !selectedCampaignIds.length,
      extraInfo: {
        title: "Load to Google Ads",
      },
      icon: <FileDoneOutlined />,
      onClick: () => {
        const campaignsWithPastStartDates = selectedCampaigns.filter(c =>
          isPastDate(moment(c.startDate)),
        );

        if (campaignsWithPastStartDates.length) {
          message.error(
            "Some campaigns have start dates in the past. Please update them and try again.",
          );
          return;
        }
        setLoadDrawerOpen(true);
      },
    },
  });

  const toolbarContents = useMemo(generateToolbarContents, [
    shouldDisableEditAction,
    shouldDisableDuplicateOrDeleteAction,
    setCampaignDrawer,
    selectedCampaigns,
    createCampaigns,
    selectedCampaignIds,
    deletePmaxCampaigns,
    setLoadDrawerOpen,
  ]);
  return {
    generateToolbarContents,
    toolbarContents,
  };
};
