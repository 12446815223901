import { DeleteOutlined } from "@ant-design/icons";
import { Button, Collapse, Select } from "antd";
import { useEffect, useMemo, useState } from "react";

import { stateOptions } from "shared/constants/dataManagement";
import {
  AllPublishedStatus,
  FetchingDataStatus,
  HeaderMenu,
  IArtboard,
  ITemplate,
  ITemplateTag,
  MediaFilterType,
  TabMenu,
  TTemplateType,
} from "shared/types/designStudio";

import { IAccount, IAccountRecord } from "shared/types/accountManagement";
import { IGetBrandsResult, IBrandRecord } from "shared/types/brandManagement";
import "../../../shared/styles/Collapse.scss";
import "./FilterSection.scss";

import classNames from "classnames";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { DecodedValueMap, SetQuery } from "use-query-params";
import actions from "../../../redux/rootActions";
import { LibraryQueryTypes } from "../Library";
import FilterSectionTemplateDimensions from "./filterSection/FilterSectionTemplateDimensions";
import { POLOTNO_EDITOR } from "../designStudioV2/constants";

interface IFilterSection {
  selectedTab: TabMenu;
  selectedHeader: HeaderMenu;

  filterTemplateOem: (filter: string[] | null) => void;
  filterTemplateStore: (filter: string[] | null) => void;
  filterTemplateTags: (tags: string[] | null) => void;
  filterTemplateOfferCounts: (count: string) => void;

  filterStampOem: (filter: string[] | null) => void;
  filterStampState: (filter: string[] | null) => void;
  filterStampDescriptor: (tags: string[] | null) => void;

  tags: ITemplateTag[];

  getOems: (paginationToken?: string) => void;
  getDealers: (paginationToken?: string) => void;

  fetchData: (dataType: FetchingDataStatus, query?: string) => void;

  fetchingData: FetchingDataStatus;
  stores: IAccount[];
  oemRecords: IBrandRecord[];
  oemsResult: null | IGetBrandsResult;

  dealerRecords: IAccountRecord[];
  dealerCount: number;
  paginationKey: string | undefined;

  templates: ITemplate[];

  artboards: IArtboard[];

  filterTemplatesAllPublishedUnpublished: (
    allPublishedUnpublished: AllPublishedStatus,
  ) => void;
  templatesAllPublishedUnpublished: AllPublishedStatus;

  selectedTemplateType?: TTemplateType;
  onSelectedTemplateTypeChange?: (selectedTemplateType?: TTemplateType) => void;

  selectedMediaType?: MediaFilterType;
  onSelectedMediaTypeChange?: (selectedMediaType?: MediaFilterType) => void;

  filterArtboardDimensions: (artboardFilterDimensions: {
    width: number | null;
    height: number | null;
  }) => void;

  filterTemplateDimensions: (templateFilterDimensions: {
    width: number | null;
    height: number | null;
  }) => void;

  libQuery: DecodedValueMap<LibraryQueryTypes>;
  setLibQuery: SetQuery<LibraryQueryTypes>;
}

const FilterSection: React.FC<IFilterSection> = ({
  selectedTab,
  selectedHeader,
  oemRecords,
  filterTemplateOem,
  filterTemplateStore,
  filterTemplateTags,
  filterTemplateOfferCounts,
  tags,

  filterStampOem,
  filterStampState,

  fetchData,
  fetchingData,
  getOems,
  oemsResult,

  templates,
  artboards,

  getDealers,
  dealerRecords,
  dealerCount,
  paginationKey,
  filterTemplatesAllPublishedUnpublished,
  templatesAllPublishedUnpublished,

  filterArtboardDimensions,
  filterTemplateDimensions,

  libQuery,
  setLibQuery,
  ...props
}) => {
  const { search } = useLocation();
  useEffect(() => {
    const { paginationKey: paginationKeyOems, scannedCount } = oemsResult || {
      paginationKey: { oem_name: "" },
      scannedCount: 0,
    };
    if (!paginationKeyOems || oemRecords.length === scannedCount) {
      return;
    }
    if (oemRecords.length > scannedCount) {
      getOems();
    } else {
      getOems(paginationKeyOems.oem_name);
    }
  }, [getOems, oemRecords, oemsResult]);

  useEffect(() => {
    if (!paginationKey || dealerRecords.length >= dealerCount) {
      return;
    }
    getDealers(paginationKey);
  }, [dealerCount, dealerRecords, getDealers, paginationKey]);

  const {
    oem_t: qOemT,
    stores: qStores,
    tags: qtags,
    offer_count: qOffers,
    oem_s: qOemS,
    states: qStates,
  } = libQuery;

  const oemsSet = useMemo(() => qOemT?.split(",") ?? [], [qOemT]);
  const storeSet = useMemo(() => qStores?.split(",") ?? [], [qStores]);
  const tagsSet = useMemo(() => qtags?.split(",") ?? [], [qtags]);
  const offersSet = useMemo(() => qOffers ?? "ALL", [qOffers]);
  const oemsStampsSet = useMemo(() => qOemS?.split(",") ?? [], [qOemS]);
  const stateSet = useMemo(() => qStates?.split(",") ?? [], [qStates]);

  useEffect(() => {
    if (selectedTab === "templates") {
      filterTemplateOem(oemsSet);
      filterTemplateStore(storeSet);
      filterTemplateTags(tagsSet);
      filterTemplateOfferCounts(offersSet);
    } else if (selectedTab === "stamps") {
      filterStampOem(oemsStampsSet);
      filterStampState(stateSet);
    }
  }, [
    filterStampOem,
    filterStampState,
    filterTemplateOem,
    filterTemplateOfferCounts,
    filterTemplateStore,
    filterTemplateTags,
    oemsStampsSet,
    oemsSet,
    offersSet,
    selectedTab,
    stateSet,
    storeSet,
    tagsSet,
  ]);

  const [artboardWidthFilter, setArtboardWidthFilter] = useState<
    number | undefined
  >(undefined);
  const [artboardHeightFilter, setArtboardHeightFilter] = useState<
    number | undefined
  >(undefined);

  const navigate = useNavigate();

  const [artboardWidthsMap, artboardHeightsMap] = useMemo(() => {
    const artboardWidthsMap: Record<number, number[]> = {}; // {str of Widths: heights at that width[]}
    const artboardHeightsMap: Record<number, number[]> = {}; // {str of Widths: heights at that width[]}

    for (const artboard of artboards) {
      if (artboard.width in artboardWidthsMap) {
        artboardWidthsMap[artboard.width].push(artboard.height);
      } else {
        artboardWidthsMap[artboard.width] = [artboard.height];
      }

      if (artboard.height in artboardHeightsMap) {
        artboardHeightsMap[artboard.height].push(artboard.width);
      } else {
        artboardHeightsMap[artboard.height] = [artboard.width];
      }
    }
    return [artboardWidthsMap, artboardHeightsMap];
  }, [artboards]);

  const artboardWidths = artboards.map(artboard => artboard.width);
  const artboardHeights = artboards.map(artboard => artboard.height);

  useEffect(() => {
    filterArtboardDimensions({
      width: artboardWidthFilter || null,
      height: artboardHeightFilter || null,
    });
  }, [artboardWidthFilter, artboardHeightFilter, filterArtboardDimensions]);

  const [templateWidthFilter, setTemplateWidthFilter] = useState<
    number | undefined
  >(undefined);

  const [templateHeightFilter, setTemplateHeightFilter] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    filterTemplateDimensions({
      width: templateWidthFilter || null,
      height: templateHeightFilter || null,
    });
  }, [templateWidthFilter, templateHeightFilter, filterTemplateDimensions]);

  const templateTypes: Array<TTemplateType | "all"> = [
    "all",
    "carcut",
    "html",
    "lifestyle",
  ];
  const templateTypeTitleByType: Record<TTemplateType | "all", string> = {
    all: "All",
    carcut: "Carcut",
    lifestyle: "Lifestyle",
    html: "HTML",
    [POLOTNO_EDITOR]: "V3",
  };
  return (
    <Collapse
      className="collapse-container design-studio-filter-section"
      expandIconPosition="right"
      defaultActiveKey={["filter"]}
    >
      <Collapse.Panel key="filter" header="Filter">
        <div className="filter-section-fields-container">
          {selectedTab === "stamps" && (
            <div style={{ width: "100%" }}>
              <div className="filter-by">
                <div className="title">Filter By OEM</div>
                <div className="filter-oem-options" style={{ padding: "1em" }}>
                  <Select
                    value={oemsStampsSet}
                    onFocus={() => {
                      if (oemRecords.length === 0) {
                        getOems();
                      }
                    }}
                    allowClear={true}
                    mode="multiple"
                    onSelect={(value: string) => {
                      const oemFilter = value;

                      if (!oemsStampsSet.includes(oemFilter)) {
                        const tempArr = [...oemsStampsSet, oemFilter];

                        setLibQuery({ oem_s: tempArr.join(",") }, "replaceIn");

                        filterStampOem(tempArr);
                      }
                    }}
                    onDeselect={(value: string) => {
                      const oemFilter = value;

                      const tempArr = [...oemsStampsSet];

                      const index = tempArr.indexOf(oemFilter);
                      if (index > -1) {
                        tempArr.splice(index, 1);
                      }

                      setLibQuery(
                        { oem_s: tempArr.length ? tempArr.join(",") : null },
                        "replaceIn",
                      );

                      filterStampOem(tempArr);
                    }}
                  >
                    {oemRecords.map(oem => {
                      return (
                        <Select.Option
                          value={oem.oemName}
                          key={`${oem.oemName}-key-${oem.key}`}
                        >
                          {oem.oemName}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
              </div>

              <div className="filter-by">
                <div className="title">Filter By Location</div>
                <div style={{ padding: "1em" }}>
                  <Select
                    key="state-select"
                    style={{ width: "100%" }}
                    placeholder="Select a Location"
                    allowClear={true}
                    mode="multiple"
                    value={stateSet}
                    onSelect={(value: string) => {
                      const stateFilter = value;

                      const tempArr = [...stateSet, stateFilter];

                      setLibQuery({ states: tempArr.join(",") }, "replaceIn");

                      filterStampState(tempArr);
                    }}
                    onDeselect={(value: string) => {
                      const stateFilter = value;

                      const tempArr = [...stateSet];

                      const index = tempArr.indexOf(stateFilter);
                      if (index > -1) {
                        tempArr.splice(index, 1);
                      }

                      setLibQuery(
                        { states: tempArr.length ? tempArr.join(",") : null },
                        "replaceIn",
                      );

                      filterStampState(tempArr);
                    }}
                  >
                    {stateOptions.map((state, index) => (
                      <Select.Option
                        key={`state-select-option-${index}`}
                        value={state}
                      >
                        {state}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>

              <br />
            </div>
          )}
          {selectedTab === "artboards" && (
            <div style={{ width: "100%" }}>
              <div className="filter-by">
                <div className="title">Filter By Artboard Dimensions</div>
                <div>
                  <div
                    className="filter-oem-options"
                    style={{ padding: "1em" }}
                  >
                    Width:
                    <Select
                      showSearch={true}
                      filterOption={true}
                      value={artboardWidthFilter?.toString()}
                      allowClear={true}
                      onChange={value => {
                        if (!value) {
                          setArtboardWidthFilter(undefined);
                          return;
                        }

                        const width = parseInt(value);
                        setArtboardWidthFilter(width);
                      }}
                    >
                      {Array.from(
                        new Set(
                          artboardHeightFilter
                            ? artboardHeightsMap[artboardHeightFilter]
                            : artboardWidths,
                        ),
                      )
                        .sort((a, b) => a - b)
                        .map((width, idx) => {
                          return (
                            <Select.Option
                              key={`${width}-width-key-dimensions-${idx}`}
                              value={width}
                            >
                              {`${width}`}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </div>
                  <div
                    className="filter-oem-options"
                    style={{ padding: "1em" }}
                  >
                    Height:
                    <Select
                      showSearch={true}
                      filterOption={true}
                      value={artboardHeightFilter?.toString()}
                      allowClear={true}
                      onChange={value => {
                        if (!value) {
                          setArtboardHeightFilter(undefined);
                          return;
                        }
                        const height = parseInt(value);
                        setArtboardHeightFilter(height);
                      }}
                    >
                      {Array.from(
                        new Set(
                          artboardWidthFilter
                            ? artboardWidthsMap[artboardWidthFilter]
                            : artboardHeights,
                        ),
                      )
                        .sort((a, b) => a - b)
                        .map((height, idx) => {
                          return (
                            <Select.Option
                              key={`${height}-height-key-dimensions-${idx}`}
                              value={height}
                            >
                              {`${height}`}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </div>
                </div>
              </div>

              <br />
            </div>
          )}

          {selectedTab === "templates" && (
            <div style={{ width: "100%" }}>
              <Button.Group className="publish-status-button-group">
                <Button
                  className={
                    templatesAllPublishedUnpublished === "ALL" ? "focused" : ""
                  }
                  onClick={() => filterTemplatesAllPublishedUnpublished("ALL")}
                >
                  All
                </Button>
                <Button
                  className={
                    templatesAllPublishedUnpublished === "PUBLISHED"
                      ? "focused"
                      : ""
                  }
                  onClick={() =>
                    filterTemplatesAllPublishedUnpublished("PUBLISHED")
                  }
                >
                  Published
                </Button>
                <Button
                  className={
                    templatesAllPublishedUnpublished === "UN-PUBLISHED"
                      ? "focused"
                      : ""
                  }
                  onClick={() =>
                    filterTemplatesAllPublishedUnpublished("UN-PUBLISHED")
                  }
                >
                  Unpublished
                </Button>
              </Button.Group>

              <br />
              <div className="filter-by">
                <div className="title">Filter By Tag</div>
                <div className="options">
                  <Select
                    mode="multiple"
                    onFocus={() => {
                      !tags.length &&
                        fetchingData === null &&
                        fetchData("tags");
                    }}
                    loading={fetchingData === "tags"}
                    style={{ color: "black" }}
                    value={tagsSet}
                    onSelect={value => {
                      const tagFilter = value;

                      let tempArr = [...tagsSet];
                      const index = tempArr.indexOf(tagFilter);
                      index > -1
                        ? tempArr.splice(index, 1)
                        : (tempArr = [...tagsSet, tagFilter]);

                      setLibQuery({ tags: tempArr.join(",") }, "replaceIn");

                      filterTemplateTags(tempArr);
                    }}
                    onDeselect={value => {
                      const tagFilter = value;

                      const tempArr = [...tagsSet];

                      const index = tempArr.indexOf(tagFilter);
                      if (index > -1) {
                        tempArr.splice(index, 1);
                      }

                      setLibQuery(
                        { tags: tempArr.length ? tempArr.join(",") : null },
                        "replaceIn",
                      );

                      filterTemplateTags(tempArr);
                    }}
                  >
                    {tags.map(tag => {
                      return (
                        <Select.Option value={tag.name} key={`${tag.name}-key`}>
                          {tag.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
              </div>

              <div className="filter-by">
                <div className="title">Filter By Store</div>
                <div className="options">
                  <Select
                    mode="multiple"
                    style={{ color: "black" }}
                    value={storeSet}
                    onFocus={() => {
                      !dealerRecords.length && !fetchingData && getDealers();
                    }}
                    onSelect={(value: string) => {
                      const storeFilter = value;

                      if (storeSet.includes(storeFilter)) {
                        return;
                      }

                      const tempArr = [...storeSet, storeFilter];

                      setLibQuery({ stores: tempArr.join(",") }, "replaceIn");

                      filterTemplateStore(tempArr);
                    }}
                    onDeselect={(value: string) => {
                      const storeFilter = value;

                      const tempArr = [...storeSet];

                      const index = tempArr.indexOf(storeFilter);
                      if (index > -1) {
                        tempArr.splice(index, 1);
                      }

                      setLibQuery(
                        { stores: tempArr.length ? tempArr.join(",") : null },
                        "replaceIn",
                      );

                      filterTemplateStore(tempArr);
                    }}
                  >
                    {dealerRecords.map(record => {
                      return (
                        <Select.Option
                          key={`${record.dealerName}-key-${record.dealerUrl}`}
                          value={record.dealerName}
                        >
                          {record.dealerName}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
              </div>

              <div className="filter-by">
                <div className="title">Filter By OEM</div>
                <div className="options">
                  <Select
                    showSearch={true}
                    value={oemsSet}
                    onFocus={() => {
                      !oemRecords.length && getOems();
                    }}
                    allowClear={true}
                    mode="multiple"
                    onSelect={(value: string) => {
                      const oemFilter = value;

                      if (oemsSet.includes(oemFilter)) {
                        return;
                      }

                      const tempArr = [...oemsSet, oemFilter];

                      setLibQuery({ oem_t: tempArr.join(",") }, "replaceIn");

                      filterTemplateOem(tempArr);
                    }}
                    onDeselect={(labeledVal: string) => {
                      const oemFilter = labeledVal;

                      const tempArr = [...oemsSet];

                      const index = tempArr.indexOf(oemFilter);
                      if (index > -1) {
                        tempArr.splice(index, 1);
                      }

                      setLibQuery(
                        { oem_t: tempArr.length ? tempArr.join(",") : null },
                        "replaceIn",
                      );

                      filterTemplateOem(tempArr);
                    }}
                  >
                    {oemRecords.map(oem => {
                      return (
                        <Select.Option
                          value={oem.oemName}
                          key={`${oem.oemName}-key-${oem.key}`}
                        >
                          {oem.oemName}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
              </div>

              <div className="filter-by">
                <div className="title"># of Offers</div>
                <div className="options">
                  <Select
                    showSearch={true}
                    value={offersSet}
                    defaultValue={offersSet || "ALL"}
                    onSelect={(value: string) => {
                      // edit
                      const offerCount = value;

                      setLibQuery({ offer_count: offerCount }, "replaceIn");

                      filterTemplateOfferCounts(offerCount);
                    }}
                  >
                    {["ALL", "1", "2", "3", "4"].map(count => {
                      return (
                        <Select.Option value={count} key={count}>
                          {count}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
              </div>

              <div className="filter-by template-type">
                <div className="title">Filter by Template Type</div>
                <div className="options">
                  <Button.Group
                    className="publish-status-button-group template-type-button-group"
                    size="small"
                  >
                    {templateTypes.map(type => {
                      const focusClassName =
                        (!props.selectedTemplateType && type === "all") ||
                        props.selectedTemplateType === type
                          ? "focused"
                          : "";
                      return (
                        <Button
                          key={`template-type-option-${type}`}
                          className={classNames(type, focusClassName)}
                          onClick={() => {
                            props.onSelectedTemplateTypeChange?.(
                              type === "all" ? undefined : type,
                            );
                          }}
                        >
                          {templateTypeTitleByType[type]}
                        </Button>
                      );
                    })}
                  </Button.Group>
                </div>
              </div>

              <div className="filter-by template-type">
                <div className="title">Filter by Media Type</div>
                <div className="options">
                  <Button.Group
                    className="publish-status-button-group template-type-button-group"
                    size="small"
                  >
                    <Button
                      className={`all-media ${
                        !props.selectedMediaType ? "focused" : ""
                      }`}
                      onClick={() => {
                        props.onSelectedMediaTypeChange?.(undefined);
                      }}
                    >
                      All
                    </Button>
                    <Button
                      className={`image ${
                        props.selectedMediaType === "image" ? "focused" : ""
                      }`}
                      onClick={() => {
                        props.onSelectedMediaTypeChange?.("image");
                      }}
                    >
                      Image
                    </Button>
                    <Button
                      className={`video ${
                        props.selectedMediaType === "video" ? "focused" : ""
                      }`}
                      onClick={() => {
                        props.onSelectedMediaTypeChange?.("video");
                      }}
                    >
                      Video
                    </Button>
                  </Button.Group>
                </div>
              </div>
              <FilterSectionTemplateDimensions
                templateWidthFilter={templateWidthFilter}
                templateHeightFilter={templateHeightFilter}
                setTemplateHeightFilter={setTemplateHeightFilter}
                setTemplateWidthFilter={setTemplateWidthFilter}
                templates={templates}
              />
            </div>
          )}
        </div>
      </Collapse.Panel>

      {["templates", "stamps"].includes(selectedTab) && (
        <Collapse.Panel header="Deleted Assets" key="trash">
          <div
            className="filter-by"
            style={{ width: "100%", padding: "0.7em" }}
          >
            <div className="publish-status-button-group">
              <Button
                className={
                  selectedHeader === "DELETED ASSETS" ? "focused" : undefined
                }
                onClick={() => {
                  navigate({
                    pathname: `/design-studio/deleted/${selectedTab}`,
                    search,
                  });
                }}
              >
                <DeleteOutlined />
                <span>View Items</span>
              </Button>
            </div>
          </div>
        </Collapse.Panel>
      )}
    </Collapse>
  );
};

const mapStateToProps = (state: any) => {
  const { designStudio, oemManagement, dealerManagement } = state;

  const { oemRecords, result } = oemManagement;

  const {
    fetchingData,
    fetchData,
    stores,
    templatesAllPublishedUnpublished,
    artboards,
    selectedFilterDescriptorStamp,
    templates,
  } = designStudio;

  const { dealerRecords, result: getDealersResult } = dealerManagement;

  const { scannedCount: dealerCount, paginationKey } = getDealersResult || {
    scannedCount: 0,
    paginationKey: { dealer_name: undefined },
  };

  return {
    fetchingData,
    fetchData,
    stores,
    oemRecords,
    oemsResult: result,

    dealerRecords,
    dealerCount,
    paginationKey: paginationKey ? paginationKey.dealer_name : undefined,
    templatesAllPublishedUnpublished,
    artboards,
    selectedFilterDescriptorStamp,
    templates,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchData: (dataType: FetchingDataStatus, query = "") => {
      dispatch(actions.designStudio.fetchData(dataType, query));
    },
    getOems: (paginationToken = "") => {
      dispatch(actions.oemManagement.getOems(paginationToken));
    },
    getDealers: (paginationToken = "") => {
      dispatch(actions.dealerManagement.getDealers(paginationToken));
    },
    filterTemplatesAllPublishedUnpublished: (
      allPublishedUnpublished: AllPublishedStatus,
    ) => {
      dispatch(
        actions.designStudio.filterTemplatesAllPublishedUnpublished(
          allPublishedUnpublished,
        ),
      );
    },
    filterArtboardDimensions: (artboardFilterDimensions: {
      width: number | null;
      height: number | null;
    }) => {
      dispatch(
        actions.designStudio.filterArtboardDimensions(artboardFilterDimensions),
      );
    },
    filterTemplateDimensions: (artboardFilterDimensions: {
      width: number | null;
      height: number | null;
    }) => {
      dispatch(
        actions.designStudio.filterTemplateDimensions(artboardFilterDimensions),
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterSection);
