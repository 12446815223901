import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import styles from "./PaginationButton.module.scss";

interface IPaginationButtonFields {
  hasMorePages: boolean;
  isFeedLoading: boolean;
}

interface IPaginationButtonHandlers {
  onClick: () => void;
}

type PaginationButtonProps = IPaginationButtonFields &
  IPaginationButtonHandlers;

export const PaginationButton = ({
  hasMorePages,
  isFeedLoading,
  onClick,
}: PaginationButtonProps) => {
  return (
    <div className={styles.footerContainer}>
      {" "}
      <Button
        onClick={onClick}
        className={styles.loadMoreButton}
        icon={hasMorePages && <PlusOutlined />}
        disabled={isFeedLoading || !hasMorePages}
      >
        {`${hasMorePages ? "Load More..." : "No More Records"}`}
      </Button>
    </div>
  );
};
