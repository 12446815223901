import { FeedTblCol } from "shared/types/assetExporter";
import { COLUMN_ASSET_BATCHES } from "shared/constants/assetExporter";
import { RowType } from "../../shared/types";

export const getMapDataTableData = (feedColumns: FeedTblCol[]) => {
  const columnTitleRows = feedColumns
    .filter(
      feedColumn =>
        typeof feedColumn.title === "string" &&
        !!feedColumn.title &&
        !!feedColumn.key &&
        feedColumn.key !== COLUMN_ASSET_BATCHES,
    )
    .map(feedColumn => ({
      title: typeof feedColumn.title === "string" ? feedColumn.title : "",
      key: feedColumn.key,
      rowType: RowType.Feed,
    }));

  return [
    {
      title: "asset batch",
      key: "asset batch",
      rowType: RowType.AssetBatch,
    },
    {
      title: "asset name",
      key: "asset name",
      rowType: RowType.AssetName,
    },
    { title: "asset", key: "asset", rowType: RowType.Media },
    ...columnTitleRows,
  ];
};
