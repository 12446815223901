import { Table } from "antd";
import classNames from "classnames";
import { useTableActions } from "./useTableActions";
import styles from "./HoverActionsVirtualTable.module.scss";
import VirtualTable, { VirtualTableProps } from "../VirtualTable";

type Props<RecordType> = VirtualTableProps<RecordType> & {
  isNested?: boolean;
  disableVirtualization?: boolean;
};

export function HoverActionsVirtualTable<
  RecordType extends Record<string, any>,
>({ disableVirtualization, ...props }: Props<RecordType>) {
  const {
    inlineActionsRef,
    contextActionsRef,
    onMouseEnter,
    onMouseLeave,
    onContextMenu,
    renderContextActions,
    renderHoverActions,
    hoverActionsOpen,
    contextActionsOpen,
  } = useTableActions(!!props.isNested);

  return (
    <>
      <div
        ref={inlineActionsRef}
        className={styles.inlineActions}
        style={{ position: props.isNested ? "absolute" : "fixed" }}
      >
        {hoverActionsOpen && renderHoverActions()}
      </div>
      <div ref={contextActionsRef} className={styles.contextActions}>
        {contextActionsOpen && renderContextActions()}
      </div>
      {disableVirtualization ? (
        <Table<RecordType>
          {...props}
          dataSource={props.dataSource}
          className={classNames(props.className)}
          pagination={false}
        />
      ) : (
        <VirtualTable
          {...props}
          onRow={record => {
            return {
              onContextMenu: event => onContextMenu(event, record), // right button click row
              onMouseEnter: event => onMouseEnter(event, record),
              onMouseLeave: event => onMouseLeave(event),
            };
          }}
        />
      )}
    </>
  );
}
