import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetFoldersListQuery } from "redux/media/media.api";
import { useQPBreadcrumb } from "./useQPBreadcrumb";

export const useActiveFolders = () => {
  const { stack, current } = useQPBreadcrumb();
  const { data } = useGetFoldersListQuery(stack.length ? stack : skipToken);

  return {
    stack: stack.flatMap(id => data?.[id] ?? []),
    current: data?.[current],
  };
};
