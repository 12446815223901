import { useMutation, useQueryClient } from "react-query";
import API from "services";
import { IAssetGroup } from "shared/types/adLibrary";

export const useUpdateAssetGroupStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (assetGroup: Partial<IAssetGroup>) =>
      API.services.adLibrary.updateAssetGroupStatus(assetGroup),
    {
      onSettled() {
        queryClient.invalidateQueries("asset-groups");
      },
    },
  );
};
