import { IDimension } from "shared/types/designStudio";

import "./CardLabel.scss";

export type CardLabel = {
  title: string;
  position: {
    x: number;
    y: number;
  };
  dimension: IDimension;
};

type CardLabelProps = CardLabel;

const CardToggle = (props: CardLabelProps) => {
  return (
    <div
      className="card-label"
      style={{
        left: props.position.x,
        top: props.position.y,
        width: props.dimension.width,
        height: props.dimension.height,
      }}
    >
      <div className="card-label-content">
        <div className="card-label-title">{props.title}</div>
      </div>
    </div>
  );
};

export default CardToggle;
