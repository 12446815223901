import API from "services";
import { useQuery } from "react-query";
import { pmaxCampaignsQueryKey } from "shared/hooks/adLibrary/pmax/constants";
import { usePmaxCampaignsData } from "screens/adLibrary/shared/hooks/dataListHooks";

export const useGetPmaxCampaigns = () => {
  const { data, isLoading, isError } = useQuery(pmaxCampaignsQueryKey, () =>
    API.services.adLibrary.getPmaxCampaigns(),
  );

  return usePmaxCampaignsData({
    data: data?.result || [],
    isLoading,
    isError,
  });
};
