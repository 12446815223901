import { useEffect, useMemo, useState } from "react";
import {
  getCAMThumbnail,
  useCAMHook,
  useCAMSearch,
} from "shared/components/media";
import { useFetchOem } from "shared/hooks/useFetchOems";
import { IAccount } from "shared/types/accountManagement";

type Props = {
  dealers: IAccount[];
  originalCanvas: fabric.Canvas | null;
  selectedAccountName?: string;
  selectedBrand?: string;
};

export const useLogos = ({
  dealers,
  originalCanvas,
  selectedAccountName,
  selectedBrand,
}: Props) => {
  const searchMedia = useCAMHook(useCAMSearch)();
  const { data: oemData } = useFetchOem(selectedBrand || "");
  const [oemLogos, setOemLogos] = useState<string[]>([]);
  const [storeLogos, setStoreLogos] = useState<string[]>([]);
  const [eventLogos, setEventLogos] = useState<string[]>([]);
  const dealer = useMemo(
    () => dealers.find(dealer => dealer.dealer_name === selectedAccountName),
    [dealers, selectedAccountName],
  );

  // WebDAM logos
  useEffect(() => {
    if (searchMedia && dealer) {
      const oemLogos = searchMedia({
        filters: {
          fields: [
            {
              field_name: "make",
              filter_type: "exact",
              value: dealer.dealer_oem,
              operator: "AND",
            },
            {
              field_name: "keyword",
              filter_type: "exact",
              value: ["logo"],
              operator: "AND",
            },
          ],
        },
      });

      const eventLogos = searchMedia({
        filters: {
          fields: [
            {
              field_name: "make",
              filter_type: "exact",
              value: dealer.dealer_oem,
              operator: "AND",
            },
            {
              field_name: "keyword",
              filter_type: "exact",
              value: ["logo", "event"],
              operator: "AND",
            },
          ],
        },
      });

      const storeLogos = searchMedia({
        filters: {
          fields: [
            {
              field_name: "store_233",
              filter_type: "exact",
              value: dealer.dealer_code,
              operator: "AND",
            },
            {
              field_name: "keyword",
              filter_type: "exact",
              value: ["logo"],
              operator: "AND",
            },
          ],
        },
      });

      Promise.all([oemLogos, eventLogos, storeLogos])
        .then(arr => {
          return arr.map(data => {
            return data.items.flatMap(asset => {
              const thumb = getCAMThumbnail(asset);
              return thumb?.url ?? [];
            });
          });
        })
        .then(([oemUrls, eventUrls, storeUrls]) => {
          setOemLogos(oemUrls);
          setStoreLogos(storeUrls);
          setEventLogos(eventUrls);
        });
    }
  }, [dealer, searchMedia]);

  // Legacy logos
  useEffect(() => {
    if (!searchMedia && dealer && originalCanvas) {
      const dealerLogos: any = dealer["logo_urls_from_S3"];
      const oemLogos = oemData
        ? JSON.parse((oemData as any).oem["logo_urls_from_S3"])
        : [];

      const filtered = originalCanvas
        .getObjects()
        ?.filter(obj => obj.type === "group")
        .map(obj => (obj as any).customData);

      for (const data of filtered) {
        switch (data.logoEventType) {
          case "STORE_LOGO":
          case "ACCOUNT_LOGO":
            setStoreLogos(
              dealerLogos[`${data.logoDropZoneType}ImagesFromS3`] || [],
            );
            break;

          case "SALES_EVENT_LOGO":
            setEventLogos(
              oemLogos[`${data.logoDropZoneType}EventImagesFromS3`] || [],
            );
            break;
          case "BRAND_LOGO":
          case "OEM_LOGO":
            setOemLogos(oemLogos[`${data.logoDropZoneType}ImagesFromS3`] || []);
            break;
        }
      }
    }
  }, [originalCanvas, dealer, oemData, searchMedia]);

  return { oemLogos, storeLogos, eventLogos };
};
