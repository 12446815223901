/* eslint-disable react/display-name */
import { CalendarOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { Link } from "react-router-dom";
import { ITableColumn, useColumn } from "shared/components/dataList";
import { DateFilterDropdown } from "shared/components/DateFilterDropdown";
import { SearchFilterDropdown } from "shared/components/SearchFilterDropdown";
import { renderTags } from "shared/components/TagListOverflow";
import { dateFormat } from "shared/constants/dataManagement";
import { IPublicDashboard } from "shared/types/dashboardManagement";

export const useDisplayColumns = () => {
  const columns: ITableColumn<IPublicDashboard>[] = [
    useColumn<IPublicDashboard>({
      key: "dashboard",
      title: "Dashboard",
      dataIndex: ["dashboard", "title"],
      render: (value: any, record: IPublicDashboard) => (
        <Link to={`/metrics/detail/${record.dashboardId}`}>{value}</Link>
      ),
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 120,
    }),
    useColumn<IPublicDashboard>({
      key: "link",
      title: "Link",
      dataIndex: ["id"],
      render: (id: string) => {
        const url = `${window.location.origin.replace(
          /\/$/,
          "",
        )}/metrics/share/${id}`;
        return <Link to={`/metrics/share/${id}`}>{url}</Link>;
      },
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 330,
    }),
    useColumn<IPublicDashboard>({
      key: "password",
      title: "Password",
      dataIndex: ["isProtected"],
      render: (isProtected: boolean) => (isProtected ? "Yes" : "No"),
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 60,
    }),
    useColumn<IPublicDashboard>({
      key: "createdAt",
      title: "Date Created",
      dataIndex: ["createdAt"],
      render: (createdAt: number | undefined) => {
        return createdAt ? moment(createdAt).format(dateFormat) : "";
      },
      filterDropdown: DateFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <CalendarOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 120,
    }),
    useColumn<IPublicDashboard>({
      key: "expirationDate",
      title: "Expiration Date",
      dataIndex: ["expirationDate"],
      render: (expirationDate: number | undefined) => {
        return expirationDate ? moment(expirationDate).format(dateFormat) : "";
      },
      filterDropdown: DateFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <CalendarOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 120,
    }),
    useColumn<IPublicDashboard>({
      key: "createdBy",
      title: "Created By",
      dataIndex: ["createdBy"],
      render: (createdBy: string | undefined) => {
        return createdBy ?? "";
      },
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 220,
    }),
    useColumn<IPublicDashboard>({
      key: "oem",
      title: "Brand",
      dataIndex: ["oem"],
      render: renderTags,
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 120,
    }),
    useColumn<IPublicDashboard>({
      key: "account",
      title: "Account",
      dataIndex: ["account"],
      render: renderTags,
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 120,
    }),
    useColumn<IPublicDashboard>({
      key: "campaign",
      title: "Campaign",
      dataIndex: ["specialCampaign"],
      render: renderTags,
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 120,
    }),
    useColumn<IPublicDashboard>({
      key: "date",
      title: "Date",
      dataIndex: ["dateValueSelection"],
      render: (sel?: string) => {
        return sel ? JSON.parse(sel)?.["inputValue"] : "";
      },
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      width: 120,
    }),
  ];

  return { columns };
};
