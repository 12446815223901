import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, message, notification } from "antd";
import API from "services";
import { getErrorMessage } from "utils/errorMessage";
import { fbLogin } from "utils/facebook/facebookSDK";
import { searchNestedObject } from "utils/helpers";

export const interceptResponse = async (
  _: Response,
  data: any,
  isAdmin = false,
) => {
  const showFacebookAccessNotification = () => {
    const notificationKey = "connectionError";

    const onClose = () => {
      notification.close(notificationKey);
    };

    const handleClick = () => {
      fbLogin(async shortTermToken => {
        try {
          if (!shortTermToken) return;

          await API.services.platformManagement.createToken({
            platform: "facebook",
            shortTermToken,
          });

          window.location.reload();
        } catch (error) {
          message.error(getErrorMessage(error));
        }
      });
    };

    notification.open({
      key: notificationKey,
      message: "Could not connect to Facebook",
      icon: <CloseCircleOutlined style={{ color: "#FF4D4F" }} />,
      placement: "bottomRight",
      duration: 0,
      bottom: 50,
      description: (
        <>
          <p>This account seems not connected to Facebook.</p>
          <div
            style={{ display: "flex", justifyContent: "end", gap: "0.5rem" }}
          >
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={handleClick} type="primary">
              Grant Access
            </Button>
          </div>
        </>
      ),
    });
  };

  const showContactAdmnistratorNotification = () => {
    const notificationKey = "contactAdministrator";

    const onClose = () => {
      notification.close(notificationKey);
    };

    notification.open({
      key: notificationKey,
      message: "Could not connect to Facebook",
      icon: <CloseCircleOutlined style={{ color: "#FF4D4F" }} />,
      placement: "bottomRight",
      duration: 0,
      bottom: 50,
      description:
        "Your account is missing authentication permissions. Please contact an admin user for assistance.",
      btn: (
        <Button type="primary" onClick={onClose}>
          Ok
        </Button>
      ),
    });
  };

  if (
    searchNestedObject(
      data,
      "Invalid OAuth access token - Cannot parse access token",
    ) ||
    searchNestedObject(
      data,
      "An access token is required to request this resource.",
    )
  ) {
    isAdmin
      ? showFacebookAccessNotification()
      : showContactAdmnistratorNotification();
  }
};
