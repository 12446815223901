import { getEnvVar } from "utils/helpers";

export const initGapiClient = ({
  discoveryDocs,
}: {
  discoveryDocs: string[];
}) => {
  gapi.load("client", () => {
    gapi.client.init({
      discoveryDocs,
    });
  });
};

export const getGsiClient = (scope: string) => {
  const clientId = getEnvVar("GOOGLE_API_CLIENT_ID");
  if (!clientId) {
    throw Error("No Google Client ID found");
  }
  return google.accounts.oauth2.initTokenClient({
    client_id: clientId,
    scope,
    callback: () => {},
  });
};

export interface TokenClientWithCallback
  extends google.accounts.oauth2.TokenClient {
  callback: (client: google.accounts.oauth2.TokenResponse) => void;
}

export const authGoogleUser = async (
  client: TokenClientWithCallback,
  callback: () => void,
) => {
  const tokenResponse = await new Promise<google.accounts.oauth2.TokenResponse>(
    (resolve, reject) => {
      const focusEventHandler = () => {
        reject({
          error: "client_focused_back_to_window",
        });
        window.removeEventListener("focus", focusEventHandler);
      };
      // Adding an event listener to detect if user focuses back to window, if so, close the current auth session.
      // https://stackoverflow.com/a/73485415
      window.addEventListener("focus", focusEventHandler);

      client.callback = tokenResponse => {
        if (tokenResponse.error) {
          reject(tokenResponse);
        }
        if (tokenResponse && tokenResponse.access_token) {
          gapi.auth.setToken(tokenResponse);
          callback();
        }
        resolve(tokenResponse);
      };
      client.requestAccessToken();
    },
  );
  return tokenResponse;
};

export const sendToGoogleSheets = async ({
  columns,
  data,
  filename,
  handleSuccess,
}: {
  columns: string[];
  data: Record<string, string>[];
  filename: string;
  handleSuccess: (spreadsheetUrl: string) => void | undefined;
}) => {
  const headerData = [
    {
      values: columns.map(col => ({
        userEnteredValue: {
          stringValue: col,
        },
      })),
    },
  ];
  const rowData = data.map(obj => ({
    values: Object.values(obj).map(val => ({
      userEnteredValue: {
        stringValue: val,
      },
    })),
  }));
  const sheet = {
    data: [
      {
        rowData: [...headerData, ...rowData],
      },
    ],
  };
  const spreadsheet = {
    sheets: [sheet],
    properties: {
      title: filename,
    },
  };
  const response = await gapi.client.sheets.spreadsheets.create(
    {},
    spreadsheet,
  );

  const doc = JSON.parse(response.body);

  handleSuccess?.(doc.spreadsheetUrl);
};
