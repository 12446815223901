import { useFetchProductSets } from "shared/hooks/adLibrary/facebook/useFetchProductSets";
import { AdLoadReviewTableEntryProductSet } from "../../AdLoadReviewTable";

type Props = {
  productSetEntries: AdLoadReviewTableEntryProductSet[];
  showProductCount?: boolean;
};

const ProductSetName = ({ productSetEntries, showProductCount }: Props) => {
  const count = productSetEntries.length;
  const productCatalogId =
    count === 1 ? productSetEntries[0].productCatalogId : "";

  const { isLoading: isLoadingProductSets, data: productSets = [] } =
    useFetchProductSets({
      productCatalogId: productCatalogId ?? "",
    });

  if (isLoadingProductSets) return <>Loading...</>;

  if (count > 1) {
    return <>Multiple ({count})</>;
  }

  const selectedProductSet = productSets.find(
    productSet => productSet.id === productSetEntries[0].productSetId,
  );

  return (
    <>
      {selectedProductSet?.name}
      {showProductCount ? ` (${selectedProductSet?.product_count})` : null}
    </>
  );
};

export default ProductSetName;
