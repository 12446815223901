import { FC, memo, useEffect, useMemo } from "react";
import { Collapse, Empty, Spin } from "antd";

import { shallowEqual, useSelector } from "react-redux";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import actions from "../../../redux/rootActions";

import { orderBy } from "lodash";

import StampCardListContainer from "./stampList/StampCardListContainer";

import {
  HeaderMenu,
  IDesignStudioState,
  IStamp,
  TabMenu,
} from "shared/types/designStudio";

import "./StampList.scss";

interface IStampList {
  activeKey: string;
  selectedTab: TabMenu;
  selectedHeader: HeaderMenu;
  setActiveKey: (input: string) => void;
}

const StampList: FC<IStampList> = ({
  activeKey,
  selectedTab,
  selectedHeader,
  setActiveKey,
}) => {
  const {
    updatingData,
    fetchingData,
    stamps,
    selectedFilterOemStamp,
    selectedFilterStateStamp,
    searchInput,
  } = useSelector(
    ({ designStudio }: { designStudio: IDesignStudioState }) => ({
      updatingData: designStudio.updatingData,
      fetchingData: designStudio.fetchingData,
      stamps: designStudio.stamps,
      selectedFilterOemStamp: designStudio.selectedFilterOemStamp,
      selectedFilterStateStamp: designStudio.selectedFilterStateStamp,
      searchInput: designStudio.searchInput,
    }),
    shallowEqual,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      actions.designStudio.fetchData(
        "stamps",
        selectedHeader === "DELETED ASSETS" ? "isDeleted=true" : undefined,
      ),
    );

    // eslint-disable-next-line
  }, [selectedHeader, updatingData]);

  // collect all unique oem from each stamp
  const stampOemSet = useMemo(() => {
    return stamps.reduce((acc, stamp) => {
      (stamp.oems ? stamp.oems : [stamp.oem as string]).forEach(oem => {
        acc.add(oem);
      });

      return acc;
    }, new Set<string>());
  }, [stamps]);
  const sortedOems = orderBy(Array.from(stampOemSet), oem => oem, ["asc"]);

  // group stamps by its oem
  const stampsByOem: Record<string, IStamp[]> = {};
  const applyOemFilter =
    !!selectedFilterOemStamp && selectedFilterOemStamp.length > 0;
  const applyStateFilter =
    !!selectedFilterStateStamp && selectedFilterStateStamp.length > 0;

  (stampOemSet || []).forEach(oem => {
    const oemExists = !!stampsByOem[oem];

    // find all stamps that contains this "oem"
    // also apply the selected filters)

    const stampsForOem = stamps
      .filter(stamp => {
        // apply selected filters
        const stampOems = stamp.oems ? stamp.oems : [stamp.oem];
        if (!applyOemFilter && !applyStateFilter) {
          return stampOems.includes(oem);
        } else if (applyOemFilter && !applyStateFilter) {
          return (
            stampOems.includes(oem) &&
            selectedFilterOemStamp!.filter(filteredOem =>
              stampOems.includes(filteredOem),
            ).length > 0
          );
        } else if (!applyOemFilter && applyStateFilter) {
          return (
            stampOems.includes(oem) &&
            selectedFilterStateStamp?.includes(stamp.state)
          );
        } else {
          // apply both filters
          // filter by oem first

          if (
            stampOems.includes(oem) &&
            selectedFilterOemStamp!.filter(filteredOem =>
              stampOems.includes(filteredOem),
            ).length > 0 &&
            selectedFilterStateStamp?.includes(stamp.state)
          ) {
            return true;
          }
        }
      })
      // apply searchInput filter is searchInput has value... otherwise, return all stamp
      .filter(stamp =>
        searchInput.trim()
          ? stamp.name.toLowerCase().includes(searchInput.trim().toLowerCase())
          : true,
      );

    stampsByOem[oem] = oemExists
      ? [...stampsByOem[oem], ...stampsForOem]
      : stampsForOem;
  });

  const isFetchingStamps = fetchingData === "stamps";
  const isStampsEmpty = !isFetchingStamps && stamps?.length === 0;
  const isStampsByOemEmpty =
    !isStampsEmpty && !sortedOems.filter(oem => stampsByOem[oem].length).length;

  return (
    <>
      {isFetchingStamps ? (
        <div className="loader">
          <Spin className="spinner" size="large" />
        </div>
      ) : isStampsEmpty ? (
        <Empty className="empty" image={Empty.PRESENTED_IMAGE_DEFAULT} />
      ) : isStampsByOemEmpty ? (
        <Empty
          className="empty"
          description="No Stamps Found"
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      ) : (
        <Collapse
          className="collapse-container stamp-list"
          activeKey={activeKey}
        >
          {sortedOems
            .filter(oem => stampsByOem[oem].length > 0)
            .map(oem => {
              const groupedById = stampsByOem[oem].reduce((acc, stamp) => {
                if (acc[stamp.id]) {
                  return acc;
                }

                acc[stamp.id] = stamp;

                return acc;
              }, {} as { [key: string]: IStamp });

              const finalStamps = Object.values(groupedById)
                .reduce((acc, stamp) => {
                  return [...acc, stamp];
                }, [] as IStamp[])
                .filter(stamp =>
                  searchInput.trim()
                    ? stamp.name
                        .toLowerCase()
                        .includes(searchInput.trim().toLowerCase())
                    : true,
                );

              const sortedStamps = orderBy(finalStamps, "name");

              const numberOfStamps = finalStamps.length;

              return (
                <Collapse.Panel
                  key={`stamp-list-panel-${oem}`}
                  className={`stamp-list-panel-${oem}`}
                  header={
                    <div
                      data-cy="stamp-list-collapse"
                      className="stamp-list-collapse"
                      onClick={() => {
                        activeKey === `stamp-list-panel-${oem}`
                          ? setActiveKey("")
                          : setActiveKey(`stamp-list-panel-${oem}`);
                      }}
                    >
                      <span className={`title ${oem}`}>{oem}</span>
                      <span
                        className={`number-counter ${oem}`}
                        data-value={numberOfStamps}
                      >
                        {numberOfStamps}
                      </span>
                    </div>
                  }
                >
                  <StampCardListContainer
                    oem={oem}
                    stamps={sortedStamps}
                    selectedHeader={selectedHeader}
                    selectedFilterOemStamp={selectedFilterOemStamp}
                    selectedFilterStateStamp={selectedFilterStateStamp}
                    searchInput={selectedTab === "stamps" ? searchInput : ""}
                  />
                </Collapse.Panel>
              );
            })}
        </Collapse>
      )}
    </>
  );
};

export default memo(StampList);
