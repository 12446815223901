import { Button } from "antd";
import { PropsWithChildren } from "react";
import { useSetCurrentSession } from "screens/adLibrary/adLoadV2/hooks/useCurrentSession";
import { AdLoadStep } from "screens/adLibrary/facebookUtils/adProto.types";

type Props = PropsWithChildren<{
  step: AdLoadStep;
}>;

const LinkToStep = ({ children, step }: Props) => {
  const { setSession } = useSetCurrentSession();

  return (
    <Button
      type="link"
      onClick={() => {
        setSession(prevSession => {
          prevSession.data.step = step;
        });
      }}
    >
      {children}
    </Button>
  );
};

export default LinkToStep;
