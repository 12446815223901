import { Button, Layout, Space, Typography } from "antd";
import { NavLink } from "react-router-dom";
import {
  NumberParam,
  StringParam,
  useQueryParam,
  useQueryParams,
} from "use-query-params";
const { Title, Text } = Typography;

export const NavigateTest = () => {
  const [hash, setHash] = useQueryParam("hash", StringParam);
  const [query, setQuery] = useQueryParams({
    num: NumberParam,
    str: StringParam,
  });

  const words = ["cool", "epic", "crazy", "fun", "hello", "slick", "sweet"];
  const randomNum = () => Math.floor(Math.random() * 10000);
  const randomStr = () => words[Math.floor(Math.random() * words.length)];
  const randomHash = () => btoa(Math.random().toString().substring(0, 5));

  const { num, str } = query;

  return (
    <Layout.Content style={{ padding: 50 }}>
      <Typography>
        <Title>Query Params Test</Title>
        <Space direction="vertical" size="middle">
          <Text>
            num: <Text strong>{num}</Text>
          </Text>
          <Space>
            <Button onClick={() => setQuery({ num: randomNum() })}>
              Push num
            </Button>
            <Button onClick={() => setQuery({ num: randomNum() }, "pushIn")}>
              PushIn num
            </Button>
            <Button onClick={() => setQuery({ num: randomNum() }, "replace")}>
              Replace num
            </Button>
            <Button onClick={() => setQuery({ num: randomNum() }, "replaceIn")}>
              ReplaceIn num
            </Button>
          </Space>
          <Text>
            str: <Text strong>{str}</Text>
          </Text>
          <Space>
            <Button onClick={() => setQuery({ str: randomStr() })}>
              Push str
            </Button>
            <Button onClick={() => setQuery({ str: randomStr() }, "pushIn")}>
              PushIn str
            </Button>
            <Button onClick={() => setQuery({ str: randomStr() }, "replace")}>
              Replace str
            </Button>
            <Button onClick={() => setQuery({ str: randomStr() }, "replaceIn")}>
              ReplaceIn str
            </Button>
          </Space>
          <Text>
            hash: <Text strong>{hash}</Text>
          </Text>
          <Space>
            <Button onClick={() => setHash(randomHash())}>Push hash</Button>
            <Button onClick={() => setHash(randomHash(), "pushIn")}>
              PushIn hash
            </Button>
            <Button onClick={() => setHash(randomHash(), "replace")}>
              Replace hash
            </Button>
            <Button onClick={() => setHash(randomHash(), "replaceIn")}>
              ReplaceIn hash
            </Button>
          </Space>
          <Button
            onClick={() => {
              setQuery({ num: null, str: null });
              setHash(null);
            }}
          >
            Clear all
          </Button>
        </Space>
        <Title>Navigation Test</Title>
        <Space direction="vertical" size="middle">
          <NavLink
            style={({ isActive }) =>
              isActive ? { fontWeight: "bold" } : undefined
            }
            to="/navtest"
            end
          >
            Push Nav Test
          </NavLink>
          <NavLink
            style={({ isActive }) =>
              isActive ? { fontWeight: "bold" } : undefined
            }
            to="/navtest"
            replace
            end
          >
            Replace Nav Test
          </NavLink>
          <NavLink
            style={({ isActive }) =>
              isActive ? { fontWeight: "bold" } : undefined
            }
            to="/navtest/page1"
          >
            Push Page 1
          </NavLink>
          <NavLink
            style={({ isActive }) =>
              isActive ? { fontWeight: "bold" } : undefined
            }
            to="/navtest/page1"
            replace
          >
            Replace Page 1
          </NavLink>
          <NavLink
            style={({ isActive }) =>
              isActive ? { fontWeight: "bold" } : undefined
            }
            to="/navtest/page2"
          >
            Push Page 2
          </NavLink>
          <NavLink
            style={({ isActive }) =>
              isActive ? { fontWeight: "bold" } : undefined
            }
            to="/navtest/page2"
            replace
          >
            Replace Page 2
          </NavLink>
          <NavLink
            style={({ isActive }) =>
              isActive ? { fontWeight: "bold" } : undefined
            }
            to="/navtest/page3"
          >
            Push Page 3
          </NavLink>
          <NavLink
            style={({ isActive }) =>
              isActive ? { fontWeight: "bold" } : undefined
            }
            to="/navtest/page3"
            replace
          >
            Replace Page 3
          </NavLink>
        </Space>
      </Typography>
    </Layout.Content>
  );
};
