import { useCallback, useEffect, useState } from "react";
import API from "services";
import {
  getCAMThumbnail,
  useCAMHook,
  useCAMSearch,
} from "shared/components/media";
import { OfferData } from "shared/types/assetBuilder";

type Jellybean = {
  id: string;
  name: string;
  url: string;
};

export const useJellybeans = (offerData?: OfferData) => {
  const searchMedia = useCAMHook(useCAMSearch)();
  const [jellybeans, setJellybeanImages] = useState<Jellybean[]>();

  const fetchJellybeans = useCallback(
    ({ year, make, model }: OfferData): Promise<Jellybean[]> => {
      const params = {
        year: year.trim(),
        make: make.trim(),
        model: model.trim(),
      };
      // WebDAM jellybeans
      if (searchMedia) {
        return searchMedia(params).then((data: WDSearchResult) => {
          return data.items.flatMap(asset => {
            const thumb = getCAMThumbnail(asset);
            if (thumb) {
              return {
                id: asset.id,
                name: asset.name,
                url: thumb.url,
              };
            }
            return [];
          });
        });
      }

      // Legacy jellybeans
      return API.services.assetBuilder
        .getJellybeanImages(params)
        .then(({ result }: any) => {
          return result.map(({ url }: { url: string }) => ({
            url,
            id: url,
            name: url.split("/")[4].split(".")[0].split("_").join(" "),
          }));
        });
    },
    [searchMedia],
  );

  useEffect(() => {
    if (offerData) {
      fetchJellybeans(offerData).then(setJellybeanImages);
    }
  }, [fetchJellybeans, offerData]);

  return { jellybeans };
};
