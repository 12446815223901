import moment from "moment";
import { FacebookAdEffectiveStatus } from "screens/adLibrary/facebookUtils/types";
import { AdReviewField } from "shared/types/adReview";
import { DelimitedArrayParam, StringParam } from "use-query-params";
import {
  checkArrayIncludes,
  checkDateMatch,
  checkFilterMatch,
  compareString,
  formatDateValue,
} from "utils/helpers";
import { deliveryStatusLabelDic } from "./adReview/adTableListContainer/columns";

type Fields = {
  adName: AdReviewField;
  status: AdReviewField;
  startDate: AdReviewField;
  endDate: AdReviewField;
  effectiveStatus: AdReviewField;
  adFormat: AdReviewField;
  destinationUrl: AdReviewField;
  createdTime: AdReviewField;
  updatedTime: AdReviewField;
  adSetName: AdReviewField;
  campaignName: AdReviewField;
  utm: AdReviewField;
  cta: AdReviewField;
  copy: AdReviewField;
  headline: AdReviewField;
  newsLinkDescription: AdReviewField;
  displayUrl: AdReviewField;
};

export type IAdReviewFields = keyof Fields;

export const fields: Fields = {
  adName: {
    filterFn: (value, record) =>
      checkFilterMatch(value?.toString(), record.name?.toString()),
    sorterFn: (a, b) => compareString(a.name, b.name),
    queryParamConfigFilter: StringParam,
  },
  createdTime: {
    sorterFn: (a, b) =>
      moment(formatDateValue(a.createdTime)).valueOf() -
      moment(formatDateValue(b.createdTime)).valueOf(),
    filterFn: (
      value: { toString: () => string },
      record: { createdTime: string },
    ) =>
      checkDateMatch(value?.toString(), parseInt(record?.createdTime ?? "0")),
    queryParamConfigFilter: StringParam,
    defaultSortOrder: "descend",
  },
  updatedTime: {
    sorterFn: (a, b) =>
      moment(formatDateValue(a.updatedTime)).valueOf() -
      moment(formatDateValue(b.updatedTime)).valueOf(),
    filterFn: (
      value: { toString: () => string },
      record: { updatedTime: string },
    ) =>
      checkDateMatch(value?.toString(), parseInt(record?.updatedTime ?? "0")),
    queryParamConfigFilter: StringParam,
    defaultSortOrder: "descend",
  },
  status: {
    sorterFn: (a, b) => compareString(a.qcStatus, b.qcStatus),
    filterFn: (value, record) =>
      checkArrayIncludes(value, record.qcStatus?.toString()),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  startDate: {
    sorterFn: (a, b) =>
      moment(formatDateValue(a.startDate)).valueOf() -
      moment(formatDateValue(b.startDate)).valueOf(),
    filterFn: (
      value: { toString: () => string },
      record: { startDate: string },
    ) => checkDateMatch(value?.toString(), parseInt(record?.startDate ?? "0")),
    queryParamConfigFilter: StringParam,
  },
  endDate: {
    sorterFn: (a, b) =>
      moment(formatDateValue(a.endDate)).valueOf() -
      moment(formatDateValue(b.endDate)).valueOf(),
    filterFn: (value: { toString: () => string }, record) =>
      checkDateMatch(value?.toString(), parseInt(record.endDate ?? "0")),
    queryParamConfigFilter: StringParam,
  },
  effectiveStatus: {
    sorterFn: (a, b) =>
      compareString(
        a.effectiveStatus
          ? deliveryStatusLabelDic[a.effectiveStatus]
          : undefined,
        b.effectiveStatus
          ? deliveryStatusLabelDic[b.effectiveStatus]
          : undefined,
      ),
    filterFn: (value, record) =>
      checkArrayIncludes(
        value,
        deliveryStatusLabelDic[
          record.effectiveStatus as FacebookAdEffectiveStatus
        ],
      ),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  adFormat: {
    sorterFn: (a, b) => compareString(a.adFormat || "", b.adFormat || ""),
    filterFn: (value, record) =>
      checkArrayIncludes(value, record.adFormat?.toString()),
    queryParamConfigFilter: DelimitedArrayParam,
  },
  destinationUrl: {
    sorterFn: (a, b) => compareString(a.destinationUrl, b.destinationUrl),
    filterFn: (
      value: { toString: () => string },
      record: { destinationUrl: string | undefined },
    ) => checkFilterMatch(value?.toString(), record.destinationUrl),
    queryParamConfigFilter: StringParam,
  },
  adSetName: {
    filterFn: (value, record) =>
      checkFilterMatch(value?.toString(), record.adSetName?.toString()),
    sorterFn: (a, b) => compareString(a.adSetName, b.adSetName),
    queryParamConfigFilter: StringParam,
  },
  campaignName: {
    filterFn: (value, record) =>
      checkFilterMatch(value?.toString(), record.campaignName?.toString()),
    sorterFn: (a, b) => compareString(a.campaignName, b.campaignName),
    queryParamConfigFilter: StringParam,
  },
  cta: {
    filterFn: (value, record) =>
      checkFilterMatch(value?.toString(), record.cta?.toString()),
    sorterFn: (a, b) => compareString(a.cta, b.cta),
    queryParamConfigFilter: StringParam,
  },
  utm: {
    filterFn: (value, record) =>
      checkFilterMatch(value?.toString(), record.utm?.toString()),
    sorterFn: (a, b) => compareString(a.utm, b.utm),
    queryParamConfigFilter: StringParam,
  },
  copy: {
    filterFn: (value, record) =>
      checkFilterMatch(value?.toString(), record.copy?.toString()),
    sorterFn: (a, b) => compareString(a.copy, b.copy),
    queryParamConfigFilter: StringParam,
  },
  headline: {
    filterFn: (value, record) =>
      checkFilterMatch(value?.toString(), record.headline?.toString()),
    sorterFn: (a, b) => compareString(a.headline, b.headline),
    queryParamConfigFilter: StringParam,
  },
  newsLinkDescription: {
    filterFn: (value, record) =>
      checkFilterMatch(
        value?.toString(),
        record.newsLinkDescription?.toString(),
      ),
    sorterFn: (a, b) =>
      compareString(a.newsLinkDescription, b.newsLinkDescription),
    queryParamConfigFilter: StringParam,
  },
  displayUrl: {
    filterFn: (value, record) =>
      checkFilterMatch(value?.toString(), record.displayUrl?.toString()),
    sorterFn: (a, b) => compareString(a.displayUrl, b.displayUrl),
    queryParamConfigFilter: StringParam,
  },
};

export const adReviewFieldKeys = Object.keys(fields) as Array<keyof typeof fields>;
