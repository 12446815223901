import { FilterFilled } from "@ant-design/icons";
import { Button } from "antd";
import { useCallback } from "react";
import AutoEllipsisText from "shared/components/AutoEllipsisText";
import {
  ITableColumn,
  useColumn,
  useDataList,
} from "shared/components/dataList";
import { BrandLogo } from "shared/types/brandsAccounts";
import { compareStringBy, getFiltersFromData } from "utils/helpers.table";
import { DataListColumnFilterSearch } from "../../dataList/DataListColumnFilterSearch";
import styles from "../LogoSelectDrawer.module.scss";
import { DEFAULT_KEY } from "shared/components/dataList/useDataList";

interface Props {
  setPreviewUrl: (previewUrl: string) => void;
}

export const useLogoSelectTableColumns = ({ setPreviewUrl }: Props) => {
  const [dataList] = useDataList<BrandLogo>();
  const getFilters = getFiltersFromData(dataList.data);

  const filterIcon = useCallback(
    (column: string) => {
      const dataListColFiltered = dataList.filters[DEFAULT_KEY][column]?.length;
      return (
        <FilterFilled
          style={{ color: dataListColFiltered ? "#1890ff" : undefined }}
        />
      );
    },
    [dataList.filters],
  );

  const DEFAULT_COLUMN_WIDTH = 200;

  const tableColumns: ITableColumn<BrandLogo>[] = [
    useColumn<BrandLogo>({
      key: "name",
      title: "Name",
      dataIndex: ["name"],
      filters: getFilters("name"),
      render: (value: any, record: BrandLogo) => (
        <div className={styles.nameColumn}>
          <img
            src={record.logoUrl}
            height={30}
            width={30}
            className={styles.thumbnailImg}
            onClick={() => setPreviewUrl(record.logoUrl)}
          />
          <Button type={"link"} onClick={() => setPreviewUrl(record.logoUrl)}>
            <AutoEllipsisText>{value}</AutoEllipsisText>
          </Button>
        </div>
      ),
      filterDropdown: props => (
        <DataListColumnFilterSearch<BrandLogo> {...props} column={"name"} />
      ),
      filterIcon: () => filterIcon("name"),
      width: DEFAULT_COLUMN_WIDTH + 150,
      fixed: "left",
      defaultSortOrder: "ascend",
    }),
    useColumn<BrandLogo>({
      key: "assetType",
      title: "Asset Type",
      dataIndex: ["assetType"],
      filters: getFilters("assetType"),
      filterDropdown: props => (
        <DataListColumnFilterSearch<BrandLogo>
          {...props}
          column={"assetType"}
        />
      ),
      filterIcon: () => filterIcon("assetType"),
      width: DEFAULT_COLUMN_WIDTH,
    }),
    useColumn<BrandLogo>({
      key: "brand",
      title: "Brand",
      dataIndex: ["brand"],
      filters: getFilters("brand"),
      sorter: compareStringBy("brand"),
      filterDropdown: props => (
        <DataListColumnFilterSearch<BrandLogo>
          {...props}
          selectedKeys={dataList.filters[DEFAULT_KEY].brand}
          column={"brand"}
        />
      ),
      filterIcon: () => filterIcon("brand"),
      width: DEFAULT_COLUMN_WIDTH + 100,
      render: (value: any) => value.replace(",", ", "),
    }),
    useColumn<BrandLogo>({
      key: "account",
      title: "Account",
      dataIndex: ["account"],
      filters: getFilters("account"),
      sorter: compareStringBy("account"),
      filterDropdown: props => (
        <DataListColumnFilterSearch<BrandLogo>
          {...props}
          selectedKeys={dataList.filters[DEFAULT_KEY].account}
          column={"account"}
        />
      ),
      filterIcon: () => filterIcon("account"),
      width: DEFAULT_COLUMN_WIDTH + 100,
      render: (value: any) => value.replace(",", ", "),
    }),
    useColumn<BrandLogo>({
      key: "logoType",
      title: "Logo Type",
      dataIndex: ["logoType"],
      filters: getFilters("logoType"),
      sorter: compareStringBy("logoType"),
      filterDropdown: props => (
        <DataListColumnFilterSearch<BrandLogo>
          {...props}
          selectedKeys={dataList.filters[DEFAULT_KEY].logoType}
          column={"logoType"}
        />
      ),
      filterIcon: () => filterIcon("logoType"),
      width: DEFAULT_COLUMN_WIDTH,
    }),
    useColumn<BrandLogo>({
      key: "artboardName",
      title: "Artboard name",
      dataIndex: ["artboardName"],
      filters: getFilters("artboardName"),
      sorter: compareStringBy("artboardName"),
      filterDropdown: props => (
        <DataListColumnFilterSearch<BrandLogo>
          {...props}
          selectedKeys={dataList.filters[DEFAULT_KEY].artboardName}
          column={"artboardName"}
        />
      ),
      filterIcon: () => filterIcon("artboardName"),
      width: DEFAULT_COLUMN_WIDTH,
    }),
  ];

  return { tableColumns };
};
