import { useCallback } from "react";
import { useStoreLabels } from "shared/hooks/useStoreLabels";
import { isValidUrl } from "utils/validators";

export const useDestinationURLValidator = () => {
  const { allStoreLabels, isLoading: isLoadingStores } = useStoreLabels();

  const isValidDestinationURL = useCallback(
    (
      value: string | undefined,
      options?: {
        optionalProtocol: boolean;
      },
    ) => {
      const isValid =
        !!value &&
        (isValidUrl(value, options) ||
          allStoreLabels.map(storeLabel => storeLabel.name).includes(value));

      return !value || isValid;
    },
    [allStoreLabels],
  );

  return { isValidDestinationURL, isLoadingStores };
};
