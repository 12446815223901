import { useMemo } from "react";
import { TableFiltersSection } from "shared/components/TableFiltersSection";
import { TagType } from "shared/components/TagList";
import { useDataList } from "shared/components/dataList";
import { getEntries } from "shared/components/dataListURL/utils";
import { BrandAccountRecord, BrandRecord } from "shared/types/brandsAccounts";

interface Props {
  tableId: string;
}

const SummarySelected = ({ tableId }: Props) => {
  const [
    { selectedIds, rows, filters, showSelected, config, data },
    dataListActions,
  ] = useDataList<BrandRecord>();

  const selectedCount = selectedIds.length;
  const totalCount = data.length;
  const currentItems = rows.length;

  const tags = useMemo(() => {
    const filterEntries = filters[tableId] ? getEntries(filters[tableId]) : [];

    return filterEntries
      ?.flatMap(
        ([key, value]): TagType<keyof BrandAccountRecord>[] | undefined => {
          if (!key || !value) return undefined;

          return value.map((v: string) => ({
            key: key as keyof BrandAccountRecord,
            value: v,
            displayValue: config.columns[key]?.display?.(v) ?? v,
          }));
        },
      )
      .filter(Boolean) as TagType<keyof BrandAccountRecord>[];
  }, [filters, config.columns, tableId]);
  return (
    <TableFiltersSection
      tags={tags}
      removeTag={dataListActions.removeFilter}
      clearTags={dataListActions.resetFilters}
      totalItems={totalCount}
      selectedCount={selectedCount}
      currentItems={currentItems}
      displaySelectedOnly={showSelected}
      onFilterClick={() => dataListActions.toggleSelectedItems()}
      tableId={tableId}
    />
  );
};

export default SummarySelected;
