import { useQueries } from "react-query";
import { fetchCanvasJson } from "shared/components/contextAPI/shared/RenderTemplate";

export const getCanvasJsonQueryKey = (url: string) => ["canvasJson", url];

export const useFetchCanvasJsonObjects = (urls: string[]) => {
  const queries = urls.map(url => ({
    queryKey: getCanvasJsonQueryKey(url),
    queryFn: async () => {
      const canvasJson = await fetchCanvasJson(url);

      return { url, canvasJson };
    },
    staleTime: Infinity,
  }));

  return useQueries(queries);
};
