import { Select } from "antd";
import { useMemo } from "react";
import { ITemplate } from "shared/types/designStudio";

interface IFilterSectionTemplateDimensions {
  templateWidthFilter: number | undefined;
  templateHeightFilter: number | undefined;
  templates: ITemplate[];
  setTemplateWidthFilter: (templateWidthFilter: number | undefined) => void;
  setTemplateHeightFilter: (templateHeightFilter: number | undefined) => void;
}

const FilterSectionTemplateDimensions: React.FC<IFilterSectionTemplateDimensions> =
  ({
    templateWidthFilter,
    templateHeightFilter,
    templates,

    setTemplateWidthFilter,
    setTemplateHeightFilter,
  }) => {
    const [templateWidthsMap, templateHeightsMap] = useMemo(() => {
      const templateWidthsMap: Record<number, number[]> = {}; // {str of Widths: heights at that width[]}
      const templateHeightsMap: Record<number, number[]> = {}; // {str of Widths: heights at that width[]}

      for (const template of templates) {
        const { width, height } = template.artboard;
        if (width in templateWidthsMap) {
          templateWidthsMap[width].push(height);
        } else {
          templateWidthsMap[width] = [height];
        }

        if (height in templateHeightsMap) {
          templateHeightsMap[height].push(width);
        } else {
          templateHeightsMap[height] = [width];
        }
      }
      return [templateWidthsMap, templateHeightsMap];
    }, [templates]);

    const templateWidths = templates.map(template => template.artboard.width);
    const templateHeights = templates.map(template => template.artboard.height);

    return (
      <div className="filter-by">
        <div className="title">Filter By Template Dimensions</div>
        <div style={{ padding: "1em" }}>
          Width:
          <Select
            showSearch={true}
            filterOption={true}
            value={templateWidthFilter?.toString()}
            allowClear={true}
            onChange={(value: string) => {
              if (!value) {
                setTemplateWidthFilter(undefined);
                return;
              }

              const width = parseInt(value);
              setTemplateWidthFilter(width);
            }}
          >
            {Array.from(
              new Set(
                templateHeightFilter
                  ? templateHeightsMap[templateHeightFilter]
                  : templateWidths,
              ),
            )
              .sort((a, b) => a - b)
              .map((width, idx) => {
                return (
                  <Select.Option
                    value={width}
                    key={`${width}-width-key-dimensions-${idx}`}
                  >
                    {width}
                  </Select.Option>
                );
              })}
          </Select>
        </div>
        <div style={{ padding: "1em" }}>
          Height:
          <Select
            showSearch={true}
            filterOption={true}
            value={templateHeightFilter?.toString()}
            allowClear={true}
            onChange={(value: string) => {
              if (!value) {
                setTemplateHeightFilter(undefined);
                return;
              }
              const height = parseInt(value);
              setTemplateHeightFilter(height);
            }}
          >
            {Array.from(
              new Set(
                templateWidthFilter
                  ? templateWidthsMap[templateWidthFilter]
                  : templateHeights,
              ),
            )
              .sort((a, b) => a - b)
              .map((height, idx) => {
                return (
                  <Select.Option
                    value={height}
                    key={`${height}-height-key-dimensions-${idx}`}
                  >
                    {`${height}`}
                  </Select.Option>
                );
              })}
          </Select>
        </div>
      </div>
    );
  };

export default FilterSectionTemplateDimensions;
