import { useState } from "react";

import { Select } from "antd";

import LogoDropZone from "./logoDropZones/LogoDropZone";

import {
  LogoEventType,
  LogoEventTypes,
} from "../../../../../shared/types/designStudio";

import "./LogoDropZones.scss";

const LogoDropZones: React.FC = () => {
  const [selectedLogoEventType, setSelectedLogoEventType] =
    useState<LogoEventType>(LogoEventTypes[0].type);

  return (
    <div className="drop-zone-container">
      <div className="type-select-container">
        <Select
          className="logo-event-type-select"
          defaultValue={selectedLogoEventType}
          onSelect={(value: LogoEventType) => {
            setSelectedLogoEventType(value);
          }}
        >
          {LogoEventTypes.map(logoEventTypeObject => {
            const { title, type } = logoEventTypeObject;

            return (
              <Select.Option key={`logo-event-type-${type}`} value={type}>
                {title}
              </Select.Option>
            );
          })}
        </Select>
      </div>

      <div className="shape-container">
        <div className="square shape">
          <LogoDropZone type="square" eventType={selectedLogoEventType} />
        </div>
        <div className="vertical shape">
          <LogoDropZone type="vertical" eventType={selectedLogoEventType} />
        </div>
        <div className="horizontal shape">
          <LogoDropZone type="horizontal" eventType={selectedLogoEventType} />
        </div>
      </div>
    </div>
  );
};

export default LogoDropZones;
