import { Divider } from "antd";
import { pluralize } from "utils/terms";
import { FilterButton } from "./FilterButton";
import { LoadStatus } from "screens/adLibrary/facebookUtils/adProto.types";
import { AdLoadProgressData } from "../AdLoadProgressTable";
import { typedGroupBy } from "utils/helpers.array";

type Props = {
  adLoadProgressData: AdLoadProgressData[];
  filterBy: LoadStatus | undefined;
  setFilterBy: (filterBy: LoadStatus | undefined) => void;
};

const FiltersBar = ({ adLoadProgressData, filterBy, setFilterBy }: Props) => {
  const {
    loading: adsLoading = [],
    error: adsError = [],
    success: adsSuccess = [],
  } = typedGroupBy(adLoadProgressData, data => data.status);

  return (
    <div style={{ marginBottom: 16 }}>
      <span>
        {adLoadProgressData.length} {pluralize("Ad", adLoadProgressData.length)}
      </span>
      <Divider type="vertical" />
      <span
        style={{
          marginRight: 8,
          fontWeight: "bold",
        }}
      >
        Filters
      </span>
      <div
        style={{
          display: "inline-flex",
        }}
      >
        <FilterButton
          filterBy={filterBy}
          setFilterBy={setFilterBy}
          status="loading"
        >
          {adsLoading.length} Loading
        </FilterButton>
        <FilterButton
          filterBy={filterBy}
          setFilterBy={setFilterBy}
          status="success"
        >
          {adsSuccess.length} Loaded
        </FilterButton>
        <FilterButton
          filterBy={filterBy}
          setFilterBy={setFilterBy}
          status="error"
        >
          {adsError.length} Error
        </FilterButton>
      </div>
    </div>
  );
};

export default FiltersBar;
