import { useAdLoadIssues } from "screens/adLibrary/adLoadV2/hooks/useAdLoadIssues";
import {
  useCurrentSession,
  useSetCurrentSession,
} from "screens/adLibrary/adLoadV2/hooks/useCurrentSession";
import { AdType, CallToAction } from "screens/adLibrary/facebookUtils/types";
import { raise } from "utils/errorMessage";
import { AllEntryTypes } from "../../AssignDestsTable.types";
import { getRecordIssue } from "../recordIssues.utils";
import ProductSetSelect from "shared/components/select/ProductSetSelect";
import { Form } from "antd";
import { findAdProtoDraft } from "screens/adLibrary/facebookUtils/adProto";
import {
  ConvertToVideo,
  InstExpAdProtoDraft,
} from "screens/adLibrary/facebookUtils/adProto.types";

const RecordProductSetSelect = ({ record }: { record: AllEntryTypes }) => {
  const { setSession } = useSetCurrentSession();
  const { session } = useCurrentSession();
  const { issues } = useAdLoadIssues();
  const productCatalogId =
    "productCatalogId" in record ? record.productCatalogId : undefined;

  if (!("productSetId" in record)) {
    return null;
  }

  const onChange = (value: string) => {
    setSession(prevSession => {
      const adProtoDraft = findAdProtoDraft({
        adProtoDrafts: prevSession.data.adProtoDrafts,
        adId: record.adId,
        storeName: record.storeName,
      });

      if (
        adProtoDraft.type !== AdType.InstantExperience &&
        adProtoDraft.type !== AdType.Collection
      ) {
        return;
      }

      adProtoDraft.convertToVideo = false;

      const elementDest =
        adProtoDraft.elementDests.find(item => item.elementId === record.id) ??
        raise("ElementDest not found");

      if (elementDest.destType === "productSet") {
        elementDest.productSetId = value;
      }
    });
  };

  const issue = getRecordIssue({
    issues,
    record,
    field: "productSetId",
  });

  const onSelectConvertToVideo = () => {
    setSession(prevSession => {
      const adProtoDraft = findAdProtoDraft({
        adProtoDrafts: prevSession.data.adProtoDrafts,
        adId: record.adId,
        storeName: record.storeName,
      });

      if ("convertToVideo" in adProtoDraft) {
        Object.assign<InstExpAdProtoDraft, ConvertToVideo>(adProtoDraft, {
          convertToVideo: true,
          destinationUrl: "",
          displayUrl: "",
          callToAction: CallToAction.SHOP_NOW,
        });
      }
    });
  };

  const protoDraft = findAdProtoDraft({
    adProtoDrafts: session.data.adProtoDrafts,
    adId: record.adId,
    storeName: record.storeName,
  });

  return (
    <Form.Item style={{ margin: 0 }} validateStatus={issue?.status}>
      <ProductSetSelect
        productSetId={record.productSetId}
        onChange={onChange}
        productCatalogId={productCatalogId}
        onSelectConvertToVideo={onSelectConvertToVideo}
        convertToVideo={
          "convertToVideo" in protoDraft && protoDraft.convertToVideo
        }
        style={{
          width: 250,
        }}
      />
    </Form.Item>
  );
};

export default RecordProductSetSelect;
