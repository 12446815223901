import { Navigate, Route, Routes } from "react-router-dom";
import {
  CAMCallback,
  CAMGuard,
  CAMSharedAssets,
} from "shared/components/media";
import * as helpers from "../utils/helpers";
import AdLibraryContainer from "./AdLibrary";
import { Lobby } from "./app/Lobby";
import AssetBuilder from "./AssetBuilder";
import AssetExport from "./AssetExport";
import AssetExporter from "./AssetExporter";
import DashboardManagement from "./DashboardManagement";
import DesignStudio from "./DesignStudio";
import InstantExperience from "./designStudio/editor/InstantExperience";
import EverythingAds from "./EverythingAds";
import Ads from "./everythingAds/Ads";
import LegalLingo from "./LegalLingo";
import LegalMessaging from "./legalLingo/LegalMessaging";
import LegalVariables from "./legalLingo/LegalVariables";
import LegalLingoV2 from "./LegalLingoV2";
import Media from "./Media";
import Metrics from "./Metrics";
import { NavigateTest } from "./NavigateTest";
import PlatformManagement from "./PlatformManagement";
import { ProtectedRoute } from "./ProtectedRoute";
import AdReview from "./campaignManagement/AdReview";
import AudienceManager from "./campaignManagement/AudienceManager";
import PublicAdReview from "./campaignManagement/PublicAdReview";
import CampaignPlannerList from "./campaignManagement/campaignPlanner/CampaignPlannerList";
import { CampaignPlannerInstanceDetail } from "./campaignManagement/campaignPlanner/CampaignPlannerInstanceDetail";
import CampaignPlanner from "./campaignManagement/CampaignPlanner";
import { GooglePerformanceMax } from "./campaignManagement/GooglePerformanceMax";
import { CampaignPlannerDetail } from "./campaignManagement/campaignPlanner/CampaignPlannerDetail";
import { CampaignManagementProvider } from "./CampaignManagement";
import AiImageGen from "./genAI/generate/AiImageGen";
import BrandsAccountsManagement from "./BrandsAccountsManagement";
import Resize from "./genAI/resize/Resize";
import LobbyWithoutAuthentication from "./app/Layout";
import { MetricsShare } from "./MetricsShare";
import { Homepage } from "./Homepage";
import { Products } from "./admin/Products";
import Templates from "./Templates";
import { Disclosures } from "./admin/Disclosures";
import MarketingMaterials from "./adLibrary/MarketingMaterials";
import Accounts from "./Accounts";
import DealerManagement from "./DealerManagement";
import OemManagement from "./OemManagement";
import MarketingMaterialDrawer from "./adLibrary/marketingMaterials/MarketingMaterialDrawer";
import CreateMarketingMaterialDrawer from "./adLibrary/marketingMaterials/CreateMarketingMaterialDrawer";
import { PermissionRoute } from "./PermissionRoute";
import { rbac } from "config/rbac";
import BeeswaxCampaigns from "./BeeswaxCampaigns";
import { EmailDelivery } from "./adLibrary/marketingMaterials/EmailDelivery";
import CampaignDrawer from "./beeswaxCampaigns/CampaignDrawer";
import AgentProfiles from "./AgentProfiles";
import BrochureManagement from "./BrochureManagement";
import { useHasBrochureAccess } from "shared/hooks/useHasBrochureAccess";
import LineItemDrawer from "./beeswaxCampaigns/lineItems/LineItemDrawer";
import DynamicDashboardDetail from "./metrics/DynamicDashboardDetail";
import Archive from "./designStudio/Archive";
import ArchiveTemplateFileDrawer from "./designStudio/archive/ArchiveTemplateFileDrawer";
import LoadCampaigns from "./beeswaxCampaigns/LoadCampaigns";
import TemplateDrawer from "./designStudio/templates/TemplateDrawer";
import ErrorBoundary from "shared/components/ErrorBoundary";
import SalesEnablementErrorFeedback from "shared/components/SalesEnablementErrorFeedback";
import ArchiveMarketingMaterialDrawer from "./designStudio/archive/ArchiveMarketingMaterialDrawer";
import { SalesEnablementMobileGuard } from "shared/components/SalesEnablementMobileGuard";
import { PrintOptions } from "./designStudio/PrintOptions";
import PrintArchive from "./designStudio/PrintArchive";

export const App = () => {
  const isNu = helpers.isEnvVarEquals("CLIENT", "nu");
  const isAuto = helpers.industryType("auto");
  const dealer = isAuto ? "store" : "account";
  const oem = isAuto ? "oem" : "brand";
  const isSalesEnablementEnabled = helpers.isFeatureEnabled(
    "ENABLE_SALES_ENABLEMENT",
  );
  const { onlyBrochureAccess } = useHasBrochureAccess();

  let index = "/asset-builder/orders";
  const salesEnablementIndex = "/sales-enablement/home";
  const brochureManagementIndex = "/brochure-management";
  if (onlyBrochureAccess) {
    index = brochureManagementIndex;
  } else if (isSalesEnablementEnabled) {
    index = salesEnablementIndex;
  } else if (isNu) {
    index = "/ad-library";
  }
  return (
    <Routes>
      <Route
        path="/"
        element={
          window.Cypress ? <Lobby /> : <ProtectedRoute component={Lobby} />
        }
      >
        <Route
          element={
            <ErrorBoundary
              feedbackComponent={
                isSalesEnablementEnabled ? (
                  <SalesEnablementErrorFeedback />
                ) : undefined
              }
            >
              <CAMGuard />
            </ErrorBoundary>
          }
        >
          <Route element={<SalesEnablementMobileGuard />}>
            <Route path="sales-enablement">
              <Route path="home" element={<Homepage />} />
              <Route
                path="disclosures"
                element={
                  <PermissionRoute
                    permissions={[rbac.manageSalesEnablementAdmin]}
                    redirectPath={salesEnablementIndex}
                  >
                    <Disclosures />
                  </PermissionRoute>
                }
              >
                <Route path=":disclosureId" />
              </Route>
              <Route
                path="products"
                element={
                  <PermissionRoute
                    permissions={[rbac.manageSalesEnablementAdmin]}
                    redirectPath={salesEnablementIndex}
                  >
                    <Products />
                  </PermissionRoute>
                }
              >
                <Route path=":productId" />
              </Route>
              <Route path="accounts" element={<Accounts />} />
              <Route path="agents" element={<AgentProfiles />} />
              <Route path="agents/:agentId" element={<AgentProfiles />} />
              <Route
                path="my-marketing-materials"
                element={<MarketingMaterials />}
              >
                <Route
                  path=":materialId"
                  element={<MarketingMaterialDrawer type="edit" />}
                >
                  <Route
                    path="send-by-email"
                    element={<EmailDelivery calledBy="DRAWER" />}
                  />
                </Route>
                <Route
                  path=":materialId/print"
                  element={<MarketingMaterialDrawer type="edit" />}
                />
                <Route
                  path=":materialId/print/checkout"
                  element={<MarketingMaterialDrawer type="edit" />}
                />
                <Route
                  path="new/:templateId"
                  element={<MarketingMaterialDrawer type="create" />}
                />
                <Route
                  path="send-by-email/:containerMaterialId"
                  element={<EmailDelivery calledBy="TABLE" />}
                />
                <Route path="new" element={<CreateMarketingMaterialDrawer />} />
              </Route>
              <Route
                path="my-marketing-materials/print"
                element={<MarketingMaterials />}
              />
              <Route
                path="my-marketing-materials/print/checkout"
                element={<MarketingMaterials />}
              />

              <Route path="media" element={<div>media</div>} />
              <Route path="archive" element={<Archive />}>
                <Route
                  path=":archiveId/material"
                  element={<ArchiveMarketingMaterialDrawer />}
                />
                <Route
                  path=":archiveId/template"
                  element={<ArchiveTemplateFileDrawer />}
                />
              </Route>
              <Route path="print-options" element={<PrintOptions />} />
              <Route path="print-options/new" element={<PrintOptions />} />

              <Route path="archive/print" element={<PrintArchive />} />
              <Route
                path="archive/print/:archiveId"
                element={<PrintArchive />}
              />

              <Route
                path="metrics/:name"
                element={<DynamicDashboardDetail allowDownload />}
              />
              <Route path="templates" element={<Templates />}>
                <Route
                  path="new"
                  element={<TemplateDrawer drawerMode="new" />}
                />
                <Route
                  path=":templateId"
                  element={<TemplateDrawer drawerMode="edit" />}
                />
                <Route
                  path=":templateId/duplicate"
                  element={<TemplateDrawer drawerMode="duplicate" />}
                />
                <Route
                  path=":templateId/material/new"
                  element={<MarketingMaterialDrawer type="create" />}
                />
                <Route
                  path=":templateId/material/:materialId"
                  element={<MarketingMaterialDrawer type="edit" />}
                >
                  <Route
                    path="send-by-email"
                    element={<EmailDelivery calledBy="DRAWER" />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route
            path="brochure-management"
            element={
              <PermissionRoute
                permissions={[
                  rbac.manageBrochures,
                  rbac.readBrochures,
                  rbac.manageAdmin,
                ]}
                redirectPath={salesEnablementIndex}
              >
                <BrochureManagement />
              </PermissionRoute>
            }
          />
          <Route
            index
            key="root-route"
            element={<Navigate to={index} replace />}
          />
          <Route
            path="dashboard-management/*"
            element={<DashboardManagement />}
          />
          <Route
            path={`${dealer}-management/*`}
            element={<DealerManagement />}
          />
          <Route path={`${oem}-management/*`} element={<OemManagement />} />
          <Route
            path="brands-accounts-management"
            element={<BrandsAccountsManagement />}
          />
          <Route
            path="platform-management/*"
            element={<PlatformManagement />}
          />
          <Route path="legal-lingo">
            <Route path="variables" element={<LegalVariables />} />
            <Route path="disclosures" element={<LegalLingo />} />
            <Route path="messaging" element={<LegalMessaging />} />
          </Route>
          <Route path="legal-lingo-v2/*" element={<LegalLingoV2 />} />
          <Route path="media/*" element={<Media />} />

          <Route path="asset-builder/*" element={<AssetBuilder />} />
          <Route path="ad-engine/*" element={<AssetExporter />} />
          <Route path="asset-export/*" element={<AssetExport />} />
          <Route path="design-studio/*" element={<DesignStudio />} />
          <Route path="ad-library/*" element={<AdLibraryContainer />} />
          <Route path="everything-ads">
            <Route path="ads" element={<Ads />} />
            <Route path="mapper" element={<EverythingAds />} />
            <Route path="ads/create" element={<InstantExperience />} />
            <Route
              path="ads/:instantExperienceId/edit"
              element={<InstantExperience />}
            />
          </Route>
          <Route
            path={"campaign-management"}
            element={<CampaignManagementProvider />}
          >
            <Route path={"ad-review"} element={<AdReview />} />
            <Route path={"audience-manager"} element={<AudienceManager />} />
            <Route path={"campaign-planner"} element={<CampaignPlanner />}>
              <Route index element={<CampaignPlannerList />} />
              <Route
                path=":plannerId/instance/:instanceId"
                element={<CampaignPlannerInstanceDetail />}
              />
              <Route path=":plannerId" element={<CampaignPlannerDetail />} />
            </Route>
            <Route
              path={"google-performance-max"}
              element={<GooglePerformanceMax />}
            />
            <Route path="beeswax" element={<BeeswaxCampaigns />}>
              <Route path="load/select" element={<LoadCampaigns />} />
              <Route path="load/result" element={<LoadCampaigns />} />
              <Route path="new" element={<CampaignDrawer />}>
                <Route path="line-items/new" element={<LineItemDrawer />} />
                <Route path="line-items/edit" element={<LineItemDrawer />} />
              </Route>
              <Route path=":campaignId" element={<CampaignDrawer />}>
                <Route path="line-items/new" element={<LineItemDrawer />} />
                <Route path="line-items/edit" element={<LineItemDrawer />} />
              </Route>
            </Route>
          </Route>
          <Route path="metrics/*" element={<Metrics />} />
          <Route path="navtest/*" element={<NavigateTest />} />
          <Route path="ai-image-tools/generate/" element={<AiImageGen />} />
          <Route
            path="ai-image-tools/generate/:sessionId"
            element={<AiImageGen />}
          />
          <Route path="ai-image-tools/resize/" element={<Resize />} />
          <Route path="ai-image-tools/resize/:sessionId" element={<Resize />} />
        </Route>
        <Route path="media-callback" element={<CAMCallback />} />
        <Route path="metrics" element={<Metrics />} />
      </Route>

      {/* PUBLIC ROUTES */}
      <Route path="/" element={<LobbyWithoutAuthentication />}>
        <Route
          path={"campaign-management"}
          element={<CampaignManagementProvider />}
        >
          <Route path={"ad-review/shared"} element={<PublicAdReview />} />
        </Route>
        <Route path="metrics/share/:id" element={<MetricsShare />} />
        <Route path={"media/shared"} element={<CAMSharedAssets />} />
      </Route>
    </Routes>
  );
};
