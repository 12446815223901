import { TAssetBatch } from "shared/types/assetExporter";

import { getColumnMatch } from "./getColumnMatch";
import {
  recommendedColumnMatches,
  recommendedColumnTitles,
} from "../constants";
import {
  AdType,
  MatchColumnsTableData,
  RecommendedColumnMatches,
  RecommendedColumnTitle,
  RowType,
} from "../../shared/types";

export const getColumnMatches = ({
  adType,
  feedColumns,
  assetBatchesForSelectedRows,
}: {
  adType: AdType;
  feedColumns: MatchColumnsTableData[];
  assetBatchesForSelectedRows: TAssetBatch[];
}): RecommendedColumnMatches => {
  // keep track of columns which have already been matched
  const matchedColumnTitles = new Set();
  return feedColumns.reduce<RecommendedColumnMatches>((accu, feedColumn) => {
    if (typeof feedColumn.title !== "string") return accu;
    const lowerCaseFeedColumnTitle = feedColumn.title?.toLowerCase();

    // look for the recommended column title whose match strings are contained in the feed column title
    const matchingRecommendedColumnTitle = recommendedColumnTitles[adType].find(
      recommendedColumnTitle => {
        const columnMatches = recommendedColumnMatches[recommendedColumnTitle];
        const isMediaRow = feedColumn.rowType === RowType.Media;
        const isValidDimensions = feedColumn.isValidDimensions !== false;
        const recommendedMediaColumns = [
          RecommendedColumnTitle.InFeedMedia,
          RecommendedColumnTitle.StoryMedia,
        ];

        const recommendedColumnTitleMatch =
          isValidDimensions &&
          columnMatches.some(matchString =>
            lowerCaseFeedColumnTitle.includes(matchString),
          );

        if (recommendedMediaColumns.includes(recommendedColumnTitle)) {
          return isMediaRow && recommendedColumnTitleMatch;
        } else {
          return !isMediaRow && recommendedColumnTitleMatch;
        }
      },
    );

    // only update our matched columns if we have not already matched the recommended column title
    if (
      matchingRecommendedColumnTitle &&
      !matchedColumnTitles.has(matchingRecommendedColumnTitle)
    ) {
      matchedColumnTitles.add(matchingRecommendedColumnTitle);

      const columnMatch = getColumnMatch(
        matchingRecommendedColumnTitle,
        assetBatchesForSelectedRows,
        feedColumn,
      );

      return {
        ...accu,
        [feedColumn.key]: columnMatch,
      };
    }

    return { ...accu, [feedColumn.key]: { recommendedColumnTitle: null } };
  }, {});
};
