import { CSSProperties, ReactNode } from "react";
import {
  Handle as FlowHandle,
  HandleProps,
  HandleType,
  Position,
} from "react-flow-renderer";
import { AppColors } from "shared/types/shared";
import { ThunderboltFilled } from "@ant-design/icons";
import styles from "./Handle.module.scss";

type Props = {
  children?: ReactNode;
  id?: string;
  type: HandleType;
  position?: Position;
  isValidConnection?: HandleProps["isValidConnection"];
};

const Handle = (props: Props) => {
  const flowHandleStyle: CSSProperties = {
    width: "30px",
    height: "30px",
    right: "-15px",
    border: "none",
    zIndex: 10,
    backgroundColor: AppColors.primary,
  };

  return (
    <FlowHandle
      className={styles.handle}
      style={flowHandleStyle}
      type={props.type}
      id={props.id}
      position={props.position || Position.Right}
      isValidConnection={props.isValidConnection}
    >
      <ThunderboltFilled />
    </FlowHandle>
  );
};

export default Handle;
