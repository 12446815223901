import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useSearchAssetsMutationMutation } from "redux/media/media.api";
import { withCustomPayload } from "./withCustomPayload";

export const useCustomSearchAssetsMutation = () => {
  const { pathname } = useLocation();
  const [trigger] = useSearchAssetsMutationMutation();

  return useCallback(
    (payload: MGSearchPayload) => {
      return trigger(withCustomPayload(payload, pathname)).unwrap();
    },
    [trigger, pathname],
  );
};
