import z from "zod";

export const accountAddressSchema = z.object({
  address: z.string().min(1, "Please enter an Address"),
  city: z.string().min(1, "Please enter a City"),
  state: z.string().min(1, "Please enter a State"),
  zip: z.string().min(1, "Please enter a Zip Code"),
});

export type AccountAddress = z.infer<typeof accountAddressSchema>;
