import { Spin } from "antd";

const Spinner = () => {
  return (
    <Spin
      tip="Loading..."
      style={{
        margin: "auto",
        width: "100%",
      }}
    />
  );
};

export default Spinner;
