import { CognitoIdentity } from "@aws-sdk/client-cognito-identity";
import {
  CredentialsAndIdentityId,
  CredentialsAndIdentityIdProvider,
} from "aws-amplify/auth";

class CredProvider implements CredentialsAndIdentityIdProvider {
  private region: string;
  private identityPoolId: string;
  private federatedLogin?: { domain: string; token: string } | undefined;

  constructor(region: string, identityPoolId: string) {
    this.region = region;
    this.identityPoolId = identityPoolId;
    this.federatedLogin = undefined;
  }

  loadFederatedLogin(login?: typeof this.federatedLogin) {
    this.federatedLogin = login;
  }

  async getCredentialsAndIdentityId(): Promise<
    CredentialsAndIdentityId | undefined
  > {
    try {
      const cognitoidentity = new CognitoIdentity({ region: this.region });

      const getIdResult = await cognitoidentity.getId({
        IdentityPoolId: this.identityPoolId,
        Logins: {
          [this.federatedLogin?.domain ?? ""]: this.federatedLogin?.token ?? "",
        },
      });

      const cognitoCredentialsResult =
        await cognitoidentity.getCredentialsForIdentity({
          IdentityId: getIdResult.IdentityId,
          Logins: {
            [this.federatedLogin?.domain ?? ""]:
              this.federatedLogin?.token ?? "",
          },
        });

      const expiration =
        typeof cognitoCredentialsResult.Credentials?.Expiration === "string"
          ? new Date(cognitoCredentialsResult.Credentials?.Expiration)
          : cognitoCredentialsResult.Credentials?.Expiration;

      const credentials: CredentialsAndIdentityId = {
        credentials: {
          accessKeyId: cognitoCredentialsResult.Credentials?.AccessKeyId ?? "",
          secretAccessKey:
            cognitoCredentialsResult.Credentials?.SecretKey ?? "",
          sessionToken:
            cognitoCredentialsResult.Credentials?.SessionToken ?? "",
          expiration: expiration ?? undefined,
        },
        identityId: getIdResult.IdentityId ?? "",
      };
      return credentials;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error getting credentials: ", e);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clearCredentialsAndIdentityId(): void {}
}

export { CredProvider };
