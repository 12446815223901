import { useState, useRef } from "react";
import { useIsOverflowing } from "shared/hooks/useHasOverflow";
import styles from "./AdMessage.module.scss";

const AdMessage = ({ message }: { message: string | undefined }) => {
  const [expandMessage, setExpandMessage] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { isOverflowingY } = useIsOverflowing({
    ref,
    deps: [message],
  });

  return (
    <div className={styles.copy}>
      <div
        ref={ref}
        className={styles.messageBox}
        style={{
          overflow: expandMessage ? "visible" : "hidden",
          maxHeight: expandMessage ? "100%" : 54,
        }}
      >
        <div
          className={styles.left}
          style={{
            height: (ref.current?.clientHeight || 0) - 18,
          }}
        ></div>
        {!expandMessage && isOverflowingY && (
          <a
            href="#"
            className={styles.seeMore}
            onClick={() => setExpandMessage(true)}
          >
            ...See more
          </a>
        )}
        <div className={styles.message}>{message}</div>
      </div>
    </div>
  );
};

export default AdMessage;
