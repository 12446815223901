/* eslint-disable react/display-name */
import { Key, useCallback } from "react";
import { Avatar, Space, Table, Typography, TableColumnProps } from "antd";

import { getColorFromConflictStatus } from "screens/adLibrary/utils";

import {
  ConflictStatus,
  IAd,
  IAdLoadDestination,
} from "shared/types/adLibrary";
import { AdType } from "screens/adLibrary/facebookUtils/types";

import Placeholder from "statics/images/image-placeholder.png";
import { SearchOutlined } from "@ant-design/icons";
import { SearchFilterDropdown } from "shared/components/SearchFilterDropdown";
import { checkFilterMatch } from "utils/helpers";
import { TableProps } from "antd/es/table";
import { FiltersType } from "shared/components/TableFiltersSection";

export interface IAdSelectTableProps {
  ads?: IAd[];
  adLoadDestination?: IAdLoadDestination | null;
  filters?: FiltersType;
}
interface IAdSelectTableHanders {
  onSelectionChange?: (selectedRowKeys: Key[]) => void;
  onFiltersChange?: (filters: FiltersType) => void;
}
export type AdSelectTableProps = IAdSelectTableProps & IAdSelectTableHanders;

interface AdTableEntry {
  key: string;
  id: string;
  name: string;
  type: AdType;
  thumbnail?: string;
}

const returnColumnSorter = (valueA: string, valueB: string) =>
  valueA?.localeCompare(valueB) ?? 0;

const AdSelectTable = ({
  ads = [],
  adLoadDestination,
  onSelectionChange,
  onFiltersChange,
  filters,
}: AdSelectTableProps) => {
  const {
    selectedAds = [],
    adDestinationKeys = [],
    removedAdDestinationKeys = [],
  } = adLoadDestination || {};
  const selectedRowKeys = selectedAds.map(ad => ad.id!);

  const handleChange: TableProps<AdTableEntry>["onChange"] = (
    _pagination,
    newFilters,
    _sorter,
    extra,
  ) => {
    onFiltersChange?.(newFilters);

    if (extra.action !== "filter") return;
    onSelectionChange?.(selectedRowKeys);
  };

  const returnStatusIsValid = useCallback(
    (adId: string) => {
      const selectedCount = adDestinationKeys?.filter(key =>
        key.includes(adId),
      ).length;
      const removedCount = removedAdDestinationKeys?.filter(key =>
        key.includes(adId),
      ).length;
      return !!selectedCount && selectedCount !== removedCount;
    },
    [removedAdDestinationKeys, adDestinationKeys],
  );

  const typeFilters = Array.from(new Set(ads.map(ad => ad.type))).map(type => ({
    text: type,
    value: type,
  }));

  const columns: Array<TableColumnProps<AdTableEntry>> = [
    {
      width: 12,
      title: (
        <Avatar
          size={12}
          style={{
            backgroundColor: getColorFromConflictStatus(ConflictStatus.EMPTY),
          }}
        />
      ),
      render: (_value, record) => {
        return (
          <Avatar
            size={12}
            style={{
              backgroundColor: getColorFromConflictStatus(
                returnStatusIsValid(record.id)
                  ? ConflictStatus.READY
                  : ConflictStatus.CONFLICT,
              ),
            }}
          />
        );
      },
    },
    {
      title: "Ads",
      dataIndex: "name",
      render: (text, record) => (
        <Space direction="horizontal" align="center">
          <img height={32} width={32} src={record?.thumbnail || Placeholder} />
          <Typography.Paragraph
            ellipsis={{ rows: 2, tooltip: true }}
            style={{ margin: 0 }}
          >
            {text}
          </Typography.Paragraph>
        </Space>
      ),
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => returnColumnSorter(a.name, b.name),
      filteredValue: filters?.name || null,
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      onFilter: (value, record) =>
        checkFilterMatch(value?.toString(), record.name),
    },
    {
      title: "Format",
      dataIndex: "type",
      sortDirections: ["ascend", "descend"],
      filteredValue: filters?.type || null,
      sorter: (a, b) => returnColumnSorter(a.type, b.type),
      filters: typeFilters,
      onFilter: (value, record) => record.type === value,
    },
  ];
  const data: AdTableEntry[] =
    ads?.map(ad => ({
      key: ad.id,
      id: ad.id,
      type: ad.type,
      thumbnail: ad.visuals.thumbnail,
      name: ad.inputParameters.name || "",
    })) ?? [];

  return (
    <Table
      size="small"
      onChange={handleChange}
      columns={columns}
      dataSource={data}
      pagination={false}
      rowSelection={{
        type: "checkbox",
        selectedRowKeys,
        onChange: onSelectionChange,
        getCheckboxProps: record => ({
          id: record.id,
          name: record.name,
        }),
      }}
    />
  );
};

export default AdSelectTable;
