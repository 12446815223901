import { memo, useEffect, useState } from "react";
import { Modal, ModalProps } from "antd";

import _flatten from "lodash/flatten";

import LoadDestination from "./instantExperienceLoad/LoadDestination";
import LoadProductSets from "./instantExperienceLoad/LoadProductSets";
import LoadInstantExperiences from "./instantExperienceLoad/LoadInstantExperiences";

import {
  LoadStatus,
  returnDoesIEHaveProductSetElement,
  returnModalBaseProps,
} from "./instantExperienceLoad/utils";

import {
  IButtonElement,
  ICarouselElement,
  IInstantExperience,
  IInstantExperienceToLoad,
  InstantExperienceBodyElement,
  IPhotoElement,
} from "shared/types/adLibrary";
import { IAccountRecord } from "shared/types/accountManagement";
import { isProductSet } from "utils/adLibrary.validators";

interface IProps {
  instantExperiences: IInstantExperience[];
  rootInstantExperience?: IInstantExperience;
  setRootInstantExperience: React.Dispatch<
    React.SetStateAction<IInstantExperience | null>
  >;
}

type InstantExperienceLoadStep =
  | "DESTINATION"
  | "PRODUCT_SETS"
  | "INSTANT_EXPERIENCES";

function InstantExperienceLoadModal({
  instantExperiences,
  rootInstantExperience,
  setRootInstantExperience,
}: IProps) {
  const [loadStatus, setLoadStatus] = useState<LoadStatus>("idle");
  const [loadStep, setLoadStep] =
    useState<InstantExperienceLoadStep>("DESTINATION");
  const [selectedDealer, setSelectedDealer] = useState<IAccountRecord>();
  const [instantExperiencesToLoad, setInstantExperiencesToLoad] = useState<
    IInstantExperienceToLoad[]
  >([]);

  const hasInstExpDestination = (
    bodyElement: InstantExperienceBodyElement,
  ): bodyElement is IPhotoElement | IButtonElement =>
    !!(
      "destination" in bodyElement &&
      bodyElement.destination?.instantExperienceId
    );

  const bodyElementsWithNestedIEs =
    rootInstantExperience?.body_elements?.filter(hasInstExpDestination) ?? [];

  const bodyCarouselElementsWithNestedIEs =
    rootInstantExperience?.body_elements?.filter(
      (bodyElement): bodyElement is ICarouselElement =>
        bodyElement.element_type === "CAROUSEL" &&
        !!bodyElement.child_elements?.filter(
          element => !!element.destination?.instantExperienceId,
        ).length,
    ) ?? [];

  const nestedInstantExpIdsInElements = bodyElementsWithNestedIEs.map(
    bodyElement => bodyElement.destination!.instantExperienceId!,
  );

  const nestedInstantExpIdsInCarousels = _flatten(
    bodyCarouselElementsWithNestedIEs.map(ie => ie.child_elements),
  )
    .map(element => element?.destination?.instantExperienceId)
    .filter(id => !!id) as string[];

  const nestedInstantExpIds = nestedInstantExpIdsInElements.concat(
    nestedInstantExpIdsInCarousels,
  );

  const nestedInstantExperiences = instantExperiences.filter(
    ie => ie.id && nestedInstantExpIds.includes(ie.id),
  );

  const productSetElements = _flatten(
    instantExperiencesToLoad.map(
      ie => ie.body_elements?.filter(isProductSet) ?? [],
    ),
  );
  const filledProductSetElements = productSetElements.filter(
    element => !!element.product_set_id,
  );
  const incompleteProductSets =
    filledProductSetElements.length === 0 ||
    filledProductSetElements.length !== productSetElements.length;

  // this logic assumes that there is only one level of nested of IEs with product_catalogs
  const rootIEHasProductSet =
    rootInstantExperience &&
    returnDoesIEHaveProductSetElement(rootInstantExperience);
  const nestedIEsWithProductSets = nestedInstantExperiences.filter(ie =>
    returnDoesIEHaveProductSetElement(ie),
  );

  useEffect(() => {
    setInstantExperiencesToLoad([
      { ...rootInstantExperience, isRoot: true },
      ...nestedInstantExperiences,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ieLoadModalSteps: Record<InstantExperienceLoadStep, ModalProps> = {
    DESTINATION: {
      cancelText: "Cancel",
      okText:
        rootIEHasProductSet || nestedIEsWithProductSets.length
          ? "Select IE Product Sets > "
          : "Load as Draft",
      onOk: () => {
        setLoadStep(
          rootIEHasProductSet || nestedIEsWithProductSets.length
            ? "PRODUCT_SETS"
            : "INSTANT_EXPERIENCES",
        );
      },
      onCancel: () => {
        setRootInstantExperience(null);
      },
      okButtonProps: {
        disabled: !selectedDealer,
        title: !selectedDealer
          ? "A store must be selected in order to continue"
          : undefined,
      },
      ...returnModalBaseProps({ title: "Set Load Destination" }),
    },
    PRODUCT_SETS: {
      cancelText: "Back",
      okText: "Load Selected IEs + Product Sets",
      onOk: () => {
        setLoadStep("INSTANT_EXPERIENCES");
      },
      onCancel: () => {
        setRootInstantExperience(null);
      },
      okButtonProps: {
        disabled:
          !selectedDealer?.details?.facebook?.fbCatalogId ||
          incompleteProductSets,
      },
      cancelButtonProps: {
        onClick: () => {
          setLoadStep("DESTINATION");
        },
        title: "Back",
      },
      bodyStyle: { maxHeight: 500, overflowY: "scroll", overflowX: "hidden" },
      ...returnModalBaseProps({ title: "Select IE Product Sets" }),
    },
    INSTANT_EXPERIENCES: {
      closable: loadStatus !== "loading",
      maskClosable: loadStatus !== "loading",
      onCancel: () => {
        if (loadStatus === "loading") return;
        setRootInstantExperience(null);
      },
      footer: null,
      ...returnModalBaseProps({ title: undefined }),
    },
  };

  return (
    <Modal {...ieLoadModalSteps[loadStep]}>
      {loadStep === "DESTINATION" && (
        <LoadDestination
          selectedDealer={selectedDealer}
          onDealerSelect={dealer => {
            setSelectedDealer(dealer);
          }}
        />
      )}
      {loadStep === "PRODUCT_SETS" && (
        <LoadProductSets
          selectedDealer={selectedDealer}
          instantExperiencesToLoad={instantExperiencesToLoad}
          setInstantExperiencesToLoad={setInstantExperiencesToLoad}
        />
      )}
      {loadStep === "INSTANT_EXPERIENCES" && (
        <LoadInstantExperiences
          loadStatus={loadStatus}
          setLoadStatus={setLoadStatus}
          selectedDealer={selectedDealer}
          instantExperiences={instantExperiences}
          instantExperiencesToLoad={instantExperiencesToLoad}
          setInstantExperiencesToLoad={setInstantExperiencesToLoad}
        />
      )}
    </Modal>
  );
}

export default memo(InstantExperienceLoadModal);
