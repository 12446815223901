import { CloudDownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { TooltipPerms } from "./TooltipPerms";
import { useSelected } from "./useSelected";
import { useDownloadAsset } from "./useDownloadAsset";
import { useState } from "react";
import { usePermsAssets } from "./usePermsAssets";

export const AssetsDownload = () => {
  const { selectedAssets, clearSelected, allAssets } = useSelected();
  const [isLoading, setIsloading] = useState(false);
  const downloadAsset = useDownloadAsset();
  const allowed = usePermsAssets("download", true);
  const enabled = allAssets && allowed;

  const handleClick = async () => {
    setIsloading(true);
    await Promise.all(selectedAssets.map(downloadAsset));
    clearSelected();
    setIsloading(false);
  };

  return (
    <TooltipPerms
      action="Download Assets"
      allowed={allowed}
      enabled={allAssets}
    >
      <Button
        shape="circle"
        icon={<CloudDownloadOutlined />}
        loading={isLoading}
        onClick={handleClick}
        disabled={!enabled}
      />
    </TooltipPerms>
  );
};
