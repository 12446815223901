import { HistoryOutlined } from "@ant-design/icons";
import { Button, Checkbox, Divider, Drawer } from "antd";
import { useState } from "react";
import CommentsOpenerWithData from "shared/button/CommentsOpenerWithData";
import { AdReviewDrawerProps } from "shared/types/campaignManagement";
import AdDetails from "./AdDetailsDrawer/AdDetails";
import AdPreviewSelector from "./AdDetailsDrawer/AdPreviewSelector";
import AdQCHistory from "./AdDetailsDrawer/AdQCHistory";
import QCEnhancementMode from "./QCEnhancementMode";

import { useSharedAdReview } from "screens/campaignManagement/useSharedAdReview";
import { useIsAdmin } from "shared/hooks/useIsAdmin";
import styles from "./AdDetailsDrawer.module.scss";

const AdDetailsDrawer = ({
  showDetails,
  selectedEntry,
  facebookAdsFromEntry,
  selectedCount,
  selectedEntryIds,
  setSelectedEntryId,
  setShowDetails,
}: AdReviewDrawerProps) => {
  const isAdmin = useIsAdmin();
  const isSharedAdReview = useSharedAdReview();

  const [isShowingHistory, setIsShowingHistory] = useState(false);
  const [isQCNextAdEnabled, setIsQCNextAdEnabled] = useState(true);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
  const [qcIndex, setQCIndex] = useState(
    selectedEntryIds?.indexOf(selectedEntry?.id ?? "") ?? -1,
  );

  const qcSize = selectedEntryIds?.length ?? 0;
  const hasNextAd = !!qcSize && qcIndex !== -1;
  const shouldShowQCMode = !isSharedAdReview && isAdmin;

  // circular index reference
  const getIndex = (next: number, qcSize: number) =>
    ((next % qcSize) + qcSize) % qcSize;

  const skip = () => {
    const next = qcIndex + 1;
    const nextIndex = getIndex(next, qcSize);
    setQCIndex(nextIndex);
    setSelectedEntryId?.(selectedEntryIds?.[nextIndex] || "");
  };

  const QCNextAd = () => (
    <div key="checkbox" className={styles.qcFooter}>
      <span style={{ marginRight: "1em" }}>
        {qcIndex + 1} of {selectedCount}
      </span>
      <Checkbox
        checked={isQCNextAdEnabled}
        onChange={({ target }) => {
          setIsQCNextAdEnabled(target.checked);
        }}
      >
        QC Next Ad
      </Checkbox>
    </div>
  );

  const Comments = () =>
    selectedEntry ? (
      <div key="comments" className={styles.commentsFooter}>
        <CommentsOpenerWithData id={selectedEntry.id} />
      </div>
    ) : null;

  const QCButtons = () =>
    selectedEntry ? (
      <div key="qc-status-buttons" className={styles.qcStatus}>
        <QCEnhancementMode
          selectedEntry={selectedEntry}
          selectedEntryIds={selectedEntryIds}
          qcSize={qcSize}
          isQCNextAdEnabled={isQCNextAdEnabled}
          setIsNextButtonDisabled={setIsNextButtonDisabled}
          skip={skip}
        />
      </div>
    ) : null;

  const SkipButton = () => (
    <Button
      className={styles.ctaFooter}
      key="next"
      type="link"
      disabled={isNextButtonDisabled}
      onClick={skip}
    >
      Skip
    </Button>
  );

  const CloseButton = () => (
    <Button
      className={styles.ctaFooter}
      key="close"
      type="link"
      onClick={() => {
        setShowDetails(false);
        setSelectedEntryId?.("");
      }}
    >
      Close
    </Button>
  );

  return (
    <Drawer
      data-cy="ad-review-drawer"
      className={`${styles.adDetailsDrawer}`}
      bodyStyle={{ display: "flex", padding: 0 }}
      width="max-content"
      placement="right"
      closable={false}
      visible={showDetails && !!selectedEntry}
      onClose={() => {
        setShowDetails(false);
        setSelectedEntryId?.("");
      }}
      footerStyle={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}
      footer={
        <>
          {shouldShowQCMode && hasNextAd && <QCNextAd key="qcNextAd" />}
          {!isSharedAdReview && <Comments key="comments" />}
          {shouldShowQCMode && <QCButtons key="qcButtons" />}
          {!isSharedAdReview && (
            <Divider key="divider" type="vertical" style={{ height: "100%" }} />
          )}
          {shouldShowQCMode && hasNextAd && <SkipButton key="skip" />}
          <CloseButton key="close" />
        </>
      }
    >
      {selectedEntry && (
        <AdPreviewSelector
          selectedEntry={selectedEntry}
          facebookAdsFromEntry={facebookAdsFromEntry}
        />
      )}
      {selectedEntry && selectedEntry?.adFormat === "InstantExperience" && (
        <div className={styles.instantExperienceColumn}>
          <AdPreviewSelector
            selectedEntry={selectedEntry}
            facebookAdsFromEntry={facebookAdsFromEntry}
            displayPreview={true}
          />
        </div>
      )}
      {selectedEntry && facebookAdsFromEntry && (
        <AdDetails
          selectedEntry={selectedEntry}
          facebookAdsFromEntry={facebookAdsFromEntry}
        />
      )}
      {shouldShowQCMode && isShowingHistory && (
        <AdQCHistory
          selectedEntry={selectedEntry}
          facebookAdsFromEntry={facebookAdsFromEntry}
        />
      )}
      {shouldShowQCMode && (
        <Button
          style={{ borderColor: isShowingHistory ? "#40a9ff" : "#D9D9D9" }}
          className={styles.historyButton}
          aria-label="Show QC History"
          onClick={() => setIsShowingHistory(!isShowingHistory)}
        >
          <HistoryOutlined
            style={{ fontSize: 16, color: "#40a9ff", lineHeight: 0 }}
          />
        </Button>
      )}
    </Drawer>
  );
};

export default AdDetailsDrawer;
