import { ElementType } from "shared/types/adLibrary";
import styles from "./FlowPlaceholder.module.scss";

type Props = {
  elementType?: ElementType;
};
const FlowPlaceholder = (props: Props) => {
  return <div className={styles.flowPlaceholder}>{props.elementType}</div>;
};

export default FlowPlaceholder;
