import useFetchPageDetails from "shared/hooks/adLibrary/facebook/useFetchPageDetails";
import styles from "./PageDetails.module.scss";

type Props = {
  pageId?: string;
};

const PageDetails = ({ pageId }: Props) => {
  const { data: pageData } = useFetchPageDetails({ pageId });

  return (
    <div className={styles.header}>
      <div className={styles.logo}>
        {pageData && <img src={pageData.picture?.data.url} alt="" />}
      </div>
      <div className={styles.page}>
        <div className={styles.pageName}>
          <div className={styles.name}>{pageData?.name}</div>
          <div className={styles.sponsored}>
            Sponsored ·{" "}
            <img
              src="https://www.facebook.com/images/mobile/privacy/wordmark/everyone.png"
              alt=""
            />
          </div>
        </div>
        <div className={styles.pageButtons}>
          <i className={styles.dots}></i>
          <i className={styles.close}></i>
        </div>
      </div>
    </div>
  );
};

export default PageDetails;
