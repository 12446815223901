import React, { useRef } from "react";
import styles from "shared/components/imagePreviewOverlay/ImagePreview.module.scss";
import { IPreviewIframe } from "shared/types/designStudio";
import { useWindowSize } from "shared/hooks/useWindowSize";

type Props = {
  iframePreview: IPreviewIframe;
  onClose: () => void;
};

const IframePreview = (props: Props) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const {
    iframePreview: { url, template },
    onClose,
  } = props;

  const onOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    onClose();
  };
  const { windowInnerHeight, windowInnerWidth } = useWindowSize();

  const height =
    windowInnerHeight - 48 < template.artboard.height
      ? windowInnerHeight - 48
      : template.artboard.height;
  const width =
    windowInnerWidth - 48 < template.artboard.width
      ? windowInnerWidth - 48
      : template.artboard.width;

  return (
    <div className={styles.overlay} onClick={onOverlayClick}>
      <div className={styles.content} style={{ height, width }}>
        <iframe
          title="HTML Template Preview"
          src={url}
          height="100%"
          width="100%"
          name="html-preview"
          ref={iframeRef}
        />
      </div>
    </div>
  );
};

export default IframePreview;
