import { cloneDeep } from "lodash";
import { useCallback, useMemo } from "react";
import { useMetaSchema } from "../useMetaSchema";
import { useQPSearch } from "../useQPSearch";

type Tag = {
  pos: number[];
  value: string;
};

export const useTags = () => {
  const { getLabel, schema } = useMetaSchema();
  const { searchState, setSearchState } = useQPSearch();

  const fields = useMemo(
    () => searchState?.filters?.fields ?? [],
    [searchState],
  );

  const tags = useMemo(() => {
    if (schema) {
      return fields.reduce((acc, field, i) => {
        const fieldName = getLabel(field.field_name);

        if (Array.isArray(field.value)) {
          return acc.concat(
            field.value.map((str, j) => ({
              pos: [i, j],
              value: `${fieldName}: ${str}`,
            })),
          );
        }

        return acc.concat({
          pos: [i],
          value: `${fieldName}: ${field.value}`,
        });
      }, [] as Tag[]);
    }

    return [];
  }, [fields, schema, getLabel]);

  const removeTag = useCallback(
    ([i, j]: number[]) => {
      const clone = cloneDeep(fields);

      if (typeof j === "number") {
        const value = clone[i].value as string[];

        if (value.length === 1) {
          clone.splice(i, 1);
        } else {
          value.splice(j, 1);
        }
      } else {
        clone.splice(i, 1);
      }
      setSearchState({ filters: clone.length ? { fields: clone } : undefined });
    },
    [fields, setSearchState],
  );

  return {
    tags,
    removeTag,
  };
};
