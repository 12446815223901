import API from "services";
import { useQuery } from "react-query";
import { YoutubeHookParams } from "../types";

export const useYoutubeOAuthConsentUrl = ({
  onSuccess,
  onError,
  oem,
  moduleUrl,
}: YoutubeHookParams) => {
  const getYoutubeOAuthConsentUrl =
    (oem: string, moduleUrl: string) => async () => {
      const { result, error } =
        await API.services.platformManagement.getYoutubeOAuthConsentUrl(
          oem,
          moduleUrl,
        );

      if (error || !result) {
        throw Error(error?.message || "Unknown error");
      }

      return result;
    };

  return useQuery<string, Error>(
    ["youtube-oauth-consent-url", oem, moduleUrl],
    getYoutubeOAuthConsentUrl(oem, moduleUrl),
    {
      enabled: false,
      onSuccess,
      onError,
    },
  );
};
