import { useDataList } from "shared/components/dataList";
import ToolbarTable, {
  ToolbarButton,
} from "shared/components/toolbarTable/ToolbarTable";
import { useLogoSelectContext } from "./context/LogoSelectContext";
import { LogoSelectFilterDrawer } from "./LogoSelectFilterDrawer";

export const LogoSelectHeader = () => {
  const { layout, setLayout, setShowFilterDrawer } = useLogoSelectContext();
  const [dataList, dataListActions] = useDataList();

  const toolbarContents: ToolbarButton = {
    Filter: {
      onClick: () => setShowFilterDrawer(true),
    },
    Sort: {
      extraInfo: [
        {
          value: "name",
          label: "Name",
          children: [
            {
              value: "desc",
              label: "Descending",
            },
            {
              value: "asc",
              label: "Ascending",
            },
          ],
        },
        {
          value: "logoType",
          label: "Logo Type",
          children: [
            {
              value: "desc",
              label: "Descending",
            },
            {
              value: "asc",
              label: "Ascending",
            },
          ],
        },
        {
          value: "artboardName",
          label: "Artboard Name",
          children: [
            {
              value: "desc",
              label: "Descending",
            },
            {
              value: "asc",
              label: "Ascending",
            },
          ],
        },
        {
          value: "brand",
          label: "Brand",
          children: [
            {
              value: "desc",
              label: "Descending",
            },
            {
              value: "asc",
              label: "Ascending",
            },
          ],
        },
        {
          value: "account",
          label: "Account",
          children: [
            {
              value: "desc",
              label: "Descending",
            },
            {
              value: "asc",
              label: "Ascending",
            },
          ],
        },
      ],
    },
  };

  return (
    <>
      <ToolbarTable
        searchPlaceholder="Search"
        titleTooltip={""}
        toolbarContents={toolbarContents}
        searchValue={dataList.globalFilter}
        sortingOrder={["Name", "Ascending"]}
        onSearch={value => dataListActions.setGlobalFilter(value)}
        onSortChange={([columnKey, order]) => {
          dataListActions.sortItems(columnKey, order);
        }}
        onClickLayout={layout => setLayout(layout)}
        layout={layout}
        previewFormat={false}
      />
      <LogoSelectFilterDrawer />
    </>
  );
};
