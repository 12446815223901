import { config } from "../config";
import { useMetaSchema } from "../useMetaSchema";
import { createColum } from "./createColumn";

type Keys = keyof Pick<MGConfig, "tableSearchCols" | "tableSelectCols">;

export function useMetaColumns(key: Keys) {
  const schemaObj = useMetaSchema();
  const fields = config[key];

  return fields.map(field => createColum(`meta.${field}`, {}, schemaObj));
}
