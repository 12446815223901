import { useState } from "react";
import placeholder from "statics/images/image-placeholder.png";
import ImagePreview from "./mediaPreview/ImagePreview";
import VideoPreview from "./mediaPreview/VideoPreview";

export type ImgDimensions = {
  naturalWidth: number;
  naturalHeight: number;
  clientWidth: number;
  clientHeight: number;
};

type Props = {
  videoUrl: string | undefined;
  thumbnailUrl: string | undefined;
};

const MediaPreview = ({ videoUrl, thumbnailUrl }: Props) => {
  const [imageDimensions, setImageDimensions] = useState<ImgDimensions>();
  const [isPaused, setIsPaused] = useState(true);

  if (videoUrl && !isPaused) {
    return (
      <VideoPreview
        videoUrl={videoUrl}
        isPaused={isPaused}
        height={imageDimensions?.clientHeight}
      />
    );
  }

  return (
    <ImagePreview
      hasVideo={!!videoUrl}
      thumbnailUrl={thumbnailUrl || placeholder}
      isPaused={isPaused}
      setIsPaused={setIsPaused}
      setImageDimensions={setImageDimensions}
    />
  );
};

export default MediaPreview;
