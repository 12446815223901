import { Col, InputNumber, Row, Typography } from "antd";
import { PrintOptionsCopyQuantity } from "shared/types/printOptions";

type Props = {
  min?: number;
  max?: number;
  onChange?: (value: PrintOptionsCopyQuantity) => void;
};

export const MinMaxInput = ({ min, max, onChange }: Props) => {
  return (
    <Row align="middle" justify="space-between" gutter={8}>
      <Col span={11}>
        <InputNumber
          placeholder="Min"
          style={{ width: "100%" }}
          value={min}
          onChange={value => {
            onChange?.({ min: value, max });
          }}
        />
      </Col>
      <Typography.Text>to</Typography.Text>
      <Col span={11}>
        <InputNumber
          placeholder="Max"
          style={{ width: "100%" }}
          value={max}
          onChange={value => {
            onChange?.({ min, max: value });
          }}
        />
      </Col>
    </Row>
  );
};
