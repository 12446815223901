import { message } from "antd";
import { useEffect, useMemo, useState } from "react";

import { isEmpty } from "lodash";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import { useRouteQuery } from "shared/hooks/useRouteQuery";
import { DestinationUrls } from "shared/types/campaignManagement";
import { createDataSource } from "../utils/helpers.table";
import useAdReviewPreferences from "./useAdReviewPreferences";
import { useFetchSharedAdReview } from "./useFetchSharedAdReview";

export enum Filters {
  Creative = "Creative",
  AIA = "AIA",
}

const usePublicAdReviewState = () => {
  const navigate = useNavigate();
  const [selectedEntryId, setSelectedEntryId] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [expand, setExpand] = useState(false);
  const [displayColumn, setDisplayColumn] = useState(false);
  const [destinationUrls, setDestinationUrls] = useState<DestinationUrls>([]);
  const [filterDrawer, setFilterDrawer] = useState(false);

  const dispatch = useAppDispatch();
  const sharedAdReviewId = useRouteQuery("sharedAdReviewId");

  const { layout } = useAdReviewPreferences();

  useEffect(() => {
    if (isEmpty(sharedAdReviewId)) {
      // TODO: specify navigate location
      navigate("/");
    }
  }, [dispatch, navigate, sharedAdReviewId]);

  const {
    sharedAdReview,
    isLoading: isLoadingFacebookAds,
    isFetching: isFetchingAds,
    error: getSharedAdReviewError,
    isError: isGetSharedAdReviewError,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useFetchSharedAdReview(sharedAdReviewId!, sharedAdReviewId != null);

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  useEffect(() => {
    if (isGetSharedAdReviewError) {
      message.error(
        getSharedAdReviewError?.message ||
          `An error occurred while pulling ads`,
      );
    }
  }, [getSharedAdReviewError, isGetSharedAdReviewError]);

  const adsToShow = sharedAdReview?.sharedAds;

  const isLoadingAds =
    (isLoadingFacebookAds || isFetchingAds) && isEmpty(adsToShow);

  const dataSource = useMemo(() => {
    return createDataSource(adsToShow || [], true);
  }, [adsToShow]);

  /** Entry is AdReviewTableEntry */
  const selectedEntry = useMemo(() => {
    if (!selectedEntryId) return;
    return dataSource.find(entry => entry.id === selectedEntryId);
  }, [selectedEntryId, dataSource]);

  const facebookAdsFromEntry = useMemo(() => {
    if (!selectedEntryId) return;
    const ids = selectedEntryId.split("_");
    const selectedFacebookAds = sharedAdReview?.sharedAds.filter(
      ad => ad.id && ids.includes(ad.id),
    );
    return selectedFacebookAds;
  }, [selectedEntryId, sharedAdReview?.sharedAds]);

  useEffect(() => {
    message.config({ maxCount: 1 });
  }, []);

  const props = {
    adsToShow,
    dataSource,
    showDetails,
    selectedEntry,
    facebookAdsFromEntry,

    sharedAdReview,
    isLoadingAds,

    layout,
    expand,
    displayColumn,
    destinationUrls,
    filterDrawer,
  };

  const handlers = {
    setShowDetails,
    setSelectedEntryId,
    setExpand,
    setDisplayColumn,
    setDestinationUrls,
    setFilterDrawer,
  };

  return {
    props,
    handlers,
  };
};
export default usePublicAdReviewState;
