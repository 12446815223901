import { isEqual } from "lodash";
import { FC, memo, useEffect } from "react";

import {
  IAssetBuilderState,
  IAssetBuildInstance,
} from "shared/types/assetBuilder";
import {
  ITemplate,
  TTemplateType,
  ITemplateTag,
  FetchingDataStatus,
} from "shared/types/designStudio";

import TemplateSelectionList from "./templateSelections/TemplateSelectionList";

import "./TemplateSelection.scss";
import { IAccount } from "shared/types/accountManagement";
import { isPharma } from "utils/helpers";

interface ITemplateSelectionProps {
  templates: ITemplate[];
  instance?: IAssetBuildInstance;
  dealerResult: IAccount;
  tags: ITemplateTag[];
  fetchingData: FetchingDataStatus;
  selectedFilterTags: string[] | null;
  buildPage: IAssetBuilderState["buildPage"];
  searchBy: string;
  selectedTemplateType: TTemplateType;
}

interface ITemplateSelectionHandlers {
  onTemplateSelect: (template: ITemplate) => void;
  getDealer: (dealerName: string) => void;
  saveSelectTemplateSearch: (searcyBy: string) => void;
  filterTemplateTags: (tags: string[] | null) => void;
  fetchData: (dataType: FetchingDataStatus, query?: string) => void;
  setSearchBy: (searchBy: string) => void;
  setSelectedTemplateType: (selectedTempalteType: TTemplateType) => void;
}

const TemplateSelection: FC<
  ITemplateSelectionProps & ITemplateSelectionHandlers
> = props => {
  useEffect(() => {
    const dealerName = props?.instance?.selectedOffer?.offerData?.dealerName;
    if (dealerName) {
      props.getDealer(dealerName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.instance?.template?.type) {
      props.setSelectedTemplateType(props.instance?.template?.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.instance]);

  return (
    <div
      className={`template-selection selection-container ${
        props.templates.length === 0 ? "empty-template" : ""
      }`}
    >
      {!isPharma && (
        <div className="template-type-selection-tabs">
          {(["carcut", "lifestyle"] as TTemplateType[]).map(type => (
            <div
              key={`template-type-tab-${type}`}
              className={`template-type-tab ${type} ${
                type === props.selectedTemplateType ? "selected" : ""
              }`}
              onClick={e => {
                e.preventDefault();

                props.setSelectedTemplateType(type);
              }}
            >
              {type === "carcut" && "Car Cut"}
              {type === "lifestyle" && "Lifestyle"}
            </div>
          ))}
        </div>
      )}

      <div className="title">Select Template</div>
      <div className="content template-selection-content">
        <TemplateSelectionList
          {...props}
          tags={props.tags}
          fetchingData={props.fetchingData}
          fetchData={props.fetchData}
          filterTemplateTags={props.filterTemplateTags}
          selectedFilterTags={props.selectedFilterTags}
          templates={props.templates}
          saveSelectTemplateSearch={props.saveSelectTemplateSearch}
          searchBy={props.searchBy}
          setSearchBy={value => {
            props.setSearchBy(value);
          }}
        />
      </div>
    </div>
  );
};

const areEqual = (
  prevProps: ITemplateSelectionProps,
  nextProps: ITemplateSelectionProps,
): boolean => {
  return isEqual(prevProps, nextProps);
};

export default memo(TemplateSelection, areEqual);
