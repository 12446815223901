import { useCallback, useEffect, useRef, useState } from "react";
import useDeepEffect from "shared/hooks/useDeepEffect";
import { IExtendedFabricObject } from "shared/types/designStudio";

export default (args: {
  canvas?: fabric.Canvas;
  isMouseDown: boolean;
  isVideoPlaying: boolean;
  margin: {
    top: number;
    left: number;
  };
}): [
  (
    | {
        id: string;
        top: number;
        left: number;
        width: number;
        height: number;
      }
    | undefined
  ),
  IExtendedFabricObject | undefined,
] => {
  const [position, setPosition] = useState<{
    id: string;
    top: number;
    left: number;
    width: number;
    height: number;
  }>();
  const [videoObject, setVideoObject] = useState<IExtendedFabricObject>();
  const reqAnimFrameId = useRef<number>();
  const cancelAnimation = useCallback(
    (videoObj?: IExtendedFabricObject, id?: number) => {
      if (!id || !videoObj) return;

      const videoElement = (
        videoObj as unknown as fabric.Image
      ).getElement() as HTMLVideoElement;
      videoElement.pause();

      window.cancelAnimationFrame(id);
    },
    [],
  );

  useDeepEffect(() => {
    if (!args.canvas) return;

    const activeObject = args.canvas.getActiveObject() as IExtendedFabricObject;
    const shouldRemoveControl =
      !activeObject ||
      !activeObject.name ||
      activeObject.customType !== "selected_video" ||
      args.isMouseDown;
    if (shouldRemoveControl) {
      // check if video objects gets removed.
      // If this is the case ,we need to cancel the animation
      const found = args.canvas
        .getObjects()
        .find(
          obj => (obj as IExtendedFabricObject).customType === "selected_video",
        );
      if (!found) {
        cancelAnimation(videoObject, reqAnimFrameId.current);
      }
      setPosition(undefined);

      return;
    }

    const { top, left, width, height } = activeObject.getBoundingRect();
    setPosition({
      id: activeObject.name!,
      top: top + args.margin.top,
      left: left + args.margin.left,
      width,
      height,
    });

    setVideoObject(activeObject);
  }, [args.canvas?.getActiveObject(), args.isMouseDown]);

  useDeepEffect(() => {
    if (!videoObject) return;
    const { top, left, width, height } = videoObject.getBoundingRect();

    setPosition(() => ({
      id: videoObject.name!,
      top: top + args.margin.top,
      left: left + args.margin.left,
      width,
      height,
    }));
  }, [args.margin]);

  useEffect(() => {
    if (!videoObject) return;

    const videoElement = (
      videoObject as unknown as fabric.Image
    ).getElement() as HTMLVideoElement;
    if (args.isVideoPlaying) {
      // start playing
      videoElement.play();
      reqAnimFrameId.current = window.requestAnimationFrame(function render() {
        args.canvas?.renderAll();

        reqAnimFrameId.current = window.requestAnimationFrame(render);
      });
    } else {
      cancelAnimation(videoObject, reqAnimFrameId.current);
    }

    return () => {
      cancelAnimation(videoObject, reqAnimFrameId.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args.isVideoPlaying]);

  return [position, videoObject];
};
