import { groupBy } from "lodash";
import { titleCase } from "utils/helpers";
import { isUrlInputValid } from "utils/validators";
import { getStoreMatch, getUrlValue } from "../../shared/utils";

import {
  IAdToLoadData,
  IButtonElement,
  ICarouselElement,
  AdLoadAdStatusEnum,
  IInstantExperience,
  IAdLoadReviewAndQAIssue,
  InstantExperienceBodyElement,
  IPhotoElement,
  IElementWithDestination,
  IEUrlValues,
  IFooterElement,
  IInstantExperienceElementDestination,
  InstantExperienceElementStyle,
} from "shared/types/adLibrary";
import { ExpandableTableEntry } from "../../shared/types";
import { AdType } from "screens/adLibrary/facebookUtils/types";
import { AccountUrl, IAccount } from "shared/types/accountManagement";
import { isCarousel, isFooter } from "utils/adLibrary.validators";
import { getEverythingAdKey } from "../reviewStepInnerDrawerV2/adsTree/helpers.adsTree";

const { Carousel, Collection, InstantExperience } = AdType;

type GetLabelIssuesArgs = {
  labels: AccountUrl[];
  adToLoad: IAdToLoadData;
  instantExperiences?: IInstantExperience[];
};

const labelQAMessages = {
  warning:
    "URL Label is unable to find URL. Manage your URLs in the Admin Section.",
  error:
    "URLs are either missing or have invalid format. Manage your URLs in the Admin Section.",
};

type GetIssuesFromGroupArgs = {
  adsToLoad: IAdToLoadData[];
  selectedStores: IAccount[];
};

export const getIssueSummaries = (args: GetIssuesFromGroupArgs) => {
  const labelIssuesGroups = getLabelIssuesFromGroups(args);
  const warning = labelIssuesGroups.find(group => !!group.warning.length)
    ?.warning?.[0];
  const error = labelIssuesGroups.find(group => !!group.error.length)
    ?.error?.[0];
  return { warning, error };
};

const getLabelIssuesFromGroups = ({
  adsToLoad,
  selectedStores,
}: GetIssuesFromGroupArgs) => {
  const groupings = groupAdsToLoadAndsLabelsByStore(adsToLoad, selectedStores);
  const labelIssuesByGroup = groupings.map(grouping =>
    getLabelIssues(grouping.adsToLoad, grouping.labels),
  );
  // Possible TODO: group by Account ID or Store Name
  return labelIssuesByGroup;
};

const groupAdsToLoadAndsLabelsByStore = (
  adsToLoad: IAdToLoadData[],
  selectedStores: IAccount[],
) => {
  const groups = groupBy<IAdToLoadData>(
    adsToLoad,
    adToLoad => adToLoad.account.account_id,
  );

  const adsLabelsGroupings = Object.entries(groups).map(
    ([accountId, adsToLoad]) => {
      const account = adsToLoad[0]?.account;
      const selectedStore = getStoreMatch(selectedStores, account);
      const labels = selectedStore?.labels || [];
      return {
        labels,
        accountId,
        adsToLoad,
        storeName: selectedStore?.dealer_name,
      };
    },
  );
  return adsLabelsGroupings;
};

const getLabelIssues = (adsToLoad: IAdToLoadData[], labels: AccountUrl[]) => {
  const issues = adsToLoad.map(adToLoad => getLabelIssue({ adToLoad, labels }));
  const warnings = issues.filter(
    issue => issue?.status === AdLoadAdStatusEnum.WARNING,
  );
  const errors = issues.filter(
    issue => issue?.status === AdLoadAdStatusEnum.ERROR,
  );
  return {
    error: errors,
    warning: warnings,
  };
};

const getLabelIssue = ({
  labels,
  adToLoad,
}: GetLabelIssuesArgs): IAdLoadReviewAndQAIssue | undefined => {
  const { type: format, inputParameters } = adToLoad.ad;
  const destinationUrl = inputParameters.destinationUrl || "";
  const areUrlsOptional = [
    AdType.Collection,
    AdType.InstantExperience,
  ].includes(format);

  if (areUrlsOptional) {
    return getLabelIssuesFromIE({
      labels,
      ieUrlValues: adToLoad.ieUrlValues,
    });
  }

  if (format === AdType.Carousel) {
    return getLabelIssuesFromCarousel(adToLoad, labels);
  }

  const isError = !isUrlLabelValueValid(destinationUrl, labels);
  if (!isError) return;

  return {
    message: labelQAMessages.error,
    status: AdLoadAdStatusEnum.ERROR,
  };
};

const getLabelIssuesFromCarousel = (
  adToLoad: IAdToLoadData,
  labels: AccountUrl[],
) => {
  const cards = adToLoad.ad.visuals.cards || [];
  const invalidCards = cards.filter(
    card =>
      !!card.destinationUrl &&
      !isUrlLabelValueValid(card.destinationUrl, labels),
  );
  if (!invalidCards.length) return;
  return {
    message: labelQAMessages.error,
    status: AdLoadAdStatusEnum.ERROR,
  };
};

export const isUrlLabelValueValid = (
  value: string | undefined,
  labels: AccountUrl[],
) =>
  value &&
  !labels.some(label => label.name === value) &&
  isUrlInputValid(value);

type GetLabelIssuesFromIEArgs = {
  labels: AccountUrl[];
  instantExperience?: IInstantExperience;
  ieUrlValues?: IEUrlValues;
};
const getLabelIssuesFromIE = ({
  labels,
  ieUrlValues,
}: GetLabelIssuesFromIEArgs) => {
  if (!ieUrlValues) return;
  const invalidLabels = Object.values(ieUrlValues)?.filter(
    labelValue => !isUrlLabelValueValid(labelValue, labels),
  );
  if (!invalidLabels.length) return;
  return {
    message: labelQAMessages.warning,
    status: AdLoadAdStatusEnum.WARNING,
  };
};

const getElementLabelsMap = ({
  element,
  instantExperiences,
  urlLabels,
  parentIds,
}: {
  element: IElementWithDestination;
  instantExperiences?: IInstantExperience[];
  urlLabels?: AccountUrl[];
  parentIds: string[];
}): IEUrlValues => {
  if (isCarousel(element)) {
    return element.child_elements.reduce<IEUrlValues>(
      (cardPhotoLabels, childPhotoElement) => {
        const ieUrlValues = getElementUrlLabel({
          element: childPhotoElement,
          urlLabels,
          instantExperiences,
          parentIds: [...parentIds, element.id],
        });
        return {
          ...cardPhotoLabels,
          ...ieUrlValues,
        };
      },
      {},
    );
  }

  if (element.element_type === "FOOTER") {
    const buttonElement = element.child_elements[0];
    return getElementUrlLabel({
      element: buttonElement,
      urlLabels,
      instantExperiences,
      parentIds: [...parentIds, element.id],
    });
  }

  if (
    element.element_type === "PHOTO" &&
    element.style &&
    element.style !== InstantExperienceElementStyle.FIT_TO_WIDTH
  ) {
    return {};
  }

  return getElementUrlLabel({
    element,
    urlLabels,
    instantExperiences,
    parentIds,
  });
};

const getElementUrlLabel = ({
  element,
  urlLabels,
  instantExperiences,
  parentIds,
}: {
  element: { destination?: IInstantExperienceElementDestination; id: string };
  urlLabels: AccountUrl[] | undefined;
  instantExperiences?: IInstantExperience[];
  parentIds: string[];
}): IEUrlValues => {
  if (element.destination?.instantExperienceId) {
    return getIEUrlValuesMap({
      ieId: element.destination?.instantExperienceId,
      instantExperiences,
      urlLabels,
      parentIds: [...parentIds, element.id],
    });
  }

  const key = getEverythingAdKey({
    parentIds,
    targetId: element.id,
  });
  const url = getUrlValue(element.destination?.urlLabel, urlLabels);
  return {
    [key]: url,
  };
};

/**
 * Recursively gets all the url labels from an instant experience and its nested components
 */
export const getIEUrlValuesMap = ({
  ieId,
  instantExperiences,
  urlLabels,
  parentIds,
}: {
  ieId: string | undefined;
  instantExperiences: IInstantExperience[] | undefined;
  urlLabels: AccountUrl[] | undefined;
  parentIds: string[];
}) => {
  const instantExp = instantExperiences?.find(ie => ie.id === ieId);
  if (!instantExp?.body_elements) return {};

  return instantExp.body_elements
    .filter(doesElementHaveDestination)
    .reduce<IEUrlValues>((componentsUrls, element) => {
      const elementLabelsMap = getElementLabelsMap({
        element,
        instantExperiences,
        urlLabels,
        parentIds: [...parentIds, instantExp.id!],
      });
      return {
        ...componentsUrls,
        ...elementLabelsMap,
      };
    }, {});
};

export const isRowExpandable = (record: IAdToLoadData) =>
  [Carousel, Collection, InstantExperience].includes(record.ad.type);

export const doesElementHaveDestination = (
  element: InstantExperienceBodyElement,
): element is IElementWithDestination =>
  !["VIDEO", "ELEMENT_GROUP"].includes(element.element_type);

export const doesElementHaveChildren = (
  element: InstantExperienceBodyElement,
): element is ICarouselElement | IFooterElement =>
  isCarousel(element) || isFooter(element);

type MapElementToEntriesMeta = {
  labels?: AccountUrl[];
  ieUrlValues?: IEUrlValues;
  instantExperiences?: IInstantExperience[];
};

type MapElementToEntriesArgs = {
  element: IElementWithDestination;
  meta: MapElementToEntriesMeta;
};

export const mapElementToEntries = ({
  element,
  meta,
}: MapElementToEntriesArgs): ExpandableTableEntry[] => {
  if (!element.id) return [];
  const { labels, ieUrlValues } = meta;

  // carousel and footer elements
  if (isCarousel(element)) {
    const parentEl = element;
    const entries: ExpandableTableEntry[] = parentEl.child_elements
      .map<ExpandableTableEntry | null>((childEl, index) => {
        if (!doesElementHaveDestination(childEl)) return null;
        const elementUrlValue = ieUrlValues?.[childEl.id];

        const { hasNestedIE, entry } = getNestedIEEntry(childEl, meta);

        if (hasNestedIE && !entry) return null;
        if (hasNestedIE && entry) return entry;

        return {
          elementId: childEl.id,
          thumbnail: childEl.url,
          name: `IMG ${index + 1}`,
          destinationUrl:
            elementUrlValue ??
            getUrlValue(childEl.destination?.urlLabel || "", labels),
        };
      })
      .filter((entry): entry is ExpandableTableEntry => !!entry);

    return [
      {
        elementId: element.id,
        name: element?.name ?? "Carousel",
        destinationUrl: "",
        children: entries,
      },
    ];
  }

  const elementUrlValue = ieUrlValues?.[element.id];

  // photo and button elements
  const typedElement = isFooter(element) ? element.child_elements[0] : element;

  const { hasNestedIE, entry } = getNestedIEEntry(typedElement, meta);

  if (hasNestedIE && !entry) return [];
  if (hasNestedIE && entry) return [entry];
  return [
    {
      elementId: element.id,
      name: element.name ?? titleCase(element.element_type),
      thumbnail: "url" in typedElement ? typedElement.url : undefined,
      destinationUrl:
        elementUrlValue ??
        getUrlValue(typedElement.destination?.urlLabel || "", labels),
    },
  ];
};

const getChildrenElements = (
  linkedIE: IInstantExperience | undefined | null,
  meta: MapElementToEntriesMeta,
): ExpandableTableEntry[] | undefined => {
  return linkedIE?.body_elements
    ?.filter(doesElementHaveDestination)
    ?.map(element =>
      mapElementToEntries({
        element,
        meta,
      }),
    )
    ?.flat();
};

const getLinkedInstantExperience = (
  element: IPhotoElement | IButtonElement,
  instantExperiences: IInstantExperience[] | undefined,
): IInstantExperience | null => {
  const linkedIEId = element.destination?.instantExperienceId;
  const ieNotFound: IInstantExperience = {
    name: "No linked Instant Experience found",
    id: linkedIEId,
  };

  return linkedIEId
    ? instantExperiences?.find(ie => ie.id === linkedIEId) ?? ieNotFound
    : null;
};

const getNestedIEEntry = (
  element: IPhotoElement | IButtonElement,
  meta: MapElementToEntriesMeta,
): { hasNestedIE: boolean; entry?: ExpandableTableEntry } => {
  const linkedIE = getLinkedInstantExperience(element, meta.instantExperiences);

  const children = getChildrenElements(linkedIE, meta);

  const entry =
    linkedIE && children?.length
      ? {
          elementId: linkedIE.id!,
          name: linkedIE?.name ?? "Instant Experience",
          destinationUrl: "",
          children,
        }
      : undefined;

  return { hasNestedIE: !!linkedIE, entry };
};
