import { CheckCircleTwoTone, LoadingOutlined } from "@ant-design/icons";
import { Modal, Row, Col, Button } from "antd";

import { FC, useEffect, useState } from "react";

import {
  ReviewExportType,
  ExportImageType,
  ExportVideoType,
  ICoopInstruction,
} from "shared/types/assetBuilder";
import { IAccount } from "shared/types/accountManagement";
import { INewOrder } from "shared/types/newOrders";
import { coopInstructions } from "../../../../shared/constants/assetBuilder";

interface IReviewExportResultModalProps {
  isHtmlExport: boolean;
  isVideoExport: boolean;
  exportTypes: Array<ReviewExportType>;
  exportImageType?: ExportImageType | "none";
  exportWFImageType?: ExportImageType;
  exportVideoType?: ExportVideoType;
  exportWFVideoType?: ExportVideoType;
  processingUpload: boolean;
  processingZip: boolean;
  processingPDF: boolean;
  processingFeedPDF: boolean;
  proofUploadData: string | undefined;
  assetsExported: number;
  exportAssetSizeState: number;
  showResultModal: boolean;
  templateName: string;
  folderName: string;
  subject: string;
  proofMessage: string;
  selectedAssets: number;
  offersExported: number;
  pdfUrl: string;
  feedPDFUrl: string;
  beingProcessedTypes: ReviewExportType[];
  exportedAssets: number;
  isExportCompleted: boolean;
  coopSupported: boolean;
  order: INewOrder | null;
  dealer: IAccount | null;
  containsVideo: boolean;
}

interface IReviewExportResultModalHandlers {
  clearExportTypes: () => void;
  toggleResultModal: (toggle: boolean) => void;
  setProcessingUpload: () => void;
  setIsHtmlExport: (toggle: boolean) => void;
  setIsVideoExport: (toggle: boolean) => void;
}

const ReviewExportResultModal: FC<
  IReviewExportResultModalProps & IReviewExportResultModalHandlers
> = ({
  isHtmlExport,
  isVideoExport,
  setIsHtmlExport,
  setIsVideoExport,
  exportTypes,
  exportImageType,
  processingZip,
  exportWFImageType,
  exportVideoType,
  exportWFVideoType,
  clearExportTypes,
  processingUpload,
  processingPDF,
  proofUploadData,
  assetsExported,
  exportAssetSizeState,
  showResultModal,
  toggleResultModal,
  setProcessingUpload,
  templateName,
  folderName,
  subject,
  proofMessage,
  offersExported,
  selectedAssets,
  pdfUrl,
  feedPDFUrl,
  isExportCompleted,
  coopSupported,
  order,
  dealer,
  containsVideo,
}) => {
  const [proofLink, setProofLink] = useState<string>("");
  const [newTabOpened, setNewTabOpened] = useState<boolean>(false);
  const [anchorTag, setAnchorTag] = useState<HTMLAnchorElement | null>(null);

  const parsedUrl = (url?: string) => {
    return url?.replace("https://", "").replace("www.", "") || "";
  };

  const findCoopInstructions = (
    coopInstructions: ICoopInstruction[],
    order: INewOrder,
    dealer: IAccount | null,
  ) => {
    return coopInstructions.find(store => {
      const { dealer_oem } = order || {};
      const { oem: store_oem } = store;
      if ([dealer_oem, store_oem].every(oem => oem === "Subaru")) {
        const cleanedCoopSite = parsedUrl(dealer?.coopDetails?.coopSite);

        const cleanedComplianceSite = parsedUrl(store.compliance_site);

        if (cleanedCoopSite.includes(cleanedComplianceSite)) {
          return order.dealer_oem.includes(store.oem);
        }
      } else {
        return order?.dealer_oem.includes(store.oem);
      }
    });
  };

  useEffect(() => {
    if (!pdfUrl) return;

    const a = document.createElement("a");
    a.href = pdfUrl;
    a.target = "_blank";
    setAnchorTag(a);
    a.click();
    setNewTabOpened(true);
  }, [pdfUrl]);

  useEffect(() => {
    if (!newTabOpened || !anchorTag) return;

    anchorTag.remove();
  }, [anchorTag, newTabOpened]);

  useEffect(() => {
    if (!proofUploadData) {
      return;
    }
    const proofUrlStr = JSON.parse(proofUploadData)?.proofUploadData;
    if (!proofUrlStr) {
      return;
    }
    setProofLink(proofUrlStr);
  }, [proofUploadData]);

  const isCheckCircle = () => {
    if (isVideoExport || isHtmlExport) return isExportCompleted;
    return !processingZip;
  };

  return (
    <>
      <Modal
        title="Export Summary"
        visible={showResultModal}
        onCancel={() => {
          clearExportTypes();
          toggleResultModal(false);
          setIsHtmlExport(false);
          setIsVideoExport(false);
        }}
        footer={[
          <Button
            key="OK"
            onClick={() => {
              setProcessingUpload();
              clearExportTypes();
              toggleResultModal(false);
              setIsHtmlExport(false);
              setIsVideoExport(false);
            }}
          >
            OK
          </Button>,
        ]}
      >
        <div className="review-export-result-modal">
          {exportTypes.includes("PDF") && (
            <Row gutter={24}>
              <Col span={21}>
                <div>
                  <b>{"Download as PDF"}</b>
                  <br />
                  {`Export: ${
                    selectedAssets === exportAssetSizeState ? "All" : "Selected"
                  }`}
                  <br />
                  {`Assets Exported: ${selectedAssets}`}
                  <br />
                  {`Note: If the PDF does not look correct please visit the Build page to check the assets`}
                  <br />
                  {pdfUrl && (
                    <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                      PDF Link
                    </a>
                  )}
                  <br />
                </div>
              </Col>
              <Col span={3}>
                {processingPDF ? (
                  <LoadingOutlined
                    style={{
                      fontSize: "2em",
                      color: `${processingPDF ? "#000000" : "#33cc33"}`,
                    }}
                  />
                ) : (
                  <CheckCircleTwoTone
                    twoToneColor={`${processingPDF ? "#000000" : "#33cc33"}`}
                    style={{
                      fontSize: "2em",
                      color: `${processingPDF ? "#000000" : "#33cc33"}`,
                    }}
                  />
                )}
              </Col>
            </Row>
          )}
          {exportTypes.includes("OFFERPDF") && (
            <Row gutter={24}>
              <Col span={21}>
                <div>
                  <b>{"Download Offer Data PDF"}</b>
                  <br />
                  {feedPDFUrl && (
                    <a
                      href={feedPDFUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Offer Data PDF Link
                    </a>
                  )}
                  <br />
                </div>
              </Col>
              <Col span={3}>
                {processingPDF ? (
                  <LoadingOutlined
                    style={{
                      fontSize: "2em",
                      color: `${processingPDF ? "#000000" : "#33cc33"}`,
                    }}
                  />
                ) : (
                  <CheckCircleTwoTone
                    twoToneColor={`${processingPDF ? "#000000" : "#33cc33"}`}
                    style={{
                      fontSize: "2em",
                      color: `${processingPDF ? "#000000" : "#33cc33"}`,
                    }}
                  />
                )}
              </Col>
            </Row>
          )}
          {exportTypes.includes("ZIP") && (
            <Row gutter={24}>
              <Col span={21}>
                <div>
                  <b>{"Download as ZIP"}</b>
                  <br />
                  {`Export: ${
                    selectedAssets === exportAssetSizeState ? "All" : "Selected"
                  }`}
                  <br />
                  {`Image type: ${exportImageType}`}
                  {containsVideo && (
                    <>
                      <br />
                      Video type: {exportVideoType}
                    </>
                  )}
                  <br />
                  {`Assets Exported: ${selectedAssets}`}
                  <br />
                </div>
              </Col>
              <Col span={3}>
                {isCheckCircle() ? (
                  <CheckCircleTwoTone
                    twoToneColor={isCheckCircle() ? "#33cc33" : "#000000"}
                    style={{
                      fontSize: "2em",
                      color: isCheckCircle() ? "#33cc33" : "#000000",
                    }}
                  />
                ) : (
                  <LoadingOutlined
                    style={{
                      fontSize: "2em",
                      color: isCheckCircle() ? "#33cc33" : "#000000",
                    }}
                  />
                )}
              </Col>
            </Row>
          )}
          {exportTypes.includes("WORKFRONT") && (
            <Row gutter={24}>
              <Col span={21}>
                <div>
                  <b>{"Send To WorkFront"}</b>
                  <br />
                  {`Export: ${
                    selectedAssets === exportAssetSizeState ? "All" : "Selected"
                  }`}
                  <br />
                  {`Assets Exported: ${selectedAssets}`}
                  <br />
                  {`Image type: ${exportWFImageType}`}
                  {containsVideo && (
                    <>
                      <br />
                      Video type: {exportWFVideoType}
                    </>
                  )}
                  <br />
                  {`Workflow Template: ${templateName}`}
                  <br />
                  {`Folder: ${folderName}`}
                  <br />
                  {`Subject: ${subject}`}
                  <br />
                  {`Message: ${proofMessage}`}
                  <br />
                  {`Note: Please `}
                  {
                    <a
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      reload
                    </a>
                  }
                  {` the page if you would like to export to Workfront again`}
                  <br />
                  {!processingUpload && proofUploadData && proofLink && (
                    <a
                      href={proofLink}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Workfront Proof
                    </a>
                  )}
                  <br />
                </div>
              </Col>
              <Col span={3}>
                {processingPDF ? (
                  <LoadingOutlined
                    style={{
                      fontSize: "2em",
                      color: `${processingUpload ? "#000000" : "#33cc33"}`,
                    }}
                  />
                ) : (
                  <CheckCircleTwoTone
                    twoToneColor={`${processingUpload ? "#000000" : "#33cc33"}`}
                    style={{
                      fontSize: "2em",
                      color: `${processingUpload ? "#000000" : "#33cc33"}`,
                    }}
                  />
                )}
              </Col>
            </Row>
          )}
          {exportTypes.includes("CSV") && (
            <Row gutter={24}>
              <Col span={21}>
                <div>
                  <b>{"Download CSV of Feed Data"}</b>
                  <br />
                  {`Offers Exported: ${offersExported}`}
                  <br />
                </div>
              </Col>
              <Col span={3}>
                <CheckCircleTwoTone
                  twoToneColor={"#33cc33"}
                  style={{
                    fontSize: "2em",
                    color: "#33cc33",
                  }}
                />
              </Col>
            </Row>
          )}
          {exportTypes.includes("HTML") && (
            <Row gutter={24}>
              <Col span={21}>
                <div>
                  <b>Download as HTML</b>
                  <br />
                  {`Offers Exported: ${assetsExported}`}
                  <br />
                </div>
              </Col>
              <Col span={3}>
                {isCheckCircle() ? (
                  <CheckCircleTwoTone
                    twoToneColor={isCheckCircle() ? "#33cc33" : "#000000"}
                    style={{
                      fontSize: "2em",
                      color: isCheckCircle() ? "#33cc33" : "#000000",
                    }}
                  />
                ) : (
                  <LoadingOutlined
                    style={{
                      fontSize: "2em",
                      color: isCheckCircle() ? "#33cc33" : "#000000",
                    }}
                  />
                )}
              </Col>
            </Row>
          )}
          {exportTypes.includes("COOP") && coopSupported && (
            <Row gutter={24}>
              <Col span={21}>
                <div>
                  <b>{"Coop Submission"}</b>
                  <br />
                  {`Submit: ${
                    selectedAssets === exportAssetSizeState ? "All" : "Selected"
                  }`}
                  <br />
                  {`Assets Being Submitted: ${selectedAssets}`}
                  <br />
                </div>
              </Col>
              <Col span={3}>
                {processingUpload ? (
                  <LoadingOutlined
                    style={{
                      fontSize: "2em",
                      color: `${processingUpload ? "#000000" : "#33cc33"}`,
                    }}
                  />
                ) : (
                  <CheckCircleTwoTone
                    twoToneColor={`${processingUpload ? "#000000" : "#33cc33"}`}
                    style={{
                      fontSize: "2em",
                      color: `${processingUpload ? "#000000" : "#33cc33"}`,
                    }}
                  />
                )}
              </Col>
            </Row>
          )}
          {exportTypes.includes("COOP") &&
            !coopSupported &&
            order?.dealer_oem && (
              <Row gutter={24}>
                <Col span={24} style={{ height: "35em", overflowY: "scroll" }}>
                  <div>
                    <b>{"Submit for Pre-Approval"}</b>
                    <br />
                    {`Export: ${
                      selectedAssets === exportAssetSizeState
                        ? "All"
                        : "Selected"
                    }`}
                    <br />
                    {`Assets Exported: ${selectedAssets}`}
                    <br />
                    {processingPDF && (
                      <LoadingOutlined
                        style={{
                          fontSize: "2em",
                          color: "#000000",
                        }}
                      />
                    )}
                    <br />
                    {pdfUrl && (
                      <a
                        href={pdfUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        PDF Link
                      </a>
                    )}

                    <br />
                    {`Coop Site: `}
                    {
                      <a
                        href={
                          findCoopInstructions(coopInstructions, order, dealer)
                            ?.compliance_site
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Pre-Approval Site
                      </a>
                    }

                    <br />
                    {`Username: ${dealer?.coopDetails?.coopUsername}`}
                    <br />
                    {`Password: ${dealer?.coopDetails?.coopPassword}`}
                    <br />
                    <br />
                    <p style={{ whiteSpace: "pre-line" }}>
                      {`Instructions to submit for pre-approval: \n${
                        findCoopInstructions(coopInstructions, order, dealer)
                          ?.instructions
                      }`}
                    </p>
                    <br />
                  </div>
                </Col>
              </Row>
            )}
        </div>
      </Modal>
    </>
  );
};

export default ReviewExportResultModal;
