import { useState } from "react";
import { Button, Table } from "antd";
import {
  ElementType,
  IInstantExperience,
  InstantExperienceBodyElement,
} from "shared/types/adLibrary";
import { ColumnType } from "antd/lib/table";
import styles from "./InstantExperiencesImportTable.module.scss";
import { compareString } from "utils/helpers";
import InstantExperiencesEditComponentDrawer from "./instantExperiencesImportTable/InstantExperiencesEditComponentDrawer";
import { useToggle } from "shared/hooks/useToggle";
import InstantExperiencesImportToolbar from "./instantExperiencesImportTable/InstantExperiencesImportToolbar";
import { filterBySearch } from "utils/helpers.toolbar";
import InstantExperiencesImportTableColumnPicker from "./instantExperiencesImportTable/InstantExperiencesImportTableColumnPicker";
import { useLocalStorage } from "shared/hooks/useLocalStorage";
import {
  componentTypeTextMap,
  getInstantExperienceToolbarIssues,
  hasInstantExperienceIssues,
  Issue,
  IssueGroup,
} from "screens/adLibrary/shared/validationUtils";
import { intersection } from "lodash";
import { renderColumnWithEllipsis } from "shared/components/AutoEllipsisText";
import { isImage, isProductSet, isVideo } from "utils/adLibrary.validators";

type Props = {
  instantExperiences: IInstantExperience[];
  selectedInstantExperienceIds: string[];
  setSelectedInstantExperienceIds: (ids: string[]) => void;
  setInstantExperience: (instantExperienceUpdate: IInstantExperience) => void;
  instantExperienceImportIssues: Issue[];
};

type ChildElement = {
  name?: string;
  oem?: string;
  type: ElementType;
  componentName?: string;
  url?: string;
  productHeadline?: string;
  productDescription?: string;
  id?: string;
  isChild: true;
};

export enum ColumnTitle {
  Brand = "Brand",
  InstantExperienceName = "Instant Experience Name",
  ComponentType = "Component Type",
  Name = "Name",
  Media = "Media Link",
  ProductHeadline = "Product Headline",
  ProductDescription = "Product Description",
}
export const plainColumnNames = Object.values(ColumnTitle);

const InstantExperiencesImportTable = ({
  instantExperiences,
  selectedInstantExperienceIds,
  setSelectedInstantExperienceIds,
  setInstantExperience,
  instantExperienceImportIssues,
}: Props) => {
  const [displayDrawer, toggleDisplayDrawer] = useToggle(false);
  const [displayColumnPicker, toggleDisplayColumnPicker] = useToggle(false);
  const [selectedColumns, setSelectedColumns] = useLocalStorage(
    "instantExperiencesReviewImport",
    plainColumnNames,
  );
  const [instantExperienceEdit, setInstantExperienceEdit] =
    useState<IInstantExperience>();
  const [instantExperienceElementEdit, setInstantExperienceElementEdit] =
    useState<InstantExperienceBodyElement>();
  const [searchValue, setSearchValue] = useState<string>("");

  const [elementsToView, setElementsToView] = useState<string[]>([]);

  const [selectedGroupToView, setSelectedGroupToView] = useState<IssueGroup>();

  const filteredInstantExperiences = filterBySearch(
    instantExperiences.filter(instantExperience => {
      if (!selectedGroupToView) return true;

      const bodyElementIds =
        instantExperience.body_elements?.map(element => element.id) ?? [];
      return (
        intersection(elementsToView, [instantExperience.id, ...bodyElementIds])
          .length > 0
      );
    }),
    searchValue,
  );

  const dataSource = filteredInstantExperiences.map(instantExperience => {
    const mapBodyElementToChildElement = (
      element: InstantExperienceBodyElement,
    ): ChildElement => ({
      name: instantExperience.name,
      oem: instantExperience.oem,
      type: element.element_type,
      componentName: element.name,
      url: isImage(element) || isVideo(element) ? element.url : undefined,
      productHeadline: isProductSet(element) ? element.item_headline : "",
      productDescription: isProductSet(element) ? element.item_description : "",
      id: element.id,
      isChild: true,
    });

    const filterBySelectedGroup = (element: InstantExperienceBodyElement) =>
      !selectedGroupToView || elementsToView.includes(element.id ?? "");

    return {
      ...instantExperience,
      children: filterBySearch(
        (instantExperience.body_elements ?? [])
          .filter(filterBySelectedGroup)
          .map(mapBodyElementToChildElement),
        searchValue,
      ),
    };
  });

  function renderItemName(item: ChildElement | IInstantExperience) {
    const isChildElement = !!(item as ChildElement).isChild;
    const componentTypeText = isChildElement
      ? componentTypeTextMap[(item as ChildElement).type]
      : "Settings";

    const onClickItem = () => {
      if (isChildElement) {
        const selectedInstantExperienceToEdit = instantExperiences.find(ie =>
          ie.body_elements?.find(element => element.id === item.id),
        );
        const selectedInstantExperienceElementToEdit =
          selectedInstantExperienceToEdit?.body_elements?.find(
            element => element.id === item.id,
          );
        setInstantExperienceEdit(selectedInstantExperienceToEdit);
        setInstantExperienceElementEdit(selectedInstantExperienceElementToEdit);
      } else {
        setInstantExperienceEdit(item as IInstantExperience);
        setInstantExperienceElementEdit(undefined);
      }

      toggleDisplayDrawer(true);
    };

    if (!componentTypeText) {
      return (item as ChildElement).type;
    }

    return (
      <Button type="link" onClick={onClickItem} style={{ cursor: "pointer" }}>
        {componentTypeText}
      </Button>
    );
  }

  const getCellProps = (
    id: string,
    field: keyof (IInstantExperience & ChildElement),
  ) => {
    const elementHasErrors = (id: string, field: string) =>
      instantExperienceImportIssues?.some(
        issue =>
          issue.id === id && issue.type === "error" && issue.field === field,
      );
    return {
      style: elementHasErrors(id, field) ? { backgroundColor: "#ffcaca" } : {},
    };
  };

  const columns: ColumnType<IInstantExperience>[] = [
    {
      title: ColumnTitle.Brand,
      dataIndex: "oem",
      key: "oem",
      width: 250,
      sorter: (a, b) => compareString(a.oem, b.oem),
      onCell: record => getCellProps(record.id!, "oem"),
    },
    {
      title: ColumnTitle.InstantExperienceName,
      dataIndex: "name",
      key: "ie-name",
      width: 300,
      sorter: (a, b) => compareString(a.name, b.name),
      onCell: record => getCellProps(record.id!, "name"),
    },
    {
      title: ColumnTitle.ComponentType,
      key: "type",
      render: renderItemName,
      width: 200,
      onCell: record => getCellProps(record.id!, "type"),
    },
    {
      title: ColumnTitle.Name,
      key: "name",
      dataIndex: "componentName",
      width: 250,
      onCell: record => getCellProps(record.id!, "componentName"),
    },
    {
      title: ColumnTitle.Media,
      dataIndex: "url",
      key: "url",
      width: 200,
      render: text => renderColumnWithEllipsis(text, 200),
      onCell: record => getCellProps(record.id!, "url"),
    },

    {
      title: ColumnTitle.ProductHeadline,
      key: "product-headline",
      dataIndex: "productHeadline",
      width: 250,
      onCell: record => getCellProps(record.id!, "productHeadline"),
    },
    {
      title: ColumnTitle.ProductDescription,
      key: "product-description",
      dataIndex: "productDescription",
      width: 250,
      onCell: record => getCellProps(record.id!, "productDescription"),
    },
  ];

  const filteredColumns = columns.filter(column =>
    selectedColumns.includes(column.title as ColumnTitle),
  );

  const toggleInstantExperiencesToView = (
    issueGroup: IssueGroup,
    idsToSelect: string[],
  ) => {
    const isSelectedGroup = issueGroup === selectedGroupToView;
    if (isSelectedGroup) {
      setElementsToView([]);
      setSelectedGroupToView(undefined);
    } else {
      setElementsToView(idsToSelect);
      setSelectedGroupToView(issueGroup);
    }
  };

  const issues = getInstantExperienceToolbarIssues(
    instantExperienceImportIssues,
    selectedGroupToView,
    toggleInstantExperiencesToView,
  );

  return (
    <>
      <InstantExperiencesImportToolbar
        issues={issues}
        onSearch={setSearchValue}
        searchValue={searchValue}
        onDisplayColumn={toggleDisplayColumnPicker}
      />
      <Table
        className={styles.instantExperiencesImportTable}
        dataSource={dataSource}
        rowKey="id"
        columns={filteredColumns}
        pagination={false}
        scroll={{ x: "max-content" }}
        expandable={{ expandIconColumnIndex: 3 }}
        rowSelection={{
          selectedRowKeys: selectedInstantExperienceIds,
          onChange: keys => {
            const isInstantExperienceId = (key: string) =>
              instantExperiences.some(ie => ie.id === key);

            return setSelectedInstantExperienceIds(
              keys.map(key => key?.toString()).filter(isInstantExperienceId),
            );
          },
          checkStrictly: false,
          getCheckboxProps: ieRecord => ({
            disabled: hasInstantExperienceIssues(
              ieRecord,
              instantExperienceImportIssues,
            ),
          }),
        }}
      />
      {displayDrawer && (
        <InstantExperiencesEditComponentDrawer
          instantExperienceEdit={instantExperienceEdit}
          setInstantExperience={setInstantExperience}
          setInstantExperienceElementEdit={setInstantExperienceElementEdit}
          instantExperienceElementEdit={instantExperienceElementEdit}
          hideDrawer={toggleDisplayDrawer}
        />
      )}
      {displayColumnPicker && (
        <InstantExperiencesImportTableColumnPicker
          onHide={toggleDisplayColumnPicker}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
        />
      )}
    </>
  );
};

export default InstantExperiencesImportTable;
