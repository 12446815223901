import { useMemo, useState } from "react";
import { useCampaignManagementIds } from "screens/adLibrary/shared/hooks/dataListHooks";
import useAdReviewPreferences from "screens/campaignManagement/adReview/shared/hooks/useAdReviewPreferences";
import useAds from "screens/campaignManagement/adReview/shared/hooks/useAds";
import { getSelectedEntries } from "screens/campaignManagement/adReview/shared/utils/helpers.ad";
import { IAdReviewFields } from "screens/campaignManagement/fields";
import { useDataListURLData } from "shared/components/dataListURL/useDataListURLData";
import { AdReviewTableEntry } from "shared/types/campaignManagement";
import { useFetchCampaignsV2 } from "shared/hooks/adLibrary/facebook/useFetchCampaigns";

export const useAdReviewAds = () => {
  const [isGroupedAds, setGroupedAds] = useState(true);
  const [selectedEntryId, setSelectedEntryId] = useState("");
  const { selectedStore, selectedDateRange } = useAdReviewPreferences();
  const fbAccountId = selectedStore?.fbAccountId || "";

  const { selectedIds } = useCampaignManagementIds();

  const { ads, dataSource, isLoading, isError } = useAds({
    filters: {
      selectedAdIds: selectedIds,
      selectedStore,
      selectedDateRange,
      isGroupedAds: isGroupedAds,
    },
  });
  const { campaignResByAcctId } = useFetchCampaignsV2({
    accountIds: [fbAccountId],
  });

  const selectedEntryIds = useMemo(() => {
    return getSelectedEntries(dataSource, selectedIds, ads).map(
      entry => entry.id,
    );
  }, [ads, dataSource, selectedIds]);

  const selectedEntry = useMemo(() => {
    if (!selectedEntryId) return;
    return dataSource.find(entry => entry.id === selectedEntryId);
  }, [selectedEntryId, dataSource]);

  const data = dataSource.map<AdReviewTableEntry>(value => ({
    ...value,
    key: value.id,
  }));

  const facebookAdsFromEntry = useMemo(() => {
    if (!selectedEntryId) return;
    const ids = selectedEntryId.split("_");
    const selectedFacebookAds = ads.filter(ad => ad.id && ids.includes(ad.id));
    return selectedFacebookAds;
  }, [selectedEntryId, ads]);

  const campaigns = campaignResByAcctId[fbAccountId].data || [];
  return {
    ads,
    ...useDataListURLData<IAdReviewFields, AdReviewTableEntry>({
      isError,
      isLoading,
      data,
    }),
    campaigns,
    selectedEntry,
    selectedEntryIds,
    setSelectedEntryId,
    facebookAdsFromEntry,
    isGroupedAds,
    setGroupedAds,
  };
};
