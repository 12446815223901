import { CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Spin,
  notification,
} from "antd";
import useForm from "antd/lib/form/hooks/useForm";
import { memo, useMemo, useState } from "react";
import { GoogleSignInButton } from "shared/button/GoogleSignInButton";
import { ITableColumn } from "shared/components/dataList";
import useSelectedColumns from "shared/hooks/userPreferences/useSelectedColumns";
import { IAd, IAdExportForm, IExportDataProps } from "shared/types/adLibrary";
import { v4 } from "uuid";
import { useAdLibraryIds } from "../shared/hooks/dataListHooks";
import { useAds } from "../shared/hooks/useAds";
import {
  industrySpecificColumns,
  retailColumns,
  sharedColumns,
} from "./adList/shared/columns";
import {
  Description,
  ErrorDescription,
} from "./adList/shared/components/ExportNotification";
import { useAdExportRequests } from "./adList/shared/hooks/useAdExportRequests";
import {
  EXPORT_GENERATING,
  useGenerateExportUploadData,
} from "./adList/shared/hooks/useGenerateExports";
import { useGoogleSheetsExports } from "./adList/shared/hooks/useGoogleSheetsExports";

const GOOGLE_SHEETS = "googleSheets";

const AdExportDrawer = ({
  showExportDrawer,
  setShowExportDrawer,
  colDefsForExport,
}: {
  showExportDrawer: boolean;
  setShowExportDrawer: (exportDrawer: boolean) => void;
  colDefsForExport: ITableColumn<IAd>[];
}) => {
  const { data: ads } = useAds();
  const { selectedIds: selectedAdIds } = useAdLibraryIds();

  const [form] = useForm<IAdExportForm>();
  const allColumns = [
    ...sharedColumns,
    ...retailColumns,
    ...industrySpecificColumns.auto,
  ];
  const colSlice = (allColumns.length + 1) / 2;
  const { selectedColumns } = useSelectedColumns(allColumns);
  const initialFormValues: IAdExportForm = useMemo(
    () => ({
      rowSelection: "selected",
      columnSelection: "selected",
      destination: "csv",
      selectedColumnNames: selectedColumns,
      selectedRowIds: [],
    }),
    [selectedColumns],
  );
  const [allColsSelected, setAllColsSelected] = useState(false);
  const [allRowsSelected, setAllRowsSelected] = useState(false);
  const [disabledExport, setDisabledExport] = useState(false);
  const [destination, setDestination] = useState("csv");

  const { mutate: generateExportUploadData } = useGenerateExportUploadData();
  const { adExportRequests, handleSetAdExportRequests, removeAdExportRequest } =
    useAdExportRequests();
  const { startGoogleAuthFlow } = useGoogleSheetsExports();

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabledExport(hasErrors);
  };

  const onFormSubmit = (values: IAdExportForm) => {
    const reqUuid = v4();
    setShowExportDrawer(false);
    const type = values.destination === GOOGLE_SHEETS ? GOOGLE_SHEETS : "file";

    const props: IExportDataProps = {
      filenamePrefix: "Ad-Library-Export",
      uuid: reqUuid,
      formData: values,
      allColsSelected,
      allRowsSelected,
      colDefsForExport,
      ads,
      selectedAdIds,
    };

    handleSetAdExportRequests(adExportRequests.concat({ uuid: reqUuid, type }));

    if (type === GOOGLE_SHEETS) {
      startGoogleAuthFlow(props);
      return;
    }

    notification.open({
      type: "success",
      key: reqUuid,
      message: "Exporting",
      icon: <Spin />,
      description: (
        <Description
          urlResponse={{ status: EXPORT_GENERATING, uuid: reqUuid }}
        />
      ),
      duration: null,
      closeIcon: <CloseOutlined />,
      onClose: () => removeAdExportRequest(reqUuid),
      placement: "bottomRight",
    });

    generateExportUploadData(props, {
      onError: () => {
        notification.error({
          key: reqUuid,
          message: <ErrorDescription message="Export Failed" />,
          placement: "bottomRight",
          duration: null,
          onClose: () => removeAdExportRequest(reqUuid),
        });
      },
    });
  };

  const checkboxRow = (title: string) => (
    <div key={`${title}-wrap`} style={{ flexGrow: 1 }}>
      <Checkbox key={title} value={title}>
        {title}
      </Checkbox>
    </div>
  );

  return (
    <Drawer
      getContainer={false}
      title="Export settings"
      width={800}
      placement="right"
      closable={true}
      onClose={() => setShowExportDrawer(false)}
      visible={showExportDrawer}
      footerStyle={{ display: "flex", justifyContent: "flex-end" }}
      footer={
        <>
          <Button type="link" onClick={() => setShowExportDrawer(false)}>
            Cancel
          </Button>
          <GoogleSignInButton
            disabled={disabledExport}
            onClick={form.submit}
            hidden={destination !== GOOGLE_SHEETS}
          />
          <Button
            type="primary"
            disabled={disabledExport}
            onClick={form.submit}
            hidden={destination === GOOGLE_SHEETS}
          >
            Export
          </Button>
        </>
      }
    >
      <Form<IAdExportForm>
        name="ad-export"
        form={form}
        layout="vertical"
        initialValues={initialFormValues}
        id="ad-export-form"
        onFinish={onFormSubmit}
        onFieldsChange={handleFormChange}
      >
        <Row
          gutter={[4, 0]}
          align={"stretch"}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Col span={12}>
            <Form.Item label="Row selection" name="rowSelection">
              <Radio.Group
                onChange={(e: RadioChangeEvent) => {
                  setAllRowsSelected(e.target.value === "all");
                }}
              >
                <Space direction="vertical">
                  <Radio value="selected">Export selected rows</Radio>
                  <Radio value="all">Export all rows</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Column selection" name="columnSelection">
              <Radio.Group
                onChange={(e: RadioChangeEvent) => {
                  setAllColsSelected(e.target.value === "all");
                }}
              >
                <Space direction="vertical">
                  <Radio value="selected">Export selected columns</Radio>
                  <Radio value="all">Export all columns</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Select columns to export"
              name="selectedColumnNames"
              rules={[
                {
                  required: !allColsSelected,
                  message: "Please select one or more columns to export",
                },
              ]}
            >
              <Checkbox.Group style={{ display: "block" }}>
                <Row justify="space-around" align="top">
                  <Col span={11}>
                    {allColumns.slice(0, colSlice).map(checkboxRow)}
                  </Col>
                  <Col span={11}>
                    {allColumns.slice(colSlice).map(checkboxRow)}
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Export for" name="destination">
              <Radio.Group
                onChange={event => setDestination(event.target.value)}
              >
                <Space direction="vertical">
                  <Radio value="excel">Excel</Radio>
                  <Radio value="csv">CSV</Radio>
                  <Radio value="googleSheets">Google Sheets</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default memo(AdExportDrawer);
