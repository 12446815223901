import { useState, useEffect } from "react";

type ImageSize = {
  width: number;
  height: number;
};

const getImageSize = (imageUrl: string): Promise<ImageSize> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = () => reject();
  });
};

export const useImageSize = (imageUrl: string) => {
  const [imageSize, setImageSize] = useState<ImageSize>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const getImgSize = async () => {
      const imgSize = await getImageSize(imageUrl);
      setImageSize(imgSize);
    };
    getImgSize();
  }, [imageUrl]);

  return imageSize;
};
