import { SetStateAction } from "react";
import { Button, Badge, Popconfirm, Tooltip } from "antd";

import { IFacebookAdToLoad } from "shared/types/adLibrary";
import { LoadingOutlined, RollbackOutlined } from "@ant-design/icons/lib/icons";
import { returnAdsProcessed } from "../utils";

interface IProps {
  title: string;
  adShellId: string;
  facebookAdsToLoad: IFacebookAdToLoad[];
  adLoadingStarted?: boolean;
  adLoadingComplete?: boolean;
  overrideBadgeToggle?: boolean;
  overideRollbackDisable?: boolean;
}

interface IHandlers {
  setAdLoadingStarted?: (value: SetStateAction<boolean>) => void;
  setRollbackFired?: (value: SetStateAction<boolean>) => void;
  setRollbackAdShellId?: (value: SetStateAction<string>) => void;
  setAdLoadingComplete?: (value: SetStateAction<boolean>) => void;
}

type AdLoadCollapseHeaderProps = IProps & IHandlers;

const CollapseHeader = (props: AdLoadCollapseHeaderProps) => {
  const {
    title,
    facebookAdsToLoad,
    adShellId,
    adLoadingStarted,
    adLoadingComplete,
    overrideBadgeToggle,

    setAdLoadingStarted,
    setRollbackFired,
    setRollbackAdShellId,
    setAdLoadingComplete,
  } = props;

  const badgeStyle = {
    borderColor: "transparent",
    marginRight: "0.5em",
  };

  const adCount = facebookAdsToLoad?.length ?? 0;
  const { adLoadings, adErrors, adSuccesses, adsProcessed } =
    returnAdsProcessed(facebookAdsToLoad);

  const showBadge = adLoadingStarted || adLoadingComplete;
  const overideRollbackDisable = !adLoadingStarted && !!adsProcessed?.length;

  const RollbackButton = (disabled: boolean) => (
    <Button
      disabled={disabled}
      title="Rollback Ad Load"
      style={{
        float: "right",
        top: "-5px",
        opacity: overrideBadgeToggle ? 0 : 1,
        cursor: overrideBadgeToggle ? "default" : undefined,
      }}
      onClick={event => {
        event.stopPropagation();
      }}
    >
      <RollbackOutlined /> Rollback
    </Button>
  );

  return (
    <div>
      {adLoadingStarted && adLoadings.length > 0 && (
        <LoadingOutlined style={{ marginRight: "12px" }} />
      )}
      {title}
      <span style={{ marginLeft: "1em" }}>
        {!adLoadingStarted && (
          <Badge
            count={`${adCount} ${adCount === 1 ? "Ad" : "Ads"}`}
            overflowCount={999}
            style={{
              ...badgeStyle,
              backgroundColor: "#eeeeee",
              color: "#444444",
            }}
            title="Total ads"
          />
        )}

        <span>
          {showBadge && (
            <Badge
              count={`${adsProcessed.length} / ${adCount}`}
              overflowCount={999}
              style={{
                ...badgeStyle,
                backgroundColor: "#1890ff",
              }}
              title="Ads processed"
            />
          )}
          {(showBadge || overrideBadgeToggle) && (
            <Badge
              count={adSuccesses.length}
              showZero={true}
              overflowCount={999}
              style={{
                backgroundColor: "#52c41a",
                ...badgeStyle,
              }}
              title="Succeeded ad loads"
            />
          )}
          {(showBadge || overrideBadgeToggle) && (
            <Badge
              count={adErrors.length}
              showZero={true}
              overflowCount={999}
              style={{
                ...badgeStyle,
                backgroundColor: "#ff4d4f",
              }}
              title="Failed ad loads"
            />
          )}
        </span>
      </span>
      {/* Possible TO DO: add retry button for retrying failed ads per ad shell */}
      {!adLoadingComplete && !overideRollbackDisable && RollbackButton(true)}
      {(adLoadingComplete || overideRollbackDisable) && (
        <Popconfirm
          placement="topLeft"
          title="Are you sure you want to rollback the creative updates?"
          onCancel={event => {
            event?.stopPropagation();
          }}
          onConfirm={event => {
            event?.stopPropagation();

            setAdLoadingStarted?.(true);
            setAdLoadingComplete?.(false);

            // Go to Step 4 of Ad Load Process, but with rollback state
            setRollbackAdShellId?.(adShellId);
            setRollbackFired?.(true);
          }}
          okText="Yes"
          cancelText="No"
        >
          <Tooltip title={"Revert ads to the state before load"}>
            {RollbackButton(false)}
          </Tooltip>
        </Popconfirm>
      )}
    </div>
  );
};

export default CollapseHeader;
