import { memo } from "react";
import {
  ButtonStyle,
  IButtonElement,
  IFooterElement,
} from "shared/types/adLibrary";
import { getMarkupFromRichText } from "../shared/utils";
import styles from "./ButtonPreview.module.scss";

interface IProps {
  instantExperienceElement: IButtonElement | IFooterElement;
  handleSelectInstantExperienceId?: (instantExperienceId: string) => void;
}

const ButtonPreview = ({
  instantExperienceElement,
  handleSelectInstantExperienceId,
}: IProps) => {
  const isFooterElement = instantExperienceElement.element_type === "FOOTER";
  const buttonElement = isFooterElement
    ? instantExperienceElement.child_elements?.[0]
    : instantExperienceElement;

  const defaultTextHTML =
    "<span style='color:#777777'>Write something...</span>";
  const markup = buttonElement.rich_text?.plain_text
    ? getMarkupFromRichText(buttonElement.rich_text)
    : defaultTextHTML;
  const getButtonColorStyle = (): React.CSSProperties => {
    if (buttonElement?.button_style === ButtonStyle.BUTTON_OUTLINE) {
      return { border: `2px solid #${buttonElement.button_color}` };
    }
    return { backgroundColor: `#${buttonElement?.button_color}` };
  };

  const isNavigable =
    !!handleSelectInstantExperienceId &&
    !!buttonElement.destination?.instantExperienceId;

  return (
    <div
      style={{
        backgroundColor: `#${buttonElement?.background_color}`,
        padding: `${isFooterElement ? 8 : 48}px 0`,
        display: "flex",
        justifyContent: "center",
        width: "100%",
        cursor: isNavigable ? "pointer" : "auto",
      }}
      onClick={() =>
        isNavigable &&
        handleSelectInstantExperienceId?.(
          buttonElement.destination!.instantExperienceId!,
        )
      }
    >
      <div
        className={styles.buttonPreview}
        style={{
          color: `#${buttonElement?.text_color}`,
          fontSize: buttonElement?.font_size,
          fontFamily: buttonElement?.font_family,
          ...getButtonColorStyle(),
        }}
        dangerouslySetInnerHTML={{
          __html: markup,
        }}
      />
    </div>
  );
};

export default memo(ButtonPreview);
