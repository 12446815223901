import { useMemo } from "react";
import { useInfiniteQuery } from "react-query";

type ResponseWithPaging<T> = T & {
  paginationKey?: string;
};

type QueryKey = string | readonly unknown[];

type Props<T, ResponseType> = {
  queryKey: QueryKey;
  queryFn: (params: {
    paginationKey?: string;
  }) => Promise<ResponseWithPaging<T>>;
  getDataFromResponse: (response: ResponseWithPaging<T>) => ResponseType[];
  enabled?: boolean;
  getPaginationKey?: (response: ResponseWithPaging<T>) => string | undefined;
};

export const useInfiniteFetchAll = <T, ResponseType>({
  queryKey,
  queryFn,
  enabled,
  getDataFromResponse,
  getPaginationKey,
}: Props<T, ResponseType>) => {
  const {
    data,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }) => queryFn({ paginationKey: pageParam }),
    getNextPageParam: params => {
      if (getPaginationKey) {
        return getPaginationKey(params);
      }
      return !!params.paginationKey ? params.paginationKey : undefined;
    },
    staleTime: 300000,
    enabled,
  });

  if (hasNextPage && !isFetchingNextPage) {
    fetchNextPage();
  }

  const flattenData = useMemo(
    () => data?.pages.flatMap(getDataFromResponse),
    [data, getDataFromResponse],
  );

  const isLoadingSomething = isLoading || !!hasNextPage || isFetchingNextPage;

  return { data: flattenData, isLoading: isLoadingSomething, isError };
};

export type PagedQueryResponse<T> = {
  pageParams: (string | undefined)[];
  pages: ResponseWithPaging<T>[];
};
