import { useMemo, useState } from "react";

import styles from "./CampaignPlannerTable.module.scss";
import { useCampaignPlanner } from "../CampaignPlannerContext";
import { CampaignPlanner } from "../types";

import { useWindowSize } from "shared/hooks/useWindowSize";
import { CampaignPlannerColumn, getColumns } from "./dataList/columns";
import DataListURLTable from "shared/components/dataListURL/DataListURLTable";
import {
  CampaignPlannerFieldKeys,
  campaignPlannerFields,
} from "./dataList/fields";
import { useCampaignPlannerIds } from "screens/adLibrary/shared/hooks/dataListHooks";
import { CampaignPlannerInstanceRowTable } from "./CampaignPlannerInstanceRowTable";
import {
  EditableColumnParameters,
  setupEditableCells,
} from "shared/components/dataList/setupEditableCells";
import { isNewItem } from "../utils";
import { RightOutlined } from "@ant-design/icons";

export const CampaignPlannerTable = () => {
  const {
    data,
    onEditEnd,
    loading,
    setSelectedInstances,
    newInstance,
    selectedInstances,
  } = useCampaignPlanner();
  const { selectedIds, setSelectedItemIds } = useCampaignPlannerIds();
  const { windowInnerHeight } = useWindowSize();
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const { columns: editableColumns, components } = useMemo(
    () =>
      setupEditableCells<
        CampaignPlanner,
        CampaignPlannerColumn & EditableColumnParameters<CampaignPlanner>
      >({
        columns: getColumns<CampaignPlanner>(onEditEnd),
        handleSave: onEditEnd,
      }),
    [onEditEnd],
  );

  const expandedRows = useMemo(
    () =>
      newInstance?.plannerId
        ? expandedRowKeys.concat(newInstance?.plannerId)
        : expandedRowKeys,
    [expandedRowKeys, newInstance?.plannerId],
  );

  const onSelectionChange = (
    record: CampaignPlanner,
    selected: boolean,
    records: CampaignPlanner[],
  ) => {
    setSelectedItemIds(records.map(planner => planner.id));

    // select all children instances
    const addedInstances = records.reduce(
      (acc, planner) => ({
        ...acc,
        [planner.id]:
          "instances" in planner && planner.id == record.id && selected
            ? planner.instances.map(instance => instance.id)
            : [],
      }),
      {},
    );

    const deletedInstances =
      selectedInstances.hasOwnProperty(record.id) && !selected
        ? {
            [record.id]: [],
          }
        : {};

    setSelectedInstances(prevInstances => ({
      ...prevInstances,
      ...addedInstances,
      ...deletedInstances,
    }));
  };

  return (
    <DataListURLTable<CampaignPlannerFieldKeys, CampaignPlanner>
      fieldKeys={campaignPlannerFields}
      type="hoverActionsVirtualTable"
      size="small"
      loading={loading}
      scroll={{ y: windowInnerHeight - 200 }}
      className={styles.table}
      dataSource={data}
      columns={editableColumns}
      components={components}
      rowKey="id"
      rowSelection={{
        selectedRowKeys: selectedIds,
        onSelect: onSelectionChange,
      }}
      rowClassName={(record: CampaignPlanner) =>
        isNewItem(record) ? styles.newRow : ""
      }
      pagination={false}
      expandable={{
        onExpand: (expanded, record) => {
          if (expanded) {
            setExpandedRowKeys(expandedKeys => [...expandedKeys, record.id]);
          } else {
            setExpandedRowKeys(expandedKeys =>
              expandedKeys.filter(key => key !== record.id),
            );
          }
        },
        expandedRowRender: (record: CampaignPlanner) =>
          "instances" in record && (
            <CampaignPlannerInstanceRowTable planner={record} />
          ),
        rowExpandable: (record: CampaignPlanner) => {
          return !!(
            record.instances.length || record.id === newInstance?.plannerId
          );
        },
        expandedRowKeys: expandedRows,
        expandIcon: ({ expanded, onExpand, record }) => {
          return (
            record.instances.length > 0 && (
              <RightOutlined
                style={{
                  transition: "all 0.1s ease-in-out",
                  transform: `${
                    expanded ? "rotate(90deg)" : "rotate(0deg)"
                  } scale(0.8)`,
                }}
                onClick={e => onExpand(record, e)}
              />
            )
          );
        },
      }}
    />
  );
};
