import { capitalize, flatten } from "lodash";

import {
  AdType,
  FacebookCampaignObjective,
  IFacebookCampaign,
} from "screens/adLibrary/facebookUtils/types";
import { IAd } from "shared/types/adLibrary";

export const returnTitleFromCampaignObjective = (
  campaignObjective: FacebookCampaignObjective,
) =>
  campaignObjective
    .split("_")
    .map(part => capitalize(part))
    .join(" ");

export const returnCanAdBeLoadedToCampaign = (
  ad: IAd,
  objective: FacebookCampaignObjective,
) => {
  const subFormat = ad.visuals.format;
  const isDynamic = [AdType.AIA, AdType.DPA, AdType.FTA].includes(ad.type);

  switch (objective) {
    case FacebookCampaignObjective.VIDEO_VIEWS:
      return AdType.Video === ad.type;
    case FacebookCampaignObjective.LINK_CLICKS:
    case FacebookCampaignObjective.CONVERSIONS:
    case FacebookCampaignObjective.REACH:
    case FacebookCampaignObjective.OUTCOME_LEADS:
      return !isDynamic;
    case FacebookCampaignObjective.OUTCOME_TRAFFIC:
    case FacebookCampaignObjective.OUTCOME_AWARENESS:
      return !isDynamic || (isDynamic && subFormat === "carousel");
    case FacebookCampaignObjective.PRODUCT_CATALOG_SALES:
    case FacebookCampaignObjective.OUTCOME_SALES: {
      return true;
    }
    default:
      return false;
  }
};

export const returnAdsetsFromCampaignData = (campaigns: IFacebookCampaign[]) =>
  flatten(campaigns.map(campaigns => campaigns.adsets?.data ?? []));
