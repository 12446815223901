import { CloseOutlined, FilterFilled } from "@ant-design/icons";
import { Button } from "antd";
import classNames from "classnames";
import TagList, { TagType } from "shared/components/TagList";
import { useDataList } from "shared/components/dataList";
import styles from "./LogoSelectFilters.module.scss";
import startCase from "lodash/startCase";
import { useMemo } from "react";
import { DEFAULT_KEY } from "../dataList/useDataList";

const displayItemCount = (filteredTotalCount: number, totalCount: number) => {
  if (filteredTotalCount !== totalCount)
    return `${filteredTotalCount} / ${totalCount} items`;
  return `${totalCount} items`;
};

export const LogoSelectFilterTags = () => {
  const [dataList, dataListActions] = useDataList();

  const tags: TagType[] = useMemo(
    () =>
      Object.entries(dataList.filters[DEFAULT_KEY]).reduce<TagType[]>(
        (acc, [key, val]) => {
          return [
            ...acc,
            ...val.map(value => ({
              key,
              value,
              displayValue: `${startCase(key)}: ${value}`,
            })),
          ];
        },
        [],
      ),
    [dataList.filters],
  );

  return (
    <>
      <div className={styles.host} data-cy="datalist-tags">
        <span>
          {displayItemCount(dataList.rows.length, dataList.data.length ?? 0)}
        </span>
        <strong>Filters</strong>
        <Button
          data-cy="datalist-filter-button"
          onClick={dataListActions.toggleSelectedItems}
          size="small"
          type={dataList.showSelected ? "primary" : "default"}
          disabled={!dataList.selectedIds.length}
          className={classNames([
            styles.filterButton,
            dataList.showSelected ? "ant-tag-blue" : "ant-tag-default",
          ])}
        >
          {dataList.selectedIds.length} selected
          {dataList.showSelected && <CloseOutlined className={styles.close} />}
          {!dataList.showSelected && !!dataList.selectedIds.length && (
            <FilterFilled />
          )}
        </Button>
        <div>
          <TagList
            tags={tags}
            onClear={() => {
              dataListActions.resetFilters();
            }}
            onRemove={(key, value) => {
              dataListActions.removeFilter(key, value);
            }}
          />
        </div>
      </div>
    </>
  );
};
