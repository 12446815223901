import { useHotkeys } from "react-hotkeys-hook";

type Args = {
  redo: () => void;
  undo: () => void;
};
export default (args: Args) => {
  useHotkeys("cmd+z", e => {
    e.preventDefault();
    args.undo();
  });
  useHotkeys("cmd+y,cmd+shift+y", e => {
    e.preventDefault();
    args.redo();
  });
};
