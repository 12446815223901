import { isCustomOfferKey, isRebateOfferKey } from "shared/types/assetBuilder";
import { TCellOfferKey } from "./Cell";

export const getOfferKey = (
  offerKey: TCellOfferKey,
  repeatableIndex?: number,
) => {
  const index = (repeatableIndex || 0) + 1;
  if (isRebateOfferKey(offerKey)) return generateOfferKey(offerKey, index);
  if (isCustomOfferKey(offerKey)) return `${offerKey}${index}`;

  return offerKey;
};

const generateOfferKey = (key: TCellOfferKey, index: number) => {
  if (key.endsWith("Rebate")) return `${key}${index}`;
  if (key.endsWith("Name")) return `${key.replace("Name", `${index}Name`)}`;
  return `${key.replace("Disclosure", `${index}Disclosure`)}`;
};
