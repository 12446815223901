const currencyFormatter = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const toUSD = (value: number | string | undefined) => {
  let num = 0;
  if (typeof value === "string") {
    try {
      num = Number.parseFloat(value);
    } catch (err) {
      // nothing to do
    }
  } else {
    num = value || 0;
  }

  return currencyFormatter.format(num);
};
