import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useQuery } from "react-query";
import { reducer } from "./reducer";
import { IDataListConfig, IDataListContext } from "./types";
import { filterSelectedRows, getInitialState, getRows } from "./utils";
import { DEFAULT_KEY } from "./useDataList";

const DataListContext = createContext<IDataListContext>([] as any);

export const useDataListContext = () => useContext(DataListContext);

interface Props {
  children: ReactNode;
  config: IDataListConfig;
}

export const DataListProvider = ({ children, config }: Props) => {
  const [state, dispatch] = useReducer(reducer, getInitialState(config));
  const { useQueryOptions } = config;
  const { data = [], isLoading, isError } = useQuery<any>(useQueryOptions);
  const [tableId, setTableId] = useState(DEFAULT_KEY);

  const selectedItems = useMemo(
    () => filterSelectedRows(data, state.selectedIds),
    [data, state.selectedIds],
  );
  const rows = useMemo(
    () => getRows(data, state, selectedItems, tableId),
    [data, selectedItems, state, tableId],
  );

  return (
    <DataListContext.Provider
      value={[
        {
          ...state,
          rows,
          isLoading,
          isError,
          selectedItems,
          data,
          tableId,
          setTableId,
        },
        dispatch,
      ]}
    >
      {children}
    </DataListContext.Provider>
  );
};
