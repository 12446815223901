import { selectSelectedAccounts } from "redux/adLibrary/adLibrary.selectors";
import { useFetchCampaigns } from "shared/hooks/adLibrary/facebook/useFetchCampaigns";
import { useAppSelector } from "shared/hooks/useAppSelector";

export const useFetchCampaignsToCreate = () => {
  const selectedAccounts = useAppSelector(selectSelectedAccounts);

  const {
    isLoading: isLoadingCampaigns,
    isFetching: isFetchingCampaigns,
    data: campaigns,
  } = useFetchCampaigns(
    {
      adAccountIds: selectedAccounts?.map(account => account.account_id),
      includeAdsets: true,
      includeAds: false,
      pullDataViaPagination: true,
      limit: 50,
    },
    { enabled: !!selectedAccounts?.length },
  );

  return { campaigns, isLoadingCampaigns, isFetchingCampaigns };
};
