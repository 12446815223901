/* eslint-disable react/display-name */
import { Badge, Table } from "antd";
import ItemCountToolbar from "screens/adLibrary/shared/components/ItemCountToolbar";
import { usePMaxDataContext } from "shared/components/contextAPI/pmaxAssetGroup/PMaxDataProvider";
import {
  AdAccountWithDealerName,
  GoogleAdsFeedStatusType,
  GoogleAdsPmaxStatusType,
  IAssetGroup,
  NameID,
} from "shared/types/adLibrary";
import NameTag from "../../shared/components/NameTag";
import AdAccountList from "../pmaxAssetGroupList/AdAccountList";
import styles from "./UpdateExistingAssetGroups.module.scss";
import { isUnableToLoad } from "shared/components/contextAPI/pmaxAssetGroup/PmaxDataProvider.utils";

const UpdateExistingAssetGroups = () => {
  const getBadge = (
    status: GoogleAdsPmaxStatusType | GoogleAdsFeedStatusType,
  ) => {
    switch (status) {
      case "Draft":
        return <Badge status="processing" />;
      case "Up to date":
      case "Loaded":
        return <Badge status="success" />;
      default:
        return <Badge status="default" />;
    }
  };
  const getUpdatedPmaxStatus = (
    feedStatus: GoogleAdsFeedStatusType,
    pmaxStatus: GoogleAdsPmaxStatusType,
  ): GoogleAdsPmaxStatusType => {
    switch (feedStatus) {
      case "Deleted from feed":
        return "Paused";
      case "Up to date":
        return "Loaded";
      case "Draft":
        if (pmaxStatus === "Paused") return "Loaded";
        else return "Never Loaded";
      default:
        return "Never Loaded";
    }
  };
  const ctx = usePMaxDataContext();
  const columns = [
    {
      title: "Asset group name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Feed status",
      dataIndex: "feedStatus",
      key: "feedStatus",
      render: (statusValue: GoogleAdsFeedStatusType) => {
        return (
          <div>
            {getBadge(statusValue)}
            <span>{statusValue}</span>
          </div>
        );
      },
    },
    {
      title: "PMax status",
      dataIndex: "pmaxStatus",
      key: "pmaxStatus",
      render: (statusValue: GoogleAdsPmaxStatusType, record: IAssetGroup) => {
        const { feedStatus } = record;
        const updatedPmaxStatus = getUpdatedPmaxStatus(feedStatus, statusValue);
        const wontUpdateStatus = isUnableToLoad(record, "update");

        return (
          <div className={styles.pmaxStatus}>
            <span>
              {getBadge(statusValue)}
              <span>{statusValue}</span>
            </span>
            {!wontUpdateStatus && (
              <>
                <span>update to</span>
                <span>
                  {getBadge(updatedPmaxStatus)}
                  <span>{updatedPmaxStatus}</span>
                </span>
              </>
            )}
          </div>
        );
      },
    },
    {
      title: "Account",
      dataIndex: "adAccounts",
      key: "adAccount",
      render: (adAccounts: AdAccountWithDealerName[]) => (
        <AdAccountList adAccounts={adAccounts} />
      ),
    },
    {
      title: "Campaigns",
      dataIndex: "campaigns",
      key: "campaigns",
      render: (campaigns: NameID[]) =>
        campaigns?.map((campaign: NameID) => (
          <NameTag id={campaign.id} name={campaign.name} key={campaign.id} />
        )),
    },
  ];

  const totalDataCount = ctx.selectedDataSource.length;
  const computedSelection = ctx.getComputedSelection("new")?.map(grp => grp.id);

  return (
    <div className={styles.container}>
      <ItemCountToolbar
        totalCount={totalDataCount}
        selectedCount={computedSelection.length}
      />
      <Table
        rowClassName={(record: IAssetGroup) => {
          return isUnableToLoad(record, "update")
            ? `${styles.notLoadable}`
            : "";
        }}
        rowSelection={{
          type: "checkbox",
          getCheckboxProps: (record: IAssetGroup) => ({
            disabled: isUnableToLoad(record, "update"),
          }),
          selectedRowKeys: computedSelection,
          onChange: ctx.setDrawerSelection,
        }}
        columns={columns}
        dataSource={ctx.selectedDataSource.map(src => ({
          ...src,
          key: src.id,
        }))}
        pagination={false}
        bordered={false}
      ></Table>
    </div>
  );
};

export default UpdateExistingAssetGroups;
