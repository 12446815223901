import API from "services";
import { useMutation, useQueryClient } from "react-query";
import { AudienceIntegrationLog } from "../types";
import { audienceIntegrationLogsQueryKey } from "../constants";

type Params = {
  id: string;
  notes: string;
};

export const useMutateAudienceIntegrationLog = () => {
  const queryClient = useQueryClient();

  const updateLog = async ({ id, notes }: Params) => {
    const { result, error } =
      await API.services.adLibrary.updateAudienceIntegrationLog(id, { notes });

    if (error || !result) throw Error(error?.message ?? "Unknown error");

    return result;
  };

  return useMutation<AudienceIntegrationLog, Error, Params>(updateLog, {
    onSettled: () => {
      queryClient.invalidateQueries(audienceIntegrationLogsQueryKey);
    },
  });
};
