import { memo } from "react";
import GenericBrandSelect from "shared/components/select/GenericBrandSelect";

interface IProps {
  selectedBrand?: string;
  defaultValue?: string;
  onChange?(selectedBrands: string): void;
  onFocus?: React.FocusEventHandler<HTMLElement>;
  disabled?: boolean;
}

function SingleBrandSelect({
  selectedBrand,
  defaultValue,
  onChange,
  onFocus,
  disabled,
}: IProps) {
  return (
    <GenericBrandSelect
      onChange={onChange}
      onFocus={onFocus}
      value={selectedBrand}
      defaultValue={defaultValue}
      disabled={disabled}
      placeholder={"Select Brand"}
    />
  );
}

export default memo(SingleBrandSelect);
