import { useGetRequiredData } from "./useGetRequiredData";
import useGetIsMounted from "shared/hooks/useGetIsMounted";
import {
  useCreateFacebookAd,
  HandleFacebookAdArgs,
  useMutateFacebookAd,
  useUpdateFacebookAd,
} from "shared/hooks/adLibrary/adLoad/useMutateFacebookAd";

import {
  returnLoadToFacebookArgs,
  recursivelyRacePromises,
  handleLoadProcess,
} from "../../../loadStep/helpers.load";

import { HandleAdLoadProcessStepArgs, PromiseTracker } from "../../types";
import { IFacebookAdCreative } from "screens/adLibrary/facebookUtils/types";
import { IAdToLoadData } from "shared/types/adLibrary";

export const useHandleLoadAdToFacebook = () => {
  const isMounted = useGetIsMounted();
  const { selectedAccounts, selectedDealers } = useGetRequiredData();

  const { mutateAsync: createFacebookAd } = useCreateFacebookAd();
  const { mutateAsync: updateFacebookAd } = useUpdateFacebookAd();
  const { mutateAsync: loadAdToFacebook } = useMutateFacebookAd();

  const getCallback = (adToLoad: IAdToLoadData) => {
    const isUpdateFLow = !!adToLoad.facebookAdId;

    return isUpdateFLow
      ? (parameters: HandleFacebookAdArgs) =>
          updateFacebookAd({
            params: {
              creative: parameters.params.creative as IFacebookAdCreative,
            },
            facebookAd: {
              ...adToLoad.facebookAd,
              id: adToLoad.facebookAdId,
            },
            facebookAdAccount: parameters.facebookAdAccount,
          })
      : createFacebookAd;
  };

  return (args: HandleAdLoadProcessStepArgs) => {
    if (!args.setCurrentAdToLoad || !isMounted()) return;
    const promiseObjs: PromiseTracker[] = args.selectedAdsToLoad.map(
      adToLoad => {
        const callback = getCallback(adToLoad);

        const loadToFBArgs = {
          ...returnLoadToFacebookArgs({
            adToLoad,
            selectedDealers,
            selectedAccounts,
          }),
          handleFacebookAd: callback,
        };
        return {
          key: adToLoad.key,
          promise: handleLoadProcess(
            adToLoad,
            () => loadAdToFacebook(loadToFBArgs),
            () => Promise.resolve(adToLoad),
          ),
        };
      },
    );

    recursivelyRacePromises(promiseObjs, args.setCurrentAdToLoad);
  };
};
