import { Button, Typography } from "antd";
import { CAMSelect } from "../media";

type Props = {
  searchPayload?: MGSearch;
  titleText: string;
  isLoading: boolean;
  matchingLogos: WDAsset[];
};

const { Text } = Typography;

export const MediaLogosCollapseTitle = ({
  titleText,
  isLoading,
  matchingLogos,
  searchPayload,
}: Props) => {
  return (
    <div style={{ marginBottom: "0.5em" }}>
      <Text>{titleText}</Text>
      <CAMSelect search={searchPayload}>
        <Button
          disabled={isLoading || !searchPayload}
          size="small"
          type="link"
        >{`(${matchingLogos.length})`}</Button>
      </CAMSelect>
    </div>
  );
};
