import API from "services";
import { useMutation, useQueryClient } from "react-query";
import { useUser } from "shared/hooks/useUser";
import { GoogleAdCampaignInternal } from "shared/types/adLibrary";
import { pmaxCampaignsQueryKey } from "./constants";
import { message } from "antd";

const loadKey = "creatingCamapaigns";

export const useCreatePmaxCampaigns = () => {
  const user = useUser();
  const queryClient = useQueryClient();
  return useMutation(
    (campaigns: GoogleAdCampaignInternal[]) => {
      if (!user) return Promise.reject("User not found");
      return API.services.adLibrary.createPmaxCampaigns({
        campaigns,
        user,
      });
    },
    {
      onMutate: () => {
        message.loading({ content: "Creating Campaigns...", key: loadKey });
      },
      onSettled: () => {
        message.destroy(loadKey);
        queryClient.invalidateQueries(pmaxCampaignsQueryKey);
      },
    },
  );
};
