import { Badge, Typography } from "antd";
import { GoogleAdCampaignInternalStatus } from "shared/types/adLibrary";
import { AppColors } from "shared/types/shared";

export const CampaignLoadStatusCell = ({
  loadStatus,
}: {
  loadStatus: GoogleAdCampaignInternalStatus;
}) => {
  const badgeColor =
    loadStatus === "Loaded" ? AppColors.success : AppColors.primary;
  return (
    <Badge
      color={badgeColor}
      text={<Typography.Text>{loadStatus}</Typography.Text>}
      dot
    />
  );
};
