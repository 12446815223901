import { IAdSessionFields } from "screens/adLibrary/adLoadSessions/sessionsTable/fields";
import { AdLoadSessionTableEntry } from "screens/adLibrary/adLoadSessions/types";
import { useDataListURLData } from "shared/components/dataListURL/useDataListURLData";
import { SessionLoadStatus } from "shared/types/adLibrary";
import { useFetchInfiniteSessions } from "./useFetchSessions";

export const useFetchAllSessions = () => {
  const { adHistorySessions, ...result } = useFetchInfiniteSessions();

  const data = adHistorySessions.map<AdLoadSessionTableEntry>(value => ({
    ...value,
    key: value.id,
    loadStatus: value.loadStatus ?? SessionLoadStatus.Unknown,
  }));

  return {
    ...result,
    ...useDataListURLData<IAdSessionFields, AdLoadSessionTableEntry>({
      data,
      isError: result.isError,
      isLoading: result.isLoading,
    }),
  };
};
