import { Key } from "react";
import { usePMaxDataContext } from "shared/components/contextAPI/pmaxAssetGroup/PMaxDataProvider";
import { TableFiltersSection } from "shared/components/TableFiltersSection";

interface FilterObj {
  key: string;
  value: Key | boolean;
}

const FilterToolbar = () => {
  const ctx = usePMaxDataContext();

  const {
    selectedRowIds,
    dataSource,
    setDataFilters,
    dataFilters,
    clearFilters,
    removeFilter,
    filteredInfo,
    originalDataSource,
  } = ctx;

  const toggleSelectedItemsFilter = () => {
    setDataFilters?.({
      displaySelectedOnly: !dataFilters?.displaySelectedOnly,
      search: dataFilters?.search || "",
    });
  };

  const tags: FilterObj[] = Object.entries(filteredInfo).reduce<FilterObj[]>(
    (acc, [key, val]) => {
      if (val) return [...acc, ...val.map(value => ({ key, value }))];
      return acc;
    },
    [],
  );

  return (
    <>
      <TableFiltersSection
        currentItems={dataSource.length}
        totalItems={originalDataSource.length}
        selectedCount={selectedRowIds.length}
        displaySelectedOnly={!!dataFilters?.displaySelectedOnly}
        onFilterClick={toggleSelectedItemsFilter}
        tags={tags}
        clearTags={clearFilters}
        removeTag={removeFilter}
      />
    </>
  );
};

export default FilterToolbar;
