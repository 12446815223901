import { useState } from "react";
import { isTextbox } from "screens/assetExporter/feedConfigurationV2/shared/validators";
import useDeepEffect from "shared/hooks/useDeepEffect";
import { TExtendedTextbox } from "shared/types/designStudio";
import { findTriggerPosition } from "utils/helpers.fabric";

export default (args: {
  canvas?: fabric.Canvas;
  activeObject?: fabric.Object;
  isMouseDown: boolean;
}): [
  (
    | {
        top: number;
        left: number;
        width: number;
        height: number;
      }
    | undefined
  ),
  TExtendedTextbox | undefined,
] => {
  const { isMouseDown, activeObject, canvas } = args;
  const [position, setPosition] = useState<{
    top: number;
    left: number;
    width: number;
    height: number;
  }>();
  const [aliasObject, setAliasObject] = useState<TExtendedTextbox>();
  useDeepEffect(() => {
    if (!canvas) return;

    if (!activeObject || activeObject.type !== "textbox") {
      setPosition(undefined);
      setAliasObject(undefined);

      return;
    }

    if (isMouseDown) {
      setPosition(undefined);

      return;
    }

    if (!isTextbox(activeObject)) return;

    setPosition(
      findTriggerPosition(canvas, activeObject, {
        top: 0,
        left: 0,
      }),
    );

    setAliasObject(activeObject);
  }, [canvas, activeObject, isMouseDown]);

  return [position, aliasObject];
};
