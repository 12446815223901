import { PropsWithChildren, useState } from "react";
import { message as AntMessage, Modal } from "antd";
import actions from "redux/rootActions";
import API from "services";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import "../ArtboardDrawer.scss";
import {
  calcTemplateCount,
  defaultArtboard,
  getImageBody,
  isMissingFields,
  isNameDuplicated,
} from "../artboardDrawer/utils";

//User Access
import { useUser } from "shared/hooks/useUser";

import { createContext } from "react";
import { useIsAdmin } from "shared/hooks/useIsAdmin";
import {
  DrawerBaseProps,
  DrawerFormActionProps,
  DrawerLogicProps,
  IFileList,
} from "../artboardDrawer/types";
import { useDrawerRow } from "./useDrawerRow";
import { useDrawerImage } from "./useDrawerImage";
import { IArtboard } from "shared/types/designStudio";
import { useDrawerArtboard } from "./useDrawerArtboard";

type DrawerContextProps = DrawerBaseProps &
  DrawerLogicProps &
  DrawerFormActionProps;

export const DrawerContext = createContext<DrawerContextProps>(
  {} as DrawerContextProps,
);

type DrawerProviderProps = DrawerBaseProps;

export const DrawerProvider = ({
  children,
  artboardToEdit,
  mode = "",
  closeDrawer,
  resetArtboardToEdit,
}: PropsWithChildren<DrawerProviderProps>) => {
  const dispatch = useAppDispatch();
  const user = useUser();

  const isAdmin = useIsAdmin();

  const checkArtboard = artboardToEdit ?? defaultArtboard;

  const [customName, setCustomName] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [searchBoxValue, setSearchBoxValue] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [artboard, setArtboard] = useState<IArtboard>(checkArtboard);

  const {
    updateBgImage,
    editThemeImageFile,
    fileList,
    setFileList,
    setFilteredFileList,
    currImage,
    setCurrImage,
    setBgImages,
    bgImages,
    ...drawerImage
  } = useDrawerImage({
    setSearchBoxValue,
    artboard,
    setShowModal,
    setCustomName,
  });

  const { doesArtboardHaveChanges, templates, artboards, creatingData } =
    useDrawerArtboard({
      artboard,
      artboardToEdit,
      setArtboard,
      setFileList,
      setFilteredFileList,
      closeDrawer,
    });

  const {
    setSelectedBrandNames,
    setSelectedAccountNames,
    setSelectedTagNames,
    ...drawerRow
  } = useDrawerRow({
    currImage,
    editThemeImageFile,
    isEdit,
    setCurrImage,
    updateBgImage,
  });

  const templateCount = calcTemplateCount({ mode, templates, artboardToEdit });
  const handlePreview = (file: any) => {
    setCurrImage(file);
    setIsEdit(true);
    setCustomName(file.name);
    setSelectedBrandNames(file.oems);
    setSelectedAccountNames(file.stores);
    setSelectedTagNames(file.tags);
    setShowModal(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setCustomName(name);
    if (isEdit) {
      const updatedImg = { ...currImage, name };
      updateBgImage(updatedImg);
      setCurrImage(updatedImg);
      editThemeImageFile(updatedImg);
    }
  };

  const handleClose = (discardButtonClicked?: boolean) => {
    if (doesArtboardHaveChanges() && !discardButtonClicked) {
      return Modal.confirm({
        title: "Confirm",
        icon: null,
        content: "Are you sure you want to discard changes?",
        okText: "Discard",
        okButtonProps: { danger: true, type: "primary" },
        onOk: () => {
          resetArtboardToEdit();
          closeDrawer();
        },
      });
    }

    closeDrawer();
    setArtboard({ ...defaultArtboard });
    setBgImages([]);
    resetArtboardToEdit();
    setSearchBoxValue("");
    // should not reset artboard if mode is update because if same artboard is selected for update again, all values are empted out.
    if (mode === "CREATE") setArtboard(defaultArtboard);
  };

  const handleSubmit = async () => {
    if (creatingData) {
      AntMessage.warning(
        "The artboard cannot be sumitted as some operations are in progress!",
      );
      return;
    }
    const newFiles = fileList.filter(file => !file.url) as IFileList[];

    if (bgImages.length > 0) {
      const updateImgs = API.services.designStudio.updateBgImages;
      const { error } = await updateImgs([...bgImages]);
      if (error) {
        AntMessage.error(error.message);
      }
      handleClose();
    }

    const { uploadBgImages } = API.services.designStudio;
    const bgImagePromises = newFiles.map(async file => {
      const { width, height } = artboard;
      const email = user?.email;
      const imageBody = await getImageBody({ file, width, height, email });

      return uploadBgImages(imageBody);
    });

    const uploadedImages = await Promise.all(bgImagePromises);
    if (isMissingFields(mode, artboard)) {
      AntMessage.warning("The Artboard is missing required fields.");
      return;
    }

    if (isNameDuplicated(mode, artboards, artboard)) {
      AntMessage.warning("This name is already in use by another artboard.");

      return;
    }
    const currIds = fileList.filter(file => file.url).map(file => file.uid);
    const imageIds = uploadedImages.map(
      res => res.result.themeBackgroundImage.id,
    );
    artboard.theme_images = currIds.concat(imageIds).join(",");
    if (mode === "CREATE") {
      artboard.created_at = Date.now();
      dispatch(actions.designStudio.createArtboard(artboard));
    } else {
      if (templateCount < 1) {
        dispatch(actions.designStudio.updateArtboard(artboard));

        return;
      }
      const modalContent = `Making changes to this artboard will affect ${templateCount} template(s). Do you want to continue?`;
      Modal.confirm({
        title: "Confirm Update",
        content: modalContent,
        okText: "Yes",
        cancelText: "No",
        onOk: () => {
          dispatch(actions.designStudio.updateArtboard(artboard));
        },
      });
    }
  };

  const handleDelete = () => {
    if (!isAdmin) {
      Modal.confirm({
        title: "Invalid Access",
        content:
          "Unable to access delete Artboard. Please reach out to an account admin for access",
        okText: "Ok",
        onOk: () => {
          handleClose();
        },
      });
    } else {
      const extra = ` It is currently in use by ${templateCount} templates.`;
      const suffix = templateCount < 1 ? "" : extra;
      const modalContent = `Are you sure you want to delete this artboard?${suffix}`;
      Modal.confirm({
        title: "Confirm Deletion",
        content: modalContent,
        okText: "Yes",
        cancelText: "No",
        onOk: () => {
          dispatch(actions.designStudio.deleteArtboard(artboard));
          handleClose();
        },
      });
    }
  };

  return (
    <DrawerContext.Provider
      value={{
        ...drawerRow,
        ...drawerImage,
        artboardToEdit,
        FULL: !isAdmin,
        artboard,
        customName,
        fileList,
        handlePreview,
        handleInputChange,
        handleClose,
        handleSubmit,
        handleDelete,
        isEdit,
        mode,
        resetArtboardToEdit,
        searchBy,
        searchBoxValue,
        setArtboard,
        setCustomName,
        setFilteredFileList,
        setFileList,
        setIsEdit,
        setSearchBy,
        setSelectedBrandNames,
        setSelectedAccountNames,
        setSelectedTagNames,
        setSearchBoxValue,
        setShowModal,
        showModal,
        closeDrawer,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};
