import { Form } from "antd";
import { ReactNode } from "react";
import { useMetaSchema } from "./useMetaSchema";

type Props = {
  name: string;
  children: ReactNode;
};

export const FormItem = ({ name, children }: Props) => {
  const { getLabel } = useMetaSchema();

  return (
    <Form.Item name={name} label={getLabel(name)}>
      {children}
    </Form.Item>
  );
};
