import { Form } from "antd";
import { memo, useLayoutEffect, useState } from "react";
import { AdLoadIssue } from "screens/adLibrary/adLoadV2/hooks/useAdLoadIssues";
import LabelSelectWithAutoComplete from "shared/components/select/LabelSelectWithAutoComplete";
import { AccountUrl } from "shared/types/accountManagement";

/**
 * Using this component with internal state value
 * to avoid issues with the cursor jumping to the end of the input
 * when changing the value asynchronosly
 */
const UrlLabelSelect = ({
  value,
  onChange,
  urlLabels,
  issue,
}: {
  value: string | undefined;
  onChange: (value: string) => void;
  urlLabels: AccountUrl[];
  issue: AdLoadIssue | undefined;
}) => {
  const [internalValue, setInternalValue] = useState<string | undefined>(value);

  useLayoutEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <Form.Item style={{ margin: 0 }} validateStatus={issue?.status}>
      <LabelSelectWithAutoComplete
        linkValue={internalValue}
        onChange={newValue => {
          const url =
            urlLabels.find(label => label.name === newValue)?.url ??
            newValue ??
            "";

          setInternalValue(url);
          onChange(url);
        }}
        willCheckFullUrl={false}
        width={250}
        urlLabels={urlLabels}
        forceHideTooltip
      />
    </Form.Item>
  );
};

export default memo(UrlLabelSelect);
