import { Spin } from "antd";
import { ReactNode } from "react";
import styles from "./ListContainer.module.scss";

type Props = {
  children?: ReactNode;
  isLoading: boolean;
};
const ListContainer = (props: Props) => {
  return (
    <Spin
      spinning={props.isLoading}
      tip="Loading data..."
      className={styles.spin}
    >
      <div className={styles.list}>{props.children}</div>
    </Spin>
  );
};

export default ListContainer;
