import { CustomizeComponent } from "rc-table/lib/interface";
import { ContextMenu } from "../ContextMenu";
import { EditableCell } from "./EditableCell";
import { useRowSelection } from "./useRowSelection";

export const useMediaTable = () => {
  const rowSelection = useRowSelection();
  const components = { body: { row: Row, cell: EditableCell } };

  return { components, rowSelection };
};

const Row = (props: CustomizeComponent & any) => {
  const { record } = props.children[0]?.props || {};

  return (
    <ContextMenu record={record}>
      <tr {...props}>{props.children}</tr>
    </ContextMenu>
  );
};
