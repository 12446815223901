import { Tabs } from "antd";
import { ReactNode } from "react";
import { IAccountRecord } from "shared/types/accountManagement";
import { OperationMode } from "shared/types/inputValues";
import { industryType } from "utils/helpers";
import DealerAdvancedStoreDetails from "./DealerAdvancedStoreDetails";
import DealerManageUrls from "./DealerManageUrls";
import DealerShowCodeSnippet from "./DealerShowCodeSnippet";

interface IDealerTabsProps {
  children: ReactNode;
  dealerToEditWithLogos: IAccountRecord;
  mode: OperationMode;
  setDealerToEditWithLogos: (dealerToEditWithLogos: IAccountRecord) => void;
}

export const DealerTabs = ({
  children,
  dealerToEditWithLogos,
  mode,
  setDealerToEditWithLogos,
}: IDealerTabsProps) => (
  <Tabs className="feed-tabs">
    <Tabs.TabPane
      key={"store-details-key"}
      tab={industryType("auto") ? "Store Details" : "Account Details"}
    >
      {children}
    </Tabs.TabPane>
    <Tabs.TabPane
      key={"advanced-store-details-key"}
      tab={
        industryType("auto")
          ? "Advanced Store Details"
          : "Advanced Account Details"
      }
    >
      <DealerAdvancedStoreDetails
        dealerToEditWithLogos={dealerToEditWithLogos}
        setDealerToEditWithLogos={setDealerToEditWithLogos}
      />
    </Tabs.TabPane>

    <Tabs.TabPane key={"manage-urls-key"} tab="Manage URLs">
      <DealerManageUrls
        dealerToEditWithLogos={dealerToEditWithLogos}
        setDealerToEditWithLogos={setDealerToEditWithLogos}
      />
    </Tabs.TabPane>

    {mode !== "CREATE" && (
      <Tabs.TabPane key={"show-code-snippet-key"} tab="Show Code Snippet">
        <DealerShowCodeSnippet dealerToEditWithLogos={dealerToEditWithLogos} />
      </Tabs.TabPane>
    )}
  </Tabs>
);
