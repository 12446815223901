import {
  useColDateModified,
  useColDimensions,
  useColExpiration,
  useColModifiedBy,
  useColName,
  useColSize,
  useColStatus,
  useColType,
} from "../table/cols";

export const useColsList = () => {
  return [
    useColName(),
    useColType(),
    useColStatus(),
    useColDimensions(),
    useColSize(),
    useColDateModified(),
    useColModifiedBy(),
    useColExpiration(),
  ];
};
