import { ZodError, ZodType } from "zod";
import isEqual from "lodash/isEqual";
import { useQuery } from "react-query";
import { useCallback } from "react";
import { RuleRender } from "antd/lib/form";
import { FormInstance } from "rc-field-form";

export const getErrorFromZod = (
  error: ZodError | undefined,
  path: Array<string | number>,
) => {
  if (!error) return undefined;
  const issue = error.issues.find(issue => isEqual(issue.path, path));
  return issue?.message;
};

export const getNestedErrorFromZod = (
  error: ZodError | undefined,
  index: string | number,
  keys: Array<string>,
) => {
  const errorMsgs = keys
    .map(key => getErrorFromZod(error, [index, key]))
    .filter(Boolean);
  return errorMsgs?.[0];
};

export const useAsyncZodValidation = <T>(
  value: T,
  schema: Zod.Schema,
  queryKey: string[],
) => {
  return useQuery(
    ["validation", ...queryKey],
    () => schema.safeParseAsync(value),
    { staleTime: Infinity },
  );
};

type ValidatorType<T> = {
  [x: string]: { data: T; schema: Zod.Schema };
};

type ValidatorTypeResponse<T> = {
  [x: string]: {
    data: T;
    schema: Zod.Schema;
    errors?: ZodError;
    success: boolean;
  };
};

export const useZodFormValidation = <T>(
  data: ValidatorType<T>,
): ValidatorTypeResponse<T> => {
  return Object.keys(data).reduce<ValidatorTypeResponse<T>>((acc, key) => {
    const validation = data[key].schema.safeParse(data[key].data);
    acc[key] = {
      ...data[key],
      errors: !validation.success ? validation.error : undefined,
      success: validation.success,
    };
    return acc;
  }, {});
};

export const useSchemaValidation = <T = unknown>(schema: ZodType<T>) =>
  useCallback(
    ({ getFieldsValue }: FormInstance) => ({
      // The ignore comment is needed because the types for the validator function are incomplete, at least on the Antd version we're using.
      // The first argument is of type RuleObject, but the type is missing the `field` property which specifies the name of the field being validated.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      validator: async ({ field }) => {
        const result = await schema.safeParseAsync(getFieldsValue());
        const error =
          !result.success &&
          result.error.issues.filter(issue => issue.path.includes(field))[0]
            ?.message;

        return error ? Promise.reject(error) : Promise.resolve();
      },
    }),
    [schema],
  ) satisfies RuleRender;
