import { useCallback } from "react";
import { message } from "antd";

import CommentsOpener from "./CommentsOpener";

import { useUser } from "shared/hooks/useUser";
import useComments, {
  UseCommentsArgs,
} from "shared/hooks/comments/useComments";
import { useMutateNotification } from "shared/hooks/notification/useMutateNotification";
import { IComment } from "shared/types/shared";
import { useLocation } from "react-router-dom";
import parse from "html-react-parser";
import { shouldEnableNotifications } from "utils/constants";

type CommentsOpenerWithDataProps = UseCommentsArgs;

const CommentsOpenerWithData = ({
  id,
  mode,
  children,
  className,
  extraInfo,
}: CommentsOpenerWithDataProps) => {
  const user = useUser();
  const { pathname, search } = useLocation();
  const commentsContext = useComments({ id, mode });
  const { mutate: mutateNotification } = useMutateNotification();
  const {
    objectId,
    comments,
    mutateComment,
    deleteComment,
    commentsError,
    isCommentsError,
    isChangingComments,
  } = commentsContext();

  const objIdToUse =
    objectId || comments?.find(comment => !!comment.objectId)?.objectId;

  const handleCommentDelete = useCallback(
    (comment: IComment) => {
      deleteComment(comment);
    },
    [deleteComment],
  );

  const handleCommentInsert = useCallback(
    (comment: IComment) => {
      mutateComment({ ...comment, objectId: objIdToUse });
      const parsed = (parse(comment?.text || "") as any).props.children;
      if (shouldEnableNotifications && Array.isArray(parsed)) {
        const mentionObjs = Object.values(parsed).filter(obj => !!obj.props);

        mentionObjs.forEach(obj => {
          mutateNotification({
            user: obj.props["data-id"],
            createdBy: `${user?.name}`,
            type: "mentionComment",
            module: "Ad Library",
            feature: `${extraInfo?.title || ""}`,
            detail: comment?.text || "",
            link: `${pathname}${search}`,
          });
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutateComment, mutateNotification],
  );

  if (isCommentsError || commentsError) {
    message.error(commentsError?.message || "Something went wrong.");
  }

  if (!id || !user) return <span />;

  // Possible TODO: implement load more comments?
  return (
    <CommentsOpener
      key={`comments-${id}`}
      objId={id}
      loggedInUser={user}
      comments={comments}
      loading={isChangingComments}
      handleCommentInsert={handleCommentInsert}
      handleCommentDelete={handleCommentDelete}
      className={className}
    >
      {children}
    </CommentsOpener>
  );
};

export default CommentsOpenerWithData;
