import moment, { MomentInput } from "moment";
import { SelectFilterField, SelectFilterOption } from "./useFilterFields";

interface HasIdAndName {
  id: string | number;
  name: string;
}

const isObject = (obj: unknown): obj is Record<string, unknown> => {
  return typeof obj === "object" && obj !== null;
};

const hasIdAndName = (obj: unknown): obj is HasIdAndName => {
  if (isObject(obj)) {
    const { id, name } = obj;

    const isValidId = typeof id === "string" || typeof id === "number";
    const isValidName = typeof name === "string";

    return isValidId && isValidName;
  }
  return false;
};

/**
 * Recursive function to find the value of a key in a nested object and map data.
 *
 * @param obj - The object to search within.
 * @param key - The key to look for in the object.
 * @returns The value associated with the key, or undefined if the key is not found.
 */
const findValueRecursively = <T>(obj: T, key: string): unknown => {
  if (obj === null || typeof obj !== "object") {
    return undefined;
  }

  const objRecord = obj as Record<string, unknown>;

  if (Object.prototype.hasOwnProperty.call(objRecord, key)) {
    let value = objRecord[key];

    if (key === "products") {
      if (Array.isArray(value)) {
        value = value.map(item => {
          if (hasIdAndName(item)) {
            return `${item.id}/${item.name}`;
          }
          return item;
        });
      } else if (hasIdAndName(value)) {
        value = `${value.id}/${value.name}`;
      }
    } else if (key === "templateCustomizable" || key === "customizable") {
      if (typeof value === "boolean") {
        value = value ? "customizable" : "nonCustomizable";
      }
    }

    return value;
  }

  for (const k in objRecord) {
    const prop = objRecord[k];

    if (typeof prop === "object" && prop !== null) {
      const result = findValueRecursively(prop, key);
      if (result !== undefined) {
        return result;
      }
    }
  }

  return undefined;
};

/**
 * Helper function to compare two values for equality.
 *
 * @param val1 - The first value.
 * @param val2 - The second value.
 * @returns True if the values are equal, false otherwise.
 */
const valuesMatch = (val1: unknown, val2: unknown): boolean => {
  if (val1 === val2) {
    return true;
  }
  if (val1 == null || val2 == null) {
    return false;
  }
  return String(val1) === String(val2);
};

/**
 * Function to get options with counts and disabled status based on the current data filters applied.
 *
 * @param data - The array of data items used to calculate the counts.
 * @param prefilteredItems - The array of filtered data items based on current data filters applied.
 * @param filterField - The filter field containing options to add count and disabled status.
 * @param dataFilters - The current data filters applied.
 * @returns An array of SelectFilterOption with counts and disabled status.
 */
export const getOptionsWithCount = <T extends { metrics?: number }>(
  data: T[],
  prefilteredItems: T[],
  filterField: SelectFilterField<T>,
  dataFilters: Record<string, string[]>,
): SelectFilterOption[] => {
  if (filterField.type !== "select") {
    return [];
  }

  const currentFilterValues = dataFilters[filterField.key] || [];
  const hasActiveSelections = currentFilterValues.length > 0;

  return filterField.options.reduce<SelectFilterOption[]>((acc, option) => {
    const optionValue = option.value;
    let matchingCount: number;

    if (!hasActiveSelections) {
      matchingCount = prefilteredItems.reduce((count, item) => {
        const itemValue = findValueRecursively(item, filterField.key);
        const itemValues = Array.isArray(itemValue) ? itemValue : [itemValue];

        return itemValues.some(val => valuesMatch(val, optionValue))
          ? count + 1
          : count;
      }, 0);
    } else {
      const dataWithOptionCount = data.reduce((count, item) => {
        const matchesFilters = Object.entries(dataFilters).every(
          ([filterKey, filterValues]) => {
            if (!Array.isArray(filterValues) || filterValues.length === 0) {
              return true;
            }
            const itemValue = findValueRecursively(item, filterKey);
            const itemValues = Array.isArray(itemValue)
              ? itemValue
              : [itemValue];

            if (filterKey === "metrics") {
              return filterValues.every(metric => {
                if (metric === "popular") {
                  return item.metrics === 1;
                }
                return false;
              });
            }

            if (
              filterKey === "expirationDate" ||
              filterKey === "updatedAt" ||
              filterKey === "createdAt" ||
              filterKey === "deliveryDate"
            ) {
              const dateRangeStr = filterValues[0];
              const [startTimestampStr, endTimestampStr] =
                dateRangeStr.split(" ");

              if (!startTimestampStr || !endTimestampStr) {
                return true;
              }

              const itemDate = moment(itemValue as MomentInput);
              const startDate = moment(parseInt(startTimestampStr, 10));
              const endDate = moment(parseInt(endTimestampStr, 10));

              if (!itemDate.isValid()) {
                return false;
              }

              return itemDate.isBetween(startDate, endDate, undefined, "[]");
            }

            if (filterKey === filterField.key) {
              return (
                filterValues.some(val =>
                  itemValues.some(itemVal => valuesMatch(itemVal, val)),
                ) ||
                itemValues.some(itemVal => valuesMatch(itemVal, optionValue))
              );
            }

            return filterValues.some(val =>
              itemValues.some(itemVal => valuesMatch(itemVal, val)),
            );
          },
        );
        return matchesFilters ? count + 1 : count;
      }, 0);

      matchingCount = dataWithOptionCount - prefilteredItems.length;
    }

    const disabled =
      matchingCount === 0 && !currentFilterValues.includes(optionValue);

    acc.push({
      ...option,
      count: matchingCount,
      disabled,
    });

    return acc;
  }, []);
};
