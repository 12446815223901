import { useMutation, useQueryClient } from "react-query";
import API from "services";
import {
  ICreateOrUpdateDashboardResponseData,
  ICreatePublicDashboardResponseData,
  IDashboard,
  IPublicDashboard,
  IValidatePassword,
  IValidatePasswordResponseData,
} from "shared/types/dashboardManagement";
import { queryKeys } from "./queryKeys";

const createOrUpdatePublicDashboard = async (
  publicDashboard: IPublicDashboard,
) => {
  const { result, error } =
    await API.services.dashboardManagement.createOrUpdatePublicDashboard(
      publicDashboard,
    );

  if (error) {
    throw Error(error.message);
  }

  return result;
};

const createOrUpdateDashboard = async (dashboard: IDashboard) => {
  const { result, error } =
    await API.services.dashboardManagement.createOrUpdateDashboard(dashboard);

  if (error) {
    throw Error(error.message);
  }

  return result;
};

const validatePassword = async (args: IValidatePassword) => {
  const { result, error } =
    await API.services.dashboardManagement.validatePassword(args);

  if (error) {
    throw Error(error.message);
  }

  return result;
};

export const useMutatePublicDashboard = () => {
  const queryClient = useQueryClient();

  return useMutation<
    ICreatePublicDashboardResponseData | null,
    Error,
    IPublicDashboard
  >(createOrUpdatePublicDashboard, {
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.publicDashboards);
    },
  });
};

export const useValidatePassword = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IValidatePasswordResponseData | null,
    Error,
    IValidatePassword
  >(validatePassword, {
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.validatePassword);
    },
  });
};

export const useMutateDashboard = () => {
  const queryClient = useQueryClient();

  return useMutation<
    ICreateOrUpdateDashboardResponseData | null,
    Error,
    IDashboard
  >(createOrUpdateDashboard, {
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.dashboards);
    },
  });
};
