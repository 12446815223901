import { DataNode } from "antd/lib/tree";
import { useCallback, useMemo } from "react";
import {
  GoogleAdAccount,
  GoogleAdCampaign,
  IAssetGroup,
} from "shared/types/adLibrary";

type ReturnType = {
  dataNodes: DataNode[];
};
export type DataNodeKeyType = "ad-account" | "campaign";
type Props = {
  adAccounts: GoogleAdAccount[];
  searchBy: string;
  checkedNodeInfo?: [DataNodeKeyType, string];
};

const getAdAccountKey = (adAcct: GoogleAdAccount) => `ad-account_${adAcct.id}`;

export const getCampaignKey = (
  adAcct: GoogleAdAccount,
  camp: GoogleAdCampaign,
) => `campaign_${adAcct.id}_${camp.id}`;

const getAssetGroupKey = (
  adAcct: GoogleAdAccount,
  camp: GoogleAdCampaign,
  grp: IAssetGroup,
) => `asset-group_${adAcct.id}_${camp.id}_${grp.id}`;

const hasAdAccount = (adAccount: GoogleAdAccount, searchBy: string) =>
  adAccount.name.toLowerCase().includes(searchBy);

const hasCampaign = (campaign: GoogleAdCampaign, searchBy: string) =>
  campaign.name.toLowerCase().includes(searchBy);

const hasAssetGroup = (assetGroup: IAssetGroup, searchBy: string) =>
  assetGroup.name.toLowerCase().includes(searchBy);

const usePmaxTreeDataNodes = (props: Props): ReturnType => {
  const { adAccounts, searchBy } = props;
  const getHighlightedTextDiv = (value: string, searchBy: string) => {
    const valueLowercase = value.toLowerCase();
    const searchByLowercase = searchBy.toLowerCase();

    if (searchBy === "" || !valueLowercase.includes(searchByLowercase))
      return value;

    const idxOf = valueLowercase.indexOf(searchByLowercase);
    const prefix = value.slice(0, idxOf);
    const highlightedText = value.slice(idxOf, idxOf + searchBy.length);
    const postfix = value.slice(idxOf + searchBy.length);

    return (
      <div>
        <span>{prefix}</span>
        <span style={{ color: "orange" }}>{highlightedText}</span>
        <span>{postfix}</span>
      </div>
    );
  };

  const adAccountToTreeDataNode = useCallback(
    (adAcct: GoogleAdAccount): DataNode => {
      const lowerCaseSearch = searchBy.toLowerCase();

      return {
        title: getHighlightedTextDiv(adAcct.name, searchBy),
        key: getAdAccountKey(adAcct),
        selectable: false,
        children: adAcct.campaigns
          ?.filter(
            campaign =>
              hasCampaign(campaign, lowerCaseSearch) ||
              campaign.assetGroups?.some(assetGroup =>
                hasAssetGroup(assetGroup, lowerCaseSearch),
              ),
          )
          .map(camp => ({
            title: getHighlightedTextDiv(camp.name, searchBy),
            key: getCampaignKey(adAcct, camp),
            selectable: false,
            children: camp.assetGroups
              ?.filter(assetGroup => hasAssetGroup(assetGroup, lowerCaseSearch))
              .map(grp => ({
                title: getHighlightedTextDiv(grp.name, searchBy),
                key: getAssetGroupKey(adAcct, camp, grp),
                selectable: false,
                checkable: false,
              })),
          })),
      };
    },
    [searchBy],
  );
  const treeDataNodes = useMemo(() => {
    return adAccounts
      .filter(adAccount => {
        const lowerCaseSearch = searchBy.toLowerCase();
        return (
          hasAdAccount(adAccount, lowerCaseSearch) ||
          adAccount?.campaigns?.some(campaign =>
            hasCampaign(campaign, lowerCaseSearch),
          ) ||
          adAccount?.campaigns?.some(campaign =>
            campaign.assetGroups?.some(assetGroup =>
              hasAssetGroup(assetGroup, lowerCaseSearch),
            ),
          )
        );
      })
      .map(adAccountToTreeDataNode);
  }, [adAccounts, adAccountToTreeDataNode, searchBy]);

  return {
    dataNodes: treeDataNodes,
  };
};

export default usePmaxTreeDataNodes;
