import { ReactNode, createContext, memo, useContext, useState } from "react";
import { BrandLogo } from "shared/types/brandsAccounts";
import { TableCardLayout } from "shared/types/toolbar";

interface LogoSelectContext {
  layout: TableCardLayout;
  showFilterDrawer: boolean;
  showLogoSelectDrawer: boolean;
  setShowLogoSelectDrawer: (showLogoSelectDrawer: boolean) => void;
  onSelect: OnSelectFn;
  setOnSelect: (onSelect: OnSelectFn) => void;
  setLayout: (layout: TableCardLayout) => void;
  setShowFilterDrawer: (showFilterDrawer: boolean) => void;
}

const LogoSelectContext = createContext<LogoSelectContext>(
  {} as LogoSelectContext,
);

interface LogoSelectContextProviderProps {
  children: ReactNode;
}

type OnSelectFn = (logo: BrandLogo) => void;

export const LogoSelectContextProvider = ({
  children,
}: LogoSelectContextProviderProps) => {
  const [showLogoSelectDrawer, setShowLogoSelectDrawer] = useState(false);
  const [onSelect, setOnSelect] = useState<OnSelectFn>(() => () => {});
  const [layout, setLayout] = useState<TableCardLayout>("card");
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);

  const contextValue = {
    layout,
    showFilterDrawer,
    showLogoSelectDrawer,
    setShowLogoSelectDrawer,
    setShowFilterDrawer,
    onSelect,
    setOnSelect,
    setLayout,
  };

  return (
    <LogoSelectContext.Provider value={contextValue}>
      {children}
    </LogoSelectContext.Provider>
  );
};

export const useLogoSelectContext = (): LogoSelectContext => {
  const context = useContext(LogoSelectContext);
  if (!context) {
    throw new Error(
      "useLogoSelectContext must be used within a LogoSelectProvider",
    );
  }
  return context;
};

export const LogoSelectProvider = memo(LogoSelectContextProvider);
