import {
  CloseCircleFilled,
  InfoCircleFilled,
  WarningFilled,
} from "@ant-design/icons";
import { List } from "antd";
import { buttonColors } from "screens/adLibrary/adLoad/adLoadDrawer/shared/constants";
import { IAdIssue } from "shared/types/adLibrary";

const avatarMap = {
  info: <InfoCircleFilled style={{ color: buttonColors.info }} />,
  warning: <WarningFilled style={{ color: buttonColors.warning }} />,
  error: <CloseCircleFilled style={{ color: buttonColors.error }} />,
};

const IssueItem = ({ issue }: { issue: IAdIssue }) => (
  <List.Item actions={issue.actions}>
    <List.Item.Meta avatar={avatarMap[issue.type]} title={issue.message} />
  </List.Item>
);

interface IProps {
  issues: IAdIssue[];
}

const IssueList = ({ issues }: IProps) => {
  return (
    <List
      style={{ maxHeight: "200px", overflow: "auto", margin: "0 1em" }}
      dataSource={issues}
      rowKey={(issue: IAdIssue) => issue.message.toString()}
      renderItem={issue => <IssueItem issue={issue} />}
    />
  );
};

export default IssueList;
