import { AppState } from "redux/store";

export const selectSelectedAccounts = (state: AppState) =>
  state.adLibrary.selectedFacebookAccounts;

export const selectAdLoadParameters = (state: AppState) =>
  state.adLibrary.adLoad.adLoadParameters;

export const selectSelectedAds = (state: AppState) =>
  state.adLibrary.adLoad.selectedAds;

export const selectFacebookAdLoadDict = (state: AppState) =>
  state.adLibrary.adLoad.facebookAdLoadDict;

export const selectDestination = (state: AppState) =>
  state.adLibrary.adLoad.destination;

export const selectAdLoad = (state: AppState) => state.adLibrary.adLoad;
