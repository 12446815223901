import { SearchOutlined } from "@ant-design/icons";
import { Button, Form, Input, Tooltip } from "antd";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { CAMSelect } from "shared/components/media";
import { IArtboard } from "shared/types/designStudio";
import { ImgFiles } from "./types";
import { useThemeBGSearch } from "./useThemeBGSearch";

import styles from "./MediaThemeList.module.scss";

type Props = {
  artboard: IArtboard;
  filteredFileList: ImgFiles[];
  setFilteredFileList: Dispatch<SetStateAction<ImgFiles[]>>;
  setSearchBoxValue: Dispatch<SetStateAction<string>>;
  searchBoxValue: string;
};

export const MediaThemeList = ({
  artboard,
  filteredFileList,
  setFilteredFileList,
  setSearchBoxValue,
  searchBoxValue,
}: Props) => {
  const { width, height } = artboard;
  const payload: MGSearchObj = useMemo(() => {
    return {
      width: String(width),
      height: String(height),
      keywords: "theme",
    };
  }, [width, height]);
  const items = useThemeBGSearch(payload);

  useEffect(() => {
    if (items.length) {
      setFilteredFileList(items);
    }
  }, [items, setFilteredFileList]);

  const onChangeImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchBoxValue(e.target.value);
    if (e.target.value === "") {
      setFilteredFileList(items);
    } else {
      const lcName = e.target.value.toLowerCase();
      const checkName = (name: string) => name.toLowerCase().includes(lcName);
      const list = items.filter(({ name }) => checkName(name));
      setFilteredFileList(list);
    }
  };

  return (
    <Form.Item label="Theme Background Image">
      {items && (
        <p>
          {`${items.length} images with artboard selected size`}
          <CAMSelect search={payload}>
            <Button type="link">View in CAM</Button>
          </CAMSelect>
        </p>
      )}
      <div>
        <div>
          <Input
            placeholder="Search for images"
            suffix={
              <Tooltip title="Search images">
                <SearchOutlined />
              </Tooltip>
            }
            onChange={onChangeImages}
            value={searchBoxValue}
          />
        </div>
        <div className={styles.list}>
          {filteredFileList.map(item => (
            <div key={item.uid}>
              <img src={item.thumbUrl} />
              <span>{item.name}</span>
            </div>
          ))}
        </div>
      </div>
    </Form.Item>
  );
};
