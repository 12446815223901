import { useMemo } from "react";
import { dealerToDealerRecordData } from "utils/helpers";
import { dedupeBy } from "utils/helpers.array";
import { useFetchDealers } from "../../../hooks/useFetchDealersV2";
import { toBrandRecord, useFetchOems } from "../../../hooks/useFetchOems";
import { getLogosForEntity } from "../logo.utils";

export const useFetchLogos = () => {
  const { oems, isFetching: brandsFetching } = useFetchOems();
  const { dealers, isFetching: accountsFetching } = useFetchDealers();

  const brandRecords = useMemo(() => oems.map(toBrandRecord), [oems]);
  const accountRecords = useMemo(
    () => dealers.map(dealerToDealerRecordData),
    [dealers],
  );

  const logos = useMemo(() => {
    const brandLogos = brandRecords.map(brandRecord =>
      getLogosForEntity(brandRecord, brandRecord.oemName),
    );
    const accountLogos = accountRecords.map(accountRecord =>
      getLogosForEntity(accountRecord, accountRecord.dealerName),
    );
    return [...brandLogos, ...accountLogos].flat().filter(dedupeBy("id"));
  }, [brandRecords, accountRecords]);

  return {
    logos,
    isFetching: brandsFetching || accountsFetching,
  };
};
