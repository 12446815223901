import { Button, Checkbox, Form, Input, InputNumber, Space } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { camelCase } from "lodash";
import { useCallback, useEffect } from "react";
import { useAdEngineActions } from "redux/assetExporter/assetExporter.slice";
import {
  DEFAULT_FTP_PORT,
  exportFtpValidationMsgs,
  ftpInputs,
} from "shared/constants/assetExporter";
import useValidateCreds from "shared/hooks/assetExporter/useValidateCreds";
import { useAppSelector } from "shared/hooks/useAppSelector";
import {
  ExportForOption,
  ExportFtpData,
  GetValidateCredsBody,
} from "shared/types/assetExporter";

import styles from "../ExportDrawer.module.scss";

export const ExportOptions = () => {
  const { feedType, exportFor } = useAppSelector(
    state => state.assetExporter.configure,
  );
  const actions = useAdEngineActions();

  const {
    mutate: validateCreds,
    isLoading: isValidatingCreds,
    isSuccess: isCredsValid,
    isError: isCredsInvalid,
  } = useValidateCreds();

  const [form] = Form.useForm();
  const helpMsg = "Invalid credentials";
  const isFeedTypeFbAds = feedType === "facebookAds";

  const options: ExportForOption[] = ["Ad Library", "CSV download", "FTP"];
  const availableOptions = isFeedTypeFbAds
    ? options
    : options.filter(opt => opt !== "Ad Library");

  const isFtpSelected = exportFor.includes("FTP");

  const onCheckboxChange = (
    event: CheckboxChangeEvent,
    value: ExportForOption,
  ) => {
    event.preventDefault();
    const isChecked = event.target.checked;
    const updatedExportFor = isChecked
      ? [...exportFor, value]
      : exportFor.filter(exportOption => exportOption !== value);

    actions.setExportFor(updatedExportFor);
  };

  const onValidateCredsClick = (formData: ExportFtpData) => {
    const body: GetValidateCredsBody = {
      host: formData.url,
      password: formData.password,
      path: formData.filePath,
      port: formData.port,
      username: formData.username,
    };
    validateCreds(body);
    actions.setExportFtpData(formData);
  };

  const getValidateStatus = () => {
    if (isValidatingCreds) return "validating";
    if (isCredsValid) return "success";
    if (isCredsInvalid) return "error";
    return;
  };

  const updatePortOnForm = useCallback(() => {
    form.setFieldsValue({ port: DEFAULT_FTP_PORT });
  }, [form]);

  const enableExportOnValidCreds = useCallback(() => {
    if (!isValidatingCreds && isCredsValid) {
      actions.setAreValidFtpCreds(true);
    } else {
      actions.setAreValidFtpCreds(false);
    }
  }, [actions, isCredsValid, isValidatingCreds]);

  useEffect(updatePortOnForm, [updatePortOnForm]);
  useEffect(enableExportOnValidCreds, [enableExportOnValidCreds]);

  return (
    <>
      <div className={styles.radioSelectionTitle}>Export for</div>
      <Space direction="vertical">
        {availableOptions.map(opt => (
          <Checkbox
            key={opt}
            value={opt}
            onChange={e => onCheckboxChange(e, opt)}
            checked={exportFor.includes(opt)}
            data-cy={`export-for-${camelCase(opt)}`}
          >
            {opt}
          </Checkbox>
        ))}
      </Space>
      <div className={styles.ftpFormContainer} id="ftp-export-form">
        <Form
          layout="vertical"
          className={styles.sectionTopSpacing}
          onFinish={onValidateCredsClick}
          validateMessages={exportFtpValidationMsgs}
          form={form}
        >
          {ftpInputs.map(ftpInput => {
            const isUsername = ftpInput.value === "username";
            const isPassword = ftpInput.value === "password";
            const displayValidation = isUsername || isPassword;
            const hasFeedback = isCredsInvalid || isCredsValid;
            const validateStatus = getValidateStatus();
            return (
              <Form.Item
                key={ftpInput.value}
                label={ftpInput.label}
                name={ftpInput.value}
                rules={ftpInput.rules}
                validateStatus={displayValidation ? validateStatus : undefined}
                help={displayValidation && isCredsInvalid ? helpMsg : undefined}
                hasFeedback={displayValidation && hasFeedback}
              >
                {ftpInput.value === "password" ? (
                  <Input.Password
                    placeholder={ftpInput.placeholder}
                    disabled={!isFtpSelected}
                    data-cy={`ftp-form-${ftpInput.value}`}
                  />
                ) : ftpInput.value === "port" ? (
                  <InputNumber
                    placeholder={ftpInput.placeholder}
                    disabled={!isFtpSelected}
                    style={{ width: "100%" }}
                    data-cy={`ftp-form-${ftpInput.value}`}
                  />
                ) : (
                  <Input
                    placeholder={ftpInput.placeholder}
                    disabled={!isFtpSelected}
                    data-cy={`ftp-form-${ftpInput.value}`}
                  />
                )}
              </Form.Item>
            );
          })}
          <Form.Item>
            <Button
              htmlType="submit"
              disabled={!isFtpSelected}
              loading={isValidatingCreds}
              data-cy="ftp-form-validateCreds"
            >
              Validate Credentials
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
