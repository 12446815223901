import { useEffect, useState } from "react";
import { Modal, Spin, Button, message, notification } from "antd";
import { RetweetOutlined, RollbackOutlined } from "@ant-design/icons";

import PaymentEngineLeaseExplained from "../paymentEngineModal/PaymentEngineLeaseExplained";
import PaymentEngineFinanceExplained from "../paymentEngineModal/PaymentEngineFinanceExplained";

import { useAppDispatch } from "shared/hooks/useAppDispatch";
import * as assetBuilderActions from "redux/assetBuilder/assetBuilder.slice";

import useApplyPaymentEngineValues from "../paymentEngineModal.hooks/useApplyPaymentEngineValues";
import useRequestPaymentEngineData from "../paymentEngineModal.hooks/useRequestPaymentEngineData";

import { multiSelectableFieldNames } from "../paymentEngineModal/constants";

import {
  OfferCalcRow,
  IOfferFormPaymentEngineModal,
} from "../paymentEngineModal/types";
import { OfferData } from "shared/types/assetBuilder";
import { ParseableOfferData } from "shared/types/paymentEngine";
import { PaymentEngineOfferCalcTable } from "../paymentEngineModal/PaymentEngineOfferCalcTable";

import "../../review/ReviewPage.scss";

const OfferFormPaymentEngineModal = ({
  offerData,
  showDSModal,
  processingExport,
  currentDealerData,
  initialSelectedRow,
  rawPaymentEngineData,
  processedPaymentEngineData,
  editField,
  toggleDSModal,
  setEditedVin,
  getPaymentEngineDataError,
  getPaymentEngineDataReset,
}: IOfferFormPaymentEngineModal) => {
  const dispatch = useAppDispatch();

  const [showLeaseExplained, toggleLeaseExplained] = useState(false);
  const [previewRequested, setPreviewRequested] = useState(false);
  const [checkboxSelectedRows, setCheckboxSelectedRow] = useState<
    OfferCalcRow[]
  >([]);
  const [feedOfferFieldValueEdits, setFeedOfferFieldValueEdits] =
    useState<OfferData | null>(null);

  const offerType = initialSelectedRow?.field.includes("zeroDownLease")
    ? "ZERO DOWN LEASE"
    : initialSelectedRow?.field.includes("finance")
    ? "FINANCE"
    : "LEASE";

  const dealType = initialSelectedRow?.field.includes("finance")
    ? "loan"
    : "lease";

  const disableButton = !processedPaymentEngineData || !rawPaymentEngineData;

  useEffect(() => {
    dispatch(assetBuilderActions.resetAssetInstanceCounter());
  }, [dispatch]);

  useEffect(() => {
    setPreviewRequested(false);
  }, [processedPaymentEngineData]);

  useEffect(() => {
    /*
       AV2-1784: Initialize feedOfferFieldValueEdits when modal is opened
       clear feedOfferFieldValueEdits when modal is closed
     */
    setFeedOfferFieldValueEdits(showDSModal ? offerData : null);
  }, [showDSModal, offerData]);

  useEffect(() => {
    if (showDSModal && getPaymentEngineDataError) {
      notification.error({
        message: "Update Failed",
        description: getPaymentEngineDataError.message,
        placement: "topRight",
      });
      setPreviewRequested(false);
    }
  }, [getPaymentEngineDataError, showDSModal]);

  const setFieldValueInFeedOfferEdits = (
    field: keyof ParseableOfferData,
    value: string,
  ) => {
    const newFeedOfferFieldValueEdits = {
      ...feedOfferFieldValueEdits,
      [field]: value,
    } as ParseableOfferData;

    setFeedOfferFieldValueEdits(newFeedOfferFieldValueEdits);
  };

  const closeModal = () => {
    getPaymentEngineDataReset();
    toggleDSModal(false);
    toggleLeaseExplained(false);
  };

  const requestPaymentEngineData = useRequestPaymentEngineData({
    dealType,
    offerType,
    offerData,
    checkboxSelectedRows,
    feedOfferFieldValueEdits,
    setPreviewRequested,
    dealerData: currentDealerData,
  });

  const applyPaymentEngineValues = useApplyPaymentEngineValues({
    offerData,
    offerType,
    rawPaymentEngineData,
    processedPaymentEngineData,
    closeModal,
    setEditedVin,
    editField,
  });

  const footerButtons = [
    <Button
      key="cancel-button"
      style={{ borderColor: "#73787c", float: "left" }}
      onClick={() => {
        if (setEditedVin) setEditedVin(undefined);
        closeModal();
      }}
    >
      Cancel
    </Button>,
    !showLeaseExplained && (
      <Button
        key="preview-paymentengine-data-button"
        icon={<RetweetOutlined />}
        disabled={!checkboxSelectedRows.length || previewRequested}
        onClick={async e => {
          e.stopPropagation();
          await requestPaymentEngineData();
        }}
      >
        Preview Offer Data
      </Button>
    ),
    !showLeaseExplained ? (
      <Button
        key="reset-button"
        onClick={() => {
          notification.info({
            message: "Table form data has been reset.",
            description:
              "The form data has been reverted to match the data from when the modal was opened.",
            placement: "topRight",
          });
          getPaymentEngineDataReset?.();
          setFeedOfferFieldValueEdits(offerData);
        }}
      >
        Reset
      </Button>
    ) : (
      <Button
        key="export-pdf-button"
        type="primary"
        disabled={disableButton}
        onClick={() => {
          message.info("export pdf coming soon");
          /*
               TO DO: Export Lease Explained content to PDF
               For now, the user can just save a screenshot
             */
        }}
      >
        Export PDF
      </Button>
    ),
    !showLeaseExplained ? (
      <Button
        key="next-button"
        type="primary"
        disabled={!!!offerData.vin}
        onClick={async e => {
          e.stopPropagation();
          await requestPaymentEngineData();
          toggleLeaseExplained(true);
        }}
      >
        Next
      </Button>
    ) : (
      <Button
        key="accept-button"
        type="primary"
        title="Apply values from your Payment Engine to matching fields in feed offer, including lease rebate fields if applicable"
        onClick={applyPaymentEngineValues}
      >
        Accept
      </Button>
    ),
  ];

  return (
    <Modal
      className="payment-engine-modal"
      title={
        <span>
          {showLeaseExplained && (
            <Button
              type="link"
              style={{ color: "#ffffff" }}
              onClick={() => {
                toggleLeaseExplained(false);
              }}
            >
              <RollbackOutlined style={{ color: "#ffffff" }} />
              Back
            </Button>
          )}
          {!showLeaseExplained
            ? `OFFER CALCULATION - ${offerType}`
            : `${
                offerType.includes("LEASE") ? "LEASING" : "FINANCING"
              } EXPLAINED`}
        </span>
      }
      footer={footerButtons}
      centered={true}
      visible={showDSModal}
      keyboard={true}
      closable={true}
      maskClosable={true}
      destroyOnClose={true}
      onCancel={() => {
        closeModal();
      }}
    >
      <Spin spinning={processingExport} size="large">
        {!showLeaseExplained && (
          <PaymentEngineOfferCalcTable
            previewRequested={previewRequested}
            processedPaymentEngineData={processedPaymentEngineData}
            offerData={offerData}
            initialSelectedRow={initialSelectedRow}
            feedOfferFieldValueEdits={feedOfferFieldValueEdits}
            setFieldValueInFeedOfferEdits={setFieldValueInFeedOfferEdits}
            checkboxSelectedRows={checkboxSelectedRows}
            setCheckboxSelectedRow={setCheckboxSelectedRow}
            multiSelectableFieldNames={multiSelectableFieldNames}
          />
        )}
        {showLeaseExplained &&
          (offerType.includes("LEASE") ? (
            <PaymentEngineLeaseExplained
              getPaymentEngineDataError={getPaymentEngineDataError}
              rawPaymentEngineData={rawPaymentEngineData}
              currentDealerData={currentDealerData}
            />
          ) : (
            <PaymentEngineFinanceExplained
              getPaymentEngineDataError={getPaymentEngineDataError}
              rawPaymentEngineData={rawPaymentEngineData}
              currentDealerData={currentDealerData}
            />
          ))}
      </Spin>
    </Modal>
  );
};

export default OfferFormPaymentEngineModal;
