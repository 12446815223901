import { DataNode } from "antd/lib/tree";
import { castArray } from "lodash";
import { Fragment, useMemo } from "react";
import uuid from "uuid";
import { useCampaignsTreeData } from "./useCampaignsTreeData";
import { HighlightedTitle } from "./HighlightedTitle";
import { useSelectedAcctCampaigns } from "screens/adLibrary/adLoadV2/hooks/useSelectedAcctCampaigns";
import { useAdLoadSelectedStores } from "screens/adLibrary/adLoadV2/hooks/useAdLoadSelectedStores";
import { raise } from "utils/errorMessage";
import { Spin } from "antd";

export const useFilteredTreeData = (searchValue: string) => {
  const { treeData, isLoadingCampaigns } = useCampaignsTreeData();
  const { selectedStores } = useAdLoadSelectedStores();
  const { campaignResByAcctId } = useSelectedAcctCampaigns();

  const filteredTreeData = useMemo(() => {
    return searchValue
      ? filterAndHighlightTitle(treeData, searchValue)
      : treeData;
  }, [searchValue, treeData]);

  const filteredTreeDataWithDefaults = filteredTreeData.map(
    (storeNode: DataNode): DataNode => {
      if (storeNode.children?.length) return storeNode;

      const fbAccountId =
        selectedStores.find(store => store.dealer_name === storeNode.key)
          ?.details?.facebook?.fbAccountId ??
        raise(`Store ${storeNode.key} not found`);

      const isLoading = campaignResByAcctId[fbAccountId]?.isLoading;

      return {
        ...storeNode,
        children: [
          getEmptyNode(
            isLoading ? (
              <>
                <Spin size="small" /> Loading campaigns...
              </>
            ) : (
              "No campaigns found"
            ),
          ),
        ],
      };
    },
  );

  return {
    treeData,
    filteredTreeData,
    filteredTreeDataWithDefaults,
    isLoadingCampaigns,
  };
};

const getEmptyNode = (title: React.ReactNode): DataNode => ({
  key: uuid(),
  title,
  isLeaf: true,
  checkable: false,
  disabled: true,
  switcherIcon: Fragment,
});

const filterAndHighlightTitle = (
  data: DataNode[],
  searchValue: string,
): DataNode[] => {
  const getItemWithHighlightedTitle = (item: DataNode) => {
    const title = <HighlightedTitle item={item} searchValue={searchValue} />;

    return {
      ...item,
      title,
      children: item.children
        ? filterAndHighlightTitle(item.children, searchValue)
        : undefined,
    };
  };

  return data.filter(findMatch(searchValue)).map(getItemWithHighlightedTitle);
};

const findMatch =
  (searchValue: string) =>
  (data: DataNode | DataNode[]): boolean => {
    return castArray(data).some(item => {
      const isMatchItem = item.title
        ?.toString()
        .toLowerCase()
        .includes(searchValue);

      if (isMatchItem) return true;

      if (item.children) {
        return findMatch(searchValue)(item.children);
      }
    });
  };
