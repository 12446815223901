import { DesignStudioTableItem } from "../../context/DesignStudioDataProvider";
import { DesignStudioItemCell } from "./DesignStudioItemCell";
import { isStamp, isTemplate } from "../../helpers";
import { DataListStatusCell } from "shared/components/dataList/DataListStatusCell";

export const DesignStudioItemStatusCell = ({
  item,
}: {
  item: DesignStudioTableItem;
}) => {
  if (isTemplate(item) || isStamp(item))
    return <DataListStatusCell item={item.asset.status} />;
  else
    return (
      <DesignStudioItemCell
        itemType={item.itemType}
        value={null}
        column="status"
      />
    );
};
