import { ArrowLeftOutlined } from "@ant-design/icons";
import { Space, Button, Tooltip } from "antd";
import { PmaxCreateAssetGroupFooterProps } from "../PmaxLoadDrawer";
import styles from "./PmaxDrawerFooter.module.scss";

export const ReviewStepFooter = ({
  onGoToAssetGroupSelection,
  loadDisabled,
  onLoadToGoogleAds,
}: PmaxCreateAssetGroupFooterProps) => {
  const disabledTooltip = loadDisabled
    ? "Ads cannot be loaded until all errors are addressed"
    : "";

  return (
    <Space direction="horizontal" className={styles.drawerActionsContainer}>
      <Space direction="horizontal" align="start">
        <Button onClick={onGoToAssetGroupSelection}>
          <ArrowLeftOutlined />
          Back
        </Button>
      </Space>
      <Space align="end">
        <Tooltip title={disabledTooltip}>
          <Button
            type="primary"
            disabled={loadDisabled}
            onClick={onLoadToGoogleAds}
          >
            Load to Google Ads
          </Button>
        </Tooltip>
      </Space>
    </Space>
  );
};
