import { AppThunk } from "redux/store";
import {
  ImportNotificationProps,
  openProcessingImportNotification,
  setDisplayCloseIcon,
} from "screens/adLibrary/adImport/ProcessingImportNotification";
import { ImportInstantExperienceData } from "screens/designStudio/library/instantExperiences/InstantExperiencesImportDrawer";
import { IInstantExperience } from "shared/types/adLibrary";
import {
  groupInstantExperiencesDataByName,
  mapCsvDataToInstantExperience,
} from "screens/adLibrary/adImport/processingImportUtils";
import {
  startProcessingInstantExperiences,
  cancelProcessingInstantExperiences,
  setDisplayInstantExperiencesImportTable,
  resetInstantExperiencesImport,
  finishProcessingInstantExperiences,
} from "redux/designStudio/designStudio.slice";
import { NavigateFunction } from "react-router-dom";

export const processInstantExperiencesImport =
  (
    instantExperiencesData: ImportInstantExperienceData[],
    initialImportNotificationProps: Partial<ImportNotificationProps>,
    navigate: NavigateFunction,
  ): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startProcessingInstantExperiences());

    const instantExperiencesDataGroupedByName =
      groupInstantExperiencesDataByName(instantExperiencesData);

    const getCancelImport = () =>
      getState().designStudio.instantExperienceImport.cancelImport;

    let importedInstantExperiences = 0;
    const importNotificationProps: ImportNotificationProps = {
      notificationKey: "processing-instant-experiences-import",
      columnsIgnored: 0,
      importedItems: importedInstantExperiences,
      totalItems: instantExperiencesDataGroupedByName.length,
      handleCancel: () => dispatch(cancelProcessingInstantExperiences()),
      handleReview: () => {
        navigate("/everything-ads/ads");
        dispatch(setDisplayInstantExperiencesImportTable(true));
      },
      itemLabel: "Instant Experiences",
      ...initialImportNotificationProps,
    };

    openProcessingImportNotification(importNotificationProps);

    const newInstantExperiences: IInstantExperience[] = await Promise.all(
      instantExperiencesDataGroupedByName.map(
        async ([ieName, ieComponentsData]) => {
          const newInstantExperience = await mapCsvDataToInstantExperience(
            ieName,
            ieComponentsData,
          );

          ++importedInstantExperiences;

          if (!getCancelImport()) {
            openProcessingImportNotification({
              ...importNotificationProps,
              importedItems: importedInstantExperiences,
            });
          }

          return newInstantExperience;
        },
      ),
    );

    if (getCancelImport()) {
      return dispatch(resetInstantExperiencesImport());
    }

    setDisplayCloseIcon(false);
    dispatch(finishProcessingInstantExperiences(newInstantExperiences));
  };
