import { useDispatch } from "react-redux";
import { FacebookAdPart, DelimterObject } from "shared/types/adLibrary";
import { setDelimiter } from "redux/adLibrary/adLibrary.slice";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { selectAdLoadParameters } from "redux/adLibrary/adLibrary.selectors";

interface IUseDelimiter {
  adPart: FacebookAdPart;
}

const useDelimiter = ({ adPart }: IUseDelimiter) => {
  const dispatch = useDispatch();
  const adLoadParameters = useAppSelector(selectAdLoadParameters);

  const updateDelimiter = (delimiter: string) => {
    const updatedDelimiters = {
      ...adLoadParameters?.delimiters,
      [adPart]: delimiter,
    } as DelimterObject;

    dispatch(setDelimiter(updatedDelimiters));
  };

  return {
    delimiter: adLoadParameters?.delimiters?.[adPart] ?? "",
    updateDelimiter,
  };
};

export default useDelimiter;
