import { useQuery } from "react-query";
import {
  IAdLoad,
  IGetAdHistorySessionJsonQueryParams,
  IGetAdHistorySessionsResponseData,
} from "shared/types/adLibrary";
import { IApiResponse } from "shared/types/shared";

interface IUseFetchAdHistorySessionJsonArgs {
  params: IGetAdHistorySessionJsonQueryParams;
  enabled?: boolean;
}

const getAdHistorySessionJson = (sessionJsonUrl: string) => async () => {
  if (!sessionJsonUrl?.trim()) {
    return;
  }

  const response = await fetch(
    `${sessionJsonUrl}?date=${new Date().getTime()}`,
    {
      method: "GET",
    },
  );

  const json = await response.json();

  return json as IAdLoad;
};

export const useFetchSessionJson = (
  args: IUseFetchAdHistorySessionJsonArgs,
) => {
  return useQuery<
    IAdLoad | undefined,
    IApiResponse<IGetAdHistorySessionsResponseData>
  >(
    ["adHistorySessionJson", args.params.sessionId],
    getAdHistorySessionJson(args.params.sessionJsonUrl),
    { staleTime: Infinity, enabled: args?.enabled },
  );
};
