import { Button, Drawer } from "antd";
import { BrandLogo } from "shared/types/brandsAccounts";
import { useDataList } from "../dataList";
import { LogoSelectFilterList } from "./LogoSelectFilterList";
import { useLogoSelectContext } from "./context/LogoSelectContext";
import { DEFAULT_KEY } from "../dataList/useDataList";

export const LogoSelectFilterDrawer = () => {
  const { showFilterDrawer, setShowFilterDrawer } = useLogoSelectContext();
  const [dataList, dataListActions] = useDataList<BrandLogo>();

  const isSomeFilterApplied = Object.values(dataList.filters[DEFAULT_KEY]).some(
    value => !!value,
  );

  return (
    <Drawer
      title="Filter by:"
      width={500}
      placement="right"
      closable={true}
      onClose={() => setShowFilterDrawer(false)}
      visible={showFilterDrawer}
      footerStyle={{ display: "flex", justifyContent: "flex-end" }}
      footer={
        <Button
          type="link"
          disabled={!isSomeFilterApplied}
          onClick={() => {
            dataListActions.resetFilters();
            setShowFilterDrawer(false);
          }}
        >
          Reset Filters
        </Button>
      }
    >
      <LogoSelectFilterList />
    </Drawer>
  );
};
