import { useRef, useEffect, useCallback } from "react";

/** Returns a function to check whether the component is still mounted,
 * Useful to avoid the  `Can't perform a React state update on an unmounted component.` warning.
 * @example
 *  const getIsMounted = useGetIsMounted();
 *  const [data, setData] = useState();
 *  useEffect(() => {
 *    const fetchData = async () => {
 *      const result = await getDataPromise();
 *      // set state if the component is still mounted
 *      if (getIsMounted()) {
 *        setData(result.data);
 *      }
 *    };
 *    fetchData();
 * }, []);
 */
function useGetIsMounted(): () => boolean {
  const ref = useRef(false);

  useEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  }, []);

  return useCallback(() => ref.current, [ref]);
}

export default useGetIsMounted;
