import { fabric } from "fabric";
import uuid from "uuid";

import * as fabricHelpers from "../helpers.fabric";
import {
  IDimension,
  IStamp,
  TShape,
  IExtendedFabricObject,
} from "shared/types/designStudio";
import {
  getInitialLogoSize,
  getSvgLogoFileUrl,
  createLogoFabricObject,
} from "./helpers.logo";

import carCutImagePath from "statics/images/car_cut.png";
import imagePlaceholderPath from "../../statics/images/stampplaceholder.svg";
import {
  TFileType,
  TImageInsertData,
  TLogoDropZone,
} from "screens/designStudio/editor/canvasContainer/canvas.hooks/useImageInsertData";

type TInsertParams = {
  canvasDimension: IDimension;
  imageInsertData: TImageInsertData;
  canvasAreaMargin: {
    top: number;
    left: number;
  };
};

export const insert = ({
  canvasDimension,
  canvasAreaMargin,
  imageInsertData,
}: TInsertParams) => {
  return new Promise<IExtendedFabricObject | null>(resolve => {
    const widthToUse = canvasDimension.width / 3;
    const heightToUse = canvasDimension.height / 3;
    const { top, left } = canvasAreaMargin;
    switch (imageInsertData.type) {
      case "CAR_CUT":
        fabric.Image.fromURL(
          carCutImagePath,
          img => {
            img.scaleToWidth(widthToUse);
            img.set({
              name: uuid(),
              top,
              left,
            });

            const extendedImg = fabricHelpers.extendFabricObject({
              object: img,
              objectType: "car_cut",
              properties: {
                layerName: "",
              },
            });

            resolve(extendedImg);
          },
          {
            crossOrigin: "anonymous",
          },
        );

        break;

      case "LIFESTYLE":
        const lifestylePlaceholderColor = "#D3D3D3";

        const rect = new fabric.Rect({
          name: uuid(),
          top,
          left,
          width: canvasDimension.width,
          height: canvasDimension.height,
          opacity: 0.8,
          fill: lifestylePlaceholderColor,
          selectable: false,
        });

        const extendedRect = fabricHelpers.extendFabricObject({
          object: rect,
          objectType: "lifestyle",
          properties: {
            layerName: "Lifestyle",
          },
        });

        resolve(extendedRect);

        break;

      case "THEME_BACKGROUND":
        const themeRect = new fabric.Rect({
          name: uuid(),
          top,
          left,
          width: canvasDimension.width,
          height: canvasDimension.height,
          opacity: 0.8,
          fill: "#D0D0D0",
          selectable: false,
        });

        const extendedThemeRect = fabricHelpers.extendFabricObject({
          object: themeRect,
          // dynamic background using same lifestyle objectType
          objectType: "theme_background",
          properties: {
            layerName: "Theme Background",
          },
        });

        resolve(extendedThemeRect);

        break;

      case "STAMP":
        const { data } = imageInsertData;
        const {
          id,
          name: stampName = "",
          thumbnailUrl,
        } = data as Partial<IStamp>;

        const fetchStamp = async () => {
          const headReq = await fetch(thumbnailUrl || "", {
            method: "HEAD",
          });

          if (headReq.status >= 300) return resolve(null);
          const name = uuid();
          const url = thumbnailUrl || imagePlaceholderPath;

          fabric.Image.fromURL(
            url,
            img => {
              img.scaleToWidth(widthToUse);
              img.set({
                top,
                left,
                name,
              });

              // The below custom attributes MUST BE IN customFabricAttributes array.
              const extendedImage = fabricHelpers.extendFabricObject({
                objectType: "stamp",
                object: img,
                properties: {
                  stampId: id,
                  stampName,
                  layerName: "",
                },
              });

              resolve(extendedImage);
            },
            {
              crossOrigin: "anonymous",
            },
          );
        };

        fetchStamp();

        break;

      case "LOGO":
        if (!imageInsertData.data) {
          return;
        }

        const {
          logoEventType,
          logoDropZoneType,
          position: draggedPosition,
          logoDimension,
        } = imageInsertData.data as TLogoDropZone;

        const dimension: IDimension = getInitialLogoSize(
          {
            width: logoDimension ? logoDimension.width : widthToUse,
            height: logoDimension ? logoDimension.height : heightToUse,
          },
          logoDropZoneType,
        );

        const logoFileUrl = getSvgLogoFileUrl(logoEventType, logoDropZoneType);

        const position: {
          top: number;
          left: number;
        } = {
          top: canvasAreaMargin.top + (draggedPosition?.top || 0),
          left: canvasAreaMargin.left + (draggedPosition?.left || 0),
        };

        createLogoFabricObject({
          logoFileUrl,
          position,
          eventType: logoEventType,
          dropZone: logoDropZoneType,
          logoDimension: dimension,
          shiftToDraggedPosition: !!draggedPosition,
        }).then(logoObject => {
          resolve(logoObject);
        });

        break;

      case "SHAPE":
        const shape = imageInsertData.data as TShape;
        const baseSize = widthToUse;
        const defaultColor = "#A9A9A9";
        const layerName = shape;
        const shapeId = uuid();

        const shapePosition = {
          top,
          left,
        };

        switch (shape) {
          case "square":
            const rect = new fabric.Rect({
              ...shapePosition,
              name: shapeId,
              width: baseSize,
              height: baseSize,
              fill: defaultColor,
              selectable: true,
            });

            const extendedRect = fabricHelpers.extendFabricObject({
              objectType: "shape",
              object: rect,
              properties: {
                layerName,
                shape,
                fill: defaultColor,
                radius: 0,
              },
            });
            extendedRect.set({ strokeUniform: true });
            resolve(extendedRect);

            break;

          case "circle":
            const circle = new fabric.Circle({
              name: shapeId,
              ...shapePosition,
              radius: baseSize * 0.5, // baseSize for radius is too big
              fill: defaultColor,
              selectable: true,
            });

            const extendedCircle = fabricHelpers.extendFabricObject({
              objectType: "shape",
              object: circle,
              properties: {
                layerName,
                shape,
                fill: defaultColor,
              },
            });

            extendedCircle.set({ strokeUniform: true });
            resolve(extendedCircle);
            break;

          case "squircle":
            const squircle = new fabric.Rect({
              name: shapeId,
              ...shapePosition,
              rx: baseSize * 0.1,
              ry: baseSize * 0.1,
              width: baseSize,
              height: baseSize * 0.6,
              fill: defaultColor,
              selectable: true,
            });

            const extendedSquircle = fabricHelpers.extendFabricObject({
              objectType: "shape",
              object: squircle,
              properties: {
                layerName,
                shape,
                fill: defaultColor,
                radius: 36,
              },
            });
            extendedSquircle.set({ strokeUniform: true });
            resolve(extendedSquircle);
            break;

          case "triangle":
            const triangle = new fabric.Triangle({
              name: shapeId,
              ...shapePosition,
              width: baseSize,
              height: baseSize,
              fill: defaultColor,
              selectable: true,
            });

            const extendedTriangle = fabricHelpers.extendFabricObject({
              objectType: "shape",
              object: triangle,
              properties: {
                layerName,
                shape,
                fill: defaultColor,
              },
            });
            extendedTriangle.set({ strokeUniform: true });
            resolve(extendedTriangle);
            break;
        }

        break; // outer switch

      case "DISCLOSURE":
        const textbox = new fabric.Textbox("{Disclosure}", {
          top,
          left,
          width: 300,
          fill: "white",
          name: uuid(),
          fontFamily: imageInsertData.data
            ? (imageInsertData.data as string)
            : "HelveticaNeueLTStd-Cn",
          fontSize: 12,
          editable: false,
        });

        fabricHelpers.disableTextboxControls(textbox);

        const extendedDisclosureTextbox = fabricHelpers.extendFabricObject({
          objectType: "disclosure",
          object: textbox,
          properties: {
            layerName: "",
          },
        });

        resolve(extendedDisclosureTextbox);
        break;

      case "TEXT":
        const textboxObject = fabricHelpers.initiateTextbox({
          text: "Start writing...",
          properties: {
            width: widthToUse,
            top,
            left,
            lineHeight: 1.0,
            selectable: true,
            name: uuid(),
            objectCaching: false,
            ...(imageInsertData.data && {
              fontFamily: imageInsertData.data as string,
            }),
          },
        });

        const extendedTextbox = fabricHelpers.extendFabricObject({
          objectType: "text",
          object: textboxObject,
          properties: {
            layerName: "textbox",
            verticalAlign: "top",
            textboxHeight: textboxObject.getBoundingRect().height,
          },
        });

        resolve(extendedTextbox);
        break;

      case "SELECTED_VIDEO":
        const { data: videoData } = imageInsertData as TImageInsertData;
        const videoElement = (videoData as TFileType)
          .element as HTMLVideoElement;
        const { videoWidth, videoHeight, duration } = videoElement;
        const video = new fabric.Image(videoElement, {
          top,
          left,
          width: videoWidth,
          height: videoHeight,
          name: uuid(),
          objectCaching: false,
        });

        video.scaleToWidth(widthToUse);

        const extendedVideo = fabricHelpers.extendFabricObject({
          objectType: "selected_video",
          object: video,
          properties: {
            layerName: "video",
            videoSrc: (videoData as TFileType).sourceUrl,
            duration: duration,
          },
        });

        resolve(extendedVideo);

        break;
      case "SELECTED_IMAGE":
        const { data: imageData } = imageInsertData as TImageInsertData;
        if (!imageData) break;

        const { dataUri: imageDataUri } = imageData as TFileType;
        if (!imageDataUri) break;

        fabric.Image.fromURL(imageDataUri, img => {
          const defaultImageWidth = widthToUse;
          img.scaleToWidth(defaultImageWidth);

          img.set({
            top,
            left,
            name: uuid(),
          });

          const extendedImage = fabricHelpers.extendFabricObject({
            objectType: "selected_image",
            object: img,
            properties: {
              layerName: "image",
            },
          });

          resolve(extendedImage);
        });
        break;

      case "BACKGROUND":
        if (!imageInsertData.data) resolve(null);

        const backgroundImageData = imageInsertData.data as TFileType;
        fabric.Image.fromURL(backgroundImageData.dataUri, img => {
          // NOTE: this image will be resized to fit the canvasArea in the Canvas.tsx
          img.set({
            name: uuid(),
            selectable: false,
            evented: false,
          });

          const extendedBackgroundImage = fabricHelpers.extendFabricObject({
            object: img,
            objectType: "canvas_bg",
            properties: {
              layerName: "canvas_bg",
            },
          });
          resolve(extendedBackgroundImage);
        });

        break;
      default:
        resolve(null);
        return;
    }
  });
};
