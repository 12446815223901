import { IConfig } from "shared/types/configuration";
import {
  IGetPutSignedUrlReqBody,
  IUploadFont,
  IUploadImagesForm,
} from "shared/types/uploadManagement";
import HttpClient from "./httpClient";

export default ({ config }: { config: IConfig }) => ({
  uploadImages: <T>(uploadForm: IUploadImagesForm): Promise<T> => {
    return HttpClient.post(config.services.uploadImagesUrl, {
      uploadForm,
    });
  },

  generateThumbnail: ({
    videoUrl,
  }: {
    videoUrl: string;
  }): Promise<{ thumbnailUrl: string }> => {
    return HttpClient.post(config.services.generateThumbnailUrl, {
      videoUrl,
    });
  },

  getSignedPutUrl: <T>(
    getPutSignedUrlReqBody: IGetPutSignedUrlReqBody,
  ): Promise<T> => {
    return HttpClient.post(
      config.services.getSignedPutUrlUrl,
      getPutSignedUrlReqBody,
    );
  },

  uploadFont: <T>(fontFile: IUploadFont): Promise<T> => {
    return HttpClient.post(config.services.uploadFont, {
      fontFile,
    });
  },
});
