import { Button, Space, Tag } from "antd";
import styles from "./ChipsFilter.module.scss";
import { AdType } from "screens/adLibrary/facebookUtils/types";
import { QCStatus } from "shared/types/shared";
import CheckableTag from "antd/lib/tag/CheckableTag";

interface IProps {
  filterTags: (AdType | QCStatus | string)[];
  selectedTags: (AdType | QCStatus | string)[];
}

interface IHandlers {
  clearFilters: () => void;
  setTagSelected: (tag: AdType | QCStatus | string) => void;
  enhancedSearch?: boolean;
}

type PropsType = IProps & IHandlers;

const ChipsFilter = ({
  filterTags,
  selectedTags,
  setTagSelected,
  clearFilters,
  enhancedSearch,
}: PropsType) => {
  return (
    <Space size={0} align="center">
      {!enhancedSearch &&
        filterTags.map(tag => (
          <CheckableTag
            data-cy="filter-tag"
            className={styles.checkableTag}
            key={tag?.toLowerCase()}
            checked={(selectedTags || []).includes(tag)}
            onClick={() => setTagSelected?.(tag)}
          >
            {tag}
          </CheckableTag>
        ))}
      {enhancedSearch &&
        selectedTags.map(tag => (
          <Tag
            data-cy="filter-tag"
            className={styles.tag}
            key={tag?.toLowerCase()}
            closable
            onClose={() => setTagSelected?.(tag)}
          >
            <span onClick={() => setTagSelected?.(tag)}>{tag}</span>
          </Tag>
        ))}
      {!!selectedTags?.length && (
        <Button type="link" onClick={clearFilters}>
          Clear
        </Button>
      )}
    </Space>
  );
};

export default ChipsFilter;
