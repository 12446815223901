import { TableRowSelection } from "antd/lib/table/interface";
import { useFileTypes } from "../useFileTypes";
import { useSelected } from "../useSelected";

export const useRowSelection = <T>(): TableRowSelection<T> => {
  const { canSelect } = useFileTypes();
  const { selectedIds, setSelected } = useSelected();
  const getCheckboxProps = (record: any) => ({
    disabled: !canSelect(record),
  });

  return {
    getCheckboxProps,
    onChange: setSelected,
    selectedRowKeys: selectedIds,
  };
};
