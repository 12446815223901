import { Col, Form, Input, Radio, Row } from "antd";
import { MinMaxInput } from "./MinMaxInput";

export const PrintOptionsForm = () => {
  return (
    <Form layout="vertical">
      <Row justify="space-between" gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input the name!" }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Weight per Copy"
            name="weight"
            rules={[
              {
                required: true,
                message: "Please input the weight per copy!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Estimated Delivery Time"
            name="estimatedDeliveryTime"
            rules={[
              {
                required: true,
                message: "Please input the estimated delivery time!",
              },
            ]}
          >
            <MinMaxInput />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Reference No."
            name="referenceNo"
            rules={[
              {
                required: true,
                message: "Please input the reference number!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item label="Copy Selection">
            <Radio.Group>
              <Radio value="quantityRange">Quantity Range</Radio>
              <Radio value="fixedAmount">Fixed Amount</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Print Mode">
            <Radio.Group>
              <Radio value="simplexOnly">Simplex Only</Radio>
              <Radio value="duplexOnly">Duplex Only</Radio>
              <Radio value="simplexAndDuplex">Simplex and Duplex</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
