import { CSSProperties, useCallback, useRef } from "react";
import ReactFlow, {
  Background,
  Controls,
  OnLoadParams,
  useStoreActions,
  ReactFlowProps,
  useZoomPanHelper,
} from "react-flow-renderer";
import {
  CANVAS_BG_COLOR,
  EdgeTypes,
  MAX_ZOOM,
  MIN_ZOOM,
  NodeTypes,
  useEverythingAdsContext,
} from "screens/everythingAds.hooks/useContextAPI";
import styles from "./FlowCanvas.module.scss";
import { message } from "antd";
import * as utils from "../utils";
import { useEffect } from "react";
import {
  generateEdges,
  generateElements,
} from "screens/everythingAds.hooks/utils";

const FlowCanvas = () => {
  const ieContext = useEverythingAdsContext();

  const { instantExperiences, setInstance, instance, flowTransform } =
    ieContext || {};

  const { paneDrag } = ieContext || {};
  const { paneMovable, paneInMoving, onMoveStart, onMoveEnd } = paneDrag || {};

  const flowWrapper = useRef<HTMLDivElement>(null);

  const onLoad = useCallback(
    (instance: OnLoadParams) => setInstance?.(instance),
    [setInstance],
  );

  const { isAuthenticated, eventCallbacks } = ieContext || {};

  const flowEventProps: Partial<ReactFlowProps> = {
    onConnect: eventCallbacks?.onConnect,
    ...(isAuthenticated
      ? {
          onElementsRemove: eventCallbacks?.onElementsRemove,
          onSelectionChange: eventCallbacks?.onSelectionChange,
        }
      : {}),
  };
  const flowProps: Partial<ReactFlowProps> = {
    ...(isAuthenticated
      ? {
          nodesDraggable: true,
        }
      : {
          nodesDraggable: false,
        }),
  };

  const setSelectedElements = useStoreActions(
    actions => actions.setSelectedElements,
  );

  const { setHoveringEdge } = ieContext || {};
  const onEdgeMouseEnter: ReactFlowProps["onEdgeMouseEnter"] = useCallback(
    (e, edge) => {
      setHoveringEdge?.(edge);
    },
    [setHoveringEdge],
  );

  const onEdgeMouseLeave: ReactFlowProps["onEdgeMouseLeave"] =
    useCallback(() => {
      setHoveringEdge?.(undefined);
    }, [setHoveringEdge]);

  const { transform } = useZoomPanHelper();
  useEffect(() => {
    if (!flowTransform) return;

    transform(flowTransform);
  }, [transform, flowTransform]);

  const connectionLineStyle: CSSProperties = {
    stroke: "#3fa9ff",
    strokeWidth: 2,
  };

  return (
    <div
      ref={flowWrapper}
      className={styles.flowWrapper}
      style={{
        cursor: paneMovable ? (paneInMoving ? "grabbing" : "grab") : "inherit",
      }}
    >
      <ReactFlow
        {...flowProps}
        {...flowEventProps}
        maxZoom={MAX_ZOOM}
        minZoom={MIN_ZOOM}
        panOnScroll={true}
        snapToGrid={true}
        onMoveStart={onMoveStart}
        onMoveEnd={onMoveEnd}
        paneMoveable={paneMovable}
        style={{ background: CANVAS_BG_COLOR }}
        nodeTypes={NodeTypes}
        edgeTypes={EdgeTypes}
        elements={ieContext!.elements}
        onLoad={onLoad}
        onDragOver={utils.onDragOver}
        onDrop={e =>
          utils
            .onDrop(e, flowWrapper.current, instance)
            .then(async ({ element, position }) => {
              const elements =
                (await generateElements({
                  element,
                  dataSource: instantExperiences || [],
                  depth: position.x,
                  degree: position.y,
                })) || [];

              // target cannot be same with source
              const edges = generateEdges({ elements }).filter(
                edge => edge.target !== edge.source,
              );

              if (elements.length === 0 && edges.length === 0)
                throw new Error("Elements cannot be generated.");

              ieContext?.setElements(es => es.concat([...elements, ...edges]));

              setSelectedElements(elements);
            })
            .catch(err => {
              message.error(`${err}`);
            })
        }
        onConnectStart={ieContext?.onConnectStart}
        onConnectStop={ieContext?.onConnectStop}
        onEdgeMouseEnter={onEdgeMouseEnter}
        onEdgeMouseLeave={onEdgeMouseLeave}
        connectionLineStyle={connectionLineStyle}
      >
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default FlowCanvas;
