import { Spin } from "antd";
import CardListContainer from "shared/components/CardListContainer";
import { useDesignStudioContext } from "../context/DesignStudioDataProvider";
import { DesignStudioItemCard } from "./designStudioCards/DesignStudioItemCard";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { useDesignStudioIds } from "screens/adLibrary/shared/hooks/dataListHooks";

export const DesignStudioCards = () => {
  const { isLoading, filteredDesignStudioAssets } = useDesignStudioContext();
  const { setSelectedItemIds, selectedIds } = useDesignStudioIds();
  const { windowInnerHeight } = useWindowSize();

  const allAvailableHeight = windowInnerHeight - 170;

  const selectCard = (id: string, checked: boolean) => {
    checked
      ? setSelectedItemIds([...selectedIds, id])
      : setSelectedItemIds(selectedIds.filter(prevId => prevId !== id));
  };

  const hasSelection = selectedIds.length > 0;
  return (
    <Spin spinning={isLoading}>
      <CardListContainer
        height={allAvailableHeight}
        virtualized
        gridArgs={{ cardWidth: 250, cardHeight: 395 }}
        cards={filteredDesignStudioAssets.map(item => {
          const isSelected = selectedIds.includes(item.id);
          return (
            <DesignStudioItemCard
              key={item.id}
              item={item}
              onChange={selectCard}
              showCheckbox={hasSelection || isSelected}
              isSelected={isSelected}
            />
          );
        })}
      />
    </Spin>
  );
};
