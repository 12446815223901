import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "redux/store";
import ConfigurationError from "shared/errors/ConfigurationError";
import {
  Environment,
  FeedType,
  IConfig,
  IConfigurationState,
} from "shared/types/configuration";
import configGenerator from "../../configGenerator";

const AV2_FEED = process.env.REACT_APP_AV2_FEED;
const feed = (
  !AV2_FEED || AV2_FEED === "internal" ? "ladtech" : AV2_FEED
) as FeedType;

const initialState: IConfigurationState = {
  env: undefined,
  config: undefined,
  settingConfiguration: false,
  settingConfigurationMessage: undefined,

  openV1: false,
  error: undefined,
  feed,
};

const configurationSlice = createSlice({
  name: "configuration",
  initialState,
  reducers: {
    setConfigurationBegin(state) {
      return {
        ...state,
        settingConfiguration: true,
        settingConfigurationMessage: "Configuring the site... Please wait.",
      };
    },
    setConfigurationSuccess(
      state,
      { payload: config }: PayloadAction<IConfig>,
    ) {
      return {
        ...state,
        config,

        settingConfiguration: false,
        settingConfigurationMessage: undefined,
      };
    },
    setConfigurationFail(
      state,
      { payload: error }: PayloadAction<ConfigurationError>,
    ) {
      return {
        ...state,
        settingConfiguration: false,
        settingConfigurationMessage: undefined,
        error,
      };
    },
    switchToV1Success(
      state,
      { payload: { openV1 } }: PayloadAction<{ openV1: boolean }>,
    ) {
      return {
        ...state,
        openV1,
      };
    },
    setFeedSuccess(state, { payload }: PayloadAction<{ feed: FeedType }>) {
      return {
        ...state,
        feed: payload.feed || "ladtech",
      };
    },
  },
});

export const setConfiguration =
  (env?: Environment): AppThunk =>
  async dispatch => {
    dispatch(setConfigurationBegin());

    try {
      const config: IConfig = configGenerator(
        env ? env : (process.env.REACT_APP_ENV as Environment),
      );

      dispatch(setConfigurationSuccess(config));
    } catch (error) {
      dispatch(
        setConfigurationFail(
          new ConfigurationError({
            message: "Failed to set configuration",
            errorObj: error,
          }),
        ),
      );
    }
  };

export const switchToV1 =
  (open: boolean): AppThunk =>
  async dispatch => {
    dispatch(
      switchToV1Success({
        openV1: open,
      }),
    );
  };

export const setFeed =
  (feed: FeedType): AppThunk =>
  async dispatch => {
    dispatch(setFeedSuccess({ feed }));
  };

export const {
  setConfigurationBegin,
  setConfigurationFail,
  setConfigurationSuccess,
  switchToV1Success,
  setFeedSuccess,
} = configurationSlice.actions;

export default configurationSlice.reducer;
