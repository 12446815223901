import { AssetExporterState, FeedUploadType } from "shared/types/assetExporter";
import { encryptPassword } from "utils/helpers";

const getPassword = (isUpdate: boolean, tmpPsw?: string, psw?: string) => {
  const defaultPassword = psw ?? "";
  if (!isUpdate) return encryptPassword(defaultPassword);
  return !tmpPsw ? encryptPassword(defaultPassword) : encryptPassword(tmpPsw);
};

const getServerFormAttrs = (
  feedDrawer: AssetExporterState["sourceFeed"]["feedDrawer"],
  feedId: string,
  isUpdate: boolean,
) => ({
  feedId,
  frequency: feedDrawer.frequency ?? "Daily",
  repeat: feedDrawer.repeat ?? "--",
  startTime: feedDrawer.startTime,
  timezone: feedDrawer.timezone,
  feedName: feedDrawer.feedName,
  host: feedDrawer.host,
  username: feedDrawer.username,
  password: getPassword(isUpdate, feedDrawer.tempPassword, feedDrawer.password),
  path: feedDrawer.path,
  port: feedDrawer.port,
  mode: feedDrawer.drawerMode,
  filterColHeader: feedDrawer.filterColHeader,
  oemTags: feedDrawer.oemTags,
  locationTags: feedDrawer.locationTags,
  storeTags: feedDrawer.storeTags,
  sync: true,
});

const getCustomFromAttrs = (
  feedDrawer: AssetExporterState["sourceFeed"]["feedDrawer"],
  feedId: string,
) => ({
  feedId,
  feedName: feedDrawer.feedName,
  filterColHeader: feedDrawer.filterColHeader,
  oemTags: feedDrawer.oemTags,
  locationTags: feedDrawer.locationTags,
  storeTags: feedDrawer.storeTags,
});

export const getFeedFormAttrs = (
  feedDrawer: AssetExporterState["sourceFeed"]["feedDrawer"],
  isUpdate: boolean,
  type: FeedUploadType,
) => {
  const feedIdFromFile = feedDrawer.uploadedFile?.split("_")[0] ?? "";
  const feedId = isUpdate ? feedDrawer.feedId : feedIdFromFile;
  if (type === "server")
    return getServerFormAttrs(feedDrawer, feedId, isUpdate);
  return getCustomFromAttrs(feedDrawer, feedId);
};
